/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlimJobPersonaQuestionnaire
 */
export interface SlimJobPersonaQuestionnaire {
    /**
     * 
     * @type {string}
     * @memberof SlimJobPersonaQuestionnaire
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlimJobPersonaQuestionnaire
     */
    state?: SlimJobPersonaQuestionnaireStateEnum;
    /**
     * Stores the state of whether the user has skipped the questionnaire, if they can skip it.
     * @type {string}
     * @memberof SlimJobPersonaQuestionnaire
     */
    skipState?: SlimJobPersonaQuestionnaireSkipStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum SlimJobPersonaQuestionnaireStateEnum {
    NotStarted = 'NOT_STARTED',
    InProgress = 'IN_PROGRESS',
    Submitted = 'SUBMITTED'
}/**
* @export
* @enum {string}
*/
export enum SlimJobPersonaQuestionnaireSkipStateEnum {
    ChoseToSkip = 'CHOSE_TO_SKIP',
    ChoseNotToSkip = 'CHOSE_NOT_TO_SKIP',
    SkippingNotAllowed = 'SKIPPING_NOT_ALLOWED',
    AutomaticallySkipped = 'AUTOMATICALLY_SKIPPED'
}

export function SlimJobPersonaQuestionnaireFromJSON(json: any): SlimJobPersonaQuestionnaire {
    return SlimJobPersonaQuestionnaireFromJSONTyped(json, false);
}

export function SlimJobPersonaQuestionnaireFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlimJobPersonaQuestionnaire {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'skipState': !exists(json, 'skip_state') ? undefined : json['skip_state'],
    };
}

export function SlimJobPersonaQuestionnaireToJSON(value?: SlimJobPersonaQuestionnaire | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'skip_state': value.skipState,
    };
}


