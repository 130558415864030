import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { StringParam, useQueryParam } from "use-query-params";

import { ReactComponent as Check } from "assets/icons/circled-green-checkmark.svg";
import { CalibrationSettings } from "components/dover/dover-outbound-modal/CalibrationSettings";
import {
  EmailsPerWeekOption,
  EMAILS_PER_WEEK_CONFIGS,
  EMAIL_VOLUME_TAB_QUERY_PARAM_VALUE,
  PASSIVE_CANDIDATES_MEETINGS_TAB_QUERY_PARAM_VALUE,
  SOURCING_AUTOPILOT_TAB_QUERY_PARAM_VALUE,
  SIMILAR_CANDIDATES_TAB_QUERY_PARAM_VALUE,
  MAX_NUMBER_OF_EMAILS_PER_WEEK,
  DEFAULT_CANDIDATE_EMAIL_INTEREST_RATE,
  CALIBRATION_SETTINGS_TAB_QUERY_PARAM_VALUE,
} from "components/dover/dover-outbound-modal/constants";
import DoverEmailVolumeTabContent from "components/dover/dover-outbound-modal/DoverEmailVolumeTabContent";
import {
  useGetCurrentCalibrationSetting,
  useGetJobNotifConfigs,
  useUpdateCalibrationSettings,
} from "components/dover/dover-outbound-modal/hooks";
import { getDoverOutboundConfigOptionFromEmailsPerWeek } from "components/dover/dover-outbound-modal/utils";
import {
  JobFeatureSettingToggleHandler,
  JobFeatureSettingToggleHandlerContext,
} from "components/dover/feature-toggle-handler/types";
import { SnoozeJobModal } from "components/dover/SnoozeJobModal";
import { SourcingAutopilotContent } from "components/dover/SourcingAutopilotModal/SourcingAutopilotContent";
import { Button, ButtonVariant } from "components/library/Button";
import { TabBar } from "components/library/TabBar";
import { SELECTED_TAB_QUERY_PARAM_KEY } from "components/library/TabBar/constants";
import { Body, BodySmall, PageTitle } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { Spacer } from "components/Spacer";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetDoverPlan, useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import {
  useGetJobOutreachStatsQuery,
  useGetJobReportMetaQuery,
  useGetJobSetupQuery,
  useUnsnoozeJobMutation,
} from "services/doverapi/endpoints/job/endpoints";
import { useGetJobIsSnoozed } from "services/doverapi/endpoints/job/hooks";
import { useUpsertJobFeatureSettingMutation } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import {
  useGetDoverOutboundConfigurationQuery,
  useUpdateDoverOutboundConfigurationMutation,
} from "services/doverapi/endpoints/jobFulfillment";
import {
  CandidateSource,
  ClientDoverPlanEnum,
  GetDoverOutboundConfiguration200ResponseShouldEngagePassiveEnum,
  NotificationConfigChannelEnum,
  NotificationConfigNotifTypeEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
// TODO: refactor these cross-view imports (sc-173852)
import { showErrorToast, toastOptions } from "utils/showToast";
import { StyledSwitch } from "views/job/JobSetup/steps/Overview/styles";
import { ModalKeyType } from "views/job/types";
import { DateOption } from "views/Reporting/constants";
import { getFilterDates } from "views/Reporting/utils";

const DoverEmailOutreachModal = ({
  isModalOpen,
  toggleModalOff,
  candidateSource,
  isEnabled,
  isUpdating = false,
  showInterestedCandidates = false,
}: {
  isModalOpen: boolean;
  toggleModalOff: () => void;
  candidateSource: CandidateSource | undefined;
  isEnabled: boolean;
  isUpdating?: boolean;
  showInterestedCandidates?: boolean;
}): React.ReactElement => {
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);

  const jobId = useJobIdFromUrl();
  const { data: job } = useGetJobSetupQuery(jobId ? jobId : skipToken);

  const configsByType = useGetJobNotifConfigs(jobId);
  const notifConfigId =
    configsByType?.[NotificationConfigNotifTypeEnum.OutOfDepthAlert]?.id &&
    configsByType?.[NotificationConfigNotifTypeEnum.OutOfDepthAlert]?.channel === NotificationConfigChannelEnum.Slack
      ? configsByType?.[NotificationConfigNotifTypeEnum.OutOfDepthAlert]?.id
      : undefined;

  const initialCalibrationSetting = useGetCurrentCalibrationSetting(job);
  const updateCalibrationSettings = useUpdateCalibrationSettings();
  const [savingCalibrationSettings, setSavingCalibrationSettings] = useState(false);

  const onSaveCalibrationSettings = async (): Promise<void> => {
    if (!jobId) {
      showErrorToast("No job id");
      return;
    }
    const updateCalibrationSettingsPromise = updateCalibrationSettings?.(jobId, notifConfigId);
    setSavingCalibrationSettings(true);

    if (!updateCalibrationSettingsPromise) {
      setSavingCalibrationSettings(false);
      showErrorToast("Error updating calibration settings");
      return;
    }

    try {
      await toast.promise(
        updateCalibrationSettingsPromise,
        {
          pending: "Updating calibration settings...",
          success: "Updated calibration settings!",
          error: "Error updating calibration settings",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }

    setSavingCalibrationSettings(false);
    toggleModalOff();
  };

  const [isUpdatedVolumeModalOpen, setIsUpdatedVolumeModalOpen] = useState(false);
  const [snoozeModalOpen, setSnoozeModalOpen] = useState<ModalKeyType>(ModalKeyType.None);
  const jobIsSnoozed = useGetJobIsSnoozed(jobId!);
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    jobIsSnoozed ? (job?.snoozedUntil as Date | null) : null
  );

  const [
    updateDoverOutboundConfiguration,
    { isLoading: isUpdatingOutboundConfig },
  ] = useUpdateDoverOutboundConfigurationMutation();
  const [unsnoozeJob, { isLoading: unSnoozingJob }] = useUnsnoozeJobMutation();

  const {
    doverOutboundConfigOption,
    emailsPerWeek,
    interestedRate,
    phoneScreensPerWeek,
    passiveCandidates,
    autoQueueOutreach,
    isRetrieveLoading,
    doverOutboundConfigurationSuccess,
    similarCandidatesSourcingTarget,
  } = useGetDoverOutboundConfigurationQuery(jobId ?? skipToken, {
    selectFromResult: ({ data, isLoading, isSuccess }) => {
      return {
        doverOutboundConfigOption: getDoverOutboundConfigOptionFromEmailsPerWeek(data?.emailsPerWeekOption),
        emailsPerWeek: data?.emailsPerWeek,
        interestedRate: data?.interestedRate,
        phoneScreensPerWeek: data?.phoneScreensPerWeek,
        passiveCandidates:
          data?.shouldEngagePassive ===
          GetDoverOutboundConfiguration200ResponseShouldEngagePassiveEnum.InterestedInRole,
        autoQueueOutreach: data?.autoQueueOutreach,
        isRetrieveLoading: isLoading,
        doverOutboundConfigurationSuccess: isSuccess,
        similarCandidatesSourcingTarget: data?.similarCandidatesSourcingTarget,
      };
    },
  });
  const isBasePlanCustomer = useIsBasePlanCustomer();
  const { isLoading: isJobMetaLoading } = useGetJobReportMetaQuery(jobId ?? skipToken);

  const [numInterestedCandidates, setNumInterstedCandidates] = React.useState<number>(5);
  const [snoozeReason, setSnoozeReason] = useState(jobIsSnoozed && job?.snoozedReason ? job?.snoozedReason : "");

  const [sourceSimilarCandidates, setSourceSimilarCandidates] = useState<boolean>(!!similarCandidatesSourcingTarget);

  const doverPlan = useGetDoverPlan();
  const showFeedbackMeetingTab = !isBasePlanCustomer;

  const { isFetching: isFetchingOutreachStats } = useGetJobOutreachStatsQuery(
    jobId && candidateSource && !showInterestedCandidates
      ? {
          id: jobId,
          channelName: "OUTBOUND",
          candidateSource: [candidateSource.id!],
        }
      : skipToken
  );
  const { isFetching: isFetchingoutreachStatsPastTwoToFourWeeks } = useGetJobOutreachStatsQuery(
    jobId && candidateSource && showInterestedCandidates
      ? {
          id: jobId,
          ...getFilterDates(DateOption.Last2To4Weeks),
          channelName: "OUTBOUND",
          candidateSource: [candidateSource.id!],
        }
      : skipToken
  );

  const [selectedEmailsPerWeekOption, setSelectedEmailsPerWeekOption] = useState<EmailsPerWeekOption | undefined>();
  const [selectedEmailsPerWeek, setSelectedEmailsPerWeek] = useState<number | undefined>();
  const [passiveCandidatesChecked, setPassiveCandidatesChecked] = React.useState<boolean>(passiveCandidates);
  const [selectedAutopilotOption, setSelectedAutopilotOption] = React.useState<boolean | undefined>(
    autoQueueOutreach as boolean | undefined
  );

  const [selectedTab] = useQueryParam(SELECTED_TAB_QUERY_PARAM_KEY, StringParam);

  const jobFeatureToggleHandler = React.useContext<JobFeatureSettingToggleHandler | undefined>(
    JobFeatureSettingToggleHandlerContext
  );
  const [upsertJobFeatureSetting, { isLoading: isUpdatingJobFeature }] = useUpsertJobFeatureSettingMutation();

  const onClickDisable = React.useCallback(() => {
    jobFeatureToggleHandler?.onFeatureToggled({
      featureName: UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
      desiredFeatureState: UpsertJobFeatureSettingStateEnum.Disabled,
    });
    toggleModalOff();
  }, [jobFeatureToggleHandler, toggleModalOff]);

  const onClickSubmit = React.useCallback(async () => {
    // The button will be disabled if jobId is undefined, but we need to check it here to satisfy the linter
    if (!jobId) {
      return;
    }

    if (showInterestedCandidates) {
      //Do side effects without opening irrelevant confirmation modal
      await updateDoverOutboundConfiguration({
        jobId,
        phoneScreensPerWeek: numInterestedCandidates,
        passiveCandidates: passiveCandidatesChecked,
        autoQueueOutreach: selectedAutopilotOption,
        sourceSimilarCandidates: sourceSimilarCandidates,
      }).unwrap();
      await upsertJobFeatureSetting({
        upsertData: {
          data: {
            job: jobId,
            featureName: UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
            state: UpsertJobFeatureSettingStateEnum.Enabled,
          },
        },
      }).unwrap();
      if (phoneScreensPerWeek !== numInterestedCandidates) {
        setIsUpdatedVolumeModalOpen(true);
      }
    } else {
      const updateOutboundConfig = (): void => {
        updateDoverOutboundConfiguration({
          jobId,
          emailsPerWeekOption: selectedEmailsPerWeekOption!,
          emailsPerWeek: selectedEmailsPerWeek,
          phoneScreensPerWeek: numInterestedCandidates,
          passiveCandidates: passiveCandidatesChecked,
          autoQueueOutreach: selectedAutopilotOption,
          sourceSimilarCandidates: sourceSimilarCandidates,
        });
      };

      jobFeatureToggleHandler?.onFeatureToggled({
        featureName: UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
        desiredFeatureState: UpsertJobFeatureSettingStateEnum.Enabled,
        additionalCallback: updateOutboundConfig,
        additionalInfoArgumentsForModalText: [
          EMAILS_PER_WEEK_CONFIGS[selectedEmailsPerWeekOption!].label,
          selectedEmailsPerWeek,
        ],
      });
    }
    toggleModalOff();
  }, [
    jobId,
    showInterestedCandidates,
    toggleModalOff,
    updateDoverOutboundConfiguration,
    numInterestedCandidates,
    passiveCandidatesChecked,
    selectedAutopilotOption,
    sourceSimilarCandidates,
    upsertJobFeatureSetting,
    phoneScreensPerWeek,
    jobFeatureToggleHandler,
    selectedEmailsPerWeekOption,
    selectedEmailsPerWeek,
  ]);

  const handleTogglePassiveCandidates = React.useCallback((): void => {
    setPassiveCandidatesChecked(!passiveCandidatesChecked);
  }, [passiveCandidatesChecked]);

  const handleToggleSimilarCandidatesSourcing = React.useCallback((): void => {
    setSourceSimilarCandidates(!sourceSimilarCandidates);
  }, [sourceSimilarCandidates]);

  const isRetrieving =
    isRetrieveLoading || isFetchingOutreachStats || isJobMetaLoading || isFetchingoutreachStatsPastTwoToFourWeeks;
  const isRetrievingOrUpdating = isUpdating || isUpdatingOutboundConfig || isRetrieving || isUpdatingJobFeature;
  const deactivateButtonIsDisabled = !isEnabled || isRetrievingOrUpdating || unSnoozingJob;

  useEffect(() => {
    if (typeof emailsPerWeek !== "undefined") {
      setSelectedEmailsPerWeek(emailsPerWeek);
    }
  }, [emailsPerWeek]);

  useEffect(() => {
    if (typeof doverOutboundConfigOption !== "undefined") {
      setSelectedEmailsPerWeekOption(doverOutboundConfigOption);
    }
  }, [doverOutboundConfigOption]);

  useEffect(() => {
    if (phoneScreensPerWeek) {
      setNumInterstedCandidates(phoneScreensPerWeek);
    }
  }, [phoneScreensPerWeek]);

  useEffect(() => {
    if (typeof autoQueueOutreach !== "undefined" && autoQueueOutreach !== null) {
      setSelectedAutopilotOption(autoQueueOutreach);
    }
  }, [autoQueueOutreach]);

  useEffect(() => {
    if (similarCandidatesSourcingTarget !== undefined) {
      setSourceSimilarCandidates(!!similarCandidatesSourcingTarget);
    }
  }, [similarCandidatesSourcingTarget]);

  useEffect(() => {
    if (passiveCandidates && doverOutboundConfigurationSuccess) {
      setPassiveCandidatesChecked(passiveCandidates);
    }
  }, [doverOutboundConfigurationSuccess, passiveCandidates]);

  const closeModalAndReset = React.useCallback(() => {
    toggleModalOff();
    setSelectedEmailsPerWeek(emailsPerWeek);
    setSelectedEmailsPerWeekOption(doverOutboundConfigOption);
    setNumInterstedCandidates(phoneScreensPerWeek ?? 0);
  }, [doverOutboundConfigOption, emailsPerWeek, phoneScreensPerWeek, toggleModalOff]);

  const onClick = React.useCallback(() => {
    onClickDisable();
    toggleModalOff();
  }, [onClickDisable, toggleModalOff]);

  const handleUnsnoozeJob = async (): Promise<void> => {
    await unsnoozeJob({ jobId: jobId! }).unwrap();
  };

  const handlePause = (): void => {
    // unsnooze job
    if (jobIsSnoozed) {
      handleUnsnoozeJob();
    }
    // open snooze job modal
    else {
      setSnoozeModalOpen(ModalKeyType.Snooze);
      toggleModalOff();
    }
  };

  const openCompletionModal = React.useCallback(() => {
    setSnoozeModalOpen(ModalKeyType.Completion);
  }, []);

  const outreachConfigurationTab = showInterestedCandidates ? "Candidate Volume" : "Volume";

  const getTabConfigs = React.useCallback(() => {
    if (showFeedbackMeetingTab) {
      const tabs = [
        {
          label: outreachConfigurationTab,
          value: EMAIL_VOLUME_TAB_QUERY_PARAM_VALUE,
        },
        { label: "Passive Candidates", value: PASSIVE_CANDIDATES_MEETINGS_TAB_QUERY_PARAM_VALUE },
        { label: "Autosend", value: SOURCING_AUTOPILOT_TAB_QUERY_PARAM_VALUE },
        { label: "Similar Candidates", value: SIMILAR_CANDIDATES_TAB_QUERY_PARAM_VALUE },
      ];
      // Only display Calibration Settings for legacy users of Sourcing Autopilot
      if (useLegacySourcingAutopilot) {
        tabs.splice(1, 0, { label: "Calibration Settings", value: CALIBRATION_SETTINGS_TAB_QUERY_PARAM_VALUE });
      }
      return tabs;
    } else if (doverPlan == ClientDoverPlanEnum.Free) {
      return [
        {
          label: outreachConfigurationTab,
          value: EMAIL_VOLUME_TAB_QUERY_PARAM_VALUE,
        },
        { label: "Autosend", value: SOURCING_AUTOPILOT_TAB_QUERY_PARAM_VALUE },
        { label: "Similar Candidates", value: SIMILAR_CANDIDATES_TAB_QUERY_PARAM_VALUE },
      ];
    }

    return []; // It will never return this basecase since all the Dover plans are covered above
  }, [showFeedbackMeetingTab, doverPlan, outreachConfigurationTab, useLegacySourcingAutopilot]);

  const numInterestedCandidatesValid = useMemo(() => {
    Math.ceil(MAX_NUMBER_OF_EMAILS_PER_WEEK * (interestedRate ?? DEFAULT_CANDIDATE_EMAIL_INTEREST_RATE));
    return numInterestedCandidates >= 1;
  }, [numInterestedCandidates, interestedRate]);

  const selectedEmailsPerWeekValid = useMemo(() => {
    return (
      !!selectedEmailsPerWeek && selectedEmailsPerWeek >= 1 && selectedEmailsPerWeek <= MAX_NUMBER_OF_EMAILS_PER_WEEK
    );
  }, [selectedEmailsPerWeek]);

  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={closeModalAndReset}
        title={<Body weight="600">{useLegacySourcingAutopilot ? "Sourcing Autopilot" : "Sourcing"}</Body>}
        maxWidth={"md"}
        customContentStyles={{ padding: "0px" }}
        customDialogStyles={{ zIndex: 9000 }}
        dialogActions={
          <>
            <Box paddingX="16px" paddingY="8px">
              <Button variant={ButtonVariant.SecondaryCritical} disabled={deactivateButtonIsDisabled} onClick={onClick}>
                Deactivate
              </Button>
            </Box>
            {selectedTab === EMAIL_VOLUME_TAB_QUERY_PARAM_VALUE && (
              <>
                <Spacer width="8px" />
                <Box display="flex" paddingX="16px" paddingY="8px">
                  <Button
                    variant={ButtonVariant.Secondary}
                    onClick={closeModalAndReset}
                    disabled={isRetrievingOrUpdating}
                  >
                    Cancel
                  </Button>
                  <Spacer width="8px" />
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={onClickSubmit}
                    disabled={isRetrievingOrUpdating || !numInterestedCandidatesValid || !selectedEmailsPerWeekValid}
                    loading={isUpdating || isUpdatingOutboundConfig || !jobId}
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
            {selectedTab === CALIBRATION_SETTINGS_TAB_QUERY_PARAM_VALUE && !savingCalibrationSettings && (
              <Stack padding="6px 8px" width="100%" direction="row" justifyContent="flex-end">
                <Button
                  variant={ButtonVariant.Primary}
                  onClick={async (): Promise<void> => await onSaveCalibrationSettings()}
                >
                  Save
                </Button>
              </Stack>
            )}
            {(selectedTab === PASSIVE_CANDIDATES_MEETINGS_TAB_QUERY_PARAM_VALUE ||
              selectedTab === SOURCING_AUTOPILOT_TAB_QUERY_PARAM_VALUE ||
              selectedTab === SIMILAR_CANDIDATES_TAB_QUERY_PARAM_VALUE) && (
              <Stack padding="6px 8px" width="100%" direction="row" justifyContent="flex-end">
                <Button
                  variant={ButtonVariant.Primary}
                  onClick={onClickSubmit}
                  disabled={isRetrievingOrUpdating}
                  loading={isUpdating || isUpdatingOutboundConfig}
                >
                  Submit
                </Button>
              </Stack>
            )}
          </>
        }
        dialogActionsStyles={{ justifyContent: "space-between" }}
      >
        {isRetrieving ? (
          <Box display="flex" justifyContent="center">
            <DoverLoadingSpinner minHeight="200px" spinnerSize="64px" width="64px" height="200px" />
          </Box>
        ) : (
          <>
            <TabBar
              selectedTabQueryParamKey={SELECTED_TAB_QUERY_PARAM_KEY}
              tabConfigs={getTabConfigs()}
              $fontSize="16px"
            />
            {selectedTab === CALIBRATION_SETTINGS_TAB_QUERY_PARAM_VALUE &&
              (savingCalibrationSettings ? (
                <DoverLoadingSpinner />
              ) : (
                // Not using the calibration settings modal here since we're already
                // inside of a modal
                <CalibrationSettings initialCalibrationSetting={initialCalibrationSetting} />
              ))}
            {selectedTab === EMAIL_VOLUME_TAB_QUERY_PARAM_VALUE && (
              <DoverEmailVolumeTabContent
                numInterestedCandidates={numInterestedCandidates}
                setNumInterstedCandidates={setNumInterstedCandidates}
                selectedEmailsPerWeek={selectedEmailsPerWeek}
                setSelectedEmailsPerWeek={setSelectedEmailsPerWeek}
                numInterestedCandidatesValid={numInterestedCandidatesValid}
                selectedEmailsPerWeekValid={selectedEmailsPerWeekValid}
              />
            )}
            {selectedTab === PASSIVE_CANDIDATES_MEETINGS_TAB_QUERY_PARAM_VALUE && (
              <Box padding="20px 24px 16px">
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Stack>
                      <Body weight="600">Surface passively interested candidates for review</Body>
                      <Body>
                        <ul>
                          <li>
                            Dover surfaces candidates that have expressed interest in your company but indicate they are
                            not actively looking to change roles.
                          </li>
                          <li>
                            Dover recommends speaking with passive candidates so you can gauge their interest in future
                            opportunities when the timing is better for them (and they may even be open to changing
                            roles earlier for the right opportunity!)
                          </li>
                        </ul>
                      </Body>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <StyledSwitch
                        checked={passiveCandidatesChecked}
                        onChange={handleTogglePassiveCandidates}
                        $checked={passiveCandidatesChecked}
                      />
                      <BodySmall
                        style={{ position: "relative", bottom: "4px" }}
                        color={passiveCandidatesChecked ? colors.success.base : colors.grayscale.gray700}
                      >
                        {passiveCandidatesChecked ? "On" : "Off"}
                      </BodySmall>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            )}
            {selectedTab === SOURCING_AUTOPILOT_TAB_QUERY_PARAM_VALUE && (
              <Box padding="20px 24px 16px">
                <SourcingAutopilotContent
                  selectedAutopilotOption={selectedAutopilotOption}
                  setSelectedAutopilotOption={setSelectedAutopilotOption}
                  showPauseButton={autoQueueOutreach as boolean | undefined}
                  onPause={handlePause}
                  jobId={jobId}
                />
              </Box>
            )}
            {selectedTab === SIMILAR_CANDIDATES_TAB_QUERY_PARAM_VALUE && (
              <Box padding="20px 24px 16px">
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Stack>
                      <Body weight="600">Source similar candidates</Body>
                      <Body>
                        <ul>
                          <li>
                            Dover reaches out to candidates who narrowly fail your search, but have a high degree of
                            similarity to candidates you approved.
                          </li>
                          <li>
                            This allows Dover to expand the candidate pool using candidates similar to those you
                            approve.
                          </li>
                        </ul>
                      </Body>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <StyledSwitch
                        checked={sourceSimilarCandidates}
                        onChange={handleToggleSimilarCandidatesSourcing}
                        $checked={sourceSimilarCandidates}
                      />
                      <BodySmall
                        style={{ position: "relative", bottom: "4px" }}
                        color={sourceSimilarCandidates ? colors.success.base : colors.grayscale.gray700}
                      >
                        {sourceSimilarCandidates ? "On" : "Off"}
                      </BodySmall>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            )}
          </>
        )}
      </CustomModal>
      <CustomModal
        open={isUpdatedVolumeModalOpen}
        onClose={(): void => {
          setIsUpdatedVolumeModalOpen(false);
        }}
        maxWidth={"sm"}
        customContentStyles={{ padding: "0px" }}
        customDialogStyles={{ zIndex: 9000 }}
      >
        <Box padding="20px 24px 16px">
          <Stack spacing={1} alignItems="center">
            <Check />
            <PageTitle>Candidate Volume Updated</PageTitle>
            <Body>Changes will go into effect at the start of next week</Body>
            <Button
              variant={ButtonVariant.Primary}
              onClick={(): void => {
                setIsUpdatedVolumeModalOpen(false);
              }}
            >
              Done
            </Button>
          </Stack>
        </Box>
      </CustomModal>
      <SnoozeJobModal
        shouldShowModal={snoozeModalOpen}
        handleCloseModal={(): void => setSnoozeModalOpen(ModalKeyType.None)}
        handleOpenCompletionModal={openCompletionModal}
        modalKey={ModalKeyType.Snooze}
        jobName={job?.name}
        jobId={jobId}
        snoozeReason={snoozeReason}
        setSnoozeReason={setSnoozeReason}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    </>
  );
};

export default DoverEmailOutreachModal;
