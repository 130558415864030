import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/material";
import React, { useState } from "react";

import { StyledDivider, StyledModal, StyledNewProUserModalPaper } from "components/dover/Campaigns/styles";
import { ModalKeyType } from "components/dover/Campaigns/types";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import DoverLoadingOverlay from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useCreateProUserMutation } from "services/doverapi/endpoints/proUser";
import { DoverUser } from "services/openapi";

interface AddNewProUserModalProps {
  shouldShowModal: string;
  handleCloseModal: () => void;
  handleOpenUpdateOrCreateCampaignModal: () => void;
  modalKey: ModalKeyType;
  setSelectedOtherUser: (selectedProUser: number | undefined) => void;
}

export const AddNewProUserModal = ({
  shouldShowModal,
  handleCloseModal,
  handleOpenUpdateOrCreateCampaignModal,
  modalKey,
  setSelectedOtherUser,
}: AddNewProUserModalProps): React.ReactElement => {
  const [createProUser, { isLoading: isLoading }] = useCreateProUserMutation();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const handleAddNewProUser = async (): Promise<void> => {
    try {
      const proUserResponse: DoverUser = await createProUser({ email, firstName, lastName }).unwrap();
      setSelectedOtherUser(proUserResponse.pk);
      setFirstName("");
      setLastName("");
      setEmail("");
    } catch (error) {
      handleCloseModal();
      handleOpenUpdateOrCreateCampaignModal();
    }
  };

  return (
    <StyledModal open={shouldShowModal === modalKey} onClick={(): void => handleCloseModal()}>
      <DoverLoadingOverlay active={isLoading} overlay={true}>
        <StyledNewProUserModalPaper
          elevation={3}
          onClick={(e): void => {
            e.stopPropagation();
          }}
        >
          <Box py={2} px={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Box>Add New User</Box>
              <Button variant={ButtonVariant.Ghost} onClick={(): void => handleCloseModal()}>
                <CloseIcon />
              </Button>
            </Stack>
            <Spacer height="8px" />
            <StyledDivider />
            <Stack spacing={1} paddingTop="8px">
              <TextField
                title={"First Name"}
                text={firstName}
                onTextUpdated={(updatedText: string): void => {
                  setFirstName(updatedText);
                }}
                fullWidth={true}
              ></TextField>
              <TextField
                title={"Last Name"}
                text={lastName}
                onTextUpdated={(updatedText: string): void => {
                  setLastName(updatedText);
                }}
                fullWidth={true}
              ></TextField>
              <TextField
                title={"Email"}
                text={email}
                onTextUpdated={(updatedText: string): void => {
                  setEmail(updatedText);
                }}
                fullWidth={true}
                helperText="Please enter their work email that will be associated with Dover"
              ></TextField>
            </Stack>
            <Spacer height="16px" />
            <Box display="flex" justifyContent="flex-end">
              <Button variant={ButtonVariant.Secondary} onClick={(): void => handleCloseModal()}>
                Cancel
              </Button>
              <Spacer width="16px" />
              <Button
                variant={ButtonVariant.Primary}
                onClick={async (): Promise<void> => {
                  await handleAddNewProUser();
                  handleCloseModal();
                  handleOpenUpdateOrCreateCampaignModal();
                }}
                disabled={!firstName || !lastName || !email || isLoading}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </StyledNewProUserModalPaper>
      </DoverLoadingOverlay>
    </StyledModal>
  );
};
