import React, { useCallback, useMemo } from "react";

import { Checkbox } from "components/library/Checkbox";
import { CandidateFilterStatusEnum } from "services/openapi";
import { StyledFormControlLabel } from "views/candidates/CandidateTable/filters/styles";
import { useParams } from "views/candidates/hooks";

interface StatusFilterProps {
  label: string;
  value: CandidateFilterStatusEnum;
}

export const CandidateStatusFilter = ({ label, value }: StatusFilterProps): React.ReactElement => {
  const [{ status }, setParams] = useParams();

  const setFromStatusArray = useMemo(() => new Set(status), [status]);

  const handleClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e.target.checked) {
        setFromStatusArray.add(value);
      } else {
        setFromStatusArray.delete(value);
      }
      setParams({ status: Array.from(setFromStatusArray), quickFilter: undefined });
    },
    [setFromStatusArray, setParams, value]
  );

  const isChecked = useMemo(() => setFromStatusArray.has(value), [setFromStatusArray, value]);

  return (
    <StyledFormControlLabel
      control={<Checkbox checked={isChecked} onChange={handleClick} />}
      label={label}
      labelPlacement={"end"}
    />
  );
};
