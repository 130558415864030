import { Divider, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useBoolean } from "react-use";

import { ReactComponent as ArrowDown } from "assets/icons/caret-down-black.svg";
import FormRenderer, { NoSubmitButtonFormTemplate } from "components/data-driven-forms";
import { READ_ONLY_MAPPER } from "components/data-driven-forms/component-mapper";
import { Card } from "components/library/Card";
import { Body, BodySmall } from "components/library/typography";
import { useGetCompanyPitchQuestionsQuery } from "services/doverapi/endpoints/company-setup/endpoints";
import { useGetJobPitchQuestionSchemaQuery } from "services/doverapi/endpoints/jobPitch";

const ReadonlyCompanyAndJobPitchCard = ({
  jobId,
  clientId,
}: {
  jobId?: string;
  clientId?: string;
}): React.ReactElement => {
  const [expanded, setExpanded] = useBoolean(true);

  const { data: companyPitchQuestions, isFetching: isFetchingCompanyPitch } = useGetCompanyPitchQuestionsQuery(
    clientId
      ? {
          clientId,
        }
      : {}
  );
  const { data: jobPitchQuestions, isFetching: isFetchingJobPitch } = useGetJobPitchQuestionSchemaQuery(
    jobId ?? skipToken
  );

  if (isFetchingCompanyPitch || isFetchingJobPitch) {
    return (
      <Card>
        <Skeleton height="90px" width="100%" />
      </Card>
    );
  }

  return (
    <Card>
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{ cursor: "pointer" }}
        onClick={setExpanded}
        mb={expanded ? 2 : 0}
      >
        <ArrowDown style={{ rotate: expanded ? "0deg" : "-90deg", transition: "all 0.2s ease" }} />
        <BodySmall textDecoration="underline">{expanded ? "Hide" : "Show"} company and job pitch</BodySmall>
      </Stack>
      {expanded && (
        <Stack spacing={2} mb={2}>
          {companyPitchQuestions && companyPitchQuestions.schema && (
            <>
              <Body weight="600">Company pitch</Body>
              <FormRenderer
                schema={companyPitchQuestions.schema}
                onSubmit={(): void => {}}
                initialValues={companyPitchQuestions.answers}
                componentMapper={READ_ONLY_MAPPER}
                CustomFormTemplate={NoSubmitButtonFormTemplate}
              />
            </>
          )}
          {jobPitchQuestions && jobPitchQuestions.schema && (
            <>
              <Divider />
              <Body weight="600">Job pitch</Body>
              <FormRenderer
                schema={jobPitchQuestions.schema}
                onSubmit={(): void => {}}
                initialValues={jobPitchQuestions.answers}
                componentMapper={READ_ONLY_MAPPER}
                CustomFormTemplate={NoSubmitButtonFormTemplate}
              />
            </>
          )}
        </Stack>
      )}
    </Card>
  );
};

export default ReadonlyCompanyAndJobPitchCard;
