import { Stack, Switch, TextareaAutosize } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { colors } from "styles/theme";

interface SwitchProps {
  $checked: boolean;
}

export const StyledSwitch = styled(Switch)<SwitchProps>`
  position: relative;
  top: -3px;
  margin-left: -11px;
  margin-top: -10px;
  margin-right: -16px;
  width: 58px;

  .MuiSwitch-switchBase.Mui-checked {
    color: white;
  }

  .MuiSwitch-track {
    opacity: 1 !important;
    height: 20px;
    width: 32px;
    border-radius: 20px;
    background-color: ${(props): string =>
      props.$checked ? `${props.theme.colors.primary.base} !important` : props.theme.colors.grayscale.gray300};
  }

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
    box-shadow: none;
  }
  .MuiSwitch-switchBase {
    top: 7px;
    margin-left: 7px;
    &.Mui-checked {
      margin-left: -1px;
    }
  }
`;

export type WhiteCardProps = typeof Stack & { highlighted?: boolean };

export const WhiteCard = styled(Stack)<WhiteCardProps>`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
  border: ${(props): string =>
    props.highlighted ? `1px solid ${colors.success.base}` : `1px solid ${colors.grayscale.gray200}`};
`;

interface BaseBadgeProps {
  $backgroundColor?: string;
  width?: string;
}

export const BaseBadge = styled(Stack)<BaseBadgeProps>`
  background-color: ${(props): string => props.$backgroundColor ?? colors.primary.light};
  border-radius: 4px;
  padding: 2px 6px;
  width: ${(props): string => props.width ?? "auto"};
  align-items: center;
`;

export const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root {
    border-top: 0;
    border-right: 0;
    border-left: 0;
  }
  .MuiDataGrid-columnSeparator {
    visibility: hidden !important;
  }
  &.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }
`;

export const PointerOnHoverReactSVG = styled(ReactSVG)`
  cursor: pointer;
`;

interface StyledEmailDeactivationFieldProps {
  invalid: boolean;
}

export const StyledDeactivationEmailField = styled(TextareaAutosize)<StyledEmailDeactivationFieldProps>`
  font-size: 12px;
  padding: 10px;
  width: 100%;
  border-color: ${({ invalid }): string => (invalid ? `${colors.critical.base}` : `${colors.grayscale.gray300}`)};
`;

interface StyledSendIconProps {
  $lighterColor: boolean;
}

export const StyledReactSVG = styled(ReactSVG)<StyledSendIconProps>`
  padding-bottom: 3px;
  padding-right: 8px;
  opacity: ${({ $lighterColor }): number => ($lighterColor ? 0.3 : 1)};
`;
