import React from "react";

import { ReactComponent as EyeCrossedOutSVG } from "assets/icons/eye-crossed-out.svg";
import { ReactComponent as EyeSVG } from "assets/icons/eye-new.svg";
import { Tooltip } from "components/library/Tooltip";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";

export const EyeIcon = ({
  height = 16,
  width = 16,
  verticalAlign = "baseline",
}: {
  height?: number;
  width?: number;
  verticalAlign?: string;
}): React.ReactElement => {
  const { data: clientData } = useGetUsersClientQuery();

  return (
    <Tooltip
      title={`Job is public and visible to all members of ${
        clientData?.name ? "the " + clientData?.name : "your hiring"
      } team`}
    >
      <div style={{ height, width }}>
        <EyeSVG color="white" style={{ verticalAlign: verticalAlign }} />
      </div>
    </Tooltip>
  );
};

export const EyeCrossedOutIcon = ({
  height = 16,
  width = 16,
  verticalAlign = "baseline",
}: {
  height?: number;
  width?: number;
  verticalAlign?: string;
}): React.ReactElement => (
  <Tooltip title={"Job is private and only visible to Admins and members of the hiring team"}>
    <div style={{ height, width }}>
      <EyeCrossedOutSVG color="white" style={{ verticalAlign: verticalAlign }} />
    </div>
  </Tooltip>
);
