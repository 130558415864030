import { DraggableAttributes, useDraggable } from "@dnd-kit/core";
import { Button } from "@doverhq/dover-ui";
import { Skeleton, Stack } from "@mui/material";
import React, { forwardRef, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as PaperIcon } from "assets/icons/paper.svg";
import { SocialLink } from "components/dover/SocialLink";
import { Chip } from "components/library/Chip";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { StarCandidateButton } from "components/StarCandidateButton";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import {
  PipelineCandidate,
  PipelineCandidateSchedulingOwnershipEnum,
  PipelineCandidateStatusEnum,
  SocialLinkType,
} from "services/openapi";
import { getLinkedinUrl } from "utils/linkedin";
import { MatchLabel } from "views/candidates/ApplicationReview/components/ApplicantListPanel/MatchLabel";
import { useKanbanListArgs } from "views/candidates/CandidateTable/board/hooks";
import { CandidateCard2 } from "views/candidates/CandidateTable/board/styles";
import { NextUpCell } from "views/candidates/CandidateTable/table/cells/NextUp/NextUpCell";
import { EnrichedNextAction } from "views/candidates/types";

interface CandidateCardProps {
  candidate: PipelineCandidate;
  page?: number;
  disabled?: boolean;
  nextAction?: EnrichedNextAction;
  nextActionLoading?: boolean;
  showMatchLabel?: boolean;
  attributes?: DraggableAttributes;
  listeners?: Record<string, Function> | undefined;
}

// We split this component into 2 pieces because for normal rendering we want to declare the Draggable
// component here, but during the renderClose method on Droppable we will receive the provided props as inputs
// And so we also need an entry point that lets us pass those in directly
export const CandidateCard = memo(
  forwardRef(
    (
      {
        attributes,
        listeners,
        candidate,
        page,
        disabled,
        nextAction,
        nextActionLoading,
        showMatchLabel,
      }: CandidateCardProps,
      ref: React.Ref<HTMLDivElement>
    ): React.ReactElement => {
      const navigate = useNavigate();
      const location = useLocation();

      const jobId = useJobIdFromUrl();

      const candidateListArgs = useKanbanListArgs({
        stageId: candidate.candidatePipelineStage?.id,
        page,
      });

      const linkedInUrl = getLinkedinUrl(candidate);

      const handleCandidateCardClick = (): void => {
        if (!jobId) {
          return;
        }

        navigate(
          APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, candidate.id!, new URLSearchParams(location.search))
        );
      };

      const handleResumeClick = (): void => {
        if (!jobId) {
          return;
        }

        const params = new URLSearchParams(location.search);
        params.set("activeFilter", "resume");
        navigate(APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, candidate.id!, params));
      };

      const isRejected = candidate.status === PipelineCandidateStatusEnum.Rejected;

      const fullName = candidate.contact.fullName;

      return (
        <CandidateCard2
          ref={ref}
          disabled={disabled || !jobId}
          onClick={handleCandidateCardClick}
          {...attributes}
          {...listeners}
        >
          <Stack
            spacing={1}
            // To hide the star button until hover
            sx={{
              "& .hover-star-candidate": {
                visibility: "hidden",
              },
              "&:hover .hover-star-candidate": {
                visibility: "visible",
              },
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={0.5} alignItems="center">
                <BodySmall weight="600">{fullName}</BodySmall>
                <StarCandidateButton
                  removePadding
                  alwaysShowStarred
                  candidate={{
                    id: candidate.id,
                    jobId: candidate.job,
                    fullName: candidate.contact.fullName,
                    isStarred: candidate.isStarred,
                  }}
                  candidateListArgs={candidateListArgs}
                />
              </Stack>
              <Stack direction="row">
                {linkedInUrl && (
                  <div onClick={(e): void => e.stopPropagation()}>
                    <SocialLink
                      linkType={SocialLinkType.LINKEDIN}
                      url={linkedInUrl}
                      style={{ height: "13", width: "13", verticalAlign: "top" }}
                    />
                  </div>
                )}
                <Button icon={{ Icon: PaperIcon, color: "white" }} onPress={handleResumeClick} />
              </Stack>
            </Stack>
            <BodySmall ellipsis>{candidate.contact.headline}</BodySmall>
            {isRejected ? (
              <Chip variant="Critical" label="Rejected" />
            ) : showMatchLabel ? (
              <MatchLabel label={candidate.candidateBucketLabel} />
            ) : nextActionLoading ? (
              <Skeleton variant="rounded" height="24px" width="100px" />
            ) : (
              nextAction && <NextUpCell condensed hideTooltip nextAction={nextAction} candidate={candidate} />
            )}
          </Stack>
        </CandidateCard2>
      );
    }
  )
);

export const DraggableCandidateCard = ({
  candidate,
  page,
  nextAction,
  nextActionLoading,
  showMatchLabel,
}: {
  candidate: PipelineCandidate;
  page: number;
  nextAction?: EnrichedNextAction;
  nextActionLoading?: boolean;
  showMatchLabel?: boolean;
}): React.ReactElement => {
  const isDragDisabled =
    candidate.schedulingOwnership === PipelineCandidateSchedulingOwnershipEnum.DoverHandlesScheduling;

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: candidate.id!,
    disabled: isDragDisabled,
  });

  const conciergeInfo = useConciergeInfo();

  if (isDragging) {
    return <></>;
  }

  return (
    <>
      <Tooltip
        title={
          isDragDisabled
            ? `Recruiting Partner enabled: contact  ${conciergeInfo?.firstName ??
                "your Recruiting Partner"} to move candidate.`
            : ""
        }
      >
        <div>
          <CandidateCard
            ref={setNodeRef}
            candidate={candidate}
            page={page}
            disabled={isDragDisabled}
            nextAction={nextAction}
            nextActionLoading={nextActionLoading}
            showMatchLabel={showMatchLabel}
            attributes={attributes}
            listeners={listeners}
          />
        </div>
      </Tooltip>
    </>
  );
};
