import { Box } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import ThumbsUpIcon from "assets/icons/thumbs-up.svg";
import { CandidateStageSelect } from "components/dover/CandidateStageSelect";
import { Overline } from "components/library/typography";
import { useReplyToSchedulingConfig } from "services/doverapi/cross-endpoint-hooks/useGetSchedulingConfig";
import { isInterestedRespondedStage } from "utils/isStage";
import { Cell, InterestedBadge } from "views/candidates/CandidateTable/table/cells/styles";
import { CellProps } from "views/candidates/CandidateTable/table/cells/types";
import { useParams } from "views/candidates/hooks";

export const CurrentStageCell = ({ candidate }: CellProps): React.ReactElement => {
  const [{ jobId }] = useParams();
  const useReplyToScheduling = useReplyToSchedulingConfig(jobId);

  return (
    <Cell display="flex" alignItems="center">
      {candidate.id && <CandidateStageSelect candidateId={candidate.id} />}
      {useReplyToScheduling &&
        candidate.candidatePipelineStage &&
        isInterestedRespondedStage(candidate.candidatePipelineStage) && (
          <InterestedBadge direction="row" spacing={1}>
            <ReactSVG src={ThumbsUpIcon} />
            <Overline>Interested</Overline>
          </InterestedBadge>
        )}
      <Box flex="1 0 auto" />
    </Cell>
  );
};
