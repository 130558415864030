import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Stack } from "@mui/material";
import React, { useCallback, useMemo } from "react";

import { FilledButton, FilledRedButton, GrayButton } from "components/Button";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

type Show = (callback: (event?: any) => void) => (event?: any) => void;

interface Props {
  title?: string | React.ReactElement;
  children: (show: (callback: (event: any) => void) => (event?: any) => void) => React.ReactNode;
  content: React.ReactNode;
  useProcessing?: boolean;
  processing?: boolean;
  cancelText?: string;
  submitText?: string;
  submitVariant?: "success" | "critical";
  submitDisabled?: boolean;
  onOpen?: Function;
  disabled?: boolean;
  /** If present, this will be called instead of the hide function for cancel button */
  cancelCallback?: Function;
  /** If present, this will be called instead of the show callback for the confirm button */
  callbackOverride?: () => void;
  /*
    Inverses the functionality of the primary and secondary button.
    Use this is you want the primary functionality to be cancel and the secondary functionality to be the callback
    All this really does is reverse the order / color of the buttons, but that can be an important UX in some scenarios
  */
  invertActions?: boolean;
  maxWidth?: DialogProps["maxWidth"];
  fullWidth?: boolean;
}

const ConfirmPrompt: React.FC<Props> = ({
  title,
  disabled = false,
  useProcessing = false,
  processing = false,
  cancelText = "Cancel",
  submitText = "Submit",
  submitVariant = "success",
  submitDisabled = false,
  onOpen,
  content,
  children,
  cancelCallback,
  callbackOverride,
  invertActions = false,
  maxWidth,
  fullWidth,
}) => {
  const [state, setState] = React.useState<{ open: boolean; callback?: () => void }>({ open: false });
  const showModal = Boolean(!disabled && (state.open || (useProcessing && processing)));

  React.useEffect(() => {
    if (callbackOverride !== undefined) {
      setState({ open: state.open, callback: callbackOverride });
    }
  }, [callbackOverride, state.open]);

  const show: Show = callback => {
    const wrappedFn = (event?: any): void => {
      if (event !== undefined) {
        event.preventDefault();

        event = {
          ...event,
          target: { ...event.target, value: event.target.value },
        };
      }

      if (disabled) {
        // if disabled, directly invoke the callback
        callback(event);
      } else {
        setState({
          open: true,
          callback: callbackOverride ?? ((): void => callback(event)),
        });
      }
    };

    if (onOpen) {
      onOpen();
    }

    return wrappedFn;
  };

  const hide = (): void => {
    setState({ open: false });
  };

  const cancel = useCallback((): void => {
    if (cancelCallback) {
      cancelCallback();
    }
    hide();
  }, [cancelCallback]);

  const confirm = useCallback((): void => {
    if (state.callback) {
      state.callback();
    }
    if (useProcessing) {
      setTimeout(hide, 200); // delay slightly in case processing updates slightly late
    } else {
      hide();
    }
  }, [state, useProcessing]);

  const submitButton = useMemo((): React.ReactElement => {
    let Button;
    if (invertActions) {
      Button = GrayButton;
    } else if (submitVariant === "critical") {
      Button = FilledRedButton;
    } else {
      Button = FilledButton;
    }

    return (
      <Button onClick={confirm} disabled={processing || submitDisabled}>
        <B2_doNotUse>{submitText}</B2_doNotUse>
      </Button>
    );
  }, [confirm, invertActions, processing, submitDisabled, submitText, submitVariant]);

  const cancelButton = useMemo((): React.ReactElement => {
    const Button = invertActions ? FilledButton : GrayButton;

    return (
      <Button onClick={cancel}>
        <B2_doNotUse>{cancelText}</B2_doNotUse>
      </Button>
    );
  }, [cancel, cancelText, invertActions]);

  const actionButtons = useMemo(
    (): React.ReactElement =>
      invertActions ? (
        <>
          {submitButton}
          <Spacer width="8px" />
          {cancelButton}
        </>
      ) : (
        <>
          {cancelButton}
          <Spacer width="8px" />
          {submitButton}
        </>
      ),
    [cancelButton, invertActions, submitButton]
  );

  return (
    <>
      {children(show)}

      <Dialog open={showModal} onClose={hide} maxWidth={maxWidth} fullWidth={fullWidth} sx={{ zIndex: 5000 }}>
        {title && (
          <>
            <DialogTitle sx={{ borderBottom: `1px solid ${colors.grayscale.gray300}`, alignItems: "center" }}>
              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                {title}
                <IconButton aria-label="close" onClick={hide} sx={{ color: colors.grayscale.gray500 }}>
                  <CloseIcon />
                </IconButton>
              </Stack>
            </DialogTitle>
            <Spacer height="40" />
          </>
        )}

        <DialogContent>
          {content}

          <Spacer height="24px" />
          <Box borderTop={`1px solid ${colors.grayscale.gray300}`} margin="0px -24px 20px" />
          <Box display="flex" justifyContent="flex-end">
            {actionButtons}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmPrompt;
