/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationQuestionApplicationPortalJob
 */
export interface ApplicationQuestionApplicationPortalJob {
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuestionApplicationPortalJob
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuestionApplicationPortalJob
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuestionApplicationPortalJob
     */
    inputType: ApplicationQuestionApplicationPortalJobInputTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationQuestionApplicationPortalJob
     */
    required: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuestionApplicationPortalJob
     */
    questionType?: ApplicationQuestionApplicationPortalJobQuestionTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationQuestionApplicationPortalJob
     */
    multipleChoiceOptions?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum ApplicationQuestionApplicationPortalJobInputTypeEnum {
    ShortAnswer = 'SHORT_ANSWER',
    LongAnswer = 'LONG_ANSWER',
    MultipleChoice = 'MULTIPLE_CHOICE',
    FileUpload = 'FILE_UPLOAD'
}/**
* @export
* @enum {string}
*/
export enum ApplicationQuestionApplicationPortalJobQuestionTypeEnum {
    LinkedinUrl = 'LINKEDIN_URL',
    Resume = 'RESUME',
    PhoneNumber = 'PHONE_NUMBER',
    Custom = 'CUSTOM'
}

export function ApplicationQuestionApplicationPortalJobFromJSON(json: any): ApplicationQuestionApplicationPortalJob {
    return ApplicationQuestionApplicationPortalJobFromJSONTyped(json, false);
}

export function ApplicationQuestionApplicationPortalJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationQuestionApplicationPortalJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'question': json['question'],
        'inputType': json['input_type'],
        'required': json['required'],
        'questionType': !exists(json, 'question_type') ? undefined : json['question_type'],
        'multipleChoiceOptions': !exists(json, 'multiple_choice_options') ? undefined : json['multiple_choice_options'],
    };
}

export function ApplicationQuestionApplicationPortalJobToJSON(value?: ApplicationQuestionApplicationPortalJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'question': value.question,
        'input_type': value.inputType,
        'required': value.required,
        'question_type': value.questionType,
        'multiple_choice_options': value.multipleChoiceOptions,
    };
}


