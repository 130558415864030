import { Divider, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { Heading } from "components/library/typography";
import { StageDrawer, StageDrawerCloseIcon } from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import { colors } from "styles/theme";
import { useInjectReducer } from "utils/injectReducer";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import { useAllHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";
import { EditStageForm } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/EditStageForm";
import { getFormInitialValues } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/helpers";
import { setupHiringPipelineReducerKey } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/constants";
import { hiringPipelineReducer, setJobId } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/reducer";
import { selectJobId } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/selectors";

interface Props {
  open: boolean;
  onClose: (skipCheckingUnsavedChanges?: boolean) => void;
  stageData: StageDataWrapper;
  clientAtsSetupComplete: boolean;
}

export const EditStageDrawer = ({ open, onClose, stageData, clientAtsSetupComplete }: Props): React.ReactElement => {
  const updateIntercomZIndex = useCallback((showIntercom: boolean): void => {
    const intercomButton = document.getElementsByClassName("intercom-launcher").item(0);
    if (intercomButton) {
      (intercomButton as HTMLElement).style.display = showIntercom ? "block" : "none";
    }
  }, []);

  const dispatch = useDispatch();
  useInjectReducer({ key: setupHiringPipelineReducerKey, reducer: hiringPipelineReducer });

  // note this is copied over from the old scheduling component
  const [jobIdFromUrl] = useJobId();
  // Get jobId from redux state to make sure it's set prior to child components rendering.
  const jobId = useSelector(selectJobId);
  const { data: job } = useGetJobSetupQuery(jobId ?? skipToken);
  useEffect(() => {
    // While we are pasing the URL, the job ID may not be set yet.
    if (!jobIdFromUrl) {
      return;
    }

    // Pass the job id from the url into redux state for easy use within our selectors.
    dispatch(setJobId(jobIdFromUrl));
  }, [dispatch, jobIdFromUrl]);

  const { stages, isFetching } = useAllHps();

  const { jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId: jobId } : skipToken, {
    selectFromResult: ({ data }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
      };
    },
  });

  const heading = stageData.contentTypeName === "UNKNOWN" ? "Edit stage" : "Edit interview";

  const initialValues = useMemo((): any => {
    if (!job || !stages || isFetching) {
      return;
    }

    return getFormInitialValues(job, stages, jobFeatures);
  }, [job, stages, isFetching, jobFeatures]);

  useEffect(() => {
    if (open) {
      updateIntercomZIndex(false);
      return;
    }
    updateIntercomZIndex(true);
  }, [open, updateIntercomZIndex]);

  return (
    <>
      <StageDrawer open={open} onClose={(): void => onClose()} anchor="right" className="stageDrawer">
        <Form
          initialValues={initialValues}
          onSubmit={(): void => {}}
          render={(): React.ReactElement => (
            <Stack
              sx={{ padding: "16px 16px 10px", minHeight: 0 }}
              flexGrow={1}
              divider={
                <Divider
                  orientation="horizontal"
                  flexItem={true}
                  sx={{ color: colors.grayscale.gray200, mx: "-16px" }}
                />
              }
            >
              <Heading>
                {heading}
                <StageDrawerCloseIcon onClick={(): void => onClose()}>+</StageDrawerCloseIcon>
              </Heading>
              <EditStageForm stageData={stageData} onClose={onClose} clientAtsSetupComplete={clientAtsSetupComplete} />
            </Stack>
          )}
        />
      </StageDrawer>
    </>
  );
};
