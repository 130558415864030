import { TextField } from "@mui/material";
import styled from "styled-components";

interface StyledTextFieldProps {
  label?: string;
}

// setting color of error state to match existing TextFields
export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  & .MuiFormHelperText-root.Mui-error {
    color: #f44336;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #f44336;
  }
  & .MuiFormLabel-asterisk.Mui-error {
    color: #f44336;
  }
  & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
  }
  .MuiOutlinedInput-notchedOutline > legend {
    display: ${(props): string => (props.label ? props.label : "")};
  }
  .MuiOutlinedInput-root.MuiInputBase-root {
    outline: none;
    border: none;
  }
`;

interface StyledListProps extends React.HTMLProps<HTMLLIElement> {
  color?: string;
}

export const StyledListItem = styled.li<StyledListProps>`
  color: ${({ color }): string => (color ? `${color}` : "inherit")};
`;
