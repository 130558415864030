import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useGetDoverOutboundConfigurationQuery } from "services/doverapi/endpoints/jobFulfillment";
import { useGetSearchV3DepthResultQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import { UseQueryResult } from "services/doverapi/types";
import { ApiApiGetSearchV3DepthResultRequest } from "services/openapi";

export const CRITICAL_DEPTH_THRESHOLD = 50;

export function useIsOutOfDepth(
  jobId: string | undefined,
  searchParams: ApiApiGetSearchV3DepthResultRequest | undefined
): UseQueryResult<boolean> {
  const {
    data: depthCount,
    isLoading: isLoadingDepthCount,
    isError: isErrorDepthCount,
    isFetching: isFetchingDepthCount,
    isSuccess: isSuccessDepthCount,
    isUninitialized: isUninitializedDepthCount,
  } = useGetSearchV3DepthResultQuery(searchParams ?? skipToken);
  const {
    data: outboundConfig,
    isLoading: isLoadingOutboundConfig,
    isError: isErrorOutboundConfig,
    isFetching: isFetchingOutboundConfig,
    isSuccess: isSuccessOutboundConfig,
    isUninitialized: isUninitializedOutboundConfig,
  } = useGetDoverOutboundConfigurationQuery(jobId || skipToken);

  const isOutOfDepth = useMemo(() => {
    if (outboundConfig?.emailsPerWeekOption) {
      return depthCount && depthCount.count < 2 * outboundConfig.emailsPerWeekOption;
    }

    return depthCount && depthCount.count <= CRITICAL_DEPTH_THRESHOLD;
  }, [depthCount, outboundConfig]);

  return {
    data: isOutOfDepth,
    isLoading: isLoadingDepthCount || isLoadingOutboundConfig,
    isError: isErrorDepthCount || isErrorOutboundConfig,
    isFetching: isFetchingDepthCount || isFetchingOutboundConfig,
    isSuccess: isSuccessDepthCount && isSuccessOutboundConfig,
    isUninitialized: isUninitializedDepthCount && isUninitializedOutboundConfig,
  };
}
