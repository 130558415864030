import React from "react";

import AddSource1 from "assets/images/greenhouse_add-source-1.png";
import AddSource2 from "assets/images/greenhouse_add-source-2.png";
import AddSource3 from "assets/images/greenhouse_add-source-3.png";
import AddSource4 from "assets/images/greenhouse_add-source-4.png";
import CreateApiKey1 from "assets/images/greenhouse_create-api-key-1.png";
import CreateApiKey2 from "assets/images/greenhouse_create-api-key-2.png";
import CreateApiKey3 from "assets/images/greenhouse_create-api-key-3.png";
import CreateApiKey4 from "assets/images/greenhouse_create-api-key-4.png";
import CreateApiKey5 from "assets/images/greenhouse_create-api-key-5.png";
import CreateApiKey6 from "assets/images/greenhouse_create-api-key-6.png";
import GivePermissions1 from "assets/images/greenhouse_give-permissions-1.png";
import GivePermissions2 from "assets/images/greenhouse_give-permissions-2.png";
import GivePermissions3 from "assets/images/greenhouse_give-permissions-3.png";
import GivePermissions4 from "assets/images/greenhouse_give-permissions-4.png";
import { B1_doNotUse } from "styles/typography";

interface InstructionListItem {
  text: string | React.ReactElement;
  imageUrl?: string;
}

/* GREENHOUSE INSTRUCTIONS */

export const addSourceInstructionItems: Readonly<InstructionListItem[]> = [
  {
    text:
      "Click the Configure icon ⚙️ in the upper right-hand corner and select Custom Options from the left-hand panel.",
    imageUrl: AddSource1,
  },
  {
    text: "On the subsequent page, click Sources under the Company Metadata section.",
    imageUrl: AddSource2,
  },
  { text: "On the Manage Sources page, click the Create New Source button.", imageUrl: AddSource3 },
  {
    text: "On the Manage Sources page, click the Create New Source button.",
    imageUrl: AddSource4,
  },
];

export const createApiKeyInstructionItems: Readonly<InstructionListItem[]> = [
  {
    text:
      "Click the Configure icon ⚙️ in the upper right-hand corner. Navigate to Dev Center on the left-hand panel. From the Dev Center page, click API Credential Management.",
    imageUrl: CreateApiKey1,
  },
  {
    text: "From the API Credential Management page, click Create New API key.",
    imageUrl: CreateApiKey2,
  },
  {
    text:
      "From the Create New Credential dialog box, select Harvest from the API Type dropdown menu and add Dover Harvest API Key as the Description. When finished, click Create.",
    imageUrl: CreateApiKey3,
  },
  {
    text:
      "Click the Copy button to copy your API key to your clipboard. This is very important because you will not be able to copy it later.",
    imageUrl: CreateApiKey4,
  },
  {
    text: "Return to this screen and paste in your API key.",
    imageUrl: CreateApiKey5,
  },
  {
    text:
      "After pasting the API key, click the ‘I have stored the API Key’ button and stay on the page for the final step in integrating Greenhouse.",
    imageUrl: CreateApiKey6,
  },
];

export const givePermissionsInstructionItems: Readonly<InstructionListItem[]> = [
  {
    text:
      "On the Manage API Key Permissions page, assign the following permissions to the API key per the screenshot below and click Update.",
    imageUrl: GivePermissions1,
    // TODO: add aria label to image for accessibility: "On the Manage API Key Permissions page, assign the following permissions to the API key: Applications, Candidates (DELETE permissions optional), Tags, Users (DELETE permissions optional), Jobs (DELETE permissions optional), Job Stages, Rejection Reasons, Sources, Prospect Pools. When finished, click Update.",
  },
  {
    text: (
      <div>
        <B1_doNotUse>Cant find the permissions page? Follow these instructions:</B1_doNotUse>
        <br />
        <B1_doNotUse>
          Begin by clicking the Configure icon ⚙️ in the upper right-hand corner. Navigate to Dev Center on the
          left-hand panel. From the Dev Center page, click API Credential Management.{" "}
        </B1_doNotUse>
      </div>
    ),
    imageUrl: GivePermissions2,
  },
  {
    text:
      "From the API Credential Management page, locate the key you wish to edit. Hover your mouse over the key and click the edit icon inline with the key name. ",
    imageUrl: GivePermissions3,
  },
  {
    text:
      "On the subsequent page, make your edits (such as changing the name of the key in the Description field, or adjusting the key's permissions by adding or removing checkmarks from the Manage Permissions section). When finished, click Save at the bottom of the page.",
    imageUrl: GivePermissions4,
  },
];
