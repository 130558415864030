import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useSubmitDecision } from "hooks/useSubmitDecision";
import { ArchiveReasonTypeEnum, CandidateBio, SubmitDecisionRequestDecisionEnum } from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";
import { useGetRejectionReasonByTypeAndDesc } from "views/CandidateDetail/components/next-action/utils/helpers";

export function RejectWithoutEmailButton({ bio }: { bio?: CandidateBio }): React.ReactElement {
  const submitDecision = useSubmitDecision();

  const candidateId = bio?.id;
  const jobId = bio?.job;
  const candidateActionVersion = bio?.actionVersion;
  const rejectNoSkillsArchiveReason = useGetRejectionReasonByTypeAndDesc(
    ArchiveReasonTypeEnum.Rejected,
    "Lacking skills/qualifications"
  );
  const rejectArchiveId = rejectNoSkillsArchiveReason?.id;
  const disabled = !candidateId || !jobId || !candidateActionVersion || !rejectArchiveId;

  const clickReject = useCallback(async () => {
    if (!candidateId || !jobId || !candidateActionVersion || !rejectArchiveId) {
      console.warn(
        "No candidateId, jobId, candidateActionVersion, or reject archive uuid when trying to reject candidate"
      );
      return;
    }

    const args = {
      jobId,
      args: {
        id: candidateId,
        data: {
          candidateActionVersion: candidateActionVersion,
          decision: SubmitDecisionRequestDecisionEnum.Reject,
          archiveReason: rejectArchiveId,
          // these next two args are important - customer manually archived the candidate without providing email content
          emailArgs: null,
          auto: false,
        },
      },
    };

    const submitDecisionPromise = submitDecision(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          pending: "Rejecting candidate...",
          success: "Rejected!",
          error: "Error rejecting",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }, [candidateActionVersion, candidateId, jobId, rejectArchiveId, submitDecision]);

  return (
    <Button variant={ButtonVariant.Ghost} onClick={clickReject} disabled={disabled} width="100%">
      <Box alignItems="flex-start" display="flex">
        <BodySmall color={colors.grayscale.gray600}>Reject without email</BodySmall>
      </Box>
    </Button>
  );
}
