/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RelatedKeyword,
    RelatedKeywordFromJSON,
    RelatedKeywordFromJSONTyped,
    RelatedKeywordToJSON,
} from './';

/**
 * 
 * @export
 * @interface Industry
 */
export interface Industry {
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    canonicalKeyword: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    friendlyName: string;
    /**
     * 
     * @type {string}
     * @memberof Industry
     */
    type?: IndustryTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Industry
     */
    readonly aliases?: Array<string>;
    /**
     * 
     * @type {Array<RelatedKeyword>}
     * @memberof Industry
     */
    relations: Array<RelatedKeyword>;
    /**
     * 
     * @type {number}
     * @memberof Industry
     */
    readonly occurrenceRate?: number;
}

/**
* @export
* @enum {string}
*/
export enum IndustryTypeEnum {
    ProgrammingLanguage = 'PROGRAMMING_LANGUAGE',
    Database = 'DATABASE',
    Framework = 'FRAMEWORK',
    Library = 'LIBRARY',
    Tool = 'TOOL',
    Process = 'PROCESS',
    Skill = 'SKILL',
    TopicOrConcept = 'TOPIC_OR_CONCEPT',
    Industry = 'INDUSTRY'
}

export function IndustryFromJSON(json: any): Industry {
    return IndustryFromJSONTyped(json, false);
}

export function IndustryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Industry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'canonicalKeyword': json['canonical_keyword'],
        'friendlyName': json['friendly_name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'relations': ((json['relations'] as Array<any>).map(RelatedKeywordFromJSON)),
        'occurrenceRate': !exists(json, 'occurrence_rate') ? undefined : json['occurrence_rate'],
    };
}

export function IndustryToJSON(value?: Industry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canonical_keyword': value.canonicalKeyword,
        'friendly_name': value.friendlyName,
        'type': value.type,
        'relations': ((value.relations as Array<any>).map(RelatedKeywordToJSON)),
    };
}


