import { atom } from "jotai";
import { z } from "zod";

import { KeywordsSchema, LocationSchema, MinMaxRangeSchema, PersonaSchema } from "views/sourcing/Search/types";

export interface SharedCriteriaAtom {
  persona?: z.infer<typeof PersonaSchema>;
  jobDescription?: string;
  yearsOfExperience?: z.infer<typeof MinMaxRangeSchema>;
  locations?: z.infer<typeof LocationSchema>[];
  keywords?: z.infer<typeof KeywordsSchema>[];
}

export const sharedCriteriaAtom = atom<SharedCriteriaAtom | undefined>(undefined);
