import { DependencyList, EffectCallback, useEffect, useRef } from "react";

/**
 * Accepts a function that contains imperative, possibly effectful code. Will NOT run on initial render, only runs when dependencies change.
 *
 * @param effect — Imperative function that can return a cleanup function
 * @param deps — If present, effect will only activate if the values in the list change.
 */
export const useOnChange = (effect: EffectCallback, deps?: DependencyList | undefined): void => {
  const isMounted = useRef(false);

  const dependencies = deps ?? [];

  useEffect(() => {
    if (isMounted.current) {
      effect();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
};
