import { CompanyList, Persona, SchoolList, Seniority, Title, TitlePattern } from "services/openapi";

export enum SeniorityCategory {
  Manager = "Manager",
  NonManager = "Non-Manager",
  Miscellaneous = "Miscellaneous",
  // This option is so that multiple toggle selection buttons can control the associated Seniority
  ManagerAndNonManager = "Manager and non-manager",
}

export interface SeniorityExtraInfo {
  listOrder: number;
  category: SeniorityCategory;
}

export type SeniorityWithExtraInfo = Seniority & SeniorityExtraInfo;

export type CompanyListWithRequiredID = Required<Omit<CompanyList, "scopedToClient">> &
  Partial<Pick<CompanyList, "scopedToClient">>;

export interface LocationWithValue {
  name: string;
  value: string;
}
export type SchoolListWithRequiredID = Required<Omit<SchoolList, "scopedToClient">> &
  Partial<Pick<SchoolList, "scopedToClient">>;

export type PersonaIDsRequired = Required<Omit<Persona, "definition">> & Partial<Pick<Persona, "definition">>;
export type TitlePatternIDsRequired = Required<TitlePattern> & { allowSoftMatch: boolean | undefined };
export type TitleIDsRequired = Required<Title>;

export type NewSchoolList = Required<Omit<SchoolList, "id">>;
export type NewCompanyList = Required<Omit<CompanyList, "id">>;
