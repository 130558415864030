import React from "react";

import { ReactComponent as MailSVG } from "assets/icons/mail-dark-outline.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Button, ButtonVariant } from "components/library/Button";
import { useDisableAtsControls } from "hooks/useDisableAtsControls";
import { useDisableCandidateActionsTooltipText } from "hooks/useDisableCandidateActionsTooltipText";
import { CandidateBio } from "services/openapi";

const StartNewEmailThreadButton = ({ candidateBio }: { candidateBio: CandidateBio }): React.ReactElement => {
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();

  const jobId = candidateBio.job;
  const invalidCandidateActionPermsTooltip = useDisableCandidateActionsTooltipText(jobId);
  const invalidCandidateActionPerms = !!invalidCandidateActionPermsTooltip;

  const disabled = useDisableAtsControls(candidateBio.id) || invalidCandidateActionPerms;

  const disabledButtonTooltip = invalidCandidateActionPermsTooltip;
  const enabledButtonTooltip = "Start new email thread";

  return (
    <>
      <div style={{ position: "relative" }}>
        <Button
          variant={ButtonVariant.Secondary}
          disabled={disabled}
          tooltip={disabled ? disabledButtonTooltip : enabledButtonTooltip}
          onClick={(): void => {
            showCandidateActionModal({
              candidateId: candidateBio.id ?? "",
              modalType: CandidateActionModalType.Reply,
              title: "Start New Thread",
            });
          }}
        >
          <MailSVG />
        </Button>
      </div>
    </>
  );
};

export default StartNewEmailThreadButton;
