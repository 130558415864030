import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { GREENHOUSE_DATA } from "services/doverapi/endpointTagsConstants";
import { AtsData } from "services/openapi";

const greenhouseDataEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getGreenhouseData: build.query<AtsData, { greenhouseKey?: string }>({
      queryFn: async ({ greenhouseKey }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getGreenhouseData({ data: { greenhouseKey } });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [GREENHOUSE_DATA];
      },
    }),
  }),
});

export const { useGetGreenhouseDataQuery } = greenhouseDataEndpoints;
