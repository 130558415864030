import { Box, BoxProps } from "@mui/material";
import React, { ReactElement } from "react";

/* -----------------------------------------------------------------------------
 * Circle
 * -------------------------------------------------------------------------- */

export interface CircleProps extends Omit<BoxProps, "width" | "height"> {
  size: BoxProps["width"];
  color?: BoxProps["bgcolor"];
}

export const Circle = ({ size, color, ...props }: CircleProps): ReactElement => {
  const { bgcolor } = props;

  return (
    <Box
      width={size}
      height={size}
      bgcolor={color ?? bgcolor}
      borderRadius="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    />
  );
};
