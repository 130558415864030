import { HiringPipelineStageForm } from "components/SetupHiringPipeline/components/InterviewPlan/constants";
import { HiringStageEmailTemplate, HiringStageEmailTemplateTemplateTypeEnum } from "services/openapi";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";

const editableTemplateTypes = [
  HiringStageEmailTemplateTemplateTypeEnum.Scheduling,
  HiringStageEmailTemplateTemplateTypeEnum.SchedulingSinglepart,
  HiringStageEmailTemplateTemplateTypeEnum.SchedulingMultipart,
  HiringStageEmailTemplateTemplateTypeEnum.SchedulingDoverInterviewer,
  HiringStageEmailTemplateTemplateTypeEnum.SchedulingTakeHome,
  HiringStageEmailTemplateTemplateTypeEnum.Rejection,
];

export const isSchedulingTemplateType = (
  templateType: HiringStageEmailTemplateTemplateTypeEnum | undefined | null
): boolean => {
  if (!templateType) {
    return false;
  }

  return [
    HiringStageEmailTemplateTemplateTypeEnum.Scheduling,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingSinglepart,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingMultipart,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingDoverInterviewer,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingTakeHome,
  ].includes(templateType);
};

export const getTemplatesForHiringStage = (
  templates: HiringStageEmailTemplate[],
  hiringStageId: string
): HiringStageEmailTemplate[] => {
  return templates.filter(t => t.hiringPipelineStage === hiringStageId);
};

export const filterRelevantTemplates = (
  templates: HiringStageEmailTemplate[],
  schedulingTemplateType: HiringStageEmailTemplateTemplateTypeEnum
): HiringStageEmailTemplate[] => {
  return templates
    .filter(t => (t.templateType ? editableTemplateTypes.includes(t.templateType) : false))
    .filter(t => {
      if (!t.templateType) {
        return false;
      }

      // We should only show scheduling templates based on the specific template type
      if (isSchedulingTemplateType(t.templateType)) {
        return schedulingTemplateType === t.templateType;
      }

      return editableTemplateTypes.includes(t.templateType);
    })
    .sort((a, b) => {
      if (
        a.templateType === HiringStageEmailTemplateTemplateTypeEnum.Rejection ||
        a.templateType === HiringStageEmailTemplateTemplateTypeEnum.RejectionInbound
      ) {
        return 1;
      }

      if (a.templateType === b.templateType) {
        if (getTemplateDisplayName(a) < getTemplateDisplayName(b)) {
          return -1;
        }

        return 0;
      }

      if (a.templateType && editableTemplateTypes.includes(a.templateType)) {
        return -1;
      }

      return 1;
    });
};

export const shouldShowInterviewerSenderSameOption = (
  stageData: HiringPipelineStageForm,
  isTakeHome: boolean
): boolean => {
  return isTakeHome || (stageData.doverStage === 500 && !stageData.useDoverInterviewer);
};

export const getSchedulingTemplateTypes = (
  stageData: StageDataWrapper,
  isTakeHome: boolean,
  isMultiPart: boolean
): HiringStageEmailTemplateTemplateTypeEnum => {
  if (stageData.useDoverInterviewer) {
    return HiringStageEmailTemplateTemplateTypeEnum.SchedulingDoverInterviewer;
  }

  if (isTakeHome) {
    return HiringStageEmailTemplateTemplateTypeEnum.SchedulingTakeHome;
  }

  return isMultiPart
    ? HiringStageEmailTemplateTemplateTypeEnum.SchedulingMultipart
    : HiringStageEmailTemplateTemplateTypeEnum.SchedulingSinglepart;
};

export const getTemplateDisplayName = (template: HiringStageEmailTemplate): string => {
  if (!template?.templateType) {
    return "";
  }

  switch (template?.templateType) {
    case HiringStageEmailTemplateTemplateTypeEnum.SchedulingDoverInterviewer:
      return "Scheduling with Dover Interviewer";
    case HiringStageEmailTemplateTemplateTypeEnum.Scheduling:
    case HiringStageEmailTemplateTemplateTypeEnum.SchedulingSinglepart:
      return template.hasSameInterviewerAndEmailSender ? "Scheduling with sender" : "Scheduling with someone else";
    case HiringStageEmailTemplateTemplateTypeEnum.SchedulingMultipart:
      return "Scheduling";
    case HiringStageEmailTemplateTemplateTypeEnum.SchedulingTakeHome:
      return "Scheduling - Take Home";
    case HiringStageEmailTemplateTemplateTypeEnum.Rejection:
    case HiringStageEmailTemplateTemplateTypeEnum.RejectionInbound:
      return "Rejection";
  }

  return "";
};
