/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeactivateJob
 */
export interface DeactivateJob {
    /**
     * 
     * @type {string}
     * @memberof DeactivateJob
     */
    deactivationReason: DeactivateJobDeactivationReasonEnum;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof DeactivateJob
     */
    candidatesToReject?: Array<{ [key: string]: string; }> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeactivateJob
     */
    onlyCancelDiInterviews?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum DeactivateJobDeactivationReasonEnum {
    Hired = 'Hired',
    HiredThroughDover = 'Hired Through Dover',
    HiredOutsideDover = 'Hired Outside Dover',
    ContractEnded = 'Contract Ended',
    ContractEndedAndStillHiring = 'Contract Ended and still hiring',
    ContractEndedAndNoLongerHiring = 'Contract Ended and no longer hiring',
    DePrioritizedJob = 'De-prioritized Job',
    DePrioritizedJobNoLongerHiring = 'De-prioritized Job - No Longer Hiring',
    DePrioritizedJobStillHiring = 'De-prioritized Job - Still Hiring',
    DuplicateJob = 'Duplicate Job',
    Other = 'Other'
}

export function DeactivateJobFromJSON(json: any): DeactivateJob {
    return DeactivateJobFromJSONTyped(json, false);
}

export function DeactivateJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeactivateJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deactivationReason': json['deactivation_reason'],
        'candidatesToReject': !exists(json, 'candidates_to_reject') ? undefined : json['candidates_to_reject'],
        'onlyCancelDiInterviews': !exists(json, 'only_cancel_di_interviews') ? undefined : json['only_cancel_di_interviews'],
    };
}

export function DeactivateJobToJSON(value?: DeactivateJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deactivation_reason': value.deactivationReason,
        'candidates_to_reject': value.candidatesToReject,
        'only_cancel_di_interviews': value.onlyCancelDiInterviews,
    };
}


