import { Stack } from "@mui/material";
import React, { FC } from "react";

import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { Body, BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { H2 } from "styles/typography/headers";
import NewTemplateButton from "views/CompanySetup/components/EmailTemplates/components/NewTemplateButton";
import { CompanySetupSectionConfig } from "views/CompanySetup/constants";

interface SectionWrapperProps {
  config: CompanySetupSectionConfig;
}

const SectionWrapper: FC<SectionWrapperProps> = ({ children, config }) => {
  const isWrappingMembersAndPermissionsPage = config.sectionLabel === "Members and Permissions";

  return (
    <Stack maxWidth={"1400px"} width={"100%"} flexGrow={1} spacing={2}>
      <Stack spacing={1}>
        <Stack width="100%" justifyContent="space-between" direction="row">
          {config.sectionLabel && <H2>{config.sectionLabel}</H2>}
          {config.helpDeskArticle && !isWrappingMembersAndPermissionsPage && (
            <ExternalLink display="inline" href={config.helpDeskArticle} target="_blank" rel="noopener noreferrer">
              <Stack direction="row" spacing={0.5} alignItems="center">
                <HelpIcon color={colors.link} className="svg-color" />
                <Body color={colors.link}>{config.helpDeskArticleLabel}</Body>
              </Stack>
            </ExternalLink>
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack width={config.sectionLabel === "Email Templates" ? "88%" : "100%"}>
            {config.description && <BodySmall color={colors.grayscale.gray600}>{config.description}</BodySmall>}
            {config.helpDeskArticle && isWrappingMembersAndPermissionsPage && (
              <ExternalLink display="inline" href={config.helpDeskArticle} target="_blank" rel="noopener noreferrer">
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <HelpIcon color={colors.link} className="svg-color" />
                  <Body color={colors.link}>{config.helpDeskArticleLabel}</Body>
                </Stack>
              </ExternalLink>
            )}
          </Stack>
          {config.sectionLabel === "Email Templates" && <NewTemplateButton style={{ marginLeft: "auto" }} />}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export default SectionWrapper;
