/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SaveCompanyPitchQuestionSchema
 */
export interface SaveCompanyPitchQuestionSchema {
    /**
     * 
     * @type {object}
     * @memberof SaveCompanyPitchQuestionSchema
     */
    answers: object;
    /**
     * 
     * @type {string}
     * @memberof SaveCompanyPitchQuestionSchema
     */
    setupState?: SaveCompanyPitchQuestionSchemaSetupStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum SaveCompanyPitchQuestionSchemaSetupStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function SaveCompanyPitchQuestionSchemaFromJSON(json: any): SaveCompanyPitchQuestionSchema {
    return SaveCompanyPitchQuestionSchemaFromJSONTyped(json, false);
}

export function SaveCompanyPitchQuestionSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveCompanyPitchQuestionSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answers': json['answers'],
        'setupState': !exists(json, 'setup_state') ? undefined : json['setup_state'],
    };
}

export function SaveCompanyPitchQuestionSchemaToJSON(value?: SaveCompanyPitchQuestionSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answers': value.answers,
        'setup_state': value.setupState,
    };
}


