import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as LightningIcon } from "assets/icons/lightning.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useListHiringPipelineStagesV2Query } from "services/doverapi/endpoints/hiringPipelineStage";
import { colors, screenSizesNumbers } from "styles/theme";
import { useCounts, useInterviewingStages } from "views/candidates/hooks";
import OverviewCard from "views/job/JobSetup/steps/Overview/components/OverviewCard";
import { OverviewCardCount, OverviewCardType } from "views/job/JobSetup/steps/Overview/types";

const CandidatesOverview = (): ReactElement => {
  const jobId = useJobIdFromUrl();
  const freeCustomer = useIsBasePlanCustomer();
  const { counts: candidateCounts, isFetching } = useCounts();
  const interviewStages = useInterviewingStages();
  const { isLoading: isLoadingInterviewStages } = useListHiringPipelineStagesV2Query(jobId ? { jobId } : skipToken);

  const isLoading = isFetching || isLoadingInterviewStages;

  if (!jobId) {
    return <></>;
  }

  const formattedInterviewStages = (interviewStages ?? []).filter(stage => stage.name !== "Offer");
  const offerStageAndCount = (interviewStages ?? []).filter(stage => stage.name === "Offer")[0];

  // Force offer stage to the end of the card
  const candidateCountsByInterviewStage: OverviewCardCount[] = [...formattedInterviewStages, offerStageAndCount].map(
    stage => {
      const hpsId = stage?.id;
      return {
        label: stage?.name,
        count: candidateCounts?.[stage?.name],
        link: APP_ROUTE_PATHS.job.candidates.candidatesTable(jobId, new URLSearchParams({ hpsId: hpsId, page: "0" })),
      };
    }
  );

  return (
    <OverviewCard
      title={OverviewCardType.CANDIDATES}
      titleIcon={<UsersIcon height="16px" width="16px" />}
      cardCounts={candidateCountsByInterviewStage}
      loadingCounts={isLoading}
      footer={freeCustomer ? <UpsellFooter /> : undefined}
      useScrollButtons
    />
  );
};

const UpsellFooter = (): ReactElement => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tabletL));
  const calendlyUrl = useGetCalendlyUrl("app", "job_overview", "solutions_card");
  return (
    <Stack
      alignItems="center"
      direction={smallScreen ? "column" : "row"}
      spacing={smallScreen ? 1 : undefined}
      borderTop={`solid 1px ${colors.grayscale.gray200}`}
      justifyContent="space-between"
      paddingTop="6px"
      marginBottom="-12px !important"
    >
      <Stack direction="row" spacing={1}>
        <LightningIcon className="svg-color" height="16px" width="16px" color={colors.brand} />
        <BodySmall color={colors.grayscale.gray600}>
          Leverage a Dover Recruiting Partner to schedule and screen your candidates
        </BodySmall>
      </Stack>
      <Button
        width={smallScreen ? "100%" : undefined}
        variant={ButtonVariant.SecondarySuccess}
        onClick={(): void => {
          window.open(calendlyUrl, "_blank", "noopener noreferrer");
        }}
      >
        Learn more
      </Button>
    </Stack>
  );
};

export default CandidatesOverview;
