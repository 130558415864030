import { Box } from "@mui/material";
import React from "react";

import { NameAndSocialLinks } from "components/CandidateHeader/NameAndSocialLinks";
import { CandidateBio } from "services/openapi";

export const CandidateHeader = ({ candidateBio }: { candidateBio: CandidateBio }): React.ReactElement => {
  return (
    <Box bgcolor="white" width="100%" paddingTop={3} paddingBottom={4}>
      <NameAndSocialLinks candidateBio={candidateBio} />
    </Box>
  );
};
