/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseCandidatePipelineStage,
    BaseCandidatePipelineStageFromJSON,
    BaseCandidatePipelineStageFromJSONTyped,
    BaseCandidatePipelineStageToJSON,
    HiringPipelineStageMilestone,
    HiringPipelineStageMilestoneFromJSON,
    HiringPipelineStageMilestoneFromJSONTyped,
    HiringPipelineStageMilestoneToJSON,
    HiringPipelineStageType,
    HiringPipelineStageTypeFromJSON,
    HiringPipelineStageTypeFromJSONTyped,
    HiringPipelineStageTypeToJSON,
    LeadCandidatePipelineStageAllOf,
    LeadCandidatePipelineStageAllOfFromJSON,
    LeadCandidatePipelineStageAllOfFromJSONTyped,
    LeadCandidatePipelineStageAllOfToJSON,
    LeadSubstageEnum,
    LeadSubstageEnumFromJSON,
    LeadSubstageEnumFromJSONTyped,
    LeadSubstageEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeadCandidatePipelineStage
 */
export interface LeadCandidatePipelineStage extends BaseCandidatePipelineStage {
    /**
     * 
     * @type {string}
     * @memberof LeadCandidatePipelineStage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadCandidatePipelineStage
     */
    name: string;
    /**
     * 
     * @type {HiringPipelineStageType}
     * @memberof LeadCandidatePipelineStage
     */
    stageType: HiringPipelineStageType;
    /**
     * 
     * @type {HiringPipelineStageMilestone}
     * @memberof LeadCandidatePipelineStage
     */
    milestone: HiringPipelineStageMilestone | null;
    /**
     * 
     * @type {number}
     * @memberof LeadCandidatePipelineStage
     */
    orderIndex?: number;
    /**
     * 
     * @type {LeadSubstageEnum}
     * @memberof LeadCandidatePipelineStage
     */
    substage: LeadSubstageEnum;
}

export function LeadCandidatePipelineStageFromJSON(json: any): LeadCandidatePipelineStage {
    return LeadCandidatePipelineStageFromJSONTyped(json, false);
}

export function LeadCandidatePipelineStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadCandidatePipelineStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BaseCandidatePipelineStageFromJSONTyped(json, ignoreDiscriminator),
        'id': json['id'],
        'name': json['name'],
        'stageType': HiringPipelineStageTypeFromJSON(json['stage_type']),
        'milestone': HiringPipelineStageMilestoneFromJSON(json['milestone']),
        'orderIndex': !exists(json, 'order_index') ? undefined : json['order_index'],
        'substage': LeadSubstageEnumFromJSON(json['substage']),
    };
}

export function LeadCandidatePipelineStageToJSON(value?: LeadCandidatePipelineStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BaseCandidatePipelineStageToJSON(value),
        'id': value.id,
        'name': value.name,
        'stage_type': HiringPipelineStageTypeToJSON(value.stageType),
        'milestone': HiringPipelineStageMilestoneToJSON(value.milestone),
        'order_index': value.orderIndex,
        'substage': LeadSubstageEnumToJSON(value.substage),
    };
}


