/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModifiedFilterField
 */
export interface ModifiedFilterField {
    /**
     * 
     * @type {number}
     * @memberof ModifiedFilterField
     */
    maxDaysAgo?: number;
    /**
     * 
     * @type {number}
     * @memberof ModifiedFilterField
     */
    minDaysAgo?: number;
}

export function ModifiedFilterFieldFromJSON(json: any): ModifiedFilterField {
    return ModifiedFilterFieldFromJSONTyped(json, false);
}

export function ModifiedFilterFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifiedFilterField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxDaysAgo': !exists(json, 'max_days_ago') ? undefined : json['max_days_ago'],
        'minDaysAgo': !exists(json, 'min_days_ago') ? undefined : json['min_days_ago'],
    };
}

export function ModifiedFilterFieldToJSON(value?: ModifiedFilterField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'max_days_ago': value.maxDaysAgo,
        'min_days_ago': value.minDaysAgo,
    };
}


