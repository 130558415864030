/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchV3CandidateFeedback,
    SearchV3CandidateFeedbackFromJSON,
    SearchV3CandidateFeedbackFromJSONTyped,
    SearchV3CandidateFeedbackToJSON,
    SearchV3Params,
    SearchV3ParamsFromJSON,
    SearchV3ParamsFromJSONTyped,
    SearchV3ParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchV3
 */
export interface SearchV3 {
    /**
     * 
     * @type {string}
     * @memberof SearchV3
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchV3
     */
    name: string;
    /**
     * Please use Search Builder to update this flag.
     * @type {boolean}
     * @memberof SearchV3
     */
    active?: boolean;
    /**
     * 
     * @type {SearchV3Params}
     * @memberof SearchV3
     */
    v3Params: SearchV3Params;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchV3
     */
    readonly clientLevelCompanyExclusions?: Array<string>;
    /**
     * 
     * @type {Array<SearchV3CandidateFeedback>}
     * @memberof SearchV3
     */
    v3CandidateFeedback?: Array<SearchV3CandidateFeedback>;
    /**
     * 
     * @type {string}
     * @memberof SearchV3
     */
    job: string;
    /**
     * 
     * @type {string}
     * @memberof SearchV3
     */
    readonly jobName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchV3
     */
    readonly clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchV3
     */
    client: string;
    /**
     * 
     * @type {number}
     * @memberof SearchV3
     */
    version?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchV3
     */
    autoDepthAdjustmentsEnabled?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof SearchV3
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchV3
     */
    readonly modified?: Date;
    /**
     * 
     * @type {number}
     * @memberof SearchV3
     */
    readonly numContacted?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchV3
     */
    searchType?: SearchV3SearchTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchV3
     */
    readonly campaignIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchV3
     */
    readonly createdBy?: string;
}

/**
* @export
* @enum {string}
*/
export enum SearchV3SearchTypeEnum {
    UserDefined = 'USER_DEFINED',
    Outbound = 'OUTBOUND',
    Inbound = 'INBOUND',
    Referrals = 'REFERRALS',
    Dtn = 'DTN'
}

export function SearchV3FromJSON(json: any): SearchV3 {
    return SearchV3FromJSONTyped(json, false);
}

export function SearchV3FromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchV3 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'v3Params': SearchV3ParamsFromJSON(json['v3_params']),
        'clientLevelCompanyExclusions': !exists(json, 'client_level_company_exclusions') ? undefined : json['client_level_company_exclusions'],
        'v3CandidateFeedback': !exists(json, 'v3_candidate_feedback') ? undefined : ((json['v3_candidate_feedback'] as Array<any>).map(SearchV3CandidateFeedbackFromJSON)),
        'job': json['job'],
        'jobName': !exists(json, 'job_name') ? undefined : json['job_name'],
        'clientName': !exists(json, 'client_name') ? undefined : json['client_name'],
        'client': json['client'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'autoDepthAdjustmentsEnabled': !exists(json, 'auto_depth_adjustments_enabled') ? undefined : json['auto_depth_adjustments_enabled'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'numContacted': !exists(json, 'num_contacted') ? undefined : json['num_contacted'],
        'searchType': !exists(json, 'search_type') ? undefined : json['search_type'],
        'campaignIds': !exists(json, 'campaign_ids') ? undefined : json['campaign_ids'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
    };
}

export function SearchV3ToJSON(value?: SearchV3 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'active': value.active,
        'v3_params': SearchV3ParamsToJSON(value.v3Params),
        'v3_candidate_feedback': value.v3CandidateFeedback === undefined ? undefined : ((value.v3CandidateFeedback as Array<any>).map(SearchV3CandidateFeedbackToJSON)),
        'job': value.job,
        'client': value.client,
        'version': value.version,
        'auto_depth_adjustments_enabled': value.autoDepthAdjustmentsEnabled,
        'search_type': value.searchType,
    };
}


