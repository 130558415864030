import { StringParam, DecodedValueMap, createEnumParam } from "use-query-params";

import { AdminCampaignStateEnum } from "services/openapi";

export const queryParamConfig = {
  client: StringParam,
  job: StringParam,
  q: StringParam,
  persona: StringParam,
  state: createEnumParam(Object.values(AdminCampaignStateEnum)),
};

export type ViewQueryParams = DecodedValueMap<typeof queryParamConfig>;
