import { ListCampaignSetupStateEnum, ListCampaignStateEnum } from "services/openapi/models/ListCampaign";
import { CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";

export function convertFloatToPercentWithOneDecimal(float: number): string {
  return (float * 100).toFixed(1) + "%";
}

export function getCampaignStatus(
  state: ListCampaignStateEnum,
  setupState: ListCampaignSetupStateEnum
): CampaignStatus {
  /* A campaign has the status of Draft until it is STARTED_PENDING_DOVER_ACTION, when it has the Processing
  status for QA. Once Complete, its status is either Active or Inactive */
  if (
    [ListCampaignSetupStateEnum.StartedPendingClientAction, ListCampaignSetupStateEnum.Pending].includes(setupState)
  ) {
    return CampaignStatus.Draft;
  } else if (setupState === ListCampaignSetupStateEnum.StartedPendingDoverAction) {
    return CampaignStatus.Processing;
  } else if (state === ListCampaignStateEnum.Active) {
    return CampaignStatus.Active;
  }
  return CampaignStatus.Inactive;
}
