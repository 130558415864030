import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { default as MuiCheckbox } from "@mui/material/Checkbox";
import React from "react";

import { CheckboxStyles, CheckboxLabelStyles } from "components/inputs/MultiSelect/styles";
import { Option } from "components/inputs/MultiSelect/types";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  option: Option;
  selected: boolean;
}

const Checkbox = ({ option, selected }: Props): React.ReactElement => {
  return (
    <CheckboxStyles>
      <MuiCheckbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
      <CheckboxLabelStyles>{option.label}</CheckboxLabelStyles>
    </CheckboxStyles>
  );
};

export default Checkbox;
