import React from "react";

import { StyledFormLabel } from "components/inputs/common";

export default ({ label, required }: { label: string; required?: boolean }): React.ReactElement => (
  <StyledFormLabel>
    {label}
    {required ? " *" : null}
  </StyledFormLabel>
);
