import React from "react";

import { ComponentStatus } from "components/dover/hooks";
import { JobFeatureGatedBannerBulletList } from "components/dover/JobFeatureGating/JobFeatureGatedBannerBulletList";
import { Banner, BannerVariant } from "components/library/Banner";
import { useGetFeatureShouldBeEnabled } from "services/doverapi/cross-endpoint-hooks/useGetFeatureShouldBeEnabled";
import { JobFeatureFeatureNameEnum } from "services/openapi";

interface JobFeatureGatedBannerProps {
  jobId: string;
  jobFeatureName: JobFeatureFeatureNameEnum;
  setComponentStatus?: (newStatus: ComponentStatus) => void;
}

export const JobFeatureGatedBanner = ({
  jobId,
  jobFeatureName,
  setComponentStatus,
}: JobFeatureGatedBannerProps): React.ReactElement | null => {
  const featureShouldBeEnabled = useGetFeatureShouldBeEnabled(jobFeatureName, jobId);

  React.useEffect(() => {
    if (!setComponentStatus) {
      return;
    }

    if (featureShouldBeEnabled) {
      setComponentStatus(ComponentStatus.EMPTY);
      return;
    }

    setComponentStatus(ComponentStatus.READY_TO_RENDER);
  }, [featureShouldBeEnabled, setComponentStatus]);

  if (featureShouldBeEnabled) {
    return null;
  }

  return (
    <Banner variant={BannerVariant.Warning}>
      <JobFeatureGatedBannerBulletList jobFeatureName={jobFeatureName} />
    </Banner>
  );
};
