/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetFeatureFlagsByCandidateRequest
 */
export interface GetFeatureFlagsByCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof GetFeatureFlagsByCandidateRequest
     */
    candidateId: string;
}

export function GetFeatureFlagsByCandidateRequestFromJSON(json: any): GetFeatureFlagsByCandidateRequest {
    return GetFeatureFlagsByCandidateRequestFromJSONTyped(json, false);
}

export function GetFeatureFlagsByCandidateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFeatureFlagsByCandidateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
    };
}

export function GetFeatureFlagsByCandidateRequestToJSON(value?: GetFeatureFlagsByCandidateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
    };
}


