import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { GmailAuthButton } from "components/dover/GmailAuth";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { useCandidateId } from "hooks/useCandidateId";
import { useDisableAtsControls } from "hooks/useDisableAtsControls";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { ActivityFeedItem } from "services/openapi";
import { useShowReplyInThreadOption } from "views/CandidateDetail/components/event-feed/hooks";

export const ReplyButton = ({ event }: { event: ActivityFeedItem }): React.ReactElement => {
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();

  const { showReplyInThreadButton, showAuthButton: showAuthButton } = useShowReplyInThreadOption({
    eventId: event.eventId,
  });

  const candidateId = useCandidateId();
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);

  const disableReplyButton = useDisableAtsControls(candidateId);

  if (!showReplyInThreadButton) {
    return <></>;
  }

  if (showAuthButton) {
    return (
      <Box marginTop="8px">
        <GmailAuthButton />
      </Box>
    );
  }

  return (
    <Tooltip
      title={
        disableReplyButton
          ? `Contact your Recruiting Partner to make changes or select "I'll handle from here"`
          : undefined
      }
    >
      <Box marginTop="8px" width="min-content" className="reply-button">
        <Button
          variant={ButtonVariant.Secondary}
          disabled={disableReplyButton}
          onClick={(e): void => {
            e.stopPropagation();
            showCandidateActionModal({
              candidateId: candidateBio?.id ?? "",
              modalType: CandidateActionModalType.Reply,
              title: "Reply",
              emailEventDetails: event.emailEventDetails,
            });
          }}
        >
          Reply
        </Button>
      </Box>
    </Tooltip>
  );
};
