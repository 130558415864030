/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSearchParamsFromJDRequest
 */
export interface GetSearchParamsFromJDRequest {
    /**
     * 
     * @type {string}
     * @memberof GetSearchParamsFromJDRequest
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSearchParamsFromJDRequest
     */
    jdText: string;
    /**
     * 
     * @type {string}
     * @memberof GetSearchParamsFromJDRequest
     */
    jobId: string;
}

export function GetSearchParamsFromJDRequestFromJSON(json: any): GetSearchParamsFromJDRequest {
    return GetSearchParamsFromJDRequestFromJSONTyped(json, false);
}

export function GetSearchParamsFromJDRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSearchParamsFromJDRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'jdText': json['jd_text'],
        'jobId': json['job_id'],
    };
}

export function GetSearchParamsFromJDRequestToJSON(value?: GetSearchParamsFromJDRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_title': value.jobTitle,
        'jd_text': value.jdText,
        'job_id': value.jobId,
    };
}


