import { Progress } from "@doverhq/dover-ui";
import { Box, createTheme, Stack, ThemeProvider, Tooltip } from "@mui/material";
import React, { useImperativeHandle } from "react";
import styled from "styled-components";

import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import Toggle from "components/library/Toggle";
import { Body, BodySmall, Heading, Subtitle1 } from "components/library/typography";
import { useEditableApplicationQuestions } from "hooks/useEditableApplicationQuestions";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { ApplicationQuestionInputTypeEnum, ApplicationQuestionQuestionTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { EditableQuestion } from "views/job/JobSetup/steps/JobPosting/components/EditableApplicationForm/EditableQuestion";
import { ApplicationFormQuestion } from "views/job/JobSetup/steps/JobPosting/components/EditableApplicationForm/types";

const Question = ({
  question,
  index,
  updateApplicationQuestion,
}: {
  question: ApplicationFormQuestion;
  index: number;
  updateApplicationQuestion: (question: ApplicationFormQuestion, index: number) => void;
}): React.ReactElement => {
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          track: {
            ".Mui-checked.Mui-checked + &": {
              opacity: ".5 !important",
              backgroundColor: `${colors.primary.base} !important`,
            },
          },
        },
      },
    },
  });

  if (question.status === "deleted") {
    return <></>;
  }
  if (question.editable) {
    return <EditableQuestion question={question} index={index} updateApplicationQuestion={updateApplicationQuestion} />;
  }
  return (
    <ThemeProvider theme={theme}>
      <QuestionContainer direction="row" justifyContent="space-between" padding="16px 0" alignItems="center">
        <Body>
          {question.question}
          {question.required && <span style={{ color: colors.critical.base }}>*</span>}
        </Body>
        <Tooltip title="This question is always required" placement="top">
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Toggle checked={true} disabled={true} label={`question-required-${index}`} />
            <BodySmall>Required</BodySmall>
          </Stack>
        </Tooltip>
      </QuestionContainer>
    </ThemeProvider>
  );
};

interface EditableApplicationFormQuestionsProps {
  editedApplicationQuestions: ApplicationFormQuestion[];
  setEditedApplicationQuestions: (editedApplicationQuestions: ApplicationFormQuestion[]) => void;
  fetchingApplicationQuestions: boolean;
  onSetFormDirty?: (isDirty: boolean) => void;
}

export const EditableApplicationFormQuestions = ({
  editedApplicationQuestions,
  setEditedApplicationQuestions,
  fetchingApplicationQuestions,
  onSetFormDirty,
}: EditableApplicationFormQuestionsProps): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  const updateApplicationQuestion = (question: ApplicationFormQuestion, index: number): void => {
    const newApplicationQuestions = [...editedApplicationQuestions];
    newApplicationQuestions[index] = question;

    // If the question is new, we don't need to update the status
    newApplicationQuestions[index].status = question.status || "updated";
    setEditedApplicationQuestions(newApplicationQuestions);
    onSetFormDirty?.(true);
  };

  const addApplicationQuestion = (): void => {
    if (!jobId) {
      return;
    }
    const newApplicationQuestions = [...editedApplicationQuestions];
    newApplicationQuestions.push({
      jobId,
      question: "",
      inputType: ApplicationQuestionInputTypeEnum.ShortAnswer,
      questionType: ApplicationQuestionQuestionTypeEnum.Custom,
      required: false,
      editable: true,
      status: "new",
      orderIndex: newApplicationQuestions.filter(
        question =>
          question.status !== "deleted" && question.questionType === ApplicationQuestionQuestionTypeEnum.Custom
      ).length,
    });
    setEditedApplicationQuestions(newApplicationQuestions);
    onSetFormDirty?.(true);
  };
  if (fetchingApplicationQuestions) {
    return <Progress size="large" />;
  }

  return (
    <>
      <Box>
        {editedApplicationQuestions.map((question, index) => (
          <Question
            key={`application-question-${index}`}
            question={question}
            index={index}
            updateApplicationQuestion={updateApplicationQuestion}
          />
        ))}
      </Box>
      <AddQuestionButton variant={ButtonVariant.Secondary} onClick={addApplicationQuestion}>
        <Subtitle1 color={colors.linkLight}>+ Add Question</Subtitle1>
      </AddQuestionButton>
    </>
  );
};

export interface EditableApplicationFormRef {
  saveApplicationQuestions: (showToast?: boolean) => void;
}

interface EditableApplicationFormProps {
  enableSave?: boolean;
  id?: string;
  onSetFormDirty?: (isDirty: boolean) => void;
}

const EditableApplicationForm = React.forwardRef<EditableApplicationFormRef, EditableApplicationFormProps>(
  ({ id, onSetFormDirty, enableSave = true }: EditableApplicationFormProps, ref): React.ReactElement => {
    const {
      editedApplicationQuestions,
      setEditedApplicationQuestions,
      saveDisabled,
      hasDuplicateQuestion,
      saveApplicationQuestions,
      fetchingApplicationQuestions,
    } = useEditableApplicationQuestions();

    useImperativeHandle(ref, () => ({
      saveApplicationQuestions,
    }));

    return (
      <Card padding="25px" id={id}>
        <Stack spacing={2}>
          <Box>
            <Heading>Application Form</Heading>
          </Box>
          <EditableApplicationFormQuestions
            editedApplicationQuestions={editedApplicationQuestions}
            setEditedApplicationQuestions={setEditedApplicationQuestions}
            fetchingApplicationQuestions={fetchingApplicationQuestions}
            onSetFormDirty={onSetFormDirty}
          />
          {enableSave && (
            <Stack direction="row">
              <Tooltip title={hasDuplicateQuestion ? "Please remove duplicate questions" : ""} placement="top">
                <Box>
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={(): Promise<void> => saveApplicationQuestions()}
                    disabled={saveDisabled}
                  >
                    Save
                  </Button>
                </Box>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Card>
    );
  }
);

const QuestionContainer = styled(Stack)`
  border-bottom: solid 1px ${colors.grayscale.gray200};
  padding: 16px 0;
`;

const AddQuestionButton = styled(Button)`
  background-color: ${colors.grayscale.gray100};
  border: dashed 1px ${colors.grayscale.gray300};
  border-radius: 0;
`;

export default EditableApplicationForm;
