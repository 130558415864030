/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalApplicantResponse
 */
export interface ExternalApplicantResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ExternalApplicantResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantResponse
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantResponse
     */
    inboundApplicationUrl?: string;
}

export function ExternalApplicantResponseFromJSON(json: any): ExternalApplicantResponse {
    return ExternalApplicantResponseFromJSONTyped(json, false);
}

export function ExternalApplicantResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalApplicantResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'message': json['message'],
        'inboundApplicationUrl': !exists(json, 'inbound_application_url') ? undefined : json['inbound_application_url'],
    };
}

export function ExternalApplicantResponseToJSON(value?: ExternalApplicantResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'message': value.message,
        'inbound_application_url': value.inboundApplicationUrl,
    };
}


