import { Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useParams } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import EmbeddedRecruiterPng from "assets/images/embedded-recruiter.png";
import DeactivateEndToEndSchedulingModal from "components/dover/feature-toggle-handler/DeactivateEndToEndSchedulingModal";
import {
  JobFeatureSettingToggleHandler,
  JobFeatureSettingToggleHandlerContext,
} from "components/dover/feature-toggle-handler/types";
import { PremiumFeatureModal } from "components/dover/job-feature-card/PremiumFeatureModal";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import PipelineReviewModal from "components/PipelineReviewModal";
import { useGetJobFeatureUIState } from "services/doverapi/cross-endpoint-hooks/useGetJobFeatureUIState";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetJobSlackUrl } from "services/doverapi/endpoints/job/hooks";
import { JobFeatureUIState } from "services/doverapi/endpoints/job/types";
import {
  useGetJobFeaturesQuery,
  useUpsertJobFeatureSettingMutation,
} from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import {
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { ConciergeProfile, SlackIconVariant } from "views/CandidateDetail/components/next-action/ConciergeProfile";
import { CardBadge } from "views/job/JobSetup/steps/Overview/components/CardBadge";
import { TemplateCard } from "views/job/JobSetup/steps/Overview/components/DoverSolutionsTemplateCard";

const EmbeddedRecruiterDefaultBody = (): React.ReactElement => {
  return (
    <BodySmall color={colors.grayscale.gray500}>
      Accelerate your hiring with an expert Recruiting Partner. With experience driving results at dozens of
      fast-growing companies across both technical and go-to-market positions, your Recruiting Partner can offer
      strategic guidance or take full ownership of the recruitment process.
    </BodySmall>
  );
};

const EmbeddedRecruiterImg = (): React.ReactElement => {
  return <img src={EmbeddedRecruiterPng} style={{ width: "100%" }} />;
};

const EMBEDDED_RECRUITER_TITLE = "Recruiting Partner";

export const EmbeddedRecruiterCard = (): React.ReactElement => {
  const isBasePlanCustomer = useIsBasePlanCustomer();

  // mutations
  const [upsertJobFeatureSetting] = useUpsertJobFeatureSettingMutation();

  // params
  const { jobId } = useParams<{ jobId: string }>();

  // fetch data
  const { data: job } = useGetJobQuery(jobId ? jobId : skipToken);
  const { jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
      };
    },
  });

  // other
  const theme = useTheme();
  const largerThanLaptop = !useMediaQuery(theme.breakpoints.down(screenSizesNumbers.laptopM));

  // local state
  const [isDeactivateConfirmationModalOpen, setIsDeactivateConfirmationModalOpen] = React.useState<boolean>(false);
  const [isPipelineReviewModalOpen, setIsPipelineReviewModalOpen] = React.useState<boolean>(false);
  const [premiumFeatureModalOpen, setPremiumFeatureModalOpen] = React.useState<boolean>(false);

  const jobFeatureToggleHandler = React.useContext<JobFeatureSettingToggleHandler | undefined>(
    JobFeatureSettingToggleHandlerContext
  );

  // callbacks
  const toggleDeactivationModalOn = React.useCallback(() => {
    jobFeatureToggleHandler?.onFeatureToggled({
      featureName: UpsertJobFeatureSettingFeatureNameEnum.E2EScheduling,
      desiredFeatureState: UpsertJobFeatureSettingStateEnum.Disabled,
    });
  }, [jobFeatureToggleHandler]);

  const toggleE2EScheduling = React.useCallback(
    (enabled: boolean) => {
      upsertJobFeatureSetting({
        upsertData: {
          data: {
            job: jobId!,
            featureName: UpsertJobFeatureSettingFeatureNameEnum.E2EScheduling,
            state: enabled ? UpsertJobFeatureSettingStateEnum.Enabled : UpsertJobFeatureSettingStateEnum.Disabled,
          },
        },
      });
    },
    [jobId, upsertJobFeatureSetting]
  );

  const toggleDeactivationModalOff = React.useCallback(() => {
    setIsDeactivateConfirmationModalOpen(false);
  }, []);
  const jobFeatureUIState = useGetJobFeatureUIState({
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
    jobId,
    allowAutoEnablement: true,
  });

  const togglePremiumFeatureModalOff = React.useCallback(() => {
    setPremiumFeatureModalOpen(false);
  }, []);

  const togglePipelineReviewModalOff = React.useCallback(() => {
    setIsPipelineReviewModalOpen(false);
  }, []);

  // derived data
  const isDoverInterviewerEnabled = !!jobFeatures?.[JobFeatureFeatureNameEnum.DoverInterviewer];
  const embeddedRecruiterActive = !!jobFeatures?.[JobFeatureFeatureNameEnum.E2EScheduling];
  const featureActiveAndSetup = embeddedRecruiterActive && jobFeatureUIState === JobFeatureUIState.Active;

  const getFeatureStateBadge = React.useCallback(() => {
    return embeddedRecruiterActive ? (
      <CardBadge jobFeatureUIState={JobFeatureUIState.Active} />
    ) : (
      <CardBadge jobFeatureUIState={JobFeatureUIState.Inactive} />
    );
  }, [embeddedRecruiterActive]);

  const shouldShowImage = React.useMemo(() => {
    return largerThanLaptop && isBasePlanCustomer;
  }, [isBasePlanCustomer, largerThanLaptop]);

  // memoized components

  const ActionsButton = React.useMemo(() => {
    if (embeddedRecruiterActive) {
      return (
        <Button
          variant={ButtonVariant.SecondaryCritical}
          onClick={(): void => {
            toggleDeactivationModalOn();
          }}
          disabled={isDoverInterviewerEnabled}
          tooltip={
            isDoverInterviewerEnabled
              ? "Please disable Dover Interviewer before turning off Recruiting Partner"
              : undefined
          }
        >
          Disable
        </Button>
      );
    } else {
      return (
        <Button
          variant={ButtonVariant.SecondarySuccess}
          disabled={job?.isSample}
          tooltip={job?.isSample ? "This feature is not available for sample jobs" : undefined}
          onClick={(): void => {
            toggleE2EScheduling(true);
          }}
        >
          Enable
        </Button>
      );
    }
  }, [
    job?.isSample,
    embeddedRecruiterActive,
    isDoverInterviewerEnabled,
    toggleE2EScheduling,
    toggleDeactivationModalOn,
  ]);

  const jobSlackUrl = useGetJobSlackUrl(jobId);

  const InnerBody = React.useMemo(() => {
    if (embeddedRecruiterActive && !isBasePlanCustomer && jobFeatureUIState === JobFeatureUIState.Active) {
      return (
        <Stack spacing={2}>
          <BodySmall color={colors.grayscale.gray500} italic>
            Talk to your Recruiting Partner for candidate communications, scheduling, and strategic recruiting help
          </BodySmall>
          <Box
            sx={{
              borderRadius: "3px",
              backgroundColor: colors.grayscale.gray100,
            }}
            padding="7px"
            width="fit-content"
          >
            <ConciergeProfile slackUrl={jobSlackUrl} slackIconVariant={SlackIconVariant.FullWidth} />
          </Box>
        </Stack>
      );
    }
    return <EmbeddedRecruiterDefaultBody />;
  }, [embeddedRecruiterActive, isBasePlanCustomer, jobFeatureUIState, jobSlackUrl]);

  return (
    <>
      <TemplateCard
        highlighted
        imageComponent={shouldShowImage ? <EmbeddedRecruiterImg /> : undefined}
        title={EMBEDDED_RECRUITER_TITLE}
        body={InnerBody}
        actionButton={ActionsButton}
        featureStateBadge={getFeatureStateBadge()}
        learnMoreLink={APP_ROUTE_PATHS.embeddedRecruiter()}
        featureActiveAndSetup={featureActiveAndSetup}
      />
      <DeactivateEndToEndSchedulingModal
        isModalOpen={isDeactivateConfirmationModalOpen}
        toggleModalOff={toggleDeactivationModalOff}
      />
      <PremiumFeatureModal isModalOpen={premiumFeatureModalOpen} toggleModalOff={togglePremiumFeatureModalOff} />
      <PipelineReviewModal isModalOpen={isPipelineReviewModalOpen} toggleModalOff={togglePipelineReviewModalOff} />
    </>
  );
};

export const EmbeddedRecruiterCardPreview = (): React.ReactElement => {
  return (
    <TemplateCard
      imageComponent={<EmbeddedRecruiterImg />}
      title={EMBEDDED_RECRUITER_TITLE}
      body={<EmbeddedRecruiterDefaultBody />}
    />
  );
};
