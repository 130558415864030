import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import React from "react";

import AsyncMultiSelect from "components/data-driven-forms/fields/AsyncMultiSelect";
import MultiSelect from "components/data-driven-forms/fields/MultiSelect";
import SingleSelectDropdown from "components/data-driven-forms/fields/SingleSelectDropdown";
import TileSelect from "components/data-driven-forms/fields/TileSelect";

const MultiSelectComponent = props => {
  if (props.options.length === 0 && props.loadOptions) {
    return <AsyncMultiSelect {...props} />;
  }
  return <MultiSelect {...props} />;
};

const SingleSelectComponent = props => {
  let longLabelExists = false;

  for (const option of props.options) {
    if (option.label?.length > 14) {
      longLabelExists = true;
      break;
    }
  }
  const numOptions = props.options.length;
  // if there are more than 5 answers or any answer is longer than 14 chars,
  // use dropdown instead of tile select
  if (numOptions <= 5 && !longLabelExists) {
    return <TileSelect {...props} />;
  } else {
    return <SingleSelectDropdown {...props} />;
  }
};

export default props => {
  // Route between custom single and multiselect components to provide a single
  // select interface.
  const { multiple, isMulti } = useFieldApi(props);
  return multiple || isMulti ? <MultiSelectComponent {...props} /> : <SingleSelectComponent {...props} />;
};
