/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, useForm, useFormState } from "react-final-form";

import { DeleteFileIconButton } from "components/dover/common";
import {
  downloadLinkedInConnectionsInstructionItems,
  INITIAL_FORM_VALUES,
} from "components/dover/LinkedInConnectionsUpload/constants";
import { Header, ChildrenContentWrapper } from "components/dover/LinkedInConnectionsUpload/styles";
import { FormValues } from "components/dover/UploadUserFile/types";
import FileUploadField from "components/inputs/FileUpload";
import { PlaceholderWrapper } from "components/inputs/FileUpload/styles";
import Instruction from "components/instructions/Instruction";
import { InstructionList } from "components/instructions/styles";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import Confirm from "components/Modal/ConfirmModal";
import { Spacer } from "components/Spacer";
import { StyledAlert } from "components/styles";
import { useCreateUserUploadedFileMutation } from "services/doverapi/endpoints/userUploadedFile";
import { DoverApiError } from "services/doverapi/types";
import { CreateUserUploadedFileUploadedContextEnum, UserUploadedFile } from "services/openapi";
import { BodyText_doNotUse } from "styles/typography";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";

interface LinkedInConnectionsUploadProps {
  headerText?: string;
  cancelUploadContacts?: () => void;
  onSuccess?: (uploadedFile: UserUploadedFile) => void;
}

const LinkedInConnectionsUpload: React.FC<LinkedInConnectionsUploadProps> = ({
  headerText,
  cancelUploadContacts,
  onSuccess,
  children,
}) => {
  const [openInstructionModal, setOpenInstructionModal] = useState<"downloadLinkedInConnections" | "">("");
  const [connectionsFile, setConnectionsFile] = useState<File | undefined>();

  const [
    createUserUploadedFile,
    { isLoading: isUploadingFile, error: fileUploadError },
  ] = useCreateUserUploadedFileMutation();
  const errorMessage = useMemo(() => {
    return (fileUploadError as DoverApiError | undefined)?.userFacingMessage;
  }, [fileUploadError]);

  const handleSubmit = (values: FormValues): void => {
    setConnectionsFile(values.files[0]);
  };

  const validate = (values: FormValues): any => {
    if (!values.files) {
      return { files: "You must select a file to upload" };
    }
  };

  const handleUpload = useCallback(async (): Promise<void> => {
    showPendingToast("Uploading LinkedIn connections...");

    const uploadedFile = await createUserUploadedFile({
      uploadedContext: CreateUserUploadedFileUploadedContextEnum.Referrals,
      fileContents: connectionsFile!,
    });

    if ("data" in uploadedFile) {
      showSuccessToast("Successfully uploaded LinkedIn connections");
      onSuccess && onSuccess(uploadedFile.data);
    } else {
      showErrorToast("Failed to upload LinkedIn connections");
    }
  }, [onSuccess, createUserUploadedFile, connectionsFile]);

  return (
    <div>
      {headerText && <Header>{headerText}</Header>}
      <Box>To upload your connections: </Box>
      <Spacer height="24px" />
      <InstructionList>
        <Instruction
          openModalKey={openInstructionModal}
          setOpenModal={(): void => setOpenInstructionModal("downloadLinkedInConnections")}
          setCloseModal={(): void => setOpenInstructionModal("")}
          cardHeader={
            <Box fontFamily="Inter" fontSize={16}>
              Download your LinkedIn connections.
            </Box>
          }
          modalKey={"downloadLinkedInConnections"}
          modalHeader={"Download LinkedIn Connections"}
          modalInstructionItems={downloadLinkedInConnectionsInstructionItems}
        />
        <Instruction
          cardHeader={
            <Box fontFamily="Inter" fontSize={16}>
              Upload your connections CSV.
            </Box>
          }
          cardInstructionBody={
            <>
              <Spacer height="24px" />
              <Box>
                <Form
                  onSubmit={handleSubmit}
                  initialValues={INITIAL_FORM_VALUES}
                  validate={validate}
                  render={({ handleSubmit, submitting }): React.ReactElement => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Grid container direction="column" spacing={3}>
                          <Grid item>
                            {errorMessage && (
                              <StyledAlert severity="error">
                                <div>{errorMessage || "Error uploading file. Please try again."}</div>
                              </StyledAlert>
                            )}
                            <UploadConnectionsField submitting={submitting} />
                          </Grid>
                        </Grid>
                      </form>
                    );
                  }}
                />
              </Box>
            </>
          }
        />
      </InstructionList>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Grid item>
          {cancelUploadContacts && (
            <Button variant={ButtonVariant.Secondary} onClick={(): void => cancelUploadContacts()}>
              Cancel
            </Button>
          )}{" "}
          <Confirm
            title="Please confirm"
            submitText="Confirm and upload"
            content={
              <Box width={520} marginLeft="auto" marginRight="auto">
                <BodyText_doNotUse $size="B1">{"I confirm that I'm uploading my connections."}</BodyText_doNotUse>
                <br />
                <BodyText_doNotUse $size="B1">
                  {
                    "I understand that uploading connections on behalf of another person can lead to issues with Dover surfacing and engaging these connections."
                  }
                </BodyText_doNotUse>
              </Box>
            }
          >
            {(confirm: any): React.ReactNode => (
              <Button
                onClick={confirm((): void => {
                  handleUpload();
                })}
                variant={ButtonVariant.Primary}
                disabled={isUploadingFile}
              >
                {isUploadingFile ? "Uploading..." : "Upload"}
              </Button>
            )}
          </Confirm>
        </Grid>
      </Box>
      {children && <ChildrenContentWrapper>{children}</ChildrenContentWrapper>}
    </div>
  );
};

const UploadConnectionsField = ({ submitting }: { submitting: boolean }): React.ReactElement => {
  const form = useForm();
  const formState = useFormState();
  const [filename, setFilename] = useState(formState.values.files[0]?.name ?? "");

  useEffect(() => {
    const files = formState.values.files;
    if (files.length > 0 && files[0]?.name !== filename) {
      setFilename(files[0]?.name);
      form.submit();
    }
  }, [formState.values]);

  return (
    <FileUploadField
      name={"files"}
      acceptFileTypes=".csv"
      dropzoneOptions={{ maxFiles: 1, disabled: submitting }}
      placeholder={
        formState.values.files[0] ? (
          <PlaceholderWrapper>
            <Body>
              {formState.values.files[0]?.name} <DeleteFileIconButton />
            </Body>
          </PlaceholderWrapper>
        ) : (
          <PlaceholderWrapper>
            <Body>Drag and drop file here</Body>
            <Body>or</Body>
            <Button variant={ButtonVariant.Secondary} onClick={(): void => {}}>
              Browse Files
            </Button>
          </PlaceholderWrapper>
        )
      }
      placeholderWhenDragActive={
        <PlaceholderWrapper>
          <Body>Drop file here</Body>
        </PlaceholderWrapper>
      }
    />
  );
};

export default LinkedInConnectionsUpload;
