/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse2005
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    inbox?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    replyType?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    replyText?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2005
     */
    handoffCc?: string;
}

export function InlineResponse2005FromJSON(json: any): InlineResponse2005 {
    return InlineResponse2005FromJSONTyped(json, false);
}

export function InlineResponse2005FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2005 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'inbox': !exists(json, 'inbox') ? undefined : json['inbox'],
        'replyType': !exists(json, 'reply_type') ? undefined : json['reply_type'],
        'replyText': !exists(json, 'reply_text') ? undefined : json['reply_text'],
        'handoffCc': !exists(json, 'handoff_cc') ? undefined : json['handoff_cc'],
    };
}

export function InlineResponse2005ToJSON(value?: InlineResponse2005 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'inbox': value.inbox,
        'reply_type': value.replyType,
        'reply_text': value.replyText,
        'handoff_cc': value.handoffCc,
    };
}


