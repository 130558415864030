import { z } from "zod";

export const EmailTemplateSchema = z.object({
  name: z.string().min(1, { message: "Template name is required" }),
  body: z.string().optional(),
  from: z.string().optional(),
  subject: z.string().optional(),
  cc: z
    .array(
      z.object({
        email: z
          .string()
          .email({ message: "Invalid email" })
          .or(z.literal("")),
        id: z.string(),
      })
    )
    .default([]),
  bcc: z
    .array(
      z.object({
        email: z
          .string()
          .email({ message: "Invalid email" })
          .or(z.literal("")),
        id: z.string(),
      })
    )
    .default([]),
});

export type EmailTemplateSchemaType = z.infer<typeof EmailTemplateSchema>;
