/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20046
 */
export interface InlineResponse20046 {
    /**
     * 
     * @type {Array<object>}
     * @memberof InlineResponse20046
     */
    relatedKeywords: Array<object>;
}

export function InlineResponse20046FromJSON(json: any): InlineResponse20046 {
    return InlineResponse20046FromJSONTyped(json, false);
}

export function InlineResponse20046FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20046 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relatedKeywords': json['relatedKeywords'],
    };
}

export function InlineResponse20046ToJSON(value?: InlineResponse20046 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relatedKeywords': value.relatedKeywords,
    };
}


