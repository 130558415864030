import { Box, Drawer, Stack } from "@mui/material";
import React, { FC, useState } from "react";

import { ReactComponent as XIcon } from "assets/icons/red-x.svg";
import { ExampleTooltip } from "components/dover/EditCareersPage/CareersPageStatsSection";
import QuickFactsList from "components/dover/EditCareersPage/EditQuickFactsList";
import EditSocialLinksList from "components/dover/EditCareersPage/EditSocialLinksList";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { Heading } from "components/library/typography";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { CareersPageDisplayStat, ClientSocialLink, ClientSocialLinkLinkTypeEnum } from "services/openapi";
import { colors } from "styles/theme";

const HEADER_HEIGHT = "56px";
const FOOTER_HEIGHT = "78px";
interface DrawerHeaderProps {
  onClose: () => void;
}

const DrawerHeader = ({ onClose }: DrawerHeaderProps): React.ReactElement => {
  // TODO: make this reusable
  return (
    <Box
      sx={{
        backgroundColor: "white",
        position: "sticky",
        top: 0,
        height: "56px",
        zIndex: 1400,
        borderBottom: `1px solid ${colors.grayscale.gray200}`,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" height="100%" px={3} py={1}>
        <Heading>Company Info</Heading>
        <Stack direction="row" spacing={1} alignItems="center">
          <ExampleTooltip />
          <Box onClick={onClose} sx={{ cursor: "pointer" }}>
            <XIcon width="16px" color={colors.grayscale.gray600} className="svg-fill" />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

interface DrawerFooterProps extends DrawerHeaderProps {
  onSave: () => Promise<void>;
  disabledMessage?: string;
}

export const DrawerFooter = ({ onClose, onSave, disabledMessage }: DrawerFooterProps): React.ReactElement => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        position: "sticky",
        bottom: 0,
        zIndex: 1400,
        borderTop: `1px solid ${colors.grayscale.gray200}`,
      }}
    >
      <Stack direction="row" spacing={1} justifyContent="flex-end" p={2} width="100%">
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        {/* TODO: add disabling logic with tooltip message */}
        <Tooltip title={disabledMessage}>
          <Box height="100%">
            <Button variant={ButtonVariant.Primary} onClick={onSave} disabled={!!disabledMessage}>
              Save
            </Button>
          </Box>
        </Tooltip>
      </Stack>
    </Box>
  );
};

interface CareersPageStatsDrawerProps {
  initialDisplayStats?: Array<CareersPageDisplayStat>;
  initialSocialLinks?: Array<ClientSocialLink>;
  onSave: (displayStats: Array<CareersPageDisplayStat>, socialLinks: Array<ClientSocialLink>) => Promise<void>;
}

export const CareersPageStatsDrawer: FC<GlobalModalProps & CareersPageStatsDrawerProps> = ({
  isOpen,
  close,
  initialDisplayStats,
  initialSocialLinks,
  onSave,
}) => {
  // Display Stats State and Functions
  const [displayStats, setDisplayStats] = useState<Array<CareersPageDisplayStat>>(
    initialDisplayStats || [{ label: "", value: "" }]
  );

  const updateStat = ({
    index,
    key,
    newValue,
  }: {
    index: number;
    key: keyof CareersPageDisplayStat;
    newValue: string;
  }): void => {
    setDisplayStats(prevStats => prevStats.map((stat, i) => (i === index ? { ...stat, [key]: newValue } : stat)));
  };

  const addStat = (): void => {
    setDisplayStats(prevStats => [...prevStats, { label: "", value: "" }]);
  };

  const removeStat = (index: number): void => {
    setDisplayStats(prevStats => prevStats.filter((_, i) => i !== index));
  };

  // Social Links State and Functions
  const [socialLinks, setSocialLinks] = useState<Array<ClientSocialLink>>(initialSocialLinks || []);

  const updateSocialLink = ({
    index,
    key,
    newValue,
  }: {
    index: number;
    key: "linkType" | "url";
    newValue: string;
  }): void => {
    setSocialLinks(prevLinks => prevLinks.map((link, i) => (i === index ? { ...link, [key]: newValue } : link)));
  };

  const addSocialLink = (): void => {
    setSocialLinks(prevLinks => [...prevLinks, { linkType: ClientSocialLinkLinkTypeEnum.Other, url: "" }]);
  };

  const removeSocialLink = (index: number): void => {
    setSocialLinks(prevLinks => prevLinks.filter((_, i) => i !== index));
  };

  const isFormComplete = displayStats.every(stat => stat.label && stat.value) && socialLinks.every(link => link.url);

  const handleSave = async (): Promise<void> => {
    await onSave(displayStats, socialLinks);
    close();
  };

  return (
    <>
      <Drawer open={isOpen} onClose={close} anchor="right" sx={{ zIndex: 1300 }}>
        <DrawerHeader onClose={close} />
        <Stack
          spacing={3}
          p={2}
          minWidth="420px"
          maxWidth="100%"
          // Set min height to fill the space between the header and footer to ensure the footer sticks to the bottom of the screen
          minHeight={`calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT})`}
          position="relative"
          overflow="auto"
        >
          <QuickFactsList
            quickFacts={displayStats}
            onUpdateQuickFact={updateStat}
            onAddQuickFact={addStat}
            onRemoveQuickFact={removeStat}
          />
          <EditSocialLinksList
            socialLinks={socialLinks}
            onUpdateSocialLink={updateSocialLink}
            onAddSocialLink={addSocialLink}
            onRemoveSocialLink={removeSocialLink}
          />
        </Stack>
        <DrawerFooter
          onClose={close}
          onSave={handleSave}
          disabledMessage={!isFormComplete ? "Please fill out all fields" : ""}
        />
      </Drawer>
    </>
  );
};

export const careersPageStatsDrawerAtom = modalAtom(CareersPageStatsDrawer);
