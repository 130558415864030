import { Grid, Select, MenuItem } from "@mui/material";
import React from "react";
import { useField, useForm } from "react-final-form";

import { Body } from "components/library/typography";
import { Spacer } from "components/Spacer";

export const MinFutureBookingTimeOverride = ({
  setIsDirty,
}: {
  setIsDirty: (value: boolean) => void;
}): React.ReactElement => {
  const form = useForm();
  const minFutureBookingTimeOverrideField = useField("minFutureBookingTimeOverride");

  return (
    <Grid container direction="column">
      <Grid item>
        <Body weight="500">How soon can candidates book interviews?</Body>
        <Spacer height="16px" />
      </Grid>

      <Grid item>
        <Select
          onChange={(e): void => {
            setIsDirty(true);
            form.change(minFutureBookingTimeOverrideField.input.name, e.target.value || null);
          }}
          value={minFutureBookingTimeOverrideField.input.value || 0}
        >
          {BUFFER_OPTIONS.map(option => {
            return <MenuItem value={option.value}>{option.label}</MenuItem>;
          })}
        </Select>
      </Grid>
    </Grid>
  );
};

const BUFFER_OPTIONS = [
  { value: 0, label: "Default" },
  { value: 30 * 60, label: "30 min" },
  { value: 60 * 60, label: "1 hour" },
  { value: 90 * 60, label: "1 hour and 30 min" },
  { value: 2 * 60 * 60, label: "2 hours" },
  { value: 3 * 60 * 60, label: "3 hours" },
  { value: 4 * 60 * 60, label: "4 hours" },
  { value: 6 * 60 * 60, label: "6 hours" },
  { value: 8 * 60 * 60, label: "8 hours" },
  { value: 12 * 60 * 60, label: "12 hours" },
  { value: 18 * 60 * 60, label: "18 hours" },
  { value: 24 * 60 * 60, label: "24 hours" },
  { value: 36 * 60 * 60, label: "36 hours" },
  { value: 48 * 60 * 60, label: "48 hours" },
];
