import { Divider, FormControlLabel, TextField, Modal, Paper, Radio } from "@mui/material";
import Color from "color";
import styled from "styled-components";

import { Body } from "components/library/typography";
import { backgrounds, colors } from "styles/theme";

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    width: 100%;
  }

  .MuiInputBase-input {
    padding: 10px;
  }

  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }

  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 0px;
`;

export const StyledModal = styled(Modal)`
  position: fixed;
  top: 25%;
  left: 25%;
`;

const StyledModalPaper = styled(Paper)`
  width: 675px;
  max-width: 90%;
  border-radius: 10px;
  max-height: 800px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

export const StyledNewProUserModalPaper = styled(StyledModalPaper)`
  width: 450px;
  border-radius: 0px;
`;

export const StyledUpgradePlanModalPaper = styled(StyledModalPaper)``;

export const StyledDivider = styled(Divider)`
  margin: 0px -32px;
`;

export const Paragraph = styled(Body)`
  margin-top: 2px;
`;
