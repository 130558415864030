/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationOption,
    LocationOptionFromJSON,
    LocationOptionFromJSONTyped,
    LocationOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListLocationsResponse
 */
export interface ListLocationsResponse {
    /**
     * 
     * @type {Array<LocationOption>}
     * @memberof ListLocationsResponse
     */
    cities: Array<LocationOption>;
    /**
     * 
     * @type {Array<LocationOption>}
     * @memberof ListLocationsResponse
     */
    remoteOptions: Array<LocationOption>;
}

export function ListLocationsResponseFromJSON(json: any): ListLocationsResponse {
    return ListLocationsResponseFromJSONTyped(json, false);
}

export function ListLocationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListLocationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cities': ((json['cities'] as Array<any>).map(LocationOptionFromJSON)),
        'remoteOptions': ((json['remote_options'] as Array<any>).map(LocationOptionFromJSON)),
    };
}

export function ListLocationsResponseToJSON(value?: ListLocationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cities': ((value.cities as Array<any>).map(LocationOptionToJSON)),
        'remote_options': ((value.remoteOptions as Array<any>).map(LocationOptionToJSON)),
    };
}


