/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientImage
 */
export interface ClientImage {
    /**
     * 
     * @type {string}
     * @memberof ClientImage
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientImage
     */
    readonly fileContents?: string;
}

export function ClientImageFromJSON(json: any): ClientImage {
    return ClientImageFromJSONTyped(json, false);
}

export function ClientImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fileContents': !exists(json, 'file_contents') ? undefined : json['file_contents'],
    };
}

export function ClientImageToJSON(value?: ClientImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


