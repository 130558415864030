/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CallRecording,
    CallRecordingFromJSON,
    CallRecordingFromJSONTyped,
    CallRecordingToJSON,
    InterviewMeetingType,
    InterviewMeetingTypeFromJSON,
    InterviewMeetingTypeFromJSONTyped,
    InterviewMeetingTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface IRRCandidateInterviewInfo
 */
export interface IRRCandidateInterviewInfo {
    /**
     * 
     * @type {string}
     * @memberof IRRCandidateInterviewInfo
     */
    stageName: string;
    /**
     * 
     * @type {string}
     * @memberof IRRCandidateInterviewInfo
     */
    interviewId: string;
    /**
     * 
     * @type {string}
     * @memberof IRRCandidateInterviewInfo
     */
    interviewerName: string;
    /**
     * 
     * @type {string}
     * @memberof IRRCandidateInterviewInfo
     */
    startDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof IRRCandidateInterviewInfo
     */
    useDoverInterviewer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IRRCandidateInterviewInfo
     */
    isDoverScheduledEvent?: boolean;
    /**
     * 
     * @type {CallRecording}
     * @memberof IRRCandidateInterviewInfo
     */
    callRecording: CallRecording;
    /**
     * 
     * @type {boolean}
     * @memberof IRRCandidateInterviewInfo
     */
    enableAiNotetaker: boolean;
    /**
     * 
     * @type {InterviewMeetingType}
     * @memberof IRRCandidateInterviewInfo
     */
    interviewerMeetingType: InterviewMeetingType;
    /**
     * 
     * @type {string}
     * @memberof IRRCandidateInterviewInfo
     */
    defaultFeedbackTemplate?: string | null;
}

export function IRRCandidateInterviewInfoFromJSON(json: any): IRRCandidateInterviewInfo {
    return IRRCandidateInterviewInfoFromJSONTyped(json, false);
}

export function IRRCandidateInterviewInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRRCandidateInterviewInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stageName': json['stage_name'],
        'interviewId': json['interview_id'],
        'interviewerName': json['interviewer_name'],
        'startDate': json['start_date'],
        'useDoverInterviewer': json['use_dover_interviewer'],
        'isDoverScheduledEvent': !exists(json, 'is_dover_scheduled_event') ? undefined : json['is_dover_scheduled_event'],
        'callRecording': CallRecordingFromJSON(json['call_recording']),
        'enableAiNotetaker': json['enable_ai_notetaker'],
        'interviewerMeetingType': InterviewMeetingTypeFromJSON(json['interviewer_meeting_type']),
        'defaultFeedbackTemplate': !exists(json, 'default_feedback_template') ? undefined : json['default_feedback_template'],
    };
}

export function IRRCandidateInterviewInfoToJSON(value?: IRRCandidateInterviewInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stage_name': value.stageName,
        'interview_id': value.interviewId,
        'interviewer_name': value.interviewerName,
        'start_date': value.startDate,
        'use_dover_interviewer': value.useDoverInterviewer,
        'is_dover_scheduled_event': value.isDoverScheduledEvent,
        'call_recording': CallRecordingToJSON(value.callRecording),
        'enable_ai_notetaker': value.enableAiNotetaker,
        'interviewer_meeting_type': InterviewMeetingTypeToJSON(value.interviewerMeetingType),
        'default_feedback_template': value.defaultFeedbackTemplate,
    };
}


