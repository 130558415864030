/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobPitchFeatures
 */
export interface JobPitchFeatures {
    /**
     * 
     * @type {string}
     * @memberof JobPitchFeatures
     */
    jobWorkingOn?: string | null;
}

export function JobPitchFeaturesFromJSON(json: any): JobPitchFeatures {
    return JobPitchFeaturesFromJSONTyped(json, false);
}

export function JobPitchFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobPitchFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobWorkingOn': !exists(json, 'job_working_on') ? undefined : json['job_working_on'],
    };
}

export function JobPitchFeaturesToJSON(value?: JobPitchFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_working_on': value.jobWorkingOn,
    };
}


