import { Button } from "@doverhq/dover-ui";
import { Box } from "@mui/material";
import React from "react";

export const StickyBottomButton = ({
  text,
  onClick,
  isLoading,
  disabled,
}: {
  text: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}): React.ReactElement => {
  return (
    <Box width="100%" p={1} position="sticky" bottom="0" bgcolor="white">
      <Button onPress={onClick} variant="primaryFilled" w="full" isLoading={isLoading} isDisabled={disabled}>
        {text}
      </Button>
    </Box>
  );
};
