import { Skeleton, Stack } from "@mui/material";
import React from "react";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { colors } from "styles/theme";
import { convertPrettyDate } from "views/CandidateDetail/utils";
import { goalHireDateModalAtom } from "views/job/components/GoalHireDate";

//------------------------------------------------------------------------------
// Component

interface GoalHireDateButtonProps {
  jobId: string;
}

const GoalHireDateButton = ({ jobId }: GoalHireDateButtonProps): React.ReactElement => {
  const { open } = useModal(goalHireDateModalAtom);
  const { data: jobSetup, isFetching: isJobSetupFetching } = useGetJobSetupQuery(jobId);

  if (isJobSetupFetching) {
    return <Skeleton width={"125px"} />;
  }

  const pastDue = jobSetup?.goalHireDate && jobSetup.goalHireDate < new Date();

  return (
    <Button
      variant={ButtonVariant.Secondary}
      onClick={(e): void => {
        e.preventDefault();
        open({ jobId });
      }}
      padding="4px 8px"
    >
      <Stack direction="row" spacing={0.5} alignItems="center">
        {!jobSetup?.goalHireDate && <PlusIcon height="14px" />}
        <BodySmall>{jobSetup?.goalHireDate ? "Goal hire date:" : "Goal hire date"}</BodySmall>
        {jobSetup?.goalHireDate && (
          <BodySmall color={pastDue ? colors.warning.dark : colors.grayscale.gray600}>
            {convertPrettyDate(jobSetup.goalHireDate)}
          </BodySmall>
        )}
      </Stack>
    </Button>
  );
};

export default GoalHireDateButton;
