import { decodeJson, encodeJson, QueryParamConfig } from "use-query-params";

import { InterviewerOption } from "components/NextInterviewer/useInterviewerOptions";
import {
  BulkCandidateInfoSchema,
  CandidateInfoSchema,
  InterviewPipelineCandidateInfoSchema,
} from "sections/addcandidate/types/candidateInfo";
import {
  AtsCandidateManualSourcing,
  CandidateBioSchedulingOwnershipEnum,
  JobStage,
  ListCampaign,
} from "services/openapi";

//--------------------------------------------------------------
// Step Types

export const StepTypes = {
  JOB_SELECT: "JOB_SELECT", // ALL
  ATS_CANDIDATE_INFO: "ATS_CANDIDATE_INFO", // interview-pipeline
  CANDIDATE_INFO: "CANDIDATE_INFO", // initial-outreach & interview-pipeline
  SELECT_CAMPAIGN: "SELECT_CAMPAIGN", // initial-outreach
  INITIAL_OUTREACH_REVIEW: "INITIAL_OUTREACH_REVIEW", // initial-outreach
  STAGE_SELECT: "STAGE_SELECT", // interview-pipeline
  INTERVIEW_INFO: "INTERVIEW_INFO", // interview-pipeline
  CUSTOMIZE_EMAIL: "CUSTOMIZE_EMAIL", // interview-pipeline
  INTERVIEW_PIPELINE_REVIEW: "INTERVIEW_PIPELINE_REVIEW", // interview-pipeline
} as const;

export type StepType = typeof StepTypes[keyof typeof StepTypes];

export type AddCandidateStep = {
  type: StepType;
  label: string;
  description: string;
  StepContent: React.ReactElement;
  PostCompletionContent?: React.ReactElement;
};

type JobSelectStepState = {
  jobId?: string;
};

type AtsCandidateInfoStep = {
  addingAtsCandidate: boolean;
  atsCandidateUrl?: string;
  atsCandidate?: AtsCandidateManualSourcing;
};

export type CandidateInfoStepState = (
  | CandidateInfoSchema
  | BulkCandidateInfoSchema
  | InterviewPipelineCandidateInfoSchema
) & {
  manualSourcingPreCheck?: {
    isValid: boolean;
    existingCandidate?: string;
    errorMessage?: string;
  };
};

type SelectCampaignStepState = {
  campaign: ListCampaign | null;
  body: string;
  subject: string;
  ccEmails: string[];
};

type InitialOutreachReviewStepState = {};

type StageSelectStepState = {
  jobStage?: JobStage;
};

type InterviewInfoStepState = {
  scheduleInterviewThroughDover: boolean;
  selectedInterviewer?: InterviewerOption;
};

type CustomizeEmailStepState = {
  schedulingOwnership: CandidateBioSchedulingOwnershipEnum;
  schedulingEmailBody: string;
  schedulingEmailSubject: string;
  schedulingEmailCcEmails: {
    email: string;
    name: string;
  }[];
  schedulingEmailSenderEmail: string;
};

//--------------------------------------------------------------
// Flow State

export type FlowState = {
  [StepTypes.ATS_CANDIDATE_INFO]: AtsCandidateInfoStep;
  [StepTypes.JOB_SELECT]: JobSelectStepState;
  [StepTypes.CANDIDATE_INFO]: CandidateInfoStepState;
  [StepTypes.SELECT_CAMPAIGN]: SelectCampaignStepState;
  [StepTypes.INITIAL_OUTREACH_REVIEW]: InitialOutreachReviewStepState;
  [StepTypes.STAGE_SELECT]: StageSelectStepState;
  [StepTypes.INTERVIEW_INFO]: InterviewInfoStepState;
  [StepTypes.CUSTOMIZE_EMAIL]: CustomizeEmailStepState;
};

type ValueOf<T> = T[keyof T];

export type FlowStateValue = ValueOf<FlowState>;

export const FlowTypes = {
  INTERVIEW_PIPELINE: "interview-pipeline",
  INITIAL_OUTREACH: "initial-outreach",
} as const;
export type FlowType = typeof FlowTypes[keyof typeof FlowTypes];

// TODO: remove bulk from above
export const OutreachTypes = {
  SINGLE: "single",
  BULK: "bulk",
} as const;
export type OutreachType = typeof OutreachTypes[keyof typeof OutreachTypes];

export const OutreachTypeParam: QueryParamConfig<OutreachType | null | undefined> = {
  encode: (value: OutreachType | null | undefined): string | null | undefined => encodeJson(value),
  decode: (jsonStr: string | (string | null)[] | null | undefined): OutreachType | undefined => {
    const valid = jsonStr && typeof jsonStr === "string" && jsonStr.length > 0;
    // if is a string
    if (valid) {
      const decoded = decodeJson(jsonStr);
      const isOutreachType = Object.values(OutreachTypes).includes(decoded as OutreachType);
      if (isOutreachType) {
        return decoded as OutreachType;
      }
    }

    return undefined;
  },
};

export const FlowTypeParam: QueryParamConfig<FlowType | null | undefined> = {
  encode: (value: FlowType | null | undefined): string | null | undefined => encodeJson(value),
  decode: (jsonStr: string | (string | null)[] | null | undefined): FlowType | undefined => {
    const valid = jsonStr && typeof jsonStr === "string" && jsonStr.length > 0;
    // if is a string
    if (valid) {
      const decoded = decodeJson(jsonStr);
      const isFlowtype = Object.values(FlowTypes).includes(decoded as FlowType);
      if (isFlowtype) {
        return decoded as FlowType;
      }
    }

    return undefined;
  },
};

//--------------------------------------------------------------
// Handle Add Candidate

export interface HandleAddCandidateResult {
  candidateId?: string;
  success: boolean;
  message?: string;
}

type HandleAddCandidateFn = (outreachType?: OutreachType | null) => Promise<HandleAddCandidateResult>;

//--------------------------------------------------------------
// Context Types

export type AddCandidateState = {
  // General
  modalOpen: boolean;
  discardChangesModalOpen: boolean;
  setDiscardChangesModalOpen: (open: boolean) => void;
  flowType: FlowType;
  closeModal: (force: boolean) => void;
  loading: boolean;
  isSubmitting: boolean;
  handleAddCandidate: HandleAddCandidateFn;
  // Form
  formDirty: boolean;
  activeStep: number;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  handleResetSteps: () => void;
  flowState: FlowState;
  setStepState: (stepType: StepType, stepState: FlowStateValue) => void;
  // finished
  candidateId: string | undefined;
};
