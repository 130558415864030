import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useParams } from "react-router-dom";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import DoverInterviewer from "assets/images/pitch_candidates.png";
import { useDisableEnablingDoverInterviewerWithTooltip } from "components/dover/DoverInterviewerCard/hooks";
import { JOB_PITCH_DRAWER_QUERY_PARAM } from "components/dover/JobPitch/data/queryParam";
import JobPitchDrawer from "components/dover/JobPitch/JobPitchDrawer";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useGetJobFeatureUIState } from "services/doverapi/cross-endpoint-hooks/useGetJobFeatureUIState";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { JobFeatureUIState } from "services/doverapi/endpoints/job/types";
import {
  useGetJobFeaturesQuery,
  useUpsertJobFeatureSettingMutation,
} from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import {
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { CardBadge } from "views/job/JobSetup/steps/Overview/components/CardBadge";
import { TemplateCard } from "views/job/JobSetup/steps/Overview/components/DoverSolutionsTemplateCard";

const DoverInterviewerImg = (): React.ReactElement => {
  return <img src={DoverInterviewer} style={{ width: "100%" }} />;
};
const DOVER_INTERVIEWER_TITLE = "Dover Interviewer";

const DoverInterviewerInactiveDescription = (): React.ReactElement => {
  return (
    <BodySmall color={colors.grayscale.gray500}>
      Delegate initial phone screens to our team of experts. Last year, we conducted over 27,000 interviews for both
      technical and go-to-market positions.
    </BodySmall>
  );
};

const JobPitchButton = (): React.ReactElement => {
  const [, setIsOpenJobPitchDrawerParam] = useQueryParam(
    JOB_PITCH_DRAWER_QUERY_PARAM,
    withDefault(BooleanParam, false)
  );

  return (
    <Button
      width="100%"
      variant={ButtonVariant.Secondary}
      onClick={(): void => {
        setIsOpenJobPitchDrawerParam(true);
      }}
    >
      Job Pitch
    </Button>
  );
};

export const DoverInterviewerCard = (): React.ReactElement => {
  // mutations
  const [upsertJobFeatureSetting] = useUpsertJobFeatureSettingMutation();

  // query params
  const { jobId } = useParams<{ jobId: string }>();

  // other
  const theme = useTheme();
  const largerThanLaptop = !useMediaQuery(theme.breakpoints.down(screenSizesNumbers.laptopML));

  // fetch data
  const { jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
      };
    },
  });

  const isBasePlanCustomer = useIsBasePlanCustomer();

  // derived data
  const jobFeatureUIState = useGetJobFeatureUIState({
    featureName: JobFeatureFeatureNameEnum.DoverInterviewer,
    jobId,
    allowAutoEnablement: true,
  });
  const isDoverInterviewerEnabled = !!jobFeatures?.[JobFeatureFeatureNameEnum.DoverInterviewer];

  const shouldShowImage = React.useMemo(() => {
    return largerThanLaptop && isBasePlanCustomer;
  }, [isBasePlanCustomer, largerThanLaptop]);

  // callbacks
  const toggleDoverInterviewer = React.useCallback(
    (enabled: boolean) => {
      if (jobId) {
        upsertJobFeatureSetting({
          upsertData: {
            data: {
              job: jobId,
              featureName: UpsertJobFeatureSettingFeatureNameEnum.DoverInterviewer,
              state: enabled ? UpsertJobFeatureSettingStateEnum.Enabled : UpsertJobFeatureSettingStateEnum.Disabled,
            },
          },
        });
      }
    },
    [jobId, upsertJobFeatureSetting]
  );

  const getFeatureStateBadge = React.useCallback(() => {
    if (!jobFeatureUIState) {
      return <></>;
    }
    if (!isDoverInterviewerEnabled) {
      return <CardBadge jobFeatureUIState={JobFeatureUIState.Inactive} />;
    }
    // TODO: this is a temp override; the logic for determining the JobFeatureUIState should be updated
    if (jobFeatureUIState === JobFeatureUIState.NeedsSetup) {
      return <CardBadge jobFeatureUIState={JobFeatureUIState.Active} />;
    }
    return <CardBadge jobFeatureUIState={jobFeatureUIState} />;
  }, [jobFeatureUIState, isDoverInterviewerEnabled]);

  // memoized components

  const {
    shouldDisable: shouldDisableEnableButton,
    tooltipMessage: disableTooltipMessage,
  } = useDisableEnablingDoverInterviewerWithTooltip();

  const Actions = React.useMemo(() => {
    // Note: longer term we should deprecate the Needs Setup state and just use Active now that we're
    // blocking the ability to enable Dover Interviewer if setup isn't complete
    const enabledStates = [JobFeatureUIState.Active, JobFeatureUIState.NeedsSetup];
    if (isDoverInterviewerEnabled && jobFeatureUIState && enabledStates.includes(jobFeatureUIState)) {
      return (
        <>
          <Stack spacing={1}>
            <Button
              variant={ButtonVariant.SecondaryCritical}
              onClick={(): void => {
                toggleDoverInterviewer(false);
              }}
            >
              Disable
            </Button>
            <JobPitchButton />
          </Stack>
        </>
      );
    }
    return (
      <Stack spacing={1}>
        <Button
          width="100%"
          variant={ButtonVariant.SecondarySuccess}
          onClick={(): void => {
            toggleDoverInterviewer(true);
          }}
          disabled={shouldDisableEnableButton}
          tooltip={disableTooltipMessage}
        >
          Enable
        </Button>

        <JobPitchButton />
      </Stack>
    );
  }, [
    isDoverInterviewerEnabled,
    jobFeatureUIState,
    shouldDisableEnableButton,
    disableTooltipMessage,
    toggleDoverInterviewer,
  ]);

  const InnerBody = React.useMemo(() => {
    if (jobFeatureUIState === JobFeatureUIState.Active) {
      return (
        <BodySmall italic color={colors.grayscale.gray500}>
          Start delegating phone screens to your Dover Interviewer
        </BodySmall>
      );
    }
    return <DoverInterviewerInactiveDescription />;
  }, [jobFeatureUIState]);

  return (
    <>
      <TemplateCard
        imageComponent={shouldShowImage ? <DoverInterviewerImg /> : undefined}
        title={DOVER_INTERVIEWER_TITLE}
        body={InnerBody}
        actionButton={Actions}
        featureStateBadge={getFeatureStateBadge()}
        learnMoreLink={APP_ROUTE_PATHS.doverInterviewer()}
        featureActiveAndSetup={isDoverInterviewerEnabled && jobFeatureUIState === JobFeatureUIState.Active}
      />

      <JobPitchDrawer />
    </>
  );
};

export const DoverInterviewerCardPreview = (): React.ReactElement => {
  return (
    <TemplateCard
      imageComponent={<DoverInterviewerImg />}
      title={DOVER_INTERVIEWER_TITLE}
      body={<DoverInterviewerInactiveDescription />}
    />
  );
};
