/* eslint-disable react/no-unstable-nested-components */
import { format } from "date-fns";
import React, { useMemo } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import DoverLogoSVG from "assets/logos/DoverD-small.svg";
import { Tooltip } from "components/library/Tooltip";
import { Overline } from "components/library/typography";
import { NextAction, Interview, PipelineCandidate } from "services/openapi";
import { theme } from "styles/theme";
import { isMultipartStage } from "utils/isStage";
import { getNextActionColors, getNextActionTitle } from "views/CandidateDetail/components/next-action/utils/helpers";
import { getInterviewerNameFromNextAction } from "views/CandidateDetail/utils";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";
import { NextUpLabelEnum } from "views/candidates/types";

interface Props {
  nextAction: NextAction;
  candidate: PipelineCandidate;
  condensed?: boolean;
  hideTooltip?: boolean;
}

export const NextUpCell = ({ nextAction, candidate, condensed, hideTooltip }: Props): React.ReactElement => {
  const colors = getNextActionColors(nextAction, candidate);
  const title = useMemo(() => {
    return getNextActionTitle(nextAction, candidate);
  }, [nextAction, candidate]);

  const NextActionBox = ({ showInterviewer }: { showInterviewer?: boolean }): React.ReactElement => {
    return (
      <StyledNextActionBox colors={colors} condensed={condensed!}>
        <Overline inline>{title}</Overline>{" "}
        {showInterviewer && <InterviewerAvatar interview={nextAction.currentInterview} />}
      </StyledNextActionBox>
    );
  };

  const CellContent = (): React.ReactElement => {
    if (!title) {
      return <></>;
    }

    if (title === NextUpLabelEnum.DoverScheduling) {
      return <NextActionBox />;
    }

    if (title === NextUpLabelEnum.InterviewScheduled) {
      return (
        <Tooltip title={hideTooltip ? "" : getScheduledToolTip(nextAction)} placement="top">
          <div>
            <NextActionBox showInterviewer />
          </div>
        </Tooltip>
      );
    }

    return <NextActionBox />;
  };

  return (
    <StyledNextUpCell condensed={condensed}>
      <CellContent />
    </StyledNextUpCell>
  );
};

const Avatar = styled.div`
  background: ${theme.backgrounds.white};
  border: 1px solid ${theme.colors.grayscale.gray200};
  border-radius: 12px;
  display: inline-block;
  font-size: 10px;
  height: 24px;
  margin-left: 4px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 24px;
`;
const Initials = styled.div`
  left: 6px;
  position: absolute;
  top: 3px;
`;
const DoverLogoContainer = styled(ReactSVG)`
  left: -4px;
  position: absolute;
  top: 2px;
  svg {
    height: 18px;
  }
`;

const InterviewerAvatar = ({ interview }: { interview?: Interview }): React.ReactElement => {
  if (interview?.hasDoverInterviewer) {
    return (
      <Avatar>
        <DoverLogoContainer src={DoverLogoSVG} />
      </Avatar>
    );
  }
  if (interview?.interviewer && interview.interviewer.firstName) {
    let initials = interview.interviewer.firstName.charAt(0);
    if (interview.interviewer.lastName) {
      initials += interview.interviewer.lastName.charAt(0);
    }
    return (
      <Avatar>
        <Initials>{initials}</Initials>
      </Avatar>
    );
  }

  return <></>;
};

const getScheduledToolTip = (nextAction: NextAction): string => {
  let title = "Scheduled";
  const isMultipartInterview = !!nextAction?.hiringPipelineStage && isMultipartStage(nextAction.hiringPipelineStage);
  if (!isMultipartInterview) {
    const interviewerName = getInterviewerNameFromNextAction(nextAction);
    title += ` with ${interviewerName}`;
  }
  const interviewStartDate = nextAction.currentInterview?.startDate;
  if (interviewStartDate) {
    title += `: ${format(interviewStartDate, "MMM d, h:mmaaa")}`;
  }

  return title;
};

interface NextActionBoxProps {
  colors: { backgroundColor: string; borderColor: string; headerTextColor: string };
  condensed: boolean;
}

const StyledNextActionBox = styled.span<NextActionBoxProps>`
  border-radius: 4px;
  padding-top: ${(props): any => (props.condensed ? "2px" : "10px")};
  padding-bottom: ${(props): any => (props.condensed ? "5px" : "10px")};
  padding-left: ${(props): any => (props.condensed ? "8px" : "10px")};
  padding-right: ${(props): any => (props.condensed ? "8px" : "10px")};
  background: ${({ colors }): string => colors.backgroundColor};
  color: ${({ colors }): string => colors.headerTextColor};
  white-space: nowrap;
`;

const StyledNextUpCell = styled(Cell)<{ condensed?: boolean }>`
  padding: ${(props): any => (props.condensed ? "0px" : "16px")};
`;
