/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MaskedEmail,
    MaskedEmailFromJSON,
    MaskedEmailFromJSONTyped,
    MaskedEmailToJSON,
} from './';

/**
 * 
 * @export
 * @interface RetrieveEmailsForLinkedinPublicUrlResponse
 */
export interface RetrieveEmailsForLinkedinPublicUrlResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RetrieveEmailsForLinkedinPublicUrlResponse
     */
    success: boolean;
    /**
     * 
     * @type {Array<MaskedEmail>}
     * @memberof RetrieveEmailsForLinkedinPublicUrlResponse
     */
    emails: Array<MaskedEmail>;
}

export function RetrieveEmailsForLinkedinPublicUrlResponseFromJSON(json: any): RetrieveEmailsForLinkedinPublicUrlResponse {
    return RetrieveEmailsForLinkedinPublicUrlResponseFromJSONTyped(json, false);
}

export function RetrieveEmailsForLinkedinPublicUrlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetrieveEmailsForLinkedinPublicUrlResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'emails': ((json['emails'] as Array<any>).map(MaskedEmailFromJSON)),
    };
}

export function RetrieveEmailsForLinkedinPublicUrlResponseToJSON(value?: RetrieveEmailsForLinkedinPublicUrlResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'emails': ((value.emails as Array<any>).map(MaskedEmailToJSON)),
    };
}


