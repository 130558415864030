import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import axios, { AxiosResponse } from "axios";

// import { appConfig } from "config/appConfig";
// import GetApiUri, { GetSuperApiUri } from "services/api_config";
import GetApiUri from "services/api_config";
import config from "services/auth_config";
import { ApiApi, Configuration, ConfigurationParameters, HooksApi, TestApi } from "services/openapi";

const instance = axios.create({
  baseURL: GetApiUri(),
});

const TIER2_TITLES_LIST_API_ROUTE = "/api/v1/admin/titles-and-seniorities";

export const getTier2Titles = async (): Promise<AxiosResponse> => {
  return await instance.get(TIER2_TITLES_LIST_API_ROUTE);
};

export const setToken = (token: string): void => {
  // Add JWT on each request
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

let auth0Client: Auth0Client;
export const setOpenApiAuth0Client = (givenAuth0Client: Auth0Client): void => {
  auth0Client = givenAuth0Client;
};

export const getToken = async (): Promise<string> => {
  if (!auth0Client) {
    auth0Client = await createAuth0Client({
      domain: config.domain,
      client_id: config.clientId,
      audience: config.audience,
      useRefreshTokens: true,
      cacheProvider: "localstorage",
      cacheLocation: "localstorage",
    });
  }

  try {
    return await auth0Client.getTokenSilently();
  } catch (e) {
    console.error("Error fetching auth0 token", e);
    return "";
  }
};

export interface OpenApiClients {
  apiApi: ApiApi;
  hooksApi: HooksApi;
  testApi: TestApi;
}

// Globals to store all clients with and without auth tokens.
let openApiClientsWithToken: OpenApiClients;
let openApiClientsWithoutToken: OpenApiClients;

// Stores SuperAPI clients
// let openApiSuperApi: OpenApiClients;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getOpenApiClients = async ({ includeToken = true, useSuperApi = false }): Promise<OpenApiClients> => {
  // if (useSuperApi) {
  //   if (openApiSuperApi) {
  //     return openApiSuperApi;
  //   }
  // } else {
  // Retrieve our clients from a singleton if we've called this previously.
  if (includeToken && openApiClientsWithToken) {
    return openApiClientsWithToken;
  } else if (!includeToken && openApiClientsWithoutToken) {
    return openApiClientsWithoutToken;
  }
  // }

  // Otherwise, instantiate new clients.
  // const basePath = useSuperApi ? GetSuperApiUri() : GetApiUri();
  const basePath = GetApiUri();
  const configParams: ConfigurationParameters = { basePath };
  if (includeToken) {
    const token = await getToken();

    if (token) configParams.apiKey = `Bearer ${token}`;
  }
  configParams.headers = { "X-DOVER-ORIGIN": "DOVER_PRO" };
  // if (useSuperApi) {
  //   configParams.headers = { ...configParams.headers, ...appConfig.superApiHeaders };
  // }

  const apiConfig = new Configuration(configParams);

  const openApiClients = {
    apiApi: new ApiApi(apiConfig),
    hooksApi: new HooksApi(apiConfig),
    testApi: new TestApi(apiConfig),
  };

  // Cache our clients into a singleton.
  // if (useSuperApi) {
  //   openApiSuperApi = openApiClients;
  // } else {
  if (includeToken) {
    openApiClientsWithToken = openApiClients;
  } else {
    openApiClientsWithoutToken = openApiClients;
  }
  // }

  return openApiClients;
};

export const isSuperAPIAllowedForNodeEnv = (): boolean => {
  return process.env.NODE_ENV !== "development";
};
