import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import styled from "styled-components";

const LoadingStyle = styled.div`
  width: 100%;
  color: ${(props): any => props.theme.colors.informational.dark};
`;

const HorizontalLoader = (): React.ReactElement => (
  <LoadingStyle>
    <LinearProgress color="inherit" />
  </LoadingStyle>
);

export default HorizontalLoader;
