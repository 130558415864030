import { ReactSVG, Props } from "react-svg";
import styled from "styled-components";

import { colors } from "styles/theme";

export const StyledHelpIconSVG = styled(ReactSVG)<Props>`
  display: flex;
  justify-content: flex-start;

  :hover {
    cursor: pointer;
  }

  svg {
    &:hover {
      path {
        stroke: ${colors.link};
      }
    }
    path {
      stroke: ${colors.grayscale.gray400};
    }
  }
`;

export const StyledSettingsIcon = styled(ReactSVG)<Props>`
  display: flex;
  align-items: center;
  margin: 0px 3px;
  svg {
    path {
      fill: ${colors.link};
    }
  }
`;
