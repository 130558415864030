import { Progress } from "@doverhq/dover-ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, DialogTitle, Drawer, Stack } from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { useForm, useController, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import {
  EmailTemplateDrawerAction,
  EmailTemplateDrawerConfigAtom,
  ToggleUnsavedChangesModalAtom,
} from "components/dover/EmailTemplates/atoms";
import { substituteVariablesForPreviewEmail } from "components/dover/EmailTemplates/helpers";
import { EmailTemplateSchema, EmailTemplateSchemaType } from "components/dover/EmailTemplates/types";
import { useValidate } from "components/dover/top-level-modal-manager/hooks/useValidate";
import { WarningBanner } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/WarningBanner";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { EmailEditorMode, EmailEditorModeAtom } from "components/library/TipTap/atoms";
import { EmailEditor } from "components/library/TipTap/EmailEditor";
import { BasicEmailOption } from "components/library/TipTap/types";
import { Body } from "components/library/typography";
import { UnsavedChangesModal } from "components/UnsavedChangesModal";
import { VariableSelector } from "components/VariableSelector";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import {
  usePartialUpdateClientEmailTemplateMutation,
  useCreateClientEmailTemplateMutation,
} from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { colors, screenSizes } from "styles/theme";
import { toastOptions } from "utils/showToast";

const PLACEHOLDER_TO = { id: "", email: "candidate@email.com", label: "Recipient Email" };
const EmailTemplateDrawer: React.FC<GlobalModalProps> = ({ isOpen, close }) => {
  // state
  const [emailTemplateDrawerConfig, setEmailTemplateDrawerConfig] = useAtom(EmailTemplateDrawerConfigAtom);
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useAtom(ToggleUnsavedChangesModalAtom);

  const selectedEmailEditorTab = useAtomValue(EmailEditorModeAtom);
  const inEditMode = selectedEmailEditorTab === EmailEditorMode.Edit;

  // config for the email tempalte being edited (if any)
  const { onCreateCallback, template: emailTemplate, action: emailTemplateAction } = emailTemplateDrawerConfig;

  const clientId = useGetClientId();

  //mutations
  const [createClientEmailTemplate] = useCreateClientEmailTemplateMutation();
  const [updateClientEmailTemplate, { isLoading: isUpdatingCET }] = usePartialUpdateClientEmailTemplateMutation();

  const isLoading = isUpdatingCET;

  const templateName = emailTemplate?.name ?? "";
  const templateBody = emailTemplate?.bodyTemplate ?? "";
  const templateSubject = emailTemplate?.subjectTemplate ?? "";
  const templateCcEmails = (emailTemplate?.ccEmails ?? []).map(cc => ({ email: cc, id: uuidv4() }));
  const templateBccEmails = (emailTemplate?.bccEmails ?? []).map(bcc => ({ email: bcc, id: uuidv4() }));

  const defaultEditorValues: EmailTemplateSchemaType = {
    name: templateName,
    body: templateBody,
    subject: templateSubject,
    cc: templateCcEmails,
    bcc: templateBccEmails,
  };

  // RHF
  const formMethods = useForm<EmailTemplateSchemaType>({
    resolver: zodResolver(EmailTemplateSchema),
    defaultValues: defaultEditorValues,
  });
  const { control, handleSubmit, reset, register, formState } = formMethods;
  const formErrors = formState.errors;
  const isDirty = formState.isDirty;

  const { field: nameField } = useController({
    name: "name",
    control,
  });
  const { field: bodyField } = useController({
    name: "body",
    control,
  });
  const { field: subjectField } = useController({
    name: "subject",
    control,
  });
  const { field: ccField } = useController({
    name: "cc",
    control,
  });
  const { field: bccField } = useController({
    name: "bcc",
    control,
  });

  const { warning, tooltip, disabledMap } = useValidate("TemplateOnly", {
    subject: subjectField.value ?? "",
    body: bodyField.value ?? "",
  });

  useEffect(() => {
    if (emailTemplate) {
      reset({
        name: emailTemplate.name,
        body: emailTemplate?.bodyTemplate ?? "",
        subject: emailTemplate?.subjectTemplate ?? "",
        cc: (emailTemplate?.ccEmails ?? []).map(cc => ({ email: cc, id: uuidv4() })),
        bcc: (emailTemplate?.bccEmails ?? []).map(bcc => ({ email: bcc, id: uuidv4() })),
      });
    }
  }, [emailTemplate, reset]);

  // substituted text for preview mode
  const substitutedBodyText = substituteVariablesForPreviewEmail(bodyField.value);
  const substitutedSubjectText = substituteVariablesForPreviewEmail(subjectField.value)
    ?.replace(/<\/?p>/g, "")
    .replace(/&nbsp;/g, " ");

  // callbacks
  const onSubmit = async (): Promise<void> => {
    switch (emailTemplateAction) {
      case EmailTemplateDrawerAction.Update:
        await updateTemplate();
        return;
      case EmailTemplateDrawerAction.Create:
        await createTemplate();
        return;
      // Safety check
      case EmailTemplateDrawerAction.Delete:
        return;
      default:
        return;
    }
  };

  const createTemplate = async (): Promise<void> => {
    if (!clientId) {
      return;
    }

    const serializedCc = ccField.value?.map((cc: BasicEmailOption) => cc.email) ?? [];
    const serializedBcc = bccField.value?.map((bcc: BasicEmailOption) => bcc.email) ?? [];

    const createTemplateArgs = {
      data: {
        name: nameField.value ?? "",
        client: clientId,
        bodyTemplate: bodyField.value ?? "",
        subjectTemplate: subjectField.value ?? "",
        ccEmails: serializedCc,
        bccEmails: serializedBcc,
      },
    };

    const createTemplatePromise = createClientEmailTemplate(createTemplateArgs).unwrap();
    try {
      const result = await toast.promise(
        createTemplatePromise,
        {
          pending: "Creating template..",
          error: "Error creating template",
        },
        { ...toastOptions }
      );
      onCreateCallback?.(result);
    } catch (e) {
      console.error(e);
    } finally {
      onCloseDrawer();
    }

    return;
  };

  const updateTemplate = async (): Promise<void> => {
    if (!emailTemplate?.id || !clientId) {
      return;
    }

    const serializedCc = ccField.value?.map((cc: BasicEmailOption) => cc.email) ?? [];
    const serializedBcc = bccField.value?.map((bcc: BasicEmailOption) => bcc.email) ?? [];

    const updateTemplateArgs = {
      id: emailTemplate.id,
      data: {
        name: nameField.value ?? "",
        client: clientId,
        bodyTemplate: bodyField.value ?? "",
        subjectTemplate: subjectField.value ?? "",
        ccEmails: serializedCc,
        bccEmails: serializedBcc,
      },
    };

    const updateClientEmailTemplatePromise = updateClientEmailTemplate(updateTemplateArgs).unwrap();
    try {
      await toast.promise(
        updateClientEmailTemplatePromise,
        {
          error: "Error updating template",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
    } finally {
      onCloseDrawer();
    }
    return;
  };

  const onCloseDrawer = (): void => {
    reset(
      {
        name: undefined,
        body: undefined,
        from: undefined,
        subject: undefined,
        cc: undefined,
        bcc: undefined,
      },
      { keepValues: false }
    );
    setEmailTemplateDrawerConfig({
      template: undefined,
      action: undefined,
    });
    close();
  };

  return (
    <Drawer
      open={isOpen}
      onClose={(): void => (isDirty ? setUnsavedChangesModalOpen(true) : onCloseDrawer())}
      title="New Template"
      anchor="right"
      style={{
        zIndex: 1500,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          maxWidth: screenSizes.laptop,
          width: "60vw",
          padding: "16px",
          paddingTop: 0,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      {isLoading ? (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
          <Progress size="large" />
        </Box>
      ) : (
        <Stack justifyContent={"space-between"} height="100%">
          <Stack>
            <DialogTitle sx={{ fontWeight: "600", paddingLeft: "0px" }}>
              {emailTemplateDrawerConfig.action === EmailTemplateDrawerAction.Create ? "New Template" : "Edit Template"}
            </DialogTitle>
            <FormProvider {...formMethods}>
              <UnsavedChangesModal
                open={unsavedChangesModalOpen}
                onCancel={(): void => {
                  setUnsavedChangesModalOpen(false);
                }}
                onDiscard={(): void => {
                  setUnsavedChangesModalOpen(false);
                  onCloseDrawer();
                }}
                showSave={false}
                customDialogStyles={{ zIndex: 1500 }}
              />
              <Stack display="flex" flexDirection="column">
                <Stack spacing={2} flexGrow={1}>
                  <TextField
                    title="Name"
                    disabled={isLoading}
                    inputProps={{ ...register("name") }}
                    onTextUpdated={nameField.onChange}
                    required
                    error={!!formErrors.name}
                    errorMessage={!nameField.value ? formErrors?.name?.message : undefined}
                  />
                  <Stack direction="row" spacing={2}>
                    <Stack flexGrow={2} spacing={1}>
                      {warning && <WarningBanner warning={{ ...warning, useErrorColor: true }} />}
                      <Body weight="600">Email content</Body>
                      <Stack direction="row" spacing={2} minHeight="528px">
                        <EmailEditor
                          showPreviewTab={true}
                          to={PLACEHOLDER_TO}
                          body={inEditMode ? bodyField.value ?? templateBody : substitutedBodyText}
                          subject={inEditMode ? subjectField.value ?? templateSubject : substitutedSubjectText}
                          cc={ccField.value as BasicEmailOption[]}
                          bcc={bccField.value as BasicEmailOption[]}
                          readOnly={!inEditMode}
                          onBodyChanged={bodyField.onChange}
                          onSubjectChanged={subjectField.onChange}
                          onBccChanged={bccField.onChange}
                          onCcChanged={ccField.onChange}
                          skipSetContent={true}
                          hideSaveTemplateButton
                        />
                        <Stack maxWidth="48%">
                          <VariableSelector isMultipart={false} />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </FormProvider>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
            sx={{
              backgroundColor: colors.white,
              padding: "16px 8px 0px 16px",
              borderTop: `1px solid ${colors.grayscale.gray200}`,
            }}
            bottom={0}
          >
            <Button
              variant={ButtonVariant.Primary}
              onClick={handleSubmit(onSubmit)}
              loading={isLoading}
              disabled={disabledMap.Send || !nameField.value}
              tooltip={tooltip || warning?.title || (!nameField.value ? "Template Name is required" : undefined)}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      )}
    </Drawer>
  );
};

export const EmailTemplateDrawerAtom = modalAtom(EmailTemplateDrawer);
