import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, MenuItem, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useState } from "react";

import { BodySmall } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetJobFeatureUIState } from "services/doverapi/cross-endpoint-hooks/useGetJobFeatureUIState";
import { JobFeatureUIState } from "services/doverapi/endpoints/job/types";
import {
  useGetJobFeaturesQuery,
  useUpsertJobFeatureSettingMutation,
} from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import {
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";

export const InterviewPlanSchedulingSettings = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const jobId = useJobIdFromUrl();

  const jobFeatureUIState = useGetJobFeatureUIState({
    featureName: JobFeatureFeatureNameEnum.ScheduleInitialCustomerCallOnly,
    jobId,
    allowAutoEnablement: true,
  });
  const { isFetching: jobFeaturesFetching } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);

  const [upsertJobFeatureSetting, { isLoading: jobFeatureSettingIsUpdating }] = useUpsertJobFeatureSettingMutation();

  const updateScheduleInitialCustomerCallOnly = React.useCallback(
    (jobFeatureState: UpsertJobFeatureSettingStateEnum) => {
      if (!jobId) {
        return;
      }
      upsertJobFeatureSetting({
        upsertData: {
          data: {
            job: jobId,
            featureName: UpsertJobFeatureSettingFeatureNameEnum.ScheduleInitialCustomerCallOnly,
            state: jobFeatureState,
          },
        },
      });
    },
    [jobId, upsertJobFeatureSetting]
  );

  const handleEditButtonClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const menuOpen = Boolean(anchorEl);

  return (
    <Stack direction="row" spacing={2}>
      <BodySmall>Dover handles scheduling</BodySmall>
      {jobFeaturesFetching || jobFeatureSettingIsUpdating ? (
        <Skeleton width={300} height={24} />
      ) : (
        <BodySmall
          style={{
            fontWeight: 700,
            cursor: "pointer",
          }}
          onClick={handleEditButtonClick}
        >
          {jobFeatureUIState === JobFeatureUIState.Active
            ? "Until someone on our team completes a call"
            : "For all interview stages"}
          <KeyboardArrowDownIcon style={{ marginLeft: "2px" }} />
        </BodySmall>
      )}
      <Menu open={menuOpen} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          onClick={(): void => {
            updateScheduleInitialCustomerCallOnly(UpsertJobFeatureSettingStateEnum.Disabled);
            handleClose();
          }}
          selected={jobFeatureUIState === JobFeatureUIState.Inactive}
        >
          <BodySmall>For all interview stages</BodySmall>
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            updateScheduleInitialCustomerCallOnly(UpsertJobFeatureSettingStateEnum.Enabled);
            handleClose();
          }}
          selected={jobFeatureUIState === JobFeatureUIState.Active}
        >
          <BodySmall>Until someone on our team completes a call</BodySmall>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
