import { omit, sortBy } from "lodash";
import _ from "lodash";

import { HiringPlanJobPosition } from "services/openapi";
import {
  NOT_STARTED_OPTION,
  getStatusOptionByValue,
  JOB_POSITION_OPENING_STATUS_ENUM,
} from "views/HiringPlan/constants";
import { HiringPlanTableRow } from "views/HiringPlan/types";

export const isPastLaunchDate = (
  launchDate: Date | null | undefined,
  status?: JOB_POSITION_OPENING_STATUS_ENUM
): string | undefined => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // ensure any time "today" is valid
  if (
    launchDate &&
    status &&
    launchDate < today &&
    getStatusOptionByValue(status)?.value === NOT_STARTED_OPTION.value
  ) {
    return "The launch date has passed";
  }
};

const getMaxStartDate = (jobPositions: HiringPlanJobPosition[]): Date => {
  let maxStartGoalDate = new Date();

  jobPositions.forEach(jobPosition => {
    const jobPositionOpeningWithMaxStartGoalDate = _.maxBy(jobPosition.jobPositionOpenings, function(
      jobPositionOpening
    ) {
      return jobPositionOpening.goalStartDate;
    });

    if (
      jobPositionOpeningWithMaxStartGoalDate &&
      jobPositionOpeningWithMaxStartGoalDate!.goalStartDate! > maxStartGoalDate
    ) {
      maxStartGoalDate = jobPositionOpeningWithMaxStartGoalDate!.goalStartDate!;
    }
  });

  return maxStartGoalDate;
};

export function getMaxGoalStartDate(jobPositions: HiringPlanJobPosition[]): Date {
  return getMaxStartDate(jobPositions);
}

export function getJobPositionStartGoalDates(jobPositions: HiringPlanJobPosition[]): Map<string, Date | undefined> {
  const startGoalDateMap = new Map<string, Date | undefined>();
  jobPositions.forEach(jobPosition => {
    // find jobPositionOpening which will act as first opening
    const firstJobPositionOpening = _.minBy(jobPosition.jobPositionOpenings, "nthOpeningForPosition");
    startGoalDateMap.set(jobPosition.id!, firstJobPositionOpening?.goalStartDate ?? undefined);
  });

  return startGoalDateMap;
}

export function getHighestNthPositionOpeningOnJobPosition(jobPosition: HiringPlanJobPosition): number {
  if (!jobPosition || !jobPosition.jobPositionOpenings) {
    return 0;
  }
  const positionOpenings = jobPosition.jobPositionOpenings.map(
    positionOpening => positionOpening.nthOpeningForPosition
  );
  return Math.max(...positionOpenings);
}

export function getHiringPlanTableRows(
  jobPositions: HiringPlanJobPosition[],
  sortParams: string[] = []
): HiringPlanTableRow[] {
  if (!jobPositions || jobPositions.length === 0) {
    return [];
  }
  // Here we generate an array of HiringPlanTableRows by iterating through each JobPositionOpening
  // of every JobPosition and flatten the values of the JobPosition and JobPositionOpening
  // into a single object as their values are represented in a single row
  const unsortedRows: HiringPlanTableRow[] = [];
  for (const jobPosition of jobPositions) {
    let isFirstOpening = true;
    for (const jobPositionOpening of jobPosition.jobPositionOpenings!) {
      unsortedRows.push({
        jobPositionId: jobPosition.id!,
        jobPosition: jobPosition,
        jobPositionOpening: jobPositionOpening,
        id: jobPositionOpening.id!.toString(), // need to cast this to a string as the model's default id is returned as an int
        isFirstOpening: isFirstOpening,
        ...omit(jobPosition, ["jobPositionOpenings", "id"]),
        ...omit(jobPositionOpening, ["jobPosition", "id"]),
      });
      isFirstOpening = false;
    }
  }

  const sortedRows = sortBy(unsortedRows, sortParams);
  return sortedRows;
}
