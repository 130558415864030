import { Progress } from "@doverhq/dover-ui";
import { Box, Grid, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import { ExternalFooter } from "components/ExternalFooter";
import { BodySmall, Overline } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { SocialLink, SocialLinks } from "components/SocialLinks";
import { useGetCareersPageClientQuery } from "services/doverapi/endpoints/careersPageClient";
import { useListCareersPageJobQuery } from "services/doverapi/endpoints/careersPageJob";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { CareersPageClientDoverPlanEnum, CareersPageJob } from "services/openapi";
import { colors, screenSizes } from "styles/theme";
import { getHtmlFromUnknownContent } from "utils/draftJS";
import { generateRandomizedImageUrl } from "utils/image";
import { JobList } from "views/inboundExternal/CareersPage/JobList";
import { Header, HeaderContent, StyledLogo, HeaderTitle, StyledHtmlWrapper } from "views/inboundExternal/styles";
import Unauthorized from "views/Unauthorized";

const PageWrapper = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  position: relative;
  background-color: white;
  padding-top: 24px;
`;

const PageContent = styled.div`
  padding: 48px;
  display: flex;
  justify-content: center;

  @media (max-width: ${screenSizes.tablet}) {
    padding: 16px;
  }
`;

const PageGrid = styled(Grid)`
  padding: 48px 48px 30px 48px;
`;

const CareersPage = ({ previewClientId }: { previewClientId?: string }): React.ReactElement => {
  const [isEmbed] = useQueryParam("embed", withDefault(BooleanParam, false));

  const { clientId: clientIdParam, clientSlug: clientSlugParam } = useParams<{
    clientId: string;
    clientSlug: string;
  }>();
  const clientSlug = clientSlugParam ?? undefined;
  let clientId = previewClientId ? clientIdParam ?? previewClientId : clientIdParam ?? undefined;

  const { isClientLoading, client, clientLoadingError } = useGetCareersPageClientQuery(
    clientId || clientSlug ? { clientId: clientId, clientSlug: clientSlug } : skipToken,
    {
      selectFromResult: ({ data, isLoading, error }) => {
        return {
          isClientLoading: isLoading,
          client: data,
          clientId: data?.id,
          clientLoadingError: error,
        };
      },
    }
  );

  clientId = client?.id;

  const { jobs, isJobsLoading, isJobsUninitialized } = useListCareersPageJobQuery(clientId ? { clientId } : skipToken, {
    selectFromResult: ({ data, isLoading, isUninitialized }) => {
      const jobs: CareersPageJob[] = listAllEntities(data);
      return {
        jobs,
        isJobsLoading: isLoading,
        isJobsUninitialized: isUninitialized,
      };
    },
  });

  const companyLogo = useMemo((): React.ReactElement => {
    return <StyledLogo src={generateRandomizedImageUrl(client?.logo)} alt=" url" />;
  }, [client?.logo]);

  const headerLogo = useMemo((): React.ReactElement => {
    return (
      <>
        {client?.careersPageLogoRedirectUrl ? (
          <a href={client?.careersPageLogoRedirectUrl}>{companyLogo}</a>
        ) : (
          companyLogo
        )}
      </>
    );
  }, [client?.careersPageLogoRedirectUrl, companyLogo]);

  // @ts-ignore
  if (clientLoadingError?.status === 404 || clientLoadingError?.serializedError?.status === 404) {
    return <Navigate to="/NotFound" />;
  }

  if (isClientLoading) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  if (client?.doverPlan === CareersPageClientDoverPlanEnum.NoAccess) {
    return <Unauthorized errorCodeOverride={CareersPageClientDoverPlanEnum.NoAccess} />;
  }

  // Check for a length of > 1 to account for invsiible characters
  const hasCareersPageInfo = !!client?.careersPageInfo && client?.careersPageInfo.length > 1;

  const loading = isJobsLoading || isJobsUninitialized;

  // Very hacky, temporary way to do embeds
  if (isEmbed) {
    return (
      <PageContent
        style={{
          maxWidth: "1800px",
        }}
      >
        <JobList jobs={jobs} clientName={client?.name} isJobsLoading={loading} isEmbed={isEmbed} />
      </PageContent>
    );
  }

  const pageTitle = client && client.name ? `${client.name} Careers` : "Careers";
  const useNewMetaTags = client?.name === "Trover";

  const hasDisplayStats = client?.careersPageDisplayStats && client.careersPageDisplayStats.length > 0;
  const hasSocialLinks = client?.careersPageSocialLinks && client.careersPageSocialLinks.length > 0;

  return (
    <>
      <PageHelmet
        title={pageTitle}
        description={useNewMetaTags ? pageTitle : undefined}
        image={useNewMetaTags ? client?.logo : undefined}
      />
      <PageWrapper>
        <Header>
          <HeaderContent>
            {client && client.logo !== undefined && <Box mb={2}>{headerLogo}</Box>}
            {client?.name && <HeaderTitle>{client.name} Careers</HeaderTitle>}
          </HeaderContent>
        </Header>
        <Stack direction="row" justifyContent="center">
          {client?.careersPageImage || hasCareersPageInfo ? (
            <PageGrid container spacing={1} columnSpacing={{ xs: 1, md: 8 }} maxWidth="1800px">
              <Grid item xs={12} md={6}>
                {(hasDisplayStats || hasSocialLinks) && (
                  <Grid container direction="row" rowSpacing={1} columnSpacing={2} mb={3}>
                    {hasDisplayStats &&
                      // @ts-ignore
                      client.careersPageDisplayStats.map((stat, index) => (
                        <Grid item key={index}>
                          <Overline color={colors.grayscale.gray500}>{stat.label}</Overline>
                          <BodySmall color={colors.grayscale.gray700}>{stat.value}</BodySmall>
                        </Grid>
                      ))}
                    {hasSocialLinks && (
                      <Grid item>
                        <Stack spacing={0.25}>
                          <Overline color={colors.grayscale.gray500}>{"Links"}</Overline>
                          {/* @ts-ignore */}
                          <SocialLinks socialLinks={(client.careersPageSocialLinks as unknown) as SocialLink[]} />
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                )}
                {client?.careersPageInfo && (
                  <StyledHtmlWrapper>
                    {ReactHtmlParser(getHtmlFromUnknownContent(client?.careersPageInfo))}
                  </StyledHtmlWrapper>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <JobList jobs={jobs} clientName={client?.name} isJobsLoading={loading} />
              </Grid>
            </PageGrid>
          ) : (
            <PageContent
              style={{
                maxWidth: "1800px",
              }}
            >
              <JobList jobs={jobs} clientName={client?.name} isJobsLoading={loading} />
            </PageContent>
          )}
        </Stack>
      </PageWrapper>
      <ExternalFooter client={client} />
    </>
  );
};

export default CareersPage;
