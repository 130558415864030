/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultipartSchedulingClientInterviewerAvailableTime,
    MultipartSchedulingClientInterviewerAvailableTimeFromJSON,
    MultipartSchedulingClientInterviewerAvailableTimeFromJSONTyped,
    MultipartSchedulingClientInterviewerAvailableTimeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MultipartSchedulingClientInterviewerAvailability
 */
export interface MultipartSchedulingClientInterviewerAvailability {
    /**
     * 
     * @type {Array<MultipartSchedulingClientInterviewerAvailableTime>}
     * @memberof MultipartSchedulingClientInterviewerAvailability
     */
    readonly availableTimes?: Array<MultipartSchedulingClientInterviewerAvailableTime>;
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingClientInterviewerAvailability
     */
    timezone: string;
}

export function MultipartSchedulingClientInterviewerAvailabilityFromJSON(json: any): MultipartSchedulingClientInterviewerAvailability {
    return MultipartSchedulingClientInterviewerAvailabilityFromJSONTyped(json, false);
}

export function MultipartSchedulingClientInterviewerAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipartSchedulingClientInterviewerAvailability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableTimes': !exists(json, 'available_times') ? undefined : ((json['available_times'] as Array<any>).map(MultipartSchedulingClientInterviewerAvailableTimeFromJSON)),
        'timezone': json['timezone'],
    };
}

export function MultipartSchedulingClientInterviewerAvailabilityToJSON(value?: MultipartSchedulingClientInterviewerAvailability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timezone': value.timezone,
    };
}


