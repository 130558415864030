import { Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { MARKETING_HOMEPAGE_URL } from "App/appConstants";
import DoverLogo from "components/icons/DoverLogo";
import { BodySmall } from "components/library/typography";
import { CareersPageClient } from "services/openapi";
import { colors } from "styles/theme";

interface Props {
  client?: CareersPageClient;
}

function slugify(str: string): string {
  return str
    .replace(/^\s+|\s+$/g, "") // convert string to lowercase
    .toLowerCase() // convert string to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove any non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}

export const ExternalFooter = ({ client }: Props): React.ReactElement => {
  const clientName = slugify(client?.name || "") || "unknown";
  return (
    <Stack
      color={colors.black}
      p={4}
      spacing={2}
      mt={2}
      bgcolor={colors.grayscale.gray100}
      boxShadow="0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)"
      alignItems="center"
      textAlign="center"
    >
      <Link to={`${MARKETING_HOMEPAGE_URL}?utm_medium=careers_page&utm_source=${clientName}&utm_campaign=dover_logo`}>
        <Stack spacing={0.5} direction="row" alignItems="center">
          <BodySmall>Jobs powered by </BodySmall>
          <DoverLogo width={60} />
        </Stack>
      </Link>
      <BodySmall>
        <Link to={`/${client?.name}/careers/${client?.id}/audit`}>
          {client?.name} is compliant with AI Employment Regulation
        </Link>
      </BodySmall>
    </Stack>
  );
};
