import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useListInterviewerEntities, useListInterviewersQuery } from "services/doverapi/endpoints/interviewer";
import { ClientInterviewer } from "services/openapi";

interface Props {
  initialInterviewerId: string;
  disabled?: boolean;
  onChange: (option: ClientInterviewer) => void;
}
const ClientInterviewerPicker = ({ initialInterviewerId, onChange, disabled }: Props): React.ReactElement => {
  const clientInterviewers = useListInterviewerEntities();
  const { isSuccess: initialized } = useListInterviewersQuery();

  const [option, setOption] = useState<ClientInterviewer | null>(null);

  useEffect(() => {
    setOption(clientInterviewers.find(i => i.id === initialInterviewerId) || null);
  }, [clientInterviewers, initialInterviewerId]);

  return (
    <Autocomplete
      value={option}
      onChange={(event: any, newOption: any): void => {
        setOption(newOption);
        onChange(newOption);
      }}
      renderInput={(params: any): React.ReactElement => (
        <TextField
          {...params}
          label={<span>Interviewer {!initialized && <CircularProgress size={14} />}</span>}
          fullWidth
        />
      )}
      options={clientInterviewers}
      getOptionLabel={(clientInterviewer: ClientInterviewer): string => clientInterviewer.email}
      disabled={disabled}
      blurOnSelect
    />
  );
};

export default ClientInterviewerPicker;
