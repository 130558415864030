// Here we augment seniorities with additional info
// First is list order (best not to really on key orders since ECMAScript does not guarantee iterator order)

import { SeniorityCategory, SeniorityExtraInfo } from "services/doverapi/endpoints/search-v3/types";
import { Seniority } from "services/openapi";

// Second is category for control purposes in form data
export const SeniorityNameToExtraInfo: Map<string, SeniorityExtraInfo> = new Map([
  ["Unknown", { listOrder: 0, category: SeniorityCategory.NonManager }],
  ["Early career", { listOrder: 1, category: SeniorityCategory.NonManager }],
  ["Mid career", { listOrder: 2, category: SeniorityCategory.NonManager }],
  ["Late career", { listOrder: 3, category: SeniorityCategory.NonManager }],
  ["Lead", { listOrder: 4, category: SeniorityCategory.ManagerAndNonManager }],
  ["Manager", { listOrder: 5, category: SeniorityCategory.Manager }],
  ["Director", { listOrder: 6, category: SeniorityCategory.Manager }],
  ["VP", { listOrder: 7, category: SeniorityCategory.Miscellaneous }],
  ["Founder", { listOrder: 8, category: SeniorityCategory.Manager }],
  ["C-level", { listOrder: 9, category: SeniorityCategory.Miscellaneous }],
  ["Freelance", { listOrder: 10, category: SeniorityCategory.NonManager }],
  ["Student", { listOrder: 11, category: SeniorityCategory.Miscellaneous }],
  ["Intern", { listOrder: 12, category: SeniorityCategory.Miscellaneous }],
]);

// NOTE(willjow): This just a hard-coded placeholder until we properly
// implement unknown seniority on the backend; remove this after
// we've done so
export const unknownSeniorityOption: Seniority = { id: 0, name: "Unknown" };
