import { Mutator } from "final-form";
import { isNil } from "lodash";

import {
  JobSourceFormValues,
  EnrichedCandidateSourceDetails,
  SourceStatus,
} from "services/doverapi/endpoints/job-source-settings/types";
import {
  CandidateSource,
  CandidateSourceFeaturesEnum,
  CandidateSourceNotReadyDetailsReasonEnum,
  Client,
  JobCandidateSourceSetting,
  JobCandidateSourceSettingDesiredStateEnum,
  JobCandidateSourceSettingExternalJobOriginEnum,
} from "services/openapi";
import { JobSourceFormErrors } from "views/job/JobBoards/components/ConfigureSource/types";

export const setValue: Mutator<JobSourceFormValues> = ([name, newValue], state, { changeValue }) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  changeValue(state, name, _ => newValue);
};

export const isOneClickIntegration = (source: CandidateSource): boolean => {
  return source.features?.includes(CandidateSourceFeaturesEnum.DoverIntegration) ?? false;
};

export const isFreeIntegration = (source: CandidateSource, selectedPricingTier?: number): boolean => {
  return selectedPricingTier === 0 && isOneClickIntegration(source);
};

export const isClientAllowedToPostToSource = (source: CandidateSource, client: Client | undefined): boolean => {
  if (!client?.isAgency) return true;
  return !source.features?.includes(CandidateSourceFeaturesEnum.AgencyPostingNotAllowed);
};

const getDefaultValuesForSource = (source: CandidateSource): JobSourceFormValues => {
  let externalJobOrigin: JobCandidateSourceSettingExternalJobOriginEnum | undefined = undefined;
  if (source.supportsCreatingExternalJob) {
    externalJobOrigin = JobCandidateSourceSettingExternalJobOriginEnum.Dover;
  } else if (source.supportsExistingExternalJob) {
    externalJobOrigin = JobCandidateSourceSettingExternalJobOriginEnum.Client;
  }

  // If the source is an ATS source, then set a reasonable default for atsInboundRejectionEnabled;
  let atsInboundRejectionEnabled: "true" | "false" | undefined = undefined;
  if (source.features?.includes(CandidateSourceFeaturesEnum.AtsIntegrated)) {
    atsInboundRejectionEnabled = "false";
  }
  let pricingTierId = null;
  if (source.pricingTiers && source.pricingTiers.length) {
    pricingTierId = source.pricingTiers[0].id;
  }

  return {
    desiredState: source.enabledByDefault
      ? JobCandidateSourceSettingDesiredStateEnum.Active
      : JobCandidateSourceSettingDesiredStateEnum.Inactive,
    externalJobOrigin,
    atsInboundRejectionEnabled: atsInboundRejectionEnabled,
    shouldAutoRenew: source.shouldAutoRenewDefault,
    pricingTierId,
  };
};

export const getInitialValues = (
  enrichedCandidateSourceDetails: EnrichedCandidateSourceDetails
): JobSourceFormValues => {
  const { candidateSource, jobCandidateSourceSettings } = enrichedCandidateSourceDetails;

  // Default to values from the source itself
  const initialValues: JobSourceFormValues = getDefaultValuesForSource(candidateSource);

  // If we already have a source setting and it has populated values, use them.
  if (jobCandidateSourceSettings) {
    const {
      desiredState,
      externalJobOrigin,
      externalJobIdentifier,
      atsInboundRejectionEnabled,
      additionalInfo,
      shouldAutoRenew,
      pricingTier,
    } = jobCandidateSourceSettings;
    initialValues.desiredState = desiredState;
    initialValues.atsInboundRejectionEnabled = atsInboundRejectionEnabled ? "true" : "false";

    if (!isNil(shouldAutoRenew)) {
      initialValues.shouldAutoRenew = shouldAutoRenew;
    }

    if (!isNil(pricingTier?.id)) {
      initialValues.pricingTierId = pricingTier?.id;
    }

    if (jobCandidateSourceSettings.externalJobOrigin) {
      initialValues.externalJobOrigin = externalJobOrigin;
    }
    if (jobCandidateSourceSettings.externalJobIdentifier) {
      initialValues.externalJobIdentifier = externalJobIdentifier;
    }
    if (jobCandidateSourceSettings.additionalInfo) {
      initialValues.additionalInfo = additionalInfo;
    }
  }
  return initialValues;
};

export const validateFormValues = ({
  source,
  values,
}: {
  source: CandidateSource;
  values: JobSourceFormValues;
}): JobSourceFormErrors => {
  const errors: { [key: string]: string } = {};

  // When deactivating a source, we want to ignore any other form errors/required fields and return early.
  if (values.desiredState === JobCandidateSourceSettingDesiredStateEnum.Inactive) {
    return errors;
  }

  if (source.supportsExistingExternalJob && source.supportsCreatingExternalJob) {
    if (!values.externalJobOrigin) {
      errors.desiredState = "Select one of the two options below.";
    }
  }
  if (
    (source.features ?? []).includes(CandidateSourceFeaturesEnum.SupportsAdditionalInfo) &&
    (!values.additionalInfo || values.additionalInfo.length === 0)
  ) {
    errors.additionalInfo = "This field cannot be empty.";
  }

  return errors;
};

export const turnToMonthEnd = (
  jobCandidateSourceSettings: JobCandidateSourceSetting | undefined
): string | undefined => {
  if (!jobCandidateSourceSettings) {
    return jobCandidateSourceSettings;
  }
  return (
    jobCandidateSourceSettings?.currentPeriodEnd?.toLocaleString("en-US", {
      month: "short",
    }) ?? ""
  );
};

/**
 * @description For an active Job Candidate Source Setting, returns whether or not the subscription details should be shown
 * @param enrichedCandidateSourceDetails
 * @param isFreeCustomer
 * @returns boolean
 */
export const showSubscriptionDetailsForActiveSource = ({
  enrichedCandidateSourceDetails,
  isFreeCustomer,
}: {
  enrichedCandidateSourceDetails: EnrichedCandidateSourceDetails;
  isFreeCustomer: boolean;
}): boolean | undefined => {
  if (!enrichedCandidateSourceDetails) {
    return undefined;
  }

  const { candidateSource, jobCandidateSourceSettings, status } = enrichedCandidateSourceDetails;

  if (!candidateSource || !jobCandidateSourceSettings || !status) {
    return undefined;
  }

  if (![SourceStatus.SettingUp, SourceStatus.Active].includes(status)) {
    return false;
  }

  const sourceIsFreeIntegration = isOneClickIntegration(candidateSource);
  const { externalJobOrigin } = jobCandidateSourceSettings;

  // For free customers, only show subscription details for Dover's one-click integrations when they select Dover to set up
  if (isFreeCustomer) {
    return sourceIsFreeIntegration && externalJobOrigin === JobCandidateSourceSettingExternalJobOriginEnum.Dover;
  }

  // Else the customer is on a paid plan, and we want to evaluate whether the Client or Dover posted the Job Board
  if (externalJobOrigin === JobCandidateSourceSettingExternalJobOriginEnum.Client) {
    return false;
  }

  return true;
};

export const getSourceIsReadyForUse = (candidateSource: CandidateSource, selectedPricingTier?: number): boolean => {
  const readyForUse = !!candidateSource.readyForUseInfo?.readyForUse;
  // If the free tier is selected for an integration, ignore the not ready for use details if the reason is missing payment method
  if (isFreeIntegration(candidateSource, selectedPricingTier)) {
    return (
      readyForUse ||
      candidateSource.readyForUseInfo?.notReadyDetails?.reason ===
        CandidateSourceNotReadyDetailsReasonEnum.PaymentMethodRequired
    );
  }
  return readyForUse;
};
