import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import IconButton from "@mui/material/IconButton";
import { SkipToken } from "@reduxjs/toolkit/query";
import React, { FC } from "react";

import { Tooltip } from "components/library/Tooltip";
import { ListPipelineCandidatesArgs } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import {
  useToggleStarredCandidateMutation,
  ToggleStarCandidateArgs,
} from "services/doverapi/endpoints/starredCandidate";
import { colors } from "styles/theme";

interface StarCandidateProps {
  id: string | undefined;
  jobId: string | undefined;
  isStarred: boolean | undefined;
  fullName: string | undefined;
}

interface StarCandidateButtonProps {
  candidate?: StarCandidateProps;
  removePadding?: boolean; // Removes padding
  hideTooltip?: boolean; // Hides the tooltips
  size?: "small" | "inherit" | "large" | "medium" | undefined;
  alwaysShowStarred?: boolean; // Remove the hover css class if starred so that it always shows
  candidateListArgs?: ListPipelineCandidatesArgs | SkipToken;
}

export const StarCandidateButton: FC<StarCandidateButtonProps> = ({
  candidate,
  removePadding: compact,
  hideTooltip,
  size,
  alwaysShowStarred,
  candidateListArgs,
}) => {
  const [toggleStarCandidate, { isLoading }] = useToggleStarredCandidateMutation();

  // Just hide the button if candidate is undefined for any other reason
  if (!candidate) {
    return null;
  }

  const click = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    // This is sometimes inside another clickable element or anchor like the kanban cards
    e.stopPropagation();
    e.preventDefault();

    if (!candidate || !candidate.id || !candidate.fullName || !candidate.jobId) {
      return;
    }

    const args: ToggleStarCandidateArgs = {
      fullName: candidate.fullName,
      jobId: candidate.jobId,
      data: {
        candidateId: candidate.id,
      },
      candidateListArgs: typeof candidateListArgs === "symbol" ? undefined : candidateListArgs,
    };

    toggleStarCandidate(args);
  };

  const isDisabled = isLoading;
  const iconColor = isDisabled ? colors.warning.dark : colors.warning.base;

  return (
    <Tooltip
      arrow
      title={
        hideTooltip
          ? ""
          : isLoading
          ? "Saving..."
          : "Starring a candidate will add them to your sidebar for quick navigation"
      }
    >
      {/* Hacky size fix for nav bar items; could improve maybe */}
      <div
        className={alwaysShowStarred && candidate.isStarred ? undefined : "hover-star-candidate"}
        style={{ height: size === "small" ? "20px" : undefined }}
      >
        <IconButton
          onClick={click}
          disabled={isDisabled}
          sx={{ padding: compact ? 0 : undefined, verticalAlign: "initial" }}
        >
          {candidate.isStarred ? (
            <StarIcon fontSize={size} sx={{ color: iconColor }} />
          ) : (
            <StarBorderOutlinedIcon sx={{ color: iconColor }} />
          )}
        </IconButton>
      </div>
    </Tooltip>
  );
};
