/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

/**
 * CalApi - interface
 * 
 * @export
 * @interface CalApiInterface
 */
export interface CalApiInterface {
    /**
     * Test endpoint to view error state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalApiInterface
     */
    calTestErrorListRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Test endpoint to view error state
     */
    calTestErrorList(): Promise<void>;

    /**
     * Test endpoint to view initial state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalApiInterface
     */
    calTestInitialListRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Test endpoint to view initial state
     */
    calTestInitialList(): Promise<void>;

    /**
     * Test endpoint to view successful calendar auth state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalApiInterface
     */
    calTestSuccessListRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Test endpoint to view successful calendar auth state
     */
    calTestSuccessList(): Promise<void>;

}

/**
 * 
 */
export class CalApi extends runtime.BaseAPI implements CalApiInterface {

    /**
     * Test endpoint to view error state
     */
    async calTestErrorListRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/cal/test/error`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test endpoint to view error state
     */
    async calTestErrorList(): Promise<void> {
        await this.calTestErrorListRaw();
    }

    /**
     * Test endpoint to view initial state
     */
    async calTestInitialListRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/cal/test/initial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test endpoint to view initial state
     */
    async calTestInitialList(): Promise<void> {
        await this.calTestInitialListRaw();
    }

    /**
     * Test endpoint to view successful calendar auth state
     */
    async calTestSuccessListRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/cal/test/success`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test endpoint to view successful calendar auth state
     */
    async calTestSuccessList(): Promise<void> {
        await this.calTestSuccessListRaw();
    }

}
