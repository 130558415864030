import { z } from "zod";

const basicSchema = {
  firstName: z.string().min(1, { message: "Required" }),
  lastName: z.string().min(1, { message: "Required" }),
  email: z
    .string()
    .email({ message: "Invalid email" })
    .optional()
    .or(z.literal(""))
    .nullable(),
  linkedinUrl: z
    .string()
    .regex(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/.+/, { message: "Invalid LinkedIn URL" })
    .optional()
    .or(z.literal("")),
  resume: z.nullable(z.instanceof(File)).optional(),
};

export const interviewPipelineCandidateInfoZodSchema = z
  .object({
    ...basicSchema,
    email: z
      .string()
      .email({ message: "Invalid email" })
      .optional()
      .or(z.literal(""))
      .nullable(),
    source: z.object({
      label: z.string().min(1, { message: "Required" }),
      value: z.string().min(1, { message: "Required" }),
      internalName: z.string().min(1, { message: "Required" }),
    }),
    agencyName: z
      .string()
      .optional()
      .nullable(),
    agencyEmail: z
      .string()
      .optional()
      .nullable(),
  })
  // Require name and email for agency sources.
  // Require name for referrals.
  .refine(data => !(["AGENCY"].includes(data.source.internalName) && !data.agencyEmail), {
    message: "Required",
    path: ["agencyEmail"],
  })
  .refine(data => !(["AGENCY", "CANDIDATE_REFERRED"].includes(data.source.internalName) && !data.agencyName), {
    message: "Required",
    path: ["agencyName"],
  });

export const candidateInfoZodSchema = z
  .object({
    ...basicSchema,
  })
  .refine(data => data.email || data.linkedinUrl, {
    message: "Required if no email is provided",
    path: ["linkedinUrl"],
  });

export const bulkCandidateInfoZodSchema = z.object({
  candidatesCsv: z.nullable(z.instanceof(File)).refine(file => file?.name.endsWith(".csv"), { message: "Required" }),
  manualSourcingItems: z
    .array(
      z.object({
        email: z
          .string()
          .email({ message: "Invalid email" })
          .optional()
          .or(z.literal(""))
          .nullable(),
        url: z.string().url({ message: "Invalid URL" }),
      })
    )
    .nullable(),
});

export type InterviewPipelineCandidateInfoSchema = z.infer<typeof interviewPipelineCandidateInfoZodSchema>;
export type BulkCandidateInfoSchema = z.infer<typeof bulkCandidateInfoZodSchema>;
export type CandidateInfoSchema = z.infer<typeof candidateInfoZodSchema>;
