import { SocialLinkTypeEnum } from "components/SocialLinks/constants";

export const PUBLISH_JOBS_PAGE_LIMIT = 10;

export const CAREERS_PAGE_TAB_PARAM = "tab";

export const CAREERS_PAGE_STATS_SAMPLE_DATA = [
  {
    label: "Employees",
    value: "100+",
  },
  {
    label: "Founded",
    value: "2010",
  },
  {
    label: "HQ",
    value: "San Francisco, CA",
  },
  {
    label: "Industry",
    value: "Tech",
  },
];

export const CAREERS_PAGE_SOCIAL_LINKS_SAMPLE_DATA = [
  {
    linkType: SocialLinkTypeEnum.Linkedin,
    url: "https://www.linkedin.com/company/your-company",
  },
  {
    linkType: SocialLinkTypeEnum.Github,
    url: "https://github.com/your-company",
  },
  {
    linkType: SocialLinkTypeEnum.Website,
    url: "https://your-company.com",
  },
];
