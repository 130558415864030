import React from "react";

import {
  BulkRejectModalProps,
  CandidateActionModalProps,
  FilePreviewModalProps,
  MoveJobModalProps,
  SharedTopLevelModalProps,
} from "components/dover/top-level-modal-manager/types";

export interface TopLevelModalManagerOptions {
  closeModal: () => void;
  showMoveJobModal: (props: Omit<MoveJobModalProps, keyof SharedTopLevelModalProps>) => void;
  showCandidateActionModal: (props: Omit<CandidateActionModalProps, keyof SharedTopLevelModalProps>) => void;
  showBulkRejectModal: (props: Omit<BulkRejectModalProps, keyof SharedTopLevelModalProps>) => void;
  showFilePreviewModal: (props: Omit<FilePreviewModalProps, keyof SharedTopLevelModalProps>) => void;
}

export const TopLevelModalManagerOptionsContext = React.createContext<TopLevelModalManagerOptions | undefined>(
  undefined
);
