import { Progress } from "@doverhq/dover-ui";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { compose, Dispatch } from "redux";
import { createStructuredSelector } from "reselect";

import AdminCampaignForm from "components/outreach-configuration/form/AdminCampaignForm";
import PageHelmet from "components/PageHelmet";
import { Campaign } from "services/openapi";
import { DAEMON } from "utils/constants";
import { useInjectReducer } from "utils/injectReducer";
import { useInjectSaga } from "utils/injectSaga";
import {
  loadCampaign as loadCampaignAction,
  saveCampaign as saveCampaignAction,
  loadUnsubscribeFooters as loadUnsubscribeFootersAction,
  sendTestEmails as sendTestEmailsAction,
} from "views/admin/CampaignView/actions";
import reducer from "views/admin/CampaignView/reducer";
import saga from "views/admin/CampaignView/saga";
import {
  makeSelectLoading,
  makeSelectCampaign,
  makeSelectSaving,
  makeSelectUnsubscribeFooters,
  makeSelectUnsubscribeFootersLoading,
} from "views/admin/CampaignView/selectors";
import {
  CampaignViewActionTypes,
  CampaignViewDesiredSelectionProps,
  CampaignViewDispatchProps,
  CampaignViewProps,
  RootState,
} from "views/admin/CampaignView/types";

const key = "campaignView";

const CampaignView: React.FC<CampaignViewProps> = ({
  // Selectors
  loading,
  campaign,
  unsubscribeFootersLoading,
  unsubscribeFooters,
  saving,
  // Actions
  loadCampaign,
  saveCampaign,
  loadUnsubscribeFooters,
  sendTestEmails,
}) => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga, mode: DAEMON });

  const { campaignId } = useParams<{ campaignId: string }>();
  useEffect(() => {
    if (!campaignId) {
      console.error("Campaign ID is not defined");
      return;
    }
    loadCampaign(campaignId);
  }, [campaignId, loadCampaign]);

  useEffect(() => {
    loadUnsubscribeFooters();
    // Only want this to run on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !campaign) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  return (
    <>
      <PageHelmet title={`Email Campaign Config for ${campaign.jobName}`} />

      <LoadingOverlay active={saving}>
        <AdminCampaignForm
          initialValues={campaign}
          onSubmit={saveCampaign}
          sendTestEmails={sendTestEmails}
          unsubscribeFootersLoading={unsubscribeFootersLoading}
          unsubscribeFooters={unsubscribeFooters}
        />
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = createStructuredSelector<RootState, CampaignViewDesiredSelectionProps>({
  loading: makeSelectLoading(),
  campaign: makeSelectCampaign(),
  unsubscribeFootersLoading: makeSelectUnsubscribeFootersLoading(),
  unsubscribeFooters: makeSelectUnsubscribeFooters(),
  saving: makeSelectSaving(),
});

function mapDispatchToProps(dispatch: Dispatch<CampaignViewActionTypes>): CampaignViewDispatchProps {
  return {
    loadCampaign: (campaignId: string): CampaignViewActionTypes => dispatch(loadCampaignAction(campaignId)),
    saveCampaign: (campaign: Campaign): CampaignViewActionTypes => dispatch(saveCampaignAction(campaign)),
    loadUnsubscribeFooters: (): CampaignViewActionTypes => dispatch(loadUnsubscribeFootersAction()),
    sendTestEmails: (campaign: Campaign): CampaignViewActionTypes => dispatch(sendTestEmailsAction(campaign)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CampaignView);
