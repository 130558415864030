import { Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect } from "react";

import DraggableSubstages from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/DraggableSubstages";
import { NextInterviewerHiringPipelineStage } from "components/NextInterviewer/usePossibleInterviewers";
import { useListHiringPipelineStagesQuery } from "services/doverapi/endpoints/hiringPipelineStage";
import { BaseInterviewSubstage } from "services/openapi/models/BaseInterviewSubstage";

interface Props {
  desiredHiringPipelineStage?: NextInterviewerHiringPipelineStage;
  job?: string;
  setUpdatedSubstages: (updatedSubstages: Array<BaseInterviewSubstage>) => void;
  showHeaders?: boolean;
  disabled?: boolean;
}

const MultipartInterviewSubstageEditor = ({
  desiredHiringPipelineStage,
  job,
  setUpdatedSubstages,
  showHeaders,
  disabled,
}: Props): React.ReactElement => {
  const { stage, isFetching, refetch } = useListHiringPipelineStagesQuery(
    job && desiredHiringPipelineStage ? job : skipToken,
    {
      selectFromResult: ({ data, isFetching }) => {
        const stage = data?.find(stage => stage.id === desiredHiringPipelineStage?.id);
        return { stage, isFetching };
      },
    }
  );

  useEffect(() => {
    // Manually trigger refetch of data when desired stage changes
    refetch();
  }, [refetch, stage, desiredHiringPipelineStage?.id]);

  if (isFetching) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton variant="rectangular" width="100px" height="40px" />
        <Skeleton variant="rectangular" width="250px" height="40px" />
        <Skeleton variant="rectangular" width="150px" height="40px" />
        <Skeleton variant="rectangular" width="250px" height="40px" />
      </Stack>
    );
  }

  if (!stage?.multipartInterviewStage) {
    return <></>;
  }

  const substages = stage.multipartInterviewStage.substages;

  if (!substages) {
    return <></>;
  }

  return (
    <DraggableSubstages
      initialSubstages={substages}
      setUpdatedSubstages={setUpdatedSubstages}
      showHeaders={showHeaders}
      disabled={disabled}
    />
  );
};

export default MultipartInterviewSubstageEditor;
