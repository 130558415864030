import { Stack } from "@mui/material";
import React from "react";

import SearchCriteriaUpdatedText from "components/dover/SearchesTable/components/SearchCriteriaUpdated";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Body, Heading } from "components/library/typography";
import CustomModal from "components/Modal";
import { useRemoveFailingPeopleFromOutboxMutation } from "services/doverapi/endpoints/search-v3/endpoints";

interface RefreshOutboxModalArgs {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  searchId: string;
}

export const RefreshOutboxModal = React.memo(
  ({ isModalOpen, setIsModalOpen, searchId }: RefreshOutboxModalArgs): React.ReactElement => {
    const [
      removeFailingPeopleFromOutbox,
      { isLoading: isRemovePeopleLoading },
    ] = useRemoveFailingPeopleFromOutboxMutation();

    const closeModal = (): void => {
      setIsModalOpen(false);
    };
    const handleDequeueFailingCandidates = (): void => {
      removeFailingPeopleFromOutbox(searchId);
      closeModal();
    };

    return (
      <CustomModal
        open={isModalOpen}
        dialogActionsStyles={{ padding: "8px 24px" }}
        customPaperProps={{ sx: { overflowY: "hidden" } }}
        customDialogStyles={{ zIndex: 10000 }}
        onClose={closeModal}
        title={<Heading weight={"600"}>Refresh queued candidates in outbox?</Heading>}
        maxWidth={"xs"}
      >
        <Stack spacing={2}>
          <SearchCriteriaUpdatedText />
          <Stack padding="15px 0px">
            <Stack direction="row">
              <Body>Would you like to</Body>
              <Body weight="500">&nbsp;remove queued candidates&nbsp;</Body>
              <Body>in</Body>
            </Stack>
            <Stack direction="row">
              <Body>your outbox that</Body>
              <Body weight="500">&nbsp;no longer pass&nbsp;</Body>
              <Body>your new set of</Body>
            </Stack>
            <Body>search criteria?</Body>
          </Stack>
          <Stack direction={"row"} spacing={1} width="100%" justifyContent="flex-end">
            <Button variant={ButtonVariant.Secondary} onClick={closeModal}>
              <BodySmall>No, keep queued candidates</BodySmall>
            </Button>
            <Button
              variant={ButtonVariant.Secondary}
              onClick={handleDequeueFailingCandidates}
              disabled={isRemovePeopleLoading}
            >
              <BodySmall>Refresh candidates</BodySmall>
            </Button>
          </Stack>
        </Stack>
      </CustomModal>
    );
  }
);
