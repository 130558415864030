import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as GreenCheckIconSVG } from "assets/icons/check-green.svg";
import { ReactComponent as RedXIconSVG } from "assets/icons/red-x.svg";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

interface FilterDisplaySmallProps {
  attributeName: string;
  // allow for undefined when we don't want a checkmark or x-mark
  passing?: boolean;
}

const FilterDisplaySmall = ({ attributeName, passing }: FilterDisplaySmallProps): React.ReactElement => {
  return (
    <Stack
      spacing={0.5}
      direction="row"
      display="flex"
      padding="3px 5px"
      style={{ border: "1px solid rgba(0, 0, 0, 0.15)", borderRadius: "5px" }}
      alignItems="center"
    >
      {passing !== undefined &&
        (passing ? <GreenCheckIconSVG style={{ padding: "1px" }} /> : <RedXIconSVG style={{ padding: "1px" }} />)}
      <BodySmall weight="300" color={colors.grayscale.gray700}>
        {attributeName}
      </BodySmall>
    </Stack>
  );
};

export default FilterDisplaySmall;
