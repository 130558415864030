/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeArchiveReasonRequest
 */
export interface ChangeArchiveReasonRequest {
    /**
     * 
     * @type {number}
     * @memberof ChangeArchiveReasonRequest
     */
    candidateActionVersion: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeArchiveReasonRequest
     */
    archiveReason: string;
}

export function ChangeArchiveReasonRequestFromJSON(json: any): ChangeArchiveReasonRequest {
    return ChangeArchiveReasonRequestFromJSONTyped(json, false);
}

export function ChangeArchiveReasonRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeArchiveReasonRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateActionVersion': json['candidate_action_version'],
        'archiveReason': json['archive_reason'],
    };
}

export function ChangeArchiveReasonRequestToJSON(value?: ChangeArchiveReasonRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_action_version': value.candidateActionVersion,
        'archive_reason': value.archiveReason,
    };
}


