import { Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import CalibrationIcon from "assets/icons/calibration.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";

export const RecalibrateModal = ({ isOpen, close }: { isOpen: boolean; close: Function }): React.ReactElement => {
  const navigate = useNavigate();
  const jobId = useJobIdFromUrl();

  return (
    <CustomModal
      open={isOpen}
      onClose={close}
      showTitleSpacer={false}
      maxWidth="sm"
      title="Edit your criteria?"
      dialogActions={
        <Stack spacing={2} direction="row" justifyContent="flex-end" style={{ padding: "12px" }}>
          <Button variant={ButtonVariant.Secondary} onClick={(): void => close()}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={(): void => {
              navigate(`/job/${jobId}/setup/search-criteria?tab=basic`);
            }}
          >
            <ReactSVG src={CalibrationIcon} style={{ display: "inline" }} /> Edit criteria
          </Button>
        </Stack>
      }
    >
      <Body>
        Looks like you’ve removed a few candidates from your queue. Feel free to view and adjust your search criteria to
        better fit what you’re looking for.
      </Body>
    </CustomModal>
  );
};
