/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublishedJobCount
 */
export interface PublishedJobCount {
    /**
     * 
     * @type {number}
     * @memberof PublishedJobCount
     */
    publishedJobsCount: number;
}

export function PublishedJobCountFromJSON(json: any): PublishedJobCount {
    return PublishedJobCountFromJSONTyped(json, false);
}

export function PublishedJobCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishedJobCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publishedJobsCount': json['published_jobs_count'],
    };
}

export function PublishedJobCountToJSON(value?: PublishedJobCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'published_jobs_count': value.publishedJobsCount,
    };
}


