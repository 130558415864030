/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DoverJobDescription,
    DoverJobDescriptionFromJSON,
    DoverJobDescriptionFromJSONTyped,
    DoverJobDescriptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GenerateJobDescriptionResponse
 */
export interface GenerateJobDescriptionResponse {
    /**
     * 
     * @type {DoverJobDescription}
     * @memberof GenerateJobDescriptionResponse
     */
    jobDescription: DoverJobDescription;
}

export function GenerateJobDescriptionResponseFromJSON(json: any): GenerateJobDescriptionResponse {
    return GenerateJobDescriptionResponseFromJSONTyped(json, false);
}

export function GenerateJobDescriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateJobDescriptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobDescription': DoverJobDescriptionFromJSON(json['job_description']),
    };
}

export function GenerateJobDescriptionResponseToJSON(value?: GenerateJobDescriptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_description': DoverJobDescriptionToJSON(value.jobDescription),
    };
}


