import { Box } from "@mui/material";
import React from "react";

import { ReactComponent as OpenNavIcon } from "assets/icons/open-nav.svg";
import { colors } from "styles/theme";

interface OpenNavProps {
  onClick: () => void;
}

/**
 * OpenNav icon that is used to open the side nav
 */
export const OpenNavButton = ({ onClick }: OpenNavProps): React.ReactElement => {
  return (
    <Box position="fixed" top="10px" left="10px" zIndex={10000}>
      <OpenNavIcon
        className="svg-pointer svg-color"
        color={colors.grayscale.gray500}
        width="18px"
        height="18px"
        onClick={onClick}
      />
    </Box>
  );
};
