import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useGetJobSetupStepsQuery } from "services/doverapi/endpoints/job/endpoints";
import { EnrichedJobSetupStep } from "services/doverapi/endpoints/job/types";
import { getEnrichedJobSetupSteps, getJobSetupStepMap } from "services/doverapi/endpoints/job/utils";

interface UseJobSetupStepsArgs {
  jobId: string | undefined;
  disregardStepRelevancy?: boolean;
  shouldSkipQuery?: boolean;
  includeAllCompletedSteps?: boolean;
}

export function useEnrichedJobSetupSteps({
  jobId,
  disregardStepRelevancy,
  includeAllCompletedSteps,
}: UseJobSetupStepsArgs): EnrichedJobSetupStep[] {
  const { stepNameToInfoMap, jpqId } = useGetJobSetupStepsQuery(jobId ?? skipToken, {
    selectFromResult: ({ data }) => {
      return {
        stepNameToInfoMap: data ? getJobSetupStepMap(data) : undefined,
        jpqId: data ? data.jpqId : null,
      };
    },
  });
  return useMemo<EnrichedJobSetupStep[]>(() => {
    if (!stepNameToInfoMap || !jobId) {
      return [];
    }
    return getEnrichedJobSetupSteps({
      stepNameToInfoMap,
      jobId,
      disregardStepRelevancy,
      jpqId,
      includeAllCompletedSteps,
    });
  }, [stepNameToInfoMap, jobId, disregardStepRelevancy, jpqId, includeAllCompletedSteps]);
}
