/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationQuestionApplicationPortalJob,
    ApplicationQuestionApplicationPortalJobFromJSON,
    ApplicationQuestionApplicationPortalJobFromJSONTyped,
    ApplicationQuestionApplicationPortalJobToJSON,
    JobCompensation,
    JobCompensationFromJSON,
    JobCompensationFromJSONTyped,
    JobCompensationToJSON,
    JobLocation,
    JobLocationFromJSON,
    JobLocationFromJSONTyped,
    JobLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationPortalJob
 */
export interface ApplicationPortalJob {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    jobOrSearchId: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    readonly clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    readonly clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    readonly clientLogo?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    readonly clientDomain?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    readonly location?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPortalJob
     */
    readonly requireLinkedinProfileUrl?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    readonly userProvidedDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalJob
     */
    readonly compensationDetails?: string;
    /**
     * 
     * @type {Array<ApplicationQuestionApplicationPortalJob>}
     * @memberof ApplicationPortalJob
     */
    applicationQuestions: Array<ApplicationQuestionApplicationPortalJob>;
    /**
     * 
     * @type {Array<JobLocation>}
     * @memberof ApplicationPortalJob
     */
    locations?: Array<JobLocation>;
    /**
     * 
     * @type {JobCompensation}
     * @memberof ApplicationPortalJob
     */
    compensation?: JobCompensation;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPortalJob
     */
    visaSupport?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationPortalJob
     */
    readonly created?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPortalJob
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPortalJob
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPortalJob
     */
    readonly shouldPublishToGoogleJobs?: boolean;
}

export function ApplicationPortalJobFromJSON(json: any): ApplicationPortalJob {
    return ApplicationPortalJobFromJSONTyped(json, false);
}

export function ApplicationPortalJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationPortalJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobOrSearchId': json['job_or_search_id'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'clientName': !exists(json, 'client_name') ? undefined : json['client_name'],
        'clientLogo': !exists(json, 'client_logo') ? undefined : json['client_logo'],
        'clientDomain': !exists(json, 'client_domain') ? undefined : json['client_domain'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'requireLinkedinProfileUrl': !exists(json, 'require_linkedin_profile_url') ? undefined : json['require_linkedin_profile_url'],
        'userProvidedDescription': !exists(json, 'user_provided_description') ? undefined : json['user_provided_description'],
        'compensationDetails': !exists(json, 'compensation_details') ? undefined : json['compensation_details'],
        'applicationQuestions': ((json['application_questions'] as Array<any>).map(ApplicationQuestionApplicationPortalJobFromJSON)),
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(JobLocationFromJSON)),
        'compensation': !exists(json, 'compensation') ? undefined : JobCompensationFromJSON(json['compensation']),
        'visaSupport': !exists(json, 'visa_support') ? undefined : json['visa_support'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'isPrivate': !exists(json, 'is_private') ? undefined : json['is_private'],
        'shouldPublishToGoogleJobs': !exists(json, 'should_publish_to_google_jobs') ? undefined : json['should_publish_to_google_jobs'],
    };
}

export function ApplicationPortalJobToJSON(value?: ApplicationPortalJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_or_search_id': value.jobOrSearchId,
        'title': value.title,
        'application_questions': ((value.applicationQuestions as Array<any>).map(ApplicationQuestionApplicationPortalJobToJSON)),
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(JobLocationToJSON)),
        'compensation': JobCompensationToJSON(value.compensation),
        'visa_support': value.visaSupport,
        'active': value.active,
        'is_private': value.isPrivate,
    };
}


