/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneratedJDSection
 */
export interface GeneratedJDSection {
    /**
     * 
     * @type {string}
     * @memberof GeneratedJDSection
     */
    header?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedJDSection
     */
    content: string;
}

export function GeneratedJDSectionFromJSON(json: any): GeneratedJDSection {
    return GeneratedJDSectionFromJSONTyped(json, false);
}

export function GeneratedJDSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratedJDSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': !exists(json, 'header') ? undefined : json['header'],
        'content': json['content'],
    };
}

export function GeneratedJDSectionToJSON(value?: GeneratedJDSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': value.header,
        'content': value.content,
    };
}


