/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SocialLinkType,
    SocialLinkTypeFromJSON,
    SocialLinkTypeFromJSONTyped,
    SocialLinkTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContactSocialLink
 */
export interface ContactSocialLink {
    /**
     * 
     * @type {string}
     * @memberof ContactSocialLink
     */
    url: string;
    /**
     * 
     * @type {SocialLinkType}
     * @memberof ContactSocialLink
     */
    linkType: SocialLinkType;
}

export function ContactSocialLinkFromJSON(json: any): ContactSocialLink {
    return ContactSocialLinkFromJSONTyped(json, false);
}

export function ContactSocialLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSocialLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'linkType': SocialLinkTypeFromJSON(json['link_type']),
    };
}

export function ContactSocialLinkToJSON(value?: ContactSocialLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'link_type': SocialLinkTypeToJSON(value.linkType),
    };
}


