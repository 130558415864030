import { Container, Stack, Box } from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useMatch } from "react-router-dom";

import PageHelmet from "components/PageHelmet";
import { usePrefetch } from "services/doverapi/endpoints/proUser";
import { SideNav } from "views/CompanySetup/components";

const CompanySetup = (): React.ReactElement => {
  const match = useMatch("/settings/company");

  const prefetchProUsers = usePrefetch("listProUsersForClient");

  useEffect(() => {
    // prefetch proUsers for client notes editor so at-mentions are snappy
    prefetchProUsers({});
  }, [prefetchProUsers]);

  if (match) {
    return <Navigate replace to={`/settings/company/general`} />;
  }

  return (
    <>
      <PageHelmet title="Settings" />

      <Container
        maxWidth={false}
        className="top-level-page-container"
        sx={{ paddingBottom: "20px", height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <Stack flexGrow={1} direction={{ xs: "column", sm: "row" }} spacing={3}>
          <Box width={{ xs: "100%", sm: "25%" }} maxWidth={"200px"}>
            <SideNav />
          </Box>
          <Box flexGrow={1} display="flex" flexDirection="column" width="100%" alignItems="center">
            <Outlet />
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default CompanySetup;
