import { createSelector, EntityState } from "@reduxjs/toolkit";
import moment from "moment";
import { Selector } from "react-redux";

import { selectGlobalState } from "domains/global/selectors";
import { GlobalRootState } from "domains/global/types";
import { multipartInterview } from "services/doverapi/endpoints/multipartScheduler";
import { QuerySelectResult } from "services/doverapi/types";
import {
  MultipartSchedulingClientInterviewerAvailability,
  MultipartSchedulingClientInterviewerCalendarEvent,
} from "services/openapi";
import {
  initialState,
  interviewEventsAdapter,
  interviewSchedulerKey,
} from "views/interview/InterviewScheduler/constants";
import {
  CalendarResource,
  InterviewerAvailabilityMap,
  InterviewerEventMap,
  InterviewRound,
  InterviewSchedulerState,
} from "views/interview/InterviewScheduler/types";
import { dateToUnix } from "views/interview/InterviewScheduler/utils";

const selectState = (state: GlobalRootState): InterviewSchedulerState => state[interviewSchedulerKey] || initialState;

export const selectResources = createSelector([selectState], (state): CalendarResource[] => state.resources);

const selectInterviewRoundEntityState = createSelector(
  [selectState],
  (state): EntityState<InterviewRound> => state.interviewRounds
);

export const selectDate = createSelector([selectState], state => state.date);
export const selectExpectedNumberOfSubstages = createSelector([selectState], state => state.expectedNumberOfSubstages);
export const selectTimezone = createSelector([selectState], state => state.timezone);

// This gets the unix timestamp of the beginning of the day and the end of the day
// Useful for selecting the correct date range with our API to retrieve interviewer calendars
export const selectDayStartEnd = createSelector([selectDate, selectTimezone], (date, timezone) => ({
  start: dateToUnix(
    moment(date)
      .tz(timezone)
      .startOf("day")
  ),
  end: dateToUnix(
    moment(date)
      .tz(timezone)
      .endOf("day")
  ),
}));

export const selectLocationOverride = createSelector([selectState], state => state.locationOverride);

export const makeSelectInterviewerCalendars = (ids: string[]): Selector<GlobalRootState, InterviewerEventMap> =>
  createSelector(
    [selectGlobalState, selectDayStartEnd],
    (globalState, { start, end }): InterviewerEventMap =>
      ids.reduce((acc, id) => {
        const resourceData: QuerySelectResult<MultipartSchedulingClientInterviewerCalendarEvent[]> = multipartInterview.endpoints.listClientInterviewerCalendarEvents.select(
          { id, start, end }
        )(globalState);

        return { ...acc, [id]: resourceData };
      }, {})
  );

export const makeSelectInterviewerAvailability = (
  ids: string[]
): Selector<GlobalRootState, InterviewerAvailabilityMap> =>
  createSelector(
    [selectGlobalState],
    (globalState): InterviewerAvailabilityMap =>
      ids.reduce((acc, id) => {
        const resourceData: QuerySelectResult<MultipartSchedulingClientInterviewerAvailability> = multipartInterview.endpoints.listClientInterviewerAvailability.select(
          { id }
        )(globalState);

        return { ...acc, [id]: resourceData };
      }, {})
  );

export const selectDeletedSubstageIds = createSelector([selectState], state => state.deletedSubstageIds);
export const selectCanceledSubstageIds = createSelector([selectState], state => state.canceledSubstageIds);
export const { selectAll: selectInterviewRounds } = interviewEventsAdapter.getSelectors(
  selectInterviewRoundEntityState
);
