import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { CURRENT_FLOW_ID_PARAM_NAME, OnboardingCurrentStepToUrlParamMap } from "App/routing/constants";
import ImportCareersPage from "components/dover/EditCareersPage/ImportCareersPage";
import JobDescriptionStep from "components/dover/InboundCriteriaSetupFlow/steps/JobDescriptionStep";
import StepFlow from "components/StepFlow/StepFlow";
import { StepFlowStep } from "components/StepFlow/types";
import {
  useGetUserOnboardingFlowQuery,
  useUpdateUserOnboardingFlowMutation,
} from "services/doverapi/endpoints/user-onboarding-flow/userOnboardingFlow";
import {
  UserOnboardingFlowMostRecentlyCompletedStepEnum,
  UserOnboardingFlowCompletedStepsEnum,
  UserOnboardingFlowOnboardingStateEnum,
  UserOnboardingFlowContentTypeEnum,
  CurrentUserOnboardingStepResponseCurrentStepEnum,
} from "services/openapi";
import { CompletionStep } from "views/create-job/AtsSetupFlow/steps/CompletionStep";
import CustomizeInboundApplicationForm from "views/create-job/AtsSetupFlow/steps/CustomizeInboundApplicationForm";
import { EditCareersPageWrapper } from "views/create-job/AtsSetupFlow/steps/EditCareersPage";
import { InviteNewUsersStep } from "views/create-job/shared-steps/InviteNewUsers";

const JobBoardsSetupFlow = (): React.ReactElement => {
  // Application level helpers and state
  const [, setJobIdParam] = useQueryParam("jobId", StringParam);
  const [jobIdCreated, setJobIdCreated] = React.useState<string | undefined>(undefined);
  const createdJobIdRef = React.useRef<string | undefined>(undefined);

  // Use the currentFlowId query param to fetch the user onboarding flow
  const [currentFlowId] = useQueryParam(CURRENT_FLOW_ID_PARAM_NAME, StringParam);
  const { data: userOnboardingFlow, isFetching: isFetchingUserOnboardingFlow } = useGetUserOnboardingFlowQuery(
    currentFlowId ?? skipToken
  );

  // Mutations
  const [
    updateUserOnboardingFlowMutation,
    { isLoading: isUpdatingUserOnboardingFlow },
  ] = useUpdateUserOnboardingFlowMutation();

  const isLoading = isFetchingUserOnboardingFlow || isUpdatingUserOnboardingFlow;

  //Effects
  useEffect(() => {
    // By updating createdJobIdRef when jobIdCreated changes, we can ensure that the created job id is passed through to the update call
    // A state value alone is unreliable because it may not be updated in time for the update call since it is async
    if (jobIdCreated) {
      createdJobIdRef.current = jobIdCreated;
    }
  }, [jobIdCreated]);

  useEffect(() => {
    if (
      userOnboardingFlow?.relatedObjectUuid &&
      userOnboardingFlow.contentType === UserOnboardingFlowContentTypeEnum.Job
    ) {
      setJobIdParam(userOnboardingFlow.relatedObjectUuid);
    }
  }, [userOnboardingFlow, setJobIdParam]);

  // Callbacks
  const handleOnBack = React.useCallback(async (): Promise<void> => {
    const tryUpdatingUserOnboardingFlowMutation = async (): Promise<void> => {
      if (userOnboardingFlow && userOnboardingFlow.completedSteps && userOnboardingFlow.currentStep) {
        const completedSteps = [...userOnboardingFlow.completedSteps];
        completedSteps.pop();
        await updateUserOnboardingFlowMutation({
          userOnboardingFlowId: userOnboardingFlow.id!,
          data: {
            ...userOnboardingFlow,
            completedSteps: completedSteps,
            mostRecentlyCompletedStep: (completedSteps[
              completedSteps.length - 1
            ] as unknown) as UserOnboardingFlowMostRecentlyCompletedStepEnum,
            onboardingState: UserOnboardingFlowOnboardingStateEnum.InProgress,
          },
        });
      }
    };
    await tryUpdatingUserOnboardingFlowMutation();
  }, [updateUserOnboardingFlowMutation, userOnboardingFlow]);

  const handleUpdateUserOnboardingFlow = React.useCallback(async (): Promise<void> => {
    const tryUpdatingUserOnboardingFlowMutation = async (): Promise<void> => {
      if (userOnboardingFlow && userOnboardingFlow.completedSteps && userOnboardingFlow.currentStep) {
        let onboardingState = UserOnboardingFlowOnboardingStateEnum.InProgress;
        if (userOnboardingFlow.isOnLastStep) {
          onboardingState = UserOnboardingFlowOnboardingStateEnum.Completed;
        }
        await updateUserOnboardingFlowMutation({
          userOnboardingFlowId: userOnboardingFlow.id!,
          data: {
            ...userOnboardingFlow,
            completedSteps: [
              ...userOnboardingFlow.completedSteps,
              (userOnboardingFlow.currentStep as unknown) as UserOnboardingFlowCompletedStepsEnum,
            ],
            mostRecentlyCompletedStep: (userOnboardingFlow.currentStep as unknown) as UserOnboardingFlowMostRecentlyCompletedStepEnum,
            onboardingState,
            // If a createdJobId exists, pass it through as the UserOnboardingFlow's relatedObjectUuid
            relatedObjectUuid: createdJobIdRef.current ?? userOnboardingFlow.relatedObjectUuid,
            contentType: createdJobIdRef.current
              ? UserOnboardingFlowContentTypeEnum.Job
              : userOnboardingFlow.contentType,
          },
        });
      }
    };

    await tryUpdatingUserOnboardingFlowMutation();
  }, [userOnboardingFlow, updateUserOnboardingFlowMutation, createdJobIdRef]);

  const ImportCareersPageStep = useMemo(() => {
    return ({ goNext }: { goNext: () => void }): React.ReactElement => {
      const onNext = async (): Promise<void> => {
        // update user onboarding flow
        await handleUpdateUserOnboardingFlow();
        goNext();
      };

      return <ImportCareersPage onSaveAndNext={onNext} inOnboardingFlow />;
    };
  }, [handleUpdateUserOnboardingFlow]);

  const JobBoardsCustomizeCareersPageStep = useMemo(() => {
    return ({ goNext }: { goNext: () => void }): React.ReactElement => {
      const onNext = async (): Promise<void> => {
        // update user onboarding flow
        await handleUpdateUserOnboardingFlow();
        goNext();
      };

      return <EditCareersPageWrapper onSaveAndNext={onNext} isLoading={isLoading} />;
    };
  }, [handleUpdateUserOnboardingFlow, isLoading]);

  const JobBoardsJobDescriptionStep = useMemo(() => {
    return ({ goNext }: { goNext: () => void; goBack?: () => void }): React.ReactElement => {
      const onNext = async (): Promise<void> => {
        // update user onboarding flow
        await handleUpdateUserOnboardingFlow();
        goNext();
      };

      const onJobCreationSuccess = (jobId: string): void => {
        setJobIdCreated(jobId);
        setJobIdParam(jobId);
      };

      return <JobDescriptionStep onNext={onNext} onJobCreationSuccess={onJobCreationSuccess} />;
    };
  }, [handleUpdateUserOnboardingFlow, setJobIdCreated, setJobIdParam]);

  const JobBoardsInboundApplicationStep = useMemo(() => {
    return ({ goNext, goBack }: { goNext: () => void; goBack?: () => void }): React.ReactElement => {
      const onNext = async (): Promise<void> => {
        // update user onboarding flow
        await handleUpdateUserOnboardingFlow();
        goNext();
      };

      const onBack = (): void | undefined => {
        handleOnBack();
        goBack?.();
      };

      return <CustomizeInboundApplicationForm onSaveAndNext={onNext} onBack={onBack} isLoading={isLoading} />;
    };
  }, [handleOnBack, handleUpdateUserOnboardingFlow, isLoading]);

  const InviteNewUsersStepComponent = useMemo(() => {
    return ({ goNext }: { goNext: () => void }): React.ReactElement => {
      const onNext = async (): Promise<void> => {
        // update user onboarding flow
        await handleUpdateUserOnboardingFlow();
        goNext();
      };

      return <InviteNewUsersStep goNext={onNext} isLoading={isLoading} />;
    };
  }, [handleUpdateUserOnboardingFlow, isLoading]);

  const JobBoardsCompletionStepComponent = useMemo(() => {
    return ({ goNext }: { goNext: () => void }): React.ReactElement => {
      const onNext = async (): Promise<void> => {
        // update user onboarding flow
        await handleUpdateUserOnboardingFlow();
        goNext();
      };

      return <CompletionStep goNext={onNext} isLoading={isLoading} />;
    };
  }, [handleUpdateUserOnboardingFlow, isLoading]);

  const steps = useMemo((): StepFlowStep[] => {
    return [
      {
        component: ImportCareersPageStep,
        progressLabel: "Careers Page",
        stepRoute: OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.CompanySetup],
      },
      {
        component: JobBoardsCustomizeCareersPageStep,
        stepRoute: OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.EditCareersPage],
      },
      {
        component: JobBoardsJobDescriptionStep,
        progressLabel: "Job Details",
        stepRoute: OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.JdQuestion],
      },
      {
        component: JobBoardsInboundApplicationStep,
        progressLabel: "Application Form",
        stepRoute:
          OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.InboundApplicationForm],
      },
      {
        component: InviteNewUsersStepComponent,
        stepRoute: OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.InviteNewUsers],
      },
      {
        component: JobBoardsCompletionStepComponent,
        progressLabel: "Complete",
        stepRoute: OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.Completion],
      },
    ];
  }, [
    JobBoardsCompletionStepComponent,
    JobBoardsCustomizeCareersPageStep,
    JobBoardsInboundApplicationStep,
    ImportCareersPageStep,
    JobBoardsJobDescriptionStep,
    InviteNewUsersStepComponent,
  ]);

  return <StepFlow title="ATS" steps={steps} />;
};

export default JobBoardsSetupFlow;
