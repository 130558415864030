import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";

import { CandidateNote } from "components/CandidateHeader/CandidateNote";
import { Body } from "components/library/typography";
import { useListCandidateNotesQuery } from "services/doverapi/endpoints/candidate";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { CandidateActivityNote } from "services/openapi";
import { Note, sortNotes } from "views/CandidateDetail/components/event-feed/Note";
import { NoNotesTabContent } from "views/CandidateDetail/components/NoNotesTabContent";

interface NotesTabProps {
  candidateId?: string;
}
export const NotesTab: React.FC<NotesTabProps> = ({ candidateId }) => {
  const { notes } = useListCandidateNotesQuery(candidateId ?? skipToken, {
    selectFromResult: ({ data }) => {
      const notes: CandidateActivityNote[] = listAllEntities(data);
      return {
        notes,
      };
    },
  });

  const sortedNotes = useMemo(() => {
    return [...(notes || [])].sort(sortNotes);
  }, [notes]);

  if (!candidateId) {
    return (
      <Stack width="100%" height="100%" justifyContent="center">
        <Body centered>No candidate selected</Body>
      </Stack>
    );
  }

  return (
    <Stack height="100%" spacing={1} m="1em">
      <Box>
        <CandidateNote candidateIdProp={candidateId} />
      </Box>
      {sortedNotes.length === 0 ? (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <NoNotesTabContent />
        </Stack>
      ) : (
        sortedNotes?.map(note => <Note note={note} />)
      )}
    </Stack>
  );
};
