import { Box, Grid, Container, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { LoadingRelative } from "components/HotLoading";
import { Spacer } from "components/Spacer";
import { useCurrentDoverEntity } from "services/doverapi/endpoints/doverEntity/customHooks";
import { useGetInterviewsQuery } from "services/doverapi/endpoints/interview";
import CandidatesInScheduling from "views/admin/RecentInterviews/components/CandidatesInScheduling";
import InterviewTable from "views/admin/RecentInterviews/components/InterviewsTable";

const InterviewsPageBase = (): React.ReactElement => {
  const currentDoverEntity = useCurrentDoverEntity();

  const { isSuccess: initialized } = useGetInterviewsQuery(
    currentDoverEntity?.id ? { doverEntityId: currentDoverEntity.id } : skipToken
  );
  return (
    <Container>
      <Box paddingTop="30px">
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2">Recent Interviews</Typography>
          </Grid>
          <Spacer height="20px" />
          {initialized ? (
            <>
              <InterviewTable />
              <Spacer height="70px" />
              <Typography variant="h2">Upcoming Candidates</Typography>
              <CandidatesInScheduling />
            </>
          ) : (
            <LoadingRelative />
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default InterviewsPageBase;
