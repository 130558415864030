/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OldPersonaToNewPersona,
    OldPersonaToNewPersonaFromJSON,
    OldPersonaToNewPersonaFromJSONTyped,
    OldPersonaToNewPersonaToJSON,
} from './';

/**
 * 
 * @export
 * @interface ModifyTitlePatternsInSearchResponse
 */
export interface ModifyTitlePatternsInSearchResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ModifyTitlePatternsInSearchResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModifyTitlePatternsInSearchResponse
     */
    searchId: string;
    /**
     * 
     * @type {OldPersonaToNewPersona}
     * @memberof ModifyTitlePatternsInSearchResponse
     */
    oldPersonaToNewPersona: OldPersonaToNewPersona;
}

export function ModifyTitlePatternsInSearchResponseFromJSON(json: any): ModifyTitlePatternsInSearchResponse {
    return ModifyTitlePatternsInSearchResponseFromJSONTyped(json, false);
}

export function ModifyTitlePatternsInSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifyTitlePatternsInSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'searchId': json['search_id'],
        'oldPersonaToNewPersona': OldPersonaToNewPersonaFromJSON(json['old_persona_to_new_persona']),
    };
}

export function ModifyTitlePatternsInSearchResponseToJSON(value?: ModifyTitlePatternsInSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'search_id': value.searchId,
        'old_persona_to_new_persona': OldPersonaToNewPersonaToJSON(value.oldPersonaToNewPersona),
    };
}


