import { Dictionary, invert, keyBy } from "lodash";

import { InterviewSchedulingScenario } from "components/SetupHiringPipeline/types";
import { FormattedJobFeatures } from "services/doverapi/endpoints/jobFeatureSettings/types";
import {
  HiringPipelineStage,
  JobSetup,
  JobFeatureFeatureNameEnum,
  HiringPipelineStageStateEnum,
  InterviewPlanInterviewSubstage,
  InterviewPlanStageType,
} from "services/openapi";
import { isAppliedOrRespondedStage, isStage } from "utils/isStage";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import {
  HiringPipelineStageForm,
  DrawerField,
  MultipartInterviewSubstageForm,
  onsiteStageFieldsWithE2E,
  requiredFieldDefault,
  StageFormAdditionalInfo,
  takeHomeFields as takeHomeFieldsList,
  atsFields as basicAtsFieldsList,
  DisplayField,
  fieldsToFormLabel,
  basicStageFields,
  InterviewStageForm,
  MultipartInterviewStageForm,
  appliedOrRespondedFields,
  onsiteStageFieldsNoE2E,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/constants";

export const getPipelineStagesFormInitialValues = (
  hiringPipelineStages: HiringPipelineStage[],
  job?: JobSetup
): Dictionary<HiringPipelineStageForm> => {
  const pipelineStagesInitialValue: HiringPipelineStageForm[] =
    hiringPipelineStages?.map(stage => getHiringPipelineStageInitialValue(stage, job)) || [];

  return keyBy(pipelineStagesInitialValue, function(stage) {
    return `stage-${stage.id}`;
  });
};

export const getFormInitialValues = (
  job: JobSetup | undefined,
  hiringPipelineStages: HiringPipelineStage[] | undefined,
  jobFeatures: FormattedJobFeatures | undefined
): any => {
  if (!job || !hiringPipelineStages) return;

  const pipelineStagesInitialValue = getPipelineStagesFormInitialValues(hiringPipelineStages, job);

  const initialValues = {
    clientSchedulingEmail: job.clientSchedulingEmail,
    schedulingCalendarGcalId: job.schedulingCalendarGcalId,
    schedulingCalendarGcalIdEdited: job.schedulingCalendarGcalId,
    usingEndToEndScheduling: !!jobFeatures && jobFeatures[JobFeatureFeatureNameEnum.E2EScheduling],
    pipelineStages: pipelineStagesInitialValue,
    pipelineStageEdits: pipelineStagesInitialValue,
    workSchedulingEmailAliasUser: job.workSchedulingEmailAlias?.userId,
    workSchedulingEmailAliasUserEdited: job.workSchedulingEmailAlias?.userId,
  };

  return initialValues;
};

const getHiringPipelineStageInitialValue = (stage: HiringPipelineStage, job?: JobSetup): HiringPipelineStageForm => {
  const { isMultipart } = isStage(stage);

  let initialValues: HiringPipelineStageForm = {
    id: stage.id,
    name: stage.name,
    hiringPipelineStageState: stage.state!,
    contentTypeName: stage.contentTypeName!,
    atsStageId: stage.atsStageId || "",
    emailTemplateEdited: false,
    reuseConferenceLink: stage?.multipartInterviewStage?.reuseConferenceLink ?? true,
    isMultiPart: isMultipart,
    interviewType: "", // This gets overwritten in the multipart and singlepart initialization
    schedulingEmailTemplateId: stage.schedulingEmailTemplateId ?? null,
    rejectionEmailTemplateId: stage.rejectionEmailTemplateId ?? null,
  };

  if (!isMultipart) {
    initialValues = {
      ...initialValues,
      ...getInterviewStageInitialValues(stage, job),
    };
  } else {
    initialValues = {
      ...initialValues,
      ...getMultipartInterviewStageInitialValues(stage, job),
    };
  }

  return initialValues;
};

const getInterviewStageInitialValues = (stage: HiringPipelineStage, job?: JobSetup): InterviewStageForm => {
  const substages = stage?.multipartInterviewStage?.substages || [];
  const interviewStage = substages[0] || {};

  const { isTakeHome } = isStage(stage);

  let initialValues: InterviewStageForm = {
    duration: interviewStage.duration ?? 60 * 30,
    useDoverInterviewer: interviewStage.useDoverInterviewer!,
    isTakeHome: interviewStage.isTakeHome!,
    atsSubstageId: interviewStage.atsSubstageId,
    atsFeedbackTemplateId: interviewStage.atsFeedbackTemplateId,
    interviewers: interviewStage.possibleInterviewers?.map(interviewer => interviewer.id) || [],
    interviewType: isTakeHome ? InterviewPlanStageType.TAKE_HOME : InterviewPlanStageType.SINGLEPART,
    defaultFeedbackTemplate: interviewStage.defaultFeedbackTemplate || job?.clientDefaultFeedbackTemplate,
  };

  // Set fields required for Take Home stage which can be set to either stage 600 or 650
  if (isTakeHome) {
    initialValues = {
      ...initialValues,
      additionalInfo: {
        takeHomeDuration: (interviewStage?.additionalInfo as StageFormAdditionalInfo)?.takeHomeDuration || "",
      },
    };
  }

  return initialValues;
};

const getMultipartInterviewStageInitialValues = (
  stage: HiringPipelineStage,
  job?: JobSetup
): MultipartInterviewStageForm => {
  const substages = stage?.multipartInterviewStage?.substages || [];

  const initialValues = {
    substages: substages.map(substage => getMultipartInterviewSubstageInitialValues(substage, job)),
    interviewMeetingType: stage?.multipartInterviewStage?.interviewMeetingType,
    interviewLocation: stage?.multipartInterviewStage?.interviewLocation!,
    interviewDetails: stage?.multipartInterviewStage?.interviewDetails!,
    debriefDuration: stage?.multipartInterviewStage?.debriefDuration!,
    interviewType: InterviewPlanStageType.MULTIPART,
  };

  // TODO: Fix
  return initialValues;
};

const getMultipartInterviewSubstageInitialValues = (
  stage: InterviewPlanInterviewSubstage,
  job?: JobSetup
): MultipartInterviewSubstageForm => {
  const initialValues = {
    id: stage.id,
    name: stage.name || "Substage 1",
    duration: stage.duration || 60 * 30,
    possibleInterviewers: stage.possibleInterviewers?.map(interviewer => interviewer.id) || [],
    interviewersRequired: stage.interviewersRequired,
    orderIndex: stage.orderIndex || 0,
    orderRequired: stage.orderRequired || false,
    atsSubstageId: stage.atsSubstageId,
    atsFeedbackTemplateId: stage.atsFeedbackTemplateId,
    defaultFeedbackTemplate: stage.defaultFeedbackTemplate || job?.clientDefaultFeedbackTemplate,
  };

  return initialValues;
};

export const secondsToMinutesOrHours = (seconds: number): string => {
  const numHours = Math.floor(seconds / 3600);
  const numMinutes = (seconds - numHours * 3600) / 60;

  return `${numHours > 0 ? `${numHours} hour${numHours === 1 ? "" : "s"}` : ""} ${
    numMinutes > 0 ? `${numMinutes} minute${numMinutes === 1 ? "" : "s"}` : ""
  }`;
};

export function transformToStageRowData(formValues: any): HiringPipelineStageForm[] {
  const stageMap = keyBy(Object.values<HiringPipelineStageForm>(formValues.pipelineStages), "doverStage");

  const rowsArray = [
    stageMap["500"],
    stageMap["600"],
    stageMap["650"],
    stageMap["700"],
    stageMap["750"],
    stageMap["800"],
  ];

  return rowsArray;
}

export const isMultiPartInterview = (stageData?: HiringPipelineStageForm): boolean => {
  return stageData?.contentTypeName === "multipart interview stage";
};

export const getStageFormFields = (
  stageData: StageDataWrapper,
  isTakeHome: boolean,
  isMultiPart: boolean,
  atsSetupComplete: boolean,
  interviewSchedulingScenario: InterviewSchedulingScenario,
  e2eSchedulingEnabled: boolean
): DisplayField[] => {
  const shouldShowAtsFields = atsSetupComplete && !shouldHideAtsFields(interviewSchedulingScenario);

  const { isOffer } = isStage(stageData.stage);
  const isAppliedOrResponded = isAppliedOrRespondedStage(stageData.stage);

  // Start with the basic stage fields
  let fields: DisplayField[] = [...basicStageFields];

  // if is take home overwrite with takehome fields
  if (isTakeHome) {
    fields = [...takeHomeFieldsList];
    // If multipart add onsite stage fields and the scheduling calendar field
  } else if (isMultiPart && e2eSchedulingEnabled) {
    fields = [...onsiteStageFieldsWithE2E];
    // If offer stage remove basic fields
  } else if (isMultiPart) {
    fields = [...onsiteStageFieldsNoE2E];
  } else if (isOffer) {
    fields = [];
  } else if (isAppliedOrResponded) {
    fields = appliedOrRespondedFields;
  }

  // If ATS there are additional fields to display
  if (shouldShowAtsFields) {
    // For multipart we don't want to display feedback forms because they exist elsewhere in the form
    const atsFields = isMultiPart
      ? [
          {
            field: DrawerField.ATS_STAGE_MAPPING,
            required: requiredFieldDefault[DrawerField.ATS_STAGE_MAPPING],
          },
        ]
      : [...basicAtsFieldsList];
    fields = [...fields, ...atsFields];
  }

  return fields;
};

export const isFormValid = (
  pipelineStage: HiringPipelineStageForm,
  fieldsToDisplay: DisplayField[],
  existingPipelineStages: { [key: string]: HiringPipelineStageForm }
): boolean => {
  return Object.keys(pipelineStage).every(label => {
    const formLabelToDrawerField = invert(fieldsToFormLabel);
    const drawerField = parseInt(formLabelToDrawerField[label]) as DrawerField;

    const formFields = fieldsToDisplay.filter(f => f.field === drawerField);
    const formValue = pipelineStage[label as keyof HiringPipelineStageForm];

    const pipelineStageKeys = Object.keys(existingPipelineStages);
    const fieldRequired = formFields[0]?.required;
    const field = formFields[0]?.field;

    if (fieldRequired && !formValue) {
      return false;
    }

    if (
      fieldRequired &&
      field === DrawerField.INTERVIEWERS &&
      !(formValue as string[])?.length &&
      !pipelineStage.useDoverInterviewer
    ) {
      return false;
    }

    if ((label === "name" || label === "atsStageId") && formValue) {
      const valueAlreadyExists = pipelineStageKeys.find(
        stageKey =>
          existingPipelineStages[stageKey].hiringPipelineStageState === HiringPipelineStageStateEnum.Active &&
          existingPipelineStages[stageKey].id !== pipelineStage.id &&
          existingPipelineStages[stageKey][label] === formValue
      );
      if (valueAlreadyExists) {
        return false;
      }
    }

    if (label === "substages" && Array.isArray(pipelineStage["substages"])) {
      return pipelineStage["substages"].every((substage: MultipartInterviewSubstageForm) => {
        return (
          substage.duration && substage.possibleInterviewers && substage.possibleInterviewers.length && substage.name
        );
      });
    }
    return true;
  });
};

export const shouldHideAtsFields = (interviewSchedulingScenario: InterviewSchedulingScenario): boolean => {
  return (
    interviewSchedulingScenario !== InterviewSchedulingScenario.FAILED_TO_SYNC_HAS_CACHE &&
    interviewSchedulingScenario !== InterviewSchedulingScenario.SYNC_SUCCESSFUL_HAS_STAGES &&
    interviewSchedulingScenario !== InterviewSchedulingScenario.SYNC_UNNECESSARY_HAS_STAGES
  );
};
