/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRRCandidate,
    IRRCandidateFromJSON,
    IRRCandidateFromJSONTyped,
    IRRCandidateToJSON,
    IRRCandidateInterviewInfo,
    IRRCandidateInterviewInfoFromJSON,
    IRRCandidateInterviewInfoFromJSONTyped,
    IRRCandidateInterviewInfoToJSON,
    IRRInterview,
    IRRInterviewFromJSON,
    IRRInterviewFromJSONTyped,
    IRRInterviewToJSON,
    JobCompensation,
    JobCompensationFromJSON,
    JobCompensationFromJSONTyped,
    JobCompensationToJSON,
    JobLocation,
    JobLocationFromJSON,
    JobLocationFromJSONTyped,
    JobLocationToJSON,
    NextStepsInfo,
    NextStepsInfoFromJSON,
    NextStepsInfoFromJSONTyped,
    NextStepsInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewRubricResponse
 */
export interface InterviewRubricResponse {
    /**
     * 
     * @type {string}
     * @memberof InterviewRubricResponse
     */
    readonly id?: string;
    /**
     * 
     * @type {IRRCandidate}
     * @memberof InterviewRubricResponse
     */
    candidate?: IRRCandidate;
    /**
     * 
     * @type {IRRCandidateInterviewInfo}
     * @memberof InterviewRubricResponse
     */
    candidateInterviewInfo?: IRRCandidateInterviewInfo;
    /**
     * 
     * @type {IRRInterview}
     * @memberof InterviewRubricResponse
     */
    interview?: IRRInterview;
    /**
     * 
     * @type {string}
     * @memberof InterviewRubricResponse
     */
    readonly jobInterviewRubricTemplate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InterviewRubricResponse
     */
    interviewState?: number;
    /**
     * 
     * @type {string}
     * @memberof InterviewRubricResponse
     */
    readonly interviewStateDisplay?: InterviewRubricResponseInterviewStateDisplayEnum;
    /**
     * 
     * @type {object}
     * @memberof InterviewRubricResponse
     */
    compiledJobInterviewRubricSchema?: object;
    /**
     * 
     * @type {object}
     * @memberof InterviewRubricResponse
     */
    rubricResponses: object;
    /**
     * 
     * @type {NextStepsInfo}
     * @memberof InterviewRubricResponse
     */
    nextStepsInfo?: NextStepsInfo;
    /**
     * 
     * @type {number}
     * @memberof InterviewRubricResponse
     */
    readonly numTimesRescheduled?: number;
    /**
     * 
     * @type {Array<JobLocation>}
     * @memberof InterviewRubricResponse
     */
    locations?: Array<JobLocation>;
    /**
     * 
     * @type {JobCompensation}
     * @memberof InterviewRubricResponse
     */
    compensation?: JobCompensation;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewRubricResponse
     */
    visaSupport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InterviewRubricResponse
     */
    roughNotes?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InterviewRubricResponse
     */
    submittedByProUserId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewRubricResponse
     */
    readonly hasTranscript?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InterviewRubricResponse
     */
    feedbackTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InterviewRubricResponse
     */
    clientDefaultFeedbackTemplate?: string;
}

/**
* @export
* @enum {string}
*/
export enum InterviewRubricResponseInterviewStateDisplayEnum {
    Pending = 'Pending',
    InProgress = 'In progress',
    Submitted = 'Submitted',
    NoShow = 'No show',
    Reviewed = 'Reviewed'
}

export function InterviewRubricResponseFromJSON(json: any): InterviewRubricResponse {
    return InterviewRubricResponseFromJSONTyped(json, false);
}

export function InterviewRubricResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewRubricResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'candidate': !exists(json, 'candidate') ? undefined : IRRCandidateFromJSON(json['candidate']),
        'candidateInterviewInfo': !exists(json, 'candidate_interview_info') ? undefined : IRRCandidateInterviewInfoFromJSON(json['candidate_interview_info']),
        'interview': !exists(json, 'interview') ? undefined : IRRInterviewFromJSON(json['interview']),
        'jobInterviewRubricTemplate': !exists(json, 'job_interview_rubric_template') ? undefined : json['job_interview_rubric_template'],
        'interviewState': !exists(json, 'interview_state') ? undefined : json['interview_state'],
        'interviewStateDisplay': !exists(json, 'interview_state_display') ? undefined : json['interview_state_display'],
        'compiledJobInterviewRubricSchema': !exists(json, 'compiled_job_interview_rubric_schema') ? undefined : json['compiled_job_interview_rubric_schema'],
        'rubricResponses': json['rubric_responses'],
        'nextStepsInfo': !exists(json, 'next_steps_info') ? undefined : NextStepsInfoFromJSON(json['next_steps_info']),
        'numTimesRescheduled': !exists(json, 'num_times_rescheduled') ? undefined : json['num_times_rescheduled'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(JobLocationFromJSON)),
        'compensation': !exists(json, 'compensation') ? undefined : JobCompensationFromJSON(json['compensation']),
        'visaSupport': !exists(json, 'visa_support') ? undefined : json['visa_support'],
        'roughNotes': !exists(json, 'rough_notes') ? undefined : json['rough_notes'],
        'submittedByProUserId': !exists(json, 'submitted_by_pro_user_id') ? undefined : json['submitted_by_pro_user_id'],
        'hasTranscript': !exists(json, 'has_transcript') ? undefined : json['has_transcript'],
        'feedbackTemplate': !exists(json, 'feedback_template') ? undefined : json['feedback_template'],
        'clientDefaultFeedbackTemplate': !exists(json, 'client_default_feedback_template') ? undefined : json['client_default_feedback_template'],
    };
}

export function InterviewRubricResponseToJSON(value?: InterviewRubricResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate': IRRCandidateToJSON(value.candidate),
        'candidate_interview_info': IRRCandidateInterviewInfoToJSON(value.candidateInterviewInfo),
        'interview': IRRInterviewToJSON(value.interview),
        'interview_state': value.interviewState,
        'compiled_job_interview_rubric_schema': value.compiledJobInterviewRubricSchema,
        'rubric_responses': value.rubricResponses,
        'next_steps_info': NextStepsInfoToJSON(value.nextStepsInfo),
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(JobLocationToJSON)),
        'compensation': JobCompensationToJSON(value.compensation),
        'visa_support': value.visaSupport,
        'rough_notes': value.roughNotes,
        'submitted_by_pro_user_id': value.submittedByProUserId,
        'feedback_template': value.feedbackTemplate,
        'client_default_feedback_template': value.clientDefaultFeedbackTemplate,
    };
}


