/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserOnboardingFlow,
    UserOnboardingFlowFromJSON,
    UserOnboardingFlowFromJSONTyped,
    UserOnboardingFlowToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse20058
 */
export interface InlineResponse20058 {
    /**
     * 
     * @type {number}
     * @memberof InlineResponse20058
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20058
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20058
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<UserOnboardingFlow>}
     * @memberof InlineResponse20058
     */
    results: Array<UserOnboardingFlow>;
}

export function InlineResponse20058FromJSON(json: any): InlineResponse20058 {
    return InlineResponse20058FromJSONTyped(json, false);
}

export function InlineResponse20058FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20058 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'previous': !exists(json, 'previous') ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(UserOnboardingFlowFromJSON)),
    };
}

export function InlineResponse20058ToJSON(value?: InlineResponse20058 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(UserOnboardingFlowToJSON)),
    };
}


