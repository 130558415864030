/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useMemo } from "react";
import { Column, useTable } from "react-table";

import { Body, Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useListUserUploadedFilesQuery } from "services/doverapi/endpoints/userUploadedFile";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { UserUploadedFileUploadedContextEnum, UserUploadedFileProcessingStateEnum } from "services/openapi";
import { colors } from "styles/theme";
import {
  StyledTableContainer,
  StyledTableRow,
  StyledTableCell,
  StyledHeaderTableCell,
} from "views/CompanySetup/components/Exclusions/styles";
interface UploadedFilesTableProps {
  uploadedContext: UserUploadedFileUploadedContextEnum;
  title: string;
}

interface UploadedFilesTableData {
  user: string | undefined;
  uploadDate: string | undefined | React.ReactElement;
  fileName: string | undefined;
}

const displayUploadStatus = (
  processingState: UserUploadedFileProcessingStateEnum | undefined,
  lastProcessedAt: Date | undefined,
  processingError: string | undefined
): React.ReactElement => {
  if (processingState === UserUploadedFileProcessingStateEnum.Failed) {
    if (processingError?.includes("Missing one or more required column headers")) {
      return <Body color={colors.critical.base}>Failed - check headers and upload again</Body>;
    }
    return (
      <>
        <Body color={colors.critical.base}>Upload failed</Body>
        <Body> - please upload again </Body>
      </>
    );
  }

  const processingTime = moment(lastProcessedAt).format("MM/DD/YY");
  if (processingState === UserUploadedFileProcessingStateEnum.Processed) {
    return <Body>{processingTime}</Body>;
  }
  const statusText = processingState?.slice(0, 1) + "" + processingState?.slice(1).toLowerCase();
  return <Body italic>{statusText}</Body>;
};

export const UploadedFilesTable = ({ uploadedContext, title }: UploadedFilesTableProps): React.ReactElement => {
  const { data, isLoading, isError } = useListUserUploadedFilesQuery({
    uploadedContext: uploadedContext,
    limit: 100,
    ordering: "-created",
  });
  const uploadedFiles = listAllEntities(data);

  const columns = useMemo(
    (): Column<UploadedFilesTableData>[] => [
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "File Name",
        accessor: "fileName",
      },
      {
        Header: "Upload Date",
        accessor: "uploadDate",
      },
    ],
    []
  );

  const tableData = useMemo((): UploadedFilesTableData[] => {
    return uploadedFiles.map(uploadedFile => {
      const row: UploadedFilesTableData = {
        user: uploadedFile.user?.fullName,
        fileName: uploadedFile.originalFileName,
        uploadDate: displayUploadStatus(
          uploadedFile?.processingState,
          uploadedFile?.lastProcessedAt,
          uploadedFile?.processingError
        ),
      };
      return row;
    });
  }, [data]);

  const isTableEmpty = tableData.length === 0;

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: tableData,
  });

  if (isError) {
    return <div>Unable to load previously uploaded files. Please refresh the page to try again.</div>;
  }

  return (
    <StyledTableContainer>
      {isLoading && <DoverLoadingSpinner spinnerSize={"30px"} />}
      {!isLoading && !isTableEmpty && (
        <>
          <hr
            style={{
              color: colors.grayscale.gray200,
              marginTop: "24px",
              marginBottom: "24px",
            }}
          />
          <Heading>{title}</Heading>
          <Spacer height="16px" />
          <Table
            className="users-with-uploaded-connections-table"
            stickyHeader
            aria-labelledby="Table"
            size="medium"
            aria-label="searches table"
          >
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <StyledHeaderTableCell key={column.id} align="left" padding="normal">
                      {column.render("Header")}
                    </StyledHeaderTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <StyledTableRow
                    /*
                    // @ts-ignore */
                    key={row.index}
                    {...row.getRowProps()}
                  >
                    {row.cells.map(cell => (
                      <StyledTableCell {...cell.getCellProps()} align="left">
                        {cell.render("Cell")}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
    </StyledTableContainer>
  );
};
