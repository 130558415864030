/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDoverOutboundConfiguration
 */
export interface UpdateDoverOutboundConfiguration {
    /**
     * 
     * @type {number}
     * @memberof UpdateDoverOutboundConfiguration
     */
    phoneScreensPerWeek?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDoverOutboundConfiguration
     */
    emailsPerWeekOption?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDoverOutboundConfiguration
     */
    emailsPerWeek?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDoverOutboundConfiguration
     */
    shouldEngagePassive?: UpdateDoverOutboundConfigurationShouldEngagePassiveEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDoverOutboundConfiguration
     */
    autoQueueOutreach?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDoverOutboundConfiguration
     */
    sourceSimilarCandidates?: boolean | null;
}

/**
* @export
* @enum {string}
*/
export enum UpdateDoverOutboundConfigurationShouldEngagePassiveEnum {
    All = 'All',
    InterestedInRole = 'Interested In Role',
    ActiveOnly = 'Active Only'
}

export function UpdateDoverOutboundConfigurationFromJSON(json: any): UpdateDoverOutboundConfiguration {
    return UpdateDoverOutboundConfigurationFromJSONTyped(json, false);
}

export function UpdateDoverOutboundConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDoverOutboundConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneScreensPerWeek': !exists(json, 'phone_screens_per_week') ? undefined : json['phone_screens_per_week'],
        'emailsPerWeekOption': !exists(json, 'emails_per_week_option') ? undefined : json['emails_per_week_option'],
        'emailsPerWeek': !exists(json, 'emails_per_week') ? undefined : json['emails_per_week'],
        'shouldEngagePassive': !exists(json, 'should_engage_passive') ? undefined : json['should_engage_passive'],
        'autoQueueOutreach': !exists(json, 'auto_queue_outreach') ? undefined : json['auto_queue_outreach'],
        'sourceSimilarCandidates': !exists(json, 'source_similar_candidates') ? undefined : json['source_similar_candidates'],
    };
}

export function UpdateDoverOutboundConfigurationToJSON(value?: UpdateDoverOutboundConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone_screens_per_week': value.phoneScreensPerWeek,
        'emails_per_week_option': value.emailsPerWeekOption,
        'emails_per_week': value.emailsPerWeek,
        'should_engage_passive': value.shouldEngagePassive,
        'auto_queue_outreach': value.autoQueueOutreach,
        'source_similar_candidates': value.sourceSimilarCandidates,
    };
}


