/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateJobDescription
 */
export interface UpdateJobDescription {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateJobDescription
     */
    useDoverJd?: boolean;
    /**
     * Dover-generated job description - should never be updated by customers
     * @type {string}
     * @memberof UpdateJobDescription
     */
    userFacingDescription?: string;
    /**
     * Job Description that is provided by users on the Job Description page. May also be an edited version of the Dover-generated description.
     * @type {string}
     * @memberof UpdateJobDescription
     */
    userProvidedDescription?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateJobDescription
     */
    isPublished?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateJobDescription
     */
    externalUrl?: string | null;
}

export function UpdateJobDescriptionFromJSON(json: any): UpdateJobDescription {
    return UpdateJobDescriptionFromJSONTyped(json, false);
}

export function UpdateJobDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJobDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'useDoverJd': !exists(json, 'use_dover_jd') ? undefined : json['use_dover_jd'],
        'userFacingDescription': !exists(json, 'user_facing_description') ? undefined : json['user_facing_description'],
        'userProvidedDescription': !exists(json, 'user_provided_description') ? undefined : json['user_provided_description'],
        'isPublished': !exists(json, 'is_published') ? undefined : json['is_published'],
        'externalUrl': !exists(json, 'external_url') ? undefined : json['external_url'],
    };
}

export function UpdateJobDescriptionToJSON(value?: UpdateJobDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'use_dover_jd': value.useDoverJd,
        'user_facing_description': value.userFacingDescription,
        'user_provided_description': value.userProvidedDescription,
        'is_published': value.isPublished,
        'external_url': value.externalUrl,
    };
}


