/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobSetupStep,
    JobSetupStepFromJSON,
    JobSetupStepFromJSONTyped,
    JobSetupStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobSetupSteps
 */
export interface JobSetupSteps {
    /**
     * 
     * @type {Array<JobSetupStep>}
     * @memberof JobSetupSteps
     */
    setupSteps: Array<JobSetupStep>;
    /**
     * 
     * @type {string}
     * @memberof JobSetupSteps
     */
    jpqId: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetupSteps
     */
    id: string;
}

export function JobSetupStepsFromJSON(json: any): JobSetupSteps {
    return JobSetupStepsFromJSONTyped(json, false);
}

export function JobSetupStepsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobSetupSteps {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'setupSteps': ((json['setup_steps'] as Array<any>).map(JobSetupStepFromJSON)),
        'jpqId': json['jpq_id'],
        'id': json['id'],
    };
}

export function JobSetupStepsToJSON(value?: JobSetupSteps | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'setup_steps': ((value.setupSteps as Array<any>).map(JobSetupStepToJSON)),
        'jpq_id': value.jpqId,
        'id': value.id,
    };
}


