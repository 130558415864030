import { atom, useAtomValue } from "jotai";

import { ListSaapReviewApplicationRequest } from "services/openapi";
import { applicationQuestionsAtom } from "views/candidates/ApplicationReview/atoms/applicationQuestions";
import { conversationForApiAtom, useSaapParamsAtom } from "views/candidates/ApplicationReview/atoms/chat";
import {
  bookmarkedFilterAtom,
  criteriaMatchFilterAtom,
  inboundSourceFilterAtom,
  nameFilterValAtom,
} from "views/candidates/ApplicationReview/atoms/filters";
import { orderingAtom, useOrderingValue } from "views/candidates/ApplicationReview/atoms/ordering";
import { paginationAtom } from "views/candidates/ApplicationReview/atoms/pagination";
import { saapParamsAtom } from "views/candidates/ApplicationReview/atoms/saapParams";
import { searchIdAtom } from "views/candidates/ApplicationReview/atoms/searchId";

export const LIST_AI_CHAT_API_LIMIT = 500;

export const toNextApplicationAtom = atom<(() => void) | undefined>(undefined);

type ListSaapReviewApplicationRequestAtom = Required<
  Pick<ListSaapReviewApplicationRequest, "searchId" | "params" | "ordering" | "limit">
> &
  Partial<ListSaapReviewApplicationRequest>;

export const listSaapReviewApplicationArgsAtom = atom<ListSaapReviewApplicationRequestAtom | undefined>(get => {
  // Search ID is required for this API
  const searchId = get(searchIdAtom);
  if (!searchId) {
    return undefined;
  }

  const params = get(saapParamsAtom);
  if (!params) {
    // wait for params to be loaded in before we call the API
    return undefined;
  }
  const applicationQuestions = get(applicationQuestionsAtom);
  const inboundSourceFilter = get(inboundSourceFilterAtom);
  const criteriaMatchFilter = get(criteriaMatchFilterAtom);
  const ordering = get(orderingAtom);
  const { page, limit } = get(paginationAtom);
  const nameFilterVal = get(nameFilterValAtom);
  const bookmarkedFilter = get(bookmarkedFilterAtom);

  return {
    searchId,
    params,
    additionalFilters: {
      applicationQuestions,
      sourceLabels: inboundSourceFilter,
      criteriaMatch: criteriaMatchFilter,
    },
    includeExplanations: true,
    page,
    limit,
    // NOTE: this isn't the ordering we will be using. We will be using the value from the hook below
    ordering,
    nameFilterVal,
    bookmarkedFilter,
  };
});

export const useListSaapReviewApplicationArgs = (): ListSaapReviewApplicationRequest | undefined => {
  const ordering = useOrderingValue();
  const rawAtom = useAtomValue(listSaapReviewApplicationArgsAtom);

  if (!rawAtom) {
    return undefined;
  }

  return {
    ...rawAtom,
    ordering,
  };
};

export const listApplicationsViaAiArgsAtom = atom(get => {
  // Search ID is required for this API
  const searchId = get(searchIdAtom);
  if (!searchId) {
    return undefined;
  }

  const conversation = get(conversationForApiAtom);
  const params = get(saapParamsAtom);
  const useSaapParams = get(useSaapParamsAtom);

  const paramsToBuildOn = useSaapParams ? params : undefined;

  return {
    data: {
      searchId,
      conversation,
      paramsToBuildOn,
      limit: LIST_AI_CHAT_API_LIMIT,
    },
  };
});

export const useListAppsOptimisticUpdateArgsAtom = (): any => {
  const toNextApplicationFunction = useAtomValue(toNextApplicationAtom);
  const toNextApplication = toNextApplicationFunction;
  const listSaapReviewApplicationArgs = useListSaapReviewApplicationArgs();
  const listApplicationsViaAiArgs = useAtomValue(listApplicationsViaAiArgsAtom);

  return {
    toNextApplication,
    listSaapReviewApplicationArgs,
    listApplicationsViaAiArgs,
  };
};
