import { MinMaxRange, ProfileRequestSortByEnum, SearchV3Params } from "services/openapi";
import { SearchV3FormSchemaType } from "views/sourcing/Search/types";

export const SAAP_PARAMS_DEBOUNCE = 1000;

export const CUSTOM_PERSONA_NAME = "Persona: custom";
export const CUSTOM_PERSONA_ID = "7b4731dc-d219-4d4d-a947-bd668ee605cd";
export const FiltersContainerID = "filtersContainer";
export const previewStateMarginRight = "5px";
export const previewStateMarginBottom = "2px";
export const sliderPaddingRight = "20px";
export const FILTERS_WIDTH_INT = 325;
export const FILTERS_WIDTH = `${FILTERS_WIDTH_INT}px`;
const CHIP_MAX_WIDTH_MULTIPLER = 0.6;
export const CHIP_MAX_WIDTH = `${FILTERS_WIDTH_INT * CHIP_MAX_WIDTH_MULTIPLER}px`;
const TITLE_MAX_WIDTH_MULTIPLER = 0.8;
export const TITLE_MAX_WIDTH = `${FILTERS_WIDTH_INT * TITLE_MAX_WIDTH_MULTIPLER}px`;

export const SEARCH_NAME = "name";
export const PERSONAS_ELEMENTS_NAME = "personas.personas";
export const LOCATIONS_ELEMENTS_NAME = "locations.locationElements";
export const EXCLUDED_LOCATIONS_ELEMENTS_NAME = "excludedLocations.locationElements";
export const TOTAL_YEARS_OF_EXPERIENCE_NAME = "totalYearsOfExperience.yearsOfExperience";
export const SPECIFIC_YEARS_OF_EXPERIENCE_NAME = "specificYearsOfExperience.yearsOfExperience";
export const SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME = "specificYearsOfExperienceByT2.elements";
export const YEARS_AT_CURRENT_COMPANY_NAME = "yearsAtCurrentCompany.yearsOfExperience";
export const BACHELORS_GRADUATION_YEAR_NAME_MIN = "bachelorsGraduationYear.yearsOfExperience.min";
export const BACHELORS_GRADUATION_YEAR_NAME_MAX = "bachelorsGraduationYear.yearsOfExperience.max";
export const EXCLUDE_FREQUENT_JOB_SWITCHING_NAME = "excludeFrequentJobSwitching";
export const SELECTED_SENIORITIES_NAME = "seniorities.selectedSeniorities";
export const EXCLUDED_SENIORITIES_NAME = "excludedSeniorities.selectedSeniorities";
export const COMPANY_SIZES_NAME = "companySizes";
export const COMPANY_SIZES_EXCLUSIONS_NAME = "companySizeExclusions";
export const SELECTED_DOVER_COMPANY_LISTS_NAME = "targetCompanyLists.selectedDoverCompanyLists";
export const INDIVIDUAL_COMPANIES_NAME = "individualCompanies";
export const INDIVIDUAL_COMPANIES_ELEMENTS_NAME = "individualCompanies.individualCompanyElements";
export const SELECTED_COMPANY_LISTS_MOST_RECENT_ONLY = "targetCompaniesMostRecentOnly";
export const INDIVIDUAL_EXCLUDED_COMPANIES_NAME = "individualExcludedCompanies.individualCompanyElements";
export const INDUSTRIES_NAME = "industries";
export const EXCLUDED_INDUSTRIES_NAME = "excludedIndustries";
export const INDUSTRIES_ELEMENTS_NAME = "industries.industryElements";
export const EXCLUDED_INDUSTRIES_ELEMENTS_NAME = "excludedIndustries.industryElements";
export const INDUSTRIES_MOST_RECENT_ONLY = "industriesMostRecentOnly";
export const COMPANY_RANK_RANGE_NAME = "companyRankRange.rankRange";
export const SCHOOL_RANK_RANGE_NAME = "schoolRankRange.rankRange";
export const TARGET_SCHOOL_LISTS_NAME = "targetSchoolLists.doverSchoolLists";
export const INDIVIDUAL_SCHOOLS_NAME = "individualSchools.individualSchools";
export const TARGET_SCHOOLS_NAME = "individualSchools";
export const INDIVIDUAL_EXCLUDED_SCHOOLS_NAME = "individualExcludedSchools.individualSchools";
export const FIELDS_OF_STUDY_NAME = "fieldsOfStudy";
export const FIELDS_OF_STUDY_ELEMENTS_NAME = "fieldsOfStudy.selectedFieldsOfStudy";
export const EDUCATION_LEVELS_NAME = "educationLevel";
export const EDUCATION_LEVELS_ELEMENTS_NAME = "educationLevel.selectedEducationLevel";
export const DIVERSITY_OPTION_NAME = "diversityOption";
export const STRICTNESS_NAME = "strictness";
export const CUSTOM_TITLES_NAME = "personas.customTitles";
export const CUSTOM_TITLES_SOFT_MATCH_NAME = "personas.customTitlesSoftMatch";
export const PERSONAS_MOST_RECENT_ONLY_NAME = "personas.personasMostRecentOnly";
export const EXCLUDED_TITLES_NAME = "personas.excludedTitles";
export const DENIED_KEYWORDS_NAME = "deniedKeywords";
export const SORTBY_NAME = "sortBy";

export const YEARS_OF_EXPERIENCE_MAX = 30;
export const YEARS_OF_EXPERIENCE_ALLOWED_RANGE = 1;

// errors
export const PERSONA_REQUIRED_ERROR = "NormalPersonasRequired";
export const CUSTOM_TITLES_REQUIRED_ERROR = "CustomTitlesRequired";
export const CUSTOM_PERSONA_SELECTED_ERROR = "CustomPersonaSelected";
export const BACHELORS_GRADUATION_YEAR_ERROR = "Please enter a valid range";

// weights - note that if any of these are changed, you must change them in search_params_converter.py too (backend)
export const PREFERRED_INDUSTRIES_WEIGHT = 0.5;
export const PREFERRED_COMPANY_SIZE_WEIGHT = 0.5;
export const PREFERRED_TARGET_SCHOOLS_WEIGHT = 0.5;
export const PREFERRED_FOS_WEIGHT = 0.5;
export const PREFERRED_KEYWORDS_WEIGHT = 0.5;
export const PREFERRED_TARGET_COS_WEIGHT = 0.5;
export const PREFERRED_EDUCATION_LEVEL_WEIGHT = 0.5;

export const EMPTY_SEARCH_PLACEHOLDER = "f97b2bd5-bf00-48d5-a78a-dcc99ae42ad0";

export const searchV3FormDefaultValues: Partial<SearchV3FormSchemaType> = {
  name: EMPTY_SEARCH_PLACEHOLDER,
  locations: { required: true, locationElements: [] },
  personas: {
    personas: [],
    customTitles: [],
    customTitlesSoftMatch: true,
    excludedTitles: [],
    required: true,
    personasMostRecentOnly: false,
  },
  excludedLocations: { required: true, locationElements: [] },
  seniorities: { required: false, selectedSeniorities: [] },
  excludedSeniorities: { required: false, selectedSeniorities: [] },
  totalYearsOfExperience: { required: true, yearsOfExperience: {} },
  specificYearsOfExperience: { required: true, yearsOfExperience: {} },
  specificYearsOfExperienceByT2: { required: true, elements: [] },
  yearsAtCurrentCompany: { required: true, yearsOfExperience: {} },
  bachelorsGraduationYear: { required: true, yearsOfExperience: {} },
  excludeFrequentJobSwitching: false,
  onlyTargetSpecificSchools: false,
  targetCompanyLists: { required: true, selectedDoverCompanyLists: [] },
  targetCompaniesMostRecentOnly: false,
  individualCompanies: { required: false, individualCompanyElements: [] },
  individualExcludedCompanies: { required: false, individualCompanyElements: [] },
  fieldsOfStudy: { required: false, selectedFieldsOfStudy: [] },
  educationLevel: { required: false, selectedEducationLevel: null },
  keywordsBuckets: [],
  deniedKeywords: [],
  companySizes: { required: false, value: [], mostRecentOnly: false },
  companySizeExclusions: { required: false, value: [], mostRecentOnly: false },
  industries: { required: false, industryElements: [] },
  excludedIndustries: { required: true, industryElements: [] },
  industriesMostRecentOnly: false,
  schoolRankRange: { required: true, rankRange: {} },
  companyRankRange: { required: true, rankRange: {} },
  targetSchoolLists: { required: true, doverSchoolLists: [] },
  individualSchools: { required: true, individualSchools: [] },
  individualExcludedSchools: { required: true, individualSchools: [] },
  strictness: 3,
  sortBy: ProfileRequestSortByEnum.Desc,
};

export const emptySearchV3Params: Omit<SearchV3Params, "targetPersonas"> = {
  targetCustomTitles: [],
  excludedCustomTitles: [],
  customTitlesSoftMatch: [],
  targetLocations: [],
  excludedLocations: [],
  targetSeniorities: [],
  personasMostRecentOnly: [],
  excludedSeniorities: [],
  totalYearsOfExperience: [],
  specificYearsOfExperience: [],
  specificYearsOfExperienceByT2: [],
  yearsAtCurrentCompanyRange: [],
  bachelorsGraduationYearRange: [],
  industries: [],
  excludedIndustries: [],
  industriesMostRecentOnly: [],
  targetCompanyLists: [],
  excludedCompanyLists: [],
  individualTargetCompanyIds: [],
  individualExcludedCompanyIds: [],
  targetCompaniesMostRecentOnly: [],
  targetCompaniesOnly: [],
  companySize: [],
  excludedCompanySize: [],
  targetSchoolLists: [],
  excludedSchoolLists: [],
  companyRankRange: [],
  schoolRankRange: [],
  individualTargetSchoolIds: [],
  // TODO(willjow): remove school_names
  individualTargetSchoolNames: [],
  individualExcludedSchoolIds: [],
  // TODO(willjow): remove school_names
  individualExcludedSchoolNames: [],
  fieldsOfStudy: [],
  educationLevel: [],
  keywords: [],
  deniedKeywords: [],
  diversity: [],
  excludeFrequentJobSwitching: [],
  customQueries: [],
  minScoreRatio: 0,
  isReengagementSearch: false,
  transformsToApplyDuringNormalization: [],
};

export const SEARCH_ID_OVERRIDE_QUERY_PARAM_NAME = "searchIdOverride";

// a map of school tier to score that will be used by ES in backend
// calculated by looking at proportion of people returned by ES for these scores
export const SCHOOL_TIER_TO_SCORE_MAP: Record<number, MinMaxRange> = {
  0: { min: 0.0, max: 0.1 }, // 0th percentile
  1: { min: 0.11, max: 0.43 }, // 80th percentile
  2: { min: 0.44, max: 0.62 }, // 94th percentile
  3: { min: 0.63, max: 0.81 }, // 96th percentile
  4: { min: 0.82, max: 1 }, // 99th percentile
};

// a map of company tier to score that will be used by ES in backend
// calculated by looking at proportion of people returned by ES for these scores
export const COMPANY_TIER_TO_SCORE_MAP: Record<number, MinMaxRange> = {
  0: { min: 0.0, max: 0.0624 }, // 0th percentile
  1: { min: 0.0625, max: 0.124 }, // 60th percentile
  2: { min: 0.125, max: 0.374 }, // 70th percentile
  3: { min: 0.375, max: 0.499 }, // 80th percentile
  4: { min: 0.5, max: 0.624 }, // 90th percentile
  5: { min: 0.625, max: 0.749 }, // 95th percentile
  6: { min: 0.75, max: 0.874 }, // 98th percentile
  7: { min: 0.875, max: 1.0 }, // 99th percentile
};

export const COMPANY_TIER_TO_PERCENTILE_EXPLAINER: Record<number, string> = {
  0: "0%",
  1: "~50%",
  2: "~40%",
  3: "~30%",
  4: "~15%",
  5: "~10%",
  6: "~5%",
  7: "~2%",
};

export const SCHOOL_TIER_TO_PERCENTILE_EXPLAINER: Record<number, string> = {
  0: "0%",
  1: "~20%",
  2: "~6%",
  3: "~4%",
  4: "~1%",
};
