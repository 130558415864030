/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimpleJobFunnelStats
 */
export interface SimpleJobFunnelStats {
    /**
     * 
     * @type {number}
     * @memberof SimpleJobFunnelStats
     */
    interestRate: number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleJobFunnelStats
     */
    phoneScreens: number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleJobFunnelStats
     */
    secondaryInterviews: number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleJobFunnelStats
     */
    onsites: number | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleJobFunnelStats
     */
    hired: number | null;
}

export function SimpleJobFunnelStatsFromJSON(json: any): SimpleJobFunnelStats {
    return SimpleJobFunnelStatsFromJSONTyped(json, false);
}

export function SimpleJobFunnelStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleJobFunnelStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interestRate': json['interest_rate'],
        'phoneScreens': json['phone_screens'],
        'secondaryInterviews': json['secondary_interviews'],
        'onsites': json['onsites'],
        'hired': json['hired'],
    };
}

export function SimpleJobFunnelStatsToJSON(value?: SimpleJobFunnelStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interest_rate': value.interestRate,
        'phone_screens': value.phoneScreens,
        'secondary_interviews': value.secondaryInterviews,
        'onsites': value.onsites,
        'hired': value.hired,
    };
}


