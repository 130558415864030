import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useQueryParam } from "use-query-params";

import { Body, Heading } from "components/library/typography";
import { flowLabels } from "sections/addcandidate/data/flowConfig";
import { HEADER_HEIGHT, HELP_DOC_HREFS } from "sections/addcandidate/data/misc";
import { OUTREACH_TYPE_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { OutreachTypeParam, OutreachTypes } from "sections/addcandidate/types";

const DrawerHeader = (): React.ReactElement => {
  const { flowType, closeModal } = useAddCandidateContext();
  const [outreachType] = useQueryParam(OUTREACH_TYPE_QUERY_PARAM, OutreachTypeParam);
  const theme = useTheme();

  const onClose = (): void => closeModal(false);

  return (
    <Box sx={{ position: "sticky", top: 0, height: HEADER_HEIGHT, zIndex: 1400 }}>
      <Paper elevation={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: "100%", pl: 3, pr: 3, pt: 1, pb: 1 }}
        >
          {/* Title */}
          <Heading>
            Add Candidate: {flowLabels[flowType]} {outreachType === OutreachTypes.BULK && "(Bulk)"}
          </Heading>

          {/* Left Side: Help & Close button */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <MuiButton
              variant="text"
              startIcon={<HelpOutlineRoundedIcon />}
              sx={{ textTransform: "none" }}
              href={HELP_DOC_HREFS.ADD_CANDIDATE}
              target="_blank"
            >
              <Body color={theme.palette.primary.dark}>Help</Body>
            </MuiButton>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default DrawerHeader;
