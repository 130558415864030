/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlimEmailSenderAlias,
    SlimEmailSenderAliasFromJSON,
    SlimEmailSenderAliasFromJSONTyped,
    SlimEmailSenderAliasToJSON,
} from './';

/**
 * 
 * @export
 * @interface BaseClientCampaignLimit
 */
export interface BaseClientCampaignLimit {
    /**
     * 
     * @type {boolean}
     * @memberof BaseClientCampaignLimit
     */
    readonly emailCapacityReached?: boolean;
    /**
     * 
     * @type {Array<SlimEmailSenderAlias>}
     * @memberof BaseClientCampaignLimit
     */
    readonly activeEmailSenderAliases?: Array<SlimEmailSenderAlias>;
    /**
     * 
     * @type {number}
     * @memberof BaseClientCampaignLimit
     */
    readonly managedEmailsLimit?: number;
}

export function BaseClientCampaignLimitFromJSON(json: any): BaseClientCampaignLimit {
    return BaseClientCampaignLimitFromJSONTyped(json, false);
}

export function BaseClientCampaignLimitFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseClientCampaignLimit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailCapacityReached': !exists(json, 'email_capacity_reached') ? undefined : json['email_capacity_reached'],
        'activeEmailSenderAliases': !exists(json, 'active_email_sender_aliases') ? undefined : ((json['active_email_sender_aliases'] as Array<any>).map(SlimEmailSenderAliasFromJSON)),
        'managedEmailsLimit': !exists(json, 'managed_emails_limit') ? undefined : json['managed_emails_limit'],
    };
}

export function BaseClientCampaignLimitToJSON(value?: BaseClientCampaignLimit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


