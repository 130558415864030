/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientInterviewer,
    ClientInterviewerFromJSON,
    ClientInterviewerFromJSONTyped,
    ClientInterviewerToJSON,
} from './';

/**
 * 
 * @export
 * @interface Interview
 */
export interface Interview {
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    id: string;
    /**
     * 
     * @type {ClientInterviewer}
     * @memberof Interview
     */
    interviewer?: ClientInterviewer;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    readonly stageName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Interview
     */
    readonly startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Interview
     */
    readonly endDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Interview
     */
    readonly hasDoverInterviewer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Interview
     */
    readonly hasCompletedNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Interview
     */
    readonly isTakeHome?: boolean;
}

export function InterviewFromJSON(json: any): Interview {
    return InterviewFromJSONTyped(json, false);
}

export function InterviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): Interview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'interviewer': !exists(json, 'interviewer') ? undefined : ClientInterviewerFromJSON(json['interviewer']),
        'stageName': !exists(json, 'stage_name') ? undefined : json['stage_name'],
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
        'hasDoverInterviewer': !exists(json, 'has_dover_interviewer') ? undefined : json['has_dover_interviewer'],
        'hasCompletedNotes': !exists(json, 'has_completed_notes') ? undefined : json['has_completed_notes'],
        'isTakeHome': !exists(json, 'is_take_home') ? undefined : json['is_take_home'],
    };
}

export function InterviewToJSON(value?: Interview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'interviewer': ClientInterviewerToJSON(value.interviewer),
    };
}


