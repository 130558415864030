import { skipToken } from "@reduxjs/toolkit/dist/query";
import { EditorState } from "draft-js";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { useListHiringStageEmailTemplatesQuery } from "services/doverapi/endpoints/emailTemplates";
import { HiringStageEmailTemplate } from "services/openapi";
import { InterviewPlanStageType } from "services/openapi/models/InterviewPlanStageType";
import { getHtmlBody } from "utils/draft-js-helpers";
import { EmailTemplateEdit } from "views/job/JobSetup/components/types";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import {
  filterRelevantTemplates,
  getSchedulingTemplateTypes,
  getTemplatesForHiringStage,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/helpers";

const useHiringStageEmailTemplates = (
  jobId: string | null | undefined,
  stageData: StageDataWrapper,
  isTakeHome: boolean,
  isMultiPart: boolean
): HiringStageEmailTemplate[] | null => {
  const { data: allTemplates } = useListHiringStageEmailTemplatesQuery(jobId ?? skipToken, {
    selectFromResult: ({ data }) => ({
      data: data && stageData.id ? getTemplatesForHiringStage(data, stageData.id) : undefined,
    }),
  });

  const schedulingTemplateType = getSchedulingTemplateTypes(stageData, isTakeHome, isMultiPart);

  const templates = useMemo(() => {
    if (!allTemplates) {
      return null;
    }
    return filterRelevantTemplates(allTemplates, schedulingTemplateType);
  }, [allTemplates, schedulingTemplateType]);

  return templates;
};

export const useEditableHiringStageEmailTemplates = (
  jobId: string | null | undefined,
  stageData: StageDataWrapper,
  selectedEmailTemplateId: string | null,
  setSelectedEmailTemplateId: (id: string) => void,
  isTakeHome: boolean,
  isMultiPart: boolean,
  interviewType: InterviewPlanStageType
): [
  HiringStageEmailTemplate[] | null,
  { [id: string]: EmailTemplateEdit } | null,
  Dispatch<SetStateAction<{ [id: string]: EmailTemplateEdit } | null>>
] => {
  const [emailTemplateEditsById, setEmailTemplateEditsById] = useState<{
    [id: string]: EmailTemplateEdit;
  } | null>(null);
  const [emailTemplateEditsInitialized, setEmailTemplateEditsInitialized] = useState(false);
  const templates = useHiringStageEmailTemplates(jobId, stageData, isTakeHome, isMultiPart);
  const [savedInterviewType, setSavedInterviewType] = useState<InterviewPlanStageType | null>(null);

  useEffect(() => {
    const selectedTemplateNotAvailable =
      selectedEmailTemplateId &&
      templates &&
      templates.length > 0 &&
      emailTemplateEditsById &&
      !emailTemplateEditsById[selectedEmailTemplateId];

    if (templates && (!selectedEmailTemplateId || selectedTemplateNotAvailable)) {
      setSelectedEmailTemplateId(templates[0]?.id!);
    }
  }, [selectedEmailTemplateId, emailTemplateEditsById, setSelectedEmailTemplateId, templates]);

  useEffect(() => {
    if ((emailTemplateEditsInitialized && savedInterviewType === interviewType) || !templates) {
      return;
    }

    const emailTemplateEditStates = templates.map(
      t =>
        ({
          id: t?.id,
          bodyTemplate: EditorState.createWithContent(getHtmlBody(t?.bodyTemplate ?? "")),
          subjectTemplate: EditorState.createWithContent(getHtmlBody(t?.subjectTemplate ?? "")),
          contacts: {
            sender: {
              email: t?.fromUserInfo?.email,
              name: t?.fromUserInfo?.fullName,
            },
            recipient: {},
            ccRecipients: t?.ccEmails?.map(email => {
              return { name: "", email };
            }),
          },
        } as EmailTemplateEdit)
    );

    const emailTemplateEditStatesById = _.keyBy(emailTemplateEditStates, "id");

    setEmailTemplateEditsById(emailTemplateEditStatesById);
    setEmailTemplateEditsInitialized(true);
    setSavedInterviewType(interviewType);
  }, [templates, emailTemplateEditsInitialized, savedInterviewType, interviewType]);

  return useMemo(() => [templates, emailTemplateEditsById, setEmailTemplateEditsById], [
    emailTemplateEditsById,
    templates,
  ]);
};
