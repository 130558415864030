/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchFeatures
 */
export interface SearchFeatures {
    /**
     * 
     * @type {number}
     * @memberof SearchFeatures
     */
    totalMinYoe?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchFeatures
     */
    totalMaxYoe?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFeatures
     */
    locations?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFeatures
     */
    requiredKeywords?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFeatures
     */
    desiredKeywords?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFeatures
     */
    requiredFieldsOfStudy?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFeatures
     */
    desiredFieldsOfStudy?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchFeatures
     */
    educationLevel?: string | null;
}

export function SearchFeaturesFromJSON(json: any): SearchFeatures {
    return SearchFeaturesFromJSONTyped(json, false);
}

export function SearchFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalMinYoe': !exists(json, 'total_min_yoe') ? undefined : json['total_min_yoe'],
        'totalMaxYoe': !exists(json, 'total_max_yoe') ? undefined : json['total_max_yoe'],
        'locations': !exists(json, 'locations') ? undefined : json['locations'],
        'requiredKeywords': !exists(json, 'required_keywords') ? undefined : json['required_keywords'],
        'desiredKeywords': !exists(json, 'desired_keywords') ? undefined : json['desired_keywords'],
        'requiredFieldsOfStudy': !exists(json, 'required_fields_of_study') ? undefined : json['required_fields_of_study'],
        'desiredFieldsOfStudy': !exists(json, 'desired_fields_of_study') ? undefined : json['desired_fields_of_study'],
        'educationLevel': !exists(json, 'education_level') ? undefined : json['education_level'],
    };
}

export function SearchFeaturesToJSON(value?: SearchFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_min_yoe': value.totalMinYoe,
        'total_max_yoe': value.totalMaxYoe,
        'locations': value.locations,
        'required_keywords': value.requiredKeywords,
        'desired_keywords': value.desiredKeywords,
        'required_fields_of_study': value.requiredFieldsOfStudy,
        'desired_fields_of_study': value.desiredFieldsOfStudy,
        'education_level': value.educationLevel,
    };
}


