/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import { AdminClient, ClientOnboardingAtsTypeEnum } from "services/openapi";

export const DOVER_CLIENT_INFO: Readonly<AdminClient> = {
  name: "Dover",
  realName: "Dover",
  id: "733c3162-cbbd-6558-9866-1d6b8561f8b9",
  csm: null,
};

export const CLIENT_ID_QUERY_PARAM = "clientId";

const TOP_BAR_HEIGHT = "56px";
// Screen height minus the height of the top bar
export const FULL_SCREEN_EXCEPT_TOP_BAR_HEIGHT = `calc(100vh - ${TOP_BAR_HEIGHT})`;

export const jobIdQueryParam = "jobId";

export const AppId = "app";

export const SIDE_NAV_WIDTH = 235;
// SIDE_NAV_WIDTH - 20
export const SIDE_NAV_POPPER_MIN_WIDTH = 215;
export const SPACE_RESERVED_FOR_DRAWER_TOGGLE_MENU = 16;

// There below two types create a mapping between ATS types and their display names
type AtsOptions = { [key in ClientOnboardingAtsTypeEnum]: Readonly<string> };
export const atsOptions: Readonly<AtsOptions> = {
  [ClientOnboardingAtsTypeEnum.NoAts]: "Dover",
  [ClientOnboardingAtsTypeEnum.Greenhouse]: "Greenhouse",
  [ClientOnboardingAtsTypeEnum.Lever]: "Lever",
  [ClientOnboardingAtsTypeEnum.Ashby]: "Ashby",
  [ClientOnboardingAtsTypeEnum.Other]: "Other",
  [ClientOnboardingAtsTypeEnum.GoogleHire]: "Google Hire",
  [ClientOnboardingAtsTypeEnum.NotRequired]: "Not Required",
};

export const HELP_CENTER_URL = "https://help.dover.com/en/";
export const MARKETING_HOMEPAGE_URL = "https://dover.com";

export enum LoginErrorCodes {
  BlockedDomain = "BLOCKED_DOMAIN",
  ClientNotFound = "CLIENT_NOT_FOUND",
  NoAccess = "NO_ACCESS",
  MissingEmail = "MISSING_EMAIL",
  UnverifiedEmail = "EMAIL_UNVERIFIED",
  UserIsBlocked = "user is blocked",
  Unknown = "UNKNOWN",
  MissingSession = "You may have pressed the back button, refreshed during login, opened too many login dialogs, or there is some issue with cookies, since we couldn't find your session. Try logging in again from the application and if the problem persists please contact the administrator.",
}

export const recruitingPartnerPictureUrl =
  "https://lh3.googleusercontent.com/a/ACg8ocIBDZCM41n6QLMkO9DFBOzenxWhftU3JNPivv3hsDyPN4-ydDQ=s96-c";
