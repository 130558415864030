import { Box, Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import DraggableSVG from "assets/icons/draggable.svg";
import TrashIconSVG from "assets/icons/trash-gray.svg";
import FeedbackTemplateSelect from "components/dover/FeedbackTemplates/FeedbackTemplateSelect";
import { colors } from "styles/theme";
import AtsInterviewFeedbackField from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/AtsInterviewFeedbackField";
import {
  NameInput,
  OrderRequiredInput,
  DurationInput,
  InterviewersInput,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/SubstageFields";
import { useShouldShowAtsInterviewFeedbackField } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/hooks";

const RemoveSubstageButton = ({ onClick }: { onClick: any }): React.ReactElement => {
  return (
    <Box style={{ cursor: "pointer" }}>
      <ReactSVG src={TrashIconSVG} onClick={onClick} />
    </Box>
  );
};

interface SubstageRowProps {
  value: any;
  onChange: (newSubstage: any) => void;
  onDelete: (idx: number) => void;
  showDelete: boolean;
  fieldPrefix: string;
  showHeaders?: boolean;
  required?: boolean;
}

const SubstageRow = ({
  value: substage,
  onChange,
  onDelete,
  showDelete,
  fieldPrefix,
  showHeaders,
  required,
}: SubstageRowProps): React.ReactElement => {
  const showAtsInterviewFeedbackField = useShouldShowAtsInterviewFeedbackField();

  const handleOrderRequiredChange = (event: any): void => {
    onChange({
      ...substage,
      orderRequired: !!event.target.value,
    });
  };

  const handleDurationChange = (event: any): void => {
    onChange({
      ...substage,
      duration: event.target.value,
    });
  };

  const handleNameChange = (event: any): void => {
    onChange({
      ...substage,
      name: event.target.value,
    });
  };

  const handlePossibleInterviewersChange = (value: string[]): void => {
    onChange({ ...substage, possibleInterviewers: value });
  };

  const getInterviewersRequiredValue = (interviewersRequired: string | undefined): boolean => {
    return interviewersRequired === "ALL" ? true : false;
  };

  const handleInterviewersRequiredChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.checked ? "ALL" : "ANY";
    onChange({ ...substage, interviewersRequired: value });
  };

  const handleAtsInterviewFeedbackChange = (value: string | null, fieldName: string): void => {
    onChange({ ...substage, [fieldName]: value });
  };

  const handleFeedbackTemplateChange = (value?: string): void => {
    onChange({ ...substage, defaultFeedbackTemplate: value });
  };

  // I hate this I'm sorry
  const interviewerInputWidth = showAtsInterviewFeedbackField ? 4.75 : 4;

  return (
    <Stack spacing={0.5} direction="row" justifyContent="center" alignItems="center">
      <Box>
        <ReactSVG src={DraggableSVG} />
      </Box>
      <Grid spacing={1} container direction="row">
        <Grid item xs={1.5}>
          <OrderRequiredInput
            value={substage.orderRequired ? 1 : 0}
            orderIndex={substage.orderIndex!}
            onChange={handleOrderRequiredChange}
          />
        </Grid>
        <Grid item xs={2}>
          <NameInput
            idx={substage.id || substage.orderIndex}
            value={substage.name}
            onChange={handleNameChange}
            required={required}
          />
        </Grid>
        <Grid item xs={2}>
          <DurationInput value={substage.duration} onChange={handleDurationChange} />
        </Grid>
        <Grid item xs={interviewerInputWidth}>
          <Box display="flex" flexDirection="column" width="100%">
            <InterviewersInput
              value={substage.possibleInterviewers}
              handleChange={handlePossibleInterviewersChange}
              required={required}
            />
            {substage.possibleInterviewers.length > 1 && (
              <FormControlLabel
                sx={{
                  "&.MuiFormControlLabel-root": {
                    marginBottom: "-6px",
                  },
                  ".MuiTypography-root": {
                    fontFamily: "Inter",
                    fontSize: "14px",
                  },
                }}
                control={
                  <Checkbox
                    sx={{
                      color: colors.grayscale.gray400,
                      "&.Mui-checked": {
                        color: colors.primary.base,
                      },
                    }}
                    checked={getInterviewersRequiredValue(substage.interviewersRequired)}
                    onChange={handleInterviewersRequiredChange}
                  />
                }
                label="All interviewers are required"
              />
            )}
          </Box>
        </Grid>
        {/* Show Dover FeedbackTemplateSelect if not on Lever or GH */}
        {showAtsInterviewFeedbackField ? (
          <Grid item xs={1.75}>
            <Box height="40px">
              <AtsInterviewFeedbackField
                fieldPrefix={fieldPrefix}
                // TODO: get this value dynamically based on selected ATS
                substageAtsValue={substage.atsFeedbackTemplateId || substage.atsSubstageId}
                onChange={handleAtsInterviewFeedbackChange}
                placeholderText={showHeaders ? "Select" : undefined}
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={2.5}>
            <Box height="40px">
              <FeedbackTemplateSelect
                feedbackTemplateId={substage.defaultFeedbackTemplate}
                setFeedbackTemplateId={handleFeedbackTemplateChange}
                allowEdits
                condensed
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {showDelete && (
        <Box>
          <RemoveSubstageButton onClick={onDelete} />
        </Box>
      )}
    </Stack>
  );
};

export default SubstageRow;
