import { input } from "@data-driven-forms/common/prop-types-templates";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { ReadOnlyField as ReadOnly } from "components/inputs/ReadOnlyField";

const ReadOnlyField = props => {
  const { input, label } = useFieldApi(props);

  return (
    <Grid item xs={12}>
      <ReadOnly label={label} value={input.value} />
    </Grid>
  );
};

ReadOnlyField.propTypes = {
  input,
  label: PropTypes.node,
};

export default ReadOnlyField;
