import { OnboardingMultipartInterviewStageInterviewMeetingTypeEnum } from "services/openapi";
import { InterviewPlanStageType } from "services/openapi/models/InterviewPlanStageType";

const interviewDurationOptions = [
  { value: 15 * 60, label: "15 min" },
  { value: 20 * 60, label: "20 min" },
  { value: 25 * 60, label: "25 min" },
  { value: 30 * 60, label: "30 min" },
  { value: 45 * 60, label: "45 min" },
  { value: 60 * 60, label: "60 min" },
  { value: 75 * 60, label: "75 min" },
  { value: 90 * 60, label: "90 min" },
  { value: 120 * 60, label: "120 min" },
];

interface Option {
  value: number | string;
  label: string;
}
export function getDurationOptions(): Option[] {
  return interviewDurationOptions;
}

const orderIndexToString = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eigth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "Thirteenth",
  "Fourteenth",
];

export function getOrderRequiredOptions(idx: number): Option[] {
  return [
    { value: 0, label: "Any" },
    { value: 1, label: orderIndexToString[idx] },
  ];
}

const interviewMeetingTypeOptions = [
  { value: OnboardingMultipartInterviewStageInterviewMeetingTypeEnum.GoogleMeet, label: "Google Meet" },
  { value: OnboardingMultipartInterviewStageInterviewMeetingTypeEnum.Zoom, label: "Zoom" },
  { value: OnboardingMultipartInterviewStageInterviewMeetingTypeEnum.InPerson, label: "In Person" },
  {
    value: OnboardingMultipartInterviewStageInterviewMeetingTypeEnum.UseInterviewerPreference,
    label: "Default to interviewer's preference",
  },
  { value: OnboardingMultipartInterviewStageInterviewMeetingTypeEnum.Other, label: "Other" },
];

export function getInterviewMeetingTypeOptions(): Option[] {
  return interviewMeetingTypeOptions;
}

export function getInterviewTypeOptions(): Option[] {
  return [
    { value: InterviewPlanStageType.SINGLEPART, label: "Single-Part" },
    { value: InterviewPlanStageType.MULTIPART, label: "Multi-Part" },
    { value: InterviewPlanStageType.TAKE_HOME, label: "Assessment / Other" },
  ];
}
