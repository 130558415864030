import Tooltip from "@mui/material/Tooltip";
import React from "react";

import { colors } from "styles/theme";

export interface ValueLabelProps {
  children: React.ReactElement;
  value: number;
  isOpen: boolean;
  largeText?: boolean;
}

// This is the label that hovers above the Slider to show the current value for that "thumb" (min or max)
const ValueLabel = (props: ValueLabelProps): React.ReactElement => {
  const { children, value, isOpen, largeText } = props;

  return (
    <Tooltip
      PopperProps={{
        popperOptions: {
          modifiers: [{ name: "flip", enabled: false }],
        },
      }}
      componentsProps={{
        popper: {
          sx: {
            zIndex: 1300, // in new search modal, popper yoe labels only show at this zIndex
          },
        },
        tooltip: {
          sx: {
            color: colors.grayscale.gray400,
            backgroundColor: "rgba(0, 0, 0, 0)",
            fontSize: largeText ? "16px" : "11px",
            fontFamily: "Inter",
            marginBottom: "0px !important",
          },
        },
      }}
      enterDelay={1000}
      open={isOpen}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
};

export default ValueLabel;
