/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseCandidateActionResponse
 */
export interface BaseCandidateActionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BaseCandidateActionResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseCandidateActionResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseCandidateActionResponse
     */
    candidateId?: string;
}

export function BaseCandidateActionResponseFromJSON(json: any): BaseCandidateActionResponse {
    return BaseCandidateActionResponseFromJSONTyped(json, false);
}

export function BaseCandidateActionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseCandidateActionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'candidateId': !exists(json, 'candidate_id') ? undefined : json['candidate_id'],
    };
}

export function BaseCandidateActionResponseToJSON(value?: BaseCandidateActionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'message': value.message,
        'candidate_id': value.candidateId,
    };
}


