import {
  JobCandidateSourceSettingExternalJobOriginEnum,
  JobCandidateSourceSetting,
  AtsSource,
  CandidateSource,
  JobCandidateSourceStats,
  CandidateSourceDisplaySectionEnum,
  JobCandidateSourceSettingDesiredStateEnum,
  JobAtsSourceSettingStateEnum,
} from "services/openapi";

/* Generic types */
export interface JobSourceFormValues {
  desiredState: JobCandidateSourceSettingDesiredStateEnum;
  externalJobOrigin?: JobCandidateSourceSettingExternalJobOriginEnum;
  externalJobIdentifier?: string | null;
  atsInboundRejectionEnabled?: "true" | "false" | null;
  deactivationReason?: JobCandidateSourceSettingDeactivationReasonEnum;
  deactivationReasonNotes?: string;
  additionalInfo?: string | null;
  shouldAutoRenew?: boolean | null;
  pricingTierId?: string | null;
  forceSetState?: boolean;
}
export interface SubmitJobCandidateSourceFormArgs extends JobSourceFormValues {
  candidateSourceId: string;
  jobId: string;
}

export interface JobCandidateSourceSettingMap {
  // Map follows the structure:
  // "<source-setting-id>": sourceSetting
  [sourceSettingId: string]: JobCandidateSourceSetting;
}

export interface EnrichedCandidateSourceDetails {
  candidateSource: CandidateSource;
  // Possibly undefined since we lazily create JobCandidateSourceSetting records
  jobCandidateSourceSettings?: JobCandidateSourceSetting;
  // Possibly undefined if we haven't yet computed stats for this source/job combo.
  candidateSourceStats?: JobCandidateSourceStats;
  status: SourceStatus;
  id: string;
}

export type SourceDisplaySectionData = {
  [displaySection in CandidateSourceDisplaySectionEnum]?: EnrichedCandidateSourceDetails[];
};

export enum SourceStatus {
  Active = "Active",
  SettingUp = "SettingUp",
  Deactivating = "Deactivating",
  Inactive = "Inactive",
  NeverActivated = "NeverActivated",
}

export type DedupedAtsSource = Pick<AtsSource, "name" | "atsType" | "defaultState" | "displaySection">;

interface AtsSourceByDisplaySectionMap {
  [displaySection: string]: DedupedAtsSource[];
}

export interface DedupedAtsSourcesInfoByDisplaySection {
  atsSourcesByDisplaySection: AtsSourceByDisplaySectionMap;
  atsSourcesNoDisplaySection: DedupedAtsSource[];
}

export interface FormattedInboundSourceExplorerRow {
  id: string;
  name: string | undefined;
  price: string | undefined;
  avgNumCandidates: number | undefined;
  enrichedSourceDetails: EnrichedCandidateSourceDetails;
}

export enum ChannelName {
  INBOUND = "Inbound",
  OUTBOUND = "Outbound",
  REFERRALS = "Referrals",
}

export enum JobCandidateSourceSettingDeactivationReasonEnum {
  NOT_ENOUGH_APPLICANTS = "Not enough applicants",
  TOO_EXPENSIVE = "Too expensive",
  LOW_QUALITY_OF_APPLICANTS = "Quality of applicants is low",
  ALREADY_MADE_HIRE = "Already made a hire",
  OTHER = "Other",
}

export interface UpsertJobAtsSourceSettingArg {
  jobCandidateSourceSettingId: string;
  atsSource: DedupedAtsSource;
  state: JobAtsSourceSettingStateEnum;
}
