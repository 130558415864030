/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewPlanStageType,
    InterviewPlanStageTypeFromJSON,
    InterviewPlanStageTypeFromJSONTyped,
    InterviewPlanStageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateHiringPipelineStageRequest
 */
export interface CreateHiringPipelineStageRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateHiringPipelineStageRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateHiringPipelineStageRequest
     */
    orderIndex: number;
    /**
     * 
     * @type {InterviewPlanStageType}
     * @memberof CreateHiringPipelineStageRequest
     */
    interviewStageType: InterviewPlanStageType;
}

export function CreateHiringPipelineStageRequestFromJSON(json: any): CreateHiringPipelineStageRequest {
    return CreateHiringPipelineStageRequestFromJSONTyped(json, false);
}

export function CreateHiringPipelineStageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateHiringPipelineStageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'orderIndex': json['order_index'],
        'interviewStageType': InterviewPlanStageTypeFromJSON(json['interview_stage_type']),
    };
}

export function CreateHiringPipelineStageRequestToJSON(value?: CreateHiringPipelineStageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'order_index': value.orderIndex,
        'interview_stage_type': InterviewPlanStageTypeToJSON(value.interviewStageType),
    };
}


