/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InterviewPlanStageType {
    SINGLEPART = 'SINGLEPART',
    MULTIPART = 'MULTIPART',
    TAKE_HOME = 'TAKE_HOME'
}

export function InterviewPlanStageTypeFromJSON(json: any): InterviewPlanStageType {
    return InterviewPlanStageTypeFromJSONTyped(json, false);
}

export function InterviewPlanStageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewPlanStageType {
    return json as InterviewPlanStageType;
}

export function InterviewPlanStageTypeToJSON(value?: InterviewPlanStageType | null): any {
    return value as any;
}

