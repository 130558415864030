import {
  DOVER_LINKEDIN_COMPANY_ID,
  LINKEDIN_COMPANY_URL_PREFIX,
  LINKEDIN_COMPANY_URL_REGEX,
  LINKEDIN_PERSON_URL_REGEX,
} from "components/dover/constants";

export function getLinkedInIdFromUrl(url: string): { id: string; error?: string } {
  // If we have the wrong format, then this shouldn't work
  const companyUrlRegexMatch = url.match(LINKEDIN_COMPANY_URL_REGEX);
  const personUrlRegexMatch = url.match(LINKEDIN_PERSON_URL_REGEX);
  const linkedinId = companyUrlRegexMatch
    ? companyUrlRegexMatch[1]
    : personUrlRegexMatch
    ? personUrlRegexMatch[1]
    : undefined;

  if (!linkedinId) {
    return {
      id: "",
      error: "Please enter a valid LinkedIn company URL beginning with 'https://www.linkedin.com/company/'",
    };
  }

  // Don't allow users to pass in Dover LinkedIn Company ID
  if (companyUrlRegexMatch && linkedinId === DOVER_LINKEDIN_COMPANY_ID) {
    return { id: "", error: "The Dover LinkedIn page isn't allowed. Please enter your company's LinkedIn URL." };
  }

  return { id: linkedinId };
}

export function getLinkedInUrlFromId(id: string | undefined | null): string {
  if (!id) {
    return "";
  }
  return LINKEDIN_COMPANY_URL_PREFIX + id + "/";
}
