import { Divider, Stack } from "@mui/material";
import React from "react";

import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall } from "components/library/typography";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useShouldShowRealProfiles } from "services/doverapi/endpoints/client/hooks";
import { colors } from "styles/theme";
import AdvancedFilters from "views/sourcing/Search/components/AdvancedFilters";
import CompaniesFilters from "views/sourcing/Search/components/CompaniesFilters";
import EducationFilters from "views/sourcing/Search/components/EducationFilters";
import LocationFilters from "views/sourcing/Search/components/LocationFilters";
import PersonasFilters from "views/sourcing/Search/components/PersonasFilters";
import SeniorityFilters from "views/sourcing/Search/components/SeniorityFilters";
import SkillsFilters from "views/sourcing/Search/components/SkillsFilters";
import YoeFilters from "views/sourcing/Search/components/YoeFilters";
import { FiltersContainerID, FILTERS_WIDTH } from "views/sourcing/Search/constants";
import { FilterToggleWrapper, FormLoadStateContext } from "views/sourcing/Search/context/FilterToggleContext";
import { useLoadFormValues, useSetFormDirty } from "views/sourcing/Search/hooks";

const HUBSPOT_CLIENT_ID = "2bc4f9ee-0ec6-4693-86a5-237625d8c963";

const FreeCustomerBanner = (): React.ReactElement => {
  return (
    <Banner variant={BannerVariant.Info} padding="14px">
      <Stack>
        <BodySmall>Dover generated basic criteria from your job description</BodySmall>
      </Stack>
    </Banner>
  );
};

const AllFilters = React.memo(
  (): React.ReactElement => {
    // Load in initial form values

    // (jhh3) Hide diversity filters for HubSpot
    // TODO: make this a property on the client model if we need to hide for
    // other clients
    const { data: client } = useGetUsersClientQuery();
    const hideDiversityFilters = client?.id === HUBSPOT_CLIENT_ID;

    const formLoadState = React.useContext(FormLoadStateContext);
    const setInitialFormValuesLoaded = formLoadState?.setFormValuesLoaded;
    const initialFormValuesLoaded = formLoadState?.loaded;
    const setFormValuesLoading = formLoadState?.setFormValuesLoading;
    const formValuesLoading = formLoadState?.formValuesLoading;

    const shouldShowRealProfiles = useShouldShowRealProfiles();
    const showPreviewBanners = !shouldShowRealProfiles;

    useLoadFormValues(!!initialFormValuesLoaded, !!formValuesLoading, setFormValuesLoading, setInitialFormValuesLoaded);

    const [formDirty, setFormDirty] = React.useState<boolean>(false);
    useSetFormDirty(formDirty, setFormDirty, !!initialFormValuesLoaded, setInitialFormValuesLoaded);

    return (
      <FilterToggleWrapper>
        <Stack
          id={FiltersContainerID}
          minWidth={FILTERS_WIDTH}
          maxWidth={FILTERS_WIDTH}
          divider={<Divider orientation="horizontal" flexItem sx={{ borderColor: colors.grayscale.gray200 }} />}
          maxHeight="100vh"
          overflow="scroll"
          sx={{
            borderBottom: `1px solid ${colors.grayscale.gray200}`,
          }}
        >
          {showPreviewBanners && <FreeCustomerBanner />}
          <PersonasFilters />
          <LocationFilters />
          <YoeFilters />
          <SkillsFilters />
          <SeniorityFilters />
          <CompaniesFilters />
          <EducationFilters />
          <AdvancedFilters hideDiversity={hideDiversityFilters} />
        </Stack>
      </FilterToggleWrapper>
    );
  }
);

export default AllFilters;
