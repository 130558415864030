import { MenuItem, Select } from "@mui/material";
import styled from "styled-components";

const SELECT_PADDING_LEFT = 10;
const SELECT_PADDING_RIGHT = 32;

interface StyledSelectProps {
  compact?: boolean;
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
  height: ${({ compact }): string => (compact ? "30px" : "40px")};
  max-width: ${({ compact }): string => (compact ? "120px" : "none")};
  .MuiSelect-outlined {
    display: flex;
    align-items: center;
    padding: 10px ${SELECT_PADDING_RIGHT}px 10px ${SELECT_PADDING_LEFT}px;
  }
  &.MuiInputBase-root {
    border-radius: 10px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    .noIcon {
      padding-left: 28px;
      line-height: 22px;
    }
  }
  &.active {
    background-color: rgba(25, 118, 210, 0.08);
    &:hover {
      background-color: rgba(25, 118, 210, 0.15);
    }
  }
`;
