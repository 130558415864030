/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientDomain
 */
export interface ClientDomain {
    /**
     * 
     * @type {string}
     * @memberof ClientDomain
     */
    domain: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientDomain
     */
    readonly isPrimaryDomain?: boolean;
}

export function ClientDomainFromJSON(json: any): ClientDomain {
    return ClientDomainFromJSONTyped(json, false);
}

export function ClientDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientDomain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': json['domain'],
        'isPrimaryDomain': !exists(json, 'is_primary_domain') ? undefined : json['is_primary_domain'],
    };
}

export function ClientDomainToJSON(value?: ClientDomain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
    };
}


