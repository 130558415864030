import { convertToHTML } from "draft-convert";
import { CompositeDecorator, ContentBlock, convertFromRaw, EditorState, Entity, RawDraftContentState } from "draft-js";
import { markdownToDraft } from "markdown-draft-js";
import React from "react";

export const EMPTY_P_TAG = "<p></p>";

/**
 * Used in a decorator to render links in draft-js. Goes hand in hand with the DraftJSLink component.
 */
const getLinkEntities = (contentBlock: ContentBlock, callback: (start: number, end: number) => void): void => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && Entity.get(entityKey).getType() === "LINK";
  }, callback);
};

/**
 * Given a draft-js content state, convert it to HTML.
 */
export const customConvertToHTML = convertToHTML({
  entityToHTML: (entity: any, originalText: string): string => {
    if (entity.type === "LINK") {
      return `<a href=${entity.data.url} target="_blank" rel="noopener noreferrer">${originalText}</a>`;
    }
    return originalText;
  },
});

/**
 * Given a draft-js editor state, return the plain text representation of it.
 */
export const getDraftJsText = (editorState: EditorState | null): string => {
  return (
    editorState
      ?.getCurrentContent()
      .getPlainText()
      .trim() ?? ""
  );
};

/**
 * Used in a decorator to render links in draft-js. Goes hand in hand with the getLinkEntities function.
 */
const DraftJSLink = (props: any): JSX.Element => {
  const { url } = Entity.get(props.entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
};

/**
 * Used in a CompositeDecorator to render links in draft-js.
 * E.g. const editorState = EditorState.createWithContent(convertFromRaw(rawObject), new CompositeDecorator(decorators));
 */
export const draftJsLinkDecorators = [{ strategy: getLinkEntities, component: DraftJSLink }];

function getHtmlFromRawDraftContentState(rawDraftContentState: RawDraftContentState): string {
  const editorState = EditorState.createWithContent(
    convertFromRaw(rawDraftContentState),
    new CompositeDecorator(draftJsLinkDecorators)
  );
  const contentState = editorState.getCurrentContent();
  const htmlString = customConvertToHTML(contentState);
  return htmlString;
}
/**
 * Given a string, return the HTML representation of it.
 * If the string is valid HTML already, return it as is.
 * If the string is valid markdown content, convert it to HTML.
 * Otherwise, assume it's draft-js content and convert it to HTML.
 *
 * WARNING: Using this on a string of unknown content type runs the risk of producing mangled html-like content
 */
export function getHtmlFromUnknownContent(content: string | object): string {
  if (typeof content === "object") {
    try {
      // If we can parse the content as EditorState, we know that it's draft-js content
      return getHtmlFromRawDraftContentState(content as RawDraftContentState);
    } catch (e) {
      // The only valid object we can parse is a raw draft-js content state
      // If we can't parse it, we assume it's invalid and return an empty html p tag
      return EMPTY_P_TAG;
    }
  }

  // Otherwise, we have a string representation of our content, and want to try a few types of parsing
  // First, attempt to parse the text as HTML via the DOMParser
  const parser = new DOMParser();
  const dom = parser.parseFromString(content as string, "text/html");
  // If the DOMParser returns any HTML elements (nodeType 1), we reasonably assume the content is valid HTML and return it as is
  if (Array.from(dom.body.childNodes).some(node => node.nodeType === 1)) {
    return content as string;
  }

  try {
    // If we can parse the content as EditorState, we know that it's draft-js content
    return getHtmlFromRawDraftContentState(JSON.parse(content as string) as RawDraftContentState);
  } catch (e) {
    // Otherwise, we assume it's markdown content and convert it to draft-js content
    const rawObject = markdownToDraft(content as string);
    const editorState = EditorState.createWithContent(
      convertFromRaw(rawObject),
      new CompositeDecorator(draftJsLinkDecorators)
    );

    // Finally, convert the editor state to HTML
    // If we had some string content that is not valid HTML, markdown, or draft-js, this will return potentially manggled HTML
    const contentState = editorState.getCurrentContent();
    const htmlString = customConvertToHTML(contentState);
    return htmlString;
  }
}

export function removeExtraPlusSymbols(inputString: string): string {
  return inputString.replace(/\+\+(.*?)\+\+/g, (match, myString) => myString);
}

export function getPlainTextFromHtml(html: string): string {
  const parser = new DOMParser();
  const document = parser.parseFromString(html, "text/html");
  return document.documentElement.textContent ?? "";
}

/**
 * Given a mapping of variables to values, replace any values found in the provided html string with the corresponding variable placeholder
 * If a specififc set of keys are passed, only they will be honored
 */
export function subsituteRelevantContentWithVariables(
  html: string,
  variables: { [key: string]: any },
  keys?: string[]
): string {
  let cleanedHtml = html;
  if (variables) {
    // For each value in the variables object, substitute in the key instead
    Object.keys(variables).forEach(key => {
      if (keys && !keys.includes(key)) {
        return;
      }

      cleanedHtml = cleanedHtml.replace(variables[key], `{{${key}}}`);
    });
  }
  return cleanedHtml;
}

/**
 * Given a mapping of variables to values, replace any of those variables found in the provided html with the correct value
 */
export function subsituteVariablesWithRelevantContent(html: string, variables: { [key: string]: any }): string {
  let cleanedHtml = html;
  if (variables) {
    // For each key in the variables object, substitute in the value instead
    Object.keys(variables).forEach(key => {
      cleanedHtml = cleanedHtml.replace(`{{${key}}}`, variables[key]);
    });
  }
  return cleanedHtml;
}
