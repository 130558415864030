import dayjs, { Dayjs } from "dayjs";
import calendar from "dayjs/plugin/calendar";

import { SlimNextCampaignMessageRequestEmailOutreachStepEnum } from "services/openapi/models/SlimNextCampaignMessageRequest";

dayjs.extend(calendar);

const calendarConfig = {
  sameDay: "[Today] hA",
  nextDay: "[Tomorrow] hA",
};

export const getNextOutreachSendLabel = (
  date: Dayjs,
  outreachStep?: SlimNextCampaignMessageRequestEmailOutreachStepEnum
): string => {
  if (!outreachStep) {
    return "";
  }
  const daysUntilSend = date.startOf("day").diff(undefined, "day");
  const dateText = daysUntilSend > 1 ? ` to send in ${daysUntilSend} days` : `: ${date.calendar(null, calendarConfig)}`;

  return stageToText[outreachStep] + dateText;
};

const stageToText = {
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.InitialOutreach]: "Initial outreach queued",
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.Followup1]: "1st follow up",
  [SlimNextCampaignMessageRequestEmailOutreachStepEnum.Followup2]: "2nd follow up",
};
