import { Stack } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as GreenCheckSVG } from "assets/icons/check-green-filled.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, ButtonText } from "components/library/typography";
import CustomModal from "components/Modal";
import { colors } from "styles/theme";
import { ToggleOnboardingCallModalOpenAtom } from "views/sourcing/Search/atoms";

export const OnboardingCallModal = (): React.ReactElement => {
  // Modal state
  const [modalOpen, toggleModalOpen] = useAtom(ToggleOnboardingCallModalOpenAtom);
  const calendlyUrl = useGetCalendlyUrl("app", "sourcing_autopilot", "upgrade_plan");

  return (
    <CustomModal
      open={modalOpen}
      title={
        <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
          <GreenCheckSVG width="18px" />
          <Body>Search saved!</Body>
        </Stack>
      }
      onClose={(): void => toggleModalOpen(false)}
      maxWidth="xs"
      dialogActions={
        <Stack display="flex" width="470px" justifyContent="center" alignItems="center">
          <Link to={calendlyUrl} target="_blank" rel="noopener noreferrer">
            <Button onClick={(): void => {}} variant={ButtonVariant.Primary}>
              <ButtonText color={colors.white}>Book a call</ButtonText>
            </Button>
          </Link>
        </Stack>
      }
    >
      <Stack spacing={2} p={1} justifyContent="center" alignItems="center" textAlign="center">
        <Body weight="500">One last step: Upgrade your plan</Body>
        <Body>Book a call with our team to explore plans and start contacting top candidates.</Body>
      </Stack>
    </CustomModal>
  );
};
