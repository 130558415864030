import { Circle, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import styled from "styled-components";

import { colors } from "styles/theme";

export const Checked = styled(RadioButtonChecked)`
  fill: ${colors.success.base} !important;
`;

export const Unchecked = styled(RadioButtonUnchecked)`
  fill: ${colors.grayscale.gray300} !important;
`;

export const GrayCircle = styled(Circle)`
  fill: ${colors.grayscale.gray300} !important;
`;

export const GreenCircle = styled(Circle)`
  fill: ${colors.success.base} !important;
`;

export const YellowCircle = styled(Circle)`
  fill: ${colors.warning.base} !important;
`;

export const RedCircle = styled(Circle)`
  fill: ${colors.critical.base} !important;
`;
