import { Drawer, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { format, isToday, differenceInCalendarDays, isThisYear } from "date-fns";
import React from "react";
import styled from "styled-components";

import { ReactComponent as RightArrow } from "assets/icons/chevron-right.svg";
import { ReactComponent as LightningIcon } from "assets/icons/lightning.svg";
import { ReactComponent as CloseIcon } from "assets/icons/withdraw.svg";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Body, Heading, Subtitle1 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetChangeLogEntriesQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import { ChangeLogEntry, ChangeLogIndividualUpdate } from "services/openapi";
import { colors } from "styles/theme";

const MAX_DRAWER_WIDTH_INT = 500;
const MAX_DRAWER_WIDTH = `${MAX_DRAWER_WIDTH_INT}px`;
const MAX_TEXT_WIDTH_COEFFICIENT = 0.675;
const MAX_TEXT_WIDTH = `${MAX_DRAWER_WIDTH_INT * MAX_TEXT_WIDTH_COEFFICIENT}px`;

interface ChangeLogDrawerProps {
  open: boolean;
  onClose: () => void;
  searchId: string;
}

const CloseButton = styled(Button)`
  padding: 5px;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const formatEventTimestamp = (date: Date | null | undefined): string => {
  // function to beautify the date
  if (!date) {
    return "";
  }
  if (isToday(date)) {
    return `Today at ${format(date, "p")}`;
  } else if (differenceInCalendarDays(new Date(), date) <= 6) {
    return date.toLocaleString("default", { weekday: "long" });
  } else if (!isThisYear(date)) {
    return format(date, "MMMM d, y");
  }
  return format(date, "MMMM do");
};

const ChangeLogEntryExpanded = React.memo(
  ({ changeLogEntry }: { changeLogEntry: ChangeLogEntry }): React.ReactElement => {
    // builds a full list of changes (in expanded state) for a ChangeLogEntry
    return (
      <Stack spacing={0.5} padding="10px 0px">
        {changeLogEntry.diff.map((changeLogDiff: ChangeLogIndividualUpdate) => (
          <Stack style={{ border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "5px", padding: "8px" }}>
            <BodySmall color={colors.grayscale.gray700} weight="500">
              {changeLogDiff.family}
            </BodySmall>
            <ul style={{ margin: "0px", padding: "5px 25px" }}>
              {changeLogDiff.changes.map((change: string) => (
                <li>
                  <BodySmall>{change}</BodySmall>
                </li>
              ))}
            </ul>
          </Stack>
        ))}
      </Stack>
    );
  }
);

const EntireChangeLogEntry = React.memo(
  ({ changeLogEntry }: { changeLogEntry: ChangeLogEntry }): React.ReactElement => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    const changeLogEntryClosed = (changeLogEntry: ChangeLogEntry): React.ReactElement => {
      const allFamilies: string[] = changeLogEntry.diff.map(
        (changeLogDiff: ChangeLogIndividualUpdate) => changeLogDiff.family
      );

      return <TextWithMaxWidth label={allFamilies.join(", ")} width={MAX_TEXT_WIDTH} />;
    };

    const toggleExpanded = (): void => {
      setExpanded(!expanded);
    };

    return (
      <Stack
        alignItems="left"
        padding="15px"
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)" }}
        sx={{
          ":hover": {
            backgroundColor: colors.grayscale.gray100,
            cursor: "pointer",
          },
        }}
        onClick={toggleExpanded}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
            <Subtitle1>{`${changeLogEntry.modifiedBy} `}</Subtitle1>
            {!expanded && changeLogEntryClosed(changeLogEntry)}
          </Stack>
          <RightArrow style={{ rotate: `${expanded ? "90deg" : "0deg"}` }} />
        </Stack>
        {/* Inner  */}
        {expanded && <ChangeLogEntryExpanded changeLogEntry={changeLogEntry} />}
        <BodySmall color={colors.grayscale.gray500}>{formatEventTimestamp(changeLogEntry.modifiedAt)}</BodySmall>
      </Stack>
    );
  }
);

const ChangeLogContent = React.memo(
  ({ changeLogEntries }: { changeLogEntries: ChangeLogEntry[] }): React.ReactElement => {
    // when change logs exist, build the entire change log content here (ie builds several `EntireChangeLogEntry`s)
    return (
      <Stack maxWidth={MAX_DRAWER_WIDTH} alignItems="left">
        {changeLogEntries.map((changeLogEntry: ChangeLogEntry) => (
          <EntireChangeLogEntry changeLogEntry={changeLogEntry} />
        ))}
      </Stack>
    );
  }
);

export const ChangeLogDrawer = React.memo(
  ({ open, onClose, searchId }: ChangeLogDrawerProps): React.ReactElement => {
    const { data: changeLogEntries, isLoading: changeLogEntriesLoading } = useGetChangeLogEntriesQuery(
      open ? { searchId: searchId } : skipToken
    );

    const noChangesYet = changeLogEntries?.length === 0;

    const ActualChangeLogContent = React.useMemo(() => {
      if (changeLogEntriesLoading) {
        return <DoverLoadingSpinner />;
      } else if (noChangesYet) {
        return (
          <Stack height="80%" alignItems="center" justifyContent="center" padding="15px" spacing={0.5}>
            <Body color={colors.grayscale.gray600}>No changes have been made to this search.</Body>
            <Body color={colors.grayscale.gray600}>Changes made to the search will appear here.</Body>
          </Stack>
        );
      } else if (changeLogEntries) {
        return <ChangeLogContent changeLogEntries={changeLogEntries} />;
      }
    }, [changeLogEntries, changeLogEntriesLoading, noChangesYet]);

    return (
      <Drawer open={open} onClose={onClose} anchor="right" className="stageDrawer" sx={{ width: MAX_DRAWER_WIDTH }}>
        <Stack width={MAX_DRAWER_WIDTH} height="100%">
          <Stack
            style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}
            padding="10px 15px"
            justifyContent="space-between"
            direction="row"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <LightningIcon className="svg-color" height="32px" color={colors.grayscale.gray700} />
              <Heading color={colors.grayscale.gray700}>Change Log</Heading>
            </Stack>
            <CloseButton variant={ButtonVariant.Ghost} onClick={onClose} removeOutline>
              <CloseIcon className="svg-color" height="12px" color={colors.grayscale.gray700} />
            </CloseButton>
          </Stack>
          {ActualChangeLogContent}
        </Stack>
      </Drawer>
    );
  }
);
