//----------------------------------------
// Steps

export interface ExtensionJobCreationConstants {
  nextStep?: ExtensionJobCreationStep;
  path: string;
  component: React.ComponentType<{}>;
  index: number;
}

export enum ExtensionJobCreationStep {
  BasicsStep = "BASICS_STEP", // AKA "Job Creation Step" / "PERSONA / Title Selection"
  CompanyPitchStep = "COMPANY_PITCH_STEP",
  OutreachStep = "OUTREACH_STEP",
  CompletedStep = "COMPLETED_STEP", // AKA "Review and Finish"
}

export interface BasicsCreateJobFormData {
  persona?: number | string;
  title?: string;
  isPrivate: boolean;
}

//----------------------------------------
// Context

export type ExtensionCreateJobState = {
  step: string;
  jobId?: string;
  onNext: () => void;
  // Steps
  // 1. Basics
  creatingJob: boolean;
  createJobError?: string;
  onSubmitCreateJob: (formData: BasicsCreateJobFormData) => Promise<void>;
  // TODO: state for other steps
};
