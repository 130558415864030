/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EsSearchCountsRequest
 */
export interface EsSearchCountsRequest {
    /**
     * 
     * @type {object}
     * @memberof EsSearchCountsRequest
     */
    sbuiSearchParams: object;
}

export function EsSearchCountsRequestFromJSON(json: any): EsSearchCountsRequest {
    return EsSearchCountsRequestFromJSONTyped(json, false);
}

export function EsSearchCountsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EsSearchCountsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sbuiSearchParams': json['sbui_search_params'],
    };
}

export function EsSearchCountsRequestToJSON(value?: EsSearchCountsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sbui_search_params': value.sbuiSearchParams,
    };
}


