import { Container, Stack, Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";
import { Navigate, Outlet, useMatch, useParams } from "react-router-dom";

import { useMatchPath } from "App/routing/hooks";
import { JOB_PATH } from "App/routing/route-path-constants";
import { Centered } from "components/Centered";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";
import { JobViewHeader } from "views/job/components/JobViewHeader";
import { JobViewLoadingSkeleton } from "views/job/components/JobViewLoadingSkeleton";
import RestrictedAccess from "views/RestrictedAccess";

/**
 * Top-level view for the route /job/:jobId
 */
const JobView = (): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string | undefined }>();
  const { data: jobSetup, isFetching: jobSetupIsFetching, isUninitialized, error } = useGetJobSetupQuery(
    jobId ?? skipToken
  );

  const { isFetching: isJobHasScoringLoading } = useJobHasScoringEnabled();

  const shouldNavigateToOverview = !!useMatch(JOB_PATH);

  // Some pages are a special case need to be handled differently for layout
  // This logic should probably be shifted to some sort of static config
  const shouldRenderChildrenWithoutContainer = useMatchPath([
    `${JOB_PATH}/application-review`,
    `${JOB_PATH}/application-review-v2`,
    `${JOB_PATH}/job-posting`,
    `${JOB_PATH}/candidates`,
    `${JOB_PATH}/candidates/:candidateId`,
    `${JOB_PATH}/reporting`,
    `${JOB_PATH}/dover-talent-network`,
  ]);

  const isAppReviewV2 = useMatchPath([`${JOB_PATH}/application-review-v2`, `${JOB_PATH}/dover-talent-network`]);

  const childContent = useMemo(() => {
    // The new app review likes having a minHeight to resolve scrolling issues
    if (isAppReviewV2) {
      return (
        <Box sx={{ minHeight: 0, height: "100%" }}>
          <Outlet />
        </Box>
      );
    }

    if (shouldRenderChildrenWithoutContainer) {
      return <Outlet />;
    }

    return (
      <Container
        maxWidth="xl"
        className="top-level-page-container"
        sx={{ minHeight: 0, paddingBottom: "20px", flexGrow: 1 }}
      >
        <Outlet />
      </Container>
    );
  }, [isAppReviewV2, shouldRenderChildrenWithoutContainer]);

  // Show loading state if job setup data is loading
  if (jobSetupIsFetching || isJobHasScoringLoading) {
    return <JobViewLoadingSkeleton />;
  }

  // @ts-ignore
  if (error?.serializedError?.status === 404) {
    return (
      <Stack width="100%" height="100%">
        <Centered>
          <RestrictedAccess />
        </Centered>
      </Stack>
    );
  }

  // If query has been initialized but we're not loading, and we don't have job setup data, redirect to 404
  if ((!isUninitialized && !jobSetup) || !jobSetup?.title) {
    return <Navigate replace to="/NotFound" />;
  }

  if (shouldNavigateToOverview && jobId) {
    return <Navigate replace to={`/job/${jobId}/overview`} />;
  }

  /* We need a unique key prop to make React remount this component when navigating
  between different jobs. Otherwise the state initialization doesn't work properly. */
  return (
    <Stack width="100%" height="100%" key={jobId}>
      <JobViewHeader />
      {childContent}
    </Stack>
  );
};

export default JobView;
