import { setMilliseconds, setMinutes, setSeconds, subDays } from "date-fns";
import { get } from "lodash";

import { JobInterviewStageStats } from "services/openapi";
import { DateOption } from "views/Reporting/constants";
import { DateRange } from "views/Reporting/types";

export function getFilterDates(dateOption: DateOption): DateRange {
  const dateRange: DateRange = {};

  // current date to the hour
  let current = new Date();
  current = setMinutes(current, 0);
  current = setSeconds(current, 0);
  current = setMilliseconds(current, 0);

  // current date as utc formatted string
  const end = current.toISOString();

  switch (dateOption) {
    case DateOption.Last7Days:
      current = subDays(current, 7);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    case DateOption.Last14Days:
      current = subDays(current, 14);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    case DateOption.Last2To4Weeks:
      dateRange.start = subDays(current, 30).toISOString();
      dateRange.end = subDays(current, 14).toISOString();
      break;
    case DateOption.Last30Days:
      current = subDays(current, 30);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    case DateOption.Last60Days:
      current = subDays(current, 60);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    case DateOption.Last90Days:
      current = subDays(current, 90);
      dateRange.start = current.toISOString();
      dateRange.end = end;
      break;
    default:
      // DateOption.AllTime, do not set a start or end
      break;
  }

  return dateRange;
}

function getPassThroughRate(currentStat: number, prevStat: number): string {
  const passThroughRate: number = Math.round((currentStat / prevStat) * 100);
  return `(${passThroughRate}% from previous stage)`;
}

export function renderPassThroughRate(
  interviewStats: JobInterviewStageStats[],
  currentStageStats: JobInterviewStageStats
): string {
  // keys to look for in interviewStats
  const statTotal = "total";
  const statActive = "active";

  const currentStageIndex = currentStageStats ? interviewStats.indexOf(currentStageStats) : undefined;
  let prevStageStats: JobInterviewStageStats | undefined = undefined;
  if (currentStageIndex) {
    // we don't accept 0 either cuz no previous stage before 0'th element
    prevStageStats = interviewStats[currentStageIndex - 1];
  }

  if (currentStageStats && prevStageStats) {
    // get the total number of candidates for the current and previous stage
    const statValue = get(currentStageStats, statTotal);
    const totalPrevStatValue = get(prevStageStats, statTotal);

    if (statValue && totalPrevStatValue) {
      // get the number of active candidates for the previous stage
      const activePrevStatValue = get(prevStageStats, statActive, 0) || 0;
      // get the number of candidates that have already gone through the prev stage
      const prevStatValue = totalPrevStatValue - activePrevStatValue;

      return getPassThroughRate(statValue, prevStatValue);
    }
  }

  return "";
}
