import { Box, Divider, Stack } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import TimerSVG from "assets/icons/timer.svg";
import LinkedinExtensionImg from "assets/images/linkedin_extension.png";
import { ClientPickerWarningBanner } from "components/dover/ClientPickerWarningBanner";
import { GmailAuthContent } from "components/dover/GmailAuth";
import { useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import { LoadingRelative } from "components/HotLoading";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Subtitle1, TitleLarge } from "components/library/typography";
import { colors } from "styles/theme";
import {
  GOOGLE_CHROME_EXTENSION_URL,
  START_SOURCING_LINKEDIN_URL,
} from "views/create-job/ExtensionJobCreationFlow/constants";
import { useExtensionCreateJobContext } from "views/create-job/ExtensionJobCreationFlow/context";
import { WhiteCard } from "views/create-job/ExtensionJobCreationFlow/styles";

const JobCreationComplete = (): React.ReactElement => {
  const { onNext } = useExtensionCreateJobContext();

  const onClickStartSourcing = React.useCallback(() => {
    window.open(START_SOURCING_LINKEDIN_URL, "_blank", "noopener noreferrer");
    onNext();
  }, [onNext]);

  const onClickDownloadExtension = React.useCallback(() => {
    window.open(GOOGLE_CHROME_EXTENSION_URL, "_blank", "noopener noreferrer");
    onNext();
  }, [onNext]);

  return (
    <WhiteCard>
      <Stack divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
        <Stack display="flex" alignItems="center" sx={{ width: "480px" }} spacing={2}>
          <Stack display="flex" alignItems="center" spacing={2}>
            <Box>
              <ReactSVG src={TimerSVG} />
            </Box>
            <Box>
              <TitleLarge color={colors.primary.base}>Nice work!</TitleLarge>
            </Box>
            <TitleLarge>You can now start using Sourcing Copilot</TitleLarge>
          </Stack>
          <Stack justifyContent="center" alignItems="flex-start" display="flex" direction="column" spacing={2}>
            <Body>{"Sourcing Copilot is accessible whenever you’re on LinkedIn"}</Body>
            <Box width="100%" display="flex" justifyContent="center">
              <img src={LinkedinExtensionImg} height="150px" />
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
          <Button variant={ButtonVariant.Secondary} onClick={onClickStartSourcing}>
            Start sourcing on LinkedIn
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={onClickDownloadExtension}>
            Install extension
          </Button>
        </Stack>
      </Stack>
    </WhiteCard>
  );
};

const JobCreationCompleteWrapper = (): React.ReactElement => {
  // Make sure the user auths their email before we show them success
  const { userAuthedSuccessfully, isGmailAuthLoading } = useUserAuthedSuccessfully();

  if (isGmailAuthLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "440px" }}>
        <LoadingRelative />
      </Box>
    );
  }

  if (!userAuthedSuccessfully) {
    return (
      <WhiteCard>
        <Stack spacing={2} sx={{ width: "464px" }}>
          <Subtitle1>Last step!</Subtitle1>
          <Body>Connect your email to allow us to send outreach to candidates for you.</Body>
          <ClientPickerWarningBanner
            warningText={`The client picker is currently set to a different client. You can still auth your own email, but not for anyone else.`}
          />
          <GmailAuthContent />
        </Stack>
      </WhiteCard>
    );
  }

  return <JobCreationComplete />;
};

export default JobCreationCompleteWrapper;
