import { Stack } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import GreenCheckIconSVG from "assets/icons/check-green.svg";
import RedXIconSVG from "assets/icons/red-x.svg";
import { Caption, Overline } from "components/library/typography";
import { colors } from "styles/theme";

interface FilterDisplayBigProps {
  filterName: string;
  value?: string[];
  passing: boolean;
  width: string;
}
interface displayAllExtraDetailsProps {
  value?: string[];
  passing: boolean;
}

const displayAllExtraDetails = ({ value, passing }: displayAllExtraDetailsProps): React.ReactElement => {
  let validatedValue = value;
  if (validatedValue === undefined || !validatedValue.length) {
    validatedValue = passing ? ["Passing"] : ["Failing"];
  }

  return (
    <Stack spacing={0.3}>
      {validatedValue.map(detail => {
        return (
          <Stack key={detail} direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
            <Caption weight="300" color={colors.black}>
              {detail}
            </Caption>
            <ReactSVG style={{ display: "flex" }} src={passing ? GreenCheckIconSVG : RedXIconSVG} />
          </Stack>
        );
      })}
    </Stack>
  );
};

const FilterDisplayBig = ({ filterName, value, passing, width }: FilterDisplayBigProps): React.ReactElement => {
  return (
    <Stack
      spacing={0.5}
      padding="10px"
      width={width}
      minHeight="50px"
      style={{ borderRadius: "5px", border: "1px solid rgba(0, 0, 0, 0.1)" }}
      sx={{ backgroundColor: colors.white }}
    >
      <Stack direction="row" justifyContent={"space-between"}>
        <Overline color={colors.black}>{filterName}</Overline>
      </Stack>
      {displayAllExtraDetails({ value, passing })}
    </Stack>
  );
};

export default FilterDisplayBig;
