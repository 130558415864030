import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { FormData } from "services/doverapi/endpoints/create-job/types";
import { CAREERS_PAGE_JOB, JOB, LIST_TAG, PERSONAS_V2 } from "services/doverapi/endpointTagsConstants";
import {
  CreateNewJobRequestCreateJobContextEnum,
  CreateNewJobResponse,
  JobSetupPersona,
  JobSetupTitlePattern,
  SlimPersona,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

const createJobEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listJobTitles: build.query<JobSetupTitlePattern[], void>({
      queryFn: async _ => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listOnboardingTitlePatterns();
          return { data: response };
        } catch (error) {
          const userFacingMessage = "Failed to load titles. Please refresh and try again.";
          showErrorToast(userFacingMessage);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getPersonaViaSoftMatch: build.query<SlimPersona, string>({
      queryFn: async (jobTitle: string) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.getPersonaViaSoftMatch({
            data: {
              jobTitle,
            },
          });
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listJobPersonas: build.query<JobSetupPersona[], void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listJobPersonas({ limit: 1000 });
          return { data: response.results };
        } catch (error) {
          const userFacingMessage = "Failed to load personas. Please refresh and try again.";
          showErrorToast(userFacingMessage);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    createJob: build.mutation<
      CreateNewJobResponse,
      { values: FormData; jobPositionId?: string; createJobContext?: CreateNewJobRequestCreateJobContextEnum }
    >({
      queryFn: async ({ values, jobPositionId, createJobContext }) => {
        try {
          const other = values.persona === "other";
          const noHiringManager = values.hiringManagerId === -1;
          const { apiApi } = await getOpenApiClients({});
          const createResponse = await apiApi.createNewJob({
            data: {
              // @ts-ignore
              personaId: other ? 0 : values.persona,
              hiringManagerId: noHiringManager ? undefined : values.hiringManagerId,
              recruiterId: values.recruiterId,
              hiringTeamMemberIds: values.hiringTeamMemberIds ?? [],
              jobPositionId: jobPositionId,
              title: values.title,
              isPrivate: values.isPrivate,
              createJobContext: createJobContext,
              goalHireDateOffset: values.hiringTimelineDays,
            },
          });
          return { data: createResponse };
        } catch (error) {
          const userFacingMessage = "Failed to create job. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result?.success && result?.jobId
          ? [
              { type: JOB, id: LIST_TAG },
              { type: CAREERS_PAGE_JOB, id: result.clientId },
              { type: PERSONAS_V2, id: LIST_TAG },
            ]
          : [];
      },
    }),
  }),
});

export const {
  useListJobPersonasQuery,
  useListJobTitlesQuery,
  useCreateJobMutation,
  useGetPersonaViaSoftMatchQuery,
} = createJobEndpoints;
