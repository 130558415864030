/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AutoformatInterviewRubricRequest
 */
export interface AutoformatInterviewRubricRequest {
    /**
     * 
     * @type {string}
     * @memberof AutoformatInterviewRubricRequest
     */
    roughNotes: string;
}

export function AutoformatInterviewRubricRequestFromJSON(json: any): AutoformatInterviewRubricRequest {
    return AutoformatInterviewRubricRequestFromJSONTyped(json, false);
}

export function AutoformatInterviewRubricRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoformatInterviewRubricRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roughNotes': json['rough_notes'],
    };
}

export function AutoformatInterviewRubricRequestToJSON(value?: AutoformatInterviewRubricRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rough_notes': value.roughNotes,
    };
}


