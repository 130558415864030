import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { Overline } from "components/library/typography";
import { SearchV3Explanation, SearchV3ExplainedFilter } from "services/openapi/models/";
import FilterDisplayBig from "views/sourcing/Search/components/CandidateCard/FilterDisplayBig";

interface CandidateFiltersExpandedProps {
  expanded: boolean;
  niceToHaveExplanation: SearchV3Explanation;
  mustHaveExplanation: SearchV3Explanation;
}
interface FiltersDisplayBigProps {
  niceToHaveList: SearchV3ExplainedFilter[];
  passing: boolean;
  width: string;
}

const CandidateFiltersExpanded = React.memo(
  ({ expanded, niceToHaveExplanation, mustHaveExplanation }: CandidateFiltersExpandedProps): React.ReactElement => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down(1100));
    const mediumScreen = useMediaQuery(theme.breakpoints.down(1350));

    const maxItemsPerRow = smallScreen ? 2 : mediumScreen ? 3 : 4;

    const passingNiceToHaves = niceToHaveExplanation.passingFilters;
    const failingNiceToHaves = niceToHaveExplanation.failingFilters;
    const passingMustHaves = mustHaveExplanation.passingFilters;
    const failingMustHaves = mustHaveExplanation.failingFilters;

    const allFiltersToDisplay = React.useMemo(() => {
      const initialNumNiceToHaveFilters = passingNiceToHaves.length + failingNiceToHaves.length;
      const initialNumMustHaveFilters = passingMustHaves.length + failingMustHaves.length;

      let niceToHaveWidth = "100%";
      let mustHaveWidth = "100%";

      // depending on screen width, we want to display 2, 3, or 4 filters per row, so must set width accordingly
      if (initialNumNiceToHaveFilters >= maxItemsPerRow) {
        niceToHaveWidth = smallScreen ? "50%" : mediumScreen ? "33%" : "25%";
      }
      if (initialNumMustHaveFilters >= maxItemsPerRow) {
        mustHaveWidth = smallScreen ? "50%" : mediumScreen ? "33%" : "25%";
      }

      // first get all nice to haves in one big element list
      const passingNiceToHavesDisplayBig = CreateFiltersDisplayBigList({
        niceToHaveList: passingNiceToHaves,
        passing: true,
        width: niceToHaveWidth,
      });
      const failingNiceToHavesDisplayBig = CreateFiltersDisplayBigList({
        niceToHaveList: failingNiceToHaves,
        passing: false,
        width: niceToHaveWidth,
      });

      // and concatenate them
      const allNiceToHavesConcatenated = (passingNiceToHavesDisplayBig || []).concat(
        failingNiceToHavesDisplayBig || []
      );

      // then get all must haves in one big element list (candidates must pass all must haves, so we can only consider passing must haves)
      const passingMustHavesDisplayBig = CreateFiltersDisplayBigList({
        niceToHaveList: passingMustHaves,
        passing: true,
        width: mustHaveWidth,
      });
      const failingMustHavesDisplayBig = CreateFiltersDisplayBigList({
        niceToHaveList: failingMustHaves,
        passing: false,
        width: mustHaveWidth,
      });
      const allMustHavesConcatenated = (passingMustHavesDisplayBig || []).concat(failingMustHavesDisplayBig || []);

      // then we want to split them up by row, so as to not show more than maxItemsPerRow
      const allNiceToHaves = [];
      const allMustHaves = [];

      for (let i = 0; i < allMustHavesConcatenated.length; i += maxItemsPerRow) {
        allMustHaves.push(
          <Stack key={i} direction="row" spacing={1} width="100%" justifyContent="flex-start">
            {allMustHavesConcatenated?.slice(i, i + maxItemsPerRow)}
          </Stack>
        );
      }
      for (let i = 0; i < initialNumNiceToHaveFilters; i += maxItemsPerRow) {
        allNiceToHaves.push(
          <Stack key={i} direction="row" spacing={1} width="100%" justifyContent="flex-start">
            {allNiceToHavesConcatenated?.slice(i, i + maxItemsPerRow)}
          </Stack>
        );
      }
      return (
        <Stack direction="column" spacing={1.5} width="100%">
          {!!allMustHaves.length && (
            <Stack spacing={1}>
              <Overline>Must Haves</Overline>
              {allMustHaves}
            </Stack>
          )}
          {!!allNiceToHaves.length && (
            <Stack spacing={1}>
              <Overline>Nice To Haves</Overline>
              {allNiceToHaves}
            </Stack>
          )}
        </Stack>
      );
    }, [
      passingNiceToHaves,
      failingNiceToHaves,
      passingMustHaves,
      failingMustHaves,
      maxItemsPerRow,
      smallScreen,
      mediumScreen,
    ]);

    if (!expanded) {
      return <></>;
    }

    return <>{allFiltersToDisplay}</>;
  }
);

const CreateFiltersDisplayBigList = ({ niceToHaveList, passing, width }: FiltersDisplayBigProps): React.ReactNode[] => {
  // takes in a list of filters and returns an array of React elements
  if (!niceToHaveList) {
    return [];
  }
  return niceToHaveList!.map(explainedFilter => (
    <FilterDisplayBig
      filterName={explainedFilter.filterName}
      value={explainedFilter.extraDetails.verbose}
      passing={passing}
      width={width}
      key={explainedFilter.filterName}
    />
  ));
};

export default CandidateFiltersExpanded;
