import { Button } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import { useSetAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { CALIBRATION_SETTINGS_TAB_QUERY_PARAM_VALUE } from "components/dover/dover-outbound-modal/constants";
import { BodySmall } from "components/library/typography";
import { editConfigModalOpenAtom } from "views/job/JobSetup/atoms";
interface SettingsCellProps {
  jobId: string;
}

export const SettingsCell = ({ jobId }: SettingsCellProps): React.ReactElement => {
  const navigate = useNavigate();
  const setEditConfigModalOpen = useSetAtom(editConfigModalOpenAtom);

  return (
    <Button
      p={1}
      variant="primaryOutlined"
      onPress={(): void => {
        navigate(
          APP_ROUTE_PATHS.job.overview(jobId, new URLSearchParams({ tab: CALIBRATION_SETTINGS_TAB_QUERY_PARAM_VALUE }))
        );
        setEditConfigModalOpen(true);
      }}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <SettingsIcon />
        <BodySmall>Settings</BodySmall>
      </Stack>
    </Button>
  );
};
