import { Box } from "@mui/material";
import React from "react";

import { colors } from "styles/theme";

const Toggle = ({
  label,
  checked,
  disabled,
  onChange: onChange,
}: {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: any) => void;
}): React.ReactElement => {
  return (
    <>
      <input
        type="checkbox"
        name={label}
        onChange={(e): void => {
          if (disabled) {
            return;
          }
          onChange?.(e);
        }}
        checked={!!checked}
        id={label}
        style={{
          display: "none",
        }}
      />
      <label htmlFor={label} style={{ margin: 0 }}>
        <Box
          position="relative"
          width="32px"
          height="20px"
          padding="4px"
          sx={{
            backgroundColor: checked ? colors.primary.base : colors.grayscale.gray300,
            borderRadius: "16px",
            cursor: disabled ? "not-allowed" : "pointer",
            opacity: disabled ? 0.5 : 1,
          }}
        >
          <Box
            sx={{
              height: "12px",
              width: "12px",
              backgroundColor: colors.white,
              borderRadius: "50%",
              position: "absolute",
              left: checked ? "16px" : "4px",
              transition: "left 0.1s ease-in-out",
            }}
          />
        </Box>
      </label>
    </>
  );
};

export default Toggle;
