/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeAppScoringEnabledResponse
 */
export interface ChangeAppScoringEnabledResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeAppScoringEnabledResponse
     */
    success: boolean;
}

export function ChangeAppScoringEnabledResponseFromJSON(json: any): ChangeAppScoringEnabledResponse {
    return ChangeAppScoringEnabledResponseFromJSONTyped(json, false);
}

export function ChangeAppScoringEnabledResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeAppScoringEnabledResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function ChangeAppScoringEnabledResponseToJSON(value?: ChangeAppScoringEnabledResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
    };
}


