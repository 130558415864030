import React, { FC } from "react";
import { Navigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { useJobId } from "hooks/useJobIdFromUrl";

export const ApplicationReviewRedirect: FC = () => {
  const [jobId] = useJobId();

  if (!jobId) {
    return <Navigate to={APP_ROUTE_PATHS.home()} />;
  }

  return <Navigate to={APP_ROUTE_PATHS.job.applicationReviewV2(jobId)} />;
};
