import { PipelineCandidate } from "services/openapi";

const LINKEDIN_PROFILE_URL_REGEX = /^(?:http(?:s)?:\/\/)(?:[\w-]+\.)?linkedin\.com\/in\/([^\s/?]+)(?:\S*)$/g;
export function isLinkedInURL(url: string): boolean {
  return url.match(LINKEDIN_PROFILE_URL_REGEX) !== null;
}

export const getLinkedinUrl = (candidate: PipelineCandidate): string | undefined => {
  const socialLinks = candidate.contact?.socialLinks;
  if (!socialLinks) {
    return undefined;
  }

  return socialLinks.find(socialLink => socialLink.linkType === "LINKEDIN")?.url;
};
