/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    candidateEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject9
     */
    companyEmail: string;
}

export function InlineObject9FromJSON(json: any): InlineObject9 {
    return InlineObject9FromJSONTyped(json, false);
}

export function InlineObject9FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject9 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateEmail': json['candidate_email'],
        'companyEmail': json['company_email'],
    };
}

export function InlineObject9ToJSON(value?: InlineObject9 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_email': value.candidateEmail,
        'company_email': value.companyEmail,
    };
}


