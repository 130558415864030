import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

type RHFTextFieldProps = TextFieldProps & {
  name: string;
};

const RHFTextField = ({ name, helperText, ...other }: RHFTextFieldProps): React.ReactElement => {
  const { control } = useFormContext();

  return (
    <Controller
      // @ts-ignore
      name={name}
      control={control}
      render={({ field, fieldState: { error } }): React.ReactElement => (
        <TextField
          {...field}
          fullWidth
          error={Boolean(error)}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
};

export default RHFTextField;
