import React from "react";

import { MaxOptionsText } from "components/inputs/AsyncMultiSelect/styles";
import { StyledFormLabel } from "components/inputs/common";

interface Props {
  maxOptions?: number;
  label: string;
  required?: boolean;
}

const Label = ({ maxOptions, label, required }: Props): React.ReactElement => {
  if (maxOptions) {
    return (
      <StyledFormLabel>
        {label}
        {required ? " *" : null} <MaxOptionsText>(Select up to {maxOptions})</MaxOptionsText>
      </StyledFormLabel>
    );
  }

  return (
    <StyledFormLabel>
      {label}
      {required ? " *" : null}
    </StyledFormLabel>
  );
};

export default Label;
