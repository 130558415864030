/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FromUserInfo
 */
export interface FromUserInfo {
    /**
     * 
     * @type {string}
     * @memberof FromUserInfo
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof FromUserInfo
     */
    readonly email?: string;
}

export function FromUserInfoFromJSON(json: any): FromUserInfo {
    return FromUserInfoFromJSONTyped(json, false);
}

export function FromUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FromUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function FromUserInfoToJSON(value?: FromUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


