import { Box } from "@mui/material";
import React from "react";

import { ReactComponent as ChevronLeftIcon } from "assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";
import { colors } from "styles/theme";

interface ScrollArrowProps {
  direction: "left" | "right";
  visible: boolean;
  onClick: () => void;
}

export const ScrollArrow = ({ direction, visible, onClick }: ScrollArrowProps): React.ReactElement => {
  return (
    <Box
      position="absolute"
      right={direction === "right" ? "5px" : undefined}
      left={direction === "left" ? "5px" : undefined}
      top="55px"
      borderRadius="50%"
      height="32px"
      width="32px"
      zIndex={10}
      display={visible ? "flex" : "none"}
      sx={{
        backgroundColor: colors.white,
        border: `1px solid ${colors.grayscale.gray200}`,

        ":hover": {
          backgroundColor: colors.grayscale.gray100,
        },
      }}
    >
      {direction === "left" ? (
        <ChevronLeftIcon
          className="svg-pointer svg-color"
          color={colors.grayscale.gray700}
          width="32px"
          height="32px"
          onClick={onClick}
        />
      ) : (
        <ChevronRightIcon
          className="svg-pointer svg-color"
          color={colors.grayscale.gray700}
          width="32px"
          height="32px"
          onClick={onClick}
        />
      )}
    </Box>
  );
};
