/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HiringPipelineStageMilestone,
    HiringPipelineStageMilestoneFromJSON,
    HiringPipelineStageMilestoneFromJSONTyped,
    HiringPipelineStageMilestoneToJSON,
    HiringPipelineStageType,
    HiringPipelineStageTypeFromJSON,
    HiringPipelineStageTypeFromJSONTyped,
    HiringPipelineStageTypeToJSON,
    SubstageType,
    SubstageTypeFromJSON,
    SubstageTypeFromJSONTyped,
    SubstageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface NextActionHiringPipelineStage
 */
export interface NextActionHiringPipelineStage {
    /**
     * 
     * @type {string}
     * @memberof NextActionHiringPipelineStage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NextActionHiringPipelineStage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NextActionHiringPipelineStage
     */
    jobId: string;
    /**
     * 
     * @type {HiringPipelineStageType}
     * @memberof NextActionHiringPipelineStage
     */
    stageType: HiringPipelineStageType;
    /**
     * A relative ordering on active HiringPipelineStages.
     * @type {number}
     * @memberof NextActionHiringPipelineStage
     */
    orderIndex?: number | null;
    /**
     * 
     * @type {HiringPipelineStageMilestone}
     * @memberof NextActionHiringPipelineStage
     */
    milestone: HiringPipelineStageMilestone | null;
    /**
     * 
     * @type {SubstageType}
     * @memberof NextActionHiringPipelineStage
     */
    contentTypeName?: SubstageType;
    /**
     * 
     * @type {string}
     * @memberof NextActionHiringPipelineStage
     */
    readonly schedulingEmailTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof NextActionHiringPipelineStage
     */
    readonly rejectionEmailTemplateId?: string;
}

export function NextActionHiringPipelineStageFromJSON(json: any): NextActionHiringPipelineStage {
    return NextActionHiringPipelineStageFromJSONTyped(json, false);
}

export function NextActionHiringPipelineStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextActionHiringPipelineStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'jobId': json['job_id'],
        'stageType': HiringPipelineStageTypeFromJSON(json['stage_type']),
        'orderIndex': !exists(json, 'order_index') ? undefined : json['order_index'],
        'milestone': HiringPipelineStageMilestoneFromJSON(json['milestone']),
        'contentTypeName': !exists(json, 'content_type_name') ? undefined : SubstageTypeFromJSON(json['content_type_name']),
        'schedulingEmailTemplateId': !exists(json, 'scheduling_email_template_id') ? undefined : json['scheduling_email_template_id'],
        'rejectionEmailTemplateId': !exists(json, 'rejection_email_template_id') ? undefined : json['rejection_email_template_id'],
    };
}

export function NextActionHiringPipelineStageToJSON(value?: NextActionHiringPipelineStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'job_id': value.jobId,
        'stage_type': HiringPipelineStageTypeToJSON(value.stageType),
        'order_index': value.orderIndex,
        'milestone': HiringPipelineStageMilestoneToJSON(value.milestone),
        'content_type_name': SubstageTypeToJSON(value.contentTypeName),
    };
}


