/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, Stack, Grid } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FieldInputProps } from "react-final-form";

import { BodySmall } from "components/library/typography";
import { AddInterviewersToHiringTeam } from "components/SetupHiringPipeline/components/Drawer/fields/EditAddInterviewersToHiringTeam";
import { MultipartInterviewSubstageForm } from "components/SetupHiringPipeline/components/InterviewPlan/constants";
import { SubstageHeader, SubstageHeaderContainer } from "components/SetupHiringPipeline/components/Row/styles";
import { useShouldShowAtsInterviewFeedbackField } from "components/SetupHiringPipeline/hooks";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";
import SubstageRow from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/SubstageRow";

const getMaxOrderIndex = (substages: Array<MultipartInterviewSubstageForm>): number => {
  if (substages.length === 0) {
    return -1;
  }
  return Math.max(...substages.map(substage => (substage.orderIndex ? substage.orderIndex : 0)), 0);
};

const getNextOrderIndex = (substages: Array<MultipartInterviewSubstageForm>): number => {
  return getMaxOrderIndex(substages) + 1;
};

interface DraggableSubstage extends MultipartInterviewSubstageForm {
  draggableId: string;
}

const getDraggableId = (idx?: number): string => {
  const dateString = new Date().getTime().toString();
  return idx ? `${idx}-${dateString}` : dateString;
};

const getDraggableSubstages = (substages: Array<MultipartInterviewSubstageForm>): DraggableSubstage[] => {
  return substages.map((substage: MultipartInterviewSubstageForm, i: number) => ({
    ...substage,
    draggableId: substage.id || getDraggableId(i),
  }));
};

const CustomTooltipIcon = React.forwardRef(function CustomTooltipIcon(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    // @ts-ignore
    <div {...props} ref={ref}>
      <InfoOutlinedIcon sx={{ width: "16px", color: colors.grayscale.gray400 }} />
    </div>
  );
});

interface SubstagesInputProps {
  input: FieldInputProps<any, HTMLElement>;
  fieldPrefix: string;
  showHeaders?: boolean;
  required?: boolean;
}

const SubstagesInput = ({ fieldPrefix, input, showHeaders, required }: SubstagesInputProps): React.ReactElement => {
  const showAtsInterviewFeedbackField = useShouldShowAtsInterviewFeedbackField();

  const [substages, setSubstages] = useState<DraggableSubstage[]>(
    input.value ? getDraggableSubstages(input.value) : []
  );

  const interviewerIds = useMemo(() => {
    return substages.reduce((acc, substage) => {
      if (!substage.possibleInterviewers) {
        return acc;
      }
      return [...acc, ...substage.possibleInterviewers];
    }, [] as string[]);
  }, [substages]);

  useEffect(() => {
    // if there are no substages, initialize an empty one
    if (substages.length === 0) {
      setSubstages(prev => [
        ...prev,
        {
          name: "Substage 1",
          duration: 60 * 30,
          possibleInterviewers: [],
          interviewersRequired: "ANY",
          orderIndex: 0,
          orderRequired: false,
          draggableId: getDraggableId(),
        } as DraggableSubstage,
      ]);
    }
  }, []);

  const addSubstage = (): void => {
    setSubstages(prev => [
      ...prev,
      {
        name: `Substage ${prev.length + 1}`,
        duration: 60 * 30,
        possibleInterviewers: [],
        interviewersRequired: "ANY",
        orderIndex: getNextOrderIndex(substages),
        orderRequired: false,
        draggableId: getDraggableId(),
      },
    ]);
  };

  const addSubstageButton = useMemo((): React.ReactElement => {
    return (
      <BodySmall onClick={(): void => addSubstage()} style={{ color: colors.link, cursor: "pointer" }}>
        + Add Interview
      </BodySmall>
    );
  }, []);

  const handleSubstageChange = ({ idx, newSubstage }: { idx: number; newSubstage: any }): void => {
    setSubstages(prev =>
      prev.map(
        (substage: DraggableSubstage, i: number): DraggableSubstage => {
          if (i === idx) {
            return newSubstage;
          }
          return substage;
        }
      )
    );
  };

  const handleDeleteSubstage = (idx: number): void => {
    const filteredSubstages = substages
      .filter((substage: DraggableSubstage, i: number) => {
        return i !== idx;
      })
      .map((substage: DraggableSubstage, i: number) => ({ ...substage, orderIndex: i })); // re-assign orderIndex

    setSubstages(filteredSubstages);
  };

  useEffect(() => {
    // strip draggableId's before update
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const strippedSubstages = substages.map(({ draggableId, ...rest }) => {
      return rest;
    });
    input.onChange(strippedSubstages);
  }, [substages]);

  const reorder = (list: Array<DraggableSubstage>, startIndex: number, endIndex: number): DraggableSubstage[] => {
    const result = Array.from(list);
    // remove item dragged
    const [removed] = result.splice(startIndex, 1);
    // re-insert into list
    result.splice(endIndex, 0, removed);

    // re-assign orderIndices based on the new order
    const remappedOrderIndex = result.map((substage: DraggableSubstage, i: number) => ({ ...substage, orderIndex: i }));
    return remappedOrderIndex;
  };

  function onDragEnd(result: any): void {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedSubstages = reorder(substages, result.source.index, result.destination.index);

    setSubstages(reorderedSubstages);
  }

  const getHeightOfDraggingItem = (draggableId: string): number => {
    const draggingItem = substages.find(substage => substage.draggableId === draggableId);
    // if this substage has more than one selected possible interviewer, a conditional checkbox
    // will be rendered beneath the substage, increasing its total height
    const hasExtraHeight = draggingItem?.possibleInterviewers && draggingItem.possibleInterviewers.length > 1;
    if (hasExtraHeight) {
      return 98; // note: this was derived from inspecting the element's styling and is subject to change
    }
    return 48;
  };

  // I hate this I'm sorry (davin) -- planning to redesign the substages
  const interviewerInputWidth = showAtsInterviewFeedbackField ? 4.75 : 4;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Spacer height={16} />
        {showHeaders && (
          <SubstageHeaderContainer container flexDirection="row" alignItems="center">
            <Grid item xs={1.5}>
              <SubstageHeader>Order</SubstageHeader>
            </Grid>
            <Grid item xs={2}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <SubstageHeader>Stage name</SubstageHeader>
                <Tooltip title={"e.g. Technical interview or Culture fit"} placement="top">
                  <CustomTooltipIcon />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <SubstageHeader>Duration</SubstageHeader>
            </Grid>
            <Grid item xs={interviewerInputWidth}>
              <SubstageHeader>Default Interviewer(s)</SubstageHeader>
            </Grid>
            {showAtsInterviewFeedbackField ? (
              // TODO: davin -- rename based on ATS type
              <Grid item xs={1.75}>
                <SubstageHeader>ATS Feedback form</SubstageHeader>
              </Grid>
            ) : (
              <Grid item xs={2.5}>
                <SubstageHeader>Feedback form</SubstageHeader>
              </Grid>
            )}
          </SubstageHeaderContainer>
        )}
        <Droppable droppableId={input.name}>
          {(provided: any, snapshot: any): any => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: "100%" }}>
              {substages &&
                substages.map((substage: any, idx: any) => {
                  return (
                    <Draggable key={substage.draggableId} draggableId={substage.draggableId} index={idx}>
                      {(provided: any): any => (
                        <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <SubstageRow
                            value={substage}
                            onDelete={(): void => handleDeleteSubstage(idx)}
                            onChange={(newSubstage: any): void => handleSubstageChange({ idx, newSubstage })}
                            showDelete={substages.length > 1}
                            fieldPrefix={fieldPrefix}
                            showHeaders={showHeaders}
                            required={required}
                          />
                          {provided.placeholder}
                        </Box>
                      )}
                    </Draggable>
                  );
                })}
              {snapshot.isDraggingOver && (
                <div style={{ width: "100%", height: `${getHeightOfDraggingItem(snapshot.draggingOverWith)}px` }} />
              )}
            </div>
          )}
        </Droppable>
        <Stack direction="column" spacing={1} paddingBottom={"8px"}>
          {substages.length < 10 && (
            <>
              <Spacer height={12} />
              {addSubstageButton}
            </>
          )}
          <AddInterviewersToHiringTeam interviewerIds={interviewerIds} />
        </Stack>
      </Box>
    </DragDropContext>
  );
};

export default SubstagesInput;
