import styled from "styled-components";

const WhiteCard = styled.div`
  background: white;
  border: 1px solid #bcc7cc;
  border-radius: 6px;
`;

export const FormCard = styled(WhiteCard)<{ $fullWidth?: boolean }>`
  position: relative;
  padding: 24px;
  width: 100%;
  max-width: ${({ $fullWidth }): string => ($fullWidth ? "100%" : "710px")};
`;
