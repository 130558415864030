/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserRating
 */
export interface UserRating {
    /**
     * 
     * @type {string}
     * @memberof UserRating
     */
    readonly id?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRating
     */
    rating: number;
    /**
     * 
     * @type {string}
     * @memberof UserRating
     */
    ratingType: UserRatingRatingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRating
     */
    feedback?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRating
     */
    contentType: UserRatingContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRating
     */
    entityUuid: string;
}

/**
* @export
* @enum {string}
*/
export enum UserRatingRatingTypeEnum {
    DoverInterviewerFeedback = 'DOVER_INTERVIEWER_FEEDBACK',
    SummaryFeedback = 'SUMMARY_FEEDBACK'
}/**
* @export
* @enum {string}
*/
export enum UserRatingContentTypeEnum {
    Interviewrubricresponse = 'interviewrubricresponse'
}

export function UserRatingFromJSON(json: any): UserRating {
    return UserRatingFromJSONTyped(json, false);
}

export function UserRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRating {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rating': json['rating'],
        'ratingType': json['rating_type'],
        'feedback': !exists(json, 'feedback') ? undefined : json['feedback'],
        'contentType': json['content_type'],
        'entityUuid': json['entity_uuid'],
    };
}

export function UserRatingToJSON(value?: UserRating | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rating': value.rating,
        'rating_type': value.ratingType,
        'feedback': value.feedback,
        'content_type': value.contentType,
        'entity_uuid': value.entityUuid,
    };
}


