import { Box } from "@mui/material";
import React from "react";

import { JOB_FEATURE_GATING_CONFIG } from "components/dover/JobFeatureGating/constants";
import { JobFeatureFeatureNameEnum } from "services/openapi";
import { B2_doNotUse } from "styles/typography";

export const JobFeatureGatedBannerBulletList = ({
  jobFeatureName,
}: {
  jobFeatureName: JobFeatureFeatureNameEnum;
}): React.ReactElement => {
  const bannerLabel = JOB_FEATURE_GATING_CONFIG[jobFeatureName]?.bannerLabel;

  return (
    <Box>
      <B2_doNotUse bold>{bannerLabel ? bannerLabel : "Additional setup required"}</B2_doNotUse>
    </Box>
  );
};
