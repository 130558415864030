/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FrontClientInterviewer,
    FrontClientInterviewerFromJSON,
    FrontClientInterviewerFromJSONTyped,
    FrontClientInterviewerToJSON,
} from './';

/**
 * 
 * @export
 * @interface FrontBaseInterviewSubstage
 */
export interface FrontBaseInterviewSubstage {
    /**
     * 
     * @type {string}
     * @memberof FrontBaseInterviewSubstage
     */
    id?: string;
    /**
     * 
     * @type {Array<FrontClientInterviewer>}
     * @memberof FrontBaseInterviewSubstage
     */
    possibleInterviewers?: Array<FrontClientInterviewer>;
    /**
     * 
     * @type {number}
     * @memberof FrontBaseInterviewSubstage
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontBaseInterviewSubstage
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FrontBaseInterviewSubstage
     */
    useDoverInterviewer?: boolean | null;
}

export function FrontBaseInterviewSubstageFromJSON(json: any): FrontBaseInterviewSubstage {
    return FrontBaseInterviewSubstageFromJSONTyped(json, false);
}

export function FrontBaseInterviewSubstageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontBaseInterviewSubstage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'possibleInterviewers': !exists(json, 'possible_interviewers') ? undefined : ((json['possible_interviewers'] as Array<any>).map(FrontClientInterviewerFromJSON)),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'useDoverInterviewer': !exists(json, 'use_dover_interviewer') ? undefined : json['use_dover_interviewer'],
    };
}

export function FrontBaseInterviewSubstageToJSON(value?: FrontBaseInterviewSubstage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'possible_interviewers': value.possibleInterviewers === undefined ? undefined : ((value.possibleInterviewers as Array<any>).map(FrontClientInterviewerToJSON)),
        'duration': value.duration,
        'name': value.name,
        'use_dover_interviewer': value.useDoverInterviewer,
    };
}


