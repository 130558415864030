/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectFieldOption
 */
export interface SelectFieldOption {
    /**
     * 
     * @type {string}
     * @memberof SelectFieldOption
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof SelectFieldOption
     */
    label: string;
}

export function SelectFieldOptionFromJSON(json: any): SelectFieldOption {
    return SelectFieldOptionFromJSONTyped(json, false);
}

export function SelectFieldOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectFieldOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'label': json['label'],
    };
}

export function SelectFieldOptionToJSON(value?: SelectFieldOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'label': value.label,
    };
}


