/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RelatedCandidateCurrentStage,
    RelatedCandidateCurrentStageFromJSON,
    RelatedCandidateCurrentStageFromJSONTyped,
    RelatedCandidateCurrentStageToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelatedCandidate
 */
export interface RelatedCandidate {
    /**
     * 
     * @type {string}
     * @memberof RelatedCandidate
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedCandidate
     */
    readonly contactId?: string;
    /**
     * 
     * @type {RelatedCandidateCurrentStage}
     * @memberof RelatedCandidate
     */
    currentPipelineStage: RelatedCandidateCurrentStage | null;
    /**
     * 
     * @type {string}
     * @memberof RelatedCandidate
     */
    readonly jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedCandidate
     */
    status?: RelatedCandidateStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof RelatedCandidate
     */
    readonly created?: Date;
}

/**
* @export
* @enum {string}
*/
export enum RelatedCandidateStatusEnum {
    Withdrew = 'WITHDREW',
    Hired = 'HIRED',
    Rejected = 'REJECTED',
    Snoozed = 'SNOOZED',
    Ignored = 'IGNORED'
}

export function RelatedCandidateFromJSON(json: any): RelatedCandidate {
    return RelatedCandidateFromJSONTyped(json, false);
}

export function RelatedCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'currentPipelineStage': RelatedCandidateCurrentStageFromJSON(json['current_pipeline_stage']),
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
    };
}

export function RelatedCandidateToJSON(value?: RelatedCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_pipeline_stage': RelatedCandidateCurrentStageToJSON(value.currentPipelineStage),
        'status': value.status,
    };
}


