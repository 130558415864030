import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { SchedulableCalendar } from "services/openapi";

const interviewPlan = doverApi.injectEndpoints({
  endpoints: build => ({
    listSchedulableCalendars: build.query<SchedulableCalendar[] | undefined, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getSchedulableCalendars({ id: jobId });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useListSchedulableCalendarsQuery } = interviewPlan;
