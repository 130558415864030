import { Stack } from "@mui/material";
import React, { FC } from "react";
import { toast } from "react-toastify";
import { useToggle } from "react-use";

import { ReactComponent as TrashIconSVG } from "assets/icons/trash.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, ButtonText, Subtitle2, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { useRemoveSampleCandidatesMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";

interface RemoveSampleApplicantsProps {
  jobId: string;
}

export const RemoveSampleApplicants: FC<RemoveSampleApplicantsProps> = ({ jobId }) => {
  const [showRemoveSamplesModal, toggleShowRemoveSamplesModal] = useToggle(false);
  const [removeSampleCandidates, { isLoading: removingSamples }] = useRemoveSampleCandidatesMutation();

  const handleRemoveSampleCandidates = async (): Promise<void> => {
    const removeSampleCandidatesArgs = {
      data: {
        jobId: jobId,
      },
    };
    const removeSampleCandidatesPromise = removeSampleCandidates(removeSampleCandidatesArgs).unwrap();

    try {
      await toast.promise(
        removeSampleCandidatesPromise,
        {
          pending: "Removing sample applicants...",
          success: "Removed sample applicants!",
          error: "Error removing sample applicants",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    } finally {
      toggleShowRemoveSamplesModal();
    }
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        px={1}
        py={1.5}
        justifyContent="center"
        alignItems="center"
        bgcolor={colors.grayscale.gray100}
        sx={{
          ":hover": {
            cursor: "pointer",
            animation: "shimmer 1.5s infinite",
            background: `linear-gradient(90deg, ${colors.grayscale.gray100} 0%, ${colors.grayscale.gray200} 50%, ${colors.grayscale.gray100} 100%)`,
            backgroundSize: "200% 100%",
            backgroundPosition: "0% 50%",
          },
          "@keyframes shimmer": {
            "0%": { backgroundPosition: "0% 50%" },
            "100%": { backgroundPosition: "200% 50%" },
          },
        }}
        onClick={toggleShowRemoveSamplesModal}
      >
        <TrashIconSVG color={colors.critical.dark} />
        <BodySmall>Remove sample applicants</BodySmall>
      </Stack>
      <CustomModal
        open={showRemoveSamplesModal}
        title={<Subtitle2>Remove sample applicants</Subtitle2>}
        onClose={toggleShowRemoveSamplesModal}
        maxWidth="sm"
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button onClick={toggleShowRemoveSamplesModal} variant={ButtonVariant.Secondary} disabled={removingSamples}>
              <ButtonText>Cancel</ButtonText>
            </Button>
            <Button onClick={handleRemoveSampleCandidates} variant={ButtonVariant.Critical} disabled={removingSamples}>
              <ButtonText color={colors.white}>Remove</ButtonText>
            </Button>
          </Stack>
        }
      >
        <Body>Remove all sample applicants? This action can not be undone.</Body>
      </CustomModal>
    </>
  );
};
