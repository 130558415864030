import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { ApiApiListMentionsForUserRequest, ApiApiPartialUpdateMentionRequest } from "services/openapi";
import { Mention } from "services/openapi/models/Mention";

const mentions = doverApi.injectEndpoints({
  endpoints: build => ({
    listMentions: build.query<Mention[], ApiApiListMentionsForUserRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.listMentionsForUser(args);
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    partialUpdateMention: build.mutation<Mention, ApiApiPartialUpdateMentionRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.partialUpdateMention(args);

          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useListMentionsQuery, usePartialUpdateMentionMutation } = mentions;
