/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveFromOutboxRequest
 */
export interface RemoveFromOutboxRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveFromOutboxRequest
     */
    candidateIds: Array<string>;
}

export function RemoveFromOutboxRequestFromJSON(json: any): RemoveFromOutboxRequest {
    return RemoveFromOutboxRequestFromJSONTyped(json, false);
}

export function RemoveFromOutboxRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveFromOutboxRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateIds': json['candidate_ids'],
    };
}

export function RemoveFromOutboxRequestToJSON(value?: RemoveFromOutboxRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_ids': value.candidateIds,
    };
}


