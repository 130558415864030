import { Skeleton, Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as EyeCrossedOutSVG } from "assets/icons/eye-crossed-out.svg";
import { ReactComponent as EyeIconSVG } from "assets/icons/eye-new.svg";
import { INVALID_PERMS_TOOLTIP_TEXT } from "components/constants";
import { ButtonVariant } from "components/library/Button";
import { MenuButton } from "components/MenuButton";
import { Role, useContainsRole } from "components/RBAC";
import { useGetJobSetupQuery, useUpdateJobVisibilityMutation } from "services/doverapi/endpoints/job/endpoints";
import { toastOptions } from "utils/showToast";

export const JobPrivateSelect = (): React.ReactElement => {
  // Fetch data
  const { jobId } = useParams<{ jobId: string | undefined }>();
  const { data: job, isFetching } = useGetJobSetupQuery(jobId ? jobId : skipToken);
  const [updateJobVisibility] = useUpdateJobVisibilityMutation();

  // handle permissions
  const canEditJobVisibility = useContainsRole(
    [Role.ADMIN, Role.CLIENT_ADMIN, Role.HIRING_MANAGER, Role.RECRUITER],
    jobId
  );

  if (isFetching || !job) {
    return <Skeleton width="36px" height="32px" />;
  }

  const handleUpdateJobVisibility = async (isPrivate: boolean): Promise<void> => {
    if (!jobId) {
      return;
    }

    // no op if job is already at the desired visibility
    if (job.isPrivate === isPrivate) {
      return;
    }

    const promise = updateJobVisibility({
      id: jobId,
      isPrivate,
    });

    await toast.promise(
      promise,
      {
        pending: "Updating job visibliity...",
        success: "Successfully updated job visibility",
        error: "Failed to update job visibility",
      },
      { ...toastOptions }
    );
  };

  return (
    <>
      <MenuButton
        disabled={!canEditJobVisibility}
        tooltip={canEditJobVisibility ? undefined : INVALID_PERMS_TOOLTIP_TEXT}
        removePadding
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        variant={ButtonVariant.Ghost}
        label={
          <Box padding={"3.5px"}>
            {job?.isPrivate ? (
              <EyeCrossedOutSVG height="24px" width="24px" />
            ) : (
              <EyeIconSVG height="24px" width="24px" />
            )}
          </Box>
        }
        options={[
          {
            label: "Private",
            subtext: "Visible to Admins and Hiring Team",
            icon: <EyeCrossedOutSVG height="24px" width="24px" />,
            iconOnLeft: true,
            onClick: (): Promise<void> => handleUpdateJobVisibility(true),
          },
          {
            label: "Visible",
            subtext: "Visible to everyone at your company",
            icon: <EyeIconSVG height="24px" width="24px" />,
            iconOnLeft: true,
            onClick: (): Promise<void> => handleUpdateJobVisibility(false),
          },
        ]}
      />
    </>
  );
};
