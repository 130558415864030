import { TextField, MenuItem } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import styled from "styled-components";

import InterviewerCreateModal, { CreateInterviewerFuncArgs } from "components/NextInterviewer/InterviewerCreateModal";
import { getDurationOptions, getOrderRequiredOptions } from "components/SetupHiringPipeline/helpers";
import { useGetJobStageSetupPipeline } from "components/SetupHiringPipeline/hooks";
import { useCreateInterviewerMutation, useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import { ClientInterviewer } from "services/openapi";

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    height: 30px;
    box-sizing: border-box;
    padding: 11px;
    width: 100%;

    input {
      height: inherit;
      box-sizing: border-box;
    }
  }
  .MuiSelect-select {
    padding: 6px 8px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    top: 0;
    legend {
      display: none;
    }
  }
  &.MuiInputBase-root {
    border: none !important;
  }
`;

interface OrderRequiredInputProps {
  value: number;
  orderIndex: number;
  onChange: (e: any) => void;
}

export const OrderRequiredInput = ({ value, orderIndex, onChange }: OrderRequiredInputProps): React.ReactElement => {
  return (
    <StyledSelect variant="outlined" style={{ background: "white", width: "100%" }} value={value} onChange={onChange}>
      {getOrderRequiredOptions(orderIndex).map((option, index) => {
        return (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
};

interface DurationInputProps {
  value: number;
  onChange: (e: any) => void;
}

export const DurationInput = ({ value, onChange }: DurationInputProps): React.ReactElement => {
  return (
    <div>
      <StyledSelect variant="outlined" style={{ background: "white", width: "100%" }} value={value} onChange={onChange}>
        {getDurationOptions().map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </div>
  );
};

const StyledTextField = styled(TextField)`
  * {
    font-family: Inter;
    font-size: 14px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
  .MuiInputBase-root {
    border: none !important;
  }
  .MuiInputBase-input {
    padding: 8.5px; // this is what makes the height of this component ~ equal to the height of the others
  }
`;

interface NameInputProps {
  idx?: number | string | null;
  value: string;
  onChange: (e: React.ChangeEvent<{ value: string }>) => void;
  required?: boolean;
}

export const NameInput = ({ idx, value, onChange, required }: NameInputProps): React.ReactElement => {
  return (
    <StyledTextField
      id={`name-${idx}`}
      variant="outlined"
      value={value}
      onChange={onChange}
      placeholder="Name"
      size="small"
      error={required && !value}
    />
  );
};

const StyledAutocompleteTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

interface InterviewersInputProps {
  value: string[];
  handleChange: Function;
  required?: boolean;
}

interface ClientInterviewerOption extends ClientInterviewer {
  inputValue?: string;
}

const filter = createFilterOptions<ClientInterviewerOption>();

export const InterviewersInput = ({ value, handleChange, required }: InterviewersInputProps): React.ReactElement => {
  const interviewers = useListInterviewerEntities();
  const job = useGetJobStageSetupPipeline();
  const [showModal, setShowModal] = useState(false);

  const selectedInterviewers = React.useMemo(() => {
    const foundInterviewers: ClientInterviewer[] = [];
    value.forEach((outerInterviewerId: string): void => {
      const found = interviewers.find(
        (innerInterviewer: ClientInterviewer) => innerInterviewer.id === outerInterviewerId
      );
      if (found) {
        foundInterviewers.push(found);
      }
    });

    return foundInterviewers;
  }, [value, interviewers]);

  const onChangeSelection = (newValue: ClientInterviewerOption[]): void => {
    const newInterviewer = newValue.find(
      (obj: ClientInterviewerOption) => obj.inputValue || obj.email == `Add a new interviewer +`
    );
    if (newInterviewer) {
      setShowModal(true);
    } else {
      const interviewerIds = newValue.map((interviewer: ClientInterviewerOption) => interviewer.id);
      handleChange(interviewerIds);
    }
  };

  const [createInterviewer] = useCreateInterviewerMutation();

  const createInterviewerFn = async (values: CreateInterviewerFuncArgs): Promise<void> => {
    if (!job?.client) {
      console.error("no job.client");
      return;
    }

    const { firstName, lastName, email, phoneNumber } = values;
    await createInterviewer({ firstName, lastName, email, phoneNumber, client: job.client }).unwrap();
    setShowModal(false);
  };

  return (
    <>
      <Autocomplete
        disableCloseOnSelect
        multiple
        fullWidth
        limitTags={2}
        value={selectedInterviewers || []}
        onChange={(_event, newValue: ClientInterviewerOption[]): void => {
          onChangeSelection(newValue);
        }}
        options={(interviewers as ClientInterviewerOption[]) || []}
        getOptionLabel={(option: ClientInterviewerOption): string => option.email}
        renderInput={(params): any => (
          <StyledAutocompleteTextField
            {...params}
            variant="outlined"
            placeholder={selectedInterviewers.length < 1 ? "Select an interviewer" : ""}
            error={required && selectedInterviewers.length === 0}
          />
        )}
        filterOptions={(options: ClientInterviewerOption[], params): any => {
          const filtered = filter(options, params);

          filtered.push({
            client: interviewers[0].client,
            email: `Add a new interviewer +`,
            inputValue: params.inputValue,
          });

          return filtered;
        }}
        size="small"
        isOptionEqualToValue={(option, value): boolean => {
          return option.id === value.id;
        }}
      />
      <InterviewerCreateModal
        showModal={showModal}
        onCloseModal={(): void => setShowModal(false)}
        createInterviewer={createInterviewerFn}
        interviewers={interviewers}
        validateEmail={(): boolean => true} // add better checking later
        creating={showModal}
        hiringManagerEmail={job?.hiringManager?.email}
      />
    </>
  );
};
