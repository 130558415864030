import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useGetIncompleteCompanySetupStepsForFeature } from "services/doverapi/cross-endpoint-hooks/useGetIncompleteCompanySetupStepsForFeature";
import { useGetIncompleteJobSetupStepsForFeature } from "services/doverapi/cross-endpoint-hooks/useGetIncompleteJobSetupStepsForFeature";
import { JobFeatureDisabledStatus } from "services/doverapi/endpoints/job/types";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { JobFeatureFeatureNameEnum, JobFeatureStateEnum } from "services/openapi";

export function useGetJobFeatureDisabledStatus(
  featureName: JobFeatureFeatureNameEnum,
  jobId: string | undefined
): JobFeatureDisabledStatus | undefined {
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);

  const incompleteCompanySetupStepsForFeature = useGetIncompleteCompanySetupStepsForFeature(featureName, jobId);
  const incompleteJobSetupStepsForFeature = useGetIncompleteJobSetupStepsForFeature(featureName, jobId);

  return useMemo(() => {
    if (!jobFeatures || !incompleteCompanySetupStepsForFeature || !incompleteJobSetupStepsForFeature) {
      return undefined;
    }
    const jobFeature = jobFeatures.features.find(feature => feature.featureName === featureName);
    if (jobFeature?.state === JobFeatureStateEnum.Disabled) {
      // If the feature is disabled, but all of the prerequisite setup steps are complete, we still want to show the functionality in-app
      if (incompleteCompanySetupStepsForFeature.length === 0 && incompleteJobSetupStepsForFeature.length === 0) {
        return JobFeatureDisabledStatus.DisabledButDependentSetupStepsComplete;
      } else if ((jobFeature?.dependentJobSetupSteps ?? []).length > 0) {
        return JobFeatureDisabledStatus.Disabled;
      }
    } else if (incompleteCompanySetupStepsForFeature.length === 0 && incompleteJobSetupStepsForFeature.length === 0) {
      return JobFeatureDisabledStatus.NotDisabled;
    }
    return JobFeatureDisabledStatus.NotDisabledIncompleteSetupSteps;
  }, [jobFeatures, featureName, incompleteCompanySetupStepsForFeature, incompleteJobSetupStepsForFeature]);
}
