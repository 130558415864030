import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ActiveIcon } from "assets/icons/active-dot.svg";
import { ReactComponent as BriefcaseIcon } from "assets/icons/briefcase.svg";
import { ReactComponent as InactiveIcon } from "assets/icons/red-circle.svg";
import { BodySmall, Caption, Overline } from "components/library/typography";
import { useFindRelatedCandidatesQuery } from "services/doverapi/endpoints/contact";
import { colors } from "styles/theme";

interface RelatedCandidatesListProps {
  contactId: string | undefined;
  candidateId: string | undefined;
}

export const RelatedCandidatesList: FC<RelatedCandidatesListProps> = ({ contactId, candidateId }) => {
  const navigate = useNavigate();

  const { data: relatedCandidates } = useFindRelatedCandidatesQuery(contactId ? { contactId: contactId } : skipToken);
  const filteredRelatedCandidates = relatedCandidates?.filter(c => c.id !== candidateId);

  const genClick = (candidateId: string): (() => void) => (): void =>
    navigate(APP_ROUTE_PATHS.candidates.candidateDetail(candidateId));

  return (
    <Stack spacing={0.5}>
      {filteredRelatedCandidates?.map(c => (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={genClick(c.id!)}
          p={0.5}
          borderRadius="4px"
          sx={{
            cursor: "pointer",
            "&:hover": {
              backgroundColor: colors.grayscale.gray100,
              "& .hide-until-hover": {
                visibility: "visible",
              },
            },
          }}
        >
          <Stack>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <BriefcaseIcon />
              <BodySmall>{c.jobTitle}</BodySmall>
            </Stack>
            <Stack direction="row" spacing={0.5} alignItems="center" ml="1rem">
              {c.status ? <InactiveIcon /> : <ActiveIcon />}
              <Caption color={colors.grayscale.gray600}>
                {c.currentPipelineStage?.name} - Created {dayjs(c.created).format("M/D/YY")}
              </Caption>
            </Stack>
          </Stack>
          <Box className="hide-until-hover" sx={{ visibility: "hidden" }}>
            <Overline color={colors.linkLight}>View</Overline>
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};
