/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetOrCreateClientRequest,
    GetOrCreateClientRequestFromJSON,
    GetOrCreateClientRequestToJSON,
    GetOrCreateClientResponse,
    GetOrCreateClientResponseFromJSON,
    GetOrCreateClientResponseToJSON,
} from '../models';

export interface HooksApiSetUpUserAccountRequest {
    data: GetOrCreateClientRequest;
}

/**
 * HooksApi - interface
 * 
 * @export
 * @interface HooksApiInterface
 */
export interface HooksApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HooksApiInterface
     */
    hooksPostCookiesCreateRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     */
    hooksPostCookiesCreate(): Promise<void>;

    /**
     * Gets or creates a Client, domain, and pro user
     * @param {GetOrCreateClientRequest} data 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HooksApiInterface
     */
    setUpUserAccountRaw(requestParameters: HooksApiSetUpUserAccountRequest): Promise<runtime.ApiResponse<GetOrCreateClientResponse>>;

    /**
     * Gets or creates a Client, domain, and pro user
     */
    setUpUserAccount(requestParameters: HooksApiSetUpUserAccountRequest): Promise<GetOrCreateClientResponse>;

}

/**
 * 
 */
export class HooksApi extends runtime.BaseAPI implements HooksApiInterface {

    /**
     */
    async hooksPostCookiesCreateRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/hooks/post_cookies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async hooksPostCookiesCreate(): Promise<void> {
        await this.hooksPostCookiesCreateRaw();
    }

    /**
     * Gets or creates a Client, domain, and pro user
     */
    async setUpUserAccountRaw(requestParameters: HooksApiSetUpUserAccountRequest): Promise<runtime.ApiResponse<GetOrCreateClientResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling setUpUserAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/hooks/set-up-user-account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetOrCreateClientRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrCreateClientResponseFromJSON(jsonValue));
    }

    /**
     * Gets or creates a Client, domain, and pro user
     */
    async setUpUserAccount(requestParameters: HooksApiSetUpUserAccountRequest): Promise<GetOrCreateClientResponse> {
        const response = await this.setUpUserAccountRaw(requestParameters);
        return await response.value();
    }

}
