import { CandidateBio } from "services/openapi";

export enum CandidateActivityNoteAuthorRole {
  Customer = "CUSTOMER",
  Admin = "ADMIN",
  CSM_or_CX = "CSM_OR_CX",
  Coordinator = "COORDINATOR",
}

export interface TimezoneOption {
  offset: string;
  label: string;
  tzCode: string;
  disabled?: boolean;
}

export type CloseFunction = (candidate: CandidateBio) => void;
