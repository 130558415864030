/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseFeedbackTemplateQuestion,
    BaseFeedbackTemplateQuestionFromJSON,
    BaseFeedbackTemplateQuestionFromJSONTyped,
    BaseFeedbackTemplateQuestionToJSON,
    FeedbackTemplateQuestionComponent,
    FeedbackTemplateQuestionComponentFromJSON,
    FeedbackTemplateQuestionComponentFromJSONTyped,
    FeedbackTemplateQuestionComponentToJSON,
    TextFieldFeedbackTemplateQuestionAllOf,
    TextFieldFeedbackTemplateQuestionAllOfFromJSON,
    TextFieldFeedbackTemplateQuestionAllOfFromJSONTyped,
    TextFieldFeedbackTemplateQuestionAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface TextFieldFeedbackTemplateQuestion
 */
export interface TextFieldFeedbackTemplateQuestion extends BaseFeedbackTemplateQuestion {
    /**
     * 
     * @type {FeedbackTemplateQuestionComponent}
     * @memberof TextFieldFeedbackTemplateQuestion
     */
    component: FeedbackTemplateQuestionComponent;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestion
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestion
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestion
     */
    helperText?: string;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestion
     */
    type?: TextFieldFeedbackTemplateQuestionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TextFieldFeedbackTemplateQuestion
     */
    largeTextArea?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum TextFieldFeedbackTemplateQuestionTypeEnum {
    Text = 'text'
}

export function TextFieldFeedbackTemplateQuestionFromJSON(json: any): TextFieldFeedbackTemplateQuestion {
    return TextFieldFeedbackTemplateQuestionFromJSONTyped(json, false);
}

export function TextFieldFeedbackTemplateQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextFieldFeedbackTemplateQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BaseFeedbackTemplateQuestionFromJSONTyped(json, ignoreDiscriminator),
        'component': FeedbackTemplateQuestionComponentFromJSON(json['component']),
        'name': json['name'],
        'label': json['label'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'helperText': !exists(json, 'helper_text') ? undefined : json['helper_text'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'largeTextArea': !exists(json, 'large_text_area') ? undefined : json['large_text_area'],
    };
}

export function TextFieldFeedbackTemplateQuestionToJSON(value?: TextFieldFeedbackTemplateQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BaseFeedbackTemplateQuestionToJSON(value),
        'component': FeedbackTemplateQuestionComponentToJSON(value.component),
        'name': value.name,
        'label': value.label,
        'placeholder': value.placeholder,
        'helper_text': value.helperText,
        'type': value.type,
        'large_text_area': value.largeTextArea,
    };
}


