import { Box } from "@mui/material";
import { atom, useAtom, useAtomValue } from "jotai";
import React from "react";

import FormRenderer from "components/data-driven-forms/Form";
import { CallRecording, JIRTJob, JobCompensation, JobLocation } from "services/openapi";
import { IRRCandidate as CandidateModel } from "services/openapi/models/IRRCandidate";
import { IRRCandidateInterviewInfo } from "services/openapi/models/IRRCandidateInterviewInfo";
import { IRRInterview as InterviewModel } from "services/openapi/models/IRRInterview";
import { interviewRubricModeAtom } from "views/interview/CandidateInterview/atoms";
import { useInterviewRubricResponseQuery } from "views/interview/CandidateInterview/hooks";
import { InterviewRubricMode } from "views/interview/CandidateInterview/types";
import {
  getComponentMapper,
  InterviewRubricFormTemplate,
  SubmittedInterviewRubricFormTemplate,
} from "views/interview/common/components/Form";
import InterviewContext, { InterviewContextCustomerFacing } from "views/interview/common/components/InterviewContext";

export const InterviewCallRecordingAtom = atom<CallRecording | null>(null);
export const InterviewStartTimeAtom = atom<Date | null>(null);

interface InterviewFormProps {
  disabled: boolean;
  onSubmit: (v: any) => void;
  onSave?: (v: any) => void;
  onEdit: (v: any) => void;
  candidate?: CandidateModel;
  job?: JIRTJob;
  jobId?: string;
  clientId?: string;
  jobLocations?: JobLocation[];
  jobCompensation?: JobCompensation;
  visaSupport?: boolean;
  interviewInfo?: InterviewModel;
  candidateInterviewInfo?: IRRCandidateInterviewInfo;
  nextStepsInfo?: any;
  schema: any;
  initialValues?: any;
  questionsOnly?: boolean;
  numTimesRescheduled?: number;
  summary?: any;
  interviewRubricResponseId?: string;
  existingRoughNotesAvailable?: boolean;
  submittedByProUserId?: number;
}

const InterviewForm = React.memo(
  ({
    disabled,
    onSave,
    onSubmit,
    onEdit,
    candidate,
    job,
    jobId,
    clientId,
    jobLocations,
    jobCompensation,
    visaSupport,
    interviewInfo,
    candidateInterviewInfo,
    interviewRubricResponseId,
    nextStepsInfo,
    schema,
    initialValues,
    questionsOnly,
    numTimesRescheduled,
    summary,
    existingRoughNotesAvailable,
    submittedByProUserId,
  }: InterviewFormProps): React.ReactElement => {
    const [interviewCallRecording, setInterviewCallRecording] = useAtom(InterviewCallRecordingAtom);
    const [interviewStartTime, setInterviewStartTime] = useAtom(InterviewStartTimeAtom);
    const interviewRubricModeAtomValue = useAtomValue(interviewRubricModeAtom);

    const { interviewRubricResponse, isLoadingInterviewRubricResponse } = useInterviewRubricResponseQuery({
      candidateId: candidate?.id,
      interviewRubricResponseId,
    });

    const inEditMode = interviewRubricModeAtomValue === InterviewRubricMode.Interview;

    const FormTemplate = inEditMode ? InterviewRubricFormTemplate : SubmittedInterviewRubricFormTemplate;

    const pitchInfo = job?.supplementalPitchInfo ?? "";

    const AppropriateInterviewContext = React.useMemo(() => {
      // dover interviewers need to see the candidate/interview context
      if (candidateInterviewInfo?.useDoverInterviewer) {
        return (
          <Box maxHeight="85vh" overflow="scroll">
            <InterviewContext
              candidate={candidate}
              includeCandidateContext
              job={job}
              jobLocations={jobLocations}
              jobCompensation={jobCompensation}
              visaSupport={visaSupport}
              includeJobInfoContext
              nextStepsInfo={nextStepsInfo}
              includeNextStepsInfoContext
            />
          </Box>
        );
      }

      return (
        <InterviewContextCustomerFacing
          candidateId={candidate?.id}
          candidateInterviewInfo={candidateInterviewInfo}
          job={job}
          interviewRubricResponseId={interviewRubricResponseId}
        />
      );
    }, [
      candidateInterviewInfo,
      candidate,
      job,
      interviewRubricResponseId,
      jobLocations,
      jobCompensation,
      visaSupport,
      nextStepsInfo,
    ]);

    if (isLoadingInterviewRubricResponse) {
      return <></>;
    }

    // Set atom values if it is not already set
    if (!interviewCallRecording && interviewRubricResponse?.candidateInterviewInfo?.callRecording) {
      setInterviewCallRecording(interviewRubricResponse.candidateInterviewInfo.callRecording);
    }
    if (!interviewStartTime && interviewRubricResponse?.interview?.start) {
      setInterviewStartTime(interviewRubricResponse.interview.start);
    }

    return (
      <FormRenderer
        schema={schema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        // eslint-disable-next-line react/no-unstable-nested-components
        CustomFormTemplate={(props: any): React.ReactElement => (
          <FormTemplate
            {...props}
            disabled={disabled}
            interviewInfo={interviewInfo}
            candidateInterviewInfo={candidateInterviewInfo}
            interviewRubricResponseId={interviewRubricResponseId}
            candidate={candidate}
            onSave={onSave}
            onEdit={onEdit}
            numTimesRescheduled={numTimesRescheduled}
          />
        )}
        componentMapper={getComponentMapper({
          interviewMode: inEditMode,
          disabled,
          questionsOnly,
          pitchCompanyAndJob: pitchInfo,
          candidate,
          jobId,
          interviewInfo,
          clientId,
          candidateInterviewInfo,
          summary,
          interviewRubricResponseId,
          interviewContext: AppropriateInterviewContext,
          existingRoughNotesAvailable,
          submittedByProUserId,
          hasTranscript: !!interviewRubricResponse?.hasTranscript,
        })}
      />
    );
  }
);

export default InterviewForm;
