import styled from "styled-components";

import { colors } from "styles/theme";

const Subtitle = styled.div`
  font-size: 0.85em;
  color: ${colors.subTitle};
`;

export const DetailsContainer = styled.div`
  margin-bottom: 16px;
`;

export const DetailItem = styled.div``;

export const DetailHeader = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

export const DetailText = styled.div`
  display: inline-block;
  font-size: 0.85em;
  margin-right: 8px;
`;

export const DetailLink = styled.a`
  display: inline-block;
  font-size: 0.85em;
  margin-right: 4px;
`;

export const DetailSubtitle = styled(Subtitle)`
  display: inline-block;
`;

export const ContactInfoWrapper = styled.div`
  align-items: baseline;
  display: flex;
  padding-left: 4px;
`;
