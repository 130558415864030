/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PersonaTier2Titles,
    PersonaTier2TitlesFromJSON,
    PersonaTier2TitlesFromJSONTyped,
    PersonaTier2TitlesToJSON,
    PersonaTitles,
    PersonaTitlesFromJSON,
    PersonaTitlesFromJSONTyped,
    PersonaTitlesToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersonaParams
 */
export interface PersonaParams {
    /**
     * 
     * @type {PersonaTier2Titles}
     * @memberof PersonaParams
     */
    targetTier2Titles: PersonaTier2Titles;
    /**
     * 
     * @type {PersonaTier2Titles}
     * @memberof PersonaParams
     */
    excludedTier2Titles: PersonaTier2Titles;
    /**
     * 
     * @type {PersonaTitles}
     * @memberof PersonaParams
     */
    targetCustomTitles: PersonaTitles;
    /**
     * 
     * @type {PersonaTitles}
     * @memberof PersonaParams
     */
    excludedCustomTitles: PersonaTitles;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonaParams
     */
    targetMlTitles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonaParams
     */
    excludedMlTitles: Array<string>;
}

export function PersonaParamsFromJSON(json: any): PersonaParams {
    return PersonaParamsFromJSONTyped(json, false);
}

export function PersonaParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonaParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetTier2Titles': PersonaTier2TitlesFromJSON(json['target_tier2_titles']),
        'excludedTier2Titles': PersonaTier2TitlesFromJSON(json['excluded_tier2_titles']),
        'targetCustomTitles': PersonaTitlesFromJSON(json['target_custom_titles']),
        'excludedCustomTitles': PersonaTitlesFromJSON(json['excluded_custom_titles']),
        'targetMlTitles': json['target_ml_titles'],
        'excludedMlTitles': json['excluded_ml_titles'],
    };
}

export function PersonaParamsToJSON(value?: PersonaParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target_tier2_titles': PersonaTier2TitlesToJSON(value.targetTier2Titles),
        'excluded_tier2_titles': PersonaTier2TitlesToJSON(value.excludedTier2Titles),
        'target_custom_titles': PersonaTitlesToJSON(value.targetCustomTitles),
        'excluded_custom_titles': PersonaTitlesToJSON(value.excludedCustomTitles),
        'target_ml_titles': value.targetMlTitles,
        'excluded_ml_titles': value.excludedMlTitles,
    };
}


