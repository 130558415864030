/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlimProUserField,
    SlimProUserFieldFromJSON,
    SlimProUserFieldFromJSONTyped,
    SlimProUserFieldToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubmittedFeedbackField
 */
export interface SubmittedFeedbackField {
    /**
     * 
     * @type {string}
     * @memberof SubmittedFeedbackField
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SubmittedFeedbackField
     */
    overallRating?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmittedFeedbackField
     */
    submissionState: number;
    /**
     * 
     * @type {SlimProUserField}
     * @memberof SubmittedFeedbackField
     */
    submittedBy: SlimProUserField;
    /**
     * 
     * @type {Date}
     * @memberof SubmittedFeedbackField
     */
    submittedOn: Date;
}

export function SubmittedFeedbackFieldFromJSON(json: any): SubmittedFeedbackField {
    return SubmittedFeedbackFieldFromJSONTyped(json, false);
}

export function SubmittedFeedbackFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmittedFeedbackField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'overallRating': !exists(json, 'overall_rating') ? undefined : json['overall_rating'],
        'submissionState': json['submission_state'],
        'submittedBy': SlimProUserFieldFromJSON(json['submitted_by']),
        'submittedOn': (new Date(json['submitted_on'])),
    };
}

export function SubmittedFeedbackFieldToJSON(value?: SubmittedFeedbackField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'overall_rating': value.overallRating,
        'submission_state': value.submissionState,
        'submitted_by': SlimProUserFieldToJSON(value.submittedBy),
        'submitted_on': (value.submittedOn.toISOString()),
    };
}


