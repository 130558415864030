import { Stack } from "@mui/material";
import React from "react";

import { AccountSettingsSectionType, CompanySetupSectionType } from "App/routing/types";
import { ProgressList } from "components/library/ProgressList";
import { ProgressListHeader } from "components/library/ProgressList";
import { ProgressListItem } from "components/library/ProgressList";
import { BodySmall } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { accountSettingsConfiguration, companySectionConfiguration } from "views/CompanySetup/constants";
import { useGetCompanySectionConfigurationKeys } from "views/CompanySetup/hooks";

export default (): React.ReactElement => {
  const companySectionConfigKeys = useGetCompanySectionConfigurationKeys();
  const hasApiAccessFF = useFeatureFlag(FeatureFlag.ApiAccess);

  return (
    <ProgressList maxWidth={200}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <ProgressListHeader>Company</ProgressListHeader>
          <Stack>
            {companySectionConfigKeys.map((sectionType: string) => {
              if (sectionType === CompanySetupSectionType.API_KEY && !hasApiAccessFF) {
                return null;
              }

              return (
                <ProgressListItem href={`/settings/company/${sectionType}`}>
                  <BodySmall>
                    {companySectionConfiguration[sectionType as CompanySetupSectionType].sideNavLabel}
                  </BodySmall>
                </ProgressListItem>
              );
            })}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <ProgressListHeader>Your Account</ProgressListHeader>
          <Stack>
            {Object.keys(accountSettingsConfiguration).map((sectionType: string) => {
              return (
                <ProgressListItem href={`/settings/account/${sectionType}`}>
                  <BodySmall>
                    {accountSettingsConfiguration[sectionType as AccountSettingsSectionType].sideNavLabel}
                  </BodySmall>
                </ProgressListItem>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </ProgressList>
  );
};
