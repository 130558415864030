import AdjustIcon from "@mui/icons-material/Adjust";
import SortIcon from "@mui/icons-material/Sort";
import SubjectIcon from "@mui/icons-material/Subject";
import UploadIcon from "@mui/icons-material/Upload";
import React from "react";

import { ApplicationQuestionInputTypeEnum } from "services/openapi";

export const applicationQuestionInputTypeIconAndLabel = {
  [ApplicationQuestionInputTypeEnum.ShortAnswer]: { icon: <SortIcon />, label: "Short answer" },
  [ApplicationQuestionInputTypeEnum.LongAnswer]: { icon: <SubjectIcon />, label: "Long answer" },
  [ApplicationQuestionInputTypeEnum.MultipleChoice]: { icon: <AdjustIcon />, label: "Multiple choice" },
  [ApplicationQuestionInputTypeEnum.FileUpload]: { icon: <UploadIcon />, label: "File upload" },
};
