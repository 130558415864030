import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { USER_RATING, USER_RATINGS_BY_ENTITY } from "services/doverapi/endpointTagsConstants";
import { UserRating, UserRatingContentTypeEnum, UserRatingRatingTypeEnum } from "services/openapi";
import { showErrorToast, showSuccessToast } from "utils/showToast";

const userRatingRequestEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listUserRatingsByEntity: build.query<UserRating[], { entityType: string; entityUuid: string }>({
      queryFn: async ({ entityType, entityUuid }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const results = await client.getUserRatingsByEntity({ entityType, entityUuid });
        return { data: results };
      },
      providesTags: (result, error, { entityType, entityUuid }) => [
        { type: USER_RATINGS_BY_ENTITY, id: `${entityType}_${entityUuid}` },
      ],
    }),
    createUserRating: build.mutation<
      UserRating,
      {
        ratingType: UserRatingRatingTypeEnum;
        contentType: UserRatingContentTypeEnum;
        entityUUID: string;
        feedback: string;
        rating: number;
      }
    >({
      queryFn: async ({ ratingType, contentType, entityUUID, feedback, rating }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result = undefined;
        try {
          result = await client.createUserRating({
            data: {
              ratingType,
              contentType,
              entityUuid: entityUUID,
              feedback,
              rating,
            },
          });

          showSuccessToast("Thank you for your response");
        } catch (error) {
          const userFacingMessage = "Failed to submit the response. Please refresh and try again.";
          showErrorToast(userFacingMessage);

          return { error: { serializedError: error as SerializedError, userFacingMessage } };
        }

        return { data: result };
      },
      invalidatesTags: result => {
        return result
          ? [
              { type: USER_RATING, id: result.id },
              { type: USER_RATINGS_BY_ENTITY, id: `${result.contentType}_${result.entityUuid}` },
            ]
          : [];
      },
    }),
  }),
});

export const { useCreateUserRatingMutation, useListUserRatingsByEntityQuery } = userRatingRequestEndpoints;
