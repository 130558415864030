import { Box } from "@mui/material";
import React from "react";

import { COLUMN_PADDING } from "components/dover/SearchesTable/SearchesTable";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

interface NumContactedCellProps {
  numContacted?: number;
}

export const NumContactedCell = ({ numContacted }: NumContactedCellProps): React.ReactElement => {
  const numContactedText = numContacted !== undefined ? numContacted : "Unknown";
  return (
    <Box padding={COLUMN_PADDING}>
      <BodySmall color={colors.linkLight}>{numContactedText}</BodySmall>
    </Box>
  );
};
