/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCredential
 */
export interface UserCredential {
    /**
     * 
     * @type {number}
     * @memberof UserCredential
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCredential
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredential
     */
    source: UserCredentialSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserCredential
     */
    readonly state?: UserCredentialStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum UserCredentialSourceEnum {
    Triplebyte = 'TRIPLEBYTE',
    Angellist = 'ANGELLIST'
}/**
* @export
* @enum {string}
*/
export enum UserCredentialStateEnum {
    Connected = 'CONNECTED',
    LostConnection = 'LOST_CONNECTION'
}

export function UserCredentialFromJSON(json: any): UserCredential {
    return UserCredentialFromJSONTyped(json, false);
}

export function UserCredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCredential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'source': json['source'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function UserCredentialToJSON(value?: UserCredential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'source': value.source,
    };
}


