import { Box, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { Body } from "components/library/typography";
import { colors } from "styles/theme";
import { focusNotesEditor } from "utils/focusNotesEditor";

export const NoNotesTabContent = (): ReactElement => {
  return (
    <Stack margin="auto" spacing={1}>
      <Body weight="600" centered onClick={focusNotesEditor}>
        No notes yet
      </Body>
      <Box>
        <Body color={colors.grayscale.gray500} centered>
          Capture quick thoughts about the candidate and share with your co-workers.
        </Body>
        <AddNoteButton color={colors.grayscale.gray500} centered onClick={focusNotesEditor}>
          Add a note
        </AddNoteButton>
      </Box>
    </Stack>
  );
};

const AddNoteButton = styled(Body)`
  margin-top: 5px;
  text-decoration: underline;
  cursor: pointer;
`;
