/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneratedJDQualification,
    GeneratedJDQualificationFromJSON,
    GeneratedJDQualificationFromJSONTyped,
    GeneratedJDQualificationToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeneratedJDQualifications
 */
export interface GeneratedJDQualifications {
    /**
     * 
     * @type {string}
     * @memberof GeneratedJDQualifications
     */
    header?: string;
    /**
     * 
     * @type {GeneratedJDQualification}
     * @memberof GeneratedJDQualifications
     */
    content: GeneratedJDQualification;
}

export function GeneratedJDQualificationsFromJSON(json: any): GeneratedJDQualifications {
    return GeneratedJDQualificationsFromJSONTyped(json, false);
}

export function GeneratedJDQualificationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratedJDQualifications {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': !exists(json, 'header') ? undefined : json['header'],
        'content': GeneratedJDQualificationFromJSON(json['content']),
    };
}

export function GeneratedJDQualificationsToJSON(value?: GeneratedJDQualifications | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': value.header,
        'content': GeneratedJDQualificationToJSON(value.content),
    };
}


