import { createEntityAdapter } from "@reduxjs/toolkit";
import moment from "moment-timezone";

import { colors } from "styles/theme";
import { InterviewRound, InterviewSchedulerState } from "views/interview/InterviewScheduler/types";

export const interviewSchedulerKey = "interviewScheduler";

export const interviewEventsAdapter = createEntityAdapter<InterviewRound>();

export const initialState: InterviewSchedulerState = {
  resources: [],
  interviewRounds: interviewEventsAdapter.getInitialState(),
  date: new Date(),
  timezone: moment.tz.guess(),
  locationOverride: "",
  expectedNumberOfSubstages: 0,
  deletedSubstageIds: [],
  canceledSubstageIds: [],
};

export const interviewStageIdQueryParam = "interviewStageId";
export const candidateIdQueryParam = "candidateId";

export const CalendarStyle = {
  Default: "Default",
  Interview: "Interview",
  Availability: "Availability",
} as const;

export const CalendarStyleColor: { [key: string]: string } = {
  Default: colors.decorative.highlightBlue,
  Interview: colors.brand,
  Availability: colors.warning.dark,
} as const;
