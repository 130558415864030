import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { EMPTY_EMAIL_USER } from "components/dover/top-level-modal-manager/constants";
import { useInitialBulkEmailSender } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/utils";
import { BasicEmailOption, EmailUser } from "components/library/TipTap/types";
import { useGetBulkRejectEmailTemplateQuery } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { HiringPipelineStageType } from "services/openapi";

interface BulkEmailStateArgs {
  jobId?: string;
  clientEmailTemplateId?: string | null;
}

export const useBulkEmailState = ({
  jobId,
  clientEmailTemplateId,
}: BulkEmailStateArgs): {
  from: EmailUser;
  setFrom: React.Dispatch<React.SetStateAction<EmailUser>>;
  initialSubject: string;
  subject: string;
  setSubject: React.Dispatch<React.SetStateAction<string>>;
  initialBody: string;
  body: string;
  setBody: React.Dispatch<React.SetStateAction<string>>;
  cc: BasicEmailOption[];
  setCc: React.Dispatch<React.SetStateAction<BasicEmailOption[]>>;
  bcc: BasicEmailOption[];
  setBcc: React.Dispatch<React.SetStateAction<BasicEmailOption[]>>;
  isFetching: boolean;
  initialClientEmailTemplateId?: string;
} => {
  /*
    Local Stage
  */
  const [from, setFrom] = useState<EmailUser>(EMPTY_EMAIL_USER);
  const [subject, setSubject] = useState<string>("");
  const [initialSubject, setInitialSubject] = useState<string>("");
  // Body and subject is a little weird because the tip tap editor is actually semi uncontrolled.
  // We just want to set the initial state for body and let TipTap handle the rest.
  // We track the changes with our own local "body" state, but we don't feed that value to
  // TipTap. Because everytime you change the body passed to tiptap it completely resets
  // all editor state and reinitizlizes, which has undesired behavior like reseting cursor position.
  const [body, setBody] = useState<string>("");
  const [initialBody, setInitialBody] = useState<string>("");
  const [cc, setCc] = useState<BasicEmailOption[]>([]);
  const [bcc, setBcc] = useState<BasicEmailOption[]>([]);

  /*
    API Calls
  */

  const { data: job, isFetching: isJobFetching } = useGetJobQuery(jobId ?? skipToken);

  const appliedHps = job?.hiringPipelineStages?.find(
    hps => hps.stageType === HiringPipelineStageType.APPLICATION_REVIEW
  );
  const { data: template, isFetching: isTemplateFetching } = useGetBulkRejectEmailTemplateQuery(
    appliedHps?.id ? { data: { hiringPipelineStageId: appliedHps.id, clientEmailTemplateId } } : skipToken
  );

  const initialClientEmailTemplateId = clientEmailTemplateId ?? appliedHps?.rejectionEmailTemplateId;

  /*
    Effects
  */

  // Set the initial email sender
  const initialEmailSender = useInitialBulkEmailSender();
  useEffect(() => {
    if (from.id === "" && from.email === "" && initialEmailSender) {
      setFrom(initialEmailSender);
    }
  }, [from, initialEmailSender, setFrom]);

  // Set initial email state once the template loads
  // Also overwrite anytime the data changes (e.g. on stage change)
  useEffect(() => {
    if (template) {
      // Convert to BasicEmailOption for editor
      const cc: BasicEmailOption[] = template.ccEmails?.map(e => ({ id: uuidv4(), email: e })) ?? [];
      const bcc: BasicEmailOption[] = template.bccEmails?.map(e => ({ id: uuidv4(), email: e })) ?? [];

      setSubject(template.subject);
      setInitialSubject(template.subject);
      setBody(template.body);
      setInitialBody(template.body);
      setCc(cc);
      setBcc(bcc);
    }
  }, [template]);

  return {
    from,
    setFrom,
    initialSubject,
    subject,
    setSubject,
    initialBody,
    body,
    setBody,
    cc,
    setCc,
    bcc,
    setBcc,
    isFetching: isTemplateFetching || isJobFetching || !template, // We include the !data check because there seems to be atleast 1 render in between api calls where both isFetching's are false which causes a UI flash
    initialClientEmailTemplateId,
  };
};
