/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobReportMeta
 */
export interface JobReportMeta {
    /**
     * 
     * @type {Date}
     * @memberof JobReportMeta
     */
    kickoffTimestamp: Date | null;
    /**
     * 
     * @type {number}
     * @memberof JobReportMeta
     */
    numPotentialReferralCandidates: number;
    /**
     * 
     * @type {Date}
     * @memberof JobReportMeta
     */
    firstOutboundCandidateContactedOn: Date | null;
}

export function JobReportMetaFromJSON(json: any): JobReportMeta {
    return JobReportMetaFromJSONTyped(json, false);
}

export function JobReportMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobReportMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kickoffTimestamp': (json['kickoff_timestamp'] === null ? null : new Date(json['kickoff_timestamp'])),
        'numPotentialReferralCandidates': json['num_potential_referral_candidates'],
        'firstOutboundCandidateContactedOn': (json['first_outbound_candidate_contacted_on'] === null ? null : new Date(json['first_outbound_candidate_contacted_on'])),
    };
}

export function JobReportMetaToJSON(value?: JobReportMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kickoff_timestamp': (value.kickoffTimestamp === null ? null : value.kickoffTimestamp.toISOString()),
        'num_potential_referral_candidates': value.numPotentialReferralCandidates,
        'first_outbound_candidate_contacted_on': (value.firstOutboundCandidateContactedOn === null ? null : value.firstOutboundCandidateContactedOn.toISOString()),
    };
}


