import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import Color from "color";
import React from "react";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/job-board-manager.svg";
import ReportingSplashImage from "assets/icons/reporting-splash-page.png";
import { ReactComponent as ReportsIcon } from "assets/icons/reports-2.svg";
import { ReactComponent as TrendingUpIcon } from "assets/icons/trending-up.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { GradientBackground } from "components/library/GradientBackground";
import { TitleLarge, Body, BodySmall } from "components/library/typography";
import { colors, screenSizesNumbers } from "styles/theme";

interface ReportingSplashProps {
  onActivate: () => void;
}

const FeatureItem = ({
  icon: Icon,
  title,
  description,
}: {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
}): React.ReactElement => (
  <Stack direction="row" spacing={2} alignItems="flex-start">
    <Icon
      width="24px"
      height="24px"
      className="svg-color"
      color={colors.primary.base}
      style={{ minWidth: "24px", marginTop: "4px" }}
    />
    <Stack spacing={0.5}>
      <Body style={{ fontSize: "18px", fontWeight: 600 }}>{title}</Body>
      <BodySmall color={colors.grayscale.gray600}>{description}</BodySmall>
    </Stack>
  </Stack>
);

const ReportingSplash = ({ onActivate }: ReportingSplashProps): React.ReactElement => {
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptop));

  return (
    <GradientBackground
      spacing={4}
      direction={isSmallScreen ? "column" : "row"}
      justifyContent="space-between"
      alignItems="center"
      minHeight={isSmallScreen ? "auto" : "700px"}
      m={2}
      p={6}
      style={{
        border: `1px solid ${colors.grayscale.gray200}`,
        borderRadius: "16px",
      }}
      angle="90"
      firstColor="rgba(230, 247, 255, 0.6)"
      firstColorPercent="0.1"
      secondColor="rgba(250, 253, 255, 0)"
      secondColorPercent="57.08"
    >
      <Stack spacing={4} maxWidth={isSmallScreen ? "100%" : "60%"}>
        <Stack spacing={2}>
          <TitleLarge
            style={{
              fontSize: isSmallScreen ? "32px" : "40px",
              fontWeight: 800,
              lineHeight: isSmallScreen ? "40px" : "48px",
              color: Color(colors.primary.dark)
                .darken(0.3)
                .toString(),
            }}
          >
            Unlock reliable reporting and analytics you can trust
          </TitleLarge>
          <Body style={{ fontSize: "18px", color: colors.grayscale.gray700 }}>
            Empower yourself with strategic insights to drive impactful conversations with business leaders and support
            informed decision-making.
          </Body>
        </Stack>

        <Stack spacing={3}>
          <FeatureItem
            icon={ReportsIcon}
            title="Dig into complex recruiting metrics"
            description="Custom reports, dashboards and alerts, to help you strategically partner with stakeholders across the business."
          />
          <FeatureItem
            icon={DashboardIcon}
            title="Default job dashboards"
            description="Out-of-the-box dashboards across all of your jobs. Help your team self-serve with pre-built analytics and collaborate at a strategic level."
          />
          <FeatureItem
            icon={CalendarIcon}
            title="Recruiting planner"
            description="Visualize and track what it will take to meet your recruiting goals. Present your hiring plan and progress in an easy to consume format."
          />
          <FeatureItem
            icon={TrendingUpIcon}
            title="Demonstrate impact and ROI"
            description="Study how new initiatives or processes impact hiring outcomes to justify change management."
          />
        </Stack>

        <Box>
          <Button variant={ButtonVariant.Primary} onClick={onActivate}>
            Activate Reporting & Analytics
          </Button>
        </Box>
      </Stack>

      {!isSmallScreen && (
        <Box
          width="45%"
          position="relative"
          alignSelf="stretch"
          display="flex"
          alignItems="flex-end"
          sx={{ height: "600px" }}
        >
          <img
            src={ReportingSplashImage}
            alt="Reporting analytics preview"
            style={{
              width: "100%",
              position: "absolute",
              height: "auto",
              maxHeight: "600px",
              bottom: "-48px",
              right: 0,
              objectFit: "contain",
              objectPosition: "bottom",
              borderRadius: "8px 8px 0 0",
            }}
          />
        </Box>
      )}
    </GradientBackground>
  );
};

export default ReportingSplash;
