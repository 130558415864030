import { SliderProps } from "@mui/material";
import React from "react";

import { AppId } from "App/appConstants";
import { StyledSlider } from "components/library/SingleValueSlider/styles";
import ValueLabel, { ValueLabelProps } from "components/library/YOESlider/ValueLabel";
import { useIsInViewport } from "hooks/useIsInViewport";

/**
 * @param shouldShowTooltip - determines whether we display a value label over the slider
 * @param scrollContainerId - if the HTMLElement with this ID is currently being scrolled, we'll hide any value label
 * @param invertTrack - will fill "end" of the track instead of "start" as slider thumb moves towards the right hand side
 */
type SingleValueSliderProps = Pick<
  SliderProps,
  "value" | "min" | "max" | "step" | "onChange" | "marks" | "valueLabelFormat"
> & {
  shouldShowTooltip?: boolean;
  scrollContainerId?: string;
  invertTrack?: boolean;
};

const SingleValueSlider = React.memo(
  ({
    value,
    min,
    max,
    step,
    shouldShowTooltip,
    scrollContainerId = AppId,
    invertTrack,
    onChange,
    marks,
    valueLabelFormat,
  }: SingleValueSliderProps): React.ReactElement => {
    const [isScrolling, setIsScrolling] = React.useState<boolean>(false);

    const sliderRef = React.useRef<HTMLSpanElement>(null);
    const isInViewport = useIsInViewport(sliderRef);

    React.useEffect(() => {
      document.getElementById(scrollContainerId)!.addEventListener("scroll", () => {
        setIsScrolling(true);
      });
      const timer = setInterval(() => {
        setIsScrolling(false);
      }, 1000);
      return (): void => {
        clearTimeout(timer);
      };
    }, [scrollContainerId]);

    return (
      <StyledSlider
        ref={sliderRef}
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        marks={marks}
        min={min}
        max={max}
        step={step}
        $invertTrack={invertTrack}
        valueLabelFormat={valueLabelFormat}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          ValueLabel: (props: ValueLabelProps): React.ReactElement => (
            <ValueLabel value={props.value} isOpen={!!shouldShowTooltip && !isScrolling && isInViewport}>
              {props.children}
            </ValueLabel>
          ),
        }}
      />
    );
  }
);

export default SingleValueSlider;
