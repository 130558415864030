import DoneIcon from "@mui/icons-material/Done";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { Box } from "@mui/material";
import React, { useState } from "react";

import { ExcludedConnections } from "components/dover/ContactExclusion/ExcludedConnections";
import { ContentWrapper, StyledEditText } from "components/dover/ContactExclusion/styles";
import LinkedInConnectionsUpload from "components/dover/LinkedInConnectionsUpload";
import { LoadingRelative } from "components/HotLoading";
import DoverLoadingOverlay from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import useDidAuthedUserUploadFile from "services/doverapi/cross-endpoint-hooks/useDidAuthedUserUploadFile";
import useUsersWhoUploadedConnections from "services/doverapi/cross-endpoint-hooks/useUsersWhoUploadedConnections";
import { useGetClientUserFileStatsQuery } from "services/doverapi/endpoints/userUploadedFile";
import { UserUploadedFileUploadedContextEnum } from "services/openapi";
import { colors } from "styles/theme";
import { StyledBanner } from "views/Contacts/components/styles";

const UPLOADED_CONTEXT = UserUploadedFileUploadedContextEnum.Referrals;

const AuthedUserAlreadyUploadedMessage = ({
  hideUploadContactsAgain,
}: {
  hideUploadContactsAgain: () => void;
}): React.ReactElement => (
  <StyledBanner>
    <Box display="flex" alignItems="baseline">
      <Box color={colors.success.base} fontSize="19px">
        <DoneIcon fontSize="inherit" />
      </Box>
      <Spacer width={16} />
      <Box>
        {`You've successfully uploaded your contacts. `}{" "}
        <StyledEditText onClick={(): void => hideUploadContactsAgain()}>Re-upload contacts</StyledEditText>
      </Box>
    </Box>
  </StyledBanner>
);

const ContactExclusion = (): React.ReactElement => {
  const [showUploadContactsAgain, setShowUploadContactsAgain] = useState(false);

  const { isSuccess: loadedUserFileStats } = useGetClientUserFileStatsQuery({
    uploadedContext: UPLOADED_CONTEXT,
  });

  const didAuthedUserUploadFile = useDidAuthedUserUploadFile(UPLOADED_CONTEXT);
  const usersWhoUploadedConnections = useUsersWhoUploadedConnections(UPLOADED_CONTEXT);
  const numUsersWhoUploadedConnections = usersWhoUploadedConnections?.length;

  const loading = didAuthedUserUploadFile === undefined || usersWhoUploadedConnections === undefined;

  if (loading) {
    return (
      <Box marginTop="30px" display="flex" justifyContent="center">
        <LoadingRelative />
      </Box>
    );
  }

  const authedUserHasUploadedStats = loadedUserFileStats && didAuthedUserUploadFile;

  const shouldShowContactUpload = showUploadContactsAgain && authedUserHasUploadedStats;
  const shouldShowSuccess = !showUploadContactsAgain && authedUserHasUploadedStats;

  if (shouldShowSuccess) {
    return (
      <div>
        <AuthedUserAlreadyUploadedMessage
          hideUploadContactsAgain={(): void => {
            setShowUploadContactsAgain(true);
          }}
        />
        <ExcludedConnections
          loadedUserFileStats={loadedUserFileStats}
          didAuthedUserUploadFile={didAuthedUserUploadFile!}
          numUsersWhoUploadedConnections={numUsersWhoUploadedConnections!}
        />
      </div>
    );
  }

  return (
    <div>
      {loadedUserFileStats && !didAuthedUserUploadFile && (
        <ContentWrapper elevation={0} variant="outlined">
          <Box display="flex" justifyContent="center" p={1}>
            <PeopleAltOutlinedIcon />
          </Box>
          <Box display="flex" justifyContent="center" fontWeight="bold">
            You have not uploaded your connections yet.
          </Box>
          <Box display="flex" justifyContent="center">
            Follow the steps below to upload them.
          </Box>
        </ContentWrapper>
      )}
      <ContentWrapper elevation={0} variant="outlined">
        {!loadedUserFileStats && <DoverLoadingOverlay active={true} overlay={false} />}
        {shouldShowContactUpload ? (
          <LinkedInConnectionsUpload
            cancelUploadContacts={(): void => {
              setShowUploadContactsAgain(false);
            }}
          />
        ) : (
          <LinkedInConnectionsUpload />
        )}
      </ContentWrapper>

      <ExcludedConnections
        loadedUserFileStats={loadedUserFileStats}
        didAuthedUserUploadFile={didAuthedUserUploadFile!}
        numUsersWhoUploadedConnections={numUsersWhoUploadedConnections!}
      />
    </div>
  );
};

export default ContactExclusion;
