import { Stack } from "@mui/material";
import React from "react";

import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import StageSelector from "components/StageSelector/StageSelector";
import { CandidateBioJobStage } from "services/openapi";

export const StageSelect: React.FC<{
  stageId?: string;
  setStage: (stageId: string) => void;
  stages?: CandidateBioJobStage[] | undefined;
  disabled?: boolean;
  disabledTooltipText?: string;
}> = ({ stageId, setStage, stages, disabled, disabledTooltipText }) => {
  return (
    <Stack spacing={1} width="100%">
      <BodySmall weight="500">Stage</BodySmall>
      <Tooltip title={disabledTooltipText}>
        <Stack width="100%">
          <StageSelector pipelineStageId={stageId} setStage={setStage} stages={stages} disabled={disabled} />
        </Stack>
      </Tooltip>
    </Stack>
  );
};
