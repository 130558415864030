/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SocialLinkType {
    NONE = 'null',
    ABOUTME = 'ABOUTME',
    ANGELLIST = 'ANGELLIST',
    CAREERBUILDER = 'CAREERBUILDER',
    CRUNCHBASE = 'CRUNCHBASE',
    FACEBOOK = 'FACEBOOK',
    FLICKR = 'FLICKR',
    GITHUB = 'GITHUB',
    GRAVATAR = 'GRAVATAR',
    HEALTHGRADES = 'HEALTHGRADES',
    INDEED = 'INDEED',
    INSTAGRAM = 'INSTAGRAM',
    KAGGLE = 'KAGGLE',
    LINKEDIN = 'LINKEDIN',
    PINTEREST = 'PINTEREST',
    PORTFOLIO = 'PORTFOLIO',
    RATEMD = 'RATEMD',
    RESUME = 'RESUME',
    STACKOVERFLOW = 'STACKOVERFLOW',
    TWITTER = 'TWITTER',
    WEBSITE = 'WEBSITE',
    OTHER = 'OTHER'
}

export function SocialLinkTypeFromJSON(json: any): SocialLinkType {
    return SocialLinkTypeFromJSONTyped(json, false);
}

export function SocialLinkTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialLinkType {
    return json as SocialLinkType;
}

export function SocialLinkTypeToJSON(value?: SocialLinkType | null): any {
    return value as any;
}

