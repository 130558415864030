import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import styled from "styled-components";

import {
  calendlyRegex,
  savvyCalRegex,
  candidateFirstNameTemplateVarRegex,
  emailSenderNameRegex,
  minNumberNameAppears,
} from "components/dover/CampaignEditor/constants";
import { getInvalidTagsFromEmailMessages } from "components/dover/CampaignEditor/utils";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall, Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CampaignMessageEditor from "components/outreach-configuration/form/CampaignMessageEditorField";
import { DEFAULT_CAMPAIGN_MESSAGE_DELAY, SECONDS_IN_DAY } from "components/outreach-configuration/form/constants";
import { campaignsFormSchema, CampaignsFormSchemaType } from "components/outreach-configuration/form/types";
import {
  selectFromListCampaignsQueryResult,
  useListCampaignsQuery,
  usePartialUpdateCampaignMutation,
} from "services/doverapi/endpoints/campaign";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import {
  Campaign,
  CampaignMessageCampaignMessageStateEnum,
  ListCampaign,
  ListCampaignSetupStateEnum,
} from "services/openapi";
import { colors, filters } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { getEmailAliasName } from "utils/getEmailAliasName";
import CampaignStateButton from "views/job/JobSetup/steps/CampaignVariants/components/CampaignStateButton";

const WhiteCard = styled(Box)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  margin: 80px 0px;
  gap: 20px;
`;

interface CampaignEditorProps {
  campaign: ListCampaign;
}

const CampaignEditor = ({ campaign }: CampaignEditorProps): React.ReactElement => {
  const { data: userDefinedSenderUser, isFetching: isFetchingProUser } = useGetProUserQuery(
    (campaign.userDefinedSenderUser as unknown) as string
  );
  const showStateButton = campaign && campaign.setupState === ListCampaignSetupStateEnum.Complete;

  return (
    <Stack spacing={3}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        sx={{ borderRadius: "4px", border: `1px solid ${colors.grayscale.gray300}` }}
      >
        <Box display="flex">
          <Box display="flex" p={2}>
            <BodySmall color={colors.grayscale.gray500}>From:</BodySmall>
          </Box>
          <Box display="flex" py={2}>
            {isFetchingProUser ? (
              <BodySmall color={colors.grayscale.gray500}>Loading...</BodySmall>
            ) : (
              <BodySmall>
                {getEmailAliasName(userDefinedSenderUser, campaign.emailSenderOption, campaign.emailAlias)}
              </BodySmall>
            )}
          </Box>
        </Box>
        <Box display="flex" p={2}>
          <Tooltip
            zIndex={1200}
            arrow={true}
            title={
              <BodySmall color={colors.white}>
                {"Dover will create a custom domain to send emails on their behalf."}
                <ExternalLink
                  target="_blank"
                  rel="noopener noreferrer"
                  color={colors.link}
                  href="https://help.dover.com/en/articles/6208043-custom-domain-faq"
                >
                  Learn more
                </ExternalLink>
              </BodySmall>
            }
          >
            <BodySmall style={{ cursor: "pointer" }} color={colors.link}>
              {"What email will these send from?"}
            </BodySmall>
          </Tooltip>
        </Box>
      </Box>
      {(campaign.threadMessages ?? []).map((message, idx) => {
        return (
          <Stack spacing={2} sx={{ paddingBottom: "40px" }}>
            {idx > 0 && <Heading>{`Followup ${idx}`}</Heading>}
            {idx === 0 && (
              <Stack justifyContent="space-between" direction="row" alignItems="end">
                <Heading>{`Initial outreach`}</Heading>
                {showStateButton && <CampaignStateButton campaign={campaign} campaignSender={userDefinedSenderUser} />}
              </Stack>
            )}
            <Box>
              <CampaignMessageEditor
                idx={idx}
                initialBodyHtmlTemplate={message.bodyTemplate}
                initialSubjectHtmlTemplate={message.subjectTemplate}
                senderUser={userDefinedSenderUser}
              />
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};

interface CampaignEditorWrapperProps {
  jobId: string;
  campaignId: string;
  onSave: () => void;
}

const CampaignEditorWrapper = ({ jobId, campaignId, onSave }: CampaignEditorWrapperProps): React.ReactElement => {
  // fetch data
  const { allCampaigns } = useListCampaignsQuery(
    { jobId },
    {
      selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
    }
  );

  const selectedCampaign = React.useMemo(() => {
    return allCampaigns?.find(campaign => campaign.id === campaignId);
  }, [allCampaigns, campaignId]);
  const { data: userDefinedSenderUser } = useGetProUserQuery(
    selectedCampaign ? ((selectedCampaign.userDefinedSenderUser as unknown) as string) : skipToken
  );

  // set up mutations
  const [partialUpdateCampaign, { isLoading: isUpdatingCampaign }] = usePartialUpdateCampaignMutation();

  // form state
  const campaignsForm = useForm<CampaignsFormSchemaType>({
    defaultValues: { campaignEditors: [] },
    resolver: zodResolver(campaignsFormSchema),
  });
  const campaignsFormValues = useWatch({ control: campaignsForm.control });

  // local state
  const [isClosingEditor, setIsClosingEditor] = useState(false);

  // derived state

  // This handles an edge case where the user selects on a campaign with a user defined sender user
  // then selects another campaign without a user defined sender user.
  // We have to ensure that the selectedCampaign has a user defined sender user, otherwise the
  // userDefinedSenderUser variable from the getProUserQuery will not update because the skipToken is used
  // and userDefinedSenderUser will still reference the one from the previous campaign.
  const senderUser = selectedCampaign?.userDefinedSenderUser ? userDefinedSenderUser : undefined;

  const emailMessages = React.useMemo<string | undefined>(() => {
    const campaignEditors = campaignsFormValues?.campaignEditors;
    if (!campaignEditors || campaignEditors.length === 0) {
      return undefined;
    }

    return campaignEditors.reduce((acc, curr) => {
      return acc + (curr.rawEditorState as any).bodyHtml ?? "";
    }, "");
  }, [campaignsFormValues?.campaignEditors]);

  const passingCalendlyQa = React.useMemo(() => {
    return !emailMessages?.match(calendlyRegex);
  }, [emailMessages]);

  const passingSavvyQa = React.useMemo(() => {
    return !emailMessages?.match(savvyCalRegex);
  }, [emailMessages]);

  const passingNameQa = React.useMemo(() => {
    let nameMatchesCount = 0;
    // If the user has defined a first name, we want to make sure it appears at least once in the email.
    if (senderUser?.firstName) {
      const regexName = new RegExp(senderUser.firstName, "g");
      nameMatchesCount = (emailMessages?.match(regexName) || []).length;
    }

    const emailSenderNameRegexMatchesCount = (emailMessages?.match(emailSenderNameRegex) || []).length;
    const count = nameMatchesCount + emailSenderNameRegexMatchesCount;

    return count >= minNumberNameAppears;
  }, [emailMessages, senderUser?.firstName]);

  const invalidTags = React.useMemo(() => {
    return getInvalidTagsFromEmailMessages(emailMessages);
  }, [emailMessages]);

  const passingCandidateFirstNameTempVar = React.useMemo(() => {
    if (
      !campaignsFormValues?.campaignEditors?.[0] ||
      !(
        campaignsFormValues?.campaignEditors?.[0]?.rawEditorState &&
        "bodyHtml" in campaignsFormValues.campaignEditors[0].rawEditorState
      )
    ) {
      return false;
    }
    return (campaignsFormValues.campaignEditors[0].rawEditorState as any).bodyHtml?.match(
      candidateFirstNameTemplateVarRegex
    );
  }, [campaignsFormValues?.campaignEditors]);

  const tooltipElement = React.useMemo(() => {
    if (invalidTags.length > 0) {
      return (
        <Stack spacing={2}>
          <BodySmall color={colors.white}>
            There are unsupported variables in your outreach. Please replace the following with Dover-supported
            variables or remove them.
          </BodySmall>
          <Stack>
            {invalidTags.map((tag: string) => (
              <>
                <BodySmall color={colors.white}>&#8226; &#123;&#123;{tag}&#125;&#125;</BodySmall>
              </>
            ))}
          </Stack>
        </Stack>
      );
    }

    if (!passingNameQa) {
      if (senderUser?.firstName) {
        return `Outreach is set to come from ${senderUser?.firstName} but there is no mention of this name in
      the outreach content. Please add a mention of ${senderUser?.firstName}.`;
      }
      return `There is no outreach variable set for the email sender. Please add {{EMAIL_SENDER_NAME}} to your outreach content.`;
    }

    if (!passingCalendlyQa || !passingSavvyQa) {
      return `Please remove the ${
        !passingCalendlyQa ? "Calendly" : "SavvyCal"
      } link in your outreach content. We’re unable to support having scheduling links in outreach messages as it disrupts the automated flow we have in place.`;
    }

    if (!passingCandidateFirstNameTempVar) {
      return `Looks like you haven't included a variable for the candidate's first name. To move forward, please include {{FIRST_NAME}} in the body of the initial outreach.`;
    }

    return undefined;
  }, [
    invalidTags,
    passingCalendlyQa,
    passingNameQa,
    passingSavvyQa,
    senderUser?.firstName,
    passingCandidateFirstNameTempVar,
  ]);

  // callbacks
  const handleSubmit = React.useCallback(() => {
    const trySavingCampaign = async (values: CampaignsFormSchemaType): Promise<void> => {
      if (!values || !selectedCampaign?.id) {
        return;
      }

      const updatedCampaign: Pick<Campaign, "updatedMessages" | "saveAsDraft"> = {
        saveAsDraft: false,
      };
      updatedCampaign.updatedMessages = (values.campaignEditors ?? []).map(message => {
        const campaignMessageState = message.enabled
          ? CampaignMessageCampaignMessageStateEnum.Active
          : CampaignMessageCampaignMessageStateEnum.Inactive;

        return {
          messageId: message.id,
          rawEditorState: message.rawEditorState as object,
          campaignMessageState,
          minMessageDelay: message.minMessageDelay,
        };
      });

      setIsClosingEditor(true);

      try {
        await partialUpdateCampaign({
          id: selectedCampaign.id,
          jobId: jobId,
          updatedCampaign: updatedCampaign as Campaign,
          showToast: false,
        }).unwrap();

        onSave();
      } catch {
        setIsClosingEditor(false);
      }
    };

    trySavingCampaign(campaignsFormValues as CampaignsFormSchemaType);
  }, [campaignsFormValues, selectedCampaign, partialUpdateCampaign, jobId, onSave]);

  // effects
  React.useEffect(() => {
    if (!selectedCampaign?.threadMessages) {
      return;
    }

    // Set initial form value on campaign load
    campaignsForm.setValue(
      "campaignEditors",
      selectedCampaign.threadMessages.map(message => {
        return {
          rawEditorState: message.rawEditorState ?? {
            bodyHtml: message.bodyTemplate,
            subjectHtml: message.subjectTemplate,
          },
          id: message.messageId,
          enabled: message.campaignMessageState !== CampaignMessageCampaignMessageStateEnum.Inactive,
          minMessageDelay: message.minMessageDelay
            ? // @ts-ignore
              parseFloat(message.minMessageDelay) * SECONDS_IN_DAY
            : DEFAULT_CAMPAIGN_MESSAGE_DELAY,
        };
      })
    );
  }, [campaignsForm, selectedCampaign?.threadMessages]);

  if (isUpdatingCampaign || isClosingEditor || !selectedCampaign) {
    return (
      <WhiteCard>
        <DoverLoadingSpinner active={true} filter={filters.filterDarkGreen} />
        <Box textAlign="center">
          <Body>{"Saving your customized outreach..."}</Body>
        </Box>
      </WhiteCard>
    );
  }

  return (
    <Stack justifyContent="center" alignItems="center">
      <Stack direction="row" spacing={4}>
        <FormProvider {...campaignsForm}>
          <Box>
            <CampaignEditor campaign={selectedCampaign} />
            <Stack>
              {(!passingCalendlyQa || !passingNameQa || !passingSavvyQa || !passingCandidateFirstNameTempVar) && (
                <Tooltip zIndex={1200} arrow={true} title={tooltipElement ?? ""} placement="top">
                  <BodySmall style={{ cursor: "pointer" }} color={colors.link}>
                    {"Please correct your outreach content before saving."}
                  </BodySmall>
                </Tooltip>
              )}
              <Stack justifyContent="flex-end">
                <Button
                  onClick={handleSubmit}
                  variant={ButtonVariant.Primary}
                  loading={isUpdatingCampaign}
                  disabled={
                    !passingCalendlyQa || !passingNameQa || !passingSavvyQa || !passingCandidateFirstNameTempVar
                  }
                >
                  {"Save"}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default CampaignEditorWrapper;
