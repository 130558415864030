import { Grid, Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Select, TextField } from "mui-rff";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import ClientInterviewerPicker from "components/dover/entity-pickers/ClientInterviewerPicker";
import EmailSenderAliasPicker from "components/dover/entity-pickers/EmailSenderAliasPicker";
import { AlwaysShowError } from "components/form/Error";
import { Button, ButtonVariant } from "components/library/Button";
import { Spacer } from "components/Spacer";
import {
  useCreateHiringStageEmailTemplateMutation,
  usePartialUpdateHiringStageEmailTemplateMutation,
  useSendTestEmailMutation,
  useGetHiringStageEmailTemplateQuery,
} from "services/doverapi/endpoints/emailTemplates";
import { useGetAdminHiringPipelineStagesSorted } from "services/doverapi/endpoints/hiringPipelineStage";
import { ClientInterviewer, HiringStageEmailTemplate } from "services/openapi";
import { ActionMenu } from "views/admin/job/HiringStageEmailTemplates/components/ActionMenu";
import { CcEmailsField } from "views/admin/job/HiringStageEmailTemplates/components/CcEmailsField";
import { EmailEditor } from "views/admin/job/HiringStageEmailTemplates/components/EmailEditor";
import {
  getInitialValuesFromEmailTemplate,
  templateTypes,
} from "views/admin/job/HiringStageEmailTemplates/form-helpers";

const StyledActionMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledForm = styled.form`
  position: relative;
`;

export const EmailTemplateForm = (): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId, emailTemplateId } = useParams<{ jobId: string; emailTemplateId: string }>();

  const { data: emailTemplate } = useGetHiringStageEmailTemplateQuery(
    emailTemplateId ? { id: emailTemplateId } : skipToken
  );

  const [
    partialUpdateHiringStageEmailTemplate,
    { isLoading: isUpdatingHiringStageEmail },
  ] = usePartialUpdateHiringStageEmailTemplateMutation();
  const [
    createHiringStageEmailTemplate,
    { isLoading: isCreatingHiringStageEmail },
  ] = useCreateHiringStageEmailTemplateMutation();

  const savingEmailTemplate = isUpdatingHiringStageEmail || isCreatingHiringStageEmail;
  const [sendTestEmail, { isLoading: sendingTestEmail }] = useSendTestEmailMutation();
  const hiringPipelineStages = useGetAdminHiringPipelineStagesSorted(jobId);

  const [initialValues, setInitialValues] = useState(getInitialValuesFromEmailTemplate(emailTemplate));
  const [isNew, setIsNew] = useState(!emailTemplateId);

  useEffect(() => {
    setInitialValues(getInitialValuesFromEmailTemplate(emailTemplate));
  }, [emailTemplate]);

  useEffect(() => {
    setIsNew(!emailTemplateId);
  }, [emailTemplateId]);

  const onSubmit = React.useCallback(
    (values: any) => {
      const trySubmit = async (): Promise<void> => {
        if (!jobId) {
          console.error("jobId is undefined");
          return;
        }
        if (isNew) {
          const result = await createHiringStageEmailTemplate({
            template: values,
            jobId,
          }).unwrap();
          navigate(APP_ROUTE_PATHS.admin.jobEmailTemplates(jobId, result.id));
        } else {
          if (!values.id) {
            return;
          }

          partialUpdateHiringStageEmailTemplate({
            hiringStageEmailTemplateId: (values as HiringStageEmailTemplate).id!,
            updatedHiringStageEmailTemplate: values as HiringStageEmailTemplate,
            showToasts: false,
          });
        }
      };

      trySubmit();
    },
    [createHiringStageEmailTemplate, navigate, isNew, jobId, partialUpdateHiringStageEmailTemplate]
  );

  const formComponent = React.useMemo(() => {
    // if (!emailTemplate) {
    //   return <div>Loading...</div>;
    // }

    return (
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validateForm}
        render={({ handleSubmit, dirty, values, form }): React.ReactNode => (
          <StyledForm onSubmit={handleSubmit}>
            <StyledActionMenuWrapper>
              <ActionMenu />
            </StyledActionMenuWrapper>

            <Grid container direction="column" spacing={2}>
              <Grid container item>
                <Grid item>Hiring pipeline stage:</Grid>
                <Spacer width="16px" />
                <Grid item>
                  <Select name="hiringPipelineStage" color="secondary">
                    {hiringPipelineStages.map((stage, index) => (
                      <MenuItem
                        sx={{
                          "&.MuiButtonBase-root": {
                            display: "flex",
                            justifyContent: "start",
                            padding: "8px",
                          },
                        }}
                        key={index}
                        value={stage.id}
                      >
                        {stage.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid container item>
                <Grid item>Template type:</Grid>
                <Spacer width="16px" />
                <Grid item>
                  <Select name="templateType">
                    {templateTypes.map((type, index) => (
                      <MenuItem
                        sx={{
                          "&.MuiButtonBase-root": {
                            display: "flex",
                            justifyContent: "start",
                            padding: "8px",
                          },
                        }}
                        key={index}
                        value={type.value}
                      >
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid item xs={8}>
                <TextField name="name" label="Template name" variant="standard" />
              </Grid>

              <Grid item xs={4}>
                <Field name="requiredEmailAlias">
                  {(props): React.ReactElement => (
                    <EmailSenderAliasPicker
                      initialEmailSenderAliasId={props.input.value}
                      onChange={(option: any): void => props.input.onChange(option?.id ?? null)}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item>
                <Field name="hasSameInterviewerAndEmailSender">
                  {(props): React.ReactElement => (
                    <FormControlLabel
                      label="Same interviewer as email sender"
                      control={
                        <Checkbox
                          checked={props.input.value}
                          onChange={(e): void => {
                            if (e.target.checked) {
                              form.change("requiredInterviewer", "");
                            }
                            props.input.onChange(e.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                  )}
                </Field>
              </Grid>
              <Grid item>
                <Field name="userEditingDisabled">
                  {(props): React.ReactElement => (
                    <FormControlLabel
                      label="User editing disabled"
                      control={
                        <Checkbox
                          checked={props.input.value}
                          onChange={(e): void => {
                            props.input.onChange(e.target.checked);
                          }}
                          color="primary"
                        />
                      }
                    />
                  )}
                </Field>
              </Grid>

              {!values.hasSameInterviewerAndEmailSender && (
                <Grid item xs={4}>
                  <Field name="requiredInterviewer">
                    {(props): React.ReactElement => (
                      <ClientInterviewerPicker
                        initialInterviewerId={props.input.value}
                        onChange={(option: ClientInterviewer): void => props.input.onChange(option?.id ?? null)}
                        disabled={values.hasSameInterviewerAndEmailSender}
                      />
                    )}
                  </Field>
                </Grid>
              )}

              <Grid item xs={8}>
                <CcEmailsField type="ccEmails" />
              </Grid>

              <Grid item xs={8}>
                <CcEmailsField type="bccEmails" />
              </Grid>

              <Grid item>
                <AlwaysShowError name="emailBody" />
                <EmailEditor />
              </Grid>

              <Grid container spacing={2} item>
                <Grid item>
                  <Button
                    variant={ButtonVariant.Secondary}
                    onClick={(): void => {
                      sendTestEmail((values as any) as HiringStageEmailTemplate);
                    }}
                    disabled={sendingTestEmail || savingEmailTemplate}
                  >
                    Send Test Email
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" variant={ButtonVariant.Primary} disabled={!dirty || savingEmailTemplate}>
                    {isNew ? "Create" : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </StyledForm>
        )}
      />
    );
  }, [
    // emailTemplate,
    hiringPipelineStages,
    initialValues,
    isNew,
    onSubmit,
    savingEmailTemplate,
    sendTestEmail,
    sendingTestEmail,
  ]);

  return formComponent;
};

function getText(html: string): string {
  const divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

function validateForm(values: any): any {
  const errors: any = {};

  if (!values?.name?.length) {
    errors.name = "Please set a name";
  } else {
    errors.name = undefined;
  }

  if (!getText(values?.subjectTemplate || "").trim()) {
    errors.emailBody = "Subject cannot be blank";
  } else {
    errors.emailBody = undefined;
  }

  return errors;
}
