/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultipartSchedulingClientInterviewer
 */
export interface MultipartSchedulingClientInterviewer {
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingClientInterviewer
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingClientInterviewer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingClientInterviewer
     */
    readonly fullName?: string;
}

export function MultipartSchedulingClientInterviewerFromJSON(json: any): MultipartSchedulingClientInterviewer {
    return MultipartSchedulingClientInterviewerFromJSONTyped(json, false);
}

export function MultipartSchedulingClientInterviewerFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipartSchedulingClientInterviewer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
    };
}

export function MultipartSchedulingClientInterviewerToJSON(value?: MultipartSchedulingClientInterviewer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}


