import { Autocomplete, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Checkbox from "components/inputs/MultiSelect/Checkbox";
import Label from "components/inputs/MultiSelect/Label";
import { AutocompleteStyles, Tag } from "components/inputs/MultiSelect/styles";
import { getOptionLabel } from "components/inputs/MultiSelect/utils";

const MultiSelect = ({
  label,
  options,
  value,
  onChange,
  maxOptions,
  TextFieldProps: { label: textFieldLabel, helperText, invalid, required, onFocus, onBlur, isSearchable, placeholder },
  ...rest
}) => {
  const [valueSet, setValueSet] = useState(!(!value || value.length === 0));
  const [internalSelected, setInternalSelected] = React.useState(value || []);
  const [open, setOpen] = useState(false);
  const maxReached = maxOptions && (internalSelected ?? []).length === maxOptions;

  React.useEffect(() => {
    if (maxReached) {
      setOpen(false);
    }
  }, [maxReached]);

  return (
    <AutocompleteStyles>
      <Label maxOptions={maxOptions} label={label} required={required} />
      <Autocomplete
        {...rest}
        open={open}
        options={options}
        value={value || []}
        multiple
        classes={{ popper: "dover-multiselect-pq-popper" }}
        disableCloseOnSelect
        getOptionDisabled={o => {
          let disable = false;

          const alreadySelected = !!(internalSelected ?? []).find(s => (s.value ?? s) === o.value);
          disable = alreadySelected || maxReached;

          return disable;
        }}
        onChange={(e, v) => {
          setValueSet(!(!v || v.length === 0));
          setInternalSelected(v);
          onChange(e, v);
        }}
        renderInput={params => {
          return (
            <TextField
              fullWidth
              onClick={() => {
                setOpen(!open);
              }}
              onFocus={onFocus}
              onBlur={e => {
                setOpen(false);
                onBlur(e);
              }}
              {...params}
              error={!!invalid}
              variant="outlined"
              helperText={helperText}
              InputLabelProps={{ shrink: false, style: { display: params.inputProps.value || valueSet ? "none" : "" } }}
              label={textFieldLabel}
              inputProps={{
                ...params.inputProps,
                readOnly: !isSearchable,
                placeholder: placeholder,
                style: { height: maxReached ? "0px" : "", padding: maxReached ? "0" : "" },
              }}
            />
          );
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <Checkbox
              option={option}
              selected={!!(internalSelected ?? []).find(s => (s.value ?? s) === option.value)}
            />
          </li>
        )}
        renderTags={(value, getTagProps) =>
          (value || []).map((option, index) => (
            <Tag key={index} data-tag-index={index} label={option.label} {...getTagProps({ index })} />
          ))
        }
        getOptionLabel={option => getOptionLabel(option, options)}
      />
    </AutocompleteStyles>
  );
};

MultiSelect.propTypes = {
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  maxOptions: PropTypes.number,
  TextFieldProps: PropTypes.shape({
    label: PropTypes.node,
    helperText: PropTypes.node,
    invalid: PropTypes.bool,
    required: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    isSearchable: PropTypes.bool,
    placeholder: PropTypes.node,
  }),
};

MultiSelect.defaultProps = {};

export default MultiSelect;
