import { Stack } from "@mui/material";
import { differenceInHours } from "date-fns";
import { TextField } from "mui-rff";
import React from "react";
import { Field } from "react-final-form";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

import { BillingSessionPathEnum } from "App/routing/types";
import { CopyToClipboard } from "components/CopyToClipboard";
import { Banner, BannerVariant } from "components/library/Banner";
import { Chip } from "components/library/Chip";
import { Body, BodySmall } from "components/library/typography";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { EnrichedCandidateSourceDetails } from "services/doverapi/endpoints/job-source-settings/types";
import {
  CompleteCheckoutRequestBillingSessionTypeEnum,
  JobCandidateSourceSetting,
  JobCandidateSourceSettingDesiredStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { isOneClickIntegration } from "views/job/JobBoards/components/ConfigureSource/helpers";

interface JobSourceDetailsProps {
  enrichedSourceDetails: EnrichedCandidateSourceDetails;
  showExternalJobOptions: boolean;
  showAdditionalInfoField: boolean;
  sourceSettings?: JobCandidateSourceSetting;
}

export const BILLING_SESSION_PATH_TO_SESSION_TYPE_MAP = {
  [BillingSessionPathEnum.JobBoards]: CompleteCheckoutRequestBillingSessionTypeEnum.JobBoards,
  [BillingSessionPathEnum.DefaultPaymentMethod]: CompleteCheckoutRequestBillingSessionTypeEnum.DefaultPaymentMethod,
};

type JobBoardConfig = {
  internalName: string;
  label: string;
  processingTimeHours: number;
  showProcessingMessage: boolean;
};

const JOB_BOARD_CONFIGS: { [key: string]: JobBoardConfig } = {
  LINKEDIN: {
    internalName: "LINKEDIN",
    label: "LinkedIn",
    processingTimeHours: 24,
    showProcessingMessage: true,
  },
  X_HIRING: {
    internalName: "X_HIRING",
    label: "X",
    processingTimeHours: 24,
    showProcessingMessage: true,
  },
};

const isOverrideJobBoard = (internalName: string): boolean => {
  return Object.keys(JOB_BOARD_CONFIGS).includes(internalName);
};

export const JobSourceDetails = ({
  enrichedSourceDetails,
  sourceSettings,
  showExternalJobOptions,
  showAdditionalInfoField,
}: JobSourceDetailsProps): React.ReactElement => {
  const { data: client } = useGetUsersClientQuery();
  const source = enrichedSourceDetails.candidateSource;
  const sourceIsOneClickIntegration = isOneClickIntegration(source);

  const jobBoardConfig =
    source.internalName && isOverrideJobBoard(source.internalName) ? JOB_BOARD_CONFIGS[source.internalName] : undefined;

  const isActive = sourceSettings?.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active;
  const isLinkedIn = source.internalName === "LINKEDIN";
  const isXHiring = source.internalName === "X_HIRING";

  // Calculate hours since posting if this is a job board that shows processing time
  const hoursPosted = sourceSettings?.desiredStateLastMadeActiveOn
    ? differenceInHours(new Date(), new Date(sourceSettings.desiredStateLastMadeActiveOn))
    : null;

  // Get job board URLs
  const linkedInCompanyUrl = client?.linkedinCompanyId
    ? `https://www.linkedin.com/company/${client.linkedinCompanyId}/jobs/`
    : null;
  const xHiringUrl = client?.name ? `https://x.com/jobs/?cname=${client.name}&q=${client.name}` : null;

  return (
    <Stack spacing={2}>
      {sourceIsOneClickIntegration && <Chip variant="Informational" label="ONE-CLICK POST" />}

      {/* Add timing alert - show for all active jobs */}
      {jobBoardConfig?.showProcessingMessage && isActive && sourceSettings?.desiredStateLastMadeActiveOn && (
        <Banner variant={BannerVariant.Info}>
          <Stack spacing={1}>
            {hoursPosted !== null && hoursPosted < jobBoardConfig.processingTimeHours ? (
              <>
                <div>
                  Your job posting is being processed by {jobBoardConfig.label}. It typically takes{" "}
                  {jobBoardConfig.processingTimeHours} hours to appear on {jobBoardConfig.label}.
                </div>
                <div>
                  When it&apos;s live, you&apos;ll see your posting{" "}
                  {isLinkedIn && linkedInCompanyUrl ? (
                    <ExternalLink href={linkedInCompanyUrl} target="_blank" rel="noopener noreferrer">
                      directly on LinkedIn
                    </ExternalLink>
                  ) : isXHiring && xHiringUrl ? (
                    <ExternalLink href={xHiringUrl} target="_blank" rel="noopener noreferrer">
                      directly on X
                    </ExternalLink>
                  ) : null}
                  .
                </div>
              </>
            ) : (
              <>
                <div>Your job posting should now be live.</div>
                <div>
                  You can view your job{" "}
                  {isLinkedIn && linkedInCompanyUrl ? (
                    <ExternalLink href={linkedInCompanyUrl} target="_blank" rel="noopener noreferrer">
                      directly on LinkedIn
                    </ExternalLink>
                  ) : isXHiring && xHiringUrl ? (
                    <ExternalLink href={xHiringUrl} target="_blank" rel="noopener noreferrer">
                      directly on X
                    </ExternalLink>
                  ) : null}
                  . If you don&apos;t see it, please contact{" "}
                  <ExternalLink href="mailto:support@dover.com">support@dover.com</ExternalLink>.
                </div>
              </>
            )}
          </Stack>
        </Banner>
      )}

      {/* Show source information if there is a description */}
      {source.description && (
        <SourceDescription color={colors.grayscale.gray600}>{ReactHtmlParser(source.description)}</SourceDescription>
      )}

      {showExternalJobOptions && (
        <Stack>
          <div>
            <Error name={"desiredState"} />
          </div>
          {sourceSettings?.externalJobIdentifier && (
            <Stack direction="row">
              <TextField
                label={source.externalJobIdentifierLabel}
                name="externalJobIdentifier"
                disabled={true}
                variant="standard"
              />
              <CopyToClipboard
                content={(): string => sourceSettings.externalJobIdentifier ?? ""}
                label={""}
                style={{ marginTop: "10px", marginLeft: "-10px" }}
              />
            </Stack>
          )}
          {showAdditionalInfoField && source.additionalInfoFieldLabel && (
            <Stack spacing={1}>
              <BodySmall color={colors.grayscale.gray500}>{source.additionalInfoFieldLabel}</BodySmall>
              <TextField name="additionalInfo" required={true} multiline rows={3} variant="outlined" />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const Error: React.FC<{ name: string }> = ({ name }) => (
  <Field
    name={name}
    subscribe={{ error: true }}
    render={({ meta: { error } }): React.ReactElement => <StyledErrorSpan>{error}</StyledErrorSpan>}
  />
);

const SourceDescription = styled(Body)`
  white-space: pre-line;

  a {
    color: ${colors.link};
  }
`;

const StyledErrorSpan = styled.span`
  && {
    color: ${(props): string => props.theme.colors.critical.dark};
  }
`;
