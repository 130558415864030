import React from "react";

import { Chip } from "components/library/Chip";
import { Tooltip } from "components/library/Tooltip";

export const SampleChip = ({ tooltipText }: { tooltipText?: string }): React.ReactElement => {
  return (
    <Tooltip title={tooltipText ?? "We've added sample candidates for you to test out our AI sorting and filtering"}>
      <div>
        <Chip variant="Informational" label="Sample" />
      </div>
    </Tooltip>
  );
};
