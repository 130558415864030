import { Box, Stack } from "@mui/material";
import React from "react";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodyExtraSmall, BodySmall, Heading } from "components/library/typography";
import CustomModal from "components/Modal";
import { AntSwitch } from "components/StyledMuiComponents";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { usePartialUpdateSearchV3Mutation } from "services/doverapi/endpoints/search-v3/endpoints";
import { ClientDoverPlanEnum, SearchV3 } from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";

interface ToggleActivationCellProps {
  search: SearchV3;
}

export const ToggleActivationCell = ({ search }: ToggleActivationCellProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [updateSearch, { isLoading: isSearchUpdating }] = usePartialUpdateSearchV3Mutation();
  const [activeChecked, setActiveChecked] = React.useState<boolean>(!!search.active);
  const doverPlan = useGetDoverPlan();
  const calendlyUrl = useGetCalendlyUrl("app", "saap", "inactive_search_toggle");

  React.useEffect(() => {
    setActiveChecked(!!search.active);
  }, [search.active]);

  const handleToggleActivation = React.useCallback(() => {
    if (!search.id) {
      return;
    }

    setActiveChecked(!activeChecked);

    updateSearch({
      id: search.id,
      data: {
        ...search,
        active: !activeChecked,
      },
    })
      .unwrap()
      .then(() => {
        setIsModalOpen(false);
      });
  }, [activeChecked, search, updateSearch]);

  return (
    <>
      <Box padding="8px 16px">
        {/* Activation Switch */}
        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip
            title={
              doverPlan === ClientDoverPlanEnum.Free ? (
                <BodyExtraSmall color="white">
                  <ExternalLink
                    $variant="secondary"
                    color={colors.link}
                    href=""
                    onClick={(e): void => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(calendlyUrl, "_blank", "noopener noreferrer");
                    }}
                  >
                    Book a call
                  </ExternalLink>{" "}
                  to start contacting candidates
                </BodyExtraSmall>
              ) : (
                ""
              )
            }
          >
            <div
              onClick={(e): void => {
                e.stopPropagation();
              }}
            >
              <AntSwitch
                disabled={doverPlan === ClientDoverPlanEnum.Free}
                checked={activeChecked}
                onChange={(event: React.SyntheticEvent): void => {
                  event.stopPropagation();
                  if (activeChecked) {
                    setIsModalOpen(true);
                    return;
                  }
                  handleToggleActivation();
                }}
              />
            </div>
          </Tooltip>
          <BodySmall>{activeChecked ? "Active" : "Inactive"}</BodySmall>
        </Stack>
      </Box>
      <CustomModal
        open={isModalOpen}
        onClose={(): void => {
          setIsModalOpen(false);
        }}
        title={<Heading weight={"600"}>{"Deactivate search"}</Heading>}
        maxWidth={"sm"}
        omitDividers={true}
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button
              variant={ButtonVariant.Secondary}
              onClick={(event: React.MouseEvent): void => {
                setIsModalOpen(false);
                event.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.SecondaryCritical}
              onClick={(event: React.MouseEvent): void => {
                event.stopPropagation();
                handleToggleActivation();
              }}
              loading={isSearchUpdating}
            >
              Deactivate
            </Button>
          </Stack>
        }
      >
        <Body>{"Deactivating this search will stop all outreach from going out"}</Body>
      </CustomModal>
    </>
  );
};
