/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20054
 */
export interface InlineResponse20054 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20054
     */
    meetingType?: string;
}

export function InlineResponse20054FromJSON(json: any): InlineResponse20054 {
    return InlineResponse20054FromJSONTyped(json, false);
}

export function InlineResponse20054FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20054 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meetingType': !exists(json, 'meeting_type') ? undefined : json['meeting_type'],
    };
}

export function InlineResponse20054ToJSON(value?: InlineResponse20054 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meeting_type': value.meetingType,
    };
}


