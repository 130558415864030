import Box from "@mui/material/Box";
import { TextField } from "mui-rff";
import React from "react";
import { Form } from "react-final-form";

import { GrayButton, PrimaryButton } from "components/Button";
import DoverModal from "components/Modal";
import { Spacer } from "components/Spacer";
import { useCreateProUserMutation } from "services/doverapi/endpoints/proUser";
import { DoverUser } from "services/openapi";
import { B2_doNotUse } from "styles/typography";

interface FormData {
  firstName?: string;
  lastName?: string;
  email?: string;
}

const validate = (values: FormData): any => {
  let errors = {};
  if (!values.firstName) {
    errors = { ...errors, firstName: "This field is required" };
  }
  if (!values.lastName) {
    errors = { ...errors, lastName: "This field is required" };
  }
  if (!values.email) {
    errors = { ...errors, email: "This field is required" };
  }
  return errors;
};

interface Props {
  isOpen: boolean;
  handleClose: Function;
}

const AddHiringManagerModal = ({ isOpen, handleClose }: Props): React.ReactElement => {
  const [createProUser] = useCreateProUserMutation();

  const onSubmit = async (values: FormData): Promise<void> => {
    const newHiringManager: DoverUser = await createProUser({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    }).unwrap();

    handleClose(newHiringManager);
  };

  return (
    <DoverModal title="Add a new hiring manager" open={isOpen} onClose={(): void => handleClose()} maxWidth="xs">
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting }): React.ReactNode => (
          <form onSubmit={handleSubmit} noValidate={true}>
            <B2_doNotUse medium>First name</B2_doNotUse>
            <Spacer height="16px" />
            <TextField
              disabled={submitting}
              id="firstName"
              name="firstName"
              variant="outlined"
              fullWidth
              required
              placeholder="Name"
              autoFocus
            />
            <Spacer height="24px" />
            <B2_doNotUse medium>Last name</B2_doNotUse>
            <Spacer height="16px" />
            <TextField disabled={submitting} name="lastName" variant="outlined" fullWidth required placeholder="Name" />
            <Spacer height="24px" />
            <B2_doNotUse medium>Email</B2_doNotUse>
            <Spacer height="16px" />
            <TextField
              disabled={submitting}
              name="email"
              variant="outlined"
              fullWidth
              required
              placeholder="Email"
              helperText="Please enter their work email that will be associated with Dover"
            />

            <Spacer height="42px" />

            <Box display="flex" justifyContent="flex-end">
              <GrayButton onClick={(): void => handleClose()}>
                <B2_doNotUse>Cancel</B2_doNotUse>
              </GrayButton>
              <Spacer width="8px" />
              <PrimaryButton type="submit" disabled={submitting}>
                <B2_doNotUse>{submitting ? "Creating..." : "Create"}</B2_doNotUse>
              </PrimaryButton>
            </Box>
          </form>
        )}
      />
    </DoverModal>
  );
};

export default AddHiringManagerModal;
