import styled from "styled-components";

import { colors } from "styles/theme";

interface TextProps {
  $color?: string;
}

export const PageTitle = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 24px;
  color: ${colors.grayscale.gray700};
  display: inline-flex;
`;

export const SideNavHeader = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
`;

export const H2 = styled.div<TextProps>`
  color: ${({ $color }): string => $color ?? colors.grayscale.gray700};
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

export const SectionHeader = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120.5%;
`;

interface CardHeaderProps {
  bold?: boolean;
}

export const CardHeader = styled.div<CardHeaderProps>`
  font-family: Inter;
  font-style: normal;
  font-weight: ${(props): any => (props.bold ? 600 : 400)};
  font-size: 18px;
  line-height: 20px;
`;

export const CardSubheader = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.grayscale.gray600};
`;
