/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidSendAtTimesRequest
 */
export interface ValidSendAtTimesRequest {
    /**
     * 
     * @type {number}
     * @memberof ValidSendAtTimesRequest
     */
    numOptions?: number;
    /**
     * 
     * @type {string}
     * @memberof ValidSendAtTimesRequest
     */
    campaignMessageRequest?: string;
}

export function ValidSendAtTimesRequestFromJSON(json: any): ValidSendAtTimesRequest {
    return ValidSendAtTimesRequestFromJSONTyped(json, false);
}

export function ValidSendAtTimesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidSendAtTimesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numOptions': !exists(json, 'num_options') ? undefined : json['num_options'],
        'campaignMessageRequest': !exists(json, 'campaign_message_request') ? undefined : json['campaign_message_request'],
    };
}

export function ValidSendAtTimesRequestToJSON(value?: ValidSendAtTimesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'num_options': value.numOptions,
        'campaign_message_request': value.campaignMessageRequest,
    };
}


