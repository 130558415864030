import { FormControl, Stack, MenuItem, Select } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { Body, Heading, Subtitle1 } from "components/library/typography";
import CustomModal from "components/Modal";
import { DeactivateJobDeactivationReasonEnum } from "services/openapi";
import { deactivateOptions } from "views/job/constants";
import { ModalKeyType } from "views/job/types";

interface DeactivateJobModalProps {
  shouldShowModal: string;
  handleCloseModal: () => void;
  handleNextModal: () => void;
  modalKey: ModalKeyType;
  jobName: string | undefined;
  deactivationReason: DeactivateJobDeactivationReasonEnum | undefined;
  setDeactivationReason: (value: DeactivateJobDeactivationReasonEnum) => void;
}

export const DeactivateJobModal = React.memo(
  ({
    shouldShowModal,
    handleCloseModal,
    handleNextModal,
    modalKey,
    jobName,
    deactivationReason,
    setDeactivationReason,
  }: DeactivateJobModalProps): React.ReactElement => {
    const deactivationReasonSelected = React.useMemo(
      () =>
        [
          DeactivateJobDeactivationReasonEnum.Hired,
          DeactivateJobDeactivationReasonEnum.DePrioritizedJobNoLongerHiring,
          DeactivateJobDeactivationReasonEnum.DePrioritizedJobStillHiring,
        ].includes(deactivationReason as DeactivateJobDeactivationReasonEnum),
      [deactivationReason]
    );

    return (
      <CustomModal
        open={shouldShowModal === modalKey}
        onClose={handleCloseModal}
        title={<Heading>{`Deactivate ${jobName ? jobName : "Job"}?`}</Heading>}
        maxWidth={"sm"}
        dialogActions={
          <Stack width={"100%"} spacing={2} direction="row" justifyContent="flex-end">
            <Button variant={ButtonVariant.Secondary} onClick={(): void => handleCloseModal()}>
              {"Cancel"}
            </Button>
            <Button
              variant={ButtonVariant.Secondary}
              disabled={!deactivationReasonSelected}
              onClick={async (): Promise<void> => {
                handleNextModal();
              }}
            >
              {"Next"}
            </Button>
          </Stack>
        }
      >
        <Stack spacing={2}>
          <Body>
            {
              "Deactivating this job will close out your search. Dover will no longer source candidates or manage those in your pipeline."
            }
          </Body>
          <Stack spacing={0.5}>
            <Subtitle1>{"Why do you want to deactivate this job?"}</Subtitle1>
            <FormControl variant="outlined" size={"small"} fullWidth={true}>
              <Select
                value={deactivationReason}
                onChange={(value): void => {
                  setDeactivationReason(value.target.value as DeactivateJobDeactivationReasonEnum);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                {Object.keys(deactivateOptions).map(deactivateOptionKey => {
                  const deactivateOption =
                    deactivateOptions[deactivateOptionKey as DeactivateJobDeactivationReasonEnum];
                  return (
                    <MenuItem key={deactivateOptionKey} value={deactivateOptionKey}>
                      {deactivateOption}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </CustomModal>
    );
  }
);
