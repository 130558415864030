import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Stack, Skeleton } from "@mui/material";
import { DataGrid, GridColDef, GridRowModel } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import { Body } from "components/library/typography";
import { useHasRole, Role } from "components/RBAC";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import {
  useGetFormattedInboundSourceExplorerRows,
  useGetSourceDisplaySectionData,
} from "services/doverapi/endpoints/job-source-settings/customHooks";
import {
  EnrichedCandidateSourceDetails,
  FormattedInboundSourceExplorerRow,
} from "services/doverapi/endpoints/job-source-settings/types";
import { colors } from "styles/theme";
import { escapeRegExp } from "utils/strings";
import { SourceCard } from "views/job/JobBoards/components/SourceCard";
import { DISCOVER_MORE_SECTION_ID } from "views/job/JobBoards/constants";
import { COLUMN_NAMES_TO_QUICK_FILTER_BY, DATA_GRID_PAGE_SIZE } from "views/job/JobBoards/constants";
import { CardProps } from "views/job/JobBoards/types";

/***** Custom Grid Components *****/

const CustomRow = (props: any): React.ReactElement => {
  return <SourceCard source={props.row} onClick={(): void => props.onClick(props.row.enrichedSourceDetails)} />;
};

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: (text: string) => void;
  value: string;
}

function EmptyState(): React.ReactElement {
  return (
    <Stack spacing={3} alignItems="center" py={4}>
      <Body weight="600">Don&apos;t see the job board you&apos;re looking for?</Body>
      <Button
        variant={ButtonVariant.Primary}
        onClick={(): void => {
          window.open("https://form.typeform.com/to/nesT1r0r", "_blank", "noopener noreferrer");
        }}
      >
        Request a new job board
      </Button>
    </Stack>
  );
}

function QuickSearchToolbar(props: QuickSearchToolbarProps): React.ReactElement {
  return (
    <Box width="100%" my={1}>
      <TextField
        text={props.value}
        onTextUpdated={props.onChange}
        placeholderText="Search for more job boards"
        fullWidth
        startAdornment={<StyledSearchIcon fontSize="small" />}
        endAdornment={
          props.value ? (
            <Button variant={ButtonVariant.Ghost} onClick={props.clearSearch}>
              <StyledClearIcon fontSize="small" />
            </Button>
          ) : (
            <></>
          )
        }
      />
    </Box>
  );
}

/***** Main Component *****/

const DiscoverCard = ({ openModalWithSource }: CardProps): React.ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "job_details", "job_boards_banner");

  const jobId = useJobIdFromUrl();
  const isAdmin = useHasRole(Role.ADMIN);

  const { data: job, isFetching: isJobFetching } = useGetJobQuery(jobId ?? skipToken);

  /* eslint-disable react-hooks/exhaustive-deps */
  const formattedInboundSourceExplorerRows =
    useGetFormattedInboundSourceExplorerRows({
      jobId: jobId,
      includeAdminPreview: isAdmin,
      // Include all sources, even if they've been enabled for the job already
      filterOutPreviouslyAddedInboundSources: false,
      includeYcWaas: true,
    }) ?? [];

  const [rows, setRows] = useState<GridRowModel<FormattedInboundSourceExplorerRow>[]>(
    formattedInboundSourceExplorerRows
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setRows(formattedInboundSourceExplorerRows);
  }, [formattedInboundSourceExplorerRows]);

  const requestSearch = useCallback(
    (searchValue: string): void => {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = formattedInboundSourceExplorerRows.filter(row => {
        return Object.keys(row).some(field => {
          if (COLUMN_NAMES_TO_QUICK_FILTER_BY.includes(field)) {
            // @ts-ignore
            return searchRegex.test(row[field].toString());
          }
          return false;
        });
      });
      setRows(filteredRows);
    },
    [setSearchText, formattedInboundSourceExplorerRows, setRows]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 350,
        hide: true,
        disableColumnMenu: true,
      },
    ],
    [openModalWithSource, colors]
  );

  const { isFetching } = useGetSourceDisplaySectionData({ jobId, includeAdminPreview: false });

  if (isJobFetching) {
    return <Skeleton width="100%" height="120px" />;
  }

  if (job?.isPrivate) {
    return (
      <Card id={DISCOVER_MORE_SECTION_ID}>
        <Banner variant={BannerVariant.Warning}>
          <Stack spacing={1}>
            <Body weight="600">Private Job</Body>
            <Body>
              Job boards are not available for private jobs. To enable job board posting, please change this job&apos;s
              privacy setting to Visible.
            </Body>
          </Stack>
        </Banner>
      </Card>
    );
  }

  return (
    <Card id={DISCOVER_MORE_SECTION_ID}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%">
          <Body weight="600">All job boards</Body>
        </Stack>
        <Banner variant={BannerVariant.Info}>
          <i>Not sure where to post your job?</i>{" "}
          <Button
            removePadding
            variant={ButtonVariant.Link}
            onClick={(): void => {
              window.open(calendlyUrl, "_blank", "noopener noreferrer");
            }}
          >
            Talk to a recruiting expert
          </Button>{" "}
        </Banner>
        {isFetching ? (
          <Skeleton width="100%" height="120px" />
        ) : (
          <>
            <QuickSearchToolbar
              value={searchText}
              onChange={(text: string): void => requestSearch(text)}
              clearSearch={(): void => requestSearch("")}
            />
            {rows.length === 0 ? (
              <EmptyState />
            ) : (
              <SourcesDataGrid
                components={{
                  Row: CustomRow,
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                disableSelectionOnClick={true}
                rowHeight={74}
                autoHeight
                sortingOrder={["desc", "asc", null]}
                hideFooter={rows.length <= DATA_GRID_PAGE_SIZE}
                componentsProps={{
                  row: {
                    onClick: (sourceDetails: EnrichedCandidateSourceDetails): void => {
                      openModalWithSource(sourceDetails);
                    },
                  },
                }}
              />
            )}
          </>
        )}
      </Stack>
    </Card>
  );
};

export default DiscoverCard;

/***** Styled Grid *****/

const SourcesDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root {
    border: none;
    box-shadow: none;
  }

  .MuiDataGrid-virtualScroller {
    border: 1px solid ${colors.grayscale.gray200};
    margin-top: 0 !important;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    width: 100%;
  }

  .MuiDataGrid-columnsContainer {
    display: none;
  }

  /* Footer styling */
  &.MuiDataGrid-root .MuiDataGrid-footerContainer {
    border: 1px solid ${colors.grayscale.gray200};
    border-top: none;
    border-radius: 0 0 6px 6px;
    background-color: ${colors.grayscale.gray100};
    justify-content: center;
    min-height: auto;
  }
`;

const StyledClearIcon = styled(ClearIcon)`
  fill: ${colors.grayscale.gray400} !important;
  color: ${colors.grayscale.gray400} !important;
`;

const StyledSearchIcon = styled(SearchIcon)`
  fill: ${colors.grayscale.gray400} !important;
  color: ${colors.grayscale.gray400} !important;
  margin-right: 12px;
`;
