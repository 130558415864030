/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtensionCandidateHistory
 */
export interface ExtensionCandidateHistory {
    /**
     * 
     * @type {string}
     * @memberof ExtensionCandidateHistory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCandidateHistory
     */
    jobId: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCandidateHistory
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCandidateHistory
     */
    readonly sourcingContext?: ExtensionCandidateHistorySourcingContextEnum;
    /**
     * 
     * @type {Date}
     * @memberof ExtensionCandidateHistory
     */
    readonly sourcingDate?: Date;
}

/**
* @export
* @enum {string}
*/
export enum ExtensionCandidateHistorySourcingContextEnum {
    Referral = 'REFERRAL',
    ManuallyAdded = 'MANUALLY_ADDED',
    Outbound = 'OUTBOUND',
    Inbound = 'INBOUND',
    Dtn = 'DTN',
    Agency = 'AGENCY'
}

export function ExtensionCandidateHistoryFromJSON(json: any): ExtensionCandidateHistory {
    return ExtensionCandidateHistoryFromJSONTyped(json, false);
}

export function ExtensionCandidateHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionCandidateHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'jobId': json['job_id'],
        'jobTitle': json['job_title'],
        'sourcingContext': !exists(json, 'sourcing_context') ? undefined : json['sourcing_context'],
        'sourcingDate': !exists(json, 'sourcing_date') ? undefined : (new Date(json['sourcing_date'])),
    };
}

export function ExtensionCandidateHistoryToJSON(value?: ExtensionCandidateHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'job_id': value.jobId,
        'job_title': value.jobTitle,
    };
}


