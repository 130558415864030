import { Box, MenuItem, Select, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";

import {
  feedbackTemplateDrawerConfigAtom,
  feedbackTemplateNameAtom,
  selectedIRTAtom,
  selectedPersonaAtom,
  setPersonaAtom,
  setQuestionsAtom,
  userDefinedQuestionsAtom,
} from "components/dover/FeedbackTemplates/atoms";
import { DrawerFooter } from "components/dover/FeedbackTemplates/DrawerHeaderFooter";
import { FeedbackTemplateDrawerAtom } from "components/dover/FeedbackTemplates/FeedbackTemplateDrawer";
import { useReset } from "components/dover/FeedbackTemplates/hooks";
import InnerForm from "components/dover/FeedbackTemplates/InnerForm";
import { FeedbackTemplateQuestion } from "components/dover/FeedbackTemplates/types";
import { PersonasAutocomplete } from "components/dover/PersonasAutocomplete";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import {
  useCreateFeedbackTemplateMutation,
  useListInterviewRubricTemplatesForPersonaQuery,
} from "services/doverapi/endpoints/feedbackTemplates";

interface CreateFormTemplateProps {
  validateOnClose: () => void;
  fromScratch: boolean;
}

/**
 * @description Form for creating a new FeedbackTemplate
 *
 * Form state is tracked with Jotai.
 *
 */
const CreateFeedbackTemplateForm: React.FC<CreateFormTemplateProps> = ({ validateOnClose, fromScratch }) => {
  // Jotai Form State
  const selectedPersona = useAtomValue(selectedPersonaAtom);
  const setSelectedPersona = useSetAtom(setPersonaAtom);
  const [selectedIRTid, setSelectedIRTid] = useAtom(selectedIRTAtom);
  const [feedbackTemplateName, setFeedbackTemplateName] = useAtom(feedbackTemplateNameAtom);
  const { onCreateCallback } = useAtomValue(feedbackTemplateDrawerConfigAtom);

  // Jotai Question State
  const userDefinedQuestions = useAtomValue(userDefinedQuestionsAtom);
  const setUserDefinedQuestions = useSetAtom(setQuestionsAtom);

  const resetAtomState = useReset();
  const { close: onClose } = useModal(FeedbackTemplateDrawerAtom);

  // Mutation
  const [createFeedbackTemplate] = useCreateFeedbackTemplateMutation();

  // Fetch IRTs for selected persona
  const {
    data: interviewRubricTemplatesForPersona,
    isFetching: isFetchingTemplates,
  } = useListInterviewRubricTemplatesForPersonaQuery(selectedPersona ? { personaId: selectedPersona } : skipToken);

  useEffect(
    () => {
      // Autoselect the first IRT if there is only one
      if (interviewRubricTemplatesForPersona && interviewRubricTemplatesForPersona.length === 1) {
        setSelectedIRTid(interviewRubricTemplatesForPersona[0].id);
      }
    },
    // Only trigger this effect when the interviewRubricTemplatesForPersona changes
    [interviewRubricTemplatesForPersona, setSelectedIRTid]
  );

  useEffect(() => {
    if (selectedIRTid) {
      const selectedIRT = interviewRubricTemplatesForPersona?.find(template => template.id === selectedIRTid);
      if (selectedIRT === undefined) {
        return;
      }
      if (feedbackTemplateName !== selectedIRT.name) {
        // Set the default Feedback Template Name to the IRT name
        setFeedbackTemplateName(selectedIRT.name);
      }
      // Set the default questions to the IRT userDefinedQuestions
      if (selectedIRT.userDefinedQuestions) {
        setUserDefinedQuestions(selectedIRT.userDefinedQuestions as FeedbackTemplateQuestion[]);
      }
    }
    // Only trigger this effect when the selected IRT changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIRTid]);

  const onCreateFeedbackTemplate = async (): Promise<void> => {
    if (feedbackTemplateName && userDefinedQuestions) {
      const validQuestions = userDefinedQuestions.filter(q => q.label.trim() !== "");

      const createdTemplateDetail = await createFeedbackTemplate({
        data: { name: feedbackTemplateName, questions: validQuestions },
      }).unwrap();
      onCreateCallback?.(createdTemplateDetail);
    }
    resetAtomState();
    onClose();
  };

  if (fromScratch) {
    return (
      <Box height="100%" overflow="auto">
        <Stack spacing={2}>
          <InnerForm />
          <DrawerFooter onClose={validateOnClose} onSave={onCreateFeedbackTemplate} />
        </Stack>
      </Box>
    );
  }

  return (
    <Box height="100%" overflow="auto">
      <Stack spacing={2}>
        <Stack spacing={1}>
          <BodySmall weight="600">Select role type</BodySmall>
          <PersonasAutocomplete
            title="Select role type"
            defaultValue={selectedPersona}
            onChange={setSelectedPersona}
            hideBanner
            hideHelpText
            hideLabel
          />
        </Stack>
        {isFetchingTemplates && <Skeleton variant="rectangular" height="50px" />}
        {!isFetchingTemplates && interviewRubricTemplatesForPersona && (
          <>
            <Stack spacing={1}>
              <BodySmall weight="600">Select a template</BodySmall>
              <Select
                defaultValue={interviewRubricTemplatesForPersona[0].id}
                value={selectedIRTid}
                onChange={(e): void => setSelectedIRTid(e.target.value)}
                size="small"
              >
                {interviewRubricTemplatesForPersona.map(template => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
                {interviewRubricTemplatesForPersona.length === 1 && (
                  <>
                    <MenuItem key="second-round-template-placeholder" value={undefined} disabled>
                      {`[Second round] Template Coming Soon`}
                    </MenuItem>
                    <MenuItem key="third-round-template-placeholder" value={undefined} disabled>
                      {`[Third round] Template Coming Soon`}
                    </MenuItem>
                  </>
                )}
              </Select>
            </Stack>
            {selectedIRTid && <InnerForm />}
          </>
        )}
        <DrawerFooter onClose={validateOnClose} onSave={onCreateFeedbackTemplate} />
      </Stack>
    </Box>
  );
};

export default CreateFeedbackTemplateForm;
