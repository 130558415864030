import { Progress } from "@doverhq/dover-ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import { CLIENT_ID_QUERY_PARAM } from "App/appConstants";
import { Body, Heading } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useGetCareersPageClientQuery } from "services/doverapi/endpoints/careersPageClient";
import { CareersPageClientDoverPlanEnum } from "services/openapi";
import { colors } from "styles/theme";
import { generateRandomizedImageUrl } from "utils/image";
import { CandidateForm } from "views/AgencyForm/CandidateForm";
import { SuccessScreen } from "views/AgencyForm/SuccessScreen";
import { SubmitAgencyCandidateFormSchemaType, submitAgencyCandidateFormSchema } from "views/AgencyForm/types";
import { StyledLogo } from "views/inboundExternal/styles";
import { useGetReferrerQuery, useListReferrerJobsQuery } from "views/referralsV2/endpoints";
import Unauthorized from "views/Unauthorized";

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${colors.grayscale.gray025}};
  padding-top: 24px;
  min-height: 1100px;
`;

const AgencyForm = (): React.ReactElement => {
  const { referrerId } = useParams<{
    referrerId: string;
  }>();
  const [clientIdParam] = useQueryParam(CLIENT_ID_QUERY_PARAM, StringParam);

  const formMethods = useForm<SubmitAgencyCandidateFormSchemaType>({
    resolver: zodResolver(submitAgencyCandidateFormSchema),
    defaultValues: {
      referrerId: referrerId,
    },
    mode: "onChange",
  });

  const { data: referrer, isFetching: fetchingReferrer } = useGetReferrerQuery(
    referrerId ? { id: referrerId } : skipToken
  );

  const clientId = clientIdParam || referrer?.clientId;

  const { data: client, isFetching: fetchingClient, isError } = useGetCareersPageClientQuery(
    clientId ? { clientId } : skipToken
  );

  const { data: jobs, isFetching: fetchingJobs } = useListReferrerJobsQuery(
    referrerId ? { id: referrerId } : skipToken
  );

  const jobSelectorJobs =
    jobs?.map(job => ({
      id: job.id!,
      title: job.title!,
      client: "",
      isSample: false,
    })) || [];

  // local state
  const [page, setPage] = useState(0);

  // error and loading states
  if (isError) {
    return <Navigate to="/NotFound" />;
  }

  if (fetchingClient || fetchingJobs || fetchingReferrer) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  if (client?.doverPlan === CareersPageClientDoverPlanEnum.NoAccess) {
    return <Unauthorized errorCodeOverride={CareersPageClientDoverPlanEnum.NoAccess} />;
  }

  // render
  return (
    <>
      <PageHelmet noIndex title="Submit Candidate" />

      <PageWrapper>
        <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
          <Stack mb={2} alignItems={"center"} justifyContent={"center"} spacing={1}>
            {client?.logo && (
              <Stack
                sx={{
                  cursor: client?.careersPageLogoRedirectUrl ? "pointer" : undefined,
                }}
                onClick={(): void => {
                  if (client?.careersPageLogoRedirectUrl) {
                    window.open(client?.careersPageLogoRedirectUrl, "_blank");
                  }
                }}
              >
                <StyledLogo src={generateRandomizedImageUrl(client?.logo)} alt=" url" />
              </Stack>
            )}
            <Heading>{client?.name}</Heading>
            <Stack alignItems={"center"} justifyContent={"center"}>
              <Body weight="600">
                {referrer?.name} - {referrer?.agencyName}
              </Body>
            </Stack>
          </Stack>

          <FormProvider {...formMethods}>
            <Stack spacing={2} width="430px">
              {page === 0 && (
                <CandidateForm
                  jobs={jobSelectorJobs}
                  onNext={(): void => {
                    setPage(1);
                  }}
                />
              )}
              {page === 1 && (
                <SuccessScreen
                  addAnother={(): void => {
                    setPage(0);
                  }}
                />
              )}
            </Stack>
          </FormProvider>
        </Stack>
      </PageWrapper>
    </>
  );
};

export default AgencyForm;
