import React from "react";
import { useField, useForm } from "react-final-form";

import { TimezonePicker } from "components/inputs/TimezonePicker/TimezonePicker";

interface Props {
  label: string;
  initialValue: string;
  setIsDirty: (value: boolean) => void;
}

const RFFTimezonePicker = ({ initialValue, setIsDirty }: Props): React.ReactElement => {
  const form = useForm();
  const timezonePickerField = useField<string>("timezone");

  const onUpdateTimezone = (timezone: any): void => {
    setIsDirty(true);
    form.change(timezonePickerField.input.name, timezone);
  };

  return <TimezonePicker initialValue={initialValue} update={onUpdateTimezone} />;
};

export default RFFTimezonePicker;
