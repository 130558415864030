import { Box, Skeleton, Stack, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { ReactSVG } from "react-svg";
import { useBoolean } from "react-use";
import styled from "styled-components";

import { ReactComponent as DownCaretSVG } from "assets/icons/caret-down-black.svg";
import DoverIcon from "assets/logos/DoverD.svg";
import { TextButton } from "components/Button";
import { LoadingRelative } from "components/HotLoading";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Overline } from "components/library/typography";
import CustomModal from "components/Modal";
import { useNextInterviewer } from "components/NextInterviewer/hooks";
import InterviewerCreateModal from "components/NextInterviewer/InterviewerCreateModal";
import { useInterviewerOptions } from "components/NextInterviewer/useInterviewerOptions";
import { useNewInterviewer } from "components/NextInterviewer/useNewInterviewer";
import {
  DOVER_INTERVIEWER,
  NextInterviewerHiringPipelineStage,
} from "components/NextInterviewer/usePossibleInterviewers";
import { InterviewerOption } from "domains/candidate/types";
import { useChangeInterviewerMutation, useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { HiringPipelineStageMilestone, SubstageType } from "services/openapi";
import { colors } from "styles/theme";
import { getFullNameInitials, trimLastNameOfFullName, truncate } from "utils/strings";
import { useJob } from "views/candidates/hooks";

export const NextInterviewer = ({
  candidateId,
  desiredHiringPipelineStage,
}: {
  candidateId: string | undefined;
  desiredHiringPipelineStage?: NextInterviewerHiringPipelineStage;
}): React.ReactElement => {
  const { data: candidateBio, isLoading: loadingCandidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const [changeInterviewer] = useChangeInterviewerMutation();
  const [selectedNextInterviewerState, setSelectedNextInterviewerState] = useState<InterviewerOption | undefined>(
    undefined
  );
  const job = useJob();

  const {
    options: { data: interviewerOptions, isFetching: isFetchingInterviewers },
  } = useInterviewerOptions({
    desiredHiringPipelineStage,
  });

  const { showModal, setShowModal, createInterviewerFn } = useNewInterviewer({
    setInterviewer: interviewer => {
      setSelectedNextInterviewerState({
        label: interviewer.label,
        value: interviewer.value ?? "",
      });
    },
    selectedInterviewer: selectedNextInterviewerState,
  });

  const interviewStage = desiredHiringPipelineStage;
  const isInitialReview = interviewStage?.milestone === HiringPipelineStageMilestone.INITIAL_CALL;
  const interviewStageHasDIEnabled = candidateBio?.nextAction?.nextInterview?.hasDoverInterviewer;

  const { nextInterviewer, nextInterviewerValid } = useNextInterviewer(candidateBio);

  const shouldAppendDoverInterviewerToOptions = useMemo(() => {
    return isInitialReview && !!interviewStageHasDIEnabled;
  }, [isInitialReview, interviewStageHasDIEnabled]);

  const [isModalOpen, toggleModal] = useBoolean(false);
  const [changeInterviewerLoading, toggleChangeInterviewerLoading] = useBoolean(false);

  const defaultName = shouldAppendDoverInterviewerToOptions ? "Dover" : "Select";

  const noChangeDoverInterviewer = !nextInterviewer?.id && selectedNextInterviewerState?.value === "DOVER_INTERVIEWER";

  const changeInterviewerDisabled =
    !selectedNextInterviewerState?.value ||
    noChangeDoverInterviewer ||
    selectedNextInterviewerState?.value === nextInterviewer?.id;

  useEffect(() => {
    if (selectedNextInterviewerState || !candidateBio?.id || !interviewerOptions) return;

    if (shouldAppendDoverInterviewerToOptions && !nextInterviewer) {
      setSelectedNextInterviewerState({
        label: DOVER_INTERVIEWER.email,
        value: "DOVER_INTERVIEWER",
      });
      return;
    }

    const initialInterviewer = interviewerOptions?.find(io => !!io.email && io.email === nextInterviewer?.email);
    if (initialInterviewer) {
      setSelectedNextInterviewerState({
        label: (initialInterviewer.label || initialInterviewer.email) ?? "",
        value: initialInterviewer.value ?? "",
      });
    }
  }, [
    shouldAppendDoverInterviewerToOptions,
    nextInterviewer,
    candidateBio,
    selectedNextInterviewerState,
    interviewerOptions,
  ]);

  const submitChangeInterviewer = async (): Promise<void> => {
    const id = candidateBio?.id;
    if (!id || !interviewStage?.id || !selectedNextInterviewerState?.value) return;

    toggleChangeInterviewerLoading();

    const interviewersById = _.keyBy(interviewerOptions, "value");
    const interviewer = interviewersById[selectedNextInterviewerState?.value];

    try {
      await changeInterviewer({
        candidateId: id,
        interviewerId: selectedNextInterviewerState?.value,
        hiringPipelineStageId: interviewStage.id,
        interviewerFullName: interviewer?.fullName || defaultName,
      }).unwrap();
    } catch (error) {
      setSelectedNextInterviewerState({ label: "", value: "" });
    } finally {
      toggleChangeInterviewerLoading();
      toggleModal();
    }
  };

  const interviewerHasFullName = nextInterviewer?.fullName?.length;
  const interviewNameOrEmail = interviewerHasFullName
    ? trimLastNameOfFullName(nextInterviewer?.fullName ?? "")
    : nextInterviewer
    ? nextInterviewer?.email
    : undefined;

  const trimmedCurrentInterviewerName = interviewNameOrEmail ? truncate(interviewNameOrEmail, 10) : defaultName;

  // multipart interviews do not use a single next interviewer
  if (candidateBio?.nextAction?.nextHiringPipelineStage?.contentTypeName !== SubstageType.INTERVIEW_STAGE) {
    return <></>;
  }

  if (loadingCandidateBio || !candidateBio || !interviewStage) {
    return <></>;
  }

  const doverInterviewerSelected = selectedNextInterviewerState?.value === "DOVER_INTERVIEWER";

  const interviewerIcon = (
    <InterviewerCircle>
      {doverInterviewerSelected ? <ReactSVG src={DoverIcon} /> : getFullNameInitials(nextInterviewer?.fullName || "?")}
    </InterviewerCircle>
  );

  return (
    <Box flex-shrink={0} marginRight="8px">
      <CustomModal
        title="Change Interviewer"
        maxWidth="xs"
        open={isModalOpen}
        onClose={toggleModal}
        customDialogStyles={{ overflowY: "visible" }}
        dialogActions={
          <Button
            variant={ButtonVariant.Primary}
            loading={changeInterviewerLoading}
            disabled={changeInterviewerDisabled}
            onClick={submitChangeInterviewer}
          >
            Submit
          </Button>
        }
      >
        {!isFetchingInterviewers && interviewerOptions ? (
          <Autocomplete
            id="application-review-select-interviewer"
            value={selectedNextInterviewerState}
            options={interviewerOptions}
            getOptionLabel={(interviewer): string => interviewer?.label ?? ""}
            onChange={(event: any, newOption: any): void => setSelectedNextInterviewerState(newOption)}
            renderInput={(params: any): React.ReactElement => <TextField {...params} label="Interviewer" />}
            disableClearable
            renderOption={(props, option): React.ReactElement => (
              // @ts-ignore
              <Stack {...props} direction="row" width="100%">
                <BodySmall style={{ flexGrow: 1 }}>{option.label}</BodySmall>
                {option.isDefaultInterviewer && <BodySmall color={colors.grayscale.gray500}>Default</BodySmall>}
              </Stack>
            )}
          />
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <LoadingRelative />
          </Box>
        )}
      </CustomModal>
      <Stack style={{ display: "flex", flexDirection: "column" }}>
        <Overline color={colors.black}>{"Next Interviewer"}</Overline>
        {isFetchingInterviewers ? (
          <Skeleton height="25px" width={"100px"} />
        ) : (
          <Tooltip
            title={
              nextInterviewerValid
                ? "Change interviewer"
                : nextInterviewer
                ? `${interviewNameOrEmail} has incomplete interviewer preferences`
                : "Select interviewer"
            }
            placement="top"
          >
            <StyledButton onClick={toggleModal}>
              <>
                {interviewerIcon}
                <Box display="flex" alignItems="center" marginLeft="4px">
                  <BodySmall>{trimmedCurrentInterviewerName}</BodySmall>
                  <DownCaretSVG width="30px" />
                </Box>
              </>
            </StyledButton>
          </Tooltip>
        )}
      </Stack>
      <InterviewerCreateModal
        showModal={showModal}
        onCloseModal={(): void => setShowModal(false)}
        createInterviewer={createInterviewerFn}
        interviewers={interviewerOptions}
        validateEmail={(): boolean => true}
        creating={showModal}
        hiringManagerEmail={job?.hiringManager?.email}
      />
    </Box>
  );
};

const StyledButton = styled(TextButton)`
  &.MuiButtonBase-root {
    justify-content: start;
    white-space: nowrap;
    min-width: auto;
    color: ${colors.grayscale.gray600};
    padding: 0;
    line-height: 1;
    &:hover {
      background-color: transparent;
    }
  }
`;

const InterviewerCircle = styled(Box)`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: solid 1px ${colors.grayscale.gray200};
  line-height: 24px;
  font-size: 12px;
  text-transform: uppercase;
  background-color: ${colors.white};
  svg {
    width: 18px;
    height: 18px;
    position: relative;
    top: -1px;
  }
`;
