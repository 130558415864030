import { EditorContent } from "@tiptap/react";
import React from "react";

import { useInitialEditorSetup } from "components/library/TipTap/hooks";
import { CommonEditorProps } from "components/library/TipTap/types";

import "components/library/TipTap/TipTapEditorStyles.scss";

const RawEditor = ({
  initialContent,
  onContentChange,
  placeholder,
  readOnly,
  customExtensions,
  onModEnter,
}: CommonEditorProps): React.ReactElement => {
  const editor = useInitialEditorSetup({
    initialContent,
    onContentChange,
    placeholder,
    readOnly,
    customExtensions,
    onModEnter,
  });

  return <EditorContent editor={editor} className="EditorContainer RawEditorContainer" />;
};

export default RawEditor;
