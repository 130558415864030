/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateBioAts
 */
export interface CandidateBioAts {
    /**
     * 
     * @type {string}
     * @memberof CandidateBioAts
     */
    type: CandidateBioAtsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CandidateBioAts
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateBioAts
     */
    stageName: string;
    /**
     * 
     * @type {Date}
     * @memberof CandidateBioAts
     */
    lastSyncedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum CandidateBioAtsTypeEnum {
    Email = 'EMAIL',
    Phone = 'PHONE'
}

export function CandidateBioAtsFromJSON(json: any): CandidateBioAts {
    return CandidateBioAtsFromJSONTyped(json, false);
}

export function CandidateBioAtsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateBioAts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'url': json['url'],
        'stageName': json['stage_name'],
        'lastSyncedAt': (json['last_synced_at'] === null ? null : new Date(json['last_synced_at'])),
    };
}

export function CandidateBioAtsToJSON(value?: CandidateBioAts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'url': value.url,
        'stage_name': value.stageName,
        'last_synced_at': (value.lastSyncedAt === null ? null : value.lastSyncedAt.toISOString().substr(0,10)),
    };
}


