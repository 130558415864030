import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { colors, backgrounds } from "styles/theme";

/* Animations */

const loadInAnimation = keyframes`
 0% { height: 0px; opacity: 0; }
 100% { height: 36px; opacity: 1; }
`;

/* Styles */

interface BackgroundProps {
  backgroundImage: string;
}

export const Background = styled.div<BackgroundProps>`
  width: 100%;
  height: 220px;
  background-repeat: repeat;
  background-color: ${backgrounds.darkBlueHome};
  background-image: ${(props): string => `url(${props.backgroundImage})`};
  z-index: -1;
`;

/* Job Card Styles */

export const JobCardContainer = styled.div`
  margin: 4px 0px;
  border: 1px solid ${colors.grayscale.gray200};
  border-radius: 4px;
`;

interface StyledSetupRowProps {
  disabled?: boolean;
}

export const StyledSetupRow = styled.div<StyledSetupRowProps>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => (props.disabled ? colors.grayscale.gray300 : colors.warning.light)};

  color: ${colors.warning.dark};
  justify-content: space-between;
  transition: height 0.5s ease;
  height: 0px;
  animation: ${loadInAnimation} 0.5s ease 1;
  height: 36px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;

  &:hover {
    cursor: pointer;
    .arrow {
      transform: translateX(4px);
    }
  }
`;

export const StyledNextActionButton = styled.div`
  margin: 0px 16px;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 6px;
`;

/* Banner Styles */

export const StyledBanner = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: inherit;
  swidth: 100%;
  background-color: white;
  border-radius: 6px;
  padding: 16px;

  &:hover {
    text-decoration: none;
    color: inherit;
    background-color: ${backgrounds.darkBlue};

    .arrow {
      transform: translateX(4px);
    }
  }
`;

interface StyledTabProps {
  $isSelected: boolean;
}

const StyledHeadingLight = styled.div`
  background-color: transparent;
  border: none;
  color: ${colors.white};
  font-weight: 500;
  text-transform: none;
  font-family: "Inter";
  padding: 8px 8px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
`;

const StyledHeadingWrapper = styled.div`
  width: 100%;
  border: none !important;
  margin-bottom: 8px;
`;

export const StyledTab = styled(StyledHeadingLight)<StyledTabProps>`
  color: ${({ $isSelected }): string => ($isSelected ? colors.primary.base : colors.grayscale.gray400)};
  border-bottom: ${({ $isSelected }): string => ($isSelected ? `2px solid ${colors.primary.base}` : "none")};
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  &:focus,
  &:hover {
    text-decoration: none;
    color: ${colors.black};
  }
`;

export const StyledTabBarWrapper = styled(StyledHeadingWrapper)`
  display: flex;
  justify-content: space-between;
`;
