import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useState } from "react";
import { useForm, useField } from "react-final-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { BodySmall } from "components/library/typography";
import {
  useListGreenhouseInterviewStagesQuery,
  useListLeverInterviewFeedbackTemplatesQuery,
} from "services/doverapi/endpoints/ats";
import { useGetCompanyAtsType } from "services/doverapi/endpoints/client/hooks";
import { AtsInterviewStage, ClientOnboardingAtsTypeEnum } from "services/openapi";
import { colors } from "styles/theme";

interface AtsInterviewFeedbackProps {
  fieldPrefix: string;
  onChange?: (value: string | null, fieldName: string) => void;
  substageAtsValue?: string;
  placeholderText?: string;
}

const AtsInterviewFeedbackField = ({
  fieldPrefix,
  onChange,
  substageAtsValue,
  placeholderText,
}: AtsInterviewFeedbackProps): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();
  const atsType = useGetCompanyAtsType();

  const atsStageId = useField<string | null>(fieldPrefix + ".atsSubstageId").input.value;
  const atsFeedbackTemplateId = useField<string | null>(fieldPrefix + ".atsFeedbackTemplateId").input.value;

  switch (atsType) {
    case ClientOnboardingAtsTypeEnum.Lever:
      return (
        <LeverFeedbackTemplateField
          jobId={jobId}
          fieldPrefix={fieldPrefix}
          initialValue={substageAtsValue || atsFeedbackTemplateId}
          onChange={onChange}
          placeholderText={placeholderText}
        />
      );
    case ClientOnboardingAtsTypeEnum.Greenhouse:
      return (
        <GreenhouseInterviewStageField
          jobId={jobId}
          fieldPrefix={fieldPrefix}
          initialValue={substageAtsValue || atsStageId}
          onChange={onChange}
          placeholderText={placeholderText}
        />
      );
    default:
      return <></>;
  }
};

export default AtsInterviewFeedbackField;

interface FieldProps {
  jobId?: string;
  fieldPrefix: string;
  initialValue?: string | null;
  onChange?: (value: string | null, fieldName: string) => void;
  placeholderText?: string;
}

const LeverFeedbackTemplateField = ({
  jobId,
  fieldPrefix,
  initialValue,
  onChange,
  placeholderText,
}: FieldProps): React.ReactElement => {
  const [value, setValue] = useState<string | null>(initialValue || null);
  const form = useForm();

  const { data: leverOptionsData } = useListLeverInterviewFeedbackTemplatesQuery(jobId ? jobId : skipToken);
  const leverOptions = leverOptionsData || [];

  const getSelectedOptionName = (id: string): string => {
    const option = leverOptions?.find(option => option.externalId === id);
    return option?.name || "";
  };

  const handleUpdateFeedbackTemplate = (e: SelectChangeEvent<any>): void => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value, "atsFeedbackTemplateId");
    } else {
      form.change(fieldPrefix + ".atsFeedbackTemplateId", e.target.value);
    }
  };

  return (
    <StyledSelect
      displayEmpty
      renderValue={(value: unknown): React.ReactNode =>
        value ? (
          <BodySmall>{getSelectedOptionName(value as string)}</BodySmall>
        ) : (
          <PlaceholderText>{placeholderText ?? "Interview Feedback"}</PlaceholderText>
        )
      }
      variant="outlined"
      value={value}
      onChange={handleUpdateFeedbackTemplate}
    >
      {leverOptions
        .map(option => {
          return (
            <StyledMenuItem key={option.externalId} value={option.externalId}>
              <BodySmall color={colors.black}>{option.name}</BodySmall>
            </StyledMenuItem>
          );
        })
        .concat([
          <StyledMenuItem key={-1} value="">
            <BodySmall color={colors.grayscale.gray400}>None</BodySmall>
          </StyledMenuItem>,
        ])}
    </StyledSelect>
  );
};

const GreenhouseInterviewStageField = ({
  jobId,
  fieldPrefix,
  initialValue,
  onChange,
  placeholderText,
}: FieldProps): React.ReactElement => {
  const [value, setValue] = useState<string | null>(initialValue || null);
  const form = useForm();

  const { greenhouseOptions } = useListGreenhouseInterviewStagesQuery(jobId ? jobId : skipToken, {
    selectFromResult: ({ data }) => {
      let unionOfInterviews: AtsInterviewStage[] = [];
      if (data) {
        data.forEach(jobStage => (unionOfInterviews = unionOfInterviews.concat(jobStage.interviews)));
      }
      return {
        greenhouseOptions: unionOfInterviews,
      };
    },
  });

  const getSelectedOptionName = (id: string): string => {
    const option = greenhouseOptions?.find(option => option.externalId === id);
    return option?.name || "";
  };

  const handleUpdateInterviewStage = (e: SelectChangeEvent<any>): void => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value, "atsSubstageId");
    } else {
      form.change(fieldPrefix + ".atsSubstageId", e.target.value);
    }
  };

  return (
    <StyledSelect
      displayEmpty
      renderValue={(value: unknown): React.ReactNode =>
        value ? (
          <BodySmall>{getSelectedOptionName(value as string)}</BodySmall>
        ) : (
          <PlaceholderText>{placeholderText ?? "Interview Feedback"}</PlaceholderText>
        )
      }
      variant="outlined"
      value={value}
      onChange={handleUpdateInterviewStage}
    >
      {greenhouseOptions
        .map(option => {
          return (
            <StyledMenuItem key={option.externalId} value={option.externalId}>
              <BodySmall color={colors.black}>{option.name}</BodySmall>
            </StyledMenuItem>
          );
        })
        .concat([
          <StyledMenuItem value="" key={-1}>
            <BodySmall color={colors.grayscale.gray400}>None</BodySmall>
          </StyledMenuItem>,
        ])}
    </StyledSelect>
  );
};

const PlaceholderText = styled(BodySmall)`
  color: ${(props): string => props.theme.colors.grayscale.gray400};
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex !important;
  padding: 8px !important;
  justify-content: start !important;
`;

const StyledSelect = styled(Select)`
  height: 100%;

  .MuiSelect-select {
    height: 100%;
    box-sizing: border-box;
    padding: 5px 11px 8px;
    background: white;

    input {
      height: inherit;
      box-sizing: border-box;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    height: 100%;
    top: 0;

    legend {
      display: none;
    }
  }
`;
