import { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import React, { useEffect } from "react";

import { StyledDrawer } from "components/library/Drawer/styles";
import { toggleIntercom } from "components/library/Drawer/utils";

interface DrawerProps extends MuiDrawerProps {
  children: React.ReactNode;
  shouldToggleIntercom?: boolean;
}

const Drawer = ({ children, shouldToggleIntercom, ...rest }: DrawerProps): React.ReactElement => {
  useEffect(() => {
    if (shouldToggleIntercom) {
      if (rest.open) {
        toggleIntercom(false);
        return;
      }
      toggleIntercom(true);
    }
  }, [rest.open, shouldToggleIntercom]);

  return <StyledDrawer {...rest}>{children}</StyledDrawer>;
};

export default Drawer;
