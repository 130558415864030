import { useJobId } from "hooks/useJobIdFromUrl";
import { useUpdateHiringPipelineStageV2Mutation } from "services/doverapi/endpoints/hiringPipelineStage";
import { ApiApiUpdateHiringPipelineStageOperationRequest } from "services/openapi";

// These are the args the rtkq endpoint needs
export interface UpdateHpsArgs extends ApiApiUpdateHiringPipelineStageOperationRequest {
  sourceIdx?: number;
  destinationIdx?: number;
}

// Here we omit the job id since we will grab this for the consumer of this hook
interface UseUpdateHpsInput extends Omit<UpdateHpsArgs, "jobId"> {}

/**
 * This is a wrapper around the update hiring pipeline stage rtkq endpoint.
 * It will get the jobId from the url and automatically add it to the request.
 * It also accepts the source and destination index for reordering stages with an optimistic update.
 */
// The mutation return type is pretty gnarly, so fine with the implied type
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUpdateHps = () => {
  const [jobId] = useJobId();

  const [updateHiringPipelineStage] = useUpdateHiringPipelineStageV2Mutation();

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (input: UseUpdateHpsInput) => {
    if (!jobId) {
      return;
    }

    const args = { ...input, jobId };
    return updateHiringPipelineStage(args);
  };
};
