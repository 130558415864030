/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubmitMultipartInterviewSchedulingRequestSubstage,
    SubmitMultipartInterviewSchedulingRequestSubstageFromJSON,
    SubmitMultipartInterviewSchedulingRequestSubstageFromJSONTyped,
    SubmitMultipartInterviewSchedulingRequestSubstageToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubmitMultipartInterviewSchedulingRequest
 */
export interface SubmitMultipartInterviewSchedulingRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipartInterviewSchedulingRequest
     */
    candidateId: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipartInterviewSchedulingRequest
     */
    interviewStageId: string;
    /**
     * 
     * @type {Array<SubmitMultipartInterviewSchedulingRequestSubstage>}
     * @memberof SubmitMultipartInterviewSchedulingRequest
     */
    substages: Array<SubmitMultipartInterviewSchedulingRequestSubstage>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitMultipartInterviewSchedulingRequest
     */
    deletedSubstageIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitMultipartInterviewSchedulingRequest
     */
    canceledSubstageIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipartInterviewSchedulingRequest
     */
    locationOverride?: string | null;
}

export function SubmitMultipartInterviewSchedulingRequestFromJSON(json: any): SubmitMultipartInterviewSchedulingRequest {
    return SubmitMultipartInterviewSchedulingRequestFromJSONTyped(json, false);
}

export function SubmitMultipartInterviewSchedulingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitMultipartInterviewSchedulingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
        'interviewStageId': json['interview_stage_id'],
        'substages': ((json['substages'] as Array<any>).map(SubmitMultipartInterviewSchedulingRequestSubstageFromJSON)),
        'deletedSubstageIds': json['deleted_substage_ids'],
        'canceledSubstageIds': json['canceled_substage_ids'],
        'locationOverride': !exists(json, 'location_override') ? undefined : json['location_override'],
    };
}

export function SubmitMultipartInterviewSchedulingRequestToJSON(value?: SubmitMultipartInterviewSchedulingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'interview_stage_id': value.interviewStageId,
        'substages': ((value.substages as Array<any>).map(SubmitMultipartInterviewSchedulingRequestSubstageToJSON)),
        'deleted_substage_ids': value.deletedSubstageIds,
        'canceled_substage_ids': value.canceledSubstageIds,
        'location_override': value.locationOverride,
    };
}


