import { TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { B2_doNotUse } from "styles/typography";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    width: 900px;
    box-sizing: border-box;
    padding: 11px;
    font-size: 14px;
    font-family: Inter;

    input {
      height: 100%;
      padding: 0;
    }
  }

  .MuiFormHelperText-root {
    margin-left: 2px;
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }

  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

const InterviewDetailsField = ({ ...props }: any): React.ReactElement => {
  return (
    <div>
      {!props.hideLabel && <B2_doNotUse style={{ maxWidth: "none" }}>Additional Instructions</B2_doNotUse>}
      <Spacer height={4} />
      <StyledTextField
        id="outlined-basic"
        variant="outlined"
        value={props.input.value}
        onChange={props.input.onChange}
        error={props.required && props.meta.error}
        multiline
        rows={4}
        helperText="e.g. scheduling preferences, directions to your office for an in person onsite"
        placeholder="Enter a description..."
      />
    </div>
  );
};

export default InterviewDetailsField;
