import styled from "styled-components";

import { theme } from "components/data-driven-forms/styles/theme";

export const AutocompleteStyles = styled.div`
  width: 100%;

  .Mui-focused > .MuiFormControl-root > .MuiOutlinedInput-root > fieldset {
    border-color: ${theme.colors.highlightBlue};
  }

  .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root > .MuiOutlinedInput-input {
    font-size: ${theme.inputs.default.fontSize};
    font-family: ${theme.inputs.default.fontFamily};
    font-weight: ${theme.inputs.default.fontWeight};
    line-height: ${theme.inputs.default.lineHeight};
    color: ${theme.colors.darkBlue};
  }

  .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
    ${({ hideLabel }) =>
      hideLabel &&
      `
        background-color: ${theme.backgrounds.blue};
    `}
  }

  .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-root {
    color: ${theme.colors.gray30};
    ${({ hideLabel }) =>
      hideLabel &&
      `
        display: none;
    `}
  }
  .MuiAutocomplete-option {
    background: blue;
  }

  .MuiAutocomplete-popper {
    background: blue;
  }
`;
