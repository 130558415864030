/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Checkbox, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { DEFAULT_DEBRIEF_DURATION } from "components/SetupHiringPipeline/constants";
import { getDurationOptions } from "components/SetupHiringPipeline/helpers";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

const DebriefDurationFields = ({ ...props }: any): React.ReactElement => {
  const [showDurationSelect, setShowDurationSelect] = useState<boolean>(!!props.input.value);
  const [debriefDuration, setDebriefDuration] = useState<number>(props.input.value || DEFAULT_DEBRIEF_DURATION);

  useEffect(() => {
    props.input.onChange(showDurationSelect ? debriefDuration : null);
  }, [showDurationSelect, debriefDuration]);

  const handleToggleCheckbox = (): void => {
    setShowDurationSelect(!showDurationSelect);
  };

  return (
    <div>
      <StyledBox display="flex" alignItems="center">
        <StyledCheckBox checked={showDurationSelect} onChange={handleToggleCheckbox} />
        <B2_doNotUse>Schedule Debrief</B2_doNotUse>
      </StyledBox>
      <Spacer height="8px" />
      {showDurationSelect && (
        <>
          <StyledSelect
            variant="outlined"
            style={{ background: "white" }}
            value={debriefDuration}
            // @ts-ignore
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setDebriefDuration(event.target.value)}
          >
            {getDurationOptions().map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </StyledSelect>
          <Spacer height="16px" />
        </>
      )}
    </div>
  );
};

export default DebriefDurationFields;

// TODO style StyledCheckbox and StyledSelect to spec: https://app.shortcut.com/dover/story/118595
const StyledSelect = styled(Select)`
  .MuiSelect-select {
    min-width: 116px;
    box-sizing: border-box;
    padding: 5px 11px 8px;

    input {
      height: inherit;
      box-sizing: border-box;
    }
  }

  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
  &.MuiInputBase-root {
    border: none !important;
  }
`;

const StyledBox = styled(Box)`
  .MuiBox-root {
    padding-left: 22px;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  &.MuiCheckbox-root {
    margin-left: -12px;
  }
  &.Mui-checked {
    .MuiSvgIcon-root {
      fill: ${colors.success.base};
    }
  }
`;
