import { Stack } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import ResetIcon from "assets/icons/reset.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useRegenerateSampleCandidateMutation } from "services/doverapi/endpoints/candidate/pipeline-endpoints";

export const SampleCandidateResetButton = ({ jobId }: { jobId: string | undefined }): React.ReactElement => {
  const [regenerateSampleCandidate, { isLoading: loadingSampleCandidate }] = useRegenerateSampleCandidateMutation();

  return (
    <>
      <Tooltip title={"Resets sample candidate back to Queued"}>
        <span>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={(e: React.MouseEvent<HTMLElement>): void => {
              e.stopPropagation();
              if (jobId) {
                regenerateSampleCandidate({ jobId: jobId });
              }
            }}
            disabled={loadingSampleCandidate}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {loadingSampleCandidate ? (
                <DoverLoadingSpinner spinnerSize="15px" minHeight="20px" height="20px" width="60px" />
              ) : (
                <>
                  <ReactSVG src={ResetIcon} />
                  <BodySmall style={{ whiteSpace: "nowrap" }}>Reset</BodySmall>
                </>
              )}
            </Stack>
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
