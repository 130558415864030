/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useField, useForm, useFormState } from "react-final-form";

export const CcEmailsField = ({ type }: { type: "ccEmails" | "bccEmails" }): React.ReactElement => {
  const form = useForm();
  const formState = useFormState();

  useField(type);

  const [referenceString, setReferenceString] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    const emailString = formState.values[type].join(", ");
    if (referenceString !== emailString) {
      setReferenceString(emailString);
      setValue(emailString);
    }
  }, [formState.values[type]]);

  return (
    <TextField
      sx={{
        "& .MuiInputBase-root": {
          padding: "8px",
        },
      }}
      label={type === "ccEmails" ? "Cc Emails" : "Bcc Emails"}
      value={value}
      onChange={(e): void => setValue(e.target.value)}
      onBlur={(e): void => {
        const emailStr = e.target.value;

        if (emailStr === "") {
          form.change(type, []);
        } else {
          const ccEmails = emailStr.split(",").map(email => email.trim());
          form.change(type, ccEmails);
        }
      }}
      fullWidth
    />
  );
};
