import { atom } from "jotai";

import { InterviewRubricMode } from "views/interview/CandidateInterview/types";

export const interviewRubricResponseStateAtom = atom<number | undefined>(undefined);
export const interviewRubricModeAtom = atom<InterviewRubricMode>(InterviewRubricMode.Review);

export const setInterviewRubricModeToEditAtom = atom(null, (_, set) => {
  set(interviewRubricModeAtom, InterviewRubricMode.Interview);
});
