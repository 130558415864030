import { differenceInCalendarDays, format, isThisYear, isToday, isYesterday } from "date-fns";
import moment from "moment";

export const isValidDate = (date: Date | null | undefined): boolean => {
  const isValid = date instanceof Date && !isNaN(date.getTime()) && date.getTime() > 0;
  return isValid;
};

export const getLocalDateString = (date: Date): string => {
  return moment(date)
    .local()
    .format("MMM DD, YYYY");
};

function padTo2Digits(num: number): string {
  return String(num).padStart(2, "0");
}

export const getHoursAndMinutesString = (date: Date): string => {
  return date.getHours() + ":" + padTo2Digits(date.getMinutes());
};

export const getNextTimeRoundedToNearest15 = (): Date => {
  const now = new Date().getTime();
  const minutes = 15;
  const ms = 1000 * 60 * minutes;

  // Math.ceil will always round up
  return new Date(Math.ceil(now / ms) * ms);
};

/**
 * @param {Date} date
 * @returns {string} date in human-readable format that evaluates given date against today's date
 */
export const getFormattedTimestamp = (date: Date): string => {
  // Base format: "4:15pm"
  const baseDateString = format(date, "h:mmaaa");

  if (isToday(date)) {
    return `Today at ${baseDateString}`;
  } else if (isYesterday(date)) {
    return `Yesterday at ${baseDateString}`;
  } else if (differenceInCalendarDays(new Date(), date) <= 6) {
    // If in the last week, format as "Monday at 4:15pm"
    return format(date, "EEEE 'at' h:mmaaa");
  } else if (!isThisYear(date)) {
    // If not in the current year, format as "Jan 1, 2020 at 4:15pm"
    return `${format(date, "MMM d, y")} at ${baseDateString}`;
  }
  // Otherwise, format as "Jan 1 at 4:15pm"
  return `${format(date, "MMM d")} at ${baseDateString}`;
};
