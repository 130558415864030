/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject13
 */
export interface InlineObject13 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject13
     */
    aliasUserEmail: string;
}

export function InlineObject13FromJSON(json: any): InlineObject13 {
    return InlineObject13FromJSONTyped(json, false);
}

export function InlineObject13FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject13 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aliasUserEmail': json['alias_user_email'],
    };
}

export function InlineObject13ToJSON(value?: InlineObject13 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alias_user_email': value.aliasUserEmail,
    };
}


