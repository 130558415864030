import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

export const DEFAULT_NUM_CANDS_PER_PAGE = 100;

export const pageAtom = atomWithReset(1);

export const paginationAtom = atom(get => {
  const page = get(pageAtom);

  return {
    page,
    limit: DEFAULT_NUM_CANDS_PER_PAGE,
  };
});
