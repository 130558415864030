import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import React from "react";
import { useForm } from "react-final-form";

import { baseColors } from "styles/theme";

const DeleteFileIconButton = ({ fieldName }: { fieldName: string }): React.ReactElement => {
  const form = useForm();
  return (
    <DeleteOutlinedIcon
      style={{ color: baseColors.critical }}
      onClick={(e): void => {
        form.change(fieldName, []);
        e.stopPropagation();
      }}
    />
  );
};

DeleteFileIconButton.defaultProps = { fieldName: "files" };

export { DeleteFileIconButton };
