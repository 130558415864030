/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyListParamsJson
 */
export interface CompanyListParamsJson {
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyListParamsJson
     */
    companyIds: Array<string>;
}

export function CompanyListParamsJsonFromJSON(json: any): CompanyListParamsJson {
    return CompanyListParamsJsonFromJSONTyped(json, false);
}

export function CompanyListParamsJsonFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyListParamsJson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyIds': json['company_ids'],
    };
}

export function CompanyListParamsJsonToJSON(value?: CompanyListParamsJson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_ids': value.companyIds,
    };
}


