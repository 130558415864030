import { ReactComponent as PaperIcon } from "assets/icons/call-notes.svg";
import { ReactComponent as CareerbuilderIcon } from "assets/icons/careerbuilder.svg";
import { ReactComponent as CrunchbaseIcon } from "assets/icons/crunchbase.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as FlickrIcon } from "assets/icons/flickr.svg";
import { ReactComponent as GithubIcon } from "assets/icons/github-filled.svg";
import { ReactComponent as GravatarIcon } from "assets/icons/gravatar.svg";
import { ReactComponent as HealthgradesIcon } from "assets/icons/healthgrades.svg";
import { ReactComponent as IndeedIcon } from "assets/icons/indeed.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as KaggleIcon } from "assets/icons/kaggle.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linked-in.svg";
import { ReactComponent as PinterestIcon } from "assets/icons/pinterest.svg";
import { ReactComponent as PortfolioIcon } from "assets/icons/portfolio.svg";
import { ReactComponent as RateMDIcon } from "assets/icons/rate-md.svg";
import { ReactComponent as StackOverflowIcon } from "assets/icons/stack-overflow.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";
import { ReactComponent as WebsiteIcon } from "assets/icons/website.svg";
import { ReactComponent as WellfoundIcon } from "assets/icons/wellfound.svg";
// TODO: use this in place of components/CandidateHeader/SocialLinks
// TODO: export generic enum from backend instead of using separate duplicative enums (i.e ClientSocialLinkLinkTypeEnum or CandidateBioSocialLinkLinkTypeEnum)
export enum SocialLinkTypeEnum {
  Aboutme = "ABOUTME",
  Angellist = "ANGELLIST",
  Careerbuilder = "CAREERBUILDER",
  Crunchbase = "CRUNCHBASE",
  Facebook = "FACEBOOK",
  Flickr = "FLICKR",
  Github = "GITHUB",
  Gravatar = "GRAVATAR",
  Healthgrades = "HEALTHGRADES",
  Indeed = "INDEED",
  Instagram = "INSTAGRAM",
  Kaggle = "KAGGLE",
  Linkedin = "LINKEDIN",
  Pinterest = "PINTEREST",
  Portfolio = "PORTFOLIO",
  Ratemd = "RATEMD",
  Resume = "RESUME",
  Stackoverflow = "STACKOVERFLOW",
  Twitter = "TWITTER",
  Website = "WEBSITE",
  Other = "OTHER",
}

export const socialLinkTypeToDisplayMap = {
  [SocialLinkTypeEnum.Aboutme]: { name: "About.me", icon: WebsiteIcon }, // TODO: get icon
  [SocialLinkTypeEnum.Angellist]: { name: "Wellfound", icon: WellfoundIcon },
  [SocialLinkTypeEnum.Careerbuilder]: { name: "CareerBuilder", icon: CareerbuilderIcon },
  [SocialLinkTypeEnum.Crunchbase]: { name: "Crunchbase", icon: CrunchbaseIcon },
  [SocialLinkTypeEnum.Facebook]: { name: "Facebook", icon: FacebookIcon },
  [SocialLinkTypeEnum.Flickr]: { name: "Flickr", icon: FlickrIcon },
  [SocialLinkTypeEnum.Github]: { name: "Github", icon: GithubIcon },
  [SocialLinkTypeEnum.Gravatar]: { name: "Gravatar", icon: GravatarIcon },
  [SocialLinkTypeEnum.Healthgrades]: { name: "Healthgrades", icon: HealthgradesIcon },
  [SocialLinkTypeEnum.Indeed]: { name: "Indeed", icon: IndeedIcon },
  [SocialLinkTypeEnum.Instagram]: { name: "Instagram", icon: InstagramIcon },
  [SocialLinkTypeEnum.Kaggle]: { name: "Kaggle", icon: KaggleIcon },
  [SocialLinkTypeEnum.Linkedin]: { name: "LinkedIn", icon: LinkedInIcon },
  [SocialLinkTypeEnum.Other]: { name: "Other", icon: WebsiteIcon },
  [SocialLinkTypeEnum.Pinterest]: { name: "Pinterest", icon: PinterestIcon },
  [SocialLinkTypeEnum.Portfolio]: { name: "Portfolio", icon: PortfolioIcon },
  [SocialLinkTypeEnum.Ratemd]: { name: "RateMD", icon: RateMDIcon },
  [SocialLinkTypeEnum.Resume]: { name: "Resume", icon: PaperIcon },
  [SocialLinkTypeEnum.Stackoverflow]: { name: "Stack Overflow", icon: StackOverflowIcon },
  [SocialLinkTypeEnum.Twitter]: { name: "Twitter", icon: TwitterIcon },
  [SocialLinkTypeEnum.Website]: { name: "Website", icon: WebsiteIcon },
};
