/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedbackTemplateCreator
 */
export interface FeedbackTemplateCreator {
    /**
     * 
     * @type {number}
     * @memberof FeedbackTemplateCreator
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackTemplateCreator
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackTemplateCreator
     */
    pictureUrl?: string | null;
}

export function FeedbackTemplateCreatorFromJSON(json: any): FeedbackTemplateCreator {
    return FeedbackTemplateCreatorFromJSONTyped(json, false);
}

export function FeedbackTemplateCreatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackTemplateCreator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
    };
}

export function FeedbackTemplateCreatorToJSON(value?: FeedbackTemplateCreator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'picture_url': value.pictureUrl,
    };
}


