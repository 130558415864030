/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtensionRecordActionRequest
 */
export interface ExtensionRecordActionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExtensionRecordActionRequest
     */
    userWorkEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionRecordActionRequest
     */
    action: string;
    /**
     * 
     * @type {object}
     * @memberof ExtensionRecordActionRequest
     */
    additionalContext?: object;
}

export function ExtensionRecordActionRequestFromJSON(json: any): ExtensionRecordActionRequest {
    return ExtensionRecordActionRequestFromJSONTyped(json, false);
}

export function ExtensionRecordActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionRecordActionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userWorkEmail': json['user_work_email'],
        'action': json['action'],
        'additionalContext': !exists(json, 'additional_context') ? undefined : json['additional_context'],
    };
}

export function ExtensionRecordActionRequestToJSON(value?: ExtensionRecordActionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_work_email': value.userWorkEmail,
        'action': value.action,
        'additional_context': value.additionalContext,
    };
}


