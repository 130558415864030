import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as ArrowRightIcon } from "@doverhq/dover-ui/icons/arrow-right.svg";
import { ReactComponent as CheckIcon } from "@doverhq/dover-ui/icons/check.svg";
import { Box, Stack } from "@mui/material";
import React, { ReactElement } from "react";

import { BodySmall } from "components/library/typography";
import { SetupGuideStep } from "components/SetupGuide";
import { colors } from "styles/theme";

interface CheckIconProps {
  color: string;
}

const StyledCheckIcon = ({ color }: CheckIconProps): React.ReactElement => {
  return (
    <Stack
      bgcolor={color}
      borderRadius="100%"
      width="24px"
      height="24px"
      justifyContent="center"
      alignItems="center"
      flexShrink={0}
    >
      <Icon Icon={CheckIcon} size={12} color="white" />
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * OverviewSection
 * -------------------------------------------------------------------------- */

interface OverviewSectionProps {
  selectedStep?: SetupGuideStep;
  steps: Array<SetupGuideStep>;
  setSelectedStep: (step: SetupGuideStep) => void;
}
export const OverviewSection = ({ selectedStep, steps, setSelectedStep }: OverviewSectionProps): ReactElement => {
  return (
    <Stack
      direction="row"
      spacing={1}
      pt={0.5}
      flex="1 1 auto"
      minWidth={"245px"}
      borderRight={{ xs: "none", sm: `1px solid ${colors.grayscale.gray200}` }}
      borderBottom={{
        xs: `1px solid ${colors.grayscale.gray200}`,
        sm: "none",
      }}
      borderRadius={"6px"}
      sx={{ backgroundColor: "white" }}
    >
      <Stack flex="1 1 auto" p={1}>
        {steps.map(step => {
          const isSelected = step.name === selectedStep?.name;

          return (
            <Stack
              onClick={(): void => {
                setSelectedStep(step);
                step.onClick?.();
              }}
              key={step.name}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              p={1}
              borderRadius={2}
              sx={{
                cursor: "pointer",
                "&:hover": { bgcolor: colors.grayscale.gray100 },
                backgroundColor: isSelected ? colors.white : "transparent",
                borderRadius: "6px",
                border: isSelected ? `1px solid ${colors.grayscale.gray200}` : "none",
                boxShadow: isSelected ? "0px 1px 4px 0px rgba(0 0 0 / 0.05)" : "none",
              }}
            >
              {step.isComplete ? (
                <Stack key={step.name} direction="row" spacing={1} alignItems="center">
                  <StyledCheckIcon color={colors.primary.base} />
                  <BodySmall color={colors.grayscale.gray500}>
                    <s>{step.name}</s>
                  </BodySmall>
                </Stack>
              ) : (
                <>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <StyledCheckIcon color={colors.grayscale.gray300} />
                    <BodySmall>{step.name}</BodySmall>
                  </Stack>
                  <Box sx={{ display: { xs: "block", sm: "none" } }}>
                    <ArrowRightIcon fill={colors.linkLight} />
                  </Box>
                </>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
