import DDFSelect from "@data-driven-forms/common/select";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import React from "react";

import InternalSingleSelectDropdown from "components/data-driven-forms/fields/SingleSelectDropdown/InternalSingleSelectDropdown";

export default props => {
  const { input, isRequired, ...rest } = useFieldApi(props);
  return <DDFSelect {...input} required={isRequired} {...rest} SelectComponent={InternalSingleSelectDropdown} />;
};
