import { Box, Stack, styled } from "@mui/material";
import { useAtomValue } from "jotai";
import React from "react";

import { colors } from "styles/theme";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";

export type Tabs = "Resume" | "Notes" | "Application" | "Explanation";

interface MainContentTabsProps {
  selectedTab: Tabs;
  setSelectedTab: (tab: Tabs) => void;
}

export const MainContentTabs: React.FC<MainContentTabsProps> = ({ selectedTab, setSelectedTab }) => {
  const dtnMode = useAtomValue(isDtnMode);

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      width="100%"
      spacing={2}
      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
    >
      <TabButton isActive={selectedTab === "Resume"} onClick={(): void => setSelectedTab("Resume")}>
        Resume
      </TabButton>
      {!dtnMode && (
        <>
          <TabButton isActive={selectedTab === "Application"} onClick={(): void => setSelectedTab("Application")}>
            Application
          </TabButton>
          <TabButton isActive={selectedTab === "Notes"} onClick={(): void => setSelectedTab("Notes")}>
            Notes
          </TabButton>
        </>
      )}
      <TabButton isActive={selectedTab === "Explanation"} onClick={(): void => setSelectedTab("Explanation")}>
        Explanation
      </TabButton>
    </Stack>
  );
};

// TODO: replace with dui component
interface TabButtonProps {
  isActive?: boolean;
}

const TabButton = styled(Box)<TabButtonProps>`
  flex-shrink: 0;
  border-bottom: ${(props): string => (props.isActive ? "3px solid " + colors.primary.base : "")};
  color: ${(props): string => (props.isActive ? colors.black : colors.grayscale.gray400)};
  font-weight: ${(props): string => (props.isActive ? "600" : "400")};
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 4px;
  cursor: pointer;
  font-size: 14px;
  width: max-content;
`;
