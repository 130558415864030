import { EmailTemplateContact } from "components/dover/CustomizeNextSteps/types";
import { BasicEmailOption } from "components/library/TipTap/types";
import { DoverUser } from "services/openapi";

/** Converts between basic utility types for draft-js and TipTap, from EmailTemplateContact to BasicEmailOption */
export function convertEmailTemplateContactToBasicEmailOption(contact: EmailTemplateContact): BasicEmailOption {
  return {
    id: contact.email,
    email: contact.email,
    label: contact.name,
  };
}

export function convertDoverUserToBasicEmailOption(user: DoverUser): BasicEmailOption | undefined {
  if (!user.email) {
    return undefined;
  }

  return {
    id: user.email!,
    email: user.email,
    label: user.fullName ?? user.email,
  };
}

/** Converts between basic utility types for TipTap and draft-js, from BasicEmailOption to EmailTemplateContact */
export function convertBasicEmailOptionToEmailTemplateContact(option: BasicEmailOption): EmailTemplateContact {
  return {
    email: option.email,
    name: option.label ?? option.email,
  };
}
