import { meta, input } from "@data-driven-forms/common/prop-types-templates";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { TextField as MuiTextField, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router";

import { validationError } from "components/data-driven-forms/fields/helpers";
import { Role, useHasRole } from "components/RBAC";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import { useFormatIndividualAIAnswerMutation } from "services/doverapi/endpoints/candidateInterview";
import { useInterviewRubricResponseQuery } from "views/interview/CandidateInterview/hooks";
import { IndividualAnswerFormatterEndAdornment } from "views/interview/common/components/IndividualAnswerFormatButton";
import { H1 } from "views/interview/common/styles";

const TextField = props => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    helper_text, // TODO: Fix BE serialization to use helperText
    description,
    validateOnMount,
    meta,
    inputProps,
    largeTextArea,
    ...rest
  } = useFieldApi(props);
  // dont want to allow DI's to use the format AI answer functionality yet
  const hasInterviewerRole = useHasRole(Role.INTERVIEWER);
  const useFormatAiAnswer = useFeatureFlag(FeatureFlag.AINotesFormatter) && !hasInterviewerRole;
  const useAINotetaker = useFeatureFlag(FeatureFlag.AINotetaker);
  const { candidateId, interviewRubricResponseId } = useParams();
  const { interviewRubricResponse: candidateInterviewRubricResponse } = useInterviewRubricResponseQuery({
    candidateId,
    interviewRubricResponseId,
  });

  const [
    formatIndividualAIAnswer,
    { isFetching: isAiFetching, isLoading: isAiLoading },
  ] = useFormatIndividualAIAnswerMutation();

  const onSubmit = async ({ formattingPromptType }) => {
    if (!candidateInterviewRubricResponse?.id) {
      return;
    }

    await formatIndividualAIAnswer({
      interviewRubricResponseId: candidateInterviewRubricResponse.id,
      questionLabel: label,
      currentAnswer: input.value,
      helperText: helperText,
      formattingPromptType: formattingPromptType,
    })
      .unwrap()
      .then(data => {
        input.onChange(data.aiAnswer);
      });
  };

  const invalid = validationError(meta, validateOnMount);
  const help =
    invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || helper_text || description;

  return (
    <Grid container spacing={3} direction="column">
      {!largeTextArea && (
        <Grid container item sm={12} md={12}>
          <div>
            <H1 style={{ paddingTop: "16px" }}>{label}</H1>
            {help && <small>Help: {help}</small>}
          </div>
        </Grid>
      )}
      <Grid container item sm={12} md={12} paddingBottom="10px">
        {isAiFetching || isAiLoading ? (
          <Skeleton height="150px" width="100%" />
        ) : (
          <MuiTextField
            {...input}
            value={input.value}
            fullWidth
            minRows={6}
            maxRows={12}
            multiline
            error={!!invalid}
            disabled={isDisabled}
            placeholder={placeholder ?? "Answer..."}
            required={isRequired}
            variant="outlined"
            sx={{
              ".MuiOutlinedInput-root.MuiInputBase-root": {
                outline: "none",
                border: "none",
              },
            }}
            inputProps={{
              readOnly: isReadOnly,
              ...inputProps,
            }}
            InputProps={
              // dont allow individualAnswerFormatter for a question without a label
              useFormatAiAnswer && !useAINotetaker && label
                ? {
                    endAdornment: <IndividualAnswerFormatterEndAdornment onSubmit={onSubmit} />,
                  }
                : undefined
            }
            {...rest}
          />
        )}
      </Grid>
    </Grid>
  );
};

TextField.propTypes = {
  input,
  meta,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  helper_text: PropTypes.node, // TODO: Fix BE serialization to use helperText
  validateOnMount: PropTypes.bool,
  description: PropTypes.node,
  inputProps: PropTypes.object,
  largeTextArea: PropTypes.bool,
};

export default TextField;
