import { Box, Stack } from "@mui/material";
import React, { FC } from "react";

import { Tooltip } from "components/library/Tooltip";
import { Caption } from "components/library/typography";
import { CandidateBio, CandidateBioContactInfoTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { useShowCandidateEmail } from "views/CandidateDetail/utils";

interface ContactInfoProps {
  candidateBio: CandidateBio;
}

export const ContactInfo: FC<ContactInfoProps> = ({ candidateBio }) => {
  const showEmail = useShowCandidateEmail(candidateBio);

  const candidateId = candidateBio.id;

  // Filter out the emails if we don't want to show them
  const contactInfo = candidateBio.contactInfo?.filter(
    info => showEmail || info.type !== CandidateBioContactInfoTypeEnum.Email
  );

  // Grab the primary email if we are including emails, otherwise just get the first contact info
  const displayContactInfo = showEmail
    ? contactInfo?.find(info => info.isPrimary && info.type === CandidateBioContactInfoTypeEnum.Email)?.value
    : contactInfo?.[0]?.value;

  if (!displayContactInfo || !candidateId) {
    return <></>;
  }

  // Calculate how many more contact infos there are
  const numOverflowContactInfo = contactInfo?.length ? contactInfo.length - 1 : 0;
  const overflowNumDisplay = numOverflowContactInfo ? `+${numOverflowContactInfo}` : "";
  const overflowDisplay = contactInfo && numOverflowContactInfo > 0 ? contactInfo.map(ci => ci.value).join(", ") : "";

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Tooltip title={overflowDisplay} placement="bottom">
        <Box sx={{ whiteSpace: "nowrap" }}>
          <Caption color={colors.grayscale.gray500}>{`${displayContactInfo} ${overflowNumDisplay}`}</Caption>
        </Box>
      </Tooltip>
    </Stack>
  );
};
