/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCompanyPitchQuestionSchemaResponse
 */
export interface GetCompanyPitchQuestionSchemaResponse {
    /**
     * 
     * @type {object}
     * @memberof GetCompanyPitchQuestionSchemaResponse
     */
    schema?: object;
    /**
     * 
     * @type {object}
     * @memberof GetCompanyPitchQuestionSchemaResponse
     */
    answers?: object;
}

export function GetCompanyPitchQuestionSchemaResponseFromJSON(json: any): GetCompanyPitchQuestionSchemaResponse {
    return GetCompanyPitchQuestionSchemaResponseFromJSONTyped(json, false);
}

export function GetCompanyPitchQuestionSchemaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyPitchQuestionSchemaResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schema': !exists(json, 'schema') ? undefined : json['schema'],
        'answers': !exists(json, 'answers') ? undefined : json['answers'],
    };
}

export function GetCompanyPitchQuestionSchemaResponseToJSON(value?: GetCompanyPitchQuestionSchemaResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schema': value.schema,
        'answers': value.answers,
    };
}


