/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IRRInterviewer
 */
export interface IRRInterviewer {
    /**
     * 
     * @type {string}
     * @memberof IRRInterviewer
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof IRRInterviewer
     */
    isDoverInterviewer: boolean;
    /**
     * 
     * @type {string}
     * @memberof IRRInterviewer
     */
    email: string;
}

export function IRRInterviewerFromJSON(json: any): IRRInterviewer {
    return IRRInterviewerFromJSONTyped(json, false);
}

export function IRRInterviewerFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRRInterviewer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'isDoverInterviewer': json['is_dover_interviewer'],
        'email': json['email'],
    };
}

export function IRRInterviewerToJSON(value?: IRRInterviewer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'is_dover_interviewer': value.isDoverInterviewer,
        'email': value.email,
    };
}


