import { Select, MenuItem } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";

import { usePartialUpdateJobPositionOpeningMutation } from "services/doverapi/endpoints/hiring-plan";
import { HiringPlanJobPositionOpening } from "services/openapi/models";
import {
  JOB_POSITION_OPENING_STATUS_OPTIONS,
  DEFAULT_STATUS_OPTION,
  getStatusOptionByValue,
  JOB_POSITION_OPENING_STATUS_ENUM,
} from "views/HiringPlan/constants";
import { HiringPlanTableRow } from "views/HiringPlan/types";

interface StyledSelectProps {
  $color?: string;
}

const StyledSelect = styled(Select)<StyledSelectProps>`
  position: relative;
  .MuiSelect-root {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: ${(props): any => props.$color || "inherit"};
  }

  &:before {
    border-color: ${(props): any => props.$color || "inherit"};
  }
  ,
  &:after {
    border-color: ${(props): any => props.$color || "inherit"};
  }
`;

interface Props {
  tableRow: HiringPlanTableRow;
}

const SingleSelectCellStatus = ({ tableRow }: Props): React.ReactElement => {
  const initialOption = tableRow.status ? getStatusOptionByValue(tableRow.status) : DEFAULT_STATUS_OPTION;

  const [currOption, setCurrOption] = useState(initialOption);

  const [partialUpdateJobPositionOpening] = usePartialUpdateJobPositionOpeningMutation();

  const handleUpdateJobPositionOpening = async (
    currOptionValue: JOB_POSITION_OPENING_STATUS_ENUM | undefined
  ): Promise<void> => {
    const updatedJobPositionOpening = {
      ...tableRow.jobPositionOpening,
      status: currOptionValue || DEFAULT_STATUS_OPTION.value,
    };
    partialUpdateJobPositionOpening({
      jobPositionOpening: updatedJobPositionOpening as HiringPlanJobPositionOpening,
    });
  };

  return (
    <StyledSelect
      $color={currOption?.color}
      onChange={async (e): Promise<void> => {
        setCurrOption(getStatusOptionByValue(e.target.value as JOB_POSITION_OPENING_STATUS_ENUM));
        await handleUpdateJobPositionOpening(
          getStatusOptionByValue(e.target.value as JOB_POSITION_OPENING_STATUS_ENUM)?.value
        );
      }}
      value={currOption?.value || DEFAULT_STATUS_OPTION}
    >
      {JOB_POSITION_OPENING_STATUS_OPTIONS.map(option => {
        return <MenuItem value={option.value}>{option.label}</MenuItem>;
      })}
    </StyledSelect>
  );
};

export default SingleSelectCellStatus;
