import { createSelector } from "@reduxjs/toolkit";

import { adminDataReducerKey, initialState } from "domains/admin/reducer";
import { AdminDataReducerState } from "domains/admin/types";
import { GlobalRootState } from "domains/global/types";

const selectState = (state: GlobalRootState): AdminDataReducerState => state[adminDataReducerKey] || initialState;

export const selectRequiresSelectedClient = createSelector<GlobalRootState, AdminDataReducerState, boolean | undefined>(
  [selectState],
  state => state.requiresSelectedClient
);
