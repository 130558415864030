import styled from "styled-components";

import { colors } from "styles/theme";

export const PlaceholderWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlaceholderText = styled.div`
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 300;
  color: ${colors.black};
`;

export const ErrorText = styled.div`
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 300;
  color: ${colors.critical.base};
`;

export const DummyButton = styled.div`
  width: 127px;
  height: 48px;
  background: white;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.informational.dark};
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
`;
