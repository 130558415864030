/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParseJDFeaturesRequest
 */
export interface ParseJDFeaturesRequest {
    /**
     * 
     * @type {string}
     * @memberof ParseJDFeaturesRequest
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ParseJDFeaturesRequest
     */
    searchTemplateId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ParseJDFeaturesRequest
     */
    jdLink?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParseJDFeaturesRequest
     */
    jdText?: string | null;
}

export function ParseJDFeaturesRequestFromJSON(json: any): ParseJDFeaturesRequest {
    return ParseJDFeaturesRequestFromJSONTyped(json, false);
}

export function ParseJDFeaturesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParseJDFeaturesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobTitle': !exists(json, 'job_title') ? undefined : json['job_title'],
        'searchTemplateId': !exists(json, 'search_template_id') ? undefined : json['search_template_id'],
        'jdLink': !exists(json, 'jd_link') ? undefined : json['jd_link'],
        'jdText': !exists(json, 'jd_text') ? undefined : json['jd_text'],
    };
}

export function ParseJDFeaturesRequestToJSON(value?: ParseJDFeaturesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_title': value.jobTitle,
        'search_template_id': value.searchTemplateId,
        'jd_link': value.jdLink,
        'jd_text': value.jdText,
    };
}


