import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Box, Chip, Stack } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import MapPinIcon from "assets/icons/map-pin-grey.svg";
import { ControlledAutocomplete } from "components/library/Autocomplete";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useListAllLocations } from "services/doverapi/endpoints/search-v3/endpoints";
import { LocationWithValue } from "services/doverapi/endpoints/search-v3/types";
import { colors } from "styles/theme";
import AdvancedAccordion from "views/sourcing/Search/components/AdvancedDropdown";
import FilterAccordion from "views/sourcing/Search/components/FilterAccordion";
import FilterSectionHeader from "views/sourcing/Search/components/FilterSectionHeader";
import { CHIP_MAX_WIDTH } from "views/sourcing/Search/constants";
import {
  EXCLUDED_LOCATIONS_ELEMENTS_NAME,
  LOCATIONS_ELEMENTS_NAME,
  previewStateMarginBottom,
  previewStateMarginRight,
} from "views/sourcing/Search/constants";
import { SearchV3FormSchemaType, TitleContentProps } from "views/sourcing/Search/types";

const AdvancedLocationFilterContent = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const excludedLocations = useWatch({ control, name: EXCLUDED_LOCATIONS_ELEMENTS_NAME });
    const locationsOptions = useListAllLocations();

    if (!locationsOptions) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <ControlledAutocomplete
        control={control}
        fontSize="small"
        title="Excluded Locations"
        placeholder={"Enter location..."}
        filterSelectedOptions={true}
        staticOptions={locationsOptions}
        name={EXCLUDED_LOCATIONS_ELEMENTS_NAME}
        getOptionLabel={(option: LocationWithValue): string => option.name}
        isOptionEqualToValue={(option: LocationWithValue, value: LocationWithValue): boolean =>
          option.value === value.value
        }
        initialValues={excludedLocations ?? []}
        multiple={true}
        sortByField={"name"}
      />
    );
  }
);

const LocationFilterTitleContent = React.memo(
  ({ expanded }: TitleContentProps): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const locations = useWatch({ control, name: "locations.locationElements" });

    const onChipDelete = React.useCallback(
      (value: string) => {
        setValue(
          LOCATIONS_ELEMENTS_NAME,
          locations.filter(location => location.value !== value)
        );
      },
      [locations, setValue]
    );

    if (locations === undefined || locations.length === 0 || expanded) {
      return <></>;
    }

    return (
      <Box>
        {locations.map(location => {
          return (
            <Chip
              label={<TextWithMaxWidth label={location.name} width={CHIP_MAX_WIDTH} />}
              key={location.name}
              deleteIcon={<HighlightOffSharpIcon />}
              onDelete={(): void => onChipDelete(location.value)}
              sx={{
                mr: previewStateMarginRight,
                mb: previewStateMarginBottom,
              }}
            />
          );
        })}
      </Box>
    );
  }
);

const LocationFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const locations = useWatch({ control, name: LOCATIONS_ELEMENTS_NAME });
    const locationsOptions = useListAllLocations();

    if (!locationsOptions || locationsOptions.length === 0) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Stack spacing={2}>
        <ControlledAutocomplete
          control={control}
          fontSize="small"
          title="City/State/Country"
          placeholder={"Enter location..."}
          staticOptions={locationsOptions}
          filterSelectedOptions={true}
          name={LOCATIONS_ELEMENTS_NAME}
          getOptionLabel={(option: LocationWithValue): string => option.name}
          isOptionEqualToValue={(option: LocationWithValue, value: LocationWithValue): boolean =>
            option.value === value.value
          }
          initialValues={locations ?? []}
          multiple={true}
          sortByField={"name"}
        />
        <AdvancedAccordion title="Advanced" expandedContent={<AdvancedLocationFilterContent />} />
      </Stack>
    );
  }
);

const LocationFilters = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const locations = useWatch({ control, name: LOCATIONS_ELEMENTS_NAME });

    return (
      <>
        <FilterSectionHeader title={"Location"} icon={MapPinIcon} />
        <FilterAccordion
          // if theres no locations selected, show the title as "Target Locations"
          title={!locations?.length ? "Target Locations" : undefined}
          TitleContent={LocationFilterTitleContent}
          expandedContent={<LocationFiltersContent />}
        />
      </>
    );
  }
);

export default LocationFilters;
