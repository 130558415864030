/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchV3Explanation,
    SearchV3ExplanationFromJSON,
    SearchV3ExplanationFromJSONTyped,
    SearchV3ExplanationToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetInDepthExplanationResponse
 */
export interface GetInDepthExplanationResponse {
    /**
     * 
     * @type {SearchV3Explanation}
     * @memberof GetInDepthExplanationResponse
     */
    niceToHaveExplanation: SearchV3Explanation;
    /**
     * 
     * @type {SearchV3Explanation}
     * @memberof GetInDepthExplanationResponse
     */
    mustHaveExplanation: SearchV3Explanation;
}

export function GetInDepthExplanationResponseFromJSON(json: any): GetInDepthExplanationResponse {
    return GetInDepthExplanationResponseFromJSONTyped(json, false);
}

export function GetInDepthExplanationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInDepthExplanationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'niceToHaveExplanation': SearchV3ExplanationFromJSON(json['nice_to_have_explanation']),
        'mustHaveExplanation': SearchV3ExplanationFromJSON(json['must_have_explanation']),
    };
}

export function GetInDepthExplanationResponseToJSON(value?: GetInDepthExplanationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nice_to_have_explanation': SearchV3ExplanationToJSON(value.niceToHaveExplanation),
        'must_have_explanation': SearchV3ExplanationToJSON(value.mustHaveExplanation),
    };
}


