/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOrCreateUserOnboardingFlowRequest
 */
export interface GetOrCreateUserOnboardingFlowRequest {
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateUserOnboardingFlowRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateUserOnboardingFlowRequest
     */
    contentType?: GetOrCreateUserOnboardingFlowRequestContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateUserOnboardingFlowRequest
     */
    relatedObjectUuid?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateUserOnboardingFlowRequest
     */
    flowName: GetOrCreateUserOnboardingFlowRequestFlowNameEnum;
}

/**
* @export
* @enum {string}
*/
export enum GetOrCreateUserOnboardingFlowRequestContentTypeEnum {
    Job = 'job',
    Useronboardingflow = 'useronboardingflow'
}/**
* @export
* @enum {string}
*/
export enum GetOrCreateUserOnboardingFlowRequestFlowNameEnum {
    UserInfo = 'USER_INFO',
    UserAndCompanyLoginInfo = 'USER_AND_COMPANY_LOGIN_INFO',
    CompanyLoginAndSetup = 'COMPANY_LOGIN_AND_SETUP',
    AtsJobBoards = 'ATS_JOB_BOARDS',
    SourcingAutopilotSetup = 'SOURCING_AUTOPILOT_SETUP',
    DoverInterviewerSetup = 'DOVER_INTERVIEWER_SETUP',
    AutoSchedulingSetup = 'AUTO_SCHEDULING_SETUP',
    ApplicantSorting = 'APPLICANT_SORTING'
}

export function GetOrCreateUserOnboardingFlowRequestFromJSON(json: any): GetOrCreateUserOnboardingFlowRequest {
    return GetOrCreateUserOnboardingFlowRequestFromJSONTyped(json, false);
}

export function GetOrCreateUserOnboardingFlowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrCreateUserOnboardingFlowRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contentType': !exists(json, 'content_type') ? undefined : json['content_type'],
        'relatedObjectUuid': !exists(json, 'related_object_uuid') ? undefined : json['related_object_uuid'],
        'flowName': json['flow_name'],
    };
}

export function GetOrCreateUserOnboardingFlowRequestToJSON(value?: GetOrCreateUserOnboardingFlowRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content_type': value.contentType,
        'related_object_uuid': value.relatedObjectUuid,
        'flow_name': value.flowName,
    };
}


