import { Radio, Stack } from "@mui/material";
import React from "react";

interface RadioButtonProps {
  value: boolean | string;
  checked: boolean;
  onChange: (value: boolean | string) => void;
  content: React.ReactElement;
}

export const RadioButton = ({ value, checked, onChange, content }: RadioButtonProps): React.ReactElement => {
  return (
    <Stack
      direction="row"
      spacing={0.3}
      alignItems="center"
      onClick={(): void => {
        onChange(value);
      }}
      sx={{ cursor: "pointer" }}
    >
      <Radio value={value} checked={checked} sx={{ padding: 0 }} />
      {content}
    </Stack>
  );
};
