import { SelectOption } from "components/AtsSettings/types";

export const reformatOptions = (options: any[], displayIds?: boolean): SelectOption[] => {
  return options.map(option => {
    if (displayIds && option?.id) {
      return { label: `${option.name} (${option.id})`, value: String(option.id) } as SelectOption;
    }
    return { label: option.name, value: String(option.id) } as SelectOption;
  });
};
