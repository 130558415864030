export const isValidURL = (str: string): boolean => {
  const urlRegex = new RegExp(
    "^((https?|ftp|smtp):\\/\\/)?(www.)?([a-z0-9]+\\.)+[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*([a-z0-9]+\\.)?.*"
  );
  return !!str.match(urlRegex);
};

export const formatURL = (str: string): string => {
  return str.includes("https") || str.includes("http") ? str : `https://${str}`;
};
