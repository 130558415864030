import { SerializedError } from "@reduxjs/toolkit";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { keyBy, sortBy } from "lodash";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  HIRING_PIPELINE_STAGE,
  HIRING_STAGE_EMAIL_TEMPLATE,
  JOB,
  LIST_TAG,
} from "services/doverapi/endpointTagsConstants";
import {
  ApiApiCreateHiringPipelineStageOperationRequest,
  ApiApiDisableHiringPipelineStageRequest,
  ApiApiListHiringPipelineStagesRequest,
  ApiApiPartialUpdateInterviewStageRequest,
  ApiApiSwapInterviewStageTypeOperationRequest,
  HiringPipelineStage,
  HiringPipelineStageListResponse,
  HiringPipelineStageMilestone,
  HiringPipelineStageType,
  InterviewPlanInterviewSubstage,
  InterviewPlanStageType,
  OnboardingHiringPipelineStage,
  OnboardingMultipartInterviewStage,
  SubstageType,
} from "services/openapi";
import { getSinglePartSubstage } from "utils/isStage";
import { showErrorToast } from "utils/showToast";
import { UpdateHpsArgs } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useUpdateHps";

const hiringPipelineStage = doverApi.injectEndpoints({
  endpoints: build => ({
    listHiringPipelineStagesV2: build.query<HiringPipelineStageListResponse, ApiApiListHiringPipelineStagesRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.listHiringPipelineStages(args);

          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: [{ type: HIRING_PIPELINE_STAGE, id: LIST_TAG }],
    }),
    disableHiringPipelineStage: build.mutation<void, ApiApiDisableHiringPipelineStageRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          await client.disableHiringPipelineStage(args);

          return { data: undefined };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [
        { type: HIRING_PIPELINE_STAGE, id: LIST_TAG },
        { type: JOB, id: LIST_TAG },
      ],
      onQueryStarted: async ({ id, jobId }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          hiringPipelineStage.util.updateQueryData("listHiringPipelineStagesV2", { jobId }, draft => {
            const disabledStageIdx = draft.results.findIndex(stage => stage.id === id);
            draft.results.splice(disabledStageIdx, 1);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          // Undo our optimistic updates if the mutation ended up failing server-side.
          patch.undo();
        }
      },
    }),
    // This is the new prometheus endpoint, the other one can be deprecated at some point
    updateHiringPipelineStageV2: build.mutation<HiringPipelineStage, UpdateHpsArgs>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.updateHiringPipelineStage(args);

          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [
        { type: HIRING_PIPELINE_STAGE, id: LIST_TAG },
        { type: JOB, id: LIST_TAG },
      ],
      onQueryStarted: async ({ jobId, id, data: { orderIndex } }, { dispatch, queryFulfilled }) => {
        // Optimistic update for order index
        // We are essentially recreating the logic that the backend does here, which feels brittle
        // I'm unsure of how to do this better though
        let orderingPatch;
        if (orderIndex !== undefined) {
          orderingPatch = dispatch(
            hiringPipelineStage.util.updateQueryData("listHiringPipelineStagesV2", { jobId }, draft => {
              // Bump the orderIndex of all stages with an orderIndex greater than or equal to the new orderIndex
              bumpOrderIndexes(orderIndex, draft.results);

              // Update the order index of the stage we're moving
              const stage = draft.results.find(stage => stage.id === id);
              if (stage) stage.orderIndex = orderIndex;
            })
          );
        }

        try {
          await queryFulfilled;
        } catch (e) {
          // try to fetch the actual error message from the backend
          try {
            const error = await e.error.serializedError.json();
            const errorMessage = error.error;
            if (errorMessage) {
              showErrorToast(errorMessage);
            }
          } catch {
            console.error("Failed to update interview plan");
          }

          // Undo our optimistic updates if the mutation ended up failing server-side.
          orderingPatch?.undo();
        }
      },
    }),
    createHiringPipelineStage: build.mutation<HiringPipelineStage, ApiApiCreateHiringPipelineStageOperationRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.createHiringPipelineStage(args);

          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        const baseTags = [{ type: HIRING_PIPELINE_STAGE, id: LIST_TAG } as const, { type: JOB, id: LIST_TAG } as const];
        const resultBasedTags = args
          ? [{ type: HIRING_STAGE_EMAIL_TEMPLATE, id: `${LIST_TAG}${args.jobId}` } as const]
          : [];
        return [...baseTags, ...resultBasedTags];
      },
      onQueryStarted: async (
        { jobId, data: { name, orderIndex, interviewStageType } },
        { dispatch, queryFulfilled }
      ) => {
        const patch = dispatch(
          hiringPipelineStage.util.updateQueryData("listHiringPipelineStagesV2", { jobId }, draft => {
            bumpOrderIndexes(orderIndex, draft.results);

            const isMultiPart = interviewStageType === InterviewPlanStageType.MULTIPART;
            const isTakeHome = interviewStageType === InterviewPlanStageType.TAKE_HOME;

            draft.results.push({
              id: "",
              name,
              orderIndex,
              stageType: HiringPipelineStageType.INTERVIEW,
              milestone: null,
              contentTypeName: isMultiPart ? SubstageType.MULTIPART_INTERVIEW_STAGE : SubstageType.INTERVIEW_STAGE,
              // For multipart's its fine to leave this blank, but single part and take home expect something in the substage array
              multipartInterviewStage: isMultiPart
                ? undefined
                : { substages: [{ isTakeHome, possibleInterviewers: [] }] },
            });
          })
        );

        try {
          await queryFulfilled;
        } catch {
          // Undo our optimistic updates if the mutation ended up failing server-side.
          patch?.undo();
        }
      },
    }),
    swapInterviewStageType: build.mutation<HiringPipelineStage, ApiApiSwapInterviewStageTypeOperationRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.swapInterviewStageType(args);

          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [
        { type: HIRING_PIPELINE_STAGE, id: LIST_TAG },
        { type: JOB, id: LIST_TAG },
      ],
    }),
    // This is the new prometheus endpoint, the other one can be deprecated at some point
    partialUpdateInterviewStage: build.mutation<
      OnboardingMultipartInterviewStage,
      ApiApiPartialUpdateInterviewStageRequest
    >({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.partialUpdateInterviewStage(args);

          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [
        { type: HIRING_PIPELINE_STAGE, id: LIST_TAG },
        { type: JOB, id: LIST_TAG },
      ],
    }),
    listHiringPipelineStages: build.query<OnboardingHiringPipelineStage[] | undefined, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const { results } = await client.listOnboardingHiringPipelineStages({ job: jobId });

          return { data: results };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [{ type: HIRING_PIPELINE_STAGE, id: LIST_TAG } as const];
      },
    }),
    getHiringPipelineStage: build.query<HiringPipelineStage | undefined, { jobId: string; hpsId: string }>({
      queryFn: async ({ jobId, hpsId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const hps = await client.getHiringPipelineStage({ id: hpsId, jobId });

          return { data: hps };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: hps => {
        return [{ type: HIRING_PIPELINE_STAGE, id: hps?.id } as const];
      },
    }),
    updateHiringPipelineStage: build.mutation<
      OnboardingHiringPipelineStage | undefined,
      { id: string; data: OnboardingHiringPipelineStage; skipInvalidation?: boolean }
    >({
      queryFn: async ({ id, data }: { id: string; data: OnboardingHiringPipelineStage }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.partialUpdateOnboardingHiringPipelineStage({ id, data });

          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (hiringPipelineStage, error, { skipInvalidation }) => {
        return skipInvalidation ? [] : [{ type: HIRING_PIPELINE_STAGE, id: LIST_TAG } as const];
      },
    }),
    updateMultipartInterviewStage: build.mutation<
      OnboardingMultipartInterviewStage | undefined,
      { id: string; data: OnboardingMultipartInterviewStage }
    >({
      queryFn: async ({ id, data }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.partialUpdateOnboardingMultipartInterviewStage({ id, data });
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: () => {
        return [{ type: HIRING_PIPELINE_STAGE, id: LIST_TAG } as const];
      },
    }),
  }),
});

export const {
  useListHiringPipelineStagesQuery,
  useUpdateHiringPipelineStageMutation,
  useUpdateMultipartInterviewStageMutation,
  useListHiringPipelineStagesV2Query,
  useDisableHiringPipelineStageMutation,
  useUpdateHiringPipelineStageV2Mutation,
  usePartialUpdateInterviewStageMutation,
  useCreateHiringPipelineStageMutation,
  useSwapInterviewStageTypeMutation,
  useGetHiringPipelineStageQuery,
} = hiringPipelineStage;

export function useGetAdminHiringPipelineStagesSorted(jobId?: string): OnboardingHiringPipelineStage[] {
  const { data: hiringPipelineStages } = useListHiringPipelineStagesQuery(jobId ?? skipToken);
  return sortBy(hiringPipelineStages, "orderIndex");
}

export function useGetAdminHiringPipelineStagesKeyedById(
  jobId?: string
): Record<string, OnboardingHiringPipelineStage> {
  const { data: hiringPipelineStages } = useListHiringPipelineStagesQuery(jobId ?? skipToken);
  return keyBy(hiringPipelineStages, "id");
}

// Bump the orderIndex of all stages with an orderIndex greater than or equal to the new orderIndex
// This is what the backend does as well
const bumpOrderIndexes = (orderIndex: number, stages: Array<HiringPipelineStage>): void => {
  stages.forEach(stage => {
    if (stage.orderIndex != undefined && stage.orderIndex >= orderIndex) {
      stage.orderIndex += 1;
    }
  });
};

export const useGetInitialCallSubstage = (jobId?: string): InterviewPlanInterviewSubstage | undefined => {
  const { substage } = useListHiringPipelineStagesV2Query(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data }): { substage: InterviewPlanInterviewSubstage | undefined } => {
      const initialCallStage = data?.results.find(s => s.milestone === HiringPipelineStageMilestone.INITIAL_CALL);
      if (!initialCallStage) {
        return { substage: undefined };
      }
      return {
        substage: getSinglePartSubstage(initialCallStage),
      };
    },
  });

  return substage;
};
