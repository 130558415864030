import { debounce } from "lodash";
import { Dispatch, SetStateAction, useState, useMemo, useEffect } from "react";

import { useOnChange } from "hooks/useOnChange";

/**
 *
 * @param initialValue Initial value for the state
 * @param wait How long to wait before setting the debounced value
 * @returns an array with the debounced value, the setter function, and the non-debounced value
 */
export const useDebounceState = <T>(initialValue: T, wait: number): [T, Dispatch<SetStateAction<T>>, T] => {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  const setValueWithDebounce = useMemo(() => debounce(setDebouncedValue, wait, { leading: false, trailing: true }), [
    wait,
  ]);

  useEffect(() => {
    // If initial value prop changes, update state
    if (initialValue !== value) {
      setValue(initialValue);
    }
    // ONLY invoke when prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useOnChange(() => {
    setValueWithDebounce(value);
  }, [setValueWithDebounce, value]);

  return [debouncedValue, setValue, value];
};
