import { Stack } from "@mui/material";
import React from "react";

import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall } from "components/library/typography";

interface PersonaBannerProps {
  title: string;
  personaId?: number;
}

export const PersonaBanner = ({ title, personaId }: PersonaBannerProps): React.ReactElement => {
  if (!personaId) {
    return (
      <Banner variant={BannerVariant.Critical}>
        <BodySmall>Please select a role type that best matches your role, or proceed with a custom role.</BodySmall>
      </Banner>
    );
  } else if (personaId == 168) {
    return (
      <Banner variant={BannerVariant.Warning}>
        <Stack direction="column" spacing={1}>
          <BodySmall weight="600">We couldn&apos;t find a matching role type</BodySmall>
          <BodySmall>
            We were unable to match your role, {title}, with one of our role types. Please look through our list of role
            types and select the one that best matches your role, or proceed with a custom role type.
          </BodySmall>
        </Stack>
      </Banner>
    );
  } else {
    return (
      <Banner variant={BannerVariant.Info}>
        <BodySmall>
          We matched your role, {title}, with one of our role types. Please change the role type if the selection does
          not look correct.
        </BodySmall>
      </Banner>
    );
  }
};
