import { Autocomplete, Select, TextField } from "mui-rff";
import styled from "styled-components";

export const Divider = styled.hr`
  margin: auto;
  margin-top: 15px;
  width: 100%;
  min-width: 250px;
`;

export const ReadOnlySpan = styled.span`
  background-color: rgba(0, 0, 0, 0.05);
`;

export const StyledAutocomplete = styled(Autocomplete)`
  min-width: 250px;
`;

export const StyledSelect = styled(Select)`
  min-width: 250px;
`;

export const StyledTextField = styled(TextField)`
  min-width: 250px;
`;

export const TemplateEditorBox = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  min-width: 400px;
`;

export const ButtonText = styled.span`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
