import { Box, Menu, MenuItem } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";

import { ReactComponent as WandIcon } from "assets/icons/green-wand.svg";
import { BodySmall } from "components/library/typography";
import { FormatAiAnswerRequestFormattingPromptTypeEnum } from "services/openapi";
import { colors } from "styles/theme";

const IndividualAnswerFormatButton = ({
  onSubmit,
}: {
  onSubmit: ({ formattingPromptType }: { formattingPromptType: string }) => void;
}): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  return (
    <>
      <Box
        position="absolute"
        bottom="10px"
        right="10px"
        border={`1px solid ${colors.grayscale.gray200}`}
        borderRadius="3px"
        padding="5px"
        sx={{ ":hover": { cursor: "pointer" } }}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <WandIcon />
      </Box>
      <Menu
        open={!!anchorEl}
        onClose={(): void => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MenuItem
          onClick={(): void => {
            onSubmit({ formattingPromptType: FormatAiAnswerRequestFormattingPromptTypeEnum.Summarize });
            setAnchorEl(null);
          }}
        >
          <BodySmall>Summarize</BodySmall>
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            onSubmit({ formattingPromptType: FormatAiAnswerRequestFormattingPromptTypeEnum.BulletPoint });
            setAnchorEl(null);
          }}
        >
          <BodySmall>Add Bullet Points</BodySmall>
        </MenuItem>
      </Menu>
    </>
  );
};

export const IndividualAnswerFormatterEndAdornment = ({
  onSubmit,
}: {
  onSubmit: ({ formattingPromptType }: { formattingPromptType: string }) => void;
}): React.ReactElement => {
  return (
    <InputAdornment position="end">
      {/* Based on how input adornment position=end works, and that we want our button to be bottom right.
      We want to have an empty box, such that a right margin is placed on the text
      (so that the text doesnt extend so far to the right that it may be covered by our button). 
      Then we want to place our button, which will be positioned absolute, bottom right */}
      <Box width="20px" />
      <IndividualAnswerFormatButton onSubmit={onSubmit} />
    </InputAdornment>
  );
};
