import { skipToken } from "@reduxjs/toolkit/dist/query";

import { FeatureFlag } from "hooks/useFeatureFlag";
import { useGetUserRolesAndPermissionsQuery } from "services/doverapi/endpoints/RBAC/endpoints";
import { UserRolesAndPermissions } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";

export enum Role {
  // GLOBAL ROLES
  ADMIN = "ADMIN",
  RECRUITING_PARTNER = "RECRUITING_PARTNER",
  INTERVIEWER = "INTERVIEWER",
  COORDINATOR = "COORDINATOR",

  // CLIENT ROLES
  CLIENT_ADMIN = "CLIENT_ADMIN",
  TEAM_MEMBER = "TEAM_MEMBER",

  // JOB ROLES
  HIRING_MANAGER = "HIRING_MANAGER",
  RECRUITER = "RECRUITER",
  HIRING_TEAM = "HIRING_TEAM",
}

export const ROLES_REQUIRED_TO_ACCESS_APP = [
  Role.ADMIN,
  Role.RECRUITING_PARTNER,
  Role.INTERVIEWER,
  Role.COORDINATOR,
  Role.CLIENT_ADMIN,
  Role.TEAM_MEMBER,
];

export const useGetPermissions = (): { permissionsData: UserRolesAndPermissions | undefined; isFetching: boolean } => {
  const { isAuthenticated, user, loading } = useAuth0();
  const { data: permissionsData, isFetching } = useGetUserRolesAndPermissionsQuery(
    isAuthenticated && user && !loading ? undefined : skipToken
  );
  return { permissionsData, isFetching };
};

export const useHasFlag = (flag?: string | FeatureFlag): boolean => {
  const { permissionsData, isFetching } = useGetPermissions();

  if (isFetching || !permissionsData) {
    return false;
  }

  if (!flag) {
    return true;
  }

  return permissionsData.featureFlags.includes(flag);
};

export const useHasRole = (desiredRole: string, jobId?: string): boolean => {
  const { permissionsData, isFetching } = useGetPermissions();

  if (isFetching || !permissionsData) {
    return false;
  }

  if (permissionsData.globalRoles.includes(desiredRole)) {
    return true;
  }

  if (jobId) {
    const jobRoles = permissionsData.jobRoles[jobId] || [];
    for (const jobRole of jobRoles) {
      if (jobRole.name === desiredRole) {
        return true;
      }
    }
  }

  return false;
};

export const useContainsRole = (allowedRoles?: string[], jobId?: string): boolean => {
  const { permissionsData, isFetching } = useGetPermissions();

  if (isFetching || !permissionsData) {
    return false;
  }

  if (!allowedRoles || allowedRoles.length === 0) {
    return true;
  }

  // return true if the user role includes at least one of the allowed roles
  for (const r of allowedRoles) {
    if (permissionsData.globalRoles.includes(r)) {
      return true;
    }
  }

  if (jobId) {
    const jobRoles = permissionsData.jobRoles[jobId] || [];
    for (const jobRole of jobRoles) {
      if (allowedRoles.includes(jobRole.name)) {
        return true;
      }
    }
  }

  return false;
};

export const useHasElevatedPermissions = (jobId?: string): boolean => {
  const { permissionsData, isFetching } = useGetPermissions();

  const hasElevatedPerms = useContainsRole(
    [Role.ADMIN, Role.CLIENT_ADMIN, Role.HIRING_MANAGER, Role.RECRUITER, Role.HIRING_TEAM],
    jobId
  );

  if (isFetching || !permissionsData) {
    return false;
  }

  return hasElevatedPerms;
};
