/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FromUserInfo,
    FromUserInfoFromJSON,
    FromUserInfoFromJSONTyped,
    FromUserInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface HiringStageEmailTemplate
 */
export interface HiringStageEmailTemplate {
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    hiringPipelineStage: string;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    templateType?: HiringStageEmailTemplateTemplateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    requiredEmailAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    requiredInterviewer?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof HiringStageEmailTemplate
     */
    hasSameInterviewerAndEmailSender?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HiringStageEmailTemplate
     */
    ccEmails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HiringStageEmailTemplate
     */
    bccEmails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    subjectTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    bodyTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    state?: HiringStageEmailTemplateStateEnum;
    /**
     * 
     * @type {string}
     * @memberof HiringStageEmailTemplate
     */
    readonly setupState?: HiringStageEmailTemplateSetupStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof HiringStageEmailTemplate
     */
    userEditingDisabled?: boolean | null;
    /**
     * 
     * @type {FromUserInfo}
     * @memberof HiringStageEmailTemplate
     */
    fromUserInfo?: FromUserInfo | null;
}

/**
* @export
* @enum {string}
*/
export enum HiringStageEmailTemplateTemplateTypeEnum {
    SchedulingTakeHome = 'SCHEDULING_TAKE_HOME',
    SchedulingSinglepart = 'SCHEDULING_SINGLEPART',
    SchedulingMultipart = 'SCHEDULING_MULTIPART',
    Rejection = 'REJECTION',
    SchedulingDoverInterviewer = 'SCHEDULING_DOVER_INTERVIEWER',
    SchedulingInbound = 'SCHEDULING_INBOUND',
    SchedulingInboundTakehome = 'SCHEDULING_INBOUND_TAKEHOME',
    SchedulingInboundMultipart = 'SCHEDULING_INBOUND_MULTIPART',
    SchedulingInboundDoverInterviewer = 'SCHEDULING_INBOUND_DOVER_INTERVIEWER',
    RejectionInbound = 'REJECTION_INBOUND',
    Scheduling = 'SCHEDULING',
    SchedulingMoveJob = 'SCHEDULING_MOVE_JOB',
    SchedulingMoveJobDoverInterviewer = 'SCHEDULING_MOVE_JOB_DOVER_INTERVIEWER',
    SchedulingInboundMoveJob = 'SCHEDULING_INBOUND_MOVE_JOB',
    SchedulingInboundMoveJobDoverInterviewer = 'SCHEDULING_INBOUND_MOVE_JOB_DOVER_INTERVIEWER',
    SchedulingThirdPartyAgency = 'SCHEDULING_THIRD_PARTY_AGENCY',
    SchedulingThirdPartyAgencyDoverInterviewer = 'SCHEDULING_THIRD_PARTY_AGENCY_DOVER_INTERVIEWER',
    SchedulingThirdPartyNonagency = 'SCHEDULING_THIRD_PARTY_NONAGENCY',
    SchedulingThirdPartyNonagencyDoverInterviewer = 'SCHEDULING_THIRD_PARTY_NONAGENCY_DOVER_INTERVIEWER'
}/**
* @export
* @enum {string}
*/
export enum HiringStageEmailTemplateStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}/**
* @export
* @enum {string}
*/
export enum HiringStageEmailTemplateSetupStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function HiringStageEmailTemplateFromJSON(json: any): HiringStageEmailTemplate {
    return HiringStageEmailTemplateFromJSONTyped(json, false);
}

export function HiringStageEmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): HiringStageEmailTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'hiringPipelineStage': json['hiring_pipeline_stage'],
        'templateType': !exists(json, 'template_type') ? undefined : json['template_type'],
        'name': json['name'],
        'requiredEmailAlias': !exists(json, 'required_email_alias') ? undefined : json['required_email_alias'],
        'requiredInterviewer': !exists(json, 'required_interviewer') ? undefined : json['required_interviewer'],
        'hasSameInterviewerAndEmailSender': !exists(json, 'has_same_interviewer_and_email_sender') ? undefined : json['has_same_interviewer_and_email_sender'],
        'ccEmails': !exists(json, 'cc_emails') ? undefined : json['cc_emails'],
        'bccEmails': !exists(json, 'bcc_emails') ? undefined : json['bcc_emails'],
        'subjectTemplate': !exists(json, 'subject_template') ? undefined : json['subject_template'],
        'bodyTemplate': !exists(json, 'body_template') ? undefined : json['body_template'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'setupState': !exists(json, 'setup_state') ? undefined : json['setup_state'],
        'userEditingDisabled': !exists(json, 'user_editing_disabled') ? undefined : json['user_editing_disabled'],
        'fromUserInfo': !exists(json, 'from_user_info') ? undefined : FromUserInfoFromJSON(json['from_user_info']),
    };
}

export function HiringStageEmailTemplateToJSON(value?: HiringStageEmailTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hiring_pipeline_stage': value.hiringPipelineStage,
        'template_type': value.templateType,
        'name': value.name,
        'required_email_alias': value.requiredEmailAlias,
        'required_interviewer': value.requiredInterviewer,
        'has_same_interviewer_and_email_sender': value.hasSameInterviewerAndEmailSender,
        'cc_emails': value.ccEmails,
        'bcc_emails': value.bccEmails,
        'subject_template': value.subjectTemplate,
        'body_template': value.bodyTemplate,
        'state': value.state,
        'user_editing_disabled': value.userEditingDisabled,
        'from_user_info': FromUserInfoToJSON(value.fromUserInfo),
    };
}


