import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import React from "react";
import { useParams } from "react-router-dom";

import { useListHiringStageEmailTemplatesQuery } from "services/doverapi/endpoints/emailTemplates";
import {
  useGetAdminHiringPipelineStagesKeyedById,
  useGetAdminHiringPipelineStagesSorted,
} from "services/doverapi/endpoints/hiringPipelineStage";
import { HiringStageEmailTemplateTemplateTypeEnum, HiringStageEmailTemplate } from "services/openapi";

export const TemplatePicker = (): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();
  const { data: emailTemplates } = useListHiringStageEmailTemplatesQuery(jobId ?? skipToken);
  const hiringPipelineStages = useGetAdminHiringPipelineStagesSorted(jobId);

  const result: { [id: string]: any } = {};
  const grouped = groupBy(emailTemplates, "hiringPipelineStage");
  for (const hiringPipelineStage in grouped) {
    result[hiringPipelineStage] = groupBy(grouped[hiringPipelineStage], "templateType");
  }

  return (
    <>
      <Box>
        {hiringPipelineStages.map(hiringPipelineStage => (
          <HiringStageEmailTemplates
            hiringPipelineStageId={hiringPipelineStage.id!}
            templatesByTemplateType={result[hiringPipelineStage.id!]}
          />
        ))}
      </Box>
    </>
  );
};

interface HiringStageEmailTemplatesProps {
  hiringPipelineStageId: string;
  templatesByTemplateType: { [templateType: string]: HiringStageEmailTemplate[] };
}
const HiringStageEmailTemplates = ({
  hiringPipelineStageId,
  templatesByTemplateType,
}: HiringStageEmailTemplatesProps): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();
  const hiringPipelineStages = useGetAdminHiringPipelineStagesKeyedById(jobId);

  if (!templatesByTemplateType) {
    return <></>;
  }

  const templatesInOrder = [
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingInbound,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingInboundDoverInterviewer,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingInboundMultipart,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingInboundTakehome,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingSinglepart,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingMultipart,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingDoverInterviewer,
    HiringStageEmailTemplateTemplateTypeEnum.SchedulingTakeHome,
    HiringStageEmailTemplateTemplateTypeEnum.Rejection,
    HiringStageEmailTemplateTemplateTypeEnum.RejectionInbound,
  ];

  return (
    <Box>
      <h2>{hiringPipelineStages[hiringPipelineStageId].name}</h2>
      {templatesInOrder.map(templateType => (
        <Box>
          {templatesByTemplateType[templateType] && (
            <Box paddingBottom={1}>
              <Box color={grey[500]} fontWeight={700}>
                {templateType}
              </Box>
              <Box paddingX={2}>
                {orderBy(templatesByTemplateType[templateType], ["state"], ["asc"]).map(t => (
                  <TemplateLink template={t} />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

interface TemplateLinkProps {
  template: HiringStageEmailTemplate;
}
const TemplateLink = ({ template }: TemplateLinkProps): React.ReactElement => {
  const { jobId, emailTemplateId } = useParams<{ jobId: string; emailTemplateId: string }>();
  const isSelected = emailTemplateId === template.id;
  return (
    <Box
      display="flex"
      paddingBottom="3px"
      onClick={(): void => window.location.replace(`/admin/job/${jobId}/hiring-stage-email-templates/${template.id}`)}
      color={isSelected ? grey[900] : grey[700]}
      style={{ cursor: "pointer" }}
    >
      {isSelected && "*"}
      <span>{template.name}</span>
    </Box>
  );
};
