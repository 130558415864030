import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";

import SourceRow from "views/CompanySetup/components/Credentials/components/SourceRow";
import { DividerNoSpacing, InvisibleLink } from "views/CompanySetup/components/Credentials/components/Styles";
import { Source } from "views/CompanySetup/components/Credentials/types";

interface Props {
  sources: any[];
  onSourceClick: (source: Source) => void;
}

const SourcesList: React.FC<Props> = ({ sources, onSourceClick }) => {
  return (
    <Fragment>
      {sources.map(s => {
        return (
          <Fragment key={s.source}>
            <Row>
              <Col>
                <DividerNoSpacing />
              </Col>
            </Row>
            <InvisibleLink onClick={(): void => onSourceClick(s.source as Source)}>
              <SourceRow cred={s} source={s.source} />
            </InvisibleLink>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default SourcesList;
