/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchV3CandidateFeedback
 */
export interface SearchV3CandidateFeedback {
    /**
     * 
     * @type {string}
     * @memberof SearchV3CandidateFeedback
     */
    candidateId: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchV3CandidateFeedback
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchV3CandidateFeedback
     */
    rejectionReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchV3CandidateFeedback
     */
    linkedinProfileUrl?: string | null;
}

export function SearchV3CandidateFeedbackFromJSON(json: any): SearchV3CandidateFeedback {
    return SearchV3CandidateFeedbackFromJSONTyped(json, false);
}

export function SearchV3CandidateFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchV3CandidateFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
        'approved': json['approved'],
        'rejectionReason': !exists(json, 'rejection_reason') ? undefined : json['rejection_reason'],
        'linkedinProfileUrl': !exists(json, 'linkedin_profile_url') ? undefined : json['linkedin_profile_url'],
    };
}

export function SearchV3CandidateFeedbackToJSON(value?: SearchV3CandidateFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'approved': value.approved,
        'rejection_reason': value.rejectionReason,
        'linkedin_profile_url': value.linkedinProfileUrl,
    };
}


