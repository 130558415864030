import PropTypes from "prop-types";
import React, { useState } from "react";

import Label from "components/inputs/TileSelect/Label";
import { TileWrapper, HelperText } from "components/inputs/TileSelect/styles";
import Tile from "components/inputs/TileSelect/Tile";

const TileSelect = ({ label, options, value, onChange, required, invalid, helperText, scoringSelection }) => {
  const [activeValue, setActiveValue] = useState(value?.value);

  const handleTileSelect = (e, option) => {
    setActiveValue(option.value);
    onChange(e, option);
  };

  return (
    <>
      <Label label={label} required={required} error={invalid} />
      <TileWrapper value={value} halfWidth={options.length === 2} custom={scoringSelection}>
        {options &&
          options.map((option, i) => {
            return (
              <Tile
                {...option}
                active={option.value === activeValue}
                onClick={e => handleTileSelect(e, option)}
                key={`${option.value}-${i}`}
              />
            );
          })}
      </TileWrapper>
      {helperText && <HelperText error={invalid}>{helperText}</HelperText>}
    </>
  );
};

TileSelect.propTypes = {
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
      graphic: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.node,
  required: PropTypes.bool,
};

TileSelect.defaultProps = {};

export default TileSelect;
