import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import {
  calendlyRegex,
  savvyCalRegex,
  candidateFirstNameTemplateVarRegex,
  minNumberNameAppears,
  emailSenderNameRegex,
  subjectSenderNameRegex,
} from "components/dover/CampaignEditor/constants";
import { getInvalidTagsFromEmailMessages } from "components/dover/CampaignEditor/utils";
import { AddNewProUserModal } from "components/dover/Campaigns/AddNewProUserModal";
import { ModalKeyType } from "components/dover/Campaigns/types";
import { UpdateOrCreateCampaignModal } from "components/dover/Campaigns/UpdateOrCreateCampaignModal";
import { UpgradePlanPublishModal } from "components/dover/Campaigns/UpgradePlanPublishModal";
import GeneratingContentMessageWithMagicWand from "components/dover/GeneratingContentMessageWithMagicWand";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, Subtitle2 } from "components/library/typography";
import DoverLoadingOverlay, { DoverLoadingSpinner as Loading } from "components/loading-overlay";
import Confirm from "components/Modal/ConfirmModal";
import { DEFAULT_CAMPAIGN_MESSAGE_DELAY, SECONDS_IN_DAY } from "components/outreach-configuration/form/constants";
import { campaignsFormSchema, CampaignsFormSchemaType } from "components/outreach-configuration/form/types";
import { Spacer } from "components/Spacer";
import {
  selectFromListCampaignsQueryResult,
  useCreateCampaignMutation,
  useGenerateAndSaveCampaignContentMutation,
  useListCampaignsQuery,
  usePartialUpdateCampaignMutation,
} from "services/doverapi/endpoints/campaign";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import {
  Campaign,
  CampaignMessageCampaignMessageStateEnum,
  CreateCampaignEmailSenderOptionEnum,
  CreateCampaignMessageCampaignMessageStateEnum,
  ListCampaignSetupStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import CampaignCards from "views/job/JobSetup/steps/CampaignVariants/CampaignCards";
import CampaignEditor from "views/job/JobSetup/steps/CampaignVariants/CampaignEditor";
import { useShouldAttemptCampaignOutreachGeneration } from "views/job/JobSetup/steps/CampaignVariants/hooks";
import { ConfirmSaveContent } from "views/job/JobSetup/steps/CampaignVariants/modals/ConfirmSaveModal";
import { ConfirmUnsavedChangesModal } from "views/job/JobSetup/steps/CampaignVariants/modals/ConfirmUnsavedChangesModal";
import { AddNewCampaignProps, CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";
import { getCampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/utils";

const CampaignVariants = ({ onSourcingPage }: { onSourcingPage?: boolean }): React.ReactElement => {
  // parse URL
  const [selectedCampaignId, setSelectedCampaignId] = useQueryParam("campaign", StringParam);
  const { jobId } = useParams<{ jobId: string }>();

  /* Fetch data */
  const [createCampaign, { isLoading: isCreatingCampaign }] = useCreateCampaignMutation();
  const { allCampaigns: campaigns, selectedCampaign, isSuccess, isFetching: campaignsFetching } = useListCampaignsQuery(
    jobId ? { jobId } : skipToken,
    {
      selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults, { selectedCampaignId }),
    }
  );
  const { isFetching: isFetchingJobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);
  const { data: jobSetup, isFetching: jobSetupFetching } = useGetJobSetupQuery(jobId ?? skipToken);
  const { data: userDefinedSenderUser } = useGetProUserQuery(
    (selectedCampaign ? (selectedCampaign?.userDefinedSenderUser as unknown) : skipToken) as string
  );

  // This handles an edge case where the user selects on a campaign with a user defined sender user
  // then selects another campaign without a user defined sender user.
  // We have to ensure that the selectedCampaign has a user defined sender user, otherwise the
  // userDefinedSenderUser variable from the getProUserQuery will not update because the skipToken is used
  // and userDefinedSenderUser will still reference the one from the previous campaign.
  const senderUser = selectedCampaign?.userDefinedSenderUser ? userDefinedSenderUser : undefined;

  const shouldAttemptCampaignOutreachGeneration = useShouldAttemptCampaignOutreachGeneration(jobId, false);
  const [
    generateAndSaveCampaignContent,
    { isLoading: isGeneratingCampaignContent },
  ] = useGenerateAndSaveCampaignContentMutation();
  // setup up mutations
  const [partialUpdateCampaign, { isLoading: isUpdatingCampaign }] = usePartialUpdateCampaignMutation();

  // form state
  const campaignsForm = useForm<CampaignsFormSchemaType>({
    defaultValues: { campaignEditors: [{}], isDirty: false },
    resolver: zodResolver(campaignsFormSchema),
  });
  const campaignsFormValues = useWatch({ control: campaignsForm.control });

  /* Local state */
  const [openModal, setOpenModal] = useState<ModalKeyType>(ModalKeyType.None);
  const [creatingNewCampaign, setCreatingNewCampaign] = useState<boolean>(false);
  const [isSavingDraft, setIsSavingDraft] = useState<boolean>(false);
  const [isAddingMessage, setIsAddingMessage] = useState<boolean>(false);
  const [hasAttemptedToGenerateAndSaveCampaignContent, setHasAttemptedToGenerateAndSaveCampaignContent] = useState<
    boolean
  >(false);
  const [selectedOtherUser, setSelectedOtherUser] = useState<number | undefined>();
  const [passingQaOverride, setPassingQaOverride] = useState(false);
  const isDataLoading = campaignsFetching || isCreatingCampaign;

  // derived values
  const emailMessages = React.useMemo<string | undefined>(() => {
    const campaignEditors = campaignsFormValues?.campaignEditors;
    if (!campaignEditors || campaignEditors.length === 0) {
      return undefined;
    }

    return campaignEditors.reduce((acc, curr) => {
      return acc + (curr.rawEditorState as any)?.bodyHtml ?? "";
    }, "");
  }, [campaignsFormValues?.campaignEditors]);

  const passingSubjectLine = React.useMemo(() => {
    const campaignEditors = campaignsFormValues?.campaignEditors;
    if (!campaignEditors || campaignEditors.length === 0) {
      return true;
    }
    const rawState = campaignEditors[0].rawEditorState;
    // @ts-ignore
    if (rawState && rawState.subjectHtml) {
      // @ts-ignore
      return !rawState.subjectHtml.match(subjectSenderNameRegex);
    }
    return true;
  }, [campaignsFormValues?.campaignEditors]);

  const passingCalendlyQa = React.useMemo(() => {
    return !emailMessages?.match(calendlyRegex);
  }, [emailMessages]);

  const passingSavvyQa = React.useMemo(() => {
    return !emailMessages?.match(savvyCalRegex);
  }, [emailMessages]);

  const passingNameQa = React.useMemo(() => {
    if (passingQaOverride) {
      return true;
    }

    let nameMatchesCount = 0;
    // If the user has defined a first name, we want to make sure it appears at least once in the email.
    if (senderUser?.firstName) {
      const regexName = new RegExp(senderUser.firstName, "g");
      nameMatchesCount = (emailMessages?.match(regexName) || []).length;
    }

    const emailSenderNameRegexMatchesCount = (emailMessages?.match(emailSenderNameRegex) || []).length;
    const count = nameMatchesCount + emailSenderNameRegexMatchesCount;

    return count >= minNumberNameAppears;
  }, [emailMessages, passingQaOverride, senderUser?.firstName]);

  const passingCandidateFirstNameTempVar = React.useMemo(() => {
    if (!campaignsFormValues?.campaignEditors?.[0]) {
      return false;
    }
    return (campaignsFormValues.campaignEditors[0].rawEditorState as any)?.bodyHtml?.match(
      candidateFirstNameTemplateVarRegex
    );
  }, [campaignsFormValues?.campaignEditors]);

  const emptySubject = React.useMemo(() => {
    // Until form data is initalized, we can't fairly determine if the subject is empty
    if (!campaignsFormValues?.campaignEditors?.[0]) {
      return false;
    }

    return (campaignsFormValues.campaignEditors[0].rawEditorState as any)?.subjectHtml == "<p></p>";
  }, [campaignsFormValues]);

  const invalidTags = React.useMemo(() => {
    return getInvalidTagsFromEmailMessages(emailMessages);
  }, [emailMessages]);

  const onlyNameEditable = useMemo<boolean>(
    () =>
      [ListCampaignSetupStateEnum.StartedPendingDoverAction, ListCampaignSetupStateEnum.Complete].includes(
        selectedCampaign?.setupState!
      ),
    [selectedCampaign]
  );

  /* Local vars */
  const jobSetupFetchingOrCampaignUpdating = campaignsFetching || jobSetupFetching || isFetchingJobFeatures;
  const campaignName = selectedCampaign?.name;
  const campaignEmailSenderOption = selectedCampaign?.emailSenderOption;
  const campaignStatus = selectedCampaign
    ? getCampaignStatus(selectedCampaign.state!, selectedCampaign.setupState!)
    : undefined;
  const campaignIsDraft = selectedCampaign && campaignStatus === CampaignStatus.Draft;

  let confirmSaveModalTitle = "Publish campaign";
  let confirmSaveModalSaveText = "Save and make this version live";
  let saveButtonText = "Save";

  /* if the campaign is inactive, simply display save instead of publish */
  if (campaignStatus && campaignStatus === CampaignStatus.Inactive) {
    confirmSaveModalTitle = "Save campaign";
    confirmSaveModalSaveText = "Save campaign";
    saveButtonText = "Save";
  }

  // effects
  React.useEffect(() => {
    if (!selectedCampaign?.threadMessages) {
      return;
    }

    // Set the form value any time the selected campaign changes, including first load
    campaignsForm.setValue(
      "campaignEditors",
      selectedCampaign.threadMessages.map(message => {
        return {
          rawEditorState: message.rawEditorState ?? {
            subjectHtml: message.subjectTemplate ?? "",
            bodyHtml: message.bodyTemplate ?? "",
          },
          id: message.messageId,
          enabled: message.campaignMessageState !== CampaignMessageCampaignMessageStateEnum.Inactive,
          minMessageDelay: message.minMessageDelay
            ? // @ts-ignore
              parseFloat(message.minMessageDelay) * SECONDS_IN_DAY
            : DEFAULT_CAMPAIGN_MESSAGE_DELAY,
        };
      })
    );

    campaignsForm.setValue("isDirty", false);
  }, [campaignsForm, selectedCampaign, selectedCampaign?.threadMessages]);

  useEffect(() => {
    if (selectedCampaign?.userDefinedSenderUser) {
      setSelectedOtherUser(selectedCampaign?.userDefinedSenderUser);
    }
  }, [selectedCampaign?.userDefinedSenderUser]);

  useEffect(() => {
    if (isSuccess && typeof campaigns !== "undefined" && campaigns.length > 0 && !selectedCampaignId) {
      setSelectedCampaignId(campaigns[0].id);
    }
  }, [campaigns, isSuccess, selectedCampaignId, setSelectedCampaignId]);

  /* Conditionally generate first outreach */
  useEffect(() => {
    async function generateAndSaveCampaignContentAPI(): Promise<void> {
      await generateAndSaveCampaignContent({
        campaignId: jobSetup?.campaignId!,
      });
      setHasAttemptedToGenerateAndSaveCampaignContent(true);
    }
    if (
      !hasAttemptedToGenerateAndSaveCampaignContent &&
      shouldAttemptCampaignOutreachGeneration &&
      jobSetup?.campaignId
    ) {
      generateAndSaveCampaignContentAPI();
    }
  }, [
    generateAndSaveCampaignContent,
    hasAttemptedToGenerateAndSaveCampaignContent,
    jobSetup?.campaignId,
    shouldAttemptCampaignOutreachGeneration,
  ]);

  // callbacks
  const handleAddNewCampaign = async ({
    campaignName,
    emailSenderOption,
    userDefinedSenderUser,
  }: AddNewCampaignProps): Promise<void> => {
    if (!jobId) {
      return;
    }

    const newCampaign = await createCampaign({
      jobId,
      name: campaignName ?? `Variant ${campaigns ? (campaigns.length + 1).toString() : "1"}`,
      campaignToClone: selectedCampaignId ?? campaigns?.[0]?.id,
      emailSenderOption: emailSenderOption,
      userDefinedSenderUser: userDefinedSenderUser,
    }).unwrap();
    setSelectedCampaignId(newCampaign?.id);
  };

  const handleAddNewMessage = async (): Promise<void> => {
    if (jobId && selectedCampaignId && selectedCampaign) {
      const lastMessage = selectedCampaign?.threadMessages
        ? selectedCampaign?.threadMessages[selectedCampaign?.threadMessages?.length - 1]
        : null;

      setIsAddingMessage(true);

      await partialUpdateCampaign({
        id: selectedCampaignId,
        jobId: jobId,
        updatedCampaign: {
          newMessages: [
            {
              campaignMessageState: CreateCampaignMessageCampaignMessageStateEnum.Inactive,
              minMessageDelay: 3 * SECONDS_IN_DAY,
              prevMessage: lastMessage ? lastMessage.messageId : null,
            },
          ],
          name: selectedCampaign.name!,
        },
      });

      setIsAddingMessage(false);
    }
  };

  const handleUpdateCampaignInternal = React.useCallback(
    ({ values, saveAsDraft }: { values: CampaignsFormSchemaType; saveAsDraft: boolean }) => {
      const execute = async (values: CampaignsFormSchemaType, saveAsDraft: boolean): Promise<void> => {
        if (!jobId) {
          return;
        }

        if (!values || !selectedCampaignId) {
          return;
        }
        if (saveAsDraft) {
          setIsSavingDraft(true);
        }
        const updatedCampaign: Required<Pick<Campaign, "updatedMessages" | "saveAsDraft">> = {
          saveAsDraft,
          updatedMessages: [],
        };

        updatedCampaign.updatedMessages = (values.campaignEditors ?? []).map(message => {
          const campaignMessageState = message.enabled
            ? CampaignMessageCampaignMessageStateEnum.Active
            : CampaignMessageCampaignMessageStateEnum.Inactive;

          return {
            messageId: message.id,
            rawEditorState: message.rawEditorState as object,
            campaignMessageState,
            minMessageDelay: message.minMessageDelay,
          };
        });

        await partialUpdateCampaign({
          id: selectedCampaignId,
          jobId: jobId,
          updatedCampaign: updatedCampaign as Campaign,
        }).unwrap();

        setIsSavingDraft(false);
        // reset form dirtiness
        campaignsForm.setValue("isDirty", false);
      };

      execute(values, saveAsDraft);
    },
    [campaignsForm, jobId, partialUpdateCampaign, selectedCampaignId]
  );

  const handleUpdateCampaign = React.useCallback(() => {
    handleUpdateCampaignInternal({ values: campaignsFormValues as CampaignsFormSchemaType, saveAsDraft: false });
  }, [campaignsFormValues, handleUpdateCampaignInternal]);

  const handleUpdateCampaignAsDraft = React.useCallback(() => {
    handleUpdateCampaignInternal({ values: campaignsFormValues as CampaignsFormSchemaType, saveAsDraft: true });
  }, [campaignsFormValues, handleUpdateCampaignInternal]);

  if (!jobSetup?.setupOutreachState || !jobSetup?.setupJobPitchState) {
    return <Loading />;
  }

  if (onSourcingPage) {
    return (
      <FormProvider {...campaignsForm}>
        <Subtitle2>Email Campaigns</Subtitle2>

        {isSuccess && (campaigns ?? []).length > 0 && (
          <CampaignCards
            campaigns={campaigns}
            selectedCampaignId={selectedCampaignId!}
            setSelectedCampaignId={setSelectedCampaignId}
          />
        )}
      </FormProvider>
    );
  }

  return (
    <FormProvider {...campaignsForm}>
      <Box maxWidth="800px" margin={"auto"}>
        <>
          <AddNewProUserModal
            shouldShowModal={openModal}
            handleCloseModal={(): void => {
              setOpenModal(ModalKeyType.None);
            }}
            handleOpenUpdateOrCreateCampaignModal={(): void => {
              setOpenModal(ModalKeyType.UpdateOrCreateCampaign);
            }}
            modalKey={ModalKeyType.AddNewProUser}
            setSelectedOtherUser={setSelectedOtherUser}
          />

          <UpdateOrCreateCampaignModal
            isNewCampaign={creatingNewCampaign}
            handleAddNewCampaign={handleAddNewCampaign}
            initialCampaignName={creatingNewCampaign ? undefined : campaignName}
            initialEmailSenderOption={
              creatingNewCampaign
                ? undefined
                : ((campaignEmailSenderOption as unknown) as CreateCampaignEmailSenderOptionEnum)
            }
            selectedOtherUser={selectedOtherUser}
            setSelectedOtherUser={setSelectedOtherUser}
            campaignId={selectedCampaignId!}
            shouldShowModal={openModal}
            handleCloseModal={(): void => {
              setOpenModal(ModalKeyType.None);
              setCreatingNewCampaign(false);
            }}
            handleOpenProUserModal={(): void => {
              setOpenModal(ModalKeyType.AddNewProUser);
            }}
            modalKey={ModalKeyType.UpdateOrCreateCampaign}
            onlyNameEditable={creatingNewCampaign ? false : onlyNameEditable}
          />

          <UpgradePlanPublishModal
            shouldShowModal={openModal}
            handleCloseModal={(): void => {
              setOpenModal(ModalKeyType.None);
            }}
            handleSaveCampaign={handleUpdateCampaignAsDraft}
            modalKey={ModalKeyType.UpgradePlanPublish}
          />

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <a
              href={"https://help.dover.com/en/articles/6308713-how-to-optimize-outreach"}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <HelpIcon color={colors.link} className="svg-color" />
                <BodySmall color={colors.link}>How to optimize outreach</BodySmall>
              </Stack>
            </a>

            {!isGeneratingCampaignContent && (
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Confirm
                  title={<Body>You may have unpublished changes.</Body>}
                  submitText={`Discard Changes`}
                  content={<ConfirmUnsavedChangesModal />}
                  useProcessing
                  submitVariant="critical"
                >
                  {(confirm: any): React.ReactNode => (
                    <Button
                      variant={ButtonVariant.Primary}
                      onClick={
                        campaignsFormValues.isDirty
                          ? confirm((): void => {
                              setCreatingNewCampaign(true);
                              setOpenModal(ModalKeyType.UpdateOrCreateCampaign);
                            })
                          : (): void => {
                              setCreatingNewCampaign(true);
                              setOpenModal(ModalKeyType.UpdateOrCreateCampaign);
                            }
                      }
                    >
                      + New Campaign
                    </Button>
                  )}
                </Confirm>
              </Box>
            )}
            <Spacer height="24px" />
          </Box>

          {isSuccess && (campaigns ?? []).length > 0 && (
            <Box py="12px">
              <CampaignCards
                campaigns={campaigns}
                selectedCampaignId={selectedCampaignId!}
                setSelectedCampaignId={setSelectedCampaignId}
              />
            </Box>
          )}
          {isGeneratingCampaignContent && (
            <GeneratingContentMessageWithMagicWand message="Auto-generating a draft of your outreach messaging" />
          )}
          {isDataLoading && <Loading />}

          {!isDataLoading &&
            !isGeneratingCampaignContent &&
            isSuccess &&
            campaigns &&
            selectedCampaignId &&
            selectedCampaign?.threadMessages?.[0]?.bodyTemplate && (
              <CampaignEditor
                selectedCampaignId={selectedCampaignId!}
                campaign={selectedCampaign}
                setOpenModal={setOpenModal}
                onlyNameEditable={onlyNameEditable}
                showDeleteButton={campaigns.length > 1}
                handleAddNewMessage={handleAddNewMessage}
              />
            )}
          <Spacer height={24} />
          <Box display="flex" justifyContent="flex-end" sx={{ paddingBottom: "24px" }}>
            {campaignIsDraft && (
              <Button
                variant={ButtonVariant.Secondary}
                disabled={jobSetupFetchingOrCampaignUpdating}
                onClick={handleUpdateCampaignAsDraft}
              >
                <BodySmall>Save as Draft</BodySmall>
              </Button>
            )}
            <Spacer width={16} />

            <Confirm
              title={confirmSaveModalTitle}
              submitText={isUpdatingCampaign ? `Saving...` : confirmSaveModalSaveText}
              cancelText={passingNameQa ? "Cancel" : "Go back and change"}
              content={
                <ConfirmSaveContent
                  campaign={selectedCampaign}
                  campaignStatus={campaignStatus}
                  passingQa={passingNameQa}
                  setPassingQa={setPassingQaOverride}
                  passingCalendly={passingCalendlyQa}
                  passingSubjectLine={passingSubjectLine}
                  passingSavvy={passingSavvyQa}
                  emptySubject={emptySubject}
                  invalidTags={invalidTags}
                  senderUserFullName={userDefinedSenderUser?.fullName ?? ""}
                  passingCandidateFirstNameTempVar={passingCandidateFirstNameTempVar}
                />
              }
              useProcessing
              processing={isUpdatingCampaign && !isSavingDraft && !isAddingMessage}
              submitDisabled={
                !passingNameQa ||
                !passingCalendlyQa ||
                !passingSavvyQa ||
                invalidTags.length > 0 ||
                emptySubject ||
                !passingCandidateFirstNameTempVar
              }
            >
              {(confirm: any): React.ReactNode => (
                <Button
                  variant={ButtonVariant.Primary}
                  disabled={jobSetupFetchingOrCampaignUpdating}
                  onClick={confirm(handleUpdateCampaign)}
                >
                  <BodySmall weight="500" color={colors.white}>
                    {saveButtonText}
                  </BodySmall>
                </Button>
              )}
            </Confirm>
          </Box>
          <Confirm
            title={<Body>You may have unpublished changes.</Body>}
            submitText={`Discard Changes`}
            content={<ConfirmUnsavedChangesModal />}
            useProcessing
            submitVariant="critical"
          >
            {/* TODO (trevor): not actually sure what's needed here if there's nothing to render*/}
            {(): React.ReactNode => <></>}
          </Confirm>
          {/* Loading state when saving as a draft */}
          {isSavingDraft && <DoverLoadingOverlay fullScreen overlay active />}
        </>
      </Box>
    </FormProvider>
  );
};

export default CampaignVariants;
