// @ts-ignore
export const setNewRelicCustomAttributes = (user, isAdmin, clientId): void => {
  try {
    // NewRelic is already loaded in the window via NewRelic APM, loading custom attributes
    // @ts-ignore
    window.newrelic.setCustomAttribute("userId", user?.id);
    // @ts-ignore
    window.newrelic.setCustomAttribute("userEmail", user?.email);
    // @ts-ignore
    window.newrelic.setCustomAttribute("isAdmin", isAdmin);
    // @ts-ignore
    window.newrelic.setCustomAttribute("clientId", clientId);
  } catch (e) {
    console.warn("Could not identify user for NewRelic custom attributes");
  }
};
