/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationPortalInboundApplication
 */
export interface ApplicationPortalInboundApplication {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    jobId: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    referrerSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    linkedinUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    readonly resume?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPortalInboundApplication
     */
    additionalInfo?: string;
    /**
     * 
     * @type {object}
     * @memberof ApplicationPortalInboundApplication
     */
    applicationQuestions?: object;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPortalInboundApplication
     */
    bookmarked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationPortalInboundApplication
     */
    optedInToTalentNetwork?: boolean | null;
}

export function ApplicationPortalInboundApplicationFromJSON(json: any): ApplicationPortalInboundApplication {
    return ApplicationPortalInboundApplicationFromJSONTyped(json, false);
}

export function ApplicationPortalInboundApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationPortalInboundApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': json['job_id'],
        'referrerSource': !exists(json, 'referrer_source') ? undefined : json['referrer_source'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'email': json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'resume': !exists(json, 'resume') ? undefined : json['resume'],
        'additionalInfo': !exists(json, 'additional_info') ? undefined : json['additional_info'],
        'applicationQuestions': !exists(json, 'application_questions') ? undefined : json['application_questions'],
        'bookmarked': !exists(json, 'bookmarked') ? undefined : json['bookmarked'],
        'optedInToTalentNetwork': !exists(json, 'opted_in_to_talent_network') ? undefined : json['opted_in_to_talent_network'],
    };
}

export function ApplicationPortalInboundApplicationToJSON(value?: ApplicationPortalInboundApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_id': value.jobId,
        'referrer_source': value.referrerSource,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'linkedin_url': value.linkedinUrl,
        'email': value.email,
        'phone_number': value.phoneNumber,
        'additional_info': value.additionalInfo,
        'application_questions': value.applicationQuestions,
        'bookmarked': value.bookmarked,
        'opted_in_to_talent_network': value.optedInToTalentNetwork,
    };
}


