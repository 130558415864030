import { Grid } from "@mui/material";
import React from "react";
import { useField, useForm } from "react-final-form";

import { Spacer } from "components/Spacer";
import { B1_doNotUse } from "styles/typography";
import { B2, StyledTextField } from "views/CompanySetup/components/InterviewPreferences/styles";

export const MaxInterviewsPerDay = ({ setIsDirty }: { setIsDirty: (value: boolean) => void }): React.ReactElement => {
  const form = useForm();
  const maxInterviewsPerDayField = useField("maxInterviewsPerDay");

  return (
    <Grid container direction="column">
      <Grid item>
        <B1_doNotUse medium>What is the max number of interviews you want scheduled per day?</B1_doNotUse>
        <B2>
          This applies only to interviews booked through Dover scheduling links and does not include onsites or other
          multi-part interviews.
        </B2>
        <Spacer height="16px" />
      </Grid>

      <Grid item>
        <StyledTextField
          variant={"standard"}
          onChange={(e): void => {
            setIsDirty(true);
            form.change(maxInterviewsPerDayField.input.name, e.target.value || null);
          }}
          type="number"
          placeholder="No limit"
          value={maxInterviewsPerDayField.input.value || null}
        />
      </Grid>
    </Grid>
  );
};
