/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindAtsCandidateRequest
 */
export interface FindAtsCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof FindAtsCandidateRequest
     */
    candidateAtsLink?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAtsCandidateRequest
     */
    candidateEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof FindAtsCandidateRequest
     */
    linkedinProfileUrl?: string;
}

export function FindAtsCandidateRequestFromJSON(json: any): FindAtsCandidateRequest {
    return FindAtsCandidateRequestFromJSONTyped(json, false);
}

export function FindAtsCandidateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAtsCandidateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateAtsLink': !exists(json, 'candidate_ats_link') ? undefined : json['candidate_ats_link'],
        'candidateEmail': !exists(json, 'candidate_email') ? undefined : json['candidate_email'],
        'linkedinProfileUrl': !exists(json, 'linkedin_profile_url') ? undefined : json['linkedin_profile_url'],
    };
}

export function FindAtsCandidateRequestToJSON(value?: FindAtsCandidateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_ats_link': value.candidateAtsLink,
        'candidate_email': value.candidateEmail,
        'linkedin_profile_url': value.linkedinProfileUrl,
    };
}


