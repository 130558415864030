/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BeginBillingPortalSessionRequest
 */
export interface BeginBillingPortalSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof BeginBillingPortalSessionRequest
     */
    returnUrl: string;
}

export function BeginBillingPortalSessionRequestFromJSON(json: any): BeginBillingPortalSessionRequest {
    return BeginBillingPortalSessionRequestFromJSONTyped(json, false);
}

export function BeginBillingPortalSessionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BeginBillingPortalSessionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'returnUrl': json['return_url'],
    };
}

export function BeginBillingPortalSessionRequestToJSON(value?: BeginBillingPortalSessionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'return_url': value.returnUrl,
    };
}


