import { sortBy } from "lodash";
import { useMemo } from "react";

import { useListInterviewersQuery } from "services/doverapi/endpoints/interviewer";
import { useListJobsQuery } from "services/doverapi/endpoints/job";
import { selectJobById } from "services/doverapi/endpoints/job/endpoints";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import {
  ClientInterviewer,
  HiringPipelineStageMilestone,
  JobFeatureFeatureNameEnum,
  SubstageType,
} from "services/openapi";

export const DOVER_INTERVIEWER_ID = "DOVER_INTERVIEWER_ID";
export const DOVER_INTERVIEWER: ClientInterviewer = {
  email: "Dover Interviewer",
  fullName: "Dover Interviewer",
  id: DOVER_INTERVIEWER_ID,
  hasSchedulingLinkOverride: false,
  client: "",
};

export interface NextInterviewerHiringPipelineStage {
  id: string;
  jobId: string;
  milestone: HiringPipelineStageMilestone | null;
  // TODO: Figure out how to make this not undefined...
  contentTypeName?: SubstageType;
}

const usePossibleInterviewers = ({
  desiredHiringPipelineStage,
  includeDoverInterviewerOverride,
  doverInterviewerIdOverride,
}: {
  desiredHiringPipelineStage?: NextInterviewerHiringPipelineStage;
  includeDoverInterviewerOverride?: boolean;
  doverInterviewerIdOverride?: string;
}): {
  possibleInterviewers: ClientInterviewer[];
  showDoverInterviewer: boolean;
  isFetchingInterviewers: boolean;
  isErrorInterviewers: boolean;
  isLoadingInterviewers: boolean;
  isUninitializedInterviewers: boolean;
  isSuccessInterviewers: boolean;
  doverInterviewerValue: ClientInterviewer;
} => {
  // redux state
  const { data: jobEntities } = useListJobsQuery({});
  const job =
    jobEntities && desiredHiringPipelineStage?.jobId
      ? selectJobById(jobEntities, desiredHiringPipelineStage.jobId)
      : undefined;
  const {
    data: unsortedInterviewersEntityAdapter,
    isFetching: isFetchingInterviewers,
    isError: isErrorInterviewers,
    isLoading: isLoadingInterviewers,
    isUninitialized: isUninitializedInterviewers,
    isSuccess: isSuccessInterviewers,
  } = useListInterviewersQuery();

  const isDoverInterviewerEnabled = !!useGetIsFeatureEnabled({
    jobId: desiredHiringPipelineStage?.jobId,
    featureName: JobFeatureFeatureNameEnum.DoverInterviewer,
  });

  const unsortedInterviewers = listAllEntities(unsortedInterviewersEntityAdapter);
  const doverInterviewer = useMemo(() => {
    if (doverInterviewerIdOverride) {
      return { ...DOVER_INTERVIEWER, id: doverInterviewerIdOverride };
    }
    return DOVER_INTERVIEWER;
  }, [doverInterviewerIdOverride]);

  const showDoverInterviewer =
    desiredHiringPipelineStage?.milestone === HiringPipelineStageMilestone.INITIAL_CALL &&
    !!job &&
    isDoverInterviewerEnabled;

  // memoized state
  const possibleInterviewers: ClientInterviewer[] = useMemo(() => {
    if (!unsortedInterviewers) {
      return [];
    }
    const sortedInterviewers = sortBy(unsortedInterviewers, "email");
    const addDoverInterviewerOption =
      includeDoverInterviewerOverride !== undefined
        ? includeDoverInterviewerOverride
        : showDoverInterviewer && !includeDoverInterviewerOverride;

    return addDoverInterviewerOption ? [doverInterviewer].concat(sortedInterviewers) : sortedInterviewers;
  }, [unsortedInterviewers, showDoverInterviewer, includeDoverInterviewerOverride, doverInterviewer]);

  return {
    possibleInterviewers,
    showDoverInterviewer,
    isFetchingInterviewers,
    doverInterviewerValue: doverInterviewer,
    isErrorInterviewers,
    isLoadingInterviewers: !isLoadingInterviewers && !isUninitializedInterviewers,
    isSuccessInterviewers,
    isUninitializedInterviewers: isUninitializedInterviewers,
  };
};

export default usePossibleInterviewers;
