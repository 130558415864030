/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * List of substages for this interview panel.
 * @export
 * @interface InterviewPanelSubstage
 */
export interface InterviewPanelSubstage {
    /**
     * ID of original InterviewStage or MultipartInterviewSubstage
     * @type {string}
     * @memberof InterviewPanelSubstage
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InterviewPanelSubstage
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InterviewPanelSubstage
     */
    duration: number;
    /**
     * List of possible interviewers for this substage.
     * @type {Array<string>}
     * @memberof InterviewPanelSubstage
     */
    possibleInterviewers: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InterviewPanelSubstage
     */
    interviewersRequired?: InterviewPanelSubstageInterviewersRequiredEnum;
    /**
     * 
     * @type {number}
     * @memberof InterviewPanelSubstage
     */
    orderIndex: number;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewPanelSubstage
     */
    orderRequired: boolean;
}

/**
* @export
* @enum {string}
*/
export enum InterviewPanelSubstageInterviewersRequiredEnum {
    Any = 'ANY',
    All = 'ALL'
}

export function InterviewPanelSubstageFromJSON(json: any): InterviewPanelSubstage {
    return InterviewPanelSubstageFromJSONTyped(json, false);
}

export function InterviewPanelSubstageFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewPanelSubstage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'duration': json['duration'],
        'possibleInterviewers': json['possible_interviewers'],
        'interviewersRequired': !exists(json, 'interviewers_required') ? undefined : json['interviewers_required'],
        'orderIndex': json['order_index'],
        'orderRequired': json['order_required'],
    };
}

export function InterviewPanelSubstageToJSON(value?: InterviewPanelSubstage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'duration': value.duration,
        'possible_interviewers': value.possibleInterviewers,
        'interviewers_required': value.interviewersRequired,
        'order_index': value.orderIndex,
        'order_required': value.orderRequired,
    };
}


