import { Button as DoverButton } from "@doverhq/dover-ui";
import { OpenInNew } from "@mui/icons-material";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import Emoji from "components/Emoji";
import { Spacer } from "components/Spacer";
import { useLazyGetInitialCallInterviewQuery } from "services/doverapi/endpoints/candidate";
import { InterviewAndCandidateIdData } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useLazyGetInterviewRubricResponseQuery } from "services/doverapi/endpoints/candidateInterview";
import { useCurrentDoverEntity } from "services/doverapi/endpoints/doverEntity/customHooks";
import {
  useGetCandidatesInSchedulingQuery,
  useReplaceInterviewerMutation,
} from "services/doverapi/endpoints/interview";
import { InterviewSubstageEnum, ShortenedCandidate } from "services/openapi";
import { createAndOpenFeedbackForm } from "utils/candidate";
import ConfirmCancelInterviewModal from "views/admin/RecentInterviews/components/ConfirmCancelInterviewModal";

const CandidateCell = styled(TableCell)`
  cursor: pointer;

  &:hover {
    background-color: ${(props): string => props.theme.colors.grayscale.gray200};
  }
`;

const CandidatesInScheduling = (): React.ReactElement => {
  const [cancelInterviewConfirmationModalOpen, setCancelInterviewConfirmationModalOpen] = useState<boolean>(false);
  const [cancelCandidate, setCancelCandidate] = useState<ShortenedCandidate | undefined>(undefined);
  const navigate = useNavigate();

  const [lazyGetInterviewId] = useLazyGetInitialCallInterviewQuery();
  const [getOrCreateIRR] = useLazyGetInterviewRubricResponseQuery();

  const currentDoverEntity = useCurrentDoverEntity();
  const { data: candidates } = useGetCandidatesInSchedulingQuery(
    currentDoverEntity?.id ? { doverEntityId: currentDoverEntity.id } : skipToken
  );
  const cancelModalText =
    "If you absolutely cannot attend this interview select yes below to cancel," +
    " this will also notify the candidate and ask them to reschedule.";

  const cancelModalTextError = "No replacement interviewer could be found for this candidate. ";

  const [replaceInterviewer, { isError }] = useReplaceInterviewerMutation();
  React.useEffect(() => {
    if (isError) {
      setCancelInterviewConfirmationModalOpen(true);
    }
  }, [isError]);

  const scheduledCandidates: ShortenedCandidate[] = [];
  const schedulingCandidates: ShortenedCandidate[] = [];
  (candidates ?? []).forEach(candidate => {
    if (candidate.currentPipelineSubstage === InterviewSubstageEnum.SCHEDULED) {
      scheduledCandidates.push(candidate);
    } else {
      schedulingCandidates.push(candidate);
    }
  });

  return (
    <>
      <ConfirmCancelInterviewModal
        open={cancelInterviewConfirmationModalOpen}
        onClose={(): void => setCancelInterviewConfirmationModalOpen(false)}
        candidate={cancelCandidate}
        modalText={isError ? cancelModalTextError + cancelModalText : cancelModalText}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Stage</strong>
              </TableCell>
              <TableCell>
                <strong>Candidate Name</strong>
              </TableCell>
              <TableCell>
                <strong>Job Name</strong>
              </TableCell>
              <TableCell>
                <strong>Dover Rubric</strong>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scheduledCandidates
              .concat(schedulingCandidates)
              .filter((candidate: ShortenedCandidate) => !!candidate.id)
              .map((candidate: ShortenedCandidate) => {
                return (
                  <TableRow key={candidate.id}>
                    <TableCell>
                      <Typography variant="body1">
                        {candidate?.currentPipelineSubstage === InterviewSubstageEnum.SCHEDULED
                          ? "Scheduled"
                          : "In Scheduling"}
                      </Typography>
                    </TableCell>
                    <CandidateCell
                      onClick={(): void => {
                        if (candidate?.id) {
                          navigate(APP_ROUTE_PATHS.candidates.candidateDetail(candidate.id));
                        }
                      }}
                    >
                      <Typography variant="body1">{candidate?.contact?.fullName}</Typography>
                    </CandidateCell>
                    <TableCell>
                      <Typography variant="body1">{candidate?.job?.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <DoverButton
                        onPress={async (): Promise<void> => {
                          lazyGetInterviewId(candidate.id!)
                            .unwrap()
                            .then((result: InterviewAndCandidateIdData) =>
                              createAndOpenFeedbackForm(getOrCreateIRR, candidate.id!, result.interview.id, false)
                            );
                        }}
                      >
                        <span>View</span>&nbsp;
                        <OpenInNew fontSize="small" />
                      </DoverButton>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Replace interviewer" placement="top">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={(): void => {
                            setCancelCandidate(candidate);
                            if (candidate.id) {
                              replaceInterviewer({ candidateId: candidate.id });
                            }
                          }}
                        >
                          Replace Interviewer
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Cancel interview and ask candidate to reschedule." placement="top">
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={(): void => {
                            setCancelCandidate(candidate);
                            setCancelInterviewConfirmationModalOpen(true);
                          }}
                        >
                          Cancel and Reschedule
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {(candidates ?? []).length === 0 && (
        <>
          <Spacer height="40px" />
          <Grid container justifyContent="center">
            <Typography variant="h5">
              Waiting for more candidates <Emoji symbol="⏳️" label="celebrate" />
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
};

export default CandidatesInScheduling;
