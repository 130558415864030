import { HiringPipelineStage } from "services/openapi";
import { isInterviewStage, isStage } from "utils/isStage";
import { useInterviewPlanStageCounts } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useInterviewPlanStageCounts";
import { useInterviewPlanStages } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useInterviewPlanStages";

// Note this should be kept in sync with the backend value
// dover_core/features/hiring_pipeline/services.py
const MAX_NUM_INTERVIEWS_ALLOWED = 15;

export interface CanDetail {
  can: boolean;
  reason?: string;
}
interface CanStageReturn {
  canEdit: CanDetail;
  canHotswap: CanDetail;
  canMove: CanDetail;
  canDisable: CanDetail;
  canAdd: CanDetail;
}

export const useCanStage = (stage: HiringPipelineStage): CanStageReturn => {
  const { stages } = useInterviewPlanStages();
  const { counts } = useInterviewPlanStageCounts();
  const { isOffer } = isStage(stage);

  // want to initialize everything as !!stage.id because !stage.id means its still being created
  // you can't do anything with a stage thats in the process of being created
  const can = {
    canEdit: { can: !!stage.id } as CanDetail,
    canHotswap: {
      can: false,
      reason: "Editing stage type is not permitted after initial stage creation.",
    } as CanDetail,
    canMove: { can: !!stage.id } as CanDetail,
    canDisable: { can: !!stage.id } as CanDetail,
    canAdd: { can: !!stage.id } as CanDetail,
  };

  // You can't exceed the max number of interviews (if stages not defined, probably still loading, so disable adding)
  if (!stages || stages.length >= MAX_NUM_INTERVIEWS_ALLOWED) {
    can.canAdd = {
      can: false,
      reason: "Oops! You've hit our stage limit. You'll need to remove a stage if you want to add another",
    };
  }

  // If stage still has candidates in it (or we are loading that information still) then it can't be disabled
  const hasCandidateOrUndefined = counts?.get(stage.id ?? "") !== 0;
  if (hasCandidateOrUndefined) {
    can.canDisable = {
      can: false,
      reason:
        "Hm... looks like you have candidates in this stage. Make sure to move them (inactive candidates, too!) to a different stage before deleting.",
    };
  }

  // Offer stage can't be moved or disabled
  if (isOffer) {
    can.canMove = { can: false, reason: "Offer stage can't moved" };
    can.canDisable = {
      can: false,
      reason: "Nope, can't delete this one. You're always required to have an offer stage.",
    };
  }

  // You must always have 1 interview hiring pipeline stage
  // So, if a stage is the last interview hiring pipeline stage (or we are still loading that information still), it can't be disabled
  // Part of the reason for this is that we always need atleast 1 stage to set the initial call milestone on.
  // We filter down the stages here because the hook used also returns the offer stage which doesn't count as an interview stage
  const interviewStages = stages?.filter(stage => isInterviewStage(stage)) ?? [];
  const isLastHps = interviewStages.length <= 1;
  if (isLastHps) {
    can.canDisable = {
      can: false,
      reason: "Nope, can't delete this one. You always need to have at least one interview stage.",
    };
  }

  return can;
};
