import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Container } from "@mui/material";
import React from "react";

import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import { theme } from "styles/theme";
import { PageTitle } from "styles/typography/headers";
import ContactExclusion from "views/Contacts/components/ContactExclusion";
import { StyledBanner } from "views/Contacts/components/styles";

const COMPANY_EXCLUSION_LINK = "/setup/company/exclusions";

const ContactsPortal = (): React.ReactElement => {
  return (
    <>
      <PageHelmet title="Upload Connections" />

      <Container style={{ maxWidth: "800px", marginTop: "40px" }}>
        <PageTitle>Excluded LinkedIn Connections</PageTitle>
        <Spacer height="12px" />
        <StyledBanner $borderColor={theme.baseColors.warning}>
          <Box display="flex" alignItems="baseline">
            <Box color={theme.baseColors.warning} fontSize="19px">
              <InfoOutlinedIcon fontSize="inherit" />
            </Box>
            <Spacer width={16} />
            <Box>
              Want to exclude an entire company or exclude candidates your team has already reached out to? You can
              update those settings{" "}
              <a href={COMPANY_EXCLUSION_LINK} target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </Box>
          </Box>
        </StyledBanner>
        <Spacer height="4px" />
        <ContactExclusion />
      </Container>
    </>
  );
};

export default ContactsPortal;
