import { skipToken } from "@reduxjs/toolkit/dist/query";

import { EmailOwner, EmailUser } from "components/library/TipTap/types";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import {
  CandidateBioSchedulingOwnershipEnum,
  CandidateBioSourceDirectionEnum,
  DoverUser,
  DoverUserGmailCredentialAuthStateEnum,
  JobFeatureFeatureNameEnum,
} from "services/openapi";
import { EmailAlias } from "services/openapi/models/EmailAlias";
import { isPreInterviewPipelineStage } from "utils/isStage";

export const NO_REPLY_EMAIL = "no-reply@notifications.dover.io";
interface UseEmailSenderOptionsArgs {
  candidateId: string;
}

/**
 * Returns the set of email users available to the authed user
 * For now, this includes the authed user if a free ATS customer, and additionally any shell emails if a contract customer
 * For reply-to scheduling scenarios, the only available email user is the previous shell email
 */
export function useEmailSenderOptions({ candidateId }: UseEmailSenderOptionsArgs): EmailUser[] | undefined {
  const showNoReply = useFeatureFlag(FeatureFlag.ShowNoReplyEmail);
  // Client and candidate data
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);

  // Fetch the relevant pro user for the currently authed user by filtering on email
  const { data: authedProUser, isLoading: userInfoLoading } = useGetAuthedUserInfoQuery();

  // Still loading necessary data
  if (candidateBio === undefined || userInfoLoading || !authedProUser) {
    return undefined;
  }

  const emailSenderOptions = [];

  // For all scenarioes the currently signed in user should be an option
  const authedUserEmailUser = createEmailUserFromDoverUser(authedProUser);
  authedUserEmailUser && emailSenderOptions.push(authedUserEmailUser);

  // Outbound candidates received outreach from a shell email.
  // We'd like the scheduling/rejection followup to have continuity, so that shell email should be available as an option.
  const isPreInterviewOutboundCandidate =
    candidateBio.searchSource?.direction === CandidateBioSourceDirectionEnum.Outbound &&
    candidateBio?.candidatePipelineStage &&
    isPreInterviewPipelineStage(candidateBio.candidatePipelineStage);

  // For user managed scheduling, we only allow the authed user
  // For dover managed scheduling, we allow the authed user and
  // either the most recently used shell account (in the case of client review) or the client scheduling email
  if (candidateBio.schedulingOwnership === CandidateBioSchedulingOwnershipEnum.DoverHandlesScheduling) {
    if (isPreInterviewOutboundCandidate) {
      if (candidateBio.currentEmailSenderAlias) {
        const previousShellEmailUser = createEmailUserFromEmailAlias(candidateBio.currentEmailSenderAlias);
        previousShellEmailUser && emailSenderOptions.push(previousShellEmailUser);
      }
    } else {
      if (candidateBio?.clientSchedulingEmailAlias) {
        const clientSchedulingEmailUser = createEmailUserFromEmailAlias(candidateBio.clientSchedulingEmailAlias);
        clientSchedulingEmailUser && emailSenderOptions.push(clientSchedulingEmailUser);
      }
    }
  }

  if (showNoReply) {
    emailSenderOptions.push({
      // don't love the hard coded ID here but we don't fetch senders via API route
      // and don't want to crowd the candidate bio route with this
      id: "9c718f38-f575-45f0-aa94-07e8ff3642fd",
      email: NO_REPLY_EMAIL,
      label: "No Reply",
      owner: EmailOwner.Dover,
      isAuthed: true,
    } as EmailUser);
  }

  return emailSenderOptions;
}

/**
 * Returns the set of email users available to the authed user for bulk email actions
 */
export function useBulkEmailSenderOptions(): EmailUser[] | undefined {
  // Get client for signed in user
  const { data: client } = useGetUsersClientQuery();

  // True if dover managed e2e scheduling is enabled for the job
  const jobId = useJobIdFromUrl();
  const isJobE2eEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  const showNoReply = useFeatureFlag(FeatureFlag.ShowNoReplyEmail);

  // Fetch the relevant pro user for the currently authed user
  const { data: authedProUser, isLoading: userInfoLoading } = useGetAuthedUserInfoQuery();

  // Still loading necessary data
  if (userInfoLoading || !authedProUser || !authedProUser.emailSenderAliasId || !authedProUser.email) {
    return undefined;
  }

  const emailSenderOptions = [];

  // For all scenarioes the currently signed in user should be an option
  const authedUserEmailUser = createEmailUserFromDoverUser(authedProUser);
  authedUserEmailUser && emailSenderOptions.push(authedUserEmailUser);

  // When dover managed e2e scheduling is enabled, we also allow the client scheduling email
  // When it's not we only allow the auther user email
  if (isJobE2eEnabled && client?.clientSchedulingEmailAlias) {
    const clientSchedulingEmailUser = createEmailUserFromEmailAlias(client.clientSchedulingEmailAlias);
    clientSchedulingEmailUser && emailSenderOptions.push(clientSchedulingEmailUser);
  }

  if (showNoReply) {
    emailSenderOptions.push({
      // don't love the hard coded ID here but we don't fetch senders via API route
      // and don't want to crowd the candidate bio route with this
      id: "9c718f38-f575-45f0-aa94-07e8ff3642fd",
      email: NO_REPLY_EMAIL,
      label: "No Reply",
      owner: EmailOwner.Dover,
      isAuthed: true,
    } as EmailUser);
  }

  return emailSenderOptions;
}

/**
 * Creates an EmailUser from an EmailAlias for use with tiptap and phoenix mutation apis
 */
const createEmailUserFromDoverUser = (doverUser?: DoverUser): EmailUser | undefined => {
  if (!doverUser?.emailSenderAliasId || !doverUser.email) {
    return;
  }

  return {
    id: doverUser.emailSenderAliasId,
    email: doverUser.email,
    label: doverUser.fullName ?? doverUser.email,
    pictureUrl: doverUser.pictureUrl,
    isAuthed: doverUser.gmailCredentialAuthState === DoverUserGmailCredentialAuthStateEnum.Valid,
    owner: EmailOwner.AuthedUser,
  };
};

/**
 * Creates an EmailUser from an EmailAlias for use with tiptap and phoenix mutation apis
 */
const createEmailUserFromEmailAlias = (emailAlias?: EmailAlias): EmailUser | undefined => {
  if (!emailAlias?.id) {
    return;
  }

  return {
    id: emailAlias.id,
    email: emailAlias.email,
    label: emailAlias.fullName ?? emailAlias.email,
    pictureUrl: undefined, // TODO: add picture url to shell email
    isAuthed: true, // The current email sender alias is always authed
    owner: EmailOwner.Dover,
  };
};

export const useInitialEmailSender = ({ candidateId }: { candidateId: string }): EmailUser | undefined => {
  const { data: bio } = useGetCandidateBioQuery(candidateId);

  const emailSenderOptions = useEmailSenderOptions({ candidateId });

  if (!emailSenderOptions) {
    return undefined;
  }

  const isCustomerHandlingScheduling =
    bio?.schedulingOwnership === CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling;

  if (isCustomerHandlingScheduling) {
    return emailSenderOptions.find(emailSender => emailSender.owner === EmailOwner.AuthedUser);
  } else {
    return emailSenderOptions.find(emailSender => emailSender.owner === EmailOwner.Dover);
  }
};

export const useInitialBulkEmailSender = (): EmailUser | undefined => {
  const jobId = useJobIdFromUrl();
  const isJobE2eEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  const emailSenderOptions = useBulkEmailSenderOptions();

  if (!emailSenderOptions) {
    return undefined;
  }

  //  If e2e enabled at job level use shell account, if not use personal account
  if (isJobE2eEnabled) {
    return emailSenderOptions.find(emailSender => emailSender.owner === EmailOwner.Dover);
  } else {
    return emailSenderOptions.find(emailSender => emailSender.owner === EmailOwner.AuthedUser);
  }
};
