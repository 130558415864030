/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RespondedSubstageEnum {
    BASE = 0,
    RC_REVIEW = 200,
    PM_REVIEW = 300,
    INTERESTED = 400
}

export function RespondedSubstageEnumFromJSON(json: any): RespondedSubstageEnum {
    return RespondedSubstageEnumFromJSONTyped(json, false);
}

export function RespondedSubstageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RespondedSubstageEnum {
    return json as RespondedSubstageEnum;
}

export function RespondedSubstageEnumToJSON(value?: RespondedSubstageEnum | null): any {
    return value as any;
}

