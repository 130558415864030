import { Switch } from "@mui/material";
import styled from "styled-components";

export const StyledSwitch = styled(Switch)`
  position: relative;
  top: -3px;
  left: -1px;
  margin-left: -11px;
  margin-right: -8px;

  .MuiSwitch-switchBase.Mui-checked {
    color: white;
  }

  .MuiSwitch-track {
    opacity: 1 !important;
    height: 20px;
    width: 32px;
    border-radius: 20px;
    background-color: ${(props): string =>
      props.checked ? `${props.theme.colors.primary.base} !important` : props.theme.colors.grayscale.gray300};
  }

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
    box-shadow: none;
  }
  .MuiSwitch-switchBase {
    top: 7px;
    margin-left: 7px;
    &.Mui-checked {
      margin-left: -1px;
    }
  }
`;
