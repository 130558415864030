import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { SubmitReferralResponse, ApiApiSubmitAgencyCandidateRequest } from "services/openapi";
import { showErrorToast } from "utils/showToast";

const agencyFormEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    submitAgencyCandidate: build.mutation<SubmitReferralResponse, ApiApiSubmitAgencyCandidateRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.submitAgencyCandidate(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to submit referral");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useSubmitAgencyCandidateMutation } = agencyFormEndpoints;
