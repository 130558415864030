import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
interface StyledRemoveButtonProps {
  $color?: string;
  $noRightPadding?: boolean;
  $noPadding?: boolean;
  $svgDimensions?: string;
}

const StyledRemoveButton = styled(IconButton)<StyledRemoveButtonProps>`
  && {
    color: ${(props): string => props.$color ?? props.theme.colors.critical.dark};
    padding-right: ${(props): string => (props.$noRightPadding ? "0px" : "12px")};
    padding: ${(props): string | undefined => (props.$noPadding ? "0px !important" : undefined)};

    svg {
      height: ${(props): string | undefined => props.$svgDimensions ?? undefined};
      width: ${(props): string | undefined => props.$svgDimensions ?? undefined};
    }

    :focus {
      outline: none;
    }

    :hover {
      background: none;
    }
  }
`;

interface RemoveButtonProps {
  onClick: Function;
  color?: string;
  noRightPadding?: boolean;
  noPadding?: boolean;
  svgDimensions?: string;
}

const RemoveButton = ({
  onClick,
  color,
  noRightPadding,
  noPadding,
  svgDimensions,
}: RemoveButtonProps): React.ReactElement => {
  return (
    <StyledRemoveButton
      $color={color}
      $noRightPadding={noRightPadding}
      $noPadding={noPadding}
      $svgDimensions={svgDimensions}
      arial-label="Remove location"
      onClick={(el): void => onClick(el)}
      disableRipple={true}
      disableFocusRipple={true}
      disableTouchRipple={true}
    >
      <RemoveCircleIcon />
    </StyledRemoveButton>
  );
};

export default RemoveButton;
