export enum StageComparisonEnum {
  LT = -1,
  EQ = 0,
  GT = 1,
  // TODO if we need this to be different from EQ based on new use cases we can maybe create a wrapper
  UNDEFINED = 0,
}

interface StageComparisonStage {
  // i would like to type this as number | undefined, but then passing in a CandidatePipelineStage is not as straightforward
  orderIndex?: number | null;
  substage?: number;
}

/**
 * Helper to compare two pipeline stages (either at a CandidatePipelineStage level or HPS level)
 *
 * @param stageLeft The left hand side stage to compare
 * @param stageRight The right hand side stage to compare
 * @param lookAtSubstages whether to do a substage comparison or not
 * you could have this as false to compare a candidate to a job stage (or just two job stages) (HPS doesnt have substage notion)
 * or you could have this as true to compare 2 different candidate's pipeline stages (candidates DO have notion of substages)
 */
export const compareStages = (
  stageLeft: StageComparisonStage,
  stageRight: StageComparisonStage,
  lookAtSubstages: boolean = true
): StageComparisonEnum => {
  if (stageLeft.orderIndex == undefined || stageRight.orderIndex == undefined) {
    return StageComparisonEnum.UNDEFINED;
  }
  if (stageLeft.orderIndex < stageRight.orderIndex) {
    return StageComparisonEnum.LT;
  } else if (stageLeft.orderIndex > stageRight.orderIndex) {
    return StageComparisonEnum.GT;
  }
  if (!lookAtSubstages) {
    return StageComparisonEnum.EQ;
  }

  if (stageLeft.substage === undefined || stageRight.substage === undefined) {
    return StageComparisonEnum.UNDEFINED;
  }
  if (stageLeft.substage < stageRight.substage) {
    return StageComparisonEnum.LT;
  } else if (stageLeft.substage > stageRight.substage) {
    return StageComparisonEnum.GT;
  }
  return StageComparisonEnum.EQ;
};
