import React, { ReactElement } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import { DoverLoadingSpinner } from "components/loading-overlay";
import { filters } from "styles/theme";

interface CalendlyEmbedProps {
  calendlyUrl: string;
  name?: string;
  email: string;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
  onEventScheduled?: () => void;
  onDateAndTimeSelected?: () => void;
  styles?: React.CSSProperties;
  dateAndTimeSelectedMaxHeight?: string;
}

function loadingSpinner(): ReactElement {
  return <DoverLoadingSpinner active={true} filter={filters.filterDarkGreen} />;
}

export const CalendlyEmbed = ({
  calendlyUrl,
  name,
  email,
  utmCampaign,
  utmMedium,
  utmSource,
  onEventScheduled,
  styles,
  dateAndTimeSelectedMaxHeight,
  onDateAndTimeSelected,
}: CalendlyEmbedProps): React.ReactElement => {
  const [maxHeight, setMaxHeight] = React.useState<string | undefined>();
  useCalendlyEventListener({
    onEventScheduled: _ => {
      onEventScheduled?.();
    },
    onDateAndTimeSelected: __ => {
      onDateAndTimeSelected?.();
      // these props seem a bit weird but because the embed is a iframe, it is hard to style
      // this is a hacky way to set the max height of the iframe and make it look better
      if (dateAndTimeSelectedMaxHeight) {
        setMaxHeight(dateAndTimeSelectedMaxHeight);
      }
    },
  });

  return (
    <InlineWidget
      pageSettings={{
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
        hideGdprBanner: true,
      }}
      url={calendlyUrl}
      utm={{
        utmCampaign,
        utmMedium,
        utmSource,
      }}
      styles={{ ...styles, maxHeight: maxHeight ?? styles?.maxHeight }}
      LoadingSpinner={loadingSpinner}
      prefill={{
        name,
        email,
      }}
    />
  );
};
