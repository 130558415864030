import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { ApiApiListFieldsOfStudyRequest } from "services/openapi";
import { EducationLevelsResponse, FieldOfStudyTier1, FieldOfStudyTier2 } from "services/openapi/models";
import { DisplayedFieldOfStudy } from "views/sourcing/Search/components/EducationFilters";

const fieldsOfStudyEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listFieldsOfStudy: build.query<FieldOfStudyTier1[], ApiApiListFieldsOfStudyRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.listFieldsOfStudy(args);
          return { data: response.results };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listFieldsOfStudySaaP: build.query<DisplayedFieldOfStudy[], ApiApiListFieldsOfStudyRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.listFieldsOfStudy(args);
          const tier1s = response.results;
          const allFieldsOfStudy: DisplayedFieldOfStudy[] = [];
          for (let i = 0; i < tier1s.length; i++) {
            const tier1 = tier1s[i];
            // want to add each tier1
            allFieldsOfStudy.push({ id: tier1.id, name: tier1.name, parentName: "" });
            // but only want to add the tier2s for tier1s that have SEVERAL tier2s
            if (tier1.tier2Fields.length > 1) {
              const listOfTier2s = tier1.tier2Fields.map(
                (tier2: FieldOfStudyTier2): DisplayedFieldOfStudy => {
                  return { id: tier2.id, name: tier2.name, parentName: tier1.name };
                }
              );
              // add all these tier2's formatted as DisplayedFieldsOfStudy to the final result
              for (let j = 0; j < listOfTier2s.length; j++) {
                allFieldsOfStudy.push(listOfTier2s[j]);
              }
            }
          }
          return { data: allFieldsOfStudy };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listEducationLevels: build.query<EducationLevelsResponse[], void>({
      queryFn: async () => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.listEducationLevels();
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useListFieldsOfStudyQuery,
  useListFieldsOfStudySaaPQuery,
  useListEducationLevelsQuery,
} = fieldsOfStudyEndpoints;
