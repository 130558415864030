import React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";

import Emoji from "components/Emoji";
import { UserCredential } from "services/openapi";
import { Logo } from "views/CompanySetup/components/Credentials/components/Logo";
import { Source } from "views/CompanySetup/components/Credentials/types";

const FixedHeightRow = styled(Row)`
  display: flex;
  align-items: center;
  height: 100px;
  border-radius: 10px;
  &:hover {
    background-color: #e0e0e2;
  }
`;

const StateCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  color: #888888;
  text-transform: uppercase;
`;

interface Props {
  cred: UserCredential;
  source: string;
}

const SourceRow: React.FC<Props> = ({ cred, source }) => (
  <FixedHeightRow>
    <Col xs={{ size: 7, offset: 1 }}>
      <Logo source={source as Source} />
    </Col>
    <StateCol xs={3}>
      {cred.state === "LOST_CONNECTION" && (
        <h1>
          <Emoji symbol="⚠️" label="Invalid Credentials" />
        </h1>
      )}
      {cred.state === "CONNECTED" && "Enabled"}
      {cred.state == null && source !== "YC_WAAS" && "Not set up"}
      {cred.state == null && source === "YC_WAAS" && "Show key"}
    </StateCol>
  </FixedHeightRow>
);

export default SourceRow;
