import { PressEvent } from "@react-types/shared/src/";
import { isEqual } from "lodash";
import { NavigateFunction, To } from "react-router-dom";

import { CURRENT_FLOW_ID_PARAM_NAME, OnboardingCurrentStepToUrlParamMap } from "App/routing/constants";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { IndividualRouteConfig } from "App/routing/types";
import {
  CurrentUserOnboardingStepResponse,
  CurrentUserOnboardingStepResponseCurrentFlowNameEnum,
  CurrentUserOnboardingStepResponseCurrentStepEnum,
} from "services/openapi";

export const isAdminOnlyPage = ({
  authedRouteMetaData,
}: Pick<IndividualRouteConfig, "authedRouteMetaData">): boolean => {
  return Boolean(authedRouteMetaData.requiresAuth && isEqual(authedRouteMetaData.allowedRoles, ["admin"]));
};

export const errorHandler = (error: Error): void => {
  console.error(error);
};

export const navigateToLink = (
  event: React.MouseEvent | PressEvent,
  navigate: NavigateFunction,
  link: string
): void => {
  // if command (on mac)/control (on windows) is pressed, open in new tab
  if (event.metaKey || event.ctrlKey) {
    window.open(link, "_blank", "noopener noreferrer");
  } else {
    navigate(link);
  }
};

export const getOnboardingFlowRoute = ({
  currentOnboardingStepResponse,
}: {
  currentOnboardingStepResponse: CurrentUserOnboardingStepResponse | undefined;
}): To | undefined => {
  if (!currentOnboardingStepResponse) {
    return undefined;
  }
  const { currentFlowName, currentStep, currentFlowId } = currentOnboardingStepResponse;
  if (!currentFlowName || !currentStep || !currentFlowId) {
    return undefined;
  }
  const currentStepPath = OnboardingCurrentStepToUrlParamMap[currentStep];
  switch (currentFlowName) {
    case CurrentUserOnboardingStepResponseCurrentFlowNameEnum.UserAndCompanyLoginInfo:
      switch (currentStep) {
        case CurrentUserOnboardingStepResponseCurrentStepEnum.FeatureSelection:
          return {
            pathname: APP_ROUTE_PATHS.onboarding.scheduleOnboarding(),
          };

        case CurrentUserOnboardingStepResponseCurrentStepEnum.ClientCreation:
        default:
          return {
            pathname: APP_ROUTE_PATHS.onboarding.accountDetails(),
            search: new URLSearchParams({ [CURRENT_FLOW_ID_PARAM_NAME]: currentFlowId }).toString(),
          };
      }
    case CurrentUserOnboardingStepResponseCurrentFlowNameEnum.ApplicantSorting:
      return {
        pathname: APP_ROUTE_PATHS.onboarding.applicantSorting(),
        search: new URLSearchParams({ step: currentStepPath, [CURRENT_FLOW_ID_PARAM_NAME]: currentFlowId }).toString(),
      };
    case CurrentUserOnboardingStepResponseCurrentFlowNameEnum.AtsJobBoards:
      return {
        pathname: APP_ROUTE_PATHS.onboarding.atsSetup(),
        search: new URLSearchParams({ step: currentStepPath, [CURRENT_FLOW_ID_PARAM_NAME]: currentFlowId }).toString(),
      };
    default:
      return {
        pathname: APP_ROUTE_PATHS.onboarding.accountDetails(),
        search: new URLSearchParams({ [CURRENT_FLOW_ID_PARAM_NAME]: currentFlowId }).toString(),
      };
  }
};
