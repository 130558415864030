/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BulkCandidateActionEmailArgs,
    BulkCandidateActionEmailArgsFromJSON,
    BulkCandidateActionEmailArgsFromJSONTyped,
    BulkCandidateActionEmailArgsToJSON,
    QueuedBulkRejectCandidate,
    QueuedBulkRejectCandidateFromJSON,
    QueuedBulkRejectCandidateFromJSONTyped,
    QueuedBulkRejectCandidateToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueueBulkRejectRequest
 */
export interface QueueBulkRejectRequest {
    /**
     * 
     * @type {boolean}
     * @memberof QueueBulkRejectRequest
     */
    sync: boolean;
    /**
     * 
     * @type {string}
     * @memberof QueueBulkRejectRequest
     */
    archiveReason?: string | null;
    /**
     * 
     * @type {BulkCandidateActionEmailArgs}
     * @memberof QueueBulkRejectRequest
     */
    rawEmailArgs?: BulkCandidateActionEmailArgs | null;
    /**
     * 
     * @type {Array<QueuedBulkRejectCandidate>}
     * @memberof QueueBulkRejectRequest
     */
    candidates: Array<QueuedBulkRejectCandidate>;
}

export function QueueBulkRejectRequestFromJSON(json: any): QueueBulkRejectRequest {
    return QueueBulkRejectRequestFromJSONTyped(json, false);
}

export function QueueBulkRejectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueueBulkRejectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sync': json['sync'],
        'archiveReason': !exists(json, 'archive_reason') ? undefined : json['archive_reason'],
        'rawEmailArgs': !exists(json, 'raw_email_args') ? undefined : BulkCandidateActionEmailArgsFromJSON(json['raw_email_args']),
        'candidates': ((json['candidates'] as Array<any>).map(QueuedBulkRejectCandidateFromJSON)),
    };
}

export function QueueBulkRejectRequestToJSON(value?: QueueBulkRejectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sync': value.sync,
        'archive_reason': value.archiveReason,
        'raw_email_args': BulkCandidateActionEmailArgsToJSON(value.rawEmailArgs),
        'candidates': ((value.candidates as Array<any>).map(QueuedBulkRejectCandidateToJSON)),
    };
}


