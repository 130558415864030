import { Box, Stack } from "@mui/material";
import styled from "styled-components";

import { colors, screenSizes } from "styles/theme";

export const CareersPagePreviewContainer = styled(Box)`
  width: 100%;
  max-width: 1000px;
  border: 1px solid ${colors.grayscale.gray200};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  flex-grow: 1;
  overflow: auto;
  background-color: white;
`;

export const PreviewContainer = styled(Stack)`
  width: 60%;
  background-color: ${colors.grayscale.gray100};
  padding: 16px;
  overflow: hidden;

  @media (max-width: ${screenSizes.tablet}) {
    width: 100%;
    padding: 0;
    background-color: transparent;
  }
`;

export const CareersPageCustomizationButtonContainer = styled(Stack)`
  width: 100%;
  padding: 8px 16px;
  border-top: 1px solid ${colors.grayscale.gray200};
`;
