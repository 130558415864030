import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

import { usePartialUpdateJobPositionOpeningMutation } from "services/doverapi/endpoints/hiring-plan";
import { HiringPlanJobPositionOpening } from "services/openapi/models";
import { theme, device } from "styles/theme";
import { isValidDate } from "utils/dates";
import { HiringPlanTableRow } from "views/HiringPlan/types";

interface TextFieldProps {
  showError?: boolean;
}

const StyledTextField = styled(TextField)<TextFieldProps>`
  .custom_date-picker {
    font-size: 14px;
    font-family: Inter;

    input {
      padding: 12px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props): string => (props.showError ? theme.colors.critical.base : "")};
    &.hover {
      border-color: ${(props): string => (props.showError ? theme.colors.critical.base : "")};
    }
  }

  .MuiFormHelperText-root {
    position: absolute;
    top: 42px;
    white-space: nowrap;
    left: -36px;
    color: ${theme.colors.critical.dark};
  }

  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

interface Props {
  tableRow: HiringPlanTableRow;
  accessor: string;
  errorText?: string;
}

const DatePickerCell = ({ tableRow, accessor, errorText }: Props): React.ReactElement => {
  const initialDate =
    tableRow[accessor as keyof HiringPlanTableRow] !== undefined
      ? (tableRow[accessor as keyof HiringPlanTableRow] as Date)
      : null;
  const [date, setDate] = useState<Date | null>(initialDate);

  const [partialUpdateJobPositionOpening] = usePartialUpdateJobPositionOpeningMutation();

  const handleUpdateJobPositionOpening = (newValue: Date | null): void => {
    const updatedJobPositionOpening = {
      ...tableRow.jobPositionOpening,
      [accessor as keyof HiringPlanJobPositionOpening]: newValue,
    };
    partialUpdateJobPositionOpening({
      jobPositionOpening: updatedJobPositionOpening as HiringPlanJobPositionOpening,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        desktopModeMediaQuery={device.desktopS}
        inputFormat="MM/dd/yy"
        value={date}
        onChange={(newValue): void => {
          setDate(newValue);
          if (isValidDate(newValue) || newValue === null) {
            handleUpdateJobPositionOpening(newValue);
          }
        }}
        InputProps={{ className: "custom_date-picker" }}
        renderInput={(params): React.ReactElement => (
          <StyledTextField showError={!!errorText} helperText={errorText} {...params} />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerCell;
