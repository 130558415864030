/* eslint-disable react/no-unstable-nested-components */
/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  InputAdornment,
  TableSortLabel,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import { addDays } from "date-fns";
import { debounce } from "lodash";
import keyBy from "lodash/keyBy";
import React from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";

import DoverLoadingOverlay from "components/loading-overlay";
import MoreOptionsMenu from "components/MoreOptionsMenu";
import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import {
  listJobPositionsInitialState,
  selectAllJobPositions,
  useCreateJobPositionMutation,
  useCreateJobPositionOpeningMutation,
  useListJobPositionsQuery,
} from "services/doverapi/endpoints/hiring-plan";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { HiringPlanJobPosition, DoverUser } from "services/openapi";
import { theme } from "styles/theme";
import { PageTitle } from "styles/typography/headers";
import AddJobPositionButton from "views/HiringPlan/components/AddJobPositionButton";
import DatePickerCell from "views/HiringPlan/components/DatePickerCell";
import DeleteJobPositionMenuItem from "views/HiringPlan/components/DeleteJobPositionMenuItem";
import HiringManagerSelect from "views/HiringPlan/components/HiringManagerSelect";
import { JobSourcesButton, JobReportButton } from "views/HiringPlan/components/JobButtons";
import NotesModal from "views/HiringPlan/components/NotesModal";
import SingleSelectCellPriority from "views/HiringPlan/components/SingleSelectCellPriority";
import SingleSelectCellStatus from "views/HiringPlan/components/SingleSelectCellStatus";
import TextFieldCell from "views/HiringPlan/components/TextFieldCell";
import { PageWrapper, StyledTableContainer, StyledAddCircleIcon } from "views/HiringPlan/styles";
import {
  getHighestNthPositionOpeningOnJobPosition,
  getHiringPlanTableRows,
  getJobPositionStartGoalDates,
  getMaxGoalStartDate,
  isPastLaunchDate,
} from "views/HiringPlan/utils";

const GlobalFilter = ({ globalFilter, setGlobalFilter }: any): React.ReactElement => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = debounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <TextField
      id="global-search"
      variant="standard"
      value={value || ""}
      onChange={(e: any): void => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      style={{ width: "300px" }}
      placeholder="Search by hiring manager"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="large">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const HiringPlan = (): React.ReactElement => {
  const { data: jobPositionEntities, isLoading: jobPositionsLoading } = useListJobPositionsQuery();
  const jobPositions = selectAllJobPositions(jobPositionEntities || listJobPositionsInitialState);

  const hiringPlanTableRows = getHiringPlanTableRows(jobPositions);

  const maxGoalStartDate = getMaxGoalStartDate(jobPositions);
  const jobPositionStartGoalDatesMap = getJobPositionStartGoalDates(jobPositions);

  const { data: hiringManagersState, isLoading: hiringManagersStateIsLoading } = useListProUsersForClientQuery({
    isHiringManager: "true",
  });

  const hiringManagers = React.useMemo(() => listAllEntities(hiringManagersState), [hiringManagersState]);
  const hiringManagersById = React.useMemo(() => keyBy(hiringManagers, (hm: any): string => hm.pk), [hiringManagers]);
  const tableData = React.useMemo(() => hiringPlanTableRows, [hiringPlanTableRows]);

  const clientId = useGetClientId();

  const loading = jobPositionsLoading || hiringManagersStateIsLoading;

  const [createJobPosition] = useCreateJobPositionMutation();
  const [createJobPositionOpening] = useCreateJobPositionOpeningMutation();

  const handleAddJobPosition = async (title: string): Promise<void> => {
    clientId && createJobPosition({ clientId, title });
  };

  const AddJobPositionOpeningButton = ({ jobPosition }: { jobPosition: HiringPlanJobPosition }): React.ReactElement => {
    const highestNthPositionOpening = getHighestNthPositionOpeningOnJobPosition(jobPosition);
    return (
      <Tooltip title="Add headcount" placement="top" style={{ position: "relative" }}>
        <Box position="relative">
          <Spacer width={16} />
          <StyledAddCircleIcon
            onClick={(): void => {
              createJobPositionOpening({
                jobPositionId: jobPosition.id!,
                nthOpeningForPosition: highestNthPositionOpening + 1,
              });
            }}
          />
        </Box>
      </Tooltip>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Job Position Id",
        accessor: "jobPositionId",
        disableSortBy: true,
      },
      {
        Header: "Title",
        accessor: "title", // accessor is the "key" in the data
        width: 220,
        minWidth: 150,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => {
          if (!tableRow.isFirstOpening) return <></>;
          return (
            <Box display="flex" justifyContent="space-between">
              <TextFieldCell tableRow={tableRow} accessor="title" />
              {tableRow.isFirstOpening && <AddJobPositionOpeningButton jobPosition={tableRow.jobPosition} />}
            </Box>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Employee #",
        accessor: "nthOpeningForPosition",
        maxWidth: 80,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => (
          <TextFieldCell
            tableRow={tableRow}
            accessor="nthOpeningForPosition"
            type="number"
            alwaysEditable
            updateJobPositionOpening
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Hiring Manager",
        accessor: "hiringManager",
        width: 150,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => {
          if (!tableRow.isFirstOpening) return <></>;
          return <HiringManagerSelect tableRow={tableRow} hiringManagers={hiringManagers ?? []} />;
        },
        sortType: (rowA: any, rowB: any, columnId: string, desc: boolean): number => {
          const hiringManagerIdA = rowA.original.jobHiringManager ?? rowA.original.hiringManager;
          const hiringManagerIdB = rowB.original.jobHiringManager ?? rowB.original.hiringManager;
          if (hiringManagerIdA === hiringManagerIdB) {
            if (rowA.original.jobPositionId === rowB.original.jobPositionId) {
              // If they're the same job position, always sort the lower position number to the top
              const bigger = desc ? 1 : -1;
              const smaller = desc ? -1 : 1;
              return rowA.original.nthOpeningForPosition < rowB.original.nthOpeningForPosition ? bigger : smaller;
            }
            return rowA.original.title < rowB.original.title ? 1 : -1;
          }
          const hiringManagerNameA =
            hiringManagers?.find((hm: DoverUser): boolean => hm.pk === hiringManagerIdA)?.fullName ??
            rowA.original.hiringManagerName;
          const hiringManagerNameB =
            hiringManagers?.find((hm: DoverUser): boolean => hm.pk === hiringManagerIdB)?.fullName ??
            rowB.original.hiringManagerName;

          return hiringManagerNameA < hiringManagerNameB ? 1 : -1;
        },
        sortDescFirst: true,
      },
      {
        Header: "Launch Date",
        accessor: "launchDate",
        minWidth: 115,
        width: 125,
        maxWidth: 140,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => (
          <DatePickerCell
            tableRow={tableRow}
            accessor="launchDate"
            errorText={isPastLaunchDate(tableRow.launchDate, tableRow.status)}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Goal Start Date",
        accessor: "goalStartDate",
        minWidth: 115,
        width: 125,
        maxWidth: 140,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => (
          <DatePickerCell tableRow={tableRow} accessor="goalStartDate" />
        ),
        sortType: (rowA: any, rowB: any, columnId: string, desc: boolean): number => {
          const maxDate = addDays(maxGoalStartDate, 1);
          const goalStartDateA = rowA.original.goalStartDate ?? maxDate;
          const goalStartDateB = rowB.original.goalStartDate ?? maxDate;

          if (rowA.original.isFirstOpening && rowB.original.isFirstOpening) {
            return goalStartDateA < goalStartDateB ? 1 : -1;
          } else if (rowA.original.isFirstOpening && !rowB.original.isFirstOpening) {
            if (rowA.original.jobPositionId === rowB.original.jobPositionId) {
              return desc ? 1 : -1;
            }

            return goalStartDateA < (jobPositionStartGoalDatesMap.get(rowB.original.jobPositionId) ?? maxDate) ? 1 : -1;
          } else if (!rowA.original.isFirstOpening && rowB.original.isFirstOpening) {
            if (rowA.original.jobPositionId === rowB.original.jobPositionId) {
              return desc ? -1 : 1;
            }

            return (jobPositionStartGoalDatesMap.get(rowA.original.jobPositionId) ?? maxDate) < goalStartDateB ? 1 : -1;
          } else {
            if (rowA.original.jobPositionId === rowB.original.jobPositionId) {
              return goalStartDateA < goalStartDateB ? 1 : -1;
            } else {
              return (jobPositionStartGoalDatesMap.get(rowA.original.jobPositionId) ?? maxDate) <
                (jobPositionStartGoalDatesMap.get(rowB.original.jobPositionId) ?? maxDate)
                ? 1
                : -1;
            }
          }
        },
        sortDescFirst: true,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 120,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => <SingleSelectCellStatus tableRow={tableRow} />,
        disableSortBy: true,
      },
      {
        Header: "Priority",
        accessor: "priority",
        width: 120,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => {
          if (!tableRow.isFirstOpening) return <></>;
          return <SingleSelectCellPriority tableRow={tableRow} />;
        },
        sortType: (rowA: any, rowB: any, columnId: string, desc: boolean): number => {
          const priorityA = rowA.original.priority;
          const priorityB = rowB.original.priority;
          if (priorityA === priorityB || (!priorityA && !priorityB)) {
            if (rowA.original.jobPositionId === rowB.original.jobPositionId) {
              // If they're the same job position, always sort the lower position number to the top
              const bigger = desc ? 1 : -1;
              const smaller = desc ? -1 : 1;
              return rowA.original.nthOpeningForPosition < rowB.original.nthOpeningForPosition ? bigger : smaller;
            }
            return rowA.original.title < rowB.original.title ? 1 : -1;
          }
          const priorityOrder = ["HIGH", "MEDIUM", "LOW", null, undefined];
          return priorityOrder.indexOf(priorityA) < priorityOrder.indexOf(priorityB) ? 1 : -1;
        },
        disableSortBy: false,
        sortDescFirst: true,
      },
      {
        Header: "Strategy",
        accessor: "strategy",
        width: 150,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => (
          <Box display="flex" width="100%">
            <JobSourcesButton jobId={tableRow.job} isFirstOpening={tableRow.isFirstOpening} />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <JobReportButton
                jobPositionId={tableRow.jobPositionId}
                jobTitle={tableRow.title}
                jobId={tableRow.job}
                isFirstOpening={tableRow.isFirstOpening}
              />
            </Box>
          </Box>
        ),
        disableSortBy: true,
      },
      {
        Header: "Notes",
        accessor: "notes",
        maxWidth: 40,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => (
          <NotesModal jobPositionOpening={tableRow.jobPositionOpening} notes={tableRow.notes} />
        ),
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "moreOptionsMenu",
        maxWidth: 48,
        // @ts-ignore
        Cell: ({ row: { original: tableRow } }): React.ReactElement => {
          return (
            <MoreOptionsMenu>
              <DeleteJobPositionMenuItem tableRow={tableRow} />
            </MoreOptionsMenu>
          );
        },
        disableSortBy: true,
      },
    ],
    [hiringManagers]
  );

  const { getTableBodyProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
    {
      // @ts-ignore
      columns,
      data: tableData,
      initialState: {
        hiddenColumns: ["jobPositionId"],
      },
      globalFilter: (rows: any, columnIds: any, filterValue?: string): any => {
        if (!filterValue) {
          return rows;
        }
        return rows.filter((r: any): boolean => {
          const hiringManagerId = r.original.jobHiringManager ?? r.original.hiringManager ?? "";
          const hiringManagerName = hiringManagersById[hiringManagerId]?.fullName ?? r.original.hiringManagerName;
          return (hiringManagerName ?? "").toLowerCase().includes(filterValue.toLowerCase());
        });
      },
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      <PageHelmet title="Hiring Plan" />

      <PageWrapper>
        <Box display="flex" width="100%" justifyContent="space-between">
          <PageTitle>Hiring Plan</PageTitle>
          <Box display="flex" alignItems="center">
            <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
            <Spacer width="16px" />
            <AddJobPositionButton addPosition={handleAddJobPosition} />
          </Box>
        </Box>
        <Spacer height="24px" />
        <StyledTableContainer>
          <Table
            className="hiring-plan-table"
            stickyHeader
            aria-labelledby="Table"
            size="medium"
            aria-label="hiring plan table"
          >
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps({ title: "Toggle Sort" }))}
                      style={{ width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth }}
                      key={column.id}
                    >
                      {column.disableSortBy ? (
                        column.render("Header")
                      ) : (
                        <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? "desc" : "asc"}>
                          {column.render("Header")}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {loading && hiringPlanTableRows.length === 0 && <Box minHeight="240px" position="relative" />}
              <DoverLoadingOverlay active={loading} absolute minHeight="auto" />
              {rows.map(row => {
                prepareRow(row);
                return (
                  <TableRow
                    style={{
                      backgroundColor: isPastLaunchDate(row.original.launchDate, row.original.status)
                        ? theme.colors.critical.light
                        : "inherit",
                    }}
                    {...row.getRowProps()}
                    key={row.original.id}
                  >
                    {row.cells.map(cell => (
                      <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </PageWrapper>
    </>
  );
};

export default HiringPlan;
