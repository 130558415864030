import { Box, Stack } from "@mui/material";
import React, { useCallback, useMemo } from "react";

import Banner from "components/Banner";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { ListCampaign } from "services/openapi";
import { B3margin } from "views/job/JobSetup/steps/CampaignVariants/styles";
import { CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";

export const ConfirmSaveContent = ({
  campaign,
  campaignStatus,
  passingQa,
  setPassingQa,
  passingCalendly,
  passingSubjectLine,
  passingSavvy,
  emptySubject,
  invalidTags,
  senderUserFullName,
  passingCandidateFirstNameTempVar,
}: {
  campaign?: ListCampaign;
  campaignStatus?: CampaignStatus;
  passingQa: boolean;
  setPassingQa: (value: boolean) => void;
  passingCalendly: boolean;
  passingSubjectLine: boolean;
  passingSavvy: boolean;
  emptySubject: boolean;
  invalidTags: Array<string>;
  senderUserFullName: string;
  passingCandidateFirstNameTempVar: boolean;
}): React.ReactElement => {
  const emailAlias = campaign?.emailAlias;
  // TODO: pull name from user_defined_sender_user
  const emailAliasEmail = emailAlias?.email;
  const emailAliasSet = !!emailAliasEmail;
  const senderName = emailAlias?.fullName;
  const switchSubmitDisabled = useCallback((): void => {
    setPassingQa(!passingQa);
  }, [passingQa, setPassingQa]);

  const calendarText = !passingCalendly ? "Calendly" : "SavvyCal";
  const checkBox = useMemo((): React.ReactElement => {
    return (
      <label hidden={passingQa}>
        <Box display="flex" justifyContent="left">
          <input checked={passingQa} type="checkbox" onChange={switchSubmitDisabled} />
          <B3margin>{"Click here to confirm and proceed"}</B3margin>
        </Box>
      </label>
    );
  }, [passingQa, switchSubmitDisabled]);

  if (invalidTags.length > 0) {
    return (
      <Stack spacing={2}>
        <BodySmall>
          There are unsupported variables in your outreach. Please replace the following with Dover-supported variables
          or remove them.
        </BodySmall>
        <Stack>
          {invalidTags.map((tag: string) => (
            <>
              <BodySmall>&#8226; &#123;&#123;{tag}&#125;&#125;</BodySmall>
            </>
          ))}
        </Stack>
      </Stack>
    );
  }
  let failingQAMessage;
  if (senderUserFullName) {
    failingQAMessage = `You’ve selected this outreach to come from ${senderUserFullName} but there is no mention of this name in
    the outreach content. Please review your outreach or confirm this is intended.`;
  } else {
    failingQAMessage =
      "We couldn't find a mention of the email sender's name in the outreach content. Please review your outreach or confirm this is intended.";
  }
  if (!passingQa) {
    return (
      <Box>
        <Box width={"520px"} marginX="auto">
          <Banner type="warning" variant="filled">
            <BodySmall>{failingQAMessage}</BodySmall>
          </Banner>
        </Box>
        <Spacer height={16} />
        <Box display="flex" justifyContent="flex-start">
          {checkBox}
        </Box>
      </Box>
    );
  }
  if (!passingCalendly || !passingSavvy) {
    return (
      <Box>
        <Box width={"520px"} marginX="auto">
          <BodySmall>
            Looks like you’ve included a {calendarText} link in your outreach copy. We’re unable to support having
            scheduling links in outreach messages as it disrupts the automated flow we have in place. To move forward,
            please remove the {calendarText} link and save again.
          </BodySmall>
        </Box>
      </Box>
    );
  }
  if (!passingCandidateFirstNameTempVar) {
    return (
      <Stack>
        <BodySmall>{`Looks like you haven't included a variable for the candidate's first name. To move forward, please include {{FIRST_NAME}} in the body of the initial outreach.`}</BodySmall>
      </Stack>
    );
  }
  if (emptySubject) {
    return (
      <Box>
        <Box width={"520px"} marginX="auto">
          <BodySmall>
            Looks like you haven’t filled out the subject line for your outreach message. To move forward, please add a
            subject line.
          </BodySmall>
        </Box>
      </Box>
    );
  }
  if (campaignStatus === CampaignStatus.Inactive) {
    return (
      <Box width={"520px"} marginX="auto">
        <BodySmall>This outreach campaign is not active.</BodySmall>
        <Spacer height={16} />
        <BodySmall>If you intend to send this outreach to candidates, please activate it after saving.</BodySmall>
      </Box>
    );
  }
  const firstLineCopy =
    campaignStatus === CampaignStatus.Active
      ? "This outreach campaign is active."
      : "Once Dover processes your campaign, it will be active for the next batch of queued candidates. Existing queued candidates will be sent the messaging from existing campaigns.";

  return (
    <Box width={"520px"} marginX="auto">
      <BodySmall>{firstLineCopy}</BodySmall>
      <Spacer height={16} />
      <BodySmall>
        {campaignStatus === CampaignStatus.Active
          ? "Once you press save, newly sourced candidates will see your revised content. "
          : " "}
        <Spacer height={16} />
        Remember to double check for any typos and that hyperlinks are working.
      </BodySmall>

      {!passingSubjectLine && (
        <BodySmall style={{ paddingTop: "16px" }}>
          {
            "You currently have {{ EMAIL_SENDER_NAME }} in the subject line. Please review and confirm this is intentional before proceeding."
          }
        </BodySmall>
      )}

      {emailAliasSet && (
        <>
          <Spacer height={16} />
          <BodySmall>
            Dover will send emails from {senderName}. Take care to ensure the email is addressed from this person.
          </BodySmall>
        </>
      )}
    </Box>
  );
};
