import { Extension } from "@tiptap/core";
import { Node as ProsemirrorNode } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import VariableHighlighterPlugin from "components/library/TipTap/extensions/variableHighlighter/variableHighlighterPlugin";

export const VALID_VARIABLES = new Set([
  "first_name",
  "sender_first_name",
  "job_title",
  "old_job_title",
  "client_name",
  "interviewer_first_name",
  "interviewer_role_title_with_article",
  "interviewer_role_title_without_article",
  "interview_date",
  "scheduling_link",
  "rescheduling_link",
  "cancellation_link",
  "agency_name",
  "job_description_link",
  "interview_duration",
  "interview_schedule",
  "FIRST_NAME",
  "SENDER_FIRST_NAME",
  "JOB_TITLE",
  "OLD_JOB_TITLE",
  "CLIENT_NAME",
  "INTERVIEWER_FIRST_NAME",
  "INTERVIEWER_ROLE_TITLE_WITH_ARTICLE",
  "INTERVIEWER_ROLE_TITLE_WITHOUT_ARTICLE",
  "INTERVIEW_DATE",
  "SCHEDULING_LINK",
  "RESCHEDULING_LINK",
  "CANCELLATION_LINK",
  "AGENCY_NAME",
  "JOB_DESCRIPTION_LINK",
  "INTERVIEW_DURATION",
  "INTERVIEW_SCHEDULE",
  "EMAIL_SENDER_NAME",
  "DAY_OF_WEEK",
  "PERSONALIZED_CONTENT",
]);
// We must declare this module to extend the Commands interface
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    variableHighlighter: {
      updateVariables: (variables: string[]) => ReturnType;
    };
  }
}

// Running this will add "decorations" to relevant results from the VariableHighlighterPlugin
// These decorations manifest as span tags with specific classnames applied
// These spans are then styled according to the classnames in the CSS
function runVariableHighlighterPlugin(doc: ProsemirrorNode, variables: string[]): DecorationSet {
  const decorations: [any?] = [];

  const results = new VariableHighlighterPlugin(doc, variables).scan().getResults();

  results.forEach(issue => {
    decorations.push(
      Decoration.inline(issue.from, issue.to, {
        class: VALID_VARIABLES.has(issue.word) ? "variable" : "variable-invalid",
      })
    );
  });

  return DecorationSet.create(doc, decorations);
}

export interface VariableHighlighterStorage {
  variables: string[];
}

export const VariableHighlighter = Extension.create<any, VariableHighlighterStorage>({
  name: "variableHighlighter",

  // Storage is mutable, essentially a class variable
  addStorage() {
    return {
      variables: [],
    };
  },

  // This exposes the ability to update variables from any instance of the editor
  // In the event that the editor does not have this plugin, this will simply no-op
  addCommands(): any {
    return {
      updateVariables: (variables: string[]) => (): void => {
        this.storage.variables = variables;
        runVariableHighlighterPlugin(this.editor.state.doc, this.storage.variables);
      },
    };
  },

  // This advanced configuration allows us to hook into the underlying ProseMirror lifecycle and state
  addProseMirrorPlugins() {
    const extension = this;

    return [
      new Plugin<DecorationSet>({
        key: new PluginKey("variableHighlighter"),
        state: {
          // Runs on doc init
          init(_, { doc }): DecorationSet {
            return runVariableHighlighterPlugin(doc, extension.storage.variables);
          },
          // Runs on doc update
          apply(transaction, oldState): DecorationSet {
            return transaction.docChanged
              ? runVariableHighlighterPlugin(transaction.doc, extension.storage.variables)
              : oldState;
          },
        },
        // Required boilerplate to return the decorations to the editor
        props: {
          decorations(state): DecorationSet | null | undefined {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
