import { ThunkDispatch } from "@reduxjs/toolkit";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { QueryFulfilledRejectionReason } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { EntityAdapter } from "@reduxjs/toolkit/src/entities/models";
import { AnyAction } from "redux";

import { doverApi } from "services/doverapi/apiSlice";
import { DoverApiError } from "services/doverapi/types";
import {
  BulkUpsertJobFeatureSetting,
  JobFeatureFeatureNameEnum,
  JobFeatures,
  JobFeatureStateEnum,
  JobSetupStepsWithSetupSummaryStateSetupSummaryStateEnum,
  JobSetupStepStateEnum,
  UpsertJobFeatureSetting,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
  JobSetupStepsWithSetupSummaryState,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

interface PerformOptimisticEntityAdapterUpdateArgs<T> {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  queryFulfilled: PromiseWithKnownReason<
    { data: T; meta: {} | undefined },
    QueryFulfilledRejectionReason<BaseQueryFn<void, symbol, DoverApiError, {}, {}>>
  >;
  endpointName: string;
  entityAdapter: EntityAdapter<T>;
  optimisticData: T;
  mutationArgs?: any;
}

export const performOptimisticUpdateOnEntity = async <T>({
  dispatch,
  queryFulfilled,
  endpointName,
  entityAdapter,
  optimisticData,
  mutationArgs,
}: PerformOptimisticEntityAdapterUpdateArgs<T>): Promise<void> => {
  // Perform an optimistic update while we wait for the mutation to complete
  const patchResult = dispatch(
    // @ts-ignore
    doverApi.util.updateQueryData(endpointName, mutationArgs, draft => {
      entityAdapter.upsertOne(draft, optimisticData);
    })
  );

  try {
    // Once the mutation completes successfully, update our UI state with the latest source-of-truth
    // from the backend. This will resolve any potential discrepancies between our optimistic update
    // and what our backend returns.
    const { data } = await queryFulfilled;
    dispatch(
      // @ts-ignore
      doverApi.util.updateQueryData(endpointName, mutationArgs, draft => {
        entityAdapter.upsertOne(draft, data);
      })
    );
  } catch {
    // If our mutation failed, roll back our optimistic update.
    patchResult.undo;
  }
};

export function getJobSetupStepsWithUpdatedRelevancy(
  draftSetupSteps: MaybeDrafted<JobSetupStepsWithSetupSummaryState>,
  currentJobFeatureSettings: JobFeatures | undefined,
  jobFeatureSettingToUpdate: UpsertJobFeatureSetting
): JobSetupStepsWithSetupSummaryState {
  if (currentJobFeatureSettings === undefined) {
    return draftSetupSteps;
  }

  let newSetupSteps = { ...draftSetupSteps };

  // If we're enabling a feature, we can update all setup steps that are in the feature's set of dependent steps
  // We save a little bit on perf here by not needing to consider the other features and their dependent steps
  if (jobFeatureSettingToUpdate.state === UpsertJobFeatureSettingStateEnum.Enabled) {
    const currentJobFeatureSetting = currentJobFeatureSettings.features.find(
      // These types are generated by OpenAPI, and while identical, our TS client doesn't know that
      feature =>
        feature.featureName === ((jobFeatureSettingToUpdate.featureName as unknown) as JobFeatureFeatureNameEnum)
    );

    // This shouldn't happen but is a cheap and convenient early return that grants us type safety below
    if (!currentJobFeatureSetting) {
      return draftSetupSteps;
    }

    newSetupSteps.setupSteps.forEach((step, index) => {
      if (currentJobFeatureSetting.dependentJobSetupSteps.includes(step.stepName)) {
        newSetupSteps.setupSteps[index] = { ...step, isRelevantToJob: true };
      }
    });
  } else {
    // If we're disabling a feature, we need to check all features' dependent setup steps when updating the relevancy of setup steps

    // First, aggregate all relevant setup steps, and exclude the feature we're updating
    const relevantSetupSteps: Set<string> = new Set();
    currentJobFeatureSettings.features.forEach(feature => {
      if (
        feature.featureName !== ((jobFeatureSettingToUpdate.featureName as unknown) as JobFeatureFeatureNameEnum) &&
        feature.state === JobFeatureStateEnum.Enabled
      ) {
        feature.dependentJobSetupSteps.forEach(step => relevantSetupSteps.add(step));
      }
    });

    // Then, set relevancy for each step based on whether it appears in the above aggregated list
    newSetupSteps.setupSteps.forEach((step, index) => {
      let relevant = false;
      if (relevantSetupSteps.has(step.stepName as string)) {
        relevant = true;
      }

      newSetupSteps.setupSteps[index] = { ...step, isRelevantToJob: relevant };
    });
  }

  // Lastly, update the setup summary state
  let newSummaryState = JobSetupStepsWithSetupSummaryStateSetupSummaryStateEnum.StartedPendingDoverAction;
  let allAreComplete = true;
  let allArePending = true;
  let someStartedPendingClientActionOrPending = false;

  newSetupSteps.setupSteps.forEach(step => {
    if (!step.isRelevantToJob) {
      return;
    }

    if (
      [
        JobSetupStepStateEnum.StartedPendingDoverAction,
        JobSetupStepStateEnum.StartedPendingClientAction,
        JobSetupStepStateEnum.Pending,
      ].includes(step.state)
    ) {
      allAreComplete = false;
    }

    if (
      [
        JobSetupStepStateEnum.Complete,
        JobSetupStepStateEnum.StartedPendingDoverAction,
        JobSetupStepStateEnum.StartedPendingClientAction,
      ].includes(step.state)
    ) {
      allArePending = false;
    }

    if ([JobSetupStepStateEnum.StartedPendingClientAction, JobSetupStepStateEnum.Pending].includes(step.state)) {
      someStartedPendingClientActionOrPending = true;
    }
  });

  if (allAreComplete) {
    newSummaryState = JobSetupStepsWithSetupSummaryStateSetupSummaryStateEnum.Complete;
  } else if (allArePending) {
    newSummaryState = JobSetupStepsWithSetupSummaryStateSetupSummaryStateEnum.Pending;
  } else if (someStartedPendingClientActionOrPending) {
    newSummaryState = JobSetupStepsWithSetupSummaryStateSetupSummaryStateEnum.StartedPendingClientAction;
  }

  newSetupSteps = { ...newSetupSteps, setupSummaryState: newSummaryState };

  return newSetupSteps;
}

interface UpdateJobFeatureOnJobFeaturesObjectArgs {
  jobFeatures: JobFeatures;
  featureName: JobFeatureFeatureNameEnum;
  featureEnabled: boolean;
}

function updateJobFeatureOnJobFeaturesObject({
  jobFeatures,
  featureName,
  featureEnabled,
}: UpdateJobFeatureOnJobFeaturesObjectArgs): JobFeatures {
  const newJobFeatureSettings: JobFeatures = { ...jobFeatures };
  const featureToUpdateIndex = newJobFeatureSettings.features.findIndex(
    currentFeature => currentFeature.featureName === featureName
  );
  // Shouldn't happen, but better safe than sorry
  if (featureToUpdateIndex === -1) {
    showErrorToast("Attempted to update unknown feature. Please contact Dover for assistance.");

    return newJobFeatureSettings;
  }

  const featureToUpdate = newJobFeatureSettings.features[featureToUpdateIndex];
  newJobFeatureSettings.features[featureToUpdateIndex] = {
    ...featureToUpdate,
    state: featureEnabled ? JobFeatureStateEnum.Enabled : JobFeatureStateEnum.Disabled,
  };

  return newJobFeatureSettings;
}

export function getJobFeaturesWithUpdatedStates(
  currentJobFeatureSettings: MaybeDrafted<JobFeatures>,
  jobFeatureSettingToUpdate: UpsertJobFeatureSetting
): JobFeatures {
  let newJobFeatureSettings = updateJobFeatureOnJobFeaturesObject({
    jobFeatures: currentJobFeatureSettings,
    featureName: (jobFeatureSettingToUpdate.featureName as unknown) as JobFeatureFeatureNameEnum,
    featureEnabled: jobFeatureSettingToUpdate.state === UpsertJobFeatureSettingStateEnum.Enabled,
  });

  // Finally, update DoverServices based on the changes up to this point
  let areServicesEnabled = false;
  if (
    newJobFeatureSettings.features.find(setting => setting.featureName === JobFeatureFeatureNameEnum.DoverInterviewer)!
      .state === JobFeatureStateEnum.Enabled ||
    newJobFeatureSettings.features.find(setting => setting.featureName === JobFeatureFeatureNameEnum.E2EScheduling)!
      .state === JobFeatureStateEnum.Enabled ||
    newJobFeatureSettings.features.find(setting => setting.featureName === JobFeatureFeatureNameEnum.ManagedOutbound)!
      .state === JobFeatureStateEnum.Enabled
  ) {
    areServicesEnabled = true;
  }

  // Return our new job feature settings, including whether or not services are enabled
  newJobFeatureSettings = {
    ...newJobFeatureSettings,
    hasServicesEnabled: areServicesEnabled,
  };

  return newJobFeatureSettings;
}

export function getJobFeaturesWithBulkUpdatedStates(
  currentJobFeatureSettings: MaybeDrafted<JobFeatures>,
  jobFeatureSettingsToUpdate: BulkUpsertJobFeatureSetting[],
  jobId: string
): JobFeatures {
  let newJobFeatureSettings: JobFeatures = { ...currentJobFeatureSettings };
  jobFeatureSettingsToUpdate.forEach(update => {
    const typeCompatibleUpsert: UpsertJobFeatureSetting = {
      job: jobId,
      featureName: (update.featureName as unknown) as UpsertJobFeatureSettingFeatureNameEnum,
      state: (update.state as unknown) as UpsertJobFeatureSettingStateEnum,
      debugInfo: update.debugInfo,
    };
    newJobFeatureSettings = getJobFeaturesWithUpdatedStates(newJobFeatureSettings, typeCompatibleUpsert);
  });
  return newJobFeatureSettings;
}

export function getJobSetupStepsWithBulkUpdatedRelevancy(
  draftSetupSteps: MaybeDrafted<JobSetupStepsWithSetupSummaryState>,
  currentJobFeatureSettings: JobFeatures | undefined,
  jobFeatureSettingsToUpdate: BulkUpsertJobFeatureSetting[],
  jobId: string
): JobSetupStepsWithSetupSummaryState {
  if (!currentJobFeatureSettings) {
    return draftSetupSteps;
  }

  let newSetupSteps = { ...draftSetupSteps };
  jobFeatureSettingsToUpdate.forEach(update => {
    const typeCompatibleUpsert: UpsertJobFeatureSetting = {
      job: jobId,
      featureName: (update.featureName as unknown) as UpsertJobFeatureSettingFeatureNameEnum,
      state: (update.state as unknown) as UpsertJobFeatureSettingStateEnum,
      debugInfo: update.debugInfo,
    };
    newSetupSteps = getJobSetupStepsWithUpdatedRelevancy(
      newSetupSteps,
      currentJobFeatureSettings,
      typeCompatibleUpsert
    );
  });

  return newSetupSteps;
}
