/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkCampaignUpdate
 */
export interface BulkCampaignUpdate {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BulkCampaignUpdate
     */
    filters: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof BulkCampaignUpdate
     */
    newValues: { [key: string]: boolean; };
}

export function BulkCampaignUpdateFromJSON(json: any): BulkCampaignUpdate {
    return BulkCampaignUpdateFromJSONTyped(json, false);
}

export function BulkCampaignUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCampaignUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filters': json['filters'],
        'newValues': json['new_values'],
    };
}

export function BulkCampaignUpdateToJSON(value?: BulkCampaignUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filters': value.filters,
        'new_values': value.newValues,
    };
}


