import { TextField } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";

import {
  usePartialUpdateJobPositionMutation,
  usePartialUpdateJobPositionOpeningMutation,
} from "services/doverapi/endpoints/hiring-plan";
import { HiringPlanJobPosition, HiringPlanJobPositionOpening } from "services/openapi/models";
import { HiringPlanTableRow } from "views/HiringPlan/types";

const StyledTextField = styled(TextField)`
  position: relative;
  .MuiInputBase-root {
    width: 100%;
    font-size: 14px;
    font-family: Inter;
  }
`;

interface Props {
  tableRow: HiringPlanTableRow;
  accessor: string;
  placeholder?: string;
  alwaysEditable?: boolean;
  updateJobPositionOpening?: boolean;
  type?: string;
}

const TextFieldCell = ({
  tableRow,
  accessor,
  placeholder,
  alwaysEditable,
  updateJobPositionOpening,
  type,
}: Props): React.ReactElement => {
  const [text, setText] = useState(tableRow[accessor as keyof HiringPlanTableRow] || "");

  const [partialUpdateJobPositionOpening] = usePartialUpdateJobPositionOpeningMutation();
  const [partialUpdateJobPosition] = usePartialUpdateJobPositionMutation();

  const handleUpdateJobPosition = (): void => {
    if (updateJobPositionOpening) {
      const updatedJobPositionOpening = {
        ...tableRow.jobPositionOpening,
        [accessor as keyof HiringPlanJobPositionOpening]: text || null,
      };
      partialUpdateJobPositionOpening({
        jobPositionOpening: updatedJobPositionOpening as HiringPlanJobPositionOpening,
      });
      return;
    }
    const updatedJobPosition = {
      ...tableRow.jobPosition,
      [accessor as keyof HiringPlanJobPosition]: text || null,
    };
    partialUpdateJobPosition({ jobPosition: updatedJobPosition as HiringPlanJobPosition });
  };

  return (
    <StyledTextField
      fullWidth
      disabled={!tableRow.isFirstOpening && !alwaysEditable}
      value={text}
      onChange={({ target: { value } }): void => setText(value)}
      onBlur={(): void => {
        setTimeout(handleUpdateJobPosition, 500);
      }}
      placeholder={placeholder || ""}
      type={type}
      variant="standard"
    />
  );
};

export default TextFieldCell;
