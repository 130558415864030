import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Outlet, useLocation } from "react-router";
import { useBoolean } from "react-use";

import { AppId, SIDE_NAV_WIDTH, SPACE_RESERVED_FOR_DRAWER_TOGGLE_MENU } from "App/appConstants";
import { SideNavV2 } from "App/components/Nav";
import { segmentLoadedAtom } from "App/routing/atoms";
import ErrorFallback from "App/routing/ErrorFallback";
import { useMatchPath } from "App/routing/hooks";
import {
  APPLICATION_REVIEW_PATH,
  APP_ROUTE_PATHS,
  CANDIDATE_INTERVIEWS_PATH,
  JOB_PATH,
} from "App/routing/route-path-constants";
import { errorHandler } from "App/routing/utils";
import GlobalStyles from "App/styles/App";
import { useGetDoverPlan, useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { colors, screenSizesNumbers } from "styles/theme";
import "App/styles/App.scss";
import "@doverhq/dover-ui/dist/tailwind.css";

/**
 * This is the main layout for the app. It contains the side nav and the main app content.
 * It includes logic for adjusting layout widths based on screen size and whether the side nav is open or not.
 */
const MainLayout = (): React.ReactElement => {
  const location = useLocation();
  // If the user is on a small screen, the side nav will be collapsed and can be toggle by clicking the open nav icon
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tabletL));

  const doverPlan = useGetDoverPlan();
  const isBasePlan = useIsBasePlanCustomer();
  const segmentLoadedValue = useAtomValue(segmentLoadedAtom);

  const intercom = (window as any).Intercom;

  // Disable intercom altogether for paid customers
  useEffect(() => {
    if (doverPlan && !isBasePlan && intercom) {
      intercom("update", {
        hide_default_launcher: true,
      });
    }
  }, [intercom, doverPlan, isBasePlan, segmentLoadedValue]);

  // The nav drawer can be toggled on at any screen size, but it is open by default on large screens
  const [drawerOpen, toggleDrawerOpen] = useBoolean(false);
  useEffect(() => {
    toggleDrawerOpen(!isSmallScreen);
  }, [isSmallScreen, toggleDrawerOpen]);

  // Some pages are a special case and need layout adjusted to not reserve space for the open nav icon
  const shouldReserveSpaceForOpenNavIcon = !useMatchPath([
    APP_ROUTE_PATHS.home(),
    APP_ROUTE_PATHS.reports.visualExplorer(),
    `${JOB_PATH}/*`,
    `${CANDIDATE_INTERVIEWS_PATH}/*`,
    `${APPLICATION_REVIEW_PATH}/*`,
  ]);

  return (
    <>
      {/* Ensures backwards compatiblity for styling on  non-refreshed pages */}
      <GlobalStyles />

      {/* Wrap all UX in an error boundary so we can let users known when something went wrong */}
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} resetKeys={[location.pathname]}>
        {/* Ensures that flex layout works for the side bar and main app content at a top level */}
        <Box display="flex">
          {/* placeholder for where the side nav will go */}
          <SideNavV2 drawerOpen={drawerOpen} toggleDrawerOpen={toggleDrawerOpen} />
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} resetKeys={[location.pathname]}>
            {/* We want everything non-sidebar to fill up the rest of the screen horizontally and vertically */}
            <Box
              id={AppId}
              // Make sure the app content is always the full height of the screen
              height={"100vh"}
              pl={!drawerOpen && shouldReserveSpaceForOpenNavIcon ? `${SPACE_RESERVED_FOR_DRAWER_TOGGLE_MENU}px` : 0}
              // Make sure the app content is always the full width of the screen
              sx={{
                width: `calc(100% - ${isSmallScreen || !drawerOpen ? 0 : SIDE_NAV_WIDTH}px)`,
                backgroundColor: colors.grayscale.gray025,
                // Add some transitions for visual delight
                transition: muiTheme.transitions.create(["margin", "width"], {
                  easing: muiTheme.transitions.easing.sharp,
                  duration: muiTheme.transitions.duration.leavingScreen,
                }),
                marginLeft: isSmallScreen ? 0 : `-${SIDE_NAV_WIDTH}px`,
                ...(drawerOpen && {
                  transition: muiTheme.transitions.create("margin", {
                    easing: muiTheme.transitions.easing.easeOut,
                    duration: muiTheme.transitions.duration.enteringScreen,
                  }),
                  marginLeft: 0,
                }),
              }}
            >
              <Stack height="100vh">
                <Box overflow="auto" flexGrow={1}>
                  <Outlet />
                </Box>
              </Stack>
            </Box>
          </ErrorBoundary>
        </Box>
      </ErrorBoundary>
    </>
  );
};

export default MainLayout;
