/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailSendRequest
 */
export interface EmailSendRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailSendRequest
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailSendRequest
     */
    sendAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof EmailSendRequest
     */
    readonly automatedType?: string;
}

export function EmailSendRequestFromJSON(json: any): EmailSendRequest {
    return EmailSendRequestFromJSONTyped(json, false);
}

export function EmailSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailSendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sendAt': !exists(json, 'send_at') ? undefined : (json['send_at'] === null ? null : new Date(json['send_at'])),
        'automatedType': !exists(json, 'automated_type') ? undefined : json['automated_type'],
    };
}

export function EmailSendRequestToJSON(value?: EmailSendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'send_at': value.sendAt === undefined ? undefined : (value.sendAt === null ? null : value.sendAt.toISOString()),
    };
}


