import { Button } from "@doverhq/dover-ui";
import React from "react";

import { Body } from "components/library/typography";
import CustomModal from "components/Modal";
import { AuthedUser } from "services/openapi";

export const BlockModal = ({
  user,
  open,
  onClose,
  setUserBlockedFlag,
  isMutatingUsers,
}: {
  user?: AuthedUser;
  open: boolean;
  onClose: () => void;
  setUserBlockedFlag: any;
  isMutatingUsers: boolean;
}): React.ReactElement => {
  if (!user || !user.id) {
    return <></>;
  }

  // if user is currently blocked, it informs us that the action to take is to unblock/reactivate
  // otherwise, deactivate
  const userIsBlocked = !!user.blocked;
  const userIdentifier = user.name || user.email;
  const reactivateOrDeactivateText = userIsBlocked ? "Reactivate" : "Deactivate";

  return (
    <CustomModal
      open={open}
      maxWidth="sm"
      title={`${reactivateOrDeactivateText} ${userIdentifier || "this user"}`}
      onClose={onClose}
      omitDividers
      dialogActions={
        <Button
          variant={userIsBlocked ? "primaryFilled" : "criticalFilled"}
          isDisabled={isMutatingUsers}
          onPress={async (): Promise<void> => {
            // first make the call, then on success close the modal
            await setUserBlockedFlag({ user: user.id!.toString(), blocked: !userIsBlocked })
              .unwrap()
              .then(() => {
                onClose();
              });
          }}
        >
          {reactivateOrDeactivateText}
        </Button>
      }
    >
      {!userIsBlocked ? (
        <Body>{`${userIdentifier || "This user"} won't be able to sign in anymore.`}</Body>
      ) : (
        <Body>{`${userIdentifier || "This user"} will now be able to sign in again.`}</Body>
      )}
    </CustomModal>
  );
};
