/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject26
 */
export interface InlineObject26 {
    /**
     * 
     * @type {object}
     * @memberof InlineObject26
     */
    answers: object;
    /**
     * 
     * @type {string}
     * @memberof InlineObject26
     */
    jobId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject26
     */
    personaQuestionnaireId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject26
     */
    searchName: string;
}

export function InlineObject26FromJSON(json: any): InlineObject26 {
    return InlineObject26FromJSONTyped(json, false);
}

export function InlineObject26FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject26 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answers': json['answers'],
        'jobId': json['job_id'],
        'personaQuestionnaireId': json['persona_questionnaire_id'],
        'searchName': json['search_name'],
    };
}

export function InlineObject26ToJSON(value?: InlineObject26 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answers': value.answers,
        'job_id': value.jobId,
        'persona_questionnaire_id': value.personaQuestionnaireId,
        'search_name': value.searchName,
    };
}


