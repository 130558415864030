/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobSetupStep,
    JobSetupStepFromJSON,
    JobSetupStepFromJSONTyped,
    JobSetupStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobSetupStepsWithSetupSummaryState
 */
export interface JobSetupStepsWithSetupSummaryState {
    /**
     * 
     * @type {Array<JobSetupStep>}
     * @memberof JobSetupStepsWithSetupSummaryState
     */
    setupSteps: Array<JobSetupStep>;
    /**
     * 
     * @type {string}
     * @memberof JobSetupStepsWithSetupSummaryState
     */
    jpqId: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobSetupStepsWithSetupSummaryState
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetupStepsWithSetupSummaryState
     */
    setupSummaryState: JobSetupStepsWithSetupSummaryStateSetupSummaryStateEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetupStepsWithSetupSummaryState
     */
    setupSummaryHiringStagesState: JobSetupStepsWithSetupSummaryStateSetupSummaryHiringStagesStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum JobSetupStepsWithSetupSummaryStateSetupSummaryStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum JobSetupStepsWithSetupSummaryStateSetupSummaryHiringStagesStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function JobSetupStepsWithSetupSummaryStateFromJSON(json: any): JobSetupStepsWithSetupSummaryState {
    return JobSetupStepsWithSetupSummaryStateFromJSONTyped(json, false);
}

export function JobSetupStepsWithSetupSummaryStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobSetupStepsWithSetupSummaryState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'setupSteps': ((json['setup_steps'] as Array<any>).map(JobSetupStepFromJSON)),
        'jpqId': json['jpq_id'],
        'id': json['id'],
        'setupSummaryState': json['setup_summary_state'],
        'setupSummaryHiringStagesState': json['setup_summary_hiring_stages_state'],
    };
}

export function JobSetupStepsWithSetupSummaryStateToJSON(value?: JobSetupStepsWithSetupSummaryState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'setup_steps': ((value.setupSteps as Array<any>).map(JobSetupStepToJSON)),
        'jpq_id': value.jpqId,
        'id': value.id,
        'setup_summary_state': value.setupSummaryState,
        'setup_summary_hiring_stages_state': value.setupSummaryHiringStagesState,
    };
}


