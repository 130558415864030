import produce from "immer";

import {
  CampaignViewActionTypes,
  CampaignViewState,
  LOAD_CAMPAIGN,
  LOAD_CAMPAIGN_SUCCESS,
  LOAD_CAMPAIGN_ERROR,
  SAVE_CAMPAIGN,
  SAVE_CAMPAIGN_SUCCESS,
  SAVE_CAMPAIGN_ERROR,
  SEND_TEST_EMAILS,
  LOAD_UNSUBSCRIBE_FOOTERS,
  LOAD_UNSUBSCRIBE_FOOTERS_SUCCESS,
  LOAD_UNSUBSCRIBE_FOOTERS_ERROR,
} from "views/admin/CampaignView/types";

// The initial state
export const initialState: CampaignViewState = {
  loading: false,
  saving: false,
  unsubscribeFootersLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action: CampaignViewActionTypes): CampaignViewState =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_CAMPAIGN:
        draft.loading = true;
        break;
      case LOAD_CAMPAIGN_SUCCESS:
        draft.loading = false;
        draft.campaign = action.campaign;
        break;
      case LOAD_CAMPAIGN_ERROR:
        draft.loading = false;
        break;
      case SAVE_CAMPAIGN:
        draft.saving = true;
        break;
      case SAVE_CAMPAIGN_SUCCESS:
        draft.saving = false;
        draft.campaign = action.campaign;
        break;
      case SAVE_CAMPAIGN_ERROR:
        draft.saving = false;
        break;
      case LOAD_UNSUBSCRIBE_FOOTERS:
        draft.unsubscribeFootersLoading = true;
        break;
      case LOAD_UNSUBSCRIBE_FOOTERS_SUCCESS:
        draft.unsubscribeFootersLoading = false;
        draft.unsubscribeFooters = action.unsubscribeFooters;
        break;
      case LOAD_UNSUBSCRIBE_FOOTERS_ERROR:
        draft.unsubscribeFootersLoading = false;
        break;
      case SEND_TEST_EMAILS:
        break;
    }
  });

export default reducer;
