import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as InboundCriteriaSVG } from "assets/icons/lite-ats.svg";
import {
  emptyV3Params,
  hackyLocationMap,
} from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup/constants";
import {
  CompaniesFormSection,
  JobLocationFormSection,
  QualificationsFormSection,
} from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup/form-sections";
import {
  InboundCriteriaSetupFormValues,
  InboundCriteriaSetupFormSchema,
} from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup/types";
import Loading from "components/HotLoading";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { BodySmall, PageTitle, Subtitle1 } from "components/library/typography";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import {
  useGetJobDescriptionQuery,
  useLazyGetSearchParamsFromJDQuery,
  useParseJdFeaturesMutation,
} from "services/doverapi/endpoints/job-description/endpoints";
import {
  useLazyListProfileSearchKeywordsQuery,
  useListAllLocations,
  usePartialUpdateSearchV3Mutation,
  useCreateSearchV3Mutation,
  useListSearchesV3Query,
  useListPersonasQuery,
  useGetPersonaForJobMutation,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { LocationWithValue } from "services/doverapi/endpoints/search-v3/types";
import { Locations, ProfileSearchKeywords, SearchV3, SearchV3SearchTypeEnum } from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { sharedCriteriaAtom } from "views/create-job/ApplicantSortingSetupFlow/atoms";
import { STEP_FLOW_PAGE_CONTAINER_HEIGHT } from "views/create-job/constants";
import {
  companySizeToSearchParamsCompanySize,
  industrySerializerToSearchParamsIndustries,
  individualCompaniesToSearchParamsIndividualCompanies,
  locationsToSearchParamsLocations,
  yearsOfExperienceToSearchParamsV3TotalYearsOfExperience,
  keywordsToSearchParamKeywordBuckets,
} from "views/sourcing/Search/utils";

/* -----------------------------------------------------------------------------
 * InboundCriteriaSetupForm
 * -------------------------------------------------------------------------- */

export interface InboundCriteriaSetupFormProps {
  jobId: string;
  onNext: () => void;
}

export const InboundCriteriaSetupForm = ({ jobId, onNext }: InboundCriteriaSetupFormProps): React.ReactElement => {
  const muiTheme = useTheme();
  const isLargeScreen = useMediaQuery(muiTheme.breakpoints.up(screenSizesNumbers.mobileL));
  const [sharedCriteria] = useAtom(sharedCriteriaAtom);

  const formMethods = useForm<InboundCriteriaSetupFormValues>({
    resolver: zodResolver(InboundCriteriaSetupFormSchema),
    defaultValues: {
      persona: sharedCriteria?.persona,
      locations: [],
      yearsOfExperience: sharedCriteria?.yearsOfExperience ?? { min: 0, max: 5 },
      keywords: sharedCriteria?.keywords ?? [],
      companySizes: [],
      industries: { industryElements: [] },
      targetCompanies: [],
    },
  });
  const { control } = formMethods;
  const personaFormValue = useWatch({ control, name: "persona" });
  const locationsFormValue = useWatch({ control, name: "locations" });
  const keywordsFormValue = useWatch({ control, name: "keywords" });
  const [isLoadingPrefilledData, setIsLoadingPrefilledData] = useState<boolean>(true);

  const { data: personasOptions } = useListPersonasQuery({ limit: 1000 });
  const { data: jobSetup } = useGetJobSetupQuery(jobId ? jobId : skipToken);
  const { data: jobDescription } = useGetJobDescriptionQuery(
    jobSetup?.id ? { jobId: jobSetup.id, requiresAuthentication: true } : skipToken
  );
  const { data: searches } = useListSearchesV3Query(
    jobId
      ? {
          job: jobId,
          searchTypeList: SearchV3SearchTypeEnum.Inbound,
        }
      : skipToken
  );
  const locationsOptions = useListAllLocations();
  const [createSearch] = useCreateSearchV3Mutation();
  const [partialUpdateSearch] = usePartialUpdateSearchV3Mutation();
  const [getSearchFeatures] = useLazyGetSearchParamsFromJDQuery();
  const [lazyListKeywords] = useLazyListProfileSearchKeywordsQuery();
  const [parseJdFeatures] = useParseJdFeaturesMutation();
  const [getPersonaForJob] = useGetPersonaForJobMutation();

  const getSearchV3Payload = (search?: SearchV3): SearchV3 => {
    const formValues = formMethods.getValues();
    const defaultV3Params = search?.v3Params;

    return {
      name: search?.name ?? "onboarding inbound",
      version: 3,
      job: jobSetup?.id!,
      client: jobSetup?.client!,
      searchType: SearchV3SearchTypeEnum.Inbound,
      v3Params: {
        ...emptyV3Params,
        ...defaultV3Params,
        targetPersonas: [
          {
            field: "personas",
            value: [formValues.persona.id],
            required: true,
            weight: undefined,
          },
        ],
        targetLocations: locationsToSearchParamsLocations({
          locationElements: formValues.locations,
          required: true,
        }),
        totalYearsOfExperience: yearsOfExperienceToSearchParamsV3TotalYearsOfExperience(
          {
            yearsOfExperience: formValues.yearsOfExperience,
            required: true,
          },
          "Total years of experience"
        ),
        industries: industrySerializerToSearchParamsIndustries({
          industryElements: formValues.industries?.industryElements ?? [],
          required: true,
        }),
        individualTargetCompanyIds: individualCompaniesToSearchParamsIndividualCompanies({
          individualCompanyElements: formValues.targetCompanies ?? [],
          required: true,
        }),
        companySize: companySizeToSearchParamsCompanySize(
          {
            value: formValues.companySizes ?? [],
            required: true,
            mostRecentOnly: false,
          },
          "Desired company size"
        ),
        keywords: keywordsToSearchParamKeywordBuckets([
          {
            bucket: formValues.keywords,
            required: true,
          },
        ]),
      },
    };
  };

  const onSubmit = async (): Promise<void> => {
    if (searches?.length) {
      const searchToUpdate = searches[0];
      await partialUpdateSearch({
        id: searchToUpdate.id!,
        data: getSearchV3Payload(searchToUpdate),
      }).unwrap();
    } else {
      await createSearch(getSearchV3Payload()).unwrap();
    }
    onNext();
  };

  const includesRequiredFields = React.useMemo(() => {
    return personaFormValue && locationsFormValue.length && keywordsFormValue.length;
  }, [personaFormValue, locationsFormValue, keywordsFormValue]);

  // We only need to parse the JD features if we don't already have
  // the data stored in the atom
  useEffect(() => {
    const promise = async (): Promise<void> => {
      const formValues = formMethods.getValues();
      const jdTextForParamsFetching = jobDescription?.useDoverJd
        ? jobDescription?.userFacingDescription
        : jobDescription?.userProvidedDescription;

      // In the event we have sharedCriteria, we do not need to parse the JD
      if (sharedCriteria && jobId && personasOptions) {
        if (!formValues.persona) {
          const personaData = await getPersonaForJob({ jobId: jobId }).unwrap();
          const persona = personasOptions?.find(persona => persona.id === personaData?.persona?.id);
          if (persona) {
            formMethods.setValue("persona", persona);
          }
        }

        // in the backend we convert the JD parsed locations to the v3 format, which corresponds to the
        // 'value' part of our loaded locationOptions
        if (!formValues.locations && sharedCriteria?.locations && locationsOptions) {
          const locationObjects = sharedCriteria.locations
            .map((rawLoc: LocationWithValue) => {
              // look up in hackyLocationMap
              const canonicalLocValue = hackyLocationMap[rawLoc.value] || rawLoc.value;

              return locationsOptions.find(loc => loc.value === canonicalLocValue);
            })
            .filter((loc: LocationWithValue | undefined): boolean => {
              return loc !== undefined;
            }) as LocationWithValue[];
          formMethods.setValue("locations", locationObjects);
        }

        setIsLoadingPrefilledData(false);
      }

      // We only want to proceed with parsing the JD once we have all of the necessary data and if
      // we do not have sharedCriteria
      if (!sharedCriteria && jdTextForParamsFetching && jobId && locationsOptions && personasOptions) {
        const parsedFeatures = await getSearchFeatures({
          jdText: jdTextForParamsFetching,
          jobTitle: jobSetup?.title,
          jobId: jobId,
        }).unwrap();

        if (parsedFeatures.personaId && !formValues.persona) {
          const persona = personasOptions?.find(persona => persona.id === parsedFeatures.personaId);
          if (persona) {
            formMethods.setValue("persona", persona);
          }
        }

        // NOTE: We are not setting the locations or keywords temporarily because
        // we only receive the string name of the loc/keyword from the backend
        // and not the full object that we require to create the search
        const requiredLocationIds = parsedFeatures.searchParams.targetLocations
          .filter((innerLoc: Locations): boolean => !!innerLoc.required)
          .map((innerLoc: Locations): string[] => innerLoc.value);

        if (!formValues.locations.length && requiredLocationIds?.length && locationsOptions) {
          // in the backend we convert the JD parsed locations to the v3 format, which corresponds to the
          // 'value' part of our loaded locationOptions
          const locationObjects = locationsOptions.filter((loc: LocationWithValue): boolean => {
            return !!requiredLocationIds.find((innerLocIds: string[]) => innerLocIds.includes(loc.value));
          });

          formMethods.setValue("locations", locationObjects);
        }

        // note that we call join twice as a means of flattening the buckets of required keywords
        const requiredKeywordIds = parsedFeatures.searchParams.keywords
          .filter((keywordBucket: ProfileSearchKeywords): any => {
            return keywordBucket.required;
          })
          .map((keywordBucket: ProfileSearchKeywords): string => {
            return keywordBucket.value.join(",");
          })
          .join(",");

        if (requiredKeywordIds?.length) {
          const keywordObjs = await lazyListKeywords({ idList: requiredKeywordIds });
          if (keywordObjs.data?.length) {
            formMethods.setValue("keywords", keywordObjs.data);
          }
        }

        const hasYoeSpecified = parsedFeatures.searchParams.totalYearsOfExperience.length;
        const defaultMin = hasYoeSpecified
          ? parsedFeatures.searchParams.totalYearsOfExperience[0].value.min
          : undefined;
        const defaultMax = hasYoeSpecified
          ? parsedFeatures.searchParams.totalYearsOfExperience[0].value.max
          : undefined;

        const newYOE = { min: defaultMin, max: defaultMax };
        formMethods.setValue("yearsOfExperience", newYOE);

        setIsLoadingPrefilledData(false);
      }
    };

    try {
      promise();
    } catch (_) {
      // We want to remove the loading state if we encounter an error so that the user can manually enter the data into the form
      setIsLoadingPrefilledData(false);
    }
  }, [
    parseJdFeatures,
    personasOptions,
    sharedCriteria,
    formMethods,
    jobDescription,
    jobSetup?.title,
    locationsOptions,
    setIsLoadingPrefilledData,
    getSearchFeatures,
    getPersonaForJob,
    jobId,
    lazyListKeywords,
  ]);

  if (isLoadingPrefilledData) return <Loading />;

  return (
    <FormProvider {...formMethods}>
      {/*  Full Screen Container */}
      <Stack
        height={STEP_FLOW_PAGE_CONTAINER_HEIGHT}
        width="100%"
        direction="row"
        justifyContent="center"
        alignItems="center"
        py={3}
      >
        <Box overflow="auto" height="100%">
          <Card>
            <Stack spacing={2} maxWidth="556px" minHeight="0">
              {isLargeScreen ? (
                <Stack alignItems="center" textAlign="center" spacing={2}>
                  <InboundCriteriaSVG />
                  <div>
                    <PageTitle>Confirm your applicant sorting criteria</PageTitle>
                    <BodySmall color={colors.grayscale.gray600}>
                      Dover will use this information to sort applicants by best match. We&apos;ve pre-filled some of
                      these based on your job description and company information.
                    </BodySmall>
                  </div>
                </Stack>
              ) : (
                <Subtitle1>
                  Dover&apos;s AI will score candidates by how well they match the following criteria{" "}
                </Subtitle1>
              )}
              <JobLocationFormSection />
              <QualificationsFormSection />
              <CompaniesFormSection />
              <Stack flex="1 1 auto" alignItems="flex-end">
                <Button
                  variant={ButtonVariant.Primary}
                  onClick={formMethods.handleSubmit(onSubmit)}
                  disabled={!includesRequiredFields}
                  tooltip={!includesRequiredFields ? "Please fill out all required fields" : undefined}
                >
                  Next <ArrowRight display="inline" color={colors.white} className="svg-color" />
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Box>
      </Stack>
    </FormProvider>
  );
};
