import { createEntityAdapter, EntityState, SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { EMAIL_SENDER_ALIAS, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import { OnboardingEmailSenderAlias } from "services/openapi/models";

const emailSenderAliasAdapter = createEntityAdapter<OnboardingEmailSenderAlias>();

const emailSenderAliasEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listEmailSenderAliases: build.query<EntityState<OnboardingEmailSenderAlias>, void>({
      queryFn: async () => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.listEmailSenderAliases({ offset: 100 });
          return {
            data: emailSenderAliasAdapter.addMany(emailSenderAliasAdapter.getInitialState(), response.results),
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result
          ? [
              ...result.ids.map(id => ({ type: EMAIL_SENDER_ALIAS, id } as const)),
              { type: EMAIL_SENDER_ALIAS, id: LIST_TAG },
            ]
          : [];
      },
    }),
  }),
});

export const { useListEmailSenderAliasesQuery } = emailSenderAliasEndpoints;
