/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateActionEmailArgs,
    CandidateActionEmailArgsFromJSON,
    CandidateActionEmailArgsFromJSONTyped,
    CandidateActionEmailArgsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SendEmailRequest
 */
export interface SendEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof SendEmailRequest
     */
    candidateActionVersion: number;
    /**
     * 
     * @type {CandidateActionEmailArgs}
     * @memberof SendEmailRequest
     */
    emailArgs: CandidateActionEmailArgs | null;
}

export function SendEmailRequestFromJSON(json: any): SendEmailRequest {
    return SendEmailRequestFromJSONTyped(json, false);
}

export function SendEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateActionVersion': json['candidate_action_version'],
        'emailArgs': CandidateActionEmailArgsFromJSON(json['email_args']),
    };
}

export function SendEmailRequestToJSON(value?: SendEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_action_version': value.candidateActionVersion,
        'email_args': CandidateActionEmailArgsToJSON(value.emailArgs),
    };
}


