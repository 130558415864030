import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Grid, Stack } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import BriefcaseIconSVG from "assets/icons/briefcase.svg";
import { Button, ButtonVariant } from "components/library/Button";
import DoverLoadingOverlay from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { CardHeader } from "styles/typography";
import { FormCard } from "views/job/styles";

const JobPitchFormTemplate = ({
  updating,
  formFields,
  fullWidth = false,
  afterSaveHook,
}: {
  updating: boolean;
  formFields: any;
  fullWidth?: boolean;
  afterSaveHook: () => void;
}): React.ReactElement => {
  const { handleSubmit, getState } = useFormApi();
  const { valid } = getState();

  const handleSaveAndNext = async (): Promise<void> => {
    await handleSubmit();
    afterSaveHook();
  };

  return (
    <>
      <FormCard $fullWidth={fullWidth}>
        <Box display="flex" alignItems="flex-end">
          <ReactSVG src={BriefcaseIconSVG} />
          <Spacer width="8px" />
          <CardHeader>Job Pitch</CardHeader>
        </Box>
        <Spacer height="48px" />

        {updating && <DoverLoadingOverlay active absolute />}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {formFields}
          </Grid>
        </form>
      </FormCard>

      <Stack direction="row" justifyContent="end" width="100%">
        <Button variant={ButtonVariant.Primary} onClick={handleSaveAndNext} disabled={!valid || updating}>
          Save
        </Button>
      </Stack>
    </>
  );
};

export default JobPitchFormTemplate;
