/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchV3Params,
    SearchV3ParamsFromJSON,
    SearchV3ParamsFromJSONTyped,
    SearchV3ParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface DepthRequest
 */
export interface DepthRequest {
    /**
     * 
     * @type {SearchV3Params}
     * @memberof DepthRequest
     */
    params: SearchV3Params;
    /**
     * 
     * @type {string}
     * @memberof DepthRequest
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof DepthRequest
     */
    searchId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DepthRequest
     */
    excludeNoEmailsPeople?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepthRequest
     */
    filters?: Array<DepthRequestFiltersEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DepthRequest
     */
    forbiddenReasons?: Array<DepthRequestForbiddenReasonsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum DepthRequestFiltersEnum {
    CompanySizes = 'Company Sizes',
    FieldOfStudy = 'Field of Study',
    EducationLevel = 'Education Level',
    Keywords = 'Keywords',
    Industries = 'Industries',
    ImpressiveCompanies = 'Impressive Companies',
    ImpressiveSchool = 'Impressive School',
    LikelihoodOfInterest = 'Likelihood of Interest',
    Persona = 'Persona',
    Location = 'Location',
    YearsOfExperience = 'Years of Experience',
    Seniority = 'Seniority',
    TargetCompanies = 'Target Companies',
    TargetSchools = 'Target Schools',
    StrictnessScale = 'Strictness Scale',
    Diversity = 'Diversity',
    CompanyPrestige = 'Company Prestige',
    SchoolPrestige = 'School Prestige',
    ExcludedEmployees = 'Excluded Employees'
}/**
* @export
* @enum {string}
*/
export enum DepthRequestForbiddenReasonsEnum {
    ProfileUnavailable = 'PROFILE_UNAVAILABLE',
    NoEmailForProfile = 'NO_EMAIL_FOR_PROFILE',
    Client = 'CLIENT',
    Job = 'JOB'
}

export function DepthRequestFromJSON(json: any): DepthRequest {
    return DepthRequestFromJSONTyped(json, false);
}

export function DepthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DepthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'params': SearchV3ParamsFromJSON(json['params']),
        'jobId': !exists(json, 'job_id') ? undefined : json['job_id'],
        'searchId': !exists(json, 'search_id') ? undefined : json['search_id'],
        'excludeNoEmailsPeople': !exists(json, 'exclude_no_emails_people') ? undefined : json['exclude_no_emails_people'],
        'filters': !exists(json, 'filters') ? undefined : json['filters'],
        'forbiddenReasons': !exists(json, 'forbidden_reasons') ? undefined : json['forbidden_reasons'],
    };
}

export function DepthRequestToJSON(value?: DepthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'params': SearchV3ParamsToJSON(value.params),
        'job_id': value.jobId,
        'search_id': value.searchId,
        'exclude_no_emails_people': value.excludeNoEmailsPeople,
        'filters': value.filters,
        'forbidden_reasons': value.forbiddenReasons,
    };
}


