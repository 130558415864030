import { SerializedError } from "@reduxjs/toolkit";

import { Analytics } from "services/analytics";
import { HTW_JD_PARSED_EVENT } from "services/analytics/constants";
import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { JOB_DESCRIPTION, JOB_SETUP } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiGenerateJobDescriptionOperationRequest,
  CreateJobDescriptionWithTextRequest,
  DoverJobDescription,
  ParseJDFeaturesRequest,
  GetSearchParamsFromJDResponse,
  GetSearchParamsFromJDRequest,
  ParseJDFeaturesResponse,
  UpdateJobDescription,
} from "services/openapi";
import { GenerateJobDescriptionResponse } from "services/openapi/models/GenerateJobDescriptionResponse";
import { showErrorToast, showSuccessToast } from "utils/showToast";

const jobDescriptionEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getJobDescription: build.query<DoverJobDescription, { jobId: string; requiresAuthentication?: boolean }>({
      queryFn: async ({ jobId, requiresAuthentication = true }) => {
        const { apiApi: client } = await getOpenApiClients({ includeToken: requiresAuthentication });

        let response: DoverJobDescription;
        try {
          response = await client.getJobDescription({ id: jobId });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }

        return {
          data: response,
        };
      },
      providesTags: result => {
        return result ? [{ type: JOB_DESCRIPTION, id: result.id! } as const] : [];
      },
    }),
    updateJobDescription: build.mutation<
      DoverJobDescription,
      { id: string; updatedJobDescription: UpdateJobDescription; showToast?: boolean }
    >({
      queryFn: async ({ id, updatedJobDescription, showToast = true }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let doverJobDescription: DoverJobDescription;
        try {
          doverJobDescription = await client.updateJobDescription({
            id: id,
            data: updatedJobDescription,
          });
          showToast && showSuccessToast("Updated job description");
        } catch (error) {
          const userFacingMessage = "Failed to update job description. Please refresh and try again.";
          showToast && showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data: doverJobDescription };
      },
      invalidatesTags: result => {
        return result
          ? [
              { type: JOB_DESCRIPTION, id: result.id },
              // Also invalidate our job setup endpoint because updating JD values may have a side-effect of changing
              // setup_jd_state.
              { type: JOB_SETUP, id: result.job.id! },
            ]
          : [];
      },
    }),
    parseJdFeatures: build.mutation<ParseJDFeaturesResponse, ParseJDFeaturesRequest>({
      queryFn: async args => {
        const analytics = new Analytics();
        const { apiApi: client } = await getOpenApiClients({});
        let response;
        try {
          response = await client.parseJDFeatures({ data: args });
        } catch (error) {
          // @ts-ignore jenkins fails on this type, for some reason it adds undefined
          analytics.track(HTW_JD_PARSED_EVENT, { jdLink: args.jdLink, success: false });
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }

        // @ts-ignore jenkins fails on this type, for some reason it adds undefined
        analytics.track(HTW_JD_PARSED_EVENT, { jdLink: args.jdLink, success: true });
        return { data: response };
      },
    }),
    getSearchParamsFromJD: build.query<GetSearchParamsFromJDResponse, GetSearchParamsFromJDRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        let response;
        try {
          response = await client.getSearchParamsFromJD({ data: args });
        } catch (error) {
          const userFacingMessage = "Failed to parse job description. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: response };
      },
    }),
    saveJDAnswersToJDObject: build.mutation<boolean, CreateJobDescriptionWithTextRequest>({
      queryFn: async ({ jobId, jdText, rawJdParsingResult, jdHighlights }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.createJobDescriptionWithText({
            data: { jobId, jdText, rawJdParsingResult, jdHighlights },
          });
          return { data: response.success };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    generateJobDescription: build.mutation<
      GenerateJobDescriptionResponse,
      ApiApiGenerateJobDescriptionOperationRequest
    >({
      queryFn: async data => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.generateJobDescription(data);
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result
          ? [
              { type: JOB_DESCRIPTION, id: result.jobDescription.id },
              // Also invalidate our job setup endpoint because updating JD values may have a side-effect of changing
              // setup_jd_state.
              { type: JOB_SETUP, id: result.jobDescription.id },
            ]
          : [];
      },
    }),
  }),
});

export const {
  useGetJobDescriptionQuery,
  useUpdateJobDescriptionMutation,
  useSaveJDAnswersToJDObjectMutation,
  useParseJdFeaturesMutation,
  useLazyGetSearchParamsFromJDQuery,
  useGenerateJobDescriptionMutation,
} = jobDescriptionEndpoints;
