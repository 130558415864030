/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SpeechSegment,
    SpeechSegmentFromJSON,
    SpeechSegmentFromJSONTyped,
    SpeechSegmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewTranscriptResponse
 */
export interface InterviewTranscriptResponse {
    /**
     * 
     * @type {Array<SpeechSegment>}
     * @memberof InterviewTranscriptResponse
     */
    speechSegments: Array<SpeechSegment>;
    /**
     * 
     * @type {string}
     * @memberof InterviewTranscriptResponse
     */
    readonly videoUrl?: string | null;
}

export function InterviewTranscriptResponseFromJSON(json: any): InterviewTranscriptResponse {
    return InterviewTranscriptResponseFromJSONTyped(json, false);
}

export function InterviewTranscriptResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewTranscriptResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'speechSegments': ((json['speech_segments'] as Array<any>).map(SpeechSegmentFromJSON)),
        'videoUrl': !exists(json, 'video_url') ? undefined : json['video_url'],
    };
}

export function InterviewTranscriptResponseToJSON(value?: InterviewTranscriptResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'speech_segments': ((value.speechSegments as Array<any>).map(SpeechSegmentToJSON)),
    };
}


