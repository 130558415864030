import React from "react";

import AddCandidate from "sections/addcandidate/components/AddCandidate";
import { AddCandidateProvider } from "sections/addcandidate/contexts";

const AddCandidateModal = (): React.ReactElement => (
  <AddCandidateProvider>
    <AddCandidate />
  </AddCandidateProvider>
);

// Items intended to be exported from this package.
// DO NOT import from other files in this package.
export { default as AddCandidateButton } from "./components/AddCandidateButton";
export default AddCandidateModal;
