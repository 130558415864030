import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { FindAtsCandidateResponse } from "services/openapi";

const manualSourcingContext = doverApi.injectEndpoints({
  endpoints: build => ({
    findAtsCandidateManualSourcing: build.query<FindAtsCandidateResponse, string>({
      queryFn: async candidateAtsLink => {
        const { apiApi } = await getOpenApiClients({});
        try {
          return { data: await apiApi.findAtsCandidate({ data: { candidateAtsLink } }) };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useFindAtsCandidateManualSourcingQuery } = manualSourcingContext;
