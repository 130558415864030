import { CandidateCountMapReturn, useCandidateCountMap } from "hooks/useCandidateCountMap";
import { useInterviewPlanStages } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useInterviewPlanStages";

/**
 * This hook gets the count of candidates in each stage of the interview plan.
 * It uses the useCandidateCountMap hook to get the counts.
 */
export const useInterviewPlanStageCounts = (): CandidateCountMapReturn => {
  const { stages } = useInterviewPlanStages();

  // We want a seperate filter for each stage relevant to the interview plan
  const countFilters = stages
    ?.filter(stage => Boolean(stage.id)) // Stages created via optimistic update won't have a stage id until the backend returns
    .map(stage => ({
      name: stage.id,
      filters: { pipelineStages: [{ pipelineStageId: stage.id, pipelineSubstages: [] }] },
    }));

  return useCandidateCountMap(countFilters);
};
