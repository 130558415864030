import { meta } from "@data-driven-forms/common/prop-types-templates";
import PropTypes from "prop-types";
import React from "react";

import { getOptionLabel, getOptionSelected } from "components/data-driven-forms/fields/AsyncMultiSelect/helpers";
import { FormFieldGrid, validationError } from "components/data-driven-forms/fields/helpers";
import AsyncMultiSelect from "components/inputs/AsyncMultiSelect";
import { useOpenApiClients } from "hooks/openApiClients";

const InternalAsyncMultiSelect = ({
  value,
  label,
  helperText,
  validateOnMount,
  meta,
  description,
  placeholder,
  required,
  onChange,
  onFocus,
  onBlur,
  FormFieldGridProps,
  maxOptions,
  textFieldLabel,
  loadOptions,
  ...rest
}) => {
  const clients = useOpenApiClients();
  const client = clients?.apiApi;
  const invalid = validationError(meta, validateOnMount);
  const internalValue = value || [];

  // TODO: Debug using the helper function in place of this; calling the helper function
  // caused an undefined response; trying to wrap in useMemo throws an error
  const fetchFunction = request => {
    switch (loadOptions) {
      case "SIMILAR_COMPANIES":
        return client.listSimilarCompanies({ limit: 50, fullTextSearch: request });
      default:
        return console.log("Error: loadOptions enum not recognized; failed to fetch options");
    }
  };

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <AsyncMultiSelect
        {...rest}
        fetch={fetchFunction}
        label={label}
        value={typeof internalValue === "undefined" ? null : internalValue}
        getOptionSelected={getOptionSelected(loadOptions)}
        getOptionLabel={getOptionLabel(loadOptions)}
        onChange={(_event, option) => {
          onChange(option.map(o => o));
        }}
        maxOptions={maxOptions}
        TextFieldProps={{
          onFocus,
          onBlur,
          required,
          invalid: !!invalid,
          label: textFieldLabel,
          helperText: invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description,
          placeholder: !internalValue || internalValue.length === 0 ? placeholder : undefined,
        }}
      />
    </FormFieldGrid>
  );
};

InternalAsyncMultiSelect.propTypes = {
  meta,
  placeholder: PropTypes.node,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.node.isRequired }))
    .isRequired,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  maxOptions: PropTypes.number,
  textFieldLabel: PropTypes.string,
};

InternalAsyncMultiSelect.defaultProps = {
  placeholder: "Please choose",
  FormFieldGridProps: {},
  inputProps: {},
  isSearchable: true,
  textFieldLabel: "Choose...",
};

export default InternalAsyncMultiSelect;
