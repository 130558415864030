import { Box, FormControl, MenuItem, Select, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isAfter } from "date-fns";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import Banner from "components/Banner";
import { OPEN_SNOOZE_JOB_PARAM } from "components/dover/feature-toggle-handler/constants";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Label, StyledSwitch } from "components/dover/SnoozeJobModal/styles";
import { snoozeOptions } from "components/dover/SnoozeJobModal/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, ButtonText, Heading, Subtitle1 } from "components/library/typography";
import CustomModal from "components/Modal";
import { useBulkUpdateCampaignMutation } from "services/doverapi/endpoints/campaign/endpoints";
import { useSnoozeJobMutation } from "services/doverapi/endpoints/job";
import { useListJobCandidateSourceSettingsQuery } from "services/doverapi/endpoints/jobCandidateSourceSetting";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { JobCandidateSourceSetting, SnoozeJobSnoozedReasonEnum } from "services/openapi";
import { colors, device } from "styles/theme";
import { isValidDate } from "utils/dates";
import { ModalKeyType } from "views/job/types";

export const SnoozeJobModal = ({
  shouldShowModal,
  handleCloseModal,
  handleOpenCompletionModal,
  modalKey,
  jobName,
  jobId,
  snoozeReason,
  setSnoozeReason,
  selectedDate,
  setSelectedDate,
}: {
  shouldShowModal: string;
  handleCloseModal: () => void;
  handleOpenCompletionModal: () => void;
  modalKey: ModalKeyType;
  jobName: string | undefined;
  jobId: string | undefined;
  snoozeReason: string;
  setSnoozeReason: (value: string) => void;
  selectedDate: Date | null;
  setSelectedDate: (value: Date | null) => void;
}): React.ReactElement => {
  // Fetch data
  const { numActiveInboundSources } = useListJobCandidateSourceSettingsQuery(
    jobId ? { job: jobId, direction: "INBOUND", desiredState: "ACTIVE" } : skipToken,
    {
      selectFromResult: ({ data }) => {
        const jobCandidateSourceSettings: JobCandidateSourceSetting[] = listAllEntities(data);
        const activeInboundSourceLabels = jobCandidateSourceSettings
          .map((jcss): string | undefined => jcss.candidateSource?.label)
          .filter((label): boolean => !!label);
        return {
          numActiveInboundSources: activeInboundSourceLabels.length,
        };
      },
    }
  );

  const calendlyUrl = useGetCalendlyUrl("app", "sourcing_autopilot", "pause_modal_calibration_help");

  // URL Info
  const [snoozeModalQuery] = useQueryParam(OPEN_SNOOZE_JOB_PARAM, BooleanParam);

  // Mutations
  const [snoozeJob, { isLoading: isSnoozingJob }] = useSnoozeJobMutation();
  const [bulkUpdateCampaigns, { isLoading: isUpdatingCampaigns }] = useBulkUpdateCampaignMutation();

  // Local state
  const [followUpEmailChecked, setFollowUpEmailChecked] = React.useState<boolean>(false);

  // Derived data
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const disableSnooze =
    snoozeReason === "" ||
    !isValidDate(selectedDate) ||
    (isValidDate(selectedDate) && !isAfter(selectedDate!, new Date()));

  const currentDate = new Date();
  let longDeactivation = 0;
  const deactivationLink = jobId
    ? APP_ROUTE_PATHS.job.jobPosting(jobId, new URLSearchParams({ ["job-posting-tab"]: "job-boards", tab: "setup" }))
    : undefined;

  if (selectedDate && isValidDate(selectedDate)) {
    const diff = selectedDate.getTime() - currentDate.getTime();
    longDeactivation = Math.floor(diff / (1000 * 3600 * 24 * 7));
  }

  // Callbacks
  const handleSnooze = useCallback(() => {
    // If we don't have the data we need, do nothing
    if (!jobId || !selectedDate) {
      return;
    }

    const snoozeJobAndCloseModal = async (): Promise<void> => {
      await snoozeJob({
        jobId: jobId!,
        snoozedReason: snoozeReason as SnoozeJobSnoozedReasonEnum,
        snoozedUntil: selectedDate!,
      }).unwrap();

      if (jobId) {
        await bulkUpdateCampaigns({
          filters: { job_id: jobId },
          newValues: { allow_follow_ups_post_deactivation: followUpEmailChecked },
        }).unwrap();
      }
    };

    handleOpenCompletionModal();

    snoozeJobAndCloseModal();
  }, [
    bulkUpdateCampaigns,
    followUpEmailChecked,
    handleOpenCompletionModal,
    jobId,
    selectedDate,
    snoozeJob,
    snoozeReason,
  ]);

  const handleToggleFollowUpEmailChecked = React.useCallback(() => {
    setFollowUpEmailChecked(!followUpEmailChecked);
  }, [followUpEmailChecked]);

  return (
    <CustomModal
      open={shouldShowModal === modalKey || !!snoozeModalQuery}
      title={<Heading>{`Pause ${jobName ? jobName : "Job"}?`}</Heading>}
      onClose={handleCloseModal}
      maxWidth={"sm"}
      dialogActions={
        <Stack direction="row" display="flex" justifyContent="flex-end" spacing={2}>
          <Button variant={ButtonVariant.Secondary} onClick={(): void => handleCloseModal()}>
            {"Cancel"}
          </Button>
          <Button
            loading={isSnoozingJob || isUpdatingCampaigns}
            variant={ButtonVariant.Primary}
            onClick={handleSnooze}
            disabled={disableSnooze}
          >
            <Stack direction="row" alignItems="center">
              <ButtonText color={disableSnooze ? colors.grayscale.gray400 : colors.white}>{"Pause"}</ButtonText>
            </Stack>
          </Button>
        </Stack>
      }
    >
      <Stack spacing={4} p={4}>
        <Stack spacing={0.5}>
          <Body>
            {`No new candidates will be contacted. Candidates will continue to respond to previously sent outreach.`}
          </Body>
        </Stack>
        <Stack spacing={2}>
          <Subtitle1>{"Why are you pausing your search?"}</Subtitle1>
          <FormControl variant="outlined" size={"small"} fullWidth={true}>
            <Select
              value={snoozeReason}
              onChange={(value): void => {
                setSnoozeReason(value.target.value as string);
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                {"Select"}
              </MenuItem>
              {Object.keys(snoozeOptions).map(snoozeOptionKey => {
                const snoozeOption = snoozeOptions[snoozeOptionKey as SnoozeJobSnoozedReasonEnum];
                return (
                  <MenuItem key={snoozeOptionKey} value={snoozeOptionKey}>
                    {snoozeOption}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        {snoozeReason === SnoozeJobSnoozedReasonEnum.HasCandidatesInProcess && (
          <Banner type="warning" alignItems="flex-start">
            <Box pl={1}>
              {`Candidates sometimes drop out in late stages, and it can significantly delay your search to rebuild your pipeline from scratch in case that happens. Dover recommends continuing sourcing to keep a healthy pipeline until the hire is closed.`}
            </Box>
          </Banner>
        )}
        {snoozeReason === SnoozeJobSnoozedReasonEnum.NeedToRecalibrateSearch && (
          <Banner type="info" alignItems="flex-start">
            <Box pl={1}>
              {`You can view and edit existing search criteria `}
              <Link to={`/job/${jobId}/setup/search-criteria?tab=basic`} rel="noopener noreferrer" target="_blank">
                {"here"}
              </Link>
              {" or "}
              <a href={calendlyUrl} target="_blank" rel="noopener noreferrer">
                {"talk to our team"}
              </a>
              {" to recalibrate."}
            </Box>
          </Banner>
        )}
        {snoozeReason !== "" && (
          <>
            <Stack spacing={2}>
              <Label>Pause Until:</Label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  desktopModeMediaQuery={device.desktopS}
                  minDate={tomorrow}
                  inputFormat="MM/dd/yyyy"
                  value={selectedDate}
                  renderInput={(params): React.ReactElement => <TextField {...params} label="Date" fullWidth />}
                  onChange={(date): void => {
                    if (isValidDate(date)) {
                      setSelectedDate(date as Date);
                    } else {
                      setSelectedDate(null);
                    }
                  }}
                />
              </LocalizationProvider>
            </Stack>
            {longDeactivation > 1 && numActiveInboundSources > 0 && deactivationLink && (
              <Banner type="warning" alignItems="flex-start">
                <Box pl={1}>
                  {`Looks like you'll be pausing this job for a while and currently have ${numActiveInboundSources} active job postings.
                        If you'd like to deactivate your job postings, you can do so `}
                  <a href={deactivationLink}>{"here"}</a>
                  {`.`}
                </Box>
              </Banner>
            )}
            {selectedDate && (
              <Stack direction="row" spacing={1}>
                <StyledSwitch
                  checked={followUpEmailChecked}
                  onChange={handleToggleFollowUpEmailChecked}
                  $checked={followUpEmailChecked}
                />
                <BodySmall>{"Send Followup Emails to Existing Candidates"}</BodySmall>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </CustomModal>
  );
};
