/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IIRFirstRoundFeedback
 */
export interface IIRFirstRoundFeedback {
    /**
     * 
     * @type {boolean}
     * @memberof IIRFirstRoundFeedback
     */
    advanceToNextRound?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof IIRFirstRoundFeedback
     */
    readonly modified?: Date;
}

export function IIRFirstRoundFeedbackFromJSON(json: any): IIRFirstRoundFeedback {
    return IIRFirstRoundFeedbackFromJSONTyped(json, false);
}

export function IIRFirstRoundFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): IIRFirstRoundFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'advanceToNextRound': !exists(json, 'advance_to_next_round') ? undefined : json['advance_to_next_round'],
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
    };
}

export function IIRFirstRoundFeedbackToJSON(value?: IIRFirstRoundFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'advance_to_next_round': value.advanceToNextRound,
    };
}


