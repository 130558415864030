import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useGetGmailAuthStatus, useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import { useCandidateId } from "hooks/useCandidateId";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import {
  useDoverManagedSchedulingConfig,
  useReplyToSchedulingConfig,
  useUserManagedSchedulingConfig,
} from "services/doverapi/cross-endpoint-hooks/useGetSchedulingConfig";
import { useGetCandidateBioQuery, useListCandidateEmailEventsQuery } from "services/doverapi/endpoints/candidate";
import {
  ActivityFeedEmailParticipantSerializer,
  ActivityFeedItem,
  ActorField,
  ActorFieldActorEnum,
  CandidateBioSourceDirectionEnum,
} from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { isInterestedRespondedStage } from "utils/isStage";
import {
  getUserFromEmailParticipants,
  getShellEmailParticipantFromEmailParticipants,
} from "views/CandidateDetail/components/event-feed/helpers";

export function useShowReplyToOption({
  eventId,
  actor,
  isInboundApplicationEvent: isInboundApplication,
}: {
  eventId?: string;
  actor: ActorField;
  isInboundApplicationEvent: boolean;
}): boolean {
  const candidateId = useCandidateId();
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);

  const useReplyToScheduling = useReplyToSchedulingConfig(candidateBio?.job);

  const { data: emailEvents, isLoading: emailEventsLoading } = useListCandidateEmailEventsQuery(
    candidateId ? candidateId : skipToken
  );

  const isMostRecentReply = useMemo((): boolean => {
    // Email events are sorted by date descending, so the first event is the most recent
    const mostRecentReply = emailEvents?.find(emailEvent => emailEvent.actor.actor === ActorFieldActorEnum.Candidate);
    return !!eventId && mostRecentReply?.eventId === eventId && !emailEventsLoading;
  }, [emailEvents, emailEventsLoading, eventId]);

  const isMostRecentCandidateReply = isMostRecentReply && actor.actor === ActorFieldActorEnum.Candidate;

  return (
    useReplyToScheduling &&
    !!candidateBio?.candidatePipelineStage &&
    isInterestedRespondedStage(candidateBio?.candidatePipelineStage) &&
    (isMostRecentCandidateReply ||
      (candidateBio.searchSource?.direction === CandidateBioSourceDirectionEnum.Inbound && isInboundApplication))
  );
}

export function useShowReplyInThreadOption({
  eventId,
}: {
  eventId?: string;
}): {
  showReplyInThreadButton: boolean;
  emailSender: ActivityFeedEmailParticipantSerializer | undefined;
  showAuthButton: boolean;
} {
  const jobId = useJobIdFromUrl();
  const useReplyToSchedulingOverride = useReplyToSchedulingConfig(jobId);
  const hasUserManagedScheduling = useUserManagedSchedulingConfig(jobId);
  const hasDoverManagedScheduling = useDoverManagedSchedulingConfig(jobId);
  const useReplyToScheduling = useReplyToSchedulingConfig(jobId);

  const candidateId = useCandidateId();
  const { user } = useAuth0();
  const { gmailAuthStatus } = useGetGmailAuthStatus({});
  const { userAuthedSuccessfully } = useUserAuthedSuccessfully();

  const { data: emailEvents, isLoading: emailEventsLoading } = useListCandidateEmailEventsQuery(
    candidateId ? candidateId : skipToken
  );

  const sortedEmailEvents = useMemo(
    () => [...(emailEvents || [])].sort((a, b) => (a.timestamp >= b.timestamp ? -1 : 1)),
    [emailEvents]
  );
  const mostRecentEmail: ActivityFeedItem | undefined = sortedEmailEvents[0];
  const emailParticipants = mostRecentEmail?.emailEventDetails?.emailParticipants;

  const shellEmailParticipant = useMemo(() => getShellEmailParticipantFromEmailParticipants(emailParticipants), [
    emailParticipants,
  ]);
  const userEmailParticipant = useMemo(() => getUserFromEmailParticipants(emailParticipants, user.email), [
    emailParticipants,
    user.email,
  ]);

  // Determine appropriate Email Sender for Replying to most recent email given its participants
  const emailSender = useMemo(() => {
    // 1. If User-Managed Scheduling, allow responding if either a Shell Email or the
    // current user is included in the email participants. If so, always respond from user's
    // email
    if (hasUserManagedScheduling) {
      if (!!shellEmailParticipant || !!userEmailParticipant) {
        // return userEmailParticipant if it exists
        if (userEmailParticipant) {
          return userEmailParticipant;
        }
        // else return email alias id from gmail auth status if it exists
        if (gmailAuthStatus?.emailAliasId && userAuthedSuccessfully) {
          return {
            emailSenderAlias: {
              id: gmailAuthStatus?.emailAliasId,
              email: user.email,
              isShellAccount: false,
              user: user.id,
            },
          };
        }
        return undefined;
      }
    }
    // 2. If Dover-Managed Scheduling, allow responding to an email in two scenarios:
    // a. If a Shell Email is present in thread, respond from the shell email
    // b. Else if a Shell Email is NOT present, allow responding from the user's email if the
    // user is a participant in the thread
    if (hasDoverManagedScheduling) {
      return shellEmailParticipant || userEmailParticipant;
    }
    return undefined;
  }, [
    hasUserManagedScheduling,
    hasDoverManagedScheduling,
    shellEmailParticipant,
    userEmailParticipant,
    gmailAuthStatus?.emailAliasId,
    userAuthedSuccessfully,
    user.email,
    user.id,
  ]);

  const showGmailAuthButton = useMemo((): boolean => {
    return (
      hasUserManagedScheduling &&
      !userAuthedSuccessfully &&
      !emailSender &&
      !useReplyToScheduling &&
      (!!shellEmailParticipant || !!userEmailParticipant)
    );
  }, [
    hasUserManagedScheduling,
    userAuthedSuccessfully,
    emailSender,
    shellEmailParticipant,
    userEmailParticipant,
    useReplyToScheduling,
  ]);

  const showReplyInThreadButton = useMemo((): boolean => {
    return (
      !useReplyToSchedulingOverride &&
      !!eventId &&
      !!sortedEmailEvents?.length &&
      mostRecentEmail?.eventId === eventId &&
      !emailEventsLoading &&
      (!!emailSender || showGmailAuthButton)
    );
  }, [
    useReplyToSchedulingOverride,
    eventId,
    sortedEmailEvents?.length,
    mostRecentEmail?.eventId,
    emailEventsLoading,
    emailSender,
    showGmailAuthButton,
  ]);

  return {
    showReplyInThreadButton: showReplyInThreadButton,
    emailSender: emailSender,
    showAuthButton: showGmailAuthButton,
  };
}
