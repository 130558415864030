/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateResponseTemplate,
    CandidateResponseTemplateFromJSON,
    CandidateResponseTemplateFromJSONTyped,
    CandidateResponseTemplateToJSON,
    FrontClient,
    FrontClientFromJSON,
    FrontClientFromJSONTyped,
    FrontClientToJSON,
    FrontDoverEntity,
    FrontDoverEntityFromJSON,
    FrontDoverEntityFromJSONTyped,
    FrontDoverEntityToJSON,
    FrontJobFAQ,
    FrontJobFAQFromJSON,
    FrontJobFAQFromJSONTyped,
    FrontJobFAQToJSON,
    InboundApplicationUrls,
    InboundApplicationUrlsFromJSON,
    InboundApplicationUrlsFromJSONTyped,
    InboundApplicationUrlsToJSON,
    JobCompensation,
    JobCompensationFromJSON,
    JobCompensationFromJSONTyped,
    JobCompensationToJSON,
    JobLocation,
    JobLocationFromJSON,
    JobLocationFromJSONTyped,
    JobLocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface FrontJob
 */
export interface FrontJob {
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    readonly id?: string;
    /**
     * 
     * @type {FrontClient}
     * @memberof FrontJob
     */
    client: FrontClient;
    /**
     * 
     * @type {boolean}
     * @memberof FrontJob
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    readonly nameWithClient?: string;
    /**
     * 
     * @type {Date}
     * @memberof FrontJob
     */
    deactivatedAt?: Date | null;
    /**
     * Reason that should be filled out by EM
     * @type {string}
     * @memberof FrontJob
     */
    deactivationReason?: FrontJobDeactivationReasonEnum;
    /**
     * Reason that was filled out by the customer
     * @type {string}
     * @memberof FrontJob
     */
    deactivationReasonUserSupplied?: FrontJobDeactivationReasonUserSuppliedEnum;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    deactivationRemainingCandidateCommunication?: FrontJobDeactivationRemainingCandidateCommunicationEnum;
    /**
     * 
     * @type {FrontDoverEntity}
     * @memberof FrontJob
     */
    csm: FrontDoverEntity | null;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    doverReviewNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    doverReviewType?: FrontJobDoverReviewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    clientReviewType?: FrontJobClientReviewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    newCandidateNotification?: FrontJobNewCandidateNotificationEnum;
    /**
     * 
     * @type {Array<FrontJobFAQ>}
     * @memberof FrontJob
     */
    faqs?: Array<FrontJobFAQ>;
    /**
     * 
     * @type {Array<CandidateResponseTemplate>}
     * @memberof FrontJob
     */
    responseTemplates?: Array<CandidateResponseTemplate>;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    schedulingBody?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    schedulingCcEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    readonly emailSenderEmail?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontJob
     */
    readonly clientShellEmails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    pointOfContactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    shouldEngagePassive?: FrontJobShouldEngagePassiveEnum;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    schedulingInterviewerTimezone?: string | null;
    /**
     * 
     * @type {InboundApplicationUrls}
     * @memberof FrontJob
     */
    inboundApplicationUrls?: InboundApplicationUrls;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    hiringManagerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontJob
     */
    recruiterEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FrontJob
     */
    readonly hasEndToEndEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontJob
     */
    useReplyToSchedulingOverride?: boolean | null;
    /**
     * 
     * @type {Array<JobLocation>}
     * @memberof FrontJob
     */
    locations?: Array<JobLocation>;
    /**
     * 
     * @type {JobCompensation}
     * @memberof FrontJob
     */
    compensation?: JobCompensation;
    /**
     * 
     * @type {boolean}
     * @memberof FrontJob
     */
    visaSupport?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum FrontJobDeactivationReasonEnum {
    Hired = 'Hired',
    HiredThroughDover = 'Hired Through Dover',
    HiredOutsideDover = 'Hired Outside Dover',
    ContractEnded = 'Contract Ended',
    ContractEndedAndStillHiring = 'Contract Ended and still hiring',
    ContractEndedAndNoLongerHiring = 'Contract Ended and no longer hiring',
    DePrioritizedJob = 'De-prioritized Job',
    DePrioritizedJobNoLongerHiring = 'De-prioritized Job - No Longer Hiring',
    DePrioritizedJobStillHiring = 'De-prioritized Job - Still Hiring',
    DuplicateJob = 'Duplicate Job',
    Other = 'Other'
}/**
* @export
* @enum {string}
*/
export enum FrontJobDeactivationReasonUserSuppliedEnum {
    Hired = 'Hired',
    HiredThroughDover = 'Hired Through Dover',
    HiredOutsideDover = 'Hired Outside Dover',
    ContractEnded = 'Contract Ended',
    ContractEndedAndStillHiring = 'Contract Ended and still hiring',
    ContractEndedAndNoLongerHiring = 'Contract Ended and no longer hiring',
    DePrioritizedJob = 'De-prioritized Job',
    DePrioritizedJobNoLongerHiring = 'De-prioritized Job - No Longer Hiring',
    DePrioritizedJobStillHiring = 'De-prioritized Job - Still Hiring',
    DuplicateJob = 'Duplicate Job',
    Other = 'Other'
}/**
* @export
* @enum {string}
*/
export enum FrontJobDeactivationRemainingCandidateCommunicationEnum {
    CustomerHandles = 'CUSTOMER_HANDLES',
    DoverHandles = 'DOVER_HANDLES'
}/**
* @export
* @enum {string}
*/
export enum FrontJobDoverReviewTypeEnum {
    RcReview = 'RC_REVIEW',
    PmReview = 'PM_REVIEW'
}/**
* @export
* @enum {string}
*/
export enum FrontJobClientReviewTypeEnum {
    Emailing = 'BEFORE_EMAILING',
    Scheduling = 'BEFORE_SCHEDULING'
}/**
* @export
* @enum {string}
*/
export enum FrontJobNewCandidateNotificationEnum {
    Slack = 'SLACK',
    Email = 'EMAIL',
    SlackAndEmail = 'SLACK_AND_EMAIL'
}/**
* @export
* @enum {string}
*/
export enum FrontJobShouldEngagePassiveEnum {
    All = 'All',
    InterestedInRole = 'Interested In Role',
    ActiveOnly = 'Active Only'
}

export function FrontJobFromJSON(json: any): FrontJob {
    return FrontJobFromJSONTyped(json, false);
}

export function FrontJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': FrontClientFromJSON(json['client']),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'nameWithClient': !exists(json, 'name_with_client') ? undefined : json['name_with_client'],
        'deactivatedAt': !exists(json, 'deactivated_at') ? undefined : (json['deactivated_at'] === null ? null : new Date(json['deactivated_at'])),
        'deactivationReason': !exists(json, 'deactivation_reason') ? undefined : json['deactivation_reason'],
        'deactivationReasonUserSupplied': !exists(json, 'deactivation_reason_user_supplied') ? undefined : json['deactivation_reason_user_supplied'],
        'deactivationRemainingCandidateCommunication': !exists(json, 'deactivation_remaining_candidate_communication') ? undefined : json['deactivation_remaining_candidate_communication'],
        'csm': FrontDoverEntityFromJSON(json['csm']),
        'doverReviewNote': !exists(json, 'dover_review_note') ? undefined : json['dover_review_note'],
        'doverReviewType': !exists(json, 'dover_review_type') ? undefined : json['dover_review_type'],
        'clientReviewType': !exists(json, 'client_review_type') ? undefined : json['client_review_type'],
        'newCandidateNotification': !exists(json, 'new_candidate_notification') ? undefined : json['new_candidate_notification'],
        'faqs': !exists(json, 'faqs') ? undefined : ((json['faqs'] as Array<any>).map(FrontJobFAQFromJSON)),
        'responseTemplates': !exists(json, 'response_templates') ? undefined : ((json['response_templates'] as Array<any>).map(CandidateResponseTemplateFromJSON)),
        'schedulingBody': !exists(json, 'scheduling_body') ? undefined : json['scheduling_body'],
        'schedulingCcEmail': !exists(json, 'scheduling_cc_email') ? undefined : json['scheduling_cc_email'],
        'emailSenderEmail': !exists(json, 'email_sender_email') ? undefined : json['email_sender_email'],
        'clientShellEmails': !exists(json, 'client_shell_emails') ? undefined : json['client_shell_emails'],
        'pointOfContactEmail': !exists(json, 'point_of_contact_email') ? undefined : json['point_of_contact_email'],
        'shouldEngagePassive': !exists(json, 'should_engage_passive') ? undefined : json['should_engage_passive'],
        'schedulingInterviewerTimezone': !exists(json, 'scheduling_interviewer_timezone') ? undefined : json['scheduling_interviewer_timezone'],
        'inboundApplicationUrls': !exists(json, 'inbound_application_urls') ? undefined : InboundApplicationUrlsFromJSON(json['inbound_application_urls']),
        'hiringManagerEmail': !exists(json, 'hiring_manager_email') ? undefined : json['hiring_manager_email'],
        'recruiterEmail': !exists(json, 'recruiter_email') ? undefined : json['recruiter_email'],
        'hasEndToEndEnabled': !exists(json, 'has_end_to_end_enabled') ? undefined : json['has_end_to_end_enabled'],
        'useReplyToSchedulingOverride': !exists(json, 'use_reply_to_scheduling_override') ? undefined : json['use_reply_to_scheduling_override'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(JobLocationFromJSON)),
        'compensation': !exists(json, 'compensation') ? undefined : JobCompensationFromJSON(json['compensation']),
        'visaSupport': !exists(json, 'visa_support') ? undefined : json['visa_support'],
    };
}

export function FrontJobToJSON(value?: FrontJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': FrontClientToJSON(value.client),
        'active': value.active,
        'deactivated_at': value.deactivatedAt === undefined ? undefined : (value.deactivatedAt === null ? null : value.deactivatedAt.toISOString()),
        'deactivation_reason': value.deactivationReason,
        'deactivation_reason_user_supplied': value.deactivationReasonUserSupplied,
        'deactivation_remaining_candidate_communication': value.deactivationRemainingCandidateCommunication,
        'csm': FrontDoverEntityToJSON(value.csm),
        'dover_review_note': value.doverReviewNote,
        'dover_review_type': value.doverReviewType,
        'client_review_type': value.clientReviewType,
        'new_candidate_notification': value.newCandidateNotification,
        'faqs': value.faqs === undefined ? undefined : ((value.faqs as Array<any>).map(FrontJobFAQToJSON)),
        'response_templates': value.responseTemplates === undefined ? undefined : ((value.responseTemplates as Array<any>).map(CandidateResponseTemplateToJSON)),
        'scheduling_body': value.schedulingBody,
        'scheduling_cc_email': value.schedulingCcEmail,
        'point_of_contact_email': value.pointOfContactEmail,
        'should_engage_passive': value.shouldEngagePassive,
        'scheduling_interviewer_timezone': value.schedulingInterviewerTimezone,
        'inbound_application_urls': InboundApplicationUrlsToJSON(value.inboundApplicationUrls),
        'hiring_manager_email': value.hiringManagerEmail,
        'recruiter_email': value.recruiterEmail,
        'use_reply_to_scheduling_override': value.useReplyToSchedulingOverride,
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(JobLocationToJSON)),
        'compensation': JobCompensationToJSON(value.compensation),
        'visa_support': value.visaSupport,
    };
}


