import { useMemo } from "react";

import { NO_REPLY_EMAIL } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/utils";
import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { isMultipartInterview } from "components/dover/top-level-modal-manager/utils/getModalWidth";
import {
  interviewPanelValid as validateInterviewPanel,
  isInterviewerEmpty,
} from "components/dover/top-level-modal-manager/utils/isInterviewerEmpty";
import { VALID_VARIABLES } from "components/library/TipTap/extensions/variableHighlighter/variableHighlighter";
import { BasicEmailOption, EmailUser } from "components/library/TipTap/types";
import { NextInterviewerHiringPipelineStage } from "components/NextInterviewer/usePossibleInterviewers";
import {
  ArchiveReason,
  CandidateBioCompletedInterview,
  CandidateBioSchedulingOwnershipEnum,
  InterviewPanel,
  InterviewPlanInterviewSubstage,
} from "services/openapi";
import { EMPTY_TIPTAP_EDITOR } from "utils/tiptap";

/*
  This file contains the logic for all validations across all flavors of the supercomponent.

  There is a single entry point called useValidate which takes in which type of validation
  you are doing (loosely each type is tied to a "flavor" of supercomponent, but some flavors
  have multiple types like reject with email and reject without email).

  This useValidate hook will figure out which validators are relevent to the validation type passed
  in and then run through each validator.  As it does this is will build up what side effects should take
  place in the super component based on which validators fail.

  Possible side effects are disabling various parts of the super component, showing a warning banner, and
  adding a tooltip to the Send button.

  To add a new validation simply create a new validator object and add it to the validators array below. You shouldn't need to touch the supercomponent
  code at all.

  Each Validator object takes a few different parameters in:
  - type: This is how you choose when to run this validator. This is an array of all the different validation types the validator
    should run for.  Your options are defined in the ValidationType type.
  - validate: This is the function that contains the actual validation logic and returns a boolean.  If the boolean
    is true then the validator passes, if it is false then the validator fails and the specified side effects will take place.
  - disable: This is an array of strings that represent the parts of the super component that should be disabled if
    the validator fails. Your options are defined in the DisabledTarget type.
  - tooltip: This is an optional string that will be added to the Send button if the validator fails.
  - warning: This is an optional warning object that will display a warning banner if the validator fails.
*/

// Our email variables look like {{scheduling_link}}
const unsubstitutedVariableRegex = /{{(.*?)}}/g;

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Types
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/

type DisabledTarget = "Send" | "Body" | "From" | "To" | "CC" | "BCC" | "Subject";

export interface Warning {
  title: string;
  body: string;
  useErrorColor?: boolean;
}

type ValidationInput = {
  Schedule: {
    fetchingTemplateAndBioData: boolean;
    subject: string;
    body: string;
    to: BasicEmailOption;
    from: EmailUser;
    desiredHiringPipelineStage: NextInterviewerHiringPipelineStage | undefined;
    substage: InterviewPlanInterviewSubstage | undefined;
    schedulingOwnership: CandidateBioSchedulingOwnershipEnum | undefined;
    interviewer: Interviewer;
    interviewPanel: InterviewPanel | undefined;
    isTakeHome: boolean;
    proceedWithoutLink: boolean;
    isValidatingLink: boolean;
    invalidLink: boolean;
    completedInterviews: CandidateBioCompletedInterview[] | undefined;
  };
  Reschedule: {
    fetchingTemplateAndBioData: boolean;
    subject: string;
    body: string;
    to: BasicEmailOption;
    from: EmailUser;
    desiredHiringPipelineStage: NextInterviewerHiringPipelineStage | undefined;
    substage: InterviewPlanInterviewSubstage | undefined;
    schedulingOwnership: CandidateBioSchedulingOwnershipEnum | undefined;
    interviewer: Interviewer;
    interviewPanel: InterviewPanel | undefined;
    isTakeHome: boolean;
    proceedWithoutLink: boolean;
    isValidatingLink: boolean;
    invalidLink: boolean;
    completedInterviews: CandidateBioCompletedInterview[] | undefined;
  };
  RejectWithEmail: {
    fetchingTemplateAndBioData: boolean;
    subject: string;
    body: string;
    to: BasicEmailOption;
    from: EmailUser;
    isValidatingLink: boolean;
    invalidLink: boolean;
    rejectionReason: ArchiveReason | null;
  };
  RejectWithoutEmail: {
    rejectionReason: ArchiveReason | null;
  };
  BulkRejectWithEmail: {
    fetchingTemplateAndBioData: boolean;
    subject: string;
    body: string;
    from: EmailUser;
    rejectionReason: ArchiveReason | null;
  };
  BulkRejectWithoutEmail: {
    rejectionReason: ArchiveReason | null;
  };
  EmailOnly: {
    fetchingTemplateAndBioData: boolean;
    subject: string;
    body: string;
    to: BasicEmailOption;
    from: EmailUser;
    isValidatingLink: boolean;
    invalidLink: boolean;
  };
  TemplateOnly: {
    subject: string;
    body: string;
  };
};

export type ValidationType = keyof ValidationInput;

type DisabledMap = Record<DisabledTarget, boolean>;

interface ValidationResults {
  warning?: Warning;
  tooltip?: string;
  disabledMap: DisabledMap;
  hideEditor: boolean;
}

type Validate<T extends ValidationType> = (args: ValidationInput[T]) => boolean;

interface Validator<T extends ValidationType> {
  type: T[];
  validate: Validate<T>;
  disable: DisabledTarget[];
  tooltip?: string;
  warning?: Warning;
  hideEditor?: boolean;
}

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Helper Functions
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/

const verifySenderFirstNameInBulkReject: Validate<"BulkRejectWithEmail"> = ({ from, body }) => {
  if (from.email === NO_REPLY_EMAIL) {
    const hasUnsubstituted = body.includes("{{sender_first_name}}");
    return !hasUnsubstituted;
  } else {
    return true;
  }
};

const verifyNoUnsubstitutedVariables: Validate<
  "Schedule" | "Reschedule" | "RejectWithEmail" | "EmailOnly" | "TemplateOnly"
> = ({ subject, body }) => {
  const subjectHasUnsubstitutedVariables = unsubstitutedVariableRegex.test(subject);
  const bodyHasUnsubstitutedVariables = unsubstitutedVariableRegex.test(body);

  return !subjectHasUnsubstitutedVariables && !bodyHasUnsubstitutedVariables;
};

const verifyNoInvalidVariables: Validate<"TemplateOnly"> = ({ subject, body }) => {
  const subjectMatches = subject.matchAll(unsubstitutedVariableRegex) || [];
  const bodyMatches = body.matchAll(unsubstitutedVariableRegex) || [];

  for (const match of Array.from(subjectMatches)) {
    if (!VALID_VARIABLES.has(match[1])) {
      return false;
    }
  }

  for (const match of Array.from(bodyMatches)) {
    if (!VALID_VARIABLES.has(match[1])) {
      return false;
    }
  }

  return true;
};

interface DoesStageRequireInterviewerArgs {
  desiredHiringPipelineStage: NextInterviewerHiringPipelineStage | undefined;
  schedulingOwnership: CandidateBioSchedulingOwnershipEnum | undefined;
  isTakeHome: boolean;
}

export const doesStageRequireInterviewer = ({
  desiredHiringPipelineStage,
  schedulingOwnership,
  isTakeHome,
}: DoesStageRequireInterviewerArgs): boolean => {
  if (desiredHiringPipelineStage === undefined) {
    return true;
  }

  const isLateStage = isMultipartInterview(desiredHiringPipelineStage);

  // never need an interviewer for multipart stages for customer handles scheduling
  // because we don't schedule for them
  if (isLateStage && schedulingOwnership === CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling) {
    return false;
  }

  // for single part interviews, we always need interviewer unless it's a take home
  if (!isLateStage && isTakeHome) {
    return false;
  }

  // otherwise we need interviewer
  return true;
};

const validateInterviewerPreferences = ({
  interviewer,
  proceedWithoutLink,
}: {
  interviewer: Interviewer;
  proceedWithoutLink: boolean;
}): boolean => interviewer.preferencesComplete || proceedWithoutLink;

const validateInterviewer: Validate<"Schedule" | "Reschedule"> = ({
  desiredHiringPipelineStage,
  schedulingOwnership,
  isTakeHome,
  interviewer,
  interviewPanel,
  proceedWithoutLink,
}) => {
  if (interviewer === undefined || proceedWithoutLink === undefined || isTakeHome === undefined) {
    return false;
  }

  const stageRequiresInterviewer = doesStageRequireInterviewer({
    schedulingOwnership,
    desiredHiringPipelineStage,
    isTakeHome,
  });

  if (!stageRequiresInterviewer) {
    return true;
  }

  // Can't determine interview validations without a stage
  if (desiredHiringPipelineStage === undefined) {
    return false;
  }

  const isLateStage = isMultipartInterview(desiredHiringPipelineStage);

  // We don't check preferences for late stage interviews as those are conducted by an interview panel
  const isPreferencesValid = isLateStage || validateInterviewerPreferences({ interviewer, proceedWithoutLink });

  const isInterviewerValid = isLateStage ? validateInterviewPanel(interviewPanel) : !isInterviewerEmpty(interviewer);

  return isPreferencesValid && isInterviewerValid;
};

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Validators
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
// Disable everthing while we are still loading key data for email sending
const fetchingTemplateAndBioValidator: Validator<
  "Schedule" | "Reschedule" | "RejectWithEmail" | "BulkRejectWithEmail"
> = {
  type: ["Schedule", "Reschedule", "RejectWithEmail", "BulkRejectWithEmail"],
  validate: ({ fetchingTemplateAndBioData }) => !fetchingTemplateAndBioData,
  disable: ["Send", "From", "To", "Body", "CC", "BCC", "Subject"],
};

// To email needs to be defined to send an email
const toEmailValidator: Validator<"Schedule" | "Reschedule" | "RejectWithEmail" | "EmailOnly"> = {
  type: ["Schedule", "Reschedule", "RejectWithEmail", "EmailOnly"],
  validate: ({ to }) => !!to?.email,
  disable: ["Send", "From", "Body", "CC", "BCC", "Subject"],
  warning: {
    title: "No email associated with this candidate",
    body: "Add an email address in order to send emails.",
  },
};

// Both subject and body should not be empty to send an email
const emptySubjectOrBodyValidator: Validator<
  "Schedule" | "Reschedule" | "RejectWithEmail" | "BulkRejectWithEmail" | "EmailOnly" | "TemplateOnly"
> = {
  type: ["Schedule", "Reschedule", "RejectWithEmail", "BulkRejectWithEmail", "EmailOnly", "TemplateOnly"],
  validate: ({ subject, body }) =>
    !!subject && !!body && subject !== EMPTY_TIPTAP_EDITOR && body !== EMPTY_TIPTAP_EDITOR,
  disable: ["Send"],
  tooltip: "Subject and Body required is required for emails",
};

// Check for bulk reject unsupported variables in the editor
const bulkRejectVariableValidator: Validator<"BulkRejectWithEmail"> = {
  type: ["BulkRejectWithEmail"],
  validate: verifySenderFirstNameInBulkReject,
  disable: ["Send"],
  warning: {
    title: "Unsupported variable present in email",
    body: "{{sender_first_name}} not supported when using no-reply sender",
  },
};

// Check for unsubstitued variables in the editor
const unsubstitutedVariablesValidator: Validator<"Schedule" | "Reschedule" | "RejectWithEmail" | "EmailOnly"> = {
  type: ["Schedule", "Reschedule", "RejectWithEmail", "EmailOnly"],
  validate: verifyNoUnsubstitutedVariables,
  disable: ["Send"],
  warning: {
    title: "Variable(s) present in email",
    body: "To send email, please replace or remove the highlighted variable(s) above",
  },
};
const invalidVariablesValidator: Validator<"TemplateOnly"> = {
  type: ["TemplateOnly"],
  validate: verifyNoInvalidVariables,
  disable: ["Send"],
  warning: {
    title: "Unsupported variables found",
    body: "Please remove variables highlighted in red to save this template.",
  },
};

// Make sure an interviewer is selected
const noInterviewerSelected: Validator<"Schedule" | "Reschedule"> = {
  type: ["Schedule", "Reschedule"],
  validate: ({ desiredHiringPipelineStage, substage, interviewer }) => {
    // Take home and mulipart should pass autamtically, for single part check if the interviewer is empty
    return isMultipartInterview(desiredHiringPipelineStage) || substage?.isTakeHome || !isInterviewerEmpty(interviewer);
  },
  disable: ["Send", "From", "To", "Body", "CC", "BCC", "Subject"],
  warning: {
    title: "No interviewer selected",
    body: "Select an interviewer to send a scheduling email.",
  },
  hideEditor: true,
};

// Make sure a duration is selected
const noDurationSelected: Validator<"Schedule" | "Reschedule"> = {
  type: ["Schedule", "Reschedule"],
  validate: ({ desiredHiringPipelineStage, interviewer, interviewPanel }) =>
    desiredHiringPipelineStage?.id === "" ? !isInterviewerEmpty(interviewer) && !!interviewPanel : true,
  disable: ["Send", "From", "To", "Body", "CC", "BCC", "Subject"],
  warning: {
    title: "No duration selected",
    body: "Select an duration to send a scheduling email.",
  },
  hideEditor: true,
};

// Make sure a stage is selected
const stageSelectedValidator: Validator<"Schedule" | "Reschedule"> = {
  type: ["Schedule", "Reschedule"],
  validate: ({ desiredHiringPipelineStage }) => desiredHiringPipelineStage !== undefined,
  disable: ["Send", "From", "To", "Body", "CC", "BCC", "Subject"],
  warning: {
    title: "No stage selected",
    body: "Select stage to send a scheduling email.",
  },
  hideEditor: true,
};

// Check that an interviewer is selected and has interview preferences complete
// Or for late stage interviewers the the interview panel is valid
const interviewerValidator: Validator<"Schedule" | "Reschedule"> = {
  type: ["Schedule", "Reschedule"],
  validate: validateInterviewer,
  disable: ["Send", "From", "To", "Body", "CC", "BCC", "Subject"],
  warning: {
    title: "Interviewer must be selected and have preferences complete",
    body: "Select a valid interviewer to send a scheduling email.",
  },
};

// Make sure the email sender is authed
const fromIsAuthedValidator: Validator<
  "Schedule" | "Reschedule" | "RejectWithEmail" | "BulkRejectWithEmail" | "EmailOnly"
> = {
  type: ["Schedule", "Reschedule", "RejectWithEmail", "BulkRejectWithEmail", "EmailOnly"],
  validate: ({ from }) => from.isAuthed,
  disable: ["Send", "To", "Body", "CC", "BCC", "Subject"],
  tooltip: "Please authenticate the sender's email account",
};

// Check that the findatime links in the body are valid
const findATimeLinkValidator: Validator<"Schedule" | "Reschedule" | "RejectWithEmail" | "EmailOnly"> = {
  type: ["Schedule", "Reschedule", "RejectWithEmail", "EmailOnly"],
  validate: ({ invalidLink }) => !invalidLink,
  disable: ["Send"],
  warning: {
    title: "Invalid scheduling link",
    body: "Dover scheduling link included is tied to different candidate. Refresh to regenerate correct link.",
  },
};

// Check that we aren't currently waiting for the findatime validation to complete
// This depends both on a debounce logic and an api call so we don't want to send while either of those are running
const findATimeLinkValidatingValidator: Validator<"Schedule" | "Reschedule" | "RejectWithEmail" | "EmailOnly"> = {
  type: ["Schedule", "Reschedule", "RejectWithEmail", "EmailOnly"],
  validate: ({ isValidatingLink }) => !isValidatingLink,
  disable: ["Send"],
  tooltip: "Please wait while we validate your email content",
};

// Check that a reject reason is filled out
const rejectReasonValidator: Validator<
  "RejectWithoutEmail" | "RejectWithEmail" | "BulkRejectWithEmail" | "BulkRejectWithoutEmail"
> = {
  type: ["RejectWithoutEmail", "RejectWithEmail", "BulkRejectWithEmail", "BulkRejectWithoutEmail"],
  validate: ({ rejectionReason }) => !!rejectionReason,
  disable: ["Send", "From", "To", "Body", "CC", "BCC", "Subject"],
  tooltip: "Please select a reason for rejection",
};

// Very difficult to avoid this any
// See the comment in the useValidate hook for more details
const validators: Validator<any>[] = [
  fetchingTemplateAndBioValidator,
  toEmailValidator,
  emptySubjectOrBodyValidator,
  stageSelectedValidator,
  noInterviewerSelected,
  noDurationSelected,
  interviewerValidator,
  fromIsAuthedValidator,
  unsubstitutedVariablesValidator,
  invalidVariablesValidator,
  findATimeLinkValidator,
  findATimeLinkValidatingValidator,
  rejectReasonValidator,
  bulkRejectVariableValidator,
];

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Validate Hook
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
export const useValidate = <T extends ValidationType>(type: T, input: ValidationInput[T]): ValidationResults => {
  let warning: Warning | undefined;
  let tooltip = "";
  let hideEditor = false;

  const disabledMap: DisabledMap = {
    Send: false,
    Body: false,
    From: false,
    To: false,
    CC: false,
    BCC: false,
    Subject: false,
  };

  // It's incredibly difficult to type the validators array in a correct, generic way
  // That gives full end-to-end type correctness because
  // Typescript is not currently able to type narrow generics inside a function body
  //
  // So this forced conversion is the compromise I have chosen which still allows for
  // - Proper typing of the inputs for consumers of this hook and
  // - Proper typing when writing the validator objects
  const validatorsForType = (useMemo(() => validators.filter(v => v.type.includes(type)), [type]) as unknown) as Array<
    Validator<T>
  >;

  validatorsForType.forEach(v => {
    if (!v.validate(input)) {
      // Add warning if it exists
      // Prioritize earlier validators (don't overwrite if the warning is already set)
      if (!warning && v.warning) {
        warning = v.warning;
      }

      // Add tooltip if it exists
      // Prioritize earlier validators (don't overwrite if the tooltip is already set)
      if (!tooltip && v.tooltip) {
        tooltip = v.tooltip;
      }

      if (v.hideEditor) {
        hideEditor = true;
      }

      // Set all disable targets to true
      v.disable.forEach(d => {
        disabledMap[d] = true;
      });
    }
  });

  return { warning, tooltip, disabledMap, hideEditor };
};
