/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobAtsSourceSettingUpsertMultipleRequest
 */
export interface JobAtsSourceSettingUpsertMultipleRequest {
    /**
     * 
     * @type {string}
     * @memberof JobAtsSourceSettingUpsertMultipleRequest
     */
    jobCandidateSourceSettingId: string;
    /**
     * 
     * @type {string}
     * @memberof JobAtsSourceSettingUpsertMultipleRequest
     */
    atsSourceName: string;
    /**
     * 
     * @type {string}
     * @memberof JobAtsSourceSettingUpsertMultipleRequest
     */
    state: JobAtsSourceSettingUpsertMultipleRequestStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum JobAtsSourceSettingUpsertMultipleRequestStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}

export function JobAtsSourceSettingUpsertMultipleRequestFromJSON(json: any): JobAtsSourceSettingUpsertMultipleRequest {
    return JobAtsSourceSettingUpsertMultipleRequestFromJSONTyped(json, false);
}

export function JobAtsSourceSettingUpsertMultipleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobAtsSourceSettingUpsertMultipleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobCandidateSourceSettingId': json['job_candidate_source_setting_id'],
        'atsSourceName': json['ats_source_name'],
        'state': json['state'],
    };
}

export function JobAtsSourceSettingUpsertMultipleRequestToJSON(value?: JobAtsSourceSettingUpsertMultipleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_candidate_source_setting_id': value.jobCandidateSourceSettingId,
        'ats_source_name': value.atsSourceName,
        'state': value.state,
    };
}


