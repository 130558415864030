import { SagaIterator } from "@redux-saga/core";
import { toast } from "react-toastify";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import { getOpenApiClients } from "services/api";
import { ApiApi, CampaignMessage, ListCampaignEmailSenderOptionEnum } from "services/openapi";
import { showErrorToast, toastOptions } from "utils/showToast";
import {
  loadCampaignError,
  loadCampaignSuccess,
  loadUnsubscribeFootersError,
  loadUnsubscribeFootersSuccess,
  saveCampaignError,
  saveCampaignSuccess,
} from "views/admin/CampaignView/actions";
import {
  LOAD_CAMPAIGN,
  LOAD_UNSUBSCRIBE_FOOTERS,
  LoadCampaignAction,
  SAVE_CAMPAIGN,
  SaveCampaignAction,
  SEND_TEST_EMAILS,
  SendTestEmailsAction,
} from "views/admin/CampaignView/types";

const SECONDS_IN_DAY = 86400;

function* loadCampaign({ campaignId }: LoadCampaignAction): SagaIterator {
  const apiApi: ApiApi = (yield call(getOpenApiClients, {})).apiApi;

  try {
    const resp = yield call(() => apiApi.getCampaign({ id: campaignId }));
    yield put(loadCampaignSuccess(resp));
  } catch (err) {
    const errorObject = yield err.json();
    console.error(errorObject);

    showErrorToast("Failed to load campaign");
    yield put(loadCampaignError(errorObject));
  }
}

function* saveCampaign({ campaign }: SaveCampaignAction): SagaIterator {
  const apiApi: ApiApi = (yield call(getOpenApiClients, {})).apiApi;

  try {
    campaign.updatedMessages = (campaign.threadMessages ?? []).map(
      (message: CampaignMessage): CampaignMessage => ({
        ...message,
        // Conversion is necessary since field is denominated in days but stored as a timedelta
        // @ts-ignore
        minMessageDelay: (parseFloat(message.minMessageDelay) ?? 0) * SECONDS_IN_DAY,
      })
    );
    campaign.saveAsDraft = true;

    // We must explicitly set the emailSenderOption in order for the backend to find the most available
    // email alias associated with the userDefinedSenderUser
    if (campaign.userDefinedSenderUser) {
      campaign.emailSenderOption = ListCampaignEmailSenderOptionEnum.OtherUser;
    }
    const resp = yield call(() => apiApi.partialUpdateCampaign({ id: campaign.id!, data: campaign }));
    yield put(saveCampaignSuccess(resp));
  } catch (err) {
    console.error(err);
    const errBody = yield err.json();
    showErrorToast(`Failed to save campaign. ${JSON.stringify(errBody)}`);
    yield put(saveCampaignError(err.message));
  }
}

function* loadUnsubscribeFooters(): SagaIterator {
  const apiApi: ApiApi = (yield call(getOpenApiClients, {})).apiApi;

  try {
    const resp = yield call(() => apiApi.listUnsubscribeFooters({}));
    yield put(loadUnsubscribeFootersSuccess(resp.results));
  } catch (err) {
    const errorObject = yield err.json();
    console.error(errorObject);

    showErrorToast("Failed to load unsubscribe footers");
    yield put(loadUnsubscribeFootersError(errorObject));
  }
}

function* sendTestEmails({ campaign }: SendTestEmailsAction): SagaIterator {
  const toastId = toast.info("Sending Test Emails...", {
    autoClose: false,
    hideProgressBar: true,
    progress: undefined,
    ...toastOptions,
  });
  try {
    const apiApi: ApiApi = (yield call(getOpenApiClients, {})).apiApi;
    yield call(() =>
      apiApi.campaignSendTestEmails({
        data: {
          emailAliasId: campaign.emailAlias as string,
          threadMessages: campaign.threadMessages as CampaignMessage[],
          sendToDoverQuality: true,
        },
      })
    );

    toast.update(toastId, {
      render: `✔️  Sent test emails`,
      type: toast.TYPE.SUCCESS,
    });
  } catch (err) {
    console.error(err);
    const errBody = yield err.json();
    toast.update(toastId, {
      render: `❌  Failed to send test emails: ${errBody.error}`,
      type: toast.TYPE.ERROR,
    });
    yield put(saveCampaignError(err.message));
  }
}

function* loadCampaignSaga(): SagaIterator {
  yield takeLatest(LOAD_CAMPAIGN, loadCampaign);
}

function* saveCampaignSaga(): SagaIterator {
  yield takeLatest(SAVE_CAMPAIGN, saveCampaign);
}

function* loadUnsubscribeFootersSaga(): SagaIterator {
  yield takeLatest(LOAD_UNSUBSCRIBE_FOOTERS, loadUnsubscribeFooters);
}

function* sendTestEmailsSaga(): SagaIterator {
  yield takeLatest(SEND_TEST_EMAILS, sendTestEmails);
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* mainCampaignViewSaga(): SagaIterator {
  // Listen for actions
  yield all([
    fork(loadCampaignSaga),
    fork(saveCampaignSaga),
    fork(loadUnsubscribeFootersSaga),
    fork(sendTestEmailsSaga),
  ]);
}
