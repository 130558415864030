export function boot_ondemand_integration(): void {
  (window as any).__ondemand_integration = {
    blackbox_path: (path: string): boolean => {
      const blackbox_dirs = [
        "/project/frontends/dover-pro/src/components/library/",
        "/project/frontends/dover-pro/src/App/routing/",
        "/project/frontends/dover-pro/src/index.js",
        "/project/frontends/dover-pro/src/services/react-auth0-spa.js",
      ];
      return blackbox_dirs.some(d => path.startsWith(d));
    },
  };
}

// updates take effect without reloading the page
// @ts-ignore I'm not sure what this is but jenkins says hot doesn't exist on this type
module.hot?.accept();
// @ts-ignore I'm not sure what this is but jenkins says hot doesn't exist on this type
module.hot?.dispose((data: any) => {
  data.boot = true;
});
// @ts-ignore I'm not sure what this is but jenkins says hot doesn't exist on this type
if (module.hot?.data?.boot) {
  boot_ondemand_integration();
}
