import { Stack } from "@mui/material";
import { format, isToday, differenceInCalendarDays, isThisYear } from "date-fns";
import React from "react";

import { COLUMN_PADDING } from "components/dover/SearchesTable/SearchesTable";
import { BodySmall, Subtitle1 } from "components/library/typography";
import { colors } from "styles/theme";

interface SearchNameCellProps {
  searchName: string;
  createdBy?: string;
  modified?: Date;
}

const formatEventTimestamp = (date: Date): string => {
  if (isToday(date)) {
    return `today at ${format(date, "p")}`;
  } else if (differenceInCalendarDays(new Date(), date) <= 6) {
    return date.toLocaleString("default", { weekday: "long" });
  } else if (!isThisYear(date)) {
    return format(date, "MMMM d, y");
  }
  return format(date, "MMMM do");
};

export const SearchNameCell = ({ searchName, createdBy, modified }: SearchNameCellProps): React.ReactElement => {
  const modifiedText = React.useMemo(() => {
    if (modified === undefined) {
      return undefined;
    }

    return formatEventTimestamp(modified);
  }, [modified]);
  return (
    <Stack padding={COLUMN_PADDING} spacing={0.5}>
      <Subtitle1 color={colors.linkLight}>{searchName}</Subtitle1>
      {!!createdBy && <BodySmall color={colors.grayscale.gray600}>{`Created by ${createdBy}`}</BodySmall>}
      {!!modifiedText && <BodySmall color={colors.grayscale.gray600}>{`Last edited ${modifiedText}`}</BodySmall>}
    </Stack>
  );
};
