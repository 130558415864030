import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TextField, MenuItem, InputAdornment } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { getOrderRequiredOptions } from "components/dover/CustomizeNextSteps/helpers";
import { Tooltip } from "components/library/Tooltip";
import InterviewerCreateModal, { CreateInterviewerFuncArgs } from "components/NextInterviewer/InterviewerCreateModal";
import { selectCurrentCandidateId } from "domains/candidate/selectors";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useCreateInterviewerMutation, useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import { ClientInterviewer } from "services/openapi";
import { colors } from "styles/theme";
import { getDurationOptions } from "views/CandidateDetail/utils";

const StyledSelect = styled(Select)`
  font-size: 14px;
  font-family: Inter;
  height: 40px;
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px !important;
  font-family: Inter !important;
`;

interface OrderRequiredInputProps {
  value: number;
  orderIndex: number;
  onChange: (e: any) => void;
  disabled?: boolean;
}

export const OrderRequiredInput = ({
  value,
  orderIndex,
  onChange,
  disabled,
}: OrderRequiredInputProps): React.ReactElement => {
  return (
    <StyledSelect
      variant="outlined"
      sx={{ width: "100%", ".MuiOutlinedInput-notchedOutline": { borderWidth: 0 } }}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {getOrderRequiredOptions(orderIndex).map((option, index) => {
        return (
          <StyledMenuItem key={index} value={option.value}>
            {option.label}
          </StyledMenuItem>
        );
      })}
    </StyledSelect>
  );
};

interface DurationInputProps {
  value: number;
  onChange: (e: any) => void;
  disabled?: boolean;
}

export const DurationInput = ({ value, onChange, disabled }: DurationInputProps): React.ReactElement => {
  return (
    <StyledSelect
      variant="outlined"
      sx={{
        width: "100%",
        ".MuiOutlinedInput-notchedOutline": { borderWidth: 0 },
        backgroundColor: `${colors.white} !important`,
      }}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {getDurationOptions().map((option, index) => {
        return (
          <StyledMenuItem key={index} value={option.value}>
            {option.label}
          </StyledMenuItem>
        );
      })}
    </StyledSelect>
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    font-size: 14px;
    font-family: Inter;
    height: 40px;
  }
  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

const CustomTooltipIcon = React.forwardRef(function CustomTooltipIcon(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    // @ts-ignore
    <div {...props} ref={ref}>
      <InfoOutlinedIcon sx={{ width: "16px", color: colors.grayscale.gray400 }} />
    </div>
  );
});

interface NameInputProps {
  idx?: number | string | null;
  value: string;
  onChange: (e: React.ChangeEvent<{ value: string }>) => void;
  disabled?: boolean;
}

export const NameInput = ({ idx, value, onChange, disabled }: NameInputProps): React.ReactElement => {
  return (
    <StyledTextField
      id={`name-${idx}`}
      variant="outlined"
      value={value}
      onChange={onChange}
      placeholder="Name"
      size="small"
      sx={{ fontSize: "14px", fontFamily: "Inter", ".MuiOutlinedInput-root ": { border: "none" } }}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={"e.g. Technical interview or Culture fit"} placement="top">
              <CustomTooltipIcon />
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

const StyledAutocompleteTextField = styled(TextField)`
  .MuiInputBase-root {
    height: 40px;
  }
  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

interface InterviewersInputProps {
  value: string[];
  handleChange: Function;
  disabled?: boolean;
}

interface ClientInterviewerOption extends ClientInterviewer {
  inputValue?: string;
}

const filter = createFilterOptions<ClientInterviewerOption>();

export const InterviewersInput = ({ value, handleChange, disabled }: InterviewersInputProps): React.ReactElement => {
  const interviewers = useListInterviewerEntities();
  const candidateId = useSelector(selectCurrentCandidateId);
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId);

  const hiringManagerId = candidateBio?.jobHiringManagerId;
  const clientId = candidateBio?.client;

  const { data: hiringManager } = useGetProUserQuery(hiringManagerId ? hiringManagerId.toString() : skipToken);

  const [showModal, setShowModal] = useState(false);
  const [selectedInterviewers, setSelectedInterviewers] = useState<ClientInterviewer[]>([]);

  useEffect(() => {
    // fetch ClientInterviewers from ids
    const foundInterviewers: ClientInterviewer[] = [];
    value.forEach((id: string): void => {
      const found = interviewers.find((interviewer: ClientInterviewer) => interviewer.id === id);
      if (found) {
        foundInterviewers.push(found);
      }
    });

    setSelectedInterviewers(foundInterviewers);
  }, [interviewers, value]);

  const onChangeSelection = (newValue: ClientInterviewerOption[]): void => {
    const newInterviewer = newValue.find(
      (obj: ClientInterviewerOption) => obj.inputValue || obj.email == `Add a new interviewer +`
    );
    if (newInterviewer) {
      setShowModal(true);
    } else {
      // return ids of the ClientInterviewers
      const interviewerIds = newValue.map((interviewer: ClientInterviewerOption) => interviewer.id);
      handleChange(interviewerIds);
      setSelectedInterviewers(newValue);
    }
  };

  const [createInterviewer] = useCreateInterviewerMutation();

  const createInterviewerFn = async (values: CreateInterviewerFuncArgs): Promise<void> => {
    const { firstName, lastName, email, phoneNumber } = values;
    await createInterviewer({ firstName, lastName, email, phoneNumber, client: clientId! }).unwrap();
    setShowModal(false);
  };

  return (
    <>
      <Autocomplete
        disableCloseOnSelect
        multiple
        limitTags={1}
        value={(selectedInterviewers as ClientInterviewerOption[]) || []}
        onChange={(_event, newValue: ClientInterviewerOption[]): void => {
          onChangeSelection(newValue);
        }}
        options={(interviewers as ClientInterviewerOption[]) || []}
        getOptionLabel={(option: ClientInterviewerOption): string => option.email}
        renderInput={(params): any => <StyledAutocompleteTextField {...params} variant="outlined" />}
        filterOptions={(options: ClientInterviewerOption[], params): any => {
          const filtered = filter(options, params);
          if (clientId) {
            filtered.push({
              client: clientId,
              email: `Add a new interviewer +`,
              inputValue: params.inputValue,
            });
          }

          return filtered;
        }}
        size="small"
        sx={{ height: "40px", fontSize: "14px", fontFamily: "Inter", ".MuiOutlinedInput-root ": { border: "none" } }}
        isOptionEqualToValue={(option, value): boolean => {
          return option.id === value.id;
        }}
        disabled={disabled}
      />
      <InterviewerCreateModal
        showModal={showModal}
        onCloseModal={(): void => setShowModal(false)}
        createInterviewer={createInterviewerFn}
        interviewers={interviewers}
        validateEmail={(): boolean => true} // add better checking later
        creating={showModal}
        hiringManagerEmail={hiringManager?.email}
      />
    </>
  );
};
