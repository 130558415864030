import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { SubmitReferralResponse, ApiApiSubmitReferralRequest } from "services/openapi";
import { showErrorToast } from "utils/showToast";

const referralFormEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    submitReferral: build.mutation<SubmitReferralResponse, ApiApiSubmitReferralRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.submitReferral(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to submit referral");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useSubmitReferralMutation } = referralFormEndpoints;
