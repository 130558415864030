import { React } from "react";
import { useLocation } from "react-router-dom";

export const GoogleAnalytics = () => {
  const location = useLocation();

  // initialize GA for apply and careers pages
  if (
    location.pathname.startsWith("/apply/") ||
    location.pathname.startsWith("/jobs/") ||
    location.pathname.includes("/careers/")
  ) {
    // eslint-disable-next-line
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line
    function gtag() {
      // eslint-disable-next-line
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-VJK8Q9EKYG");
  }

  return <></>;
};
