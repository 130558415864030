import { JobSetupStepStateEnum, JobSetupStepStepNameEnum } from "services/openapi";

interface JobSetupStepInfo {
  state: JobSetupStepStateEnum;
  isRelevantToJob: boolean;
}
export type JobSetupStepNameToInfo = { [key in JobSetupStepStepNameEnum]: JobSetupStepInfo };

export enum JobFeatureUIState {
  Inactive = "INACTIVE",
  NeedsSetup = "NEEDS_SETUP",
  PendingCall = "PENDING_CALL",
  Active = "ACTIVE",
  Paused = "PAUSED",
}

export interface JobSetupStepComponentProps {
  setupFlowOnNext?: () => void;
  setUpdating?: (updating: boolean) => void;
}

export interface JobSetupStepConstants {
  label: string;
  path: string;
  stepName: JobSetupStepStepNameEnum;
}

export interface JobSetupStepConfig extends JobSetupStepConstants {
  setupState: JobSetupStepStateEnum;
}

export interface EnrichedJobSetupStep extends JobSetupStepConfig {
  route: string;
}

interface StepVisiblityCheckFuncParams {
  isRelevantToJob: boolean;
}

export type StepVisiblityCheckFunc = (params: StepVisiblityCheckFuncParams) => boolean;

export enum JobFeatureDisabledStatus {
  Disabled = "Disabled",
  NotDisabled = "NotDisabled",
  NotDisabledIncompleteSetupSteps = "NotDisabledIncompleteSetupSteps",
  DisabledButDependentSetupStepsComplete = "DisabledButDependentSetupStepsComplete",
}
