import { memoize } from "lodash";

import {
  EmailsPerWeekOption,
  EMAILS_PER_WEEK_CONFIGS,
  WEEKS_IN_MONTH,
} from "components/dover/dover-outbound-modal/constants";
import { DoverOutboundPersonaStats } from "services/openapi";

export const getDoverOutboundConfigOptionFromEmailsPerWeek = memoize((emailsPerWeek: number | undefined):
  | EmailsPerWeekOption
  | undefined => {
  if (!emailsPerWeek) {
    return undefined;
  }
  if (emailsPerWeek < 150) {
    return EmailsPerWeekOption.Passive;
  } else if (emailsPerWeek < 200) {
    return EmailsPerWeekOption.Standard;
  }
  return EmailsPerWeekOption.Boosted;
});

export const getPersonaInterestedCandidatesPerWeek = (
  doverOutboundPersonaStats: Array<DoverOutboundPersonaStats>,
  configType: EmailsPerWeekOption,
  percentile: 10 | 50
): number => {
  const statsPerEmailsPerWeekOption = doverOutboundPersonaStats.find(
    doverOutboundPersonaStat =>
      doverOutboundPersonaStat.targetMinEmailsPerWeek === EMAILS_PER_WEEK_CONFIGS[configType].expectedNewCandidatesCount
  );
  const numPersonaInterestedPerMonth =
    percentile === 10
      ? statsPerEmailsPerWeekOption!.numInterested10PercentileJob!
      : statsPerEmailsPerWeekOption!.numInterested50PercentileJob!;

  const personaInterestedCandidatesPerWeek = Math.round(numPersonaInterestedPerMonth / WEEKS_IN_MONTH);

  // We only want to show the actual interested candidates per week if its 1+. Otherwise, we show 1.
  return personaInterestedCandidatesPerWeek > 0 ? personaInterestedCandidatesPerWeek : 1;
};

export const convertInterestRateToCount = (interestedRate: number): string => {
  return (100 * interestedRate).toFixed(1);
};
