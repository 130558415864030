export const substituteVariablesForPreviewEmail = (templateText?: string): string => {
  if (!templateText) {
    return "";
  }
  const substitutedText = templateText.replace(getTemplateVariableRegex(), (match, variable) => {
    if (variable in defaultTemplateValues) {
      return defaultTemplateValues[variable];
    }
    return match;
  });
  return substitutedText;
};

const getTemplateVariableRegex = (): RegExp => {
  return /{{\s*(.*?)\s*}}/g;
};

const defaultTemplateValues: Record<string, string> = {
  scheduling_link: "https://findatime.io",
  first_name: "John",
  sender_first_name: "Alex",
  interviewer_first_name: "Jane",
  client_name: "Dover",
  interviewer_role_title_with_article: "Jane, Senior Product Designer",
};
