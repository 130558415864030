import { skipToken } from "@reduxjs/toolkit/dist/query";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { BaseQueryFn, QueryDefinition } from "@reduxjs/toolkit/query";
import { useAtomValue } from "jotai";
import { isEqual } from "lodash";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListEducationLevelsQuery, useListFieldsOfStudySaaPQuery } from "services/doverapi/endpoints/education";
import { unknownSeniorityOption } from "services/doverapi/endpoints/search-v3/constants";
import {
  useGetClientScopedTargetSchoolLists,
  useGetDoversTargetSchoolLists,
  useGetDoversTargetCompanyLists,
} from "services/doverapi/endpoints/search-v3/customHooks";
import {
  useGetSearchV3Query,
  useListAllLocations,
  useListCompanySizesQuery,
  useListDiversityOptionsQuery,
  useListSenioritiesQuery,
  useListSearchesV3Query,
  useLazyListPersonasQuery,
  useLazyListIndividualCompaniesQuery,
  useLazyListProfileSearchIndustriesQuery,
  useLazyListIndividualSchoolsQuery,
  useLazyListProfileSearchKeywordsQuery,
  useLazyGetKeywordsWithBucketIndicesQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { PersonaIDsRequired } from "services/doverapi/endpoints/search-v3/types";
import { DoverApiError } from "services/doverapi/types";
import {
  SearchV3,
  Seniority,
  Persona,
  SearchV3SearchTypeEnum,
  ApiApiListPersonasRequest,
  ApiApiListSimilarCompaniesRequest,
  Company,
  School,
  ApiApiListProfileSearchIndustriesRequest,
  ApiApiListProfileSearchKeywordsRequest,
  ProfileSearchIndustrySerializer,
  ProfileSearchKeywordSerializer,
  ApiApiListSimilarSchoolsRequest,
  KeywordWithBucketIndicesRequest,
  KeywordWithBucketIndicesResponse,
} from "services/openapi";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";
import {
  COMPANY_SIZES_NAME,
  COMPANY_SIZES_EXCLUSIONS_NAME,
  EXCLUDED_LOCATIONS_ELEMENTS_NAME,
  EXCLUDED_SENIORITIES_NAME,
  EXCLUDE_FREQUENT_JOB_SWITCHING_NAME,
  INDIVIDUAL_EXCLUDED_COMPANIES_NAME,
  LOCATIONS_ELEMENTS_NAME,
  PERSONAS_ELEMENTS_NAME,
  SEARCH_NAME,
  SELECTED_SENIORITIES_NAME,
  SPECIFIC_YEARS_OF_EXPERIENCE_NAME,
  TOTAL_YEARS_OF_EXPERIENCE_NAME,
  BACHELORS_GRADUATION_YEAR_NAME_MIN,
  BACHELORS_GRADUATION_YEAR_NAME_MAX,
  SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME,
  YEARS_AT_CURRENT_COMPANY_NAME,
  YEARS_OF_EXPERIENCE_MAX,
  SELECTED_DOVER_COMPANY_LISTS_NAME,
  SELECTED_COMPANY_LISTS_MOST_RECENT_ONLY,
  COMPANY_RANK_RANGE_NAME,
  SCHOOL_RANK_RANGE_NAME,
  INDUSTRIES_MOST_RECENT_ONLY,
  INDUSTRIES_NAME,
  EXCLUDED_INDUSTRIES_NAME,
  TARGET_SCHOOL_LISTS_NAME,
  INDIVIDUAL_EXCLUDED_SCHOOLS_NAME,
  FIELDS_OF_STUDY_NAME,
  EDUCATION_LEVELS_NAME,
  STRICTNESS_NAME,
  DIVERSITY_OPTION_NAME,
  CUSTOM_TITLES_NAME,
  CUSTOM_TITLES_SOFT_MATCH_NAME,
  PERSONAS_MOST_RECENT_ONLY_NAME,
  EXCLUDED_TITLES_NAME,
  SEARCH_ID_OVERRIDE_QUERY_PARAM_NAME,
  DENIED_KEYWORDS_NAME,
  searchV3FormDefaultValues,
  TARGET_SCHOOLS_NAME,
  INDIVIDUAL_COMPANIES_NAME,
} from "views/sourcing/Search/constants";
import { searchV3FormSchema, SearchV3FormSchemaType } from "views/sourcing/Search/types";
import {
  getKeywordsBucketWithMetadataName,
  searchParamsRankRangeToCompanyRankRange,
  searchParamsRankRangeToSchoolRankRange,
} from "views/sourcing/Search/utils";

// typing for lazy queries
type LazyListPersonasType = LazyQueryTrigger<
  QueryDefinition<
    ApiApiListPersonasRequest,
    BaseQueryFn<void, any, DoverApiError, {}, {}>,
    any,
    PersonaIDsRequired[],
    "doverApi"
  >
>;
type LazyListCompaniesType = LazyQueryTrigger<
  QueryDefinition<
    ApiApiListSimilarCompaniesRequest,
    BaseQueryFn<void, any, DoverApiError, {}, {}>,
    any,
    Company[],
    "doverApi"
  >
>;
type LazyListSchoolsType = LazyQueryTrigger<
  QueryDefinition<
    ApiApiListSimilarSchoolsRequest,
    BaseQueryFn<void, any, DoverApiError, {}, {}>,
    any,
    School[],
    "doverApi"
  >
>;
type LazyGetKeywordsType = LazyQueryTrigger<
  QueryDefinition<
    ApiApiListProfileSearchKeywordsRequest,
    BaseQueryFn<void, any, DoverApiError, {}, {}>,
    any,
    ProfileSearchKeywordSerializer[],
    "doverApi"
  >
>;
type LazyGetKeywordBucketsType = LazyQueryTrigger<
  QueryDefinition<
    KeywordWithBucketIndicesRequest[],
    BaseQueryFn<void, any, DoverApiError, {}, {}>,
    any,
    KeywordWithBucketIndicesResponse[],
    "doverApi"
  >
>;
type LazyGetIndustriesType = LazyQueryTrigger<
  QueryDefinition<
    ApiApiListProfileSearchIndustriesRequest,
    BaseQueryFn<void, any, DoverApiError, {}, {}>,
    any,
    ProfileSearchIndustrySerializer[],
    "doverApi"
  >
>;

export const personasToPersonasIDsRequiredConverter = (personas: Persona[]): PersonaIDsRequired[] => {
  const requiredIDs: PersonaIDsRequired[] = personas
    .filter(persona => !!persona.id && !!persona.cleanName)
    .map(persona => {
      return {
        id: persona.id!,
        name: persona.name,
        cleanName: persona.cleanName!,
        definition: persona.definition,
        jobCategoryDisplay: persona.jobCategoryDisplay ?? "",
        paramsJson: persona.paramsJson,
        scopedToClient: persona.scopedToClient ?? null,
        parentHasMlTitles: persona.parentHasMlTitles ?? false,
      };
    });
  return requiredIDs;
};

export const convertPersonaIDToFormValueAsync = async (
  newPersonaIDsForForm: string[],
  lazyListPersonas: LazyListPersonasType
): Promise<PersonaIDsRequired[]> => {
  // given some persona IDs, return the corresponding PersonaIDsRequired objects
  const formattedPersonaIds = newPersonaIDsForForm.join(",");

  if (formattedPersonaIds === "") {
    return [];
  }
  const response = await lazyListPersonas({ idList: formattedPersonaIds });
  const personas = response.data;
  return personasToPersonasIDsRequiredConverter(personas ?? []);
};

const convertPersonaIDToFormValue = (
  newPersonaIDsForForm: string[],
  lazyListPersonas: (request: ApiApiListPersonasRequest) => any
): boolean => {
  // given some persona IDs, return the corresponding PersonaIDsRequired objects
  const formattedPersonaIds = newPersonaIDsForForm.join(",");

  if (formattedPersonaIds === "") {
    return false;
  }

  lazyListPersonas({ idList: formattedPersonaIds });
  return true;
};

const getIndividualCompaniesFormValue = (search: SearchV3, lazyListCompanies: LazyListCompaniesType): boolean => {
  if (search.v3Params.individualTargetCompanyIds.length === 0) {
    return false;
  }

  const formattedCompanyIds = search.v3Params.individualTargetCompanyIds[0].value.join(",");
  if (formattedCompanyIds === "") {
    return false;
  }

  lazyListCompanies({ idList: formattedCompanyIds });
  return true;
};

const getIndividualExcludedCompaniesFormValue = (
  search: SearchV3,
  lazyListCompanies: LazyListCompaniesType
): boolean => {
  if (search.v3Params.individualExcludedCompanyIds.length === 0) {
    return false;
  }

  const formattedCompanyIds = search.v3Params.individualExcludedCompanyIds[0].value.join(",");
  if (formattedCompanyIds === "") {
    return false;
  }

  lazyListCompanies({ idList: formattedCompanyIds });
  return true;
};

const getIndustriesFormValue = (search: SearchV3, lazyListIndustries: LazyGetIndustriesType): boolean => {
  if (search.v3Params.industries.length === 0) {
    return false;
  }

  const formattedIndustryIds = search.v3Params.industries[0].value.join(",");
  if (formattedIndustryIds === "") {
    return false;
  }

  lazyListIndustries({ idList: formattedIndustryIds });
  return true;
};

const getExcludedIndustriesFormValue = (search: SearchV3, lazyListIndustries: LazyGetIndustriesType): boolean => {
  if (search.v3Params.excludedIndustries?.length === 0) {
    return false;
  }

  const formattedIndustryIds = search.v3Params.excludedIndustries[0].value.join(",");
  if (formattedIndustryIds === "") {
    return false;
  }

  lazyListIndustries({ idList: formattedIndustryIds });
  return true;
};

const getIndividualSchoolsFormValue = (search: SearchV3, lazyListSchools: LazyListSchoolsType): boolean => {
  if (
    search.v3Params.individualTargetSchoolIds.length === 0 &&
    search.v3Params.individualTargetSchoolNames.length === 0
  ) {
    return false;
  }

  // TODO(willjow): remove school_names
  // Prefer school ids if present
  const formattedSchoolIds = search.v3Params.individualTargetSchoolIds[0]?.value.join(",");
  const formattedSchoolNames = search.v3Params.individualTargetSchoolNames[0]?.value.join(",");

  if (!formattedSchoolIds && !formattedSchoolNames) {
    return false;
  }

  const listSimilarSchoolsRequestParams = formattedSchoolIds
    ? { idList: formattedSchoolIds }
    : { nameList: formattedSchoolNames };

  // make the call here, and use the data later
  lazyListSchools(listSimilarSchoolsRequestParams);
  return true;
};

const getIndividualSchoolsExclusionsFormValue = (search: SearchV3, lazyListSchools: LazyListSchoolsType): boolean => {
  if (
    search.v3Params.individualExcludedSchoolIds.length === 0 &&
    search.v3Params.individualExcludedSchoolNames.length === 0
  ) {
    return false;
  }

  // TODO(willjow): remove school_names
  // Prefer school ids if present
  const formattedSchoolIds = search.v3Params.individualExcludedSchoolIds[0]?.value.join(",");
  const formattedSchoolNames = search.v3Params.individualExcludedSchoolNames[0]?.value.join(",");

  if (!formattedSchoolIds && !formattedSchoolNames) {
    return false;
  }

  const listSimilarSchoolsRequestParams = formattedSchoolIds
    ? { idList: formattedSchoolIds }
    : { nameList: formattedSchoolNames };

  lazyListSchools(listSimilarSchoolsRequestParams);
  return true;
};

const getDeniedKeywordsValue = (search: SearchV3, lazyListKeywords: LazyGetKeywordsType): boolean => {
  if (search.v3Params.deniedKeywords.length === 0) {
    return false;
  }

  const deniedKeywordIds = search.v3Params.deniedKeywords[0].value.join(",");

  if (!deniedKeywordIds.length) {
    return false;
  }

  lazyListKeywords({ idList: deniedKeywordIds });
  return true;
};

const setKeywordsFormValue = (search: SearchV3, lazyGetKeywordsBuckets: LazyGetKeywordBucketsType): boolean => {
  if (search.v3Params.keywords.length === 0) {
    return false;
  }

  const request = [];
  for (let bucketIndex = 0; bucketIndex < search.v3Params.keywords.length; bucketIndex++) {
    const keywordBucket = search.v3Params.keywords[bucketIndex];

    request.push({ bucketIndex: bucketIndex, idsList: keywordBucket.value });
    // in case any of the keywords buckets values are [], then just skip it, and keep track of the 'actual' bucket index
  }
  lazyGetKeywordsBuckets(request);
  return true;
};

export function useSearchId(): string | null | undefined {
  const { searchId } = useParams<{ searchId: string }>();
  const [searchIdOverride] = useQueryParam(SEARCH_ID_OVERRIDE_QUERY_PARAM_NAME, StringParam);

  return React.useMemo(() => {
    return searchIdOverride ?? searchId;
  }, [searchId, searchIdOverride]);
}

export function useGetSearchFromUrl(): SearchV3 | undefined {
  const dtnMode = useAtomValue(isDtnMode);

  const searchIdFromUrl = useSearchId();
  const jobId = useJobIdFromUrl();
  const { data: searchFromUrl } = useGetSearchV3Query(searchIdFromUrl ? { id: searchIdFromUrl } : skipToken);
  const { data: searches } = useListSearchesV3Query(
    jobId
      ? { job: jobId, searchTypeList: dtnMode ? SearchV3SearchTypeEnum.Dtn : SearchV3SearchTypeEnum.Inbound }
      : skipToken
  );

  const search = React.useMemo(() => {
    if (searchFromUrl) {
      return searchFromUrl;
    }
    const activeSearches = searches?.filter(search => search.active);
    return activeSearches?.length ? activeSearches[0] : searches?.[0];
  }, [searches, searchFromUrl]);
  return search;
}

export function useIsSaaPReviewContext(): boolean {
  const searchIdFromUrl = useSearchId();
  // SaaPReview provides jobId in the URL, not searchId.
  return searchIdFromUrl == undefined;
}

export function useLoadFormValues(
  formValuesLoaded: boolean,
  formValuesLoading: boolean,
  setFormValuesLoading?: (loading: boolean) => void,
  setInitialFormValuesLoaded?: (loaded: boolean) => void
): void {
  const search = useGetSearchFromUrl();

  // Form options section
  const locationsOptions = useListAllLocations();
  const { data: seniorityOptions } = useListSenioritiesQuery();
  const { data: companySizesOptions } = useListCompanySizesQuery();
  const { data: mySchoolListsOptions } = useGetClientScopedTargetSchoolLists();
  const { data: doverSchoolListsOptions } = useGetDoversTargetSchoolLists();
  const { data: doverCompanyListsOptions } = useGetDoversTargetCompanyLists();
  const { data: supportedFieldsOfStudy } = useListFieldsOfStudySaaPQuery({ limit: 100 });
  const { data: educationLevels } = useListEducationLevelsQuery();
  const { data: diversityOptions } = useListDiversityOptionsQuery();

  // lazy queries
  const [lazyListPersonas, { currentData: listPersonasData }] = useLazyListPersonasQuery();

  // companies
  const [lazyListCompanies, { currentData: individualCompaniesData }] = useLazyListIndividualCompaniesQuery();
  const [
    lazyListExcludedCompanies,
    { currentData: individualExcludedCompaniesData },
  ] = useLazyListIndividualCompaniesQuery();

  // industries
  const [lazyListIndustries, { currentData: industriesData }] = useLazyListProfileSearchIndustriesQuery();
  const [
    lazyListExcludedIndustries,
    { currentData: excludedIndustriesData },
  ] = useLazyListProfileSearchIndustriesQuery();

  // schools
  const [lazyListSchools, { currentData: schoolsData }] = useLazyListIndividualSchoolsQuery();
  const [lazyListExcludedSchools, { currentData: excludedSchoolsData }] = useLazyListIndividualSchoolsQuery();

  // keywords
  const [lazyListDeniedKeywords, { currentData: deniedKeywordsData }] = useLazyListProfileSearchKeywordsQuery();
  const [lazyListKeywords] = useLazyListProfileSearchKeywordsQuery();
  const [lazyGetKeywordsBuckets, { currentData: keywordBucketsData }] = useLazyGetKeywordsWithBucketIndicesQuery();

  // Form helper methods
  const { setValue } = useFormContext<SearchV3FormSchemaType>();

  React.useEffect(() => {
    // Wait until these callbacks are present so we can fire an event when initial form state is loaded
    if (setInitialFormValuesLoaded === undefined || setFormValuesLoading === undefined) {
      return;
    }

    if (formValuesLoading || formValuesLoaded) {
      return;
    }

    // We can't do anything with the search if it's undefined
    if (search === undefined) {
      return;
    }

    // return type of these function is boolean, to indicate whether the query was actually made
    // if it wasn't made, the data from the lazy query will always be undefined, so that's why we need to keep track of
    // whether a query was made and therefore, whether we should await the results of that query
    const shouldAwaitSchools = getIndividualSchoolsFormValue(search, lazyListSchools);
    const shouldAwaitExcludedSchools = getIndividualSchoolsExclusionsFormValue(search, lazyListExcludedSchools);
    const shouldAwaitIndustries = getIndustriesFormValue(search, lazyListIndustries);
    const shouldAwaitExcludedIndustries = getExcludedIndustriesFormValue(search, lazyListExcludedIndustries);
    const shouldAwaitExcludedCompanies = getIndividualExcludedCompaniesFormValue(search, lazyListExcludedCompanies);
    const shouldAwaitCompanies = getIndividualCompaniesFormValue(search, lazyListCompanies);
    const shouldAwaitKeywords = setKeywordsFormValue(search, lazyGetKeywordsBuckets);
    const shouldAwaitDeniedKeywords = getDeniedKeywordsValue(search, lazyListDeniedKeywords);
    let shouldAwaitListPersonas =
      !!search?.v3Params.targetPersonas.length && !!search?.v3Params.targetPersonas[0].value.length;

    if (shouldAwaitListPersonas) {
      shouldAwaitListPersonas = convertPersonaIDToFormValue(search.v3Params.targetPersonas[0].value, lazyListPersonas);
    }

    // Lastly, we also early return if we are missing any form options
    // That's because we want to load in the whole form at once to prevent multiple API calls
    // So we have to hold off any any form value updates until all of the options are loaded
    if (
      locationsOptions === undefined ||
      seniorityOptions === undefined ||
      companySizesOptions === undefined ||
      mySchoolListsOptions === undefined ||
      doverSchoolListsOptions === undefined ||
      doverCompanyListsOptions === undefined ||
      supportedFieldsOfStudy === undefined ||
      educationLevels === undefined ||
      diversityOptions === undefined ||
      (shouldAwaitSchools && schoolsData === undefined) ||
      (shouldAwaitExcludedSchools && excludedSchoolsData === undefined) ||
      (shouldAwaitIndustries && industriesData === undefined) ||
      (shouldAwaitExcludedIndustries && excludedIndustriesData === undefined) ||
      (shouldAwaitCompanies && individualCompaniesData === undefined) ||
      (shouldAwaitExcludedCompanies && individualExcludedCompaniesData === undefined) ||
      (shouldAwaitDeniedKeywords && deniedKeywordsData === undefined) ||
      (shouldAwaitListPersonas && listPersonasData === undefined) ||
      (shouldAwaitKeywords && keywordBucketsData === undefined)
    ) {
      return;
    }
    setFormValuesLoading(true);

    // This function performs all of the asynchronous options first the require calls to the API client
    // then it performs the synchronous form-related operations, to make sure we do everything at once
    const setFormValuesAsync = async (): Promise<void> => {
      // Set search name
      setValue(SEARCH_NAME, search.name);

      // Set target locations
      if (search.v3Params.targetLocations.length > 0) {
        setValue(
          LOCATIONS_ELEMENTS_NAME,
          locationsOptions.filter(option => search.v3Params.targetLocations[0].value.includes(option.value))
        );
      }

      // Set location exclusions
      if (search.v3Params.excludedLocations.length > 0) {
        setValue(
          EXCLUDED_LOCATIONS_ELEMENTS_NAME,
          locationsOptions.filter(option => search.v3Params.excludedLocations[0].value.includes(option.value))
        );
      }

      /** START SECTION - total years of experience */
      if (search.v3Params.totalYearsOfExperience.length > 0) {
        const savedTotalYearsOfExperience = search.v3Params.totalYearsOfExperience[0].value;
        if (savedTotalYearsOfExperience.min !== undefined || savedTotalYearsOfExperience.max !== undefined) {
          setValue(TOTAL_YEARS_OF_EXPERIENCE_NAME, {
            min: savedTotalYearsOfExperience.min,
            max:
              savedTotalYearsOfExperience.max !== undefined &&
              savedTotalYearsOfExperience.max <= YEARS_OF_EXPERIENCE_MAX
                ? savedTotalYearsOfExperience.max
                : undefined,
          });
        }
      }
      /** END SECTION - total years of experience */

      /** START SECTION - specific years of experience */
      if (search.v3Params.specificYearsOfExperience.length > 0) {
        // If these values aren't set on the search object, do nothing
        const savedSpecificYearsOfExperience = search.v3Params.specificYearsOfExperience[0].value;
        if (savedSpecificYearsOfExperience.min !== undefined || savedSpecificYearsOfExperience.max !== undefined) {
          const savedTotalYearsOfExperience = search.v3Params.totalYearsOfExperience[0]?.value;

          // Specific minimum YOE must be at least equal to total YOE
          const specificYearsMin = Math.ceil(
            Math.min(savedSpecificYearsOfExperience.min ?? 0, savedTotalYearsOfExperience?.min ?? 0)
          );

          let specificYearsMax = undefined;

          // Specific maximum YOE is at most total maximum YOE
          // Also bounded by YEARS_OF_EXPERIENCE_MAX
          if (savedSpecificYearsOfExperience.max !== undefined && savedTotalYearsOfExperience?.max !== undefined) {
            specificYearsMax = Math.ceil(
              Math.min(savedTotalYearsOfExperience?.max, savedSpecificYearsOfExperience.max, YEARS_OF_EXPERIENCE_MAX)
            );
          }

          setValue(SPECIFIC_YEARS_OF_EXPERIENCE_NAME, {
            min: specificYearsMin,
            max: specificYearsMax,
          });
        }
      }
      /** END SECTION - specific years of experience */

      /** START SECTION - specific years of experience by T2*/
      if (search.v3Params.specificYearsOfExperienceByT2.length > 0) {
        const yoeByT2s = search.v3Params.specificYearsOfExperienceByT2[0].value;

        setValue(
          SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME,
          yoeByT2s.map(yoeByT2 => {
            return {
              required: true,
              titleId: yoeByT2.titleId,
              yoeRange: yoeByT2.yoeRange,
            };
          })
        );
      }
      /** END SECTION - specific years of experience */

      // set year of bachelor graduation
      if (search.v3Params.bachelorsGraduationYearRange.length > 0) {
        const savedBachelorsGraduation = search.v3Params.bachelorsGraduationYearRange[0].value;
        if (savedBachelorsGraduation.min !== undefined) {
          setValue(BACHELORS_GRADUATION_YEAR_NAME_MIN, savedBachelorsGraduation.min);
        }
        if (savedBachelorsGraduation.max !== undefined) {
          setValue(BACHELORS_GRADUATION_YEAR_NAME_MAX, savedBachelorsGraduation.max);
        }
      }

      // set years at current company range
      if (search.v3Params.yearsAtCurrentCompanyRange.length > 0) {
        const yearsAtCurrentCompany = search.v3Params.yearsAtCurrentCompanyRange[0].value;
        if (yearsAtCurrentCompany.min !== undefined || yearsAtCurrentCompany.max !== undefined) {
          setValue(YEARS_AT_CURRENT_COMPANY_NAME, {
            min: yearsAtCurrentCompany.min,
            max:
              yearsAtCurrentCompany.max !== undefined && yearsAtCurrentCompany.max <= YEARS_OF_EXPERIENCE_MAX
                ? yearsAtCurrentCompany.max
                : undefined,
          });
        }
      }

      // Set exclude jumpiness
      if (search.v3Params.excludeFrequentJobSwitching.length > 0) {
        setValue(EXCLUDE_FREQUENT_JOB_SWITCHING_NAME, search.v3Params.excludeFrequentJobSwitching[0].value);
      }

      /** START SECTION - seniorities */
      let selectedSeniorities: Seniority[] = [];

      // We only ever have targetSeniorities or excludedSeniorities set
      // So we can reason about which checkboxes are checked directly or inversely
      // If exclusions are set, that means unknown must be checked too
      if (search.v3Params.targetSeniorities.length > 0) {
        selectedSeniorities = seniorityOptions.filter(option =>
          search.v3Params.targetSeniorities[0].value.seniorities.includes(option.id)
        );
      } else if (search.v3Params.excludedSeniorities.length > 0) {
        selectedSeniorities = [
          unknownSeniorityOption,
          ...seniorityOptions
            .filter(option => option.name !== "Unknown")
            .filter(option => !search.v3Params.excludedSeniorities[0].value.seniorities.includes(option.id)),
        ];
      }

      setValue(SELECTED_SENIORITIES_NAME, selectedSeniorities);
      setValue(
        EXCLUDED_SENIORITIES_NAME,
        seniorityOptions.filter(option => !selectedSeniorities.find(selected => option.id === selected.id))
      );
      /** END SECTION - seniorities */

      // Set company sizes
      if (search.v3Params.companySize.length > 0 && search.v3Params.companySize[0].value.sizes.length > 0) {
        const listOfMins = search.v3Params.companySize[0].value.sizes.map(size => size.min);
        const insideValue = companySizesOptions
          .filter(option => listOfMins.includes(option.minSize))
          .map(option => {
            return { name: option.name, minSize: option.minSize, maxSize: option.maxSize };
          });
        setValue(COMPANY_SIZES_NAME, {
          value: insideValue,
          required: !!search.v3Params.companySize[0].required,
          mostRecentOnly: !!search.v3Params.companySize[0].value.mostRecentOnly,
        });
      }

      // Set company size exclusions
      if (
        search.v3Params.excludedCompanySize.length > 0 &&
        search.v3Params.excludedCompanySize[0].value.sizes.length > 0
      ) {
        const listOfMins = search.v3Params.excludedCompanySize[0].value.sizes.map(size => size.min);
        const insideValue = companySizesOptions
          .filter(option => listOfMins.includes(option.minSize))
          .map(option => {
            return { name: option.name, minSize: option.minSize, maxSize: option.maxSize };
          });
        setValue(COMPANY_SIZES_EXCLUSIONS_NAME, {
          value: insideValue,
          required: !!search.v3Params.excludedCompanySize[0].required,
          mostRecentOnly: !!search.v3Params.excludedCompanySize[0].value.mostRecentOnly,
        });
      }

      // Set company prestige
      if (search.v3Params.companyRankRange.length > 0) {
        const companyRankRangeFormValue = searchParamsRankRangeToCompanyRankRange(
          search.v3Params.companyRankRange[0].value
        );
        setValue(COMPANY_RANK_RANGE_NAME, companyRankRangeFormValue);
      }

      // Set target company lists
      if (search.v3Params.targetCompanyLists.length > 0) {
        setValue(
          SELECTED_DOVER_COMPANY_LISTS_NAME,
          doverCompanyListsOptions.filter(option => search.v3Params.targetCompanyLists[0].value.includes(option.id))
        );
      }

      // Set "current position only" toggle
      if (search.v3Params.targetCompaniesMostRecentOnly.length > 0) {
        setValue(SELECTED_COMPANY_LISTS_MOST_RECENT_ONLY, search.v3Params.targetCompaniesMostRecentOnly[0].value);
      }

      // Set industries "current position only" toggle
      if (search.v3Params.industriesMostRecentOnly.length > 0) {
        setValue(INDUSTRIES_MOST_RECENT_ONLY, search.v3Params.industriesMostRecentOnly[0].value);
      }

      // Set school prestige
      if (search.v3Params.schoolRankRange.length > 0) {
        const schoolRankRangeFormValue = searchParamsRankRangeToSchoolRankRange(
          search.v3Params.schoolRankRange[0].value
        );
        setValue(SCHOOL_RANK_RANGE_NAME, schoolRankRangeFormValue);
      }

      // Set school lists
      if (search.v3Params.targetSchoolLists.length > 0) {
        setValue(
          TARGET_SCHOOL_LISTS_NAME,
          doverSchoolListsOptions.filter(option => search.v3Params.targetSchoolLists[0].value.includes(option.id))
        );
      }

      // Set fields of study
      if (search.v3Params.fieldsOfStudy.length > 0 && search.v3Params.fieldsOfStudy[0].value.length > 0) {
        setValue(FIELDS_OF_STUDY_NAME, {
          selectedFieldsOfStudy: supportedFieldsOfStudy.filter(option =>
            search.v3Params.fieldsOfStudy[0].value.includes(option.id)
          ),
          required: !!search.v3Params.fieldsOfStudy[0].required,
        });
      }

      // Set education level
      setValue(EDUCATION_LEVELS_NAME, {
        selectedEducationLevel:
          educationLevels.find(option => search.v3Params.educationLevel?.[0]?.value === option.educationLevel) ?? null,
        required: !!search.v3Params.educationLevel?.[0]?.required,
      });

      // Set diversity options
      if (search.v3Params.diversity.length > 0) {
        const matchingDiversityOptions = diversityOptions.filter(
          option => search.v3Params.diversity[0].value === option.value
        );
        if (matchingDiversityOptions.length > 0) {
          setValue(DIVERSITY_OPTION_NAME, matchingDiversityOptions[0].value);
        }
      }

      // Set strictness
      setValue(STRICTNESS_NAME, search.v3Params.minScoreRatio * 10);

      // Set custom titles and exclusions
      setValue(CUSTOM_TITLES_NAME, search.v3Params.targetCustomTitles[0]?.value.titles ?? []);
      setValue(
        CUSTOM_TITLES_SOFT_MATCH_NAME,
        search.v3Params.customTitlesSoftMatch[0]?.value ?? searchV3FormDefaultValues.personas?.customTitlesSoftMatch
      );
      setValue(EXCLUDED_TITLES_NAME, search.v3Params.excludedCustomTitles[0]?.value.titles ?? []);
      setValue(
        PERSONAS_MOST_RECENT_ONLY_NAME,
        search.v3Params.personasMostRecentOnly[0]?.value ?? searchV3FormDefaultValues.personas?.personasMostRecentOnly
      );
      // set the data loaded in from the lazy queries
      // keywords
      if (keywordBucketsData !== undefined && search.v3Params.keywords.length) {
        // in case any of our buckets are empty (ie keyword has been deleted, or simply a bucket exists without any keywords,
        // we skip them, so as not to have empty buckets in frontend)
        let actualBucketIndex = 0;

        for (let currBucketIndex = 0; currBucketIndex < search.v3Params.keywords.length; currBucketIndex++) {
          const keywordsInBucket = keywordBucketsData.find(bucketInfo => bucketInfo.bucketIndex === currBucketIndex)
            ?.keywordsInBucket;
          if (keywordsInBucket?.length) {
            setValue(getKeywordsBucketWithMetadataName(actualBucketIndex) as any, {
              bucket: keywordsInBucket,
              required: !!search.v3Params.keywords[currBucketIndex].required,
            });
            actualBucketIndex += 1;
          }
        }
      }
      if (deniedKeywordsData) {
        setValue(DENIED_KEYWORDS_NAME, deniedKeywordsData);
      }
      // personas
      if (listPersonasData) {
        setValue(PERSONAS_ELEMENTS_NAME, personasToPersonasIDsRequiredConverter(listPersonasData));
      }
      // companies
      const individualCosRequired = search.v3Params.individualTargetCompanyIds.length
        ? !!search.v3Params.individualTargetCompanyIds[0].required
        : false;
      setValue(INDIVIDUAL_COMPANIES_NAME, {
        required: individualCosRequired,
        individualCompanyElements: individualCompaniesData ?? [],
      });

      if (individualExcludedCompaniesData) {
        setValue(INDIVIDUAL_EXCLUDED_COMPANIES_NAME, individualExcludedCompaniesData);
      }
      // industries
      if (industriesData) {
        setValue(INDUSTRIES_NAME, {
          industryElements: industriesData,
          required: !!search.v3Params.industries[0].required,
        });
      }
      if (excludedIndustriesData) {
        setValue(EXCLUDED_INDUSTRIES_NAME, {
          industryElements: excludedIndustriesData,
          required: !!search.v3Params.excludedIndustries[0].required,
        });
      }
      // schools
      setValue(TARGET_SCHOOLS_NAME, {
        individualSchools: schoolsData ?? [],
        required: !!search.v3Params.individualTargetSchoolIds?.[0]?.required ?? false,
      });

      if (excludedSchoolsData) {
        setValue(INDIVIDUAL_EXCLUDED_SCHOOLS_NAME, excludedSchoolsData);
      }
    };

    // After everthing is loaded, fire some callbacks
    setFormValuesAsync().then(() => {
      setFormValuesLoading(false);
      setInitialFormValuesLoaded(true);
    });
  }, [
    doverCompanyListsOptions,
    companySizesOptions,
    diversityOptions,
    educationLevels,
    formValuesLoaded,
    locationsOptions,
    doverSchoolListsOptions,
    mySchoolListsOptions,
    search,
    seniorityOptions,
    setInitialFormValuesLoaded,
    setValue,
    supportedFieldsOfStudy,
    lazyListPersonas,
    lazyListCompanies,
    lazyListIndustries,
    lazyListSchools,
    lazyListKeywords,
    lazyListExcludedSchools,
    lazyListExcludedIndustries,
    lazyListExcludedCompanies,
    lazyListDeniedKeywords,
    deniedKeywordsData,
    individualCompaniesData,
    individualExcludedCompaniesData,
    schoolsData,
    excludedSchoolsData,
    industriesData,
    excludedIndustriesData,
    listPersonasData,
    formValuesLoading,
    setFormValuesLoading,
    lazyGetKeywordsBuckets,
    keywordBucketsData,
  ]);
}

export const useSetFormDirty = (
  formDirty: boolean,
  setFormDirty: (dirty: boolean) => void,
  initialFormValuesLoaded: boolean,
  setInitialFormValuesLoaded?: (loaded: boolean) => void
): void => {
  const search = useGetSearchFromUrl();

  const initiallyLoadedValues = React.useRef<SearchV3FormSchemaType | undefined>(undefined);

  // Sets up the initial form state and auto-validates using zod
  const { control } = useFormContext<SearchV3FormSchemaType>();

  // Keep track of when form values change
  const values = useWatch({ control });

  const currentInitiallyLoadedValue = initiallyLoadedValues.current;

  // Every time the search changes, set the form state as clean
  React.useEffect(() => {
    if (search?.id) {
      setFormDirty(false);
      if (setInitialFormValuesLoaded) {
        setInitialFormValuesLoaded(false);
      }

      initiallyLoadedValues.current = undefined;
    }
  }, [search?.id, setFormDirty, setInitialFormValuesLoaded]);

  // After initial form load, store the original values
  React.useEffect(() => {
    if (!initialFormValuesLoaded) {
      return;
    }

    const formParseResult = searchV3FormSchema.safeParse(values);
    if (!formParseResult.success) {
      return;
    }
    if (!currentInitiallyLoadedValue) {
      initiallyLoadedValues.current = formParseResult.data;
    }
  }, [initialFormValuesLoaded, currentInitiallyLoadedValue, search, values]);

  React.useEffect(() => {
    // If the form parsing fails, return early
    // We'll handle errors at the top level
    const formParseResult = searchV3FormSchema.safeParse(values);
    if (!formParseResult.success) {
      return;
    }

    // Wait if form values aren't loaded yet
    if (!initialFormValuesLoaded || !currentInitiallyLoadedValue) {
      setFormDirty(false);
      return;
    }

    // If form values have loaded but changed, set form dirty
    if (currentInitiallyLoadedValue && !isEqual(currentInitiallyLoadedValue, values)) {
      setFormDirty(true);
    } else {
      setFormDirty(false);
    }
  }, [initiallyLoadedValues, initialFormValuesLoaded, search, setFormDirty, values, currentInitiallyLoadedValue]);
};
