import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { InfoTip } from "components/InfoTip";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useDisableCandidateActionsTooltipText } from "hooks/useDisableCandidateActionsTooltipText";
import { useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { CandidateBio, UpdateCandidateBioBodySchedulingOwnershipEnum } from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";

export function IllHandleButton({
  bio,
  variant = ButtonVariant.Secondary,
  fullWidth,
  justifyText = "center",
}: {
  bio?: CandidateBio;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  justifyText?: "center" | "flex-start";
}): React.ReactElement {
  const [updateBio] = useUpdateCandidateBioMutation();

  const candidateId = bio?.id;
  const jobId = bio?.job;

  const invalidCandidateActionPermsTooltip = useDisableCandidateActionsTooltipText(jobId);
  const invalidCandidateActionPerms = !!invalidCandidateActionPermsTooltip;

  const disabled = !candidateId || !jobId || invalidCandidateActionPerms;

  const clickIllHandle = useCallback(async () => {
    if (!candidateId || !jobId) {
      console.warn("No candidateId or jobId when trying to update scheduling ownership");
      return;
    }

    const args = {
      id: candidateId,
      jobId,
      hideToast: true,
      data: {
        schedulingOwnership: UpdateCandidateBioBodySchedulingOwnershipEnum.CustomerHandlesScheduling,
      },
    };

    const submitDecisionPromise = updateBio(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          pending: "Updating scheduling ownership...",
          success: "Updated!",
          error: "Error updating",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }, [candidateId, jobId, updateBio]);

  return (
    <Button
      variant={variant}
      onClick={clickIllHandle}
      disabled={disabled}
      tooltip={invalidCandidateActionPermsTooltip}
      width={fullWidth ? "100%" : undefined}
    >
      <Stack direction="row" spacing={1} justifyContent={justifyText} alignItems="center">
        <BodySmall color={colors.grayscale.gray600}>{`I'll handle from here`}</BodySmall>
        <InfoTip text={`You can select this option to email this candidate from your own email.`} />
      </Stack>
    </Button>
  );
}
