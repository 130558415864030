import { Box, Stack } from "@mui/material";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";

import { ReactComponent as UploadCloudSVG } from "assets/icons/upload-cloud.svg";
import { imageUploadErrorAtom } from "components/ImageUpload/atoms";
import { getUserFacingFileTypes } from "components/ImageUpload/utils";
import FileUploadField from "components/inputs/FileUpload";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";

export const ImageUploadField = ({
  submitting,
  acceptedFileTypes,
  errorKey,
}: {
  submitting: boolean;
  acceptedFileTypes: string[];
  errorKey: string;
}): React.ReactElement => {
  const form = useForm();
  const formState = useFormState();
  const [filename, setFilename] = useState(formState.values.files[0]?.name ?? "");
  const [imageUploadError, setImageUploadError] = useAtom(imageUploadErrorAtom);

  useEffect(() => {
    if (imageUploadError?.errorMessage) {
      showErrorToast(`Error uploading image: ${imageUploadError.errorMessage}`);
      setImageUploadError(undefined);
      return;
    }
  }, [imageUploadError, setImageUploadError]);

  useEffect(() => {
    const files = formState.values.files;
    if (files.length > 0 && files[0]?.name !== filename) {
      setFilename(files[0]?.name);
      form.submit();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [formState.values]);

  return (
    <Stack spacing={1}>
      <FileUploadField
        name={"files"}
        acceptFileTypes={acceptedFileTypes}
        dropzoneOptions={{ maxFiles: 1, disabled: submitting }}
        errorKey={errorKey}
        placeholder={
          formState.values.files[0] ? (
            <Stack height="100%" justifyContent="center" alignItems="center">
              <BodySmall>{formState.values.files[0]?.name}</BodySmall>
            </Stack>
          ) : (
            <Stack spacing={1} alignItems="center" justifyContent="center">
              <UploadCloudSVG className="svg-fill" color={colors.grayscale.gray500} />
              <BodySmall color={colors.grayscale.gray500} centered>
                Drag and drop file here or{" "}
                <Box sx={{ cursor: "pointer", color: colors.link, display: "inline" }}>browse computer</Box>
              </BodySmall>
            </Stack>
          )
        }
        placeholderWhenDragActive={
          <Stack height="100%" justifyContent="center" alignItems="center">
            <BodySmall>Drop file here</BodySmall>
          </Stack>
        }
      />
      <BodySmall color={colors.grayscale.gray500}>{`Supported file types: ${getUserFacingFileTypes(
        acceptedFileTypes
      )}`}</BodySmall>
    </Stack>
  );
};

export default ImageUploadField;
