import { ApiApiListCampaignsRequest } from "services/openapi";
import { ViewQueryParams } from "views/admin/Campaigns/types";

export const queryParamsToApiParams = (params: ViewQueryParams): ApiApiListCampaignsRequest => ({
  ordering: "-modified",
  limit: 50,
  offset: 0,
  state: params?.state || undefined,
  jobCampaignConfigJobClient: params?.client || undefined,
  jobCampaignConfigJob: params?.job || undefined,
  fullTextSearch: params?.q || undefined,
  jobCampaignConfigJobPersona: params?.persona || undefined,
});
