/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlimNextCampaignMessageRequest
 */
export interface SlimNextCampaignMessageRequest {
    /**
     * 
     * @type {Date}
     * @memberof SlimNextCampaignMessageRequest
     */
    sendAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SlimNextCampaignMessageRequest
     */
    campaignId?: string;
    /**
     * 
     * @type {string}
     * @memberof SlimNextCampaignMessageRequest
     */
    campaignMessageId?: string;
    /**
     * 
     * @type {string}
     * @memberof SlimNextCampaignMessageRequest
     */
    campaignState?: SlimNextCampaignMessageRequestCampaignStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SlimNextCampaignMessageRequest
     */
    emailSendRequestId?: string;
    /**
     * 
     * @type {string}
     * @memberof SlimNextCampaignMessageRequest
     */
    campaignMessageRequestId?: string;
    /**
     * 
     * @type {string}
     * @memberof SlimNextCampaignMessageRequest
     */
    emailOutreachStep?: SlimNextCampaignMessageRequestEmailOutreachStepEnum;
    /**
     * 
     * @type {string}
     * @memberof SlimNextCampaignMessageRequest
     */
    state?: SlimNextCampaignMessageRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SlimNextCampaignMessageRequest
     */
    readonly debugInfo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SlimNextCampaignMessageRequest
     */
    allowFollowUpsPostDeactivation?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum SlimNextCampaignMessageRequestCampaignStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED'
}/**
* @export
* @enum {string}
*/
export enum SlimNextCampaignMessageRequestEmailOutreachStepEnum {
    InitialOutreach = 'Initial outreach',
    Followup1 = 'Followup 1',
    Followup2 = 'Followup 2'
}/**
* @export
* @enum {string}
*/
export enum SlimNextCampaignMessageRequestStateEnum {
    InReview = 'IN_REVIEW',
    Queued = 'QUEUED',
    ManuallyQueued = 'MANUALLY_QUEUED',
    QueuedExternal = 'QUEUED_EXTERNAL',
    Sent = 'SENT',
    Sending = 'SENDING',
    Cancelled = 'CANCELLED',
    Failed = 'FAILED'
}

export function SlimNextCampaignMessageRequestFromJSON(json: any): SlimNextCampaignMessageRequest {
    return SlimNextCampaignMessageRequestFromJSONTyped(json, false);
}

export function SlimNextCampaignMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlimNextCampaignMessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sendAt': !exists(json, 'send_at') ? undefined : (new Date(json['send_at'])),
        'campaignId': !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
        'campaignMessageId': !exists(json, 'campaign_message_id') ? undefined : json['campaign_message_id'],
        'campaignState': !exists(json, 'campaign_state') ? undefined : json['campaign_state'],
        'emailSendRequestId': !exists(json, 'email_send_request_id') ? undefined : json['email_send_request_id'],
        'campaignMessageRequestId': !exists(json, 'campaign_message_request_id') ? undefined : json['campaign_message_request_id'],
        'emailOutreachStep': !exists(json, 'email_outreach_step') ? undefined : json['email_outreach_step'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'debugInfo': !exists(json, 'debug_info') ? undefined : json['debug_info'],
        'allowFollowUpsPostDeactivation': !exists(json, 'allow_follow_ups_post_deactivation') ? undefined : json['allow_follow_ups_post_deactivation'],
    };
}

export function SlimNextCampaignMessageRequestToJSON(value?: SlimNextCampaignMessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'send_at': value.sendAt === undefined ? undefined : (value.sendAt.toISOString()),
        'campaign_id': value.campaignId,
        'campaign_message_id': value.campaignMessageId,
        'campaign_state': value.campaignState,
        'email_send_request_id': value.emailSendRequestId,
        'campaign_message_request_id': value.campaignMessageRequestId,
        'email_outreach_step': value.emailOutreachStep,
        'state': value.state,
        'allow_follow_ups_post_deactivation': value.allowFollowUpsPostDeactivation,
    };
}


