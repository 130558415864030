import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { clientEndpoints } from "services/doverapi/endpoints/client/endpoints";
import { COMPANY_LOGO_URL_SENTINEL } from "services/doverapi/endpoints/company-setup/constants";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { performSideEffectForClientOnboardingUpdate } from "services/doverapi/endpoints/company-setup/utils";
import { CLIENT_ONBOARDING } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiGetCompanyPitchQuestionSchemaRequest,
  ApiApiListClientDomainsRequest,
  ApiApiPatchClientOnboardingRequest,
  BulkUpsertClientDomain,
  BulkUpsertClientDomainResponse,
  CareersPageDisplayStat,
  ClientDomain,
  ClientOnboarding,
  ClientSocialLink,
  CreateUserUploadedFileUploadedContextEnum,
  GenerateCareersPageRequest,
  GenerateCareersPageResponse,
  GetCompanyPitchQuestionSchemaResponse,
  SaveCompanyPitchQuestionSchema1,
  SaveCompanyPitchQuestionSchemaSetupStateEnum,
} from "services/openapi";
import { showErrorToast, showSuccessToast } from "utils/showToast";

interface UpdateCareersPageDisplayStatsArgs {
  id: string;
  careersPageDisplayStats?: CareersPageDisplayStat[];
  careersPageSocialLinks?: ClientSocialLink[];
}

const companySetupEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    updateCareersPageDisplayStats: build.mutation<ClientOnboarding, UpdateCareersPageDisplayStatsArgs>({
      queryFn: async ({ id, careersPageDisplayStats, careersPageSocialLinks }) => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.patchClientOnboarding({
            id,
            data: {
              careersPageDisplayStats,
              careersPageSocialLinks,
            },
          });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update careers page display stats");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      onQueryStarted: async ({ careersPageDisplayStats, careersPageSocialLinks }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          clientEndpoints.util.updateQueryData("getClientOnboarding", undefined, draft => {
            if (draft) {
              draft.careersPageDisplayStats = careersPageDisplayStats;
              draft.careersPageSocialLinks = careersPageSocialLinks;
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          // Undo our optimistic updates if the mutation ended up failing server-side
          patch.undo();
        }
      },
    }),
    updateClientOnboarding: build.mutation<
      ClientOnboarding,
      ApiApiPatchClientOnboardingRequest & {
        logoUrl?: Blob | string | null;
        careersPageImageUrl?: Blob | null;
        onboardingSection: OnboardingSection;
      }
    >({
      queryFn: async ({ onboardingSection, id, data, logoUrl, careersPageImageUrl }) => {
        const { apiApi } = await getOpenApiClients({});
        let transformedData = { ...data };

        try {
          // If the logoUrl is a string, it's already uploaded to GCS
          if (logoUrl && typeof logoUrl !== "string") {
            try {
              await apiApi.uploadLogo({ file: logoUrl });
            } catch (error) {
              showErrorToast("Failed to upload logo");

              return {
                error: {
                  serializedError: error as SerializedError,
                },
              };
            }
          } else if (logoUrl === null) {
            transformedData = { ...transformedData, logoUrl: COMPANY_LOGO_URL_SENTINEL };
          }

          if (careersPageImageUrl) {
            // set the new image
            await apiApi.createUserUploadedFile({
              fileContents: careersPageImageUrl as Blob,
              uploadedContext: CreateUserUploadedFileUploadedContextEnum.CareersPageImage,
            });
          } else if (careersPageImageUrl === null) {
            // delete the image
            transformedData = { ...transformedData, careersPageImageId: null };
          }

          const response = await apiApi.patchClientOnboarding({ id, data: transformedData });
          performSideEffectForClientOnboardingUpdate({
            onboardingSection,
            response,
          });

          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return args.id ? [{ type: CLIENT_ONBOARDING, id: args.id }] : [];
      },
    }),
    updateCompanyBio: build.mutation<
      SaveCompanyPitchQuestionSchema1,
      {
        answers: any;
        clientId?: string;
        showToast?: boolean;
        setupState?: SaveCompanyPitchQuestionSchemaSetupStateEnum;
      }
    >({
      queryFn: async ({ answers, setupState, showToast = true }) => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.saveCompanyPitchQuestionSchema({ data: { answers, setupState } });
          if (showToast) {
            showSuccessToast("Biography saved");
          }
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to save company info");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return args.clientId
          ? [{ type: CLIENT_ONBOARDING, id: args.clientId }, { type: CLIENT_ONBOARDING } as const]
          : [];
      },
    }),
    listClientDomains: build.query<ClientDomain[], ApiApiListClientDomainsRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          // TODO: use the list entity adapter
          const response = await apiApi.listClientDomains(args);
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to list domains. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: CLIENT_ONBOARDING } as const] : [];
      },
    }),
    bulkUpsertClientDomains: build.mutation<BulkUpsertClientDomainResponse, BulkUpsertClientDomain>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.bulkUpsertClientDomains({ data: args });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update domains.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: CLIENT_ONBOARDING }] : [];
      },
    }),
    generateCareersPage: build.mutation<GenerateCareersPageResponse, GenerateCareersPageRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.generateCareersPage({ data: args });
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: CLIENT_ONBOARDING, id: result.client.id }] : [];
      },
    }),
    getCompanyPitchQuestions: build.query<
      GetCompanyPitchQuestionSchemaResponse,
      ApiApiGetCompanyPitchQuestionSchemaRequest
    >({
      queryFn: async ({ clientId }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.getCompanyPitchQuestionSchema({ clientId });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      providesTags: result => {
        return result ? [{ type: CLIENT_ONBOARDING } as const] : [];
      },
    }),
  }),
});

export const {
  useUpdateClientOnboardingMutation,
  useUpdateCareersPageDisplayStatsMutation,
  useUpdateCompanyBioMutation,
  useListClientDomainsQuery,
  useBulkUpsertClientDomainsMutation,
  useGenerateCareersPageMutation,
  useGetCompanyPitchQuestionsQuery,
} = companySetupEndpoints;
