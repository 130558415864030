import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";

interface Props {
  children: React.ReactNode;
}

const MoreOptionsMenu = ({ children }: Props): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant={ButtonVariant.Ghost} onClick={handleClick}>
        <MoreHorizIcon style={{ color: "#000000" }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {children && React.Children.map(children, (item, index) => <MenuItem key={index}>{item}</MenuItem>)}
      </Menu>
    </div>
  );
};

export default MoreOptionsMenu;
