import KeyboardCommandKeyIcon from "@mui/icons-material/KeyboardCommandKey";
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import DoverCommandBar from "App/components/Nav/CommandBar";
import { INVITE_TEAM_QUERY_PARAM } from "App/components/Nav/constants";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as MagnifyingGlassIcon } from "assets/icons/magnifying-glass.svg";
import { CREATE_JOB_DRAWER_OPEN_PARAM } from "components/dover/CreateJob/constants";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useOpenApiClients } from "hooks/openApiClients";
import { ADD_CANDIDATE_DRAWER_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { addCandidateV2DrawerAtom } from "sections/addCandidateV2";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useAuth0 } from "services/react-auth0-spa";
import { colors } from "styles/theme";

// Setup commandbar on mount
export const SearchBarV3 = (): React.ReactElement => {
  const navigate = useNavigate();
  const apiClient = useOpenApiClients()?.apiApi;
  const { data: client } = useGetUsersClientQuery();
  const isBasePlanCustomer = useIsBasePlanCustomer();
  const { open } = useModal(addCandidateV2DrawerAtom);

  const [, setCreateJobDrawerOpen] = useQueryParam(CREATE_JOB_DRAWER_OPEN_PARAM, BooleanParam);
  const [, setIsOpenAddCandidateDrawerParam] = useQueryParam(ADD_CANDIDATE_DRAWER_QUERY_PARAM, BooleanParam);
  const [, setInviteNewUsersModalOpen] = useQueryParam(INVITE_TEAM_QUERY_PARAM, BooleanParam);
  const { user } = useAuth0();

  useEffect(() => {
    if (apiClient && client) {
      DoverCommandBar.getInstance(
        navigate,
        apiClient,
        user.sub,
        (): void => setCreateJobDrawerOpen(true),
        (): void => open({}),
        (): void => setInviteNewUsersModalOpen(true),
        (): void => {
          if (isBasePlanCustomer) {
            navigate(APP_ROUTE_PATHS.embeddedRecruiter());
          } else {
            window.open(`https://dovertalent.slack.com/archives/${client?.adminChannelId}`, "_blank");
          }
        }
      ).register();
    }
  }, [
    apiClient,
    navigate,
    setCreateJobDrawerOpen,
    setIsOpenAddCandidateDrawerParam,
    setInviteNewUsersModalOpen,
    client,
    isBasePlanCustomer,
    user,
    open,
  ]);

  return (
    <Button
      variant={ButtonVariant.Secondary}
      width="100%"
      padding="0px 16px"
      onClick={(): void => window?.CommandBar?.open()}
    >
      <Stack direction="row" spacing={0.5} justifyContent="space-between" alignItems="center">
        <MagnifyingGlassIcon height="16px" width="16px" />
        <Stack direction="row" alignItems="center">
          <KeyboardCommandKeyIcon sx={{ color: colors.grayscale.gray500, fontSize: "16px" }} />
          <BodySmall color={colors.grayscale.gray500}>+K</BodySmall>
        </Stack>
      </Stack>
    </Button>
  );
};

export default (): React.ReactElement => {
  return <SearchBarV3 />;
};
