import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { BooleanParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import { ReactComponent as PlusIcon } from "assets/icons/add.svg";
import { Button, ButtonProps, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { ADD_CANDIDATE_DRAWER_QUERY_PARAM, FLOW_TYPE_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { FlowTypeParam, FlowTypes } from "sections/addcandidate/types";
import { addCandidateV2DrawerAtom } from "sections/addCandidateV2";
import { colors } from "styles/theme";

interface AddCandidateButtonProps {
  buttonProps?: ButtonProps;
  iconOnly?: boolean;
  hiringPipelineStageId?: string;
  label?: string;
}

export const useHandleAddCandidate = (): (() => void) => {
  const useNewAddCandidate = useFeatureFlag(FeatureFlag.SimplifiedAddCandidate);
  const { open } = useModal(addCandidateV2DrawerAtom);

  const [, setIsOpenAddCandidateDrawerParam] = useQueryParam(
    ADD_CANDIDATE_DRAWER_QUERY_PARAM,
    withDefault(BooleanParam, false)
  );
  const [, setFlowTypeParam] = useQueryParam(FLOW_TYPE_QUERY_PARAM, FlowTypeParam);

  const addCandidateLegacy = useCallback((): void => {
    setFlowTypeParam(FlowTypes.INTERVIEW_PIPELINE);
    setIsOpenAddCandidateDrawerParam(true);
  }, [setFlowTypeParam, setIsOpenAddCandidateDrawerParam]);

  const addCandidateNew = useCallback((): void => {
    open({});
  }, [open]);

  return useNewAddCandidate ? addCandidateNew : addCandidateLegacy;
};

const AddCandidateButton = ({
  buttonProps = { variant: ButtonVariant.GhostPrimary },
  iconOnly,
  hiringPipelineStageId,
  label = "Candidate",
}: AddCandidateButtonProps): React.ReactElement => {
  const handleAddCandidate = useHandleAddCandidate();
  const [, setHpsIdParam] = useQueryParam("hpsId", StringParam);

  const onClick = useCallback((): void => {
    if (hiringPipelineStageId) {
      setHpsIdParam(hiringPipelineStageId);
    }
    handleAddCandidate();
  }, [hiringPipelineStageId, setHpsIdParam, handleAddCandidate]);

  return (
    <Button {...buttonProps} onClick={onClick}>
      <Stack spacing={0.5} direction="row" alignItems="center">
        <PlusIcon width="18px" height="18px" />
        {!iconOnly && (
          <BodySmall weight="400" color={colors.grayscale.gray600}>
            {label}
          </BodySmall>
        )}
      </Stack>
    </Button>
  );
};

export default AddCandidateButton;
