import { z } from "zod";

export const submitAgencyCandidateFormSchema = z.object({
  // agency recruiter info
  referrerId: z.string().min(1, { message: "Required" }),
  // candidate info
  jobId: z.string().min(1, { message: "Required" }),
  candidateFirstName: z.string().min(1, { message: "Required" }),
  candidateLastName: z.string().min(1, { message: "Required" }),
  candidateEmail: z
    .string()
    .min(1, "Required")
    .email({ message: "Invalid email" }),
  candidateLinkedinUrl: z
    .string()
    .regex(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/.+/, {
      message: "Invalid LinkedIn URL",
    })
    .max(200, { message: "LinkedIn URL cannot be more than 200 characters long" })
    .optional()
    .or(z.literal("")),
  resume: z.nullable(z.instanceof(File)).optional(),
  additionalComments: z
    .string()
    .max(5000, { message: "Additional comments cannot be more than 5000 characters long" })
    .optional(),
});

export type SubmitAgencyCandidateFormSchemaType = z.infer<typeof submitAgencyCandidateFormSchema>;
