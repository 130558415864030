import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { RECRUITING_PARTNER } from "services/doverapi/endpointTagsConstants";
import { RecruitingPartnerListResponse } from "services/openapi";

export const recruitingPartnerEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listRecruitingPartners: build.query<RecruitingPartnerListResponse, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.listRecruitingPartners({});
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: RECRUITING_PARTNER } as const] : [];
      },
    }),
  }),
});

export const { useListRecruitingPartnersQuery } = recruitingPartnerEndpoints;
