import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { Centered } from "components/Centered";
import InviteNewUsersForm from "components/InviteNewUsers";
import { useInviteNewUsersForm } from "components/InviteNewUsers/hooks";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Heading } from "components/library/typography";
import { screenSizesNumbers } from "styles/theme";

export const InviteNewUsersStep = ({
  goNext,
  isLoading,
}: {
  goNext: () => Promise<void>;
  isLoading?: boolean;
}): React.ReactElement => {
  const {
    customEmailMessage,
    emailAddresses,
    setEmailAddresses,
    setCustomEmailMessage,
    emailAddressesAreValid,
    sendInvitations,
    inputValue,
    setInputValue,
    submitDisabled,
  } = useInviteNewUsersForm();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tablet));

  const onSaveAndNext = async (): Promise<void> => {
    const result = await sendInvitations();
    if (result.success) {
      await goNext();
    }
  };

  return (
    <Centered>
      <Card
        style={{
          width: "100%",
          maxWidth: "580px",
          minWidth: smallScreen ? "100vw" : "580px",
        }}
      >
        <Stack spacing={2}>
          <Heading centered>Invite your team to Dover</Heading>
          <InviteNewUsersForm
            emailAddresses={emailAddresses}
            setEmailAddresses={setEmailAddresses}
            customEmailMessage={customEmailMessage}
            setCustomEmailMessage={setCustomEmailMessage}
            emailAddressesAreValid={emailAddressesAreValid}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button
              variant={ButtonVariant.Secondary}
              onClick={async (): Promise<void> => await goNext()}
              disabled={isLoading}
            >
              {"I'll do this later"}
            </Button>
            <Button variant={ButtonVariant.Primary} onClick={onSaveAndNext} disabled={submitDisabled || isLoading}>
              Invite
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Centered>
  );
};
