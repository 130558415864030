import React from "react";
import styled from "styled-components";

interface StickyDivProps {
  children: React.ReactNode;
  top: number | string;
}

const StickyDivStyled = styled.div`
  position: sticky;
  overflow: auto;
  width: inherit;
`;

const StickyDiv: React.FC<StickyDivProps> = ({ children, top }) => {
  const topValue = typeof top === "number" ? `${top}px` : top;
  return <StickyDivStyled style={{ top: topValue }}>{children}</StickyDivStyled>;
};

export default StickyDiv;
