/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarkOnboardingCompleteRequest
 */
export interface MarkOnboardingCompleteRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MarkOnboardingCompleteRequest
     */
    force?: boolean;
}

export function MarkOnboardingCompleteRequestFromJSON(json: any): MarkOnboardingCompleteRequest {
    return MarkOnboardingCompleteRequestFromJSONTyped(json, false);
}

export function MarkOnboardingCompleteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkOnboardingCompleteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'force': !exists(json, 'force') ? undefined : json['force'],
    };
}

export function MarkOnboardingCompleteRequestToJSON(value?: MarkOnboardingCompleteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'force': value.force,
    };
}


