import { Slider } from "@mui/material";
import styled from "styled-components";

import { colors } from "styles/theme";

interface StyledSliderProps {
  $invertTrack?: boolean;
}

export const StyledSlider = styled(Slider)<StyledSliderProps>`
  .MuiSlider-rail {
    color: ${(props): string => (props.$invertTrack ? colors.brand : colors.grayscale.gray300)};
    opacity: 1;
  }

  .MuiSlider-track {
    color: ${(props): string => (props.$invertTrack ? colors.grayscale.gray300 : colors.brand)};
    background-color: ${(props): string => (props.$invertTrack ? colors.grayscale.gray300 : colors.brand)};
    border: ${(props): string =>
      props.$invertTrack ? "1px solid " + colors.grayscale.gray300 : "1px solid " + colors.brand};
    opacity: 1;
  }

  .MuiSlider-thumb {
    color: ${colors.primary.base};
    width: 15px;
    height: 15px;

    :hover,
    &.Mui-active {
      box-shadow: none !important;
    }
  }

  .MuiSlider-mark {
    top: 30px;
    background-color: ${colors.grayscale.gray300};
    height: 8px;
  }
`;
