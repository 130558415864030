import { Stack } from "@mui/material";
import React, { useMemo } from "react";

import { BodySmall } from "components/library/typography";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { SubmitFeedbackButton } from "views/CandidateDetail/components/next-action/states/SubmitFeedbackButton";
import {
  NextActionStateComponentProps,
  defaultSchedulingText,
  colorsMap,
} from "views/CandidateDetail/components/next-action/utils/constants";

export function UserIsSchedulingInterviewNew({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  const description = useMemo(() => {
    return (
      <Stack spacing={1}>
        <BodySmall>{defaultSchedulingText}</BodySmall>
        <SubmitFeedbackButton candidateBio={candidateBio} />
      </Stack>
    );
  }, [candidateBio]);

  return <NextActionCardSkeleton title={"Scheduling"} colors={colorsMap.blue} description={description} />;
}
