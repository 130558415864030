import { Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Overline } from "components/library/typography";
import { colors } from "styles/theme";

interface ChipProps {
  variant: "Primary" | "Informational" | "Warning" | "Critical" | "Gray" | "Transparent";
  label: string;
  icon?: React.ReactNode;
}

export const Chip: React.FC<ChipProps> = ({ variant = "Primary", label, icon }) => {
  return (
    <ChipStyle variant={variant}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        {icon && icon}
        <Overline color={labelColors[variant]}>{label}</Overline>
      </Stack>
    </ChipStyle>
  );
};

const ChipStyle = styled.div<Pick<ChipProps, "variant">>`
  background: ${(props): string => backgroundColors[props.variant]};
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
`;

const backgroundColors = {
  Primary: colors.primary.light,
  Informational: colors.informational.light,
  Warning: colors.warning.light,
  Critical: colors.critical.light,
  Gray: colors.grayscale.gray100,
  Transparent: "transparent",
};

const labelColors = {
  Primary: colors.primary.hover,
  Informational: colors.informational.dark,
  Warning: colors.warning.dark,
  Critical: colors.critical.dark,
  Gray: colors.grayscale.gray600,
  Transparent: colors.grayscale.gray600,
};
