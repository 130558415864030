/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";

import { GmailAuthContent, GmailAuthHelpText } from "components/dover/GmailAuth";
import { useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import CustomModal from "components/Modal";
import { useListCampaignsQuery, selectFromListCampaignsQueryResult } from "services/doverapi/endpoints/campaign";
import { SlimEmailSenderAliasGmailCredentialAuthStateEnum } from "services/openapi";

interface GmailAuthModalProps {
  jobId: string;
  selectedCampaignId?: string;
  isModalOpen: boolean;
  onModalClose: () => void;
}

export const GmailAuthModal = React.memo(
  ({ jobId, selectedCampaignId, isModalOpen, onModalClose }: GmailAuthModalProps): React.ReactElement => {
    const { selectedCampaign } = useListCampaignsQuery(
      { jobId },
      {
        selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults, { selectedCampaignId }),
        pollingInterval: isModalOpen ? 6000 : 0,
      }
    );
    const { userAuthedSuccessfully } = useUserAuthedSuccessfully(!isModalOpen);

    // If this modal is opened due to a campaign, then close the modal if any of the gmail aliases on the job's campaigns become valid
    React.useEffect(() => {
      if (
        selectedCampaign?.emailAlias?.gmailCredentialAuthState ===
        SlimEmailSenderAliasGmailCredentialAuthStateEnum.Valid
      ) {
        onModalClose();
      }
    }, [selectedCampaign?.emailAlias?.gmailCredentialAuthState]);

    // If the user's auth becomes valid, then close the modal
    React.useEffect(() => {
      if (userAuthedSuccessfully) {
        onModalClose();
      }
    }, [userAuthedSuccessfully]);

    return (
      <CustomModal
        open={isModalOpen}
        onClose={(): void => {
          onModalClose();
        }}
        title={"Email Authentication"}
        maxWidth={"sm"}
      >
        <>
          <GmailAuthHelpText textAlign="center" />
          <GmailAuthContent />
        </>
      </CustomModal>
    );
  }
);
