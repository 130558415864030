import { Menu, MenuItem, styled } from "@mui/material";
import React from "react";

export const EditDropdownMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    display: block;
    padding: 8px;
  }
`;

interface DropdownButtonMenuProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  options: React.ReactElement[];
}

export const DropdownButtonMenu = React.memo(
  ({ anchorEl, handleClose, options }: DropdownButtonMenuProps): React.ReactElement => {
    const editMenuOpen = Boolean(anchorEl);

    return (
      <Menu
        open={editMenuOpen}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            maxHeight: 400,
          },
        }}
      >
        {options}
      </Menu>
    );
  }
);
