/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NextAction,
    NextActionFromJSON,
    NextActionFromJSONTyped,
    NextActionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidateNextAction
 */
export interface CandidateNextAction {
    /**
     * 
     * @type {string}
     * @memberof CandidateNextAction
     */
    readonly id?: string;
    /**
     * 
     * @type {NextAction}
     * @memberof CandidateNextAction
     */
    nextAction?: NextAction;
    /**
     * 
     * @type {string}
     * @memberof CandidateNextAction
     */
    schedulingOwnership?: CandidateNextActionSchedulingOwnershipEnum;
}

/**
* @export
* @enum {string}
*/
export enum CandidateNextActionSchedulingOwnershipEnum {
    DoverHandlesScheduling = 'DOVER_HANDLES_SCHEDULING',
    CustomerHandlesScheduling = 'CUSTOMER_HANDLES_SCHEDULING'
}

export function CandidateNextActionFromJSON(json: any): CandidateNextAction {
    return CandidateNextActionFromJSONTyped(json, false);
}

export function CandidateNextActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateNextAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nextAction': !exists(json, 'next_action') ? undefined : NextActionFromJSON(json['next_action']),
        'schedulingOwnership': !exists(json, 'scheduling_ownership') ? undefined : json['scheduling_ownership'],
    };
}

export function CandidateNextActionToJSON(value?: CandidateNextAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_action': NextActionToJSON(value.nextAction),
        'scheduling_ownership': value.schedulingOwnership,
    };
}


