import { Stack } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import CustomModal from "components/Modal";
import { B1_doNotUse } from "styles/typography";

const DeactivateFeatureConfirmationModal = ({
  isModalOpen,
  title,
  body,
  toggleModalOff,
  onConfirmDisable,
}: {
  isModalOpen: boolean;
  title: string;
  body: string | React.ReactNode;
  toggleModalOff: () => void;
  onConfirmDisable: () => void;
}): React.ReactElement => {
  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={(): void => {
          toggleModalOff();
        }}
        title={<B1_doNotUse bold>{title}</B1_doNotUse>}
        maxWidth={"xs"}
        omitDividers={true}
        customDialogStyles={{ zIndex: 9999 }}
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Secondary} onClick={toggleModalOff}>
              Cancel
            </Button>
            <Button variant={ButtonVariant.SecondaryCritical} onClick={onConfirmDisable}>
              Disable
            </Button>
          </Stack>
        }
      >
        <B1_doNotUse>{body}</B1_doNotUse>
      </CustomModal>
    </>
  );
};

export default DeactivateFeatureConfirmationModal;
