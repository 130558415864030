import { Stack } from "@mui/material";
import React from "react";

import { BodySmall } from "components/library/typography";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import { isMultipartStage } from "utils/isStage";
import { ConciergeProfile } from "views/CandidateDetail/components/next-action/ConciergeProfile";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { SubmitFeedbackButton } from "views/CandidateDetail/components/next-action/states/SubmitFeedbackButton";
import {
  NextActionStateComponentProps,
  colorsMap,
  defaultSchedulingText,
} from "views/CandidateDetail/components/next-action/utils/constants";

export function ConciergeScheduling({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  const hiringPipelineStage = candidateBio.nextAction?.hiringPipelineStage;

  // NOTE: We may introduce other interview stage types in the future.
  // This may not handle things correctly.
  const description =
    hiringPipelineStage && isMultipartStage(hiringPipelineStage) ? (
      <ConciergeSchedulingMultipartDescription candidateBio={candidateBio} />
    ) : (
      <ConciergeSchedulingDescription candidateBio={candidateBio} />
    );

  return <NextActionCardSkeleton title={"Dover Scheduling"} description={description} colors={colorsMap.blue} />;
}

const ConciergeSchedulingDescription = ({ candidateBio }: NextActionStateComponentProps): React.ReactElement => {
  return (
    <Stack spacing={2}>
      <ConciergeProfile slackUrl={candidateBio.slackUrl} />
      <BodySmall>
        {defaultSchedulingText} Candidates scheduling via Dover Scheduling links will also receive up to two scheduling
        reminders.
      </BodySmall>
      <SubmitFeedbackButton candidateBio={candidateBio} />
    </Stack>
  );
};

const ConciergeSchedulingMultipartDescription = ({
  candidateBio,
}: NextActionStateComponentProps): React.ReactElement => {
  const conciergeInfo = useConciergeInfo();
  const fullName = candidateBio?.contact.fullName;

  return (
    <Stack spacing={2}>
      <ConciergeProfile slackUrl={candidateBio.slackUrl} />
      <BodySmall>
        {conciergeInfo?.firstName} is currently scheduling {fullName} for the{" "}
        {candidateBio.nextAction?.hiringPipelineStage?.name}.
      </BodySmall>
      <SubmitFeedbackButton candidateBio={candidateBio} />
    </Stack>
  );
};
