import { Box } from "@mui/material";
import React from "react";

import InstructionList from "components/instructions/components/InstructionList";
import {
  CardListItem,
  CardListItemWrapper,
  ModalLink,
  StyledModal,
  StyledModalHeader,
} from "components/instructions/styles";
import { InstructionListItem } from "components/instructions/types";
import { Spacer } from "components/Spacer";
import { ExternalLink } from "styles/typography";

interface InstructionsProps {
  openModalKey?: string;
  setOpenModal?: () => void;
  setCloseModal?: () => void;
  cardHeader: React.ReactNode;
  modalKey?: string;
  modalHeader?: React.ReactNode;
  modalInstructionItems?: readonly InstructionListItem[];
  cardInstructionBody?: React.ReactNode;
  instructionsLink?: string;
}

const Instruction = ({
  openModalKey,
  setOpenModal,
  setCloseModal,
  cardHeader,
  modalKey,
  modalHeader,
  modalInstructionItems,
  cardInstructionBody,
  instructionsLink,
}: InstructionsProps): React.ReactElement => {
  return (
    <CardListItemWrapper>
      <CardListItem>
        <Box display="flex" justifyContent="space-between">
          <Box>{cardHeader}</Box>
          {modalKey && setOpenModal && !instructionsLink && (
            <ModalLink
              onClick={(): void => {
                setOpenModal();
              }}
            >
              View Instructions
            </ModalLink>
          )}
          {instructionsLink && (
            <ExternalLink
              href={instructionsLink}
              style={{ fontSize: "14px" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Instructions
            </ExternalLink>
          )}
        </Box>
        <Box pr={4}>{cardInstructionBody}</Box>
        {modalHeader && setCloseModal && (
          <StyledModal
            zIndex={1500}
            isOpen={openModalKey === modalKey}
            onClose={(): void => setCloseModal()}
            labelledBy={modalKey}
            toggle={(): void => setCloseModal()}
          >
            <StyledModalHeader toggle={(): void => setCloseModal()}>{modalHeader}</StyledModalHeader>
            <Spacer height="24px" />
            {modalInstructionItems && <InstructionList listItems={modalInstructionItems} />}
          </StyledModal>
        )}
      </CardListItem>
    </CardListItemWrapper>
  );
};

export default Instruction;
