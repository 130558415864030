import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { CLIENT_ROLES, PRO_USER, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import {
  DoverRole,
  AuthedUser,
  UserRolesAndPermissions,
  ApiApiPartialUpdateRoleRequest,
  ApiApiUpdateUserClientRoleOperationRequest,
  UpdateClientRoleResponse,
  SetUserToBlockedResponse,
} from "services/openapi";
import { showErrorToast, showSuccessToast } from "utils/showToast";

interface SetUserBlockedProps {
  user: string;
  blocked: boolean;
}

const userPermsEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listRoles: build.query<DoverRole[], void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listRoles({});
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load permissions");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: CLIENT_ROLES, id: LIST_TAG } as const] : [];
      },
    }),
    listAuthenticatedUsers: build.query<AuthedUser[], void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listAuthenticatedUsers({});
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to load users.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: PRO_USER, id: LIST_TAG } as const] : [];
      },
    }),
    getUserRolesAndPermissions: build.query<UserRolesAndPermissions, void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.getUserRolesAndPermissions({});
          return { data: response };
        } catch (error) {
          const userFacingMessage = "Failed to retrieve user roles and permissions";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: PRO_USER }] : [];
      },
    }),
    updateDefaultClientRole: build.mutation<DoverRole, ApiApiPartialUpdateRoleRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const result = await apiApi.partialUpdateRole(args);
          showSuccessToast("Successfully updated default permissions.");
          return { data: result };
        } catch (error) {
          showErrorToast("Failed to update default permissions.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: CLIENT_ROLES, id: LIST_TAG }] : [];
      },
    }),
    setUserBlockedFlag: build.mutation<SetUserToBlockedResponse, SetUserBlockedProps>({
      queryFn: async args => {
        const blockOrUnblockText = args.blocked ? "block" : "unblock";
        try {
          const { apiApi } = await getOpenApiClients({});
          const result = await apiApi.setUserBlockedFlag({ user: args.user, data: { blocked: args.blocked } });
          showSuccessToast(`Successfully ${blockOrUnblockText}ed user.`);
          return { data: result };
        } catch (error) {
          showErrorToast(`Failed to ${blockOrUnblockText} user.`);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result?.userId !== undefined
          ? [
              { type: PRO_USER, id: LIST_TAG },
              { type: PRO_USER, id: result.userId },
            ]
          : result
          ? [{ type: PRO_USER, id: LIST_TAG }]
          : [];
      },
    }),
    updateUserClientRole: build.mutation<UpdateClientRoleResponse, ApiApiUpdateUserClientRoleOperationRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.updateUserClientRole(args);
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result && result.userId
          ? [
              { type: PRO_USER, id: LIST_TAG },
              { type: PRO_USER, id: result.userId },
            ]
          : result
          ? [{ type: PRO_USER, id: LIST_TAG }]
          : [];
      },
    }),
  }),
});

export const {
  useListRolesQuery,
  useUpdateDefaultClientRoleMutation,
  useUpdateUserClientRoleMutation,
  useListAuthenticatedUsersQuery,
  useGetUserRolesAndPermissionsQuery,
  useSetUserBlockedFlagMutation,
} = userPermsEndpoints;
