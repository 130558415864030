/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    V3ExtraDetail,
    V3ExtraDetailFromJSON,
    V3ExtraDetailFromJSONTyped,
    V3ExtraDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchV3ExplainedFilter
 */
export interface SearchV3ExplainedFilter {
    /**
     * 
     * @type {string}
     * @memberof SearchV3ExplainedFilter
     */
    filterName: string;
    /**
     * 
     * @type {V3ExtraDetail}
     * @memberof SearchV3ExplainedFilter
     */
    extraDetails: V3ExtraDetail;
}

export function SearchV3ExplainedFilterFromJSON(json: any): SearchV3ExplainedFilter {
    return SearchV3ExplainedFilterFromJSONTyped(json, false);
}

export function SearchV3ExplainedFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchV3ExplainedFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filterName': json['filter_name'],
        'extraDetails': V3ExtraDetailFromJSON(json['extra_details']),
    };
}

export function SearchV3ExplainedFilterToJSON(value?: SearchV3ExplainedFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter_name': value.filterName,
        'extra_details': V3ExtraDetailToJSON(value.extraDetails),
    };
}


