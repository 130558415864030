import { DragDropContext, OnBeforeDragStartResponder, OnDragEndResponder } from "@hello-pangea/dnd";
import { Box, Stack } from "@mui/material";
import React, { FC, useState } from "react";
import { useBoolean } from "react-use";

import { ReactComponent as XRedCircle } from "assets/icons/x-red-circle.svg";
import { Body } from "components/library/typography";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { HiringPipelineStage, JobFeatureFeatureNameEnum } from "services/openapi";
import { EditStageDrawerWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import { InterestedStageRow } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/InterestedStageRow";
import { SchedulingContainer } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/SchedulingContainer";
import {
  StageCard,
  StageRow,
  StageRowsContainer,
} from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/Stage";
import {
  useInterestedStages,
  useInterviewPlanStages,
} from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useInterviewPlanStages";
import { useUpdateHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useUpdateHps";
import { InterviewersPendingSetup } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewersPendingSetup";
import { LoadingSkeleton } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/LoadingSkeleton";
import { InterviewPlanSchedulingSettings } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/SchedulingSettings";

// Loads in the interview plan data and handles error and loading states before rendering InterviewPlan
export const InterviewPlanData: FC = () => {
  const { stages: interviewingStages, isLoading, isError } = useInterviewPlanStages();
  const { stages: interestedStages } = useInterestedStages();

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (isError || !interviewingStages || !interestedStages) {
    return (
      <Stack spacing={1} alignItems="center" mt="3rem">
        <XRedCircle height="48px" width="48px" />
        <Body>Error loading interview plan data. Please refresh to try again.</Body>
      </Stack>
    );
  }

  return <InterviewPlan interestedStages={interestedStages} interviewingStages={interviewingStages} />;
};

interface SchedulingProps {
  interestedStages: HiringPipelineStage[];
  interviewingStages: HiringPipelineStage[];
}

// The main interview plan component
const InterviewPlan: FC<SchedulingProps> = ({ interestedStages, interviewingStages }) => {
  const [selectedStage, setSelectedStage] = useState<HiringPipelineStage | undefined>(undefined);
  const [isDragging, toggleDragging] = useBoolean(false);

  const updateHps = useUpdateHps();

  const onDragStart: OnBeforeDragStartResponder = () => {
    toggleDragging(true);
  };

  const onDragEnd: OnDragEndResponder = ({ destination, source }) => {
    toggleDragging(false);

    // If there is no destination we don't need to do anything
    if (!destination) return;

    // If the destination is the same as the source we don't need to do anything
    if (source.droppableId === destination.droppableId && destination.index === source.index) return;

    // We always want the orderIndex we send to the backend in our update to be
    // the orderIndex of the stage located just after the stage we just moved.
    // This is because the backend will automatically bump up any other stages that have
    // order indexes that are equal to or greater than the one we send.
    //
    // This means that depending on which direction we are moving the stage we need to add an offset
    const indexOffset = destination.index > source.index ? 1 : 0;
    const destinationOrderIndex = destination.index + indexOffset;

    // You aren't allowed to move stages beyond offer stage
    if (destinationOrderIndex >= interviewingStages.length) return;

    const updatedOrderIndex = interviewingStages[destinationOrderIndex].orderIndex;

    if (updatedOrderIndex == undefined) return; // This shouldn't ever happen

    const updatedStageId = interviewingStages[source.index].id;

    if (!updatedStageId) return; // This shouldn't ever happen

    updateHps({
      id: updatedStageId,
      data: { orderIndex: updatedOrderIndex },
    });
  };

  const isEditStageDrawerOpen = Boolean(selectedStage);
  const onEditStageDrawerClose = (): void => setSelectedStage(undefined);
  const clickEditStage = (stage: HiringPipelineStage): void => setSelectedStage(stage);
  const [jobId] = useJobId();
  const isJobE2eEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  return (
    <>
      <EditStageDrawerWrapper open={isEditStageDrawerOpen} onClose={onEditStageDrawerClose} stage={selectedStage} />

      <SchedulingContainer>
        {isJobE2eEnabled && (
          <Box sx={{ paddingBottom: "20px" }}>
            <InterviewPlanSchedulingSettings />
            <InterviewersPendingSetup />
          </Box>
        )}
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ marginLeft: "40px", marginRight: "42px" }}
        >
          {interestedStages.map(stage => (
            <StageCard key={stage.id} onClick={(): void => clickEditStage(stage)}>
              <InterestedStageRow stage={stage} />
            </StageCard>
          ))}
        </Stack>
        <DragDropContext onBeforeDragStart={onDragStart} onDragEnd={onDragEnd}>
          <StageRowsContainer>
            {interviewingStages?.map((stage, idx) => (
              <StageRow
                key={stage.id}
                stage={stage}
                index={idx}
                anyRowIsDragging={isDragging}
                onEdit={clickEditStage}
              />
            ))}
          </StageRowsContainer>
        </DragDropContext>
      </SchedulingContainer>
    </>
  );
};
