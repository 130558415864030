import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

import { SaapReviewApplicationSchedulingOwnershipEnum } from "services/openapi";

interface MultiSelectSchemaSelectedApplication {
  candidateId: string;
  applicationId: string;
  candidateActionVersion?: number;
  schedulingOwnership?: SaapReviewApplicationSchedulingOwnershipEnum;
}

// If the user has enabled multi-select mode
export const multiSelectEnabledAtom = atomWithReset(false);

// The applications multi-selected by the user
export const multiSelectApplicationsAtom = atomWithReset<MultiSelectSchemaSelectedApplication[]>([]);

// An object of all the selected ids for faster lookup
export const multiSelectIdMapAtom = atom(get => {
  const selectedApplications = get(multiSelectApplicationsAtom);

  return selectedApplications.reduce((acc: Record<string, boolean>, curr) => {
    acc[curr.applicationId] = true;
    return acc;
  }, {});
});

export const selectApplicationAtom = atom(null, (get, set, application: MultiSelectSchemaSelectedApplication) => {
  const selectedApplications = get(multiSelectApplicationsAtom);
  const newSelectedApplications = [...selectedApplications, application];

  set(multiSelectApplicationsAtom, newSelectedApplications);
});

export const deselectApplicationAtom = atom(null, (get, set, applicationId: string) => {
  const selectedApplications = get(multiSelectApplicationsAtom);
  const newSelectedApplications = selectedApplications.filter(({ applicationId: id }) => id !== applicationId);

  set(multiSelectApplicationsAtom, newSelectedApplications);
});
