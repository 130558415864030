import { Stack } from "@mui/material";
import React from "react";

import MarkdownRenderer from "components/CustomMarkdownRenderer";
import { BodySmall } from "components/library/typography";

interface Props {
  label: string;
  value?: string;
}

export const ReadOnlyField = ({ label, value = "Not provided" }: Props): React.ReactElement => {
  return (
    <Stack spacing={0.5}>
      <BodySmall weight="600">{label}</BodySmall>
      <BodySmall>
        <MarkdownRenderer source={value} />
      </BodySmall>
    </Stack>
  );
};
