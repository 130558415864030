import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TextField, Box, Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    width: 335px;
    height: 44px;
    box-sizing: border-box;
    padding: 11px;
    font-size: 14px;
    font-family: Inter;

    input {
      height: 100%;
      padding: 0;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }

  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

interface InterviewLocationProps {
  label: string;
  hoverText: string | null;
  required?: boolean;
  hideLabel?: boolean;
  [x: string]: any;
}

const InterviewLocationField = ({
  label,
  hoverText,
  required,
  hideLabel,
  ...props
}: InterviewLocationProps): React.ReactElement => {
  return (
    <div>
      {!hideLabel && (
        <Box display="flex" alignItems="center">
          <B2_doNotUse medium>{label}</B2_doNotUse>
          <Spacer width={4} />
          {hoverText && (
            <Tooltip title={hoverText} placement="top">
              <InfoOutlinedIcon sx={{ width: "16px", color: colors.grayscale.gray400 }} />
            </Tooltip>
          )}
        </Box>
      )}
      <Spacer height={4} />
      <StyledTextField
        id="outlined-basic"
        variant="outlined"
        value={props.input.value}
        onChange={props.input.onChange}
        error={required && props.meta.error}
      />
    </div>
  );
};

export default InterviewLocationField;
