/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DoverUser,
    DoverUserFromJSON,
    DoverUserFromJSONTyped,
    DoverUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidateFiles
 */
export interface CandidateFiles {
    /**
     * 
     * @type {string}
     * @memberof CandidateFiles
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateFiles
     */
    name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CandidateFiles
     */
    readonly created?: Date;
    /**
     * 
     * @type {string}
     * @memberof CandidateFiles
     */
    candidate: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateFiles
     */
    readonly fileContents?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateFiles
     */
    uploadedContext: CandidateFilesUploadedContextEnum;
    /**
     * 
     * @type {DoverUser}
     * @memberof CandidateFiles
     */
    user?: DoverUser | null;
}

/**
* @export
* @enum {string}
*/
export enum CandidateFilesUploadedContextEnum {
    Resume = 'RESUME',
    Portfolio = 'PORTFOLIO',
    InterviewSubmission = 'INTERVIEW_SUBMISSION',
    Other = 'OTHER'
}

export function CandidateFilesFromJSON(json: any): CandidateFiles {
    return CandidateFilesFromJSONTyped(json, false);
}

export function CandidateFilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateFiles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'candidate': json['candidate'],
        'fileContents': !exists(json, 'file_contents') ? undefined : json['file_contents'],
        'uploadedContext': json['uploaded_context'],
        'user': !exists(json, 'user') ? undefined : DoverUserFromJSON(json['user']),
    };
}

export function CandidateFilesToJSON(value?: CandidateFiles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'candidate': value.candidate,
        'uploaded_context': value.uploadedContext,
        'user': DoverUserToJSON(value.user),
    };
}


