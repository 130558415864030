/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { sortBy } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Column, useTable } from "react-table";

import {
  StyledTableContainer,
  StyledTableRow,
  StyledTableCell,
  StyledHeaderTableCell,
} from "components/dover/ClientUserFileStatsTable/styles";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetClientUserFileStatsQuery } from "services/doverapi/endpoints/userUploadedFile";
import { UserUploadedFileUploadedContextEnum, ClientUserFileStats } from "services/openapi";

interface ClientUserFileStatsTableProps {
  uploadedContext: UserUploadedFileUploadedContextEnum;
}

interface ClientUserFileStatsTableData {
  user: string;
  uploadDate: string;
  status: string;
}

const ClientUserFileStatsTable = ({ uploadedContext }: ClientUserFileStatsTableProps): React.ReactElement => {
  const [pollClientUserFiles, setPollClientUserFiles] = React.useState(false);
  const { data, isSuccess: initialized } = useGetClientUserFileStatsQuery(
    { uploadedContext },
    {
      pollingInterval: pollClientUserFiles ? 3000 : undefined,
    }
  );

  useEffect(() => {
    if (data?.some(data => data.mostRecentProcessingState === "PROCESSING")) {
      setPollClientUserFiles(true);
    } else {
      setPollClientUserFiles(false);
    }
  }, [data]);

  const showTable = !!data?.some(
    data => typeof data.mostRecentProcessingState !== "undefined" || typeof data.mostRecentUploadTime !== "undefined"
  );

  const columns = useMemo(
    (): Column<ClientUserFileStatsTableData>[] => [
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "Upload Date",
        accessor: "uploadDate",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );

  const tableData = useMemo((): ClientUserFileStatsTableData[] => {
    const sortedByUploadTimeDesc = sortBy(data, [
      (userStats: ClientUserFileStats): number | undefined =>
        userStats.mostRecentUploadTime ? userStats.mostRecentUploadTime?.getTime() * -1 : undefined,
    ]);
    return sortedByUploadTimeDesc.map((userStats: ClientUserFileStats) => {
      const row: ClientUserFileStatsTableData = {
        user: userStats.user?.fullName || "",
        uploadDate: userStats.mostRecentUploadTime ? moment(userStats.mostRecentUploadTime).format("MM/DD/YY") : "--",
        status: userStats.mostRecentProcessingState || "--",
      };
      return row;
    });
  }, [data]);

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: tableData,
  });

  if (!showTable) {
    return <></>;
  }

  return (
    <StyledTableContainer>
      {!initialized && <DoverLoadingSpinner spinnerSize={"30px"} />}
      {initialized && (
        <Table
          className="users-with-uploaded-connections-table"
          stickyHeader
          aria-labelledby="Table"
          size="medium"
          aria-label="searches table"
        >
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <StyledHeaderTableCell key={column.id} align="left" padding="normal">
                    {column.render("Header")}
                  </StyledHeaderTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <StyledTableRow
                  /*
                  // @ts-ignore */
                  key={row.index}
                  {...row.getRowProps()}
                >
                  {row.cells.map(cell => (
                    <StyledTableCell {...cell.getCellProps()} align="left">
                      {cell.render("Cell")}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </StyledTableContainer>
  );
};

export default ClientUserFileStatsTable;
