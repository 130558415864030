import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Box, Stack, TextField } from "@mui/material";
import MUIAutocomplete, {
  AutocompleteProps as MUIAutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { sortBy } from "lodash";
import { isEqual } from "lodash";
import throttle from "lodash/throttle";
import React, { useState } from "react";
import styled from "styled-components";

import HelpIconSVG from "assets/icons/help-question.svg";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Body, BodySmall, Caption } from "components/library/typography";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import { StyledHelpIconSVG } from "views/sourcing/Search/styles";

const StyledAutocompleteWrapper = styled(Box)`
  .MuiAutocomplete-noOptions {
    cursor: pointer !important;
    :hover {
      background-color: #f5f5f5;
    }
  }

  fieldset {
    display: none;

    legend {
      display: none;
    }
  }

  flex-grow: 1;
  border: 1px solid ${colors.grayscale.gray300};
  border-radius: 4px;

  :hover {
    border-color: ${(props): string => {
      return props.className == "error" ? colors.critical.base : colors.grayscale.gray700;
    }};
  }

  :focus-within {
    border-color: ${(props): string => {
      return props.className == "error" ? colors.critical.base : `rgb(25, 118, 210)`;
    }};
    border-width: 2px;
  }
  border-color: ${(props): string => {
    return props.className == "error" ? colors.critical.base : `inherit`;
  }};

  ul {
    max-height: 180px;
  }

  .MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
    padding-right: 32px !important;
  }

  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 3px 32px 3px 3px;
    max-height: 250px;
    overflow: auto;
  }

  .MuiInputBase-root {
    background-color: white;
  }
`;

type PartialMUIAutoCompleteProps = Pick<
  MUIAutocompleteProps<any, boolean, undefined, undefined>,
  | "size"
  | "disablePortal"
  | "noOptionsText"
  | "renderOption"
  | "filterSelectedOptions"
  | "filterOptions"
  | "groupBy"
  | "isOptionEqualToValue"
  | "getOptionLabel"
  | "getOptionDisabled"
  | "multiple"
  | "disabled"
  | "readOnly"
  | "freeSolo"
  | "disableCloseOnSelect"
>;

interface SingleSelectProps<OptionsType> {
  initialValue?: OptionsType;
  onSelectedOptionChange?: (value: OptionsType) => void;
}

interface MultiSelectProps<OptionsType> {
  initialValues?: OptionsType[] | null;
  onSelectedOptionsChange?: (values: OptionsType[]) => void;
}

export type AutocompleteProps<OptionsType, FetchedDataType = OptionsType> = PartialMUIAutoCompleteProps & {
  fontSize?: "small" | "medium";
  title?: React.ReactNode;
  subTitle?: string;
  tooltipText?: React.ReactElement;
  placeholder: string;
  errorText?: string;
  sortByField?: string;
  groupBy?: (option: any) => string;
  dataTransform?: (value: FetchedDataType) => OptionsType;
  onChipClick?: (chip: HTMLElement | null) => void;
  setCurrSearchString?: (searchString: string) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  renderTags?: (value: OptionsType[], getTagProps: any) => React.ReactElement;
  hideOptionsUntilLimit?: number; // if you want to hide options until a certain number of characters are entered
} & SingleSelectProps<OptionsType> &
  MultiSelectProps<OptionsType> & {
    // use this when you want to fetch data from an API (asynchronously)
    fetch?: (request: string) => Promise<FetchedDataType[]>;
    // use this when you want to use static data
    staticOptions?: Array<OptionsType>;
    // function which will run after you fetch your data. gives you access to the request, as well as the response
    // only makes sense to use this with fetch
    onFetchCompleted?: (request: string, fetchResponse: FetchedDataType[] | OptionsType[]) => void;
  };

export const Autocomplete = <OptionsType extends object | string, FetchedDataType = OptionsType>({
  /* Styling */
  fontSize,
  /* Static or control values */
  initialValue,
  initialValues,
  title,
  subTitle,
  tooltipText,
  placeholder,
  errorText,
  hideOptionsUntilLimit = 0,
  /* Callbacks */
  onSelectedOptionChange,
  onSelectedOptionsChange,
  dataTransform,
  onChipClick,
  setCurrSearchString,
  /* MUI built-in props */
  size,
  disablePortal,
  multiple,
  noOptionsText,
  renderOption,
  renderInput,
  renderTags,
  filterSelectedOptions,
  onFetchCompleted,
  filterOptions,
  groupBy,
  isOptionEqualToValue,
  getOptionLabel,
  getOptionDisabled,
  readOnly,
  freeSolo,
  disabled,
  disableCloseOnSelect,
  /* Mutually exclusive props */
  fetch,
  staticOptions,
  /* Other */
  sortByField,
}: AutocompleteProps<OptionsType, FetchedDataType>): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<Array<OptionsType>>(
    staticOptions ? (sortByField ? sortBy(staticOptions, sortByField) : staticOptions) : []
  );
  const [loading, setLoading] = React.useState(false);
  const [singleSelectValue, setSingleSelectValue] = React.useState<OptionsType | undefined>(initialValue);
  const [multiSelectValues, setMultiSelectValues] = React.useState<OptionsType[] | null>(initialValues || []);

  React.useEffect(() => {
    if (multiple && initialValues !== undefined && !isEqual(multiSelectValues, initialValues)) {
      setMultiSelectValues(initialValues);
    }

    if (!multiple && initialValue !== undefined && !isEqual(singleSelectValue, initialValue)) {
      setSingleSelectValue(initialValue);
    }
  }, [initialValues, singleSelectValue, multiSelectValues, initialValue, multiple]);

  const fetchData = React.useCallback(
    (request: string) => {
      const throttleFunc = throttle((request: string) => {
        (async (): Promise<void> => {
          // If fetch is undefined, we are using static options, and there is nothing to do here
          if (!fetch) {
            return;
          }

          setLoading(true);
          try {
            const resp = await fetch(request);
            // Optionally transform data into a preferred shape
            const results = dataTransform ? resp.map(dataTransform) : resp;

            // Optionally run a check on the request and its resultings after fetching (using async fetch)
            onFetchCompleted?.(request, results);

            // The cast here is allowable because either the fetched data is the same as the options type, or we transform it
            setOptions(sortByField ? sortBy(results as OptionsType[], sortByField) : (results as OptionsType[]));
          } catch (err) {
            showErrorToast("Failed to load options. Please refresh and try again.");
          }
          setLoading(false);
        })();
      }, 1000);

      throttleFunc(request);
    },
    [dataTransform, fetch, onFetchCompleted, sortByField]
  );

  // If we don't have static options, we want to clear out options on close
  // If we do have static options, we want to preserve those regardless of open/close state
  React.useEffect(() => {
    if (!open) {
      setOptions(staticOptions ? (sortByField ? sortBy(staticOptions, sortByField) : staticOptions) : []);
    }
  }, [open, sortByField, staticOptions]);

  const titleAndSubtitle = React.useMemo(() => {
    if (fontSize === undefined) {
      return (
        <>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <>
              {title && <Body weight="500">{title}</Body>}
              {subTitle && <BodySmall>{subTitle}</BodySmall>}
            </>
            {tooltipText && (
              <Tooltip
                title={tooltipText}
                placement="right"
                arrow={true}
                variant={TooltipVariant.Dark}
                boxSizing="border-box"
              >
                {/* Span is necessary to prevent a MUI v5 render issue */}
                <span>
                  <StyledHelpIconSVG src={HelpIconSVG} />
                </span>
              </Tooltip>
            )}
          </Stack>
        </>
      );
    }

    switch (fontSize) {
      case "small":
        return (
          <>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <>
                {title && <BodySmall weight="500">{title}</BodySmall>}
                {subTitle && <Caption>{subTitle}</Caption>}
              </>
              {tooltipText && (
                <Tooltip
                  title={tooltipText}
                  placement="right"
                  arrow={true}
                  variant={TooltipVariant.Dark}
                  boxSizing="border-box"
                >
                  {/* Span is necessary to prevent a MUI v5 render issue */}
                  <span>
                    <StyledHelpIconSVG src={HelpIconSVG} />
                  </span>
                </Tooltip>
              )}
            </Stack>
          </>
        );
      case "medium":
      default:
        return (
          <>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <>
                {title && <Body weight="500">{title}</Body>}
                {subTitle && <BodySmall>{subTitle}</BodySmall>}
              </>
              {tooltipText && (
                <Tooltip
                  title={tooltipText}
                  placement="right"
                  arrow={true}
                  variant={TooltipVariant.Dark}
                  boxSizing="border-box"
                >
                  {/* Span is necessary to prevent a MUI v5 render issue */}
                  <span>
                    <StyledHelpIconSVG src={HelpIconSVG} />
                  </span>
                </Tooltip>
              )}
            </Stack>
          </>
        );
    }
  }, [fontSize, subTitle, title, tooltipText]);

  // Selects share most props
  const sharedProps = React.useMemo(() => {
    const defaultRenderInput = (params: any): React.ReactNode => {
      return (
        <TextField
          sx={{
            ".MuiOutlinedInput-root.MuiInputBase-root": {
              outline: "none",
              border: "none",
            },
          }}
          {...params}
          InputLabelProps={{ shrink: false }}
          placeholder={
            !singleSelectValue && (multiSelectValues === null || multiSelectValues?.length === 0) ? placeholder : ""
          }
          fullWidth
          onChange={(event): void => {
            // dont fire API if the user deleted text or did not enter anything
            if (event.target.value !== "" || event.target.value !== null) {
              setCurrSearchString?.(event.target.value || "");
              fetchData(event.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      );
    };
    const sharedPropsInternal: Pick<
      MUIAutocompleteProps<any, boolean, undefined, undefined>,
      | "open"
      | "onOpen"
      | "onClose"
      | "disablePortal"
      | "ChipProps"
      | "fullWidth"
      | "disableCloseOnSelect"
      | "isOptionEqualToValue"
      | "getOptionLabel"
      | "getOptionDisabled"
      | "renderOption"
      | "renderInput"
      | "renderTags"
      | "noOptionsText"
      | "filterSelectedOptions"
      | "filterOptions"
      | "groupBy"
      | "options"
      | "loading"
      | "size"
      | "readOnly"
      | "freeSolo"
      | "disabled"
    > = {
      open,
      onOpen: (): void => setOpen(true),
      onClose: (): void => setOpen(false),
      disablePortal,
      fullWidth: true,
      ChipProps: {
        deleteIcon: <HighlightOffSharpIcon />,
        onClick: (e: any): void => onChipClick?.(e.target as HTMLElement),
      },
      disableCloseOnSelect,
      isOptionEqualToValue,
      getOptionLabel,
      getOptionDisabled,
      renderOption,
      noOptionsText,
      disabled,
      readOnly,
      freeSolo,
      filterSelectedOptions,
      filterOptions,
      groupBy,
      options,
      loading,
      size,
      renderInput: renderInput ?? defaultRenderInput,
      renderTags,
    };
    return sharedPropsInternal;
  }, [
    open,
    disablePortal,
    disableCloseOnSelect,
    isOptionEqualToValue,
    getOptionLabel,
    getOptionDisabled,
    renderOption,
    noOptionsText,
    disabled,
    readOnly,
    freeSolo,
    filterSelectedOptions,
    filterOptions,
    groupBy,
    options,
    loading,
    size,
    renderInput,
    renderTags,
    singleSelectValue,
    multiSelectValues,
    placeholder,
    setCurrSearchString,
    fetchData,
    onChipClick,
  ]);

  const [inputValue, setInputValue] = useState("");

  // Props differ slightly based on whether it's a multi-select or not
  const autocompleteInternal = React.useMemo(() => {
    const exceededHideOptionsLimit = inputValue.length >= hideOptionsUntilLimit;
    const myOptions = exceededHideOptionsLimit ? options : [];

    if (multiple) {
      return (
        <MUIAutocomplete
          {...sharedProps}
          multiple={true as boolean}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue): void => {
            setInputValue(newInputValue);
          }}
          noOptionsText={exceededHideOptionsLimit ? noOptionsText : "Start typing to see options"}
          value={multiSelectValues ?? []}
          options={myOptions}
          onChange={(_event, newValue): void => {
            setMultiSelectValues(newValue);
            onSelectedOptionsChange?.(newValue);
          }}
        />
      );
    }

    return (
      <MUIAutocomplete
        {...sharedProps}
        multiple={false as boolean}
        value={singleSelectValue}
        onChange={(_event, newValue): void => {
          setSingleSelectValue(newValue);
          onSelectedOptionChange?.(newValue);
        }}
      />
    );
  }, [
    hideOptionsUntilLimit,
    inputValue,
    multiSelectValues,
    multiple,
    noOptionsText,
    onSelectedOptionChange,
    onSelectedOptionsChange,
    options,
    sharedProps,
    singleSelectValue,
  ]);

  return (
    <Stack spacing={0.5} width={"100%"}>
      {titleAndSubtitle}
      <StyledAutocompleteWrapper className={errorText ? "error" : "noError"}>
        {autocompleteInternal}
      </StyledAutocompleteWrapper>
      {errorText && (
        <Caption color={colors.critical.base} style={{ position: "relative", left: "1px" }}>
          {errorText}
        </Caption>
      )}
    </Stack>
  );
};
