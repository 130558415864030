import { Link } from "react-router-dom";
import styled from "styled-components";

import { colors } from "styles/theme";

export { SectionHeader, CardHeader, CardSubheader } from "./headers";

type TextType = "H3" | "B0" | "B1" | "B2" | "B3";
enum FontSize {
  H3 = "20px",
  B0 = "18px",
  B1 = "16px",
  B2 = "14px",
  B3 = "12px",
}
enum LineHeight {
  H3 = "28px",
  B0 = "26px",
  B1 = "24px",
  B2 = "18px",
  B3 = "15px",
  B4 = "12px",
}

export type Weight = 400 | 500 | 600 | 700;
interface BodyTextProps {
  $size: TextType;

  $color?: string;
  $weight?: Weight;
}

/**
 * @deprecated - please use styles from {@link https://github.com/doverhq/dover/blob/master/frontends/dover-pro/src/components/library/typography.ts} instead
 */
export const BodyText_doNotUse = styled.div<BodyTextProps>`
  color: ${({ $color }): string => $color ?? "inherit"};
  font-family: Inter;
  font-style: normal;
  font-weight: ${({ $weight }): number => $weight ?? 400};
  font-size: ${({ $size }): string => FontSize[$size]};
  line-height: ${({ $size }): string => LineHeight[$size]};
`;

interface TextProps {
  $color?: string;
  medium?: boolean;
  bold?: boolean;
  noMaxWidth?: boolean;
  maxWidth?: string;
  textAlign?: string;
  display?: string;
}

/**
 * @deprecated - please use styles from {@link https://github.com/doverhq/dover/blob/master/frontends/dover-pro/src/components/library/typography.ts} instead
 */
export const B0_doNotUse = styled.div<TextProps>`
  color: ${({ $color }): string => $color ?? "inherit"};
  font-family: Inter;
  font-size: 18px;
  font-weight: ${(props): any => (props.bold ? "600" : props.medium ? "500" : "400")};
  line-height: 26px;
`;

/**
 * @deprecated - please use styles from {@link https://github.com/doverhq/dover/blob/master/frontends/dover-pro/src/components/library/typography.ts} instead
 * Use Subtitle1 or Body component
 */
export const B1_doNotUse = styled.div<TextProps>`
  color: ${({ $color }): string => $color ?? "inherit"};
  font-family: Inter;
  font-size: 16px;
  font-weight: ${(props): any => (props.bold ? "600" : props.medium ? "500" : "400")};
  line-height: 24px;
  display: ${(props): any => props.display ?? "block"};
  text-align: ${(props): any => props.textAlign ?? "initial"};
`;

/**
 * @deprecated - please use styles from {@link https://github.com/doverhq/dover/blob/master/frontends/dover-pro/src/components/library/typography.ts} instead
 */
export const B2_doNotUse = styled.div<TextProps>`
  color: ${({ $color }): string => $color ?? "inherit"};
  font-family: Inter;
  font-style: normal;
  font-weight: ${(props): any => (props.bold ? "600" : props.medium ? "500" : "400")};
  font-size: 14px;
  line-height: 18px;
  display: ${(props): any => props.display ?? "block"};
  text-align: ${(props): any => props.textAlign ?? "initial"};
`;

/**
 * @deprecated - please use styles from {@link https://github.com/doverhq/dover/blob/master/frontends/dover-pro/src/components/library/typography.ts} instead
 */
export const B3_doNotUse = styled.div<TextProps>`
  color: ${({ $color }): string => $color ?? "inherit"};
  font-family: Inter;
  font-style: normal;
  font-weight: ${(props): any => (props.bold ? "600" : props.medium ? "500" : "400")};
  font-size: 12px;
  line-height: 16px;
  max-width: ${(props): any => (props.noMaxWidth ? "none" : props.maxWidth ? props.maxWidth : "640px")};
  text-align: ${(props): any => props.textAlign ?? "initial"};
`;

interface LinkProps {
  $variant?: "primary" | "secondary" | "light";
  display?: "inline" | "block" | "inline-block";
}

const handleLinkVariantStyles = (props: LinkProps): string => {
  switch (props.$variant) {
    case "light":
      return `
          color: ${colors.linkLight};
          text-decoration: none;
      `;
    case "secondary":
      return `
          color: inherit;
          text-decoration: underline;
      `;
    case "primary":
    default:
      return `
          color: ${colors.link};
          text-decoration: none;
      `;
  }
};

export const InternalLink = styled(Link)<LinkProps>`
  ${(props): string => handleLinkVariantStyles(props)}
  ${({ display }): string => (display ? display : "initial")}
`;

const commonLinkStyles = `
  width: fit-content;
  cursor: pointer;

  :hover {
    color: ${colors.link};
    text-decoration: underline;
  }
`;

export const ExternalLink = styled.a<LinkProps>`
  ${commonLinkStyles}

  ${(props): string => handleLinkVariantStyles(props)}
`;

export const FakeLink = styled.span<LinkProps & { onClick: (event: React.MouseEvent) => void }>`
  ${commonLinkStyles}

  ${(props): string => handleLinkVariantStyles(props)}
`;

/**
 * @deprecated - please use styles from {@link https://github.com/doverhq/dover/blob/master/frontends/dover-pro/src/components/library/typography.ts} instead
 */
export const Overline_doNotUse = styled(B3_doNotUse)`
  font-size: 10px;
`;
