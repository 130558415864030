import { Tooltip } from "@mui/material";
import React, { ReactElement } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import CheckmarkIcon from "assets/icons/white-checkmark.svg";
import { Spacer } from "components/Spacer";
import {
  BaseCandidatePipelineStage,
  CandidateBio,
  CandidateBioJobStage,
  InterviewSubstageEnum,
} from "services/openapi";
import { colors as libColors } from "styles/theme";
import { isActiveInterviewPipelineStage, isInterviewStage } from "utils/isStage";
import { compareStages, StageComparisonEnum } from "utils/stageComparison";
import { getNextActionColors } from "views/CandidateDetail/components/next-action/utils/helpers";
import { StyledTooltip } from "views/Referrals/ReviewReferrals/components/styles";

export const generateTimelineForInterviewTracker = (candidateBio: CandidateBio): ReactElement[][] => {
  // Creates a timeline object without substage labels that is also wider than the one that appears in the candidate detail page
  const colors = getNextActionColors(candidateBio?.nextAction);

  return genTimelineForInterviewTracker(
    candidateBio.jobStages?.filter(stage => isActiveInterviewPipelineStage(stage)),
    candidateBio.candidatePipelineStage,
    colors.backgroundColor,
    colors.borderColor
  );
};

export const Timeline = ({ candidateBio }: { candidateBio: CandidateBio }): ReactElement => {
  const colors = getNextActionColors(candidateBio?.nextAction);
  return (
    <TimelineContainer>
      {genTimeline(
        candidateBio.jobStages!,
        candidateBio.candidatePipelineStage,
        colors.backgroundColor,
        colors.borderColor
      )}
    </TimelineContainer>
  );
};

const genTimeline = (
  jobStages: CandidateBioJobStage[] | undefined,
  currentPipelineStage: BaseCandidatePipelineStage | undefined,
  backgroundColor: string,
  borderColor: string
): ReactElement[][] => {
  const jobStagesCopy = [...jobStages!];
  return jobStagesCopy.sort(compareStages).map((stage: CandidateBioJobStage, index: number) => {
    const timeline = [];
    if (index > 0) {
      timeline.push(<TimelineLine />);
    }

    const isActive = !!currentPipelineStage && currentPipelineStage.id === stage.id;
    const isCompleted =
      currentPipelineStage && compareStages(currentPipelineStage, stage, false) === StageComparisonEnum.GT;

    timeline.push(
      <Tooltip title={<StyledTooltip>{stage.name}</StyledTooltip>} placement="bottom" enterDelay={300} leaveDelay={200}>
        <TimelineCircle
          active={isActive}
          completed={isCompleted}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        >
          {isCompleted && <StyledReactSVG src={CheckmarkIcon} />}
        </TimelineCircle>
      </Tooltip>
    );

    return timeline;
  });
};

const genTimelineForInterviewTracker = (
  jobStages: CandidateBioJobStage[] | undefined,
  candidatePipelineStage: BaseCandidatePipelineStage | undefined,
  backgroundColor: string,
  borderColor: string
): ReactElement[][] => {
  const jobStagesCopy = [...jobStages!];
  const nameElements: ReactElement[] = [];
  const elements = jobStagesCopy.sort(compareStages).map((stage: CandidateBioJobStage, index: number) => {
    const timeline = [];
    if (index > 0) {
      timeline.push(<Spacer width={2} />);
      timeline.push(<TimelineLine />);
      timeline.push(<TimelineLine />);
      timeline.push(<Spacer width={2} />);
    }

    const isActive = !!candidatePipelineStage?.id && candidatePipelineStage.id === stage.id;
    const isCompleted =
      !!candidatePipelineStage && compareStages(candidatePipelineStage, stage, false) === StageComparisonEnum.GT;

    if (isActive) {
      nameElements.push(<TimelineStageNameV2>{stage.name}</TimelineStageNameV2>);
      nameElements.push(
        <TimelineStageNameV2>{customerFacingSubstageName(candidatePipelineStage)}</TimelineStageNameV2>
      );
    }

    timeline.push(
      <Tooltip title={<StyledTooltip>{stage.name}</StyledTooltip>} placement="bottom" enterDelay={300} leaveDelay={200}>
        <TimelineCircle
          active={isActive}
          completed={isCompleted}
          backgroundColor={isActive ? libColors.brand : backgroundColor}
          borderColor={borderColor}
        >
          {isCompleted && <StyledReactSVG src={CheckmarkIcon} />}
        </TimelineCircle>
      </Tooltip>
    );

    return timeline;
  });

  elements.unshift(nameElements);
  return elements;
};

export const customerFacingSubstageName = (
  pipelineStage: BaseCandidatePipelineStage | undefined
): "Scheduled" | "Completed" | "Scheduling" | "" => {
  if (!pipelineStage || !isInterviewStage(pipelineStage)) {
    return "";
  }

  switch (pipelineStage.substage) {
    case InterviewSubstageEnum.SCHEDULED:
      return "Scheduled";
    case InterviewSubstageEnum.COMPLETED:
      return "Completed";
    case InterviewSubstageEnum.SCHEDULING_1:
    case InterviewSubstageEnum.SCHEDULING_2:
    case InterviewSubstageEnum.SCHEDULING_3:
    case InterviewSubstageEnum.SCHEDULING_4:
      return "Scheduling";
    default:
      return "";
  }
};

const TimelineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

interface TimelineCircleProps {
  active?: boolean;
  completed?: boolean;
  backgroundColor: string;
  borderColor: string;
}

const TimelineCircle = styled.div<TimelineCircleProps>`
  display: inline-block;
  background-color: ${(props): string =>
    props.active ? props.backgroundColor : props.completed ? libColors.subTitle : ""};
  border: 2px solid ${(props): string => (props.active ? props.borderColor : libColors.subTitle)};
  border-radius: 50%;
  border-width: 2px;
  height: 16px;
  width: 16px;
  margin: 4px;
  position: relative;
`;

const TimelineLine = styled.div`
  display: inline-block;
  height: 1px;
  flex: 1;
  background-color: ${libColors.subTitle};
`;

const TimelineStageNameV2 = styled.span`
  font-size: 0.75em;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
`;

const StyledReactSVG = styled(ReactSVG)`
  svg {
    margin-bottom: 15px;
    margin-left: 1px;
    width: 11px;
    height: 11px;
  }
`;
