import {
  HiringPipelineStageStateEnum,
  InterviewPlanMultipartInterviewStageInterviewMeetingTypeEnum,
} from "services/openapi";

export type DoverStage = 500 | 600 | 650 | 700 | 750 | 800;

export const ALL_DOVER_STAGES: DoverStage[] = [500, 600, 650, 750];

export const potentialStagesToAdd = [600, 650, 750];

export enum StageType {
  SINGLE_STAGE_INTERVIEW,
  MULTI_STAGE_INTERVIEW,
  TAKE_HOME,
}

export enum DrawerField {
  INTERVIEW_TYPE,
  NAME,
  CLIENT_EMAIL_TEMPLATES,
  FEEDBACK_TEMPLATE,
  DURATION,
  INTERVIEWERS,
  ATS_STAGE_MAPPING,
  ATS_FEEDBACK_FORM,
  TAKE_HOME_DURATION,
  SUBSTAGES,
  MEETING_TYPE,
  LOCATION,
  ADDITIONAL_INSTRUCTIONS,
  DEBRIEF_DURATION,
  SCHEDULING_CALENDAR,
}

export interface DisplayField {
  field: DrawerField;
  required: boolean;
}

export interface MultipartInterviewSubstageForm {
  id?: string;
  name?: string;
  duration?: number;
  possibleInterviewers?: Array<string>;
  interviewersRequired?: string;
  orderIndex?: number;
  orderRequired?: boolean;
  defaultFeedbackTemplate?: string;

  // ATS Interview Feedback fields
  atsFeedbackTemplateId?: string;
  atsSubstageId?: string;
}

export interface StageFormAdditionalInfo {
  takeHomeDuration: string;
}

export interface InterviewStageForm {
  interviewType: string;
  duration?: number;
  interviewers?: string[];
  useDoverInterviewer?: boolean;
  isTakeHome?: boolean;
  atsFeedbackTemplateId?: string;
  atsSubstageId?: string;
  additionalInfo?: StageFormAdditionalInfo;
  defaultFeedbackTemplate?: string;
}

export interface MultipartInterviewStageForm {
  substages?: Array<MultipartInterviewSubstageForm>;
  interviewMeetingType?: InterviewPlanMultipartInterviewStageInterviewMeetingTypeEnum;
  reuseConferenceLink?: boolean;
  interviewLocation?: string;
  interviewDetails?: object;
  debriefDuration?: number;
  interviewType: string;
}

export interface HiringPipelineStageForm extends InterviewStageForm, MultipartInterviewStageForm {
  // Hiring Pipeline Stage fields
  id?: string;
  name: string;
  contentTypeName: string;
  atsStageId: string;
  hiringPipelineStageState: HiringPipelineStageStateEnum;
  isMultiPart: boolean;
  // State
  emailTemplateEdited?: boolean;
  interviewType: string;
  schedulingEmailTemplateId: string | null;
  rejectionEmailTemplateId: string | null;
}

export const fieldsToFormLabel = {
  [DrawerField.NAME]: "name",
  [DrawerField.DURATION]: "duration",
  [DrawerField.INTERVIEWERS]: "interviewers",
  [DrawerField.ATS_STAGE_MAPPING]: "atsStageId",
  [DrawerField.ATS_FEEDBACK_FORM]: "atsFeedbackTemplateId",
  [DrawerField.SUBSTAGES]: "substages",
  [DrawerField.MEETING_TYPE]: "interviewMeetingType",
  [DrawerField.LOCATION]: "interviewLocation",
  [DrawerField.ADDITIONAL_INSTRUCTIONS]: "interviewDetails",
  [DrawerField.DEBRIEF_DURATION]: "debriefDuration",
  [DrawerField.SCHEDULING_CALENDAR]: undefined,
};

export const requiredFieldDefault = {
  [DrawerField.INTERVIEW_TYPE]: true,
  [DrawerField.NAME]: true,
  [DrawerField.DURATION]: true,
  [DrawerField.INTERVIEWERS]: false,
  [DrawerField.ATS_STAGE_MAPPING]: false,
  [DrawerField.ATS_FEEDBACK_FORM]: false,
  [DrawerField.SUBSTAGES]: false,
  [DrawerField.MEETING_TYPE]: false,
  [DrawerField.LOCATION]: false,
  [DrawerField.ADDITIONAL_INSTRUCTIONS]: false,
  [DrawerField.DEBRIEF_DURATION]: false,
  [DrawerField.SCHEDULING_CALENDAR]: false,
};

export const appliedOrRespondedFields = [
  { field: DrawerField.NAME, required: requiredFieldDefault[DrawerField.NAME] },
  { field: DrawerField.CLIENT_EMAIL_TEMPLATES, required: false },
];

export const basicStageFields = [
  { field: DrawerField.INTERVIEW_TYPE, required: requiredFieldDefault[DrawerField.INTERVIEW_TYPE] },
  { field: DrawerField.NAME, required: requiredFieldDefault[DrawerField.NAME] },
  { field: DrawerField.DURATION, required: requiredFieldDefault[DrawerField.DURATION] },
  { field: DrawerField.INTERVIEWERS, required: requiredFieldDefault[DrawerField.INTERVIEWERS] },
  { field: DrawerField.CLIENT_EMAIL_TEMPLATES, required: false },
  { field: DrawerField.FEEDBACK_TEMPLATE, required: false },
];

export const atsFields = [
  { field: DrawerField.ATS_STAGE_MAPPING, required: requiredFieldDefault[DrawerField.ATS_STAGE_MAPPING] },
  { field: DrawerField.ATS_FEEDBACK_FORM, required: requiredFieldDefault[DrawerField.ATS_FEEDBACK_FORM] },
];

export const takeHomeFields = [
  { field: DrawerField.INTERVIEW_TYPE, required: requiredFieldDefault[DrawerField.INTERVIEW_TYPE] },
  { field: DrawerField.NAME, required: requiredFieldDefault[DrawerField.NAME] },
  { field: DrawerField.DURATION, required: false },
  { field: DrawerField.INTERVIEWERS, required: false },
  { field: DrawerField.CLIENT_EMAIL_TEMPLATES, required: false },
  { field: DrawerField.FEEDBACK_TEMPLATE, required: false },
];

export const onsiteStageFieldsWithE2E = [
  { field: DrawerField.INTERVIEW_TYPE, required: requiredFieldDefault[DrawerField.INTERVIEW_TYPE] },
  { field: DrawerField.NAME, required: requiredFieldDefault[DrawerField.NAME] },
  { field: DrawerField.SUBSTAGES, required: requiredFieldDefault[DrawerField.SUBSTAGES] },
  { field: DrawerField.MEETING_TYPE, required: requiredFieldDefault[DrawerField.MEETING_TYPE] },
  { field: DrawerField.LOCATION, required: requiredFieldDefault[DrawerField.LOCATION] },
  {
    field: DrawerField.ADDITIONAL_INSTRUCTIONS,
    required: requiredFieldDefault[DrawerField.ADDITIONAL_INSTRUCTIONS],
  },
  { field: DrawerField.DEBRIEF_DURATION, required: requiredFieldDefault[DrawerField.DEBRIEF_DURATION] },
  {
    field: DrawerField.SCHEDULING_CALENDAR,
    required: requiredFieldDefault[DrawerField.SCHEDULING_CALENDAR],
  },
  { field: DrawerField.CLIENT_EMAIL_TEMPLATES, required: false },
];

export const onsiteStageFieldsNoE2E = [
  { field: DrawerField.INTERVIEW_TYPE, required: requiredFieldDefault[DrawerField.INTERVIEW_TYPE] },
  { field: DrawerField.NAME, required: requiredFieldDefault[DrawerField.NAME] },
  { field: DrawerField.SUBSTAGES, required: requiredFieldDefault[DrawerField.SUBSTAGES] },
  { field: DrawerField.CLIENT_EMAIL_TEMPLATES, required: false },
];
