import { Box, FormControl, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { interviewSchedulerActions } from "views/interview/InterviewScheduler/reducers";
import { selectLocationOverride } from "views/interview/InterviewScheduler/selectors";

interface LocationOverrideProps {
  disabled?: boolean;
}

export const LocationOverride = ({ disabled }: LocationOverrideProps): React.ReactElement => {
  const dispatch = useDispatch();

  const locationOverride = useSelector(selectLocationOverride);

  return (
    <Box>
      <FormControl>
        <TextField
          label="Location Override"
          variant="outlined"
          multiline
          value={locationOverride}
          onChange={(event: any): void => {
            dispatch(interviewSchedulerActions.setLocationOverride(event.target.value));
          }}
          disabled={disabled}
        />
      </FormControl>
    </Box>
  );
};
