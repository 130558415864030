import { Box, Divider } from "@mui/material";
import clipboardCopy from "clipboard-copy";
import React from "react";
import styled from "styled-components";

import { GmailAuthModal } from "components/dover/GmailAuth";
import { useGetGmailAuthStatus } from "components/dover/GmailAuth/hooks";
import { ComponentStatus } from "components/dover/hooks";
import { useJobHasActiveCampaignWithValidGmailAlias } from "components/dover/JobHasNoCampaignWithAuthedGmailAliasBanner/hooks";
import { LoadingRelative } from "components/HotLoading";
import { Banner, BannerVariant } from "components/library/Banner";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { appConfig } from "config/appConfig";
import { selectFromListCampaignsQueryResult, useListCampaignsQuery } from "services/doverapi/endpoints/campaign";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { colors } from "styles/theme";
import { B2_doNotUse, InternalLink } from "styles/typography";

const FakeLink = styled(B2_doNotUse)`
  cursor: pointer;
`;

export interface JobHasNoCampaignWithAuthedGmailAliasBannerProps {
  jobId: string;
  setComponentStatus?: (newStatus: ComponentStatus) => void;
}

export const JobHasNoCampaignWithAuthedGmailAliasBanner = React.memo(
  ({ jobId, setComponentStatus }: JobHasNoCampaignWithAuthedGmailAliasBannerProps): React.ReactElement | null => {
    const [isGmailAuthModalOpen, setIsGmailAuthModalOpen] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);

    const { gmailAliasesThatNeedAuth, isLoading: isCampaignsLoading } = useListCampaignsQuery(
      { jobId },
      {
        selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
        pollingInterval: isGmailAuthModalOpen ? 6000 : 0,
      }
    );
    const { gmailAuthStatus, isGmailAuthLoading } = useGetGmailAuthStatus({});
    const { data: jobFeatures, isLoading: isLoadingJobFeatures } = useGetJobFeaturesQuery({ jobId });

    const jobHasActiveCampaignWithValidGmailAlias = useJobHasActiveCampaignWithValidGmailAlias(jobId);

    const isLoading = isGmailAuthLoading || isCampaignsLoading || isLoadingJobFeatures;

    React.useEffect(() => {
      if (!setComponentStatus) {
        return;
      }

      if (isLoading) {
        setComponentStatus(ComponentStatus.LOADING);
        return;
      }

      if (!jobFeatures || jobFeatures.hasServicesEnabled || jobHasActiveCampaignWithValidGmailAlias) {
        setComponentStatus(ComponentStatus.EMPTY);
        return;
      }

      setComponentStatus(ComponentStatus.READY_TO_RENDER);
    }, [isLoading, jobFeatures, jobHasActiveCampaignWithValidGmailAlias, setComponentStatus]);

    const onModalCloseCallback = React.useCallback((): void => {
      setIsGmailAuthModalOpen(false);
    }, []);

    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center">
          <LoadingRelative />
        </Box>
      );
    }

    // In practice, jobFeatures should always be defined since we early return on loading state
    if (!jobFeatures || jobFeatures.hasServicesEnabled) {
      return null;
    }

    if (jobHasActiveCampaignWithValidGmailAlias) {
      return null;
    }

    if ((gmailAliasesThatNeedAuth ?? []).length > 0) {
      return (
        <Banner variant={BannerVariant.Warning}>
          <Box>
            <GmailAuthModal jobId={jobId} isModalOpen={isGmailAuthModalOpen} onModalClose={onModalCloseCallback} />
            <Box>
              <B2_doNotUse>{`Authorize one of the following emails to source candidates for this job:`}</B2_doNotUse>
            </Box>
            {gmailAliasesThatNeedAuth &&
              gmailAliasesThatNeedAuth.map((gmailAlias, index) => {
                return (
                  <Box>
                    <Box marginY={1} display="flex" flexDirection="row">
                      <Box display="flex" minWidth={"200px"} mr={2}>
                        <B2_doNotUse display="flex">{gmailAlias}</B2_doNotUse>
                      </Box>
                      <Box display="flex">
                        <B2_doNotUse display="flex">
                          {gmailAlias === gmailAuthStatus?.email ? (
                            <FakeLink
                              display="flex"
                              $color={colors.link}
                              onClick={(): void => {
                                setIsGmailAuthModalOpen(true);
                              }}
                            >
                              Authorize your Gmail account
                            </FakeLink>
                          ) : (
                            <>
                              Ask this user to authorize{" "}
                              <Box ml={0.5}>
                                <Tooltip
                                  variant={TooltipVariant.Dark}
                                  title={clicked ? "Copied!" : "Copy to clipboard"}
                                  placement="top"
                                  onOpen={(): void => setClicked(false)}
                                  onClick={(): void => {
                                    clipboardCopy(`${appConfig.appUrl}/settings/gmail-auth`);
                                    setClicked(true);
                                  }}
                                >
                                  <FakeLink display="flex" $color={colors.link}>
                                    here
                                  </FakeLink>
                                </Tooltip>
                              </Box>
                            </>
                          )}
                        </B2_doNotUse>
                      </Box>
                    </Box>
                    {index < gmailAliasesThatNeedAuth.length - 1 && <Divider />}
                  </Box>
                );
              })}
            {gmailAuthStatus?.email &&
            !(gmailAliasesThatNeedAuth && gmailAliasesThatNeedAuth.includes(gmailAuthStatus.email)) ? (
              <>
                <Divider />
                <Box marginY={1}>
                  <B2_doNotUse>
                    You can also{" "}
                    <InternalLink to={`/job/${jobId}/setup/outreach`}>create a new email campaign</InternalLink> using
                    your email
                  </B2_doNotUse>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Banner>
      );
    }
    return null;
  }
);
