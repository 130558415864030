import { Box, Stack, Radio, RadioGroup, FormControl, FormControlLabel } from "@mui/material";
import Color from "color";
import React, { useState } from "react";
import styled from "styled-components";

import ProUserAutocomplete from "components/dover/SearchesTable/components/ProUserAutocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Body, BodySmall } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import CustomModal from "components/Modal";
import {
  selectFromListCampaignsQueryResult,
  useCreateCampaignMutation,
  useListCampaignsQuery,
} from "services/doverapi/endpoints/campaign";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { CreateCampaignEmailSenderOptionEnum, DoverUser } from "services/openapi";
import { backgrounds, colors } from "styles/theme";

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 0px;
`;

interface CreateCampaignModalProps {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  onNewCampaignCreated: (campaignId: string) => void;
  campaignToClone?: string;
  jobId: string;
}

const CreateCampaignModal = ({
  handleCloseModal,
  isModalOpen,
  onNewCampaignCreated,
  campaignToClone,
  jobId,
}: CreateCampaignModalProps): React.ReactElement => {
  const { proUsers } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsers,
        };
      },
    }
  );

  const { allCampaigns: campaigns } = useListCampaignsQuery(
    { jobId },
    {
      selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
    }
  );

  const [submitting, setSubmitting] = useState(false);
  const [campaignName, setCampaignName] = useState<string | undefined>(undefined);
  const [campaignNameError, setCampaignNameError] = useState<boolean>(false);
  const [selectedEmailSenderOption, setSelectedEmailSenderOption] = useState<
    CreateCampaignEmailSenderOptionEnum | undefined
  >(undefined);
  const [selectedOtherUser, setSelectedOtherUser] = useState<number | undefined>(undefined);

  const getUserDefinedSenderUser = React.useCallback((): number | undefined => {
    return selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser ? selectedOtherUser : undefined;
  }, [selectedEmailSenderOption, selectedOtherUser]);

  const [createCampaign, { isLoading: creatingNewCampaign }] = useCreateCampaignMutation();
  const handleAddNewCampaign = React.useCallback(async () => {
    const newCampaign = await createCampaign({
      jobId,
      campaignToClone: campaignToClone ?? undefined,
      name: campaignName ?? `Variant ${campaigns ? (campaigns.length + 1).toString() : "1"}`,
      emailSenderOption: selectedEmailSenderOption,
      userDefinedSenderUser: getUserDefinedSenderUser(),
    }).unwrap();

    if (!newCampaign.id) {
      return;
    }

    onNewCampaignCreated(newCampaign.id);
  }, [
    createCampaign,
    jobId,
    campaignToClone,
    campaignName,
    campaigns,
    selectedEmailSenderOption,
    getUserDefinedSenderUser,
    onNewCampaignCreated,
  ]);

  const handleCampaignNameChange = React.useCallback(
    (value: string): void => {
      if ((campaigns ?? []).map(campaign => campaign.name).includes(value)) {
        setCampaignNameError(true);
      } else {
        setCampaignNameError(false);
      }
      setCampaignName(value);
    },
    [campaigns]
  );

  return (
    <DoverLoadingOverlay active={submitting} overlay={submitting} minHeight="auto" fullScreen={submitting}>
      <CustomModal
        title={<Body>{"Create Campaign"}</Body>}
        open={isModalOpen}
        onClose={(): void => handleCloseModal()}
        maxWidth="sm"
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Secondary} onClick={(): void => handleCloseModal()}>
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              disabled={
                !selectedEmailSenderOption ||
                !campaignName ||
                campaignNameError ||
                (selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser && !selectedOtherUser)
              }
              loading={creatingNewCampaign}
              onClick={async (): Promise<void> => {
                setSubmitting(true);
                await handleAddNewCampaign();
                setSubmitting(false);
                handleCloseModal();
              }}
            >
              Submit
            </Button>
          </Stack>
        }
      >
        <Stack spacing={2}>
          <TextField
            title={"Campaign Name"}
            text={campaignName ?? ""}
            error={campaignNameError}
            errorMessage={"Cannot have the same name as another campaign"}
            onTextUpdated={handleCampaignNameChange}
          ></TextField>
          <Body weight="500">{"Who do you prefer outreach come from?"}</Body>
          <FormControl component="fieldset" variant="standard">
            <RadioGroup
              name="selectEmailSender"
              value={selectedEmailSenderOption}
              onChange={(event): void =>
                setSelectedEmailSenderOption(event.target.value as CreateCampaignEmailSenderOptionEnum)
              }
            >
              <Stack spacing={1.5}>
                <Box>
                  <StyledFormControlLabel
                    value={CreateCampaignEmailSenderOptionEnum.HiringManager}
                    control={<StyledRadio />}
                    label={<Body style={{ marginLeft: "4px" }}>Hiring manager</Body>}
                  />
                  <Box ml={2}>
                    <BodySmall color={colors.grayscale.gray600}>
                      Candidates typically prefer emails directly from a hiring manager, which leads to a higher
                      interest rate.
                    </BodySmall>
                  </Box>
                </Box>
                <Box>
                  <StyledFormControlLabel
                    value={CreateCampaignEmailSenderOptionEnum.VirtualRecruiter}
                    control={<StyledRadio />}
                    label={<Body style={{ marginLeft: "4px" }}>Virtual Recruiter</Body>}
                  />
                  <Box ml={2}>
                    <BodySmall color={colors.grayscale.gray600}>
                      This may result in 20% fewer interested candidates. Dover can set up outreach to send from a
                      “recruiter” email.
                    </BodySmall>
                  </Box>
                </Box>
                <Box>
                  <StyledFormControlLabel
                    value={CreateCampaignEmailSenderOptionEnum.OtherUser}
                    control={<StyledRadio />}
                    label={<Body>Someone else on my team</Body>}
                  />
                </Box>
              </Stack>
            </RadioGroup>
          </FormControl>
          {selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser && proUsers && (
            <Box pl={2}>
              <ProUserAutocomplete setSelectedProUser={setSelectedOtherUser} />
            </Box>
          )}
        </Stack>
      </CustomModal>
    </DoverLoadingOverlay>
  );
};

export default CreateCampaignModal;
