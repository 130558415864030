/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateNewJobRequest
 */
export interface CreateNewJobRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateNewJobRequest
     */
    hiringManagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateNewJobRequest
     */
    recruiterId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateNewJobRequest
     */
    hiringTeamMemberIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateNewJobRequest
     */
    jobPositionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewJobRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewJobRequest
     */
    createJobContext?: CreateNewJobRequestCreateJobContextEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNewJobRequest
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateNewJobRequest
     */
    goalHireDateOffset?: number;
}

/**
* @export
* @enum {string}
*/
export enum CreateNewJobRequestCreateJobContextEnum {
    Default = 'DEFAULT',
    Free = 'FREE',
    FreeAts = 'FREE_ATS',
    LimitedOutbound = 'LIMITED_OUTBOUND',
    FreeSourcingExtension = 'FREE_SOURCING_EXTENSION',
    LimitedOutboundJd = 'LIMITED_OUTBOUND_JD'
}

export function CreateNewJobRequestFromJSON(json: any): CreateNewJobRequest {
    return CreateNewJobRequestFromJSONTyped(json, false);
}

export function CreateNewJobRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNewJobRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hiringManagerId': !exists(json, 'hiring_manager_id') ? undefined : json['hiring_manager_id'],
        'recruiterId': !exists(json, 'recruiter_id') ? undefined : json['recruiter_id'],
        'hiringTeamMemberIds': !exists(json, 'hiring_team_member_ids') ? undefined : json['hiring_team_member_ids'],
        'jobPositionId': !exists(json, 'job_position_id') ? undefined : json['job_position_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'createJobContext': !exists(json, 'create_job_context') ? undefined : json['create_job_context'],
        'isPrivate': !exists(json, 'is_private') ? undefined : json['is_private'],
        'goalHireDateOffset': !exists(json, 'goal_hire_date_offset') ? undefined : json['goal_hire_date_offset'],
    };
}

export function CreateNewJobRequestToJSON(value?: CreateNewJobRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hiring_manager_id': value.hiringManagerId,
        'recruiter_id': value.recruiterId,
        'hiring_team_member_ids': value.hiringTeamMemberIds,
        'job_position_id': value.jobPositionId,
        'title': value.title,
        'create_job_context': value.createJobContext,
        'is_private': value.isPrivate,
        'goal_hire_date_offset': value.goalHireDateOffset,
    };
}


