/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20037
 */
export interface InlineResponse20037 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20037
     */
    email: string;
}

export function InlineResponse20037FromJSON(json: any): InlineResponse20037 {
    return InlineResponse20037FromJSONTyped(json, false);
}

export function InlineResponse20037FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20037 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function InlineResponse20037ToJSON(value?: InlineResponse20037 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}


