import { atom } from "jotai";

import { TalentNetworkRequest } from "services/openapi";
import { dtnParamsAtom } from "views/candidates/DoverTalentNetwork/atoms/dtnParams";
import { dtnSearchIdAtom } from "views/candidates/DoverTalentNetwork/atoms/dtnSearchId";

export const toNextTalentAtom = atom<(() => void) | undefined>(undefined);

export const listTalentsArgsAtom = atom(get => {
  // Search ID is required for this API
  const searchId = get(dtnSearchIdAtom);
  if (!searchId) {
    return undefined;
  }

  const params = get(dtnParamsAtom);

  return {
    searchId,
    params,
    limit: 3000,
  } as TalentNetworkRequest;
});

export const listTalentsOptimisticUpdateArgsAtom = atom(get => {
  const toNextApplication = get(toNextTalentAtom);
  const listDoverTalentsArgs = get(listTalentsArgsAtom);

  return {
    toNextApplication,
    listDoverTalentsArgs,
  };
});
