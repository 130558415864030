import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { Field, useForm } from "react-final-form";

import {
  RequiredAsterisk,
  StageDrawerFormLabel,
  StageNameField,
} from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import { HiringPipelineStageStateEnum } from "services/openapi";
import { isInterviewStage } from "utils/isStage";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";

export const EditStageName = ({
  fieldPrefix,
  required,
  stageData,
  isTakeHome,
}: {
  fieldPrefix: string;
  required: boolean;
  stageData: StageDataWrapper;
  isTakeHome: boolean;
}): React.ReactElement => {
  const form = useForm();

  useEffect(() => {
    // If the stage is a take-home stage and has not been initialized (inactive), we want to set the name to "Take-home"
    if (isTakeHome && stageData.stage.state === HiringPipelineStageStateEnum.Inactive) {
      form.change(`${fieldPrefix}.name`, "Take-home");
    }
  }, [isTakeHome, form, fieldPrefix, stageData.stage.state]);

  const nameEditable = isInterviewStage(stageData.stage);

  return (
    <Stack spacing={1}>
      <StageDrawerFormLabel bold>
        Stage name {required && nameEditable && <RequiredAsterisk>*</RequiredAsterisk>}
      </StageDrawerFormLabel>
      <Field name={`${fieldPrefix}.name`} validate={(value): boolean => required && !value}>
        {(props): React.ReactElement => {
          return (
            <>
              <StageNameField
                id="outlined-basic"
                variant="outlined"
                value={props.input.value}
                onChange={props.input.onChange}
                error={required && props.meta.error}
                disabled={!nameEditable}
              />
            </>
          );
        }}
      </Field>
    </Stack>
  );
};
