/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleCandidatePipelineStageFilterField
 */
export interface SingleCandidatePipelineStageFilterField {
    /**
     * 
     * @type {string}
     * @memberof SingleCandidatePipelineStageFilterField
     */
    pipelineStageId: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SingleCandidatePipelineStageFilterField
     */
    pipelineSubstages: Array<number>;
}

export function SingleCandidatePipelineStageFilterFieldFromJSON(json: any): SingleCandidatePipelineStageFilterField {
    return SingleCandidatePipelineStageFilterFieldFromJSONTyped(json, false);
}

export function SingleCandidatePipelineStageFilterFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleCandidatePipelineStageFilterField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pipelineStageId': json['pipeline_stage_id'],
        'pipelineSubstages': json['pipeline_substages'],
    };
}

export function SingleCandidatePipelineStageFilterFieldToJSON(value?: SingleCandidatePipelineStageFilterField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pipeline_stage_id': value.pipelineStageId,
        'pipeline_substages': value.pipelineSubstages,
    };
}


