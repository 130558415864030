import { Box, Divider } from "@mui/material";
import { format, isValid } from "date-fns";
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";

import Instruction from "components/instructions/Instruction";
import { InstructionList } from "components/instructions/styles";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { Body } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { ClientOnboardingSetupAtsStateEnum } from "services/openapi";
import { theme } from "styles/theme";
import {
  addSourceInstructionItems,
  createApiKeyInstructionItems,
  givePermissionsInstructionItems,
} from "views/CompanySetup/components/AtsSettings/constants";
import {
  ErrorMessageTypography,
  StyledDoneIcon,
  StyledEditIcon,
  SuccessText,
  StyledTextField,
  StyledErrorOutlinedIcon,
} from "views/CompanySetup/components/AtsSettings/styles";
import { getHiddenKey } from "views/CompanySetup/components/AtsSettings/utils";
import { subDomainReg } from "views/CompanySetup/constants";

const GreenhouseInvalidError = (): React.ReactElement => {
  return (
    <ErrorMessageTypography>
      This api key is invalid! Please try the following:
      <ol>
        <li>{`Make sure the key type is 'Harvest'`}</li>
        <li>{`Make sure you've given all of the correct permissions in step 4`}</li>
        <li>{`Contact our support team`}</li>
      </ol>
    </ErrorMessageTypography>
  );
};

const SubdomainErrorTooltip = styled(Tooltip)`
    font-family: ${theme.body.b2.fontFamily},
    font-size: ${theme.body.b2.fontSize},
    font-weight: ${theme.body.b2.fontWeight},
    background-color: ${theme.colors.secondary.base},
`;

interface GreenhouseComponentProps {
  setupAtsState: ClientOnboardingSetupAtsStateEnum | undefined;
  greenhouseApiKeyInput: string | null | undefined;
  greenhouseCredsValid: boolean | null | undefined;
  greenhouseCredsLastValidAt: Date | undefined;
  greenhouseSubdomainInput: string | null | undefined;
  greenhouseEditMode: boolean;
  setGreenhouseEditMode: (editMode: boolean) => void;
  setGreenhouseSubdomainInput: (input: string) => void;
  setGreenhouseApiKeyInput: (input: string) => void;
}

export const GreenhouseComponent = ({
  setupAtsState,
  greenhouseApiKeyInput: greenhouseApiKeyInput,
  greenhouseCredsValid,
  greenhouseCredsLastValidAt,
  greenhouseSubdomainInput,
  greenhouseEditMode,
  setGreenhouseEditMode,
  setGreenhouseSubdomainInput,
  setGreenhouseApiKeyInput,
}: GreenhouseComponentProps): React.ReactElement => {
  const [subdomainError, setSubdomainError] = useState(false);
  const [openInstructionModal, setOpenInstructionModal] = useState("");

  const handleGreenhouseSubdomainChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!subDomainReg.test(event.target.value)) {
      setSubdomainError(true);
    } else {
      setSubdomainError(false);
    }
    setGreenhouseSubdomainInput(event.target.value);
  };
  const handleApiKeyChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setGreenhouseApiKeyInput(event.target.value);
  };

  const toggleEditGreenhouse = (): void => {
    setGreenhouseEditMode(!greenhouseEditMode);
  };

  const formattedLastValidAt = isValid(greenhouseCredsLastValidAt)
    ? format(greenhouseCredsLastValidAt!, "PP p")
    : undefined;
  const showSuccessScreen =
    (setupAtsState === ClientOnboardingSetupAtsStateEnum.StartedPendingDoverAction ||
      setupAtsState === ClientOnboardingSetupAtsStateEnum.Complete) &&
    greenhouseCredsValid &&
    formattedLastValidAt &&
    !greenhouseEditMode;

  if (showSuccessScreen) {
    return (
      <>
        <SuccessText>
          <StyledDoneIcon />
          Successfully connected on {formattedLastValidAt}
        </SuccessText>
        <Spacer height="16px" />
        <Box border={1} borderRadius={5} borderColor="grey.400" padding={1}>
          <Box width="100%" p={2}>
            <Box display="inline" component="div" marginRight={4}>
              Subdomain:
            </Box>
            <Box display="inline" component="div">
              {greenhouseSubdomainInput}
            </Box>
          </Box>
          <Divider />
          <Box width="100%" p={2} overflow="hidden">
            <Box display="inline" component="div" marginRight={7.75}>
              API Key:
            </Box>
            <Box display="inline" component="div">
              {getHiddenKey(greenhouseApiKeyInput)}
            </Box>
          </Box>
          <Divider />
          <Box width="100%" p={1}>
            <Box display="inline" component="div" width="120px">
              <Button variant={ButtonVariant.Ghost} onClick={toggleEditGreenhouse}>
                <StyledEditIcon /> Edit
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box>
      <Body>Follow the steps below to integrate with Greenhouse:</Body>
      <Spacer height="16px" />
      <InstructionList>
        <Instruction
          cardHeader={<Body>What is your greenhouse subdomain?</Body>}
          cardInstructionBody={
            <>
              <Spacer height="16px" />
              <Box display="flex" alignItems="center">
                <StyledTextField
                  name="greenhouseSubdomain"
                  variant="outlined"
                  placeholder="e.g. app, app2, acme, etc."
                  fullWidth
                  onChange={handleGreenhouseSubdomainChange}
                  defaultValue={greenhouseSubdomainInput ?? ""}
                  error={subdomainError}
                  style={{ maxWidth: "305px" }}
                />
                <Spacer width="12px" />
                <Body>.greenhouse.io</Body>
                {subdomainError && (
                  <SubdomainErrorTooltip
                    placement="right"
                    title={
                      <ul>
                        <li>Your subdomain part must have a length no greater than 63.</li>
                        <li>
                          Your subdomain part must begin and end with an alpha-numeric (i.e. letters [A-Za-z] or digits
                          [0-9]).
                        </li>
                        <li>
                          Your subdomain part may contain periods and hyphens (dashes), but may not begin or end with a
                          period or hyphen.
                        </li>
                      </ul>
                    }
                  >
                    <StyledErrorOutlinedIcon style={{ marginLeft: "8px" }} />
                  </SubdomainErrorTooltip>
                )}
              </Box>
              <Spacer height="12px" />
              <Body>
                {
                  "You can easily determine what your subdomain is by signing into Greenhouse and looking at your browser's address bar to see what comes before .greenhouse.io."
                }
              </Body>
            </>
          }
        />
        <Instruction
          openModalKey={openInstructionModal}
          setOpenModal={(): void => setOpenInstructionModal("addSourceInstructions")}
          setCloseModal={(): void => setOpenInstructionModal("")}
          cardHeader={
            <Body>
              Create a Source called <strong>Dover</strong>
            </Body>
          }
          modalKey={"addSourceInstructions"}
          modalHeader={
            <>
              Create a Source called <strong>Dover</strong>
            </>
          }
          modalInstructionItems={addSourceInstructionItems}
        />
        <Instruction
          openModalKey={openInstructionModal}
          setOpenModal={(): void => setOpenInstructionModal("createApiKeyInstructions")}
          setCloseModal={(): void => setOpenInstructionModal("")}
          cardHeader={<Body>Create Harvest API key and paste it below</Body>}
          modalKey={"createApiKeyInstructions"}
          modalHeader={"Create Harvest API key"}
          modalInstructionItems={createApiKeyInstructionItems}
          cardInstructionBody={
            <>
              <Spacer height="16px" />
              <StyledTextField
                name="greenhouseApiKey"
                variant="outlined"
                placeholder="Enter API key"
                fullWidth
                onChange={handleApiKeyChange}
                defaultValue={greenhouseApiKeyInput ?? ""}
                style={{ maxWidth: "339px" }}
              />
            </>
          }
        />
        <Instruction
          openModalKey={openInstructionModal}
          setOpenModal={(): void => setOpenInstructionModal("givePermissionsInstructions")}
          setCloseModal={(): void => setOpenInstructionModal("")}
          cardHeader={<Body>Give Dover the correct Greenhouse permissions</Body>}
          modalKey={"givePermissionsInstructions"}
          modalHeader={"Give Dover the correct Greenhouse permissions"}
          modalInstructionItems={givePermissionsInstructionItems}
        />
      </InstructionList>
      <Spacer height="16px" />
      <Body>Once you click Save and Validate, Dover will begin syncing which can take up to 1 business day.</Body>
      <Spacer height="16px" />
      {!greenhouseCredsValid && formattedLastValidAt && <GreenhouseInvalidError />}
    </Box>
  );
};
