/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JIRTClient,
    JIRTClientFromJSON,
    JIRTClientFromJSONTyped,
    JIRTClientToJSON,
    JIRTHiringManager,
    JIRTHiringManagerFromJSON,
    JIRTHiringManagerFromJSONTyped,
    JIRTHiringManagerToJSON,
    JIRTJobFAQ,
    JIRTJobFAQFromJSON,
    JIRTJobFAQFromJSONTyped,
    JIRTJobFAQToJSON,
} from './';

/**
 * 
 * @export
 * @interface JIRTJob
 */
export interface JIRTJob {
    /**
     * 
     * @type {string}
     * @memberof JIRTJob
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof JIRTJob
     */
    title?: string | null;
    /**
     * 
     * @type {JIRTClient}
     * @memberof JIRTJob
     */
    client: JIRTClient;
    /**
     * 
     * @type {JIRTHiringManager}
     * @memberof JIRTJob
     */
    hiringManager: JIRTHiringManager;
    /**
     * 
     * @type {Array<JIRTJobFAQ>}
     * @memberof JIRTJob
     */
    readonly faqs?: Array<JIRTJobFAQ>;
    /**
     * 
     * @type {string}
     * @memberof JIRTJob
     */
    slackChannelId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JIRTJob
     */
    readonly hasServicesEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JIRTJob
     */
    shouldHideCompensationInternally?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof JIRTJob
     */
    readonly supplementalPitchInfo?: string;
}

export function JIRTJobFromJSON(json: any): JIRTJob {
    return JIRTJobFromJSONTyped(json, false);
}

export function JIRTJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): JIRTJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'client': JIRTClientFromJSON(json['client']),
        'hiringManager': JIRTHiringManagerFromJSON(json['hiring_manager']),
        'faqs': !exists(json, 'faqs') ? undefined : ((json['faqs'] as Array<any>).map(JIRTJobFAQFromJSON)),
        'slackChannelId': !exists(json, 'slack_channel_id') ? undefined : json['slack_channel_id'],
        'hasServicesEnabled': !exists(json, 'has_services_enabled') ? undefined : json['has_services_enabled'],
        'shouldHideCompensationInternally': !exists(json, 'should_hide_compensation_internally') ? undefined : json['should_hide_compensation_internally'],
        'supplementalPitchInfo': !exists(json, 'supplemental_pitch_info') ? undefined : json['supplemental_pitch_info'],
    };
}

export function JIRTJobToJSON(value?: JIRTJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'client': JIRTClientToJSON(value.client),
        'hiring_manager': JIRTHiringManagerToJSON(value.hiringManager),
        'slack_channel_id': value.slackChannelId,
        'should_hide_compensation_internally': value.shouldHideCompensationInternally,
    };
}


