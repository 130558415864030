import { Box, Grid, Divider } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { OverallRatingSelect, OVERALL_RATING_NAME } from "views/interview/common/components/OverallRatingSelect";
import { H1 } from "views/interview/common/styles";

interface Selection {
  label: string | React.ReactNode;
  value: any;
  color: string;
}

const AnswerStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface AnswerProps {
  color: string;
}

const green = "#E0F5EF";
const red = "#F8DDDD";
const gray = "#F0F3F5";

const colorMap: Map<string, string> = new Map([
  ["green", green],
  ["red", red],
  ["gray", gray],
]);

const Answer = styled.div<AnswerProps>`
  padding: 6px 10px;
  background: ${(props: AnswerProps): any => `${props.color}`};
  height: fit-content;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
`;

interface Props {
  label: string | React.ReactNode;
  selections?: Selection[];
  notes?: string;
  largeTextArea?: boolean;
  questionsOnly?: boolean;
}

export default ({ label, selections, notes, largeTextArea, questionsOnly }: Props): React.ReactElement => {
  return (
    <Grid item container direction="column" display="flex" spacing={0.8}>
      <Divider />
      <Spacer height="16px" />
      {!largeTextArea && label !== OVERALL_RATING_NAME && (
        <Grid item xs={12} md={questionsOnly ? 12 : 5}>
          <H1>{label}</H1>
        </Grid>
      )}
      {!questionsOnly && (
        <>
          {label === OVERALL_RATING_NAME ? (
            <OverallRatingSelect notes={notes} />
          ) : (
            <>
              <Grid item container xs={12} md={3}>
                {selections && (
                  <AnswerStyles>
                    {selections.map(({ label: qLabel, color }: Selection) => (
                      <Answer color={colorMap.get(color) || gray}>
                        <H1>{qLabel}</H1>
                      </Answer>
                    ))}
                  </AnswerStyles>
                )}
              </Grid>
              {notes && (
                <Grid item xs={12} md={largeTextArea ? 12 : 4} justifyContent="flex-end" display="flex">
                  <Box whiteSpace="pre-line" width="100%">
                    {notes}
                  </Box>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};
