import { Stack } from "@mui/material";
import React from "react";

import { StyledListItem } from "components/dover/inputs/pro-users/styles";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { ProfileSearchKeywordSerializer } from "services/openapi";

interface NewKeywordModalProps {
  open: boolean;
  onClose: () => void;
  newlyAddedKeyword: ProfileSearchKeywordSerializer | undefined;
  newlyAddedKeywordChildren: string[] | undefined;
  acceptKeyword: () => void;
}

export const NewKeywordModal = ({
  open,
  onClose,
  newlyAddedKeyword,
  newlyAddedKeywordChildren,
  acceptKeyword,
}: NewKeywordModalProps): React.ReactElement => {
  const innerBody = React.useMemo(() => {
    if (!newlyAddedKeywordChildren) {
      return <DoverLoadingSpinner />;
    }

    return (
      <Stack spacing={2}>
        <Body>{`"${newlyAddedKeyword?.canonicalKeyword}" has the following keywords associated with it that will also be added:`}</Body>
        <Stack
          spacing={1}
          maxHeight="150px"
          overflow="scroll"
          sx={{
            // show the scrollbar all the time, rather than only when scrolling, to indicate that scrolling is a possibility
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "8px",
              backgroundColor: "#F5F5F5",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "4px",
            },
          }}
        >
          {newlyAddedKeywordChildren.map(child => (
            <StyledListItem key={child}>{child}</StyledListItem>
          ))}
        </Stack>
        <Body>{`Do you want to add "${newlyAddedKeyword?.canonicalKeyword}" and its children to the bucket?`}</Body>
      </Stack>
    );
  }, [newlyAddedKeyword, newlyAddedKeywordChildren]);

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title="New Keyword's Children"
      maxWidth="xs"
      dialogActions={
        <>
          <Button variant={ButtonVariant.Secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={acceptKeyword}>
            Accept
          </Button>
        </>
      }
    >
      {innerBody}
    </CustomModal>
  );
};
