/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateActionEmailArgs,
    CandidateActionEmailArgsFromJSON,
    CandidateActionEmailArgsFromJSONTyped,
    CandidateActionEmailArgsToJSON,
    InterviewPanel,
    InterviewPanelFromJSON,
    InterviewPanelFromJSONTyped,
    InterviewPanelToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubmitDecisionRequest
 */
export interface SubmitDecisionRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmitDecisionRequest
     */
    candidateActionVersion: number;
    /**
     * 
     * @type {string}
     * @memberof SubmitDecisionRequest
     */
    decision: SubmitDecisionRequestDecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmitDecisionRequest
     */
    archiveReason?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubmitDecisionRequest
     */
    auto: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubmitDecisionRequest
     */
    responseToCandidateQuestion?: string;
    /**
     * 
     * @type {CandidateActionEmailArgs}
     * @memberof SubmitDecisionRequest
     */
    emailArgs?: CandidateActionEmailArgs | null;
    /**
     * The stage we'd like to move the candidate to. Required for manual scheduling.
     * @type {string}
     * @memberof SubmitDecisionRequest
     */
    desiredHiringPipelineStage?: string | null;
    /**
     * 
     * @type {InterviewPanel}
     * @memberof SubmitDecisionRequest
     */
    interviewPanel?: InterviewPanel | null;
}

/**
* @export
* @enum {string}
*/
export enum SubmitDecisionRequestDecisionEnum {
    Approve = 'APPROVE',
    Reject = 'REJECT',
    Reschedule = 'RESCHEDULE',
    Email = 'EMAIL'
}

export function SubmitDecisionRequestFromJSON(json: any): SubmitDecisionRequest {
    return SubmitDecisionRequestFromJSONTyped(json, false);
}

export function SubmitDecisionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitDecisionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateActionVersion': json['candidate_action_version'],
        'decision': json['decision'],
        'archiveReason': !exists(json, 'archive_reason') ? undefined : json['archive_reason'],
        'auto': json['auto'],
        'responseToCandidateQuestion': !exists(json, 'response_to_candidate_question') ? undefined : json['response_to_candidate_question'],
        'emailArgs': !exists(json, 'email_args') ? undefined : CandidateActionEmailArgsFromJSON(json['email_args']),
        'desiredHiringPipelineStage': !exists(json, 'desired_hiring_pipeline_stage') ? undefined : json['desired_hiring_pipeline_stage'],
        'interviewPanel': !exists(json, 'interview_panel') ? undefined : InterviewPanelFromJSON(json['interview_panel']),
    };
}

export function SubmitDecisionRequestToJSON(value?: SubmitDecisionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_action_version': value.candidateActionVersion,
        'decision': value.decision,
        'archive_reason': value.archiveReason,
        'auto': value.auto,
        'response_to_candidate_question': value.responseToCandidateQuestion,
        'email_args': CandidateActionEmailArgsToJSON(value.emailArgs),
        'desired_hiring_pipeline_stage': value.desiredHiringPipelineStage,
        'interview_panel': InterviewPanelToJSON(value.interviewPanel),
    };
}


