import { Stack } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { InboundCriteriaSetupFormValues } from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup/types";
import { ControlledAutocomplete } from "components/library/Autocomplete";
import { Subtitle2 } from "components/library/typography";
import {
  useListCompanySizesQuery,
  useLazyListIndividualCompaniesQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { Company, ListCompanySizeSerializer } from "services/openapi";
import { IndustryAutocomplete } from "views/sourcing/Search/components/CompaniesFilters";

export const CompaniesFormSection = (): React.ReactElement => {
  const { control } = useFormContext<InboundCriteriaSetupFormValues>();
  const companySizesFormValue = useWatch({ control, name: "companySizes" });
  const targetCompaniesFormValue = useWatch({ control, name: "targetCompanies" });

  const { data: companySizesOptions } = useListCompanySizesQuery();
  const [lazyListCompanies] = useLazyListIndividualCompaniesQuery();

  const fetchCompanies = async (request: string): Promise<Company[]> => {
    return await lazyListCompanies({ limit: 50, fullTextSearch: request }).unwrap();
  };

  return (
    <Stack spacing={2}>
      <ControlledAutocomplete
        control={control}
        name="companySizes"
        fontSize="small"
        title="Desired company size"
        placeholder="Enter company sizes..."
        staticOptions={companySizesOptions ?? []}
        filterSelectedOptions
        getOptionLabel={(option: ListCompanySizeSerializer): string => option.name}
        isOptionEqualToValue={(option: ListCompanySizeSerializer, value: ListCompanySizeSerializer): boolean =>
          option.minSize === value.minSize && option.maxSize === value.maxSize
        }
        initialValues={companySizesFormValue ?? []}
        multiple
        sortByField="minSize"
      />
      <Stack spacing={0.5}>
        <Subtitle2>Industries</Subtitle2>
        <IndustryAutocomplete
          watchName="industries"
          formFieldName="industries.industryElements"
          showRequiredToggle={false}
        />
      </Stack>
      <ControlledAutocomplete
        title="Target companies"
        control={control}
        fontSize="small"
        placeholder="Enter specific companies..."
        noOptionsText="Start typing to see companies"
        fetch={fetchCompanies}
        filterSelectedOptions
        name="targetCompanies"
        initialValues={targetCompaniesFormValue ?? []}
        multiple
        sortByField="name"
        getOptionLabel={(option: Company): string => (option.url ? `${option.name} (${option.url})` : `${option.name}`)}
      />
    </Stack>
  );
};
