import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { TextField, Select } from "@mui/material";
import styled from "styled-components";

import { Body } from "components/library/typography";
import { colors } from "styles/theme";

const WhiteCard = styled.div`
  background: white;
  border: 1px solid #bcc7cc;
  border-radius: 6px;
`;

export const AtsCard = styled(WhiteCard)`
  padding: 24px;
  width: 100%;
  overflow: hidden;
`;

export const SuccessText = styled(Body)`
  color: ${colors.success.base};
`;

export const ErrorMessageTypography = styled(Body)`
  color: ${colors.critical.base};
`;

export const StyledDoneIcon = styled(DoneIcon)`
  fill: ${colors.primary.base};
  width: 20px;
  padding-bottom: 3px;
  margin-right: 5px;
`;

export const StyledEditIcon = styled(EditIcon)`
  width: 18px;
  padding-right: 4px;
`;

export const StyledErrorOutlinedIcon = styled(ErrorOutlineOutlinedIcon)`
  color: ${colors.critical.base};
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    width: 100%;
    height: 34px;
  }
  fieldset {
    legend {
      width: unset;
    }
  }
`;

export const StyledSelect = styled(Select)`
  fieldset {
    legend {
      width: unset;
    }
  }
`;
