import { Box, Stack, Divider } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isEqual } from "lodash";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

import { ReactComponent as CheckedMailIcon } from "assets/icons/email_with_check.svg";
import { ControlledAutocomplete } from "components/library/Autocomplete";
import { Banner, BannerVariant } from "components/library/Banner";
import { Body, BodySmall, Subtitle1 } from "components/library/typography";
import AnimatedLoadingEllipsis from "components/loading-overlay/LoadingEllipsis";
import { useDebounceState } from "hooks/useDebounceState";
import { CRITICAL_DEPTH_THRESHOLD, useIsOutOfDepth } from "services/doverapi/cross-endpoint-hooks/useIsOutOfDepth";
import { useShouldShowRealProfiles } from "services/doverapi/endpoints/client/hooks";
import { useGetSearchV3DepthResultQuery, useGetSearchV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import { roundDepthCount } from "services/doverapi/endpoints/search-v3/utils";
import { ApiApiGetSearchV3DepthResultRequest, ProfileRequestSortByEnum } from "services/openapi";
import { colors } from "styles/theme";
import CandidateCardList from "views/sourcing/Search/components/CandidateCardList";
import { SAAP_PARAMS_DEBOUNCE, SORTBY_NAME } from "views/sourcing/Search/constants";
import { FormLoadStateContext } from "views/sourcing/Search/context/FilterToggleContext";
import { useSearchId } from "views/sourcing/Search/hooks";
import { searchV3FormSchema, SearchV3FormSchemaType, SourcingContext } from "views/sourcing/Search/types";
import { getDepthAdjustmentSuggestions, getSearchV3FromFormState } from "views/sourcing/Search/utils";

interface SourcingCandidateViewProps {
  context?: SourcingContext;
}

const StyledSortByMenuWrapper = styled(Box)`
  width: 180px;
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator {
    display: none;
  }
`;

const SortByMenu = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const sortBy = useWatch({ control, name: SORTBY_NAME });

    return (
      <StyledSortByMenuWrapper>
        <ControlledAutocomplete
          control={control}
          fontSize="small"
          placeholder={"Sort by"}
          name={SORTBY_NAME}
          filterSelectedOptions={false}
          staticOptions={Object.values(ProfileRequestSortByEnum)}
          getOptionLabel={(option: ProfileRequestSortByEnum): string => {
            switch (option) {
              case ProfileRequestSortByEnum.Desc:
                return "Best match first";
              case ProfileRequestSortByEnum.Asc:
                return "Worst match first";
            }
          }}
          initialValue={sortBy}
        />
      </StyledSortByMenuWrapper>
    );
  }
);

const SourcingCandidateView = React.memo(
  ({ context }: SourcingCandidateViewProps): React.ReactElement => {
    const searchId = useSearchId();
    const { data: search, isLoading: isSearchLoading } = useGetSearchV3Query(searchId ? { id: searchId } : skipToken);
    const showRealProfilesAndAllowCalibration = useShouldShowRealProfiles();

    const {
      control,
      formState: { errors },
    } = useFormContext<SearchV3FormSchemaType>();
    const values = useWatch({ control });

    const initialFormValuesLoaded = React.useContext(FormLoadStateContext)?.loaded;
    const numErrors = Object.keys(errors).length;

    const [debouncedSearchParams, setSearchParams, searchParams] = useDebounceState<
      ApiApiGetSearchV3DepthResultRequest | undefined
    >(undefined, SAAP_PARAMS_DEBOUNCE);

    const fieldsInErrorState = React.useMemo(() => {
      if (numErrors === 0) {
        return [];
      }

      return Object.keys(errors);
    }, [errors, numErrors]);

    const hasErrors = React.useMemo(() => {
      return initialFormValuesLoaded && fieldsInErrorState.length > 0;
    }, [initialFormValuesLoaded, fieldsInErrorState.length]);

    React.useEffect(() => {
      // If a search is undefined or loading, we don't wish to proceed
      if (search === undefined || isSearchLoading) {
        return;
      }

      if (!initialFormValuesLoaded) {
        return;
      }

      const formParseResult = searchV3FormSchema.safeParse(values);
      if (!formParseResult.success) {
        // Since the form was not parsed correctly, stop here
        return;
      }

      // After updates, we'll get a referentially different search object from RTKQ
      // It's important the we check deep equality against the new search params so we don't perform an additional unnecessary update
      const newSearchParams = getSearchV3FromFormState(formParseResult.data, search).v3Params;
      if (isEqual(search.v3Params, newSearchParams)) {
        return;
      }

      return setSearchParams({ data: { params: newSearchParams, searchId: search.id } });
    }, [initialFormValuesLoaded, isSearchLoading, search, setSearchParams, values]);

    const readyToRequestDepths = initialFormValuesLoaded && debouncedSearchParams;
    const { data: depthCount, isFetching: isDepthFetching } = useGetSearchV3DepthResultQuery(
      // technically dont need the debouncedSearchParams check after readyToRequestDepths but typescript too dumb
      readyToRequestDepths && debouncedSearchParams ? debouncedSearchParams : skipToken
    );

    const { data: excludeNoEmailsDepthCount, isFetching: isEmailCountsFetching } = useGetSearchV3DepthResultQuery(
      // technically dont need the debouncedSearchParams check after readyToRequestDepths but typescript too dumb
      readyToRequestDepths && debouncedSearchParams
        ? { data: { ...debouncedSearchParams.data, excludeNoEmailsPeople: true } }
        : skipToken
    );

    const criticallyLowDepth = React.useMemo(() => {
      return depthCount && depthCount.count <= CRITICAL_DEPTH_THRESHOLD;
    }, [depthCount]);

    const simpleDepthMessage = React.useMemo(() => {
      const availDepth = depthCount?.count ?? 0;
      const emailDepth = excludeNoEmailsDepthCount?.count ?? 0;
      const numContacted = search?.numContacted ?? 0;
      const totalDepth = roundDepthCount(availDepth + numContacted);
      const depthMessage = (
        <Stack direction="row" spacing={0.6} justifyContent="space-between" alignItems="center">
          <Subtitle1>{totalDepth}</Subtitle1>
          <Body>total</Body>
          <Divider sx={{ backgroundColor: colors.grayscale.gray500, height: "8px", width: "1px" }} />
          <Subtitle1>{roundDepthCount(availDepth)} </Subtitle1>
          <Body>not reached out to</Body>
          <Divider sx={{ backgroundColor: colors.grayscale.gray500, height: "8px", width: "1px" }} />
          <CheckedMailIcon />
          <Subtitle1>{roundDepthCount(emailDepth)} </Subtitle1>
          <Body>emails found</Body>
        </Stack>
      );

      return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            {depthMessage}
            {!criticallyLowDepth && (
              <BodySmall color={colors.grayscale.gray500}>{"See sample candidates below"}</BodySmall>
            )}
          </Stack>
          {showRealProfilesAndAllowCalibration && <SortByMenu />}
        </Stack>
      );
    }, [
      depthCount?.count,
      search?.numContacted,
      excludeNoEmailsDepthCount?.count,
      criticallyLowDepth,
      showRealProfilesAndAllowCalibration,
    ]);

    const depthText = React.useMemo(() => {
      if (hasErrors) {
        return <Subtitle1>{`Failed to calculate matching candidates`}</Subtitle1>;
      }
      if (!readyToRequestDepths || isDepthFetching || isEmailCountsFetching || isSearchLoading) {
        return (
          <Stack direction="row" minWidth="280px">
            <Subtitle1>Calculating matching candidates</Subtitle1>
            <AnimatedLoadingEllipsis />
          </Stack>
        );
      }
      return simpleDepthMessage;
    }, [readyToRequestDepths, hasErrors, isDepthFetching, isEmailCountsFetching, isSearchLoading, simpleDepthMessage]);

    const [bannerExpanded, setBannerExpanded] = React.useState<boolean>(false);
    const toggleBannerExpanded = React.useCallback(() => {
      setBannerExpanded(!bannerExpanded);
    }, [bannerExpanded]);

    const { data: isOutOfDepth } = useIsOutOfDepth(search?.job, searchParams);

    React.useEffect(() => {
      if (!isOutOfDepth) {
        // When we hide the banner, set the expansion state back to default
        setBannerExpanded(false);
      }
    }, [isOutOfDepth]);

    const outOfDepthBanner = React.useMemo(() => {
      const formParseResult = searchV3FormSchema.safeParse(values);
      if (!formParseResult.success) {
        return <></>;
      }

      const suggestions = getDepthAdjustmentSuggestions(formParseResult.data);
      // it doesnt matter whether we have any suggestions when depth is critically low (<50)
      // however, given there are suggestions for Persona OR Custom titles, there should always be at least 1 suggestion
      if (suggestions.length === 0 && !criticallyLowDepth) {
        return <></>;
      }
      return (
        <Banner variant={criticallyLowDepth ? BannerVariant.Critical : BannerVariant.Warning}>
          <Stack spacing={0.5} alignItems="left">
            {!criticallyLowDepth && <BodySmall>{"Less than 2 weeks of outreach available"}</BodySmall>}
            {criticallyLowDepth && <BodySmall>{"Relax your filters to see examples of matching candidates"}</BodySmall>}
            {bannerExpanded ? (
              <BodySmall onClick={toggleBannerExpanded} pointer={true} color={colors.linkLight}>
                {"Hide suggestions"}
              </BodySmall>
            ) : (
              <Stack direction="row" alignItems="center" spacing={"3px"}>
                <BodySmall pointer={true} onClick={toggleBannerExpanded} color={colors.linkLight} inline={true}>
                  {"View suggestions"}
                </BodySmall>
                <BodySmall inline={true}>{"to expand the candidate pool"}</BodySmall>
              </Stack>
            )}
            {bannerExpanded && (
              <BodySmall>
                <ul style={{ marginTop: "4px", marginBottom: "0px", position: "relative", right: "15px" }}>
                  {suggestions.slice(0, 3).map(suggestion => {
                    return <li key={suggestion}>{suggestion}</li>;
                  })}
                </ul>
              </BodySmall>
            )}
          </Stack>
        </Banner>
      );
    }, [bannerExpanded, criticallyLowDepth, toggleBannerExpanded, values]);

    const emptyResultMessage = React.useMemo(() => {
      if (hasErrors) {
        return (
          <Stack alignItems="center" spacing={1} marginTop="200px !important" textAlign={"center"}>
            <Body weight="500">{"Please correct the errors in the following fields"}</Body>
            <ul>
              {fieldsInErrorState.map((fieldWithError, index) => {
                return (
                  <li key={index}>
                    <BodySmall>{fieldWithError}</BodySmall>
                  </li>
                );
              })}
            </ul>
          </Stack>
        );
      }

      return (
        <Stack alignItems="center" spacing={1} marginTop="200px !important" textAlign={"center"}>
          <Body weight="500">{"No results found"}</Body>
          <BodySmall>{"Please adjust your search"}</BodySmall>
        </Stack>
      );
    }, [fieldsInErrorState, hasErrors]);

    const candidateCardList = React.useMemo(() => {
      if (!hasErrors && (depthCount === undefined || depthCount.count > 0 || isDepthFetching)) {
        return <CandidateCardList context={context} />;
      }

      return emptyResultMessage;
    }, [context, depthCount, emptyResultMessage, hasErrors, isDepthFetching]);

    return (
      <Stack pt={1} spacing={1} marginX={2} width="100%">
        {/* only show the depth text if its not critically low depth (<50) */}
        {!criticallyLowDepth && depthText}
        {isOutOfDepth && outOfDepthBanner}
        {candidateCardList}
      </Stack>
    );
  }
);

export default SourcingCandidateView;
