import { Box, Menu, MenuItem, Stack } from "@mui/material";
import clipboardCopy from "clipboard-copy";
import React from "react";

import { ReactComponent as CopyLinkIcon } from "assets/icons/copy.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linked-in.svg";
import { ReactComponent as EmailIcon } from "assets/icons/mail-dark-outline.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter-x-icon.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Overline } from "components/library/typography";
import { colors } from "styles/theme";
import { showDefaultToast } from "utils/showToast";

/* -----------------------------------------------------------------------------
 * Helper components
 * -------------------------------------------------------------------------- */

interface SocialShareMenuItemProps {
  onClick?: () => void;
  icon: React.ReactNode;
  text: string;
}

const SocialShareMenuItem: React.FC<SocialShareMenuItemProps> = ({ onClick, icon, text }) => {
  return (
    <MenuItem onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center">
        {icon}
        <BodySmall>{text}</BodySmall>
      </Stack>
    </MenuItem>
  );
};

/* -----------------------------------------------------------------------------
 * Main components
 * -------------------------------------------------------------------------- */

interface SocialShareButtonProps {
  url: string;
  socialPostText: string;
  emailSubject?: string;
  emailBody?: string;
  menuLabel?: string;
}

const SocialShareButton: React.FC<SocialShareButtonProps> = ({
  url,
  socialPostText,
  menuLabel,
  emailSubject,
  emailBody,
}) => {
  const [menuAnchorEl, setMenuAnchorClose] = React.useState(null);

  // Encode inputs to use in URLs
  // (Ensures special characters, line breaks, and spaces are properly encoded to avoid issues with rendering or interpretation)
  const encodedUrl = encodeURIComponent(url);
  const encodedText = encodeURIComponent(socialPostText);
  const encodedEmailSubject = emailSubject ? encodeURIComponent(emailSubject) : "";
  const encodedEmailBody = emailBody ? encodeURIComponent(emailBody) : "";

  const handleMoreMenuItemClick = (event: React.MouseEvent<any>): void => {
    setMenuAnchorClose(event.currentTarget);
  };
  const handleMoreMenuItemClose = (): void => {
    setMenuAnchorClose(null);
  };

  const handleCopyLink = (): void => {
    clipboardCopy(url);
    showDefaultToast("Link copied to clipboard!");
  };

  const handleShareOnLinkedIn = (): void => {
    window.open(`https://www.linkedin.com/shareArticle?mini=false&url=${encodedUrl}&text=${encodedText}`, "_blank");
  };

  const handleShareOnTwitter = (): void => {
    // Twitter link preview doesn't seem to work, so removed url parameter to prevent two links from showing
    window.open(`https://twitter.com/intent/tweet?text=${encodedText}`, "_blank");
  };

  const handleEmailShare = (): void => {
    const mailToLink = `mailto:?subject=${encodedEmailSubject}&body=${encodedEmailBody}`;
    window.location.href = mailToLink;
  };

  return (
    <>
      <Button variant={ButtonVariant.Secondary} onClick={handleMoreMenuItemClick} padding="4px 8px">
        <Stack direction="row" spacing={1} alignItems="center">
          <ShareIcon color={colors.informational.dark} className="svg-color" />
          <BodySmall>Share</BodySmall>
        </Stack>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(menuAnchorEl)}
        onClose={handleMoreMenuItemClose}
        MenuListProps={{
          onMouseLeave: handleMoreMenuItemClose,
          onClick: handleMoreMenuItemClose,
        }}
        PaperProps={{
          sx: { minWidth: "200px" },
        }}
      >
        {menuLabel && (
          <Box px={2} py={0.5}>
            <Overline color={colors.grayscale.gray600}>{menuLabel}</Overline>
          </Box>
        )}
        <SocialShareMenuItem onClick={handleCopyLink} icon={<CopyLinkIcon />} text="Copy link" />
        <SocialShareMenuItem onClick={handleShareOnLinkedIn} icon={<LinkedInIcon />} text="Draft post on LinkedIn" />
        <SocialShareMenuItem onClick={handleShareOnTwitter} icon={<TwitterIcon />} text="Draft post on X" />

        {emailSubject && emailBody && (
          <SocialShareMenuItem onClick={handleEmailShare} icon={<EmailIcon />} text="Draft email" />
        )}
      </Menu>
    </>
  );
};

export default SocialShareButton;
