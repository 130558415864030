/* -----------------------------------------------------------------------------
 * emptyV3Params
 * -------------------------------------------------------------------------- */

// NOTE(jhh3): For the flow we have two different location selectors.
// The values between them are almost but not exactly the same
// The following maps between differeneces in the top 20 US cities
// worth noting that the first selector has a very large set of possiblities
// while the second only has ~300. The first is only cities, The second is a
// mix of city state country
// TODO: align these two selectors to fix things
export const hackyLocationMap: { [key: string]: string } = {
  "New York City, NY": "New York, NY",
  "Washington, DC": "Washington DC",
};

export const emptyV3Params = {
  targetPersonas: [],
  targetCustomTitles: [],
  excludedCustomTitles: [],
  customTitlesSoftMatch: [],
  personasMostRecentOnly: [],
  targetLocations: [],
  excludedLocations: [],
  targetSeniorities: [],
  excludedSeniorities: [],
  totalYearsOfExperience: [],
  specificYearsOfExperience: [],
  specificYearsOfExperienceByT2: [],
  yearsAtCurrentCompanyRange: [],
  bachelorsGraduationYearRange: [],
  industries: [],
  excludedIndustries: [],
  industriesMostRecentOnly: [],
  targetCompanyLists: [],
  excludedCompanyLists: [],
  individualTargetCompanyIds: [],
  individualExcludedCompanyIds: [],
  targetCompaniesMostRecentOnly: [],
  targetCompaniesOnly: [],
  companyRankRange: [],
  companySize: [],
  excludedCompanySize: [],
  targetSchoolLists: [],
  excludedSchoolLists: [],
  individualTargetSchoolIds: [],
  individualTargetSchoolNames: [],
  individualExcludedSchoolIds: [],
  individualExcludedSchoolNames: [],
  schoolRankRange: [],
  fieldsOfStudy: [],
  educationLevel: [],
  keywords: [],
  deniedKeywords: [],
  diversity: [],
  excludeFrequentJobSwitching: [],
  customQueries: [],
  minScoreRatio: 0,
  isReengagementSearch: false,
  transformsToApplyDuringNormalization: [],
};
