import { Box, Stack, StackProps } from "@mui/material";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { ReactComponent as ArrowLeft } from "assets/icons/chevron-left.svg";
import { ReactComponent as ArrowRight } from "assets/icons/chevron-right.svg";
import { BodySmall, Overline } from "components/library/typography";
import { useIsInViewport } from "hooks/useIsInViewport";
import { HiringPipelineStage, HiringPipelineStageType } from "services/openapi";
import { colors } from "styles/theme";
import {
  useCounts,
  useParams,
  useAreStagesActive,
  useInterviewingStages,
  useIsContactedStageSelected,
  useIsApplicantsActive,
  useIsLeadsActive,
  useIsInterviewingActive,
} from "views/candidates/hooks";
import { PipelineProcessingStage, PipelineProcessingStageMapping } from "views/candidates/types";
import { useStageIdsByType, useStagesByType } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

const SCROLL_INCREMENT = 150;

export const CandidateTableTabs = (): React.ReactElement => {
  const isApplicantsActive = useIsApplicantsActive();
  const isLeadsActive = useIsLeadsActive();
  const isInterviewingActive = useIsInterviewingActive();

  const [scrollableRef, setScrollableRef] = useState<HTMLDivElement | null>(null);

  // When the scroll buttons are clicked, we perform a scroll operation on the scrollable content
  const scrollLeft = (): void => scrollableRef?.scrollBy({ left: -SCROLL_INCREMENT, top: 0, behavior: "smooth" });
  const scrollRight = (): void => scrollableRef?.scrollBy({ left: SCROLL_INCREMENT, top: 0, behavior: "smooth" });

  // Track the start and end of the tabs by ref so we can determine if they're in the viewport
  const tabsStartRef = React.useRef<HTMLAnchorElement>(null);
  const tabsEndRef = React.useRef<HTMLAnchorElement>(null);

  // When the start or end of the tabs is in the viewport, we'll hide the corresponding arrow
  const isTabStartInViewport = useIsInViewport(tabsStartRef);
  const isTabRightInViewport = useIsInViewport(tabsEndRef);

  // Track whether or not the scroll buttons should be visible
  const showLeftArrow = useMemo(() => !isTabStartInViewport, [isTabStartInViewport]);
  const showRightArrow = useMemo(() => !isTabRightInViewport, [isTabRightInViewport]);

  // When the scrollable content ref is mounted, we'll set it in state so we can perform operations on it
  const onScrollableRefMount = useCallback((node: HTMLDivElement): void => {
    setScrollableRef(node);
  }, []);

  return (
    <Box position="relative" bgcolor={isInterviewingActive ? colors.white : colors.grayscale.gray100}>
      <ScrollArrowContainer
        justifyContent="center"
        arrowDirection="left"
        onClick={scrollLeft}
        visibility={showLeftArrow ? "visible" : "hidden"}
      >
        <ArrowLeft />
      </ScrollArrowContainer>
      <TabsContainer ref={onScrollableRefMount}>
        <Stack direction="row" minWidth="1100px">
          <Box ref={tabsStartRef} />
          <TabContainer
            props={{
              bgcolor: isLeadsActive ? colors.white : colors.grayscale.gray100,
              borderRight: `1px solid ${colors.grayscale.gray300}`,
            }}
          >
            <Stack direction="row" spacing={0.5}>
              <Overline color={colors.grayscale.gray500}>Leads</Overline>
            </Stack>
            <LeadsStagesTabs />
          </TabContainer>
          <TabContainer
            props={{
              bgcolor: isApplicantsActive ? colors.white : colors.grayscale.gray100,
            }}
          >
            <Stack direction="row" spacing={0.5}>
              <Overline color={colors.grayscale.gray500}>Applicants</Overline>
            </Stack>
            <ApplicationTabs />
          </TabContainer>
          <TabContainer
            props={{
              bgcolor: isInterviewingActive ? colors.white : colors.grayscale.gray100,
              borderLeft: `1px solid ${colors.grayscale.gray300}`,
            }}
          >
            <Overline color={colors.grayscale.gray500}>Interviewing</Overline>
            <InterviewingStagesTabs />
          </TabContainer>
          <Box ref={tabsEndRef} />
        </Stack>
      </TabsContainer>
      <ScrollArrowContainer
        justifyContent="center"
        arrowDirection="right"
        onClick={scrollRight}
        visibility={showRightArrow ? "visible" : "hidden"}
      >
        <ArrowRight />
      </ScrollArrowContainer>
    </Box>
  );
};

const TabContainer = ({ props, children }: { props: StackProps; children: React.ReactNode }): React.ReactElement => (
  <Stack spacing={1} padding="8px" paddingBottom="0" flexShrink={0} {...props}>
    {children}
  </Stack>
);

const StageCount: React.FC<{
  count?: number;
}> = ({ count }) => {
  if (count === undefined) {
    return <></>;
  }

  return (
    <Box bgcolor={colors.grayscale.gray200} borderRadius="100px" paddingX="6px">
      <Overline>{count}</Overline>
    </Box>
  );
};

interface StageTabProps {
  handleClick: () => void;
  isActive: boolean;
  name: string;
}

const StageTab: React.FC<StageTabProps> = ({ handleClick, isActive, name }) => {
  const { counts, isFetching } = useCounts();
  const count = counts && counts[name];

  return (
    <StageTabContainer onClick={handleClick} active={isActive}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <BodySmall
          weight={isActive ? "600" : "400"}
          color={isActive ? colors.grayscale.gray700 : colors.grayscale.gray400}
        >
          {name}
        </BodySmall>
        {!isFetching && <StageCount count={count} />}
      </Stack>
    </StageTabContainer>
  );
};

const ApplicationTabs: React.FC = () => {
  const [, setParams] = useParams();

  const appReviewStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.APPLICATION_REVIEW });
  const appReviewStages = useStagesByType({ stageType: HiringPipelineStageType.APPLICATION_REVIEW });

  const isActive = useAreStagesActive(appReviewStageIds);

  const handleClick = useCallback((): void => {
    if (!isActive) {
      setParams({ hpsId: appReviewStageIds, substages: [] });
      setParams({ page: 0 });
    }
  }, [appReviewStageIds, isActive, setParams]);

  if (!appReviewStages?.length) {
    return <></>;
  }

  return <StageTab handleClick={handleClick} isActive={isActive} name={appReviewStages[0].name} />;
};

const LeadsStagesTabs: React.FC = () => {
  const queuedStages =
    useStagesByType({ stageType: HiringPipelineStageType.QUEUED })?.map(stage => {
      return {
        name: stage.name,
        processingStage: PipelineProcessingStage.Queued,
        stageIds: [stage.id],
      };
    }) ?? [];
  const contactedStages =
    useStagesByType({ stageType: HiringPipelineStageType.CONTACTED })?.map(stage => {
      return {
        name: stage.name,
        processingStage: PipelineProcessingStage.Contacted,
        stageIds: [stage.id],
      };
    }) ?? [];

  const respondedStages =
    useStagesByType({ stageType: HiringPipelineStageType.RESPONDED })?.map(stage => {
      return {
        name: stage.name,
        processingStage: PipelineProcessingStage.Responded,
        stageIds: [stage.id],
      };
    }) ?? [];

  const leadStages = [...queuedStages, ...contactedStages, ...respondedStages];

  return (
    <Stack direction={"row"}>
      {leadStages.map(stage => (
        <LeadsStagesTab stage={stage} />
      ))}
    </Stack>
  );
};

interface LeadsStagesTabProps {
  stage: PipelineProcessingStageMapping;
}

const LeadsStagesTab: React.FC<LeadsStagesTabProps> = ({ stage }) => {
  const [, setParams] = useParams();

  // Contacted stage needs different logic becuase it can be filtered down to specific stages
  const isContactedStageSelected = useIsContactedStageSelected();
  const isStageActive = useAreStagesActive(stage.stageIds);
  const isActive =
    stage.processingStage === PipelineProcessingStage.Contacted ? isContactedStageSelected : isStageActive;

  const handleClick = useCallback((): void => {
    if (!isActive) {
      setParams({ hpsId: [], substages: [] });
      setParams({ hpsId: stage.stageIds, substages: [] });
      setParams({ page: 0 });
    }
  }, [isActive, setParams, stage.stageIds]);

  return <StageTab key={stage.processingStage} handleClick={handleClick} isActive={isActive} name={stage.name} />;
};

const InterviewingStagesTabs = (): ReactElement => {
  const stages = useInterviewingStages();

  return (
    <Stack direction={"row"}>
      {stages?.map(stage => (
        <InterviewingStagesTab key={stage.id} stage={stage} />
      ))}
    </Stack>
  );
};

interface InterviewingStagesTabProps {
  stage: HiringPipelineStage;
}

const InterviewingStagesTab: React.FC<InterviewingStagesTabProps> = ({ stage }) => {
  const [, setParams] = useParams();

  const stageArray = React.useMemo(() => [stage.id], [stage.id]);

  const isActive = useAreStagesActive(stageArray);

  const handleClick = useCallback((): void => {
    if (!isActive) {
      setParams({ hpsId: stageArray, substages: [] });
      setParams({ page: 0 });
    }
  }, [isActive, setParams, stageArray]);

  return <StageTab handleClick={handleClick} isActive={isActive} name={stage.name!} />;
};

interface StageTabContainerProps {
  active?: boolean;
}

const StageTabContainer = styled.div<StageTabContainerProps>`
  cursor: pointer;
  padding: 0.5em 1em;
  box-sizing: border-box;
  min-width: fit-content;
  margin-bottom: -1px;
  border-bottom: ${(props): string =>
    props.active ? `3px solid ${props.theme.colors.primary.base}` : "3px solid transparent"};
`;

const TabsContainer = styled(Box)`
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
`;

interface ScrollArrowProps {
  arrowDirection: "left" | "right";
}

const ScrollArrowContainer = styled(Stack)<ScrollArrowProps>`
  height: 100%;
  padding: 0 3px;
  position: absolute;
  left: ${(props): string => (props.arrowDirection === "left" ? "0" : "auto")};
  right: ${(props): string => (props.arrowDirection === "right" ? "0" : "auto")};
  border-${(props): string => (props.arrowDirection === "left" ? "right" : "left")}: solid 1px ${
  colors.grayscale.gray300
};
  top 0;
  cursor: pointer;
  background-color: ${colors.white};
  z-index: 1;
  &:hover {
    background-color: ${colors.grayscale.gray100};
  }
`;
