import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { doverTypeToJson } from "services/doverapi/doverTypeToJson";
import { FEEDBACK_TEMPLATE, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiListFeedbackTemplatesRequest,
  ApiApiListInterviewRubricTemplatesForPersonaRequest,
  BaseFeedbackTemplateQuestion,
  FeedbackTemplateDetail,
  FeedbackTemplateListResponse,
  InterviewRubricTemplate,
} from "services/openapi";
import { showErrorToast, showSuccessToast } from "utils/showToast";

interface CreateFeedbackTemplateRequest {
  data: {
    name: string;
    questions: Array<BaseFeedbackTemplateQuestion>;
  };
}

interface UpdateFeedbackTemplateRequest {
  id: string;
  data: {
    name: string;
    questions: Array<BaseFeedbackTemplateQuestion>;
  };
}

const feedbackTemplates = doverApi.injectEndpoints({
  endpoints: build => ({
    createFeedbackTemplate: build.mutation<FeedbackTemplateDetail, CreateFeedbackTemplateRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const resp = await client.createFeedbackTemplate({
            data: {
              name: args.data.name,
              questions: doverTypeToJson(args.data.questions),
            },
          });
          showSuccessToast("Created new feedback form.");
          return { data: resp };
        } catch (error) {
          const errorDetail = await error.json();
          if (errorDetail.error) {
            showErrorToast(errorDetail.error);
          } else {
            showErrorToast(
              "Failed to create feedback form. Please ensure all questions are labeled, and any Select questions have at least one option."
            );
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: () => {
        return [{ type: FEEDBACK_TEMPLATE, id: LIST_TAG }];
      },
    }),
    getFeedbackTemplate: build.query<FeedbackTemplateDetail, string>({
      queryFn: async id => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.getFeedbackTemplate({ id });

          return {
            data: response,
          };
        } catch (error) {
          const errorDetail = await error.json();
          if (errorDetail.error) {
            showErrorToast(errorDetail.error);
          } else {
            showErrorToast("Failed to fetch feedback form. Please refresh and try again.");
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: FEEDBACK_TEMPLATE, id: result.id }] : [];
      },
    }),
    updateFeedbackTemplate: build.mutation<FeedbackTemplateDetail, UpdateFeedbackTemplateRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const resp = await client.partialUpdateFeedbackTemplate({
            id: args.id,
            data: {
              name: args.data.name,
              questions: doverTypeToJson(args.data.questions),
            },
          });
          return { data: resp };
        } catch (error) {
          const errorDetail = await error.json();
          if (errorDetail.error) {
            showErrorToast(errorDetail.error);
          } else {
            showErrorToast("Failed to update feedback form. Please refresh and try again.");
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, { id }) => {
        return result ? [{ type: FEEDBACK_TEMPLATE, id }] : [];
      },
      onQueryStarted: async ({ id, data }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          // Optimistically update the feedback template name in the list view
          feedbackTemplates.util.updateQueryData("listFeedbackTemplates", {}, draft => {
            const updatedTemplate = draft.results.find(template => template.id === id);
            if (updatedTemplate) {
              updatedTemplate.name = data.name;
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          // Undo our optimistic updates if the mutation ended up failing server-side
          patch.undo();
        }
      },
    }),
    deleteFeedbackTemplate: build.mutation<void, string>({
      queryFn: async id => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          await client.deleteFeedbackTemplate({ id });
          return { data: undefined };
        } catch (error) {
          const errorDetail = await error.json();
          if (errorDetail.error) {
            showErrorToast(errorDetail.error);
          } else {
            showErrorToast("Failed to delete feedback form. Please refresh and try again.");
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          // Optimistically remove the deleted feedback template from the list view
          feedbackTemplates.util.updateQueryData("listFeedbackTemplates", {}, draft => {
            const deletedTemplateIndex = draft.results.findIndex(template => template.id === id);
            draft.results.splice(deletedTemplateIndex, 1);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          // Undo our optimistic updates if the mutation ended up failing server-side
          patch.undo();
        }
      },
      invalidatesTags: (result, error, id) => {
        return [{ type: FEEDBACK_TEMPLATE, id }];
      },
    }),
    listFeedbackTemplates: build.query<FeedbackTemplateListResponse, ApiApiListFeedbackTemplatesRequest>({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listFeedbackTemplates(args);

          return { data: response };
        } catch (error) {
          let errorMessage = "Failed to fetch feedback forms. Please refresh and try again.";
          if (error instanceof Response) {
            try {
              const errorData = await error.json();
              if (errorData.error) {
                errorMessage = errorData.error;
              }
            } catch {
              // If we can't parse the error JSON, use the default message
            }
          }

          showErrorToast(errorMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result
          ? [
              ...result.results.map(template => ({ type: FEEDBACK_TEMPLATE, id: template.id } as const)),
              { type: FEEDBACK_TEMPLATE, id: LIST_TAG },
            ]
          : [{ type: FEEDBACK_TEMPLATE, id: LIST_TAG }];
      },
    }),
    listInterviewRubricTemplatesForPersona: build.query<
      InterviewRubricTemplate[],
      ApiApiListInterviewRubricTemplatesForPersonaRequest
    >({
      queryFn: async args => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listInterviewRubricTemplatesForPersona(args);

          return {
            data: response.results,
          };
        } catch (error) {
          showErrorToast("Failed to fetch templates for persona. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useCreateFeedbackTemplateMutation,
  useGetFeedbackTemplateQuery,
  useUpdateFeedbackTemplateMutation,
  useDeleteFeedbackTemplateMutation,
  useListFeedbackTemplatesQuery,
  useListInterviewRubricTemplatesForPersonaQuery,
} = feedbackTemplates;
