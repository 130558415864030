import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

const SHOW_UPSELL_INTERVAL = 15;
const APP_REVIEW_NUM_ACTIONED_CANDIDATES_KEY = "app_review_num_actioned_candidates";

export const numActionedCandidatesAtom = atomWithStorage(APP_REVIEW_NUM_ACTIONED_CANDIDATES_KEY, 0);

export const incrementNumActionedCandidatesAtom = atom(null, (get, set) => {
  set(numActionedCandidatesAtom, get(numActionedCandidatesAtom) + 1);
});

export const resetNumActionedCandidatesAtom = atom(null, (get, set) => {
  set(numActionedCandidatesAtom, 0);
});

export const shouldShowRpUpsellAtom = atom(get => {
  const numActionedCandidates = get(numActionedCandidatesAtom);

  return numActionedCandidates > 0 && numActionedCandidates % SHOW_UPSELL_INTERVAL === 0;
});
