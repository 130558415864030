import { Option } from "components/dover/CustomizeNextSteps/types";
import { CandidateBioContactInfo, CandidateBioContactInfoTypeEnum } from "services/openapi";

const orderIndexToString = [
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eigth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "Thirteenth",
  "Fourteenth",
];

export function getOrderRequiredOptions(idx: number): Option[] {
  return [
    { value: 0, label: "Any" },
    { value: 1, label: orderIndexToString[idx] },
  ];
}

export const getPreferredCandidateBioEmail = (contactInfo: CandidateBioContactInfo[]): string | null => {
  // Choose a primary email from the list of contact info, if one exists.
  // Otherwise, choose an arbitrary email from the list.
  // Note that this logic is *not* in sync with the backend logic that determines which contact email is used in communications.
  const emails = contactInfo.filter(contact => contact.type === CandidateBioContactInfoTypeEnum.Email);
  const emailsMarkedPrimary = emails.filter(contact => contact.isPrimary);
  if (emailsMarkedPrimary.length) {
    return emailsMarkedPrimary[0].value;
  } else if (emails.length) {
    return emails[0].value;
  }
  return null;
};

export const replaceTemplateVarsInHtml = (html: string, variables: { [key: string]: any }): any => {
  let cleanedHtml = html;

  // Replace variables if a value is available
  for (const variable in variables) {
    if (variables[variable]) {
      cleanedHtml = cleanedHtml.replace(`{{${variable}}}`, variables[variable]);
    }
  }

  return cleanedHtml;
};
