import { SerializedError } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { LIST_TAG, LIST_TALENT_NETWORK } from "services/doverapi/endpointTagsConstants";
import { TalentNetworkRequest, TalentNetworkReturn } from "services/openapi";

export const talentNetworkEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listDoverTalents: build.query<TalentNetworkReturn, TalentNetworkRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});

          const result = await client.listTalentNetwork({ data: args });
          return { data: result };
        } catch (error) {
          const userFacingMessage = "Failed to load applicants. Please refresh and try again.";
          toast(userFacingMessage, {
            autoClose: 2000,
            position: toast.POSITION.TOP_CENTER,
            type: toast.TYPE.ERROR,
          });
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: _ => [{ type: LIST_TALENT_NETWORK, id: LIST_TAG }],
    }),
  }),
});

export const { useListDoverTalentsQuery } = talentNetworkEndpoints;
