export const setupHiringPipelineReducerKey = "SetupHiringPipeline";

const INITIAL_CALL = "Initial call";
const SECOND_INTERVIEW = "Second interview";
const TAKE_HOME = "Take-home";
const FINAL_INTERVIEW = "Final interview";

export type StageName = typeof INITIAL_CALL | typeof SECOND_INTERVIEW | typeof TAKE_HOME | typeof FINAL_INTERVIEW;

export const STAGE_NAMES: StageName[] = [INITIAL_CALL, SECOND_INTERVIEW, TAKE_HOME, FINAL_INTERVIEW];

// These are names of Greenhouse Stages that should be disabled in the UI.
export const GREENHOUSE_DISABLE_STAGES = ["Application Review"];

// Lever stages to disable in the UI because they cannot be mapped to Dover stages
// Note these are currently case sensitive
export const LEVER_DISABLE_STAGES = ["Responded", "Reached out", "New lead", "New applicant", "Contacted"];

export const ASHBY_DISABLE_STAGES = ["new lead", "reached out", "replied", "application review", "archived", "hired"];

export const DOVER_STAGE_MAPPING = {
  INITIAL_CALL: 500,
  SECOND_ASSESSMENT: 600, // refers to Second Interview or Take-Home
  SECOND_ASSESSMENT_2: 650, // refers to Second Interview or Take-Home
  ONSITE: 700,
  FINAL_INTERVIEW: 750,
};

export const DEFAULT_DEBRIEF_DURATION = 15 * 60; // 15 minutes

export const DOVER_INTERVIEWER_HELP_LINK = "https://help.dover.com/en/articles/6229954-dover-interviewers";
export const DOVER_INTERVIEWER_INFO_TEXT = "Dover conducted 27,296 interviews for roles like this last year.";
export const END_TO_END_SCHEDULING_HELP_LINK =
  "https://help.dover.com/en/articles/6390362-end-to-end-scheduling-with-dover";
export const END_TO_END_SCHEDULING_INFO_TEXT =
  "Dover can help you schedule candidates from the moment they enter your funnel to the moment you’re ready to send them an offer.";
