/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button as DoverButton } from "@doverhq/dover-ui";
import { OpenInNew } from "@mui/icons-material";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import Emoji from "components/Emoji";
import { Spacer } from "components/Spacer";
import { useLazyGetInitialCallInterviewQuery } from "services/doverapi/endpoints/candidate";
import { InterviewAndCandidateIdData } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useLazyGetInterviewRubricResponseQuery } from "services/doverapi/endpoints/candidateInterview";
import { useCurrentDoverEntity } from "services/doverapi/endpoints/doverEntity/customHooks";
import { useGetInterviewsQuery } from "services/doverapi/endpoints/interview";
import { ShortenedCandidate } from "services/openapi";
import { createAndOpenFeedbackForm } from "utils/candidate";

const CandidateCell = styled(TableCell)`
  cursor: pointer;

  &:hover {
    background-color: ${(props): any => props.theme.colors.grayscale.gray200};
  }
`;

const InterviewsTable = (): React.ReactElement => {
  const navigate = useNavigate();
  const currentDoverEntity = useCurrentDoverEntity();
  const { data: candidates } = useGetInterviewsQuery(
    currentDoverEntity?.id ? { doverEntityId: currentDoverEntity.id } : skipToken
  );
  const [lazyGetInterviewId] = useLazyGetInitialCallInterviewQuery();
  const [getOrCreateIRR] = useLazyGetInterviewRubricResponseQuery();

  return (
    <>
      <Spacer height="20px" />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Candidate Name</strong>
              </TableCell>
              <TableCell>
                <strong>Job Name</strong>
              </TableCell>
              <TableCell>
                <strong>Dover Rubric</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(candidates ?? [])
              .filter((candidate: ShortenedCandidate) => !!candidate.id)
              .map((candidate: ShortenedCandidate) => {
                return (
                  <TableRow key={candidate.id}>
                    <CandidateCell
                      onClick={(): void => {
                        if (candidate?.id) {
                          navigate(APP_ROUTE_PATHS.candidates.candidateDetail(candidate.id));
                        }
                      }}
                    >
                      <Typography variant="body1">{candidate?.contact?.fullName}</Typography>
                    </CandidateCell>
                    <TableCell>
                      <Typography variant="body1">{candidate?.job?.name}</Typography>
                    </TableCell>
                    <TableCell>
                      <DoverButton
                        onPress={async (): Promise<void> => {
                          lazyGetInterviewId(candidate.id!)
                            .unwrap()
                            .then((result: InterviewAndCandidateIdData) =>
                              createAndOpenFeedbackForm(getOrCreateIRR, candidate.id!, result.interview.id, false)
                            );
                        }}
                      >
                        <span>View</span>&nbsp;
                        <OpenInNew fontSize="small" />
                      </DoverButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {(candidates ?? []).length === 0 && (
        <>
          <Spacer height="40px" />
          <Grid container justifyContent="center">
            <Typography variant="h5">
              All done! <Emoji symbol="🎉" label="celebrate" />
            </Typography>
          </Grid>
        </>
      )}
    </>
  );
};

export default InterviewsTable;
