/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateFilterList,
    CandidateFilterListFromJSON,
    CandidateFilterListFromJSONTyped,
    CandidateFilterListToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidatePipelineCountRequest
 */
export interface CandidatePipelineCountRequest {
    /**
     * 
     * @type {Array<CandidateFilterList>}
     * @memberof CandidatePipelineCountRequest
     */
    countFilters: Array<CandidateFilterList>;
}

export function CandidatePipelineCountRequestFromJSON(json: any): CandidatePipelineCountRequest {
    return CandidatePipelineCountRequestFromJSONTyped(json, false);
}

export function CandidatePipelineCountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidatePipelineCountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countFilters': ((json['count_filters'] as Array<any>).map(CandidateFilterListFromJSON)),
    };
}

export function CandidatePipelineCountRequestToJSON(value?: CandidatePipelineCountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count_filters': ((value.countFilters as Array<any>).map(CandidateFilterListToJSON)),
    };
}


