import React from "react";

import { BodySmall } from "components/library/typography";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { NextActionStateComponentProps, colorsMap } from "views/CandidateDetail/components/next-action/utils/constants";
import { getNextActionTitle } from "views/CandidateDetail/components/next-action/utils/helpers";

export function HandlingOffer({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  return (
    <NextActionCardSkeleton
      title={getNextActionTitle(candidateBio?.nextAction)}
      colors={colorsMap.white}
      description={<BodySmall>Your team is handling the offer process.</BodySmall>}
    />
  );
}
