/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchFeatures,
    SearchFeaturesFromJSON,
    SearchFeaturesFromJSONTyped,
    SearchFeaturesToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateSearchFromFeaturesRequest
 */
export interface UpdateSearchFromFeaturesRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSearchFromFeaturesRequest
     */
    personaId?: string;
    /**
     * 
     * @type {SearchFeatures}
     * @memberof UpdateSearchFromFeaturesRequest
     */
    searchFeatures: SearchFeatures | null;
}

export function UpdateSearchFromFeaturesRequestFromJSON(json: any): UpdateSearchFromFeaturesRequest {
    return UpdateSearchFromFeaturesRequestFromJSONTyped(json, false);
}

export function UpdateSearchFromFeaturesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSearchFromFeaturesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personaId': !exists(json, 'persona_id') ? undefined : json['persona_id'],
        'searchFeatures': SearchFeaturesFromJSON(json['search_features']),
    };
}

export function UpdateSearchFromFeaturesRequestToJSON(value?: UpdateSearchFromFeaturesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'persona_id': value.personaId,
        'search_features': SearchFeaturesToJSON(value.searchFeatures),
    };
}


