import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import DeactivateFeatureConfirmationModal from "components/dover/feature-toggle-handler/DeactivateFeatureConfirmationModal";
import { ServiceFeatureModalProps } from "components/dover/job-feature-card/types";
import { Body, BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useBulkUpdateCampaignMutation } from "services/doverapi/endpoints/campaign/endpoints";
import {
  useGetJobFeaturesQuery,
  useUpsertJobFeatureSettingMutation,
} from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import {
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { ExternalLink } from "styles/typography";
import { showErrorToast } from "utils/showToast";
import { StyledSwitch } from "views/job/JobSetup/steps/Overview/styles";

const DeactivateTargetedSourcingSchedulingModal = ({
  isModalOpen,
  toggleModalOff,
}: ServiceFeatureModalProps): React.ReactElement => {
  const [upsertJobFeatureSetting] = useUpsertJobFeatureSettingMutation();

  const [bulkUpdateCampaigns] = useBulkUpdateCampaignMutation();

  const jobId = useJobIdFromUrl();

  const { jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
      };
    },
  });

  const [followUpEmailChecked, setFollowUpEmailChecked] = React.useState<boolean>(false);

  const handleToggleFollowUpEmailChecked = React.useCallback(() => {
    setFollowUpEmailChecked(!followUpEmailChecked);
  }, [followUpEmailChecked]);

  const FollowUpToggle = React.useMemo(() => {
    return (
      <>
        <Stack direction="row" spacing={1}>
          <StyledSwitch
            checked={followUpEmailChecked}
            onChange={handleToggleFollowUpEmailChecked}
            $checked={followUpEmailChecked}
          />
          <Stack spacing={0}>
            <BodySmall>{"Send Followup Emails to Existing Candidates"}</BodySmall>
          </Stack>
        </Stack>
      </>
    );
  }, [followUpEmailChecked, handleToggleFollowUpEmailChecked]);

  const modalText = React.useMemo(() => {
    if (!jobFeatures) {
      return (
        <Box display="flex" justifyContent="center">
          <DoverLoadingSpinner minHeight="40px" height="40px" spinnerSize="30px" width="40px" />
        </Box>
      );
    }

    if (
      !jobFeatures[JobFeatureFeatureNameEnum.DoverInterviewer] &&
      !jobFeatures[JobFeatureFeatureNameEnum.E2EScheduling]
    ) {
      return (
        <Stack spacing={3}>
          <Body>
            {
              "Dover will no longer automatically source or contact new candidates for this role. You can indicate below if you would like existing candidates to be sent follow-ups."
            }
          </Body>
          <Body>
            {"Any Dover-managed email accounts created for outreach for this role will be deleted within 7 days."}
          </Body>
          <Body>
            {"You can still source candidates using the Sourcing Extension or by adding them manually."}
            {
              " However, you will need to connect your work email to Dover and handle responses and scheduling yourself. "
            }
            <ExternalLink
              display="inline"
              href={"https://help.dover.com/en/articles/6480907-when-does-dover-use-my-work-email"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </ExternalLink>
          </Body>
          <Body>
            {
              "If you're looking to deactivate this job entirely, you can do so under Manage Job > Deactivate Job in the Job Overview page. Note that you will no longer be able to use any features for this job."
            }
          </Body>
          <Spacer height="16px" />
          {FollowUpToggle}
        </Stack>
      );
    }

    return (
      <Stack spacing={3}>
        <Body>
          {
            "Dover will no longer automatically source or contact new candidates for this role. You can indicate below if you would like existing candidates to be sent follow-ups."
          }
        </Body>
        <Body>
          {"Any Dover-managed email accounts created for outreach for this role will be deleted within 7 days."}
        </Body>
        <Body>{"You can still source candidates using the Sourcing Extension or by adding them manually."}</Body>
        {FollowUpToggle}
      </Stack>
    );
  }, [FollowUpToggle, jobFeatures]);

  const onConfirmDisable = React.useCallback(() => {
    const tryUpsertFeatureSetting = async (): Promise<void> => {
      upsertJobFeatureSetting({
        upsertData: {
          data: {
            job: jobId!,
            featureName: UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
            state: UpsertJobFeatureSettingStateEnum.Disabled,
          },
        },
      })
        .unwrap()
        .catch(err => {
          console.error(err);
          showErrorToast("Failed to update job feature. Please refresh and try again.");
        });
      bulkUpdateCampaigns({
        filters: { job_id: jobId! },
        newValues: { allow_follow_ups_post_deactivation: followUpEmailChecked },
      });

      toggleModalOff();
    };

    tryUpsertFeatureSetting();
  }, [bulkUpdateCampaigns, followUpEmailChecked, jobId, toggleModalOff, upsertJobFeatureSetting]);

  return (
    <DeactivateFeatureConfirmationModal
      isModalOpen={isModalOpen}
      title="Deactivate Sourcing?"
      body={modalText}
      toggleModalOff={toggleModalOff}
      onConfirmDisable={onConfirmDisable}
    />
  );
};

export default DeactivateTargetedSourcingSchedulingModal;
