/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import BusinessIcon from "@mui/icons-material/Business";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, FormLabel, IconButton, InputAdornment, Radio, RadioGroup } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { createEnumParam, NumberParam, StringParam, useQueryParam, useQueryParams } from "use-query-params";

import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { appConfig } from "config/appConfig";
import { useAdminListCampaignsQuery } from "services/doverapi/endpoints/campaign";
import { useAdminGetClientsQuery, useListSlimSearchTemplateQuery } from "services/doverapi/endpoints/client/endpoints";
import { AdminCampaignStateEnum, AdminClient, SlimSearchTemplate } from "services/openapi";
import { H2 } from "styles/typography/headers";
import { queryParamConfig } from "views/admin/Campaigns/types";
import { queryParamsToApiParams } from "views/admin/Campaigns/utils";

const PersonaClientJobSelect = (): React.ReactElement => {
  const { data: clients } = useAdminGetClientsQuery();
  const { data: personas } = useListSlimSearchTemplateQuery();

  const [clientId, setClientId] = useQueryParam("client", StringParam);
  const [personaId, setPersonaId] = useQueryParam("persona", NumberParam);

  const [selectedClientValue, setSelectedClientValue] = useState<AdminClient | undefined | null>(
    clientId ? (clients ?? []).find(c => c.id === clientId) : undefined
  );

  const [selectedPersonaValue, setSelectedPersonaValue] = useState<SlimSearchTemplate | undefined | null>(
    personaId ? (personas ?? []).find(p => p.id === personaId) : undefined
  );

  useEffect((): void => setClientId(selectedClientValue?.id), [selectedClientValue]);
  useEffect((): void => setPersonaId(selectedPersonaValue?.id), [selectedPersonaValue]);

  return (
    <>
      <Autocomplete
        value={selectedPersonaValue}
        onChange={(_, newValue: SlimSearchTemplate | null): void => {
          setSelectedPersonaValue(newValue);
        }}
        options={personas ?? []}
        getOptionLabel={(p: SlimSearchTemplate): string => p.name}
        sx={{ width: "100%" }}
        renderInput={(params: any): React.ReactElement => <TextField {...params} label="Persona" />}
      />
      <Spacer height="8px" />
      <Autocomplete
        value={selectedClientValue}
        onChange={(_, newValue: AdminClient | null): void => {
          setSelectedClientValue(newValue);
        }}
        options={clients ?? []}
        getOptionLabel={(c: AdminClient): string => c.name}
        sx={{ width: "100%" }}
        renderInput={(params: any): React.ReactElement => <TextField {...params} label="Client" />}
      />
    </>
  );
};

const StateSwitch = (): React.ReactElement => {
  const enumParam = createEnumParam(Object.values(AdminCampaignStateEnum));
  const [state, setState] = useQueryParam("state", enumParam);
  const [value, setValue] = useState(state);

  useEffect((): void => setState(value), [value]);

  // AdminCampaignStateEnum doesn't have an all value because it represents the absence of the filter
  // But we can't use null or undefined as the value because it doesn't play nice with the mui radio buttons
  const ALL = useMemo(() => "ALL", []);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">State</FormLabel>
      <RadioGroup
        row
        defaultValue={value ?? ALL}
        onChange={(_, value): void => {
          setValue(value === ALL ? null : (value as AdminCampaignStateEnum));
        }}
      >
        <FormControlLabel value={ALL} control={<Radio />} label="All" />
        <FormControlLabel value={AdminCampaignStateEnum.Active} control={<Radio />} label="Active" />
        <FormControlLabel value={AdminCampaignStateEnum.Inactive} control={<Radio />} label="Inactive" />
        <FormControlLabel value={AdminCampaignStateEnum.Deleted} control={<Radio />} label="Deleted" />
      </RadioGroup>
    </FormControl>
  );
};

const TextSearchFilter = (): React.ReactElement => {
  const [textFilter, setTextFilter] = useQueryParam("q", StringParam);
  const [value, setValue] = useState(textFilter);

  useEffect((): void => setTextFilter(value || undefined), [value]);

  return (
    <TextField
      id="global-search"
      value={value || ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(e.target.value);
      }}
      style={{ width: "100%" }}
      placeholder="Search"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const SelectionInfo = (): React.ReactElement => {
  const { data: clients } = useAdminGetClientsQuery();

  const [clientId] = useQueryParam("client", StringParam);
  const selectedClientValue = useMemo(() => (clients ?? []).find(c => c.id === clientId), [clientId]);

  if (!selectedClientValue) {
    return <></>;
  }

  return (
    <>
      <H2>Info</H2>
      <Spacer height="16px" />
      <Chip
        color="default"
        size="small"
        icon={<BusinessIcon />}
        label={
          <a
            href={`${appConfig.appUrl}/admin/django-admin/dover_domain_models/client/${clientId}/change/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Django Admin Company
          </a>
        }
      />
    </>
  );
};

const Filters = (): React.ReactElement => {
  const [query] = useQueryParams(queryParamConfig);
  const { data: campaigns, isLoading: campaignsAreLoading } = useAdminListCampaignsQuery(queryParamsToApiParams(query));

  return (
    <>
      <H2>Filters</H2>
      <Spacer height="16px" />
      <TextSearchFilter />
      <Spacer height="8px" />
      <PersonaClientJobSelect />
      <Spacer height="32px" />
      <StateSwitch />
      {!campaignsAreLoading && !!campaigns && <BodySmall>Showing {campaigns.length} Results</BodySmall>}
    </>
  );
};

const LoadingStyle = styled.div`
  width: 100%;
  color: ${(props): any => props.theme.colors.informational.dark};
`;

export default (): React.ReactElement => {
  const { data: clients } = useAdminGetClientsQuery();
  const { data: personas } = useListSlimSearchTemplateQuery();

  if (!clients || !personas) {
    return (
      <>
        <H2>Filters</H2>
        <LoadingStyle>
          <LinearProgress color="inherit" />
        </LoadingStyle>
      </>
    );
  }

  return (
    <>
      <Filters />
      <Spacer height="20px" />
      <SelectionInfo />
    </>
  );
};
