import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  RadioGroupProps,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import { Subtitle2, BodySmall } from "components/library/typography";
import { InterviewPipelineCandidateInfoSchema } from "sections/addcandidate/types/candidateInfo";

// ----------------------------------------------------------------------

type SourceRadioProps = RadioGroupProps & {
  name: string;
  options: InterviewPipelineCandidateInfoSchema["source"][];
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
};

export function SourceRadioGroup({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  ...other
}: SourceRadioProps): React.ReactElement {
  const { control, setValue } = useFormContext();

  const labelledby = label ? `${name}-${label}` : "";

  return (
    <Controller
      // @ts-ignore
      name={name}
      control={control}
      render={({ field, fieldState: { error } }): React.ReactElement => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" id={labelledby} sx={{ typography: "body2" }}>
              <Subtitle2>{label}</Subtitle2>
            </FormLabel>
          )}

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : <BodySmall>{helperText}</BodySmall>}
            </FormHelperText>
          )}

          <RadioGroup
            {...field}
            aria-labelledby={labelledby}
            row={row}
            {...other}
            onChange={(e, value): void => {
              field.onChange(e);
              // Hack to set the full object onto form.
              // Unclear if the `onChange` call above has other useful side effects
              // to manage form state.
              setValue(
                "source",
                options.find(choice => choice.value === value)
              );
            }}
          >
            {options.map(option => (
              <FormControlLabel
                key={option.value}
                // MUI casts this to a string, so we can't act on entire source objects here.
                // https://mui.com/material-ui/api/radio-group/#RadioGroup-prop-value
                value={option.value}
                control={<Radio />}
                label={option.label}
                sx={{
                  "&:not(:last-of-type)": {
                    mb: spacing || 0,
                  },
                  ...(row && {
                    mr: 0,
                    "&:not(:last-of-type)": {
                      mr: spacing || 2,
                    },
                  }),
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}
