export const TWENTY_MEGA_BYTES = 20 * 1024 * 1024;

export const VARIABLES = {
  FIRST_NAME: {
    value: "<Example Name>",
    description: "The candidate's first name.",
  },
  DAY_OF_WEEK: {
    value: "<e.g. Tuesday>",
    description: "The day of the week the outreach email is sent.",
  },
  PERSONALIZED_CONTENT: {
    value: "<A personalized message to the candidate populated at send time.>",
    description: "A personalized message to the candidate populated at send time.",
  },
  EMAIL_SENDER_NAME: {
    value: "<your name>",
    description: "The name of the person sending the email.",
  },
};

export const SCHEDULING_LINKS = ["calendly.com", "goodtime.io", "findatime.io"];
