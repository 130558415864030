/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignMessage,
    CampaignMessageFromJSON,
    CampaignMessageFromJSONTyped,
    CampaignMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface CampaignSendTestEmailsRequest
 */
export interface CampaignSendTestEmailsRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignSendTestEmailsRequest
     */
    emailAliasId: string;
    /**
     * 
     * @type {Array<CampaignMessage>}
     * @memberof CampaignSendTestEmailsRequest
     */
    threadMessages: Array<CampaignMessage>;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignSendTestEmailsRequest
     */
    sendToDoverQuality?: boolean | null;
}

export function CampaignSendTestEmailsRequestFromJSON(json: any): CampaignSendTestEmailsRequest {
    return CampaignSendTestEmailsRequestFromJSONTyped(json, false);
}

export function CampaignSendTestEmailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignSendTestEmailsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAliasId': json['email_alias_id'],
        'threadMessages': ((json['thread_messages'] as Array<any>).map(CampaignMessageFromJSON)),
        'sendToDoverQuality': !exists(json, 'send_to_dover_quality') ? undefined : json['send_to_dover_quality'],
    };
}

export function CampaignSendTestEmailsRequestToJSON(value?: CampaignSendTestEmailsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_alias_id': value.emailAliasId,
        'thread_messages': ((value.threadMessages as Array<any>).map(CampaignMessageToJSON)),
        'send_to_dover_quality': value.sendToDoverQuality,
    };
}


