import { Box, Skeleton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AddNewProUserModal } from "components/dover/Campaigns/AddNewProUserModal";
import { ModalKeyType } from "components/dover/Campaigns/types";
import { Button, ButtonVariant } from "components/library/Button";
import EditorWithMenu from "components/library/TipTap/EditorWithMenu";
import { CustomMention } from "components/library/TipTap/extensions/customMention";
import RawEditor from "components/library/TipTap/RawEditor";
import {
  CommonEditorProps,
  EditorType,
  MentionOption,
  MentionsEditorInnerProps,
  MentionsEditorProps,
} from "components/library/TipTap/types";
import { getSuggestionOptions } from "components/library/TipTap/utils";
import { BodySmall } from "components/library/typography";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { ClientDoverPlanEnum, DoverUser } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { colors } from "styles/theme";

const MentionsEditor = (props: MentionsEditorProps): React.ReactElement => {
  // fetch data
  const { proUsers } = useListProUsersForClientQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => {
      const proUsers: DoverUser[] | undefined = data ? listAllEntities(data) : undefined;
      return {
        proUsers,
        isLoading,
      };
    },
  });
  const { data: client } = useGetUsersClientQuery();

  const doverPlan = useGetDoverPlan();
  const includeCSMsInUsersList = doverPlan === ClientDoverPlanEnum.JobSlots;

  // Construct new list that includes csm
  const usersList: DoverUser[] | undefined = useMemo(() => {
    const csm = client?.csm?.proUser;

    if (csm && includeCSMsInUsersList) {
      return [...(proUsers ?? []), csm];
    } else {
      return proUsers;
    }
  }, [client?.csm?.proUser, proUsers, includeCSMsInUsersList]);

  const proUsersRef = React.useRef(usersList);
  useEffect(() => {
    proUsersRef.current = usersList;
  }, [usersList]);

  const { user } = useAuth0();

  // local state
  const [openModal, setOpenModal] = useState<ModalKeyType>(ModalKeyType.None);

  // callbacks
  const onClickAddUser = useCallback(() => {
    setOpenModal(ModalKeyType.AddNewProUser);
  }, []);

  // memoized data
  const addNewUserButton = useMemo(() => {
    return (
      <Button variant={ButtonVariant.Ghost} removePadding={true} onClick={onClickAddUser} grow={true}>
        <BodySmall style={{ padding: "7px", textAlign: "left" }} color={colors.linkLight}>
          {"+ Add user"}
        </BodySmall>
      </Button>
    );
  }, [onClickAddUser]);

  const getOptions = (): MentionOption[] => {
    return (proUsersRef.current ?? []).map(proUser => {
      return {
        userId: proUser.id!.toString(),
        label: proUser.fullName ?? proUser.email!,
        mentionerName: user.name,
        mentionerEmail: user.email,
        subheader: proUser.email,
        isMe: proUser.email === user.email,
      };
    });
  };

  // show loading state if data is not ready
  if (!props.readOnly && !usersList) {
    return (
      <Box
        sx={{
          borderRadius: "4px",
          width: "100%",
        }}
      >
        <Skeleton height="150px" width="100%" />
      </Box>
    );
  }

  return (
    <>
      <MentionsEditorInner {...props} getOptions={getOptions} footer={addNewUserButton} />
      <AddNewProUserModal
        shouldShowModal={openModal}
        handleCloseModal={(): void => {
          setOpenModal(ModalKeyType.None);
        }}
        handleOpenUpdateOrCreateCampaignModal={(): void => {}} // no-op
        modalKey={ModalKeyType.AddNewProUser}
        setSelectedOtherUser={(): void => {}} // no-op
      />
    </>
  );
};

// This is the shape of the class that we will eventually move to dover-ui
const MentionsEditorInner = (props: MentionsEditorInnerProps): React.ReactElement => {
  const suggestions = getSuggestionOptions(props.getOptions, props.footer);

  const commonProps: CommonEditorProps = {
    ...props,
    customExtensions: [
      CustomMention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: suggestions,
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label}`;
        },
      }),
    ],
  };

  return props.editorType === EditorType.RawEditor ? (
    <RawEditor {...commonProps} />
  ) : (
    <EditorWithMenu {...commonProps} />
  );
};

export default MentionsEditor;
