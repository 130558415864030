/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtensionJob
 */
export interface ExtensionJob {
    /**
     * 
     * @type {Date}
     * @memberof ExtensionJob
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExtensionJob
     */
    readonly modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExtensionJob
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionJob
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtensionJob
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionJob
     */
    cleanName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionJob
     */
    client: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtensionJob
     */
    active?: boolean;
}

export function ExtensionJobFromJSON(json: any): ExtensionJob {
    return ExtensionJobFromJSONTyped(json, false);
}

export function ExtensionJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'name': json['name'],
        'cleanName': !exists(json, 'clean_name') ? undefined : json['clean_name'],
        'client': json['client'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function ExtensionJobToJSON(value?: ExtensionJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'name': value.name,
        'clean_name': value.cleanName,
        'client': value.client,
        'active': value.active,
    };
}


