/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewPanel,
    InterviewPanelFromJSON,
    InterviewPanelFromJSONTyped,
    InterviewPanelToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetEmailTemplateRequestV2
 */
export interface GetEmailTemplateRequestV2 {
    /**
     * 
     * @type {string}
     * @memberof GetEmailTemplateRequestV2
     */
    candidateId: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailTemplateRequestV2
     */
    emailAliasId: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailTemplateRequestV2
     */
    decision?: GetEmailTemplateRequestV2DecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetEmailTemplateRequestV2
     */
    previousEmailEventId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetEmailTemplateRequestV2
     */
    desiredHiringPipelineStageId?: string | null;
    /**
     * 
     * @type {InterviewPanel}
     * @memberof GetEmailTemplateRequestV2
     */
    interviewPanel?: InterviewPanel | null;
    /**
     * 
     * @type {string}
     * @memberof GetEmailTemplateRequestV2
     */
    clientEmailTemplateId?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum GetEmailTemplateRequestV2DecisionEnum {
    Approve = 'APPROVE',
    Reject = 'REJECT',
    Reschedule = 'RESCHEDULE',
    Email = 'EMAIL'
}

export function GetEmailTemplateRequestV2FromJSON(json: any): GetEmailTemplateRequestV2 {
    return GetEmailTemplateRequestV2FromJSONTyped(json, false);
}

export function GetEmailTemplateRequestV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmailTemplateRequestV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
        'emailAliasId': json['email_alias_id'],
        'decision': !exists(json, 'decision') ? undefined : json['decision'],
        'previousEmailEventId': !exists(json, 'previous_email_event_id') ? undefined : json['previous_email_event_id'],
        'desiredHiringPipelineStageId': !exists(json, 'desired_hiring_pipeline_stage_id') ? undefined : json['desired_hiring_pipeline_stage_id'],
        'interviewPanel': !exists(json, 'interview_panel') ? undefined : InterviewPanelFromJSON(json['interview_panel']),
        'clientEmailTemplateId': !exists(json, 'client_email_template_id') ? undefined : json['client_email_template_id'],
    };
}

export function GetEmailTemplateRequestV2ToJSON(value?: GetEmailTemplateRequestV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'email_alias_id': value.emailAliasId,
        'decision': value.decision,
        'previous_email_event_id': value.previousEmailEventId,
        'desired_hiring_pipeline_stage_id': value.desiredHiringPipelineStageId,
        'interview_panel': InterviewPanelToJSON(value.interviewPanel),
        'client_email_template_id': value.clientEmailTemplateId,
    };
}


