import { Button } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import { useSetAtom } from "jotai";
import React from "react";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { CriteriaMode, criteriaModeAtom } from "views/candidates/ApplicationReview/atoms/chat";

export const NoExactMatchesCard = (): React.ReactElement => {
  const setCriteriaMode = useSetAtom(criteriaModeAtom);

  const onClick = (): void => setCriteriaMode(CriteriaMode.Criteria);

  return (
    <Stack
      direction="row"
      borderRadius="6px"
      px={1}
      m={1}
      alignItems="center"
      justifyContent="space-between"
      border={`1px solid ${colors.grayscale.gray200}`}
    >
      <BodySmall weight="500" style={{ marginRight: "4px" }} color={colors.grayscale.gray600}>
        No exact matches
      </BodySmall>
      <Button onPress={onClick}>
        <BodySmall
          weight="500"
          style={{ marginRight: "4px" }}
          color={colors.grayscale.gray600}
          textDecoration="underline"
        >
          Edit criteria
        </BodySmall>
      </Button>
    </Stack>
  );
};
