import styled from "styled-components";

import { colors } from "styles/theme";

/*
  This can be used as a clean surface to place content on top.
  It is commonly used throughout the app as a background for the main content of a page.
*/
interface CardProps {
  width?: string;
  maxWidth?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  color?: string;
  flex?: string;
  padding?: string;
  hoverable?: boolean;
}

export const Card = styled.div<CardProps>`
  position: relative;
  max-width: ${({ maxWidth }): string | undefined => maxWidth ?? undefined};
  width: ${({ fullWidth, width }): string | undefined => (fullWidth ? "100%" : width)};
  height: ${({ fullHeight }): string | undefined => (fullHeight ? "100%" : undefined)};
  padding: ${({ padding }): string => padding ?? "16px"};
  background-color: ${({ color }): string => color ?? colors.white};
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 1px solid ${colors.grayscale.gray200};
  flex: ${({ flex }): string => flex ?? "0 1 auto"};

  ${({ hoverable }): string | false | undefined =>
    hoverable &&
    `
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
      background-color: ${colors.grayscale.gray100};
    }
  `}
`;
