import { createSelector } from "@reduxjs/toolkit";

import { setupHiringPipelineReducerKey } from "components/SetupHiringPipeline/constants";
import { initialState } from "components/SetupHiringPipeline/reducer";
import { HiringPipelineState } from "components/SetupHiringPipeline/types";
import { selectGlobalState } from "domains/global/selectors";
import { GlobalRootState } from "domains/global/types";
import { jobEndpoints } from "services/doverapi/endpoints/job";
import { JobSetup } from "services/openapi";

const selectState = (state: GlobalRootState): HiringPipelineState =>
  state[setupHiringPipelineReducerKey] || initialState;

export const selectIncludesTakeHomeStage = createSelector<GlobalRootState, HiringPipelineState, boolean>(
  [selectState],
  state => state.includesTakeHomeStage
);

export const selectATSSettingsDismissed = createSelector<GlobalRootState, HiringPipelineState, boolean>(
  [selectState],
  state => state.atsSettingsDismissed
);

export const selectJobId = createSelector<GlobalRootState, HiringPipelineState, string | undefined>(
  [selectState],
  state => {
    return state.jobId;
  }
);

export const selectJobSetup = createSelector([selectGlobalState, selectJobId], (globalState, jobId):
  | JobSetup
  | undefined => {
  if (!jobId) {
    return undefined;
  }

  // Make use of RTKQ's state. This selector acts as a thin wrapper so that it's easy to use as
  // a dependency for other selectors.
  const { data } = jobEndpoints.endpoints.getJobSetup.select(jobId)(globalState);
  return data;
});
