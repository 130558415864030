import { Node as ProsemirrorNode } from "@tiptap/pm/model";

export interface Result {
  from: number;
  to: number;
  word: string;
}

/**
 * VariableHighlighterPlugin
 *
 * A class that scans a Prosemirror document and records the positions of matching variables.
 */
export default class VariableHighlighterPlugin {
  /**
   * The Prosemirror document to be scanned.
   */
  private doc: ProsemirrorNode;

  /**
   * An array to store the results of the variable scanning.
   */
  private results: Array<Result> = [];

  /**
   * A regular expression pattern to match the variables.
   */
  private variableRegex: RegExp | null = null;

  /**
   * Constructs a new instance of VariableHighlighterPlugin.
   *
   * @param doc - The Prosemirror document to be scanned.
   * @param variables - An case-insensitive array of variable names to be matched.
   */
  constructor(doc: ProsemirrorNode, variables: string[]) {
    // Construct the regular expression pattern using the provided variable names.
    const variablePattern = variables.length ? variables.join("|") : ".*?";
    const pattern = `\\{\\{(${variablePattern})\\}\\}`;
    this.variableRegex = new RegExp(pattern, "gi");
    this.doc = doc;
  }

  /**
   * Records the position of a matched variable in the document.
   *
   * @param from - The start position of the matched variable.
   * @param to - The end position of the matched variable.
   */
  record(from: number, to: number, word: string): void {
    this.results.push({
      from,
      to,
      word,
    });
  }

  /**
   * Scans the Prosemirror document and records the positions of matching variables.
   *
   * @returns The current instance of VariableHighlighterPlugin.
   */
  scan(): VariableHighlighterPlugin {
    this.doc.descendants((node: any, position: number) => {
      if (!node.isText) {
        return;
      }

      let matches = this.variableRegex?.exec(node.text);
      while (matches) {
        this.record(position + matches.index, position + matches.index + matches[0].length, matches[1]);
        matches = this.variableRegex?.exec(node.text);
      }
    });

    return this;
  }

  /**
   * Retrieves the results of the variable scanning.
   *
   * @returns An array of Result objects containing the positions of matching variables.
   */
  getResults(): Result[] {
    return this.results;
  }
}
