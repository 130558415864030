import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as AlertTriangleIcon } from "@doverhq/dover-ui/icons/alert-triangle.svg";
import { ReactComponent as RefreshIcon } from "@doverhq/dover-ui/icons/refresh.svg";
import { Box, Stack, styled } from "@mui/material";
import { DataGrid, GridCellParams, GridEnrichedColDef, GridRowModel } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useState } from "react";

import { ReactComponent as PencilEditIcon } from "assets/icons/pencil-edit.svg";
import DataGridLoadingOverlay from "components/DataGridLoadingOverlay";
import { Circle } from "components/library/Circle";
import { Caption, Overline, Subtitle1 } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { useListCandidateSourcesQuery } from "services/doverapi/endpoints/candidateSource";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useListJobsQuery } from "services/doverapi/endpoints/job";
import { useListJobCandidateSourceSettingsQuery } from "services/doverapi/endpoints/jobCandidateSourceSetting";
import {
  CandidateSource,
  ClientOnboardingAtsTypeEnum,
  DashboardJob,
  JobCandidateSourceSettingDesiredStateEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { AtsJobConfigurationDrawer } from "views/CompanySetup/components/AtsSettings/AtsJobConfigurationDrawer";
import { AtsCard } from "views/CompanySetup/components/AtsSettings/styles";

/* -----------------------------------------------------------------------------
 * helpers
 * -------------------------------------------------------------------------- */

interface GridRowData {
  id?: string;
  jobTitle?: string;
  atsJobTitle?: string;
  atsType?: ClientOnboardingAtsTypeEnum;
}

type HeaderName = "Dover Job" | "ATS Job" | "Inbound" | "";

const generateColumn = (headerName: HeaderName): GridEnrichedColDef => {
  return {
    headerName,
    field: headerName,
    flex: 1,
    sortable: false,
    renderHeader: (params): ReactElement => {
      return (
        <Box sx={{ textWrap: "wrap" }}>
          <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>
        </Box>
      );
    },
    renderCell: (params): ReactElement => {
      if (headerName === "Dover Job") {
        return <SyncAtsCellText>{params.row.jobTitle}</SyncAtsCellText>;
      } else if (headerName === "ATS Job") {
        return (
          <SyncAtsJobsCell
            isSynced={!!params.row.atsJobTitle}
            color={params.row.atsJobTitle ? undefined : colors.grayscale.gray500}
          >
            {params.row.atsJobTitle ?? "Not synced"}
          </SyncAtsJobsCell>
        );
      } else if (headerName === "Inbound") {
        return (
          <Stack direction="row" flex="1 1 auto" justifyContent="space-between" alignItems="center" px={1}>
            <SyncAtsInboundCell jobId={params.row.id} atsType={params.row.atsType} />
            <PencilEditIcon />
          </Stack>
        );
      }
      return <></>;
    },
  };
};

const generateRow = (job: DashboardJob, atsType?: ClientOnboardingAtsTypeEnum): GridRowModel<GridRowData> => {
  return {
    atsType,
    id: job.id,
    jobTitle: job.title ?? undefined,
    atsJobTitle: job.atsJobTitle ?? undefined,
  };
};

/* -----------------------------------------------------------------------------
 * StyledDataGrid
 * -------------------------------------------------------------------------- */

const StyledDataGrid = styled(DataGrid)`
  .MuiDataGrid-columnsContainer {
    background: ${colors.grayscale.gray100};
  }

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiTablePagination-displayedRows {
    margin-bottom: 0;
  }
`;

/* -----------------------------------------------------------------------------
 * SyncAtsCellText
 * -------------------------------------------------------------------------- */

interface SyncAtsCellTextProps {
  color?: string;
  children: React.ReactNode;
}

const SyncAtsCellText = ({ color, children }: SyncAtsCellTextProps): ReactElement => {
  return (
    <Box sx={{ textWrap: "wrap" }}>
      <Caption color={color ? color : colors.grayscale.gray700}>{children}</Caption>
    </Box>
  );
};

/* -----------------------------------------------------------------------------
 * SyncAtsJobsCell
 * -------------------------------------------------------------------------- */

interface SyncAtsJobsCellProps extends SyncAtsCellTextProps {
  isSynced: boolean;
}

const SyncAtsJobsCell = ({ isSynced, color, children }: SyncAtsJobsCellProps): ReactElement => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {isSynced ? (
        <Icon Icon={RefreshIcon} color="primary-base" />
      ) : (
        <Icon Icon={AlertTriangleIcon} color="warning-base" />
      )}
      <SyncAtsCellText color={color}>{children}</SyncAtsCellText>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * SyncAtsInboundCell
 * -------------------------------------------------------------------------- */

interface SyncAtsInboundCellProps {
  jobId: string;
  atsType?: ClientOnboardingAtsTypeEnum;
}

const SyncAtsInboundCell = ({ jobId, atsType }: SyncAtsInboundCellProps): ReactElement => {
  const { data: candidateSources } = useListCandidateSourcesQuery(
    jobId
      ? {
          jobId,
        }
      : skipToken
  );
  const { data: jobCandidateSourceSettings } = useListJobCandidateSourceSettingsQuery(
    jobId
      ? {
          job: jobId,
        }
      : skipToken
  );

  const candidateSource = (Object.values(candidateSources?.entities ?? { atsType: false }) as CandidateSource[]).find(
    source => source?.atsType === atsType
  );
  const jobCandidateSourceSetting = Object.values(
    jobCandidateSourceSettings?.entities ?? { candidateSource: undefined }
  ).find(j => j?.candidateSource?.id === candidateSource?.id);
  const isActive = jobCandidateSourceSetting?.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active;

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {isActive && <Circle size="8px" bgcolor={colors.primary.base} />}
      <SyncAtsCellText color={isActive ? colors.grayscale.gray700 : colors.grayscale.gray500}>
        {isActive ? "Active" : "Inactive"}
      </SyncAtsCellText>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * SyncAtsJobs
 * -------------------------------------------------------------------------- */

const LIST_JOBS_LIMIT = 10;

export const SyncAtsJobs = (): ReactElement => {
  const { data: client, isLoading: isClientLoading } = useGetClientOnboardingQuery();
  const { data: activeJobs, isLoading: isListJobsLoading } = useListJobsQuery({ active: "true" });
  const jobIds = activeJobs?.ids?.filter(jobId => !!activeJobs?.entities[jobId]);
  const jobRows =
    jobIds
      ?.map(jobId => generateRow(activeJobs?.entities[jobId]!, client?.atsType))
      // we want to sort the rows with all active jobs first and then sorted alphabetically
      .sort((a, b) => {
        if (a.atsJobTitle && !b.atsJobTitle) {
          return -1;
        } else if (b.atsJobTitle && !a.atsJobTitle) {
          return 1;
        }
        return a.jobTitle?.localeCompare(b.jobTitle ?? "") ?? 0;
      }) ?? [];

  const [selectedJob, setSelectedJob] = useState<DashboardJob | undefined>(undefined);

  const handleCellClick = (params: GridCellParams<any, any, any>): void => {
    setSelectedJob(activeJobs?.entities[params.row.id]);
  };

  const closeDrawer = (): void => {
    setSelectedJob(undefined);
  };

  if (
    isClientLoading ||
    ![
      ClientOnboardingAtsTypeEnum.Greenhouse,
      ClientOnboardingAtsTypeEnum.Lever,
      ClientOnboardingAtsTypeEnum.Ashby,
    ].includes(client?.atsType as ClientOnboardingAtsTypeEnum)
  ) {
    return <></>;
  }

  return (
    <>
      <Spacer height="12px" />
      <AtsCard>
        <Box pb={2}>
          <Subtitle1>Sync to your ATS jobs</Subtitle1>
        </Box>
        <StyledDataGrid
          autoHeight
          headerHeight={35}
          rowHeight={72}
          rows={isListJobsLoading ? [] : jobRows}
          columns={[generateColumn("Dover Job"), generateColumn("ATS Job"), generateColumn("Inbound")]}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          loading={isListJobsLoading}
          pagination
          pageSize={LIST_JOBS_LIMIT}
          rowCount={isListJobsLoading ? 0 : jobRows.length}
          components={{
            LoadingOverlay: DataGridLoadingOverlay,
          }}
          onCellClick={handleCellClick}
        />
        <AtsJobConfigurationDrawer job={selectedJob} onRequestClose={closeDrawer} />
      </AtsCard>
    </>
  );
};
