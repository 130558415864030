/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackTemplateQuestionComponent,
    FeedbackTemplateQuestionComponentFromJSON,
    FeedbackTemplateQuestionComponentFromJSONTyped,
    FeedbackTemplateQuestionComponentToJSON,
} from './';

import {
     SelectFieldFeedbackTemplateQuestionFromJSONTyped,
     TextFieldFeedbackTemplateQuestionFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface BaseFeedbackTemplateQuestion
 */
export interface BaseFeedbackTemplateQuestion {
    /**
     * 
     * @type {FeedbackTemplateQuestionComponent}
     * @memberof BaseFeedbackTemplateQuestion
     */
    component: FeedbackTemplateQuestionComponent;
    /**
     * 
     * @type {string}
     * @memberof BaseFeedbackTemplateQuestion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BaseFeedbackTemplateQuestion
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof BaseFeedbackTemplateQuestion
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseFeedbackTemplateQuestion
     */
    helperText?: string;
}

export function BaseFeedbackTemplateQuestionFromJSON(json: any): BaseFeedbackTemplateQuestion {
    return BaseFeedbackTemplateQuestionFromJSONTyped(json, false);
}

export function BaseFeedbackTemplateQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseFeedbackTemplateQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['discriminatorRefName'] === 'SelectFieldFeedbackTemplateQuestion') {
            return SelectFieldFeedbackTemplateQuestionFromJSONTyped(json, true);
        }
        if (json['discriminatorRefName'] === 'TextFieldFeedbackTemplateQuestion') {
            return TextFieldFeedbackTemplateQuestionFromJSONTyped(json, true);
        }
    }
    return {
        
        'component': FeedbackTemplateQuestionComponentFromJSON(json['component']),
        'name': json['name'],
        'label': json['label'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'helperText': !exists(json, 'helper_text') ? undefined : json['helper_text'],
    };
}

export function BaseFeedbackTemplateQuestionToJSON(value?: BaseFeedbackTemplateQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': FeedbackTemplateQuestionComponentToJSON(value.component),
        'name': value.name,
        'label': value.label,
        'placeholder': value.placeholder,
        'helper_text': value.helperText,
    };
}


