import { Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as FeedbackSVG } from "assets/icons/add-rubric-feedback.svg";
import { ReactComponent as CaretDownSVG } from "assets/icons/caret-down-black.svg";
import { ReactComponent as DownCaretBlueSVG } from "assets/icons/caret-down-link.svg";
import { ReactComponent as EyeSVG } from "assets/icons/eye.svg";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { MenuButton, MenuButtonProps } from "components/MenuButton";
import {
  useLazyGetInterviewRubricResponseQuery,
  useGetOrCreateMultipartInterviewSubstagesQuery,
  useListFeedbackFormsForInterviewQuery,
} from "services/doverapi/endpoints/candidateInterview";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { CandidateBio } from "services/openapi";
import { colors } from "styles/theme";
import { createAndOpenFeedbackForm } from "utils/candidate";
import { isMultipartStage, isSinglepartStage } from "utils/isStage";

const SUBMIT_FEEDBACK_BUTTON_TEXT = "Submit Feedback";

interface SubmitFeedbackButtonProps extends Omit<MenuButtonProps, "label" | "options" | "variant"> {
  candidateBio?: CandidateBio;
  hideIcon?: boolean;
  variant?: ButtonVariant;
}

const LoadingSkeleton = (): React.ReactElement => <Skeleton width="120px" height="35px" />;

const SinglePartFeedbackButton = ({
  candidateBio,
  variant = ButtonVariant.Secondary,
  hideIcon = false,
  ...props
}: SubmitFeedbackButtonProps): React.ReactElement => {
  // Get the rubric response for the current interview - changes view depending on whether its completed
  const candidateId = candidateBio?.id;
  const interviewId = candidateBio?.nextAction?.currentInterview?.id;

  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const [getOrCreateIRR] = useLazyGetInterviewRubricResponseQuery();

  const { currentData: feedbackForms, isFetching: isFetchingFeedbackForms } = useListFeedbackFormsForInterviewQuery(
    candidateId && interviewId ? { interviewId } : skipToken
  );

  const feedbackAuthedUserSubmitted = feedbackForms?.find(
    f => !!f.submittedByProUserId && f.submittedByProUserId === userInfo?.id
  );
  const isFeedbackSubmitted = feedbackAuthedUserSubmitted && feedbackAuthedUserSubmitted?.interviewState === 300;
  const currInterviewUsesDI = candidateBio?.nextAction?.currentInterview?.hasDoverInterviewer;

  const openFeedbackLink = React.useCallback((): void => {
    if (!candidateId || !feedbackAuthedUserSubmitted?.id) {
      return;
    }

    const link = APP_ROUTE_PATHS.candidateInterview(candidateId, feedbackAuthedUserSubmitted.id);
    window.open(link, "_blank", "noopener noreferrer");
  }, [candidateId, feedbackAuthedUserSubmitted?.id]);

  const submitFeedbackHandler = React.useCallback(async () => {
    if (!candidateId || !interviewId) {
      return;
    }

    if (feedbackAuthedUserSubmitted) {
      openFeedbackLink();
      return;
    }
    createAndOpenFeedbackForm(getOrCreateIRR, candidateId, interviewId);
  }, [openFeedbackLink, feedbackAuthedUserSubmitted, getOrCreateIRR, candidateId, interviewId]);

  // if still loading, show skeleton
  if (!candidateBio?.id || isFetchingFeedbackForms) {
    return <LoadingSkeleton />;
  }

  const color = variant === ButtonVariant.Link ? colors.link : colors.grayscale.gray600;
  if (isFeedbackSubmitted && currInterviewUsesDI) {
    return (
      <Button variant={variant} width="160px" onClick={openFeedbackLink}>
        <Stack direction="row" spacing={1} alignItems="center">
          <EyeSVG />
          <BodySmall color={color}>View feedback</BodySmall>
        </Stack>
      </Button>
    );
  }

  return (
    <Button
      variant={variant}
      onClick={submitFeedbackHandler}
      width="172px"
      disabled={currInterviewUsesDI}
      tooltip={
        currInterviewUsesDI
          ? "Your Dover Interviewer will submit feedback once their interview is complete."
          : undefined
      }
      {...props}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        {!hideIcon && <FeedbackSVG />}
        <BodySmall color={color}>{SUBMIT_FEEDBACK_BUTTON_TEXT}</BodySmall>
      </Stack>
    </Button>
  );
};

const MultipartFeedbackButton = ({
  candidateBio,
  variant = ButtonVariant.Secondary,
  hideIcon = false,
  ...props
}: SubmitFeedbackButtonProps): React.ReactElement => {
  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const [getOrCreateIRR] = useLazyGetInterviewRubricResponseQuery();

  const candidateId = candidateBio?.id;

  // Get or create the actual interview if it doesn't already exist
  const {
    currentData: multipartInterviewData,
    isFetching: isMultipartDataFetching,
  } = useGetOrCreateMultipartInterviewSubstagesQuery(
    candidateBio?.id && candidateBio.nextAction?.hiringPipelineStage?.id
      ? { candidateId: candidateBio.id, hiringPipelineStageId: candidateBio.nextAction.hiringPipelineStage.id }
      : skipToken
  );

  const MultipartSubmitFeedbackEl = React.useMemo(() => {
    const color = variant === ButtonVariant.Link ? colors.link : colors.grayscale.gray600;
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {!hideIcon && <FeedbackSVG />}
        <BodySmall color={color}>{SUBMIT_FEEDBACK_BUTTON_TEXT}</BodySmall>
        {variant === ButtonVariant.Link ? <DownCaretBlueSVG /> : <CaretDownSVG />}
      </Stack>
    );
  }, [hideIcon, variant]);

  // these are the subbuttons for the menu button
  const multipartLabels = React.useMemo(() => {
    if (!multipartInterviewData || !candidateId) {
      return [];
    }

    return multipartInterviewData?.substages?.map(substage => {
      const feedbackAuthedUserSubmitted = substage.feedbackForms?.find(
        f => !!f.submittedByProUserId && f.submittedByProUserId === userInfo?.id
      );

      let clickHandler: () => Promise<void>;
      if (feedbackAuthedUserSubmitted) {
        const feedbackUrl = APP_ROUTE_PATHS.candidateInterview(candidateId, feedbackAuthedUserSubmitted.id);
        clickHandler = async (): Promise<void> => {
          window.open(feedbackUrl, "_blank", "noopener noreferrer");
        };
      } else {
        clickHandler = async (): Promise<void> => {
          createAndOpenFeedbackForm(getOrCreateIRR, candidateId, substage.id);
        };
      }

      return {
        label: <TextWithMaxWidth label={substage.name} width="170px" />,
        onClick: clickHandler,
      };
    });
  }, [candidateId, userInfo?.id, getOrCreateIRR, multipartInterviewData]);

  // if still loading, show skeleton
  if (!candidateId || isMultipartDataFetching) {
    return <LoadingSkeleton />;
  }

  // if there are no multipart labels, something is wrong
  if (!multipartLabels?.length) {
    console.error("No substages found for a multipart interview, although there should be");
    return <></>;
  }

  return (
    <MenuButton
      label={MultipartSubmitFeedbackEl}
      variant={variant}
      options={multipartLabels}
      menuWidth="190px"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      {...props}
    />
  );
};

export const SubmitFeedbackButton = ({
  candidateBio,
  variant = ButtonVariant.Secondary,
  hideIcon,
  ...props
}: SubmitFeedbackButtonProps): React.ReactElement => {
  if (!candidateBio?.nextAction?.hiringPipelineStage) {
    return <LoadingSkeleton />;
  }

  // We may introduce other interview types in the future, so handle them here.
  if (isMultipartStage(candidateBio?.nextAction?.hiringPipelineStage)) {
    return <MultipartFeedbackButton candidateBio={candidateBio} variant={variant} hideIcon={hideIcon} {...props} />;
  }

  if (isSinglepartStage(candidateBio?.nextAction?.hiringPipelineStage)) {
    return <SinglePartFeedbackButton candidateBio={candidateBio} variant={variant} hideIcon={hideIcon} {...props} />;
  }

  return <LoadingSkeleton />;
};
