import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as XIcon } from "@doverhq/dover-ui/icons/x.svg";
import { Stack, Box } from "@mui/material";
import React from "react";

import { Body } from "components/library/typography";

export const StickyTopButton = ({
  text,
  icon,
  onClose,
  toggle,
}: {
  text: string;
  icon: React.ReactElement;
  onClose: () => void;
  toggle?: React.ReactElement;
}): React.ReactElement => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      padding="4px 8px"
      width="100%"
      position="sticky"
      top="0"
    >
      {/* min height of 36px as that is the height of the buttons from the "Scoring" */}
      <Stack direction="row" alignItems="center" spacing={1} minHeight="36px">
        {icon}
        <Body weight="500">{text}</Body>
        {toggle}
      </Stack>
      <Box onClick={onClose} sx={{ cursor: "pointer" }}>
        <Icon Icon={XIcon} />
      </Box>
    </Stack>
  );
};
