import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import DraggableSVG from "assets/icons/draggable.svg";
import TrashIconSVG from "assets/icons/trash-gray.svg";
import {
  DurationInput,
  NameInput,
  InterviewersInput,
  OrderRequiredInput,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/SubstageFields";
import { colors } from "styles/theme";

const RemoveSubstageButton = ({ onClick }: { onClick: any }): React.ReactElement => {
  return (
    <Box style={{ cursor: "pointer" }}>
      <ReactSVG src={TrashIconSVG} onClick={onClick} />
    </Box>
  );
};

interface SubstageRowProps {
  value: any;
  onChange: (newSubstage: any) => void;
  onDelete: (idx: number) => void;
  showDelete: boolean;
  disabled?: boolean;
}

const SubstageRow = ({
  value: substage,
  onChange,
  onDelete,
  showDelete,
  disabled,
}: SubstageRowProps): React.ReactElement => {
  const handleOrderRequiredChange = (event: any): void => {
    onChange({
      ...substage,
      orderRequired: !!event.target.value,
    });
  };

  const handleDurationChange = (event: any): void => {
    onChange({
      ...substage,
      duration: event.target.value,
    });
  };

  const handleNameChange = (event: any): void => {
    onChange({
      ...substage,
      name: event.target.value,
    });
  };

  const handlePossibleInterviewersChange = (value: string[]): void => {
    onChange({ ...substage, possibleInterviewers: value });
  };

  const getInterviewersRequiredValue = (interviewersRequired: string | undefined): boolean => {
    return interviewersRequired === "ALL" ? true : false;
  };

  const handleInterviewersRequiredChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.checked ? "ALL" : "ANY";
    onChange({ ...substage, interviewersRequired: value });
  };

  return (
    <Stack direction="row" spacing={1} width="100%">
      <Box flex="0.5 1 0">
        <ReactSVG src={DraggableSVG} />
      </Box>
      <Box flex="1.8 1 0">
        <OrderRequiredInput
          value={substage.orderRequired ? 1 : 0}
          orderIndex={substage.orderIndex!}
          onChange={handleOrderRequiredChange}
          disabled={disabled}
        />
      </Box>
      <Box flex="4 1 0">
        <NameInput
          idx={substage.id || substage.orderIndex}
          value={substage.name}
          onChange={handleNameChange}
          disabled={disabled}
        />
      </Box>
      <Box flex="2 1 0">
        <DurationInput value={substage.duration} onChange={handleDurationChange} disabled={disabled} />
      </Box>
      <Box flex="8 1 0">
        <>
          <InterviewersInput
            value={Array.from(substage.possibleInterviewers)}
            handleChange={handlePossibleInterviewersChange}
            disabled={disabled}
          />
          {substage.possibleInterviewers.length > 1 && (
            <FormControlLabel
              sx={{
                "&.MuiFormControlLabel-root": {
                  marginBottom: "-6px",
                },
                ".MuiTypography-root": {
                  fontFamily: "Inter",
                  fontSize: "14px",
                },
              }}
              disabled={disabled}
              control={
                <Checkbox
                  sx={{
                    color: colors.grayscale.gray400,
                    "&.Mui-checked": {
                      color: colors.primary.base,
                    },
                  }}
                  checked={getInterviewersRequiredValue(substage.interviewersRequired)}
                  onChange={handleInterviewersRequiredChange}
                  disabled={disabled}
                />
              }
              label="All interviewers are required"
            />
          )}
        </>
      </Box>
      <Box display="flex" flex="0.5 1 0">
        {showDelete && !disabled && (
          <Box m="auto">
            <RemoveSubstageButton onClick={onDelete} />
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default SubstageRow;
