import { Box, MenuItem, Stack } from "@mui/material";
import React, { useCallback } from "react";
import styled from "styled-components";

import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { quickFilterDescriptions } from "views/candidates/constants";
import { useIsQuickFilterActive, useParams, useResetParams } from "views/candidates/hooks";
import { QuickFilterEnum } from "views/candidates/types";

interface QuickFilterProps {
  label: string;
  value: QuickFilterEnum;
  count?: number;
  isDropdownItem?: boolean;
}

export const QuickFilter = ({ label, value, count, isDropdownItem }: QuickFilterProps): React.ReactElement => {
  const [, setParams] = useParams();
  const resetParams = useResetParams();

  const isActive = useIsQuickFilterActive(value);

  const handleClick = useCallback((): void => {
    if (isActive) {
      resetParams();
      return;
    }

    setParams({ quickFilter: value });
  }, [isActive, resetParams, setParams, value]);

  const quickFilterInnerContent = (
    <>
      <BodySmall weight={isActive ? "600" : "400"} style={{ whiteSpace: "nowrap" }}>
        {label}
      </BodySmall>
      {count !== undefined && <QuickFilterSelectorCount colored={false}>{count}</QuickFilterSelectorCount>}
    </>
  );

  return (
    <Tooltip title={quickFilterDescriptions[value]} enterDelay={500}>
      {isDropdownItem ? (
        <MenuItem
          sx={{
            backgroundColor: isActive ? colors.primary.light : colors.white,
          }}
          onClick={handleClick}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {quickFilterInnerContent}
          </Stack>
        </MenuItem>
      ) : (
        <QuickFilterSelector direction="row" spacing={1} selected={isActive} onClick={handleClick}>
          {quickFilterInnerContent}
        </QuickFilterSelector>
      )}
    </Tooltip>
  );
};

interface QuickFilterSelectorProps {
  selected: boolean;
}

const QuickFilterSelector = styled(Stack)<QuickFilterSelectorProps>`
  padding: 8px 16px;
  border: ${(props: QuickFilterSelectorProps): string =>
    `solid 1px ${props.selected ? colors.success.base : colors.grayscale.gray300}`};
  background-color: ${(props: QuickFilterSelectorProps): string =>
    props.selected ? colors.primary.light : colors.white};
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
`;

interface QuickFilterSelectorCountProps {
  colored: boolean;
}

const QuickFilterSelectorCount = styled(Box)<QuickFilterSelectorCountProps>`
  padding: 0 6px;
  background-color: ${(props: QuickFilterSelectorCountProps): string =>
    props.colored ? colors.critical.base : colors.grayscale.gray200};
  border-radius: 20px;
  color: ${(props: QuickFilterSelectorCountProps): string => (props.colored ? colors.white : colors.black)};
  font-size: 12px;
  font-weight: 600;
  font-family: Inter;
`;
