import { Box, Stack } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import { MentionListActions, MentionSuggestionProps } from "components/library/TipTap/types";
import { BodySmall, BodyExtraSmall } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useIsContractPlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { selectProUsers, useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { DoverUser } from "services/openapi";
import { colors } from "styles/theme";

const MentionList = forwardRef<MentionListActions, MentionSuggestionProps>((props, ref) => {
  const { data: proUsers } = useListProUsersForClientQuery({});
  const { data: client } = useGetUsersClientQuery();

  const isContractPlan = useIsContractPlanCustomer();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number): void => {
    const item = props.items[index];

    if (item) {
      props.command(item);
    }
  };

  const upHandler = (): void => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = (): void => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const hoverHandler = (index: number): void => {
    setSelectedIndex(index);
  };

  const enterHandler = (): void => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: any }): boolean => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  // Create new list that includes csm as well as pro users
  const usersList: DoverUser[] | undefined = useMemo(() => {
    const proUserList = proUsers ? selectProUsers(proUsers) : [];

    const csm = client?.csm?.proUser;
    const csmList = isContractPlan && csm ? [csm] : [];

    return [...proUserList, ...csmList];
  }, [isContractPlan, client?.csm?.proUser, proUsers]);

  if (!proUsers) {
    return <></>;
  }

  return (
    <Box
      sx={{
        background: colors.white,
        borderRadius: "4px",
        boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        padding: "4px",
        position: "relative",
      }}
    >
      {props.items.length ? (
        props.items.map((item, index) => {
          const proUser = usersList?.find(user => user.id?.toString() === item.userId);
          if (!proUser) {
            return <></>;
          }

          return (
            <Stack
              direction="row"
              alignItems="center"
              onMouseEnter={(): void => hoverHandler(index)}
              sx={{
                backgroundColor: index === selectedIndex ? colors.informational.light : colors.white,
                cursor: "pointer",
              }}
            >
              <PersonaCoin
                // Safe cast as proUser ID is guaranteed to be defined
                id={proUser.id!.toString()}
                pictureUrl={proUser.pictureUrl}
                firstName={proUser.firstName}
                lastName={proUser.lastName}
              />
              <Stack
                sx={{
                  padding: "6px",
                  paddingRight: "30px",
                }}
                key={index}
                onClick={(): void => selectItem(index)}
              >
                <BodySmall>{item.label}</BodySmall>
                {item.subheader && <BodyExtraSmall color={colors.grayscale.gray500}>{item.subheader}</BodyExtraSmall>}
              </Stack>
            </Stack>
          );
        })
      ) : (
        <Box className="item" sx={{ padding: "4px 7px" }}>
          No result
        </Box>
      )}
      {props.footer ?? <></>}
    </Box>
  );
});

export default MentionList;
