/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignMessageRequestEmailAlias
 */
export interface CampaignMessageRequestEmailAlias {
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequestEmailAlias
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequestEmailAlias
     */
    readonly fullName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignMessageRequestEmailAlias
     */
    readonly isValidEmailAlias?: boolean;
}

export function CampaignMessageRequestEmailAliasFromJSON(json: any): CampaignMessageRequestEmailAlias {
    return CampaignMessageRequestEmailAliasFromJSONTyped(json, false);
}

export function CampaignMessageRequestEmailAliasFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignMessageRequestEmailAlias {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'isValidEmailAlias': !exists(json, 'is_valid_email_alias') ? undefined : json['is_valid_email_alias'],
    };
}

export function CampaignMessageRequestEmailAliasToJSON(value?: CampaignMessageRequestEmailAlias | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}


