/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobFeatureSettingJob,
    JobFeatureSettingJobFromJSON,
    JobFeatureSettingJobFromJSONTyped,
    JobFeatureSettingJobToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobFeatureSetting
 */
export interface JobFeatureSetting {
    /**
     * 
     * @type {JobFeatureSettingJob}
     * @memberof JobFeatureSetting
     */
    job: JobFeatureSettingJob;
    /**
     * 
     * @type {string}
     * @memberof JobFeatureSetting
     */
    featureName: JobFeatureSettingFeatureNameEnum;
    /**
     * 
     * @type {string}
     * @memberof JobFeatureSetting
     */
    state: JobFeatureSettingStateEnum;
    /**
     * 
     * @type {object}
     * @memberof JobFeatureSetting
     */
    debugInfo?: object | null;
}

/**
* @export
* @enum {string}
*/
export enum JobFeatureSettingFeatureNameEnum {
    ManagedOutbound = 'MANAGED_OUTBOUND',
    DoverInterviewer = 'DOVER_INTERVIEWER',
    E2EScheduling = 'E2E_SCHEDULING',
    ScheduleInitialCustomerCallOnly = 'SCHEDULE_INITIAL_CUSTOMER_CALL_ONLY'
}/**
* @export
* @enum {string}
*/
export enum JobFeatureSettingStateEnum {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

export function JobFeatureSettingFromJSON(json: any): JobFeatureSetting {
    return JobFeatureSettingFromJSONTyped(json, false);
}

export function JobFeatureSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFeatureSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'job': JobFeatureSettingJobFromJSON(json['job']),
        'featureName': json['feature_name'],
        'state': json['state'],
        'debugInfo': !exists(json, 'debug_info') ? undefined : json['debug_info'],
    };
}

export function JobFeatureSettingToJSON(value?: JobFeatureSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': JobFeatureSettingJobToJSON(value.job),
        'feature_name': value.featureName,
        'state': value.state,
        'debug_info': value.debugInfo,
    };
}


