import React from "react";

export interface FilterToggle {
  isToggling: boolean;
  onFilterToggled: () => void;
}

export const FilterTogglerContext: React.Context<FilterToggle | undefined> = React.createContext<
  FilterToggle | undefined
>(undefined);

export const FilterToggleWrapper: React.FC = ({ children }) => {
  const [isToggling, setIsToggling] = React.useState<boolean>(false);
  const [timerId, setTimerId] = React.useState<number | undefined>(undefined);

  const onFilterToggled = React.useCallback(() => {
    setIsToggling(true);
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }

    const timer = window.setTimeout(() => {
      setIsToggling(false);
    }, 1000);
    setTimerId(timer);
  }, [timerId]);

  return (
    <FilterTogglerContext.Provider value={{ isToggling, onFilterToggled }}>{children}</FilterTogglerContext.Provider>
  );
};

interface FormLoadState {
  loaded: boolean;
  setFormValuesLoaded: (loaded: boolean) => void;
  formValuesLoading: boolean;
  setFormValuesLoading: (loading: boolean) => void;
}

export const FormLoadStateContext: React.Context<FormLoadState | undefined> = React.createContext<
  FormLoadState | undefined
>(undefined);

export const FormLoadStateWrapper: React.FC = ({ children }) => {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const setFormValuesLoaded = React.useCallback((loaded: boolean) => {
    setLoaded(loaded);
  }, []);
  const [formValuesLoading, setFormValuesLoading] = React.useState<boolean>(false);

  return (
    <FormLoadStateContext.Provider value={{ loaded, setFormValuesLoaded, formValuesLoading, setFormValuesLoading }}>
      {children}
    </FormLoadStateContext.Provider>
  );
};
