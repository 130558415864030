import { toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastOptions: ToastOptions = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
};

export const showErrorToast = (label: string): React.ReactText =>
  toast(label, {
    type: toast.TYPE.ERROR,
    ...toastOptions,
  });

export const showSuccessToast = (label: string): React.ReactText =>
  toast(label, {
    type: toast.TYPE.SUCCESS,
    ...toastOptions,
  });

export const showPendingToast = (label: string): React.ReactText =>
  toast(label, {
    type: toast.TYPE.INFO,
    ...toastOptions,
  });

export const showDefaultToast = (label: string): React.ReactText =>
  toast(label, {
    type: toast.TYPE.DEFAULT,
    ...toastOptions,
  });
