import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { FormControl, FormGroup, FormHelperText, Slider as MUISlider, FormLabel, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { validationError, FormFieldGrid } from "components/data-driven-forms/fields/helpers";
import { FieldWrapper } from "components/data-driven-forms/styles/layout";

const RangeSlider = props => {
  const {
    input,
    isReadOnly,
    isDisabled,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    FormFieldGridProps,
    FormControlProps,
    FormGroupProps,
    FormLabelProps,
    FormHelperTextProps,
    before,
    after,
    InputGridProps,
    BeforeGridProps,
    SliderGridProps,
    AfterGridProps,
    initialValue,
    ...rest
  } = useFieldApi(props);

  const [value, setValue] = useState(initialValue ?? input.value);

  const invalid = validationError(meta, validateOnMount);
  const text = invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description;

  return (
    <FieldWrapper>
      <FormFieldGrid {...FormFieldGridProps}>
        <FormControl fullWidth required={isRequired} error={!!invalid} component="fieldset" {...FormControlProps}>
          <FormGroup {...FormGroupProps}>
            <FormLabel component="legend" {...FormLabelProps}>
              {label}
            </FormLabel>
            <Grid container spacing={2} alignItems="center" {...InputGridProps}>
              {before && (
                <Grid item {...BeforeGridProps}>
                  {before}
                </Grid>
              )}
              <Grid item xs {...SliderGridProps}>
                <MUISlider
                  {...input}
                  value={value}
                  {...rest}
                  disabled={isDisabled || isReadOnly}
                  onChange={(_e, value) => {
                    setValue(value);
                    input.onChange(value);
                  }}
                />
              </Grid>
              {after && (
                <Grid item {...AfterGridProps}>
                  {after}
                </Grid>
              )}
            </Grid>
            {text && <FormHelperText {...FormHelperTextProps}>{text}</FormHelperText>}
          </FormGroup>
        </FormControl>
      </FormFieldGrid>
    </FieldWrapper>
  );
};

RangeSlider.propTypes = {
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  description: PropTypes.node,
  validateOnMount: PropTypes.bool,
  FormFieldGridProps: PropTypes.object,
  FormControlProps: PropTypes.object,
  FormGroupProps: PropTypes.object,
  FormLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  before: PropTypes.node,
  after: PropTypes.node,
  InputGridProps: PropTypes.object,
  BeforeGridProps: PropTypes.object,
  SliderGridProps: PropTypes.object,
  AfterGridProps: PropTypes.object,
};

export default RangeSlider;
