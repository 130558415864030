import { Box, Divider, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import _ from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { AccountSettingsSectionType } from "App/routing/types";
import { ReactComponent as WarningIconSVG } from "assets/icons/alert.svg";
import { ReactComponent as HelpIconSVG } from "assets/icons/help-question.svg";
import { ReactComponent as MailOpenIconSVG } from "assets/icons/mail-open.svg";
import { ReactComponent as ThumbsUpSVG } from "assets/icons/thumbs-up.svg";
import { addCandidateToCampaignModalAtom } from "components/dover/AddCandidate/AddCandidateToCampaignModal";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall, Subtitle1 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useModal } from "GlobalOverlays/atoms";
import { useGetCampaignStatsQuery } from "services/doverapi/endpoints/campaign";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import { CampaignStats, ListCampaign, ListCampaignStateEnum } from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { getEmailAliasName } from "utils/getEmailAliasName";
import { CampaignDrawerAtom } from "views/job/JobSetup/steps/CampaignVariants/CampaignDrawer";
import CampaignStateToggle from "views/job/JobSetup/steps/CampaignVariants/CampaignStateToggle";
import { useIsCampaignEmailSenderAuthed } from "views/job/JobSetup/steps/CampaignVariants/hooks";
import { StatusCell } from "views/job/JobSetup/steps/CampaignVariants/StatusCell";
import { CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";
import { convertFloatToPercentWithOneDecimal } from "views/job/JobSetup/steps/CampaignVariants/utils";

/*************************************************
 * Subcomponents
 ************************************************/

const AuthGmailTooltip = ({ campaign }: { campaign: ListCampaign }): React.ReactElement => {
  const { data: userDefinedSenderUser } = useGetProUserQuery(
    campaign.userDefinedSenderUser ? ((campaign.userDefinedSenderUser as unknown) as string) : skipToken
  );

  const { isAuthed: isCampaignEmailSenderAuthed } = useIsCampaignEmailSenderAuthed({
    userDefinedSenderUser: campaign.userDefinedSenderUser,
    emailSenderOption: campaign.emailSenderOption,
    emailAliasEmail: campaign.emailAlias?.email ?? undefined,
    emailAliasGmailCredentialAuthState: campaign.emailAlias?.gmailCredentialAuthState,
  });

  if (isCampaignEmailSenderAuthed) {
    return <></>;
  }

  const name = getEmailAliasName(userDefinedSenderUser, campaign.emailSenderOption, campaign.emailAlias);

  return (
    <Tooltip
      onClick={(e): void => e.stopPropagation()}
      title={
        <>
          {`To send emails from ${campaign.emailAlias?.email} please have ${name} `}
          <InternalLink
            target="_blank"
            $variant="secondary"
            to={APP_ROUTE_PATHS.accountSetup(AccountSettingsSectionType.EMAIL_CONNECTION)}
          >{` connect their Gmail account`}</InternalLink>
        </>
      }
    >
      <Box mr={0.5}>
        <WarningIconSVG />
      </Box>
    </Tooltip>
  );
};

const CampaignStat = ({
  icon,
  label,
  statName,
  isPercentage,
  campaignStats,
}: {
  icon?: React.ReactNode;
  label: string;
  statName: "interestRate" | "openRate" | "numContacted";
  isPercentage: boolean;
  campaignStats: CampaignStats;
}): React.ReactElement => {
  const stat = campaignStats[statName];

  const statValue = useMemo(
    (): React.ReactElement =>
      _.isNil(stat) ? (
        <Tooltip
          title="No candidates have been contacted through this campaign yet. Check back in a few days!"
          placement="top"
        >
          <div>
            <span>
              --
              <HelpIconSVG color={colors.grayscale.gray400} className="svg-color" />
            </span>
          </div>
        </Tooltip>
      ) : (
        <>{isPercentage ? convertFloatToPercentWithOneDecimal(stat) : stat}</>
      ),
    [isPercentage, stat]
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {icon && icon}
        <BodySmall>{label}: </BodySmall>
      </Stack>
      <BodySmall>{statValue}</BodySmall>
    </Stack>
  );
};

/*************************************************
 * Main Component
 ************************************************/

const CampaignCard = ({ campaign }: { campaign: ListCampaign }): React.ReactElement => {
  const { open: openCampaignDrawer } = useModal(CampaignDrawerAtom);
  const { open: openAddCandidateToCampaignModal } = useModal(addCandidateToCampaignModalAtom);

  const { data: userDefinedSenderUser, isFetching: isFetchingProUser } = useGetProUserQuery(
    (campaign.userDefinedSenderUser as unknown) as string
  );
  const { data: campaignStats, isFetching: isFetchingCampaignStats } = useGetCampaignStatsQuery(
    campaign?.id ? { campaignId: campaign.id! } : skipToken
  );

  const campaignStatus: CampaignStatus =
    campaign.state === ListCampaignStateEnum.Active ? CampaignStatus.Active : CampaignStatus.Inactive;

  const disableSourceCandidateButton = campaign.state === ListCampaignStateEnum.Inactive;

  const emailSenderDisplay = userDefinedSenderUser?.fullName ?? "Not set";

  return (
    <CampaignCardContainer
      spacing={1}
      p={1}
      onClick={(): void => {
        openCampaignDrawer({ campaignId: campaign.id });
      }}
    >
      <Stack spacing={0.5} width="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" alignItems="center">
            <CampaignStateToggle campaign={campaign} />
            <Box ml={0.5}>
              <StatusCell status={campaignStatus} stats={campaignStats} />
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Subtitle1>{campaign.name}</Subtitle1>

      <BodyExtraSmall color={colors.grayscale.gray600} weight="500">
        {isFetchingProUser ? (
          <Skeleton width="70%" />
        ) : (
          <Stack direction="row" alignItems="center">
            <AuthGmailTooltip campaign={campaign} />
            <BodySmall>{emailSenderDisplay}</BodySmall>
          </Stack>
        )}
      </BodyExtraSmall>
      {isFetchingCampaignStats || !campaignStats ? (
        <Box height="50px">
          <DoverLoadingSpinner spinnerSize={"40px"} minHeight={"50px"} />
        </Box>
      ) : (
        <Stack spacing={1}>
          <CampaignStat
            label="Total contacted"
            statName="numContacted"
            isPercentage={false}
            campaignStats={campaignStats}
          />
          <CampaignStat
            label="Open"
            statName="openRate"
            icon={<MailOpenIconSVG />}
            isPercentage={true}
            campaignStats={campaignStats}
          />
          <CampaignStat
            label="Interested"
            statName="interestRate"
            icon={<ThumbsUpSVG color={colors.black} className="svg-color" />}
            isPercentage={true}
            campaignStats={campaignStats}
          />
        </Stack>
      )}
      {/* Apply negative margin to Divider to expand over container padding */}
      <Divider sx={{ marginLeft: "-8px !important", marginRight: "-8px !important" }} />
      <Button
        width="100%"
        variant={ButtonVariant.Secondary}
        onClick={(e): void => {
          e.stopPropagation();
          if (campaign.id) {
            openAddCandidateToCampaignModal({ campaignId: campaign.id });
          } else {
            console.error("Campaign id is missing");
          }
        }}
        disabled={disableSourceCandidateButton}
        tooltip={disableSourceCandidateButton ? "Campaign must be active to source candidates" : ""}
      >
        Source candidate
      </Button>
    </CampaignCardContainer>
  );
};

const CampaignCardContainer = styled(Stack)`
  height: auto;
  min-width: 186px;
  border-radius: 10px;
  border: 2px solid ${colors.grayscale.gray200};
  margin-right: 12px;
  background-color: ${backgrounds.white};
  cursor: pointer;

  // Fancy shmancy selector to disable the hover effect when the child button is hovered
  &:hover:not(:has(button:hover)) {
    background: ${colors.grayscale.gray100};
  }
`;

export default CampaignCard;
