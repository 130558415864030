import { createSelector, Selector, SerializedError } from "@reduxjs/toolkit";
import _ from "lodash";

import { selectGlobalState } from "domains/global/selectors";
import { GlobalRootState } from "domains/global/types";
import { jobEndpoints } from "services/doverapi/endpoints/job";
import { getEntityById } from "services/doverapi/entityAdapterUtils";
import {
  AuthUser,
  DashboardJob,
  JobReferral,
  JobReferralStats,
  JobReferralTriagingStatusEnum,
} from "services/openapi/models";
import { reviewReferralsKey, initialState } from "views/Referrals/ReviewReferrals/constants";
import { ReviewReferralsState } from "views/Referrals/ReviewReferrals/types";
import { filterJobReferrals, getFilterMapKey } from "views/Referrals/ReviewReferrals/utils";

const selectState = (state: GlobalRootState): ReviewReferralsState => state[reviewReferralsKey] || initialState;

export const selectJobs = createSelector<GlobalRootState, ReviewReferralsState, DashboardJob[]>(
  [selectState],
  state => Object.values(state.jobs) as DashboardJob[]
);

export const selectJobsInitialized = createSelector<GlobalRootState, ReviewReferralsState, boolean>(
  [selectState],
  state => state.jobsInitialized
);

const selectSelectedJobId = createSelector<GlobalRootState, ReviewReferralsState, string | null | undefined>(
  [selectState],
  (state): string | null | undefined => state.selectedJobId
);

export const selectSelectedJob = createSelector<
  GlobalRootState,
  GlobalRootState,
  string | null | undefined,
  DashboardJob | null | undefined
>([selectGlobalState, selectSelectedJobId], (globalState, selectedJobId) => {
  // We haven't yet determined if there's a selected job
  if (selectedJobId === undefined) {
    return undefined;
  }
  // We've determined that there is no job selected
  if (selectedJobId === null) {
    return null;
  }

  const { data: jobs } = jobEndpoints.endpoints.listJobs.select({
    active: "true",
  })(globalState);

  // We've determined that there is a job selected
  return getEntityById(jobs, selectedJobId);
});

export const selectCurrentPageIndex = createSelector<GlobalRootState, ReviewReferralsState, number>(
  [selectState],
  (state): number => state.currentPageIndex
);

export const selectNumItemsPerPage = createSelector<GlobalRootState, ReviewReferralsState, number>(
  [selectState],
  (state): number => state.numItemsPerPage
);

export const selectLoadingJobReferrals = createSelector<GlobalRootState, ReviewReferralsState, boolean>(
  [selectState],
  (state): boolean => state.loadingJobReferrals
);

export const selectLoadingJobReferralsError = createSelector<
  GlobalRootState,
  ReviewReferralsState,
  SerializedError | null
>([selectState], (state): SerializedError | null => state.loadingJobReferralsError);

const selectAllLoadedJobReferrals = createSelector<GlobalRootState, ReviewReferralsState, JobReferral[]>(
  [selectState],
  (state): JobReferral[] => Object.values(state.jobReferrals)
);

export const selectReferrersInitialized = createSelector<GlobalRootState, ReviewReferralsState, boolean>(
  [selectState],
  (state): boolean => state.referrersInitialized
);

export const selectReferrers = createSelector<GlobalRootState, ReviewReferralsState, AuthUser[]>(
  [selectState],
  (state): AuthUser[] => _.sortBy(Object.values(state.referrers), "firstName")
);

export const selectSelectedReferrer = createSelector<
  GlobalRootState,
  ReviewReferralsState,
  AuthUser | null | undefined
>([selectState], state => {
  // We haven't yet determined if there's a selected referrer
  if (state.selectedReferrerId === undefined) {
    return undefined;
  }
  // We've determined that there is no referrer selected
  if (state.selectedReferrerId === null) {
    return null;
  }
  // We've determined that there is a referrer selected
  return state.referrers[state.selectedReferrerId];
});

export const selectFilteredJobReferrals = (
  triagingStatus: JobReferralTriagingStatusEnum | undefined
): Selector<GlobalRootState, JobReferral[]> =>
  createSelector(
    [selectAllLoadedJobReferrals, selectSelectedJob, selectSelectedReferrer],
    (
      allJobReferrals: JobReferral[],
      selectedJob: DashboardJob | null | undefined,
      selectedReferrer: AuthUser | null | undefined
    ): JobReferral[] =>
      filterJobReferrals(allJobReferrals, {
        jobId: selectedJob?.id,
        referrerId: selectedReferrer?.id,
        triagingStatus,
      })
  );

export const selectTotalNumJobReferrals = (
  triagingStatus: JobReferralTriagingStatusEnum | undefined
): Selector<GlobalRootState, number | undefined> =>
  createSelector(
    [selectState, selectSelectedReferrer, selectSelectedJob],
    (state, selectedReferrer, selectedJob): number | undefined =>
      state.totalNumJobReferralsMap[
        getFilterMapKey({
          jobId: selectedJob?.id,
          referrerId: selectedReferrer?.id ? String(selectedReferrer?.id) : undefined,
          triagingStatus,
        })
      ]
  );

export const selectReferralStats = (): Selector<GlobalRootState, JobReferralStats | null> =>
  createSelector(
    [selectState, selectSelectedReferrer, selectSelectedJob],
    (state, selectedReferrer, selectedJob): JobReferralStats | null =>
      state.referralStatsMap[
        getFilterMapKey({
          jobId: selectedJob?.id,
          referrerId: selectedReferrer?.id ? String(selectedReferrer?.id) : undefined,
        })
      ]
  );
