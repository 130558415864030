import { Button, ToggleButton } from "@doverhq/dover-ui";
import { ReactComponent as MagnifyingGlassIcon } from "@doverhq/dover-ui/icons/magnifying-glass.svg";
import { Stack } from "@mui/material";
import { useAtom } from "jotai";
import React, { FC } from "react";
import { useBoolean } from "react-use";

import { ReactComponent as ScoringSVG } from "assets/icons/scoring.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useGetClientIsTam } from "services/doverapi/endpoints/client/hooks";
import { colors } from "styles/theme";
import { CriteriaMode, criteriaModeAtom } from "views/candidates/ApplicationReview/atoms/chat";
import ApplicantFilters from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantFilters";
import { ApplicantSearchBar } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantSearchBar";
import { ApplicantSort } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantSort";

export const ApplicantListToolbar: FC = () => {
  const [criteriaMode, setCriteriaMode] = useAtom(criteriaModeAtom);

  const [searchBarOpen, toggleSearchBar] = useBoolean(false);

  const simpReviewFFOn = useFeatureFlag(FeatureFlag.SimpReview);
  let clientIsExplicitNonTam = useGetClientIsTam() === false;
  // for now gate this logic behind simpReviewFF
  clientIsExplicitNonTam = clientIsExplicitNonTam && simpReviewFFOn;

  const isCriteriaSelected = criteriaMode === CriteriaMode.Criteria;

  const clickCriteria = (): void => {
    setCriteriaMode(isCriteriaSelected ? undefined : CriteriaMode.Criteria);
  };

  return (
    <Stack spacing={0.5} borderBottom={`1px solid ${colors.grayscale.gray200}`}>
      <Stack direction="row" justifyContent="space-evenly" py={0.5} alignItems="center">
        {/* Criteria */}
        {!clientIsExplicitNonTam && (
          <Tooltip title="Applicants will be sorted based on your criteria.">
            <div>
              <ToggleButton isSelected={isCriteriaSelected} onPress={clickCriteria}>
                <ScoringSVG
                  className="svg-color"
                  color={isCriteriaSelected ? colors.primary.base : colors.grayscale.gray600}
                />
                <BodySmall color={colors.grayscale.gray600}>Scoring</BodySmall>
              </ToggleButton>
            </div>
          </Tooltip>
        )}
        {/* Filters */}
        <ApplicantFilters />
        {/* Sort */}
        <ApplicantSort />
        {/* Search Trigger */}
        <Tooltip title="Search candidate by name">
          <div>
            <Button variant="ghost" p={1} icon={{ Icon: MagnifyingGlassIcon }} onPress={toggleSearchBar} />
          </div>
        </Tooltip>
      </Stack>
      {/* Name filtering doesn't work on the AI route */}
      {searchBarOpen && <ApplicantSearchBar />}
    </Stack>
  );
};
