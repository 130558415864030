import { Stack } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

import FeedbackTemplateSelect from "components/dover/FeedbackTemplates/FeedbackTemplateSelect";
import { InfoTip } from "components/InfoTip";
import { StageDrawerFormLabel } from "components/SetupHiringPipeline/components/InterviewPlan/styles";

export const EditFeedbackTemplate = ({ fieldPrefix }: { fieldPrefix: string }): React.ReactElement => {
  return (
    <Stack spacing={1}>
      <Stack spacing={1} direction={"row"}>
        <StageDrawerFormLabel bold>Feedback Form</StageDrawerFormLabel>
        <InfoTip text={"Assign a feedback form for interviewers to complete during interviews for this stage."} />
      </Stack>

      <Field name={`${fieldPrefix}.defaultFeedbackTemplate`}>
        {(props): React.ReactElement => {
          return (
            <FeedbackTemplateSelect
              feedbackTemplateId={props.input.value}
              setFeedbackTemplateId={props.input.onChange}
              allowEdits
            />
          );
        }}
      </Field>
    </Stack>
  );
};
