/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtsJob,
    AtsJobFromJSON,
    AtsJobFromJSONTyped,
    AtsJobToJSON,
    AtsUser,
    AtsUserFromJSON,
    AtsUserFromJSONTyped,
    AtsUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface AtsData
 */
export interface AtsData {
    /**
     * 
     * @type {boolean}
     * @memberof AtsData
     */
    success: boolean;
    /**
     * 
     * @type {Array<AtsJob>}
     * @memberof AtsData
     */
    jobs: Array<AtsJob>;
    /**
     * 
     * @type {Array<AtsUser>}
     * @memberof AtsData
     */
    users: Array<AtsUser>;
    /**
     * 
     * @type {Array<AtsUser>}
     * @memberof AtsData
     */
    siteAdmins: Array<AtsUser>;
}

export function AtsDataFromJSON(json: any): AtsData {
    return AtsDataFromJSONTyped(json, false);
}

export function AtsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'jobs': ((json['jobs'] as Array<any>).map(AtsJobFromJSON)),
        'users': ((json['users'] as Array<any>).map(AtsUserFromJSON)),
        'siteAdmins': ((json['site_admins'] as Array<any>).map(AtsUserFromJSON)),
    };
}

export function AtsDataToJSON(value?: AtsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'jobs': ((value.jobs as Array<any>).map(AtsJobToJSON)),
        'users': ((value.users as Array<any>).map(AtsUserToJSON)),
        'site_admins': ((value.siteAdmins as Array<any>).map(AtsUserToJSON)),
    };
}


