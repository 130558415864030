import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { ClientOnboardingAtsTypeEnum } from "services/openapi";

export function useShouldSyncJobAtsStages({ jobId }: { jobId: string | null | undefined }): boolean {
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);
  const { data: clientOnboarding } = useGetClientOnboardingQuery();

  return useMemo(() => {
    // Make sure prerequisite data is loaded before determining whether or not we should sync ats stages.
    if (!jobSetup || !clientOnboarding) {
      return false;
    }

    // Don't bother if creds haven't yet been provided
    if (!jobSetup?.atsCredentialsProvided) {
      return false;
    }

    const companyAtsType = clientOnboarding?.atsType;
    const atsJobId = jobSetup?.atsJobId;

    // Only relevant for certain ats types
    if (companyAtsType === ClientOnboardingAtsTypeEnum.Lever) {
      // TODO: update this for Lever OAuth flow. This should behave like Greenhouse now.
      // We'll need to check for the feature flag.
      // https://app.shortcut.com/dover/story/46328/find-a-way-to-pass-down-feature-flag-to-selectors-reducers-actions
      return true;
    }
    // Greenhouse requires that credentials be present and that the user has previously supplied the ats job id.
    if (companyAtsType === ClientOnboardingAtsTypeEnum.Greenhouse && atsJobId) {
      return true;
    }
    if (companyAtsType === ClientOnboardingAtsTypeEnum.Ashby) {
      return true;
    }
    return false;
  }, [clientOnboarding, jobSetup]);
}
