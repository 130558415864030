import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { CLIENT_ONBOARDING } from "services/doverapi/endpointTagsConstants";
import { CareersPageClient } from "services/openapi";

const careersPageClient = doverApi.injectEndpoints({
  endpoints: build => ({
    getCareersPageClient: build.query<CareersPageClient, { clientId?: string; clientSlug?: string }>({
      queryFn: async ({ clientId, clientSlug }) => {
        // get unauthenticated api client by setting includeToken to false
        if (!clientId && !clientSlug) {
          throw "Must pass either clientId or ClientSlug";
        }
        const { apiApi: client } = await getOpenApiClients({ includeToken: false });
        try {
          // use client slug if available
          if (clientSlug) {
            const response = await client.getCareersPageClientBySlug({ slug: clientSlug });
            return {
              data: response,
            };
          }
          // else use client id
          const response = await client.getCareersPageClient({ id: clientId as string });
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, args) => {
        return args.clientId ? [{ type: CLIENT_ONBOARDING, id: args.clientId }] : [];
      },
    }),
  }),
});

export const { useGetCareersPageClientQuery } = careersPageClient;
