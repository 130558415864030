/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlimClient,
    SlimClientFromJSON,
    SlimClientFromJSONTyped,
    SlimClientToJSON,
    SlimJob,
    SlimJobFromJSON,
    SlimJobFromJSONTyped,
    SlimJobToJSON,
    SlimSearchTemplate,
    SlimSearchTemplateFromJSON,
    SlimSearchTemplateFromJSONTyped,
    SlimSearchTemplateToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminCampaign
 */
export interface AdminCampaign {
    /**
     * 
     * @type {string}
     * @memberof AdminCampaign
     */
    readonly id?: string;
    /**
     * 
     * @type {SlimJob}
     * @memberof AdminCampaign
     */
    job?: SlimJob;
    /**
     * 
     * @type {SlimClient}
     * @memberof AdminCampaign
     */
    client?: SlimClient;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaign
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminCampaign
     */
    readonly modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminCampaign
     */
    state?: AdminCampaignStateEnum;
    /**
     * 
     * @type {SlimSearchTemplate}
     * @memberof AdminCampaign
     */
    persona?: SlimSearchTemplate;
    /**
     * 
     * @type {number}
     * @memberof AdminCampaign
     */
    readonly openRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminCampaign
     */
    readonly interestRate?: number | null;
}

/**
* @export
* @enum {string}
*/
export enum AdminCampaignStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED'
}

export function AdminCampaignFromJSON(json: any): AdminCampaign {
    return AdminCampaignFromJSONTyped(json, false);
}

export function AdminCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'job': !exists(json, 'job') ? undefined : SlimJobFromJSON(json['job']),
        'client': !exists(json, 'client') ? undefined : SlimClientFromJSON(json['client']),
        'name': json['name'],
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'persona': !exists(json, 'persona') ? undefined : SlimSearchTemplateFromJSON(json['persona']),
        'openRate': !exists(json, 'open_rate') ? undefined : json['open_rate'],
        'interestRate': !exists(json, 'interest_rate') ? undefined : json['interest_rate'],
    };
}

export function AdminCampaignToJSON(value?: AdminCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': SlimJobToJSON(value.job),
        'client': SlimClientToJSON(value.client),
        'name': value.name,
        'state': value.state,
        'persona': SlimSearchTemplateToJSON(value.persona),
    };
}


