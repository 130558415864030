import { z } from "zod";

export const singleAddCandidateToCampaignSchema = z
  .object({
    firstName: z.string().or(z.literal("")),
    lastName: z.string().or(z.literal("")),
    email: z
      .string()
      .email({ message: "Invalid email" })
      .optional()
      .or(z.literal(""))
      .nullable(),
    linkedIn: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/.+/, { message: "Invalid LinkedIn URL" })
      .optional()
      .or(z.literal("")),
  })
  .superRefine((data, ctx) => {
    if (!data.email && !data.linkedIn) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Either email or LinkedIn is required",
        path: ["email"],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Either email or LinkedIn is required",
        path: ["linkedIn"],
      });
    }
  });

export type SingleAddCandidateToCampaignSchemaType = z.infer<typeof singleAddCandidateToCampaignSchema>;

export const bulkUploadCandidatesToCampaignSchema = z.object({
  candidatesCsv: z.nullable(z.instanceof(File)),
  manualSourcingItems: z
    .array(
      z.object({
        email: z
          .string()
          .email({ message: "Invalid email" })
          .optional()
          .or(z.literal(""))
          .nullable(),
        url: z.string().url({ message: "Invalid URL" }),
      })
    )
    .nullable(),
});

export type BulkUploadCandidatesToCampaignSchemaType = z.infer<typeof bulkUploadCandidatesToCampaignSchema>;

export type UploadType = "single" | "bulk";
