/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlimNextAction
 */
export interface SlimNextAction {
    /**
     * 
     * @type {string}
     * @memberof SlimNextAction
     */
    state: SlimNextActionStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SlimNextAction
     */
    schedulingState: SlimNextActionSchedulingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SlimNextAction
     */
    owner: SlimNextActionOwnerEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SlimNextAction
     */
    pendingCustomerResponse: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SlimNextAction
     */
    isLastInterview: boolean;
}

/**
* @export
* @enum {string}
*/
export enum SlimNextActionStateEnum {
    NoActionRequired = 'NO_ACTION_REQUIRED',
    NeedsAction = 'NEEDS_ACTION'
}/**
* @export
* @enum {string}
*/
export enum SlimNextActionSchedulingStateEnum {
    NewLead = 'NEW_LEAD',
    Scheduling = 'SCHEDULING',
    Scheduled = 'SCHEDULED',
    InitialReview = 'INITIAL_REVIEW',
    CallCompleted = 'CALL_COMPLETED',
    OfferExtended = 'OFFER_EXTENDED'
}/**
* @export
* @enum {string}
*/
export enum SlimNextActionOwnerEnum {
    User = 'USER',
    Dover = 'DOVER'
}

export function SlimNextActionFromJSON(json: any): SlimNextAction {
    return SlimNextActionFromJSONTyped(json, false);
}

export function SlimNextActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlimNextAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'schedulingState': json['scheduling_state'],
        'owner': json['owner'],
        'pendingCustomerResponse': json['pending_customer_response'],
        'isLastInterview': json['is_last_interview'],
    };
}

export function SlimNextActionToJSON(value?: SlimNextAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'scheduling_state': value.schedulingState,
        'owner': value.owner,
        'pending_customer_response': value.pendingCustomerResponse,
        'is_last_interview': value.isLastInterview,
    };
}


