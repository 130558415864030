import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { CompanySetupSectionType } from "App/routing/types";
import FormRenderer from "components/data-driven-forms";
import { COMPANY_BIO_MAPPER } from "components/data-driven-forms/component-mapper";
import { LoadingRelative } from "components/HotLoading";
import { Spacer } from "components/Spacer";
import { useGetClientId, useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useUpdateCompanyBioMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { useGetCompanyPitchQuestionsQuery } from "services/doverapi/endpoints/company-setup/endpoints";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";

const WhiteCard = styled.div`
  background: white;
  border: 1px solid #bcc7cc;
  border-radius: 6px;
`;

const FormCard = styled(WhiteCard)`
  padding: 24px;
  width: 100%;
`;

const CompanyBiography = React.memo(
  (): React.ReactElement => {
    const clientId = useGetClientId();
    const [updateCompanyBioMutation] = useUpdateCompanyBioMutation();
    const isBasePlanCustomer = useIsBasePlanCustomer();
    const { data: companyPitchQuestions } = useGetCompanyPitchQuestionsQuery({});

    const handleSubmit = React.useCallback(
      (values: any) => {
        updateCompanyBioMutation({ answers: values, clientId });
      },
      [clientId, updateCompanyBioMutation]
    );

    const transformedSchema = React.useMemo(() => {
      if (!companyPitchQuestions?.schema) {
        return {};
      }
      if (!isBasePlanCustomer) {
        // @ts-ignore
        const updatedSchema = companyPitchQuestions.schema.fields.map((field: any): any => {
          if (["primaryProduct", "companyCurrentSize"].includes(field.name)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { validate, ...restOfField } = field;
            restOfField["isRequired"] = false;
            return restOfField;
          }
          return field;
        });
        return {
          fields: [...updatedSchema],
        };
      }
      return companyPitchQuestions.schema;
    }, [isBasePlanCustomer, companyPitchQuestions?.schema]);

    if (!companyPitchQuestions) {
      return (
        <Box display="flex" justifyContent="center">
          <LoadingRelative />
        </Box>
      );
    }

    return (
      <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.DOVER_INTERVIEWER]}>
        <Spacer height="16px" />
        <FormCard>
          <FormRenderer
            schema={transformedSchema}
            onSubmit={handleSubmit}
            initialValues={companyPitchQuestions.answers}
            componentMapper={COMPANY_BIO_MAPPER}
          />
        </FormCard>
      </SectionWrapper>
    );
  }
);

export default CompanyBiography;
