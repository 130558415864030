import { Stack } from "@mui/material";
import React from "react";

import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import NoCandidatesEmptyState from "views/candidates/components/NoCandidatesEmptyState";

const NoApplicantsEmptyState = (): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={2} px={1}>
      <NoCandidatesEmptyState jobId={jobId} variant="app-review" />
    </Stack>
  );
};

export default NoApplicantsEmptyState;
