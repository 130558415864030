import { Menu, Stack, MenuItem, PopoverOrigin } from "@mui/material";
import React, { useState } from "react";

import { Button, ButtonProps, ButtonVariant } from "components/library/Button";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

interface MenuOption {
  label: string | React.ReactNode;
  subtext?: string;
  disabled?: boolean;
  onClick?: () => void;
  labelColor?: string;
  icon?: React.ReactNode;
  iconOnLeft?: boolean;
}

export interface MenuButtonProps extends ButtonProps {
  label: string | React.ReactNode;
  tooltip?: string | React.ReactElement;
  options: MenuOption[];
  menuWidth?: string;
  menuHeight?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export const MenuButton: React.FC<MenuButtonProps> = ({
  label,
  disabled,
  tooltip,
  options,
  menuWidth,
  anchorOrigin,
  transformOrigin,
  removePadding,
  variant = ButtonVariant.Secondary,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <Button
        disabled={disabled}
        tooltip={tooltip}
        removePadding={removePadding}
        width={menuWidth}
        variant={variant}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
          setAnchorEl(e.currentTarget);
        }}
      >
        {label}
      </Button>
      <Menu
        open={!!anchorEl}
        onClose={(): void => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Stack width={menuWidth}>
          {options.map((o, i) => {
            return (
              <MenuItem
                key={i}
                disabled={!!o.disabled}
                onClick={(): void => {
                  o?.onClick?.();
                  setAnchorEl(null);
                }}
              >
                {o.icon ? (
                  <Stack direction="row" justifyContent="left" alignItems="center" spacing={1} width="100%">
                    {o.iconOnLeft ? <>{o.icon}</> : null}
                    <Stack direction="column" spacing={0.5}>
                      <BodySmall color={o.labelColor}>{o.label}</BodySmall>
                      {o.subtext && <BodyExtraSmall color={colors.grayscale.gray600}>{o.subtext}</BodyExtraSmall>}
                    </Stack>
                    {o.iconOnLeft ? null : <>{o.icon}</>}
                  </Stack>
                ) : (
                  <BodySmall color={o.labelColor}>{o.label}</BodySmall>
                )}
              </MenuItem>
            );
          })}
        </Stack>
      </Menu>
    </>
  );
};
