/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject17
 */
export interface InlineObject17 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject17
     */
    isNoShow?: boolean;
}

export function InlineObject17FromJSON(json: any): InlineObject17 {
    return InlineObject17FromJSONTyped(json, false);
}

export function InlineObject17FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject17 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isNoShow': !exists(json, 'is_no_show') ? undefined : json['is_no_show'],
    };
}

export function InlineObject17ToJSON(value?: InlineObject17 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_no_show': value.isNoShow,
    };
}


