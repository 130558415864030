/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { useCandidateId } from "hooks/useCandidateId";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { backgrounds } from "styles/theme";
import { CandidateDetails } from "views/CandidateDetail/components/CandidateDetails";
import { StageAndStatusCardV2 } from "views/CandidateDetail/components/StageAndStatusCard/StageAndStatusCardV2";

export const Bio = (): React.ReactElement => {
  const candidateId = useCandidateId();
  const { data: candidateBio, isFetching: isFetchingCandidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);

  if (!candidateBio || isFetchingCandidateBio) {
    return <></>;
  } else {
    return (
      <Box bgcolor={backgrounds.gray} paddingTop={3} paddingX={2} paddingBottom={2}>
        <StageAndStatusCardV2 />
        <CandidateDetails candidateBio={candidateBio} />
      </Box>
    );
  }
};
