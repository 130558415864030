/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndividualTargetSchoolIds
 */
export interface IndividualTargetSchoolIds {
    /**
     * 
     * @type {string}
     * @memberof IndividualTargetSchoolIds
     */
    field: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IndividualTargetSchoolIds
     */
    value: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualTargetSchoolIds
     */
    required?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof IndividualTargetSchoolIds
     */
    weight?: number | null;
}

export function IndividualTargetSchoolIdsFromJSON(json: any): IndividualTargetSchoolIds {
    return IndividualTargetSchoolIdsFromJSONTyped(json, false);
}

export function IndividualTargetSchoolIdsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividualTargetSchoolIds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'value': json['value'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
    };
}

export function IndividualTargetSchoolIdsToJSON(value?: IndividualTargetSchoolIds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'value': value.value,
        'required': value.required,
        'weight': value.weight,
    };
}


