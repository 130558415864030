import { Box } from "@mui/material";
import React from "react";

import { Tooltip } from "components/library/Tooltip";
import { BaseTypeProps, BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

interface TextWithMaxWidthProps {
  label: string;
  width: string;
  TextElement?: React.FC<BaseTypeProps>;
}

export const TextWithMaxWidth = React.memo(
  ({ label, width, TextElement = BodySmall }: TextWithMaxWidthProps): React.ReactElement => {
    // helper function to create a BodySmall with a max width, after which it will use an ellipsis
    const myRef = React.createRef<HTMLInputElement>();
    const [widthOverload, setWidthOverload] = React.useState(false);

    React.useEffect(() => {
      if (myRef.current) {
        const elementWidth = myRef.current.offsetWidth;
        setWidthOverload(!!elementWidth && elementWidth >= parseInt(width.split("px")[0]));
      }
    }, [width, myRef]);

    const InnerBox = (
      <Box maxWidth={width} ref={myRef}>
        <TextElement ellipsis color={colors.grayscale.gray700}>
          {label}
        </TextElement>
      </Box>
    );

    // we only want to wrap the label with a tooltip if the text is overflowing
    if (widthOverload) {
      return <Tooltip title={label}>{InnerBox}</Tooltip>;
    }
    return InnerBox;
  }
);
