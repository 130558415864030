import { Icon } from "@doverhq/dover-ui";
import { Radio, Stack, styled } from "@mui/material";
import Color from "color";
import React from "react";

import { Body } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import { backgrounds, colors } from "styles/theme";

interface CalibrationSettingCardProps {
  selected?: boolean;
  label: string;
  description: string;
  icon?: any;
  pictureUrl?: string;
  onClick?: () => void;
}

export const CalibrationSettingCard = ({
  selected,
  label,
  description,
  icon,
  pictureUrl,
  onClick,
}: CalibrationSettingCardProps): React.ReactElement => {
  return (
    <StyledSettingCard direction="row" onClick={onClick} selected={selected}>
      <Stack direction="row" spacing={2}>
        <Stack direction="column" alignItems="left">
          {icon && <Icon Icon={icon} size={25} />}
          {pictureUrl && <PersonaCoin size="medium" pictureUrl={pictureUrl} color="gray" />}
        </Stack>
        <Stack direction="row" alignItems="right">
          <Stack direction="column">
            <Body color={colors.black} weight="600">
              {label}
            </Body>
            <Body color={colors.grayscale.gray600}>{description}</Body>
          </Stack>
        </Stack>
      </Stack>
      <Stack justifyContent="flex-end">
        <StyledRadio checked={selected} />
      </Stack>
    </StyledSettingCard>
  );
};

interface StyledSettingCardProps {
  selected?: boolean;
}

export const StyledSettingCard = styled(Stack)`
  background-color: ${({ selected }: StyledSettingCardProps): string =>
    selected ? colors.primary.light : colors.white};
  border: 1px solid
    ${({ selected }: StyledSettingCardProps): string => (selected ? colors.primary.base : colors.grayscale.gray200)};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 8px 16px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  min-height: 90px;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    background-color: ${colors.grayscale.gray100};
  }
`;

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }

  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;
