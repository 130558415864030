import { Box } from "@mui/material";
import { isThisYear } from "date-fns";
import { format } from "date-fns-tz";
import React, { ReactElement, useState } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import CalendarCompletedIcon from "assets/icons/calendar-completed.svg";
import CalendarNoShowIcon from "assets/icons/calendar-no-show.svg";
import CalendarIcon from "assets/icons/calendar-outlined-grey.svg";
import CalendarScheduledIcon from "assets/icons/calendar-scheduled.svg";
import DoverEmailIcon from "assets/icons/dover-email.svg";
import MailForwardedIcon from "assets/icons/forward.svg";
import AdvancedIcon from "assets/icons/green-arrow-right.svg";
import InboundIcon from "assets/icons/inbound.svg";
import JobsIcon from "assets/icons/jobs-icon.svg";
import MailIcon from "assets/icons/mail.svg";
import ReplyIcon from "assets/icons/reply.svg";
import SnoozeIcon from "assets/icons/snooze.svg";
import WithdrawIcon from "assets/icons/withdraw.svg";
import Emoji from "components/Emoji";
import { Spacer } from "components/Spacer";
import {
  ActivityFeedItem,
  ActivityFeedItemSubtypeEnum,
  ActivityFeedItemTypeEnum,
  ActorField,
  ActorFieldActorEnum,
} from "services/openapi";
import { colors, theme } from "styles/theme";
import { CancelEmailButton } from "views/CandidateDetail/components/event-feed/CancelEmailButton";
import { EmailEventBody } from "views/CandidateDetail/components/event-feed/EmailEventBody";
import { EventBadge } from "views/CandidateDetail/components/event-feed/EventBadge";
import { useShowReplyToOption, useShowReplyInThreadOption } from "views/CandidateDetail/components/event-feed/hooks";
import { InterviewEventBody } from "views/CandidateDetail/components/event-feed/InterviewEventBody";
import { MovedJobEventBody } from "views/CandidateDetail/components/event-feed/MovedJobEventBody";
import { ReplyButton } from "views/CandidateDetail/components/event-feed/ReplyButton";

interface Props {
  event: ActivityFeedItem;
}
export const Event = ({ event }: Props): ReactElement => {
  const [expanded, setExpanded] = useState(false);
  let icon;
  let eventBody: ReactElement | null = null;
  const showNeedsDecisionFlow = useShowReplyToOption({
    eventId: event.eventId,
    actor: event.actor,
    isInboundApplicationEvent: event.type === ActivityFeedItemTypeEnum.InboundApplication,
  });
  const { showReplyInThreadButton } = useShowReplyInThreadOption({ eventId: event.eventId });

  const isStageChangeEvent = [
    ActivityFeedItemTypeEnum.InboundApplication,
    ActivityFeedItemTypeEnum.StageReachedUpdated,
    ActivityFeedItemTypeEnum.FinalStatusSet,
  ].includes(event.type);

  switch (event.type) {
    case ActivityFeedItemTypeEnum.Email:
      if (event.actor.actor === ActorFieldActorEnum.Candidate) {
        icon = ReplyIcon;
      } else if (event.actor.actor === ActorFieldActorEnum.Dover) {
        icon = event.actor.action === "forwarded an email" ? MailForwardedIcon : DoverEmailIcon;
      } else {
        icon = MailIcon;
      }
      eventBody = <EmailEventBody emailEventDetails={event.emailEventDetails!} isExpanded={expanded} />;
      break;
    case ActivityFeedItemTypeEnum.Calendar:
      if (event.subtype === ActivityFeedItemSubtypeEnum.InterviewScheduled) {
        icon = CalendarScheduledIcon;
      } else if (event.subtype === ActivityFeedItemSubtypeEnum.InterviewCanceled) {
        icon = CalendarIcon;
      } else if (event.subtype === ActivityFeedItemSubtypeEnum.InterviewNoShow) {
        icon = CalendarNoShowIcon;
      }

      if (event.interviewEventDetails) {
        eventBody = <InterviewEventBody interviewEvent={event} />;
      }
      break;
    case ActivityFeedItemTypeEnum.InterviewFeedback:
      if (event?.interviewEventDetails?.end && event.interviewEventDetails.end > new Date()) {
        icon = CalendarScheduledIcon;
      } else {
        icon = CalendarCompletedIcon;
      }

      eventBody = <InterviewEventBody interviewEvent={event} />;
      break;
    case ActivityFeedItemTypeEnum.InboundApplication:
      icon = InboundIcon;
      eventBody = null;
      break;
    case ActivityFeedItemTypeEnum.StageReachedUpdated:
      icon = AdvancedIcon;
      eventBody = null;
      break;
    case ActivityFeedItemTypeEnum.FinalStatusSet:
      if ([ActivityFeedItemSubtypeEnum.Rejected, ActivityFeedItemSubtypeEnum.Withdraw].includes(event.subtype!)) {
        icon = WithdrawIcon;
      } else if (event.subtype === ActivityFeedItemSubtypeEnum.Snoozed) {
        icon = SnoozeIcon;
      }
      eventBody = null;
      break;
    case ActivityFeedItemTypeEnum.MovedToDifferentJob:
      icon = JobsIcon;
      if (event.movedJobEventDetails) {
        eventBody = <MovedJobEventBody event={event} />;
      }
      break;
    default:
  }

  const isEmail = event.type === ActivityFeedItemTypeEnum.Email;
  const isQueuedEmail = isEmail && event.esrId && event.actor.action?.includes("(queued)");

  return (
    <EventContainer
      $isEmail={isEmail}
      isStageChangeEvent={isStageChangeEvent}
      $clickable={isEmail}
      $activeBadge={showNeedsDecisionFlow}
      onClick={(): void => setExpanded(!expanded)}
      className={isEmail && event.actor.actor === ActorFieldActorEnum.Candidate ? "candidate-email" : undefined}
    >
      {showNeedsDecisionFlow && <EventBadge />}
      {event.subtype === ActivityFeedItemSubtypeEnum.Hired ? (
        <Box fontSize="20px">
          <Emoji symbol="🎉" label="celebrate" />
        </Box>
      ) : (
        <EventIcon src={icon} />
      )}
      <Spacer width={16} />
      <EventContent>
        <EventTitle>
          <Box display="flex" alignItems="center">
            <ActorAction actor={event.actor} isStageChangeEvent={isStageChangeEvent} />
          </Box>
          <Timestamp>{formatEventTimestamp(event.timestamp)}</Timestamp>
        </EventTitle>
        {eventBody && (
          <>
            <Spacer height={16} />
            <div>{eventBody}</div>
          </>
        )}
        {(showNeedsDecisionFlow || showReplyInThreadButton) && <ReplyButton event={event} />}
        {isQueuedEmail && <CancelEmailButton event={event} />}
      </EventContent>
    </EventContainer>
  );
};

interface ActorProps {
  actor: ActorField;
  isStageChangeEvent: boolean;
}

const ActorAction = ({ actor, isStageChangeEvent }: ActorProps): ReactElement => {
  let actorName;
  switch (actor.actor) {
    case ActorFieldActorEnum.Dover:
      actorName = "Dover";
      break;
    case ActorFieldActorEnum.Candidate:
      actorName = actor.actorData?.preferred;
      break;
    case ActorFieldActorEnum.User:
      actorName = `${actor.actorData?.first}` + (actor.actorData?.last ? ` ${actor.actorData?.last}` : "");
      break;
    default:
  }

  return (
    <span>
      <StyledBold>{actorName}</StyledBold>
      &nbsp;
      <StyledSpan isStageChangeEvent={isStageChangeEvent}>{actor.action}</StyledSpan>
    </span>
  );
};

export const formatEventTimestamp = (date: Date): string => {
  if (isThisYear(date)) {
    return format(date, "E, LLL d, p z");
  }
  return format(date, "E, LLL d, yyyy, p z");
};

interface StageChangeProps {
  isStageChangeEvent?: boolean;
  $clickable?: boolean;
  $isEmail?: boolean;
  $activeBadge?: boolean;
}

const EventContainer = styled.div<StageChangeProps>`
  border: 1px solid ${colors.grayscale.gray200};
  border-radius: 6px;
  display: flex;
  margin-bottom: 16px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: ${({ isStageChangeEvent, $activeBadge }): string =>
    $activeBadge ? "34px 16px 16px" : isStageChangeEvent ? "10px 16px" : "16px"};
  &:hover {
    cursor: ${({ $clickable }): string => ($clickable ? "pointer" : "initial")};
    background-color: ${({ $isEmail }): string => ($isEmail ? theme.backgrounds.darkBlue : "initial")};
  }
`;

const EventContent = styled.div`
  flex: 1 0 auto;
  width: calc(100% - 40px);
`;

const EventTitle = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
`;

const EventIcon = styled(ReactSVG)`
  height: 20px;
  width: 20px;
`;

const Timestamp = styled.div`
  font-size: 14px;
  color: ${colors.grayscale.gray400};
  flex-shrink: 0;
  padding-left: 16px;
  padding-top: 2px;
`;

const StyledBold = styled.b<StageChangeProps>`
  font-size: ${({ isStageChangeEvent }): string => (isStageChangeEvent ? "12px" : "14px")};
`;
const StyledSpan = styled.span<StageChangeProps>`
  font-size: ${({ isStageChangeEvent }): string => (isStageChangeEvent ? "12px" : "14px")};
`;
