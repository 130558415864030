import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { CAMPAIGN, FAKE_ID, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import {
  AdminCampaign,
  ApiApiListCampaignsRequest,
  BulkCampaignUpdate,
  BulkCampaignUpdateResult,
  Campaign,
  CampaignCampaignStateEnum,
  CreateCampaign,
  CreateCampaignEmailSenderOptionEnum,
  GenerateAndSaveCampaignContentResult,
  ListCampaign,
} from "services/openapi";
import { CampaignStats } from "services/openapi/models/CampaignStats";
import { showErrorToast, showSuccessToast } from "utils/showToast";

const listCampaignAdapter = createEntityAdapter<ListCampaign>();

const campaignEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getCampaign: build.query<Campaign, string>({
      queryFn: async campaignId => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.getCampaign({ id: campaignId });
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: CAMPAIGN, id: result.id } as const] : [];
      },
    }),
    listCampaigns: build.query<EntityState<ListCampaign>, { jobId: string }>({
      queryFn: async ({ jobId }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.listUserFacingCampaigns({
            job: jobId,
            ordering: "state,created",
            showDeleted: "false",
          });
          return { data: listCampaignAdapter.addMany(listCampaignAdapter.getInitialState(), response.results) };
        } catch (error) {
          const userFacingMessage = "Failed to load campaigns. Please refresh and try again.";

          showErrorToast(userFacingMessage);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        // is result available?
        return result
          ? // successful query
            [...result.ids.map(id => ({ type: CAMPAIGN, id } as const)), { type: CAMPAIGN, id: LIST_TAG }]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: CAMPAIGN, id: LIST_TAG }];
      },
    }),
    adminListCampaigns: build.query<AdminCampaign[], ApiApiListCampaignsRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.listCampaigns(args);
          return { data: response.results };
        } catch (error) {
          const userFacingMessage = "Failed to load campaigns. Please refresh and try again.";

          showErrorToast(userFacingMessage);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    createCampaign: build.mutation<
      CreateCampaign,
      {
        jobId: string;
        name: string;
        campaignToClone: string | undefined;
        emailSenderOption: CreateCampaignEmailSenderOptionEnum | undefined;
        userDefinedSenderUser: number | undefined;
      }
    >({
      queryFn: async ({ jobId, name, campaignToClone, emailSenderOption, userDefinedSenderUser }) => {
        const { apiApi: client } = await getOpenApiClients({});

        if (!emailSenderOption) {
          emailSenderOption = CreateCampaignEmailSenderOptionEnum.HiringManager;
        }
        try {
          const response = await client.createCampaign({
            data: { jobId, name, campaignToClone, emailSenderOption, userDefinedSenderUser },
          });
          return { data: response };
        } catch (error) {
          const userFacingMessage = "Failed to create campaign. Please refresh and try again.";

          showErrorToast(userFacingMessage);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: createdCampaign => {
        const createdCampaignId = createdCampaign?.id;
        if (!createdCampaignId) {
          return [];
        }

        return [
          // Invalidate fake entity now that it exists
          { type: CAMPAIGN, id: FAKE_ID } as const,
          // Invalidate our list so that it includes our newly created ProUser
          { type: CAMPAIGN, id: LIST_TAG } as const,
          // Nothing should depend on the created pro user yet, but just in case, invalidate based on its ID too
          ...(createdCampaignId ? [{ type: CAMPAIGN, id: createdCampaignId } as const] : []),
        ];
      },
    }),
    partialUpdateCampaign: build.mutation<
      Campaign,
      {
        id: string;
        jobId: string;
        updatedCampaign: Campaign;
        showToast?: boolean;
        emailOrNameUpdated?: boolean;
      }
    >({
      queryFn: async ({ id, updatedCampaign, showToast = true }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result: Campaign;
        try {
          result = await client.partialUpdateCampaign({
            id: id,
            data: updatedCampaign,
          });
          if (showToast) {
            showSuccessToast("Campaign saved");
          }
        } catch (error) {
          if (error.message) {
            showErrorToast(error.message);
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: (result, error, args) => {
        if (args.emailOrNameUpdated) {
          return result ? [{ type: CAMPAIGN, id: result.id }] : [];
        } else {
          return result ? [{ type: CAMPAIGN, id: result.id }] : [];
        }
      },
    }),
    deleteCampaign: build.mutation<Campaign, { id: string; name: string }>({
      queryFn: async ({ id, name }) => {
        const { apiApi: client } = await getOpenApiClients({});

        /* Setting a unique name upon soft deletion prevents name collisions 
        with potential new Campaigns */
        const newName = `${name} - Deleted ${new Date()}`;
        const updatedCampaign = {
          name: newName,
          campaignState: CampaignCampaignStateEnum.Deleted,
        };

        let result: Campaign;
        try {
          result = await client.partialUpdateCampaign({
            id: id,
            data: updatedCampaign,
          });
        } catch (error) {
          const userFacingMessage = "Failed to delete campaign. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: result => {
        return result ? [{ type: CAMPAIGN, id: result.id }] : [];
      },
    }),
    getCampaignStats: build.query<CampaignStats, { campaignId: string }>({
      queryFn: async ({ campaignId }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.getCampaignStats({ id: campaignId });
          return {
            data: response,
          };
        } catch (error) {
          const userFacingMessage = "Failed to load campaigns. Please refresh and try again.";
          showErrorToast(userFacingMessage);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    generateAndSaveCampaignContent: build.mutation<
      GenerateAndSaveCampaignContentResult,
      { campaignId: string; includePersonalizedContent?: boolean }
    >({
      queryFn: async ({ campaignId, includePersonalizedContent = true }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const data: GenerateAndSaveCampaignContentResult = await client.generateAndSaveCampaignContent({
          id: campaignId,
          data: { includePersonalizedContent },
        });
        return { data };
      },
      invalidatesTags: (result, error, { campaignId }) => {
        return [{ type: CAMPAIGN, id: campaignId }];
      },
    }),
    bulkUpdateCampaign: build.mutation<BulkCampaignUpdateResult, BulkCampaignUpdate>({
      queryFn: async ({ filters, newValues }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.bulkUpdateCampaigns({ data: { filters, newValues } });
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [{ type: CAMPAIGN }],
    }),
  }),
});

export const {
  useGetCampaignQuery,
  useListCampaignsQuery,
  useCreateCampaignMutation,
  useGetCampaignStatsQuery,
  useGenerateAndSaveCampaignContentMutation,
  usePartialUpdateCampaignMutation,
  useDeleteCampaignMutation,
  useBulkUpdateCampaignMutation,
  useAdminListCampaignsQuery,
} = campaignEndpoints;
