import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";

import DataGridLoadingOverlay from "components/DataGridLoadingOverlay";
import { useGetDoverCareersPageSource } from "services/doverapi/cross-endpoint-hooks/useGetDoverOutboundCandidateSource";
import { CareersPageJob } from "services/openapi";
import { TableWrapper } from "views/CompanySetup/components/Notifications/styles";
import { JobPostingCell, PublishJobCell, ActionsCell } from "views/EditCareersPage/components/PublishJobsTableCells";
import { PUBLISH_JOBS_PAGE_LIMIT } from "views/EditCareersPage/constants";

const columns: GridColDef[] = [
  {
    field: "job",
    headerName: "Job Posting",
    flex: 0.5,
    minWidth: 150,
    sortable: false,
    cellClassName: "t-cell",
    headerClassName: "h-cell",
    renderCell: (params: GridRenderCellParams): React.ReactElement => <JobPostingCell {...params} />,
  },
  {
    field: "publish",
    headerName: "Publish",
    flex: 0.5,
    minWidth: 150,
    sortable: false,
    cellClassName: "t-cell",
    headerClassName: "h-cell",
    renderCell: (params: GridRenderCellParams): React.ReactElement => <PublishJobCell {...params} />,
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 0.5,
    minWidth: 150,
    sortable: false,
    cellClassName: "t-cell",
    headerClassName: "h-cell",
    renderCell: (params: GridRenderCellParams): React.ReactElement => <ActionsCell {...params} />,
  },
];

interface PublishJobsTableProps {
  jobs?: CareersPageJob[];
  jobCount: number;
  jobsLoading: boolean;
  clientId?: string;
  clientName?: string;
  page: number;
  handlePageChange: (page: number) => void;
}

export const PublishJobsTable = ({
  jobs,
  jobCount,
  jobsLoading,
  clientId,
  clientName,
  page,
  handlePageChange,
}: PublishJobsTableProps): React.ReactElement => {
  // Dover careers page source
  const { data: doverCareersPageSource, isLoading: sourceLoading } = useGetDoverCareersPageSource();

  // Enrich jobs w client and source info
  const enrichedJobRows =
    jobs && !sourceLoading
      ? jobs.map((job: CareersPageJob) => ({
          id: job.id,
          title: job.title,
          locations: job.locations,
          isPublished: job.isPublished,
          clientName,
          clientId,
          isSample: job.isSample,
          sourceId: doverCareersPageSource?.id,
        }))
      : [];

  return (
    <TableWrapper>
      <DataGrid
        loading={jobsLoading || sourceLoading}
        autoHeight
        headerHeight={40}
        rowHeight={96}
        rows={enrichedJobRows}
        rowCount={jobCount}
        columns={columns}
        pagination
        paginationMode="server"
        page={page}
        pageSize={PUBLISH_JOBS_PAGE_LIMIT}
        onPageChange={handlePageChange}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        components={{
          LoadingOverlay: DataGridLoadingOverlay,
        }}
      />
    </TableWrapper>
  );
};
