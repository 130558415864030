/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateOutreachNoProfileRequest
 */
export interface GenerateOutreachNoProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    userWorkEmail: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    userFullName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    userJobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    userCompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    userCompanyInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    hiringJobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    emailTone: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    candidateLinkedinUrl: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    candidateFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    candidateCurrentRole?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    candidateCurrentCompany?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    candidateSchool?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    candidateLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachNoProfileRequest
     */
    additionalInstructions?: string;
}

export function GenerateOutreachNoProfileRequestFromJSON(json: any): GenerateOutreachNoProfileRequest {
    return GenerateOutreachNoProfileRequestFromJSONTyped(json, false);
}

export function GenerateOutreachNoProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateOutreachNoProfileRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userWorkEmail': json['user_work_email'],
        'userFullName': json['user_full_name'],
        'userJobTitle': json['user_job_title'],
        'userCompanyName': json['user_company_name'],
        'userCompanyInfo': !exists(json, 'user_company_info') ? undefined : json['user_company_info'],
        'hiringJobTitle': json['hiring_job_title'],
        'emailTone': json['email_tone'],
        'candidateLinkedinUrl': json['candidate_linkedin_url'],
        'candidateFullName': !exists(json, 'candidate_full_name') ? undefined : json['candidate_full_name'],
        'candidateCurrentRole': !exists(json, 'candidate_current_role') ? undefined : json['candidate_current_role'],
        'candidateCurrentCompany': !exists(json, 'candidate_current_company') ? undefined : json['candidate_current_company'],
        'candidateSchool': !exists(json, 'candidate_school') ? undefined : json['candidate_school'],
        'candidateLocation': !exists(json, 'candidate_location') ? undefined : json['candidate_location'],
        'additionalInstructions': !exists(json, 'additional_instructions') ? undefined : json['additional_instructions'],
    };
}

export function GenerateOutreachNoProfileRequestToJSON(value?: GenerateOutreachNoProfileRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_work_email': value.userWorkEmail,
        'user_full_name': value.userFullName,
        'user_job_title': value.userJobTitle,
        'user_company_name': value.userCompanyName,
        'user_company_info': value.userCompanyInfo,
        'hiring_job_title': value.hiringJobTitle,
        'email_tone': value.emailTone,
        'candidate_linkedin_url': value.candidateLinkedinUrl,
        'candidate_full_name': value.candidateFullName,
        'candidate_current_role': value.candidateCurrentRole,
        'candidate_current_company': value.candidateCurrentCompany,
        'candidate_school': value.candidateSchool,
        'candidate_location': value.candidateLocation,
        'additional_instructions': value.additionalInstructions,
    };
}


