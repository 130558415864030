/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanySetupStep
 */
export interface CompanySetupStep {
    /**
     * 
     * @type {string}
     * @memberof CompanySetupStep
     */
    stepName: CompanySetupStepStepNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanySetupStep
     */
    state: CompanySetupStepStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySetupStep
     */
    isRelevantToJobs: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CompanySetupStepStepNameEnum {
    BasicInfoState = 'setup_step_basic_info_state',
    PitchState = 'setup_step_pitch_state',
    CareersPageState = 'setup_step_careers_page_state',
    ExclusionsState = 'setup_step_exclusions_state',
    AtsState = 'setup_step_ats_state',
    BrandingState = 'setup_step_branding_state',
    CredentialsState = 'setup_step_credentials_state'
}/**
* @export
* @enum {string}
*/
export enum CompanySetupStepStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function CompanySetupStepFromJSON(json: any): CompanySetupStep {
    return CompanySetupStepFromJSONTyped(json, false);
}

export function CompanySetupStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySetupStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stepName': json['step_name'],
        'state': json['state'],
        'isRelevantToJobs': json['is_relevant_to_jobs'],
    };
}

export function CompanySetupStepToJSON(value?: CompanySetupStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step_name': value.stepName,
        'state': value.state,
        'is_relevant_to_jobs': value.isRelevantToJobs,
    };
}


