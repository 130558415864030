import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";

import { DEFAULT_GMAIL_AUTH_POLLING_INTERVAL } from "components/dover/GmailAuth/constants";
import { useGetUserGmailAuthValidQuery } from "services/doverapi/endpoints/proUser";
import { GmailAuth } from "services/openapi";

export const useGetGmailAuthStatus = ({
  skip = false,
  userId,
}: {
  skip?: boolean;
  userId?: string;
}): { gmailAuthStatus: GmailAuth | undefined; isGmailAuthLoading: boolean } => {
  const [hasError, setHasError] = useState(false);
  const [gmailAuthValid, setGmailAuthValid] = useState(false);
  const [nylasAuthValid, setNylasAuthValid] = useState(false);

  // We want to continuously refetch this api, because the user has to leave the app to auth their gmail.
  // We are polling this api, because we want to update the UI once the user auths their gmail successfully.
  const { data: gmailAuthStatus, isLoading, isError } = useGetUserGmailAuthValidQuery(
    hasError || skip ? skipToken : { userId },
    {
      pollingInterval: gmailAuthValid && nylasAuthValid ? 0 : DEFAULT_GMAIL_AUTH_POLLING_INTERVAL,
    }
  );

  useEffect(() => {
    setHasError(currentValue => {
      if (!currentValue && isError) {
        return true;
      }
      return currentValue;
    });
  }, [isError]);

  useEffect(() => {
    // If gmailAuthStatus.valid is defined and not equal to the state, set the state
    if (gmailAuthStatus?.valid !== undefined) {
      setGmailAuthValid(gmailAuthStatus.valid);
    }

    if (gmailAuthStatus?.nylasAuthValid !== undefined) {
      setNylasAuthValid(gmailAuthStatus.nylasAuthValid);
    }
  }, [gmailAuthStatus?.valid, gmailAuthStatus?.nylasAuthValid]);

  return { gmailAuthStatus, isGmailAuthLoading: isLoading };
};

export const useUserAuthedSuccessfully = (
  skip?: boolean
): {
  userAuthedSuccessfully: boolean | undefined;
  isGmailAuthLoading: boolean;
} => {
  const { gmailAuthStatus, isGmailAuthLoading } = useGetGmailAuthStatus({ skip });
  // If API hasn't resolved, return undefined
  if (isGmailAuthLoading || gmailAuthStatus === undefined) {
    return { userAuthedSuccessfully: undefined, isGmailAuthLoading };
  }

  return { userAuthedSuccessfully: !!gmailAuthStatus?.nylasAuthValid && gmailAuthStatus?.valid, isGmailAuthLoading };
};
