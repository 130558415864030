import { MenuItem, Select } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { getInterviewTypeOptions } from "components/SetupHiringPipeline/helpers";
import { colors } from "styles/theme";

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    width: 335px;
    height: 44px;
    box-sizing: border-box;
    padding: 11px;

    input {
      height: inherit;
      box-sizing: border-box;
    }
  }
`;

export const EditDropdownMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    display: block;
    padding: 8px;
  }
`;

const InterviewTypeField = ({ ...props }: any): React.ReactElement => {
  return (
    <StyledSelect
      variant="outlined"
      style={{ background: colors.white }}
      value={props.input.value}
      onChange={props.input.onChange}
      error={props.required && !props.input.value}
      disabled={props.disabled}
    >
      {getInterviewTypeOptions().map((option, index) => {
        return (
          <EditDropdownMenuItem key={index} value={option.value}>
            {option.label}
          </EditDropdownMenuItem>
        );
      })}
    </StyledSelect>
  );
};

export default InterviewTypeField;
