import { Button } from "@doverhq/dover-ui";
import { Box, Stack, styled } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useAtom } from "jotai";
import React from "react";

import { ReactComponent as CopySVG } from "assets/icons/copy.svg";
import { ReactComponent as TrashSVG } from "assets/icons/trash-gray.svg";
import { INVALID_PERMS_TOOLTIP_TEXT } from "components/constants";
import {
  EmailTemplateDrawerAction,
  EmailTemplateDrawerConfigAtom,
  ToggleDeleteTemplateConfirmationModalAtom,
} from "components/dover/EmailTemplates/atoms";
import { EmailTemplateDrawerAtom } from "components/dover/EmailTemplates/EmailTemplateDrawer";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import { Role, useContainsRole } from "components/RBAC";
import { useModal } from "GlobalOverlays/atoms";
import { ClientEmailTemplate } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";

const StyledPersonaCoin = styled(PersonaCoin)`
  line-height: 0px;
`;

export const NameCell = (params: GridRenderCellParams): React.ReactElement => {
  return (
    <Box sx={{ textWrap: "wrap" }}>
      <BodySmall>{params.row.name}</BodySmall>
    </Box>
  );
};

export const CreatedByCell = (params: GridRenderCellParams): React.ReactElement => {
  const templateAuthor = params.row.createdBy;
  const fullName = `${templateAuthor.firstName} ${templateAuthor.lastName}`;
  return (
    <Box style={{ lineHeight: "normal" }}>
      <StyledPersonaCoin pictureUrl={templateAuthor.pictureUrl} tooltip={fullName} />
    </Box>
  );
};

export const AssignedJobsCell = (params: GridRenderCellParams): React.ReactElement => {
  const assignedJobCount = params.row.jobs.length;
  const assignedJobTitles = params.row.jobs.map((job: any) => job.title);
  // can truncate this later if needed
  const jobTitlesTooltip = assignedJobTitles.join(", ");

  return (
    <Box sx={{ cursor: "default" }}>
      <Tooltip variant={TooltipVariant.Dark} title={jobTitlesTooltip}>
        <BodySmall weight="400">{assignedJobCount}</BodySmall>
      </Tooltip>
    </Box>
  );
};

export const ActionsCell = (params: GridRenderCellParams): React.ReactElement => {
  // permissions (also enforced by API)
  const userHasPermissions = useContainsRole([Role.ADMIN, Role.CLIENT_ADMIN]);
  const { user: currentUser } = useAuth0();
  const templateAuthor = params.row.createdBy;

  const userCanUpdateTemplate = userHasPermissions || currentUser.email === templateAuthor.email;

  // atoms and state
  const { open: openEmailTemplateDrawer } = useModal(EmailTemplateDrawerAtom);
  const [, setEmailTemplateDrawerConfig] = useAtom(EmailTemplateDrawerConfigAtom);
  const [, toggleDeleteConfirmationModal] = useAtom(ToggleDeleteTemplateConfirmationModalAtom);

  // callbacks
  const onDuplicate = async (): Promise<void> => {
    const templateCopy = { ...params.row };
    templateCopy.name = `${templateCopy.name} (copy)`;
    setEmailTemplateDrawerConfig({
      template: templateCopy as ClientEmailTemplate,
      action: EmailTemplateDrawerAction.Create,
    });
    openEmailTemplateDrawer({});
  };

  const onClickDelete = async (): Promise<void> => {
    // set template to be deleted and pop open confirmation modal
    setEmailTemplateDrawerConfig({
      template: params.row as ClientEmailTemplate,
      action: EmailTemplateDrawerAction.Delete,
    });
    toggleDeleteConfirmationModal(true);
  };

  return (
    <Stack direction="row" spacing={2}>
      {/* duplicate template */}
      <Tooltip title="Duplicate template">
        <div>
          <Button onPress={onDuplicate} icon={{ Icon: CopySVG, color: "transparent" }} />
        </div>
      </Tooltip>
      {/* delete template (opens confirmation modal) */}
      <Tooltip title={!userCanUpdateTemplate ? INVALID_PERMS_TOOLTIP_TEXT : "Delete template"}>
        <div>
          <Button
            onPress={onClickDelete}
            isDisabled={!userCanUpdateTemplate}
            icon={{ Icon: TrashSVG, color: "transparent" }}
          />
        </div>
      </Tooltip>
    </Stack>
  );
};
