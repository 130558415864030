/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIConversation,
    AIConversationFromJSON,
    AIConversationFromJSONTyped,
    AIConversationToJSON,
    SearchV3Params,
    SearchV3ParamsFromJSON,
    SearchV3ParamsFromJSONTyped,
    SearchV3ParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListAppsViaAIRequest
 */
export interface ListAppsViaAIRequest {
    /**
     * 
     * @type {Array<AIConversation>}
     * @memberof ListAppsViaAIRequest
     */
    conversation: Array<AIConversation>;
    /**
     * 
     * @type {SearchV3Params}
     * @memberof ListAppsViaAIRequest
     */
    paramsToBuildOn?: SearchV3Params;
    /**
     * 
     * @type {string}
     * @memberof ListAppsViaAIRequest
     */
    searchId: string;
    /**
     * 
     * @type {number}
     * @memberof ListAppsViaAIRequest
     */
    limit?: number;
}

export function ListAppsViaAIRequestFromJSON(json: any): ListAppsViaAIRequest {
    return ListAppsViaAIRequestFromJSONTyped(json, false);
}

export function ListAppsViaAIRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAppsViaAIRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conversation': ((json['conversation'] as Array<any>).map(AIConversationFromJSON)),
        'paramsToBuildOn': !exists(json, 'params_to_build_on') ? undefined : SearchV3ParamsFromJSON(json['params_to_build_on']),
        'searchId': json['search_id'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function ListAppsViaAIRequestToJSON(value?: ListAppsViaAIRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conversation': ((value.conversation as Array<any>).map(AIConversationToJSON)),
        'params_to_build_on': SearchV3ParamsToJSON(value.paramsToBuildOn),
        'search_id': value.searchId,
        'limit': value.limit,
    };
}


