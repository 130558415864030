import { MenuItem, Select } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { getInterviewMeetingTypeOptions } from "components/SetupHiringPipeline/helpers";
import { Spacer } from "components/Spacer";

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    width: 335px;
    height: 44px;
    box-sizing: border-box;
    padding: 11px;

    input {
      height: inherit;
      box-sizing: border-box;
    }
  }
`;

const InterviewMeetingTypeField = ({ ...props }: any): React.ReactElement => {
  return (
    <div>
      <Spacer height={4} />
      <StyledSelect
        variant="outlined"
        style={{ background: "white" }}
        value={props.input.value}
        onChange={props.input.onChange}
        error={props.required && !props.input.value}
      >
        {getInterviewMeetingTypeOptions().map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </StyledSelect>
    </div>
  );
};

export default InterviewMeetingTypeField;
