/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateCareersPageRequest
 */
export interface GenerateCareersPageRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateCareersPageRequest
     */
    companyUrl: string;
}

export function GenerateCareersPageRequestFromJSON(json: any): GenerateCareersPageRequest {
    return GenerateCareersPageRequestFromJSONTyped(json, false);
}

export function GenerateCareersPageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateCareersPageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyUrl': json['company_url'],
    };
}

export function GenerateCareersPageRequestToJSON(value?: GenerateCareersPageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_url': value.companyUrl,
    };
}


