/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import { getOpenApiClients, OpenApiClients } from "services/api";

export const useOpenApiClients = (includeToken: boolean = true): OpenApiClients | undefined => {
  const [clients, setClients] = useState<OpenApiClients | undefined>();

  useEffect(() => {
    (async (): Promise<void> => {
      const clients = await getOpenApiClients({ includeToken });
      setClients(clients);
    })();
  }, []);

  return clients;
};
