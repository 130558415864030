/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateSchedulingLinkForCandidateRequest
 */
export interface ValidateSchedulingLinkForCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateSchedulingLinkForCandidateRequest
     */
    candidateId: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateSchedulingLinkForCandidateRequest
     */
    link: string;
}

export function ValidateSchedulingLinkForCandidateRequestFromJSON(json: any): ValidateSchedulingLinkForCandidateRequest {
    return ValidateSchedulingLinkForCandidateRequestFromJSONTyped(json, false);
}

export function ValidateSchedulingLinkForCandidateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateSchedulingLinkForCandidateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
        'link': json['link'],
    };
}

export function ValidateSchedulingLinkForCandidateRequestToJSON(value?: ValidateSchedulingLinkForCandidateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'link': value.link,
    };
}


