/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ValidateSchedulingLinkForCandidateResponse
 */
export interface ValidateSchedulingLinkForCandidateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateSchedulingLinkForCandidateResponse
     */
    valid: boolean;
}

export function ValidateSchedulingLinkForCandidateResponseFromJSON(json: any): ValidateSchedulingLinkForCandidateResponse {
    return ValidateSchedulingLinkForCandidateResponseFromJSONTyped(json, false);
}

export function ValidateSchedulingLinkForCandidateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidateSchedulingLinkForCandidateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': json['valid'],
    };
}

export function ValidateSchedulingLinkForCandidateResponseToJSON(value?: ValidateSchedulingLinkForCandidateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': value.valid,
    };
}


