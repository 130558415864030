export enum InterviewSchedulingScenario {
  SYNC_SUCCESSFUL_HAS_STAGES = "SYNC_SUCCESSFUL_HAS_STAGES",
  SYNC_SUCCESSFUL_NO_STAGES = "SYNC_SUCCESSFUL_NO_STAGES",
  SYNC_PENDING_DOVER_ACTION = "SYNC_PENDING_DOVER_ACTION",
  FAILED_TO_SYNC_HAS_CACHE = "FAILED_TO_SYNC_HAS_CACHE",
  FAILED_TO_SYNC_NO_CACHE = "FAILED_TO_SYNC_NO_CACHE",
  SYNC_UNNECESSARY_HAS_STAGES = "SYNC_UNNECESSARY_HAS_STAGES",
  HIDE_ATS_STAGES_COLUMN = "HIDE_ATS_STAGES_COLUMN",
  HAS_NOT_COMPLETED_JOB_ATS = "HAS_NOT_COMPLETED_JOB_ATS",
  HAS_NOT_COMPLETED_COMPANY_ATS = "HAS_NOT_COMPLETED_COMPANY_ATS",
  LOADING = "LOADING",
}

export interface HiringPipelineState {
  jobId: string | undefined;
  includesTakeHomeStage: boolean;
  atsSettingsDismissed: boolean;
}
