import { Box } from "@mui/material";
import React, { ReactElement, useState, useCallback } from "react";

import { BlockNavWhen } from "components/library/BlockNav/types";
import { useBlockNav } from "components/library/BlockNav/useBlockNav";
import { Button, ButtonVariant } from "components/library/Button";
import CustomModal from "components/Modal";
import { Spacer } from "components/Spacer";
import { B2_doNotUse } from "styles/typography";

interface BlockNavProps {
  when: BlockNavWhen;
  hook?: () => void;
  reset?: () => void;
  blockRefresh?: boolean;
  forceBlockRefresh?: boolean;
  title?: string;
  content?: string | ReactElement;
  primaryButtonText?: string;
  secondaryButtonText?: string;
}

/**
 * Pops up a modal and makes user confirm browser and react router navigation away from a page when rendered and certain conditions are met
 *
 * @author Max McKinley <max.mckinley@dover.com>
 *
 * @param when - Will block navigation if true or is a function that returns true, otherwise navigation will be unblocked
 * @param hook - A function that will be called when the user confirms navigation away from the page
 * @param reset - A function that will be called when the user cancels navigation
 * @param blockRefresh - Block page refreshing as well as navigation, has to use browser native UI
 * @param forceBlockRefresh - Block page refreshing no matter what
 * @param title - Text at the top of the confirmation modal
 * @param content - Text in the body of the confirmation modal
 * @param primaryButtonText - Text in the button that confirms navigation
 * @param secondaryButtonText - Text in the button that cancels navigation
 */
export const BlockNav = React.memo(
  ({
    when,
    hook,
    reset,
    blockRefresh,
    forceBlockRefresh,
    title = "You may have unpublished changes",
    content = "It looks like you may have made some changes to this page, if you leave now your changes will be lost.",
    primaryButtonText = "Discard Changes",
    secondaryButtonText = "Cancel",
  }: BlockNavProps): React.ReactElement => {
    const [open, setOpen] = useState<boolean>(false);
    const [callback, setCallback] = useState<(() => void) | undefined>(undefined);

    const show = useCallback((cb: () => void) => {
      setOpen(true);
      setCallback(() => cb);
    }, []);

    useBlockNav(when, show, blockRefresh, forceBlockRefresh);

    const onClick = useCallback((): void => {
      setOpen(false);

      if (hook) {
        hook();
      }

      if (callback) {
        callback();
      }
    }, [callback, hook]);

    const onCancel = React.useCallback(() => {
      reset?.();
      setOpen(false);
    }, [reset]);

    const body = typeof content === "string" ? <B2_doNotUse>{content}</B2_doNotUse> : content;

    return (
      <CustomModal
        maxWidth="sm"
        title={title}
        open={open}
        onClose={onCancel}
        dialogActions={
          <Box display="flex">
            <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
              {secondaryButtonText}
            </Button>
            <Spacer width="8px" />
            <Button variant={ButtonVariant.Critical} onClick={onClick}>
              {primaryButtonText}
            </Button>
          </Box>
        }
      >
        {body}
      </CustomModal>
    );
  }
);
