import { Stack } from "@mui/material";
import React, { ReactElement } from "react";

import { Body } from "components/library/typography";

export const EmptyEventTab = (): ReactElement => {
  return (
    <Stack maxWidth="580px" margin="auto" spacing={1}>
      <Body weight="600" centered>
        There is no activity for this candidate
      </Body>
    </Stack>
  );
};
