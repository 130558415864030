import { format, nextMonday } from "date-fns";
import React from "react";
import { useParams } from "react-router-dom";

import ActivateFeatureConfirmationModal from "components/dover/feature-toggle-handler/ActivateFeatureConfirmationModal";
import { ServiceFeatureModalProps } from "components/dover/job-feature-card/types";
import { Body } from "components/library/typography";
import { useUpsertJobFeatureSettingMutation } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import {
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

const ActivateTargetedSourcingModal = ({
  isModalOpen,
  additionalInfoArgumentsForModalText,
  toggleModalOff,
  additionalCallback,
}: ServiceFeatureModalProps): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();
  const [forceLoading, setForceLoading] = React.useState<boolean>(false);

  const [upsertJobFeatureSetting] = useUpsertJobFeatureSettingMutation();

  const nextMondayDate = format(nextMonday(new Date()), "MMM do");

  const modalText = React.useMemo(() => {
    if (additionalInfoArgumentsForModalText === undefined || additionalInfoArgumentsForModalText.length === 0) {
      return "Error updating options. Please refresh and try again";
    }

    return (
      <Body>
        You have chosen to send <b>{additionalInfoArgumentsForModalText[1]}</b> emails per week and it will start going
        into effect from Monday, {nextMondayDate}.
      </Body>
    );
  }, [additionalInfoArgumentsForModalText, nextMondayDate]);

  const onConfirmEnable = React.useCallback(
    (onContinue?: () => void) => {
      setForceLoading(true);
      additionalCallback?.();

      const tryUpsertFeatureSetting = async (): Promise<void> => {
        try {
          await upsertJobFeatureSetting({
            upsertData: {
              data: {
                job: jobId!,
                featureName: UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
                state: UpsertJobFeatureSettingStateEnum.Enabled,
              },
            },
          }).unwrap();

          // Ensure we have loaded the step we want to navigate to
          setTimeout(() => {
            toggleModalOff();
            onContinue?.();
          }, 1000);
          return;
        } catch (err) {
          console.error(err);
          showErrorToast("Failed to update job feature. Please refresh and try again.");
        }

        toggleModalOff();
      };

      tryUpsertFeatureSetting();
    },
    [additionalCallback, jobId, toggleModalOff, upsertJobFeatureSetting]
  );

  return (
    <ActivateFeatureConfirmationModal
      isModalOpen={isModalOpen}
      featureName={JobFeatureFeatureNameEnum.ManagedOutbound}
      title="Sourcing configuration updated"
      body={modalText}
      toggleModalOff={toggleModalOff}
      onConfirmEnable={onConfirmEnable}
      forceLoading={forceLoading}
    />
  );
};

export default ActivateTargetedSourcingModal;
