/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Checkbox, Stack, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import compact from "lodash/compact";
import React, { useEffect, useState } from "react";
import { useForm, useFormState, useField } from "react-final-form";

import InterviewerCreateModal, { CreateInterviewerFuncArgs } from "components/NextInterviewer/InterviewerCreateModal";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useCreateInterviewerMutation, useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";

interface Props {
  name: string;
  small?: boolean;
  initialInterviewers: string[];
}

export const InterviewersField = (props: Props): React.ReactElement => {
  const { name, small, initialInterviewers } = props;
  const form = useForm();
  const field = useField(name);
  const formState = useFormState();

  const [jobId] = useJobId();
  const { data: job } = useGetJobSetupQuery(jobId ?? skipToken);
  const interviewers = useListInterviewerEntities();

  const interviewerIds = interviewers.length ? interviewers?.map(i => i.id!) : [];

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const interviewersIds = compact(form.getFieldState(name)?.value);
    const hasCustom = interviewersIds.find((i: any) => i.createInterviewer);

    if (hasCustom) {
      const newValue = interviewersIds.filter((i: any) => !i.createInterviewer);
      form.change(name, newValue);

      setShowModal(true);
    }
  }, [form, formState]);

  const [createInterviewer] = useCreateInterviewerMutation();

  const createInterviewerFn = async (values: CreateInterviewerFuncArgs): Promise<void> => {
    const { firstName, lastName, email, phoneNumber } = values;

    const interviewersInvolved = form.getFieldState(name)?.value;

    const result = await createInterviewer({ firstName, lastName, email, phoneNumber, client: job?.client! }).unwrap();
    const newValue = [...interviewersInvolved, result.id];
    form.change(name, newValue);

    setShowModal(false);
  };

  return (
    <>
      <Autocomplete
        value={initialInterviewers}
        multiple
        disableCloseOnSelect
        options={interviewerIds}
        isOptionEqualToValue={(option: any, value: any): boolean => {
          return option === value;
        }}
        getOptionLabel={(option: any): string => interviewers?.find(i => i.id === option)?.email ?? ""}
        onChange={(_, newValue): void => {
          field.input.onChange(newValue);
        }}
        renderOption={(props, option, { selected }): React.ReactElement => {
          return (
            <li {...props} style={{ padding: "0px" }}>
              <Stack key={option} direction="row" alignItems={"center"}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {interviewers?.find(i => i.id === option)?.email ?? (option as any).email}
              </Stack>
            </li>
          );
        }}
        renderInput={(params): React.ReactElement => <TextField {...params} placeholder="Email" />}
        filterOptions={(options: any, params: any): any => {
          const filtered = options.filter((interviewerId: string) => {
            const optionLabel = params.getOptionLabel(interviewerId);
            return optionLabel ? optionLabel.includes(params.inputValue) : false;
          });

          const tempString = `"${params.inputValue}"`;
          filtered.push({
            email: `Add a new interviewer ${params.inputValue ? tempString : "+"}`,
            createInterviewer: true,
          });

          return filtered;
        }}
        size={small ? "small" : "medium"}
      />
      <InterviewerCreateModal
        showModal={showModal}
        onCloseModal={(): void => setShowModal(false)}
        createInterviewer={createInterviewerFn}
        interviewers={interviewers}
        validateEmail={(): boolean => true} // add better checking later
        creating={showModal}
        hiringManagerEmail={job?.hiringManager?.email}
      />
    </>
  );
};
