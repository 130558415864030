/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobAtsStageMap
 */
export interface JobAtsStageMap {
    /**
     * 
     * @type {string}
     * @memberof JobAtsStageMap
     */
    atsStage: string;
    /**
     * 
     * @type {string}
     * @memberof JobAtsStageMap
     */
    atsStageId?: string | null;
}

export function JobAtsStageMapFromJSON(json: any): JobAtsStageMap {
    return JobAtsStageMapFromJSONTyped(json, false);
}

export function JobAtsStageMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobAtsStageMap {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atsStage': json['ats_stage'],
        'atsStageId': !exists(json, 'ats_stage_id') ? undefined : json['ats_stage_id'],
    };
}

export function JobAtsStageMapToJSON(value?: JobAtsStageMap | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ats_stage': value.atsStage,
        'ats_stage_id': value.atsStageId,
    };
}


