import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Box, Stack, Grid } from "@mui/material";
import React from "react";

import { Card } from "components/library/Card";
import { SectionHeader } from "views/interview/common/components/InterviewRubric/FormBody";
import { Section as SectionModel } from "views/interview/common/types";

export default ({ section }: { section: SectionModel }): React.ReactElement => {
  const formOptions = useFormApi();

  return (
    <Box mb={4}>
      <Card>
        <Grid item xs={12}>
          <SectionHeader section={section} />
        </Grid>
        <Stack spacing={1.5} paddingTop="16px">
          {section.fields.map(item => {
            return (
              <Grid item key={item.name} xs={12} marginBottom="5px">
                {formOptions.renderForm([{ ...item, isDisabled: item.isDisabled, validate: [] }])}
              </Grid>
            );
          })}
        </Stack>
      </Card>
    </Box>
  );
};
