/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignMessageRequestEmailSendRequest
 */
export interface CampaignMessageRequestEmailSendRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequestEmailSendRequest
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CampaignMessageRequestEmailSendRequest
     */
    sendAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequestEmailSendRequest
     */
    state?: CampaignMessageRequestEmailSendRequestStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum CampaignMessageRequestEmailSendRequestStateEnum {
    InReview = 'IN_REVIEW',
    Queued = 'QUEUED',
    ManuallyQueued = 'MANUALLY_QUEUED',
    QueuedExternal = 'QUEUED_EXTERNAL',
    Sent = 'SENT',
    Sending = 'SENDING',
    Cancelled = 'CANCELLED',
    Failed = 'FAILED'
}

export function CampaignMessageRequestEmailSendRequestFromJSON(json: any): CampaignMessageRequestEmailSendRequest {
    return CampaignMessageRequestEmailSendRequestFromJSONTyped(json, false);
}

export function CampaignMessageRequestEmailSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignMessageRequestEmailSendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sendAt': !exists(json, 'send_at') ? undefined : (json['send_at'] === null ? null : new Date(json['send_at'])),
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function CampaignMessageRequestEmailSendRequestToJSON(value?: CampaignMessageRequestEmailSendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'send_at': value.sendAt === undefined ? undefined : (value.sendAt === null ? null : value.sendAt.toISOString()),
        'state': value.state,
    };
}


