import { SchedulableStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { doesStageRequireInterviewer } from "components/dover/top-level-modal-manager/hooks/useValidate";
import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { convertBaseSubstageToInterviewPanel } from "components/dover/top-level-modal-manager/utils/convertBaseSubstageToInterviewPanel";
import { isMultipartInterview } from "components/dover/top-level-modal-manager/utils/getModalWidth";
import { isOneOffInterviewStage } from "components/dover/top-level-modal-manager/utils/isOneOffInterviewStage";
import {
  BaseInterviewSubstage,
  CandidateBioSchedulingOwnershipEnum,
  InterviewPanel,
  InterviewPanelSubstage,
  InterviewPanelSubstageInterviewersRequiredEnum,
} from "services/openapi";

interface InterviewPanelArgs {
  interviewer?: Interviewer;
  stage?: SchedulableStage;
  substageId?: string;
  substages?: BaseInterviewSubstage[];
  substageDuration?: number;
  schedulingOwnership?: CandidateBioSchedulingOwnershipEnum;
  isTakeHome: boolean;
}

export const createInterviewPanel = ({
  interviewer,
  stage,
  substageId,
  substages,
  substageDuration,
  schedulingOwnership,
  isTakeHome,
}: InterviewPanelArgs): InterviewPanel | undefined => {
  // Stage needs to be defined for single part and multipart
  if (!isOneOffInterviewStage(stage) && !stage?.id) {
    return undefined;
  }

  const stageRequiresInterviewer = doesStageRequireInterviewer({
    schedulingOwnership,
    desiredHiringPipelineStage: stage,
    isTakeHome,
  });

  // If stage is multipart we can use the substages directly
  // If single part we need to convert to substage schema
  const usingMultipartEditor = isMultipartInterview(stage);

  if (usingMultipartEditor) {
    if (stageRequiresInterviewer && (!substages || substages.length === 0)) {
      return undefined;
    }

    const multipartSubstages: InterviewPanelSubstage[] = convertBaseSubstageToInterviewPanel(substages ?? []);
    return {
      hiringPipelineStage: stage!.id!,
      substages: multipartSubstages,
    };
  } else {
    if (!substageDuration) {
      return undefined;
    }

    if (stageRequiresInterviewer && !interviewer?.value) {
      return undefined;
    }

    const possibleInterviewers = [];
    if (interviewer?.value) {
      possibleInterviewers.push(interviewer.value);
    }

    // Convert our single part interview to substage schema
    // interviewsRequired, orderIndex, and orderRequired are meaningless on
    // a single part interview, but we have to include them to satisfy the serializer typing
    // So we just set to some arbitrary default values
    const singlePartSubstages: InterviewPanelSubstage[] = [
      {
        id: substageId,
        duration: substageDuration,
        possibleInterviewers: possibleInterviewers,
        interviewersRequired: InterviewPanelSubstageInterviewersRequiredEnum.Any,
        orderIndex: 0,
        orderRequired: false,
      },
    ];

    return {
      hiringPipelineStage: stage?.isOneOffInterview ? undefined : stage?.id,
      substages: singlePartSubstages,
    };
  }
};
