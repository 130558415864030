import { atomWithReset, useResetAtom } from "jotai/utils";

import {
  BulkUploadCandidatesToCampaignSchemaType,
  SingleAddCandidateToCampaignSchemaType,
} from "components/dover/AddCandidate/types";

/**
 * Step 1: Candidate form data to be persist the RHF data in the SingleCandidateUploadForm and BulkUploadCandidatesForm components
 */

export const singleAddCandidateToCampaignAtom = atomWithReset<SingleAddCandidateToCampaignSchemaType | undefined>(
  undefined
);

export const bulkUploadCandidatesToCampaignAtom = atomWithReset<BulkUploadCandidatesToCampaignSchemaType | undefined>(
  undefined
);

export const uploadTypeAtom = atomWithReset<"single" | "bulk">("single");

/**
 * Step 2: Campaign Message state to be used in the PreviewAndSendEmailStep component
 */

export const campaignMessageSubjectAtom = atomWithReset<string | undefined>(undefined);
export const campaignMessageBodyAtom = atomWithReset<string | undefined>(undefined);
export const campaignMessageCcEmailsAtom = atomWithReset<string[] | undefined>(undefined);
export const campaignMessageValidAtom = atomWithReset<boolean>(true);

/**
 * Hook to reset all the atoms used in the AddCandidateToCampaignModal on close to prevent
 * stale data from being used when the modal is opened again
 */
export const useResetAddCandidateToCampaignAtomState = (): (() => void) => {
  const resetSingleAddCandidateData = useResetAtom(singleAddCandidateToCampaignAtom);
  const resetBulkAddCandidateData = useResetAtom(bulkUploadCandidatesToCampaignAtom);
  const resetUploadType = useResetAtom(uploadTypeAtom);
  const resetCampaignSubject = useResetAtom(campaignMessageSubjectAtom);
  const resetCampaignBody = useResetAtom(campaignMessageBodyAtom);
  const resetCampaignMessageCcEmails = useResetAtom(campaignMessageCcEmailsAtom);
  const resetCampaignMessageValid = useResetAtom(campaignMessageValidAtom);

  return (): void => {
    resetSingleAddCandidateData();
    resetBulkAddCandidateData();
    resetUploadType();
    resetCampaignSubject();
    resetCampaignBody();
    resetCampaignMessageCcEmails();
    resetCampaignMessageValid();
  };
};
