import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import MagicWandSVG from "assets/icons/magic-wand-color.svg";
import AnimatedLoadingEllipsis from "components/loading-overlay/LoadingEllipsis";
import { Spacer } from "components/Spacer";

const MagicWandLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingMessage = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-style: italic;
  width: 420px;
  max-width: 100%;
  display: flex;
`;

const GeneratingContentMessageWithMagicWand = ({ message }: { message: string }): React.ReactElement => {
  return (
    <MagicWandLoaderWrapper>
      <ReactSVG src={MagicWandSVG} />
      <Spacer height="42px" />
      <LoadingMessage>
        {message}
        <AnimatedLoadingEllipsis />
      </LoadingMessage>
    </MagicWandLoaderWrapper>
  );
};

export default GeneratingContentMessageWithMagicWand;
