import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import React from "react";

import BoardViewSVG from "assets/icons/candidates-board-view.svg";
import TableViewSVG from "assets/icons/candidates-table-view.svg";
import { Tooltip } from "components/library/Tooltip/Tooltip";
import { StyledSVG } from "styles/layout";
import { filters } from "styles/theme";
import { CandidatesViewType } from "views/candidates/types";

interface BoardTableViewToggleProps {
  view: CandidatesViewType;
  handleChangeCandidatesViewType: (event: React.MouseEvent<HTMLElement>, nextView: CandidatesViewType) => void;
}

export const BoardTableViewToggle = ({
  view,
  handleChangeCandidatesViewType,
}: BoardTableViewToggleProps): React.ReactElement => {
  return (
    <ToggleButtonGroup value={view} exclusive onChange={handleChangeCandidatesViewType}>
      <ToggleButton
        sx={{ borderRadius: "4px 0 0 4px" }}
        size="small"
        value={CandidatesViewType.Table}
        aria-label="table-icon"
      >
        <Tooltip title="Table view">
          <div>
            <StyledSVG
              filter={view === CandidatesViewType.Table ? filters.filterDarkGreen : undefined}
              src={TableViewSVG}
            />
          </div>
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        sx={{ borderRadius: "0 4px 4px 0", borderLeft: "none" }}
        size="small"
        value={CandidatesViewType.Board}
        aria-label="board-icon"
      >
        <Tooltip title="Board view">
          <div>
            <StyledSVG
              filter={view === CandidatesViewType.Board ? filters.filterDarkGreen : undefined}
              src={BoardViewSVG}
            />
          </div>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
