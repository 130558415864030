import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  SxProps,
  Theme,
  Stack,
  MenuItem,
  Menu,
} from "@mui/material";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Row, TableInstance } from "react-table";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { navigateToLink } from "App/routing/utils";
import ChevronRight from "assets/icons/chevron-right.svg";
import CampaignEditorWrapper from "components/dover/CampaignEditor/CampaignEditor";
import { AddOrRemoveCampaignModal } from "components/dover/SearchesTable/components/AddOrRemoveCampaignModal";
import { MUI_SWITCH_INPUT_CLASS } from "components/dover/SearchesTable/constants";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Heading, Overline } from "components/library/typography";
import CustomModal from "components/Modal";
import { Spacer } from "components/Spacer";
import { selectFromListCampaignsQueryResult, useListCampaignsQuery } from "services/doverapi/endpoints/campaign";
import { useRemoveCampaignFromSearchMutation } from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3 } from "services/openapi";
import { CustomScrollBox } from "styles/layout";
import { colors } from "styles/theme";

const downArrow = (
  <ReactSVG src={ChevronRight} beforeInjection={(svg): void => svg.setAttribute("transform", "rotate(90)")} />
);
const upArrow = (
  <ReactSVG src={ChevronRight} beforeInjection={(svg): void => svg.setAttribute("transform", "rotate(270)")} />
);

interface ScrollableTableProps {
  table: TableInstance<SearchV3>;
  sx: SxProps<Theme>;
}

export const ScrollableTable = ({ table, sx }: ScrollableTableProps): React.ReactElement => {
  const { getTableBodyProps, headerGroups, rows, prepareRow } = table;
  const ref = useRef<HTMLElement>(null);
  const navigate = useNavigate();

  const onTableRowClick = React.useCallback(
    (row: Row<SearchV3>, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (!row.original.id) {
        return;
      }

      // Allow for switches to be toggle without triggering a window event
      if ((event.target as Element).classList.contains(MUI_SWITCH_INPUT_CLASS)) {
        return;
      }
      navigateToLink(event, navigate, APP_ROUTE_PATHS.sourcing(row.original.id));
    },
    [navigate]
  );

  return (
    <CustomScrollBox ref={ref} sx={sx}>
      <Table stickyHeader>
        <TableHead>
          {headerGroups.map(headerGroup => {
            return (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <TableCell
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({ title: column.canSort ? `sort by ${column.Header}` : undefined })
                      )}
                      key={column.id}
                      align="left"
                      sx={{
                        backgroundColor: colors.grayscale.gray100,
                        padding: "8px 16px",
                        // @ts-ignore
                        ...(column.sx ?? {}),
                      }}
                    >
                      <Box display="flex" height="24px" alignItems="center">
                        <Overline>{column.render("Header")}</Overline>
                        {column.isSorted && <Spacer width={8} />}
                        {column.isSorted && (column.isSortedDesc ? upArrow : downArrow)}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <>
                <TableRow
                  {...rowProps}
                  key={rowProps.key}
                  onClick={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>): void => {
                    onTableRowClick(row, event);
                  }}
                  sx={{
                    backgroundColor: "#fff",
                    "&:hover": { backgroundColor: colors.grayscale.gray100, cursor: "pointer" },
                  }}
                >
                  {row.cells.map(cell => (
                    <TableCell sx={{ borderBottom: "none" }} {...cell.getCellProps()} align="left" padding="none">
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: "#fff",
                  }}
                >
                  <TableCell colSpan={row.cells.length} sx={{ pt: "0px", pb: "4px" }}>
                    <CampaignsBySearch search={row.original} />
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </CustomScrollBox>
  );
};

const StyledMenuButton = styled(Button)`
  padding: 4px 8px;
`;

interface CampaignSelectorProps {
  search: SearchV3;
  campaignId: string;
}

const CampaignSelector = React.memo(
  ({ search, campaignId }: CampaignSelectorProps): React.ReactElement => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const { selectedCampaign: campaign, isLoading: campaignsLoading } = useListCampaignsQuery(
      { jobId: search.job },
      {
        selectFromResult: rtkResults =>
          selectFromListCampaignsQueryResult(rtkResults, { selectedCampaignId: campaignId }),
      }
    );

    const openMenu = React.useCallback((event: React.MouseEvent<HTMLElement>): void => {
      setMenuAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = React.useCallback((): void => {
      setMenuAnchorEl(null);
    }, []);

    const [removeCampaignFromSearch] = useRemoveCampaignFromSearchMutation();
    const handleRemoveCampaign = React.useCallback((): void => {
      if (!search.id) {
        return;
      }

      removeCampaignFromSearch({ searchId: search.id, campaignId });

      closeMenu();
    }, [campaignId, closeMenu, removeCampaignFromSearch, search.id]);

    const [shouldShowEditCampaignModal, setShouldShowEditCampaignModal] = React.useState(false);
    const handleViewCampaign = React.useCallback((): void => {
      if (!search.id) {
        return;
      }

      setShouldShowEditCampaignModal(true);

      closeMenu();
    }, [closeMenu, search.id]);

    if (!campaign?.name || !campaign.id || campaignsLoading) {
      return <></>;
    }

    return (
      <>
        <StyledMenuButton variant={ButtonVariant.Secondary} onClick={openMenu}>
          <Stack alignItems="center" direction="row" spacing={1.5}>
            <BodySmall>{campaign.name}</BodySmall>
            <KeyboardArrowDownIcon />
          </Stack>
        </StyledMenuButton>
        <Menu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={closeMenu}>
          <Stack>
            <MenuItem
              onClick={menuAnchorEl ? handleRemoveCampaign : undefined}
              sx={{
                padding: "4px 8px !important",
              }}
            >
              <BodySmall>Remove</BodySmall>
            </MenuItem>
            <MenuItem
              onClick={menuAnchorEl ? handleViewCampaign : undefined}
              sx={{
                padding: "4px 8px !important",
              }}
            >
              <BodySmall>View/Edit</BodySmall>
            </MenuItem>
          </Stack>
        </Menu>
        {campaign?.id && (
          <CustomModal
            open={shouldShowEditCampaignModal}
            dialogActionsStyles={{ padding: "8px 24px" }}
            customPaperProps={{ sx: { overflowY: "hidden" } }}
            customDialogStyles={{ zIndex: 3000 }}
            onClose={(): void => {
              setShouldShowEditCampaignModal(false);
            }}
            title={<Heading weight={"600"}>{"Edit Outreach"}</Heading>}
            maxWidth={"md"}
          >
            <CampaignEditorWrapper
              jobId={search.job}
              campaignId={campaign.id}
              onSave={(): void => {
                setShouldShowEditCampaignModal(false);
              }}
            />
          </CustomModal>
        )}
      </>
    );
  }
);

interface CampaignsBySearchProps {
  search: SearchV3;
}

const CampaignsBySearch = React.memo(
  ({ search }: CampaignsBySearchProps): React.ReactElement => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const { allCampaigns } = useListCampaignsQuery(
      { jobId: search.job },
      {
        selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
      }
    );

    if (!allCampaigns) {
      return <></>;
    }

    return (
      <>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ backgroundColor: "#F9FAFB", padding: "4px 8px" }}>
          <MailOutlineIcon sx={{ color: colors.grayscale.gray400 }} fontSize="small" />
          <Overline weight="700">{"outreach campaigns"}</Overline>
          <Button
            variant={ButtonVariant.Ghost}
            removePadding={true}
            onClick={(): void => {
              setIsModalOpen(true);
            }}
          >
            <Overline color={colors.linkLight}>{"+ Add"}</Overline>
          </Button>
          {search.campaignIds
            ?.filter(id => !!id)
            .map(campaignId => {
              return <CampaignSelector key={campaignId} search={search} campaignId={campaignId} />;
            })}
        </Stack>
        <AddOrRemoveCampaignModal
          isPrimaryModalOpen={isModalOpen}
          setIsPrimaryModalOpen={setIsModalOpen}
          search={search}
        />
      </>
    );
  }
);
