/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {Date}
     * @memberof Job
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Job
     */
    readonly modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    title: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    client: string;
    /**
     * 
     * @type {boolean}
     * @memberof Job
     */
    active?: boolean;
    /**
     * Reason that should be filled out by EM
     * @type {string}
     * @memberof Job
     */
    deactivationReason?: JobDeactivationReasonEnum;
    /**
     * Reason that was filled out by the customer
     * @type {string}
     * @memberof Job
     */
    deactivationReasonUserSupplied?: JobDeactivationReasonUserSuppliedEnum;
    /**
     * 
     * @type {Date}
     * @memberof Job
     */
    deactivatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    snoozedReason?: JobSnoozedReasonEnum;
    /**
     * 
     * @type {Date}
     * @memberof Job
     */
    lastSnoozedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Job
     */
    snoozedUntil?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    clientReviewType?: JobClientReviewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    newCandidateNotification?: JobNewCandidateNotificationEnum;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    doverReviewType?: JobDoverReviewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    doverReviewNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    outreachReplyioCampaignId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    shouldEngagePassive?: JobShouldEngagePassiveEnum;
    /**
     * 
     * @type {object}
     * @memberof Job
     */
    outreachEmailTemplate?: object | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    schedulingInterviewerTimezone?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Job
     */
    shouldSendSchedulingReminders?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    schedulingCcEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    schedulingBody?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Job
     */
    firstInterviewEmailSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    atsType?: JobAtsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    atsJobId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    atsJobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    atsSyncSetting?: JobAtsSyncSettingEnum;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    hiringManager?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum JobDeactivationReasonEnum {
    Hired = 'Hired',
    HiredThroughDover = 'Hired Through Dover',
    HiredOutsideDover = 'Hired Outside Dover',
    ContractEnded = 'Contract Ended',
    ContractEndedAndStillHiring = 'Contract Ended and still hiring',
    ContractEndedAndNoLongerHiring = 'Contract Ended and no longer hiring',
    DePrioritizedJob = 'De-prioritized Job',
    DePrioritizedJobNoLongerHiring = 'De-prioritized Job - No Longer Hiring',
    DePrioritizedJobStillHiring = 'De-prioritized Job - Still Hiring',
    DuplicateJob = 'Duplicate Job',
    Other = 'Other'
}/**
* @export
* @enum {string}
*/
export enum JobDeactivationReasonUserSuppliedEnum {
    Hired = 'Hired',
    HiredThroughDover = 'Hired Through Dover',
    HiredOutsideDover = 'Hired Outside Dover',
    ContractEnded = 'Contract Ended',
    ContractEndedAndStillHiring = 'Contract Ended and still hiring',
    ContractEndedAndNoLongerHiring = 'Contract Ended and no longer hiring',
    DePrioritizedJob = 'De-prioritized Job',
    DePrioritizedJobNoLongerHiring = 'De-prioritized Job - No Longer Hiring',
    DePrioritizedJobStillHiring = 'De-prioritized Job - Still Hiring',
    DuplicateJob = 'Duplicate Job',
    Other = 'Other'
}/**
* @export
* @enum {string}
*/
export enum JobSnoozedReasonEnum {
    OutOfOffice = 'Out of Office',
    HasCandidatesInProcess = 'Has Candidates in Process',
    DontNeedToHireForThisJob = 'Dont Need to Hire for this Job',
    NeedToRecalibrateSearch = 'Need to recalibrate search',
    Other = 'Other'
}/**
* @export
* @enum {string}
*/
export enum JobClientReviewTypeEnum {
    Emailing = 'BEFORE_EMAILING',
    Scheduling = 'BEFORE_SCHEDULING'
}/**
* @export
* @enum {string}
*/
export enum JobNewCandidateNotificationEnum {
    Slack = 'SLACK',
    Email = 'EMAIL',
    SlackAndEmail = 'SLACK_AND_EMAIL'
}/**
* @export
* @enum {string}
*/
export enum JobDoverReviewTypeEnum {
    RcReview = 'RC_REVIEW',
    PmReview = 'PM_REVIEW'
}/**
* @export
* @enum {string}
*/
export enum JobShouldEngagePassiveEnum {
    All = 'All',
    InterestedInRole = 'Interested In Role',
    ActiveOnly = 'Active Only'
}/**
* @export
* @enum {string}
*/
export enum JobAtsTypeEnum {
    Greenhouse = 'GREENHOUSE',
    Lever = 'LEVER',
    Ashby = 'ASHBY',
    GoogleHire = 'GOOGLE_HIRE',
    NoAts = 'NO_ATS',
    Other = 'OTHER',
    NotRequired = 'NOT_REQUIRED'
}/**
* @export
* @enum {string}
*/
export enum JobAtsSyncSettingEnum {
    Sourced = 'SOURCED',
    FirstContacted = 'FIRST_CONTACTED',
    Responded = 'RESPONDED',
    ReplyTypeSet = 'REPLY_TYPE_SET',
    CallCompleted = 'CALL_COMPLETED',
    CallScheduling = 'CALL_SCHEDULING',
    AddManually = 'ADD_MANUALLY'
}

export function JobFromJSON(json: any): Job {
    return JobFromJSONTyped(json, false);
}

export function JobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Job {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'client': json['client'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'deactivationReason': !exists(json, 'deactivation_reason') ? undefined : json['deactivation_reason'],
        'deactivationReasonUserSupplied': !exists(json, 'deactivation_reason_user_supplied') ? undefined : json['deactivation_reason_user_supplied'],
        'deactivatedAt': !exists(json, 'deactivated_at') ? undefined : (json['deactivated_at'] === null ? null : new Date(json['deactivated_at'])),
        'snoozedReason': !exists(json, 'snoozed_reason') ? undefined : json['snoozed_reason'],
        'lastSnoozedAt': !exists(json, 'last_snoozed_at') ? undefined : (json['last_snoozed_at'] === null ? null : new Date(json['last_snoozed_at'])),
        'snoozedUntil': !exists(json, 'snoozed_until') ? undefined : (json['snoozed_until'] === null ? null : new Date(json['snoozed_until'])),
        'clientReviewType': !exists(json, 'client_review_type') ? undefined : json['client_review_type'],
        'newCandidateNotification': !exists(json, 'new_candidate_notification') ? undefined : json['new_candidate_notification'],
        'doverReviewType': !exists(json, 'dover_review_type') ? undefined : json['dover_review_type'],
        'doverReviewNote': !exists(json, 'dover_review_note') ? undefined : json['dover_review_note'],
        'outreachReplyioCampaignId': !exists(json, 'outreach_replyio_campaign_id') ? undefined : json['outreach_replyio_campaign_id'],
        'shouldEngagePassive': !exists(json, 'should_engage_passive') ? undefined : json['should_engage_passive'],
        'outreachEmailTemplate': !exists(json, 'outreach_email_template') ? undefined : json['outreach_email_template'],
        'schedulingInterviewerTimezone': !exists(json, 'scheduling_interviewer_timezone') ? undefined : json['scheduling_interviewer_timezone'],
        'shouldSendSchedulingReminders': !exists(json, 'should_send_scheduling_reminders') ? undefined : json['should_send_scheduling_reminders'],
        'schedulingCcEmail': !exists(json, 'scheduling_cc_email') ? undefined : json['scheduling_cc_email'],
        'schedulingBody': !exists(json, 'scheduling_body') ? undefined : json['scheduling_body'],
        'firstInterviewEmailSent': !exists(json, 'first_interview_email_sent') ? undefined : json['first_interview_email_sent'],
        'atsType': !exists(json, 'ats_type') ? undefined : json['ats_type'],
        'atsJobId': !exists(json, 'ats_job_id') ? undefined : json['ats_job_id'],
        'atsJobTitle': !exists(json, 'ats_job_title') ? undefined : json['ats_job_title'],
        'atsSyncSetting': !exists(json, 'ats_sync_setting') ? undefined : json['ats_sync_setting'],
        'hiringManager': !exists(json, 'hiring_manager') ? undefined : json['hiring_manager'],
    };
}

export function JobToJSON(value?: Job | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'name': value.name,
        'client': value.client,
        'active': value.active,
        'deactivation_reason': value.deactivationReason,
        'deactivation_reason_user_supplied': value.deactivationReasonUserSupplied,
        'deactivated_at': value.deactivatedAt === undefined ? undefined : (value.deactivatedAt === null ? null : value.deactivatedAt.toISOString()),
        'snoozed_reason': value.snoozedReason,
        'last_snoozed_at': value.lastSnoozedAt === undefined ? undefined : (value.lastSnoozedAt === null ? null : value.lastSnoozedAt.toISOString()),
        'snoozed_until': value.snoozedUntil === undefined ? undefined : (value.snoozedUntil === null ? null : value.snoozedUntil.toISOString()),
        'client_review_type': value.clientReviewType,
        'new_candidate_notification': value.newCandidateNotification,
        'dover_review_type': value.doverReviewType,
        'dover_review_note': value.doverReviewNote,
        'outreach_replyio_campaign_id': value.outreachReplyioCampaignId,
        'should_engage_passive': value.shouldEngagePassive,
        'outreach_email_template': value.outreachEmailTemplate,
        'scheduling_interviewer_timezone': value.schedulingInterviewerTimezone,
        'should_send_scheduling_reminders': value.shouldSendSchedulingReminders,
        'scheduling_cc_email': value.schedulingCcEmail,
        'scheduling_body': value.schedulingBody,
        'first_interview_email_sent': value.firstInterviewEmailSent,
        'ats_type': value.atsType,
        'ats_job_id': value.atsJobId,
        'ats_job_title': value.atsJobTitle,
        'ats_sync_setting': value.atsSyncSetting,
        'hiring_manager': value.hiringManager,
    };
}


