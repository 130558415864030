import { Button } from "@doverhq/dover-ui";
import { Box, Stack, Radio, Popover } from "@mui/material";
import { grey } from "@mui/material/colors";
import Color from "color";
import React, { useState } from "react";
import { useField } from "react-final-form";
import styled from "styled-components";

import HelpIconSVG from "assets/icons/help-question.svg";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Spacer } from "components/Spacer";
import { theme } from "styles/theme";
import { B1_doNotUse, B2_doNotUse } from "styles/typography";

interface Props {
  name: string;
  onSubmit: (newValue: boolean) => Promise<Object | undefined>;
}

export const DoverInterviewerField = ({ name, onSubmit }: Props): React.ReactElement => {
  const field = useField<boolean>(name);
  const useDoverInterviewer = field.input.value;
  const [tempValue, setTempValue] = useState(useDoverInterviewer);

  const selectedOptionBorder = theme.colors.decorative.highlightBlue;
  const selectedOptionBackground = theme.backgrounds.darkBlue;

  /* popover handlers */
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handleClickDoverInterviewerField = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
    setTempValue(useDoverInterviewer);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const toolTipText = "You can turn off Dover Interviewer for this job.";

  /* use dover interviewer option */
  const useDoverInterviewerOption = (
    <Box
      bgcolor={tempValue ? selectedOptionBackground : undefined}
      border={1}
      borderColor={tempValue ? selectedOptionBorder : grey[400]}
      borderRadius={5}
      padding={2}
      paddingLeft="43px"
      paddingRight="23px"
      paddingTop="12px"
      onClick={(): void => {
        setTempValue(true);
      }}
      style={{ cursor: "pointer" }}
    >
      <Box
        bgcolor="#9296F7"
        borderRadius={3}
        color="white"
        display="inline-block"
        fontSize={11}
        fontWeight={700}
        letterSpacing="0.25px"
        padding="2px 5px"
      >
        RECOMMENDED
      </Box>
      <Spacer height={6} />
      <Box display="flex" alignItems="center" position="relative">
        <Box left="-38px" position="absolute">
          <StyledRadio checked={tempValue} />
        </Box>
        <StyledOptionLabel>Use Dover Interviewer</StyledOptionLabel>
      </Box>
      <Spacer height={6} />
      <StyledOptionDescription>Calls will be handled by a Dover Interviewer by default.</StyledOptionDescription>
      <Spacer height={8} />
      <StyledOptionDescription>
        Excited about a particular candidate? As Dover sends you candidates to approve in Slack, you can select a new
        interviewer on a per candidate basis
      </StyledOptionDescription>
    </Box>
  );

  /* do not use dover interviewer option */
  const doNotUseDoverInterviewerOption = (
    <Box
      bgcolor={!tempValue ? selectedOptionBackground : undefined}
      border={1}
      borderColor={!tempValue ? selectedOptionBorder : grey[400]}
      borderRadius={5}
      padding={2}
      paddingLeft="40px"
      onClick={(): void => setTempValue(false)}
      style={{ cursor: "pointer" }}
    >
      <Box display="flex" alignItems="center" position="relative">
        <Box left="-38px" position="absolute">
          <StyledRadio checked={!tempValue} />
        </Box>
        <StyledOptionLabel>Do Not Use Dover Interviewer</StyledOptionLabel>
      </Box>
      <Spacer height={6} />
      <StyledOptionDescription>
        Schedule and complete interviews as you approve candidates in Slack.
      </StyledOptionDescription>
    </Box>
  );

  return (
    <div>
      <StyledOptionBox
        onClick={handleClickDoverInterviewerField}
        alignItems="center"
        bgcolor="white"
        border={1}
        borderColor={grey[400]}
        borderRadius={1}
        display="flex"
        height="56px"
        paddingLeft={2}
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction="row"
          spacing={0}
          style={{ justifyContent: "space-between", width: "100%", marginRight: "16px" }}
          alignItems="center"
        >
          <B1_doNotUse>Dover Interviewer</B1_doNotUse>
          <Stack direction="row" spacing={1} alignItems="center">
            <B2_doNotUse $color="#bdbdbd">|</B2_doNotUse>
            <B2_doNotUse>Configure</B2_doNotUse>
            <Tooltip title={toolTipText} variant={TooltipVariant.Dark} placement="right">
              <img src={HelpIconSVG} />
            </Tooltip>
          </Stack>
        </Stack>
      </StyledOptionBox>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -20,
          horizontal: "left",
        }}
      >
        <Box padding={3} width={420}>
          {useDoverInterviewerOption}
          <Spacer height={16} />
          {doNotUseDoverInterviewerOption}
          <Spacer height={24} />
          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" onPress={handleClose}>
              Cancel
            </Button>
            <Spacer width={14} />
            <Button
              variant="primaryFilled"
              onPress={(): void => {
                onSubmit(tempValue);
                handleClose();
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

/* components */
const StyledOptionBox = styled(Box)`
  :hover {
    background-color: ${theme.backgrounds.darkBlue};
  }
`;

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${theme.colors.decorative.highlightBlue};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(theme.backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

const StyledOptionLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const StyledOptionDescription = styled.div`
  font-size: 14px;
`;
