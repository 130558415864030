import { EntityState } from "@reduxjs/toolkit";

import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { UseQueryResult } from "services/doverapi/types";
import { DashboardJob, JobHiringManager } from "services/openapi";

interface SelectFromListJobsQueryResult extends UseQueryResult<EntityState<DashboardJob>> {
  allJobs: DashboardJob[] | undefined;
  firstJob: DashboardJob | undefined;
  activeJobs: DashboardJob[] | undefined;
  activeJobTitles: string[] | undefined;
  inactiveJobs: DashboardJob[] | undefined;
  authedUsersJobs: DashboardJob[] | undefined;
  notAuthedUsersJobs: DashboardJob[] | undefined;
  activeHiringManagers: JobHiringManager[] | undefined;
}

export const selectFromListJobsQueryResult = (
  rtkResults: UseQueryResult<EntityState<DashboardJob>>,
  { authedUserEmail, sortFunc }: { authedUserEmail?: string; sortFunc?: (jobs: DashboardJob[]) => DashboardJob[] } = {}
): SelectFromListJobsQueryResult => {
  /* Used as a helper for RTKQ's selectFromResult option to perform
   *
   * Example:
   *
   * const { user } = useAuth0();
   * const {
   *   isLoading: jobsLoading,
   *   error: jobsLoadingError,
   *   authedUsersJobs: myJobs,
   *   notAuthedUsersJobs: otherJobs,
   * } = useListJobsQuery(undefined, {
   *   selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults, { authedUserEmail: user.email }),
   * });
   *
   * */

  // Start off with everything as undefined. Only if we're done loading and the prerequisite
  // args are provided will certain bits of enriched data be assigned.
  let allJobs: DashboardJob[] | undefined = undefined;
  let firstJob = undefined;
  let activeJobs: DashboardJob[] | undefined = undefined;
  let inactiveJobs: DashboardJob[] | undefined = undefined;
  let authedUsersJobs: DashboardJob[] | undefined = undefined;
  let notAuthedUsersJobs: DashboardJob[] | undefined = undefined;
  let activeJobTitles: string[] | undefined = undefined;
  let activeHiringManagers: JobHiringManager[] | undefined = undefined;
  let activeHiringMangersIdList: number[] | undefined = undefined;

  if (!rtkResults.isLoading && rtkResults.data) {
    // Flatten to an array of jobs and sort them if a sort function was provided.
    allJobs = listAllEntities(rtkResults.data) ?? [];
    if (sortFunc) {
      allJobs = sortFunc(allJobs);
    }

    // Re-initialize arrays now that data has been loaded from the endpoint
    // (assuming prerequisite args were supplied).
    activeJobs = [];
    inactiveJobs = [];
    activeJobTitles = [];
    activeHiringManagers = [];
    activeHiringMangersIdList = [];

    if (authedUserEmail) {
      authedUsersJobs = [];
      notAuthedUsersJobs = [];
    }

    // Iterate over jobs once, building up our filtered arrays of jobs in one go.
    allJobs.forEach((job: DashboardJob) => {
      if (job.active) {
        activeJobs!.push(job);
        activeJobTitles!.push(job.title!);
        if (job.hiringManager && !activeHiringMangersIdList?.includes(job.hiringManager.id!)) {
          activeHiringManagers?.push(job.hiringManager);
        }
        activeHiringMangersIdList!.push(job.hiringManager?.id!);
      } else {
        inactiveJobs!.push(job);
      }

      // If the authed user's email was provided, then keep track of which jobs belong to them vs don't.
      if (authedUserEmail) {
        if (job.hiringManager?.email === authedUserEmail) {
          authedUsersJobs!.push(job);
        } else {
          notAuthedUsersJobs!.push(job);
        }
      }
    });

    if (authedUsersJobs && authedUsersJobs.length > 0) {
      const authedUserActiveJob = authedUsersJobs.find(job => job.active);
      firstJob = authedUserActiveJob;
    }

    if (!firstJob && activeJobs.length > 0) {
      firstJob = activeJobs[0];
    }

    firstJob = firstJob || allJobs[0];
  }

  return {
    // Transformations
    allJobs,
    firstJob,
    activeJobs,
    activeJobTitles,
    inactiveJobs,
    authedUsersJobs,
    notAuthedUsersJobs,
    activeHiringManagers,
    // Default result values that come for free from RTK Query
    // https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#usequery
    ...rtkResults,
  };
};
