import { Grid, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { ReactComponent as AvailableIcon } from "assets/icons/active-dot.svg";
import { ReactComponent as CheckIcon } from "assets/icons/complete-check.svg";
import { ReactComponent as ShopIcon } from "assets/icons/shop.svg";
import { ReactComponent as GreenCheck } from "assets/icons/simple-green-check.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Body, BodySmall, Heading, PageTitle, Subtitle1, Subtitle2 } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import RecruitingPartnerVideo from "components/RecruitingPartnerVideo";
import { useListRecruitingPartnersQuery } from "services/doverapi/endpoints/recruiting-partner/endpoints";
import { RecruitingPartner } from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { ReactComponent as LogosImage } from "views/RecruitingPartners/logos.svg";

const RPCard = ({ recruitingPartner }: { recruitingPartner: RecruitingPartner }): React.ReactElement => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));

  const clientNames = recruitingPartner.clientNames?.split(",");

  return (
    <Card width={isMobile ? "95%" : "fit-content"}>
      <Stack spacing={1} width={{ xs: "100%", md: "100%" }} height={{ xs: undefined, md: "312px" }}>
        {/* photo + header */}
        <Stack alignItems="center" spacing={1} direction="row">
          <Stack minWidth={"54px"} minHeight={"54px"}>
            <PersonaCoin size="large" pictureUrl={recruitingPartner.pictureUrl} color="gray" />
          </Stack>
          <Stack>
            <Body weight="500">{recruitingPartner.name}</Body>
            {recruitingPartner.yearsOfExperience !== undefined && (
              <BodySmall weight="300">{recruitingPartner.yearsOfExperience} years of experience</BodySmall>
            )}
            {recruitingPartner.isAvailable && (
              <Stack direction="row" alignItems={"center"} spacing={0.5}>
                <AvailableIcon />
                <BodySmall weight="300">Available</BodySmall>
              </Stack>
            )}
          </Stack>
        </Stack>
        {/* body */}
        <Stack justifyContent={"space-between"} height="100%">
          <BodySmall>{recruitingPartner.blurb}</BodySmall>
          {clientNames && (
            <Stack>
              <Subtitle2>{"I've recruited for:"}</Subtitle2>
              <Stack spacing={0.5}>
                {clientNames.map(clientName => (
                  <BodySmall>&#8226; {clientName}</BodySmall>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

const RecruitingPartnersPage = (): React.ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "recruiting_partners_page", "cta");

  const { data, isFetching } = useListRecruitingPartnersQuery();

  return (
    // page
    <Stack direction="row" justifyContent="center">
      {/* centered container */}
      <Stack maxWidth={"1000px"} py={4} width="100%" spacing={{ xs: 3, sm: 4 }}>
        <Stack spacing={2} alignItems={"center"}>
          <Stack spacing={1} alignItems={"center"}>
            <ShopIcon width="24px" height="24px" className="svg-color" color={colors.grayscale.gray600} />
            <Heading>Recruiting Partners</Heading>
          </Stack>
          {/* top section + video */}
          <Stack
            p={{ xs: 2, sm: 4 }}
            sx={{
              border: `1px solid ${colors.grayscale.gray200}`,
              borderRadius: "10px",
              background: "linear-gradient(#F4F6F8 10%, rgba(244, 246, 248, 0) 100%)",
            }}
            width="100%"
            maxWidth={"765px"}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent={{ xs: "center", sm: "space-between" }}
              alignItems={{ xs: "center", sm: "flex-start" }}
            >
              {/* left side of video */}
              <Stack>
                <Stack
                  spacing={2}
                  justifyContent={{ xs: "center", sm: "space-between" }}
                  alignItems={{ xs: "center", sm: "flex-start" }}
                >
                  {/* bullets + video */}
                  {/* bullets + CTA */}
                  <PageTitle>Work with experienced recruiters to hire top talent</PageTitle>
                  <Subtitle1>Why work with a Recruiting Partner?</Subtitle1>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                      <CheckIcon className="svg-color" color={colors.brand} />
                      <Body weight="300">Highly vetted and experienced recruiters</Body>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                      <CheckIcon className="svg-color" color={colors.brand} />
                      <Body weight="300">Handles everything from sourcing to offer</Body>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                      <CheckIcon className="svg-color" color={colors.brand} />
                      <Body weight="300">Flexible alternative to traditional recruiters</Body>
                    </Stack>
                  </Stack>
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={(): void => {
                      window.open(calendlyUrl, "_blank");
                    }}
                  >
                    Match with a Recruiter
                  </Button>
                </Stack>
              </Stack>
              {/* right side -- video */}
              <Stack
                justifyContent={"center"}
                alignItems={"flex-end"}
                height="100%"
                width="50%"
                minHeight={"188px"}
                minWidth={"340px"}
                pt={{ xs: "16px", sm: "0px" }}
              >
                <RecruitingPartnerVideo height="188px" width="340px" />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {/* bottom section */}
        <Stack spacing={3} alignItems={"center"}>
          <Stack spacing={1} alignItems={"center"}>
            <Heading>Meet our Recruiting Partners</Heading>
            <Stack direction="row" spacing={1.5} alignItems="center" justifyContent="center">
              <Stack direction="row" spacing={0.5}>
                <GreenCheck />
                <BodySmall color={colors.grayscale.gray600}>No minimums</BodySmall>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <GreenCheck />
                <BodySmall color={colors.grayscale.gray600}>No commitments</BodySmall>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <GreenCheck />
                <BodySmall color={colors.grayscale.gray600}>Pause whenever you need</BodySmall>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={1} alignItems={"center"}>
            <Body weight="300">{"We've helped hire at top companies"}</Body>
            <LogosImage />
          </Stack>
          <Grid container rowSpacing={{ xs: 2, sm: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {!isFetching &&
              data?.results.map(rp => (
                <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: "8px !important" }}>
                  <RPCard recruitingPartner={rp} />
                </Grid>
              ))}
            {isFetching && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <Skeleton width="259px" height="274px" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Skeleton width="259px" height="274px" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Skeleton width="259px" height="274px" />
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RecruitingPartnersPage;
