import { Stack } from "@mui/material";
import React from "react";

import Accordion from "components/accordion";
import { DashboardJob } from "services/openapi";
import { colors } from "styles/theme";
import { JobsList } from "views/Homepage/components/JobsList";
import { NoJobsCard } from "views/Homepage/components/NoJobsCard";

interface ActiveAndInactiveJobsProps {
  activeJobs: DashboardJob[];
  activeJobsIsLoading: boolean;
  inactiveJobs: DashboardJob[];
  inactiveJobsIsLoading: boolean;
}

export const ActiveAndInactiveJobs = ({
  activeJobs,
  activeJobsIsLoading,
  inactiveJobs,
  inactiveJobsIsLoading,
}: ActiveAndInactiveJobsProps): React.ReactElement => {
  const activeNonPausedJobs = activeJobs?.filter(j => !j.snoozedUntil || j.snoozedUntil <= new Date());

  const activeJobsAutopilotPaused = activeJobs?.filter(j => !!j.snoozedUntil && j.snoozedUntil > new Date());

  const getActiveJobsOrNoJobsCard = (): React.ReactElement => {
    if (!activeJobsIsLoading && activeJobs?.length === 0) {
      return <NoJobsCard />;
    }

    return (
      <>
        <JobsList jobs={activeNonPausedJobs} isLoading={activeJobsIsLoading} />
        {!!activeJobsAutopilotPaused.length && (
          <Accordion title={"Autopilot Paused"} titleColor={colors.black} initialOpen>
            <JobsList jobs={activeJobsAutopilotPaused} isLoading={activeJobsIsLoading} numSkeletonRows={2} />
          </Accordion>
        )}
      </>
    );
  };

  const getInactiveJobsAccordion = (): React.ReactElement => {
    if (inactiveJobsIsLoading || inactiveJobs?.length === 0) {
      return <></>;
    }

    return (
      <Accordion title={"Inactive"} titleColor={colors.black}>
        <JobsList jobs={inactiveJobs} isLoading={inactiveJobsIsLoading} numSkeletonRows={2} />
      </Accordion>
    );
  };

  return (
    <Stack spacing={1} paddingBottom="5px">
      {getActiveJobsOrNoJobsCard()}
      {getInactiveJobsAccordion()}
    </Stack>
  );
};
