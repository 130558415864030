import clipboardCopy from "clipboard-copy";

import { appConfig } from "config/appConfig";

export const copyReferralLink = ({
  clientId,
  name,
  email,
}: {
  clientId?: string;
  name?: string;
  lastName?: string;
  email?: string;
}): void => {
  if (!clientId) {
    return;
  }

  // format name and email into a query string
  const query = new URLSearchParams();
  if (name) query.set("name", name);
  if (email) query.set("email", email);

  const queryString = query.toString();
  const referralLink = `${appConfig.appUrl}/submit-referral/${clientId}?${queryString}`;

  clipboardCopy(referralLink);
};
