/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TitlePatternInfo,
    TitlePatternInfoFromJSON,
    TitlePatternInfoFromJSONTyped,
    TitlePatternInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ModifyTitlePatternsInSearchRequest
 */
export interface ModifyTitlePatternsInSearchRequest {
    /**
     * 
     * @type {Array<TitlePatternInfo>}
     * @memberof ModifyTitlePatternsInSearchRequest
     */
    titlePatternsInfo: Array<TitlePatternInfo>;
    /**
     * 
     * @type {string}
     * @memberof ModifyTitlePatternsInSearchRequest
     */
    requestedPersonaId: string;
    /**
     * 
     * @type {string}
     * @memberof ModifyTitlePatternsInSearchRequest
     */
    newPersonaName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModifyTitlePatternsInSearchRequest
     */
    keepMlTitles?: boolean;
}

export function ModifyTitlePatternsInSearchRequestFromJSON(json: any): ModifyTitlePatternsInSearchRequest {
    return ModifyTitlePatternsInSearchRequestFromJSONTyped(json, false);
}

export function ModifyTitlePatternsInSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModifyTitlePatternsInSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'titlePatternsInfo': ((json['title_patterns_info'] as Array<any>).map(TitlePatternInfoFromJSON)),
        'requestedPersonaId': json['requested_persona_id'],
        'newPersonaName': !exists(json, 'new_persona_name') ? undefined : json['new_persona_name'],
        'keepMlTitles': !exists(json, 'keep_ml_titles') ? undefined : json['keep_ml_titles'],
    };
}

export function ModifyTitlePatternsInSearchRequestToJSON(value?: ModifyTitlePatternsInSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title_patterns_info': ((value.titlePatternsInfo as Array<any>).map(TitlePatternInfoToJSON)),
        'requested_persona_id': value.requestedPersonaId,
        'new_persona_name': value.newPersonaName,
        'keep_ml_titles': value.keepMlTitles,
    };
}


