import { Box, Container } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { JobReferralTabPaths } from "App/routing/types";
import { defaultTriageTab } from "views/Referrals/ReviewReferrals/triageTabConfigs";

const ReviewReferralsWrapper = (): React.ReactElement => {
  const navigate = useNavigate();
  const { triageTabPath } = useParams<{ triageTabPath: string }>();

  if (typeof triageTabPath === "undefined") {
    // If the user didn't specify a tab, redirect to our default.
    navigate(APP_ROUTE_PATHS.referrals.review(defaultTriageTab as JobReferralTabPaths), { replace: true });
    return <></>;
  }

  return (
    <Container maxWidth={"lg"}>
      <Box pt={8}>
        <Box className="text-center mb-4">Under Construction</Box>
        <Box className="text-center alert alert-danger" role="alert">
          We&apos;re currently reworking our referrals offering. Stay tuned for updates!
        </Box>
      </Box>
    </Container>
  );
};

export default ReviewReferralsWrapper;
