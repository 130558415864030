import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { useGetInitialCallInterviewQuery } from "services/doverapi/endpoints/candidate";
import { useLazyGetInterviewRubricResponseQuery } from "services/doverapi/endpoints/candidateInterview";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import { createAndOpenFeedbackForm } from "utils/candidate";
import { ConciergeProfile } from "views/CandidateDetail/components/next-action/ConciergeProfile";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { colorsMap, NextActionStateComponentProps } from "views/CandidateDetail/components/next-action/utils/constants";

export function ConciergePostDoverInterviewer({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  const conciergeInfo = useConciergeInfo();

  const {
    data: initialCallInterviewData,
    isFetching: isFetchingInitialCallInterviewData,
  } = useGetInitialCallInterviewQuery(candidateBio?.id ?? skipToken);

  const [getOrCreateIRR] = useLazyGetInterviewRubricResponseQuery();
  if (!candidateBio.id || isFetchingInitialCallInterviewData || !initialCallInterviewData?.interview.id) {
    return <></>;
  }

  const description = (
    <Stack spacing={2}>
      <ConciergeProfile slackUrl={candidateBio.slackUrl} />
      <Subtitle2 weight="500">Would you like {conciergeInfo?.firstName} to schedule this candidate?</Subtitle2>
      <Stack direction="row" spacing={1}>
        <Button
          variant={ButtonVariant.Secondary}
          onClick={async (): Promise<void> =>
            await createAndOpenFeedbackForm(
              getOrCreateIRR,
              candidateBio.id!,
              initialCallInterviewData.interview.id,
              false
            )
          }
        >
          <BodySmall weight="500">Review Interview Notes</BodySmall>
        </Button>
      </Stack>
    </Stack>
  );

  return <NextActionCardSkeleton title={"Awaiting Decision"} colors={colorsMap.yellow} description={description} />;
}
