import { SerializedError } from "@reduxjs/toolkit";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { Role, useHasRole } from "components/RBAC";
import {
  useGetInterviewRubricResponseQuery,
  useGetInterviewRubricResponseByIdQuery,
} from "services/doverapi/endpoints/candidateInterview";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { DoverApiError } from "services/doverapi/types";
import { InterviewRubricResponse } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { InterviewRubricMode } from "views/interview/CandidateInterview/types";

export function useAuthedUserHasInterviewerRole(): boolean {
  return useHasRole(Role.INTERVIEWER);
}

interface InterviewRubricResponseResponseProps {
  interviewRubricResponse?: InterviewRubricResponse;
  rubricMode?: InterviewRubricMode;
  isLoadingInterviewRubricResponse?: boolean;
  isLoadingInterviewRubricResponseSuccess?: boolean;
  isPostDecision?: boolean;
  isPostInitialSubmit?: boolean;
  isEditing?: boolean;
  isUserHm?: boolean;
  userSameClientAsCandidate?: boolean;
  error?: DoverApiError | SerializedError;
}

export const useInterviewRubricResponseQuery = ({
  candidateId,
  interviewId,
  interviewRubricResponseId,
}: {
  candidateId?: string;
  interviewId?: string;
  interviewRubricResponseId?: string;
}): InterviewRubricResponseResponseProps => {
  const {
    user: { email: authedUserEmail },
  } = useAuth0();
  const authedUserHasInterviewerRole = useAuthedUserHasInterviewerRole();
  const clientId = useGetClientId();

  const selectFromResult = ({
    data,
    isFetching,
    isSuccess,
    error,
  }: {
    data?: InterviewRubricResponse;
    isFetching: boolean;
    isSuccess: boolean;
    error?: DoverApiError | SerializedError;
  }): InterviewRubricResponseResponseProps => {
    const advanceToNextRound = data?.candidate?.feedback?.advanceToNextRound;
    const isPostDecision = advanceToNextRound !== undefined && advanceToNextRound !== null;
    const isEditing = data?.interviewState === 200;
    const isPostInitialSubmit = data?.interviewState === 300;
    const isUserHm = !!authedUserEmail && authedUserEmail === data?.candidate?.job?.hiringManager?.email;
    const rubricMode = data?.candidateInterviewInfo?.useDoverInterviewer
      ? authedUserHasInterviewerRole && !isPostDecision
        ? InterviewRubricMode.Interview
        : InterviewRubricMode.Review
      : isPostInitialSubmit
      ? InterviewRubricMode.Review
      : InterviewRubricMode.Interview;
    const userSameClientAsCandidate = clientId === data?.candidate?.job.client.id;

    return {
      interviewRubricResponse: data,
      rubricMode,
      isLoadingInterviewRubricResponse: isFetching,
      isLoadingInterviewRubricResponseSuccess: isSuccess,
      isPostDecision,
      isPostInitialSubmit,
      isEditing,
      isUserHm,
      userSameClientAsCandidate,
      error,
    };
  };

  const getFromCandidateIdAndInterviewId = useGetInterviewRubricResponseQuery(
    candidateId && interviewId && !interviewRubricResponseId ? { candidateId, interviewId } : skipToken,
    { selectFromResult }
  );

  const getFromInterviewRubricResponseId = useGetInterviewRubricResponseByIdQuery(
    interviewRubricResponseId ?? skipToken,
    { selectFromResult }
  );
  return useMemo(() => {
    const {
      interviewRubricResponse,
      rubricMode,
      isLoadingInterviewRubricResponse,
      isLoadingInterviewRubricResponseSuccess,
      isPostDecision,
      isEditing,
      isPostInitialSubmit,
      isUserHm,
      userSameClientAsCandidate,
      error,
    } = interviewRubricResponseId ? getFromInterviewRubricResponseId : getFromCandidateIdAndInterviewId;

    return {
      interviewRubricResponse,
      rubricMode,
      isLoadingInterviewRubricResponse,
      isLoadingInterviewRubricResponseSuccess,
      isPostDecision,
      isPostInitialSubmit,
      isEditing,
      isUserHm,
      userSameClientAsCandidate,
      error,
    };
  }, [getFromCandidateIdAndInterviewId, getFromInterviewRubricResponseId, interviewRubricResponseId]);
};
