import { Box, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryParam, withDefault } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import HelpSVG from "assets/icons/help-question.svg";
import Loading from "components/HotLoading";
import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import { selectFromListJobsQueryResult, useListJobsQuery } from "services/doverapi/endpoints/job";
import { DashboardJob } from "services/openapi";
import { colors } from "styles/theme";
import { B2_doNotUse, ExternalLink } from "styles/typography";
import { ActiveCandidates } from "views/Reporting/components/ActiveCandidates";
import { InterviewStatsTable } from "views/Reporting/components/InterviewStatsTable";
import { KickoffDate } from "views/Reporting/components/KickoffDate";
import { DateOption, dateRangeOptionQueryParamName, DateRangeOptionQueryParamType } from "views/Reporting/constants";
import { StyledRow, StyledSVG, Title } from "views/Reporting/styles";
import { getFilterDates } from "views/Reporting/utils";

const JobReport = (): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId } = useParams<{ jobId?: string }>();

  const [job, setJob] = useState<DashboardJob | null>(null);
  const [dateRangeOption, setDateRangeOption] = useQueryParam(
    dateRangeOptionQueryParamName,
    withDefault(DateRangeOptionQueryParamType, DateOption.AllTime)
  );

  const { activeJobs, allJobs, isLoading: jobsLoading } = useListJobsQuery(
    {},
    {
      selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults),
    }
  );

  useEffect(() => {
    if (activeJobs && activeJobs.length) {
      setJob(activeJobs[0]);
    }
  }, [activeJobs]);

  useEffect(() => {
    if (!allJobs) {
      return;
    }

    // if no job id in params, default to first job in list
    if (!jobId) {
      navigate(APP_ROUTE_PATHS.reports.reporting(allJobs[0]?.id));
    }
    setJob(allJobs.find(j => j.id === jobId) || null);
  }, [jobId, allJobs, navigate]);

  const selectedDateRange = useMemo(() => {
    return getFilterDates(dateRangeOption);
  }, [dateRangeOption]);

  if (jobsLoading || !job || !job.id) {
    return <Loading />;
  }

  return (
    <>
      <PageHelmet title={job.title ? `${job.title} - Report` : "Report"} />

      <Container sx={{ paddingBottom: "32px", display: "flex" }} maxWidth={"xl"}>
        <StyledRow>
          {job && (
            <Stack direction="row" spacing={3} marginTop="32px" flexGrow={1}>
              <Box flexGrow={1}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                  <Grid item xs={12}>
                    <Box color={colors.grayscale.gray600} display="flex" justifyContent="space-between">
                      <Box display="flex" width="100%">
                        <ActiveCandidates jobId={job.id} />
                        <Spacer width={16} />
                        <KickoffDate jobId={job.id} />
                        <Box display="flex" marginLeft="auto" alignItems="center">
                          <StyledSVG src={HelpSVG} $width="18px" $color={colors.informational.base} />
                          <Spacer width={4} />
                          <ExternalLink
                            href="https://help.dover.com/en/articles/6308801-reports-page-faqs"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <B2_doNotUse $color={colors.informational.base}>Learn More</B2_doNotUse>
                          </ExternalLink>
                        </Box>
                      </Box>
                    </Box>
                    <Spacer height={16} />
                  </Grid>
                </Grid>
                <br />
                <InterviewStatsTable
                  job={job}
                  dateRange={selectedDateRange}
                  dateRangeOption={dateRangeOption}
                  onDateRangeChange={setDateRangeOption}
                />
              </Box>
            </Stack>
          )}
          {!job && !jobsLoading && (
            <Grid item xs={12}>
              <Title>Please select a job</Title>
            </Grid>
          )}
        </StyledRow>
      </Container>
    </>
  );
};

export default JobReport;
