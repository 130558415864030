/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtensionCampaignMessage
 */
export interface ExtensionCampaignMessage {
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaignMessage
     */
    campaignMessageState?: ExtensionCampaignMessageCampaignMessageStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaignMessage
     */
    messageId: string;
    /**
     * 
     * @type {number}
     * @memberof ExtensionCampaignMessage
     */
    minMessageDelay?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaignMessage
     */
    prevMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaignMessage
     */
    subjectTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaignMessage
     */
    bodyTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaignMessage
     */
    unsubscribeFooterOverride?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum ExtensionCampaignMessageCampaignMessageStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}

export function ExtensionCampaignMessageFromJSON(json: any): ExtensionCampaignMessage {
    return ExtensionCampaignMessageFromJSONTyped(json, false);
}

export function ExtensionCampaignMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionCampaignMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaignMessageState': !exists(json, 'campaign_message_state') ? undefined : json['campaign_message_state'],
        'messageId': json['message_id'],
        'minMessageDelay': !exists(json, 'min_message_delay') ? undefined : json['min_message_delay'],
        'prevMessage': !exists(json, 'prev_message') ? undefined : json['prev_message'],
        'subjectTemplate': !exists(json, 'subject_template') ? undefined : json['subject_template'],
        'bodyTemplate': !exists(json, 'body_template') ? undefined : json['body_template'],
        'unsubscribeFooterOverride': !exists(json, 'unsubscribe_footer_override') ? undefined : json['unsubscribe_footer_override'],
    };
}

export function ExtensionCampaignMessageToJSON(value?: ExtensionCampaignMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaign_message_state': value.campaignMessageState,
        'message_id': value.messageId,
        'min_message_delay': value.minMessageDelay,
        'prev_message': value.prevMessage,
        'subject_template': value.subjectTemplate,
        'body_template': value.bodyTemplate,
        'unsubscribe_footer_override': value.unsubscribeFooterOverride,
    };
}


