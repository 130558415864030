/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkUpsertClientDomainResponse
 */
export interface BulkUpsertClientDomainResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpsertClientDomainResponse
     */
    success: boolean;
}

export function BulkUpsertClientDomainResponseFromJSON(json: any): BulkUpsertClientDomainResponse {
    return BulkUpsertClientDomainResponseFromJSONTyped(json, false);
}

export function BulkUpsertClientDomainResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpsertClientDomainResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function BulkUpsertClientDomainResponseToJSON(value?: BulkUpsertClientDomainResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
    };
}


