/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAgencyRecruiter
 */
export interface CreateAgencyRecruiter {
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyRecruiter
     */
    agencyId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyRecruiter
     */
    agencyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyRecruiter
     */
    recruiterName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyRecruiter
     */
    recruiterEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyRecruiter
     */
    rate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgencyRecruiter
     */
    rateType: CreateAgencyRecruiterRateTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateAgencyRecruiterRateTypeEnum {
    Percentage = 'PERCENTAGE',
    FlatRate = 'FLAT_RATE'
}

export function CreateAgencyRecruiterFromJSON(json: any): CreateAgencyRecruiter {
    return CreateAgencyRecruiterFromJSONTyped(json, false);
}

export function CreateAgencyRecruiterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAgencyRecruiter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agencyId': !exists(json, 'agency_id') ? undefined : json['agency_id'],
        'agencyName': !exists(json, 'agency_name') ? undefined : json['agency_name'],
        'recruiterName': json['recruiter_name'],
        'recruiterEmail': json['recruiter_email'],
        'rate': json['rate'],
        'rateType': json['rate_type'],
    };
}

export function CreateAgencyRecruiterToJSON(value?: CreateAgencyRecruiter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency_id': value.agencyId,
        'agency_name': value.agencyName,
        'recruiter_name': value.recruiterName,
        'recruiter_email': value.recruiterEmail,
        'rate': value.rate,
        'rate_type': value.rateType,
    };
}


