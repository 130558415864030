import { Button, Progress } from "@doverhq/dover-ui";
import { ReactComponent as XIcon } from "@doverhq/dover-ui/icons/x.svg";
import { Box, Stack } from "@mui/material";
import { useSetAtom } from "jotai";
import React, { FC } from "react";

import { ReactComponent as AppReviewCandsSVG } from "assets/icons/sample-app-review-cands.svg";
import { ReactComponent as SimpleCriteriaSVG } from "assets/icons/sample-simple-criteria.svg";
import { ReactComponent as ScoringSVG } from "assets/icons/scoring.svg";
import { BodySmall, Subtitle1, Subtitle2 } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useActivateScoringForJobMutation } from "services/doverapi/endpoints/applicationReview";
import { SearchV3 } from "services/openapi";
import { colors } from "styles/theme";
import { criteriaModeAtom } from "views/candidates/ApplicationReview/atoms/chat";
import { SaapSorters } from "views/candidates/ApplicationReview/components/CriteriaPanel/SaapSorters";
import { SimpleSorters } from "views/candidates/ApplicationReview/components/CriteriaPanel/SimpleSorters";
import { StickyBottomButton } from "views/candidates/ApplicationReview/components/StickyBottomButton";
import { StickyTopButton } from "views/candidates/ApplicationReview/components/StickyTopButton";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";

interface CriteriaPanelProps {
  search: SearchV3;
  jobId: string;
}

const NumberBubble = ({ number }: { number: number }): React.ReactElement => (
  <Box
    border={`1px solid ${colors.grayscale.gray100}`}
    borderRadius="50%"
    width="26px"
    height="26px"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <BodySmall color={colors.linkLight}>{number}</BodySmall>
  </Box>
);

const ActivateBodySection = ({
  title,
  index,
  SVGIcon,
}: {
  title: string;
  index: number;
  SVGIcon: React.ReactElement;
}): React.ReactElement => {
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={0.5} paddingX="4px">
        <NumberBubble number={index} />
        <Subtitle2>{title}</Subtitle2>
      </Stack>
      <Stack width="100%" alignItems="center">
        {SVGIcon}
      </Stack>
    </>
  );
};

const ActivateScoringBody = (): React.ReactElement => {
  return (
    <Stack
      p={1}
      spacing={1}
      height="100%"
      minHeight={0}
      overflow="auto"
      borderTop={`1px solid ${colors.grayscale.gray200}`}
      borderBottom={`1px solid ${colors.grayscale.gray200}`}
    >
      <Box marginBottom="8px">
        <Subtitle1>How it works</Subtitle1>
      </Box>
      <ActivateBodySection index={1} title="Add your criteria" SVGIcon={<SimpleCriteriaSVG />} />
      <ActivateBodySection
        index={2}
        title="Your applicants are scored and sorted by best match"
        SVGIcon={<AppReviewCandsSVG />}
      />
    </Stack>
  );
};

const CriteriaContent = ({ jobId, search }: CriteriaPanelProps): React.ReactElement => {
  const [activateScoringForJob] = useActivateScoringForJobMutation();
  const setCriteriaMode = useSetAtom(criteriaModeAtom);

  const { jobHasScoringEnabled, isFetching } = useJobHasScoringEnabled();

  const localStorageKey = `hasSeenSaapReviewScoringTutorial-${jobId}`;
  const [hasSeenSaapReviewScoringTutorial, setHasSeenSaapReviewScoringTutorial] = useLocalStorage<boolean>(
    localStorageKey,
    false
  );

  const onClose = (): void => setCriteriaMode(undefined);

  if (isFetching) {
    return <Progress />;
  }

  // if you already have scoring enabled or you've activated it in the past
  // show the simple sorters
  if (jobHasScoringEnabled || hasSeenSaapReviewScoringTutorial) {
    return <SimpleSorters search={search} onClose={onClose} jobId={jobId} />;
  }

  // otherwise introduce them to the concept of scoring
  const activateScoring = (): void => {
    setHasSeenSaapReviewScoringTutorial(true);
    activateScoringForJob({ id: jobId, data: { desiredInboundEnrichingEnabled: true } });
  };

  return (
    <>
      <StickyTopButton
        onClose={onClose}
        text="Applicant scoring"
        icon={<ScoringSVG className="svg-color" color={colors.primary.base} />}
      />
      <ActivateScoringBody />
      <StickyBottomButton text="Activate" onClick={activateScoring} />
    </>
  );
};

export const CriteriaPanel: FC<CriteriaPanelProps> = ({ jobId, search }) => {
  const setCriteriaMode = useSetAtom(criteriaModeAtom);

  const useSimpReviewFF = useFeatureFlag(FeatureFlag.SimpReview);

  const CriteriaPart = useSimpReviewFF ? (
    <Stack bgcolor={colors.white} height="100%" justifyContent="center" minHeight={0}>
      <CriteriaContent jobId={jobId} search={search} />
    </Stack>
  ) : (
    // TODO: remove this when the feature flag is GA'd
    <>
      <Box ml="auto !important">
        <Button variant="ghost" icon={{ Icon: XIcon }} onPress={(): void => setCriteriaMode(undefined)} />
      </Box>
      <SaapSorters search={search} />
    </>
  );

  return <>{CriteriaPart}</>;
};
