/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddToCampaignMessageOverride
 */
export interface AddToCampaignMessageOverride {
    /**
     * 
     * @type {string}
     * @memberof AddToCampaignMessageOverride
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof AddToCampaignMessageOverride
     */
    subject: string;
}

export function AddToCampaignMessageOverrideFromJSON(json: any): AddToCampaignMessageOverride {
    return AddToCampaignMessageOverrideFromJSONTyped(json, false);
}

export function AddToCampaignMessageOverrideFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddToCampaignMessageOverride {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'subject': json['subject'],
    };
}

export function AddToCampaignMessageOverrideToJSON(value?: AddToCampaignMessageOverride | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'subject': value.subject,
    };
}


