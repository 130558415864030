/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HiringPipelineStageMilestone,
    HiringPipelineStageMilestoneFromJSON,
    HiringPipelineStageMilestoneFromJSONTyped,
    HiringPipelineStageMilestoneToJSON,
    HiringPipelineStageType,
    HiringPipelineStageTypeFromJSON,
    HiringPipelineStageTypeFromJSONTyped,
    HiringPipelineStageTypeToJSON,
} from './';

import {
     ApplicationReviewCandidatePipelineStageFromJSONTyped,
     ContactedCandidatePipelineStageFromJSONTyped,
     InterviewCandidatePipelineStageFromJSONTyped,
     LeadCandidatePipelineStageFromJSONTyped,
     OfferCandidatePipelineStageFromJSONTyped,
     RespondedCandidatePipelineStageFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface BaseCandidatePipelineStage
 */
export interface BaseCandidatePipelineStage {
    /**
     * 
     * @type {string}
     * @memberof BaseCandidatePipelineStage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BaseCandidatePipelineStage
     */
    name: string;
    /**
     * 
     * @type {HiringPipelineStageType}
     * @memberof BaseCandidatePipelineStage
     */
    stageType: HiringPipelineStageType;
    /**
     * 
     * @type {HiringPipelineStageMilestone}
     * @memberof BaseCandidatePipelineStage
     */
    milestone: HiringPipelineStageMilestone | null;
    /**
     * 
     * @type {number}
     * @memberof BaseCandidatePipelineStage
     */
    orderIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseCandidatePipelineStage
     */
    substage?: number;
}

export function BaseCandidatePipelineStageFromJSON(json: any): BaseCandidatePipelineStage {
    return BaseCandidatePipelineStageFromJSONTyped(json, false);
}

export function BaseCandidatePipelineStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseCandidatePipelineStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['discriminatorRefName'] === 'ApplicationReviewCandidatePipelineStage') {
            return ApplicationReviewCandidatePipelineStageFromJSONTyped(json, true);
        }
        if (json['discriminatorRefName'] === 'ContactedCandidatePipelineStage') {
            return ContactedCandidatePipelineStageFromJSONTyped(json, true);
        }
        if (json['discriminatorRefName'] === 'InterviewCandidatePipelineStage') {
            return InterviewCandidatePipelineStageFromJSONTyped(json, true);
        }
        if (json['discriminatorRefName'] === 'LeadCandidatePipelineStage') {
            return LeadCandidatePipelineStageFromJSONTyped(json, true);
        }
        if (json['discriminatorRefName'] === 'OfferCandidatePipelineStage') {
            return OfferCandidatePipelineStageFromJSONTyped(json, true);
        }
        if (json['discriminatorRefName'] === 'RespondedCandidatePipelineStage') {
            return RespondedCandidatePipelineStageFromJSONTyped(json, true);
        }
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'stageType': HiringPipelineStageTypeFromJSON(json['stage_type']),
        'milestone': HiringPipelineStageMilestoneFromJSON(json['milestone']),
        'orderIndex': !exists(json, 'order_index') ? undefined : json['order_index'],
        'substage': !exists(json, 'substage') ? undefined : json['substage'],
    };
}

export function BaseCandidatePipelineStageToJSON(value?: BaseCandidatePipelineStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'stage_type': HiringPipelineStageTypeToJSON(value.stageType),
        'milestone': HiringPipelineStageMilestoneToJSON(value.milestone),
        'order_index': value.orderIndex,
        'substage': value.substage,
    };
}


