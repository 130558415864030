import { Box } from "@mui/material";
import React from "react";

import { BodyExtraSmall } from "components/library/typography";
import { colors } from "styles/theme";

export const InputErrorMessage = ({ show, message }: { show: boolean; message?: string }): React.ReactElement => {
  if (!show || !message) {
    return <></>;
  }

  return (
    <Box position="absolute" top={"calc(100% + 4px)"}>
      <BodyExtraSmall color={colors.critical.base}>{message}</BodyExtraSmall>
    </Box>
  );
};
