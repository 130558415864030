import { useListClientDomainsQuery } from "services/doverapi/endpoints/company-setup/endpoints";

export function useValidateDomain(email: string): boolean {
  const { data: clientDomains } = useListClientDomainsQuery({});

  if (!email || !clientDomains) {
    return true;
  }

  const domainIndex = email.lastIndexOf("@") + 1;

  if (domainIndex === 0) {
    return false;
  }
  const inputDomain = email.slice(domainIndex);

  return clientDomains.some(clientDomain => clientDomain.domain === inputDomain);
}
