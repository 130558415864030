import { Box } from "@mui/material";
import { useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { FC, useEffect } from "react";

import { TextField } from "components/library/TextField";
import { useDebounceState } from "hooks/useDebounceState";
import { nameFilterValAtom } from "views/candidates/ApplicationReview/atoms/filters";
import { pageAtom } from "views/candidates/ApplicationReview/atoms/pagination";

export const ApplicantSearchBar: FC = () => {
  const setNameFilterVal = useSetAtom(nameFilterValAtom);

  const [debouncedNameSearch, setNameSearch, nameSearch] = useDebounceState<string>("", 1000);
  const resetPage = useResetAtom(pageAtom);

  // Debounce the name updates before it hits jotai so that we don't recall the api too often
  useEffect(() => {
    setNameFilterVal(debouncedNameSearch);
    if (debouncedNameSearch) {
      // only reset the page once theyve started typing a name filter
      resetPage();
    }
  }, [debouncedNameSearch, resetPage, setNameFilterVal]);

  return (
    <Box p={0.5}>
      <TextField
        autoFocus
        fullWidth
        text={nameSearch}
        onTextUpdated={setNameSearch}
        placeholderText="Search candidate by name"
      />
    </Box>
  );
};
