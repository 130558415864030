import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";

import { useIsInViewport } from "hooks/useIsInViewport";
import { colors } from "styles/theme";
import { useJobNavOptions } from "views/job/hooks";

interface JobViewNavOptionsProps {
  showLeftScrollArrow: (show: boolean) => void;
  showRightScrollArrow: (show: boolean) => void;
  onScrollableRefMount: (node: HTMLDivElement) => void;
}

export const JobViewNavOptions = ({
  showLeftScrollArrow,
  showRightScrollArrow,
  onScrollableRefMount,
}: JobViewNavOptionsProps): React.ReactElement => {
  const navOptions = useJobNavOptions();

  const { jobId } = useParams<{ jobId: string | undefined }>();

  // Track the first and last nav options by ref so we can determine if they're in the viewport
  const firstNavOptionRef = React.useRef<HTMLAnchorElement>(null);
  const lastNavOptionRef = React.useRef<HTMLAnchorElement>(null);

  // When the first or last nav option is in the viewport, we'll hide the corresponding scroll button
  const isFirstNavOptionInViewport = useIsInViewport(firstNavOptionRef);
  const isLastNavOptionInViewport = useIsInViewport(lastNavOptionRef);
  useEffect(() => {
    showLeftScrollArrow(!isFirstNavOptionInViewport);
    showRightScrollArrow(!isLastNavOptionInViewport);
  }, [isFirstNavOptionInViewport, isLastNavOptionInViewport, showLeftScrollArrow, showRightScrollArrow]);

  // If jobId is not available, return nothing
  // It's the parents responsibility to handle this case and any loading or error states
  if (!jobId) {
    return <></>;
  }

  return (
    <Stack
      ref={onScrollableRefMount}
      direction="row"
      alignItems="end"
      justifyContent="start"
      spacing={1}
      paddingLeft="25px"
      paddingRight="10px"
      position="relative"
      sx={{
        overflowX: "auto",
        a: {
          boxSizing: "border-box",
          padding: "8px 12px 6px",
          fontSize: "14px",
          fontFamily: "Inter",
          fontWeight: 400,
          color: colors.grayscale.gray400,
          textDecoration: "none !important",
          whiteSpace: "nowrap",
          // used to avoid jumpiness due to border-box sizing
          // if we don't do this, the nav link becoming active adds a border and the text jumps up
          borderBottom: `2px solid ${colors.white}`,

          ":hover": {
            color: colors.grayscale.gray400,
            backgroundColor: colors.grayscale.gray100,
          },

          "&.job-nav-item-active": {
            fontWeight: 600,
            color: colors.grayscale.gray700,
            borderBottom: `2px solid ${colors.primary.base}}`,

            "&:hover": {
              color: colors.grayscale.gray700,
            },
          },
        },
      }}
    >
      {navOptions.map((option, index) => {
        const isFirstOption = index === 0;
        const isLastOption = index === navOptions.length - 1;
        return (
          <NavLink
            to={`/job/${jobId}/${option.path}`}
            className={({ isActive }): string | undefined => (isActive ? "job-nav-item-active" : undefined)}
            ref={isFirstOption ? firstNavOptionRef : isLastOption ? lastNavOptionRef : undefined}
          >
            {option.title}
          </NavLink>
        );
      })}
    </Stack>
  );
};
