import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { BillingSessionPathEnum } from "App/routing/types";
import arrows from "assets/logos/arrows.svg";
import { useBeginCheckoutMutation } from "services/doverapi/endpoints/billing";
import { StyledSVG } from "styles/layout";
import { enumFromStringValue } from "utils/stringToEnum";
import { redirectURL } from "views/billing/constants";
import { Paragraph } from "views/billing/styles";

const BeginCheckoutSessionRedirect = (): React.ReactElement => {
  const [beginCheckout, { data: checkoutInfo, isError }] = useBeginCheckoutMutation();
  const [redirectUrl] = useQueryParam(redirectURL, StringParam);
  const { billingSessionType: billingSessionTypeRaw } = useParams<{ billingSessionType: string | undefined }>();
  const sessionType = enumFromStringValue(BillingSessionPathEnum, billingSessionTypeRaw);
  // Initiate a checkout session upon component mount
  useEffect((): void => {
    const baseUrl = window.location.origin;
    let successUrl = `${baseUrl}/billing/session/success/${sessionType}/?stripeSessionId={CHECKOUT_SESSION_ID}`;
    if (redirectUrl) {
      successUrl += "&redirectUrl=" + encodeURIComponent(redirectUrl);
    }

    // Allow the user to cancel adding card info and return back to the current page.
    const cancelUrl = window.location.href;

    beginCheckout({ successUrl: successUrl, cancelUrl: cancelUrl });
  }, [redirectUrl, beginCheckout, sessionType]);

  // Once a checkout session has been created, redirect to the url that our backend provides us with.
  useEffect(() => {
    if (!checkoutInfo?.checkoutUrl) {
      return;
    }
    window.location.replace(checkoutInfo.checkoutUrl);
  }, [checkoutInfo?.checkoutUrl]);

  if (isError || (checkoutInfo && !checkoutInfo.checkoutUrl)) {
    return <div>Failed to begin checkout process.</div>;
  }
  if (!sessionType) {
    return <Navigate replace to="/notfound" />;
  }
  return (
    <>
      <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
        <Stack direction="column" alignItems="center" spacing={2}>
          <StyledSVG src={arrows} />
          <Paragraph>Taking you to set up your payment method...</Paragraph>
        </Stack>
      </Box>
    </>
  );
};

export default BeginCheckoutSessionRedirect;
