import styled from "styled-components";

export const Header = styled.div`
  font-family: "Nunito Sans";
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 40px;
`;

export const ChildrenContentWrapper = styled.div`
  margin: 0 40px;
`;

export const ExternalLink = styled.a`
  width: fit-content;
  cursor: pointer;
  color: #0087c0;
  text-decoration: none;

  :hover {
    color: #0087c0;
    text-decoration: underline;
  }
`;
