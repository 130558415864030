import { Stack } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Heading } from "components/library/typography";
import CustomModal from "components/Modal";

interface SaveAndProceedModalArgs {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  proceedFunction: () => void;
  saveSearchFunction: () => void;
  actionName: string;
}
export const SaveAndProceedModal = React.memo(
  ({
    isModalOpen,
    setIsModalOpen,
    proceedFunction,
    saveSearchFunction,
    actionName,
  }: SaveAndProceedModalArgs): React.ReactElement => {
    const returnToSearchesFunction = (): void => {
      setIsModalOpen(false);
    };

    const saveAndProceedFunction = async (): Promise<void> => {
      // this function first saves the search, then performs the second function call
      await saveSearchFunction();
      proceedFunction();
    };

    return (
      <CustomModal
        open={isModalOpen}
        dialogActionsStyles={{ padding: "8px 24px" }}
        customPaperProps={{ sx: { overflowY: "hidden" } }}
        customDialogStyles={{ zIndex: 10000 }}
        onClose={(): void => {
          setIsModalOpen(false);
        }}
        title={<Heading weight={"600"}>{`${actionName}?`}</Heading>}
        maxWidth={"md"}
      >
        <BodySmall>
          {"You have made changes to your search that have not been saved. How do you wish to proceed?"}
        </BodySmall>
        <Stack direction="row" justifyContent="flex-end" spacing={1} margin="10px">
          <Button variant={ButtonVariant.Primary} onClick={saveAndProceedFunction}>
            {`Save and ${actionName}`}
          </Button>
          <Button variant={ButtonVariant.Secondary} onClick={returnToSearchesFunction}>
            Return to Editing
          </Button>
        </Stack>
      </CustomModal>
    );
  }
);
