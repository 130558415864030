import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Box, Chip, Stack } from "@mui/material";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import BuildingIcon from "assets/icons/building-icon-grey.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { StyledListItem } from "components/dover/inputs/pro-users/styles";
import { ControlledAutocomplete, Autocomplete } from "components/library/Autocomplete";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, Overline, Subtitle2 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import RemoveButton from "components/RemoveButton";
import { useOpenApiClients } from "hooks/openApiClients";
import {
  useGetOrCreateKeywordWithNameMutation,
  useLazyListBucketChildrenAndAliasesQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { ProfileSearchKeywordSerializer } from "services/openapi";
import { colors } from "styles/theme";
import AdvancedAccordion from "views/sourcing/Search/components/AdvancedDropdown";
import FilterAccordion from "views/sourcing/Search/components/FilterAccordion";
import FilterSectionHeader from "views/sourcing/Search/components/FilterSectionHeader";
import { NewKeywordModal } from "views/sourcing/Search/components/NewKeywordModal";
import RequiredToggle from "views/sourcing/Search/components/RequiredToggle";
import {
  CHIP_MAX_WIDTH,
  FILTERS_WIDTH_INT,
  previewStateMarginBottom,
  previewStateMarginRight,
  DENIED_KEYWORDS_NAME,
} from "views/sourcing/Search/constants";
import { useGetSearchFromUrl } from "views/sourcing/Search/hooks";
import { KeywordsCollectionItem, SearchV3FormSchemaType } from "views/sourcing/Search/types";

const ALL_KEYWORDS_NAME = "keywordsBuckets";

const getKeywordsBucketName = (index: number): string => {
  return `keywordsBuckets.${index}.bucket`;
};

const getKeywordsBucketRequiredFieldName = (index: number): string => {
  return `keywordsBuckets.${index}.required`;
};

interface KeywordsBucketProps {
  keywordsCollection: KeywordsCollectionItem[];
  index: number;
}

// Shared components/functions for keywords/denied keywords

const fetchKeywordsInner = async (
  client: any,
  request: string,
  selectedChildren: string[]
): Promise<ProfileSearchKeywordSerializer[]> => {
  const keywords = await client.listProfileSearchKeywords({ limit: 50, queryText: request });
  const results = keywords.results;
  // want to separate keywords that will be auto added from those that will not
  const keywordsNotAutoAdded = results.filter(
    (keyword: ProfileSearchKeywordSerializer): boolean => !selectedChildren.includes(keyword.canonicalKeyword)
  );
  const keywordsAlreadyAdded: ProfileSearchKeywordSerializer[] = selectedChildren.map(child => ({
    id: uuidv4(),
    canonicalKeyword: child,
    friendlyName: child,
    aliases: [],
  }));
  // now sort the keywords by friendlyName
  const orderedKeywordsNotAutoAdded = sortBy(keywordsNotAutoAdded, "friendlyName");
  const orderedKeywordsAlreadyAdded = sortBy(keywordsAlreadyAdded, "friendlyName");
  return [...orderedKeywordsAlreadyAdded, ...orderedKeywordsNotAutoAdded];
};

const KeywordsBucket = React.memo(
  ({ keywordsCollection, index }: KeywordsBucketProps): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const [selectedChildren, setSelectedChildren] = useState<string[]>([]);
    const [currSearchString, setCurrSearchString] = useState<string>("");
    // need to keep track of this so we can pass it to the new Keywords modal
    const [newlyAddedKeyword, setNewlyAddedKeyword] = useState<ProfileSearchKeywordSerializer | undefined>(undefined);
    const [newKeywordModalOpen, setNewKeywordModalOpen] = useState<boolean>(false);
    const [newlyAddedKeywordChildren, setNewlyAddedKeywordChildren] = useState<string[] | undefined>(undefined);
    const [keywordsChildrenLoading, setKeywordsChildrenLoading] = useState<boolean>(false);

    const [getOrCreateKeywordWithName, { isLoading: isCreateKeywordLoading }] = useGetOrCreateKeywordWithNameMutation();

    const keywords: ProfileSearchKeywordSerializer[] = useWatch({
      control,
      name: getKeywordsBucketName(index) as any,
    });

    const [listBucketChildrenAndAliases] = useLazyListBucketChildrenAndAliasesQuery();

    const selectedCanonicalKeywords = React.useMemo(() => {
      return keywords.map(keyword => keyword.canonicalKeyword) ?? [];
    }, [keywords]);

    const client = useOpenApiClients()?.apiApi;

    // this useEffect keeps track of which keywords's children will be auto selected in the backend
    useEffect(() => {
      if (client === undefined) {
        return;
      }
      const setChildrenUsingListBucketChildrenAndAliases = async (): Promise<void> => {
        const results = await listBucketChildrenAndAliases(selectedCanonicalKeywords).unwrap();
        if (results) {
          setSelectedChildren(results);
        }
      };

      setChildrenUsingListBucketChildrenAndAliases();
    }, [client, keywords, listBucketChildrenAndAliases, selectedCanonicalKeywords]);

    const fetchKeywords = async (request: string): Promise<ProfileSearchKeywordSerializer[]> => {
      if (client === undefined) {
        return [];
      }

      return fetchKeywordsInner(client, request, selectedChildren);
    };

    const search = useGetSearchFromUrl();

    const getOrCreateKeyword = async (): Promise<void> => {
      if (!currSearchString.length || isCreateKeywordLoading) {
        return;
      }
      // call API to create keyword
      getOrCreateKeywordWithName(currSearchString)
        .unwrap()
        .then(newKeyword => {
          // add this keyword to the FormState via a SetValue
          onKeywordsSelectionChange([...keywords, newKeyword]);
          // clear the currSearchString
          setCurrSearchString("");
        });
    };

    // Keyword Modal helpers

    const onKeywordModalClose = React.useCallback((): void => {
      setNewKeywordModalOpen(false);
      setNewlyAddedKeywordChildren(undefined);
    }, []);

    const acceptKeyword = React.useCallback((): void => {
      setValue(getKeywordsBucketName(index) as any, [...keywords, newlyAddedKeyword]);
      onKeywordModalClose();
    }, [index, keywords, newlyAddedKeyword, onKeywordModalClose, setValue]);

    const onKeywordsSelectionChange = React.useCallback(
      async (newKeywords: ProfileSearchKeywordSerializer[]): Promise<void> => {
        // if a keyword is added, we want to check if it has children.
        // if it does, open the modal which prompts user to view and accept the addition of children keywords
        // if they accept via the prompt modal, we use acceptKeywords to add the children to the form state
        // if a keyword is deleted, just update the form state

        if (newKeywords.length > (keywords?.length ?? 0)) {
          const newKeyword = newKeywords[newKeywords.length - 1];
          setKeywordsChildrenLoading(true);
          const results = await listBucketChildrenAndAliases([newKeyword.canonicalKeyword]).unwrap();
          setKeywordsChildrenLoading(false);
          // if there are no children or if the only child/alias is the keyword itself, we can just add the keyword

          if (!results.length || (results.length == 1 && results[0] === newKeyword.canonicalKeyword)) {
            setValue(getKeywordsBucketName(index) as any, newKeywords);
          } else {
            setNewlyAddedKeyword(newKeyword);
            // otherwise prompt the user to check if they are happy with the children/aliases
            setNewlyAddedKeywordChildren(results);
            setNewKeywordModalOpen(true);
          }
        } else if (newKeywords.length < keywords.length) {
          // we are removing a keyword. no need to prompt any behaviour from user
          setValue(getKeywordsBucketName(index) as any, newKeywords);
        }
      },
      [index, keywords.length, listBucketChildrenAndAliases, setValue]
    );

    const deleteBucket = React.useCallback(
      (index: number) => {
        const newKeywordsCollection = [...keywordsCollection];
        newKeywordsCollection.splice(index, 1);
        setValue(ALL_KEYWORDS_NAME, newKeywordsCollection);
      },
      [keywordsCollection, setValue]
    );

    const onChipDelete = React.useCallback(
      (value: ProfileSearchKeywordSerializer, bucketNumber: number) => {
        const newKeywordsCollection = [...keywordsCollection];
        if (bucketNumber >= newKeywordsCollection.length) {
          console.error("Bucket number is out of bounds");
          return;
        }
        newKeywordsCollection[bucketNumber].bucket = newKeywordsCollection[bucketNumber].bucket.filter(
          (keyword: ProfileSearchKeywordSerializer) => keyword.id !== value.id
        );
        return setValue(ALL_KEYWORDS_NAME, newKeywordsCollection);
      },
      [keywordsCollection, setValue]
    );

    // final returns

    if (search === undefined) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <>
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          <Autocomplete
            fontSize="small"
            placeholder={"Enter a keyword..."}
            noOptionsText={
              !currSearchString.length ? "Start typing to see keywords" : "No results found. Press + button to add it."
            }
            fetch={fetchKeywords}
            setCurrSearchString={setCurrSearchString}
            filterSelectedOptions={true}
            getOptionLabel={(option: ProfileSearchKeywordSerializer): string => option.friendlyName}
            getOptionDisabled={(option: ProfileSearchKeywordSerializer): boolean =>
              selectedChildren.includes(option.canonicalKeyword)
            }
            filterOptions={(options: ProfileSearchKeywordSerializer[]): ProfileSearchKeywordSerializer[] => {
              return options.filter(
                (option: ProfileSearchKeywordSerializer) => !selectedCanonicalKeywords.includes(option.canonicalKeyword)
              );
            }}
            renderTags={(value: ProfileSearchKeywordSerializer[]): React.ReactElement => {
              return (
                <Stack alignItems="flex-start" spacing={1}>
                  {value.map((option: ProfileSearchKeywordSerializer, kwdIndex: number) => {
                    return (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Chip
                          label={
                            // 40% the length of the filters. just arbitrary number to not make it overflow past the X button
                            <TextWithMaxWidth label={option.friendlyName} width={`${0.4 * FILTERS_WIDTH_INT}px`} />
                          }
                          key={option.id}
                          onDelete={(): void => onChipDelete(option, index)}
                          deleteIcon={<HighlightOffSharpIcon />}
                        />
                        {kwdIndex < value.length - 1 && <Overline>OR</Overline>}
                      </Stack>
                    );
                  })}
                </Stack>
              );
            }}
            isOptionEqualToValue={(
              option: ProfileSearchKeywordSerializer,
              value: ProfileSearchKeywordSerializer
            ): boolean => option.canonicalKeyword === value.canonicalKeyword}
            onSelectedOptionsChange={onKeywordsSelectionChange}
            renderOption={(props, option: ProfileSearchKeywordSerializer): React.ReactElement => {
              // note: key prop must be declared last so that MUI's props.key (getOptionLabel) does
              // not override provided key
              if (selectedCanonicalKeywords.includes(option.canonicalKeyword)) {
                return (
                  // @ts-ignore
                  <StyledListItem {...props} key={option.canonicalKeyword}>
                    <Body>{`${option.canonicalKeyword} `}</Body>
                    <BodySmall>{", already added"}</BodySmall>
                  </StyledListItem>
                );
              }
              if (selectedChildren.includes(option.canonicalKeyword)) {
                return (
                  // @ts-ignore
                  <StyledListItem {...props} key={option.canonicalKeyword}>
                    <Body>{`${option.canonicalKeyword} `}</Body>
                    <BodySmall>{", will be auto added"}</BodySmall>
                  </StyledListItem>
                );
              }

              return (
                // @ts-ignore
                <StyledListItem {...props} key={option.canonicalKeyword}>
                  <Body>{option.canonicalKeyword}</Body>
                </StyledListItem>
              );
            }}
            renderInput={
              keywordsChildrenLoading
                ? (): React.ReactElement => {
                    return <DoverLoadingSpinner />;
                  }
                : undefined
            }
            initialValues={keywords ?? []}
            multiple={true}
          />
          <Stack spacing={1}>
            <Box onClick={getOrCreateKeyword} sx={{ cursor: "pointer" }}>
              <PlusIcon />
            </Box>
            <RemoveButton
              svgDimensions={"15px"}
              noPadding={true}
              color={colors.critical.hover}
              onClick={(): void => deleteBucket(index)}
            />
          </Stack>
        </Stack>
        <NewKeywordModal
          open={newKeywordModalOpen}
          onClose={onKeywordModalClose}
          newlyAddedKeyword={newlyAddedKeyword}
          newlyAddedKeywordChildren={newlyAddedKeywordChildren}
          acceptKeyword={acceptKeyword}
        />
      </>
    );
  }
);

const KeywordsTitleContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const keywordsCollection = useWatch({ control, name: ALL_KEYWORDS_NAME });

    const firstThreeRequiredKeywords = React.useMemo(() => {
      return keywordsCollection
        .filter(bucket => bucket.required)
        .map(bucket => bucket.bucket)
        .flat()
        .slice(0, 3);
    }, [keywordsCollection]);

    const firstThreeOptionalKeywords = React.useMemo(() => {
      return keywordsCollection
        .filter(bucket => !bucket.required)
        .map(bucket => bucket.bucket)
        .flat()
        .slice(0, 3);
    }, [keywordsCollection]);

    if ((firstThreeRequiredKeywords.length === 0 && firstThreeOptionalKeywords.length === 0) || expanded) {
      return <></>;
    }

    return (
      <Stack spacing={1}>
        {!!firstThreeRequiredKeywords.length && (
          <Box>
            <Box paddingBottom="5px">
              <Overline color={colors.grayscale.gray500}>Must haves</Overline>
            </Box>
            {firstThreeRequiredKeywords.map(keyword => {
              return (
                <Chip
                  label={<TextWithMaxWidth label={keyword.friendlyName} width={CHIP_MAX_WIDTH} />}
                  key={keyword.id}
                  deleteIcon={<HighlightOffSharpIcon />}
                  sx={{
                    mr: previewStateMarginRight,
                    mb: previewStateMarginBottom,
                  }}
                />
              );
            })}
          </Box>
        )}
        {!!firstThreeOptionalKeywords.length && (
          <Box>
            <Box paddingBottom="5px">
              <Overline color={colors.grayscale.gray500}>Nice to haves</Overline>
            </Box>
            {firstThreeOptionalKeywords.map(keyword => {
              return (
                <Chip
                  label={<TextWithMaxWidth label={keyword.friendlyName} width={CHIP_MAX_WIDTH} />}
                  key={keyword.id}
                  deleteIcon={<HighlightOffSharpIcon />}
                  sx={{
                    mr: previewStateMarginRight,
                    mb: previewStateMarginBottom,
                  }}
                />
              );
            })}
          </Box>
        )}
        <BodySmall color={colors.grayscale.gray400}>{"Expand section to see all keywords"}</BodySmall>
      </Stack>
    );
  }
);

const KeywordsBucketCollection = React.memo(
  ({ omitAdvanced = false }: { omitAdvanced?: boolean }): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const keywordsCollection = useWatch({ control, name: ALL_KEYWORDS_NAME });
    // add an empty bucket by default

    const addNewBucket = React.useCallback(() => {
      setValue(ALL_KEYWORDS_NAME, [...keywordsCollection, { required: false, bucket: [] }]);
    }, [keywordsCollection, setValue]);

    const numKeywords = keywordsCollection.length;

    return (
      <Stack spacing={2}>
        <Stack paddingBottom="0px" marginBottom="0px">
          {keywordsCollection.map((keywordBucket, index) => {
            return (
              <Stack alignItems="center">
                <Stack
                  alignItems="center"
                  spacing={2}
                  width={"100%"}
                  direction="row"
                  padding="8px"
                  key={index}
                  sx={{ backgroundColor: colors.grayscale.gray100 }}
                >
                  <Stack spacing={1} width="100%">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                      <Subtitle2 color={colors.grayscale.gray700} weight="600">
                        Any of
                      </Subtitle2>
                      <RequiredToggle
                        required={keywordBucket.required}
                        onChange={(required: boolean): void => {
                          setValue(getKeywordsBucketRequiredFieldName(index) as any, required);
                        }}
                      />
                    </Stack>
                    {/* <Stack direction="row" spacing={1} border="1px solid red" width="100%"> */}
                    <KeywordsBucket keywordsCollection={keywordsCollection} index={index} />
                    {/* </Stack> */}
                  </Stack>
                </Stack>
                {index < numKeywords - 1 && (
                  <Stack margin="10px">
                    <Overline color={colors.grayscale.gray500}>AND</Overline>
                  </Stack>
                )}
              </Stack>
            );
          })}
        </Stack>
        <Stack direction="row" justifyContent="flex-start">
          <Button variant={ButtonVariant.Ghost} removePadding={true} onClick={addNewBucket}>
            <BodySmall color={colors.linkLight}>{"+ Add"}</BodySmall>
          </Button>
        </Stack>
        {!omitAdvanced && <AdvancedAccordion title="Advanced" expandedContent={<DeniedKeywordsBucketCollection />} />}
      </Stack>
    );
  }
);

const DeniedKeywordsBucketCollection = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const deniedKeywords = useWatch({ control, name: DENIED_KEYWORDS_NAME });
    const [excludedChildren, setExcludedChildren] = useState<string[]>([]);
    const client = useOpenApiClients()?.apiApi;
    const [getOrCreateKeywordWithName, { isLoading: isCreateKeywordLoading }] = useGetOrCreateKeywordWithNameMutation();
    const [currSearchString, setCurrSearchString] = useState<string>("");

    const [listBucketChildrenAndAliases] = useLazyListBucketChildrenAndAliasesQuery();

    const excludedCanonicalKeywords = React.useMemo(() => {
      return deniedKeywords.map(keyword => keyword.canonicalKeyword);
    }, [deniedKeywords]);

    // this useEffect keeps track of which keywords's children will be auto excluded in the backend
    useEffect(() => {
      if (client === undefined) {
        return;
      }
      const setChildrenUsingListBucketChildrenAndAliases = async (): Promise<void> => {
        const results = await listBucketChildrenAndAliases(excludedCanonicalKeywords).unwrap();
        if (results) {
          setExcludedChildren(results);
        }
      };

      setChildrenUsingListBucketChildrenAndAliases();
    }, [client, excludedCanonicalKeywords, listBucketChildrenAndAliases]);

    const fetchKeywords = async (request: string): Promise<ProfileSearchKeywordSerializer[]> => {
      if (client === undefined) {
        return [];
      }

      return fetchKeywordsInner(client, request, excludedChildren);
    };

    const getOrCreateKeyword = async (): Promise<void> => {
      if (!currSearchString.length || isCreateKeywordLoading) {
        return;
      }
      // call API to create keyword
      getOrCreateKeywordWithName(currSearchString)
        .unwrap()
        .then(newKeyword => {
          // add this keyword to the FormState via a SetValue
          setValue(DENIED_KEYWORDS_NAME as any, [...deniedKeywords, newKeyword]);
          // clear the currSearchString
          setCurrSearchString("");
        });
    };

    const onChipDelete = React.useCallback(
      (value: ProfileSearchKeywordSerializer) => {
        const newDeniedKeywords = [...deniedKeywords].filter(
          (keyword: ProfileSearchKeywordSerializer) => keyword.id !== value.id
        );
        return setValue(DENIED_KEYWORDS_NAME, newDeniedKeywords);
      },
      [deniedKeywords, setValue]
    );

    return (
      <Stack>
        {/* Put the title outside of the autocomplete so the below + sign to add /getorcreate keywords can be aligned with autocomplete */}
        <Subtitle2 color={colors.grayscale.gray700} weight="600">
          Excluded Keywords
        </Subtitle2>
        <Stack direction="row" alignItems="center" spacing={1}>
          <ControlledAutocomplete
            control={control}
            fontSize="small"
            placeholder={"Enter a keyword..."}
            noOptionsText={
              !currSearchString.length ? "Start typing to see keywords" : "No results found. Press + button to add it."
            }
            fetch={fetchKeywords}
            setCurrSearchString={setCurrSearchString}
            name={DENIED_KEYWORDS_NAME}
            filterSelectedOptions={true}
            getOptionLabel={(option: ProfileSearchKeywordSerializer): string => option.friendlyName}
            initialValues={deniedKeywords ?? []}
            multiple={true}
            getOptionDisabled={(option: ProfileSearchKeywordSerializer): boolean =>
              excludedChildren.includes(option.canonicalKeyword)
            }
            renderTags={(value: ProfileSearchKeywordSerializer[]): React.ReactElement => {
              return (
                <Stack alignItems="flex-start" spacing={1}>
                  {value.map((option: ProfileSearchKeywordSerializer, kwdIndex: number) => {
                    return (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Chip
                          label={
                            // 40% the length of the filters. just arbitrary number to not make it overflow past the X button
                            <TextWithMaxWidth label={option.friendlyName} width={`${0.4 * FILTERS_WIDTH_INT}px`} />
                          }
                          key={option.id}
                          onDelete={(): void => onChipDelete(option)}
                          deleteIcon={<HighlightOffSharpIcon />}
                        />
                        {kwdIndex < value.length - 1 && <Overline>OR</Overline>}
                      </Stack>
                    );
                  })}
                </Stack>
              );
            }}
            renderOption={(props, option: ProfileSearchKeywordSerializer): React.ReactElement => {
              // note: key prop must be declared last so that MUI's props.key (getOptionLabel) does
              // not override provided key
              if (excludedCanonicalKeywords.includes(option.canonicalKeyword)) {
                return (
                  // @ts-ignore
                  <StyledListItem {...props} key={option.friendlyName}>
                    <Body>{`${option.canonicalKeyword} `}</Body>
                    <BodySmall>{", already excluded"}</BodySmall>
                  </StyledListItem>
                );
              }
              if (excludedChildren.includes(option.canonicalKeyword)) {
                return (
                  // @ts-ignore
                  <StyledListItem {...props} key={option.friendlyName}>
                    <Body>{`${option.canonicalKeyword} `}</Body>
                    <BodySmall>{", will be auto excluded"}</BodySmall>
                  </StyledListItem>
                );
              }

              return (
                // @ts-ignore
                <StyledListItem {...props} key={option.canonicalKeyword}>
                  <Body>{option.canonicalKeyword}</Body>
                </StyledListItem>
              );
            }}
          />
          <Box onClick={getOrCreateKeyword} sx={{ cursor: "pointer" }}>
            <PlusIcon />
          </Box>
        </Stack>
      </Stack>
    );
  }
);

const SkillsFilters = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const keywordsCollection = useWatch({ control, name: ALL_KEYWORDS_NAME });
    const noKeywordsChip = !keywordsCollection?.length || keywordsCollection.every(bucket => !bucket.bucket.length);
    return (
      <>
        <FilterSectionHeader title={"Skills"} icon={BuildingIcon} />
        <FilterAccordion
          title={noKeywordsChip ? "Keywords" : undefined}
          TitleContent={KeywordsTitleContent}
          expandedContent={<KeywordsBucketCollection />}
        />
      </>
    );
  }
);

export default SkillsFilters;
