import { AccountSettingsSectionType, CompanySetupSectionType } from "App/routing/types";
export const subDomainReg = RegExp(/^[a-zA-Z0-9][a-zA-Z0-9.-]*$/);

export interface CompanySetupSectionConfig {
  sideNavLabel: string;
  sectionLabel?: string;
  helpDeskArticle?: string;
  helpDeskArticleLabel?: string;
  description?: string;
}

export const SECTIONS_ONLY_FOR_PAID_CUSTOMERS = [
  CompanySetupSectionType.SOURCING_AUTOPILOT,
  CompanySetupSectionType.DOVER_INTERVIEWER,
  CompanySetupSectionType.ATS_SETTINGS,
  CompanySetupSectionType.API_KEY,
];

/**
 * @dev Do not use this full list to render the side nav. Use useGetCompanySectionConfigurationKeys instead (from hooks)
 */
export const companySectionConfiguration: Record<CompanySetupSectionType, CompanySetupSectionConfig> = {
  [CompanySetupSectionType.GENERAL]: {
    sideNavLabel: "General",
    sectionLabel: "General",
    description: "Update your company information.",
  },
  [CompanySetupSectionType.MEMBERS]: {
    sideNavLabel: "Members",
    sectionLabel: "Members and Permissions",
    helpDeskArticle: "https://help.dover.com/en/articles/8677668-user-permissions",
    helpDeskArticleLabel: "Learn more",
  },
  [CompanySetupSectionType.EMAIL_TEMPLATES]: {
    sideNavLabel: "Email Templates",
    sectionLabel: "Email Templates",
    description:
      "Create reusable email templates to save time when sending common interview communications like scheduling, updates, and rejections.",
  },
  [CompanySetupSectionType.FEEDBACK_FORMS]: {
    sideNavLabel: "Feedback Forms",
    // Label and Description are rendered in the FeedbackTemplates component for more layout control
  },
  [CompanySetupSectionType.ATS_SETTINGS]: {
    sideNavLabel: "ATS Settings",
    sectionLabel: "ATS Settings",
    description: "Integrate with your existing ATS or select Dover.",
  },
  [CompanySetupSectionType.INTEGRATIONS]: {
    sideNavLabel: "Integrations",
    sectionLabel: "Integrations",
    description: "Authenticate to integrate with these job boards.",
  },
  [CompanySetupSectionType.API_KEY]: {
    sideNavLabel: "API Key",
    sectionLabel: "API Key",
    description: "Integrate with your own tools to add candidates or see recent hires.",
  },
  [CompanySetupSectionType.SOURCING_AUTOPILOT]: {
    sideNavLabel: "Sourcing",
    sectionLabel: "Sourcing",
  },
  [CompanySetupSectionType.DOVER_INTERVIEWER]: {
    sideNavLabel: "Dover Interviewer",
    sectionLabel: "Dover Interviewer",
    description:
      "Dover uses this content to generate your outreach messaging and interact with candidates. The more information you provide, the better your results will be!",
  },
};

export const accountSettingsConfiguration: Record<AccountSettingsSectionType, CompanySetupSectionConfig> = {
  [AccountSettingsSectionType.INTERVIEW_SCHEDULING]: {
    sideNavLabel: "Interview Scheduling",
    sectionLabel: "Interview Scheduling",
    description: "Control how and when candidates can schedule on your calendar.",
  },
  [AccountSettingsSectionType.EMAIL_CONNECTION]: {
    sideNavLabel: "Email Connection",
    sectionLabel: "Connect your email",
    description:
      "Link Dover to your Gmail account to send emails to candidates. Remember to auth the email you're logged in with!",
    helpDeskArticle: "https://help.dover.com/en/articles/6480907-when-does-dover-use-my-work-email",
    helpDeskArticleLabel: "Learn more",
  },
  [AccountSettingsSectionType.NOTIFICATIONS]: {
    sideNavLabel: "Notifications",
    sectionLabel: "Notifications",
    description: "Set your notification preferences for each job.",
  },
};
