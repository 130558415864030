/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    candidateEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    companyEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    conversationId?: string;
}

export function InlineObject6FromJSON(json: any): InlineObject6 {
    return InlineObject6FromJSONTyped(json, false);
}

export function InlineObject6FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject6 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateEmail': json['candidate_email'],
        'companyEmail': json['company_email'],
        'conversationId': !exists(json, 'conversation_id') ? undefined : json['conversation_id'],
    };
}

export function InlineObject6ToJSON(value?: InlineObject6 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_email': value.candidateEmail,
        'company_email': value.companyEmail,
        'conversation_id': value.conversationId,
    };
}


