import { useMemo, useEffect, useState, RefObject } from "react";

export function useIsInViewport<T extends HTMLElement>(ref: RefObject<T>): boolean {
  /* Used to determine if a component is visible on screen.

  Inspired by: https://bobbyhadz.com/blog/react-check-if-element-in-viewport
   */

  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      }),
    []
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    observer.observe(ref.current);

    return (): void => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
