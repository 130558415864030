import React from "react";

import RangeSlider from "components/data-driven-forms/fields/RangeSlider";
import TileSelect from "components/data-driven-forms/fields/TileSelect";

export default props => {
  // Route between standard RangeSlider or special case TileSelect which is
  // is used for the scoring questions
  if (props.marks[0].label.toLowerCase().trim() === "don't care") {
    return <TileSelect scoringSelection options={props.marks} {...props} />;
  } else return <RangeSlider {...props} />;
};
