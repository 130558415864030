import { Box, Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

import CreateJobForm from "components/dover/CreateJob";
import { Body } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { filters } from "styles/theme";
import FailureMessage from "views/create-job/CreateJob/components/FailureMessage";
import { MAX_CONTENT_WIDTH } from "views/create-job/ExtensionJobCreationFlow/constants";
import { useExtensionCreateJobContext } from "views/create-job/ExtensionJobCreationFlow/context";
import { WhiteCard } from "views/create-job/ExtensionJobCreationFlow/styles";

const Container = styled(WhiteCard)`
  max-width: ${(): any => MAX_CONTENT_WIDTH}px;
  width: 100%;
`;

const CreatingJobCard = (): React.ReactElement => {
  return (
    <WhiteCard>
      <Stack width={`${MAX_CONTENT_WIDTH}px`} justifyContent="center">
        <DoverLoadingSpinner active={true} filter={filters.filterDarkGreen} />
        <Box textAlign="center">
          <Body>Loading... this will take a few seconds</Body>
        </Box>
      </Stack>
    </WhiteCard>
  );
};

const CreateJob = (): React.ReactElement => {
  const { onSubmitCreateJob, creatingJob, createJobError } = useExtensionCreateJobContext();

  if (creatingJob) {
    return <CreatingJobCard />;
  }
  return (
    <Container>
      {createJobError ? (
        <FailureMessage failureMessage={createJobError} />
      ) : (
        <CreateJobForm
          showHiringManagerAndRecruiterFields={false}
          initialTitle={""}
          onSubmit={onSubmitCreateJob}
          creating={creatingJob}
          justifyHeaderCenter
          hideBackButton
        />
      )}
    </Container>
  );
};

export default CreateJob;
