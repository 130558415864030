import { Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom } from "jotai";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { CompanySetupSectionType } from "App/routing/types";
import {
  EmailTemplateDrawerAction,
  EmailTemplateDrawerConfigAtom,
  ToggleDeleteTemplateConfirmationModalAtom,
} from "components/dover/EmailTemplates/atoms";
import { EmailTemplateDrawerAtom } from "components/dover/EmailTemplates/EmailTemplateDrawer";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, ButtonText, Overline, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { useModal } from "GlobalOverlays/atoms";
import {
  useDeleteClientEmailTemplateMutation,
  useListClientEmailTemplatesQuery,
} from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { ClientEmailTemplate } from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";
import {
  AssignedJobsCell,
  CreatedByCell,
  NameCell,
  ActionsCell,
} from "views/CompanySetup/components/EmailTemplates/components/TemplateTableCells";
import { EMAIL_TEMPLATES_PAGE_LIMIT } from "views/CompanySetup/components/EmailTemplates/constants";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";

const GridWrapper = styled.div`
  .MuiDataGrid-columnsContainer {
    background: ${colors.grayscale.gray100};
  }
  .h-cell {
    background: ${colors.grayscale.gray100};
  }
  .MuiDataGrid-row {
    cursor: pointer;
  }
`;

const EmailTemplatesTableColumns: GridEnrichedColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <NameCell {...params} />,
  },
  {
    field: "assignedJobs",
    headerName: "Assigned Jobs",
    minWidth: 120,
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <AssignedJobsCell {...params} />,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <CreatedByCell {...params} />,
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    minWidth: 150,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <ActionsCell {...params} />,
  },
];

export const EmailTemplatesTable = (): React.ReactElement => {
  // state & misc
  const [tablePage, setTablePage] = React.useState(0);
  const clientId = useGetClientId();

  // atoms
  const [emailTemplateDrawerConfig, setEmailTemplateDrawerConfig] = useAtom(EmailTemplateDrawerConfigAtom);
  const { open: openEmailTemplateDrawer } = useModal(EmailTemplateDrawerAtom);
  const [deleteConfirmationModalOpen, toggleDeleteConfirmationModal] = useAtom(
    ToggleDeleteTemplateConfirmationModalAtom
  );

  // RTK
  const {
    currentData: { results: emailTemplates, count: templateCount } = {},
    isFetching: isFetchingTemplates,
  } = useListClientEmailTemplatesQuery(
    clientId ? { offset: tablePage * EMAIL_TEMPLATES_PAGE_LIMIT, limit: EMAIL_TEMPLATES_PAGE_LIMIT } : skipToken
  );
  const [deleteClientEmailTemplate, { isLoading: isDeletingTemplate }] = useDeleteClientEmailTemplateMutation();

  // callbacks
  const onEdit = (emailTemplate: ClientEmailTemplate): void => {
    setEmailTemplateDrawerConfig({
      template: emailTemplate,
      action: EmailTemplateDrawerAction.Update,
    });
    openEmailTemplateDrawer({});
  };

  const onDelete = async (): Promise<void> => {
    if (!emailTemplateDrawerConfig?.template?.id) {
      return;
    }

    const deleteTemplateArgs = {
      id: emailTemplateDrawerConfig.template.id,
    };

    const deleteTemplatePromise = deleteClientEmailTemplate(deleteTemplateArgs).unwrap();
    try {
      await toast.promise(
        deleteTemplatePromise,
        {
          pending: "Deleting template..",
          error: "Error deleting template",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
    } finally {
      setEmailTemplateDrawerConfig({
        template: undefined,
        action: undefined,
      });
      toggleDeleteConfirmationModal(false);
    }
    return;
  };

  return (
    <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.EMAIL_TEMPLATES]}>
      {/* Confirmation modal for deleting templates */}
      <CustomModal
        open={deleteConfirmationModalOpen}
        title={<Subtitle2>Delete template?</Subtitle2>}
        onClose={(): void => toggleDeleteConfirmationModal(false)}
        maxWidth="sm"
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button onClick={(): void => toggleDeleteConfirmationModal(false)} variant={ButtonVariant.Secondary}>
              <ButtonText>Cancel</ButtonText>
            </Button>
            <Button onClick={onDelete} variant={ButtonVariant.Critical} disabled={isDeletingTemplate}>
              <ButtonText color={colors.white}>Remove</ButtonText>
            </Button>
          </Stack>
        }
      >
        <Body>This action can not be undone.</Body>
      </CustomModal>
      {/* Templates table */}
      <GridWrapper>
        <DataGrid
          loading={isFetchingTemplates}
          autoHeight
          headerHeight={40}
          rowHeight={64}
          rows={emailTemplates ?? []}
          rowCount={templateCount}
          columns={EmailTemplatesTableColumns}
          pagination
          paginationMode="server"
          page={tablePage}
          pageSize={EMAIL_TEMPLATES_PAGE_LIMIT}
          onPageChange={setTablePage}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          localeText={{ noRowsLabel: "Click '+New' to create a new email template" }}
          onRowClick={(params: GridRowParams): void => {
            onEdit(params.row as ClientEmailTemplate);
          }}
        />
      </GridWrapper>
    </SectionWrapper>
  );
};
