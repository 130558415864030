/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelatedJobReferral
 */
export interface RelatedJobReferral {
    /**
     * 
     * @type {string}
     * @memberof RelatedJobReferral
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedJobReferral
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedJobReferral
     */
    triagingStatus: RelatedJobReferralTriagingStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum RelatedJobReferralTriagingStatusEnum {
    NeedsReview = 'NEEDS_REVIEW',
    NeedsManualOutreach = 'NEEDS_MANUAL_OUTREACH',
    Contacted = 'CONTACTED',
    Rejected = 'REJECTED'
}

export function RelatedJobReferralFromJSON(json: any): RelatedJobReferral {
    return RelatedJobReferralFromJSONTyped(json, false);
}

export function RelatedJobReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedJobReferral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'jobTitle': json['job_title'],
        'triagingStatus': json['triaging_status'],
    };
}

export function RelatedJobReferralToJSON(value?: RelatedJobReferral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'job_title': value.jobTitle,
        'triaging_status': value.triagingStatus,
    };
}


