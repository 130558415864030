import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { JobFeatureFeatureNameEnum } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";

export enum SchedulingConfigType {
  ReplyTo = "REPLY_TO_SCHEDULING",
  DoverManaged = "DOVER_MANAGED",
  UserManaged = "USER_MANAGED",
}

export const useGetSchedulingConfigTypeForJob = (jobId?: string | null): SchedulingConfigType | undefined => {
  const { user } = useAuth0();
  const { data: client } = useGetUsersClientQuery(user ? undefined : skipToken);
  const { data: job } = useGetJobQuery(jobId ? jobId : skipToken);

  /** Dover Managed Case: JobFeatureSetting for E2E Scheduling is Enabled */
  const isJobE2eEnabled = !!useGetIsFeatureEnabled({
    jobId: jobId ?? undefined,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  if (isJobE2eEnabled) {
    return SchedulingConfigType.DoverManaged;
  }

  /** Reply-To Cases:
   * 1. Job.useReplyToSchedulingOverride is True
   * 2. Client.useReplyToSchedulingOverride is True
   * 3. Client.emailProvider is not Gmail
   */
  const jobUseReplyToSchedulingOverride = job?.useReplyToSchedulingOverride;
  const clientUseReplyToSchedulingOverride = client?.useReplyToSchedulingOverride;

  if (jobUseReplyToSchedulingOverride || clientUseReplyToSchedulingOverride) {
    return SchedulingConfigType.ReplyTo;
  }

  /** User Managed Cases: All other cases */
  return SchedulingConfigType.UserManaged;
};

export const useReplyToSchedulingConfig = (jobId?: string | null): boolean => {
  const schedulingConfigType = useGetSchedulingConfigTypeForJob(jobId);
  return schedulingConfigType === SchedulingConfigType.ReplyTo;
};

export const useDoverManagedSchedulingConfig = (jobId?: string | null): boolean => {
  const schedulingConfigType = useGetSchedulingConfigTypeForJob(jobId);
  return schedulingConfigType === SchedulingConfigType.DoverManaged;
};

export const useUserManagedSchedulingConfig = (jobId?: string | null): boolean => {
  const schedulingConfigType = useGetSchedulingConfigTypeForJob(jobId);
  return schedulingConfigType === SchedulingConfigType.UserManaged;
};
