import { CompanySetupStepConstants } from "services/doverapi/endpoints/company-setup/types";
import { CompanySetupStepStepNameEnum } from "services/openapi";

export const COMPANY_SETUP_STEP_CONFIGS: Readonly<
  { [key in CompanySetupStepStepNameEnum]: CompanySetupStepConstants }
> = {
  [CompanySetupStepStepNameEnum.BasicInfoState]: {
    path: "basic-info",
    label: "Basic Info",
    stepName: CompanySetupStepStepNameEnum.BasicInfoState,
  },
  [CompanySetupStepStepNameEnum.PitchState]: {
    path: "biography",
    label: "Company Pitch",
    stepName: CompanySetupStepStepNameEnum.PitchState,
  },
  [CompanySetupStepStepNameEnum.CareersPageState]: {
    path: "careers-page-company-info",
    label: "Careers Page",
    stepName: CompanySetupStepStepNameEnum.CareersPageState,
  },
  [CompanySetupStepStepNameEnum.ExclusionsState]: {
    path: "exclusions",
    label: "Exclusions",
    stepName: CompanySetupStepStepNameEnum.ExclusionsState,
  },
  [CompanySetupStepStepNameEnum.AtsState]: {
    path: "ats-setting",
    label: "ATS",
    stepName: CompanySetupStepStepNameEnum.AtsState,
  },
  [CompanySetupStepStepNameEnum.BrandingState]: {
    path: "branding",
    label: "Branding",
    stepName: CompanySetupStepStepNameEnum.BrandingState,
  },
  [CompanySetupStepStepNameEnum.CredentialsState]: {
    path: "credentials",
    label: "Credentials",
    stepName: CompanySetupStepStepNameEnum.CredentialsState,
  },
};

export const COMPANY_LOGO_URL_SENTINEL = "SENTINEL.com/SENTINEL_LOGO.png";
