import { MenuItem } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useFormState } from "react-final-form";

import { BodySmall } from "components/library/typography";
import { useListSchedulableCalendarsQuery } from "services/doverapi/endpoints/interviewPlan";
import { SchedulableCalendar } from "services/openapi";
import { colors } from "styles/theme";
import { StyledSelect } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/EndToEndJobConfig/styles";
import { removeDoverInterviewerCalendars } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/utils";

export const SchedulingCalendarSelect = ({
  jobId,
  handleSelectSchedulableCalendar,
}: {
  jobId: string;
  handleSelectSchedulableCalendar: (event: React.ChangeEvent<HTMLInputElement>) => void;
}): React.ReactElement => {
  const formState = useFormState();
  const schedulingCalendarGcalId = formState.values.schedulingCalendarGcalId;
  const clientSchedulingEmail = formState.values.clientSchedulingEmail;
  const { schedulableCalendars } = useListSchedulableCalendarsQuery(jobId, {
    selectFromResult: ({ data }) => {
      const schedulableCalendars: SchedulableCalendar[] = removeDoverInterviewerCalendars(data);
      return {
        schedulableCalendars,
      };
    },
  });

  const defaultSchedulingCal = useMemo(() => {
    /* The primary calendar's id should match the email address (thus being equal to the email address
      on the designated client's scheduling EmailSenderAlias) */
    const primaryCal = schedulableCalendars.find(c => c.id === clientSchedulingEmail);
    return primaryCal?.id;
  }, [clientSchedulingEmail, schedulableCalendars]);

  const schedulableCalendarsWithoutDefault = useMemo(() => {
    return schedulableCalendars?.filter(c => c.id !== defaultSchedulingCal);
  }, [schedulableCalendars, defaultSchedulingCal]);

  const initialCalendar = useMemo(() => {
    return schedulingCalendarGcalId || defaultSchedulingCal;
  }, [schedulingCalendarGcalId, defaultSchedulingCal]);

  const [selectedCalendar, setSelectedCalendar] = useState<string | undefined>(initialCalendar);

  const selectSchedulableCalendar = (event: React.ChangeEvent<HTMLInputElement>): void => {
    handleSelectSchedulableCalendar(event);
    if (event.target.value) {
      setSelectedCalendar(event.target.value);
    }
  };

  return (
    <StyledSelect
      variant="outlined"
      value={selectedCalendar}
      /* @ts-ignore */
      onChange={selectSchedulableCalendar}
    >
      {schedulableCalendarsWithoutDefault &&
        schedulableCalendarsWithoutDefault.map((calendar: SchedulableCalendar, index: number) => {
          return (
            <MenuItem key={index} value={calendar.id}>
              <BodySmall color={colors.black}>{calendar.name}</BodySmall>
            </MenuItem>
          );
        })}
    </StyledSelect>
  );
};
