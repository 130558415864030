import { Box, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { ChangeEvent } from "react";

import { ReactComponent as DownCaretSVG } from "assets/icons/caret-down-black.svg";
import { BodySmall } from "components/library/typography";
import { DashboardJob } from "services/openapi";
import { colors } from "styles/theme";

const DownArrowIcon = (
  <Box display="flex" m="auto">
    <DownCaretSVG width="30px" />
  </Box>
);

const JobSelector = ({
  jobs,
  jobId,
  setJobId,
  disabled,
}: {
  jobs: DashboardJob[];
  jobId?: string;
  setJobId: (job: string) => void;
  disabled?: boolean;
}): React.ReactElement => {
  const selectedJob = React.useMemo(() => {
    return jobs.find(j => j.id === jobId);
  }, [jobs, jobId]);

  return (
    <Autocomplete
      disabled={disabled}
      size="small"
      popupIcon={DownArrowIcon}
      options={jobs}
      sx={{
        backgroundColor: colors.white,
        ".MuiAutocomplete-inputRoot": { height: "40px" },
        ".MuiOutlinedInput-root ": { border: "none" },
        ".MuiAutocomplete-endAdornment": { top: "auto" },
      }} // This is to fix a mui v4 issue
      getOptionLabel={(option: DashboardJob): string => option.title || ""}
      renderOption={(props, option: DashboardJob): React.ReactElement => {
        return (
          <li {...props} key={option.id}>
            <BodySmall>{option.title || ""}</BodySmall>
          </li>
        );
      }}
      value={selectedJob}
      onChange={(_event: ChangeEvent<{}>, newValue: unknown): void => {
        const job = newValue as DashboardJob;
        setJobId(job?.id ?? "");
      }}
      renderInput={(params): React.ReactElement => (
        <TextField
          {...params}
          sx={{ ".MuiOutlinedInput-root": { fontSize: "14px", lineHeight: "20px" } }} // This is to match our BodySmall font, until we better integrate with MUI theme I think we have to duplicate this
          placeholder="Select Job"
        />
      )}
    />
  );
};

export default JobSelector;
