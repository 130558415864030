/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LeadSubstageEnum {
    BASE = 0
}

export function LeadSubstageEnumFromJSON(json: any): LeadSubstageEnum {
    return LeadSubstageEnumFromJSONTyped(json, false);
}

export function LeadSubstageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadSubstageEnum {
    return json as LeadSubstageEnum;
}

export function LeadSubstageEnumToJSON(value?: LeadSubstageEnum | null): any {
    return value as any;
}

