/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoverInterviewerPreview
 */
export interface DoverInterviewerPreview {
    /**
     * 
     * @type {string}
     * @memberof DoverInterviewerPreview
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverInterviewerPreview
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverInterviewerPreview
     */
    readonly photo?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverInterviewerPreview
     */
    quote?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverInterviewerPreview
     */
    location?: string;
    /**
     * 
     * @type {object}
     * @memberof DoverInterviewerPreview
     */
    interviewsCompletedByAggregateRoleKey: object;
    /**
     * 
     * @type {string}
     * @memberof DoverInterviewerPreview
     */
    globalSchedulingLink: string;
}

export function DoverInterviewerPreviewFromJSON(json: any): DoverInterviewerPreview {
    return DoverInterviewerPreviewFromJSONTyped(json, false);
}

export function DoverInterviewerPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoverInterviewerPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'quote': !exists(json, 'quote') ? undefined : json['quote'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'interviewsCompletedByAggregateRoleKey': json['interviews_completed_by_aggregate_role_key'],
        'globalSchedulingLink': json['global_scheduling_link'],
    };
}

export function DoverInterviewerPreviewToJSON(value?: DoverInterviewerPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quote': value.quote,
        'location': value.location,
        'interviews_completed_by_aggregate_role_key': value.interviewsCompletedByAggregateRoleKey,
        'global_scheduling_link': value.globalSchedulingLink,
    };
}


