import { createSelector, Selector } from "@reduxjs/toolkit";

import { userFilesReducerKey, initialState } from "domains/files/reducer";
import { UserFilesReducerState } from "domains/files/types";
import { GlobalRootState } from "domains/global/types";

const selectState = (state: GlobalRootState): UserFilesReducerState => state[userFilesReducerKey] || initialState;

export const selectError = (errorKey: string): Selector<GlobalRootState, string> =>
  createSelector([selectState], state => state.errorMap[errorKey]);
