/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseFeedbackTemplateQuestion,
    BaseFeedbackTemplateQuestionFromJSON,
    BaseFeedbackTemplateQuestionFromJSONTyped,
    BaseFeedbackTemplateQuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewRubricTemplate
 */
export interface InterviewRubricTemplate {
    /**
     * 
     * @type {string}
     * @memberof InterviewRubricTemplate
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewRubricTemplate
     */
    readonly name?: string;
    /**
     * 
     * @type {object}
     * @memberof InterviewRubricTemplate
     */
    readonly compiledFormSchema?: object;
    /**
     * 
     * @type {Array<BaseFeedbackTemplateQuestion>}
     * @memberof InterviewRubricTemplate
     */
    readonly userDefinedQuestions?: Array<BaseFeedbackTemplateQuestion>;
}

export function InterviewRubricTemplateFromJSON(json: any): InterviewRubricTemplate {
    return InterviewRubricTemplateFromJSONTyped(json, false);
}

export function InterviewRubricTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewRubricTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'compiledFormSchema': !exists(json, 'compiled_form_schema') ? undefined : json['compiled_form_schema'],
        'userDefinedQuestions': !exists(json, 'user_defined_questions') ? undefined : ((json['user_defined_questions'] as Array<any>).map(BaseFeedbackTemplateQuestionFromJSON)),
    };
}

export function InterviewRubricTemplateToJSON(value?: InterviewRubricTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


