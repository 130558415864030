import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useParams } from "react-router-dom";

import {
  FEATURE_NAME_TO_ACTIVATION_MODAL_MAP,
  FEATURE_NAME_TO_DEACTIVATION_MODAL_MAP,
} from "components/dover/feature-toggle-handler/constants";
import {
  JobFeatureSettingToggleHandlerContext,
  OnFeatureToggledParams,
} from "components/dover/feature-toggle-handler/types";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { SERVICE_FEATURE_NAMES } from "services/doverapi/endpoints/job/constants";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import {
  ClientDoverPlanEnum,
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";

interface ActivationModalState {
  featureName: UpsertJobFeatureSettingFeatureNameEnum;
  desiredFeatureState: UpsertJobFeatureSettingStateEnum;
  additionalInfoArgumentsForModalText?: string[];
  additionalCallback?: () => void;
}

const JobFeatureToggleHandler: React.FC<{}> = ({ children }) => {
  const { jobId } = useParams<{ jobId: string }>();

  const doverPlan = useGetDoverPlan();
  const isCreditsCustomer = doverPlan === ClientDoverPlanEnum.Credits;
  const planIsFreeOrPaygo = doverPlan === ClientDoverPlanEnum.Free || doverPlan === ClientDoverPlanEnum.PayAsYouGo;

  const [activationModalState, setActivationModalState] = React.useState<ActivationModalState | undefined>(undefined);
  const [isAnyActivationModalOpen, setIsAnyActivationModalOpen] = React.useState<boolean>(false);
  const { jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
      };
    },
  });

  // Useful for tracking whether or not to show ConvertToPaidJobModal
  const isAnyServiceEnabled = React.useMemo(() => {
    return (
      !!jobFeatures &&
      (jobFeatures[JobFeatureFeatureNameEnum.DoverInterviewer] ||
        jobFeatures[JobFeatureFeatureNameEnum.ManagedOutbound] ||
        jobFeatures[JobFeatureFeatureNameEnum.E2EScheduling])
    );
  }, [jobFeatures]);

  // We might toggle a confirmation modal on if we're trying to enable a service for the first time
  const maybeToggleConfirmPaidModalOn = React.useCallback(
    (featureName: JobFeatureFeatureNameEnum, callback?: () => void) => {
      // If this is already a paid job, or we're not enabling a service, or the user is on a free plan or paygo plan,
      // do nothing
      if (
        !SERVICE_FEATURE_NAMES.includes(featureName) ||
        isAnyServiceEnabled ||
        isCreditsCustomer ||
        planIsFreeOrPaygo
      ) {
        callback?.();
        return;
      }
    },
    [isAnyServiceEnabled, isCreditsCustomer, planIsFreeOrPaygo]
  );

  const onFeatureToggledInner = React.useCallback(
    ({
      featureName,
      desiredFeatureState,
      additionalInfoArgumentsForModalText,
      additionalCallback,
    }: OnFeatureToggledParams) => {
      setActivationModalState({
        featureName,
        desiredFeatureState,
        additionalInfoArgumentsForModalText,
        additionalCallback,
      });
      setIsAnyActivationModalOpen(true);
    },
    []
  );

  const onFeatureToggled = React.useCallback(
    ({
      featureName,
      desiredFeatureState,
      additionalInfoArgumentsForModalText,
      additionalCallback,
    }: OnFeatureToggledParams) => {
      const callback = (): void => {
        onFeatureToggledInner({
          featureName,
          desiredFeatureState,
          additionalInfoArgumentsForModalText,
          additionalCallback,
        });
      };

      if (desiredFeatureState === UpsertJobFeatureSettingStateEnum.Enabled) {
        maybeToggleConfirmPaidModalOn((featureName as unknown) as JobFeatureFeatureNameEnum, callback);
        return;
      }

      callback();
    },
    [maybeToggleConfirmPaidModalOn, onFeatureToggledInner]
  );

  const toggleActivationModalOff = React.useCallback(() => {
    setActivationModalState(undefined);
    setIsAnyActivationModalOpen(false);
  }, []);

  const activationModal = React.useMemo(() => {
    if (activationModalState === undefined) {
      return undefined;
    }

    const ModalFromMapper =
      activationModalState.desiredFeatureState === UpsertJobFeatureSettingStateEnum.Enabled
        ? FEATURE_NAME_TO_ACTIVATION_MODAL_MAP[activationModalState.featureName]
        : FEATURE_NAME_TO_DEACTIVATION_MODAL_MAP[activationModalState.featureName];

    if (!ModalFromMapper) {
      return undefined;
    }

    return (
      <ModalFromMapper
        toggleModalOff={toggleActivationModalOff}
        isModalOpen={isAnyActivationModalOpen}
        additionalCallback={activationModalState.additionalCallback}
        additionalInfoArgumentsForModalText={activationModalState.additionalInfoArgumentsForModalText}
      />
    );
  }, [isAnyActivationModalOpen, activationModalState, toggleActivationModalOff]);

  React.useEffect(() => {
    if (activationModal === undefined) {
      setIsAnyActivationModalOpen(false);
    }
  }, [activationModal]);

  return (
    <>
      <JobFeatureSettingToggleHandlerContext.Provider
        value={{
          onFeatureToggled: onFeatureToggled,
        }}
      >
        {children}
      </JobFeatureSettingToggleHandlerContext.Provider>
      {activationModal !== undefined && activationModal}
    </>
  );
};

export default JobFeatureToggleHandler;
