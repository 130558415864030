import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useController, useForm } from "react-hook-form";
import styled from "styled-components";
import { z } from "zod";

import { ReactComponent as JobBoardManagerSVG } from "assets/icons/job-board-manager.svg";
import { TitlesAutocomplete } from "components/dover/TitlesAutocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import { Body, BodySmall, PageTitle } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { useCreateJobMutation } from "services/doverapi/endpoints/create-job/endpoints";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import {
  useGenerateJobDescriptionMutation,
  useGetJobDescriptionQuery,
  useUpdateJobDescriptionMutation,
} from "services/doverapi/endpoints/job-description/endpoints";
import { colors, screenSizesNumbers } from "styles/theme";
import { MobilePreviewToggle } from "views/create-job/AtsSetupFlow/components/MobilePreviewToggle";
import { STEP_FLOW_PAGE_CONTAINER_HEIGHT } from "views/create-job/constants";
import CreateJobDescription from "views/create-job/shared-steps/CreateJobDescription";
import { CareersPagePreviewContainer, PreviewContainer } from "views/create-job/styles";
import InboundApplicationPortalWrapper from "views/inboundExternal/InboundApplication";

/* -----------------------------------------------------------------------------
 * React Hook Form Setup
 * -------------------------------------------------------------------------- */

const SlimCreateJobSchema = z.object({
  persona: z
    .number()
    .int()
    .positive(),
  title: z.string(),
});

type SlimCreateJobSchemaFormType = z.infer<typeof SlimCreateJobSchema>;

const initialFormValues: SlimCreateJobSchemaFormType = {
  persona: 0,
  title: "",
};

const JobDescriptionPagePreview = ({ show }: { show: boolean }): React.ReactElement => {
  // TODO:
  // maybe it makes sense to NOT make the showDesktopPreview reliant on isSuccess value and instead
  // reference the loading state of those mutations and use that here to show that a preview is coming
  // skeleton component would probably look better than the overlay
  const [, { isLoading: isUpdatingJobDescription }] = useUpdateJobDescriptionMutation({
    fixedCacheKey: "updateJobDescription",
  });
  return (
    <AnimatedPreviewContainer className={show ? "show" : ""}>
      <PreviewContainer spacing={2} alignItems="center" sx={{ width: "100% !important" }}>
        <PageTitle>Preview</PageTitle>
        <CareersPagePreviewContainer>
          <DoverLoadingOverlay active={isUpdatingJobDescription}>
            <Box style={{ pointerEvents: "none" }}>
              <InboundApplicationPortalWrapper showGeneratedDescription />
            </Box>
          </DoverLoadingOverlay>
        </CareersPagePreviewContainer>
      </PreviewContainer>
    </AnimatedPreviewContainer>
  );
};

interface JobDescriptionStepProps {
  hideJdPreview?: boolean;
  jobId?: string;
  onJobCreationSuccess?: (jobId: string) => void;
  onNext: () => Promise<void>;
}

const JobDescriptionStep = ({
  hideJdPreview = false,
  jobId: jobIdProp,
  onJobCreationSuccess,
  onNext,
}: JobDescriptionStepProps): React.ReactElement => {
  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));

  const [mobilePreviewModeOn, setMobilePreviewModeOn] = useState(false);
  const [jobId, setJobId] = useState(jobIdProp);
  const [hasCreatedJob, setHasCreatedJob] = useState(!!jobId);

  const { currentJobTitle, isFetchingCurrentJobTitle } = useGetJobQuery(jobId ?? skipToken, {
    selectFromResult: ({ data, isFetching }) => {
      return {
        currentJobTitle: data?.title,
        isFetchingCurrentJobTitle: isFetching,
      };
    },
  });

  const { data: existingJobDescription } = useGetJobDescriptionQuery(jobId ? { jobId } : skipToken);

  const [createJob, { isLoading: isCreatingJob }] = useCreateJobMutation();
  const [, { isSuccess: isSuccessGenerateJd }] = useGenerateJobDescriptionMutation({
    fixedCacheKey: "generateJobDescription",
  });
  const [, { isSuccess: isSuccessUpdateJd }] = useUpdateJobDescriptionMutation({
    fixedCacheKey: "updateJobDescription",
  });
  const hasUpsertedJd = useMemo((): boolean => {
    return isSuccessUpdateJd || isSuccessGenerateJd || !!existingJobDescription?.userProvidedDescription;
  }, [existingJobDescription, isSuccessGenerateJd, isSuccessUpdateJd]);

  // RHF setup
  const formMethods = useForm<SlimCreateJobSchemaFormType>({
    resolver: zodResolver(SlimCreateJobSchema),
    defaultValues: initialFormValues,
  });

  const { control, handleSubmit } = formMethods;

  const { field: titleField } = useController({
    name: "title",
    control,
  });

  const { field: personaField } = useController({
    name: "persona",
    control,
  });

  const onCreateJob = React.useCallback(async (): Promise<void> => {
    const response = await createJob({
      values: { title: titleField.value, persona: personaField.value, isPrivate: false },
      createJobContext: undefined,
    }).unwrap();

    if (response.success && response.jobId) {
      onJobCreationSuccess?.(response.jobId);
      setHasCreatedJob(true);
      setJobId(response.jobId);
    }

    return;
  }, [createJob, onJobCreationSuccess, personaField.value, setJobId, titleField.value]);

  useEffect(() => {
    if (currentJobTitle) {
      formMethods.setValue("title", currentJobTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJobTitle]);

  const updatedJobTitle = useMemo((): string | undefined => {
    // Update Job title if it's changed
    if (hasUpsertedJd && jobId && currentJobTitle !== titleField.value) {
      return titleField.value;
    }
    return undefined;
  }, [hasUpsertedJd, jobId, currentJobTitle, titleField.value]);

  const showDesktopPreview = useMemo((): boolean => {
    return !smallScreen && hasCreatedJob && hasUpsertedJd && !hideJdPreview;
  }, [smallScreen, hasCreatedJob, hasUpsertedJd, hideJdPreview]);

  return (
    /* Full Screen Container */
    <Stack direction="row" height={STEP_FLOW_PAGE_CONTAINER_HEIGHT} width="100%" justifyContent="center">
      {/* Left Panel Container */}
      <Stack
        overflow="hidden"
        width={!showDesktopPreview ? "100%" : "40%"}
        alignItems={showDesktopPreview ? { xs: "center", xl: "flex-end" } : "center"}
        justifyContent={showDesktopPreview ? { xs: "center", xl: "flex-start" } : "center"}
        mt={{ xs: 0, xl: 3 }}
      >
        {/* Card Wrapper */}
        <Stack
          width="100%"
          maxWidth={showDesktopPreview ? { xs: "507px", lg: "700px" } : "507px"}
          justifyContent="center"
          alignItems="stretch"
          my={hasUpsertedJd && !hideJdPreview ? 0 : 3}
          overflow="auto"
        >
          {smallScreen && hasUpsertedJd && (
            <MobilePreviewToggle previewModeOn={mobilePreviewModeOn} setPreviewModeOn={setMobilePreviewModeOn} />
          )}
          {mobilePreviewModeOn ? (
            <JobDescriptionPagePreview show={!hideJdPreview} />
          ) : (
            // TODO: this wrapper should have transition for height; should work to "grow" with expanded form
            // Card styles necessary to prevent content overflow and to allow for correct scroll behavior
            <AnimatedFormContainer flexGrow="1" overflow="auto" minHeight="0">
              <Card padding={hasUpsertedJd ? "16px 16px 0 16px" : undefined}>
                <FormProvider {...formMethods}>
                  <Stack spacing={2} p={hasCreatedJob && !hideJdPreview ? 0 : 2}>
                    <Stack width="100%" alignItems="center" spacing={2}>
                      <JobBoardManagerSVG />
                      <Body weight="600">
                        {hasCreatedJob ? "Now let's add some details" : "Let’s start by creating your job"}
                      </Body>
                    </Stack>
                    <Stack spacing={1}>
                      <BodySmall weight="600">Job title</BodySmall>
                      {hasCreatedJob ? (
                        <TextField
                          text={titleField.value}
                          onTextUpdated={titleField.onChange}
                          disabled={isFetchingCurrentJobTitle}
                        />
                      ) : (
                        <TitlesAutocomplete
                          title={titleField.value}
                          setTitle={titleField.onChange}
                          setPersonaId={personaField.onChange}
                          hideLabel
                          hideHelpText
                          disabled={isCreatingJob}
                        />
                      )}
                    </Stack>
                    {!hasCreatedJob && (
                      <Stack alignItems="flex-end">
                        <Button
                          variant={ButtonVariant.Primary}
                          onClick={handleSubmit(onCreateJob)}
                          width="fit-content"
                          loading={isCreatingJob}
                        >
                          <BodySmall color={colors.white}>Next</BodySmall>
                        </Button>
                      </Stack>
                    )}
                    <AnimatedJobDescriptionForm className={hasCreatedJob ? "show" : ""}>
                      <CreateJobDescription
                        jobId={jobId}
                        goNext={onNext}
                        variant="page"
                        updatedJobTitle={updatedJobTitle}
                        skipPreview={hideJdPreview}
                      />
                    </AnimatedJobDescriptionForm>
                  </Stack>
                </FormProvider>
              </Card>
            </AnimatedFormContainer>
          )}
        </Stack>
      </Stack>
      {/* Right Panel */}
      <JobDescriptionPagePreview show={showDesktopPreview} />
    </Stack>
  );
};

export default JobDescriptionStep;

const AnimatedFormContainer = styled(Stack)`
  transition: height 0.5s ease;
`;

const AnimatedPreviewContainer = styled.div`
  transition: width 0.5s ease;
  width: 0px;
  display: none;
  &.show {
    display: block;
    width: 60%;
    overflow: auto;

    @media (max-width: ${screenSizesNumbers.tablet}px) {
      width: 100%;
      padding: 0px 16px;
    }
  }
`;

const AnimatedJobDescriptionForm = styled.div`
  transition: all 5s ease;
  height: 0px;
  display: none;
  &.show {
    display: block;
    height: 100%;
  }
`;
