import { TextField, Autocomplete } from "@mui/material";
import React, { ChangeEvent } from "react";

import { ListCampaign } from "services/openapi";

const SelectCampaign = ({
  campaigns,
  campaign,
  setCampaign,
}: {
  campaigns: ListCampaign[];
  campaign: ListCampaign | null;
  setCampaign: (job: ListCampaign | null) => void;
}): React.ReactElement => {
  return (
    <Autocomplete
      options={campaigns}
      getOptionLabel={(option: ListCampaign): string => option.name || ""}
      value={campaign}
      onChange={(_event: ChangeEvent<{}>, newValue: unknown): void => {
        const campaign = newValue as ListCampaign;
        setCampaign(campaign);
      }}
      renderInput={(params): React.ReactElement => (
        <TextField {...params} label="Select Email Campaign" variant="outlined" fullWidth />
      )}
    />
  );
};

export default SelectCampaign;
