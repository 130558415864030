/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { useParams } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";

import { jobIdQueryParam } from "App/appConstants";

const useJobIdFromUrl = (): string | undefined => {
  // Support reading job ids from the route path itself (e.g. "/job/:jobId/setup/:step?")
  const { jobId: jobIdFromPath } = useParams<{ jobId: string | undefined }>();
  // Also support reading job ids from query params (e.g. "/candidates?job=<job-id>")
  const [jobFromParam] = useQueryParam("job", StringParam);
  const [jobIdFromParam] = useQueryParam("jobId", StringParam);

  return jobIdFromPath ?? jobFromParam ?? jobIdFromParam ?? undefined;
};

// Unfortunately the above hook was made and then widely adopted without returning a setter
// which led to a lot of code in components that looked like this:
//  const [, setJobId] = useQueryParam(jobIdQueryParam, StringParam);
//  const jobId = useJobIdFromUrl();
// Don't have time to refactor all 70 uses, so for now just wrapping with another hook
// that will reduce code duplication and reduce the chances of a type mismatch
// We should eventually remove the above hook (and also the "job" query param)
export const useJobId = (): [string | undefined, (jobId: string | undefined) => void] => {
  const [, setJobId] = useQueryParam(jobIdQueryParam, StringParam);
  const jobId = useJobIdFromUrl();

  return [jobId, setJobId];
};

export default useJobIdFromUrl;
