import { Box } from "@mui/material";
import React from "react";

import { B2_doNotUse } from "styles/typography";

export const ConfirmUnsavedChangesModal = (): React.ReactElement => {
  return (
    <Box width={445} marginX="auto">
      <B2_doNotUse>
        <b>Tip:</b> To make changes to multiple email variants, open each one in a new browser tab.
      </B2_doNotUse>
    </Box>
  );
};
