/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    originalOutreachEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject5
     */
    candidateEmail: string;
}

export function InlineObject5FromJSON(json: any): InlineObject5 {
    return InlineObject5FromJSONTyped(json, false);
}

export function InlineObject5FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject5 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'originalOutreachEmail': json['original_outreach_email'],
        'candidateEmail': json['candidate_email'],
    };
}

export function InlineObject5ToJSON(value?: InlineObject5 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'original_outreach_email': value.originalOutreachEmail,
        'candidate_email': value.candidateEmail,
    };
}


