import { Box, Stack } from "@mui/material";
import React from "react";

import GrowYourTeamImage from "assets/images/grow_your_team.png";
import CreateJobButton from "components/dover/CreateJob/CreateJobButton";
import { ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";

export const NoJobsCard = (): React.ReactElement => {
  return (
    <Stack direction={{ xs: "column", sm: "row" }} justifyContent="center" spacing={3}>
      <Box p={3} maxWidth="420px">
        <img src={GrowYourTeamImage} style={{ width: "100%" }} />
      </Box>
      <Stack justifyContent="center" p={3} maxWidth="720px">
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Body weight="600">Grow your team with Dover</Body>
            <Body>
              {
                "Welcome to Dover, your one-stop shop for hiring! Get started by creating a job and explore our free ATS features. Leverage our paid solutions like Recruiting Partner, Sourcing, and Dover Interviewer to streamline your hiring and find top talent with ease."
              }
            </Body>
          </Stack>
          <Box>
            <CreateJobButton omitCreateJobModal buttonProps={{ variant: ButtonVariant.Primary }} />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
