import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, ButtonText } from "components/library/typography";
import CustomModal from "components/Modal";
import { CreateNewJobRequestCreateJobContextEnum } from "services/openapi";
import { colors } from "styles/theme";

const SourcingSelectionModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }): React.ReactElement => {
  const [sourcingEnabled, setSourcingEnabled] = React.useState(true);
  const navigate = useNavigate();

  const onClickContinue = React.useCallback(() => {
    navigate(APP_ROUTE_PATHS.job.createJob(CreateNewJobRequestCreateJobContextEnum.FreeAts));
  }, [navigate]);

  return (
    <CustomModal
      open={isOpen}
      onClose={close}
      showTitleSpacer={false}
      maxWidth="sm"
      title="Create job"
      dialogActions={
        <Stack spacing={2} direction="row" justifyContent="flex-end" style={{ padding: "12px" }}>
          <Button variant={ButtonVariant.Secondary} onClick={(): void => close()}>
            Cancel
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={onClickContinue}>
            <Stack spacing={1} direction="row" alignItems="center">
              <ButtonText color={colors.white}>Continue</ButtonText>
              <ArrowRight className="svg-color" color={colors.white} />
            </Stack>
          </Button>
        </Stack>
      }
    >
      <RadioGroup
        row
        value={sourcingEnabled}
        onChange={(e): void => {
          setSourcingEnabled(e.target.value === "true");
        }}
      >
        <FormControlLabel
          label={
            <>
              <Body>Yes, use Dover Sourcing</Body>
            </>
          }
          value={true}
          checked={sourcingEnabled}
          control={<Radio />}
          sx={{ marginBottom: "20px" }}
        />
        <FormControlLabel
          label={
            <>
              <Body>No</Body>
              <BodySmall color={colors.grayscale.gray500}>You can always enable this later.</BodySmall>
            </>
          }
          value={false}
          checked={!sourcingEnabled}
          control={<Radio />}
        />
      </RadioGroup>
    </CustomModal>
  );
};

export default SourcingSelectionModal;
