import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { useListCandidateSourcesQuery } from "services/doverapi/endpoints/candidateSource";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { CandidateSource, CandidateSourceDirectionEnum } from "services/openapi";

export function useGetDoverOutboundCandidateSource(jobId?: string): CandidateSource | undefined {
  const { data: candidateSources } = useListCandidateSourcesQuery(
    jobId
      ? {
          jobId,
        }
      : skipToken
  );

  return React.useMemo(() => {
    const allCandidateSources = listAllEntities(candidateSources) ?? [];
    if (allCandidateSources.length === 0) {
      return undefined;
    }

    return allCandidateSources.find(
      source => source.internalName === "DOVER" && source.direction === CandidateSourceDirectionEnum.Outbound
    );
  }, [candidateSources]);
}

export function useGetDoverCareersPageSource(): { data: CandidateSource | undefined; isLoading: boolean } {
  const { data: candidateSources, isLoading } = useListCandidateSourcesQuery({});

  const allCandidateSources = listAllEntities(candidateSources) ?? [];
  if (allCandidateSources.length === 0) {
    return { data: undefined, isLoading };
  }

  return { data: allCandidateSources.find(source => source.internalName === "DOVER_CAREERS_PAGE"), isLoading };
}
