import { Stack } from "@mui/material";
import React from "react";

import { BodySmall } from "components/library/typography";
import { ConciergeProfile } from "views/CandidateDetail/components/next-action/ConciergeProfile";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import {
  colorsMap,
  formatDateShort,
  formatTime,
  NextActionStateComponentProps,
} from "views/CandidateDetail/components/next-action/utils/constants";

export function ConciergePendingNotes({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  // Must have an interview
  const { endDate } = candidateBio.nextAction!.currentInterview!;
  let completedDate: string = "";
  if (endDate) {
    completedDate += ` on ${formatDateShort(endDate!)} at ${formatTime(endDate!)}`;
  }

  const description = (
    <Stack spacing={2}>
      <ConciergeProfile slackUrl={candidateBio.slackUrl} />
      <BodySmall>{`Call completed with Dover Interviewer${completedDate}. Interview notes will be uploaded shortly.`}</BodySmall>
    </Stack>
  );

  return <NextActionCardSkeleton title={"Awaiting Decision"} colors={colorsMap.yellow} description={description} />;
}
