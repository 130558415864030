/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailTemplateV2
 */
export interface EmailTemplateV2 {
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateV2
     */
    emailAliasId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailTemplateV2
     */
    toEmails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailTemplateV2
     */
    ccEmails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailTemplateV2
     */
    bccEmails: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateV2
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateV2
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateV2
     */
    threadId: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplateV2
     */
    messageKey: string;
}

export function EmailTemplateV2FromJSON(json: any): EmailTemplateV2 {
    return EmailTemplateV2FromJSONTyped(json, false);
}

export function EmailTemplateV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailTemplateV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAliasId': json['email_alias_id'],
        'toEmails': json['to_emails'],
        'ccEmails': json['cc_emails'],
        'bccEmails': json['bcc_emails'],
        'subject': json['subject'],
        'body': json['body'],
        'threadId': json['thread_id'],
        'messageKey': json['message_key'],
    };
}

export function EmailTemplateV2ToJSON(value?: EmailTemplateV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_alias_id': value.emailAliasId,
        'to_emails': value.toEmails,
        'cc_emails': value.ccEmails,
        'bcc_emails': value.bccEmails,
        'subject': value.subject,
        'body': value.body,
        'thread_id': value.threadId,
        'message_key': value.messageKey,
    };
}


