import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useBoolean } from "react-use";

import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning-outlined.svg";
import { useBulkEmailState } from "components/dover/top-level-modal-manager/hooks/useBulkEmailState";
import { useValidate } from "components/dover/top-level-modal-manager/hooks/useValidate";
import { BulkCandidateActionEmailEditor } from "components/dover/top-level-modal-manager/modals/BulkCandidateActionEmailEditor";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_MAX_WIDTH,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { WarningBanner } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/WarningBanner";
import { ClientEmailTemplateSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/ClientEmailTemplateSelect";
import { RejectReasonSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/RejectReasonSelect";
import { BulkRejectModalProps } from "components/dover/top-level-modal-manager/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { ScheduledSendButton } from "components/ScheduledSendButton";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useQueueBulkRejectionMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { ArchiveReason, ArchiveReasonTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";
import { multiSelectApplicationsAtom } from "views/candidates/ApplicationReview/atoms/multiSelect";

export const BulkRejectModal = ({ isOpen, closeModal }: BulkRejectModalProps): React.ReactElement => {
  const [queueBulkReject] = useQueueBulkRejectionMutation();
  const jobId = useJobIdFromUrl();

  const [sendAt, setSendAt] = useState<Date>(new Date());
  const [rejectionReason, setRejectionReason] = useState<ArchiveReason | null>(null);
  const [openEmailEditor, toggleEmailEditor] = useBoolean(false);
  const [clientEmailTemplateId, setClientEmailTemplateId] = useState<string | null | undefined>(undefined);

  const multiSelectApplications = useAtomValue(multiSelectApplicationsAtom);

  const {
    from,
    setFrom,
    initialSubject,
    subject,
    setSubject,
    initialBody,
    body,
    setBody,
    cc,
    setCc,
    bcc,
    setBcc,
    isFetching,
    initialClientEmailTemplateId,
  } = useBulkEmailState({ jobId, clientEmailTemplateId });

  const { warning, tooltip, disabledMap, hideEditor } = useValidate(
    openEmailEditor ? "BulkRejectWithEmail" : "BulkRejectWithoutEmail",
    {
      fetchingTemplateAndBioData: isFetching,
      subject,
      body,
      from,
      rejectionReason,
    }
  );

  const onSubmit = useCallback(async () => {
    if (!rejectionReason?.id || !jobId) {
      return;
    }

    const candidates = multiSelectApplications
      .filter(a => !!a.candidateActionVersion)
      .map(a => ({
        id: a.candidateId,
        actionVersion: a.candidateActionVersion!, // The filter above gurantees this to be defined, ts just doesn't know it
      }));

    const args = {
      data: {
        sync: false,
        archiveReason: rejectionReason.id,
        rawEmailArgs: openEmailEditor
          ? {
              emailAlias: from.id,
              ccEmails: cc.map(o => o.email),
              bccEmails: bcc.map(o => o.email),
              subject: subject,
              body: body,
              sendAt: sendAt,
            }
          : null,
        candidates,
      },
    };

    const queueBulkRejectPromise = queueBulkReject(args).unwrap();

    try {
      await toast.promise(
        queueBulkRejectPromise,
        {
          pending: "Queueing...",
          success: "Queued Rejections!",
          error: "Error queueing rejections",
        },
        { ...toastOptions }
      );

      closeModal();
    } catch (e) {
      console.error(e);
      return;
    }
  }, [
    bcc,
    body,
    cc,
    closeModal,
    from.id,
    jobId,
    sendAt,
    multiSelectApplications,
    queueBulkReject,
    rejectionReason?.id,
    subject,
    openEmailEditor,
  ]);

  const ActionButtons = useMemo(
    () => (
      <Stack width="100%">
        {!isFetching && <WarningBanner warning={warning} />}
        <Stack width="100%" spacing={2} direction="row" justifyContent="space-between" padding="12px">
          {openEmailEditor && (
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <Body>Send </Body>
              <ScheduledSendButton setSendAt={setSendAt} />
            </Stack>
          )}
          <Button
            variant={ButtonVariant.SecondarySuccess}
            onClick={onSubmit}
            disabled={disabledMap.Send}
            tooltip={tooltip}
          >
            {openEmailEditor ? "Send and Submit" : "Submit"}
          </Button>
        </Stack>
      </Stack>
    ),
    [disabledMap.Send, setSendAt, isFetching, onSubmit, openEmailEditor, tooltip, warning]
  );

  let toggleEmailIcon;
  let toggleEmailText;
  if (openEmailEditor) {
    toggleEmailIcon = <MinusIcon color={colors.grayscale.gray600} className="svg-color" />;
    toggleEmailText = "Remove email";
  } else {
    toggleEmailIcon = <AddIcon color={colors.grayscale.gray600} className="svg-color" />;
    toggleEmailText = "Include email";
  }

  const numApplicantsSelected = multiSelectApplications.length;

  return (
    <CustomModal
      open={isOpen}
      onClose={closeModal}
      title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>Bulk Reject</Body>}
      maxWidth={CANDIDATE_ACTION_MODAL_MAX_WIDTH}
      customDialogStyles={CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES}
      customContentStyles={CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES}
      dialogActions={ActionButtons}
      dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
    >
      <Stack spacing={2}>
        <RejectReasonSelect
          reason={rejectionReason}
          setReason={setRejectionReason}
          archiveContext={ArchiveReasonTypeEnum.Rejected}
        />
        <Stack spacing={1}>
          {openEmailEditor && (
            <ClientEmailTemplateSelect
              clientEmailTemplateId={clientEmailTemplateId ?? initialClientEmailTemplateId}
              setClientEmailTemplateId={setClientEmailTemplateId}
            />
          )}
          <Stack direction="row" justifyContent="space-between">
            {/* // Include empty div to keep flex spacing */}
            {openEmailEditor ? <BodySmall weight="500">Email</BodySmall> : <div />}
            <Button variant={ButtonVariant.Ghost} onClick={toggleEmailEditor} removePadding>
              <Stack direction="row" spacing={0.5} alignItems="center">
                {toggleEmailIcon}
                <BodySmall color={colors.grayscale.gray600}>{toggleEmailText}</BodySmall>
              </Stack>
            </Button>
          </Stack>
          {openEmailEditor && !hideEditor && (
            <Stack spacing={1}>
              <Banner variant={BannerVariant.Warning} overrideIcon={<WarningIcon width="28px" />}>
                <Stack>
                  <BodySmall weight="500">{`Emailing ${numApplicantsSelected} applicant${
                    numApplicantsSelected === 1 ? "" : "s"
                  }`}</BodySmall>
                  <BodySmall>{`Do not include any content specific to one individual. Dover will auto-fill all variables.`}</BodySmall>
                </Stack>
              </Banner>
              <BulkCandidateActionEmailEditor
                body={initialBody}
                onBodyChanged={setBody}
                subject={initialSubject}
                onSubjectChanged={setSubject}
                from={from}
                onFromChanged={setFrom}
                cc={cc}
                onCcChanged={setCc}
                bcc={bcc}
                onBccChanged={setBcc}
                disableFrom={disabledMap.From}
                disableCc={disabledMap.CC}
                disableBcc={disabledMap.BCC}
                disableSubject={disabledMap.Subject}
                disableBody={disabledMap.Body}
                loading={isFetching}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </CustomModal>
  );
};
