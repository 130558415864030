import { Link } from "@mui/material";
import React from "react";

import { B1_doNotUse } from "styles/typography";

export const GmailAuthHelpText = ({
  textAlign,
}: {
  textAlign?: "left" | "right" | "center" | "justify" | "initial" | "inherit";
}): React.ReactElement => {
  return (
    <B1_doNotUse textAlign={textAlign}>
      This allows Dover to send initial outreach or scheduling emails to candidates you approve. Dover will not be
      sending emails from your email address without your initial approval.{" "}
      <B1_doNotUse bold display="inline">
        Please make sure to authenticate the same email you are logged into Dover with.
      </B1_doNotUse>{" "}
      <Link
        href={"https://help.dover.com/en/articles/6480907-when-does-dover-use-my-work-email"}
        target="_blank"
        rel="noreferrer noopener"
        style={{ textDecoration: "none" }}
      >
        Learn More
      </Link>
    </B1_doNotUse>
  );
};
