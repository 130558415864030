import { Autocomplete, Box, MenuItem, Stack, TextField } from "@mui/material";
import { useSetAtom, useAtomValue } from "jotai";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as GreenCheckSVG } from "assets/icons/check-green-filled.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { ApplicationQuestion, ApplicationQuestionInputTypeEnum } from "services/openapi";
import { InternalLink } from "styles/typography";
import {
  applicationQuestionsAtom,
  selectAppQuestionOptionAtom,
  setAnswersForQuestionIdAtom,
} from "views/candidates/ApplicationReview/atoms/applicationQuestions";

interface AppQuestionSectionProps {
  customAppQuestions?: ApplicationQuestion[];
}

const QuestionTextEl = ({ question }: { question: string }): React.ReactElement => {
  return (
    <Box padding="8px" paddingBottom={0}>
      <Body weight="600">{question}</Body>
    </Box>
  );
};

export const MultipleChoiceQuestion = ({ question }: { question: ApplicationQuestion }): React.ReactElement => {
  const selectAppQuestionOption = useSetAtom(selectAppQuestionOptionAtom);
  const applicationQuestionsValue = useAtomValue(applicationQuestionsAtom);
  const answersForQuestion = question.id ? applicationQuestionsValue[question.id] : [];

  const addNewAnswer = (option: string): void => {
    if (!question.id || !option) {
      return;
    }
    selectAppQuestionOption(question.id, option);
  };

  // if no multiple choice options, then just early return
  if (!question.multipleChoiceOptions?.length) {
    return <></>;
  }

  return (
    <Stack width="100%" spacing={1}>
      <QuestionTextEl question={question.question} />
      <Stack>
        {question.multipleChoiceOptions.map(option => {
          return (
            <MenuItem
              value={option}
              onClick={(): void => addNewAnswer(option)}
              key={option}
              sx={{
                "&.MuiButtonBase-root": {
                  padding: "8px",
                  borderRadius: "4px",
                },
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <BodySmall>{option}</BodySmall>
                {answersForQuestion?.includes(option) && <GreenCheckSVG height="16px" width="16px" />}
              </Stack>
            </MenuItem>
          );
        })}
      </Stack>
    </Stack>
  );
};

const FreeTextQuestion = ({ question }: { question: ApplicationQuestion }): React.ReactElement => {
  const applicationQuestionsValue = useAtomValue(applicationQuestionsAtom);
  const selectAppQuestionOption = useSetAtom(selectAppQuestionOptionAtom);
  const setOptionsForQuestionId = useSetAtom(setAnswersForQuestionIdAtom);
  const [currentAutocompleteInput, setCurrentAutocompleteInput] = React.useState<string>("");

  const answersForQuestion = question.id ? applicationQuestionsValue[question.id] : [];

  const addNewAnswer = (newAnswer: string): void => {
    if (!newAnswer || !question.id || answersForQuestion?.includes(newAnswer)) {
      return;
    }
    selectAppQuestionOption(question.id, newAnswer);
  };

  const updateAnswersForQuestion = (newAnswers: string[]): void => {
    if (!question.id) {
      return;
    }
    setOptionsForQuestionId(question.id, newAnswers);
    setCurrentAutocompleteInput("");
  };
  return (
    <Stack width="100%" spacing={1}>
      <QuestionTextEl question={question.question} />
      <Stack direction="row" alignItems="center" padding="8px" spacing={1}>
        <Autocomplete
          open={false}
          fullWidth
          value={answersForQuestion}
          inputValue={currentAutocompleteInput}
          onInputChange={(_, value: string): void => {
            setCurrentAutocompleteInput(value);
          }}
          onChange={(_, value: string[]): void => updateAnswersForQuestion(value)}
          multiple
          freeSolo
          options={answersForQuestion}
          renderInput={(params: any): React.ReactElement => {
            return (
              <TextField
                {...params}
                placeholder={!answersForQuestion?.length ? "Start typing, and press enter to add filter..." : undefined}
                sx={{
                  ".MuiOutlinedInput-root.MuiInputBase-root": {
                    outline: "none",
                    border: "none",
                    padding: "4px",
                  },
                  width: "365px",
                }}
                // endAdornment={null} so that the dropdown arrow doesn't show up
                InputProps={{ ...params.InputProps, endAdornment: null }}
              />
            );
          }}
        />
        <Button
          variant={ButtonVariant.Secondary}
          onClick={(): void => {
            addNewAnswer(currentAutocompleteInput);
            setCurrentAutocompleteInput("");
          }}
          disabled={!currentAutocompleteInput}
        >
          Add
        </Button>
      </Stack>
    </Stack>
  );
};

export const ApplicationQuestionsSection = ({ customAppQuestions }: AppQuestionSectionProps): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  return (
    <Stack padding="8px">
      {customAppQuestions?.map((question: ApplicationQuestion) => {
        if (!question.inputType) {
          return <Body>Error...</Body>;
        } else if (question.inputType === ApplicationQuestionInputTypeEnum.MultipleChoice) {
          return <MultipleChoiceQuestion question={question} key={question.id} />;
        } else if (
          [ApplicationQuestionInputTypeEnum.ShortAnswer, ApplicationQuestionInputTypeEnum.LongAnswer].includes(
            question.inputType
          )
        ) {
          return <FreeTextQuestion question={question} key={question.id} />;
        }
      })}
      {jobId && (
        <Box padding="8px">
          <BodySmall>
            You can modify your questions{" "}
            <InternalLink
              to={APP_ROUTE_PATHS.job.jobPosting(jobId, new URLSearchParams({ jobPostingTab: "application-form" }))}
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </InternalLink>
          </BodySmall>
        </Box>
      )}
    </Stack>
  );
};
