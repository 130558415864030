import { Box, Stack, TextField, Radio, RadioGroup, FormControl, FormControlLabel } from "@mui/material";
import Color from "color";
import React, { ChangeEvent, useCallback, useState } from "react";
import styled from "styled-components";

import { FilledButton, SecondaryButton } from "components/Button";
import DoverLogo from "components/icons/DoverLogo";
import { BodySmall, PageTitle, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { Spacer } from "components/Spacer";
import { useCreateUserRatingMutation } from "services/doverapi/endpoints/userRating";
import { UserRatingContentTypeEnum, UserRatingRatingTypeEnum } from "services/openapi";
import { backgrounds } from "styles/theme";
import { colors } from "styles/theme";

interface RatingOption {
  value: number;
  label?: string;
}

interface Props {
  question: string;
  ratingOptions: RatingOption[];
  showModal: boolean;
  ratingType: UserRatingRatingTypeEnum;
  contentType: UserRatingContentTypeEnum;
  contentId: string;
  onSubmitCallback: () => void;
  onCancelCallback: () => void;
}

const UserRatingModal = ({
  question,
  ratingOptions,
  showModal,
  ratingType,
  contentType,
  contentId,
  onSubmitCallback,
  onCancelCallback,
}: Props): React.ReactElement => {
  const [createUserRating, { isLoading }] = useCreateUserRatingMutation();

  const [ratingNumber, setRatingNumber] = useState<number>(-1);
  const [feedback, setFeedback] = useState<string>("");

  const handleCancel = (): void => {
    if (onCancelCallback) {
      onCancelCallback();
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      await createUserRating({
        rating: ratingNumber,
        ratingType: ratingType,
        contentType: contentType,
        entityUUID: contentId,
        feedback: feedback,
      }).unwrap();
      if (onSubmitCallback) {
        onSubmitCallback();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onRadioChoiceChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setRatingNumber(parseInt(event.target.value));
  }, []);

  const onFeedbackTextChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    setFeedback(event.target.value);
  }, []);

  const submitIsDisabled = isLoading || ratingNumber < 0;

  return (
    <CustomModal
      maxWidth={"xs"}
      open={showModal}
      onClose={handleCancel}
      showTitleSpacer={false}
      title={
        <>
          <Box
            sx={{
              position: "relative",
              paddingBottom: "16px",
            }}
          >
            <DoverLogo />
          </Box>
          <PageTitle>{question}</PageTitle>
        </>
      }
      omitDividers={true}
    >
      <Stack spacing={2}>
        <FormControl component="fieldset" variant="standard">
          <RadioGroup aria-label="Rating" name="Rating" value={ratingNumber} onChange={onRadioChoiceChange}>
            {ratingOptions.map(ratingOption => {
              return (
                <>
                  <BodySmall>
                    <FormControlLabel value={ratingOption.value} control={<StyledRadio />} label={ratingOption.label} />
                  </BodySmall>
                  <Spacer width={"12px"} />
                </>
              );
            })}
          </RadioGroup>
        </FormControl>
        <Subtitle2>Additional feedback</Subtitle2>

        <StyledTextField
          className="rating-feedback-area"
          value={feedback}
          fullWidth={true}
          multiline
          placeholder="Enter a description"
          rows={4}
          onChange={onFeedbackTextChange}
        />
        <Box display="flex" justifyContent="flex-end">
          <SecondaryButton onClick={handleCancel}>Close</SecondaryButton>
          <Spacer width="8px" />
          <FilledButton onClick={handleSubmit} disabled={submitIsDisabled}>
            Submit
          </FilledButton>
        </Box>
      </Stack>
    </CustomModal>
  );
};

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root.MuiInputBase-root {
    border: none;
    font-size: 14px;
    font-family: Inter;
    width: 100%;
    input {
      padding: 12px;
    }
  }

  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

export default UserRatingModal;
