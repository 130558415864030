import React from "react";
import { ReactSVG } from "react-svg";

import Gears from "assets/icons/gears.svg";
import {
  SuccessWrapper,
  SuccessHeader,
  SpacerLine,
  SuccessSubheader,
  SuccessText,
} from "views/Referrals/UploadConnections/styles";

const Success = (): React.ReactElement => {
  return (
    <SuccessWrapper>
      <ReactSVG src={Gears} />
      <SuccessHeader>Thanks! We’re processing your connections to look for the best fits.</SuccessHeader>
      <SpacerLine />
      <SuccessSubheader>{"What's next?"}</SuccessSubheader>
      <SuccessText>
        Your company’s hiring managers will look through those that are best fits for the jobs your company is hiring
        for.
      </SuccessText>
    </SuccessWrapper>
  );
};

export default Success;
