/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateAndSaveCampaignContentResult
 */
export interface GenerateAndSaveCampaignContentResult {
    /**
     * 
     * @type {string}
     * @memberof GenerateAndSaveCampaignContentResult
     */
    readonly initialMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateAndSaveCampaignContentResult
     */
    readonly updatedInitialMessage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenerateAndSaveCampaignContentResult
     */
    readonly followUpMessage1?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateAndSaveCampaignContentResult
     */
    readonly updatedFollowUpMessage1?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenerateAndSaveCampaignContentResult
     */
    readonly followUpMessage2?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateAndSaveCampaignContentResult
     */
    readonly updatedFollowUpMessage2?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateAndSaveCampaignContentResult
     */
    readonly updatedCampaignContent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateAndSaveCampaignContentResult
     */
    readonly campaignAlreadyHadContent?: boolean;
}

export function GenerateAndSaveCampaignContentResultFromJSON(json: any): GenerateAndSaveCampaignContentResult {
    return GenerateAndSaveCampaignContentResultFromJSONTyped(json, false);
}

export function GenerateAndSaveCampaignContentResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateAndSaveCampaignContentResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'initialMessage': !exists(json, 'initial_message') ? undefined : json['initial_message'],
        'updatedInitialMessage': !exists(json, 'updated_initial_message') ? undefined : json['updated_initial_message'],
        'followUpMessage1': !exists(json, 'follow_up_message_1') ? undefined : json['follow_up_message_1'],
        'updatedFollowUpMessage1': !exists(json, 'updated_follow_up_message_1') ? undefined : json['updated_follow_up_message_1'],
        'followUpMessage2': !exists(json, 'follow_up_message_2') ? undefined : json['follow_up_message_2'],
        'updatedFollowUpMessage2': !exists(json, 'updated_follow_up_message_2') ? undefined : json['updated_follow_up_message_2'],
        'updatedCampaignContent': !exists(json, 'updated_campaign_content') ? undefined : json['updated_campaign_content'],
        'campaignAlreadyHadContent': !exists(json, 'campaign_already_had_content') ? undefined : json['campaign_already_had_content'],
    };
}

export function GenerateAndSaveCampaignContentResultToJSON(value?: GenerateAndSaveCampaignContentResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


