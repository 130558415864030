import { ReactSVG } from "react-svg";
import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: 24px 36px;
  max-width: 720px;
  margin: 0 auto;
`;

export const StyledSVG = styled(ReactSVG)`
  display: inline;
  svg {
    height: 16px;
    width: 16px;
    margin-right: 12px;
  }
`;
