import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import styled from "styled-components";

import { LoadingRelative } from "components/HotLoading";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";

interface ModalContentProps {
  width?: string;
  textAlign?: string;
}

// this is used to style the low level modal component from "@mui/material"
// all modals should eventually be replaced with the CustomModal below
export const StyledModalContent = styled.div<ModalContentProps>`
  background-color: white;
  margin: auto;
  width: ${(props): any => props.width ?? "auto"};
  text-align: ${(props): any => props.textAlign ?? "initial"};
  max-width: 90%;
  padding: 32px 24px;
  border-radius: 8px;
`;

export type MaxWidth = "xs" | "sm" | "md" | "lg" | "xl";

export interface CustomModalProps {
  title?: string | React.ReactElement;
  open: boolean;
  loading?: boolean;
  showTitleSpacer?: boolean;
  onClose?: Function;
  maxWidth?: MaxWidth;
  customTitleStyles?: object;
  customContentStyles?: object;
  dialogActions?: React.ReactElement;
  customDialogStyles?: object;
  dialogActionsStyles?: object;
  omitDividers?: boolean;
  customPaperProps?: DialogProps["PaperProps"];
  hideCloseButton?: boolean;
}

// Moving forward, this should be the preferred modal component that is used in the site
export const CustomModal: React.FC<CustomModalProps> = ({
  title,
  children,
  open,
  loading,
  showTitleSpacer = true,
  onClose,
  maxWidth,
  customTitleStyles,
  customContentStyles,
  dialogActions,
  customDialogStyles,
  dialogActionsStyles,
  omitDividers,
  customPaperProps,
  hideCloseButton,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(768));

  const handleCloseButton = React.useCallback(
    (event: React.MouseEvent): void => {
      event.stopPropagation();
      onClose?.();
    },
    [onClose]
  );

  return (
    <Dialog
      fullScreen={smallScreen}
      fullWidth
      maxWidth={maxWidth || "lg"}
      sx={customDialogStyles}
      scroll="paper"
      onClose={(): void => onClose?.()}
      open={open}
      PaperProps={customPaperProps}
    >
      {(title || !hideCloseButton) && (
        <DialogTitle sx={customTitleStyles}>
          {!hideCloseButton && (
            <IconButton
              aria-label="close"
              onClick={handleCloseButton}
              sx={{
                "&.MuiIconButton-root": {
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: colors.grayscale.gray500,
                  zIndex: 1000,
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
          {title}
        </DialogTitle>
      )}
      {(!title || showTitleSpacer) && <Spacer height="40" />}
      <DialogContent sx={customContentStyles} dividers={!omitDividers && !!title}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center">
            <LoadingRelative />
          </Stack>
        ) : (
          children
        )}
      </DialogContent>
      {dialogActions && <DialogActions sx={dialogActionsStyles}>{dialogActions}</DialogActions>}
    </Dialog>
  );
};

export default CustomModal;
