/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";

export default function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";

  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  useEffect(() => {
    if (hasWindow) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}
