import {
  BaseFeedbackTemplateQuestion,
  FeedbackTemplateDetail,
  FeedbackTemplateQuestionComponent,
  SelectFieldFeedbackTemplateQuestion,
  TextFieldFeedbackTemplateQuestion,
} from "services/openapi";

type SelectFieldQuestion = SelectFieldFeedbackTemplateQuestion & {
  component: FeedbackTemplateQuestionComponent.SELECT;
};

type TextFieldQuestion = TextFieldFeedbackTemplateQuestion & {
  component: FeedbackTemplateQuestionComponent.TEXT_FIELD;
};

export type FeedbackTemplateQuestion = Omit<BaseFeedbackTemplateQuestion, "component"> &
  (SelectFieldQuestion | TextFieldQuestion);

export enum FeedbackTemplateDrawerContextType {
  Create = "CREATE",
  Edit = "EDIT",
  Duplicate = "DUPLICATE",
}

export enum FeedbackTemplateCreateMode {
  FromTemplate = "template",
  FromScratch = "scratch",
}

export interface FeedbackTemplateDrawerConfig {
  onCreateCallback?: (template: FeedbackTemplateDetail) => void;
  existingTemplateId?: string;
  assignedJobStageCount?: number;
  context?: FeedbackTemplateDrawerContextType;
}

export interface FeedbackTemplateFormProps {
  validateOnClose: () => void;
}
