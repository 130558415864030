import { TextField, MenuItem } from "@mui/material";
import { sortBy } from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { allTimezoneOptions } from "components/inputs/TimezonePicker/constants";
import { TimezoneOption } from "components/inputs/TimezonePicker/types";
import { StyledAutocomplete } from "views/CompanySetup/components/InterviewPreferences/styles";

const Wrapper = styled.div`
  width: 19em;
  .MuiAutocomplete-inputRoot {
    padding-right: 0 !important;
  }
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 14px);
  }
`;

interface Props {
  initialValue?: string;
  update: (value: string) => void;
}

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const TimezonePicker = ({ initialValue = "America/Los_Angeles", update }: Props): React.ReactElement => {
  const timezoneOptions = useMemo(
    () =>
      sortBy(allTimezoneOptions, tz => {
        if (tz.value === timeZone) return [0, tz];
        if (tz.value.startsWith("US")) return [1, tz];
        if (tz.value.startsWith("America")) return [2, tz];
        return [3, tz];
      }),
    []
  );

  const defaultOption = timezoneOptions.find(option => option.value === initialValue);
  const [option, setOption] = useState(defaultOption);

  return (
    <Wrapper>
      <StyledAutocomplete
        value={option || defaultOption}
        onChange={(event: any, newOption: any): void => {
          if (newOption) {
            setOption(newOption);
            update(newOption.value);
          }
        }}
        renderInput={(params: any): React.ReactElement => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Set your timezone"
            fullWidth
            InputProps={{
              ...params.InputProps,
              readOnly: true,
            }}
          />
        )}
        renderOption={(props, option): React.ReactElement => (
          <MenuItem {...props} key={(option as TimezoneOption).value}>
            {(option as TimezoneOption).label}
          </MenuItem>
        )}
        options={timezoneOptions}
        getOptionLabel={(timezone): string => (timezone as TimezoneOption).label}
        disableClearable
        disablePortal
      />
    </Wrapper>
  );
};
