import { LocationOption } from "services/openapi";
import { RemotePolicies } from "views/job/constants";

/**
 * Based on remotePolicy, determine if remote regions should be shown
 * This can also depend on "specificLocationRequired", because for UI simplicity reasons, if you say "remote only" we give you the option to say
 * you don't care where employees are situated. Instead of making them find the "International" option, we just select it for them,
 * in which case we don't want to show the remote regions field.
 */
export const shouldShowRemoteRegions = ({
  remotePolicy,
  requireSpecificLoc = true,
}: {
  remotePolicy: RemotePolicies;
  requireSpecificLoc?: boolean;
}): boolean => {
  return requireSpecificLoc && [RemotePolicies.Both, RemotePolicies.Remote].includes(remotePolicy);
};

export const shouldShowInOfficeCities = (remotePolicy: RemotePolicies): boolean =>
  remotePolicy === RemotePolicies.InOffice || remotePolicy === RemotePolicies.Both;

/**
 * For autocompletes we filter our list of location options in the frontend
 * @param options the list of location Options we want to filter
 * @param inputValue the search string provided by the user which we want to match on
 */
export const filterLocationOptions = (options: LocationOption[], inputValue: string): LocationOption[] => {
  const loweredInputValue = inputValue.toLowerCase();
  return (
    options
      // first filter on simple includes for speed
      .filter(option => option.displayName.toLowerCase().includes(loweredInputValue))
      // then also insure one of the words in the display name starts with the input value
      .filter(option =>
        option.displayName
          .toLowerCase()
          .split(" ")
          .some((loweredSubstring: string) =>
            loweredInputValue
              .split(" ")
              .some(loweredInputValueSplit => loweredSubstring.startsWith(loweredInputValueSplit))
          )
      )
  );
};
