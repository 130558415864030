import { Stack } from "@mui/material";
import React from "react";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { GradientBackground } from "components/library/GradientBackground";
import { TitleLarge, Body, BodySmall } from "components/library/typography";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";

const InterviewerSplashPage = (): React.ReactElement => {
  const isBasePlanCustomer = useIsBasePlanCustomer();

  return (
    <Stack spacing={2} p={2}>
      {!isBasePlanCustomer && (
        <Banner variant={BannerVariant.Success}>
          <BodySmall>
            Ready to use for your jobs. Make sure to set up Dover Interviewer for your job to start delegating phone
            screens to them.
          </BodySmall>
        </Banner>
      )}
      <GradientBackground
        spacing={3}
        p={4}
        alignItems="center"
        style={{
          border: `1px solid ${colors.grayscale.gray200}`,
          borderRadius: "13px",
        }}
        angle="180"
        firstColor={colors.primary.light}
        secondColor={colors.white}
      >
        <Stack spacing={2} alignItems="center">
          <TitleLarge style={{ fontWeight: 800, fontSize: "40px", textAlign: "center", lineHeight: "48px" }}>
            Dover Interviewer
          </TitleLarge>
          <Body color={colors.grayscale.gray600} centered weight="700">
            Delegate initial phone screens to our team of experts
          </Body>
        </Stack>
        <Body style={{ maxWidth: "570px", textAlign: "center" }}>
          Dover Interviewers have conducted <b>over 27,000 interviews</b> across various roles including engineering,
          marketing, product, and sales. Work with a dedicated interviewer to customize interview questions and give the
          perfect pitch to candidates.
        </Body>
        <Stack direction="row" spacing={2} alignItems="center">
          <ExternalLink
            href={useGetCalendlyUrl("app", "splash_page", "dover_interviewer")}
            target="_blank"
            rel="noopener noreferrer"
            color={colors.link}
          >
            <Button variant={ButtonVariant.Primary}>Book a call</Button>
          </ExternalLink>
          <ExternalLink
            href="https://www.dover.com/our-interviewers"
            target="_blank"
            rel="noopener noreferrer"
            color={colors.link}
          >
            Meet our interviewers
          </ExternalLink>
        </Stack>
      </GradientBackground>
    </Stack>
  );
};

export default InterviewerSplashPage;
