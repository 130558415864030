/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecruitingPartner
 */
export interface RecruitingPartner {
    /**
     * 
     * @type {string}
     * @memberof RecruitingPartner
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecruitingPartner
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RecruitingPartner
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RecruitingPartner
     */
    pictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RecruitingPartner
     */
    blurb: string;
    /**
     * 
     * @type {boolean}
     * @memberof RecruitingPartner
     */
    isAvailable: boolean;
    /**
     * 
     * @type {string}
     * @memberof RecruitingPartner
     */
    clientNames?: string;
    /**
     * 
     * @type {number}
     * @memberof RecruitingPartner
     */
    yearsOfExperience?: number;
}

export function RecruitingPartnerFromJSON(json: any): RecruitingPartner {
    return RecruitingPartnerFromJSONTyped(json, false);
}

export function RecruitingPartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecruitingPartner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'name': json['name'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
        'blurb': json['blurb'],
        'isAvailable': json['is_available'],
        'clientNames': !exists(json, 'client_names') ? undefined : json['client_names'],
        'yearsOfExperience': !exists(json, 'years_of_experience') ? undefined : json['years_of_experience'],
    };
}

export function RecruitingPartnerToJSON(value?: RecruitingPartner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'picture_url': value.pictureUrl,
        'blurb': value.blurb,
        'is_available': value.isAvailable,
        'client_names': value.clientNames,
        'years_of_experience': value.yearsOfExperience,
    };
}


