/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchTransformValidationResult
 */
export interface SearchTransformValidationResult {
    /**
     * 
     * @type {string}
     * @memberof SearchTransformValidationResult
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransformValidationResult
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SearchTransformValidationResult
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTransformValidationResult
     */
    canBeLinked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTransformValidationResult
     */
    linkedByDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTransformValidationResult
     */
    supported: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchTransformValidationResult
     */
    reason: string;
}

export function SearchTransformValidationResultFromJSON(json: any): SearchTransformValidationResult {
    return SearchTransformValidationResultFromJSONTyped(json, false);
}

export function SearchTransformValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchTransformValidationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'label': json['label'],
        'description': json['description'],
        'canBeLinked': json['can_be_linked'],
        'linkedByDefault': json['linked_by_default'],
        'supported': json['supported'],
        'reason': json['reason'],
    };
}

export function SearchTransformValidationResultToJSON(value?: SearchTransformValidationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'label': value.label,
        'description': value.description,
        'can_be_linked': value.canBeLinked,
        'linked_by_default': value.linkedByDefault,
        'supported': value.supported,
        'reason': value.reason,
    };
}


