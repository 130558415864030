import { useGetArchiveReasonsQuery } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { ArchiveReasonTypeEnum, NextAction, ArchiveReason, PipelineCandidate } from "services/openapi";
import { nextActionLabelColorsMap } from "views/CandidateDetail/components/next-action/utils/constants";
import { getNextActionLabel } from "views/candidates/constants";
import { NextUpLabelEnum } from "views/candidates/types";

export const getNextActionTitle = (nextAction?: NextAction, candidate?: PipelineCandidate): string => {
  return getNextActionLabel(nextAction, candidate) || "";
};

export const getNextActionColors = (
  nextAction?: NextAction,
  candidate?: PipelineCandidate
): { backgroundColor: string; borderColor: string; headerTextColor: string } => {
  const nextActionLabel = getNextActionLabel(nextAction, candidate) || NextUpLabelEnum.NeedsAction;
  return nextActionLabelColorsMap[nextActionLabel];
};

export function useGetRejectionReasonByTypeAndDesc(
  type: ArchiveReasonTypeEnum,
  desc: string
): ArchiveReason | undefined {
  const { data: archiveReasons } = useGetArchiveReasonsQuery();

  const rejectionReason = archiveReasons?.results?.find(r => r.type === type && r.description == desc);

  return rejectionReason;
}
