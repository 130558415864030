import { Icon } from "@doverhq/dover-ui";
import { Autocomplete, Box, Skeleton, Stack, InputAdornment } from "@mui/material";
import { useSetAtom } from "jotai";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import { ReactComponent as SmartReplySVG } from "assets/icons/ai-sparkles-solid.svg";
import { ReactComponent as PencilSVG } from "assets/icons/pencil-edit.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { EmailTemplateDrawerAction, EmailTemplateDrawerConfigAtom } from "components/dover/EmailTemplates/atoms";
import { EmailTemplateDrawerAtom } from "components/dover/EmailTemplates/EmailTemplateDrawer";
import { StyledTextField } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { InfoTip } from "components/InfoTip";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useListClientEmailTemplatesQuery } from "services/doverapi/endpoints/client/endpoints";
import { ClientEmailTemplate } from "services/openapi";
import { colors } from "styles/theme";
import { EMAIL_TEMPLATES_PAGE_LIMIT } from "views/CompanySetup/components/EmailTemplates/constants";

export const SMART_REPLY = null;
const ADD_NEW_OR_MANAGE = "ADD_NEW_OR_MANAGE";

interface ClientEmailTemplateOption {
  label: string;
  value: string | null;
  onClick?: () => void;
}

export interface ClientEmailTemplateSelectorProps {
  clientEmailTemplateId?: string | null;
  setClientEmailTemplateId: (templateId: string) => void;
  hideLabel?: boolean;
  hideSmartReply?: boolean;
  disabled?: boolean;
  disabledTooltipText?: string;
  allowEdits?: boolean;
}

const ClientEmailTemplateSelector: React.FC<ClientEmailTemplateSelectorProps> = ({
  disabled,
  hideLabel,
  hideSmartReply,
  clientEmailTemplateId,
  setClientEmailTemplateId,
  allowEdits,
}) => {
  const { open: openEmailTemplateDrawer } = useModal(EmailTemplateDrawerAtom);
  const setEmailTemplateDrawerConfig = useSetAtom(EmailTemplateDrawerConfigAtom);

  const { data, isFetching } = useListClientEmailTemplatesQuery({
    offset: 0 * EMAIL_TEMPLATES_PAGE_LIMIT,
    limit: 300,
  });

  const getOptions = (): ClientEmailTemplateOption[] => {
    const fetchedTemplates = data?.results ?? [];
    const smartReplyOption = hideSmartReply ? [] : [{ value: SMART_REPLY, label: "Smart Reply" }];

    return [
      ...smartReplyOption,
      ...fetchedTemplates.map(template => ({
        value: template.id,
        label: template.name,
      })),
    ] as ClientEmailTemplateOption[];
  };
  const options = getOptions();

  const NewOrManageTemplatesRow: ClientEmailTemplateOption = {
    value: ADD_NEW_OR_MANAGE,
    label: "Add New or Manage",
  };

  const initialOption = clientEmailTemplateId
    ? options.find(option => option.value === clientEmailTemplateId) // use the selected template if passed in
    : hideSmartReply // otherwise default to smart reply if it's not hiddens
    ? undefined
    : options[0];

  if (isFetching || !options) {
    return <Skeleton width="100%" height="60px" />;
  }

  const createClientEmailTemplateCallback = (template?: ClientEmailTemplate): void => {
    if (!template || !template.id) {
      console.error("New email template is undefined");
      return;
    }
    setClientEmailTemplateId(template.id);
  };

  const onCreateClientEmailTemplate = (): void => {
    setEmailTemplateDrawerConfig({
      template: undefined,
      onCreateCallback: createClientEmailTemplateCallback,
      action: EmailTemplateDrawerAction.Create,
    });
    openEmailTemplateDrawer({});
  };

  const onEditClientEmailTemplate = (option: ClientEmailTemplateOption): void => {
    const template = data?.results.find(template => template.id === option.value);
    if (!template || !template.id) {
      console.error("Template not found");
      return;
    }
    setEmailTemplateDrawerConfig({
      template: template,
      action: EmailTemplateDrawerAction.Update,
    });
    openEmailTemplateDrawer({});
  };

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      id="client-email-template-selector"
      value={initialOption}
      options={[...options, NewOrManageTemplatesRow]}
      placeholder="Select a template"
      getOptionLabel={(template): string => template?.label ?? ""}
      onChange={(_, newOption: any): void => {
        if (newOption.value === ADD_NEW_OR_MANAGE) {
          return;
        }
        if (newOption.onClick) {
          newOption.onClick();
          return;
        }
        setClientEmailTemplateId(newOption.value);
      }}
      filterOptions={(options, { inputValue }): ClientEmailTemplateOption[] => {
        return options.filter(
          option =>
            option.value === SMART_REPLY ||
            option.value === ADD_NEW_OR_MANAGE ||
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      }}
      sx={{
        fontSize: "14px",
        fontFamily: "Inter",
        backgroundColor: colors.white,
        ".MuiOutlinedInput-root ": { border: "none" },
        ".MuiAutocomplete-endAdornment": { top: "auto" },
      }}
      componentsProps={{
        // Allows passing styles to the list of options (paper element)
        paper: {
          sx: {
            // Ensures all options in the dropdown are visible by accounting for the height of the fixed
            // "Add New or Manage" row at the bottom of the list
            paddingBottom: "42px",
          },
        },
      }}
      renderInput={(params: any): React.ReactElement => {
        return (
          <StyledTextField
            {...params}
            placeholder={"Select a template"}
            hideLabel={hideLabel}
            label={hideLabel ? undefined : "Template"}
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment:
                params?.inputProps?.value === "Smart Reply" ? (
                  <InputAdornment position="start">
                    <SmartReplySVG width="20px" height="20px" style={{ marginRight: "-8px" }} />
                  </InputAdornment>
                ) : (
                  undefined
                ),
            }}
          />
        );
      }}
      renderOption={(props, option): React.ReactElement => {
        // Special option for fixed "Add New or Manage"
        if (option.value === ADD_NEW_OR_MANAGE) {
          return (
            <Stack
              direction="row"
              position="fixed"
              bottom="0"
              justifyContent="space-between"
              width="100%"
              px={1}
              py={2}
              bgcolor={colors.grayscale.gray100}
              color={colors.black}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                onClick={onCreateClientEmailTemplate}
                sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
              >
                <Icon Icon={PlusIcon} />
                <BodySmall>New Template</BodySmall>
              </Stack>
              <Link
                to={APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.EMAIL_TEMPLATES)}
                target="_blank"
                style={{ color: "inherit" }}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <SettingsIcon className="svg-fill" color={colors.black} />
                  <BodySmall>Manage</BodySmall>
                </Stack>
              </Link>
            </Stack>
          );
        }
        return (
          // @ts-ignore
          <OptionWrapper {...props} key={option.value}>
            <Stack spacing={0.5} direction="row" alignItems="center">
              {option.value === SMART_REPLY && <SmartReplySVG width="20px" height="20px" />}
              <BodySmall>{option.label}</BodySmall>
              {option.value === SMART_REPLY && (
                <InfoTip text="Dover auto-drafts emails based on the interview stage and email sender" />
              )}
            </Stack>
            {allowEdits && (
              <Tooltip title="View and edit">
                <Box width="14px">
                  <PencilSVG
                    width="14px"
                    height="14px"
                    onClick={(): void => {
                      if (option.value) {
                        setClientEmailTemplateId(option.value);
                        onEditClientEmailTemplate(option);
                      }
                    }}
                  />
                </Box>
              </Tooltip>
            )}
          </OptionWrapper>
        );
      }}
    />
  );
};

export default ClientEmailTemplateSelector;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  // Ensures pencil edit icons are right-aligned
  &.MuiAutocomplete-option {
    display: flex;
    justify-content: space-between;
  },
`;
