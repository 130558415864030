import { Box, Skeleton } from "@mui/material";
import React from "react";

import { colors } from "styles/theme";

export const JobViewLoadingSkeleton = (): React.ReactElement => {
  return (
    <Box
      sx={{
        padding: "16px",
        margin: "4px 0px",
        border: `1px solid ${colors.grayscale.gray100}`,
        borderRadius: "4px",
      }}
    >
      <Skeleton height="150px" width="90%" />
      <Skeleton height="150px" width="90%" />
      <Skeleton height="150px" width="90%" />
      <Skeleton height="150px" width="90%" />
    </Box>
  );
};
