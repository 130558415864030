/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfileResponse,
    ProfileResponseFromJSON,
    ProfileResponseFromJSONTyped,
    ProfileResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShowUnreviewedSimilarCandidatesResponse
 */
export interface ShowUnreviewedSimilarCandidatesResponse {
    /**
     * 
     * @type {ProfileResponse}
     * @memberof ShowUnreviewedSimilarCandidatesResponse
     */
    baseCandidate: ProfileResponse;
    /**
     * 
     * @type {Array<ProfileResponse>}
     * @memberof ShowUnreviewedSimilarCandidatesResponse
     */
    similarCandidates: Array<ProfileResponse>;
}

export function ShowUnreviewedSimilarCandidatesResponseFromJSON(json: any): ShowUnreviewedSimilarCandidatesResponse {
    return ShowUnreviewedSimilarCandidatesResponseFromJSONTyped(json, false);
}

export function ShowUnreviewedSimilarCandidatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowUnreviewedSimilarCandidatesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseCandidate': ProfileResponseFromJSON(json['base_candidate']),
        'similarCandidates': ((json['similar_candidates'] as Array<any>).map(ProfileResponseFromJSON)),
    };
}

export function ShowUnreviewedSimilarCandidatesResponseToJSON(value?: ShowUnreviewedSimilarCandidatesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'base_candidate': ProfileResponseToJSON(value.baseCandidate),
        'similar_candidates': ((value.similarCandidates as Array<any>).map(ProfileResponseToJSON)),
    };
}


