import { skipToken } from "@reduxjs/toolkit/dist/query";

import { Role, useContainsRole } from "components/RBAC";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { CandidateBioSchedulingOwnershipEnum } from "services/openapi";

export const useDisableAtsControls = (candidateId?: string): boolean => {
  const { schedulingOwnership } = useGetCandidateBioQuery(candidateId ?? skipToken, {
    selectFromResult: ({ data }) => ({ schedulingOwnership: data?.schedulingOwnership }),
  });

  const isAdmin = useContainsRole([Role.ADMIN, Role.RECRUITING_PARTNER]);
  return !isAdmin && schedulingOwnership === CandidateBioSchedulingOwnershipEnum.DoverHandlesScheduling;
};
