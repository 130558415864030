/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtensionV2CampaignEmailSenderAlias
 */
export interface ExtensionV2CampaignEmailSenderAlias {
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2CampaignEmailSenderAlias
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2CampaignEmailSenderAlias
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2CampaignEmailSenderAlias
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2CampaignEmailSenderAlias
     */
    readonly gmailCredentialAuthState?: ExtensionV2CampaignEmailSenderAliasGmailCredentialAuthStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum ExtensionV2CampaignEmailSenderAliasGmailCredentialAuthStateEnum {
    IsShellAccount = 'IS_SHELL_ACCOUNT',
    Valid = 'VALID',
    AuthInvalid = 'AUTH_INVALID',
    NeverAuthed = 'NEVER_AUTHED',
    InvalidAuthCredType = 'INVALID_AUTH_CRED_TYPE'
}

export function ExtensionV2CampaignEmailSenderAliasFromJSON(json: any): ExtensionV2CampaignEmailSenderAlias {
    return ExtensionV2CampaignEmailSenderAliasFromJSONTyped(json, false);
}

export function ExtensionV2CampaignEmailSenderAliasFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionV2CampaignEmailSenderAlias {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'gmailCredentialAuthState': !exists(json, 'gmail_credential_auth_state') ? undefined : json['gmail_credential_auth_state'],
    };
}

export function ExtensionV2CampaignEmailSenderAliasToJSON(value?: ExtensionV2CampaignEmailSenderAlias | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
    };
}


