/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChangeLogIndividualUpdate,
    ChangeLogIndividualUpdateFromJSON,
    ChangeLogIndividualUpdateFromJSONTyped,
    ChangeLogIndividualUpdateToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChangeLogEntry
 */
export interface ChangeLogEntry {
    /**
     * 
     * @type {Array<ChangeLogIndividualUpdate>}
     * @memberof ChangeLogEntry
     */
    diff: Array<ChangeLogIndividualUpdate>;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogEntry
     */
    readonly modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ChangeLogEntry
     */
    modifiedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogEntry
     */
    modifiedByDoverReason?: ChangeLogEntryModifiedByDoverReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeLogEntry
     */
    notes?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum ChangeLogEntryModifiedByDoverReasonEnum {
    AutoDepthFinding = 'AUTO_DEPTH_FINDING',
    SlackRejectionReasons = 'SLACK_REJECTION_REASONS',
    Backfill = 'BACKFILL'
}

export function ChangeLogEntryFromJSON(json: any): ChangeLogEntry {
    return ChangeLogEntryFromJSONTyped(json, false);
}

export function ChangeLogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeLogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'diff': ((json['diff'] as Array<any>).map(ChangeLogIndividualUpdateFromJSON)),
        'modifiedBy': !exists(json, 'modified_by') ? undefined : json['modified_by'],
        'modifiedAt': !exists(json, 'modified_at') ? undefined : (json['modified_at'] === null ? null : new Date(json['modified_at'])),
        'modifiedByDoverReason': !exists(json, 'modified_by_dover_reason') ? undefined : json['modified_by_dover_reason'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function ChangeLogEntryToJSON(value?: ChangeLogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'diff': ((value.diff as Array<any>).map(ChangeLogIndividualUpdateToJSON)),
        'modified_at': value.modifiedAt === undefined ? undefined : (value.modifiedAt === null ? null : value.modifiedAt.toISOString()),
        'modified_by_dover_reason': value.modifiedByDoverReason,
        'notes': value.notes,
    };
}


