import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Box, Chip, Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { sortBy } from "lodash";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil-edit.svg";
import PeopleIcon from "assets/icons/people-outlined-grey.svg";
import { StyledListItem } from "components/dover/inputs/pro-users/styles";
import NoValuesAutocomplete from "components/library/Autocomplete/NoValuesAutocomplete";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { StyledSwitch } from "components/library/Switch";
import { TextField } from "components/library/TextField";
import { Tooltip } from "components/library/Tooltip";
import { Body, Subtitle1, BodySmall, Caption, Overline } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import {
  useListPersonasQuery,
  useListSearchesUsingPersonaQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { PersonaIDsRequired } from "services/doverapi/endpoints/search-v3/types";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import AdvancedAccordion from "views/sourcing/Search/components/AdvancedDropdown";
import { CustomizeTitlesModal } from "views/sourcing/Search/components/CustomizeTitlesModal";
import FilterAccordion from "views/sourcing/Search/components/FilterAccordion";
import FilterSectionHeader from "views/sourcing/Search/components/FilterSectionHeader";
import {
  EXCLUDED_TITLES_NAME,
  PERSONAS_ELEMENTS_NAME,
  previewStateMarginBottom,
  previewStateMarginRight,
  PERSONAS_MOST_RECENT_ONLY_NAME,
  CUSTOM_PERSONA_NAME,
  CHIP_MAX_WIDTH,
  CUSTOM_PERSONA_SELECTED_ERROR,
  PERSONA_REQUIRED_ERROR,
  FILTERS_WIDTH_INT,
} from "views/sourcing/Search/constants";
import { useGetSearchFromUrl } from "views/sourcing/Search/hooks";
import { SearchV3FormSchemaType, TitleContentProps } from "views/sourcing/Search/types";

const PersonasFilterTitleContent = React.memo(
  ({ expanded }: TitleContentProps): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const personas = useWatch({ control, name: PERSONAS_ELEMENTS_NAME });

    const onChipDelete = React.useCallback(
      (value: PersonaIDsRequired) => {
        return setValue(
          PERSONAS_ELEMENTS_NAME,
          personas.filter(persona => persona.id !== value.id)
        );
      },
      [personas, setValue]
    );

    if (!personas.length || expanded) {
      return <></>;
    }

    if (Array.isArray(personas)) {
      return (
        <Box>
          {personas.map(persona => {
            if (!persona.cleanName) {
              return <></>;
            }
            return (
              <Chip
                label={<TextWithMaxWidth label={persona.cleanName} width={CHIP_MAX_WIDTH} />}
                key={persona.id}
                deleteIcon={<HighlightOffSharpIcon />}
                onDelete={(): void => onChipDelete(persona)}
                sx={{
                  mr: previewStateMarginRight,
                  mb: previewStateMarginBottom,
                }}
              />
            );
          })}
        </Box>
      );
    } else {
      const cleanName = (personas as PersonaIDsRequired).cleanName;
      if (!cleanName) {
        return <></>;
      }
      return (
        <Chip
          label={<TextWithMaxWidth label={cleanName} width={CHIP_MAX_WIDTH} />}
          key={(personas as PersonaIDsRequired).id}
          deleteIcon={<HighlightOffSharpIcon />}
          onDelete={(): void => onChipDelete(personas as PersonaIDsRequired)}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      );
    }
  }
);

export const ExcludedCustomTitles = React.memo(() => {
  const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
  const excludedTitles = useWatch({ control, name: EXCLUDED_TITLES_NAME });
  const personasMostRecentOnly = useWatch({ control, name: PERSONAS_MOST_RECENT_ONLY_NAME });

  const handleEnter = React.useCallback(
    (title: string) => {
      let newTitles: string[] = [];
      if (excludedTitles) {
        newTitles = newTitles.concat(excludedTitles);
      }

      newTitles.push(title);
      setValue(EXCLUDED_TITLES_NAME, newTitles);
    },
    [excludedTitles, setValue]
  );

  const onChipDelete = React.useCallback(
    (titleToDelete: string) => {
      return setValue(
        EXCLUDED_TITLES_NAME,
        excludedTitles.filter(title => title !== titleToDelete)
      );
    },
    [excludedTitles, setValue]
  );

  const personasMostRecentOnlySwitch = React.useMemo(() => {
    return (
      <FormControlLabel
        sx={{
          margin: "0px",
        }}
        checked={personasMostRecentOnly}
        onChange={(e: any): void => {
          setValue(PERSONAS_MOST_RECENT_ONLY_NAME, e.target.checked);
        }}
        control={<StyledSwitch />}
        label={<BodySmall color={colors.grayscale.gray700}>Most recent position only</BodySmall>}
      />
    );
  }, [personasMostRecentOnly, setValue]);

  return (
    <Stack spacing={1} paddingTop="5px">
      {personasMostRecentOnlySwitch}
      <TextField
        subTitle="Excluded Titles"
        required={true}
        placeholderText={"Enter a title, then press 'Enter'"}
        handleEnter={handleEnter}
        clearOnEnter={true}
      />
      <Box>
        {excludedTitles &&
          excludedTitles.map(title => {
            return (
              <Chip
                label={<TextWithMaxWidth label={title} width={CHIP_MAX_WIDTH} />}
                key={title}
                deleteIcon={<HighlightOffSharpIcon />}
                onDelete={(): void => onChipDelete(title)}
                sx={{
                  mr: previewStateMarginRight,
                  mb: previewStateMarginBottom,
                }}
              />
            );
          })}
      </Box>
    </Stack>
  );
});

const CustomizablePersonaSection = React.memo(
  ({
    persona,
    onChipDelete,
    setIsCustomizeTitlesModalOpen,
  }: {
    persona: PersonaIDsRequired;
    onChipDelete: (value: PersonaIDsRequired) => void;
    setIsCustomizeTitlesModalOpen: (persona: PersonaIDsRequired | null) => void;
  }): React.ReactElement => {
    const [isSearchesUsingPersonaModalOpen, setIsSearchesUsingPersonaModalOpen] = React.useState(false);

    const search = useGetSearchFromUrl();
    const actualSearchId = React.useMemo(() => {
      return search?.id;
    }, [search?.id]);
    const personaIsCustom = React.useMemo(() => {
      return !!persona.scopedToClient;
    }, [persona.scopedToClient]);

    const { data: searchesUsingPersona, isLoading } = useListSearchesUsingPersonaQuery(
      personaIsCustom && actualSearchId ? { personaId: persona.id, searchId: actualSearchId } : skipToken
    );

    const otherSearchesUsingPersona = React.useMemo(() => {
      return searchesUsingPersona?.filter(search => search.id != actualSearchId);
    }, [actualSearchId, searchesUsingPersona]);

    ///////////////////////////////////////////////
    // other searches using persona warning modal /
    ///////////////////////////////////////////////

    const dialogActionButtons = React.useMemo(() => {
      return (
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Secondary} onClick={(): void => setIsSearchesUsingPersonaModalOpen(false)}>
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              onClick={(): void => {
                setIsSearchesUsingPersonaModalOpen(false);
                setIsCustomizeTitlesModalOpen(persona);
              }}
              disabled={isLoading}
            >
              Proceed
            </Button>
          </Stack>
        </Stack>
      );
    }, [isLoading, persona, setIsCustomizeTitlesModalOpen]);

    const otherSearchesUsingPersonaInnerBody = React.useMemo(() => {
      return (
        <Stack spacing={1}>
          <Body>
            Other searches are using this persona too. Modifications here will also affect the following searches:
          </Body>
          <Stack spacing={1} maxHeight="150px">
            {otherSearchesUsingPersona?.map(search => {
              return (
                <li>
                  <InternalLink to={`/sourcing/${search.id}`}>{search.name}</InternalLink>
                </li>
              );
            })}
          </Stack>
        </Stack>
      );
    }, [otherSearchesUsingPersona]);

    // The actual modal
    const otherSearchesUsingPersonaModal = React.useMemo(() => {
      return (
        <CustomModal
          open={isSearchesUsingPersonaModalOpen}
          onClose={(): void => setIsSearchesUsingPersonaModalOpen(false)}
          title={<Subtitle1 weight="600">{"Customize Persona?"}</Subtitle1>}
          maxWidth={"xs"}
          omitDividers={true}
          dialogActions={dialogActionButtons}
        >
          {otherSearchesUsingPersonaInnerBody}
        </CustomModal>
      );
    }, [dialogActionButtons, isSearchesUsingPersonaModalOpen, otherSearchesUsingPersonaInnerBody]);

    //////////////////////////////////////////
    // The actual persona chip and pencil icon
    //////////////////////////////////////////

    const chipLabel = React.useMemo(() => {
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          height="32px"
          marginLeft="5px"
        >
          <TextWithMaxWidth label={persona.cleanName || ""} width={`${FILTERS_WIDTH_INT * 0.45}px`} />
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            display={"flex"}
            justifyContent={"center"}
          >
            <PencilIcon
              onClick={(): void => {
                if (otherSearchesUsingPersona?.length) {
                  setIsSearchesUsingPersonaModalOpen(true);
                } else {
                  setIsCustomizeTitlesModalOpen(persona);
                }
              }}
              className="svg-fill"
              color={colors.muiChipGray}
            />
          </Box>
        </Stack>
      );
    }, [persona, otherSearchesUsingPersona?.length, setIsCustomizeTitlesModalOpen]);

    return (
      <>
        <Chip
          label={chipLabel}
          key={persona.id}
          deleteIcon={<HighlightOffSharpIcon />}
          onDelete={(): void => onChipDelete(persona)}
          sx={{
            width: "100%",
            ".MuiChip-label": {
              width: "100%",
            },
            padding: 0,
            margin: 0,
          }}
        />
        {otherSearchesUsingPersonaModal}
      </>
    );
  }
);

const PersonasFiltersContentCustomizeTitles = React.memo(
  (): React.ReactElement => {
    const [
      customizeTitlesModalOpenWithPersona,
      setCustomizeTitlesModalOpenWithPersona,
    ] = React.useState<PersonaIDsRequired | null>(null);

    const {
      control,
      setValue,
      formState: { errors },
    } = useFormContext<SearchV3FormSchemaType>();
    const personas = useWatch({ control, name: PERSONAS_ELEMENTS_NAME });

    const onChipDelete = React.useCallback(
      (value: PersonaIDsRequired) => {
        return setValue(
          PERSONAS_ELEMENTS_NAME,
          personas.filter(persona => persona.id !== value.id)
        );
      },
      [personas, setValue]
    );

    const { data: personasOptions, isLoading: isPersonasLoading } = useListPersonasQuery({ limit: 1000 });

    const sortPersonasHelper = (persona: PersonaIDsRequired): string => {
      // we want personas to not only be sorted by their classification, but also by their name
      return persona.jobCategoryDisplay + persona.cleanName;
    };

    const alphabetizedPersonasOptions = React.useMemo(() => {
      if (!personasOptions) {
        return [];
      }

      // we need to alphabetise the list of personas
      const alphabetizedPersonas = sortBy(personasOptions, persona => sortPersonasHelper(persona));

      return [...alphabetizedPersonas];
    }, [personasOptions]);

    const personasWithCleanNames = React.useMemo(() => {
      return personas?.filter(personaOption => personaOption.cleanName && personaOption.cleanName.length);
    }, [personas]);

    if (!alphabetizedPersonasOptions) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    const setPersonasValues = (newPersonas: PersonaIDsRequired[]): void => {
      setValue(PERSONAS_ELEMENTS_NAME, newPersonas);

      // if the most recently added persona is the custom one, then we want to open its 'customize patterns' modal
      if (newPersonas.length && newPersonas[newPersonas.length - 1].cleanName === "Custom") {
        // open custom titles model with custom persona
        setCustomizeTitlesModalOpenWithPersona(newPersonas[newPersonas.length - 1]);
      }
    };

    const hasCustomPersonaError = errors["personas"]?.message === CUSTOM_PERSONA_SELECTED_ERROR;
    const noPersonaSelectedError = errors["personas"]?.message === PERSONA_REQUIRED_ERROR;

    return (
      <Stack spacing={1.5}>
        <NoValuesAutocomplete
          getOptionLabel={(option: PersonaIDsRequired): string => option.cleanName!} // safe assert because of how we define personasWithCleanNames
          options={alphabetizedPersonasOptions ?? []}
          optionsLoading={isPersonasLoading}
          placeholder={!personasWithCleanNames.length ? "Search for a persona..." : undefined}
          onSelect={setPersonasValues}
          initialValues={personasWithCleanNames}
          isOptionEqualToValue={(option: PersonaIDsRequired, value: PersonaIDsRequired): boolean => {
            return option.id === value.id;
          }}
          groupBy={(option: PersonaIDsRequired): string => option.jobCategoryDisplay}
          renderOption={(props, option: PersonaIDsRequired): React.ReactElement => {
            return (
              // @ts-ignore
              <StyledListItem {...props} key={option.id}>
                <Stack direction="column" spacing={0.5}>
                  <Body>{option.cleanName}</Body>
                  {option.definition && <BodySmall color={colors.grayscale.gray500}>{option.definition}</BodySmall>}
                </Stack>
              </StyledListItem>
            );
          }}
          renderTags={(value: PersonaIDsRequired[]): React.ReactElement => {
            return (
              <Stack alignItems="flex-end" spacing={1}>
                {value.map((persona: PersonaIDsRequired) => {
                  return (
                    <CustomizablePersonaSection
                      persona={persona}
                      onChipDelete={onChipDelete}
                      setIsCustomizeTitlesModalOpen={setCustomizeTitlesModalOpenWithPersona}
                    />
                  );
                })}
                {hasCustomPersonaError && (
                  <Caption color={colors.critical.base}>
                    {"Customize your persona by pressing the pencil icon, then press 'Save'."}
                  </Caption>
                )}
              </Stack>
            );
          }}
        />
        {customizeTitlesModalOpenWithPersona && (
          <CustomizeTitlesModal
            persona={customizeTitlesModalOpenWithPersona}
            isModalOpen={!!customizeTitlesModalOpenWithPersona}
            toggleModalOff={(): void => setCustomizeTitlesModalOpenWithPersona(null)}
          />
        )}
        {noPersonaSelectedError && <Caption color={colors.critical.base}>Select a persona to see results</Caption>}
        <AdvancedAccordion title="Advanced" expandedContent={<ExcludedCustomTitles />} />
      </Stack>
    );
  }
);

const PersonasFilters = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const personas = useWatch({ control, name: PERSONAS_ELEMENTS_NAME });
    const isBasePlanCustomer = useIsBasePlanCustomer();

    const expandedOverride = React.useMemo(() => {
      return isBasePlanCustomer && personas?.length > 0 && personas[0].name === CUSTOM_PERSONA_NAME;
    }, [isBasePlanCustomer, personas]);

    const RoleTypeHeaderTitle = (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Overline color={colors.grayscale.gray500}>Role type</Overline>
        <Tooltip title="Dover’s built-in types use current and past position titles, and handles alternate phrasings with intelligent categorization.">
          <HelpIcon />
        </Tooltip>
      </Stack>
    );

    return (
      <>
        <FilterSectionHeader title={RoleTypeHeaderTitle} icon={PeopleIcon} />
        <FilterAccordion
          title={!personas?.length ? "Role type" : undefined}
          TitleContent={PersonasFilterTitleContent}
          expandedContent={<PersonasFiltersContentCustomizeTitles />}
          errorTextKey={"personas"}
          expandedOverride={expandedOverride}
        />
      </>
    );
  }
);

export default PersonasFilters;
