import { createEntityAdapter, EntityState, SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { CURRENT_ONBOARDING_STEP, LIST_TAG, USER_ONBOARDING_FLOW } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiListUserOnboardingFlowsRequest,
  GetOrCreateUserOnboardingFlowRequest,
  UserOnboardingFlow,
  UserOnboardingFlowOnboardingStateEnum,
  MarkOnboardingCompleteResponse,
} from "services/openapi";
import { CurrentUserOnboardingStepResponse } from "services/openapi/models/CurrentUserOnboardingStepResponse";
import { DjangoListResponseType } from "types";
import { showErrorToast } from "utils/showToast";

const userOnboardingFlowAdapter = createEntityAdapter<UserOnboardingFlow>();

const userOnboardingFlowEndpointsTemp = doverApi.injectEndpoints({
  endpoints: build => ({
    listUserOnboardingFlows: build.query<EntityState<UserOnboardingFlow>, ApiApiListUserOnboardingFlowsRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DjangoListResponseType<UserOnboardingFlow>;
        try {
          response = await client.listUserOnboardingFlows({ ...args });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return {
          data: userOnboardingFlowAdapter.addMany(userOnboardingFlowAdapter.getInitialState(), response.results),
        };
      },
      providesTags: result => {
        return result
          ? [
              ...result.ids.map(id => ({ type: USER_ONBOARDING_FLOW, id } as const)),
              { type: USER_ONBOARDING_FLOW, id: LIST_TAG },
            ]
          : [{ type: USER_ONBOARDING_FLOW, id: LIST_TAG }];
      },
    }),
    getUserOnboardingFlow: build.query<UserOnboardingFlow, string>({
      queryFn: async userOnboardingFlowId => {
        const { apiApi: client } = await getOpenApiClients({});
        let result;
        try {
          result = await client.retrieveUserOnboardingFlow({ id: userOnboardingFlowId });
        } catch (error) {
          showErrorToast("Failed to load onboarding flow");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      providesTags: result => (result ? [{ type: USER_ONBOARDING_FLOW, id: result.id }] : []),
    }),
    getOrCreateUserOnboardingFlow: build.mutation<UserOnboardingFlow, GetOrCreateUserOnboardingFlowRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.getOrCreateUserOnboardingFlow({ data: args });
        } catch (error) {
          showErrorToast("Failed to load onboarding flow");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: result =>
        result
          ? [
              { type: USER_ONBOARDING_FLOW, id: result.id },
              { type: USER_ONBOARDING_FLOW, id: LIST_TAG },
            ]
          : [],
    }),
    getCurrentOnboardingStepForUser: build.query<CurrentUserOnboardingStepResponse, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        let result;
        try {
          result = await client.getCurrentOnboardingStepForUser();
        } catch (error) {
          showErrorToast("Failed to load onboarding flow");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      providesTags: result =>
        result?.currentFlowId ? [{ type: CURRENT_ONBOARDING_STEP, id: result.currentFlowId }] : [],
    }),
    markOnboardingComplete: build.mutation<MarkOnboardingCompleteResponse, boolean | undefined>({
      queryFn: async force => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const data = await apiApi.markOnboardingComplete({ data: { force } });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => [{ type: CURRENT_ONBOARDING_STEP, id: result?.onboardingFlowId }],
    }),
  }),
});

const userOnboardingFlowEndpoints = userOnboardingFlowEndpointsTemp.injectEndpoints({
  endpoints: build => ({
    updateUserOnboardingFlow: build.mutation<
      UserOnboardingFlow,
      { userOnboardingFlowId: string; data: UserOnboardingFlow }
    >({
      queryFn: async ({ userOnboardingFlowId, data }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.updateUserOnboardingFlow({ id: userOnboardingFlowId, data });
        } catch (error) {
          showErrorToast("Failed to save onboarding. Please try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchUpdate = dispatch(
          userOnboardingFlowEndpointsTemp.util.updateQueryData(
            "getUserOnboardingFlow",
            args.userOnboardingFlowId,
            (draft: UserOnboardingFlow | undefined) => {
              if (draft) {
                draft = { ...draft, ...args.data };
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchUpdate.undo();
        }
      },
      invalidatesTags: (result, error, arg) => {
        // If the updated onboarding flow is completed, invalidate the current onboarding step for the user
        if (result?.onboardingState === UserOnboardingFlowOnboardingStateEnum.Completed) {
          return [
            { type: USER_ONBOARDING_FLOW, id: arg.userOnboardingFlowId },
            { type: USER_ONBOARDING_FLOW, id: LIST_TAG },
            { type: CURRENT_ONBOARDING_STEP, id: arg.userOnboardingFlowId },
          ];
        }
        // Otherwise, just invalidate the updated onboarding flow
        return [
          { type: USER_ONBOARDING_FLOW, id: arg.userOnboardingFlowId },
          { type: USER_ONBOARDING_FLOW, id: LIST_TAG },
        ];
      },
    }),
  }),
});

export const {
  useListUserOnboardingFlowsQuery,
  useGetUserOnboardingFlowQuery,
  useGetOrCreateUserOnboardingFlowMutation,
  useUpdateUserOnboardingFlowMutation,
  useGetCurrentOnboardingStepForUserQuery,
  useMarkOnboardingCompleteMutation,
} = userOnboardingFlowEndpoints;
