import { useBulkOutreachApi } from "sections/addcandidate/services/api/bulkOutreachImpl";
import { IAddCandidate } from "sections/addcandidate/services/api/interface";
import { useInterviewPipelineApi } from "sections/addcandidate/services/api/interviewPipelineImpl";
import { useSingleOutreachApi } from "sections/addcandidate/services/api/singleOutreachImpl";
import { FlowType, FlowTypes, FlowState, OutreachType, OutreachTypes } from "sections/addcandidate/types";

export const useAddCandidateImplementation = (
  flowType: FlowType,
  flowState: FlowState
): ((outreachType?: OutreachType | null) => IAddCandidate) => {
  const singleOutreachApi = useSingleOutreachApi(flowState);
  const bulkOutreachApi = useBulkOutreachApi(flowState);
  const InterviewPipelineApi = useInterviewPipelineApi(flowState);

  return (outreachType?: OutreachType | null): IAddCandidate => {
    switch (flowType) {
      case FlowTypes.INITIAL_OUTREACH:
        if (outreachType === OutreachTypes.BULK) {
          return bulkOutreachApi;
        } else {
          return singleOutreachApi;
        }
      case FlowTypes.INTERVIEW_PIPELINE:
        return InterviewPipelineApi;
      default:
        throw new Error("Not implemented");
    }
  };
};
