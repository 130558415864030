/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewPanelSubstage,
    InterviewPanelSubstageFromJSON,
    InterviewPanelSubstageFromJSONTyped,
    InterviewPanelSubstageToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewPanel
 */
export interface InterviewPanel {
    /**
     * The original HiringPipelineStage on which this interview panel is based. This must be tied to an InterviewStage or a MultipartInterviewStage, as it'll determine any additional validation we need to perform.
     * @type {string}
     * @memberof InterviewPanel
     */
    hiringPipelineStage?: string;
    /**
     * List of substages for this interview panel.
     * @type {Array<InterviewPanelSubstage>}
     * @memberof InterviewPanel
     */
    substages: Array<InterviewPanelSubstage>;
}

export function InterviewPanelFromJSON(json: any): InterviewPanel {
    return InterviewPanelFromJSONTyped(json, false);
}

export function InterviewPanelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewPanel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hiringPipelineStage': !exists(json, 'hiring_pipeline_stage') ? undefined : json['hiring_pipeline_stage'],
        'substages': ((json['substages'] as Array<any>).map(InterviewPanelSubstageFromJSON)),
    };
}

export function InterviewPanelToJSON(value?: InterviewPanel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hiring_pipeline_stage': value.hiringPipelineStage,
        'substages': ((value.substages as Array<any>).map(InterviewPanelSubstageToJSON)),
    };
}


