import { Box } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

import { RequiredAsterisk, StageDrawerFormLabel } from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import InterviewDetailsField from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/InterviewDetailsField";

export const EditAdditionalInstructions = ({
  fieldPrefix,
  required,
  stageData,
}: {
  fieldPrefix: string;
  required: boolean;
  stageData: StageDataWrapper;
}): React.ReactElement => {
  return (
    <Box>
      <StageDrawerFormLabel bold>
        Additional instructions {required && <RequiredAsterisk>*</RequiredAsterisk>}
      </StageDrawerFormLabel>
      <Field
        name={fieldPrefix + ".interviewDetails"}
        parse={(value): any => value}
        validate={(value): boolean => {
          return required && !value;
        }}
      >
        {(props): React.ReactElement => {
          return <InterviewDetailsField {...props} stageName={stageData.name} required={required} hideLabel />;
        }}
      </Field>
    </Box>
  );
};
