import React from "react";
import styled, { keyframes } from "styled-components";

const ellipsisAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 20px;
  }
`;

const AnimatedLoadingEllipsis = styled.div`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation-name: ${ellipsisAnimation};
    animation-timing-function: steps(6, end);
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    content: "\\2026";
  }
`;

const LoadingEllipsis = (): React.ReactElement => {
  return <AnimatedLoadingEllipsis />;
};
export default LoadingEllipsis;
