import styled from "styled-components";

import { theme } from "components/data-driven-forms/styles/theme";
import { StyledFormLabel as DefaultStyledFormLabel } from "components/inputs/common";

export const StyledFormLabel = styled(DefaultStyledFormLabel)`
  &.MuiFormLabel-root {
    padding-bottom: 56px;
  }
`;

export default styled.div`
  .MuiSlider-thumb {
    width: 13px;
    height: 13px;
    display: flex;
    position: absolute;
    box-sizing: border-box;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    margin-left: -6px;
    border-radius: 50%;
    justify-content: center;
    background-color: ${theme.colors.white};
    border: 1px ${theme.colors.highlightBlue} solid;
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 10px rgba(63, 81, 181, 0.16);
    background-color: ${theme.colors.highlightBlue};
  }

  .MuiSlider-track {
    height: 3px;
    display: block;
    position: absolute;
    border-radius: 1px;
    background-color: ${theme.colors.highlightBlue};
  }

  .MuiSlider-markLabel {
    top: -20px;
    color: ${theme.colors.gray30};
    position: absolute;
    transform: translateX(-50%);
    font-size: ${theme.inputs.bold.fontSize};
    font-family: "${theme.inputs.bold.fontFamily}";
    font-weight: ${theme.inputs.bold.fontWeight};
    line-height: ${theme.inputs.bold.lineHeight};
    white-space: nowrap;
    letter-spacing: 0.01071em;
  }

  .MuiSlider-rail {
    width: 100%;
    height: 3px;
    display: block;
    opacity: 0.38;
    position: absolute;
    border-radius: 1px;
    background-color: ${theme.colors.gray30};
  }

  .MuiSlider-markActive {
    display: none;
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-marked {
    margin-bottom: 0px;
  }
`;
