import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as EqualsIcon } from "assets/icons/equals.svg";
import Accordion from "components/accordion";
import {
  DEFAULT_CANDIDATE_EMAIL_INTEREST_RATE,
  MAX_NUMBER_OF_EMAILS_PER_WEEK,
} from "components/dover/dover-outbound-modal/constants";
import { getDoverOutboundConfigOptionFromEmailsPerWeek } from "components/dover/dover-outbound-modal/utils";
import { Banner, BannerVariant } from "components/library/Banner";
import { TextField } from "components/library/TextField";
import { BodySmall, Heading, Subtitle2 } from "components/library/typography";
import { useGetDoverOutboundConfigurationQuery } from "services/doverapi/endpoints/jobFulfillment";
import { colors } from "styles/theme";

const DoverEmailVolumeTabContent = ({
  numInterestedCandidates,
  setNumInterstedCandidates,
  selectedEmailsPerWeek,
  setSelectedEmailsPerWeek,
  numInterestedCandidatesValid,
  selectedEmailsPerWeekValid,
}: {
  numInterestedCandidates: number;
  setNumInterstedCandidates: (num: number) => void;
  selectedEmailsPerWeek: number | undefined;
  setSelectedEmailsPerWeek: (num: number | undefined) => void;
  numInterestedCandidatesValid: boolean;
  selectedEmailsPerWeekValid: boolean;
}): React.ReactElement => {
  // Needed to avoid infinite useEffect loop
  const [unitInControl, setUnitInControl] = React.useState<"candidates" | "emails" | undefined>();

  const { jobId } = useParams<{ jobId: string }>();

  const { interestedRate, isFetching } = useGetDoverOutboundConfigurationQuery(jobId ?? skipToken, {
    selectFromResult: ({ data, isFetching }) => {
      return {
        doverOutboundConfigOption: getDoverOutboundConfigOptionFromEmailsPerWeek(data?.emailsPerWeekOption),
        phoneScreensPerWeek: data?.phoneScreensPerWeek,
        interestedRate: data?.interestedRate,
        isFetching,
      };
    },
  });

  const interestRateToUse = interestedRate ?? DEFAULT_CANDIDATE_EMAIL_INTEREST_RATE;

  useEffect(() => {
    if (unitInControl === "emails" && selectedEmailsPerWeek) {
      setNumInterstedCandidates(Math.ceil(selectedEmailsPerWeek * interestRateToUse));
    }
  }, [selectedEmailsPerWeek, setNumInterstedCandidates, unitInControl, interestRateToUse]);

  useEffect(() => {
    const numEmailsPerCandidate = 1 / interestRateToUse;
    const numEmailsForNumInterestedCandidates = numInterestedCandidates / interestRateToUse;

    // If the user has selected a new number of emails per week, check if the number of interested candidates
    // is within the range of the new number of emails per week
    // If not, set the number of emails per week to the minimum number of emails per week that would
    // result in the number of interested candidates
    const emailsPerWeekWithinNewRange =
      !selectedEmailsPerWeek ||
      (Math.floor(numEmailsForNumInterestedCandidates - numEmailsPerCandidate) < selectedEmailsPerWeek &&
        numEmailsForNumInterestedCandidates > selectedEmailsPerWeek);

    if (unitInControl === "candidates" && numInterestedCandidates && !emailsPerWeekWithinNewRange) {
      let ceilNumEmailsPerWeek = Math.floor(numEmailsForNumInterestedCandidates);
      if (ceilNumEmailsPerWeek > MAX_NUMBER_OF_EMAILS_PER_WEEK) {
        ceilNumEmailsPerWeek = MAX_NUMBER_OF_EMAILS_PER_WEEK;
      }
      setSelectedEmailsPerWeek(ceilNumEmailsPerWeek);
    }
  }, [numInterestedCandidates, setSelectedEmailsPerWeek, unitInControl, selectedEmailsPerWeek, interestRateToUse]);

  return (
    <Stack minHeight="400px" justifyContent="space-between" py={1}>
      <Box />
      <Stack direction="row" justifyContent="center" spacing={3} alignItems="center">
        <StyledStack spacing={2}>
          <Subtitle2>New candidates emailed per week</Subtitle2>
          <TextField
            numbersOnly
            text={selectedEmailsPerWeek?.toString()}
            onTextUpdated={(updatedText): void => {
              setSelectedEmailsPerWeek(parseInt(updatedText));
            }}
            inputProps={{
              step: 20,
              min: 20,
              max: MAX_NUMBER_OF_EMAILS_PER_WEEK,
            }}
            onFocus={(): void => setUnitInControl("emails")}
            error={!selectedEmailsPerWeekValid}
          />
        </StyledStack>
        <CloseIcon
          color="inherit"
          width="30px"
          height="30px"
          style={{ color: colors.grayscale.gray500, position: "relative", top: "4px" }}
        />
        <StyledStack spacing={2}>
          <Subtitle2 style={{ position: "relative", top: "6px" }}>Estimated interested rate </Subtitle2>
          {interestRateToUse && !isFetching && <Heading>{(interestRateToUse * 100).toFixed(1)}%</Heading>}
        </StyledStack>
        <EqualsIcon width="30px" height="30px" className="svg-fill" color={colors.primary.base} />
        <StyledStack spacing={2}>
          <Subtitle2>Estimated interested candidates per week</Subtitle2>
          <TextField
            numbersOnly
            text={numInterestedCandidates.toString()}
            onTextUpdated={(updatedText): void => {
              setNumInterstedCandidates(parseInt(updatedText || "0"));
            }}
            inputProps={{
              step: 1,
              min: 1,
              max: Math.ceil(MAX_NUMBER_OF_EMAILS_PER_WEEK * interestRateToUse),
            }}
            onFocus={(): void => setUnitInControl("candidates")}
            error={!numInterestedCandidatesValid}
          />
        </StyledStack>
      </Stack>
      {(!numInterestedCandidatesValid || !selectedEmailsPerWeekValid) && (
        <Box mx={2}>
          <Banner variant={BannerVariant.Critical} fullWidth>
            <BodySmall>
              Please adjust your inputs to fall within range. The minimum number of new candidates contacted and
              estimated number of interested candidates is 1. The maximum number of new candidates contacted is{" "}
              {MAX_NUMBER_OF_EMAILS_PER_WEEK} to ensure email deliverability.
            </BodySmall>
          </Banner>
        </Box>
      )}
      <Stack spacing={1} p={2}>
        <Accordion title={"How does this work?"} titleColor={colors.link}>
          <>
            <BodySmall style={{ paddingTop: "10px" }}>
              You can adjust the new candidates sent emails per week, which will automatically adjust the estimated
              number of interested candidates you will receive per week. You can also adjust the estimated number of
              interested candidates and Dover will intelligently calculate the number of new candidates sent emails per
              week to achieve your targeted number of interested candidates.
            </BodySmall>
          </>
        </Accordion>
        <Banner variant={BannerVariant.Info} fullWidth>
          <BodySmall>
            Dover intelligently calculates the number of candidates to send outreach to in order to achieve your target
            number of interested candidates.
          </BodySmall>
        </Banner>
      </Stack>
    </Stack>
  );
};

const StyledStack = styled(Stack)`
  background-color: ${colors.grayscale.gray100};
  width: 165px;
  height: 115px;
  padding: 8px;
  border-radius: 4px;
`;

export default DoverEmailVolumeTabContent;
