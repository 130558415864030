import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { COLUMN_PADDING } from "components/dover/SearchesTable/SearchesTable";
import { BodySmall } from "components/library/typography";
import { useGetDoverOutboundConfigurationQuery } from "services/doverapi/endpoints/jobFulfillment";
import { useGetSearchV3DepthResultQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import { roundDepthCount } from "services/doverapi/endpoints/search-v3/utils";
import { SearchV3Params } from "services/openapi";
import { colors } from "styles/theme";

interface RemainingCellProps {
  searchParams: SearchV3Params;
  searchId?: string;
  jobId?: string;
}

export const RemainingCell = ({ searchParams, searchId, jobId }: RemainingCellProps): React.ReactElement => {
  const { data: depthCount } = useGetSearchV3DepthResultQuery(
    searchId ? { data: { params: searchParams, searchId: searchId } } : skipToken
  );

  const { data: outboundConfig } = useGetDoverOutboundConfigurationQuery(jobId || skipToken);

  const isCritical = React.useMemo(() => {
    if (outboundConfig?.emailsPerWeekOption) {
      return depthCount && depthCount.count < 1 * outboundConfig.emailsPerWeekOption;
    }
    return depthCount && depthCount.count < 50;
  }, [depthCount, outboundConfig]);

  const isWarning = React.useMemo(() => {
    if (outboundConfig?.emailsPerWeekOption) {
      return depthCount && depthCount.count < 2 * outboundConfig.emailsPerWeekOption;
    }

    return depthCount && depthCount.count < 300;
  }, [depthCount, outboundConfig]);

  const remainingText = React.useMemo(() => {
    if (depthCount === undefined) {
      return "Loading";
    }
    return roundDepthCount(depthCount?.count);
  }, [depthCount]);

  return (
    <Box padding={COLUMN_PADDING} display="flex">
      <BodySmall
        style={{
          backgroundColor: isCritical ? colors.critical.light : isWarning ? colors.warning.light : colors.primary.light,
          padding: "4px",
          borderRadius: "4px",
        }}
        color={isCritical ? colors.critical.base : isWarning ? colors.warning.base : colors.primary.base}
      >
        {remainingText}
      </BodySmall>
    </Box>
  );
};
