import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";
import React, { useState, FC } from "react";

import { ReactComponent as ActiveIcon } from "assets/icons/active-dot.svg";
import { ReactComponent as BriefcaseIcon } from "assets/icons/briefcase.svg";
import { ReactComponent as InactiveIcon } from "assets/icons/red-circle.svg";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Body, BodySmall, Caption, Subtitle1, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { StyledRadio } from "components/StyledMuiComponents";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import {
  useFindRelatedCandidatesQuery,
  useListContactEmailsQuery,
  useListContactPhoneNumbersQuery,
  useMergeContactsMutation,
} from "services/doverapi/endpoints/contact";
import { Contact, RelatedCandidate } from "services/openapi";
import { colors } from "styles/theme";

interface CandidateMergeModalProps extends GlobalModalProps {
  originalContact: Contact;
  duplicateContact: Contact;
}

export const CandidateMergeModal: FC<CandidateMergeModalProps> = ({
  isOpen,
  close,
  originalContact,
  duplicateContact,
}) => {
  const [mergeContacts, { isLoading }] = useMergeContactsMutation();

  const [selectedContact, setSelectedContact] = useState(originalContact);
  const secondaryContact = selectedContact.id === originalContact.id ? duplicateContact : originalContact;

  const genOnClick = (contact: Contact) => (): void => setSelectedContact(contact);
  const merge = async (): Promise<void> => {
    if (!selectedContact.id || !secondaryContact.id) {
      console.error("Tried to merge with a missing contact id");
      return;
    }

    try {
      await mergeContacts({
        data: { primaryContactId: selectedContact.id, secondaryContactId: secondaryContact.id },
      }).unwrap();
    } finally {
      close();
    }
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={close}
      title="Merge Candidates"
      maxWidth="sm"
      dialogActions={
        <Button variant={ButtonVariant.Primary} onClick={merge} disabled={isLoading}>
          Merge Candidates
        </Button>
      }
    >
      {isLoading ? (
        <Progress />
      ) : (
        <Stack spacing={1.5}>
          <Stack spacing={0.5}>
            <Subtitle1>Select primary candidate</Subtitle1>
            <BodySmall>{`If there are conflicts in data, this person's information will be used`}</BodySmall>
            <Box borderRadius="4px" p={2} bgcolor={colors.grayscale.gray100}>
              <Stack spacing={1}>
                <ContactCard
                  isCurrent
                  contact={originalContact}
                  isSelected={selectedContact.id === originalContact.id}
                  onClick={genOnClick(originalContact)}
                />
                <ContactCard
                  contact={duplicateContact}
                  isSelected={selectedContact.id === duplicateContact.id}
                  onClick={genOnClick(duplicateContact)}
                />
              </Stack>
            </Box>
          </Stack>
          <Stack spacing={0.5}>
            <Subtitle1>Preview of Changes</Subtitle1>
            <Box borderRadius="4px" p={2} bgcolor={colors.grayscale.gray100}>
              <PreviewCard primaryContact={selectedContact} secondaryContact={secondaryContact} />
            </Box>
          </Stack>
          <Banner variant={BannerVariant.Warning} centeredIcon>
            <Body color={colors.grayscale.gray600}>This action cannot be undone</Body>
          </Banner>
        </Stack>
      )}
    </CustomModal>
  );
};

interface ContactCardProps {
  contact: Contact;
  isSelected: boolean;
  onClick: () => void;
  isCurrent?: boolean;
}

const ContactCard: FC<ContactCardProps> = ({ contact, isSelected, onClick, isCurrent }) => {
  const args = contact.id ? { contactId: contact.id } : skipToken;
  const { data: relatedCandidates } = useFindRelatedCandidatesQuery(args);
  const { data: emails } = useListContactEmailsQuery(args);
  const { data: phoneNumbers } = useListContactPhoneNumbersQuery(args);

  const sortedRelatedCandidates = [...(relatedCandidates ?? [])].sort(sortRelatedCandidates);
  return (
    <Card hoverable onClick={onClick}>
      <Box sx={{ display: "grid", gap: "1rem", gridTemplateColumns: "1fr 5fr 5fr" }}>
        <StyledRadio
          checked={isSelected}
          disableRipple
          sx={{
            alignSelf: "start",
            "&.MuiRadio-root:hover": {
              backgroundColor: "transparent !important",
            },
          }}
        />
        <Stack spacing={0.5}>
          <Subtitle2>{`${contact.fullName} ${isCurrent ? "(current)" : ""}`}</Subtitle2>
          {contact.headline && <BodySmall color={colors.grayscale.gray600}>{contact.headline}</BodySmall>}
          {emails && emails.results.map(e => <BodySmall color={colors.grayscale.gray600}>{e.email}</BodySmall>)}
          {phoneNumbers &&
            phoneNumbers.results.map(p => <BodySmall color={colors.grayscale.gray600}>{p.phoneNumber}</BodySmall>)}
        </Stack>
        <Stack spacing={0.5}>
          {sortedRelatedCandidates.map(c => (
            <>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <BriefcaseIcon />
                <Body>{c.jobTitle}</Body>
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                {c.status ? <InactiveIcon /> : <ActiveIcon />}
                <Caption color={colors.grayscale.gray600}>
                  {c.currentPipelineStage?.name} - Created {dayjs(c.created).format("M/D/YY")}
                </Caption>
              </Stack>
            </>
          ))}
        </Stack>
      </Box>
    </Card>
  );
};

interface PreviewCardProps {
  primaryContact: Contact;
  secondaryContact: Contact;
}

const PreviewCard: FC<PreviewCardProps> = ({ primaryContact, secondaryContact }) => {
  // Combine all the different related candidates into one list and sort them by most recently created
  const { data: primaryRelatedCandidates } = useFindRelatedCandidatesQuery(
    primaryContact.id ? { contactId: primaryContact.id } : skipToken
  );

  const { data: secondaryRelatedCandidates } = useFindRelatedCandidatesQuery(
    secondaryContact.id ? { contactId: secondaryContact.id } : skipToken
  );

  const relatedCandidates = (primaryRelatedCandidates && secondaryRelatedCandidates
    ? [...primaryRelatedCandidates, ...secondaryRelatedCandidates]
    : []
  ).sort(sortRelatedCandidates);

  // Get the emails and phone numbers for both contacts and union them together
  const primaryEmailsArgs = primaryContact.id ? { contactId: primaryContact.id } : skipToken;
  const primaryPhoneNumbersArgs = primaryContact.id ? { contactId: primaryContact.id } : skipToken;
  const secondaryEmailsArgs = secondaryContact.id ? { contactId: secondaryContact.id } : skipToken;
  const secondaryPhoneNumbersArgs = secondaryContact.id ? { contactId: secondaryContact.id } : skipToken;
  const { data: primaryEmails } = useListContactEmailsQuery(primaryEmailsArgs);
  const { data: primaryPhoneNumbers } = useListContactPhoneNumbersQuery(primaryPhoneNumbersArgs);
  const { data: secondaryEmails } = useListContactEmailsQuery(secondaryEmailsArgs);
  const { data: secondaryPhoneNumbers } = useListContactPhoneNumbersQuery(secondaryPhoneNumbersArgs);
  const emails = [...(primaryEmails?.results ?? []), ...(secondaryEmails?.results ?? [])];
  const phoneNumbers = [...(primaryPhoneNumbers?.results ?? []), ...(secondaryPhoneNumbers?.results ?? [])];

  return (
    <Card>
      <Stack spacing={1.5}>
        <Stack>
          <Subtitle2>{primaryContact.fullName}</Subtitle2>
          {primaryContact.headline && <BodySmall color={colors.grayscale.gray600}>{primaryContact.headline}</BodySmall>}
          {emails && emails.map(e => <BodySmall color={colors.grayscale.gray600}>{e.email}</BodySmall>)}
          {phoneNumbers &&
            phoneNumbers.map(p => <BodySmall color={colors.grayscale.gray600}>{p.phoneNumber}</BodySmall>)}
        </Stack>
        <Box sx={{ display: "grid", gap: "1rem", gridTemplateColumns: "3fr 2fr 2fr" }}>
          {relatedCandidates?.map(c => (
            <>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                {c.status ? <InactiveIcon /> : <ActiveIcon />}
                <BodySmall>{c.jobTitle}</BodySmall>
              </Stack>
              <BodySmall color={colors.grayscale.gray600}>{c.currentPipelineStage?.name}</BodySmall>
              <BodySmall color={colors.grayscale.gray600}>Created {dayjs(c.created).format("M/D/YY")}</BodySmall>
            </>
          ))}
        </Box>
      </Stack>
    </Card>
  );
};

const sortRelatedCandidates = (a: RelatedCandidate, b: RelatedCandidate): number => dayjs(b.created).diff(a.created);

export const candidateMergeModalAtom = modalAtom(CandidateMergeModal);
