import { Radio, RadioGroup, Skeleton, Stack } from "@mui/material";
import React, { ReactElement, SyntheticEvent } from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";

import CompensationField from "App/components/CompensationField";
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { InfoTip } from "components/InfoTip";
import { Autocomplete } from "components/library/Autocomplete";
import { Card } from "components/library/Card";
import { Checkbox } from "components/library/Checkbox";
import Toggle from "components/library/Toggle";
import { BodySmall, Subtitle1, Subtitle2 } from "components/library/typography";
import { Role, useContainsRole, useHasRole } from "components/RBAC";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListLocationOptionsQuery } from "services/doverapi/endpoints/job/endpoints";
import { LocationOption } from "services/openapi";
import { INTERNATIONAL_LOCATION_NAME, RemotePolicies, VisaSupport } from "views/job/constants";
import {
  DetailsFormSchema,
  JOB_POSTING_SCROLL_MARGIN_TOP,
  LocationOptionSchema,
} from "views/job/JobSetup/steps/JobPosting/JobPosting";
import { filterLocationOptions, shouldShowRemoteRegions } from "views/job/utils";

/* -----------------------------------------------------------------------------
 * RadioButton
 * -------------------------------------------------------------------------- */

interface RadioButtonProps {
  value: boolean | RemotePolicies;
  checked: boolean;
  onChange: (value: boolean | RemotePolicies) => void;
  text: string;
}

const RadioButton = ({ value, checked, onChange, text }: RadioButtonProps): React.ReactElement => {
  return (
    <Stack
      direction="row"
      spacing={0.3}
      alignItems="center"
      onClick={(): void => {
        onChange(value);
      }}
      sx={{ cursor: "pointer" }}
    >
      <Radio value={value} checked={checked} sx={{ padding: 0 }} />
      <BodySmall>{text}</BodySmall>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * JobDetailsSection
 * -------------------------------------------------------------------------- */

interface JobDetailsSectionProps {
  locationRef: React.RefObject<HTMLDivElement>;
  id?: string;
  formattedInitialRemoteRegions?: LocationOptionSchema[];
  hasLocationError: boolean;
}

export const JobDetailsSection = ({
  locationRef,
  id,
  formattedInitialRemoteRegions,
  hasLocationError,
}: JobDetailsSectionProps): ReactElement => {
  // query params
  const jobId = useJobIdFromUrl();

  // local state
  const isAdminOrInterviewer = useHasRole(Role.ADMIN, Role.INTERVIEWER);
  const isHmOrRecruiterOrClientAdmin = useContainsRole([Role.CLIENT_ADMIN, Role.HIRING_MANAGER, Role.RECRUITER], jobId);
  const showCompFields = isAdminOrInterviewer || isHmOrRecruiterOrClientAdmin;

  // rtkq
  const { data: locationOptions, isLoading: isLoadingLocationOptions } = useListLocationOptionsQuery();

  const internationalRemoteRegion = React.useMemo(() => {
    return locationOptions?.remoteOptions.find(loc => loc.displayName === INTERNATIONAL_LOCATION_NAME);
  }, [locationOptions?.remoteOptions]);

  // form state
  const { errors } = useFormState<DetailsFormSchema>();
  const { watch, setValue, control, getValues } = useFormContext<DetailsFormSchema>();

  const { field: remoteRegionsField } = useController({
    name: "remoteRegions",
    control,
  });

  const { field: remotePolicyField } = useController({
    name: "remotePolicy",
    control,
  });
  const { field: supportVisasField } = useController({
    name: "supportVisas",
    control,
  });

  // derived

  // by default, require a specific location, that way when you switch to not requiring it
  // it'll select the international location in the onChange handler for the radio button
  const remoteFromAnywhereOk =
    remoteRegionsField.value?.length === 1 && remoteRegionsField.value[0].displayName === INTERNATIONAL_LOCATION_NAME;
  const requiredSpecific = !remoteFromAnywhereOk;

  const showRemoteRegions = remotePolicyField.value
    ? shouldShowRemoteRegions({
        remotePolicy: remotePolicyField.value,
        // if can't find international location ourselves, then request that they select a specific location
        requireSpecificLoc: requiredSpecific || !internationalRemoteRegion,
      })
    : false;
  const showOnsiteCities = [RemotePolicies.InOffice, RemotePolicies.Both].includes(remotePolicyField.value);

  // callbacks/functions
  const unrequireSpecificLoc = React.useCallback(() => {
    if (!internationalRemoteRegion) {
      console.error("Couldn't find international remote region, early return");
      return;
    }
    const formattedInternationalRegion = {
      displayName: internationalRemoteRegion.displayName,
      locationOption: internationalRemoteRegion.id,
    };
    remoteRegionsField.onChange([formattedInternationalRegion]);
  }, [internationalRemoteRegion, remoteRegionsField]);

  const requireSpecificLoc = (): void => {
    // if initial location was international then set it to nothing when they select "yes"
    if (formattedInitialRemoteRegions?.find(location => location.displayName === INTERNATIONAL_LOCATION_NAME)) {
      remoteRegionsField.onChange([]);
    } else {
      // reset the regions field as they dont even know they selected the "international" one
      remoteRegionsField.onChange(formattedInitialRemoteRegions ?? []);
    }
  };

  if (isLoadingLocationOptions) return <Skeleton height="300px" />;

  return (
    <Card ref={locationRef} style={{ scrollMarginTop: JOB_POSTING_SCROLL_MARGIN_TOP }} id={id}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          {hasLocationError && <InfoTip text={"Job details are required to post to job boards."} />}
          <Subtitle1>Job Details</Subtitle1>
        </Stack>

        <Stack spacing={1.5}>
          <Stack direction="row" spacing={0.5}>
            {!!errors.remotePolicy && <AlertIcon />}
            <Subtitle2>What is your remote policy for this job?</Subtitle2>
          </Stack>
          <RadioGroup name="remotePolicy" defaultValue={getValues("remotePolicy")}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
              <RadioButton
                value={RemotePolicies.InOffice}
                checked={remotePolicyField.value === RemotePolicies.InOffice}
                onChange={(): void => {
                  remotePolicyField.onChange(RemotePolicies.InOffice);
                }}
                text="In-office"
              />
              <RadioButton
                value={RemotePolicies.Both}
                checked={remotePolicyField.value === RemotePolicies.Both}
                onChange={(): void => remotePolicyField.onChange(RemotePolicies.Both)}
                text="In-office or remote"
              />
              <RadioButton
                value={RemotePolicies.Remote}
                checked={remotePolicyField.value === RemotePolicies.Remote}
                onChange={(): void => remotePolicyField.onChange(RemotePolicies.Remote)}
                text="Remote only"
              />
            </Stack>
          </RadioGroup>
          {/* If they select remote only, then give them the option to be specific or just allow worldwide */}
          {[RemotePolicies.Both, RemotePolicies.Remote].includes(remotePolicyField.value) &&
            !!internationalRemoteRegion && (
              <Stack spacing={0.5}>
                <Subtitle2>Are remote employees required to live in a specific location?</Subtitle2>
                <Stack direction="row" spacing={2}>
                  <RadioButton
                    value={requiredSpecific}
                    checked={requiredSpecific}
                    onChange={requireSpecificLoc}
                    text="Yes"
                  />
                  <RadioButton
                    value={!requiredSpecific}
                    checked={!requiredSpecific}
                    onChange={unrequireSpecificLoc}
                    text="No (can work from anywhere)"
                  />
                </Stack>
              </Stack>
            )}
        </Stack>
        {showOnsiteCities && (
          <Stack direction="row" spacing={0.3} alignItems="center">
            <Checkbox
              checked={watch("wfhAllowed")}
              onChange={(): void => setValue("wfhAllowed", !getValues("wfhAllowed"))}
            />
            <BodySmall>In-office employees may work from home a few days per week</BodySmall>
          </Stack>
        )}
        {showRemoteRegions && (
          <Stack spacing={0.5}>
            <Subtitle2>Remote regions</Subtitle2>
            <Autocomplete
              multiple
              errorText={errors.remoteRegions?.message}
              disabled={!locationOptions?.remoteOptions}
              placeholder="Remote regions"
              initialValues={watch("remoteRegions")}
              hideOptionsUntilLimit={1}
              staticOptions={
                locationOptions?.remoteOptions?.map(o => ({
                  displayName: o.displayName,
                  locationOption: o.id ?? "",
                })) ?? []
              }
              onSelectedOptionsChange={(
                values: {
                  displayName: string;
                  locationOption: string;
                }[]
              ): void => {
                setValue(
                  "remoteRegions",
                  values.sort((a, b) => {
                    return a.displayName > b.displayName ? 1 : -1;
                  })
                );
              }}
              filterOptions={(options, state): LocationOption[] => filterLocationOptions(options, state.inputValue)}
              isOptionEqualToValue={(option, value): boolean => option.displayName === value.displayName}
              getOptionLabel={(option): string => option.displayName}
              renderOption={(props, option): React.ReactElement => (
                <li {...props} key={option.locationOption}>
                  <BodySmall>{option.displayName}</BodySmall>
                </li>
              )}
            />
          </Stack>
        )}
        {showOnsiteCities && (
          <Stack spacing={0.5}>
            <Subtitle2>In-office cities</Subtitle2>
            <Autocomplete
              multiple
              errorText={errors.onsiteCities?.message}
              disabled={!locationOptions?.cities}
              placeholder="In-office cities"
              initialValues={watch("onsiteCities")}
              hideOptionsUntilLimit={1}
              staticOptions={
                locationOptions?.cities?.map(o => ({ displayName: o.displayName, locationOption: o.id ?? "" })) ?? []
              }
              onSelectedOptionsChange={(
                values: {
                  displayName: string;
                  locationOption: string;
                }[]
              ): void => {
                setValue(
                  "onsiteCities",
                  values.sort((a, b) => {
                    return a.displayName > b.displayName ? 1 : -1;
                  })
                );
              }}
              filterOptions={(options, state): LocationOption[] => filterLocationOptions(options, state.inputValue)}
              isOptionEqualToValue={(option, value): boolean => option.displayName === value.displayName}
              getOptionLabel={(option): string => option.displayName}
              renderOption={(props, option): React.ReactElement => (
                <li {...props} key={option.locationOption}>
                  <BodySmall>{option.displayName}</BodySmall>
                </li>
              )}
            />
          </Stack>
        )}
        {showCompFields && (
          <Stack spacing={1.5}>
            <Subtitle2>Annual compensation</Subtitle2>
            <CompensationField
              control={control}
              currencyCodeName="currencyCode"
              compLowerBoundName="compLowerBound"
              compUpperBoundName="compUpperBound"
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <Toggle
                checked={watch("openToSharingComp")}
                label="openToSharingComp"
                onChange={(event: SyntheticEvent<HTMLInputElement>): void => {
                  setValue("openToSharingComp", (event.target as HTMLInputElement).checked);
                }}
              />
              <BodySmall>Show compensation on job posting</BodySmall>
            </Stack>
          </Stack>
        )}
        <Stack spacing={1.5}>
          <Subtitle2>Are you open to sponsor a candidate&apos;s visa?</Subtitle2>
          <RadioGroup name="supportVisas" defaultValue={getValues("supportVisas")}>
            <Stack direction="row" spacing={1} alignItems="center">
              <RadioButton
                value={supportVisasField.value === VisaSupport.Yes}
                checked={supportVisasField.value === VisaSupport.Yes}
                onChange={(): void => supportVisasField.onChange(VisaSupport.Yes)}
                text="Yes"
              />
              <RadioButton
                value={supportVisasField.value === VisaSupport.No}
                checked={supportVisasField.value === VisaSupport.No}
                onChange={(): void => supportVisasField.onChange(VisaSupport.No)}
                text="No"
              />
            </Stack>
          </RadioGroup>
        </Stack>
      </Stack>
    </Card>
  );
};
