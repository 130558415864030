import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { AccordianVariant } from "components/accordion";
import { colors, filters } from "styles/theme";

export const TitleWrapper = styled.div<{ variant?: AccordianVariant }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: "row";
  justify-content: "flex-start";
  width: ${(props: any): string => (props.variant === AccordianVariant.Light ? "100%" : "")};
`;

export const StyledArrow = styled(ReactSVG)<{ small?: boolean; variant?: AccordianVariant }>`
  position: relative;
  bottom: ${(props): any => (props.small ? "3px" : "1px")};
  transform: rotate(0);
  transform-origin: 35% 55%;
  transition: transform 0.1s ease;

  &.open {
    transform: rotate(90deg);
  }

  svg {
    filter: ${(props: any): string => (props.variant === AccordianVariant.Light ? filters.filterWhite : "")};
  }
`;

interface TitleProps {
  color?: string;
  fontSize?: "14px" | "16px";
}

export const Title = styled.div<TitleProps>`
  margin-left: 8px;
  font-family: "Inter";
  font-size: ${(props): any => props.fontSize ?? "16px"};
  color: ${(props): any => props.color ?? colors.link};
`;

export const Text = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.1s ease;
  margin-left: 16px;
  font-family: "Inter";
  font-size: 16px;

  &.open {
    max-height: 90px;
  }
`;
