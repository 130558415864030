/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IIRFirstRoundFeedback,
    IIRFirstRoundFeedbackFromJSON,
    IIRFirstRoundFeedbackFromJSONTyped,
    IIRFirstRoundFeedbackToJSON,
    IRRContact,
    IRRContactFromJSON,
    IRRContactFromJSONTyped,
    IRRContactToJSON,
    JIRTJob,
    JIRTJobFromJSON,
    JIRTJobFromJSONTyped,
    JIRTJobToJSON,
} from './';

/**
 * 
 * @export
 * @interface IRRCandidate
 */
export interface IRRCandidate {
    /**
     * 
     * @type {string}
     * @memberof IRRCandidate
     */
    readonly id?: string;
    /**
     * 
     * @type {IRRContact}
     * @memberof IRRCandidate
     */
    contact: IRRContact;
    /**
     * 
     * @type {IIRFirstRoundFeedback}
     * @memberof IRRCandidate
     */
    feedback: IIRFirstRoundFeedback;
    /**
     * 
     * @type {JIRTJob}
     * @memberof IRRCandidate
     */
    job: JIRTJob;
    /**
     * 
     * @type {string}
     * @memberof IRRCandidate
     */
    status?: IRRCandidateStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof IRRCandidate
     */
    readonly preferredPhoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof IRRCandidate
     */
    readonly actionVersion?: number;
}

/**
* @export
* @enum {string}
*/
export enum IRRCandidateStatusEnum {
    Withdrew = 'WITHDREW',
    Hired = 'HIRED',
    Rejected = 'REJECTED',
    Snoozed = 'SNOOZED',
    Ignored = 'IGNORED'
}

export function IRRCandidateFromJSON(json: any): IRRCandidate {
    return IRRCandidateFromJSONTyped(json, false);
}

export function IRRCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRRCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contact': IRRContactFromJSON(json['contact']),
        'feedback': IIRFirstRoundFeedbackFromJSON(json['feedback']),
        'job': JIRTJobFromJSON(json['job']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'preferredPhoneNumber': !exists(json, 'preferred_phone_number') ? undefined : json['preferred_phone_number'],
        'actionVersion': !exists(json, 'action_version') ? undefined : json['action_version'],
    };
}

export function IRRCandidateToJSON(value?: IRRCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact': IRRContactToJSON(value.contact),
        'feedback': IIRFirstRoundFeedbackToJSON(value.feedback),
        'job': JIRTJobToJSON(value.job),
        'status': value.status,
    };
}


