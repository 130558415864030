import Papa from "papaparse";

import { OutreachBulkCandidateItem } from "services/openapi";

export function parseManualSourcingItemCSV(file: File): Promise<OutreachBulkCandidateItem[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      worker: true,
      header: true,
      complete: ({
        data,
      }: {
        data: { Email?: string; "Email Address"?: string; LinkedIn?: string; "LinkedIn URL"?: string }[];
      }) => {
        const manualSourcingItems = data
          .filter(item => {
            const url = item["LinkedIn URL"] || item.LinkedIn;
            return url && url.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/);
          })
          .map(item => {
            return {
              email: item.Email || item["Email Address"] || "",
              url: item.LinkedIn || item["LinkedIn URL"] || "",
            } as OutreachBulkCandidateItem;
          });
        resolve(manualSourcingItems);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
}
