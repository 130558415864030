import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  CLIENT,
  CLIENT_EMAIL_TEMPLATE,
  CLIENT_ONBOARDING,
  GET_EMAIL_TEMPLATE_V2,
  LIST_TAG,
} from "services/doverapi/endpointTagsConstants";
import {
  AdminClient,
  BaseClientCampaignLimit,
  CanonicalCompany,
  Client,
  ClientOnboarding,
  CompanySetupSteps,
  SlimSearchTemplate,
  UpdateClientBody,
  Company,
  ApiApiPatchClientOnboardingRequest,
  ClientEmailTemplate,
  ApiApiGetClientEmailTemplateRequest,
  ApiApiListClientEmailTemplatesRequest,
  ApiApiCreateClientEmailTemplateRequest,
  ApiApiPartialUpdateClientEmailTemplateRequest,
  ApiApiDeleteClientEmailTemplateRequest,
  ApiApiParseClientEmailTemplateRequest,
  ParseClientEmailTemplate,
  ClientImage,
  ApiApiApiV1ClientImagesCreateRequest,
} from "services/openapi";
import { reAuth } from "services/reAuth";
import { showErrorToast } from "utils/showToast";

export const clientEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getUsersClient: build.query<Client, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.getUsersClient({});
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: CLIENT, id: result.id } as const] : [];
      },
    }),
    partialUpdateClient: build.mutation<Client, { id: string; updatedClient: UpdateClientBody }>({
      queryFn: async ({ id, updatedClient }) => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const data = await apiApi.partialUpdateClient({ id, data: updatedClient });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return args.id
          ? [
              { type: CLIENT, id: args.id },
              {
                type: CLIENT_ONBOARDING,
                id: args.id,
              },
            ]
          : [];
      },
    }),
    getClientOnboarding: build.query<ClientOnboarding, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data: ClientOnboarding = await client.getClientOnboarding({});
          return { data };
        } catch (error) {
          showErrorToast("Failed to load client data. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: CLIENT_ONBOARDING, id: result.id } as const] : [];
      },
    }),
    patchClientOnboarding: build.mutation<ClientOnboarding, ApiApiPatchClientOnboardingRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const data = await client.patchClientOnboarding(args);
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return args.id ? [{ type: CLIENT_ONBOARDING, id: args.id }] : [];
      },
    }),
    getCompaniesByUrns: build.query<Company[], string[]>({
      queryFn: async requestedUrns => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client?.getCompaniesByUrn({ data: { urns: requestedUrns } });
          return { data: response };
        } catch (error) {
          const userFacingMessage = "Failed to get client level company exclusions. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getCompanySetupSteps: build.query<CompanySetupSteps, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.getCompanySetupSteps({});
          return { data: response };
        } catch (error) {
          const userFacingMessage = "Failed to load company settings. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: CLIENT_ONBOARDING, id: result.id } as const] : [];
      },
    }),
    adminGetClients: build.query<AdminClient[], void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.adminListClients({ limit: 10000 });
          return { data: response.results };
        } catch (error) {
          const userFacingMessage = "Failed to load admin clients. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    setClientAlias: build.mutation<{ success: boolean }, string>({
      queryFn: async clientId => {
        const { apiApi } = await getOpenApiClients({});

        try {
          await apiApi.setClientAlias({ data: { aliasClient: clientId } });
          reAuth();
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
        return { data: { success: true } };
      },
    }),
    createClientEmailTemplate: build.mutation<ClientEmailTemplate, ApiApiCreateClientEmailTemplateRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.createClientEmailTemplate(args);
          return { data: response };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, _) => {
        return result
          ? [
              { type: CLIENT_EMAIL_TEMPLATE, id: LIST_TAG } as const,
              { type: GET_EMAIL_TEMPLATE_V2, id: result?.id } as const,
            ]
          : [];
      },
    }),
    partialUpdateClientEmailTemplate: build.mutation<
      ClientEmailTemplate,
      ApiApiPartialUpdateClientEmailTemplateRequest
    >({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.partialUpdateClientEmailTemplate(args);
          return { data: response };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
      invalidatesTags: response => {
        return [
          { type: CLIENT_EMAIL_TEMPLATE, id: LIST_TAG } as const,
          { type: GET_EMAIL_TEMPLATE_V2, id: response?.id } as const,
        ];
      },
    }),
    deleteClientEmailTemplate: build.mutation<void, ApiApiDeleteClientEmailTemplateRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.deleteClientEmailTemplate(args);
          return { data: response };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
      invalidatesTags: () => {
        return [{ type: CLIENT_EMAIL_TEMPLATE, id: LIST_TAG } as const];
      },
    }),
    getClientEmailTemplate: build.query<ClientEmailTemplate, ApiApiGetClientEmailTemplateRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.getClientEmailTemplate(args);
          return { data: response };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
      providesTags: response => {
        return response ? [{ type: CLIENT_EMAIL_TEMPLATE, id: response.id }] : [];
      },
    }),
    parseClientEmailTemplate: build.mutation<ParseClientEmailTemplate, ApiApiParseClientEmailTemplateRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.parseClientEmailTemplate(args);
          return { data: response };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
    }),
    listClientEmailTemplates: build.query<
      { results: ClientEmailTemplate[]; count: number },
      ApiApiListClientEmailTemplatesRequest
    >({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.listClientEmailTemplates(args);
          return { data: response, count: response.count };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
      providesTags: response => {
        return response ? [{ type: CLIENT_EMAIL_TEMPLATE, id: LIST_TAG }] : [];
      },
    }),
    getCanonicalCompanyInfo: build.query<CanonicalCompany, void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response: CanonicalCompany = await apiApi.getCanonicalCompanyInfo({});
          return { data: response };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
    }),
    listSlimSearchTemplate: build.query<SlimSearchTemplate[], void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const response = await client.listSlimSearchTemplates({ limit: 1000, offset: 0 });
          return { data: response.results };
        } catch (error) {
          const userFacingMessage = "Failed to load personas. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getBaseClientCampaignLimits: build.query<BaseClientCampaignLimit, void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response: BaseClientCampaignLimit = await apiApi.getBaseClientCampaignLimits({});
          return { data: response };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
    }),
    getRemainingConciergeHours: build.query<number | null, void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.getRemainingConciergeHours();
          return { data: response.remainingConciergeHours };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
    }),
    uploadClientImage: build.mutation<ClientImage, ApiApiApiV1ClientImagesCreateRequest>({
      queryFn: async args => {
        const { apiApi } = await getOpenApiClients({});

        try {
          const response = await apiApi.apiV1ClientImagesCreate(args);
          return { data: response };
        } catch (err) {
          return {
            error: {
              serializedError: err as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useGetUsersClientQuery,
  usePartialUpdateClientMutation,
  useGetCompaniesByUrnsQuery,
  useGetClientOnboardingQuery,
  usePatchClientOnboardingMutation,
  useGetCompanySetupStepsQuery,
  useAdminGetClientsQuery,
  useSetClientAliasMutation,
  useGetClientEmailTemplateQuery,
  useListClientEmailTemplatesQuery,
  useCreateClientEmailTemplateMutation,
  usePartialUpdateClientEmailTemplateMutation,
  useDeleteClientEmailTemplateMutation,
  useGetCanonicalCompanyInfoQuery,
  useListSlimSearchTemplateQuery,
  useGetBaseClientCampaignLimitsQuery,
  useGetRemainingConciergeHoursQuery,
  useParseClientEmailTemplateMutation,
  useUploadClientImageMutation,
} = clientEndpoints;
