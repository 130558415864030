/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject14
 */
export interface InlineObject14 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject14
     */
    aliasClient: string;
}

export function InlineObject14FromJSON(json: any): InlineObject14 {
    return InlineObject14FromJSONTyped(json, false);
}

export function InlineObject14FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject14 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aliasClient': json['alias_client'],
    };
}

export function InlineObject14ToJSON(value?: InlineObject14 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alias_client': value.aliasClient,
    };
}


