/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegenerateSampleCandidateResponse
 */
export interface RegenerateSampleCandidateResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RegenerateSampleCandidateResponse
     */
    successfullyGeneratedCandidate: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegenerateSampleCandidateResponse
     */
    deletedExistingSampleCandidate: boolean;
}

export function RegenerateSampleCandidateResponseFromJSON(json: any): RegenerateSampleCandidateResponse {
    return RegenerateSampleCandidateResponseFromJSONTyped(json, false);
}

export function RegenerateSampleCandidateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegenerateSampleCandidateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'successfullyGeneratedCandidate': json['successfully_generated_candidate'],
        'deletedExistingSampleCandidate': json['deleted_existing_sample_candidate'],
    };
}

export function RegenerateSampleCandidateResponseToJSON(value?: RegenerateSampleCandidateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'successfully_generated_candidate': value.successfullyGeneratedCandidate,
        'deleted_existing_sample_candidate': value.deletedExistingSampleCandidate,
    };
}


