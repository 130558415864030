/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlimSearch,
    SlimSearchFromJSON,
    SlimSearchFromJSONTyped,
    SlimSearchToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChildSearchTransformRelationship
 */
export interface ChildSearchTransformRelationship {
    /**
     * 
     * @type {SlimSearch}
     * @memberof ChildSearchTransformRelationship
     */
    childSearch?: SlimSearch;
    /**
     * 
     * @type {string}
     * @memberof ChildSearchTransformRelationship
     */
    readonly searchTransformName?: ChildSearchTransformRelationshipSearchTransformNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ChildSearchTransformRelationship
     */
    readonly linked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChildSearchTransformRelationship
     */
    readonly overridden?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ChildSearchTransformRelationshipSearchTransformNameEnum {
    Clone = 'CLONE',
    Inbound = 'INBOUND',
    InboundV3 = 'INBOUND_V3',
    Referrals = 'REFERRALS',
    TargetedCompanies = 'TARGETED_COMPANIES',
    ProfileAttributes = 'PROFILE_ATTRIBUTES',
    Reengagement = 'REENGAGEMENT'
}

export function ChildSearchTransformRelationshipFromJSON(json: any): ChildSearchTransformRelationship {
    return ChildSearchTransformRelationshipFromJSONTyped(json, false);
}

export function ChildSearchTransformRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildSearchTransformRelationship {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'childSearch': !exists(json, 'child_search') ? undefined : SlimSearchFromJSON(json['child_search']),
        'searchTransformName': !exists(json, 'search_transform_name') ? undefined : json['search_transform_name'],
        'linked': !exists(json, 'linked') ? undefined : json['linked'],
        'overridden': !exists(json, 'overridden') ? undefined : json['overridden'],
    };
}

export function ChildSearchTransformRelationshipToJSON(value?: ChildSearchTransformRelationship | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'child_search': SlimSearchToJSON(value.childSearch),
    };
}


