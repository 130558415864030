/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject11
 */
export interface InlineObject11 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject11
     */
    candidateId: string;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject11
     */
    pendingCustomerResponse: boolean;
}

export function InlineObject11FromJSON(json: any): InlineObject11 {
    return InlineObject11FromJSONTyped(json, false);
}

export function InlineObject11FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject11 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
        'pendingCustomerResponse': json['pending_customer_response'],
    };
}

export function InlineObject11ToJSON(value?: InlineObject11 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'pending_customer_response': value.pendingCustomerResponse,
    };
}


