import React from "react";
import { UseFormReturn } from "react-hook-form";

import { CompanyPitchFormSchema } from "components/dover/CompanySetupPitch/types";
import { TextField } from "components/library/TextField";
import { useGetCanonicalCompanyInfoQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetCompanyPitchQuestionsQuery } from "services/doverapi/endpoints/company-setup/endpoints";

const CompanyPitchQuestionFields = ({
  companyPitchFormMethods,
}: {
  companyPitchFormMethods: UseFormReturn<CompanyPitchFormSchema, any>;
}): React.ReactElement => {
  const { data: companyPitchQuestions } = useGetCompanyPitchQuestionsQuery({});
  const { data: canonicalCompany, isLoading: canonicalCompanyLoading } = useGetCanonicalCompanyInfoQuery();
  const {
    register: companyPitchRegister,
    formState: { errors: companyPitchErrors },
  } = companyPitchFormMethods;

  if (canonicalCompanyLoading) {
    return <>Loading...</>;
  }

  // Get the company description from the bio if it exists, otherwise use the canonical company description
  const defaultCompanyDescription =
    (companyPitchQuestions?.answers as any)?.companyDescription ?? canonicalCompany?.description;

  return (
    <>
      <TextField
        title={`Please describe your company and primary product / service in 2-3 sentences.`}
        defaultValue={defaultCompanyDescription}
        multiline={true}
        minRows={3}
        required={true}
        placeholderText={"E.g. Acme's on a mission to improve dental hygiene one toothbrush at a time."}
        inputProps={{ ...companyPitchRegister("companyDescription") }}
        errorMessage={companyPitchErrors.companyDescription?.message}
        error={!!companyPitchErrors.companyDescription}
      />
    </>
  );
};

export default CompanyPitchQuestionFields;
