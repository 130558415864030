import React, { useEffect } from "react";

import { Centered } from "components/Centered";
import { LoadingRelative } from "components/HotLoading";
import PageHelmet from "components/PageHelmet";
import { useOpenApiClients } from "hooks/openApiClients";
import { metabaseReportingUrl } from "views/Reporting/constants";
import ReportingSplash from "views/Reporting/ReportingSplash";

const MetabaseReporting = (): React.ReactElement => {
  const [authLoading, setAuthLoading] = React.useState(true);
  const [showSplash, setShowSplash] = React.useState(true);
  const apiApi = useOpenApiClients()?.apiApi;
  const [authMetabaseAPI, setAuthMetabaseAPI] = React.useState<string | null>(null);

  // Check if user needs to see splash screen
  useEffect(() => {
    const checkMetabaseStatus = async (): Promise<void> => {
      setAuthLoading(true);
      try {
        console.log("Checking Metabase user status...");
        const response = await apiApi?.checkMetabaseUserStatus();
        console.log("Got Metabase status response:", response);

        if (response?.hasActiveUser) {
          console.log("User is active, getting auth URL...");
          const authResponse = await apiApi?.authMetabaseUser({
            returnTo: metabaseReportingUrl,
          });
          console.log("Got auth response:", authResponse);

          if (authResponse) {
            setAuthMetabaseAPI(authResponse.metabaseUrl);
            setShowSplash(false);
          }
        }
      } catch (e) {
        console.error("Error checking Metabase status:", e);
      }
      setAuthLoading(false);
    };
    checkMetabaseStatus();
  }, [apiApi]);

  const handleActivateReporting = async (): Promise<void> => {
    setAuthLoading(true);
    try {
      const response = await apiApi?.authMetabaseUser({
        returnTo: metabaseReportingUrl,
      });

      if (response?.metabaseUrl) {
        setAuthMetabaseAPI(response.metabaseUrl);
        setShowSplash(false);
      } else {
        console.error("Auth response missing metabaseUrl");
      }
    } catch (e) {
      console.error("Error creating/activating Metabase user:", e);
    }
    setAuthLoading(false);
  };

  if (authLoading) {
    return (
      <Centered>
        <LoadingRelative />
      </Centered>
    );
  }

  if (showSplash) {
    return <ReportingSplash onActivate={handleActivateReporting} />;
  }

  return (
    <>
      <PageHelmet title="Reporting" />
      <iframe src={authMetabaseAPI ?? ""} style={{ width: "100%", height: "calc(100vh)" }} frameBorder="0" />
    </>
  );
};

export default MetabaseReporting;
