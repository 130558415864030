import React from "react";

import { StyledFormLabel, StyledFormSubLabel } from "components/inputs/common";
import { Spacer } from "components/Spacer";

interface Props {
  label: string;
  required?: boolean;
}

const Label = ({ label, required }: Props): React.ReactElement => {
  const labelLines = label.split("\n");
  const firstLine = labelLines[0];
  const otherLines = labelLines.slice(1);

  return (
    <>
      <StyledFormLabel>
        {firstLine}
        {required ? " *" : null}
      </StyledFormLabel>
      {otherLines.map((l, idx) => (
        <StyledFormSubLabel key={idx}>{l}</StyledFormSubLabel>
      ))}
      {otherLines && otherLines.length > 0 && <Spacer height="4px" />}
    </>
  );
};

export default Label;
