import { Stack } from "@mui/material";
import { sortBy } from "lodash";
import React, { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { RequiredAsterisk } from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup/components/RequiredAsterisk";
import { InboundCriteriaSetupFormValues } from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup/types";
import { Subtitle2 } from "components/library/typography";
import YOESlider, { YearsOfExperienceRange } from "components/library/YOESlider";
import {
  useLazyListProfileSearchKeywordsQuery,
  useGetOrCreateKeywordWithNameMutation,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { ProfileSearchKeywordSerializer } from "services/openapi";
import { KeywordsAutocomplete } from "views/create-job/shared-steps/CreateJobDescription";
import { YEARS_OF_EXPERIENCE_MAX, YEARS_OF_EXPERIENCE_ALLOWED_RANGE } from "views/sourcing/Search/constants";

const YOE_SCROLL_CONTAINER_ID = "applicant-sorting-setup-flow-criteria-yoe";

export const QualificationsFormSection = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<InboundCriteriaSetupFormValues>();
    const yearsOfExperienceFormValue = useWatch({ control, name: "yearsOfExperience" });
    const keywordsFormValue = useWatch({
      control,
      name: "keywords",
    });
    const [customKeywordString, setCustomKeywordString] = useState<string>("");

    const [lazyListKeywords] = useLazyListProfileSearchKeywordsQuery();
    const [getOrCreateKeywordWithName, { isLoading: isCreateKeywordLoading }] = useGetOrCreateKeywordWithNameMutation();
    const selectedCanonicalKeywords = keywordsFormValue?.map(keyword => keyword.canonicalKeyword) ?? [];

    const onChangeYearsOfExperience = (yearsOfExperience: YearsOfExperienceRange): void => {
      if (!yearsOfExperience) {
        return;
      }
      // NOTE: the min and max values from the slider can be null but the form expects undefined
      const { min, max } = yearsOfExperience;
      setValue("yearsOfExperience", { min: min ?? undefined, max: max ?? undefined });
    };

    const fetchKeywords = async (request: string): Promise<ProfileSearchKeywordSerializer[]> => {
      const keywordsResponse = await lazyListKeywords({ limit: 50, queryText: request }).unwrap();

      // return keywordsResponse sorted first by if they haven't already been added, then by friendlyName
      return sortBy(keywordsResponse, [
        (k: ProfileSearchKeywordSerializer): boolean => selectedCanonicalKeywords.includes(k.canonicalKeyword),
        "friendlyName",
      ]);
    };

    const onFetchKeywordsCompleted = (
      customKeywordString: string,
      fetchResponse: ProfileSearchKeywordSerializer[]
    ): void => {
      const hasNoNewKeywords = fetchResponse.every((keyword: ProfileSearchKeywordSerializer) =>
        selectedCanonicalKeywords.includes(keyword.canonicalKeyword)
      );
      if (hasNoNewKeywords && customKeywordString.length) {
        setCustomKeywordString(customKeywordString);
      } else {
        setCustomKeywordString("");
      }
    };

    const getOrCreateKeyword = async (): Promise<void> => {
      if (!customKeywordString.length) {
        return;
      }
      const newKeyword = await getOrCreateKeywordWithName(customKeywordString).unwrap();
      setValue("keywords", [...keywordsFormValue, newKeyword]);
      setCustomKeywordString("");
    };

    const onKeywordsSelectionChange = async (newKeywords: ProfileSearchKeywordSerializer[]): Promise<void> => {
      // If there are more keywords than before, we added a new keyword
      if (newKeywords.length > keywordsFormValue.length) {
        const newKeyword = newKeywords[newKeywords.length - 1];
        setValue("keywords", [...keywordsFormValue, newKeyword]);
        // Otherwise, if the new length is less than before, we removed a keyword
      } else if (newKeywords.length < keywordsFormValue.length) {
        setValue("keywords", newKeywords);
      }
      // We can ignore the case where the length is the same, because the user didn't change anything
    };

    return (
      <Stack spacing={2} id={YOE_SCROLL_CONTAINER_ID}>
        <Stack spacing={0.5}>
          <Subtitle2>
            Total years of experience <RequiredAsterisk />
          </Subtitle2>
          <YOESlider
            values={yearsOfExperienceFormValue}
            onChange={onChangeYearsOfExperience}
            maximumValue={YEARS_OF_EXPERIENCE_MAX}
            minimumRange={YEARS_OF_EXPERIENCE_ALLOWED_RANGE}
            shouldShowTooltip={true}
            scrollContainerId={YOE_SCROLL_CONTAINER_ID}
          />
        </Stack>
        <Stack spacing={0.5}>
          <Subtitle2>
            Job requirements and skills <RequiredAsterisk />
          </Subtitle2>
          <KeywordsAutocomplete
            customKeywordString={customKeywordString}
            selectedCanonicalKeywords={selectedCanonicalKeywords}
            isCreateKeywordLoading={isCreateKeywordLoading}
            initialValue={keywordsFormValue}
            fetchKeywords={fetchKeywords}
            onFetchCompleted={onFetchKeywordsCompleted}
            getOrCreateKeyword={getOrCreateKeyword}
            onKeywordsSelectionChange={onKeywordsSelectionChange}
          />
        </Stack>
      </Stack>
    );
  }
);
