import { useParams } from "react-router-dom";

import { useSearchParams } from "hooks/useSearchParams";

export const candidateIdQueryParam = "candidate";

export const useCandidateId = (): string | undefined => {
  const query = useSearchParams([candidateIdQueryParam]);
  const { candidateId } = useParams<{ candidateId: string }>();

  return query?.candidate ?? candidateId;
};
