/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchV3Params,
    SearchV3ParamsFromJSON,
    SearchV3ParamsFromJSONTyped,
    SearchV3ParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProfileRequest
 */
export interface ProfileRequest {
    /**
     * 
     * @type {SearchV3Params}
     * @memberof ProfileRequest
     */
    params: SearchV3Params;
    /**
     * 
     * @type {string}
     * @memberof ProfileRequest
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileRequest
     */
    searchId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfileRequest
     */
    start: number;
    /**
     * 
     * @type {number}
     * @memberof ProfileRequest
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof ProfileRequest
     */
    sortBy?: ProfileRequestSortByEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileRequest
     */
    sample?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ProfileRequestSortByEnum {
    Desc = 'score_desc',
    Asc = 'score_asc'
}

export function ProfileRequestFromJSON(json: any): ProfileRequest {
    return ProfileRequestFromJSONTyped(json, false);
}

export function ProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'params': SearchV3ParamsFromJSON(json['params']),
        'jobId': !exists(json, 'job_id') ? undefined : json['job_id'],
        'searchId': !exists(json, 'search_id') ? undefined : json['search_id'],
        'start': json['start'],
        'size': json['size'],
        'sortBy': !exists(json, 'sort_by') ? undefined : json['sort_by'],
        'sample': !exists(json, 'sample') ? undefined : json['sample'],
    };
}

export function ProfileRequestToJSON(value?: ProfileRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'params': SearchV3ParamsToJSON(value.params),
        'job_id': value.jobId,
        'search_id': value.searchId,
        'start': value.start,
        'size': value.size,
        'sort_by': value.sortBy,
        'sample': value.sample,
    };
}


