import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as NoApplicantsIcon } from "assets/icons/no-applicants-icon.svg";
import { Subtitle1, BodySmall } from "components/library/typography";

const ErrorFetchingAppsState = ({ paramsAreBroken }: { paramsAreBroken?: boolean }): React.ReactElement => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={2} px={1}>
      <NoApplicantsIcon />
      <Stack alignItems="center">
        <Subtitle1>Applicant info will display here</Subtitle1>
        {paramsAreBroken && <BodySmall centered>Unable to parse your criteria.</BodySmall>}
        <BodySmall centered>Please update your Criteria or AI Chat query to see applicants.</BodySmall>
      </Stack>
    </Stack>
  );
};

export default ErrorFetchingAppsState;
