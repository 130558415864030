import Stack from "@mui/material/Stack";
import React from "react";

import { INVALID_PERMS_TOOLTIP_TEXT } from "components/constants";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { useHasElevatedPermissions } from "components/RBAC";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";

interface BaseStepControlsProps {
  jobId?: string;
  hideBackButton?: boolean;
  disableContinue?: boolean;
  showScheduleButton?: boolean;
  isSchedulable?: boolean;
  hasEmail?: boolean;
  continueLabel?: string;
  backLabel?: string;
  overrideHandleNext?: () => void;
  handleAddAndSchedule?: () => void;
}

const BaseStepControls = ({
  jobId,
  hideBackButton = false,
  disableContinue = false,
  showScheduleButton = false,
  isSchedulable = false,
  hasEmail = false,
  continueLabel = "Continue",
  backLabel = "Back",
  overrideHandleNext,
  handleAddAndSchedule,
}: BaseStepControlsProps): React.ReactElement => {
  const { handleNextStep, handlePreviousStep } = useAddCandidateContext();

  const hasPermissionToSchedule = useHasElevatedPermissions(jobId);

  let tooltipText = "";
  if (hasPermissionToSchedule) {
    if (isSchedulable) {
      if (!hasEmail) {
        tooltipText = "Scheduling unavailable due to no email.";
      }
    } else {
      tooltipText = "Scheduling not supported for the selected stage.";
    }
  } else {
    tooltipText = INVALID_PERMS_TOOLTIP_TEXT;
  }

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
      <Button disabled={disableContinue} variant={ButtonVariant.Primary} onClick={overrideHandleNext ?? handleNextStep}>
        {continueLabel}
      </Button>
      {showScheduleButton && (
        <Tooltip
          variant={TooltipVariant.Dark}
          title={tooltipText}
          placement="top"
          disabled={isSchedulable && hasEmail && hasPermissionToSchedule}
        >
          <div>
            <Button
              variant={ButtonVariant.Primary}
              onClick={handleAddAndSchedule}
              disabled={!isSchedulable || !hasEmail || !hasPermissionToSchedule}
            >
              Add Candidate & Schedule
            </Button>
          </div>
        </Tooltip>
      )}
      {!hideBackButton && (
        <Button variant={ButtonVariant.Ghost} onClick={handlePreviousStep}>
          {backLabel}
        </Button>
      )}
    </Stack>
  );
};

export default BaseStepControls;
