import { skipToken } from "@reduxjs/toolkit/dist/query";

import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetClientIsTam } from "services/doverapi/endpoints/client/hooks";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";

interface ScoringEnabledResult {
  jobHasScoringEnabled?: boolean;
  isFetching: boolean;
}

export const useJobHasScoringEnabled = (): ScoringEnabledResult => {
  const jobId = useJobIdFromUrl();
  const { data: jobInfo, isFetching: isJobFetching } = useGetJobQuery(jobId ?? skipToken);
  const useSimpReviewFF = useFeatureFlag(FeatureFlag.SimpReview);

  const clientIsExplicitNonTam = useGetClientIsTam() === false;

  if (!useSimpReviewFF) {
    // if simp review is off, just assume everyone has scoring enabled so as to not mess with existing behavioru
    return { jobHasScoringEnabled: true, isFetching: false };
  }

  // if you are explicitly non tam, then scoring will always be disabled
  const jobHasScoringEnabled = !!jobInfo?.inboundEnrichingEnabled && !clientIsExplicitNonTam;

  return { jobHasScoringEnabled, isFetching: isJobFetching };
};
