/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchDoverCandidateDetails
 */
export interface SearchDoverCandidateDetails {
    /**
     * 
     * @type {string}
     * @memberof SearchDoverCandidateDetails
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDoverCandidateDetails
     */
    currentEmployment?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDoverCandidateDetails
     */
    linkedinUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDoverCandidateDetails
     */
    stageName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchDoverCandidateDetails
     */
    status?: string;
}

export function SearchDoverCandidateDetailsFromJSON(json: any): SearchDoverCandidateDetails {
    return SearchDoverCandidateDetailsFromJSONTyped(json, false);
}

export function SearchDoverCandidateDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchDoverCandidateDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobTitle': json['job_title'],
        'currentEmployment': !exists(json, 'current_employment') ? undefined : json['current_employment'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'stageName': !exists(json, 'stage_name') ? undefined : json['stage_name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function SearchDoverCandidateDetailsToJSON(value?: SearchDoverCandidateDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_title': value.jobTitle,
        'current_employment': value.currentEmployment,
        'linkedin_url': value.linkedinUrl,
        'stage_name': value.stageName,
        'status': value.status,
    };
}


