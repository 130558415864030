import { SelectOption } from "components/AtsSettings/types";
import { DashboardJobAtsSyncSettingEnum } from "services/openapi/models/DashboardJob";

export const LEVER_ATS_SYNC_SETTING_OPTIONS: Readonly<SelectOption[]> = [
  {
    label: "The candidate responds to any outreach email (Recommended)",
    value: DashboardJobAtsSyncSettingEnum.Responded,
  },
  { label: "The first outreach email is sent to a candidate", value: DashboardJobAtsSyncSettingEnum.FirstContacted },
];

export const GREENHOUSE_ATS_SYNC_SETTING_OPTIONS: Readonly<SelectOption[]> = [
  {
    label: "The candidate is approved for the Initial Call (Recommended)",
    value: DashboardJobAtsSyncSettingEnum.CallScheduling,
  },
  { label: "The candidate responds to any outreach email", value: DashboardJobAtsSyncSettingEnum.Responded },
  { label: "The first outreach email is sent to a candidate", value: DashboardJobAtsSyncSettingEnum.FirstContacted },
];
