import { Menu, MenuItem, Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom } from "jotai";
import { isEqual } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import AddSVG from "assets/icons/add.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import CheckSVG from "assets/icons/check.svg";
import Arrow from "assets/icons/chevron-right.svg";
import HelpIconSVG from "assets/icons/help-question.svg";
import { ReactComponent as LightningIcon } from "assets/icons/lightning.svg";
import PencilEditSVG from "assets/icons/pencil-edit.svg";
import { ReactComponent as ThreeDotsSVG } from "assets/icons/three-dots.svg";
import XCrossCircleSVG from "assets/icons/x-cross-circle.svg";
import { ActivateSearchModal } from "components/dover/SearchesTable/components/ActivateSearchModal";
import { AddOrRemoveCampaignModal } from "components/dover/SearchesTable/components/AddOrRemoveCampaignModal";
import { RefreshOutboxModal } from "components/dover/SearchesTable/components/RefreshOutboxModal";
import { SaveAndProceedModal } from "components/dover/SearchesTable/components/SaveAndProceedModal";
import { SettingsCell } from "components/dover/SearchesTable/components/SettingsCell";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import { Role, useHasRole } from "components/RBAC";
import { appConfig } from "config/appConfig";
import { useFeatureFlag, FeatureFlag } from "hooks/useFeatureFlag";
import { useIsBasePlanCustomer, useShouldShowRealProfiles } from "services/doverapi/endpoints/client/hooks";
import { useGetDoverOutboundConfigurationQuery } from "services/doverapi/endpoints/jobFulfillment";
import {
  useCloneSearchV3Mutation,
  useCreateChangeLogEntryMutation,
  useGetSearchV3Query,
  usePartialUpdateSearchV3Mutation,
  useReEngageSearchV3Mutation,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3SearchTypeEnum } from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { ToggleOnboardingCallModalOpenAtom } from "views/sourcing/Search/atoms";
import { ChangeLogDrawer } from "views/sourcing/Search/components/ChangeLogDrawer";
import { OnboardingCallModal } from "views/sourcing/Search/components/OnboardingCallModal";
import SearchName from "views/sourcing/Search/components/SearchName";
import { WaterFallModal } from "views/sourcing/Search/components/WaterfallDepthModal";
import { useSearchId } from "views/sourcing/Search/hooks";
import { StyledHelpIconSVG } from "views/sourcing/Search/styles";
import { searchV3FormSchema, SearchV3FormSchemaType, SourcingContext } from "views/sourcing/Search/types";
import { getSearchV3FromFormState } from "views/sourcing/Search/utils";

const StyledSearchMenuButton = styled(Button)`
  padding: 4px 8px;
`;

const StyledSaveButton = styled(Button)`
  padding: 5px 16px;
`;

const RE_ENGAGEMENT_SEARCH_TOOLTIP =
  "Reengagement searches are used to reach back out to candidates who never responded to earlier outreach for at least 4 months, widening the candidate pool for long-running jobs and searches.";

interface TopBarProps {
  context?: SourcingContext;
}

const TopBar = React.memo(
  ({ context }: TopBarProps): React.ReactElement => {
    const searchId = useSearchId();
    const navigate = useNavigate();
    const clientIsOnBasePlan = useIsBasePlanCustomer();
    const muiTheme = useTheme();
    const isMediumScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptop));
    const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tabletL));

    // determine if user is admin, to conditionally show the "log search params" button
    const userIsAdmin = useHasRole(Role.ADMIN);

    const shouldShowRealProfiles = useShouldShowRealProfiles();
    const shouldShowOnboardingCallModal = !shouldShowRealProfiles;

    // mutations and queries
    const { data: search } = useGetSearchV3Query(searchId ? { id: searchId } : skipToken);
    const { data: outboundConfig } = useGetDoverOutboundConfigurationQuery(search?.job || skipToken);
    const [partialUpdateSearch, { isLoading: isSearchUpdating }] = usePartialUpdateSearchV3Mutation();
    const [createChangeLogEntry, { isLoading: isChangeLogEntryCreating }] = useCreateChangeLogEntryMutation();
    const [cloneSearchV3, { isLoading: isCloneSearchLoading }] = useCloneSearchV3Mutation();
    const [reEngageSearchV3, { isLoading: isReengagementSearchLoading }] = useReEngageSearchV3Mutation();

    // modal states
    const [isRefreshOutboxModalOpen, setIsRefreshOutboxModalOpen] = React.useState(false);
    const [isChangeLogOpen, setIsChangeLogOpen] = React.useState(false);
    const [isActivateSearchModalOpen, setIsActivateSearchModalOpen] = React.useState(false);
    const [isWaterFallModalOpen, setIsWaterFallModalOpen] = React.useState(false);
    const [onboardingCallModalOpen, toggleOnboardingCallModalOpen] = useAtom(ToggleOnboardingCallModalOpenAtom);

    const autoSendEnabled = outboundConfig && !!outboundConfig?.autoQueueOutreach;

    // ffs
    const useSourcingPage = useFeatureFlag(FeatureFlag.SourcingPage);

    const { control } = useFormContext<SearchV3FormSchemaType>();
    const values = useWatch({ control });

    const onClickSaveSearch = React.useCallback(async () => {
      // Early return if the form state isn't legit
      // We'll handle errors at the top level of the Sourcing page
      const formParseResult = searchV3FormSchema.safeParse(values);
      if (!formParseResult.success) {
        return;
      }

      // If a search is not yet defined, we don't wish to proceed
      if (!search || !searchId) {
        return;
      }

      // After updates, we'll get a referentially different search object from RTKQ
      // It's important the we check deep equality against the new search so we don't perform an additional unnecessary update
      const newSearch = getSearchV3FromFormState(formParseResult.data, search);
      if (isEqual(search, newSearch)) {
        return;
      }

      await partialUpdateSearch({ id: searchId, data: { ...search, ...newSearch } });
      createChangeLogEntry({ searchId: searchId });
      if (!search.active && !clientIsOnBasePlan) {
        setIsActivateSearchModalOpen(true);
      }
      // we only want to show this modal for !autoSendEnabled or PayGo/Free (provided search is outbound)
      else if (search.searchType === SearchV3SearchTypeEnum.Outbound && (!autoSendEnabled || clientIsOnBasePlan)) {
        setIsRefreshOutboxModalOpen(true);
      }

      // Lastly, pop open an upsell modal for free customers
      if (shouldShowOnboardingCallModal) {
        toggleOnboardingCallModalOpen(true);
      }
    }, [
      values,
      search,
      searchId,
      partialUpdateSearch,
      createChangeLogEntry,
      autoSendEnabled,
      clientIsOnBasePlan,
      shouldShowOnboardingCallModal,
      toggleOnboardingCallModalOpen,
    ]);

    const saveSearchButton = useMemo(() => {
      const saveSearchText = isMediumScreen ? "Save" : "Save Search";
      return (
        <StyledSaveButton variant={ButtonVariant.Primary} onClick={onClickSaveSearch} loading={isSearchUpdating}>
          <Body color={colors.white}>{saveSearchText}</Body>
        </StyledSaveButton>
      );
    }, [isSearchUpdating, isMediumScreen, onClickSaveSearch]);

    const returnToSearchesButton = useMemo(() => {
      return (
        <StyledSearchMenuButton
          variant={ButtonVariant.Secondary}
          onClick={(): void => {
            if (search?.job) {
              if (useSourcingPage) {
                navigate(APP_ROUTE_PATHS.job.sourcing(search.job));
              } else if (search?.searchType === SearchV3SearchTypeEnum.Inbound) {
                navigate(APP_ROUTE_PATHS.job.applicationReviewV2(search.job));
              } else {
                navigate(APP_ROUTE_PATHS.job.autopilot(search.job));
              }
            }
          }}
        >
          <ArrowLeft />
        </StyledSearchMenuButton>
      );
    }, [navigate, search, useSourcingPage]);

    const [searchMenuAnchorEl, setSearchMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const openSearchMenu = React.useCallback((event: React.MouseEvent<HTMLElement>): void => {
      setSearchMenuAnchorEl(event.currentTarget);
    }, []);

    const closeSearchMenu = React.useCallback((): void => {
      setSearchMenuAnchorEl(null);
    }, []);

    const onOpenChangeLogButton = useCallback(() => {
      closeSearchMenu();
      setIsChangeLogOpen(true);
    }, [closeSearchMenu]);

    const onCloseChangeLog = useCallback(() => {
      setIsChangeLogOpen(false);
    }, []);

    const openChangeLogButton = useMemo(() => {
      return (
        <Button variant={ButtonVariant.Ghost} removePadding removeOutline>
          <Stack direction="row" alignItems="center" spacing={2}>
            <LightningIcon className="svg-color" color={colors.grayscale.gray700} />
            <BodySmall color={colors.grayscale.gray700}>Change Log</BodySmall>
          </Stack>
        </Button>
      );
    }, []);

    const handleToggleActivation = React.useCallback(() => {
      if (!search?.id) {
        return;
      }

      partialUpdateSearch({
        id: search.id,
        data: {
          ...search,
          active: !search.active,
        },
      })
        .unwrap()
        .then(() => {
          closeSearchMenu();
        });
    }, [closeSearchMenu, partialUpdateSearch, search]);

    const [isCampaignsModalOpen, setIsCampaignsModalOpen] = React.useState(false);

    const [isCloneSearchModalOpen, setIsCloneSearchModalOpen] = React.useState(false);

    const [isReengageSearchModalOpen, setIsReengageSearchModalOpen] = React.useState(false);

    const callCloneSearchFunction = React.useCallback(() => {
      if (!searchId) {
        return;
      }

      cloneSearchV3(searchId)
        .unwrap()
        .then(result => {
          window.open(`/sourcing/${result}/`, "_blank", "noopener noreferrer");
          setIsCloneSearchModalOpen(false);
        });
    }, [cloneSearchV3, searchId]);

    const onClickCloneSearch = React.useCallback(() => {
      // Function which clones a search

      // Early return if the form state isn't legit
      // We'll handle errors at the top level of the Sourcing page
      const formParseResult = searchV3FormSchema.safeParse(values);
      if (!formParseResult.success) {
        return;
      }

      // If a search is not yet defined, we don't wish to proceed
      if (!search || !searchId || isCloneSearchLoading) {
        return;
      }

      // After updates, we'll get a referentially different search object from RTKQ
      // We want to inform users if their search has been updated since loading, so they clone exactly what they want to clone
      const newSearch = getSearchV3FromFormState(formParseResult.data, search);
      if (!isEqual(search, newSearch)) {
        setIsCloneSearchModalOpen(true);
      } else {
        callCloneSearchFunction();
      }
    }, [callCloneSearchFunction, isCloneSearchLoading, search, searchId, values]);

    const { appUrl } = appConfig;

    const callReengageSearchFunction = React.useCallback(() => {
      if (!searchId) {
        return;
      }
      reEngageSearchV3(searchId)
        .unwrap()
        .then(response => {
          const newSearchLink = appUrl + "/sourcing/" + response.searchId;
          console.log(response);
          // given that we may fail to create a new campaign, only open it if it successfully created
          if (response.jobId && response.campaignId) {
            const campaignLink = appUrl + "/job/" + response.jobId + "/setup/outreach?campaign=" + response.campaignId;
            // open campaign in new tab
            window.open(campaignLink, "_blank", "noopener noreferrer");
          }
          // open new search in same tab (use window.open to reload the page fully - otherwise search name stays same)
          window.open(newSearchLink, "_self");
        });
    }, [appUrl, reEngageSearchV3, searchId]);

    const onClickReengageSearch = React.useCallback(() => {
      // Function which clones a search

      // Early return if the form state isn't legit
      // We'll handle errors at the top level of the Sourcing page
      const formParseResult = searchV3FormSchema.safeParse(values);
      if (!formParseResult.success) {
        return;
      }

      // If a search is not yet defined, we don't wish to proceed
      if (!search || !searchId || isReengagementSearchLoading) {
        return;
      }

      // After updates, we'll get a referentially different search object from RTKQ
      // We want to inform users if their search has been updated since loading, so they clone exactly what they want to clone
      const newSearch = getSearchV3FromFormState(formParseResult.data, search);
      if (!isEqual(search, newSearch)) {
        setIsReengageSearchModalOpen(true);
      } else {
        callReengageSearchFunction();
      }
    }, [callReengageSearchFunction, isReengagementSearchLoading, search, searchId, values]);

    const onClickShowParams = React.useCallback(() => {
      // Function which logs search parameters for eng debugging purposes

      // Early return if the form state isn't legit
      // We'll handle errors at the top level of the Sourcing page
      const formParseResult = searchV3FormSchema.safeParse(values);
      if (!formParseResult.success) {
        return;
      }

      // If a search is not yet defined, we don't wish to proceed
      if (!search || !searchId) {
        return;
      }

      // After updates, we'll get a referentially different search object from RTKQ
      // It's important the we check deep equality against the new search so we don't perform an additional unnecessary update
      const newSearch = getSearchV3FromFormState(formParseResult.data, search);

      console.log(newSearch.v3Params);
    }, [search, searchId, values]);

    const searchMenu = useMemo(() => {
      return (
        <>
          <StyledSearchMenuButton variant={ButtonVariant.Secondary} onClick={openSearchMenu}>
            <ThreeDotsSVG />
          </StyledSearchMenuButton>
          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={!!searchMenuAnchorEl}
            anchorEl={searchMenuAnchorEl}
            onClose={closeSearchMenu}
          >
            <MenuItem onClick={searchMenuAnchorEl ? handleToggleActivation : undefined}>
              <Stack direction="row" alignItems="center" spacing={2}>
                {search?.active ? (
                  <>
                    <ReactSVG style={{ display: "flex" }} src={XCrossCircleSVG} />
                    <BodySmall color={colors.critical.base}>{"Deactivate"}</BodySmall>
                  </>
                ) : (
                  <>
                    <ReactSVG style={{ display: "flex" }} src={CheckSVG} />
                    <BodySmall>{"Activate"}</BodySmall>
                  </>
                )}
              </Stack>
            </MenuItem>
            <MenuItem
              onClick={
                searchMenuAnchorEl
                  ? (): void => {
                      setIsCampaignsModalOpen(true);
                    }
                  : undefined
              }
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <ReactSVG style={{ display: "flex" }} src={PencilEditSVG} />
                <BodySmall>{"Add or remove campaigns"}</BodySmall>
              </Stack>
            </MenuItem>
            <MenuItem onClick={onClickCloneSearch}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <ReactSVG style={{ display: "flex" }} src={AddSVG} />
                <BodySmall>{"Clone Search"}</BodySmall>
              </Stack>
            </MenuItem>
            {search?.campaignIds && search?.campaignIds?.length > 0 && (
              <MenuItem onClick={onClickReengageSearch}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ReactSVG style={{ display: "flex" }} src={AddSVG} />
                  <BodySmall>{"Create Reengagement Search"}</BodySmall>
                  <Tooltip
                    title={RE_ENGAGEMENT_SEARCH_TOOLTIP}
                    placement="right"
                    arrow={true}
                    variant={TooltipVariant.Dark}
                    boxSizing="border-box"
                  >
                    {/* Span is necessary to prevent a MUI v5 render issue */}
                    <span>
                      <StyledHelpIconSVG src={HelpIconSVG} />
                    </span>
                  </Tooltip>
                </Stack>
              </MenuItem>
            )}
            <MenuItem onClick={onOpenChangeLogButton}>{openChangeLogButton}</MenuItem>
            {userIsAdmin && (
              <>
                <MenuItem onClick={onClickShowParams}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <ReactSVG style={{ display: "flex" }} src={Arrow} />
                    <BodySmall>{"Log Search Parameters"}</BodySmall>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={(): void => setIsWaterFallModalOpen(true)}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <ReactSVG style={{ display: "flex" }} src={Arrow} />
                    <BodySmall>{"Debug depth"}</BodySmall>
                  </Stack>
                </MenuItem>
              </>
            )}
          </Menu>
        </>
      );
    }, [
      closeSearchMenu,
      handleToggleActivation,
      onClickCloneSearch,
      onClickReengageSearch,
      onClickShowParams,
      onOpenChangeLogButton,
      openChangeLogButton,
      openSearchMenu,
      search?.active,
      search?.campaignIds,
      searchMenuAnchorEl,
      userIsAdmin,
    ]);

    return (
      <div>
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: colors.white,
            height: "56px",
            px: "24px",
            boxShadow: `inset 0px -1px 0px ${colors.grayscale.gray200}`,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {returnToSearchesButton}
            <SearchName mediumScreen={isMediumScreen} smallScreen={isSmallScreen} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            {context !== SourcingContext.CreateJob && (
              <>
                {saveSearchButton}
                {search?.job && <SettingsCell jobId={search?.job} />}
                {searchMenu}
              </>
            )}
          </Stack>
        </Stack>
        {search !== undefined && (
          <AddOrRemoveCampaignModal
            isPrimaryModalOpen={isCampaignsModalOpen}
            setIsPrimaryModalOpen={setIsCampaignsModalOpen}
            search={search}
          />
        )}
        <SaveAndProceedModal
          isModalOpen={isCloneSearchModalOpen}
          setIsModalOpen={setIsCloneSearchModalOpen}
          proceedFunction={callCloneSearchFunction}
          saveSearchFunction={onClickSaveSearch}
          actionName="Clone Search"
        />
        {search?.campaignIds && search?.campaignIds?.length > 0 && (
          <SaveAndProceedModal
            isModalOpen={isReengageSearchModalOpen}
            setIsModalOpen={setIsReengageSearchModalOpen}
            proceedFunction={callReengageSearchFunction}
            saveSearchFunction={onClickSaveSearch}
            actionName="Create Reengagement Search"
          />
        )}
        {!!search && (
          <ActivateSearchModal
            isModalOpen={isActivateSearchModalOpen}
            setIsModalOpen={setIsActivateSearchModalOpen}
            search={search}
            // want to disable the activate button so it doesnt save search before changelogentry is created
            activateDisabled={isChangeLogEntryCreating}
          />
        )}
        {!!searchId && !shouldShowOnboardingCallModal && (
          <>
            <RefreshOutboxModal
              isModalOpen={isRefreshOutboxModalOpen}
              setIsModalOpen={setIsRefreshOutboxModalOpen}
              searchId={searchId}
            />
            <ChangeLogDrawer open={isChangeLogOpen} onClose={onCloseChangeLog} searchId={searchId} />
          </>
        )}
        {shouldShowOnboardingCallModal && onboardingCallModalOpen && <OnboardingCallModal />}
        <WaterFallModal open={isWaterFallModalOpen} onClosed={(): void => setIsWaterFallModalOpen(false)} />
      </div>
    );
  }
);

export default TopBar;
