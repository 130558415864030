/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DoverUser,
    DoverUserFromJSON,
    DoverUserFromJSONTyped,
    DoverUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface DoverEntity
 */
export interface DoverEntity {
    /**
     * 
     * @type {string}
     * @memberof DoverEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DoverEntity
     */
    readonly firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverEntity
     */
    email: string;
    /**
     * For Dover EM/CSMs this is the calendly link customers will use to schedule job onboarding meetings
     * @type {string}
     * @memberof DoverEntity
     */
    jobOnboardingSchedulingLink?: string | null;
    /**
     * For Dover EM/CSMs this is the calendly link customers will use to schedule adhoc meetings (15 mins)
     * @type {string}
     * @memberof DoverEntity
     */
    adhocMeetingSchedulingLink?: string | null;
    /**
     * For Dover Support/CX this is the calendly link customers will use to schedule adhoc meetings
     * @type {string}
     * @memberof DoverEntity
     */
    supportMeetingSchedulingLink?: string | null;
    /**
     * 
     * @type {DoverUser}
     * @memberof DoverEntity
     */
    proUser?: DoverUser | null;
}

export function DoverEntityFromJSON(json: any): DoverEntity {
    return DoverEntityFromJSONTyped(json, false);
}

export function DoverEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoverEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'email': json['email'],
        'jobOnboardingSchedulingLink': !exists(json, 'job_onboarding_scheduling_link') ? undefined : json['job_onboarding_scheduling_link'],
        'adhocMeetingSchedulingLink': !exists(json, 'adhoc_meeting_scheduling_link') ? undefined : json['adhoc_meeting_scheduling_link'],
        'supportMeetingSchedulingLink': !exists(json, 'support_meeting_scheduling_link') ? undefined : json['support_meeting_scheduling_link'],
        'proUser': !exists(json, 'pro_user') ? undefined : DoverUserFromJSON(json['pro_user']),
    };
}

export function DoverEntityToJSON(value?: DoverEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'job_onboarding_scheduling_link': value.jobOnboardingSchedulingLink,
        'adhoc_meeting_scheduling_link': value.adhocMeetingSchedulingLink,
        'support_meeting_scheduling_link': value.supportMeetingSchedulingLink,
        'pro_user': DoverUserToJSON(value.proUser),
    };
}


