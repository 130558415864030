import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export enum DoverLoginSource {
  MainApp = "app",
  Extension = "extension",
}

// This atom is used to store the source of the login,
// so that we can redirect the user to the correct page after the initial onboarding flow
// For extension users, we redirect them to the extension create job flow rather than the homepage
export const doverLoginSourceAtom = atomWithStorage<DoverLoginSource>("loginSource", DoverLoginSource.MainApp);

export const segmentLoadedAtom = atom<number>(0);
