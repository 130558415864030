import { Box } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useLazyGetInterviewRubricResponseQuery } from "services/doverapi/endpoints/candidateInterview";
import { CandidateBio } from "services/openapi";
import { colors } from "styles/theme";
import { createAndOpenFeedbackForm } from "utils/candidate";

export const InterviewCompletedByDoverInterviewerButtons = ({
  candidateBio,
  interviewId,
}: {
  candidateBio: CandidateBio;
  interviewId: string;
}): React.ReactElement => {
  const [getOrCreateIRR] = useLazyGetInterviewRubricResponseQuery();
  return (
    <Box display="flex" minHeight="40px" justifyContent="space-between" width="100%">
      <Button
        variant={ButtonVariant.Secondary}
        onClick={async (): Promise<void> =>
          await createAndOpenFeedbackForm(getOrCreateIRR, candidateBio.id!, interviewId, false)
        }
      >
        <BodySmall weight="500" color={colors.grayscale.gray600}>
          Review Interview Notes
        </BodySmall>
      </Button>
    </Box>
  );
};
