/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobOutreachBenchmarks
 */
export interface JobOutreachBenchmarks {
    /**
     * 
     * @type {number}
     * @memberof JobOutreachBenchmarks
     */
    responseRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachBenchmarks
     */
    interestedRate?: number | null;
}

export function JobOutreachBenchmarksFromJSON(json: any): JobOutreachBenchmarks {
    return JobOutreachBenchmarksFromJSONTyped(json, false);
}

export function JobOutreachBenchmarksFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobOutreachBenchmarks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responseRate': !exists(json, 'response_rate') ? undefined : json['response_rate'],
        'interestedRate': !exists(json, 'interested_rate') ? undefined : json['interested_rate'],
    };
}

export function JobOutreachBenchmarksToJSON(value?: JobOutreachBenchmarks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response_rate': value.responseRate,
        'interested_rate': value.interestedRate,
    };
}


