/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddToCampaignMessageOverride,
    AddToCampaignMessageOverrideFromJSON,
    AddToCampaignMessageOverrideFromJSONTyped,
    AddToCampaignMessageOverrideToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddToCampaignRequest
 */
export interface AddToCampaignRequest {
    /**
     * 
     * @type {string}
     * @memberof AddToCampaignRequest
     */
    campaignId: string;
    /**
     * 
     * @type {Date}
     * @memberof AddToCampaignRequest
     */
    sendAt?: Date | null;
    /**
     * 
     * @type {AddToCampaignMessageOverride}
     * @memberof AddToCampaignRequest
     */
    initialMessageOverride?: AddToCampaignMessageOverride | null;
}

export function AddToCampaignRequestFromJSON(json: any): AddToCampaignRequest {
    return AddToCampaignRequestFromJSONTyped(json, false);
}

export function AddToCampaignRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddToCampaignRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaignId': json['campaign_id'],
        'sendAt': !exists(json, 'send_at') ? undefined : (json['send_at'] === null ? null : new Date(json['send_at'])),
        'initialMessageOverride': !exists(json, 'initial_message_override') ? undefined : AddToCampaignMessageOverrideFromJSON(json['initial_message_override']),
    };
}

export function AddToCampaignRequestToJSON(value?: AddToCampaignRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaign_id': value.campaignId,
        'send_at': value.sendAt === undefined ? undefined : (value.sendAt === null ? null : value.sendAt.toISOString()),
        'initial_message_override': AddToCampaignMessageOverrideToJSON(value.initialMessageOverride),
    };
}


