/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchV3PersonaQuestionnaire
 */
export interface SearchV3PersonaQuestionnaire {
    /**
     * 
     * @type {string}
     * @memberof SearchV3PersonaQuestionnaire
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchV3PersonaQuestionnaire
     */
    readonly personaName?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof SearchV3PersonaQuestionnaire
     */
    readonly questions?: Set<string>;
    /**
     * 
     * @type {object}
     * @memberof SearchV3PersonaQuestionnaire
     */
    readonly compiledConfig?: object;
}

export function SearchV3PersonaQuestionnaireFromJSON(json: any): SearchV3PersonaQuestionnaire {
    return SearchV3PersonaQuestionnaireFromJSONTyped(json, false);
}

export function SearchV3PersonaQuestionnaireFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchV3PersonaQuestionnaire {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'personaName': !exists(json, 'persona_name') ? undefined : json['persona_name'],
        'questions': !exists(json, 'questions') ? undefined : json['questions'],
        'compiledConfig': !exists(json, 'compiled_config') ? undefined : json['compiled_config'],
    };
}

export function SearchV3PersonaQuestionnaireToJSON(value?: SearchV3PersonaQuestionnaire | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


