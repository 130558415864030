/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtensionV2CampaignEmailSenderAlias,
    ExtensionV2CampaignEmailSenderAliasFromJSON,
    ExtensionV2CampaignEmailSenderAliasFromJSONTyped,
    ExtensionV2CampaignEmailSenderAliasToJSON,
    ExtensionV2CampaignMessage,
    ExtensionV2CampaignMessageFromJSON,
    ExtensionV2CampaignMessageFromJSONTyped,
    ExtensionV2CampaignMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExtensionV2Campaign
 */
export interface ExtensionV2Campaign {
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2Campaign
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2Campaign
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionV2Campaign
     */
    state?: ExtensionV2CampaignStateEnum;
    /**
     * 
     * @type {ExtensionV2CampaignEmailSenderAlias}
     * @memberof ExtensionV2Campaign
     */
    emailAlias?: ExtensionV2CampaignEmailSenderAlias;
    /**
     * 
     * @type {ExtensionV2CampaignMessage}
     * @memberof ExtensionV2Campaign
     */
    message?: ExtensionV2CampaignMessage;
}

/**
* @export
* @enum {string}
*/
export enum ExtensionV2CampaignStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED'
}

export function ExtensionV2CampaignFromJSON(json: any): ExtensionV2Campaign {
    return ExtensionV2CampaignFromJSONTyped(json, false);
}

export function ExtensionV2CampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionV2Campaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'emailAlias': !exists(json, 'email_alias') ? undefined : ExtensionV2CampaignEmailSenderAliasFromJSON(json['email_alias']),
        'message': !exists(json, 'message') ? undefined : ExtensionV2CampaignMessageFromJSON(json['message']),
    };
}

export function ExtensionV2CampaignToJSON(value?: ExtensionV2Campaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'state': value.state,
        'email_alias': ExtensionV2CampaignEmailSenderAliasToJSON(value.emailAlias),
        'message': ExtensionV2CampaignMessageToJSON(value.message),
    };
}


