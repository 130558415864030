import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import { CenteredCol } from "App/styles/App";
import Logo from "views/CompanySetup/components/Credentials/components/Logo";
import { Divider, BackLink } from "views/CompanySetup/components/Credentials/components/Styles";
import { Source } from "views/CompanySetup/components/Credentials/types";

interface Props {
  source: Source;
  instructionsComponent?: React.ReactNode;
  stageComponent: React.ReactNode;
}

const SourcePage: React.FC<Props> = ({ source, instructionsComponent, stageComponent }) => {
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <Col>
          <BackLink onClick={(): void => navigate(APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.INTEGRATIONS))}>
            <ArrowBackIosIcon />
            Back to sources
          </BackLink>
        </Col>
      </Row>
      <Row>
        <Col>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col xs={{ size: 8, offset: 2 }}>
          <Logo source={source} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Divider />
        </Col>
      </Row>
      {!!instructionsComponent && (
        <>
          <Row>
            <CenteredCol xs={12}>{instructionsComponent}</CenteredCol>
          </Row>
          <Row>
            <Col>
              <Divider />
            </Col>
          </Row>
        </>
      )}
      {stageComponent}
    </>
  );
};

export default SourcePage;
