/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelatedKeyword
 */
export interface RelatedKeyword {
    /**
     * 
     * @type {number}
     * @memberof RelatedKeyword
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof RelatedKeyword
     */
    type?: RelatedKeywordTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RelatedKeyword
     */
    keywordName: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedKeyword
     */
    readonly freq?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedKeyword
     */
    keywordFriendlyName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RelatedKeyword
     */
    aliases: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum RelatedKeywordTypeEnum {
    ChildToParent = 'CHILD_TO_PARENT',
    ParentToChild = 'PARENT_TO_CHILD',
    Symmetrical = 'SYMMETRICAL'
}

export function RelatedKeywordFromJSON(json: any): RelatedKeyword {
    return RelatedKeywordFromJSONTyped(json, false);
}

export function RelatedKeywordFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedKeyword {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'keywordName': json['keyword_name'],
        'freq': !exists(json, 'freq') ? undefined : json['freq'],
        'keywordFriendlyName': json['keyword_friendly_name'],
        'aliases': json['aliases'],
    };
}

export function RelatedKeywordToJSON(value?: RelatedKeyword | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'keyword_name': value.keywordName,
        'keyword_friendly_name': value.keywordFriendlyName,
        'aliases': value.aliases,
    };
}


