/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOnboardingFlow
 */
export interface UserOnboardingFlow {
    /**
     * 
     * @type {string}
     * @memberof UserOnboardingFlow
     */
    readonly id?: string;
    /**
     * 
     * @type {number}
     * @memberof UserOnboardingFlow
     */
    readonly proUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserOnboardingFlow
     */
    readonly flowName?: UserOnboardingFlowFlowNameEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserOnboardingFlow
     */
    completedSteps?: Array<UserOnboardingFlowCompletedStepsEnum>;
    /**
     * 
     * @type {string}
     * @memberof UserOnboardingFlow
     */
    mostRecentlyCompletedStep?: UserOnboardingFlowMostRecentlyCompletedStepEnum;
    /**
     * 
     * @type {string}
     * @memberof UserOnboardingFlow
     */
    onboardingState?: UserOnboardingFlowOnboardingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UserOnboardingFlow
     */
    contentType?: UserOnboardingFlowContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserOnboardingFlow
     */
    relatedObjectUuid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingFlow
     */
    readonly isEligibleForFlow?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserOnboardingFlow
     */
    readonly currentStep?: UserOnboardingFlowCurrentStepEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserOnboardingFlow
     */
    readonly isOnLastStep?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UserOnboardingFlowFlowNameEnum {
    UserInfo = 'USER_INFO',
    UserAndCompanyLoginInfo = 'USER_AND_COMPANY_LOGIN_INFO',
    CompanyLoginAndSetup = 'COMPANY_LOGIN_AND_SETUP',
    AtsJobBoards = 'ATS_JOB_BOARDS',
    SourcingAutopilotSetup = 'SOURCING_AUTOPILOT_SETUP',
    DoverInterviewerSetup = 'DOVER_INTERVIEWER_SETUP',
    AutoSchedulingSetup = 'AUTO_SCHEDULING_SETUP',
    ApplicantSorting = 'APPLICANT_SORTING'
}/**
* @export
* @enum {string}
*/
export enum UserOnboardingFlowCompletedStepsEnum {
    ClientCreation = 'CLIENT_CREATION',
    JobSelection = 'JOB_SELECTION',
    CompanySetup = 'COMPANY_SETUP',
    EditCareersPage = 'EDIT_CAREERS_PAGE',
    JdQuestion = 'JD_QUESTION',
    InterviewStages = 'INTERVIEW_STAGES',
    SourcingAutopilotJdQuestion = 'SOURCING_AUTOPILOT_JD_QUESTION',
    DoverInterviewerHowItWorks = 'DOVER_INTERVIEWER_HOW_IT_WORKS',
    AutoSchedulingHowItWorks = 'AUTO_SCHEDULING_HOW_IT_WORKS',
    InboundApplicationForm = 'INBOUND_APPLICATION_FORM',
    InboundCriteria = 'INBOUND_CRITERIA',
    FeatureSelection = 'FEATURE_SELECTION',
    InviteNewUsers = 'INVITE_NEW_USERS',
    Completion = 'COMPLETION'
}/**
* @export
* @enum {string}
*/
export enum UserOnboardingFlowMostRecentlyCompletedStepEnum {
    ClientCreation = 'CLIENT_CREATION',
    JobSelection = 'JOB_SELECTION',
    CompanySetup = 'COMPANY_SETUP',
    EditCareersPage = 'EDIT_CAREERS_PAGE',
    JdQuestion = 'JD_QUESTION',
    InterviewStages = 'INTERVIEW_STAGES',
    SourcingAutopilotJdQuestion = 'SOURCING_AUTOPILOT_JD_QUESTION',
    DoverInterviewerHowItWorks = 'DOVER_INTERVIEWER_HOW_IT_WORKS',
    AutoSchedulingHowItWorks = 'AUTO_SCHEDULING_HOW_IT_WORKS',
    InboundApplicationForm = 'INBOUND_APPLICATION_FORM',
    InboundCriteria = 'INBOUND_CRITERIA',
    FeatureSelection = 'FEATURE_SELECTION',
    InviteNewUsers = 'INVITE_NEW_USERS',
    Completion = 'COMPLETION'
}/**
* @export
* @enum {string}
*/
export enum UserOnboardingFlowOnboardingStateEnum {
    NotStarted = 'NOT_STARTED',
    InProgress = 'IN_PROGRESS',
    Completed = 'COMPLETED',
    NoLongerRelevant = 'NO_LONGER_RELEVANT'
}/**
* @export
* @enum {string}
*/
export enum UserOnboardingFlowContentTypeEnum {
    Job = 'job',
    Useronboardingflow = 'useronboardingflow'
}/**
* @export
* @enum {string}
*/
export enum UserOnboardingFlowCurrentStepEnum {
    ClientCreation = 'CLIENT_CREATION',
    JobSelection = 'JOB_SELECTION',
    CompanySetup = 'COMPANY_SETUP',
    EditCareersPage = 'EDIT_CAREERS_PAGE',
    JdQuestion = 'JD_QUESTION',
    InterviewStages = 'INTERVIEW_STAGES',
    SourcingAutopilotJdQuestion = 'SOURCING_AUTOPILOT_JD_QUESTION',
    DoverInterviewerHowItWorks = 'DOVER_INTERVIEWER_HOW_IT_WORKS',
    AutoSchedulingHowItWorks = 'AUTO_SCHEDULING_HOW_IT_WORKS',
    InboundApplicationForm = 'INBOUND_APPLICATION_FORM',
    InboundCriteria = 'INBOUND_CRITERIA',
    FeatureSelection = 'FEATURE_SELECTION',
    InviteNewUsers = 'INVITE_NEW_USERS',
    Completion = 'COMPLETION'
}

export function UserOnboardingFlowFromJSON(json: any): UserOnboardingFlow {
    return UserOnboardingFlowFromJSONTyped(json, false);
}

export function UserOnboardingFlowFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOnboardingFlow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'proUserId': !exists(json, 'pro_user_id') ? undefined : json['pro_user_id'],
        'flowName': !exists(json, 'flow_name') ? undefined : json['flow_name'],
        'completedSteps': !exists(json, 'completed_steps') ? undefined : json['completed_steps'],
        'mostRecentlyCompletedStep': !exists(json, 'most_recently_completed_step') ? undefined : json['most_recently_completed_step'],
        'onboardingState': !exists(json, 'onboarding_state') ? undefined : json['onboarding_state'],
        'contentType': !exists(json, 'content_type') ? undefined : json['content_type'],
        'relatedObjectUuid': !exists(json, 'related_object_uuid') ? undefined : json['related_object_uuid'],
        'isEligibleForFlow': !exists(json, 'is_eligible_for_flow') ? undefined : json['is_eligible_for_flow'],
        'currentStep': !exists(json, 'current_step') ? undefined : json['current_step'],
        'isOnLastStep': !exists(json, 'is_on_last_step') ? undefined : json['is_on_last_step'],
    };
}

export function UserOnboardingFlowToJSON(value?: UserOnboardingFlow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed_steps': value.completedSteps,
        'most_recently_completed_step': value.mostRecentlyCompletedStep,
        'onboarding_state': value.onboardingState,
        'content_type': value.contentType,
        'related_object_uuid': value.relatedObjectUuid,
    };
}


