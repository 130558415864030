/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOrCreateClientRequest
 */
export interface GetOrCreateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateClientRequest
     */
    secret: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateClientRequest
     */
    doverPlan: GetOrCreateClientRequestDoverPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateClientRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateClientRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateClientRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateClientRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateClientRequest
     */
    initialSetupFlow?: GetOrCreateClientRequestInitialSetupFlowEnum;
}

/**
* @export
* @enum {string}
*/
export enum GetOrCreateClientRequestDoverPlanEnum {
    NoAccess = 'NO_ACCESS',
    Free = 'FREE',
    Credits = 'CREDITS',
    PayAsYouGo = 'PAY_AS_YOU_GO',
    JobSlots = 'JOB_SLOTS',
    Growth = 'GROWTH',
    Concierge = 'CONCIERGE',
    FreeAts = 'FREE_ATS',
    FreeExtension = 'FREE_EXTENSION'
}/**
* @export
* @enum {string}
*/
export enum GetOrCreateClientRequestInitialSetupFlowEnum {
    ApplicantSorting = 'APPLICANT_SORTING',
    AtsJobBoards = 'ATS_JOB_BOARDS',
    AtsAddCandidate = 'ATS_ADD_CANDIDATE',
    SourcingAutopilot = 'SOURCING_AUTOPILOT',
    RecruitingCopilot = 'RECRUITING_COPILOT',
    AtsSetup = 'ATS_SETUP',
    AtsWithCall = 'ATS_WITH_CALL',
    EmbeddedRecruiter = 'EMBEDDED_RECRUITER',
    Bypass = 'BYPASS'
}

export function GetOrCreateClientRequestFromJSON(json: any): GetOrCreateClientRequest {
    return GetOrCreateClientRequestFromJSONTyped(json, false);
}

export function GetOrCreateClientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrCreateClientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'secret': json['secret'],
        'doverPlan': json['dover_plan'],
        'email': json['email'],
        'userId': json['user_id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'initialSetupFlow': !exists(json, 'initial_setup_flow') ? undefined : json['initial_setup_flow'],
    };
}

export function GetOrCreateClientRequestToJSON(value?: GetOrCreateClientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'secret': value.secret,
        'dover_plan': value.doverPlan,
        'email': value.email,
        'user_id': value.userId,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'initial_setup_flow': value.initialSetupFlow,
    };
}


