/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenericEmailResponseTemplate
 */
export interface GenericEmailResponseTemplate {
    /**
     * 
     * @type {string}
     * @memberof GenericEmailResponseTemplate
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof GenericEmailResponseTemplate
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GenericEmailResponseTemplate
     */
    readonly modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof GenericEmailResponseTemplate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GenericEmailResponseTemplate
     */
    templateType?: GenericEmailResponseTemplateTemplateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GenericEmailResponseTemplate
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof GenericEmailResponseTemplate
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenericEmailResponseTemplate
     */
    job?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum GenericEmailResponseTemplateTemplateTypeEnum {
    InterviewReminder = 'INTERVIEW_REMINDER',
    InterviewReminderNoSchedulingLinks = 'INTERVIEW_REMINDER_NO_SCHEDULING_LINKS',
    LateStageRejection = 'LATE_STAGE_REJECTION',
    SchedulingReminder = 'SCHEDULING_REMINDER',
    NotInterested = 'NOT_INTERESTED',
    ApplicationConfirmation = 'APPLICATION_CONFIRMATION',
    NewUserInvitation = 'NEW_USER_INVITATION',
    Unknown = 'UNKNOWN'
}

export function GenericEmailResponseTemplateFromJSON(json: any): GenericEmailResponseTemplate {
    return GenericEmailResponseTemplateFromJSONTyped(json, false);
}

export function GenericEmailResponseTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericEmailResponseTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'name': json['name'],
        'templateType': !exists(json, 'template_type') ? undefined : json['template_type'],
        'body': json['body'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'job': !exists(json, 'job') ? undefined : json['job'],
    };
}

export function GenericEmailResponseTemplateToJSON(value?: GenericEmailResponseTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'template_type': value.templateType,
        'body': value.body,
        'subject': value.subject,
        'job': value.job,
    };
}


