import { useLocation } from "react-router-dom";

export const useSearchParamsRaw = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const useSearchParams = (paramKeys: string[]): { [paramKey: string]: string | null } => {
  const searchParamsRaw = useSearchParamsRaw();
  return paramKeys.reduce((acc, paramKey) => ({ ...acc, [paramKey]: searchParamsRaw.get(paramKey) }), {});
};
