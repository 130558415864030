import { Stack, TextField } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";

import { recruitingPartnerPictureUrl } from "App/appConstants";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { BodyExtraSmall, BodySmall, ButtonText, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { PersonaCoin } from "components/PersonaCoin";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobSetupQuery, useUpdateJobSetupMutation } from "services/doverapi/endpoints/job";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";

interface GoalHireDateModalProps {
  jobId: string;
}
const GoalHireDateModal: React.FC<GlobalModalProps & GoalHireDateModalProps> = ({ isOpen, close, jobId }) => {
  const calendlyUrl = useGetCalendlyUrl("app", "job_overview", "goal_hire_date_modal");
  const isBasePlanCustomer = useIsBasePlanCustomer();

  const { data: job, isLoading: jobLoading } = useGetJobSetupQuery(jobId);
  const [updateJobSetup, { isLoading: isSaving }] = useUpdateJobSetupMutation();

  const [selectedDate, setSelectedDate] = useState<Date | null>(job?.goalHireDate ?? null);

  useEffect(() => {
    setSelectedDate(job?.goalHireDate ?? null);
  }, [job?.goalHireDate]);

  const handleSave = async (): Promise<void> => {
    await updateJobSetup({
      id: jobId,
      goalHireDate: selectedDate,
    });
    close();
  };

  const saveDisabled = !selectedDate || selectedDate === job?.goalHireDate;

  return (
    <CustomModal
      open={isOpen}
      title={<Subtitle2>Goal hire date</Subtitle2>}
      onClose={close}
      maxWidth={isBasePlanCustomer ? "sm" : "xs"}
      omitDividers
      loading={jobLoading}
      customContentStyles={{ padding: "0px" }}
      dialogActions={
        <Stack direction="row" spacing={1} pt={1}>
          <Button onClick={close} variant={ButtonVariant.Secondary} disabled={isSaving}>
            <ButtonText color={colors.grayscale.gray500}>Cancel</ButtonText>
          </Button>
          <Button
            loading={isSaving}
            width="fit-content"
            variant={ButtonVariant.Primary}
            onClick={handleSave}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </Stack>
      }
    >
      <Stack direction="row" justifyContent={"space-evenly"} spacing={2} pl={2} pr={2}>
        <Stack border={`1px solid ${colors.grayscale.gray200}`} borderRadius="6px" padding={1}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              componentsProps={{ actionBar: { actions: [] } }}
              ToolbarComponent={(): null => null}
              inputFormat="MM/dd/yyyy"
              value={selectedDate}
              renderInput={(params): React.ReactElement => <TextField {...params} fullWidth />}
              onChange={(date): void => {
                setSelectedDate(date);
              }}
            />
          </LocalizationProvider>
        </Stack>
        {isBasePlanCustomer && (
          <Stack
            spacing={1}
            border={`1px solid ${colors.grayscale.gray200}`}
            borderRadius="6px"
            padding={1}
            height="fit-content"
          >
            <BodySmall weight="600">Need to speed up hiring?</BodySmall>
            <Stack alignItems="center" spacing={1} direction="row" paddingBottom={1}>
              <Stack minWidth={"32px"} minHeight={"32px"}>
                <PersonaCoin size="medium" pictureUrl={recruitingPartnerPictureUrl} color="gray" />
              </Stack>
              <Stack spacing={0.5}>
                <BodySmall>Lavanya B.</BodySmall>
                <BodyExtraSmall color={colors.grayscale.gray500}>Has worked with 50+ companies</BodyExtraSmall>
              </Stack>
            </Stack>
            <ExternalLink
              display="inline"
              href={calendlyUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginTop: "0px" }}
            >
              <BodySmall color={colors.link}>
                <CalendarIcon color={colors.link} className="svg-color" /> Hire faster with a recruiting partner
              </BodySmall>
            </ExternalLink>
          </Stack>
        )}
      </Stack>
    </CustomModal>
  );
};

export const goalHireDateModalAtom = modalAtom(GoalHireDateModal);
