/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HiringPipelineStageMilestone {
    INITIAL_CALL = 'INITIAL_CALL',
    ONSITE = 'ONSITE'
}

export function HiringPipelineStageMilestoneFromJSON(json: any): HiringPipelineStageMilestone {
    return HiringPipelineStageMilestoneFromJSONTyped(json, false);
}

export function HiringPipelineStageMilestoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): HiringPipelineStageMilestone {
    return json as HiringPipelineStageMilestone;
}

export function HiringPipelineStageMilestoneToJSON(value?: HiringPipelineStageMilestone | null): any {
    return value as any;
}

