import { TrackEvent } from "services/analytics/track-events-types";

export class Analytics {
  /* Thin wrapper around window.analytics.

  Used to for strict type enforcement + graceful handling of uninitialized window.analytics object.

  See Segment's docs here: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
  */

  analytics: Window["analytics"] | undefined;

  constructor() {
    this.analytics = window.analytics;
  }

  track(...args: TrackEvent): void {
    const [eventName, payload] = args;

    if (!this.analytics) {
      console.log(`Skipping '${eventName}' track event since the analytics script is unavailable.`);
      return;
    }
    this.analytics.track(eventName, payload);
  }

  page(): void {
    if (!this.analytics) {
      console.log(`Skipping page event since the analytics script is unavailable.`);
      return;
    }
    this.analytics.page();
  }
}
