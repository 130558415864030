import { Stack } from "@mui/material";
import React, { useCallback } from "react";

import { ReactComponent as PencilIcon } from "assets/icons/pencil-edit.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Overline } from "components/library/typography";
import { ArchiveReason, ArchiveReasonTypeEnum } from "services/openapi";
import { colors } from "styles/theme";

const hiredIcon = <span className="emoji">🎉</span>;

const reasonToTitle = (reason: ArchiveReason): string | React.ReactElement => {
  switch (reason.type) {
    case ArchiveReasonTypeEnum.Rejected:
      return "Rejected";
    case ArchiveReasonTypeEnum.Withdrew:
      return "Withdrew";
    case ArchiveReasonTypeEnum.Hired:
      return <>{hiredIcon} Hired</>;
    case ArchiveReasonTypeEnum.Snoozed:
      return "Snoozed";
    case ArchiveReasonTypeEnum.Other:
      return "Archived";
    default:
      return "Archived";
  }
};

const reasonToVariant = (reason: ArchiveReason): BannerVariant => {
  switch (reason.type) {
    case ArchiveReasonTypeEnum.Rejected:
      return BannerVariant.Critical;
    case ArchiveReasonTypeEnum.Withdrew:
      return BannerVariant.Critical;
    case ArchiveReasonTypeEnum.Hired:
      return BannerVariant.Success;
    case ArchiveReasonTypeEnum.Snoozed:
      return BannerVariant.Warning;
    case ArchiveReasonTypeEnum.Other:
      return BannerVariant.Info;
    default:
      return BannerVariant.Info;
  }
};

const reasonToColor = (reason: ArchiveReason): string => {
  switch (reason.type) {
    case ArchiveReasonTypeEnum.Rejected:
      return colors.critical.base;
    case ArchiveReasonTypeEnum.Withdrew:
      return colors.critical.base;
    case ArchiveReasonTypeEnum.Hired:
      return colors.success.base;
    case ArchiveReasonTypeEnum.Snoozed:
      return colors.warning.dark;
    case ArchiveReasonTypeEnum.Other:
      return colors.informational.base;
    default:
      return colors.informational.base;
  }
};

export const ArchiveBanner = ({
  candidateId,
  archiveReason,
}: {
  candidateId?: string;
  archiveReason?: ArchiveReason;
}): React.ReactElement => {
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();

  const clickEdit = useCallback(() => {
    if (!candidateId) return; // Shouldn't be possible because button is disabled

    showCandidateActionModal({
      candidateId,
      modalType: CandidateActionModalType.Reject,
      initialRejectionReason: archiveReason,
    });
  }, [archiveReason, candidateId, showCandidateActionModal]);

  if (!archiveReason) return <></>;

  const isRejectedOrWithdrew =
    archiveReason.type === ArchiveReasonTypeEnum.Rejected || archiveReason.type === ArchiveReasonTypeEnum.Withdrew;

  return (
    <Banner variant={reasonToVariant(archiveReason)} noIcon padding="8px 4px">
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <Stack>
          <Overline color={reasonToColor(archiveReason)}>{reasonToTitle(archiveReason)}</Overline>
          {isRejectedOrWithdrew && <BodySmall>{archiveReason.description}</BodySmall>}
        </Stack>
        {isRejectedOrWithdrew && (
          <Button variant={ButtonVariant.Ghost} onClick={clickEdit}>
            <PencilIcon />
          </Button>
        )}
      </Stack>
    </Banner>
  );
};
