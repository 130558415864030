import { JobAtsSettingsFormValues, SelectOption } from "components/AtsSettings/types";
import { ClientOnboardingAtsTypeEnum } from "services/openapi";

export const getUpdatedJobSetupValues = (
  jobId: string,
  values: JobAtsSettingsFormValues,
  atsJobs: SelectOption[],
  selectedJobName?: string,
  companyAtsType?: ClientOnboardingAtsTypeEnum
): {
  triggerResyncOverride: boolean;
  updateValues: JobAtsSettingsFormValues & { id: string };
} => {
  let triggerResyncOverride = false;

  const updateValues: JobAtsSettingsFormValues & { id: string } = {
    id: jobId,
    atsJobTitle: undefined,
    atsBehalfId: undefined,
    atsJobId: undefined,
    atsSyncSetting: undefined,
  };

  if (
    companyAtsType === ClientOnboardingAtsTypeEnum.Greenhouse ||
    companyAtsType === ClientOnboardingAtsTypeEnum.Lever ||
    companyAtsType === ClientOnboardingAtsTypeEnum.Ashby
  ) {
    if (Object.prototype.hasOwnProperty.call(values, "atsBehalfId")) {
      updateValues.atsBehalfId = values.atsBehalfId;
    }
    if (Object.prototype.hasOwnProperty.call(values, "atsJobId")) {
      updateValues.atsJobId = values.atsJobId;

      const maybeJob = atsJobs.find(j => j.value == values.atsJobId);
      if (maybeJob) {
        updateValues.atsJobTitle = maybeJob.label;
        if (updateValues.atsJobTitle !== selectedJobName) {
          triggerResyncOverride = true;
        }
      } else {
        triggerResyncOverride = true;
      }
    }
    if (Object.prototype.hasOwnProperty.call(values, "atsSyncSetting")) {
      updateValues.atsSyncSetting = values.atsSyncSetting;
    }
  } else {
    if (Object.prototype.hasOwnProperty.call(values, "atsJobTitle")) {
      updateValues.atsJobTitle = values.atsJobTitle;
      if (updateValues.atsJobTitle !== selectedJobName || !selectedJobName) {
        triggerResyncOverride = true;
      }
    }
  }
  return { triggerResyncOverride, updateValues };
};
