/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientEmailProvider {
    GMAIL = 'GMAIL',
    OUTLOOK = 'OUTLOOK',
    OTHER = 'OTHER'
}

export function ClientEmailProviderFromJSON(json: any): ClientEmailProvider {
    return ClientEmailProviderFromJSONTyped(json, false);
}

export function ClientEmailProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientEmailProvider {
    return json as ClientEmailProvider;
}

export function ClientEmailProviderToJSON(value?: ClientEmailProvider | null): any {
    return value as any;
}

