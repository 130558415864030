import { Progress } from "@doverhq/dover-ui";
import { Dialog, Box, Stack } from "@mui/material";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import React, { ReactElement } from "react";
import { useBoolean } from "react-use";

import { Body } from "components/library/typography";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { colors } from "styles/theme";

interface FileViewerProps {
  url?: string;
  iframeHeight?: string;
  iframeContainerHeight?: string;
  noFileText?: string;
}

export const ClickableFileViewer = ({
  url,
  iframeHeight,
  iframeContainerHeight,
  noFileText,
}: FileViewerProps): ReactElement => {
  const [fileViewerFullScreen, toggleFileViewerFullScreen] = useBoolean(false);
  if (!url) {
    return <NoFile noFileText={noFileText} />;
  }

  return (
    <>
      <Box
        onClick={(): void => toggleFileViewerFullScreen()}
        mx="auto !important"
        width="85%"
        sx={{ cursor: "pointer" }}
      >
        <FileViewer url={url} iframeHeight={iframeHeight} />
      </Box>
      <Dialog fullWidth maxWidth="lg" scroll="paper" open={fileViewerFullScreen} onClose={toggleFileViewerFullScreen}>
        <FileViewer url={url} noFileText={noFileText} iframeContainerHeight={iframeContainerHeight} />
      </Dialog>
    </>
  );
};

// I recognize that the height props is not a great way to do this,
// but getting the correct layout with the iframe can be a little finicky and this works for now
export const FileViewer = ({ url, iframeHeight, iframeContainerHeight, noFileText }: FileViewerProps): ReactElement => {
  if (!url) {
    return <NoFile noFileText={noFileText} />;
  }

  return (
    <Stack
      sx={{
        flexGrow: 1,
        minHeight: 0,
        height: "100%",
        width: "100%",
      }}
    >
      {url.includes(".pdf") ? (
        // Make sure `pdfjs-dist` package version is updated when we update this
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={url}
            defaultScale={SpecialZoomLevel.PageWidth}
            renderLoader={(): ReactElement => (
              <Box m={5} height="100%" display="flex" alignItems="center" justifyContent="center">
                <Progress text="Loading Resume..." size="large" />
              </Box>
            )}
            renderError={(): React.ReactElement => {
              return <InvalidFile />;
            }}
          />
        </Worker>
      ) : (
        <Box height={iframeContainerHeight ?? "100%"}>
          <iframe
            title="File Viewer"
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
            width="100%"
            height={iframeHeight ?? "100%"}
            style={{ border: "none" }}
          />
        </Box>
      )}
    </Stack>
  );
};

const NoFile = ({ noFileText }: { noFileText?: string }): ReactElement => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={1} paddingTop={8}>
      <Body weight="600" centered>
        {noFileText || "No file to load"}
      </Body>
    </Stack>
  );
};

const InvalidFile = (): ReactElement => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={1}>
      <Body weight="600" centered>
        Unable to load file
      </Body>
      <Body color={colors.grayscale.gray500} centered>
        Try refreshing the page or contact support
      </Body>
    </Stack>
  );
};
