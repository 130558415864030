/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FrontBaseInterviewSubstage,
    FrontBaseInterviewSubstageFromJSON,
    FrontBaseInterviewSubstageFromJSONTyped,
    FrontBaseInterviewSubstageToJSON,
} from './';

/**
 * 
 * @export
 * @interface FrontMultipartInterviewStage
 */
export interface FrontMultipartInterviewStage {
    /**
     * 
     * @type {string}
     * @memberof FrontMultipartInterviewStage
     */
    readonly id?: string;
    /**
     * 
     * @type {Array<FrontBaseInterviewSubstage>}
     * @memberof FrontMultipartInterviewStage
     */
    substages?: Array<FrontBaseInterviewSubstage>;
}

export function FrontMultipartInterviewStageFromJSON(json: any): FrontMultipartInterviewStage {
    return FrontMultipartInterviewStageFromJSONTyped(json, false);
}

export function FrontMultipartInterviewStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontMultipartInterviewStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'substages': !exists(json, 'substages') ? undefined : ((json['substages'] as Array<any>).map(FrontBaseInterviewSubstageFromJSON)),
    };
}

export function FrontMultipartInterviewStageToJSON(value?: FrontMultipartInterviewStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'substages': value.substages === undefined ? undefined : ((value.substages as Array<any>).map(FrontBaseInterviewSubstageToJSON)),
    };
}


