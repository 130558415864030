import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";

import { ReactComponent as TrashIconSVG } from "assets/icons/trash.svg";
import { InputErrorMessage } from "components/dover/EditCareersPage/InputErrorMessage";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { BodySmall } from "components/library/typography";
import { CareersPageDisplayStat } from "services/openapi";
import { colors } from "styles/theme";

interface QuickFactProps extends Pick<QuickFactsListProps, "onUpdateQuickFact" | "onRemoveQuickFact"> {
  stat: CareersPageDisplayStat;
  index: number;
}

const QUICK_FACT_PLACEHOLDERS = [
  { label: "Location", value: "San Francisco, CA, USA" },
  { label: "Company size", value: "45" },
  { label: "Industry", value: "Software Development" },
  { label: "Funding", value: "$18M" },
];

const QUICK_FACT_MAX_LENGTH = 50;

const QuickFact = ({ stat, index, onUpdateQuickFact, onRemoveQuickFact }: QuickFactProps): React.ReactElement => {
  const [labelErrorMessage, setLabelErrorMessage] = React.useState<string>("");
  const [valueErrorMessage, setValueErrorMessage] = React.useState<string>("");

  const validInputLength = (text: string): boolean => {
    return text.length < QUICK_FACT_MAX_LENGTH;
  };

  const maxCharacterErrorMessage = `Maximum length is ${QUICK_FACT_MAX_LENGTH} characters`;

  useEffect(() => {
    // Check if the input is at the max length and set an error message if so
    if (stat.label.length === QUICK_FACT_MAX_LENGTH) {
      setLabelErrorMessage(maxCharacterErrorMessage);
    } else setLabelErrorMessage("");

    if (stat.value.length === QUICK_FACT_MAX_LENGTH) {
      setValueErrorMessage(maxCharacterErrorMessage);
    } else setValueErrorMessage("");
  }, [stat.label, stat.value, maxCharacterErrorMessage]);

  const validateLabelOnBlur = (): void => {
    // If there's a value but no label, show error message
    if (stat.value && !stat.label) {
      setLabelErrorMessage("Please enter a title");
    } else if (!validInputLength(stat.label)) {
      setLabelErrorMessage(maxCharacterErrorMessage);
    } else setLabelErrorMessage("");
  };

  const validateValueOnBlur = (): void => {
    // If there's a label but no value, show error message
    if (stat.label && !stat.value) {
      setValueErrorMessage("Please enter a value");
    } else if (!validInputLength(stat.value)) {
      setValueErrorMessage(maxCharacterErrorMessage);
    } else setValueErrorMessage("");
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" pb={labelErrorMessage || valueErrorMessage ? 2 : 0}>
      <Stack spacing={0.5}>
        <BodySmall weight="600">Title</BodySmall>
        <Box position="relative">
          <TextField
            text={stat.label}
            onTextUpdated={(text): void => onUpdateQuickFact({ index, key: "label", newValue: text })}
            placeholderText={QUICK_FACT_PLACEHOLDERS[index % QUICK_FACT_PLACEHOLDERS.length].label}
            inputProps={{ maxLength: QUICK_FACT_MAX_LENGTH }}
            error={!!labelErrorMessage}
            onBlur={validateLabelOnBlur}
          />
          <InputErrorMessage message={labelErrorMessage} show={!!labelErrorMessage} />
        </Box>
      </Stack>
      <Stack spacing={0.5}>
        <BodySmall weight="600">Value</BodySmall>
        <Box position="relative">
          <TextField
            text={stat.value}
            onTextUpdated={(text): void => onUpdateQuickFact({ index, key: "value", newValue: text })}
            placeholderText={QUICK_FACT_PLACEHOLDERS[index % QUICK_FACT_PLACEHOLDERS.length].value}
            inputProps={{ maxLength: QUICK_FACT_MAX_LENGTH }}
            error={!!valueErrorMessage}
            onBlur={validateValueOnBlur}
          />
          <InputErrorMessage message={valueErrorMessage} show={!!valueErrorMessage} />
        </Box>
      </Stack>
      <div>
        <Box mt={2}>
          <Button
            variant={ButtonVariant.Ghost}
            onClick={(): void => onRemoveQuickFact(index)}
            removePadding
            removeOutline
          >
            <TrashIconSVG color={colors.grayscale.gray600} className="svg-color" />
          </Button>
        </Box>
      </div>
    </Stack>
  );
};

interface UpdateQuickFactArgs {
  index: number;
  key: "label" | "value";
  newValue: string;
}

interface QuickFactsListProps {
  quickFacts: Array<CareersPageDisplayStat>;
  onUpdateQuickFact: (args: UpdateQuickFactArgs) => void;
  onAddQuickFact: () => void;
  onRemoveQuickFact: (index: number) => void;
}

const QuickFactsList = ({
  quickFacts,
  onUpdateQuickFact,
  onAddQuickFact,
  onRemoveQuickFact,
}: QuickFactsListProps): React.ReactElement => {
  return (
    <Stack alignItems="flex-start" spacing={1}>
      <div>
        <BodySmall weight="600">Quick Facts</BodySmall>
        <BodySmall color={colors.grayscale.gray600}>e.g. Location, Company size, Industry, Funding, etc.</BodySmall>
      </div>
      {quickFacts.map((stat, index) => (
        <QuickFact
          key={index}
          index={index}
          stat={stat}
          onUpdateQuickFact={onUpdateQuickFact}
          onRemoveQuickFact={onRemoveQuickFact}
        />
      ))}
      <BodySmall>
        <Button variant={ButtonVariant.Link} onClick={onAddQuickFact} removePadding removeOutline>
          + Add fact
        </Button>
      </BodySmall>
    </Stack>
  );
};

export default QuickFactsList;
