import { useMemo } from "react";

import { useGetCompanySetupStepsQuery } from "services/doverapi/endpoints/client/endpoints";
import { getCompanySetupStepMap } from "services/doverapi/endpoints/company-setup/utils";
import { CompanySetupStepStateEnum, CompanySetupStepStepNameEnum } from "services/openapi";

export const useIsCompanyPitchComplete = (): boolean => {
  const { data: companySetupSteps } = useGetCompanySetupStepsQuery();
  return useMemo(() => {
    let isCompanyPitchComplete = false;
    if (companySetupSteps) {
      const companyStepNameToInfoMap = getCompanySetupStepMap(companySetupSteps);
      isCompanyPitchComplete = [
        CompanySetupStepStateEnum.Complete,
        CompanySetupStepStateEnum.StartedPendingDoverAction,
      ].includes(companyStepNameToInfoMap[CompanySetupStepStepNameEnum.PitchState].state);
    }
    return isCompanyPitchComplete;
  }, [companySetupSteps]);
};
