/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject22
 */
export interface InlineObject22 {
    /**
     * 
     * @type {object}
     * @memberof InlineObject22
     */
    answers: object;
}

export function InlineObject22FromJSON(json: any): InlineObject22 {
    return InlineObject22FromJSONTyped(json, false);
}

export function InlineObject22FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject22 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answers': json['answers'],
    };
}

export function InlineObject22ToJSON(value?: InlineObject22 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answers': value.answers,
    };
}


