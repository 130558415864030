import { createEnumParam } from "use-query-params";

export const INSUFFICIENT_DATA_ALERT_MESSAGE: string =
  "Insufficient data in this timeframe. Please select a longer timeframe to view this data.";

export enum DateOption {
  AllTime = "ALL_TIME",
  Last7Days = "LAST_7_DAYS",
  Last14Days = "LAST_14_DAYS",
  Last2To4Weeks = "LAST_2_TO_4_WEEKS",
  Last30Days = "LAST_30_DAYS",
  Last60Days = "LAST_60_DAYS",
  Last90Days = "LAST_90_DAYS",
}

interface DateFilterOption {
  key: DateOption;
  label: string;
}

export const JOB_REPORT_DATE_FILTER_OPTIONS: Array<DateFilterOption> = [
  { key: DateOption.AllTime, label: "All time" },
  { key: DateOption.Last7Days, label: "Last 7 days" },
  { key: DateOption.Last14Days, label: "Last 14 days" },
  { key: DateOption.Last30Days, label: "Last 30 days" },
  { key: DateOption.Last60Days, label: "Last 60 days" },
  { key: DateOption.Last90Days, label: "Last 90 days" },
];

export const dateRangeOptionQueryParamName = "dateOption";
export const DateRangeOptionQueryParamType = createEnumParam(Object.values(DateOption));

export const metabaseReportingUrl =
  "https://dover.metabaseapp.com/collection/2322-1-analytics?new_button=false&action_buttons=false&top_nav=true&side_nav=true&header=false&additional_info=false";
