/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCampaign
 */
export interface CreateCampaign {
    /**
     * 
     * @type {string}
     * @memberof CreateCampaign
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaign
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaign
     */
    jobId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaign
     */
    campaignToClone?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCampaign
     */
    emailSenderOption: CreateCampaignEmailSenderOptionEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateCampaign
     */
    userDefinedSenderUser?: number;
}

/**
* @export
* @enum {string}
*/
export enum CreateCampaignEmailSenderOptionEnum {
    HiringManager = 'HIRING_MANAGER',
    VirtualRecruiter = 'VIRTUAL_RECRUITER',
    OtherUser = 'OTHER_USER'
}

export function CreateCampaignFromJSON(json: any): CreateCampaign {
    return CreateCampaignFromJSONTyped(json, false);
}

export function CreateCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'jobId': json['job_id'],
        'campaignToClone': !exists(json, 'campaign_to_clone') ? undefined : json['campaign_to_clone'],
        'emailSenderOption': json['email_sender_option'],
        'userDefinedSenderUser': !exists(json, 'user_defined_sender_user') ? undefined : json['user_defined_sender_user'],
    };
}

export function CreateCampaignToJSON(value?: CreateCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'job_id': value.jobId,
        'campaign_to_clone': value.campaignToClone,
        'email_sender_option': value.emailSenderOption,
        'user_defined_sender_user': value.userDefinedSenderUser,
    };
}


