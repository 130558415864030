/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { useCandidateId } from "hooks/useCandidateId";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { backgrounds } from "styles/theme";
import { CandidateDetails } from "views/CandidateDetail/components/CandidateDetails";
import { StageAndStatusCardV2 } from "views/CandidateDetail/components/StageAndStatusCard/StageAndStatusCardV2";
import { CloseFunction } from "views/CandidateDetail/types";

export const CandidateBio = ({ close }: { close?: CloseFunction }): React.ReactElement => {
  const candidateId = useCandidateId();
  const { data: candidateBio, isFetching: isFetchingCandidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);

  if (!candidateBio || !candidateId || isFetchingCandidateBio) {
    return <></>;
  } else {
    return (
      <CandidateBioContainer>
        {close && (
          <Box display="flex" justifyContent="flex-end">
            <CloseIconWrapper>
              <CloseIcon color="action" onClick={(): void => close(candidateBio)} />
            </CloseIconWrapper>
          </Box>
        )}
        <Spacer height={12} />
        <StageAndStatusCardV2 />
        <Spacer height={12} />
        <CandidateDetails candidateBio={candidateBio} />
      </CandidateBioContainer>
    );
  }
};

const CandidateBioContainer = styled.div`
  background-color: ${backgrounds.gray};
  display: inline-block;
  padding: 24px;
  height: 100%;
  width: 342px;
  overflow: auto;
`;

const CloseIconWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
