import { Grid } from "@mui/material";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

export const validationError = (meta, validateOnMount) => {
  if (validateOnMount) {
    return meta.error || meta.submitError;
  }

  return meta.touched && (meta.error || meta.submitError);
};

export const FormFieldGrid = ({ children, className, ...props }) => {
  return (
    <Grid xs={12} item position="relative" className={clsx(className)} {...props}>
      {children}
    </Grid>
  );
};

FormFieldGrid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
