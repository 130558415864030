import { Box } from "@mui/material";
import React from "react";

import { ListCampaign } from "services/openapi";
import CampaignCard from "views/job/JobSetup/steps/CampaignVariants/CampaignCard";

const CampaignCards = ({
  campaigns,
  setSelectedCampaignId,
  selectedCampaignId,
}: {
  campaigns: ListCampaign[] | undefined;
  setSelectedCampaignId: (selectedCampaignId: string) => void;
  selectedCampaignId: string;
}): React.ReactElement => {
  return (
    <Box display="flex" maxWidth="800px" sx={{ overflowX: "auto", flexWrap: "nowrap" }}>
      {(campaigns ?? []).map(campaign => {
        const isSelected = campaign.id === selectedCampaignId;
        return (
          <CampaignCard isSelected={isSelected} setSelectedCampaignId={setSelectedCampaignId} campaign={campaign} />
        );
      })}
    </Box>
  );
};

export default CampaignCards;
