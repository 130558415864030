import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { LEVER_AUTH_INFO, LEVER_DATA } from "services/doverapi/endpointTagsConstants";
import { AtsData, LeverAuthInfo } from "services/openapi";

const leverDataEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getLeverData: build.query<AtsData, undefined>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getLeverData();
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [LEVER_DATA];
      },
    }),
    getLeverAuthInfo: build.query<LeverAuthInfo, { clientId: string }>({
      queryFn: async ({ clientId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getLeverAuthInfo({ id: clientId });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [LEVER_AUTH_INFO];
      },
    }),
  }),
});

export const { useGetLeverDataQuery, useGetLeverAuthInfoQuery } = leverDataEndpoints;
