import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import React from "react";

import Step from "sections/addcandidate/components/Step";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { AddCandidateStep } from "sections/addcandidate/types";

// Reduce the space between steps
const stepperSx = {
  ".MuiStepLabel-vertical": {
    paddingTop: 0.25,
    paddingBottom: 0.25,
  },
  ".MuiStepConnector-line": {
    minHeight: "8px",
  },
  ".MuiStepLabel-iconContainer.Mui-active": {
    paddingTop: 0.5,
    paddingBottom: 0.5,
  },
};

const AddCandidateStepper = ({ steps }: { steps: AddCandidateStep[] }): React.ReactElement => {
  const { activeStep } = useAddCandidateContext();

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} orientation="vertical" sx={stepperSx}>
        {steps.map(step => (
          <Step key={step.label} step={step} />
        ))}
      </Stepper>
    </Box>
  );
};

export default AddCandidateStepper;
