import { SocialLinkTypeEnum } from "components/SocialLinks/constants";

export const CAREERS_PAGE_SOCIAL_LINK_EXCLUDED_LINK_TYPES = [
  SocialLinkTypeEnum.Aboutme,
  SocialLinkTypeEnum.Flickr,
  SocialLinkTypeEnum.Gravatar,
  SocialLinkTypeEnum.Healthgrades,
  SocialLinkTypeEnum.Pinterest,
  SocialLinkTypeEnum.Portfolio,
  SocialLinkTypeEnum.Resume,
];
