import { useJobId } from "hooks/useJobIdFromUrl";
import { useCreateHiringPipelineStageMutation } from "services/doverapi/endpoints/hiringPipelineStage";
import { UseMutationResult } from "services/doverapi/types";
import { ApiApiCreateHiringPipelineStageOperationRequest, HiringPipelineStage } from "services/openapi";

// This is the type the rtkq endpoints takes as input

// This is the type that the mutation trigger we return from this wrapper will take as input
// It's basically the rtkq args minus the stuff we figure out for you in the wrapper
interface CreateHpsInput extends Omit<ApiApiCreateHiringPipelineStageOperationRequest, "jobId"> {}

// This is the input for the wrapper hook itself, how to configure the hook
interface UseCreateHpsInput {
  shared?: boolean;
}

// This hook should be the main entry point for creating a hiring pipeline stage
// It allows for an optional shared mutation cache key, and will automatically grab the jobId from the url
export const useCreateHps = ({
  shared,
}: UseCreateHpsInput): [(input: CreateHpsInput) => void, UseMutationResult<HiringPipelineStage>] => {
  const [jobId] = useJobId();

  const [createHps, result] = useCreateHiringPipelineStageMutation({
    fixedCacheKey: shared ? "shared-create-hps-key" : undefined,
  });

  return [
    (input: CreateHpsInput): void => {
      if (!jobId) {
        return;
      }

      const args = { ...input, jobId };
      createHps(args);
    },
    result,
  ];
};
