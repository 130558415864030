/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PersonaParams,
    PersonaParamsFromJSON,
    PersonaParamsFromJSONTyped,
    PersonaParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface Persona
 */
export interface Persona {
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    name: string;
    /**
     * What a customer would call this
     * @type {string}
     * @memberof Persona
     */
    cleanName?: string | null;
    /**
     * Description of persona surfaced in the app
     * @type {string}
     * @memberof Persona
     */
    definition?: string | null;
    /**
     * 
     * @type {PersonaParams}
     * @memberof Persona
     */
    paramsJson: PersonaParams;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    readonly jobCategoryDisplay?: string;
    /**
     * 
     * @type {string}
     * @memberof Persona
     */
    scopedToClient?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Persona
     */
    readonly parentHasMlTitles?: boolean;
}

export function PersonaFromJSON(json: any): Persona {
    return PersonaFromJSONTyped(json, false);
}

export function PersonaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Persona {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'cleanName': !exists(json, 'clean_name') ? undefined : json['clean_name'],
        'definition': !exists(json, 'definition') ? undefined : json['definition'],
        'paramsJson': PersonaParamsFromJSON(json['params_json']),
        'jobCategoryDisplay': !exists(json, 'job_category_display') ? undefined : json['job_category_display'],
        'scopedToClient': !exists(json, 'scoped_to_client') ? undefined : json['scoped_to_client'],
        'parentHasMlTitles': !exists(json, 'parent_has_ml_titles') ? undefined : json['parent_has_ml_titles'],
    };
}

export function PersonaToJSON(value?: Persona | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'clean_name': value.cleanName,
        'definition': value.definition,
        'params_json': PersonaParamsToJSON(value.paramsJson),
        'scoped_to_client': value.scopedToClient,
    };
}


