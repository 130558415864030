import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import BaseStepControls from "sections/addcandidate/components/BaseStepControls";
import { Editor } from "sections/addcandidate/components/EmailEditor";
import HorizontalLoader from "sections/addcandidate/components/HorizontalLoader";
import SelectCampaign from "sections/addcandidate/components/SelectCampaign";
import { StepConfig } from "sections/addcandidate/data/stepConfig";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { AddCandidateStep, StepTypes } from "sections/addcandidate/types";
import { InterviewPipelineCandidateInfoSchema } from "sections/addcandidate/types/candidateInfo";
import { useListCampaignsQuery } from "services/doverapi/endpoints/campaign";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { ListCampaign, ListCampaignStateEnum } from "services/openapi";

const StepContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: {
      [StepTypes.JOB_SELECT]: { jobId },
      [StepTypes.CANDIDATE_INFO]: candidateInfoState,
      [StepTypes.SELECT_CAMPAIGN]: state,
    },
    setStepState,
  } = useAddCandidateContext();

  const { firstName, email: candidateEmail } = candidateInfoState as InterviewPipelineCandidateInfoSchema;

  const [outreachValid, setOutreachValid] = useState(true);
  const [showEditor, setShowEditor] = useState(false);

  // Campaigns
  const { data: emailCampaignsEntityAdapter, isLoading: campaignsLoading } = useListCampaignsQuery({ jobId: jobId! });
  const activeEmailCampaigns = useMemo(() => {
    return listAllEntities(emailCampaignsEntityAdapter).filter(c => c.state === ListCampaignStateEnum.Active);
  }, [emailCampaignsEntityAdapter]);

  const disableContinue = !state.campaign || !outreachValid;

  //-----------------------------------------------------------------------------
  // Callbacks

  const setBody = useCallback(
    (newBody: string) => {
      setStepState(StepTypes.SELECT_CAMPAIGN, { ...state, body: newBody });
    },
    [setStepState, state]
  );
  const setSubject = useCallback(
    (newSubject: string) => setStepState(StepTypes.SELECT_CAMPAIGN, { ...state, subject: newSubject }),
    [setStepState, state]
  );
  const setCcEmails = useCallback(
    (newCcEmails: string[]) => setStepState(StepTypes.SELECT_CAMPAIGN, { ...state, ccEmails: newCcEmails }),
    [setStepState, state]
  );

  const setSelectedCampaign = useCallback(
    (newCampaign: ListCampaign | null) => {
      if (!state.campaign || state.campaign.id !== newCampaign?.id) {
        const message = newCampaign?.threadMessages?.find(m => !m.prevMessage);
        setStepState(StepTypes.SELECT_CAMPAIGN, {
          campaign: newCampaign,
          subject: message?.subjectTemplate,
          body: message?.bodyTemplate,
          ccEmails: [],
        });
      }
    },
    [setStepState, state]
  );

  //-----------------------------------------------------------------------------
  // Effects

  useEffect(() => {
    if (!state.campaign) {
      setSelectedCampaign(activeEmailCampaigns ? activeEmailCampaigns[0] : null);
    }
  }, [activeEmailCampaigns, setSelectedCampaign, state.campaign]);

  //-----------------------------------------------------------------------------
  // Render

  if (campaignsLoading) {
    return <HorizontalLoader />;
  }

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ mb: 2, mt: 2 }}>
        <SelectCampaign campaigns={activeEmailCampaigns} campaign={state.campaign} setCampaign={setSelectedCampaign} />
        {state.campaign && (
          <Stack direction="column" spacing={1}>
            <Button variant={ButtonVariant.Ghost} onClick={(): void => setShowEditor(!showEditor)}>
              {showEditor ? "Hide Editor" : "Edit message"}
            </Button>
            <Collapse in={showEditor}>
              <Editor
                body={state.body}
                subject={state.subject}
                fromEmail={state.campaign.emailAlias?.email}
                toName={firstName}
                toEmail={candidateEmail ?? "<willbeaddedlater>"}
                ccEmails={state.ccEmails}
                setBody={setBody}
                setSubject={setSubject}
                setCcEmails={setCcEmails}
                setOutreachValid={setOutreachValid}
              />
            </Collapse>
          </Stack>
        )}
      </Stack>

      <BaseStepControls disableContinue={disableContinue} />
    </>
  );
};

const PostCompletionContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: {
      [StepTypes.SELECT_CAMPAIGN]: { campaign, body, subject },
    },
  } = useAddCandidateContext();

  const firstMessageInCampaign = useMemo(() => {
    if (!campaign) {
      return null;
    }

    return campaign.threadMessages?.find(message => message.prevMessage === undefined);
  }, [campaign]);

  const modificationsMade = useMemo(() => {
    return (
      (body && body !== firstMessageInCampaign?.bodyTemplate) ||
      (subject && subject !== firstMessageInCampaign?.subjectTemplate)
    );
  }, [firstMessageInCampaign, body, subject]);

  //-----------------------------------------------------------------------------
  // Render

  if (!campaign) {
    return <></>;
  }

  return (
    <BodySmall>
      <em>{campaign.name}</em> campaign {campaign.emailAlias?.email && `(from: ${campaign.emailAlias?.email})`}{" "}
      {modificationsMade && "(with modifications)"}
    </BodySmall>
  );
};

const step: AddCandidateStep = {
  ...StepConfig[StepTypes.SELECT_CAMPAIGN],
  StepContent: <StepContent />,
  PostCompletionContent: <PostCompletionContent />,
};

export default step;
