import { Divider, Stack } from "@mui/material";
import React from "react";

import { CommandBarSocialLinks } from "App/components/Nav/CommandBarSocialLinks";
import { Body, BodySmall, PageTitle } from "components/library/typography";
import { PronounceName } from "components/PronounceName";
import { SocialLinkType } from "services/openapi";

interface CandidatePreviewDetails {
  fullName: string;
  jobTitle: string;
  currentEmployment?: string;
  linkedInUrl?: string;
  stageName?: string;
  status?: string;
}

interface CommandBarCandidatePreviewProps {
  details: CandidatePreviewDetails;
}

const CommandBarCandidatePreview = ({ details }: CommandBarCandidatePreviewProps): React.ReactElement => {
  const firstName = details.fullName.split(" ")[0];

  return (
    <Stack spacing={1} padding="16px 0px">
      <Stack direction="row" width="100%" justifyContent="left">
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          divider={<Divider orientation="vertical" sx={{ height: "10px", mr: "4px" }} />}
        >
          <Stack direction="row" spacing={0.5}>
            <PageTitle id="candidate-name">{details.fullName}</PageTitle>
            <PronounceName name={firstName} />
          </Stack>
          {details.linkedInUrl && (
            <CommandBarSocialLinks
              socialLinks={[
                {
                  linkType: SocialLinkType.LINKEDIN,
                  url: details.linkedInUrl,
                },
              ]}
            />
          )}
        </Stack>
      </Stack>
      {details.currentEmployment && <Body>{details.currentEmployment}</Body>}
      <Divider />
      <Body>💼 {details.jobTitle}</Body>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        divider={<Divider orientation="vertical" sx={{ height: "10px", mr: "4px" }} />}
      >
        {details.stageName && <BodySmall>{details.stageName}</BodySmall>}
        {details.status && <BodySmall>{details.status}</BodySmall>}
      </Stack>
    </Stack>
  );
};

export default CommandBarCandidatePreview;
