import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { MultipartSchedulingBaseInterviewSubstage } from "services/openapi";
import { initialState, interviewEventsAdapter } from "views/interview/InterviewScheduler/constants";
import { CalendarResource } from "views/interview/InterviewScheduler/types";

const interviewSchedulerSlice = createSlice({
  name: "interviewScheduler",
  initialState: initialState,
  reducers: {
    addResource(state, action: PayloadAction<CalendarResource | undefined>): void {
      const resource = action.payload;

      if (!resource) {
        return;
      }

      // We should not add duplicate resources
      if (state.resources.some(r => r.id === resource.id)) {
        return;
      }

      state.resources.push(resource);
    },
    removeResource(state, action: PayloadAction<CalendarResource | undefined>): void {
      const resource = action.payload;

      if (!resource) {
        return;
      }

      state.resources = state.resources.filter(r => r.id !== resource.id);
    },
    addInterviewRound(
      state,
      action: PayloadAction<{
        interviewRound: MultipartSchedulingBaseInterviewSubstage;
        id: string;
        start: number | null; // unix timestamp in milliseconds
        interviewerIds: string[];
        newName: string;
        newDuration: number;
      }>
    ): void {
      const { interviewRound, id, start, interviewerIds, newDuration, newName } = action.payload;

      const interviewEvent = {
        id: id,
        multipartInterviewSubstageId: interviewRound.multipartInterviewSubstageId,
        candidateMultipartInterviewSubstageId: interviewRound.candidateMultipartInterviewSubstageId,
        title: newName,
        interviewerIds: interviewerIds,
        start: start ? new Date(start) : null,
        duration: newDuration,
        possibleInterviewers: interviewRound.possibleInterviewers,
        order: interviewRound.order!,
        // FE only fields
        interview: true,
        end: start
          ? moment(start)
              .add(newDuration ?? interviewRound.durationInMinutes, "m")
              .toDate()
          : null,
      };

      interviewEventsAdapter.upsertOne(state.interviewRounds, interviewEvent);
    },
    removeInterviewRound(
      state,
      action: PayloadAction<{
        id: string;
        isCandidateMultipartInterviewSubstage: boolean;
      }>
    ): void {
      const { id, isCandidateMultipartInterviewSubstage } = action.payload;
      interviewEventsAdapter.removeOne(state.interviewRounds, id);
      if (isCandidateMultipartInterviewSubstage) {
        state.deletedSubstageIds.push(id);
      }
    },
    cancelInterviewRound(
      state,
      action: PayloadAction<{
        id: string;
      }>
    ): void {
      const { id } = action.payload;
      state.canceledSubstageIds.push(id);
    },
    keepInterviewRound(
      state,
      action: PayloadAction<{
        id: string;
      }>
    ): void {
      const { id } = action.payload;
      const idx = state.canceledSubstageIds.indexOf(id);
      if (idx > -1) {
        state.canceledSubstageIds.splice(idx, 1);
      }
    },
    setLocationOverride(state, action: PayloadAction<string>): void {
      state.locationOverride = action.payload;
    },
    setDate(state, action: PayloadAction<Date>): void {
      state.date = action.payload;
    },
    setTimezone(state, action: PayloadAction<string>): void {
      state.timezone = action.payload;
    },
    incrementExpectedNumberOfStages(state): void {
      state.expectedNumberOfSubstages++;
    },
    decrementExpectedNumberOfStages(state): void {
      state.expectedNumberOfSubstages--;
    },
    setExpectedNumberOfSubstages(state, action: PayloadAction<number>): void {
      state.expectedNumberOfSubstages = action.payload;
    },
  },
});

export const interviewSchedulerActions = interviewSchedulerSlice.actions;
export default interviewSchedulerSlice.reducer;
