import { YearsOfExperienceRange } from "components/library/YOESlider";

export function computeYOESliderValues(
  yearsOfExperience: YearsOfExperienceRange,
  diffMin: number,
  rangeMax: number
): number[] {
  if (rangeMax < diffMin) {
    return [0, diffMin];
  }

  const sliderMax = rangeMax + 1;

  // Ensure we have a whole number
  const roundedMin = Math.round(yearsOfExperience.min ?? 0);
  // Ensure the min is no less than 0 and no more than rangeMax - diffMin
  const min = Math.min(Math.max(roundedMin, 0), rangeMax - diffMin);

  // Ensure we have a whole number
  const roundedMax = Math.round(yearsOfExperience.max ?? sliderMax);
  // Ensure the max is no more than slider max, and no less than min + diffMin
  const max = Math.max(Math.min(roundedMax, sliderMax), min + diffMin);

  return [min, max];
}
