import { Stack } from "@mui/material";
import React, { useCallback, useMemo } from "react";

import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { BodySmall } from "components/library/typography";
import BaseStepControls from "sections/addcandidate/components/BaseStepControls";
import PipelineStageSelect from "sections/addcandidate/components/SelectPipelineStage";
import { StepConfig } from "sections/addcandidate/data/stepConfig";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { AddCandidateStep, StepTypes } from "sections/addcandidate/types";
import { InterviewPipelineCandidateInfoSchema } from "sections/addcandidate/types/candidateInfo";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { DashboardJob, JobStage } from "services/openapi";
import { showErrorToast } from "utils/showToast";

// TODO: ADd the ATS candidate support

const StepContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: {
      [StepTypes.JOB_SELECT]: { jobId },
      [StepTypes.STAGE_SELECT]: state,
      [StepTypes.CANDIDATE_INFO]: candidateInfoState,
    },
    handleNextStep,
    handleAddCandidate,
    setStepState,
  } = useAddCandidateContext();

  // supercomponent
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();
  const candidateEmail = (candidateInfoState as InterviewPipelineCandidateInfoSchema).email;

  // TODO: USE a better selector
  const { activeJobs: unsortedJobs } = useGetJobsDerivedData({});
  const job: DashboardJob | undefined = unsortedJobs?.find(job => job.id === jobId);

  //-----------------------------------------------------------------------------
  // Callbacks

  const setStage = useCallback(
    (jobStage?: JobStage) => {
      setStepState(StepTypes.STAGE_SELECT, { ...state, jobStage });
    },
    [setStepState, state]
  );

  const addWithoutScheduling = (): void => {
    handleAddCandidate().then(result => {
      if (result.success) {
        handleNextStep();
      } else {
        showErrorToast(result.message || "Error adding candidate, please try again.");
      }
    });
  };

  const addAndSchedule = (): void => {
    handleAddCandidate().then(result => {
      if (result.success) {
        const candidateId = result.candidateId!;
        handleNextStep();
        showCandidateActionModal({
          candidateId,
          modalType: CandidateActionModalType.Schedule,
        });
      } else {
        showErrorToast(result.message || "Error adding candidate, please try again.");
      }
    });
  };

  //-----------------------------------------------------------------------------
  // Render

  if (!job) {
    return <></>;
  }

  return (
    <Stack spacing={2} mt={2}>
      <PipelineStageSelect job={job} jobStage={state.jobStage} setPipelineStage={setStage} />
      <BaseStepControls
        jobId={job?.id}
        continueLabel="Add Candidate"
        showScheduleButton
        disableContinue={!state.jobStage}
        isSchedulable={state.jobStage?.isSchedulable}
        hasEmail={!!candidateEmail}
        overrideHandleNext={addWithoutScheduling}
        handleAddAndSchedule={addAndSchedule}
      />
    </Stack>
  );
};

const PostCompletionContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: {
      [StepTypes.JOB_SELECT]: { jobId },
      [StepTypes.STAGE_SELECT]: { jobStage },
    },
  } = useAddCandidateContext();
  const { activeJobs: unsortedJobs } = useGetJobsDerivedData({});
  const job: DashboardJob | undefined = unsortedJobs?.find(job => job.id === jobId);
  const selectedJobStage = useMemo(
    (): JobStage | null => job?.hiringPipelineStages!.find(stage => stage.id === jobStage?.id) ?? null,
    [job, jobStage]
  );

  //-----------------------------------------------------------------------------
  // Render

  if (!selectedJobStage) {
    return <></>;
  }

  return <BodySmall>{selectedJobStage?.name}</BodySmall>;
};

const step: AddCandidateStep = {
  ...StepConfig[StepTypes.STAGE_SELECT],
  StepContent: <StepContent />,
  PostCompletionContent: <PostCompletionContent />,
};

export default step;
