/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationOption
 */
export interface LocationOption {
    /**
     * 
     * @type {string}
     * @memberof LocationOption
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationOption
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof LocationOption
     */
    locationType: LocationOptionLocationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationOption
     */
    readonly city?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationOption
     */
    readonly state?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationOption
     */
    readonly country?: string;
}

/**
* @export
* @enum {string}
*/
export enum LocationOptionLocationTypeEnum {
    City = 'CITY',
    Country = 'COUNTRY',
    Region = 'REGION',
    Admin1 = 'ADMIN_1'
}

export function LocationOptionFromJSON(json: any): LocationOption {
    return LocationOptionFromJSONTyped(json, false);
}

export function LocationOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'displayName': json['display_name'],
        'locationType': json['location_type'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function LocationOptionToJSON(value?: LocationOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_name': value.displayName,
        'location_type': value.locationType,
    };
}


