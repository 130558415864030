import PencilIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams, GridRowParams } from "@mui/x-data-grid";
import React from "react";
import { useQueryParams } from "use-query-params";

import DataGridLoadingOverlay from "components/DataGridLoadingOverlay";
import { useAdminListCampaignsQuery } from "services/doverapi/endpoints/campaign";
import { queryParamConfig } from "views/admin/Campaigns/types";
import { queryParamsToApiParams } from "views/admin/Campaigns/utils";

const columns: GridColDef[] = [
  {
    field: "jobName",
    headerName: "Job Name",
    flex: 1,
    minWidth: 200,
    sortable: false,
    valueGetter: (params: GridValueGetterParams): string => `${params.row.job?.name}`,
    renderCell: (params: GridRenderCellParams): React.ReactElement => (
      <>
        <IconButton style={{ marginLeft: "-10px", display: "inline-block" }}>
          <PencilIcon />
        </IconButton>
        <div style={{ lineHeight: "20px" }}>
          {params.row.client?.name}: {params.row.job?.name}
        </div>
      </>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    flex: 0.5,
    minWidth: 150,
    valueGetter: (params: GridValueGetterParams): string => params.row.name ?? "-",
  },
  {
    field: "personaName",
    headerName: "Persona",
    flex: 0.5,
    minWidth: 150,
    valueGetter: (params: GridValueGetterParams): string => params.row.persona?.name ?? "-",
  },
  {
    field: "state",
    headerName: "State",
    flex: 0.5,
    minWidth: 150,
    valueGetter: (params: GridValueGetterParams): string => params.row.state ?? "-",
  },
  {
    field: "modified",
    headerName: "Modified",
    flex: 0.5,
    minWidth: 150,
    valueGetter: (params: GridValueGetterParams): string => params.row.modified ?? "-",
  },
  {
    field: "openRate",
    headerName: "Open Rate",
    flex: 0.5,
    minWidth: 150,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.openRate ? (params.row.openRate * 100).toFixed(2) + "%" : "-",
  },
  {
    field: "interestRate",
    headerName: "Interest Rate",
    flex: 0.5,
    minWidth: 150,
    valueGetter: (params: GridValueGetterParams): string =>
      params.row.openRate ? (params.row.interestRate * 100).toFixed(2) + "%" : "-",
  },
];

export default (): React.ReactElement => {
  const [query] = useQueryParams(queryParamConfig);
  const { data: campaigns, isLoading: campaignsAreLoading } = useAdminListCampaignsQuery(queryParamsToApiParams(query));

  return (
    <DataGrid
      onRowClick={(params: GridRowParams): void => {
        window.open(`/admin/campaign/${params.id}`, "_blank", "noopener noreferrer");
      }}
      loading={campaignsAreLoading}
      autoHeight
      rowHeight={72}
      rows={campaigns ?? []}
      columns={columns}
      hideFooter
      disableSelectionOnClick
      disableColumnFilter
      components={{
        LoadingOverlay: DataGridLoadingOverlay,
      }}
      density="compact"
    />
  );
};
