import { Box } from "@mui/material";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";

interface BadgeProps {
  borderColor?: string;
  backgroundColor?: string;
  color?: string;
  emphasize?: boolean;
  display?: string;
  hideborder?: boolean;
}

const Badge = styled(Box)<BadgeProps>`
  background-color: ${({ backgroundColor }): string => (backgroundColor ? backgroundColor : colors.grayscale.gray200)};
  border: ${({ borderColor, hideborder }): string =>
    hideborder ? "none" : "1px solid " + (borderColor ? borderColor : colors.grayscale.gray300)};
  color: ${({ color }): string => (color ? color : colors.black)};
  display: ${({ display }): string => (display ? display : "flex")};
  align-items: center;

  padding: 0px 5px;
  border-radius: 5px;
  line-height: 24px;
  max-width: fit-content;
  white-space: nowrap;
  cursor: default;
  ${({ emphasize }): string =>
    emphasize
      ? `
       font-size: 10px;
       font-weight: 700;
       height: 24px;
       letter-spacing: .1rem;
      `
      : `
      font-size: 12px;
      height: 28px;
      `}
`;

interface Props extends BadgeProps {
  text: React.ReactNode;
  icon?: ReactElement;
}

interface StyledDivProps {
  display?: string;
}

const StyledDiv = styled.div<StyledDivProps>`
  display: ${({ display }): string => (display ? display : "initial")};
`;

const StyledBadge = (props: Props): React.ReactElement => {
  return (
    <StyledDiv display={props.display}>
      <Badge
        borderColor={props.borderColor}
        backgroundColor={props.backgroundColor}
        color={props.color}
        emphasize={props.emphasize}
        display={props.display}
        hideborder={props.hideborder}
      >
        {props.text}
        {props.icon && (
          <>
            <Spacer width={8} />
            {props.icon}
          </>
        )}
      </Badge>
    </StyledDiv>
  );
};

export default StyledBadge;
