import { MenuItem, Select, SelectChangeEvent, Stack, styled } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { sortBy } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { getNewSearchV3 } from "components/dover/helpers";
import { Autocomplete } from "components/library/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Body, BodySmall, Heading } from "components/library/typography";
import CustomModal from "components/Modal";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { selectFromListJobsQueryResult } from "services/doverapi/endpoints/job";
import { useGetJobQuery, useListJobsQuery } from "services/doverapi/endpoints/job/endpoints";
import { useCreateSearchV3Mutation, useListPersonasQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import { PersonaIDsRequired } from "services/doverapi/endpoints/search-v3/types";
import { getEntityById } from "services/doverapi/entityAdapterUtils";
import { DashboardJob, SearchV3 } from "services/openapi";
import { colors } from "styles/theme";

interface NewSearchInfo {
  job: DashboardJob;
  persona: PersonaIDsRequired;
  searchName: string;
}

interface CreateNewSearchModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  jobId?: string;
}

const StyledSelect = styled(Select)`
  width: 100%;

  .MuiSelect-select {
    padding-top: 10px;
    padding-bottom: 10px;
    background: ${colors.white};
    border-color: ${colors.grayscale.gray300};
  }

  fieldset {
    border-color: ${colors.grayscale.gray300};
    top: 0px;

    legend {
      display: none;
    }
  }
`;

export const jobsSortFunc = (jobs: DashboardJob[]): DashboardJob[] =>
  sortBy(jobs, [(job: DashboardJob): string => (job.title ? job.title.toLowerCase() : "")]);

interface JobsSelectProps {
  onSetSelectedJob: (job: DashboardJob) => void;
  providedJob?: DashboardJob;
}

const JobsSelect = React.memo(
  ({ onSetSelectedJob, providedJob }: JobsSelectProps): React.ReactElement => {
    const [selectedJobId, setSelectedJobId] = React.useState<string | undefined>(undefined);
    const { allJobs, data } = useListJobsQuery(undefined, {
      selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults, { sortFunc: jobsSortFunc }),
    });
    const jobsWithSourcingEnabled = React.useMemo(() => {
      const jobsToReturn = allJobs?.filter(job => job.hasManagedOutboundEnabled);
      if (providedJob && !jobsToReturn?.includes(providedJob)) {
        return (jobsToReturn ?? []).concat([providedJob]);
      }
      return jobsToReturn;
    }, [allJobs, providedJob]);

    const selectedJob = getEntityById(data, selectedJobId) ?? jobsWithSourcingEnabled?.[0];

    if (providedJob && selectedJobId === undefined) {
      setSelectedJobId(providedJob.id);
    }

    React.useEffect(() => {
      if (selectedJob) {
        onSetSelectedJob(selectedJob);
      }
    }, [onSetSelectedJob, selectedJob]);

    if (jobsWithSourcingEnabled === undefined) {
      return <></>;
    }

    return (
      <Stack spacing={1}>
        <Body weight="500">{"Job"}</Body>
        <StyledSelect
          disabled={!!providedJob}
          value={selectedJobId ?? jobsWithSourcingEnabled[0].id}
          onChange={(e: SelectChangeEvent<unknown>): void => {
            setSelectedJobId(e.target.value as string);
          }}
        >
          {jobsWithSourcingEnabled.map(job => {
            return (
              <MenuItem key={job.id} value={job.id}>
                <Body color={colors.grayscale.gray600}>{job.title}</Body>
              </MenuItem>
            );
          })}
        </StyledSelect>
      </Stack>
    );
  }
);

export const CreateNewSearchModal = React.memo(({ isModalOpen, closeModal, jobId }: CreateNewSearchModalProps) => {
  const { data: personasOptions } = useListPersonasQuery({ limit: 1000 });
  const clientId = useGetClientId();
  const navigate = useNavigate();
  const doverPlan = useGetDoverPlan();

  // Info we need to create a new search
  const [newSearchInfo, setNewSearchInfo] = React.useState<NewSearchInfo | undefined>(undefined);
  const [selectedJob, setSelectedJob] = React.useState<DashboardJob | undefined>(undefined);
  const [selectedPersonaForNewSearch, setSelectedPersonaForNewSearch] = React.useState<PersonaIDsRequired | undefined>(
    undefined
  );
  const [searchName, setSearchName] = React.useState<string>("");
  const { data: jobFromArgs } = useGetJobQuery(jobId ?? skipToken);

  const [createSearchV3, { isLoading: isCreatingSearchV3 }] = useCreateSearchV3Mutation();

  const handleCreateNewSearchOnNext = React.useCallback(() => {
    // Without this data we can't take any actions
    if (!selectedJob?.id || !selectedPersonaForNewSearch || !searchName || !clientId) {
      return;
    }

    // Create the search
    createSearchV3(
      getNewSearchV3({ persona: selectedPersonaForNewSearch, jobId: selectedJob.id, searchName, clientId })
    )
      .unwrap()
      .then((newSearchV3: SearchV3) => {
        if (!newSearchV3.id) {
          return;
        }

        navigate(APP_ROUTE_PATHS.sourcing(newSearchV3.id));
      });
  }, [clientId, createSearchV3, navigate, searchName, selectedJob, selectedPersonaForNewSearch]);

  const handleCloseModal = React.useCallback(() => {
    setNewSearchInfo(undefined);
    closeModal();
  }, [closeModal]);

  const createNewJob = React.useCallback(() => {
    window.open("/home?createJobDrawerOpen=1");
  }, []);

  if (!personasOptions) {
    return <></>;
  }

  return (
    <CustomModal
      open={isModalOpen}
      onClose={handleCloseModal}
      customPaperProps={{ sx: { overflowY: "hidden" } }}
      title={<Heading>New search</Heading>}
      maxWidth={"md"}
      omitDividers={true}
      dialogActions={
        selectedJob !== undefined && newSearchInfo !== undefined ? (
          undefined
        ) : (
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Secondary} onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              onClick={handleCreateNewSearchOnNext}
              disabled={searchName.length === 0 || !selectedPersonaForNewSearch}
              loading={isCreatingSearchV3}
            >
              Next
            </Button>
          </Stack>
        )
      }
    >
      <Stack spacing={2}>
        <JobsSelect onSetSelectedJob={setSelectedJob} providedJob={jobFromArgs} />
        <Stack spacing={2} alignItems="start">
          <Button variant={ButtonVariant.Ghost} removePadding={true} onClick={createNewJob} disabled={!doverPlan}>
            <BodySmall color={colors.linkLight}>+ Create new job</BodySmall>
          </Button>
        </Stack>
        <TextField
          required={true}
          errorMessage="Required"
          title="Search Name"
          text={searchName}
          placeholderText="Enter search name"
          onTextUpdated={setSearchName}
        />
        <Autocomplete
          placeholder="Select a persona..."
          staticOptions={personasOptions}
          sortByField="cleanName"
          errorText={!selectedPersonaForNewSearch ? "Required" : ""}
          onSelectedOptionChange={(selectedOption: PersonaIDsRequired): void => {
            setSelectedPersonaForNewSearch(selectedOption);
          }}
          multiple={false}
          getOptionLabel={(option: PersonaIDsRequired): string => option.cleanName!}
        />
      </Stack>
    </CustomModal>
  );
});
