import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import MagicWandIcon from "assets/icons/chonky-magic-wand.svg";
import WandEnvelopeIcon from "assets/icons/wand-envelope.svg";
import CompanyPitchQuestionFields from "components/dover/CompanySetupPitch/QuestionFields";
import { companyPitchFormSchema, CompanyPitchFormSchema } from "components/dover/CompanySetupPitch/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, PageTitle, ButtonText } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useUpdateCompanyBioMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { useGetCompanyPitchQuestionsQuery } from "services/doverapi/endpoints/company-setup/endpoints";
import { colors, filters } from "styles/theme";
import { useExtensionCreateJobContext } from "views/create-job/ExtensionJobCreationFlow/context";
import { WhiteCard } from "views/create-job/ExtensionJobCreationFlow/styles";

const StyledSVG = styled(ReactSVG)`
  display: inline;
  svg {
    margin-top: -4px;
    width: 17px;
    height: 17px;
  }
`;
const CompanyPitch = (): React.ReactElement => {
  const clientId = useGetClientId();
  const [updateCompanyBioMutation, { isLoading: updatingCompanyBio }] = useUpdateCompanyBioMutation();
  const { data: companyPitchQuestions, isLoading: loadingCompanyPitch } = useGetCompanyPitchQuestionsQuery({});
  const companyPitchFormMethods = useForm<CompanyPitchFormSchema>({ resolver: zodResolver(companyPitchFormSchema) });
  const { handleSubmit: handleCompanyPitchSubmit } = companyPitchFormMethods;
  const { jobId, onNext } = useExtensionCreateJobContext();

  const onCompanyPitchSubmit = React.useCallback(
    async (data: CompanyPitchFormSchema): Promise<void> => {
      const trySubmitCompanyPitch = async (): Promise<void> => {
        if (companyPitchQuestions === undefined) {
          return;
        }

        await updateCompanyBioMutation({
          answers: {
            ...(companyPitchQuestions.answers as any),
            companyDescription: data.companyDescription,
          },
          clientId,
          showToast: false,
        }).unwrap();

        if (!jobId) {
          console.error("Job ID is undefined");
          return;
        }
        onNext();
      };
      await trySubmitCompanyPitch();
    },
    [jobId, clientId, companyPitchQuestions, onNext, updateCompanyBioMutation]
  );

  if (loadingCompanyPitch) {
    return (
      <WhiteCard>
        <Stack width="580px" justifyContent="center">
          <DoverLoadingSpinner active={true} filter={filters.filterDarkGreen} />
          <Box textAlign="center">
            <Body>Generating company pitch questions...</Body>
          </Box>
        </Stack>
      </WhiteCard>
    );
  }

  if (updatingCompanyBio) {
    return (
      <WhiteCard>
        <Stack width="580px" justifyContent="center">
          <DoverLoadingSpinner active={true} filter={filters.filterDarkGreen} />
          <Box textAlign="center">
            <Body>Saving company pitch answers...</Body>
          </Box>
        </Stack>
      </WhiteCard>
    );
  }

  return (
    <WhiteCard>
      <Stack spacing={2} sx={{ width: "500px" }}>
        <Box display="flex" justifyContent="center">
          <ReactSVG src={WandEnvelopeIcon} />
        </Box>
        <PageTitle>Dover will auto-generate your customized outreach template based on your responses</PageTitle>
        <FormProvider {...companyPitchFormMethods}>
          <CompanyPitchQuestionFields companyPitchFormMethods={companyPitchFormMethods} />
          <Box display="flex" justifyContent="flex-end" paddingTop="8px">
            <Button variant={ButtonVariant.Primary} onClick={handleCompanyPitchSubmit(onCompanyPitchSubmit)}>
              <Stack direction="row" spacing={1}>
                <StyledSVG src={MagicWandIcon} />
                <ButtonText centered color={colors.white}>
                  Generate Outreach
                </ButtonText>
              </Stack>
            </Button>
          </Box>
        </FormProvider>
      </Stack>
    </WhiteCard>
  );
};

export default CompanyPitch;
