import { SerializedError } from "@reduxjs/toolkit";

import { EmailsPerWeekOption, EMAILS_PER_WEEK_CONFIGS } from "components/dover/dover-outbound-modal/constants";
import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { JOB_FULFILLMENT, LIST_TAG, PIPELINE_CANDIDATE } from "services/doverapi/endpointTagsConstants";
import {
  GetDoverOutboundConfiguration200Response,
  UpdateDoverOutboundConfiguration,
  UpdateDoverOutboundConfigurationShouldEngagePassiveEnum,
} from "services/openapi";

const jobFulfillmentEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getDoverOutboundConfiguration: build.query<GetDoverOutboundConfiguration200Response, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.getDoverOutboundConfiguration({ id: jobId });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      providesTags: (result, error, jobId) => {
        return result ? [{ type: JOB_FULFILLMENT, id: jobId } as const] : [];
      },
    }),
    updateDoverOutboundConfiguration: build.mutation<
      UpdateDoverOutboundConfiguration,
      {
        jobId: string;
        emailsPerWeekOption?: EmailsPerWeekOption;
        emailsPerWeek?: number;
        phoneScreensPerWeek?: number;
        passiveCandidates?: boolean;
        autoQueueOutreach?: boolean;
        freeTrialStartDate?: Date;
        sourceSimilarCandidates?: boolean;
      }
    >({
      queryFn: async ({
        jobId,
        emailsPerWeekOption,
        emailsPerWeek,
        phoneScreensPerWeek,
        passiveCandidates,
        autoQueueOutreach,
        sourceSimilarCandidates,
      }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        let shouldEngagePassiveCandidates:
          | UpdateDoverOutboundConfigurationShouldEngagePassiveEnum
          | undefined = undefined;
        if (typeof passiveCandidates !== undefined) {
          shouldEngagePassiveCandidates = passiveCandidates
            ? UpdateDoverOutboundConfigurationShouldEngagePassiveEnum.InterestedInRole
            : UpdateDoverOutboundConfigurationShouldEngagePassiveEnum.ActiveOnly;
        }
        try {
          result = await client.updateDoverOutboundConfiguration({
            id: jobId,
            data: {
              emailsPerWeekOption: emailsPerWeekOption
                ? EMAILS_PER_WEEK_CONFIGS[emailsPerWeekOption].expectedNewCandidatesCount
                : undefined,
              emailsPerWeek,
              phoneScreensPerWeek,
              shouldEngagePassive: shouldEngagePassiveCandidates,
              autoQueueOutreach,
              sourceSimilarCandidates,
            },
          });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: (result, error, args) => {
        return result
          ? [
              { type: JOB_FULFILLMENT, id: args.jobId },
              { type: PIPELINE_CANDIDATE, id: LIST_TAG },
            ]
          : [];
      },
    }),
  }),
});

export const {
  useGetDoverOutboundConfigurationQuery,
  useUpdateDoverOutboundConfigurationMutation,
} = jobFulfillmentEndpoints;
