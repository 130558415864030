import React, { createContext, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { useCreateJobMutation } from "services/doverapi/endpoints/create-job/endpoints";
import { CreateNewJobRequestCreateJobContextEnum } from "services/openapi";
import { showErrorToast } from "utils/showToast";
import {
  EXTENSION_JOB_CREATION_CONFIG,
  KEYED_BY_PATH_EXTENSION_JOB_CREATION_CONFIG,
} from "views/create-job/ExtensionJobCreationFlow/constants";
import {
  BasicsCreateJobFormData,
  ExtensionCreateJobState,
  ExtensionJobCreationStep,
} from "views/create-job/ExtensionJobCreationFlow/types";
import { getExtensionUrlPath } from "views/create-job/ExtensionJobCreationFlow/utils";

const initialState: ExtensionCreateJobState = {
  step: "",
  onNext: () => {},
  // Steps
  // 1. Basics
  creatingJob: false,
  onSubmitCreateJob: () => Promise.resolve(),
};

const ExtensionCreateJobContext = createContext<ExtensionCreateJobState>(initialState);

export const ExtensionCreateJobProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const [createJob, { isLoading: loadingCreatingJob, data: createJobResponse }] = useCreateJobMutation();
  const creatingJob = loadingCreatingJob;
  //----------------------------------------
  // State

  const [jobId, setJobId] = React.useState<string | undefined>();
  const { step } = useParams<{ step: string }>();

  //----------------------------------------
  // Handlers and callbacks

  // This is the callback that is passed to each step to allow them to navigate to the next step
  // The steps are responsible for saving their data before calling this callback.
  const onNext = React.useCallback(() => {
    if (!step) {
      return;
    }

    const nextStep = KEYED_BY_PATH_EXTENSION_JOB_CREATION_CONFIG[step]?.nextStep;

    if (nextStep) {
      navigate(getExtensionUrlPath(nextStep));
      return;
    }

    navigate(APP_ROUTE_PATHS.home());
  }, [step, navigate]);

  // Step 1: Basics submit handler
  const onSubmitCreateJob = React.useCallback(
    async (values: BasicsCreateJobFormData): Promise<void> => {
      const tryCreateJob = async (): Promise<void> => {
        const response = await createJob({
          values,
          createJobContext: CreateNewJobRequestCreateJobContextEnum.FreeSourcingExtension,
        }).unwrap();
        if (response.success) {
          setJobId(response.jobId);
          onNext();
        } else {
          // TODO: Handle error
          showErrorToast(`Failed to create job: ${response.failureReason}`);
        }
      };

      await tryCreateJob();
    },
    [createJob, onNext]
  );

  //----------------------------------------
  // Effects

  // Set default step if none is provided or if trying to advance without a job id
  React.useEffect(() => {
    const currentStep = KEYED_BY_PATH_EXTENSION_JOB_CREATION_CONFIG[step ?? ""];
    if (!currentStep) {
      navigate(getExtensionUrlPath(ExtensionJobCreationStep.BasicsStep), {
        replace: true,
      });
    }

    if (currentStep?.index > EXTENSION_JOB_CREATION_CONFIG[ExtensionJobCreationStep.BasicsStep].index) {
      if (!jobId) {
        navigate(getExtensionUrlPath(ExtensionJobCreationStep.BasicsStep), {
          replace: true,
        });
      }
    }
  }, [step, navigate, jobId]);

  //----------------------------------------
  // Render

  if (!step) {
    return null;
  }

  return (
    <ExtensionCreateJobContext.Provider
      value={{
        step,
        jobId,
        onNext,
        creatingJob,
        createJobError: createJobResponse?.failureReason,
        onSubmitCreateJob,
      }}
    >
      {children}
    </ExtensionCreateJobContext.Provider>
  );
};

export const useExtensionCreateJobContext = (): ExtensionCreateJobState =>
  useContext<ExtensionCreateJobState>(ExtensionCreateJobContext);
