import AdjustIcon from "@mui/icons-material/Adjust";
import SubjectIcon from "@mui/icons-material/Subject";
import { MenuItem, Select, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import SelectOptionsList from "components/dover/FeedbackTemplates/SelectOptionsList";
import { FeedbackTemplateQuestion } from "components/dover/FeedbackTemplates/types";
import { Card } from "components/library/Card";
import { BodySmall } from "components/library/typography";
import { RequiredAsterisk } from "components/RequiredAsterisk";
import { FeedbackTemplateQuestionComponent, SelectFieldOption } from "services/openapi";
import { colors } from "styles/theme";

const generateNewQuestionName = (): string => {
  return uuidv4();
};

export const feedbackTemplateQuestionTypeIconAndLabel = {
  [FeedbackTemplateQuestionComponent.TEXT_FIELD]: {
    icon: <SubjectIcon htmlColor={colors.grayscale.gray400} />,
    label: "Free text",
  },
  [FeedbackTemplateQuestionComponent.SELECT]: {
    icon: <AdjustIcon htmlColor={colors.grayscale.gray400} />,
    label: "Select",
  },
};

const QuestionTypeLabel = ({ value }: { value: FeedbackTemplateQuestionComponent }): React.ReactElement => {
  const questionTypeIconAndLabel = feedbackTemplateQuestionTypeIconAndLabel[value];
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {questionTypeIconAndLabel.icon}
      <BodySmall color={colors.grayscale.gray700}>{questionTypeIconAndLabel.label}</BodySmall>
    </Stack>
  );
};

export const QuestionEditor = ({
  existingQuestion,
  onChange,
}: {
  existingQuestion?: FeedbackTemplateQuestion;
  onChange: (question: FeedbackTemplateQuestion) => void;
}): React.ReactElement => {
  const [question, setQuestion] = useState(existingQuestion?.label || "");
  const [description, setDescription] = useState(existingQuestion?.helperText || "");
  const [questionType, setQuestionType] = useState<FeedbackTemplateQuestionComponent>(
    existingQuestion?.component || FeedbackTemplateQuestionComponent.TEXT_FIELD
  );
  const [selectOptions, setSelectOptions] = useState<SelectFieldOption[]>(
    existingQuestion?.component === FeedbackTemplateQuestionComponent.SELECT
      ? existingQuestion.options
      : [{ label: "", value: "" }]
  );

  // Helper function to notify parent of changes
  const notifyChange = (updates: Partial<FeedbackTemplateQuestion> = {}): void => {
    const questionData = {
      label: question,
      name: existingQuestion?.name || generateNewQuestionName(),
      helperText: description,
      component: questionType,
      options: selectOptions,
      ...updates,
    };
    onChange(questionData);
  };

  return (
    <Card>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <BodySmall weight="600">
            Interview Question
            <RequiredAsterisk />
          </BodySmall>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack flexGrow={3}>
              <TextField
                size="small"
                multiline
                fullWidth
                required
                value={question}
                onChange={(event): void => {
                  setQuestion(event.target.value);
                  notifyChange({ label: event.target.value });
                }}
              />
            </Stack>
            <Stack flexGrow={1}>
              <Select
                size="small"
                value={questionType}
                onChange={(e): void => {
                  const newType = e.target.value as FeedbackTemplateQuestionComponent;
                  setQuestionType(newType);
                  notifyChange({ component: newType });
                }}
                renderValue={(value): React.ReactNode => <QuestionTypeLabel value={value} />}
              >
                {Object.values(FeedbackTemplateQuestionComponent).map(value => (
                  <MenuItem key={value} value={value}>
                    <QuestionTypeLabel value={value} />
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <BodySmall weight="600">Help text for interviewer (optional)</BodySmall>
          <TextField
            size="small"
            multiline
            fullWidth
            placeholder={"Add in any additional info here. There is a 1000 character limit."}
            value={description}
            onChange={(event): void => {
              setDescription(event.target.value);
              notifyChange({ helperText: event.target.value });
            }}
            inputProps={{ maxLength: 1000 }}
          />
        </Stack>
        {questionType === FeedbackTemplateQuestionComponent.SELECT && (
          <SelectOptionsList
            options={selectOptions}
            setOptions={(newOptions: SelectFieldOption[]): void => {
              setSelectOptions(newOptions);
              notifyChange({ options: newOptions });
            }}
          />
        )}
      </Stack>
    </Card>
  );
};
