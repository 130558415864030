import { useCallback, useEffect, useState } from "react";

import { CreateInterviewerFuncArgs } from "components/NextInterviewer/InterviewerCreateModal";
import { InterviewerOption } from "components/NextInterviewer/useInterviewerOptions";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useCreateInterviewerMutation } from "services/doverapi/endpoints/interviewer";
import { showErrorToast } from "utils/showToast";

export const useNewInterviewer = ({
  setInterviewer,
  selectedInterviewer,
}: {
  setInterviewer?: (interviewer: InterviewerOption & { preferencesComplete: boolean }) => void;
  selectedInterviewer?: InterviewerOption;
}): {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  createInterviewerFn: (values: CreateInterviewerFuncArgs) => void;
} => {
  const [createInterviewer] = useCreateInterviewerMutation();
  const clientId = useGetClientId();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (selectedInterviewer?.createInterviewer) {
      setShowModal(true);
    }
  }, [selectedInterviewer?.createInterviewer]);

  const createInterviewerFnCallback = useCallback(
    (values: CreateInterviewerFuncArgs) => {
      const createInterviewerFn = async (values: CreateInterviewerFuncArgs): Promise<void> => {
        if (!clientId) {
          return;
        }
        const { firstName, lastName, email, phoneNumber } = values;
        try {
          const result = await createInterviewer({
            firstName,
            lastName,
            email,
            phoneNumber,
            client: clientId,
          }).unwrap();
          setInterviewer?.({
            label: `${result.fullName} <${result.email}>`,
            hasSchedulingLinkOverride: result.hasSchedulingLinkOverride,
            isDefaultInterviewer: true,
            fullName: result.fullName,
            email: result.email,
            preferencesComplete: false,
          });
        } catch (e) {
          showErrorToast("Failed to create interviewer");
        }

        setShowModal(false);
      };
      createInterviewerFn(values);
    },
    [clientId, createInterviewer, setInterviewer]
  );

  return {
    showModal,
    setShowModal,
    createInterviewerFn: createInterviewerFnCallback,
  };
};
