import { Button } from "@doverhq/dover-ui";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { appConfig } from "config/appConfig";

/** Button that opens gmail auth page on click */
export const AuthButton = (): React.ReactElement => {
  const click = (): void => {
    // kind of odd to redirect like this maybe, but if we just navigate() there
    // itll keep the scheduling modal open (if thats where we're rendering this from)
    // so for simplicity and to avoid passing a closeModal prop through 4 things, lets just
    // redirect this way.
    const emailConnectionPath = APP_ROUTE_PATHS.userSettings.emailConnection();
    window.location.href = `${appConfig.appUrl}${emailConnectionPath}`;
  };

  return (
    <Button variant="primaryFilled" onPress={click}>
      Auth email
    </Button>
  );
};
