/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SpeechSegment
 */
export interface SpeechSegment {
    /**
     * 
     * @type {string}
     * @memberof SpeechSegment
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof SpeechSegment
     */
    speakerName: string;
    /**
     * 
     * @type {number}
     * @memberof SpeechSegment
     */
    startTimestamp: number;
    /**
     * 
     * @type {number}
     * @memberof SpeechSegment
     */
    endTimestamp: number;
}

export function SpeechSegmentFromJSON(json: any): SpeechSegment {
    return SpeechSegmentFromJSONTyped(json, false);
}

export function SpeechSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpeechSegment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'speakerName': json['speaker_name'],
        'startTimestamp': json['start_timestamp'],
        'endTimestamp': json['end_timestamp'],
    };
}

export function SpeechSegmentToJSON(value?: SpeechSegment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'speaker_name': value.speakerName,
        'start_timestamp': value.startTimestamp,
        'end_timestamp': value.endTimestamp,
    };
}


