import atsCandidateInfoStep from "sections/addcandidate/components/steps/AtsCandidateStep";
import candidateInfoStep from "sections/addcandidate/components/steps/CandidateInfoStep";
import initialOutreachReviewStep from "sections/addcandidate/components/steps/InitialOutreachReviewStep";
import jobSelectionStep from "sections/addcandidate/components/steps/JobSelectionStep";
import selectCampaignStep from "sections/addcandidate/components/steps/SelectCampaignStep";
import stageSelectStep from "sections/addcandidate/components/steps/StageSelectStep";
import { AddCandidateStep, FlowType, FlowTypes } from "sections/addcandidate/types";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { ClientOnboardingAtsTypeEnum } from "services/openapi";

// Flow Definitions
//
// Map: FlowType -> AddCandidateStep[]
//
// Order matters here, as the order of the steps will be the order they are
// displayed in the stepper

export function useGetSteps(flowType: FlowType): AddCandidateStep[] {
  const { data: client } = useGetClientOnboardingQuery();

  if (flowType === FlowTypes.INITIAL_OUTREACH) {
    return [jobSelectionStep, candidateInfoStep, selectCampaignStep, initialOutreachReviewStep];
  }

  const allowAddingAtsCandidates =
    client?.atsType &&
    [
      ClientOnboardingAtsTypeEnum.Greenhouse,
      ClientOnboardingAtsTypeEnum.Lever,
      ClientOnboardingAtsTypeEnum.Ashby,
    ].includes(client.atsType);

  if (!allowAddingAtsCandidates) {
    return [jobSelectionStep, candidateInfoStep, stageSelectStep];
  }

  return [atsCandidateInfoStep, jobSelectionStep, candidateInfoStep, stageSelectStep];
}
