import { Extension } from "@tiptap/react";

interface ModenterHandlerOptions {
  callback: () => void | undefined;
}

export const ModEnterHandler = Extension.create<ModenterHandlerOptions>({
  name: "customModEnterHandler",
  priority: 1000,

  addKeyboardShortcuts() {
    return {
      "Mod-Enter": (): boolean => {
        const { callback } = this.options;
        callback?.();
        return true;
      },
    };
  },
});
