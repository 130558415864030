export const HEADER_HEIGHT = "56px";
export const GOOGLE_CHROME_EXTENSION_URL =
  "https://chrome.google.com/webstore/detail/dover/ekmeakdlmfdpidkmifdcgaekafojmfch/related?hl=en";

export const HELP_DOC_HREFS = {
  ADD_CANDIDATE: "https://help.dover.com/en/articles/6308860-adding-candidates-to-dover",
  CSV_FORMAT: "https://help.dover.com/en/articles/6637563-how-to-upload-a-csv-for-adding-candidates-to-outreach",
};

export const TWENTY_MEGA_BYTES = 20 * 1024 * 1024;
