import ClearIcon from "@mui/icons-material/Clear";
import GradingRounded from "@mui/icons-material/GradingRounded";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import React, { useCallback } from "react";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

const truncateString = (str: string, num: number, suffix?: string): string => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + `...${suffix ?? ""}`;
};

export const FilePreview = ({
  onDelete,
  fileName,
  truncateNameLength = 35,
  previewPrefix = "Resume",
}: {
  onDelete?: VoidFunction;
  fileName: string;
  truncateNameLength?: number;
  previewPrefix?: string;
}): React.ReactElement => {
  const fileSuffix = fileName.split(".").pop();
  const truncatedFileName = truncateString(fileName, truncateNameLength, fileSuffix);
  const isTruncated = truncatedFileName !== fileName;

  const renderFileName = useCallback(
    (): React.ReactElement => (
      <BodySmall>
        {previewPrefix && `${previewPrefix}:`} <b>{truncatedFileName}</b>
      </BodySmall>
    ),
    [truncatedFileName, previewPrefix]
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: "100%" }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <GradingRounded sx={{ color: colors.primary.base }} />
        {isTruncated ? <Tooltip title={fileName}>{renderFileName()}</Tooltip> : renderFileName()}
      </Stack>

      <Tooltip title="Remove">
        <IconButton
          size="small"
          onClick={(e): void => {
            e.stopPropagation();
            if (onDelete) {
              onDelete();
            }
          }}
        >
          <ClearIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
