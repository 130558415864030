/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateJobCandidateSourceSetting
 */
export interface CreateJobCandidateSourceSetting {
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    job: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    candidateSource: string;
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    desiredState: CreateJobCandidateSourceSettingDesiredStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    externalJobIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    externalJobOrigin?: CreateJobCandidateSourceSettingExternalJobOriginEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateJobCandidateSourceSetting
     */
    atsInboundRejectionEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    deactivationReason?: CreateJobCandidateSourceSettingDeactivationReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    deactivationReasonNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    additionalInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateJobCandidateSourceSetting
     */
    pricingTierId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateJobCandidateSourceSetting
     */
    shouldAutoRenew?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateJobCandidateSourceSetting
     */
    forceSetState?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreateJobCandidateSourceSettingDesiredStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}/**
* @export
* @enum {string}
*/
export enum CreateJobCandidateSourceSettingExternalJobOriginEnum {
    Dover = 'DOVER',
    Client = 'CLIENT'
}/**
* @export
* @enum {string}
*/
export enum CreateJobCandidateSourceSettingDeactivationReasonEnum {
    NotEnoughApplicants = 'NOT_ENOUGH_APPLICANTS',
    TooExpensive = 'TOO_EXPENSIVE',
    LowQualityOfApplicants = 'LOW_QUALITY_OF_APPLICANTS',
    AlreadyMadeHire = 'ALREADY_MADE_HIRE',
    Other = 'OTHER'
}

export function CreateJobCandidateSourceSettingFromJSON(json: any): CreateJobCandidateSourceSetting {
    return CreateJobCandidateSourceSettingFromJSONTyped(json, false);
}

export function CreateJobCandidateSourceSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateJobCandidateSourceSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'job': json['job'],
        'candidateSource': json['candidate_source'],
        'desiredState': json['desired_state'],
        'externalJobIdentifier': !exists(json, 'external_job_identifier') ? undefined : json['external_job_identifier'],
        'externalJobOrigin': !exists(json, 'external_job_origin') ? undefined : json['external_job_origin'],
        'atsInboundRejectionEnabled': !exists(json, 'ats_inbound_rejection_enabled') ? undefined : json['ats_inbound_rejection_enabled'],
        'deactivationReason': !exists(json, 'deactivation_reason') ? undefined : json['deactivation_reason'],
        'deactivationReasonNotes': !exists(json, 'deactivation_reason_notes') ? undefined : json['deactivation_reason_notes'],
        'additionalInfo': !exists(json, 'additional_info') ? undefined : json['additional_info'],
        'pricingTierId': !exists(json, 'pricing_tier_id') ? undefined : json['pricing_tier_id'],
        'shouldAutoRenew': !exists(json, 'should_auto_renew') ? undefined : json['should_auto_renew'],
        'forceSetState': !exists(json, 'force_set_state') ? undefined : json['force_set_state'],
    };
}

export function CreateJobCandidateSourceSettingToJSON(value?: CreateJobCandidateSourceSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': value.job,
        'candidate_source': value.candidateSource,
        'desired_state': value.desiredState,
        'external_job_identifier': value.externalJobIdentifier,
        'external_job_origin': value.externalJobOrigin,
        'ats_inbound_rejection_enabled': value.atsInboundRejectionEnabled,
        'deactivation_reason': value.deactivationReason,
        'deactivation_reason_notes': value.deactivationReasonNotes,
        'additional_info': value.additionalInfo,
        'pricing_tier_id': value.pricingTierId,
        'should_auto_renew': value.shouldAutoRenew,
        'force_set_state': value.forceSetState,
    };
}


