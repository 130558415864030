/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseEmailTemplate
 */
export interface BaseEmailTemplate {
    /**
     * 
     * @type {string}
     * @memberof BaseEmailTemplate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BaseEmailTemplate
     */
    requiredEmailAlias: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseEmailTemplate
     */
    requiredInterviewer: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseEmailTemplate
     */
    ccEmails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseEmailTemplate
     */
    bccEmails: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BaseEmailTemplate
     */
    body: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseEmailTemplate
     */
    hasSameInterviewerAndEmailSender: boolean | null;
}

export function BaseEmailTemplateFromJSON(json: any): BaseEmailTemplate {
    return BaseEmailTemplateFromJSONTyped(json, false);
}

export function BaseEmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseEmailTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'requiredEmailAlias': json['required_email_alias'],
        'requiredInterviewer': json['required_interviewer'],
        'ccEmails': json['cc_emails'],
        'bccEmails': json['bcc_emails'],
        'body': json['body'],
        'hasSameInterviewerAndEmailSender': json['has_same_interviewer_and_email_sender'],
    };
}

export function BaseEmailTemplateToJSON(value?: BaseEmailTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'required_email_alias': value.requiredEmailAlias,
        'required_interviewer': value.requiredInterviewer,
        'cc_emails': value.ccEmails,
        'bcc_emails': value.bccEmails,
        'body': value.body,
        'has_same_interviewer_and_email_sender': value.hasSameInterviewerAndEmailSender,
    };
}


