/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobInterviewStageStats,
    JobInterviewStageStatsFromJSON,
    JobInterviewStageStatsFromJSONTyped,
    JobInterviewStageStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobFunnelStats
 */
export interface JobFunnelStats {
    /**
     * 
     * @type {Array<JobInterviewStageStats>}
     * @memberof JobFunnelStats
     */
    statsByStage: Array<JobInterviewStageStats>;
}

export function JobFunnelStatsFromJSON(json: any): JobFunnelStats {
    return JobFunnelStatsFromJSONTyped(json, false);
}

export function JobFunnelStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFunnelStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statsByStage': ((json['stats_by_stage'] as Array<any>).map(JobInterviewStageStatsFromJSON)),
    };
}

export function JobFunnelStatsToJSON(value?: JobFunnelStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stats_by_stage': ((value.statsByStage as Array<any>).map(JobInterviewStageStatsToJSON)),
    };
}


