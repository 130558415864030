import { Box, Stack, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ReactComponent as WandIcon } from "assets/icons/green-wand.svg";
import { Button, ButtonVariant } from "components/library/Button";
import Drawer from "components/library/Drawer";
import { Heading, Body, BodySmall } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import {
  useAddInterviewQuestionsToRubricMutation,
  useGenerateInterviewQuestionsWithAIMutation,
} from "services/doverapi/endpoints/candidateInterview";
import { colors } from "styles/theme";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";
import {
  SUGGESTED_QUESTIONS_DRAWER_NAME,
  FAILED_TO_ADD_QUESTIONS_ERROR_TEXT,
  FAILED_TO_GENERATE_SUGGESTIONS_ERROR_TEXT,
} from "views/interview/common/components/AISuggestedQuestions/constants";

//-----------------------------------------------------------------------------
// Types & Context

interface QuestionSuggestion {
  id: string;
  question: string;
  justification: string;
  potentialFlag: boolean;
}

interface DrawerProps {
  interviewRubricResponseId: string;
}

// Context Provider to handle state for the Drawer

type AISuggestedQuestionsState = {
  questions: QuestionSuggestion[];
  selectedQuestionIDs: string[];
  // Action state
  isGeneratingQuestions: boolean;
  isAddingQuestions: boolean;
  errorText?: string;
  // Actions
  onCancel: () => void;
  onGenerateQuestions: () => void;
  onAddSelectedQuestions: () => void;
  onToggleSelected: (question: QuestionSuggestion) => void;
};

const initialState: AISuggestedQuestionsState = {
  questions: [],
  selectedQuestionIDs: [],
  isGeneratingQuestions: true,
  isAddingQuestions: false,
  onCancel: () => {},
  onGenerateQuestions: () => {},
  onAddSelectedQuestions: () => {},
  onToggleSelected: () => {},
};

const AISuggestedQuestionsContext = createContext<AISuggestedQuestionsState>(initialState);
const useAISuggestedQuestionsContext = (): AISuggestedQuestionsState =>
  useContext<AISuggestedQuestionsState>(AISuggestedQuestionsContext);

//-----------------------------------------------------------------------------
// Drawer components

const DrawerHeader = (): React.ReactElement => (
  <>
    <Heading>AI Interview Question Suggestions</Heading>
    <Stack
      sx={{ backgroundColor: colors.decorative.mintGreen, borderRadius: "3px", padding: "10px" }}
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <WandIcon className="svg-fill" color={colors.success.base} />
      <Body color={colors.success.base}>
        {
          "Dover's AI generated the questions below based on this candidate's specific background. You can add them to your interview rubric below."
        }
      </Body>
    </Stack>
  </>
);

const QuestionSuggestionListItem = ({ question }: { question: QuestionSuggestion }): React.ReactElement => {
  const { selectedQuestionIDs, onToggleSelected } = useAISuggestedQuestionsContext();
  const labelId = `checkbox-list-label-${question.id}`;
  const isSelected = useMemo(() => selectedQuestionIDs.find(qId => qId === question.id) !== undefined, [
    selectedQuestionIDs,
    question.id,
  ]);

  return (
    <ListItem
      key={question.id}
      secondaryAction={
        question.potentialFlag ? (
          <Tooltip title={"Potentially an important area to dig into"}>
            <Body>⚠</Body>
          </Tooltip>
        ) : (
          undefined
        )
      }
      disablePadding
    >
      <ListItemButton role={undefined} onClick={(): void => onToggleSelected(question)} dense>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={isSelected}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={
            <Body>
              <strong>Question: </strong>
              {question.question}
            </Body>
          }
          secondary={
            <React.Fragment>
              <BodySmall>
                <strong>Why ask?</strong> {question.justification}
              </BodySmall>
            </React.Fragment>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

const QuestionSuggestionList = (): React.ReactElement => {
  const { questions } = useAISuggestedQuestionsContext();

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {questions.map(question => (
        <QuestionSuggestionListItem question={question} key={question.id} />
      ))}
    </List>
  );
};

const DrawerBody = (): React.ReactElement => {
  const { isGeneratingQuestions, isAddingQuestions, errorText } = useAISuggestedQuestionsContext();
  const isError = !!errorText;
  const isLoading = isGeneratingQuestions || isAddingQuestions;
  const loadingText = useMemo((): string => {
    if (isGeneratingQuestions) {
      return "Generating questions...";
    }
    if (isAddingQuestions) {
      return "Adding questions...";
    }
    return "";
  }, [isGeneratingQuestions, isAddingQuestions]);

  return (
    <DoverLoadingOverlay text={loadingText} active={isLoading} overlay={true}>
      <Stack>{isError ? <Body>{errorText}</Body> : <QuestionSuggestionList />}</Stack>
    </DoverLoadingOverlay>
  );
};

const AddSummaryBanner = (): React.ReactElement | null => {
  const { isGeneratingQuestions, selectedQuestionIDs } = useAISuggestedQuestionsContext();
  const questionText = useMemo((): string => {
    if (selectedQuestionIDs.length === 0) {
      return ""; // we won't display this
    }
    if (selectedQuestionIDs.length === 1) {
      return "Add the selected question to the rubric";
    }

    return `Add the ${selectedQuestionIDs.length} selected questions to the rubric`;
  }, [selectedQuestionIDs]);

  // No reason to show a warning, add selected is disabled
  if (isGeneratingQuestions || selectedQuestionIDs.length === 0) {
    return null;
  }

  return (
    <Box sx={{ backgroundColor: colors.warning.light, borderRadius: "3px", padding: "10px" }} display="inline-block">
      <BodySmall>{questionText}</BodySmall>
    </Box>
  );
};

const ActionsButtons = (): React.ReactElement => {
  const {
    onCancel,
    onGenerateQuestions,
    onAddSelectedQuestions,
    selectedQuestionIDs,
    isGeneratingQuestions,
    isAddingQuestions,
  } = useAISuggestedQuestionsContext();

  const generateQuestionsButtonDisabled = isAddingQuestions || isGeneratingQuestions;
  const addSelectedQuestionsButtonDisabled =
    isAddingQuestions || isGeneratingQuestions || selectedQuestionIDs.length === 0;

  return (
    <Stack direction="row" spacing={1} width="100%" justifyContent="flex-end">
      <Button variant={ButtonVariant.Ghost} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant={ButtonVariant.Secondary}
        onClick={onGenerateQuestions}
        disabled={generateQuestionsButtonDisabled}
      >
        Regenerate suggestions
      </Button>
      <Button
        variant={ButtonVariant.Primary}
        onClick={onAddSelectedQuestions}
        disabled={addSelectedQuestionsButtonDisabled}
      >
        Add selected
      </Button>
    </Stack>
  );
};

const DrawerFooter = (): React.ReactElement => (
  <Stack alignItems="flex-end" width="100%" spacing={1}>
    <AddSummaryBanner />
    <ActionsButtons />
  </Stack>
);

const DrawerContent = (): React.ReactElement => {
  return (
    <Stack spacing={2} p={2} sx={{ height: "100%" }} direction="column" justifyContent="space-between">
      <Stack spacing={2} sx={{ height: "100%", overflowY: "auto" }} direction="column" justifyContent="flex-start">
        <DrawerHeader />
        <DrawerBody />
      </Stack>

      <DrawerFooter />
    </Stack>
  );
};

//-----------------------------------------------------------------------------
// Provider, for managing state & coordinating actions

const AISuggestedQuestionsDrawerProvider: React.FC<DrawerProps> = ({ children, interviewRubricResponseId }) => {
  //----------------------------------------
  // State

  // Whether the drawer is open from the query param.
  const [drawerOpenQueryParamState, setDrawerOpen] = useQueryParam(SUGGESTED_QUESTIONS_DRAWER_NAME, BooleanParam);
  const drawerOpen = !!drawerOpenQueryParamState;

  // Internal state
  const [errorText, setErrorText] = React.useState<string | undefined>();
  const [questions, setQuestions] = React.useState<QuestionSuggestion[]>([]);
  const [selectedQuestionIDs, setSelectedQuestionIDs] = React.useState<string[]>([]);
  const [isGeneratingQuestions, setIsGeneratingQuestions] = React.useState<boolean>(true);
  const [isAddingQuestions, setIsAddingQuestions] = React.useState<boolean>(false);

  //----------------------------------------
  // Actions

  const [generateFn] = useGenerateInterviewQuestionsWithAIMutation();
  const [addQuestionsFn] = useAddInterviewQuestionsToRubricMutation();

  const closeDrawer = (): void => {
    setSelectedQuestionIDs([]);
    setDrawerOpen(false);
  };
  const onCancel = closeDrawer;
  const onGenerateQuestions = useCallback(async (): Promise<void> => {
    setIsGeneratingQuestions(true);
    setErrorText(undefined);
    setSelectedQuestionIDs([]);
    try {
      const resp = await generateFn({ interviewRubricResponseId }).unwrap();
      setQuestions(resp.questions);
    } catch (e) {
      console.error("Error generating question suggestions", e);
      setErrorText(FAILED_TO_GENERATE_SUGGESTIONS_ERROR_TEXT);
    } finally {
      setIsGeneratingQuestions(false);
    }
  }, [interviewRubricResponseId, generateFn]);
  const onAddSelectedQuestions = async (): Promise<void> => {
    setIsAddingQuestions(true);
    setErrorText(undefined);
    try {
      showPendingToast("Adding questions...");
      const selectedQuestions = questions.filter(question => selectedQuestionIDs.includes(question.id));
      await addQuestionsFn({ interviewRubricResponseId, questions: selectedQuestions.map(sq => sq.question) }).unwrap();

      showSuccessToast("Added 🙂");
      setQuestions([]);
      closeDrawer();
    } catch (e) {
      showErrorToast("Something went wrong 😬");
      console.error("Error adding questions to rubric response", e);
      setSelectedQuestionIDs([]);
      setQuestions([]);
      setErrorText(FAILED_TO_ADD_QUESTIONS_ERROR_TEXT);
    } finally {
      setIsAddingQuestions(false);
    }
  };
  const onToggleSelected = (question: QuestionSuggestion): void => {
    setSelectedQuestionIDs(prev => {
      if (prev.includes(question.id)) {
        return prev.filter((id: string): boolean => id !== question.id);
      }
      return [...prev, question.id];
    });
  };

  //----------------------------------------
  // Effects

  // On open, generate questions
  useEffect(() => {
    if (drawerOpen) {
      onGenerateQuestions();
    }
  }, [drawerOpen, onGenerateQuestions]);

  //----------------------------------------
  // Render

  return (
    <AISuggestedQuestionsContext.Provider
      value={{
        questions,
        selectedQuestionIDs,
        isGeneratingQuestions,
        isAddingQuestions,
        errorText,
        onCancel,
        onGenerateQuestions,
        onAddSelectedQuestions,
        onToggleSelected,
      }}
    >
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{ position: "relative", overflow: "auto" }}
        shouldToggleIntercom
      >
        {children}
      </Drawer>
    </AISuggestedQuestionsContext.Provider>
  );
};

//-----------------------------------------------------------------------------
// Provider wrapper, AI Question Suggestion Drawer

const AISuggestedQuestionsDrawer = (props: DrawerProps): React.ReactElement => {
  return (
    <AISuggestedQuestionsDrawerProvider {...props}>
      <DrawerContent />
    </AISuggestedQuestionsDrawerProvider>
  );
};

export default AISuggestedQuestionsDrawer;
