import { Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { ProfileListItem } from "App/components/Nav/SideListItem";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as CloseNavIcon } from "assets/icons/close-nav.svg";
import DoverLogo from "components/icons/DoverLogo";
import { colors } from "styles/theme";

interface SideNavHeaderProps {
  toggleDrawerOpen: () => void;
}

/**
 * SideNavHeader
 * Includes UI for logo and to allow for menu collapse
 */
export const SideNavHeader = ({ toggleDrawerOpen }: SideNavHeaderProps): React.ReactElement => {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mb="5px">
        <Link to={APP_ROUTE_PATHS.home()}>
          <DoverLogo />
        </Link>

        <Stack direction="row" spacing={1} alignItems={"center"}>
          <Stack direction="row" justifyContent={"flex-end"}>
            <ProfileListItem />
          </Stack>
          <CloseNavIcon
            width={"20px"}
            height={"20px"}
            onClick={toggleDrawerOpen}
            className="svg-pointer svg-color"
            color={colors.grayscale.gray700}
          />
        </Stack>
      </Stack>
    </>
  );
};
