/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobSetupTitlePattern
 */
export interface JobSetupTitlePattern {
    /**
     * 
     * @type {number}
     * @memberof JobSetupTitlePattern
     */
    searchTemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof JobSetupTitlePattern
     */
    titlePattern: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetupTitlePattern
     */
    jobCategoryDisplay: string;
}

export function JobSetupTitlePatternFromJSON(json: any): JobSetupTitlePattern {
    return JobSetupTitlePatternFromJSONTyped(json, false);
}

export function JobSetupTitlePatternFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobSetupTitlePattern {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTemplateId': json['search_template_id'],
        'titlePattern': json['title_pattern'],
        'jobCategoryDisplay': json['job_category_display'],
    };
}

export function JobSetupTitlePatternToJSON(value?: JobSetupTitlePattern | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_template_id': value.searchTemplateId,
        'title_pattern': value.titlePattern,
        'job_category_display': value.jobCategoryDisplay,
    };
}


