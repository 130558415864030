import { isBefore } from "date-fns";

import { DashboardJob } from "services/openapi";

export function sortJobsByTitle(jobs: DashboardJob[]): DashboardJob[] {
  return jobs.sort((a, b) => {
    if (!a.title || !b.title) {
      return 0;
    }
    if (a.title < b.title) {
      return -1;
    } else if (a.title > b.title) {
      return 1;
    }
    return 0;
  });
}

/**
 * Given a list of jobs, sort them so that jobs that are snoozed are at the end.
 */
export function sortJobsWithSnoozedLast(jobs: DashboardJob[]): DashboardJob[] {
  const today = new Date();
  return jobs.sort((a, b) => {
    const stillSnoozedA = !!a.snoozedUntil && isBefore(today, a.snoozedUntil);
    const stillSnoozedB = !!b.snoozedUntil && isBefore(today, b.snoozedUntil);
    if (stillSnoozedA && stillSnoozedB) {
      return 1;
    } else if (!stillSnoozedA && stillSnoozedB) {
      return -1;
    }
    return 0;
  });
}

/**
 * Given a list of jobs, sort them so that jobs that are inactive are at the end.
 */
export function sortJobsWithInactiveLast(jobs: DashboardJob[]): DashboardJob[] {
  return jobs.sort((a, b) => {
    if (!a.active && b.active) {
      return 1;
    } else if (a.active && !b.active) {
      return -1;
    }
    return 0;
  });
}

/**
 * Sort jobs using a chain of sorters.
 * The sorters are applied in the order they are passed in.
 * For example `composeSortJobs(sortJobsWithSnoozedLast, sortJobsWithInactiveLast)` will first sort jobs with snoozed last, then sort jobs with inactive last.
 * @param sorters A list of functions that take a list of jobs and return a list of jobs.
 */
export function composeSortJobs(
  ...sorters: ((jobs: DashboardJob[]) => DashboardJob[])[]
): (jobs: DashboardJob[]) => DashboardJob[] {
  return (jobs: DashboardJob[]): DashboardJob[] => {
    return sorters.reduce((acc, sorter) => sorter(acc), jobs);
  };
}
