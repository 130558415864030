/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueryBuilderRule
 */
export interface QueryBuilderRule {
    /**
     * 
     * @type {string}
     * @memberof QueryBuilderRule
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof QueryBuilderRule
     */
    operator: string;
    /**
     * 
     * @type {object}
     * @memberof QueryBuilderRule
     */
    value: object;
}

export function QueryBuilderRuleFromJSON(json: any): QueryBuilderRule {
    return QueryBuilderRuleFromJSONTyped(json, false);
}

export function QueryBuilderRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryBuilderRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'operator': json['operator'],
        'value': json['value'],
    };
}

export function QueryBuilderRuleToJSON(value?: QueryBuilderRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'operator': value.operator,
        'value': value.value,
    };
}


