/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanySetupStep,
    CompanySetupStepFromJSON,
    CompanySetupStepFromJSONTyped,
    CompanySetupStepToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanySetupSteps
 */
export interface CompanySetupSteps {
    /**
     * 
     * @type {string}
     * @memberof CompanySetupSteps
     */
    id: string;
    /**
     * 
     * @type {Array<CompanySetupStep>}
     * @memberof CompanySetupSteps
     */
    setupSteps: Array<CompanySetupStep>;
    /**
     * 
     * @type {string}
     * @memberof CompanySetupSteps
     */
    setupSummaryState: CompanySetupStepsSetupSummaryStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum CompanySetupStepsSetupSummaryStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function CompanySetupStepsFromJSON(json: any): CompanySetupSteps {
    return CompanySetupStepsFromJSONTyped(json, false);
}

export function CompanySetupStepsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySetupSteps {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'setupSteps': ((json['setup_steps'] as Array<any>).map(CompanySetupStepFromJSON)),
        'setupSummaryState': json['setup_summary_state'],
    };
}

export function CompanySetupStepsToJSON(value?: CompanySetupSteps | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'setup_steps': ((value.setupSteps as Array<any>).map(CompanySetupStepToJSON)),
        'setup_summary_state': value.setupSummaryState,
    };
}


