import { z } from "zod";

const rawEditorStateSchema = z.object({
  subjectHtml: z.string().optional(),
  bodyHtml: z.string().optional(),
});

const campaignEditorsSchema = z.array(
  z.object({
    rawEditorState: rawEditorStateSchema,
    id: z.string(),
    enabled: z.boolean(),
    minMessageDelay: z.number(),
  })
);

export const campaignsFormSchema = z.object({
  name: z.string().optional(),
  campaignEditors: campaignEditorsSchema,
  isDirty: z.boolean(),
  active: z.boolean().optional(),
});

export type CampaignEditorsType = z.infer<typeof campaignEditorsSchema>;
export type CampaignsFormSchemaType = z.infer<typeof campaignsFormSchema>;
