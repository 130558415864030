/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserUploadedFileProUser,
    UserUploadedFileProUserFromJSON,
    UserUploadedFileProUserFromJSONTyped,
    UserUploadedFileProUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientUserFileStats
 */
export interface ClientUserFileStats {
    /**
     * 
     * @type {UserUploadedFileProUser}
     * @memberof ClientUserFileStats
     */
    user?: UserUploadedFileProUser;
    /**
     * 
     * @type {string}
     * @memberof ClientUserFileStats
     */
    readonly mostRecentlyUploadedFileName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClientUserFileStats
     */
    readonly mostRecentUploadTime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ClientUserFileStats
     */
    readonly mostRecentProcessingState?: string | null;
}

export function ClientUserFileStatsFromJSON(json: any): ClientUserFileStats {
    return ClientUserFileStatsFromJSONTyped(json, false);
}

export function ClientUserFileStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientUserFileStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserUploadedFileProUserFromJSON(json['user']),
        'mostRecentlyUploadedFileName': !exists(json, 'most_recently_uploaded_file_name') ? undefined : json['most_recently_uploaded_file_name'],
        'mostRecentUploadTime': !exists(json, 'most_recent_upload_time') ? undefined : (json['most_recent_upload_time'] === null ? null : new Date(json['most_recent_upload_time'])),
        'mostRecentProcessingState': !exists(json, 'most_recent_processing_state') ? undefined : json['most_recent_processing_state'],
    };
}

export function ClientUserFileStatsToJSON(value?: ClientUserFileStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserUploadedFileProUserToJSON(value.user),
    };
}


