import { Container, Stack } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";

export const SchedulingContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Container maxWidth="md">
      <Stack>{children}</Stack>
    </Container>
  );
};
