import React from "react";

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const TextWithNewLinesAndLinks = ({ text }: { text: string }): React.ReactElement => (
  <>
    {text.split("\n").map(
      (str: string): React.ReactElement => (
        <p>{str.split(" ").map(part => (URL_REGEX.test(part) ? <a href={part}>{part} </a> : part + " "))}</p>
      )
    )}
  </>
);
