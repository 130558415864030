import { Button, Icon } from "@doverhq/dover-ui";
import { ReactComponent as AlertTriangleIcon } from "@doverhq/dover-ui/icons/alert-triangle.svg";
import { ReactComponent as ArrowRightIcon } from "@doverhq/dover-ui/icons/arrow-right.svg";
import { ReactComponent as PaperIcon } from "@doverhq/dover-ui/icons/paper.svg";
import { ReactComponent as SlashIcon } from "@doverhq/dover-ui/icons/slash.svg";
import { Box, Drawer, Stack } from "@mui/material";
import { atom, useAtom, useAtomValue } from "jotai";
import React, { ReactElement, useEffect, useState } from "react";

import { ReactComponent as AISparklesIcon } from "assets/icons/ai-sparkles-solid.svg";
import { ReactComponent as AINotetakerUpsellImage } from "assets/images/ai-notetaker-upsell.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Circle } from "components/library/Circle";
import Toggle from "components/library/Toggle";
import { Subtitle2, BodySmall, PageTitle, BodyExtraSmall } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import {
  useGetTranscriptForInterviewQuery,
  usePartialUpdateCandidateInterviewMutation,
} from "services/doverapi/endpoints/interview";
import { InterviewMeetingType, IRRCandidateInterviewInfo } from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink, InternalLink } from "styles/typography";
import { TranscriptFeed } from "views/interview/CandidateInterview/TranscriptDrawer/TranscriptFeed";
import { InterviewCallRecordingAtom, InterviewStartTimeAtom } from "views/interview/common/components/InterviewForm";

/* -----------------------------------------------------------------------------
 * helpers
 * -------------------------------------------------------------------------- */
const useGetIsBeforeCallCompleted = (): boolean => {
  const callRecording = useAtomValue(InterviewCallRecordingAtom);
  const interviewStartTime = useAtomValue(InterviewStartTimeAtom);
  const now = new Date();
  const callRecordingStatus = callRecording?.status;
  return (
    !!interviewStartTime &&
    !!(interviewStartTime > now) &&
    (!callRecordingStatus || !!(callRecordingStatus < CallRecordingStatus.IN_PROGRESS))
  );
};

/* -----------------------------------------------------------------------------
 * constants
 * -------------------------------------------------------------------------- */

export const TRANSCRIPT_DRAWER_WIDTH = "316px";
export const AINotetakerDrawerOpenAtom = atom(true);

/* -----------------------------------------------------------------------------
 * types
 * -------------------------------------------------------------------------- */

export enum CallRecordingStatus {
  BOT_NEEDS_RESCHEDULE = 50,
  BOT_SCHEDULED = 100,
  BOT_SCHEDULING_FAILED = 190,

  IN_PROGRESS = 200,

  RECORDING_FINISHED = 300,
  RECORDING_PROCESSING = 310,
  RECORDING_PROCESSED = 320,
  RECORDING_FAILED = 390,
}

/* -----------------------------------------------------------------------------
 * DrawerHeader
 * -------------------------------------------------------------------------- */

interface DrawerHeaderProps {
  onRequestClose: () => void;
}

const DrawerHeader = ({ onRequestClose }: DrawerHeaderProps): ReactElement => {
  return (
    <Stack direction="row" alignItems="center" pt={1} px={2}>
      <Button variant="ghost" onPress={onRequestClose}>
        <Icon Icon={ArrowRightIcon} size={16} color="gray-700" />
      </Button>
      <Subtitle2>Transcript</Subtitle2>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * FreeUserUpsellState
 * -------------------------------------------------------------------------- */

const FreeUserUpsellState = (): ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "ai_notetaker", "transcript_drawer_cta");
  const handleLearnMore = (): void => {
    window.open(calendlyUrl, "_blank", "noopener noreferrer");
  };

  return (
    <Box px={2}>
      <Stack
        spacing={3}
        p={2}
        borderRadius="6px"
        sx={{
          background: "linear-gradient(179.96deg, rgba(230, 255, 247, 0.6) 0%, rgba(250, 255, 253, 0) 159.42%)",
        }}
      >
        <AINotetakerUpsellImage />
        <Stack spacing={1}>
          <PageTitle>Dover Notetaker</PageTitle>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Circle size="8px" color={colors.primary.base} />
              <BodySmall>Let Dover take notes for you!</BodySmall>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Circle size="8px" color={colors.primary.base} />
              <BodySmall>Get summarized notes after every call</BodySmall>
            </Stack>
          </Stack>
        </Stack>
        <Button variant="primaryFilled" onPress={handleLearnMore}>
          Learn more
        </Button>
      </Stack>
    </Box>
  );
};

/* -----------------------------------------------------------------------------
 * TranscriptionProcessingState
 * -------------------------------------------------------------------------- */

const TranscriptionProcessingState = (): ReactElement => {
  return (
    <Stack spacing={0.5} justifyContent="center" alignItems="center" flex="1 1 auto" p={1}>
      <Subtitle2>Transcription will display here</Subtitle2>
      <BodySmall>Please check back later</BodySmall>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * AINotetakerPreCallState
 * -------------------------------------------------------------------------- */

const AINotetakerPreCallState = ({
  candidateInterviewInfo,
}: {
  candidateInterviewInfo: IRRCandidateInterviewInfo;
}): ReactElement => {
  const { interviewId, enableAiNotetaker, interviewerMeetingType } = candidateInterviewInfo;
  const [isToggleChecked, setIsToggleChecked] = useState(enableAiNotetaker);

  const [
    partialUpdateCandidateInterview,
    { isLoading: isUpdatingCandidateInterview },
  ] = usePartialUpdateCandidateInterviewMutation();

  const handleToggleAINotetaker = async (): Promise<void> => {
    // For UI responsiveness, we update the toggle state first
    setIsToggleChecked(!isToggleChecked);
    await partialUpdateCandidateInterview({ id: interviewId, data: { enableAiNotetaker: !isToggleChecked } });
  };

  if (interviewerMeetingType !== InterviewMeetingType.GOOGLE_MEET) {
    return (
      <Stack spacing={0.5} flex="1 1 auto" justifyContent="center" alignItems="center" textAlign="center" p={1}>
        <Subtitle2>AI Notetaker is unable to be added to interviews not hosted on Google Meet</Subtitle2>
        <BodySmall>
          To use AI Notetaker on interviews scheduled moving forward, update your{" "}
          <InternalLink to="/settings/interviews">interview preferences</InternalLink> to schedule interviews on Google
          Meet
        </BodySmall>
      </Stack>
    );
  }

  const text = isToggleChecked
    ? "AI Notetaker is enabled for this interview"
    : "AI Notetaker is disabled for this interview";

  const subtext = isToggleChecked
    ? "Transcript will display here after the interview."
    : "Enable AI Notetaker below to receive a recording, transcript, and AI notes.";

  return (
    <Stack spacing={2} flex="1 1 auto" justifyContent="center" alignItems="center" p={1}>
      <Stack spacing={0.5} justifyContent="center" alignItems="center" textAlign="center">
        {!isToggleChecked && <Icon Icon={SlashIcon} size={16} color="critical-base" />}
        <Subtitle2>{text}</Subtitle2>
        <BodySmall>{subtext}</BodySmall>
      </Stack>
      <Stack spacing={1} alignItems="center">
        <Stack
          spacing={0.5}
          direction="row"
          textAlign="center"
          alignItems="center"
          onClick={handleToggleAINotetaker}
          sx={{ cursor: "pointer" }}
        >
          <Toggle checked={isToggleChecked} label="" disabled={isUpdatingCandidateInterview} />
          <BodySmall>AI Notetaker</BodySmall>
          <Icon Icon={AISparklesIcon} />
        </Stack>
        <ExternalLink
          href="https://help.dover.com/en/articles/9044463-dover-ai-notetaker"
          target="_blank"
          rel="noopener noreferrer"
          $variant="secondary"
        >
          <BodyExtraSmall color={colors.grayscale.gray600}>Learn more</BodyExtraSmall>
        </ExternalLink>
      </Stack>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * AINotetakerOffPostCallState
 * -------------------------------------------------------------------------- */

const AINotetakerOffPostCallState = (): ReactElement => {
  return (
    <Stack spacing={0.5} flex="1 1 auto" justifyContent="center" alignItems="center" textAlign="center">
      <Subtitle2>No transcript available</Subtitle2>
      <BodySmall>AI Notetaker was not added to your call</BodySmall>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * AIFailedState
 * -------------------------------------------------------------------------- */

const AIFailedState = (): ReactElement => {
  return (
    <Stack spacing={0.5} justifyContent="center" alignItems="center" flex="1 1 auto">
      <Icon Icon={AlertTriangleIcon} size={16} color="warning-base" />
      <Subtitle2>Unable to load transcript</Subtitle2>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * TranscriptFeedWrapper
 * -------------------------------------------------------------------------- */

interface TranscriptFeedWrapperProps {
  candidateInterviewId: string;
}

const TranscriptFeedWrapper = ({ candidateInterviewId }: TranscriptFeedWrapperProps): ReactElement => {
  const [shouldPoll, setShouldPoll] = useState(true);

  const {
    data: transcript,
    isLoading: isLoadingTranscript,
    isError: isGetTranscriptError,
  } = useGetTranscriptForInterviewQuery(
    {
      candidateInterviewId,
    },
    {
      pollingInterval: 1000,
      skip: !shouldPoll,
    }
  );

  useEffect(() => {
    // Once we have the transcript or if there is an error, stop polling
    if (transcript?.speechSegments?.length || isGetTranscriptError) {
      setShouldPoll(false);
      // TODO: Force reloading Interview Rubric Responses when transcript loads in,
      // as we will populate the rubric responses with AI-generated answers
      // dispatch(doverApi.util.invalidateTags([CANDIDATE_RUBRIC_RESPONSES_TAG]));
    }
  }, [transcript, isGetTranscriptError]);

  if (isLoadingTranscript) return <></>;

  if (isGetTranscriptError) {
    return <AIFailedState />;
  }

  if (!transcript) {
    // By here we know that we are 1. post-call and 2. have AI notetaker enabled so we are either
    // waiting for the transcript to be processed or the transcript is not available
    return <TranscriptionProcessingState />;
  }

  return <TranscriptFeed transcript={transcript} />;
};

/* -----------------------------------------------------------------------------
 * DrawerBody
 * -------------------------------------------------------------------------- */

interface DrawerBodyProps {
  candidateInterviewInfo: IRRCandidateInterviewInfo;
}

const DrawerBody = ({ candidateInterviewInfo }: DrawerBodyProps): ReactElement => {
  const isBasePlanCustomer = useIsBasePlanCustomer();
  const useAINotetaker = useFeatureFlag(FeatureFlag.AINotetaker);

  const { interviewId, callRecording, enableAiNotetaker } = candidateInterviewInfo;

  const isBeforeCallCompleted = useGetIsBeforeCallCompleted();

  // If the user is on the base plan and AI Notetaker is not enabled, show the upsell state
  if (isBasePlanCustomer && !useAINotetaker) {
    return <FreeUserUpsellState />;
  }

  // If the call has not completed yet, show the pre-call state
  if (isBeforeCallCompleted) {
    return <AINotetakerPreCallState candidateInterviewInfo={candidateInterviewInfo} />;
  }

  // Call is completed but we AI Notetaker was not enabled or we don't have a call recording
  if (!enableAiNotetaker || !callRecording) {
    return <AINotetakerOffPostCallState />;
  }

  // Call recording failed
  if (callRecording?.status === CallRecordingStatus.RECORDING_FAILED) {
    return <AIFailedState />;
  }

  return <TranscriptFeedWrapper candidateInterviewId={interviewId} />;
};

interface TranscriptDrawerProps {
  candidateInterviewInfo: IRRCandidateInterviewInfo;
}

export const TranscriptDrawer = ({ candidateInterviewInfo }: TranscriptDrawerProps): ReactElement => {
  const [isAINotetakerDrawerOpen, setAINotetakerDrawerOpen] = useAtom(AINotetakerDrawerOpenAtom);

  const openDrawer = (): void => {
    setAINotetakerDrawerOpen(true);
  };

  const closeDrawer = (): void => {
    setAINotetakerDrawerOpen(false);
  };

  return (
    <>
      {!isAINotetakerDrawerOpen && (
        <Box right={0} top={130} position="fixed" mr="-34px">
          <Box height="fit-content" display={{ xs: "none", sm: "block" }} sx={{ transform: "rotate(90deg)" }}>
            <Box
              onClick={openDrawer}
              p={1}
              pt={1.5}
              sx={{
                cursor: "pointer",
                backgroundColor: "white",
                border: `1px solid ${colors.grayscale.gray200}`,
                boxShadow: "2px 0 4px rgba(0, 0, 0, 0.05)",
                // using a Box component instead of a Button in order to customize border radius
                borderRadius: "0 0 6px 6px",
              }}
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Icon Icon={PaperIcon} color="gray-500" />
                Transcript
              </Stack>
            </Box>
          </Box>
        </Box>
      )}
      <Drawer
        variant="persistent"
        open={isAINotetakerDrawerOpen}
        anchor="right"
        onClose={closeDrawer}
        sx={{
          position: "relative",
          overflow: "auto",
          "& .MuiDrawer-Paper": { width: { xs: "100vw", sm: TRANSCRIPT_DRAWER_WIDTH } },
        }}
      >
        <Stack flex="1 1 auto" width={{ xs: "100vw", sm: TRANSCRIPT_DRAWER_WIDTH }}>
          <DrawerHeader onRequestClose={closeDrawer} />
          <DrawerBody candidateInterviewInfo={candidateInterviewInfo} />
        </Stack>
      </Drawer>
    </>
  );
};
