import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Overline } from "components/library/typography";
import { CircledNumber } from "views/interview/common/styles";

const STANDARD_QUESTION_MESSAGE = "This is a standard question included in all feedback forms and cannot be edited.";

export const OverallRatingQuestionEl = (): React.ReactElement => {
  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      maxWidth="380px"
      sx={{ cursor: "not-allowed" }}
    >
      <Overline centered>Strong No-Hire</Overline>
      <CircledNumber disabled>1</CircledNumber>
      <CircledNumber disabled>2</CircledNumber>
      <CircledNumber disabled>3</CircledNumber>
      <CircledNumber disabled>4</CircledNumber>
      <Overline centered>Strong Hire</Overline>
    </Stack>
  );
};

export const OverallRatingSection = (): React.ReactElement => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <BodySmall weight="600">Overall Rating</BodySmall>
        <Tooltip title={STANDARD_QUESTION_MESSAGE}>
          <LockIcon />
        </Tooltip>
      </Stack>
      <OverallRatingQuestionEl />
    </Stack>
  );
};
