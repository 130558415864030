import { Box } from "@mui/material";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { colors } from "styles/theme";

export const WhiteCard = styled(Box)`
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 40px;
  gap: 17px;
  top: 50px;

  background: ${colors.white};
  border: 1px solid ${colors.grayscale.gray200};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const StyledArrowSVG = styled(ReactSVG)`
  display: inline;
  svg {
    path {
      stroke: ${colors.white};
    }
  }
`;
