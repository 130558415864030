import React from "react";
import { Field } from "react-final-form";

import { Tooltip } from "components/library/Tooltip";
import InterviewTypeField from "components/SetupHiringPipeline/components/fields/InterviewTypeField";
import { CanDetail } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useCanStage";
import {
  RequiredAsterisk,
  StageDrawerFormLabel,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/styles";

export const EditInterviewType = ({
  fieldPrefix,
  required,
  canSwap,
}: {
  fieldPrefix: string;
  required: boolean;
  canSwap: CanDetail;
}): React.ReactElement => {
  return (
    <>
      <StageDrawerFormLabel bold>
        Select interview type {required && <RequiredAsterisk>*</RequiredAsterisk>}
      </StageDrawerFormLabel>
      <Field
        name={fieldPrefix + ".interviewType"}
        validate={(value): boolean => {
          return required && !value;
        }}
      >
        {(props): React.ReactElement => {
          return (
            <Tooltip title={canSwap.reason} placement="left">
              <div>
                <InterviewTypeField {...props} required={required} disabled={!canSwap.can} />
              </div>
            </Tooltip>
          );
        }}
      </Field>
    </>
  );
};
