import { Box, Stack, styled } from "@mui/material";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";

import CopyIcon from "assets/icons/copy.svg";
import Banner from "components/Banner";
import { Body } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import { ClientInterviewer, ClientInterviewerSetupStateEnum } from "services/openapi";
import { colors } from "styles/theme";
import { useInterviewPlanStages } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useInterviewPlanStages";

export const InterviewersPendingSetup = (): React.ReactElement => {
  const interviewers = useListInterviewerEntities();
  const { stages } = useInterviewPlanStages();

  const substages = stages?.map(stage => stage.multipartInterviewStage?.substages).flat();
  const allInterviewers = substages?.map(substage => substage?.possibleInterviewers).flat();
  const allInterviewerIds = allInterviewers?.map(interviewer => interviewer?.id);
  const interviewersPendingSetup =
    interviewers?.filter((i: ClientInterviewer) => {
      return i.setupState !== ClientInterviewerSetupStateEnum.Ready && allInterviewerIds?.includes(i.id);
    }) || [];

  const [clipboardText, setClipboardText] = useState<string | undefined>(undefined);
  const interviewPreferencesLink = `${appConfig.appUrl}/settings/interview-preferences`;

  const copyToClipboard = (textToCopy: string): void => {
    navigator.clipboard.writeText(textToCopy);
    setClipboardText(textToCopy);
  };

  if (interviewersPendingSetup.length === 0) {
    return <></>;
  }

  return (
    <Banner type="warning" variant="filled" useSpacer alignItems="flex-start">
      <Stack spacing={1}>
        <Box style={{ maxWidth: "none", marginTop: "6px" }}>
          <Stack spacing={1}>
            <Box display="flex">
              <Body weight="700">The following people need to complete their interview preferences</Body>
              <Body
                color={colors.grayscale.gray600}
                onClick={(): void => {
                  copyToClipboard(interviewPreferencesLink);
                }}
                style={{ marginLeft: "8px" }}
              >
                <StyledReactSVG src={CopyIcon} />
                {clipboardText === interviewPreferencesLink ? "Copied!" : "Copy link"}
              </Body>
            </Box>
          </Stack>
        </Box>
        <ul>
          {interviewersPendingSetup.map((interviewer, index) => {
            return (
              <li key={index}>
                <Body key={index}>{interviewer.fullName || interviewer.email}</Body>
              </li>
            );
          })}
        </ul>
      </Stack>
    </Banner>
  );
};

const StyledReactSVG = styled(ReactSVG)`
  display: inline;
  margin-right: 4px;
  &:hover {
    cursor: pointer;
  }
`;
