import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Box, Chip, FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import Color from "color";
import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

import HelpIconSVG from "assets/icons/help-question.svg";
import LightBulbIcon from "assets/icons/idea-lightbulb.svg";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import SingleValueSlider from "components/library/SingleValueSlider";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { useListDiversityOptionsQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import { JobFeatureStateEnum } from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import FilterAccordion from "views/sourcing/Search/components/FilterAccordion";
import FilterSectionHeader from "views/sourcing/Search/components/FilterSectionHeader";
import TitleWithRequiredLabel from "views/sourcing/Search/components/TitleWithRequiredLabel";
import {
  FiltersContainerID,
  previewStateMarginBottom,
  previewStateMarginRight,
  sliderPaddingRight,
  DIVERSITY_OPTION_NAME,
  STRICTNESS_NAME,
  CHIP_MAX_WIDTH,
} from "views/sourcing/Search/constants";
import { FilterToggle, FilterTogglerContext } from "views/sourcing/Search/context/FilterToggleContext";
import { useGetSearchFromUrl, useIsSaaPReviewContext } from "views/sourcing/Search/hooks";
import { StyledHelpIconSVG } from "views/sourcing/Search/styles";
import { SearchV3FormSchemaType, TitleContentProps } from "views/sourcing/Search/types";

interface DiversityOptionInfo {
  friendlyName: string;
  tooltip?: string;
}

const diversityOptionToInfoMap: Map<string, DiversityOptionInfo> = new Map([
  ["EVERYONE", { friendlyName: "Everyone" }],
  ["GENDER", { friendlyName: "Non-male only" }],
  [
    "ETHNICITY",
    {
      friendlyName: "Underrepresented minorities only",
      tooltip:
        "Dover defines underrepresented minorities as candidates who identify as Black/African American, Hispanic/Latino, or American Indian",
    },
  ],
  ["GENDER_OR_ETHNICITY", { friendlyName: "Non-male OR underrepresented minorities" }],
]);

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base} !important;
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

const DiversityFilterTitleContent = React.memo(
  ({ expanded }: TitleContentProps): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const diversityOption = useWatch({ control, name: DIVERSITY_OPTION_NAME });

    const onChipDelete = React.useCallback(() => {
      setValue(DIVERSITY_OPTION_NAME, undefined);
    }, [setValue]);

    if (expanded || diversityOption === undefined) {
      return <></>;
    }

    const label = diversityOptionToInfoMap.get(diversityOption);

    if (!label) {
      return <></>;
    }

    return (
      <Box>
        <Chip
          label={<TextWithMaxWidth label={label.friendlyName} width={CHIP_MAX_WIDTH} />}
          deleteIcon={<HighlightOffSharpIcon />}
          onDelete={(): void => onChipDelete()}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      </Box>
    );
  }
);

const DiversityFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const diversityOption = useWatch({ control, name: DIVERSITY_OPTION_NAME });
    const { data: diversityOptions } = useListDiversityOptionsQuery();

    const search = useGetSearchFromUrl();
    const isSaaPReviewContext = useIsSaaPReviewContext();

    const jobId = search?.job;

    const managedOutboundState = useGetManagedOutboundState(isSaaPReviewContext ? { jobId } : { jobId: undefined });

    const tooltipTitle = React.useMemo(() => {
      const tooltipText =
        "Diversity filters are disabled for applicants due to legal restrictions. These are available through Sourcing Autopilot.";
      if (managedOutboundState !== JobFeatureStateEnum.Enabled && !!jobId) {
        return (
          <BodySmall color={colors.white}>
            {tooltipText} You can turn it on <InternalLink to={`/job/${jobId}/setup/overview`}>here</InternalLink>.
          </BodySmall>
        );
      }

      return tooltipText;
    }, [jobId, managedOutboundState]);

    const controllerBody = React.useCallback(
      (field: any) => {
        if (!diversityOptions) {
          return <></>;
        }

        return (
          <RadioGroup {...field} value={field.value ?? null}>
            {diversityOptions.map(option => {
              const optionInfo = diversityOptionToInfoMap.get(option.value);
              return (
                <Stack key={option.value} direction="row" alignItems="center">
                  <FormControlLabel
                    value={option.value ?? null}
                    control={<StyledRadio value={option.value} checked={diversityOption === option.value} />}
                    label={
                      <BodySmall color={colors.grayscale.gray700}>{optionInfo?.friendlyName ?? option.name}</BodySmall>
                    }
                    disabled={isSaaPReviewContext}
                  />
                  {optionInfo?.tooltip && (
                    <Tooltip
                      title={optionInfo.tooltip}
                      placement="right"
                      arrow={true}
                      variant={TooltipVariant.Dark}
                      boxSizing="border-box"
                    >
                      {/* Span is necessary to prevent a MUI v5 render issue */}
                      <span style={{ position: "relative", bottom: "4px" }}>
                        <StyledHelpIconSVG src={HelpIconSVG} />
                      </span>
                    </Tooltip>
                  )}
                </Stack>
              );
            })}
          </RadioGroup>
        );
      },
      [diversityOption, diversityOptions, isSaaPReviewContext]
    );

    const tooltipWrapperControllerBody = React.useCallback(
      (field: any) => {
        return (
          <Tooltip title={tooltipTitle} placement="right-start" arrow={true}>
            {controllerBody(field)}
          </Tooltip>
        );
      },
      [controllerBody, tooltipTitle]
    );

    if (!diversityOptions) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Controller
        rules={{ required: true }}
        control={control}
        name={DIVERSITY_OPTION_NAME}
        render={({ field }): React.ReactElement =>
          isSaaPReviewContext ? tooltipWrapperControllerBody(field) : controllerBody(field)
        }
      />
    );
  }
);

const StrictnessTitleContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const strictness = useWatch({ control, name: STRICTNESS_NAME });

    if (expanded) {
      return <></>;
    }

    return (
      <Box>
        <Chip
          label={<TextWithMaxWidth label={`Strictness: ${strictness}`} width={CHIP_MAX_WIDTH} />}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      </Box>
    );
  }
);

const StrictnessContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const strictness = useWatch({ control, name: STRICTNESS_NAME });

    const search = useGetSearchFromUrl();

    const handleSliderChange = React.useCallback(
      (event: Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
          return;
        }

        setValue(STRICTNESS_NAME, newValue);
      },
      [setValue]
    );

    const isToggling = React.useContext<FilterToggle | undefined>(FilterTogglerContext)?.isToggling;

    if (!search) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Stack spacing={1} paddingRight={sliderPaddingRight}>
        <SingleValueSlider
          value={strictness}
          min={0}
          max={10}
          step={1}
          marks={true}
          scrollContainerId={FiltersContainerID}
          onChange={handleSliderChange}
          shouldShowTooltip={!isToggling && expanded}
        />
      </Stack>
    );
  }
);

const AdvancedFilters = React.memo(
  ({ hideDiversity }: { hideDiversity?: boolean }): React.ReactElement => {
    const [strictnessExpanded, setStrictnessExpanded] = React.useState<boolean>(false);

    return (
      <>
        <FilterSectionHeader title={"Advanced"} icon={LightBulbIcon} />
        {!hideDiversity && (
          <FilterAccordion
            title="Diversity"
            TitleContent={DiversityFilterTitleContent}
            expandedContent={<DiversityFiltersContent />}
          />
        )}
        <FilterAccordion
          title={
            <TitleWithRequiredLabel
              mainTitle={"Strictness Scale"}
              tooltipText={"Determines the number of nice to haves required for a candidate to be considered a match."}
            />
          }
          TitleContent={StrictnessTitleContent}
          expandedContent={<StrictnessContent expanded={strictnessExpanded} />}
          expandedCallback={setStrictnessExpanded}
        />
      </>
    );
  }
);

export default AdvancedFilters;
