/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewPanelField,
    InterviewPanelFieldFromJSON,
    InterviewPanelFieldFromJSONTyped,
    InterviewPanelFieldToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewEventField
 */
export interface InterviewEventField {
    /**
     * 
     * @type {string}
     * @memberof InterviewEventField
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewEventField
     */
    isMultipart?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewEventField
     */
    isDoverInterview?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InterviewEventField
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InterviewEventField
     */
    end?: Date;
    /**
     * 
     * @type {Array<InterviewPanelField>}
     * @memberof InterviewEventField
     */
    interviewPanel?: Array<InterviewPanelField>;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewEventField
     */
    hasTranscript?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InterviewEventField
     */
    callRecordingStatus?: number;
}

export function InterviewEventFieldFromJSON(json: any): InterviewEventField {
    return InterviewEventFieldFromJSONTyped(json, false);
}

export function InterviewEventFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewEventField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'isMultipart': !exists(json, 'is_multipart') ? undefined : json['is_multipart'],
        'isDoverInterview': !exists(json, 'is_dover_interview') ? undefined : json['is_dover_interview'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'interviewPanel': !exists(json, 'interview_panel') ? undefined : ((json['interview_panel'] as Array<any>).map(InterviewPanelFieldFromJSON)),
        'hasTranscript': !exists(json, 'has_transcript') ? undefined : json['has_transcript'],
        'callRecordingStatus': !exists(json, 'call_recording_status') ? undefined : json['call_recording_status'],
    };
}

export function InterviewEventFieldToJSON(value?: InterviewEventField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'is_multipart': value.isMultipart,
        'is_dover_interview': value.isDoverInterview,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'interview_panel': value.interviewPanel === undefined ? undefined : ((value.interviewPanel as Array<any>).map(InterviewPanelFieldToJSON)),
        'has_transcript': value.hasTranscript,
        'call_recording_status': value.callRecordingStatus,
    };
}


