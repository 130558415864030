/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonaIDRequest
 */
export interface PersonaIDRequest {
    /**
     * 
     * @type {string}
     * @memberof PersonaIDRequest
     */
    personaId: string;
}

export function PersonaIDRequestFromJSON(json: any): PersonaIDRequest {
    return PersonaIDRequestFromJSONTyped(json, false);
}

export function PersonaIDRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonaIDRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personaId': json['persona_id'],
    };
}

export function PersonaIDRequestToJSON(value?: PersonaIDRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'persona_id': value.personaId,
    };
}


