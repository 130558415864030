import { Grid } from "@mui/material";
import React from "react";

import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import { SectionHeader } from "styles/typography";
import SidePanel from "views/admin/Campaigns/components/SidePanel";
import Table from "views/admin/Campaigns/components/Table";
import { PageWrapper } from "views/admin/Campaigns/styles";

const CampaignsAdminView = (): React.ReactElement => {
  return (
    <>
      <PageHelmet title="Admin Campaigns" />

      <PageWrapper>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <SectionHeader>Campaigns</SectionHeader>
            <Spacer height="20px" />
            <Table />
          </Grid>
          <Grid item container direction="column" alignItems="flex-end" xs={3}>
            <SidePanel />
          </Grid>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default CampaignsAdminView;
