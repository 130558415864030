import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { SIDE_NAV_WIDTH } from "App/appConstants";
import { OpenNavButton } from "App/components/Nav/OpenNavButton";
import { SideNavV2Content } from "App/components/Nav/SideNavV2Content";
import AddCandidate from "sections/addcandidate";
import { colors, screenSizesNumbers } from "styles/theme";

interface SideNavV2Props {
  drawerOpen: boolean;
  toggleDrawerOpen: () => void;
}

/**
 * SideNavV2
 * A drawer-based side navigation component that implements reponsive design
 */
export const SideNavV2 = ({ drawerOpen, toggleDrawerOpen }: SideNavV2Props): React.ReactElement => {
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tabletL));

  return (
    <>
      {/* Render the candidate drawer as part of the side navigation */}
      <AddCandidate />
      <Drawer
        // For smaller form facators, the side nav should be light dismissed and not affect layout
        variant={isSmallScreen ? "temporary" : "persistent"}
        open={drawerOpen}
        onClose={toggleDrawerOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        anchor={"left"}
        sx={{
          // Force width to affect layout of the rest of the app
          // Does not affect the side nav on mobile
          width: `${SIDE_NAV_WIDTH}px`,
          "& .MuiDrawer-paper": {
            borderRight: `1px solid ${colors.grayscale.gray200}`,
            width: `${SIDE_NAV_WIDTH}px`,
            backgroundColor: colors.grayscale.gray100,
            padding: "12px",
            overflow: "visible",
          },
        }}
      >
        <SideNavV2Content toggleDrawerOpen={toggleDrawerOpen} />
      </Drawer>
      {!drawerOpen && <OpenNavButton onClick={toggleDrawerOpen} />}
    </>
  );
};
