import { Moment } from "moment-timezone";

export const dateToUnix = (date: Moment | Date): number => Math.floor(date.valueOf() / 1000);

export const interpretDateAsUTC = (d: Date): Date => {
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()));
};

export const interpretDateAsLocal = (d: Date): Date => {
  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds()
  );
};

export const removeOffset = (m: Moment): Date => m.tz("UTC", true).toDate();

export const timezones = [
  {
    label: "US/Alaska",
    index: 0,
    value: "America/Anchorage",
  },
  {
    label: "US/Arizona",
    index: 1,
    value: "America/Phoenix",
  },
  {
    label: "US/Central",
    index: 2,
    value: "America/Chicago",
  },
  {
    label: "US/Eastern",
    index: 3,
    value: "America/New_York",
  },
  {
    label: "US/Hawaii",
    index: 4,
    value: "Pacific/Honolulu",
  },
  {
    label: "US/Mountain",
    index: 5,
    value: "America/Denver",
  },
  {
    label: "US/Pacific",
    index: 6,
    value: "America/Los_Angeles",
  },
  {
    label: "Europe/Dublin",
    index: 7,
    value: "Europe/Dublin",
  },
  {
    label: "Europe/Kiev",
    index: 8,
    value: "Europe/Kiev",
  },
  {
    label: "Europe/London",
    index: 9,
    value: "Europe/London",
  },
  {
    label: "Europe/Rome",
    index: 10,
    value: "Europe/Rome",
  },
  {
    label: "Asia/Hong Kong",
    index: 11,
    value: "Asia/Hong_Kong",
  },
  {
    label: "Asia/India",
    index: 12,
    value: "Asia/Kolkata",
  },
  {
    label: "Asia/Seoul",
    index: 13,
    value: "Asia/Seoul",
  },
  {
    label: "Asia/Singapore",
    index: 14,
    value: "Asia/Kuala_Lumpur",
  },
  {
    label: "Asia/Tokyo",
    index: 15,
    value: "Asia/Tokyo",
  },
];
