import { Stack, Grid } from "@mui/material";
import React from "react";

import { Overline } from "components/library/typography";
import { getOptionLabel } from "views/interview/common/components/SelectField";
import { CircledNumber } from "views/interview/common/styles";

interface OverallRatingSelectProps {
  notes?: string;
  setNotes?: (notes: string) => void;
  options?: any[];
}

export const OVERALL_RATING_NAME = "Overall Rating";

export const OverallRatingSelect = React.memo(({ notes, setNotes, options }: OverallRatingSelectProps) => {
  const optionsToDisplay = options || ["1", "2", "3", "4"];
  const isSelected = (option: any): boolean => {
    if (options) {
      return !!notes && option.label === notes;
    }
    return option === notes;
  };

  const onClick = (option: string): void => {
    if (setNotes && options) {
      setNotes(getOptionLabel(option, options));
    }
  };

  return (
    <Grid item container xs={12} md={12} justifyContent="center" sx={{ paddingTop: "16px" }} display="flex">
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Overline centered>Strong No-Hire</Overline>
        {optionsToDisplay.map(option => {
          return (
            <Stack direction="row">
              <CircledNumber
                disabled={!setNotes && !options}
                selected={isSelected(option)}
                onClick={(): void => onClick(option)}
              >
                {options ? getOptionLabel(option, options) : option}
              </CircledNumber>
            </Stack>
          );
        })}
        <Overline centered>Strong Hire</Overline>
      </Stack>
    </Grid>
  );
});
