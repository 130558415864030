import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { SAAP_REVIEW_SCORED_APPLICATION_LIST, JOB } from "services/doverapi/endpointTagsConstants";
import {
  ListSaapReviewApplicationRequest,
  ListApplicationsReturn,
  ApiApiListApplicationsViaAIRequest,
  ListAppsViaAIResponse,
  GetInDepthExplanationResponse,
  GetInDepthExplanationRequest,
  ApiApiChangeAppScoringEnabledOperationRequest,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

export const applicationReviewEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listSaapReviewApplications: build.query<ListApplicationsReturn, ListSaapReviewApplicationRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});

          const result = await client.listSaapReviewApplications({ data: args });
          return { data: result };
        } catch (error) {
          const userFacingMessage = "Failed to load applicants. Please refresh and try again.";
          showErrorToast(userFacingMessage);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, params) => {
        return [
          { type: SAAP_REVIEW_SCORED_APPLICATION_LIST },
          { type: SAAP_REVIEW_SCORED_APPLICATION_LIST, id: params.searchId },
          { type: SAAP_REVIEW_SCORED_APPLICATION_LIST, id: params.bookmarkedFilter?.toString() }, // This is used for invalidating the list used in the unselected bookmark tab in app review
        ];
      },
    }),
    listApplicationsViaAI: build.query<ListAppsViaAIResponse, ApiApiListApplicationsViaAIRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});

          const result = await client.listApplicationsViaAI(args);
          return { data: result };
        } catch (error) {
          console.warn("error", error);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, params) => {
        return [
          { type: SAAP_REVIEW_SCORED_APPLICATION_LIST },
          { type: SAAP_REVIEW_SCORED_APPLICATION_LIST, id: params.data.searchId },
        ];
      },
    }),
    getInDepthExplanation: build.query<GetInDepthExplanationResponse, GetInDepthExplanationRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});

          const result = await client.getInDepthExplanation({ data: args });
          return { data: result };
        } catch (error) {
          const userFacingMessage = "Failed to get an in depth explanation. Please try again or contact Dover Support.";
          showErrorToast(userFacingMessage);

          console.warn("error", error);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    activateScoringForJob: build.mutation<boolean, ApiApiChangeAppScoringEnabledOperationRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});

          const result = await client.changeAppScoringEnabled(args);
          return { data: result.success };
        } catch (error) {
          console.warn("error", error);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: SAAP_REVIEW_SCORED_APPLICATION_LIST }, { type: JOB, id: args.id }];
      },
    }),
  }),
});

export const {
  useListSaapReviewApplicationsQuery,
  useListApplicationsViaAIQuery,
  useLazyListApplicationsViaAIQuery,
  usePrefetch,
  useGetInDepthExplanationQuery,
  useActivateScoringForJobMutation,
} = applicationReviewEndpoints;
