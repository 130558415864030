import styled from "styled-components";

import { theme } from "components/data-driven-forms/styles/theme";

export const TextFieldStyles = styled.div`
  width: 100%;

  .Mui-focused > .MuiFormControl-root > .MuiOutlinedInput-root > fieldset {
    border-color: ${theme.colors.highlightBlue};
  }

  .MuiFormControl-root > .MuiOutlinedInput-root {
    ${({ hideLabel }) =>
      hideLabel &&
      `
        background-color: ${theme.backgrounds.blue};
    `}
  }

  .MuiFormControl-root > .MuiFormLabel-root {
    color: ${theme.colors.gray30};
    ${({ hideLabel }) =>
      hideLabel &&
      `
        display: none;
    `}
  }
`;
