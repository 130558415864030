/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalApplicantRequest
 */
export interface ExternalApplicantRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    coreSourceIdentifier: ExternalApplicantRequestCoreSourceIdentifierEnum;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    externalJobIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    linkedinUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    resumeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    readonly resume?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalApplicantRequest
     */
    performDeduplication?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    externalApplicationId: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalApplicantRequest
     */
    additionalInfo?: string;
    /**
     * 
     * @type {Date}
     * @memberof ExternalApplicantRequest
     */
    appliedAt?: Date;
}

/**
* @export
* @enum {string}
*/
export enum ExternalApplicantRequestCoreSourceIdentifierEnum {
    DoverOutbound = 'DOVER_OUTBOUND',
    ManualOutbound = 'MANUAL_OUTBOUND',
    DoverReferrals = 'DOVER_REFERRALS',
    TriplebyteOutbound = 'TRIPLEBYTE_OUTBOUND',
    SourcingExtensionOutbound = 'SOURCING_EXTENSION_OUTBOUND',
    UnknownInbound = 'UNKNOWN_INBOUND',
    DoverTalentNetwork = 'DOVER_TALENT_NETWORK',
    CareersPage = 'CAREERS_PAGE',
    DoverCareersPage = 'DOVER_CAREERS_PAGE',
    CandidateReferredInbound = 'CANDIDATE_REFERRED_INBOUND',
    Greenhouse = 'GREENHOUSE',
    Lever = 'LEVER',
    Agency = 'AGENCY',
    Linkedin = 'LINKEDIN',
    Indeed = 'INDEED',
    Angellist = 'ANGELLIST',
    TriplebyteInbound = 'TRIPLEBYTE_INBOUND',
    YcWaas = 'YC_WAAS',
    XHiring = 'X_HIRING'
}

export function ExternalApplicantRequestFromJSON(json: any): ExternalApplicantRequest {
    return ExternalApplicantRequestFromJSONTyped(json, false);
}

export function ExternalApplicantRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalApplicantRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coreSourceIdentifier': json['core_source_identifier'],
        'externalJobIdentifier': !exists(json, 'external_job_identifier') ? undefined : json['external_job_identifier'],
        'jobId': !exists(json, 'job_id') ? undefined : json['job_id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'resumeUrl': !exists(json, 'resume_url') ? undefined : json['resume_url'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'resume': !exists(json, 'resume') ? undefined : json['resume'],
        'performDeduplication': !exists(json, 'perform_deduplication') ? undefined : json['perform_deduplication'],
        'externalApplicationId': json['external_application_id'],
        'additionalInfo': !exists(json, 'additional_info') ? undefined : json['additional_info'],
        'appliedAt': !exists(json, 'applied_at') ? undefined : (new Date(json['applied_at'])),
    };
}

export function ExternalApplicantRequestToJSON(value?: ExternalApplicantRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'core_source_identifier': value.coreSourceIdentifier,
        'external_job_identifier': value.externalJobIdentifier,
        'job_id': value.jobId,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'linkedin_url': value.linkedinUrl,
        'resume_url': value.resumeUrl,
        'email': value.email,
        'perform_deduplication': value.performDeduplication,
        'external_application_id': value.externalApplicationId,
        'additional_info': value.additionalInfo,
        'applied_at': value.appliedAt === undefined ? undefined : (value.appliedAt.toISOString()),
    };
}


