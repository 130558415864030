import { Box } from "@mui/material";
import React from "react";

import { colors } from "styles/theme";
import Header from "views/create-job/ExtensionJobCreationFlow/components/Header";
import Layout from "views/create-job/ExtensionJobCreationFlow/components/Layout";
import LoadingNextStepCard from "views/create-job/ExtensionJobCreationFlow/components/LoadingNextStepCard";
import { KEYED_BY_PATH_EXTENSION_JOB_CREATION_CONFIG } from "views/create-job/ExtensionJobCreationFlow/constants";
import {
  ExtensionCreateJobProvider,
  useExtensionCreateJobContext,
} from "views/create-job/ExtensionJobCreationFlow/context";

const JobCreationStep = (): React.ReactElement => {
  const { step } = useExtensionCreateJobContext();

  const CurrentJobCreationStepComponent = React.useMemo(() => {
    const stepConfig = KEYED_BY_PATH_EXTENSION_JOB_CREATION_CONFIG[step ?? ""];
    return stepConfig?.component ?? <LoadingNextStepCard />;
  }, [step]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="start"
      sx={{
        minHeight: "calc(100vh - 56px - 58px - 86px - 52px)", // The 3 pixel values are to account for the top navigation bar and two possible banners and their margins
        backgroundColor: colors.grayscale.gray100,
      }}
    >
      {CurrentJobCreationStepComponent ? <CurrentJobCreationStepComponent /> : <LoadingNextStepCard />}
    </Box>
  );
};

const ExtensionJobCreationFlow = (): React.ReactElement => {
  return (
    <ExtensionCreateJobProvider>
      <Layout>
        <Header />
        <JobCreationStep />
      </Layout>
    </ExtensionCreateJobProvider>
  );
};

export default ExtensionJobCreationFlow;
