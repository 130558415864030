/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultipartSchedulingClientInterviewer,
    MultipartSchedulingClientInterviewerFromJSON,
    MultipartSchedulingClientInterviewerFromJSONTyped,
    MultipartSchedulingClientInterviewerToJSON,
} from './';

/**
 * 
 * @export
 * @interface MultipartSchedulingBaseInterviewSubstage
 */
export interface MultipartSchedulingBaseInterviewSubstage {
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    candidateMultipartInterviewSubstageId?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    multipartInterviewSubstageId?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    name: string;
    /**
     * 
     * @type {Array<MultipartSchedulingClientInterviewer>}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    possibleInterviewers: Array<MultipartSchedulingClientInterviewer>;
    /**
     * 
     * @type {Array<MultipartSchedulingClientInterviewer>}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    actualInterviewers: Array<MultipartSchedulingClientInterviewer>;
    /**
     * 
     * @type {number}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    durationInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    order?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    requireAllInterviewers?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MultipartSchedulingBaseInterviewSubstage
     */
    confirmedStartTime?: Date;
}

export function MultipartSchedulingBaseInterviewSubstageFromJSON(json: any): MultipartSchedulingBaseInterviewSubstage {
    return MultipartSchedulingBaseInterviewSubstageFromJSONTyped(json, false);
}

export function MultipartSchedulingBaseInterviewSubstageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipartSchedulingBaseInterviewSubstage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateMultipartInterviewSubstageId': !exists(json, 'candidate_multipart_interview_substage_id') ? undefined : json['candidate_multipart_interview_substage_id'],
        'multipartInterviewSubstageId': !exists(json, 'multipart_interview_substage_id') ? undefined : json['multipart_interview_substage_id'],
        'name': json['name'],
        'possibleInterviewers': ((json['possible_interviewers'] as Array<any>).map(MultipartSchedulingClientInterviewerFromJSON)),
        'actualInterviewers': ((json['actual_interviewers'] as Array<any>).map(MultipartSchedulingClientInterviewerFromJSON)),
        'durationInMinutes': json['duration_in_minutes'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'requireAllInterviewers': !exists(json, 'require_all_interviewers') ? undefined : json['require_all_interviewers'],
        'confirmedStartTime': !exists(json, 'confirmed_start_time') ? undefined : (new Date(json['confirmed_start_time'])),
    };
}

export function MultipartSchedulingBaseInterviewSubstageToJSON(value?: MultipartSchedulingBaseInterviewSubstage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_multipart_interview_substage_id': value.candidateMultipartInterviewSubstageId,
        'multipart_interview_substage_id': value.multipartInterviewSubstageId,
        'name': value.name,
        'possible_interviewers': ((value.possibleInterviewers as Array<any>).map(MultipartSchedulingClientInterviewerToJSON)),
        'actual_interviewers': ((value.actualInterviewers as Array<any>).map(MultipartSchedulingClientInterviewerToJSON)),
        'duration_in_minutes': value.durationInMinutes,
        'order': value.order,
        'require_all_interviewers': value.requireAllInterviewers,
        'confirmed_start_time': value.confirmedStartTime === undefined ? undefined : (value.confirmedStartTime.toISOString()),
    };
}


