import AddCircleIcon from "@mui/icons-material/AddCircle";
import { TableContainer } from "@mui/material";
import styled from "styled-components";

import { filters } from "styles/theme";
import { colors } from "styles/theme";

export const PageWrapper = styled.div`
  padding: 24px 36px;
`;

export const StyledTableContainer = styled(TableContainer)`
  .hiring-plan-table {
    position: relative;
    background-color: white;
    border: 1px solid ${colors.grayscale.gray300};
    overflow-x: auto;
  }
  max-height: 80vh;
`;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  cursor: pointer;
  position: relative;

  filter: ${filters.filterDarkBlue};
`;
