/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrentUserOnboardingStepResponse
 */
export interface CurrentUserOnboardingStepResponse {
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOnboardingStepResponse
     */
    currentFlowId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOnboardingStepResponse
     */
    currentFlowName?: CurrentUserOnboardingStepResponseCurrentFlowNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CurrentUserOnboardingStepResponse
     */
    currentStep?: CurrentUserOnboardingStepResponseCurrentStepEnum;
}

/**
* @export
* @enum {string}
*/
export enum CurrentUserOnboardingStepResponseCurrentFlowNameEnum {
    UserInfo = 'USER_INFO',
    UserAndCompanyLoginInfo = 'USER_AND_COMPANY_LOGIN_INFO',
    CompanyLoginAndSetup = 'COMPANY_LOGIN_AND_SETUP',
    AtsJobBoards = 'ATS_JOB_BOARDS',
    SourcingAutopilotSetup = 'SOURCING_AUTOPILOT_SETUP',
    DoverInterviewerSetup = 'DOVER_INTERVIEWER_SETUP',
    AutoSchedulingSetup = 'AUTO_SCHEDULING_SETUP',
    ApplicantSorting = 'APPLICANT_SORTING'
}/**
* @export
* @enum {string}
*/
export enum CurrentUserOnboardingStepResponseCurrentStepEnum {
    ClientCreation = 'CLIENT_CREATION',
    JobSelection = 'JOB_SELECTION',
    CompanySetup = 'COMPANY_SETUP',
    EditCareersPage = 'EDIT_CAREERS_PAGE',
    JdQuestion = 'JD_QUESTION',
    InterviewStages = 'INTERVIEW_STAGES',
    SourcingAutopilotJdQuestion = 'SOURCING_AUTOPILOT_JD_QUESTION',
    DoverInterviewerHowItWorks = 'DOVER_INTERVIEWER_HOW_IT_WORKS',
    AutoSchedulingHowItWorks = 'AUTO_SCHEDULING_HOW_IT_WORKS',
    InboundApplicationForm = 'INBOUND_APPLICATION_FORM',
    InboundCriteria = 'INBOUND_CRITERIA',
    FeatureSelection = 'FEATURE_SELECTION',
    InviteNewUsers = 'INVITE_NEW_USERS',
    Completion = 'COMPLETION'
}

export function CurrentUserOnboardingStepResponseFromJSON(json: any): CurrentUserOnboardingStepResponse {
    return CurrentUserOnboardingStepResponseFromJSONTyped(json, false);
}

export function CurrentUserOnboardingStepResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentUserOnboardingStepResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentFlowId': !exists(json, 'current_flow_id') ? undefined : json['current_flow_id'],
        'currentFlowName': !exists(json, 'current_flow_name') ? undefined : json['current_flow_name'],
        'currentStep': !exists(json, 'current_step') ? undefined : json['current_step'],
    };
}

export function CurrentUserOnboardingStepResponseToJSON(value?: CurrentUserOnboardingStepResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_flow_id': value.currentFlowId,
        'current_flow_name': value.currentFlowName,
        'current_step': value.currentStep,
    };
}


