import { Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ChromeSVG } from "assets/icons/chrome-gray.svg";
import { ReactComponent as RocketIcon } from "assets/icons/rocket-black.svg";
import { SOURCING_EXTENSION_STORE_URL } from "components/constants";
import { BodySmall } from "components/library/typography";
import { SetupGuide, SetupGuideStep, seenSetupGuideExtensionAd } from "components/SetupGuide";
import { ReactComponent as CreateCareersPageIllustration } from "components/SetupGuide/illustrations/create-careers-page.svg";
import { ReactComponent as ExtensionIllustration } from "components/SetupGuide/illustrations/extension.svg";
import { ReactComponent as InviteIntervewPanelIllustration } from "components/SetupGuide/illustrations/invite-interview-panel.svg";
import { ReactComponent as PostJobBoardsIllustration } from "components/SetupGuide/illustrations/post-job-boards.svg";
import { CTAButton, GrayBody } from "components/SetupGuide/SetupGuideCTA";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useListEditCareersPageJobsQuery } from "services/doverapi/endpoints/careersPageJob";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { colors } from "styles/theme";
import { useAllHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

/* -----------------------------------------------------------------------------
 * SetupGuide
 * -------------------------------------------------------------------------- */

export const JobSetupGuide = ({ jobId }: { jobId: string }): React.ReactElement => {
  const [hasDownloadedExtension, setHasDownloadedExtension] = useAtom(seenSetupGuideExtensionAd);
  const showExtensionAd = useFeatureFlag(FeatureFlag.AdvertiseSourcingExtension);

  const { data: client } = useGetUsersClientQuery();

  // make requests for setup steps
  const { data: job, isFetching: isFetchingJob } = useGetJobQuery(jobId);
  const { stages, isFetching: isFetchingHps } = useAllHps(jobId);
  const {
    currentData: { results: publishableJobs } = {},
    isFetching: isFetchingCareersPagesJobs,
  } = useListEditCareersPageJobsQuery(client?.id ? { clientId: client?.id, limit: 200 } : skipToken);

  const [dismissed, setDismissed] = useLocalStorage(`setupGuideDismissed${jobId}`, false);
  const [hidden, setHidden] = useLocalStorage(`setupGuideHidden${jobId}`, false);

  const steps: Array<SetupGuideStep> = [
    {
      name: "Add job to your careers page",
      content: <GrayBody>Publish your job to your careers page to start getting applicants</GrayBody>,
      graphic: <CreateCareersPageIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.editCareersPage(true)}>Add to careers page</CTAButton>,
      isComplete: !!publishableJobs?.find(j => j.id === jobId && !j.isSample && j.isPublished),
    },
    {
      name: "Post to job boards",
      content: <GrayBody>Promote your job to start getting more applicants</GrayBody>,
      graphic: <PostJobBoardsIllustration width="100%" height="auto" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.jobBoards(jobId)}>Add to job boards</CTAButton>,
      isComplete: !!job?.jobBoardsSetupComplete,
      // isComplete: true,
    },
    {
      name: "Invite your interview panel",
      content: <GrayBody>Add your team to your interview plan to organize your process</GrayBody>,
      graphic: <InviteIntervewPanelIllustration width="100%" />,
      ctaButton: <CTAButton path={APP_ROUTE_PATHS.job.interviewPlan(jobId)}>Invite team</CTAButton>,
      isComplete:
        !job?.isSample &&
        // all interview stages should have at least one interviewer
        !!stages
          ?.filter(s => s.multipartInterviewStage)
          .every(s => s.multipartInterviewStage?.substages?.filter(s => s.possibleInterviewers.length > 0)),
    },
    ...(showExtensionAd
      ? [
          {
            name: "Get Sourcing Extension",
            isComplete: hasDownloadedExtension,
            ctaMaxWidth: "70%",
            content: (
              <GrayBody>
                <b>Reach out to candidates on LinkedIn</b>
                <br />
                Add candidates to outreach campaigns as you find them on LinkedIn
              </GrayBody>
            ),
            graphic: <ExtensionIllustration />,
            ctaButton: (
              <CTAButton
                onPress={(): void => {
                  setHasDownloadedExtension(true);
                  window.open(SOURCING_EXTENSION_STORE_URL, "_blank", "noopener noreferrer");
                }}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <ChromeSVG className="svg-color" color={colors.white} />
                  <span>Download Chrome Extension</span>
                </Stack>
              </CTAButton>
            ),
          },
        ]
      : []),
  ];

  const isLoading = isFetchingHps || isFetchingJob || isFetchingCareersPagesJobs;

  const allComplete = steps.every(s => s.isComplete);

  useEffect(() => {
    if (allComplete) {
      setHidden(true);
    }
  }, [allComplete, setHidden]);

  if (isLoading) {
    return <Skeleton width="100%" height="240px" />;
  }

  const dismissGuide = (): void => {
    setDismissed(true);
  };

  if (dismissed) {
    return <></>;
  }

  return (
    <Stack spacing={1}>
      <SetupGuide
        steps={steps}
        onDismiss={dismissGuide}
        loading={isLoading}
        heading={
          <Stack direction="row" alignItems={"center"} spacing={1}>
            <Stack direction="row" alignItems={"center"} spacing={0.5}>
              <RocketIcon color="black" />
              <BodySmall weight="600">Setup checklist</BodySmall>
            </Stack>
            {/* {!allComplete && <Chip variant="Informational" label="Incomplete" />} */}
          </Stack>
        }
        showHideButton
        isHiddenInitially={hidden}
        onToggleHide={(): void => setHidden(!hidden)}
      />
    </Stack>
  );
};
