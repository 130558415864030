/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobInterviewSubStageStats
 */
export interface JobInterviewSubStageStats {
    /**
     * 
     * @type {string}
     * @memberof JobInterviewSubStageStats
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof JobInterviewSubStageStats
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewSubStageStats
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewSubStageStats
     */
    active: number;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewSubStageStats
     */
    rejected: number;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewSubStageStats
     */
    withdrew: number;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewSubStageStats
     */
    snoozed: number;
}

export function JobInterviewSubStageStatsFromJSON(json: any): JobInterviewSubStageStats {
    return JobInterviewSubStageStatsFromJSONTyped(json, false);
}

export function JobInterviewSubStageStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobInterviewSubStageStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'label': json['label'],
        'total': json['total'],
        'active': json['active'],
        'rejected': json['rejected'],
        'withdrew': json['withdrew'],
        'snoozed': json['snoozed'],
    };
}

export function JobInterviewSubStageStatsToJSON(value?: JobInterviewSubStageStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'label': value.label,
        'total': value.total,
        'active': value.active,
        'rejected': value.rejected,
        'withdrew': value.withdrew,
        'snoozed': value.snoozed,
    };
}


