import { Stack } from "@mui/material";
import React from "react";

import InviteNewUsersForm from "components/InviteNewUsers";
import { useInviteNewUsersForm } from "components/InviteNewUsers/hooks";
import { Button, ButtonVariant } from "components/library/Button";
import CustomModal from "components/Modal";

const InviteNewUsersModal = ({ open, onClose }: { open: boolean; onClose: () => void }): React.ReactElement => {
  const {
    emailAddresses,
    setEmailAddresses,
    emailAddressesAreValid,
    customEmailMessage,
    setCustomEmailMessage,
    submitDisabled,
    sendInvitations,
    inputValue,
    setInputValue,
  } = useInviteNewUsersForm();

  const handleClose = (): void => {
    onClose();
    setEmailAddresses([]);
    setCustomEmailMessage("");
    setInputValue("");
  };

  const onSubmit = async (): Promise<void> => {
    const result = await sendInvitations();
    if (result.success) {
      onClose();
    }
  };

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title="Invite your team"
      dialogActions={
        <Stack direction="row" display="flex" justifyContent="flex-end" spacing={2}>
          <Button variant={ButtonVariant.Secondary} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant={ButtonVariant.Primary} disabled={submitDisabled} onClick={onSubmit}>
            Invite
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2}>
        <InviteNewUsersForm
          emailAddresses={emailAddresses}
          setEmailAddresses={setEmailAddresses}
          emailAddressesAreValid={emailAddressesAreValid}
          customEmailMessage={customEmailMessage}
          setCustomEmailMessage={setCustomEmailMessage}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </Stack>
    </CustomModal>
  );
};

export default InviteNewUsersModal;
