/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnsubscribeFooter
 */
export interface UnsubscribeFooter {
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeFooter
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof UnsubscribeFooter
     */
    footer: string;
    /**
     * 
     * @type {boolean}
     * @memberof UnsubscribeFooter
     */
    active?: boolean;
}

export function UnsubscribeFooterFromJSON(json: any): UnsubscribeFooter {
    return UnsubscribeFooterFromJSONTyped(json, false);
}

export function UnsubscribeFooterFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnsubscribeFooter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'footer': json['footer'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function UnsubscribeFooterToJSON(value?: UnsubscribeFooter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'footer': value.footer,
        'active': value.active,
    };
}


