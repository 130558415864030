import { Box, Stack } from "@mui/material";
import React, { useMemo } from "react";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Subtitle1 } from "components/library/typography";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { JobFeatureFeatureNameEnum } from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { WhiteCard } from "views/job/JobSetup/steps/Overview/styles";

interface TemplateCardProps {
  imageComponent?: React.ReactElement;
  title: string;
  body: React.ReactElement;
  actionButton?: React.ReactElement;
  highlighted?: boolean; // prop to be used to highlight a card (right now only Recruiting Partner)
  featureStateBadge?: React.ReactElement;
  learnMoreLink?: string;
  featureActiveAndSetup?: boolean;
  feature?: JobFeatureFeatureNameEnum;
  settings?: React.ReactElement;
  forceAction?: boolean; // By default we check on plan to show action button, but this prop can be used to show it no matter what plan
}

export const TemplateCard = React.memo(
  ({
    imageComponent,
    title,
    body,
    actionButton,
    highlighted,
    featureStateBadge,
    learnMoreLink,
    featureActiveAndSetup,
    feature,
    settings,
    forceAction,
  }: TemplateCardProps): React.ReactElement => {
    const isBasePlanCustomer = useIsBasePlanCustomer();
    const calendlyUrl = useGetCalendlyUrl("app", "job_overview", "solutions_card");

    const learnMoreLinkComponent = useMemo(
      () =>
        learnMoreLink ? (
          <InternalLink to={learnMoreLink} style={{ textDecoration: "none" }}>
            <Body color={colors.grayscale.gray500}>Learn more</Body>
          </InternalLink>
        ) : null,
      [learnMoreLink]
    );

    const actionsSection = useMemo(() => {
      if (!actionButton) {
        return <></>;
      }

      // if you are contract or have managed outbound, you can use the action button
      if (forceAction || !isBasePlanCustomer || feature === JobFeatureFeatureNameEnum.ManagedOutbound) {
        return (
          <Stack alignItems="center" spacing={1} flexShrink={0}>
            {actionButton}
            {learnMoreLink && !featureActiveAndSetup && learnMoreLinkComponent}
          </Stack>
        );
      }

      // otherwise you can book a call to learn more
      return (
        <Stack spacing={1} flexShrink={0} alignItems="center">
          <Button
            variant={ButtonVariant.SecondarySuccess}
            onClick={(): void => {
              window.open(calendlyUrl, "_blank", "noopener noreferrer");
            }}
          >
            Book a call
          </Button>
          {learnMoreLinkComponent}
        </Stack>
      );
    }, [
      actionButton,
      forceAction,
      isBasePlanCustomer,
      feature,
      learnMoreLinkComponent,
      learnMoreLink,
      featureActiveAndSetup,
      calendlyUrl,
    ]);

    const cardContent = settings ? (
      <Stack spacing={1} width="100%">
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="row" spacing={1} alignItems="center">
            <Subtitle1>{title}</Subtitle1>
            {featureStateBadge}
          </Stack>
          {actionsSection}
        </Stack>
        {settings}
      </Stack>
    ) : (
      <Stack direction="row" justifyContent="space-between" width="100%" spacing={1} alignItems={"center"}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            <Subtitle1>{title}</Subtitle1>
            <div>{featureStateBadge}</div>
          </Stack>
          {body}
        </Stack>
        {actionsSection}
      </Stack>
    );

    return (
      <WhiteCard
        direction="row"
        justifyContent="space-between"
        highlighted={highlighted}
        spacing={2}
        alignItems="flex-start"
      >
        {imageComponent && (
          <Box minWidth="150px" maxWidth="150px" display="flex" alignItems="center" justifyContent="center">
            {imageComponent}
          </Box>
        )}
        {cardContent}
      </WhiteCard>
    );
  }
);
