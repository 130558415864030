import { Box, MenuItem, Select, Stack, TextField } from "@mui/material";
import React from "react";
import { Control, FieldValues, Path, useController, useFormState } from "react-hook-form";
import styled from "styled-components";

import { Subtitle2 } from "components/library/typography";
import { useListCurrencyOptionsQuery } from "services/doverapi/endpoints/job/endpoints";
import { colors } from "styles/theme";
import { currencyCodeToSybmol } from "utils/displayJobCompensation";
import { isStringNumber } from "utils/isStringNumber";

interface CompensationFieldProps<T extends FieldValues> {
  control?: Control<T>;
  currencyCodeName: Path<T>;
  compLowerBoundName: Path<T>;
  compUpperBoundName: Path<T>;
}

const CompensationField = <T extends FieldValues>({
  control,
  currencyCodeName,
  compLowerBoundName,
  compUpperBoundName,
}: CompensationFieldProps<T>): React.ReactElement => {
  const { data: currencyOptions } = useListCurrencyOptionsQuery();

  const { field: currencyCodeField } = useController<T>({
    name: currencyCodeName,
    control,
  });

  const { field: compLowerBoundField } = useController({
    name: compLowerBoundName,
    control,
  });

  const { field: compUpperBoundField } = useController({
    name: compUpperBoundName,
    control,
  });

  const { errors } = useFormState({ control });

  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={1} height={{ xs: "auto", sm: "60px" }}>
      <Select
        size="small"
        value={currencyCodeField.value}
        onChange={currencyCodeField.onChange}
        style={{ height: "40px" }}
      >
        {currencyOptions?.map(currencyCode => (
          <MenuItem key={currencyCode} value={currencyCode}>
            {currencyCodeToSybmol(currencyCode)}
          </MenuItem>
        ))}
      </Select>
      <Stack spacing={1}>
        <StyledTextField
          size="small"
          // @ts-ignore
          value={compLowerBoundField.value?.toLocaleString() ?? ""}
          onChange={(e): void => {
            // Remove the commas, these are just for display purposes
            const value = e.target.value.replaceAll(",", "");

            // Make sure the user can only enter numbers
            if (!isStringNumber(value) && value !== "") {
              return;
            }

            // zod is expecting a number so convert to number if possible
            const valAsNumber = parseInt(value);
            const isBlank = value === null || value === "";
            if (isBlank) {
              compLowerBoundField.onChange(null);
            } else if (!isNaN(valAsNumber)) {
              compLowerBoundField.onChange(valAsNumber);
            } else {
              compLowerBoundField.onChange(undefined);
            }
          }}
          error={!!errors.compLowerBound?.message}
        />
        {errors.compLowerBound?.message && (
          <Subtitle2 color={colors.critical.base}>{errors.compLowerBound?.message}</Subtitle2>
        )}
      </Stack>
      <Stack pt="20px" sx={{ display: { xs: "none", sm: "block" } }}>
        <Box width="11px" height="1px" bgcolor={colors.grayscale.gray400} />
      </Stack>
      <Stack spacing={1}>
        <StyledTextField
          size="small"
          // @ts-ignore
          value={compUpperBoundField.value?.toLocaleString() ?? ""}
          onChange={(e): void => {
            // Remove the commas, these are just for display purposes
            const value = e.target.value.replaceAll(",", "");

            // Make sure the user can only enter numbers
            if (!isStringNumber(value) && value !== "") {
              return;
            }

            // zod is expecting a number so convert to number if possible
            const valAsNumber = parseInt(value);
            const isBlank = value === null || value === "";
            if (isBlank) {
              compUpperBoundField.onChange(null);
            } else if (!isNaN(valAsNumber)) {
              compUpperBoundField.onChange(valAsNumber);
            } else {
              compUpperBoundField.onChange(undefined);
            }
          }}
          error={!!errors.compUpperBound?.message}
        />
        {errors.compUpperBound?.message && (
          <Subtitle2 color={colors.critical.base}>{errors.compUpperBound?.message}</Subtitle2>
        )}
      </Stack>
    </Stack>
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    height: 40px;
  }
`;

export default CompensationField;
