import { Switch } from "@mui/material";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { theme } from "styles/theme";

export const Label = styled.div`
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

interface StyledSendIconProps {
  $lighterColor: boolean;
}

export const StyledReactSVG = styled(ReactSVG)<StyledSendIconProps>`
  padding-bottom: 3px;
  padding-right: 8px;
  opacity: ${({ $lighterColor }): number => ($lighterColor ? 0.3 : 1)};
`;

interface SwitchProps {
  $checked: boolean;
}

export const StyledSwitch = styled(Switch)<SwitchProps>`
  position: relative;
  top: -3px;
  margin-left: -11px;
  margin-top: -10px;
  margin-right: -16px;
  width: 58px;

  .MuiSwitch-switchBase.Mui-checked {
    color: ${theme.colors.white};
  }

  .MuiSwitch-track {
    opacity: 1 !important;
    height: 20px;
    width: 32px;
    border-radius: 20px;
    background-color: ${(props): string =>
      props.$checked ? `${props.theme.colors.primary.base} !important` : props.theme.colors.grayscale.gray300};
  }

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
    box-shadow: none;
  }
  .MuiSwitch-switchBase {
    top: 7px;
    margin-left: 7px;
    &.Mui-checked {
      margin-left: -1px;
    }
  }
`;
