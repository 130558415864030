/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PipelineFailureReason
 */
export interface PipelineFailureReason {
    /**
     * 
     * @type {string}
     * @memberof PipelineFailureReason
     */
    readonly value?: PipelineFailureReasonValueEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineFailureReason
     */
    readonly label?: string;
}

/**
* @export
* @enum {string}
*/
export enum PipelineFailureReasonValueEnum {
    FailedToProcess = 'FAILED_TO_PROCESS',
    FailedToFindContactInfo = 'FAILED_TO_FIND_CONTACT_INFO',
    CandidateAlreadyInPipeline = 'CANDIDATE_ALREADY_IN_PIPELINE',
    CandidateAlreadyInAts = 'CANDIDATE_ALREADY_IN_ATS',
    ConnectionOfEmailSender = 'CONNECTION_OF_EMAIL_SENDER',
    OtherEmailSendFailure = 'OTHER_EMAIL_SEND_FAILURE',
    Other = 'OTHER'
}

export function PipelineFailureReasonFromJSON(json: any): PipelineFailureReason {
    return PipelineFailureReasonFromJSONTyped(json, false);
}

export function PipelineFailureReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineFailureReason {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'label': !exists(json, 'label') ? undefined : json['label'],
    };
}

export function PipelineFailureReasonToJSON(value?: PipelineFailureReason | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


