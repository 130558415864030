import { Box, Divider, Grid, FormControlLabel } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import LoadingArrowSVG from "assets/icons/loading-arrow.svg";
import Banner from "components/Banner";
import { StyledSwitch } from "components/library/Switch";
import { Body, BodySmall } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import {
  useGetDeduplicatedAtsSourcesByDisplaySection,
  useGetJobAtsSourceSettingsByJobCandidateSourceSettings,
} from "services/doverapi/endpoints/job-source-settings/customHooks";
import {
  useListJobAtsSourceSettingsQuery,
  useLoadAtsSourcesQuery,
  useSyncAtsSourcesMutation,
  useUpsertJobAtsSourceSettingsMutation,
} from "services/doverapi/endpoints/job-source-settings/endpoints";
import { DedupedAtsSource } from "services/doverapi/endpoints/job-source-settings/types";
import {
  AtsSourceDefaultStateEnum,
  CandidateSource,
  JobAtsSourceSetting,
  JobAtsSourceSettingStateEnum,
  JobCandidateSourceSetting,
  SyncAtsSourcesResponse,
} from "services/openapi";
import { InternalLink } from "styles/typography";
import { showErrorToast } from "utils/showToast";

interface SyncAtsSourceMapping {
  [jobCandidateSourceSettingId: string]: SyncAtsSourcesResponse;
}

function getToggleState({
  atsSourceDefaultState,
  atsSourceSettingState,
}: {
  atsSourceDefaultState: AtsSourceDefaultStateEnum;
  atsSourceSettingState: JobAtsSourceSettingStateEnum | undefined;
}): boolean {
  // We lazily create JobAtsSourceSetting records, so one many not exist yet. If it does, use its state.
  if (atsSourceSettingState === undefined) {
    return atsSourceDefaultState === AtsSourceDefaultStateEnum.Active;
  }

  // Otherwise, fall back to using the default state of the AtsSource itself.
  return atsSourceSettingState === JobAtsSourceSettingStateEnum.Active;
}

const ResyncButton = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const AtsSourceSettingToggle = ({
  atsSource,
  atsSourceSetting,
  jobCandidateSourceSettingId,
  loading,
}: {
  atsSource: DedupedAtsSource;
  atsSourceSetting: JobAtsSourceSetting | undefined;
  jobCandidateSourceSettingId: string;
  loading: boolean;
}): React.ReactElement => {
  const initialIsChecked = getToggleState({
    atsSourceDefaultState: atsSource.defaultState,
    atsSourceSettingState: atsSourceSetting?.state,
  });
  const [isChecked, setIsChecked] = useState<boolean>(initialIsChecked);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(initialIsChecked);
  }, [initialIsChecked]);

  const [upsertJobAtsSourceSetting] = useUpsertJobAtsSourceSettingsMutation();

  const handleToggle = async (currentIsChecked: boolean): Promise<void> => {
    setIsUpdating(true);

    // Optimistically update the toggle state while we wait for the API to resolve.
    setIsChecked(!currentIsChecked);

    try {
      await upsertJobAtsSourceSetting({
        jobCandidateSourceSettingId,
        atsSource: atsSource,
        state: !isChecked ? JobAtsSourceSettingStateEnum.Active : JobAtsSourceSettingStateEnum.Inactive,
      }).unwrap();
    } catch (e) {
      // If the API failed, reset the toggle state back to what it was.
      setIsChecked(currentIsChecked);

      showErrorToast(`Failed to enable the "${atsSource.name}" source. Please try again.`);
    }

    // Rely on new prop values to update isChecked rather than calling setIsChecked here.
    setIsUpdating(false);
  };

  return (
    <FormControlLabel
      control={
        <StyledSwitch
          disabled={loading || isUpdating}
          checked={isChecked}
          onChange={(): Promise<void> => handleToggle(isChecked)}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={atsSource.name}
    />
  );
};

const AtsSourceSettingDisplaySections = ({
  sectionHeader,
  atsSources,
  sourceSettings,
  loading,
}: {
  sectionHeader?: string;
  atsSources: DedupedAtsSource[];
  sourceSettings: JobCandidateSourceSetting;
  loading: boolean;
}): React.ReactElement => {
  const atsSourceSettings = useGetJobAtsSourceSettingsByJobCandidateSourceSettings(sourceSettings);

  return (
    <Grid container style={{ paddingLeft: !sectionHeader ? "12px" : "initial" }}>
      {sectionHeader && (
        <Grid item xs={12}>
          <Body weight="600">{sectionHeader}</Body>
          <Spacer height={8} />
        </Grid>
      )}
      {atsSources.map((atsSource, index) => {
        const atsSourceSetting = find(
          atsSourceSettings,
          atsSourceSettingInfo => atsSourceSettingInfo.atsSource.name === atsSource.name
        );
        return (
          <Grid
            key={atsSourceSetting?.id ?? atsSource.name ?? index}
            item
            xs={4}
            pl={sectionHeader ? "12px" : "initial"}
          >
            <AtsSourceSettingToggle
              key={atsSourceSetting?.id!}
              atsSource={atsSource}
              atsSourceSetting={atsSourceSetting}
              jobCandidateSourceSettingId={sourceSettings.id!}
              loading={loading}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const AtsSourceSettingsList = ({
  source,
  sourceSettings,
}: {
  source: CandidateSource;
  sourceSettings: JobCandidateSourceSetting;
}): React.ReactElement => {
  const [syncAtsSources, { isLoading: isSyncingAtsSources }] = useSyncAtsSourcesMutation();
  const { isFetching: isJobAtsSourceSettingsFetching } = useListJobAtsSourceSettingsQuery();
  const { isFetching: atsSourcesAreFetching } = useLoadAtsSourcesQuery(
    source.atsType ? { atsType: source.atsType } : skipToken
  );

  const isLoading = isJobAtsSourceSettingsFetching || isSyncingAtsSources || atsSourcesAreFetching;

  const [atsSourceMapping, setAtsSourceMapping] = React.useState<SyncAtsSourceMapping>({});
  const [errorSyncingAtsSources, setErrorSyncAtsSources] = React.useState<boolean>(false);

  const dedupedAtsSourcesInfoByDisplaySection = useGetDeduplicatedAtsSourcesByDisplaySection(source.atsType);

  const hasAtsSourcesBySection =
    Object.keys(dedupedAtsSourcesInfoByDisplaySection.atsSourcesByDisplaySection).length > 0;
  const hasAtsSourcesNoSection = dedupedAtsSourcesInfoByDisplaySection.atsSourcesNoDisplaySection.length > 0;

  useEffect(() => {
    const trySyncAtsSources = async (): Promise<void> => {
      if (sourceSettings.id && !atsSourceMapping[sourceSettings.id]?.success) {
        const response = await syncAtsSources({ jobCandidateSourceSetting: sourceSettings }).unwrap();
        setAtsSourceMapping({ ...atsSourceMapping, [sourceSettings.id]: { success: !!response.success } });

        if (!response.success) {
          setErrorSyncAtsSources(true);
        }
      }
    };

    trySyncAtsSources();
  }, [atsSourceMapping, source.atsType, sourceSettings, syncAtsSources]);

  return (
    <div>
      <Spacer height={16} />
      {errorSyncingAtsSources && (
        <Banner type="critical" alignItems="flex-start">
          <Box>
            <BodySmall>
              Unable to sync sources from {source.label!}. Try refreshing the page or contacting{" "}
              <InternalLink to="/get-help">support</InternalLink> for help.
            </BodySmall>
          </Box>
        </Banner>
      )}
      <Box display="flex" alignItems="flex-end">
        <BodySmall>
          Dover will pull and score new applications with any of the sources selected below. All inbound sources are
          enabled by default.
        </BodySmall>
      </Box>
      <Box display="flex" alignItems="flex-end">
        <BodySmall>{`Don't see a source?`}</BodySmall>
        <Spacer width={8} />
        <ResyncButton
          onClick={(): void => {
            syncAtsSources({ jobCandidateSourceSetting: sourceSettings });
          }}
        >
          {LoadingArrowSVG && <ReactSVG src={LoadingArrowSVG} />}
          <Spacer width={2} />
          <BodySmall weight="500">Re-Sync Sources</BodySmall>
        </ResyncButton>
      </Box>
      <Spacer height={24} />
      <Grid container spacing={1}>
        {hasAtsSourcesBySection &&
          Object.keys(dedupedAtsSourcesInfoByDisplaySection.atsSourcesByDisplaySection).map(displaySection => {
            return (
              <AtsSourceSettingDisplaySections
                key={displaySection}
                sectionHeader={displaySection}
                atsSources={dedupedAtsSourcesInfoByDisplaySection.atsSourcesByDisplaySection[displaySection]}
                sourceSettings={sourceSettings}
                loading={isLoading}
              />
            );
          })}
        {hasAtsSourcesBySection && hasAtsSourcesNoSection && (
          <Grid item xs={12}>
            <Spacer height={8} />
            <Divider />
            <Spacer height={16} />
          </Grid>
        )}
        {hasAtsSourcesNoSection && (
          <AtsSourceSettingDisplaySections
            atsSources={dedupedAtsSourcesInfoByDisplaySection.atsSourcesNoDisplaySection}
            sourceSettings={sourceSettings}
            loading={isLoading}
          />
        )}
      </Grid>
      <DoverLoadingOverlay active={isLoading} minHeight="auto" fullScreen={isLoading} />
    </div>
  );
};
