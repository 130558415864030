import { createSelector } from "@reduxjs/toolkit";

import { setupHiringPipelineReducerKey } from "components/SetupHiringPipeline/constants";
import { initialState } from "components/SetupHiringPipeline/reducer";
import { HiringPipelineState } from "components/SetupHiringPipeline/types";
import { selectGlobalState } from "domains/global/selectors";
import { GlobalRootState } from "domains/global/types";
import { LoadableEntity } from "domains/types";
import { jobEndpoints } from "services/doverapi/endpoints/job";
import { isDoverApiError } from "services/doverapi/types";
import { JobSetup, SyncAndListJobAtsStages } from "services/openapi";

const selectState = (state: GlobalRootState): HiringPipelineState =>
  state[setupHiringPipelineReducerKey] || initialState;

export const selectIncludesTakeHomeStage = createSelector<GlobalRootState, HiringPipelineState, boolean>(
  [selectState],
  state => state.includesTakeHomeStage
);

export const selectATSSettingsDismissed = createSelector<GlobalRootState, HiringPipelineState, boolean>(
  [selectState],
  state => state.atsSettingsDismissed
);

export const selectJobId = createSelector<GlobalRootState, HiringPipelineState, string | undefined>(
  [selectState],
  state => {
    return state.jobId;
  }
);

export const selectJobSetup = createSelector([selectGlobalState, selectJobId], (globalState, jobId):
  | JobSetup
  | undefined => {
  if (!jobId) {
    return undefined;
  }

  // Make use of RTKQ's state. This selector acts as a thin wrapper so that it's easy to use as
  // a dependency for other selectors.
  const { data } = jobEndpoints.endpoints.getJobSetup.select(jobId)(globalState);
  return data;
});

export const selectAtsStagesInfo = createSelector(
  [selectGlobalState, selectJobId],
  (globalState, jobId): LoadableEntity<SyncAndListJobAtsStages> => {
    if (!jobId) {
      return {
        initialized: false,
        loading: false,
        loadingError: null,
        data: undefined,
      };
    }

    // Make use of RTKQ's state. This selector acts as a thin wrapper so that it's easy to use as
    // a dependency for other selectors.
    // However, we should look for calls with either triggerResyncOverride === true or false.
    const {
      data: triggeredResyncData,
      status: triggeredResyncStatus,
      error: triggeredResyncError,
    } = jobEndpoints.endpoints.syncAndListJobAtsStages.select({
      jobId,
      triggerResyncOverride: true,
    })(globalState);

    const {
      data: didntTriggerResyncData,
      status: didntTriggerResyncStatus,
      error: didntTriggerResyncError,
    } = jobEndpoints.endpoints.syncAndListJobAtsStages.select({
      jobId,
    })(globalState);

    // Prioritize data from api calls where we triggered a resync forcefully.
    const data = triggeredResyncData ?? didntTriggerResyncData;
    const status = triggeredResyncStatus === "uninitialized" ? didntTriggerResyncStatus : triggeredResyncStatus;
    const error = triggeredResyncError ?? didntTriggerResyncError;

    return {
      initialized: data !== undefined,
      loading: status === "pending",
      loadingError: isDoverApiError(error) ? error?.serializedError : error ?? null,
      data: data,
    };
  }
);
