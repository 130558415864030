import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { ReactComponent as ChromeSVG } from "assets/icons/chrome.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link.svg";
import RecruitingCopilotImage from "assets/images/recruiting-copilot.png";
import { Button, ButtonVariant } from "components/library/Button";
import { GradientBackground } from "components/library/GradientBackground";
import { Body, Heading, TitleLarge } from "components/library/typography";
import { colors, screenSizesNumbers } from "styles/theme";

const GOOGLE_CHROME_EXTENSION_URL =
  "https://chrome.google.com/webstore/detail/dover/ekmeakdlmfdpidkmifdcgaekafojmfch/related?hl=en";

const SourcingCopilot = (): React.ReactElement => {
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tabletL));

  return (
    <Stack p={3}>
      <SplashContent
        spacing={2}
        firstColor={colors.primary.light}
        secondColor={colors.white}
        width="100%"
        direction={isSmallScreen ? "column" : "row"}
        justifyContent="space-between"
      >
        <Stack
          spacing={2}
          alignItems="baseline"
          width={isSmallScreen ? undefined : "470px"}
          minWidth={isSmallScreen ? undefined : "250px"}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Heading>Sourcing Copilot Chrome Extension</Heading>
            <ChromeSVG style={{ width: "24px", height: "24px", minWidth: "24px" }} />
          </Stack>
          <TitleLarge
            style={{
              fontSize: "32px",
              fontWeight: 800,
            }}
          >
            Effortlessly reach out to top candidates
          </TitleLarge>
          <ul>
            <li>
              <Body>Send personalized outreach to candidates you find on LinkedIn with a few clicks</Body>
            </li>
            <li>
              <Body>Use our AI-powered email generation to craft emails that will stand out to the candidate</Body>
            </li>
          </ul>
          <Button
            variant={ButtonVariant.Primary}
            onClick={(): void => {
              window.open(GOOGLE_CHROME_EXTENSION_URL, "_blank", "noopener noreferrer");
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <ExternalLinkIcon color={colors.white} className="svg-color" width="16px" />
              <Body color={colors.white}>Download</Body>
            </Stack>
          </Button>
        </Stack>
        <img
          src={RecruitingCopilotImage}
          style={{ width: "100%", maxWidth: isSmallScreen ? "350px" : "400px", objectFit: "contain" }}
        />
      </SplashContent>
    </Stack>
  );
};

const SplashContent = styled(GradientBackground)`
  padding: 32px 24px;
  border-radius: 13px;
  border: 1px solid ${colors.grayscale.gray200};
`;

export default SourcingCopilot;
