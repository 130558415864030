import { Box, Grid } from "@mui/material";
import styled from "styled-components";

import { colors } from "styles/theme";

export const SubstageHeader = styled(Box)`
  text-transform: uppercase;
  font-size: 12px;
  color: ${colors.grayscale.gray600};
`;

export const SubstageHeaderContainer = styled(Grid)`
  width: 100%;
  padding-left: 30px;
  margin-bottom: 8px;
`;
