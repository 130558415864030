import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as ClipboardIconSVG } from "assets/icons/clipboard.svg";
import ComboButton, { ComboButtonVariant } from "components/library/combo-button/ComboButton";
import { Subtitle2 } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { useModal } from "GlobalOverlays/atoms";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { colors } from "styles/theme";
import { inviteReferrerModalAtom } from "views/referralsV2/InviteReferrerModal";
import { ReferrersTable } from "views/referralsV2/ReferrersTable";
import { copyReferralLink } from "views/referralsV2/utils";

/* -----------------------------------------------------------------------------
 * PrimaryView
 * -------------------------------------------------------------------------- */

const JobReferralsPage = (): React.ReactElement => {
  const clientId = useGetClientId();

  const { open } = useModal(inviteReferrerModalAtom);

  return (
    <Stack maxWidth="800px" margin="0 auto" spacing={1}>
      <PageHelmet title="Referrals" />
      {/* header */}
      <Stack paddingTop={2} direction="row" justifyContent="space-between" alignItems="center">
        <Subtitle2>Referrals</Subtitle2>
        <Stack direction="row" spacing={1}>
          <ComboButton
            variant={ComboButtonVariant.Primary}
            onClick={(): void => {
              open({});
            }}
            label={"+ Invite"}
            overflowActions={[
              {
                icon: <ClipboardIconSVG className="svg-color" color={colors.brand} />,
                label: "Copy referral form link",
                onClick: (): void => {
                  copyReferralLink({
                    clientId,
                  });
                },
              },
            ]}
          />
        </Stack>
      </Stack>
      <ReferrersTable />
    </Stack>
  );
};

export default JobReferralsPage;
