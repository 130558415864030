/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkCandidateActionEmailArgs
 */
export interface BulkCandidateActionEmailArgs {
    /**
     * 
     * @type {string}
     * @memberof BulkCandidateActionEmailArgs
     */
    emailAlias: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkCandidateActionEmailArgs
     */
    ccEmails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkCandidateActionEmailArgs
     */
    bccEmails: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BulkCandidateActionEmailArgs
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof BulkCandidateActionEmailArgs
     */
    body: string;
}

export function BulkCandidateActionEmailArgsFromJSON(json: any): BulkCandidateActionEmailArgs {
    return BulkCandidateActionEmailArgsFromJSONTyped(json, false);
}

export function BulkCandidateActionEmailArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCandidateActionEmailArgs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAlias': json['email_alias'],
        'ccEmails': json['cc_emails'],
        'bccEmails': json['bcc_emails'],
        'subject': json['subject'],
        'body': json['body'],
    };
}

export function BulkCandidateActionEmailArgsToJSON(value?: BulkCandidateActionEmailArgs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_alias': value.emailAlias,
        'cc_emails': value.ccEmails,
        'bcc_emails': value.bccEmails,
        'subject': value.subject,
        'body': value.body,
    };
}


