import { TableCell, TableContainer, TableRow } from "@mui/material";
import styled from "styled-components";

import { theme } from "styles/theme";

const colors = {
  tableContainerBackgroundColor: "white",
  evenRowBackgroundColor: theme.colors.grayscale.gray100,
  oddRowBackgroundColor: "white",
  headerRowBackgroundColor: "white",
};

export const StyledTableContainer = styled(TableContainer)`
  max-height: 330px;
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: ${colors.evenRowBackgroundColor};
  }
  &:nth-of-type(odd) {
    background-color: ${colors.oddRowBackgroundColor};
  }
`;

export const StyledHeaderTableCell = styled(TableCell)`
  padding: 9px;
  font-size: 16px;
  font-family: "Inter";
  font-weight: normal;
  background-color: ${colors.headerRowBackgroundColor};
`;

export const StyledTableCell = styled(TableCell)`
  border-bottom: 0px solid;
  padding: 9px;
  font-size: 16px;
  font-family: "Inter";
`;
