import { z } from "zod";

import { PersonaSchema } from "views/sourcing/Search/types";
import {
  IndividualCompanySchema,
  CompanySizesSchema,
  IndustrySchema,
  KeywordsSchema,
  LocationSchema,
  MinMaxRangeSchema,
} from "views/sourcing/Search/types";

export const InboundCriteriaSetupFormSchema = z.object({
  persona: PersonaSchema,
  locations: z.array(LocationSchema),
  yearsOfExperience: MinMaxRangeSchema,
  keywords: z.array(KeywordsSchema),
  companySizes: z.array(CompanySizesSchema),
  industries: z.object({ industryElements: z.array(IndustrySchema) }),
  targetCompanies: z.array(IndividualCompanySchema),
});

export type InboundCriteriaSetupFormValues = z.infer<typeof InboundCriteriaSetupFormSchema>;
