/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDoverOutboundConfiguration200Response
 */
export interface GetDoverOutboundConfiguration200Response {
    /**
     * 
     * @type {number}
     * @memberof GetDoverOutboundConfiguration200Response
     */
    phoneScreensPerWeek: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetDoverOutboundConfiguration200Response
     */
    emailsPerWeekOption: number;
    /**
     * 
     * @type {number}
     * @memberof GetDoverOutboundConfiguration200Response
     */
    emailsPerWeek: number;
    /**
     * 
     * @type {string}
     * @memberof GetDoverOutboundConfiguration200Response
     */
    shouldEngagePassive: GetDoverOutboundConfiguration200ResponseShouldEngagePassiveEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetDoverOutboundConfiguration200Response
     */
    autoQueueOutreach: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetDoverOutboundConfiguration200Response
     */
    similarCandidatesSourcingTarget: number;
    /**
     * 
     * @type {number}
     * @memberof GetDoverOutboundConfiguration200Response
     */
    interestedRate: number | null;
}

/**
* @export
* @enum {string}
*/
export enum GetDoverOutboundConfiguration200ResponseShouldEngagePassiveEnum {
    All = 'All',
    InterestedInRole = 'Interested In Role',
    ActiveOnly = 'Active Only'
}

export function GetDoverOutboundConfiguration200ResponseFromJSON(json: any): GetDoverOutboundConfiguration200Response {
    return GetDoverOutboundConfiguration200ResponseFromJSONTyped(json, false);
}

export function GetDoverOutboundConfiguration200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDoverOutboundConfiguration200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneScreensPerWeek': json['phone_screens_per_week'],
        'emailsPerWeekOption': json['emails_per_week_option'],
        'emailsPerWeek': json['emails_per_week'],
        'shouldEngagePassive': json['should_engage_passive'],
        'autoQueueOutreach': json['auto_queue_outreach'],
        'similarCandidatesSourcingTarget': json['similar_candidates_sourcing_target'],
        'interestedRate': json['interested_rate'],
    };
}

export function GetDoverOutboundConfiguration200ResponseToJSON(value?: GetDoverOutboundConfiguration200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone_screens_per_week': value.phoneScreensPerWeek,
        'emails_per_week_option': value.emailsPerWeekOption,
        'emails_per_week': value.emailsPerWeek,
        'should_engage_passive': value.shouldEngagePassive,
        'auto_queue_outreach': value.autoQueueOutreach,
        'similar_candidates_sourcing_target': value.similarCandidatesSourcingTarget,
        'interested_rate': value.interestedRate,
    };
}


