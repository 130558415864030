import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";

import RoundCheckboxFilledSVG from "assets/icons/round-checkbox-filled.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { useUpdateJobSetupMutation } from "services/doverapi/endpoints/job";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { colors } from "styles/theme";
import { B1_doNotUse, B2_doNotUse } from "styles/typography";
import { StyledSwitch } from "views/job/JobSetup/steps/Overview/styles";

const PipelineReviewModal = ({
  isModalOpen,
  toggleModalOff,
}: {
  isModalOpen: boolean;
  toggleModalOff: () => void;
}): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();

  const [updateJobSetup, { isLoading: jobSetupIsUpdating }] = useUpdateJobSetupMutation();
  const { data: jobSetup, isFetching: jobSetupIsFetching } = useGetJobSetupQuery(jobId || skipToken);

  const [pipelineReviewChecked, setPipelineReviewChecked] = useState<boolean>(!!jobSetup?.pipelineReviewCallsEnabled);

  const onClickSubmit = React.useCallback(() => {
    const tryUpdate = async (): Promise<void> => {
      if (!jobId) {
        return;
        console.error("job id not defined");
      }

      if (jobSetup?.pipelineReviewCallsEnabled !== pipelineReviewChecked) {
        await updateJobSetup({ id: jobId, pipelineReviewCallsEnabled: pipelineReviewChecked });
      }

      toggleModalOff();
    };

    tryUpdate();
  }, [jobId, jobSetup?.pipelineReviewCallsEnabled, pipelineReviewChecked, toggleModalOff, updateJobSetup]);

  const handleTogglePipelineReviewEnabled = (): void => {
    setPipelineReviewChecked(!pipelineReviewChecked);
  };

  const isLoading = jobSetupIsFetching || jobSetupIsUpdating || !jobId;

  // Make sure to update when jobSetup loads
  useEffect(() => {
    if (jobSetup?.pipelineReviewCallsEnabled) {
      setPipelineReviewChecked(true);
    } else {
      setPipelineReviewChecked(false);
    }
  }, [jobSetup?.pipelineReviewCallsEnabled]);

  return (
    <CustomModal
      open={isModalOpen}
      onClose={toggleModalOff}
      title={<B1_doNotUse bold>Pipeline Review Meetings</B1_doNotUse>}
      maxWidth={"sm"}
      customDialogStyles={{ zIndex: 9999 }}
      customContentStyles={{ padding: "0px" }}
      dialogActions={
        <Stack padding="6px 8px" width="100%" direction="row" justifyContent="flex-end">
          <Button variant={ButtonVariant.Primary} onClick={onClickSubmit} loading={isLoading}>
            Submit
          </Button>
        </Stack>
      }
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <DoverLoadingSpinner minHeight="200px" spinnerSize="64px" width="64px" height="200px" />
        </Box>
      ) : (
        <Box padding="20px 24px 16px">
          <Stack spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <B1_doNotUse>
                {
                  "Meet regularly with your dedicated Customer Experience Partner to review your pipeline and take action on candidates. Meetings can happen at a cadence of your choosing."
                }
              </B1_doNotUse>
              <Stack direction="row" spacing={1} alignItems="center">
                <StyledSwitch
                  checked={pipelineReviewChecked}
                  onChange={handleTogglePipelineReviewEnabled}
                  $checked={pipelineReviewChecked}
                />
                <B2_doNotUse
                  style={{ position: "relative", bottom: "4px" }}
                  $color={pipelineReviewChecked ? colors.success.base : colors.grayscale.gray700}
                >
                  {pipelineReviewChecked ? "On" : "Off"}
                </B2_doNotUse>
              </Stack>
            </Stack>
            {pipelineReviewChecked && (
              <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                <ReactSVG style={{ position: "relative", bottom: "2px" }} src={RoundCheckboxFilledSVG} />
                <B2_doNotUse>Your customer experience partner will reach out to schedule time with you.</B2_doNotUse>
              </Stack>
            )}
          </Stack>
        </Box>
      )}
    </CustomModal>
  );
};

export default PipelineReviewModal;
