import React from "react";
import styled from "styled-components";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

interface StyledButtonProps {
  $active?: boolean;
  $disabled?: boolean;
  $width?: string;
}

const StyledButtonRadio = styled.div<StyledButtonProps>`
  position: relative;
  box-sizing: border-box;
  padding: 8px 16px;
  color: ${colors.grayscale.gray600};
  background-color: ${({ $active, $disabled }): string =>
    $disabled ? colors.grayscale.gray300 : $active ? colors.primary.light : "transparent"};
  border: 1px solid;
  border-color: ${({ $active }): string => ($active ? colors.primary.base : colors.grayscale.gray300)};
  border-radius: 4px;
  cursor: pointer;
  width: ${({ $width }): string => ($width ? $width : "100%")};
  text-align: center;

  ${({ $disabled }): string | undefined => ($disabled ? "pointer-events: none;" : "")}

  &:hover {
    background-color: ${colors.primary.light};
  }
`;

const SecondaryButtonRadio = styled.div<StyledButtonProps>`
  position: relative;
  box-sizing: border-box;
  padding: 8px 16px;
  background-color: ${({ $active, $disabled }): string =>
    $disabled ? colors.white : $active ? colors.grayscale.gray050 : "transparent"};
  color: ${({ $active, $disabled }): string =>
    $disabled ? colors.grayscale.gray300 : $active ? colors.primary.light : "transparent"};
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  width: ${({ $width }): string => ($width ? $width : "100%")};
  text-align: center;
  border-color: ${colors.grayscale.gray300};
  ${({ $disabled }): string | undefined => ($disabled ? "pointer-events: none;" : "")}

  &:hover {
    background-color: ${colors.grayscale.gray050};
  }
`;

interface ButtonRadioProps {
  active: boolean;
  label: string | React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  width?: string;
  variant?: "primary" | "secondary";
}

export const ButtonRadio = ({
  active,
  label,
  onClick,
  disabled,
  width,
  variant = "primary",
}: ButtonRadioProps): React.ReactElement => {
  if (variant === "secondary") {
    return (
      <SecondaryButtonRadio $active={active} onClick={onClick} $disabled={disabled} $width={width}>
        <BodySmall weight="500" color={active ? colors.primary.base : colors.grayscale.gray600}>
          {label}
        </BodySmall>
      </SecondaryButtonRadio>
    );
  }

  return (
    <StyledButtonRadio $active={active} onClick={onClick} $disabled={disabled} $width={width}>
      <BodySmall weight="500">{label}</BodySmall>
    </StyledButtonRadio>
  );
};
