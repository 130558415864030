/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtsCandidateManualSourcing,
    AtsCandidateManualSourcingFromJSON,
    AtsCandidateManualSourcingFromJSONTyped,
    AtsCandidateManualSourcingToJSON,
} from './';

/**
 * 
 * @export
 * @interface FindAtsCandidateResponse
 */
export interface FindAtsCandidateResponse {
    /**
     * 
     * @type {AtsCandidateManualSourcing}
     * @memberof FindAtsCandidateResponse
     */
    atsCandidate?: AtsCandidateManualSourcing;
}

export function FindAtsCandidateResponseFromJSON(json: any): FindAtsCandidateResponse {
    return FindAtsCandidateResponseFromJSONTyped(json, false);
}

export function FindAtsCandidateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindAtsCandidateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'atsCandidate': !exists(json, 'ats_candidate') ? undefined : AtsCandidateManualSourcingFromJSON(json['ats_candidate']),
    };
}

export function FindAtsCandidateResponseToJSON(value?: FindAtsCandidateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ats_candidate': AtsCandidateManualSourcingToJSON(value.atsCandidate),
    };
}


