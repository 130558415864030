import pick from "lodash/pick";

import {
  HiringStageEmailTemplate,
  HiringStageEmailTemplateStateEnum,
  HiringStageEmailTemplateTemplateTypeEnum,
} from "services/openapi";

// Why are these hardcoded?
export const templateTypes = [
  { value: HiringStageEmailTemplateTemplateTypeEnum.Scheduling, label: "Scheduling" },
  {
    value: HiringStageEmailTemplateTemplateTypeEnum.SchedulingDoverInterviewer,
    label: "Scheduling (Dover Interviewer)",
  },
  {
    value: HiringStageEmailTemplateTemplateTypeEnum.SchedulingTakeHome,
    label: "Scheduling (Take Home)",
  },
  { value: HiringStageEmailTemplateTemplateTypeEnum.SchedulingMoveJob, label: "Scheduling (Move Job)" },
  {
    value: HiringStageEmailTemplateTemplateTypeEnum.SchedulingMoveJobDoverInterviewer,
    label: "Scheduling (Move Job - Dover Interviewer)",
  },
  { value: HiringStageEmailTemplateTemplateTypeEnum.SchedulingInbound, label: "Scheduling (Inbound)" },
  {
    value: HiringStageEmailTemplateTemplateTypeEnum.SchedulingInboundDoverInterviewer,
    label: "Scheduling (Inbound - Dover Interviewer)",
  },
  {
    value: HiringStageEmailTemplateTemplateTypeEnum.SchedulingInboundMoveJob,
    label: "Scheduling (Inbound - Move Job)",
  },
  {
    value: HiringStageEmailTemplateTemplateTypeEnum.SchedulingInboundMoveJobDoverInterviewer,
    label: "Scheduling (Inbound - Move Job - Dover Interviewer)",
  },
  {
    value: HiringStageEmailTemplateTemplateTypeEnum.Rejection,
    label: "Rejection",
  },
  {
    value: HiringStageEmailTemplateTemplateTypeEnum.RejectionInbound,
    label: "Rejection (Inbound)",
  },
];

const fields = [
  "id",
  "name",
  "hiringPipelineStage",
  "requiredInterviewer",
  "requiredEmailAlias",
  "hasSameInterviewerAndEmailSender",
  "userEditingDisabled",
  "subjectTemplate",
  "bodyTemplate",
  "templateType",
  "ccEmails",
  "bccEmails",
  "state",
];

const defaultInitialValues = {
  name: "",
  hiringPipelineStage: "",
  requiredInterviewer: "",
  requiredEmailAlias: "",
  hasSameInterviewerAndEmailSender: true,
  userEditingDisabled: false,
  subjectTemplate: "",
  bodyTemplate: "",
  templateType: "",
  ccEmails: [],
  bccEmails: [],
  state: HiringStageEmailTemplateStateEnum.Active,
};

export function getInitialValuesFromEmailTemplate(
  emailTemplate?: HiringStageEmailTemplate
): typeof defaultInitialValues {
  const pickedFields = pick(emailTemplate, fields);
  if (!pickedFields.requiredEmailAlias) {
    pickedFields.requiredEmailAlias = "";
  }
  if (!pickedFields.requiredInterviewer) {
    pickedFields.requiredInterviewer = "";
  }
  return Object.assign({}, defaultInitialValues, pickedFields);
}
