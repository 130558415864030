import { Divider } from "@mui/material";
import { Switches } from "mui-rff";
import styled from "styled-components";

import { colors } from "styles/theme";

export const ListItem = styled.li`
  position: relative;
  margin-left: 40px;
  counter-increment: custom-counter;
  list-style: none;

  &::before {
    position: absolute;
    left: -40px;
    content: counter(custom-counter);
    font-size: 13px;
    color: #355966;
    width: 25px;
    height: 25px;
    border: 1px solid #ccc;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const StyledSwitches = styled(Switches)`
  position: relative;
  top: -3px;
  margin-left: -2px;
  margin-top: -7px;
  margin-right: -6px;
  width: 58px;

  .MuiSwitch-switchBase.Mui-checked {
    color: white;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${colors.primary.base};
  }

  .MuiSwitch-track {
    opacity: 1 !important;
    height: 20px;
    width: 32px;
    border-radius: 20px;
    background-color: ${colors.grayscale.gray300};
  }

  .MuiSwitch-thumb {
    width: 12px;
    height: 12px;
    box-shadow: none;
  }
  .MuiSwitch-switchBase {
    top: 7px;
    margin-left: 7px;
    &.Mui-checked {
      margin-left: -1px;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0px -24px;
`;
