/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontClient
 */
export interface FrontClient {
    /**
     * 
     * @type {string}
     * @memberof FrontClient
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontClient
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FrontClient
     */
    doverPlan?: FrontClientDoverPlanEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FrontClient
     */
    useReplyToSchedulingOverride?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FrontClient
     */
    emailProvider?: FrontClientEmailProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum FrontClientDoverPlanEnum {
    NoAccess = 'NO_ACCESS',
    Free = 'FREE',
    Credits = 'CREDITS',
    PayAsYouGo = 'PAY_AS_YOU_GO',
    JobSlots = 'JOB_SLOTS',
    Growth = 'GROWTH',
    Concierge = 'CONCIERGE',
    FreeAts = 'FREE_ATS',
    FreeExtension = 'FREE_EXTENSION'
}/**
* @export
* @enum {string}
*/
export enum FrontClientEmailProviderEnum {
    Gmail = 'GMAIL',
    Outlook = 'OUTLOOK',
    Other = 'OTHER'
}

export function FrontClientFromJSON(json: any): FrontClient {
    return FrontClientFromJSONTyped(json, false);
}

export function FrontClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'doverPlan': !exists(json, 'dover_plan') ? undefined : json['dover_plan'],
        'useReplyToSchedulingOverride': !exists(json, 'use_reply_to_scheduling_override') ? undefined : json['use_reply_to_scheduling_override'],
        'emailProvider': !exists(json, 'email_provider') ? undefined : json['email_provider'],
    };
}

export function FrontClientToJSON(value?: FrontClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dover_plan': value.doverPlan,
        'use_reply_to_scheduling_override': value.useReplyToSchedulingOverride,
        'email_provider': value.emailProvider,
    };
}


