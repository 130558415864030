import { z } from "zod";

export type RoleTitleData = { article: string; title: string; pronouns: string };

// Marking these fields optional on the schema since they're nullable on pro users,
// and it simplifies how we default values
export const EditProUserFormSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  roleTitle: z.string().optional(),
  roleId: z.string().optional(),
});

export type EditProUserFormSchemaType = z.infer<typeof EditProUserFormSchema>;
