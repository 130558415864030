import { Box } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import LinkIconSVG from "assets/icons/external-link.svg";
import Accordion from "components/accordion";
import FormRenderer, { NoSubmitButtonFormTemplate } from "components/data-driven-forms";
import { READ_ONLY_MAPPER } from "components/data-driven-forms/component-mapper";
import { Spacer } from "components/Spacer";
import { B1_doNotUse, InternalLink } from "styles/typography";

interface Props {
  companyBioSchema: any;
  companyBioValues: any;
}

const ReadOnlyCompanyPitch = ({ companyBioSchema, companyBioValues }: Props): React.ReactElement => {
  return (
    <Accordion title="View responses" titleWhenOpen="Hide responses">
      <>
        <Spacer height="32px" />

        <FormRenderer
          schema={companyBioSchema}
          onSubmit={(): void => {}}
          initialValues={companyBioValues}
          componentMapper={READ_ONLY_MAPPER}
          CustomFormTemplate={NoSubmitButtonFormTemplate}
        />
        <Box display="flex" alignItems="flex-end">
          <ReactSVG src={LinkIconSVG} />
          <Spacer width="12px" />
          <B1_doNotUse>
            <InternalLink to="/setup/company/biography" target="_blank">
              Edit in Settings
            </InternalLink>
          </B1_doNotUse>
        </Box>
      </>
    </Accordion>
  );
};

export default ReadOnlyCompanyPitch;
