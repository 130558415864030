import { Stack } from "@mui/material";
import { useSetAtom } from "jotai";
import React from "react";

import { ReactComponent as RPUpsellImage } from "assets/images/rp-upsell.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Heading } from "components/library/typography";
import { colors } from "styles/theme";
import { resetNumActionedCandidatesAtom } from "views/candidates/ApplicationReview/hooks/useSaapNumActioned";

const RecruitingPartnerUpsell = (): React.ReactElement => {
  const reset = useSetAtom(resetNumActionedCandidatesAtom);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      spacing={2}
      px={1}
      sx={{
        background: "linear-gradient(rgba(106, 204, 175, 0.1) 10%, rgba(53, 102, 87, 0) 100%)",
      }}
    >
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <RPUpsellImage />
        <Heading>Tired of reviewing resumes?</Heading>
        <Body color={colors.grayscale.gray500}>Let a Dover Recruiting Partner handle it for you!</Body>
        <Stack direction="row" spacing={2}>
          <Button
            variant={ButtonVariant.SecondaryLight}
            onClick={(): void => {
              reset();
            }}
          >
            Skip
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            onClick={(): void => {
              window.open("https://www.dover.com/recruiter", "_blank");
            }}
          >
            Learn More
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RecruitingPartnerUpsell;
