import { Node } from "@tiptap/core";

/**
 * A node that represents a single line of text.
 */
export const OneLiner = Node.create({
  name: "oneLiner",
  topNode: true,
  content: "block",
});
