import { BillingSessionPathEnum } from "App/routing/types";
import { CompleteCheckoutRequestBillingSessionTypeEnum } from "services/openapi";

export const StripeSessionIdQueryParam = "stripeSessionId";
export const redirectURL = "redirectUrl";

export const BILLING_SESSION_PATH_TO_SESSION_TYPE_MAP = {
  [BillingSessionPathEnum.JobBoards]: CompleteCheckoutRequestBillingSessionTypeEnum.JobBoards,
  [BillingSessionPathEnum.DefaultPaymentMethod]: CompleteCheckoutRequestBillingSessionTypeEnum.DefaultPaymentMethod,
};
