import { Box } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import ClipboardIconSVG from "assets/icons/clipboard.svg";
import ReadOnlyCompanyPitch from "components/dover/JobPitch/ReadOnlyCompanyPitch";
import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { Spacer } from "components/Spacer";
import { useGetCompanySetupStepsQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetCompanyPitchQuestionsQuery } from "services/doverapi/endpoints/company-setup/endpoints";
import { getCompanySetupStepMap } from "services/doverapi/endpoints/company-setup/utils";
import { CompanySetupStepStateEnum, CompanySetupStepStepNameEnum } from "services/openapi/models";
import { CardHeader, CardSubheader, InternalLink } from "styles/typography";
import { FormCard } from "views/job/styles";

const CompanyPitchCard = ({
  fullWidth,
  hideHelpText = false,
}: {
  fullWidth?: boolean;
  hideHelpText?: boolean;
}): React.ReactElement => {
  const { data: companyPitchQuestions, isFetching: companyPitchQuestionsLoading } = useGetCompanyPitchQuestionsQuery(
    {}
  );
  const { data: companySetupSteps, isLoading: companySetupLoading } = useGetCompanySetupStepsQuery();
  let companyPitchComplete = false;
  if (companySetupSteps) {
    const companyStepNameToInfoMap = getCompanySetupStepMap(companySetupSteps);
    companyPitchComplete = [
      CompanySetupStepStateEnum.Complete,
      CompanySetupStepStateEnum.StartedPendingDoverAction,
    ].includes(companyStepNameToInfoMap[CompanySetupStepStepNameEnum.PitchState].state);
  }

  const loading = companySetupLoading || companyPitchQuestionsLoading;

  const showReadOnlyForm = !loading && companyPitchComplete && companyPitchQuestions;

  return (
    <FormCard $fullWidth={fullWidth}>
      <Box display="flex" alignItems="flex-end">
        <ReactSVG src={ClipboardIconSVG} />
        <Spacer width="8px" />
        <CardHeader>Company Pitch</CardHeader>
      </Box>
      {hideHelpText ? (
        <Spacer height="32px" />
      ) : (
        <>
          <Spacer height="16px" />
          <CardSubheader>
            Your company mission, and what makes it exciting (e.g. funding, traction, customers).
          </CardSubheader>
          <Spacer height="16px" />
        </>
      )}
      {loading && <DoverLoadingSpinner />}
      {!loading && !companyPitchComplete && (
        <Banner variant={BannerVariant.Warning}>
          <BodySmall>
            Please complete your{" "}
            <InternalLink to="/setup/company/biography" target="_blank">
              company pitch
            </InternalLink>{" "}
            so Dover can generate your outreach messaging
          </BodySmall>
        </Banner>
      )}
      {showReadOnlyForm && (
        <>
          <Banner variant={BannerVariant.Info}>
            <BodySmall>
              Please review your company pitch responses below. These responses will be used by Dover Interviewers to
              pitch the opportunity to candidates.
            </BodySmall>
          </Banner>
          <Spacer height="16px" />
          <ReadOnlyCompanyPitch
            companyBioSchema={companyPitchQuestions?.schema}
            companyBioValues={companyPitchQuestions?.answers}
          />
        </>
      )}
    </FormCard>
  );
};

export default CompanyPitchCard;
