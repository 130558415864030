/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontContact
 */
export interface FrontContact {
    /**
     * 
     * @type {string}
     * @memberof FrontContact
     */
    readonly id?: string;
    /**
     * The full name of the Contact.
     * @type {string}
     * @memberof FrontContact
     */
    fullName: string;
    /**
     * The name the Contact should be addressed by. Used in communications.
     * @type {string}
     * @memberof FrontContact
     */
    preferredName: string;
    /**
     * 
     * @type {string}
     * @memberof FrontContact
     */
    linkedinUrl?: string;
}

export function FrontContactFromJSON(json: any): FrontContact {
    return FrontContactFromJSONTyped(json, false);
}

export function FrontContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': json['full_name'],
        'preferredName': json['preferred_name'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
    };
}

export function FrontContactToJSON(value?: FrontContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full_name': value.fullName,
        'preferred_name': value.preferredName,
        'linkedin_url': value.linkedinUrl,
    };
}


