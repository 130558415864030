import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { FAKE_ID, LIST_TAG, NOTIFICATION_CONFIG } from "services/doverapi/endpointTagsConstants";
import { performOptimisticUpdateOnEntity } from "services/doverapi/mutationUtils";
import { NotificationConfig } from "services/openapi";
import { DjangoListResponseType } from "types";
import { showErrorToast } from "utils/showToast";

const notificationConfigAdapter = createEntityAdapter<NotificationConfig>();

const notificationConfig = doverApi.injectEndpoints({
  endpoints: build => ({
    getNotificationConfigs: build.query<EntityState<NotificationConfig>, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DjangoListResponseType<NotificationConfig>;
        try {
          response = await client.listNotificationConfig({ limit: 1000, offset: 0 });
        } catch (error) {
          const userFacingMessage = "Failed to load notification preferences. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return {
          data: notificationConfigAdapter.addMany(notificationConfigAdapter.getInitialState(), response.results),
        };
      },
      providesTags: result => {
        // is result available?
        return result
          ? // successful query
            [
              ...result.ids.map(id => ({ type: NOTIFICATION_CONFIG, id } as const)),
              { type: NOTIFICATION_CONFIG, id: LIST_TAG },
            ]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: NOTIFICATION_CONFIG, id: LIST_TAG }];
      },
    }),
    upsertNotificationConfig: build.mutation<NotificationConfig, NotificationConfig>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let notificationConfig: NotificationConfig;
        try {
          if (args.id) {
            notificationConfig = await client.updateNotificationConfig({ data: args, id: args.id });
          } else {
            notificationConfig = await client.createNotificationConfig({ data: args });
          }
        } catch (error) {
          const userFacingMessage = "Failed to update notification preferences. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data: notificationConfig };
      },
      invalidatesTags: (notificationConfig, error, upsertValues) => {
        const createdNotificationConfig: boolean = !!upsertValues.id;
        const upsertedNotificationId: string | undefined = notificationConfig?.id;

        return [
          // Invalidate entity if it already existing
          ...(upsertedNotificationId ? [{ type: NOTIFICATION_CONFIG, id: upsertedNotificationId } as const] : []),
          // Invalidate fake entity if it hadn't already existed
          ...(createdNotificationConfig ? [{ type: NOTIFICATION_CONFIG, id: FAKE_ID } as const] : []),
        ];
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await performOptimisticUpdateOnEntity({
          endpointName: "getNotificationConfigs",
          dispatch,
          queryFulfilled,
          entityAdapter: notificationConfigAdapter,
          optimisticData: {
            ...args,
            id: args.id ?? FAKE_ID,
          },
        });
      },
    }),
  }),
});

export const { useGetNotificationConfigsQuery, useUpsertNotificationConfigMutation } = notificationConfig;
