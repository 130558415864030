import { Box, Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import ClientUserFileStatsTable from "components/dover/ClientUserFileStatsTable";
import { LoadingRelative } from "components/HotLoading";
import { Autocomplete } from "components/library/Autocomplete/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Heading } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { useOpenApiClients } from "hooks/openApiClients";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useGetClientOnboardingQuery, useGetCompaniesByUrnsQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useUpdateClientOnboardingMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { Company, UserUploadedFileUploadedContextEnum } from "services/openapi";
import { CompanyContactExclusion } from "views/CompanySetup/components/Exclusions/CompanyContactExclusion";
import { ExclusionsCard } from "views/CompanySetup/components/Exclusions/styles";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";

const ExclusionsComponent = (): React.ReactElement => {
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);

  const clientId = useGetClientId();
  const navigate = useNavigate();

  const { data: clientOnboarding, isLoading: isClientOnboardingLoading } = useGetClientOnboardingQuery();

  const { data: initialCompaniesValues, isLoading: isCompanyExclusionsLoading } = useGetCompaniesByUrnsQuery(
    isClientOnboardingLoading ? skipToken : clientOnboarding?.excludedCompanies || []
  );

  const [companyExclusionInput, setCompanyExclusionInput] = React.useState<Company[] | undefined>(
    initialCompaniesValues
  );
  const [
    updateClientOnboardingMutation,
    { isLoading: isUpdatingClientOnboarding },
  ] = useUpdateClientOnboardingMutation();

  const handleCompanyExclusionIDChange = React.useCallback((companies: Company[]): void => {
    setCompanyExclusionInput(companies);
  }, []);
  const client = useOpenApiClients()?.apiApi;

  const fetchIndividualCompanies = async (request: string): Promise<Company[]> => {
    if (client === undefined) {
      return [];
    }

    const companies = await client.listSimilarCompanies({ limit: 50, fullTextSearch: request });
    return companies.results;
  };

  const handleSave = React.useCallback((): void => {
    const excludedCompaniesList = companyExclusionInput || [];

    updateClientOnboardingMutation({
      id: clientId!,
      data: { excludedCompanies: excludedCompaniesList.filter(company => company.urn).map(company => company.urn!) },
      onboardingSection: OnboardingSection.EXCLUSIONS,
    });
  }, [clientId, companyExclusionInput, updateClientOnboardingMutation]);

  if (isClientOnboardingLoading || !clientOnboarding) {
    return (
      <Box marginTop="30px" display="flex" justifyContent="center">
        <LoadingRelative />
      </Box>
    );
  }
  const disabledSave = isUpdatingClientOnboarding;
  return (
    <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.SOURCING_AUTOPILOT]}>
      <Box marginTop="30px">
        {isCompanyExclusionsLoading && <Skeleton variant="rectangular" height="200px" />}
        {!isCompanyExclusionsLoading && (
          <ExclusionsCard>
            <Heading>Excluded Companies from Sourcing{useLegacySourcingAutopilot ? " Autopilot" : ""}</Heading>
            <Spacer height="4px" />
            <Autocomplete
              placeholder={"Enter specific companies..."}
              noOptionsText={"Start typing to see companies"}
              fetch={fetchIndividualCompanies}
              initialValues={companyExclusionInput ?? initialCompaniesValues}
              sortByField={"name"}
              onSelectedOptionsChange={(selectedOptions: Company[]): void => {
                handleCompanyExclusionIDChange(selectedOptions);
              }}
              getOptionLabel={(option: Company): string =>
                option.url ? `${option.name} (${option.url})` : `${option.name}`
              }
              multiple={true}
            />
            <Box marginTop="16px" display="flex" justifyContent="flex-end">
              <Button variant={ButtonVariant.Primary} onClick={(): void => handleSave()} disabled={disabledSave}>
                Save
              </Button>
            </Box>
          </ExclusionsCard>
        )}
        <Spacer height="16px" />
        <ExclusionsCard>
          <Stack spacing={1}>
            <Heading>Excluded LinkedIn Connections</Heading>
            <Body>Stop Dover from reaching out to your personal connections.</Body>
          </Stack>

          <Box marginTop="16px" display="flex" justifyContent="flex-end">
            <Button
              variant={ButtonVariant.Primary}
              onClick={(): void => {
                navigate(APP_ROUTE_PATHS.contacts());
              }}
            >
              Upload
            </Button>
          </Box>
          <ClientUserFileStatsTable uploadedContext={UserUploadedFileUploadedContextEnum.Referrals} />
        </ExclusionsCard>
        <Spacer height="16px" />
        <CompanyContactExclusion />
      </Box>
    </SectionWrapper>
  );
};

export default ExclusionsComponent;
