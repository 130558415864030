import { Box, Stack } from "@mui/material";
import React, { ReactElement, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { LoadingRelative } from "components/HotLoading";
import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { selectCurrentCandidateId } from "domains/candidate/selectors";
import {
  useListCandidateEmailEventsQuery,
  useListCandidateInterviewEventsQuery,
  useListCandidateJobApplicationEventsQuery,
  useListCandidateStageChangeEventsQuery,
  useListCandidateMovedJobEventsQuery,
  useListCandidateNotesQuery,
} from "services/doverapi/endpoints/candidate";
import { usePrefetch } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import {
  ActivityFeedItem,
  CandidateActivityNote,
  ActivityFeedItemTypeEnum,
  ActivityFeedItemSubtypeEnum,
  BaseCandidatePipelineStage,
} from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { isAppReviewStage } from "utils/isStage";
import { EmptyEventTab } from "views/CandidateDetail/components/event-feed/EmptyEventTab";
import { Event } from "views/CandidateDetail/components/event-feed/Event";
import { Note, sortNotes } from "views/CandidateDetail/components/event-feed/Note";
import { FilesTab } from "views/CandidateDetail/components/FilesTab";
import { NoNotesTabContent } from "views/CandidateDetail/components/NoNotesTabContent";
import { ResumeTab } from "views/CandidateDetail/components/ResumeTab";

const isExtendedNote = (obj: ActivityFeedItem | ExtendedNote): boolean => {
  return "isNote" in obj;
};

export interface ExtendedNote extends CandidateActivityNote {
  isNote?: boolean;
}

export const EventFeed = ({
  pipelineStage,
  shouldHideCandidateDetails,
  isHiredOrOffer,
}: {
  pipelineStage?: BaseCandidatePipelineStage | null;
  shouldHideCandidateDetails: boolean;
  isHiredOrOffer: boolean;
}): ReactElement => {
  const candidateId = useSelector(selectCurrentCandidateId);

  const prefetchCandidateFiles = usePrefetch("listCandidateFiles");

  const { data: emailEvents, isLoading: emailEventsLoading } = useListCandidateEmailEventsQuery(candidateId);
  const { notes } = useListCandidateNotesQuery(candidateId, {
    selectFromResult: ({ data }) => {
      const notes: CandidateActivityNote[] = listAllEntities(data);
      return {
        notes,
      };
    },
  });

  const { data: interviewEvents, isLoading: interviewEventsLoading } = useListCandidateInterviewEventsQuery(
    candidateId
  );

  const extendedNotes = useMemo(() => {
    return notes
      ? notes.map(
          (note: CandidateActivityNote): ExtendedNote => {
            return { ...note, isNote: true };
          }
        )
      : [];
  }, [notes]);

  const sortedEmailEvents = useMemo(
    () => [...(emailEvents || [])].sort((a, b) => (a.timestamp >= b.timestamp ? -1 : 1)),
    [emailEvents]
  );

  const sortedInterviewEvents = useMemo(
    () =>
      [...(interviewEvents || [])]
        .filter(a => a.type === ActivityFeedItemTypeEnum.Calendar)
        .sort((a, b) => (a.timestamp >= b.timestamp ? -1 : 1)),
    [interviewEvents]
  );

  const sortedInterviewFeedbackEvents = useMemo(
    () =>
      [...(interviewEvents || [])]
        .filter(a => a.subtype === ActivityFeedItemSubtypeEnum.InterviewFeedback)
        .sort((a, b) => (a.timestamp >= b.timestamp ? -1 : 1)),
    [interviewEvents]
  );

  const sortedNotes = useMemo(() => {
    return [...(extendedNotes || [])].sort(sortNotes);
  }, [extendedNotes]);

  const {
    data: jobApplicationEvents,
    isLoading: jobApplicationEventsLoading,
  } = useListCandidateJobApplicationEventsQuery(candidateId);
  const { data: stageChangeEvents, isLoading: stageChangeEventsLoading } = useListCandidateStageChangeEventsQuery(
    candidateId
  );
  const { data: movedJobEvents, isLoading: movedJobEventsLoading } = useListCandidateMovedJobEventsQuery(candidateId);

  const allEvents = useMemo(
    () =>
      ([] as (ActivityFeedItem | ExtendedNote)[])
        .concat(
          emailEvents || [],
          interviewEvents || [],
          jobApplicationEvents || [],
          stageChangeEvents || [],
          movedJobEvents || [],
          extendedNotes ? extendedNotes : []
        )
        .sort((a: any, b: any) => {
          const isAPinned = isExtendedNote(a) && a.pinnedAt;
          const isBPinned = isExtendedNote(b) && b.pinnedAt;

          // If one is pinned and the other is not, the pinned one should come first
          if (isAPinned && !isBPinned) {
            return -1;
          }
          if (!isAPinned && isBPinned) {
            return 1;
          }

          if (isAPinned && isBPinned) {
            return a.pinnedAt >= b.pinnedAt ? -1 : 1;
          }

          // If neither are pinned, sort by timestamp
          // notes use `created` instead of `timestamp`
          const timestampA = isExtendedNote(a) ? a.created : a.timestamp;
          const timestampB = isExtendedNote(b) ? b.created : b.timestamp;
          return timestampA >= timestampB ? -1 : 1;
        }),
    [emailEvents, interviewEvents, jobApplicationEvents, stageChangeEvents, movedJobEvents, extendedNotes]
  );

  // If a candidate is in applied stage we want to default to the resume tab
  // The activity tab will be mostly blank
  const defaultFilter = pipelineStage && isAppReviewStage(pipelineStage) ? "resume" : "all-activity";

  // "all-activity" | "emails" | "interviews" | "resume" | "files" | "feedback" | "notes";
  const [activeFilterParam, setActiveFilterParam] = useQueryParam(
    "activeFilter",
    withDefault(StringParam, defaultFilter)
  );

  // Prefetch candidate files
  useEffect(() => {
    prefetchCandidateFiles({ candidateId });
  }, [candidateId, prefetchCandidateFiles]);

  const getFilteredEvents = (eventFilter: string): (ActivityFeedItem | ExtendedNote)[] => {
    switch (eventFilter) {
      case "all-activity":
        return allEvents;
      case "emails":
        return sortedEmailEvents;
      case "interviews":
        return sortedInterviewEvents;
      case "notes":
        return sortedNotes;
      case "feedback":
        return sortedInterviewFeedbackEvents;
      default:
        return [];
    }
  };

  if (shouldHideCandidateDetails) {
    return (
      <Box bgcolor="white">
        <BodySmall color={colors.grayscale.gray600}>
          Candidate has received an offer or was hired! Their event feed is no longer available.
        </BodySmall>
      </Box>
    );
  }

  const filteredEvents = getFilteredEvents(activeFilterParam);

  return (
    <Box bgcolor="white" height="100%">
      <Stack
        direction="row"
        justifyContent="flex-start"
        spacing={3}
        bgcolor={backgrounds.white}
        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)", position: "sticky", top: "-25px", zIndex: "1000" }}
      >
        <FilterButton
          isActive={activeFilterParam === "all-activity"}
          onClick={(): void => setActiveFilterParam("all-activity")}
        >
          All Activity
        </FilterButton>
        <FilterButton isActive={activeFilterParam === "emails"} onClick={(): void => setActiveFilterParam("emails")}>
          Emails
        </FilterButton>
        <FilterButton
          isActive={activeFilterParam === "interviews"}
          onClick={(): void => setActiveFilterParam("interviews")}
        >
          Interviews
        </FilterButton>

        <FilterButton isActive={activeFilterParam === "resume"} onClick={(): void => setActiveFilterParam("resume")}>
          Resume
        </FilterButton>
        <FilterButton isActive={activeFilterParam === "files"} onClick={(): void => setActiveFilterParam("files")}>
          Files
        </FilterButton>
        <FilterButton
          isActive={activeFilterParam === "feedback"}
          onClick={(): void => setActiveFilterParam("feedback")}
        >
          Interview Feedback
        </FilterButton>
        <FilterButton isActive={activeFilterParam === "notes"} onClick={(): void => setActiveFilterParam("notes")}>
          Notes
        </FilterButton>
      </Stack>
      {isHiredOrOffer && (
        <Stack pt={2}>
          <Banner variant={BannerVariant.Info}>
            <BodySmall color={colors.grayscale.gray600}>
              This candidate is in the offer stage/hired. Their activity feed is hidden from everyone except the hiring
              manager, recruiter, and admins.
            </BodySmall>
          </Banner>
        </Stack>
      )}
      <EventFeedContainer>
        {activeFilterParam === "resume" ? (
          <ResumeTab candidateId={candidateId} />
        ) : activeFilterParam === "files" ? (
          <FilesTab candidateId={candidateId} />
        ) : emailEventsLoading ||
          interviewEventsLoading ||
          jobApplicationEventsLoading ||
          stageChangeEventsLoading ||
          movedJobEventsLoading ? (
          <Box paddingLeft="45%" paddingTop="20%">
            <LoadingRelative />
          </Box>
        ) : activeFilterParam === "notes" && filteredEvents.length === 0 ? (
          <NoNotesTabContent />
        ) : filteredEvents.length === 0 ? (
          <EmptyEventTab />
        ) : (
          genEvents(filteredEvents)
        )}
      </EventFeedContainer>

      <Spacer height={12} />
    </Box>
  );
};

const genEvents = (events: (ActivityFeedItem | ExtendedNote)[]): ReactElement[] =>
  events.map(
    (event): ReactElement => {
      if (isExtendedNote(event)) {
        return <Note key={(event as ExtendedNote).id} note={event as ExtendedNote} />;
      }
      return <Event key={(event as ActivityFeedItem).eventId} event={event as ActivityFeedItem} />;
    }
  );

// "active" is a word used by the reactstrap button we are overloading, so we have to use active
// could change potentially if we fully switch to mui
interface FilterButtonProps {
  isActive?: boolean;
}

export const FilterButton = styled(Box)<FilterButtonProps>`
  flex-shrink: 0;
  background-color: ${backgrounds.white};
  border-bottom: ${(props): string => (props.isActive ? "3px solid " + colors.primary.base : "")};
  color: ${(props): string => (props.isActive ? colors.black : colors.grayscale.gray400)};
  font-weight: ${(props): string => (props.isActive ? "600" : "400")};
  padding: 20px 0;
  cursor: pointer;
  font-size: 14px;
  width: max-content;
`;

const EventFeedContainer = styled.div`
  margin-top: 20px;
`;
