// The RTKQ types are too much to type out manually, the interperated type is correct

import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useJobId } from "hooks/useJobIdFromUrl";
import { useListHiringPipelineStagesV2Query } from "services/doverapi/endpoints/hiringPipelineStage";
import { UseQueryResult } from "services/doverapi/types";
import { HiringPipelineStage, HiringPipelineStageListResponse, HiringPipelineStageType } from "services/openapi";

/**
 * This hook gets the job id from the url and fetches all the hiring pipelines stages associated with that job.
 *
 * This will sometimes, temporarily, include "fake" stages that were created via an optimistic update on the
 * interview plan page.  If you aren't working on that page it is likely you don't want those "fake" stages included
 * as they don't exist in the DB on the backend yet and don't have a stage id.  If that's the case then you can use the `useRealHps` hook
 * below which filters out those "fake" stages.
 *
 * It also returns all the other rtkq return values.
 */
export const useAllHps = (
  jobIdProp?: string
): Omit<UseQueryResult<HiringPipelineStageListResponse>, "data"> & {
  stages: Array<HiringPipelineStage> | undefined;
} => {
  const [jobIdParam] = useJobId();

  const jobId = jobIdProp ?? jobIdParam;

  const { data, ...response } = useListHiringPipelineStagesV2Query(jobId ? { jobId } : skipToken);

  return {
    // We have to make a copy of the array because rtkq results are immutable and sort modifies the array in place
    // When we upgrade TS versions we can change this to use the newer toSorted method
    stages: [...(data?.results ?? [])]
      // Sort the stages by order index. When coming directly from backend they should already be sorted,
      // but this is still needed for optimistic updates where modify the order index in place
      .sort((a, b) => {
        if (a.orderIndex == undefined || b.orderIndex == undefined) return 0;

        return a.orderIndex - b.orderIndex;
      }),
    ...response,
  };
};

export const useRealHps = (
  jobId?: string
): Omit<UseQueryResult<HiringPipelineStageListResponse>, "data"> & {
  stages: Array<HiringPipelineStage> | undefined;
} => {
  const { stages, ...rest } = useAllHps(jobId);

  return { stages: stages?.filter(s => Boolean(s.id)), ...rest };
};

export const useStagesByType = ({
  stageType,
  jobId,
}: {
  stageType: HiringPipelineStageType;
  jobId?: string;
}): Array<HiringPipelineStage> | undefined => {
  const { stages } = useRealHps(jobId);

  return stages?.filter(stage => stage.stageType === stageType);
};
export const useStageIdsByType = ({
  stageType,
  jobId,
}: {
  stageType: HiringPipelineStageType;
  jobId?: string;
}): Array<string> => {
  return useStagesByType({ stageType, jobId })?.map(stage => stage.id) ?? [];
};
