/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBulkRejectionTemplateRequest
 */
export interface GetBulkRejectionTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof GetBulkRejectionTemplateRequest
     */
    hiringPipelineStageId: string;
    /**
     * 
     * @type {string}
     * @memberof GetBulkRejectionTemplateRequest
     */
    clientEmailTemplateId?: string | null;
}

export function GetBulkRejectionTemplateRequestFromJSON(json: any): GetBulkRejectionTemplateRequest {
    return GetBulkRejectionTemplateRequestFromJSONTyped(json, false);
}

export function GetBulkRejectionTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBulkRejectionTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hiringPipelineStageId': json['hiring_pipeline_stage_id'],
        'clientEmailTemplateId': !exists(json, 'client_email_template_id') ? undefined : json['client_email_template_id'],
    };
}

export function GetBulkRejectionTemplateRequestToJSON(value?: GetBulkRejectionTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hiring_pipeline_stage_id': value.hiringPipelineStageId,
        'client_email_template_id': value.clientEmailTemplateId,
    };
}


