import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { APPLICATION_PORTAL_JOB, APPLICATION_QUESTION, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import { ApplicationQuestion, ApplicationQuestionInputTypeEnum } from "services/openapi";

const candidateEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listApplicationQuestions: build.query<ApplicationQuestion[], { job?: string; clientId?: string }>({
      queryFn: async ({ job, clientId }) => {
        const { apiApi } = await getOpenApiClients({});
        const response = await apiApi.listApplicationQuestions({ job, clientId });
        return { data: response.results };
      },
      providesTags: result => (result ? [{ type: APPLICATION_QUESTION, id: LIST_TAG }] : []),
    }),
    partialUpdateApplicationQuestion: build.mutation<
      ApplicationQuestion,
      { applicationQuestionId: string; updatedApplicationQuestion: ApplicationQuestion; jobId?: string }
    >({
      queryFn: async ({
        applicationQuestionId,
        updatedApplicationQuestion,
      }: {
        applicationQuestionId: string;
        updatedApplicationQuestion: ApplicationQuestion;
      }) => {
        let result: ApplicationQuestion;
        const { apiApi } = await getOpenApiClients({});

        try {
          result = await apiApi.apiV1InboundApplicationQuestionPartialUpdate({
            id: applicationQuestionId,
            data: updatedApplicationQuestion,
          });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: (result, error, { jobId }) => {
        return [
          // Invalidate our list so that it includes our newly updated ApplicationQuestion
          { type: APPLICATION_QUESTION, id: LIST_TAG } as const,
          { type: APPLICATION_PORTAL_JOB, id: jobId },
        ];
      },
    }),
    createApplicationQuestion: build.mutation<
      ApplicationQuestion,
      {
        jobId: string;
        question: string;
        inputType: ApplicationQuestionInputTypeEnum;
        required: boolean;
        multipleChoiceOptions?: string[];
        orderIndex?: number | null;
      }
    >({
      queryFn: async ({ jobId, question, inputType, required, multipleChoiceOptions, orderIndex }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.createApplicationQuestion({
            data: { jobId, question, inputType, required, multipleChoiceOptions, orderIndex },
          });
          return { data: response };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, { jobId }) => {
        return result
          ? [
              // Invalidate our list so that it includes our newly created ApplicationQuestion
              { type: APPLICATION_QUESTION, id: LIST_TAG } as const,
              { type: APPLICATION_PORTAL_JOB, id: jobId },
            ]
          : [];
      },
    }),

    deleteApplicationQuestion: build.mutation<boolean, { applicationQuestionId: string; jobId?: string }>({
      queryFn: async ({ applicationQuestionId }: { applicationQuestionId: string }) => {
        const { apiApi } = await getOpenApiClients({});
        try {
          await apiApi.apiV1InboundApplicationQuestionDelete({ id: applicationQuestionId });
          return { data: true };
        } catch (err) {
          return { error: { serializedError: err as SerializedError } };
        }
      },
      invalidatesTags: (result, error, { jobId }) => {
        return [
          // Invalidate our list so that it removes our deleted ApplicationQuestion
          { type: APPLICATION_QUESTION, id: LIST_TAG } as const,
          { type: APPLICATION_PORTAL_JOB, id: jobId },
        ];
      },
    }),
  }),
});

export const {
  useListApplicationQuestionsQuery,
  usePartialUpdateApplicationQuestionMutation,
  useCreateApplicationQuestionMutation,
  useDeleteApplicationQuestionMutation,
} = candidateEndpoints;
