import { toZod } from "tozod";
import { z } from "zod";

import {
  CompanyListWithRequiredID,
  LocationWithValue,
  PersonaIDsRequired,
  SchoolListWithRequiredID,
} from "services/doverapi/endpoints/search-v3/types";
import {
  ProfileSearchKeywordSerializer,
  Seniority,
  ListCompanySizeSerializer,
  Company,
  EducationLevelsResponse,
  MinMaxRange,
  School,
  ProfileRequestSortByEnum,
  ProfileSearchIndustrySerializer,
  PersonaParams,
  PersonaTier2Titles,
  PersonaTitles,
} from "services/openapi";
import { DisplayedFieldOfStudy } from "views/sourcing/Search/components/EducationFilters";
import { CUSTOM_PERSONA_SELECTED_ERROR, CUSTOM_PERSONA_ID } from "views/sourcing/Search/constants";

const metadataDecorator = z.object({
  required: z.boolean(),
});

export const LocationSchema: toZod<LocationWithValue> = z.object({
  name: z.string(),
  value: z.string(),
});
export const LocationWithMetadataSchema = metadataDecorator.extend({
  locationElements: z.array(LocationSchema),
});

const t2TitlesSchema: toZod<PersonaTier2Titles> = z.object({
  tier2Titles: z.array(z.string()),
  tier2TitleIds: z.array(z.string()),
  mostRecentOnly: z.boolean(),
});

const customTitlesSchema: toZod<PersonaTitles> = z.object({
  titles: z.array(z.string()),
  mostRecentOnly: z.boolean(),
});

const personaParams: toZod<PersonaParams> = z.object({
  targetTier2Titles: t2TitlesSchema,
  excludedTier2Titles: t2TitlesSchema,
  targetCustomTitles: customTitlesSchema,
  excludedCustomTitles: customTitlesSchema,
  targetMlTitles: z.array(z.string()),
  excludedMlTitles: z.array(z.string()),
});

export const PersonaSchema: toZod<PersonaIDsRequired> = z.object({
  name: z.string(),
  id: z.string(),
  cleanName: z.nullable(z.string()),
  definition: z.optional(z.string()),
  jobCategoryDisplay: z.string(),
  paramsJson: personaParams,
  scopedToClient: z.nullable(z.string()),
  parentHasMlTitles: z.boolean(),
});

export const PersonaWithMetadataSchema = metadataDecorator.extend({
  personas: z.array(PersonaSchema),
  customTitles: z.array(z.string()),
  customTitlesSoftMatch: z.boolean(),
  excludedTitles: z.array(z.string()),
  personasMostRecentOnly: z.boolean(),
});

const SenioritiesSchema: toZod<Seniority> = z.object({
  name: z.string(),
  id: z.number(),
});
export const SenioritiesWithMetadataSchema = metadataDecorator.extend({
  selectedSeniorities: z.array(SenioritiesSchema),
});

export const IndividualCompanySchema: toZod<Company> = z.object({
  name: z.string(),
  id: z.string(),
  url: z.string(),
  urn: z.optional(z.string()),
});
export const IndividualCompanyWithMetadataSchema = metadataDecorator.extend({
  individualCompanyElements: z.array(IndividualCompanySchema),
});

const CompanyListsSchema: toZod<CompanyListWithRequiredID> = z.object({
  name: z.string(),
  id: z.string(),
  scopedToClient: z.optional(z.string()),
  paramsJson: z.object({
    companyIds: z.array(z.string()),
  }),
});
export const CompanyListsWithMetadataSchema = metadataDecorator.extend({
  selectedDoverCompanyLists: z.array(CompanyListsSchema),
});

const FieldsOfStudySchema: toZod<DisplayedFieldOfStudy> = z.object({
  name: z.string().min(1),
  id: z.string(),
  parentName: z.string(),
});
export const FieldsOfStudyWithMetadataSchema = metadataDecorator.extend({
  selectedFieldsOfStudy: z.array(FieldsOfStudySchema),
});

const EducationLevelsSchema: toZod<EducationLevelsResponse> = z.object({
  educationLevel: z.string().min(1),
});
export const EducationLevelsWithMetadataSchema = metadataDecorator.extend({
  selectedEducationLevel: z.nullable(EducationLevelsSchema),
});

export const MinMaxRangeSchema: toZod<MinMaxRange> = z.object({
  min: z.number().optional(),
  max: z.number().optional(),
});

export const YearsOfExperienceWithMetadataSchema = metadataDecorator.extend({
  yearsOfExperience: MinMaxRangeSchema,
});

export const YearsOfExperienceByTitleWithMetadataSchema = metadataDecorator.extend({
  titleId: z.number().optional(),
  yoeRange: MinMaxRangeSchema,
});

export const YoeByT2WithMetadataSchema = metadataDecorator.extend({
  elements: z.array(YearsOfExperienceByTitleWithMetadataSchema),
});

const IndividualSchoolSchema: toZod<School> = z.object({
  name: z.string(),
  id: z.string(),
});

const SchoolListsSchema: toZod<SchoolListWithRequiredID> = z.object({
  name: z.string(),
  id: z.string(),
  scopedToClient: z.optional(z.string()),
  paramsJson: z.object({
    schoolIds: z.array(z.string()),
  }),
});

export const SchoolListsWithMetadataSchema = metadataDecorator.extend({
  doverSchoolLists: z.array(SchoolListsSchema),
});

export const IndividualSchoolsWithMetadataSchema = metadataDecorator.extend({
  individualSchools: z.array(IndividualSchoolSchema),
});

export const KeywordsSchema: toZod<ProfileSearchKeywordSerializer> = z.object({
  id: z.string(),
  friendlyName: z.string(),
  canonicalKeyword: z.string(),
  aliases: z.string().array(),
});
export const KeywordsWithMetadataSchema = metadataDecorator.extend({
  bucket: z.array(KeywordsSchema),
});
export type KeywordsCollectionItem = z.infer<typeof KeywordsWithMetadataSchema>;

export const CompanySizesSchema: toZod<ListCompanySizeSerializer> = z.object({
  name: z.string(),
  minSize: z.nullable(z.number()),
  maxSize: z.nullable(z.number()),
});
export const CompanySizesWithMetadataSchema = metadataDecorator.extend({
  value: z.array(CompanySizesSchema),
  required: z.boolean(),
  // put this here instead of in CompanySizesSchema because it should apply to
  // all company sizes
  mostRecentOnly: z.boolean(),
});

export const IndustrySchema: toZod<ProfileSearchIndustrySerializer> = z.object({
  id: z.string(),
  friendlyName: z.string(),
  canonicalKeyword: z.string(),
  aliases: z.string().array(),
});
export const IndustryWithMetadataSchema = metadataDecorator.extend({
  industryElements: z.array(IndustrySchema),
});

export const RankRangeSchema = metadataDecorator.extend({
  rankRange: MinMaxRangeSchema,
});

export const searchV3FormSchema = z.object({
  name: z.string().min(1),
  locations: LocationWithMetadataSchema,
  excludedLocations: LocationWithMetadataSchema,
  personas: PersonaWithMetadataSchema.refine(val => !val.personas?.find(persona => persona.id === CUSTOM_PERSONA_ID), {
    message: CUSTOM_PERSONA_SELECTED_ERROR,
  }),
  seniorities: SenioritiesWithMetadataSchema,
  excludedSeniorities: SenioritiesWithMetadataSchema,
  individualCompanies: IndividualCompanyWithMetadataSchema,
  individualExcludedCompanies: IndividualCompanyWithMetadataSchema,
  targetCompanyLists: CompanyListsWithMetadataSchema,
  targetCompaniesMostRecentOnly: z.boolean(),
  fieldsOfStudy: FieldsOfStudyWithMetadataSchema,
  educationLevel: EducationLevelsWithMetadataSchema,
  totalYearsOfExperience: YearsOfExperienceWithMetadataSchema,
  specificYearsOfExperience: YearsOfExperienceWithMetadataSchema,
  specificYearsOfExperienceByT2: YoeByT2WithMetadataSchema,
  yearsAtCurrentCompany: YearsOfExperienceWithMetadataSchema,
  bachelorsGraduationYear: YearsOfExperienceWithMetadataSchema.refine(val => {
    // if both min and max are provided, we need min <= max. otherwise make sure its a number of undefined
    if (typeof val.yearsOfExperience.min === "number" && typeof val.yearsOfExperience.max === "number") {
      return val.yearsOfExperience.min <= val.yearsOfExperience.max;
    } else {
      return (
        (typeof val.yearsOfExperience.min === "number" || typeof val.yearsOfExperience.min === "undefined") &&
        (typeof val.yearsOfExperience.min === "number" || typeof val.yearsOfExperience.min === "undefined")
      );
    }
  }),
  excludeFrequentJobSwitching: z.boolean(),
  onlyTargetSpecificSchools: z.boolean(),
  keywordsBuckets: z.array(KeywordsWithMetadataSchema),
  deniedKeywords: z.array(KeywordsSchema),
  companySizes: CompanySizesWithMetadataSchema,
  companySizeExclusions: CompanySizesWithMetadataSchema,
  industries: IndustryWithMetadataSchema,
  excludedIndustries: IndustryWithMetadataSchema,
  industriesMostRecentOnly: z.boolean(),
  diversityOption: z.string().optional(),
  schoolRankRange: RankRangeSchema,
  companyRankRange: RankRangeSchema,
  individualSchools: IndividualSchoolsWithMetadataSchema,
  targetSchoolLists: SchoolListsWithMetadataSchema,
  individualExcludedSchools: IndividualSchoolsWithMetadataSchema,
  strictness: z.number(),
  // NOTE(willjow): `sortBy` is only used for `useListSearchV3ProfilesQuery`; it
  // isn't used for generating `SearchV3` objects. It just lives here for
  // convenience.
  sortBy: z.nativeEnum(ProfileRequestSortByEnum),
});

export type SearchV3FormSchemaType = z.infer<typeof searchV3FormSchema>;

export interface TitleContentProps {
  expanded: boolean;
}

export enum SourcingContext {
  CreateJob = "CREATE_JOB",
}
