import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

import {
  ASHBY_DISABLE_STAGES,
  GREENHOUSE_DISABLE_STAGES,
  LEVER_DISABLE_STAGES,
} from "components/SetupHiringPipeline/constants";
import { useGetJobStageSetupPipeline } from "components/SetupHiringPipeline/hooks";
import { InterviewSchedulingScenario } from "components/SetupHiringPipeline/types";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetCompanyAtsType } from "services/doverapi/endpoints/client/hooks";
import { useSyncAndListJobAtsStagesQuery } from "services/doverapi/endpoints/job/endpoints";
import { isDoverApiError } from "services/doverapi/types";
import {
  ClientOnboardingAtsTypeEnum,
  ClientOnboardingSetupAtsStateEnum,
  JobAtsStageMap,
  JobSetupSetupAtsStateEnum,
} from "services/openapi";

export function useShouldShowAtsInterviewFeedbackField(): boolean {
  const { data: clientOnboarding } = useGetClientOnboardingQuery();

  return useMemo((): boolean => {
    if (!clientOnboarding) {
      return false;
    }

    const atsType = clientOnboarding?.atsType;
    const atsSetupState = clientOnboarding?.setupAtsState;
    if (atsType && atsSetupState) {
      const allowableAtsTypes = [ClientOnboardingAtsTypeEnum.Lever, ClientOnboardingAtsTypeEnum.Greenhouse];
      const allowableAtsSetupStates = [
        ClientOnboardingSetupAtsStateEnum.StartedPendingDoverAction,
        ClientOnboardingSetupAtsStateEnum.Complete,
      ];
      return allowableAtsTypes.includes(atsType) && allowableAtsSetupStates.includes(atsSetupState);
    }
    return false;
  }, [clientOnboarding]);
}

const useSelectAtsStagesInfo = (): any => {
  const [jobId] = useJobId();
  const {
    data: triggeredResyncData,
    isLoading: isTriggeredLoading,
    error: triggeredResyncError,
  } = useSyncAndListJobAtsStagesQuery(
    jobId
      ? {
          jobId,
          triggerResyncOverride: true,
        }
      : skipToken
  );
  const {
    data: didntTriggerResyncData,
    isLoading: isNonTriggeredLoading,
    error: didntTriggerResyncError,
  } = useSyncAndListJobAtsStagesQuery(
    jobId
      ? {
          jobId,
        }
      : skipToken
  );

  // Prioritize data from api calls where we triggered a resync forcefully.
  const data = triggeredResyncData ?? didntTriggerResyncData;
  const loading = isTriggeredLoading || isNonTriggeredLoading;
  const error = triggeredResyncError ?? didntTriggerResyncError;

  return {
    initialized: data !== undefined,
    loading: loading,
    loadingError: isDoverApiError(error) ? error?.serializedError : error ?? null,
    data: data,
  };
};

export function useGetAtsStages(): Array<JobAtsStageMap> | undefined {
  const atsStagesInfo = useSelectAtsStagesInfo();
  const atsStages = atsStagesInfo.data?.atsStages;
  return atsStages;
}

export function useGetDisabledAtsStages(): Array<JobAtsStageMap> | undefined {
  const atsStages = useGetAtsStages();
  const companyAtsType = useGetCompanyAtsType();

  if (companyAtsType === ClientOnboardingAtsTypeEnum.Greenhouse) {
    return atsStages?.filter(stage => GREENHOUSE_DISABLE_STAGES.includes(stage.atsStage));
  } else if (companyAtsType === ClientOnboardingAtsTypeEnum.Lever) {
    return atsStages?.filter(stage => LEVER_DISABLE_STAGES.includes(stage.atsStage));
  } else if (companyAtsType === ClientOnboardingAtsTypeEnum.Ashby) {
    return atsStages?.filter(stage => ASHBY_DISABLE_STAGES.includes(stage.atsStage.toLowerCase()));
  }
}

export function useGetInterviewSchedulingScenario(): InterviewSchedulingScenario {
  const jobSetup = useGetJobStageSetupPipeline();

  const { data: clientOnboarding } = useGetClientOnboardingQuery();

  const atsStagesInfo = useSelectAtsStagesInfo();
  const atsStagesLoading = atsStagesInfo.loading;
  const atsStages = atsStagesInfo.data?.atsStages;
  const atsStagesSyncSuccessful = atsStagesInfo.data?.syncSuccessful;

  const companyAtsType = clientOnboarding?.atsType;
  const setupCompanyAtsState = clientOnboarding?.setupAtsState;
  const jobSetupSetupAtsState = jobSetup?.setupAtsState;
  const atsCredentialsProvided = jobSetup?.atsCredentialsProvided;

  if ([ClientOnboardingAtsTypeEnum.NoAts, ClientOnboardingAtsTypeEnum.Other].includes(companyAtsType!)) {
    return InterviewSchedulingScenario.HIDE_ATS_STAGES_COLUMN;
  } else if (atsStagesLoading) {
    return InterviewSchedulingScenario.LOADING;
  } else if (
    [ClientOnboardingSetupAtsStateEnum.Pending, ClientOnboardingSetupAtsStateEnum.StartedPendingClientAction].includes(
      setupCompanyAtsState!
    )
  ) {
    return InterviewSchedulingScenario.HAS_NOT_COMPLETED_COMPANY_ATS;
  } else if (
    [JobSetupSetupAtsStateEnum.Pending, JobSetupSetupAtsStateEnum.StartedPendingClientAction].includes(
      jobSetupSetupAtsState!
    )
  ) {
    return InterviewSchedulingScenario.HAS_NOT_COMPLETED_JOB_ATS;
  } else if (companyAtsType === ClientOnboardingAtsTypeEnum.Lever && !atsCredentialsProvided) {
    // For Lever, we cannot perform the ats stage sync until Dover has saved the client's Lever cookies.
    return InterviewSchedulingScenario.SYNC_PENDING_DOVER_ACTION;
  } else if (atsStagesSyncSuccessful === undefined && atsStages !== undefined && atsStages.length > 0) {
    return InterviewSchedulingScenario.SYNC_UNNECESSARY_HAS_STAGES;
  } else if (atsStagesSyncSuccessful === false) {
    if (atsStages === undefined || atsStages.length === 0) {
      return InterviewSchedulingScenario.FAILED_TO_SYNC_NO_CACHE;
    } else {
      return InterviewSchedulingScenario.FAILED_TO_SYNC_HAS_CACHE;
    }
  } else if (atsStages !== undefined && atsStages.length > 0) {
    return InterviewSchedulingScenario.SYNC_SUCCESSFUL_HAS_STAGES;
  }
  return InterviewSchedulingScenario.SYNC_SUCCESSFUL_NO_STAGES;
}
