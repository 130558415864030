import { SaapReviewApplication, SaapReviewApplicationSchedulingOwnershipEnum } from "services/openapi";

export const isApplicantEligibleForBulkReject = (
  application: SaapReviewApplication,
  isJobE2eEnabled: boolean
): boolean => {
  // Make sure the candidate is eligble for bulk reject
  const hasActionVersion = application.candidateActionVersion !== undefined;
  const hasOkaySchedulingOwnership =
    (application.schedulingOwnership === SaapReviewApplicationSchedulingOwnershipEnum.DoverHandlesScheduling &&
      isJobE2eEnabled) ||
    (application.schedulingOwnership === SaapReviewApplicationSchedulingOwnershipEnum.CustomerHandlesScheduling &&
      !isJobE2eEnabled); // We don't want the confusion of bulk rejecting candidates that are manually customer scheduled on a dover managed job

  return hasActionVersion && hasOkaySchedulingOwnership && !application.isDemoCandidate;
};
