import { Stack, Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

import GoogleButtonLarge from "assets/GoogleButtonLarge.png";
import GmailAuthCheckboxInstruction from "assets/images/next_steps_gmail_auth_instructions.png";
import { Heading } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { appConfig } from "config/appConfig";

const StyledImage = styled.img`
  object-fit: contain;
  width: auto;
  max-width: 80%;
`;

export const GmailAuthButton = (): React.ReactElement => (
  <a href={`${appConfig.legacyAppUrl}/utils/auth`} target="_blank" rel="noopener noreferrer">
    <img src={GoogleButtonLarge} width="200" alt="Google sign in" />
  </a>
);

export const GmailAuthContent: React.FC<any> = () => {
  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <Spacer height="24px" />
      <Heading>Connect your Gmail</Heading>
      <Box display="flex" justifyContent="center" maxWidth="600px">
        <StyledImage src={GmailAuthCheckboxInstruction} alt="Gmail Auth Checkbox Instruction" />
      </Box>
      <Box display="flex" justifyContent="center" paddingBottom="24px">
        <GmailAuthButton />
      </Box>
    </Stack>
  );
};
