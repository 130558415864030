/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewStatusPointOfContact
 */
export interface InterviewStatusPointOfContact {
    /**
     * 
     * @type {string}
     * @memberof InterviewStatusPointOfContact
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewStatusPointOfContact
     */
    readonly title?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewStatusPointOfContact
     */
    readonly email?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewStatusPointOfContact
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {object}
     * @memberof InterviewStatusPointOfContact
     */
    roleTitleData?: object | null;
}

export function InterviewStatusPointOfContactFromJSON(json: any): InterviewStatusPointOfContact {
    return InterviewStatusPointOfContactFromJSONTyped(json, false);
}

export function InterviewStatusPointOfContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewStatusPointOfContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
        'roleTitleData': !exists(json, 'role_title_data') ? undefined : json['role_title_data'],
    };
}

export function InterviewStatusPointOfContactToJSON(value?: InterviewStatusPointOfContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'picture_url': value.pictureUrl,
        'role_title_data': value.roleTitleData,
    };
}


