import { Skeleton, Stack, Switch } from "@mui/material";
import React from "react";

import { INVALID_PERMS_TOOLTIP_TEXT } from "components/constants";
import { Tooltip } from "components/library/Tooltip";
import { Body } from "components/library/typography";
import { useHasElevatedPermissions } from "components/RBAC";
import { useGetUsersClientQuery, usePartialUpdateClientMutation } from "services/doverapi/endpoints/client/endpoints";
import { colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { SwitchLabelControl } from "views/CompanySetup/components/Notifications/styles";

export const TalentNetworkBanner = (): React.ReactElement => {
  const { data: client, isLoading, isFetching: isReloadingClient } = useGetUsersClientQuery();
  const [partialUpdateClient, { isLoading: isUpdatingClient }] = usePartialUpdateClientMutation();

  // Perms check
  const hasElevatedPermissions = useHasElevatedPermissions();

  const getTooltipMessage = (hasElevatedPermissions: boolean): string => {
    if (!hasElevatedPermissions) {
      return INVALID_PERMS_TOOLTIP_TEXT;
    }
    return "";
  };

  const handleToggleEnableTalentNetwork = async (checked: boolean): Promise<void> => {
    if (!client?.id) {
      console.error("No client id found");
      return;
    }

    await partialUpdateClient({
      id: client.id,
      updatedClient: {
        enableTalentNetwork: checked,
        // TODO: these shouldn't be required in update call
        name: client.name,
        realName: client.realName,
      },
    });
  };

  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      bgcolor={colors.white}
      p={2}
      borderRadius="6px"
      border={`1px solid ${colors.grayscale.gray200}`}
    >
      <Stack>
        <Body weight="600">Dover Talent Network (Beta)</Body>
        <Stack direction="row" spacing={1}>
          <Body>Get more applicants by joining our talent network!</Body>
          <ExternalLink
            href={"https://help.dover.com/en/articles/8888042-dover-talent-network-beta"}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Body color={colors.link}>Learn more</Body>
          </ExternalLink>
        </Stack>
      </Stack>
      {!client || isLoading ? (
        <Skeleton height="40px" width="100px" />
      ) : (
        <Tooltip title={getTooltipMessage(hasElevatedPermissions)}>
          <SwitchLabelControl
            checked={client.enableTalentNetwork}
            disabled={!hasElevatedPermissions || isUpdatingClient || isReloadingClient}
            onChange={(e, checked): void => {
              handleToggleEnableTalentNetwork(checked);
            }}
            control={<Switch sx={{ m: 1 }} />}
            label={""}
            sx={{ color: colors.primary.base }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};
