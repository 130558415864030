import { Box } from "@mui/material";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";

import GoogleButtonLargePNG from "assets/icons/auth-google-button.png";
import ConnectGoogleDiagramSVG from "assets/icons/connect-google-diagram.svg";
import GoogleAuthDetailsSVG from "assets/images/google-auth-details.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import Modal from "components/Modal";
import { Spacer } from "components/Spacer";
import { appConfig } from "config/appConfig";
import { StyledSVG, WhiteCard } from "views/CompanySetup/components/InterviewPreferences/styles";

export const NeedsCalendarAuthBlocker = (): React.ReactElement => {
  const [openModal, setOpenModal] = useState(false);

  const modalContent = (
    <>
      <StyledSVG src={GoogleAuthDetailsSVG} />
      <Body style={{ textAlign: "center" }}>
        Connect your Google calendar so that Dover can schedule interviews based on your availability.
      </Body>
      <Spacer height="16px" />
      <Body style={{ textAlign: "center" }}>
        Dover <strong>only</strong> edits calendar events regarding candidate interviews.
      </Body>
      <Spacer height="32px" />
      <Box display="flex" justifyContent="center">
        <a href={`${appConfig.legacyAppUrl}/cal/auth`} target="_blank" rel="noopener noreferrer">
          <Button variant={ButtonVariant.Primary} onClick={(): void => setOpenModal(false)}>
            Continue
          </Button>
        </a>
      </Box>
      <Spacer height="8px" />
    </>
  );

  return (
    <Box>
      <WhiteCard>
        <Body weight="500">Provide your availability</Body>
        <Spacer height="48px" />

        <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
          <ReactSVG src={ConnectGoogleDiagramSVG} />
          <Spacer height="16px" />

          <Body>Dover will create calendar events for candidate interviews.</Body>
          <Spacer height="24px" />

          <div style={{ cursor: "pointer" }} onClick={(): void => setOpenModal(true)}>
            <img src={GoogleButtonLargePNG} width="250" alt="Google sign in" />
          </div>
          <Spacer height="24px" />
        </Box>
      </WhiteCard>
      <Modal open={openModal} onClose={(): void => setOpenModal(false)} maxWidth="sm">
        {modalContent}
      </Modal>
    </Box>
  );
};
