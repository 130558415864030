import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect } from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { BodySmall, Subtitle2 } from "components/library/typography";
import BaseStepControls from "sections/addcandidate/components/BaseStepControls";
import HorizontalLoader from "sections/addcandidate/components/HorizontalLoader";
import AddAtsCandidateToggle from "sections/addcandidate/components/steps/AtsCandidateStep/AddAtsCandidateToggle";
import InvalidJobBanner from "sections/addcandidate/components/steps/AtsCandidateStep/InvalidJobBanner";
import { StepConfig } from "sections/addcandidate/data/stepConfig";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { AddCandidateStep, StepTypes } from "sections/addcandidate/types";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useFindAtsCandidateManualSourcingQuery } from "services/doverapi/endpoints/manualSourcingContext";
import { ClientOnboardingAtsTypeEnum } from "services/openapi";
import { showErrorToast } from "utils/showToast";
import { titleCase } from "utils/strings";

export const StepContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: { [StepTypes.ATS_CANDIDATE_INFO]: state },
    setStepState,
  } = useAddCandidateContext();

  const { data: client, isLoading: isLoadingClient } = useGetClientOnboardingQuery();

  const { data: atsCandidate, isFetching: isFetchingAtsCandidate, isError } = useFindAtsCandidateManualSourcingQuery(
    state.atsCandidateUrl || skipToken
  );

  const [atsUrl, setAtsUrl] = React.useState<string>("");
  const [lastRequestedAtsUrl, setLastRequestedAtsUrl] = React.useState<string>("");

  useEffect(() => {
    if (isFetchingAtsCandidate) {
      return;
    }

    if (isError) {
      showErrorToast("Unable to find ATS candidate");
    }

    if (atsCandidate) {
      const atsCandidateData = atsCandidate.atsCandidate;

      setLastRequestedAtsUrl(atsUrl);

      if (atsCandidateData) {
        setStepState(StepTypes.ATS_CANDIDATE_INFO, { ...state, atsCandidate: atsCandidateData });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, atsCandidate, isFetchingAtsCandidate, setStepState]);

  //-----------------------------------------------------------------------------
  // Render

  const allowAddingAtsCandidates =
    client?.atsType &&
    [
      ClientOnboardingAtsTypeEnum.Greenhouse,
      ClientOnboardingAtsTypeEnum.Lever,
      ClientOnboardingAtsTypeEnum.Ashby,
    ].includes(client.atsType);

  if (isLoadingClient) {
    return <HorizontalLoader />;
  }

  if (!allowAddingAtsCandidates) {
    return (
      <>
        <Box mt={2} mb={2}>
          <BodySmall>No third-party ATS to sync from</BodySmall>
        </Box>
        <BaseStepControls />
      </>
    );
  }

  const pendingImport = atsUrl !== lastRequestedAtsUrl;
  const invalidAtsCandidate =
    allowAddingAtsCandidates && state.addingAtsCandidate && (isFetchingAtsCandidate || !atsCandidate?.atsCandidate);
  const atsCandidateLoaded = atsCandidate?.atsCandidate && !pendingImport;

  const showInvalidJobBanner =
    allowAddingAtsCandidates &&
    state.addingAtsCandidate &&
    !isFetchingAtsCandidate &&
    atsCandidate?.atsCandidate &&
    !atsCandidate?.atsCandidate?.job;
  const disableContinue = invalidAtsCandidate || pendingImport || showInvalidJobBanner;
  const disableImport = isFetchingAtsCandidate || !atsUrl || (showInvalidJobBanner && !pendingImport);

  return (
    <Stack spacing={2}>
      <AddAtsCandidateToggle atsType={client?.atsType} />
      {state.addingAtsCandidate && (
        <Stack spacing={2}>
          {showInvalidJobBanner && <InvalidJobBanner />}
          <TextField
            title="ATS Url"
            text={atsUrl}
            onTextUpdated={(text): void => setAtsUrl(text)}
            placeholderText="https://myats.com/candidate/123"
          />
          {atsCandidateLoaded && !showInvalidJobBanner ? (
            <Subtitle2>
              We found {atsCandidate?.atsCandidate?.firstName} in {titleCase(client?.atsType ?? "your ATS")}
            </Subtitle2>
          ) : (
            <Button
              variant={ButtonVariant.Primary}
              onClick={(): void => setStepState(StepTypes.ATS_CANDIDATE_INFO, { ...state, atsCandidateUrl: atsUrl })}
              disabled={disableImport}
              loading={isFetchingAtsCandidate}
            >
              Import Candidate
            </Button>
          )}
        </Stack>
      )}
      <BaseStepControls disableContinue={disableContinue} hideBackButton />
    </Stack>
  );
};

const PostCompletionContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: { [StepTypes.ATS_CANDIDATE_INFO]: state },
  } = useAddCandidateContext();
  //-----------------------------------------------------------------------------
  // Render

  if (state.addingAtsCandidate) {
    return <BodySmall>Adding candidate from ATS</BodySmall>;
  }

  return <BodySmall>Not adding candidate from ATS</BodySmall>;
};

const step: AddCandidateStep = {
  ...StepConfig[StepTypes.ATS_CANDIDATE_INFO],
  StepContent: <StepContent />,
  PostCompletionContent: <PostCompletionContent />,
};

export default step;
