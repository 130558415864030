import { Box, Divider, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom } from "jotai";
import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as RightArrowIcon } from "assets/icons/arrow-right.svg";
import { ReactComponent as ChatIcon } from "assets/icons/chat-bubbles.svg";
import { ReactComponent as CheckGreenFilledIcon } from "assets/icons/check-green-filled.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CompletedCheck } from "assets/icons/complete-check.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { ReactComponent as ReplyIcon } from "assets/icons/reply.svg";
import { ReactComponent as RejectThumbDown } from "assets/icons/thumbs-down.svg";
import {
  CandidateActionButtons,
  CandidateActionButtonsVariant,
} from "components/CandidateActionButtons/CandidateActionButtons";
import { SocialLink } from "components/dover/SocialLink";
import { Button, ButtonVariant } from "components/library/Button";
import { Chip } from "components/library/Chip";
import { Body, BodyExtraSmall, BodySmall, ButtonText, Subtitle2 } from "components/library/typography";
import SeeMoreLess from "components/SeeMoreLess";
import { useGetCandidateBioQuery, useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { usePartialUpdateMentionMutation } from "services/doverapi/endpoints/mentions";
import {
  CandidateBio,
  CandidateBioStatusEnum,
  NextActionSchedulingStateEnum,
  NextActionStateEnum,
  PipelineCandidate,
  UpdateCandidateBioBodyStatusEnum,
} from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { getFormattedTimestamp } from "utils/dates";
import { getPlainTextFromHtml } from "utils/draftJS";
import { isInitialCallStage, isOfferStage } from "utils/isStage";
import { readMentionsAtom } from "views/candidates/CandidatesReview/atoms/readMentions";
import { CANDIDATE_RESPONSE_MAX_CHAR_LENGTH, socialLinksToInclude } from "views/candidates/CandidatesReview/constants";
import { useGetMostRecentCandidateEmailEventBody } from "views/candidates/CandidatesReview/hooks";
import { ReviewFilterEnum } from "views/candidates/types";

const CandidateCard = ({
  candidate,
  reviewType,
}: {
  candidate: PipelineCandidate;
  reviewType: ReviewFilterEnum;
}): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "6px",
        border: `1px solid ${colors.grayscale.gray200}`,
        overflow: "hidden",
        "&:hover": { boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)" },
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          background: colors.white,
          cursor: "pointer",
          padding: "16px",
          flexWrap: "wrap",
        }}
        onClick={(): void => {
          const queryParams = new URLSearchParams(location.search);
          queryParams.set("activeFilter", "all-activity");

          navigate(
            APP_ROUTE_PATHS.review({
              candidateId: candidate.id,
              queryParams,
            })
          );
        }}
      >
        <CandidateBasicInfo candidate={candidate} />
        <ActionTimestamp candidate={candidate} />
      </Stack>
      {reviewType === ReviewFilterEnum.Mentions ? (
        <MentionLowerCardContent candidate={candidate} />
      ) : (
        <LowerCardContent candidate={candidate} />
      )}
    </Box>
  );
};

const CandidateBasicInfo = ({ candidate }: { candidate: PipelineCandidate }): React.ReactElement => {
  const { data: bio } = useGetCandidateBioQuery(candidate.id ? candidate.id : skipToken);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tabletL));
  const fullName = candidate.contact.fullName;

  return (
    <Stack flexBasis={smallScreen ? "100%" : "40%"} maxWidth="400px">
      <Stack direction="row" spacing={smallScreen ? 1 : 2}>
        <Body weight="600">{fullName}</Body>
        {bio?.contact.socialLinks
          ?.filter(socialLink => socialLinksToInclude?.includes(socialLink.linkType))
          ?.map(socialLink => (
            <span
              key={socialLink.url}
              onClick={(e): void => e.stopPropagation()}
              style={{ margin: "-1px", marginLeft: "12px" }}
            >
              <SocialLink linkType={socialLink.linkType} url={socialLink.url} />
            </span>
          ))}
      </Stack>
      <BodySmall color={colors.grayscale.gray600}>{candidate.contact.headline}</BodySmall>
    </Stack>
  );
};

const ActionTimestamp = ({ candidate }: { candidate: PipelineCandidate }): React.ReactElement => {
  const { data: bio, isLoading: bioLoading } = useGetCandidateBioQuery(candidate.id ? candidate.id : skipToken);

  const lastAction = useMemo((): React.ReactElement => {
    let icon = <></>;
    let text = "";

    switch (bio?.nextAction?.schedulingState) {
      case NextActionSchedulingStateEnum.CallCompleted: {
        icon = <CompletedCheck className="svg-fill" color={colors.grayscale.gray400} width="16px" />;
        text = `Completed ${bio?.nextAction?.hiringPipelineStage?.name ?? "interview"}`;
        break;
      }
      case NextActionSchedulingStateEnum.InitialReview: {
        icon = <ReplyIcon className="svg-fill" color={colors.grayscale.gray400} width="16px" />;
        text = "Responded to outreach";
        break;
      }
      default:
        return <></>;
    }

    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {icon}
        <LastActionLabel color={colors.grayscale.gray700}>{text}</LastActionLabel>
      </Stack>
    );
  }, [bio?.nextAction?.hiringPipelineStage?.name, bio?.nextAction?.schedulingState]);

  if (bioLoading) {
    return <Skeleton width="48px" height="100%" />;
  }

  return (
    <Stack alignItems="flex-end">
      {lastAction}
      {candidate.lastEventTs && (
        <Box ml={2.5}>
          <BodyExtraSmall color={colors.grayscale.gray600}>
            {getFormattedTimestamp(candidate.lastEventTs)}
          </BodyExtraSmall>
        </Box>
      )}
    </Stack>
  );
};

const LowerCardContent = ({ candidate }: { candidate: PipelineCandidate }): React.ReactElement => {
  const { data: bio, isLoading: bioLoading } = useGetCandidateBioQuery(candidate.id ? candidate.id : skipToken);
  const mostRecentCandidateResponse = useGetMostRecentCandidateEmailEventBody(candidate.id!);

  /****** Candidate Bio Values ********/
  const pendingCustomerResponse = bio?.nextAction?.pendingCustomerResponse;
  const schedulingState = bio?.nextAction?.schedulingState;
  const callCompleted = schedulingState === NextActionSchedulingStateEnum.CallCompleted;
  const candidateNextActionState = bio?.nextAction?.state;
  const candidateOnLastInterviewStage = bio?.nextAction?.isLastInterview;
  const candidateOnOfferExtended = bio?.candidatePipelineStage && isOfferStage(bio?.candidatePipelineStage);
  const hasDoverInterviewer = bio?.nextAction?.currentInterview?.hasDoverInterviewer;
  const hasNotesSubmitted =
    callCompleted && hasDoverInterviewer && bio?.nextAction?.currentInterview?.hasCompletedNotes;

  /****** Optimistic Update Tags ********/
  const needsAction = bio?.nextAction?.state === NextActionStateEnum.NeedsAction;
  const showRejectedTag = bio && bio.status === CandidateBioStatusEnum.Rejected;
  const showWithdrawnTag = bio && bio.status === CandidateBioStatusEnum.Withdrew;
  const showAdvancedTag = useMemo(() => {
    return (
      // Assume if candidate is moved to stage 800, they are advanced
      candidateOnOfferExtended || schedulingState === NextActionSchedulingStateEnum.Scheduling
    );
  }, [schedulingState, candidateOnOfferExtended]);

  /****** Conditional Text and Buttons ********/
  const showCandidateResponse = useMemo(() => {
    return pendingCustomerResponse;
  }, [pendingCustomerResponse]);

  const showDefaultActionButtons = useMemo(() => {
    if (callCompleted && hasDoverInterviewer && !hasNotesSubmitted) {
      return false;
    }
    return needsAction && !showCandidateResponse;
  }, [callCompleted, hasDoverInterviewer, hasNotesSubmitted, showCandidateResponse, needsAction]);

  const nextActionText = useMemo((): React.ReactElement => {
    if (!candidateNextActionState) {
      return <></>;
    }
    return (
      <Stack direction="row">
        <BodySmall>{"Advance to"}&nbsp;</BodySmall>
        <BodySmall weight="600">{bio?.nextAction?.nextHiringPipelineStage?.name ?? "next stage"}</BodySmall>
        <BodySmall>{"?"}</BodySmall>
      </Stack>
    );
  }, [candidateNextActionState, bio?.nextAction?.nextHiringPipelineStage?.name]);

  const optimisticAdvancedText = useMemo(() => {
    const candidateOnInitialCall = bio?.candidatePipelineStage && isInitialCallStage(bio?.candidatePipelineStage);
    const stageAdvancedTo = candidateOnInitialCall
      ? "Initial Call"
      : candidateOnOfferExtended
      ? "Offer Extended"
      : bio?.nextAction?.hiringPipelineStage?.name ?? "next stage";
    return `Advanced to ${stageAdvancedTo}`;
  }, [bio, candidateOnOfferExtended]);

  if (bioLoading) {
    return <Skeleton height="40px" width="90px" sx={{ marginLeft: "16px" }} />;
  }

  if (!bioLoading && !bio) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="6px 16px"
      bgcolor={colors.grayscale.gray100}
      borderTop={`1px solid ${colors.grayscale.gray200}`}
      flexWrap="wrap"
      overflow="auto"
      spacing={1}
    >
      {showAdvancedTag && <Chip label={optimisticAdvancedText} variant="Primary" />}
      {showRejectedTag && <Chip label="Rejected" variant="Critical" />}
      {showWithdrawnTag && <Chip label="Withdrawn" variant="Warning" />}
      {showCandidateResponse && (
        <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
          <CandidateResponse candidateResponse={mostRecentCandidateResponse ?? ""} />
          {bio && (
            <CandidateActionButtons
              candidateBio={bio}
              variant={CandidateActionButtonsVariant.Light}
              condense={true}
              hideToast={true}
              skipTagInvalidation={true}
            />
          )}
        </Stack>
      )}
      {callCompleted && hasDoverInterviewer && !hasNotesSubmitted && <PendingDoverInterviewerNotes />}
      {bio && showDefaultActionButtons && !showAdvancedTag && (
        <>
          <BodySmall weight="600">{nextActionText}</BodySmall>
          {candidateOnLastInterviewStage ? (
            <Stack spacing={1} alignItems="flex-end">
              <LastInterviewStageButtons candidateBio={bio} />
              <LastInterviewStageInfoText />
            </Stack>
          ) : (
            <CandidateActionButtons
              candidateBio={bio}
              variant={CandidateActionButtonsVariant.Light}
              condense={true}
              hideToast={true}
              skipTagInvalidation={true}
            />
          )}
        </>
      )}
    </Stack>
  );
};

const PendingDoverInterviewerNotes = (): React.ReactElement => {
  return (
    <Stack flexBasis="80%" direction="row" alignItems="center" minHeight="48px">
      <BodySmall weight="600">
        Call completed with Dover Interviewer. Interview notes will be uploaded shortly.
      </BodySmall>
    </Stack>
  );
};

const CandidateResponse = ({ candidateResponse }: { candidateResponse: string }): React.ReactElement => {
  return (
    <Stack flexBasis="50%">
      <BodySmall weight="600">Candidate asked:</BodySmall>
      <BodySmall>
        <SeeMoreLess
          text={candidateResponse}
          showMoreLabel="...view full response"
          showLessLabel=" hide full response"
          maxCharLimit={CANDIDATE_RESPONSE_MAX_CHAR_LENGTH}
        />
      </BodySmall>
    </Stack>
  );
};

const LastInterviewStageButtons = ({ candidateBio }: { candidateBio: CandidateBio }): React.ReactElement => {
  const [updateCandidateBio, { isLoading: updateCandidateBioLoading }] = useUpdateCandidateBioMutation();

  const handleAdvanceToNextStage = async (): Promise<void> => {
    if (candidateBio.id) {
      const stage = candidateBio.nextAction?.nextHiringPipelineStage;

      await updateCandidateBio({
        id: candidateBio.id,
        data: {
          currentPipelineStage: stage?.id,
          currentPipelineSubstage: 0,
        },
        hideToast: true,
        skipTagInvalidation: true,
        candidatePipelineStage: stage
          ? {
              id: stage.id,
              name: stage.name,
              stageType: stage.stageType,
              milestone: stage.milestone,
              orderIndex: stage.orderIndex ?? undefined,
              substage: 0,
            }
          : undefined,
      });
    }
  };

  const handleReject = async (): Promise<void> => {
    if (candidateBio.id) {
      await updateCandidateBio({
        id: candidateBio.id,
        data: {
          status: UpdateCandidateBioBodyStatusEnum.Rejected,
        },
        hideToast: true,
        skipTagInvalidation: true,
      });
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <Button variant={ButtonVariant.Secondary} onClick={handleAdvanceToNextStage} disabled={updateCandidateBioLoading}>
        <Stack direction="row" spacing={1} alignItems="center">
          <RightArrowIcon width="15px" />
          <BodySmall>Offer Extended</BodySmall>
        </Stack>
      </Button>
      <Button variant={ButtonVariant.Secondary} onClick={handleReject} disabled={updateCandidateBioLoading}>
        <Stack direction="row" spacing={1} alignItems="center">
          <RejectThumbDown width="15px" height="15px" className="svg-fill" color={colors.critical.base} />
          <BodySmall>Reject</BodySmall>
        </Stack>
      </Button>
    </Stack>
  );
};

const LastInterviewStageInfoText = (): React.ReactElement => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <InfoIcon className="svg-color" color={colors.grayscale.gray400} width="15px" height="15px" />
      <BodySmall color={colors.grayscale.gray400}>
        As a reminder, you will be responsible for sending next steps
      </BodySmall>
    </Stack>
  );
};

const LastActionLabel = styled(BodySmall)`
  font-size: 13px;
  line-height: 20px;
`;

const MentionLowerCardContent = ({ candidate }: { candidate: PipelineCandidate }): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const [updateMention] = usePartialUpdateMentionMutation();

  // We don't show read mentions on reload, so can just track the state here
  // We track this in jotai so that the state can persist even when this component is unmounted
  // for example like on a page navigation
  const [readMentions, setReadMentions] = useAtom(readMentionsAtom);

  const setMarkedAsRead = (markedAsRead: boolean, mentionId?: string): void => {
    if (mentionId) {
      setReadMentions({ ...readMentions, [mentionId]: markedAsRead });
      updateMention({ id: mentionId, data: { markedAsRead: markedAsRead } });
    }
  };

  return (
    <Stack
      p="6px 16px"
      bgcolor={colors.grayscale.gray100}
      borderTop={`1px solid ${colors.grayscale.gray200}`}
      spacing={1}
      divider={<Divider sx={{ marginX: "-16px !important" }} />}
    >
      {candidate.mentions?.map(mention => {
        if (!mention.id) {
          return;
        }

        return (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack>
              <Subtitle2>{`${mention.mentionersName} mentioned you:`}</Subtitle2>
              <Body>{getPlainTextFromHtml(mention?.content ?? "")}</Body>
            </Stack>
            {Object.prototype.hasOwnProperty.call(readMentions, mention.id) && readMentions[mention.id] ? (
              <Stack direction="row" spacing={0.75} alignItems="center">
                <CheckGreenFilledIcon height="16px" width="16px" />
                <Body>Marked as read</Body>
                <Button
                  removePadding
                  variant={ButtonVariant.Ghost}
                  onClick={setMarkedAsRead.bind(null, false, mention.id)}
                >
                  <Box sx={{ textDecoration: "underline" }}>Undo</Box>
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" spacing={1}>
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={(): void => {
                    const queryParams = new URLSearchParams(location.search);
                    queryParams.set("activeFilter", "notes");

                    navigate(
                      APP_ROUTE_PATHS.review({
                        candidateId: candidate.id,
                        queryParams,
                      })
                    );
                  }}
                >
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <ChatIcon />
                    <ButtonText>Reply</ButtonText>
                  </Stack>
                </Button>
                <Button
                  variant={ButtonVariant.Secondary}
                  tooltip="Mark as read"
                  onClick={setMarkedAsRead.bind(null, true, mention.id)}
                >
                  <CheckIcon />
                </Button>
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default CandidateCard;
