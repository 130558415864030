import { Stack } from "@mui/material";
import React, { FC } from "react";

import { ReactComponent as NoEmailIcon } from "assets/icons/no-email.svg";
import { Warning } from "components/dover/top-level-modal-manager/hooks/useValidate";
import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall } from "components/library/typography";

export const WarningBanner: FC<{ warning?: Warning }> = ({ warning }) => {
  if (!warning) {
    return null;
  }

  return (
    <Banner
      variant={warning.useErrorColor ? BannerVariant.Critical : BannerVariant.Warning}
      overrideIcon={<NoEmailIcon width="28px" />}
    >
      <Stack>
        <BodySmall weight="500">{warning.title}</BodySmall>
        <BodySmall>{warning.body}</BodySmall>
      </Stack>
    </Banner>
  );
};
