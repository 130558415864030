/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientFromJSONTyped,
    ClientToJSON,
} from './';

/**
 * 
 * @export
 * @interface GenerateCareersPageResponse
 */
export interface GenerateCareersPageResponse {
    /**
     * 
     * @type {Client}
     * @memberof GenerateCareersPageResponse
     */
    client: Client;
}

export function GenerateCareersPageResponseFromJSON(json: any): GenerateCareersPageResponse {
    return GenerateCareersPageResponseFromJSONTyped(json, false);
}

export function GenerateCareersPageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateCareersPageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client': ClientFromJSON(json['client']),
    };
}

export function GenerateCareersPageResponseToJSON(value?: GenerateCareersPageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': ClientToJSON(value.client),
    };
}


