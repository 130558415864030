import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import { EmailUser, EmailOwner } from "components/library/TipTap/types";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import { CandidateBioCompletedInterview, ClientInterviewerSetupStateEnum } from "services/openapi";

/**
 * This hook is used to determine if the editor should be disabled for a given Email User.
 */
export function useShouldDisableEditorForEmailUser(user: EmailUser): boolean {
  // Skip determining auth status if Email User is a Dover shell account
  const { userAuthedSuccessfully } = useUserAuthedSuccessfully(user.owner === EmailOwner.Dover);
  return !userAuthedSuccessfully || userAuthedSuccessfully === undefined;
}

interface InterviewPreferencesCheckReturnType {
  // Useful for a quick check about valiadity of interviewer preferences among one or more interviewers
  hasInvalidInterviewers: boolean;
  // Userful for a more granular check about validity of interviewer preferences for a single interviewer
  interviewerIdsToValidate: { [interviewerId: string]: boolean };
}

interface AreInterviewPreferencesCompleteForInterviewerIdsArgs {
  interviewerIds: string[];
  shouldSkip?: boolean;
}

/**
 * This hook is used to determine if the interview preferences for a given set of interviewers are complete.
 * @remarks - We may not want to block on interviewer preferences if the customer is handling scheduling
 *          - This was at least the logic in the past, but this logic is not included here
 *          - FYI Max McKinley <max.mckinley@dover.com>
 */
export function useAreInterviewPreferencesCompleteForInterviewerIds({
  interviewerIds,
  shouldSkip,
}: AreInterviewPreferencesCompleteForInterviewerIdsArgs): InterviewPreferencesCheckReturnType {
  // First, fetch all interviewers, or skip
  const allInterviewers = useListInterviewerEntities(shouldSkip);

  // Then, filter down to just the ones we care about based on the IDs that were passed in
  // If skipping, this will be empty
  const interviewers = useMemo(() => {
    return allInterviewers.filter((i: any) => interviewerIds.includes(i.id));
  }, [interviewerIds, allInterviewers]);

  // If skipping, this will be empty as well
  const invalidInterviewers = useMemo(() => {
    return interviewers.filter(interviewer => {
      return (
        interviewer.id !== "DOVER_INTERVIEWER" &&
        interviewer.id !== "DOVER_INTERVIEWER_ID" &&
        interviewer.setupState !== ClientInterviewerSetupStateEnum.Ready
      );
    });
  }, [interviewers]);

  return {
    hasInvalidInterviewers: invalidInterviewers.length > 0 || !!shouldSkip,
    // No need to check for skipping in the interviewIdsToValidate field as this will already be an empty array if skipping
    interviewerIdsToValidate: invalidInterviewers.reduce((acc, interviewer) => {
      acc[interviewer.id!] = true;
      return acc;
    }, {} as { [interviewerId: string]: boolean }),
  };
}

interface HasCandidateCompletedInterviewForSelectedStageArgs {
  candidateId: string;
  selectedStageId: string;
  shouldSkip?: boolean;
}

/**
 * This hook is used to determine if a candidate has completed an interview for a given stage.
 * @remarks - Not all candidate-action-related scenarios will desire this check, e.g. rescheudling an interview
 *          - Therefore, shouldSkip is included as a convenience mechanism to skip this check from the consumer layer
 */
export function useHasCandidateCompletedInterviewForSelectedStage({
  candidateId,
  selectedStageId,
  shouldSkip,
}: HasCandidateCompletedInterviewForSelectedStageArgs): boolean {
  const { data: candidateBio } = useGetCandidateBioQuery(shouldSkip ? skipToken : candidateId);

  if (shouldSkip) {
    return false;
  }

  return !!candidateBio?.completedInterviews?.find(
    (interview: CandidateBioCompletedInterview): boolean => interview.hiringPipelineStageId === selectedStageId
  );
}
