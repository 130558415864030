import { convertDoverUserToBasicEmailOption } from "components/dover/CustomizeNextSteps/components/utils";
import { BasicEmailOption } from "components/library/TipTap/types";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { DoverUser } from "services/openapi";

export const useCcOptions = (): BasicEmailOption[] | undefined => {
  // Get the pro users for the signed in client
  const { proUsers } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsers,
        };
      },
    }
  );

  if (!proUsers) {
    return undefined;
  }

  // Convert to proper data type for TipTap
  // Ensure that each option at least has an email
  const ccOptions = proUsers
    .filter(user => !!user.email)
    .map(convertDoverUserToBasicEmailOption)
    // Filter out users that were missing data and thus failed to convert
    .filter(user => !!user);

  return ccOptions as BasicEmailOption[];
};
