import { ReactComponent as CriteriaIcon } from "@doverhq/dover-ui/icons/criteria.svg";
import { Box, RadioGroup, Stack } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";

import { ReactComponent as BellIcon } from "assets/icons/bell.svg";
import { CalibrationSettingAtom } from "components/dover/dover-outbound-modal/atoms/calibrationSettingAtoms";
import { CalibrationSettingCard } from "components/dover/dover-outbound-modal/CalibrationSettingCard";
import {
  DEFAULT_EMBEDDED_RECRUITER_PICTURE_URL,
  SourcingCalibrationSettingOptions,
} from "components/dover/dover-outbound-modal/constants";
import { TitleLarge } from "components/library/typography";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";

interface CalibrationSettingsProps {
  initialCalibrationSetting?: SourcingCalibrationSettingOptions;
}

// Calibration settings options for managing job-level depth alerting
export const CalibrationSettings = ({ initialCalibrationSetting }: CalibrationSettingsProps): React.ReactElement => {
  const conciergeInfo = useConciergeInfo();
  const isBasePlanCustomer = useIsBasePlanCustomer();
  // Set default Recruiting Partner photo to Brittany for free customers in gated onboarding flow
  const pictureUrl = isBasePlanCustomer ? DEFAULT_EMBEDDED_RECRUITER_PICTURE_URL : conciergeInfo?.pictureUrl;

  const [selectedCalibrationSetting, setSelectedCalibrationSetting] = useAtom(CalibrationSettingAtom);
  const optionIsSelected = (option: SourcingCalibrationSettingOptions): boolean => {
    return (
      selectedCalibrationSetting === option || (!selectedCalibrationSetting && initialCalibrationSetting === option)
    );
  };

  return (
    <>
      <Box padding="20px 24px 16px">
        <Stack direction="column" spacing={2}>
          <TitleLarge>When Sourcing Autopilot is about to run out of candidates, it should...</TitleLarge>
          <RadioGroup name="selectCalibrationSettings">
            <Stack spacing={1.5}>
              <CalibrationSettingCard
                selected={optionIsSelected(SourcingCalibrationSettingOptions.AutoAdjust)}
                label="Auto-adjust my criteria (recommended)"
                description="Automatically calibrate your criteria to open up your candidate pool"
                icon={CriteriaIcon}
                onClick={(): void => setSelectedCalibrationSetting(SourcingCalibrationSettingOptions.AutoAdjust)}
              />
              <CalibrationSettingCard
                selected={optionIsSelected(SourcingCalibrationSettingOptions.NotifyMe)}
                label="Notify me"
                description="You will receive Slack and email notifications to update your criteria"
                icon={BellIcon}
                onClick={(): void => setSelectedCalibrationSetting(SourcingCalibrationSettingOptions.NotifyMe)}
              />
              <CalibrationSettingCard
                selected={optionIsSelected(SourcingCalibrationSettingOptions.EmbeddedRecruiter)}
                label="Notify my Recruiting Partner"
                description="Your recruiting partner will get notified to update your criteria for you"
                pictureUrl={pictureUrl}
                onClick={(): void => setSelectedCalibrationSetting(SourcingCalibrationSettingOptions.EmbeddedRecruiter)}
              />
            </Stack>
          </RadioGroup>
        </Stack>
      </Box>
    </>
  );
};
