import React from "react";

import { Autocomplete } from "components/library/Autocomplete/Autocomplete";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { DoverUser } from "services/openapi/models";

interface PassedProps {
  setSelectedProUser: (selectedProUser: number | undefined) => void;
}

const ProUserAutocomplete = ({ setSelectedProUser }: PassedProps): React.ReactElement => {
  const { proUsers } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsers,
        };
      },
    }
  );

  if (proUsers === undefined) {
    return <></>;
  }
  return (
    <Autocomplete
      placeholder={"Select a user..."}
      staticOptions={proUsers}
      onSelectedOptionChange={(selectedOption: DoverUser): void => {
        setSelectedProUser(selectedOption.id);
      }}
      multiple={false}
      getOptionLabel={(option: DoverUser): string => option.fullName || option.email || ""}
    />
  );
};

export default ProUserAutocomplete;
