import { CompanySetupSectionType } from "App/routing/types";
import { useIsContractPlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { companySectionConfiguration, SECTIONS_ONLY_FOR_PAID_CUSTOMERS } from "views/CompanySetup/constants";

export const useGetCompanySectionConfigurationKeys = (): CompanySetupSectionType[] => {
  const clientIsNotPaid = !useIsContractPlanCustomer();

  // remove some stuff not accessible to free people
  if (clientIsNotPaid) {
    return Object.keys(companySectionConfiguration).filter(
      (section: string) => !SECTIONS_ONLY_FOR_PAID_CUSTOMERS.includes(section as CompanySetupSectionType)
    ) as CompanySetupSectionType[];
  }
  return Object.keys(companySectionConfiguration) as CompanySetupSectionType[];
};
