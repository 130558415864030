import { Box, MenuItem, Stack, Select } from "@mui/material";
import React from "react";

import { INVALID_PERMS_TOOLTIP_TEXT } from "components/constants";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import { DoverRole } from "services/openapi";

interface PermissionsSelectorProps {
  options: DoverRole[];
  value: DoverRole | undefined;
  isLoading?: boolean;
  setValue: (val: string | undefined) => void;
  disabled?: boolean;
  styles?: object;
  showVerboseCopyText?: boolean;
}

export const PermissionsSelector = ({
  options,
  value,
  setValue,
  isLoading,
  disabled,
  styles,
  showVerboseCopyText,
}: PermissionsSelectorProps): React.ReactElement => {
  const disabledTooltipText = disabled ? INVALID_PERMS_TOOLTIP_TEXT : undefined;
  const onChange = (event: any): void => {
    if (!event.target.value) {
      return;
    }
    const newRole = options.find(option => option.id === event.target.value);
    if (!newRole) {
      return;
    }
    setValue(newRole.id);
  };

  return (
    <Tooltip title={disabledTooltipText}>
      <Box>
        <Select
          value={value?.id}
          onChange={onChange}
          // this sx is to get rid of double outline and remove the big padding
          sx={styles}
          size="small"
          disabled={isLoading || disabled}
          renderValue={(value): React.ReactElement => (
            <Stack spacing={1} direction="row">
              {showVerboseCopyText && <BodySmall weight="600">Auto-assign new users as:</BodySmall>}
              <BodySmall>{options.find(option => option.id === value)?.cleanName!}</BodySmall>
            </Stack>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {options.map(option => (
            <MenuItem key={option.id} value={option.id}>
              <Stack direction="column">
                <BodySmall>{option.cleanName}</BodySmall>
                {option.description && <BodyExtraSmall color="gray">{option.description}</BodyExtraSmall>}
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Tooltip>
  );
};
