import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Button, ButtonVariant } from "components/library/Button";

const NotFoundBox = styled(Box)`
  .form-signin {
    width: 100%;
    max-width: 640px;
    padding: 15px;
    margin: 0 auto;
  }
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
  }
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  .form-label-group input::placeholder {
    color: transparent;
  }
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
  }
  .paragraph-bigger.cc-bigger-light {
    opacity: 1;
    font-family: neue-haas-grotesk-text, sans-serif;
    color: #312f4a;
    font-size: 24px;
    line-height: 110%;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
  }
`;

const NotFound = (): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <Box pt={8}>
      <NotFoundBox display="flex" justifyContent="center" m="auto">
        <form className="form-signin">
          <Box className="text-center mb-4">
            <img
              className="mb-4"
              src="https://uploads-ssl.webflow.com/5cbe0c945e636f107c79b29e/5d40e717eee5234153ff2570_dover-logo-script-aqua.svg"
              alt=""
              width="auto"
              height="72"
            />
          </Box>
          <Box className="text-center mb-4">404 - Page Not Found</Box>
          <Box className="alert alert-danger" role="alert">
            Whoops! 😢 Looks like you navigated to the wrong page. If you think this is a mistake, reach out to us at{" "}
            <a href="mailto:support@dover.com?Subject=Login%20issue" target="_top">
              support@dover.com
            </a>{" "}
            or visit our{" "}
            <a href="https://help.dover.com/en/" rel="noopener noreferrer" target="_blank">
              help center
            </a>
            .
          </Box>
          <Stack width="100%">
            <Button
              variant={ButtonVariant.Secondary}
              onClick={(): void => navigate(APP_ROUTE_PATHS.home(), { replace: true })}
            >
              Go to our home page!
            </Button>
          </Stack>
        </form>
      </NotFoundBox>
    </Box>
  );
};

export default NotFound;
