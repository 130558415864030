/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorldRegionResponse
 */
export interface WorldRegionResponse {
    /**
     * 
     * @type {string}
     * @memberof WorldRegionResponse
     */
    regionName: string;
}

export function WorldRegionResponseFromJSON(json: any): WorldRegionResponse {
    return WorldRegionResponseFromJSONTyped(json, false);
}

export function WorldRegionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorldRegionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regionName': json['region_name'],
    };
}

export function WorldRegionResponseToJSON(value?: WorldRegionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region_name': value.regionName,
    };
}


