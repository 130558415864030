/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobCandidateSourceStats
 */
export interface JobCandidateSourceStats {
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceStats
     */
    readonly candidateSourceId?: string;
    /**
     * 
     * @type {number}
     * @memberof JobCandidateSourceStats
     */
    readonly numApplicantsReceived?: number;
    /**
     * 
     * @type {number}
     * @memberof JobCandidateSourceStats
     */
    readonly avgNumCandidatesPerJob?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobCandidateSourceStats
     */
    readonly numUniqueJobs?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobCandidateSourceStats
     */
    readonly avgNumCandidatesPerJobF60d?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobCandidateSourceStats
     */
    readonly numUniqueJobsF60d?: number | null;
}

export function JobCandidateSourceStatsFromJSON(json: any): JobCandidateSourceStats {
    return JobCandidateSourceStatsFromJSONTyped(json, false);
}

export function JobCandidateSourceStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobCandidateSourceStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateSourceId': !exists(json, 'candidate_source_id') ? undefined : json['candidate_source_id'],
        'numApplicantsReceived': !exists(json, 'num_applicants_received') ? undefined : json['num_applicants_received'],
        'avgNumCandidatesPerJob': !exists(json, 'avg_num_candidates_per_job') ? undefined : json['avg_num_candidates_per_job'],
        'numUniqueJobs': !exists(json, 'num_unique_jobs') ? undefined : json['num_unique_jobs'],
        'avgNumCandidatesPerJobF60d': !exists(json, 'avg_num_candidates_per_job_f60d') ? undefined : json['avg_num_candidates_per_job_f60d'],
        'numUniqueJobsF60d': !exists(json, 'num_unique_jobs_f60d') ? undefined : json['num_unique_jobs_f60d'],
    };
}

export function JobCandidateSourceStatsToJSON(value?: JobCandidateSourceStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


