import { Stack } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import { ReactComponent as CheckGreenFilled } from "assets/icons/check-green-filled.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Body, Heading } from "components/library/typography";
import { SubmitReferralFormSchemaType } from "views/ReferralForm/types";

interface SuccessScreenProps {
  addAnother: () => void;
}

export const SuccessScreen = ({ addAnother }: SuccessScreenProps): React.ReactElement => {
  const { getValues, reset: resetForm } = useFormContext<SubmitReferralFormSchemaType>();
  const candidateName = `${getValues("candidateFirstName")} ${getValues("candidateLastName")}`;

  const handleAddAnother = (): void => {
    resetForm({
      // keep referrer info
      ...getValues(),
      // clear candidate info
      candidateFirstName: "",
      candidateLastName: "",
      candidateEmail: "",
      resume: null,
      additionalComments: "",
      candidateLinkedinUrl: "",
    });
    addAnother();
  };

  return (
    <Card fullWidth>
      <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
        <CheckGreenFilled width={"40px"} />
        <Heading weight="600">Candidate submitted!</Heading>
        <Body>{candidateName} has been added to the pipeline</Body>
        <Button width="fit-content" variant={ButtonVariant.Primary} onClick={handleAddAnother}>
          Add another
        </Button>
      </Stack>
    </Card>
  );
};
