import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { candidateEndpointsTemp } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import {
  CANDIDATE_BIO,
  CANDIDATE_EMAIL_EVENT,
  CANDIDATE_INTERVIEW_EVENT,
  CANDIDATE_INTERVIEW_RUBRIC_RESPONSE,
  CANDIDATE_JOB_APPLICATION_EVENT,
  CANDIDATE_MOVED_JOB_EVENT,
  CANDIDATE_STAGE_CHANGE_EVENT,
  DOVER_INTERVIEWER_CANDIDATES,
  FIND_DUPE_CONTACT,
  FIND_RELATED_CANDIDATES,
  GET_EMAIL_TEMPLATE_V2,
  LIST_CONTACT_EMAILS,
  LIST_CONTACT_PHONE_NUMBERS,
  LIST_TAG,
  PIPELINE_CANDIDATE,
  SAAP_REVIEW_SCORED_APPLICATION_LIST,
  STARRED_CANDIDATE_LIST,
} from "services/doverapi/endpointTagsConstants";
import {
  ApiApiCreateContactEmailsOperationRequest,
  ApiApiCreateContactPhoneNumbersOperationRequest,
  ApiApiDeleteContactEmailRequest,
  ApiApiDeleteContactPhoneNumberRequest,
  ApiApiFindDupeContactRequest,
  ApiApiFindRelatedCandidatesRequest,
  ApiApiListContactEmailsRequest,
  ApiApiListContactPhoneNumbersRequest,
  ApiApiMergeContactsOperationRequest,
  ApiApiPartialUpdateContactEmailRequest,
  ApiApiPartialUpdateContactPhoneNumberRequest,
  ApiApiPartialUpdateContactRequest,
  Contact,
  ContactEmail,
  ContactPhoneNumber,
  FindDupeContactResponse,
  InlineResponse20024,
  InlineResponse20025,
  MergeContactsRequest,
  RelatedCandidate,
} from "services/openapi";

// Optionally include candidate ID for optimistic updates to candidate bio + related resources
export interface PartialUpdateContactRequest extends ApiApiPartialUpdateContactRequest {
  candidateId?: string;
}

export interface PartialUpdateContactEmailRequest extends ApiApiPartialUpdateContactEmailRequest {
  candidateId?: string;
}

export interface CreateContactEmailRequest extends ApiApiCreateContactEmailsOperationRequest {
  candidateId?: string;
}

export interface DeleteContactEmailRequest extends ApiApiDeleteContactEmailRequest {
  candidateId?: string;
}

export const contactEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listContactEmails: build.query<InlineResponse20024, ApiApiListContactEmailsRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data;
        try {
          data = await client.listContactEmails(args);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      providesTags: [{ type: LIST_CONTACT_EMAILS, id: LIST_TAG }],
    }),
    listContactPhoneNumbers: build.query<InlineResponse20025, ApiApiListContactPhoneNumbersRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data;
        try {
          data = await client.listContactPhoneNumbers(args);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      providesTags: [{ type: LIST_CONTACT_PHONE_NUMBERS, id: LIST_TAG }],
    }),
    findDupeContact: build.query<FindDupeContactResponse, ApiApiFindDupeContactRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data;
        try {
          data = await client.findDupeContact(args);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      providesTags: [{ type: FIND_DUPE_CONTACT, id: LIST_TAG }],
    }),
    findRelatedCandidates: build.query<Array<RelatedCandidate>, ApiApiFindRelatedCandidatesRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data;
        try {
          data = await client.findRelatedCandidates(args);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      providesTags: [{ type: FIND_RELATED_CANDIDATES, id: LIST_TAG }],
    }),
    createContactEmails: build.mutation<void, CreateContactEmailRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        // We only use candidate ID for optimistic updates; shouldn't be passed into the request
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { candidateId: _, ...actualArgs } = args;

        let data;
        try {
          data = await client.createContactEmails(actualArgs);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      invalidatesTags: (result, error, { candidateId }: CreateContactEmailRequest) => {
        const tags = [];
        tags.push({ type: LIST_CONTACT_EMAILS, id: LIST_TAG } as const);

        if (candidateId) {
          tags.push({ type: GET_EMAIL_TEMPLATE_V2, id: candidateId } as const);
        }

        return tags;
      },
      onQueryStarted: async ({ data: { contactId, emails } }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          contactEndpoints.util.updateQueryData("listContactEmails", { contactId }, draft => {
            const newEmails: Array<ContactEmail> = emails.map(email => ({ email, contactId }));

            draft.count += newEmails.length;
            draft.results.push(...newEmails);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patch?.undo();
        }
      },
    }),
    createContactPhoneNumbers: build.mutation<void, ApiApiCreateContactPhoneNumbersOperationRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data;
        try {
          data = await client.createContactPhoneNumbers(args);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      invalidatesTags: [{ type: LIST_CONTACT_PHONE_NUMBERS, id: LIST_TAG }],
      onQueryStarted: async ({ data: { contactId, phoneNumbers } }, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          contactEndpoints.util.updateQueryData("listContactPhoneNumbers", { contactId }, draft => {
            const newPhoneNumbers: Array<ContactPhoneNumber> = phoneNumbers.map(phoneNumber => ({
              phoneNumber,
              contactId,
            }));

            draft.count += newPhoneNumbers.length;
            draft.results.push(...newPhoneNumbers);
          })
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patch?.undo();
        }
      },
    }),
    partialUpdateContact: build.mutation<Contact, PartialUpdateContactRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        // We only use candidate ID for optimistic updates; shouldn't be passed into the request
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { candidateId: _, ...actualArgs } = args;

        let data;
        try {
          data = await client.partialUpdateContact(actualArgs);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      invalidatesTags: (
        result,
        error,
        { data: { primaryEmailId, primaryPhoneNumberId }, candidateId }: PartialUpdateContactRequest
      ) => {
        const tags = [];
        tags.push(
          { type: FIND_DUPE_CONTACT, id: LIST_TAG } as const,
          { type: FIND_RELATED_CANDIDATES, id: LIST_TAG } as const
        );

        if (primaryEmailId) {
          tags.push({ type: LIST_CONTACT_EMAILS, id: LIST_TAG } as const);
        }

        if (primaryPhoneNumberId) {
          tags.push({ type: LIST_CONTACT_PHONE_NUMBERS, id: LIST_TAG } as const);
        }

        if (candidateId) {
          tags.push(
            { type: PIPELINE_CANDIDATE, id: candidateId } as const,
            { type: STARRED_CANDIDATE_LIST, id: candidateId } as const,
            { type: DOVER_INTERVIEWER_CANDIDATES, id: candidateId } as const,
            { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: candidateId } as const,
            { type: SAAP_REVIEW_SCORED_APPLICATION_LIST, id: candidateId } as const
          );

          tags.push(
            { type: GET_EMAIL_TEMPLATE_V2, id: candidateId } as const,
            { type: CANDIDATE_INTERVIEW_EVENT, id: candidateId } as const,
            { type: CANDIDATE_EMAIL_EVENT, id: candidateId } as const,
            { type: CANDIDATE_JOB_APPLICATION_EVENT, id: candidateId } as const,
            { type: CANDIDATE_STAGE_CHANGE_EVENT, id: candidateId } as const,
            { type: CANDIDATE_MOVED_JOB_EVENT, id: candidateId } as const
          );
        } else {
          tags.push(
            { type: PIPELINE_CANDIDATE } as const,
            { type: PIPELINE_CANDIDATE, id: LIST_TAG } as const,
            { type: STARRED_CANDIDATE_LIST } as const,
            { type: DOVER_INTERVIEWER_CANDIDATES } as const,
            { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE } as const,
            { type: SAAP_REVIEW_SCORED_APPLICATION_LIST } as const
          );
        }

        return tags;
      },
      onQueryStarted: async (args: PartialUpdateContactRequest, { dispatch, queryFulfilled }) => {
        // If a candidate ID is in scope, optimistically update contact info via candidate bio API
        if (args.candidateId) {
          const patch = dispatch(
            candidateEndpointsTemp.util.updateQueryData("getCandidateBio", args.candidateId, draft => {
              draft.contact = { ...draft.contact, ...args.data };
            })
          );

          try {
            await queryFulfilled;
          } catch {
            patch.undo();
          }
        }
      },
    }),
    partialUpdateContactEmail: build.mutation<ContactEmail, PartialUpdateContactEmailRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        // We only use candidate ID for optimistic updates; shouldn't be passed into the request
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { candidateId: _, ...actualArgs } = args;

        let data;
        try {
          data = await client.partialUpdateContactEmail(actualArgs);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      invalidatesTags: (result, error, { candidateId }: PartialUpdateContactEmailRequest) => {
        const tags = [];
        tags.push({ type: LIST_CONTACT_EMAILS, id: LIST_TAG } as const);

        if (candidateId) {
          tags.push({ type: GET_EMAIL_TEMPLATE_V2, id: candidateId } as const);
        }

        return tags;
      },
    }),
    partialUpdateContactPhoneNumber: build.mutation<ContactPhoneNumber, ApiApiPartialUpdateContactPhoneNumberRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data;
        try {
          data = await client.partialUpdateContactPhoneNumber(args);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      invalidatesTags: [{ type: LIST_CONTACT_PHONE_NUMBERS, id: LIST_TAG }],
    }),
    deleteContactEmail: build.mutation<void, DeleteContactEmailRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        // We only use candidate ID for optimistic updates; shouldn't be passed into the request
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { candidateId: _, ...actualArgs } = args;

        let data;
        try {
          data = await client.deleteContactEmail(actualArgs);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      invalidatesTags: (result, error, { candidateId }: DeleteContactEmailRequest) => {
        const tags = [];
        tags.push({ type: LIST_CONTACT_EMAILS, id: LIST_TAG } as const);

        if (candidateId) {
          tags.push({ type: GET_EMAIL_TEMPLATE_V2, id: candidateId } as const);
        }

        return tags;
      },
    }),
    deleteContactPhoneNumber: build.mutation<void, ApiApiDeleteContactPhoneNumberRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data;
        try {
          data = await client.deleteContactPhoneNumber(args);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      invalidatesTags: [{ type: LIST_CONTACT_PHONE_NUMBERS, id: LIST_TAG }],
    }),
    mergeContacts: build.mutation<MergeContactsRequest, ApiApiMergeContactsOperationRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let data;
        try {
          data = await client.mergeContacts(args);
        } catch (e) {
          return {
            error: {
              serializedError: e as SerializedError,
            },
          };
        }

        return { data };
      },
      invalidatesTags: [
        { type: LIST_CONTACT_EMAILS, id: LIST_TAG } as const,
        { type: LIST_CONTACT_PHONE_NUMBERS, id: LIST_TAG },
        { type: FIND_DUPE_CONTACT, id: LIST_TAG } as const,
        { type: FIND_RELATED_CANDIDATES, id: LIST_TAG } as const,
        { type: CANDIDATE_BIO } as const,
        { type: PIPELINE_CANDIDATE } as const,
        { type: PIPELINE_CANDIDATE, id: LIST_TAG } as const,
        { type: STARRED_CANDIDATE_LIST } as const,
        { type: DOVER_INTERVIEWER_CANDIDATES } as const,
        { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE } as const,
        { type: SAAP_REVIEW_SCORED_APPLICATION_LIST } as const,
      ],
    }),
  }),
});

export const {
  useListContactEmailsQuery,
  useListContactPhoneNumbersQuery,
  useFindDupeContactQuery,
  useFindRelatedCandidatesQuery,
  useCreateContactEmailsMutation,
  useCreateContactPhoneNumbersMutation,
  usePartialUpdateContactMutation,
  usePartialUpdateContactEmailMutation,
  usePartialUpdateContactPhoneNumberMutation,
  useDeleteContactEmailMutation,
  useDeleteContactPhoneNumberMutation,
  useMergeContactsMutation,
} = contactEndpoints;
