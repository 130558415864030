import clipboardCopy from "clipboard-copy";

import { appConfig } from "config/appConfig";

export const copyAgencyLink = ({ clientId, referrerId }: { clientId: string; referrerId: string }): void => {
  if (!clientId) {
    return;
  }

  const agencyLink = `${appConfig.appUrl}/agency-portal/${referrerId}?clientId=${clientId}`;

  clipboardCopy(agencyLink);
};
