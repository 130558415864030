import { z } from "zod";

export const addAgencyFormSchema = z.object({
  jobId: z.string().min(1, { message: "Required" }),
  agencyName: z.string().min(1, { message: "Required" }),
  email: z
    .string()
    .min(1, "Required")
    .email({ message: "Invalid email" }),
  firstName: z.string().min(1, { message: "Required" }),
  lastName: z.string().min(1, { message: "Required" }),
  rate: z
    .string()
    .regex(/^(0|[1-9]\d*)(\.\d+)?$/, { message: "Invalid rate" })
    .min(1, { message: "Required" }),
  rateType: z.string().min(1, { message: "Required" }),
});

export type AddAgencyFormSchema = z.infer<typeof addAgencyFormSchema>;
