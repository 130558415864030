/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Persona,
    PersonaFromJSON,
    PersonaFromJSONTyped,
    PersonaToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPersonaForJobResponse
 */
export interface GetPersonaForJobResponse {
    /**
     * 
     * @type {Persona}
     * @memberof GetPersonaForJobResponse
     */
    persona: Persona;
}

export function GetPersonaForJobResponseFromJSON(json: any): GetPersonaForJobResponse {
    return GetPersonaForJobResponseFromJSONTyped(json, false);
}

export function GetPersonaForJobResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPersonaForJobResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'persona': PersonaFromJSON(json['persona']),
    };
}

export function GetPersonaForJobResponseToJSON(value?: GetPersonaForJobResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'persona': PersonaToJSON(value.persona),
    };
}


