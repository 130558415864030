import React from "react";

import { AppId } from "App/appConstants";
import { StyledAlignedSlider } from "components/library/YOESlider/styles";
import { computeYOESliderValues } from "components/library/YOESlider/utils";
import ValueLabel, { ValueLabelProps } from "components/library/YOESlider/ValueLabel";
import { useIsInViewport } from "hooks/useIsInViewport";

export interface YearsOfExperienceRange {
  min?: number | null;
  max?: number | null;
}

interface YOESliderProps {
  values: YearsOfExperienceRange;
  onChange: (yearsOfExperience: YearsOfExperienceRange) => void;
  maximumValue: number;
  minimumRange: number;
  shouldShowTooltip: boolean;
  marks?: boolean;
  scrollContainerId?: string;
}

const YOESlider = React.memo(
  ({
    values,
    onChange,
    minimumRange,
    maximumValue,
    shouldShowTooltip,
    marks,
    scrollContainerId = AppId,
  }: YOESliderProps): React.ReactElement => {
    const sliderRef = React.useRef<HTMLSpanElement>(null);
    const isInViewport = useIsInViewport(sliderRef);

    const handleChange = React.useCallback(
      (event: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
          return;
        }

        if (activeThumb === 0) {
          const min: number | null = Math.min(newValue[0], (values.max ?? maximumValue) - minimumRange);
          // If not set, this will remain not set since this thumb was not moved
          const max = values.max;

          if (values.min !== min || values.max !== max) {
            onChange({ min, max });
          }
        } else {
          const min = values.min;
          const max = newValue[1] > maximumValue ? null : Math.max(newValue[1], (values.min ?? 0) + minimumRange);
          if (values.min !== min || values.max !== max) {
            onChange({ min, max });
          }
        }
      },
      [values.max, values.min, maximumValue, minimumRange, onChange]
    );

    const [isScrolling, setIsScrolling] = React.useState<boolean>(false);

    React.useEffect(() => {
      document.getElementById(scrollContainerId)!.addEventListener("scroll", () => {
        setIsScrolling(true);
      });
      const timer = setInterval(() => {
        setIsScrolling(false);
      }, 1000);
      return (): void => {
        clearTimeout(timer);
      };
    }, [scrollContainerId]);

    return (
      <StyledAlignedSlider
        ref={sliderRef}
        value={computeYOESliderValues(values, minimumRange, maximumValue)}
        onChange={handleChange}
        valueLabelDisplay="auto"
        max={maximumValue}
        marks={marks}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          ValueLabel: (props: ValueLabelProps): React.ReactElement => (
            <ValueLabel value={props.value} isOpen={shouldShowTooltip && !isScrolling && isInViewport}>
              {props.children}
            </ValueLabel>
          ),
        }}
        disableSwap
        valueLabelFormat={(value: number): React.ReactNode => {
          return value < maximumValue ? `${Math.round(value)}` : "UNLIMITED";
        }}
      />
    );
  }
);

export default YOESlider;
