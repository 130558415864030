/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AtsSource
 */
export interface AtsSource {
    /**
     * 
     * @type {string}
     * @memberof AtsSource
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof AtsSource
     */
    readonly name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AtsSource
     */
    readonly atsType?: AtsSourceAtsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AtsSource
     */
    defaultState: AtsSourceDefaultStateEnum;
    /**
     * 
     * @type {string}
     * @memberof AtsSource
     */
    readonly displaySection?: string;
}

/**
* @export
* @enum {string}
*/
export enum AtsSourceAtsTypeEnum {
    Greenhouse = 'GREENHOUSE',
    Lever = 'LEVER',
    Ashby = 'ASHBY',
    GoogleHire = 'GOOGLE_HIRE',
    NoAts = 'NO_ATS',
    Other = 'OTHER',
    NotRequired = 'NOT_REQUIRED'
}/**
* @export
* @enum {string}
*/
export enum AtsSourceDefaultStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}

export function AtsSourceFromJSON(json: any): AtsSource {
    return AtsSourceFromJSONTyped(json, false);
}

export function AtsSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtsSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'atsType': !exists(json, 'ats_type') ? undefined : json['ats_type'],
        'defaultState': json['default_state'],
        'displaySection': !exists(json, 'display_section') ? undefined : json['display_section'],
    };
}

export function AtsSourceToJSON(value?: AtsSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_state': value.defaultState,
    };
}


