/* multiple layout components depend on these values so they're defined here for consistency */

export const X_GUTTER = "50px"; // left and right padding
export const X_GUTTER_MOBILE = "24px";
export const LEFT_PANEL_WIDTH = "600px"; // width of job description
export const RIGHT_PANEL_WIDTH = "640px"; // width of application form
export const MIDDLE_SPACER_WIDTH = "72px"; // space between jd and application on desktop

// query parameter value used in InboundApplication
export const DOVER_CAREERS_PAGE_RECRUITING_SOURCE = "76643084";
