import { useAtomValue } from "jotai";
import React, { FC } from "react";

import { GlobalModalAtom, globalModalsListAtom, useModal } from "GlobalOverlays/atoms";

export interface GlobalModalProps {
  isOpen: boolean;
  close: () => void;
}

/**
 * @description GlobalOverlays is a component to render all the global overlays (i.e. modals and drawers)
 * at the top level of the application
 *
 * The state of these modals is managed via the global modals atoms
 */
const GlobalOverlays: FC = () => {
  const globalModalAtoms = useAtomValue(globalModalsListAtom);

  return (
    <>
      {globalModalAtoms.map(modalAtom => (
        <ManagedModal modalAtom={modalAtom} key={modalAtom.toString()} />
      ))}
    </>
  );
};

interface ModalManagerProps {
  modalAtom: GlobalModalAtom;
}

const ManagedModal: FC<ModalManagerProps> = ({ modalAtom }) => {
  const { Component, isOpen, close, context } = useModal(modalAtom);

  if (!isOpen) return null;

  return <Component isOpen={isOpen} close={close} {...context} />;
};

export default GlobalOverlays;
