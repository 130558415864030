import { Add, Remove } from "@mui/icons-material";
import { Stack, Box, Divider } from "@mui/material";
import { sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useBoolean } from "react-use";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import { Centered } from "components/Centered";
import { LoadingRelative } from "components/HotLoading";
import { EyeCrossedOutIcon } from "components/icons/EyeIcon";
import { BodySmall } from "components/library/typography";
import { useListJobsQuery, selectFromListJobsQueryResult } from "services/doverapi/endpoints/job";
import { DashboardJob } from "services/openapi";
import { CustomScrollBox } from "styles/layout";
import { colors } from "styles/theme";

const JOB_FILTER_HEIGHT = "125px";

/* sort jobs by cleanName */
const jobsSortFunc = (jobs: DashboardJob[]): DashboardJob[] =>
  sortBy(jobs, [(job: DashboardJob): string => (job.title ? job.title.toLowerCase() : "")]);

interface JobLabelProps {
  $active: boolean;
}

const JobLabel = styled(BodySmall)<JobLabelProps>`
  color: ${(props): string => (props.$active ? colors.black : colors.grayscale.gray500)};
  cursor: pointer;
  font-weight: ${(props): number => (props.$active ? 500 : 400)};
  padding: 3px 0px;
`;

interface JobsFilterProps {
  activeJobId: string;
}

const JobsFilter = React.memo(
  ({ activeJobId }: JobsFilterProps): React.ReactElement => {
    const [showInactiveJobs, toggleInactiveJobs] = useBoolean(false);

    // Url Query Params
    const [selectedJobId, setSelectedJobId] = useQueryParam("jobId", StringParam);

    const { activeJobs, inactiveJobs, isLoading: jobsLoading } = useListJobsQuery(undefined, {
      selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults, { sortFunc: jobsSortFunc }),
    });

    const changeJob = useCallback(
      (jobId: string): void => {
        setSelectedJobId(jobId);
      },
      [setSelectedJobId]
    );

    const activeJobList = useMemo(() => {
      if (!activeJobs) {
        return undefined;
      }
      return activeJobs
        .filter(job => !!job.id)
        .map((job: DashboardJob) => {
          return (
            <Stack key={job.id} direction="row" alignItems="center" spacing={1}>
              <JobLabel $active={job.id === activeJobId} onClick={(): void => setSelectedJobId(job.id!)}>
                {/* ID cast is safe due to filter on non-null IDs*/}
                {job.title}
              </JobLabel>
              {job.isPrivate && <EyeCrossedOutIcon />}
            </Stack>
          );
        });
    }, [activeJobId, activeJobs, setSelectedJobId]);

    const inactiveJobList = useMemo(() => {
      if (!inactiveJobs) {
        return undefined;
      }
      return inactiveJobs
        .filter(job => !!job.id)
        .map((job: DashboardJob) => {
          return (
            <Stack direction="row" alignItems="center" spacing={1}>
              <JobLabel key={job.id} $active={job.id === activeJobId} onClick={(): void => setSelectedJobId(job.id!)}>
                {/* ID cast is safe due to filter on non-null IDs*/}
                {job.title}
              </JobLabel>
              {job.isPrivate && <EyeCrossedOutIcon />}
            </Stack>
          );
        });
    }, [activeJobId, inactiveJobs, setSelectedJobId]);

    // If there are no active jobs, but there are inactive jobs, toggle the list open
    useEffect(() => {
      if ((!activeJobList || activeJobList.length === 0) && inactiveJobList && inactiveJobList.length > 0) {
        toggleInactiveJobs(true);
      }
    }, [toggleInactiveJobs, activeJobList, inactiveJobList]);

    // Set default job
    useEffect(() => {
      // If nothing is selected, default to first active Job
      if (!selectedJobId) {
        if (activeJobs?.[0]) {
          changeJob(activeJobs[0].id!);
        }
      }
    }, [activeJobs, changeJob, inactiveJobs, selectedJobId]);

    if (jobsLoading) {
      return (
        <Box height={JOB_FILTER_HEIGHT} width="100%" position="relative">
          <Centered>
            <LoadingRelative />
          </Centered>
        </Box>
      );
    }

    return (
      <Stack
        spacing={2}
        divider={<Divider orientation="horizontal" flexItem sx={{ borderColor: colors.grayscale.gray300 }} />}
      >
        <Stack spacing={1.5}>
          <BodySmall color={colors.grayscale.gray500} weight="600">
            {"Active jobs"}
          </BodySmall>
          <CustomScrollBox maxHeight={JOB_FILTER_HEIGHT}>{activeJobList}</CustomScrollBox>
        </Stack>
        <Stack spacing={1.5}>
          <Stack
            sx={{ cursor: "pointer" }}
            onClick={toggleInactiveJobs}
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <BodySmall color={colors.grayscale.gray500} weight="600">
              {"Inactive jobs"}
            </BodySmall>
            {showInactiveJobs ? <Remove /> : <Add />}
          </Stack>
          {showInactiveJobs && (
            <Stack spacing={1}>
              <CustomScrollBox maxHeight={JOB_FILTER_HEIGHT}>{inactiveJobList}</CustomScrollBox>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }
);

export default JobsFilter;
