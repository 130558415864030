import React from "react";
import { Field } from "react-final-form";

import InterviewMeetingTypeField from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/InterviewMeetingTypeField";
import {
  MeetingTypeContainer,
  RequiredAsterisk,
  StageDrawerFormLabel,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/styles";

export const EditMeetingType = ({
  fieldPrefix,
  required,
}: {
  fieldPrefix: string;
  required: boolean;
}): React.ReactElement => {
  return (
    <MeetingTypeContainer>
      <StageDrawerFormLabel bold>
        Interview Location {required && <RequiredAsterisk>*</RequiredAsterisk>}
      </StageDrawerFormLabel>
      <Field
        name={fieldPrefix + ".interviewMeetingType"}
        validate={(value): boolean => {
          return required && !value;
        }}
      >
        {(props): React.ReactElement => {
          return <InterviewMeetingTypeField {...props} required={required} />;
        }}
      </Field>
    </MeetingTypeContainer>
  );
};
