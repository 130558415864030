import {
  BaseInterviewSubstage,
  BaseInterviewSubstageInterviewersRequiredEnum,
  InterviewPanelSubstage,
  InterviewPanelSubstageInterviewersRequiredEnum,
} from "services/openapi";

function convert(
  interviewersRequired: BaseInterviewSubstageInterviewersRequiredEnum
): InterviewPanelSubstageInterviewersRequiredEnum {
  return new Map(Object.entries(InterviewPanelSubstageInterviewersRequiredEnum)).get(
    interviewersRequired
  ) as InterviewPanelSubstageInterviewersRequiredEnum;
}

export const convertBaseSubstageToInterviewPanel = (substages: BaseInterviewSubstage[]): InterviewPanelSubstage[] => {
  return substages.map(substage => ({
    id: substage.id ?? null,
    name: substage.name,
    duration: substage.duration ?? 0,
    possibleInterviewers: Array.from(substage.possibleInterviewers ?? []),
    interviewersRequired: substage.interviewersRequired
      ? convert(substage.interviewersRequired)
      : InterviewPanelSubstageInterviewersRequiredEnum.All,
    orderIndex: substage.orderIndex ?? 0,
    orderRequired: !!substage.orderRequired,
  }));
};
