/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureFlags
 */
export interface FeatureFlags {
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureFlags
     */
    flags: Array<string>;
}

export function FeatureFlagsFromJSON(json: any): FeatureFlags {
    return FeatureFlagsFromJSONTyped(json, false);
}

export function FeatureFlagsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureFlags {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'flags': json['flags'],
    };
}

export function FeatureFlagsToJSON(value?: FeatureFlags | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'flags': value.flags,
    };
}


