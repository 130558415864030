import { FlowType, FlowTypes, OutreachType, OutreachTypes } from "sections/addcandidate/types";

export const flowDescriptions: { [key in FlowType]: string } = {
  [FlowTypes.INTERVIEW_PIPELINE]: `Add a candidate to your interview process.`,
  [FlowTypes.INITIAL_OUTREACH]: `Add lead(s) to an email campaign.`,
};

export const flowLabels: { [key in FlowType]: string } = {
  [FlowTypes.INTERVIEW_PIPELINE]: `Interview process`,
  [FlowTypes.INITIAL_OUTREACH]: `Outreach`,
};

export const flowOutreachDescriptions: { [key in OutreachType]: string } = {
  [OutreachTypes.SINGLE]: flowDescriptions[FlowTypes.INITIAL_OUTREACH],
  [OutreachTypes.BULK]: `Add leads in bulk by uploading a CSV file.`,
};

export const flowOutreachLabels: { [key in OutreachType]: string } = {
  [OutreachTypes.SINGLE]: flowLabels[FlowTypes.INITIAL_OUTREACH],
  [OutreachTypes.BULK]: `Bulk outreach`,
};

export const outreachLabels: { [key in OutreachType]: string } = {
  [OutreachTypes.SINGLE]: `Single`,
  [OutreachTypes.BULK]: `Multiple`,
};
