import { Autocomplete } from "@mui/material";
import React from "react";

import { StyledTextField } from "components/dover/inputs/pro-users/styles";
import { colors } from "styles/theme";
import { DateOption, JOB_REPORT_DATE_FILTER_OPTIONS } from "views/Reporting/constants";

interface DateDropdownSelectorProps {
  value: DateOption;
  onChange: (option: DateOption) => void;
}

export const DateDropdownSelector = ({ value, onChange }: DateDropdownSelectorProps): React.ReactElement => {
  return (
    <Autocomplete
      sx={{
        minWidth: "200px",
        "& .MuiInputBase-root": {
          border: `1px solid ${colors.grayscale.gray300}`,
          borderRadius: "4px",
          "&:hover": {
            borderColor: colors.grayscale.gray400,
          },
          "&.Mui-focused": {
            borderColor: colors.primary.base,
          },
        },
      }}
      disableClearable
      value={JOB_REPORT_DATE_FILTER_OPTIONS.find(option => option.key === value)}
      onChange={(_event, newValue): void => {
        if (newValue) {
          onChange(newValue.key);
        }
      }}
      options={JOB_REPORT_DATE_FILTER_OPTIONS}
      getOptionLabel={(option): string => option.label}
      renderInput={(params): React.ReactElement => {
        return <StyledTextField {...params} size="small" placeholder="Select date range" />;
      }}
    />
  );
};
