import React, { ReactElement } from "react";

import { FilePreviewModalProps } from "components/dover/top-level-modal-manager/types";
import { FileViewer } from "components/FileViewer";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";

export const FilePreviewModal = ({ isOpen, closeModal, url, name }: FilePreviewModalProps): ReactElement => {
  return (
    <CustomModal open={isOpen} onClose={closeModal} title={<Body>{name ?? "File Preview"}</Body>}>
      <FileViewer url={url} iframeHeight="600px" />
    </CustomModal>
  );
};
