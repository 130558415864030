import { Stack, Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ReactSVG } from "react-svg";

import PencilEditSVG from "assets/icons/pencil-edit.svg";
import { TextField } from "components/library/TextField";
import { Subtitle1, BodySmall } from "components/library/typography";
import { useGetSearchV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import { EMPTY_SEARCH_PLACEHOLDER, SEARCH_NAME } from "views/sourcing/Search/constants";
import { useSearchId } from "views/sourcing/Search/hooks";
import { SearchV3FormSchemaType } from "views/sourcing/Search/types";

const SearchName = React.memo(
  ({ mediumScreen, smallScreen }: { mediumScreen: boolean; smallScreen: boolean }): React.ReactElement => {
    const [editing, setEditing] = React.useState<boolean>(false);

    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const name = useWatch({ control, name: SEARCH_NAME });

    const searchId = useSearchId();
    const { data: search } = useGetSearchV3Query(searchId ? { id: searchId } : skipToken);

    const onTextChange = React.useCallback(
      (text: string) => {
        if (search) {
          setValue(SEARCH_NAME, text);
        }
      },
      [search, setValue]
    );

    const onStartEditing = React.useCallback(
      (e: React.SyntheticEvent) => {
        setEditing(true);
        e.stopPropagation();
      },
      [setEditing]
    );

    const onStopEditing = React.useCallback(() => {
      setEditing(false);
    }, [setEditing]);

    const searchNameWithJobTitle = React.useMemo(() => {
      if (search === undefined || smallScreen) {
        return name;
      }

      return `${search.jobName}: ${name}`;
    }, [name, search, smallScreen]);

    if (name === undefined) {
      return <></>;
    }

    if (!editing) {
      if (mediumScreen) {
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            onClick={onStartEditing}
            sx={{
              ":hover": { cursor: "pointer" },
              "#search-name-edit-icon": { visibility: "hidden" },
              ":hover #search-name-edit-icon": { visibility: "visible" },
            }}
          >
            <BodySmall>{name === EMPTY_SEARCH_PLACEHOLDER ? "Loading..." : searchNameWithJobTitle}</BodySmall>
            <Box id="search-name-edit-icon">
              <ReactSVG style={{ display: "flex" }} src={PencilEditSVG} />
            </Box>
          </Stack>
        );
      }
      return (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          onClick={onStartEditing}
          sx={{
            ":hover": { cursor: "pointer" },
            "#search-name-edit-icon": { visibility: "hidden" },
            ":hover #search-name-edit-icon": { visibility: "visible" },
          }}
        >
          <Subtitle1>{name === EMPTY_SEARCH_PLACEHOLDER ? "Loading..." : searchNameWithJobTitle}</Subtitle1>
          <Box id="search-name-edit-icon">
            <ReactSVG style={{ display: "flex" }} src={PencilEditSVG} />
          </Box>
        </Stack>
      );
    }

    return <TextField text={name} autoFocus={true} onBlur={(): void => onStopEditing()} onTextUpdated={onTextChange} />;
  }
);

export default SearchName;
