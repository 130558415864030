/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateResponseTemplate
 */
export interface CandidateResponseTemplate {
    /**
     * 
     * @type {string}
     * @memberof CandidateResponseTemplate
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponseTemplate
     */
    readonly job?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponseTemplate
     */
    readonly emailSender?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponseTemplate
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateResponseTemplate
     */
    ccEmails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponseTemplate
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponseTemplate
     */
    readonly interviewer?: string;
}

export function CandidateResponseTemplateFromJSON(json: any): CandidateResponseTemplate {
    return CandidateResponseTemplateFromJSONTyped(json, false);
}

export function CandidateResponseTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateResponseTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'job': !exists(json, 'job') ? undefined : json['job'],
        'emailSender': !exists(json, 'email_sender') ? undefined : json['email_sender'],
        'name': json['name'],
        'ccEmails': !exists(json, 'cc_emails') ? undefined : json['cc_emails'],
        'body': json['body'],
        'interviewer': !exists(json, 'interviewer') ? undefined : json['interviewer'],
    };
}

export function CandidateResponseTemplateToJSON(value?: CandidateResponseTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'cc_emails': value.ccEmails,
        'body': value.body,
    };
}


