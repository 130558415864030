import { z } from "zod";

import {
  BooleanField,
  Locations,
  SearchV3,
  TargetPersonas,
  TargetSeniorities,
  TotalYearsOfExperience,
  SpecificYoeByT2,
  ProfileSearchKeywords,
  CompanySize,
  TargetCompanyLists,
  FieldsOfStudy,
  EducationLevel,
  IndividualExcludedSchoolIds,
  // TODO(willjow): remove school_names
  IndividualExcludedSchoolNames,
  IndividualTargetSchoolIds,
  // TODO(willjow): remove school_names
  IndividualTargetSchoolNames,
  TargetSchoolLists,
  Industries,
  Diversity,
  IndividualTargetCompanyIds,
  IndividualExcludedCompanyIds,
  TargetCustomTitles,
  ExcludedCustomTitles,
  RankRange,
  MinMaxRange,
} from "services/openapi";
import {
  COMPANY_TIER_TO_SCORE_MAP,
  CUSTOM_PERSONA_NAME,
  PREFERRED_COMPANY_SIZE_WEIGHT,
  PREFERRED_FOS_WEIGHT,
  PREFERRED_INDUSTRIES_WEIGHT,
  PREFERRED_EDUCATION_LEVEL_WEIGHT,
  PREFERRED_KEYWORDS_WEIGHT,
  SCHOOL_TIER_TO_SCORE_MAP,
  PREFERRED_TARGET_SCHOOLS_WEIGHT,
  PREFERRED_TARGET_COS_WEIGHT,
} from "views/sourcing/Search/constants";
import {
  CompanyListsWithMetadataSchema,
  FieldsOfStudyWithMetadataSchema,
  LocationWithMetadataSchema,
  PersonaWithMetadataSchema,
  SearchV3FormSchemaType,
  SenioritiesWithMetadataSchema,
  EducationLevelsWithMetadataSchema,
  YoeByT2WithMetadataSchema,
  YearsOfExperienceWithMetadataSchema,
  KeywordsWithMetadataSchema,
  KeywordsSchema,
  CompanySizesWithMetadataSchema,
  IndustryWithMetadataSchema,
  SchoolListsWithMetadataSchema,
  IndividualSchoolsWithMetadataSchema,
  IndividualCompanyWithMetadataSchema,
  RankRangeSchema,
} from "views/sourcing/Search/types";

export const COMPANY_TIER_MAX = 8;
export const SCHOOL_TIER_MAX = 5;

export const getKeywordsBucketWithMetadataName = (index: number): string => {
  return `keywordsBuckets.${index}`;
};

export const locationsToSearchParamsLocations = (
  locations: z.infer<typeof LocationWithMetadataSchema>
): Locations[] => {
  return [
    {
      field: "locations",
      value: locations.locationElements.map(location => location.value),
      required: locations.required,
      weight: undefined,
    },
  ];
};

const personasToSearchParamsPersonas = (persona: z.infer<typeof PersonaWithMetadataSchema>): TargetPersonas[] => {
  return [
    {
      field: "personas",
      value: persona.personas
        .filter(personaVal => personaVal.name !== CUSTOM_PERSONA_NAME)
        .map(personaVal2 => personaVal2.id),
      required: persona.required,
      weight: undefined,
    },
  ];
};

const senioritiesToSearchParamsTargetSeniorities = (
  senioritiesFormValues: z.infer<typeof SenioritiesWithMetadataSchema>
): TargetSeniorities[] => {
  // TODO(willjow): Remove this once we've properly implemented unknown
  // seniority on the backend
  if (senioritiesFormValues.selectedSeniorities.find(seniority => seniority.name === "Unknown")) {
    return [];
  }

  return [
    {
      field: "seniorities",
      value: {
        seniorities: senioritiesFormValues.selectedSeniorities.map(seniority => seniority.id),
        mostRecentOnly: true,
      },
      required: senioritiesFormValues.required,
      weight: undefined,
    },
  ];
};

const exludedSenioritiesToSearchParamsExcludedSeniorities = (
  senioritiesFormValues: z.infer<typeof SenioritiesWithMetadataSchema>,
  excludedSenioritiesFormValues: z.infer<typeof SenioritiesWithMetadataSchema>
): TargetSeniorities[] => {
  // TODO(trevor): Remove this once we've properly implemented unknown
  // seniority on the backend
  if (!senioritiesFormValues.selectedSeniorities.find(seniority => seniority.name === "Unknown")) {
    return [];
  }

  return [
    {
      field: "excluded seniorities",
      value: {
        seniorities: excludedSenioritiesFormValues.selectedSeniorities.map(seniority => seniority.id),
        mostRecentOnly: true,
      },
      required: excludedSenioritiesFormValues.required,
      weight: undefined,
    },
  ];
};

export const yearsOfExperienceToSearchParamsV3TotalYearsOfExperience = (
  yearsOfExperience: z.infer<typeof YearsOfExperienceWithMetadataSchema>,
  fieldName: string
): TotalYearsOfExperience[] => {
  return [
    {
      field: fieldName,
      value: { min: yearsOfExperience.yearsOfExperience.min, max: yearsOfExperience.yearsOfExperience.max },
      required: yearsOfExperience.required,
      weight: undefined,
    },
  ];
};
const yoeByT2ToSearchParamsV3 = (yoeByT2: z.infer<typeof YoeByT2WithMetadataSchema>): SpecificYoeByT2[] => {
  return [
    {
      field: "specific yoe by t2",
      value: yoeByT2.elements
        .filter(yoeByTitle => {
          return !!yoeByTitle.titleId;
        })
        .map(yoeByTitle => {
          return { titleId: yoeByTitle.titleId!, yoeRange: yoeByTitle.yoeRange };
        }),
      // there is no nice to have/must have toggle, so if this is defined, it's required
      required: true,
      weight: undefined,
    },
  ];
};

const targetCompanyListsToSearchParamsTargetCompanyLists = (
  companyList: z.infer<typeof CompanyListsWithMetadataSchema>,
  required: boolean
): TargetCompanyLists[] => {
  return [
    {
      field: "target_company_list",
      value: companyList.selectedDoverCompanyLists.map(company => company.id),
      required,
      weight: required ? 0 : PREFERRED_TARGET_COS_WEIGHT,
    },
  ];
};

const booleanFieldToSearchParamsBooleanField = (fieldName: string, booleanFieldValue: boolean): BooleanField[] => {
  return [
    {
      field: fieldName,
      value: booleanFieldValue,
      required: true,
      weight: undefined,
    },
  ];
};

const targetSchoolListsToSearchparamsTargetSchoolLists = (
  schoolList: z.infer<typeof SchoolListsWithMetadataSchema>,
  required: boolean
): TargetSchoolLists[] => {
  return [
    {
      field: "target_school_lists",
      value: schoolList.doverSchoolLists.map(school => school.id),
      required: required,
      weight: required ? undefined : PREFERRED_TARGET_SCHOOLS_WEIGHT,
    },
  ];
};

export const companySizeToSearchParamsCompanySize = (
  companySizes: z.infer<typeof CompanySizesWithMetadataSchema>,
  fieldName: string
): CompanySize[] => {
  if (companySizes.value.length > 0) {
    const sizes = companySizes.value.map(size => ({ min: size.minSize, max: size.maxSize }));
    return [
      {
        field: fieldName,
        value: { sizes: sizes, mostRecentOnly: companySizes.mostRecentOnly },
        required: companySizes.required,
        weight: companySizes.required ? 0 : PREFERRED_COMPANY_SIZE_WEIGHT,
      },
    ];
  }
  return [];
};

export const keywordsToSearchParamKeywordBuckets = (
  keywordBuckets: z.infer<typeof KeywordsWithMetadataSchema>[]
): ProfileSearchKeywords[] => {
  return keywordBuckets.map((bucket, index) => {
    return {
      field: `keyword bucket ${index}`,
      value: bucket.bucket.map(keyword => keyword.id),
      required: bucket.required,
      weight: bucket.required ? 0 : PREFERRED_KEYWORDS_WEIGHT,
    };
  });
};

const deniedKeywordsToSearchParamDeniedKeywords = (
  keywords: z.infer<typeof KeywordsSchema>[]
): ProfileSearchKeywords[] => {
  return [
    {
      field: "denied keywords",
      value: keywords.map(keyword => keyword.id),
      required: true,
    },
  ];
};

const fieldsOfStudyToSearchParamsFieldsOfStudy = (
  fieldsOfStudy: z.infer<typeof FieldsOfStudyWithMetadataSchema>
): FieldsOfStudy[] => {
  return [
    {
      field: "fields of study",
      value: fieldsOfStudy.selectedFieldsOfStudy.map(t1 => t1.id),
      required: fieldsOfStudy.required,
      weight: PREFERRED_FOS_WEIGHT,
    },
  ];
};

const educationLevelsToSearchParamsEducationLevels = (
  educationLevel: z.infer<typeof EducationLevelsWithMetadataSchema>
): EducationLevel[] => {
  if (educationLevel.selectedEducationLevel) {
    return [
      {
        field: "education_level",
        value: educationLevel.selectedEducationLevel.educationLevel,
        required: educationLevel.required,
        weight: PREFERRED_EDUCATION_LEVEL_WEIGHT,
      },
    ];
  }
  return [];
};

export const industrySerializerToSearchParamsIndustries = (
  industries: z.infer<typeof IndustryWithMetadataSchema>
): Industries[] => {
  return [
    {
      field: "industries",
      value: industries.industryElements.map(industry => industry.id),
      required: industries.required,
      weight: PREFERRED_INDUSTRIES_WEIGHT,
    },
  ];
};

const industrySerializerToSearchParamsExcludedIndustries = (
  excludedIndustries: z.infer<typeof IndustryWithMetadataSchema>
): Industries[] => {
  return [
    {
      field: "excluded_industries",
      value: excludedIndustries.industryElements.map(industry => industry.id),
      required: true,
      weight: undefined,
    },
  ];
};

const diversityOptionToSearchParamsDiversity = (diversityOption?: string): Diversity[] => {
  return diversityOption
    ? [{ field: "diversity", value: diversityOption, required: undefined, weight: undefined }]
    : [];
};

const companyRankRangeToSearchParamsRankRange = (companyRankRange: z.infer<typeof RankRangeSchema>): RankRange[] => {
  return [
    {
      field: "company_rank_range",
      value: {
        min: COMPANY_TIER_TO_SCORE_MAP[companyRankRange.rankRange.min ?? 0].min,
        max:
          companyRankRange.rankRange.max === undefined || companyRankRange.rankRange.max === COMPANY_TIER_MAX
            ? 1
            : COMPANY_TIER_TO_SCORE_MAP[companyRankRange.rankRange.max].min,
      },
      required: companyRankRange.required,
      weight: undefined,
    },
  ];
};
const schoolRankRangeToSearchParamsRankRange = (schoolRankRange: z.infer<typeof RankRangeSchema>): RankRange[] => {
  return [
    {
      field: "school_rank_range",
      value: {
        min: SCHOOL_TIER_TO_SCORE_MAP[schoolRankRange.rankRange.min ?? 0].min,
        max:
          schoolRankRange.rankRange.max === undefined || schoolRankRange.rankRange.max === SCHOOL_TIER_MAX
            ? 1
            : SCHOOL_TIER_TO_SCORE_MAP[schoolRankRange.rankRange.max].min,
      },
      required: schoolRankRange.required,
      weight: undefined,
    },
  ];
};
export const searchParamsRankRangeToCompanyRankRange = (minMaxRange: MinMaxRange): MinMaxRange => {
  const findTierValueForScore = (value: number): number => {
    for (const [record_key, record_value] of Object.entries(COMPANY_TIER_TO_SCORE_MAP)) {
      if (value <= (record_value.max ?? 0) && value >= (record_value.min ?? 1)) {
        return parseInt(record_key);
      }
    }
    return 0;
  };
  return {
    min: findTierValueForScore(minMaxRange.min ?? 0),
    max:
      minMaxRange.max !== undefined && minMaxRange.max !== 1
        ? findTierValueForScore(minMaxRange.max)
        : COMPANY_TIER_MAX,
  };
};

export const searchParamsRankRangeToSchoolRankRange = (minMaxRange: MinMaxRange): MinMaxRange => {
  const findTierValueForScore = (value: number): number => {
    for (const [record_key, record_value] of Object.entries(SCHOOL_TIER_TO_SCORE_MAP)) {
      if (value <= (record_value.max ?? 0) && value >= (record_value.min ?? 1)) {
        return parseInt(record_key);
      }
    }
    return 0;
  };
  return {
    min: findTierValueForScore(minMaxRange.min ?? 0),
    max:
      minMaxRange.max !== undefined && minMaxRange.max !== 1 ? findTierValueForScore(minMaxRange.max) : SCHOOL_TIER_MAX,
  };
};

const excludedSchoolsIdsToSearchParamsExcludedSchoolIds = (
  excludedSchools: z.infer<typeof IndividualSchoolsWithMetadataSchema>
): IndividualExcludedSchoolIds[] => {
  return [
    {
      field: "excluded school ids",
      value: excludedSchools.individualSchools.map(school => school.id),
      required: excludedSchools.required,
      weight: undefined,
    },
  ];
};

// TODO(willjow): remove school_names
const excludedSchoolsNamesToSearchParamsExcludedSchoolNames = (
  excludedSchools: z.infer<typeof IndividualSchoolsWithMetadataSchema>
): IndividualExcludedSchoolNames[] => {
  return [
    {
      field: "excluded school names",
      value: excludedSchools.individualSchools.map(school => school.name),
      required: excludedSchools.required,
      weight: undefined,
    },
  ];
};

const targetSchoolsIdsToSearchParamsTargetSchoolIds = (
  targetSchools: z.infer<typeof IndividualSchoolsWithMetadataSchema>
): IndividualTargetSchoolIds[] => {
  return [
    {
      field: "target school ids",
      value: targetSchools.individualSchools.map(school => school.id),
      required: targetSchools.required,
      weight: targetSchools.required ? undefined : PREFERRED_TARGET_SCHOOLS_WEIGHT,
    },
  ];
};

// TODO(willjow): remove school_names
const targetSchoolsNamesToSearchParamsTargetSchoolNames = (
  targetSchools: z.infer<typeof IndividualSchoolsWithMetadataSchema>
): IndividualTargetSchoolNames[] => {
  return [
    {
      field: "target school names",
      value: targetSchools.individualSchools.map(school => school.name),
      required: targetSchools.required,
      weight: undefined,
    },
  ];
};

export const individualCompaniesToSearchParamsIndividualCompanies = (
  companies: z.infer<typeof IndividualCompanyWithMetadataSchema>
): IndividualTargetCompanyIds[] => {
  return [
    {
      field: "target company ids",
      value: companies.individualCompanyElements.map(company => company.id),
      required: companies.required,
      weight: companies.required ? 0 : PREFERRED_TARGET_COS_WEIGHT,
    },
  ];
};

const individualExcludedCompaniesToSearchParamsIndividualExcludedCompanies = (
  companies: z.infer<typeof IndividualCompanyWithMetadataSchema>
): IndividualExcludedCompanyIds[] => {
  return [
    {
      field: "excluded company ids",
      value: companies.individualCompanyElements.map(company => company.id),
      required: companies.required,
      weight: undefined,
    },
  ];
};

function customTitlesToSearchParamsCustomTitles(titles: string[]): TargetCustomTitles[] {
  if (titles.length === 0) {
    return [];
  }

  return [
    {
      field: "custom titles",
      value: { titles, mostRecentOnly: false },
      required: true,
      weight: undefined,
    },
  ];
}

function excludedTitlesToSearchParamsExcludedTitles(titles: string[]): ExcludedCustomTitles[] {
  if (titles.length === 0) {
    return [];
  }

  return [
    {
      field: "excluded titles",
      value: { titles, mostRecentOnly: false },
      required: true,
      weight: undefined,
    },
  ];
}

// Note from Trevor:
// Ideally we want to make this purely reason about the formState and ignore the current search,
// but this is difficult to do until we have fleshed out all of the filters
// for now, we can use the current search params as a placeholder, replacing individual fields as we go
export function getSearchV3FromFormState(formState: SearchV3FormSchemaType, currentSearch: SearchV3): SearchV3 {
  const newSearchParams = { ...currentSearch.v3Params };
  newSearchParams.targetLocations = locationsToSearchParamsLocations(formState.locations);
  newSearchParams.excludedLocations = locationsToSearchParamsLocations(formState.excludedLocations);
  newSearchParams.targetPersonas = personasToSearchParamsPersonas(formState.personas);
  newSearchParams.targetSeniorities = senioritiesToSearchParamsTargetSeniorities(formState.seniorities);
  newSearchParams.excludedSeniorities = exludedSenioritiesToSearchParamsExcludedSeniorities(
    formState.seniorities,
    formState.excludedSeniorities
  );
  newSearchParams.totalYearsOfExperience = yearsOfExperienceToSearchParamsV3TotalYearsOfExperience(
    formState.totalYearsOfExperience,
    "total years of experience"
  );
  newSearchParams.specificYearsOfExperience = yearsOfExperienceToSearchParamsV3TotalYearsOfExperience(
    formState.specificYearsOfExperience,
    "specific years of experience"
  );
  newSearchParams.yearsAtCurrentCompanyRange = yearsOfExperienceToSearchParamsV3TotalYearsOfExperience(
    formState.yearsAtCurrentCompany,
    "years at current company"
  );
  newSearchParams.bachelorsGraduationYearRange = yearsOfExperienceToSearchParamsV3TotalYearsOfExperience(
    formState.bachelorsGraduationYear,
    "bachelors graduation year"
  );
  newSearchParams.specificYearsOfExperienceByT2 = yoeByT2ToSearchParamsV3(formState.specificYearsOfExperienceByT2);
  newSearchParams.excludeFrequentJobSwitching = booleanFieldToSearchParamsBooleanField(
    "exclude frequent job switching",
    formState.excludeFrequentJobSwitching
  );

  newSearchParams.targetCompanyLists = targetCompanyListsToSearchParamsTargetCompanyLists(
    formState.targetCompanyLists,
    // bit counter intuitive, but we copy over the individualCompanies.required for both individual IDs and target co's
    formState.individualCompanies.required
  );
  newSearchParams.targetCompaniesMostRecentOnly = booleanFieldToSearchParamsBooleanField(
    "target_companies__most_recent_only",
    formState.targetCompaniesMostRecentOnly
  );
  newSearchParams.fieldsOfStudy = fieldsOfStudyToSearchParamsFieldsOfStudy(formState.fieldsOfStudy);
  newSearchParams.educationLevel = educationLevelsToSearchParamsEducationLevels(formState.educationLevel);
  newSearchParams.targetSchoolLists = targetSchoolListsToSearchparamsTargetSchoolLists(
    formState.targetSchoolLists,
    // School lists and individual target schools are part of the same filter, even though
    // they're separate params; this is a hack to keep their requiredness in sync
    formState.individualSchools.required
  );
  newSearchParams.companySize = companySizeToSearchParamsCompanySize(formState.companySizes, "company_sizes");
  newSearchParams.excludedCompanySize = companySizeToSearchParamsCompanySize(
    formState.companySizeExclusions,
    "excluded_company_sizes"
  );
  newSearchParams.keywords = keywordsToSearchParamKeywordBuckets(formState.keywordsBuckets);
  newSearchParams.deniedKeywords = deniedKeywordsToSearchParamDeniedKeywords(formState.deniedKeywords);
  newSearchParams.industries = industrySerializerToSearchParamsIndustries(formState.industries);
  newSearchParams.excludedIndustries = industrySerializerToSearchParamsExcludedIndustries(formState.excludedIndustries);
  newSearchParams.industriesMostRecentOnly = booleanFieldToSearchParamsBooleanField(
    "industries__most_recent_only",
    formState.industriesMostRecentOnly
  );
  newSearchParams.diversity = diversityOptionToSearchParamsDiversity(formState.diversityOption);
  newSearchParams.companyRankRange = companyRankRangeToSearchParamsRankRange(formState.companyRankRange);
  newSearchParams.schoolRankRange = schoolRankRangeToSearchParamsRankRange(formState.schoolRankRange);
  newSearchParams.individualTargetSchoolIds = targetSchoolsIdsToSearchParamsTargetSchoolIds(
    formState.individualSchools
  );
  // TODO(willjow): remove school_names
  newSearchParams.individualTargetSchoolNames = targetSchoolsNamesToSearchParamsTargetSchoolNames(
    formState.individualSchools
  );
  newSearchParams.individualExcludedSchoolIds = excludedSchoolsIdsToSearchParamsExcludedSchoolIds(
    formState.individualExcludedSchools
  );
  // TODO(willjow): remove school_names)
  newSearchParams.individualExcludedSchoolNames = excludedSchoolsNamesToSearchParamsExcludedSchoolNames(
    formState.individualExcludedSchools
  );
  newSearchParams.individualTargetCompanyIds = individualCompaniesToSearchParamsIndividualCompanies(
    formState.individualCompanies
  );
  newSearchParams.individualExcludedCompanyIds = individualExcludedCompaniesToSearchParamsIndividualExcludedCompanies(
    formState.individualExcludedCompanies
  );
  newSearchParams.minScoreRatio = formState.strictness / 10;
  newSearchParams.targetCustomTitles = customTitlesToSearchParamsCustomTitles(formState.personas.customTitles);
  newSearchParams.customTitlesSoftMatch = booleanFieldToSearchParamsBooleanField(
    "custom_titles_soft_match",
    formState.personas.customTitlesSoftMatch
  );
  newSearchParams.personasMostRecentOnly = booleanFieldToSearchParamsBooleanField(
    "personas__most_recent_only",
    formState.personas.personasMostRecentOnly
  );
  newSearchParams.excludedCustomTitles = excludedTitlesToSearchParamsExcludedTitles(formState.personas.excludedTitles);

  const newSearch = { ...currentSearch, v3Params: newSearchParams };
  newSearch.name = formState.name;

  return newSearch;
}

export function getDepthAdjustmentSuggestions(formState: SearchV3FormSchemaType): string[] {
  const suggestions = [];

  /**
   * Note: please do not change the order of the conditional statements below without checking with the product team first.
   * These items are in priority order.
   */
  if (formState.onlyTargetSpecificSchools) {
    suggestions.push(`Turn off "only source from specific schools/lists" toggle`);
  }

  if (!formState.seniorities.selectedSeniorities.find(seniority => seniority.name === "Unknown")) {
    suggestions.push(`Add "Unknown" to your Seniority Level targets`);
  }

  let keywordMustHaveBucketExists = false;
  formState.keywordsBuckets.forEach(bucket => {
    if (bucket.required) {
      keywordMustHaveBucketExists = true;
    }
  });
  if (keywordMustHaveBucketExists) {
    suggestions.push(`Change keywords from must have to nice to have`);
  }

  if (formState.industries.required) {
    suggestions.push(`Change industries from must have to nice to have`);
  }

  if (formState.companySizes.required) {
    suggestions.push(`Change company sizes from must have to nice to have`);
  }

  if (formState.fieldsOfStudy.required) {
    suggestions.push(`Change field of study from must have to nice to have`);
  }

  if (formState.educationLevel.required) {
    suggestions.push(`Change highest degree earned from must have to nice to have`);
  }

  if (formState.strictness >= 3) {
    suggestions.push(`Reduce minimum bar on strictness scale`);
  }

  if (!!formState.diversityOption && formState.diversityOption !== "EVERYONE") {
    suggestions.push(`Remove diversity filter`);
  }

  if (formState.locations.locationElements.length > 0) {
    suggestions.push(`Expand locations to include more states or countries`);
  }

  if (formState.personas.customTitles.length > 0) {
    suggestions.push(`Add additional target custom titles`);
  } else {
    suggestions.push(`Add additional target personas`);
  }

  return suggestions;
}
