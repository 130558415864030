import { Box, Stack } from "@mui/material";
import React, { FC, ReactElement, useState } from "react";

import { ReactComponent as BuildingIcon } from "assets/icons/building-icon-grey.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as MapPinIcon } from "assets/icons/map-pin.svg";
import { EditableDisplayField } from "components/EditableDisplayField";
import { Chip } from "components/library/Chip";
import { PageTitle } from "components/library/typography";
import { PronounceName } from "components/PronounceName";
import { StarCandidateButton } from "components/StarCandidateButton";
import { usePartialUpdateContactMutation } from "services/doverapi/endpoints/contact";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { CandidateBio } from "services/openapi";
import { colors } from "styles/theme";
import { CandidateMergeButton } from "views/CandidateDetail/components/CandidateMergeBanner";
import { SocialLinksV2 } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/SocialLinks";

interface Props {
  candidateBio: CandidateBio;
}

export const NameAndSocialLinks = ({ candidateBio }: Props): ReactElement => {
  const { data: jobSetup } = useGetJobSetupQuery(candidateBio.job!);

  const fullName = candidateBio.contact.fullName;
  const firstName = fullName?.split(" ")[0];

  const isSampleCand = candidateBio.isDemoInboundCand || jobSetup?.isSample;

  return (
    <Stack>
      <Stack direction="row" spacing={0.5} alignItems="center" minWidth={0} width="100%">
        <Name
          key={candidateBio.contact.id}
          contactId={candidateBio.contact.id}
          initialName={fullName}
          candidateId={candidateBio.id}
        />
        <PronounceName name={firstName} />
        {isSampleCand && <Chip variant="Informational" label={"Sample Candidate"} />}
        <StarCandidateButton
          candidate={{
            id: candidateBio.id,
            jobId: candidateBio.job,
            fullName: fullName,
            isStarred: candidateBio.isStarred,
          }}
        />
        <Box ml="auto !important">
          <CandidateMergeButton contact={candidateBio.contact} candidateId={candidateBio.id} />
        </Box>
      </Stack>
      {/* Negative margin is just to get these closer to the links but still allow for padding for their hover background styling */}
      <Stack direction="row" spacing={1} marginBottom="-6px">
        <Headline
          contactId={candidateBio.contact.id}
          initialHeadline={candidateBio.contact.headline}
          candidateId={candidateBio.id}
        />
        <Location
          contactId={candidateBio.contact.id}
          initialLocation={candidateBio.contact.location}
          candidateId={candidateBio.id}
        />
      </Stack>
      {candidateBio.id && (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <LinkIcon />
          <SocialLinksV2 candidateId={candidateBio.id} socialLinks={candidateBio.contact.socialLinks || []} />
        </Stack>
      )}
    </Stack>
  );
};

interface NameProps {
  contactId: string | undefined;
  initialName: string | undefined;
  candidateId: string | undefined;
}

const Name: FC<NameProps> = ({ contactId, initialName, candidateId }) => {
  const [updateContact] = usePartialUpdateContactMutation();

  // The location to reset to if the user tries to save an empty field or presses escape
  // We track this locally because initially this value comes from the backend but if the user
  // saves and then edits again without refreshing we want this value to be updated without
  // having to do manual cache change shenanigans
  const [nameToResetTo, setNameToResetTo] = useState(initialName);

  // The controlled state of the text field
  const [modifiedName, setModifiedName] = useState(initialName);

  if (!initialName || !contactId) {
    console.error("Candidate detail modal: contactId or name is undefined");
    return null;
  }

  const reset = (): void => {
    setModifiedName(nameToResetTo);
  };

  const save = (): void => {
    // Reset if the user tries to save empty field
    if (!modifiedName) {
      reset();
      return;
    }

    if (modifiedName !== initialName) {
      // The backend doesn't automatically derive preferred name, so we should update both
      updateContact({
        id: contactId,
        candidateId: candidateId,
        data: {
          fullName: modifiedName,
          preferredName: modifiedName.split(" ")[0],
        },
      });
      setNameToResetTo(modifiedName);
    }
  };

  return (
    // The display field has some padding for its on hover background effect
    // This margin left is to keep it left aligned with the rest of this component
    <Box marginLeft="-8px">
      <EditableDisplayField
        text={modifiedName}
        onChange={setModifiedName}
        onBlur={save}
        onEnter={save}
        onEscape={reset}
        DisplayComponent={PageTitle}
      />
    </Box>
  );
};

interface HeadlineProps {
  contactId: string | undefined;
  initialHeadline: string | undefined;
  candidateId: string | undefined;
}

const Headline: FC<HeadlineProps> = ({ contactId, initialHeadline, candidateId }) => {
  const [updateContact] = usePartialUpdateContactMutation();

  // The location to reset to if the user tries to save an empty field or presses escape
  // We track this locally because initially this value comes from the backend but if the user
  // saves and then edits again without refreshing we want this value to be updated without
  // having to do manual cache change shenanigans
  const [headlineToResetTo, setHeadlineToResetTo] = useState(initialHeadline);

  // The controlled state of the text field
  const [modifiedHeadline, setModifiedHeadline] = useState(initialHeadline);

  if (!contactId) {
    console.error("Candidate detail modal: contactId is undefined");
    return null;
  }

  const save = (): void => {
    if (modifiedHeadline !== initialHeadline) {
      updateContact({
        id: contactId,
        candidateId: candidateId,
        data: { headline: modifiedHeadline },
      });
      setHeadlineToResetTo(modifiedHeadline);
    }
  };

  const reset = (): void => {
    setModifiedHeadline(headlineToResetTo);
  };

  return (
    <Stack direction="row" alignItems="center" minWidth={0}>
      <BuildingIcon />
      <EditableDisplayField
        text={modifiedHeadline}
        displayOnlyText={modifiedHeadline || "+ Add Headline"}
        displayColor={modifiedHeadline ? undefined : colors.grayscale.gray500}
        placeholder="Headline"
        onChange={setModifiedHeadline}
        onBlur={save}
        onEnter={save}
        onEscape={reset}
      />
    </Stack>
  );
};

interface LocationProps {
  contactId: string | undefined;
  initialLocation: string | undefined;
  candidateId: string | undefined;
}

const Location: FC<LocationProps> = ({ contactId, initialLocation, candidateId }) => {
  const [updateContact] = usePartialUpdateContactMutation();

  // The location to reset to if the user tries to save an empty field or presses escape
  // We track this locally because initially this value comes from the backend but if the user
  // saves and then edits again without refreshing we want this value to be updated without
  // having to do manual cache change shenanigans
  const [locationToResetTo, setLocationToResetTo] = useState(initialLocation);

  // The controlled state of the text field
  const [modifiedLocation, setModifiedLocation] = useState(initialLocation);

  if (!contactId) {
    console.error("Candidate detail modal: contactId is undefined");
    return null;
  }

  const save = (): void => {
    if (modifiedLocation !== initialLocation) {
      updateContact({ id: contactId, candidateId: candidateId, data: { location: modifiedLocation } });
      setLocationToResetTo(modifiedLocation);
    }
  };

  const reset = (): void => {
    setModifiedLocation(locationToResetTo);
  };

  return (
    <Stack direction="row" alignItems="center" minWidth={0}>
      <MapPinIcon />
      <EditableDisplayField
        text={modifiedLocation}
        displayOnlyText={modifiedLocation || "+ Add Location"}
        displayColor={modifiedLocation ? undefined : colors.grayscale.gray500}
        placeholder="Location"
        onChange={setModifiedLocation}
        onBlur={save}
        onEnter={save}
        onEscape={reset}
      />
    </Stack>
  );
};
