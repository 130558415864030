import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

import { SaapReviewAdditionalFilters } from "services/openapi";
import { hasAppQuestionFiltersAppliedAtom } from "views/candidates/ApplicationReview/atoms/applicationQuestions";
import { selectedApplicantListTabAtom } from "views/candidates/ApplicationReview/atoms/selectedApplicantListTab";

export const nameFilterValAtom = atomWithReset<string>("");

export const bookmarkedFilterAtom = atom(get => {
  const selectedApplicantTab = get(selectedApplicantListTabAtom);
  return selectedApplicantTab === "Bookmarked" ? true : false;
});

export const criteriaMatchFilterAtom = atomWithReset<SaapReviewAdditionalFilters["criteriaMatch"] | undefined>(
  undefined
);

export const inboundSourceFilterAtom = atomWithReset<SaapReviewAdditionalFilters["sourceLabels"] | undefined>(
  undefined
);

export const toggleSourceFilterAtom = atom(null, (get, set, inboundSourceName: string) => {
  const inboundSourceFilter = get(inboundSourceFilterAtom);
  if (inboundSourceFilter?.includes(inboundSourceName)) {
    set(
      inboundSourceFilterAtom,
      inboundSourceFilter.filter(name => name !== inboundSourceName)
    );
  } else {
    set(inboundSourceFilterAtom, [...(inboundSourceFilter ?? []), inboundSourceName]);
  }
});

export const hasFiltersAppliedAtom = atom(get => {
  const inboundSourceFilter = get(inboundSourceFilterAtom);
  const criteriaMatchFilter = get(criteriaMatchFilterAtom);
  const hasAppQuestionFiltersApplied = get(hasAppQuestionFiltersAppliedAtom);

  return inboundSourceFilter?.length || criteriaMatchFilter?.length || hasAppQuestionFiltersApplied;
});
