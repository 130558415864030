const errorFunction = () => console.log("Option Loader not recognized. Make sure enum maps to valid endpoint.");

export const getOptionLabel = loadOptions => {
  let optionLabelFunction;
  switch (loadOptions) {
    case "SIMILAR_COMPANIES":
      optionLabelFunction = option => `${option.name} (${option.url})`;
      break;
    default:
      optionLabelFunction = errorFunction;
  }
  return optionLabelFunction;
};

export const getOptionSelected = loadOptions => {
  let optionSelectedFunction;
  switch (loadOptions) {
    case "SIMILAR_COMPANIES":
      optionSelectedFunction = (option, value) => option.id === value.id;
      break;
    default:
      optionSelectedFunction = errorFunction;
  }
  return optionSelectedFunction;
};
