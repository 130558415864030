/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InterviewSubstageEnum {
    BASE = 0,
    SCHEDULING_1 = 100,
    SCHEDULING_2 = 200,
    SCHEDULING_3 = 300,
    SCHEDULING_4 = 400,
    SCHEDULED = 500,
    COMPLETED = 700
}

export function InterviewSubstageEnumFromJSON(json: any): InterviewSubstageEnum {
    return InterviewSubstageEnumFromJSONTyped(json, false);
}

export function InterviewSubstageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewSubstageEnum {
    return json as InterviewSubstageEnum;
}

export function InterviewSubstageEnumToJSON(value?: InterviewSubstageEnum | null): any {
    return value as any;
}

