import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import React from "react";

interface PronounceNameProps {
  name?: string;
}

export const PronounceName: React.FC<PronounceNameProps> = ({ name }) => {
  return (
    <>
      {name && (
        <a href={`https://www.google.com/search?q=pronounce+${name}`} target="_blank" rel="noopener noreferrer">
          <VolumeUpIcon />
        </a>
      )}
    </>
  );
};
