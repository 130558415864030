import { Box, Stack } from "@mui/material";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { useStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useRescheduleInterviewMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import { ApiApiRescheduleInterviewOperationRequest, CandidateBio } from "services/openapi";
import { colors } from "styles/theme";
import { isTakeHomeStage } from "utils/isStage";
import { toastOptions } from "utils/showToast";
import { ConciergeProfile } from "views/CandidateDetail/components/next-action/ConciergeProfile";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { SubmitFeedbackButton } from "views/CandidateDetail/components/next-action/states/SubmitFeedbackButton";
import {
  colorsMap,
  formatDateShort,
  formatTime,
  NextActionStateComponentProps,
} from "views/CandidateDetail/components/next-action/utils/constants";
import { getInterviewerNameFromNextAction } from "views/CandidateDetail/utils";

export function ConciergeScheduled({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  return (
    <NextActionCardSkeleton
      title={"Interview Scheduled"}
      colors={colorsMap.green}
      description={<InterviewScheduledDescription candidateBio={candidateBio} />}
    />
  );
}

export const ConciergeRescheduleButton = ({
  candidateBio,
  variant = ButtonVariant.Secondary,
  fullWidth,
  justifyText = "center",
}: {
  candidateBio: CandidateBio;
  variant?: ButtonVariant;
  fullWidth?: boolean;
  justifyText?: "center" | "flex-start";
}): React.ReactElement => {
  const [rescheduleInterview] = useRescheduleInterviewMutation();
  const conciergeInfo = useConciergeInfo();

  const candidateId = candidateBio.id;
  const candidateActionVersion = candidateBio?.actionVersion;

  const { stage } = useStage({ candidateId, forceUseCurrentPipelineStage: true });

  const clickReschedule = useCallback(async () => {
    if (!candidateId || !stage?.id || !candidateBio.job || !candidateActionVersion) {
      return;
    }

    const args: ApiApiRescheduleInterviewOperationRequest = {
      id: candidateId,
      data: {
        candidateActionVersion,
        auto: true,
        desiredHiringPipelineStage: stage.id,
      },
    };

    const submitDecisionPromise = rescheduleInterview(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          pending: "Submitting...",
          success: "Submitted!",
          error: "Error Rescheduling",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }, [candidateId, stage?.id, candidateBio.job, candidateActionVersion, rescheduleInterview]);

  const conciergeName = conciergeInfo?.firstName || "your recruiting partner";

  return (
    <Button variant={variant} onClick={clickReschedule} width={fullWidth ? "100%" : undefined}>
      <Box display="flex" justifyContent={justifyText}>
        <BodySmall color={colors.grayscale.gray600}>Ask {conciergeName} to reschedule</BodySmall>
      </Box>
    </Button>
  );
};

const InterviewScheduledDescription = ({ candidateBio }: { candidateBio: CandidateBio }): React.ReactElement => {
  const interviewerName = getInterviewerNameFromNextAction(candidateBio.nextAction!);
  const interview = candidateBio.nextAction!.currentInterview;
  const startDate = interview?.startDate;
  const stageName = interview?.stageName || "";

  let text = `${stageName} scheduled`;
  if (startDate) {
    if (interviewerName) {
      text += ` with ${interviewerName}`;
    }
    text += ` on ${formatDateShort(startDate)} at ${formatTime(startDate)}`;
  }

  if (candidateBio.candidatePipelineStage && isTakeHomeStage(candidateBio.candidatePipelineStage)) {
    return <BodySmall>Take-home in progress</BodySmall>;
  }

  return (
    <Stack width="100%" spacing={2}>
      <ConciergeProfile slackUrl={candidateBio.slackUrl} />
      <BodySmall>{text}.</BodySmall>
      <Stack direction="row" spacing={0.5}>
        <ConciergeRescheduleButton candidateBio={candidateBio} />
        <SubmitFeedbackButton candidateBio={candidateBio} />
      </Stack>
    </Stack>
  );
};
