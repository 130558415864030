/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Add, Remove } from "@mui/icons-material";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useQueryParam, StringParam } from "use-query-params";

import { Centered } from "components/Centered";
import { FilterDivider, FilterSectionLabel } from "components/filters/styles";
import { LoadingRelative } from "components/HotLoading";
import { Spacer } from "components/Spacer";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListJobsQuery, selectFromListJobsQueryResult } from "services/doverapi/endpoints/job";
import { getEntityById } from "services/doverapi/entityAdapterUtils";
import { CustomScrollBox } from "styles/layout";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

const JOB_FILTER_HEIGHT = "220px";

interface JobLabelProps {
  $active: boolean;
}
const JobLabel = styled(B2_doNotUse)<JobLabelProps>`
  color: ${(props): string => (props.$active ? colors.black : colors.grayscale.gray500)};
  cursor: pointer;
  font-weight: ${(props): number => (props.$active ? 500 : 400)};
  padding: 6px 0px;
`;

const JobSelectSectionHeader = styled.div`
  align-items: center;
  color: ${colors.grayscale.gray500};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const JobSelector = (): React.ReactElement => {
  const [showInactiveJobs, setShowInactiveJobs] = useState(false);
  const [, setJobId] = useQueryParam("job", StringParam);
  const jobId = useJobIdFromUrl();

  const { activeJobs, inactiveJobs, firstJob: defaultJob, isLoading: jobsInitializing, data } = useListJobsQuery(
    undefined,
    {
      selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults),
    }
  );

  const selectedJob = getEntityById(data, jobId);

  useEffect(() => {
    // Default to the first job if no valid jobId is found in the url.
    if (!jobsInitializing) {
      if (defaultJob && !selectedJob) {
        setJobId(defaultJob.id);
      }
    }
  }, [defaultJob, jobId, jobsInitializing]);

  const activeJobList = useMemo(
    () =>
      activeJobs
        ? activeJobs.map(job => (
            <JobLabel $active={job.id === jobId} onClick={(): void => setJobId(job.id)} key={job.id}>
              {job.title}
            </JobLabel>
          ))
        : null,
    [activeJobs, jobId]
  );
  const inactiveJobList = useMemo(
    () =>
      inactiveJobs
        ? inactiveJobs.map(job => (
            <JobLabel $active={job.id === jobId} onClick={(): void => setJobId(job.id)} key={job.id}>
              {job.title}
            </JobLabel>
          ))
        : null,
    [inactiveJobs, jobId]
  );

  if (jobsInitializing || !selectedJob) {
    return (
      <Box height={JOB_FILTER_HEIGHT} width="100%" position="relative">
        <Centered>
          <LoadingRelative />
        </Centered>
      </Box>
    );
  }

  return (
    <>
      <FilterSectionLabel bold>Active Jobs</FilterSectionLabel>
      <Spacer height={6} />
      <CustomScrollBox maxHeight={JOB_FILTER_HEIGHT}>{activeJobList}</CustomScrollBox>
      <Spacer height={24} />
      <FilterDivider />
      <Spacer height={24} />
      <JobSelectSectionHeader onClick={(): void => setShowInactiveJobs(!showInactiveJobs)}>
        <FilterSectionLabel bold>Inactive Jobs</FilterSectionLabel>
        {showInactiveJobs ? <Remove /> : <Add />}
      </JobSelectSectionHeader>
      {showInactiveJobs && (
        <>
          <Spacer height={6} />
          <CustomScrollBox maxHeight={JOB_FILTER_HEIGHT}>{inactiveJobList}</CustomScrollBox>
        </>
      )}
    </>
  );
};

export default JobSelector;
