import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as CircledGreenCheckmarkIcon } from "assets/icons/circled-green-checkmark.svg";
import { ReactComponent as EmptyCircleIcon } from "assets/icons/empty-circle.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link.svg";
import { ReactComponent as HelpQuestionIcon } from "assets/icons/help-question.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedin-logo-placeholder.svg";
import { ReactComponent as LinkedInWithCheckmarkIcon } from "assets/icons/linkedin-with-checkmark.svg";
import { ReactComponent as WebsiteWithCheckmarkIcon } from "assets/icons/website-with-checkmark.svg";
import ApplicantSortingPreviewImgSrc from "assets/images/applicant-sorting-preview.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Body, BodySmall, Overline, PageTitle, Subtitle1, Subtitle2 } from "components/library/typography";
import Loading from "components/Loading";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetUsersClientQuery, usePartialUpdateClientMutation } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobCandidateSourceSettingsQuery } from "services/doverapi/endpoints/job-source-settings/endpoints";
import { UpdateClientBodyAtsTypeEnum, UpdateClientBodyDoverPlanEnum, ClientEmailProvider } from "services/openapi";
import { JobCandidateSourceSettingStateEnum } from "services/openapi/models/JobCandidateSourceSetting";
import { colors, screenSizesNumbers } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import {
  CAREERS_PAGE_SETUP_ARTICLE_URL,
  LINKEDIN_CANDIDATE_SOURCE_LABEL,
  LINKEDIN_JOB_POSTING_ARTICLE_URL,
} from "views/create-job/AtsSetupFlow/constants";

/* -----------------------------------------------------------------------------
 * Styled Icons
 * -------------------------------------------------------------------------- */

const GreenEmptyCircle = styled(EmptyCircleIcon)`
  fill: ${colors.primary.light};

  & > circle {
    stroke: ${colors.primary.base};
  }
`;

const BlueExternalLinkIcon = styled(ExternalLinkIcon)`
  & path {
    stroke: ${colors.linkLight};
  }
`;

const BlueHelpQuestionIcon = styled(HelpQuestionIcon)`
  & path {
    stroke: ${colors.linkLight};
  }
`;

/* -----------------------------------------------------------------------------
 * AtsSetupCompletionStep
 * -------------------------------------------------------------------------- */

interface AtsSetupCompletionStepProps extends Omit<CompletionStepProps, "isLoading"> {
  jobId: string;
}

const AtsSetupCompletionStep = ({ jobId, goNext }: AtsSetupCompletionStepProps): React.ReactElement => {
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const isLargeScreen = useMediaQuery(muiTheme.breakpoints.up(screenSizesNumbers.mobileL));

  const { data: client, isLoading: isLoadingClientData } = useGetUsersClientQuery();

  const [partialUpdateClient] = usePartialUpdateClientMutation();
  const {
    data: candidateSourceSettings,
    isLoading: isLoadingCandidateSourceSettings,
  } = useGetJobCandidateSourceSettingsQuery({
    jobId: jobId,
    includeAdminPreview: false,
  });
  const linkedInSource = candidateSourceSettings?.find(
    source => source.candidateSource?.label === LINKEDIN_CANDIDATE_SOURCE_LABEL
  );
  const isLinkedInEnabled = linkedInSource?.state === JobCandidateSourceSettingStateEnum.Active;

  const updateClientAtsType = async (): Promise<void> => {
    if (client?.id) {
      await partialUpdateClient({
        id: client.id,
        updatedClient: {
          ...client,
          atsType: UpdateClientBodyAtsTypeEnum.NoAts, // No ATS === Dover
          // the fields below are not being updated, only writing them this way for typing reasons
          doverPlan: client.doverPlan ? ((client.doverPlan as unknown) as UpdateClientBodyDoverPlanEnum) : undefined,
          emailProvider: client.emailProvider ? ((client.emailProvider as unknown) as ClientEmailProvider) : undefined,
        },
      });
    }
  };

  const onSetupSorting = async (): Promise<void> => {
    // Set Dover as the Client's ATS once they complete the ATS Setup Flow
    await updateClientAtsType();
    await goNext();
    navigate(APP_ROUTE_PATHS.onboarding.applicantSorting(jobId));
  };

  const onExit = async (): Promise<void> => {
    // Set Dover as the Client's ATS once they complete the ATS Setup Flow
    await updateClientAtsType();
    await goNext();
    navigate(APP_ROUTE_PATHS.home());
  };

  if (isLoadingClientData || isLoadingCandidateSourceSettings) {
    return <Loading />;
  }

  if (!client) {
    showErrorToast("There was an error finding your company. Please refresh and try again.");
    return <></>;
  }

  return (
    <Stack spacing={3} p={2}>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <CircledGreenCheckmarkIcon width="24px" height="24px" />
          <PageTitle>You&apos;re all set up</PageTitle>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack alignItems="center" width="24px" height="100%">
            <Box width="1px" height="100%" bgcolor={colors.grayscale.gray200} />
          </Stack>
          <Card flex="1 1 auto">
            <Stack spacing={1} flex="1 1 auto">
              <Stack direction={isLargeScreen ? "row" : "column"} spacing={1}>
                <WebsiteWithCheckmarkIcon width="36px" height="36px" />
                <Stack>
                  <Subtitle2>Careers page created</Subtitle2>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Link to={APP_ROUTE_PATHS.careersPage(client.name, client?.id ?? "")} target="_blank">
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <BodySmall color={colors.linkLight}>View</BodySmall>
                        <BlueExternalLinkIcon width="16px" height="16px" />
                      </Stack>
                    </Link>
                    <Box width="1px" height="10px" bgcolor={colors.grayscale.gray200} />
                    <a target="_blank" rel="noopener noreferrer" href={CAREERS_PAGE_SETUP_ARTICLE_URL}>
                      <Stack direction="row" alignItems="center" spacing={0.5}>
                        <BodySmall color={colors.linkLight}>Link from your website</BodySmall>
                        <BlueHelpQuestionIcon />
                      </Stack>
                    </a>
                  </Stack>
                </Stack>
              </Stack>
              {isLinkedInEnabled && (
                <Stack direction={isLargeScreen ? "row" : "column"} spacing={1}>
                  <LinkedInWithCheckmarkIcon width="36px" height="36px" />
                  <Stack>
                    <Subtitle2>Your LinkedIn posting will go live on your company page within 24 hours</Subtitle2>
                    <a target="_blank" rel="noopener noreferrer" href={LINKEDIN_JOB_POSTING_ARTICLE_URL}>
                      <BodySmall color={colors.linkLight}>Learn more</BodySmall>
                    </a>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Card>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <GreenEmptyCircle width="24px" height="24px" />
          <PageTitle>What&apos;s next?</PageTitle>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack width="24px" />
          <Stack spacing={1} flex="1 1 auto" maxWidth="570px">
            {!isLinkedInEnabled && (
              <Card flex="1 1 auto">
                <Stack spacing={1} flex="1 1 auto">
                  <Stack
                    direction={isLargeScreen ? "row" : "column"}
                    spacing={1}
                    alignItems={isLargeScreen ? "center" : "flex-start"}
                  >
                    <Stack
                      width="28px"
                      height="28px"
                      bgcolor={colors.informational.light}
                      justifyContent="center"
                      alignItems="center"
                      borderRadius={1}
                    >
                      <LinkedInIcon width="16px" height="16px" />
                    </Stack>
                    <Stack direction={isLargeScreen ? "row" : "column"} spacing={2}>
                      <Subtitle2>Post to LinkedIn for FREE and other job boards</Subtitle2>
                      <Link to={APP_ROUTE_PATHS.job.jobBoards(jobId)}>
                        <BodySmall color={colors.linkLight}>Post to job boards</BodySmall>
                      </Link>
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            )}
            <Card>
              <Stack spacing={1}>
                <Overline color={colors.primary.base}>AI-Powered Applicant Sorting</Overline>
                <Subtitle1>Dover auto-sorts new applicants for easy reviewing</Subtitle1>
                <img src={ApplicantSortingPreviewImgSrc} alt="Applicant sorting preview" />
                <Body color={colors.grayscale.gray500} italic>
                  Takes ~2 minutes to set up, but feel free to do this later
                </Body>
              </Stack>
            </Card>
            <Stack direction="row" justifyContent={isLargeScreen ? "flex-end" : "space-between"} spacing={1}>
              <Button variant={ButtonVariant.Primary} onClick={onSetupSorting} grow={isLargeScreen ? false : true}>
                Set up AI sorting
              </Button>
              <Button variant={ButtonVariant.SecondarySuccess} onClick={onExit} grow={isLargeScreen ? false : true}>
                Finish &amp; Exit
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * CompletionStep
 * -------------------------------------------------------------------------- */

export interface CompletionStepProps {
  goNext: () => Promise<void>;
  isLoading: boolean;
}

export const CompletionStep = ({ goNext, isLoading }: CompletionStepProps): React.ReactElement => {
  const jobId = useJobIdFromUrl();
  const navigate = useNavigate();

  if (isLoading) {
    return <Loading />;
  }

  if (!jobId) {
    navigate(APP_ROUTE_PATHS.home(), { replace: true });
    return <></>;
  }

  return <AtsSetupCompletionStep jobId={jobId} goNext={goNext} />;
};
