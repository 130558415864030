export function roundDepthCount(count: number): String {
  if (count < 50) {
    return "<50";
  }

  if (count < 1000) {
    return `~${(Math.round(count / 10) * 10).toLocaleString("en-US")}`;
  }

  // if the count is less than 10,000 (or we want to ignore this limit), round to the nearest 100
  if (count < 10000) {
    return `~${(Math.round(count / 100) * 100).toLocaleString("en-US")}`;
  }

  return "10,000+";
}
