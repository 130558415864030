import { zodResolver } from "@hookform/resolvers/zod";
import { InputAdornment, Stack, TextField, createFilterOptions } from "@mui/material";
import React, { ReactElement } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";

import { ReactComponent as CheckGreenFilled } from "assets/icons/check-green-filled.svg";
import { Autocomplete } from "components/library/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Heading, BodyExtraSmall, BodySmall } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import CustomModal from "components/Modal";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { Agency, CreateAgencyRecruiterRateTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { AddAgencyFormSchema, addAgencyFormSchema } from "views/agencies/types";
import { useCreateAgencyRecruiterMutation, useListAgenciesQuery } from "views/referralsV2/endpoints";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    border: none;
  }
`;

const OffsetButton = styled(Button)`
  margin-right: -1px;
`;

interface AutocompleteAgency extends Agency {
  inputValue?: string;
}

interface SuccessScreenProps {
  agencyName: string;
}

const SuccessScreen: React.FC<SuccessScreenProps> = ({ agencyName }) => {
  return (
    <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
      <CheckGreenFilled width={"40px"} />
      <Heading weight="600">Agency added!</Heading>
      <Body>{agencyName} has been added</Body>
    </Stack>
  );
};

export const AddAgencyModal: React.FC<GlobalModalProps> = ({ isOpen, close }) => {
  const jobId = useJobIdFromUrl();
  const filter = createFilterOptions<AutocompleteAgency>();

  const [page, setPage] = React.useState<number>(0);
  const { data: agencies, isFetching: isFetchingAgencies } = useListAgenciesQuery({ limit: 300 });

  const [selectedAgency, setSelectedAgency] = React.useState<AutocompleteAgency | null>(null);

  const [selectedRateType, setSelectedRateType] = React.useState<CreateAgencyRecruiterRateTypeEnum>(
    CreateAgencyRecruiterRateTypeEnum.Percentage
  );

  const formMethods = useForm<AddAgencyFormSchema>({
    resolver: zodResolver(addAgencyFormSchema),
    defaultValues: {
      jobId,
      agencyName: "",
      email: "",
      firstName: "",
      lastName: "",
      rate: undefined,
      rateType: CreateAgencyRecruiterRateTypeEnum.Percentage,
    },
  });

  const {
    register,
    formState: { errors: formErrors },
    setValue,
    getValues,
    handleSubmit,
  } = formMethods;

  const [addAgencyRecruiter, { isLoading: isCreatingAgencyRecruiter }] = useCreateAgencyRecruiterMutation();

  const addAgency = async (): Promise<void> => {
    if (!jobId) {
      return;
    }

    const formValues = getValues();
    await addAgencyRecruiter({
      jobId,
      data: {
        agencyId: selectedAgency?.id || undefined,
        agencyName: selectedAgency?.id ? undefined : selectedAgency?.name,
        recruiterEmail: formValues.email,
        recruiterName: `${formValues.firstName} ${formValues.lastName}`,
        rate: formValues.rate,
        rateType: selectedRateType,
      },
    }).unwrap();
    setPage(1);
  };

  const onAddClick = async (): Promise<void> => {
    setValue("agencyName", selectedAgency?.name || "");
    setValue("rateType", selectedRateType);
    handleSubmit(addAgency)();
  };

  return (
    <CustomModal
      title="Add Agency"
      loading={isFetchingAgencies}
      open={isOpen}
      onClose={close}
      maxWidth="sm"
      omitDividers
      showTitleSpacer={false}
    >
      {isCreatingAgencyRecruiter ? (
        <DoverLoadingOverlay active backgroundColor="white" />
      ) : (
        <FormProvider {...formMethods}>
          {page === 0 ? (
            <Stack spacing={2}>
              <BodyExtraSmall weight={"500"} color={colors.grayscale.gray500}>
                AGENCY INFORMATION
              </BodyExtraSmall>
              <Stack spacing={0.5}>
                <BodySmall weight="600">Agency Name</BodySmall>
                <Autocomplete
                  // @ts-ignore
                  freeSolo
                  autoHighlight
                  selectOnFocus
                  clearOnBlur
                  placeholder="Agency Name"
                  // @ts-ignore
                  initialValue={selectedAgency}
                  staticOptions={(agencies?.results || []) as AutocompleteAgency[]}
                  errorText={formErrors.agencyName?.message}
                  filterOptions={(options, params): any[] => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(option => inputValue === option.name);

                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        id: "",
                        name: `Add: "${inputValue}"`,
                        inputValue,
                      });
                    }

                    return filtered;
                  }}
                  onSelectedOptionChange={(value: string | object): void => {
                    if (typeof value === "string") {
                      setSelectedAgency({
                        id: "",
                        name: value,
                      });
                      // @ts-ignore
                    } else if (value && value.inputValue) {
                      // Create a new value from the user input
                      setSelectedAgency({
                        id: "",
                        // @ts-ignore
                        name: value.inputValue,
                      });
                    } else {
                      setSelectedAgency((value as AutocompleteAgency) || null);
                    }
                  }}
                  isOptionEqualToValue={(option: AutocompleteAgency, value: AutocompleteAgency): boolean => {
                    return option.name === value.name;
                  }}
                  getOptionLabel={(option: string | AutocompleteAgency): string => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  renderOption={(props, option): ReactElement => {
                    const { ...optionProps } = props;
                    return (
                      <li key={option.name} {...optionProps}>
                        {option.name}
                      </li>
                    );
                  }}
                />
              </Stack>

              <BodyExtraSmall weight={"500"} color={colors.grayscale.gray500}>
                RECRUITER INFORMATION
              </BodyExtraSmall>

              <Stack spacing={3} direction="row" justifyContent={"space-between"}>
                <Stack direction="row" spacing={1} width="100%">
                  <Stack spacing={0.5} width="50%">
                    <BodySmall weight="600">First Name</BodySmall>
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="First Name"
                      required={true}
                      inputProps={{ ...register("firstName") }}
                      helperText={formErrors.firstName?.message}
                      error={!!formErrors.firstName}
                    />
                  </Stack>
                  <Stack spacing={0.5} width="50%">
                    <BodySmall weight="600">First Name</BodySmall>
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Last Name"
                      required={true}
                      inputProps={{ ...register("lastName") }}
                      helperText={formErrors.lastName?.message}
                      error={!!formErrors.lastName}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Stack spacing={0.5}>
                <BodySmall weight="600">Email</BodySmall>
                <StyledTextField
                  fullWidth
                  size="small"
                  placeholder="Email"
                  required={true}
                  inputProps={{ ...register("email") }}
                  helperText={formErrors.email?.message}
                  error={!!formErrors.email}
                />
              </Stack>

              <Stack spacing={0.5}>
                <BodySmall weight="600">Fee</BodySmall>
                <Stack direction="row" spacing={1}>
                  <Stack sx={{ width: "100%", maxWidth: "370px" }}>
                    <StyledTextField
                      fullWidth
                      size="small"
                      placeholder="Fee"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedRateType === CreateAgencyRecruiterRateTypeEnum.Percentage ? "%" : "$"}
                          </InputAdornment>
                        ),
                      }}
                      required={true}
                      inputProps={{ ...register("rate") }}
                      helperText={formErrors.rate?.message}
                      error={!!formErrors.rate}
                    />
                  </Stack>

                  <Stack direction="row" height="40px">
                    <OffsetButton
                      removeOutline
                      variant={
                        selectedRateType === CreateAgencyRecruiterRateTypeEnum.Percentage
                          ? ButtonVariant.GhostPrimary2
                          : ButtonVariant.GhostPrimary
                      }
                      borderRadius="4px 0px 0px 4px"
                      onClick={(): void => {
                        setSelectedRateType(CreateAgencyRecruiterRateTypeEnum.Percentage);
                      }}
                    >
                      <BodySmall
                        weight={selectedRateType === CreateAgencyRecruiterRateTypeEnum.Percentage ? "500" : undefined}
                        color={
                          selectedRateType === CreateAgencyRecruiterRateTypeEnum.Percentage ? colors.brand : undefined
                        }
                      >
                        % Percent
                      </BodySmall>
                    </OffsetButton>
                    <Button
                      removeOutline
                      variant={
                        selectedRateType === CreateAgencyRecruiterRateTypeEnum.FlatRate
                          ? ButtonVariant.GhostPrimary2
                          : ButtonVariant.GhostPrimary
                      }
                      borderRadius="0px 4px 4px 0px"
                      onClick={(): void => {
                        setSelectedRateType(CreateAgencyRecruiterRateTypeEnum.FlatRate);
                      }}
                    >
                      <BodySmall
                        weight={selectedRateType === CreateAgencyRecruiterRateTypeEnum.FlatRate ? "500" : undefined}
                        color={
                          selectedRateType === CreateAgencyRecruiterRateTypeEnum.FlatRate ? colors.brand : undefined
                        }
                      >
                        $ Fee
                      </BodySmall>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="row" justifyContent={"flex-end"} width="100%" sx={{ marginTop: "30px !important" }}>
                <Stack width="fit-content">
                  <Button variant={ButtonVariant.Primary} onClick={onAddClick}>
                    Add
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <SuccessScreen agencyName={selectedAgency?.name || ""} />
          )}
        </FormProvider>
      )}
    </CustomModal>
  );
};

export const addAgencyModalAtom = modalAtom(AddAgencyModal);
