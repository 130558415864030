import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useQueryParam } from "use-query-params";

import { Body, BodySmall, Subtitle1 } from "components/library/typography";
import BaseStepControls from "sections/addcandidate/components/BaseStepControls";
import HorizontalLoader from "sections/addcandidate/components/HorizontalLoader";
import PastStepsSummary from "sections/addcandidate/components/PastStepsSummary";
import candidateInfoStep from "sections/addcandidate/components/steps/CandidateInfoStep";
import jobSelectionStep from "sections/addcandidate/components/steps/JobSelectionStep";
import selectCampaignStep from "sections/addcandidate/components/steps/SelectCampaignStep";
import { OUTREACH_TYPE_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { StepConfig } from "sections/addcandidate/data/stepConfig";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { AddCandidateStep, StepTypes, OutreachTypeParam, OutreachTypes } from "sections/addcandidate/types";
import { CandidateInfoSchema } from "sections/addcandidate/types/candidateInfo";
import { colors } from "styles/theme";

const pastSteps = [
  {
    label: "Job",
    step: jobSelectionStep,
  },
  {
    label: "Candidate Details",
    step: candidateInfoStep,
  },
  {
    label: "Campaign",
    step: selectCampaignStep,
  },
];

const SingleOutreachPostCreateSummary = (): React.ReactElement => {
  const {
    flowState: { [StepTypes.CANDIDATE_INFO]: state },
  } = useAddCandidateContext();
  const candidateInfoState = state as CandidateInfoSchema;
  const emailProvided = Boolean(candidateInfoState.email);

  return (
    <>
      <Subtitle1>Post Create Actions</Subtitle1>
      <Stack direction="column" spacing={1} sx={{ pl: 1 }}>
        {!emailProvided && (
          <>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <div style={{ width: "100%" }}>
                <Body>
                  <b>Email Finding</b>
                </Body>
              </div>
              <div style={{ textAlign: "right", minWidth: "70%" }}>
                <Body>We will attempt to find contact infromation.</Body>
              </div>
            </Stack>
            <Divider />
          </>
        )}
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <div style={{ width: "100%" }}>
            <Body>
              <b>Outreach</b>
            </Body>
          </div>
          <div style={{ textAlign: "right", minWidth: "70%" }}>
            <Body>
              We will attempt to email the candidate. Emails are queued to send during our valid sending windows. The
              candidate will be visible in the Outbox.
            </Body>
          </div>
        </Stack>
      </Stack>
    </>
  );
};

const BulkOutreachPostCreateSummary = (): React.ReactElement => (
  <>
    <Subtitle1>Post Create Actions</Subtitle1>
    <Stack direction="column" spacing={1} sx={{ pl: 1 }}>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <div style={{ width: "100%" }}>
          <Body>
            <b>Candidate Creation</b>
          </Body>
        </div>
        <div style={{ textAlign: "right", minWidth: "70%" }}>
          <Body>
            We will attempt to find candidate details from the provided LinkedIn URLs. We need this information to write
            personalized emails.
          </Body>
        </div>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <div style={{ width: "100%" }}>
          <Body>
            <b>Email Finding</b>
          </Body>
        </div>
        <div style={{ textAlign: "right", minWidth: "70%" }}>
          <Body>We will attempt to find emails for any candidates where they were not provided.</Body>
        </div>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <div style={{ width: "100%" }}>
          <Body>
            <b>Outreach</b>
          </Body>
        </div>
        <div style={{ textAlign: "right", minWidth: "70%" }}>
          <Body>If the previous steps went well. We will add the candidates to the outreach queue.</Body>
        </div>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="center" textAlign="center" alignItems="center" spacing={1}>
        <Body>
          This entire process can take several hours after which you can expect to see these profiles in the{" "}
          <em>Candidates</em> view.
        </Body>
      </Stack>
    </Stack>
  </>
);

const StepContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    handleNextStep,
    handleAddCandidate: handleAddCandidateOrBulkAddCandidates,
    isSubmitting,
  } = useAddCandidateContext();

  const [outreachType] = useQueryParam(OUTREACH_TYPE_QUERY_PARAM, OutreachTypeParam);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  //-----------------------------------------------------------------------------
  // Callbacks

  const onSubmit = async (): Promise<void> => {
    // 0. Clear out old error state
    setErrorMessage(undefined);

    // 1. Attempt to add the candidate
    const result = await handleAddCandidateOrBulkAddCandidates(outreachType);

    // 2. Check result
    // 2.1 If it went well, advance
    if (result.success) {
      handleNextStep();
    } else {
      // 2.2 Otherwise, surface the error message
      setErrorMessage(result.message);
    }
  };

  //-----------------------------------------------------------------------------
  // Effects

  useEffect((): (() => void) => {
    // Clear error message on unmount
    return (): void => {
      setErrorMessage(undefined);
    };
  }, []);

  //-----------------------------------------------------------------------------
  // Render

  return (
    <>
      {errorMessage && (
        <Box sx={{ mt: 2 }}>
          <Body color={colors.critical.base}>{errorMessage}</Body>
        </Box>
      )}

      {isSubmitting ? (
        <Box sx={{ mt: 2, mb: 4 }}>
          <HorizontalLoader />
        </Box>
      ) : (
        <Stack
          direction="column"
          spacing={2}
          sx={{ mb: 4, mt: 2, border: "1px solid black", borderRadius: "4px", p: 1 }}
        >
          <PastStepsSummary pastSteps={pastSteps} />

          {outreachType !== OutreachTypes.BULK ? (
            <SingleOutreachPostCreateSummary />
          ) : (
            <BulkOutreachPostCreateSummary />
          )}
        </Stack>
      )}

      <BaseStepControls continueLabel="Finish & Create" overrideHandleNext={onSubmit} disableContinue={isSubmitting} />
    </>
  );
};

const PostCompletionContent = (): React.ReactElement => {
  const { isSubmitting } = useAddCandidateContext();

  return <BodySmall>{isSubmitting ? "Submitting..." : "Done!"}</BodySmall>;
};

const step: AddCandidateStep = {
  ...StepConfig[StepTypes.INITIAL_OUTREACH_REVIEW],
  StepContent: <StepContent />,
  PostCompletionContent: <PostCompletionContent />,
};

export default step;
