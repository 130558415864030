import React, { createContext, useContext, useState } from "react";

import { FeatureSelectorFeatureName } from "components/onboarding/types";

interface AppRootState {
  selectedFeature?: FeatureSelectorFeatureName;
  setSelectedFeature: (selectedFeature: FeatureSelectorFeatureName | undefined) => void;
}

const initialState = {
  selectedFeature: undefined,
  setSelectedFeature: (): void => {},
};

const AppRootContext = createContext<{
  selectedFeature?: FeatureSelectorFeatureName;
  setSelectedFeature: (selectedFeature: FeatureSelectorFeatureName | undefined) => void;
}>(initialState);

export const AppRootProvider: React.FC = ({ children }) => {
  const [selectedFeature, setSelectedFeature] = useState<FeatureSelectorFeatureName | undefined>();

  return (
    <AppRootContext.Provider
      value={{
        selectedFeature,
        setSelectedFeature,
      }}
    >
      {children}
    </AppRootContext.Provider>
  );
};

export const useAppRootContext = (): AppRootState => useContext<AppRootState>(AppRootContext);
