import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";

import { Card } from "components/library/Card";
import { Body, BodySmall } from "components/library/typography";
import { FormBodyWrapper } from "views/interview/common/components/InterviewRubric/styles";
import { Section } from "views/interview/common/types";

interface SectionHeaderProps {
  section: Section;
}

export const SectionHeader = ({ section }: SectionHeaderProps): React.ReactElement => (
  <Box mb={2}>
    <Body weight="600">{section.title}</Body>
    {section.context && <BodySmall>{section.context}</BodySmall>}
  </Box>
);

interface FormSectionProps {
  sectionRef: any;
  section: Section;
  globalDisabled: boolean;
}

const FormSection = ({ sectionRef, section, globalDisabled }: FormSectionProps): React.ReactElement => {
  const formOptions = useFormApi();

  return (
    <Box mb={4}>
      <Card>
        <Grid item key={section.name} ref={sectionRef.ref} id={section.name} xs={12}>
          <Grid item xs={12}>
            <SectionHeader section={section} />
          </Grid>
          {section.fields.map(item => {
            return (
              <Grid item key={item.name} ref={sectionRef.questionRefs[item.name]} xs={12}>
                {formOptions.renderForm([{ ...item, isDisabled: item.isDisabled || globalDisabled, validate: [] }])}
              </Grid>
            );
          })}
        </Grid>
      </Card>
    </Box>
  );
};

interface Props {
  sectionRefs: any;
  sections: Section[];
  globalDisabled: boolean;
}

export default ({ sectionRefs, sections, globalDisabled }: Props): React.ReactElement => (
  <FormBodyWrapper>
    {sections.map(section => (
      <FormSection
        key={section.name}
        section={section}
        sectionRef={sectionRefs[section.name]}
        globalDisabled={globalDisabled}
      />
    ))}
  </FormBodyWrapper>
);
