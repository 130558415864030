/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { meta } from "@data-driven-forms/common/prop-types-templates";
import parseInternalValue from "@data-driven-forms/common/select/parse-internal-value";
import PropTypes from "prop-types";
import React from "react";

import { FormFieldGrid, validationError } from "components/data-driven-forms/fields/helpers";
import { createValue } from "components/data-driven-forms/fields/SingleSelectDropdown/utils";
import SingleSelectDropdown from "components/inputs/SingleSelectDropdown";

const InternalSingleSelectDropdown = ({
  value,
  options,
  label,
  helperText,
  validateOnMount,
  meta,
  isSearchable,
  description,
  placeholder,
  required,
  onChange,
  onFocus,
  onBlur,
  FormFieldGridProps,
  maxOptions,
  textFieldLabel,
  ...rest
}) => {
  const invalid = validationError(meta, validateOnMount);
  const internalValue = parseInternalValue(value, false);
  const [internalSelected, setInternalSelected] = React.useState();

  React.useEffect(() => {
    // Handle remounts in wizard form correctly
    if ((internalValue || []).length > 0 && !internalSelected) {
      setInternalSelected(internalValue);
    }
  }, [internalValue]);

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <SingleSelectDropdown
        {...rest}
        label={label}
        maxOptions={maxOptions}
        TextFieldProps={{
          onFocus,
          onBlur,
          required,
          invalid,
          label: textFieldLabel,
          helperText: invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description,
          isSearchable,
          placeholder: !internalValue || internalValue.length === 0 ? placeholder : undefined,
        }}
        options={options}
        value={
          internalSelected ? createValue(internalSelected) : typeof internalValue === "undefined" ? null : internalValue
        }
        onChange={(_event, option) => {
          setInternalSelected(option);
          onChange(createValue(option));
        }}
      />
    </FormFieldGrid>
  );
};

InternalSingleSelectDropdown.propTypes = {
  meta,
  placeholder: PropTypes.node,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.node.isRequired }))
    .isRequired,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  maxOptions: PropTypes.number,
  textFieldLabel: PropTypes.string,
};

InternalSingleSelectDropdown.defaultProps = {
  placeholder: "Please choose",
  FormFieldGridProps: {},
  inputProps: {},
  isSearchable: true,
  textFieldLabel: "Choose...",
};

export default InternalSingleSelectDropdown;
