import { useState, useEffect, RefObject, MutableRefObject } from "react";

interface UseElementDimensionsResult {
  height: number;
  width: number;
}

export const useElementDimensions = <T extends HTMLElement = HTMLDivElement>(
  elementRef: RefObject<T | undefined> | MutableRefObject<T | undefined>
): UseElementDimensionsResult => {
  const [dimensions, setDimensions] = useState<UseElementDimensionsResult>({
    height: elementRef.current?.offsetHeight ?? 0,
    width: elementRef.current?.offsetWidth ?? 0,
  });

  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver(entries => {
      const { height, width } = entries[0].contentRect;
      setDimensions({ height, width });
    });
    resizeObserver.observe(elementRef.current);
    return (): void => resizeObserver.disconnect(); // clean up
  }, [elementRef]);

  return dimensions;
};
