import React, { useCallback, useMemo } from "react";

import { Checkbox } from "components/library/Checkbox";
import { CandidateFilterSourcingContextEnum } from "services/openapi";
import { StyledFormControlLabel } from "views/candidates/CandidateTable/filters/styles";
import { useParams } from "views/candidates/hooks";

interface CandidateSourceFilterProps {
  label: string;
  value: CandidateFilterSourcingContextEnum;
}

export const CandidateSourceFilter = ({ label, value }: CandidateSourceFilterProps): React.ReactElement => {
  const [{ sourcingContext }, setParams] = useParams();

  const setFromContextArray = useMemo(() => new Set(sourcingContext), [sourcingContext]);

  const handleClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setFromContextArray.add(value);
      } else {
        setFromContextArray.delete(value);
      }

      setParams({ sourcingContext: Array.from(setFromContextArray), quickFilter: undefined });
    },
    [setFromContextArray, setParams, value]
  );

  const isChecked = useMemo(() => setFromContextArray.has(value), [setFromContextArray, value]);

  return (
    <StyledFormControlLabel
      control={<Checkbox checked={isChecked} onChange={handleClick} value={value} />}
      label={label}
      labelPlacement={"end"}
    />
  );
};
