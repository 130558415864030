import { createEntityAdapter, EntityState, SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { DoverEntityRoute } from "services/openapi";

const doverEntityAdapter = createEntityAdapter<DoverEntityRoute>();

const doverEntityEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listDoverEntities: build.query<EntityState<DoverEntityRoute>, { email: string }>({
      queryFn: async ({ email }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const resp = await apiApi.listDoverEntities({ email });
          return { data: doverEntityAdapter.addMany(doverEntityAdapter.getInitialState(), resp.results) };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useListDoverEntitiesQuery } = doverEntityEndpoints;
