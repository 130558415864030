import { Box, Stack, Menu, Divider } from "@mui/material";
import React from "react";

import { ReactComponent as EllipsisSVG } from "assets/icons/ellipsis.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodyExtraSmall } from "components/library/typography";
import { useConciergeInfo } from "services/doverapi/endpoints/client/hooks";
import { CandidateBio } from "services/openapi";
import { colors } from "styles/theme";
import { FakeLink, ExternalLink } from "styles/typography";
import { focusNotesEditor } from "utils/focusNotesEditor";

export const InterviewStagesMenuButton = ({
  options,
  candidateBio,
}: {
  options: React.ReactNode[];
  candidateBio: CandidateBio;
}): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const conciergeInfo = useConciergeInfo();

  return (
    <>
      <Button
        height="100%"
        removePadding
        variant={ButtonVariant.Secondary}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" padding="0px 8px">
          <EllipsisSVG />
        </Box>
      </Button>
      <Menu open={!!anchorEl} onClose={(): void => setAnchorEl(null)} anchorEl={anchorEl}>
        <Stack maxWidth="240px" alignItems="flex-start">
          {options}
          <Divider />
          <Stack spacing={1} padding="10px 16px 0px 16px">
            <BodyExtraSmall color={colors.grayscale.gray600} italic>
              Need to customize next steps with this candidate?{" "}
            </BodyExtraSmall>
            <Stack direction="row" spacing={0.6}>
              <BodyExtraSmall color={colors.grayscale.gray600}>
                <ExternalLink href={candidateBio.slackUrl} target="_blank" rel="noopener noreferrer">
                  Slack {conciergeInfo?.firstName}
                </ExternalLink>
                {" or "}
              </BodyExtraSmall>
              <BodyExtraSmall>
                <FakeLink onClick={focusNotesEditor}>tag them in a note</FakeLink>
              </BodyExtraSmall>
            </Stack>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};
