/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DoverEntityEmail,
    DoverEntityEmailFromJSON,
    DoverEntityEmailFromJSONTyped,
    DoverEntityEmailToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminClient
 */
export interface AdminClient {
    /**
     * 
     * @type {string}
     * @memberof AdminClient
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminClient
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminClient
     */
    realName: string;
    /**
     * 
     * @type {DoverEntityEmail}
     * @memberof AdminClient
     */
    csm: DoverEntityEmail | null;
}

export function AdminClientFromJSON(json: any): AdminClient {
    return AdminClientFromJSONTyped(json, false);
}

export function AdminClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'realName': json['real_name'],
        'csm': DoverEntityEmailFromJSON(json['csm']),
    };
}

export function AdminClientToJSON(value?: AdminClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'real_name': value.realName,
        'csm': DoverEntityEmailToJSON(value.csm),
    };
}


