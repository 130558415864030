/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignMessageRequestCampaign,
    CampaignMessageRequestCampaignFromJSON,
    CampaignMessageRequestCampaignFromJSONTyped,
    CampaignMessageRequestCampaignToJSON,
} from './';

/**
 * 
 * @export
 * @interface CampaignMessageRequestCampaignMessage
 */
export interface CampaignMessageRequestCampaignMessage {
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequestCampaignMessage
     */
    readonly id?: string;
    /**
     * 
     * @type {CampaignMessageRequestCampaign}
     * @memberof CampaignMessageRequestCampaignMessage
     */
    campaign: CampaignMessageRequestCampaign;
}

export function CampaignMessageRequestCampaignMessageFromJSON(json: any): CampaignMessageRequestCampaignMessage {
    return CampaignMessageRequestCampaignMessageFromJSONTyped(json, false);
}

export function CampaignMessageRequestCampaignMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignMessageRequestCampaignMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'campaign': CampaignMessageRequestCampaignFromJSON(json['campaign']),
    };
}

export function CampaignMessageRequestCampaignMessageToJSON(value?: CampaignMessageRequestCampaignMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaign': CampaignMessageRequestCampaignToJSON(value.campaign),
    };
}


