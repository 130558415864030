import {
  EnrichedCandidateSourceDetails,
  FormattedInboundSourceExplorerRow,
  JobCandidateSourceSettingMap,
  SourceStatus,
} from "services/doverapi/endpoints/job-source-settings/types";
import {
  CandidateSource,
  JobCandidateSourceSetting,
  JobCandidateSourceSettingDesiredStateEnum,
  JobCandidateSourceSettingStateEnum,
  JobCandidateSourceStats,
} from "services/openapi";

export const formatCandidateSourceDetails = (
  inboundSources: EnrichedCandidateSourceDetails[] | undefined
): FormattedInboundSourceExplorerRow[] => {
  const formattedInboundSourceRows: FormattedInboundSourceExplorerRow[] = [];
  inboundSources?.forEach((inboundSource, index) => {
    const avgNumCandidates = inboundSource.candidateSourceStats?.avgNumCandidatesPerJobF60d || undefined;
    const price = inboundSource.candidateSource.pricingLabel ? inboundSource.candidateSource.pricingLabel : "--";
    const sourceName = inboundSource.candidateSource.label;

    const formattedRow = {
      id: inboundSource.id,
      name: sourceName,
      price,
      avgNumCandidates,
      enrichedSourceDetails: inboundSource,
    };
    formattedInboundSourceRows[index] = formattedRow;
  });
  return formattedInboundSourceRows;
};

export const getMatchingCandidateSourceStats = (
  sourceId: string,
  candidateSourceStats: Array<JobCandidateSourceStats>
): JobCandidateSourceStats | undefined => {
  return candidateSourceStats.find(candidateSourceStat => candidateSourceStat.candidateSourceId === sourceId);
};

export const getMatchingSourceSetting = (
  source: CandidateSource,
  sourceSettings: JobCandidateSourceSettingMap
): JobCandidateSourceSetting | undefined => {
  return Object.values(sourceSettings).find(sourceSetting => sourceSetting.candidateSource!.id === source.id);
};

export const getSourceStatus = (jobCandidateSourceSettings: JobCandidateSourceSetting | undefined): SourceStatus => {
  if (!jobCandidateSourceSettings) {
    return SourceStatus.NeverActivated;
  }
  const desiredState = jobCandidateSourceSettings.desiredState;
  const state = jobCandidateSourceSettings.state;
  if (
    desiredState === JobCandidateSourceSettingDesiredStateEnum.Active &&
    state === JobCandidateSourceSettingStateEnum.Active
  ) {
    return SourceStatus.Active;
  } else if (
    desiredState === JobCandidateSourceSettingDesiredStateEnum.Active &&
    state === JobCandidateSourceSettingStateEnum.Inactive
  ) {
    return SourceStatus.SettingUp;
  } else if (
    desiredState === JobCandidateSourceSettingDesiredStateEnum.Inactive &&
    state === JobCandidateSourceSettingStateEnum.Active
  ) {
    return SourceStatus.Deactivating;
  }
  return SourceStatus.Inactive;
};
