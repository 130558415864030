import { useState, useEffect, Dispatch, SetStateAction } from "react";

import { session } from "utils/storage";

export const getStorageValue = <TValue>(key: string, defaultValue: TValue): TValue => {
  const saved = session.getItem(key);

  if (saved === null) {
    return defaultValue;
  }

  try {
    return JSON.parse(saved);
  } catch (error) {
    return saved as any;
  }
};

/**
 * Hook that provides a way to store and retrieve values from session storage.
 *
 * WARNING: this state is not shared between multiple instances of this hook.
 * So if you need to read and update from session storage in separate components
 * use the jotai storage atom instead: https://jotai.org/docs/utilities/storage
 *
 * @param key Key to use for the session storage entry
 * @param defaultValue default value to use if the session storage entry doesn't exist
 *
 * @returns A tuple containing the current value and a function to update it
 */
export const useSessionStorage = <TValue>(
  key: string,
  defaultValue: TValue
): [TValue, Dispatch<SetStateAction<TValue>>] => {
  const [value, setValue] = useState<TValue>(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    if (typeof value === "string") {
      session.setItem(key, value);
    } else {
      session.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};
