/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewPlanInterviewSubstage,
    InterviewPlanInterviewSubstageFromJSON,
    InterviewPlanInterviewSubstageFromJSONTyped,
    InterviewPlanInterviewSubstageToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewPlanMultipartInterviewStage
 */
export interface InterviewPlanMultipartInterviewStage {
    /**
     * 
     * @type {Array<InterviewPlanInterviewSubstage>}
     * @memberof InterviewPlanMultipartInterviewStage
     */
    substages: Array<InterviewPlanInterviewSubstage>;
    /**
     * 
     * @type {string}
     * @memberof InterviewPlanMultipartInterviewStage
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewPlanMultipartInterviewStage
     */
    interviewMeetingType?: InterviewPlanMultipartInterviewStageInterviewMeetingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InterviewPlanMultipartInterviewStage
     */
    interviewLocation?: string;
    /**
     * 
     * @type {object}
     * @memberof InterviewPlanMultipartInterviewStage
     */
    interviewDetails?: object;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewPlanMultipartInterviewStage
     */
    reuseConferenceLink?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InterviewPlanMultipartInterviewStage
     */
    debriefDuration?: number;
}

/**
* @export
* @enum {string}
*/
export enum InterviewPlanMultipartInterviewStageInterviewMeetingTypeEnum {
    GoogleMeet = 'GOOGLE_MEET',
    Zoom = 'ZOOM',
    InPerson = 'IN_PERSON',
    Other = 'OTHER',
    UseInterviewerPreference = 'USE_INTERVIEWER_PREFERENCE'
}

export function InterviewPlanMultipartInterviewStageFromJSON(json: any): InterviewPlanMultipartInterviewStage {
    return InterviewPlanMultipartInterviewStageFromJSONTyped(json, false);
}

export function InterviewPlanMultipartInterviewStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewPlanMultipartInterviewStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'substages': ((json['substages'] as Array<any>).map(InterviewPlanInterviewSubstageFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'interviewMeetingType': !exists(json, 'interview_meeting_type') ? undefined : json['interview_meeting_type'],
        'interviewLocation': !exists(json, 'interview_location') ? undefined : json['interview_location'],
        'interviewDetails': !exists(json, 'interview_details') ? undefined : json['interview_details'],
        'reuseConferenceLink': !exists(json, 'reuse_conference_link') ? undefined : json['reuse_conference_link'],
        'debriefDuration': !exists(json, 'debrief_duration') ? undefined : json['debrief_duration'],
    };
}

export function InterviewPlanMultipartInterviewStageToJSON(value?: InterviewPlanMultipartInterviewStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'substages': ((value.substages as Array<any>).map(InterviewPlanInterviewSubstageToJSON)),
        'id': value.id,
        'interview_meeting_type': value.interviewMeetingType,
        'interview_location': value.interviewLocation,
        'interview_details': value.interviewDetails,
        'reuse_conference_link': value.reuseConferenceLink,
        'debrief_duration': value.debriefDuration,
    };
}


