export const AddCandidateErrors = {
  singleOutreach: {
    email: {
      notFound:
        "Failed to find email. We won't be able to contact this person. Please go back and provide one if you can.",
      transientError:
        "Something went wrong trying to find an email 😢 Please try again or contact support if the issue persists.",
    },
  },
  bulkOutreach: {
    emptyCandidateList: "Please add at least one candidate.",
    campaignNotFound: "Please select a campaign.",
  },
  generic: {
    transientError: "Something went wrong 😢 Please try again or contact support if the issue persists.",
  },
};
