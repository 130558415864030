/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCampaignMessageRequest
 */
export interface UpdateCampaignMessageRequest {
    /**
     * 
     * @type {Date}
     * @memberof UpdateCampaignMessageRequest
     */
    emailSendRequestSendAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateCampaignMessageRequest
     */
    emailSendRequestState?: UpdateCampaignMessageRequestEmailSendRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCampaignMessageRequest
     */
    subjectOverride?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCampaignMessageRequest
     */
    bodyOverride?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum UpdateCampaignMessageRequestEmailSendRequestStateEnum {
    InReview = 'IN_REVIEW',
    Queued = 'QUEUED',
    ManuallyQueued = 'MANUALLY_QUEUED',
    QueuedExternal = 'QUEUED_EXTERNAL',
    Sent = 'SENT',
    Sending = 'SENDING',
    Cancelled = 'CANCELLED',
    Failed = 'FAILED'
}

export function UpdateCampaignMessageRequestFromJSON(json: any): UpdateCampaignMessageRequest {
    return UpdateCampaignMessageRequestFromJSONTyped(json, false);
}

export function UpdateCampaignMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCampaignMessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailSendRequestSendAt': !exists(json, 'email_send_request_send_at') ? undefined : (new Date(json['email_send_request_send_at'])),
        'emailSendRequestState': !exists(json, 'email_send_request_state') ? undefined : json['email_send_request_state'],
        'subjectOverride': !exists(json, 'subject_override') ? undefined : json['subject_override'],
        'bodyOverride': !exists(json, 'body_override') ? undefined : json['body_override'],
    };
}

export function UpdateCampaignMessageRequestToJSON(value?: UpdateCampaignMessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_send_request_send_at': value.emailSendRequestSendAt === undefined ? undefined : (value.emailSendRequestSendAt.toISOString()),
        'email_send_request_state': value.emailSendRequestState,
        'subject_override': value.subjectOverride,
        'body_override': value.bodyOverride,
    };
}


