import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { FC } from "react";

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { ReactComponent as MergeIcon } from "assets/icons/merge.svg";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useFindDupeContactQuery } from "services/doverapi/endpoints/contact";
import { Contact } from "services/openapi";
import { candidateMergeModalAtom } from "views/CandidateDetail/components/CandidateMergeModal";

interface CandidateMergeBannerProps {
  contact: Contact | undefined;
  candidateId: string | undefined;
}

export const CandidateMergeBanner: FC<CandidateMergeBannerProps> = ({ contact, candidateId }) => {
  const { open } = useModal(candidateMergeModalAtom);

  const args = contact?.id && candidateId ? { id: contact.id, candidateId: candidateId } : skipToken;
  const { data } = useFindDupeContactQuery(args);

  const [isDismissed, setDismissed] = useLocalStorage(`candidateMergeBannerDismissed-${candidateId}`, false);

  if (!contact || !data?.contact) {
    return null;
  }

  if (isDismissed) {
    return null;
  }

  const openMergeModal = (): void => {
    // I don't think this is even possible, but typescript isn't figuring that out
    if (!data?.contact) {
      return;
    }

    open({ originalContact: contact, duplicateContact: data.contact });
  };

  const dismiss = (): void => {
    setDismissed(true);
  };

  return (
    <Banner centeredIcon fullWidth variant={BannerVariant.Gray} overrideIcon={<InfoIcon />}>
      <Stack direction="row" alignItems="center" spacing={1} width="100%">
        <Body>This might be the same person as {data.contact.fullName}</Body>
        <Button variant={ButtonVariant.Secondary} onClick={openMergeModal}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <MergeIcon />
            <BodySmall>Preview & Merge</BodySmall>
          </Stack>
        </Button>
        <Box ml="auto !important">
          <Button variant={ButtonVariant.Ghost} onClick={dismiss}>
            X
          </Button>
        </Box>
      </Stack>
    </Banner>
  );
};

export const CandidateMergeButton: FC<CandidateMergeBannerProps> = ({ contact, candidateId }) => {
  const { open } = useModal(candidateMergeModalAtom);

  const args = contact?.id && candidateId ? { id: contact.id, candidateId: candidateId } : skipToken;
  const { data } = useFindDupeContactQuery(args);

  const [isDismissed] = useLocalStorage(`candidateMergeBannerDismissed-${candidateId}`, false);

  if (!contact || !data?.contact) {
    return null;
  }

  if (!isDismissed) {
    return null;
  }

  const openMergeModal = (): void => {
    // I don't think this is even possible, but typescript isn't figuring that out
    if (!data?.contact) {
      return;
    }

    open({ originalContact: contact, duplicateContact: data.contact });
  };

  return (
    <Tooltip title={`Preview & merge candidate with ${data.contact.fullName}`}>
      <Button removePadding variant={ButtonVariant.Secondary} onClick={openMergeModal}>
        <Box p={0.5} display="flex" alignItems="center">
          <MergeIcon />
        </Box>
      </Button>
    </Tooltip>
  );
};
