/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobReferralStats
 */
export interface JobReferralStats {
    /**
     * 
     * @type {number}
     * @memberof JobReferralStats
     */
    readonly total?: number;
    /**
     * 
     * @type {number}
     * @memberof JobReferralStats
     */
    readonly numInNeedsReview?: number;
    /**
     * 
     * @type {number}
     * @memberof JobReferralStats
     */
    readonly numInNeedsManualOutreach?: number;
    /**
     * 
     * @type {number}
     * @memberof JobReferralStats
     */
    readonly numInContacted?: number;
    /**
     * 
     * @type {number}
     * @memberof JobReferralStats
     */
    readonly numInRejected?: number;
}

export function JobReferralStatsFromJSON(json: any): JobReferralStats {
    return JobReferralStatsFromJSONTyped(json, false);
}

export function JobReferralStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobReferralStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'numInNeedsReview': !exists(json, 'num_in_needs_review') ? undefined : json['num_in_needs_review'],
        'numInNeedsManualOutreach': !exists(json, 'num_in_needs_manual_outreach') ? undefined : json['num_in_needs_manual_outreach'],
        'numInContacted': !exists(json, 'num_in_contacted') ? undefined : json['num_in_contacted'],
        'numInRejected': !exists(json, 'num_in_rejected') ? undefined : json['num_in_rejected'],
    };
}

export function JobReferralStatsToJSON(value?: JobReferralStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


