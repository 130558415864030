/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HiringPlanJobPositionOpening
 */
export interface HiringPlanJobPositionOpening {
    /**
     * 
     * @type {number}
     * @memberof HiringPlanJobPositionOpening
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPositionOpening
     */
    jobPosition: string;
    /**
     * 
     * @type {number}
     * @memberof HiringPlanJobPositionOpening
     */
    nthOpeningForPosition: number;
    /**
     * 
     * @type {Date}
     * @memberof HiringPlanJobPositionOpening
     */
    goalStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof HiringPlanJobPositionOpening
     */
    launchDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPositionOpening
     */
    status?: HiringPlanJobPositionOpeningStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPositionOpening
     */
    notes?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum HiringPlanJobPositionOpeningStatusEnum {
    NotStarted = 'NOT_STARTED',
    OnTrack = 'ON_TRACK',
    OffTrack = 'OFF_TRACK',
    Late = 'LATE',
    Completed = 'COMPLETED',
    Opportunistic = 'OPPORTUNISTIC'
}

export function HiringPlanJobPositionOpeningFromJSON(json: any): HiringPlanJobPositionOpening {
    return HiringPlanJobPositionOpeningFromJSONTyped(json, false);
}

export function HiringPlanJobPositionOpeningFromJSONTyped(json: any, ignoreDiscriminator: boolean): HiringPlanJobPositionOpening {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'jobPosition': json['job_position'],
        'nthOpeningForPosition': json['nth_opening_for_position'],
        'goalStartDate': !exists(json, 'goal_start_date') ? undefined : (json['goal_start_date'] === null ? null : new Date(json['goal_start_date'])),
        'launchDate': !exists(json, 'launch_date') ? undefined : (json['launch_date'] === null ? null : new Date(json['launch_date'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function HiringPlanJobPositionOpeningToJSON(value?: HiringPlanJobPositionOpening | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_position': value.jobPosition,
        'nth_opening_for_position': value.nthOpeningForPosition,
        'goal_start_date': value.goalStartDate === undefined ? undefined : (value.goalStartDate === null ? null : value.goalStartDate.toISOString()),
        'launch_date': value.launchDate === undefined ? undefined : (value.launchDate === null ? null : value.launchDate.toISOString()),
        'status': value.status,
        'notes': value.notes,
    };
}


