import Slider from "@mui/material/Slider";
import styled from "styled-components";

import { colors } from "styles/theme";

const StyledSlider = styled(Slider)`
  &.MuiSlider-root {
    .MuiSlider-rail {
      color: ${colors.grayscale.gray300};
    }

    .MuiSlider-track {
      color: ${colors.brand};
    }

    .MuiSlider-thumb {
      color: ${colors.primary.base};
    }
  }
`;

export const StyledAlignedSlider = styled(StyledSlider)`
  &.MuiSlider-root {
    width: 96%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;

    .MuiSlider-rail {
      width: 103%;
      position: relative;
      right: 9px;
      color: ${colors.grayscale.gray300};
    }

    .MuiSlider-track {
      color: ${colors.brand};
    }

    .MuiSlider-thumb {
      color: ${colors.primary.base};
    }

    .MuiSlider-mark {
      top: 30px;
      background-color: ${colors.grayscale.gray300};
      height: 8px;
    }
  }
`;
