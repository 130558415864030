/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchV3ExplainedFilter,
    SearchV3ExplainedFilterFromJSON,
    SearchV3ExplainedFilterFromJSONTyped,
    SearchV3ExplainedFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchV3Explanation
 */
export interface SearchV3Explanation {
    /**
     * 
     * @type {Array<SearchV3ExplainedFilter>}
     * @memberof SearchV3Explanation
     */
    passingFilters: Array<SearchV3ExplainedFilter>;
    /**
     * 
     * @type {Array<SearchV3ExplainedFilter>}
     * @memberof SearchV3Explanation
     */
    failingFilters: Array<SearchV3ExplainedFilter>;
}

export function SearchV3ExplanationFromJSON(json: any): SearchV3Explanation {
    return SearchV3ExplanationFromJSONTyped(json, false);
}

export function SearchV3ExplanationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchV3Explanation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'passingFilters': ((json['passing_filters'] as Array<any>).map(SearchV3ExplainedFilterFromJSON)),
        'failingFilters': ((json['failing_filters'] as Array<any>).map(SearchV3ExplainedFilterFromJSON)),
    };
}

export function SearchV3ExplanationToJSON(value?: SearchV3Explanation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'passing_filters': ((value.passingFilters as Array<any>).map(SearchV3ExplainedFilterToJSON)),
        'failing_filters': ((value.failingFilters as Array<any>).map(SearchV3ExplainedFilterToJSON)),
    };
}


