/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BeginCheckoutRequest
 */
export interface BeginCheckoutRequest {
    /**
     * 
     * @type {string}
     * @memberof BeginCheckoutRequest
     */
    successUrl: string;
    /**
     * 
     * @type {string}
     * @memberof BeginCheckoutRequest
     */
    cancelUrl: string;
}

export function BeginCheckoutRequestFromJSON(json: any): BeginCheckoutRequest {
    return BeginCheckoutRequestFromJSONTyped(json, false);
}

export function BeginCheckoutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BeginCheckoutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'successUrl': json['success_url'],
        'cancelUrl': json['cancel_url'],
    };
}

export function BeginCheckoutRequestToJSON(value?: BeginCheckoutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success_url': value.successUrl,
        'cancel_url': value.cancelUrl,
    };
}


