import { createSelector, Selector } from "reselect";

import { Campaign, UnsubscribeFooter } from "services/openapi";
import { initialState } from "views/admin/CampaignView/reducer";
import { RootState, CampaignViewState } from "views/admin/CampaignView/types";

const selectCampaignView = (state: RootState): CampaignViewState => state.campaignView || initialState;

export const makeSelectLoading = (): Selector<RootState, boolean> =>
  createSelector<RootState, CampaignViewState, boolean>(
    selectCampaignView,
    (state: CampaignViewState): boolean => state.loading
  );

export const makeSelectCampaign = (): Selector<RootState, Campaign | undefined> =>
  createSelector<RootState, CampaignViewState, Campaign | undefined>(
    selectCampaignView,
    (state: CampaignViewState): Campaign | undefined => state.campaign
  );

export const makeSelectUnsubscribeFootersLoading = (): Selector<RootState, boolean> =>
  createSelector<RootState, CampaignViewState, boolean>(
    selectCampaignView,
    (state: CampaignViewState): boolean => state.unsubscribeFootersLoading
  );

export const makeSelectUnsubscribeFooters = (): Selector<RootState, UnsubscribeFooter[] | undefined> =>
  createSelector<RootState, CampaignViewState, UnsubscribeFooter[] | undefined>(
    selectCampaignView,
    (state: CampaignViewState): UnsubscribeFooter[] | undefined => state.unsubscribeFooters
  );

export const makeSelectSaving = (): Selector<RootState, boolean> =>
  createSelector<RootState, CampaignViewState, boolean>(
    selectCampaignView,
    (state: CampaignViewState): boolean => state.saving
  );
