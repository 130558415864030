import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { LIST_TAG, USER_UPLOADED_FILE } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiListUserUploadedFilesRequest,
  ClientUserFileStats,
  CreateUserUploadedFileUploadedContextEnum,
  UserUploadedFile,
  UserUploadedFileUploadedContextEnum,
} from "services/openapi";
import { DjangoListResponseType } from "types";
import { showErrorToast } from "utils/showToast";

const userUploadedFileAdapter = createEntityAdapter<UserUploadedFile>();

const userUploadedFile = doverApi.injectEndpoints({
  endpoints: build => ({
    listUserUploadedFiles: build.query<EntityState<UserUploadedFile>, ApiApiListUserUploadedFilesRequest>({
      queryFn: async ({ uploadedContext, processingState, ordering, limit = 100, offset = 0 }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DjangoListResponseType<UserUploadedFile>;
        try {
          response = await client.listUserUploadedFiles({ limit, offset, ordering, processingState, uploadedContext });
        } catch (error) {
          const userFacingMessage = "Failed to load files. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return {
          data: userUploadedFileAdapter.addMany(userUploadedFileAdapter.getInitialState(), response.results),
        };
      },
      providesTags: result => {
        // is result available?
        return result
          ? // successful query
            [
              ...result.ids.map(id => ({ type: USER_UPLOADED_FILE, id } as const)),
              { type: USER_UPLOADED_FILE, id: LIST_TAG },
            ]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: USER_UPLOADED_FILE, id: LIST_TAG }];
      },
    }),
    getClientUserFileStats: build.query<
      Array<ClientUserFileStats>,
      { uploadedContext: UserUploadedFileUploadedContextEnum }
    >({
      queryFn: async ({ uploadedContext }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let clientUserFileStats: Array<ClientUserFileStats>;
        try {
          clientUserFileStats = await client.getClientUserFileStats({
            statsUploadedContext: uploadedContext as string,
          });
        } catch (error) {
          const userFacingMessage = "Failed to load file stats. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return {
          data: clientUserFileStats,
        };
      },
      providesTags: result => (result ? [{ type: USER_UPLOADED_FILE, id: LIST_TAG }] : []),
    }),
    createUserUploadedFile: build.mutation<
      UserUploadedFile,
      {
        uploadedContext: CreateUserUploadedFileUploadedContextEnum;
        fileContents: File;
      }
    >({
      queryFn: async ({ uploadedContext, fileContents }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.createUserUploadedFile({ uploadedContext, fileContents });
        } catch (error) {
          const userFacingMessage = "Failed to upload file 😢. Please try again.";
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: result => [
        ...(result ? [{ type: USER_UPLOADED_FILE, id: result.id } as const] : []),
        { type: USER_UPLOADED_FILE, id: LIST_TAG },
      ],
    }),
  }),
});

export const {
  useListUserUploadedFilesQuery,
  useGetClientUserFileStatsQuery,
  useCreateUserUploadedFileMutation,
} = userUploadedFile;
