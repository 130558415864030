import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import styled from "styled-components";

import { theme } from "components/data-driven-forms/styles/theme";

export const AutocompleteStyles = styled.div`
  width: 100%;

  .Mui-focused > .MuiFormControl-root > .MuiOutlinedInput-root > fieldset {
    border-color: ${theme.colors.highlightBlue};
  }

  .MuiAutocomplete-root > .MuiFormControl-root > .MuiOutlinedInput-root {
    ${({ hideLabel }) =>
      hideLabel &&
      `
        background-color: ${theme.backgrounds.blue};
    `}
  }

  .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-root {
    color: ${theme.colors.gray30};
    ${({ hideLabel }) =>
      hideLabel &&
      `
        display: none;
    `}
  }
  .MuiAutocomplete-option {
    background: blue;
  }

  .MuiAutocomplete-popper {
    background: blue;
  }
`;

export const CheckboxStyles = styled.div`
  .MuiCheckbox-root {
    margin-right: 8px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${theme.colors.highlightBlue};
  }
`;

export const CheckboxLabelStyles = styled.span`
  font-size: ${theme.inputs.default.fontSize};
  font-family: ${theme.inputs.default.fontFamily};
  font-weight: ${theme.inputs.default.fontWeight};
  line-height: ${theme.inputs.default.lineHeight};
  color: ${theme.colors.darkBlue};
`;

export const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  box-sizing: content-box;
  outline: 0;
  overflow: hidden;
  padding: 8px;
  color: ${theme.colors.darkGreen};
  border-color: ${theme.colors.darkGreen};
  background-color: white;
  font-size: ${theme.inputs.default.fontSize};
  font-family: ${theme.inputs.default.fontFamily};
  font-weight: ${theme.inputs.default.fontWeight};
  line-height: ${theme.inputs.default.lineHeight};

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    cursor: pointer;
    padding: 4px;
  }
`;

export const MaxOptionsText = styled.span`
  color: ${theme.colors.gray40};
`;
