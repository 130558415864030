import React from "react";

import StageSelector from "components/StageSelector/StageSelector";
import { DashboardJob, HiringPipelineStageType, JobStage } from "services/openapi";

interface Props {
  job: DashboardJob;
  jobStage?: JobStage;
  setPipelineStage: (stage?: JobStage) => void;
}

const PipelineStageSelect = ({ job, jobStage, setPipelineStage }: Props): React.ReactElement => {
  return (
    <StageSelector
      pipelineStageId={jobStage?.id}
      setStage={(pipelineStageId: string): void => {
        setPipelineStage(job.hiringPipelineStages?.find(hps => hps.id === pipelineStageId));
      }}
      stages={job.hiringPipelineStages?.map(
        (hps): JobStage => ({
          ...hps,
          // Hardcoded override for stage 400 (Client Review) to "Responded"
          // TODO: Just rename all these HPSs to Responded
          name: hps.stageType === HiringPipelineStageType.RESPONDED ? "Responded" : hps.name,
        })
      )}
    />
  );
};

export default PipelineStageSelect;
