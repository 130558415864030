import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";

import { getInvalidTagsFromEmailMessages } from "components/dover/CampaignEditor/utils";
import {
  CALENDLY_REGEX,
  CANDIDATE_FIRST_NAME_TEMPLATE_VAR_REGEX,
  EMAIL_SENDER_NAME_REGEX,
  MIN_TIMES_NAME_APPEARS,
  SAVVY_CAL_REGEX,
} from "components/outreach-configuration/form/constants";
import { CampaignEditorsType } from "components/outreach-configuration/form/types";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { useGetUserGmailAuthValidQuery } from "services/doverapi/endpoints/proUser";
import {
  DoverUser,
  EmailSenderAliasGmailCredentialAuthStateEnum,
  JobFeatureStateEnum,
  ListCampaign,
  ListCampaignEmailSenderOptionEnum,
  SlimEmailSenderAliasGmailCredentialAuthStateEnum,
} from "services/openapi";
import {
  useIsEligibleForCampaignOutreachGeneration,
  usePitchStepIsIrrelevantOrCompleted,
} from "views/job/JobSetup/hooks";

export function useShouldAttemptCampaignOutreachGeneration(
  jobId: string | undefined,
  dismissedJobPitchIncompleteModal: boolean,
  allowIncompleteJobPitch: boolean = false
): boolean {
  const everythingSetupAndEligible = useIsEligibleForCampaignOutreachGeneration(jobId, allowIncompleteJobPitch);
  const pitchStepIsIrrelevantOrComplete = usePitchStepIsIrrelevantOrCompleted(jobId);

  const isEligibleForCampaignOutreachGenerationButIncompleteJobPitch = useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(
    jobId
  );
  return useMemo<boolean>(() => {
    if (everythingSetupAndEligible && (allowIncompleteJobPitch || pitchStepIsIrrelevantOrComplete)) {
      return true;
    }
    return isEligibleForCampaignOutreachGenerationButIncompleteJobPitch && dismissedJobPitchIncompleteModal;
  }, [
    everythingSetupAndEligible,
    pitchStepIsIrrelevantOrComplete,
    isEligibleForCampaignOutreachGenerationButIncompleteJobPitch,
    dismissedJobPitchIncompleteModal,
    allowIncompleteJobPitch,
  ]);
}

function useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(jobId?: string): boolean {
  const pitchStepIsIrrelevantOrComplete = usePitchStepIsIrrelevantOrCompleted(jobId);
  const wouldOtherwiseAttemptCampaignOutreachGeneration = useIsEligibleForCampaignOutreachGeneration(jobId, true);

  return useMemo<boolean>(() => {
    return wouldOtherwiseAttemptCampaignOutreachGeneration && !pitchStepIsIrrelevantOrComplete;
  }, [wouldOtherwiseAttemptCampaignOutreachGeneration, pitchStepIsIrrelevantOrComplete]);
}

function useHasNotAutogeneratedCampaignContentBefore(campaigns: ListCampaign[] | undefined): boolean {
  return useMemo<boolean>(() => {
    return (
      !!campaigns &&
      campaigns.length === 1 &&
      // This checks that the only campaign has no campaign message content generated yet.
      (campaigns[0].threadMessages ?? []).every(message => !message.bodyTemplate)
    );
  }, [campaigns]);
}

export function useShouldShowIncompleteJobPitchModal(
  jobId: string | undefined,
  dismissedJobPitchIncompleteModal: boolean,
  campaigns: ListCampaign[] | undefined,
  isGeneratingCampaignContent: boolean
): boolean {
  const isEligibleForCampaignOutreachGenerationButIncompleteJobPitch = useIsEligibleForCampaignOutreachGenerationButIncompleteJobPitch(
    jobId
  );
  const hasNotAutogeneratedCampaignContentBefore = useHasNotAutogeneratedCampaignContentBefore(campaigns);
  return useMemo<boolean>(() => {
    if (dismissedJobPitchIncompleteModal || isGeneratingCampaignContent) {
      return false;
    }
    return isEligibleForCampaignOutreachGenerationButIncompleteJobPitch && hasNotAutogeneratedCampaignContentBefore;
  }, [
    isEligibleForCampaignOutreachGenerationButIncompleteJobPitch,
    dismissedJobPitchIncompleteModal,
    hasNotAutogeneratedCampaignContentBefore,
    isGeneratingCampaignContent,
  ]);
}

const useIsUsingShellAccount = (): boolean | undefined => {
  const [jobId] = useJobId();

  const isFreePlan = useIsFreeCustomer();
  const managedOutboundState = useGetManagedOutboundState({ jobId });

  if (!jobId) {
    return undefined;
  }

  return !isFreePlan && managedOutboundState === JobFeatureStateEnum.Enabled;
};

interface UseIsCampaignEmailSenderAuthedProps {
  userDefinedSenderUser: number | undefined;
  emailSenderOption: ListCampaignEmailSenderOptionEnum | undefined;
  emailAliasEmail: string | undefined;
  emailAliasGmailCredentialAuthState:
    | EmailSenderAliasGmailCredentialAuthStateEnum
    | SlimEmailSenderAliasGmailCredentialAuthStateEnum
    | undefined;
}

export const useIsCampaignEmailSenderAuthed = ({
  userDefinedSenderUser,
  emailSenderOption,
  emailAliasEmail,
  emailAliasGmailCredentialAuthState,
}: UseIsCampaignEmailSenderAuthedProps): {
  isAuthed: boolean;
  isLoading: boolean;
} => {
  const isUsingShellAccount = useIsUsingShellAccount();

  const skip =
    !userDefinedSenderUser ||
    emailSenderOption === ListCampaignEmailSenderOptionEnum.VirtualRecruiter ||
    isUsingShellAccount;

  const { data: gmailAuthStatus, isLoading: isGmailAuthLoading } = useGetUserGmailAuthValidQuery(
    skip ? skipToken : { userId: userDefinedSenderUser ? userDefinedSenderUser.toString() : undefined }
  );

  return useMemo(() => {
    // If using a Virtual Recruiter, we don't need to check for Gmail auth status
    if (emailSenderOption === ListCampaignEmailSenderOptionEnum.VirtualRecruiter || isUsingShellAccount) {
      return { isAuthed: true, isLoading: false };
    }

    return {
      isAuthed:
        gmailAuthStatus?.valid === true &&
        !!emailAliasEmail &&
        !!emailAliasGmailCredentialAuthState &&
        [
          SlimEmailSenderAliasGmailCredentialAuthStateEnum.Valid,
          SlimEmailSenderAliasGmailCredentialAuthStateEnum.IsShellAccount,
          EmailSenderAliasGmailCredentialAuthStateEnum.Valid,
          EmailSenderAliasGmailCredentialAuthStateEnum.IsShellAccount,
        ].includes(emailAliasGmailCredentialAuthState),
      isLoading: isGmailAuthLoading,
    };
  }, [
    emailSenderOption,
    isUsingShellAccount,
    gmailAuthStatus?.valid,
    emailAliasEmail,
    emailAliasGmailCredentialAuthState,
    isGmailAuthLoading,
  ]);
};

interface UseValidateCampaignMessagesReturn {
  errorMessage: string | undefined;
}

export const useValidateCampaignMessages = ({
  campaignMessages,
  senderUser,
}: {
  campaignMessages: CampaignEditorsType | undefined;
  senderUser: DoverUser | undefined;
}): UseValidateCampaignMessagesReturn => {
  const emailMessages = useMemo((): string | undefined => {
    if (!campaignMessages || campaignMessages.length === 0) {
      return undefined;
    }

    return campaignMessages.reduce((acc, curr) => {
      return acc + (curr.rawEditorState as any).bodyHtml ?? "";
    }, "");
  }, [campaignMessages]);

  // Check for Calendly and SavvyCal links (not allowed)
  const passingCalendlyQa = !emailMessages?.match(CALENDLY_REGEX);

  const passingSavvyQa = !emailMessages?.match(SAVVY_CAL_REGEX);

  // If we know the sender's first name, we want to make sure it appears at least once in the initial email
  const passingNameQa = useMemo((): boolean => {
    const initialMessageBody = campaignMessages?.[0]?.rawEditorState?.bodyHtml;
    let nameMatchesCount = 0;
    if (senderUser?.firstName) {
      const regexName = new RegExp(senderUser.firstName, "g");
      nameMatchesCount = (initialMessageBody?.match(regexName) || []).length;
    }

    const emailSenderNameRegexMatchesCount = (initialMessageBody?.match(EMAIL_SENDER_NAME_REGEX) || []).length;
    const count = nameMatchesCount + emailSenderNameRegexMatchesCount;

    return count >= MIN_TIMES_NAME_APPEARS;
  }, [campaignMessages, senderUser?.firstName]);

  const invalidTags = getInvalidTagsFromEmailMessages(emailMessages);

  // Check for candidate's first name template variable
  const passingCandidateFirstNameTempVar = useMemo(() => {
    if (
      !campaignMessages?.[0] ||
      !(campaignMessages?.[0]?.rawEditorState && "bodyHtml" in campaignMessages[0].rawEditorState)
    ) {
      return false;
    }
    return (campaignMessages[0].rawEditorState as any).bodyHtml?.match(CANDIDATE_FIRST_NAME_TEMPLATE_VAR_REGEX);
  }, [campaignMessages]);

  const errorMessage = useMemo((): string | undefined => {
    if (invalidTags.length > 0) {
      return `Please replace the following with Dover-supported
            variables or remove them: ${invalidTags.join(", ")}`;
    }
    if (!passingNameQa) {
      if (senderUser?.firstName) {
        return `Outreach is set to come from ${senderUser?.firstName} but there is no mention of this name in
      the first message. Please add a mention of ${senderUser?.firstName}.`;
      }
      return `There is no outreach variable set for the email sender. Please add {{EMAIL_SENDER_NAME}} to your outreach content.`;
    }

    if (!passingCalendlyQa || !passingSavvyQa) {
      return `Please remove the ${
        !passingCalendlyQa ? "Calendly" : "SavvyCal"
      } link in your outreach content. We’re unable to support having scheduling links in outreach messages as it disrupts the automated flow we have in place.`;
    }

    if (!passingCandidateFirstNameTempVar) {
      return `Looks like you haven't included a variable for the candidate's first name. To move forward, please include {{FIRST_NAME}} in the body of the initial outreach.`;
    }
    return undefined;
  }, [
    invalidTags,
    passingCalendlyQa,
    passingNameQa,
    passingSavvyQa,
    senderUser?.firstName,
    passingCandidateFirstNameTempVar,
  ]);

  return {
    errorMessage,
  };
};
