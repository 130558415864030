/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultipartSchedulingClientInterviewerAvailableTime
 */
export interface MultipartSchedulingClientInterviewerAvailableTime {
    /**
     * 
     * @type {number}
     * @memberof MultipartSchedulingClientInterviewerAvailableTime
     */
    day: number;
    /**
     * 
     * @type {number}
     * @memberof MultipartSchedulingClientInterviewerAvailableTime
     */
    startHour: number;
    /**
     * 
     * @type {number}
     * @memberof MultipartSchedulingClientInterviewerAvailableTime
     */
    startMinute: number;
    /**
     * 
     * @type {number}
     * @memberof MultipartSchedulingClientInterviewerAvailableTime
     */
    endHour: number;
    /**
     * 
     * @type {number}
     * @memberof MultipartSchedulingClientInterviewerAvailableTime
     */
    endMinute: number;
}

export function MultipartSchedulingClientInterviewerAvailableTimeFromJSON(json: any): MultipartSchedulingClientInterviewerAvailableTime {
    return MultipartSchedulingClientInterviewerAvailableTimeFromJSONTyped(json, false);
}

export function MultipartSchedulingClientInterviewerAvailableTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipartSchedulingClientInterviewerAvailableTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'day': json['day'],
        'startHour': json['start_hour'],
        'startMinute': json['start_minute'],
        'endHour': json['end_hour'],
        'endMinute': json['end_minute'],
    };
}

export function MultipartSchedulingClientInterviewerAvailableTimeToJSON(value?: MultipartSchedulingClientInterviewerAvailableTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day': value.day,
        'start_hour': value.startHour,
        'start_minute': value.startMinute,
        'end_hour': value.endHour,
        'end_minute': value.endMinute,
    };
}


