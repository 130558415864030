import { Box, RadioGroup, Stack, FormControl } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { AddNewProUserModal } from "components/dover/Campaigns/AddNewProUserModal";
import { StyledFormControlLabel, StyledRadio } from "components/dover/Campaigns/styles";
import { ModalKeyType } from "components/dover/Campaigns/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, Subtitle2 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { StyledTextField } from "components/StyledMuiComponents";
import {
  selectFromListCampaignsQueryResult,
  useCreateCampaignMutation,
  useGenerateAndSaveCampaignContentMutation,
  useListCampaignsQuery,
} from "services/doverapi/endpoints/campaign";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { CreateCampaignEmailSenderOptionEnum, DoverUser } from "services/openapi";
import { colors } from "styles/theme";
import ProUserSelect from "views/job/JobSetup/steps/CampaignVariants/modals/components/ProUserSelect";

const CreateCampaignForm: React.FC<{ setNewCampaignId: (newCampaignId: string) => void }> = ({ setNewCampaignId }) => {
  const { jobId } = useParams<{ jobId: string }>();
  const { proUsers } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsers,
        };
      },
    }
  );
  const [createCampaign, { isLoading: isCreatingCampaign }] = useCreateCampaignMutation();
  const [generateAndSaveCampaignContent] = useGenerateAndSaveCampaignContentMutation();

  const { allCampaigns: campaigns } = useListCampaignsQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
  });

  const isFreePlan = useIsFreeCustomer();

  const [submitting, setSubmitting] = useState(false);
  const [campaignName, setCampaignName] = useState<string | undefined>();
  const [campaignNameError, setCampaignNameError] = useState<boolean>(false);
  const [selectedEmailSenderOption, setSelectedEmailSenderOption] = useState<
    CreateCampaignEmailSenderOptionEnum | undefined
  >();
  const [selectedOtherUser, setSelectedOtherUser] = useState<number | undefined>();
  // This is using our legacy modal system
  const [openModal, setOpenModal] = useState<ModalKeyType>(ModalKeyType.None);

  const getUserDefinedSenderUser = (): number | undefined => {
    return selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser ? selectedOtherUser : undefined;
  };

  const handleCampaignNameChange = (value: any): void => {
    if ((campaigns ?? []).map(campaign => campaign.name).includes(value)) {
      setCampaignNameError(true);
    } else {
      setCampaignNameError(false);
    }
    setCampaignName(value);
  };

  const handleAddNewCampaign = async (): Promise<void> => {
    if (!jobId) {
      return;
    }

    try {
      const newCampaign = await createCampaign({
        jobId,
        name: campaignName ?? `Variant ${campaigns ? (campaigns.length + 1).toString() : "1"}`,
        emailSenderOption: selectedEmailSenderOption,
        userDefinedSenderUser: getUserDefinedSenderUser(),
        campaignToClone: undefined,
      }).unwrap();
      if (newCampaign.id) {
        await generateAndSaveCampaignContent({
          campaignId: newCampaign.id,
        });
        setNewCampaignId(newCampaign.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AddNewProUserModal
        shouldShowModal={openModal}
        handleCloseModal={(): void => {
          setOpenModal(ModalKeyType.None);
        }}
        handleOpenUpdateOrCreateCampaignModal={(): void => {
          setOpenModal(ModalKeyType.UpdateOrCreateCampaign);
        }}
        modalKey={ModalKeyType.AddNewProUser}
        setSelectedOtherUser={setSelectedOtherUser}
      />
      <Stack p={2}>
        {submitting ? (
          <DoverLoadingSpinner />
        ) : (
          <Stack spacing={2}>
            <Body weight="500">Campaign Name</Body>
            <StyledTextField
              fullWidth
              value={campaignName}
              onChange={(e): void => handleCampaignNameChange(e.target.value)}
              error={campaignNameError}
              helperText={campaignNameError && "Cannot have the same name as another campaign."}
            />
            <Stack spacing={2}>
              <Subtitle2>Who do you prefer outreach come from?</Subtitle2>
              <FormControl component="fieldset" variant="standard">
                <RadioGroup
                  name="selectEmailSender"
                  value={selectedEmailSenderOption}
                  onChange={(event): void =>
                    setSelectedEmailSenderOption(event.target.value as CreateCampaignEmailSenderOptionEnum)
                  }
                >
                  <StyledFormControlLabel
                    value={CreateCampaignEmailSenderOptionEnum.HiringManager}
                    control={<StyledRadio />}
                    label={<Body style={{ marginLeft: "4px" }}>Hiring manager</Body>}
                  />
                  <Box ml={2}>
                    <BodySmall color={colors.grayscale.gray600}>
                      Candidates typically prefer emails directly from a hiring manager, which leads to a higher
                      interest rate.
                    </BodySmall>
                  </Box>
                  {!isFreePlan && (
                    <>
                      <StyledFormControlLabel
                        value={CreateCampaignEmailSenderOptionEnum.VirtualRecruiter}
                        control={<StyledRadio />}
                        label={<Body style={{ marginLeft: "4px" }}>Virtual Recruiter</Body>}
                        sx={{ marginBottom: 0 }}
                      />
                      <Box ml={2}>
                        <BodySmall color={colors.grayscale.gray600}>
                          This may result in 20% fewer interested candidates. Dover can set up outreach to send from a
                          “recruiter” email.
                        </BodySmall>
                      </Box>
                    </>
                  )}
                  <StyledFormControlLabel
                    value={CreateCampaignEmailSenderOptionEnum.OtherUser}
                    control={<StyledRadio />}
                    label={<Body style={{ marginLeft: "4px" }}>Someone else on my team</Body>}
                  />
                </RadioGroup>
              </FormControl>
              {selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser && proUsers && (
                <ProUserSelect
                  initialProUser={selectedOtherUser}
                  proUsers={proUsers}
                  setSelectedProUser={setSelectedOtherUser}
                  handleOpenProUserModal={(): void => {
                    setOpenModal(ModalKeyType.AddNewProUser);
                  }}
                />
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        width="inherit"
        position="fixed"
        bottom={0}
        p={1}
        sx={{
          zIndex: 50,
          backgroundColor: colors.white,
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          width="fit-content"
          variant={ButtonVariant.Primary}
          loading={!jobId || isCreatingCampaign}
          disabled={
            submitting ||
            !selectedEmailSenderOption ||
            !campaignName ||
            campaignNameError ||
            (selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser && !selectedOtherUser)
          }
          onClick={async (): Promise<void> => {
            setSubmitting(true);
            handleAddNewCampaign();
            setSubmitting(false);
          }}
        >
          Create
        </Button>
      </Stack>
    </>
  );
};

export default CreateCampaignForm;
