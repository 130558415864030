/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    userSlackId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    userEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2003
     */
    userGroup: string;
}

export function InlineResponse2003FromJSON(json: any): InlineResponse2003 {
    return InlineResponse2003FromJSONTyped(json, false);
}

export function InlineResponse2003FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2003 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userName': json['user_name'],
        'userSlackId': json['user_slack_id'],
        'userEmail': json['user_email'],
        'userGroup': json['user_group'],
    };
}

export function InlineResponse2003ToJSON(value?: InlineResponse2003 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.userName,
        'user_slack_id': value.userSlackId,
        'user_email': value.userEmail,
        'user_group': value.userGroup,
    };
}


