import { Box, Divider, Stack } from "@mui/material";
import React, { FC } from "react";

import { HiringPipelineStage, InterviewPlanInterviewSubstage } from "services/openapi";
import { colors } from "styles/theme";
import { calcMultipartDuration } from "utils/calcMultipartDuration";
import { EditStage } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStage";
import {
  StageDuration,
  StageInterviewers,
  StageName,
  SubstageName,
} from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/StageParts";

const MultipartContainer: FC = ({ children }) => {
  return (
    <Stack spacing={2.5} width="100%">
      {children}
    </Stack>
  );
};

const MultipartRowsContainer: FC = ({ children }) => {
  return (
    <Stack
      spacing={0.5}
      divider={
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ borderColor: colors.grayscale.gray200, marginX: "-16px !important" }}
        />
      }
    >
      {children}
    </Stack>
  );
};

interface MultipartHeaderProps {
  stage: HiringPipelineStage;
}

const MultipartHeader: FC<MultipartHeaderProps> = ({ stage }) => {
  const duration = calcMultipartDuration(stage);

  // We use relative / absolute positioning so we can place the edit button in
  // the top right while also keeping the flex layout consistent between
  // the header row and the substage rows
  return (
    <Box position="relative">
      <Stack direction="row">
        <Box flex={1}>
          <StageName name={stage.name} />
        </Box>
        {/* add an empty flexbox here so the stage duration matches up with the one from the substagerow below */}
        <Box flex={1}></Box>
        <Box flex={1}>
          <StageDuration duration={duration} />
        </Box>
      </Stack>
      <Box position="absolute" top="-4px" right="0px">
        <EditStage stage={stage} />
      </Box>
    </Box>
  );
};

interface MultipartSubstageRowProps {
  substage: InterviewPlanInterviewSubstage;
}

const MultipartSubstageRow: FC<MultipartSubstageRowProps> = ({ substage }) => {
  return (
    <Stack direction="row">
      <Box flex={1}>
        <SubstageName name={substage.name} />
      </Box>
      <Box flex={1}>
        {/* @ts-ignore TODO: api typing is currently broken */}
        <StageInterviewers interviewers={substage.possibleInterviewers} />
      </Box>
      <Box flex={1}>
        <StageDuration duration={substage.duration} />
      </Box>
    </Stack>
  );
};

interface MultipartRowProps {
  stage: HiringPipelineStage;
}

// Composes the multipart stage row
export const MultipartRow: FC<MultipartRowProps> = ({ stage }) => {
  return (
    <MultipartContainer>
      <MultipartHeader stage={stage} />
      <MultipartRowsContainer>
        {stage?.multipartInterviewStage?.substages?.map(substage => (
          <MultipartSubstageRow substage={substage} />
        ))}
      </MultipartRowsContainer>
    </MultipartContainer>
  );
};
