import FormTemplate from "@data-driven-forms/mui-component-mapper/form-template";
import { FormRenderer } from "@data-driven-forms/react-form-renderer";
import PropTypes from "prop-types";
import React from "react";

import defaultComponentMapper from "components/data-driven-forms/component-mapper";
import { colors } from "styles/theme";

const DefaultFormTemplate = props => (
  <FormTemplate {...props} buttonsProps={{ submit: { style: { background: colors.primary.base } } }} />
);

export const NoSubmitButtonFormTemplate = props => (
  <FormTemplate
    {...props}
    formWrapperProps={{ GridProps: { spacing: 0, container: true }, GridContainerProps: { spacing: 0 } }}
    showFormControls={false}
  />
);

// NOTE(jh): I failed to get any of these imports to work / compile in
// typescript so here's a shallow JS wrapper around the code. I feel like the
// might have a bug in their MUI stuff.
const DataDrivenFormRenderer = ({
  schema,
  onSubmit,
  debug,
  initialValues,
  CustomFormTemplate = DefaultFormTemplate,
  componentMapper,
}) => {
  return (
    <FormRenderer
      schema={schema}
      componentMapper={componentMapper ?? defaultComponentMapper}
      FormTemplate={CustomFormTemplate}
      onSubmit={onSubmit}
      debug={debug}
      initialValues={initialValues}
    />
  );
};

DataDrivenFormRenderer.propTypes = {
  schema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  debug: PropTypes.func,
  initialValues: PropTypes.object,
  componentMapper: PropTypes.object,
};

export default DataDrivenFormRenderer;
