export enum EmailsPerWeekOption {
  Passive = "Passive",
  Standard = "Standard",
  Boosted = "Boosted",
}

export const EMAILS_PER_WEEK_CONFIGS: Readonly<{ [emailsPerWeekOption in EmailsPerWeekOption]: any }> = {
  [EmailsPerWeekOption.Passive]: {
    label: "Passive",
    expectedNewCandidatesCount: 100,
    credits: 0.5,
  },
  [EmailsPerWeekOption.Standard]: {
    label: "Standard",
    expectedNewCandidatesCount: 150,
    credits: 1,
  },
  [EmailsPerWeekOption.Boosted]: {
    label: "Boosted",
    expectedNewCandidatesCount: 200,
    credits: 1.5,
  },
};

export const INTEREST_RATE_THRESHOLD_FOR_BOOSTED_DOVER_OUTBOUND = 0.025;

export const WEEKS_IN_MONTH = 4.28;

export const MS_IN_DAY = 24 * 60 * 60 * 1000;

export const DEFAULT_CANDIDATE_EMAIL_INTEREST_RATE = 0.042;

export const MAX_NUMBER_OF_EMAILS_PER_WEEK = 200;

export const EMAIL_VOLUME_TAB_QUERY_PARAM_VALUE = "Email Volume";
export const PASSIVE_CANDIDATES_MEETINGS_TAB_QUERY_PARAM_VALUE = "Passive Candidates";
export const SOURCING_AUTOPILOT_TAB_QUERY_PARAM_VALUE = "Sourcing Autopilot";
export const SIMILAR_CANDIDATES_TAB_QUERY_PARAM_VALUE = "Similar Candidates";
export const CALIBRATION_SETTINGS_TAB_QUERY_PARAM_VALUE = "Calibration-Settings";

export enum SourcingCalibrationSettingOptions {
  AutoAdjust = "AutoAdjust",
  NotifyMe = "NotifyMe",
  EmbeddedRecruiter = "EmbeddedRecruiter",
}

export const CalibrationSettingLabels: Readonly<
  { [calibrationSettingOption in SourcingCalibrationSettingOptions]: string }
> = {
  [SourcingCalibrationSettingOptions.AutoAdjust]: "Auto Adjust",
  [SourcingCalibrationSettingOptions.NotifyMe]: "Notify Me",
  [SourcingCalibrationSettingOptions.EmbeddedRecruiter]: "Recruiting Partner",
};

export const DEFAULT_EMBEDDED_RECRUITER_PICTURE_URL =
  "https://lh3.googleusercontent.com/a/ACg8ocLBHUcJYPgcnnlkUSlGQvvPOyU0I41EM3j-n-SSci5R-A=s96-c";
