import React, { ReactElement } from "react";
import styled from "styled-components";

import { Overline } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { colors as themeColors } from "styles/theme";

export const NextActionCardSkeleton = ({
  title,
  titleEnd,
  description,
  fullWidthDescription,
  buttons,
  buttonsTooltip,
  colors,
}: {
  title: string;
  titleEnd?: ReactElement;
  description?: ReactElement;
  fullWidthDescription?: ReactElement;
  buttons?: ReactElement;
  buttonsTooltip?: ReactElement;
  colors: { backgroundColor: string; borderColor: string; headerTextColor: string };
}): ReactElement => {
  return (
    <NextActionContainer
      backgroundColor={colors.backgroundColor}
      borderColor={colors.borderColor}
      id="next-action-card-container"
    >
      <NextActionHeader color={colors.headerTextColor} borderColor={colors.borderColor}>
        <Overline style={{ display: "flex", alignItems: "center" }}>
          {title.toUpperCase()}
          {titleEnd}
        </Overline>
      </NextActionHeader>
      <NextActionRow>
        {description && (
          <>
            {description}
            <Spacer width={8} />
          </>
        )}
        {buttonsTooltip && (
          <>
            {buttonsTooltip}
            <Spacer width={8} />
          </>
        )}

        {buttons}
      </NextActionRow>
      {fullWidthDescription}
    </NextActionContainer>
  );
};

interface NextActionContainerProps {
  borderColor: string;
  backgroundColor: string;
}

interface NextActionHeaderProps {
  borderColor: string;
  color?: string;
}

const NextActionContainer = styled.div<NextActionContainerProps>`
  background-color: ${(props): string => props.backgroundColor};
  border: 1px solid ${(props): string => props.borderColor};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const NextActionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
`;
const NextActionHeader = styled.div<NextActionHeaderProps>`
  padding: 6px 16px;
  color: ${(props): string => props.color ?? themeColors.black};
  border-bottom: 1px solid ${(props): string => props.borderColor};
`;
