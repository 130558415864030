import { Box, Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { ReactElement } from "react";

import { Body, BodyExtraSmall, BodySmall, Overline } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { useModal } from "GlobalOverlays/atoms";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetCandidateCountsQuery } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { CandidateFilterSourcingContextEnum, CandidateFilterStatusEnum } from "services/openapi";
import { colors } from "styles/theme";
import { useListJobReferrersQuery } from "views/referralsV2/endpoints";
import { referredCandidatesModalAtom } from "views/referralsV2/ReferredCandidatesTable";
import { ReferralsTableWrapper } from "views/referralsV2/styles";

const LabeledCount = ({ label, count }: { label: string; count?: number | string }): ReactElement => {
  return (
    <Stack alignItems="flex-start">
      <Body weight="500" color={count === 0 || count === "--" ? colors.grayscale.gray500 : colors.black}>
        {count}
      </Body>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Box width="80px">
          <BodyExtraSmall weight="500" color={colors.grayscale.gray500}>
            {label}
          </BodyExtraSmall>
        </Box>
      </Stack>
    </Stack>
  );
};

export enum ReferralStatusType {
  SUBMITTED = "submitted",
  ACTIVE = "active",
  HIRES = "hires",
}

export type ReferralCandidateCounts = {
  submitted: number;
  active: number;
  hires: number;
};

interface Referrer {
  id?: string;
  name?: string;
  email: string;
  counts: ReferralCandidateCounts;
}

const ReferrerInfoCell = (params: GridRenderCellParams): React.ReactElement => {
  const referrer = params.row as Referrer;
  const counts = referrer.counts;

  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems="center"
      justifyContent={"space-between"}
      width="100%"
      sx={{
        cursor: counts.submitted > 0 ? "pointer" : undefined,
      }}
    >
      <Stack spacing={0}>
        {referrer.name && referrer.email ? (
          <>
            <BodySmall weight="600">{referrer.name}</BodySmall>
            <BodySmall weight="400">{referrer.email}</BodySmall>
          </>
        ) : (
          <BodySmall weight="600">{referrer.email}</BodySmall>
        )}
      </Stack>
      <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-end"}>
        <Stack direction="row" spacing={3}>
          <LabeledCount label={"SUBMITTED"} count={counts.submitted} />
          <LabeledCount label={"ACTIVE"} count={counts.active} />
          <LabeledCount label={"HIRES"} count={counts.hires} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const COLUMN_DEF: GridEnrichedColDef[] = [
  {
    field: "referrer",
    headerName: "Referrers",
    flex: 1,
    width: 650,
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <ReferrerInfoCell {...params} />,
  },
];

export const ReferrersTable = (): React.ReactElement => {
  const [page, setPage] = React.useState(0);

  const jobId = useJobIdFromUrl();
  const { data, isFetching } = useListJobReferrersQuery(
    jobId ? { jobId, isAgencyRecruiter: false, offset: page * 10, limit: 10 } : skipToken
  );

  const { data: counts, isFetching: isFetchingCandidates } = useGetCandidateCountsQuery(
    !jobId || isFetching
      ? skipToken
      : {
          args: {
            jobId,
            data: {
              countFilters: [
                ...(data?.results.map(r => {
                  return {
                    name: `${ReferralStatusType.SUBMITTED}|${r.referrerId}`,
                    filters: {
                      sourcingContext: [CandidateFilterSourcingContextEnum.Referral],
                      referrer: r.referrerId,
                    },
                  };
                }) || []),
                ...(data?.results.map(r => {
                  return {
                    name: `${ReferralStatusType.ACTIVE}|${r.referrerId}`,
                    filters: {
                      status: [CandidateFilterStatusEnum.Active],
                      sourcingContext: [CandidateFilterSourcingContextEnum.Referral],
                      referrer: r.referrerId,
                    },
                  };
                }) || []),
                ...(data?.results.map(r => {
                  return {
                    name: `${ReferralStatusType.HIRES}|${r.referrerId}`,
                    filters: {
                      status: [CandidateFilterStatusEnum.Hired],
                      sourcingContext: [CandidateFilterSourcingContextEnum.Referral],
                      referrer: r.referrerId,
                    },
                  };
                }) || []),
              ],
            },
          },
        }
  );

  const countsByReferrer = counts?.reduce((acc, count) => {
    const [type, referrerId] = count.name.split("|");

    if (referrerId) {
      acc[referrerId] = acc[referrerId] || { submitted: 0, active: 0, hires: 0 };
      if (type === ReferralStatusType.SUBMITTED) {
        acc[referrerId].submitted = count.count;
      } else if (type === ReferralStatusType.ACTIVE) {
        acc[referrerId].active = count.count;
      } else if (type === ReferralStatusType.HIRES) {
        acc[referrerId].hires = count.count;
      }
    }
    return acc;
  }, {} as { [key: string]: ReferralCandidateCounts });

  const rows: Referrer[] = (
    data?.results?.map(referrer => {
      return {
        id: referrer.referrerId,
        email: referrer.email,
        name: referrer.name,
        counts: countsByReferrer?.[referrer.referrerId ?? ""] ?? { submitted: 0, active: 0, hires: 0 },
      };
    }) || []
  ).sort((a, b) => b.counts.submitted - a.counts.submitted);

  const { open } = useModal(referredCandidatesModalAtom);

  if (isFetching || isFetchingCandidates) {
    return <DoverLoadingOverlay active />;
  }

  return (
    <ReferralsTableWrapper>
      <DataGrid
        autoHeight
        headerHeight={40}
        rowHeight={64}
        rows={rows}
        rowCount={data?.count || 0}
        hideFooter={!!((data?.count || 0) <= 10)}
        columns={COLUMN_DEF}
        pagination
        paginationMode="server"
        page={page}
        pageSize={10}
        onPageChange={setPage}
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        localeText={{ noRowsLabel: "Invite coworkers, investors, and friends to refer candidates" }}
        onRowClick={(params: GridRowParams): void => {
          const referrer = params.row as Referrer;
          open({
            displayValue: referrer.name ?? referrer.email,
            referrerId: referrer.id ?? "",
          });
        }}
      />
    </ReferralsTableWrapper>
  );
};
