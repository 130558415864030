import { Box, Container, Paper } from "@mui/material";
import React from "react";

import Accordion from "components/accordion";
import LinkedInConnectionsUpload from "components/dover/LinkedInConnectionsUpload";
import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import { uploadConnectionsFAQs } from "views/Referrals/ReviewReferrals/constants";
import Success from "views/Referrals/UploadConnections/components/Success";

const UploadConnections = (): React.ReactElement => {
  const [successfullyUploadedConnections, setSuccessfullyUploadedConnections] = React.useState<boolean>(false);

  return (
    <>
      <PageHelmet title="Upload Connections" />

      <Container maxWidth={"md"} style={{ marginTop: "40px" }}>
        <Paper elevation={1} style={{ padding: "30px 40px" }}>
          {!successfullyUploadedConnections && (
            <LinkedInConnectionsUpload
              headerText={"You’ve been invited to upload your connections"}
              onSuccess={(): void => setSuccessfullyUploadedConnections(true)}
            >
              <Box maxWidth="560px">
                <Spacer height="32px" />
                <Accordion
                  title={"What does Dover do with this data?"}
                  text={
                    <>
                      {`Dover will find potential good fits in your team's network for the roles your company is hiring for and continuously surface them for review. For additional details, see `}
                      <a href={uploadConnectionsFAQs} target="_blank" rel="noopener noreferrer">
                        here
                      </a>
                      {`.`}
                    </>
                  }
                />
                <Spacer height="32px" />
              </Box>
            </LinkedInConnectionsUpload>
          )}
          {successfullyUploadedConnections && <Success />}
        </Paper>
      </Container>
    </>
  );
};

export default UploadConnections;
