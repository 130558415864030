import { Stack } from "@mui/material";
import styled from "styled-components";

interface GradientBackgroundProps {
  angle?: string;
  firstColor: string;
  firstColorPercent?: string;
  secondColor: string;
  secondColorPercent?: string;
}

export const GradientBackground = styled(Stack)<GradientBackgroundProps>`
  background: linear-gradient(
    ${(props): string => props.angle ?? "90"}deg,
    ${(props): string => props.firstColor} ${(props): string => props.firstColorPercent ?? "0"}%,
    ${(props): string => props.secondColor} ${(props): string => props.secondColorPercent ?? "100"}%
  );
`;
