/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeLogIndividualUpdate
 */
export interface ChangeLogIndividualUpdate {
    /**
     * 
     * @type {string}
     * @memberof ChangeLogIndividualUpdate
     */
    family: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeLogIndividualUpdate
     */
    changes: Array<string>;
}

export function ChangeLogIndividualUpdateFromJSON(json: any): ChangeLogIndividualUpdate {
    return ChangeLogIndividualUpdateFromJSONTyped(json, false);
}

export function ChangeLogIndividualUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeLogIndividualUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'family': json['family'],
        'changes': json['changes'],
    };
}

export function ChangeLogIndividualUpdateToJSON(value?: ChangeLogIndividualUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'family': value.family,
        'changes': value.changes,
    };
}


