/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoverOutboundPersonaStats
 */
export interface DoverOutboundPersonaStats {
    /**
     * 
     * @type {number}
     * @memberof DoverOutboundPersonaStats
     */
    targetMinEmailsPerWeek: number;
    /**
     * This field stores the number of interested candidates for a month for 90 percent of jobs that have the same persona
     * @type {number}
     * @memberof DoverOutboundPersonaStats
     */
    numInterested10PercentileJob?: number | null;
    /**
     * This field stores the number of interested candidates for a month for 50 percent of jobs that have the same persona
     * @type {number}
     * @memberof DoverOutboundPersonaStats
     */
    numInterested50PercentileJob?: number | null;
}

export function DoverOutboundPersonaStatsFromJSON(json: any): DoverOutboundPersonaStats {
    return DoverOutboundPersonaStatsFromJSONTyped(json, false);
}

export function DoverOutboundPersonaStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoverOutboundPersonaStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetMinEmailsPerWeek': json['target_min_emails_per_week'],
        'numInterested10PercentileJob': !exists(json, 'num_interested_10_percentile_job') ? undefined : json['num_interested_10_percentile_job'],
        'numInterested50PercentileJob': !exists(json, 'num_interested_50_percentile_job') ? undefined : json['num_interested_50_percentile_job'],
    };
}

export function DoverOutboundPersonaStatsToJSON(value?: DoverOutboundPersonaStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target_min_emails_per_week': value.targetMinEmailsPerWeek,
        'num_interested_10_percentile_job': value.numInterested10PercentileJob,
        'num_interested_50_percentile_job': value.numInterested50PercentileJob,
    };
}


