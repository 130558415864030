import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";
import React from "react";
import { useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Button, ButtonVariant } from "components/library/Button";
import { Subtitle1, Subtitle2 } from "components/library/typography";
import HorizontalLoader from "sections/addcandidate/components/HorizontalLoader";
import { OUTREACH_TYPE_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { OutreachTypes, OutreachTypeParam } from "sections/addcandidate/types";
import { colors } from "styles/theme";

const FlowComplete = (): React.ReactElement => {
  const { candidateId, handleResetSteps, isSubmitting } = useAddCandidateContext();
  const [outreachType] = useQueryParam(OUTREACH_TYPE_QUERY_PARAM, OutreachTypeParam);

  if (isSubmitting) {
    return <HorizontalLoader />;
  }

  const viewCandidate = (): void => {
    const navigateTo = APP_ROUTE_PATHS.candidates.candidateDetail(candidateId!);
    window.open(navigateTo, "_blank")?.focus();
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%", width: "100%" }}
    >
      <CheckCircleIcon sx={{ color: colors.primary.base, fontSize: "36px" }} />
      <Subtitle1>{outreachType === OutreachTypes.BULK ? "Candidates" : "Candidate"} added</Subtitle1>
      <Button variant={ButtonVariant.Ghost} onClick={viewCandidate}>
        <Subtitle2 color={colors.primary.base}>View Candidate</Subtitle2>
      </Button>
      <Button variant={ButtonVariant.Ghost} disabled={isSubmitting} onClick={handleResetSteps}>
        <Subtitle2 color={colors.grayscale.gray400}>
          Add {outreachType === OutreachTypes.BULK ? "more candidates" : "another candidate"}
        </Subtitle2>
      </Button>
    </Stack>
  );
};

export default FlowComplete;
