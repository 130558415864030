import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { Overline } from "components/library/typography";
import { useCandidateId } from "hooks/useCandidateId";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { CandidateBioSourceDirectionEnum } from "services/openapi";
import { colors } from "styles/theme";

export const EventBadge = (): ReactElement => {
  const candidateId = useCandidateId();
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  return (
    <BadgeContainer>
      <Overline color={colors.warning.dark}>
        {candidateBio?.searchSource?.direction === CandidateBioSourceDirectionEnum.Inbound
          ? "Review the candidate's application"
          : "Needs decision"}
      </Overline>
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  background: ${colors.warning.light};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 5px 5px 0 0;
  padding: 4px 16px;
  margin-bottom: 20px;
`;
