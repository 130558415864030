import { Box, Stack, TextField, FormControl, MenuItem, Select } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isAfter } from "date-fns";
import React, { useCallback } from "react";

import PauseIcon from "assets/icons/pause-outlined.svg";
import Banner from "components/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, ButtonText, Heading, Subtitle1 } from "components/library/typography";
import CustomModal from "components/Modal";
import { useSnoozeJobMutation } from "services/doverapi/endpoints/job";
import { SnoozeJobSnoozedReasonEnum } from "services/openapi";
import { device } from "styles/theme";
import { isValidDate } from "utils/dates";
import { StyledReactSVG } from "views/job/JobSetup/steps/Overview/styles";
import { ModalKeyType, snoozeOptions } from "views/job/types";

export const UpdateSnoozeModal = ({
  shouldShowModal,
  handleCloseModal,
  handleOpenCompletionModal,
  modalKey,
  jobName,
  jobId,
  snoozeReason,
  setSnoozeReason,
  selectedDate,
  setSelectedDate,
}: {
  shouldShowModal: string;
  handleCloseModal: () => void;
  handleOpenCompletionModal: () => void;
  modalKey: ModalKeyType;
  jobName: string | undefined;
  jobId: string | undefined;
  snoozeReason: string;
  setSnoozeReason: (value: string) => void;
  selectedDate: Date | null;
  setSelectedDate: (value: Date | null) => void;
}): React.ReactElement => {
  // Mutations
  const [snoozeJob] = useSnoozeJobMutation();

  // Derived data
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const disableSnooze =
    snoozeReason === "" ||
    !isValidDate(selectedDate) ||
    (isValidDate(selectedDate) && !isAfter(selectedDate!, new Date()));

  // Callbacks
  const handleSnooze = useCallback(() => {
    const snoozeJobAsync = async (): Promise<void> => {
      await snoozeJob({
        jobId: jobId!,
        snoozedReason: snoozeReason as SnoozeJobSnoozedReasonEnum,
        snoozedUntil: selectedDate!,
      }).unwrap();

      handleOpenCompletionModal();
    };

    snoozeJobAsync();
  }, [handleOpenCompletionModal, jobId, selectedDate, snoozeJob, snoozeReason]);

  return (
    <CustomModal
      open={shouldShowModal === modalKey}
      title={<Heading>{`Change Pause Details for ${jobName ? jobName : "Job"}?`}</Heading>}
      onClose={handleCloseModal}
      maxWidth={"sm"}
      dialogActions={
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant={ButtonVariant.Secondary} onClick={handleCloseModal}>
            {"Cancel"}
          </Button>
          <Button variant={ButtonVariant.Secondary} onClick={handleSnooze} disabled={disableSnooze}>
            <Stack direction="row" alignItems="center">
              <StyledReactSVG src={PauseIcon} $lighterColor={disableSnooze} />
              <ButtonText>{"Update Pause Info"}</ButtonText>
            </Stack>
          </Button>
        </Stack>
      }
    >
      <Stack p={4} spacing={4}>
        <Body>
          {`Remember that when pausing your search, you'll stop receiving new candidates. All existing candidates in your pipeline will be managed as usual.`}
        </Body>
        <Stack spacing={2}>
          <Subtitle1>{"Why are you pausing your search?"}</Subtitle1>
          <FormControl variant="outlined" size={"small"} fullWidth={true}>
            <Select
              value={snoozeReason}
              onChange={(value): void => {
                setSnoozeReason(value.target.value as string);
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                {"Select"}
              </MenuItem>
              {Object.keys(snoozeOptions).map(snoozeOptionKey => {
                const snoozeOption = snoozeOptions[snoozeOptionKey as SnoozeJobSnoozedReasonEnum];
                return (
                  <MenuItem key={snoozeOptionKey} value={snoozeOptionKey}>
                    {snoozeOption}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        {snoozeReason === SnoozeJobSnoozedReasonEnum.HasCandidatesInProcess && (
          <Banner type="warning" alignItems="flex-start">
            <Box pl={1}>
              {`Candidates sometimes drop out in late stages, and it can significantly delay your search to rebuild your pipeline from scratch in case that happens. Dover recommends continuing sourcing to keep a healthy pipeline until the hire is closed.`}
            </Box>
          </Banner>
        )}
        {snoozeReason !== "" && (
          <Stack spacing={2}>
            <Subtitle1>{"Pause Until:"}</Subtitle1>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                desktopModeMediaQuery={device.desktopS}
                minDate={tomorrow}
                inputFormat="MM/dd/yyyy"
                value={selectedDate}
                renderInput={(params): React.ReactElement => <TextField {...params} label="Date" fullWidth />}
                onChange={(date): void => {
                  if (isValidDate(date)) {
                    setSelectedDate(date as Date);
                  } else {
                    setSelectedDate(null);
                  }
                }}
              />
            </LocalizationProvider>
          </Stack>
        )}
      </Stack>
    </CustomModal>
  );
};
