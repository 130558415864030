import { ToggleButton } from "@doverhq/dover-ui";
import { ReactComponent as CriteriaIcon } from "@doverhq/dover-ui/icons/criteria.svg";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSetAtom } from "jotai";
import { isEqual } from "lodash";
import React, { useCallback, useContext, useEffect } from "react";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { StringParam, useQueryParam } from "use-query-params";

import { ReactComponent as MagnifyingGlassSVG } from "assets/icons/magnifying-glass.svg";
import { LoadingRelative } from "components/HotLoading";
import { Body, BodySmall, Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useDebounceState } from "hooks/useDebounceState";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListSearchesV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3, SearchV3Params, SearchV3SearchTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";
import { ApplicantCard } from "views/candidates/ApplicationReview/components/ApplicantListPanel/ApplicantCard";
import { SaapSorters } from "views/candidates/ApplicationReview/components/CriteriaPanel/SaapSorters";
import { applicationIdQueryParam } from "views/candidates/ApplicationReview/constants";
import { findApplicationIndex } from "views/candidates/ApplicationReview/utils/findApplicationIndex";
import { dtnParamsAtom } from "views/candidates/DoverTalentNetwork/atoms/dtnParams";
import { dtnSearchIdAtom } from "views/candidates/DoverTalentNetwork/atoms/dtnSearchId";
import { toNextTalentAtom } from "views/candidates/DoverTalentNetwork/atoms/listTalentNetwork";
import { DTNMainPanel } from "views/candidates/DoverTalentNetwork/components/MainPanel/DTNMainPanel";
import { useDoverTalents } from "views/candidates/DoverTalentNetwork/hooks/useDoverTalents";
import { FILTERS_WIDTH, searchV3FormDefaultValues } from "views/sourcing/Search/constants";
import { FormLoadStateContext, FormLoadStateWrapper } from "views/sourcing/Search/context/FilterToggleContext";
import { useLoadFormValues } from "views/sourcing/Search/hooks";
import { SearchV3FormSchemaType, searchV3FormSchema } from "views/sourcing/Search/types";
import { getSearchV3FromFormState } from "views/sourcing/Search/utils";

export const DoverTalentNetworkWrapper = (): React.ReactElement => {
  const jobIdFromUrl = useJobIdFromUrl();

  const setDtnSearchId = useSetAtom(dtnSearchIdAtom);
  const setIsDtn = useSetAtom(isDtnMode);
  setIsDtn(true);

  const { currentData: dtnSearches, isFetching: isFetchingSearches } = useListSearchesV3Query(
    jobIdFromUrl ? { job: jobIdFromUrl, searchTypeList: SearchV3SearchTypeEnum.Dtn } : skipToken
  );

  const search = React.useMemo(() => {
    if (!dtnSearches) {
      return undefined;
    }

    // should always be defined
    return dtnSearches[0];
  }, [dtnSearches]);

  const searchV3FormMethods = useForm<SearchV3FormSchemaType>({
    defaultValues: searchV3FormDefaultValues,
    resolver: zodResolver(searchV3FormSchema),
  });

  useEffect(() => {
    setDtnSearchId(search?.id);
  }, [search, setDtnSearchId]);

  // no searches v3s - must check for jobId existence because if !jobId --> we are still loading jobs
  // this means we won't have inboundSearches because we haven't run the query for them yet
  if (jobIdFromUrl && !dtnSearches?.length && !isFetchingSearches) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center" direction="row" spacing={1}>
        <MagnifyingGlassSVG className="svg-fill" color={colors.grayscale.gray500} />
        <Heading color={colors.grayscale.gray500}>
          No searches found. Please contact your Dover team to help you resolve this.
        </Heading>
      </Stack>
    );
  }

  if (!search) {
    return <DoverLoadingSpinner />;
  }

  return (
    <FormProvider {...searchV3FormMethods}>
      <FormLoadStateWrapper>
        <DoverTalentNetwork search={search} />
      </FormLoadStateWrapper>
    </FormProvider>
  );
};

export const DoverTalentNetwork = ({ search }: { search: SearchV3 }): React.ReactElement => {
  const setDtnParams = useSetAtom(dtnParamsAtom);
  const setToNextTalent = useSetAtom(toNextTalentAtom);

  const formLoadState = useContext(FormLoadStateContext);
  const setInitialFormValuesLoaded = formLoadState?.setFormValuesLoaded;
  const initialFormValuesLoaded = formLoadState?.loaded;
  const setFormValuesLoading = formLoadState?.setFormValuesLoading;
  const formValuesLoading = formLoadState?.formValuesLoading;

  useLoadFormValues(!!initialFormValuesLoaded, !!formValuesLoading, setFormValuesLoading, setInitialFormValuesLoaded);

  const { control } = useFormContext<SearchV3FormSchemaType>();
  const values = useWatch({ control });

  const [criteriaOpen, setCriteriaOpen] = React.useState(false);
  const [debouncedSearchParams, setSearchParams] = useDebounceState<SearchV3Params | undefined>(undefined, 500);

  const { doverTalents, isFetching: isFetchingDTN, isLoading: isLoadingDTN } = useDoverTalents();

  const [selectedApplicationIdParam, setSelectedApplicationIdParam] = useQueryParam(
    applicationIdQueryParam,
    StringParam
  );

  const selectedApplication = doverTalents?.find(
    application => application?.inboundAppId === selectedApplicationIdParam
  );

  // Callbacks
  const toNextTalent = useCallback(() => {
    if (!selectedApplication || !doverTalents) {
      return;
    }

    const currApplicationIndex = findApplicationIndex(doverTalents, selectedApplication.inboundAppId);

    // Couldn't find the application
    if (currApplicationIndex === undefined) {
      return;
    }

    const nextApplicationIndex = currApplicationIndex + 1;

    // Early return if at end of list already
    if (nextApplicationIndex >= doverTalents.length) {
      return;
    }

    // Set the new selected application id in the url
    setSelectedApplicationIdParam(doverTalents[nextApplicationIndex].inboundAppId);
  }, [doverTalents, selectedApplication, setSelectedApplicationIdParam]);

  // Set Search Params for List Call
  useEffect(() => {
    // Early return if a search is undefined or loading
    if (search === undefined) {
      return;
    }

    if (!initialFormValuesLoaded) {
      return;
    }

    // Early return if the search isn't parsable
    const formParseResult = searchV3FormSchema.safeParse(values);
    if (!formParseResult.success) {
      return;
    }

    // After updates, we'll get a referentially different search object from RTKQ
    // It's important the we check deep equality against the new search params so we don't perform an additional unnecessary update
    const newSearchParams = getSearchV3FromFormState(formParseResult.data, search).v3Params;
    if (isEqual(search.v3Params, newSearchParams) && !isEqual(debouncedSearchParams, newSearchParams)) {
      setSearchParams(search.v3Params);
      return;
    }

    // Prevent unnecessary rerenders
    if (!isEqual(debouncedSearchParams, newSearchParams)) {
      setSearchParams(newSearchParams);
    }
  }, [debouncedSearchParams, initialFormValuesLoaded, search, setSearchParams, values]);

  // console.log("debouncedSearchParams", debouncedSearchParams);
  // Set Atom Values for optimistic updates
  useEffect(() => {
    setDtnParams(debouncedSearchParams);
  }, [debouncedSearchParams, setDtnParams]);

  // We put this in jotai to use for optimistic updates
  useEffect(() => {
    // Wrapping in an anonymous function because jotai setters accept functions as inputs and calls them
    // So if you pass toNextApplication directly, it will get called immediately
    setToNextTalent(() => toNextTalent);
  }, [setToNextTalent, toNextTalent]);

  React.useEffect(() => {
    // Early return if we are still loading or don't have any applications
    if (isLoadingDTN || !doverTalents || !doverTalents.length) {
      return;
    }

    // Selected application does exist in the list, so early return
    if (selectedApplication) {
      return;
    }

    // Couldn't find the selected application so default to the first one
    setSelectedApplicationIdParam(doverTalents[0].inboundAppId);
  }, [doverTalents, setSelectedApplicationIdParam, isLoadingDTN, selectedApplication]);

  const showLoadingState = isLoadingDTN || isFetchingDTN || !doverTalents;
  return (
    <Stack direction="row" spacing={1} height="100%" px="1rem" py="0.5rem">
      <Stack
        minWidth="325px"
        maxWidth={{ xs: "none", md: "350px" }}
        display={{ md: "flex" }}
        flex={1}
        bgcolor={colors.white}
        border={`1px solid ${colors.grayscale.gray200}`}
        borderRadius="6px"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        overflow="hidden" // This is to stop the multi select action bar from clipping the bottom corners of the border
      >
        {showLoadingState ? (
          <Stack width="100%" height="100%" justifyContent="center" alignItems="center" padding="8px">
            <LoadingRelative />
          </Stack>
        ) : (
          <>
            <Stack
              direction={"row"}
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              padding="6px"
              borderBottom={`1px solid ${colors.grayscale.gray200}`}
            >
              <BodySmall color={colors.grayscale.gray600}>{doverTalents?.length} Matches</BodySmall>
              <ToggleButton
                icon={{ Icon: CriteriaIcon, side: "left" }}
                isSelected={criteriaOpen}
                onPress={(): void => setCriteriaOpen(!criteriaOpen)}
              >
                Criteria
              </ToggleButton>
            </Stack>
            <Stack direction="column" maxWidth="350px" overflow="auto">
              {doverTalents?.map(app => (
                <ApplicantCard
                  key={app.fullName}
                  application={app}
                  onClick={(): void => setSelectedApplicationIdParam(app.inboundAppId)}
                  selected={selectedApplication?.inboundAppId === app.inboundAppId}
                  disabled={false}
                />
              ))}
            </Stack>
            {doverTalents?.length === 0 && (
              <>
                <Stack
                  width={"100%"}
                  height={"100%"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  padding={"16px"}
                  sx={{ textAlign: "center" }}
                >
                  <Body weight="600">No matches found</Body>
                  <BodySmall>Try adjusting your criteria or checking again later</BodySmall>
                </Stack>
              </>
            )}
          </>
        )}
      </Stack>
      {criteriaOpen && (
        <Stack
          direction="column"
          minWidth={FILTERS_WIDTH} // The saap criteria form is shared with the sourcing page, so we need to share this min width so that both pages look good
          maxWidth={{ xs: "none", md: "350px" }}
          flex={1}
          bgcolor={colors.grayscale.gray100}
          border={`1px solid ${colors.grayscale.gray200}`}
          borderRadius="6px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          overflow="hidden" // This is to stop the chat background from clipping the bottom corners of the border
        >
          <SaapSorters search={search} />
        </Stack>
      )}
      {/* Main panel */}
      <Stack height="100%" display={"flex"} flex={3}>
        <Stack
          minHeight={0}
          height="100%"
          border={`1px solid ${colors.grayscale.gray200}`}
          borderRadius="6px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          sx={{
            backgroundImage: `linear-gradient(${colors.grayscale.gray100}, ${colors.white} 10%)`,
          }}
        >
          <DTNMainPanel
            application={selectedApplication}
            isFetching={showLoadingState}
            noResults={doverTalents?.length === 0}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
