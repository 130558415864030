import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React from "react";

import { Body, Subtitle1 } from "components/library/typography";
import { AddCandidateStep } from "sections/addcandidate/types";

interface Props {
  label?: string;
  pastSteps: {
    label: string;
    step: AddCandidateStep;
  }[];
}

const PastStepsSummary = ({ label = "Details", pastSteps }: Props): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // Render

  return (
    <>
      <Subtitle1>{label}</Subtitle1>
      <Stack direction="column" spacing={1} sx={{ pl: 1 }}>
        {pastSteps.map(({ label: stepLabel, step }, index) => (
          <React.Fragment key={stepLabel}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Body>
                <b>{stepLabel}</b>
              </Body>
              <div style={{ textAlign: "right" }}>{step.PostCompletionContent}</div>
            </Stack>

            {index !== pastSteps.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Stack>
    </>
  );
};

export default PastStepsSummary;
