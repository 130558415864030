import { AddCandidateErrors } from "sections/addcandidate/data/errors";
import { IAddCandidate } from "sections/addcandidate/services/api/interface";
import { FlowState, StepTypes } from "sections/addcandidate/types";
import { InterviewPipelineCandidateInfoSchema } from "sections/addcandidate/types/candidateInfo";
import { useAddCandidateInitialOutreachInterviewMutation } from "services/doverapi/endpoints/candidate";

// ---------------------------------------------------------------------------
// Service Implementation

export const useInterviewPipelineApi = (flowState: FlowState): IAddCandidate => {
  const [addCandidateInitialOutreachInterviewMutation] = useAddCandidateInitialOutreachInterviewMutation();

  return {
    addCandidate: async (): Promise<{ success: boolean; candidateId?: string; message?: string }> => {
      // 0. Collect data & api clients
      const { jobId } = flowState[StepTypes.JOB_SELECT];
      const candidateInfo = flowState[StepTypes.CANDIDATE_INFO] as InterviewPipelineCandidateInfoSchema;
      const { jobStage } = flowState[StepTypes.STAGE_SELECT];

      // 1. Make API call
      try {
        const addResponse = await addCandidateInitialOutreachInterviewMutation({
          job: jobId!,
          // Candidate info
          pipelineStage: jobStage?.id!,
          firstName: candidateInfo.firstName,
          lastName: candidateInfo.lastName,
          email: candidateInfo.email ?? undefined,
          linkedinProfileUrl: candidateInfo.linkedinUrl,
          resume: candidateInfo.resume ? (candidateInfo.resume as Blob) : undefined,
          sourceId: candidateInfo.source.value,
          agencyName: candidateInfo.agencyName ?? undefined,
          agencyEmail: candidateInfo.agencyEmail ?? undefined,
        }).unwrap();
        // 1.1 If we failed to create the candidate, return an error
        if (!addResponse.success) {
          return {
            success: false,
            message: addResponse.message,
          };
        }
        // 1.2 If the candidate was created successfully, return success
        return {
          candidateId: addResponse.candidate,
          success: true,
        };
      } catch (e) {
        // 1.3 If we failed to create the candidate due to a transient error, return an error telling them to try again
        return {
          success: false,
          message: AddCandidateErrors.generic.transientError,
        };
      }
    },
  };
};
