import { Stack } from "@mui/material";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";
import { ExternalLink } from "styles/typography";

export const PremiumFeatureModal = ({
  isModalOpen,
  toggleModalOff,
}: {
  isModalOpen: boolean;
  toggleModalOff: () => void;
}): ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "premium_feature", "misc");

  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={toggleModalOff}
        maxWidth="sm"
        customContentStyles={{ borderTop: "none" }}
      >
        <ModalBody spacing={2.5}>
          <Body centered>
            This is a premium feature. If you&apos;re interested in using this feature, please book time to talk to our
            sales team{" "}
            <ExternalLink href={calendlyUrl} target="_blank" style={{ textDecoration: "none" }}>
              here
            </ExternalLink>
            .
          </Body>
        </ModalBody>
      </CustomModal>
    </>
  );
};

const ModalBody = styled(Stack)`
  padding: 0 40px 25px;
`;
