/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobAtsSourceSettingAtsSource
 */
export interface JobAtsSourceSettingAtsSource {
    /**
     * 
     * @type {string}
     * @memberof JobAtsSourceSettingAtsSource
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobAtsSourceSettingAtsSource
     */
    name?: string | null;
}

export function JobAtsSourceSettingAtsSourceFromJSON(json: any): JobAtsSourceSettingAtsSource {
    return JobAtsSourceSettingAtsSourceFromJSONTyped(json, false);
}

export function JobAtsSourceSettingAtsSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobAtsSourceSettingAtsSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function JobAtsSourceSettingAtsSourceToJSON(value?: JobAtsSourceSettingAtsSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}


