import { Tooltip as MuiToolip } from "@mui/material";
import React from "react";
import styled from "styled-components";
import theme from "styled-theming";

import { colors } from "styles/theme";
export enum TooltipVariant {
  Light = "LIGHT",
  Dark = "DARK",
}

const backgroundColor = theme.variants("mode", "variant", {
  [TooltipVariant.Light]: { light: colors.white },
  [TooltipVariant.Dark]: { light: colors.grayscale.gray700 },
});

const textColor = theme.variants("mode", "variant", {
  [TooltipVariant.Light]: { light: colors.grayscale.gray700 },
  [TooltipVariant.Dark]: { light: colors.white },
});

const StyledTooltip = styled(props => (
  <MuiToolip classes={{ popper: props.className }} sx={{ boxSizing: "content-box" }} style={{ zIndex: 1 }} {...props} />
))`
  .MuiTooltip-tooltip {
    box-sizing: ${(props): string => props.boxsizing ?? "border-box"};
    background-color: ${backgroundColor};
    color: ${textColor};
    border: ${(props): string => props.border ?? "none"};
    border-radius: 4px;
    min-height: 32px;
    font-family: "Inter";
    font-size: 12px;
    line-height: 16px;
    padding: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    .MuiTooltip-arrow {
      color: ${backgroundColor};
    }
  }

  &.MuiTooltip-popper {
    z-index: ${(props): number | undefined => props.$zIndex};
  }
`;

interface TooltipProps {
  variant?: TooltipVariant;
  title?: string | React.ReactElement;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  children: React.ReactElement;
  interactive?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  onOpen?: () => void;
  onClose?: () => void;
  enterDelay?: number;
  leaveDelay?: number;
  arrow?: boolean;
  open?: boolean;
  boxSizing?: "border-box" | "content-box";
  zIndex?: number;
  disabled?: boolean;
  border?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  variant = TooltipVariant.Dark,
  title,
  placement = "top",
  children,
  interactive = true,
  onClick = (): void => {},
  onOpen = (): void => {},
  onClose = (): void => {},
  enterDelay = 100,
  leaveDelay = 0,
  arrow = false,
  open = undefined,
  boxSizing = "border-box",
  zIndex = undefined,
  disabled = false,
  border = undefined,
}) => {
  if (disabled || !title) {
    return children;
  }

  return (
    <StyledTooltip
      variant={variant}
      title={title}
      placement={placement}
      onClick={onClick}
      onOpen={onOpen}
      onClose={onClose}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      arrow={arrow}
      disableInteractive={!interactive}
      open={open}
      boxsizing={boxSizing}
      $zIndex={zIndex}
      border={border}
    >
      {children}
    </StyledTooltip>
  );
};
