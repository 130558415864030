import React from "react";

import { XOR } from "tslib/helperTypes";

export enum ComponentStatus {
  LOADING = "loading",
  EMPTY = "empty",
  READY_TO_RENDER = "readyToRender",
  ERROR = "error",
  SKIPPED = "skipped",
}

interface ComponentMetadata<PropType> {
  componentStatus: ComponentStatus;
  component: React.ReactElement<PropType> | null;
}

interface ComponentStatusOptionalProps {
  setComponentStatus?: (newStatus: ComponentStatus) => void;
}

type useComponentAndMetadataArgs<PropType> = XOR<
  { props: PropType; componentType: React.ComponentType<PropType> },
  { skip: boolean }
>;

export function useComponentAndMetadata<PropType extends ComponentStatusOptionalProps>({
  props,
  componentType,
  skip,
}: useComponentAndMetadataArgs<PropType>): ComponentMetadata<PropType> {
  const [componentStatus, setComponentStatus] = React.useState<ComponentStatus>(ComponentStatus.LOADING);

  // Not actually possible for all of these conditions to hit, but TypeScript isn't smart enough to know that skip and other props are mutually exclusive
  if (skip || !componentType || !props) {
    return {
      componentStatus: ComponentStatus.SKIPPED,
      component: null,
    };
  }

  const component = React.createElement(componentType, { ...props, setComponentStatus });

  return {
    componentStatus: componentStatus,
    component,
  };
}
