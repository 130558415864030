/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IRRContact
 */
export interface IRRContact {
    /**
     * 
     * @type {string}
     * @memberof IRRContact
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof IRRContact
     */
    linkedinUrl: string | null;
    /**
     * The full name of the Contact.
     * @type {string}
     * @memberof IRRContact
     */
    fullName: string;
    /**
     * The name the Contact should be addressed by. Used in communications.
     * @type {string}
     * @memberof IRRContact
     */
    preferredName: string;
}

export function IRRContactFromJSON(json: any): IRRContact {
    return IRRContactFromJSONTyped(json, false);
}

export function IRRContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRRContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'linkedinUrl': json['linkedin_url'],
        'fullName': json['full_name'],
        'preferredName': json['preferred_name'],
    };
}

export function IRRContactToJSON(value?: IRRContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'linkedin_url': value.linkedinUrl,
        'full_name': value.fullName,
        'preferred_name': value.preferredName,
    };
}


