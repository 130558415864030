/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyListParamsJson,
    CompanyListParamsJsonFromJSON,
    CompanyListParamsJsonFromJSONTyped,
    CompanyListParamsJsonToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanyList
 */
export interface CompanyList {
    /**
     * 
     * @type {string}
     * @memberof CompanyList
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyList
     */
    scopedToClient?: string | null;
    /**
     * 
     * @type {CompanyListParamsJson}
     * @memberof CompanyList
     */
    paramsJson: CompanyListParamsJson;
}

export function CompanyListFromJSON(json: any): CompanyList {
    return CompanyListFromJSONTyped(json, false);
}

export function CompanyListFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'scopedToClient': !exists(json, 'scoped_to_client') ? undefined : json['scoped_to_client'],
        'paramsJson': CompanyListParamsJsonFromJSON(json['params_json']),
    };
}

export function CompanyListToJSON(value?: CompanyList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'scoped_to_client': value.scopedToClient,
        'params_json': CompanyListParamsJsonToJSON(value.paramsJson),
    };
}


