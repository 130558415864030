import { Box, CircularProgress, Stack } from "@mui/material";
import { format } from "date-fns";
import React, { ReactElement, useMemo } from "react";

import { ReactComponent as DownloadSVG } from "assets/icons/download.svg";
import { ReactComponent as TrashSVG } from "assets/icons/trash.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { FileDropzone } from "components/FileDropzone";
import { FileThumbnail } from "components/FileThumbnail";
import { Body, Caption } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import {
  useListCandidateFilesQuery,
  useUploadCandidateFileMutation,
  useDeleteCandidateFileMutation,
} from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import {
  CandidateFiles,
  CandidateFilesUploadedContextEnum,
  CreateCandidateFileUploadedContextEnum,
} from "services/openapi";
import { colors } from "styles/theme";

interface DownloadFileArgs {
  url?: string;
  name?: string;
}

export const FilesTab = ({ candidateId }: { candidateId: string }): ReactElement => {
  const { showFilePreviewModal: showFileViewerModal } = useTopLevelModalManagerOptions();

  const [uploadFile] = useUploadCandidateFileMutation();
  const [deleteFile] = useDeleteCandidateFileMutation();

  const { data: files, isFetching } = useListCandidateFilesQuery({ candidateId });

  const downloadFile = ({ url, name }: DownloadFileArgs): void => {
    if (!url || !name) {
      return;
    }

    const a = document.createElement("a");
    a.style.display = "none";
    a.download = name;
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
  };

  const onClickFile = (file: CandidateFiles): void => {
    const url = file.fileContents;
    const name = file.name ?? undefined;
    const isPdf = url?.includes(".pdf") || undefined;
    const isOffice =
      url?.includes(".doc") || url?.includes(".ppt") || url?.includes(".xls") || url?.includes(".odt") || undefined;

    if (!isPdf && !isOffice) {
      downloadFile({ url, name });
      return;
    }

    showFileViewerModal({ url, name });
  };

  const onDrop = (acceptedFiles: File[]): void => {
    acceptedFiles.forEach(file => {
      uploadFile({
        candidate: candidateId,
        uploadedContext: CreateCandidateFileUploadedContextEnum.Other,
        fileContents: file,
        name: file.name,
      });
    });
  };

  const filteredFiles = useMemo(
    () => files?.filter((f): boolean => f.uploadedContext !== CandidateFilesUploadedContextEnum.Resume),
    [files]
  );

  return (
    <Stack spacing={2}>
      <FileDropzone options={{ onDrop }} />
      {isFetching ? (
        <Box display="flex" alignItems="center" justifyContent="center" marginTop="3em !important">
          <CircularProgress />
        </Box>
      ) : (
        <Stack spacing={1}>
          {filteredFiles?.map((file, index) => (
            <Stack
              key={file.id ?? file.name ?? file.created?.toISOString() ?? `${file.uploadedContext}-${index}}`}
              direction="row"
              spacing={2}
              alignItems="center"
              py="1em"
              sx={{
                cursor: "pointer",
                border: `1px ${colors.grayscale.gray300} solid`,
                borderRadius: "4px",
                ":hover": {
                  backgroundColor: colors.grayscale.gray100,
                },
              }}
              onClick={(): void => onClickFile(file)}
            >
              <FileThumbnail url={file.fileContents} />
              <Stack spacing={0.5} width="60%">
                <Body ellipsis>{file.name ?? "Untitled File"}</Body>
                {file.created && file.user && (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Caption>Uploaded {format(file.created, "M/dd/yy")} by </Caption>
                    <PersonaCoin size="small" pictureUrl={file.user.pictureUrl} tooltip={file.user.fullName} />
                  </Stack>
                )}
              </Stack>
              <Box
                sx={{ cursor: "pointer", marginLeft: "auto !important" }}
                onClick={(e): void => {
                  e.stopPropagation();
                }}
              >
                <a href={file.fileContents} title="Download" target="_blank" rel="noreferrer">
                  <DownloadSVG />
                </a>
              </Box>
              <Box
                sx={{ cursor: "pointer", marginRight: "1em !important" }}
                onClick={(e): void => {
                  e.stopPropagation();

                  if (!file.id) {
                    return;
                  }

                  deleteFile({ id: file.id });
                }}
              >
                <TrashSVG height="20px" width="20px" />
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
};
