import { ButtonFactory } from "components/Button/button-factory";
import { colors } from "styles/theme";

export const FilledButton = ButtonFactory({ hexColor: colors.primary.base, variant: "filled" });
export const FilledRedButton = ButtonFactory({ hexColor: colors.critical.base, variant: "filled" });
export const FilledGrayButton = ButtonFactory({ hexColor: colors.grayscale.gray400, variant: "filled" });

export const PrimaryButton = ButtonFactory({ hexColor: colors.primary.base, variant: "outlined" });
export const SecondaryButton = ButtonFactory({ hexColor: colors.grayscale.gray300, variant: "outlined" });
export const GrayButton = ButtonFactory({ hexColor: colors.black, variant: "outlined" });
export const GhostButton = ButtonFactory({ hexColor: "transparent", variant: "outlined" });
export const RedButton = ButtonFactory({ hexColor: colors.critical.base, variant: "outlined" });

export const TextButton = ButtonFactory({ hexColor: colors.informational.dark, variant: "text" });
