import React from "react";

import UploadUserFile from "components/dover/UploadUserFile";
import { Body, Heading, Subtitle1 } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { CreateUserUploadedFileUploadedContextEnum, UserUploadedFileUploadedContextEnum } from "services/openapi";
import { Bullet, ExclusionsCard, List } from "views/CompanySetup/components/Exclusions/styles";
import { UploadedFilesTable } from "views/CompanySetup/components/Exclusions/UploadedFilesTable";

export const CompanyContactExclusion = (): React.ReactElement => {
  const title = "Uploaded CSVs";

  return (
    <ExclusionsCard>
      <Heading>Other Exclusions</Heading>
      <Spacer height="4px" />
      <Body>Exclude candidates who are not in your integrated ATS or personal LinkedIn Connections above.</Body>
      <Spacer height="24px" />
      <Subtitle1>Your CSV must include the following columns:</Subtitle1>
      <Spacer height="4px" />
      <List>
        <Bullet>LinkedIn</Bullet>
        <Bullet>full_name</Bullet>
        <Bullet>email</Bullet>
      </List>
      <UploadUserFile
        uploadedContext={CreateUserUploadedFileUploadedContextEnum.CompanyLevelContactExclusion}
        acceptFileTypes={".csv"}
      />
      <UploadedFilesTable
        uploadedContext={UserUploadedFileUploadedContextEnum.CompanyLevelContactExclusion}
        title={title}
      />
    </ExclusionsCard>
  );
};
