import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { z } from "zod";

import ProUserSelector from "components/dover/inputs/pro-users/ProUserDropdownSelector";
import { Button, ButtonVariant } from "components/library/Button";
import { Heading, BodySmall, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { useUpdateJobHiringTeamMutation } from "services/doverapi/endpoints/job";
import { JobSetup } from "services/openapi";
import { colors } from "styles/theme";

interface ChangeJobRolesModalProps {
  open: boolean;
  onClose: () => void;
  job: JobSetup;
}

const jobDetailsSchema = z.object({
  hiringManagerId: z.number(),
  recruiterId: z.number().optional(),
  hiringTeamMemberIds: z.array(z.number()),
});

type JobDetailsSchema = z.infer<typeof jobDetailsSchema>;

export const ChangeJobRolesModal = ({ open, onClose, job }: ChangeJobRolesModalProps): React.ReactElement => {
  const [updateHiringTeam, { isLoading: isUpdating }] = useUpdateJobHiringTeamMutation();

  const methods = useForm<JobDetailsSchema>({
    defaultValues: {
      hiringManagerId: job.hiringManager?.id,
      recruiterId: job.recruiter?.id,
      hiringTeamMemberIds: job.hiringTeam?.map(u => u.id),
    },
    resolver: zodResolver(jobDetailsSchema),
  });

  const { field: hiringManagerField } = useController({
    name: "hiringManagerId",
    control: methods.control,
  });

  const { field: recruiterField } = useController({
    name: "recruiterId",
    control: methods.control,
  });
  const { field: hiringTeamField } = useController({
    name: "hiringTeamMemberIds",
    control: methods.control,
  });

  const onCloseWrapper = React.useCallback(() => {
    methods.reset();
    onClose();
  }, [methods, onClose]);

  const onSubmit = React.useCallback(async () => {
    const hiringManagerId = hiringManagerField.value;
    const recruiterId = recruiterField.value;
    const hiringTeamMemberIds = hiringTeamField.value;

    if (!job.id || !hiringManagerId) {
      return;
    }

    await updateHiringTeam({
      id: job.id,
      hiringManagerId: hiringManagerId,
      recruiterId: recruiterId ?? null,
      hiringTeamMemberIds,
    });
    onCloseWrapper();
  }, [job.id, onCloseWrapper, updateHiringTeam, hiringManagerField.value, recruiterField.value, hiringTeamField.value]);

  const noHmError = !hiringManagerField.value;
  return (
    <>
      <CustomModal
        open={open}
        onClose={onCloseWrapper}
        maxWidth={"xs"}
        title={<Heading weight="600">Hiring Team</Heading>}
      >
        {/* Can't use onSubmit here because inside ProUserSelector there is another form -- submitting that form will submit this one too */}
        <form>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Subtitle2>Hiring Manager</Subtitle2>
              <ProUserSelector value={hiringManagerField.value} onChange={hiringManagerField.onChange} size="small" />
              {noHmError && <BodySmall color={colors.critical.base}>A hiring manager is required</BodySmall>}
            </Stack>
            <Stack spacing={1}>
              <Subtitle2>Recruiter (optional)</Subtitle2>
              <ProUserSelector value={recruiterField.value ?? null} onChange={recruiterField.onChange} size="small" />
              <Subtitle2>Additional team members (optional)</Subtitle2>
              <ProUserSelector
                multiple
                value={hiringTeamField.value}
                onChange={hiringTeamField.onChange}
                size="small"
              />
            </Stack>
            <Stack spacing={1} alignItems="flex-end">
              <Stack direction="row" spacing={1}>
                <Button variant={ButtonVariant.Primary} onClick={onSubmit} disabled={isUpdating || noHmError}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </CustomModal>
    </>
  );
};
