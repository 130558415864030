import { memoize } from "lodash";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { JOB_SETUP_STEP_CONFIGS, STEP_VISIBILITY_CONFIG } from "services/doverapi/endpoints/job/constants";
import {
  EnrichedJobSetupStep,
  JobSetupStepConfig,
  JobSetupStepConstants,
  JobSetupStepNameToInfo,
  StepVisiblityCheckFunc,
} from "services/doverapi/endpoints/job/types";
import {
  JobSetupStep,
  JobSetupSteps,
  JobSetupStepStateEnum,
  JobSetupStepStepNameEnum,
  DashboardJob,
} from "services/openapi";

export const prioritizeActiveJobsSort = (jobs: DashboardJob[]): DashboardJob[] => {
  if (jobs.find(job => job.active)) {
    return jobs.filter(job => job.active);
  }
  return jobs;
};

export const getJobSetupStepMap = memoize(
  (jobSetupStepStates: JobSetupSteps): JobSetupStepNameToInfo => {
    const map: any = {};
    jobSetupStepStates.setupSteps.forEach((stepState: JobSetupStep) => {
      map[stepState.stepName] = { state: stepState.state, isRelevantToJob: stepState.isRelevantToJob };
    });
    return map as JobSetupStepNameToInfo;
  }
);

export const getStepRoute = (jobId: string, stepPath: string, jpqId?: string | null): string => {
  if (stepPath === "questionnaire") {
    if (!jpqId) {
      return APP_ROUTE_PATHS.job.overview(jobId);
    }
  }
  return APP_ROUTE_PATHS.job.jobSetupStep(jobId, stepPath);
};

function getJobSetupSteps({
  stepNameToInfoMap,
  disregardStepRelevancy,
  includeAllCompletedSteps,
}: {
  stepNameToInfoMap: JobSetupStepNameToInfo;
  disregardStepRelevancy?: boolean;
  includeAllCompletedSteps?: boolean;
}): JobSetupStepConfig[] {
  const setupSteps: JobSetupStepConfig[] = [];

  Object.entries(stepNameToInfoMap).forEach(([key, value]) => {
    const shouldShowStep: StepVisiblityCheckFunc = STEP_VISIBILITY_CONFIG[key as JobSetupStepStepNameEnum];
    if (
      shouldShowStep?.({ isRelevantToJob: value.isRelevantToJob }) ||
      disregardStepRelevancy ||
      (includeAllCompletedSteps && value.state === JobSetupStepStateEnum.Complete)
    ) {
      setupSteps.push({
        ...(JOB_SETUP_STEP_CONFIGS[key as JobSetupStepStepNameEnum] as JobSetupStepConstants),
        setupState: value.state,
      });
    }
  });

  return setupSteps;
}

export function getEnrichedJobSetupSteps({
  stepNameToInfoMap,
  jobId,
  jpqId,
  disregardStepRelevancy,
  includeAllCompletedSteps,
}: {
  stepNameToInfoMap: JobSetupStepNameToInfo;
  jobId: string | undefined;
  jpqId: string | null;
  disregardStepRelevancy?: boolean;
  includeAllCompletedSteps?: boolean;
}): EnrichedJobSetupStep[] {
  const rawJobSetupSteps: JobSetupStepConfig[] = getJobSetupSteps({
    stepNameToInfoMap: stepNameToInfoMap,
    disregardStepRelevancy,
    includeAllCompletedSteps,
  });

  if (!jobId) {
    return [];
  }

  return rawJobSetupSteps.map(step => ({
    ...step,
    route: getStepRoute(jobId, step.path, jpqId),
  }));
}
