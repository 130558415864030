import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import styled from "styled-components";

import { ReactComponent as ThumbsUpIcon } from "assets/icons/thumbs-up.svg";
import { Button, ButtonVariant } from "components/library/Button";
import ComboButton, { ComboButtonVariant } from "components/library/combo-button/ComboButton";
import { Body, Heading } from "components/library/typography";
import CustomModal from "components/Modal";
import { Role, useHasRole } from "components/RBAC";
import { RejectionReason } from "contexts/feedback-context/constants";
import { useShouldShowRealProfiles } from "services/doverapi/endpoints/client/hooks";
import {
  useRegisterSaapReviewedCandidateSubmissionMutation,
  usePartialUpdateSearchV3Mutation,
  useGetSearchV3Query,
} from "services/doverapi/endpoints/search-v3/endpoints";
import {
  ProfileResponse,
  RegisterSaapReviewedCandidateRequestReviewTypeEnum,
  ProfileResponseSaapCalibrationTypeEnum,
  RegisterSaapReviewedCandidateRequestRejectionReasonEnum,
  SearchV3SearchTypeEnum,
} from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import CandidateFiltersBar from "views/sourcing/Search/components/CandidateCard/CandidateFiltersBar";
import CandidateFiltersExpanded from "views/sourcing/Search/components/CandidateCard/CandidateFiltersExpanded";
import CandidateInfo, { SocialLinks } from "views/sourcing/Search/components/CandidateCard/CandidateInfo";
import { useSearchId } from "views/sourcing/Search/hooks";
import { SourcingContext } from "views/sourcing/Search/types";

const PADDING_CANDIDATE_CARDS = "28px";
const CALIBRATION_MODE_WIDTH = "225px";
const CANDIDATE_INFO_WIDTH = `calc(100% - ${CALIBRATION_MODE_WIDTH})`;

const mapRejectionReasonToEnum = (
  rejectionReason: string | undefined
): RegisterSaapReviewedCandidateRequestRejectionReasonEnum | undefined => {
  // function to map rejection reasons to the ENUM we feed to the backend
  // (note: a record didn't work due to rejectionReason types/typescript errors)
  switch (rejectionReason) {
    case RejectionReason.HasTheWrongJobTitles:
      return RegisterSaapReviewedCandidateRequestRejectionReasonEnum.WrongJobTitles;
    case RejectionReason.HasNotWorkedAtASimilarSizedCompany:
      return RegisterSaapReviewedCandidateRequestRejectionReasonEnum.WrongCompanySize;
    case RejectionReason.WorkedAtUnimpressiveCompanies:
      return RegisterSaapReviewedCandidateRequestRejectionReasonEnum.UnimpressiveCompanies;
    case RejectionReason.AttendedAnUnimpressiveSchool:
      return RegisterSaapReviewedCandidateRequestRejectionReasonEnum.UnimpressiveSchools;
    case RejectionReason.MajoredInTheWrongSubjectAtSchool:
      return RegisterSaapReviewedCandidateRequestRejectionReasonEnum.WrongSchoolMajor;
    case RejectionReason.NotEnoughYearsOfExperience:
      return RegisterSaapReviewedCandidateRequestRejectionReasonEnum.NotEnoughYearsOfExperience;
    default:
      return undefined;
  }
};

const ThumbsDownIcon = styled(ThumbsUpIcon)`
  transform: rotate(180deg);
`;

// need to style a button with such a color as an easy way to get a black star for topCandidate
const BlackTextButton = styled(Button)`
  color: ${colors.grayscale.gray700};
  outline: 1px solid ${colors.grayscale.gray200};
`;

// style a button with a light yellow background and darker yellow color for svg's text
const LightYellowButton = styled(Button)`
  background-color: ${colors.warning.light};
  color: ${colors.warning.base};
  &:hover {
    background-color: ${colors.white};
  }
  outline: 1px solid ${colors.grayscale.gray200};
`;

// style a button with a light green background and darker green color for svg's text
const LightGreenButton = styled(Button)`
  background-color: ${backgrounds.green};
  &:hover {
    background-color: ${colors.white};
  }
  outline: 1px solid ${colors.grayscale.gray200};
`;

const BORDER_RADIUS_VALUE = "4px";

interface CandidateCardProps {
  profile: ProfileResponse;
  context?: SourcingContext;
}

const CandidateCard = React.memo(
  ({ profile, context }: CandidateCardProps): React.ReactElement => {
    const isAdmin = useHasRole(Role.ADMIN);

    const shouldShowRealProfiles = useShouldShowRealProfiles();
    const [partialUpdateSearch] = usePartialUpdateSearchV3Mutation();

    const [candidateCardExpanded, setCandidateCardExpanded] = React.useState(false);

    const searchId = useSearchId();

    const { data: search, isLoading: isSearchLoading } = useGetSearchV3Query(searchId ? { id: searchId } : skipToken);

    const [selectedRejectionReason, setSelectedRejectionReason] = React.useState<string | undefined>();

    const [inactiveSearchModalOpen, setInactiveSearchModalOpen] = React.useState(false);

    const hasEmail = profile.hasEmail ?? false;

    const [
      registerSaapReviewedCandidateSubmission,
      { isLoading: isRegisteringSubmission },
    ] = useRegisterSaapReviewedCandidateSubmissionMutation();

    const [registeredReviewType, setRegisteredReviewType] = React.useState<string | undefined>(
      profile.saapCalibrationType
    );

    const isTopCandidate = React.useMemo(() => {
      return registeredReviewType === ProfileResponseSaapCalibrationTypeEnum.TopCandidate;
    }, [registeredReviewType]);

    const isGoodFit = React.useMemo(() => {
      return registeredReviewType === ProfileResponseSaapCalibrationTypeEnum.GoodFit;
    }, [registeredReviewType]);

    const isBadFit = React.useMemo(() => {
      if (registeredReviewType !== ProfileResponseSaapCalibrationTypeEnum.BadFit) {
        setSelectedRejectionReason(undefined);
      }
      return registeredReviewType === ProfileResponseSaapCalibrationTypeEnum.BadFit;
    }, [registeredReviewType]);

    const socialLinks = React.useMemo((): SocialLinks => {
      let linkedinLink: string | undefined,
        gitHubLink: string | undefined,
        personalWebsiteLink: string | undefined = undefined;
      const socialLinks = profile.socialLinks;

      // show social links to all admins and all those with `shouldShowRealProfiles`
      if (socialLinks && (isAdmin || shouldShowRealProfiles)) {
        for (let i = 0; i < socialLinks.length; i++) {
          if (socialLinks[i].linkType == "LINKEDIN") {
            linkedinLink = socialLinks[i].url;
          } else if (socialLinks[i].linkType == "GITHUB") {
            gitHubLink = socialLinks[i].url;
          } else if (socialLinks[i].linkType == "WEBSITE") {
            personalWebsiteLink = socialLinks[i].url;
          }
        }
      }
      return {
        linkedinLink,
        gitHubLink,
        personalWebsiteLink,
      };
    }, [profile.socialLinks, isAdmin, shouldShowRealProfiles]);

    const passingNiceToHaves = React.useMemo(() => {
      const passingObjects = profile.niceToHaveExplanation.passingFilters;
      const passingExtraDetails = passingObjects
        .map(passingObject => passingObject.extraDetails)
        .flat()
        .map(extraDetail => extraDetail.simple)
        .flat();
      return passingExtraDetails;
    }, [profile]);

    const passingMustHaves = React.useMemo(() => {
      const passingObjects = profile.mustHaveExplanation.passingFilters;
      const passingExtraDetails = passingObjects
        .map(passingObject => passingObject.extraDetails)
        .flat()
        .map(extraDetail => extraDetail.simple)
        .flat();
      return passingExtraDetails;
    }, [profile]);

    const failingNiceToHaves = React.useMemo(() => {
      const failingObjects = profile.niceToHaveExplanation.failingFilters;
      const failingExtraDetails = failingObjects
        .map(failingObject => failingObject.extraDetails)
        .flat()
        .map(extraDetail => extraDetail.simple)
        .flat();
      return failingExtraDetails;
    }, [profile]);

    // non-create job flow calibration mode functions
    const registerSaapReviewedCandidateSubmissionWrapper = React.useCallback(
      async (
        reviewType: RegisterSaapReviewedCandidateRequestReviewTypeEnum | undefined,
        rejectionReason: RegisterSaapReviewedCandidateRequestRejectionReasonEnum | undefined
      ): Promise<boolean> => {
        // make the API call to register a candidate review with a given reviewType and rejectionReason
        if (!searchId) {
          return false;
        }
        if (!search?.active) {
          setInactiveSearchModalOpen(true);
        }
        const registerResponse = await registerSaapReviewedCandidateSubmission({
          searchId: searchId,
          canonicalId: profile.canonicalId,
          reviewType: reviewType,
          rejectionReason: rejectionReason,
        }).unwrap();
        return registerResponse.success;
      },
      [profile.canonicalId, registerSaapReviewedCandidateSubmission, search, searchId]
    );

    const goodFitOnClick = React.useCallback(async () => {
      // when you click goodfit, it was either goodfit before, and want to remove the Goodfit review
      // or it wasn't a goodfit before, and you want to make it a good fit
      const desiredState = isGoodFit ? undefined : RegisterSaapReviewedCandidateRequestReviewTypeEnum.GoodFit;
      const success = await registerSaapReviewedCandidateSubmissionWrapper(desiredState, undefined);

      // if the API call failed, then we want to leave the state as is (to show it wasnt successful)
      if (success) {
        setRegisteredReviewType(desiredState);
      }
    }, [isGoodFit, registerSaapReviewedCandidateSubmissionWrapper]);

    const badFitOnClick = React.useCallback(
      async (justUpdatingReason: boolean | undefined = undefined, rejectionReason: string | undefined = undefined) => {
        // when you click badfit, it was either badfit before, and want to remove the badfit review
        // or it wasn't a badfit before, and you want to make it a good fit, with a possible rejection reason
        // justUpdatingReason is for when it was already a bad fit, but you are just updating the rejection reason associated with the bad fit

        let desiredState: RegisterSaapReviewedCandidateRequestReviewTypeEnum | undefined;
        if (justUpdatingReason) {
          // want to resubmit a review of bad_fit even if it was already bad fit, because thats how backend expects a rejection reason update
          desiredState = RegisterSaapReviewedCandidateRequestReviewTypeEnum.BadFit;
        } else {
          desiredState = isBadFit ? undefined : RegisterSaapReviewedCandidateRequestReviewTypeEnum.BadFit;
        }

        // case the rejection reason string to appropriate enum that the backend expects
        const castRejectionReason = mapRejectionReasonToEnum(rejectionReason);
        const success = await registerSaapReviewedCandidateSubmissionWrapper(desiredState, castRejectionReason);

        // if the API call failed, then we want to leave the state as is (to show it wasnt successful)
        if (success) {
          setRegisteredReviewType(desiredState);
        }
      },
      [isBadFit, registerSaapReviewedCandidateSubmissionWrapper]
    );

    const topCandidateOnClick = React.useCallback(async () => {
      // when you click topcandidate, it was either topcandidate before, and want to remove the topcandidate review
      // or it wasn't a topcandidate before, and you want to make it a good fit
      const desiredState = isTopCandidate ? undefined : RegisterSaapReviewedCandidateRequestReviewTypeEnum.TopCandidate;

      const success = await registerSaapReviewedCandidateSubmissionWrapper(desiredState, undefined);

      // if the API call failed, then we want to leave the state as is (to show it wasnt successful)
      if (success) {
        setRegisteredReviewType(desiredState);
      }
    }, [isTopCandidate, registerSaapReviewedCandidateSubmissionWrapper]);

    const rejectionReasonsMenuItemsCalibrationMode = React.useMemo(() => {
      return Object.values(RejectionReason).map(reason => {
        return {
          label: reason,
          onClick: (): void => {
            // if it was clicked while it was already a bad fit, then dont want to deselect bad fit, but rather want to update the rejection reason
            const justUpdatingReason = isBadFit;
            badFitOnClick(justUpdatingReason, reason);
            setSelectedRejectionReason(reason);
          },
        };
      });
    }, [badFitOnClick, isBadFit]);

    const expandMoreIcon = React.useMemo(() => {
      return candidateCardExpanded ? <KeyboardArrowUpIcon /> : <ChevronRightIcon />;
    }, [candidateCardExpanded]);

    const onClickCandidateCard = React.useCallback(() => {
      setCandidateCardExpanded(!candidateCardExpanded);
    }, [candidateCardExpanded]);

    const isInCalibrationMode = React.useMemo(() => {
      // this is the calibration mode that happens in the non-create job flow (in the default saap UI mode)
      // cant happen at the same time as the create job flow calibration mode
      // want to double check that the customer is on a plan that allows calibration mode
      return (
        context !== SourcingContext.CreateJob &&
        shouldShowRealProfiles &&
        !isSearchLoading &&
        search?.searchType !== SearchV3SearchTypeEnum.Inbound
      );
    }, [context, isSearchLoading, search?.searchType, shouldShowRealProfiles]);

    const goodFitButton = React.useMemo(() => {
      const textColor = isGoodFit ? colors.success.base : colors.black;
      const innerGoodFitBody = (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          padding="7px 12px"
          paddingLeft="9px"
          width="175px"
        >
          {/* Keep this 26px to align it with other icons */}
          <Box width="26px">
            <ThumbsUpIcon className="svg-color" color={textColor} />
          </Box>
          <Body color={textColor}>Good Fit</Body>
        </Stack>
      );
      if (!isGoodFit) {
        // want to use a ghost button when it is not a good fit
        return (
          <BlackTextButton
            variant={ButtonVariant.Ghost}
            onClick={goodFitOnClick}
            removePadding
            removeOutline
            disabled={isRegisteringSubmission}
          >
            {innerGoodFitBody}
          </BlackTextButton>
        );
      }
      // want the light green background button when it is a good fit
      return (
        <LightGreenButton
          variant={ButtonVariant.Ghost}
          onClick={goodFitOnClick}
          removePadding
          removeOutline
          disabled={isRegisteringSubmission}
        >
          {innerGoodFitBody}
        </LightGreenButton>
      );
    }, [goodFitOnClick, isGoodFit, isRegisteringSubmission]);

    const badFitButton = React.useMemo(() => {
      const textColor = isBadFit ? colors.critical.base : colors.black;
      return (
        <Box height="40px" width="100%">
          <ComboButton
            label=""
            overflowActions={rejectionReasonsMenuItemsCalibrationMode}
            onClick={(): void => {
              badFitOnClick();
            }}
            variant={isBadFit ? ComboButtonVariant.LightCritical : ComboButtonVariant.Ghost}
            icon={
              <>
                {/* Keep this 26px to align it with other icons */}
                <Box width="26px">
                  <ThumbsDownIcon className="svg-color" color={textColor} />
                </Box>
                <Body color={isBadFit ? colors.critical.base : colors.black}>Bad Fit</Body>
              </>
            }
            hideLabel={true}
            disabled={isRegisteringSubmission}
            selectedLabel={selectedRejectionReason}
          />
        </Box>
      );
    }, [
      badFitOnClick,
      isBadFit,
      isRegisteringSubmission,
      rejectionReasonsMenuItemsCalibrationMode,
      selectedRejectionReason,
    ]);

    const topCandidateButton = React.useMemo(() => {
      const textColor = isTopCandidate ? colors.warning.base : colors.black;
      const innerTopCandidateBody = (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-start"
          padding="7px 12px"
          paddingLeft="9px"
          width="175px"
        >
          <StarBorderIcon />
          <Body color={textColor}>Top Candidate </Body>
        </Stack>
      );
      if (!isTopCandidate) {
        // want a ghost button if it is not a top candidate
        return (
          <BlackTextButton
            variant={ButtonVariant.Ghost}
            onClick={topCandidateOnClick}
            removePadding
            removeOutline
            disabled={isRegisteringSubmission}
          >
            {innerTopCandidateBody}
          </BlackTextButton>
        );
      }
      // want a button with light red background if it is a top candidate
      return (
        <LightYellowButton
          variant={ButtonVariant.Ghost}
          onClick={topCandidateOnClick}
          removePadding
          removeOutline
          disabled={isRegisteringSubmission}
        >
          {innerTopCandidateBody}
        </LightYellowButton>
      );
    }, [isRegisteringSubmission, isTopCandidate, topCandidateOnClick]);

    const closeInactiveSearchModal = React.useCallback(() => {
      setInactiveSearchModalOpen(false);
    }, []);

    const activateSearch = React.useCallback(() => {
      if (!search?.id) {
        return;
      }

      // activate this search
      partialUpdateSearch({
        id: search.id,
        data: {
          ...search,
          active: true,
        },
      })
        .unwrap()
        .then(() => {
          closeInactiveSearchModal();
        });
    }, [closeInactiveSearchModal, partialUpdateSearch, search]);

    return (
      // Parent stack which holds both the candidate info and the calibration section
      <>
        <Stack direction="row" justifyContent="space-between" width="100%" sx={{ backgroundColor: "white" }}>
          {/* Candidate Card Section - Actual info about the candidate */}
          <Stack
            sx={{ ":hover": { backgroundColor: colors.grayscale.gray100, cursor: "pointer" } }}
            style={{
              border: `1px solid ${colors.grayscale.gray200}`,
              boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
              // borderRadius depends on whether calibrationMode is on, so we can keep the four corners rounded on the whole card
              borderRadius: isInCalibrationMode
                ? `${BORDER_RADIUS_VALUE} 0px 0px ${BORDER_RADIUS_VALUE}`
                : BORDER_RADIUS_VALUE,
            }}
            // leave 20% for the calibrationMode section if its on
            width={isInCalibrationMode ? CANDIDATE_INFO_WIDTH : "100%"}
            onClick={onClickCandidateCard}
          >
            <Box padding="25px 15px 10px 15px">
              <CandidateInfo
                fullName={profile.fullName}
                socialLinks={socialLinks}
                recentPositionsInfo={profile.recentPositionsInfo}
                totalYoe={profile.totalYoe}
                specificYoe={profile.specificYoe}
                headlineEducation={profile.headlineEducation}
                location={profile.location}
                candidateCardExpanded={candidateCardExpanded}
                hasEmail={hasEmail}
              />
            </Box>
            {/* Accordion for the nice to have and must have filters section */}
            <Accordion
              expanded={candidateCardExpanded}
              style={{ boxShadow: "none", borderRadius: `0px 0px 0px ${BORDER_RADIUS_VALUE}` }}
              sx={{
                // removes a line at the top of the accordion
                "&:before": {
                  display: "none",
                },
                backgroundColor: "inherit",
                padding: `0px ${PADDING_CANDIDATE_CARDS}`,
              }}
              disableGutters={true}
            >
              <AccordionSummary sx={{ minHeight: "0px", padding: "0px", margin: "0px" }}>
                {!candidateCardExpanded && (
                  <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
                    <CandidateFiltersBar
                      passingNiceToHaves={passingNiceToHaves}
                      failingNiceToHaves={failingNiceToHaves}
                      passingMustHaves={passingMustHaves}
                    />
                    {expandMoreIcon}
                  </Stack>
                )}
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px", paddingBottom: PADDING_CANDIDATE_CARDS }}>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={1}
                  paddingTop="8px"
                >
                  <CandidateFiltersExpanded
                    expanded={candidateCardExpanded}
                    niceToHaveExplanation={profile.niceToHaveExplanation}
                    mustHaveExplanation={profile.mustHaveExplanation}
                  />
                  {expandMoreIcon}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
          {/* Calibration Mode Section */}
          {isInCalibrationMode && (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              width={CALIBRATION_MODE_WIDTH}
              justifyContent="center"
              alignItems="center"
              style={{
                border: `1px solid ${colors.grayscale.gray200}`,
                borderRadius: `0px ${BORDER_RADIUS_VALUE} ${BORDER_RADIUS_VALUE} 0px`,
                borderLeft: "none",
              }}
            >
              {/* Need a stack inside of a box so as to keep the 3 buttons left aligned, but inside the center of the box */}
              <Stack spacing={1} alignItems="flex-start">
                {topCandidateButton}
                {goodFitButton}
                {badFitButton}
              </Stack>
            </Box>
          )}
        </Stack>
        {search !== undefined && (
          <CustomModal
            open={inactiveSearchModalOpen}
            onClose={closeInactiveSearchModal}
            title={<Heading>Inactive Search</Heading>}
            maxWidth={"sm"}
          >
            <Stack spacing={1}>
              <Body>
                {
                  "This search is not currently active, so we won't reach out to approved candidates yet. Do you want to activate it?"
                }
              </Body>
              <Stack spacing={1} direction="row" width="100%" justifyContent="flex-end">
                <BlackTextButton variant={ButtonVariant.Ghost} onClick={activateSearch} removeOutline>
                  Yes
                </BlackTextButton>
                <BlackTextButton variant={ButtonVariant.Ghost} onClick={closeInactiveSearchModal} removeOutline>
                  No
                </BlackTextButton>
              </Stack>
            </Stack>
          </CustomModal>
        )}
      </>
    );
  }
);

export default CandidateCard;
