import { TextField, Autocomplete } from "@mui/material";
import React from "react";
import styled from "styled-components";

const TimePickerWrapper = styled.div`
  width: 104px;
  .MuiInputBase-root {
    padding: 0 !important;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    border: 0;
  }
`;

interface Props {
  label?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  errorText?: string;
}
export const TimePicker = ({ label = "", value, disabled, onChange, errorText }: Props): React.ReactElement => {
  return (
    <TimePickerWrapper>
      <Autocomplete
        value={value}
        onChange={(event: any, newValue: any): void => {
          onChange && onChange(newValue);
        }}
        renderInput={(params: any): React.ReactElement => (
          <TextField {...params} label={label} error={!!errorText} helperText={errorText} fullWidth />
        )}
        options={getTimeOptions()}
        blurOnSelect
        disabled={disabled}
      />
    </TimePickerWrapper>
  );
};

const amHours = ["6", "7", "8", "9", "10", "11"];
const pmHours = ["12", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
const minutes = ["00", "30"];
function getTimeOptions(): any {
  const options: any = [];

  amHours.forEach(hour => {
    minutes.forEach(minute => {
      options.push(`${hour}:${minute} am`);
    });
  });

  pmHours.forEach(hour => {
    minutes.forEach(minute => {
      options.push(`${hour}:${minute} pm`);
    });
  });

  return options;
}
