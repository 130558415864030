import React from "react";
import styled from "styled-components";

import { ActivityFeedItem } from "services/openapi";

interface Props {
  event: ActivityFeedItem;
}

export const MovedJobEventBody = ({ event }: Props): React.ReactElement => {
  return (
    <Subtitle>
      From {event?.movedJobEventDetails?.oldJobTitle || ""} to {event?.movedJobEventDetails?.newJobTitle || ""}
    </Subtitle>
  );
};

const Subtitle = styled.p`
  font-size: 12px;
  font-family: "Inter";
  margin-bottom: 0px;
  margin-left: 4px;
`;
