/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobReferralJob
 */
export interface JobReferralJob {
    /**
     * 
     * @type {string}
     * @memberof JobReferralJob
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferralJob
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JobReferralJob
     */
    readonly hiringManagerId?: number;
    /**
     * 
     * @type {string}
     * @memberof JobReferralJob
     */
    readonly hiringManagerEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobReferralJob
     */
    readonly hiringManagerFullName?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof JobReferralJob
     */
    readonly emailAliasUserIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof JobReferralJob
     */
    readonly numActiveInitialOutreachCampaigns?: number;
}

export function JobReferralJobFromJSON(json: any): JobReferralJob {
    return JobReferralJobFromJSONTyped(json, false);
}

export function JobReferralJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobReferralJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'hiringManagerId': !exists(json, 'hiring_manager_id') ? undefined : json['hiring_manager_id'],
        'hiringManagerEmail': !exists(json, 'hiring_manager_email') ? undefined : json['hiring_manager_email'],
        'hiringManagerFullName': !exists(json, 'hiring_manager_full_name') ? undefined : json['hiring_manager_full_name'],
        'emailAliasUserIds': !exists(json, 'email_alias_user_ids') ? undefined : json['email_alias_user_ids'],
        'numActiveInitialOutreachCampaigns': !exists(json, 'num_active_initial_outreach_campaigns') ? undefined : json['num_active_initial_outreach_campaigns'],
    };
}

export function JobReferralJobToJSON(value?: JobReferralJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
    };
}


