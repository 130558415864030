import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as GreenCheckIconSVG } from "assets/icons/check-green.svg";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

const SearchCriteriaUpdatedText = React.memo(
  (): React.ReactElement => {
    return (
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        sx={{ backgroundColor: colors.grayscale.gray100 }}
        style={{ borderRadius: "3px" }}
        padding="8px"
      >
        <GreenCheckIconSVG />
        <BodySmall>Search criteria saved!</BodySmall>
      </Stack>
    );
  }
);

export default SearchCriteriaUpdatedText;
