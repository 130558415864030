import { createSlice } from "@reduxjs/toolkit";

import { UserFilesReducerState } from "domains/files/types";

export const userFilesReducerKey = "FileReducerKey";

export const initialState: UserFilesReducerState = { errorMap: {} };

const userFilesSlice = createSlice({
  name: "UserFileDataStore",
  initialState: initialState,
  reducers: {
    addError(state, action): void {
      const { key, msg } = action.payload;
      state.errorMap[key] = msg;
    },
  },
});

export const userFilesActions = userFilesSlice.actions;
export const userFilesReducer = userFilesSlice.reducer;
