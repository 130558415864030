import { Button } from "@doverhq/dover-ui";
import { ReactComponent as MinusIcon } from "@doverhq/dover-ui/icons/minus.svg";
import { ReactComponent as PlusIcon } from "@doverhq/dover-ui/icons/plus.svg";
import { keyframes, Skeleton, Stack, styled } from "@mui/material";
import { atomWithStorage } from "jotai/utils";
import React, { ReactElement, useState } from "react";
import { useBoolean } from "react-use";

import { SetupGuideCTA } from "components/SetupGuide/SetupGuideCTA";
import { OverviewSection } from "components/SetupGuide/SetupGuideOverview";
import { colors } from "styles/theme";

export const seenSetupGuideExtensionAd = atomWithStorage<boolean>("seenSetupGuideExtensionAd", false);

/* -----------------------------------------------------------------------------
 * types
 * -------------------------------------------------------------------------- */

export interface SetupGuideStep {
  name: string;
  content: ReactElement;
  graphic?: ReactElement;
  ctaMaxWidth?: string;
  ctaButton?: ReactElement;
  isComplete: boolean;
  onClick?: () => void;
}

/* -----------------------------------------------------------------------------
 * SetupGuideLayout
 * -------------------------------------------------------------------------- */

const StyledCTASection = styled(Stack)`
  animation-delay: 0.1s;
  animation: ${keyframes`
    0% { top: 100%; }
    100% { top: 0; }
  `} 0.4s ease-in-out forwards;
`;

interface SetupGuideLayoutProps {
  steps: Array<SetupGuideStep>;
  onDismiss: () => void;
}

export const SetupGuideLayout = ({ steps, onDismiss }: SetupGuideLayoutProps): React.ReactElement => {
  const firstIncompleteStep = steps.find(step => !step.isComplete);
  const [selectedStep, setSelectedStep] = useState<SetupGuideStep | undefined>(undefined);

  if (!selectedStep && firstIncompleteStep) {
    setSelectedStep(firstIncompleteStep);
  }

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      borderRadius={"6px"}
      border={`1px solid ${colors.grayscale.gray200}`}
    >
      <OverviewSection steps={steps} selectedStep={selectedStep} setSelectedStep={setSelectedStep} />
      <Stack
        pt={{ xs: 4, sm: 0 }}
        pb={{ xs: 2, sm: 0 }}
        px={2}
        sx={{
          background: "linear-gradient(180deg, #F4F6F8 0%, rgba(244, 246, 248, 0) 100%)",
        }}
        width="100%"
      >
        <StyledCTASection height="100%" width="100%" justifyContent="center" alignItems="center">
          <SetupGuideCTA step={selectedStep} onDismiss={onDismiss} />
        </StyledCTASection>
      </Stack>
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * SetupGuide
 * -------------------------------------------------------------------------- */

export interface SetupGuideProps {
  loading?: boolean;
  heading: ReactElement;
  steps: Array<SetupGuideStep>;
  onDismiss: () => void;
  showHideButton?: boolean;
  isHiddenInitially?: boolean;
  onToggleHide?: () => void;
}

export const SetupGuide = ({
  loading,
  heading,
  steps,
  onDismiss,
  showHideButton,
  isHiddenInitially,
  onToggleHide,
}: SetupGuideProps): ReactElement => {
  const [isHidden, toggleHidden] = useBoolean(isHiddenInitially ?? false);
  if (loading) {
    return (
      <Stack>
        {heading && <Skeleton width="320px" height="36px" />}
        <Skeleton width="100%" height="240px" />
      </Stack>
    );
  }

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderRadius={"6px"}
        p={isHidden ? 1 : 0}
        border={isHidden ? `1px solid ${colors.grayscale.gray200}` : "none"}
        sx={{ backgroundColor: isHidden ? "white" : "transparent" }}
      >
        {heading}
        {showHideButton && (
          <Button
            h={0}
            variant="ghost"
            onPress={(): void => {
              toggleHidden();
              onToggleHide?.();
            }}
            icon={{
              Icon: isHidden ? PlusIcon : MinusIcon,
              color: "gray-600",
              side: "left",
            }}
          >
            {isHidden ? "Show" : "Hide"}
          </Button>
        )}
      </Stack>
      {((showHideButton && !isHidden) || !showHideButton) && <SetupGuideLayout steps={steps} onDismiss={onDismiss} />}
    </Stack>
  );
};
