import { Typography } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

import { useGetOrCreateClientApiQuery } from "services/doverapi/endpoints/clientApiKey";
import { Key } from "views/CompanySetup/components/Credentials/components/Key";
import SourcePage from "views/CompanySetup/components/Credentials/components/SourcePage";
import {
  CredentialsContainer,
  StyledList,
  StyledListItem,
  StyledListItemIcon,
} from "views/CompanySetup/components/Credentials/Styles";

const WaaSPageWrapper = (): React.ReactElement => {
  const { data } = useGetOrCreateClientApiQuery("YC_WAAS");
  const helpDocLink = "https://help.dover.com/en/articles/6649755-connect-yc-work-at-a-startup-to-dover";

  const instructionsComponent = (
    <StyledList>
      <StyledListItem button={false as any}>
        <StyledListItemIcon>
          <Typography>{"💡"}</Typography>
        </StyledListItemIcon>
        <ListItemText
          primary={
            <span>
              Your Work at a Startup key is:
              <br />
              <br />
              <Key value={data?.key!} />
              <br />
              To use this key copy it into WaaS as part of the Dover setup.
              <br />
              <a href={helpDocLink} target="_blank" rel="noreferrer">
                View setup instructions
              </a>
            </span>
          }
        />
      </StyledListItem>
    </StyledList>
  );

  return (
    <CredentialsContainer>
      <SourcePage source="YC_WAAS" instructionsComponent={instructionsComponent} stageComponent="" />
    </CredentialsContainer>
  );
};

export default WaaSPageWrapper;
