import React from "react";

import { RejectionReason } from "contexts/feedback-context/constants";

export enum CandidateFeedbackType {
  Approve = "Approve",
  Reject = "Reject",
}

export interface CandidateFeedbackValues {
  linkedInUrl?: string;
  type: CandidateFeedbackType;
  rejectionReason?: RejectionReason;
}

export type CandidateIDToCandidateFeedbackValues = {
  [key: string]: CandidateFeedbackValues;
};

interface CandidateFeedback {
  logCandidateFeedback: (id: string, feedback: CandidateFeedbackValues) => void;
  resetFeedback: () => void;
  likeCandidate: (id: string, linkedInUrl?: string) => void;
  dislikeCandidate: (id: string, rejectionReason?: RejectionReason, linkedInUrl?: string) => void;
  hasFeedback: boolean;
  numberOfApprovedCandidates: number;
  numberOfRejectedCandidates: number;
  candidateFeedback: CandidateIDToCandidateFeedbackValues;
}

export const CandidateFeedbackContext: React.Context<CandidateFeedback | undefined> = React.createContext<
  CandidateFeedback | undefined
>(undefined);
