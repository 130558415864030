import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React from "react";

import JobSelector from "components/JobSelector/JobSelector";
import DoNotSeeJob from "sections/addcandidate/components/steps/JobSelectionStep/DoNotSeeJobHelpText";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { sortJobsByTitle } from "utils/job";

interface AddCandidateJobSelectorProps {
  jobId?: string;
  setJobId: (jobId: string) => void;
}
export const AddCandidateJobSelector = ({ jobId, setJobId }: AddCandidateJobSelectorProps): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State
  const { activeJobs: jobs } = useGetJobsDerivedData({ sortFunc: sortJobsByTitle });

  return (
    <>
      <Stack direction="column" sx={{ mb: 2, mt: 2 }} alignItems="flex-start" spacing={2}>
        <Box sx={{ width: "100%" }}>
          <JobSelector jobs={jobs ?? []} jobId={jobId} setJobId={setJobId} />
        </Box>
        <DoNotSeeJob />
      </Stack>
    </>
  );
};
