import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BooleanParam, createEnumParam, StringParam, useQueryParam, withDefault } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import CreateJobForm from "components/dover/CreateJob";
import { CREATE_JOB_DRAWER_OPEN_PARAM, CREATE_JOB_CONTEXT_PARAM } from "components/dover/CreateJob/constants";
import Drawer from "components/library/Drawer";
import DoverLoadingOverlay from "components/loading-overlay";
import StepFlow from "components/StepFlow/StepFlow";
import { useCreateJobMutation } from "services/doverapi/endpoints/create-job/endpoints";
import { FormData } from "services/doverapi/endpoints/create-job/types";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { CreateNewJobResponse } from "services/openapi";
import { CreateNewJobRequestCreateJobContextEnum } from "services/openapi/models/CreateNewJobRequest";
import { colors } from "styles/theme";

/* -----------------------------------------------------------------------------
 * CreateJobWrapper
 * -------------------------------------------------------------------------- */

interface JobWrapperProps {
  goNext: () => void;
}

const CreateJobWrapper = (_: JobWrapperProps): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const enumParam = createEnumParam(Object.values(CreateNewJobRequestCreateJobContextEnum));
  const [context] = useQueryParam(CREATE_JOB_CONTEXT_PARAM, enumParam);
  const [createJob, { isLoading: creating }] = useCreateJobMutation();
  const [, setJobIdParam] = useQueryParam("jobId", StringParam);

  const onSubmit = React.useCallback(
    async (values: FormData): Promise<void> => {
      const tryCreateJob = async (): Promise<CreateNewJobResponse> => {
        const response = await createJob({
          values,
          jobPositionId: location?.state?.jobPositionId,
          createJobContext: context ? context : undefined,
        }).unwrap();

        if (response.success && response.jobId) {
          setJobIdParam(response.jobId);
        }

        return response;
      };

      const resp = await tryCreateJob();
      if (resp.success && resp.jobId) {
        const params = new URLSearchParams(location.search);
        params.set("isNewJob", "1");
        params.set(CREATE_JOB_DRAWER_OPEN_PARAM, "0");
        navigate(APP_ROUTE_PATHS.job.jobPosting(resp.jobId, params));
      }
    },
    [context, createJob, location?.state?.jobPositionId, location.search, setJobIdParam, navigate]
  );
  return (
    <DoverLoadingOverlay active={creating}>
      <Stack justifyContent="center" direction="row" p={2}>
        <Box flexGrow={1}>
          <CreateJobForm
            showHiringManagerAndRecruiterFields={true}
            creating={creating}
            initialTitle={location?.state?.jobTitle}
            onSubmit={async (values: FormData): Promise<void> => {
              await onSubmit(values);
            }}
          />
        </Box>
      </Stack>
    </DoverLoadingOverlay>
  );
};

/* -----------------------------------------------------------------------------
 * CreateJobFlow
 * -------------------------------------------------------------------------- */

interface CreateJobFlowProps {
  setCreateJobDrawerOpen: (open?: boolean | null) => void;
}

const CreateJobFlow = React.memo(
  ({ setCreateJobDrawerOpen }: CreateJobFlowProps): ReactElement => {
    return (
      <StepFlow
        hideProgressBar
        title="Add a job"
        steps={[
          {
            component: CreateJobWrapper,
            progressLabel: "Basics",
            stepRoute: "create-job",
          },
        ]}
        onClose={(): void => {
          setCreateJobDrawerOpen(false);
        }}
        bgColor={colors.white}
      />
    );
  }
);

/* -----------------------------------------------------------------------------
 * CreateJob
 * -------------------------------------------------------------------------- */

const CreateJob = (): React.ReactElement => {
  const [createJobDrawerOpen, setCreateJobDrawerOpen] = useQueryParam(
    CREATE_JOB_DRAWER_OPEN_PARAM,
    withDefault(BooleanParam, false)
  );

  const { jobsLoading } = useGetJobsDerivedData({});
  const isLoading = jobsLoading;

  return (
    <Drawer
      open={createJobDrawerOpen}
      onClose={(): void => {
        setCreateJobDrawerOpen(false);
      }}
      anchor="right"
    >
      {isLoading ? (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
          <Progress size="large" />
        </Box>
      ) : (
        <CreateJobFlow setCreateJobDrawerOpen={setCreateJobDrawerOpen} />
      )}
    </Drawer>
  );
};

export default CreateJob;
