/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewQuestionSuggestion,
    InterviewQuestionSuggestionFromJSON,
    InterviewQuestionSuggestionFromJSONTyped,
    InterviewQuestionSuggestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewQuestionSuggestionsResponse
 */
export interface InterviewQuestionSuggestionsResponse {
    /**
     * 
     * @type {Array<InterviewQuestionSuggestion>}
     * @memberof InterviewQuestionSuggestionsResponse
     */
    questions: Array<InterviewQuestionSuggestion>;
}

export function InterviewQuestionSuggestionsResponseFromJSON(json: any): InterviewQuestionSuggestionsResponse {
    return InterviewQuestionSuggestionsResponseFromJSONTyped(json, false);
}

export function InterviewQuestionSuggestionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewQuestionSuggestionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questions': ((json['questions'] as Array<any>).map(InterviewQuestionSuggestionFromJSON)),
    };
}

export function InterviewQuestionSuggestionsResponseToJSON(value?: InterviewQuestionSuggestionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questions': ((value.questions as Array<any>).map(InterviewQuestionSuggestionToJSON)),
    };
}


