/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlimEmailSenderAlias
 */
export interface SlimEmailSenderAlias {
    /**
     * 
     * @type {string}
     * @memberof SlimEmailSenderAlias
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlimEmailSenderAlias
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof SlimEmailSenderAlias
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SlimEmailSenderAlias
     */
    readonly isVirtual?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SlimEmailSenderAlias
     */
    readonly userId?: number;
    /**
     * 
     * @type {string}
     * @memberof SlimEmailSenderAlias
     */
    readonly gmailCredentialAuthState?: SlimEmailSenderAliasGmailCredentialAuthStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum SlimEmailSenderAliasGmailCredentialAuthStateEnum {
    IsShellAccount = 'IS_SHELL_ACCOUNT',
    Valid = 'VALID',
    AuthInvalid = 'AUTH_INVALID',
    NeverAuthed = 'NEVER_AUTHED',
    InvalidAuthCredType = 'INVALID_AUTH_CRED_TYPE'
}

export function SlimEmailSenderAliasFromJSON(json: any): SlimEmailSenderAlias {
    return SlimEmailSenderAliasFromJSONTyped(json, false);
}

export function SlimEmailSenderAliasFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlimEmailSenderAlias {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'isVirtual': !exists(json, 'is_virtual') ? undefined : json['is_virtual'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'gmailCredentialAuthState': !exists(json, 'gmail_credential_auth_state') ? undefined : json['gmail_credential_auth_state'],
    };
}

export function SlimEmailSenderAliasToJSON(value?: SlimEmailSenderAlias | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}


