import { Box } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";

import { BodySmall, Subtitle1 } from "components/library/typography";

// For our purposes here, only h0 and h1 are needed
const heading = props => {
  switch (props.level) {
    case 1:
      return (
        <Box mb={2}>
          <Subtitle1 {...props} />
        </Box>
      );
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return (
        <Box mb={0.5}>
          <BodySmall weight="600" {...props} />
        </Box>
      );

    // default to BodySmall if you try to get a heading of level 0 or 7, as an example
    default:
      return <BodySmall {...props} />;
  }
};

const paragraph = props => (
  <Box mb={2}>
    <BodySmall {...props} />
  </Box>
);

const customRenderers = { heading, paragraph };

export default ({ source }) => <ReactMarkdown source={source} renderers={customRenderers} />;
