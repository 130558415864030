/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseInterviewSubstage
 */
export interface BaseInterviewSubstage {
    /**
     * 
     * @type {string}
     * @memberof BaseInterviewSubstage
     */
    id?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof BaseInterviewSubstage
     */
    possibleInterviewers?: Set<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BaseInterviewSubstage
     */
    addInterviewersToHiringTeam?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof BaseInterviewSubstage
     */
    duration?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BaseInterviewSubstage
     */
    useDoverInterviewer?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BaseInterviewSubstage
     */
    atsSubstageId?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseInterviewSubstage
     */
    atsFeedbackTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseInterviewSubstage
     */
    defaultFeedbackTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseInterviewSubstage
     */
    interviewersRequired?: BaseInterviewSubstageInterviewersRequiredEnum;
    /**
     * 
     * @type {number}
     * @memberof BaseInterviewSubstage
     */
    orderIndex?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseInterviewSubstage
     */
    orderRequired?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BaseInterviewSubstage
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseInterviewSubstage
     */
    isTakeHome?: boolean | null;
    /**
     * 
     * @type {object}
     * @memberof BaseInterviewSubstage
     */
    additionalInfo?: object | null;
}

/**
* @export
* @enum {string}
*/
export enum BaseInterviewSubstageInterviewersRequiredEnum {
    Any = 'ANY',
    All = 'ALL'
}

export function BaseInterviewSubstageFromJSON(json: any): BaseInterviewSubstage {
    return BaseInterviewSubstageFromJSONTyped(json, false);
}

export function BaseInterviewSubstageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseInterviewSubstage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'possibleInterviewers': !exists(json, 'possible_interviewers') ? undefined : json['possible_interviewers'],
        'addInterviewersToHiringTeam': !exists(json, 'add_interviewers_to_hiring_team') ? undefined : json['add_interviewers_to_hiring_team'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'useDoverInterviewer': !exists(json, 'use_dover_interviewer') ? undefined : json['use_dover_interviewer'],
        'atsSubstageId': !exists(json, 'ats_substage_id') ? undefined : json['ats_substage_id'],
        'atsFeedbackTemplateId': !exists(json, 'ats_feedback_template_id') ? undefined : json['ats_feedback_template_id'],
        'defaultFeedbackTemplate': !exists(json, 'default_feedback_template') ? undefined : json['default_feedback_template'],
        'interviewersRequired': !exists(json, 'interviewers_required') ? undefined : json['interviewers_required'],
        'orderIndex': !exists(json, 'order_index') ? undefined : json['order_index'],
        'orderRequired': !exists(json, 'order_required') ? undefined : json['order_required'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isTakeHome': !exists(json, 'is_take_home') ? undefined : json['is_take_home'],
        'additionalInfo': !exists(json, 'additional_info') ? undefined : json['additional_info'],
    };
}

export function BaseInterviewSubstageToJSON(value?: BaseInterviewSubstage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'possible_interviewers': value.possibleInterviewers,
        'add_interviewers_to_hiring_team': value.addInterviewersToHiringTeam,
        'duration': value.duration,
        'use_dover_interviewer': value.useDoverInterviewer,
        'ats_substage_id': value.atsSubstageId,
        'ats_feedback_template_id': value.atsFeedbackTemplateId,
        'default_feedback_template': value.defaultFeedbackTemplate,
        'interviewers_required': value.interviewersRequired,
        'order_index': value.orderIndex,
        'order_required': value.orderRequired,
        'name': value.name,
        'is_take_home': value.isTakeHome,
        'additional_info': value.additionalInfo,
    };
}


