// Design System: https://www.figma.com/file/AGeI1pkDY1sUHAAxwV5Pyk/Design-System?node-id=5%3A51

export const colors = {
  green: "#71CFB3", // our brand green
  darkGreen: "#3BA384",
  hoverDarkGreen: "#2B7861",
  red: "#E06363", // error color
  darkBlue: "#16303D",
  mediumBlue: "#355966",
  highlightBlue: "#93ADF5",
  white: "#fff",
  almostBlack: "#171C1F", // use in place of standard black
  // lightest to darkest
  gray10: "#F0F3F5",
  gray20: "#D8E1E5",
  gray30: "#BCC7CC",
  gray40: "#909DA3",

  baseWhite: "white",
};

const backgrounds = {
  fog: "#F7FAFB",
  blue: "#FAFDFF",
  darkBlue: "#F5FAFF",
  green: "#F5FFF8",
};

// see this reference: https://codepen.io/sosuke/pen/Pjoqqp
const filters = {
  filterGreen:
    "brightness(0) saturate(100%) invert(83%) sepia(14%) saturate(1070%) hue-rotate(109deg) brightness(89%) contrast(86%)",

  filterDarkGreen:
    " brightness(0) saturate(100%) invert(52%) sepia(26%) saturate(897%) hue-rotate(111deg) brightness(100%) contrast(84%)",

  filterRed:
    "brightness(0) saturate(100%) invert(58%) sepia(85%) saturate(3405%) hue-rotate(324deg) brightness(98%) contrast(78%)",

  filterDarkBlue:
    "brightness(0) saturate(100%) invert(13%) sepia(19%) saturate(1733%) hue-rotate(155deg) brightness(94%) contrast(89%)",

  filterGray30:
    "brightness(0) saturate(100%) invert(93%) sepia(10%) saturate(206%) hue-rotate(154deg) brightness(85%) contrast(89%)",

  filterWhite:
    "brightness(0) saturate(100%) invert(97%) sepia(1%) saturate(0%) hue-rotate(209deg) brightness(119%) contrast(100%)",
};

export const headers = {
  h0: {
    fontFamily: "Nunito Sans",
    fontSize: "56px",
    fontWeight: 600,
    lineHeight: "72px",
  },
  h1: {
    fontFamily: "Nunito Sans",
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "56px",
  },
  h2: {
    fontFamily: "Nunito Sans",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "56px",
  },
  h3: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
  },
};

export const bodyText = {
  // named b2 in design system
  b0: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  // named b3 in design system
  b1: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  // named b4 in design system
  b2: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
  },
};

const inputText = {
  // named Input/N2 in design system
  default: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  small: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  // named Input/N2-L in design system
  link: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textDecoration: "underline",
  },
  // named Input/N3 in design system
  bold: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
};

const buttons = {
  primary: {
    color: colors.green,
    background: colors.white,
    width: "auto",
    minWidth: "128px",
    border: `1px solid ${colors.gray10}`,
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease, border 0.1s ease",

    svg: {
      filter: filters.filterGreen,
    },

    "&:hover": {
      background: backgrounds.green,
      border: `1px solid ${colors.green}`,
    },

    "&.active": {
      fontWeight: 600,
      background: backgrounds.green,
      border: `2px solid ${colors.green}`,
    },

    "&:focus": {
      outline: 0,
    },
  },
  filled: {
    color: colors.white,
    background: colors.darkGreen,
    width: "auto",
    minWidth: "128px",
    border: "none",
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease, border 0.1s ease",

    svg: {
      filter: filters.filterWhite,
    },

    "&:hover": {
      background: colors.hoverDarkGreen,
    },

    "&.active": {
      fontWeight: 600,
      background: colors.hoverDarkGreen,
    },

    "&:focus": {
      outline: 0,
    },
  },
  secondary: {
    color: colors.darkBlue,
    background: colors.white,
    width: "auto",
    minWidth: "128px",
    border: `1px solid ${colors.gray10}`,
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease, border 0.1s ease",

    svg: {
      filter: filters.filterDarkBlue,
    },

    "&:hover": {
      background: backgrounds.blue,
      border: `1px solid ${colors.highlightBlue}`,
    },

    "&.active": {
      fontWeight: 600,
      background: "rgba(147, 173, 245, 0.15)",
      border: `1px solid ${colors.highlightBlue}`,
    },

    "&:focus": {
      outline: 0,
    },
  },
  outline: {
    color: colors.mediumBlue,
    background: colors.white,
    width: "auto",
    minWidth: "128px",
    border: `1px solid #BCC7CC`,
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease, border 0.1s ease",

    svg: {
      filter: filters.filterDarkBlue,
    },

    "&:hover": {
      background: backgrounds.blue,
      border: `1px solid ${colors.highlightBlue}`,
    },

    "&.active": {
      fontWeight: 600,
      background: "rgba(147, 173, 245, 0.15)",
      border: `1px solid ${colors.highlightBlue}`,
    },

    "&:focus": {
      outline: 0,
    },
  },
  disabled: {
    color: colors.gray30,
    background: colors.gray10,
    width: "auto",
    minWidth: "128px",
    border: "none",
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    svg: {
      filter: filters.filterGray30,
    },
  },
  error: {
    color: colors.red,
    background: colors.white,
    width: "auto",
    minWidth: "128px",
    border: `1px solid ${colors.red}`,
    borderRadius: "8px",
    padding: "16px 20px",

    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",

    transition: "background 0.1s ease",

    svg: {
      filter: filters.filterRed,
    },

    "&:hover, &.active": {
      color: colors.white,
      background: colors.red,

      svg: {
        filter: filters.filterWhite,
      },
    },

    "&:focus": {
      outline: 0,
    },
  },
  compact: {
    padding: "8px 12px",
    borderRadius: "4px",
  },
};

export const theme = {
  colors: colors,
  headers: headers,
  body: bodyText,
  inputs: inputText,
  backgrounds: backgrounds,
  buttons: buttons,
  filters: filters,
};
