import { Box } from "@mui/material";
import React from "react";

import { StageDrawerFormLabel } from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import { useShouldShowAtsInterviewFeedbackField } from "components/SetupHiringPipeline/hooks";
import AtsInterviewFeedbackField from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/AtsInterviewFeedbackField";

export const EditATSFeedbackForm = ({ fieldPrefix }: { fieldPrefix: string }): React.ReactElement => {
  const showAtsInterviewFeedbackField = useShouldShowAtsInterviewFeedbackField();
  if (!showAtsInterviewFeedbackField) {
    return <></>;
  }
  return (
    <Box>
      <StageDrawerFormLabel bold>Feedback form</StageDrawerFormLabel>
      <AtsInterviewFeedbackField fieldPrefix={fieldPrefix} />
    </Box>
  );
};
