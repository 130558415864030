import { createEntityAdapter, EntityState, SerializedError } from "@reduxjs/toolkit";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { INTERVIEWER, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { ApiApiUpdateInterviewerRequest, ClientInterviewer } from "services/openapi";
import { showErrorToast } from "utils/showToast";

const interviewerAdapter = createEntityAdapter<ClientInterviewer>();

const interviewers = doverApi.injectEndpoints({
  endpoints: build => ({
    getClientInterviewerForUser: build.query<ClientInterviewer, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getClientInterviewerForUser({});
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    updateClientInterviewer: build.mutation<ClientInterviewer, ApiApiUpdateInterviewerRequest>({
      queryFn: async ({ id, data }: ApiApiUpdateInterviewerRequest) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.updateInterviewer({ id, data });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update interviewer settings. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: INTERVIEWER, id: LIST_TAG }] : [];
      },
    }),
    listInterviewers: build.query<EntityState<ClientInterviewer>, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.listInterviewers({ limit: 999 });
          return { data: interviewerAdapter.addMany(interviewerAdapter.getInitialState(), data.results) };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: INTERVIEWER, id: LIST_TAG }] : [];
      },
    }),
    createInterviewer: build.mutation<
      ClientInterviewer,
      {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber?: string;
        client: string;
      }
    >({
      queryFn: async ({ firstName, lastName, email, phoneNumber, client }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const formattedEmail = email.toLowerCase();
          const args = { data: { firstName, lastName, email: formattedEmail, phoneNumber, client } };
          const response = await apiApi.createInterviewer(args);
          return { data: response };
        } catch (error) {
          const userFacingMessage = "Failed to create interviewer. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: INTERVIEWER, id: LIST_TAG }] : [];
      },
    }),
  }),
});

export const {
  useListInterviewersQuery,
  useCreateInterviewerMutation,
  useGetClientInterviewerForUserQuery,
  useUpdateClientInterviewerMutation,
} = interviewers;

export function useListInterviewerEntities(skip?: boolean): ClientInterviewer[] {
  const { data: interviewerEntities } = useListInterviewersQuery(skip ? skipToken : undefined);
  return React.useMemo(() => {
    if (interviewerEntities === undefined) {
      return [];
    }

    return listAllEntities(interviewerEntities);
  }, [interviewerEntities]);
}
