import MuiStep, { StepProps } from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import React, { useMemo } from "react";

import { Body, Subtitle1 } from "components/library/typography";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { AddCandidateStep } from "sections/addcandidate/types";
import { colors } from "styles/theme";

const stepSX = {
  "& .MuiStepLabel-root .Mui-completed": {
    color: colors.primary.base, // circle color (COMPLETED)
  },
  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
    color: colors.white, // Just text label (COMPLETED)
  },
  "& .MuiStepLabel-root .Mui-active": {
    color: colors.grayscale.gray500, // circle color (ACTIVE)
  },
};

interface AddCandidateStepperProps extends StepProps {
  step: AddCandidateStep;
}

const Step = ({ step, ...rest }: AddCandidateStepperProps): React.ReactElement => {
  const { activeStep } = useAddCandidateContext();
  const PostCompletionContent = useMemo(() => {
    const index = rest.index ?? 0;
    const showCompleted = step.PostCompletionContent && index < activeStep;

    return showCompleted ? step.PostCompletionContent : null;
  }, [step.PostCompletionContent, rest.index, activeStep]);

  return (
    <MuiStep {...rest} sx={stepSX}>
      {/* Step label */}
      <StepLabel optional={PostCompletionContent}>
        <Subtitle1>{step.label}</Subtitle1>
      </StepLabel>

      {/* Step content */}
      <StepContent>
        {/* Step description */}
        <Body>{step.description}</Body>

        {/* Step content */}
        {/* Step content should include controls to progress or go back */}
        {step.StepContent}
      </StepContent>
    </MuiStep>
  );
};

export default Step;
