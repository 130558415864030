export const variables = {
  FIRST_NAME: {
    value: "Alice",
    description: "The candidate's first name.",
  },
  DAY_OF_WEEK: {
    value: "<e.g. Tuesday>",
    description: "The day of the week the outreach email is sent.",
  },
  PERSONALIZED_CONTENT: {
    value: "<A personalized message to the candidate populated at send time.>",
    description: "A personalized message to the candidate populated at send time.",
  },
  EMAIL_SENDER_NAME: {
    value: "<your name>",
    description: "The name of the person sending the email.",
  },
};

export const schedulingLinks = ["calendly.com", "goodtime.io"];
