import { QueryDefinition, BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { toast } from "react-toastify";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { GetInterviewRubricResponseRequest } from "services/doverapi/endpoints/candidateInterview";
import { DoverApiError } from "services/doverapi/types";
import { InterviewRubricResponse } from "services/openapi";
import { toastOptions } from "utils/showToast";

type LazyListIRRType = LazyQueryTrigger<
  QueryDefinition<
    GetInterviewRubricResponseRequest,
    BaseQueryFn<void, any, DoverApiError, {}, {}>,
    any,
    InterviewRubricResponse,
    "doverApi"
  >
>;

export const createAndOpenFeedbackForm = async (
  getOrCreateIRR: LazyListIRRType,
  candidateId: string,
  interviewId: string,
  forceCreate: boolean = true
): Promise<void> => {
  const getOrCreatePromise: Promise<InterviewRubricResponse> = getOrCreateIRR({
    candidateId,
    interviewId,
    forceCreate: forceCreate,
  }).unwrap();

  await toast
    .promise(
      getOrCreatePromise,
      {
        pending: `${forceCreate ? "Creating" : "Fetching"} feedback form...`,
        success: `${forceCreate ? "Created" : "Fetched"} feedback form!`,
        error: `Error ${forceCreate ? "creating" : "fetching"} feedback form. Please try again later.`,
      },
      { ...toastOptions }
    )
    .then(newFeedbackForm => {
      if (newFeedbackForm?.id) {
        const link = APP_ROUTE_PATHS.candidateInterview(candidateId, newFeedbackForm.id);
        window.open(link, "_blank", "noopener noreferrer");
      }
    });
};
