import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { CURRENT_FLOW_ID_PARAM_NAME } from "App/routing/constants";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { InboundCriteriaSetupFlow } from "components/dover/InboundCriteriaSetupFlow";
import HideScrollOnWindowsWrapper from "components/HideWindowsScrollbars";
import { useActivateScoringForJobMutation } from "services/doverapi/endpoints/applicationReview";
import {
  useGetUserOnboardingFlowQuery,
  useUpdateUserOnboardingFlowMutation,
} from "services/doverapi/endpoints/user-onboarding-flow/userOnboardingFlow";
import {
  UserOnboardingFlowMostRecentlyCompletedStepEnum,
  UserOnboardingFlowCompletedStepsEnum,
  UserOnboardingFlowOnboardingStateEnum,
  UserOnboardingFlowContentTypeEnum,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

const ApplicantSortingSetupFlow = (): React.ReactElement => {
  // Application level helpers and state
  const navigate = useNavigate();
  const [jobIdParam, setJobIdParam] = useQueryParam("jobId", StringParam);
  const [jobIdCreated, setJobIdCreated] = React.useState<string | undefined>(undefined);
  const createdJobIdRef = React.useRef<string | undefined>(undefined);

  // Use the currentFlowId query param to fetch the user onboarding flow
  const [currentFlowId] = useQueryParam(CURRENT_FLOW_ID_PARAM_NAME, StringParam);
  const { data: userOnboardingFlow } = useGetUserOnboardingFlowQuery(currentFlowId ?? skipToken);
  const [activateScoringForJob] = useActivateScoringForJobMutation();

  // Mutations
  const [updateUserOnboardingFlowMutation] = useUpdateUserOnboardingFlowMutation();

  // Effects
  useEffect(() => {
    // By updating createdJobIdRef when jobIdCreated changes, we can ensure that the created job id is passed through to the update call
    // A state value alone is unreliable because it may not be updated in time for the update call since it is async
    if (jobIdCreated) {
      createdJobIdRef.current = jobIdCreated;
    }
  }, [jobIdCreated]);

  useEffect(() => {
    if (
      userOnboardingFlow?.relatedObjectUuid &&
      userOnboardingFlow.contentType === UserOnboardingFlowContentTypeEnum.Job
    ) {
      setJobIdParam(userOnboardingFlow.relatedObjectUuid);
    }
  }, [userOnboardingFlow, setJobIdParam]);

  // Callbacks
  const handleUpdateUserOnboardingFlow = React.useCallback(async (): Promise<void> => {
    const tryUpdatingUserOnboardingFlowMutation = async (): Promise<void> => {
      if (userOnboardingFlow && userOnboardingFlow.completedSteps && userOnboardingFlow.currentStep) {
        let onboardingState = UserOnboardingFlowOnboardingStateEnum.InProgress;
        if (userOnboardingFlow.isOnLastStep) {
          onboardingState = UserOnboardingFlowOnboardingStateEnum.Completed;
        }
        await updateUserOnboardingFlowMutation({
          userOnboardingFlowId: userOnboardingFlow.id!,
          data: {
            ...userOnboardingFlow,
            completedSteps: [
              ...userOnboardingFlow.completedSteps,
              (userOnboardingFlow.currentStep as unknown) as UserOnboardingFlowCompletedStepsEnum,
            ],
            mostRecentlyCompletedStep: (userOnboardingFlow.currentStep as unknown) as UserOnboardingFlowMostRecentlyCompletedStepEnum,
            onboardingState,
            // If a createdJobId exists, pass it through as the UserOnboardingFlow's relatedObjectUuid
            relatedObjectUuid: createdJobIdRef.current ?? userOnboardingFlow.relatedObjectUuid,
            contentType: createdJobIdRef.current
              ? UserOnboardingFlowContentTypeEnum.Job
              : userOnboardingFlow.contentType,
          },
        });
      }
    };

    await tryUpdatingUserOnboardingFlowMutation();
  }, [userOnboardingFlow, updateUserOnboardingFlowMutation, createdJobIdRef]);

  const onAfterCreateJob = async (jobId: string): Promise<void> => {
    setJobIdCreated(jobId);
    setJobIdParam(jobId);
  };

  const onAfterJobDescription = async (): Promise<void> => {
    // update user onboarding flow
    await handleUpdateUserOnboardingFlow();
  };

  const onAfterCriteria = async (): Promise<void> => {
    // update user onboarding flow
    await handleUpdateUserOnboardingFlow();
  };

  const onAfterCompletion = async (): Promise<void> => {
    const jobId = jobIdParam ?? createdJobIdRef.current;
    if (!jobId) {
      showErrorToast("Job not found, please refresh and try again.");
      return;
    }
    // update user onboarding flow
    await handleUpdateUserOnboardingFlow();
    await activateScoringForJob({ id: jobId, data: { desiredInboundEnrichingEnabled: true } });
    navigate(APP_ROUTE_PATHS.job.applicationReviewV2(jobId));
  };

  return (
    <HideScrollOnWindowsWrapper>
      <InboundCriteriaSetupFlow
        flowTitle="Applicant Sorting"
        jobId={jobIdParam ?? createdJobIdRef.current ?? undefined}
        onAfterCreateJob={onAfterCreateJob}
        onAfterJobDescription={onAfterJobDescription}
        onAfterCriteria={onAfterCriteria}
        onAfterCompletion={onAfterCompletion}
      />
    </HideScrollOnWindowsWrapper>
  );
};

export default ApplicantSortingSetupFlow;
