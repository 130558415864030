import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { JOB_CANDIDATE_SOURCE_SETTING, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import { ApiApiListJobCandidateSourceSettingsRequest, JobCandidateSourceSetting } from "services/openapi";
import { DjangoListResponseType } from "types";
import { showErrorToast } from "utils/showToast";

const jobCandidateSourceSettingAdapter = createEntityAdapter<JobCandidateSourceSetting>();

const jobCandidateSourceSetting = doverApi.injectEndpoints({
  endpoints: build => ({
    listJobCandidateSourceSettings: build.query<
      EntityState<JobCandidateSourceSetting>,
      ApiApiListJobCandidateSourceSettingsRequest
    >({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DjangoListResponseType<JobCandidateSourceSetting>;
        try {
          response = await client.listJobCandidateSourceSettings({ limit: 1000, offset: 0, ...args });
        } catch (error) {
          const userFacingMessage = "Failed to load job candidate source settings. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return {
          data: jobCandidateSourceSettingAdapter.addMany(
            jobCandidateSourceSettingAdapter.getInitialState(),
            response.results
          ),
        };
      },
      providesTags: result => {
        // is result available?
        return result
          ? // successful query
            [
              ...result.ids.map(id => ({ type: JOB_CANDIDATE_SOURCE_SETTING, id } as const)),
              { type: JOB_CANDIDATE_SOURCE_SETTING, id: LIST_TAG },
            ]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: JOB_CANDIDATE_SOURCE_SETTING, id: LIST_TAG }];
      },
    }),
  }),
});

export const { useListJobCandidateSourceSettingsQuery } = jobCandidateSourceSetting;
