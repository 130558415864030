/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

const AutocompletePaperComponent: React.FC = ({ children }) => {
  return <Paper style={{ minWidth: "fit-content" }}>{children}</Paper>;
};

import { Paper } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useState, useEffect } from "react";

import { DoverUser } from "services/openapi/models";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";
import { StyledTextField } from "views/job/JobSetup/steps/CampaignVariants/styles";

interface ProUserOption extends DoverUser {
  inputValue?: string;
}

const filter = createFilterOptions<ProUserOption>();

interface PassedProps {
  initialProUser: number | undefined;
  proUsers: DoverUser[];
  setSelectedProUser: (selectedProUser: number | undefined) => void;
  handleOpenProUserModal: () => void;
}

const ProUserSelect = ({
  initialProUser,
  proUsers,
  setSelectedProUser,
  handleOpenProUserModal,
}: PassedProps): React.ReactElement => {
  const [value, setValue] = useState<ProUserOption | null>(null);

  useEffect((): void => {
    const proUserId = initialProUser;
    if (!value && proUserId) {
      setValue(proUsers.find((pu: DoverUser): boolean => pu.pk === proUserId) ?? null);
    }
  }, [proUsers]);

  return (
    <Autocomplete
      fullWidth
      value={value}
      onChange={(event, newValue): void => {
        if (
          typeof newValue === "string" ||
          (newValue && newValue.inputValue) ||
          newValue?.fullName == "Add a new user +"
        ) {
          handleOpenProUserModal();
        } else {
          setValue(newValue);
          setSelectedProUser(newValue?.pk ?? undefined);
        }
      }}
      PaperComponent={AutocompletePaperComponent}
      selectOnFocus
      handleHomeEndKeys
      filterOptions={(options, params): any => {
        const filtered = filter(options, params);

        filtered.push({
          inputValue: params.inputValue,
          email: "",
          fullName: `Add a new user +`,
        } as ProUserOption);

        return filtered;
      }}
      options={(proUsers as ProUserOption[]) || []}
      getOptionLabel={(option): string => {
        option = option as ProUserOption;
        return option.fullName! || option.email!;
      }}
      renderOption={(props, option): React.ReactElement => (
        // @ts-ignore
        <li
          style={{
            color: option.fullName == "Add a new user +" || option.inputValue ? colors.informational.base : "inherit",
          }}
          {...props}
          key={option.pk ?? option.firstName! + option.lastName! + option.email}
        >
          <B2_doNotUse>{`${option.fullName || ""}`}</B2_doNotUse>
        </li>
      )}
      freeSolo
      renderInput={(params): React.ReactElement => <StyledTextField {...params} size="small" />}
    />
  );
};

export default ProUserSelect;
