import { useMemo } from "react";

import useUsersWhoUploadedConnections from "services/doverapi/cross-endpoint-hooks/useUsersWhoUploadedConnections";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { ClientUserFileStats, UserUploadedFileUploadedContextEnum } from "services/openapi";

const useDidAuthedUserUploadFile = (uploadedContext: UserUploadedFileUploadedContextEnum): boolean | undefined => {
  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const usersWhoUploadedConnections = useUsersWhoUploadedConnections(uploadedContext);

  return useMemo(() => {
    if (!userInfo || !usersWhoUploadedConnections) {
      return undefined;
    }

    return usersWhoUploadedConnections.some(
      (userStats: ClientUserFileStats): boolean => userInfo?.email === userStats.user?.email
    );
  }, [usersWhoUploadedConnections, userInfo]);
};

export default useDidAuthedUserUploadFile;
