import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Box, Chip, Stack } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import ResumeIcon from "assets/icons/resume-icon-grey.svg";
import { StyledListItem } from "components/dover/inputs/pro-users/styles";
import { ControlledAutocomplete } from "components/library/Autocomplete";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, Overline } from "components/library/typography";
import YOESlider, { YearsOfExperienceRange } from "components/library/YOESlider";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useOpenApiClients } from "hooks/openApiClients";
import { useListFieldsOfStudySaaPQuery, useListEducationLevelsQuery } from "services/doverapi/endpoints/education";
import { useGetDoversTargetSchoolLists } from "services/doverapi/endpoints/search-v3/customHooks";
import { SchoolListWithRequiredID } from "services/doverapi/endpoints/search-v3/types";
import { EducationLevelsResponse, School } from "services/openapi/models";
import { colors } from "styles/theme";
import AdvancedAccordion from "views/sourcing/Search/components/AdvancedDropdown";
import FilterAccordion from "views/sourcing/Search/components/FilterAccordion";
import FilterSectionHeader from "views/sourcing/Search/components/FilterSectionHeader";
import RequiredToggle from "views/sourcing/Search/components/RequiredToggle";
import { EditSchoolListsModal, SaveNewSchoolListModal } from "views/sourcing/Search/components/SaveAsListModal";
import TitleWithRequiredLabel from "views/sourcing/Search/components/TitleWithRequiredLabel";
import { CHIP_MAX_WIDTH, TARGET_SCHOOLS_NAME } from "views/sourcing/Search/constants";
import {
  EDUCATION_LEVELS_ELEMENTS_NAME,
  EDUCATION_LEVELS_NAME,
  FIELDS_OF_STUDY_ELEMENTS_NAME,
  FIELDS_OF_STUDY_NAME,
  INDIVIDUAL_EXCLUDED_SCHOOLS_NAME,
  INDIVIDUAL_SCHOOLS_NAME,
  SCHOOL_RANK_RANGE_NAME,
  previewStateMarginBottom,
  previewStateMarginRight,
  sliderPaddingRight,
  TARGET_SCHOOL_LISTS_NAME,
  FiltersContainerID,
  SCHOOL_TIER_TO_PERCENTILE_EXPLAINER,
} from "views/sourcing/Search/constants";
import { useGetSearchFromUrl } from "views/sourcing/Search/hooks";
import { SearchV3FormSchemaType, TitleContentProps } from "views/sourcing/Search/types";
import { SCHOOL_TIER_MAX } from "views/sourcing/Search/utils";

export interface DisplayedFieldOfStudy {
  id: string;
  name: string;
  parentName: string; // if this is a non-empty string, means it is a Tier2 with a Tier1 as parent
}

const FieldsOfStudyFilterTitleContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const fieldsOfStudy = useWatch({ control, name: FIELDS_OF_STUDY_NAME });
    const degree = useWatch({ control, name: EDUCATION_LEVELS_ELEMENTS_NAME });

    const onFieldsOfStudyChipDelete = React.useCallback(
      (value: DisplayedFieldOfStudy) => {
        setValue(FIELDS_OF_STUDY_NAME, {
          selectedFieldsOfStudy: fieldsOfStudy.selectedFieldsOfStudy.filter(
            fieldsOfStudy => fieldsOfStudy.name !== value.name
          ),
          required: fieldsOfStudy.required,
        });
      },
      [fieldsOfStudy, setValue]
    );

    const fieldsOfStudyChips = React.useMemo(() => {
      if (fieldsOfStudy === undefined || fieldsOfStudy.selectedFieldsOfStudy.length === 0) {
        return <></>;
      }

      return (
        <>
          {fieldsOfStudy.selectedFieldsOfStudy.map(fieldOfStudy => {
            return (
              <Chip
                label={<TextWithMaxWidth label={fieldOfStudy.name} width={CHIP_MAX_WIDTH} />}
                key={fieldOfStudy.name}
                deleteIcon={<HighlightOffSharpIcon />}
                onDelete={(): void => onFieldsOfStudyChipDelete(fieldOfStudy)}
                sx={{
                  mr: previewStateMarginRight,
                  mb: previewStateMarginBottom,
                }}
              />
            );
          })}
        </>
      );
    }, [fieldsOfStudy, onFieldsOfStudyChipDelete]);

    const onDegreeChipDelete = React.useCallback(() => {
      setValue(EDUCATION_LEVELS_ELEMENTS_NAME, null);
    }, [setValue]);

    const degreeChip = React.useMemo(() => {
      if (!degree) {
        return <></>;
      }

      return (
        <Box>
          <Chip
            label={<TextWithMaxWidth label={degree.educationLevel} width={CHIP_MAX_WIDTH} />}
            deleteIcon={<HighlightOffSharpIcon />}
            onDelete={(): void => onDegreeChipDelete()}
            sx={{
              mr: previewStateMarginRight,
              mb: previewStateMarginBottom,
            }}
          />
        </Box>
      );
    }, [degree, onDegreeChipDelete]);

    if (expanded) {
      return <></>;
    }

    return (
      <Box>
        {fieldsOfStudyChips}
        {degreeChip}
      </Box>
    );
  }
);

const FieldsOfStudyFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const fieldsOfStudy = useWatch({ control, name: FIELDS_OF_STUDY_NAME });
    const { data: supportedFieldsOfStudy } = useListFieldsOfStudySaaPQuery({ limit: 100 });

    const fieldsOfStudyRequiredToggle = React.useMemo(() => {
      return (
        <RequiredToggle
          required={fieldsOfStudy.required}
          onChange={(required: boolean): void => {
            setValue(FIELDS_OF_STUDY_NAME, { selectedFieldsOfStudy: fieldsOfStudy.selectedFieldsOfStudy, required });
          }}
        />
      );
    }, [fieldsOfStudy.selectedFieldsOfStudy, fieldsOfStudy.required, setValue]);

    const selectedFieldsOfStudyNames = React.useMemo(() => {
      return fieldsOfStudy.selectedFieldsOfStudy.map(fieldOfStudy => fieldOfStudy.name);
    }, [fieldsOfStudy]);

    if (!supportedFieldsOfStudy) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Stack spacing={1}>
        <ControlledAutocomplete
          control={control}
          fontSize="small"
          title="Field Of Study"
          placeholder={"Enter field of study..."}
          staticOptions={supportedFieldsOfStudy}
          name={FIELDS_OF_STUDY_ELEMENTS_NAME}
          getOptionLabel={(option: DisplayedFieldOfStudy): string => option.name}
          filterSelectedOptions={false}
          getOptionDisabled={(option: DisplayedFieldOfStudy): boolean =>
            selectedFieldsOfStudyNames.includes(option.parentName) || selectedFieldsOfStudyNames.includes(option.name)
          }
          isOptionEqualToValue={(option: DisplayedFieldOfStudy, value: DisplayedFieldOfStudy): boolean =>
            option.id === value.id
          }
          initialValues={fieldsOfStudy.selectedFieldsOfStudy ?? []}
          multiple={true}
          sortByField={"name"}
          renderOption={(props, option: DisplayedFieldOfStudy): React.ReactElement => {
            if (option.parentName) {
              return (
                // note: key prop must be declared last so that MUI's props.key (getOptionLabel) does
                // not override provided key
                // @ts-ignore
                <StyledListItem {...props} key={option.name}>
                  <Stack marginLeft="15px">
                    <BodySmall>{option.name}</BodySmall>
                  </Stack>
                </StyledListItem>
              );
            }
            return (
              // @ts-ignore
              <StyledListItem {...props} key={option.name}>
                <Body>{option.name}</Body>
              </StyledListItem>
            );
          }}
        />
        {fieldsOfStudyRequiredToggle}
      </Stack>
    );
  }
);

const EducationLevelFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const educationLevelInControl = useWatch({ control, name: EDUCATION_LEVELS_NAME });
    const { data: educationLevels } = useListEducationLevelsQuery();

    const educationLevelsRequiredToggle = React.useMemo(() => {
      return (
        <RequiredToggle
          required={educationLevelInControl.required}
          onChange={(required: boolean): void => {
            setValue(EDUCATION_LEVELS_NAME, {
              selectedEducationLevel: educationLevelInControl.selectedEducationLevel,
              required,
            });
          }}
        />
      );
    }, [educationLevelInControl.selectedEducationLevel, educationLevelInControl.required, setValue]);

    if (!educationLevels) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Stack spacing={1}>
        <ControlledAutocomplete
          control={control}
          fontSize="small"
          title="Highest degree earned"
          placeholder={"Enter education level..."}
          staticOptions={educationLevels}
          isOptionEqualToValue={(option: EducationLevelsResponse, value: EducationLevelsResponse): boolean =>
            option.educationLevel === value.educationLevel
          }
          filterSelectedOptions={true}
          name={EDUCATION_LEVELS_ELEMENTS_NAME}
          getOptionLabel={(option: EducationLevelsResponse): string => option.educationLevel}
          initialValue={educationLevelInControl.selectedEducationLevel ?? null}
        />
        {educationLevelsRequiredToggle}
      </Stack>
    );
  }
);

interface IndividualSchoolsFiltersContentProps {
  toggleSchoolListModalOn: () => void;
}

const IndividualSchoolsFiltersContent = React.memo(
  ({ toggleSchoolListModalOn }: IndividualSchoolsFiltersContentProps): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const individualSchoolsInControl = useWatch({ control, name: INDIVIDUAL_SCHOOLS_NAME });

    const client = useOpenApiClients()?.apiApi;
    const fetchIndividualSchools = async (request: string): Promise<School[]> => {
      if (client === undefined) {
        return [];
      }

      const schools = await client.listSimilarSchools({ limit: 50, fullTextSearch: request });
      return schools.results;
    };

    const search = useGetSearchFromUrl();

    if (search === undefined) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <Stack spacing={1} alignItems="start">
        <ControlledAutocomplete
          control={control}
          title="Specific Schools"
          fontSize="small"
          placeholder={"Enter specific schools..."}
          noOptionsText={"Start typing to see schools"}
          fetch={fetchIndividualSchools}
          filterSelectedOptions={true}
          name={INDIVIDUAL_SCHOOLS_NAME}
          getOptionLabel={(option: School): string => option.name}
          initialValues={individualSchoolsInControl ?? []}
          multiple={true}
          sortByField={"name"}
        />
        <Button variant={ButtonVariant.Secondary} removeOutline onClick={toggleSchoolListModalOn}>
          <Overline color={colors.grayscale.gray600}>{"Save as list"}</Overline>
        </Button>
      </Stack>
    );
  }
);

const IndividualSchoolsExclusionsFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const individualExcludedSchoolsInControl = useWatch({ control, name: INDIVIDUAL_EXCLUDED_SCHOOLS_NAME });

    const client = useOpenApiClients()?.apiApi;
    const fetchIndividualSchools = async (request: string): Promise<School[]> => {
      if (client === undefined) {
        return [];
      }

      const schools = await client.listSimilarSchools({ limit: 50, fullTextSearch: request });
      return schools.results;
    };

    const search = useGetSearchFromUrl();

    if (search === undefined) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <ControlledAutocomplete
        control={control}
        title={"Exclude Specific Schools"}
        fontSize="small"
        placeholder={"Enter specific schools..."}
        noOptionsText={"Start typing to see schools"}
        fetch={fetchIndividualSchools}
        filterSelectedOptions={true}
        name={INDIVIDUAL_EXCLUDED_SCHOOLS_NAME}
        getOptionLabel={(option: School): string => option.name}
        initialValues={individualExcludedSchoolsInControl ?? []}
        multiple={true}
        sortByField={"name"}
      />
    );
  }
);

const EducationFiltersAdvancedContent = React.memo(
  (): React.ReactElement => {
    return (
      <Stack spacing={1} marginTop="5px">
        <IndividualSchoolsExclusionsFiltersContent />
      </Stack>
    );
  }
);

const IndividualSchoolsAndExclusionsFilterContent = React.memo(
  (): React.ReactElement => {
    return (
      <Stack spacing={1}>
        <AdvancedAccordion title="Advanced" expandedContent={<EducationFiltersAdvancedContent />} />
      </Stack>
    );
  }
);

const AllSchoolsFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const targetSchools = useWatch({ control, name: TARGET_SCHOOLS_NAME });

    const [newSchoolListModalOpen, setNewSchoolListModalOpen] = React.useState<boolean>(false);
    const [editSchoolListModalOpen, setEditSchoolListModalOpen] = React.useState<boolean>(false);

    const toggleNewSchoolListModalOn = React.useCallback(() => {
      setNewSchoolListModalOpen(true);
    }, []);

    const toggleNewSchoolListModalOff = React.useCallback(() => {
      setNewSchoolListModalOpen(false);
    }, []);

    const toggleEditSchoolListModalOn = React.useCallback(() => {
      setEditSchoolListModalOpen(true);
    }, []);

    const toggleEditSchoolListModalOff = React.useCallback(() => {
      setEditSchoolListModalOpen(false);
    }, []);

    return (
      <>
        <Stack spacing={2}>
          <IndividualSchoolsFiltersContent toggleSchoolListModalOn={toggleNewSchoolListModalOn} />
          <SchoolListsFiltersContent toggleEditSchoolListModalOn={toggleEditSchoolListModalOn} />
          <RequiredToggle
            required={targetSchools.required}
            onChange={(required: boolean): void => {
              setValue(TARGET_SCHOOLS_NAME, {
                individualSchools: targetSchools.individualSchools,
                required,
              });
            }}
          />
          <IndividualSchoolsAndExclusionsFilterContent />
        </Stack>
        <SaveNewSchoolListModal isModalOpen={newSchoolListModalOpen} toggleModalOff={toggleNewSchoolListModalOff} />
        <EditSchoolListsModal
          isModalOpen={editSchoolListModalOpen}
          toggleModalOff={toggleEditSchoolListModalOff}
          toggleNewSchoolModalOn={toggleNewSchoolListModalOn}
        />
      </>
    );
  }
);

const SchoolListsFilterTitleContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const targetSchoolLists = useWatch({ control, name: TARGET_SCHOOL_LISTS_NAME });
    const individualSchoolsInControl = useWatch({ control, name: INDIVIDUAL_SCHOOLS_NAME });

    const onChipDelete = React.useCallback(
      (value: SchoolListWithRequiredID | School) => {
        setValue(
          TARGET_SCHOOL_LISTS_NAME,
          targetSchoolLists.filter(schoolList => schoolList.name !== value.name)
        );
        setValue(
          INDIVIDUAL_SCHOOLS_NAME,
          individualSchoolsInControl.filter(school => school.name !== value.name)
        );
      },
      [setValue, targetSchoolLists, individualSchoolsInControl]
    );

    if (
      expanded ||
      targetSchoolLists === undefined ||
      (targetSchoolLists.length === 0 && individualSchoolsInControl.length === 0)
    ) {
      return <></>;
    }

    return (
      <Box>
        {targetSchoolLists?.map(schoolList => {
          return (
            <Chip
              label={<TextWithMaxWidth label={schoolList.name} width={CHIP_MAX_WIDTH} />}
              key={schoolList.name}
              deleteIcon={<HighlightOffSharpIcon />}
              onDelete={(): void => onChipDelete(schoolList)}
              sx={{
                mr: previewStateMarginRight,
                mb: previewStateMarginBottom,
              }}
            />
          );
        })}
        {individualSchoolsInControl.map(school => {
          return (
            <Chip
              label={<TextWithMaxWidth label={school.name} width={CHIP_MAX_WIDTH} />}
              key={school.name}
              deleteIcon={<HighlightOffSharpIcon />}
              onDelete={(): void => onChipDelete(school)}
              sx={{
                mr: previewStateMarginRight,
                mb: previewStateMarginBottom,
              }}
            />
          );
        })}
      </Box>
    );
  }
);
interface SchoolListsFiltersContentProps {
  toggleEditSchoolListModalOn: () => void;
}

const SchoolListsFiltersContent = React.memo(
  ({ toggleEditSchoolListModalOn }: SchoolListsFiltersContentProps): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const targetSchoolListsInControl = useWatch({ control, name: TARGET_SCHOOL_LISTS_NAME });
    const { data: doverSchoolListsOptions } = useGetDoversTargetSchoolLists();

    if (!doverSchoolListsOptions) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }

    return (
      <>
        <Stack spacing={1}>
          <ControlledAutocomplete
            control={control}
            title={
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <BodySmall weight="500">Lists</BodySmall>
                <Stack
                  sx={{
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <BodySmall weight="500" color={colors.link} onClick={toggleEditSchoolListModalOn}>
                    Edit
                  </BodySmall>
                </Stack>
              </Stack>
            }
            fontSize="small"
            placeholder={"Enter a school list..."}
            staticOptions={doverSchoolListsOptions}
            name={TARGET_SCHOOL_LISTS_NAME}
            filterSelectedOptions={true}
            getOptionLabel={(option: School): string => option.name}
            initialValues={targetSchoolListsInControl ?? []}
            multiple={true}
            sortByField={"name"}
          />
        </Stack>
      </>
    );
  }
);

const DegreeFilterContent = React.memo(
  (): React.ReactElement => {
    return (
      <Stack spacing={2}>
        <FieldsOfStudyFiltersContent />
        <EducationLevelFiltersContent />
      </Stack>
    );
  }
);

const SchoolPrestigeTitleContent = React.memo(
  ({ expanded }: TitleContentProps): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const schoolRankRange = useWatch({ control, name: SCHOOL_RANK_RANGE_NAME });

    const tierLabelText = React.useMemo(() => {
      let label = "";
      if (schoolRankRange.min) {
        label = `Top ${SCHOOL_TIER_TO_PERCENTILE_EXPLAINER[schoolRankRange.min]}`;
        if (schoolRankRange.max !== undefined && schoolRankRange.max !== SCHOOL_TIER_MAX) {
          label += ` (excluding top ${SCHOOL_TIER_TO_PERCENTILE_EXPLAINER[schoolRankRange.max]})`;
        }
      } else if (schoolRankRange.max !== undefined && schoolRankRange.max !== SCHOOL_TIER_MAX) {
        label = `Excluding top ${SCHOOL_TIER_TO_PERCENTILE_EXPLAINER[schoolRankRange.max]}`;
      }
      return label;
    }, [schoolRankRange.max, schoolRankRange.min]);

    if (expanded || !tierLabelText.length) {
      return <></>;
    }

    return (
      <Box>
        <Chip
          label={<TextWithMaxWidth label={tierLabelText} width={CHIP_MAX_WIDTH} />}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      </Box>
    );
  }
);

const SchoolPrestigeFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const schoolRankRange = useWatch({ control, name: SCHOOL_RANK_RANGE_NAME });

    const handleSpecificYOESliderChange = React.useCallback(
      (value: YearsOfExperienceRange) => {
        setValue(SCHOOL_RANK_RANGE_NAME, {
          min: value.min ?? 0,
          max: value.max ?? SCHOOL_TIER_MAX,
        });
      },
      [setValue]
    );

    const tierLabel = React.useMemo(() => {
      let label = "No Thresholds Selected";
      if (schoolRankRange.min !== undefined && schoolRankRange.min !== 0) {
        label = `Top ${SCHOOL_TIER_TO_PERCENTILE_EXPLAINER[schoolRankRange.min]}`;
        if (schoolRankRange.max !== undefined && schoolRankRange.max !== SCHOOL_TIER_MAX) {
          label += ` (excluding top ${SCHOOL_TIER_TO_PERCENTILE_EXPLAINER[schoolRankRange.max]})`;
        }
      } else if (schoolRankRange.max !== undefined && schoolRankRange.max !== SCHOOL_TIER_MAX) {
        label = `Excluding top ${SCHOOL_TIER_TO_PERCENTILE_EXPLAINER[schoolRankRange.max]}`;
      }

      return (
        <Box padding="10px" width="fit-content" sx={{ backgroundColor: colors.grayscale.gray100 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <BodySmall>{label}</BodySmall>
          </Stack>
        </Box>
      );
    }, [schoolRankRange.max, schoolRankRange.min]);

    return (
      <Stack spacing={2} paddingRight={sliderPaddingRight}>
        <YOESlider
          values={schoolRankRange}
          onChange={handleSpecificYOESliderChange}
          maximumValue={SCHOOL_TIER_MAX}
          minimumRange={1}
          marks={true}
          shouldShowTooltip={false}
          scrollContainerId={FiltersContainerID}
        />
        {tierLabel}
      </Stack>
    );
  }
);

const EducationFilters = React.memo(
  (): React.ReactElement => {
    const [degreeExpanded, setDegreeExpanded] = React.useState<boolean>(false);
    const [targetSchoolsExpanded, setTargetSchoolsExpanded] = React.useState<boolean>(false);

    return (
      <>
        <FilterSectionHeader title={"Education"} icon={ResumeIcon} />
        <FilterAccordion
          title={
            <TitleWithRequiredLabel
              mainTitle={"Prestige"}
              tooltipText="Dover’s estimation of educational prestige based on algorithmic signals"
            />
          }
          TitleContent={SchoolPrestigeTitleContent}
          expandedContent={<SchoolPrestigeFiltersContent />}
        />
        <FilterAccordion
          title={
            <TitleWithRequiredLabel
              mainTitle={"Target Schools"}
              requiredFieldName={TARGET_SCHOOLS_NAME}
              expanded={targetSchoolsExpanded}
            />
          }
          TitleContent={SchoolListsFilterTitleContent}
          expandedContent={<AllSchoolsFiltersContent />}
          expandedCallback={setTargetSchoolsExpanded}
        />
        <FilterAccordion
          title={
            <TitleWithRequiredLabel
              requiredFieldName={FIELDS_OF_STUDY_NAME}
              mainTitle={"Degree"}
              expanded={degreeExpanded}
            />
          }
          TitleContent={FieldsOfStudyFilterTitleContent}
          expandedContent={<DegreeFilterContent />}
          expandedCallback={setDegreeExpanded}
        />
      </>
    );
  }
);

export default EducationFilters;
