import { Box } from "@mui/material";
import React from "react";

import { Body } from "components/library/typography";
import { ClientOnboardingAtsTypeEnum, ClientOnboardingSetupAtsStateEnum } from "services/openapi";
import { SuccessText } from "views/CompanySetup/components/AtsSettings/styles";

export const OtherAtsComponent = ({
  setupAtsState,
  atsType,
}: {
  setupAtsState: ClientOnboardingSetupAtsStateEnum | undefined;
  atsType: ClientOnboardingAtsTypeEnum | undefined;
}): React.ReactElement => {
  const switchedFromNoAts = atsType === ClientOnboardingAtsTypeEnum.NoAts;

  if (setupAtsState === ClientOnboardingSetupAtsStateEnum.Complete && !switchedFromNoAts) {
    return (
      <Box width="500px">
        <SuccessText>Reach out to customersupport@dover.com if you have questions!</SuccessText>
      </Box>
    );
  }
  return (
    <Box width="500px">
      <Body>
        {
          "If you'd like to discuss support for an alternative ATS, please reach out to customersupport@dover.com for more information."
        }
      </Body>
    </Box>
  );
};
