import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { FAKE_ID, LIST_TAG, USER_CREDENTIAL } from "services/doverapi/endpointTagsConstants";
import {
  ApiV1SubmitUserCredentialCredentials,
  SubmitUserCredentialRequestSourceEnum,
  UserCredential,
} from "services/openapi";
import { DjangoListResponseType } from "types";
import { showErrorToast } from "utils/showToast";

const userCredentialAdapter = createEntityAdapter<UserCredential>();

const userCredentialEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listUserCredentials: build.query<EntityState<UserCredential>, void>({
      queryFn: async () => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DjangoListResponseType<UserCredential>;
        try {
          response = await client.listUserCredentials({});
        } catch (error) {
          const userFacingMessage = "Failed to load credentials. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return {
          data: userCredentialAdapter.addMany(userCredentialAdapter.getInitialState(), response.results),
        };
      },
      providesTags: result => {
        return result
          ? [...result.ids.map(id => ({ type: USER_CREDENTIAL, id } as const)), { type: USER_CREDENTIAL, id: LIST_TAG }]
          : [{ type: USER_CREDENTIAL, id: LIST_TAG }];
      },
    }),
    submitUserCredential: build.mutation<
      string,
      {
        source: SubmitUserCredentialRequestSourceEnum;
        username?: string;
        credentials: ApiV1SubmitUserCredentialCredentials;
      }
    >({
      queryFn: async ({ source, username, credentials }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let nextStage: string;
        try {
          const result = await client.submitUserCredential({ data: { source, credentials, username } });
          nextStage = result.nextStage as string;
        } catch (error) {
          let userFacingMessage = "Failed to submit credentials. Please refresh and try again.";
          if (error.status === 400) {
            userFacingMessage = error.error;
          }
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data: nextStage };
      },
      invalidatesTags: () => {
        return [
          { type: USER_CREDENTIAL, id: LIST_TAG },
          { type: USER_CREDENTIAL, id: FAKE_ID },
        ];
      },
    }),
  }),
});

export const { useListUserCredentialsQuery, useSubmitUserCredentialMutation } = userCredentialEndpoints;
