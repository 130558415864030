import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import GreenCheckIcon from "assets/icons/simple-green-check.svg";
import { SOURCING_AUTOPILOT_HELP_ARTICLE } from "components/dover/constants";
import { ExternalLink } from "components/dover/LinkedInConnectionsUpload/styles";
import { SourcingAutopilotContent } from "components/dover/SourcingAutopilotModal/SourcingAutopilotContent";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";
import {
  useGetDoverOutboundConfigurationQuery,
  useUpdateDoverOutboundConfigurationMutation,
} from "services/doverapi/endpoints/jobFulfillment";
import { colors } from "styles/theme";

const StyledGreenCheckSVG = styled(ReactSVG)`
  margin-bottom: 3px;
`;

export const SourcingAutopilotModal = ({
  isModalOpen,
  onModalClose,
  jobId,
  showPauseButton,
  onPause,
}: {
  isModalOpen: boolean;
  onModalClose: () => void;
  jobId?: string;
  showPauseButton?: boolean;
  onPause?: () => void;
}): React.ReactElement => {
  const { jobId: paramJobId } = useParams<{ jobId: string }>();

  const bestJobId = jobId || paramJobId;

  const { data: outboundConfig, isLoading: loadingOutboundConfig } = useGetDoverOutboundConfigurationQuery(
    bestJobId || skipToken
  );

  const [updateOutboundConfig, { isLoading: updatingAutoOutreach }] = useUpdateDoverOutboundConfigurationMutation();

  const [selectedAutopilotOption, setSelectedAutopilotOption] = React.useState<boolean | undefined>(
    outboundConfig?.autoQueueOutreach as boolean | undefined
  );

  const handleSubmit = React.useCallback(async () => {
    if (bestJobId) {
      await updateOutboundConfig({ jobId: bestJobId, autoQueueOutreach: selectedAutopilotOption }).unwrap();
      onModalClose();
    }
  }, [updateOutboundConfig, onModalClose, bestJobId, selectedAutopilotOption]);

  const modalTitle = (
    <Stack alignItems="center" direction="row" justifyContent="center" spacing={1.5}>
      <StyledGreenCheckSVG src={GreenCheckIcon} />
      <Body color={colors.grayscale.gray600}>Configure Autosend</Body>
    </Stack>
  );

  return (
    <CustomModal
      title={modalTitle}
      showTitleSpacer={false}
      open={isModalOpen}
      onClose={(): void => {
        onModalClose();
      }}
      hideCloseButton={true}
      maxWidth={"sm"}
      dialogActions={
        <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center" style={{ padding: "12px" }}>
          <Stack alignItems="center">
            <ExternalLink href={SOURCING_AUTOPILOT_HELP_ARTICLE} target="_blank" rel="noopener noreferrer">
              Learn more
            </ExternalLink>
          </Stack>
          <Button variant={ButtonVariant.Secondary} onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            disabled={loadingOutboundConfig || updatingAutoOutreach}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2} alignItems="center" marginTop={1}>
        <SourcingAutopilotContent
          selectedAutopilotOption={selectedAutopilotOption}
          setSelectedAutopilotOption={setSelectedAutopilotOption}
          showPauseButton={showPauseButton}
          onPause={onPause}
          jobId={jobId}
        />
      </Stack>
    </CustomModal>
  );
};
