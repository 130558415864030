import styled from "styled-components";

import { colors } from "components/data-driven-forms/styles/theme";

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const FormBodyWrapper = styled.div`
  width: 75%;
  min-width: 800px;
  max-width: 1200px;
  height: 100%;
  padding: 64px;
  padding-top: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 0 24px ${colors.gray10};
  overflow: auto;

  @media (max-width: 1008px) {
    width: 100%;
    min-width: auto;
  }
`;
// (davin) this wrapper conditionally allows the Calibration step
// to take up more horizontal space than the other form steps
interface CalibrationWrapperProps {
  isCalibrationStep: boolean;
}

export const CalibrationWrapper = styled.div<CalibrationWrapperProps>`
  padding-right: ${(props): any => (props.isCalibrationStep ? "32px" : "64px")};
  max-width: ${(props): any => (props.isCalibrationStep ? "none" : "800px")};
`;

export const FormSidebarWrapper = styled.div`
  width: auto;
  padding: 32px;
  padding-top: 64px;
  overflow: auto;

  /* hide right sidebar on small screens */
  @media (max-width: 1008px) {
    display: none;
  }
`;

export const PageHeader = styled.div`
  margin-bottom: 30px;
`;

export const Divider = styled.div`
  background: ${colors.gray10};
  height: 1px;
  width: 100%;
  margin-top: 24px;
`;

// (davin) this was spec'd to be margin-bottom: 80px but I thought that
// was too much
export const FieldWrapper = styled.div`
  margin-bottom: 48px;
`;
