import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Box, Checkbox, Chip, Stack } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import PenToolIcon from "assets/icons/pen-tool-grey.svg";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Caption } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useListSenioritiesWithExtraInfo } from "services/doverapi/endpoints/search-v3/customHooks";
import { SeniorityCategory } from "services/doverapi/endpoints/search-v3/types";
import { Seniority } from "services/openapi";
import { colors } from "styles/theme";
import FilterAccordion from "views/sourcing/Search/components/FilterAccordion";
import FilterSectionHeader from "views/sourcing/Search/components/FilterSectionHeader";
import { CHIP_MAX_WIDTH } from "views/sourcing/Search/constants";
import { previewStateMarginBottom, previewStateMarginRight } from "views/sourcing/Search/constants";
import { SELECTED_SENIORITIES_NAME, EXCLUDED_SENIORITIES_NAME } from "views/sourcing/Search/constants";
import { SearchV3FormSchemaType } from "views/sourcing/Search/types";

const SenioritiesTitleContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const seniorities = useWatch({ control, name: SELECTED_SENIORITIES_NAME });
    const excludedSeniorities = useWatch({ control, name: EXCLUDED_SENIORITIES_NAME });

    const onChipDelete = React.useCallback(
      (value: Seniority) => {
        const newSeniorities = seniorities.filter(seniority => seniority.id !== value.id);
        const newExcludedSeniorities = [value, ...excludedSeniorities.filter(seniority => seniority.id !== value.id)];

        setValue(SELECTED_SENIORITIES_NAME, newSeniorities);
        setValue(EXCLUDED_SENIORITIES_NAME, newExcludedSeniorities);
      },
      [excludedSeniorities, seniorities, setValue]
    );

    if (seniorities === undefined || seniorities.length === 0 || expanded) {
      return <></>;
    }

    return (
      <Box>
        {seniorities.map(seniority => {
          return (
            <Chip
              label={<TextWithMaxWidth label={seniority.name} width={CHIP_MAX_WIDTH} />}
              key={seniority.id}
              deleteIcon={<HighlightOffSharpIcon />}
              onDelete={(): void => onChipDelete(seniority)}
              sx={{
                mr: previewStateMarginRight,
                mb: previewStateMarginBottom,
              }}
            />
          );
        })}
      </Box>
    );
  }
);

const SeniorityFiltersContent = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const seniorities = useWatch({ control, name: SELECTED_SENIORITIES_NAME });
    const excludedSeniorities = useWatch({ control, name: EXCLUDED_SENIORITIES_NAME });
    const seniorityOptions = useListSenioritiesWithExtraInfo();

    const getIsChecked = React.useCallback(
      (seniorityOption: Seniority) => {
        return !!seniorities.find(seniority => seniority.id === seniorityOption.id);
      },
      [seniorities]
    );

    const setIsChecked = React.useCallback(
      (seniorityOption: Seniority, isChecked) => {
        const newSeniorities = seniorities.filter(seniority => seniority.id !== seniorityOption.id);
        const newExcludedSeniorities = excludedSeniorities.filter(seniority => seniority.id !== seniorityOption.id);

        if (isChecked) {
          newSeniorities.push(seniorityOption);
        } else {
          newExcludedSeniorities.push(seniorityOption);
        }

        setValue(SELECTED_SENIORITIES_NAME, newSeniorities);
        setValue(EXCLUDED_SENIORITIES_NAME, newExcludedSeniorities);
      },
      [excludedSeniorities, seniorities, setValue]
    );

    const allChecked = React.useMemo(() => {
      if (!seniorityOptions) {
        return false;
      }

      for (let i = 0; i < seniorityOptions.length; i++) {
        if (!getIsChecked(seniorityOptions[i])) {
          return false;
        }
      }

      return true;
    }, [getIsChecked, seniorityOptions]);

    const toggleAllChecked = React.useCallback(
      (seniorityCategories: SeniorityCategory[]) => {
        if (seniorityOptions === undefined) {
          return;
        }

        const senioritiesToAdd: Seniority[] = seniorityOptions.filter(seniorityOption =>
          seniorityCategories.includes(seniorityOption.category)
        );

        setValue(SELECTED_SENIORITIES_NAME, senioritiesToAdd);
      },
      [seniorityOptions, setValue]
    );

    if (!seniorityOptions) {
      return (
        <Stack direction="row">
          <BodySmall color={colors.grayscale.gray500}>Loading</BodySmall>
          <DoverLoadingSpinner minHeight="18px" height="18px" width="32px" spinnerSize="15px" />
        </Stack>
      );
    }
    return (
      <Stack spacing={1}>
        <BodySmall>{"Quick Add"}</BodySmall>
        <Stack direction="row" spacing={2}>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={(): void => {
              toggleAllChecked([SeniorityCategory.NonManager, SeniorityCategory.ManagerAndNonManager]);
            }}
          >
            <Caption>{"Individual Contributors"}</Caption>
          </Button>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={(): void => {
              toggleAllChecked([SeniorityCategory.Manager, SeniorityCategory.ManagerAndNonManager]);
            }}
          >
            <Caption>{"Managers"}</Caption>
          </Button>
        </Stack>
        <Stack>
          {seniorityOptions.map(seniority => {
            return (
              <Stack direction="row" alignItems="center" key={seniority.id}>
                <Checkbox
                  sx={{
                    pl: "0px",
                    color: colors.grayscale.gray400,
                    "&.Mui-checked": {
                      color: colors.primary.base,
                    },
                  }}
                  checked={getIsChecked(seniority) || allChecked}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                    setIsChecked(seniority, event.target.checked);
                  }}
                />
                <BodySmall color={colors.grayscale.gray700}>{seniority.name}</BodySmall>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    );
  }
);

const SeniorityFilters = React.memo(
  (): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const seniorities = useWatch({ control, name: SELECTED_SENIORITIES_NAME });
    return (
      <>
        <FilterSectionHeader title={"Seniority"} icon={PenToolIcon} />
        <FilterAccordion
          title={!seniorities?.length ? "Seniority Level" : undefined}
          TitleContent={SenioritiesTitleContent}
          expandedContent={<SeniorityFiltersContent />}
        />
      </>
    );
  }
);

export default SeniorityFilters;
