import { ActivityFeedEmailParticipantSerializer } from "services/openapi";

export const getShellEmailParticipantFromEmailParticipants = (
  emailParticipants: ActivityFeedEmailParticipantSerializer[] | undefined
): ActivityFeedEmailParticipantSerializer | undefined => {
  if (!emailParticipants) {
    return undefined;
  }
  const shellEmailParticipant = emailParticipants.find(p => p.emailSenderAlias?.isShellAccount);
  return shellEmailParticipant;
};

export const getUserFromEmailParticipants = (
  emailParticipants: ActivityFeedEmailParticipantSerializer[] | undefined,
  userEmail: string
): ActivityFeedEmailParticipantSerializer | undefined => {
  if (!emailParticipants) {
    return undefined;
  }
  return emailParticipants.find(p => p.emailSenderAlias?.email === userEmail);
};
