/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Calendar,
    CalendarFromJSON,
    CalendarFromJSONTyped,
    CalendarToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewerSchedulingInfo
 */
export interface InterviewerSchedulingInfo {
    /**
     * 
     * @type {string}
     * @memberof InterviewerSchedulingInfo
     */
    readonly id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewerSchedulingInfo
     */
    readonly finishedSetup?: boolean;
    /**
     * 
     * @type {object}
     * @memberof InterviewerSchedulingInfo
     */
    availableTimes?: object;
    /**
     * 
     * @type {string}
     * @memberof InterviewerSchedulingInfo
     */
    timezone: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InterviewerSchedulingInfo
     */
    busyGcalIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InterviewerSchedulingInfo
     */
    interviewMeetingType?: InterviewerSchedulingInfoInterviewMeetingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InterviewerSchedulingInfo
     */
    interviewLocation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InterviewerSchedulingInfo
     */
    maxInterviewsPerWeek?: number | null;
    /**
     * 
     * @type {Array<Calendar>}
     * @memberof InterviewerSchedulingInfo
     */
    readonly allCalendars?: Array<Calendar>;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewerSchedulingInfo
     */
    readonly calendarConnected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InterviewerSchedulingInfo
     */
    meetingBuffer: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewerSchedulingInfo
     */
    minFutureBookingTimeOverride?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewerSchedulingInfo
     */
    readonly googleMeetEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InterviewerSchedulingInfo
     */
    readonly availabilityDebuggerLink?: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewerSchedulingInfo
     */
    maxInterviewsPerDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InterviewerSchedulingInfo
     */
    schedulingLinkConfig: InterviewerSchedulingInfoSchedulingLinkConfigEnum;
    /**
     * 
     * @type {string}
     * @memberof InterviewerSchedulingInfo
     */
    schedulingLinkOverride?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum InterviewerSchedulingInfoInterviewMeetingTypeEnum {
    GoogleMeet = 'GOOGLE_MEET',
    Phone = 'PHONE',
    Whereby = 'WHEREBY',
    Zoom = 'ZOOM'
}/**
* @export
* @enum {string}
*/
export enum InterviewerSchedulingInfoSchedulingLinkConfigEnum {
    DoverSchedulingLink = 'DOVER_SCHEDULING_LINK',
    ThirdPartySchedulingLink = 'THIRD_PARTY_SCHEDULING_LINK',
    NoSchedulingLink = 'NO_SCHEDULING_LINK'
}

export function InterviewerSchedulingInfoFromJSON(json: any): InterviewerSchedulingInfo {
    return InterviewerSchedulingInfoFromJSONTyped(json, false);
}

export function InterviewerSchedulingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewerSchedulingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'finishedSetup': !exists(json, 'finished_setup') ? undefined : json['finished_setup'],
        'availableTimes': !exists(json, 'available_times') ? undefined : json['available_times'],
        'timezone': json['timezone'],
        'busyGcalIds': !exists(json, 'busy_gcal_ids') ? undefined : json['busy_gcal_ids'],
        'interviewMeetingType': !exists(json, 'interview_meeting_type') ? undefined : json['interview_meeting_type'],
        'interviewLocation': !exists(json, 'interview_location') ? undefined : json['interview_location'],
        'maxInterviewsPerWeek': !exists(json, 'max_interviews_per_week') ? undefined : json['max_interviews_per_week'],
        'allCalendars': !exists(json, 'all_calendars') ? undefined : ((json['all_calendars'] as Array<any>).map(CalendarFromJSON)),
        'calendarConnected': !exists(json, 'calendar_connected') ? undefined : json['calendar_connected'],
        'meetingBuffer': json['meeting_buffer'],
        'minFutureBookingTimeOverride': !exists(json, 'min_future_booking_time_override') ? undefined : json['min_future_booking_time_override'],
        'googleMeetEnabled': !exists(json, 'google_meet_enabled') ? undefined : json['google_meet_enabled'],
        'availabilityDebuggerLink': !exists(json, 'availability_debugger_link') ? undefined : json['availability_debugger_link'],
        'maxInterviewsPerDay': !exists(json, 'max_interviews_per_day') ? undefined : json['max_interviews_per_day'],
        'schedulingLinkConfig': json['scheduling_link_config'],
        'schedulingLinkOverride': !exists(json, 'scheduling_link_override') ? undefined : json['scheduling_link_override'],
    };
}

export function InterviewerSchedulingInfoToJSON(value?: InterviewerSchedulingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_times': value.availableTimes,
        'timezone': value.timezone,
        'busy_gcal_ids': value.busyGcalIds,
        'interview_meeting_type': value.interviewMeetingType,
        'interview_location': value.interviewLocation,
        'max_interviews_per_week': value.maxInterviewsPerWeek,
        'meeting_buffer': value.meetingBuffer,
        'min_future_booking_time_override': value.minFutureBookingTimeOverride,
        'max_interviews_per_day': value.maxInterviewsPerDay,
        'scheduling_link_config': value.schedulingLinkConfig,
        'scheduling_link_override': value.schedulingLinkOverride,
    };
}


