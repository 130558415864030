/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterSaapReviewedCandidateSubmissionResponse
 */
export interface RegisterSaapReviewedCandidateSubmissionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RegisterSaapReviewedCandidateSubmissionResponse
     */
    success: boolean;
}

export function RegisterSaapReviewedCandidateSubmissionResponseFromJSON(json: any): RegisterSaapReviewedCandidateSubmissionResponse {
    return RegisterSaapReviewedCandidateSubmissionResponseFromJSONTyped(json, false);
}

export function RegisterSaapReviewedCandidateSubmissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterSaapReviewedCandidateSubmissionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function RegisterSaapReviewedCandidateSubmissionResponseToJSON(value?: RegisterSaapReviewedCandidateSubmissionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
    };
}


