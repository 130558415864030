import React from "react";
import { Field } from "react-final-form";

export const required = value => (value ? undefined : value === 0 ? undefined : "Required");
export const noSpecialChars = value => (/^[a-zA-Z0-9 ]*$/.test(value) ? undefined : "No special characters allowed");
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const FieldPrefixContext = React.createContext();

export const FieldPrefix = ({ prefix, children }) => (
  <FieldPrefixContext.Provider value={prefix}>{children}</FieldPrefixContext.Provider>
);
export const PrefixedField = ({ name, ...props }) => (
  <FieldPrefixContext.Consumer>{prefix => <Field name={`${prefix}.${name}`} {...props} />}</FieldPrefixContext.Consumer>
);

export const sortDateCol = (d1, d2) => {
  // if ((!n1 && n1 != 0) || !n2 && n2 != 0){
  //   return 1;
  // }
  return d1 > d2 ? 1 : -1;
};

export const sortNumCol = (n1, n2) => {
  // if ((!n1 && n1 != 0) || !n2 && n2 != 0){
  //   return 1;
  // }
  return parseFloat(n1) > parseFloat(n2) ? 1 : -1;
};
