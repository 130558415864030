import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React from "react";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { selectedApplicantListTabAtom } from "views/candidates/ApplicationReview/atoms/selectedApplicantListTab";

export const NoApplicants = ({ paramsAreBroken }: { paramsAreBroken?: boolean }): React.ReactElement => {
  const selectedTab = useAtomValue(selectedApplicantListTabAtom);

  const isUnreviewedTabSelected = selectedTab === "Unreviewed";

  return (
    <Stack p={1} alignItems="center" justifyContent="center" spacing={2} height="100%">
      <BodySmall weight="500">{paramsAreBroken ? "Error fetching candidates" : "No applicants to display"}</BodySmall>
      {/* Don't care to show this text for tabs other than unreviewed. ie doesnt make sense to show this for the bookmarked cands tab */}
      {isUnreviewedTabSelected && (
        <BodySmall color={colors.grayscale.gray500}>Post to more job boards or adjust your filters</BodySmall>
      )}
    </Stack>
  );
};
