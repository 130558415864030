/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfileSearchKeywordSerializer,
    ProfileSearchKeywordSerializerFromJSON,
    ProfileSearchKeywordSerializerFromJSONTyped,
    ProfileSearchKeywordSerializerToJSON,
} from './';

/**
 * 
 * @export
 * @interface KeywordWithBucketIndicesResponse
 */
export interface KeywordWithBucketIndicesResponse {
    /**
     * 
     * @type {Array<ProfileSearchKeywordSerializer>}
     * @memberof KeywordWithBucketIndicesResponse
     */
    keywordsInBucket: Array<ProfileSearchKeywordSerializer>;
    /**
     * 
     * @type {number}
     * @memberof KeywordWithBucketIndicesResponse
     */
    bucketIndex: number;
}

export function KeywordWithBucketIndicesResponseFromJSON(json: any): KeywordWithBucketIndicesResponse {
    return KeywordWithBucketIndicesResponseFromJSONTyped(json, false);
}

export function KeywordWithBucketIndicesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeywordWithBucketIndicesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keywordsInBucket': ((json['keywords_in_bucket'] as Array<any>).map(ProfileSearchKeywordSerializerFromJSON)),
        'bucketIndex': json['bucket_index'],
    };
}

export function KeywordWithBucketIndicesResponseToJSON(value?: KeywordWithBucketIndicesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keywords_in_bucket': ((value.keywordsInBucket as Array<any>).map(ProfileSearchKeywordSerializerToJSON)),
        'bucket_index': value.bucketIndex,
    };
}


