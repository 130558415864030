import { Stack, Skeleton } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { isEqual } from "lodash";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { ExternalLink } from "components/dover/LinkedInConnectionsUpload/styles";
import { BodySmall } from "components/library/typography";
import { useDebounceState } from "hooks/useDebounceState";
import { useIsInViewport } from "hooks/useIsInViewport";
import { useOnChange } from "hooks/useOnChange";
import { useShouldShowRealProfiles } from "services/doverapi/endpoints/client/hooks";
import {
  useGetSearchV3DepthResultQuery,
  useGetSearchV3Query,
  useListSearchV3ProfilesQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import {
  ApiApiGetSearchV3DepthResultRequest,
  ApiApiListSearchV3ProfileResultsRequest,
  ProfileRequest,
} from "services/openapi";
import { colors } from "styles/theme";
import { retrieveJobReferral } from "views/Referrals/ReviewReferrals/actions";
import CandidateCard from "views/sourcing/Search/components/CandidateCard/CandidateCard";
import { SAAP_PARAMS_DEBOUNCE } from "views/sourcing/Search/constants";
import { FormLoadStateContext } from "views/sourcing/Search/context/FilterToggleContext";
import { useSearchId } from "views/sourcing/Search/hooks";
import { searchV3FormSchema, SearchV3FormSchemaType, SourcingContext } from "views/sourcing/Search/types";
import { getSearchV3FromFormState } from "views/sourcing/Search/utils";

export const OFFSET_MULTIPLIER = 10;

interface ScrollEndProps {
  onScrollEndInViewport: () => void;
}

// This special component serves a single purpose, which is to call some callback when it scrolls into view
// Useful for the end of a list
const ScrollEnd = React.memo(
  ({ onScrollEndInViewport }: ScrollEndProps): React.ReactElement => {
    const boxRef = React.useRef<HTMLDivElement>(null);
    const isInViewport = useIsInViewport(boxRef);

    useOnChange(() => {
      if (isInViewport) {
        onScrollEndInViewport();
      }
    }, [isInViewport]);

    return <div style={{ height: "10px" }} ref={boxRef}></div>;
  }
);

const OnboardingCallAd = (): React.ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "sourcing_autopilot", "saap_calibration");

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={0.5}
      padding={0.5}
      borderRadius={2}
      bgcolor={colors.linkLight}
      color="white"
    >
      <BodySmall color={colors.white}>
        😕 Not seeing your ideal candidates?{" "}
        <ExternalLink
          href={calendlyUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "white",
            textDecoration: "underline",
          }}
        >
          Book a calibration call
        </ExternalLink>
      </BodySmall>
    </Stack>
  );
};

interface CandidateCardWindowProps {
  offset: number;
  forceDelayLoading: boolean;
  context?: SourcingContext;
  onFinishFetchingProfiles: () => void;
  onOutOfDepth: () => void;
  setIsLoading: (index: number, loading: boolean) => void;
}

const CandidateCardWindow = React.memo(
  ({
    offset,
    forceDelayLoading,
    context,
    onFinishFetchingProfiles,
    onOutOfDepth,
    setIsLoading,
  }: CandidateCardWindowProps): React.ReactElement => {
    const { control } = useFormContext<SearchV3FormSchemaType>();
    const values = useWatch({ control });
    const sortBy = useWatch({ control, name: "sortBy" });

    const searchId = useSearchId();
    const { data: search, isLoading: isSearchLoading } = useGetSearchV3Query(searchId ? { id: searchId } : skipToken);

    const intialFormValuesLoaded = React.useContext(FormLoadStateContext)?.loaded;
    const shouldShowRealProfiles = useShouldShowRealProfiles();
    const showOnboardingCallAds = !shouldShowRealProfiles;

    const [debouncedSearchParams, setSearchParams] = useDebounceState<
      ApiApiListSearchV3ProfileResultsRequest | undefined
    >(undefined, SAAP_PARAMS_DEBOUNCE);

    React.useEffect(() => {
      let partialProfileRequest: Pick<ProfileRequest, "start" | "size" | "sample"> = {
        start: offset,
        size: OFFSET_MULTIPLIER,
        sample: false,
      };

      if (context === SourcingContext.CreateJob) {
        partialProfileRequest = {
          ...partialProfileRequest,
          sample: true,
        };
      }
      // If a search is undefined or loading, we don't wish to proceed
      if (search === undefined || isSearchLoading) {
        return;
      }

      if (!intialFormValuesLoaded) {
        return;
      }

      const formParseResult = searchV3FormSchema.safeParse(values);
      if (!formParseResult.success) {
        // Since the form was not parsed correctly, stop here
        return;
      }

      // After updates, we'll get a referentially different search object from RTKQ
      // It's important the we check deep equality against the new search params so we don't perform an additional unnecessary update
      const newSearchParams = getSearchV3FromFormState(formParseResult.data, search).v3Params;
      if (isEqual(search.v3Params, newSearchParams)) {
        return;
      }

      setSearchParams({
        data: {
          params: newSearchParams,
          sortBy: sortBy,
          searchId: search.id,
          ...partialProfileRequest,
        },
      });
    }, [context, intialFormValuesLoaded, isSearchLoading, offset, search, setSearchParams, sortBy, values]);

    const { data: resultProfiles, isFetching } = useListSearchV3ProfilesQuery(
      intialFormValuesLoaded && debouncedSearchParams ? debouncedSearchParams : skipToken
    );

    useOnChange(() => {
      // In create job context, this is the only CandidateCardWindow
      if (context === SourcingContext.CreateJob) {
        setIsLoading(0, !resultProfiles || isFetching);
        retrieveJobReferral;
      }
      setIsLoading(offset / OFFSET_MULTIPLIER, !resultProfiles || isFetching);
    }, [isFetching, resultProfiles, setIsLoading]);

    const candidateCardsWithAds = (arr: JSX.Element[]): JSX.Element[] => {
      // Interleave onboarding call ads in between sample candidates for free customers
      return arr.reduce((acc: JSX.Element[], curr, index) => {
        acc.push(curr);
        if ((index + 1) % 3 === 0 && index !== arr.length - 1) {
          acc.push(<OnboardingCallAd key={`ad-${index}`} />);
        }
        return acc;
      }, []);
    };

    const candidateCards = React.useMemo(() => {
      if (!resultProfiles || isFetching || forceDelayLoading) {
        return (
          <Stack spacing={2} height="600px">
            {Array.from(Array(3)).map((value, index) => {
              return <Skeleton key={index} variant="rectangular" height="200px" />;
            })}
          </Stack>
        );
      }
      const candidateCards = resultProfiles!.map(profile => (
        <CandidateCard profile={profile} key={profile.canonicalId} context={context} />
      ));
      if (showOnboardingCallAds) {
        return candidateCardsWithAds(candidateCards);
      }
      return candidateCards;
    }, [context, forceDelayLoading, isFetching, resultProfiles, showOnboardingCallAds]);

    useOnChange(() => {
      if (resultProfiles && !isFetching) {
        if (resultProfiles.length === 0) {
          onOutOfDepth();
        }

        onFinishFetchingProfiles();
      }
    }, [resultProfiles, isFetching]);

    return <>{candidateCards}</>;
  }
);

interface CandidateCardListProps {
  context?: SourcingContext;
  setIsLoading?: (loading: boolean) => void;
  setNoCandidates?: (noCandidates: boolean) => void;
}

const CandidateCardList = React.memo(
  ({ context, setIsLoading, setNoCandidates }: CandidateCardListProps): React.ReactElement => {
    const searchId = useSearchId();
    const { data: search, isLoading: isSearchLoading } = useGetSearchV3Query(searchId ? { id: searchId } : skipToken);

    const { control } = useFormContext<SearchV3FormSchemaType>();
    const values = useWatch({ control });

    // Used as a virtualization helper
    const [numWindows, setNumWindows] = React.useState<number>(1);
    const [windowsLoadingByIndex, setWindowsLoadingByIndex] = React.useState<boolean[]>([false]);

    const [fetchingProfiles, setFetchingProfiles] = React.useState<boolean>(true);
    const [outOfDepth, setOutOfDepth] = React.useState<boolean>(false);

    const intialFormValuesLoaded = React.useContext(FormLoadStateContext)?.loaded;

    const [debouncedSearchParams, setSearchParams, searchParams] = useDebounceState<
      ApiApiGetSearchV3DepthResultRequest | undefined
    >(undefined, SAAP_PARAMS_DEBOUNCE);

    React.useEffect(() => {
      // If a search is undefined or loading, we don't wish to proceed
      if (search === undefined || isSearchLoading) {
        return;
      }

      if (!intialFormValuesLoaded) {
        setSearchParams({ data: { params: search.v3Params, searchId: search.id } });
        return;
      }

      const formParseResult = searchV3FormSchema.safeParse(values);
      if (!formParseResult.success) {
        // Since the form was not parsed correctly, stop here
        return;
      }

      // After updates, we'll get a referentially different search object from RTKQ
      // It's important the we check deep equality against the new search params so we don't perform an additional unnecessary update
      const newSearchParams = getSearchV3FromFormState(formParseResult.data, search).v3Params;
      if (isEqual(search.v3Params, newSearchParams)) {
        return;
      }

      setSearchParams({ data: { params: newSearchParams, searchId: search.id } });
    }, [intialFormValuesLoaded, isSearchLoading, search, setSearchParams, values]);

    const { data: depthCount } = useGetSearchV3DepthResultQuery(
      intialFormValuesLoaded && debouncedSearchParams ? debouncedSearchParams : skipToken
    );

    const onFinishFetchingProfiles = React.useCallback(() => {
      setFetchingProfiles(false);
      if (setIsLoading) {
        setIsLoading(false);
      }
    }, [setIsLoading]);

    const onOutOfDepth = React.useCallback(() => {
      setOutOfDepth(true);
      if (setNoCandidates) {
        setNoCandidates(true);
      }
    }, [setNoCandidates]);

    // Reset out of depth state on every form value change, to be recomputed later
    useOnChange(() => {
      setOutOfDepth(false);
      if (setNoCandidates) {
        setNoCandidates(false);
      }
    }, [values]);

    const getForceDelayLoading = React.useCallback(
      (index: number) => {
        if (index >= numWindows) {
          return false;
        }

        for (let i = 0; i < index; i++) {
          if (windowsLoadingByIndex[i]) {
            return true;
          }
        }

        return false;
      },
      [numWindows, windowsLoadingByIndex]
    );

    const setIsWindowLoading = React.useCallback(
      (index: number, isLoading: boolean) => {
        if (index >= numWindows) {
          return;
        }

        const newWindowsLoadingByIndex = [...windowsLoadingByIndex];
        newWindowsLoadingByIndex.splice(index, 1, isLoading);

        if (!isEqual(newWindowsLoadingByIndex, windowsLoadingByIndex)) {
          setWindowsLoadingByIndex(newWindowsLoadingByIndex);
        }
      },
      [numWindows, windowsLoadingByIndex]
    );

    const candidateCardWindows = React.useMemo(() => {
      if (searchParams === undefined) {
        return undefined;
      }

      // We only need one window if we're in the create job context
      if (context === SourcingContext.CreateJob) {
        return (
          <CandidateCardWindow
            offset={0}
            onFinishFetchingProfiles={onFinishFetchingProfiles}
            onOutOfDepth={onOutOfDepth}
            forceDelayLoading={false}
            setIsLoading={setIsWindowLoading}
            context={context}
          />
        );
      }

      return Array.from(Array(numWindows)).map((value: any, index: number) => {
        return (
          <CandidateCardWindow
            offset={index * OFFSET_MULTIPLIER}
            onFinishFetchingProfiles={onFinishFetchingProfiles}
            onOutOfDepth={onOutOfDepth}
            forceDelayLoading={getForceDelayLoading(index)}
            setIsLoading={setIsWindowLoading}
            key={index}
          />
        );
      });
    }, [
      context,
      getForceDelayLoading,
      numWindows,
      onFinishFetchingProfiles,
      onOutOfDepth,
      searchParams,
      setIsWindowLoading,
    ]);

    const onScrollEndInViewport = React.useCallback(() => {
      // We don't need to consider sourcing context here because the ScrollEnd component is only rendered when the context is not CreateJob,
      // And we otherwise want to keep this logic
      if (!outOfDepth && !fetchingProfiles && !!depthCount && numWindows * OFFSET_MULTIPLIER < depthCount.count) {
        setNumWindows(numWindows + 1);
        setWindowsLoadingByIndex([...windowsLoadingByIndex, true]);
        setFetchingProfiles(true);
      }
    }, [depthCount, fetchingProfiles, numWindows, outOfDepth, windowsLoadingByIndex]);

    // If we have no data sets, show some loading state
    if (candidateCardWindows === undefined) {
      return (
        <>
          {Array.from(Array(3)).map((value, index) => {
            return <Skeleton key={index} variant="rectangular" height="200px" />;
          })}
        </>
      );
    }

    return (
      <Stack overflow="auto" spacing={1.6}>
        {candidateCardWindows}
        {(!context || context !== SourcingContext.CreateJob) && (
          <ScrollEnd onScrollEndInViewport={onScrollEndInViewport} />
        )}
      </Stack>
    );
  }
);

export default CandidateCardList;
