import { skipToken } from "@reduxjs/toolkit/query";

import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useGetLeverAuthInfoQuery } from "services/doverapi/endpoints/leverData/index";
import { UseQueryResult } from "services/doverapi/types";
import { LeverAuthInfo } from "services/openapi";

export function useLeverAuthInfo(pollingInterval?: number): UseQueryResult<LeverAuthInfo> {
  const clientId = useGetClientId();
  return useGetLeverAuthInfoQuery(clientId ? { clientId } : skipToken, { pollingInterval });
}

export function useIsLeverAuthValid(): boolean | undefined {
  const { data: leverAuthInfo } = useLeverAuthInfo();
  return leverAuthInfo?.isValid;
}

export function useLeverHasLssPermissionScopes(): boolean | undefined {
  const { data: leverAuthInfo } = useLeverAuthInfo();
  return leverAuthInfo?.hasLssInterviewScopes;
}
