/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalibrationCandidate
 */
export interface CalibrationCandidate {
    /**
     * 
     * @type {string}
     * @memberof CalibrationCandidate
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CalibrationCandidate
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalibrationCandidate
     */
    readonly modified?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CalibrationCandidate
     */
    sendToClient?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalibrationCandidate
     */
    sentToClient?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalibrationCandidate
     */
    status?: CalibrationCandidateStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CalibrationCandidate
     */
    feedback?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CalibrationCandidate
     */
    candidateScore?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CalibrationCandidate
     */
    matchScore?: number | null;
    /**
     * 
     * @type {object}
     * @memberof CalibrationCandidate
     */
    features?: object | null;
    /**
     * 
     * @type {object}
     * @memberof CalibrationCandidate
     */
    searchParams?: object | null;
    /**
     * 
     * @type {string}
     * @memberof CalibrationCandidate
     */
    person: string;
    /**
     * 
     * @type {string}
     * @memberof CalibrationCandidate
     */
    job: string;
    /**
     * 
     * @type {string}
     * @memberof CalibrationCandidate
     */
    search?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum CalibrationCandidateStatusEnum {
    Approved = 'APPROVED',
    Rejected = 'REJECTED',
    Maybe = 'MAYBE',
    PendingReview = 'PENDING_REVIEW'
}

export function CalibrationCandidateFromJSON(json: any): CalibrationCandidate {
    return CalibrationCandidateFromJSONTyped(json, false);
}

export function CalibrationCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalibrationCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'sendToClient': !exists(json, 'send_to_client') ? undefined : json['send_to_client'],
        'sentToClient': !exists(json, 'sent_to_client') ? undefined : json['sent_to_client'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'feedback': !exists(json, 'feedback') ? undefined : json['feedback'],
        'candidateScore': !exists(json, 'candidate_score') ? undefined : json['candidate_score'],
        'matchScore': !exists(json, 'match_score') ? undefined : json['match_score'],
        'features': !exists(json, 'features') ? undefined : json['features'],
        'searchParams': !exists(json, 'search_params') ? undefined : json['search_params'],
        'person': json['person'],
        'job': json['job'],
        'search': !exists(json, 'search') ? undefined : json['search'],
    };
}

export function CalibrationCandidateToJSON(value?: CalibrationCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'send_to_client': value.sendToClient,
        'sent_to_client': value.sentToClient,
        'status': value.status,
        'feedback': value.feedback,
        'candidate_score': value.candidateScore,
        'match_score': value.matchScore,
        'features': value.features,
        'search_params': value.searchParams,
        'person': value.person,
        'job': value.job,
        'search': value.search,
    };
}


