import { TableCell, TableContainer, TableRow } from "@mui/material";
import styled from "styled-components";

import { theme } from "styles/theme";

const colors = {
  tableContainerBackgroundColor: "white",
  evenRowBackgroundColor: theme.colors.grayscale.gray100,
  oddRowBackgroundColor: "white",
  headerRowBackgroundColor: "white",
};

export const StyledTableContainer = styled(TableContainer)`
  max-height: 330px;
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: ${colors.evenRowBackgroundColor};
  }
  &:nth-of-type(odd) {
    background-color: ${colors.oddRowBackgroundColor};
  }
`;

export const StyledHeaderTableCell = styled(TableCell)`
  padding: 9px;
  font-size: 16px;
  font-family: "Inter";
  font-weight: normal;
  background-color: ${colors.headerRowBackgroundColor};
`;

export const StyledTableCell = styled(TableCell)`
  border-bottom: 0px solid;
  padding: 9px;
  font-size: 16px;
  font-family: "Inter";
`;

const WhiteCard = styled.div`
  background: white;
  border: 1px solid #bcc7cc;
  border-radius: 6px;
`;

export const ExclusionsCard = styled(WhiteCard)`
  padding: 24px;
  width: 100%;
`;

export const List = styled.ul`
  padding-inline-start: 20px;
  margin-bottom: 24px;
`;

export const Bullet = styled.li`
  list-style-type: disc;
  margin-left: auto;
`;
