import { AddCandidateErrors } from "sections/addcandidate/data/errors";
import { IAddCandidate } from "sections/addcandidate/services/api/interface";
import { FlowState, StepTypes } from "sections/addcandidate/types";
import { BulkCandidateInfoSchema } from "sections/addcandidate/types/candidateInfo";
import { useAddCandidateInitialOutreachBulkMutation } from "services/doverapi/endpoints/candidate";

// ---------------------------------------------------------------------------
// Service Implementation

export const useBulkOutreachApi = (flowState: FlowState): IAddCandidate => {
  const [addCandidateInitialOutreachBulkMutation] = useAddCandidateInitialOutreachBulkMutation();

  return {
    addCandidate: async (): Promise<{ success: boolean; message?: string }> => {
      // 0. Collect data & api clients
      const { jobId } = flowState[StepTypes.JOB_SELECT];
      const candidateInfo = flowState[StepTypes.CANDIDATE_INFO] as BulkCandidateInfoSchema;
      const campaignState = flowState[StepTypes.SELECT_CAMPAIGN];

      // 1. Validate data
      // This should never happen, but just in case
      if (!candidateInfo.manualSourcingItems || candidateInfo.manualSourcingItems.length === 0) {
        return {
          success: false,
          message: AddCandidateErrors.bulkOutreach.emptyCandidateList,
        };
      }
      // This should never happen, but just in case
      if (!campaignState.campaign?.id) {
        return {
          success: false,
          message: AddCandidateErrors.bulkOutreach.campaignNotFound,
        };
      }

      // 2. Make API call
      try {
        const addResponse = await addCandidateInitialOutreachBulkMutation({
          data: {
            job: jobId!,
            candidates: candidateInfo.manualSourcingItems,
            campaign: campaignState.campaign?.id,
            firstMessageBody: campaignState.body,
            firstMessageSubject: campaignState.subject,
            ccEmails: campaignState.ccEmails,
          },
        }).unwrap();
        // 2.1 If we failed to create the candidate, return an error
        if (!addResponse.success) {
          return {
            success: false,
            message: addResponse.message,
          };
        }
        // 2.2 If the candidate was created successfully, return success
        return {
          success: true,
        };
      } catch (e) {
        // 2.3 If we failed due to a transient error, return an error telling them to try again
        return {
          success: false,
          message: AddCandidateErrors.generic.transientError,
        };
      }
    },
  };
};
