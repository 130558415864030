/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Contact,
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface FindDupeContactResponse
 */
export interface FindDupeContactResponse {
    /**
     * 
     * @type {Contact}
     * @memberof FindDupeContactResponse
     */
    contact?: Contact;
}

export function FindDupeContactResponseFromJSON(json: any): FindDupeContactResponse {
    return FindDupeContactResponseFromJSONTyped(json, false);
}

export function FindDupeContactResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindDupeContactResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contact': !exists(json, 'contact') ? undefined : ContactFromJSON(json['contact']),
    };
}

export function FindDupeContactResponseToJSON(value?: FindDupeContactResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact': ContactToJSON(value.contact),
    };
}


