import { pronounsOptions } from "views/CompanySetup/components/InterviewPreferences/constants";
import {
  InterviewPreferencesFormErrors,
  InterviewPreferencesFormValues,
} from "views/CompanySetup/components/InterviewPreferences/types";

export function validateForm(values: InterviewPreferencesFormValues): InterviewPreferencesFormErrors {
  const errors: InterviewPreferencesFormErrors = {};

  if (!values.availableTimes || Object.keys(values.availableTimes).length === 0) {
    errors.availableTimes = "Please select at least one available day";
  }
  if (!values.interviewMeetingType) {
    errors.interviewMeetingType = "Required";
  }
  if (!values.timezone) {
    errors.timezone = "Required";
  }
  if (!values.busyGcalIds) {
    errors.busyGcalIds = "";
  }
  if (values.meetingBuffer === undefined) {
    errors.meetingBuffer = "Required";
  }
  if (values.maxInterviewsPerDay) {
    if (values.maxInterviewsPerDay <= 0) {
      errors.maxInterviewsPerDay = "Must be a positive number";
    }
  }

  return errors;
}

export function isOtherSelected(value: string | undefined): boolean {
  // @ts-ignore
  return pronounsOptions.includes(value!);
}
