function camelToSnakeCase(camel: string): string {
  return camel.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function doverTypeToJson<T>(model: T): any {
  // Null and undefined are directly serializable
  if (model === null || model === undefined) {
    return model;
  }

  // As are native types string, number, and boolean
  if (["string", "number", "boolean"].includes(typeof model)) {
    return model;
  }

  // Recursively convert arrays
  if (Array.isArray(model)) {
    const modelArray: any[] = [];
    model.forEach(modelVal => modelArray.push(doverTypeToJson(modelVal)));
    return modelArray;
  }

  // Recursively convert objects
  const json: any = {};
  Object.entries(model).forEach(([key, value]) => {
    const snake_case_key = camelToSnakeCase(key);
    if (value === null) {
      json[snake_case_key] = null;
    }

    if (value === undefined) {
      json[snake_case_key] = undefined;
    } else if (["string", "number", "boolean"].includes(typeof value)) {
      json[snake_case_key] = value;
    } else if (Array.isArray(value)) {
      const arrayVal: any[] = [];
      (value as Array<any>).map(v => {
        const recursiveJson = doverTypeToJson(v);
        arrayVal.push(recursiveJson);
      });
      json[snake_case_key] = arrayVal;
    } else {
      const valToJson = doverTypeToJson(value);
      json[snake_case_key] = valToJson;
    }
  });

  return json;
}
