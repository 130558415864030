/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MinMaxRange,
    MinMaxRangeFromJSON,
    MinMaxRangeFromJSONTyped,
    MinMaxRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface TotalYearsOfExperience
 */
export interface TotalYearsOfExperience {
    /**
     * 
     * @type {string}
     * @memberof TotalYearsOfExperience
     */
    field: string;
    /**
     * 
     * @type {MinMaxRange}
     * @memberof TotalYearsOfExperience
     */
    value: MinMaxRange;
    /**
     * 
     * @type {boolean}
     * @memberof TotalYearsOfExperience
     */
    required?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TotalYearsOfExperience
     */
    weight?: number | null;
}

export function TotalYearsOfExperienceFromJSON(json: any): TotalYearsOfExperience {
    return TotalYearsOfExperienceFromJSONTyped(json, false);
}

export function TotalYearsOfExperienceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TotalYearsOfExperience {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'value': MinMaxRangeFromJSON(json['value']),
        'required': !exists(json, 'required') ? undefined : json['required'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
    };
}

export function TotalYearsOfExperienceToJSON(value?: TotalYearsOfExperience | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'value': MinMaxRangeToJSON(value.value),
        'required': value.required,
        'weight': value.weight,
    };
}


