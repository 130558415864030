import { TextField } from "@mui/material";
import MUIAutocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

interface NoValuesAutocompleteProps<T> {
  options: T[];
  // what to do when a new value is selected
  onSelect: (options: T[]) => void;
  optionsLoading: boolean;
  placeholder?: string;
  getOptionLabel: (option: T) => string;
  initialValues?: T[];
  filterSelectedOptions?: boolean;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  noOptionsText?: string | React.ReactElement;
  // (optional): callback for when the text field value changes. used to control text field
  onTextChange?: (text: string) => void;
  // (optional): the value that will be displayed in the text field. Means of controlling/having access to text field
  // If not provided, the text field will follow whatever the user types
  textFieldValue?: string;
  renderOption?: (props: any, option: T, state: any) => React.ReactElement;
  groupBy?: (option: T) => string;
  // if groupBy is provided, probably best to leave shouldShort as undefined so that the options are sorted by group
  shouldSort?: boolean;
  renderTags?: (value: T[], getTagProps: any) => React.ReactElement;
}

const NoValuesAutocomplete = React.memo(
  <T extends object>({
    options,
    onSelect,
    optionsLoading,
    placeholder,
    getOptionLabel,
    initialValues,
    isOptionEqualToValue,
    noOptionsText,
    onTextChange,
    renderTags,
    textFieldValue,
    filterSelectedOptions,
    renderOption,
    groupBy,
    shouldSort,
  }: NoValuesAutocompleteProps<T>): React.ReactElement => {
    // This is a React component to allow selecting options in the UI without the options populating the autocomplete after selection.
    // This is useful for when you want to select multiple options, but want to deal with the display of the selected options yourself
    // rather than having them pop up in autocomplete.

    const [backupValue, setBackupValue] = React.useState("");

    // Sorting function based on label
    const sortOptions = (a: T, b: T): number => {
      if (getOptionLabel(a) < getOptionLabel(b)) {
        return -1;
      }
      if (getOptionLabel(a) > getOptionLabel(b)) {
        return 1;
      }
      return 0;
    };

    const actualRenderTags =
      renderTags ||
      ((): null => {
        return null;
      });

    return (
      <MUIAutocomplete
        disabled={optionsLoading}
        multiple={true}
        options={shouldSort ? [...options].sort(sortOptions) : options}
        onChange={(_event, newValue: T[] | null): void => {
          onTextChange?.("");
          setBackupValue("");
          onSelect(newValue || []);
        }}
        noOptionsText={noOptionsText}
        value={initialValues}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        renderTags={actualRenderTags}
        filterSelectedOptions={filterSelectedOptions}
        renderOption={renderOption}
        groupBy={groupBy}
        renderInput={(params): JSX.Element => {
          // our hacky of way of doing a 'control' textField (ie just set the text value to the one we want to feed it)
          params.inputProps.value = textFieldValue ?? backupValue;
          return (
            <TextField
              sx={{
                ".MuiOutlinedInput-root.MuiInputBase-root": {
                  outline: "none",
                  border: "none",
                  paddingLeft: "6px",
                },
              }}
              {...params}
              placeholder={placeholder}
              onChange={(event): void => {
                setBackupValue(event.target.value || "");
                onTextChange?.(event.target.value || "");
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {optionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {!optionsLoading ? params.InputProps.endAdornment : null}
                  </>
                ),
              }}
            />
          );
        }}
      />
    );
  }
) as <T>(props: NoValuesAutocompleteProps<T>) => JSX.Element;

export default NoValuesAutocomplete;
