import { skipToken } from "@reduxjs/toolkit/dist/query";
import { pick } from "lodash";
import { uniqBy } from "lodash";
import React from "react";

import { ReloadableEntity } from "domains/types";
import {
  useGetJobCandidateSourceSettingsQuery,
  useGetJobCandidateSourcesQuery,
  useGetCandidateSourceStatsQuery,
  useListJobAtsSourceSettingsQuery,
  useLoadAtsSourcesQuery,
} from "services/doverapi/endpoints/job-source-settings/endpoints";
import {
  JobCandidateSourceSettingMap,
  SourceDisplaySectionData,
  EnrichedCandidateSourceDetails,
  FormattedInboundSourceExplorerRow,
  DedupedAtsSourcesInfoByDisplaySection,
  DedupedAtsSource,
} from "services/doverapi/endpoints/job-source-settings/types";
import {
  getMatchingSourceSetting,
  getMatchingCandidateSourceStats,
  getSourceStatus,
  formatCandidateSourceDetails,
} from "services/doverapi/endpoints/job-source-settings/utils";
import {
  CandidateSourceDisplaySectionEnum,
  CandidateSourceFeaturesEnum,
  JobAtsSourceSetting,
  JobCandidateSourceSetting,
  JobCandidateSourceSettingDesiredStateEnum,
} from "services/openapi";

function useGetCandidateSourceSettingsMap({
  jobId,
  includeAdminPreview,
  includeYcWaas,
}: {
  jobId: string | undefined;
  includeAdminPreview: boolean;
  includeYcWaas?: boolean;
}): JobCandidateSourceSettingMap | undefined {
  const { data: candidateSourceSettings } = useGetJobCandidateSourceSettingsQuery(
    jobId ? { jobId, includeAdminPreview, includeYcWaas } : skipToken
  );
  return React.useMemo<JobCandidateSourceSettingMap | undefined>(() => {
    if (candidateSourceSettings === undefined) {
      return undefined;
    }

    return candidateSourceSettings.reduce((sourceSettingsMap, sourceSetting) => {
      if (sourceSetting.id) {
        return { ...sourceSettingsMap, [sourceSetting.id]: sourceSetting };
      }
      return sourceSettingsMap;
    }, {});
  }, [candidateSourceSettings]);
}

export function useGetSourceDisplaySectionData({
  jobId,
  includeAdminPreview,
  includeYcWaas,
}: {
  jobId: string | undefined;
  includeAdminPreview: boolean;
  includeYcWaas?: boolean;
}): ReloadableEntity<SourceDisplaySectionData> {
  const { data: candidateSources, isFetching: areCandidateSourcesFetching } = useGetJobCandidateSourcesQuery(
    jobId
      ? {
          jobId,
          includeAdminPreview,
          includeYcWaas,
        }
      : skipToken
  );
  const candidateSourceSettingsMap = useGetCandidateSourceSettingsMap({ jobId, includeAdminPreview, includeYcWaas });
  const { data: candidateSourceStats, isFetching: areCandidateSourceStatsFetching } = useGetCandidateSourceStatsQuery(
    jobId
      ? {
          jobId,
        }
      : skipToken
  );

  return React.useMemo<ReloadableEntity<SourceDisplaySectionData>>(() => {
    if (
      candidateSources === undefined ||
      candidateSourceSettingsMap === undefined ||
      candidateSourceStats === undefined
    ) {
      return { data: undefined, isFetching: areCandidateSourcesFetching || areCandidateSourceStatsFetching };
    }

    const sourceDisplaySectionData: SourceDisplaySectionData = {};
    [...candidateSources]
      .filter(candidateSource => !!candidateSource.id)
      .forEach(candidateSource => {
        const displaySection = candidateSource.displaySection!;
        const jobCandidateSourceSettings = getMatchingSourceSetting(candidateSource, candidateSourceSettingsMap);
        const enrichedCandidateSourceDetails: EnrichedCandidateSourceDetails = {
          id: candidateSource.id!,
          candidateSource,
          jobCandidateSourceSettings,
          candidateSourceStats: getMatchingCandidateSourceStats(candidateSource.id!, candidateSourceStats),
          status: getSourceStatus(jobCandidateSourceSettings),
        };

        if (!sourceDisplaySectionData[displaySection]) {
          sourceDisplaySectionData[displaySection] = [enrichedCandidateSourceDetails];
        } else {
          sourceDisplaySectionData[displaySection]!.push(enrichedCandidateSourceDetails);
        }
      });

    return {
      data: sourceDisplaySectionData,
      isFetching: areCandidateSourcesFetching || areCandidateSourceStatsFetching,
    };
  }, [
    areCandidateSourceStatsFetching,
    areCandidateSourcesFetching,
    candidateSourceSettingsMap,
    candidateSourceStats,
    candidateSources,
  ]);
}

export function useGetFormattedPreviouslyAddedInboundSourceRows({
  jobId,
  includeAdminPreview,
  includeYcWaas,
}: {
  jobId: string | undefined;
  includeAdminPreview: boolean;
  includeYcWaas?: boolean;
}): FormattedInboundSourceExplorerRow[] {
  const sourceDisplaySectionData = useGetSourceDisplaySectionData({ jobId, includeAdminPreview, includeYcWaas });

  return React.useMemo<FormattedInboundSourceExplorerRow[]>(() => {
    const activeInboundSourceRows = sourceDisplaySectionData?.data?.[CandidateSourceDisplaySectionEnum.Inbound]?.filter(
      inboundSource =>
        inboundSource.jobCandidateSourceSettings &&
        (inboundSource.jobCandidateSourceSettings.desiredState === JobCandidateSourceSettingDesiredStateEnum.Active ||
          inboundSource.jobCandidateSourceSettings.desiredStateLastMadeActiveOn)
    );

    return formatCandidateSourceDetails(activeInboundSourceRows);
  }, [sourceDisplaySectionData?.data]);
}

export function useGetFormattedRecommendedSourceRows({
  jobId,
  includeAdminPreview,
}: {
  jobId: string | undefined;
  includeAdminPreview: boolean;
}): FormattedInboundSourceExplorerRow[] {
  const sourceDisplaySectionData = useGetSourceDisplaySectionData({ jobId, includeAdminPreview });

  return React.useMemo<FormattedInboundSourceExplorerRow[]>(() => {
    const neverAddedRecommendedSources = sourceDisplaySectionData?.data?.[
      CandidateSourceDisplaySectionEnum.Inbound
    ]?.filter(
      inboundSource =>
        // Source isn't active or setting up
        inboundSource.jobCandidateSourceSettings?.desiredState !== JobCandidateSourceSettingDesiredStateEnum.Active &&
        // Source is recommended or has Dover integration
        inboundSource.candidateSource.features?.some(feature =>
          [CandidateSourceFeaturesEnum.RecommendedByDover, CandidateSourceFeaturesEnum.DoverIntegration].includes(
            feature
          )
        )
    );

    return formatCandidateSourceDetails(neverAddedRecommendedSources);
  }, [sourceDisplaySectionData?.data]);
}

export function useGetFormattedInboundSourceExplorerRows({
  jobId,
  includeAdminPreview,
  filterOutPreviouslyAddedInboundSources = true,
  includeYcWaas,
}: {
  jobId: string | undefined;
  includeAdminPreview: boolean;
  filterOutPreviouslyAddedInboundSources?: boolean;
  includeYcWaas?: boolean;
}): FormattedInboundSourceExplorerRow[] {
  const sourceDisplaySectionData = useGetSourceDisplaySectionData({
    jobId,
    includeAdminPreview,
    includeYcWaas,
  });
  return React.useMemo<FormattedInboundSourceExplorerRow[]>(() => {
    const inboundSourceExplorerRows = sourceDisplaySectionData?.data?.[
      CandidateSourceDisplaySectionEnum.Inbound
    ]?.filter(inboundSource =>
      // We must check if jobCandidateSourceSettings is undefined/null, because we lazily create this record.
      // And we must check if the desired state is Inactive to support legacy job candidate source settings, since we only added desiredStateLastMadeActiveOn in November 2021
      filterOutPreviouslyAddedInboundSources
        ? !inboundSource.jobCandidateSourceSettings ||
          (inboundSource.jobCandidateSourceSettings &&
            inboundSource.jobCandidateSourceSettings.desiredState ===
              JobCandidateSourceSettingDesiredStateEnum.Inactive &&
            !inboundSource.jobCandidateSourceSettings.desiredStateLastMadeActiveOn)
        : true
    );

    return formatCandidateSourceDetails(inboundSourceExplorerRows);
  }, [filterOutPreviouslyAddedInboundSources, sourceDisplaySectionData?.data]);
}

export function useGetJobAtsSourceSettingsByJobCandidateSourceSettings(
  jobCandidateSourceSetting: JobCandidateSourceSetting | undefined
): JobAtsSourceSetting[] {
  const { data: jobAtsSourceSettingsInfo } = useListJobAtsSourceSettingsQuery();

  return React.useMemo(() => {
    if (jobCandidateSourceSetting === undefined || jobAtsSourceSettingsInfo === undefined) {
      return [];
    }

    return jobAtsSourceSettingsInfo.filter(
      atsSourceSetting => atsSourceSetting.jobCandidateSourceSetting === jobCandidateSourceSetting.id
    );
  }, [jobAtsSourceSettingsInfo, jobCandidateSourceSetting]);
}

export function useGetDeduplicatedAtsSourcesByDisplaySection(atsType?: string): DedupedAtsSourcesInfoByDisplaySection {
  const { data: atsSources } = useLoadAtsSourcesQuery(atsType ? { atsType } : skipToken);

  return React.useMemo(() => {
    if (atsSources === undefined) {
      return { atsSourcesByDisplaySection: {}, atsSourcesNoDisplaySection: [] };
    }

    const dedupedAtsSourceByName: DedupedAtsSource[] = uniqBy(atsSources, "name").map(source =>
      pick(source, ["name", "atsType", "defaultState", "displaySection"])
    );

    return dedupedAtsSourceByName.reduce<DedupedAtsSourcesInfoByDisplaySection>(
      (acc, atsSource) => {
        if (atsSource.displaySection) {
          acc.atsSourcesByDisplaySection[atsSource.displaySection!] =
            acc.atsSourcesByDisplaySection[atsSource.displaySection!] || [];
          acc.atsSourcesByDisplaySection[atsSource.displaySection!].push(atsSource);
        } else {
          acc.atsSourcesNoDisplaySection.push(atsSource);
        }
        return acc;
      },
      { atsSourcesByDisplaySection: {}, atsSourcesNoDisplaySection: [] }
    );
  }, [atsSources]);
}
