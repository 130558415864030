import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import React, { FC, useEffect } from "react";
import { useMatch } from "react-router-dom";

import { SideBarNavItem } from "App/components/Nav/SideBarNavItemV2";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import CreateJobButton from "components/dover/CreateJob/CreateJobButton";
import { EyeCrossedOutIcon } from "components/icons/EyeIcon";
import { ButtonVariant } from "components/library/Button";
import { BodySmall, Caption } from "components/library/typography";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { DashboardJob } from "services/openapi";
import { colors } from "styles/theme";
import { composeSortJobs, sortJobsByTitle, sortJobsWithInactiveLast, sortJobsWithSnoozedLast } from "utils/job";
import { HomepageTabEnum } from "views/Homepage/types";

const SideNavJobsLoadingState: FC = () => (
  // Need important because mui override it's own styling
  <Box sx={{ marginTop: "32px !important" }}>
    <Progress size="small" />
  </Box>
);

interface SideNavJobsProps {
  jobs: Array<DashboardJob> | undefined;
}

/**
 * SideNavJobs
 * Creates a scrollable list of the authed users jobs, if there are any
 * If there are no jobs, this renders and empty UI
 * Grows to fill available vertical space in the side nav
 */
const SideNavJobs: FC<SideNavJobsProps> = ({ jobs }) => {
  const { jobsLoading, uninitialized } = useGetJobsDerivedData({
    sortFunc: composeSortJobs(sortJobsByTitle, sortJobsWithSnoozedLast, sortJobsWithInactiveLast),
  });

  // If switching between jobs we want to maintain the selected tab
  // These code figures out which tab we are on and we append it to the route later
  const match = useMatch("/job/:id/*");
  const jobTab = match && match.params["*"];

  // Loading State
  if (jobsLoading || uninitialized) {
    return <SideNavJobsLoadingState />;
  }

  // Empty State
  if (!jobs || jobs.length === 0) {
    return <BodySmall color={colors.grayscale.gray400}>{"No jobs added yet"}</BodySmall>;
  }

  return (
    <Stack height="100%" overflow="auto">
      {jobs.map((job, index) => {
        // This should never happen, but if there's missing data, return nothing
        if (!job.title || !job.id) {
          return null;
        }

        let jobPath = APP_ROUTE_PATHS.job.job(job.id);
        if (jobTab) {
          jobPath += `/${jobTab}`;
        }

        return (
          <SideBarNavItem
            key={job.id ?? index}
            to={jobPath}
            label={job.title}
            endAdornment={job.isPrivate ? <EyeCrossedOutIcon /> : undefined}
          />
        );
      })}
    </Stack>
  );
};

export const SideNavJobsContainer: FC = () => {
  const { authedUsersActiveJobs, notAuthedUsersActiveJobs } = useGetJobsDerivedData({
    sortFunc: composeSortJobs(sortJobsByTitle, sortJobsWithSnoozedLast, sortJobsWithInactiveLast),
  });

  const [selectedTab, setSelectedTab] = useLocalStorage<HomepageTabEnum>(
    "sideNavSelectedJobTab",
    HomepageTabEnum.MyJobs
  );

  // Default to correct tab based on what type of jobs they have
  useEffect(() => {
    // Default to other jobs if the user has no jobs
    if (selectedTab === HomepageTabEnum.MyJobs && !authedUsersActiveJobs?.length) {
      setSelectedTab(HomepageTabEnum.OtherJobs);
    }

    // Default to my jobs if the user has jobs
    if (selectedTab === HomepageTabEnum.OtherJobs && authedUsersActiveJobs?.length) {
      setSelectedTab(HomepageTabEnum.MyJobs);
    }
    // we should not be setting selectedTab as a dependency here, as it will run when the user selects a tab
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authedUsersActiveJobs?.length]);

  const jobs = selectedTab === HomepageTabEnum.MyJobs ? authedUsersActiveJobs : notAuthedUsersActiveJobs;

  return (
    <Stack height="100%" spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          {[HomepageTabEnum.MyJobs, HomepageTabEnum.OtherJobs].map(tab => (
            <Caption
              key={tab}
              color={selectedTab === tab ? colors.black : colors.grayscale.gray500}
              style={{
                fontSize: "14px",
                cursor: "pointer",
                borderBottom: selectedTab === tab ? `2px solid ${colors.primary.base}` : undefined,
                fontWeight: selectedTab === tab ? 600 : 400,
              }}
              onClick={(): void => {
                setSelectedTab(tab);
              }}
            >
              {tab}
            </Caption>
          ))}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <CreateJobButton
            buttonContent={<div>Job</div>}
            includePlusIcon
            buttonProps={{
              variant: ButtonVariant.GhostPrimary,
              height: "100%",
              padding: "4px 4px",
            }}
          />
        </Stack>
      </Stack>
      <SideNavJobs jobs={jobs} />
    </Stack>
  );
};
