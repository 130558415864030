import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useListCampaignsQuery, selectFromListCampaignsQueryResult } from "services/doverapi/endpoints/campaign";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { SlimEmailSenderAliasGmailCredentialAuthStateEnum } from "services/openapi";

function useJobHasActiveAndCompleteCampaigns(jobId: string | null | undefined): boolean | undefined {
  const { activeAndCompleteCampaigns } = useListCampaignsQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
  });

  return useMemo<boolean | undefined>(() => {
    if (!activeAndCompleteCampaigns) {
      return undefined;
    }
    return activeAndCompleteCampaigns.length > 0;
  }, [activeAndCompleteCampaigns]);
}

export function useJobHasActiveCampaignWithValidGmailAlias(jobId: string | null | undefined): boolean | undefined {
  const { activeAndCompleteCampaigns, isLoading: isLoadingCampaigns } = useListCampaignsQuery(
    jobId ? { jobId } : skipToken,
    {
      selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
    }
  );
  const { data: jobFeatures, isLoading: isLoadingJobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);

  const jobHasActiveAndCompleteCampaigns = useJobHasActiveAndCompleteCampaigns(jobId);
  return useMemo<boolean | undefined>(() => {
    if (isLoadingJobFeatures || isLoadingCampaigns) {
      return undefined;
    }
    if (!activeAndCompleteCampaigns || !jobFeatures) {
      return undefined;
    }
    if (jobFeatures.hasServicesEnabled) {
      return false;
    }
    return (
      jobHasActiveAndCompleteCampaigns &&
      activeAndCompleteCampaigns.some(
        campaign =>
          campaign.emailAlias?.gmailCredentialAuthState === SlimEmailSenderAliasGmailCredentialAuthStateEnum.Valid
      )
    );
  }, [
    jobHasActiveAndCompleteCampaigns,
    activeAndCompleteCampaigns,
    jobFeatures,
    isLoadingJobFeatures,
    isLoadingCampaigns,
  ]);
}
