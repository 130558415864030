import React from "react";
import { Control, Controller } from "react-hook-form";

import { Autocomplete, AutocompleteProps } from "components/library/Autocomplete/Autocomplete";

type ControlledAutocompleteProps = AutocompleteProps<any> & {
  control: Control<any>;
  name: string;
};

export const ControlledAutocomplete = ({
  control,
  name,
  ...autocompleteProps
}: ControlledAutocompleteProps): React.ReactElement => {
  return (
    <Controller
      render={({ field: { onChange }, ...props }): React.ReactElement => {
        return (
          <Autocomplete
            {...props}
            {...autocompleteProps}
            onSelectedOptionsChange={onChange}
            onSelectedOptionChange={onChange}
          />
        );
      }}
      name={name}
      control={control}
      rules={{ required: true, minLength: 2 }}
      defaultValue={autocompleteProps.multiple ? autocompleteProps.initialValues : autocompleteProps.initialValue}
    />
  );
};
