import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { GetOrCreateApiKeyCredentialResponse } from "services/openapi";

const clientApiKeyEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getOrCreateClientApi: build.query<GetOrCreateApiKeyCredentialResponse, string>({
      queryFn: async source => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getOrCreateApiKeyCredential({ data: { jobSource: source } });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useGetOrCreateClientApiQuery } = clientApiKeyEndpoints;
