/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject19
 */
export interface InlineObject19 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject19
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject19
     */
    job: string;
}

export function InlineObject19FromJSON(json: any): InlineObject19 {
    return InlineObject19FromJSONTyped(json, false);
}

export function InlineObject19FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject19 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'job': json['job'],
    };
}

export function InlineObject19ToJSON(value?: InlineObject19 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'job': value.job,
    };
}


