/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobOutreachStats
 */
export interface JobOutreachStats {
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    numSent: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    numEmailed: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    numResponded: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    numInterested: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    numActiveInterested: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    numOutboxCurrent: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    numOutboxDoverCurrent: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    numOutboxExternalCurrent: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    responseRate: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobOutreachStats
     */
    interestedRate: number | null;
}

export function JobOutreachStatsFromJSON(json: any): JobOutreachStats {
    return JobOutreachStatsFromJSONTyped(json, false);
}

export function JobOutreachStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobOutreachStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numSent': json['num_sent'],
        'numEmailed': json['num_emailed'],
        'numResponded': json['num_responded'],
        'numInterested': json['num_interested'],
        'numActiveInterested': json['num_active_interested'],
        'numOutboxCurrent': json['num_outbox_current'],
        'numOutboxDoverCurrent': json['num_outbox_dover_current'],
        'numOutboxExternalCurrent': json['num_outbox_external_current'],
        'responseRate': json['response_rate'],
        'interestedRate': json['interested_rate'],
    };
}

export function JobOutreachStatsToJSON(value?: JobOutreachStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'num_sent': value.numSent,
        'num_emailed': value.numEmailed,
        'num_responded': value.numResponded,
        'num_interested': value.numInterested,
        'num_active_interested': value.numActiveInterested,
        'num_outbox_current': value.numOutboxCurrent,
        'num_outbox_dover_current': value.numOutboxDoverCurrent,
        'num_outbox_external_current': value.numOutboxExternalCurrent,
        'response_rate': value.responseRate,
        'interested_rate': value.interestedRate,
    };
}


