import { TextField, Popper, ClickAwayListener, Autocomplete, Stack } from "@mui/material";
import { orderBy } from "lodash";
import React, { ChangeEvent, useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import { CLIENT_ID_QUERY_PARAM, DOVER_CLIENT_INFO } from "App/appConstants";
import { SIDE_NAV_POPPER_MIN_WIDTH } from "App/appConstants";
import { reloadPage } from "App/components/ClientImpersonator/utils";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { selectRequiresSelectedClient } from "domains/admin/selectors";
import { AdminDataReducerState } from "domains/admin/types";
import { GlobalRootState } from "domains/global/types";
import authConfig from "services/auth_config";
import { useAdminGetClientsQuery, useSetClientAliasMutation } from "services/doverapi/endpoints/client/endpoints";
import { AdminClient } from "services/openapi/models";
import { useAuth0 } from "services/react-auth0-spa";
import { reAuth } from "services/reAuth";
import { colors } from "styles/theme";

/**
 * ClientImpersonator
 * Allows admins only to select a client to impersonate via Auth0
 * Handles light dismiss of menus
 */
const ClientImpersonator: React.FC<AdminDataReducerState> = ({ requiresSelectedClient }) => {
  // Query data from backend
  const { data: clients, isLoading: clientsAreLoading } = useAdminGetClientsQuery();

  // Backend data mutations
  const [setClientAlias] = useSetClientAliasMutation();

  // Auth info
  const { user } = useAuth0();
  const { email: authedUsersEmail } = user;

  // Local state and query params
  const [aliasClient, setAliasClient] = React.useState<AdminClient | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectInputValue, setSelectInputValue] = useState<string>("");
  const [clientIdParam, setClientIdParam] = useQueryParam(CLIENT_ID_QUERY_PARAM, StringParam);

  // Other helpers
  const dispatch = useDispatch();

  // Derived data
  const sortedClients = React.useMemo(() => {
    return clients
      ? orderBy(
          clients,
          [
            (client: AdminClient): boolean => {
              return client.csm?.email !== authedUsersEmail;
            },
          ],
          ["asc"]
        )
      : undefined;
  }, [authedUsersEmail, clients]);

  // Indicate to admin when clients are loading or not found
  const buttonText = React.useMemo(() => {
    if (clientsAreLoading) {
      return "Loading...";
    }

    if (!sortedClients) {
      return "Clients not found";
    }

    return aliasClient ? aliasClient.realName ?? aliasClient.name : "Set Client";
  }, [aliasClient, clientsAreLoading, sortedClients]);

  // Callbacks
  const setClientAliasHelper = useCallback(
    (clientId: string) => {
      const trySetClientAlias = async (): Promise<void> => {
        const data = await setClientAlias(clientId).unwrap();
        if (data.success) {
          reAuth();
          reloadPage();
        }
      };

      trySetClientAlias();
    },
    [setClientAlias]
  );

  const handleChange = useCallback(
    (selectedClient: AdminClient | null): void => {
      if (selectedClient && selectedClient.id !== aliasClient?.id) {
        // Case when a client is selected
        setClientAliasHelper(selectedClient.id!);
        setClientIdParam(selectedClient.id);
      }
    },
    [aliasClient?.id, setClientAliasHelper, setClientIdParam]
  );

  // Effects

  // Set the client alias when the user is loaded
  useEffect(() => {
    if (!sortedClients) {
      return;
    }

    const clientId = user[authConfig.clientInfoUrl];
    if (!clientId) {
      return;
    }
    const client = sortedClients.find((c: AdminClient): boolean => c.id === clientId);

    if (client) {
      setAliasClient(client);
    }
  }, [sortedClients, dispatch, user]);

  useEffect(() => {
    if (requiresSelectedClient === undefined) {
      return;
    }

    const clientId = user[authConfig.clientInfoUrl];
    if (requiresSelectedClient) {
      if (!clientId) {
        // Case when a client must be selected and one isn't yet – set to Dover by default.
        setClientAliasHelper(DOVER_CLIENT_INFO.id!);
      } else if (!clientIdParam) {
        setClientIdParam(clientId);
      }
    }
  }, [clientIdParam, requiresSelectedClient, setClientAliasHelper, setClientIdParam, user]);

  if (requiresSelectedClient === false) {
    return <></>;
  }

  // The client impersonator will grow to fill all available space in its parent container
  // It automatically dismisses the popper when a click occurs outside of it
  return (
    <ClickAwayListener onClickAway={(): void => setAnchorEl(null)}>
      <div>
        <StyledButton
          variant={ButtonVariant.Ghost}
          removePadding
          onClick={(e): void => setAnchorEl(anchorEl ? null : e.currentTarget)}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <BodySmall>{buttonText}</BodySmall>
            <ChevronDownIcon className="svg-fill" color={colors.grayscale.gray700} width="20px" height="20px" />
          </Stack>
        </StyledButton>
        <Popper
          sx={{
            marginTop: "4px !important",
            zIndex: 1300,
            minWidth: `${SIDE_NAV_POPPER_MIN_WIDTH}px}`,
            "& .MuiInputBase-root": {
              backgroundColor: `${colors.white} !important`,
              border: `1px solid ${colors.grayscale.gray300}`,
            },
          }}
          id="client-alias-popper"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="right"
        >
          <Autocomplete
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: colors.white,
                border: `1px solid ${colors.grayscale.gray300}`,
              },
            }}
            options={sortedClients ?? []}
            getOptionLabel={(option): string => option.name}
            value={aliasClient}
            inputValue={selectInputValue}
            onInputChange={(event, newInputValue): void => {
              setSelectInputValue(newInputValue);
              event?.stopPropagation();
            }}
            onChange={(event: ChangeEvent<{}>, newValue: AdminClient | null): void => {
              handleChange(newValue);
              event?.stopPropagation();
            }}
            renderInput={(params): React.ReactNode => (
              <TextField
                {...params}
                label="Select Client"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
                sx={{ backgroundColor: colors.white }}
              />
            )}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state: GlobalRootState): AdminDataReducerState => {
  return {
    requiresSelectedClient: selectRequiresSelectedClient(state),
  };
};

const StyledButton = styled(Button)`
  padding: 6px;
  &:hover {
    background-color: ${colors.grayscale.gray200};
  }
`;

export default connect(mapStateToProps)(ClientImpersonator);
