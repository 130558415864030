import React from "react";
import { useParams } from "react-router-dom";

import ActivateFeatureConfirmationModal from "components/dover/feature-toggle-handler/ActivateFeatureConfirmationModal";
import { ServiceFeatureModalProps } from "components/dover/job-feature-card/types";
import { useUpsertJobFeatureSettingMutation } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import {
  JobFeatureFeatureNameEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

const ActivateDoverInterviewerModal = ({
  isModalOpen,
  toggleModalOff,
}: ServiceFeatureModalProps): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();
  const [forceLoading, setForceLoading] = React.useState<boolean>(false);

  const [upsertJobFeatureSetting] = useUpsertJobFeatureSettingMutation();

  const modalText =
    "Once setup is complete, approved candidates moving forward will be sent scheduling emails to schedule with a Dover Interviewer. Any candidates who were approved prior will still be sent scheduling emails to schedule with the previously listed first round interviewer.";

  const onConfirmEnable = React.useCallback(
    (onContinue?: () => void) => {
      setForceLoading(true);
      const tryUpsertFeatureSetting = async (): Promise<void> => {
        try {
          await upsertJobFeatureSetting({
            upsertData: {
              data: {
                job: jobId!,
                featureName: UpsertJobFeatureSettingFeatureNameEnum.DoverInterviewer,
                state: UpsertJobFeatureSettingStateEnum.Enabled,
              },
            },
          }).unwrap();

          // Ensure we have loaded the step we want to navigate to
          setTimeout(() => {
            toggleModalOff();
            onContinue?.();
          }, 1000);
          return;
        } catch (err) {
          console.error(err);
          showErrorToast("Failed to update job feature. Please refresh and try again.");
        }

        toggleModalOff();
      };

      tryUpsertFeatureSetting();
    },
    [jobId, toggleModalOff, upsertJobFeatureSetting]
  );

  return (
    <ActivateFeatureConfirmationModal
      isModalOpen={isModalOpen}
      featureName={JobFeatureFeatureNameEnum.DoverInterviewer}
      title="Dover Interviewer (Enabled)"
      body={modalText}
      toggleModalOff={toggleModalOff}
      onConfirmEnable={onConfirmEnable}
      forceLoading={forceLoading}
    />
  );
};

export default ActivateDoverInterviewerModal;
