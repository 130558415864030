/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateSourceNotReadyDetails
 */
export interface CandidateSourceNotReadyDetails {
    /**
     * 
     * @type {string}
     * @memberof CandidateSourceNotReadyDetails
     */
    readonly reason?: CandidateSourceNotReadyDetailsReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof CandidateSourceNotReadyDetails
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateSourceNotReadyDetails
     */
    actionLabel: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidateSourceNotReadyDetails
     */
    actionUrl: string | null;
}

/**
* @export
* @enum {string}
*/
export enum CandidateSourceNotReadyDetailsReasonEnum {
    CredentialsNotSupplied = 'CREDENTIALS_NOT_SUPPLIED',
    CredentialsInvalid = 'CREDENTIALS_INVALID',
    SourceIsGatedOnSetupSteps = 'SOURCE_IS_GATED_ON_SETUP_STEPS',
    SourceUnavailable = 'SOURCE_UNAVAILABLE',
    PaymentMethodRequired = 'PAYMENT_METHOD_REQUIRED'
}

export function CandidateSourceNotReadyDetailsFromJSON(json: any): CandidateSourceNotReadyDetails {
    return CandidateSourceNotReadyDetailsFromJSONTyped(json, false);
}

export function CandidateSourceNotReadyDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateSourceNotReadyDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'message': json['message'],
        'actionLabel': json['action_label'],
        'actionUrl': json['action_url'],
    };
}

export function CandidateSourceNotReadyDetailsToJSON(value?: CandidateSourceNotReadyDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'action_label': value.actionLabel,
        'action_url': value.actionUrl,
    };
}


