import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { FormSpy } from "react-final-form";

import { Spacer } from "components/Spacer";
import { useSubmitInterviewRubricResponseMutation } from "services/doverapi/endpoints/candidateInterview";
import InterviewRubric from "views/interview/common/components/InterviewRubric";
import InterviewRubricReview from "views/interview/common/components/InterviewRubricReview";
import { SelectAnswer, TextFieldAnswer } from "views/interview/common/components/InterviewRubricReview/AnswerRows";
import InterviewSelect from "views/interview/common/components/SelectField";
import StickyControls from "views/interview/common/components/StickyControls";
import InterviewTextField from "views/interview/common/components/TextField";

const AutoSave = ({ saveFn }) => {
  //debounce needs to be in a usecallback to memoize it and not create a new function every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const triggerSubmit = useCallback(
    debounce(({ values, valid, dirty }) => valid && dirty && saveFn(values), 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return <FormSpy onChange={triggerSubmit} subscription={{ values: true, valid: true, dirty: true }} />;
};

export const getComponentMapper = props =>
  props.interviewMode
    ? {
        "interview-rubric": {
          component: InterviewRubric,
          candidateInterviewInfo: props.candidateInterviewInfo,
          interviewContext: props?.interviewContext,
          globalDisabled: props?.disabled ?? false,
          companyAndJobPitch: props?.pitchCompanyAndJob,
          useDoverInterviewer: !!props?.candidateInterviewInfo?.useDoverInterviewer,
          existingRoughNotesAvailable: props?.existingRoughNotesAvailable,
          hasTranscript: props.hasTranscript,
          jobId: props?.jobId,
          clientId: props?.clientId,
        },
        select: InterviewSelect,
        "text-field": InterviewTextField,
      }
    : {
        "interview-rubric": {
          component: InterviewRubricReview,
          interviewRubricResponseId: props?.interviewRubricResponseId,
          candidate: props?.candidate,
          candidateInterviewInfo: props.candidateInterviewInfo,
          questionsOnly: props?.questionsOnly,
          interview: props?.interviewInfo,
          submittedByProUserId: props?.submittedByProUserId,
          hasTranscript: props.hasTranscript,
        },
        select: {
          component: SelectAnswer,
          questionsOnly: props?.questionsOnly,
        },
        "text-field": {
          component: TextFieldAnswer,
          questionsOnly: props?.questionsOnly,
        },
      };

export const InterviewRubricFormTemplate = ({
  schema,
  formFields,
  disabled,
  onSave,
  interviewInfo,
  candidateInterviewInfo,
  candidate,
  numTimesRescheduled,
  interviewRubricResponseId,
}) => {
  const { handleSubmit } = useFormApi();
  const [isSaving, setIsSaving] = useState(false);
  const [lastSaved, setLastSaved] = useState();

  const [, { isLoading: isSubmittingRubric }] = useSubmitInterviewRubricResponseMutation({
    fixedCacheKey: "submitting-irr",
  });

  const autoSave = async values => {
    // Don't trigger autosave if it's being submitted with the Submit button
    if (isSubmittingRubric) {
      return;
    }
    setIsSaving(true);
    await onSave(values);
    setIsSaving(false);

    setLastSaved(moment().format()); // update state outside of if so sandbox can fake saving
  };

  return (
    <form onSubmit={handleSubmit} style={{ height: "100%", width: "100%" }} className="sticky-controls">
      <AutoSave saveFn={autoSave} />
      <StickyControls
        interviewInfo={interviewInfo}
        candidateInterviewInfo={candidateInterviewInfo}
        candidate={candidate}
        inProgress={!disabled}
        lastSavedAt={lastSaved}
        isSaving={isSaving}
        numTimesRescheduled={numTimesRescheduled}
        interviewRubricResponseId={interviewRubricResponseId}
      />

      <Spacer height="30px" />
      {schema.title}
      {formFields}
    </form>
  );
};

export const SubmittedInterviewRubricFormTemplate = ({
  schema,
  formFields,
  disabled,
  onEdit,
  interviewInfo,
  interviewRubricResponseId,
  candidateInterviewInfo,
  candidate,
}) => {
  const { handleSubmit } = useFormApi();

  return (
    <form onSubmit={handleSubmit} style={{ height: "100%", width: "100%" }} className="sticky-controls">
      <StickyControls
        interviewInfo={interviewInfo}
        candidateInterviewInfo={candidateInterviewInfo}
        interviewRubricResponseId={interviewRubricResponseId}
        candidate={candidate}
        inProgress={!disabled}
        setEditing={onEdit}
      />

      <Spacer height="16px" />
      {schema.title}
      {formFields}
      <Spacer height="100px" />
    </form>
  );
};
