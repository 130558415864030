import MuiWarningIcon from "@mui/icons-material/Warning";
import { Box, Paper } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { theme } from "styles/theme";

export const ContentWrapper = styled(Paper)<{ minHeight?: string }>`
  padding: 24px;
  color: ${theme.colors.black};
  margin: 20px 0px;

  ${({ minHeight }: { minHeight?: string }): string | undefined => minHeight && `min-height: ${minHeight};`}
`;

export const HeaderH1 = styled.div`
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  margin-left: 6px;
  margin-bottom: 20px;
`;

export const StyledText = styled.p`
  font-family: "Inter";
  font-size: 16px;
  margin: 10px 6px;
`;

export const WarningIcon = (): React.ReactElement => {
  return <MuiWarningIcon sx={{ color: "#e0b563", marginRight: "0.5em" }} fontSize="small" />;
};

export const StyledEditText = styled.span`
  color: ${theme.colors.link};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledWarningBanner = styled(Box)`
  align-items: baseline;
  border: 1px solid ${theme.baseColors.warning};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  margin: 16px 0px;
`;
