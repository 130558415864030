import { Box, Stack } from "@mui/material";
import React from "react";

import { Body } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { filters } from "styles/theme";
import { WhiteCard } from "views/create-job/ExtensionJobCreationFlow/styles";

const LoadingNextStepCard = (): React.ReactElement => {
  return (
    <WhiteCard>
      <Stack width="580px" justifyContent="center">
        <DoverLoadingSpinner active={true} filter={filters.filterDarkGreen} />
        <Box textAlign="center">
          <Body>Loading next step...</Body>
        </Box>
      </Stack>
    </WhiteCard>
  );
};

export default LoadingNextStepCard;
