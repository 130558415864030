import { useAtomValue } from "jotai";
import { Dictionary, groupBy, keyBy } from "lodash";

import { CalibrationSettingAtom } from "components/dover/dover-outbound-modal/atoms/calibrationSettingAtoms";
import { SourcingCalibrationSettingOptions } from "components/dover/dover-outbound-modal/constants";
import { useUpdateJobSetupMutation } from "services/doverapi/endpoints/job";
import {
  useGetNotificationConfigsQuery,
  useUpsertNotificationConfigMutation,
} from "services/doverapi/endpoints/notificationConfig";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import {
  JobSetup,
  NotificationConfig,
  NotificationConfigChannelEnum,
  NotificationConfigNotifTypeEnum,
} from "services/openapi";

export const useGetJobNotifConfigs = (jobId?: string): Dictionary<NotificationConfig> | undefined => {
  const { notificationConfigsByJob } = useGetNotificationConfigsQuery(undefined, {
    selectFromResult: ({ data: configs }) => {
      const notificationConfigs: NotificationConfig[] = listAllEntities(configs);
      return {
        notificationConfigsByJob: groupBy<NotificationConfig>(
          notificationConfigs,
          (nc: NotificationConfig): string => nc.job
        ),
      };
    },
  });

  const jobNotificationConfigs = jobId ? notificationConfigsByJob?.[jobId] : [];
  const configsByType = keyBy<NotificationConfig>(
    jobNotificationConfigs,
    (nc: NotificationConfig): string => nc.notifType
  );

  return configsByType;
};

// Custom hook for getting current calibration setting
export const useGetCurrentCalibrationSetting = (job: JobSetup | undefined): SourcingCalibrationSettingOptions => {
  const autoDepthEnabled = job?.autoSearchAdjustmentsEnabled;
  const configsByType = useGetJobNotifConfigs(job?.id);

  // autoadjust enabled if autoadjust is on for the job and the notifications are off
  if (autoDepthEnabled) {
    return SourcingCalibrationSettingOptions.AutoAdjust;
  }
  // notify-me enabled if autoadjust is off for the job and the notifications are on
  const outOfDepthNotifsEnabled = configsByType?.[NotificationConfigNotifTypeEnum.OutOfDepthAlert]?.enabled;
  if (outOfDepthNotifsEnabled) {
    return SourcingCalibrationSettingOptions.NotifyMe;
  }
  // default to Recruiting Partner if the other two settings are off
  return SourcingCalibrationSettingOptions.EmbeddedRecruiter;
};

export interface UpdateCalibrationSettingsArgs {
  enablingAutoDepthAdjustments: boolean;
  enablingNotifications: boolean;
}

// Custom hook for updating calibration settings (auto-depth and notif configs)
export const useUpdateCalibrationSettings = ():
  | ((jobId: string, notifConfigId?: string) => Promise<any>)
  | undefined => {
  // Job & notif config mutations
  const [updateJobSetup] = useUpdateJobSetupMutation();
  const [upsertConfig] = useUpsertNotificationConfigMutation();

  // Derive args for mutations based on calibration setting atom
  const selectedCalibrationSetting = useAtomValue(CalibrationSettingAtom);
  const updateCalibrationSettingsArgs: UpdateCalibrationSettingsArgs = {
    enablingAutoDepthAdjustments: false,
    enablingNotifications: false,
  };

  if (!selectedCalibrationSetting) {
    return;
  }

  switch (selectedCalibrationSetting) {
    // Enabling auto-adjust
    case SourcingCalibrationSettingOptions.AutoAdjust:
      updateCalibrationSettingsArgs.enablingAutoDepthAdjustments = true;
      updateCalibrationSettingsArgs.enablingNotifications = false;
      break;

    // Enabling ood notifications
    case SourcingCalibrationSettingOptions.NotifyMe:
      updateCalibrationSettingsArgs.enablingAutoDepthAdjustments = false;
      updateCalibrationSettingsArgs.enablingNotifications = true;
      break;

    // Enabling Recruiting Partner (i.e. disabling above two)
    case SourcingCalibrationSettingOptions.EmbeddedRecruiter:
      updateCalibrationSettingsArgs.enablingAutoDepthAdjustments = false;
      updateCalibrationSettingsArgs.enablingNotifications = false;
      break;
    default:
      console.error("Invalid calibration setting");
      break;
  }

  return async (jobId: string, notifConfigId?: string): Promise<any> => {
    await Promise.all([
      updateJobSetup({
        id: jobId,
        autoSearchAdjustmentsEnabled: updateCalibrationSettingsArgs.enablingAutoDepthAdjustments,
      }),
      upsertConfig({
        enabled: updateCalibrationSettingsArgs.enablingNotifications,
        job: jobId,
        notifType: NotificationConfigNotifTypeEnum.OutOfDepthAlert,
        id: notifConfigId ?? undefined,
        channel: NotificationConfigChannelEnum.Slack,
      }),
    ]);
  };
};
