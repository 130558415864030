import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Autocomplete, AutocompleteRenderGetTagProps, Chip, Stack, TextField } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import { useInviteNewUsersForm } from "components/InviteNewUsers/hooks";
import RawEditor from "components/library/TipTap/RawEditor";
import { BasicEmailOption } from "components/library/TipTap/types";
import { constructFromFieldString } from "components/library/TipTap/utils";
import { Subtitle2, Caption } from "components/library/typography";
import { useListClientDomainsQuery } from "services/doverapi/endpoints/company-setup/endpoints";
import { colors } from "styles/theme";

const InviteNewUsersForm = ({
  emailAddresses,
  setEmailAddresses,
  emailAddressesAreValid,
  setCustomEmailMessage,
  inputValue,
  setInputValue,
}: {
  emailAddresses: BasicEmailOption[];
  setEmailAddresses: (emailAddresses: BasicEmailOption[]) => void;
  emailAddressesAreValid: boolean;
  customEmailMessage: string;
  inputValue: string;
  setInputValue: (inputValue: string) => void;
  setCustomEmailMessage: (customEmailMessage: string) => void;
}): React.ReactElement => {
  const { data: clientDomains } = useListClientDomainsQuery({});
  const { emailAddressIsValid } = useInviteNewUsersForm();

  return (
    <>
      <Stack spacing={0.5}>
        <Subtitle2>Enter email addresses</Subtitle2>
        <Autocomplete
          multiple
          freeSolo
          filterSelectedOptions
          options={[]}
          value={emailAddresses}
          isOptionEqualToValue={(option: BasicEmailOption, value: BasicEmailOption): boolean => {
            return option.email === value.email;
          }}
          onChange={(_event: React.ChangeEvent<{}>, value: (string | BasicEmailOption)[]): void => {
            const updatedEmailAddresses: BasicEmailOption[] = value.map(option => {
              if (typeof option === "string") {
                return { email: option, id: uuidv4() } as BasicEmailOption;
              }
              return option;
            });
            setInputValue("");
            setEmailAddresses(updatedEmailAddresses);
          }}
          renderInput={(params): React.ReactElement => (
            <TextField
              {...params}
              value={inputValue}
              size="medium"
              placeholder={emailAddresses && emailAddresses.length > 0 ? undefined : "Type an email"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                setInputValue(event.target.value);
              }}
            />
          )}
          fullWidth
          renderTags={(value: BasicEmailOption[], getTagProps: AutocompleteRenderGetTagProps): React.ReactNode => {
            return value.map((option, index) => {
              const isInvalid = !emailAddressIsValid(option.email);
              return (
                <Chip
                  variant="outlined"
                  label={constructFromFieldString(option.label, option.email)}
                  size="medium"
                  deleteIcon={<HighlightOffSharpIcon />}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: isInvalid ? colors.critical.light : colors.grayscale.gray100,
                    borderColor: isInvalid ? colors.critical.base : "transparent",
                    ".MuiSvgIcon-root": {
                      color: colors.grayscale.gray500,
                    },
                  }}
                />
              );
            });
          }}
        />
        <Caption color={emailAddressesAreValid ? undefined : colors.critical.base} italic>
          Emails must include your company domain ({clientDomains?.map(clientDomain => clientDomain.domain).join(", ")}
          ). Anyone logging into Dover with your company domain email will be automatically added to your team.
        </Caption>
      </Stack>
      <Stack spacing={0.5}>
        <Subtitle2>Include a message (optional)</Subtitle2>
        <RawEditor initialContent="" onContentChange={setCustomEmailMessage} />
      </Stack>
    </>
  );
};

export default InviteNewUsersForm;
