/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HiringPipelineStageMilestone,
    HiringPipelineStageMilestoneFromJSON,
    HiringPipelineStageMilestoneFromJSONTyped,
    HiringPipelineStageMilestoneToJSON,
    HiringPipelineStageType,
    HiringPipelineStageTypeFromJSON,
    HiringPipelineStageTypeFromJSONTyped,
    HiringPipelineStageTypeToJSON,
    JobInterviewSubStageStats,
    JobInterviewSubStageStatsFromJSON,
    JobInterviewSubStageStatsFromJSONTyped,
    JobInterviewSubStageStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobInterviewStageStats
 */
export interface JobInterviewStageStats {
    /**
     * 
     * @type {string}
     * @memberof JobInterviewStageStats
     */
    hpsName: string;
    /**
     * 
     * @type {HiringPipelineStageType}
     * @memberof JobInterviewStageStats
     */
    hpsStageType?: HiringPipelineStageType;
    /**
     * 
     * @type {HiringPipelineStageMilestone}
     * @memberof JobInterviewStageStats
     */
    hpsMilestone?: HiringPipelineStageMilestone | null;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewStageStats
     */
    orderIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof JobInterviewStageStats
     */
    hpsId: string;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewStageStats
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewStageStats
     */
    active: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewStageStats
     */
    rejected: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewStageStats
     */
    withdrew: number | null;
    /**
     * 
     * @type {number}
     * @memberof JobInterviewStageStats
     */
    snoozed: number | null;
    /**
     * 
     * @type {Array<JobInterviewSubStageStats>}
     * @memberof JobInterviewStageStats
     */
    subgroups: Array<JobInterviewSubStageStats>;
}

export function JobInterviewStageStatsFromJSON(json: any): JobInterviewStageStats {
    return JobInterviewStageStatsFromJSONTyped(json, false);
}

export function JobInterviewStageStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobInterviewStageStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hpsName': json['hps_name'],
        'hpsStageType': !exists(json, 'hps_stage_type') ? undefined : HiringPipelineStageTypeFromJSON(json['hps_stage_type']),
        'hpsMilestone': !exists(json, 'hps_milestone') ? undefined : HiringPipelineStageMilestoneFromJSON(json['hps_milestone']),
        'orderIndex': !exists(json, 'order_index') ? undefined : json['order_index'],
        'hpsId': json['hps_id'],
        'total': json['total'],
        'active': json['active'],
        'rejected': json['rejected'],
        'withdrew': json['withdrew'],
        'snoozed': json['snoozed'],
        'subgroups': ((json['subgroups'] as Array<any>).map(JobInterviewSubStageStatsFromJSON)),
    };
}

export function JobInterviewStageStatsToJSON(value?: JobInterviewStageStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hps_name': value.hpsName,
        'hps_stage_type': HiringPipelineStageTypeToJSON(value.hpsStageType),
        'hps_milestone': HiringPipelineStageMilestoneToJSON(value.hpsMilestone),
        'order_index': value.orderIndex,
        'hps_id': value.hpsId,
        'total': value.total,
        'active': value.active,
        'rejected': value.rejected,
        'withdrew': value.withdrew,
        'snoozed': value.snoozed,
        'subgroups': ((value.subgroups as Array<any>).map(JobInterviewSubStageStatsToJSON)),
    };
}


