/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientEmailProvider,
    ClientEmailProviderFromJSON,
    ClientEmailProviderFromJSONTyped,
    ClientEmailProviderToJSON,
    DoverEntity,
    DoverEntityFromJSON,
    DoverEntityFromJSONTyped,
    DoverEntityToJSON,
    EmailAlias,
    EmailAliasFromJSON,
    EmailAliasFromJSONTyped,
    EmailAliasToJSON,
    StripePaymentMethod,
    StripePaymentMethodFromJSON,
    StripePaymentMethodFromJSONTyped,
    StripePaymentMethodToJSON,
} from './';

/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    slug?: string;
    /**
     * 
     * @type {DoverEntity}
     * @memberof Client
     */
    csm?: DoverEntity | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    realName: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    doverPlan?: ClientDoverPlanEnum;
    /**
     * Indicates what primary messaging provider the client uses
     * @type {string}
     * @memberof Client
     */
    messagingProvider?: ClientMessagingProviderEnum;
    /**
     * Indicates the non-Dover supported messaging provider that a client uses
     * @type {string}
     * @memberof Client
     */
    messagingProviderOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    readonly hasDefaultPaymentMethod?: string;
    /**
     * 
     * @type {StripePaymentMethod}
     * @memberof Client
     */
    defaultStripePaymentMethod: StripePaymentMethod | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    paymentMethodCollectionExperiment?: ClientPaymentMethodCollectionExperimentEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Client
     */
    readonly atsScopes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    atsType?: ClientAtsTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Client
     */
    readonly shellEmails?: Array<string>;
    /**
     * 
     * @type {ClientEmailProvider}
     * @memberof Client
     */
    emailProvider: ClientEmailProvider;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    readonly useReplyToSchedulingOverride?: boolean | null;
    /**
     * This column stores a 45 min joint calendly link with the job's CSM/CX. Used in the Schedule Onboarding step in the JPQ
     * @type {string}
     * @memberof Client
     */
    kickoffCallSchedulingLinkOverride?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    customerFacingNotes?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Client
     */
    paymentMethodCollectedOn?: Date | null;
    /**
     * Slack ID of dover-client-admin channel
     * @type {string}
     * @memberof Client
     */
    adminChannelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    linkedinCompanyId?: string | null;
    /**
     * 
     * @type {EmailAlias}
     * @memberof Client
     */
    clientSchedulingEmailAlias?: EmailAlias;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    enableTalentNetwork?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    isTam?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    isIcp?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    region?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    isAgency?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Client
     */
    readonly usesEmbeddedRecruiter?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ClientDoverPlanEnum {
    NoAccess = 'NO_ACCESS',
    Free = 'FREE',
    Credits = 'CREDITS',
    PayAsYouGo = 'PAY_AS_YOU_GO',
    JobSlots = 'JOB_SLOTS',
    Growth = 'GROWTH',
    Concierge = 'CONCIERGE',
    FreeAts = 'FREE_ATS',
    FreeExtension = 'FREE_EXTENSION'
}/**
* @export
* @enum {string}
*/
export enum ClientMessagingProviderEnum {
    Slack = 'SLACK',
    Other = 'OTHER'
}/**
* @export
* @enum {string}
*/
export enum ClientPaymentMethodCollectionExperimentEnum {
    OnSignup = 'ON_SIGNUP',
    AfterEnablingSourcing = 'AFTER_ENABLING_SOURCING',
    AfterEnablingAutopilotOrSending = 'AFTER_ENABLING_AUTOPILOT_OR_SENDING',
    OnPayAsYouGoUpgrade = 'ON_PAY_AS_YOU_GO_UPGRADE'
}/**
* @export
* @enum {string}
*/
export enum ClientAtsTypeEnum {
    Greenhouse = 'GREENHOUSE',
    Lever = 'LEVER',
    Ashby = 'ASHBY',
    GoogleHire = 'GOOGLE_HIRE',
    NoAts = 'NO_ATS',
    Other = 'OTHER',
    NotRequired = 'NOT_REQUIRED'
}

export function ClientFromJSON(json: any): Client {
    return ClientFromJSONTyped(json, false);
}

export function ClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): Client {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'csm': !exists(json, 'csm') ? undefined : DoverEntityFromJSON(json['csm']),
        'name': json['name'],
        'realName': json['real_name'],
        'doverPlan': !exists(json, 'dover_plan') ? undefined : json['dover_plan'],
        'messagingProvider': !exists(json, 'messaging_provider') ? undefined : json['messaging_provider'],
        'messagingProviderOther': !exists(json, 'messaging_provider_other') ? undefined : json['messaging_provider_other'],
        'hasDefaultPaymentMethod': !exists(json, 'has_default_payment_method') ? undefined : json['has_default_payment_method'],
        'defaultStripePaymentMethod': StripePaymentMethodFromJSON(json['default_stripe_payment_method']),
        'paymentMethodCollectionExperiment': !exists(json, 'payment_method_collection_experiment') ? undefined : json['payment_method_collection_experiment'],
        'atsScopes': !exists(json, 'ats_scopes') ? undefined : json['ats_scopes'],
        'atsType': !exists(json, 'ats_type') ? undefined : json['ats_type'],
        'shellEmails': !exists(json, 'shell_emails') ? undefined : json['shell_emails'],
        'emailProvider': ClientEmailProviderFromJSON(json['email_provider']),
        'useReplyToSchedulingOverride': !exists(json, 'use_reply_to_scheduling_override') ? undefined : json['use_reply_to_scheduling_override'],
        'kickoffCallSchedulingLinkOverride': !exists(json, 'kickoff_call_scheduling_link_override') ? undefined : json['kickoff_call_scheduling_link_override'],
        'customerFacingNotes': !exists(json, 'customer_facing_notes') ? undefined : json['customer_facing_notes'],
        'paymentMethodCollectedOn': !exists(json, 'payment_method_collected_on') ? undefined : (json['payment_method_collected_on'] === null ? null : new Date(json['payment_method_collected_on'])),
        'adminChannelId': !exists(json, 'admin_channel_id') ? undefined : json['admin_channel_id'],
        'linkedinCompanyId': !exists(json, 'linkedin_company_id') ? undefined : json['linkedin_company_id'],
        'clientSchedulingEmailAlias': !exists(json, 'client_scheduling_email_alias') ? undefined : EmailAliasFromJSON(json['client_scheduling_email_alias']),
        'enableTalentNetwork': !exists(json, 'enable_talent_network') ? undefined : json['enable_talent_network'],
        'isTam': !exists(json, 'is_tam') ? undefined : json['is_tam'],
        'isIcp': !exists(json, 'is_icp') ? undefined : json['is_icp'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'isAgency': !exists(json, 'is_agency') ? undefined : json['is_agency'],
        'usesEmbeddedRecruiter': !exists(json, 'uses_embedded_recruiter') ? undefined : json['uses_embedded_recruiter'],
    };
}

export function ClientToJSON(value?: Client | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'csm': DoverEntityToJSON(value.csm),
        'name': value.name,
        'real_name': value.realName,
        'dover_plan': value.doverPlan,
        'messaging_provider': value.messagingProvider,
        'messaging_provider_other': value.messagingProviderOther,
        'default_stripe_payment_method': StripePaymentMethodToJSON(value.defaultStripePaymentMethod),
        'payment_method_collection_experiment': value.paymentMethodCollectionExperiment,
        'ats_type': value.atsType,
        'email_provider': ClientEmailProviderToJSON(value.emailProvider),
        'kickoff_call_scheduling_link_override': value.kickoffCallSchedulingLinkOverride,
        'customer_facing_notes': value.customerFacingNotes,
        'payment_method_collected_on': value.paymentMethodCollectedOn === undefined ? undefined : (value.paymentMethodCollectedOn === null ? null : value.paymentMethodCollectedOn.toISOString()),
        'admin_channel_id': value.adminChannelId,
        'linkedin_company_id': value.linkedinCompanyId,
        'client_scheduling_email_alias': EmailAliasToJSON(value.clientSchedulingEmailAlias),
        'enable_talent_network': value.enableTalentNetwork,
        'is_tam': value.isTam,
        'is_icp': value.isIcp,
        'region': value.region,
        'is_agency': value.isAgency,
    };
}


