import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";

// Reusable tooltip for disabling autopilot activation (has hyperlinks)
const DisabledAutopilotActivationTooltip = ({ jobId }: { jobId: string }): React.ReactElement => {
  const outreachTabLink = APP_ROUTE_PATHS.job.outreach(jobId);

  // Remove hyperlinks in new sourcing page
  const useSourcingPage = useFeatureFlag(FeatureFlag.SourcingPage);
  const oneActiveCampaign = useSourcingPage ? (
    "1 active outreach campaign"
  ) : (
    <a href={outreachTabLink}>1 active outreach campaign</a>
  );

  return <span>There must be at least 1 active search and {oneActiveCampaign} to enable Sourcing</span>;
};

export default DisabledAutopilotActivationTooltip;
