import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { useQueryParam } from "use-query-params";

import { Subtitle1 } from "components/library/typography";
import { flowLabels } from "sections/addcandidate/data/flowConfig";
import { HEADER_HEIGHT } from "sections/addcandidate/data/misc";
import { FLOW_TYPE_QUERY_PARAM, OUTREACH_TYPE_QUERY_PARAM } from "sections/addcandidate/data/queryParams";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { FlowTypes, FlowType, FlowTypeParam, OutreachTypeParam } from "sections/addcandidate/types";
import { colors } from "styles/theme";

const FlowNav = (): React.ReactElement => {
  const { flowType: activeFlowType } = useAddCandidateContext();
  const [, setFlowTypeParam] = useQueryParam(FLOW_TYPE_QUERY_PARAM, FlowTypeParam);
  const [, setOutreachTypeParam] = useQueryParam(OUTREACH_TYPE_QUERY_PARAM, OutreachTypeParam);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: FlowType): void => {
    setFlowTypeParam(newValue);
    if (newValue === FlowTypes.INTERVIEW_PIPELINE) {
      setOutreachTypeParam(undefined);
    }
  };

  return (
    <Stack
      direction="column"
      sx={{ p: 0, width: "100%", position: "sticky", top: HEADER_HEIGHT }}
      spacing={3}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Tabs
        value={activeFlowType}
        onChange={handleTabChange}
        sx={{
          width: "100%",
          backgroundColor: colors.grayscale.gray050,
          "& .MuiTabs-indicator": {
            backgroundColor: `${colors.primary.dark}`,
          },
        }}
        variant="fullWidth"
      >
        {Object.values(FlowTypes).map(flowType => (
          <Tab
            value={flowType}
            sx={{
              textTransform: "none",
              "&.Mui-selected": {
                outline: "none",
              },
            }}
            label={<Subtitle1>{flowLabels[flowType]}</Subtitle1>}
          />
        ))}
      </Tabs>
    </Stack>
  );
};

export default FlowNav;
