import { meta } from "@data-driven-forms/common/prop-types-templates";
import PropTypes from "prop-types";
import React from "react";

import { FormFieldGrid, validationError } from "components/data-driven-forms/fields/helpers";
import MultiSelect from "components/inputs/MultiSelect";

const parseValue = (value, options) => {
  if (!value || !value.length > 0) {
    return value;
  }

  return value.map(v => {
    return {
      value: v,
      label: options.find(o => o.value === v).label,
    };
  });
};

const InternalMultiSelect = ({
  value,
  options,
  label,
  helperText,
  validateOnMount,
  meta,
  isSearchable,
  description,
  placeholder,
  required,
  onChange,
  onFocus,
  onBlur,
  FormFieldGridProps,
  maxOptions,
  textFieldLabel,
  ...rest
}) => {
  const invalid = validationError(meta, validateOnMount);
  const internalValue = parseValue(value, options);

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <MultiSelect
        {...rest}
        label={label}
        maxOptions={maxOptions}
        TextFieldProps={{
          onFocus,
          onBlur,
          required,
          invalid: !!invalid,
          label: textFieldLabel,
          helperText: invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description,
          isSearchable,
          placeholder: !internalValue || internalValue.length === 0 ? placeholder : undefined,
        }}
        options={options}
        value={typeof internalValue === "undefined" ? null : internalValue}
        onChange={(_event, option) => {
          onChange(option.map(o => o.value));
        }}
      />
    </FormFieldGrid>
  );
};

InternalMultiSelect.propTypes = {
  meta,
  placeholder: PropTypes.node,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.node.isRequired }))
    .isRequired,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  maxOptions: PropTypes.number,
  textFieldLabel: PropTypes.string,
};

InternalMultiSelect.defaultProps = {
  placeholder: "Please choose",
  FormFieldGridProps: {},
  inputProps: {},
  isSearchable: true,
  textFieldLabel: "Choose...",
};

export default InternalMultiSelect;
