import { useMemo } from "react";
import { matchPath, useLocation, useParams, useMatches } from "react-router-dom";

import { FALLBACK_ROUTE } from "App/routing/route-path-constants";
import { IndivididualRouteConfigMetaData, UseMatchesResponse } from "App/routing/types";

export function useMatchPath(paths: string[]): boolean {
  const location = useLocation();

  return paths.some(path => {
    const match = matchPath({ path }, location.pathname);
    return match != null;
  });
}

export function useGetTemplatedRoutePath(): string | undefined {
  const location = useLocation();
  const params = useParams();

  if (!params) {
    return undefined;
  }

  // For each param, replace the value in the location path with the param name.
  // This will give us the path to the route template.
  const routeTemplatePath = Object.keys(params).reduce((path, param) => {
    // Safe cast since we're pulling params straight from the url.
    const value: string = params[param] as string;
    return path.replace(value, `:${param}`);
  }, location.pathname);

  return routeTemplatePath;
}

/**
 * Like useMatches but only returns match(s) matching the pathname exactly
 */
export function useExactMatches(): UseMatchesResponse {
  const { pathname } = useLocation();
  const routeMatches = useMatches();
  return useMemo(() => {
    // just get the lowest level routes to match
    return routeMatches.filter(route => route.pathname === pathname);
  }, [pathname, routeMatches]);
}

/**
 * Check if we are on the /* 404 page
 */
export function useIsOnFallbackNotFoundPage(): boolean {
  const routeMatches = useExactMatches();
  return useMemo(() => {
    return routeMatches.some(route => route.pathname === FALLBACK_ROUTE);
  }, [routeMatches]);
}

/**
 * If the page does not require authentication, and consider it a public route and return True
 */
export function useIsOnPublicRoute(): boolean {
  const routeMatches = useExactMatches();
  return useMemo(() => {
    return !routeMatches.some(
      route => (route.handle as IndivididualRouteConfigMetaData)?.authedRouteMetaData?.requiresAuth
    );
  }, [routeMatches]);
}
