/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Interview,
    InterviewFromJSON,
    InterviewFromJSONTyped,
    InterviewToJSON,
    NextActionHiringPipelineStage,
    NextActionHiringPipelineStageFromJSON,
    NextActionHiringPipelineStageFromJSONTyped,
    NextActionHiringPipelineStageToJSON,
} from './';

/**
 * 
 * @export
 * @interface NextAction
 */
export interface NextAction {
    /**
     * 
     * @type {string}
     * @memberof NextAction
     */
    state: NextActionStateEnum;
    /**
     * 
     * @type {string}
     * @memberof NextAction
     */
    schedulingState: NextActionSchedulingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof NextAction
     */
    owner: NextActionOwnerEnum;
    /**
     * 
     * @type {boolean}
     * @memberof NextAction
     */
    pendingCustomerResponse: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NextAction
     */
    isLastInterview: boolean;
    /**
     * 
     * @type {Interview}
     * @memberof NextAction
     */
    currentInterview?: Interview;
    /**
     * 
     * @type {Interview}
     * @memberof NextAction
     */
    nextInterview?: Interview;
    /**
     * 
     * @type {NextActionHiringPipelineStage}
     * @memberof NextAction
     */
    hiringPipelineStage?: NextActionHiringPipelineStage;
    /**
     * 
     * @type {NextActionHiringPipelineStage}
     * @memberof NextAction
     */
    nextHiringPipelineStage?: NextActionHiringPipelineStage;
    /**
     * 
     * @type {string}
     * @memberof NextAction
     */
    initialCandidateReply?: string;
}

/**
* @export
* @enum {string}
*/
export enum NextActionStateEnum {
    NoActionRequired = 'NO_ACTION_REQUIRED',
    NeedsAction = 'NEEDS_ACTION'
}/**
* @export
* @enum {string}
*/
export enum NextActionSchedulingStateEnum {
    NewLead = 'NEW_LEAD',
    Scheduling = 'SCHEDULING',
    Scheduled = 'SCHEDULED',
    InitialReview = 'INITIAL_REVIEW',
    CallCompleted = 'CALL_COMPLETED',
    OfferExtended = 'OFFER_EXTENDED'
}/**
* @export
* @enum {string}
*/
export enum NextActionOwnerEnum {
    User = 'USER',
    Dover = 'DOVER'
}

export function NextActionFromJSON(json: any): NextAction {
    return NextActionFromJSONTyped(json, false);
}

export function NextActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'schedulingState': json['scheduling_state'],
        'owner': json['owner'],
        'pendingCustomerResponse': json['pending_customer_response'],
        'isLastInterview': json['is_last_interview'],
        'currentInterview': !exists(json, 'current_interview') ? undefined : InterviewFromJSON(json['current_interview']),
        'nextInterview': !exists(json, 'next_interview') ? undefined : InterviewFromJSON(json['next_interview']),
        'hiringPipelineStage': !exists(json, 'hiring_pipeline_stage') ? undefined : NextActionHiringPipelineStageFromJSON(json['hiring_pipeline_stage']),
        'nextHiringPipelineStage': !exists(json, 'next_hiring_pipeline_stage') ? undefined : NextActionHiringPipelineStageFromJSON(json['next_hiring_pipeline_stage']),
        'initialCandidateReply': !exists(json, 'initial_candidate_reply') ? undefined : json['initial_candidate_reply'],
    };
}

export function NextActionToJSON(value?: NextAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'scheduling_state': value.schedulingState,
        'owner': value.owner,
        'pending_customer_response': value.pendingCustomerResponse,
        'is_last_interview': value.isLastInterview,
        'current_interview': InterviewToJSON(value.currentInterview),
        'next_interview': InterviewToJSON(value.nextInterview),
        'hiring_pipeline_stage': NextActionHiringPipelineStageToJSON(value.hiringPipelineStage),
        'next_hiring_pipeline_stage': NextActionHiringPipelineStageToJSON(value.nextHiringPipelineStage),
        'initial_candidate_reply': value.initialCandidateReply,
    };
}


