/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetrieveEmailsForLinkedinPublicUrlRequest
 */
export interface RetrieveEmailsForLinkedinPublicUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof RetrieveEmailsForLinkedinPublicUrlRequest
     */
    job?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RetrieveEmailsForLinkedinPublicUrlRequest
     */
    linkedinPublicUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof RetrieveEmailsForLinkedinPublicUrlRequest
     */
    attemptEmailFinding: boolean;
}

export function RetrieveEmailsForLinkedinPublicUrlRequestFromJSON(json: any): RetrieveEmailsForLinkedinPublicUrlRequest {
    return RetrieveEmailsForLinkedinPublicUrlRequestFromJSONTyped(json, false);
}

export function RetrieveEmailsForLinkedinPublicUrlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetrieveEmailsForLinkedinPublicUrlRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'job': !exists(json, 'job') ? undefined : json['job'],
        'linkedinPublicUrl': json['linkedin_public_url'],
        'attemptEmailFinding': json['attempt_email_finding'],
    };
}

export function RetrieveEmailsForLinkedinPublicUrlRequestToJSON(value?: RetrieveEmailsForLinkedinPublicUrlRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': value.job,
        'linkedin_public_url': value.linkedinPublicUrl,
        'attempt_email_finding': value.attemptEmailFinding,
    };
}


