/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignMessageRequestCampaignMessage,
    CampaignMessageRequestCampaignMessageFromJSON,
    CampaignMessageRequestCampaignMessageFromJSONTyped,
    CampaignMessageRequestCampaignMessageToJSON,
    CampaignMessageRequestEmailAlias,
    CampaignMessageRequestEmailAliasFromJSON,
    CampaignMessageRequestEmailAliasFromJSONTyped,
    CampaignMessageRequestEmailAliasToJSON,
    CampaignMessageRequestEmailSendRequest,
    CampaignMessageRequestEmailSendRequestFromJSON,
    CampaignMessageRequestEmailSendRequestFromJSONTyped,
    CampaignMessageRequestEmailSendRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface CampaignMessageRequest
 */
export interface CampaignMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequest
     */
    readonly id?: string;
    /**
     * 
     * @type {CampaignMessageRequestCampaignMessage}
     * @memberof CampaignMessageRequest
     */
    message: CampaignMessageRequestCampaignMessage;
    /**
     * 
     * @type {CampaignMessageRequestEmailSendRequest}
     * @memberof CampaignMessageRequest
     */
    emailSendRequest?: CampaignMessageRequestEmailSendRequest | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequest
     */
    readonly subject?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequest
     */
    readonly body?: string;
    /**
     * 
     * @type {CampaignMessageRequestEmailAlias}
     * @memberof CampaignMessageRequest
     */
    emailAlias?: CampaignMessageRequestEmailAlias | null;
    /**
     * 
     * @type {string}
     * @memberof CampaignMessageRequest
     */
    readonly campaignId?: string;
}

export function CampaignMessageRequestFromJSON(json: any): CampaignMessageRequest {
    return CampaignMessageRequestFromJSONTyped(json, false);
}

export function CampaignMessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignMessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'message': CampaignMessageRequestCampaignMessageFromJSON(json['message']),
        'emailSendRequest': !exists(json, 'email_send_request') ? undefined : CampaignMessageRequestEmailSendRequestFromJSON(json['email_send_request']),
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'emailAlias': !exists(json, 'email_alias') ? undefined : CampaignMessageRequestEmailAliasFromJSON(json['email_alias']),
        'campaignId': !exists(json, 'campaign_id') ? undefined : json['campaign_id'],
    };
}

export function CampaignMessageRequestToJSON(value?: CampaignMessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': CampaignMessageRequestCampaignMessageToJSON(value.message),
        'email_send_request': CampaignMessageRequestEmailSendRequestToJSON(value.emailSendRequest),
        'email_alias': CampaignMessageRequestEmailAliasToJSON(value.emailAlias),
    };
}


