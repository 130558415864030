import { Stack } from "@mui/material";
import React from "react";

import DoverInterviewerHowItWorksContent from "components/DIHowItWorks";
import InterviewerSplashPage from "views/Interviewer/SplashPage";

const DoverInterviewerPage = (): React.ReactElement => {
  return (
    <Stack spacing={2} pb={4}>
      <InterviewerSplashPage />
      <DoverInterviewerHowItWorksContent />
    </Stack>
  );
};

export default DoverInterviewerPage;
