/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import Label from "components/inputs/AsyncMultiSelect/Label";
import {
  AutocompleteStyles,
  Tag,
  CheckboxStyles,
  CheckboxLabelStyles,
} from "components/inputs/AsyncMultiSelect/styles";

const AsyncMultiSelect = ({
  fetch,
  label,
  getOptionSelected,
  onChange,
  value: internalValue,
  getOptionLabel,
  maxOptions,
  TextFieldProps: { label: textFieldLabel, helperText, invalid, required },
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valueSet, setValueSet] = useState(!(!internalValue || internalValue.length === 0));
  const [value, setValue] = useState(internalValue || []);

  const fetchData = useCallback(
    debounce(request => {
      (async () => {
        setLoading(true);
        try {
          const resp = await fetch(request);
          setOptions(resp.results);
        } catch (err) {
          console.error(err);
        }
        setLoading(false);
      })();
    }, 500),
    [fetch]
  );

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <AutocompleteStyles>
      <Label maxOptions={maxOptions} label={label} required={required} />
      <Autocomplete
        {...rest}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple
        disableCloseOnSelect
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={(event, newValue) => {
          setValueSet(!(!newValue || newValue.length === 0));
          setValue(newValue);
          onChange(event, newValue);
        }}
        noOptionsText="Start typing to see results..."
        options={options}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                e.preventDefault();
              }
            }}
            label={textFieldLabel}
            helperText={helperText}
            fullWidth
            variant="outlined"
            error={!!invalid}
            InputLabelProps={{ shrink: false, style: { display: params.inputProps.value || valueSet ? "none" : "" } }}
            onChange={ev => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value !== "" || ev.target.value !== null) {
                fetchData(ev.target.value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <CheckboxStyles>
              <CheckboxLabelStyles>{getOptionLabel(option)}</CheckboxLabelStyles>
            </CheckboxStyles>
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <Tag key={index} data-tag-index={index} label={getOptionLabel(option)} {...getTagProps({ index })} />
            );
          })
        }
      />
    </AutocompleteStyles>
  );
};

AsyncMultiSelect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  label: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  getOptionSelected: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
};

export default AsyncMultiSelect;
