import React from "react";

import { UpsertJobFeatureSettingFeatureNameEnum, UpsertJobFeatureSettingStateEnum } from "services/openapi";

export interface OnFeatureToggledParams {
  featureName: UpsertJobFeatureSettingFeatureNameEnum;
  desiredFeatureState: UpsertJobFeatureSettingStateEnum;
  additionalInfoArgumentsForModalText?: string[];
  additionalCallback?: () => void;
}

export interface JobFeatureSettingToggleHandler {
  onFeatureToggled: (params: OnFeatureToggledParams) => void;
}

export const JobFeatureSettingToggleHandlerContext: React.Context<
  JobFeatureSettingToggleHandler | undefined
> = React.createContext<JobFeatureSettingToggleHandler | undefined>(undefined);
