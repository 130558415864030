/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobAtsSourceSettingAtsSource,
    JobAtsSourceSettingAtsSourceFromJSON,
    JobAtsSourceSettingAtsSourceFromJSONTyped,
    JobAtsSourceSettingAtsSourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobAtsSourceSetting
 */
export interface JobAtsSourceSetting {
    /**
     * 
     * @type {string}
     * @memberof JobAtsSourceSetting
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobAtsSourceSetting
     */
    jobCandidateSourceSetting: string;
    /**
     * 
     * @type {JobAtsSourceSettingAtsSource}
     * @memberof JobAtsSourceSetting
     */
    atsSource: JobAtsSourceSettingAtsSource;
    /**
     * 
     * @type {string}
     * @memberof JobAtsSourceSetting
     */
    state: JobAtsSourceSettingStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum JobAtsSourceSettingStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}

export function JobAtsSourceSettingFromJSON(json: any): JobAtsSourceSetting {
    return JobAtsSourceSettingFromJSONTyped(json, false);
}

export function JobAtsSourceSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobAtsSourceSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'jobCandidateSourceSetting': json['job_candidate_source_setting'],
        'atsSource': JobAtsSourceSettingAtsSourceFromJSON(json['ats_source']),
        'state': json['state'],
    };
}

export function JobAtsSourceSettingToJSON(value?: JobAtsSourceSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_candidate_source_setting': value.jobCandidateSourceSetting,
        'ats_source': JobAtsSourceSettingAtsSourceToJSON(value.atsSource),
        'state': value.state,
    };
}


