import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  CAMPAIGN_MESSAGE_REQUEST,
  CANDIDATE_BIO,
  LIST_TAG,
  PIPELINE_CANDIDATE,
} from "services/doverapi/endpointTagsConstants";
import { CampaignMessageRequest, UpdateCampaignMessageRequest } from "services/openapi";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";

const emailSendingEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getCampaignMessageRequest: build.query<CampaignMessageRequest, { cmrId: string; jobId: string }>({
      queryFn: async ({ cmrId }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.getCampaignMessageRequest({
            id: cmrId,
          });
        } catch (error) {
          showErrorToast("Failed to load email");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: CAMPAIGN_MESSAGE_REQUEST, id: result.id },
              { type: CAMPAIGN_MESSAGE_REQUEST, id: arg.jobId },
            ]
          : [],
    }),
    updateCampaignMessageRequest: build.mutation<
      UpdateCampaignMessageRequest,
      { campaignMessageRequestId: string; data: UpdateCampaignMessageRequest }
    >({
      queryFn: async ({ campaignMessageRequestId, data }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.updateCampaignMessageRequest({ id: campaignMessageRequestId, data });
        } catch (error) {
          showErrorToast("Failed to save email. Please try again.");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: (result, error, arg) => [
        { type: CAMPAIGN_MESSAGE_REQUEST, id: arg.campaignMessageRequestId },
        { type: PIPELINE_CANDIDATE, id: LIST_TAG },
      ],
    }),
    sendCampaignMessageRequestEmail: build.mutation<void, { campaignMessageRequestId: string; candidateId: string }>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        showPendingToast("Sending email...");

        try {
          await client.sendEmail({ id: args.campaignMessageRequestId });
          showSuccessToast("Successfully sent email");
        } catch (error) {
          let errMsg = "Failed to send email. Please refresh and try again.";

          if (error.status === 400) {
            const returnedMsg = (await error.json()).message;
            if (returnedMsg) {
              errMsg = returnedMsg;
            }
          }
          showErrorToast(errMsg);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: undefined };
      },
      invalidatesTags: (result, error, arg) => [
        { type: CAMPAIGN_MESSAGE_REQUEST, id: arg.campaignMessageRequestId },
        { type: CANDIDATE_BIO, id: arg.candidateId },
        { type: PIPELINE_CANDIDATE, id: LIST_TAG },
      ],
    }),
    sendNewUserInvitation: build.mutation<void, { invitationMessage: string; toEmails: string[] }>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          await client.sendNewUserInvitation({
            data: { toEmails: args.toEmails, invitationMessage: args.invitationMessage },
          });
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: undefined };
      },
    }),
  }),
});

export const {
  useGetCampaignMessageRequestQuery,
  useUpdateCampaignMessageRequestMutation,
  useSendCampaignMessageRequestEmailMutation,
  useSendNewUserInvitationMutation,
} = emailSendingEndpoints;
