/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateJobVisibility
 */
export interface UpdateJobVisibility {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateJobVisibility
     */
    isPrivate: boolean;
}

export function UpdateJobVisibilityFromJSON(json: any): UpdateJobVisibility {
    return UpdateJobVisibilityFromJSONTyped(json, false);
}

export function UpdateJobVisibilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJobVisibility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isPrivate': json['is_private'],
    };
}

export function UpdateJobVisibilityToJSON(value?: UpdateJobVisibility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_private': value.isPrivate,
    };
}


