/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActorField
 */
export interface ActorField {
    /**
     * 
     * @type {string}
     * @memberof ActorField
     */
    actor: ActorFieldActorEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ActorField
     */
    actorData: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof ActorField
     */
    action: string | null;
}

/**
* @export
* @enum {string}
*/
export enum ActorFieldActorEnum {
    Dover = 'Dover',
    Candidate = 'Candidate',
    User = 'User'
}

export function ActorFieldFromJSON(json: any): ActorField {
    return ActorFieldFromJSONTyped(json, false);
}

export function ActorFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActorField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actor': json['actor'],
        'actorData': json['actor_data'],
        'action': json['action'],
    };
}

export function ActorFieldToJSON(value?: ActorField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor': value.actor,
        'actor_data': value.actorData,
        'action': value.action,
    };
}


