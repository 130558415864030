import { Box, Stack } from "@mui/material";
import React from "react";

import { ReactComponent as GreenCheckSVG } from "assets/icons/check-green-filled.svg";
import { ReactComponent as IncompleteSVG } from "assets/icons/empty-circle.svg";
import { ReactComponent as InProgressSVG } from "assets/icons/faint-green-filled-circle.svg";
import { Overline } from "components/library/typography";
import { StepFlowStep } from "components/StepFlow/types";
import { colors } from "styles/theme";

interface ProgressBarProps {
  currentStep: StepFlowStep;
  steps: StepFlowStep[];
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, steps }) => {
  const stepsWithProgressLabels = steps.filter(step => !!step.progressLabel);
  const numSteps = stepsWithProgressLabels.length;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {stepsWithProgressLabels.map((step, index) => {
        const currentStepIndex = stepsWithProgressLabels.findIndex(
          curr => curr.progressLabel === currentStep.progressLabel
        );
        return (
          <>
            {step.progressLabel && (
              <ProgressItem
                key={step.progressLabel}
                label={step.progressLabel}
                index={index}
                currentStep={currentStepIndex}
                numSteps={numSteps}
              />
            )}
          </>
        );
      })}
    </Stack>
  );
};

interface ProgressItemProps {
  label: string;
  index: number;
  currentStep: number;
  numSteps: number;
}

const ProgressItem: React.FC<ProgressItemProps> = ({ label, index, currentStep, numSteps }) => {
  let ProgressIcon: React.FC<React.SVGProps<SVGSVGElement>>;
  if (currentStep < index) {
    ProgressIcon = IncompleteSVG;
  } else if (currentStep > index) {
    ProgressIcon = GreenCheckSVG;
  } else {
    ProgressIcon = InProgressSVG;
  }

  const isNotLast = index < numSteps - 1;

  return (
    <>
      <ProgressIcon height="24px" width="24px" />
      <Overline>{label}</Overline>
      {isNotLast && <HeaderLine />}
    </>
  );
};

const HeaderLine = (): React.ReactElement => {
  return <Box mt="2px" display="inline-block" height="1px" width="60px" bgcolor={colors.grayscale.gray300} />;
};
