import { Box, Select, Stack } from "@mui/material";
import React from "react";

import { DurationInput } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/SubstageFields";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

export const DurationSelect: React.FC<{
  duration?: number;
  disabled?: boolean;
  disabledTooltipText?: string;
  setDuration: (e: any) => void;
}> = ({ duration, disabled, setDuration, disabledTooltipText }) => {
  return (
    <Stack spacing={1} width={"100%"}>
      <BodySmall weight="500">Duration</BodySmall>
      {disabled ? (
        <Select
          size="small"
          disabled={true}
          displayEmpty
          sx={{
            backgroundColor: colors.white,
          }} // This is to fix a mui v4 issue
          renderValue={(_): React.ReactNode => {
            return (
              <Tooltip title={disabledTooltipText}>
                <Box display="flex" alignItems="center">
                  <BodySmall color={colors.grayscale.gray400}>N/A</BodySmall>
                </Box>
              </Tooltip>
            );
          }}
        />
      ) : (
        <DurationInput value={duration ?? 0} onChange={setDuration} disabled={disabled} />
      )}
    </Stack>
  );
};
