/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationReviewSubstageEnum {
    BASE = 0
}

export function ApplicationReviewSubstageEnumFromJSON(json: any): ApplicationReviewSubstageEnum {
    return ApplicationReviewSubstageEnumFromJSONTyped(json, false);
}

export function ApplicationReviewSubstageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationReviewSubstageEnum {
    return json as ApplicationReviewSubstageEnum;
}

export function ApplicationReviewSubstageEnumToJSON(value?: ApplicationReviewSubstageEnum | null): any {
    return value as any;
}

