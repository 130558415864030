import { Stack } from "@mui/material";
import React from "react";

import SearchCriteriaUpdatedText from "components/dover/SearchesTable/components/SearchCriteriaUpdated";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, Heading } from "components/library/typography";
import CustomModal from "components/Modal";
import { usePartialUpdateSearchV3Mutation } from "services/doverapi/endpoints/search-v3/endpoints";
import { SearchV3 } from "services/openapi";
import { colors } from "styles/theme";

interface ActivateSearchModalArgs {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  search: SearchV3;
  activateDisabled?: boolean;
}

export const ActivateSearchModal = React.memo(
  ({ isModalOpen, setIsModalOpen, search, activateDisabled }: ActivateSearchModalArgs): React.ReactElement => {
    const [partialUpdateSearch, { isLoading: isSearchMutationLoading }] = usePartialUpdateSearchV3Mutation();

    const closeModal = React.useCallback((): void => {
      setIsModalOpen(false);
    }, [setIsModalOpen]);

    const handleToggleActivation = React.useCallback(() => {
      if (!search.id) {
        return;
      }

      partialUpdateSearch({
        id: search.id,
        data: {
          ...search,
          active: true,
        },
      })
        .unwrap()
        .then(() => {
          closeModal();
        });
    }, [closeModal, partialUpdateSearch, search]);

    return (
      <CustomModal
        open={isModalOpen}
        dialogActionsStyles={{ padding: "8px 24px" }}
        customPaperProps={{ sx: { overflowY: "hidden" } }}
        customDialogStyles={{ zIndex: 10000 }}
        onClose={closeModal}
        title={<Heading weight={"600"}>Set as active?</Heading>}
        maxWidth={"xs"}
      >
        <Stack spacing={2}>
          <SearchCriteriaUpdatedText />
          <Stack alignItems="center" padding="15px 5px">
            <Body>
              {
                "Activate this search to receive daily new candidates in your leads outbox. If you have outreach autopilot enabled, they'll automatically be queued for outreach"
              }
            </Body>
          </Stack>
          <Stack direction={"row"} spacing={1} width="100%" justifyContent="flex-end">
            <Button variant={ButtonVariant.Secondary} onClick={closeModal}>
              <BodySmall>Not now</BodySmall>
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              onClick={handleToggleActivation}
              disabled={isSearchMutationLoading || activateDisabled}
            >
              <BodySmall color={colors.white}>Set as Active</BodySmall>
            </Button>
          </Stack>
        </Stack>
      </CustomModal>
    );
  }
);
