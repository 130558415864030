/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PositionInfo
 */
export interface PositionInfo {
    /**
     * 
     * @type {string}
     * @memberof PositionInfo
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof PositionInfo
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PositionInfo
     */
    duration: string;
}

export function PositionInfoFromJSON(json: any): PositionInfo {
    return PositionInfoFromJSONTyped(json, false);
}

export function PositionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PositionInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': json['company_name'],
        'title': json['title'],
        'duration': json['duration'],
    };
}

export function PositionInfoToJSON(value?: PositionInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_name': value.companyName,
        'title': value.title,
        'duration': value.duration,
    };
}


