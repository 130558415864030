/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIConversation,
    AIConversationFromJSON,
    AIConversationFromJSONTyped,
    AIConversationToJSON,
    ListApplicationsReturn,
    ListApplicationsReturnFromJSON,
    ListApplicationsReturnFromJSONTyped,
    ListApplicationsReturnToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListAppsViaAIResponse
 */
export interface ListAppsViaAIResponse {
    /**
     * 
     * @type {ListApplicationsReturn}
     * @memberof ListAppsViaAIResponse
     */
    results: ListApplicationsReturn;
    /**
     * 
     * @type {Array<AIConversation>}
     * @memberof ListAppsViaAIResponse
     */
    conversation: Array<AIConversation>;
    /**
     * 
     * @type {boolean}
     * @memberof ListAppsViaAIResponse
     */
    success: boolean;
}

export function ListAppsViaAIResponseFromJSON(json: any): ListAppsViaAIResponse {
    return ListAppsViaAIResponseFromJSONTyped(json, false);
}

export function ListAppsViaAIResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAppsViaAIResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ListApplicationsReturnFromJSON(json['results']),
        'conversation': ((json['conversation'] as Array<any>).map(AIConversationFromJSON)),
        'success': json['success'],
    };
}

export function ListAppsViaAIResponseToJSON(value?: ListAppsViaAIResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ListApplicationsReturnToJSON(value.results),
        'conversation': ((value.conversation as Array<any>).map(AIConversationToJSON)),
        'success': value.success,
    };
}


