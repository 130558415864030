/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiV1SubmitUserCredentialCredentials,
    ApiV1SubmitUserCredentialCredentialsFromJSON,
    ApiV1SubmitUserCredentialCredentialsFromJSONTyped,
    ApiV1SubmitUserCredentialCredentialsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubmitUserCredentialRequest
 */
export interface SubmitUserCredentialRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitUserCredentialRequest
     */
    source: SubmitUserCredentialRequestSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmitUserCredentialRequest
     */
    username?: string;
    /**
     * 
     * @type {ApiV1SubmitUserCredentialCredentials}
     * @memberof SubmitUserCredentialRequest
     */
    credentials: ApiV1SubmitUserCredentialCredentials;
}

/**
* @export
* @enum {string}
*/
export enum SubmitUserCredentialRequestSourceEnum {
    Triplebyte = 'TRIPLEBYTE',
    Angellist = 'ANGELLIST'
}

export function SubmitUserCredentialRequestFromJSON(json: any): SubmitUserCredentialRequest {
    return SubmitUserCredentialRequestFromJSONTyped(json, false);
}

export function SubmitUserCredentialRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitUserCredentialRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': json['source'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'credentials': ApiV1SubmitUserCredentialCredentialsFromJSON(json['credentials']),
    };
}

export function SubmitUserCredentialRequestToJSON(value?: SubmitUserCredentialRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'username': value.username,
        'credentials': ApiV1SubmitUserCredentialCredentialsToJSON(value.credentials),
    };
}


