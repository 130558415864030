/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CareersPageSocialLink
 */
export interface CareersPageSocialLink {
    /**
     * 
     * @type {string}
     * @memberof CareersPageSocialLink
     */
    linkType: CareersPageSocialLinkLinkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CareersPageSocialLink
     */
    url: string;
}

/**
* @export
* @enum {string}
*/
export enum CareersPageSocialLinkLinkTypeEnum {
    Aboutme = 'ABOUTME',
    Angellist = 'ANGELLIST',
    Careerbuilder = 'CAREERBUILDER',
    Crunchbase = 'CRUNCHBASE',
    Facebook = 'FACEBOOK',
    Flickr = 'FLICKR',
    Github = 'GITHUB',
    Gravatar = 'GRAVATAR',
    Healthgrades = 'HEALTHGRADES',
    Indeed = 'INDEED',
    Instagram = 'INSTAGRAM',
    Kaggle = 'KAGGLE',
    Linkedin = 'LINKEDIN',
    Pinterest = 'PINTEREST',
    Portfolio = 'PORTFOLIO',
    Ratemd = 'RATEMD',
    Resume = 'RESUME',
    Stackoverflow = 'STACKOVERFLOW',
    Twitter = 'TWITTER',
    Website = 'WEBSITE',
    Other = 'OTHER'
}

export function CareersPageSocialLinkFromJSON(json: any): CareersPageSocialLink {
    return CareersPageSocialLinkFromJSONTyped(json, false);
}

export function CareersPageSocialLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): CareersPageSocialLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'linkType': json['link_type'],
        'url': json['url'],
    };
}

export function CareersPageSocialLinkToJSON(value?: CareersPageSocialLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'link_type': value.linkType,
        'url': value.url,
    };
}


