/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSchedulingLinkRequest
 */
export interface GetSchedulingLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof GetSchedulingLinkRequest
     */
    candidateId: string;
    /**
     * 
     * @type {string}
     * @memberof GetSchedulingLinkRequest
     */
    interviewerId: string;
    /**
     * 
     * @type {string}
     * @memberof GetSchedulingLinkRequest
     */
    desiredHiringPipelineStageId?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSchedulingLinkRequest
     */
    interviewDurationSeconds?: number;
}

export function GetSchedulingLinkRequestFromJSON(json: any): GetSchedulingLinkRequest {
    return GetSchedulingLinkRequestFromJSONTyped(json, false);
}

export function GetSchedulingLinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSchedulingLinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
        'interviewerId': json['interviewer_id'],
        'desiredHiringPipelineStageId': !exists(json, 'desired_hiring_pipeline_stage_id') ? undefined : json['desired_hiring_pipeline_stage_id'],
        'interviewDurationSeconds': !exists(json, 'interview_duration_seconds') ? undefined : json['interview_duration_seconds'],
    };
}

export function GetSchedulingLinkRequestToJSON(value?: GetSchedulingLinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'interviewer_id': value.interviewerId,
        'desired_hiring_pipeline_stage_id': value.desiredHiringPipelineStageId,
        'interview_duration_seconds': value.interviewDurationSeconds,
    };
}


