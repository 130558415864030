import { memoize } from "lodash";

import { FormattedJobFeatures } from "services/doverapi/endpoints/jobFeatureSettings/types";
import { JobFeature, JobFeatureStateEnum } from "services/openapi";

export const getCurrentJobFeatureStates = memoize(
  (features: JobFeature[] | undefined, forceAllFeaturesDisabled?: boolean): FormattedJobFeatures | undefined => {
    if (!features) {
      return undefined;
    }

    return features?.reduce((agg: FormattedJobFeatures, feature): FormattedJobFeatures => {
      agg[feature.featureName] = forceAllFeaturesDisabled ? false : feature.state === JobFeatureStateEnum.Enabled;
      return agg;
    }, {});
  }
);
