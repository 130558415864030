import { Drawer as MuiDrawer } from "@mui/material";
import styled from "styled-components";

import { screenSizes } from "styles/theme";

export const StyledDrawer = styled(MuiDrawer)`
  .MuiDrawer-paperAnchorRight {
    padding: 0px;
    width: 50vw;
  }
  @media (max-width: ${screenSizes.laptopL}) {
    .MuiDrawer-paperAnchorRight {
      padding: 0px;
      width: 65vw;
    }
  }
  @media (max-width: ${screenSizes.laptop}) {
    .MuiDrawer-paperAnchorRight {
      padding: 0px;
      width: 80vw;
    }
  }
  @media (max-width: ${screenSizes.tablet}) {
    .MuiDrawer-paperAnchorRight {
      padding: 0px;
      width: 100vw;
    }
  }
`;
