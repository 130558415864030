import { Box } from "@mui/material";
import styled from "styled-components";

import { colors } from "styles/theme";

interface StyledBannerProps {
  $borderColor?: string;
}

export const StyledBanner = styled(Box)<StyledBannerProps>`
  align-items: baseline;
  border: ${({ $borderColor = colors.success.base }): string => `1px solid ${$borderColor}`};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  margin: 8px 0px;
`;
