import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import GreenCheckSVG from "assets/icons/check-green-filled.svg";
import IncompleteSVG from "assets/icons/empty-circle.svg";
import EyeIcon from "assets/icons/eye.svg";
import InProgressSVG from "assets/icons/faint-green-filled-circle.svg";
import { Body, BodySmall, Overline, Subtitle1 } from "components/library/typography";
import { colors, screenSizesNumbers } from "styles/theme";
import SVG from "views/create-job/ExtensionJobCreationFlow/components/SVG";
import {
  EXTENSION_JOB_CREATION_CONFIG,
  KEYED_BY_PATH_EXTENSION_JOB_CREATION_CONFIG,
} from "views/create-job/ExtensionJobCreationFlow/constants";
import { useExtensionCreateJobContext } from "views/create-job/ExtensionJobCreationFlow/context";
import { ExtensionJobCreationStep } from "views/create-job/ExtensionJobCreationFlow/types";

// Used so the stepper is properly centered with the content
const SIDE_ITEM_MIN_WIDTH = 96;

const HeaderBar = styled(Stack)`
  padding: 16px 32px;
  width: 100%;
  z-index: 1202; // top nav + 1
  background: ${colors.white};
  box-shadow: inset 0px - 1px 0px #cfd7e0;
  border-bottom: 1px solid ${colors.grayscale.gray200};
`;

const HeaderLine = styled(Box)`
  display: inline-block;
  height: 1px;
  background-color: ${colors.grayscale.gray300};
  margin-top: 2px;
`;

const CloseButton = (): React.ReactElement => {
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));
  const [chatOpen, setChatOpen] = useState(false);
  const toggleChat = (): void => setChatOpen(!chatOpen);

  useEffect(() => {
    if (chatOpen === undefined) {
      return;
    }

    const Intercom = (window as any).Intercom;
    if (Intercom) {
      if (!chatOpen) {
        Intercom("hide");
      } else {
        Intercom("update", {
          hide_default_launcher: false,
        });
        Intercom("onHide", () => {
          // Adjust state if they hide using the launcher
          if (chatOpen) {
            setChatOpen(false);
          }
        });
        Intercom("showSpace", "home");
      }
    }
  }, [chatOpen]);

  const handleClick = (): void => navigate(APP_ROUTE_PATHS.home());

  return (
    <Box
      display="flex"
      sx={{
        "&:hover": { cursor: "pointer" },
        minWidth: `${SIDE_ITEM_MIN_WIDTH}px`,
      }}
      position={smallScreen ? "absolute" : undefined}
      right={smallScreen ? "5%" : undefined}
      top={smallScreen ? "7px" : undefined}
      justifyContent="end"
    >
      {!smallScreen && (
        <Box
          sx={{
            "&:hover": { cursor: "pointer" },
            mr: 2,
          }}
          onClick={toggleChat}
        >
          <Body>Help</Body>
        </Box>
      )}

      <Box onClick={handleClick}>
        <CloseIcon sx={{ width: smallScreen ? "20px" : undefined }} />
      </Box>
    </Box>
  );
};

const ProgressItem = ({ label, src }: { label: string; src: string }): React.ReactElement => {
  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));

  return (
    <>
      <Box>
        <SVG radius={smallScreen ? "17px" : "24px"} src={src} />
      </Box>
      <Overline>{label}</Overline>
    </>
  );
};

const ResponsiveHeaderLine = (): React.ReactElement => {
  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));

  return <HeaderLine width={smallScreen ? "10px" : "60px"} />;
};

const Progress = (): React.ReactElement => {
  const { step } = useExtensionCreateJobContext();

  const getProgressIcon = useCallback(
    (target: ExtensionJobCreationStep): string => {
      if (!step) {
        return IncompleteSVG;
      }

      const targetStepConfig = EXTENSION_JOB_CREATION_CONFIG[target];
      const currentStepConfig = KEYED_BY_PATH_EXTENSION_JOB_CREATION_CONFIG[step];

      if (targetStepConfig.index < currentStepConfig.index) {
        return GreenCheckSVG;
      }

      if (targetStepConfig.index === currentStepConfig.index) {
        return InProgressSVG;
      }

      return IncompleteSVG;
    },
    [step]
  );

  const basicsHeaderTabIcon = useMemo(() => getProgressIcon(ExtensionJobCreationStep.BasicsStep), [getProgressIcon]);
  const companyInfoHeaderTabIcon = useMemo(() => getProgressIcon(ExtensionJobCreationStep.CompanyPitchStep), [
    getProgressIcon,
  ]);
  const draftOutreachHeaderTabIcon = useMemo(() => getProgressIcon(ExtensionJobCreationStep.OutreachStep), [
    getProgressIcon,
  ]);

  return (
    <Stack display="flex" alignItems="center" direction="row" spacing={1}>
      <ProgressItem label="Basics" src={basicsHeaderTabIcon} />
      <ResponsiveHeaderLine />
      <ProgressItem label="Company Info" src={companyInfoHeaderTabIcon} />
      <ResponsiveHeaderLine />
      <ProgressItem label="Draft Outreach" src={draftOutreachHeaderTabIcon} />
      <ResponsiveHeaderLine />
      <ProgressItem label="Review & Finish" src={EyeIcon} />
    </Stack>
  );
};

const Header = (): React.ReactElement => {
  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));

  return (
    <HeaderBar
      direction={smallScreen ? "column" : "row"}
      justifyContent={smallScreen ? undefined : "space-between"}
      alignItems={smallScreen ? "start" : "center"}
      spacing={smallScreen ? 1 : undefined}
    >
      {/* Left text */}
      <Box display="flex" sx={{ minWidth: `${SIDE_ITEM_MIN_WIDTH}px` }}>
        {smallScreen ? <BodySmall weight="500">Get Started</BodySmall> : <Subtitle1>Get Started</Subtitle1>}
      </Box>

      {/* Middle Progress */}
      <Progress />

      {/* Right close and help */}
      <CloseButton />
    </HeaderBar>
  );
};

export default Header;
