import { Stack } from "@mui/material";
import React from "react";

import { CreateNewSearchModal } from "components/dover/NewSearchModal";
import SearchesTable from "components/dover/SearchesTable/SearchesTable";
import { Button, ButtonVariant } from "components/library/Button";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { JobFeatureStateEnum } from "services/openapi";

const SearchCriteria = (): React.ReactElement => {
  const [jobId] = useJobId();

  const managedOutboundState = useGetManagedOutboundState({ jobId });
  const outboundOn = managedOutboundState === JobFeatureStateEnum.Enabled;
  const isFreePlan = useIsFreeCustomer();

  const [isNewSearchModalOpen, setIsNewSearchModalOpen] = React.useState(false);

  const handleClickNewSearch = React.useCallback(() => {
    setIsNewSearchModalOpen(true);
  }, []);

  const handleCancelNewSearch = React.useCallback(() => {
    setIsNewSearchModalOpen(false);
  }, []);

  const showNewSearchButton = outboundOn && !isFreePlan;

  const topBarSearchCriteria = React.useMemo(() => {
    return (
      <Stack direction="row" width="100%" justifyContent="end" alignItems="center" spacing={1}>
        <Button variant={ButtonVariant.Primary} onClick={handleClickNewSearch}>
          + New Search
        </Button>
      </Stack>
    );
  }, [handleClickNewSearch]);

  return (
    <Stack direction="column" spacing={2}>
      {showNewSearchButton && topBarSearchCriteria}
      <SearchesTable />
      <CreateNewSearchModal isModalOpen={isNewSearchModalOpen} closeModal={handleCancelNewSearch} jobId={jobId} />
    </Stack>
  );
};

export default SearchCriteria;
