import { skipToken } from "@reduxjs/toolkit/query";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectAtsStagesInfo, selectJobSetup } from "components/SetupHiringPipeline/selectors";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import {
  ClientOnboardingAtsTypeEnum,
  ClientOnboardingSetupAtsStateEnum,
  JobAtsStageMap,
  JobSetup,
} from "services/openapi";

export function useShouldShowAtsInterviewFeedbackField(): boolean {
  const { data: clientOnboarding } = useGetClientOnboardingQuery();

  return useMemo((): boolean => {
    if (!clientOnboarding) {
      return false;
    }

    const atsType = clientOnboarding?.atsType;
    const atsSetupState = clientOnboarding?.setupAtsState;
    if (atsType && atsSetupState) {
      const allowableAtsTypes = [ClientOnboardingAtsTypeEnum.Lever, ClientOnboardingAtsTypeEnum.Greenhouse];
      const allowableAtsSetupStates = [
        ClientOnboardingSetupAtsStateEnum.StartedPendingDoverAction,
        ClientOnboardingSetupAtsStateEnum.Complete,
      ];
      return allowableAtsTypes.includes(atsType) && allowableAtsSetupStates.includes(atsSetupState);
    }
    return false;
  }, [clientOnboarding]);
}

export function useGetAtsStages(): Array<JobAtsStageMap> | undefined {
  const atsStagesInfo = useSelector(selectAtsStagesInfo);
  const atsStages = atsStagesInfo.data?.atsStages;
  return atsStages;
}

export const useGetJobStageSetupPipeline = (): JobSetup | undefined => {
  const jobFromSelector = useSelector(selectJobSetup);
  const [jobIdFromUrl] = useJobId();
  const { data: jobFromUrl } = useGetJobSetupQuery(jobIdFromUrl ?? skipToken);
  return jobFromSelector || jobFromUrl;
};
