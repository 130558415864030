/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useEnrichedJobSetupSteps } from "services/doverapi/cross-endpoint-hooks/useGetJobSetupSteps";
import {
  useGetClientOnboardingQuery,
  useGetCompanySetupStepsQuery,
} from "services/doverapi/endpoints/client/endpoints";
import { getCompanySetupStepMap } from "services/doverapi/endpoints/company-setup/utils";
import { useGetJobSetupQuery, useGetJobSetupStepsQuery } from "services/doverapi/endpoints/job/endpoints";
import { EnrichedJobSetupStep } from "services/doverapi/endpoints/job/types";
import {
  CompanySetupStepStateEnum,
  CompanySetupStepStepNameEnum,
  JobSetupStep,
  JobSetupStepStateEnum,
  JobSetupStepStepNameEnum,
} from "services/openapi/models";
import { EnrichedJobSetupStepsWithSummaryState } from "views/job/JobSetup/types";

export function useCurrentJobSetupStepIndex(jobId: string | undefined, step: string | undefined): number | undefined {
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);
  const jobSetupSteps = useEnrichedJobSetupSteps({
    jobId,
    shouldSkipQuery: !jobId || !jobSetup,
    includeAllCompletedSteps: !!jobSetup && !jobSetup.active,
  });

  return useMemo<number | undefined>(() => {
    return jobSetupSteps.findIndex(s => s.path === step);
  }, [jobSetupSteps, step]);
}

export function useNextJobSetupStep(
  jobId: string | undefined,
  step: string | undefined
): EnrichedJobSetupStep | undefined {
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);
  const jobSetupSteps = useEnrichedJobSetupSteps({
    jobId,
    shouldSkipQuery: !jobId || !jobSetup,
    includeAllCompletedSteps: !!jobSetup && !jobSetup.active,
  });
  const currentJobSetupStepIndex = useCurrentJobSetupStepIndex(jobId, step);
  return useMemo<EnrichedJobSetupStep | undefined>(() => {
    if (typeof currentJobSetupStepIndex === "undefined") {
      return undefined;
    }
    return jobSetupSteps[currentJobSetupStepIndex + 1];
  }, [currentJobSetupStepIndex, jobSetupSteps]);
}

export function usePreviousJobSetupStep(
  jobId: string | undefined,
  step: string | undefined
): EnrichedJobSetupStep | undefined {
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);
  const jobSetupSteps = useEnrichedJobSetupSteps({
    jobId,
    shouldSkipQuery: !jobId || !jobSetup,
    includeAllCompletedSteps: !!jobSetup && !jobSetup.active,
  });
  const currentJobSetupStepIndex = useCurrentJobSetupStepIndex(jobId, step);
  return useMemo<EnrichedJobSetupStep | undefined>(() => {
    if (typeof currentJobSetupStepIndex === "undefined") {
      return undefined;
    }
    return jobSetupSteps[currentJobSetupStepIndex - 1];
  }, [currentJobSetupStepIndex, jobSetupSteps]);
}

export function useJobSetupStepsWithSummaryState(jobId?: string): EnrichedJobSetupStepsWithSummaryState | undefined {
  const { data: jobSetupStepsWithSummaryState } = useGetJobSetupStepsQuery(jobId ?? skipToken);
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);
  const enrichedJobSetupSteps = useEnrichedJobSetupSteps({
    jobId,
    shouldSkipQuery: !jobId || !jobSetup,
    includeAllCompletedSteps: !!jobSetup && !jobSetup.active,
  });

  return useMemo<EnrichedJobSetupStepsWithSummaryState | undefined>(() => {
    if (!jobSetupStepsWithSummaryState || !jobSetup) {
      return undefined;
    }

    return {
      steps: enrichedJobSetupSteps,
      setupSummaryState: jobSetupStepsWithSummaryState.setupSummaryState,
      setupSummaryHiringStagesState: jobSetupStepsWithSummaryState.setupSummaryHiringStagesState,
    };
  }, [jobId, jobSetupStepsWithSummaryState, jobSetup, enrichedJobSetupSteps]);
}

export function usePitchStepIsIrrelevantOrCompleted(jobId: string | undefined): boolean | undefined {
  const { data: jobSetupSteps } = useGetJobSetupStepsQuery(jobId ?? skipToken);

  return useMemo<boolean | undefined>((): boolean | undefined => {
    const pitchStep: JobSetupStep | undefined = jobSetupSteps?.setupSteps?.find(
      (jobSetupStep): boolean => jobSetupStep.stepName === JobSetupStepStepNameEnum.PitchState
    );

    if (!pitchStep) {
      return undefined;
    }

    const pitchStepIsIrrelevantOrComplete: boolean =
      !pitchStep.isRelevantToJob ||
      [JobSetupStepStateEnum.Complete, JobSetupStepStateEnum.StartedPendingDoverAction].includes(pitchStep.state);

    return pitchStepIsIrrelevantOrComplete;
  }, [jobSetupSteps]);
}

function useOutreachStepState(jobId: string | undefined): JobSetupStepStateEnum | undefined {
  const { data: jobSetupSteps } = useGetJobSetupStepsQuery(jobId ?? skipToken);

  return useMemo<JobSetupStepStateEnum | undefined>((): JobSetupStepStateEnum | undefined => {
    const outreachStep: JobSetupStep | undefined = jobSetupSteps?.setupSteps?.find(
      (jobSetupStep): boolean => jobSetupStep.stepName === JobSetupStepStepNameEnum.OutreachState
    );

    return outreachStep?.state;
  }, [jobSetupSteps]);
}

export function useIsEligibleForCampaignOutreachGeneration(
  jobId: string | undefined,
  allowIncompleteJobPitch: boolean = false
): boolean {
  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);
  const { data: clientOnboarding } = useGetClientOnboardingQuery();
  const { data: companySetupSteps } = useGetCompanySetupStepsQuery();

  const pitchStepIsIrrelevantOrComplete = usePitchStepIsIrrelevantOrCompleted(jobId);
  const outreachStepState = useOutreachStepState(jobId);

  return useMemo<boolean>(() => {
    if (companySetupSteps) {
      const companyStepNameToInfoMap = getCompanySetupStepMap(companySetupSteps);
      const isEligibleForCampaignOutreachGeneration =
        (allowIncompleteJobPitch || !!pitchStepIsIrrelevantOrComplete) &&
        companyStepNameToInfoMap?.[CompanySetupStepStepNameEnum.PitchState]?.state ===
          CompanySetupStepStateEnum.Complete &&
        outreachStepState === JobSetupStepStateEnum.Pending;

      return isEligibleForCampaignOutreachGeneration;
    }
    return false;
  }, [
    allowIncompleteJobPitch,
    jobSetup?.setupOutreachState,
    jobSetup?.setupJobPitchState,
    clientOnboarding?.setupCompanyBiographyState,
    companySetupSteps,
  ]);
}
