import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";

import {
  ListPipelineCandidatesArgs,
  useListPipelineCandidatesQuery,
} from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import {
  ApiApiListPipelineCandidatesRequest,
  CandidateFilter,
  CandidateFilterList,
  CandidateFilterSourcingContextEnum,
  CandidateFilterStatusEnum,
  HiringPipelineStage,
  HiringPipelineStageType,
  InterviewSubstageEnum,
  SingleCandidatePipelineStageFilterField,
} from "services/openapi";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";
import { useParams } from "views/candidates/hooks";
import { QuickFilterEnum } from "views/candidates/types";
import { useStageIdsByType } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

export const BOARD_LIST_CANDIDATES_LIMIT = 10;

interface UseBoardCandidateArgs {
  stageId: string | undefined;
  page: number | undefined;
}

// The type definition is huge and I don't know how to manually recreate it
// The implied type is correct
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useBoardCandidates = ({ stageId, page }: UseBoardCandidateArgs) => {
  const args = useKanbanListArgs({ stageId, page });

  return useListPipelineCandidatesQuery(args);
};

export const useCountFilters = (stages: Array<HiringPipelineStage>): Array<CandidateFilterList> => {
  const [{ quickFilter, status, sourcingContext }] = useParams();

  const getFilterArg = useFilterArgGetter();

  return stages.reduce((countFilters: Array<CandidateFilterList>, stage) => {
    const filters = getFilterArg({
      stageId: stage.id,
      quickFilter,
      status,
      sourcingContext,
    });

    if (filters) {
      countFilters.push({
        name: stage.id,
        filters,
      });
    }

    return countFilters;
  }, []);
};

export const useKanbanListArgs = ({ stageId, page }: UseBoardCandidateArgs): ListPipelineCandidatesArgs | SkipToken =>
  useKanbanListArgsGetter()({ stageId, page });

// Creates a function that accepts stageId and page as params
// and returns args for the list candidates route as used by the
// kanban board page
export const useKanbanListArgsGetter = (): (({
  stageId,
  page,
}: UseBoardCandidateArgs) => ListPipelineCandidatesArgs | SkipToken) => {
  // Get params from the URL
  const [{ jobId, quickFilter, status, sourcingContext }] = useParams();

  // We also need job scoring to be enabled to determine if this job should
  // show the match labels or not. If no scoring, no match labels.
  const { jobHasScoringEnabled } = useJobHasScoringEnabled();

  // Get function that will construct our filters objects
  const getFilterArg = useFilterArgGetter();

  return ({ stageId, page }: UseBoardCandidateArgs): ListPipelineCandidatesArgs | SkipToken => {
    // Check that we have all the necessary params to make the request
    if (!jobId || !stageId || page === undefined) {
      return skipToken;
    }

    // Construct filters object
    const provideCandidateBucketLabels = jobHasScoringEnabled || false;
    const filters = getFilterArg({ stageId, quickFilter, status, sourcingContext });

    if (!filters) {
      return skipToken;
    }

    // Construct the args
    const args: ApiApiListPipelineCandidatesRequest = {
      jobId,
      data: {
        filters,
        provideCandidateBucketLabels,
      },
      ordering: "-modified",
      limit: BOARD_LIST_CANDIDATES_LIMIT,
      offset: page * BOARD_LIST_CANDIDATES_LIMIT,
    };

    return {
      useSuperApi: true,
      args,
    };
  };
};

// Creates a function that constructs a CandidateFilter argument object based on
// provided inputs for use in either the list candidates route or the get counts route
const useFilterArgGetter = (): (({
  stageId,
  quickFilter,
  status,
  sourcingContext,
}: {
  stageId: string;
  quickFilter?: QuickFilterEnum;
  status: Array<CandidateFilterStatusEnum>;
  sourcingContext: Array<CandidateFilterSourcingContextEnum>;
}) => CandidateFilter | undefined) => {
  const getQuickFilter = useQuickFilterArgsGetter();

  // Check if a quick filter is enabled
  return ({ stageId, quickFilter, status, sourcingContext }): CandidateFilter | undefined => {
    if (quickFilter) {
      // If a quick filter is enabled we use the args associated with that quick filter
      // and ignore any other filters set in the url or otherwise passed in
      const filterArgs = getQuickFilter(quickFilter, stageId);

      if (!filterArgs) {
        return undefined;
      }

      return {
        ...filterArgs,
      };
    } else {
      // Convert stage id to the filter format expected by the API
      const pipelineStages: Array<SingleCandidatePipelineStageFilterField> = [
        {
          pipelineStageId: stageId,
          pipelineSubstages: [],
        },
      ];

      // Construct the filters object
      return {
        pipelineStages,
        status,
        sourcingContext,
      };
    }
  };
};

// Creates a function that will construct your filters args object
// For a given quickfilter and given stage
// NOTE: some quick filters should only apply to specific stages
// So in cases where the passed in stage is not relevant to the quick filter
// The function will return undefined, up to consumer how to handle that
// We can't return empty object because that would be considered a filter with not filtering criteria
// And would return all candidates
const useQuickFilterArgsGetter = (): ((
  quickfilter: QuickFilterEnum,
  stageId: string
) => CandidateFilter | undefined) => {
  const interviewStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.INTERVIEW });

  return (quickFilter: QuickFilterEnum, stageId: string): CandidateFilter | undefined => {
    switch (quickFilter) {
      case QuickFilterEnum.Interviewing: {
        if (!interviewStageIds.includes(stageId)) {
          return undefined;
        }

        return {
          pipelineStages: [
            {
              pipelineStageId: stageId,
              pipelineSubstages: [],
            },
          ],
          status: [CandidateFilterStatusEnum.Active],
        };
      }
      case QuickFilterEnum.Scheduled: {
        if (!interviewStageIds.includes(stageId)) {
          return undefined;
        }

        return {
          pipelineStages: [
            {
              pipelineStageId: stageId,
              pipelineSubstages: [InterviewSubstageEnum.SCHEDULED],
            },
          ],
          status: [CandidateFilterStatusEnum.Active],
        };
      }
      case QuickFilterEnum.Rejected: {
        return {
          pipelineStages: [
            {
              pipelineStageId: stageId,
              pipelineSubstages: [],
            },
          ],
          status: [CandidateFilterStatusEnum.Rejected],
        };
      }
      default:
        return undefined;
    }
  };
};
