import { Stack } from "@mui/material";
import React from "react";

import { Body } from "components/library/typography";
import DoverLoadingOverlay, { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";

const EnablingAutopilotLoadingOverlay = (): React.ReactElement => {
  return (
    <DoverLoadingOverlay active={true} overlay={true} fullScreen>
      <CustomModal title="Sourcing" maxWidth="md" open={true} hideCloseButton={true}>
        <Stack justifyContent="center" alignItems="center">
          <Stack direction="row" minWidth="280px">
            <Body>
              Dover’s parsing your job description and generating your search criteria… this may take a minute!
            </Body>
          </Stack>
          <DoverLoadingSpinner />
        </Stack>
      </CustomModal>
    </DoverLoadingOverlay>
  );
};

export default EnablingAutopilotLoadingOverlay;
