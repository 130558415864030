import { Box, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";

import { Body } from "components/library/typography";
import { Spacer } from "components/Spacer";
import {
  useGetInterviewerProUserQuery,
  usePartialUpdateProUserForInterviewerMutation,
} from "services/doverapi/endpoints/proUser";
import { colors } from "styles/theme";
import { articles, pronounsOptions } from "views/CompanySetup/components/InterviewPreferences/constants";
import {
  WhiteCard,
  B2,
  StyledSelect,
  StyledTextField,
  StyledPronounsSelect,
} from "views/CompanySetup/components/InterviewPreferences/styles";
import { PronounSelectOptions } from "views/CompanySetup/components/InterviewPreferences/types";
import { isOtherSelected } from "views/CompanySetup/components/InterviewPreferences/utils";

type RoleTitleData = { article: string; title: string; pronouns: string };
export const RoleTitle = (): React.ReactElement => {
  const { data: proUser } = useGetInterviewerProUserQuery();
  const [partialUpdateProUserForInterviewer] = usePartialUpdateProUserForInterviewerMutation();
  const [editedProUser, setEditedProUser] = useState<{
    roleTitleData: RoleTitleData;
    phoneNumber?: string | null;
  }>({
    roleTitleData: proUser?.roleTitleData as RoleTitleData,
    phoneNumber: proUser?.phoneNumber,
  });
  const [pronounsSelectField, setPronounsSelectField] = useState<PronounSelectOptions>(
    // @ts-ignore
    isOtherSelected(editedProUser?.pronouns!) ? proUser?.roleTitleData?.pronouns : "other"
  );
  const [pronounsTextField, setPronounsTextField] = useState<string>(
    // @ts-ignore
    !isOtherSelected(editedProUser?.pronouns!) ? proUser?.roleTitleData?.pronouns : ""
  );
  const update = ({
    roleTitleData,
    phoneNumber,
  }: {
    roleTitleData?: RoleTitleData;
    phoneNumber?: string | null;
  }): void => {
    partialUpdateProUserForInterviewer({ user: String(proUser?.id!), data: { roleTitleData, phoneNumber } });
  };

  return (
    <WhiteCard>
      <Box>
        <Body weight="500">What is your role at {proUser?.clientName} (e.g. the CEO, a software engineer)?</Body>
        <B2>We will use this to give candidates context in scheduling emails.</B2>
      </Box>
      <Spacer height="32px" />
      <Spacer height={8} />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box width="auto">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Body>I am</Body>
            <StyledSelect
              placeholder="Select"
              onChange={(e): void => {
                const newValue: string = e.target.value === "-" ? "" : (e.target.value as string);
                const newRoleTitleData = {
                  ...editedProUser.roleTitleData,
                  article: newValue,
                };
                setEditedProUser({ ...editedProUser, roleTitleData: newRoleTitleData });
                if (newRoleTitleData.title?.trim().length) {
                  update({ roleTitleData: newRoleTitleData });
                }
              }}
              value={editedProUser?.roleTitleData?.article || "-"}
            >
              {articles.map((article, index) => (
                <MenuItem key={index} value={article}>
                  {article}
                </MenuItem>
              ))}
            </StyledSelect>
          </Stack>
        </Box>
        <Box width="250px" sx={{ flexGrow: 1 }}>
          <StyledTextField
            onChange={(e): void => {
              setEditedProUser({
                ...editedProUser,
                roleTitleData: { ...editedProUser?.roleTitleData, title: e.target.value },
              });
            }}
            onBlur={(): void => {
              if (editedProUser.roleTitleData?.article && editedProUser.roleTitleData?.title?.trim().length) {
                update(editedProUser);
              }
            }}
            value={editedProUser.roleTitleData?.title}
            fullWidth
            placeholder="Enter your title"
          />
        </Box>
        <Box sx={{ width: "auto" }}>
          <Body>at {proUser?.clientName}</Body>
        </Box>
      </Stack>
      <Spacer height={24} />
      <Box>
        <Body>Example of how this will be used in emails:</Body>
        <Spacer height={12} />
        <Box style={{ width: "fit-content", padding: "10px", backgroundColor: "#F0F3F5" }}>
          <Body>
            <i>{`I'm looping in ${proUser?.firstName}, ${editedProUser.roleTitleData?.article} ${editedProUser
              .roleTitleData?.title || "[your title]"}, to tell you more...`}</i>
          </Body>
        </Box>
      </Box>
      <Spacer height={24} />
      <Box>
        <Box display="flex">
          <Body>My pronouns are:</Body>
          <Spacer width={8} />
        </Box>
        <Spacer height="8px" />
        <Box display="flex">
          <StyledPronounsSelect
            placeholder="Select"
            onChange={(e): void => {
              const newRoleTitleData = {
                ...editedProUser.roleTitleData,
                pronouns:
                  e.target.value !== "other" || pronounsTextField === ""
                    ? (e.target.value as string)
                    : pronounsTextField,
              };
              setPronounsSelectField(e.target.value as PronounSelectOptions);
              setEditedProUser({
                ...editedProUser,
                roleTitleData: newRoleTitleData,
              });
              if (newRoleTitleData?.article && newRoleTitleData?.title?.trim().length && newRoleTitleData?.pronouns) {
                update({ roleTitleData: newRoleTitleData });
              }
            }}
            value={pronounsSelectField}
          >
            {pronounsOptions.map((pronouns, index) => (
              <MenuItem key={index} value={pronouns}>
                {pronouns}
              </MenuItem>
            ))}
            <MenuItem value={"other"}>other</MenuItem>
          </StyledPronounsSelect>
          <Spacer width={12} />
          {pronounsSelectField === "other" && (
            <StyledTextField
              onChange={(e): void => {
                const newRoleTitleData = {
                  ...editedProUser.roleTitleData,
                  pronouns: e.target.value,
                };
                setEditedProUser({
                  ...editedProUser,
                  roleTitleData: newRoleTitleData,
                });
                setPronounsTextField(e.target.value);
                if (isOtherSelected(e.target.value)) {
                  update({ roleTitleData: newRoleTitleData });
                }
              }}
              onBlur={(): void => {
                if (
                  editedProUser.roleTitleData?.article &&
                  editedProUser.roleTitleData?.title?.trim().length &&
                  editedProUser.roleTitleData?.pronouns &&
                  editedProUser.roleTitleData.pronouns !== "other"
                ) {
                  update({ roleTitleData: editedProUser.roleTitleData });
                }
              }}
              value={pronounsTextField}
              fullWidth
              placeholder="Enter your pronouns"
            />
          )}
        </Box>
        <Box fontSize="12px" color={colors.grayscale.gray400}>
          {`We'll use this information when referring to you over email and during initial calls with candidates.`}
        </Box>
      </Box>
    </WhiteCard>
  );
};
