import { Stack } from "@mui/material";
import React from "react";
import { useBoolean } from "react-use";

import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevron-right.svg";
import { ReactComponent as LightbulbSVG } from "assets/icons/lightbulb-in-circle.svg";
import { ReactComponent as Trendline } from "assets/icons/trendline.svg";
import { TimeToHireTable } from "components/dover/CreateJob/components/TimeToHireTable";
import { Card } from "components/library/Card";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

interface DoverInsightsProps {
  timelineText: string;
  hoursPerWeek: number;
}

export const DoverInsights = ({ timelineText, hoursPerWeek }: DoverInsightsProps): React.ReactElement => {
  const [expanded, toggleExpanded] = useBoolean(false);

  return (
    <Card padding="8px" color={colors.primary.extraLight}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <LightbulbSVG width="24px" height="24px" />
          <BodySmall weight="500" color={colors.primary.base}>
            Dover Insights
          </BodySmall>
        </Stack>
        <BodySmall>
          To make your hire <strong>{timelineText}</strong>, it will take on average{" "}
          <strong>~{hoursPerWeek}hrs/wk</strong>
        </BodySmall>
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={(): void => toggleExpanded()}
        >
          {!expanded ? (
            <ChevronRight width="20px" height="20px" color={colors.grayscale.gray500} />
          ) : (
            <ChevronDown width="20px" height="20px" color={colors.grayscale.gray500} />
          )}
          <BodySmall color={colors.grayscale.gray500}>How is this calculated?</BodySmall>
        </Stack>
        {expanded && (
          <Card padding="8px">
            <Stack spacing={1}>
              <BodySmall color={colors.grayscale.gray500}>Average hours of work per hire</BodySmall>
              <TimeToHireTable />
              <Stack direction="row" spacing={0.5}>
                <Trendline width="20px" height="20px" />
                <BodySmall color={colors.grayscale.gray500}>
                  Calculations are gathered from hiring processes at 500+ startups
                </BodySmall>
              </Stack>
            </Stack>
          </Card>
        )}
      </Stack>
    </Card>
  );
};
