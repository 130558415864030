import { createEntityAdapter, SerializedError } from "@reduxjs/toolkit";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { CANDIDATE_SOURCE, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import { ApiApiListCandidateSourcesRequest, CandidateSource } from "services/openapi";
import { DjangoListResponseType } from "types";
import { showErrorToast } from "utils/showToast";

const candidateSourceAdapter = createEntityAdapter<CandidateSource>();

const candidateSource = doverApi.injectEndpoints({
  endpoints: build => ({
    listCandidateSources: build.query<EntityState<CandidateSource>, ApiApiListCandidateSourcesRequest>({
      queryFn: async args => {
        const { apiApi: client } = await getOpenApiClients({});

        let response: DjangoListResponseType<CandidateSource>;
        try {
          response = await client.listCandidateSources({ limit: 1000, offset: 0, ...args });
        } catch (error) {
          const userFacingMessage = "Failed to load candidate sources. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return {
          data: candidateSourceAdapter.addMany(candidateSourceAdapter.getInitialState(), response.results),
        };
      },
      providesTags: result => {
        // is result available?
        return result
          ? // successful query
            [
              ...result.ids.map(id => ({ type: CANDIDATE_SOURCE, id } as const)),
              { type: CANDIDATE_SOURCE, id: LIST_TAG },
            ]
          : // an error occurred, but we still want to re-fetch this query when this tag is invalidated
            [{ type: CANDIDATE_SOURCE, id: LIST_TAG }];
      },
    }),
  }),
});

export const { useListCandidateSourcesQuery } = candidateSource;
