import { Button } from "@doverhq/dover-ui";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation, useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { useCandidateId } from "hooks/useCandidateId";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { PipelineCandidate } from "services/openapi";
import { useCandidates, useParams } from "views/candidates/hooks";
import { OFFSET_MULTIPLIER } from "views/sourcing/Search/components/CandidateCardList";

const PREV_HOT_KEY = "left";
const NEXT_HOT_KEY = "right";

interface NavigationArrowsProps {
  isLoading: boolean;
}

export const NavigationArrows = React.memo(
  ({ isLoading }: NavigationArrowsProps): React.ReactElement => {
    // When candidate is removed from pipeline (rejected), we need to track the last valid prev/next candidate index
    const [lastValidPrevCandidateIndex, setLastValidPrevCandidateIndex] = React.useState<number | null>(null);
    const [lastValidNextCandidateIndex, setLastValidNextCandidateIndex] = React.useState<number | null>(null);
    const [pageUserWasLastOn, setPageUserWasLastOn] = React.useState<number>(0);
    const [{ page }, setParams] = useParams();

    const { data: candidatesData } = useCandidates();
    const { results: candidates, count } = candidatesData || { results: [] as PipelineCandidate[], count: 0 };

    const allCandidateIds = useMemo(() => candidates?.map((c: PipelineCandidate) => c.id!), [candidates]);
    const jobId = useJobIdFromUrl();

    const navigate = useNavigate();
    const location = useLocation();
    const candidateId = useCandidateId();

    const currCandidateIndex = useMemo(() => {
      return allCandidateIds.findIndex((candidate): boolean => candidate === candidateId);
    }, [allCandidateIds, candidateId]);

    const nextCandidateIndex = useMemo(() => {
      if (currCandidateIndex == -1) {
        return null;
      } // loop around if at the end of the candidate list
      else if (currCandidateIndex == allCandidateIds.length - 1) {
        return 0;
      } else {
        return currCandidateIndex + 1;
      }
    }, [allCandidateIds.length, currCandidateIndex]);

    const prevCandidateIndex = useMemo(() => {
      if (currCandidateIndex == -1) {
        return null;
      } // loop around if at the start of the candidate list
      else if (currCandidateIndex == 0) {
        return allCandidateIds.length - 1;
      } else {
        return currCandidateIndex - 1;
      }
    }, [allCandidateIds.length, currCandidateIndex]);

    useEffect(() => {
      if (prevCandidateIndex != null) {
        setLastValidPrevCandidateIndex(prevCandidateIndex);
      }
    }, [prevCandidateIndex]);

    useEffect(() => {
      if (nextCandidateIndex != null) {
        setLastValidNextCandidateIndex(nextCandidateIndex);
      }
    }, [nextCandidateIndex]);

    useEffect(() => {
      if (jobId && pageUserWasLastOn !== page) {
        // if we are going back in pages, we want to go to the last candidate on the new (prev) page
        // otherwise we want to go to the first candidate on the new (next) page
        const desiredIndex = page > pageUserWasLastOn ? 0 : Math.max(allCandidateIds.length - 1, 0);
        if (allCandidateIds.length > 0 && !allCandidateIds.find(cid => cid === candidateId)) {
          navigate(
            `${APP_ROUTE_PATHS.job.candidates.candidateDetail(
              jobId,
              allCandidateIds[desiredIndex],
              new URLSearchParams(location.search)
            )}`
          );
        }
      }
    }, [jobId, allCandidateIds, candidateId, location.search, navigate, pageUserWasLastOn, page]);

    const toPrevCandidate = useCallback((): void => {
      if (isLoading || lastValidPrevCandidateIndex == null) {
        return;
      }

      if (lastValidPrevCandidateIndex === allCandidateIds.length - 1) {
        const hasPreviousPage = page > 0;
        if (hasPreviousPage) {
          setPageUserWasLastOn(page);
          setParams({ page: page - 1 });
        }
        return;
      }

      const prevCandidate = allCandidateIds[lastValidPrevCandidateIndex];

      if (jobId) {
        navigate(
          `${APP_ROUTE_PATHS.job.candidates.candidateDetail(
            jobId,
            prevCandidate,
            new URLSearchParams(location.search)
          )}`
        );
      }
    }, [isLoading, lastValidPrevCandidateIndex, allCandidateIds, jobId, location.search, navigate, page, setParams]);

    const toNextCandidate = useCallback((): void => {
      if (isLoading || lastValidNextCandidateIndex == null) {
        return;
      }

      if (lastValidNextCandidateIndex === 0) {
        const hasNextPage = count > (page + 1) * OFFSET_MULTIPLIER;
        if (hasNextPage) {
          setPageUserWasLastOn(page);
          setParams({ page: page + 1 });
        }
        return;
      }

      const nextCandidate = allCandidateIds[lastValidNextCandidateIndex];

      if (jobId) {
        navigate(
          APP_ROUTE_PATHS.job.candidates.candidateDetail(jobId, nextCandidate, new URLSearchParams(location.search))
        );
      }
    }, [
      isLoading,
      lastValidNextCandidateIndex,
      allCandidateIds,
      jobId,
      location.search,
      navigate,
      count,
      page,
      setParams,
    ]);

    useHotkeys(PREV_HOT_KEY, toPrevCandidate, { enabled: !isLoading }, [toPrevCandidate, isLoading]);
    useHotkeys(NEXT_HOT_KEY, toNextCandidate, { enabled: !isLoading }, [toNextCandidate, isLoading]);

    return (
      <Box>
        {allCandidateIds && (
          <Stack alignItems="center" spacing={2} marginRight="1.25em">
            {lastValidPrevCandidateIndex !== null && (
              <Button variant="primaryOutlined" p={0.5} onPress={toPrevCandidate} isDisabled={isLoading}>
                <ExpandLess color={isLoading ? "disabled" : "action"} />
              </Button>
            )}
            {lastValidNextCandidateIndex !== null && (
              <Button variant="primaryOutlined" p={0.5} onPress={toNextCandidate} isDisabled={isLoading}>
                <ExpandMore color={isLoading ? "disabled" : "action"} />
              </Button>
            )}
          </Stack>
        )}
      </Box>
    );
  }
);
