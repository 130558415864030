/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShortenedContact
 */
export interface ShortenedContact {
    /**
     * The full name of the Contact.
     * @type {string}
     * @memberof ShortenedContact
     */
    fullName: string;
}

export function ShortenedContactFromJSON(json: any): ShortenedContact {
    return ShortenedContactFromJSONTyped(json, false);
}

export function ShortenedContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShortenedContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['full_name'],
    };
}

export function ShortenedContactToJSON(value?: ShortenedContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full_name': value.fullName,
    };
}


