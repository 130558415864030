/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityFeedEmailParticipantSerializer,
    ActivityFeedEmailParticipantSerializerFromJSON,
    ActivityFeedEmailParticipantSerializerFromJSONTyped,
    ActivityFeedEmailParticipantSerializerToJSON,
    EmailHeader,
    EmailHeaderFromJSON,
    EmailHeaderFromJSONTyped,
    EmailHeaderToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmailEventField
 */
export interface EmailEventField {
    /**
     * 
     * @type {string}
     * @memberof EmailEventField
     */
    emailBody: string;
    /**
     * 
     * @type {string}
     * @memberof EmailEventField
     */
    emailSnippet: string;
    /**
     * 
     * @type {EmailHeader}
     * @memberof EmailEventField
     */
    emailHeader: EmailHeader;
    /**
     * 
     * @type {Array<ActivityFeedEmailParticipantSerializer>}
     * @memberof EmailEventField
     */
    emailParticipants?: Array<ActivityFeedEmailParticipantSerializer>;
    /**
     * 
     * @type {string}
     * @memberof EmailEventField
     */
    cleanEmailText?: string;
}

export function EmailEventFieldFromJSON(json: any): EmailEventField {
    return EmailEventFieldFromJSONTyped(json, false);
}

export function EmailEventFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailEventField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailBody': json['email_body'],
        'emailSnippet': json['email_snippet'],
        'emailHeader': EmailHeaderFromJSON(json['email_header']),
        'emailParticipants': !exists(json, 'email_participants') ? undefined : ((json['email_participants'] as Array<any>).map(ActivityFeedEmailParticipantSerializerFromJSON)),
        'cleanEmailText': !exists(json, 'clean_email_text') ? undefined : json['clean_email_text'],
    };
}

export function EmailEventFieldToJSON(value?: EmailEventField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_body': value.emailBody,
        'email_snippet': value.emailSnippet,
        'email_header': EmailHeaderToJSON(value.emailHeader),
        'email_participants': value.emailParticipants === undefined ? undefined : ((value.emailParticipants as Array<any>).map(ActivityFeedEmailParticipantSerializerToJSON)),
        'clean_email_text': value.cleanEmailText,
    };
}


