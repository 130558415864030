/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HiringPlanJobPositionOpening,
    HiringPlanJobPositionOpeningFromJSON,
    HiringPlanJobPositionOpeningFromJSONTyped,
    HiringPlanJobPositionOpeningToJSON,
} from './';

/**
 * 
 * @export
 * @interface HiringPlanJobPosition
 */
export interface HiringPlanJobPosition {
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    client: string;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    hiringManagerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    hiringManager?: string | null;
    /**
     * 
     * @type {Array<HiringPlanJobPositionOpening>}
     * @memberof HiringPlanJobPosition
     */
    readonly jobPositionOpenings?: Array<HiringPlanJobPositionOpening>;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    job?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HiringPlanJobPosition
     */
    readonly jobHiringManager?: number;
    /**
     * 
     * @type {number}
     * @memberof HiringPlanJobPosition
     */
    readonly jobRecruiter?: number;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    priority?: HiringPlanJobPositionPriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    recruiter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HiringPlanJobPosition
     */
    recruiterName?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum HiringPlanJobPositionPriorityEnum {
    High = 'HIGH',
    Medium = 'MEDIUM',
    Low = 'LOW'
}

export function HiringPlanJobPositionFromJSON(json: any): HiringPlanJobPosition {
    return HiringPlanJobPositionFromJSONTyped(json, false);
}

export function HiringPlanJobPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): HiringPlanJobPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'client': json['client'],
        'title': json['title'],
        'hiringManagerName': !exists(json, 'hiring_manager_name') ? undefined : json['hiring_manager_name'],
        'hiringManager': !exists(json, 'hiring_manager') ? undefined : json['hiring_manager'],
        'jobPositionOpenings': !exists(json, 'job_position_openings') ? undefined : ((json['job_position_openings'] as Array<any>).map(HiringPlanJobPositionOpeningFromJSON)),
        'job': !exists(json, 'job') ? undefined : json['job'],
        'jobHiringManager': !exists(json, 'job_hiring_manager') ? undefined : json['job_hiring_manager'],
        'jobRecruiter': !exists(json, 'job_recruiter') ? undefined : json['job_recruiter'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'recruiter': !exists(json, 'recruiter') ? undefined : json['recruiter'],
        'recruiterName': !exists(json, 'recruiter_name') ? undefined : json['recruiter_name'],
    };
}

export function HiringPlanJobPositionToJSON(value?: HiringPlanJobPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': value.client,
        'title': value.title,
        'hiring_manager_name': value.hiringManagerName,
        'hiring_manager': value.hiringManager,
        'job': value.job,
        'priority': value.priority,
        'recruiter': value.recruiter,
        'recruiter_name': value.recruiterName,
    };
}


