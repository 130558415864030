import { Box, Button, Checkbox, Grid, Radio, Typography } from "@mui/material";
import Color from "color";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { backgrounds, colors } from "styles/theme";

interface StyledTabProps {
  $isSelected: boolean;
}

interface StyledButtonProps {
  $textColor?: string | undefined;
  $isDoverOutreachButton: boolean;
}

interface StyledDontReachOutButtonProps {
  $textColor?: string | undefined;
  $isDoverOutreachButton: boolean;
  $smallWidth: boolean;
}

interface StyledMultConnectionProps {
  $isDropdownButton: boolean;
}

interface StyledSendIconProps {
  $lighterColor: boolean;
}

export const StyledLink = styled(Link)<StyledTabProps>`
  padding: 8px;
  background-color: transparent;
  border: none;
  color: ${({ $isSelected }): string => ($isSelected ? "rgb(23,28,31)" : "rgb(120,120,104)")};
  border-bottom: ${({ $isSelected }): string => ($isSelected ? `2px solid ${colors.black}` : "none")};
  font-weight: 500;
  text-transform: none;
  font-family: "Inter";
  cursor: pointer;
  margin: 0px 5px;
  padding: 18px 12px;
  text-decoration: none;
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    color: ${colors.black};
  }
`;

export const StyledTabBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TypographyInter = styled(Typography)`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  min-width: 160px;
`;

export const TypographyHelp = styled(Typography)`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
`;

export const TypographyHelpSubtext = styled(Typography)`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  max-width: 900px;
`;

export const DateText = styled(Typography)`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  color: #636d72;
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  width: 148px;
  height: 33px;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  text-transform: none;
  color: ${({ $textColor }): string => ($textColor === "red" ? `${colors.critical.base}` : `${colors.black}`)};
  margin-right: ${({ $isDoverOutreachButton }): string => ($isDoverOutreachButton ? "8px" : "0px")};
`;

export const StyledUndoButton = styled(StyledButton)`
  width: 192px;
`;

export const StyledDontReachOutWithIconButton = styled(Button)<StyledDontReachOutButtonProps>`
  width: ${({ $smallWidth }): string => ($smallWidth ? "33px" : "152px")};
  min-width: ${({ $smallWidth }): string => ($smallWidth ? "33px" : "152px")};
  height: 33px;
  padding: 0px 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  text-transform: none;
  color: ${({ $textColor }): string => ($textColor === "red" ? `${colors.critical.base}` : `${colors.black}`)};
  margin-right: ${({ $isDoverOutreachButton }): string => ($isDoverOutreachButton ? "8px" : "0px")};
`;

export const StyledNotInterestedIcon = styled(ReactSVG)`
  svg {
    width: 20px;
    padding-right: 5px;
    padding-bottom: 2px;
  }
`;

export const StyledTooltip = styled.span`
  font-size: 12px;
  font-family: "Inter";
`;

export const StyledSendIcon = styled(ReactSVG)<StyledSendIconProps>`
  svg {
    width: 15px;
    height: 15px;
  }
  margin-right: 4px;
  margin-bottom: 2px;
  opacity: ${({ $lighterColor }): number => ($lighterColor ? 0.3 : 1)};
`;

export const StyledConnectionButton = styled(Button)`
  height: 33px;
  padding: 0px 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  text-transform: none;
  color: ${colors.black};
  margin-right: clamp(4px, 64px, 80px);
`;

export const StyledMultConnectionButton = styled(Button)<StyledMultConnectionProps>`
  min-width: 29px;
  width: ${({ $isDropdownButton }): string => ($isDropdownButton ? `29px` : `auto`)};
  height: 33px;
  padding: ${({ $isDropdownButton }): string => ($isDropdownButton ? `0px` : `0px 8px`)};
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  text-transform: none;
  color: ${colors.black};
`;

export const StyledMarkAsContactedButton = styled(Button)`
  width: 148px;
  height: 33px;
  padding: 0px 2px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  text-transform: none;
  color: ${colors.black};
  margin-right: 16px;
`;

export const StyledMultipleFitJobsButton = styled(Button)`
  width: auto;
  height: 33px;
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  text-transform: none;
  color: ${colors.black};
`;

export const StyledGrid = styled(Grid)`
  cursor: pointer;
`;

export const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 542px;
  max-width: 100vw;
  margin: 0 auto;
  text-align: center;
`;

export const DividingLine = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
`;

export const StyledUploadSVG = styled(ReactSVG)`
  svg {
    path {
      stroke: ${colors.informational.dark};
    }
  }
`;

export const StyledReferralPartyBanner = styled(Box)`
  background-color: #dcf7fd;
  position: sticky;
  top: 0px;
  z-index: 1000;
`;

export const StyledCloseIconWrapper = styled(Box)`
  position: absolute;
  left: 95%;
  top: 0px;
  cursor: pointer;
`;

interface StyledUndoIconProps {
  $lighterColor: boolean;
}

export const StyledUndoIcon = styled(ReactSVG)<StyledUndoIconProps>`
  svg {
    width: 16px;
    height: 16px;
  }
  margin-right: 4px;
  margin-bottom: 2px;
  opacity: ${({ $lighterColor }): number => ($lighterColor ? 0.3 : 1)};
`;

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  svg {
    fill: ${colors.grayscale.gray500};
  }
  &.Mui-checked {
    svg {
      fill: ${colors.primary.base};
    }
  }
`;
