import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { Navigate, useParams } from "react-router-dom";

import { Centered } from "components/Centered";
import { LoadingRelative } from "components/HotLoading";
import { useInboundApplicationApiQuery } from "services/doverapi/endpoints/inboundApplication";
import InboundApplicationProcessingModal from "views/InboundApplication/components/InboundApplicationProcessingModal";

const InboundView = (): React.ReactElement => {
  const { inboundApplicationId } = useParams<{ inboundApplicationId: string }>();
  const {
    data: inboundApplication,
    isFetching: isFetchingInboundApplication,
    error: hasError,
  } = useInboundApplicationApiQuery(inboundApplicationId ?? skipToken);

  const candidateId = inboundApplication?.candidate?.id;
  const candidateEmail = inboundApplication?.email;
  const jobId = inboundApplication?.job?.id;

  if (isFetchingInboundApplication) {
    return (
      <Centered>
        <LoadingRelative />
      </Centered>
    );
  }

  if (hasError) {
    return (
      <Box pt={8} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Box pb={2}>{"We are unable to retrieve details for this Inbound Application."}</Box>
        <Box>
          {"If you believe this to be an error, please contact the Dover Support Team: "}
          <a href="mailto:support@dover.com" target="_top">
            support@dover.com
          </a>
        </Box>
      </Box>
    );
  }

  if (candidateId) {
    return <Navigate replace to={`/candidates/${candidateId}`} />;
  }

  return <InboundApplicationProcessingModal open={true} candidateEmail={candidateEmail} jobId={jobId} />;
};

export default InboundView;
