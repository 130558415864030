import styled from "styled-components";

export const InstructionList = styled.ol`
  padding-inline-start: 0;
  list-style: none;
  counter-reset: custom-counter;
`;

export const ListItem = styled.li`
  position: relative;
  margin-left: 43px;
  margin-bottom: 32px;
  counter-increment: custom-counter;

  &::before {
    position: absolute;
    left: -43px;
    content: counter(custom-counter);
    font-size: 13px;
    color: white;
    width: 25px;
    height: 25px;
    background-color: #355966;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const StyledImage = styled.img`
  object-fit: contain;
  width: auto;
  max-width: 100%;
`;
