import { useGetClientUserFileStatsQuery } from "services/doverapi/endpoints/userUploadedFile";
import { ClientUserFileStats, UserUploadedFileUploadedContextEnum } from "services/openapi";

const useUsersWhoUploadedConnections = (
  uploadedContext: UserUploadedFileUploadedContextEnum
): Array<ClientUserFileStats> | undefined => {
  const { usersWhoUploadedConnections } = useGetClientUserFileStatsQuery(
    {
      uploadedContext,
    },
    {
      selectFromResult: ({ data: clientUserFileStats }) => {
        if (!clientUserFileStats) {
          return { usersWhoUploadedConnections: undefined };
        }

        const usersWhoUploadedConnections = clientUserFileStats.filter(
          (userStats: ClientUserFileStats): boolean => !!userStats.mostRecentUploadTime
        );
        return { usersWhoUploadedConnections };
      },
    }
  );

  return usersWhoUploadedConnections;
};

export default useUsersWhoUploadedConnections;
