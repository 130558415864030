import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { CANDIDATE_INTERVIEW_TRANSCRIPT, DOVER_INTERVIEWER_CANDIDATES } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiGetTranscriptForInterviewRequest,
  ApiApiPartialUpdateCandidateInterviewRequest,
  UpdateCandidateInterview,
  InlineResponse2001,
  InterviewTranscriptResponse,
  ShortenedCandidate,
} from "services/openapi";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";

const interview = doverApi.injectEndpoints({
  endpoints: build => ({
    getInterviews: build.query<ShortenedCandidate[], { doverEntityId: string }>({
      queryFn: async ({ doverEntityId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getDoverInterviewerCandidates({
            doverEntityId,
            isCallCompleted: true,
          });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        return result ? [{ type: DOVER_INTERVIEWER_CANDIDATES }] : [];
      },
    }),
    getCandidatesInScheduling: build.query<ShortenedCandidate[], { doverEntityId: string }>({
      queryFn: async ({ doverEntityId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getDoverInterviewerCandidates({
            doverEntityId,
            isCallCompleted: false,
          });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    replaceInterviewer: build.mutation<InlineResponse2001, { candidateId: string }>({
      queryFn: async ({ candidateId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        showPendingToast("Replacing Interviewer...");
        try {
          const data = await client.replaceInterviewer({ candidateId: candidateId, data: {} });
          showSuccessToast("Successfully replaced interviewer");
          return { data };
        } catch (error) {
          showErrorToast("Failed to replace interviewer");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: DOVER_INTERVIEWER_CANDIDATES }] : [];
      },
    }),
    getTranscriptForInterview: build.query<InterviewTranscriptResponse, ApiApiGetTranscriptForInterviewRequest>({
      queryFn: async ({ candidateInterviewId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getTranscriptForInterview({ candidateInterviewId });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, { candidateInterviewId }) => {
        return result ? [{ type: CANDIDATE_INTERVIEW_TRANSCRIPT, id: candidateInterviewId }] : [];
      },
    }),
    partialUpdateCandidateInterview: build.mutation<
      UpdateCandidateInterview,
      ApiApiPartialUpdateCandidateInterviewRequest
    >({
      queryFn: async ({ id, data }: ApiApiPartialUpdateCandidateInterviewRequest) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const results = await client.partialUpdateCandidateInterview({
            id,
            data,
          });
          return { data: results };
        } catch (error) {
          showErrorToast("Failed to update interview. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useGetInterviewsQuery,
  useGetCandidatesInSchedulingQuery,
  useReplaceInterviewerMutation,
  useGetTranscriptForInterviewQuery,
  usePartialUpdateCandidateInterviewMutation,
} = interview;
