import ClearIcon from "@mui/icons-material/Clear";
import GradingRounded from "@mui/icons-material/GradingRounded";
import { SxProps, Stack, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled, Theme } from "@mui/material/styles";
import React, { useCallback } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useFormContext, Controller } from "react-hook-form";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { truncate } from "utils/strings";

// ----------------------------------------------------------------------

interface CustomFile extends File {
  path?: string;
  preview?: string;
  lastModifiedDate?: Date;
}

interface UploadProps extends DropzoneOptions {
  error?: boolean;
  sx?: SxProps<Theme>;
  thumbnail?: boolean;
  placeholder?: React.ReactNode;
  helperText?: React.ReactNode;
  disableMultiple?: boolean;
  file?: CustomFile | string | null;
  onDelete?: VoidFunction;
  files?: (File | string)[];
  onUpload?: VoidFunction;
  onRemove?: (file: CustomFile | string) => void;
  onRemoveAll?: VoidFunction;
  previewPrefix?: string;
}

// ----------------------------------------------------------------------

const StyledDropZone = styled("div")(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: "flex",
  flexShrink: 0,
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  margin: 0,
  color: theme.palette.text.disabled,
  borderRadius: "4px",
  border: `dashed 1px ${colors.grayscale.gray500}`,
  backgroundColor: colors.white,
  "&:hover": {
    backgroundColor: colors.grayscale.gray050,
  },
}));

const FilePreview = ({
  onDelete,
  fileName,
  truncateNameLength = 35,
  previewPrefix,
}: {
  onDelete?: VoidFunction;
  fileName: string;
  truncateNameLength?: number;
  previewPrefix?: string;
}): React.ReactElement => {
  const fileSuffix = fileName.split(".").pop();
  const truncatedFileName = truncate(fileName, truncateNameLength, fileSuffix);
  const isTruncated = truncatedFileName !== fileName;

  const renderFileName = useCallback(
    (): React.ReactElement => (
      <BodySmall>
        {previewPrefix && `${previewPrefix}:`} <b>{truncatedFileName}</b>
      </BodySmall>
    ),
    [truncatedFileName, previewPrefix]
  );

  return (
    <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <GradingRounded sx={{ color: colors.primary.base }} />
        {isTruncated ? <Tooltip title={fileName}>{renderFileName()}</Tooltip> : renderFileName()}
      </Stack>

      <Tooltip title="Remove">
        <IconButton
          size="small"
          onClick={(e): void => {
            e.stopPropagation();
            if (onDelete) {
              onDelete();
            }
          }}
        >
          <ClearIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const UploadBox = ({
  placeholder,
  error,
  disabled,
  previewPrefix,
  sx,
  file,
  ...rest
}: UploadProps): React.ReactElement => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled,
    ...rest,
  });

  const isError = isDragReject || error;

  return (
    // @ts-ignore
    <StyledDropZone
      {...getRootProps()}
      sx={{
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(isError && {
          color: "error.main",
          bgcolor: "error.lighter",
          borderColor: "error.light",
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: "none",
        }),
        padding: 16,
        ...sx,
      }}
    >
      {/* @ts-ignore */}
      <input {...getInputProps()} />

      {/* @ts-ignore */}
      {file?.name ? (
        <>
          {/* @ts-ignore */}
          <FilePreview fileName={file.name} onDelete={rest.onDelete} previewPrefix={previewPrefix} />
        </>
      ) : (
        placeholder
      )}
    </StyledDropZone>
  );
};

// ----------------------------------------------------------------------

interface ControlledCsvUploadProps extends Omit<UploadProps, "file"> {
  name: string;
  multiple?: boolean;
}

const ControlledCsvUpload = ({ name, previewPrefix, ...rest }: ControlledCsvUploadProps): React.ReactElement => {
  const { control } = useFormContext();

  return (
    <Controller
      // @ts-ignore
      name={name}
      control={control}
      render={({ field, fieldState: { error } }): React.ReactElement => (
        // TODO: refactor to use existing FileDropzone (sc-197353)
        <UploadBox file={field.value} error={!!error} previewPrefix={previewPrefix} {...rest} />
      )}
    />
  );
};

export default ControlledCsvUpload;
