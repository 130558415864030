import { useResetAtom } from "jotai/utils";
import { useEffect } from "react";

import { messagesAtom } from "views/candidates/ApplicationReview/atoms/chat";
import {
  criteriaMatchFilterAtom,
  inboundSourceFilterAtom,
  nameFilterValAtom,
} from "views/candidates/ApplicationReview/atoms/filters";
import {
  multiSelectApplicationsAtom,
  multiSelectEnabledAtom,
} from "views/candidates/ApplicationReview/atoms/multiSelect";
import { pageAtom } from "views/candidates/ApplicationReview/atoms/pagination";

export const useReset = (): void => {
  const resetPage = useResetAtom(pageAtom);
  const resetNameFilter = useResetAtom(nameFilterValAtom);
  const resetChat = useResetAtom(messagesAtom);
  const resetMultiSelectEnabled = useResetAtom(multiSelectEnabledAtom);
  const resetMultiSelectApplications = useResetAtom(multiSelectApplicationsAtom);
  const resetInboundSourceFilter = useResetAtom(inboundSourceFilterAtom);
  const resetCriteriaMatchFilter = useResetAtom(criteriaMatchFilterAtom);

  // Reset the state when changing jobs
  const resetState = (): void => {
    resetNameFilter();
    resetChat();
    resetPage();
    resetMultiSelectEnabled();
    resetMultiSelectApplications();
    resetInboundSourceFilter();
    resetCriteriaMatchFilter();
  };

  useEffect(() => {
    return resetState;
    // We only want to clean up state when the component unmounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
