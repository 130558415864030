import Grid from "@mui/material/Grid";
import { TextField } from "mui-rff";
import React from "react";
import { Form } from "react-final-form";

import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import DoverModal from "components/Modal";
import { Spacer } from "components/Spacer";

interface AddJobPositionButtonProps {
  addPosition: (title: string) => Promise<void>;
}

interface FormData {
  title?: string;
}

const validate = (values: FormData): any => {
  let errors = {};
  if (!values.title) {
    errors = { ...errors, title: "This field is required" };
  }
  return errors;
};

const AddJobPositionButton = ({ addPosition }: AddJobPositionButtonProps): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false);
  const openModal = (): void => setOpen(true);
  const closeModal = (): void => setOpen(false);

  const onSubmit = async (values: FormData): Promise<void> => {
    await addPosition(values.title!);
    closeModal();
  };

  return (
    <>
      <Button variant={ButtonVariant.Primary} onClick={openModal}>
        + Add Position
      </Button>
      <DoverModal title={"Add a New Job Position"} open={open} onClose={closeModal} maxWidth="sm">
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitting }): React.ReactNode => (
            <form onSubmit={handleSubmit} noValidate={true}>
              <div>
                <Body>{`What's the title of the position?`}</Body>
                <Spacer height="16px" />
                <TextField
                  disabled={submitting}
                  name="title"
                  required
                  variant="outlined"
                  fullWidth
                  placeholder="Senior Software Engineer"
                  autoFocus
                />
              </div>
              <Spacer height="60px" />
              <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
                <Button variant={ButtonVariant.Primary} onClick={handleSubmit} disabled={submitting}>
                  {submitting ? "Creating..." : "Create"}
                </Button>
              </Grid>
            </form>
          )}
        />
      </DoverModal>
    </>
  );
};

export default AddJobPositionButton;
