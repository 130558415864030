import { Box, Skeleton, Stack } from "@mui/material";
import { sortBy } from "lodash";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { RequiredAsterisk } from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup/components/RequiredAsterisk";
import { InboundCriteriaSetupFormValues } from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup/types";
import { ControlledAutocomplete } from "components/library/Autocomplete";
import { Subtitle2, Body, BodySmall } from "components/library/typography";
import { useListAllLocations, useListPersonasQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import { PersonaIDsRequired, LocationWithValue } from "services/doverapi/endpoints/search-v3/types";
import { colors } from "styles/theme";

export const JobLocationFormSection = (): React.ReactElement => {
  const { control, setValue } = useFormContext<InboundCriteriaSetupFormValues>();
  const locationsOptions = useListAllLocations();
  const locationsFormValue = useWatch({ control, name: "locations" });

  const personaFormValue = useWatch({ control, name: "persona" });
  const { data: personasOptions, isLoading: isPersonasLoading } = useListPersonasQuery({ limit: 1000 });

  const sortPersonasHelper = (persona: PersonaIDsRequired): string => {
    // we want personas to not only be sorted by their classification, but also by their name
    return persona.jobCategoryDisplay + persona.cleanName;
  };

  const sortedAndFilteredPersonaOptions = React.useMemo(() => {
    if (!personasOptions) {
      return [];
    }

    // we need to alphabetise the list of personas and filter out the Custom option
    const alphabetizedPersonas = sortBy(personasOptions, persona => sortPersonasHelper(persona)).filter(
      persona => persona.cleanName !== "Custom"
    );

    return [...alphabetizedPersonas];
  }, [personasOptions]);

  const setPersona = (persona: PersonaIDsRequired | undefined): void => {
    if (persona) {
      setValue("persona", persona);
    }
  };

  return (
    <Stack spacing={2}>
      {!sortedAndFilteredPersonaOptions || isPersonasLoading ? (
        <Skeleton height="32px" width="100%" />
      ) : (
        <ControlledAutocomplete
          title={
            <Subtitle2>
              Role type <RequiredAsterisk />
            </Subtitle2>
          }
          control={control}
          name="persona"
          getOptionLabel={(option: PersonaIDsRequired): string => option.cleanName!} // safe assert because of how we define personasWithCleanNames
          staticOptions={sortedAndFilteredPersonaOptions}
          placeholder={"Search for a persona..."}
          onSelectedOptionChange={setPersona}
          initialValue={personaFormValue ?? null}
          isOptionEqualToValue={(option: PersonaIDsRequired, value: PersonaIDsRequired): boolean => {
            return option.id === value.id;
          }}
          groupBy={(option: PersonaIDsRequired): string => option.jobCategoryDisplay}
          renderOption={(props, option: PersonaIDsRequired): React.ReactElement => {
            return (
              // @ts-ignore
              <Box key={option.id} style={{ color: props.color ?? "inherit" }} {...props} p={1}>
                <Stack direction="column" spacing={0.5}>
                  <Body>{option.cleanName}</Body>
                  {option.definition && <BodySmall color={colors.grayscale.gray500}>{option.definition}</BodySmall>}
                </Stack>
              </Box>
            );
          }}
        />
      )}
      <ControlledAutocomplete
        control={control}
        name="locations"
        fontSize="small"
        title={
          <Subtitle2>
            City/State/Country <RequiredAsterisk />
          </Subtitle2>
        }
        placeholder={"Enter location..."}
        staticOptions={locationsOptions}
        filterSelectedOptions={true}
        getOptionLabel={(option: LocationWithValue): string => option.name}
        isOptionEqualToValue={(option: LocationWithValue, value: LocationWithValue): boolean =>
          option.value === value.value
        }
        initialValues={locationsFormValue ?? []}
        multiple={true}
        sortByField={"name"}
      />
    </Stack>
  );
};
