import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React from "react";

import { BodySmall, Subtitle2 } from "components/library/typography";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { StepTypes } from "sections/addcandidate/types";
import { ClientOnboardingAtsTypeEnum } from "services/openapi";
import { titleCase } from "utils/strings";

const AddAtsCandidateToggle = ({ atsType }: { atsType?: ClientOnboardingAtsTypeEnum }): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: { [StepTypes.ATS_CANDIDATE_INFO]: state },
    setStepState,
  } = useAddCandidateContext();

  //-----------------------------------------------------------------------------
  // Callbacks

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newValue: boolean): void => {
    setStepState(StepTypes.ATS_CANDIDATE_INFO, { ...state, addingAtsCandidate: newValue });
  };

  //-----------------------------------------------------------------------------
  // Render

  return (
    <Stack sx={{ mb: 2, mt: 2 }} spacing={2}>
      <Subtitle2>Do you want to add a candidate from {titleCase(atsType ?? "your ATS")}?</Subtitle2>
      <ToggleButtonGroup
        color="primary"
        value={state.addingAtsCandidate}
        exclusive
        onChange={handleChange}
        aria-label="Import a candidate"
      >
        <ToggleButton value={true} sx={{ textTransform: "none", width: "50px" }} size="small">
          <BodySmall>Yes</BodySmall>
        </ToggleButton>
        <ToggleButton value={false} sx={{ textTransform: "none", width: "50px" }} size="small">
          <BodySmall>No</BodySmall>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default AddAtsCandidateToggle;
