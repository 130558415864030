import { HiringPipelineStage } from "services/openapi";

// Calculates the total duration of a all multipart substages
export const calcMultipartDuration = (stage: HiringPipelineStage): number | undefined => {
  const substages = stage.multipartInterviewStage?.substages;

  if (!substages) {
    return undefined;
  }

  return substages.reduce((acc, substage) => acc + (substage.duration ?? 0), 0);
};
