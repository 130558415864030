import { Spinner } from "reactstrap";
import styled from "styled-components";

const spinnerColor = "#312f4a";

interface SizeableSpinnerProps {
  fontSize: string;
  height: string;
  color: string;
}

export const SizeableSpinner = styled(Spinner)<SizeableSpinnerProps>`
  font-size: ${({ fontSize }): string => fontSize};
  height: ${({ height }): string => height};
  width: ${({ height }): string => height};
  color: ${({ spinnerColor }): string => spinnerColor};
`;

export const LoadingRelative = styled(Spinner)`
  width: 3rem;
  height: 3rem;
  color: ${spinnerColor};
`;

const SpinLoading = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3rem;
  height: 3rem;
  color: ${spinnerColor};
`;

export default SpinLoading;
