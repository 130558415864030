import Stack from "@mui/material/Stack";
import React, { useMemo } from "react";

import { Button, ButtonVariant } from "components/library/Button";
import Drawer from "components/library/Drawer";
import { Body, ButtonText, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import DrawerHeader from "sections/addcandidate/components/DrawerHeader";
import FlowComplete from "sections/addcandidate/components/FlowComplete";
import HorizontalLoader from "sections/addcandidate/components/HorizontalLoader";
import FlowNav from "sections/addcandidate/components/Nav";
import Stepper from "sections/addcandidate/components/Stepper";
import { useGetSteps } from "sections/addcandidate/components/steps";
import { flowDescriptions } from "sections/addcandidate/data/flowConfig";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";

//------------------------------------------------------------------------------
// Sub Components

const DrawerBody = (): React.ReactElement => {
  const { activeStep, loading, flowType } = useAddCandidateContext();
  // Select steps for the selected flow
  const steps = useGetSteps(flowType);
  const finished = useMemo(() => activeStep === steps.length, [activeStep, steps.length]);

  if (finished) {
    return <FlowComplete />;
  }

  return (
    <>
      {/* Nav: Flow tabs / select  */}
      <FlowNav />

      {/* Main Content: Flow steps / Body */}
      <Stack
        direction="column"
        sx={{ p: 3, width: "100%", height: "100%", overflow: "auto" }}
        spacing={3}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Body>{flowDescriptions[flowType]}</Body>

        {/* Loader and flow steps */}
        {loading ? <HorizontalLoader /> : <Stepper steps={steps} />}
      </Stack>
    </>
  );
};

const ConfirmDrawerCloseDialog = (): React.ReactElement => {
  const { discardChangesModalOpen, setDiscardChangesModalOpen, closeModal } = useAddCandidateContext();
  const closeDiscardChangesModal = (): void => setDiscardChangesModalOpen(false);

  return (
    <CustomModal
      open={discardChangesModalOpen}
      title={<Subtitle2>Are you sure?</Subtitle2>}
      onClose={closeDiscardChangesModal}
      maxWidth="sm"
      dialogActions={
        <Stack direction="row" spacing={1}>
          <Button
            onClick={(): void => {
              closeDiscardChangesModal();
              closeModal(true);
            }}
            variant={ButtonVariant.Secondary}
          >
            <ButtonText>Yes, close</ButtonText>
          </Button>
          <Button onClick={closeDiscardChangesModal} variant={ButtonVariant.Primary}>
            <ButtonText>Cancel</ButtonText>
          </Button>
        </Stack>
      }
    >
      <Body>Closing will discard all of your changes.</Body>
    </CustomModal>
  );
};

//------------------------------------------------------------------------------
// Main Component

const AddCandidate = (): React.ReactElement => {
  const { closeModal, modalOpen } = useAddCandidateContext();
  // Select steps for the selected flow
  const onClose = (): void => closeModal(false);

  return (
    <>
      {/* Confirm discard changes modal */}
      <ConfirmDrawerCloseDialog />

      {/* Add candidate drawer */}
      <Drawer
        open={modalOpen}
        anchor="right"
        onClose={onClose}
        sx={{ position: "relative", overflow: "auto" }}
        shouldToggleIntercom
      >
        <DrawerHeader />
        <DrawerBody />
      </Drawer>
    </>
  );
};

export default (): React.ReactElement => {
  const context = useAddCandidateContext();

  if (!context) {
    return <></>;
  }

  return <AddCandidate />;
};
