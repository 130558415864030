import React from "react";

import { StyledFormLabel } from "components/inputs/common";

interface Props {
  label: string;
  error?: boolean;
  required?: boolean;
}

const Label = ({ label, error }: Props): React.ReactElement => {
  return <StyledFormLabel error={error}>{label}</StyledFormLabel>;
};

export default Label;
