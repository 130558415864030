/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchV3Params,
    SearchV3ParamsFromJSON,
    SearchV3ParamsFromJSONTyped,
    SearchV3ParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface TalentNetworkRequest
 */
export interface TalentNetworkRequest {
    /**
     * 
     * @type {SearchV3Params}
     * @memberof TalentNetworkRequest
     */
    params?: SearchV3Params;
    /**
     * 
     * @type {string}
     * @memberof TalentNetworkRequest
     */
    searchId: string;
    /**
     * 
     * @type {number}
     * @memberof TalentNetworkRequest
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof TalentNetworkRequest
     */
    limit: number;
}

export function TalentNetworkRequestFromJSON(json: any): TalentNetworkRequest {
    return TalentNetworkRequestFromJSONTyped(json, false);
}

export function TalentNetworkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TalentNetworkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'params': !exists(json, 'params') ? undefined : SearchV3ParamsFromJSON(json['params']),
        'searchId': json['search_id'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'limit': json['limit'],
    };
}

export function TalentNetworkRequestToJSON(value?: TalentNetworkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'params': SearchV3ParamsToJSON(value.params),
        'search_id': value.searchId,
        'page': value.page,
        'limit': value.limit,
    };
}


