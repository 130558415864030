/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateHiringPipelineStageRequest
 */
export interface UpdateHiringPipelineStageRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateHiringPipelineStageRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateHiringPipelineStageRequest
     */
    orderIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateHiringPipelineStageRequest
     */
    atsStageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHiringPipelineStageRequest
     */
    schedulingEmailTemplateId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateHiringPipelineStageRequest
     */
    rejectionEmailTemplateId?: string | null;
}

export function UpdateHiringPipelineStageRequestFromJSON(json: any): UpdateHiringPipelineStageRequest {
    return UpdateHiringPipelineStageRequestFromJSONTyped(json, false);
}

export function UpdateHiringPipelineStageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateHiringPipelineStageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'orderIndex': !exists(json, 'order_index') ? undefined : json['order_index'],
        'atsStageId': !exists(json, 'ats_stage_id') ? undefined : json['ats_stage_id'],
        'schedulingEmailTemplateId': !exists(json, 'scheduling_email_template_id') ? undefined : json['scheduling_email_template_id'],
        'rejectionEmailTemplateId': !exists(json, 'rejection_email_template_id') ? undefined : json['rejection_email_template_id'],
    };
}

export function UpdateHiringPipelineStageRequestToJSON(value?: UpdateHiringPipelineStageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'order_index': value.orderIndex,
        'ats_stage_id': value.atsStageId,
        'scheduling_email_template_id': value.schedulingEmailTemplateId,
        'rejection_email_template_id': value.rejectionEmailTemplateId,
    };
}


