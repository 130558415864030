import { PersonaIDsRequired } from "services/doverapi/endpoints/search-v3/types";
import { JobSetupPersona, SearchV3 } from "services/openapi";
import { emptySearchV3Params } from "views/sourcing/Search/constants";

export const isCustomPersonaOption = (persona: JobSetupPersona): boolean => {
  return persona.customerFacingName?.toLowerCase() === "custom";
};

interface GetNewSearchArgs {
  persona?: PersonaIDsRequired;
  searchName: string;
  jobId: string;
  clientId: string;
}

export function getNewSearchV3({ persona, searchName, jobId, clientId }: GetNewSearchArgs): SearchV3 {
  return {
    name: searchName,
    active: true,
    job: jobId,
    client: clientId,
    version: 3,
    autoDepthAdjustmentsEnabled: false,
    v3Params: {
      targetPersonas: persona
        ? [
            {
              field: "personas",
              value: [persona.id],
              required: true,
              weight: undefined,
            },
          ]
        : [],
      ...emptySearchV3Params,
    },
  };
}
