import Alert from "@mui/material/Alert";
import styled from "styled-components";

import { colors } from "styles/theme";

/**
 * @deprecated not an official component; refer to dover-ui
 *
 * Prefer to deprecate a shared styles file in favor of dover-ui / components with
 * self contained styles and a shared theme
 *
 */
export const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    border: 1px solid #ffa0a0;
    background-color: #ffe4e4;
    color: ${colors.black};
    font-family: Nunito Sans;
    font-size: 14px;
    margin-bottom: 8px;
  }
`;
