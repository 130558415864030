import { CreateCampaignEmailSenderOptionEnum } from "services/openapi";

export enum CampaignStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
  Inactive = "INACTIVE",
  Processing = "PROCESSING",
}

export interface AddNewCampaignProps {
  campaignName: string | undefined;
  emailSenderOption: CreateCampaignEmailSenderOptionEnum | undefined;
  userDefinedSenderUser: number | undefined;
}
