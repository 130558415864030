import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { HiringPipelineState } from "components/SetupHiringPipeline/types";

export const initialState: HiringPipelineState = {
  jobId: undefined,
  includesTakeHomeStage: false,
  atsSettingsDismissed: false,
};

const hiringPipelineSlice = createSlice({
  name: "HiringPipeline",
  initialState: initialState,
  reducers: {
    setJobId: (state, action: PayloadAction<string>): void => {
      state.jobId = action.payload;
    },
    setIncludesTakeHomeStage(state, action: PayloadAction<boolean>): void {
      state.includesTakeHomeStage = action.payload;
    },
    setATSSettingsDismissed(state, action: PayloadAction<boolean>): void {
      state.atsSettingsDismissed = action.payload;
    },
  },
});

export const hiringPipelineReducer = hiringPipelineSlice.reducer;
export const { setJobId, setIncludesTakeHomeStage, setATSSettingsDismissed } = hiringPipelineSlice.actions;
