import { Paper, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

import { LoadingRelative } from "components/HotLoading";
import DoverLogo from "components/icons/DoverLogo";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, PageTitle } from "components/library/typography";
import { useOpenApiClients } from "hooks/openApiClients";
import { useAuth0 } from "services/react-auth0-spa";
import { screenSizesNumbers } from "styles/theme";
import { FakeLink } from "styles/typography";

const VerifyEmail = () => {
  const { user, logout } = useAuth0();
  const apiApi = useOpenApiClients()?.apiApi;
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tabletL));

  const [loading, setLoading] = React.useState(false);
  const [showSentConfirmation, setShowSentConfirmation] = React.useState(false);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper sx={{ minWidth: isSmallScreen ? 400 : 700, maxWidth: 700 }} elevation={6}>
        <Stack
          spacing={2}
          alignContent="center"
          justifyContent="center"
          sx={{
            textAlign: "center",
            padding: "20px",
          }}
        >
          <Box>
            <DoverLogo width={120} />
          </Box>
          {loading && (
            <Box>
              <LoadingRelative />
            </Box>
          )}
          {!loading && (
            <>
              {showSentConfirmation ? (
                <>
                  <PageTitle>Email verification email sent!</PageTitle>
                  <BodySmall weight="600">{user.email}</BodySmall>
                </>
              ) : (
                <>
                  <PageTitle>Please verify your email</PageTitle>
                  <BodySmall>Click on the verification link in the email we sent to you.</BodySmall>
                  <BodySmall weight="600">{user.email}</BodySmall>
                </>
              )}
              <BodySmall>If you don&apos;t see it, please check your spam folder. Already verified?</BodySmall>
              <Box>
                <BodySmall>
                  <Button
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Go to app
                  </Button>
                </BodySmall>
                <br />
                <Stack direction="row" alignContent="center" justifyContent="center" spacing={0.5}>
                  <BodySmall>Can&apos;t find the email?</BodySmall>
                  <BodySmall>
                    <FakeLink
                      onClick={() => {
                        try {
                          setLoading(true);
                          apiApi?.resendEmailVerificationEmail({
                            data: {
                              email: user.email,
                            },
                          });
                        } finally {
                          setShowSentConfirmation(true);
                          setLoading(false);
                        }
                      }}
                    >
                      Resend email
                    </FakeLink>
                  </BodySmall>
                </Stack>
                <Stack direction="row" alignContent="center" justifyContent="center" spacing={0.5}>
                  <BodySmall>{showSentConfirmation ? "Already verified?" : "Not the right email address?"}</BodySmall>
                  <BodySmall>
                    <FakeLink
                      onClick={() => {
                        logout({
                          returnTo: window.location.origin,
                        });
                      }}
                    >
                      {showSentConfirmation ? "Sign in" : "Sign in again"}
                    </FakeLink>
                  </BodySmall>
                </Stack>
              </Box>
            </>
          )}
        </Stack>
      </Paper>
    </Grid>
  );
};

export default VerifyEmail;
