/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseFeedbackTemplateQuestion,
    BaseFeedbackTemplateQuestionFromJSON,
    BaseFeedbackTemplateQuestionFromJSONTyped,
    BaseFeedbackTemplateQuestionToJSON,
    FeedbackTemplateQuestionComponent,
    FeedbackTemplateQuestionComponentFromJSON,
    FeedbackTemplateQuestionComponentFromJSONTyped,
    FeedbackTemplateQuestionComponentToJSON,
    SelectFieldFeedbackTemplateQuestionAllOf,
    SelectFieldFeedbackTemplateQuestionAllOfFromJSON,
    SelectFieldFeedbackTemplateQuestionAllOfFromJSONTyped,
    SelectFieldFeedbackTemplateQuestionAllOfToJSON,
    SelectFieldOption,
    SelectFieldOptionFromJSON,
    SelectFieldOptionFromJSONTyped,
    SelectFieldOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface SelectFieldFeedbackTemplateQuestion
 */
export interface SelectFieldFeedbackTemplateQuestion extends BaseFeedbackTemplateQuestion {
    /**
     * 
     * @type {FeedbackTemplateQuestionComponent}
     * @memberof SelectFieldFeedbackTemplateQuestion
     */
    component: FeedbackTemplateQuestionComponent;
    /**
     * 
     * @type {string}
     * @memberof SelectFieldFeedbackTemplateQuestion
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SelectFieldFeedbackTemplateQuestion
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof SelectFieldFeedbackTemplateQuestion
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectFieldFeedbackTemplateQuestion
     */
    helperText?: string;
    /**
     * 
     * @type {Array<SelectFieldOption>}
     * @memberof SelectFieldFeedbackTemplateQuestion
     */
    options: Array<SelectFieldOption>;
    /**
     * 
     * @type {boolean}
     * @memberof SelectFieldFeedbackTemplateQuestion
     */
    multiple?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SelectFieldFeedbackTemplateQuestion
     */
    maxOptions?: number;
}

export function SelectFieldFeedbackTemplateQuestionFromJSON(json: any): SelectFieldFeedbackTemplateQuestion {
    return SelectFieldFeedbackTemplateQuestionFromJSONTyped(json, false);
}

export function SelectFieldFeedbackTemplateQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectFieldFeedbackTemplateQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...BaseFeedbackTemplateQuestionFromJSONTyped(json, ignoreDiscriminator),
        'component': FeedbackTemplateQuestionComponentFromJSON(json['component']),
        'name': json['name'],
        'label': json['label'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'helperText': !exists(json, 'helper_text') ? undefined : json['helper_text'],
        'options': ((json['options'] as Array<any>).map(SelectFieldOptionFromJSON)),
        'multiple': !exists(json, 'multiple') ? undefined : json['multiple'],
        'maxOptions': !exists(json, 'max_options') ? undefined : json['max_options'],
    };
}

export function SelectFieldFeedbackTemplateQuestionToJSON(value?: SelectFieldFeedbackTemplateQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...BaseFeedbackTemplateQuestionToJSON(value),
        'component': FeedbackTemplateQuestionComponentToJSON(value.component),
        'name': value.name,
        'label': value.label,
        'placeholder': value.placeholder,
        'helper_text': value.helperText,
        'options': ((value.options as Array<any>).map(SelectFieldOptionToJSON)),
        'multiple': value.multiple,
        'max_options': value.maxOptions,
    };
}


