import { Select } from "@mui/material";
import styled from "styled-components";
export const StyledSelect = styled(Select)`
  width: 360px;
  height: 36px;

  .MuiSelect-select {
    background: white;
    font-family: Inter;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
  }
  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }

  &.MuiInputBase-root {
    border: none !important;
  }
`;
