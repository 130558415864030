import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import React from "react";

import { HEADER_HEIGHT, JOB_PITCH_HELP_DOC_HREF } from "components/dover/JobPitch/data/misc";
import { useJobPitchContext } from "components/dover/JobPitch/hooks/useJobPitchContext";
import { Body, Heading } from "components/library/typography";

const DrawerHeader = (): React.ReactElement => {
  const { closeModal } = useJobPitchContext();
  const theme = useTheme();

  const onClose = (): void => closeModal(false);

  return (
    <Box sx={{ position: "sticky", top: 0, height: HEADER_HEIGHT, zIndex: 1400 }}>
      <Paper elevation={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: "100%", pl: 3, pr: 3, pt: 1, pb: 1 }}
        >
          {/* Title */}
          <Heading>Job Pitch</Heading>

          {/* Left Side: Help & Close button */}
          <Stack direction="row" alignItems="center" spacing={1}>
            <MuiButton
              variant="text"
              startIcon={<HelpOutlineRoundedIcon />}
              sx={{ textTransform: "none" }}
              href={JOB_PITCH_HELP_DOC_HREF}
              target="_blank"
            >
              <Body color={theme.palette.primary.dark}>Help</Body>
            </MuiButton>
            <IconButton aria-label="close" onClick={onClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

export default DrawerHeader;
