export const standardSelectableVariables = [
  "scheduling_link",
  "first_name",
  "sender_first_name",
  "interviewer_first_name",
  "interviewer_role_title_with_article",
  "job_title",
];

export const multipartStageSelectableVariables = ["first_name", "sender_first_name", "client_name"];
