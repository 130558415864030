import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import styled from "styled-components";

const StyledReactPlayer = styled(ReactPlayer)`
  .react-player__preview {
    border-radius: 10px;
  }
  .react-player__play-icon {
    display: none;
  }
  .react-player__preview {
    background-color: #071b30;
  }
`;

interface RecruitingPartnerVideoProps extends ReactPlayerProps {
  width?: string;
  height?: string;
}
const RecruitingPartnerVideo = ({ width, height, ...rest }: RecruitingPartnerVideoProps): React.ReactElement => {
  return (
    <StyledReactPlayer
      controls
      url="https://cdn.dover.io/dover/meet_the_team.mp4"
      height={height}
      width={width}
      playing={true}
      light="https://cdn.dover.io/dover/sirid_meet_the_team_three.png"
      {...rest}
    />
  );
};

export default RecruitingPartnerVideo;
