import styled from "styled-components";

import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

export const FilterPanel = styled.div`
  width: 200px;
`;

export const FilterTitle = styled.div`
  font-size: 16px;
  color: ${colors.grayscale.gray700};
`;

export const FilterDivider = styled.div`
  border-top: 1px solid ${colors.grayscale.gray300};
`;

export const FilterSectionLabel = styled(B2_doNotUse)`
  color: ${colors.grayscale.gray500};
`;
