import { Box, Stack } from "@mui/material";
import React from "react";

import { ButtonText } from "components/library/typography";
import { colors } from "styles/theme";

export const MobilePreviewToggle = ({
  previewModeOn,
  setPreviewModeOn,
}: {
  previewModeOn: boolean;
  setPreviewModeOn: (mobilePreviewModeOn: boolean) => void;
}): React.ReactElement => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      style={{ borderRadius: "4px", border: `solid 1px ${colors.grayscale.gray200}` }}
    >
      <Box
        flexGrow={1}
        style={{
          textAlign: "center",
          padding: "10px",
          borderRight: `solid 1px ${colors.grayscale.gray200}`,
          backgroundColor: previewModeOn ? colors.white : colors.grayscale.gray100,
          cursor: "pointer",
          borderRadius: "3px 0 0 3px",
        }}
        onClick={(): void => setPreviewModeOn(false)}
      >
        <ButtonText color={previewModeOn ? colors.grayscale.gray600 : colors.primary.base}>Edit</ButtonText>
      </Box>
      <Box
        flexGrow={1}
        style={{
          textAlign: "center",
          padding: "10px",
          backgroundColor: previewModeOn ? colors.grayscale.gray100 : colors.white,
          cursor: "pointer",
          borderRadius: "0 3px 3px 0",
        }}
        onClick={(): void => setPreviewModeOn(true)}
      >
        <ButtonText color={previewModeOn ? colors.primary.base : colors.grayscale.gray600}>Preview</ButtonText>
      </Box>
    </Stack>
  );
};
