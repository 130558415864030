/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HiringPipelineStageType {
    QUEUED = 'QUEUED',
    CONTACTED = 'CONTACTED',
    RESPONDED = 'RESPONDED',
    APPLICATION_REVIEW = 'APPLICATION_REVIEW',
    OFFER = 'OFFER',
    INTERVIEW = 'INTERVIEW'
}

export function HiringPipelineStageTypeFromJSON(json: any): HiringPipelineStageType {
    return HiringPipelineStageTypeFromJSONTyped(json, false);
}

export function HiringPipelineStageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HiringPipelineStageType {
    return json as HiringPipelineStageType;
}

export function HiringPipelineStageTypeToJSON(value?: HiringPipelineStageType | null): any {
    return value as any;
}

