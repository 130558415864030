import React from "react";
import { Field } from "react-final-form";
import styled from "styled-components";

const StyledErrorSpan = styled.span`
  && {
    color: ${(props): string => props.theme.colors.critical.dark};
  }
`;

const isErrorRenderable = (error: any): boolean => {
  return !!error && (React.isValidElement(error) || typeof error === "string");
};

const Error: React.FC<{ name: string }> = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }): React.ReactElement | null =>
      touched && isErrorRenderable(error) ? <StyledErrorSpan>{error}</StyledErrorSpan> : null
    }
  />
);

export const AlwaysShowError: React.FC<{ name: string }> = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { error } }): React.ReactElement | null => {
      return isErrorRenderable(error) ? <StyledErrorSpan>{error}</StyledErrorSpan> : null;
    }}
  />
);

export default Error;
