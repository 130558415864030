import { Menu, Stack } from "@mui/material";
import React, { FC } from "react";

import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";
import { CandidateSourceSection } from "views/candidates/CandidateTable/filters/AdvancedFilterSection/CandidateSourceSection";
import { CandidateStatusFilterSection } from "views/candidates/CandidateTable/filters/AdvancedFilterSection/CandidateStatusSection";

interface FilterMenuProps {
  anchor: Element | null;
  onClose: () => void;
}

export const FilterMenu: FC<FilterMenuProps> = ({ anchor, onClose }) => {
  return (
    <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={onClose}>
      <Stack spacing={1} py={0.5} px={1}>
        <Section>
          <Label>Status</Label>
          <CandidateStatusFilterSection />
        </Section>
        <Section>
          <Label>Source</Label>
          <CandidateSourceSection />
        </Section>
      </Stack>
    </Menu>
  );
};

const Label: React.FC = ({ children }) => (
  <BodySmall color={colors.grayscale.gray500} weight="600">
    {children}
  </BodySmall>
);

const Section: React.FC = ({ children }) => <Stack spacing={0.5}>{children}</Stack>;
