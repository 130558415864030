import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as NoApplicantsIcon } from "assets/icons/no-applicants-icon.svg";
import { Subtitle1 } from "components/library/typography";

const NoDTNMatches = (): React.ReactElement => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={2} px={1}>
      <Stack alignItems="center">
        <NoApplicantsIcon />
        <Subtitle1>Candidate details will display here</Subtitle1>
      </Stack>
    </Stack>
  );
};

export default NoDTNMatches;
