/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultipartInterviewSubstage,
    MultipartInterviewSubstageFromJSON,
    MultipartInterviewSubstageFromJSONTyped,
    MultipartInterviewSubstageToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidateFacingMultipartInterview
 */
export interface CandidateFacingMultipartInterview {
    /**
     * 
     * @type {string}
     * @memberof CandidateFacingMultipartInterview
     */
    readonly label?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateFacingMultipartInterview
     */
    readonly doverStage?: string;
    /**
     * 
     * @type {Array<MultipartInterviewSubstage>}
     * @memberof CandidateFacingMultipartInterview
     */
    readonly substages?: Array<MultipartInterviewSubstage>;
    /**
     * 
     * @type {string}
     * @memberof CandidateFacingMultipartInterview
     */
    readonly reschedulingLink?: string;
}

export function CandidateFacingMultipartInterviewFromJSON(json: any): CandidateFacingMultipartInterview {
    return CandidateFacingMultipartInterviewFromJSONTyped(json, false);
}

export function CandidateFacingMultipartInterviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateFacingMultipartInterview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'doverStage': !exists(json, 'dover_stage') ? undefined : json['dover_stage'],
        'substages': !exists(json, 'substages') ? undefined : ((json['substages'] as Array<any>).map(MultipartInterviewSubstageFromJSON)),
        'reschedulingLink': !exists(json, 'rescheduling_link') ? undefined : json['rescheduling_link'],
    };
}

export function CandidateFacingMultipartInterviewToJSON(value?: CandidateFacingMultipartInterview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


