import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  SubmitJobCandidateSourceFormArgs,
  UpsertJobAtsSourceSettingArg,
} from "services/doverapi/endpoints/job-source-settings/types";
import {
  JOB_ATS_SOURCES,
  JOB_ATS_SOURCE_SETTINGS,
  JOB_CANDIDATE_SOURCE_SETTING,
  JOB,
  JOB_SETUP_STEP,
  JOB_DESCRIPTION,
  LIST_TAG,
  EDIT_CAREERS_PAGE_JOB,
  CANDIDATE_SOURCE,
  JOB_BOARDS,
  CAREERS_PAGE_JOB_COUNT,
} from "services/doverapi/endpointTagsConstants";
import {
  AtsSource,
  CandidateSource,
  CreateJobCandidateSourceSettingDeactivationReasonEnum,
  CreateJobCandidateSourceSettingDesiredStateEnum,
  CreateJobCandidateSourceSettingExternalJobOriginEnum,
  JobAtsSourceSetting,
  JobAtsSourceSettingUpsertMultipleRequestStateEnum,
  JobCandidateSourceSetting,
  JobCandidateSourceStats,
  SyncAtsSourcesResponse,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

const jobSourceSettingsEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getJobCandidateSources: build.query<
      CandidateSource[],
      { jobId: string; includeAdminPreview: boolean; includeYcWaas?: boolean }
    >({
      queryFn: async ({ includeAdminPreview, jobId, includeYcWaas }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listCandidateSources({
            limit: 1000,
            includeAdminPreview,
            jobId,
            includeYcWaas,
          });
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load candidate sources. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, args) => {
        return result
          ? [
              { type: JOB_BOARDS, id: args.jobId } as const,
              { type: JOB, id: args.jobId } as const,
              { type: JOB_SETUP_STEP, id: args.jobId } as const,
              { type: JOB_DESCRIPTION, id: args.jobId } as const,
            ]
          : [];
      },
    }),
    getJobCandidateSourceSettings: build.query<
      JobCandidateSourceSetting[],
      { jobId: string; includeAdminPreview: boolean; includeYcWaas?: boolean }
    >({
      queryFn: async ({ includeAdminPreview, jobId, includeYcWaas }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listJobCandidateSourceSettings({
            limit: 1000,
            includeAdminPreview,
            job: jobId,
            includeYcWaas,
          });
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load candidate sources settings. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => {
        // is result available?
        return result ? [{ type: JOB_CANDIDATE_SOURCE_SETTING, id: LIST_TAG }] : [];
      },
    }),
    getCandidateSourceStats: build.query<JobCandidateSourceStats[], { jobId: string }>({
      queryFn: async ({ jobId }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const data = await apiApi.getCandidateSourceStats({ id: jobId! });
          return { data };
        } catch (error) {
          showErrorToast("Failed to load candidate source stats. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    submitJobCandidateSourceForm: build.mutation<JobCandidateSourceSetting, SubmitJobCandidateSourceFormArgs>({
      queryFn: async ({
        jobId,
        candidateSourceId,
        desiredState,
        externalJobOrigin,
        externalJobIdentifier,
        atsInboundRejectionEnabled,
        deactivationReason,
        deactivationReasonNotes,
        additionalInfo,
        shouldAutoRenew,
        pricingTierId,
        forceSetState,
      }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.upsertJobCandidateSourceSetting({
            data: {
              job: jobId,
              candidateSource: candidateSourceId,
              desiredState: (desiredState as string) as CreateJobCandidateSourceSettingDesiredStateEnum,
              externalJobOrigin: (externalJobOrigin as string) as CreateJobCandidateSourceSettingExternalJobOriginEnum,
              externalJobIdentifier: externalJobIdentifier,
              atsInboundRejectionEnabled:
                atsInboundRejectionEnabled === undefined ? undefined : atsInboundRejectionEnabled === "true",
              deactivationReason:
                deactivationReason === undefined
                  ? undefined
                  : ((deactivationReason as string) as CreateJobCandidateSourceSettingDeactivationReasonEnum),
              deactivationReasonNotes,
              additionalInfo,
              shouldAutoRenew: shouldAutoRenew ?? undefined,
              pricingTierId,
              forceSetState,
            },
          });
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to add job board.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return result
          ? [
              { type: CANDIDATE_SOURCE, id: LIST_TAG },
              { type: JOB_CANDIDATE_SOURCE_SETTING, id: LIST_TAG },
              { type: EDIT_CAREERS_PAGE_JOB, id: LIST_TAG },
              { type: CAREERS_PAGE_JOB_COUNT, id: LIST_TAG },
              { type: JOB_SETUP_STEP, id: args.jobId },
              { type: JOB_DESCRIPTION, id: args.jobId },
              { type: JOB, id: args.jobId },
            ]
          : [];
      },
    }),
    listJobAtsSourceSettings: build.query<JobAtsSourceSetting[], void>({
      queryFn: async () => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listJobAtsSourceSettings({ limit: 1000 });
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load ats source settings. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => (result ? [{ type: JOB_ATS_SOURCE_SETTINGS, id: LIST_TAG }] : []),
    }),
    upsertJobAtsSourceSettings: build.mutation<JobAtsSourceSetting[], UpsertJobAtsSourceSettingArg>({
      queryFn: async ({ jobCandidateSourceSettingId, atsSource, state }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.upsertMultipleJobAtsSourceSettings({
            data: {
              jobCandidateSourceSettingId,
              atsSourceName: atsSource.name!,
              state: (state as string) as JobAtsSourceSettingUpsertMultipleRequestStateEnum,
            },
          });

          return { data: response };
        } catch (error) {
          showErrorToast("Failed to submit form. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: JOB_ATS_SOURCE_SETTINGS, id: LIST_TAG }] : [];
      },
    }),
    loadAtsSources: build.query<AtsSource[], { atsType: string }>({
      queryFn: async ({ atsType }) => {
        try {
          const { apiApi } = await getOpenApiClients({});
          const response = await apiApi.listAtsSources({ limit: 1000, ordering: "name", atsType });
          return { data: response.results };
        } catch (error) {
          showErrorToast("Failed to load ats sources. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: result => (result ? [{ type: JOB_ATS_SOURCES, id: LIST_TAG }] : []),
    }),
    syncAtsSources: build.mutation<SyncAtsSourcesResponse, { jobCandidateSourceSetting: JobCandidateSourceSetting }>({
      queryFn: async ({ jobCandidateSourceSetting }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});
          const response = await client.syncAtsSources({ id: jobCandidateSourceSetting.id!, data: {} });

          return { data: response };
        } catch (error) {
          showErrorToast("Failed to sync ats sources. Please refresh and try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: result => {
        return result ? [{ type: JOB_ATS_SOURCES, id: LIST_TAG }] : [];
      },
    }),
  }),
});

export const {
  useGetJobCandidateSourcesQuery,
  useGetJobCandidateSourceSettingsQuery,
  useGetCandidateSourceStatsQuery,
  useSubmitJobCandidateSourceFormMutation,
  useListJobAtsSourceSettingsQuery,
  useUpsertJobAtsSourceSettingsMutation,
  useLoadAtsSourcesQuery,
  useSyncAtsSourcesMutation,
} = jobSourceSettingsEndpoints;
