/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChildSearchTransformRelationship,
    ChildSearchTransformRelationshipFromJSON,
    ChildSearchTransformRelationshipFromJSONTyped,
    ChildSearchTransformRelationshipToJSON,
    SlimClient,
    SlimClientFromJSON,
    SlimClientFromJSONTyped,
    SlimClientToJSON,
    SlimJob,
    SlimJobFromJSON,
    SlimJobFromJSONTyped,
    SlimJobToJSON,
    SlimSearchStats,
    SlimSearchStatsFromJSON,
    SlimSearchStatsFromJSONTyped,
    SlimSearchStatsToJSON,
    SlimSearchTemplate,
    SlimSearchTemplateFromJSON,
    SlimSearchTemplateFromJSONTyped,
    SlimSearchTemplateToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminSearch
 */
export interface AdminSearch {
    /**
     * 
     * @type {string}
     * @memberof AdminSearch
     */
    readonly id?: string;
    /**
     * 
     * @type {SlimJob}
     * @memberof AdminSearch
     */
    job?: SlimJob;
    /**
     * 
     * @type {SlimClient}
     * @memberof AdminSearch
     */
    client?: SlimClient;
    /**
     * 
     * @type {SlimSearchTemplate}
     * @memberof AdminSearch
     */
    originalSearchTemplate?: SlimSearchTemplate;
    /**
     * 
     * @type {SlimSearchStats}
     * @memberof AdminSearch
     */
    searchStats?: SlimSearchStats;
    /**
     * 
     * @type {Array<ChildSearchTransformRelationship>}
     * @memberof AdminSearch
     */
    readonly childSearchTransforms?: Array<ChildSearchTransformRelationship>;
    /**
     * 
     * @type {string}
     * @memberof AdminSearch
     */
    readonly activeName?: string;
    /**
     * 
     * @type {object}
     * @memberof AdminSearch
     */
    readonly counts?: object;
    /**
     * 
     * @type {number}
     * @memberof AdminSearch
     */
    readonly searchVersion?: number;
    /**
     * 
     * @type {Date}
     * @memberof AdminSearch
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminSearch
     */
    readonly modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminSearch
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSearch
     */
    internalKey: string;
    /**
     * Please use Search Builder to update this flag.
     * @type {boolean}
     * @memberof AdminSearch
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminSearch
     */
    searchType?: AdminSearchSearchTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSearch
     */
    useSearchV2?: boolean | null;
    /**
     * 
     * @type {object}
     * @memberof AdminSearch
     */
    searchV2Params?: object | null;
    /**
     * 
     * @type {Date}
     * @memberof AdminSearch
     */
    lastEdited?: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum AdminSearchSearchTypeEnum {
    UserDefined = 'USER_DEFINED',
    Outbound = 'OUTBOUND',
    Inbound = 'INBOUND',
    Referrals = 'REFERRALS',
    Dtn = 'DTN'
}

export function AdminSearchFromJSON(json: any): AdminSearch {
    return AdminSearchFromJSONTyped(json, false);
}

export function AdminSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'job': !exists(json, 'job') ? undefined : SlimJobFromJSON(json['job']),
        'client': !exists(json, 'client') ? undefined : SlimClientFromJSON(json['client']),
        'originalSearchTemplate': !exists(json, 'original_search_template') ? undefined : SlimSearchTemplateFromJSON(json['original_search_template']),
        'searchStats': !exists(json, 'search_stats') ? undefined : SlimSearchStatsFromJSON(json['search_stats']),
        'childSearchTransforms': !exists(json, 'child_search_transforms') ? undefined : ((json['child_search_transforms'] as Array<any>).map(ChildSearchTransformRelationshipFromJSON)),
        'activeName': !exists(json, 'active_name') ? undefined : json['active_name'],
        'counts': !exists(json, 'counts') ? undefined : json['counts'],
        'searchVersion': !exists(json, 'search_version') ? undefined : json['search_version'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'name': json['name'],
        'internalKey': json['internal_key'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'searchType': !exists(json, 'search_type') ? undefined : json['search_type'],
        'useSearchV2': !exists(json, 'use_search_v2') ? undefined : json['use_search_v2'],
        'searchV2Params': !exists(json, 'search_v2_params') ? undefined : json['search_v2_params'],
        'lastEdited': !exists(json, 'last_edited') ? undefined : (json['last_edited'] === null ? null : new Date(json['last_edited'])),
    };
}

export function AdminSearchToJSON(value?: AdminSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': SlimJobToJSON(value.job),
        'client': SlimClientToJSON(value.client),
        'original_search_template': SlimSearchTemplateToJSON(value.originalSearchTemplate),
        'search_stats': SlimSearchStatsToJSON(value.searchStats),
        'name': value.name,
        'internal_key': value.internalKey,
        'active': value.active,
        'search_type': value.searchType,
        'use_search_v2': value.useSearchV2,
        'search_v2_params': value.searchV2Params,
        'last_edited': value.lastEdited === undefined ? undefined : (value.lastEdited === null ? null : value.lastEdited.toISOString()),
    };
}


