/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlimGmailAuthUser,
    SlimGmailAuthUserFromJSON,
    SlimGmailAuthUserFromJSONTyped,
    SlimGmailAuthUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface GmailAuth
 */
export interface GmailAuth {
    /**
     * 
     * @type {boolean}
     * @memberof GmailAuth
     */
    valid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GmailAuth
     */
    nylasAuthValid: boolean;
    /**
     * 
     * @type {string}
     * @memberof GmailAuth
     */
    email: string | null;
    /**
     * 
     * @type {SlimGmailAuthUser}
     * @memberof GmailAuth
     */
    user: SlimGmailAuthUser;
    /**
     * 
     * @type {string}
     * @memberof GmailAuth
     */
    emailAliasId: string | null;
}

export function GmailAuthFromJSON(json: any): GmailAuth {
    return GmailAuthFromJSONTyped(json, false);
}

export function GmailAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): GmailAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': json['valid'],
        'nylasAuthValid': json['nylas_auth_valid'],
        'email': json['email'],
        'user': SlimGmailAuthUserFromJSON(json['user']),
        'emailAliasId': json['email_alias_id'],
    };
}

export function GmailAuthToJSON(value?: GmailAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': value.valid,
        'nylas_auth_valid': value.nylasAuthValid,
        'email': value.email,
        'user': SlimGmailAuthUserToJSON(value.user),
        'email_alias_id': value.emailAliasId,
    };
}


