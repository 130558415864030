import { TextField } from "@mui/material";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { B2_doNotUse, B3_doNotUse } from "styles/typography";

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiOutlinedInput-root.MuiInputBase-root {
    outline: none;
    border: none;
  }

  .MuiInputBase-input {
    padding: 10px;
  }
`;

export const StyledSVG = styled(ReactSVG)`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const FakeLink = styled(B2_doNotUse)`
  cursor: pointer;
`;

export const B3margin = styled(B3_doNotUse)`
  margin: 0px 10px;
`;
