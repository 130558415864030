import { zodResolver } from "@hookform/resolvers/zod";
import { Backdrop, Box, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { ReactComponent as AISparklesSVG } from "assets/icons/ai-sparkles.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import EditCareersPageForm from "components/dover/EditCareersPage/EditCareersPageForm";
import {
  EditCareersPageSchemaFormType,
  EditCareersPageSchema,
  GENERATE_CAREERS_PAGE_CACHE_KEY,
} from "components/dover/EditCareersPage/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, ButtonText, PageTitle } from "components/library/typography";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import {
  useGenerateCareersPageMutation,
  useUpdateClientOnboardingMutation,
} from "services/doverapi/endpoints/company-setup/endpoints";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { ClientOnboarding } from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import { MobilePreviewToggle } from "views/create-job/AtsSetupFlow/components/MobilePreviewToggle";
import {
  CareersPageCustomizationButtonContainer,
  CareersPagePreviewContainer,
  PreviewContainer,
} from "views/create-job/styles";
import { OnboardingFormProps } from "views/create-job/types";
import CareersPage from "views/inboundExternal/CareersPage";

const CareersPagePreview = (): React.ReactElement => {
  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));
  const clientId = useGetClientId();

  return (
    <PreviewContainer spacing={2} alignItems="flex-start">
      {!smallScreen && <PageTitle>Preview</PageTitle>}
      <CareersPagePreviewContainer>
        <Box style={{ pointerEvents: "none" }}>
          <CareersPage previewClientId={clientId} />
        </Box>
      </CareersPagePreviewContainer>
    </PreviewContainer>
  );
};

export const EditCareersPageWrapper = ({ onSaveAndNext, isLoading }: OnboardingFormProps): React.ReactElement => {
  const { data: clientOnboarding, isFetching: clientOnboardingDataIsLoading } = useGetClientOnboardingQuery();

  if (clientOnboardingDataIsLoading) {
    return (
      <Stack width={"100%"}>
        <Skeleton height="100px" width={"100%"} />
        <Skeleton height="100px" width={"100%"} />
        <Skeleton height="100px" width={"100%"} />
      </Stack>
    );
  }

  if (!clientOnboarding) {
    return <></>;
  }

  return <EditCareersPage onSaveAndNext={onSaveAndNext} isLoading={isLoading} clientOnboarding={clientOnboarding} />;
};

const EditCareersPage = ({
  onSaveAndNext,
  isLoading: isLoadingProp,
  clientOnboarding,
}: OnboardingFormProps & { clientOnboarding: ClientOnboarding }): React.ReactElement => {
  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));
  // Load data
  const clientId = useGetClientId();

  const [, { isSuccess }] = useGenerateCareersPageMutation({ fixedCacheKey: GENERATE_CAREERS_PAGE_CACHE_KEY });
  const [showSuccessScreen, setShowSuccessScreen] = React.useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      setShowSuccessScreen(true);
    }
  }, [isSuccess]);

  // Mutations
  const [
    updateClientOnboardingMutation,
    { isLoading: isCompanyOnboardingUpdating, isError: isClientOnboardingUpdateError },
  ] = useUpdateClientOnboardingMutation();

  // RHF setup
  const formMethods = useForm<EditCareersPageSchemaFormType>({
    resolver: zodResolver(EditCareersPageSchema),
    defaultValues: {
      image: clientOnboarding.careersPageImageUrl ?? undefined,
      companyInfo: clientOnboarding.careersPageInfo ?? "",
      logo: clientOnboarding.logoUrl ?? undefined,
    },
  });

  const { handleSubmit } = formMethods;
  const hasCompanyInfo = !!formMethods.getValues("companyInfo")?.length;

  // Local state
  const [mobilePreviewModeOn, setMobilePreviewModeOn] = React.useState(false);

  // callbacks
  const saveChanges = React.useCallback(() => {
    if (!clientId) {
      console.error("No client id found");
      return;
    }

    const { companyInfo, logo, image } = formMethods.getValues();
    return updateClientOnboardingMutation({
      id: clientId,
      data: {
        careersPageInfo: companyInfo || "<p></p>",
      },
      logoUrl: logo,
      careersPageImageUrl: typeof image !== "string" ? image : undefined,
      onboardingSection: OnboardingSection.CAREERS_PAGE,
    });
  }, [clientId, formMethods, updateClientOnboardingMutation]);

  const onNext = React.useCallback(() => {
    saveChanges()
      ?.unwrap()
      .then(onSaveAndNext);
  }, [onSaveAndNext, saveChanges]);

  useEffect(() => {
    if (isClientOnboardingUpdateError) {
      showErrorToast("Failed to save careers page, please try again.");
    }
  }, [isClientOnboardingUpdateError]);

  return (
    <FormProvider {...formMethods}>
      <Backdrop
        open={showSuccessScreen}
        onClick={(): void => setShowSuccessScreen(false)}
        sx={{
          zIndex: 1000,
          display: "flex",
          "&.MuiBackdrop-root": {
            backgroundColor: "rgba(255, 255, 255, 0.91)",
          },
        }}
      >
        <Stack
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ textAlign: "center" }}
        >
          <div>
            <AISparklesSVG width="32px" height="32px" />
            <Body weight="700">{"Success!"}</Body>
          </div>
          <Body>We used AI to populate your page using your company website.</Body>
          <Body>🔎 Make sure you review the content! You can always edit this later.</Body>
          <Button variant={ButtonVariant.Primary} onClick={(): void => setShowSuccessScreen(false)}>
            <BodySmall color={colors.white} weight="500">
              Check it out
            </BodySmall>
          </Button>
        </Stack>
      </Backdrop>
      <Stack
        boxSizing="border-box"
        direction="row"
        height="100%"
        maxHeight="calc(100vh - 50px)"
        width="1600px"
        maxWidth="100%"
        overflow="hidden"
        justifyContent="center"
        mt="0px !important"
      >
        <Box flexGrow={1} position="relative" width="45%" sx={{ backgroundColor: "white", overflow: "hidden" }}>
          <Stack spacing={2} flexGrow={1} height="calc(100% - 60px)" py={2} px={3} overflow="auto">
            <Box>
              <PageTitle>Customize your careers page</PageTitle>
              <Body color={colors.grayscale.gray600}>You can make changes later</Body>
            </Box>
            {smallScreen && (
              <MobilePreviewToggle previewModeOn={mobilePreviewModeOn} setPreviewModeOn={setMobilePreviewModeOn} />
            )}
            {!hasCompanyInfo && (
              <Banner variant={BannerVariant.Warning}>
                {"Oh no! We weren't able to gather enough information. Please add any relevant information."}
              </Banner>
            )}
            {smallScreen && mobilePreviewModeOn ? <CareersPagePreview /> : <EditCareersPageForm inOnboardingFlow />}
          </Stack>
          <CareersPageCustomizationButtonContainer spacing={2} direction="row" justifyContent="flex-end">
            <Button variant={ButtonVariant.Secondary} onClick={saveChanges}>
              Preview changes
            </Button>
            <Button
              variant={ButtonVariant.Primary}
              onClick={handleSubmit(onNext)}
              loading={isCompanyOnboardingUpdating}
              disabled={isLoadingProp}
            >
              <Stack spacing={0.5} direction="row" alignItems="center">
                <ButtonText color={colors.white}>Next</ButtonText>
                <ArrowRight className="svg-color" color={colors.white} />
              </Stack>
            </Button>
          </CareersPageCustomizationButtonContainer>
        </Box>
        {!smallScreen && <CareersPagePreview />}
      </Stack>
    </FormProvider>
  );
};
