import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { Box, Menu } from "@mui/material";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { TextButton } from "components/Button";
import { Button } from "components/library/Button";
import { colors, theme } from "styles/theme";

interface OverflowProps {
  $isOverflow: boolean;
  $isPinned?: boolean;
}

export const NoteContainer = styled.div<OverflowProps>`
    font-family: Inter;
    border: 1px solid ${colors.grayscale.gray200};
    border-radius: 6px;
    display: flex;
    margin-bottom: 16px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
    padding: 15px 15px 10px 15px;
    cursor ${(props): string => (props.$isOverflow ? "pointer" : "default")};
    background-color ${(props): string => (props.$isPinned ? theme.colors.warning.light : colors.white)};
  `;

export const SaveButton = styled(Button)`
  width: 120px;
`;

export const CancelButton = styled(Button)`
  width: 80px;
`;

export const StyledBox = styled(Box)`
  width: 100%;
`;

export const NoteContent = styled.div`
  flex: 1 0 auto;
  width: calc(100% - 40px);
`;

export const NoteTitle = styled.div`
  align-items: center;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
`;

export const NoteIcon = styled(ReactSVG)`
  height: 50px;
  width: 50px;
`;

export const RotatedPushPinSVG = styled(PushPinOutlinedIcon)`
  transform: rotate(-45deg);
  height: 28px;
  width: 28px;
`;

export const RotatedPushPinSVGWrapper = styled.div`
  height: 50px;
  width: 50px;
  display: block;
`;

export const TimestampText = styled.div`
  font-size: 14px;
  color: ${colors.grayscale.gray400};
  font-family: ${theme.fontFamily};
`;

export const DropdownToggle = styled(TextButton)`
  &.MuiButton-root {
    min-width: 35px;
    padding: 3px;
    float: right;
  }
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    padding: 0;
  }
`;

export const ConfirmationMenu = styled(Menu)`
  .MuiList-root {
    padding: 16px;
    background-color: ${colors.grayscale.gray100};
  }
`;
