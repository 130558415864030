import { EMPTY_INTERVIEWER } from "components/dover/top-level-modal-manager/constants";
import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { InterviewPanel } from "services/openapi";

export const isInterviewerEmpty = (interviewer: Interviewer): boolean =>
  interviewer.label === EMPTY_INTERVIEWER.label && interviewer.value === EMPTY_INTERVIEWER.value;

export const interviewPanelValid = (interviewPanel?: InterviewPanel): boolean => {
  if (!interviewPanel) {
    return false;
  }

  for (const substage of interviewPanel.substages) {
    if (substage.possibleInterviewers.length < 1) {
      return false;
    }

    if (!substage.name) {
      return false;
    }

    if (!substage.duration) {
      return false;
    }
  }

  return true;
};
