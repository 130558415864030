import { skipToken } from "@reduxjs/toolkit/dist/query";

/**
 * Returns the skipToken if the condition is true, otherwise returns the args
 *
 * @param args Arguments object for an RTKQ endpoint
 * @param condition Boolean condition to determine whether to skip the endpoint
 * @returns skipToken if condition is true, otherwise returns args
 */
export const argsOrSkip = <T>(args: T, condition: boolean): T | typeof skipToken => (condition ? skipToken : args);
