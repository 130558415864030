import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { applicationReviewEndpoints } from "services/doverapi/endpoints/applicationReview";
import { SAAP_REVIEW_SCORED_APPLICATION_LIST } from "services/doverapi/endpointTagsConstants";
import {
  ApiApiListApplicationsViaAIRequest,
  ApplicationPortalInboundApplication,
  ListSaapReviewApplicationRequest,
} from "services/openapi";
import { InboundApplicationResponse } from "services/openapi/models/InboundApplicationResponse";

const inboundApplicationEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    inboundApplicationApi: build.query<InboundApplicationResponse, string>({
      queryFn: async inbound_application_id => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.apiV1InboundApplicationRead({ id: inbound_application_id });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    updateApplicationBookmark: build.mutation<
      ApplicationPortalInboundApplication,
      {
        args: { id: string; bookmarked: boolean }; // We do a more custom route here, instead of generic partial update route, because the optimistic update logic needed is very specific
        toNextApplication?: () => void; // Used to move to next application in app review
        listSaapReviewApplicationArgs?: ListSaapReviewApplicationRequest; // Used for optimistic update of list app routes for app review
        listApplicationsViaAiArgs?: ApiApiListApplicationsViaAIRequest; // Used for optimistic update of list app routes via ai for app review
      }
    >({
      queryFn: async ({ args }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.partialUpdateInboundApplication(args);
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      async onQueryStarted(
        { args, toNextApplication, listSaapReviewApplicationArgs, listApplicationsViaAiArgs },
        { dispatch, queryFulfilled }
      ) {
        // If toNextApplication is provided it means the saap route is the one being used
        // This route has bookmark filtering based on the tab selected, so when we bookmark a candidate
        // We need to remove them from the cache, move to the next app, and update the counts of both the bookmarked tab and the unreviewed tab
        // And then invalidate the list for the tab that ISNT currently selected so that it will refetch
        // If toNextApplication is not provided, it means the ai chat route is being used
        // This route does not have bookmark filtering, so we just need to update the bookmarked status in place
        if (toNextApplication && listSaapReviewApplicationArgs) {
          toNextApplication();

          // Run the optimistic update for the listSaapReviewApplications route if input is provided
          const patchListSaapReviewApplications = dispatch(
            applicationReviewEndpoints.util.updateQueryData(
              "listSaapReviewApplications",
              listSaapReviewApplicationArgs,
              draft => {
                // Look for the app in the list
                const index = draft?.applications.findIndex(app => app.inboundAppId === args.id);

                // If app was found, update the cache
                if (index > -1) {
                  // Remove the actioned app, and decrement the total count
                  draft.applications.splice(index, 1);
                  draft.totalCount--;
                }
              }
            )
          );

          // For the route that isn't selected we just want to update the total count to update the chip in the tab
          const listSaapReviewApplicationArgsInverseBookmark = {
            ...listSaapReviewApplicationArgs,
            bookmarkedFilter: !listSaapReviewApplicationArgs.bookmarkedFilter,
          };

          const patchListSaapReviewApplicationsInverseBookmark = dispatch(
            applicationReviewEndpoints.util.updateQueryData(
              "listSaapReviewApplications",
              listSaapReviewApplicationArgsInverseBookmark,
              draft => {
                // Increase the total count
                draft.totalCount++;
              }
            )
          );

          // Now we want to invalidate the other list so that it refetches and adds the new applicant to its list
          dispatch(
            applicationReviewEndpoints.util.invalidateTags([
              { type: SAAP_REVIEW_SCORED_APPLICATION_LIST, id: args.bookmarked?.toString() },
            ])
          );

          try {
            // Attempt to submit application review decision
            await queryFulfilled;
          } catch {
            // If the call failed, undo the optimistic updates
            patchListSaapReviewApplications?.undo();
            patchListSaapReviewApplicationsInverseBookmark?.undo();
          }
        } else if (listApplicationsViaAiArgs) {
          // If we are using the ai chat, we want to update the bookmarked status in place
          const patchListApplicationsViaAi = dispatch(
            applicationReviewEndpoints.util.updateQueryData(
              "listApplicationsViaAI",
              listApplicationsViaAiArgs,
              draft => {
                // Look for the app in the list
                const index = draft?.results.applications.findIndex(app => app.inboundAppId === args.id);

                // If app was found, update the cache
                if (index > -1) {
                  // Update the bookmarked status
                  draft.results.applications[index].bookmarked = args.bookmarked;
                }
              }
            )
          );

          try {
            // Attempt to submit application review decision
            await queryFulfilled;
          } catch {
            // If the call failed, undo the optimistic updates
            patchListApplicationsViaAi?.undo();
          }
        }
      },
    }),
  }),
});

export const { useInboundApplicationApiQuery, useUpdateApplicationBookmarkMutation } = inboundApplicationEndpoints;
