import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { jobEndpoints as doverApi } from "services/doverapi/endpoints/job/endpoints";
import {
  CAMPAIGN_MESSAGE_REQUEST,
  HIRING_PIPELINE_STAGE,
  JOB,
  JOB_FEATURE_SETTINGS,
  JOB_SETUP,
  LIST_TAG,
} from "services/doverapi/endpointTagsConstants";
import {
  getJobFeaturesWithBulkUpdatedStates,
  getJobFeaturesWithUpdatedStates,
  getJobSetupStepsWithBulkUpdatedRelevancy,
  getJobSetupStepsWithUpdatedRelevancy,
} from "services/doverapi/mutationUtils";
import {
  ApiApiBulkUpsertJobFeatureSettingsRequest,
  ApiApiUpsertJobFeatureSettingRequest,
  JobFeatures,
  JobFeatureSetting,
  JobFeatureSettingFeatureNameEnum,
} from "services/openapi";
import {
  BulkUpsertJobFeatureSetting,
  BulkUpsertJobFeatureSettingFeatureNameEnum,
} from "services/openapi/models/BulkUpsertJobFeatureSetting";
import { showErrorToast } from "utils/showToast";

const jobFeatureSettingsEndpointsTemp = doverApi.injectEndpoints({
  endpoints: build => ({
    getJobFeatures: build.query<JobFeatures, { jobId: string; showToastOnError?: boolean }>({
      queryFn: async ({ jobId, showToastOnError = true }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let data: JobFeatures;
        try {
          data = await client.getJobFeatures({ id: jobId });
        } catch (error) {
          const userFacingMessage = "Failed to load job features. Please refresh and try again.";
          if (showToastOnError) {
            showErrorToast(userFacingMessage);
          }
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data };
      },
      providesTags: (result, error, { jobId }) => {
        return result ? [{ type: JOB_FEATURE_SETTINGS, id: jobId } as const] : [];
      },
    }),
  }),
});

// We must inject this endpoint separately
// Because the optimistic update requires knowledge of the getJobSetupSteps route
const jobFeatureSettingsEndpoints = jobFeatureSettingsEndpointsTemp.injectEndpoints({
  endpoints: build => ({
    upsertJobFeatureSetting: build.mutation<JobFeatureSetting, { upsertData: ApiApiUpsertJobFeatureSettingRequest }>({
      queryFn: async ({ upsertData }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const { data } = upsertData;
        let updateJobFeatureSetting: JobFeatureSetting;
        try {
          updateJobFeatureSetting = await client.upsertJobFeatureSetting({
            data,
          });
        } catch (error) {
          const userFacingMessage = "Failed to create or update job feature setting. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data: updateJobFeatureSetting };
      },
      /**
       * This code allows "optimistic updates"
       * The idea is that whenever we update the job feature settings,
       * we don't want to have to wait for an API response to update job setup steps
       * Because we already have all the information we need,
       * we can go ahead and make the update to the job setup steps cache in real time
       */
      async onQueryStarted(
        { upsertData: { data: jobFeatureSettingToUpdate } },
        { dispatch, queryFulfilled, getState }
      ) {
        const { data: currentJobFeatureSettings } = jobFeatureSettingsEndpoints.endpoints.getJobFeatures.select({
          jobId: jobFeatureSettingToUpdate.job,
        })(getState());
        // Go ahead and immediately update the job features cache
        const featureStatesPatchResult = dispatch(
          jobFeatureSettingsEndpointsTemp.util.updateQueryData(
            "getJobFeatures",
            { jobId: jobFeatureSettingToUpdate.job },
            draft => {
              Object.assign(draft, getJobFeaturesWithUpdatedStates(draft, jobFeatureSettingToUpdate));
            }
          )
        );

        // And immediately update the job setup steps cache
        const setupStepsPatchResult = dispatch(
          jobFeatureSettingsEndpointsTemp.util.updateQueryData(
            "getJobSetupSteps",
            jobFeatureSettingToUpdate.job,
            draft => {
              Object.assign(
                draft,
                getJobSetupStepsWithUpdatedRelevancy(draft, currentJobFeatureSettings, jobFeatureSettingToUpdate)
              );
            }
          )
        );

        try {
          // Now attempt to upsert the job feature setting
          await queryFulfilled;
        } catch {
          // If the upsert call failed, we also want to undo the optimistic updates
          setupStepsPatchResult.undo();
          featureStatesPatchResult.undo();
        }
      },
      invalidatesTags: result => {
        if (!result) {
          return [];
        }

        const cacheTagsToInvalidate: Array<{
          type: "jobFeatureSettings" | "jobSetup" | "job" | "campaignMessageRequest" | "hiringPipelineStage";
          id?: string;
        }> = [{ type: JOB_FEATURE_SETTINGS, id: result.job.id }];

        if (
          // Turning on/off any of these features should invalidate the cache fo our job and job setup apis, because they trigger
          /// side-effects that could change the response data of job and job setup.
          [
            JobFeatureSettingFeatureNameEnum.E2EScheduling,
            JobFeatureSettingFeatureNameEnum.DoverInterviewer,
            JobFeatureSettingFeatureNameEnum.ManagedOutbound,
          ].includes(result.featureName)
        ) {
          if (result.featureName === JobFeatureSettingFeatureNameEnum.ManagedOutbound) {
            cacheTagsToInvalidate.push({
              type: CAMPAIGN_MESSAGE_REQUEST,
              id: result.job.id,
            });
          }
          cacheTagsToInvalidate.push({ type: JOB_SETUP, id: result.job.id });
          cacheTagsToInvalidate.push({ type: JOB, id: result.job.id });
        }
        if (result.featureName === JobFeatureSettingFeatureNameEnum.DoverInterviewer) {
          cacheTagsToInvalidate.push({
            type: HIRING_PIPELINE_STAGE,
            id: LIST_TAG,
          } as const);
        }

        return cacheTagsToInvalidate;
      },
    }),
    bulkUpsertJobFeatureSetting: build.mutation<
      BulkUpsertJobFeatureSetting[],
      ApiApiBulkUpsertJobFeatureSettingsRequest
    >({
      queryFn: async ({ id, data }) => {
        const { apiApi: client } = await getOpenApiClients({});
        let updateJobFeatureSettings: BulkUpsertJobFeatureSetting[];
        try {
          updateJobFeatureSettings = await client.bulkUpsertJobFeatureSettings({ id, data });
        } catch (error) {
          const userFacingMessage = "Failed to create or update job feature settings. Please refresh and try again.";
          showErrorToast(userFacingMessage);
          return {
            error: {
              serializedError: error as SerializedError,
              userFacingMessage,
            },
          };
        }
        return { data: updateJobFeatureSettings };
      },
      /**
       * This code allows "optimistic updates"
       * The idea is that whenever we update the job feature settings,
       * we don't want to have to wait for an API response to update job setup steps
       * Because we already have all the information we need,
       * we can go ahead and make the update to the job setup steps cache in real time
       */
      async onQueryStarted({ data: jobFeatureSettingsToUpdate, id: jobId }, { dispatch, queryFulfilled, getState }) {
        const { data: currentJobFeatureSettings } = jobFeatureSettingsEndpoints.endpoints.getJobFeatures.select({
          jobId,
        })(getState());
        // Go ahead and immediately update the job features cache
        const featureStatesPatchResult = dispatch(
          jobFeatureSettingsEndpointsTemp.util.updateQueryData("getJobFeatures", { jobId }, draft => {
            Object.assign(draft, getJobFeaturesWithBulkUpdatedStates(draft, jobFeatureSettingsToUpdate, jobId));
          })
        );

        // And immediately update the job setup steps cache
        const setupStepsPatchResult = dispatch(
          jobFeatureSettingsEndpointsTemp.util.updateQueryData("getJobSetupSteps", jobId, draft => {
            Object.assign(
              draft,
              getJobSetupStepsWithBulkUpdatedRelevancy(
                draft,
                currentJobFeatureSettings,
                jobFeatureSettingsToUpdate,
                jobId
              )
            );
          })
        );

        try {
          // Now attempt to upsert the job feature setting
          await queryFulfilled;
        } catch {
          // If the upsert call failed, we also want to undo the optimistic updates
          setupStepsPatchResult.undo();
          featureStatesPatchResult.undo();
        }
      },
      invalidatesTags: (result, error, { data, id }) => {
        if (!result) {
          return [];
        }

        const cacheTagsToInvalidate: Array<{
          type: "jobFeatureSettings" | "jobSetup" | "job";
          id?: string;
        }> = [{ type: JOB_FEATURE_SETTINGS, id }];

        data.forEach(datum => {
          if (
            // Turning on/off any of these features should invalidate the cache fo our job and job setup apis, because they trigger
            /// side-effects that could change the response data of job and job setup.
            [
              BulkUpsertJobFeatureSettingFeatureNameEnum.E2EScheduling,
              BulkUpsertJobFeatureSettingFeatureNameEnum.DoverInterviewer,
              BulkUpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
            ].includes(datum.featureName)
          ) {
            cacheTagsToInvalidate.push({ type: JOB_SETUP, id });
            cacheTagsToInvalidate.push({ type: JOB, id });
          }
        });
        return cacheTagsToInvalidate;
      },
    }),
  }),
});

export const {
  useGetJobFeaturesQuery,
  useBulkUpsertJobFeatureSettingMutation,
  useUpsertJobFeatureSettingMutation,
} = jobFeatureSettingsEndpoints;
