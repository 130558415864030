import { Autocomplete, Box, Stack, TextField as MUITextField } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import matchSorter from "match-sorter";
import React, { useEffect } from "react";
import { useDebounce } from "react-use";
import styled from "styled-components";

import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { TextField } from "components/library/TextField";
import { Body, BodyExtraSmall, BodySmall, Subtitle1 } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import {
  useGetPersonaViaSoftMatchQuery,
  useListJobTitlesQuery,
} from "services/doverapi/endpoints/create-job/endpoints";
import { JobSetupTitlePattern } from "services/openapi";
import { colors } from "styles/theme";

const CUSTOM_PERSONA_ID = 168;
interface AutocompleteProps {
  disabled?: boolean;
}

export const StyledAutocompleteWrapper = styled(Box)<AutocompleteProps>`
  .MuiAutocomplete-noOptions {
    cursor: pointer !important;
    :hover {
      background-color: #f5f5f5;
    }
  }

  fieldset {
    display: none;

    legend {
      display: none;
    }
  }

  flex-grow: 1;
  border: 1px solid ${colors.grayscale.gray300};
  border-radius: 4px;

  :hover {
    border-color: ${(props): string => {
      return props.className == "error"
        ? colors.critical.base
        : props.disabled
        ? colors.grayscale.gray300
        : colors.grayscale.gray700;
    }};
  }

  :focus-within {
    border-color: ${(props): string => {
      return props.className == "error" ? colors.critical.base : `rgb(25, 118, 210)`;
    }};
    border-width: 2px;
  }
  border-color: ${(props): string => {
    return props.className == "error" ? colors.critical.base : colors.grayscale.gray300;
  }};

  ul {
    max-height: 180px;
  }

  .MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
    padding-right: 32px !important;
  }

  .MuiAutocomplete-root .MuiOutlinedInput-root {
    max-height: 250px;
    overflow: auto;
  }

  .MuiInputBase-root {
    background-color: white;
    font-family: Inter;
  }
`;

interface TitlesAutocompleteProps {
  errorText?: string;
  helpText?: string;
  showStrategyCallLink?: boolean;
  placeholderText?: string | undefined;
  hideLabel?: boolean;
  hideHelpText?: boolean;
  disabled?: boolean;
  title?: string;
  setTitle: (value: string | undefined) => void;
  setPersonaId?: (value: number | undefined) => void;
}

export const TitlesAutocomplete = React.memo(
  ({
    helpText,
    showStrategyCallLink,
    errorText,
    placeholderText,
    hideLabel,
    hideHelpText,
    disabled,
    title,
    setTitle,
    setPersonaId,
  }: TitlesAutocompleteProps): React.ReactElement => {
    const disableAutocomplete = useFeatureFlag(FeatureFlag.DisableTitlesAutocomplete);
    const calendlyUrl = useGetCalendlyUrl("app", "create_job", "not_sure_what_position");

    const { data: allTitles, isLoading: loading } = useListJobTitlesQuery();

    const [selectedTitle, setSelectedTitle] = React.useState<JobSetupTitlePattern | null | undefined>(null);
    const [customTitleInput, setCustomTitleInput] = React.useState<string>("");
    const [debouncedTitle, setDebouncedTitle] = React.useState<string>("");
    useDebounce(
      () => {
        if (disableAutocomplete) {
          setDebouncedTitle(customTitleInput);
          setTitle(customTitleInput);
        }
      },
      500,
      [customTitleInput]
    );

    const customTitle = React.useMemo(() => {
      return {
        titlePattern: customTitleInput,
        searchTemplateId: CUSTOM_PERSONA_ID,
        jobCategoryDisplay: "Custom",
      };
    }, [customTitleInput]);

    const titleOptions: JobSetupTitlePattern[] = React.useMemo(() => {
      if (allTitles) {
        return [...allTitles, customTitle];
      }

      return [];
    }, [customTitle, allTitles]);

    const { currentData: softMatchPersona } = useGetPersonaViaSoftMatchQuery(
      disableAutocomplete ? (debouncedTitle ? debouncedTitle : skipToken) : title ?? skipToken
    );

    useEffect(() => {
      if (!setPersonaId) {
        return;
      }

      // set to custom first, and then to soft match persona if it exists
      setPersonaId(CUSTOM_PERSONA_ID);
      if (softMatchPersona) {
        setPersonaId(softMatchPersona.id);
      }
    }, [softMatchPersona, setPersonaId]);

    if (disableAutocomplete) {
      return (
        <Stack spacing={1.5}>
          <BodySmall weight="600">Position Title</BodySmall>
          <TextField
            text={customTitleInput}
            onTextUpdated={(updatedText: string): void => {
              setCustomTitleInput(updatedText);
            }}
          />
        </Stack>
      );
    }

    return (
      <Stack width={"100%"} spacing={0.5}>
        {!hideLabel && <Subtitle1>{"Position Title"}</Subtitle1>}
        <StyledAutocompleteWrapper>
          <Autocomplete
            size="small"
            options={customTitleInput || title ? titleOptions : []}
            noOptionsText={`Start typing to see suggestions...`}
            loading={loading}
            loadingText="Loading..."
            autoHighlight={true}
            autoSelect={true}
            onChange={(_, value): void => {
              const titlePattern = value as JobSetupTitlePattern;
              setSelectedTitle(titlePattern);
              setTitle(titlePattern?.titlePattern);

              if (setPersonaId && titlePattern?.searchTemplateId !== CUSTOM_PERSONA_ID) {
                setPersonaId(titlePattern?.searchTemplateId);
              }
            }}
            disabled={disabled}
            freeSolo={disabled}
            value={selectedTitle}
            groupBy={(option): string => option.jobCategoryDisplay ?? ""}
            onInputChange={(_, newValue): void => {
              setCustomTitleInput(newValue);
            }}
            isOptionEqualToValue={(option, value): boolean => option.titlePattern === value.titlePattern}
            getOptionLabel={(option): string => {
              return (option as JobSetupTitlePattern).titlePattern;
            }}
            filterOptions={(options, { inputValue }): JobSetupTitlePattern[] => {
              return matchSorter(options, inputValue, { keys: ["titlePattern"] });
            }}
            renderInput={(params): React.ReactElement => {
              return <MUITextField {...params} placeholder={placeholderText ?? "Enter job title here"} />;
            }}
            renderOption={(props, option): React.ReactElement => {
              const optionLabel = option.titlePattern;
              return (
                // @ts-ignore - the mui props don't agree with the li typing
                <li
                  {...props}
                  style={{
                    listStyle: "none",
                    width: "100%",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box display="flex">
                      <Body>{optionLabel}</Body>
                    </Box>
                  </Box>
                </li>
              );
            }}
          />
        </StyledAutocompleteWrapper>
        {!hideHelpText && (
          <BodyExtraSmall color={colors.grayscale.gray600}>
            {helpText ? helpText : "The visible job title to candidates. This is editable in the job settings page."}
          </BodyExtraSmall>
        )}
        {showStrategyCallLink && (
          <BodyExtraSmall color={colors.grayscale.gray600}>
            <a href={calendlyUrl} target="_blank" rel="noreferrer">
              Not sure what position you need to hire?
            </a>
          </BodyExtraSmall>
        )}
        {errorText && <BodySmall color={colors.critical.base}>{errorText}</BodySmall>}
      </Stack>
    );
  }
);
