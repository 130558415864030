import { Button } from "@doverhq/dover-ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import {
  CompanySlugAtom,
  CompanyUrlInputAtom,
  GenerateCareersPageErroredAtom,
} from "components/dover/EditCareersPage/atoms";
import EditCareersPageForm from "components/dover/EditCareersPage/EditCareersPageForm";
import { EditCareersPageSchema, EditCareersPageSchemaFormType } from "components/dover/EditCareersPage/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { BlockNav } from "components/library/BlockNav";
import { Card } from "components/library/Card";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useUpdateClientOnboardingMutation } from "services/doverapi/endpoints/company-setup/endpoints";
import { OnboardingSection } from "services/doverapi/endpoints/company-setup/types";
import { ClientOnboarding } from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";

export const CAREERS_PAGE_SCROLLABLE_CONTAINER_ID = "careers-page-scrollable-container";

interface EditCareersPageFormProps {
  isLoading: boolean;
  clientOnboarding: ClientOnboarding;
}

export const EditCareersPageFormWrapper = ({
  isLoading: isLoadingProp,
  clientOnboarding,
}: EditCareersPageFormProps): React.ReactElement => {
  const companyUrlInputAtom = useAtomValue(CompanyUrlInputAtom);
  const setCompanySlugAtom = useSetAtom(CompanySlugAtom);
  const isErrorGeneratingCareersPage = useAtomValue(GenerateCareersPageErroredAtom);

  // Load data
  const clientId = useGetClientId();
  setCompanySlugAtom(clientOnboarding.slug ?? "");

  // Mutations
  const [
    updateClientOnboardingMutation,
    { isLoading: isCompanyOnboardingUpdating, isError: isClientOnboardingUpdateError },
  ] = useUpdateClientOnboardingMutation();

  // RHF setup
  const formMethods = useForm<EditCareersPageSchemaFormType>({
    resolver: zodResolver(EditCareersPageSchema),
    defaultValues: {
      image: clientOnboarding.careersPageImageUrl ?? undefined,
      companyInfo: clientOnboarding.careersPageInfo ?? undefined,
      logo: clientOnboarding.logoUrl ?? undefined,
      // If careersPageLogoRedirectUrl is not defined, use companyUrlInputAtom to use what was set in the import careers page step
      companyUrl: clientOnboarding.careersPageLogoRedirectUrl ?? companyUrlInputAtom ?? "",
      companySlug: clientOnboarding.slug ?? "",
    },
  });

  const { handleSubmit } = formMethods;

  // callbacks
  const onSave = (): void => {
    if (!clientId) {
      console.error("No client id found");
      return;
    }
    const { companyInfo, logo, image, companyUrl, companySlug } = formMethods.getValues();
    setCompanySlugAtom(companySlug);
    updateClientOnboardingMutation({
      id: clientId,
      data: {
        careersPageInfo: companyInfo,
        careersPageLogoRedirectUrl: companyUrl,
        slug: companySlug,
      },
      logoUrl: logo,
      careersPageImageUrl: typeof image !== "string" ? image : undefined,
      onboardingSection: OnboardingSection.CAREERS_PAGE,
    });
  };

  useEffect(() => {
    if (isClientOnboardingUpdateError) {
      showErrorToast("Error saving your company info. Please refresh and try again.");
    }
  }, [isClientOnboardingUpdateError]);

  return (
    <FormProvider {...formMethods}>
      <BlockNav when={formMethods.formState.isDirty} />
      <Card>
        <Stack
          boxSizing="border-box"
          height="100%"
          maxHeight="calc(100vh - 202px)"
          width="100%"
          overflow="hidden"
          justifyContent="center"
          pb={5}
        >
          <Stack
            spacing={2}
            flexGrow={1}
            height="auto"
            py={2}
            px={3}
            overflow="auto"
            minHeight="0"
            className="hide-scrollbar"
            id={CAREERS_PAGE_SCROLLABLE_CONTAINER_ID}
          >
            {isErrorGeneratingCareersPage && (
              <Banner variant={BannerVariant.Warning}>
                {"Oh no! We weren't able to gather enough information. Please add any relevant information."}
              </Banner>
            )}
            <EditCareersPageForm inOnboardingFlow={false} />
          </Stack>
        </Stack>
        <Stack
          alignItems="flex-end"
          position="absolute"
          width="100%"
          left={0}
          bottom={0}
          px={2}
          py={1}
          sx={{ backgroundColor: colors.white, zIndex: 1 }}
          borderTop={`1px solid ${colors.grayscale.gray200}`}
          borderRadius={"0 0 6px 6px"}
        >
          <Button
            variant="primaryFilled"
            // @ts-ignore
            onPress={handleSubmit(onSave)}
            isLoading={isCompanyOnboardingUpdating}
            isDisabled={isLoadingProp}
          >
            Save
          </Button>
        </Stack>
      </Card>
    </FormProvider>
  );
};
