/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SaapReviewAdditionalFilters,
    SaapReviewAdditionalFiltersFromJSON,
    SaapReviewAdditionalFiltersFromJSONTyped,
    SaapReviewAdditionalFiltersToJSON,
    SearchV3Params,
    SearchV3ParamsFromJSON,
    SearchV3ParamsFromJSONTyped,
    SearchV3ParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListSaapReviewApplicationRequest
 */
export interface ListSaapReviewApplicationRequest {
    /**
     * 
     * @type {SearchV3Params}
     * @memberof ListSaapReviewApplicationRequest
     */
    params?: SearchV3Params;
    /**
     * 
     * @type {SaapReviewAdditionalFilters}
     * @memberof ListSaapReviewApplicationRequest
     */
    additionalFilters?: SaapReviewAdditionalFilters;
    /**
     * 
     * @type {string}
     * @memberof ListSaapReviewApplicationRequest
     */
    searchId: string;
    /**
     * 
     * @type {number}
     * @memberof ListSaapReviewApplicationRequest
     */
    page?: number;
    /**
     * 
     * @type {string}
     * @memberof ListSaapReviewApplicationRequest
     */
    ordering: ListSaapReviewApplicationRequestOrderingEnum;
    /**
     * 
     * @type {number}
     * @memberof ListSaapReviewApplicationRequest
     */
    limit: number;
    /**
     * 
     * @type {string}
     * @memberof ListSaapReviewApplicationRequest
     */
    nameFilterVal?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListSaapReviewApplicationRequest
     */
    bookmarkedFilter?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListSaapReviewApplicationRequest
     */
    includeExplanations?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ListSaapReviewApplicationRequestOrderingEnum {
    Newest = 'newest',
    Oldest = 'oldest',
    Best = 'best'
}

export function ListSaapReviewApplicationRequestFromJSON(json: any): ListSaapReviewApplicationRequest {
    return ListSaapReviewApplicationRequestFromJSONTyped(json, false);
}

export function ListSaapReviewApplicationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSaapReviewApplicationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'params': !exists(json, 'params') ? undefined : SearchV3ParamsFromJSON(json['params']),
        'additionalFilters': !exists(json, 'additional_filters') ? undefined : SaapReviewAdditionalFiltersFromJSON(json['additional_filters']),
        'searchId': json['search_id'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'ordering': json['ordering'],
        'limit': json['limit'],
        'nameFilterVal': !exists(json, 'name_filter_val') ? undefined : json['name_filter_val'],
        'bookmarkedFilter': !exists(json, 'bookmarked_filter') ? undefined : json['bookmarked_filter'],
        'includeExplanations': !exists(json, 'include_explanations') ? undefined : json['include_explanations'],
    };
}

export function ListSaapReviewApplicationRequestToJSON(value?: ListSaapReviewApplicationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'params': SearchV3ParamsToJSON(value.params),
        'additional_filters': SaapReviewAdditionalFiltersToJSON(value.additionalFilters),
        'search_id': value.searchId,
        'page': value.page,
        'ordering': value.ordering,
        'limit': value.limit,
        'name_filter_val': value.nameFilterVal,
        'bookmarked_filter': value.bookmarkedFilter,
        'include_explanations': value.includeExplanations,
    };
}


