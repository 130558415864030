import React from "react";
import { Field, useForm } from "react-final-form";

import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import InterviewLocationField from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/InterviewLocationField";
import {
  MeetingLocationContainer,
  RequiredAsterisk,
  StageDrawerFormLabel,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/styles";

export const EditMeetingLocation = ({
  fieldPrefix,
  required,
}: {
  fieldPrefix: string;
  required: boolean;
}): React.ReactElement => {
  const form = useForm();
  const interviewMeetingTypeValue = form.getFieldState(fieldPrefix + ".interviewMeetingType")?.value;

  return (
    <MeetingLocationContainer>
      <Tooltip
        variant={TooltipVariant.Dark}
        title={
          interviewMeetingTypeValue === "IN_PERSON"
            ? ""
            : "Include a meeting link here or Dover will create a unique link for each candidate."
        }
        placement="top"
      >
        <StageDrawerFormLabel bold display="inline">
          Meeting Link: {required && <RequiredAsterisk>*</RequiredAsterisk>}
        </StageDrawerFormLabel>
      </Tooltip>
      <Field
        name={fieldPrefix + ".interviewLocation"}
        parse={(value): any => value}
        validate={(value): boolean => {
          return required && !value;
        }}
      >
        {(props): React.ReactElement => {
          return (
            <InterviewLocationField
              {...props}
              label={interviewMeetingTypeValue === "IN_PERSON" ? "Address" : "Meeting Link (optional)"}
              hoverText={
                interviewMeetingTypeValue === "IN_PERSON"
                  ? null
                  : "Include a meeting link here or Dover will create a unique link for each candidate."
              }
              required={required}
              hideLabel
            />
          );
        }}
      </Field>
    </MeetingLocationContainer>
  );
};
