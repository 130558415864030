/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminProfileAttribute
 */
export interface AdminProfileAttribute {
    /**
     * 
     * @type {string}
     * @memberof AdminProfileAttribute
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProfileAttribute
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminProfileAttribute
     */
    type: AdminProfileAttributeTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof AdminProfileAttribute
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof AdminProfileAttribute
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminProfileAttribute
     */
    readonly attributeCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof AdminProfileAttribute
     */
    readonly created?: Date;
    /**
     * 
     * @type {object}
     * @memberof AdminProfileAttribute
     */
    searchParams?: object | null;
}

/**
* @export
* @enum {string}
*/
export enum AdminProfileAttributeTypeEnum {
    ElasticsearchQuery = 'ELASTICSEARCH_QUERY'
}

export function AdminProfileAttributeFromJSON(json: any): AdminProfileAttribute {
    return AdminProfileAttributeFromJSONTyped(json, false);
}

export function AdminProfileAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminProfileAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'type': json['type'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'attributeCount': !exists(json, 'attribute_count') ? undefined : json['attribute_count'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'searchParams': !exists(json, 'search_params') ? undefined : json['search_params'],
    };
}

export function AdminProfileAttributeToJSON(value?: AdminProfileAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'data': value.data,
        'description': value.description,
        'search_params': value.searchParams,
    };
}


