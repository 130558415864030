/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateBioCompletedInterview
 */
export interface CandidateBioCompletedInterview {
    /**
     * 
     * @type {string}
     * @memberof CandidateBioCompletedInterview
     */
    readonly hiringPipelineStageId?: string;
}

export function CandidateBioCompletedInterviewFromJSON(json: any): CandidateBioCompletedInterview {
    return CandidateBioCompletedInterviewFromJSONTyped(json, false);
}

export function CandidateBioCompletedInterviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateBioCompletedInterview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hiringPipelineStageId': !exists(json, 'hiring_pipeline_stage_id') ? undefined : json['hiring_pipeline_stage_id'],
    };
}

export function CandidateBioCompletedInterviewToJSON(value?: CandidateBioCompletedInterview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


