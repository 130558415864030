/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AtsCandidateManualSourcing
 */
export interface AtsCandidateManualSourcing {
    /**
     * 
     * @type {string}
     * @memberof AtsCandidateManualSourcing
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AtsCandidateManualSourcing
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AtsCandidateManualSourcing
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AtsCandidateManualSourcing
     */
    job: string | null;
}

export function AtsCandidateManualSourcingFromJSON(json: any): AtsCandidateManualSourcing {
    return AtsCandidateManualSourcingFromJSONTyped(json, false);
}

export function AtsCandidateManualSourcingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtsCandidateManualSourcing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'job': json['job'],
    };
}

export function AtsCandidateManualSourcingToJSON(value?: AtsCandidateManualSourcing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'job': value.job,
    };
}


