import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

import { CompanySetupSectionType } from "App/routing/types";
import CompanySetupBasicInfo from "components/dover/CompanySetupBasicInfo";
import { formSchema, FormSchema } from "components/dover/CompanySetupBasicInfo/types";
import { Card } from "components/library/Card";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";

const BasicInfo = (): React.ReactElement => {
  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  return (
    <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.GENERAL]}>
      <FormProvider {...formMethods}>
        <Box marginTop="30px">
          <Card>
            <CompanySetupBasicInfo />
          </Card>
        </Box>
      </FormProvider>
    </SectionWrapper>
  );
};

export default BasicInfo;
