import { Stack } from "@mui/material";
import styled from "styled-components";

export const TemplateEditorBox = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
`;

export const TemplateMessagingContainer = styled(Stack)`
  width: 35%;
`;

export const TemplateEditorStack = styled(Stack)`
  min-width: 65%;
`;

export const EditorAndVariablesContainer = styled(Stack)`
  width: 100%;
  overflow: hidden;
`;
