/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobFeature,
    JobFeatureFromJSON,
    JobFeatureFromJSONTyped,
    JobFeatureToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobFeatures
 */
export interface JobFeatures {
    /**
     * 
     * @type {Array<JobFeature>}
     * @memberof JobFeatures
     */
    features: Array<JobFeature>;
    /**
     * 
     * @type {boolean}
     * @memberof JobFeatures
     */
    readonly hasServicesEnabled?: boolean;
}

export function JobFeaturesFromJSON(json: any): JobFeatures {
    return JobFeaturesFromJSONTyped(json, false);
}

export function JobFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'features': ((json['features'] as Array<any>).map(JobFeatureFromJSON)),
        'hasServicesEnabled': !exists(json, 'has_services_enabled') ? undefined : json['has_services_enabled'],
    };
}

export function JobFeaturesToJSON(value?: JobFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'features': ((value.features as Array<any>).map(JobFeatureToJSON)),
    };
}


