import { Box, Stack } from "@mui/material";
import React from "react";

import { Caption } from "components/library/typography";
import { SaapReviewApplication } from "services/openapi";
import { colors } from "styles/theme";

interface ContactInfoProps {
  application: SaapReviewApplication;
}

export const ContactInfo: React.FC<ContactInfoProps> = ({ application }) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Box sx={{ whiteSpace: "nowrap" }}>
        <Caption color={colors.grayscale.gray500}>{application.email}</Caption>
      </Box>
    </Stack>
  );
};
