import LinearProgress from "@mui/material/LinearProgress";
import { GridOverlay } from "@mui/x-data-grid";
import React from "react";
import styled from "styled-components";

const LoadingStyle = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  color: ${(props): any => props.theme.colors.informational.dark};
`;

export default (): React.ReactElement => {
  return (
    <GridOverlay>
      <LoadingStyle>
        <LinearProgress color="inherit" />
      </LoadingStyle>
    </GridOverlay>
  );
};
