import styled from "styled-components";

import { colors, headers, bodyText } from "components/data-driven-forms/styles/theme";

export const PageTitle = styled.div`
  ${headers.h3};
  color: ${colors.gray40};
  margin-top: 8px;
`;
export const PageTitle2 = styled.div`
  ${headers.h2};
  color: ${colors.gray40};
  margin-bottom: 8px;
`;
export const PageJobTitle = styled.div`
  ${headers.h2};
  color: ${colors.black};
  margin-bottom: 8px;
`;
export const PageDescription = styled.div`
  ${bodyText.b1};
  color: ${colors.mediumBlue};
`;

export const BodyText = styled.div`
  ${bodyText.b0};
  color: ${colors.mediumBlue};
  max-width: 640px;
  margin-bottom: 16px;
`;
