import { Box, IconButton, Stack, TextField } from "@mui/material";
import React from "react";

import { ReactComponent as PlusIcon } from "assets/icons/add.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { RequiredAsterisk } from "components/RequiredAsterisk";
import { SelectFieldOption } from "services/openapi";
import { colors } from "styles/theme";

interface SelectOptionsListProps {
  options: SelectFieldOption[];
  setOptions: (options: SelectFieldOption[]) => void;
}

const SelectOptionsList: React.FC<SelectOptionsListProps> = ({ options, setOptions }) => {
  return (
    <Stack spacing={1}>
      <BodySmall weight="600">
        Options
        <RequiredAsterisk />
      </BodySmall>
      <Stack spacing={1}>
        {options?.map((option, optionIndex) => (
          <Stack key={optionIndex} spacing={1} direction="row" alignItems="center">
            <Box
              sx={{
                borderRadius: "50%",
                height: "20px",
                width: "20px",
                border: `solid 1px ${colors.grayscale.gray300}`,
              }}
            />
            <TextField
              size="small"
              fullWidth
              placeholder="Enter an option..."
              value={option.value}
              onChange={(e): void => {
                const newOptions = [...options];
                newOptions[optionIndex] = { value: e.target.value, label: e.target.value };
                setOptions(newOptions);
              }}
            />
            {options.length > 1 && (
              <IconButton
                onClick={(): void => {
                  const newOptions = [...options];
                  newOptions.splice(optionIndex, 1);
                  setOptions(newOptions);
                }}
              >
                <PlusIcon style={{ transform: "rotate(45deg)" }} />
              </IconButton>
            )}
          </Stack>
        ))}
        <Box>
          <Button
            variant={ButtonVariant.Ghost}
            onClick={(): void => {
              const newOptions = [...options];
              newOptions.push({ value: "", label: "" });
              setOptions(newOptions);
            }}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <PlusIcon className="svg-color" color={colors.linkLight} />
              <BodySmall color={colors.linkLight}>Add option</BodySmall>
            </Stack>
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default SelectOptionsList;
