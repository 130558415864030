/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MinMaxRange
 */
export interface MinMaxRange {
    /**
     * 
     * @type {number}
     * @memberof MinMaxRange
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof MinMaxRange
     */
    max?: number;
}

export function MinMaxRangeFromJSON(json: any): MinMaxRange {
    return MinMaxRangeFromJSONTyped(json, false);
}

export function MinMaxRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinMaxRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
    };
}

export function MinMaxRangeToJSON(value?: MinMaxRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min': value.min,
        'max': value.max,
    };
}


