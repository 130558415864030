import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect } from "react";

import { ComponentStatus, useComponentAndMetadata } from "components/dover/hooks";
import {
  JobHasNoCampaignWithAuthedGmailAliasBanner,
  JobHasNoCampaignWithAuthedGmailAliasBannerProps,
} from "components/dover/JobHasNoCampaignWithAuthedGmailAliasBanner";
import JobSelector from "components/JobSelector/JobSelector";
import { BodySmall } from "components/library/typography";
import BaseStepControls from "sections/addcandidate/components/BaseStepControls";
import DoNotSeeJob from "sections/addcandidate/components/steps/JobSelectionStep/DoNotSeeJobHelpText";
import { StepConfig } from "sections/addcandidate/data/stepConfig";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { AddCandidateStep, FlowTypes, StepTypes } from "sections/addcandidate/types";
import { useGetJobsDerivedData } from "services/doverapi/endpoints/job/hooks";
import { DashboardJob } from "services/openapi";
import { sortJobsByTitle } from "utils/job";

const StepContent = (): React.ReactElement => {
  //-----------------------------------------------------------------------------
  // State

  const {
    flowState: {
      [StepTypes.ATS_CANDIDATE_INFO]: atsCandidateInfoState,
      [StepTypes.JOB_SELECT]: { jobId },
    },
    flowType,
    setStepState,
  } = useAddCandidateContext();

  const { activeJobs: jobs } = useGetJobsDerivedData({ sortFunc: sortJobsByTitle });

  //-----------------------------------------------------------------------------
  // Callbacks

  const setJobId = useCallback((newJobId: string): void => setStepState(StepTypes.JOB_SELECT, { jobId: newJobId }), [
    setStepState,
  ]);

  //-----------------------------------------------------------------------------
  // Effects

  useEffect(() => {
    // If we're adding a candidate from customer's ATS, set the values to
    // the ATS candidate we pulled
    if (atsCandidateInfoState?.addingAtsCandidate && atsCandidateInfoState?.atsCandidate?.job) {
      setJobId(atsCandidateInfoState.atsCandidate.job);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atsCandidateInfoState, setJobId]);

  //-----------------------------------------------------------------------------
  // cached UI

  const {
    componentStatus: campaignAuthComponentStatus,
    component: JobHasNoCampaignWithAuthedGmailAliasBannerOrEmpty,
  } = useComponentAndMetadata<JobHasNoCampaignWithAuthedGmailAliasBannerProps>(
    jobId ? { props: { jobId }, componentType: JobHasNoCampaignWithAuthedGmailAliasBanner } : { skip: true }
  );

  // Undefined values can count as falsy here because we don't want to allow continue until we have all of our data
  // TODO: Make this less hacky
  // The banner depend on more pieces of state than are ideal to pull in here
  // (i.e. more than just the useJobHas... hooks above)
  // TODO: fix
  const shouldGateCampaignAuth = flowType === FlowTypes.INITIAL_OUTREACH;
  const needsCampaignAuth = shouldGateCampaignAuth && campaignAuthComponentStatus !== ComponentStatus.EMPTY;
  const disableContinue = !jobId || needsCampaignAuth;

  //-----------------------------------------------------------------------------
  // Render

  return (
    <>
      <Stack direction="column" sx={{ mb: 2, mt: 2 }} alignItems="flex-start" spacing={2}>
        {shouldGateCampaignAuth &&
          ![(ComponentStatus.SKIPPED, ComponentStatus.EMPTY)].includes(campaignAuthComponentStatus) && (
            <div style={{ width: "100%" }}>{JobHasNoCampaignWithAuthedGmailAliasBannerOrEmpty}</div>
          )}
        <Box sx={{ width: "100%" }}>
          <JobSelector jobs={jobs ?? []} jobId={jobId} setJobId={setJobId} />
        </Box>
        <DoNotSeeJob />
      </Stack>

      <BaseStepControls disableContinue={disableContinue} hideBackButton={flowType === FlowTypes.INITIAL_OUTREACH} />
    </>
  );
};

const PostCompletionContent = (): React.ReactElement => {
  const {
    flowState: { [StepTypes.JOB_SELECT]: state },
  } = useAddCandidateContext();

  // TODO: Move selected job to context
  const { activeJobs: unsortedJobs } = useGetJobsDerivedData({});

  const job: DashboardJob | undefined = unsortedJobs?.find(job => job.id === state.jobId);

  if (!job) {
    return <></>;
  }

  return <BodySmall>{job.title}</BodySmall>;
};

const step: AddCandidateStep = {
  ...StepConfig[StepTypes.JOB_SELECT],
  StepContent: <StepContent />,
  PostCompletionContent: <PostCompletionContent />,
};

export default step;
