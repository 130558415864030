import { Box } from "@mui/material";
import React from "react";

import { ReactComponent as CirclePlusSVG } from "assets/icons/circle-plus.svg";
import CreateJobButton from "components/dover/CreateJob/CreateJobButton";
import { Tooltip } from "components/library/Tooltip";
import { DashboardJob } from "services/openapi";
import { StyledTab, StyledTabBarWrapper } from "views/Homepage/styles";
import { HomepageTabEnum } from "views/Homepage/types";

interface HomepageTabProps {
  tabOption: HomepageTabEnum;
  selectedTab: HomepageTabEnum;
  numJobs?: number;
  setSelectedTab: (selectedTab: HomepageTabEnum | undefined) => void;
}

const HomepageTab = React.memo(
  ({ tabOption, selectedTab, numJobs, setSelectedTab }: HomepageTabProps): React.ReactElement => {
    const label = tabOption;
    const isSelected = selectedTab === tabOption;

    return (
      <StyledTab onClick={(): void => setSelectedTab(tabOption)} $isSelected={isSelected}>
        {label}
        {numJobs !== undefined && ` (${numJobs})`}
      </StyledTab>
    );
  }
);

export const HomepageTabBar = React.memo(
  ({
    selectedTab,
    myJobs,
    otherJobs,
    setSelectedTab,
  }: {
    selectedTab: HomepageTabEnum;
    myJobs?: DashboardJob[];
    otherJobs?: DashboardJob[];
    setSelectedTab: (selectedTab: HomepageTabEnum | undefined) => void;
  }): React.ReactElement => {
    return (
      <StyledTabBarWrapper id="homepage-tab-bar-wrapper">
        <Box display="flex" alignItems="center">
          <HomepageTab
            tabOption={HomepageTabEnum.MyJobs}
            selectedTab={selectedTab}
            numJobs={myJobs?.length}
            setSelectedTab={setSelectedTab}
          />
          <HomepageTab
            tabOption={HomepageTabEnum.OtherJobs}
            selectedTab={selectedTab}
            numJobs={otherJobs?.length}
            setSelectedTab={setSelectedTab}
          />
          <CreateJobButton
            omitCreateJobModal
            triggerElement={
              <Tooltip title="Add a new job">
                <CirclePlusSVG style={{ cursor: "pointer", width: "18px", height: "18px", marginLeft: "8px" }} />
              </Tooltip>
            }
          />
        </Box>
      </StyledTabBarWrapper>
    );
  }
);
