import { Skeleton, Stack } from "@mui/material";
import React, { ReactElement } from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as WarningIcon } from "assets/icons/alert.svg";
import { ReactComponent as CircleCheckIcon } from "assets/icons/circled-green-checkmark.svg";
import { ReactComponent as InboxIcon } from "assets/icons/inbox.svg";
import { Circle } from "components/library/Circle";
import { BodySmall } from "components/library/typography";
import { useHasRole, Role } from "components/RBAC";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import {
  useGetFormattedPreviouslyAddedInboundSourceRows,
  useGetSourceDisplaySectionData,
} from "services/doverapi/endpoints/job-source-settings/customHooks";
import { SourceStatus } from "services/doverapi/endpoints/job-source-settings/types";
import { SaapReviewAdditionalFiltersCriteriaMatchEnum } from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";
import { useCountApplicantsByBucketLabel } from "views/candidates/hooks";
import OverviewCard from "views/job/JobSetup/steps/Overview/components/OverviewCard";
import { OverviewCardCount, OverviewCardType } from "views/job/JobSetup/steps/Overview/types";

const ApplicantsOverview = (): ReactElement => {
  const jobId = useJobIdFromUrl();

  // if scoring is not enabled just show total applicants
  // otherwise we can split up applicants by how they match the criteria
  const { jobHasScoringEnabled } = useJobHasScoringEnabled();

  const { count: numBestMatches, isFetching: isFetchingNumBestMatches } = useCountApplicantsByBucketLabel({
    bucketLabel: SaapReviewAdditionalFiltersCriteriaMatchEnum.BestMatch,
    jobId,
    skip: !jobHasScoringEnabled,
  });
  const { count: numCloseMatches, isFetching: isFetchingNumCloseMatches } = useCountApplicantsByBucketLabel({
    bucketLabel: SaapReviewAdditionalFiltersCriteriaMatchEnum.CloseMatch,
    jobId,
    skip: !jobHasScoringEnabled,
  });
  const { count: numMismatches, isFetching: isFetchingNumMismatches } = useCountApplicantsByBucketLabel({
    bucketLabel: SaapReviewAdditionalFiltersCriteriaMatchEnum.Mismatch,
    jobId,
    skip: !jobHasScoringEnabled,
  });

  const { count: numTotalApplicants, isFetching: isFetchingTotalApplicants } = useCountApplicantsByBucketLabel({
    bucketLabel: undefined,
    jobId,
    skip: !!jobHasScoringEnabled,
  });

  const isFetchingCounts = !jobHasScoringEnabled
    ? isFetchingTotalApplicants
    : isFetchingNumBestMatches || isFetchingNumCloseMatches || isFetchingNumMismatches;

  if (!jobId) {
    return <></>;
  }

  const totalApplicantsReq = {
    label: "Total Applicants",
    labelIcon: <Circle size="4px" color={colors.primary.base} />,
    count: numTotalApplicants,
    link: APP_ROUTE_PATHS.job.applicationReviewV2(jobId),
  };

  const bestMatchReq = {
    label: "Best Match",
    labelIcon: <Circle size="4px" color={colors.primary.base} />,
    count: numBestMatches,
    link: APP_ROUTE_PATHS.job.applicationReviewV2(jobId),
  };

  const closeMatchReq = {
    label: "Close Match",
    labelIcon: <Circle size="4px" color={colors.linkLight} />,
    count: numCloseMatches,
    link: APP_ROUTE_PATHS.job.applicationReviewV2(jobId),
  };

  const mismatchReq = {
    label: "Mismatch",
    labelIcon: <Circle size="4px" color={colors.lookAtMeYellow} />,
    count: numMismatches,
    link: APP_ROUTE_PATHS.job.applicationReviewV2(jobId),
  };

  const applicantCounts: OverviewCardCount[] = !jobHasScoringEnabled
    ? [totalApplicantsReq]
    : [bestMatchReq, closeMatchReq, mismatchReq];

  return (
    <OverviewCard
      title={OverviewCardType.APPLICANTS}
      titleIcon={<InboxIcon height="16px" width="16px" />}
      cardCounts={applicantCounts}
      loadingCounts={isFetchingCounts}
      footer={<JobBoardsFooter jobId={jobId} />}
    />
  );
};

interface JobBoardsFooterProps {
  jobId: string;
}

const JobBoardsFooter = ({ jobId }: JobBoardsFooterProps): ReactElement => {
  const isAdmin = useHasRole(Role.ADMIN);
  const { isFetching: isFetchingSources } = useGetSourceDisplaySectionData({ jobId, includeAdminPreview: isAdmin });

  const formattedActiveInboundSourceRows = useGetFormattedPreviouslyAddedInboundSourceRows({
    jobId,
    includeAdminPreview: isAdmin,
  });
  const activeSourceRows = formattedActiveInboundSourceRows.filter(
    source => source.enrichedSourceDetails.status !== SourceStatus.Inactive
  );
  const activeSourceCount = activeSourceRows.length;

  const activeSourcesFooter = (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <CircleCheckIcon width="16px" height="16px" />
      <BodySmall>
        Active on{" "}
        <InternalLink to={APP_ROUTE_PATHS.job.jobBoards(jobId)} $variant="secondary">{`${activeSourceCount} job board${
          activeSourceCount > 1 ? "s" : ""
        }`}</InternalLink>
      </BodySmall>
    </Stack>
  );

  const noActiveSourcesFooter = (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <WarningIcon />
      <BodySmall>
        Not published to any{" "}
        <InternalLink to={APP_ROUTE_PATHS.job.jobBoards(jobId)} $variant="secondary">
          job boards
        </InternalLink>
      </BodySmall>
    </Stack>
  );

  if (isFetchingSources) {
    return (
      <Skeleton
        sx={{
          padding: "8px",
          margin: "16px -8px -16px -8px !important",
        }}
      />
    );
  }

  return (
    <Stack
      borderTop={`solid 1px ${colors.grayscale.gray200}`}
      sx={{
        margin: "24px -16px -16px -16px !important",
        padding: "10px 16px !important",
      }}
    >
      {activeSourceCount > 0 ? activeSourcesFooter : noActiveSourcesFooter}
    </Stack>
  );
};

export default ApplicantsOverview;
