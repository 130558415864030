import HelpIcon from "@mui/icons-material/HelpOutline";
import React from "react";

import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { HELP_DOC_HREFS } from "sections/addcandidate/data/misc";

const CSVHelpTooltipButton = (): React.ReactElement => {
  return (
    <Tooltip
      title={<BodySmall color="inherit">View CSV formatting requirements</BodySmall>}
      placement="top"
      arrow={true}
      variant={TooltipVariant.Dark}
      onClick={(e: React.SyntheticEvent): void => {
        window.open(HELP_DOC_HREFS.CSV_FORMAT, "_blank", "noopener noreferrer");
        e.stopPropagation();
      }}
    >
      <HelpIcon sx={{ cursor: "pointer", width: "20px" }} />
    </Tooltip>
  );
};

export default CSVHelpTooltipButton;
