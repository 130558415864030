import React from "react";

import { FilterPanel } from "components/filters";
import JobSelector from "components/filters/jobs/JobListSelector";

export default (): React.ReactElement => (
  <FilterPanel>
    <JobSelector />
  </FilterPanel>
);
