import React from "react";

import { CandidateStatusFilter } from "views/candidates/CandidateTable/filters/AdvancedFilterSection/CandidateStatusFilter";
import { SourceLabelList } from "views/candidates/constants";

export const CandidateStatusFilterSection = (): React.ReactElement => {
  return (
    <>
      {SourceLabelList.map(source => (
        <CandidateStatusFilter key={source.value} label={source.label} value={source.value} />
      ))}
    </>
  );
};
