import { Box, Stack } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";

export const FooterSpacer: React.FC = () => {
  return <Box height="64px" />;
};

interface FooterActionsProps {
  onBack?: () => void;
  disableNext?: boolean;
  nextLabel?: string;
}

const FooterActions: React.FC<FooterActionsProps> = ({ onBack, disableNext, nextLabel = "Next" }) => {
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={1} position="absolute" bottom="0" width="100%">
      {onBack && (
        <Button variant={ButtonVariant.Secondary} onClick={onBack}>
          Previous
        </Button>
      )}
      <Button variant={ButtonVariant.Primary} type="submit" disabled={disableNext}>
        {nextLabel}
      </Button>
    </Stack>
  );
};

export default FooterActions;
