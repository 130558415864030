/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TitlePatternInfo
 */
export interface TitlePatternInfo {
    /**
     * 
     * @type {boolean}
     * @memberof TitlePatternInfo
     */
    selected: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TitlePatternInfo
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof TitlePatternInfo
     */
    patternName: string;
    /**
     * 
     * @type {number}
     * @memberof TitlePatternInfo
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof TitlePatternInfo
     */
    allowSoftMatch: boolean;
}

export function TitlePatternInfoFromJSON(json: any): TitlePatternInfo {
    return TitlePatternInfoFromJSONTyped(json, false);
}

export function TitlePatternInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TitlePatternInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected': json['selected'],
        'isDefault': json['is_default'],
        'patternName': json['pattern_name'],
        'id': json['id'],
        'allowSoftMatch': json['allow_soft_match'],
    };
}

export function TitlePatternInfoToJSON(value?: TitlePatternInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected': value.selected,
        'is_default': value.isDefault,
        'pattern_name': value.patternName,
        'id': value.id,
        'allow_soft_match': value.allowSoftMatch,
    };
}


