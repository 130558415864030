import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import CloseIcon from "assets/icons/x-circle.svg";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";
import { interviewSchedulerActions } from "views/interview/InterviewScheduler/reducers";
import { CalendarResource } from "views/interview/InterviewScheduler/types";

export const Pill = ({ resource }: { resource: CalendarResource }): ReactElement => {
  const dispatch = useDispatch();

  return (
    <PillContainer
      onClick={(): void => {
        dispatch(interviewSchedulerActions.removeResource(resource));
      }}
    >
      {resource.fullName || resource.email}
      <Spacer width="4px" /> <ReactSVG src={CloseIcon} />
    </PillContainer>
  );
};

const PillContainer = styled.div`
  border-radius: 100px;
  background-color: ${colors.grayscale.gray100};
  margin: 4px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
`;
