import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import { JobFeatureFeatureNameEnum, JobFeatureStateEnum } from "services/openapi";

interface UseGetIsFeatureEnabledParams {
  featureName: JobFeatureFeatureNameEnum;
  jobId?: string;
}

export function useGetIsFeatureEnabled({ featureName, jobId }: UseGetIsFeatureEnabledParams): boolean | undefined {
  const { data } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);

  return useMemo(() => {
    const jobFeaturesAsArray = getCurrentJobFeatureStates(data?.features);
    if (!jobFeaturesAsArray) {
      return undefined;
    }

    return jobFeaturesAsArray[featureName];
  }, [data?.features, featureName]);
}

export function useGetManagedOutboundState({
  jobId,
}: {
  jobId: string | null | undefined;
}): JobFeatureStateEnum | undefined {
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);
  const limitedOutboundState: JobFeatureStateEnum | undefined = useMemo(() => {
    if (!jobFeatures) {
      return undefined;
    }

    const feature = jobFeatures.features.find(
      jobFeature => jobFeature.featureName === JobFeatureFeatureNameEnum.ManagedOutbound
    );
    if (!feature) {
      return undefined;
    }

    return feature.state;
  }, [jobFeatures]);
  return limitedOutboundState;
}

export function useGetDoverInterviewerState({
  jobId,
}: {
  jobId: string | null | undefined;
}): JobFeatureStateEnum | undefined {
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);
  const doverInterviewerState: JobFeatureStateEnum | undefined = useMemo(() => {
    if (!jobFeatures) {
      return undefined;
    }

    const feature = jobFeatures.features.find(
      jobFeature => jobFeature.featureName === JobFeatureFeatureNameEnum.DoverInterviewer
    );
    if (!feature) {
      return undefined;
    }

    return feature.state;
  }, [jobFeatures]);
  return doverInterviewerState;
}

export function useGetEndToEndSchedulingState({
  jobId,
}: {
  jobId: string | null | undefined;
}): JobFeatureStateEnum | undefined {
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);
  const endToEndSchedulingState: JobFeatureStateEnum | undefined = useMemo(() => {
    if (!jobFeatures) {
      return undefined;
    }

    const feature = jobFeatures.features.find(
      jobFeature => jobFeature.featureName === JobFeatureFeatureNameEnum.E2EScheduling
    );
    if (!feature) {
      return undefined;
    }

    return feature.state;
  }, [jobFeatures]);
  return endToEndSchedulingState;
}
