/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QueryBuilderRule,
    QueryBuilderRuleFromJSON,
    QueryBuilderRuleFromJSONTyped,
    QueryBuilderRuleToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryBuilderValue
 */
export interface QueryBuilderValue {
    /**
     * 
     * @type {string}
     * @memberof QueryBuilderValue
     */
    combinator: string;
    /**
     * 
     * @type {Array<QueryBuilderRule>}
     * @memberof QueryBuilderValue
     */
    rules: Array<QueryBuilderRule>;
    /**
     * 
     * @type {Array<object>}
     * @memberof QueryBuilderValue
     */
    subqueries: Array<object>;
}

export function QueryBuilderValueFromJSON(json: any): QueryBuilderValue {
    return QueryBuilderValueFromJSONTyped(json, false);
}

export function QueryBuilderValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryBuilderValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'combinator': json['combinator'],
        'rules': ((json['rules'] as Array<any>).map(QueryBuilderRuleFromJSON)),
        'subqueries': json['subqueries'],
    };
}

export function QueryBuilderValueToJSON(value?: QueryBuilderValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'combinator': value.combinator,
        'rules': ((value.rules as Array<any>).map(QueryBuilderRuleToJSON)),
        'subqueries': value.subqueries,
    };
}


