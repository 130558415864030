/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Mention
 */
export interface Mention {
    /**
     * 
     * @type {string}
     * @memberof Mention
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Mention
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof Mention
     */
    mentionersName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Mention
     */
    markedAsRead?: boolean;
}

export function MentionFromJSON(json: any): Mention {
    return MentionFromJSONTyped(json, false);
}

export function MentionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mention {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'mentionersName': !exists(json, 'mentioners_name') ? undefined : json['mentioners_name'],
        'markedAsRead': !exists(json, 'marked_as_read') ? undefined : json['marked_as_read'],
    };
}

export function MentionToJSON(value?: Mention | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'mentioners_name': value.mentionersName,
        'marked_as_read': value.markedAsRead,
    };
}


