import { Campaign, UnsubscribeFooter } from "services/openapi";
import {
  // Load campaign
  LOAD_CAMPAIGN,
  LoadCampaignAction,
  LOAD_CAMPAIGN_SUCCESS,
  LoadCampaignSuccessAction,
  LOAD_CAMPAIGN_ERROR,
  LoadCampaignErrorAction,
  // Save campaign
  SAVE_CAMPAIGN,
  SaveCampaignAction,
  SAVE_CAMPAIGN_SUCCESS,
  SaveCampaignSuccessAction,
  SAVE_CAMPAIGN_ERROR,
  SaveCampaignErrorAction,
  // Load unsubscribe footers
  LOAD_UNSUBSCRIBE_FOOTERS,
  LoadUnsubscribeFootersAction,
  LOAD_UNSUBSCRIBE_FOOTERS_SUCCESS,
  LoadUnsubscribeFootersSuccessAction,
  LOAD_UNSUBSCRIBE_FOOTERS_ERROR,
  LoadUnsubscribeFootersErrorAction,
  // Send test emails
  SEND_TEST_EMAILS,
  SendTestEmailsAction,
} from "views/admin/CampaignView/types";

export function loadCampaign(campaignId: string): LoadCampaignAction {
  return {
    type: LOAD_CAMPAIGN,
    campaignId,
  };
}

export function loadCampaignSuccess(campaign: Campaign): LoadCampaignSuccessAction {
  return {
    type: LOAD_CAMPAIGN_SUCCESS,
    campaign,
  };
}

export function loadCampaignError(error?: string): LoadCampaignErrorAction {
  return {
    type: LOAD_CAMPAIGN_ERROR,
    error,
  };
}

export function saveCampaign(campaign: Campaign): SaveCampaignAction {
  return {
    type: SAVE_CAMPAIGN,
    campaign,
  };
}

export function saveCampaignSuccess(campaign: Campaign): SaveCampaignSuccessAction {
  return {
    type: SAVE_CAMPAIGN_SUCCESS,
    campaign,
  };
}

export function saveCampaignError(error?: string): SaveCampaignErrorAction {
  return {
    type: SAVE_CAMPAIGN_ERROR,
    error,
  };
}

export function loadUnsubscribeFooters(): LoadUnsubscribeFootersAction {
  return {
    type: LOAD_UNSUBSCRIBE_FOOTERS,
  };
}

export function loadUnsubscribeFootersSuccess(
  unsubscribeFooters: UnsubscribeFooter[]
): LoadUnsubscribeFootersSuccessAction {
  return {
    type: LOAD_UNSUBSCRIBE_FOOTERS_SUCCESS,
    unsubscribeFooters,
  };
}

export function loadUnsubscribeFootersError(error?: string): LoadUnsubscribeFootersErrorAction {
  return {
    type: LOAD_UNSUBSCRIBE_FOOTERS_ERROR,
    error,
  };
}

export function sendTestEmails(campaign: Campaign): SendTestEmailsAction {
  return {
    type: SEND_TEST_EMAILS,
    campaign,
  };
}
