import {
  HiringPipelineStage,
  HiringPipelineStageMilestone,
  SubstageType,
  HiringPipelineStageType,
  BaseCandidatePipelineStage,
  RespondedSubstageEnum,
  ContactedSubstageEnum,
  InterviewPlanInterviewSubstage,
} from "services/openapi";

// Keep this is sync with the backend.
// TODO: It'd be great if we could figure out a way to share constants like this between the two...
const PRE_INTERVIEW_STAGE_TYPES = [
  HiringPipelineStageType.QUEUED,
  HiringPipelineStageType.CONTACTED,
  HiringPipelineStageType.RESPONDED,
  HiringPipelineStageType.APPLICATION_REVIEW,
];

const IS_CONTACTED_OR_MORE_STAGE_TYPES = [
  HiringPipelineStageType.CONTACTED,
  HiringPipelineStageType.RESPONDED,
  HiringPipelineStageType.APPLICATION_REVIEW,
  HiringPipelineStageType.INTERVIEW,
  HiringPipelineStageType.OFFER,
];

export const isPreInterviewPipelineStage = (stage: HiringPipelineStage): boolean => {
  return PRE_INTERVIEW_STAGE_TYPES.includes(stage.stageType);
};

export const isContactedOrBeyondStage = (stage: HiringPipelineStage): boolean => {
  return IS_CONTACTED_OR_MORE_STAGE_TYPES.includes(stage.stageType);
};

// Interview stage utils
export const getSinglePartSubstage = (stage: HiringPipelineStage): InterviewPlanInterviewSubstage | undefined => {
  const substages = stage.multipartInterviewStage?.substages;

  if (!substages || substages.length > 1 || stage.contentTypeName !== SubstageType.INTERVIEW_STAGE) {
    return undefined;
  }

  return substages[0];
};

export const isQueuedStage = (stage: HiringPipelineStage): boolean => {
  return stage.stageType === HiringPipelineStageType.QUEUED;
};
export const isContactedStage = (stage: HiringPipelineStage): boolean => {
  return stage.stageType === HiringPipelineStageType.CONTACTED;
};
export const hasReceivedAllOutreach = (stage: BaseCandidatePipelineStage): boolean => {
  return isContactedStage(stage) && stage?.substage === ContactedSubstageEnum.OUTREACH_1_FOLLOW_UP_3;
};

export const isRespondedStage = (stage: HiringPipelineStage): boolean => {
  return stage.stageType === HiringPipelineStageType.RESPONDED;
};

export const isInterestedRespondedStage = (stage: BaseCandidatePipelineStage): boolean => {
  return (
    stage.stageType === HiringPipelineStageType.RESPONDED &&
    stage.substage != undefined &&
    stage.substage >= RespondedSubstageEnum.INTERESTED
  );
};

export const isAppliedOrRespondedStage = (stage: HiringPipelineStage): boolean =>
  stage.stageType === HiringPipelineStageType.APPLICATION_REVIEW ||
  stage.stageType === HiringPipelineStageType.RESPONDED;

export const isAppReviewStage = (stage: HiringPipelineStage): boolean =>
  stage.stageType === HiringPipelineStageType.APPLICATION_REVIEW;

export const isInitialCallStage = (stage: HiringPipelineStage): boolean =>
  stage.milestone === HiringPipelineStageMilestone.INITIAL_CALL;

export const isSinglepartStage = (stage: HiringPipelineStage): boolean =>
  stage.contentTypeName === SubstageType.INTERVIEW_STAGE;

export const isMultipartStage = (stage: HiringPipelineStage): boolean =>
  stage.contentTypeName === SubstageType.MULTIPART_INTERVIEW_STAGE;

export const isInterviewStage = (stage: HiringPipelineStage): boolean => {
  return stage.stageType === HiringPipelineStageType.INTERVIEW;
};

export const isOfferStage = (stage: HiringPipelineStage): boolean => {
  return stage.stageType === HiringPipelineStageType.OFFER;
};

export const isTakeHomeStage = (stage: HiringPipelineStage): boolean => {
  const substage = getSinglePartSubstage(stage);

  return substage?.isTakeHome || false;
};

// composite hooks
export const isActiveInterviewPipelineStage = (stage: HiringPipelineStage): boolean => {
  return isInterviewStage(stage) || isOfferStage(stage);
};

export const isPreResponseStage = (stage: HiringPipelineStage): boolean => {
  return [HiringPipelineStageType.QUEUED, HiringPipelineStageType.CONTACTED].includes(stage.stageType);
};

export const needsAction = (stage: HiringPipelineStage): boolean => {
  return isRespondedStage(stage) || isActiveInterviewPipelineStage(stage);
};

export const isPreClientReview = (stage: BaseCandidatePipelineStage): boolean => {
  if (isPreInterviewPipelineStage(stage)) {
    return true;
  }
  return isRespondedStage(stage) || isInterestedRespondedStage(stage);
};

interface isStageReturn {
  isInitialCall: boolean;
  isMultipart: boolean;
  isAppliedOrResponded: boolean;
  isOffer: boolean;
  isTakeHome: boolean;
}

export const isStage = (stage: HiringPipelineStage): isStageReturn => {
  return {
    isInitialCall: isInitialCallStage(stage),
    isMultipart: isMultipartStage(stage),
    isAppliedOrResponded: isAppliedOrRespondedStage(stage),
    isOffer: isOfferStage(stage),
    isTakeHome: isTakeHomeStage(stage),
  };
};
