import { Box, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";

import InfoIconSVG from "assets/icons/info-icon.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import { useUpdateJobHiringTeamMutation } from "services/doverapi/endpoints/job";
import { toastOptions } from "utils/showToast";

export const AddInterviewersToHiringTeam = ({ interviewerIds }: { interviewerIds: string[] }): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  const clientInterviewers = useListInterviewerEntities();

  const [initialInterviewersValue] = useState<string[]>(interviewerIds);
  const [interviewersChanged, setInterviewersChanged] = useState<boolean>(false);

  const [updateHiringTeam] = useUpdateJobHiringTeamMutation();

  useEffect(() => {
    if (interviewerIds.length !== initialInterviewersValue.length) {
      setInterviewersChanged(true);
    }
  }, [interviewerIds, initialInterviewersValue.length]);

  const interviewerIdToProUserId = useMemo(() => {
    return clientInterviewers.reduce((acc, interviewer) => {
      if (!interviewer.proUserId || !interviewer.id) {
        return acc;
      }
      acc[interviewer.id] = interviewer.proUserId;
      return acc;
    }, {} as Record<string, number>);
  }, [clientInterviewers]);

  const addInterviewersToHiringTeam = useCallback(async () => {
    if (!interviewerIds || interviewerIds.length === 0 || !jobId) {
      return;
    }

    const promise = updateHiringTeam({
      // need to skip invalidating tags because otherwise we'll trigger a refresh
      skipInvalidateTags: true,
      id: jobId,
      hiringTeamMemberIds: interviewerIds.map(interviewerId => interviewerIdToProUserId[interviewerId]).filter(i => i),
      appendHiringTeamMembers: true,
    });

    await toast.promise(
      promise,
      {
        pending: "Updating hiring team members...",
        success: "Successfully updated hiring team members",
        error: "Failed to update hiring team members",
      },
      { ...toastOptions }
    );
  }, [interviewerIds, updateHiringTeam, interviewerIdToProUserId, jobId]);

  if (!interviewersChanged || interviewerIds.length === 0) {
    return <></>;
  }

  return (
    <>
      <Stack direction="row" spacing={1} marginBottom="4px" paddingTop={"8px"}>
        <Button variant={ButtonVariant.Secondary} onClick={addInterviewersToHiringTeam}>
          Add all interviewers to hiring team
        </Button>
        <Tooltip
          title={
            "Hiring team members are able to view all feedback forms and take actions on candidates such as scheduling, emailing, and changing stage/status"
          }
          placement="top"
        >
          <Box sx={{ paddingTop: "9px" }}>
            <ReactSVG src={InfoIconSVG} />
          </Box>
        </Tooltip>
      </Stack>
    </>
  );
};
