import { Box, RadioGroup, Stack, FormControl, TextField } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { StyledFormControlLabel, StyledRadio } from "components/dover/Campaigns/styles";
import { ModalKeyType } from "components/dover/Campaigns/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { Spacer } from "components/Spacer";
import {
  selectFromListCampaignsQueryResult,
  useListCampaignsQuery,
  usePartialUpdateCampaignMutation,
} from "services/doverapi/endpoints/campaign";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { CreateCampaignEmailSenderOptionEnum, DoverUser } from "services/openapi";
import { colors } from "styles/theme";
import { B1_doNotUse, B2_doNotUse } from "styles/typography";
import ProUserSelect from "views/job/JobSetup/steps/CampaignVariants/modals/components/ProUserSelect";
import { AddNewCampaignProps } from "views/job/JobSetup/steps/CampaignVariants/types";

interface UpdateOrCreateCampaignModalProps {
  isNewCampaign?: boolean;
  handleAddNewCampaign?: ({ campaignName, emailSenderOption, userDefinedSenderUser }: AddNewCampaignProps) => void;
  initialCampaignName?: string;
  initialEmailSenderOption?: CreateCampaignEmailSenderOptionEnum;
  selectedOtherUser?: number;
  setSelectedOtherUser: (selectedOtherUser: number | undefined) => void;
  campaignId?: string;
  shouldShowModal: ModalKeyType;
  handleCloseModal: () => void;
  handleOpenProUserModal: () => void;
  modalKey: ModalKeyType;
  onlyNameEditable: boolean;
}

export const UpdateOrCreateCampaignModal = ({
  isNewCampaign,
  handleAddNewCampaign,
  initialCampaignName,
  initialEmailSenderOption,
  selectedOtherUser,
  setSelectedOtherUser,
  campaignId,
  shouldShowModal,
  handleCloseModal,
  handleOpenProUserModal,
  modalKey,
  onlyNameEditable,
}: UpdateOrCreateCampaignModalProps): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();
  const { proUsers } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsers,
        };
      },
    }
  );

  const { allCampaigns: campaigns } = useListCampaignsQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
  });

  const onBasePlan = useIsBasePlanCustomer();
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);

  const [updateCampaign] = usePartialUpdateCampaignMutation();

  const [submitting, setSubmitting] = useState(false);
  const [campaignName, setCampaignName] = useState<string | undefined>(initialCampaignName);
  const [campaignNameError, setCampaignNameError] = useState<boolean>(false);
  const [selectedEmailSenderOption, setSelectedEmailSenderOption] = useState<
    CreateCampaignEmailSenderOptionEnum | undefined
  >(initialEmailSenderOption);

  useEffect(() => {
    setCampaignName(initialCampaignName);
    setSelectedEmailSenderOption(initialEmailSenderOption);
  }, [initialCampaignName, initialEmailSenderOption]);

  const getUserDefinedSenderUser = (): number | undefined => {
    return selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser ? selectedOtherUser : undefined;
  };

  const handleUpdateCampaign = async (): Promise<void> => {
    // Button will be disabled until jobId is defined anyway. This check is for type safety and an extra safeguard.
    if (!jobId) {
      return;
    }

    const userDefinedSenderUser = getUserDefinedSenderUser();
    const originalCampaign = (campaigns ?? []).filter(campaign => campaign.id == campaignId)[0];
    await updateCampaign({
      // TODO: add form validation to prevent needing to assert id and name are not undefined
      id: campaignId!,
      jobId: jobId,
      updatedCampaign: {
        name: campaignName!,
        emailSenderOption: selectedEmailSenderOption as string,
        userDefinedSenderUser,
        saveAsDraft: true,
      },
      emailOrNameUpdated:
        campaignName != originalCampaign.name ||
        (selectedEmailSenderOption as string) != originalCampaign.emailSenderOption,
    });
  };

  const handleCampaignNameChange = (value: any): void => {
    if (
      (campaigns ?? [])
        .filter(campaign => campaign.id != campaignId)
        .map(campaign => campaign.name)
        .includes(value)
    ) {
      setCampaignNameError(true);
    } else {
      setCampaignNameError(false);
    }
    setCampaignName(value);
  };

  return (
    <CustomModal
      title={<B1_doNotUse>{isNewCampaign ? "Create Campaign" : "Edit Campaign"}</B1_doNotUse>}
      open={shouldShowModal === modalKey}
      onClose={(): void => handleCloseModal()}
      maxWidth="xs"
      dialogActions={
        <>
          <Button variant={ButtonVariant.Secondary} onClick={(): void => handleCloseModal()}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            loading={!jobId}
            disabled={
              submitting ||
              !selectedEmailSenderOption ||
              !campaignName ||
              campaignNameError ||
              (selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser && !selectedOtherUser)
            }
            onClick={async (): Promise<void> => {
              setSubmitting(true);
              if (isNewCampaign && handleAddNewCampaign) {
                handleAddNewCampaign({
                  campaignName: campaignName,
                  emailSenderOption: selectedEmailSenderOption as CreateCampaignEmailSenderOptionEnum,
                  userDefinedSenderUser: getUserDefinedSenderUser(),
                });
              } else {
                await handleUpdateCampaign();
              }
              setSubmitting(false);
              handleCloseModal();
            }}
          >
            Submit
          </Button>
        </>
      }
    >
      {submitting ? (
        <DoverLoadingSpinner />
      ) : (
        <Box>
          <Body weight="500">Campaign Name</Body>
          <Spacer height="12px" />
          <TextField
            fullWidth
            value={campaignName}
            onChange={(e): void => handleCampaignNameChange(e.target.value)}
            error={campaignNameError}
            sx={{
              ".MuiOutlinedInput-root.MuiInputBase-root": {
                outline: "none",
                border: "none",
              },
            }}
            helperText={campaignNameError && "Cannot have the same name as another campaign."}
          />
          <Spacer height="16px" />
          {!onlyNameEditable && (
            <>
              <Stack spacing={2}>
                <Box display="flex">
                  <B2_doNotUse medium>Who do you prefer outreach come from?</B2_doNotUse>
                </Box>
                <FormControl component="fieldset" variant="standard">
                  <RadioGroup
                    name="selectEmailSender"
                    value={selectedEmailSenderOption}
                    onChange={(event): void =>
                      setSelectedEmailSenderOption(event.target.value as CreateCampaignEmailSenderOptionEnum)
                    }
                  >
                    <StyledFormControlLabel
                      value={CreateCampaignEmailSenderOptionEnum.HiringManager}
                      control={<StyledRadio />}
                      label={<Body style={{ marginLeft: "4px" }}>Hiring manager</Body>}
                    />
                    <Box ml={2}>
                      <BodySmall color={colors.grayscale.gray600}>
                        Candidates typically prefer emails directly from a hiring manager, which leads to a higher
                        interest rate.
                      </BodySmall>
                    </Box>
                    <Spacer height="12px" />
                    {jobFeatures?.hasServicesEnabled && !onBasePlan && (
                      <>
                        <StyledFormControlLabel
                          value={CreateCampaignEmailSenderOptionEnum.VirtualRecruiter}
                          control={<StyledRadio />}
                          label={<Body style={{ marginLeft: "4px" }}>Virtual Recruiter</Body>}
                          sx={{ marginBottom: 0 }}
                        />
                        <Box ml={2}>
                          <BodySmall color={colors.grayscale.gray600}>
                            This may result in 20% fewer interested candidates. Dover can set up outreach to send from a
                            “recruiter” email.
                          </BodySmall>
                        </Box>
                        <Spacer height="12px" />
                      </>
                    )}
                    <StyledFormControlLabel
                      value={CreateCampaignEmailSenderOptionEnum.OtherUser}
                      control={<StyledRadio />}
                      label={<Body style={{ marginLeft: "4px" }}>Someone else on my team</Body>}
                    />
                  </RadioGroup>
                </FormControl>
                {selectedEmailSenderOption === CreateCampaignEmailSenderOptionEnum.OtherUser && proUsers && (
                  <ProUserSelect
                    initialProUser={selectedOtherUser}
                    proUsers={proUsers}
                    setSelectedProUser={setSelectedOtherUser}
                    handleOpenProUserModal={handleOpenProUserModal}
                  />
                )}
              </Stack>
            </>
          )}
        </Box>
      )}
    </CustomModal>
  );
};
