/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailArgs
 */
export interface EmailArgs {
    /**
     * 
     * @type {string}
     * @memberof EmailArgs
     */
    subjectHtml: string;
    /**
     * 
     * @type {string}
     * @memberof EmailArgs
     */
    bodyHtml: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailArgs
     */
    ccEmails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailArgs
     */
    bccEmails: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EmailArgs
     */
    delay: number;
}

export function EmailArgsFromJSON(json: any): EmailArgs {
    return EmailArgsFromJSONTyped(json, false);
}

export function EmailArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailArgs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectHtml': json['subject_html'],
        'bodyHtml': json['body_html'],
        'ccEmails': json['cc_emails'],
        'bccEmails': json['bcc_emails'],
        'delay': json['delay'],
    };
}

export function EmailArgsToJSON(value?: EmailArgs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject_html': value.subjectHtml,
        'body_html': value.bodyHtml,
        'cc_emails': value.ccEmails,
        'bcc_emails': value.bccEmails,
        'delay': value.delay,
    };
}


