import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import styled from "styled-components";

export const CredentialsContainer = styled.div`
  margin-top: 60px;
  margin-left: 50px;
  max-width: 500px;
`;

export const StyledList = styled(List)`
  &.MuiList-padding {
    padding-top: 0px;
  }
`;

export const StyledListItem = styled(ListItem)`
  .MuiListItemText-primary {
    font-family: Avenir, sans-serif;
  }
  .MuiListItemText-secondary {
    font-family: Avenir, sans-serif;
    font-size: 0.8em;
  }
  &.MuiListItem-root {
    align-items: flex-start;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    color: rgba(0, 0, 0, 1);
    min-width: 24px;
  }
`;
