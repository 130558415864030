/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobReferralPerson
 */
export interface JobReferralPerson {
    /**
     * 
     * @type {string}
     * @memberof JobReferralPerson
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferralPerson
     */
    readonly linkedinUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferralPerson
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferralPerson
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferralPerson
     */
    readonly currentTitleAtCurrentCompany?: string;
}

export function JobReferralPersonFromJSON(json: any): JobReferralPerson {
    return JobReferralPersonFromJSONTyped(json, false);
}

export function JobReferralPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobReferralPerson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'firstName': json['first_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'currentTitleAtCurrentCompany': !exists(json, 'current_title_at_current_company') ? undefined : json['current_title_at_current_company'],
    };
}

export function JobReferralPersonToJSON(value?: JobReferralPerson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
    };
}


