import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { Form } from "react-final-form";

import { DeleteFileIconButton } from "components/dover/common";
import { CancelButton } from "components/dover/UploadUserFile/styles";
import { FormValues } from "components/dover/UploadUserFile/types";
import FileUploadField from "components/inputs/FileUpload";
import { PlaceholderWrapper, PlaceholderText, DummyButton } from "components/inputs/FileUpload/styles";
import { Button, ButtonVariant } from "components/library/Button";
import { StyledAlert } from "components/styles";
import { useCreateUserUploadedFileMutation } from "services/doverapi/endpoints/userUploadedFile";
import { DoverApiError } from "services/doverapi/types";
import { CreateUserUploadedFileUploadedContextEnum } from "services/openapi";
import { showSuccessToast } from "utils/showToast";

const UPLOAD_USER_FILE = "UPLOAD_USER_FILE";

const INITIAL_FORM_VALUES: FormValues = {
  files: [],
};

interface UserUploadedFileProps {
  uploadedContext: CreateUserUploadedFileUploadedContextEnum;
  acceptFileTypes: Array<string> | string;
  cancelUploadContacts?: () => void;
}

const UploadUserFile = ({
  uploadedContext,
  acceptFileTypes,
  cancelUploadContacts,
}: UserUploadedFileProps): React.ReactElement => {
  const [createUserUploadedFile, { error: fileUploadError }] = useCreateUserUploadedFileMutation();
  const errorMessage = useMemo(() => {
    return (fileUploadError as DoverApiError | undefined)?.userFacingMessage;
  }, [fileUploadError]);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    try {
      await createUserUploadedFile({
        uploadedContext,
        fileContents: values.files[0],
      }).unwrap();

      showSuccessToast("Successfully uploaded and queued for processing!");
    } catch (error) {
      console.error(error);
    }
  };

  const validate = (values: FormValues): any => {
    if (!values.files) {
      return { files: "You must select a file to upload" };
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={INITIAL_FORM_VALUES}
      validate={validate}
      render={({ handleSubmit, submitting, values, pristine, form }): React.ReactElement => {
        return (
          <form>
            <Box display="flex" flexDirection="column">
              <Box>
                {errorMessage && (
                  <StyledAlert severity="error">
                    <div>{errorMessage}</div>
                  </StyledAlert>
                )}
                <FileUploadField
                  name={"files"}
                  errorKey={UPLOAD_USER_FILE}
                  acceptFileTypes={acceptFileTypes}
                  dropzoneOptions={{ maxFiles: 1, disabled: submitting }}
                  placeholder={
                    values.files[0] ? (
                      <PlaceholderWrapper>
                        <PlaceholderText>
                          {values.files[0]?.name} <DeleteFileIconButton />
                        </PlaceholderText>
                      </PlaceholderWrapper>
                    ) : (
                      <PlaceholderWrapper>
                        <PlaceholderText>Drag and drop file here</PlaceholderText>
                        <PlaceholderText>or</PlaceholderText>
                        <DummyButton>Browse Files</DummyButton>
                      </PlaceholderWrapper>
                    )
                  }
                  placeholderWhenDragActive={
                    <PlaceholderWrapper>
                      <PlaceholderText>Drop file here</PlaceholderText>
                    </PlaceholderWrapper>
                  }
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Box>
                  {cancelUploadContacts && (
                    <CancelButton variant={ButtonVariant.Secondary} onClick={(): void => cancelUploadContacts()}>
                      Cancel
                    </CancelButton>
                  )}
                  <Box marginTop="16px" maxWidth="710px" display="flex" justifyContent="flex-end">
                    <Button
                      variant={ButtonVariant.Primary}
                      onClick={async (): Promise<void> => {
                        await handleSubmit();
                        form.reset();
                      }}
                      disabled={submitting || pristine}
                    >
                      Upload
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        );
      }}
    />
  );
};

export default UploadUserFile;
