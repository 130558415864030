import { tagRegex, validTags } from "components/dover/CampaignEditor/constants";

export function getInvalidTagsFromEmailMessages(emailMessages: string | undefined): string[] {
  if (!emailMessages) {
    return [];
  }

  const allTags: string[] = [];
  let match: null | RegExpExecArray = null;
  while ((match = tagRegex.exec(emailMessages)) !== null) {
    allTags.push(match[1].toUpperCase());
  }
  return allTags.filter(tag => !validTags.includes(tag));
}
