import Container from "@mui/material/Container";
import styled from "styled-components";

import { size } from "App/styles/App";

export const FormBodyWrapper = styled.div`
  .MuiInputBase-root.Mui-disabled {
    color: #16303d;
    cursor: default;
  }
`;

export const InterviewRubricContainer = styled(Container)`
  width: 95%;
  margin-bottom: 77px;
  max-width: ${size.desktopS};
`;
