/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Box, Modal, TextField } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import NoteIcon from "assets/icons/note.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonText } from "components/library/typography";
import { StyledModalContent } from "components/Modal";
import { Spacer } from "components/Spacer";
import { usePartialUpdateJobPositionOpeningMutation } from "services/doverapi/endpoints/hiring-plan";
import { HiringPlanJobPositionOpening } from "services/openapi/models";
import { colors, filters } from "styles/theme";

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root.MuiInputBase-root {
    font-size: 14px;
    font-family: Inter;
    width: 480px;
    max-width: 100%;

    input {
      padding: 12px;
    }
  }

  .MuiOutlinedInput-notchedOutline > legend {
    display: none;
  }
`;

const StyledNotesIcon = styled(ReactSVG)`
  position: relative;
  width: 24px;
`;

const StyledNoteAddOutlinedIcon = styled(NoteAddOutlinedIcon)`
  filter: ${filters.filterGray400};
`;

interface Props {
  jobPositionOpening: HiringPlanJobPositionOpening;
  notes: string;
}

const NotesModal = ({ jobPositionOpening, notes }: Props): React.ReactElement => {
  const [notesText, setNotesText] = useState<string | null | undefined>(notes);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (notes !== notesText) {
      setNotesText(notes);
    }
  }, [notes]);

  const [partialUpdateJobPositionOpening] = usePartialUpdateJobPositionOpeningMutation();

  const handleUpdateJobPositionOpening = (): void => {
    const updatedJobPositionOpening: HiringPlanJobPositionOpening = {
      ...jobPositionOpening,
      notes: notesText,
    };
    partialUpdateJobPositionOpening({
      jobPositionOpening: updatedJobPositionOpening as HiringPlanJobPositionOpening,
    });
    setShowModal(false);
  };

  const icon = useMemo((): React.ReactElement => {
    if (notes) {
      return <StyledNotesIcon src={NoteIcon} />;
    }
    return <StyledNoteAddOutlinedIcon />;
  }, []);

  return (
    <>
      <Box style={{ cursor: "pointer" }} onClick={(): void => setShowModal(true)}>
        {icon}
      </Box>
      <Modal
        style={{ display: "flex" }}
        open={showModal}
        onClose={(): void => {
          handleUpdateJobPositionOpening();
        }}
      >
        <StyledModalContent>
          <StyledTextField
            className="custom_notes-area"
            value={notesText || ""}
            multiline
            rows={6}
            onChange={(e): void => {
              e.preventDefault();
              setNotesText(e.target.value);
            }}
          />
          <Spacer height="24px" />
          <Box display="flex" justifyContent="flex-end">
            <Button variant={ButtonVariant.Secondary} onClick={(): void => setShowModal(false)}>
              <ButtonText>Cancel</ButtonText>
            </Button>
            <Spacer width="8px" />
            <Button variant={ButtonVariant.Primary} onClick={(): void => handleUpdateJobPositionOpening()}>
              <ButtonText color={colors.white}>Save</ButtonText>
            </Button>
          </Box>
        </StyledModalContent>
      </Modal>
    </>
  );
};

export default NotesModal;
