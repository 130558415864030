import { Box } from "@mui/material";
import React from "react";

import ClientUserFileStatsTable from "components/dover/ClientUserFileStatsTable";
import {
  ContentWrapper,
  HeaderH1,
  WarningIcon,
  StyledText,
  StyledWarningBanner,
} from "components/dover/ContactExclusion/styles";
import { UserUploadedFileUploadedContextEnum } from "services/openapi";

const AuthUserHasNotUploadedWarningMessage = (): React.ReactElement => (
  <StyledWarningBanner>
    <Box display="flex" alignItems="baseline">
      <StyledText>
        <WarningIcon />
        No one on your team has uploaded their contacts yet.
      </StyledText>
    </Box>
  </StyledWarningBanner>
);

interface ExcludedConnectionsProps {
  loadedUserFileStats: boolean;
  numUsersWhoUploadedConnections: number;
  didAuthedUserUploadFile: boolean;
}

export const ExcludedConnections = ({
  loadedUserFileStats,
  numUsersWhoUploadedConnections,
  didAuthedUserUploadFile,
}: ExcludedConnectionsProps): React.ReactElement => {
  const showAuthedUserHasNotUploadedWarningMessage =
    loadedUserFileStats && numUsersWhoUploadedConnections === 0 && !didAuthedUserUploadFile;

  return (
    <ContentWrapper elevation={0} variant="outlined">
      <HeaderH1>Team Uploads</HeaderH1>
      <ClientUserFileStatsTable uploadedContext={UserUploadedFileUploadedContextEnum.Referrals} />

      {showAuthedUserHasNotUploadedWarningMessage && <AuthUserHasNotUploadedWarningMessage />}
    </ContentWrapper>
  );
};
