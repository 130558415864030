import { useAtomValue } from "jotai";
import { useLocation } from "react-router-dom";

import { APPLICATION_REVIEW_PATH, DTN_PATH } from "App/routing/route-path-constants";
import {
  SubmitDecisionArgs,
  useSubmitDecisionMutation,
} from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useListAppsOptimisticUpdateArgsAtom } from "views/candidates/ApplicationReview/atoms/listApplicationArgs";
import { listTalentsOptimisticUpdateArgsAtom } from "views/candidates/DoverTalentNetwork/atoms/listTalentNetwork";

/**
 * This hook should always be used when wanting to call the submit decision api.
 * It wraps your api call in some logic that will properly add optimistic update information only when needed.
 * It returns the full rtkq mutation function result so you can use it like you normally would.
 */
// The mutation return type is pretty gnarly, so fine with the implied type
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSubmitDecision = () => {
  const [submitDecision] = useSubmitDecisionMutation();

  // Needed for optimistic update after approving or rejecting
  const listAppsOptimisticUpdateArgs = useListAppsOptimisticUpdateArgsAtom();
  const listTalentsOptimisticUpdateArgs = useAtomValue(listTalentsOptimisticUpdateArgsAtom);

  const location = useLocation();
  const inSaapReview = location.pathname.includes(APPLICATION_REVIEW_PATH);
  const inDtn = location.pathname.includes(DTN_PATH);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (args: SubmitDecisionArgs) => {
    // We only want this function called in saap review because otherwise it will route you to saap review
    const toNextApplication = inSaapReview
      ? listAppsOptimisticUpdateArgs?.toNextApplication
      : inDtn
      ? listTalentsOptimisticUpdateArgs?.toNextApplication
      : undefined;

    const fullArgs = {
      ...listAppsOptimisticUpdateArgs,
      ...listTalentsOptimisticUpdateArgs,
      toNextApplication,
      ...args,
    };

    return submitDecision(fullArgs);
  };
};
