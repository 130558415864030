/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HiringPipelineStageMilestone,
    HiringPipelineStageMilestoneFromJSON,
    HiringPipelineStageMilestoneFromJSONTyped,
    HiringPipelineStageMilestoneToJSON,
    HiringPipelineStageType,
    HiringPipelineStageTypeFromJSON,
    HiringPipelineStageTypeFromJSONTyped,
    HiringPipelineStageTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobStage
 */
export interface JobStage {
    /**
     * 
     * @type {string}
     * @memberof JobStage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof JobStage
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobStage
     */
    isTakeHome: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobStage
     */
    useDoverInterviewer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JobStage
     */
    readonly isSchedulable?: boolean;
    /**
     * 
     * @type {HiringPipelineStageType}
     * @memberof JobStage
     */
    stageType: HiringPipelineStageType;
    /**
     * 
     * @type {HiringPipelineStageMilestone}
     * @memberof JobStage
     */
    milestone: HiringPipelineStageMilestone | null;
    /**
     * 
     * @type {string}
     * @memberof JobStage
     */
    readonly schedulingEmailTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobStage
     */
    readonly rejectionEmailTemplateId?: string;
}

export function JobStageFromJSON(json: any): JobStage {
    return JobStageFromJSONTyped(json, false);
}

export function JobStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'isTakeHome': json['is_take_home'],
        'useDoverInterviewer': json['use_dover_interviewer'],
        'isSchedulable': !exists(json, 'is_schedulable') ? undefined : json['is_schedulable'],
        'stageType': HiringPipelineStageTypeFromJSON(json['stage_type']),
        'milestone': HiringPipelineStageMilestoneFromJSON(json['milestone']),
        'schedulingEmailTemplateId': !exists(json, 'scheduling_email_template_id') ? undefined : json['scheduling_email_template_id'],
        'rejectionEmailTemplateId': !exists(json, 'rejection_email_template_id') ? undefined : json['rejection_email_template_id'],
    };
}

export function JobStageToJSON(value?: JobStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'is_take_home': value.isTakeHome,
        'use_dover_interviewer': value.useDoverInterviewer,
        'stage_type': HiringPipelineStageTypeToJSON(value.stageType),
        'milestone': HiringPipelineStageMilestoneToJSON(value.milestone),
    };
}


