/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiV1SubmitUserCredentialCredentials
 */
export interface ApiV1SubmitUserCredentialCredentials {
    /**
     * 
     * @type {string}
     * @memberof ApiV1SubmitUserCredentialCredentials
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1SubmitUserCredentialCredentials
     */
    mfaCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiV1SubmitUserCredentialCredentials
     */
    apiKey?: string;
}

export function ApiV1SubmitUserCredentialCredentialsFromJSON(json: any): ApiV1SubmitUserCredentialCredentials {
    return ApiV1SubmitUserCredentialCredentialsFromJSONTyped(json, false);
}

export function ApiV1SubmitUserCredentialCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiV1SubmitUserCredentialCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': !exists(json, 'password') ? undefined : json['password'],
        'mfaCode': !exists(json, 'mfa_code') ? undefined : json['mfa_code'],
        'apiKey': !exists(json, 'api_key') ? undefined : json['api_key'],
    };
}

export function ApiV1SubmitUserCredentialCredentialsToJSON(value?: ApiV1SubmitUserCredentialCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'mfa_code': value.mfaCode,
        'api_key': value.apiKey,
    };
}


