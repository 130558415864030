import { Box, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { useForm, useFormState } from "react-final-form";
import { useParams } from "react-router-dom";

import InfoIconSVG from "assets/icons/info-icon.svg";
import { Tooltip } from "components/library/Tooltip";
import {
  FormLabelInfoIcon,
  StageDrawerFormLabel,
} from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import { removeDoverInterviewerCalendars } from "components/SetupHiringPipeline/utils";
import { useListSchedulableCalendarsQuery } from "services/doverapi/endpoints/interviewPlan";
import { SchedulableCalendar } from "services/openapi";
import { SchedulingCalendarSelect } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/EndToEndJobConfig";

export const EditSchedulingCalendar = (): React.ReactElement => {
  const form = useForm();
  const formState = useFormState();
  const { jobId } = useParams<{ jobId: string }>();
  const clientSchedulingEmail = formState.values.clientSchedulingEmail;
  const { schedulableCalendars } = useListSchedulableCalendarsQuery(jobId!, {
    selectFromResult: ({ data }) => {
      const schedulableCalendars: SchedulableCalendar[] = removeDoverInterviewerCalendars(data);
      return {
        schedulableCalendars,
      };
    },
  });

  const handleSelectSchedulableCalendar = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value) {
      form.change("schedulingCalendarGcalIdEdited", event.target.value);
    }
  };

  const defaultSchedulingCal = useMemo(() => {
    /* The primary calendar's id should match the email address (thus being equal to the email address
      on the designated client's scheduling EmailSenderAlias) */
    const primaryCal = schedulableCalendars.find(c => c.id === clientSchedulingEmail);
    return primaryCal?.id;
  }, [clientSchedulingEmail, schedulableCalendars]);

  return (
    <Box width="300px">
      <Stack direction="row" spacing={1} marginBottom="4px">
        <StageDrawerFormLabel bold>Schedule events on</StageDrawerFormLabel>
        <Tooltip
          title={`By default, events will be scheduled on ${defaultSchedulingCal}. If you want to schedule on a calendar not visible here, allow  ${clientSchedulingEmail} to “make changes to events” in Google calendar settings`}
          placement="top"
        >
          <span>
            <FormLabelInfoIcon src={InfoIconSVG} />
          </span>
        </Tooltip>
      </Stack>

      {jobId && (
        <SchedulingCalendarSelect jobId={jobId} handleSelectSchedulableCalendar={handleSelectSchedulableCalendar} />
      )}
    </Box>
  );
};
