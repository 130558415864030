import { ReactSVG } from "react-svg";
import styled from "styled-components";

interface SVGProps {
  radius: string;
}

const SVG = styled(ReactSVG)<SVGProps>`
  display: inline;
  svg {
    height: ${(props): string => props.radius};
    width: ${(props): string => props.radius};
  }
`;

export default SVG;
