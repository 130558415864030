import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import React, { useState } from "react";

import { ClickableFileViewer } from "components/FileViewer";
import { SaapReviewApplication } from "services/openapi";
import BioLoadingErrorState from "views/candidates/components/BioLoadingErrorState";
import ErrorFetchingAppsState from "views/candidates/components/ErrorFetchingAppsState";
import { ActionSection } from "views/candidates/DoverTalentNetwork/components/MainPanel/ActionSection";
import { ApplicantInfo } from "views/candidates/DoverTalentNetwork/components/MainPanel/ApplicantInfo/ApplicantInfo";
import { MainContentTabs, Tabs } from "views/candidates/DoverTalentNetwork/components/MainPanel/MainContentTabs";
import NoDTNMatches from "views/candidates/DoverTalentNetwork/components/MainPanel/NoDTNMatches";

interface MainPanelProps {
  application?: SaapReviewApplication;
  isFetching: boolean;
  errorFetchingApps?: boolean;
  noResults?: boolean;
}

export const DTNMainPanel: React.FC<MainPanelProps> = ({ application, isFetching, errorFetchingApps, noResults }) => {
  /*
    This is some legacy code that we can maybe eventually remove.
    In the current state applicants will always have a candidate object and therefore candidate id.
    But in the past we lazily created candidates and so they didn't always have a candidate object.
    So we would use the inboundAppId as the candidateId in that case and the backend would figure it out.
    Any new applicants created since ~November 2023 don't need this logic, but it's here for any legacy applications
    from before that project.
  */

  const [selectedTab, setSelectedTab] = useState<Tabs>("Resume");

  if (isFetching) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <Progress text="Loading Candidate Info..." size="large" />
      </Box>
    );
  }

  if (errorFetchingApps) {
    return <ErrorFetchingAppsState />;
  }

  if (noResults) {
    return <NoDTNMatches />;
  }

  if (!application) {
    return <BioLoadingErrorState />;
  }

  return (
    <Stack p="8px" spacing={1} sx={{ overflowY: "auto" }}>
      <ApplicantInfo application={application} />
      <ActionSection application={application} />
      <MainContentTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === "Resume" && (
        <ClickableFileViewer
          url={application?.resumePath}
          iframeHeight="900px"
          iframeContainerHeight="90vh"
          noFileText="No resume provided"
        />
      )}
      {/* {selectedTab === "Explanation" && <ExplanationTab applicationId={application?.inboundAppId} />} */}
    </Stack>
  );
};
