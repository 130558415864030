import { Box } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

import { RequiredAsterisk, StageDrawerFormLabel } from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import SubstagesInput from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Row/Substages";

export const EditSubStages = ({
  fieldPrefix,
  required,
}: {
  fieldPrefix: string;
  required: boolean;
}): React.ReactElement => {
  return (
    <Box>
      <StageDrawerFormLabel bold>Sub-stages {required && <RequiredAsterisk>*</RequiredAsterisk>}</StageDrawerFormLabel>
      <Field
        name={fieldPrefix + ".substages"}
        validate={(value): boolean => {
          return required && !value;
        }}
      >
        {(props): React.ReactElement => {
          return <SubstagesInput input={props.input} fieldPrefix={fieldPrefix} showHeaders required={required} />;
        }}
      </Field>
    </Box>
  );
};
