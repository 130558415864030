/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthedUser
 */
export interface AuthedUser {
    /**
     * 
     * @type {number}
     * @memberof AuthedUser
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthedUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthedUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthedUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthedUser
     */
    readonly name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthedUser
     */
    readonly roles?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AuthedUser
     */
    blocked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthedUser
     */
    pictureUrl?: string | null;
    /**
     * 
     * @type {object}
     * @memberof AuthedUser
     */
    roleTitleData?: object | null;
}

export function AuthedUserFromJSON(json: any): AuthedUser {
    return AuthedUserFromJSONTyped(json, false);
}

export function AuthedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthedUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'blocked': !exists(json, 'blocked') ? undefined : json['blocked'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
        'roleTitleData': !exists(json, 'role_title_data') ? undefined : json['role_title_data'],
    };
}

export function AuthedUserToJSON(value?: AuthedUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'blocked': value.blocked,
        'picture_url': value.pictureUrl,
        'role_title_data': value.roleTitleData,
    };
}


