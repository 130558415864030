/**
 * Returns label of selected option
 * @param {Object|Array} option currently selected option
 * @param {Array<Object>} options all options avaiable
 * @returns {String}
 */
export const getOptionLabel = (option, options) => {
  if (typeof option === "undefined") {
    return "";
  }

  if (option === "") {
    return "";
  }

  if (Array.isArray(option) && option.length === 0) {
    return "";
  }

  if (typeof option === "object") {
    return option.label;
  }

  const item = options.find(({ value }) => value === option);
  return (item && item.label) || "";
};
