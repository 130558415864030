import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Button, ButtonVariant } from "components/library/Button";
import { useDuplicateJobMutation } from "services/doverapi/endpoints/job/endpoints";

//------------------------------------------------------------------------------
// Component

interface DuplicateJobButtonProps {
  jobId: string;
}

const DuplicateJobButton = ({ jobId }: DuplicateJobButtonProps): React.ReactElement => {
  const [duplicateJob, { isLoading }] = useDuplicateJobMutation();
  const navigate = useNavigate();

  const handleClick = async (): Promise<void> => {
    const res = await duplicateJob({ id: jobId }).unwrap();
    if (res.success) {
      navigate(APP_ROUTE_PATHS.job.overview(res.jobId));
    }
  };
  return (
    <Button variant={ButtonVariant.Secondary} loading={isLoading} onClick={handleClick} padding="4px 8px">
      Duplicate
    </Button>
  );
};

export default DuplicateJobButton;
