import { ReactRenderer } from "@tiptap/react";
import { SuggestionKeyDownProps, SuggestionOptions, SuggestionProps } from "@tiptap/suggestion";
import tippy from "tippy.js";

import MentionList from "components/library/TipTap/MentionList";
import { BasicEmailOption, MentionOption } from "components/library/TipTap/types";

export function getSuggestionOptions(
  getOptions: () => MentionOption[],
  suggestionFooter?: React.ReactNode
): Omit<SuggestionOptions<MentionOption>, "editor"> | undefined {
  return {
    items: ({ query }): MentionOption[] => {
      const options = getOptions();
      return options.filter(option => option.label.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5);
    },
    render: (): any => {
      let component: ReactRenderer | undefined = undefined;
      let popup: any = undefined;

      return {
        onStart: (props: SuggestionProps): void => {
          component = new ReactRenderer(MentionList, {
            props: { ...props, footer: suggestionFooter },
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          const referenceClientRect = props.clientRect();

          popup = tippy("body", {
            getReferenceClientRect: referenceClientRect ? (): DOMRect => referenceClientRect : null,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },

        onUpdate(props: SuggestionProps): void {
          component?.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: SuggestionKeyDownProps): boolean {
          if (props.event.key === "Escape") {
            popup[0].hide();

            return true;
          }

          return (component?.ref as any)?.onKeyDown(props);
        },

        onExit(_: SuggestionProps): void {
          popup[0].destroy();
          component?.destroy();
        },
      };
    },
  };
}

interface GetFirstNameAndLastNameFromBasicEmailOptionsReturnType {
  firstName?: string;
  lastName?: string;
}
export function getFirstNameAndLastNameFromBasicEmailOptions(
  basicEmailOption: BasicEmailOption
): GetFirstNameAndLastNameFromBasicEmailOptionsReturnType | undefined {
  const { label } = basicEmailOption;
  if (label === undefined) {
    return undefined;
  }

  const labelSections = label.split(" ");
  return { firstName: labelSections[0], lastName: labelSections[1] };
}

export function constructFromFieldString(senderLabel: string | undefined, senderEmail: string): string {
  return senderLabel ? `${senderLabel} <${senderEmail}>` : senderEmail;
}

const templateVariableRegex = /{{\s*(.*?)\s*}}/g;

export const substituteVariables = (
  templateText: string | undefined | null,
  substitionMap: Record<string, string | undefined>
): string => {
  if (!templateText) {
    return "";
  }

  const substitutedText = templateText.replace(templateVariableRegex, (match, variable) => {
    // Check if we have a default substitution value
    if (variable in substitionMap) {
      const substitution = substitionMap[variable];
      if (substitution) {
        return substitution;
      }
    }

    // If the variable isn't in the substitution map just return back the original match
    return match;
  });

  return substitutedText;
};
