import { Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar-outlined-grey.svg";
import { ReactComponent as CircleCheckIcon } from "assets/icons/circled-green-checkmark.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useCandidateCountMap } from "hooks/useCandidateCountMap";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useListHiringPipelineStagesV2Query } from "services/doverapi/endpoints/hiringPipelineStage";
import { useListSearchesV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import {
  CandidateFilterList,
  CandidateFilterSourcingContextEnum,
  HiringPipelineStage,
  HiringPipelineStageMilestone,
  HiringPipelineStageType,
  SearchV3SearchTypeEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { QuickFilterParam, convertQuickFiltersToCountList, useGetQuickFilters } from "views/candidates/hooks";
import { QuickFilterEnum } from "views/candidates/types";
import OverviewCard from "views/job/JobSetup/steps/Overview/components/OverviewCard";
import { OverviewCardCount, OverviewCardType } from "views/job/JobSetup/steps/Overview/types";
import { useRealHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

const stageFilterBuilder = (
  stages: HiringPipelineStage[],
  stageTypes: HiringPipelineStageType[]
): CandidateFilterList[] => {
  return (
    stages
      .filter(s => s.stageType === stageTypes[0])
      .map(
        (s): CandidateFilterList => ({
          name: s.name!,
          filters: {
            pipelineStages: stages
              ?.filter(s => stageTypes.includes(s.stageType))
              .map(s => {
                return {
                  pipelineStageId: s.id,
                  pipelineSubstages: s.stageType === HiringPipelineStageType.RESPONDED ? [400] : [],
                };
              }),
            status: [],
            sourcingContext: [
              CandidateFilterSourcingContextEnum.ManuallyAdded,
              CandidateFilterSourcingContextEnum.Outbound,
            ],
          },
        })
      ) ?? []
  );
};

const getCountsFilters = (
  stages: HiringPipelineStage[],
  quickFilters: Record<QuickFilterEnum, QuickFilterParam>
): CandidateFilterList[] => {
  const initialCallHpsId = stages?.find(s => s.milestone === HiringPipelineStageMilestone.INITIAL_CALL)?.id;

  const contactedStageFilter = stageFilterBuilder(stages, [
    HiringPipelineStageType.CONTACTED,
    HiringPipelineStageType.RESPONDED,
    HiringPipelineStageType.INTERVIEW,
    HiringPipelineStageType.OFFER,
  ]);

  const interstedStageFilter = stageFilterBuilder(stages, [
    HiringPipelineStageType.RESPONDED,
    HiringPipelineStageType.INTERVIEW,
    HiringPipelineStageType.OFFER,
  ]);

  const countsFilters = [...contactedStageFilter, ...interstedStageFilter];

  // Only run needs action filter if HPSs are available
  if (initialCallHpsId) {
    const [needsActionFilter] = convertQuickFiltersToCountList([QuickFilterEnum.AllNeedsAction], quickFilters);
    needsActionFilter.filters.pipelineStages = needsActionFilter.filters.pipelineStages?.filter(
      p => p.pipelineStageId === initialCallHpsId
    );
    countsFilters.push(needsActionFilter);
  }

  return countsFilters;
};

const SourcingOverview = (): React.ReactElement => {
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);

  const jobId = useJobIdFromUrl();
  const freeCustomer = useIsBasePlanCustomer();

  const allStages = useRealHps();
  const quickFilters = useGetQuickFilters();

  const countFilters = getCountsFilters(allStages?.stages ?? [], quickFilters);
  const { counts: candidateCounts, isFetching } = useCandidateCountMap(countFilters);

  const { isLoading: isLoadingOutboundStages } = useListHiringPipelineStagesV2Query(jobId ? { jobId } : skipToken);
  const isLoadingStagesAndCounts = isFetching || isLoadingOutboundStages;

  const contactedStage = allStages?.stages?.find(stage => stage.stageType === HiringPipelineStageType.CONTACTED);
  const respondedStage = allStages?.stages?.find(stage => stage.stageType === HiringPipelineStageType.RESPONDED);
  const initialCallStage = allStages?.stages?.find(
    stage => stage.milestone === HiringPipelineStageMilestone.INITIAL_CALL
  );

  if (!jobId) {
    return <></>;
  }

  const labels: Record<string, string> = {
    Contacted: "Contacted",
    Responded: "Interested",
  };
  const candidateCountsByOutboundStage: OverviewCardCount[] = [contactedStage, respondedStage].map(stage => {
    const hpsId = stage?.id ?? "";
    const stageCount = freeCustomer ? "--" : stage?.name && !isFetching ? candidateCounts?.get(stage?.name) : undefined;
    return {
      label: stage?.name ? labels[stage?.name] : stage?.name,
      count: stageCount,
      link: APP_ROUTE_PATHS.job.candidates.candidatesTable(jobId, new URLSearchParams({ hpsId: hpsId, page: "0" })),
    };
  });

  const needsActionCount: number | "--" | undefined = freeCustomer
    ? "--"
    : !isFetching
    ? candidateCounts?.get(QuickFilterEnum.AllNeedsAction)
    : 0;

  candidateCountsByOutboundStage.push({
    label: "Needs Action",
    count: needsActionCount,
    link: APP_ROUTE_PATHS.job.candidates.candidatesTable(
      jobId,
      new URLSearchParams({ hpsId: initialCallStage?.id ?? "", page: "0" })
    ),
  });

  return (
    <OverviewCard
      title={OverviewCardType.SOURCING}
      titleIcon={<MailIcon height="16px" width="16px" />}
      cardCounts={candidateCountsByOutboundStage}
      loadingCounts={isLoadingStagesAndCounts}
      footer={
        freeCustomer || !useLegacySourcingAutopilot ? (
          <HelpWithSourcingButton />
        ) : (
          // Only show active searches footer if the user is on the legacy sourcing autopilot
          <ActiveSearchesFooter jobId={jobId} />
        )
      }
    />
  );
};

const HelpWithSourcingButton = (): React.ReactElement => {
  const calendlyUrl = useGetCalendlyUrl("app", "sourcing_autopilot", "book_a_call");
  return (
    <Stack mt="20px !important">
      <Button
        onClick={(): void => {
          window.open(calendlyUrl, "_blank", "noopener noreferrer");
        }}
        variant={ButtonVariant.SecondarySuccess}
        width="100%"
      >
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
          <CalendarIcon height="16px" width="16px" className="svg-color" color={colors.primary.base} />
          <BodySmall color={colors.primary.base}>Get help with sourcing</BodySmall>
        </Stack>
      </Button>
    </Stack>
  );
};

interface ActiveSearchesFooterProps {
  jobId: string;
}

const ActiveSearchesFooter = ({ jobId }: ActiveSearchesFooterProps): React.ReactElement => {
  const { data: outboundSearches, isFetching: isFetchingSearches } = useListSearchesV3Query(
    jobId
      ? {
          limit: 1000,
          job: jobId,
          searchTypeList: SearchV3SearchTypeEnum.Outbound,
        }
      : skipToken
  );

  const activeOutboundSearches = outboundSearches?.filter(search => !!search.active);
  const activeOutboundSearchCount = activeOutboundSearches?.length ?? 0;

  const activeSearchesFooter = (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <CircleCheckIcon width="16px" height="16px" />
      <BodySmall>
        {`${activeOutboundSearchCount} active`}{" "}
        <InternalLink to={APP_ROUTE_PATHS.job.autopilot(jobId)} $variant="secondary">
          {`search${activeOutboundSearchCount > 1 ? "es" : ""}`}
        </InternalLink>
      </BodySmall>
    </Stack>
  );

  const noActiveSearchesFooter = (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <InfoIcon />
      <BodySmall>
        No active{" "}
        <InternalLink to={APP_ROUTE_PATHS.job.autopilot(jobId)} $variant="secondary">
          searches
        </InternalLink>
      </BodySmall>
    </Stack>
  );

  if (isFetchingSearches) {
    return (
      <Skeleton
        sx={{
          padding: "8px",
          margin: "16px -8px -16px -8px !important",
        }}
      />
    );
  }

  return (
    <Stack
      borderTop={`solid 1px ${colors.grayscale.gray200}`}
      sx={{
        margin: "24px -16px -16px -16px !important",
        padding: "10px 16px !important",
      }}
    >
      {activeOutboundSearchCount > 0 ? activeSearchesFooter : noActiveSearchesFooter}
    </Stack>
  );
};

export default SourcingOverview;
