import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { EmailOwner, EmailUser } from "components/library/TipTap/types";

// I don't like hardcoding this but unfortunately the api doesn't include this currently
export const DOVER_INTERVIEWER: Interviewer = {
  label: "Dover Interviewer",
  value: "DOVER_INTERVIEWER_ID",
  fullName: "Dover Interviewer",
  hasSchedulingLinkOverride: false,
  preferencesComplete: true,
};

// This is the initial empty state for the interviewer select
export const EMPTY_INTERVIEWER: Interviewer = {
  label: "",
  value: "",
  hasSchedulingLinkOverride: false,
  preferencesComplete: false,
};

// This is the initial empty state for the email sender field aka "from"
export const EMPTY_EMAIL_USER: EmailUser = {
  id: "",
  email: "",
  owner: EmailOwner.AuthedUser,
  isAuthed: false,
};
