/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20074
 */
export interface InlineResponse20074 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse20074
     */
    nextStage: InlineResponse20074NextStageEnum;
}

/**
* @export
* @enum {string}
*/
export enum InlineResponse20074NextStageEnum {
    Connected = 'CONNECTED',
    LostConnection = 'LOST_CONNECTION',
    Login = 'LOGIN',
    MfaText = 'MFA_TEXT',
    MfaEmail = 'MFA_EMAIL'
}

export function InlineResponse20074FromJSON(json: any): InlineResponse20074 {
    return InlineResponse20074FromJSONTyped(json, false);
}

export function InlineResponse20074FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20074 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextStage': json['next_stage'],
    };
}

export function InlineResponse20074ToJSON(value?: InlineResponse20074 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_stage': value.nextStage,
    };
}


