/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeAppScoringEnabledRequest
 */
export interface ChangeAppScoringEnabledRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeAppScoringEnabledRequest
     */
    desiredInboundEnrichingEnabled: boolean;
}

export function ChangeAppScoringEnabledRequestFromJSON(json: any): ChangeAppScoringEnabledRequest {
    return ChangeAppScoringEnabledRequestFromJSONTyped(json, false);
}

export function ChangeAppScoringEnabledRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeAppScoringEnabledRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'desiredInboundEnrichingEnabled': json['desired_inbound_enriching_enabled'],
    };
}

export function ChangeAppScoringEnabledRequestToJSON(value?: ChangeAppScoringEnabledRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'desired_inbound_enriching_enabled': value.desiredInboundEnrichingEnabled,
    };
}


