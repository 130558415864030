/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dataclass,
    DataclassFromJSON,
    DataclassFromJSONTyped,
    DataclassToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserRolesAndPermissions
 */
export interface UserRolesAndPermissions {
    /**
     * 
     * @type {number}
     * @memberof UserRolesAndPermissions
     */
    proUserId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRolesAndPermissions
     */
    globalRoles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRolesAndPermissions
     */
    globalPermissions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRolesAndPermissions
     */
    featureFlags: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<Dataclass>; }}
     * @memberof UserRolesAndPermissions
     */
    jobRoles: { [key: string]: Array<Dataclass>; };
}

export function UserRolesAndPermissionsFromJSON(json: any): UserRolesAndPermissions {
    return UserRolesAndPermissionsFromJSONTyped(json, false);
}

export function UserRolesAndPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRolesAndPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proUserId': json['pro_user_id'],
        'globalRoles': json['global_roles'],
        'globalPermissions': json['global_permissions'],
        'featureFlags': json['feature_flags'],
        'jobRoles': json['job_roles'],
    };
}

export function UserRolesAndPermissionsToJSON(value?: UserRolesAndPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pro_user_id': value.proUserId,
        'global_roles': value.globalRoles,
        'global_permissions': value.globalPermissions,
        'feature_flags': value.featureFlags,
        'job_roles': value.jobRoles,
    };
}


