import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import styled from "styled-components";

import { LoginErrorCodes } from "App/appConstants";
import DoverLogo from "components/icons/DoverLogo";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import { useAuth0 } from "services/react-auth0-spa";

// Ported from our backend
// TODO(jh): Refactor into more canonical styled components
const UnauthorizedBox = styled(Box)`
  .form-signin {
    width: 100%;
    max-width: 640px;
    padding: 15px;
    margin: 0 auto;
  }
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
  }
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
  .form-label-group input::placeholder {
    color: transparent;
  }
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
  }
  .paragraph-bigger.cc-bigger-light {
    opacity: 1;
    font-family: neue-haas-grotesk-text, sans-serif;
    color: #312f4a;
    font-size: 24px;
    line-height: 110%;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
  }
`;

const UnauthorizedMessage = ({ loginFailureErrorCode }: { loginFailureErrorCode: string }): React.ReactElement => {
  const SupportEmailLink = useMemo(
    () => (
      <a href="mailto:support@dover.com?Subject=Login%20issue" target="_top">
        support@dover.com
      </a>
    ),
    []
  );

  const TalkToUseLink = useMemo(() => <a href="https://www.dover.com/talk-to-us">here</a>, []);

  let message;
  switch (loginFailureErrorCode) {
    case LoginErrorCodes.ClientNotFound:
      message = (
        <>
          {"Whoops! Looks like you don't have an account with Dover yet. Talk to our team "}
          {TalkToUseLink}
          {" to get set up."}
        </>
      );
      break;
    case LoginErrorCodes.BlockedDomain:
      message = (
        <>
          {
            "Whoops! It looks like you’re using a personal email. Please log in with your work email or reach out to us at "
          }
          {SupportEmailLink}
          {"."}
        </>
      );
      break;
    case LoginErrorCodes.UserIsBlocked:
      message = (
        <>
          {"Oops! Looks like your Dover account has been deactivated. Please reach out to an admin to regain access."}
        </>
      );
      break;
    case LoginErrorCodes.NoAccess:
      message = (
        <>
          <Body weight="600">Unable to create your account</Body>
          <Body style={{ marginBottom: "8px" }}>
            Due to our stringent security protocols aimed at ensuring a trusted network of business users, we are unable
            to create your account based on the domain of your email address.
          </Body>
          <Body>Next Steps:</Body>
          <Body>1. Make sure you&apos;re using your company email domain for signup.</Body>
          <Body>
            2. If you&apos;re still encountering this issue or believe this is an error, please reach out to our support
            team at {SupportEmailLink}. Please provide your full name, company name and LinkedIn page, your company
            website, and any additional details to expedite the process.
          </Body>
          <Body>
            3. Once you&apos;ve contacted our support team, we&apos;ll review your account and follow up within 1-2
            business days.
          </Body>
        </>
      );
      break;
    case LoginErrorCodes.MissingSession:
      message = (
        <>
          <Body>{'Whoops! We hit an unexpected error, or you hit "Continue" twice.'}</Body>
          <br></br>
          <Body>
            Please try again by clicking the button below. If this error persists, please email {SupportEmailLink}.
          </Body>
        </>
      );
      break;
    case LoginErrorCodes.MissingEmail:
    case LoginErrorCodes.Unknown:
    default:
      message = (
        <>
          {"Whoops! An unknown error occurred. Please try again or reach out to us at "}
          {SupportEmailLink}
          {"."}
        </>
      );
      break;
  }
  return message;
};

const Unauthorized = ({ errorCodeOverride }: { errorCodeOverride?: string }): React.ReactElement => {
  const { loginFailureErrorCode, logout } = useAuth0();

  return (
    <div>
      <UnauthorizedBox display="flex" justifyContent="center" m="auto">
        <form className="form-signin">
          <Box sx={{ paddingBottom: "20px" }}>
            <DoverLogo width={120} />
          </Box>
          <div className="alert alert-danger" role="alert">
            <UnauthorizedMessage loginFailureErrorCode={errorCodeOverride || loginFailureErrorCode} />
          </div>
          <Button
            width={"100%"}
            variant={ButtonVariant.Secondary}
            onClick={(e): void => {
              e.preventDefault();
              logout({
                returnTo: window.location.origin,
              });
            }}
          >
            Try again or log in with a different account
          </Button>
        </form>
      </UnauthorizedBox>
    </div>
  );
};

export default Unauthorized;
