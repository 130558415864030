import { INVALID_PERMS_TOOLTIP_TEXT } from "components/constants";
import { useHasElevatedPermissions } from "components/RBAC";

export const useDisableCandidateActionsTooltipText = (jobId?: string): string | undefined => {
  const hasValidCandidateActionPerms = useHasElevatedPermissions(jobId);

  if (!hasValidCandidateActionPerms) {
    return INVALID_PERMS_TOOLTIP_TEXT;
  }

  return undefined;
};
