import { Select, TextField, Checkbox, Radio, Autocomplete } from "@mui/material";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { colors, baseColors } from "styles/theme";

export const WhiteCard = styled.div`
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${colors.grayscale.gray200};
  padding: 24px 32px;
`;

export const B2 = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledSelect = styled(Select)`
  .MuiSelect-select {
    border-radius: 2px;
    padding: 8px;
    min-width: 64px;
  }
  fieldset {
    legend {
      width: unset;
    }
  }
  ::after,
  ::before {
    border-bottom: 0;
  }
`;

export const StyledPronounsSelect = styled(StyledSelect)`
  .MuiSelect-select {
    min-width: 144px;
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    border-radius: 2px;
    padding: 8px;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    border: 0;
  }
  fieldset {
    legend {
      width: unset;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  svg {
    fill: ${colors.grayscale.gray200};
  }
  &.Mui-checked {
    svg {
      fill: ${colors.decorative.highlightBlue};
    }
  }
`;

export const DividingLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d8e1e5;
`;

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.decorative.highlightBlue};
  }
`;

export const RequiredAsterisk = styled.span`
  &::before {
    content: "*";
    color: ${baseColors.critical};
  }
`;

export const StyledSVG = styled(ReactSVG)`
  display: inline;
  svg {
    height: 16px;
    width: 16px;
    margin-right: 12px;
  }
`;

export const StyledConnectIcon = styled(ReactSVG)`
  svg {
    width: 100%;
    object-fit: contain;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  fieldset {
    legend {
      width: unset;
    }
  }
`;

export const StyledTimeSelect = styled(Select)`
  .MuiSelect-select {
    border-radius: 2px;
    padding: 8px;
    min-width: 64px;
  }
  fieldset {
    legend {
      width: unset;
    }
  }
`;
