import { Skeleton, Stack } from "@mui/material";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";

import { useGetDisableCandidateStageOrStatusSelect } from "components/dover/CandidateStageSelect/hooks";
import { StyledMenuItem, StyledSelect } from "components/dover/CandidateStageSelect/styles";
import {
  getStageOptions,
  getIcon,
  StageSelectValue,
  findCandidateStageFromOptions,
} from "components/dover/CandidateStageSelect/utils";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import { Role, useHasRole } from "components/RBAC";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetCandidateBioQuery, useUpdateCandidateBioMutation } from "services/doverapi/endpoints/candidate";
import { useRealHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

export const CandidateStageSelect = ({
  candidateId,
  compact = false,
}: {
  candidateId: string;
  compact?: boolean;
}): React.ReactElement => {
  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const { data: candidateBio, isLoading: isLoadingCandidateBio } = useGetCandidateBioQuery(candidateId);
  const [updateCandidateBio] = useUpdateCandidateBioMutation();

  const jobId = useJobIdFromUrl();
  const isAdmin = useHasRole(Role.ADMIN);

  const { stages, isFetching: isFetchingHpss } = useRealHps();

  const stageOptions = stages ? getStageOptions({ stages, mainStagesOnly: !isAdmin }) : [];

  const { disabled, tooltipText } = useGetDisableCandidateStageOrStatusSelect(candidateBio, jobId);

  const handleUpdateStage = (newVal: StageSelectValue): void => {
    // ensure we close the Select popup after a selection is made
    setOpenSelect(false);
    updateCandidateBio({
      id: candidateBio?.id!,
      jobId: candidateBio?.job,
      data: { currentPipelineStage: newVal.id, currentPipelineSubstage: newVal.substage },
      hideToast: false,
    });
  };

  if (isLoadingCandidateBio || isFetchingHpss) {
    return <Skeleton height="40px" width="100px" />;
  }

  if (!candidateBio || !candidateBio.id) {
    return <></>;
  }

  // candidate's current stage as a StageSelectValue
  const currentPipelineStage = candidateBio?.candidatePipelineStage
    ? findCandidateStageFromOptions({
        stages: stageOptions,
        candidatePipelineStage: candidateBio?.candidatePipelineStage,
      })
    : undefined;

  const pipelineStageName = !currentPipelineStage ? "Change stage" : currentPipelineStage.name;

  const icon = currentPipelineStage ? getIcon(currentPipelineStage) : undefined;

  return (
    <Tooltip title={tooltipText}>
      <Stack onClick={(e): void => e.stopPropagation()}>
        <StyledSelect
          compact={compact}
          disabled={disabled}
          value={pipelineStageName}
          variant="outlined"
          open={openSelect}
          onOpen={(): void => setOpenSelect(true)}
          onClose={(): void => setOpenSelect(false)}
          displayEmpty={true}
          renderValue={(): React.ReactNode => {
            if (compact) {
              return <BodyExtraSmall ellipsis>{pipelineStageName}</BodyExtraSmall>;
            }

            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                {!!icon && <ReactSVG src={icon} />}
                <BodySmall ellipsis>{pipelineStageName}</BodySmall>
              </Stack>
            );
          }}
        >
          {stageOptions.map(stageObj => {
            return (
              <StyledMenuItem
                onClick={(): void => {
                  handleUpdateStage(stageObj);
                }}
                className={
                  currentPipelineStage &&
                  stageObj.id === currentPipelineStage.id &&
                  stageObj.substage === currentPipelineStage.substage
                    ? "active"
                    : ""
                }
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ReactSVG src={getIcon(stageObj) ?? ""} />
                  <BodyExtraSmall>{stageObj.name}</BodyExtraSmall>
                </Stack>
              </StyledMenuItem>
            );
          })}
        </StyledSelect>
      </Stack>
    </Tooltip>
  );
};
