/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactSocialLink,
    ContactSocialLinkFromJSON,
    ContactSocialLinkFromJSONTyped,
    ContactSocialLinkToJSON,
} from './';

/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    headline?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    location?: string;
    /**
     * 
     * @type {Array<ContactSocialLink>}
     * @memberof Contact
     */
    socialLinks?: Array<ContactSocialLink>;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    primaryEmailId?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    primaryPhoneNumberId?: string;
}

export function ContactFromJSON(json: any): Contact {
    return ContactFromJSONTyped(json, false);
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'socialLinks': !exists(json, 'social_links') ? undefined : ((json['social_links'] as Array<any>).map(ContactSocialLinkFromJSON)),
        'primaryEmailId': !exists(json, 'primary_email_id') ? undefined : json['primary_email_id'],
        'primaryPhoneNumberId': !exists(json, 'primary_phone_number_id') ? undefined : json['primary_phone_number_id'],
    };
}

export function ContactToJSON(value?: Contact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full_name': value.fullName,
        'preferred_name': value.preferredName,
        'headline': value.headline,
        'location': value.location,
        'social_links': value.socialLinks === undefined ? undefined : ((value.socialLinks as Array<any>).map(ContactSocialLinkToJSON)),
        'primary_email_id': value.primaryEmailId,
        'primary_phone_number_id': value.primaryPhoneNumberId,
    };
}


