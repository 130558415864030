import { Stack, TextField } from "@mui/material";
import React from "react";
import { useField, useForm } from "react-final-form";

import { Subtitle2 } from "components/library/typography";

export const SchedulingLinkInput: React.FC = () => {
  const form = useForm();
  const schedulingLinkField = useField("schedulingLinkOverride");

  return (
    <Stack spacing={1}>
      <Subtitle2 weight="500">Link</Subtitle2>
      <TextField
        size="small"
        placeholder="Input link"
        value={schedulingLinkField.input.value}
        onChange={(e): void => form.change(schedulingLinkField.input.name, e.target.value)}
      />
    </Stack>
  );
};
