import { INVALID_PERMS_TOOLTIP_TEXT } from "components/constants";
import { Role, useContainsRole, useHasRole } from "components/RBAC";
import { useCandidateIsOfferOrHired } from "hooks/useCandidateIsOfferOrHired";
import { useDisableAtsControls } from "hooks/useDisableAtsControls";
import { CandidateBio, CandidateBioStatusEnum } from "services/openapi";

interface StageSelectorDisabled {
  disabled: boolean;
  tooltipText?: string;
}

export const useGetDisableCandidateStageOrStatusSelect = (
  candidateBio: CandidateBio | undefined,
  jobId?: string
): StageSelectorDisabled => {
  const isAdmin = useHasRole(Role.ADMIN);

  const candidateIsOfferOrHired = useCandidateIsOfferOrHired(candidateBio);

  const allowedRoles = candidateIsOfferOrHired
    ? [Role.ADMIN, Role.CLIENT_ADMIN, Role.HIRING_MANAGER, Role.RECRUITER]
    : [Role.ADMIN, Role.CLIENT_ADMIN, Role.HIRING_MANAGER, Role.RECRUITER, Role.HIRING_TEAM];

  const invalidCandidateActionPerms = !useContainsRole(allowedRoles, jobId);

  const disableAtsControls = useDisableAtsControls(candidateBio?.id);

  if (!candidateBio) {
    return { disabled: true, tooltipText: "Candidate not found" };
  }

  /** Admin Override: Always Enable Stage/Status Change */
  if (isAdmin) {
    return { disabled: false };
  }

  /** Always disable if user has insufficient global/job-level perms */
  if (invalidCandidateActionPerms) {
    return { disabled: true, tooltipText: INVALID_PERMS_TOOLTIP_TEXT };
  }

  // Never allow stage/status select for dover managed scheduling
  if (disableAtsControls) {
    return {
      disabled: true,
      tooltipText: "Dover will update interview stages when you schedule or reject this candidate.",
    };
  }

  /** Else default to enabled
   */
  return { disabled: false };
};

export const useGetDisableCandidateStageSelect = (
  candidateBio: CandidateBio | undefined,
  jobId?: string
): StageSelectorDisabled => {
  const { disabled, tooltipText } = useGetDisableCandidateStageOrStatusSelect(candidateBio, jobId);

  if (disabled) {
    return { disabled, tooltipText };
  }

  // do additional check for stage select
  // if candidate is hired, we should disable stage select
  if (candidateBio?.status === CandidateBioStatusEnum.Hired) {
    return { disabled: true, tooltipText: "Cannot update stage when status is `Hired`" };
  }

  return { disabled: false };
};
