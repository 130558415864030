import { Button } from "@doverhq/dover-ui";
import { Box, Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useBoolean } from "react-use";

import { ReactComponent as AISparklesIcon } from "assets/icons/ai-sparkles-solid.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";
import { ReactComponent as PencilIcon } from "assets/icons/pencil-edit.svg";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import Toggle from "components/library/Toggle";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import CustomModal, { CustomModalProps } from "components/Modal";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import {
  useGetClientInterviewerForUserQuery,
  useUpdateClientInterviewerMutation,
} from "services/doverapi/endpoints/interviewer";
import { colors } from "styles/theme";

interface SummaryFormattingModalProps extends Pick<CustomModalProps, "open" | "onClose"> {}

const SummaryFormattingModal = ({ open, onClose }: SummaryFormattingModalProps): React.ReactElement => {
  const [summaryFormatting, setSummaryFormatting] = useState<string>("");

  const { data: clientInterviewer, isSuccess: isSuccessLoadingInterviewer } = useGetClientInterviewerForUserQuery();
  const { id: interviewerId, email, client, aiNotetakerCustomFormatInstructions } = clientInterviewer || {};
  const [updateClientInterviewer, { isLoading: isUpdatingClientInterviewer }] = useUpdateClientInterviewerMutation();

  useEffect(() => {
    // Initialize the text field state when we have the client interviewer data
    if (isSuccessLoadingInterviewer && aiNotetakerCustomFormatInstructions) {
      setSummaryFormatting(aiNotetakerCustomFormatInstructions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessLoadingInterviewer]);

  const handleUpdateSummaryFormatting = async (): Promise<void> => {
    if (!interviewerId || !email || !client) return;
    await updateClientInterviewer({
      id: interviewerId,
      data: { email, client, aiNotetakerCustomFormatInstructions: summaryFormatting },
    });
    onClose?.();
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title="Summary Formatting"
      maxWidth="sm"
      dialogActions={
        <Stack direction="row" spacing={1}>
          {/* @ts-ignore */}
          <Button onPress={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onPress={handleUpdateSummaryFormatting}
            variant="primaryFilled"
            isLoading={isUpdatingClientInterviewer}
          >
            Save
          </Button>
        </Stack>
      }
    >
      <Stack spacing={1}>
        <BodySmall color={colors.grayscale.gray600}>
          Provide instructions on how you would like your AI notes to be formatted
        </BodySmall>
        <TextField
          text={summaryFormatting}
          onTextUpdated={setSummaryFormatting}
          multiline
          minRows={4}
          fullWidth
          placeholderText="- Use bullets&#013;- Provide summary, pros, cons, and any potential concerns."
        />
      </Stack>
    </CustomModal>
  );
};

const AINotetakerSettings = (): React.ReactElement => {
  // Gating prompting config separate from general config during testing period
  const showPromptingConfig = useFeatureFlag(FeatureFlag.AINotetakerUIConfig);
  const [enableAINotetakerToggled, setEnableAINotetakerToggled] = useBoolean(false);
  const [isModalOpen, setIsModalOpen] = useBoolean(false);

  const { data: clientInterviewer, isSuccess: isSuccessLoadingInterviewer } = useGetClientInterviewerForUserQuery();
  const { id: interviewerId, enableAiNotetakerForAllInterviews, email, client } = clientInterviewer || {};

  const [updateClientInterviewer, { isLoading: isUpdatingClientInterviewer }] = useUpdateClientInterviewerMutation();

  useEffect(() => {
    // Initialize the toggle state when we have the client interviewer data
    if (isSuccessLoadingInterviewer && enableAiNotetakerForAllInterviews !== undefined) {
      setEnableAINotetakerToggled(enableAiNotetakerForAllInterviews);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessLoadingInterviewer]);

  const handleToggleAINotetaker = async (): Promise<void> => {
    if (!interviewerId || !email || !client) return;

    setEnableAINotetakerToggled(!enableAINotetakerToggled);
    await updateClientInterviewer({
      id: interviewerId,
      data: { email, client, enableAiNotetakerForAllInterviews: !enableAINotetakerToggled },
    });
  };

  // If we don't have the necessary client interviewer data once loaded, we don't render anything
  if (isSuccessLoadingInterviewer && (!interviewerId || !email || !client)) {
    return <></>;
  }

  return (
    <Card>
      {showPromptingConfig && <SummaryFormattingModal open={isModalOpen} onClose={(): void => setIsModalOpen(false)} />}
      <Stack spacing={1}>
        <Box>
          <Stack direction="row" spacing={0.5} alignItems="center" mb={0.5}>
            <AISparklesIcon width="16px" height="16px" />
            <Body weight="600">AI Notetaker</Body>
          </Stack>
          <BodySmall color={colors.grayscale.gray600}>
            {
              "AI Notetaker can join your interviews scheduled through Dover and provide you with a recording, transcript, and notes for each interview."
            }
          </BodySmall>
        </Box>
        <Box border={`1px solid ${colors.grayscale.gray200}`} borderRadius="6px" px={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" py={1}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <BodySmall>Auto-join interviews</BodySmall>
              <Tooltip title="AI Notetaker will automatically join interviews scheduled through Dover">
                <InfoIcon />
              </Tooltip>
            </Stack>
            {isSuccessLoadingInterviewer && (
              <Toggle
                label="Enable AI Notetaker"
                checked={enableAINotetakerToggled}
                onChange={handleToggleAINotetaker}
                disabled={isUpdatingClientInterviewer}
              />
            )}
          </Stack>
          {showPromptingConfig && (
            <>
              <Divider />
              <Stack direction="row" justifyContent="space-between" py={1}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <BodySmall>Summary formatting</BodySmall>
                  <Tooltip title="Configure the format of your AI Notes">
                    <InfoIcon />
                  </Tooltip>
                </Stack>
                <Stack
                  direction="row"
                  spacing={0.5}
                  alignItems="center"
                  onClick={(): void => setIsModalOpen(true)}
                  sx={{ cursor: "pointer" }}
                >
                  <PencilIcon />
                  <BodySmall>Edit</BodySmall>
                </Stack>
              </Stack>
            </>
          )}
        </Box>
      </Stack>
    </Card>
  );
};

export default AINotetakerSettings;
