/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SaapReviewApplication,
    SaapReviewApplicationFromJSON,
    SaapReviewApplicationFromJSONTyped,
    SaapReviewApplicationToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListApplicationsReturn
 */
export interface ListApplicationsReturn {
    /**
     * 
     * @type {number}
     * @memberof ListApplicationsReturn
     */
    totalCount: number;
    /**
     * 
     * @type {Array<SaapReviewApplication>}
     * @memberof ListApplicationsReturn
     */
    applications: Array<SaapReviewApplication>;
}

export function ListApplicationsReturnFromJSON(json: any): ListApplicationsReturn {
    return ListApplicationsReturnFromJSONTyped(json, false);
}

export function ListApplicationsReturnFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListApplicationsReturn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['total_count'],
        'applications': ((json['applications'] as Array<any>).map(SaapReviewApplicationFromJSON)),
    };
}

export function ListApplicationsReturnToJSON(value?: ListApplicationsReturn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_count': value.totalCount,
        'applications': ((value.applications as Array<any>).map(SaapReviewApplicationToJSON)),
    };
}


