// Subset of our Auth0 domain blocklist
export const KNOWN_PERSONAL_DOMAINS = [
  "aol.com",
  "aol.com",
  "att.net",
  "bellsouth.net",
  "comcast.net",
  "cox.net",
  "earthlink.net",
  "fastmail.com",
  "foxmail.com",
  "gmail.com",
  "gmx.com",
  "gmx.de",
  "hotmail.co.uk",
  "icloud.com",
  "live.com",
  "mac.com",
  "mail.com",
  "mail.ru",
  "me.com",
  "msn.com",
  "pacbell.net",
  "pm.me",
  "pobox.com",
  "protonmail.ch",
  "protonmail.com",
  "qq.com",
  "qq.com",
  "rocketmail.com",
  "sbcglobal.net",
  "stanfordalumni.org",
  "verizon.net",
  "yahoo.ca",
  "yahoo.co.in",
  "yahoo.co.uk",
  "yahoo.com",
  "yahoo.in",
  "yandex.com",
  "yandex.ru",
  "ymail.com",
  "zohomail.com",
];
