/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SchedulingLink
 */
export interface SchedulingLink {
    /**
     * 
     * @type {string}
     * @memberof SchedulingLink
     */
    link: string | null;
}

export function SchedulingLinkFromJSON(json: any): SchedulingLink {
    return SchedulingLinkFromJSONTyped(json, false);
}

export function SchedulingLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulingLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'link': json['link'],
    };
}

export function SchedulingLinkToJSON(value?: SchedulingLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'link': value.link,
    };
}


