import { Box } from "@mui/material";
import React from "react";

import { JobReferralTabPaths } from "App/routing/types";
import { Spacer } from "components/Spacer";
import { JobReferralTriagingStatusEnum } from "services/openapi";
import {
  threeKeysToRecruitingFromYourNetworkUrl,
  exampleReferralTemplate,
} from "views/Referrals/ReviewReferrals/constants";

type TabSubTextGetter = () => React.ReactNode;

export interface TriageTabConfig {
  label: string;
  urlPath: string;
  tabSubTextHiringManager: TabSubTextGetter;
  tabSubTextEmployee: TabSubTextGetter;
}

export const triageTabConfigs: Readonly<{ [triagingStatus in JobReferralTriagingStatusEnum]: TriageTabConfig }> = {
  [JobReferralTriagingStatusEnum.NeedsReview]: {
    label: "Needs Review",
    urlPath: JobReferralTabPaths.NeedsReview,
    tabSubTextHiringManager: (): React.ReactNode => (
      <Box>
        <Box>{`Dover has shortlisted candidates for you.`}</Box>
        <Spacer height="20px" />
        <Box>{`Select whether you'll reach out yourself, or if you'd prefer the hiring manager reach out. When reviewing referrals, candidates should be evaluated on job fit and culture fit.`}</Box>
      </Box>
    ),
    tabSubTextEmployee: (): React.ReactNode => (
      <Box>
        <Box>{`Dover has shortlisted candidates for you.`}</Box>
        <Spacer height="20px" />
        <Box>{`Select whether you'll reach out yourself, or if you'd prefer the hiring manager reach out. When reviewing referrals, candidates should be evaluated on job fit and culture fit.`}</Box>
      </Box>
    ),
  },
  [JobReferralTriagingStatusEnum.NeedsManualOutreach]: {
    label: "Needs Manual Outreach",
    urlPath: JobReferralTabPaths.NeedsManualOutreach,
    tabSubTextHiringManager: (): React.ReactNode => (
      <Box>
        <div>{`💡 These are connections that you or hiring managers have indicated you should reach out to manually.`}</div>
        <Spacer height="20px" />
        <div>
          {`See `}
          <a href={exampleReferralTemplate} target="_blank" rel="noopener noreferrer">
            here
          </a>
          {` for an example template to use. For additional tips on how to engage your friends and acquaintances about joining your company, see `}
          <a href={threeKeysToRecruitingFromYourNetworkUrl} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </div>
      </Box>
    ),
    tabSubTextEmployee: (): React.ReactNode => (
      <Box>
        <div>{`💡 These are connections that you or hiring managers have indicated you should reach out to manually.`}</div>
        <Spacer height="20px" />
        <div>
          {`See `}
          <a href={exampleReferralTemplate} target="_blank" rel="noopener noreferrer">
            here
          </a>
          {` for an example template to use. For additional tips on how to engage your friends and acquaintances about joining your company, see `}
          <a href={threeKeysToRecruitingFromYourNetworkUrl} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </div>
      </Box>
    ),
  },
  [JobReferralTriagingStatusEnum.Contacted]: {
    label: "Contacted",
    urlPath: JobReferralTabPaths.Contacted,
    tabSubTextHiringManager: (): React.ReactNode => null,
    tabSubTextEmployee: (): React.ReactNode => null,
  },
  [JobReferralTriagingStatusEnum.Rejected]: {
    label: "Don't Reach Out",
    urlPath: JobReferralTabPaths.DontReachOut,
    tabSubTextHiringManager: (): React.ReactNode => null,
    tabSubTextEmployee: (): React.ReactNode => null,
  },
};

export const defaultTriageTab = triageTabConfigs[JobReferralTriagingStatusEnum.NeedsReview].urlPath;
