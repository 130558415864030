import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import React from "react";

import { Body, Subtitle1 } from "components/library/typography";
import { useModal } from "GlobalOverlays/atoms";
import { HEADER_HEIGHT, HELP_DOC_HREFS } from "sections/addcandidate/data/misc";
import { addCandidateV2DrawerAtom } from "sections/addCandidateV2";
import { colors } from "styles/theme";

const DrawerHeader = (): React.ReactElement => {
  const { close } = useModal(addCandidateV2DrawerAtom);

  const theme = useTheme();

  return (
    <Box sx={{ position: "sticky", top: 0, height: HEADER_HEIGHT, zIndex: 1400 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "100%", pl: 3, pr: 3, pt: 0.5, pb: 0.5 }}
        borderBottom={1}
        borderColor={colors.grayscale.gray200}
      >
        {/* Title */}
        <Subtitle1 color={colors.grayscale.gray400}>Add Candidate</Subtitle1>

        {/* Left Side: Help & Close button */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <MuiButton
            variant="text"
            startIcon={<HelpOutlineRoundedIcon />}
            sx={{ textTransform: "none" }}
            href={HELP_DOC_HREFS.ADD_CANDIDATE}
            target="_blank"
          >
            <Body color={theme.palette.primary.dark}>Help</Body>
          </MuiButton>
          <IconButton aria-label="close" onClick={close}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default DrawerHeader;
