/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HiringPipelineStage,
    HiringPipelineStageFromJSON,
    HiringPipelineStageFromJSONTyped,
    HiringPipelineStageToJSON,
} from './';

/**
 * 
 * @export
 * @interface HiringPipelineStageListResponse
 */
export interface HiringPipelineStageListResponse {
    /**
     * 
     * @type {number}
     * @memberof HiringPipelineStageListResponse
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStageListResponse
     */
    next: string | null;
    /**
     * 
     * @type {string}
     * @memberof HiringPipelineStageListResponse
     */
    previous: string | null;
    /**
     * 
     * @type {Array<HiringPipelineStage>}
     * @memberof HiringPipelineStageListResponse
     */
    results: Array<HiringPipelineStage>;
}

export function HiringPipelineStageListResponseFromJSON(json: any): HiringPipelineStageListResponse {
    return HiringPipelineStageListResponseFromJSONTyped(json, false);
}

export function HiringPipelineStageListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HiringPipelineStageListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'],
        'previous': json['previous'],
        'results': ((json['results'] as Array<any>).map(HiringPipelineStageFromJSON)),
    };
}

export function HiringPipelineStageListResponseToJSON(value?: HiringPipelineStageListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(HiringPipelineStageToJSON)),
    };
}


