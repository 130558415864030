/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationPortalJob,
    ApplicationPortalJobFromJSON,
    ApplicationPortalJobFromJSONTyped,
    ApplicationPortalJobToJSON,
    CandidateBio,
    CandidateBioFromJSON,
    CandidateBioFromJSONTyped,
    CandidateBioToJSON,
    CandidateFacingMultipartInterview,
    CandidateFacingMultipartInterviewFromJSON,
    CandidateFacingMultipartInterviewFromJSONTyped,
    CandidateFacingMultipartInterviewToJSON,
    InterviewStatusPointOfContact,
    InterviewStatusPointOfContactFromJSON,
    InterviewStatusPointOfContactFromJSONTyped,
    InterviewStatusPointOfContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewStatus
 */
export interface InterviewStatus {
    /**
     * 
     * @type {CandidateBio}
     * @memberof InterviewStatus
     */
    candidateBio?: CandidateBio;
    /**
     * 
     * @type {ApplicationPortalJob}
     * @memberof InterviewStatus
     */
    job: ApplicationPortalJob;
    /**
     * 
     * @type {CandidateFacingMultipartInterview}
     * @memberof InterviewStatus
     */
    activeInterview: CandidateFacingMultipartInterview;
    /**
     * 
     * @type {InterviewStatusPointOfContact}
     * @memberof InterviewStatus
     */
    pointOfContact: InterviewStatusPointOfContact;
    /**
     * 
     * @type {Date}
     * @memberof InterviewStatus
     */
    processStartedDate: Date;
}

export function InterviewStatusFromJSON(json: any): InterviewStatus {
    return InterviewStatusFromJSONTyped(json, false);
}

export function InterviewStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateBio': !exists(json, 'candidate_bio') ? undefined : CandidateBioFromJSON(json['candidate_bio']),
        'job': ApplicationPortalJobFromJSON(json['job']),
        'activeInterview': CandidateFacingMultipartInterviewFromJSON(json['active_interview']),
        'pointOfContact': InterviewStatusPointOfContactFromJSON(json['point_of_contact']),
        'processStartedDate': (new Date(json['process_started_date'])),
    };
}

export function InterviewStatusToJSON(value?: InterviewStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_bio': CandidateBioToJSON(value.candidateBio),
        'job': ApplicationPortalJobToJSON(value.job),
        'active_interview': CandidateFacingMultipartInterviewToJSON(value.activeInterview),
        'point_of_contact': InterviewStatusPointOfContactToJSON(value.pointOfContact),
        'process_started_date': (value.processStartedDate.toISOString()),
    };
}


