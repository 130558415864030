import { Box } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { useCandidateId } from "hooks/useCandidateId";
import { useCancelEmailMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { ActivityFeedItem } from "services/openapi";
import { showErrorToast, showSuccessToast } from "utils/showToast";

export const CancelEmailButton = ({ event }: { event: ActivityFeedItem }): React.ReactElement => {
  const [cancelEmail] = useCancelEmailMutation();
  const candidateId = useCandidateId();

  const handleCancelEmail = async (): Promise<void> => {
    if (candidateId && event.esrId) {
      try {
        const res = await cancelEmail({
          id: candidateId,
          data: {
            esrId: event.esrId,
          },
        }).unwrap();

        if (res.success) {
          showSuccessToast("Email has been cancelled successfully");
        } else {
          if (res.message) {
            showErrorToast(res.message);
          } else {
            showErrorToast("Was unable to cancel email");
          }
        }
      } catch (e) {
        showErrorToast("Was unable to cancel email");
      }
    }
  };

  return (
    <Box marginTop="8px" className="reply-button">
      <Button
        variant={ButtonVariant.Secondary}
        onClick={(e): void => {
          e.stopPropagation();
          handleCancelEmail();
        }}
      >
        Cancel queued email
      </Button>
    </Box>
  );
};
