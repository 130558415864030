import { Icon } from "@doverhq/dover-ui";
import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { FC, ReactElement } from "react";

import { candidateBioSocialLinkToDisplayMapV2 } from "components/CandidateHeader/SocialLinks/constants";
import { EditSocialLinksV2 } from "components/CandidateHeader/SocialLinks/EditSocialLinks";
import { Tooltip } from "components/library/Tooltip";
import { SocialLinkType } from "services/openapi";
import { isDtnMode } from "views/candidates/ApplicationReview/atoms/dtn";

export interface SocialLinkV2 {
  linkType: SocialLinkType;
  url: string;
}

interface SocialLinksPropsV2 {
  candidateId?: string;
  socialLinks: Array<SocialLinkV2>;
}

export const SocialLinksV2: FC<SocialLinksPropsV2> = ({ candidateId, socialLinks }) => {
  const dtnMode = useAtomValue(isDtnMode);

  // Remove duplicate URLs
  const uniqueSocialLinks = socialLinks.filter(
    (link, index, self) => index === self.findIndex(t => t.url === link.url)
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {uniqueSocialLinks.map(
        (link): ReactElement => {
          const socialLinkDisplay = candidateBioSocialLinkToDisplayMapV2[link.linkType];

          return (
            <Tooltip key={link.url} title={socialLinkDisplay.name}>
              <a
                href={link.url}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon Icon={socialLinkDisplay.icon} color="white" size={socialLinkDisplay.width ?? 16} />
              </a>
            </Tooltip>
          );
        }
      )}
      {!dtnMode && candidateId && <EditSocialLinksV2 candidateId={candidateId} />}
    </Stack>
  );
};
