import { Box } from "@mui/material";
import styled from "styled-components";

import { colors as themeColors } from "styles/theme";

// Copied here from michael's figma
// TODO: extract to somewhere better / use a better library

export const H0 = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
`;

export const H1 = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const colors = {
  calendarIconGrayFill: themeColors.subTitle,
  lightGray: "#d8e1e6",
  infoBoxBlue: "#f5f8fa",
};

interface CircledNumberProps {
  selected?: boolean;
  disabled?: boolean;
}

export const CircledNumber = styled(Box)<CircledNumberProps>`
  border: solid 1px ${themeColors.grayscale.gray300};
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ selected }): string => (selected ? themeColors.informational.light : "")};
  &:hover {
    cursor: ${({ disabled }): string => (disabled ? "" : "pointer")};
    background-color: ${({ disabled }): string => (disabled ? "" : themeColors.informational.light)};
  }
`;
