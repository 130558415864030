import { Radio, Switch, TextField, ToggleButtonGroup } from "@mui/material";
import { styled as MUIStyled } from "@mui/material/styles";
import Color from "color";
import styled from "styled-components";

import { colors, backgrounds } from "styles/theme";

export const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButton-root {
    text-transform: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    background-color: ${colors.grayscale.gray100};

    &.Mui-selected {
      color: ${colors.primary.base};
      background-color: ${colors.white};
  }

  &.MuiToggleButtonGroup-grouped:not(:first-of-type) {
    border-left: 1px solid ${colors.grayscale.gray200};
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    border: none;
    background-color: ${colors.white};
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }
`;

export const AntSwitch = MUIStyled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,

    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: colors.primary.base,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: colors.grayscale.gray200,
    boxSizing: "border-box",
  },
  "& .Mui-disabled": {
    "& + .MuiSwitch-track": {
      opacity: 0.7,
    },
    "&.Mui-checked": {
      color: colors.grayscale.gray100,
    },
  },
}));
