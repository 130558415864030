/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveSampleCandidatesResponse
 */
export interface RemoveSampleCandidatesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RemoveSampleCandidatesResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof RemoveSampleCandidatesResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveSampleCandidatesResponse
     */
    candidateId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveSampleCandidatesResponse
     */
    candidateIds: Array<string>;
}

export function RemoveSampleCandidatesResponseFromJSON(json: any): RemoveSampleCandidatesResponse {
    return RemoveSampleCandidatesResponseFromJSONTyped(json, false);
}

export function RemoveSampleCandidatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveSampleCandidatesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'candidateId': !exists(json, 'candidate_id') ? undefined : json['candidate_id'],
        'candidateIds': json['candidate_ids'],
    };
}

export function RemoveSampleCandidatesResponseToJSON(value?: RemoveSampleCandidatesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'message': value.message,
        'candidate_id': value.candidateId,
        'candidate_ids': value.candidateIds,
    };
}


