/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpsertJobFeatureSetting
 */
export interface UpsertJobFeatureSetting {
    /**
     * 
     * @type {string}
     * @memberof UpsertJobFeatureSetting
     */
    job: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertJobFeatureSetting
     */
    featureName: UpsertJobFeatureSettingFeatureNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UpsertJobFeatureSetting
     */
    state: UpsertJobFeatureSettingStateEnum;
    /**
     * 
     * @type {object}
     * @memberof UpsertJobFeatureSetting
     */
    debugInfo?: object | null;
}

/**
* @export
* @enum {string}
*/
export enum UpsertJobFeatureSettingFeatureNameEnum {
    ManagedOutbound = 'MANAGED_OUTBOUND',
    DoverInterviewer = 'DOVER_INTERVIEWER',
    E2EScheduling = 'E2E_SCHEDULING',
    ScheduleInitialCustomerCallOnly = 'SCHEDULE_INITIAL_CUSTOMER_CALL_ONLY'
}/**
* @export
* @enum {string}
*/
export enum UpsertJobFeatureSettingStateEnum {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

export function UpsertJobFeatureSettingFromJSON(json: any): UpsertJobFeatureSetting {
    return UpsertJobFeatureSettingFromJSONTyped(json, false);
}

export function UpsertJobFeatureSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertJobFeatureSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'job': json['job'],
        'featureName': json['feature_name'],
        'state': json['state'],
        'debugInfo': !exists(json, 'debug_info') ? undefined : json['debug_info'],
    };
}

export function UpsertJobFeatureSettingToJSON(value?: UpsertJobFeatureSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': value.job,
        'feature_name': value.featureName,
        'state': value.state,
        'debug_info': value.debugInfo,
    };
}


