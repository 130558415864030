/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterSaapReviewedCandidateRequest
 */
export interface RegisterSaapReviewedCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterSaapReviewedCandidateRequest
     */
    canonicalId: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSaapReviewedCandidateRequest
     */
    searchId: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSaapReviewedCandidateRequest
     */
    reviewType?: RegisterSaapReviewedCandidateRequestReviewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterSaapReviewedCandidateRequest
     */
    rejectionReason?: RegisterSaapReviewedCandidateRequestRejectionReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum RegisterSaapReviewedCandidateRequestReviewTypeEnum {
    GoodFit = 'GOOD_FIT',
    BadFit = 'BAD_FIT',
    TopCandidate = 'TOP_CANDIDATE'
}/**
* @export
* @enum {string}
*/
export enum RegisterSaapReviewedCandidateRequestRejectionReasonEnum {
    LackingSpecificSkills = 'LACKING_SPECIFIC_SKILLS',
    NoLongerHiring = 'NO_LONGER_HIRING',
    NotEnoughYearsOfExperience = 'NOT_ENOUGH_YEARS_OF_EXPERIENCE',
    NotInterestedInRole = 'NOT_INTERESTED_IN_ROLE',
    Other = 'OTHER',
    ShortStints = 'SHORT_STINTS',
    TooSenior = 'TOO_SENIOR',
    UnimpressiveCompanies = 'UNIMPRESSIVE_COMPANIES',
    UnimpressiveSchools = 'UNIMPRESSIVE_SCHOOLS',
    WrongCompanyIndustry = 'WRONG_COMPANY_INDUSTRY',
    WrongCompanySize = 'WRONG_COMPANY_SIZE',
    WrongJobTitles = 'WRONG_JOB_TITLES',
    WrongLocation = 'WRONG_LOCATION',
    WrongSchoolMajor = 'WRONG_SCHOOL_MAJOR',
    WrongTypeOfExperience = 'WRONG_TYPE_OF_EXPERIENCE',
    MiscalculatedYearsOfExperience = 'MISCALCULATED_YEARS_OF_EXPERIENCE',
    MisidentifiedCompanyQuality = 'MISIDENTIFIED_COMPANY_QUALITY',
    MisidentifiedSkillset = 'MISIDENTIFIED_SKILLSET',
    MisidentifiedLocation = 'MISIDENTIFIED_LOCATION',
    MisidentifiedCompanySize = 'MISIDENTIFIED_COMPANY_SIZE',
    MisidentifiedWorkHistory = 'MISIDENTIFIED_WORK_HISTORY',
    WrongComapnySize = 'WRONG_COMAPNY_SIZE'
}

export function RegisterSaapReviewedCandidateRequestFromJSON(json: any): RegisterSaapReviewedCandidateRequest {
    return RegisterSaapReviewedCandidateRequestFromJSONTyped(json, false);
}

export function RegisterSaapReviewedCandidateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterSaapReviewedCandidateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canonicalId': json['canonical_id'],
        'searchId': json['search_id'],
        'reviewType': !exists(json, 'review_type') ? undefined : json['review_type'],
        'rejectionReason': !exists(json, 'rejection_reason') ? undefined : json['rejection_reason'],
    };
}

export function RegisterSaapReviewedCandidateRequestToJSON(value?: RegisterSaapReviewedCandidateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canonical_id': value.canonicalId,
        'search_id': value.searchId,
        'review_type': value.reviewType,
        'rejection_reason': value.rejectionReason,
    };
}


