import { Button } from "@doverhq/dover-ui";
import { ReactComponent as SortIcon } from "@doverhq/dover-ui/icons/sort.svg";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import { PressEvent } from "@react-types/shared/src/";
import { useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { useState } from "react";

import { ReactComponent as GreenCheckSVG } from "assets/icons/check-green-filled.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useGetClientIsTam } from "services/doverapi/endpoints/client/hooks";
import { ListSaapReviewApplicationRequestOrderingEnum } from "services/openapi";
import { orderingAtom, useOrderingValue } from "views/candidates/ApplicationReview/atoms/ordering";
import { pageAtom } from "views/candidates/ApplicationReview/atoms/pagination";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";

export const ApplicantSort = (): React.ReactElement => {
  const simpReviewFFOn = useFeatureFlag(FeatureFlag.SimpReview);
  let clientIsExplicitNonTam = useGetClientIsTam() === false;
  // for now gate this logic behind simpReviewFF
  clientIsExplicitNonTam = clientIsExplicitNonTam && simpReviewFFOn;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const setOrdering = useSetAtom(orderingAtom);
  const ordering = useOrderingValue();

  // Ensure if scoring is off that we dont try to sort by best match
  const { jobHasScoringEnabled } = useJobHasScoringEnabled();

  const resetPage = useResetAtom(pageAtom);

  const openMenu = React.useCallback((event: PressEvent): void => {
    setAnchorEl(event.target);
  }, []);

  const closeMenu = React.useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const setOrderingWrapper = React.useCallback(
    (ordering: ListSaapReviewApplicationRequestOrderingEnum) => {
      setOrdering(ordering);
      resetPage();
    },
    [setOrdering, resetPage]
  );

  return (
    <>
      <Tooltip title="Sort">
        <div>
          <Button p={1} variant="ghost" icon={{ Icon: SortIcon }} onPress={openMenu} />
        </div>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        {!clientIsExplicitNonTam && (
          <Tooltip title={!jobHasScoringEnabled ? "Activate scoring to use this sorter." : undefined}>
            <div>
              <MenuItem
                onClick={(): void => setOrderingWrapper(ListSaapReviewApplicationRequestOrderingEnum.Best)}
                disabled={!jobHasScoringEnabled}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                  <BodySmall>Best Match</BodySmall>
                  {ordering === ListSaapReviewApplicationRequestOrderingEnum.Best && (
                    <Box paddingLeft={"8px"} display="flex" justifyContent="center">
                      <GreenCheckSVG height="16px" width="16px" />
                    </Box>
                  )}
                </Stack>
              </MenuItem>
            </div>
          </Tooltip>
        )}
        <MenuItem onClick={(): void => setOrderingWrapper(ListSaapReviewApplicationRequestOrderingEnum.Newest)}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <BodySmall>Newest</BodySmall>
            {ordering === ListSaapReviewApplicationRequestOrderingEnum.Newest && (
              <Box paddingLeft={"8px"} display="flex" justifyContent="center">
                <GreenCheckSVG height="16px" width="16px" />
              </Box>
            )}
          </Stack>
        </MenuItem>
        <MenuItem onClick={(): void => setOrderingWrapper(ListSaapReviewApplicationRequestOrderingEnum.Oldest)}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <BodySmall>Oldest</BodySmall>
            {ordering === ListSaapReviewApplicationRequestOrderingEnum.Oldest && (
              <Box paddingLeft={"8px"} display="flex" justifyContent="center">
                <GreenCheckSVG height="16px" width="16px" />
              </Box>
            )}
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
