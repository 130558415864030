import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as NoApplicantsIcon } from "assets/icons/no-applicants-icon.svg";
import { Subtitle1, BodySmall } from "components/library/typography";

const BioLoadingErrorState = (): React.ReactElement => {
  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%" spacing={2} px={1}>
      <NoApplicantsIcon />
      <Stack alignItems="center">
        <Subtitle1>Applicant info failed to load</Subtitle1>
        <BodySmall centered>Try refreshing or contacting Dover Support.</BodySmall>
      </Stack>
    </Stack>
  );
};

export default BioLoadingErrorState;
