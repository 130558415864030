/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SaapReviewAdditionalFilters
 */
export interface SaapReviewAdditionalFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof SaapReviewAdditionalFilters
     */
    sourceLabels?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SaapReviewAdditionalFilters
     */
    criteriaMatch?: Array<SaapReviewAdditionalFiltersCriteriaMatchEnum>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof SaapReviewAdditionalFilters
     */
    applicationQuestions?: { [key: string]: Array<string>; };
}

/**
* @export
* @enum {string}
*/
export enum SaapReviewAdditionalFiltersCriteriaMatchEnum {
    BestMatch = 'BEST_MATCH',
    CloseMatch = 'CLOSE_MATCH',
    Mismatch = 'MISMATCH',
    Scoring = 'SCORING',
    EnrichingProfile = 'ENRICHING_PROFILE',
    NoInfo = 'NO_INFO'
}

export function SaapReviewAdditionalFiltersFromJSON(json: any): SaapReviewAdditionalFilters {
    return SaapReviewAdditionalFiltersFromJSONTyped(json, false);
}

export function SaapReviewAdditionalFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaapReviewAdditionalFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceLabels': !exists(json, 'source_labels') ? undefined : json['source_labels'],
        'criteriaMatch': !exists(json, 'criteria_match') ? undefined : json['criteria_match'],
        'applicationQuestions': !exists(json, 'application_questions') ? undefined : json['application_questions'],
    };
}

export function SaapReviewAdditionalFiltersToJSON(value?: SaapReviewAdditionalFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source_labels': value.sourceLabels,
        'criteria_match': value.criteriaMatch,
        'application_questions': value.applicationQuestions,
    };
}


