import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as ClockIcon } from "@doverhq/dover-ui/icons/clock.svg";
import { Box, Stack } from "@mui/material";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as AISparklesIcon } from "assets/icons/ai-sparkles.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Chip } from "components/library/Chip";
import { Tooltip } from "components/library/Tooltip";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import { PersonaCoin } from "components/PersonaCoin";
import { useCandidateId } from "hooks/useCandidateId";
import { useDisableCandidateActionsTooltipText } from "hooks/useDisableCandidateActionsTooltipText";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useLazyGetInterviewRubricResponseQuery } from "services/doverapi/endpoints/candidateInterview";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { InterviewEventField } from "services/openapi";
import { colors } from "styles/theme";
import { createAndOpenFeedbackForm } from "utils/candidate";
import { formatTime } from "views/CandidateDetail/components/next-action/utils/constants";
import { convertPrettyDate } from "views/CandidateDetail/utils";
import { CallRecordingStatus } from "views/interview/CandidateInterview/TranscriptDrawer";

/* -----------------------------------------------------------------------------
 * TranscriptionStatusIndicator
 * -------------------------------------------------------------------------- */

const TranscriptionStatusIndicator = ({ interviewEvent }: Props): React.ReactElement => {
  const callStatus = interviewEvent?.callRecordingStatus;

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {[
        CallRecordingStatus.IN_PROGRESS,
        CallRecordingStatus.RECORDING_FINISHED,
        CallRecordingStatus.RECORDING_PROCESSING,
      ].includes(callStatus as CallRecordingStatus) && (
        <>
          <Icon Icon={ClockIcon} color="gray-600" size={16} />
          <BodyExtraSmall color={colors.grayscale.gray600}>AI notes processing</BodyExtraSmall>
        </>
      )}
      {callStatus === CallRecordingStatus.RECORDING_PROCESSED && (
        <>
          <Icon Icon={AISparklesIcon} size={16} />
          <BodyExtraSmall color={colors.grayscale.gray600}>AI notes available</BodyExtraSmall>
        </>
      )}
    </Stack>
  );
};

/* -----------------------------------------------------------------------------
 * InterviewFeedbackCompletedCard
 * -------------------------------------------------------------------------- */

const getLabelColor = (rating: number): "Primary" | "Informational" | "Warning" | "Critical" | "Gray" => {
  if (rating === 1) {
    return "Critical";
  } else if (rating === 2) {
    return "Warning";
  } else if (rating === 3) {
    return "Informational";
  } else {
    return "Primary";
  }
};

interface Props {
  interviewEvent?: InterviewEventField;
}

export const InterviewFeedbackCompletedCard = ({ interviewEvent }: Props): React.ReactElement => {
  const { data: userInfo } = useGetAuthedUserInfoQuery();
  const jobId = useJobIdFromUrl();
  const disabledTooltipText = useDisableCandidateActionsTooltipText(jobId);
  const candidateId = useCandidateId();
  const [getOrCreateIRR] = useLazyGetInterviewRubricResponseQuery();
  const useAINotetaker = useFeatureFlag(FeatureFlag.AINotetaker);

  const submitFeedbackHandler = React.useCallback(
    async (interviewId: string) => {
      if (!candidateId) {
        return;
      }

      createAndOpenFeedbackForm(getOrCreateIRR, candidateId, interviewId);
    },
    [getOrCreateIRR, candidateId]
  );

  if (!interviewEvent) {
    return <></>;
  }

  return (
    <Stack direction="column" spacing={1}>
      {interviewEvent.interviewPanel?.map(substage => {
        const isDoverInterview = !!interviewEvent.isDoverInterview;

        return (
          <Stack
            direction="column"
            spacing={1}
            padding={1}
            sx={{
              borderBottom: interviewEvent?.isMultipart ? "1px" : "0px",
              borderBottomStyle: "solid",
              borderColor: colors.grayscale.gray200,
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <Stack direction="column" spacing={0.25} width="132px">
                  <BodyExtraSmall weight="600">{substage.name}</BodyExtraSmall>
                  {substage?.startTime && substage?.endTime && (
                    <BodyExtraSmall color={colors.grayscale.gray500}>
                      {formatTime(substage.startTime)} - {formatTime(substage.endTime)}
                    </BodyExtraSmall>
                  )}
                </Stack>
                {!!substage?.interviewers && (
                  <Stack direction="row" spacing={1}>
                    {substage.interviewers.map(interviewer => (
                      <PersonaCoin
                        useDoverLogo={isDoverInterview}
                        firstName={interviewer.fullName}
                        pictureUrl={interviewer.pictureUrl}
                        size="small"
                        tooltip={isDoverInterview ? "Dover Interviewer" : interviewer.fullName}
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" spacing={1}>
                {useAINotetaker && interviewEvent?.hasTranscript && (
                  <TranscriptionStatusIndicator interviewEvent={interviewEvent} />
                )}
                <Button variant={ButtonVariant.Link} removePadding>
                  <BodySmall
                    color={ButtonVariant.Link}
                    onClick={(): Promise<void> => submitFeedbackHandler(substage.id)}
                  >
                    Submit Feedback
                  </BodySmall>
                </Button>
              </Stack>
            </Stack>

            {substage.submittedFeedbackForms?.map(feedbackForm => {
              const authedUserIsAuthor = userInfo?.id === feedbackForm?.submittedBy.id;
              const canClick = authedUserIsAuthor || !disabledTooltipText;
              const openFeedbackForm = (): void => {
                if (!canClick || !candidateId || !feedbackForm.id) {
                  return;
                }
                const link = APP_ROUTE_PATHS.candidateInterview(candidateId, feedbackForm.id);
                window.open(link, "_blank", "noopener noreferrer");
              };

              const isDoverInterview = !!interviewEvent.isDoverInterview;
              const submitterName = feedbackForm?.submittedBy.fullName;

              const submittedByLabel = isDoverInterview ? "Dover Interviewer" : submitterName;

              if (feedbackForm.submissionState < 300 && !authedUserIsAuthor) {
                return <></>;
              }

              return (
                <Tooltip title={disabledTooltipText} placement="top" key={feedbackForm.id}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      borderRadius: "4px",
                      border: `1px solid ${colors.grayscale.gray200}`,
                      background: "var(--Text-White, #FFF)",
                      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
                      cursor: canClick ? "pointer" : undefined,
                      ":hover": {
                        backgroundColor: colors.grayscale.gray100,
                      },
                    }}
                    padding={1}
                    onClick={openFeedbackForm}
                  >
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                      <PersonaCoin
                        useDoverLogo={isDoverInterview}
                        firstName={submittedByLabel}
                        pictureUrl={feedbackForm.submittedBy.pictureUrl}
                        size="small"
                      />
                      <Stack direction="column" spacing={0.5}>
                        <BodyExtraSmall weight="600">{submittedByLabel}</BodyExtraSmall>
                        <BodyExtraSmall color={colors.grayscale.gray400}>{`Submitted ${convertPrettyDate(
                          feedbackForm.submittedOn
                        )}`}</BodyExtraSmall>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Box height={"100%"}>
                        {feedbackForm.submissionState < 300 && <Chip variant="Gray" label={"IN PROGRESS"} />}
                        {feedbackForm.submissionState === 300 && feedbackForm.overallRating && (
                          <Chip
                            variant={getLabelColor(feedbackForm.overallRating)}
                            label={`OVERALL RATING: ${feedbackForm.overallRating}/4`}
                          />
                        )}
                      </Box>
                    </Stack>
                  </Stack>
                </Tooltip>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};
