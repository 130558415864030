import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { BodySmall } from "components/library/typography";

export const QueuedEmailReactSVG = styled(ReactSVG)`
  svg {
    margin-bottom: 6px;
  }
`;

export const TemplateEditorBox = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  min-width: 400px;
`;

export const StyledBodySmall = styled(BodySmall)`
  margin-top: 2px;
`;
