import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { FormProvider, useController, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Button as ComponentsLibButton, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Body, ButtonText, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { Role, useContainsRole } from "components/RBAC";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { usePartialUpdateProUserMutation } from "services/doverapi/endpoints/proUser";
import {
  useListRolesQuery,
  useUpdateDefaultClientRoleMutation,
  useUpdateUserClientRoleMutation,
  useListAuthenticatedUsersQuery,
  useSetUserBlockedFlagMutation,
} from "services/doverapi/endpoints/RBAC/endpoints";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";
import { ToggleEditModalOpenAtom, UserBeingEditedAtom } from "views/CompanySetup/components/Users/atoms";
import { PermissionsSelector } from "views/CompanySetup/components/Users/components/PermissionsSelector";
import {
  EditProUserFormSchema,
  EditProUserFormSchemaType,
  RoleTitleData,
} from "views/CompanySetup/components/Users/types";

export const EditUserModal = (): React.ReactElement => {
  const clientId = useGetClientId();

  // Mutations
  const [partialUpdateProUser, { isLoading: updatingProUser }] = usePartialUpdateProUserMutation();

  // RTK
  const { data: clientRoles } = useListRolesQuery();
  const userHasPermissions = useContainsRole([Role.ADMIN, Role.CLIENT_ADMIN]);
  const [, { isLoading: isBlockingUser }] = useSetUserBlockedFlagMutation();
  const [, { isLoading: isDefaultRoleUpdating }] = useUpdateDefaultClientRoleMutation();
  const [updateUsersClientRole, { isLoading: isUpdatingUserRole }] = useUpdateUserClientRoleMutation();

  // Modal state
  const [editModalOpen, toggleEditModalOpen] = useAtom(ToggleEditModalOpenAtom);
  const [userBeingEdited, setUserBeingEdited] = useAtom(UserBeingEditedAtom);
  const isMutatingUsers = isDefaultRoleUpdating || isUpdatingUserRole || isBlockingUser;

  const { isFetching: isProUsersFetching } = useListAuthenticatedUsersQuery();
  const selectedRole = clientRoles?.find(option => option.name && userBeingEdited?.roles?.includes(option.name));

  const defaultModalValues: EditProUserFormSchemaType = {
    firstName: userBeingEdited?.firstName,
    lastName: userBeingEdited?.lastName,
    roleTitle: (userBeingEdited?.roleTitleData as RoleTitleData)?.title,
    roleId: selectedRole?.id,
  };

  // RHF
  const formMethods = useForm<EditProUserFormSchemaType>({
    resolver: zodResolver(EditProUserFormSchema),
    defaultValues: defaultModalValues,
  });
  const { control, handleSubmit, reset, register } = formMethods;
  const { field: firstNameField } = useController({
    name: "firstName",
    control,
  });

  const { field: lastNameField } = useController({
    name: "lastName",
    control,
  });

  const { field: roleTitleField } = useController({
    name: "roleTitle",
    control,
  });

  const { field: roleIdField } = useController({
    name: "roleId",
    control,
  });

  // Callbacks
  const onCloseModal = (): void => {
    reset({
      firstName: undefined,
      lastName: undefined,
      roleTitle: undefined,
      roleId: undefined,
    });
    setUserBeingEdited(undefined);
    toggleEditModalOpen(false);
  };

  const onEditProUserSubmit = async (): Promise<void> => {
    if (!userBeingEdited?.id || !clientId || !roleIdField?.value) {
      return;
    }
    if (!firstNameField.value || !lastNameField.value) {
      return;
    }

    // Bulk update dover role & pro user
    const promises = [
      updateUsersClientRole({
        user: userBeingEdited.id.toString(),
        data: { roleId: roleIdField.value },
      }),
      partialUpdateProUser({
        user: userBeingEdited.id.toString(),
        data: {
          firstName: firstNameField.value,
          lastName: lastNameField.value,
          roleTitleData: {
            ...userBeingEdited.roleTitleData,
            title: roleTitleField.value,
          },
        },
      }),
    ];

    try {
      await toast.promise(
        // @ts-ignore
        Promise.all(promises),
        {
          pending: "Editing user",
          success: "Done!",
          error: "Error editing user",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    } finally {
      onCloseModal();
    }
  };

  if (!userBeingEdited) {
    return <></>;
  }

  return (
    <CustomModal
      open={editModalOpen}
      title={<Subtitle2>Edit user info</Subtitle2>}
      onClose={onCloseModal}
      maxWidth="sm"
      dialogActions={
        <Stack direction="row" spacing={1}>
          <ComponentsLibButton onClick={onCloseModal} variant={ButtonVariant.Secondary} disabled={isMutatingUsers}>
            <ButtonText>Cancel</ButtonText>
          </ComponentsLibButton>
          <ComponentsLibButton
            onClick={handleSubmit(onEditProUserSubmit)}
            variant={ButtonVariant.Primary}
            disabled={isMutatingUsers || !firstNameField.value || !lastNameField.value}
          >
            <ButtonText color={colors.white}>Save</ButtonText>
          </ComponentsLibButton>
        </Stack>
      }
    >
      <FormProvider {...formMethods}>
        <Stack spacing={2} p={3}>
          <Stack direction="row">
            <Box flexBasis="50%">
              <TextField
                title="First name"
                disabled={updatingProUser}
                inputProps={{ ...register("firstName") }}
                onTextUpdated={firstNameField.onChange}
                required
                error={!firstNameField.value}
                errorMessage={!firstNameField.value ? "First name is required" : undefined}
              />
            </Box>
            <Box ml={2} flexBasis="50%">
              <TextField
                title="Last name"
                disabled={updatingProUser}
                inputProps={{ ...register("lastName") }}
                onTextUpdated={lastNameField.onChange}
                required
                error={!lastNameField.value}
                errorMessage={!lastNameField.value ? "Last name is required" : undefined}
              />
            </Box>
          </Stack>
          <TextField
            title="Title"
            disabled={updatingProUser}
            placeholderText="Software Engineer"
            inputProps={{ ...register("roleTitle") }}
            onTextUpdated={roleTitleField.onChange}
          />
          <Stack>
            <Body weight="500">Role</Body>
            <PermissionsSelector
              options={clientRoles ?? []}
              value={clientRoles?.find(option => option.id === roleIdField.value)}
              isLoading={isProUsersFetching}
              setValue={roleIdField.onChange}
              disabled={!userHasPermissions || isMutatingUsers}
              styles={{
                ".MuiOutlinedInput-root.MuiInputBase-root": { outline: "none", border: "none" },
                width: "100%",
              }}
            />
          </Stack>
        </Stack>
      </FormProvider>
    </CustomModal>
  );
};
