/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkUpsertClientDomain
 */
export interface BulkUpsertClientDomain {
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpsertClientDomain
     */
    domains: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertClientDomain
     */
    primaryDomain: string;
}

export function BulkUpsertClientDomainFromJSON(json: any): BulkUpsertClientDomain {
    return BulkUpsertClientDomainFromJSONTyped(json, false);
}

export function BulkUpsertClientDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpsertClientDomain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domains': json['domains'],
        'primaryDomain': json['primary_domain'],
    };
}

export function BulkUpsertClientDomainToJSON(value?: BulkUpsertClientDomain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domains': value.domains,
        'primary_domain': value.primaryDomain,
    };
}


