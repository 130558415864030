import { Button } from "@doverhq/dover-ui";
import { useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ReactComponent as PlusSVG } from "assets/icons/plus.svg";
import { EmailTemplateDrawerAction, EmailTemplateDrawerConfigAtom } from "components/dover/EmailTemplates/atoms";
import { EmailTemplateDrawerAtom } from "components/dover/EmailTemplates/EmailTemplateDrawer";
import { useModal } from "GlobalOverlays/atoms";
import { ClientEmailTemplate } from "services/openapi";

const NewTemplateButton = ({ style }: { style?: React.CSSProperties }): React.ReactElement => {
  const { open: openEmailTemplateDrawer } = useModal(EmailTemplateDrawerAtom);

  const setEmailTemplateDrawerConfig = useSetAtom(EmailTemplateDrawerConfigAtom);

  const [newTemplate] = useQueryParam("newTemplate", BooleanParam);

  const onClick = (): void => {
    setEmailTemplateDrawerConfig({
      action: EmailTemplateDrawerAction.Create,
      template: {
        name: "New Template",
      } as ClientEmailTemplate,
    });
    openEmailTemplateDrawer({});
  };

  useEffect(() => {
    if (newTemplate) {
      openEmailTemplateDrawer({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button variant="outlined" icon={{ Icon: PlusSVG }} onPress={onClick} style={style}>
      New
    </Button>
  );
};

export default NewTemplateButton;
