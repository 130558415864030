/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateFilter,
    CandidateFilterFromJSON,
    CandidateFilterFromJSONTyped,
    CandidateFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidatePipelineListRequest
 */
export interface CandidatePipelineListRequest {
    /**
     * 
     * @type {CandidateFilter}
     * @memberof CandidatePipelineListRequest
     */
    filters: CandidateFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CandidatePipelineListRequest
     */
    provideCandidateBucketLabels?: boolean;
}

export function CandidatePipelineListRequestFromJSON(json: any): CandidatePipelineListRequest {
    return CandidatePipelineListRequestFromJSONTyped(json, false);
}

export function CandidatePipelineListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidatePipelineListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filters': CandidateFilterFromJSON(json['filters']),
        'provideCandidateBucketLabels': !exists(json, 'provide_candidate_bucket_labels') ? undefined : json['provide_candidate_bucket_labels'],
    };
}

export function CandidatePipelineListRequestToJSON(value?: CandidatePipelineListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filters': CandidateFilterToJSON(value.filters),
        'provide_candidate_bucket_labels': value.provideCandidateBucketLabels,
    };
}


