/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateContactPhoneNumbersRequest
 */
export interface CreateContactPhoneNumbersRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateContactPhoneNumbersRequest
     */
    contactId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateContactPhoneNumbersRequest
     */
    phoneNumbers: Array<string>;
}

export function CreateContactPhoneNumbersRequestFromJSON(json: any): CreateContactPhoneNumbersRequest {
    return CreateContactPhoneNumbersRequestFromJSONTyped(json, false);
}

export function CreateContactPhoneNumbersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateContactPhoneNumbersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactId': json['contact_id'],
        'phoneNumbers': json['phone_numbers'],
    };
}

export function CreateContactPhoneNumbersRequestToJSON(value?: CreateContactPhoneNumbersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact_id': value.contactId,
        'phone_numbers': value.phoneNumbers,
    };
}


