import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { ClientInterviewer, ClientInterviewerSetupStateEnum, HiringPlanInterviewer } from "services/openapi";

export const convertClientInterviewer = (interviewer?: ClientInterviewer): Interviewer | undefined => {
  if (!interviewer) {
    return undefined;
  }

  if (!interviewer.id) {
    return undefined;
  }

  return {
    label: `${interviewer.fullName} <${interviewer.email}>`,
    value: interviewer.id,
    fullName: interviewer.fullName,
    hasSchedulingLinkOverride: interviewer.hasSchedulingLinkOverride ?? false,
    preferencesComplete: interviewer.setupState === ClientInterviewerSetupStateEnum.Ready,
  };
};

export const convertHiringPlanInterviewer = (interviewer?: HiringPlanInterviewer): Interviewer | undefined => {
  if (!interviewer) {
    return undefined;
  }

  if (!interviewer.id) {
    return undefined;
  }

  const fullName = `${interviewer.firstName} ${interviewer.lastName}`;

  return {
    label: `${fullName} <${interviewer.email}>`,
    value: interviewer.id,
    fullName: fullName,
    hasSchedulingLinkOverride: false, // TODO
    preferencesComplete: true, // TODO
  };
};
