/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SenioritiesValue
 */
export interface SenioritiesValue {
    /**
     * 
     * @type {Array<number>}
     * @memberof SenioritiesValue
     */
    seniorities: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof SenioritiesValue
     */
    mostRecentOnly: boolean;
}

export function SenioritiesValueFromJSON(json: any): SenioritiesValue {
    return SenioritiesValueFromJSONTyped(json, false);
}

export function SenioritiesValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): SenioritiesValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seniorities': json['seniorities'],
        'mostRecentOnly': json['most_recent_only'],
    };
}

export function SenioritiesValueToJSON(value?: SenioritiesValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seniorities': value.seniorities,
        'most_recent_only': value.mostRecentOnly,
    };
}


