import React, { createContext, useCallback, useState } from "react";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import { JOB_PITCH_DRAWER_QUERY_PARAM } from "components/dover/JobPitch/data/queryParam";
import { JobPitchState } from "components/dover/JobPitch/types";

const initialJobPitchState: JobPitchState = {
  modalOpen: false,
  closeModal: () => {},
  discardChangesModalOpen: false,
  setDiscardChangesModalOpen: () => {},
};

export const JobPitchContext = createContext<JobPitchState>(initialJobPitchState);

export const JobPitchProvider: React.FC = ({ children }) => {
  //----------------------------------------
  // Query param state

  const [isModalOpenParam, setIsModalOpenParam] = useQueryParam(
    JOB_PITCH_DRAWER_QUERY_PARAM,
    withDefault(BooleanParam, false)
  );

  //----------------------------------------
  // General state

  const [discardChangesModalOpen, setDiscardChangesModalOpen] = useState(false);

  // Modal
  const closeModal = useCallback(
    (force: boolean): void => {
      // use undefined to remove the query param from the URL
      if (force) {
        setIsModalOpenParam(undefined);
      } else {
        setDiscardChangesModalOpen(true);
      }
    },
    [setIsModalOpenParam]
  );

  //----------------------------------------
  // Render

  return (
    <JobPitchContext.Provider
      value={{
        discardChangesModalOpen,
        setDiscardChangesModalOpen,
        modalOpen: isModalOpenParam,
        closeModal,
      }}
    >
      {children}
    </JobPitchContext.Provider>
  );
};
