import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import EditIconSVG from "assets/icons/edit.svg";
import InfoIconSVG from "assets/icons/info-icon.svg";
import Banner from "components/Banner";
import { CAMPAIGN_MESSAGE_LIMIT } from "components/constants";
import { ModalKeyType } from "components/dover/Campaigns/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall, Heading } from "components/library/typography";
import CampaignMessageEditor from "components/outreach-configuration/form/CampaignMessageEditorField";
import { Spacer } from "components/Spacer";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import {
  ClientDoverPlanEnum,
  ListCampaign,
  ListCampaignSetupStateEnum,
  SlimEmailSenderAliasGmailCredentialAuthStateEnum,
} from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { ExternalLink } from "styles/typography";
import { getEmailAliasName } from "utils/getEmailAliasName";
import CampaignStateButton from "views/job/JobSetup/steps/CampaignVariants/components/CampaignStateButton";
import DeleteCampaignButton from "views/job/JobSetup/steps/CampaignVariants/components/DeleteCampaignButton";
import { FakeLink, StyledSVG } from "views/job/JobSetup/steps/CampaignVariants/styles";
import { CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";
import { getCampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/utils";

const EditorBackground = styled.div`
  background-color: ${backgrounds.white};
  border-radius: 4px;
  padding: 16px;
  border: 1px solid ${colors.grayscale.gray300};
`;

const renderStatusBanner = (status: CampaignStatus): React.ReactElement => {
  switch (status) {
    case CampaignStatus.Active:
      return (
        <Box pb="24px">
          <Banner type="success" alignItems="center" variant="filled">
            <BodySmall>
              {`Any edits will go live immediately upon saving. `}
              <br />
              <b>Note</b>
              {`: It takes up to 2 weeks to collect open and interested rates.`}
            </BodySmall>
          </Banner>
        </Box>
      );
    case CampaignStatus.Processing:
      return (
        <Box pb="24px">
          <Banner type="none" alignItems="center" variant="filled">
            <BodySmall>{`We are processing your outreach. It will go live shortly.`}</BodySmall>
          </Banner>
        </Box>
      );
    case CampaignStatus.Draft:
      return (
        <Box pb="24px">
          <Banner type="info" alignItems="center" variant="filled">
            <BodySmall>{`You are editing a draft.`}</BodySmall>
          </Banner>
        </Box>
      );
    case CampaignStatus.Inactive:
      return (
        <Box pb="24px">
          <Banner type="critical" alignItems="center" variant="filled">
            <BodySmall>{`This campaign is deactivated.`}</BodySmall>
          </Banner>
        </Box>
      );
    default:
      return <></>;
  }
};

interface CampaignEditorProps {
  selectedCampaignId: string;
  campaign: ListCampaign;
  setOpenModal: (value: ModalKeyType) => void;
  onlyNameEditable: boolean;
  showDeleteButton: boolean;
  handleAddNewMessage: () => void;
}

const CampaignEditor = ({
  campaign,
  setOpenModal,
  onlyNameEditable,
  showDeleteButton,
  handleAddNewMessage,
}: CampaignEditorProps): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId } = useParams<{ jobId: string }>();
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);

  const campaignStatus = getCampaignStatus(campaign.state!, campaign.setupState!);
  const doverPlan = useGetDoverPlan();

  /* Show Activate/Deactivate button if campaign setup state is Complete */
  const showStateButton = campaign && campaign?.setupState === ListCampaignSetupStateEnum.Complete;
  const { data: userDefinedSenderUser, isFetching: isFetchingProUser } = useGetProUserQuery(
    campaign.userDefinedSenderUser ? ((campaign.userDefinedSenderUser as unknown) as string) : skipToken
  );

  // This handles an edge case where the user selects on a campaign with a user defined sender user
  // then selects another campaign without a user defined sender user.
  // We have to ensure that the selectedCampaign has a user defined sender user, otherwise the
  // userDefinedSenderUser variable from the getProUserQuery will not update because the skipToken is used
  // and userDefinedSenderUser will still reference the one from the previous campaign.
  const senderUser = campaign.userDefinedSenderUser ? userDefinedSenderUser : undefined;

  const senderAndNameEditable = useMemo(() => !onlyNameEditable, [onlyNameEditable]);

  const isPlatformOnlyJob = jobFeatures?.hasServicesEnabled === false;
  const shouldShowGmailUnauthedFlow =
    isPlatformOnlyJob &&
    campaign.emailAlias &&
    ![
      SlimEmailSenderAliasGmailCredentialAuthStateEnum.Valid,
      SlimEmailSenderAliasGmailCredentialAuthStateEnum.IsShellAccount,
    ].includes(campaign.emailAlias.gmailCredentialAuthState!);

  const messageLimitReached = useMemo(
    () => campaign && campaign.threadMessages && campaign.threadMessages.length >= CAMPAIGN_MESSAGE_LIMIT,
    [campaign]
  );

  const newMessageButton = useMemo(
    (): React.ReactElement => (
      <Button
        variant={ButtonVariant.Secondary}
        onClick={handleAddNewMessage}
        disabled={messageLimitReached}
        width="100%"
      >
        {"+ New Followup"}
      </Button>
    ),
    [handleAddNewMessage, messageLimitReached]
  );

  const CreateNewMessageButton = useMemo(() => {
    if (messageLimitReached) {
      const isPlanFree = doverPlan === ClientDoverPlanEnum.Free;
      const tooltipMessage = isPlanFree
        ? "Additional followups are not supported on the free plan."
        : `Outreach campaigns are limited to ${CAMPAIGN_MESSAGE_LIMIT} emails to avoid negative candidate interactions.`;
      return (
        <Tooltip title={tooltipMessage} placement="top" arrow={true}>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            {newMessageButton}
          </Box>
        </Tooltip>
      );
    } else {
      return newMessageButton;
    }
  }, [messageLimitReached, newMessageButton, doverPlan]);

  return (
    <>
      <Spacer height={16} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Body>{campaign.name}</Body>
        <Box display="flex" alignItems="center">
          {showDeleteButton && <DeleteCampaignButton campaign={campaign} />}
          {showStateButton && (
            <>
              <Spacer width={8} />
              <CampaignStateButton campaign={campaign} campaignSender={senderUser} />
            </>
          )}
        </Box>
      </Box>
      <Spacer height={24} />
      <EditorBackground>
        <Box display="flex" flexDirection="column">
          {renderStatusBanner(campaignStatus)}
          {shouldShowGmailUnauthedFlow && (
            <Box pb="24px">
              <Banner type="warning" alignItems="center" variant="filled">
                <>
                  <BodySmall inline>
                    {`To send emails${campaign.emailAlias ? ` from ${campaign.emailAlias?.email}` : ""}, please `}
                    <FakeLink
                      display="inline"
                      $color={colors.link}
                      onClick={(): void => {
                        navigate(APP_ROUTE_PATHS.userSettings.gmailAuth());
                      }}
                    >{` authorize the Gmail account`}</FakeLink>
                  </BodySmall>
                </>
              </Banner>
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ borderRadius: "4px", border: `1px solid ${colors.grayscale.gray300}` }}
          >
            <Box display="flex">
              <Box display="flex" p={2}>
                <BodySmall color={colors.grayscale.gray500}>From:</BodySmall>
              </Box>
              <Box display="flex" py={2}>
                {isFetchingProUser ? (
                  <BodySmall color={colors.grayscale.gray500}>Loading...</BodySmall>
                ) : (
                  <BodySmall>
                    {getEmailAliasName(senderUser, campaign.emailSenderOption, campaign.emailAlias)}
                  </BodySmall>
                )}
                <Spacer width={12} />
                <Box>
                  {senderAndNameEditable ? (
                    <StyledSVG
                      src={EditIconSVG}
                      style={{ cursor: "pointer" }}
                      onClick={(): void => setOpenModal(ModalKeyType.UpdateOrCreateCampaign)}
                    />
                  ) : (
                    <Tooltip
                      title={
                        "Email sender cannot be changed. Create a new outreach campaign with a different email sender."
                      }
                      placement="top"
                    >
                      <span>
                        <StyledSVG src={InfoIconSVG} />
                      </span>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>
            {isPlatformOnlyJob && (
              <Box display="flex" p={2}>
                <Stack direction="row" spacing={1}>
                  <BodySmall color={colors.grayscale.gray500}>
                    {`Sending from work email, ${campaign?.emailAlias?.email}`}
                  </BodySmall>
                  <ExternalLink
                    display="inline"
                    href={"https://help.dover.com/en/articles/6480907-when-does-dover-use-my-work-email"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BodySmall color={colors.link}>Learn More</BodySmall>
                  </ExternalLink>
                </Stack>
              </Box>
            )}
          </Box>
          <Spacer height="24px" />
          {(campaign.threadMessages ?? []).map((message, idx) => {
            return (
              <>
                {idx === 0 && <Heading>Initial outreach</Heading>}
                <Spacer height={16} />
                <Box>
                  <CampaignMessageEditor
                    key={`${campaign.id!}.${idx}`}
                    idx={idx}
                    initialBodyHtmlTemplate={message.bodyTemplate}
                    initialSubjectHtmlTemplate={message.subjectTemplate}
                    senderUser={senderUser}
                  />
                </Box>
                <Spacer height={40} />
              </>
            );
          })}
          {CreateNewMessageButton}
        </Box>
      </EditorBackground>
    </>
  );
};

export default CampaignEditor;
