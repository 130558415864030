/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeedbackTemplateQuestionComponent,
    FeedbackTemplateQuestionComponentFromJSON,
    FeedbackTemplateQuestionComponentFromJSONTyped,
    FeedbackTemplateQuestionComponentToJSON,
} from './';

/**
 * 
 * @export
 * @interface TextFieldFeedbackTemplateQuestionAllOf
 */
export interface TextFieldFeedbackTemplateQuestionAllOf {
    /**
     * 
     * @type {FeedbackTemplateQuestionComponent}
     * @memberof TextFieldFeedbackTemplateQuestionAllOf
     */
    component: FeedbackTemplateQuestionComponent;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestionAllOf
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestionAllOf
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestionAllOf
     */
    placeholder?: string;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestionAllOf
     */
    helperText?: string;
    /**
     * 
     * @type {string}
     * @memberof TextFieldFeedbackTemplateQuestionAllOf
     */
    type?: TextFieldFeedbackTemplateQuestionAllOfTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TextFieldFeedbackTemplateQuestionAllOf
     */
    largeTextArea?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum TextFieldFeedbackTemplateQuestionAllOfTypeEnum {
    Text = 'text'
}

export function TextFieldFeedbackTemplateQuestionAllOfFromJSON(json: any): TextFieldFeedbackTemplateQuestionAllOf {
    return TextFieldFeedbackTemplateQuestionAllOfFromJSONTyped(json, false);
}

export function TextFieldFeedbackTemplateQuestionAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextFieldFeedbackTemplateQuestionAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': FeedbackTemplateQuestionComponentFromJSON(json['component']),
        'name': json['name'],
        'label': json['label'],
        'placeholder': !exists(json, 'placeholder') ? undefined : json['placeholder'],
        'helperText': !exists(json, 'helper_text') ? undefined : json['helper_text'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'largeTextArea': !exists(json, 'large_text_area') ? undefined : json['large_text_area'],
    };
}

export function TextFieldFeedbackTemplateQuestionAllOfToJSON(value?: TextFieldFeedbackTemplateQuestionAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': FeedbackTemplateQuestionComponentToJSON(value.component),
        'name': value.name,
        'label': value.label,
        'placeholder': value.placeholder,
        'helper_text': value.helperText,
        'type': value.type,
        'large_text_area': value.largeTextArea,
    };
}


