import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { theme } from "components/data-driven-forms/styles/theme";

type Props = {
  error?: boolean;
  halfWidth?: boolean;
  custom?: boolean;
};

export const TileWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: ${(props): any =>
    props?.custom ? "1fr 1fr 1fr 1fr 1fr" : props?.halfWidth ? "repeat(2, 50%)" : "repeat(3, 33%)"};
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &:nth-child(3) {
    margin-right: 0 !important;
  }
`;

export const StyledTile = styled.div`
  ${theme.buttons.secondary};
  margin-bottom: 0;
  border: 1px solid ${theme.colors.gray30};
  cursor: default;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledGraphic = styled(ReactSVG)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const HelperText = styled.div<Props>`
  color: ${(props): any => (props?.error ? theme.colors.red : theme.colors.almostBlack)};
  font-family: ${theme.body.b2.fontFamily};
  font-size: ${theme.body.b2.fontSize};
  font-weight: ${theme.body.b2.fontWeight};
`;
