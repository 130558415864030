import { CalendlyRole } from "App/components/Onboarding/AccountDetailsFormV2";
import { PRODUCT_CALENDLY, SALES_CALENDLY } from "components/constants";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";

/**
 * Returns the Calendly URL based on the user's role and referral status.
 * This relies on the getUserInfo API call to be pre-loaded, otherwise there will be a malformed URL while the call is loading
 * This call is generally required in all dover pages but could be a caveat somewhere down the line.
 */
export function useGetCalendlyUrl(
  utmMedium: string,
  utmSource: string,
  utmCampaign: string,
  forceSales?: boolean
): string {
  const { data: userInfo } = useGetAuthedUserInfoQuery();

  const roleTitleData = (userInfo?.roleTitleData as any) || {};
  const calendlyRole = roleTitleData["calendlyRole"];

  if (calendlyRole === CalendlyRole.Founder || forceSales) {
    return `${SALES_CALENDLY}?utm_medium=${utmMedium}&utm_source=${utmSource}&utm_campaign=${utmCampaign}`;
  }

  return `${PRODUCT_CALENDLY}?utm_medium=${utmMedium}&utm_source=${utmSource}&utm_campaign=${utmCampaign}`;
}
