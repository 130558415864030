import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useParams } from "react-router-dom";
import { useQueryParam, BooleanParam, withDefault } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import { JOB_PITCH_DRAWER_QUERY_PARAM } from "components/dover/JobPitch/data/queryParam";
import { useIsCompanyPitchComplete } from "services/doverapi/endpoints/company-setup/hooks";
import { useGetFeedbackTemplateQuery } from "services/doverapi/endpoints/feedbackTemplates";
import { useGetInitialCallSubstage } from "services/doverapi/endpoints/hiringPipelineStage";
import { useGetJobQuery } from "services/doverapi/endpoints/job/endpoints";
import { useIsJobSetupStepComplete } from "services/doverapi/endpoints/job/hooks";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { getCurrentJobFeatureStates } from "services/doverapi/endpoints/jobFeatureSettings/utils";
import { JobFeatureFeatureNameEnum, JobSetupStepStepNameEnum } from "services/openapi";
import { InternalLink, FakeLink } from "styles/typography";

interface DisableEnablingDoverInterviewerWithTooltipResponse {
  shouldDisable: boolean;
  // Note: (davin) I don't think it's ideal to return JSX from a hook, but I want to keep all disabling / tooltip logic in one place
  tooltipMessage: React.ReactElement | string;
}

export const useDisableEnablingDoverInterviewerWithTooltip = (): DisableEnablingDoverInterviewerWithTooltipResponse => {
  const { jobId } = useParams<{ jobId: string }>();
  const { data: job } = useGetJobQuery(jobId ? jobId : skipToken);

  const { jobFeatures, isFetchingJobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: ({ data, isFetching }) => {
      return {
        jobFeatures: getCurrentJobFeatureStates(data?.features || []),
        isFetchingJobFeatures: isFetching,
      };
    },
  });

  const [, setIsOpenJobPitchDrawerParam] = useQueryParam(
    JOB_PITCH_DRAWER_QUERY_PARAM,
    withDefault(BooleanParam, false)
  );

  // Derived states
  const embeddedRecruiterActive = !!jobFeatures?.[JobFeatureFeatureNameEnum.E2EScheduling];
  const isCompanyPitchComplete = useIsCompanyPitchComplete();
  const isJobPitchComplete = useIsJobSetupStepComplete(JobSetupStepStepNameEnum.PitchState, jobId);
  const initialCallSubstage = useGetInitialCallSubstage(jobId);
  const isFeedbackTemplateAssigned = !!initialCallSubstage?.defaultFeedbackTemplate;

  const { isDefaultClientFeedbackTemplate, isFetchingFeedbackTemplate } = useGetFeedbackTemplateQuery(
    initialCallSubstage?.defaultFeedbackTemplate || skipToken,
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          isDefaultClientFeedbackTemplate: data?.isDefault,
          isFetchingFeedbackTemplate: isFetching,
        };
      },
    }
  );

  const isCustomFeedbackTemplateAssigned = isFeedbackTemplateAssigned && !isDefaultClientFeedbackTemplate;

  // List of states that should disable the Dover Interviewer enable button
  const disablingStates = [
    !!job?.isSample,
    !embeddedRecruiterActive,
    !isCompanyPitchComplete,
    !isJobPitchComplete,
    !isCustomFeedbackTemplateAssigned,
  ];
  const isLoadingData = isFetchingJobFeatures || isFetchingFeedbackTemplate;
  const shouldDisable = disablingStates.some(state => state) || isLoadingData;
  let tooltipMessage: string | React.ReactElement = "";
  if (shouldDisable && !isLoadingData) {
    // Hierarchy of disabling reasons:
    // 1. if job is a sample job, simply state that the feature is not available
    if (job?.isSample) {
      tooltipMessage = "This feature is not available for sample jobs";
    }
    // 2. if Recruiting Partner is not active
    else if (!embeddedRecruiterActive) {
      tooltipMessage = "Please enable Recruiting Partner before using Dover Interviewer";
    }

    // 3. Else we list out the incomplete items (company pitch, job pitch, feedback template assigned to initial call)
    else {
      const incompleteItems: Array<React.ReactElement> = [];

      if (!isCompanyPitchComplete) {
        incompleteItems.push(
          <InternalLink
            $variant="secondary"
            to={APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.GENERAL)}
            target="_blank"
          >
            Company Pitch
          </InternalLink>
        );
      }
      if (!isJobPitchComplete) {
        incompleteItems.push(
          <FakeLink $variant="secondary" onClick={(): void => setIsOpenJobPitchDrawerParam(true)}>
            Job Pitch
          </FakeLink>
        );
      }
      if (!isCustomFeedbackTemplateAssigned) {
        if (jobId) {
          incompleteItems.push(
            <InternalLink $variant="secondary" to={APP_ROUTE_PATHS.job.interviewPlan(jobId)} target="_blank">
              Assign a custom Feedback Form
            </InternalLink>
          );
        }
      }

      tooltipMessage = (
        <>
          Please complete the following in order to enable Dover Interviewer:
          <ul style={{ margin: 0 }}>
            {incompleteItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </>
      );
    }
  }

  return { shouldDisable, tooltipMessage };
};
