import { Box } from "@mui/material";
import styled from "styled-components";

import { BodySmall } from "components/library/typography";
import { colors, screenSizes } from "styles/theme";
import {
  X_GUTTER,
  X_GUTTER_MOBILE,
  LEFT_PANEL_WIDTH,
  RIGHT_PANEL_WIDTH,
  MIDDLE_SPACER_WIDTH,
} from "views/inboundExternal/constants";

interface HeaderProps {
  center?: boolean;
}

export const Header = styled.div<HeaderProps>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: ${({ center }): string => (center ? "center" : "flex-start")};
  align-items: center;
  padding: 0 ${X_GUTTER};

  @media (max-width: ${screenSizes.tablet}) {
    padding-left: ${X_GUTTER_MOBILE};
  }
`;

export const HeaderBg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* set header content width equal to total width of all page content plus one x_gutter in order to
  responsively maintain left-alignment with JD */
  width: calc(${LEFT_PANEL_WIDTH} + ${RIGHT_PANEL_WIDTH} + ${MIDDLE_SPACER_WIDTH});
  max-width: 1800px;
  justify-content: center;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.grayscale.gray200};

  @media (max-width: ${screenSizes.laptop}) {
    width: calc(${LEFT_PANEL_WIDTH});
  }
`;

interface SizeProps {
  size?: "sm" | "lg";
}

export const StyledLogo = styled.img`
  z-index: 10;
  position: relative;
  height: 40px;
  object-fit: contain;
  object-position: left;
`;

export const CompanyName = styled.div<SizeProps>`
  font-family: Inter;
  font-size: ${(props): string => (props.size === "sm" ? "22px" : "36px")};
  font-weight: 600;
  z-index: 10;
  position: relative;
`;

export const HeaderTitle = styled.div<SizeProps>`
  font-size: ${(props): string => (props.size === "sm" ? "18px" : "32px")};
  line-height: ${(props): string => (props.size === "sm" ? "24px" : "36px")};
  font-weight: 600;
  font-family: Inter;
  position: relative;
  z-index: 10;

  @media (max-width: ${screenSizes.tablet}) {
    font-size: ${(props): string => (props.size === "sm" ? "16px" : "24px")};
  }
`;

export const JobInfoBox = styled.div<SizeProps>`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const SectionHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${(props): string => props.color ?? colors.black};
`;

export const FormLabel = styled(BodySmall)`
  margin-bottom: 8px;
`;

export const StyledHtmlWrapper = styled(Box)`
  font-family: Inter;
  h1,
  h2 {
    font-style: normal;
    line-height: normal;

    &:not(:first-of-type) {
      margin-top: 24px;
    }
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  ul {
    padding-left: 32px;
  }

  li p {
    margin: 0;
  }

  strong {
    font-weight: 700;
  }

  img {
    max-height: 450px;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 8px;
    margin-top: 8px;
  }
`;
