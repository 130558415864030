import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useEnrichedJobSetupSteps } from "services/doverapi/cross-endpoint-hooks/useGetJobSetupSteps";
import { EnrichedJobSetupStep } from "services/doverapi/endpoints/job/types";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { JobFeatureFeatureNameEnum, JobSetupStepStateEnum } from "services/openapi";

export function useGetIncompleteJobSetupStepsForFeature(
  featureName: JobFeatureFeatureNameEnum,
  jobId: string | undefined
): EnrichedJobSetupStep[] | undefined {
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);
  const enrichedSetupSteps = useEnrichedJobSetupSteps({ jobId, disregardStepRelevancy: true });
  return useMemo(() => {
    if (!jobFeatures) {
      return undefined;
    }

    const feature = jobFeatures.features.find(jobFeature => jobFeature.featureName === featureName);
    if (!feature) {
      return undefined;
    }

    return enrichedSetupSteps.filter(
      step =>
        feature.dependentJobSetupSteps.includes(step.stepName) &&
        [JobSetupStepStateEnum.Pending, JobSetupStepStateEnum.StartedPendingClientAction].includes(step.setupState)
    );
  }, [enrichedSetupSteps, featureName, jobFeatures]);
}
