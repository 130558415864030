import { createSlice } from "@reduxjs/toolkit";

import { AdminDataReducerState } from "domains/admin/types";

export const adminDataReducerKey = "adminDataReducerKey";

export const initialState: AdminDataReducerState = {
  requiresSelectedClient: undefined,
};

const adminDataSlice = createSlice({
  name: "AdminDataStore",
  initialState: initialState,
  reducers: {
    setRequiresSelectedClient: (state, action): void => {
      state.requiresSelectedClient = action.payload;
    },
  },
});

export const setRequiresSelectedClient = adminDataSlice.actions.setRequiresSelectedClient;
export const adminDataReducer = adminDataSlice.reducer;
