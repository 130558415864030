/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicationQuestionInboundApplicationResponse,
    ApplicationQuestionInboundApplicationResponseFromJSON,
    ApplicationQuestionInboundApplicationResponseFromJSONTyped,
    ApplicationQuestionInboundApplicationResponseToJSON,
    InboundApplicationCandidate,
    InboundApplicationCandidateFromJSON,
    InboundApplicationCandidateFromJSONTyped,
    InboundApplicationCandidateToJSON,
    InboundApplicationJob,
    InboundApplicationJobFromJSON,
    InboundApplicationJobFromJSONTyped,
    InboundApplicationJobToJSON,
} from './';

/**
 * 
 * @export
 * @interface InboundApplicationResponse
 */
export interface InboundApplicationResponse {
    /**
     * 
     * @type {Array<ApplicationQuestionInboundApplicationResponse>}
     * @memberof InboundApplicationResponse
     */
    readonly applicationQuestions?: Array<ApplicationQuestionInboundApplicationResponse>;
    /**
     * 
     * @type {string}
     * @memberof InboundApplicationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InboundApplicationResponse
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof InboundApplicationResponse
     */
    state: string;
    /**
     * 
     * @type {InboundApplicationCandidate}
     * @memberof InboundApplicationResponse
     */
    candidate: InboundApplicationCandidate;
    /**
     * 
     * @type {InboundApplicationJob}
     * @memberof InboundApplicationResponse
     */
    job: InboundApplicationJob;
    /**
     * 
     * @type {string}
     * @memberof InboundApplicationResponse
     */
    additionalInfo?: string;
}

export function InboundApplicationResponseFromJSON(json: any): InboundApplicationResponse {
    return InboundApplicationResponseFromJSONTyped(json, false);
}

export function InboundApplicationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InboundApplicationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationQuestions': !exists(json, 'application_questions') ? undefined : ((json['application_questions'] as Array<any>).map(ApplicationQuestionInboundApplicationResponseFromJSON)),
        'id': json['id'],
        'email': json['email'],
        'state': json['state'],
        'candidate': InboundApplicationCandidateFromJSON(json['candidate']),
        'job': InboundApplicationJobFromJSON(json['job']),
        'additionalInfo': !exists(json, 'additional_info') ? undefined : json['additional_info'],
    };
}

export function InboundApplicationResponseToJSON(value?: InboundApplicationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'state': value.state,
        'candidate': InboundApplicationCandidateToJSON(value.candidate),
        'job': InboundApplicationJobToJSON(value.job),
        'additional_info': value.additionalInfo,
    };
}


