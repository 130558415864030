import { Icon } from "@doverhq/dover-ui";
import { Box, Stack, Switch } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ExternalLinkSVG } from "assets/icons/external-link.svg";
import { ReactComponent as PencilIconSVG } from "assets/icons/pencil-edit.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { useHasElevatedPermissions } from "components/RBAC";
import { useSubmitJobCandidateSourceFormMutation } from "services/doverapi/endpoints/job-source-settings/endpoints";
import { JobCandidateSourceSettingDesiredStateEnum, JobLocation } from "services/openapi";
import { colors } from "styles/theme";
import { ExternalLink, InternalLink } from "styles/typography";
import { showErrorToast } from "utils/showToast";
import { SwitchLabelControl } from "views/CompanySetup/components/Notifications/styles";

export const JobPostingCell = (params: GridRenderCellParams): React.ReactElement => {
  // Show two locations tops, and include remainder as "+X more" if more than two
  const totalLocationsCount = params.row.locations.length;
  const locations =
    totalLocationsCount <= 2 ? params.row.locations : [params.row.locations[0], params.row.locations[1]];

  return (
    <Stack>
      <Box sx={{ textWrap: "wrap" }}>
        <BodySmall weight="600">{params.row.title}</BodySmall>
      </Box>
      <Box>
        {locations.map((location: JobLocation) => (
          <BodySmall key={`${params.row.id}-${location.locationOptionId}`} color={colors.grayscale.gray500}>
            {location.name}
          </BodySmall>
        ))}
        {totalLocationsCount > 2 && <BodySmall>{`+${totalLocationsCount - 2} more`}</BodySmall>}
      </Box>
    </Stack>
  );
};

export const PublishJobCell = (params: GridRenderCellParams): React.ReactElement => {
  // Perms check
  const hasElevatedPermissions = useHasElevatedPermissions(params.row.id);

  // Mutations
  const [submitJobCandidateSourceForm, { isLoading }] = useSubmitJobCandidateSourceFormMutation();

  // Copy text helpers
  const getTooltipMessage = (hasElevatedPermissions: boolean, isPublished: boolean): string => {
    if (!hasElevatedPermissions) {
      return `Only hiring team members can ${isPublished ? "hide" : "publish"} this job`;
    }
    return "";
  };

  const handlePublishJob = async (): Promise<void> => {
    if (!params.row.sourceId) {
      showErrorToast("Error publishing to careers page");
      return;
    }

    await submitJobCandidateSourceForm({
      jobId: params.row.id,
      candidateSourceId: params.row.sourceId,
      desiredState: params.row.isPublished
        ? JobCandidateSourceSettingDesiredStateEnum.Inactive
        : JobCandidateSourceSettingDesiredStateEnum.Active,
    });
  };

  const isSample = params.row.isSample;
  return (
    <Tooltip
      title={
        isSample
          ? "Cannot publish sample jobs to careers page"
          : getTooltipMessage(hasElevatedPermissions, params.row.isPublished)
      }
    >
      <SwitchLabelControl
        checked={params.row.isPublished}
        disabled={isLoading || !hasElevatedPermissions || isSample}
        onChange={async (): Promise<void> => await handlePublishJob()}
        control={<Switch sx={{ m: 1 }} />}
        label=""
        sx={{ color: params.row.isPublished ? colors.primary.base : colors.grayscale.gray400 }}
      />
    </Tooltip>
  );
};

export const ActionsCell = (params: GridRenderCellParams): React.ReactElement => {
  return (
    <Stack direction="row" spacing={2}>
      <ExternalLink href={APP_ROUTE_PATHS.job.jobPosting(params.row.id)} rel="noopener noreferrer" target="_blank">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Icon Icon={PencilIconSVG} color="transparent" />
          <BodySmall color={colors.grayscale.gray500}>Edit</BodySmall>
        </Stack>
      </ExternalLink>
      <InternalLink
        to={APP_ROUTE_PATHS.inboundApplicationPortal(params.row.clientName, params.row.id)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Stack direction="row" spacing={0.7} alignItems="center">
          <Icon Icon={ExternalLinkSVG} color="transparent" />
          <BodySmall color={colors.grayscale.gray500}>View</BodySmall>
        </Stack>
      </InternalLink>
    </Stack>
  );
};
