/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnboardingMultipartInterviewStage,
    OnboardingMultipartInterviewStageFromJSON,
    OnboardingMultipartInterviewStageFromJSONTyped,
    OnboardingMultipartInterviewStageToJSON,
} from './';

/**
 * 
 * @export
 * @interface OnboardingHiringPipelineStage
 */
export interface OnboardingHiringPipelineStage {
    /**
     * 
     * @type {string}
     * @memberof OnboardingHiringPipelineStage
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingHiringPipelineStage
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OnboardingHiringPipelineStage
     */
    doverStage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingHiringPipelineStage
     */
    atsStageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingHiringPipelineStage
     */
    state?: OnboardingHiringPipelineStageStateEnum;
    /**
     * 
     * @type {string}
     * @memberof OnboardingHiringPipelineStage
     */
    readonly setupState?: OnboardingHiringPipelineStageSetupStateEnum;
    /**
     * 
     * @type {OnboardingMultipartInterviewStage}
     * @memberof OnboardingHiringPipelineStage
     */
    multipartInterviewStage?: OnboardingMultipartInterviewStage;
    /**
     * 
     * @type {string}
     * @memberof OnboardingHiringPipelineStage
     */
    readonly contentTypeName?: string;
    /**
     * A relative ordering on active HiringPipelineStages.
     * @type {number}
     * @memberof OnboardingHiringPipelineStage
     */
    orderIndex?: number | null;
}

/**
* @export
* @enum {string}
*/
export enum OnboardingHiringPipelineStageStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}/**
* @export
* @enum {string}
*/
export enum OnboardingHiringPipelineStageSetupStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function OnboardingHiringPipelineStageFromJSON(json: any): OnboardingHiringPipelineStage {
    return OnboardingHiringPipelineStageFromJSONTyped(json, false);
}

export function OnboardingHiringPipelineStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingHiringPipelineStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'doverStage': !exists(json, 'dover_stage') ? undefined : json['dover_stage'],
        'atsStageId': !exists(json, 'ats_stage_id') ? undefined : json['ats_stage_id'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'setupState': !exists(json, 'setup_state') ? undefined : json['setup_state'],
        'multipartInterviewStage': !exists(json, 'multipart_interview_stage') ? undefined : OnboardingMultipartInterviewStageFromJSON(json['multipart_interview_stage']),
        'contentTypeName': !exists(json, 'content_type_name') ? undefined : json['content_type_name'],
        'orderIndex': !exists(json, 'order_index') ? undefined : json['order_index'],
    };
}

export function OnboardingHiringPipelineStageToJSON(value?: OnboardingHiringPipelineStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dover_stage': value.doverStage,
        'ats_stage_id': value.atsStageId,
        'state': value.state,
        'multipart_interview_stage': OnboardingMultipartInterviewStageToJSON(value.multipartInterviewStage),
        'order_index': value.orderIndex,
    };
}


