/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveSampleCandidatesRequest
 */
export interface RemoveSampleCandidatesRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveSampleCandidatesRequest
     */
    jobId: string;
}

export function RemoveSampleCandidatesRequestFromJSON(json: any): RemoveSampleCandidatesRequest {
    return RemoveSampleCandidatesRequestFromJSONTyped(json, false);
}

export function RemoveSampleCandidatesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveSampleCandidatesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': json['job_id'],
    };
}

export function RemoveSampleCandidatesRequestToJSON(value?: RemoveSampleCandidatesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_id': value.jobId,
    };
}


