import { Stack } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import { useBasicResumeUploader } from "components/BasicResumeUploader";
import JobSelector from "components/JobSelector/JobSelector";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { TextField } from "components/library/TextField";
import { Body } from "components/library/typography";
import { ParseCandidateInfoResult } from "services/openapi";
import { useSubmitAgencyCandidateMutation } from "views/AgencyForm/endpoints";
import { SubmitAgencyCandidateFormSchemaType } from "views/AgencyForm/types";
import { ReferralJob } from "views/ReferralForm";

interface CandidateFormProps {
  jobs: ReferralJob[];
  onNext: () => void;
}

export const CandidateForm = ({ jobs, onNext }: CandidateFormProps): React.ReactElement => {
  const [selectedJobId, setSelectedJobId] = React.useState<string>(jobs[0].id!);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    setValue,
    getValues,
    trigger,
  } = useFormContext<SubmitAgencyCandidateFormSchemaType>();

  const onSuccessfulUpload = (resume: File): void => {
    setValue("resume", resume);
  };

  const onParseSuccessful = (result: ParseCandidateInfoResult): void => {
    if (result.firstName && !getValues("candidateFirstName")) {
      setValue("candidateFirstName", result.firstName);
    }
    if (result.lastName && !getValues("candidateLastName")) {
      setValue("candidateLastName", result.lastName);
    }
    if (result.email && !getValues("candidateEmail")) {
      setValue("candidateEmail", result.email);
    }
    if (result.linkedinUrl && !getValues("candidateLinkedinUrl")) {
      setValue("candidateLinkedinUrl", result.linkedinUrl);
    }
    trigger();
  };

  const onClearUpload = (): void => {
    setValue("resume", undefined);
  };

  const [isParsingResume, basicResumeUploader] = useBasicResumeUploader({
    onSuccessfulUpload,
    onParseSuccessful,
    onClearUpload,
  });

  const [submitAgencyCandidate, { isLoading }] = useSubmitAgencyCandidateMutation();
  const addAgencyCandidate = async (): Promise<void> => {
    await submitAgencyCandidate({
      jobId: getValues("jobId"),
      referrerId: getValues("referrerId"),
      candidateFirstName: getValues("candidateFirstName"),
      candidateLastName: getValues("candidateLastName"),
      candidateEmail: getValues("candidateEmail"),
      candidateLinkedinUrl: getValues("candidateLinkedinUrl"),
      resume: getValues("resume") as Blob,
      additionalComments: getValues("additionalComments"),
    }).unwrap();

    onNext();
  };

  const onSubmitClick = async (): Promise<void> => {
    setValue("jobId", selectedJobId);
    handleSubmit(addAgencyCandidate)();
  };

  return (
    <Card fullWidth>
      <Stack spacing={2}>
        <Stack spacing={0.5}>
          <Body weight="500">Job</Body>

          <JobSelector
            jobs={jobs}
            jobId={selectedJobId}
            setJobId={(jobId: string): void => {
              setSelectedJobId(jobId);
            }}
          />
        </Stack>

        <Stack spacing={1}>
          <Stack direction="row" justifyContent="flex-start" spacing={0.5}>
            <Body weight="500">Resume</Body>
          </Stack>
          {basicResumeUploader}
        </Stack>
        <TextField
          title={`First name *`}
          required={true}
          inputProps={{ ...register("candidateFirstName") }}
          errorMessage={formErrors.candidateFirstName?.message}
          error={!!formErrors.candidateFirstName}
          autoFocus
          loading={isParsingResume}
        />
        <TextField
          title={`Last Name *`}
          required={true}
          inputProps={{ ...register("candidateLastName") }}
          errorMessage={formErrors.candidateLastName?.message}
          error={!!formErrors.candidateLastName}
          loading={isParsingResume}
        />
        <TextField
          title={`Email *`}
          required={true}
          inputProps={{ ...register("candidateEmail") }}
          errorMessage={formErrors.candidateEmail?.message}
          error={!!formErrors.candidateEmail}
          loading={isParsingResume}
        />
        <TextField
          title={`LinkedIn URL`}
          placeholderText="https://linkedin.com/in/john-doe"
          required={true}
          inputProps={{ ...register("candidateLinkedinUrl") }}
          errorMessage={formErrors.candidateLinkedinUrl?.message}
          error={!!formErrors.candidateLinkedinUrl}
          loading={isParsingResume}
        />
        <TextField
          title={`Additional Comments`}
          required={true}
          inputProps={{ ...register("additionalComments") }}
          errorMessage={formErrors.additionalComments?.message}
          error={!!formErrors.additionalComments}
          multiline
          minRows={3}
        />
        <Stack alignItems={"flex-end"} minHeight={"43px"}>
          <Button
            disabled={
              !getValues("candidateFirstName") || !getValues("candidateLastName") || !getValues("candidateEmail")
            }
            variant={ButtonVariant.Primary}
            onClick={onSubmitClick}
            loading={isLoading}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
