import { Checkbox, Stack, Skeleton } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams, GridSortDirection } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import LinkedInIcon from "assets/icons/linkedin-logo-placeholder.svg";
import { GmailAuthButton } from "components/dover/GmailAuth";
import { useUserAuthedSuccessfully } from "components/dover/GmailAuth/hooks";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall, Body, Heading } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { useUserManagedSchedulingConfig } from "services/doverapi/cross-endpoint-hooks/useGetSchedulingConfig";
import { useListPipelineCandidatesQuery } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useDeactivateJobMutation } from "services/doverapi/endpoints/job";
import {
  CandidateFilterStatusEnum,
  DeactivateJobDeactivationReasonEnum,
  HiringPipelineStageType,
  RespondedSubstageEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { PointerOnHoverReactSVG, StyledDataGrid } from "views/job/JobSetup/steps/Overview/styles";
import { useRealHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";
import { ModalKeyType } from "views/job/types";

interface DeactivateConfirmationModalProps {
  shouldShowModal: string;
  handleCloseModal: () => void;
  handleOpenCompletionModal: () => void;
  handlePrevModal: (() => void) | undefined;
  modalKey: ModalKeyType;
  jobName: string | undefined;
  selectedDeactivationReason: DeactivateJobDeactivationReasonEnum | undefined;
  isSampleJob?: boolean;
}

export const DeactivateConfirmationModal = ({
  shouldShowModal,
  handleCloseModal,
  handleOpenCompletionModal,
  handlePrevModal,
  modalKey,
  jobName,
  selectedDeactivationReason,
  isSampleJob = false,
}: DeactivateConfirmationModalProps): React.ReactElement => {
  const { userAuthedSuccessfully } = useUserAuthedSuccessfully();

  // Get job from URL
  const { jobId } = useParams<{ jobId: string | undefined }>();

  const hasUserManagedScheduling = useUserManagedSchedulingConfig(jobId);
  // Local state
  const [selectedRows, setSelectedRows] = useState<Array<string | number>>([]);
  const [sortModel, setSortModel] = useState([
    {
      field: "stage",
      sort: "asc" as GridSortDirection,
    },
  ]);
  const [cancelAllCalls, setCancelAllCalls] = useState<boolean>(false);

  // Fetch data
  const { stages: hiringPipelinesForJob, isLoading: hiringPipelinesLoading } = useRealHps();

  // at this point we want to build a list of pipelines within which we want to reject candidates
  // these are responded.interested and all interview stages
  const interviewHPSs = hiringPipelinesForJob?.filter(hps => hps.stageType === HiringPipelineStageType.INTERVIEW);
  const respondedInterviewHPS = hiringPipelinesForJob?.find(hps => hps.stageType === HiringPipelineStageType.RESPONDED);
  const interviewPipelineStages =
    interviewHPSs?.map(hps => {
      return {
        pipelineStageId: hps.id,
        pipelineSubstages: [],
      };
    }) ?? [];
  const clientReviewPipeline = respondedInterviewHPS
    ? [
        {
          pipelineStageId: respondedInterviewHPS.id!,
          pipelineSubstages: [RespondedSubstageEnum.INTERESTED],
        },
      ]
    : [];

  // want to show people from client review, and all interview stages
  const pipelinesToReject = [...clientReviewPipeline, ...interviewPipelineStages];

  const { data: candidatesResult, isFetching: isFetchingCandidates } = useListPipelineCandidatesQuery(
    jobId && !hiringPipelinesLoading
      ? {
          args: {
            jobId: jobId,
            data: {
              filters: {
                pipelineStages: pipelinesToReject,
                status: [CandidateFilterStatusEnum.Active],
                modified: { maxDaysAgo: 90 },
              },
            },
            limit: 1000,
          },
        }
      : skipToken
  );

  // since candidates loading depends on hiring pipeline stage loading, we should batch them together
  const awaitingCandidatesFetching = hiringPipelinesLoading || isFetchingCandidates;

  // Mutations
  const [deactivateJob, { isLoading: isDeactivatingJob }] = useDeactivateJobMutation();

  // Derived data
  const candidates = candidatesResult?.results ?? [];
  const { data: client, isLoading: clientLoading } = useGetUsersClientQuery();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params: GridValueGetterParams): string => params.row.contact.fullName ?? "-",
      renderCell: (params: GridRenderCellParams): React.ReactElement => (
        <Stack direction="row" spacing={2} alignItems="center" sx={{ marginLeft: "5px" }}>
          <BodySmall onClick={(): void => handleNameClick(params.id as string)}>
            {params.row.contact.fullName ?? "-"}
          </BodySmall>
          <PointerOnHoverReactSVG
            src={LinkedInIcon}
            style={{ marginTop: "-3px" }}
            onClick={(): void => {
              window.open(
                `${
                  params.row.contact.socialLinks?.find((socialLink: any) => {
                    return socialLink.linkType === "LINKEDIN";
                  })?.url
                }`,
                "_blank",
                "noopener noreferrer"
              );
            }}
          />
        </Stack>
      ),
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 0.5,
      minWidth: 150,
      // order null indices at the end
      valueGetter: (params: GridValueGetterParams): string => params.row.candidatePipelineStage?.orderIndex ?? 10000,
      renderCell: (params: GridRenderCellParams): React.ReactElement => (
        <Stack sx={{ marginLeft: "5px" }}>
          <BodySmall>{params.row.candidatePipelineStage?.name || "-"}</BodySmall>
        </Stack>
      ),
    },
  ];

  const activeCandidateTableMaxHeight = 372;
  const activeCandidateRowHeight = 56;
  const activeCandidateTableHeight = activeCandidateRowHeight * (candidates?.length + 1) + 2;
  const showCandidateTable = candidates?.length > 0;
  const oneActiveCandidate = candidates?.length === 1;

  const gmailNeedsAuth = hasUserManagedScheduling && !userAuthedSuccessfully && selectedRows.length > 0;

  const deactivationText =
    (selectedDeactivationReason === DeactivateJobDeactivationReasonEnum.Hired ? "Congrats on the hire! " : "") +
    "Please read through the next steps you'll need to take once this job is deactivated.";

  const formatCandidatesToReject = useMemo(() => {
    return selectedRows.map((candidateId: string | number) => ({ id: candidateId as string }));
  }, [selectedRows]);

  // Callbacks
  const handleNameClick = useCallback((candidate_id: string): void => {
    window.open(`/candidates/${candidate_id}`, "_blank", "noopener noreferrer");
  }, []);

  const handleDeactivate = useCallback(() => {
    const deactivateJobAndOpenModal = async (): Promise<void> => {
      let deactivationReason = selectedDeactivationReason;
      if (isSampleJob) {
        deactivationReason = DeactivateJobDeactivationReasonEnum.DePrioritizedJobNoLongerHiring;
      }

      if (!deactivationReason) {
        console.error("No deactivation reason selected");
        return;
      }
      await deactivateJob({
        jobId: jobId!,
        // if isSampleJob want to call deactivate job in the most basic way
        deactivationReason: deactivationReason,
        candidatesToReject: !selectedRows || isSampleJob ? undefined : formatCandidatesToReject,
        onlyCancelDiInterviews: isSampleJob ? true : !cancelAllCalls, // if not true, will just deactivate DI
      });
      handleOpenCompletionModal();
    };

    deactivateJobAndOpenModal();
  }, [
    cancelAllCalls,
    deactivateJob,
    formatCandidatesToReject,
    handleOpenCompletionModal,
    isSampleJob,
    jobId,
    selectedDeactivationReason,
    selectedRows,
  ]);

  const SampleJobConfirmation = <Body>Are you sure you want to deactivate your Sample Job?</Body>;

  const ConfirmationBody = (
    <Stack spacing={3}>
      <BodySmall>{deactivationText}</BodySmall>
      {showCandidateTable && (
        <Stack spacing={1}>
          <BodySmall weight="700">
            {"1. Select which candidates you'd like Dover to send "}
            {selectedDeactivationReason === DeactivateJobDeactivationReasonEnum.DePrioritizedJobStillHiring
              ? "a handoff note to "
              : "rejection notes to "}
          </BodySmall>
          {awaitingCandidatesFetching ? (
            <DoverLoadingSpinner />
          ) : (
            <BodySmall>
              {`There ${oneActiveCandidate ? "is" : "are"} currently `}
              <b>{`${candidates?.length ?? ""} candidate${oneActiveCandidate ? "" : "s"}`}</b>
              {` in your process that ${
                oneActiveCandidate ? "has" : "have"
              } been active in the past 90 days. You can select candidates for
                Dover to ${
                  selectedDeactivationReason === DeactivateJobDeactivationReasonEnum.DePrioritizedJobStillHiring
                    ? "send a handoff note to"
                    : "reject"
                } below. All candidates you do not select will need to be contacted by you directly either through email
                or your ATS. You can use the `}
              <InternalLink to={`/candidates/?job=${jobId}&status=ACTIVE`} target="_blank" rel="noopener noreferrer">
                {"pipeline view"}
              </InternalLink>
              {" to locate the candidate's contact information."}
            </BodySmall>
          )}
          <div style={{ height: activeCandidateTableHeight, maxHeight: activeCandidateTableMaxHeight }}>
            <StyledDataGrid
              loading={awaitingCandidatesFetching}
              rowHeight={activeCandidateRowHeight}
              rows={candidates ?? []}
              columns={columns}
              hideFooter
              checkboxSelection
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={(model): void => setSortModel(model)}
              onSelectionModelChange={(ids: Array<string | number>): void => {
                setSelectedRows(ids);
              }}
            />
          </div>
          <BodySmall color={colors.grayscale.gray500}>
            {"More information about the notes we send when you deactivate a job can be found "}
            <a
              href="https://help.dover.com/en/articles/6297904-how-can-i-deactivate-a-job-in-dover#h_b3fa70e69c"
              target="_blank"
              rel="noopener noreferrer"
            >
              {"here"}
            </a>
            {". Please make sure your pipeline is accurate to ensure you are not sending a double rejection."}
          </BodySmall>
          {gmailNeedsAuth && (
            <>
              <BodySmall weight="700">
                To send rejection notes to the selected candidates, please authenticate your gmail.
              </BodySmall>
              <GmailAuthButton />
            </>
          )}
        </Stack>
      )}
      <Stack spacing={1}>
        <BodySmall>{"If you're using Dover for interviews, all calls will be cancelled."}</BodySmall>
        {clientLoading ? (
          <Skeleton width="95%" height="40px" />
        ) : (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ ":hover": { cursor: "pointer" } }}
            onClick={(): void => setCancelAllCalls(prev => !prev)}
          >
            <Checkbox checked={cancelAllCalls} />
            <BodySmall>{`Also cancel all calls with the ${client?.name} team`}</BodySmall>
          </Stack>
        )}
        <BodySmall>
          {"The Slack channel will be deactivated within the next few days. Please don't hesitate to ping us at "}
          <a
            href="mailto:support@dover.com?Subject=Job%20Deactivation%20Help"
            target="_blank"
            rel="noopener noreferrer"
          >
            {"support@dover.com"}
          </a>
          {" if you need help."}
        </BodySmall>
      </Stack>
    </Stack>
  );

  return (
    <CustomModal
      open={shouldShowModal === modalKey}
      onClose={handleCloseModal}
      title={<Heading>{`Deactivate ${jobName ? jobName : "Job"}?`}</Heading>}
      maxWidth={"sm"}
      dialogActions={
        <Stack width="100%" direction="row" spacing={2} justifyContent="flex-end">
          {!!handlePrevModal && (
            <Button variant={ButtonVariant.Secondary} onClick={handlePrevModal}>
              Previous
            </Button>
          )}
          <Button
            variant={ButtonVariant.SecondaryCritical}
            loading={isDeactivatingJob}
            onClick={handleDeactivate}
            disabled={gmailNeedsAuth || clientLoading}
          >
            {"Deactivate"}
          </Button>
        </Stack>
      }
    >
      {isSampleJob ? SampleJobConfirmation : ConfirmationBody}
    </CustomModal>
  );
};
