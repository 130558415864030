import { Stack } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { Overline } from "components/library/typography";
import { colors } from "styles/theme";

const StyledReactSVG = styled(ReactSVG)`
  display: flex;
  svg {
    height: 16px;
    width: 16px;
  }
`;

interface FilterSectionHeaderProps {
  title: React.ReactElement | string;
  icon: string;
}

const FilterSectionHeader = React.memo(
  ({ title, icon }: FilterSectionHeaderProps): React.ReactElement => {
    return (
      <Stack alignItems="center" direction="row" spacing={1} padding="12px 16px">
        <StyledReactSVG src={icon} />
        {typeof title === "string" ? <Overline color={colors.grayscale.gray500}>{title}</Overline> : title}
      </Stack>
    );
  }
);

export default FilterSectionHeader;
