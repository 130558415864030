/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FieldOfStudyTier2
 */
export interface FieldOfStudyTier2 {
    /**
     * 
     * @type {string}
     * @memberof FieldOfStudyTier2
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FieldOfStudyTier2
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FieldOfStudyTier2
     */
    patterns: Array<string>;
}

export function FieldOfStudyTier2FromJSON(json: any): FieldOfStudyTier2 {
    return FieldOfStudyTier2FromJSONTyped(json, false);
}

export function FieldOfStudyTier2FromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldOfStudyTier2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'patterns': json['patterns'],
    };
}

export function FieldOfStudyTier2ToJSON(value?: FieldOfStudyTier2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'patterns': value.patterns,
    };
}


