import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import React from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ACTIVE_QUERY_PARAM } from "components/dover/SearchesTable/constants";
import { BodySmall } from "components/library/typography";
import { colors } from "styles/theme";

const StatusFilter = (): React.ReactElement => {
  const [active, setActive] = useQueryParam(ACTIVE_QUERY_PARAM, BooleanParam);

  return (
    <Stack spacing={1.5}>
      <Stack
        color={colors.grayscale.gray500}
        alignItems="flex-start"
        justifyContent="space-between"
        sx={{ cursor: "pointer" }}
      >
        <BodySmall color={colors.grayscale.gray500} weight="600">
          Status
        </BodySmall>
      </Stack>

      <FormControlLabel
        sx={{
          margin: 0,

          ".MuiFormControlLabel-label": {
            fontFamily: "Inter",
            fontSize: "14px",
          },
        }}
        control={
          <Checkbox
            checked={!!active}
            onChange={(): void => setActive(!active)}
            sx={{
              padding: 0,
              position: "relative",
              right: "2px",
              marginRight: "4px",
              color: colors.grayscale.gray300,
              "&.Mui-checked": {
                color: colors.primary.base,
              },
            }}
          />
        }
        label={"Active only"}
        labelPlacement={"end"}
      />
    </Stack>
  );
};

export default StatusFilter;
