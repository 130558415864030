import { Stack } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import Modal from "components/Modal";
import { modalAtom, useModal } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";

//------------------------------------------------------------------------------
// Component

interface CandidateStageStatusConfirmModal extends GlobalModalProps {
  confirm: () => void;
}

const CandidateStageStatusConfirmModal = ({ confirm }: CandidateStageStatusConfirmModal): React.ReactElement => {
  const { isOpen, close } = useModal(candidateStageStatusConfirmModal);

  return (
    <Modal
      open={isOpen}
      onClose={close}
      maxWidth="sm"
      title="Confirm change?"
      dialogActions={
        <Stack direction="row" spacing={1}>
          <Button variant={ButtonVariant.Secondary} onClick={close}>
            Cancel
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            onClick={(): void => {
              confirm();
              close();
            }}
          >
            Confirm
          </Button>
        </Stack>
      }
    >
      <Body>
        Changing this candidate&apos;s stage/status will make the activity feed viewable to all members of your
        organization. Are you sure you want to do this?
      </Body>
    </Modal>
  );
};

export const candidateStageStatusConfirmModal = modalAtom(CandidateStageStatusConfirmModal);
