/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CampaignStats
 */
export interface CampaignStats {
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    numContacted: number;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    numInterested: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    numOpened: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    readonly interestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CampaignStats
     */
    readonly openRate?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof CampaignStats
     */
    readonly earliestFirstContactedOn?: Date | null;
}

export function CampaignStatsFromJSON(json: any): CampaignStats {
    return CampaignStatsFromJSONTyped(json, false);
}

export function CampaignStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numContacted': json['num_contacted'],
        'numInterested': json['num_interested'],
        'numOpened': json['num_opened'],
        'interestRate': !exists(json, 'interest_rate') ? undefined : json['interest_rate'],
        'openRate': !exists(json, 'open_rate') ? undefined : json['open_rate'],
        'earliestFirstContactedOn': !exists(json, 'earliest_first_contacted_on') ? undefined : (json['earliest_first_contacted_on'] === null ? null : new Date(json['earliest_first_contacted_on'])),
    };
}

export function CampaignStatsToJSON(value?: CampaignStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'num_contacted': value.numContacted,
        'num_interested': value.numInterested,
        'num_opened': value.numOpened,
    };
}


