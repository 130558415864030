import { zodResolver } from "@hookform/resolvers/zod";
import Stack from "@mui/material/Stack";
import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Body, BodySmall, Subtitle2 } from "components/library/typography";
import UploadBox from "sections/addcandidate/components/hook-form/RHFUploadBox";
import CSVHelpTooltipButton from "sections/addcandidate/components/steps/CandidateInfoStep/components/CandidateInfoForm/CSVHelpTooltipButton";
import CSVSummary from "sections/addcandidate/components/steps/CandidateInfoStep/components/CSVSummaryField";
import { parseManualSourcingItemCSV } from "sections/addcandidate/components/steps/CandidateInfoStep/utils";
import { TWENTY_MEGA_BYTES } from "sections/addcandidate/data/misc";
import { BulkCandidateInfoSchema, bulkCandidateInfoZodSchema } from "sections/addcandidate/types/candidateInfo";
import { OutreachBulkCandidateItem } from "services/openapi";
import { colors } from "styles/theme";

interface BulkCandidateInfoFormProps {
  initialValues?: BulkCandidateInfoSchema;
  onSubmit: (data: BulkCandidateInfoSchema) => void;
  jobId: string;
}

const BulkCandidateInfoForm = React.forwardRef<HTMLFormElement, BulkCandidateInfoFormProps>(
  ({ initialValues, onSubmit }, forwardedRef): React.ReactElement => {
    //---------------------------------------------
    // State

    const [isParsing, setIsParsing] = React.useState(false);
    const [parsingError, setParsingError] = React.useState<string | null>(null);

    const methods = useForm<BulkCandidateInfoSchema>({
      defaultValues: initialValues,
      mode: "onBlur",
      resolver: zodResolver(bulkCandidateInfoZodSchema),
    });

    const {
      getValues,
      setValue,
      formState: { errors },
    } = methods;

    //---------------------------------------------
    // Callbacks

    // Handle drop
    // Read the file and parse it
    const handleDrop = useCallback(
      async (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
          return;
        }
        const file = acceptedFiles[0];

        if (file) {
          setValue("candidatesCsv", file, { shouldValidate: true });
        }

        setIsParsing(true);
        let rows: OutreachBulkCandidateItem[] = [];
        try {
          rows = await parseManualSourcingItemCSV(file);
          console.log("rows", rows);
        } catch {
          setParsingError("Failed to parse CSV");
          setIsParsing(false);
          return;
        }
        if (rows.length == 0) {
          setParsingError(
            "No rows found with valid data. Check your headers and make sure you're supplying LinkedIn profile URLs."
          );
        }
        setValue("manualSourcingItems", rows);
        setIsParsing(false);
      },
      [setValue]
    );

    const handleRemoveFile = (): void => {
      // @ts-ignore
      setValue("candidatesCsv", null);
      setValue("manualSourcingItems", null);
      setParsingError(null);
    };

    //---------------------------------------------
    // Render

    return (
      <FormProvider {...methods}>
        <form
          ref={forwardedRef}
          onSubmit={(e): void => {
            e.preventDefault();
            onSubmit({
              candidatesCsv: getValues("candidatesCsv"),
              manualSourcingItems: getValues("manualSourcingItems"),
            });
          }}
        >
          <Stack direction="column" spacing={2}>
            {/* Candidate Info TODO: add tooltip */}
            <Stack direction="row" alignItems="center" spacing={1}>
              <Subtitle2>Candidates</Subtitle2>
              <CSVHelpTooltipButton />
            </Stack>
            <BodySmall>
              {"The CSV should contain two columns: "}
              <em>LinkedIn</em> and <em>Email</em>.
            </BodySmall>
            <UploadBox
              name="candidatesCsv"
              placeholder={
                <Stack direction="column" justifySelf="center" alignItems="center" spacing={0}>
                  <Body>Candidate CSV</Body>
                  <BodySmall>Drag and drop a CSV here or click to upload</BodySmall>
                </Stack>
              }
              maxSize={TWENTY_MEGA_BYTES}
              onDrop={handleDrop}
              onDelete={handleRemoveFile}
              sx={{ width: "100%", height: "32px", padding: 5 }}
              accept=".csv"
              previewPrefix="CSV"
            />
            {parsingError && <BodySmall color={colors.critical.base}>{parsingError}</BodySmall>}
            {errors.candidatesCsv && <BodySmall color={colors.critical.base}>CSV required</BodySmall>}
            {!isParsing && !parsingError && <CSVSummary />}
          </Stack>
        </form>
      </FormProvider>
    );
  }
);

export default BulkCandidateInfoForm;
