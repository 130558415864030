import * as Sentry from "@sentry/react";
import { isAfter } from "date-fns";
import { useMemo } from "react";
import { useDeepCompareEffect } from "react-use";

import { useAuth0 } from "services/react-auth0-spa";

interface Auth0User {
  sub: string;
  [key: string]: any;
}

interface AdditionalUserTraits {
  [key: string]: any;
}

interface MergedUserTraits extends AdditionalUserTraits {
  displayName: string;
  email: string;
  user: Auth0User;
}

function getDomain(): string {
  if (window.location.port.length > 0) {
    return window.location.hostname;
  }
  const domain = `.${window.location.hostname.match(/\w*\.\w*$/gi)?.[0]}`;
  return encodeURIComponent(domain);
}

export function setCookie(name: string, val: string): void {
  const date = new Date();
  const value = val;

  // Set it expire in one year
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

  // Set it
  const cookieValue = `${name}=${value}; expires=${date.toUTCString()}; domain=${getDomain()}; path=/`;
  document.cookie = cookieValue;
}

const useIdentifyUser = (additionalUserTraits: AdditionalUserTraits | void): void => {
  const { user, loginAppState } = useAuth0();

  const mergedUserTraits: MergedUserTraits | undefined = useMemo(
    () =>
      user
        ? {
            ...additionalUserTraits,
            displayName: user.name,
            email: user.email,
            user: user,
            utmMedium: loginAppState?.utmMedium,
            utmSource: loginAppState?.utmSource,
            utmCampaign: loginAppState?.utmCampaign,
          }
        : undefined,
    [user, loginAppState, additionalUserTraits]
  );

  // Perform a deep compare on mergedUserTraits so that we don't trigger redundant calls to segment,
  // passing the same traits each time.
  useDeepCompareEffect(() => {
    if (!mergedUserTraits) {
      return;
    }

    // Identify the user in Sentry
    try {
      Sentry.setUser({ email: mergedUserTraits.email });
    } catch (err) {
      // Gracefully fail if user has an ad blocker or some other issue
      console.error(err);
    }

    if (!window.analytics) {
      console.debug("Skipping indentify call to Segment because analytics api is not present", mergedUserTraits);
      return;
    }

    // Identify the user in segment
    try {
      // Before this date, we used to key segment events by sub (auth0_user_id).
      // After this date, we key segment events by email.
      const userCreatedAtCutoff = new Date(Date.UTC(2023, 0, 5, 6));

      const userTraitIdentifier = isAfter(userCreatedAtCutoff, new Date(mergedUserTraits.user.created_at))
        ? mergedUserTraits.user.sub
        : mergedUserTraits.user.email;

      if (userTraitIdentifier) {
        window.analytics.identify(userTraitIdentifier, mergedUserTraits);
      }
    } catch (err) {
      // Gracefully fail if user has an ad blocker
      console.error(err);
    }
  }, [mergedUserTraits]);
};

export default useIdentifyUser;
