/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlimSearchStats
 */
export interface SlimSearchStats {
    /**
     * 
     * @type {number}
     * @memberof SlimSearchStats
     */
    readonly matching?: number;
    /**
     * 
     * @type {number}
     * @memberof SlimSearchStats
     */
    readonly availableToSource?: number;
}

export function SlimSearchStatsFromJSON(json: any): SlimSearchStats {
    return SlimSearchStatsFromJSONTyped(json, false);
}

export function SlimSearchStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlimSearchStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matching': !exists(json, 'matching') ? undefined : json['matching'],
        'availableToSource': !exists(json, 'available_to_source') ? undefined : json['available_to_source'],
    };
}

export function SlimSearchStatsToJSON(value?: SlimSearchStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


