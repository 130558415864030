/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripePaymentMethod
 */
export interface StripePaymentMethod {
    /**
     * Last four digits of the card
     * @type {string}
     * @memberof StripePaymentMethod
     */
    lastFourDigits: string;
    /**
     * Type of Credit Card
     * @type {string}
     * @memberof StripePaymentMethod
     */
    brand: string;
}

export function StripePaymentMethodFromJSON(json: any): StripePaymentMethod {
    return StripePaymentMethodFromJSONTyped(json, false);
}

export function StripePaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripePaymentMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastFourDigits': json['last_four_digits'],
        'brand': json['brand'],
    };
}

export function StripePaymentMethodToJSON(value?: StripePaymentMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_four_digits': value.lastFourDigits,
        'brand': value.brand,
    };
}


