import { Box, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { BillingSessionPathEnum } from "App/routing/types";
import check from "assets/logos/check.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useCompleteCheckoutMutation } from "services/doverapi/endpoints/billing";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { StyledSVG } from "styles/layout";
import { enumFromStringValue } from "utils/stringToEnum";
import { StripeSessionIdQueryParam, redirectURL } from "views/billing/constants";
import { BILLING_SESSION_PATH_TO_SESSION_TYPE_MAP } from "views/billing/constants";
import { Paragraph } from "views/billing/styles";

const CheckoutSessionSuccess = (): React.ReactElement => {
  const navigate = useNavigate();
  const [isProcessingCheckoutDelay, setIsProcessingCheckoutDelay] = useState<boolean>(true);
  const clientId = useGetClientId();

  const { billingSessionType: billingSessionTypeRaw } = useParams<{ billingSessionType: string | undefined }>();
  const sessionType = enumFromStringValue(BillingSessionPathEnum, billingSessionTypeRaw);
  // Once the customer has completed the checkout process in Stripe's UI, they'll be redirected
  // back to this page with this query param set. This is how we know they've completed the process.
  const [stripeSessionId] = useQueryParam(StripeSessionIdQueryParam, StringParam);
  const [redirectUrl] = useQueryParam(redirectURL, StringParam);

  const [
    completeCheckout,
    { isSuccess: successfullyCompletedCheckout, isLoading: isCompletingCheckout },
  ] = useCompleteCheckoutMutation();

  useEffect((): void => {
    if (stripeSessionId && sessionType && clientId) {
      completeCheckout({
        clientId,
        data: {
          billingSessionId: stripeSessionId,
          billingSessionType: BILLING_SESSION_PATH_TO_SESSION_TYPE_MAP[sessionType],
        },
      });
    }
  }, [completeCheckout, stripeSessionId, sessionType, clientId]);

  useEffect(() => {
    if (isProcessingCheckoutDelay) {
      setTimeout(() => {
        setIsProcessingCheckoutDelay(false);
      }, 3000);
    }
  }, [isProcessingCheckoutDelay]);

  const onClickTryAgain = useCallback(() => {
    navigate(APP_ROUTE_PATHS.billing.beginSession());
  }, [navigate]);

  const onClickReturnHome = useCallback(() => {
    navigate(APP_ROUTE_PATHS.home());
  }, [navigate]);

  if (successfullyCompletedCheckout && redirectUrl) {
    window.location.replace(decodeURIComponent(redirectUrl));
    return <></>;
  } else if (successfullyCompletedCheckout) {
    return (
      <>
        <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
          <Stack direction="column" alignItems="center" spacing={2}>
            <StyledSVG src={check} />
            <Paragraph>Thanks for setting up your payment method!</Paragraph>
            <Button onClick={onClickReturnHome} variant={ButtonVariant.Primary}>
              Return Home
            </Button>
          </Stack>
        </Box>
      </>
    );
  }

  if ((isCompletingCheckout === true || isProcessingCheckoutDelay) && stripeSessionId) {
    return (
      <>
        <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
          <Stack direction="column" alignItems="center" spacing={2}>
            <DoverLoadingSpinner spinnerSize={"20px"} />
            <Paragraph>Processing your payment method...</Paragraph>
          </Stack>
        </Box>
      </>
    );
  }

  return (
    <Stack>
      <p>An error occurred. Please try again.</p>
      <Button onClick={onClickTryAgain} variant={ButtonVariant.Primary}>
        Try Again
      </Button>
    </Stack>
  );
};

export default CheckoutSessionSuccess;
