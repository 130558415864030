/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
} from '../models';

/**
 * TestApi - interface
 * 
 * @export
 * @interface TestApiInterface
 */
export interface TestApiInterface {
    /**
     * Flush the redis cache containing sentences and other airtable data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApiInterface
     */
    flushCacheRaw(): Promise<runtime.ApiResponse<InlineResponse2001>>;

    /**
     * Flush the redis cache containing sentences and other airtable data
     */
    flushCache(): Promise<InlineResponse2001>;

}

/**
 * 
 */
export class TestApi extends runtime.BaseAPI implements TestApiInterface {

    /**
     * Flush the redis cache containing sentences and other airtable data
     */
    async flushCacheRaw(): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer JWT authentication
        }

        const response = await this.request({
            path: `/test/flush-cache`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Flush the redis cache containing sentences and other airtable data
     */
    async flushCache(): Promise<InlineResponse2001> {
        const response = await this.flushCacheRaw();
        return await response.value();
    }

}
