import FormLabel from "@mui/material/FormLabel";
import styled from "styled-components";

import { theme } from "components/data-driven-forms/styles/theme";

type LabelProps = {
  error?: boolean;
};

export const StyledFormLabel = styled(FormLabel)<LabelProps>`
  &.MuiFormLabel-root {
    color: ${(props): any => (props?.error ? theme.colors.red : theme.colors.almostBlack)};
    padding: 0;
    font-size: ${theme.inputs.default.fontSize};
    font-family: ${theme.inputs.default.fontFamily};
    font-weight: ${theme.inputs.default.fontWeight};
    line-height: ${theme.inputs.default.lineHeight};
  }
`;

export const StyledFormSubLabel = styled(FormLabel)<LabelProps>`
  &.MuiFormLabel-root {
    color: ${(props): any => (props?.error ? theme.colors.red : theme.colors.almostBlack)};
    padding: 0;
    font-size: ${theme.inputs.small.fontSize};
    font-family: ${theme.inputs.small.fontFamily};
    font-weight: ${theme.inputs.small.fontWeight};
    line-height: ${theme.inputs.small.lineHeight};
  }
  display: block;
`;
