import AddIcon from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import { List, ListItem, Popover } from "@mui/material";
import { get, sum } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

import { BodySmall } from "components/library/typography";
import { DashboardJob, JobInterviewStageStats, JobInterviewSubStageStats } from "services/openapi";
import { colors } from "styles/theme";
import { ChildRow, ChildTableHeader, StyledLink, ExpandableTableHeader, FlexSpan } from "views/Reporting/styles";
import { renderPassThroughRate } from "views/Reporting/utils";

const StyledLostBadge = styled("span")`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background-color: ${colors.primary.extraLight};
  border-radius: 4px;
  color: ${colors.primary.base};
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.primary.light};
  }

  svg {
    margin-left: 4px;
    font-size: 18px;
  }
`;

export const StageRow: React.FC<{
  allStats: JobInterviewStageStats[];
  stageStats: JobInterviewStageStats;
  job: DashboardJob;
  showPercentages?: boolean;
}> = ({ allStats, stageStats, job, showPercentages }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!stageStats) {
    return <></>;
  }

  const isExpandable = stageStats?.subgroups.length > 0;

  const lostSubComponents = [stageStats?.rejected, stageStats?.withdrew, stageStats?.snoozed];
  let lost: number | null;
  if (lostSubComponents.every(subComponent => subComponent !== undefined && subComponent !== null)) {
    lost = sum(lostSubComponents);
  } else {
    lost = null;
  }

  const renderStat = (stat: string): string => {
    const statValue = get(stageStats, stat);
    return statValue === null ? "0" : statValue;
  };

  const renderActiveCell = (value: string | number): React.ReactElement => {
    if (value === null) {
      return <>{"—"}</>;
    }
    if (value == "0" || value === 0) {
      return <>{value}</>;
    }

    const toLink =
      stageStats.hpsId !== "HIRED"
        ? `/job/${job.id}/candidates?hpsId=${stageStats.hpsId}&status=ACTIVE`
        : `/job/${job.id}/candidates?status=ACTIVE`;
    return (
      <StyledLink to={toLink} target="_blank" rel="noopener noreferrer">
        {value}
      </StyledLink>
    );
  };

  const renderLostListItem = (name: string, value: number): React.ReactElement => {
    const label = name.charAt(0).toUpperCase() + name.slice(1);
    if (value === 0) {
      return (
        <ListItem>
          {label}: {value}
        </ListItem>
      );
    }

    const toLink =
      stageStats.hpsId !== "HIRED"
        ? `/job/${job.id}/candidates?hpsId=${stageStats.hpsId}&status=${label.toUpperCase()}`
        : `/job/${job.id}/candidates?status=${label.toUpperCase()}`;

    return (
      <StyledLink to={toLink} target="_blank" rel="noopener noreferrer">
        <ListItem>
          {label}: {value}
        </ListItem>
      </StyledLink>
    );
  };

  const renderLostCell = (
    totalLost: number,
    numRejected?: number,
    numWithdrew?: number,
    numSnoozed?: number
  ): React.ReactElement => {
    const rejected = numRejected !== undefined ? numRejected : parseInt(renderStat("rejected"));
    const withdrew = numWithdrew !== undefined ? numWithdrew : parseInt(renderStat("withdrew"));
    const snoozed = numSnoozed !== undefined ? numSnoozed : parseInt(renderStat("snoozed"));

    // need to put "id" in front because the id can't start with a number
    const triggerId = "id" + stageStats.hpsId.replace(/-/g, "");

    return (
      <span>
        <StyledLostBadge
          id={triggerId}
          onClick={(e): void => {
            e.preventDefault();
            setAnchorEl(e.currentTarget);
          }}
        >
          {totalLost}
          <ArrowRightIcon />
        </StyledLostBadge>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            },
          }}
        >
          <List sx={{ padding: "8px 0" }}>
            {renderLostListItem("rejected", rejected)}
            {renderLostListItem("withdrew", withdrew)}
            {renderLostListItem("snoozed", snoozed)}
          </List>
        </Popover>
      </span>
    );
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr key={stageStats.hpsId}>
        <ExpandableTableHeader onClick={(): void => setIsExpanded(!isExpanded)}>
          <FlexSpan>
            {stageStats.hpsName}
            {isExpandable && (
              <span>
                {!isExpanded ? (
                  <AddIcon style={{ cursor: "pointer", color: "#3F51B5" }} />
                ) : (
                  <RemoveIcon style={{ cursor: "pointer", color: "#3F51B5" }} />
                )}
              </span>
            )}
          </FlexSpan>
        </ExpandableTableHeader>
        <td>
          {renderStat("total")}
          {showPercentages && <BodySmall>{renderPassThroughRate(allStats, stageStats)}</BodySmall>}
        </td>
        <td>{renderActiveCell(renderStat("active"))}</td>
        <td>{lost !== null && lost > 0 ? renderLostCell(lost) : "0"}</td>
      </tr>
      {isExpandable && isExpanded && (
        <>
          {stageStats.subgroups.map(
            (subgroup: JobInterviewSubStageStats): React.ReactNode => {
              const { name, label, total, active, rejected, withdrew, snoozed } = subgroup;
              const totalLost = rejected + withdrew + snoozed;
              return (
                <ChildRow key={name}>
                  <ChildTableHeader scope="row">{label}</ChildTableHeader>
                  <td>{total}</td>
                  <td>{renderActiveCell(active)}</td>
                  <td>
                    {totalLost !== null && totalLost > 0 ? renderLostCell(totalLost, rejected, withdrew, snoozed) : "0"}
                  </td>
                </ChildRow>
              );
            }
          )}
        </>
      )}
    </>
  );
};
