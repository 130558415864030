import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useMemo } from "react";
import { toast } from "react-toastify";

import { ReactComponent as ThumbsDownIcon } from "assets/icons/thumbs-down-white.svg";
import { ReactComponent as ThumbsUpIcon } from "assets/icons/thumbs-up.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { useEmailState } from "components/dover/top-level-modal-manager/hooks/useEmailState";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useSubmitDecision } from "hooks/useSubmitDecision";
import { SubmitDecisionArgs } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job";
import { CandidateBio, NextActionSchedulingStateEnum, SubmitDecisionRequestDecisionEnum } from "services/openapi";
import { colors } from "styles/theme";
import { isAppReviewStage } from "utils/isStage";
import { toastOptions } from "utils/showToast";

export function OneClickActionButtons({
  bio,
  hideLabels,
  disableApprove,
  disabledApproveTooltip,
  disableReject,
  disableRejectTooltip,
  submitHook,
  preventDefaultEvent, // Bandage for IRR form
}: {
  bio: CandidateBio;
  hideLabels?: boolean;
  disableApprove?: boolean;
  disabledApproveTooltip?: string | React.ReactElement;
  disableReject?: boolean;
  disableRejectTooltip?: string;
  submitHook?: () => void;
  preventDefaultEvent?: boolean;
}): React.ReactElement {
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();
  const candidateId = bio.id;

  const submitDecision = useSubmitDecision();

  const { to } = useEmailState({ candidateId: candidateId! });
  const candidateActionVersion = bio?.actionVersion;
  const approveDisabled =
    disableApprove || !candidateId || !candidateActionVersion || !!disabledApproveTooltip || !to.email;
  const rejectDisabled = disableReject || !candidateId || !candidateActionVersion || !to.email;

  const missingEmailTooltip = useMemo<string | undefined>(() => {
    if (!to.email) {
      return "Add email address to email candidate";
    }
    return undefined;
  }, [to.email]);

  const { data: job, isLoading: jobLoading } = useGetJobSetupQuery(bio?.job || skipToken);

  const rejectionButtonLabel = useMemo<string>(() => {
    if (!bio || !job || jobLoading) {
      return "Send rejection";
    }

    if (
      bio?.candidatePipelineStage &&
      isAppReviewStage(bio?.candidatePipelineStage) &&
      !job.shouldSendInboundRejectionEmails
    ) {
      return "Reject";
    }

    return "Send rejection";
  }, [bio, job, jobLoading]);

  const scheduleButtonLabel = useMemo(() => {
    if (bio?.nextAction?.schedulingState === NextActionSchedulingStateEnum.InitialReview) {
      return "Schedule interview";
    }

    return "Schedule next interview";
  }, [bio]);

  const pendingResponse = bio?.nextAction?.pendingCustomerResponse;

  const clickSchedule = useCallback(async () => {
    if (!bio.job || !candidateId || !candidateActionVersion) {
      console.warn("No candidateId or candidateActionVersion when trying to schedule");
      return;
    }

    if (pendingResponse) {
      showCandidateActionModal({ candidateId, modalType: CandidateActionModalType.NeedsResponse });
      return;
    }

    const args: SubmitDecisionArgs = {
      jobId: bio.job,
      args: {
        id: candidateId,
        data: {
          candidateActionVersion,
          decision: SubmitDecisionRequestDecisionEnum.Approve,
          auto: true,
        },
      },
    };

    const submitDecisionPromise = submitDecision(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          error: "Error Scheduling",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
    submitHook?.();
  }, [
    bio.job,
    candidateActionVersion,
    candidateId,
    pendingResponse,
    showCandidateActionModal,
    submitDecision,
    submitHook,
  ]);

  const clickReject = useCallback(async () => {
    if (!bio.job || !candidateId || !candidateActionVersion) {
      console.warn("No candidateId or candidateActionVersion when trying to reject");
      return;
    }

    const args: SubmitDecisionArgs = {
      jobId: bio.job,
      args: {
        id: candidateId,
        data: {
          candidateActionVersion,
          decision: SubmitDecisionRequestDecisionEnum.Reject,
          auto: true,
        },
      },
    };

    const submitDecisionPromise = submitDecision(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          error: "Error Rejecting",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
    submitHook?.();
  }, [bio.job, candidateActionVersion, candidateId, submitDecision, submitHook]);

  return (
    <Stack direction="row" spacing={1}>
      <Button
        variant={ButtonVariant.Primary}
        onClick={(e: any): void => {
          if (preventDefaultEvent) {
            e.preventDefault();
          }
          clickSchedule();
        }}
        disabled={approveDisabled}
        tooltip={disabledApproveTooltip ?? missingEmailTooltip}
        height="100%"
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          <ThumbsUpIcon className="svg-color" />
          {!hideLabels && (
            <Box sx={{ whiteSpace: "nowrap" }}>
              <BodySmall color={approveDisabled ? colors.black : colors.white}>{scheduleButtonLabel}</BodySmall>
            </Box>
          )}
        </Stack>
      </Button>
      <Button
        variant={ButtonVariant.Critical}
        onClick={(e: any): void => {
          if (preventDefaultEvent) {
            e.preventDefault();
          }
          clickReject();
        }}
        disabled={rejectDisabled}
        tooltip={disableRejectTooltip ?? missingEmailTooltip}
        height="100%"
      >
        <Stack direction="row" spacing={0.5} alignItems="center">
          <ThumbsDownIcon />
          {!hideLabels && (
            <Box sx={{ whiteSpace: "nowrap" }}>
              <BodySmall color={rejectDisabled ? colors.black : colors.white}>{rejectionButtonLabel}</BodySmall>
            </Box>
          )}
        </Stack>
      </Button>
    </Stack>
  );
}
