import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { ASHBY_DATA } from "services/doverapi/endpointTagsConstants";
import { AtsData } from "services/openapi";

const ashbyDataEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getAshbyData: build.query<AtsData, { ashbyKey?: string }>({
      queryFn: async ({ ashbyKey }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const data = await client.getAshbyData({ data: { ashbyKey } });
          return { data };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [ASHBY_DATA];
      },
    }),
  }),
});

export const { useGetAshbyDataQuery } = ashbyDataEndpoints;
