import { Stack } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

import ClientEmailTemplateSelector from "components/dover/EmailTemplates/ClientEmailTemplateSelector";
import { InfoTip } from "components/InfoTip";
import { BodySmall } from "components/library/typography";
import { StageDrawerFormLabel } from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import { HiringPipelineStageType } from "services/openapi";
import { colors } from "styles/theme";
import { isInterviewStage } from "utils/isStage";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";

const getSchedulingSuffix = (stageData: StageDataWrapper): string => {
  if (stageData.stage.stageType === HiringPipelineStageType.APPLICATION_REVIEW) {
    return "";
  } else if (stageData.stage.stageType === HiringPipelineStageType.RESPONDED) {
    return "";
  } else {
    return ` for ${stageData.name}`;
  }
};
const getRejectionLabel = (stageData: StageDataWrapper): string => {
  if (stageData.stage.stageType === HiringPipelineStageType.APPLICATION_REVIEW) {
    return "application";
  } else if (stageData.stage.stageType === HiringPipelineStageType.RESPONDED) {
    return "initial response";
  } else {
    return `${stageData.name} completed`;
  }
};

export const EditClientEmailTemplates = ({
  fieldPrefix,
  stageData,
}: {
  fieldPrefix: string;
  stageData: StageDataWrapper;
}): React.ReactElement => {
  const showSchedulingTemplateSelector = isInterviewStage(stageData.stage);

  return (
    <Stack spacing={1}>
      <Stack spacing={1} direction={"row"}>
        <StageDrawerFormLabel bold>Assign email templates</StageDrawerFormLabel>
        <InfoTip text={"Auto populate email messages when moving candidate through this stage"} />
      </Stack>
      <Stack direction="row" spacing={1} width="100%" justifyContent={"space-between"}>
        <Stack spacing={0} width="100%">
          <StageDrawerFormLabel bold>Scheduling{getSchedulingSuffix(stageData)}</StageDrawerFormLabel>
          {showSchedulingTemplateSelector ? (
            <Field name={`${fieldPrefix}.schedulingEmailTemplateId`}>
              {(props): React.ReactElement => {
                return (
                  <ClientEmailTemplateSelector
                    hideLabel
                    clientEmailTemplateId={props.input.value}
                    setClientEmailTemplateId={props.input.onChange}
                    allowEdits
                  />
                );
              }}
            </Field>
          ) : (
            <BodySmall color={colors.grayscale.gray500}>Assign a scheduling template in the subsequent stage</BodySmall>
          )}
        </Stack>
        <Stack spacing={0} width="100%">
          <StageDrawerFormLabel bold>Rejection (after {getRejectionLabel(stageData)})</StageDrawerFormLabel>
          <Field name={`${fieldPrefix}.rejectionEmailTemplateId`}>
            {(props): React.ReactElement => {
              return (
                <ClientEmailTemplateSelector
                  hideLabel
                  clientEmailTemplateId={props.input.value}
                  setClientEmailTemplateId={props.input.onChange}
                  allowEdits
                />
              );
            }}
          </Field>
        </Stack>
      </Stack>
    </Stack>
  );
};
