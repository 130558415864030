/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckIfProfileExistsResponse
 */
export interface CheckIfProfileExistsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckIfProfileExistsResponse
     */
    _exists: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckIfProfileExistsResponse
     */
    readonly error?: string;
}

export function CheckIfProfileExistsResponseFromJSON(json: any): CheckIfProfileExistsResponse {
    return CheckIfProfileExistsResponseFromJSONTyped(json, false);
}

export function CheckIfProfileExistsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckIfProfileExistsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_exists': json['exists'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function CheckIfProfileExistsResponseToJSON(value?: CheckIfProfileExistsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exists': value._exists,
    };
}


