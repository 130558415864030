/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneratedJobDescription,
    GeneratedJobDescriptionFromJSON,
    GeneratedJobDescriptionFromJSONTyped,
    GeneratedJobDescriptionToJSON,
    JobDescriptionJob,
    JobDescriptionJobFromJSON,
    JobDescriptionJobFromJSONTyped,
    JobDescriptionJobToJSON,
} from './';

/**
 * 
 * @export
 * @interface DoverJobDescription
 */
export interface DoverJobDescription {
    /**
     * 
     * @type {string}
     * @memberof DoverJobDescription
     */
    readonly id?: string;
    /**
     * 
     * @type {JobDescriptionJob}
     * @memberof DoverJobDescription
     */
    job: JobDescriptionJob;
    /**
     * 
     * @type {boolean}
     * @memberof DoverJobDescription
     */
    readonly useDoverJd?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoverJobDescription
     */
    readonly isPublished?: boolean;
    /**
     * 
     * @type {GeneratedJobDescription}
     * @memberof DoverJobDescription
     */
    generatedDescription: GeneratedJobDescription;
    /**
     * Dover-generated job description - should never be updated by customers
     * @type {string}
     * @memberof DoverJobDescription
     */
    userFacingDescription?: string;
    /**
     * Job Description that is provided by users on the Job Description page. May also be an edited version of the Dover-generated description.
     * @type {string}
     * @memberof DoverJobDescription
     */
    userProvidedDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoverJobDescription
     */
    readonly externalUrl?: string;
}

export function DoverJobDescriptionFromJSON(json: any): DoverJobDescription {
    return DoverJobDescriptionFromJSONTyped(json, false);
}

export function DoverJobDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoverJobDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'job': JobDescriptionJobFromJSON(json['job']),
        'useDoverJd': !exists(json, 'use_dover_jd') ? undefined : json['use_dover_jd'],
        'isPublished': !exists(json, 'is_published') ? undefined : json['is_published'],
        'generatedDescription': GeneratedJobDescriptionFromJSON(json['generated_description']),
        'userFacingDescription': !exists(json, 'user_facing_description') ? undefined : json['user_facing_description'],
        'userProvidedDescription': !exists(json, 'user_provided_description') ? undefined : json['user_provided_description'],
        'externalUrl': !exists(json, 'external_url') ? undefined : json['external_url'],
    };
}

export function DoverJobDescriptionToJSON(value?: DoverJobDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': JobDescriptionJobToJSON(value.job),
        'generated_description': GeneratedJobDescriptionToJSON(value.generatedDescription),
        'user_facing_description': value.userFacingDescription,
        'user_provided_description': value.userProvidedDescription,
    };
}


