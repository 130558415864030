import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState, useEffect } from "react";

import { DOVER_INTERVIEWER, EMPTY_INTERVIEWER } from "components/dover/top-level-modal-manager/constants";
import { SchedulableStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import {
  convertClientInterviewer,
  convertHiringPlanInterviewer,
} from "components/dover/top-level-modal-manager/utils/convertClientInterviewer";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useGetHiringPipelineStageQuery } from "services/doverapi/endpoints/hiringPipelineStage";
import { HiringPipelineStageMilestone, HiringPlanInterviewer, NextActionStateEnum } from "services/openapi";

export const useInterviewer = (
  candidateId: string,
  stage?: SchedulableStage
): {
  interviewer: Interviewer;
  setInterviewer: React.Dispatch<React.SetStateAction<Interviewer>>;
} => {
  const { data: bio } = useGetCandidateBioQuery(candidateId);
  const [interviewer, setInterviewer] = useState<Interviewer>(EMPTY_INTERVIEWER);
  const shouldUseNextInterview = bio?.nextAction?.state === NextActionStateEnum.NeedsAction;
  const [jobId] = useJobId();
  const { data: extraStageInfo } = useGetHiringPipelineStageQuery(
    stage?.id && jobId ? { hpsId: stage.id, jobId: jobId } : skipToken
  );

  // Set initial interviewer once bio loads
  useEffect(() => {
    if (stage?.isOneOffInterview) {
      setInterviewer(EMPTY_INTERVIEWER);
      return;
    }

    // Only want to do this once while it's still empty
    const nextInterview = shouldUseNextInterview ? bio?.nextAction?.nextInterview : bio?.nextAction?.currentInterview;

    // Can be either a dover interviewer or a candidate bio interviewer
    const initialInterviewer =
      nextInterview?.hasDoverInterviewer && stage?.milestone === HiringPipelineStageMilestone.INITIAL_CALL
        ? DOVER_INTERVIEWER
        : convertClientInterviewer(nextInterview?.interviewer);

    if (initialInterviewer) {
      setInterviewer(initialInterviewer);
    }

    // Use possible interviewers only if DI is not already set
    if (extraStageInfo && !initialInterviewer) {
      const possibleInterviewers: HiringPlanInterviewer[] = [];
      for (const substage of extraStageInfo?.multipartInterviewStage?.substages || []) {
        possibleInterviewers.push(...substage.possibleInterviewers);
      }
      // if any exist, just pick a 'random' one (the first one)
      const interviewer = possibleInterviewers.length
        ? convertHiringPlanInterviewer(possibleInterviewers[0])
        : undefined;
      if (interviewer) {
        setInterviewer(interviewer);
        return;
      }
    }

    // tracking stage here so that we can update the interviewer when the stage changes
  }, [
    shouldUseNextInterview,
    bio?.nextAction?.nextInterview,
    bio?.nextAction?.currentInterview,
    stage,
    extraStageInfo,
  ]);

  return { interviewer, setInterviewer };
};
