import Grid from "@mui/material/Grid";
import React from "react";
import { InlineWidget } from "react-calendly";
import styled from "styled-components";

import { TopLevelContainer } from "App/styles/App";
import { BodyText } from "components/data-driven-forms/styles/typography";
import Loading from "components/HotLoading";
import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useAuth0 } from "services/react-auth0-spa";

const Container = styled(TopLevelContainer)`
  padding: 16px 64px !important;
`;

const GetHelp = (): React.ReactElement => {
  const { data: client, isLoading: isLoadingClient } = useGetUsersClientQuery();
  const csm = client?.csm;

  const { user } = useAuth0();

  if (isLoadingClient) {
    return <Loading />;
  }

  if (!csm?.adhocMeetingSchedulingLink) {
    return (
      <>
        <PageHelmet title="Get Help" />

        <Container>
          <Spacer height="24px" />
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <BodyText>
              We{"'"}re missing scheduling information for your customer success manager. Please email{" "}
              <a href="mailto:support@dover.com">support@dover.com</a>.
            </BodyText>
          </Grid>
        </Container>
      </>
    );
  }

  return (
    <>
      <PageHelmet title="Get Help" />

      <Container>
        <Spacer height="24px" />

        <InlineWidget
          url={csm.adhocMeetingSchedulingLink}
          styles={{ height: "720px" }}
          prefill={{
            email: user.email,
            name: user.name,
          }}
        />
      </Container>
    </>
  );
};

export default GetHelp;
