/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MultipartSchedulingBaseInterviewSubstage,
    MultipartSchedulingBaseInterviewSubstageFromJSON,
    MultipartSchedulingBaseInterviewSubstageFromJSONTyped,
    MultipartSchedulingBaseInterviewSubstageToJSON,
    MultipartSchedulingClientInterviewer,
    MultipartSchedulingClientInterviewerFromJSON,
    MultipartSchedulingClientInterviewerFromJSONTyped,
    MultipartSchedulingClientInterviewerToJSON,
} from './';

/**
 * 
 * @export
 * @interface MultipartSchedulingMultipartInterviewStage
 */
export interface MultipartSchedulingMultipartInterviewStage {
    /**
     * 
     * @type {object}
     * @memberof MultipartSchedulingMultipartInterviewStage
     */
    interviewDetails?: object | null;
    /**
     * 
     * @type {Array<MultipartSchedulingBaseInterviewSubstage>}
     * @memberof MultipartSchedulingMultipartInterviewStage
     */
    readonly substages?: Array<MultipartSchedulingBaseInterviewSubstage>;
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingMultipartInterviewStage
     */
    readonly jobName?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipartSchedulingMultipartInterviewStage
     */
    readonly stageName?: string;
    /**
     * 
     * @type {Array<MultipartSchedulingClientInterviewer>}
     * @memberof MultipartSchedulingMultipartInterviewStage
     */
    readonly allInterviewers?: Array<MultipartSchedulingClientInterviewer>;
    /**
     * 
     * @type {number}
     * @memberof MultipartSchedulingMultipartInterviewStage
     */
    readonly debriefDurationInMinutes?: number;
}

export function MultipartSchedulingMultipartInterviewStageFromJSON(json: any): MultipartSchedulingMultipartInterviewStage {
    return MultipartSchedulingMultipartInterviewStageFromJSONTyped(json, false);
}

export function MultipartSchedulingMultipartInterviewStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipartSchedulingMultipartInterviewStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interviewDetails': !exists(json, 'interview_details') ? undefined : json['interview_details'],
        'substages': !exists(json, 'substages') ? undefined : ((json['substages'] as Array<any>).map(MultipartSchedulingBaseInterviewSubstageFromJSON)),
        'jobName': !exists(json, 'job_name') ? undefined : json['job_name'],
        'stageName': !exists(json, 'stage_name') ? undefined : json['stage_name'],
        'allInterviewers': !exists(json, 'all_interviewers') ? undefined : ((json['all_interviewers'] as Array<any>).map(MultipartSchedulingClientInterviewerFromJSON)),
        'debriefDurationInMinutes': !exists(json, 'debrief_duration_in_minutes') ? undefined : json['debrief_duration_in_minutes'],
    };
}

export function MultipartSchedulingMultipartInterviewStageToJSON(value?: MultipartSchedulingMultipartInterviewStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interview_details': value.interviewDetails,
    };
}


