import { zodResolver } from "@hookform/resolvers/zod";
import Stack from "@mui/material/Stack";
import React, { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { Body, BodySmall, Subtitle2 } from "components/library/typography";
import { RHFTextField } from "sections/addcandidate/components/hook-form";
import { SourceRadioGroup } from "sections/addcandidate/components/hook-form/RHFRadioGroup";
import UploadBox from "sections/addcandidate/components/hook-form/RHFUploadBox";
import HorizontalLoader from "sections/addcandidate/components/HorizontalLoader";
import AgencyInfoFields from "sections/addcandidate/components/steps/CandidateInfoStep/components/AgencyInfoFields";
import { TWENTY_MEGA_BYTES } from "sections/addcandidate/data/misc";
import { useAddCandidateContext } from "sections/addcandidate/hooks/useAddCandidateContext";
import { FlowType, FlowTypes, StepTypes } from "sections/addcandidate/types";
import {
  CandidateInfoSchema,
  candidateInfoZodSchema,
  InterviewPipelineCandidateInfoSchema,
  interviewPipelineCandidateInfoZodSchema,
} from "sections/addcandidate/types/candidateInfo";
import { useListCandidateSourcesQuery } from "services/doverapi/endpoints/candidateSource";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";

interface CandidateInfoFieldProps {
  candidateSourceChoices: { label: string; value: string; internalName: string }[];
  handleRemoveFile: () => void;
  handleDrop: (acceptedFiles: File[]) => void;
  flowType: FlowType;
}

const CandidateInfoFields = ({
  candidateSourceChoices,
  handleRemoveFile,
  handleDrop,
  flowType,
}: CandidateInfoFieldProps): React.ReactElement => (
  <Stack direction="column" spacing={2}>
    <Subtitle2>Candidate Details</Subtitle2>
    <Stack direction="row" spacing={2}>
      <RHFTextField name="firstName" size="small" variant="outlined" label="First Name" placeholder="John" autoFocus />
      <RHFTextField name="lastName" size="small" variant="outlined" label="Last Name" placeholder="Smith" />
    </Stack>

    <RHFTextField
      name="email"
      size="small"
      variant="outlined"
      label="Email (optional)"
      placeholder="john.smith@dover.com"
    />
    <RHFTextField
      name="linkedinUrl"
      size="small"
      variant="outlined"
      label="LinkedIn URL (optional)"
      placeholder="https://www.linkedin.com/in/johnsmith/"
    />

    <UploadBox
      name="resume"
      placeholder={
        <Stack direction="column" justifySelf="center" alignItems="center" spacing={0}>
          <Body>Resume (optional)</Body>
          <BodySmall>Drag and drop a resume here or click to upload</BodySmall>
        </Stack>
      }
      maxSize={TWENTY_MEGA_BYTES}
      onDrop={handleDrop}
      onDelete={handleRemoveFile}
      sx={{ width: "100%", height: "32px", padding: 5 }}
      accept=".pdf"
    />

    {/* Fields specific to interview pipeline */}
    {flowType === FlowTypes.INTERVIEW_PIPELINE && (
      <>
        <SourceRadioGroup
          row
          name="source.value"
          label="Candidate Source"
          options={candidateSourceChoices}
          helperText="Where did you find this person? This is used for reporting and filtering."
        />
        <AgencyInfoFields />
      </>
    )}
  </Stack>
);

interface CandidateInfoFormProps {
  initialValues?: CandidateInfoSchema | InterviewPipelineCandidateInfoSchema;
  onSubmit: (data: CandidateInfoSchema | InterviewPipelineCandidateInfoSchema) => void;
  jobId: string;
  flowType: FlowType;
}

const CANDIATE_SOURCE_ORDERING_BY_INTERNAL_NAME = [
  // Unmapped sources will appear at then end
  "AGENCY",
  "CANDIDATE_REFERRED",
  "UNKNOWN",
];

const CandidateInfoForm = React.forwardRef<HTMLFormElement, CandidateInfoFormProps>(
  ({ initialValues, onSubmit, jobId, flowType }, forwardedRef): React.ReactElement => {
    //---------------------------------------------
    // State

    const {
      flowState: { [StepTypes.ATS_CANDIDATE_INFO]: atsCandidateInfoState },
    } = useAddCandidateContext();

    const methods = useForm<CandidateInfoSchema | InterviewPipelineCandidateInfoSchema>({
      defaultValues: initialValues,
      mode: "onBlur",
      resolver: zodResolver(
        flowType === FlowTypes.INITIAL_OUTREACH ? candidateInfoZodSchema : interviewPipelineCandidateInfoZodSchema
      ),
    });

    const { handleSubmit, setValue, watch } = methods;

    // Candidate Sources
    const { data: candidateSourcesEntityAdapter, isLoading: isLoadingSources } = useListCandidateSourcesQuery({
      jobId,
      thirdPartyAllowed: true,
    });

    const candidateSourceChoices = useMemo(() => {
      const candidateSources = listAllEntities(candidateSourcesEntityAdapter) ?? [];
      return candidateSources
        .map(source => {
          return {
            label: source.label || "",
            value: source.id!,
            internalName: source.internalName!,
          };
        })
        .sort(
          (a, b) =>
            // NOTE: We've swapped the usual order of substraction here.
            // Elements with higher index in array will appear earlier in the list.
            // Unmapped sources will have indexOf == -1 and will appear at the end,
            // and aren't guaranteed to have stable order by API.
            // https://app.shortcut.com/dover/story/192768/default-select-other-when-adding-a-candidate
            CANDIATE_SOURCE_ORDERING_BY_INTERNAL_NAME.indexOf(b.internalName) -
            CANDIATE_SOURCE_ORDERING_BY_INTERNAL_NAME.indexOf(a.internalName)
        );
    }, [candidateSourcesEntityAdapter]);

    // Set source default to other
    // Not a fan of this pattern, but could't think of a better way to do this
    // Without a big refactor of this code and we wanted this for ats launch
    const source = watch("source");
    useEffect(() => {
      if (!source) {
        return;
      }

      if (!source.internalName && candidateSourceChoices.length > 0) {
        const other = candidateSourceChoices.find(choice => choice.internalName === "UNKNOWN");
        if (other) {
          setValue("source", other);
        }
      }
    }, [candidateSourceChoices, setValue, source]);

    //---------------------------------------------
    // Callbacks

    const handleDrop = useCallback(
      (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
          return;
        }
        const file = acceptedFiles[0];

        if (file) {
          setValue("resume", file, { shouldValidate: true });
        }
      },
      [setValue]
    );

    const handleRemoveFile = (): void => {
      // @ts-ignore
      setValue("resume", null);
    };

    //---------------------------------------------
    // Effects

    useEffect(() => {
      // If we're adding a candidate from customer's ATS, set the values to
      // the ATS candidate we pulled
      const atsCandidate = atsCandidateInfoState?.atsCandidate;

      if (atsCandidateInfoState?.addingAtsCandidate && atsCandidate) {
        setValue("firstName", atsCandidate.firstName);
        setValue("lastName", atsCandidate.lastName);
        setValue("email", atsCandidate.email);
      }
    }, [atsCandidateInfoState, setValue]);

    //---------------------------------------------
    // Render

    if (isLoadingSources) {
      return <HorizontalLoader />;
    }

    return (
      <FormProvider {...methods}>
        <form ref={forwardedRef} onSubmit={handleSubmit(onSubmit)}>
          <CandidateInfoFields
            candidateSourceChoices={candidateSourceChoices}
            handleRemoveFile={handleRemoveFile}
            handleDrop={handleDrop}
            flowType={flowType}
          />
        </form>
      </FormProvider>
    );
  }
);
export default CandidateInfoForm;
