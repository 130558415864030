import { AddCandidateErrors } from "sections/addcandidate/data/errors";
import { IAddCandidate } from "sections/addcandidate/services/api/interface";
import { FlowState, StepTypes } from "sections/addcandidate/types";
import { CandidateInfoSchema } from "sections/addcandidate/types/candidateInfo";
import {
  useAddCandidateInitialOutreachMutation,
  useRetrieveEmailsForLinkedinPublicUrlQuery,
} from "services/doverapi/endpoints/candidate";

// ---------------------------------------------------------------------------
// Service Implementation

export const useSingleOutreachApi = (flowState: FlowState): IAddCandidate => {
  // 0. Collect data & api clients
  const { jobId } = flowState[StepTypes.JOB_SELECT];
  const candidateInfo = flowState[StepTypes.CANDIDATE_INFO] as CandidateInfoSchema;
  const campaignState = flowState[StepTypes.SELECT_CAMPAIGN];

  const [addCandidateInitialOutreachMutation] = useAddCandidateInitialOutreachMutation();
  const { data: retrieveEmailResp } = useRetrieveEmailsForLinkedinPublicUrlQuery(
    {
      data: {
        job: jobId,
        linkedinPublicUrl: candidateInfo.linkedinUrl!, // form validation forces this to be set, if not provided an email
        attemptEmailFinding: true,
      },
    },
    // Skip if we already have an email
    // Skip if we don't have a linkedin url
    { skip: !!candidateInfo.email || !candidateInfo.linkedinUrl }
  );

  return {
    addCandidate: async (): Promise<{ success: boolean; candidateId?: string; message?: string }> => {
      // 1. If not provided an email, attempt to find one
      let emailId: string | undefined;
      if (!candidateInfo.email) {
        try {
          // 1.1. If no email found, return an error telling the user to provide an email
          if (!retrieveEmailResp?.success || !retrieveEmailResp?.emails || retrieveEmailResp.emails.length === 0) {
            return {
              success: false,
              message: AddCandidateErrors.singleOutreach.email.notFound,
            };
          }
          // 1.2. If email found, use it (the first one; it's sorted by best/most likely to be a personal)
          emailId = retrieveEmailResp.emails[0].emailId;
        } catch (e) {
          // 1.3 If we fail due to a transient error, return an error telling them to try again
          return {
            success: false,
            message: AddCandidateErrors.singleOutreach.email.transientError,
          };
        }
      }

      // 2. Create the candidate
      const resume = candidateInfo.resume;
      try {
        const response = await addCandidateInitialOutreachMutation({
          job: jobId!,
          firstName: candidateInfo.firstName,
          lastName: candidateInfo.lastName,
          resume: resume ? (resume as Blob) : undefined,
          campaign: campaignState.campaign?.id ?? "",
          emailId: emailId,
          email: candidateInfo.email ?? "",
          firstMessageBody: campaignState.body,
          firstMessageSubject: campaignState.subject,
          ccEmails: campaignState.ccEmails,
          linkedinProfileUrl: candidateInfo.linkedinUrl,
        }).unwrap();
        // 2.1 If we failed to create the candidate, return an error
        if (!response.success) {
          return {
            success: false,
            message: response.message,
          };
        }
        // 2.2 If the candidate was created successfully, return success
        return {
          success: true,
          candidateId: response.candidate,
        };
      } catch (error) {
        // 2.3 If we failed due to a transient error, return an error telling them to try again
        return {
          success: false,
          message: AddCandidateErrors.generic.transientError,
        };
      }
    },
  };
};
