import { Box, Stack } from "@mui/material";
import capitalize from "lodash/capitalize";
import React, { ReactElement } from "react";

import { ReactComponent as InboundIcon } from "assets/icons/inbound.svg";
import { ReactComponent as ManualSourceIcon } from "assets/icons/manual_source.svg";
import { ReactComponent as OutboundIcon } from "assets/icons/outbound.svg";
import { ReactComponent as ReferralsIcon } from "assets/icons/referrals.svg";
import { ReactComponent as SlackIcon } from "assets/icons/slack.svg";
import { Spacer } from "components/Spacer";
import { CandidateBio } from "services/openapi";
import { ContactInfo } from "views/CandidateDetail/components/ContactInfo";
import {
  ContactInfoWrapper,
  DetailHeader,
  DetailItem,
  DetailLink,
  DetailsContainer,
  DetailSubtitle,
  DetailText,
} from "views/CandidateDetail/components/details/styles";
import { convertPrettyDate } from "views/CandidateDetail/utils";

interface Props {
  candidateBio: CandidateBio;
}

export const CandidateDetails = ({ candidateBio }: Props): ReactElement => {
  const ats = candidateBio.ats!;

  return (
    <DetailsContainer>
      <ContactInfo contactId={candidateBio.contact.id} candidateId={candidateBio.id} />
      <Spacer height={8} />
      <SlackLink slackUrl={candidateBio.slackUrl} />
      <Spacer height={24} />
      {candidateBio.searchSource && (
        <DetailItem>
          <DetailHeader>SOURCE</DetailHeader>
          <Spacer height={8} />
          {genSource(candidateBio)}
        </DetailItem>
      )}
      <Spacer height={24} />
      {ats && (
        <DetailItem>
          <DetailHeader>ATS</DetailHeader>
          <Spacer height={8} />
          <DetailLink href={ats.url} rel="noopener noreferrer" target="_blank">
            {capitalize(ats.type)}
          </DetailLink>
          <DetailText>{ats.stageName && `(${ats.stageName})`}</DetailText>
          <DetailSubtitle>{ats.lastSyncedAt && `Last synced ${convertPrettyDate(ats.lastSyncedAt)}`}</DetailSubtitle>
        </DetailItem>
      )}
    </DetailsContainer>
  );
};

const SlackLink = ({ slackUrl }: { slackUrl: string | undefined }): ReactElement => {
  return slackUrl ? (
    <DetailItem>
      <ContactInfoWrapper>
        <Box display="flex" alignItems="center">
          <SlackIcon width="16px" />
          <Spacer width={12} />
          <DetailLink href={slackUrl} rel="noopener noreferrer" target="_blank">
            View in Slack
          </DetailLink>
        </Box>
      </ContactInfoWrapper>
    </DetailItem>
  ) : (
    <></>
  );
};

const genSource = (candidateBio: CandidateBio): ReactElement | undefined => {
  const source = candidateBio.searchSource!;
  const { displayCategory, displayAction } = source;

  let icon;
  if (displayAction == "Contacted") {
    icon = <OutboundIcon width="16px" />;
  } else if (displayAction == "Referred by") {
    icon = <ReferralsIcon width="16px" />;
  } else if (displayAction == "Applied") {
    icon = <InboundIcon width="16px" />;
  } else if (displayAction.startsWith("Added by")) {
    icon = <ManualSourceIcon width="16px" />;
  } else if (displayAction.startsWith("Inbound")) {
    icon = <InboundIcon width="16px" />;
  } else {
    icon = <OutboundIcon width="16px" />;
  }

  const getDisplayAction = (displayAction: string): string => {
    if (displayAction === "Referred by") {
      return "Referred";
    }
    return displayAction;
  };

  const prettyDate = source.pipelineStartedDate ? convertPrettyDate(source.pipelineStartedDate) : "";
  const prettyDateString = prettyDate === "today" ? "today" : `on ${prettyDate}`;

  return (
    <Box display="flex" alignItems="baseline" flexDirection="column">
      <Spacer height={8} /> {/* Added extra spacing here */}
      <ContactInfoWrapper>
        <Stack direction="row" spacing={1} alignItems="baseline">
          {icon}
          <DetailText>{displayCategory}</DetailText>
        </Stack>
      </ContactInfoWrapper>
      <ContactInfoWrapper>
        {prettyDate && (
          <DetailSubtitle>
            <Spacer height={8} />
            {getDisplayAction(displayAction)} {prettyDateString}
          </DetailSubtitle>
        )}
      </ContactInfoWrapper>
    </Box>
  );
};
