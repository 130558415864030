import { createEntityAdapter, EntityState, SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { HIRING_PLAN_JOB_POSITION, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import { HiringPlanJobPosition, HiringPlanJobPositionOpening } from "services/openapi";
import { showErrorToast } from "utils/showToast";

const listJobPositionsAdapter = createEntityAdapter<HiringPlanJobPosition>();
export const listJobPositionsInitialState = listJobPositionsAdapter.getInitialState();

const hiringPlanEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listJobPositions: build.query<EntityState<HiringPlanJobPosition>, void>({
      queryFn: async () => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listJobPositions({});
          return { data: listJobPositionsAdapter.addMany(listJobPositionsInitialState, response.results) };
        } catch (error) {
          showErrorToast("Failed to load positions. Please refresh and try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      providesTags: results => {
        return results
          ? [
              ...results.ids.map(id => ({ type: HIRING_PLAN_JOB_POSITION, id } as const)),
              { type: HIRING_PLAN_JOB_POSITION, id: LIST_TAG },
            ]
          : [{ type: HIRING_PLAN_JOB_POSITION, id: LIST_TAG }];
      },
    }),
    createJobPosition: build.mutation<HiringPlanJobPosition, { clientId: string; title: string }>({
      queryFn: async ({ clientId, title }) => {
        const { apiApi } = await getOpenApiClients({});
        try {
          return { data: await apiApi.createJobPositionForHiringPlan({ data: { client: clientId, title } }) };
        } catch (error) {
          showErrorToast("Failed to create new position. Please refresh and try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: () => {
        return [{ type: HIRING_PLAN_JOB_POSITION, id: LIST_TAG }];
      },
    }),
    createJobPositionOpening: build.mutation<
      HiringPlanJobPositionOpening,
      { jobPositionId: string; nthOpeningForPosition: number }
    >({
      queryFn: async ({ jobPositionId, nthOpeningForPosition }) => {
        const { apiApi } = await getOpenApiClients({});
        try {
          return {
            data: await apiApi.createJobPositionOpeningForHiringPlan({
              data: { jobPosition: jobPositionId, nthOpeningForPosition },
            }),
          };
        } catch (error) {
          showErrorToast("Failed to create new position opening. Please refresh and try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: HIRING_PLAN_JOB_POSITION, id: args.jobPositionId }];
      },
    }),
    partialUpdateJobPosition: build.mutation<HiringPlanJobPosition, { jobPosition: HiringPlanJobPosition }>({
      queryFn: async ({ jobPosition }) => {
        const { apiApi } = await getOpenApiClients({});
        try {
          return { data: await apiApi.partialUpdateJobPosition({ id: jobPosition.id!, data: jobPosition }) };
        } catch (error) {
          showErrorToast("Failed to update position. Please refresh and try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: result => {
        return [{ type: HIRING_PLAN_JOB_POSITION, id: result?.id }];
      },
    }),
    partialUpdateJobPositionOpening: build.mutation<
      HiringPlanJobPositionOpening,
      { jobPositionOpening: HiringPlanJobPositionOpening }
    >({
      queryFn: async ({ jobPositionOpening }) => {
        const { apiApi } = await getOpenApiClients({});
        try {
          return {
            data: await apiApi.partialUpdateJobPositionOpening({
              id: jobPositionOpening.id!,
              data: jobPositionOpening,
            }),
          };
        } catch (error) {
          showErrorToast("Failed to update position. Please refresh and try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: result => {
        return [{ type: HIRING_PLAN_JOB_POSITION, id: result?.jobPosition }];
      },
    }),
    deleteJobPosition: build.mutation<void, { id: string }>({
      queryFn: async ({ id }) => {
        const { apiApi } = await getOpenApiClients({});

        try {
          return { data: await apiApi.deleteJobPositionForHiringPlan({ id }) };
        } catch (error) {
          showErrorToast("Failed to delete position. Please refresh and try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: HIRING_PLAN_JOB_POSITION, id: args.id }];
      },
    }),
    deleteJobPositionOpening: build.mutation<void, { id: number; jobPositionId: string }>({
      queryFn: async ({ id }) => {
        const { apiApi } = await getOpenApiClients({});

        try {
          return { data: await apiApi.deleteJobPositionOpeningForHiringPlan({ id }) };
        } catch (error) {
          showErrorToast("Failed to delete headcount. Please refresh and try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: (result, error, args) => {
        return [{ type: HIRING_PLAN_JOB_POSITION, id: args.jobPositionId }];
      },
    }),
  }),
});

export const {
  useListJobPositionsQuery,
  useCreateJobPositionMutation,
  useCreateJobPositionOpeningMutation,
  usePartialUpdateJobPositionMutation,
  usePartialUpdateJobPositionOpeningMutation,
  useDeleteJobPositionMutation,
  useDeleteJobPositionOpeningMutation,
} = hiringPlanEndpoints;

export const { selectAll: selectAllJobPositions } = listJobPositionsAdapter.getSelectors();
