import { Box } from "@mui/material";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { colors } from "styles/theme";

export const CustomScrollBox = styled(Box)`
  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.grayscale.gray200};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.grayscale.gray400};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.grayscale.gray500};
  }
`;

interface StyledSVGProps {
  filter?: string;
}

export const StyledSVG = styled(ReactSVG)<StyledSVGProps>`
  display: flex;
  align-items: center;
  filter: ${({ filter }): string => (filter ? filter : "none")};
`;
