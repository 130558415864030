import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  SxProps,
  Theme,
  TableContainer,
} from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";
import { TableInstance } from "react-table";
import styled from "styled-components";

import ChevronRight from "assets/icons/chevron-right.svg";
import HelpIconSVG from "assets/icons/help-question.svg";
import { ButtonVariant } from "components/library/Button";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Overline } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { AddCandidateButton } from "sections/addcandidate";
import { useHandleAddCandidate } from "sections/addcandidate/components/AddCandidateButton";
import { colors } from "styles/theme";
import { ColumnNameEnum } from "views/candidates/constants";
import { FAILURE_REASON_TOOLTIP_TEXT } from "views/candidates/constants";

const downArrow = (
  <ReactSVG src={ChevronRight} beforeInjection={(svg): void => svg.setAttribute("transform", "rotate(90)")} />
);
const upArrow = (
  <ReactSVG src={ChevronRight} beforeInjection={(svg): void => svg.setAttribute("transform", "rotate(270)")} />
);

type SetCurrentPageIndexFunction = (currentPageIndex: number) => void;

interface PaginatedTableProps {
  table: TableInstance<any>;
  hiddenHeaders?: string[];
  sx: SxProps<Theme>;
  onRowClick?: (args: any) => void;
  isLoadingMore: boolean;
  currentPageIndex: number;
  setCurrentPageIndex: SetCurrentPageIndexFunction;
  totalNumItems: number;
  numItemsPerPage: number;
}
const PaginatedTable = ({
  table,
  hiddenHeaders,
  onRowClick,
  sx,
  isLoadingMore,
  currentPageIndex,
  setCurrentPageIndex,
  totalNumItems,
  numItemsPerPage,
}: PaginatedTableProps): React.ReactElement => {
  const openAddCandidateDrawer = useHandleAddCandidate();

  const { getTableBodyProps, headerGroups, rows, prepareRow } = table;

  const totalNumPages = Math.ceil(totalNumItems / numItemsPerPage);
  const isOnFirstPage = currentPageIndex === 0;
  const isOnLastPage = currentPageIndex === totalNumPages - 1;
  const showLoadingSpinner = isLoadingMore;

  const handlePageChange = (proposedPageIndex: number): void => {
    // A safety check to prevent navigating past the last page
    if (proposedPageIndex >= totalNumPages) {
      return;
    }
    setCurrentPageIndex(proposedPageIndex);
  };

  return (
    <>
      <TableContainer sx={sx}>
        <Table stickyHeader>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <TableCell
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          title: column.canSort ? `sort by ${column.Header}` : undefined,
                        })
                      )}
                      key={column.id}
                      align="left"
                      sx={{
                        backgroundColor: colors.grayscale.gray100,
                        // @ts-ignore
                        ...(column.sx ?? {}),
                      }}
                    >
                      <Box display="flex" height="0px" alignItems="center">
                        <Overline>
                          {(!hiddenHeaders || !hiddenHeaders.includes(column.Header as string)) &&
                            column.render("Header")}
                        </Overline>

                        {column.Header === ColumnNameEnum.FailureReason && (
                          <>
                            <Spacer width={8} />
                            <Tooltip
                              title={FAILURE_REASON_TOOLTIP_TEXT}
                              variant={TooltipVariant.Dark}
                              placement="right"
                            >
                              <img src={HelpIconSVG} alt="Help Icon" />
                            </Tooltip>
                          </>
                        )}
                        {column.isSorted && <Spacer width={8} />}
                        {column.isSorted && (column.isSortedDesc ? upArrow : downArrow)}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            <TableRow
              className="candidates-table-row"
              key={"add-candidate"}
              onClick={(): void => openAddCandidateDrawer()}
              sx={{
                backgroundColor: "#fff",
                "&:hover": { backgroundColor: colors.grayscale.gray100, cursor: "pointer" },
              }}
            >
              <TableCell align="left" padding="none" sx={{ padding: "4px 16px !important" }}>
                <AddCandidateButton
                  buttonProps={{
                    variant: ButtonVariant.Ghost,
                  }}
                />
              </TableCell>
              <TableCell key={"add-candidate-empty-1"} align="left" padding="none" />
              <TableCell key={"add-candidate-empty-2"} align="left" padding="none" />
              <TableCell key={"add-candidate-empty-3"} align="left" padding="none" />
              <TableCell key={"add-candidate-empty-4"} align="left" padding="none" />
            </TableRow>
            {rows.map(row => {
              prepareRow(row);
              const rowProps = row.getRowProps();

              return (
                <TableRow
                  className="candidates-table-row"
                  {...rowProps}
                  key={rowProps.key + row.original.id}
                  onClick={(): void => onRowClick && onRowClick(row.original)}
                  sx={{
                    backgroundColor: "#fff",
                    "&:hover": { backgroundColor: colors.grayscale.gray100, cursor: "pointer" },
                  }}
                >
                  {row.cells.map(cell => (
                    <TableCell {...cell.getCellProps()} align="left" padding="none">
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box justifyContent="center" display="flex">
        <TablePaginationStyled
          count={totalNumItems}
          rowsPerPage={numItemsPerPage}
          rowsPerPageOptions={[10]}
          page={currentPageIndex}
          SelectProps={{
            inputProps: { "aria-label": "rows per page", disabled: showLoadingSpinner },
            native: true,
          }}
          onPageChange={(e, newPage): void => handlePageChange(newPage)}
          backIconButtonProps={{ disabled: showLoadingSpinner || isOnFirstPage }}
          nextIconButtonProps={{ disabled: showLoadingSpinner || isOnLastPage }}
          showFirstButton={true}
          showLastButton={true}
        />
      </Box>
    </>
  );
};

export default PaginatedTable;

const TablePaginationStyled = styled(TablePagination)`
  // These style overrides make sure that the footer content
  // is centered regardless of the number of columns in the table.

  .MuiToolbar-root {
    justify-content: center !important;
  }

  .MuiTablePagination-spacer {
    flex: 0 !important;
  }

  .MuiTablePagination-displayedRows,
  .MuiTablePagination-selectLabel {
    margin-bottom: 0;
  }

  border-bottom: none;
`;
