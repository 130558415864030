import Mention from "@tiptap/extension-mention";
import { v4 as uuidv4 } from "uuid";

export const CustomMention = Mention.extend({
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element: HTMLElement): any => element.getAttribute("data-id"),
        renderHTML: (): Record<string, any> => {
          return {
            "data-id": uuidv4(),
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element: HTMLElement): any => element.getAttribute("data-label"),
        renderHTML: (attributes: Record<string, any>): Record<string, any> => {
          if (!attributes.label) {
            return {};
          }

          return {
            "data-label": attributes.label,
          };
        },
      },
      mentionerName: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: (element: HTMLElement): any => element.getAttribute("data-mentionername"),
        // … and customize the HTML rendering.
        renderHTML: (attributes: Record<string, any>): Record<string, any> => {
          return {
            "data-mentionername": attributes.mentionerName,
          };
        },
      },
      mentionerEmail: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: (element: HTMLElement): any => element.getAttribute("data-mentioneremail"),
        // … and customize the HTML rendering.
        renderHTML: (attributes: Record<string, any>): Record<string, any> => {
          return {
            "data-mentioneremail": attributes.mentionerEmail,
          };
        },
      },
      userId: {
        default: null,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: (element: HTMLElement): any => element.getAttribute("data-userid"),
        // … and customize the HTML rendering.
        renderHTML: (attributes: Record<string, any>): Record<string, any> => {
          return {
            "data-userid": attributes.userId,
          };
        },
      },
      isMe: {
        default: false,
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML: (element: HTMLElement): any => element.getAttribute("data-isme"),
        // … and customize the HTML rendering.
        renderHTML: (attributes: Record<string, any>): Record<string, any> => {
          return {
            "data-isme": attributes.isMe,
            style: attributes.isMe === "true" ? "background-color: #EBF5FF; color: #105EB0" : undefined,
          };
        },
      },
    };
  },
});
