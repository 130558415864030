/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PipelineCandidate,
    PipelineCandidateFromJSON,
    PipelineCandidateFromJSONTyped,
    PipelineCandidateToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidatePipelineListResponse
 */
export interface CandidatePipelineListResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidatePipelineListResponse
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof CandidatePipelineListResponse
     */
    next: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidatePipelineListResponse
     */
    previous: string | null;
    /**
     * 
     * @type {Array<PipelineCandidate>}
     * @memberof CandidatePipelineListResponse
     */
    results: Array<PipelineCandidate>;
}

export function CandidatePipelineListResponseFromJSON(json: any): CandidatePipelineListResponse {
    return CandidatePipelineListResponseFromJSONTyped(json, false);
}

export function CandidatePipelineListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidatePipelineListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'next': json['next'],
        'previous': json['previous'],
        'results': ((json['results'] as Array<any>).map(PipelineCandidateFromJSON)),
    };
}

export function CandidatePipelineListResponseToJSON(value?: CandidatePipelineListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'next': value.next,
        'previous': value.previous,
        'results': ((value.results as Array<any>).map(PipelineCandidateToJSON)),
    };
}


