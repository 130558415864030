import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { useListDoverTalentsQuery } from "services/doverapi/endpoints/talentNetwork";
import { TalentNetwork } from "services/openapi";
import { listTalentsArgsAtom } from "views/candidates/DoverTalentNetwork/atoms/listTalentNetwork";

interface UseDoverTalentsReturn {
  doverTalents?: TalentNetwork[];
  isFetching: boolean;
  isLoading: boolean;
}

export const useDoverTalents = (): UseDoverTalentsReturn => {
  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Form API
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */
  // Should only run the normal api if the ai chat is not open and the search id exists
  const listTalentsArgs = useAtomValue(listTalentsArgsAtom);
  const shouldCall = !!listTalentsArgs?.params;
  const { currentData: formResults, isFetching: isFormFetching, isLoading: isFormLoading } = useListDoverTalentsQuery(
    shouldCall && listTalentsArgs ? listTalentsArgs : skipToken
  );

  /*
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    Combined results
    ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  */

  // This memo is necessary
  const results = useMemo(() => {
    return {
      doverTalents: formResults?.networkMembers,
      isFetching: isFormFetching,
      isLoading: isFormLoading,
    };
  }, [formResults?.networkMembers, isFormFetching, isFormLoading]);

  return results;
};
