import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { GlobalRootState } from "domains/global/types";
import { getOpenApiClients } from "services/api";
import {
  ApiApiGetReferralStatsRequest,
  ApiApiListJobReferralsRequest,
  JobReferral,
  JobReferralContactMethodEnum,
  JobReferralProUser,
  JobReferralReviewStateEnum,
  JobReferralTriagingStatusEnum,
  ShouldBeContactedByProUser,
} from "services/openapi";
import { DjangoListResponseType } from "types";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";
import { selectSelectedJob, selectSelectedReferrer } from "views/Referrals/ReviewReferrals/selectors";

interface LoadJobReferralsFulfilledResult {
  response: DjangoListResponseType<JobReferral>;
  requestParameters: ApiApiListJobReferralsRequest;
}

export const retrieveJobReferral = createAsyncThunk("referrals/retrieveJobReferral", async ({ id }: { id: string }) => {
  const { apiApi } = await getOpenApiClients({});
  return await apiApi.retrieveJobReferral({ id });
});

export const getReferralStats = createAsyncThunk(
  "referrals/getReferralStats",
  async ({ job, referrer }: { job: string | null; referrer: number | null }) => {
    const { apiApi } = await getOpenApiClients({});
    const jobId = job || undefined;
    const referrerId = referrer ? referrer.toString() : undefined;
    const requestParameters: ApiApiGetReferralStatsRequest = {
      job: jobId,
      referrer: referrerId,
    };

    return {
      response: await apiApi.getReferralStats(requestParameters),
      requestParameters,
    };
  }
);

export const fetchActiveJobs = createAsyncThunk("referrals/fetchActiveJobs", async () => {
  const { apiApi } = await getOpenApiClients({});
  return await apiApi.listJobs({ limit: 200, active: "true" });
});

export const loadJobReferrals = createAsyncThunk(
  "referrals/loadJobReferrals",
  async ({
    job,
    referrer,
    triagingStatus,
    offset,
    limit,
  }: {
    job: string | null;
    referrer: number | null;
    triagingStatus: JobReferralTriagingStatusEnum | undefined;
    offset?: number;
    limit?: number;
  }): Promise<LoadJobReferralsFulfilledResult> => {
    const { apiApi } = await getOpenApiClients({});
    const jobId = job || undefined;
    const referrerId = referrer ? referrer.toString() : undefined;
    const requestParameters: ApiApiListJobReferralsRequest = {
      job: jobId,
      referrer: referrerId,
      triagingStatus: triagingStatus,
      ordering: "job__name,match_rank",
      offset,
      limit,
    };
    return {
      response: await apiApi.listJobReferrals(requestParameters),
      requestParameters,
    };
  }
);

export const loadReferrers = createAsyncThunk("referrals/loadJobReferrers", async () => {
  const { apiApi } = await getOpenApiClients({});
  return await apiApi.getJobReferrers({});
});

export const updateJobReferralNotes = createAsyncThunk(
  "referrals/updateJobReferralNotes",
  async ({ jobReferral, notes }: { jobReferral: JobReferral; notes: string }) => {
    const { apiApi } = await getOpenApiClients({});

    try {
      const resp = await apiApi.partialUpdateJobReferral({ id: jobReferral.id!, data: { notes } });

      return resp;
    } catch (e) {
      showErrorToast("Failed to update referral notes. Please refresh and try again.");
      throw e;
    }
  }
);

export const setConnectionShouldReachOut = createAsyncThunk(
  "referrals/setConnectionShouldReachOut",
  async (
    {
      jobReferralId,
      connection,
    }: { jobReferralId: string; connection: ShouldBeContactedByProUser | JobReferralProUser },
    thunkAPI
  ) => {
    const { apiApi } = await getOpenApiClients({});
    try {
      const resp = await apiApi.partialUpdateJobReferral({
        id: jobReferralId,
        data: {
          reviewState: JobReferralReviewStateEnum.Approved,
          contactMethod: JobReferralContactMethodEnum.ManualReachOut,
          shouldBeContactedBy: {
            id: connection.id!,
          },
        },
      });
      showSuccessToast(`Indicated that ${connection.firstName} should reach out`);

      const state = thunkAPI.getState() as GlobalRootState;
      const selectedReferrer = selectSelectedReferrer(state);
      const selectedJob = selectSelectedJob(state);
      thunkAPI.dispatch(
        getReferralStats({
          job: selectedJob?.id as string,
          referrer: selectedReferrer?.id as number,
        })
      );
      return resp;
    } catch (e) {
      showErrorToast("Failed to indicate that connection should reach out. Please refresh and try again.");
      // Get the latest state back from the backend so that our redux state is correct.
      thunkAPI.dispatch(retrieveJobReferral({ id: jobReferralId }));
      throw e;
    }
  }
);

export const setDoverShouldReachOut = createAsyncThunk(
  "referrals/setDoverShouldReachOut",
  async ({ jobReferralId }: { jobReferralId: string }, thunkAPI) => {
    const { apiApi } = await getOpenApiClients({});
    try {
      const resp = await apiApi.partialUpdateJobReferral({
        id: jobReferralId,
        data: {
          reviewState: JobReferralReviewStateEnum.Approved,
          contactMethod: JobReferralContactMethodEnum.DoverReachOut,
        },
      });
      showSuccessToast(
        `Outreach email queued to send to ${resp.person?.firstName || "referral"} for the ${resp.job?.title} role.`
      );

      const state = thunkAPI.getState() as GlobalRootState;
      const selectedReferrer = selectSelectedReferrer(state);
      const selectedJob = selectSelectedJob(state);
      thunkAPI.dispatch(
        getReferralStats({
          job: selectedJob?.id as string,
          referrer: selectedReferrer?.id as number,
        })
      );
      return resp;
    } catch (e) {
      if (e.status === 409) {
        const errMsg = (await e.json()).message;
        showErrorToast(errMsg);
      } else {
        showErrorToast("Failed to update referral. Please refresh and try again.");
      }
      // Get the latest state back from the backend so that our redux state is correct.
      thunkAPI.dispatch(retrieveJobReferral({ id: jobReferralId }));
      throw e;
    }
  }
);

export const setDontReachOut = createAsyncThunk(
  "referrals/setDontReachOut",
  async ({ jobReferralId }: { jobReferralId: string }, thunkAPI) => {
    const { apiApi } = await getOpenApiClients({});
    try {
      const resp = await apiApi.partialUpdateJobReferral({
        id: jobReferralId,
        data: {
          reviewState: JobReferralReviewStateEnum.Rejected,
        },
      });
      showSuccessToast(
        `Indicated that ${resp.person?.firstName || "referral"} should not be reached out to for the ${
          resp.job?.title
        } role.`
      );

      const state = thunkAPI.getState() as GlobalRootState;
      const selectedReferrer = selectSelectedReferrer(state);
      const selectedJob = selectSelectedJob(state);
      thunkAPI.dispatch(
        getReferralStats({
          job: selectedJob?.id as string,
          referrer: selectedReferrer?.id as number,
        })
      );
      return resp;
    } catch (e) {
      showErrorToast("Failed to update referral. Please refresh and try again.");
      // Get the latest state back from the backend so that our redux state is correct.
      thunkAPI.dispatch(retrieveJobReferral({ id: jobReferralId }));
      throw e;
    }
  }
);

export const markAsContacted = createAsyncThunk(
  "referrals/markAsContacted",
  async ({ jobReferralId }: { jobReferralId: string }, thunkAPI) => {
    const { apiApi } = await getOpenApiClients({});
    try {
      const resp = await apiApi.markJobReferralAsContacted({
        id: jobReferralId,
        data: {},
      });
      showSuccessToast(`Indicated that ${resp.person?.firstName || "referral"} has been contacted`);

      const state = thunkAPI.getState() as GlobalRootState;
      const selectedReferrer = selectSelectedReferrer(state);
      const selectedJob = selectSelectedJob(state);
      thunkAPI.dispatch(
        getReferralStats({
          job: selectedJob?.id as string,
          referrer: selectedReferrer?.id as number,
        })
      );
      return resp;
    } catch (e) {
      if (e.status === 409) {
        const errMsg = (await e.json()).message;
        showErrorToast(errMsg);
      } else {
        showErrorToast("Failed to update referral. Please refresh and try again.");
      }
      // Get the latest state back from the backend so that our redux state is correct.
      thunkAPI.dispatch(retrieveJobReferral({ id: jobReferralId }));
      throw e;
    }
  }
);

export const revertToNeedsReview = createAsyncThunk(
  "referrals/revertToNeedsReview",
  async ({ jobReferralId }: { jobReferralId: string }, thunkAPI) => {
    const { apiApi } = await getOpenApiClients({});
    showPendingToast("Moving referral back to Needs Review");
    try {
      const resp = await apiApi.revertToNeedsReview({
        id: jobReferralId,
        data: {},
      });
      showSuccessToast(`Successfully moved ${resp.person?.firstName || "referral"} back to Needs Review`);

      const state = thunkAPI.getState() as GlobalRootState;
      const selectedReferrer = selectSelectedReferrer(state);
      const selectedJob = selectSelectedJob(state);
      thunkAPI.dispatch(
        getReferralStats({
          job: selectedJob?.id as string,
          referrer: selectedReferrer?.id as number,
        })
      );
      return resp;
    } catch (e) {
      if (e.status === 409) {
        const errMsg = (await e.json()).message;
        showErrorToast(errMsg);
      } else {
        showErrorToast(`Failed to move referral back to Needs Review`);
      }

      // Get the latest state back from the backend so that our redux state is correct.
      thunkAPI.dispatch(retrieveJobReferral({ id: jobReferralId }));
      throw e;
    }
  }
);

export const setSelectedReferrerId = createAction("referrals/setSelectedReferrerId", (referrerId: number | null) => ({
  payload: { referrerId },
}));

export const setCurrentPageIndex = createAction("referrals/setCurrentPageIndex", (currentPageIndex: number) => ({
  payload: {
    currentPageIndex,
  },
}));

export const setNumItemsPerPage = createAction("referrals/setNumItemsPerPage", (numItemsPerPage: number) => ({
  payload: {
    numItemsPerPage,
  },
}));
