import { Box, Stack } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, ButtonVariant } from "components/library/Button";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { useGetIncompleteJobSetupStepsForFeature } from "services/doverapi/cross-endpoint-hooks/useGetIncompleteJobSetupStepsForFeature";
import { JobFeatureFeatureNameEnum } from "services/openapi";
import { B1_doNotUse } from "styles/typography";

const ActivateFeatureConfirmationModal = ({
  isModalOpen,
  forceLoading,
  featureName,
  title,
  body,
  toggleModalOff,
  onConfirmEnable,
}: {
  isModalOpen: boolean;
  forceLoading?: boolean;
  featureName: JobFeatureFeatureNameEnum;
  title: string;
  body?: string | React.ReactNode;
  toggleModalOff: () => void;
  onConfirmEnable: (onContinue?: () => void) => void;
}): React.ReactElement => {
  const { jobId } = useParams<{ jobId: string }>();
  const incompleteSetupSteps = useGetIncompleteJobSetupStepsForFeature(featureName, jobId);

  const navigate = useNavigate();

  const isLoading = incompleteSetupSteps === undefined || forceLoading;

  const onContinue = React.useCallback(() => {
    const navigateToStep = (): void => {
      if (incompleteSetupSteps === undefined || incompleteSetupSteps.length === 0) {
        return;
      }

      navigate(incompleteSetupSteps[0].route);
    };

    onConfirmEnable(navigateToStep);
  }, [navigate, incompleteSetupSteps, onConfirmEnable]);

  React.useEffect(() => {
    // If there's no incomplete steps and no text to show, just auto-confirm
    if (body === undefined && incompleteSetupSteps !== undefined && incompleteSetupSteps.length === 0) {
      onConfirmEnable();
    }
  }, [body, incompleteSetupSteps, onConfirmEnable]);

  const dialogActions = React.useMemo(() => {
    if (incompleteSetupSteps === undefined) {
      return <></>;
    }

    if (incompleteSetupSteps.length === 0) {
      return (
        <Button variant={ButtonVariant.Primary} onClick={(): void => onConfirmEnable()} loading={isLoading}>
          Confirm
        </Button>
      );
    }

    return (
      <Stack direction="row" spacing={1} justifyContent="center">
        <Button variant={ButtonVariant.Secondary} onClick={toggleModalOff}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={onContinue} loading={isLoading}>
          Continue
        </Button>
      </Stack>
    );
  }, [incompleteSetupSteps, isLoading, onConfirmEnable, onContinue, toggleModalOff]);

  if (body === undefined && incompleteSetupSteps !== undefined && incompleteSetupSteps.length === 0) {
    return <></>;
  }

  return (
    <CustomModal
      open={isModalOpen}
      onClose={toggleModalOff}
      title={<B1_doNotUse bold>{title}</B1_doNotUse>}
      maxWidth={"xs"}
      customDialogStyles={{ zIndex: 9999 }}
      dialogActions={dialogActions}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <DoverLoadingSpinner minHeight="40px" height="40px" spinnerSize="30px" width="40px" />
        </Box>
      ) : (
        <B1_doNotUse>
          <Stack spacing={2}>
            {incompleteSetupSteps !== undefined && incompleteSetupSteps.length > 0 && (
              <Stack spacing={1}>
                <Box>{`There ${
                  incompleteSetupSteps.length === 1 ? "is an additional setup item" : "are additional setup items"
                } to complete:`}</Box>
                <ul>
                  {incompleteSetupSteps.map(enrichedJobSetupStep => (
                    <li key={enrichedJobSetupStep.stepName}>{enrichedJobSetupStep.label}</li>
                  ))}
                </ul>
              </Stack>
            )}
            {body && <div>{body}</div>}
          </Stack>
        </B1_doNotUse>
      )}
    </CustomModal>
  );
};

export default ActivateFeatureConfirmationModal;
