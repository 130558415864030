import { Stack } from "@mui/material";
import React, { ReactElement, useState } from "react";
import styled from "styled-components";

import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { standardSelectableVariables, multipartStageSelectableVariables } from "components/VariableSelector/constants";
import { colors } from "styles/theme";

export const VariableSelector = ({
  variables,
  isMultipart = false,
}: {
  variables?: string[];
  isMultipart?: boolean;
}): ReactElement => {
  const [clipboardText, setClipboardText] = useState<string | undefined>(undefined);

  const selectableVariables =
    // If variables are passed in, use those. Otherwise, use the standard selectable variables based on single vs multipart interview messaging.
    variables?.length ? variables : isMultipart ? multipartStageSelectableVariables : standardSelectableVariables;

  const copyToClipboard = (textToCopy: string): void => {
    navigator.clipboard.writeText(textToCopy);
    setClipboardText(textToCopy);
  };

  return (
    <VariableSelectContainer spacing={1}>
      <Subtitle2>Personalize your email</Subtitle2>
      <BodySmall>To insert dynamic content, you can use the following:</BodySmall>
      <ul>
        {selectableVariables.map(v => {
          const variableWithTokens = `{{${v}}}`;
          return (
            <Tooltip
              title={clipboardText === variableWithTokens ? "Copied!" : "Click to copy"}
              variant={TooltipVariant.Dark}
              placement="top"
            >
              <li
                onClick={(): void => {
                  copyToClipboard(variableWithTokens);
                }}
              >
                {variableWithTokens}
              </li>
            </Tooltip>
          );
        })}
      </ul>
    </VariableSelectContainer>
  );
};

const VariableSelectContainer = styled(Stack)`
  background-color: ${colors.grayscale.gray100};
  border-radius: 3px;
  padding: 16px;
  width: 100%;

  ul {
    padding-left: 16px;
  }

  li {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 14px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      background-color: ${colors.grayscale.gray200};
    }
  }
`;
