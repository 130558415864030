/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeverAuthInfo
 */
export interface LeverAuthInfo {
    /**
     * 
     * @type {boolean}
     * @memberof LeverAuthInfo
     */
    isValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeverAuthInfo
     */
    hasLssInterviewScopes: boolean;
}

export function LeverAuthInfoFromJSON(json: any): LeverAuthInfo {
    return LeverAuthInfoFromJSONTyped(json, false);
}

export function LeverAuthInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeverAuthInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isValid': json['is_valid'],
        'hasLssInterviewScopes': json['has_lss_interview_scopes'],
    };
}

export function LeverAuthInfoToJSON(value?: LeverAuthInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_valid': value.isValid,
        'has_lss_interview_scopes': value.hasLssInterviewScopes,
    };
}


