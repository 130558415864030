/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientEmailProvider,
    ClientEmailProviderFromJSON,
    ClientEmailProviderFromJSONTyped,
    ClientEmailProviderToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateClientBody
 */
export interface UpdateClientBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    realName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    doverPlan?: UpdateClientBodyDoverPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    customerFacingNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    atsType?: UpdateClientBodyAtsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    setupAtsState?: UpdateClientBodySetupAtsStateEnum;
    /**
     * 
     * @type {ClientEmailProvider}
     * @memberof UpdateClientBody
     */
    emailProvider?: ClientEmailProvider;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    linkedinCompanyId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateClientBody
     */
    isAgency?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    region?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientBody
     */
    referralSource?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateClientBody
     */
    enableTalentNetwork?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UpdateClientBodyDoverPlanEnum {
    NoAccess = 'NO_ACCESS',
    Free = 'FREE',
    Credits = 'CREDITS',
    PayAsYouGo = 'PAY_AS_YOU_GO',
    JobSlots = 'JOB_SLOTS',
    Growth = 'GROWTH',
    Concierge = 'CONCIERGE',
    FreeAts = 'FREE_ATS',
    FreeExtension = 'FREE_EXTENSION'
}/**
* @export
* @enum {string}
*/
export enum UpdateClientBodyAtsTypeEnum {
    Greenhouse = 'GREENHOUSE',
    Lever = 'LEVER',
    Ashby = 'ASHBY',
    GoogleHire = 'GOOGLE_HIRE',
    NoAts = 'NO_ATS',
    Other = 'OTHER',
    NotRequired = 'NOT_REQUIRED'
}/**
* @export
* @enum {string}
*/
export enum UpdateClientBodySetupAtsStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function UpdateClientBodyFromJSON(json: any): UpdateClientBody {
    return UpdateClientBodyFromJSONTyped(json, false);
}

export function UpdateClientBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClientBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'realName': json['real_name'],
        'doverPlan': !exists(json, 'dover_plan') ? undefined : json['dover_plan'],
        'customerFacingNotes': !exists(json, 'customer_facing_notes') ? undefined : json['customer_facing_notes'],
        'atsType': !exists(json, 'ats_type') ? undefined : json['ats_type'],
        'setupAtsState': !exists(json, 'setup_ats_state') ? undefined : json['setup_ats_state'],
        'emailProvider': !exists(json, 'email_provider') ? undefined : ClientEmailProviderFromJSON(json['email_provider']),
        'linkedinCompanyId': !exists(json, 'linkedin_company_id') ? undefined : json['linkedin_company_id'],
        'isAgency': !exists(json, 'is_agency') ? undefined : json['is_agency'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'referralSource': !exists(json, 'referral_source') ? undefined : json['referral_source'],
        'enableTalentNetwork': !exists(json, 'enable_talent_network') ? undefined : json['enable_talent_network'],
    };
}

export function UpdateClientBodyToJSON(value?: UpdateClientBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'slug': value.slug,
        'real_name': value.realName,
        'dover_plan': value.doverPlan,
        'customer_facing_notes': value.customerFacingNotes,
        'ats_type': value.atsType,
        'setup_ats_state': value.setupAtsState,
        'email_provider': ClientEmailProviderToJSON(value.emailProvider),
        'linkedin_company_id': value.linkedinCompanyId,
        'is_agency': value.isAgency,
        'region': value.region,
        'referral_source': value.referralSource,
        'enable_talent_network': value.enableTalentNetwork,
    };
}


