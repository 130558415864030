import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { useDeleteHiringStageEmailTemplateMutation } from "services/doverapi/endpoints/emailTemplates";

const StyledDeleteMenuItem = styled(MenuItem)`
  color: ${red[500]};
`;

export const ActionMenu = (): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId, emailTemplateId } = useParams<{ jobId: string; emailTemplateId: string }>();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const [deleteHiringStageEmailTemplate] = useDeleteHiringStageEmailTemplateMutation();

  const deleteTemplate = (): void => {
    if (!jobId || !emailTemplateId) {
      console.error("jobId or emailTemplateId is missing");
      return;
    }
    deleteHiringStageEmailTemplate({ emailTemplateId });
    navigate(APP_ROUTE_PATHS.admin.jobEmailTemplates(jobId), { replace: true });
  };

  return (
    <div>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <StyledDeleteMenuItem
          onClick={(): void => {
            deleteTemplate();
            handleClose();
          }}
        >
          Delete template
        </StyledDeleteMenuItem>
      </Menu>
    </div>
  );
};
