import { Box } from "@mui/material";
import React from "react";
import { matchPath, Navigate, Outlet, useLocation } from "react-router-dom";

import { useIsOnPublicRoute, useIsOnFallbackNotFoundPage } from "App/routing/hooks";
import { getOnboardingFlowRoute } from "App/routing/utils";
import Loading from "components/HotLoading";
import { useGetCurrentOnboardingStepForUserQuery } from "services/doverapi/endpoints/user-onboarding-flow/userOnboardingFlow";
import { colors } from "styles/theme";

const InitialRoute = (): React.ReactElement => {
  const { pathname } = useLocation();

  const {
    currentOnboardingRoute,
    isFetchingCurrentOnboardingStep,
    isUninitialized,
  } = useGetCurrentOnboardingStepForUserQuery(undefined, {
    selectFromResult: ({ data, isFetching, isUninitialized }) => ({
      currentOnboardingRoute: getOnboardingFlowRoute({
        currentOnboardingStepResponse: data,
      }),
      isFetchingCurrentOnboardingStep: isFetching,
      isUninitialized,
    }),
  });

  const isOnPublicRoute = useIsOnPublicRoute();
  const isOnFallbackNotFoundPage = useIsOnFallbackNotFoundPage();

  const isOnOnboardingRoute = !!matchPath({ path: "/onboarding", end: false }, pathname);

  if (isFetchingCurrentOnboardingStep || isUninitialized) {
    return (
      // This styled Box ensures the initial loading screen appears above the nav components
      // defined in MainLayout
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100vw"
        height="100vh"
        zIndex={5000}
        bgcolor={colors.grayscale.gray100}
      >
        <Loading />
      </Box>
    );
  }

  // Check if we should redirect the user to the onboarding flow
  if (
    // we have a current onboarding route
    currentOnboardingRoute &&
    // and the user is not on a public route (those that don't require auth), such as accessing a careers page
    // or inbound applications
    !isOnPublicRoute &&
    // Excluding NotFound is a product decision to not redirect the user if they're
    // trying to access a nonexistent route
    !isOnFallbackNotFoundPage &&
    // and not on a route containing `/onboarding` -- we don't want to redirect
    // the user to the onboarding flow if they're already on it
    !isOnOnboardingRoute
  ) {
    return <Navigate to={currentOnboardingRoute} replace />;
  }

  // Else redirect app
  return <Outlet />;
};

export default InitialRoute;
