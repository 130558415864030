/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlimProUserField
 */
export interface SlimProUserField {
    /**
     * 
     * @type {number}
     * @memberof SlimProUserField
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SlimProUserField
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof SlimProUserField
     */
    pictureUrl?: string;
}

export function SlimProUserFieldFromJSON(json: any): SlimProUserField {
    return SlimProUserFieldFromJSONTyped(json, false);
}

export function SlimProUserFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlimProUserField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fullName': json['full_name'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
    };
}

export function SlimProUserFieldToJSON(value?: SlimProUserField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'full_name': value.fullName,
        'picture_url': value.pictureUrl,
    };
}


