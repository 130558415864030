/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtensionCampaignEmailSenderAlias,
    ExtensionCampaignEmailSenderAliasFromJSON,
    ExtensionCampaignEmailSenderAliasFromJSONTyped,
    ExtensionCampaignEmailSenderAliasToJSON,
    ExtensionCampaignMessage,
    ExtensionCampaignMessageFromJSON,
    ExtensionCampaignMessageFromJSONTyped,
    ExtensionCampaignMessageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExtensionCampaign
 */
export interface ExtensionCampaign {
    /**
     * 
     * @type {Date}
     * @memberof ExtensionCampaign
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExtensionCampaign
     */
    readonly modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaign
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaign
     */
    state?: ExtensionCampaignStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtensionCampaign
     */
    name: string;
    /**
     * 
     * @type {ExtensionCampaignMessage}
     * @memberof ExtensionCampaign
     */
    firstMessage?: ExtensionCampaignMessage | null;
    /**
     * 
     * @type {ExtensionCampaignEmailSenderAlias}
     * @memberof ExtensionCampaign
     */
    emailAlias: ExtensionCampaignEmailSenderAlias;
}

/**
* @export
* @enum {string}
*/
export enum ExtensionCampaignStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED'
}

export function ExtensionCampaignFromJSON(json: any): ExtensionCampaign {
    return ExtensionCampaignFromJSONTyped(json, false);
}

export function ExtensionCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtensionCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'name': json['name'],
        'firstMessage': !exists(json, 'first_message') ? undefined : ExtensionCampaignMessageFromJSON(json['first_message']),
        'emailAlias': ExtensionCampaignEmailSenderAliasFromJSON(json['email_alias']),
    };
}

export function ExtensionCampaignToJSON(value?: ExtensionCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'name': value.name,
        'first_message': ExtensionCampaignMessageToJSON(value.firstMessage),
        'email_alias': ExtensionCampaignEmailSenderAliasToJSON(value.emailAlias),
    };
}


