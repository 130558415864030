import { Stack } from "@mui/material";
import React from "react";

import { ReactComponent as CheckSVG } from "assets/icons/simple-green-check.svg";
import { ReactComponent as WarningIcon } from "assets/icons/warning-outlined.svg";
import { Overline } from "components/library/typography";
import { JobFeatureUIState } from "services/doverapi/endpoints/job/types";
import { colors } from "styles/theme";
import { BaseBadge } from "views/job/JobSetup/steps/Overview/styles";

export const CardBadge = ({
  jobFeatureUIState,
  jobSnoozedUntil,
}: {
  jobFeatureUIState: JobFeatureUIState;
  jobSnoozedUntil?: string;
}): React.ReactElement => {
  switch (jobFeatureUIState) {
    case JobFeatureUIState.Active:
      return (
        <BaseBadge>
          <Stack direction="row" alignItems="center">
            <CheckSVG />
            <Overline color={colors.success.base}>Active</Overline>
          </Stack>
        </BaseBadge>
      );
    case JobFeatureUIState.NeedsSetup:
      return (
        <BaseBadge $backgroundColor={colors.warning.light}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <WarningIcon />
            <Overline color={colors.warning.base}>Needs Setup</Overline>
          </Stack>
        </BaseBadge>
      );
    case JobFeatureUIState.Paused:
      return (
        <BaseBadge $backgroundColor={colors.grayscale.gray200}>
          <Overline>{jobSnoozedUntil ? `Paused until ${jobSnoozedUntil}` : "Paused"}</Overline>
        </BaseBadge>
      );
    case JobFeatureUIState.Inactive:
      return (
        <BaseBadge $backgroundColor={colors.grayscale.gray200}>
          <Overline>Inactive</Overline>
        </BaseBadge>
      );
    default:
      return <></>;
  }
};
