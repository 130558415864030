import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { FormControl, FormGroup, FormHelperText, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { FormFieldGrid, validationError } from "components/data-driven-forms/fields/helpers";
import RangeSliderInput from "components/inputs";

const RangeSlider = props => {
  const {
    input,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    FormFieldGridProps,
    FormControlProps,
    FormGroupProps,
    FormHelperTextProps,
    after,
    InputGridProps,
    SliderGridProps,
    AfterGridProps,
    initialValue,
    ...rest
  } = useFieldApi(props);

  const invalid = validationError(meta, validateOnMount);
  const text = invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description;

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <FormControl fullWidth required={isRequired} error={!!invalid} component="fieldset" {...FormControlProps}>
        <FormGroup {...FormGroupProps}>
          <Grid container spacing={2} alignItems="center" {...InputGridProps}>
            <Grid item xs {...SliderGridProps}>
              <RangeSliderInput
                {...input}
                initialValue={initialValue ?? input.value}
                {...rest}
                label={label}
                onChange={(_e, value) => {
                  input.onChange(value);
                }}
              />
            </Grid>
            {after && (
              <Grid item {...AfterGridProps}>
                {after}
              </Grid>
            )}
          </Grid>
          {text && <FormHelperText {...FormHelperTextProps}>{text}</FormHelperText>}
        </FormGroup>
      </FormControl>
    </FormFieldGrid>
  );
};

RangeSlider.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  description: PropTypes.node,
  validateOnMount: PropTypes.bool,
  FormFieldGridProps: PropTypes.object,
  FormControlProps: PropTypes.object,
  FormGroupProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  after: PropTypes.node,
  InputGridProps: PropTypes.object,
  SliderGridProps: PropTypes.object,
  AfterGridProps: PropTypes.object,
};

export default RangeSlider;
