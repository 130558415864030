import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { Overline } from "components/library/typography";
import { colors } from "styles/theme";

const StyledToggleButton = styled(ToggleButton)`
  &.MuiButtonBase-root {
    padding: 8px 10px;
  }

  &:active,
  &:focus {
    outline: none !important;
  }
`;

interface RequiredToggleProps {
  required: boolean;
  onChange: (required: boolean) => void;
}

// This component is made specifically to work with the SearchV3 form
// If usage is desired elsewhere, this component will need to be modified,
// or a new component will need to be made
const RequiredToggle = React.memo(
  ({ required, onChange }: RequiredToggleProps): React.ReactElement => {
    const [alignment, setAlignment] = React.useState<string | null>(required ? "must have" : "nice to have");

    const handleAlignment = React.useCallback(
      (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        setAlignment(newAlignment);
        onChange(!!newAlignment && newAlignment === "must have");
      },
      [onChange]
    );

    React.useEffect(() => {
      setAlignment(required ? "must have" : "nice to have");
    }, [required]);

    return (
      <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment}>
        <StyledToggleButton value="nice to have">
          <Overline color={required ? colors.grayscale.gray500 : colors.primary.base}>{"nice to have"}</Overline>
        </StyledToggleButton>
        <StyledToggleButton value="must have">
          <Overline color={required ? colors.primary.base : colors.grayscale.gray500}>{"must have"}</Overline>
        </StyledToggleButton>
      </ToggleButtonGroup>
    );
  }
);

export default RequiredToggle;
