import { Campaign, UnsubscribeFooter } from "services/openapi";

export interface CampaignViewState {
  loading: boolean;
  campaign?: Campaign;
  unsubscribeFootersLoading: boolean;
  unsubscribeFooters?: UnsubscribeFooter[];
  saving: boolean;
}

// Might need a better way to distribute this
export interface RootState {
  campaignView: CampaignViewState;
}

export interface CampaignViewDesiredSelectionProps {
  loading: boolean;
  campaign?: Campaign;
  unsubscribeFootersLoading: boolean;
  unsubscribeFooters?: UnsubscribeFooter[];
  saving: boolean;
}

export interface CampaignViewDispatchProps {
  loadCampaign: (campaignId: string) => void;
  saveCampaign: (campaign: Campaign) => void;
  loadUnsubscribeFooters: () => void;
  sendTestEmails: (campaign: Campaign) => void;
}

export interface CampaignViewProps extends CampaignViewDesiredSelectionProps, CampaignViewDispatchProps {}

/* Actions */

// Load campaign data

export const LOAD_CAMPAIGN = "app/CampaignView/LOAD_CAMPAIGN";
export interface LoadCampaignAction {
  type: typeof LOAD_CAMPAIGN;
  campaignId: string;
}

export const LOAD_CAMPAIGN_SUCCESS = "app/CampaignView/LOAD_CAMPAIGN_SUCCESS";
export interface LoadCampaignSuccessAction {
  type: typeof LOAD_CAMPAIGN_SUCCESS;
  campaign: Campaign;
}

export const LOAD_CAMPAIGN_ERROR = "app/CampaignView/LOAD_CAMPAIGN_ERROR";
export interface LoadCampaignErrorAction {
  type: typeof LOAD_CAMPAIGN_ERROR;
  error?: string;
}

// Save campaign data

export const SAVE_CAMPAIGN = "app/CampaignView/SAVE_CAMPAIGN";
export interface SaveCampaignAction {
  type: typeof SAVE_CAMPAIGN;
  campaign: Campaign;
}

export const SAVE_CAMPAIGN_SUCCESS = "app/CampaignView/SAVE_CAMPAIGN_SUCCESS";
export interface SaveCampaignSuccessAction {
  type: typeof SAVE_CAMPAIGN_SUCCESS;
  campaign: Campaign;
}

export const SAVE_CAMPAIGN_ERROR = "app/CampaignView/SAVE_CAMPAIGN_ERROR";
export interface SaveCampaignErrorAction {
  type: typeof SAVE_CAMPAIGN_ERROR;
  error?: string;
}

// Load unsubscribe footers

export const LOAD_UNSUBSCRIBE_FOOTERS = "app/CampaignView/LOAD_UNSUBSCRIBE_FOOTERS";
export interface LoadUnsubscribeFootersAction {
  type: typeof LOAD_UNSUBSCRIBE_FOOTERS;
}

export const LOAD_UNSUBSCRIBE_FOOTERS_SUCCESS = "app/CampaignView/LOAD_UNSUBSCRIBE_FOOTERS_SUCCESS";
export interface LoadUnsubscribeFootersSuccessAction {
  type: typeof LOAD_UNSUBSCRIBE_FOOTERS_SUCCESS;
  unsubscribeFooters: UnsubscribeFooter[];
}

export const LOAD_UNSUBSCRIBE_FOOTERS_ERROR = "app/CampaignView/LOAD_UNSUBSCRIBE_FOOTERS_ERROR";
export interface LoadUnsubscribeFootersErrorAction {
  type: typeof LOAD_UNSUBSCRIBE_FOOTERS_ERROR;
  error?: string;
}

// Send test emails

export const SEND_TEST_EMAILS = "app/CampaignView/SEND_TEST_EMAILS";
export interface SendTestEmailsAction {
  type: typeof SEND_TEST_EMAILS;
  campaign: Campaign;
}

export type CampaignViewActionTypes =
  | LoadCampaignAction
  | LoadCampaignSuccessAction
  | LoadCampaignErrorAction
  | SaveCampaignAction
  | SaveCampaignSuccessAction
  | SaveCampaignErrorAction
  | LoadUnsubscribeFootersAction
  | LoadUnsubscribeFootersSuccessAction
  | LoadUnsubscribeFootersErrorAction
  | SendTestEmailsAction;
