/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { meta } from "@data-driven-forms/common/prop-types-templates";
import DDFSelect from "@data-driven-forms/common/select";
import parseInternalValue from "@data-driven-forms/common/select/parse-internal-value";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import React from "react";

import { validationError, FormFieldGrid } from "components/data-driven-forms/fields/helpers";

/**
 * Returns label of selected option
 * @param {Object|Array} option currently selected option
 * @param {Array<Object>} options all options avaiable
 * @returns {String}
 */
const getOptionLabel = (option, options) => {
  if (typeof option === "undefined") {
    return "";
  }

  if (option === "") {
    return "";
  }

  if (Array.isArray(option) && option.length === 0) {
    return "";
  }

  if (typeof option === "object") {
    return option.label;
  }

  const item = options.find(({ value }) => value === option);
  return (item && item.label) || "";
};

/**
 * Function that creates correct DDF select value format
 * @param {Object|Array} option currently selected values
 * @param {Boolean} isMulti multiple select flag
 * @returns {Object|Array<Object>}
 */
const createValue = (option, isMulti) => {
  if (isMulti) {
    return Array.isArray(option) ? option.map(item => (typeof item === "object" ? item : { value: item })) : option;
  }

  return option;
};

const InternalSelect = ({
  value,
  options,
  label,
  helperText,
  validateOnMount,
  meta,
  isSearchable,
  description,
  isMulti,
  placeholder,
  onInputChange,
  isFetching,
  noOptionsMessage,
  hideSelectedOptions,
  required,
  onChange,
  onFocus,
  onBlur,
  FormFieldGridProps,
  TextFieldProps: { inputProps: textFieldInputProps, ...TextFieldProps },
  inputProps,
  isClearable,
  isDisabled,
  maxOptions,
  disableCloseOnSelect,
  ...rest
}) => {
  const invalid = validationError(meta, validateOnMount);
  const internalValue = parseInternalValue(value, isMulti);
  const [internalSelected, setInternalSelected] = React.useState();

  React.useEffect(() => {
    // Handle remounts in wizard form correctly
    if (isMulti && (internalValue || []).length > 0 && !internalSelected) {
      setInternalSelected(internalValue);
    }
  }, [internalValue]);

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <Autocomplete
        filterSelectedOptions={hideSelectedOptions}
        disabled={isDisabled}
        disableClearable={isClearable}
        popupIcon={isFetching ? <CircularProgress size={20} color="inherit" /> : <ArrowDropDownIcon />}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionDisabled={o => {
          let disable = false;

          if (isMulti) {
            const alreadySelected = !!(internalSelected ?? []).find(s => (s.value ?? s) === o.value);
            disable = alreadySelected;
            if (maxOptions) {
              const numSelected = (internalSelected ?? []).length;
              const maxReached = numSelected === maxOptions;
              disable = disable || maxReached;
            }
          }

          return disable;
        }}
        {...rest}
        renderInput={params => {
          return (
            <TextField
              fullWidth
              onFocus={onFocus}
              onBlur={onBlur}
              {...params}
              required={required}
              error={!!invalid}
              helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
              label={label}
              margin="normal"
              {...TextFieldProps}
              inputProps={{
                ...params.inputProps,
                ...textFieldInputProps,
                ...inputProps,
                readOnly: !isSearchable,
                placeholder: !internalValue || internalValue.length === 0 ? placeholder : undefined,
              }}
            />
          );
        }}
        noOptionsText={noOptionsMessage()}
        onInputChange={(_event, value) => onInputChange(value)}
        options={options}
        multiple={isMulti}
        getOptionLabel={option => getOptionLabel(option, options)}
        value={typeof internalValue === "undefined" ? null : internalValue}
        onChange={(_event, option) => {
          if (isMulti) {
            setInternalSelected(option);
          }

          return onChange(createValue(option, isMulti));
        }}
        loading={isFetching}
      />
    </FormFieldGrid>
  );
};

InternalSelect.propTypes = {
  meta,
  placeholder: PropTypes.node,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.node.isRequired }))
    .isRequired,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  value: PropTypes.any,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  loadingMessage: PropTypes.node,
  onInputChange: PropTypes.func,
  isFetching: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  hideSelectedOptions: PropTypes.bool,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  TextFieldProps: PropTypes.object,
  inputProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  maxOptions: PropTypes.number,
};

InternalSelect.defaultProps = {
  placeholder: "Please choose",
  FormFieldGridProps: {},
  TextFieldProps: {},
  inputProps: {},
  loadingText: "Loading...",
};

const Select = props => {
  const {
    input,
    isRequired,
    isDisabled,
    isReadOnly,
    disabled,
    multiple,
    isMulti,
    isClearable,
    disableClearable,
    loadingMessage,
    loadingText,
    noOptionsMessage,
    noOptionsText,
    disableCloseOnSelect,
    ...rest
  } = useFieldApi(props);
  const multi = multiple || isMulti;
  return (
    <DDFSelect
      {...input}
      isMulti={multi}
      disableCloseOnSelect={disableCloseOnSelect || multi}
      required={isRequired}
      disabled={isDisabled || isReadOnly || disabled}
      disableClearable={!isClearable || disableClearable}
      loadingText={loadingMessage || loadingText}
      noOptionsMessage={noOptionsMessage || noOptionsText}
      {...rest}
      SelectComponent={InternalSelect}
    />
  );
};

export default Select;
