import { Button, Progress } from "@doverhq/dover-ui";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { Alert } from "reactstrap";

import { CompanySetupSectionType } from "App/routing/types";
import KeyIcon from "assets/icons/key.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { useGetOrCreateClientApiQuery } from "services/doverapi/endpoints/clientApiKey";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";

const credentialSource = "GENERIC";
const apiDocLink = `${appConfig.appUrl}/docs/api`;
const helpDocLink = "https://help.dover.com/en/articles/10088975-dover-api";

const ApiKey = (): React.ReactElement => {
  const { data, isLoading, error: loadError } = useGetOrCreateClientApiQuery(credentialSource);
  const [isKeyVisible, setIsKeyVisible] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = (): void => {
    if (data?.key) {
      navigator.clipboard.writeText(data.key);
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    }
  };

  const toggleKeyVisibility = (): void => {
    setIsKeyVisible(!isKeyVisible);
  };
  const loading = !data?.key || isLoading;

  if (loading) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  if (loadError) {
    return (
      <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.API_KEY]}>
        <Alert color="danger">Failed to load api keys</Alert>
      </SectionWrapper>
    );
  }

  return (
    <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.API_KEY]}>
      <Tooltip open={showTooltip} title="API key copied!" placement="bottom-start">
        <Box style={{ paddingTop: "4px", display: "flex", alignItems: "center" }}>
          <ReactSVG src={KeyIcon} style={{ width: "24px", height: "24px", paddingRight: "4px" }} />
          <BodySmall style={{ marginRight: "8px" }}>App Key:</BodySmall>
          <BodySmall style={{ marginRight: "8px" }}>{isKeyVisible ? data?.key : "••••••••••••••••"}</BodySmall>
          <Button onPress={toggleKeyVisibility} variant="primaryOutlined" style={{ marginRight: "8px" }}>
            {isKeyVisible ? "Hide" : "Reveal"}
          </Button>
          <Button id="copyButton" onPress={handleCopy} variant="outlined">
            Copy
          </Button>
        </Box>
      </Tooltip>
      <Box style={{ paddingTop: "8px" }}>
        <BodySmall>
          <a href={apiDocLink} target="_blank" rel="noopener noreferrer">
            View API Documentation
          </a>
        </BodySmall>
      </Box>
      <Box style={{ paddingTop: "8px" }}>
        <BodySmall>
          Interested in setting up Zapier?{" "}
          <a href={helpDocLink} target="_blank" rel="noopener noreferrer">
            Check out our help article on the API and Zapier.
          </a>
        </BodySmall>
      </Box>
    </SectionWrapper>
  );
};

export default ApiKey;
