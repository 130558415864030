import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import React from "react";
import { useForm, FormProvider, useController } from "react-hook-form";
import { z } from "zod";

import { GrayButton, PrimaryButton } from "components/Button";
import { StyledTextField } from "components/dover/inputs/pro-users/styles";
import { BodyExtraSmall, BodySmall } from "components/library/typography";
import DoverModal from "components/Modal";
import { Spacer } from "components/Spacer";
import { useValidateDomain } from "hooks/emailValidation";
import { useCreateProUserMutation } from "services/doverapi/endpoints/proUser";
import { DoverUser } from "services/openapi";
import { colors } from "styles/theme";

interface Props {
  open: boolean;
  handleClose: (newProUser?: DoverUser) => void;
}
const AddProUserFormSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
});

type AddProUserFormSchemaType = z.infer<typeof AddProUserFormSchema>;

const initialFormValues: AddProUserFormSchemaType = {
  firstName: "",
  lastName: "",
  email: "",
};

const HelperText = ({ text, isError }: { text: string; isError?: boolean }): React.ReactElement => {
  return <BodyExtraSmall color={isError ? colors.critical.base : colors.grayscale.gray500}>{text}</BodyExtraSmall>;
};

const HelperTextWithBox = (props: { text: string; isError?: boolean }): React.ReactElement => {
  return (
    <Box marginTop="5px">
      <HelperText {...props} />
    </Box>
  );
};

const AddProUserModal = ({ open, handleClose }: Props): React.ReactElement => {
  const [createProUser, { isError, isLoading }] = useCreateProUserMutation();

  const formMethods = useForm<AddProUserFormSchemaType>({
    resolver: zodResolver(AddProUserFormSchema),
    defaultValues: initialFormValues,
  });
  const { control, handleSubmit, formState } = formMethods;
  const { errors } = formState;

  const { field: firstNameField } = useController({
    name: "firstName",
    control,
  });

  const { field: lastNameField } = useController({
    name: "lastName",
    control,
  });

  const { field: emailField } = useController({
    name: "email",
    control,
  });

  const validDomain = useValidateDomain(emailField.value);

  const emailDomainIsInvalid = React.useMemo(() => {
    if (errors.email) {
      return true;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailIsFullyWritten = emailPattern.test(emailField.value);

    return emailIsFullyWritten && !validDomain;
  }, [emailField.value, errors.email, validDomain]);

  const onSubmit = async (): Promise<void> => {
    // have to keep emailDomainIsInvalid check here because it's not a form error
    // its not a form error because it uses a hook which isn't easily added to form errors
    if (emailDomainIsInvalid) {
      return;
    }

    const requestData: DoverUser = {
      firstName: firstNameField.value,
      lastName: lastNameField.value,
      email: emailField.value,
    };

    // create new ProUser
    const newProUser: DoverUser = await createProUser(requestData).unwrap();
    handleClose(newProUser);
  };

  const EmailHelperText = React.useMemo(() => {
    if (emailDomainIsInvalid) {
      return <HelperText isError text="Email must be valid and domain must match one of your company's domains." />;
    }
    return <HelperText text="Please enter their work email that will be associated with Dover" />;
  }, [emailDomainIsInvalid]);

  return (
    <DoverModal title="Add User" open={open} onClose={(): void => handleClose()} maxWidth="xs">
      <FormProvider {...formMethods}>
        <BodySmall>First name</BodySmall>
        <Spacer height="16px" />
        <StyledTextField
          disabled={isLoading}
          value={firstNameField.value}
          onChange={firstNameField.onChange}
          variant="outlined"
          fullWidth
          required
          placeholder="First Name"
          autoFocus
          helperText={errors.firstName && <HelperTextWithBox text="You must enter a first name." isError />}
          error={!!errors.firstName}
        />
        <Spacer height="24px" />
        <BodySmall>Last name</BodySmall>
        <Spacer height="16px" />
        <StyledTextField
          disabled={isLoading}
          value={lastNameField.value}
          onChange={lastNameField.onChange}
          variant="outlined"
          fullWidth
          required
          placeholder="Last Name"
          helperText={errors.lastName && <HelperTextWithBox text="You must enter a last name." isError />}
          error={!!errors.lastName}
        />
        <Spacer height="24px" />
        <BodySmall>Email</BodySmall>
        <Spacer height="16px" />
        <StyledTextField
          disabled={isLoading}
          onChange={emailField.onChange}
          value={emailField.value}
          variant="outlined"
          fullWidth
          required
          placeholder="Email"
          helperText={<Box marginTop="5px">{EmailHelperText}</Box>}
          error={emailDomainIsInvalid}
        />
        <Spacer height="16px" />
        {isError && <HelperText isError text="Failed to create new user, please try again" />}

        <Spacer height="16px" />
        <Box display="flex" justifyContent="flex-end">
          <GrayButton
            onClick={(): void => {
              handleClose();
            }}
          >
            <BodySmall>Cancel</BodySmall>
          </GrayButton>
          <Spacer width="8px" />
          <PrimaryButton onClick={handleSubmit(onSubmit)} disabled={isLoading}>
            <BodySmall>{isLoading ? "Creating..." : "Create"}</BodySmall>
          </PrimaryButton>
        </Box>
      </FormProvider>
    </DoverModal>
  );
};

export default AddProUserModal;
