/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldOfStudyTier2,
    FieldOfStudyTier2FromJSON,
    FieldOfStudyTier2FromJSONTyped,
    FieldOfStudyTier2ToJSON,
} from './';

/**
 * 
 * @export
 * @interface FieldOfStudyTier1
 */
export interface FieldOfStudyTier1 {
    /**
     * 
     * @type {string}
     * @memberof FieldOfStudyTier1
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FieldOfStudyTier1
     */
    name: string;
    /**
     * 
     * @type {Array<FieldOfStudyTier2>}
     * @memberof FieldOfStudyTier1
     */
    tier2Fields: Array<FieldOfStudyTier2>;
}

export function FieldOfStudyTier1FromJSON(json: any): FieldOfStudyTier1 {
    return FieldOfStudyTier1FromJSONTyped(json, false);
}

export function FieldOfStudyTier1FromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldOfStudyTier1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'tier2Fields': ((json['tier2_fields'] as Array<any>).map(FieldOfStudyTier2FromJSON)),
    };
}

export function FieldOfStudyTier1ToJSON(value?: FieldOfStudyTier1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'tier2_fields': ((value.tier2Fields as Array<any>).map(FieldOfStudyTier2ToJSON)),
    };
}


