import { EntityAdapter, EntityState, SerializedError } from "@reduxjs/toolkit";
import { QueryStatus } from "@reduxjs/toolkit/dist/query/core/apiState";

import { DjangoListResponseType } from "types";

export interface DoverApiError {
  serializedError: SerializedError;
  userFacingMessage?: string;
}

export function isDoverApiError(object: any): object is DoverApiError {
  return object && "serializedError" in object;
}

export interface UseQueryResult<T> {
  // Base query state
  originalArgs?: unknown; // Arguments passed to the query
  data?: T; // The latest returned result regardless of hook arg, if present
  error?: unknown; // Error result if present
  requestId?: string; // A string generated by RTK Query
  endpointName?: string; // The name of the given endpoint for the query

  // Derived request status booleans
  isUninitialized: boolean; // Query has not started yet.
  isLoading: boolean; // Query is currently loading for the first time. No data yet.
  isFetching: boolean; // Query is currently fetching, but might have data from an earlier request.
  isSuccess: boolean; // Query has data from a successful load.
  isError: boolean; // Query is currently in an "error" state.
}

export interface UseMutationResult<T> {
  // Base query state
  data?: T; // The latest returned result regardless of hook arg, if present
  error?: unknown; // Error result if present

  // Derived request status booleans
  isUninitialized: boolean; // Mutation has not started yet.
  isLoading: boolean; // Mutation is currently loading for the first time. No data yet.
  isSuccess: boolean; // Mutation has data from a successful load.
  isError: boolean; // Mutation is currently in an "error" state.
}

export interface QuerySelectResult<T> {
  // Base query state
  data?: T; // The latest returned result regardless of hook arg, if present
  endpointName?: string; // The name of the given endpoint for the query
  startedTimeStamp?: number;
  fulfilledTimeStamp?: number;
  originalArgs?: unknown; // Arguments passed to the query
  requestId?: string; // A string generated by RTK Query
  error?: unknown; // Error result if present
  status: QueryStatus;

  // Derived request status booleans
  isUninitialized: boolean; // Query has not started yet.
  isLoading: boolean; // Query is currently loading for the first time. No data yet.
  isSuccess: boolean; // Query has data from a successful load.
  isError: boolean; // Query is currently in an "error" state.
}

export interface DjangoListResponseAdapterType<EntityType> {
  count: number;
  next?: string | null;
  previous?: string | null;
  results: EntityState<EntityType>;
}

export function convertDjangoListResponse<T>(
  adapter: EntityAdapter<T>,
  response: DjangoListResponseType<T>
): DjangoListResponseAdapterType<T> {
  return {
    count: response.count,
    next: response.next,
    previous: response.previous,
    results: adapter.addMany(adapter.getInitialState(), response.results),
  };
}
