/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneratedJDQualifications,
    GeneratedJDQualificationsFromJSON,
    GeneratedJDQualificationsFromJSONTyped,
    GeneratedJDQualificationsToJSON,
    GeneratedJDSection,
    GeneratedJDSectionFromJSON,
    GeneratedJDSectionFromJSONTyped,
    GeneratedJDSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeneratedJobDescription
 */
export interface GeneratedJobDescription {
    /**
     * 
     * @type {GeneratedJDSection}
     * @memberof GeneratedJobDescription
     */
    aboutTheRole: GeneratedJDSection | null;
    /**
     * 
     * @type {GeneratedJDSection}
     * @memberof GeneratedJobDescription
     */
    jobMandates: GeneratedJDSection | null;
    /**
     * 
     * @type {GeneratedJDQualifications}
     * @memberof GeneratedJobDescription
     */
    qualifications: GeneratedJDQualifications | null;
    /**
     * 
     * @type {GeneratedJDSection}
     * @memberof GeneratedJobDescription
     */
    aboutTheCompany: GeneratedJDSection | null;
    /**
     * 
     * @type {GeneratedJDSection}
     * @memberof GeneratedJobDescription
     */
    additionalInformation: GeneratedJDSection | null;
    /**
     * 
     * @type {Array<GeneratedJDSection>}
     * @memberof GeneratedJobDescription
     */
    aiGenerated: Array<GeneratedJDSection> | null;
}

export function GeneratedJobDescriptionFromJSON(json: any): GeneratedJobDescription {
    return GeneratedJobDescriptionFromJSONTyped(json, false);
}

export function GeneratedJobDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratedJobDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aboutTheRole': GeneratedJDSectionFromJSON(json['about_the_role']),
        'jobMandates': GeneratedJDSectionFromJSON(json['job_mandates']),
        'qualifications': GeneratedJDQualificationsFromJSON(json['qualifications']),
        'aboutTheCompany': GeneratedJDSectionFromJSON(json['about_the_company']),
        'additionalInformation': GeneratedJDSectionFromJSON(json['additional_information']),
        'aiGenerated': (json['ai_generated'] === null ? null : (json['ai_generated'] as Array<any>).map(GeneratedJDSectionFromJSON)),
    };
}

export function GeneratedJobDescriptionToJSON(value?: GeneratedJobDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'about_the_role': GeneratedJDSectionToJSON(value.aboutTheRole),
        'job_mandates': GeneratedJDSectionToJSON(value.jobMandates),
        'qualifications': GeneratedJDQualificationsToJSON(value.qualifications),
        'about_the_company': GeneratedJDSectionToJSON(value.aboutTheCompany),
        'additional_information': GeneratedJDSectionToJSON(value.additionalInformation),
        'ai_generated': (value.aiGenerated === null ? null : (value.aiGenerated as Array<any>).map(GeneratedJDSectionToJSON)),
    };
}


