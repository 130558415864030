import { Button } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import React, { ReactElement, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BaseTypeProps, Body, BodySmall, Heading } from "components/library/typography";
import { SetupGuideStep } from "components/SetupGuide";
import { colors } from "styles/theme";

export const GrayBody = (props: BaseTypeProps & { children: ReactNode }): React.ReactElement => {
  return (
    <>
      <Box display={{ xs: "none", sm: "block" }}>
        <Body color={colors.grayscale.gray600} {...props} />
      </Box>
      <Box display={{ xs: "block", sm: "none" }}>
        <BodySmall color={colors.grayscale.gray600} {...props} />
      </Box>
    </>
  );
};

/* -----------------------------------------------------------------------------
 * CallToAction
 * -------------------------------------------------------------------------- */

interface CTAButtonProps {
  children: ReactNode;
  path?: string;
  onPress?: () => void;
}

export const CTAButton = ({ children, path, onPress }: CTAButtonProps): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <Button
      variant="primaryFilled"
      onPress={(): void => {
        if (onPress) {
          onPress();
        } else if (path) {
          navigate(path);
        }
      }}
      style={{ width: "fit-content" }}
    >
      {children}
    </Button>
  );
};

/* -----------------------------------------------------------------------------
 * SetupGuideCTA
 * -------------------------------------------------------------------------- */

interface SetupGuideCTAProps {
  step?: SetupGuideStep;
  onDismiss: () => void;
}

export const SetupGuideCTA = ({ step, onDismiss }: SetupGuideCTAProps): ReactElement => {
  // We want the dismissal button to appear as if it's loading until the component is unmounted, which occurs after the patchClientOnboarding mutation resolves.
  const [isUpdatingClientOnboarding, setIsUpdatingClientOnboarding] = useState(false);

  if (!step) {
    return (
      <Stack flex="1 1 auto" justifyContent="center" alignItems="center" spacing={1}>
        <Heading>&#127881;</Heading>
        <GrayBody>{"You're all set up!"}</GrayBody>
        <Button
          variant="primaryFilled"
          onPress={(): void => {
            setIsUpdatingClientOnboarding(true);
            onDismiss();
          }}
          style={{ width: "fit-content" }}
          isLoading={isUpdatingClientOnboarding}
        >
          Dismiss setup guide
        </Button>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width="100%">
      <Stack
        spacing={2}
        minWidth={"50%"}
        maxWidth={step.ctaMaxWidth ? step.ctaMaxWidth : step.graphic ? "50%" : "100%"}
      >
        {step.content}
        {step.ctaButton && step.ctaButton}
      </Stack>
      {step.graphic && step.graphic}
    </Stack>
  );
};
