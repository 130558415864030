/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SpecificYoeByTitle,
    SpecificYoeByTitleFromJSON,
    SpecificYoeByTitleFromJSONTyped,
    SpecificYoeByTitleToJSON,
} from './';

/**
 * 
 * @export
 * @interface SpecificYoeByT2
 */
export interface SpecificYoeByT2 {
    /**
     * 
     * @type {string}
     * @memberof SpecificYoeByT2
     */
    field: string;
    /**
     * 
     * @type {Array<SpecificYoeByTitle>}
     * @memberof SpecificYoeByT2
     */
    value: Array<SpecificYoeByTitle>;
    /**
     * 
     * @type {boolean}
     * @memberof SpecificYoeByT2
     */
    required?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SpecificYoeByT2
     */
    weight?: number | null;
}

export function SpecificYoeByT2FromJSON(json: any): SpecificYoeByT2 {
    return SpecificYoeByT2FromJSONTyped(json, false);
}

export function SpecificYoeByT2FromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecificYoeByT2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'value': ((json['value'] as Array<any>).map(SpecificYoeByTitleFromJSON)),
        'required': !exists(json, 'required') ? undefined : json['required'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
    };
}

export function SpecificYoeByT2ToJSON(value?: SpecificYoeByT2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'value': ((value.value as Array<any>).map(SpecificYoeByTitleToJSON)),
        'required': value.required,
        'weight': value.weight,
    };
}


