import { Box, Stack } from "@mui/material";
import React from "react";

import { ReactComponent as WandIcon } from "assets/icons/green-wand.svg";
import { Button, ButtonVariant } from "components/library/Button";
import Drawer from "components/library/Drawer";
import RawEditor from "components/library/TipTap/RawEditor";
import { Heading, Body, BodySmall } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { colors } from "styles/theme";

interface DrawerProps {
  initialNotes?: string;
  drawerOpen: boolean;
  onClose: () => void;
  onSubmit: (v: string) => void;
  isSubmitting: boolean;
}

const DrawerHeader = (): React.ReactElement => (
  <>
    <Heading>AI notes formatter</Heading>
    <Stack
      sx={{ backgroundColor: colors.decorative.mintGreen, borderRadius: "3px", padding: "10px" }}
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <WandIcon className="svg-fill" color={colors.success.base} />
      <Body color={colors.success.base}>
        {"Use Dover's AI to clean up and align your notes to your individual interview questions."}
      </Body>
    </Stack>
  </>
);

const DrawerContent = ({ onClose, initialNotes, onSubmit, isSubmitting }: DrawerProps): React.ReactElement => {
  const [roughNotes, setRoughNotes] = React.useState<string>(initialNotes || "");

  return (
    <Stack spacing={2} p={2} sx={{ height: "100%" }}>
      <DrawerHeader />

      {/* Editor */}
      <DoverLoadingOverlay
        text="Formatting your notes..."
        active={isSubmitting}
        height="calc(100vh - 250px)"
        overlay={true}
      >
        <Box sx={{ height: "calc(100vh - 250px)" }} display="flex">
          {isSubmitting ? (
            <Box border={`1px solid ${colors.grayscale.gray200}`} borderRadius="4px" height="100%" width="100%" />
          ) : (
            <RawEditor
              initialContent={initialNotes || ""}
              onContentChange={setRoughNotes}
              placeholder="Enter your notes:"
            />
          )}
        </Box>
      </DoverLoadingOverlay>

      {/* Action Buttons / Footer */}
      <Stack alignItems="flex-end" width="100%" spacing={1}>
        <Box
          sx={{ backgroundColor: colors.warning.light, borderRadius: "3px", padding: "10px" }}
          display="inline-block"
        >
          <BodySmall>Note: Pressing submit will overwrite previous responses.</BodySmall>
        </Box>
        <Stack direction="row" spacing={1} width="100%" justifyContent="flex-end">
          <Button variant={ButtonVariant.Secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={(): void => onSubmit(roughNotes)} disabled={isSubmitting}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

const AINoteFormatterDrawer = (props: DrawerProps): React.ReactElement => (
  <Drawer
    anchor="right"
    open={props.drawerOpen}
    onClose={props.onClose}
    sx={{ position: "relative", overflow: "auto" }}
    shouldToggleIntercom
  >
    <DrawerContent {...props} />
  </Drawer>
);

export default AINoteFormatterDrawer;
