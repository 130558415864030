import { Stack, FormControlLabel, Radio } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Color from "color";
import { format, isAfter } from "date-fns";
import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import DashingMailIcon from "assets/icons/dashing-mail.svg";
import MailManualReviewIcon from "assets/icons/mail-manual-review.svg";
import { ReactComponent as PauseIcon } from "assets/icons/pause-outlined.svg";
import { ReactComponent as UserCheckSVG } from "assets/icons/user-check.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, Caption, Overline, Subtitle2 } from "components/library/typography";
import { useGetJobSetupQuery } from "services/doverapi/endpoints/job/endpoints";
import { backgrounds, colors } from "styles/theme";
import { isValidDate } from "utils/dates";

const StyledDashingMailReactSVG = styled(ReactSVG)`
  display: inline;
  svg {
    height: 36px;
    width: 36px;
    path {
      fill: ${colors.grayscale.gray600};
    }
    line {
      stroke: ${colors.grayscale.gray600};
    }
  }
`;

const StyledManualReviewMailReactSVG = styled(ReactSVG)`
  svg {
    height: 30px;
    width: 30px;
  }
  margin-top: 2px;
  margin-bottom: 4px;
`;

const StyledConfigCard = styled(Stack)<{ isSelected: boolean }>`
  box-sizing: border-box;
  position: relative;

  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 12px;
  isolation: isolate;

  width: 260px;

  background: ${({ isSelected }): string => (isSelected ? `${colors.primary.light}` : "none")};

  border: ${({ isSelected }): string =>
    isSelected ? `1px solid ${colors.primary.base}` : `1px solid ${colors.grayscale.gray300}`};
  border-radius: 5px;

  flex: none;
  order: 0;
  flex-grow: 1;
  &:hover {
    cursor: pointer;
  }
`;

const StyledRadio = styled(Radio)`
  &.Mui-checked {
    color: ${colors.primary.base};
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.darkBlue)
      .alpha(0.3)
      .toString()};
  }
`;

export const SourcingAutopilotContent = ({
  selectedAutopilotOption,
  setSelectedAutopilotOption,
  showPauseButton,
  jobId,
  onPause,
}: {
  selectedAutopilotOption: boolean | undefined;
  setSelectedAutopilotOption: (v: boolean) => void;
  showPauseButton?: boolean;
  jobId?: string;
  onPause?: () => void;
}): React.ReactElement => {
  const { data: job } = useGetJobSetupQuery(jobId ?? skipToken);
  const hasSnoozedUntilDateInFuture =
    job?.snoozedUntil && isValidDate(job.snoozedUntil) && isAfter(job.snoozedUntil, new Date());

  // eslint-disable-next-line react/no-unstable-nested-components
  const InterestedReplyInfo = ({
    numInterestedCandidates,
  }: {
    numInterestedCandidates: number;
  }): React.ReactElement => {
    const replyText = numInterestedCandidates === 1 ? "reply" : "replies";
    return (
      <>
        <hr
          style={{
            background: colors.grayscale.gray300,
            height: "0px",
            width: "10%",
          }}
        />
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Overline>Receive on average</Overline>
          <Stack direction="row" spacing={0.5}>
            {[...Array(numInterestedCandidates)].map(() => (
              <UserCheckSVG />
            ))}
          </Stack>
          <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
            <Subtitle2>
              {numInterestedCandidates} interested {replyText}/wk
            </Subtitle2>
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <Stack spacing={2} justifyContent="center" alignItems="center">
      <Stack direction="row" spacing={2}>
        <StyledConfigCard
          isSelected={selectedAutopilotOption === true}
          onClick={(): void => {
            setSelectedAutopilotOption(true);
          }}
        >
          <Stack>
            <StyledDashingMailReactSVG src={DashingMailIcon} />
          </Stack>
          <Stack>
            <Subtitle2>Autosend On</Subtitle2>
          </Stack>
          <Stack textAlign="center">
            <BodySmall>
              I want Dover to <b>automatically</b> send outreach emails to 100+ qualified candidates/wk
            </BodySmall>
          </Stack>
          <Stack direction="row" sx={{ top: "10px", right: "0px", position: "absolute" }}>
            <FormControlLabel
              value={true}
              control={<StyledRadio checked={selectedAutopilotOption === true} />}
              label=""
            />
          </Stack>
          <InterestedReplyInfo numInterestedCandidates={5} />
        </StyledConfigCard>
        <StyledConfigCard
          isSelected={selectedAutopilotOption === false}
          onClick={(): void => {
            setSelectedAutopilotOption(false);
          }}
        >
          <Stack>
            <StyledManualReviewMailReactSVG src={MailManualReviewIcon} />
          </Stack>
          <Stack>
            <Subtitle2>Autosend Off</Subtitle2>
          </Stack>
          <Stack textAlign="center" spacing={0.5}>
            <BodySmall>I want to review and manually send outreach to candidates</BodySmall>
            <Caption color={colors.grayscale.gray600}>You can always turn on Autosend later</Caption>
          </Stack>
          <Stack direction="row" sx={{ top: "10px", right: "0px", position: "absolute" }}>
            <FormControlLabel
              value={false}
              control={<StyledRadio checked={selectedAutopilotOption === false} />}
              label=""
            />
          </Stack>

          <InterestedReplyInfo numInterestedCandidates={1} />
        </StyledConfigCard>
      </Stack>
      {showPauseButton && hasSnoozedUntilDateInFuture && (
        <Stack spacing={1} alignItems="center" padding={2} style={{ background: colors.grayscale.gray100 }}>
          <Body>Autopilot paused until {format(job?.snoozedUntil!, "M/d/yy")}</Body>
          <Button variant={ButtonVariant.Secondary} onClick={onPause} width="fit-content">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <PauseIcon />
              <BodySmall>{hasSnoozedUntilDateInFuture ? "Unpause" : "Pause"}</BodySmall>
            </Stack>
          </Button>
        </Stack>
      )}
      {showPauseButton && !hasSnoozedUntilDateInFuture && (
        <Button variant={ButtonVariant.Secondary} onClick={onPause}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <PauseIcon />
            <BodySmall>{"Pause"}</BodySmall>
          </Stack>
        </Button>
      )}
    </Stack>
  );
};
