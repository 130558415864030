import { Box } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

import DebriefDurationFields from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/DebriefDurationFields";

export const EditDebriefDuration = ({
  fieldPrefix,
  required,
}: {
  fieldPrefix: string;
  required: boolean;
}): React.ReactElement => {
  return (
    <Box width="200px">
      <Field
        name={fieldPrefix + ".debriefDuration"}
        parse={(value): any => value}
        validate={(value): boolean => {
          return required && !value;
        }}
      >
        {(props): React.ReactElement => {
          return <DebriefDurationFields {...props} />;
        }}
      </Field>
    </Box>
  );
};
