import { Stack, Box } from "@mui/material";
import { TextField } from "mui-rff";
import React, { useState, useEffect } from "react";
import { Form as RFForm, FormRenderProps } from "react-final-form";
import LoadingOverlay from "react-loading-overlay";
import styled from "styled-components";

import { Button, ButtonVariant } from "components/library/Button";
import { Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { InterviewerOption } from "components/NextInterviewer/useInterviewerOptions";
import { useListClientDomainsQuery } from "services/doverapi/endpoints/company-setup/endpoints";
import { ClientInterviewer } from "services/openapi/models";

const ModalForm = styled(RFForm)`
  width: 100%;
  margin-top: 24px;
`;

export type CreateInterviewerFuncArgs = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  linkedinPublicId?: string;
};

interface InterviewerCreateModalProps {
  createInterviewer: ({
    firstName,
    lastName,
    email,
    phoneNumber,
    linkedinPublicId,
  }: CreateInterviewerFuncArgs) => Promise<void> | void;
  creating: boolean;
  validateEmail: (email: string) => boolean;
  interviewers?: (ClientInterviewer | InterviewerOption)[];
  showModal: boolean;
  onCloseModal?: Function;
  hiringManagerEmail?: string;
}

const InterviewerCreateModal = ({
  createInterviewer,
  creating,
  validateEmail,
  interviewers,
  showModal,
  onCloseModal = (): void => {},
  hiringManagerEmail,
}: InterviewerCreateModalProps): React.ReactElement => {
  const { data: clientDomains } = useListClientDomainsQuery({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted && !creating) {
      onCloseModal();
    }
  }, [creating, onCloseModal, submitted]);

  const run = (values: any): void => {
    setSubmitted(true);
    createInterviewer(values);
  };

  const validateDomain = (email: string): boolean => {
    if (!email || !hiringManagerEmail || !clientDomains) {
      return true;
    }

    const domainIndex = email.lastIndexOf("@") + 1;
    const hiringManagerDomainIndex = hiringManagerEmail.lastIndexOf("@") + 1;

    if (domainIndex === 0 || hiringManagerDomainIndex === 0) {
      return false;
    }
    const inputDomain = email.slice(domainIndex);

    return clientDomains.some(clientDomain => clientDomain.domain === inputDomain);
  };

  const validate = (values: any): any => {
    let errors = {};

    if (!values.firstName) {
      errors = { ...errors, firstName: "Required" };
    }

    if (!values.lastName) {
      errors = { ...errors, lastName: "Required" };
    }

    if (!validateEmail(values.email)) {
      errors = { ...errors, email: "Cannot use sender as interviewer" };
    } else if (!validateDomain(values.email)) {
      errors = { ...errors, email: "Domain must match one of your company's domains" };
    } else if (interviewers && interviewers.find(i => i.email === values.email)) {
      errors = { ...errors, email: "Interviewer already exists" };
    }

    return errors;
  };

  return (
    <LoadingOverlay active={creating} spinner>
      <div>
        <CustomModal open={showModal} onClose={onCloseModal} maxWidth="sm" title="Add Interviewer">
          <ModalForm onSubmit={run} validate={validate}>
            {({ handleSubmit }: { handleSubmit: FormRenderProps["handleSubmit"] }): React.ReactElement => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Subtitle2>First Name</Subtitle2>
                    <StyledTextField
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label=""
                      autoFocus
                      placeholder="First name"
                    />
                    <Subtitle2>Last Name</Subtitle2>
                    <StyledTextField
                      name="lastName"
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label=""
                      placeholder="Last name"
                    />
                    <Subtitle2>Email</Subtitle2>
                    <StyledTextField
                      name="email"
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label=""
                      placeholder="Email address"
                    />
                  </Stack>
                  <Box textAlign="right">
                    <Button variant={ButtonVariant.Primary}>Save</Button>
                  </Box>
                </Stack>
              </form>
            )}
          </ModalForm>
        </CustomModal>
      </div>
    </LoadingOverlay>
  );
};

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 40px;
    border: none;
  }
`;

export default InterviewerCreateModal;
