/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateActionEmailArgs
 */
export interface CandidateActionEmailArgs {
    /**
     * 
     * @type {string}
     * @memberof CandidateActionEmailArgs
     */
    emailAlias: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateActionEmailArgs
     */
    ccEmails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateActionEmailArgs
     */
    bccEmails: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CandidateActionEmailArgs
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateActionEmailArgs
     */
    body: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateActionEmailArgs
     */
    toEmails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CandidateActionEmailArgs
     */
    threadId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CandidateActionEmailArgs
     */
    sendAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CandidateActionEmailArgs
     */
    messageKey?: string | null;
}

export function CandidateActionEmailArgsFromJSON(json: any): CandidateActionEmailArgs {
    return CandidateActionEmailArgsFromJSONTyped(json, false);
}

export function CandidateActionEmailArgsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateActionEmailArgs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAlias': json['email_alias'],
        'ccEmails': json['cc_emails'],
        'bccEmails': json['bcc_emails'],
        'subject': json['subject'],
        'body': json['body'],
        'toEmails': !exists(json, 'to_emails') ? undefined : json['to_emails'],
        'threadId': !exists(json, 'thread_id') ? undefined : json['thread_id'],
        'sendAt': (new Date(json['send_at'])),
        'messageKey': !exists(json, 'message_key') ? undefined : json['message_key'],
    };
}

export function CandidateActionEmailArgsToJSON(value?: CandidateActionEmailArgs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_alias': value.emailAlias,
        'cc_emails': value.ccEmails,
        'bcc_emails': value.bccEmails,
        'subject': value.subject,
        'body': value.body,
        'to_emails': value.toEmails,
        'thread_id': value.threadId,
        'send_at': (value.sendAt.toISOString()),
        'message_key': value.messageKey,
    };
}


