/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobOutboundStats
 */
export interface JobOutboundStats {
    /**
     * 
     * @type {number}
     * @memberof JobOutboundStats
     */
    numClientReview: number;
    /**
     * 
     * @type {number}
     * @memberof JobOutboundStats
     */
    percentClientReview: number | null;
}

export function JobOutboundStatsFromJSON(json: any): JobOutboundStats {
    return JobOutboundStatsFromJSONTyped(json, false);
}

export function JobOutboundStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobOutboundStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numClientReview': json['num_client_review'],
        'percentClientReview': json['percent_client_review'],
    };
}

export function JobOutboundStatsToJSON(value?: JobOutboundStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'num_client_review': value.numClientReview,
        'percent_client_review': value.percentClientReview,
    };
}


