/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateSourcePricingTier,
    CandidateSourcePricingTierFromJSON,
    CandidateSourcePricingTierFromJSONTyped,
    CandidateSourcePricingTierToJSON,
    JobCandidateSourceSettingJobCandidateSource,
    JobCandidateSourceSettingJobCandidateSourceFromJSON,
    JobCandidateSourceSettingJobCandidateSourceFromJSONTyped,
    JobCandidateSourceSettingJobCandidateSourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobCandidateSourceSetting
 */
export interface JobCandidateSourceSetting {
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    readonly job?: string;
    /**
     * 
     * @type {JobCandidateSourceSettingJobCandidateSource}
     * @memberof JobCandidateSourceSetting
     */
    candidateSource?: JobCandidateSourceSettingJobCandidateSource;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    readonly state?: JobCandidateSourceSettingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    desiredState: JobCandidateSourceSettingDesiredStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof JobCandidateSourceSetting
     */
    desiredStateLastMadeActiveOn?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    externalJobIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    externalJobOrigin?: JobCandidateSourceSettingExternalJobOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    readonly inboundApplicationUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof JobCandidateSourceSetting
     */
    readonly percentBudgetRemaining?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof JobCandidateSourceSetting
     */
    readonly atsInboundRejectionEnabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    deactivationReason?: JobCandidateSourceSettingDeactivationReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    deactivationReasonNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobCandidateSourceSetting
     */
    additionalInfo?: string | null;
    /**
     * 
     * @type {CandidateSourcePricingTier}
     * @memberof JobCandidateSourceSetting
     */
    pricingTier?: CandidateSourcePricingTier;
    /**
     * 
     * @type {Date}
     * @memberof JobCandidateSourceSetting
     */
    readonly currentPeriodStart?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof JobCandidateSourceSetting
     */
    readonly currentPeriodEnd?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof JobCandidateSourceSetting
     */
    readonly shouldAutoRenew?: boolean | null;
}

/**
* @export
* @enum {string}
*/
export enum JobCandidateSourceSettingStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}/**
* @export
* @enum {string}
*/
export enum JobCandidateSourceSettingDesiredStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE'
}/**
* @export
* @enum {string}
*/
export enum JobCandidateSourceSettingExternalJobOriginEnum {
    Dover = 'DOVER',
    Client = 'CLIENT'
}/**
* @export
* @enum {string}
*/
export enum JobCandidateSourceSettingDeactivationReasonEnum {
    NotEnoughApplicants = 'NOT_ENOUGH_APPLICANTS',
    TooExpensive = 'TOO_EXPENSIVE',
    LowQualityOfApplicants = 'LOW_QUALITY_OF_APPLICANTS',
    AlreadyMadeHire = 'ALREADY_MADE_HIRE',
    Other = 'OTHER'
}

export function JobCandidateSourceSettingFromJSON(json: any): JobCandidateSourceSetting {
    return JobCandidateSourceSettingFromJSONTyped(json, false);
}

export function JobCandidateSourceSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobCandidateSourceSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'job': !exists(json, 'job') ? undefined : json['job'],
        'candidateSource': !exists(json, 'candidate_source') ? undefined : JobCandidateSourceSettingJobCandidateSourceFromJSON(json['candidate_source']),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'desiredState': json['desired_state'],
        'desiredStateLastMadeActiveOn': !exists(json, 'desired_state_last_made_active_on') ? undefined : (json['desired_state_last_made_active_on'] === null ? null : new Date(json['desired_state_last_made_active_on'])),
        'externalJobIdentifier': !exists(json, 'external_job_identifier') ? undefined : json['external_job_identifier'],
        'externalJobOrigin': !exists(json, 'external_job_origin') ? undefined : json['external_job_origin'],
        'inboundApplicationUrl': !exists(json, 'inbound_application_url') ? undefined : json['inbound_application_url'],
        'percentBudgetRemaining': !exists(json, 'percent_budget_remaining') ? undefined : json['percent_budget_remaining'],
        'atsInboundRejectionEnabled': !exists(json, 'ats_inbound_rejection_enabled') ? undefined : json['ats_inbound_rejection_enabled'],
        'deactivationReason': !exists(json, 'deactivation_reason') ? undefined : json['deactivation_reason'],
        'deactivationReasonNotes': !exists(json, 'deactivation_reason_notes') ? undefined : json['deactivation_reason_notes'],
        'additionalInfo': !exists(json, 'additional_info') ? undefined : json['additional_info'],
        'pricingTier': !exists(json, 'pricing_tier') ? undefined : CandidateSourcePricingTierFromJSON(json['pricing_tier']),
        'currentPeriodStart': !exists(json, 'current_period_start') ? undefined : (json['current_period_start'] === null ? null : new Date(json['current_period_start'])),
        'currentPeriodEnd': !exists(json, 'current_period_end') ? undefined : (json['current_period_end'] === null ? null : new Date(json['current_period_end'])),
        'shouldAutoRenew': !exists(json, 'should_auto_renew') ? undefined : json['should_auto_renew'],
    };
}

export function JobCandidateSourceSettingToJSON(value?: JobCandidateSourceSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_source': JobCandidateSourceSettingJobCandidateSourceToJSON(value.candidateSource),
        'desired_state': value.desiredState,
        'desired_state_last_made_active_on': value.desiredStateLastMadeActiveOn === undefined ? undefined : (value.desiredStateLastMadeActiveOn === null ? null : value.desiredStateLastMadeActiveOn.toISOString()),
        'external_job_identifier': value.externalJobIdentifier,
        'external_job_origin': value.externalJobOrigin,
        'deactivation_reason': value.deactivationReason,
        'deactivation_reason_notes': value.deactivationReasonNotes,
        'additional_info': value.additionalInfo,
        'pricing_tier': CandidateSourcePricingTierToJSON(value.pricingTier),
    };
}


