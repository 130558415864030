import Container from "@mui/material/Container";
import styled from "styled-components";

import { colors } from "views/interview/common/styles";

interface ContainerStyleProps {
  compact?: boolean;
}

export const InterviewRubricContainerWrapper = styled.div<ContainerStyleProps>`
  width: 100%;
  padding: ${(props: ContainerStyleProps): any => `${props.compact ? "0px 16px" : "0px 64px"};`};
`;

export const InterviewRubricContainer = styled(Container)<ContainerStyleProps>`
  width: 100%;
  max-width: 700px;
  padding: 0;
`;

export const HeaderH0 = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 129.3%;
`;

export const HeaderH1 = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 129.3%;
`;

export const SocialWrapper = styled.div`
  font-size: 20px;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 -8px;
  align-items: center;

  > * {
    margin: 0 8px;
  }
`;

export const VerticalDivider = styled.div`
  height: 16px;
  margin: 6px 0;
  border: 1px solid ${colors.lightGray};
  background: ${colors.lightGray};
  width: 1px;
`;

export const LinkItem = styled.div`
  flex: 0 1 auto;
`;

export const StyledInfoBox = styled.div`
  background: ${colors.infoBoxBlue};
  border: 1px solid ${colors.lightGray};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  align-tems: center;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const SectionTitle = styled.div`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 129.3%;
`;
