/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobFAQ
 */
export interface JobFAQ {
    /**
     * 
     * @type {string}
     * @memberof JobFAQ
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobFAQ
     */
    isDefault?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JobFAQ
     */
    job: string;
    /**
     * 
     * @type {string}
     * @memberof JobFAQ
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof JobFAQ
     */
    answer?: string | null;
}

export function JobFAQFromJSON(json: any): JobFAQ {
    return JobFAQFromJSONTyped(json, false);
}

export function JobFAQFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFAQ {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
        'job': json['job'],
        'question': json['question'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
    };
}

export function JobFAQToJSON(value?: JobFAQ | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'is_default': value.isDefault,
        'job': value.job,
        'question': value.question,
        'answer': value.answer,
    };
}


