/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeactivationStats
 */
export interface DeactivationStats {
    /**
     * 
     * @type {boolean}
     * @memberof DeactivationStats
     */
    readonly jobKickedOff?: boolean;
}

export function DeactivationStatsFromJSON(json: any): DeactivationStats {
    return DeactivationStatsFromJSONTyped(json, false);
}

export function DeactivationStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeactivationStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobKickedOff': !exists(json, 'job_kicked_off') ? undefined : json['job_kicked_off'],
    };
}

export function DeactivationStatsToJSON(value?: DeactivationStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


