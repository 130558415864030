/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchIDAndName,
    SearchIDAndNameFromJSON,
    SearchIDAndNameFromJSONTyped,
    SearchIDAndNameToJSON,
} from './';

/**
 * 
 * @export
 * @interface OtherSearchesUsingPersonaResponse
 */
export interface OtherSearchesUsingPersonaResponse {
    /**
     * 
     * @type {Array<SearchIDAndName>}
     * @memberof OtherSearchesUsingPersonaResponse
     */
    searches: Array<SearchIDAndName>;
}

export function OtherSearchesUsingPersonaResponseFromJSON(json: any): OtherSearchesUsingPersonaResponse {
    return OtherSearchesUsingPersonaResponseFromJSONTyped(json, false);
}

export function OtherSearchesUsingPersonaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherSearchesUsingPersonaResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searches': ((json['searches'] as Array<any>).map(SearchIDAndNameFromJSON)),
    };
}

export function OtherSearchesUsingPersonaResponseToJSON(value?: OtherSearchesUsingPersonaResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searches': ((value.searches as Array<any>).map(SearchIDAndNameToJSON)),
    };
}


