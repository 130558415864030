import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { CountMap, useCandidateCountMap } from "hooks/useCandidateCountMap";
import { useListCandidateEmailEventsQuery } from "services/doverapi/endpoints/candidate";
import { ActivityFeedItem, ActorFieldActorEnum } from "services/openapi";
import { convertQuickFiltersToCountList, useGetQuickFilters } from "views/candidates/hooks";
import { QuickFilterEnum } from "views/candidates/types";

export const useGetMostRecentCandidateEmailEventBody = (candidateId: string): string | undefined => {
  const { data: emailEvents } = useListCandidateEmailEventsQuery(candidateId ? candidateId : skipToken);

  const mostRecentCandidateEmail = useMemo((): ActivityFeedItem | undefined => {
    // Email events are sorted by date descending, so the first event is the most recent
    return emailEvents?.find(emailEvent => emailEvent.actor.actor === ActorFieldActorEnum.Candidate);
  }, [emailEvents]);

  return mostRecentCandidateEmail?.emailEventDetails?.cleanEmailText;
};

export const useGetNextUpCounts = (): { isFetching: boolean; counts: CountMap } => {
  const quickFilters = useGetQuickFilters();

  const filters = convertQuickFiltersToCountList(
    [
      QuickFilterEnum.AllNeedsAction,
      QuickFilterEnum.Applicants,
      QuickFilterEnum.RespondedAsInterested,
      QuickFilterEnum.PendingCustomerResponse,
      QuickFilterEnum.CompletedInterview,
      QuickFilterEnum.Mentions,
    ],
    quickFilters
  );

  const { counts, isFetching } = useCandidateCountMap(filters);

  return {
    isFetching,
    counts,
  };
};
