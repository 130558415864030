import { Container, Divider, Stack } from "@mui/material";
import { EntityState } from "@reduxjs/toolkit";
import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { BooleanParam, StringParam, useQueryParams } from "use-query-params";

import { CreateNewSearchModal, jobsSortFunc } from "components/dover/NewSearchModal";
import JobsFilter from "components/dover/SearchesTable/components/JobsFilters";
import StatusFilter from "components/dover/SearchesTable/components/StatusFilter";
import SearchesTable from "components/dover/SearchesTable/SearchesTable";
import { Button, ButtonVariant } from "components/library/Button";
import { TitleLarge } from "components/library/typography";
import PageHelmet from "components/PageHelmet";
import { Role, useHasRole } from "components/RBAC";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";
import { selectFromListJobsQueryResult, useListJobsQuery } from "services/doverapi/endpoints/job";
import { useGetManagedOutboundState } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import { getEntityById } from "services/doverapi/entityAdapterUtils";
import { UseQueryResult } from "services/doverapi/types";
import { DashboardJob, JobFeatureStateEnum } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { colors } from "styles/theme";
import SearchesSplashPage from "views/sourcing/SplashPage";

/* -----------------------------------------------------------------------------
 * FilterPanel
 * -------------------------------------------------------------------------- */

const FilterPanelStack = styled(Stack)`
  padding: 0 24px;
  min-width: 280px;
  max-width: 350px;
  max-height: calc(100vh - 90px);
  overflow-y: auto;
`;

interface FilterPanelProps {
  jobId?: string | null;
}

const FilterPanel = ({ jobId }: FilterPanelProps): ReactElement => {
  return (
    <FilterPanelStack
      spacing={2}
      divider={<Divider orientation="horizontal" flexItem sx={{ borderColor: colors.grayscale.gray300 }} />}
    >
      <JobsFilter activeJobId={jobId ?? ""} />
      <StatusFilter />
    </FilterPanelStack>
  );
};

/* -----------------------------------------------------------------------------
 * PrimaryView
 * -------------------------------------------------------------------------- */

interface SearchButtonProps {
  onClick: () => void;
}

const SearchButton = ({ onClick }: SearchButtonProps): ReactElement => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button variant={ButtonVariant.Secondary} onClick={onClick}>
        + New Search
      </Button>
    </Stack>
  );
};

interface PrimaryViewProps extends SearchButtonProps {
  jobId?: string | null;
}

const PrimaryView = ({ jobId, onClick }: PrimaryViewProps): ReactElement => {
  const {
    user: { email: authedUsersEmail },
  } = useAuth0();
  const { data } = useListJobsQuery(undefined, {
    selectFromResult: (rtkResults: UseQueryResult<EntityState<DashboardJob>>) =>
      selectFromListJobsQueryResult(rtkResults, { authedUserEmail: authedUsersEmail, sortFunc: jobsSortFunc }),
  });
  const selectedJobFromQuery = getEntityById(data, jobId);
  const managedOutboundState = useGetManagedOutboundState({ jobId });
  const outBoundOn = managedOutboundState === JobFeatureStateEnum.Enabled;
  const isFreePlan = useIsFreeCustomer();

  const showNewSearchButton = outBoundOn && !isFreePlan;

  return (
    <Container maxWidth="xl">
      <Stack spacing={3}>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <TitleLarge>{selectedJobFromQuery?.title}</TitleLarge>
        </Stack>
        <SearchesTable />
        {showNewSearchButton ? <SearchButton onClick={onClick} /> : null}
      </Stack>
    </Container>
  );
};

/* -----------------------------------------------------------------------------
 * SourcingView
 * -------------------------------------------------------------------------- */

const SourcingView = (): React.ReactElement => {
  const isAdmin = useHasRole(Role.ADMIN);
  const useLegacySourcingAutopilot = useFeatureFlag(FeatureFlag.LegacySourcingAutopilot);

  const canViewSourcing = isAdmin || useLegacySourcingAutopilot;

  const [query] = useQueryParams({
    jobId: StringParam,
    activeOnly: BooleanParam,
  });

  const [isNewSearchModalOpen, setIsNewSearchModalOpen] = React.useState(false);

  const handleClickNewSearch = React.useCallback(() => {
    setIsNewSearchModalOpen(true);
  }, []);

  const handleCancelNewSearch = React.useCallback(() => {
    setIsNewSearchModalOpen(false);
  }, []);

  if (!canViewSourcing) {
    return <Navigate to="NotFound" />;
  }

  return (
    <>
      <PageHelmet title="Searches" />
      <SearchesSplashPage />
      <Stack paddingTop={2} pb={4} direction="row" justifyContent="space-between">
        <FilterPanel jobId={query.jobId} />
        <PrimaryView jobId={query.jobId} onClick={handleClickNewSearch} />
      </Stack>
      <CreateNewSearchModal
        isModalOpen={isNewSearchModalOpen}
        closeModal={handleCancelNewSearch}
        jobId={query.jobId || undefined}
      />
    </>
  );
};

export default SourcingView;
