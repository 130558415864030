/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationOption,
    LocationOptionFromJSON,
    LocationOptionFromJSONTyped,
    LocationOptionToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobLocation
 */
export interface JobLocation {
    /**
     * 
     * @type {string}
     * @memberof JobLocation
     */
    locationType: JobLocationLocationTypeEnum;
    /**
     * 
     * @type {LocationOption}
     * @memberof JobLocation
     */
    locationOption?: LocationOption;
    /**
     * 
     * @type {string}
     * @memberof JobLocation
     */
    locationOptionId: string;
    /**
     * 
     * @type {string}
     * @memberof JobLocation
     */
    name: string;
}

/**
* @export
* @enum {string}
*/
export enum JobLocationLocationTypeEnum {
    Remote = 'REMOTE',
    InOffice = 'IN_OFFICE',
    Hybrid = 'HYBRID'
}

export function JobLocationFromJSON(json: any): JobLocation {
    return JobLocationFromJSONTyped(json, false);
}

export function JobLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationType': json['location_type'],
        'locationOption': !exists(json, 'location_option') ? undefined : LocationOptionFromJSON(json['location_option']),
        'locationOptionId': json['location_option_id'],
        'name': json['name'],
    };
}

export function JobLocationToJSON(value?: JobLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location_type': value.locationType,
        'location_option': LocationOptionToJSON(value.locationOption),
        'location_option_id': value.locationOptionId,
        'name': value.name,
    };
}


