import { Box } from "@mui/material";
import { isValid } from "date-fns";
import { format } from "date-fns/fp";
import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import RejectedSVG from "assets/icons/rejected.svg";
import { Spacer } from "components/Spacer";
import { ActivityFeedItem, ActivityFeedItemSubtypeEnum } from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { InterviewFeedbackCompletedCard } from "views/CandidateDetail/components/InterviewFeedbackCard";

interface Props {
  interviewEvent: ActivityFeedItem;
}

export const InterviewEventBody = ({ interviewEvent }: Props): React.ReactElement => {
  switch (interviewEvent.subtype) {
    case ActivityFeedItemSubtypeEnum.InterviewScheduled:
      return <ScheduledEventBody interviewEvent={interviewEvent} />;
    case ActivityFeedItemSubtypeEnum.InterviewCanceled:
      return <CanceledEventBody interviewEvent={interviewEvent} />;
    case ActivityFeedItemSubtypeEnum.InterviewNoShow:
      return <NoshowEventBody interviewEvent={interviewEvent} />;
    case ActivityFeedItemSubtypeEnum.InterviewFeedback:
      return <InterviewFeedbackCompletedCard interviewEvent={interviewEvent.interviewEventDetails} />;
    default:
      return <></>;
  }
};

const ScheduledEventBody = ({ interviewEvent }: Props): React.ReactElement => {
  const formatMonth = format("MMM");
  const formatDay = format("d");
  const formatTime = format("h:mmaaa");

  const { interviewEventDetails } = interviewEvent;
  // technically could just falsey check here but lets be safer and check the dates are valid
  const startDate = interviewEventDetails?.start;
  const endDate = interviewEventDetails?.end;
  const startDateIsValid = isValid(interviewEventDetails!.start);
  const endDateIsValid = isValid(interviewEventDetails!.end);

  return (
    <>
      <EventBodyContainer>
        <Box display="flex" padding={1}>
          {startDateIsValid && (
            <DateBox>
              <DateBoxMonth>{formatMonth(startDate as Date).toUpperCase()}</DateBoxMonth>
              <DateBoxDate>{formatDay(startDate as Date)}</DateBoxDate>
            </DateBox>
          )}
          <EventDescription>
            <EventTitle>{interviewEventDetails!.title}</EventTitle>
            {startDateIsValid && endDateIsValid && (
              <EventSubtitle>
                {formatTime(startDate as Date)} - {formatTime(endDate as Date)}
              </EventSubtitle>
            )}
          </EventDescription>
        </Box>
      </EventBodyContainer>
    </>
  );
};

const NoshowEventBody = ({ interviewEvent }: Props): React.ReactElement => {
  const { interviewEventDetails } = interviewEvent;
  if (interviewEventDetails?.title && interviewEventDetails?.start && interviewEventDetails?.end) {
    return <CalendarEventBody interviewEvent={interviewEvent} />;
  }
  return <></>;
};

const CanceledEventBody = ({ interviewEvent }: Props): React.ReactElement => {
  return (
    <>
      <Spacer height={10} />
      <CalendarEventBody interviewEvent={interviewEvent} />
    </>
  );
};

const CalendarEventBody = ({ interviewEvent }: Props): React.ReactElement => {
  const formatMonth = format("MMM");
  const formatDay = format("d");
  const formatTime = format("h:mmaaa");

  const { interviewEventDetails } = interviewEvent;

  // technically could just falsey check here but lets be safer and check the dates are valid
  const startDate = interviewEventDetails?.start;
  const endDate = interviewEventDetails?.end;
  const startDateIsValid = isValid(interviewEventDetails!.start);
  const endDateIsValid = isValid(interviewEventDetails!.end);

  return (
    <EventBodyContainer>
      <Box display="flex" padding={1}>
        {startDateIsValid && (
          <DateBox>
            <DateBoxMonth>
              <Box bgcolor={colors.critical.base}>{formatMonth(startDate as Date).toUpperCase()}</Box>
            </DateBoxMonth>
            <DateBoxDate>{formatDay(startDate as Date)}</DateBoxDate>
            <Box position="absolute" right="-6px" bottom="-6px" width="16px">
              <ReactSVG src={RejectedSVG} />
            </Box>
          </DateBox>
        )}
        <EventDescription sx={{ textDecoration: "line-through" }}>
          <EventTitle>{interviewEventDetails!.title}</EventTitle>
          {startDateIsValid && endDateIsValid && (
            <EventSubtitle>
              {formatTime(startDate as Date)} - {formatTime(endDate as Date)}
            </EventSubtitle>
          )}
        </EventDescription>
      </Box>
    </EventBodyContainer>
  );
};

const EventBodyContainer = styled.div`
  border-radius: 4px;
  background-color: ${colors.grayscale.gray100};
  width: 350px;
`;

const DateBox = styled.div`
  align-self: center;
  background-color: ${backgrounds.white};
  border: 1px solid ${colors.grayscale.gray200};
  border-radius: 3px;
  height: 44px;
  position: relative;
  width: 48px;
`;

const DateBoxMonth = styled.div`
  background-color: ${colors.grayscale.gray600};
  color: white;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
`;

const DateBoxDate = styled.div`
  font-size: 1.3em;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
`;

const EventDescription = styled(Box)`
  margin-left: 24px;
`;

const EventTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const EventSubtitle = styled.div`
  font-size: 12px;
`;
