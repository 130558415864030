/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutreachBulkCandidateItem,
    OutreachBulkCandidateItemFromJSON,
    OutreachBulkCandidateItemFromJSONTyped,
    OutreachBulkCandidateItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddCandidateInitialOutreachBulkRequest
 */
export interface AddCandidateInitialOutreachBulkRequest {
    /**
     * 
     * @type {string}
     * @memberof AddCandidateInitialOutreachBulkRequest
     */
    job: string;
    /**
     * 
     * @type {Array<OutreachBulkCandidateItem>}
     * @memberof AddCandidateInitialOutreachBulkRequest
     */
    candidates: Array<OutreachBulkCandidateItem>;
    /**
     * 
     * @type {string}
     * @memberof AddCandidateInitialOutreachBulkRequest
     */
    campaign: string;
    /**
     * 
     * @type {string}
     * @memberof AddCandidateInitialOutreachBulkRequest
     */
    firstMessageBody?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddCandidateInitialOutreachBulkRequest
     */
    firstMessageSubject?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddCandidateInitialOutreachBulkRequest
     */
    ccEmails?: Array<string> | null;
}

export function AddCandidateInitialOutreachBulkRequestFromJSON(json: any): AddCandidateInitialOutreachBulkRequest {
    return AddCandidateInitialOutreachBulkRequestFromJSONTyped(json, false);
}

export function AddCandidateInitialOutreachBulkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddCandidateInitialOutreachBulkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'job': json['job'],
        'candidates': ((json['candidates'] as Array<any>).map(OutreachBulkCandidateItemFromJSON)),
        'campaign': json['campaign'],
        'firstMessageBody': !exists(json, 'first_message_body') ? undefined : json['first_message_body'],
        'firstMessageSubject': !exists(json, 'first_message_subject') ? undefined : json['first_message_subject'],
        'ccEmails': !exists(json, 'cc_emails') ? undefined : json['cc_emails'],
    };
}

export function AddCandidateInitialOutreachBulkRequestToJSON(value?: AddCandidateInitialOutreachBulkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': value.job,
        'candidates': ((value.candidates as Array<any>).map(OutreachBulkCandidateItemToJSON)),
        'campaign': value.campaign,
        'first_message_body': value.firstMessageBody,
        'first_message_subject': value.firstMessageSubject,
        'cc_emails': value.ccEmails,
    };
}


