/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FrontPerson
 */
export interface FrontPerson {
    /**
     * 
     * @type {string}
     * @memberof FrontPerson
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontPerson
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof FrontPerson
     */
    readonly cleanFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontPerson
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontPerson
     */
    readonly linkedinUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontPerson
     */
    readonly location?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontPerson
     */
    readonly timezone?: string | null;
}

export function FrontPersonFromJSON(json: any): FrontPerson {
    return FrontPersonFromJSONTyped(json, false);
}

export function FrontPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontPerson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': json['first_name'],
        'cleanFirstName': !exists(json, 'clean_first_name') ? undefined : json['clean_first_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
    };
}

export function FrontPersonToJSON(value?: FrontPerson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
    };
}


