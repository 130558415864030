import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, Stack } from "@mui/material";
import React, { useState } from "react";

import { Paragraph, StyledModal, StyledUpgradePlanModalPaper } from "components/dover/Campaigns/styles";
import { ModalKeyType } from "components/dover/Campaigns/types";
import { Button, ButtonVariant } from "components/library/Button";
import DoverLoadingOverlay from "components/loading-overlay";

interface UpgradePlanPublishModalProps {
  shouldShowModal: string;
  handleCloseModal: () => void;
  handleSaveCampaign: () => void;
  modalKey: ModalKeyType;
}

export const UpgradePlanPublishModal = ({
  shouldShowModal,
  handleCloseModal,
  handleSaveCampaign,
  modalKey,
}: UpgradePlanPublishModalProps): React.ReactElement => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSave = async (): Promise<void> => {
    setSubmitting(true);
    handleSaveCampaign();
    setSubmitting(false);
    handleCloseModal();
  };

  return (
    <StyledModal open={shouldShowModal === modalKey} onClick={(): void => handleCloseModal()}>
      <DoverLoadingOverlay active={submitting} overlay={true}>
        <StyledUpgradePlanModalPaper
          elevation={3}
          onClick={(e): void => {
            e.stopPropagation();
          }}
        >
          <Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" py={2} px={3}>
              <Box>Publish a Second Campaign</Box>
              <Button variant={ButtonVariant.Ghost} onClick={(): void => handleCloseModal()}>
                <CloseIcon />
              </Button>
            </Stack>
            <Divider />
            <Stack spacing={2} py={2} px={3}>
              <Paragraph>{"Your plan only allows 1 active campaign for each job."}</Paragraph>
              <Paragraph>
                <b>If you wish to replace your active campaign</b>, click {`"Cancel"`} to return to the previous page,
                deactivate the currently active campaign, and hit {`"Publish"`} on this campaign again.
              </Paragraph>
              <Paragraph>
                <b>If you wish to have 2 active campaigns</b>, click {`"Save and explore new plans"`} to find the plan
                that gives you the right features and/or services!
              </Paragraph>
            </Stack>
            <Divider />
            <Stack direction="row-reverse" spacing={2} py={2} px={3}>
              <Button variant={ButtonVariant.Primary} onClick={handleSave}>
                Save
              </Button>
              <Button variant={ButtonVariant.Secondary} onClick={handleCloseModal}>
                {"Cancel"}
              </Button>
            </Stack>
          </Stack>
        </StyledUpgradePlanModalPaper>
      </DoverLoadingOverlay>
    </StyledModal>
  );
};
