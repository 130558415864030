import { Box, TextField, TextFieldProps } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useBoolean } from "react-use";
import { DefaultTheme, StyledComponent } from "styled-components";

import { Tooltip } from "components/library/Tooltip";
import { BaseTypeProps, Body } from "components/library/typography";
import { colors } from "styles/theme";

interface EditableDisplayFieldProps {
  text?: string;
  displayOnlyText?: string; // If you want to display a different text than the one being edited you can include this field
  onChange?: (text: string) => void;
  onBlur?: () => void;
  onEnter?: () => void;
  onEscape?: () => void;
  DisplayComponent?: StyledComponent<"div", DefaultTheme, BaseTypeProps, never>;
  defaultMode?: "display" | "edit";
  fullWidth?: boolean;
  displayColor?: string;
}

/**
 * This component simply display the text in its default state,
 * but it can be clicked on to switch into editing mode.
 */
export const EditableDisplayField: FC<EditableDisplayFieldProps &
  Omit<TextFieldProps, "value" | "onChange" | "onBlur" | "onKeyDown">> = ({
  text,
  displayOnlyText,
  onChange,
  onBlur,
  onEnter,
  onEscape,
  DisplayComponent,
  size = "small",
  placeholder,
  defaultMode = "display",
  disabled,
  fullWidth,
  displayColor,
  ...textFieldProps
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const [isEditing, toggleIsEditing] = useBoolean(defaultMode === "edit");

  const textElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  }, []);

  const Display = DisplayComponent ?? Body;

  const toggle = (): void => {
    if (!disabled) {
      toggleIsEditing();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange?.(event.target.value);
  };

  const handleBlur = (): void => {
    toggleIsEditing();
    onBlur?.();
  };

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      toggleIsEditing();
      onEnter?.();
    }

    if (event.key === "Escape") {
      toggleIsEditing();
      onEscape?.();
    }
  };

  if (isEditing) {
    return (
      <TextField
        autoFocus
        value={text}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyPress}
        placeholder={placeholder}
        size={size}
        disabled={disabled}
        fullWidth={fullWidth}
        {...textFieldProps}
      />
    );
  }

  return (
    <Tooltip title={isOverflowed ? text : ""}>
      <Box
        onClick={toggle}
        width={fullWidth ? "100%" : undefined}
        minWidth={0}
        p={1}
        borderRadius="6px"
        sx={{ "&:hover": { backgroundColor: colors.grayscale.gray200 } }}
      >
        <Display ref={textElementRef} pointer ellipsis color={disabled ? colors.grayscale.gray500 : displayColor}>
          {displayOnlyText ?? text}
        </Display>
      </Box>
    </Tooltip>
  );
};
