import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Label from "components/inputs/SingleSelectDropdown/Label";
import { AutocompleteStyles } from "components/inputs/SingleSelectDropdown/styles";
import { getOptionLabel } from "components/inputs/SingleSelectDropdown/utils";

// TODO(jhh3): Share more code between this and MultiSelect
const SingleSelectDropdown = ({
  label,
  options,
  value,
  onChange,
  TextFieldProps: { label: textFieldLabel, helperText, invalid, required, onFocus, onBlur, isSearchable, placeholder },
  ...rest
}) => {
  const [valueSet, setValueSet] = useState(false);

  return (
    <AutocompleteStyles hideLabel={valueSet}>
      <Label label={label} required={required} />
      <Autocomplete
        {...rest}
        options={options}
        value={value}
        classes={{ popper: "dover-multiselect-pq-popper" }}
        onChange={(e, v) => {
          setValueSet(!(!v || v.length === 0));
          onChange(e, v);
        }}
        blurOnSelect
        renderInput={params => {
          return (
            <TextField
              fullWidth
              onFocus={onFocus}
              onBlur={onBlur}
              {...params}
              error={!!invalid}
              variant="outlined"
              helperText={helperText}
              InputLabelProps={{ shrink: false, style: { display: params.inputProps.value || valueSet ? "none" : "" } }}
              label={textFieldLabel}
              inputProps={{
                ...params.inputProps,
                readOnly: !isSearchable,
                placeholder: placeholder,
              }}
            />
          );
        }}
        getOptionLabel={option => getOptionLabel(option, options)}
      />
    </AutocompleteStyles>
  );
};

SingleSelectDropdown.propTypes = {
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
    })
  ).isRequired,
  value: PropTypes.any,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  TextFieldProps: PropTypes.shape({
    label: PropTypes.node,
    helperText: PropTypes.node,
    invalid: PropTypes.bool,
    required: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    isSearchable: PropTypes.bool,
    placeholder: PropTypes.node,
  }),
};

SingleSelectDropdown.defaultProps = {};

export default SingleSelectDropdown;
