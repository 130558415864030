/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateJobHiringTeam
 */
export interface UpdateJobHiringTeam {
    /**
     * 
     * @type {string}
     * @memberof UpdateJobHiringTeam
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateJobHiringTeam
     */
    hiringManagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateJobHiringTeam
     */
    recruiterId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateJobHiringTeam
     */
    hiringTeamMemberIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateJobHiringTeam
     */
    appendHiringTeamMembers?: boolean;
}

export function UpdateJobHiringTeamFromJSON(json: any): UpdateJobHiringTeam {
    return UpdateJobHiringTeamFromJSONTyped(json, false);
}

export function UpdateJobHiringTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJobHiringTeam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hiringManagerId': !exists(json, 'hiring_manager_id') ? undefined : json['hiring_manager_id'],
        'recruiterId': !exists(json, 'recruiter_id') ? undefined : json['recruiter_id'],
        'hiringTeamMemberIds': !exists(json, 'hiring_team_member_ids') ? undefined : json['hiring_team_member_ids'],
        'appendHiringTeamMembers': !exists(json, 'append_hiring_team_members') ? undefined : json['append_hiring_team_members'],
    };
}

export function UpdateJobHiringTeamToJSON(value?: UpdateJobHiringTeam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hiring_manager_id': value.hiringManagerId,
        'recruiter_id': value.recruiterId,
        'hiring_team_member_ids': value.hiringTeamMemberIds,
        'append_hiring_team_members': value.appendHiringTeamMembers,
    };
}


