import { Stack } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Centered } from "components/Centered";
import { LoadingRelative } from "components/HotLoading";
import { Autocomplete } from "components/library/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Subtitle1 } from "components/library/typography";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { useOpenApiClients } from "hooks/openApiClients";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import {
  useGetClientScopedTargetCompanyLists,
  useGetClientScopedTargetSchoolLists,
} from "services/doverapi/endpoints/search-v3/customHooks";
import {
  useCreateCompanyListMutation,
  useCreateSchoolListMutation,
  usePartialUpdateCompanyListMutation,
  usePartialUpdateSchoolListMutation,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { CompanyListWithRequiredID, SchoolListWithRequiredID } from "services/doverapi/endpoints/search-v3/types";
import { Company, CompanyList, School, SchoolList } from "services/openapi";
import { colors } from "styles/theme";
import {
  INDIVIDUAL_COMPANIES_ELEMENTS_NAME,
  INDIVIDUAL_SCHOOLS_NAME,
  TARGET_SCHOOL_LISTS_NAME,
  SELECTED_DOVER_COMPANY_LISTS_NAME,
} from "views/sourcing/Search/constants";
import { SearchV3FormSchemaType } from "views/sourcing/Search/types";

const EMPTY_LIST_NAME_ERROR = "Please enter a list name";

export const SaveNewSchoolListModal = ({
  isModalOpen,
  toggleModalOff,
}: {
  isModalOpen: boolean;
  toggleModalOff: () => void;
}): React.ReactElement => {
  const [listTitle, setListTitle] = React.useState<string>("");
  const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
  const targetSchoolLists = useWatch({ control, name: TARGET_SCHOOL_LISTS_NAME });

  // When creating a list, users want to load in the data that they were working with
  // Going forward though, we update this data only in the context of this modal
  const individualSchoolsInControl = useWatch({ control, name: INDIVIDUAL_SCHOOLS_NAME });
  const [individualSchools, setIndividualSchools] = React.useState<School[]>([]);

  const client = useOpenApiClients()?.apiApi;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchIndividualSchools = async (request: string): Promise<School[]> => {
    if (client === undefined) {
      return [];
    }

    const schools = await client.listSimilarSchools({ limit: 50, fullTextSearch: request });
    return schools.results;
  };

  React.useEffect(() => {
    if (individualSchoolsInControl !== undefined) {
      setIndividualSchools(individualSchoolsInControl);
    }
  }, [individualSchoolsInControl]);

  const [createSchoolList, { isLoading: isCreatingSchoolList }] = useCreateSchoolListMutation();

  const body = React.useMemo(() => {
    return (
      <Stack spacing={3}>
        <TextField title={"Name this list"} text={listTitle} onTextUpdated={setListTitle} />
        <Autocomplete
          title="Add schools"
          fontSize="small"
          placeholder="Enter specific schools..."
          fetch={fetchIndividualSchools}
          onSelectedOptionsChange={setIndividualSchools}
          noOptionsText="Start typing to see schools..."
          filterSelectedOptions={true}
          getOptionLabel={(option: School): string => option.name}
          initialValues={individualSchools}
          multiple={true}
          sortByField={"name"}
        />
      </Stack>
    );
  }, [fetchIndividualSchools, individualSchools, listTitle]);

  const clientId = useGetClientId();
  const onCreateSchoolList = React.useCallback(() => {
    if (clientId === undefined) {
      return;
    }

    createSchoolList({
      name: listTitle,
      scopedToClient: clientId,
      paramsJson: { schoolIds: individualSchools.map(school => school.id) },
    })
      .unwrap()
      .then((list: SchoolList) => {
        toggleModalOff();
        setValue(INDIVIDUAL_SCHOOLS_NAME, []);
        setValue(TARGET_SCHOOL_LISTS_NAME, [
          ...(targetSchoolLists as SchoolListWithRequiredID[]),
          list as SchoolListWithRequiredID,
        ]);
        setListTitle("");
      });
  }, [clientId, createSchoolList, individualSchools, listTitle, setValue, targetSchoolLists, toggleModalOff]);

  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={toggleModalOff}
        title={<Subtitle1 weight="600">{"Create a school list"}</Subtitle1>}
        maxWidth={"xs"}
        omitDividers={true}
        dialogActions={
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Button variant={ButtonVariant.Secondary} onClick={toggleModalOff}>
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.Primary}
                onClick={onCreateSchoolList}
                loading={isCreatingSchoolList}
                disabled={!listTitle}
              >
                Save
              </Button>
            </Stack>
            {!listTitle && <BodySmall color={colors.critical.base}>{EMPTY_LIST_NAME_ERROR}</BodySmall>}
          </Stack>
        }
      >
        {body}
      </CustomModal>
    </>
  );
};

export const EditSchoolListsModal = ({
  isModalOpen,
  toggleModalOff,
  toggleNewSchoolModalOn,
}: {
  isModalOpen: boolean;
  toggleModalOff: () => void;
  toggleNewSchoolModalOn: () => void;
}): React.ReactElement => {
  const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
  const targetSchoolListsInControl = useWatch({ control, name: TARGET_SCHOOL_LISTS_NAME });

  const [updateSchoolList, { isLoading: isUpdatingSchoolList }] = usePartialUpdateSchoolListMutation();
  const { data: mySchoolListsOptions } = useGetClientScopedTargetSchoolLists();

  const [selectedSchoolList, setSelectedSchoolList] = React.useState<SchoolListWithRequiredID | undefined>();
  const [listTitle, setListTitle] = React.useState<string>("");
  const [individualSchools, setIndividualSchools] = React.useState<School[]>([]);
  const [loadingSchoolsInList, setLoadingSchoolsInList] = React.useState<boolean>(false);

  const client = useOpenApiClients()?.apiApi;
  const fetchIndividualSchools = React.useCallback(
    async (request: string): Promise<School[]> => {
      if (client === undefined) {
        return [];
      }

      const schools = await client.listSimilarSchools({ limit: 50, fullTextSearch: request });
      return schools.results;
    },
    [client]
  );

  // Upon initial load
  React.useEffect(() => {
    if (selectedSchoolList === undefined && mySchoolListsOptions !== undefined && mySchoolListsOptions.length > 0) {
      setSelectedSchoolList(mySchoolListsOptions[0]);
    }
  }, [mySchoolListsOptions, selectedSchoolList]);

  // Upon selecting a new list off the sidebar
  React.useEffect(() => {
    if (selectedSchoolList) {
      const fetchExistingSchoolsInList = async (idList: string): Promise<School[]> => {
        if (client === undefined) {
          return [];
        }

        const schools = await client.listSimilarSchools({ limit: 50, idList });
        return schools.results;
      };

      setLoadingSchoolsInList(true);
      setListTitle(selectedSchoolList.name);
      fetchExistingSchoolsInList((selectedSchoolList.paramsJson.schoolIds ?? []).join(",")).then(
        (schools: School[]) => {
          setIndividualSchools(schools);
          setLoadingSchoolsInList(false);
        }
      );
    }
  }, [selectedSchoolList, client]);

  const onSave = React.useCallback(async () => {
    if (selectedSchoolList) {
      const updatedList = await updateSchoolList({
        id: selectedSchoolList.id,
        data: {
          name: listTitle,
          paramsJson: {
            schoolIds: [...individualSchools.map(school => school.id)],
          },
        },
      }).unwrap();

      setValue(TARGET_SCHOOL_LISTS_NAME, [
        ...(targetSchoolListsInControl?.filter(option => option.id !== updatedList.id) as SchoolListWithRequiredID[]),
        updatedList as SchoolListWithRequiredID,
      ]);
      toggleModalOff();
    }
  }, [
    setValue,
    toggleModalOff,
    updateSchoolList,
    selectedSchoolList,
    listTitle,
    individualSchools,
    targetSchoolListsInControl,
  ]);

  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={toggleModalOff}
        title={<Subtitle1 weight="600">{"Edit my lists"}</Subtitle1>}
        maxWidth={"md"}
        omitDividers={true}
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Secondary} onClick={toggleModalOff}>
              Cancel
            </Button>
            <Button variant={ButtonVariant.Primary} onClick={onSave} loading={isUpdatingSchoolList}>
              Save
            </Button>
          </Stack>
        }
      >
        <Stack direction="row" spacing={4}>
          <Stack spacing={0.5}>
            {mySchoolListsOptions?.map(schoolList => (
              <Stack
                sx={{ backgroundColor: schoolList.id === selectedSchoolList?.id ? colors.grayscale.gray200 : "none" }}
              >
                <Button
                  variant={ButtonVariant.Ghost}
                  onClick={(): void => {
                    setSelectedSchoolList(schoolList);
                  }}
                >
                  <Body weight="600" style={{ textAlign: "start" }}>
                    {schoolList.name}
                  </Body>
                </Button>
              </Stack>
            ))}
            <Button variant={ButtonVariant.Ghost} onClick={toggleNewSchoolModalOn}>
              <Body weight="600" color={colors.link} style={{ textAlign: "start" }}>
                + Add new
              </Body>
            </Button>
          </Stack>
          {loadingSchoolsInList ? (
            <Centered>
              <LoadingRelative />
            </Centered>
          ) : (
            <Stack spacing={2}>
              <TextField title={"List name"} text={listTitle} onTextUpdated={setListTitle} />
              <Autocomplete
                title="Specific schools"
                fontSize="small"
                placeholder="Enter specific schools..."
                fetch={fetchIndividualSchools}
                onSelectedOptionsChange={setIndividualSchools}
                noOptionsText="Start typing to see schools..."
                filterSelectedOptions={true}
                getOptionLabel={(option: School): string => option.name}
                initialValues={individualSchools}
                multiple={true}
                sortByField={"name"}
              />
            </Stack>
          )}
        </Stack>
      </CustomModal>
    </>
  );
};

export const SaveNewCompanyListModal = ({
  isModalOpen,
  toggleModalOff,
}: {
  isModalOpen: boolean;
  toggleModalOff: () => void;
}): React.ReactElement => {
  const [listTitle, setListTitle] = React.useState<string>("");
  const { control, setValue } = useFormContext<SearchV3FormSchemaType>();

  // When creating a list, users want to load in the data that they were working with
  // Going forward though, we update this data only in the context of this modal
  const individualCompaniesInControl = useWatch({ control, name: INDIVIDUAL_COMPANIES_ELEMENTS_NAME });
  const myCompanyListsInControl = useWatch({ control, name: SELECTED_DOVER_COMPANY_LISTS_NAME });
  const [individualCompanies, setIndividualCompanies] = React.useState<Company[]>([]);

  const client = useOpenApiClients()?.apiApi;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchIndividualCompanies = async (request: string): Promise<Company[]> => {
    if (client === undefined) {
      return [];
    }

    const companies = await client.listSimilarCompanies({ limit: 1000, fullTextSearch: request });
    return companies.results;
  };

  React.useEffect(() => {
    if (individualCompaniesInControl !== undefined) {
      setIndividualCompanies(individualCompaniesInControl);
    }
  }, [individualCompaniesInControl]);

  const [createCompanyList, { isLoading: isCreatingCompanyList }] = useCreateCompanyListMutation();

  const body = React.useMemo(() => {
    return (
      <Stack spacing={3}>
        <TextField title={"Name this list"} text={listTitle} onTextUpdated={setListTitle} />
        <Autocomplete
          title="Add companies"
          fontSize="small"
          placeholder="Enter specific companies..."
          fetch={fetchIndividualCompanies}
          onSelectedOptionsChange={setIndividualCompanies}
          noOptionsText="Start typing to see companies..."
          filterSelectedOptions={true}
          initialValues={individualCompanies}
          multiple={true}
          sortByField={"name"}
          getOptionLabel={(option: Company): string =>
            option.url ? `${option.name} (${option.url})` : `${option.name}`
          }
        />
      </Stack>
    );
  }, [fetchIndividualCompanies, individualCompanies, listTitle]);

  const clientId = useGetClientId();
  const onCreateCompanyList = React.useCallback(() => {
    if (clientId === undefined) {
      return;
    }

    createCompanyList({
      name: listTitle,
      scopedToClient: clientId,
      paramsJson: { companyIds: individualCompanies.map(company => company.id) },
    })
      .unwrap()
      .then((list: CompanyList) => {
        toggleModalOff();
        setValue(INDIVIDUAL_COMPANIES_ELEMENTS_NAME, []);
        setValue(SELECTED_DOVER_COMPANY_LISTS_NAME, [
          ...(myCompanyListsInControl as CompanyListWithRequiredID[]),
          list as CompanyListWithRequiredID,
        ]);
        setListTitle("");
      });
  }, [clientId, myCompanyListsInControl, createCompanyList, individualCompanies, listTitle, setValue, toggleModalOff]);

  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={toggleModalOff}
        title={<Subtitle1 weight="600">{"Create a company list"}</Subtitle1>}
        maxWidth={"xs"}
        omitDividers={true}
        dialogActions={
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Button variant={ButtonVariant.Secondary} onClick={toggleModalOff}>
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.Primary}
                onClick={onCreateCompanyList}
                loading={isCreatingCompanyList}
                disabled={!listTitle}
              >
                Save
              </Button>
            </Stack>
            {!listTitle && <BodySmall color={colors.critical.base}>{EMPTY_LIST_NAME_ERROR}</BodySmall>}
          </Stack>
        }
      >
        {body}
      </CustomModal>
    </>
  );
};

export const EditCompanyListsModal = ({
  isModalOpen,
  toggleModalOff,
  toggleNewCompanyModalOn,
}: {
  isModalOpen: boolean;
  toggleModalOff: () => void;
  toggleNewCompanyModalOn: () => void;
}): React.ReactElement => {
  const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
  const myCompanyListsInControl = useWatch({ control, name: SELECTED_DOVER_COMPANY_LISTS_NAME });

  const [updateCompanyList, { isLoading: isUpdatingCompanyList }] = usePartialUpdateCompanyListMutation();
  const { data: myCompanyListsOptions } = useGetClientScopedTargetCompanyLists();

  const [selectedCompanyList, setSelectedCompanyList] = React.useState<CompanyListWithRequiredID>();
  const [listTitle, setListTitle] = React.useState<string>("");
  const [individualCompanies, setIndividualCompanies] = React.useState<Company[]>([]);
  const [loadingCompaniesInList, setLoadingCompaniesInList] = React.useState<boolean>(false);

  const client = useOpenApiClients()?.apiApi;
  const fetchIndividualCompanies = React.useCallback(
    async (request: string): Promise<Company[]> => {
      if (client === undefined) {
        return [];
      }

      const companies = await client.listSimilarCompanies({ limit: 1000, fullTextSearch: request });
      return companies.results;
    },
    [client]
  );

  // Upon initial load
  React.useEffect(() => {
    if (selectedCompanyList === undefined && myCompanyListsOptions !== undefined && myCompanyListsOptions.length > 0) {
      setSelectedCompanyList(myCompanyListsOptions[0]);
    }
  }, [myCompanyListsOptions, selectedCompanyList]);

  // Upon selecting a new list off the sidebar
  React.useEffect(() => {
    if (selectedCompanyList) {
      const fetchExistingCompaniesInList = async (idList: string): Promise<Company[]> => {
        if (client === undefined) {
          return [];
        }

        const companies = await client.listSimilarCompanies({ limit: 1000, idList });
        return companies.results;
      };

      setLoadingCompaniesInList(true);
      setListTitle(selectedCompanyList.name);
      fetchExistingCompaniesInList((selectedCompanyList.paramsJson.companyIds ?? []).join(",")).then(
        (companies: Company[]) => {
          setIndividualCompanies(companies);
          setLoadingCompaniesInList(false);
        }
      );
    }
  }, [selectedCompanyList, client]);

  const onSave = React.useCallback(async () => {
    if (selectedCompanyList) {
      const updatedList = await updateCompanyList({
        id: selectedCompanyList.id,
        data: {
          name: listTitle,
          paramsJson: {
            companyIds: [...individualCompanies.map(company => company.id)],
          },
        },
      }).unwrap();

      setValue(SELECTED_DOVER_COMPANY_LISTS_NAME, [
        ...(myCompanyListsInControl?.filter(option => option.id !== updatedList.id) as CompanyListWithRequiredID[]),
        updatedList as CompanyListWithRequiredID,
      ]);
      toggleModalOff();
    }
  }, [
    setValue,
    toggleModalOff,
    updateCompanyList,
    selectedCompanyList,
    listTitle,
    individualCompanies,
    myCompanyListsInControl,
  ]);

  return (
    <>
      <CustomModal
        open={isModalOpen}
        onClose={toggleModalOff}
        title={<Subtitle1 weight="600">{"Edit my lists"}</Subtitle1>}
        maxWidth={"md"}
        omitDividers={true}
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button variant={ButtonVariant.Secondary} onClick={toggleModalOff}>
              Cancel
            </Button>
            <Button variant={ButtonVariant.Primary} onClick={onSave} loading={isUpdatingCompanyList}>
              Save
            </Button>
          </Stack>
        }
      >
        <Stack direction="row" spacing={4}>
          <Stack spacing={0.5}>
            {myCompanyListsOptions?.map(companyList => (
              <Stack
                sx={{ backgroundColor: companyList.id === selectedCompanyList?.id ? colors.grayscale.gray200 : "none" }}
              >
                <Button
                  variant={ButtonVariant.Ghost}
                  onClick={(): void => {
                    setSelectedCompanyList(companyList);
                  }}
                >
                  <Body weight="600" style={{ textAlign: "start" }}>
                    {companyList.name}
                  </Body>
                </Button>
              </Stack>
            ))}
            <Button variant={ButtonVariant.Ghost} onClick={toggleNewCompanyModalOn}>
              <Body weight="600" color={colors.link} style={{ textAlign: "start" }}>
                + Add new
              </Body>
            </Button>
          </Stack>
          {loadingCompaniesInList ? (
            <Centered>
              <LoadingRelative />
            </Centered>
          ) : (
            <Stack spacing={2}>
              <Stack>
                <TextField
                  title={"List name"}
                  text={listTitle}
                  onTextUpdated={setListTitle}
                  disabled={!selectedCompanyList}
                />
              </Stack>
              <Stack>
                <Autocomplete
                  title="Specific companies"
                  fontSize="small"
                  placeholder="Enter specific companies..."
                  fetch={fetchIndividualCompanies}
                  onSelectedOptionsChange={setIndividualCompanies}
                  noOptionsText="Start typing to see companies..."
                  filterSelectedOptions={true}
                  initialValues={individualCompanies}
                  multiple={true}
                  sortByField={"name"}
                  getOptionLabel={(option: Company): string =>
                    option.url ? `${option.name} (${option.url})` : `${option.name}`
                  }
                  disabled={!selectedCompanyList}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </CustomModal>
    </>
  );
};
