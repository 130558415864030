/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArchiveReason
 */
export interface ArchiveReason {
    /**
     * 
     * @type {string}
     * @memberof ArchiveReason
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ArchiveReason
     */
    clientId: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArchiveReason
     */
    type?: ArchiveReasonTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ArchiveReason
     */
    description: string;
}

/**
* @export
* @enum {string}
*/
export enum ArchiveReasonTypeEnum {
    Rejected = 'REJECTED',
    Withdrew = 'WITHDREW',
    Hired = 'HIRED',
    Snoozed = 'SNOOZED',
    Other = 'OTHER'
}

export function ArchiveReasonFromJSON(json: any): ArchiveReason {
    return ArchiveReasonFromJSONTyped(json, false);
}

export function ArchiveReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveReason {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clientId': json['client_id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': json['description'],
    };
}

export function ArchiveReasonToJSON(value?: ArchiveReason | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'type': value.type,
        'description': value.description,
    };
}


