import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import styled from "styled-components";

import { PageTabProps } from "components/library/TabBar/types";
import { colors } from "styles/theme";

export const PageTab = styled(Box)<PageTabProps>`
  border: transparent;
  box-shadow: ${({ $isActive }): string => ($isActive ? `inset 0px -3px 0px ${colors.primary.base}` : "")};
  display: inline-block;
  font-weight: ${({ $isActive }): string => ($isActive ? "bold" : "")};
  padding: 20px;
  cursor: pointer;
  font-size: ${({ $fontSize }): string => $fontSize ?? "14px"};
  font-family: Inter;
  ${({ $disabled }): string =>
    $disabled
      ? `
        cursor: not-allowed;
        color: ${colors.grayscale.gray400};
      `
      : `
        color: ${colors.grayscale.gray700};
      `};
`;

export const PageTabContainer = styled(Stack)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 65px;
`;
