import React from "react";
import styled from "styled-components";

import { Source } from "views/CompanySetup/components/Credentials/types";

const SOURCE_TO_LOGO: { [key in Source]?: string } = {
  WELLFOUND: "/static/images/wellfound/default.svg",
  TRIPLEBYTE: "/static/images/triplebyte/default.svg",
  YC_WAAS: "/static/images/yc_waas/default.png",
};

const LogoImg = styled.img`
  width: 100%;
  height: auto;
`;

interface Props {
  source: Source;
}

export const Logo: React.FC<Props> = ({ source }) => <LogoImg src={SOURCE_TO_LOGO[source]} />;

export default Logo;
