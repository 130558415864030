/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkCampaignUpdateResult
 */
export interface BulkCampaignUpdateResult {
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof BulkCampaignUpdateResult
     */
    updatedCampaigns: Array<{ [key: string]: string; }>;
}

export function BulkCampaignUpdateResultFromJSON(json: any): BulkCampaignUpdateResult {
    return BulkCampaignUpdateResultFromJSONTyped(json, false);
}

export function BulkCampaignUpdateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCampaignUpdateResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updatedCampaigns': json['updated_campaigns'],
    };
}

export function BulkCampaignUpdateResultToJSON(value?: BulkCampaignUpdateResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updated_campaigns': value.updatedCampaigns,
    };
}


