import { EntityState } from "@reduxjs/toolkit";

import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { UseQueryResult } from "services/doverapi/types";
import { ListCampaign, ListCampaignSetupStateEnum, ListCampaignStateEnum } from "services/openapi";

interface SelectFromListCampaignsQueryResult extends UseQueryResult<EntityState<ListCampaign>> {
  allCampaigns: ListCampaign[] | undefined;
  activeAndCompleteCampaigns: ListCampaign[] | undefined;
  gmailAliasesThatNeedAuth: string[] | undefined;
  selectedCampaign: ListCampaign | undefined;
}

export const selectFromListCampaignsQueryResult = (
  rtkResults: UseQueryResult<EntityState<ListCampaign>>,
  {
    selectedCampaignId,
    sortFunc,
  }: { selectedCampaignId?: string | null; sortFunc?: (campaigns: ListCampaign[]) => ListCampaign[] } = {}
): SelectFromListCampaignsQueryResult => {
  // Start off with everything as undefined. Only if we're done loading and the prerequisite
  // args are provided will certain bits of enriched data be assigned.
  let allCampaigns: ListCampaign[] | undefined = undefined;
  let activeAndCompleteCampaigns: ListCampaign[] | undefined = undefined;
  let gmailAliasesThatNeedAuth: string[] | undefined = undefined;
  let selectedCampaign: ListCampaign | undefined = undefined;

  if (!rtkResults.isLoading && rtkResults.data) {
    // Flatten to an array of campaigns and sort them if a sort function was provided.
    allCampaigns = listAllEntities(rtkResults.data) ?? [];
    if (sortFunc) {
      allCampaigns = sortFunc(allCampaigns);
    }

    // Re-initialize arrays now that data has been loaded from the endpoint
    // (assuming prerequisite args were supplied).
    activeAndCompleteCampaigns = [];
    gmailAliasesThatNeedAuth = [];

    // Iterate over campaigns once
    allCampaigns.forEach((campaign: ListCampaign) => {
      if (
        campaign.state === ListCampaignStateEnum.Active &&
        campaign.setupState === ListCampaignSetupStateEnum.Complete
      ) {
        if (campaign?.emailAlias?.email) {
          gmailAliasesThatNeedAuth!.push(campaign.emailAlias.email);
        }

        activeAndCompleteCampaigns!.push(campaign);
      }

      if (selectedCampaignId && campaign.id === selectedCampaignId) {
        selectedCampaign = campaign;
      }
    });
  }

  return {
    // Transformations
    allCampaigns,
    activeAndCompleteCampaigns,
    gmailAliasesThatNeedAuth,
    selectedCampaign,
    // Default result values that come for free from RTK Query
    // https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#usequery
    ...rtkResults,
  };
};
