import PreviewIcon from "@mui/icons-material/Preview";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { BodySmall, Subtitle2 } from "components/library/typography";
import { OutreachBulkCandidateItem } from "services/openapi";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const CSVSummary = (): React.ReactElement | null => {
  const { control } = useFormContext();
  const manualSourcingItems = useWatch({
    control: control,
    name: "manualSourcingItems",
  });
  const rowsWithEmail = manualSourcingItems?.filter((row: OutreachBulkCandidateItem) => row.email).length;

  if (!manualSourcingItems || manualSourcingItems.length === 0) {
    return null;
  }

  return (
    <Stack direction="column" spacing={1}>
      <Subtitle2>CSV Summary</Subtitle2>
      <Stack direction="row" alignItems="center" spacing={1}>
        <BodySmall>{manualSourcingItems.length} rows total rows parsed</BodySmall>
        <HtmlTooltip
          title={
            <div style={{ maxHeight: "200px", overflow: "auto" }}>
              <Stack direction="column" spacing={1}>
                {manualSourcingItems.map((row: OutreachBulkCandidateItem, index: number) => (
                  <React.Fragment key={index}>
                    <BodySmall>
                      {row.url} ({row.email ? row.email : "No email"})
                    </BodySmall>
                  </React.Fragment>
                ))}
              </Stack>
            </div>
          }
        >
          <PreviewIcon />
        </HtmlTooltip>
      </Stack>
      <BodySmall>{rowsWithEmail} rows have an email</BodySmall>
    </Stack>
  );
};

export default CSVSummary;
