import { useEffect } from "react";
import { unstable_useBlocker } from "react-router-dom";

import { BlockNavWhen, BlockNavCallback } from "components/library/BlockNav/types";

/* Pure utility functions */
const preventReload = (e: BeforeUnloadEvent): boolean => {
  e.preventDefault();
  return false;
};

/**
 * This hook will block browser and react router navigation when certain conditions are met and allows the user to hook into that functionality
 *
 * @author Max McKinley <max.mckinley@dover.com>
 *
 * @param when - Will block navigation if true or is a function that returns true, otherwise will be unblocked
 * @param callback - Function that runs when user tries to navigate away, will be passed a function that unblocks navigation
 * @param blockRefresh - Block page refreshing as well as navigation
 * @param forceBlockRefresh - Block page refreshing no matter what
 */
export const useBlockNav = (
  when: BlockNavWhen,
  callback?: BlockNavCallback,
  blockRefresh?: boolean,
  forceBlockRefresh?: boolean
): void => {
  // If we should block navigation, do so
  const shouldBlock = typeof when === "function" ? when() : when;
  const blocker = unstable_useBlocker(shouldBlock);

  useEffect(() => {
    const removeReloadListener = (): void => {
      window.onbeforeunload = (): undefined => undefined;
    };

    if ((!!blockRefresh && shouldBlock) || forceBlockRefresh) {
      window.onbeforeunload = preventReload;
    } else {
      removeReloadListener();
    }

    return removeReloadListener;
  }, [blockRefresh, forceBlockRefresh, shouldBlock]);

  useEffect(() => {
    // If we're blocking navigation, provide the caller a way to unblock navigation
    if (blocker.state === "blocked") {
      callback?.(() => blocker.proceed());
    }

    // When we should no longer block navigation, unblock it
    if (blocker.state === "blocked" && !shouldBlock) {
      blocker.proceed();
    }
  }, [blocker, callback, shouldBlock]);

  return;
};
