import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import Color from "color";
import React from "react";
import styled from "styled-components";

import { ReactComponent as DashingMailIcon } from "assets/icons/dashing-mail.svg";
import { ReactComponent as PeopleIcon } from "assets/icons/people.svg";
import SourcingCandidates from "assets/images/sourcing-candidates.png";
import CreateJobButton from "components/dover/CreateJob/CreateJobButton";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { Button, ButtonVariant } from "components/library/Button";
import { GradientBackground } from "components/library/GradientBackground";
import { TitleLarge, Body, BodySmall } from "components/library/typography";
import { useLocalStorage } from "hooks/useLocalStorage";
import { selectFromListJobsQueryResult, useListJobsQuery } from "services/doverapi/endpoints/job";
import { CreateNewJobRequestCreateJobContextEnum } from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { ExternalLink } from "styles/typography";

const SearchesSplashPage = ({ variant = "page" }: { variant?: "page" | "panel" }): React.ReactElement => {
  const { allJobs } = useListJobsQuery(undefined, {
    selectFromResult: rtkResults => selectFromListJobsQueryResult(rtkResults),
  });
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptop));
  const [showSourcingSplash, setShowSourcingSplash] = useLocalStorage("showSourcsingSpssfsslashPagedse", true);
  const calendlyUrl = useGetCalendlyUrl("app", "splash_page", "sourcing_autopilot");
  const innerContent = (
    <>
      <Stack spacing={2.5}>
        <TitleLarge
          style={{
            fontSize: isSmallScreen ? "32px" : "40px",
            width: isSmallScreen ? "100%" : variant === "page" ? "470px" : "530px",
            fontWeight: 800,
            lineHeight: isSmallScreen ? "40px" : "48px",
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          <span
            style={{
              color: Color(colors.primary.dark)
                .darken(0.3)
                .toString(),
            }}
          >
            Automatically receive{" "}
          </span>
          <span
            style={{
              color: Color(colors.brand)
                .darken(0.3)
                .toString(),
            }}
          >
            qualified candidates
          </span>
        </TitleLarge>
        <Stack spacing={1} alignItems={isSmallScreen ? "center" : "left"}>
          <Stack direction="row" spacing={1} alignItems="center">
            <PeopleIcon
              width="20px"
              height="14px"
              className="svg-color"
              color={colors.grayscale.gray500}
              style={{
                minWidth: "14px",
              }}
            />
            <Body>Receive candidates that match your exact criteria</Body>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            {/* marginTop: 6px is a hack to make the icon look centered with the top line; this change is a bit faster than the ideal to drilling into the Body component to expose line height (which also isn't a perfect solution)*/}
            <StyledDashingMailIcon
              width="20px"
              height="11px"
              className="svg-fill"
              color={colors.grayscale.gray500}
              style={{
                marginTop: "6px",
                minWidth: "20px",
              }}
            />
            <Body>Dover finds and emails candidates for you, making outbound recruiting completely effortless</Body>
          </Stack>
        </Stack>
        {variant === "page" && (
          <>
            <Box textAlign={isSmallScreen ? "center" : "left"}>
              {(allJobs?.length ?? 0) > 0 ? (
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={(): void => {
                    setShowSourcingSplash(false);
                  }}
                >
                  Ok, got it
                </Button>
              ) : (
                <CreateJobButton
                  context={CreateNewJobRequestCreateJobContextEnum.Default}
                  label="Get Started"
                  buttonProps={{ variant: ButtonVariant.Primary }}
                />
              )}
            </Box>
            <Stack direction="row" spacing={1.5} alignItems="center" justifyContent={isSmallScreen ? "center" : "left"}>
              <BodySmall>
                Questions?{" "}
                <ExternalLink href={calendlyUrl} target="_blank" rel="noopener noreferrer" color={colors.link}>
                  Talk to us
                </ExternalLink>
              </BodySmall>
            </Stack>
          </>
        )}
      </Stack>
      <img
        src={SourcingCandidates}
        alt="Sourcing Candidates"
        width="400px"
        height="300px"
        style={{
          objectFit: "contain",
          marginTop: variant === "page" ? undefined : "40px",
        }}
      />
    </>
  );

  if (!showSourcingSplash) {
    return <></>;
  }

  if (variant === "panel") {
    return innerContent;
  }

  return (
    <GradientBackground
      spacing={2}
      direction={isSmallScreen ? "column" : "row"}
      justifyContent="space-between"
      alignItems="center"
      height={isSmallScreen ? "auto" : "400px"}
      m={2}
      p={4}
      style={{
        border: `1px solid ${colors.grayscale.gray200}`,
        borderRadius: "13px",
      }}
      angle="90"
      firstColor="rgba(230, 255, 247, 0.6)"
      firstColorPercent="0.1"
      secondColor="rgba(250, 255, 253, 0)"
      secondColorPercent="57.08"
    >
      {innerContent}
    </GradientBackground>
  );
};

const StyledDashingMailIcon = styled(DashingMailIcon)`
  line {
    stroke: ${colors.primary.base};
  }
`;

export default SearchesSplashPage;
