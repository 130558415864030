import { Box } from "@mui/material";
import React from "react";

import RedXIcon from "assets/icons/red-x.svg";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";
import { CellProps } from "views/candidates/CandidateTable/table/cells/types";

export const FailureReasonCell = ({ candidate }: CellProps): React.ReactElement => {
  return (
    <Cell>
      {candidate.pipelineFailedProcessing ? (
        <Box display="flex" alignItems="center">
          <img src={RedXIcon} alt="Error Icon" />
          <Spacer width={8} />
          <BodySmall>{candidate.pipelineFailureReason?.label}</BodySmall>
        </Box>
      ) : (
        ""
      )}
    </Cell>
  );
};
