import { Box } from "@mui/material";
import React from "react";

import { InterviewSchedulingScenario } from "components/SetupHiringPipeline/types";
import { Spacer } from "components/Spacer";
import { JobAtsStageMap, SchedulableCalendar } from "services/openapi";

export function getAtsStageTooltip(interviewSchedulingScenario: InterviewSchedulingScenario): React.ReactChild {
  if (
    interviewSchedulingScenario === InterviewSchedulingScenario.LOADING ||
    interviewSchedulingScenario === InterviewSchedulingScenario.SYNC_SUCCESSFUL_HAS_STAGES ||
    interviewSchedulingScenario === InterviewSchedulingScenario.SYNC_UNNECESSARY_HAS_STAGES
  ) {
    return `These correspond to interview stages set up in your ATS.`;
  } else if (interviewSchedulingScenario === InterviewSchedulingScenario.HAS_NOT_COMPLETED_COMPANY_ATS) {
    return `Please complete your company ATS integration in order to sync your interview plan to your ATS.`;
  } else if (interviewSchedulingScenario === InterviewSchedulingScenario.SYNC_PENDING_DOVER_ACTION) {
    return `Dover is still setting up your ATS integration. Please check back later to select your job ATS stages.`;
  } else if (interviewSchedulingScenario === InterviewSchedulingScenario.HAS_NOT_COMPLETED_JOB_ATS) {
    return (
      <Box>
        <Box>These correspond to interview stages set up in your ATS.</Box>
        <Spacer height="8px" />
        <Box>Please fill out the ATS Settings above before you can select your ATS Stage.</Box>
      </Box>
    );
  } else if (interviewSchedulingScenario === InterviewSchedulingScenario.FAILED_TO_SYNC_HAS_CACHE) {
    return `We were unable to recently sync this job’s ATS stages with your ATS. If you don’t see the correct ATS stages below, please contact support for help.`;
  } else if (
    interviewSchedulingScenario === InterviewSchedulingScenario.FAILED_TO_SYNC_NO_CACHE ||
    interviewSchedulingScenario === InterviewSchedulingScenario.SYNC_SUCCESSFUL_NO_STAGES
  ) {
    return `We were unable to sync your job's ATS stages with your ATS. Please make sure the information you input above is correct. If this problem persists, please contact Dover support for help.`;
  }
  return "";
}

export function getSelectPlaceholderText(
  interviewSchedulingScenario: InterviewSchedulingScenario,
  name: string
): string {
  if (
    interviewSchedulingScenario === InterviewSchedulingScenario.FAILED_TO_SYNC_HAS_CACHE ||
    interviewSchedulingScenario === InterviewSchedulingScenario.SYNC_SUCCESSFUL_HAS_STAGES ||
    interviewSchedulingScenario === InterviewSchedulingScenario.SYNC_UNNECESSARY_HAS_STAGES
  ) {
    return "";
  }
  if (interviewSchedulingScenario === InterviewSchedulingScenario.LOADING) {
    return "Loading...";
  }
  if (name.includes("Initial call")) {
    return "e.g. First Round";
  } else if (name.includes("Take-home")) {
    return "e.g. Take Home";
  } else if (name.includes("Second interview")) {
    return "e.g. Second Round";
  } else if (name.includes("Onsite")) {
    return "e.g. Onsite";
  } else if (name.includes("Offer extended")) {
    return "e.g. Offer";
  }
  return "";
}

export function getAtsFieldDisplayText(value: any, atsStages: JobAtsStageMap[] | undefined): string | undefined {
  return atsStages?.find(atsStage => atsStage.atsStageId === value)?.atsStage;
}

/* Removes Dover Interview calendars automatically named in the format "Hiring Calendar - {uuid}" */
export const removeDoverInterviewerCalendars = (data: SchedulableCalendar[] | undefined): SchedulableCalendar[] => {
  if (!data) {
    return [];
  }
  const regex = new RegExp("^Hiring Calendar - ");
  return data.filter(calendar => !calendar.name?.match(regex));
};
