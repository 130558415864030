import { Stack, Checkbox, Box, TextField } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { ReactComponent as InfoIconSVG } from "assets/icons/info-icon.svg";
import { ReactComponent as RedXIconSVG } from "assets/icons/red-x.svg";
import NoValuesAutocomplete from "components/library/Autocomplete/NoValuesAutocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { StyledSwitch } from "components/library/Switch";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Body, BodySmall, Overline, Subtitle1 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import { useOpenApiClients } from "hooks/openApiClients";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import {
  useListTitlePatternsForPersonaQuery,
  useModifyTitlePatternsInSearchMutation,
  useListTitlePatternsQuery,
  useCreateTitlePatternMutation,
  useLazyListPersonasQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { PersonaIDsRequired, TitlePatternIDsRequired } from "services/doverapi/endpoints/search-v3/types";
import {
  TitlePatternInfo,
  ModifyTitlePatternsInSearchResponse,
  ModifyTitlePatternsInSearchRequest,
} from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast, showSuccessToast } from "utils/showToast";
import { PERSONAS_ELEMENTS_NAME } from "views/sourcing/Search/constants";
import { convertPersonaIDToFormValueAsync, useGetSearchFromUrl } from "views/sourcing/Search/hooks";
import { SearchV3FormSchemaType } from "views/sourcing/Search/types";

interface CustomizeTitlesModalProps {
  isModalOpen: boolean;
  toggleModalOff: () => void;
  persona: PersonaIDsRequired;
}

// when a new title pattern is added, it will default to being soft match
const CUSTOM_TITLE_PATTERN_DEFAULT_SOFT_MATCH = true;

const TitlePatternAdder = React.memo(
  (props: { createTitlePattern: () => Promise<void> }): React.ReactElement => {
    // name it "Add Pattern" because it will return existing pattern if pattern with that name already exists
    return (
      <Button variant={ButtonVariant.Primary} width="100%" onClick={props.createTitlePattern}>
        + Add Pattern
      </Button>
    );
  }
);

export const CustomizeTitlesModal = React.memo(
  ({ isModalOpen, toggleModalOff, persona }: CustomizeTitlesModalProps): React.ReactElement => {
    const search = useGetSearchFromUrl();
    const clientId = useGetClientId();

    // get the setValue so we can set form state later
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const personas = useWatch({ control, name: PERSONAS_ELEMENTS_NAME });

    // prepare api client/mutations
    const apiClient = useOpenApiClients()?.apiApi;
    const [modifyTitlePatternsInSearch] = useModifyTitlePatternsInSearchMutation();
    const [createTitlePattern, { isLoading: isCreateTPLoading }] = useCreateTitlePatternMutation();
    const [lazyListPersonas] = useLazyListPersonasQuery();

    // initial list of title patterns is stored here
    const {
      data: titlePatternsForPersona,
      isLoading: isTitlePatternsForPersonaLoading,
    } = useListTitlePatternsForPersonaQuery(isModalOpen ? persona.id : skipToken); // only load once the modal is open
    // load all possible title patterns
    const { data: titlePatternOptions, isLoading: isTitlePatternsLoading } = useListTitlePatternsQuery(
      isModalOpen ? { limit: 1500 } : skipToken // only load once the modal is open
    );

    // everytime the user adds a pattern, it will be added to this list
    const [allPossibleTitlesFormatted2, setAllPossibleTitlesFormatted2] = React.useState<
      TitlePatternIDsRequired[] | undefined
    >([]);
    // list of all initial patterns, so we know whether customizations have been made
    const [allInitiallySelectedIDs, setAllInitiallySelectedIDs] = React.useState<number[] | undefined>([]);
    // this stores all the initial patterns. Useful to know which patterns have been added by the user
    const [allDefaultPatterns, setAllDefaultPatterns] = React.useState<TitlePatternIDsRequired[] | undefined>([]);
    const [allNonDefaultPatterns, setAllNonDefaultPatterns] = React.useState<TitlePatternIDsRequired[] | undefined>([]);

    const [mLToggleIsOn, setMLToggleIsOn] = React.useState<boolean>(true);

    // a way to keep track of which title pattern is being created
    const [titlePatternToCreate, setTitlePatternToCreate] = React.useState<string>("");

    const [newCustomPersonaName, setNewCustomPersonaName] = React.useState<string>(persona.cleanName || "");

    const searchId = React.useMemo(() => {
      return search?.id;
    }, [search?.id]);

    // this keeps track of all the patterns that are currently selected (only saves the IDs)
    const [selectedIDs, setSelectedIDs] = React.useState(
      allPossibleTitlesFormatted2?.map((pattern: TitlePatternIDsRequired) => {
        return pattern.id;
      })
    );

    //////////////////////////////////////////////
    // Pattern loading / initial state useEffect /
    //////////////////////////////////////////////

    // once the title patterns are loaded, we need to format them into lists of TitlePatternIDsRequired
    // that our UI can use, and build our list of Selected IDS (which determines which checkboxes are checked)
    React.useEffect(() => {
      const allInitialFetchedPatterns: TitlePatternIDsRequired[] | undefined = titlePatternsForPersona?.map(
        (pattern: TitlePatternInfo) => ({
          pattern: pattern.patternName,
          id: pattern.id,
          allowSoftMatch: pattern.allowSoftMatch,
        })
      );

      setAllPossibleTitlesFormatted2(allInitialFetchedPatterns);

      const defaultPatterns =
        titlePatternsForPersona?.filter((pattern: TitlePatternInfo) => {
          return pattern.isDefault;
        }) || [];

      setAllDefaultPatterns(
        defaultPatterns.map((pattern: TitlePatternInfo) => ({
          pattern: pattern.patternName,
          id: pattern.id,
          allowSoftMatch: pattern.allowSoftMatch,
        })) || []
      );

      const nonDefaultPatterns =
        titlePatternsForPersona?.filter((pattern: TitlePatternInfo) => {
          return !pattern.isDefault;
        }) || [];

      setAllNonDefaultPatterns(
        nonDefaultPatterns.map((pattern: TitlePatternInfo) => ({
          pattern: pattern.patternName,
          id: pattern.id,
          allowSoftMatch: pattern.allowSoftMatch,
        })) || []
      );
      // only want to set the selected IDs as IDs that are supposed to be selected
      const initiallySelectedIDs =
        titlePatternsForPersona
          ?.filter((pattern: TitlePatternInfo) => {
            return pattern.selected;
          })
          .map((pattern: TitlePatternInfo) => {
            return pattern.id;
          }) || [];
      setSelectedIDs(initiallySelectedIDs);
      setAllInitiallySelectedIDs(initiallySelectedIDs);

      // if there are no ML titles, then the switch should be off to show ML titles are disabled
      setMLToggleIsOn(persona.paramsJson.targetMlTitles.length > 0 || persona.paramsJson.excludedMlTitles.length > 0);

      // if you create a new persona and reopen the modal, it will remember what the cleanName used to be.
      // but the backend adds (Custom) at the end so we need to reload it properly here to reflect that.
      // no real risk in this because the textfield should be disabled anyway.
      setNewCustomPersonaName(persona.cleanName || "");
    }, [persona.cleanName, persona.paramsJson, titlePatternsForPersona]);

    const failedLoadingCondition = React.useMemo((): boolean => {
      return (
        allPossibleTitlesFormatted2 === undefined ||
        titlePatternOptions === undefined ||
        allDefaultPatterns === undefined ||
        allNonDefaultPatterns === undefined
      );
    }, [allDefaultPatterns, allNonDefaultPatterns, allPossibleTitlesFormatted2, titlePatternOptions]);

    // variable which checks if there are no customizations yet. if not, disable the save button
    const noCustomizationsYet = React.useMemo((): boolean => {
      const initialMLSwitch =
        persona.paramsJson.targetMlTitles.length > 0 || persona.paramsJson.excludedMlTitles.length > 0;
      const currentMLSwitch = mLToggleIsOn;
      const changesInMLToggle = initialMLSwitch !== currentMLSwitch;

      const changesinName = newCustomPersonaName !== persona.cleanName;

      return !!(
        selectedIDs?.every(patternId => allInitiallySelectedIDs?.includes(patternId)) &&
        allInitiallySelectedIDs?.length === selectedIDs?.length &&
        !changesInMLToggle &&
        !changesinName
      );
    }, [
      persona.paramsJson.targetMlTitles.length,
      persona.paramsJson.excludedMlTitles.length,
      persona.cleanName,
      mLToggleIsOn,
      newCustomPersonaName,
      selectedIDs,
      allInitiallySelectedIDs,
    ]);

    const shouldShowMLTitlesToggle = React.useMemo((): boolean => {
      // if our persona has ML titles, or if the parent has ML titles, we should show the switch

      return (
        persona.paramsJson.targetMlTitles.length > 0 ||
        persona.paramsJson.excludedMlTitles.length > 0 ||
        !!persona.parentHasMlTitles
      );
    }, [
      persona.paramsJson.targetMlTitles.length,
      persona.paramsJson.excludedMlTitles.length,
      persona.parentHasMlTitles,
    ]);

    ////////////////////////////
    // Save new title patterns /
    ////////////////////////////

    const onModifyTitlesSuccess = React.useCallback(
      async (modifyTitlesResponse: ModifyTitlePatternsInSearchResponse): Promise<void> => {
        // function to deal with a successful response from the modifyTitles API call. updates the personas section in SaaP
        // and removes the ML Titles section if necessary
        showSuccessToast("Saved title preferences!");
        const oldPersonaToNewPersona = modifyTitlesResponse.oldPersonaToNewPersona;
        const newPersonaIDsForForm = personas.map((persona: PersonaIDsRequired) => {
          if (persona.id == oldPersonaToNewPersona.oldPersonaId && oldPersonaToNewPersona.newPersonaId) {
            return oldPersonaToNewPersona.newPersonaId;
          }
          return persona.id;
        });
        // have to make a new API call because the personasOptions is out of date after an update
        const newPersonasInForm = await convertPersonaIDToFormValueAsync(newPersonaIDsForForm, lazyListPersonas);

        setValue(PERSONAS_ELEMENTS_NAME, newPersonasInForm);
      },
      [lazyListPersonas, personas, setValue]
    );

    const buildTitlePatternInfo = React.useCallback((): TitlePatternInfo[] => {
      // function which creates the TitlePatternInfo objects that are used to update the persona
      return allPossibleTitlesFormatted2!.map((pattern: TitlePatternIDsRequired) => {
        return {
          id: pattern.id,
          selected: selectedIDs!.includes(pattern.id),
          isDefault: allDefaultPatterns!
            .map((defaultPattern: TitlePatternIDsRequired) => defaultPattern.id)
            .includes(pattern.id),
          patternName: pattern.pattern,
          allowSoftMatch: pattern.allowSoftMatch ?? CUSTOM_TITLE_PATTERN_DEFAULT_SOFT_MATCH,
        };
      });
    }, [allDefaultPatterns, allPossibleTitlesFormatted2, selectedIDs]);

    const modifyTitlePatternsInSearchWrapper = React.useCallback(
      async (finalTitlePatternInfo: TitlePatternInfo[]): Promise<ModifyTitlePatternsInSearchResponse> => {
        return await modifyTitlePatternsInSearch({
          id: searchId!, // can assert ! because we checked searchID before calling this function
          data: {
            titlePatternsInfo: finalTitlePatternInfo,
            requestedPersonaId: persona.id,
            newPersonaName: newCustomPersonaName,
            keepMlTitles: mLToggleIsOn,
          } as ModifyTitlePatternsInSearchRequest,
        }).unwrap();
      },
      [modifyTitlePatternsInSearch, newCustomPersonaName, persona.id, searchId, mLToggleIsOn]
    );

    const onSave = React.useCallback(async (): Promise<void> => {
      if (!searchId) {
        return;
      }
      // we also want to make sure we have info that can be used to update the persona, ie lists aren't undefined
      if (failedLoadingCondition) {
        return;
      }

      // we want to build an object where we take the pattern ID, whether it is selected, and whether it is default
      const finalTitlePatternInfo = buildTitlePatternInfo();

      // make the API call to modify the title patterns/update the persona in the search
      const modifyTitlesResponse: ModifyTitlePatternsInSearchResponse = await modifyTitlePatternsInSearchWrapper(
        finalTitlePatternInfo
      );

      if (modifyTitlesResponse.success) {
        onModifyTitlesSuccess(modifyTitlesResponse);
      } else {
        showErrorToast("Failed to save title preferences. Please refresh and try again.");
      }
      toggleModalOff();
    }, [
      buildTitlePatternInfo,
      failedLoadingCondition,
      modifyTitlePatternsInSearchWrapper,
      onModifyTitlesSuccess,
      searchId,
      toggleModalOff,
    ]);

    /////////////////////////////
    // Pattern addition helpers /
    /////////////////////////////

    const addOrRemoveIDFromSelectedIDs = React.useCallback(
      (id: number): void => {
        if (selectedIDs === undefined) {
          return;
        }
        // function which selects a title pattern if it wasn't already selected, and deselects it if it was already selected
        if (selectedIDs.includes(id)) {
          setSelectedIDs(selectedIDs.filter((selectedID: number) => selectedID !== id));
        } else {
          setSelectedIDs([...selectedIDs, id]);
        }
      },
      [selectedIDs]
    );

    const addTitlePatternsToSelectedIDs = React.useCallback(
      (patterns: TitlePatternIDsRequired[]): void => {
        // function which conditionally adds the new patterns to the selectedIDs, allPossibleTitlesFormatted2, and nonDefaultPatternsToAdd
        // any pattern that isnt part of the allPossibleTitlesFormatted2 is new.
        if (failedLoadingCondition) {
          return;
        }
        const patternsToAdd = patterns.filter(
          (pattern: TitlePatternIDsRequired) =>
            !allPossibleTitlesFormatted2!.some(formattedTitle => formattedTitle.id === pattern.id)
        );
        if (patternsToAdd.length) {
          // so we should add them to the list of all Options, as well as select it.
          setAllPossibleTitlesFormatted2([...allPossibleTitlesFormatted2!, ...patternsToAdd]);
          setSelectedIDs([...selectedIDs!, ...patternsToAdd.map(pattern => pattern.id)]);
        }
        const nonDefaultPatternsToAdd = patternsToAdd.filter(
          (pattern: TitlePatternIDsRequired) =>
            !allDefaultPatterns!.some(defaultPattern => defaultPattern.id === pattern.id) &&
            !allNonDefaultPatterns!.some(nonDefaultPattern => nonDefaultPattern.id === pattern.id)
        );
        if (nonDefaultPatternsToAdd.length) {
          // if it is a nonDefault, we should add it to the list of nondefaults too
          setAllNonDefaultPatterns([...(allNonDefaultPatterns || []), ...nonDefaultPatternsToAdd]);
        }
      },
      [allDefaultPatterns, allNonDefaultPatterns, allPossibleTitlesFormatted2, failedLoadingCondition, selectedIDs]
    );

    const addPatternsAfterSelection = React.useCallback(
      (patternsToAdd: TitlePatternIDsRequired[] | null): void => {
        // function which will select a new pattern, and ignore all previously added/selected ones.
        if (!patternsToAdd || !patternsToAdd.length) {
          return;
        }
        addTitlePatternsToSelectedIDs(patternsToAdd);
      },
      [addTitlePatternsToSelectedIDs]
    );

    const createTitlePatternWrapper = React.useCallback(async (): Promise<void> => {
      const createTitleResponse: TitlePatternIDsRequired | null = await createTitlePattern(
        titlePatternToCreate
      ).unwrap();

      if (createTitleResponse) {
        addTitlePatternsToSelectedIDs([createTitleResponse]);
      } else {
        showErrorToast("Failed to create a new title. Please refresh and try again.");
      }
      setTitlePatternToCreate("");
    }, [addTitlePatternsToSelectedIDs, createTitlePattern, titlePatternToCreate]);

    const onDeletePattern = React.useCallback(
      (patternID: number): void => {
        // remove it from selectedIDs, allPossibleTitlesFormatted2, and allNonDefaultPatterns
        if (selectedIDs?.includes(patternID)) {
          setSelectedIDs(selectedIDs.filter((selectedID: number) => selectedID !== patternID));
        }
        if (allPossibleTitlesFormatted2?.some(formattedTitle => formattedTitle.id === patternID)) {
          setAllPossibleTitlesFormatted2(
            allPossibleTitlesFormatted2.filter(
              (formattedTitle: TitlePatternIDsRequired) => formattedTitle.id !== patternID
            )
          );
        }
        if (allNonDefaultPatterns?.some(nonDefaultPattern => nonDefaultPattern.id === patternID)) {
          setAllNonDefaultPatterns(
            allNonDefaultPatterns.filter(
              (nonDefaultPattern: TitlePatternIDsRequired) => nonDefaultPattern.id !== patternID
            )
          );
        }
      },
      [allNonDefaultPatterns, allPossibleTitlesFormatted2, selectedIDs]
    );

    //////////////////////////
    // Actual react elements /
    //////////////////////////

    const buildTitlePatternOption = React.useCallback(
      (pattern: TitlePatternIDsRequired, defaultPattern: boolean): React.ReactElement => {
        // function which builds the list of all title patterns to display (used once with default and once with non default patterns)

        // eslint-disable-next-line react/no-unstable-nested-components
        const TitlePatternAndX = (): React.ReactElement => {
          // only want to add the X if it is a non default pattern (cant remove defaults)
          if (defaultPattern) {
            return <Body>{pattern.pattern}</Body>;
          }
          return (
            <Stack direction="row" alignItems="center" width="80%" justifyContent="space-between">
              <Body>{pattern.pattern}</Body>
              <Box
                onClick={(): void => onDeletePattern(pattern.id)}
                sx={{ ":hover": { cursor: "pointer" } }}
                height="18px"
                width="18px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ border: `1px solid ${colors.grayscale.gray400}`, borderRadius: "3px" }}
              >
                <RedXIconSVG className="svg-fill" color={colors.grayscale.gray400} />
              </Box>
            </Stack>
          );
        };

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox
              sx={{
                pl: "0px",
                color: colors.grayscale.gray400,
                "&.Mui-checked": {
                  color: colors.primary.base,
                },
                ":hover": {
                  backgroundColor: "transparent",
                },
              }}
              checked={selectedIDs?.includes(pattern.id)}
              onChange={(): void => {
                addOrRemoveIDFromSelectedIDs(pattern.id);
              }}
            />
            <TitlePatternAndX />
          </Stack>
        );
      },
      [addOrRemoveIDFromSelectedIDs, onDeletePattern, selectedIDs]
    );

    const defaultPatternsSection = React.useMemo((): React.ReactElement => {
      if (!allDefaultPatterns?.length) {
        return <></>;
      }
      return (
        <>
          <Box padding="10px 0px">
            <Overline>Defaults</Overline>
          </Box>
          <Stack>
            {allDefaultPatterns.map((pattern: TitlePatternIDsRequired) => {
              return buildTitlePatternOption(pattern, true);
            })}
          </Stack>
        </>
      );
    }, [allDefaultPatterns, buildTitlePatternOption]);

    const nonDefaultPatternsSection = React.useMemo((): React.ReactElement => {
      if (!allNonDefaultPatterns?.length) {
        return <></>;
      }
      return (
        <>
          <Box padding="10px 0px">
            <Overline>Added Patterns</Overline>
          </Box>
          <Stack>
            {allNonDefaultPatterns.map((pattern: TitlePatternIDsRequired) => {
              return buildTitlePatternOption(pattern, false);
            })}
          </Stack>
        </>
      );
    }, [allNonDefaultPatterns, buildTitlePatternOption]);

    const noPatternsExist = React.useMemo((): boolean => {
      return !allDefaultPatterns?.length && !allNonDefaultPatterns?.length;
    }, [allDefaultPatterns, allNonDefaultPatterns]);

    const noPatternsExistSection = React.useMemo((): React.ReactElement => {
      if (noPatternsExist) {
        return (
          <BodySmall color={colors.critical.base}>No default patterns exist for this persona. Add your own!</BodySmall>
        );
      }
      return <></>;
    }, [noPatternsExist]);

    ///////////////////
    // Final Modal UI /
    ///////////////////

    const innerModalContentsFailure = React.useMemo((): React.ReactElement => {
      return <DoverLoadingSpinner />;
    }, []);

    const personaNameErrorMessage = React.useMemo(() => {
      if (!newCustomPersonaName.length) {
        return "Please enter a persona name";
      } else if (newCustomPersonaName === "Custom") {
        return "Please enter a persona name other than 'Custom'";
      }
      return undefined;
    }, [newCustomPersonaName]);

    const innerModalContentsSuccess = React.useMemo((): React.ReactElement => {
      return (
        <Stack spacing={1}>
          <Box paddingBottom="20px">
            <TextField
              sx={{
                ".MuiOutlinedInput-root.MuiInputBase-root": {
                  outline: "none",
                  border: "none",
                },
                width: "100%",
              }}
              label="Enter persona name..."
              error={!!personaNameErrorMessage}
              helperText={personaNameErrorMessage}
              value={newCustomPersonaName}
              onChange={(event): void => {
                setNewCustomPersonaName(event.target.value);
              }}
            />
          </Box>

          {isTitlePatternsForPersonaLoading && <DoverLoadingSpinner />}
          <Box maxHeight="350px" overflow="scroll">
            {defaultPatternsSection}
            {nonDefaultPatternsSection}
          </Box>
          <Stack paddingTop="20px" direction="row" width="100%">
            <Box width="100%">
              <NoValuesAutocomplete
                getOptionLabel={(option: TitlePatternIDsRequired): string => option.pattern}
                renderOption={(props: any, option: TitlePatternIDsRequired): React.ReactElement => {
                  return (
                    <li {...props} key={option.id}>
                      {option.pattern}
                    </li>
                  );
                }}
                options={titlePatternOptions ?? []}
                optionsLoading={isTitlePatternsLoading}
                placeholder={"Select a title..."}
                onSelect={addPatternsAfterSelection}
                onTextChange={setTitlePatternToCreate}
                initialValues={allPossibleTitlesFormatted2}
                filterSelectedOptions={false}
                isOptionEqualToValue={(option: TitlePatternIDsRequired, value: TitlePatternIDsRequired): boolean => {
                  return option.id === value.id;
                }}
                noOptionsText={
                  !isCreateTPLoading && !!clientId ? (
                    <TitlePatternAdder createTitlePattern={createTitlePatternWrapper} />
                  ) : (
                    "No titles found..."
                  )
                }
                textFieldValue={titlePatternToCreate}
                shouldSort={true}
              />
            </Box>
            <Button variant={ButtonVariant.Secondary} onClick={createTitlePatternWrapper} removeOutline>
              Add
            </Button>
          </Stack>
          {noPatternsExistSection}
          {shouldShowMLTitlesToggle && (
            <Stack direction="row" alignItems="center">
              <Box paddingRight="10px">
                <BodySmall>AI title expansion</BodySmall>
              </Box>
              <StyledSwitch
                checked={mLToggleIsOn}
                onChange={(): void => {
                  setMLToggleIsOn(!mLToggleIsOn);
                }}
              />
              <Tooltip title="Broadens your search to include candidates with similar, but not identical, job titles.">
                <InfoIconSVG />
              </Tooltip>
            </Stack>
          )}
        </Stack>
      );
    }, [
      personaNameErrorMessage,
      newCustomPersonaName,
      isTitlePatternsForPersonaLoading,
      noPatternsExistSection,
      defaultPatternsSection,
      nonDefaultPatternsSection,
      titlePatternOptions,
      isTitlePatternsLoading,
      addPatternsAfterSelection,
      allPossibleTitlesFormatted2,
      isCreateTPLoading,
      clientId,
      createTitlePatternWrapper,
      titlePatternToCreate,
      shouldShowMLTitlesToggle,
      mLToggleIsOn,
    ]);

    const saveButton = React.useMemo((): React.ReactElement => {
      const actualButton = (
        <Button
          variant={ButtonVariant.Primary}
          onClick={onSave}
          disabled={!!personaNameErrorMessage || noCustomizationsYet || apiClient === undefined || noPatternsExist}
        >
          Save
        </Button>
      );

      if (noCustomizationsYet || personaNameErrorMessage) {
        const tooltipText = noCustomizationsYet
          ? "You must add job titles in order to save."
          : personaNameErrorMessage!;

        return (
          <Tooltip title={tooltipText} arrow={true} placement="top" variant={TooltipVariant.Dark}>
            {/* Span is necessary to prevent a MUI v5 render issue */}
            <span>{actualButton}</span>
          </Tooltip>
        );
      }

      return actualButton;
    }, [onSave, personaNameErrorMessage, noCustomizationsYet, apiClient, noPatternsExist]);

    return (
      <CustomModal
        open={isModalOpen}
        onClose={toggleModalOff}
        title={<Subtitle1 weight="600">{"Modify Titles"}</Subtitle1>}
        maxWidth={"xs"}
        omitDividers={true}
        dialogActions={
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Button variant={ButtonVariant.Secondary} onClick={toggleModalOff}>
                Cancel
              </Button>
              {saveButton}
            </Stack>
          </Stack>
        }
      >
        {failedLoadingCondition ? innerModalContentsFailure : innerModalContentsSuccess}
      </CustomModal>
    );
  }
);
