/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TalentNetwork
 */
export interface TalentNetwork {
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    latestTitleAtLatestCompany?: string;
    /**
     * 
     * @type {number}
     * @memberof TalentNetwork
     */
    totalYoe?: number;
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    resumePath?: string;
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    inboundAppId: string;
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    personId: string;
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    candidateLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    linkedinUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    cleanFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof TalentNetwork
     */
    email: string;
}

export function TalentNetworkFromJSON(json: any): TalentNetwork {
    return TalentNetworkFromJSONTyped(json, false);
}

export function TalentNetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): TalentNetwork {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'latestTitleAtLatestCompany': !exists(json, 'latest_title_at_latest_company') ? undefined : json['latest_title_at_latest_company'],
        'totalYoe': !exists(json, 'total_yoe') ? undefined : json['total_yoe'],
        'resumePath': !exists(json, 'resume_path') ? undefined : json['resume_path'],
        'inboundAppId': json['inbound_app_id'],
        'personId': json['person_id'],
        'candidateLocation': !exists(json, 'candidate_location') ? undefined : json['candidate_location'],
        'linkedinUrl': !exists(json, 'linkedin_url') ? undefined : json['linkedin_url'],
        'cleanFirstName': !exists(json, 'clean_first_name') ? undefined : json['clean_first_name'],
        'email': json['email'],
    };
}

export function TalentNetworkToJSON(value?: TalentNetwork | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full_name': value.fullName,
        'latest_title_at_latest_company': value.latestTitleAtLatestCompany,
        'total_yoe': value.totalYoe,
        'resume_path': value.resumePath,
        'inbound_app_id': value.inboundAppId,
        'person_id': value.personId,
        'candidate_location': value.candidateLocation,
        'linkedin_url': value.linkedinUrl,
        'clean_first_name': value.cleanFirstName,
        'email': value.email,
    };
}


