import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtom } from "jotai";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import { FormProvider, useController, useForm, useFormContext, useFormState, useWatch } from "react-hook-form";

import { ReactComponent as AIWandSVG } from "assets/icons/ai-wand-gradient.svg";
import { Autocomplete } from "components/library/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonRadio } from "components/library/ButtonRadio";
import { TextField } from "components/library/TextField";
import { Body, BodySmall, Subtitle2 } from "components/library/typography";
import YOESlider, { YearsOfExperienceRange } from "components/library/YOESlider";
import DoverLoadingOverlay from "components/loading-overlay";
import SpicyLoading from "components/loading-overlay/SpicyLoader";
import {
  useGenerateJobDescriptionMutation,
  useGetJobDescriptionQuery,
} from "services/doverapi/endpoints/job-description/endpoints";
import {
  useGetOrCreateKeywordWithNameMutation,
  useLazyListProfileSearchKeywordsQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { DoverJobDescription, ProfileSearchKeywordSerializer } from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import { sharedCriteriaAtom } from "views/create-job/ApplicantSortingSetupFlow/atoms";
import {
  CreateJobDescriptionSchema,
  CreateJobDescriptionSchemaFormType,
  initialCreateJobDescriptionFormValues,
} from "views/create-job/CreateJob/constants";
import JobLocationFields, {
  useGetJobLocationsFromFieldValues,
} from "views/create-job/shared-steps/components/JobLocationFields";
import EditJobDescription from "views/create-job/shared-steps/EditJobDescription";
import { shouldShowInOfficeCities, shouldShowRemoteRegions } from "views/job/utils";
import { YEARS_OF_EXPERIENCE_MAX, YEARS_OF_EXPERIENCE_ALLOWED_RANGE } from "views/sourcing/Search/constants";

const GENERATING_JD_SPICY_LOADING_OPTIONS = [
  "Generating job description...",
  "Attempting to sound professional and important...",
  "Making sure it's not too short...",
  "Wrangling words into a pleasing order...",
  "Retrieving buzzwords from the cloud...",
];

const YEARS_OF_EXPERIENCE_FORM_NAME = "totalYearsOfExperience.yearsOfExperience";

const KEYWORDS_BUCKET_NAME = "keywordsBucket.bucket";

const deduceNoNewOptions = (
  fetchResponse: ProfileSearchKeywordSerializer[],
  selectedCanonicalKeywords: string[]
): boolean => {
  // function to determine if the keyword request does not have any matches
  const noNewOptions = fetchResponse.every((keyword: ProfileSearchKeywordSerializer): boolean =>
    selectedCanonicalKeywords.includes(keyword.canonicalKeyword)
  );
  return noNewOptions;
};

const KeywordAdder = ({
  customKeywordString,
  getOrCreateKeyword,
}: {
  customKeywordString: string;
  getOrCreateKeyword: () => Promise<void>;
}): React.ReactElement => {
  // shared component for adding keywords
  return (
    <Box sx={{ cursor: "pointer" }} onClick={getOrCreateKeyword}>
      <Body>{`+ Add ${customKeywordString} keyword`}</Body>
    </Box>
  );
};

interface KeywordsAutocompleteProps {
  customKeywordString: string;
  selectedCanonicalKeywords: string[];
  isCreateKeywordLoading: boolean;
  initialValue: ProfileSearchKeywordSerializer[];
  fetchKeywords: (request: string) => Promise<ProfileSearchKeywordSerializer[]>;
  onFetchCompleted: (customKeywordString: string, fetchResponse: ProfileSearchKeywordSerializer[]) => void;
  getOrCreateKeyword: () => Promise<void>;
  onKeywordsSelectionChange: (newKeywords: ProfileSearchKeywordSerializer[]) => Promise<void>;
}

export const KeywordsAutocomplete = ({
  customKeywordString,
  selectedCanonicalKeywords,
  isCreateKeywordLoading,
  fetchKeywords,
  onFetchCompleted,
  getOrCreateKeyword,
  onKeywordsSelectionChange,
  initialValue,
}: KeywordsAutocompleteProps): React.ReactElement => {
  return (
    <Autocomplete
      fontSize="small"
      placeholder={"Enter a keyword..."}
      noOptionsText={
        !isCreateKeywordLoading && !!customKeywordString.length ? (
          <KeywordAdder customKeywordString={customKeywordString} getOrCreateKeyword={getOrCreateKeyword} />
        ) : (
          "Start typing to see keywords"
        )
      }
      fetch={fetchKeywords}
      onFetchCompleted={onFetchCompleted}
      filterSelectedOptions={true}
      getOptionLabel={(option: ProfileSearchKeywordSerializer): string => option.friendlyName}
      getOptionDisabled={(option: ProfileSearchKeywordSerializer): boolean =>
        selectedCanonicalKeywords.includes(option.canonicalKeyword)
      }
      onSelectedOptionsChange={onKeywordsSelectionChange}
      initialValues={initialValue}
      multiple={true}
    />
  );
};

const KeywordsSelector = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<CreateJobDescriptionSchemaFormType>();
    const [customKeywordString, setCustomKeywordString] = useState<string>("");

    const [lazyListKeywords] = useLazyListProfileSearchKeywordsQuery();
    const [getOrCreateKeywordWithName, { isLoading: isCreateKeywordLoading }] = useGetOrCreateKeywordWithNameMutation();

    const keywords: ProfileSearchKeywordSerializer[] = useWatch({
      control,
      name: KEYWORDS_BUCKET_NAME,
    });

    const selectedCanonicalKeywords = React.useMemo(() => {
      return keywords.map(keyword => keyword.canonicalKeyword);
    }, [keywords]);

    const fetchKeywords = async (request: string): Promise<ProfileSearchKeywordSerializer[]> => {
      const keywordsResponse = await lazyListKeywords({ limit: 50, queryText: request }).unwrap();

      // return keywordsResponse sorted first by if they haven't already been added, then by friendlyName
      return sortBy(keywordsResponse, [
        (k): boolean => selectedCanonicalKeywords.includes(k.canonicalKeyword),
        "friendlyName",
      ]);
    };

    const onFetchCompleted = (customKeywordString: string, fetchResponse: ProfileSearchKeywordSerializer[]): void => {
      const noNewOptions = deduceNoNewOptions(fetchResponse, selectedCanonicalKeywords);
      if (noNewOptions && customKeywordString.length) {
        // we are searching for something, but fetch is returning the same thing as before we started typing
        // -> this keyword doesnt exist
        setCustomKeywordString(customKeywordString);
      } else {
        setCustomKeywordString("");
      }
    };

    const getOrCreateKeyword = async (): Promise<void> => {
      if (!customKeywordString.length) {
        return;
      }
      // call API to create keyword
      getOrCreateKeywordWithName(customKeywordString)
        .unwrap()
        .then(newKeyword => {
          // add this keyword to the FormState via a SetValue
          setValue(KEYWORDS_BUCKET_NAME, [...keywords, newKeyword]);
          // clear the customKeywordString
          setCustomKeywordString("");
        });
    };

    const onKeywordsSelectionChange = React.useCallback(
      async (newKeywords: ProfileSearchKeywordSerializer[]): Promise<void> => {
        if (newKeywords.length > keywords.length) {
          const newKeyword = newKeywords[newKeywords.length - 1];
          setValue(KEYWORDS_BUCKET_NAME, [...keywords, newKeyword]);
        } else if (newKeywords.length < keywords.length) {
          // if a keyword is deleted, just update the form state
          setValue(KEYWORDS_BUCKET_NAME, newKeywords);
        }
      },
      [keywords, setValue]
    );

    return (
      <KeywordsAutocomplete
        customKeywordString={customKeywordString}
        selectedCanonicalKeywords={selectedCanonicalKeywords}
        isCreateKeywordLoading={isCreateKeywordLoading}
        initialValue={keywords ?? []}
        fetchKeywords={fetchKeywords}
        onFetchCompleted={onFetchCompleted}
        getOrCreateKeyword={getOrCreateKeyword}
        onKeywordsSelectionChange={onKeywordsSelectionChange}
      />
    );
  }
);

const GenerateJobDescriptionForm = ({
  jobId,
  setGeneratedJobDescription,
  variant,
}: {
  jobId?: string;
  setGeneratedJobDescription: (value: DoverJobDescription) => void;
  variant?: "page" | "drawer";
}): React.ReactElement => {
  const [sharedCriteria, setSharedCriteria] = useAtom(sharedCriteriaAtom);

  // TODO: subscribe to this to check isSuccess in JobDescriptionStep
  const [generateJobDescription, { isLoading: isGeneratingJobDescription }] = useGenerateJobDescriptionMutation({
    fixedCacheKey: "generateJobDescription",
  });

  // RHF setup
  const { control, setValue, handleSubmit, getValues } = useFormContext<CreateJobDescriptionSchemaFormType>();

  const totalYearsOfExperienceFormValue = useWatch({ control, name: YEARS_OF_EXPERIENCE_FORM_NAME });

  const { errors } = useFormState({ control });

  const { field: remotePolicyField } = useController({
    name: "remotePolicy",
    control,
  });

  const { field: remoteRegionsField } = useController({
    name: "remoteRegions",
    control,
  });

  const { field: onsiteCitiesField } = useController({
    name: "onsiteCities",
    control,
  });

  const { field: freeTextField } = useController({
    name: "freeText",
    control,
  });

  // Dynamically show certain form inputs based on the remote policy
  const showRemoteRegions = remotePolicyField.value
    ? shouldShowRemoteRegions({ remotePolicy: remotePolicyField.value })
    : false;
  const showOnsiteCities = remotePolicyField.value ? shouldShowInOfficeCities(remotePolicyField.value) : false;

  const remoteRegionsError = React.useMemo(() => {
    if (!showRemoteRegions || !!remoteRegionsField?.value?.length) {
      return undefined;
    }
    return "Please select at least one region";
  }, [remoteRegionsField?.value?.length, showRemoteRegions]);

  const onsiteCitiesError = React.useMemo(() => {
    if (!showOnsiteCities || !!onsiteCitiesField?.value?.length) {
      return undefined;
    }
    return "Please select at least one city";
  }, [onsiteCitiesField?.value?.length, showOnsiteCities]);

  const disableSubmit = remoteRegionsError || onsiteCitiesError || isGeneratingJobDescription;

  const handleYearsOfExperienceSliderChange = React.useCallback(
    (value: YearsOfExperienceRange) => {
      setValue(YEARS_OF_EXPERIENCE_FORM_NAME, {
        min: value.min ?? undefined,
        max: value.max ?? undefined,
      });
    },
    [setValue]
  );

  const locations = useGetJobLocationsFromFieldValues();

  const onSubmit = React.useCallback(async (): Promise<void> => {
    const createJobAndGenerateJd = async (): Promise<void> => {
      const values = getValues();

      if (jobId) {
        try {
          const result = await generateJobDescription({
            data: {
              jobId,
              yearsOfExperience: values.totalYearsOfExperience.yearsOfExperience,
              locations: locations,
              skills: values.keywordsBucket.bucket.map(keyword => keyword.canonicalKeyword),
              additionalDetails: values.freeText,
            },
          });
          if (result && "data" in result) {
            setGeneratedJobDescription(result.data.jobDescription);
            setSharedCriteria({
              persona: sharedCriteria?.persona,
              jobDescription: result.data.jobDescription.userFacingDescription,
              yearsOfExperience: values.totalYearsOfExperience.yearsOfExperience,
              locations: locations.map(location => ({ value: location.name, name: location.name })),
              keywords: values.keywordsBucket.bucket,
            });
          }
        } catch (e) {
          showErrorToast("Failed to generate job description. Please refresh and try again.");
        }
      }
    };
    // await creating a Job here and pass its id to generateJobDescription
    await createJobAndGenerateJd();
  }, [
    generateJobDescription,
    getValues,
    jobId,
    locations,
    setGeneratedJobDescription,
    setSharedCriteria,
    sharedCriteria?.persona,
  ]);

  const onErrors = (): void => {
    console.log("errors", errors);
  };

  if (isGeneratingJobDescription) {
    return (
      <Box
        position="absolute"
        width="100%"
        // 66px is the height of the StepHeader in the Create Job drawer
        height={variant === "drawer" ? "calc(100vh - 66px)" : "100%"}
        // 50px is the height of the StepHeader - 16px of margin in the container for the drawer
        // in the page variant, accomodate 16px top margin in parent container
        top={variant === "drawer" ? "50px" : "-16px"}
        left="0"
        sx={{ backgroundColor: "white" }}
      >
        <SpicyLoading options={GENERATING_JD_SPICY_LOADING_OPTIONS} />
      </Box>
    );
  }

  return (
    <DoverLoadingOverlay active={isGeneratingJobDescription}>
      <Stack spacing={2} id="create-job-description-scroll-container" position="relative">
        <Box sx={{ backgroundColor: colors.grayscale.gray100, borderRadius: "6px" }} p={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <BodySmall>✏️</BodySmall>
            <BodySmall>Just answer a few questions about the job and we’ll create one for you.</BodySmall>
          </Stack>
        </Box>
        <Subtitle2>Years of experience</Subtitle2>
        <YOESlider
          values={totalYearsOfExperienceFormValue}
          onChange={handleYearsOfExperienceSliderChange}
          maximumValue={YEARS_OF_EXPERIENCE_MAX}
          minimumRange={YEARS_OF_EXPERIENCE_ALLOWED_RANGE}
          shouldShowTooltip={true}
          scrollContainerId={"create-job-description-scroll-container"}
        />
        <JobLocationFields />
        <Stack spacing={0.5}>
          <Subtitle2>Job requirements and skills</Subtitle2>
          <KeywordsSelector />
        </Stack>
        <Stack spacing={1.5}>
          <Subtitle2>Include anything else about the role</Subtitle2>
          <TextField
            minRows={4}
            multiline={true}
            text={freeTextField.value}
            onTextUpdated={(value): void => freeTextField.onChange(value)}
          />
        </Stack>
        <Stack alignItems="flex-end" width="100%" sx={{ backgroundColor: colors.white }} py={2}>
          <Button
            width="fit-content"
            variant={ButtonVariant.Primary}
            onClick={handleSubmit(onSubmit, onErrors)}
            disabled={!!disableSubmit}
          >
            {variant === "page" ? "Save and preview" : "Generate job description"}
          </Button>
        </Stack>
      </Stack>
    </DoverLoadingOverlay>
  );
};

interface CreateJobDescriptionProps {
  jobId?: string;
  goNext: () => Promise<void> | void;
  variant?: "page" | "drawer";
  updatedJobTitle?: string;
  skipPreview?: boolean;
}

const CreateJobDescription = ({
  jobId,
  goNext,
  variant = "drawer",
  updatedJobTitle,
  skipPreview = false,
}: CreateJobDescriptionProps): React.ReactElement => {
  // RHF setup
  const formMethods = useForm<CreateJobDescriptionSchemaFormType>({
    resolver: zodResolver(CreateJobDescriptionSchema),
    defaultValues: initialCreateJobDescriptionFormValues,
  });

  const [hasExistingJd, setHasExistingJd] = useState<boolean | null>(null);
  const [generatedJobDescription, setGeneratedJobDescription] = useState<DoverJobDescription | undefined>(undefined);

  const { data: existingJobDescription } = useGetJobDescriptionQuery(jobId ? { jobId } : skipToken);

  useEffect(() => {
    if (existingJobDescription?.userProvidedDescription) {
      setHasExistingJd(true);
    }
  }, [existingJobDescription]);

  return (
    <FormProvider {...formMethods}>
      <Stack width="100%" spacing={2} p={variant === "drawer" ? 2 : 0} pt={variant === "drawer" ? 3 : 0}>
        {!hasExistingJd && !generatedJobDescription && (
          <>
            <BodySmall weight="600">Do you have a job description?</BodySmall>
            <Stack direction="row" spacing={1}>
              <ButtonRadio
                active={!!hasExistingJd}
                onClick={(): void => setHasExistingJd(true)}
                label="Yes, import it"
              />
              <ButtonRadio
                active={hasExistingJd === false}
                onClick={(): void => setHasExistingJd(false)}
                label={
                  <Stack direction="row" spacing={1} justifyContent="center">
                    <AIWandSVG />
                    <div>No, write one for me</div>
                  </Stack>
                }
              />
            </Stack>
          </>
        )}
        {hasExistingJd !== null && (
          <>
            {hasExistingJd || generatedJobDescription ? (
              <EditJobDescription
                jobId={jobId}
                onSaveAndNext={goNext}
                variant={variant}
                generatedJobDescription={generatedJobDescription}
                updatedJobTitle={updatedJobTitle}
                skipPreview={skipPreview}
              />
            ) : (
              <GenerateJobDescriptionForm
                jobId={jobId}
                setGeneratedJobDescription={setGeneratedJobDescription}
                variant={variant}
              />
            )}
          </>
        )}
      </Stack>
    </FormProvider>
  );
};
export default CreateJobDescription;
