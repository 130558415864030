import { JobLocation, JobLocationLocationTypeEnum } from "services/openapi";

/**
 * Will generate a string to display Job Locations grouped by location type
 *
 * @param jobLocations List of job locations from Job.JobLocations model
 */
export const displayJobLocations = (jobLocations?: JobLocation[]): string => {
  if (!jobLocations?.length) return "Not specified";

  // Group up the locations by location type
  const inOfficeLocations = jobLocations?.filter(
    location => location.locationType === JobLocationLocationTypeEnum.InOffice
  );
  const hybridLocations = jobLocations?.filter(
    location => location.locationType === JobLocationLocationTypeEnum.Hybrid
  );
  const remoteLocations = jobLocations?.filter(
    location => location.locationType === JobLocationLocationTypeEnum.Remote
  );

  // Build the display string
  let locationDisplay = "";

  if (inOfficeLocations?.length) {
    locationDisplay = `In Office (${inOfficeLocations
      ?.map(location => location.locationOption?.displayName)
      .join("; ")})`;
  }

  if (hybridLocations?.length) {
    if (locationDisplay) locationDisplay += " | ";
    locationDisplay += `Hybrid (${hybridLocations?.map(location => location.locationOption?.displayName).join("; ")})`;
  }

  if (remoteLocations?.length) {
    if (locationDisplay) locationDisplay += " | ";
    locationDisplay += `Remote (${remoteLocations?.map(location => location.locationOption?.displayName).join("; ")})`;
  }

  return locationDisplay;
};
