import { createSelector } from "@reduxjs/toolkit";

import { initialState, candidatesReducerKey } from "domains/candidate/reducer";
import { CandidatesReducerState } from "domains/candidate/types";
import { GlobalRootState } from "domains/global/types";

const selectState = (state: GlobalRootState): CandidatesReducerState => state[candidatesReducerKey] || initialState;

export const selectCurrentCandidateId = createSelector([selectState], state => state.currentCandidateId);
export const selectUnsavedCandidateNotes = createSelector([selectState], state => state.unsavedCandidateNotes);
