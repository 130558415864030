import { Box, Stack } from "@mui/material";
import React from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";

import { Autocomplete } from "components/library/Autocomplete";
import { Checkbox } from "components/library/Checkbox";
import { RadioButton } from "components/library/RadioButton";
import { BodySmall, Subtitle2 } from "components/library/typography";
import { useListLocationOptionsQuery } from "services/doverapi/endpoints/job/endpoints";
import { JobLocation, JobLocationLocationTypeEnum, LocationOption } from "services/openapi";
import { colors } from "styles/theme";
import { CreateJobDescriptionSchemaFormType } from "views/create-job/CreateJob/constants";
import { INTERNATIONAL_LOCATION_NAME, RemotePolicies } from "views/job/constants";
import { filterLocationOptions, shouldShowInOfficeCities, shouldShowRemoteRegions } from "views/job/utils";

export const useGetJobLocationsFromFieldValues = (): JobLocation[] => {
  const { control } = useFormContext<CreateJobDescriptionSchemaFormType>();

  const { field: remotePolicyField } = useController({
    name: "remotePolicy",
    control,
  });

  const { field: wfhAllowedField } = useController({
    name: "wfhAllowed",
    control,
  });

  const { field: remoteRegionsField } = useController({
    name: "remoteRegions",
    control,
  });

  const { field: onsiteCitiesField } = useController({
    name: "onsiteCities",
    control,
  });

  const showRemoteRegions = shouldShowRemoteRegions({ remotePolicy: remotePolicyField.value });
  const showOnsiteCities = shouldShowInOfficeCities(remotePolicyField.value);

  // Convert the locations to the correct format for the backend
  // It wants a single array with location type
  const cityLocationType = wfhAllowedField.value
    ? JobLocationLocationTypeEnum.Hybrid
    : JobLocationLocationTypeEnum.InOffice;

  const cityLocations = onsiteCitiesField?.value?.map(city => ({
    name: city.displayName,
    locationOptionId: city.locationOption,
    locationType: cityLocationType,
  }));

  const remoteLocations = remoteRegionsField.value?.map(region => ({
    name: region.displayName,
    locationOptionId: region.locationOption,
    locationType: JobLocationLocationTypeEnum.Remote,
  }));

  // Only send the locations if their type is enabled above
  const locations: JobLocation[] = [];
  showOnsiteCities && cityLocations && locations.push(...cityLocations);
  showRemoteRegions && remoteLocations && locations.push(...remoteLocations);
  return locations;
};

const JobLocationFields = (): React.ReactElement => {
  const { data: locationOptions } = useListLocationOptionsQuery();
  // RHF setup
  const { control } = useFormContext<CreateJobDescriptionSchemaFormType>();

  const { errors } = useFormState({ control });

  const { field: remotePolicyField } = useController({
    name: "remotePolicy",
    control,
  });

  const { field: wfhAllowedField } = useController({
    name: "wfhAllowed",
    control,
  });

  const { field: remoteRegionsField } = useController({
    name: "remoteRegions",
    control,
  });

  const { field: onsiteCitiesField } = useController({
    name: "onsiteCities",
    control,
  });

  const internationalRemoteRegion = React.useMemo(() => {
    return locationOptions?.remoteOptions.find(loc => loc.displayName === INTERNATIONAL_LOCATION_NAME);
  }, [locationOptions?.remoteOptions]);

  // by default, require a specific location, that way when you switch to not requiring it
  // it'll select the international location in the onChange handler for the radio button
  const remoteFromAnywhereOk =
    remoteRegionsField.value?.length === 1 && remoteRegionsField.value[0].displayName === INTERNATIONAL_LOCATION_NAME;
  const requiredSpecific = !remoteFromAnywhereOk;

  const unrequireSpecificLoc = React.useCallback(() => {
    if (!internationalRemoteRegion) {
      console.error("Couldn't find international remote region, early return");
      return;
    }
    const formattedInternationalRegion = {
      displayName: internationalRemoteRegion.displayName,
      locationOption: internationalRemoteRegion.id,
    };
    remoteRegionsField.onChange([formattedInternationalRegion]);
  }, [internationalRemoteRegion, remoteRegionsField]);

  const requireSpecificLoc = (): void => {
    // reset the regions field as they dont even know they selected the "international" one
    remoteRegionsField.onChange([]);
  };

  // Dynamically show certain form inputs based on the remote policy
  const showRemoteRegions = remotePolicyField.value
    ? shouldShowRemoteRegions({
        remotePolicy: remotePolicyField.value,
        // if can't find international location ourselves, then request that they select a specific location
        requireSpecificLoc: requiredSpecific || !internationalRemoteRegion,
      })
    : false;
  const showOnsiteCities = remotePolicyField.value ? shouldShowInOfficeCities(remotePolicyField.value) : false;

  const remoteRegionsError = React.useMemo(() => {
    if (!showRemoteRegions || !!remoteRegionsField?.value?.length) {
      return undefined;
    }
    return "Please select at least one region";
  }, [remoteRegionsField?.value?.length, showRemoteRegions]);

  const onsiteCitiesError = React.useMemo(() => {
    if (!showOnsiteCities || !!onsiteCitiesField?.value?.length) {
      return undefined;
    }
    return "Please select at least one city";
  }, [onsiteCitiesField?.value?.length, showOnsiteCities]);

  return (
    <>
      <Stack spacing={1}>
        <Subtitle2>What is your remote policy for this job?</Subtitle2>
        <Stack direction="row" spacing={2}>
          <RadioButton
            value={RemotePolicies.InOffice}
            checked={remotePolicyField.value === RemotePolicies.InOffice}
            onChange={(): void => {
              remotePolicyField.onChange(RemotePolicies.InOffice);
            }}
            content={<BodySmall>In-office</BodySmall>}
          />
          <RadioButton
            value={RemotePolicies.Both}
            checked={remotePolicyField.value === RemotePolicies.Both}
            onChange={(): void => remotePolicyField.onChange(RemotePolicies.Both)}
            content={<BodySmall>In-office or remote</BodySmall>}
          />
          <RadioButton
            value={RemotePolicies.Remote}
            checked={remotePolicyField.value === RemotePolicies.Remote}
            onChange={(): void => remotePolicyField.onChange(RemotePolicies.Remote)}
            content={<BodySmall>Remote only</BodySmall>}
          />
        </Stack>
      </Stack>
      {/* If they select remote only, then give them the option to be specific or just allow worldwide */}
      <Stack spacing={0.5}>
        {[RemotePolicies.Both, RemotePolicies.Remote].includes(remotePolicyField.value) && !!internationalRemoteRegion && (
          <>
            <Subtitle2>Are remote employees required to live in a specific location?</Subtitle2>
            <Stack direction="row" spacing={2}>
              <RadioButton
                value={requiredSpecific}
                checked={requiredSpecific}
                onChange={requireSpecificLoc}
                content={<BodySmall>Yes</BodySmall>}
              />
              <RadioButton
                value={!requiredSpecific}
                checked={!requiredSpecific}
                onChange={unrequireSpecificLoc}
                content={<BodySmall>No (can work from anywhere)</BodySmall>}
              />
            </Stack>
          </>
        )}
        {remotePolicyField.value !== RemotePolicies.Remote && (
          <Stack direction="row" spacing={0.3} alignItems="center">
            <Checkbox checked={!!wfhAllowedField.value} onChange={wfhAllowedField.onChange} />
            <BodySmall
              color={remotePolicyField.value === RemotePolicies.InOffice ? undefined : colors.grayscale.gray400}
            >
              Employees may work from home a few days per week
            </BodySmall>
          </Stack>
        )}
      </Stack>
      {showRemoteRegions && (
        <Stack spacing={0.5}>
          <Subtitle2>Remote regions</Subtitle2>
          <Autocomplete
            multiple
            errorText={errors.remoteRegions?.message}
            disabled={!locationOptions?.remoteOptions}
            placeholder="Remote regions"
            initialValues={remoteRegionsField.value}
            hideOptionsUntilLimit={1}
            staticOptions={
              locationOptions?.remoteOptions?.map(o => ({ displayName: o.displayName, locationOption: o.id })) ?? []
            }
            onSelectedOptionsChange={(values: any[]): void => {
              remoteRegionsField.onChange(
                values.sort((a, b) => {
                  return a.displayName > b.displayName ? 1 : -1;
                })
              );
            }}
            filterOptions={(options, state): LocationOption[] => filterLocationOptions(options, state.inputValue)}
            isOptionEqualToValue={(option, value): boolean => option.displayName === value.displayName}
            getOptionLabel={(option): string => option.displayName}
            renderOption={(props, option): React.ReactElement => (
              <li {...props} key={option.locationOption}>
                <BodySmall>{option.displayName}</BodySmall>
              </li>
            )}
          />
          {remoteRegionsError && <BodySmall color={colors.critical.base}>{remoteRegionsError}</BodySmall>}
        </Stack>
      )}
      {showOnsiteCities && (
        <Box>
          <Stack spacing={0.5}>
            <Subtitle2>In-office cities</Subtitle2>
            <Autocomplete
              multiple
              errorText={errors.onsiteCities?.message}
              disabled={!locationOptions?.cities}
              placeholder="In-office cities"
              initialValues={onsiteCitiesField.value}
              hideOptionsUntilLimit={1}
              staticOptions={
                locationOptions?.cities?.map(o => ({ displayName: o.displayName, locationOption: o.id })) ?? []
              }
              onSelectedOptionsChange={(values: any[]): void => {
                onsiteCitiesField.onChange(
                  values.sort((a, b) => {
                    return a.displayName > b.displayName ? 1 : -1;
                  })
                );
              }}
              filterOptions={(options, state): LocationOption[] => filterLocationOptions(options, state.inputValue)}
              isOptionEqualToValue={(option, value): boolean => option.displayName === value.displayName}
              getOptionLabel={(option): string => option.displayName}
              renderOption={(props, option): React.ReactElement => (
                <li {...props} key={option.locationOption}>
                  <BodySmall>{option.displayName}</BodySmall>
                </li>
              )}
            />
            {onsiteCitiesError && <BodySmall color={colors.critical.base}>{onsiteCitiesError}</BodySmall>}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default JobLocationFields;
