/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TalentNetwork,
    TalentNetworkFromJSON,
    TalentNetworkFromJSONTyped,
    TalentNetworkToJSON,
} from './';

/**
 * 
 * @export
 * @interface TalentNetworkReturn
 */
export interface TalentNetworkReturn {
    /**
     * 
     * @type {Array<TalentNetwork>}
     * @memberof TalentNetworkReturn
     */
    networkMembers: Array<TalentNetwork>;
}

export function TalentNetworkReturnFromJSON(json: any): TalentNetworkReturn {
    return TalentNetworkReturnFromJSONTyped(json, false);
}

export function TalentNetworkReturnFromJSONTyped(json: any, ignoreDiscriminator: boolean): TalentNetworkReturn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'networkMembers': ((json['network_members'] as Array<any>).map(TalentNetworkFromJSON)),
    };
}

export function TalentNetworkReturnToJSON(value?: TalentNetworkReturn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'network_members': ((value.networkMembers as Array<any>).map(TalentNetworkToJSON)),
    };
}


