import { ListItem } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { colors } from "styles/theme";

interface ProgressListItemProps {
  children: React.ReactNode;
  selected?: boolean;
  idx?: number;
  href?: string;
  onClick?: () => void;
}

const linkSelectedClassName = "Mui-selected";

const StyledLinkWrapper = styled.div`
a {
  text-decoration: none;
  color: ${colors.black};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${colors.black};
  }
  &:hover .MuiButtonBase-root {
    background-color: ${colors.grayscale.gray200};
  }
  &.Mui-selected > .MuiListItem-root {
    background-color ${colors.white};
    border: 1px solid ${colors.grayscale.gray200};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  }
  .MuiTypography-root {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
`;

const StyledItem = styled.div`
  .MuiListItem-root {
    color: ${colors.black};

    &:focus,
    &:hover,
    &:active {
      color: ${colors.black};
    }

    &:hover,
    &.Mui-selected {
      background-color: ${colors.grayscale.gray300};
      &:hover,
      &:focus,
      &:active {
        background-color: ${colors.grayscale.gray300};
      }
    }

    .MuiTypography-root {
      font-family: "Inter";
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const ProgressListItem: React.FC<ProgressListItemProps> = ({ children, selected, href, idx, onClick }) => {
  const style = { padding: "8px", borderRadius: "6px" };
  const item: React.ReactElement = (
    <ListItem disableRipple={true} button={true} style={style} selected={selected}>
      {children}
    </ListItem>
  );
  if (href) {
    return (
      <StyledLinkWrapper>
        <NavLink
          key={idx}
          to={href}
          end
          className={({ isActive }): string | undefined =>
            [isActive ? linkSelectedClassName : null].filter(Boolean).join(" ")
          }
        >
          {item}
        </NavLink>
      </StyledLinkWrapper>
    );
  }
  if (onClick) {
    return (
      <StyledItem onClick={onClick} key={idx}>
        {item}
      </StyledItem>
    );
  }
  return <StyledItem key={idx}>{item}</StyledItem>;
};
