import { StepType, StepTypes } from "sections/addcandidate/types";

export const StepConfig: { [key in StepType]: { type: StepType; label: string; description: string } } = {
  [StepTypes.ATS_CANDIDATE_INFO]: {
    type: StepTypes.ATS_CANDIDATE_INFO,
    label: "Import a candidate",
    description: ``,
  },
  [StepTypes.JOB_SELECT]: {
    type: StepTypes.JOB_SELECT,
    label: "Select a job",
    description: `Select the job you want to add a candidate to.`,
  },
  [StepTypes.CANDIDATE_INFO]: {
    type: StepTypes.CANDIDATE_INFO,
    label: "Candidate details",
    description: `Provide the information for the candidate or candidates you want to add.`,
  },
  [StepTypes.SELECT_CAMPAIGN]: {
    type: StepTypes.SELECT_CAMPAIGN,
    label: "Select an email campaign",
    description: `Select the campaign you want to add the candidate to and customize the email as needed.`,
  },
  [StepTypes.INITIAL_OUTREACH_REVIEW]: {
    type: StepTypes.INITIAL_OUTREACH_REVIEW,
    label: "Review and add to job",
    description: `Review the information you've provided and click Finish & Create when you're ready.`,
  },
  [StepTypes.STAGE_SELECT]: {
    type: StepTypes.STAGE_SELECT,
    label: "Stage",
    description: "Select the stage you want to add the candidate to.",
  },
  [StepTypes.INTERVIEW_INFO]: {
    type: StepTypes.INTERVIEW_INFO,
    label: "Input interview information",
    description: "",
  },
  [StepTypes.CUSTOMIZE_EMAIL]: {
    type: StepTypes.CUSTOMIZE_EMAIL,
    label: "Customize email",
    description: "Edit the scheduling email that will be sent to the candidate.",
  },
  [StepTypes.INTERVIEW_PIPELINE_REVIEW]: {
    type: StepTypes.INTERVIEW_PIPELINE_REVIEW,
    label: "Review and create",
    description: "Review the information you've provided and create the candidate.",
  },
};
