import { Stack } from "@mui/material";
import { Editor } from "@tiptap/react";
import React, { useCallback, useState } from "react";

import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { SharedTopLevelModalProps } from "components/dover/top-level-modal-manager/types";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";
import { colors } from "styles/theme";

export interface InsertLinkModalProps extends SharedTopLevelModalProps {
  editor: Editor;
}

const InsertLinkModal = ({ isOpen, closeModal, editor }: InsertLinkModalProps): React.ReactElement => {
  const [link, setLink] = useState<string>();

  // callbacks
  const insertLinkCallback = useCallback(async () => {
    if (!link) {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .unsetLink()
        .run();
    } else {
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: link })
        .run();
    }

    closeModal();
  }, [editor, link, closeModal]);

  return (
    <CustomModal
      open={isOpen}
      onClose={closeModal}
      title={<Body>Insert Link</Body>}
      maxWidth="sm"
      customDialogStyles={{ ...CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES, zIndex: 1500 }}
      customContentStyles={{ ...CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES, backgroundColor: colors.white }} // There is an issue with mui text fields that only allow transparent background, so overridinng the whole modal to be white here
      dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
    >
      <Stack spacing={2} direction="column">
        <TextField
          text={link || ""}
          onTextUpdated={(updatedText: string): void => {
            setLink(updatedText);
          }}
          placeholderText="Enter URL"
        />
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button variant={ButtonVariant.Secondary} onClick={closeModal}>
            Cancel
          </Button>
          <Button variant={ButtonVariant.Primary} onClick={insertLinkCallback} disabled={!link}>
            Insert
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default InsertLinkModal;
