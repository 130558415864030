import { Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

import { ReactComponent as GreenArrowRight } from "assets/icons/arrow-long.svg";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import CandidateInterestedThumbsup from "assets/images/candidate_interested_thumbsup.png";
import DoverInterviewerCustomization from "assets/images/dover_interviewer_customization.png";
import DoverInterviewerZoom from "assets/images/dover_interviewer_zoom.png";
import { BodySmall, PageTitle, Subtitle1, Subtitle2 } from "components/library/typography";
import { colors } from "styles/theme";

const HowItWorksCard = styled(Stack)`
  width: 275px;
  height: 325px;

  /* Text/White */

  background: #ffffff;
  /* Background/Hover */

  border: 1px solid #e1e7ec;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
`;

const CardTopHalf = styled(Stack)`
  height: 50%;
  background-color: ${colors.grayscale.gray100};
  align-items: center;
`;

const CardBottomText = ({ title, bodyText }: { title: string; bodyText: string }): React.ReactElement => {
  return (
    <Stack height="50%" padding={2} zIndex={"10"} sx={{ backgroundColor: colors.white }}>
      <Subtitle1 color={colors.grayscale.gray700}>{title}</Subtitle1>
      <BodySmall color={colors.grayscale.gray600}>{bodyText}</BodySmall>
    </Stack>
  );
};
const DoverInterviewerHowItWorksContent = (): React.ReactElement => {
  return (
    <Stack alignItems="center" justifyContent="center" spacing={4} marginY={6}>
      <PageTitle>How it works</PageTitle>
      <Stack direction="row" alignItems="center" spacing={3}>
        <HowItWorksCard>
          <CardTopHalf justifyContent="start" paddingTop="10px" spacing={1.5}>
            <img src={CandidateInterestedThumbsup} alt="Candidate Interested" style={{ width: "50px" }} />
            <BodySmall>
              James P. is <b>interested</b> in your role
            </BodySmall>
            <Stack
              direction="row"
              spacing={0.5}
              justifyContent="center"
              alignItems="center"
              padding="10px"
              sx={{
                border: `1px solid ${colors.primary.base}`,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
                borderRadius: "4px",
              }}
            >
              <ArrowRight className="svg-color" color={colors.primary.base} />
              <Subtitle2 color={colors.primary.base}>Move to initial call</Subtitle2>
            </Stack>
          </CardTopHalf>
          <CardBottomText
            title={"Approve candidates"}
            bodyText={
              "Send approved candidates to an initial call with a Dover Interviewer. You can still select an interviewer from your team to take the initial call instead."
            }
          />
        </HowItWorksCard>
        <GreenArrowRight />
        <HowItWorksCard>
          <CardTopHalf justifyContent="center" paddingTop="10px">
            <img src={DoverInterviewerZoom} alt="Dover Interviewer Zoom" />
          </CardTopHalf>
          <CardBottomText
            title={"Dover screens candidates"}
            bodyText={
              "Dover Interviewers will screen candidates based on questions you provide. They will also pitch your company and job."
            }
          />
        </HowItWorksCard>
        <GreenArrowRight />
        <HowItWorksCard>
          <CardTopHalf justifyContent="end">
            <img
              src={DoverInterviewerCustomization}
              alt="Dover Interviewer Customization"
              style={{ objectFit: "contain", width: "130px", marginBottom: "-20px" }}
            />
          </CardTopHalf>
          <CardBottomText
            title={"Review notes from interviews"}
            bodyText={
              "Dover notifies you when interviews are completed and provides detailed notes. You can advance or reject candidates."
            }
          />
        </HowItWorksCard>
      </Stack>
    </Stack>
  );
};

export default DoverInterviewerHowItWorksContent;
