import { format } from "date-fns";
import capitalize from "lodash/capitalize";
import React from "react";

import Emoji from "components/Emoji";
import { PipelineCandidateStatusEnum, PipelineCandidate, NextAction } from "services/openapi";
import { NextUpCell } from "views/candidates/CandidateTable/table/cells/NextUp/NextUpCell";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";

interface NextActionCellProps {
  candidate: PipelineCandidate;
  nextAction?: NextAction;
}

export const NextActionCell = ({ candidate, nextAction }: NextActionCellProps): React.ReactElement => {
  if (!candidate.status) {
    if (nextAction) {
      return <NextUpCell nextAction={nextAction} candidate={candidate} />;
    }
    return <Cell />;
  }

  let symbol;
  switch (candidate.status) {
    case PipelineCandidateStatusEnum.Withdrew:
    case PipelineCandidateStatusEnum.Rejected:
      symbol = "❌";
      break;
    case PipelineCandidateStatusEnum.Hired:
      symbol = "🎉";
      break;
    case PipelineCandidateStatusEnum.Snoozed:
      symbol = "🔕";
      break;
    default:
  }
  return (
    <Cell>
      {symbol && <Emoji symbol={symbol} label={candidate.status.toLowerCase()} />}
      &nbsp;&nbsp;{capitalize(candidate.status)}
      {candidate.lastStatusEventTs && ` on ${format(candidate.lastStatusEventTs!, "MM/dd/yy")}`}
    </Cell>
  );
};
