/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnboardingProUser,
    OnboardingProUserFromJSON,
    OnboardingProUserFromJSONTyped,
    OnboardingProUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface OnboardingEmailSenderAlias
 */
export interface OnboardingEmailSenderAlias {
    /**
     * 
     * @type {string}
     * @memberof OnboardingEmailSenderAlias
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingEmailSenderAlias
     */
    email?: string | null;
    /**
     * Set to override default suggestion in Company Setup UI; useful if HM already has setup an alias
     * @type {string}
     * @memberof OnboardingEmailSenderAlias
     */
    suggestedAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingEmailSenderAlias
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingEmailSenderAlias
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingEmailSenderAlias
     */
    readonly name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingEmailSenderAlias
     */
    isDefault?: boolean;
    /**
     * 
     * @type {OnboardingProUser}
     * @memberof OnboardingEmailSenderAlias
     */
    user?: OnboardingProUser;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingEmailSenderAlias
     */
    connectedToFront?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingEmailSenderAlias
     */
    readonly isVirtual?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OnboardingEmailSenderAlias
     */
    emailSender: string;
}

export function OnboardingEmailSenderAliasFromJSON(json: any): OnboardingEmailSenderAlias {
    return OnboardingEmailSenderAliasFromJSONTyped(json, false);
}

export function OnboardingEmailSenderAliasFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingEmailSenderAlias {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'suggestedAlias': !exists(json, 'suggested_alias') ? undefined : json['suggested_alias'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
        'user': !exists(json, 'user') ? undefined : OnboardingProUserFromJSON(json['user']),
        'connectedToFront': !exists(json, 'connected_to_front') ? undefined : json['connected_to_front'],
        'isVirtual': !exists(json, 'is_virtual') ? undefined : json['is_virtual'],
        'emailSender': json['email_sender'],
    };
}

export function OnboardingEmailSenderAliasToJSON(value?: OnboardingEmailSenderAlias | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'suggested_alias': value.suggestedAlias,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'is_default': value.isDefault,
        'user': OnboardingProUserToJSON(value.user),
        'connected_to_front': value.connectedToFront,
        'email_sender': value.emailSender,
    };
}


