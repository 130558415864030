/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateSourceNotReadyDetails,
    CandidateSourceNotReadyDetailsFromJSON,
    CandidateSourceNotReadyDetailsFromJSONTyped,
    CandidateSourceNotReadyDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidateSourceReadyForUseResults
 */
export interface CandidateSourceReadyForUseResults {
    /**
     * 
     * @type {boolean}
     * @memberof CandidateSourceReadyForUseResults
     */
    readonly readyForUse?: boolean;
    /**
     * 
     * @type {CandidateSourceNotReadyDetails}
     * @memberof CandidateSourceReadyForUseResults
     */
    notReadyDetails: CandidateSourceNotReadyDetails | null;
}

export function CandidateSourceReadyForUseResultsFromJSON(json: any): CandidateSourceReadyForUseResults {
    return CandidateSourceReadyForUseResultsFromJSONTyped(json, false);
}

export function CandidateSourceReadyForUseResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateSourceReadyForUseResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readyForUse': !exists(json, 'ready_for_use') ? undefined : json['ready_for_use'],
        'notReadyDetails': CandidateSourceNotReadyDetailsFromJSON(json['not_ready_details']),
    };
}

export function CandidateSourceReadyForUseResultsToJSON(value?: CandidateSourceReadyForUseResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'not_ready_details': CandidateSourceNotReadyDetailsToJSON(value.notReadyDetails),
    };
}


