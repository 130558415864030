import { SerializedError } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  ApiApiGetClientInterviewerAvailabilityRequest,
  ApiApiGetClientInterviewerCalendarEventsRequest,
  ApiApiGetMultipartInterviewStageRequest,
  CancelMultipartInterviewRequest,
  MultipartSchedulingCandidate,
  MultipartSchedulingClientInterviewerAvailability,
  MultipartSchedulingClientInterviewerCalendarEvent,
  MultipartSchedulingMultipartInterviewStage,
  SubmitMultipartInterviewSchedulingRequest,
} from "services/openapi";
import { toastOptions } from "utils/showToast";

export const multipartInterview = doverApi.injectEndpoints({
  endpoints: build => ({
    getMultipartInterview: build.query<
      MultipartSchedulingMultipartInterviewStage,
      ApiApiGetMultipartInterviewStageRequest
    >({
      queryFn: async params => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.getMultipartInterviewStage(params);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listClientInterviewerCalendarEvents: build.query<
      MultipartSchedulingClientInterviewerCalendarEvent[],
      ApiApiGetClientInterviewerCalendarEventsRequest
    >({
      queryFn: async params => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.getClientInterviewerCalendarEvents(params);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    listClientInterviewerAvailability: build.query<
      MultipartSchedulingClientInterviewerAvailability,
      ApiApiGetClientInterviewerAvailabilityRequest
    >({
      queryFn: async params => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.getClientInterviewerAvailability(params);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    getCandidate: build.query<MultipartSchedulingCandidate, string>({
      queryFn: async id => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const response = await client.getMultipartSchedulingCandidate({ id });
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    submitMultipartInterviewScheduling: build.mutation<void, SubmitMultipartInterviewSchedulingRequest>({
      queryFn: async (args: SubmitMultipartInterviewSchedulingRequest) => {
        const { apiApi: client } = await getOpenApiClients({});

        const toastId = toast("Submitting multipart interview...", {
          ...toastOptions,
          type: toast.TYPE.INFO,
          autoClose: false,
        });

        let result;
        try {
          result = await client.submitMultipartInterviewSchedulingRequest({ data: args });
        } catch (err) {
          const errBody = await err.json();
          console.log(errBody);
          toast.update(toastId, {
            render: `${errBody.error}`,
            type: toast.TYPE.ERROR,
          });

          return {
            error: {
              serializedError: err,
            },
          };
        }

        toast.update(toastId, {
          render: "Successfully submitted interview schedule",
          autoClose: 2000,
          type: toast.TYPE.SUCCESS,
        });

        return { data: result };
      },
    }),
    cancelMultipartInterview: build.mutation<void, CancelMultipartInterviewRequest>({
      queryFn: async (args: CancelMultipartInterviewRequest) => {
        const { apiApi: client } = await getOpenApiClients({});

        const toastId = toast("Canceling multipart interview...", {
          ...toastOptions,
          type: toast.TYPE.INFO,
          autoClose: false,
        });

        let result;
        try {
          result = await client.cancelMultipartInterviewRequest({ data: args });
        } catch (err) {
          const errBody = await err.json();
          console.log(errBody);
          toast.update(toastId, {
            render: `${errBody.error}`,
            autoClose: 2000,
            type: toast.TYPE.ERROR,
          });

          return {
            error: {
              serializedError: err,
            },
          };
        }

        toast.update(toastId, {
          render: "Successfully canceled interview",
          autoClose: 2000,
          type: toast.TYPE.SUCCESS,
        });

        return { data: result };
      },
    }),
  }),
});

export const {
  useGetMultipartInterviewQuery,
  useGetCandidateQuery,
  useSubmitMultipartInterviewSchedulingMutation,
  useCancelMultipartInterviewMutation,
} = multipartInterview;
