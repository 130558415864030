/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlimClient,
    SlimClientFromJSON,
    SlimClientFromJSONTyped,
    SlimClientToJSON,
    SlimDoverEntity,
    SlimDoverEntityFromJSON,
    SlimDoverEntityFromJSONTyped,
    SlimDoverEntityToJSON,
} from './';

/**
 * 
 * @export
 * @interface SlimJob
 */
export interface SlimJob {
    /**
     * 
     * @type {string}
     * @memberof SlimJob
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlimJob
     */
    title?: string | null;
    /**
     * 
     * @type {object}
     * @memberof SlimJob
     */
    candidateHealth?: object | null;
    /**
     * 
     * @type {SlimClient}
     * @memberof SlimJob
     */
    client?: SlimClient;
    /**
     * 
     * @type {SlimDoverEntity}
     * @memberof SlimJob
     */
    csm: SlimDoverEntity | null;
    /**
     * 
     * @type {string}
     * @memberof SlimJob
     */
    outreachReplyioCampaignId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SlimJob
     */
    clientReviewType?: SlimJobClientReviewTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SlimJob
     */
    autoSearchAdjustmentsEnabled?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum SlimJobClientReviewTypeEnum {
    Emailing = 'BEFORE_EMAILING',
    Scheduling = 'BEFORE_SCHEDULING'
}

export function SlimJobFromJSON(json: any): SlimJob {
    return SlimJobFromJSONTyped(json, false);
}

export function SlimJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlimJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'candidateHealth': !exists(json, 'candidate_health') ? undefined : json['candidate_health'],
        'client': !exists(json, 'client') ? undefined : SlimClientFromJSON(json['client']),
        'csm': SlimDoverEntityFromJSON(json['csm']),
        'outreachReplyioCampaignId': !exists(json, 'outreach_replyio_campaign_id') ? undefined : json['outreach_replyio_campaign_id'],
        'clientReviewType': !exists(json, 'client_review_type') ? undefined : json['client_review_type'],
        'autoSearchAdjustmentsEnabled': !exists(json, 'auto_search_adjustments_enabled') ? undefined : json['auto_search_adjustments_enabled'],
    };
}

export function SlimJobToJSON(value?: SlimJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'candidate_health': value.candidateHealth,
        'client': SlimClientToJSON(value.client),
        'csm': SlimDoverEntityToJSON(value.csm),
        'outreach_replyio_campaign_id': value.outreachReplyioCampaignId,
        'client_review_type': value.clientReviewType,
        'auto_search_adjustments_enabled': value.autoSearchAdjustmentsEnabled,
    };
}


