import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import { useResetAtom } from "jotai/utils";
import { useMemo } from "react";

import {
  selectedPersonaAtom,
  selectedIRTAtom,
  feedbackTemplateNameAtom,
  userDefinedQuestionsAtom,
  feedbackTemplateFormHasErrorAtom,
  feedbackTemplateDrawerConfigAtom,
} from "components/dover/FeedbackTemplates/atoms";
import { INVALID_PERMS_TO_EDIT_TEMPLATE_MESSAGE } from "components/dover/FeedbackTemplates/constants";
import { FeedbackTemplateDrawerContextType } from "components/dover/FeedbackTemplates/types";
import { Role, useContainsRole } from "components/RBAC";
import { useGetFeedbackTemplateQuery } from "services/doverapi/endpoints/feedbackTemplates";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";

/**
 * @description Resets all atoms related to the feedback template drawer and its form state. Does NOT close the drawer.
 */
export const useReset = (): (() => void) => {
  const resetPersona = useResetAtom(selectedPersonaAtom);
  const resetIRTName = useResetAtom(selectedIRTAtom);
  const resetFeedbackTemplateName = useResetAtom(feedbackTemplateNameAtom);
  const resetQuestions = useResetAtom(userDefinedQuestionsAtom);
  const resetFeedbackTemplateFormHasError = useResetAtom(feedbackTemplateFormHasErrorAtom);
  const resetFeedbacktemplateDrawerConfig = useResetAtom(feedbackTemplateDrawerConfigAtom);

  return (): void => {
    resetPersona();
    resetIRTName();
    resetFeedbackTemplateName();
    resetQuestions();
    resetFeedbackTemplateFormHasError();
    resetFeedbacktemplateDrawerConfig();
  };
};

export const useFeedbackTemplateFormIsDirty = (): boolean => {
  const feedbackTemplateName = useAtomValue(feedbackTemplateNameAtom);
  const questions = useAtomValue(userDefinedQuestionsAtom);
  const config = useAtomValue(feedbackTemplateDrawerConfigAtom);
  const hasEditPerms = useHasPermissionToEditTemplate();

  const { data: initialTemplate } = useGetFeedbackTemplateQuery(config.existingTemplateId ?? skipToken);

  if (!hasEditPerms) return false;

  if (!initialTemplate) {
    // For new templates, consider dirty if user has entered anything
    return Boolean(feedbackTemplateName || questions.length);
  }

  // For existing templates, check if values have changed
  const nameChanged = feedbackTemplateName !== undefined && feedbackTemplateName !== initialTemplate.name;
  const questionsChanged =
    questions.length !== initialTemplate.userDefinedQuestions?.length ||
    JSON.stringify(questions) !== JSON.stringify(initialTemplate.userDefinedQuestions);

  return nameChanged || questionsChanged;
};

export const useHasPermissionToEditTemplate = (): boolean => {
  const userHasAdminPermissions = useContainsRole([Role.ADMIN, Role.CLIENT_ADMIN]);
  const { data: currentUserInfo } = useGetAuthedUserInfoQuery();
  const { existingTemplateId, context } = useAtomValue(feedbackTemplateDrawerConfigAtom);

  const { data: existingFeedbackTemplate } = useGetFeedbackTemplateQuery(existingTemplateId ?? skipToken);
  const inEditMode = context === FeedbackTemplateDrawerContextType.Edit;

  return useMemo(() => {
    return !inEditMode || userHasAdminPermissions || currentUserInfo?.id === existingFeedbackTemplate?.createdBy?.id;
  }, [inEditMode, userHasAdminPermissions, currentUserInfo, existingFeedbackTemplate]);
};

export const useDisableSave = (): boolean => {
  const hasPermissionToEdit = useHasPermissionToEditTemplate();
  const hasError = useAtomValue(feedbackTemplateFormHasErrorAtom);
  const feedbackTemplateName = useAtomValue(feedbackTemplateNameAtom);
  const questions = useAtomValue(userDefinedQuestionsAtom);

  return useMemo(() => {
    return Boolean(hasError || !feedbackTemplateName || !questions.length || !hasPermissionToEdit);
  }, [hasError, feedbackTemplateName, questions, hasPermissionToEdit]);
};

export const useDisableSaveTooltip = (): string => {
  const hasPermissionToEdit = useHasPermissionToEditTemplate();
  const hasError = useAtomValue(feedbackTemplateFormHasErrorAtom);
  const feedbackTemplateName = useAtomValue(feedbackTemplateNameAtom);
  const questions = useAtomValue(userDefinedQuestionsAtom);

  if (!hasPermissionToEdit) {
    return INVALID_PERMS_TO_EDIT_TEMPLATE_MESSAGE;
  }

  if (hasError) {
    return "Please correct the errors in the form";
  }

  if (!feedbackTemplateName) {
    return "Please enter a template name";
  }

  if (!questions.length) {
    return "Please add at least one question";
  }

  return "";
};
