import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { ReactComponent as CircleX } from "assets/icons/x-red-circle.svg";
import { Card } from "components/library/Card";
import { Body, BodySmall, Subtitle1, TitleLarge } from "components/library/typography";
import { STEP_FLOW_STEP_PARAM_NAME } from "components/StepFlow/constants";
import { ProgressBar } from "components/StepFlow/ProgressBar";
import { StepFlowOnClose, StepFlowStep } from "components/StepFlow/types";
import { colors, screenSizesNumbers } from "styles/theme";

interface StepFlowProps {
  title: string;
  steps: StepFlowStep[];
  onClose?: StepFlowOnClose;
  bgColor?: string;
  hideProgressBar?: boolean;
}

/**
 * This component scaffolds a multi-step flow. It can handle the header, progress bar, layout, styling, exiting, and navigation between steps.
 * This component will pass StepProps to each step component.
 *
 * Also, take a look at StepFlowNav for the recommended buttons component that can be used to navigate between steps.
 *
 * @param title Required. The title of the flow. Will display in top left corner.
 * @param steps Required. An array of StepFlowStep objects. Each object represents a step in the flow. Look as the JSDoc for StepFlowStep for more information.
 * @param onClose Optional. If provided, will display a close button in the top right corner. If a function, will call that function when the close button is clicked. If a string, will navigate to that string when the close button is clicked. Recommended to use APP_ROUTE_PATHS to get this string.
 * @param bgColor Optional. The background color of the flow. Defaults to gray100.
 */
const StepFlow: React.FC<StepFlowProps> = ({ title, steps, onClose, bgColor, hideProgressBar }) => {
  const navigate = useNavigate();
  const [stepParam, setStepParam] = useQueryParam(STEP_FLOW_STEP_PARAM_NAME, StringParam);

  useEffect(() => {
    // If no step param is provided, default to the first step
    if (!stepParam) {
      setStepParam(steps[0].stepRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentStep = useMemo(() => {
    const step = steps.find(step => step.stepRoute === stepParam);
    return step ?? steps[0];
  }, [stepParam, steps]);

  const currentStepIndex = useMemo(() => steps.indexOf(currentStep), [currentStep, steps]);

  const goNext = useCallback((): void => {
    const nextStepRoute = steps[currentStepIndex + 1]?.stepRoute;
    if (nextStepRoute) {
      setStepParam(nextStepRoute);
    }
  }, [currentStepIndex, setStepParam, steps]);

  const goBack = useCallback((): void => {
    const prevStepRoute = steps[currentStepIndex - 1]?.stepRoute;
    if (prevStepRoute) {
      setStepParam(prevStepRoute);
    }
  }, [currentStepIndex, setStepParam, steps]);

  const close = useMemo(
    () => (onClose ? (typeof onClose === "function" ? onClose : (): void => navigate(onClose)) : undefined),
    [navigate, onClose]
  );

  const StepWrapper = useMemo(() => (currentStep?.custom ? React.Fragment : StepFlowStepWrapper), [
    currentStep?.custom,
  ]);

  // If they somehow end up in an invalid step show a error
  if (currentStepIndex < 0 || currentStepIndex >= steps.length || !currentStep) {
    return (
      <Stack width="100%" height="100%" alignItems="center" pt="25vh" bgcolor={bgColor ?? colors.grayscale.gray100}>
        <Card>
          <Stack alignItems="center" spacing={1}>
            <CircleX height="36px" width="36px" />
            <Body>Invalid step. If problem persists please reach out to support.</Body>
          </Stack>
        </Card>
      </Stack>
    );
  }

  return (
    <Stack height="100%" bgcolor={bgColor ?? colors.grayscale.gray100}>
      <StepFlowHeader
        title={title}
        currentStep={currentStep}
        steps={steps}
        onClose={(): void => {
          setStepParam(undefined);
          close?.();
        }}
        hideProgressBar={hideProgressBar}
      />
      <StepWrapper step={currentStep}>
        <currentStep.component goNext={goNext} goBack={goBack} />
      </StepWrapper>
    </Stack>
  );
};

interface StepFlowHeaderProps {
  title: string;
  currentStep: StepFlowStep;
  steps: StepFlowStep[];
  onClose?: () => void;
  hideProgressBar?: boolean;
}

const StepFlowHeader: React.FC<StepFlowHeaderProps> = React.memo(
  ({ title, currentStep, steps, onClose, hideProgressBar }) => {
    const muiTheme = useTheme();
    const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));
    const currentStepIndex = useMemo(() => steps.indexOf(currentStep), [currentStep, steps]);

    const desktopHeader = (
      <>
        <Subtitle1>{title}</Subtitle1>
        {!hideProgressBar && <ProgressBar currentStep={currentStep} steps={steps} />}
        <Box display="flex" flex="1" justifyContent="flex-end" alignItems="center">
          {onClose && (
            <Box
              sx={{ "&:hover": { cursor: "pointer" }, ml: 2 }}
              onClick={(): void => {
                onClose();
              }}
            >
              <CloseIcon />
            </Box>
          )}
        </Box>
      </>
    );

    const mobileHeader = (
      <Stack width="100%">
        <Stack direction="row" justifyContent="space-between">
          <Subtitle1>{title}</Subtitle1>
          {onClose && <CloseIcon />}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <BodySmall>{steps[currentStepIndex].progressLabel}</BodySmall>
          <BodySmall>{`${currentStepIndex + 1} of ${steps.length}`}</BodySmall>
        </Stack>
      </Stack>
    );

    const header = isSmallScreen ? mobileHeader : desktopHeader;

    return (
      <Stack
        direction="row"
        padding={2}
        bgcolor={colors.white}
        boxShadow={`inset 0px -1px 0px ${colors.grayscale.gray300}`}
      >
        {header}
      </Stack>
    );
  }
);

interface StepFlowStepWrapperProps {
  step: StepFlowStep;
}

const StepFlowStepWrapper: React.FC<StepFlowStepWrapperProps> = React.memo(({ step, children }) => {
  return (
    <Stack spacing={1} alignItems="center" height="100%">
      {step.title && <TitleLarge>{step.title}</TitleLarge>}
      {step.description && <Body>{step.description}</Body>}
      {children}
    </Stack>
  );
});

export default StepFlow;
