/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignMessage,
    CampaignMessageFromJSON,
    CampaignMessageFromJSONTyped,
    CampaignMessageToJSON,
    CreateCampaignMessage,
    CreateCampaignMessageFromJSON,
    CreateCampaignMessageFromJSONTyped,
    CreateCampaignMessageToJSON,
    EmailSenderAlias,
    EmailSenderAliasFromJSON,
    EmailSenderAliasFromJSONTyped,
    EmailSenderAliasToJSON,
} from './';

/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    campaignState?: CampaignCampaignStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    emailAlias?: string | null;
    /**
     * 
     * @type {EmailSenderAlias}
     * @memberof Campaign
     */
    emailAliasInfo?: EmailSenderAlias;
    /**
     * 
     * @type {boolean}
     * @memberof Campaign
     */
    enableUnsubscribeFooter?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    readonly jobName?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    readonly clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    name: string;
    /**
     * 
     * @type {Array<EmailSenderAlias>}
     * @memberof Campaign
     */
    readonly potentialEmailAliases?: Array<EmailSenderAlias>;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    sendingDefaultTz?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    sendingWindow?: CampaignSendingWindowEnum;
    /**
     * 
     * @type {Array<CampaignMessage>}
     * @memberof Campaign
     */
    readonly threadMessages?: Array<CampaignMessage>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Campaign
     */
    trackingTypes?: Array<string>;
    /**
     * 
     * @type {Array<CreateCampaignMessage>}
     * @memberof Campaign
     */
    newMessages?: Array<CreateCampaignMessage>;
    /**
     * 
     * @type {Array<CampaignMessage>}
     * @memberof Campaign
     */
    updatedMessages?: Array<CampaignMessage>;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    userDefinedSenderUser?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    emailSenderOption?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Campaign
     */
    saveAsDraft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    setupState?: CampaignSetupStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Campaign
     */
    allowFollowUpsPostDeactivation?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CampaignCampaignStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED'
}/**
* @export
* @enum {string}
*/
export enum CampaignSendingWindowEnum {
    DefaultBusinessHours = 'DEFAULT_BUSINESS_HOURS',
    DefaultBusinessHoursPlusWeekends = 'DEFAULT_BUSINESS_HOURS_PLUS_WEEKENDS',
    AlwaysWeekendHours = 'ALWAYS_WEEKEND_HOURS'
}/**
* @export
* @enum {string}
*/
export enum CampaignSetupStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}

export function CampaignFromJSON(json: any): Campaign {
    return CampaignFromJSONTyped(json, false);
}

export function CampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): Campaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaignState': !exists(json, 'campaign_state') ? undefined : json['campaign_state'],
        'emailAlias': !exists(json, 'email_alias') ? undefined : json['email_alias'],
        'emailAliasInfo': !exists(json, 'email_alias_info') ? undefined : EmailSenderAliasFromJSON(json['email_alias_info']),
        'enableUnsubscribeFooter': !exists(json, 'enable_unsubscribe_footer') ? undefined : json['enable_unsubscribe_footer'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'jobName': !exists(json, 'job_name') ? undefined : json['job_name'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'name': json['name'],
        'potentialEmailAliases': !exists(json, 'potential_email_aliases') ? undefined : ((json['potential_email_aliases'] as Array<any>).map(EmailSenderAliasFromJSON)),
        'sendingDefaultTz': !exists(json, 'sending_default_tz') ? undefined : json['sending_default_tz'],
        'sendingWindow': !exists(json, 'sending_window') ? undefined : json['sending_window'],
        'threadMessages': !exists(json, 'thread_messages') ? undefined : ((json['thread_messages'] as Array<any>).map(CampaignMessageFromJSON)),
        'trackingTypes': !exists(json, 'tracking_types') ? undefined : json['tracking_types'],
        'newMessages': !exists(json, 'new_messages') ? undefined : ((json['new_messages'] as Array<any>).map(CreateCampaignMessageFromJSON)),
        'updatedMessages': !exists(json, 'updated_messages') ? undefined : ((json['updated_messages'] as Array<any>).map(CampaignMessageFromJSON)),
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'userDefinedSenderUser': !exists(json, 'user_defined_sender_user') ? undefined : json['user_defined_sender_user'],
        'emailSenderOption': !exists(json, 'email_sender_option') ? undefined : json['email_sender_option'],
        'saveAsDraft': !exists(json, 'save_as_draft') ? undefined : json['save_as_draft'],
        'setupState': !exists(json, 'setup_state') ? undefined : json['setup_state'],
        'allowFollowUpsPostDeactivation': !exists(json, 'allow_follow_ups_post_deactivation') ? undefined : json['allow_follow_ups_post_deactivation'],
    };
}

export function CampaignToJSON(value?: Campaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaign_state': value.campaignState,
        'email_alias': value.emailAlias,
        'email_alias_info': EmailSenderAliasToJSON(value.emailAliasInfo),
        'enable_unsubscribe_footer': value.enableUnsubscribeFooter,
        'name': value.name,
        'sending_default_tz': value.sendingDefaultTz,
        'sending_window': value.sendingWindow,
        'tracking_types': value.trackingTypes,
        'new_messages': value.newMessages === undefined ? undefined : ((value.newMessages as Array<any>).map(CreateCampaignMessageToJSON)),
        'updated_messages': value.updatedMessages === undefined ? undefined : ((value.updatedMessages as Array<any>).map(CampaignMessageToJSON)),
        'weight': value.weight,
        'user_defined_sender_user': value.userDefinedSenderUser,
        'email_sender_option': value.emailSenderOption,
        'save_as_draft': value.saveAsDraft,
        'setup_state': value.setupState,
        'allow_follow_ups_post_deactivation': value.allowFollowUpsPostDeactivation,
    };
}


