/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProUser
 */
export interface ProUser {
    /**
     * 
     * @type {number}
     * @memberof ProUser
     */
    readonly id?: number;
    /**
     * 
     * @type {object}
     * @memberof ProUser
     */
    roleTitleData?: object | null;
    /**
     * 
     * @type {string}
     * @memberof ProUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProUser
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProUser
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProUser
     */
    phoneNumber?: string | null;
}

export function ProUserFromJSON(json: any): ProUser {
    return ProUserFromJSONTyped(json, false);
}

export function ProUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'roleTitleData': !exists(json, 'role_title_data') ? undefined : json['role_title_data'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'clientName': !exists(json, 'client_name') ? undefined : json['client_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
    };
}

export function ProUserToJSON(value?: ProUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role_title_data': value.roleTitleData,
        'first_name': value.firstName,
        'client_name': value.clientName,
        'email': value.email,
        'phone_number': value.phoneNumber,
    };
}


