import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { JOB_PITCH, JOB_SETUP } from "services/doverapi/endpointTagsConstants";
import {
  GetJobPitchQuestionSchemaResponse,
  SaveJobPitchQuestionSchema,
  SaveJobPitchQuestionSchema1,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

const jobPitchEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getJobPitchQuestionSchema: build.query<GetJobPitchQuestionSchemaResponse, string>({
      queryFn: async jobId => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.getJobPitchQuestionSchema({ jobId });
        } catch (error) {
          showErrorToast("Failed to load Job Pitch");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      providesTags: (result, error, jobId) => [{ type: JOB_PITCH, id: jobId }],
    }),
    saveJobPitchQuestionSchema: build.mutation<
      SaveJobPitchQuestionSchema1,
      { jobId: string; data: SaveJobPitchQuestionSchema }
    >({
      queryFn: async ({ jobId, data }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.saveJobPitchQuestionSchema({ jobId, data });
        } catch (error) {
          showErrorToast("Failed to save Job Pitch answers. Please try again.");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
        return { data: result };
      },
      invalidatesTags: (result, error, arg) => [
        { type: JOB_PITCH, id: arg.jobId },
        { type: JOB_SETUP, id: arg.jobId },
      ],
    }),
  }),
});

export const { useGetJobPitchQuestionSchemaQuery, useSaveJobPitchQuestionSchemaMutation } = jobPitchEndpoints;
