/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubmitMultipartInterviewSchedulingRequestSubstage
 */
export interface SubmitMultipartInterviewSchedulingRequestSubstage {
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipartInterviewSchedulingRequestSubstage
     */
    candidateMultipartInterviewSubstageId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipartInterviewSchedulingRequestSubstage
     */
    multipartInterviewSubstageId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitMultipartInterviewSchedulingRequestSubstage
     */
    interviewerIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SubmitMultipartInterviewSchedulingRequestSubstage
     */
    start: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipartInterviewSchedulingRequestSubstage
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof SubmitMultipartInterviewSchedulingRequestSubstage
     */
    duration: number;
}

export function SubmitMultipartInterviewSchedulingRequestSubstageFromJSON(json: any): SubmitMultipartInterviewSchedulingRequestSubstage {
    return SubmitMultipartInterviewSchedulingRequestSubstageFromJSONTyped(json, false);
}

export function SubmitMultipartInterviewSchedulingRequestSubstageFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitMultipartInterviewSchedulingRequestSubstage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateMultipartInterviewSubstageId': !exists(json, 'candidate_multipart_interview_substage_id') ? undefined : json['candidate_multipart_interview_substage_id'],
        'multipartInterviewSubstageId': !exists(json, 'multipart_interview_substage_id') ? undefined : json['multipart_interview_substage_id'],
        'interviewerIds': json['interviewer_ids'],
        'start': json['start'],
        'title': json['title'],
        'duration': json['duration'],
    };
}

export function SubmitMultipartInterviewSchedulingRequestSubstageToJSON(value?: SubmitMultipartInterviewSchedulingRequestSubstage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_multipart_interview_substage_id': value.candidateMultipartInterviewSubstageId,
        'multipart_interview_substage_id': value.multipartInterviewSubstageId,
        'interviewer_ids': value.interviewerIds,
        'start': value.start,
        'title': value.title,
        'duration': value.duration,
    };
}


