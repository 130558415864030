/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOrCreateClientResponse
 */
export interface GetOrCreateClientResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateClientResponse
     */
    clientId: string;
}

export function GetOrCreateClientResponseFromJSON(json: any): GetOrCreateClientResponse {
    return GetOrCreateClientResponseFromJSONTyped(json, false);
}

export function GetOrCreateClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrCreateClientResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['client_id'],
    };
}

export function GetOrCreateClientResponseToJSON(value?: GetOrCreateClientResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
    };
}


