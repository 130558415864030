import { Box, Drawer, IconButton, Stack, TextField as MUITextField, Grid, StackProps } from "@mui/material";
import { Select, TextField } from "mui-rff";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { Caption } from "components/library/typography";
import { colors, screenSizes } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

interface StageRowProps extends StackProps {
  $hasSubstages: boolean;
  $editable: boolean;
}

export const StageRowContainer = styled(Stack)<StageRowProps>`
  border: 1px solid ${colors.grayscale.gray100};
  border-radius: 5px;
  border-bottom-left-radius: ${({ $hasSubstages }): string => ($hasSubstages ? "0px" : "5px")};
  border-bottom-right-radius: ${({ $hasSubstages }): string => ($hasSubstages ? "0px" : "5px")};
  background-color: ${colors.white};
  padding: 10px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  cursor: ${({ $editable }): string => ($editable ? "pointer" : "default")};
  @media (min-width: 900px) {
    min-height: 60px;
  }
  &:hover {
    background-color: ${({ $editable }): string => ($editable ? colors.grayscale.gray100 : colors.white)};
  }
`;

export const SubstageRowContainer = styled(Grid)`
  background-color: ${colors.white};
  padding: 10px;
  border: 1px solid ${colors.grayscale.gray100};
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-top: none;
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const SubstageName = styled(B2_doNotUse)`
  font-weight: 500;
  @media (min-width: 900px) {
    padding-left: 26px;
  }
`;

export const ActionsContainer = styled(Stack)`
  justify-content: flex-end;
  align-items: center;
  button {
    padding: 5px 10px;
  }
`;

export const StageDrawer = styled(Drawer)`
  &.MuiDrawer-root {
    z-index: 1202;
  }

  .MuiDrawer-paperAnchorRight {
    width: 60vw;
    height: 100vh;

    @media (max-width: ${screenSizes.laptop}) {
      width: 85vw;
    }
  }
  .MuiSelect-select {
    padding: 10px 8px !important;
    font-size: 14px !important;
  }
  .MuiTextField-root,
  .MuiInputBase-root {
    width: 100% !important;
  }
  .MuiSelect-root {
    max-height: 40px;
  }
  .MuiAutocomplete-inputRoot {
    padding: 2px 8px;
  }
`;

export const StageNameField = styled(MUITextField)`
  .MuiOutlinedInput-notchedOutline legend {
    display: none;
  }
  .MuiInputBase-input {
    padding: 6px 8px !important;
  }
  .MuiOutlinedInput-root {
    border: none;
  }
`;

export const StageDrawerContent = styled(Box)`
  padding: 16px 0 50px;
  overflow-y: auto;
  min-height: 0;
`;

export const StageDrawerActions = styled(Stack)`
  width: 100%;
  background-color: white;
  height: 85px;
  z-index: 1;
  padding: 15px;
  border-top: solid 1px ${colors.grayscale.gray300};
`;

export const StageDrawerCloseIcon = styled(IconButton)`
  position: relative;
  bottom: 3px;

  &.MuiButtonBase-root {
    transform: rotate(45deg);
    width: 35px;
    height: 35px;
    float: right;
    color: ${colors.black};
    font-size: 28px;
  }
`;

export const StageDrawerFormLabel = styled(B2_doNotUse)`
  margin-bottom: 5px;
`;

export const StageDrawerFormError = styled(Caption)`
  color: ${colors.critical.base};
  margin-top: 5px;
`;

export const FormLabelInfoIcon = styled(ReactSVG)`
  position: relative;
  bottom: 5px;
`;

export const DurationField = styled(Select)`
  max-width: 130px;
  background-color: ${colors.white};
  .MuiInputBase-root {
    padding: 13px;
  }
`;

export const MeetingTypeContainer = styled(Box)`
  width: calc(50% - 8px);
  display: inline-block;
  margin-bottom: 24px;
  .MuiSelect-root {
    width: 100%;
  }
`;

interface InterviewDurationContainerProps extends StackProps {
  $isInline: boolean;
}
export const InterviewDurationContainer = styled(Box)<InterviewDurationContainerProps>`
  width: ${(props): string => (props.$isInline ? "20%" : "100%")};
  display: ${(props): string => (props.$isInline ? "inline-block" : "block")};
  margin-bottom: 24px;
  .MuiInputBase-root {
    max-width: ${(props): string => (props.$isInline ? "calc(100% - 16px)" : "130px")};
  }
`;

interface InterviewerContainerProps extends StackProps {
  $isInline: boolean;
}
export const InterviewerContainer = styled(Box)<InterviewerContainerProps>`
  width: ${(props): string => (props.$isInline ? "80%" : "100%")};
  display: ${(props): string => (props.$isInline ? "inline-block" : "block")};
  margin-bottom: ${(props): string => (props.$isInline ? "0" : "24px")};
`;

export const MeetingLocationContainer = styled(Box)`
  width: calc(50% - 8px);
  display: inline-block;
  margin-left: 16px;
  position: relative;
  bottom: 10px;
`;

export const RequiredAsterisk = styled.span`
  color: ${colors.critical.base};
`;

export const SmallTextInput = styled(TextField)`
  &.MuiFormControl-root {
    width: 200px !important;
  }
`;
