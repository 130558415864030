/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobFeature
 */
export interface JobFeature {
    /**
     * 
     * @type {string}
     * @memberof JobFeature
     */
    featureName: JobFeatureFeatureNameEnum;
    /**
     * 
     * @type {string}
     * @memberof JobFeature
     */
    state: JobFeatureStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobFeature
     */
    dependentJobSetupSteps: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobFeature
     */
    dependentCompanySetupSteps: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum JobFeatureFeatureNameEnum {
    ManagedOutbound = 'MANAGED_OUTBOUND',
    DoverInterviewer = 'DOVER_INTERVIEWER',
    E2EScheduling = 'E2E_SCHEDULING',
    ScheduleInitialCustomerCallOnly = 'SCHEDULE_INITIAL_CUSTOMER_CALL_ONLY'
}/**
* @export
* @enum {string}
*/
export enum JobFeatureStateEnum {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

export function JobFeatureFromJSON(json: any): JobFeature {
    return JobFeatureFromJSONTyped(json, false);
}

export function JobFeatureFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFeature {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'featureName': json['feature_name'],
        'state': json['state'],
        'dependentJobSetupSteps': json['dependent_job_setup_steps'],
        'dependentCompanySetupSteps': json['dependent_company_setup_steps'],
    };
}

export function JobFeatureToJSON(value?: JobFeature | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feature_name': value.featureName,
        'state': value.state,
        'dependent_job_setup_steps': value.dependentJobSetupSteps,
        'dependent_company_setup_steps': value.dependentCompanySetupSteps,
    };
}


