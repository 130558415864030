import { Message } from "@doverhq/dover-ui/dist/types/Chat";
import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

import { AIConversation, AIConversationTypeEnum } from "services/openapi";

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  Types
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
export enum CriteriaMode {
  Criteria = "criteria",
  Chat = "chat",
}

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  Constants
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
// This is the user representing the AI responses from our backend
export const DOVER_AI_USER = {
  id: "__DOVER_AI_USER",
  name: "Dover Bot",
};

// This is the user representing the signed in user using the chat
export const CHAT_USER = {
  id: "__CHAT_USER",
  name: "You",
};

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  Helpers
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/

/*
  The listApplicationsViaAi api and the generic Chat UI component expects conversations in different formats
  The following two helpers convert between these formats
*/
const convertFromAIConversationToMessage = (conversation: AIConversation): Message => ({
  user: conversation.type === AIConversationTypeEnum.Ai ? DOVER_AI_USER : CHAT_USER,
  message: conversation.content,
  // @ts-ignore
  additionalKwargs: conversation?.additionalKwargs, // TODO: Update dover-ui type
});

export const convertFromMessageToAIConversation = (message: Message): AIConversation => ({
  type: message.user?.id === DOVER_AI_USER.id ? AIConversationTypeEnum.Ai : AIConversationTypeEnum.Human,
  content: message.message,
  // @ts-ignore
  additionalKwargs: message?.additionalKwargs, // TODO: Update dover-ui type
});

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  Base Atoms
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
// Contains all the messages that make up the chat state
export const messagesAtom = atomWithReset<Message[]>([]);

// Whether the chat window is open or the normal saap window is open
// undefined means its closed
export const criteriaModeAtom = atom<CriteriaMode | undefined>(undefined);

// Whether the search should be built off of the params from the saap form or independent of them
export const useSaapParamsAtom = atom<boolean>(false);

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  messagesAtom derived Atoms
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
// True if the initial message is the only message present in the conversation
export const onlyInitialMessageAtom = atom(get => {
  const messages = get(messagesAtom);
  return messages.length === 1;
});

// Grabs the most recent message from the conversation
export const mostRecentMessageAtom = atom(get => {
  const messages = get(messagesAtom);
  return messages.length ? messages[messages.length - 1] : undefined;
});

// If the most recent message was sent by the user
export const isMostRecentMessageUserAtom = atom(get => {
  const mostRecentMessage = get(mostRecentMessageAtom);
  return mostRecentMessage?.user?.id === CHAT_USER.id; // Was the last message sent by the user or the AI
});

// If chat control functionality (undo, reset etc.) should be disabled
// We don't want to allow the user to undo or reset if the only message is the AI's initial message
// Or if their own message is the most recent message, because
// That would mean the api is still loading
export const isChatControlDisabledAtom = atom(get => {
  const onlyInitialMessage = get(onlyInitialMessageAtom);
  const isMostRecentMessageUser = get(isMostRecentMessageUserAtom);

  return onlyInitialMessage || isMostRecentMessageUser;
});

// This is used as the conversation input for the listApplicationsViaAi API
export const conversationForApiAtom = atom(get => {
  const messages = get(messagesAtom);
  const isMostRecentMessageUser = get(isMostRecentMessageUserAtom);

  // If the last message was sent by the AI, we don't want to include it in the conversation for the api
  // If we did it would cause an infinite loop as the conversation is both an input and an output to the api
  const sliceIndex = isMostRecentMessageUser ? messages.length : messages.length - 1;

  return messages.slice(0, sliceIndex).map(convertFromMessageToAIConversation);
});

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  messagesAtom Action Atoms
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/

// Add a single message
export const addMessageAtom = atom(null, (get, set, message: Message) => {
  const messages = get(messagesAtom);
  set(messagesAtom, [...messages, message]);
});

// Undo the last two messages
// It is the last two because we want to undo the user's message and the AI's response
export const undoMessageAtom = atom(null, (get, set) => {
  const messages = get(messagesAtom);
  set(messagesAtom, messages.slice(0, messages.length - 2));
});

// Convert from the AIConversation type to Message type and override the whole conversation
// This is used when the listApplicationsViaAi api returns a new conversation
export const setMessagesFromConversationAtom = atom(null, (get, set, conversation: AIConversation[]) => {
  set(messagesAtom, conversation.map(convertFromAIConversationToMessage));
});

/*
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  useSaapParams Action Atoms
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
export const toggleUseSaapParamsAtom = atom(null, (get, set) => {
  const useSaapParams = get(useSaapParamsAtom);
  set(useSaapParamsAtom, !useSaapParams);
});
