import React from "react";

import { SeniorityNameToExtraInfo } from "services/doverapi/endpoints/search-v3/constants";
import {
  useListCompanyListsQuery,
  useListSchoolListsQuery,
  useListSenioritiesQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import {
  CompanyListWithRequiredID,
  SchoolListWithRequiredID,
  SeniorityCategory,
  SeniorityWithExtraInfo,
} from "services/doverapi/endpoints/search-v3/types";
import { UseQueryResult } from "services/doverapi/types";

export function useListSenioritiesWithExtraInfo(): SeniorityWithExtraInfo[] | undefined {
  const { data: seniorities } = useListSenioritiesQuery();

  return React.useMemo<SeniorityWithExtraInfo[] | undefined>(() => {
    if (seniorities === undefined) {
      return undefined;
    }

    // Tracks listOrder for un-matching seniority options for future compatibility
    let currentOverflowIndex = Object.keys(SeniorityNameToExtraInfo).length;

    return seniorities
      .map(seniority => {
        let extraInfo = SeniorityNameToExtraInfo.get(seniority.name);
        if (extraInfo === undefined) {
          extraInfo = { listOrder: currentOverflowIndex, category: SeniorityCategory.Miscellaneous };
          currentOverflowIndex += 1;
        }

        return { ...seniority, ...extraInfo };
      })
      .sort((seniority1, seniority2) => seniority1.listOrder - seniority2.listOrder);
  }, [seniorities]);
}
export function useGetClientScopedTargetSchoolLists(): UseQueryResult<SchoolListWithRequiredID[]> {
  const {
    data: schoolListsOptions,
    isUninitialized,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useListSchoolListsQuery();

  return React.useMemo(() => {
    // scopedToClient is the clientId, but it is safe to assume that it is always equal to the authed client
    return {
      data: schoolListsOptions?.filter(schoolList => schoolList.scopedToClient),
      isUninitialized,
      isLoading,
      isFetching,
      isSuccess,
      isError,
    };
  }, [schoolListsOptions, isUninitialized, isLoading, isFetching, isSuccess, isError]);
}

export function useGetDoversTargetSchoolLists(): UseQueryResult<SchoolListWithRequiredID[]> {
  const {
    data: schoolListsOptions,
    isUninitialized,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useListSchoolListsQuery();

  return React.useMemo(() => {
    return {
      data: schoolListsOptions,
      isUninitialized,
      isLoading,
      isFetching,
      isSuccess,
      isError,
    };
  }, [schoolListsOptions, isUninitialized, isLoading, isFetching, isSuccess, isError]);
}

export function useGetClientScopedTargetCompanyLists(): UseQueryResult<CompanyListWithRequiredID[]> {
  const {
    data: companyListsOptions,
    isUninitialized,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useListCompanyListsQuery({ limit: 1000 });

  return React.useMemo(() => {
    // scopedToClient is the clientId, but it is safe to assume that it is always equal to the authed client
    return {
      data: companyListsOptions?.filter(companyList => companyList.scopedToClient),
      isUninitialized,
      isLoading,
      isFetching,
      isSuccess,
      isError,
    };
  }, [companyListsOptions, isUninitialized, isLoading, isFetching, isSuccess, isError]);
}

export function useGetDoversTargetCompanyLists(): UseQueryResult<CompanyListWithRequiredID[]> {
  const {
    data: companyListsOptions,
    isUninitialized,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useListCompanyListsQuery({
    limit: 100,
  });

  return React.useMemo(() => {
    return {
      data: companyListsOptions,
      isUninitialized,
      isLoading,
      isFetching,
      isSuccess,
      isError,
    };
  }, [companyListsOptions, isUninitialized, isLoading, isFetching, isSuccess, isError]);
}
