/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MovedJobEventField
 */
export interface MovedJobEventField {
    /**
     * 
     * @type {string}
     * @memberof MovedJobEventField
     */
    newJobTitle: string | null;
    /**
     * 
     * @type {string}
     * @memberof MovedJobEventField
     */
    oldJobTitle: string | null;
}

export function MovedJobEventFieldFromJSON(json: any): MovedJobEventField {
    return MovedJobEventFieldFromJSONTyped(json, false);
}

export function MovedJobEventFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovedJobEventField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newJobTitle': json['new_job_title'],
        'oldJobTitle': json['old_job_title'],
    };
}

export function MovedJobEventFieldToJSON(value?: MovedJobEventField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_job_title': value.newJobTitle,
        'old_job_title': value.oldJobTitle,
    };
}


