import { Box } from "@mui/material";
import React, { useMemo, useState } from "react";

import Arrow from "assets/icons/chevron-right.svg";
import { TitleWrapper, Title, Text, StyledArrow } from "components/accordion/styles";
import { Spacer } from "components/Spacer";

export enum AccordianVariant {
  Light = "LIGHT",
  Dark = "DARK",
}
// TODO (davin): refactor this to always accept only children elements
// OR enforce that EITHER children or a text prop is provided
type AccordionProps = {
  title: string | React.ReactNode;
  titleWhenOpen?: string;
  // defaults to accepting children elements
  children?: React.ReactElement;
  // if no children are provided, must pass in a text prop
  text?: React.ReactNode;
  titleColor?: string;
  titleSize?: "14px" | "16px";
  variant?: AccordianVariant;
  initialOpen?: boolean;
};

const Accordion = ({
  title,
  titleWhenOpen,
  text,
  children,
  titleColor,
  titleSize,
  variant = AccordianVariant.Dark,
  initialOpen = false,
}: AccordionProps): React.ReactElement => {
  const [open, setOpen] = useState(initialOpen);

  const accordionContent = useMemo((): React.ReactElement => {
    if (children) {
      return children;
    } else if (text) {
      return (
        <>
          <Spacer height={12} />
          <Text className={open ? "open" : ""}>{text}</Text>
        </>
      );
    }
    return <></>;
  }, [children, open, text]);

  return (
    <Box width="100%">
      <TitleWrapper onClick={(): void => setOpen(!open)} variant={variant}>
        <StyledArrow className={open ? "open" : ""} src={Arrow} small={titleSize === "14px"} variant={variant} />
        <Title color={titleColor} fontSize={titleSize}>
          {open && titleWhenOpen ? titleWhenOpen : title}
        </Title>
      </TitleWrapper>
      {open && accordionContent}
    </Box>
  );
};

export default Accordion;
