import { CurrentUserOnboardingStepResponseCurrentStepEnum } from "services/openapi";

export const OnboardingCurrentStepToUrlParamMap: Record<CurrentUserOnboardingStepResponseCurrentStepEnum, string> = {
  [CurrentUserOnboardingStepResponseCurrentStepEnum.JobSelection]: "create-job",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.EditCareersPage]: "edit-careers-page",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.CompanySetup]: "company-setup",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.InterviewStages]: "interview-stages",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.JdQuestion]: "jd-question",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.InboundApplicationForm]: "inbound-application",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.InboundCriteria]: "criteria",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.InviteNewUsers]: "invite-new-users",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.Completion]: "success",
  // Not sub-steps in any flow but defined here for completeness
  // Note: FeatureSelection and ClientCreation are steps in the first flow but route
  // to direct paths as opposed to sub-steps
  [CurrentUserOnboardingStepResponseCurrentStepEnum.FeatureSelection]: "",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.ClientCreation]: "",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.SourcingAutopilotJdQuestion]: "",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.AutoSchedulingHowItWorks]: "",
  [CurrentUserOnboardingStepResponseCurrentStepEnum.DoverInterviewerHowItWorks]: "",
};

export const CURRENT_FLOW_ID_PARAM_NAME = "currentFlowId";
