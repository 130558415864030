/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NextActionForCandidatesRequest
 */
export interface NextActionForCandidatesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof NextActionForCandidatesRequest
     */
    candidateIds?: Array<string>;
}

export function NextActionForCandidatesRequestFromJSON(json: any): NextActionForCandidatesRequest {
    return NextActionForCandidatesRequestFromJSONTyped(json, false);
}

export function NextActionForCandidatesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextActionForCandidatesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateIds': !exists(json, 'candidate_ids') ? undefined : json['candidate_ids'],
    };
}

export function NextActionForCandidatesRequestToJSON(value?: NextActionForCandidatesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_ids': value.candidateIds,
    };
}


