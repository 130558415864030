/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateFacingInterviewer,
    CandidateFacingInterviewerFromJSON,
    CandidateFacingInterviewerFromJSONTyped,
    CandidateFacingInterviewerToJSON,
} from './';

/**
 * 
 * @export
 * @interface MultipartInterviewSubstage
 */
export interface MultipartInterviewSubstage {
    /**
     * 
     * @type {string}
     * @memberof MultipartInterviewSubstage
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipartInterviewSubstage
     */
    readonly label?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipartInterviewSubstage
     */
    readonly description?: string;
    /**
     * 
     * @type {Array<CandidateFacingInterviewer>}
     * @memberof MultipartInterviewSubstage
     */
    readonly interviewers?: Array<CandidateFacingInterviewer>;
    /**
     * 
     * @type {Date}
     * @memberof MultipartInterviewSubstage
     */
    readonly startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MultipartInterviewSubstage
     */
    readonly endTime?: Date;
}

export function MultipartInterviewSubstageFromJSON(json: any): MultipartInterviewSubstage {
    return MultipartInterviewSubstageFromJSONTyped(json, false);
}

export function MultipartInterviewSubstageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipartInterviewSubstage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'interviewers': !exists(json, 'interviewers') ? undefined : ((json['interviewers'] as Array<any>).map(CandidateFacingInterviewerFromJSON)),
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
    };
}

export function MultipartInterviewSubstageToJSON(value?: MultipartInterviewSubstage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


