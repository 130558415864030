import { MenuItem } from "@mui/material";
import React from "react";

import { Tooltip } from "components/library/Tooltip";
import {
  DurationField,
  InterviewDurationContainer,
  RequiredAsterisk,
  StageDrawerFormLabel,
} from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import { getDurationOptions } from "components/SetupHiringPipeline/helpers";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";

export const EditStageDuration = ({
  fieldPrefix,
  required,
  isTakeHome,
  stageData,
}: {
  stageData: StageDataWrapper;
  fieldPrefix: string;
  required: boolean;
  isTakeHome: boolean;
}): React.ReactElement => {
  // This is bad, but so is everything else here

  if (isTakeHome) {
    return <></>;
  }

  return (
    <InterviewDurationContainer $isInline={isTakeHome}>
      <StageDrawerFormLabel bold>Duration {required && <RequiredAsterisk>*</RequiredAsterisk>}</StageDrawerFormLabel>
      <Tooltip
        title={stageData.useDoverInterviewer ? "You cannot change the duration for Dover interviews." : ""}
        placement="left"
      >
        <div>
          <DurationField
            variant="outlined"
            name={`${fieldPrefix}.duration`}
            disabled={stageData.useDoverInterviewer}
            required={required}
          >
            {getDurationOptions().map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option.value}
                  sx={{ "&.MuiButtonBase-root": { display: "flex", justifyContent: "start", padding: "8px" } }}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </DurationField>
        </div>
      </Tooltip>
    </InterviewDurationContainer>
  );
};
