import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { Overline } from "components/library/typography";
import { screenSizesNumbers } from "styles/theme";
import FilterDisplaySmall from "views/sourcing/Search/components/CandidateCard/FilterDisplaySmall";

interface CandidateFiltersBarProps {
  passingNiceToHaves: string[];
  failingNiceToHaves: string[];
  passingMustHaves: string[];
}
interface FiltersDisplaySmallListProps {
  filtersList: string[];
  passing: boolean;
}

const CandidateFiltersBar = React.memo(
  ({ passingNiceToHaves, failingNiceToHaves, passingMustHaves }: CandidateFiltersBarProps): React.ReactElement => {
    // These are all the nice to have and must have explanations in basic format (no extra details)
    const muiTheme = useTheme();
    const lessThanXLargeScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.desktopXS));
    const lessThanLargeScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptopL));

    let maxFiltersPerRow;
    if (lessThanLargeScreen) {
      maxFiltersPerRow = 1;
    } else if (lessThanXLargeScreen) {
      maxFiltersPerRow = 2;
    } else {
      maxFiltersPerRow = 3;
    }

    // first create React elements out of our nice to haves
    const passingNiceToHavesFilters = React.useMemo(() => {
      if (!passingNiceToHaves) {
        return [];
      }
      return CreateFiltersDisplaySmallList({ filtersList: passingNiceToHaves, passing: true });
    }, [passingNiceToHaves]);

    const failingNiceToHavesFilters = React.useMemo(() => {
      if (!failingNiceToHaves) {
        return [];
      }
      return CreateFiltersDisplaySmallList({ filtersList: failingNiceToHaves, passing: false });
    }, [failingNiceToHaves]);

    // concatenate passing and failing nice to haves in 1 NICE TO HAVE list
    const niceToHavesConcatenated = passingNiceToHavesFilters.concat(failingNiceToHavesFilters);

    // then the same for passing must haves (can assume they pass all must haves, or else backend is broken)
    const passingMustHavesFilters = React.useMemo(() => {
      if (!passingMustHaves) {
        return [];
      }
      return CreateFiltersDisplaySmallList({ filtersList: passingMustHaves, passing: true });
    }, [passingMustHaves]);

    return (
      <Stack direction="column" spacing={1} padding="5px 0px">
        {!!passingMustHavesFilters.length && (
          <FiltersListToDisplay
            label="Must have"
            filters={passingMustHavesFilters}
            maxElementsPerRow={maxFiltersPerRow}
          />
        )}
        {!!niceToHavesConcatenated.length && (
          <FiltersListToDisplay
            label="Nice to have"
            filters={niceToHavesConcatenated}
            maxElementsPerRow={maxFiltersPerRow}
          />
        )}
      </Stack>
    );
  }
);

const FiltersListToDisplay = React.memo(
  ({
    label,
    filters,
    maxElementsPerRow,
  }: {
    label: string;
    filters: React.ReactNode[];
    maxElementsPerRow: number;
  }): React.ReactElement => {
    // This memo is used to display the filters in a row, in basic format (no extra details), with
    // a + more button if there are more than maxElementsPerRow filters
    // one for nice to haves and one for must haves

    if (filters.length > maxElementsPerRow) {
      const numFiltersNotShown = filters.length - maxElementsPerRow;
      const numFiltersNotShownText = `+ ${numFiltersNotShown} more`;
      return (
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <Stack width="100px" padding="10px 0px">
            <Overline>{label}</Overline>
          </Stack>
          <Stack>
            <Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
              {filters.slice(0, maxElementsPerRow)}
              <FilterDisplaySmall key={"numFiltersNotShown"} attributeName={numFiltersNotShownText} />
            </Stack>
          </Stack>
        </Stack>
      );
    } else {
      // if < maxElementsPerRow, just display all filters
      return (
        <Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
          <Stack width="100px">
            <Overline>{label}</Overline>
          </Stack>
          {filters}
        </Stack>
      );
    }
  }
);
const CreateFiltersDisplaySmallList = ({ filtersList, passing }: FiltersDisplaySmallListProps): React.ReactNode[] => {
  // create an array of react elements made up of passing or failing filters
  if (!filtersList) {
    return [];
  }
  return filtersList!.map(filterName => (
    <FilterDisplaySmall key={filterName} attributeName={filterName} passing={passing} />
  ));
};

export default CandidateFiltersBar;
