/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BachelorsGraduationYear,
    BachelorsGraduationYearFromJSON,
    BachelorsGraduationYearFromJSONTyped,
    BachelorsGraduationYearToJSON,
    BooleanField,
    BooleanFieldFromJSON,
    BooleanFieldFromJSONTyped,
    BooleanFieldToJSON,
    CompanySize,
    CompanySizeFromJSON,
    CompanySizeFromJSONTyped,
    CompanySizeToJSON,
    CustomQueries,
    CustomQueriesFromJSON,
    CustomQueriesFromJSONTyped,
    CustomQueriesToJSON,
    Diversity,
    DiversityFromJSON,
    DiversityFromJSONTyped,
    DiversityToJSON,
    EducationLevel,
    EducationLevelFromJSON,
    EducationLevelFromJSONTyped,
    EducationLevelToJSON,
    ExcludedCompanyLists,
    ExcludedCompanyListsFromJSON,
    ExcludedCompanyListsFromJSONTyped,
    ExcludedCompanyListsToJSON,
    ExcludedCustomTitles,
    ExcludedCustomTitlesFromJSON,
    ExcludedCustomTitlesFromJSONTyped,
    ExcludedCustomTitlesToJSON,
    ExcludedSchoolLists,
    ExcludedSchoolListsFromJSON,
    ExcludedSchoolListsFromJSONTyped,
    ExcludedSchoolListsToJSON,
    ExcludedSeniorities,
    ExcludedSenioritiesFromJSON,
    ExcludedSenioritiesFromJSONTyped,
    ExcludedSenioritiesToJSON,
    FieldsOfStudy,
    FieldsOfStudyFromJSON,
    FieldsOfStudyFromJSONTyped,
    FieldsOfStudyToJSON,
    IndividualExcludedCompanyIds,
    IndividualExcludedCompanyIdsFromJSON,
    IndividualExcludedCompanyIdsFromJSONTyped,
    IndividualExcludedCompanyIdsToJSON,
    IndividualExcludedSchoolIds,
    IndividualExcludedSchoolIdsFromJSON,
    IndividualExcludedSchoolIdsFromJSONTyped,
    IndividualExcludedSchoolIdsToJSON,
    IndividualExcludedSchoolNames,
    IndividualExcludedSchoolNamesFromJSON,
    IndividualExcludedSchoolNamesFromJSONTyped,
    IndividualExcludedSchoolNamesToJSON,
    IndividualTargetCompanyIds,
    IndividualTargetCompanyIdsFromJSON,
    IndividualTargetCompanyIdsFromJSONTyped,
    IndividualTargetCompanyIdsToJSON,
    IndividualTargetSchoolIds,
    IndividualTargetSchoolIdsFromJSON,
    IndividualTargetSchoolIdsFromJSONTyped,
    IndividualTargetSchoolIdsToJSON,
    IndividualTargetSchoolNames,
    IndividualTargetSchoolNamesFromJSON,
    IndividualTargetSchoolNamesFromJSONTyped,
    IndividualTargetSchoolNamesToJSON,
    Industries,
    IndustriesFromJSON,
    IndustriesFromJSONTyped,
    IndustriesToJSON,
    Locations,
    LocationsFromJSON,
    LocationsFromJSONTyped,
    LocationsToJSON,
    ProfileSearchKeywords,
    ProfileSearchKeywordsFromJSON,
    ProfileSearchKeywordsFromJSONTyped,
    ProfileSearchKeywordsToJSON,
    RankRange,
    RankRangeFromJSON,
    RankRangeFromJSONTyped,
    RankRangeToJSON,
    SpecificYearsOfExperience,
    SpecificYearsOfExperienceFromJSON,
    SpecificYearsOfExperienceFromJSONTyped,
    SpecificYearsOfExperienceToJSON,
    SpecificYoeByT2,
    SpecificYoeByT2FromJSON,
    SpecificYoeByT2FromJSONTyped,
    SpecificYoeByT2ToJSON,
    TargetCompaniesOnly,
    TargetCompaniesOnlyFromJSON,
    TargetCompaniesOnlyFromJSONTyped,
    TargetCompaniesOnlyToJSON,
    TargetCompanyLists,
    TargetCompanyListsFromJSON,
    TargetCompanyListsFromJSONTyped,
    TargetCompanyListsToJSON,
    TargetCustomTitles,
    TargetCustomTitlesFromJSON,
    TargetCustomTitlesFromJSONTyped,
    TargetCustomTitlesToJSON,
    TargetPersonas,
    TargetPersonasFromJSON,
    TargetPersonasFromJSONTyped,
    TargetPersonasToJSON,
    TargetSchoolLists,
    TargetSchoolListsFromJSON,
    TargetSchoolListsFromJSONTyped,
    TargetSchoolListsToJSON,
    TargetSeniorities,
    TargetSenioritiesFromJSON,
    TargetSenioritiesFromJSONTyped,
    TargetSenioritiesToJSON,
    TotalYearsOfExperience,
    TotalYearsOfExperienceFromJSON,
    TotalYearsOfExperienceFromJSONTyped,
    TotalYearsOfExperienceToJSON,
    YearsAtCurrentCompany,
    YearsAtCurrentCompanyFromJSON,
    YearsAtCurrentCompanyFromJSONTyped,
    YearsAtCurrentCompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchV3Params
 */
export interface SearchV3Params {
    /**
     * 
     * @type {Array<TargetPersonas>}
     * @memberof SearchV3Params
     */
    targetPersonas: Array<TargetPersonas>;
    /**
     * 
     * @type {Array<TargetCustomTitles>}
     * @memberof SearchV3Params
     */
    targetCustomTitles: Array<TargetCustomTitles>;
    /**
     * 
     * @type {Array<ExcludedCustomTitles>}
     * @memberof SearchV3Params
     */
    excludedCustomTitles: Array<ExcludedCustomTitles>;
    /**
     * 
     * @type {Array<BooleanField>}
     * @memberof SearchV3Params
     */
    customTitlesSoftMatch: Array<BooleanField>;
    /**
     * 
     * @type {Array<BooleanField>}
     * @memberof SearchV3Params
     */
    personasMostRecentOnly: Array<BooleanField>;
    /**
     * 
     * @type {Array<Locations>}
     * @memberof SearchV3Params
     */
    targetLocations: Array<Locations>;
    /**
     * 
     * @type {Array<Locations>}
     * @memberof SearchV3Params
     */
    excludedLocations: Array<Locations>;
    /**
     * 
     * @type {Array<TargetSeniorities>}
     * @memberof SearchV3Params
     */
    targetSeniorities: Array<TargetSeniorities>;
    /**
     * 
     * @type {Array<ExcludedSeniorities>}
     * @memberof SearchV3Params
     */
    excludedSeniorities: Array<ExcludedSeniorities>;
    /**
     * 
     * @type {Array<TotalYearsOfExperience>}
     * @memberof SearchV3Params
     */
    totalYearsOfExperience: Array<TotalYearsOfExperience>;
    /**
     * 
     * @type {Array<SpecificYearsOfExperience>}
     * @memberof SearchV3Params
     */
    specificYearsOfExperience: Array<SpecificYearsOfExperience>;
    /**
     * 
     * @type {Array<SpecificYoeByT2>}
     * @memberof SearchV3Params
     */
    specificYearsOfExperienceByT2: Array<SpecificYoeByT2>;
    /**
     * 
     * @type {Array<YearsAtCurrentCompany>}
     * @memberof SearchV3Params
     */
    yearsAtCurrentCompanyRange: Array<YearsAtCurrentCompany>;
    /**
     * 
     * @type {Array<BachelorsGraduationYear>}
     * @memberof SearchV3Params
     */
    bachelorsGraduationYearRange: Array<BachelorsGraduationYear>;
    /**
     * 
     * @type {Array<Industries>}
     * @memberof SearchV3Params
     */
    industries: Array<Industries>;
    /**
     * 
     * @type {Array<Industries>}
     * @memberof SearchV3Params
     */
    excludedIndustries: Array<Industries>;
    /**
     * 
     * @type {Array<BooleanField>}
     * @memberof SearchV3Params
     */
    industriesMostRecentOnly: Array<BooleanField>;
    /**
     * 
     * @type {Array<TargetCompanyLists>}
     * @memberof SearchV3Params
     */
    targetCompanyLists: Array<TargetCompanyLists>;
    /**
     * 
     * @type {Array<ExcludedCompanyLists>}
     * @memberof SearchV3Params
     */
    excludedCompanyLists: Array<ExcludedCompanyLists>;
    /**
     * 
     * @type {Array<IndividualTargetCompanyIds>}
     * @memberof SearchV3Params
     */
    individualTargetCompanyIds: Array<IndividualTargetCompanyIds>;
    /**
     * 
     * @type {Array<IndividualExcludedCompanyIds>}
     * @memberof SearchV3Params
     */
    individualExcludedCompanyIds: Array<IndividualExcludedCompanyIds>;
    /**
     * 
     * @type {Array<BooleanField>}
     * @memberof SearchV3Params
     */
    targetCompaniesMostRecentOnly: Array<BooleanField>;
    /**
     * 
     * @type {Array<TargetCompaniesOnly>}
     * @memberof SearchV3Params
     */
    targetCompaniesOnly: Array<TargetCompaniesOnly>;
    /**
     * 
     * @type {Array<RankRange>}
     * @memberof SearchV3Params
     */
    companyRankRange: Array<RankRange>;
    /**
     * 
     * @type {Array<CompanySize>}
     * @memberof SearchV3Params
     */
    companySize: Array<CompanySize>;
    /**
     * 
     * @type {Array<CompanySize>}
     * @memberof SearchV3Params
     */
    excludedCompanySize: Array<CompanySize>;
    /**
     * 
     * @type {Array<TargetSchoolLists>}
     * @memberof SearchV3Params
     */
    targetSchoolLists: Array<TargetSchoolLists>;
    /**
     * 
     * @type {Array<ExcludedSchoolLists>}
     * @memberof SearchV3Params
     */
    excludedSchoolLists: Array<ExcludedSchoolLists>;
    /**
     * 
     * @type {Array<IndividualTargetSchoolIds>}
     * @memberof SearchV3Params
     */
    individualTargetSchoolIds: Array<IndividualTargetSchoolIds>;
    /**
     * 
     * @type {Array<IndividualTargetSchoolNames>}
     * @memberof SearchV3Params
     */
    individualTargetSchoolNames: Array<IndividualTargetSchoolNames>;
    /**
     * 
     * @type {Array<IndividualExcludedSchoolIds>}
     * @memberof SearchV3Params
     */
    individualExcludedSchoolIds: Array<IndividualExcludedSchoolIds>;
    /**
     * 
     * @type {Array<IndividualExcludedSchoolNames>}
     * @memberof SearchV3Params
     */
    individualExcludedSchoolNames: Array<IndividualExcludedSchoolNames>;
    /**
     * 
     * @type {Array<RankRange>}
     * @memberof SearchV3Params
     */
    schoolRankRange: Array<RankRange>;
    /**
     * 
     * @type {Array<FieldsOfStudy>}
     * @memberof SearchV3Params
     */
    fieldsOfStudy: Array<FieldsOfStudy>;
    /**
     * 
     * @type {Array<EducationLevel>}
     * @memberof SearchV3Params
     */
    educationLevel: Array<EducationLevel>;
    /**
     * 
     * @type {Array<ProfileSearchKeywords>}
     * @memberof SearchV3Params
     */
    keywords: Array<ProfileSearchKeywords>;
    /**
     * 
     * @type {Array<ProfileSearchKeywords>}
     * @memberof SearchV3Params
     */
    deniedKeywords: Array<ProfileSearchKeywords>;
    /**
     * 
     * @type {Array<Diversity>}
     * @memberof SearchV3Params
     */
    diversity: Array<Diversity>;
    /**
     * 
     * @type {Array<BooleanField>}
     * @memberof SearchV3Params
     */
    excludeFrequentJobSwitching: Array<BooleanField>;
    /**
     * 
     * @type {Array<CustomQueries>}
     * @memberof SearchV3Params
     */
    customQueries: Array<CustomQueries>;
    /**
     * 
     * @type {number}
     * @memberof SearchV3Params
     */
    minScoreRatio: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchV3Params
     */
    isReengagementSearch: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchV3Params
     */
    transformsToApplyDuringNormalization: Array<string>;
}

export function SearchV3ParamsFromJSON(json: any): SearchV3Params {
    return SearchV3ParamsFromJSONTyped(json, false);
}

export function SearchV3ParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchV3Params {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetPersonas': ((json['target_personas'] as Array<any>).map(TargetPersonasFromJSON)),
        'targetCustomTitles': ((json['target_custom_titles'] as Array<any>).map(TargetCustomTitlesFromJSON)),
        'excludedCustomTitles': ((json['excluded_custom_titles'] as Array<any>).map(ExcludedCustomTitlesFromJSON)),
        'customTitlesSoftMatch': ((json['custom_titles_soft_match'] as Array<any>).map(BooleanFieldFromJSON)),
        'personasMostRecentOnly': ((json['personas_most_recent_only'] as Array<any>).map(BooleanFieldFromJSON)),
        'targetLocations': ((json['target_locations'] as Array<any>).map(LocationsFromJSON)),
        'excludedLocations': ((json['excluded_locations'] as Array<any>).map(LocationsFromJSON)),
        'targetSeniorities': ((json['target_seniorities'] as Array<any>).map(TargetSenioritiesFromJSON)),
        'excludedSeniorities': ((json['excluded_seniorities'] as Array<any>).map(ExcludedSenioritiesFromJSON)),
        'totalYearsOfExperience': ((json['total_years_of_experience'] as Array<any>).map(TotalYearsOfExperienceFromJSON)),
        'specificYearsOfExperience': ((json['specific_years_of_experience'] as Array<any>).map(SpecificYearsOfExperienceFromJSON)),
        'specificYearsOfExperienceByT2': ((json['specific_years_of_experience_by_t2'] as Array<any>).map(SpecificYoeByT2FromJSON)),
        'yearsAtCurrentCompanyRange': ((json['years_at_current_company_range'] as Array<any>).map(YearsAtCurrentCompanyFromJSON)),
        'bachelorsGraduationYearRange': ((json['bachelors_graduation_year_range'] as Array<any>).map(BachelorsGraduationYearFromJSON)),
        'industries': ((json['industries'] as Array<any>).map(IndustriesFromJSON)),
        'excludedIndustries': ((json['excluded_industries'] as Array<any>).map(IndustriesFromJSON)),
        'industriesMostRecentOnly': ((json['industries_most_recent_only'] as Array<any>).map(BooleanFieldFromJSON)),
        'targetCompanyLists': ((json['target_company_lists'] as Array<any>).map(TargetCompanyListsFromJSON)),
        'excludedCompanyLists': ((json['excluded_company_lists'] as Array<any>).map(ExcludedCompanyListsFromJSON)),
        'individualTargetCompanyIds': ((json['individual_target_company_ids'] as Array<any>).map(IndividualTargetCompanyIdsFromJSON)),
        'individualExcludedCompanyIds': ((json['individual_excluded_company_ids'] as Array<any>).map(IndividualExcludedCompanyIdsFromJSON)),
        'targetCompaniesMostRecentOnly': ((json['target_companies_most_recent_only'] as Array<any>).map(BooleanFieldFromJSON)),
        'targetCompaniesOnly': ((json['target_companies_only'] as Array<any>).map(TargetCompaniesOnlyFromJSON)),
        'companyRankRange': ((json['company_rank_range'] as Array<any>).map(RankRangeFromJSON)),
        'companySize': ((json['company_size'] as Array<any>).map(CompanySizeFromJSON)),
        'excludedCompanySize': ((json['excluded_company_size'] as Array<any>).map(CompanySizeFromJSON)),
        'targetSchoolLists': ((json['target_school_lists'] as Array<any>).map(TargetSchoolListsFromJSON)),
        'excludedSchoolLists': ((json['excluded_school_lists'] as Array<any>).map(ExcludedSchoolListsFromJSON)),
        'individualTargetSchoolIds': ((json['individual_target_school_ids'] as Array<any>).map(IndividualTargetSchoolIdsFromJSON)),
        'individualTargetSchoolNames': ((json['individual_target_school_names'] as Array<any>).map(IndividualTargetSchoolNamesFromJSON)),
        'individualExcludedSchoolIds': ((json['individual_excluded_school_ids'] as Array<any>).map(IndividualExcludedSchoolIdsFromJSON)),
        'individualExcludedSchoolNames': ((json['individual_excluded_school_names'] as Array<any>).map(IndividualExcludedSchoolNamesFromJSON)),
        'schoolRankRange': ((json['school_rank_range'] as Array<any>).map(RankRangeFromJSON)),
        'fieldsOfStudy': ((json['fields_of_study'] as Array<any>).map(FieldsOfStudyFromJSON)),
        'educationLevel': ((json['education_level'] as Array<any>).map(EducationLevelFromJSON)),
        'keywords': ((json['keywords'] as Array<any>).map(ProfileSearchKeywordsFromJSON)),
        'deniedKeywords': ((json['denied_keywords'] as Array<any>).map(ProfileSearchKeywordsFromJSON)),
        'diversity': ((json['diversity'] as Array<any>).map(DiversityFromJSON)),
        'excludeFrequentJobSwitching': ((json['exclude_frequent_job_switching'] as Array<any>).map(BooleanFieldFromJSON)),
        'customQueries': ((json['custom_queries'] as Array<any>).map(CustomQueriesFromJSON)),
        'minScoreRatio': json['min_score_ratio'],
        'isReengagementSearch': json['is_reengagement_search'],
        'transformsToApplyDuringNormalization': json['transforms_to_apply_during_normalization'],
    };
}

export function SearchV3ParamsToJSON(value?: SearchV3Params | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'target_personas': ((value.targetPersonas as Array<any>).map(TargetPersonasToJSON)),
        'target_custom_titles': ((value.targetCustomTitles as Array<any>).map(TargetCustomTitlesToJSON)),
        'excluded_custom_titles': ((value.excludedCustomTitles as Array<any>).map(ExcludedCustomTitlesToJSON)),
        'custom_titles_soft_match': ((value.customTitlesSoftMatch as Array<any>).map(BooleanFieldToJSON)),
        'personas_most_recent_only': ((value.personasMostRecentOnly as Array<any>).map(BooleanFieldToJSON)),
        'target_locations': ((value.targetLocations as Array<any>).map(LocationsToJSON)),
        'excluded_locations': ((value.excludedLocations as Array<any>).map(LocationsToJSON)),
        'target_seniorities': ((value.targetSeniorities as Array<any>).map(TargetSenioritiesToJSON)),
        'excluded_seniorities': ((value.excludedSeniorities as Array<any>).map(ExcludedSenioritiesToJSON)),
        'total_years_of_experience': ((value.totalYearsOfExperience as Array<any>).map(TotalYearsOfExperienceToJSON)),
        'specific_years_of_experience': ((value.specificYearsOfExperience as Array<any>).map(SpecificYearsOfExperienceToJSON)),
        'specific_years_of_experience_by_t2': ((value.specificYearsOfExperienceByT2 as Array<any>).map(SpecificYoeByT2ToJSON)),
        'years_at_current_company_range': ((value.yearsAtCurrentCompanyRange as Array<any>).map(YearsAtCurrentCompanyToJSON)),
        'bachelors_graduation_year_range': ((value.bachelorsGraduationYearRange as Array<any>).map(BachelorsGraduationYearToJSON)),
        'industries': ((value.industries as Array<any>).map(IndustriesToJSON)),
        'excluded_industries': ((value.excludedIndustries as Array<any>).map(IndustriesToJSON)),
        'industries_most_recent_only': ((value.industriesMostRecentOnly as Array<any>).map(BooleanFieldToJSON)),
        'target_company_lists': ((value.targetCompanyLists as Array<any>).map(TargetCompanyListsToJSON)),
        'excluded_company_lists': ((value.excludedCompanyLists as Array<any>).map(ExcludedCompanyListsToJSON)),
        'individual_target_company_ids': ((value.individualTargetCompanyIds as Array<any>).map(IndividualTargetCompanyIdsToJSON)),
        'individual_excluded_company_ids': ((value.individualExcludedCompanyIds as Array<any>).map(IndividualExcludedCompanyIdsToJSON)),
        'target_companies_most_recent_only': ((value.targetCompaniesMostRecentOnly as Array<any>).map(BooleanFieldToJSON)),
        'target_companies_only': ((value.targetCompaniesOnly as Array<any>).map(TargetCompaniesOnlyToJSON)),
        'company_rank_range': ((value.companyRankRange as Array<any>).map(RankRangeToJSON)),
        'company_size': ((value.companySize as Array<any>).map(CompanySizeToJSON)),
        'excluded_company_size': ((value.excludedCompanySize as Array<any>).map(CompanySizeToJSON)),
        'target_school_lists': ((value.targetSchoolLists as Array<any>).map(TargetSchoolListsToJSON)),
        'excluded_school_lists': ((value.excludedSchoolLists as Array<any>).map(ExcludedSchoolListsToJSON)),
        'individual_target_school_ids': ((value.individualTargetSchoolIds as Array<any>).map(IndividualTargetSchoolIdsToJSON)),
        'individual_target_school_names': ((value.individualTargetSchoolNames as Array<any>).map(IndividualTargetSchoolNamesToJSON)),
        'individual_excluded_school_ids': ((value.individualExcludedSchoolIds as Array<any>).map(IndividualExcludedSchoolIdsToJSON)),
        'individual_excluded_school_names': ((value.individualExcludedSchoolNames as Array<any>).map(IndividualExcludedSchoolNamesToJSON)),
        'school_rank_range': ((value.schoolRankRange as Array<any>).map(RankRangeToJSON)),
        'fields_of_study': ((value.fieldsOfStudy as Array<any>).map(FieldsOfStudyToJSON)),
        'education_level': ((value.educationLevel as Array<any>).map(EducationLevelToJSON)),
        'keywords': ((value.keywords as Array<any>).map(ProfileSearchKeywordsToJSON)),
        'denied_keywords': ((value.deniedKeywords as Array<any>).map(ProfileSearchKeywordsToJSON)),
        'diversity': ((value.diversity as Array<any>).map(DiversityToJSON)),
        'exclude_frequent_job_switching': ((value.excludeFrequentJobSwitching as Array<any>).map(BooleanFieldToJSON)),
        'custom_queries': ((value.customQueries as Array<any>).map(CustomQueriesToJSON)),
        'min_score_ratio': value.minScoreRatio,
        'is_reengagement_search': value.isReengagementSearch,
        'transforms_to_apply_during_normalization': value.transformsToApplyDuringNormalization,
    };
}


