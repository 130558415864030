/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IndividualTargetSchoolNames
 */
export interface IndividualTargetSchoolNames {
    /**
     * 
     * @type {string}
     * @memberof IndividualTargetSchoolNames
     */
    field: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IndividualTargetSchoolNames
     */
    value: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualTargetSchoolNames
     */
    required?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof IndividualTargetSchoolNames
     */
    weight?: number | null;
}

export function IndividualTargetSchoolNamesFromJSON(json: any): IndividualTargetSchoolNames {
    return IndividualTargetSchoolNamesFromJSONTyped(json, false);
}

export function IndividualTargetSchoolNamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndividualTargetSchoolNames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'value': json['value'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
    };
}

export function IndividualTargetSchoolNamesToJSON(value?: IndividualTargetSchoolNames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'value': value.value,
        'required': value.required,
        'weight': value.weight,
    };
}


