import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo, useState } from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { PageTitle } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { useEditableApplicationQuestions } from "hooks/useEditableApplicationQuestions";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import {
  useCreateApplicationQuestionMutation,
  useDeleteApplicationQuestionMutation,
  useListApplicationQuestionsQuery,
  usePartialUpdateApplicationQuestionMutation,
} from "services/doverapi/endpoints/applicationQuestion";
import { colors, screenSizesNumbers } from "styles/theme";
import { MobilePreviewToggle } from "views/create-job/AtsSetupFlow/components/MobilePreviewToggle";
import { CareersPagePreviewContainer, PreviewContainer } from "views/create-job/styles";
import { OnboardingFormProps } from "views/create-job/types";
import InboundApplicationPortalWrapper from "views/inboundExternal/InboundApplication";
import { EditableApplicationFormQuestions } from "views/job/JobSetup/steps/JobPosting/components/EditableApplicationForm";

const CustomizeInboundApplicationForm = ({
  onSaveAndNext,
  onBack,
  isLoading: isLoadingProp,
}: OnboardingFormProps): React.ReactElement => {
  const [mobilePreviewModeOn, setMobilePreviewModeOn] = useState(false);
  const jobId = useJobIdFromUrl();

  const muiTheme = useTheme();
  const smallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));

  const {
    editedApplicationQuestions,
    setEditedApplicationQuestions,
    saveDisabled,
    saveApplicationQuestions,
  } = useEditableApplicationQuestions();
  const { isFetching: fetchingApplicationQuestions } = useListApplicationQuestionsQuery(
    jobId ? { job: jobId } : skipToken
  );
  const [, { isLoading: isUpdatingApplicationQuestion }] = usePartialUpdateApplicationQuestionMutation();
  const [, { isLoading: isDeletingApplicationQuestion }] = useDeleteApplicationQuestionMutation();
  const [, { isLoading: isCreatingApplicationQuestion }] = useCreateApplicationQuestionMutation();

  const isLoading =
    isLoadingProp ||
    isUpdatingApplicationQuestion ||
    isDeletingApplicationQuestion ||
    isCreatingApplicationQuestion ||
    fetchingApplicationQuestions;

  const innerForm = (
    <>
      <Stack
        p={2}
        style={{
          border: `1px solid ${colors.grayscale.gray025}`,
          boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)",
          borderRadius: "8px",
        }}
        spacing={1}
      >
        <EditableApplicationFormQuestions
          editedApplicationQuestions={editedApplicationQuestions}
          setEditedApplicationQuestions={setEditedApplicationQuestions}
          fetchingApplicationQuestions={fetchingApplicationQuestions}
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <Button
          variant={ButtonVariant.Secondary}
          onClick={(): void => {
            saveApplicationQuestions();
          }}
        >
          Preview changes
        </Button>
      </Stack>
    </>
  );

  const applicationPagePreview = useMemo(
    () => (
      <PreviewContainer spacing={2} alignItems="flex-start">
        {!smallScreen && <PageTitle>Preview</PageTitle>}
        <CareersPagePreviewContainer>
          <DoverLoadingOverlay active={isLoading}>
            <Box style={{ pointerEvents: "none" }}>
              <InboundApplicationPortalWrapper />
            </Box>
          </DoverLoadingOverlay>
        </CareersPagePreviewContainer>
      </PreviewContainer>
    ),
    [isLoading, smallScreen]
  );

  return (
    <Stack
      boxSizing="border-box"
      direction="row"
      height="100%"
      maxHeight="calc(100vh - 40px)"
      width="1600px"
      maxWidth="100%"
      overflow="hidden"
      justifyContent="center"
    >
      <Stack
        flexGrow={1}
        position="relative"
        width="45%"
        height="100%"
        sx={{ backgroundColor: "white" }}
        justifyContent="space-between"
        overflow="hidden"
      >
        <Stack spacing={2} flexGrow={1} overflow="auto" height="100%" py={2} px={3} justifyContent="space-between">
          <Box>
            <Box pb={2}>
              <PageTitle>Edit your application form</PageTitle>
            </Box>
            {smallScreen && (
              <MobilePreviewToggle previewModeOn={mobilePreviewModeOn} setPreviewModeOn={setMobilePreviewModeOn} />
            )}
            {mobilePreviewModeOn && smallScreen ? applicationPagePreview : innerForm}
          </Box>
        </Stack>
        <Stack
          position="sticky"
          bottom="-16px"
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          py={2}
          px={3}
          alignItems="center"
          borderTop={`1px solid ${colors.grayscale.gray200}`}
          sx={{ backgroundColor: "white" }}
        >
          <Button variant={ButtonVariant.Secondary} onClick={onBack} disabled={isLoadingProp}>
            Back
          </Button>
          <Button
            variant={ButtonVariant.Primary}
            disabled={saveDisabled || isLoadingProp}
            onClick={async (): Promise<void> => {
              saveApplicationQuestions();
              await onSaveAndNext();
            }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
      {!smallScreen && applicationPagePreview}
    </Stack>
  );
};

export default CustomizeInboundApplicationForm;
