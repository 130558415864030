import { Box, Stack } from "@mui/material";
import React, { useCallback } from "react";

import CalendarSVG from "assets/icons/calendar.svg";
import { GrayButton } from "components/Button";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { CandidateBio, CandidateBioSchedulingOwnershipEnum, NextActionSchedulingStateEnum } from "services/openapi";
import { StyledSVG } from "styles/layout";
import { colors } from "styles/theme";
import { isTakeHomeStage } from "utils/isStage";
import { NextActionCardSkeleton } from "views/CandidateDetail/components/next-action/NextActionCardSkeleton";
import { ConciergeRescheduleButton } from "views/CandidateDetail/components/next-action/states/ConciergeScheduled";
import { SubmitFeedbackButton } from "views/CandidateDetail/components/next-action/states/SubmitFeedbackButton";
import {
  formatDateShort,
  formatTime,
  NextActionStateComponentProps,
} from "views/CandidateDetail/components/next-action/utils/constants";
import { getNextActionTitle, getNextActionColors } from "views/CandidateDetail/components/next-action/utils/helpers";
import { getInterviewerNameFromNextAction } from "views/CandidateDetail/utils";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";

export function InterviewScheduled({ candidateBio }: NextActionStateComponentProps): React.ReactElement {
  return (
    <NextActionCardSkeleton
      title={getNextActionTitle(candidateBio?.nextAction)}
      colors={getNextActionColors(candidateBio?.nextAction)}
      description={<InterviewScheduledDescription candidateBio={candidateBio} />}
      buttons={<InterviewScheduledButtons candidateBio={candidateBio} />}
    />
  );
}

const InterviewScheduledDescription = ({ candidateBio }: { candidateBio: CandidateBio }): React.ReactElement => {
  if (candidateBio.candidatePipelineStage && isTakeHomeStage(candidateBio.candidatePipelineStage)) {
    return <BodySmall>Take-home in progress</BodySmall>;
  }

  const interviewerName = getInterviewerNameFromNextAction(candidateBio.nextAction!);
  const interview = candidateBio.nextAction!.currentInterview;
  const startDate = interview?.startDate;
  const stageName = interview?.stageName || "";

  let text = `${stageName} scheduled`;
  if (startDate) {
    if (interviewerName) {
      text += ` with ${interviewerName}`;
    }
    text += ` on ${formatDateShort(startDate)} at ${formatTime(startDate)}`;
  }
  return (
    <Stack spacing={1}>
      <BodySmall>{text}.</BodySmall>
      <SubmitFeedbackButton candidateBio={candidateBio} />
    </Stack>
  );
};

export const InterviewScheduledButtons = ({
  candidateBio,
  inPipeline,
}: {
  candidateBio: CandidateBio;
  inPipeline?: boolean;
}): React.ReactElement => {
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();
  const doverManaged = candidateBio.schedulingOwnership === CandidateBioSchedulingOwnershipEnum.DoverHandlesScheduling;

  const clickReschedule = useCallback(() => {
    const candidateId = candidateBio.id;
    if (!candidateId) return;

    showCandidateActionModal({ candidateId, modalType: CandidateActionModalType.Reschedule });
  }, [candidateBio.id, showCandidateActionModal]);

  const isInitialReview = candidateBio.nextAction?.schedulingState === NextActionSchedulingStateEnum.InitialReview;
  const hasInterviewScheduledThroughDover = candidateBio.nextAction?.currentInterview?.startDate;

  if (isInitialReview || (!doverManaged && !hasInterviewScheduledThroughDover)) {
    if (inPipeline)
      return (
        <Cell>
          <BodySmall italic color={colors.grayscale.gray400}>
            No action available
          </BodySmall>
        </Cell>
      );
    return <></>;
  }

  // TODO: Jacky - Make concierge reschedule button

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        {doverManaged ? (
          <ConciergeRescheduleButton candidateBio={candidateBio} />
        ) : (
          <GrayButton $fontSize="14px" onClick={clickReschedule} disabled={!candidateBio.id}>
            <StyledSVG src={CalendarSVG} /> <Spacer width="4px" />
            Reschedule
          </GrayButton>
        )}
      </Box>
    </>
  );
};
