import { DjangoListResponseType } from "types";

type EntityToJSONFunc<EntityType> = (value?: EntityType | null) => any;

export function djangoListResponseToJSON<EntityType>(
  // The typed object as typically returned by openapi's list endpoint responses.
  value: DjangoListResponseType<EntityType> | null | undefined,
  // A function exported by our openapi client responsible for converting individual typed entity objects into raw JSON.
  // You can find these functions by searching for "<entity-name>ToJSON" within services/openapi.
  entityToJSONFunc: EntityToJSONFunc<EntityType>
): any {
  // Modeled after openapi's InlineResponseXXXXXToJSON functions, which takes typed list response objects and returns
  // raw JSON as returned by the raw network request.

  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    count: value.count,
    next: value.next,
    previous: value.previous,
    results: (value.results as Array<any>).map(entityToJSONFunc),
  };
}

export function getUrlParams(url: string): URLSearchParams {
  const fullURL = new URL(url);
  return new URLSearchParams(fullURL.search);
}
