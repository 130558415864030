import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue } from "jotai";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useField, useForm } from "react-final-form";
import { useParams } from "react-router";

import { EmailEditorMode, EmailEditorModeAtom } from "components/library/TipTap/atoms";
import { EmailEditor as TipTapEditor } from "components/library/TipTap/EmailEditor";
import { substituteVariables } from "components/library/TipTap/utils";
import { useGetHiringStageEmailTemplateQuery } from "services/doverapi/endpoints/emailTemplates";
import { getPlainTextFromHtml } from "utils/draftJS";

export const EmailEditor = (): React.ReactElement => {
  const form = useForm();
  useField("bodyTemplate");
  useField("subjectTemplate");

  const selectedEmailEditorTab = useAtomValue(EmailEditorModeAtom);

  const { emailTemplateId } = useParams<{
    emailTemplateId: string;
  }>();

  const [initialBody, setInitialBody] = useState<string | null | undefined>(undefined);
  const [initialSubject, setInitialSubject] = useState<string | null | undefined>(undefined);
  const [body, setBody] = useState<string | null | undefined>(undefined);
  const [subject, setSubject] = useState<string | null | undefined>(undefined);

  const { data: emailTemplate } = useGetHiringStageEmailTemplateQuery(
    emailTemplateId ? { id: emailTemplateId } : skipToken
  );

  useEffect(() => {
    if (initialBody === undefined && initialSubject === undefined && emailTemplate) {
      setInitialBody(emailTemplate?.bodyTemplate);
      setBody(emailTemplate?.bodyTemplate);
      setInitialSubject(getPlainTextFromHtml(emailTemplate?.subjectTemplate ?? ""));
      setSubject(getPlainTextFromHtml(emailTemplate?.subjectTemplate ?? ""));
    }
  }, [emailTemplate, emailTemplate?.bodyTemplate, emailTemplate?.subjectTemplate, initialBody, initialSubject]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeDebounced = useCallback(
    debounce(
      ({ bodyEditorState, subjectEditorState }: { bodyEditorState: string; subjectEditorState: string }): void => {
        if (!!bodyEditorState && !!subjectEditorState) {
          form.batch(() => {
            form.change("subjectTemplate", subjectEditorState);
            form.change("bodyTemplate", bodyEditorState);
          });
        }
      },
      300
    ),
    [emailTemplateId]
  );

  // Preview Mode
  if (selectedEmailEditorTab === EmailEditorMode.Preview) {
    const substitutionMap = {
      SCHEDULING_LINK: "findatime.io/schedule/77f584c2-2d53-4af8-8ce4-443ed601e497",
      FIRST_NAME: "Alice",
      SENDER_FIRST_NAME: "Douglas",
      INTERVIEWER_FIRST_NAME: "Balice",
      JOB_TITLE: "HR Generalist",
      OLD_JOB_TITLE: "HR Specialist",
      CLIENT_NAME: "Dover",
      INTERVIEWER_ROLE_TITLE_WITH_ARTICLE: "the Chief Nose Model",
      INTERVIEWER_ROLE_TITLE_WITHOUT_ARTICLE: "Chief Nose Model",
      INTERVIEW_DURATION: "2 hours and 45 minutes",
      INTERVIEW_SCHEDULE: "\n\t1. Intro call: 45 minutes\n\t2. Pair Programming: 2 hours and 45 minutes\n\n",
      JOB_DESCRIPTION_LINK: "www.trover.io/careers/my-awesome-job",
    };

    const substitutedBody = substituteVariables(body, substitutionMap);

    const substitutedSubject = substituteVariables(getPlainTextFromHtml(subject ?? ""), substitutionMap);

    return <TipTapEditor showPreviewTab readOnly body={substitutedBody} subject={substitutedSubject} />;
  }

  const onSubjectChanged = (updatedSubject: string): void => {
    onChangeDebounced({
      bodyEditorState: body ?? "",
      subjectEditorState: updatedSubject,
    });

    setSubject(updatedSubject);
  };

  const onBodyChanged = (updatedBody: string): void => {
    onChangeDebounced({
      bodyEditorState: updatedBody,
      subjectEditorState: subject ?? "",
    });

    setBody(updatedBody);
  };

  return (
    <TipTapEditor
      showPreviewTab
      body={initialBody ?? ""}
      subject={initialSubject ?? ""}
      onBodyChanged={onBodyChanged}
      onSubjectChanged={onSubjectChanged}
    />
  );
};
