/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobSetupPersona
 */
export interface JobSetupPersona {
    /**
     * 
     * @type {number}
     * @memberof JobSetupPersona
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof JobSetupPersona
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof JobSetupPersona
     */
    jobCategory?: JobSetupPersonaJobCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSetupPersona
     */
    readonly jobCategoryDisplay?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JobSetupPersona
     */
    readonly titlePatterns?: Array<string>;
    /**
     * What a customer would call this
     * @type {string}
     * @memberof JobSetupPersona
     */
    customerFacingName?: string | null;
    /**
     * Does the persona represent an IC or a manager
     * @type {boolean}
     * @memberof JobSetupPersona
     */
    isManagement?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof JobSetupPersona
     */
    isAlpha?: boolean;
    /**
     * This field is displayed in-app to explain what describe each persona
     * @type {string}
     * @memberof JobSetupPersona
     */
    definition?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum JobSetupPersonaJobCategoryEnum {
    DataAnalytics = 'DATA_ANALYTICS',
    Design = 'DESIGN',
    Engineering = 'ENGINEERING',
    Finance = 'FINANCE',
    InformationTechnology = 'INFORMATION_TECHNOLOGY',
    Legal = 'LEGAL',
    Marketing = 'MARKETING',
    Operations = 'OPERATIONS',
    Product = 'PRODUCT',
    Sales = 'SALES',
    Hr = 'HR',
    Custom = 'CUSTOM'
}

export function JobSetupPersonaFromJSON(json: any): JobSetupPersona {
    return JobSetupPersonaFromJSONTyped(json, false);
}

export function JobSetupPersonaFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobSetupPersona {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'jobCategory': !exists(json, 'job_category') ? undefined : json['job_category'],
        'jobCategoryDisplay': !exists(json, 'job_category_display') ? undefined : json['job_category_display'],
        'titlePatterns': !exists(json, 'title_patterns') ? undefined : json['title_patterns'],
        'customerFacingName': !exists(json, 'customer_facing_name') ? undefined : json['customer_facing_name'],
        'isManagement': !exists(json, 'is_management') ? undefined : json['is_management'],
        'isAlpha': !exists(json, 'is_alpha') ? undefined : json['is_alpha'],
        'definition': !exists(json, 'definition') ? undefined : json['definition'],
    };
}

export function JobSetupPersonaToJSON(value?: JobSetupPersona | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'job_category': value.jobCategory,
        'customer_facing_name': value.customerFacingName,
        'is_management': value.isManagement,
        'is_alpha': value.isAlpha,
        'definition': value.definition,
    };
}


