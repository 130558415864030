import { Divider, Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { useActionVersion } from "components/dover/top-level-modal-manager/hooks/useActionVersion";
import { useEmailState } from "components/dover/top-level-modal-manager/hooks/useEmailState";
import { useValidate } from "components/dover/top-level-modal-manager/hooks/useValidate";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { CandidateActionEmailEditor } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor";
import { WarningBanner } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/WarningBanner";
import { ClientEmailTemplateSelect } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/ClientEmailTemplateSelect";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { ScheduledSendButton } from "components/ScheduledSendButton";
import { useCandidateJobId } from "hooks/useCandidateJobId";
import { useQueueEmailMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { CandidateActionEmailArgs, GetEmailTemplateRequestV2DecisionEnum } from "services/openapi";
import { toastOptions } from "utils/showToast";

export const ReplyModal = ({
  isOpen,
  closeModal,
  candidateId,
  title,
  emailEventDetails,
}: CandidateActionModalProps): React.ReactElement => {
  const [queueEmail] = useQueueEmailMutation();
  const candidateActionVersion = useActionVersion(candidateId);
  const jobId = useCandidateJobId(candidateId);

  const [sendAt, setSendAt] = useState<Date>(new Date());
  const [clientEmailTemplateId, setClientEmailTemplateId] = useState<string | undefined>(undefined);

  const {
    to,
    from,
    setFrom,
    initialSubject,
    subject,
    setSubject,
    initialBody,
    body,
    setBody,
    cc,
    setCc,
    bcc,
    setBcc,
    messageKey,
    threadId,
    isFetching,
    isValidatingLink,
    invalidLink,
  } = useEmailState({
    candidateId,
    decision: GetEmailTemplateRequestV2DecisionEnum.Email,
    emailEventDetails,
    clientEmailTemplateId,
  });

  const { warning, tooltip, disabledMap, hideEditor } = useValidate("EmailOnly", {
    fetchingTemplateAndBioData: isFetching,
    subject,
    to,
    from,
    body,
    isValidatingLink,
    invalidLink,
  });

  const onSubmit = useCallback(async () => {
    if (!from || !jobId) {
      return;
    }

    // Both routes use email args
    // Create those here
    const emailArgs: CandidateActionEmailArgs = {
      emailAlias: from.id,
      toEmails: [to.email],
      ccEmails: cc.map(o => o.email),
      bccEmails: bcc.map(o => o.email),
      subject,
      body,
      threadId,
      messageKey: messageKey,
      sendAt: sendAt,
    };

    const queueEmailArgs = {
      id: candidateId,
      data: { candidateActionVersion, emailArgs },
    };
    const queueEmailPromise = queueEmail(queueEmailArgs).unwrap();

    try {
      await toast.promise(
        queueEmailPromise,
        {
          pending: "Updating...",
          success: "Updated!",
          error: "Error updating",
        },
        { ...toastOptions }
      );

      closeModal();
    } catch (e) {
      console.error(e);
      return;
    }
  }, [
    from,
    jobId,
    to.email,
    cc,
    bcc,
    subject,
    body,
    threadId,
    messageKey,
    sendAt,
    candidateId,
    candidateActionVersion,
    queueEmail,
    closeModal,
  ]);

  const ActionButtons = useMemo(
    () => (
      <Stack width="100%">
        {!isFetching && <WarningBanner warning={warning} />}
        <Stack width="100%" spacing={2} direction="row" justifyContent="space-between" padding="12px">
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Body>Send </Body>
            <ScheduledSendButton setSendAt={setSendAt} />
          </Stack>
          <Button
            variant={ButtonVariant.SecondarySuccess}
            onClick={onSubmit}
            disabled={disabledMap.Send}
            tooltip={tooltip}
          >
            Send
          </Button>
        </Stack>
      </Stack>
    ),
    [disabledMap.Send, setSendAt, isFetching, onSubmit, tooltip, warning]
  );

  return (
    <>
      <CustomModal
        open={isOpen}
        onClose={closeModal}
        title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>{title}</Body>}
        maxWidth={"md"}
        customDialogStyles={CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES}
        customContentStyles={CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES}
        dialogActions={ActionButtons}
        dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
      >
        <Stack spacing={2}>
          <ClientEmailTemplateSelect hideSmartReply setClientEmailTemplateId={setClientEmailTemplateId} />
          <Divider
            sx={{
              margin: "24px -24px !important",
            }}
          />

          {!hideEditor && (
            <Stack spacing={1}>
              <BodySmall weight="500">Email</BodySmall>
              <CandidateActionEmailEditor
                candidateId={candidateId}
                hideSchedulingLink={false}
                body={initialBody}
                onBodyChanged={setBody}
                subject={initialSubject}
                onSubjectChanged={setSubject}
                from={from}
                onFromChanged={setFrom}
                to={to}
                cc={cc}
                onCcChanged={setCc}
                bcc={bcc}
                onBccChanged={setBcc}
                disableFrom={disabledMap.From}
                disableTo={disabledMap.To}
                disableCc={disabledMap.CC}
                disableBcc={disabledMap.BCC}
                disableSubject={disabledMap.Subject}
                disableBody={disabledMap.Body}
                loading={isFetching}
              />
            </Stack>
          )}
        </Stack>
      </CustomModal>
    </>
  );
};
