import { Box, Stack } from "@mui/material";
import React, { FC } from "react";
import styled from "styled-components";

import { ReactComponent as GreenCheckIconSVG } from "assets/icons/check-green.svg";
import { ReactComponent as RedXIconSVG } from "assets/icons/red-x.svg";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Overline, BodySmall } from "components/library/typography";
import {
  SaapReviewApplicationCandidateBucketLabelEnum,
  PassingAndFailingFilters,
  PipelineCandidateCandidateBucketLabelEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { backgroundColors, labelColors, labelText, MatchChipProps } from "views/candidates/ApplicationReview/constants";

interface MatchLabelProps {
  label: SaapReviewApplicationCandidateBucketLabelEnum | PipelineCandidateCandidateBucketLabelEnum | undefined;
  explanation?: PassingAndFailingFilters;
}

const FilterExplanation = ({ filterName, passing }: { filterName: string; passing: boolean }): React.ReactElement => {
  return (
    <Stack spacing={0.5} direction="row" display="flex" padding="0px 5px" alignItems="center">
      <Box width="18px" display="flex" justifyContent="center">
        {passing ? <GreenCheckIconSVG style={{ padding: "1px" }} /> : <RedXIconSVG style={{ padding: "1px" }} />}
      </Box>
      <BodySmall weight="300" color={colors.grayscale.gray700}>
        {filterName}
      </BodySmall>
    </Stack>
  );
};

const ExplanationChip = ({ explanation }: { explanation: PassingAndFailingFilters }): React.ReactElement => {
  const passingNiceToHaves = explanation?.nthPassingFilters ?? [];
  const failingNiceToHaves = explanation?.nthFailingFilters ?? [];
  const passingMustHaves = explanation?.mhPassingFilters ?? [];
  const failingMustHaves = explanation?.mhFailingFilters ?? [];

  const hasNiceToHaves = passingNiceToHaves.length > 0 || failingNiceToHaves.length > 0;
  const hasMustHaves = passingMustHaves.length > 0 || failingMustHaves.length > 0;

  return (
    <Stack direction="column" spacing={1} padding="5px 0px">
      {hasMustHaves && (
        <>
          <Overline>Must have</Overline>
          {passingMustHaves?.map(filter => (
            <FilterExplanation key={filter} filterName={filter} passing />
          ))}
          {failingMustHaves?.map(filter => (
            <FilterExplanation key={filter} filterName={filter} passing={false} />
          ))}
        </>
      )}
      {hasNiceToHaves && (
        <>
          <Overline>Nice to have</Overline>
          {passingNiceToHaves?.map(filter => (
            <FilterExplanation key={filter} filterName={filter} passing />
          ))}
          {failingNiceToHaves?.map(filter => (
            <FilterExplanation key={filter} filterName={filter} passing={false} />
          ))}
        </>
      )}
    </Stack>
  );
};

const Chip = styled.div<MatchChipProps>`
  background: ${(props): string => backgroundColors[props.label]};
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  width: fit-content;
`;

export const MatchLabel: FC<MatchLabelProps> = ({ label, explanation }) => {
  if (!label) {
    return null;
  }

  const icon = (
    <Chip label={label}>
      <Overline color={labelColors[label]}>{labelText[label]}</Overline>
    </Chip>
  );

  // If the label isn't explicitly one of best, close, or mismatch, then we just want to show the tooltip,
  // because those are special cases where we haven't yet scored them + we want to explain in english what's going on.
  // if the label is best, close, or mismatch, it means we've scored them and we can explain their scoring.
  const labelMandatesFilterExplanation = [
    SaapReviewApplicationCandidateBucketLabelEnum.BestMatch,
    SaapReviewApplicationCandidateBucketLabelEnum.CloseMatch,
    SaapReviewApplicationCandidateBucketLabelEnum.Mismatch,
  ].includes(label as SaapReviewApplicationCandidateBucketLabelEnum);

  let tooltip;
  if (explanation && labelMandatesFilterExplanation) {
    tooltip = <ExplanationChip explanation={explanation} />;
  }

  return (
    <Tooltip title={tooltip} variant={TooltipVariant.Light} border={`1px solid ${colors.grayscale.gray200}`}>
      {icon}
    </Tooltip>
  );
};
