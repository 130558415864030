import React from "react";

import { CandidateSourceFilter } from "views/candidates/CandidateTable/filters/AdvancedFilterSection/CandidateSourceFilter";
import { ProcessingChannels } from "views/candidates/constants";

export const CandidateSourceSection = (): React.ReactElement => {
  return (
    <>
      {ProcessingChannels.map(channel => (
        <CandidateSourceFilter key={channel.value} label={channel.label} value={channel.value} />
      ))}
    </>
  );
};
