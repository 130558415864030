import React, { ReactElement } from "react";

import DoverEmailOutreachModal from "components/dover/dover-outbound-modal/DoverEmailOutreachModal";
import JobFeatureToggleHandler from "components/dover/feature-toggle-handler/JobFeatureToggleHandler";
import { CandidateSource } from "services/openapi";

const DoverOutboundModal = React.memo(
  ({
    isModalOpen,
    candidateSource,
    toggleModalOff,
    isDoverOutboundEnabled,
    isUpdating,
  }: {
    isModalOpen: boolean;
    candidateSource: CandidateSource | undefined;
    toggleModalOff: () => void;
    isDoverOutboundEnabled: boolean;
    isUpdating?: boolean;
  }): ReactElement => {
    const showInterestedCandidates = false;
    return (
      <JobFeatureToggleHandler>
        <DoverEmailOutreachModal
          isModalOpen={isModalOpen}
          toggleModalOff={toggleModalOff}
          candidateSource={candidateSource}
          isEnabled={isDoverOutboundEnabled}
          isUpdating={isUpdating}
          showInterestedCandidates={showInterestedCandidates}
        />
      </JobFeatureToggleHandler>
    );
  }
);

export default DoverOutboundModal;
