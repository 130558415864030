/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateFacingInterviewer
 */
export interface CandidateFacingInterviewer {
    /**
     * 
     * @type {string}
     * @memberof CandidateFacingInterviewer
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateFacingInterviewer
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateFacingInterviewer
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidateFacingInterviewer
     */
    linkedinPublicId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidateFacingInterviewer
     */
    readonly fullName?: string;
    /**
     * 
     * @type {object}
     * @memberof CandidateFacingInterviewer
     */
    roleTitleData: object;
}

export function CandidateFacingInterviewerFromJSON(json: any): CandidateFacingInterviewer {
    return CandidateFacingInterviewerFromJSONTyped(json, false);
}

export function CandidateFacingInterviewerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateFacingInterviewer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'linkedinPublicId': !exists(json, 'linkedin_public_id') ? undefined : json['linkedin_public_id'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'roleTitleData': json['role_title_data'],
    };
}

export function CandidateFacingInterviewerToJSON(value?: CandidateFacingInterviewer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone_number': value.phoneNumber,
        'linkedin_public_id': value.linkedinPublicId,
        'role_title_data': value.roleTitleData,
    };
}


