/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyFeatures
 */
export interface CompanyFeatures {
    /**
     * 
     * @type {string}
     * @memberof CompanyFeatures
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyFeatures
     */
    primaryProduct?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyFeatures
     */
    companyDescription?: string | null;
}

export function CompanyFeaturesFromJSON(json: any): CompanyFeatures {
    return CompanyFeaturesFromJSONTyped(json, false);
}

export function CompanyFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyName': !exists(json, 'company_name') ? undefined : json['company_name'],
        'primaryProduct': !exists(json, 'primary_product') ? undefined : json['primary_product'],
        'companyDescription': !exists(json, 'company_description') ? undefined : json['company_description'],
    };
}

export function CompanyFeaturesToJSON(value?: CompanyFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_name': value.companyName,
        'primary_product': value.primaryProduct,
        'company_description': value.companyDescription,
    };
}


