import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { useLocalStorage } from "react-use";

import { DoverInterviewerCard } from "components/dover/DoverInterviewerCard";
import JobFeatureToggleHandler from "components/dover/feature-toggle-handler/JobFeatureToggleHandler";
import EnablingAutopilotLoadingOverlay from "components/dover/SearchesTable/components/EnablingAutopilotLoadingOverlay";
import { ToggleEnablingAutopilotAtom } from "components/dover/sourcing/atoms";
import { SourcingAutopilotCard } from "components/dover/sourcing/SourcingAutopilotCard";
import { BodySmall } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { CandidateFilterStatusEnum } from "services/openapi";
import { screenSizesNumbers } from "styles/theme";
import { useParams } from "views/candidates/hooks";
import ApplicantsOverview from "views/job/JobSetup/steps/Overview/components/ApplicantsOverview";
import CandidatesOverview from "views/job/JobSetup/steps/Overview/components/CandidatesOverview";
import { EmbeddedRecruiterCard } from "views/job/JobSetup/steps/Overview/components/EmbeddedRecruiterCard";
import { JobSetupGuide } from "views/job/JobSetup/steps/Overview/components/JobSetupGuide";
import SourcingOverview from "views/job/JobSetup/steps/Overview/components/SourcingOverview";
import { InterviewStatsTable } from "views/Reporting/components/InterviewStatsTable";
import { DateOption } from "views/Reporting/constants";
import { getFilterDates } from "views/Reporting/utils";

const Overview = (): React.ReactElement => {
  const jobId = useJobIdFromUrl();
  // Responsive design
  const theme = useTheme();
  const smallishScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tablet));

  // Jotai state
  const isEnablingAutopilot = useAtomValue(ToggleEnablingAutopilotAtom);
  const freeCustomer = useIsBasePlanCustomer();

  // Load in status query param for candidate counts
  const [{ status }, setParams] = useParams();
  const [jobSetupGuideDismissed] = useLocalStorage(`setupGuideDismissed${jobId}`, false);
  const [dateRangeOption, setDateRangeOption] = React.useState<DateOption>(DateOption.AllTime);

  // Set params for initial load
  useEffect(() => {
    if (!status.length) {
      setParams({ status: [CandidateFilterStatusEnum.Active] });
    }

    // We intentionally only want to run this on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Only show solutions cards for contract customers
  const showSolutionsCards = !freeCustomer;

  return (
    <JobFeatureToggleHandler>
      <Stack width="100%" direction="row" justifyContent={"center"}>
        {isEnablingAutopilot && <EnablingAutopilotLoadingOverlay />}
        <Stack spacing={2} width="100%" maxWidth={"800px"}>
          {jobId && !jobSetupGuideDismissed && <JobSetupGuide jobId={jobId} />}
          <Stack flexDirection={smallishScreen ? "column" : "row"} gap={2}>
            <ApplicantsOverview />
            <SourcingOverview />
          </Stack>
          <CandidatesOverview />
          {showSolutionsCards && (
            <>
              <BodySmall weight="700">Solutions</BodySmall>
              <EmbeddedRecruiterCard />
              <SourcingAutopilotCard />
              <DoverInterviewerCard />
            </>
          )}
          {jobId && (
            <InterviewStatsTable
              job={{
                id: jobId,
                title: "",
                client: "",
                isSample: false,
              }}
              dateRange={getFilterDates(dateRangeOption)}
              dateRangeOption={dateRangeOption}
              onDateRangeChange={setDateRangeOption}
            />
          )}
        </Stack>
      </Stack>
    </JobFeatureToggleHandler>
  );
};

export default Overview;
