import { meta } from "@data-driven-forms/common/prop-types-templates";
import PropTypes from "prop-types";
import React from "react";

import { FormFieldGrid, validationError } from "components/data-driven-forms/fields/helpers";
import TileSelect from "components/inputs/TileSelect";

// (Davin): should this move to utils? shared among fields?
const parseValue = (value, options) => {
  if (!value) {
    return value;
  }

  const option = options.find(o => o.value === value);
  if (!option) {
    return null;
  }

  return {
    value: value,
    label: option?.label,
  };
};

const InternalTileSelect = ({
  value,
  options,
  label,
  helperText,
  validateOnMount,
  meta,
  description,
  onInputChange,
  required,
  onChange,
  FormFieldGridProps,
  ...rest
}) => {
  const invalid = validationError(meta, validateOnMount);
  const internalValue = parseValue(value, options);

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <TileSelect
        {...rest}
        label={label}
        required={required}
        invalid={!!invalid}
        helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
        onInputChange={(_event, value) => onInputChange(value)}
        options={options}
        value={typeof internalValue === "undefined" ? null : internalValue}
        onChange={(_event, option) => {
          onChange(option.value);
        }}
      />
    </FormFieldGrid>
  );
};

InternalTileSelect.propTypes = {
  meta,
  placeholder: PropTypes.node,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any.isRequired, label: PropTypes.node.isRequired }))
    .isRequired,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  value: PropTypes.any,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  inputProps: PropTypes.object,
};

InternalTileSelect.defaultProps = {
  FormFieldGridProps: {},
  inputProps: {},
};

export default InternalTileSelect;
