import { Stack, Box } from "@mui/material";
import React, { FC } from "react";

import { HiringPipelineStage } from "services/openapi";
import { getSinglePartSubstage, isInitialCallStage } from "utils/isStage";
import { EditStage } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStage";
import {
  StageDuration,
  StageInterviewers,
  StageName,
} from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/StageParts";

interface SinglePartRowProps {
  stage: HiringPipelineStage;
}

export const SinglePartRow: FC<SinglePartRowProps> = ({ stage }) => {
  const substage = getSinglePartSubstage(stage);
  const isInitialCall = isInitialCallStage(stage);
  // if its initial call and DI is on for some substage (this is singlepart so should only have 1 substage)
  // then its using DI
  const useDoverinterviewer = isInitialCall
    ? stage.multipartInterviewStage?.substages?.some(s => s.useDoverInterviewer)
    : false;
  const isTakeHome = stage.multipartInterviewStage?.substages?.some(s => s.isTakeHome);

  return (
    // We use relative / absolute positioning so we can place the edit button
    // Without taking up space in the flex box. This allows us an easy way to
    // maintain consistency with the multipart substage rows that don't have an
    // edit button
    <Box position="relative" width="100%">
      <Stack direction="row" width="100%" alignItems="center">
        <Box flex={1}>
          <StageName name={stage.name} />
        </Box>
        <Box flex={1}>
          {substage && (
            <StageInterviewers
              interviewers={substage.possibleInterviewers}
              stageUsingDI={useDoverinterviewer}
              isTakeHome={isTakeHome}
            />
          )}
        </Box>
        <Box flex={1}>{substage && <StageDuration duration={substage.duration} />}</Box>
      </Stack>
      <Box position="absolute" right="0" top="-4px">
        <EditStage stage={stage} />
      </Box>
    </Box>
  );
};
