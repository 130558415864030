import { Box, Stack } from "@mui/material";
import React, { ReactElement, useCallback, useMemo } from "react";
import { DropEvent, DropzoneOptions, ErrorCode, FileRejection, useDropzone } from "react-dropzone";

import { ReactComponent as UploadCloudSVG } from "assets/icons/upload-cloud.svg";
import { Body } from "components/library/typography";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";

type CustomErrorCode = ErrorCode | "name-too-large";

const nameLengthErrorCode = "name-too-large";
const maxNameLength = 100;

export const nameLengthValidator = (
  file: File
): {
  code: string;
  message: string;
} | null => {
  if (file.name.length > maxNameLength) {
    return {
      code: nameLengthErrorCode,
      message: `Name is larger than ${maxNameLength} characters`,
    };
  }

  return null;
};

const dropzoneRejectionToMessage = (errorCode: CustomErrorCode, options: DropzoneOptions): string => {
  switch (errorCode) {
    case ErrorCode.FileInvalidType: {
      const acceptedFileTypes = Array.isArray(options.accept) ? options.accept?.join(", ") : options.accept;
      return `Invalid file type. Accepted file types: ${acceptedFileTypes}.`;
    }
    case ErrorCode.FileTooLarge:
      return `File too large. Maximum file size: ${options.maxSize}.`;
    case ErrorCode.FileTooSmall:
      return `File too small. Minimum file size: ${options.minSize}.`;
    case ErrorCode.TooManyFiles:
      return `Too many files. Number of files allowed is ${options.maxFiles}.`;
    case nameLengthErrorCode:
      return `Name too large. Max name length is ${maxNameLength}.`;
    default:
      return "Error uploading file";
  }
};

// Display a toast with an error message to user when file upload fails client side
export const useOnDropRejected = (
  options: DropzoneOptions
): ((fileRejections: FileRejection[], event: DropEvent) => void) =>
  useCallback(
    (rejectedFiles: FileRejection[]): void => {
      showErrorToast(dropzoneRejectionToMessage(rejectedFiles[0].errors[0].code as CustomErrorCode, options));
    },
    [options]
  );

export const FileDropzone = ({ options }: { options?: DropzoneOptions }): ReactElement => {
  // onDropRejected needs to be aware of the other options selected
  // So make options object without that one first, and then add onDropRejected later
  // Override the interactive options because they should always be this for this component
  const dropzoneOptions = useMemo(
    () => ({
      maxFiles: 50,
      maxSize: 20000000,
      validator: nameLengthValidator,
      ...options,
      noClick: true,
      noDrag: false,
      noKeyboard: false,
    }),
    [options]
  );

  const onDropRejected = useOnDropRejected(dropzoneOptions);

  const { getRootProps, getInputProps, open } = useDropzone({
    ...dropzoneOptions,
    onDropRejected,
  });

  return (
    <Box
      sx={{
        padding: "2rem",
        border: `1px ${colors.grayscale.gray300} dashed`,
        borderRadius: "4px",
      }}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Stack spacing={1} alignItems="center" justifyContent="center">
          <UploadCloudSVG color={colors.grayscale.gray500} />
          <Body color={colors.grayscale.gray500} centered>
            Drag and drop files or{" "}
            <Box onClick={open} sx={{ cursor: "pointer", color: colors.link, display: "inline" }}>
              browse computer
            </Box>
          </Body>
        </Stack>
      </div>
    </Box>
  );
};
