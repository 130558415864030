const auth0DomainConfigs = {
  prod: {
    domain: "doverapp.auth0.com",
    clientId: "7LYxiCMG4Lio5q5Xq7e8d7nS3UAzLZom",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  dev: {
    domain: "dover-dev.auth0.com",
    clientId: "nNS8rgbJQl8vSNwTqVAVN1gbepZkZ7RL",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
};

// pick from auth0DomainConfigs based on REACT_APP_AUTH0_CONFIG, defaulting to prod
const auth0DomainConfigName =
  process.env.REACT_APP_AUTH0_CONFIG && process.env.REACT_APP_AUTH0_CONFIG in auth0DomainConfigs
    ? (process.env.REACT_APP_AUTH0_CONFIG as keyof typeof auth0DomainConfigs)
    : "prod";
const selectedAuth0DomainConfig = auth0DomainConfigs[auth0DomainConfigName];

const authConfig = {
  ...selectedAuth0DomainConfig,
  aliasInfoUrl: process.env.REACT_APP_AUTH0_AUDIENCE + "/alias_info",
  clientInfoUrl: process.env.REACT_APP_AUTH0_AUDIENCE + "/client_info",
  userMetadataUrl: process.env.REACT_APP_AUTH0_AUDIENCE + "/user_metadata",
  manuallySourceChromeExtensionId:
    process.env.REACT_APP_MANUALLY_SOURCED_CHROME_EXTENSION_ID || "ekmeakdlmfdpidkmifdcgaekafojmfch",
  manuallySourceChromeExtensionTestingId:
    process.env.REACT_APP_MANUALLY_SOURCED_CHROME_EXTENSION_TESTING_ID || "iclmefjnjecinngdkhgeblhfogcmooen",
  adminChromeExtensionId: process.env.REACT_APP_ADMIN_CHROME_EXTENSION_ID || "hbfhlhibcbldbecfpmedpgjhpkcmnkhc",
};

export default authConfig;
