import { Box, Divider, Stack } from "@mui/material";
import React from "react";

import ClientImpersonatorV2 from "App/components/Nav/ClientImpersonatorV2";
import SearchBar from "App/components/Nav/SearchBarV2";
import { SideBarNavItem } from "App/components/Nav/SideBarNavItemV2";
import { SideNavHeader } from "App/components/Nav/SideNavHeader";
import { SideNavHelp } from "App/components/Nav/SideNavHelp";
import { SideNavJobsContainer } from "App/components/Nav/SideNavJobs";
import { StarredCandidatesNavList } from "App/components/Nav/StarredCandidatesNavList";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as GlobeIcon } from "assets/icons/globe.svg";
import { ReactComponent as HomeIcon } from "assets/icons/home-icon.svg";
import { ReactComponent as ReviewIcon } from "assets/icons/review-list-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ShopIcon } from "assets/icons/shop.svg";
import { ReactComponent as TrendingUpIcon } from "assets/icons/trending-up.svg";
import { ButtonVariant } from "components/library/Button";
import { Role, useContainsRole } from "components/RBAC";
import AddCandidateButton from "sections/addcandidate/components/AddCandidateButton";
import { useListStarredCandidatesQuery } from "services/doverapi/endpoints/starredCandidate";
import { colors, deviceHeight } from "styles/theme";
import { useIsHeightSmallerThan } from "utils/layout";

const SideNavItemsList = (): React.ReactElement => {
  const isSmallScreen = useIsHeightSmallerThan(deviceHeight.tablet);

  return (
    <Stack minHeight={isSmallScreen ? "76px" : undefined} overflow={isSmallScreen ? "auto" : undefined}>
      <SideBarNavItem to="/home" label="Home" icon={<HomeIcon width="18px" height="18px" className="svg-fill" />} />
      <SideBarNavItem
        to="/review"
        label="Review"
        icon={<ReviewIcon width="18px" height="18px" className="svg-fill" />}
      />
      <SideBarNavItem
        to={APP_ROUTE_PATHS.editCareersPage()}
        label="Careers Page"
        icon={<GlobeIcon width="18px" height="18px" className="svg-fill" />}
      />
      <SideBarNavItem
        to={APP_ROUTE_PATHS.recruitingPartners()}
        label="Recruiting Partners"
        icon={<ShopIcon width="18px" height="18px" className="svg-color" />}
      />
      <SideBarNavItem
        to="/visual-explorer"
        label="Reporting & Analytics"
        icon={<TrendingUpIcon width="18px" height="18px" className="svg-fill" />}
      />
      <SideBarNavItem
        to={APP_ROUTE_PATHS.companySetup()}
        label="Settings & Members"
        icon={<SettingsIcon width="18px" height="18px" className="svg-fill" />}
      />
    </Stack>
  );
};

interface SideNavV2ContentProps {
  toggleDrawerOpen: () => void;
}

/**
 * SideNavV2Content
 * Contains all the content for the side nav, and controls some special render cases depending on the view we're in
 */
export const SideNavV2Content = ({ toggleDrawerOpen }: SideNavV2ContentProps): React.ReactElement => {
  const isInternalUser = useContainsRole([Role.ADMIN, Role.RECRUITING_PARTNER, Role.INTERVIEWER, Role.COORDINATOR]);

  const { data: starredCandidates } = useListStarredCandidatesQuery({});
  const showStarredCandidates = starredCandidates && starredCandidates.length > 0;
  const isSmallScreen = useIsHeightSmallerThan(deviceHeight.tablet);

  return (
    <Stack spacing={1} height="100%">
      <SideNavHeader toggleDrawerOpen={toggleDrawerOpen} />
      <Stack
        spacing={1}
        width="100%"
        height="100%"
        minHeight={0}
        divider={
          <Divider
            orientation="horizontal"
            flexItem
            sx={{ borderColor: colors.grayscale.gray200, mx: "-12px !important" }}
          />
        }
      >
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            height: "30px",
            // Override command bar default styles to fit width of side nav
            "& .commandbar-user-launcher": {
              width: "auto",
              minWidth: 0,
              padding: "4px 8px",
              borderColor: colors.grayscale.gray300,
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: 400,
              color: colors.grayscale.gray600,
              backgroundColor: colors.white,
              boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
              "&:hover": {
                backgroundColor: colors.grayscale.gray100,
              },
            },
            "& .commandbar-user-launcher__content": {
              display: "inline-flex",
            },
            "& .commandbar-user-launcher__prefix": { marginTop: "-10px" },
            "& .commandbar-user-launcher__suffix": { paddingLeft: "4px", marginTop: "-10px" },
            "& .commandbar-user-launcher__tag": {
              fontSize: "14px",
              backgroundColor: "transparent",
              color: colors.grayscale.gray600,
              display: "inline",
              padding: "0.2px 0.5px 0",
            },
            "& .commandbar-user-launcher__suffix span:nth-child(2)": {
              display: "none",
            },
          }}
        >
          <AddCandidateButton
            buttonProps={{
              variant: ButtonVariant.GhostPrimary,
              height: "30px",
              padding: "4px 8px",
            }}
            label="Candidate"
          />
          <SearchBar />
        </Stack>
        <SideNavItemsList />
        {showStarredCandidates && (
          <Box maxHeight="125px">
            <StarredCandidatesNavList />
          </Box>
        )}
        <Box minHeight="76px" height={isSmallScreen ? "76px" : undefined} flexGrow={1}>
          <SideNavJobsContainer />
        </Box>
        {/* important needed to override mui stack injected styles */}
        <Box>
          <SideNavHelp />
        </Box>
        {isInternalUser && (
          <Box display="flex" justifyContent="center">
            <ClientImpersonatorV2 />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
