/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import React, { useMemo } from "react";

import AnswerRow from "views/interview/common/components/InterviewRubricReview/BaseAnswerRow";

export const TextFieldAnswer = props => {
  const { input, label, largeTextArea, ...rest } = useFieldApi(props);

  // The input.value is not strictly typed so we can run into an unhandled exception if we pass in anything other than a string here
  const notes = typeof input.value === "string" ? input.value : undefined;
  return <AnswerRow label={label} notes={notes} largeTextArea={largeTextArea} questionsOnly={rest?.questionsOnly} />;
};

export const SelectAnswer = props => {
  const { input, label, multiple, isMulti, options, ...rest } = useFieldApi(props);
  const notes = input?.value?.notes;
  const value = input?.value?.value;
  const selections = useMemo(() => {
    const multi = multiple || isMulti;
    const isSet = value && (!multi || (multi && value.length));
    const values = isSet ? (multi ? value : [value]) : [];

    return values.map(v => ({
      value: v,
      color: options.find(o => o?.value === v)?.color || "gray",
      label: options.find(o => o?.value === v)?.label || v,
    }));
  }, [value]);

  return <AnswerRow label={label} notes={notes} selections={selections} questionsOnly={rest?.questionsOnly} />;
};
