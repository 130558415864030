import { Box, Stack, Skeleton } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";

import { ReactComponent as AISparklesSVG } from "assets/icons/ai-sparkles.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedin-logo-placeholder.svg";
import { getLinkedInIdFromUrl } from "components/dover/CompanySetupBasicInfo/utils";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import EditorWithMenu from "components/library/TipTap/EditorWithMenu";
import Toggle from "components/library/Toggle";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall, Subtitle2 } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { usePartialUpdateClientMutation, useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useUpdateJobSetupMutation } from "services/doverapi/endpoints/job";
import {
  useGetJobDescriptionQuery,
  useUpdateJobDescriptionMutation,
} from "services/doverapi/endpoints/job-description/endpoints";
import { useGetSourceDisplaySectionData } from "services/doverapi/endpoints/job-source-settings/customHooks";
import { useSubmitJobCandidateSourceFormMutation } from "services/doverapi/endpoints/job-source-settings/endpoints";
import {
  DoverJobDescription,
  JobCandidateSourceSettingDesiredStateEnum,
  JobCandidateSourceSettingExternalJobOriginEnum,
  UpdateClientBodyAtsTypeEnum,
  UpdateClientBodyDoverPlanEnum,
  ClientEmailProvider,
} from "services/openapi";
import { colors } from "styles/theme";
import { getHtmlFromUnknownContent } from "utils/draftJS";
import { EMPTY_TIPTAP_EDITOR } from "utils/tiptap";
import { CreateJobDescriptionSchemaFormType } from "views/create-job/CreateJob/constants";
import JobLocationFields, {
  useGetJobLocationsFromFieldValues,
} from "views/create-job/shared-steps/components/JobLocationFields";
import { OnboardingFormProps } from "views/create-job/types";
import { shouldShowInOfficeCities, shouldShowRemoteRegions } from "views/job/utils";

interface EditJobDescriptionProps extends OnboardingFormProps {
  jobId?: string;
  variant?: "page" | "drawer";
  generatedJobDescription?: DoverJobDescription;
  updatedJobTitle?: string;
  skipPreview?: boolean;
}

const EditJobDescriptionForm = ({
  jobId,
  generatedJobDescription,
  existingJobDescription,
}: EditJobDescriptionProps & { existingJobDescription?: DoverJobDescription }): React.ReactElement => {
  const { isFetching: isFetchingSources } = useGetSourceDisplaySectionData({
    jobId: jobId ?? undefined,
    includeAdminPreview: false,
  });

  const { control } = useFormContext<CreateJobDescriptionSchemaFormType>();
  const { data: clientData } = useGetUsersClientQuery();

  const { field: jobDescriptionField } = useController({
    name: "jobDescription",
    control,
  });

  const { field: postOnLinkedInField } = useController({
    name: "postOnLinkedIn",
    control,
  });

  const { field: linkedInCompanyUrlField } = useController({
    name: "linkedInCompanyUrl",
    control,
  });

  const linkedInError = React.useMemo(() => {
    if (postOnLinkedInField.value && !clientData?.linkedinCompanyId) {
      const { error } = getLinkedInIdFromUrl(linkedInCompanyUrlField.value || "");
      return error;
    }
    return undefined;
  }, [linkedInCompanyUrlField.value, postOnLinkedInField.value, clientData?.linkedinCompanyId]);

  // Don't ask for linkedin url if we already have the client's linkedin id
  const askForLinkedInURL = !clientData?.linkedinCompanyId && !isFetchingSources && postOnLinkedInField.value;
  const clientIsAgency = clientData?.isAgency ?? false;
  const postingToLinkedInEnabled = !clientIsAgency;
  const initialJdContent =
    generatedJobDescription?.userFacingDescription ?? existingJobDescription?.userProvidedDescription ?? "";

  const htmlInitialJobDescription = getHtmlFromUnknownContent(initialJdContent);

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Subtitle2>Job description</Subtitle2>
        {generatedJobDescription && (
          <Stack direction="row" spacing={1}>
            <AISparklesSVG />
            <BodySmall>Here&apos;s your AI generated job description!</BodySmall>
          </Stack>
        )}
        <div>
          <EditorWithMenu
            initialContent={htmlInitialJobDescription}
            onContentChange={jobDescriptionField.onChange}
            placeholder="Enter a job description here..."
          />
        </div>
      </Stack>
      <JobLocationFields />
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Subtitle2>Promote your job</Subtitle2>
          {isFetchingSources ? (
            <Stack spacing={0.5}>
              <Skeleton variant="rectangular" width="100%" height="90px" />
            </Stack>
          ) : (
            <>
              <Tooltip
                title={
                  clientIsAgency ? (
                    <>
                      {`Dover one-click integration for LinkedIn is not available for staffing or talent agencies. Please follow the instructions `}
                      <a href="https://help.dover.com/en/articles/6238844-linkedin-job-postings">here</a>
                      {` to post the job yourself.`}
                    </>
                  ) : (
                    undefined
                  )
                }
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="12px"
                  style={{
                    border: `1px solid ${colors.grayscale.gray200}`,
                    borderRadius: "4px",
                    cursor: "pointer",
                    opacity: `${postingToLinkedInEnabled ? 1 : 0.5}`,
                  }}
                  onClick={(e): void => {
                    e.preventDefault();
                    postOnLinkedInField.onChange(!postOnLinkedInField.value);
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <LinkedInIcon width="24px" height="24px" />
                    <Body>Post on LinkedIn (Free)</Body>
                  </Stack>

                  <Toggle
                    checked={postingToLinkedInEnabled && postOnLinkedInField.value}
                    disabled={!postingToLinkedInEnabled}
                    label="post-on-linkedIn"
                  />
                </Stack>
              </Tooltip>
              <BodySmall color={colors.grayscale.gray500}>You can post on 50+ supported job boards later</BodySmall>
            </>
          )}
        </Stack>
        {askForLinkedInURL && (
          <Stack spacing={1}>
            <Subtitle2>LinkedIn Company URL</Subtitle2>
            <TextField
              placeholderText={"e.g. https://www.linkedin.com/company/doverhq/"}
              text={linkedInCompanyUrlField.value || ""}
              onTextUpdated={linkedInCompanyUrlField.onChange}
            />
            {linkedInError && <BodySmall color={colors.critical.base}>{linkedInError}</BodySmall>}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const EditJobDescription = ({
  jobId,
  onSaveAndNext,
  isLoading: isLoadingProp,
  variant,
  generatedJobDescription,
  updatedJobTitle,
  skipPreview,
}: EditJobDescriptionProps): React.ReactElement => {
  // Data Fetching
  const { data: existingJobDescription } = useGetJobDescriptionQuery(jobId ? { jobId } : skipToken);
  const { data: clientData } = useGetUsersClientQuery();
  const { data: sourceDisplaySectionData } = useGetSourceDisplaySectionData({
    jobId: jobId ?? undefined,
    includeAdminPreview: false,
  });

  // Mutations
  const [updateJobSetup] = useUpdateJobSetupMutation();
  const [partialUpdateClient] = usePartialUpdateClientMutation();
  // TODO: subscribe to this to check isSuccess in JobDescriptionStep
  const [
    updateJobDescription,
    { isLoading: isUpdatingJobDescription, isSuccess: isSuccessUpdatingJd },
  ] = useUpdateJobDescriptionMutation({
    fixedCacheKey: "updateJobDescription",
  });
  const [submitJobCandidateSourceForm] = useSubmitJobCandidateSourceFormMutation();

  // Derived Data
  const linkedInCandidateSourceData = useMemo(
    () => sourceDisplaySectionData?.INBOUND?.find(source => source.candidateSource.internalName === "LINKEDIN"),
    [sourceDisplaySectionData]
  );

  // RHF setup
  const { control, handleSubmit } = useFormContext<CreateJobDescriptionSchemaFormType>();

  const { field: jobDescriptionField } = useController({
    name: "jobDescription",
    control,
  });

  const { field: remotePolicyField } = useController({
    name: "remotePolicy",
    control,
  });

  const { field: postOnLinkedInField } = useController({
    name: "postOnLinkedIn",
    control,
  });

  const { field: linkedInCompanyUrlField } = useController({
    name: "linkedInCompanyUrl",
    control,
  });

  const { field: remoteRegionsField } = useController({
    name: "remoteRegions",
    control,
  });

  const { field: onsiteCitiesField } = useController({
    name: "onsiteCities",
    control,
  });

  // Dynamically show certain form inputs based on the remote policy
  const showRemoteRegions = remotePolicyField.value
    ? shouldShowRemoteRegions({ remotePolicy: remotePolicyField.value })
    : false;
  const showOnsiteCities = remotePolicyField.value ? shouldShowInOfficeCities(remotePolicyField.value) : false;

  const locations = useGetJobLocationsFromFieldValues();

  const isReadyToSubmit = useMemo(() => {
    return isSuccessUpdatingJd || existingJobDescription?.userProvidedDescription || skipPreview;
  }, [isSuccessUpdatingJd, existingJobDescription?.userProvidedDescription, skipPreview]);

  const onSaveChanges = React.useCallback(async (): Promise<void> => {
    if (jobId && existingJobDescription) {
      if (existingJobDescription.id) {
        // 1. Update job description
        updateJobDescription({
          id: existingJobDescription.id,
          updatedJobDescription: {
            userProvidedDescription: jobDescriptionField.value,
            isPublished: true,
            useDoverJd: false,
          },
        });
      }
      // 2. Update JobCandidateSource for LinkedIn
      const linkedInFreePricingTierId = linkedInCandidateSourceData?.candidateSource?.pricingTiers?.find(
        tier => tier.price === 0
      )?.id;

      if (!jobId || !linkedInCandidateSourceData?.candidateSource.id || !linkedInFreePricingTierId) {
        return;
      }

      // Only submit the LinkedIn source form if the user has selected to post on LinkedIn
      // because we don't want to create a Job Candidate Source otherwise
      if (postOnLinkedInField.value) {
        await submitJobCandidateSourceForm({
          jobId,
          candidateSourceId: linkedInCandidateSourceData.candidateSource.id,
          desiredState: JobCandidateSourceSettingDesiredStateEnum.Active,
          shouldAutoRenew: true,
          pricingTierId: linkedInFreePricingTierId,
          // Set the external job origin to Dover since it's a one-click integration
          externalJobOrigin: JobCandidateSourceSettingExternalJobOriginEnum.Dover,
          // ensures LinkedIn goes fully active, i.e state is set to active, not just desiredState
          forceSetState: true,
        }).unwrap();
      }

      // 3. Update JobSetup with provided locations and optionally the updated job title
      await updateJobSetup({
        id: jobId,
        locations,
        title: updatedJobTitle,
      }).unwrap();

      // 4. Update Client with LinkedIn company ID
      if (linkedInCompanyUrlField.value && clientData?.id) {
        partialUpdateClient({
          id: clientData.id,
          updatedClient: {
            ...clientData,
            // note that this should only update the linkedinCompanyId
            linkedinCompanyId: getLinkedInIdFromUrl(linkedInCompanyUrlField.value).id,
            // the fields below are not being updated, only writing them this way for typing reasons
            doverPlan: clientData.doverPlan
              ? ((clientData.doverPlan as unknown) as UpdateClientBodyDoverPlanEnum)
              : undefined,
            atsType: (clientData.atsType as unknown) as UpdateClientBodyAtsTypeEnum,
            emailProvider: clientData.emailProvider
              ? ((clientData.emailProvider as unknown) as ClientEmailProvider)
              : undefined,
          },
        }).unwrap();
      }
    }
  }, [
    clientData,
    existingJobDescription,
    jobDescriptionField.value,
    jobId,
    linkedInCandidateSourceData,
    linkedInCompanyUrlField.value,
    locations,
    partialUpdateClient,
    postOnLinkedInField.value,
    submitJobCandidateSourceForm,
    updateJobDescription,
    updateJobSetup,
    updatedJobTitle,
  ]);

  const onNext = React.useCallback(async (): Promise<void> => {
    await onSaveChanges();
    // Skip going to the next step in the page version if the user hasn't yet saved and previewed the JD
    if (variant === "page" && !isReadyToSubmit) {
      return;
    }
    await onSaveAndNext();
  }, [variant, isReadyToSubmit, onSaveAndNext, onSaveChanges]);

  const linkedInMissingJobDescriptionError = React.useMemo(() => {
    if (
      postOnLinkedInField.value &&
      (!jobDescriptionField?.value || jobDescriptionField?.value === EMPTY_TIPTAP_EDITOR)
    ) {
      return "Please enter a job description";
    }
    return undefined;
  }, [postOnLinkedInField, jobDescriptionField]);

  const remoteRegionsError = React.useMemo(() => {
    if (!showRemoteRegions || !!remoteRegionsField.value.length) {
      return undefined;
    }
    return "Please select at least one region";
  }, [remoteRegionsField, showRemoteRegions]);

  const onsiteCitiesError = React.useMemo(() => {
    if (!showOnsiteCities || !!onsiteCitiesField.value.length) {
      return undefined;
    }
    return "Please select at least one city";
  }, [onsiteCitiesField, showOnsiteCities]);

  const linkedInError = React.useMemo(() => {
    if (postOnLinkedInField.value && !clientData?.linkedinCompanyId) {
      const { error } = getLinkedInIdFromUrl(linkedInCompanyUrlField.value || "");
      return error;
    }
    return undefined;
  }, [linkedInCompanyUrlField.value, postOnLinkedInField.value, clientData?.linkedinCompanyId]);

  const collectLocationInfo = !generatedJobDescription && !existingJobDescription?.userProvidedDescription;

  const locationError = collectLocationInfo && (onsiteCitiesError || remoteRegionsError);

  const disabledSaveButtonTooltip = React.useMemo(() => {
    if (linkedInMissingJobDescriptionError) {
      return "Input a job description above to post to LinkedIn";
    } else if (locationError && linkedInError) {
      return "Please enter a location and a valid LinkedIn company URL";
    } else if (locationError) {
      return "Please enter a location";
    } else if (linkedInError) {
      return "Please enter a valid LinkedIn company URL";
    }
    return undefined;
  }, [linkedInError, locationError, linkedInMissingJobDescriptionError]);

  const buttonText = useMemo((): string => {
    if (variant === "page") {
      return isReadyToSubmit ? "Save and next" : "Save and preview";
    }
    return "Next";
  }, [variant, isReadyToSubmit]);

  return (
    <Stack boxSizing="border-box" direction="row" height="100%" width="100%" justifyContent="center">
      <Box flexGrow={1} position="relative" sx={{ backgroundColor: "white" }}>
        <Stack spacing={2} flexGrow={1} overflow="auto">
          <DoverLoadingOverlay active={isUpdatingJobDescription}>
            <EditJobDescriptionForm
              onSaveAndNext={onSaveAndNext}
              generatedJobDescription={generatedJobDescription}
              existingJobDescription={existingJobDescription}
            />
          </DoverLoadingOverlay>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          position="sticky"
          bottom="0"
          width="100%"
          sx={{ backgroundColor: colors.white }}
          py={2}
          spacing={1}
        >
          {/* TODO: create a reusable var for this case to be used for onNext and buttonText as well */}
          {variant === "page" &&
            (!!generatedJobDescription || !!existingJobDescription?.userProvidedDescription) &&
            !skipPreview && (
              <Button variant={ButtonVariant.Secondary} onClick={onSaveChanges} disabled={!!disabledSaveButtonTooltip}>
                Preview changes
              </Button>
            )}
          <Button
            tooltip={disabledSaveButtonTooltip}
            variant={ButtonVariant.Primary}
            onClick={handleSubmit(onNext)}
            disabled={!!disabledSaveButtonTooltip || isLoadingProp || isUpdatingJobDescription}
          >
            {buttonText}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default EditJobDescription;
