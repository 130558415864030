/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobReferralJob,
    JobReferralJobFromJSON,
    JobReferralJobFromJSONTyped,
    JobReferralJobToJSON,
    JobReferralPerson,
    JobReferralPersonFromJSON,
    JobReferralPersonFromJSONTyped,
    JobReferralPersonToJSON,
    JobReferralProUser,
    JobReferralProUserFromJSON,
    JobReferralProUserFromJSONTyped,
    JobReferralProUserToJSON,
    RelatedJobReferral,
    RelatedJobReferralFromJSON,
    RelatedJobReferralFromJSONTyped,
    RelatedJobReferralToJSON,
    ShouldBeContactedByProUser,
    ShouldBeContactedByProUserFromJSON,
    ShouldBeContactedByProUserFromJSONTyped,
    ShouldBeContactedByProUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface JobReferral
 */
export interface JobReferral {
    /**
     * 
     * @type {string}
     * @memberof JobReferral
     */
    readonly id?: string;
    /**
     * 
     * @type {Array<JobReferralProUser>}
     * @memberof JobReferral
     */
    readonly connections?: Array<JobReferralProUser>;
    /**
     * 
     * @type {JobReferralJob}
     * @memberof JobReferral
     */
    job?: JobReferralJob;
    /**
     * 
     * @type {JobReferralPerson}
     * @memberof JobReferral
     */
    person?: JobReferralPerson;
    /**
     * 
     * @type {Date}
     * @memberof JobReferral
     */
    readonly created?: Date;
    /**
     * 
     * @type {number}
     * @memberof JobReferral
     */
    readonly matchRank?: number;
    /**
     * 
     * @type {string}
     * @memberof JobReferral
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferral
     */
    readonly triagingStatus?: JobReferralTriagingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JobReferral
     */
    reviewState?: JobReferralReviewStateEnum;
    /**
     * 
     * @type {JobReferralProUser}
     * @memberof JobReferral
     */
    reviewedBy?: JobReferralProUser;
    /**
     * 
     * @type {Date}
     * @memberof JobReferral
     */
    readonly reviewedOn?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof JobReferral
     */
    contactMethod?: JobReferralContactMethodEnum;
    /**
     * 
     * @type {ShouldBeContactedByProUser}
     * @memberof JobReferral
     */
    shouldBeContactedBy?: ShouldBeContactedByProUser;
    /**
     * 
     * @type {JobReferralProUser}
     * @memberof JobReferral
     */
    contactMethodSetBy?: JobReferralProUser;
    /**
     * 
     * @type {Date}
     * @memberof JobReferral
     */
    readonly contactMethodSetOn?: Date | null;
    /**
     * 
     * @type {JobReferralProUser}
     * @memberof JobReferral
     */
    effectivelyContactedBy?: JobReferralProUser;
    /**
     * 
     * @type {Date}
     * @memberof JobReferral
     */
    readonly effectivelyContactedOn?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof JobReferral
     */
    readonly lastActionSummary?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof JobReferral
     */
    readonly lastActionOn?: Date | null;
    /**
     * 
     * @type {Array<RelatedJobReferral>}
     * @memberof JobReferral
     */
    readonly relatedJobReferrals?: Array<RelatedJobReferral>;
}

/**
* @export
* @enum {string}
*/
export enum JobReferralTriagingStatusEnum {
    NeedsReview = 'NEEDS_REVIEW',
    NeedsManualOutreach = 'NEEDS_MANUAL_OUTREACH',
    Contacted = 'CONTACTED',
    Rejected = 'REJECTED'
}/**
* @export
* @enum {string}
*/
export enum JobReferralReviewStateEnum {
    PendingReview = 'PENDING_REVIEW',
    Approved = 'APPROVED',
    Rejected = 'REJECTED'
}/**
* @export
* @enum {string}
*/
export enum JobReferralContactMethodEnum {
    ManualReachOut = 'MANUAL_REACH_OUT',
    DoverReachOut = 'DOVER_REACH_OUT'
}

export function JobReferralFromJSON(json: any): JobReferral {
    return JobReferralFromJSONTyped(json, false);
}

export function JobReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobReferral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'connections': !exists(json, 'connections') ? undefined : ((json['connections'] as Array<any>).map(JobReferralProUserFromJSON)),
        'job': !exists(json, 'job') ? undefined : JobReferralJobFromJSON(json['job']),
        'person': !exists(json, 'person') ? undefined : JobReferralPersonFromJSON(json['person']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'matchRank': !exists(json, 'match_rank') ? undefined : json['match_rank'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'triagingStatus': !exists(json, 'triaging_status') ? undefined : json['triaging_status'],
        'reviewState': !exists(json, 'review_state') ? undefined : json['review_state'],
        'reviewedBy': !exists(json, 'reviewed_by') ? undefined : JobReferralProUserFromJSON(json['reviewed_by']),
        'reviewedOn': !exists(json, 'reviewed_on') ? undefined : (json['reviewed_on'] === null ? null : new Date(json['reviewed_on'])),
        'contactMethod': !exists(json, 'contact_method') ? undefined : json['contact_method'],
        'shouldBeContactedBy': !exists(json, 'should_be_contacted_by') ? undefined : ShouldBeContactedByProUserFromJSON(json['should_be_contacted_by']),
        'contactMethodSetBy': !exists(json, 'contact_method_set_by') ? undefined : JobReferralProUserFromJSON(json['contact_method_set_by']),
        'contactMethodSetOn': !exists(json, 'contact_method_set_on') ? undefined : (json['contact_method_set_on'] === null ? null : new Date(json['contact_method_set_on'])),
        'effectivelyContactedBy': !exists(json, 'effectively_contacted_by') ? undefined : JobReferralProUserFromJSON(json['effectively_contacted_by']),
        'effectivelyContactedOn': !exists(json, 'effectively_contacted_on') ? undefined : (json['effectively_contacted_on'] === null ? null : new Date(json['effectively_contacted_on'])),
        'lastActionSummary': !exists(json, 'last_action_summary') ? undefined : json['last_action_summary'],
        'lastActionOn': !exists(json, 'last_action_on') ? undefined : (json['last_action_on'] === null ? null : new Date(json['last_action_on'])),
        'relatedJobReferrals': !exists(json, 'related_job_referrals') ? undefined : ((json['related_job_referrals'] as Array<any>).map(RelatedJobReferralFromJSON)),
    };
}

export function JobReferralToJSON(value?: JobReferral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': JobReferralJobToJSON(value.job),
        'person': JobReferralPersonToJSON(value.person),
        'notes': value.notes,
        'review_state': value.reviewState,
        'reviewed_by': JobReferralProUserToJSON(value.reviewedBy),
        'contact_method': value.contactMethod,
        'should_be_contacted_by': ShouldBeContactedByProUserToJSON(value.shouldBeContactedBy),
        'contact_method_set_by': JobReferralProUserToJSON(value.contactMethodSetBy),
        'effectively_contacted_by': JobReferralProUserToJSON(value.effectivelyContactedBy),
    };
}


