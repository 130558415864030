import { Box, Menu, MenuItem, Stack } from "@mui/material";
import React from "react";

import { ReactComponent as PlayIcon } from "assets/icons/arrow-filled.svg";
import { ReactComponent as CaretDownIcon } from "assets/icons/caret-down-black.svg";
import { GreenCircle, RedCircle } from "components/icons/RadioIcons";
import { BodySmall } from "components/library/typography";
import { useActivateScoringForJobMutation } from "services/doverapi/endpoints/applicationReview";
import { colors } from "styles/theme";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";

const ScoringIsActiveButton = (): React.ReactElement => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <GreenCircle sx={{ width: "10px", height: "10px" }} />
      <BodySmall>Active</BodySmall>
      <CaretDownIcon className="svg-fill" color={colors.grayscale.gray500} width="14px" height="14px" />
    </Stack>
  );
};

const ScoringIsInactivateButton = (): React.ReactElement => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <RedCircle sx={{ width: "10px", height: "10px" }} />
      <BodySmall>Inactive</BodySmall>
      <CaretDownIcon className="svg-fill" color={colors.grayscale.gray500} width="14px" height="14px" />
    </Stack>
  );
};

const ActivateScoringButton = (): React.ReactElement => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <PlayIcon className="svg-fill" color={colors.primary.base} width="16px" height="16px" />
      <BodySmall color={colors.primary.base}>Activate</BodySmall>
    </Stack>
  );
};

const DeactivateScoringButton = (): React.ReactElement => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <BodySmall color={colors.critical.base}>Deactivate</BodySmall>
    </Stack>
  );
};

export const JobScoringToggle = ({ jobId }: { jobId: string | undefined }): React.ReactElement => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);

  const { jobHasScoringEnabled } = useJobHasScoringEnabled();
  const [toggleScoringForJob, { isLoading }] = useActivateScoringForJobMutation();

  const flipScoringEnabledForJob = (): void => {
    if (!jobId) {
      console.error("No job ID found for activating scoring");
      return;
    }
    toggleScoringForJob({ id: jobId, data: { desiredInboundEnrichingEnabled: !jobHasScoringEnabled } });
  };

  return (
    <>
      <Box
        borderRadius="6px"
        p={1}
        sx={{ cursor: "pointer" }}
        onClick={(event: React.MouseEvent<HTMLElement>): void => {
          setMenuAnchorEl(event.currentTarget);
        }}
      >
        {jobHasScoringEnabled ? <ScoringIsActiveButton /> : <ScoringIsInactivateButton />}
      </Box>
      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={(): void => setMenuAnchorEl(null)}
        sx={{ minWidth: "150px" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* If no job ID (which shouldnt happen), or mutation in flight, just disable the button */}
        <MenuItem onClick={flipScoringEnabledForJob} disabled={!jobId || isLoading} sx={{ cursor: "pointer" }}>
          {jobHasScoringEnabled ? <DeactivateScoringButton /> : <ActivateScoringButton />}
        </MenuItem>
      </Menu>
    </>
  );
};
