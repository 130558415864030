import { Box, Divider, IconButton, Menu, MenuItem, Skeleton, Stack, TextField as MuiTextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { ReactComponent as PlusIcon } from "assets/icons/add.svg";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as TrashIconSVG } from "assets/icons/trash-gray.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import Toggle from "components/library/Toggle";
import { Body, BodySmall } from "components/library/typography";
import { useListApplicationQuestionsQuery } from "services/doverapi/endpoints/applicationQuestion";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { ApplicationQuestionInputTypeEnum, ApplicationQuestionQuestionTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { applicationQuestionInputTypeIconAndLabel } from "views/job/JobSetup/steps/JobPosting/components/EditableApplicationForm/constants";
import { ApplicationFormQuestion } from "views/job/JobSetup/steps/JobPosting/components/EditableApplicationForm/types";

const EditableMultipleChoiceOptions = ({
  question,
  index,
  updateApplicationQuestion,
}: {
  question: ApplicationFormQuestion;
  index: number;
  updateApplicationQuestion: (question: ApplicationFormQuestion, index: number) => void;
}): React.ReactElement => {
  return (
    <Stack spacing={1}>
      <BodySmall weight="700">Options</BodySmall>
      <Stack spacing={1}>
        {question.multipleChoiceOptions &&
          question.multipleChoiceOptions?.map((option, optionIndex) => (
            <Stack key={optionIndex} spacing={1} direction="row" alignItems="center">
              <Box
                style={{
                  borderRadius: "50%",
                  height: "20px",
                  width: "20px",
                  border: `solid 1px ${colors.grayscale.gray300}`,
                }}
              />
              <StyledTextField
                fullWidth
                placeholderText="Enter an option..."
                text={option}
                onTextUpdated={(newValue): void => {
                  if (!question.multipleChoiceOptions) return;
                  const newOptions = [...question.multipleChoiceOptions];
                  newOptions[optionIndex] = newValue;
                  updateApplicationQuestion({ ...question, multipleChoiceOptions: newOptions }, index);
                }}
              />
              {question.multipleChoiceOptions && question.multipleChoiceOptions.length > 1 && (
                <IconButton
                  onClick={(): void => {
                    if (!question.multipleChoiceOptions) return;
                    const newOptions = [...question.multipleChoiceOptions];
                    newOptions.splice(optionIndex, 1);
                    updateApplicationQuestion({ ...question, multipleChoiceOptions: newOptions }, index);
                  }}
                >
                  <PlusIcon style={{ transform: "rotate(45deg)" }} />
                </IconButton>
              )}
            </Stack>
          ))}
        <Box>
          <Button
            variant={ButtonVariant.Ghost}
            onClick={(): void => {
              const newOptions = question.multipleChoiceOptions ? [...question.multipleChoiceOptions] : [];
              newOptions.push("");
              updateApplicationQuestion({ ...question, multipleChoiceOptions: newOptions }, index);
            }}
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <PlusIcon className="svg-color" color={colors.linkLight} />
              <BodySmall color={colors.linkLight}>Add option</BodySmall>
            </Stack>
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export const EditableQuestion = ({
  question,
  index,
  updateApplicationQuestion,
}: {
  question: ApplicationFormQuestion;
  index: number;
  updateApplicationQuestion: (question: ApplicationFormQuestion, index: number) => void;
}): React.ReactElement => {
  const clientId = useGetClientId();
  const { data: applicationQuestions, isFetching: applicationQuestionsFetching } = useListApplicationQuestionsQuery(
    clientId ? { clientId } : skipToken
  );
  const [inputTypeMenuAnchorEl, setInputTypeMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  // Event handlers
  const openInputTypeMenu = React.useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setInputTypeMenuAnchorEl(event.currentTarget);
  }, []);

  const closeInputTypeMenu = React.useCallback((): void => {
    setInputTypeMenuAnchorEl(null);
  }, []);

  const customApplicationQuestions = useMemo(() => {
    return applicationQuestions?.filter(
      question => question.questionType === ApplicationQuestionQuestionTypeEnum.Custom
    );
  }, [applicationQuestions]);

  const onQuestionChange = useCallback(
    (option: any): void => {
      const isCustomQuestion = typeof option === "string";
      const updatedQuestion: ApplicationFormQuestion = { ...question };
      updatedQuestion.question = isCustomQuestion ? option : option?.question ?? "";
      if (!isCustomQuestion) {
        updatedQuestion.inputType = option.inputType;
        if (option?.inputType === ApplicationQuestionInputTypeEnum.MultipleChoice && !!option.multipleChoiceOptions) {
          updatedQuestion.multipleChoiceOptions = option.multipleChoiceOptions;
        }
      }
      updateApplicationQuestion(updatedQuestion, index);
    },
    [question, updateApplicationQuestion, index]
  );

  if (question.questionType !== ApplicationQuestionQuestionTypeEnum.Custom) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        padding="16px 0"
        borderBottom={`solid 1px ${colors.grayscale.gray200}`}
        alignItems="center"
      >
        <Body>{question.question}</Body>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          onClick={(e): void => {
            e.preventDefault();
            updateApplicationQuestion({ ...question, required: !question.required }, index);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <Toggle checked={question.required} label={`required-${index}`} />
          <BodySmall>Required</BodySmall>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack padding="16px" spacing={1} border={`solid 1px ${colors.grayscale.gray200}`} mt={2} borderRadius="4px">
      <BodySmall weight="700">Question</BodySmall>
      <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
        {customApplicationQuestions && !applicationQuestionsFetching ? (
          <Autocomplete
            onChange={(event, option: any): void => {
              onQuestionChange(option);
            }}
            value={question}
            freeSolo
            autoSelect
            getOptionLabel={(option): string => (typeof option === "string" ? option : option.question)}
            options={customApplicationQuestions.map(question => question)}
            renderInput={(params): React.ReactElement => (
              <MuiTextField
                {...params}
                placeholder="Enter a question..."
                sx={{
                  ".MuiInputBase-root": {
                    border: "none",
                    height: "46px",
                  },
                }}
              />
            )}
            size="small"
            fullWidth
          />
        ) : (
          <Skeleton variant="text" width="100%" height="46px" />
        )}
        <Button variant={ButtonVariant.Secondary} onClick={openInputTypeMenu}>
          <Stack direction="row" alignItems="center" spacing={1} color={colors.grayscale.gray400}>
            {applicationQuestionInputTypeIconAndLabel[question.inputType].icon}
            <BodySmall style={{ whiteSpace: "nowrap" }}>
              {applicationQuestionInputTypeIconAndLabel[question.inputType].label}
            </BodySmall>
            <ChevronDownIcon />
          </Stack>
        </Button>
        <Menu open={!!inputTypeMenuAnchorEl} anchorEl={inputTypeMenuAnchorEl} onClose={closeInputTypeMenu}>
          {Object.entries(applicationQuestionInputTypeIconAndLabel)
            .filter(([key]) => key !== ApplicationQuestionInputTypeEnum.FileUpload)
            .map(([key, value]) => (
              <MenuItem
                key={key}
                onClick={(): void => {
                  const updatedQuestion = { ...question, inputType: key as ApplicationQuestionInputTypeEnum };
                  if (
                    updatedQuestion.inputType === ApplicationQuestionInputTypeEnum.MultipleChoice &&
                    !updatedQuestion.multipleChoiceOptions
                  ) {
                    updatedQuestion.multipleChoiceOptions = [""];
                  }
                  updateApplicationQuestion(updatedQuestion, index);
                  closeInputTypeMenu();
                }}
              >
                {value.label}
              </MenuItem>
            ))}
        </Menu>
      </Stack>
      {/* TODO: Remove as any once we update backend */}
      {question.inputType === ApplicationQuestionInputTypeEnum.MultipleChoice && (
        <EditableMultipleChoiceOptions
          question={question}
          index={index}
          updateApplicationQuestion={updateApplicationQuestion}
        />
      )}
      <Divider />
      <Stack justifyContent="flex-end" direction="row" alignItems="center" spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          onClick={(e): void => {
            e.preventDefault();
            updateApplicationQuestion({ ...question, required: !question.required }, index);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <Toggle checked={question.required} label={`required-${index}`} />
          <BodySmall>Required</BodySmall>
        </Stack>
        <IconButton onClick={(): void => updateApplicationQuestion({ ...question, status: "deleted" }, index)}>
          <TrashIconSVG />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    height: 46px;
  }
`;
