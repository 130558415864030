import { Box, Stack } from "@mui/material";
import React, { FC } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ReactComponent as MagnifyingGlassSVG } from "assets/icons/magnifying-glass.svg";
import { CREATE_JOB_DRAWER_OPEN_PARAM } from "components/dover/CreateJob/constants";
import { Heading } from "components/library/typography";
import { colors } from "styles/theme";

export const NoJobs: FC = () => {
  const [, setCreateJobDrawerOpen] = useQueryParam(CREATE_JOB_DRAWER_OPEN_PARAM, BooleanParam);

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <MagnifyingGlassSVG className="svg-color svg-fill" color={colors.grayscale.gray400} height="36px" width="36px" />
      <Heading color={colors.grayscale.gray500}>
        <Box
          sx={{
            cursor: "pointer",
            color: colors.link,
            display: "inline",
          }}
          onClick={(): void => {
            setCreateJobDrawerOpen(true);
          }}
        >
          Add a job
        </Box>{" "}
        to start seeing candidates
      </Heading>
    </Stack>
  );
};
