import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { Chip, Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { sortBy } from "lodash";
import React from "react";
import { useFormContext, useWatch, useFieldArray } from "react-hook-form";
import { z } from "zod";

import HelpIconSVG from "assets/icons/help-question.svg";
import PenToolIcon from "assets/icons/pen-tool-grey.svg";
import { Autocomplete } from "components/library/Autocomplete";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Button, ButtonVariant } from "components/library/Button";
import { StyledSwitch } from "components/library/Switch";
import { TextField } from "components/library/TextField";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { BodySmall, Overline } from "components/library/typography";
import YOESlider, { YearsOfExperienceRange } from "components/library/YOESlider";
import RemoveButton from "components/RemoveButton";
import { useListTitlesQuery } from "services/doverapi/endpoints/search-v3/endpoints";
import { TitleIDsRequired } from "services/doverapi/endpoints/search-v3/types";
import { MinMaxRange, Title } from "services/openapi";
import { colors } from "styles/theme";
import AdvancedAccordion from "views/sourcing/Search/components/AdvancedDropdown";
import FilterAccordion from "views/sourcing/Search/components/FilterAccordion";
import FilterSectionHeader from "views/sourcing/Search/components/FilterSectionHeader";
import { CHIP_MAX_WIDTH } from "views/sourcing/Search/constants";
import {
  FiltersContainerID,
  previewStateMarginBottom,
  previewStateMarginRight,
  sliderPaddingRight,
  YEARS_OF_EXPERIENCE_ALLOWED_RANGE,
  YEARS_OF_EXPERIENCE_MAX,
} from "views/sourcing/Search/constants";
import {
  TOTAL_YEARS_OF_EXPERIENCE_NAME,
  SPECIFIC_YEARS_OF_EXPERIENCE_NAME,
  SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME,
  EXCLUDE_FREQUENT_JOB_SWITCHING_NAME,
  YEARS_AT_CURRENT_COMPANY_NAME,
  BACHELORS_GRADUATION_YEAR_NAME_MIN,
  BACHELORS_GRADUATION_YEAR_NAME_MAX,
  BACHELORS_GRADUATION_YEAR_ERROR,
} from "views/sourcing/Search/constants";
import { FilterToggle, FilterTogglerContext } from "views/sourcing/Search/context/FilterToggleContext";
import { useGetSearchFromUrl } from "views/sourcing/Search/hooks";
import { StyledHelpIconSVG } from "views/sourcing/Search/styles";
import {
  SearchV3FormSchemaType,
  TitleContentProps,
  YearsOfExperienceByTitleWithMetadataSchema,
} from "views/sourcing/Search/types";

const ExcludeFrequentJobSwitchingSwitch = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const excludeFrequentJobSwitching = useWatch({ control, name: EXCLUDE_FREQUENT_JOB_SWITCHING_NAME });

    const ExcludeJumpySwitch = React.useMemo(() => {
      return (
        <FormControlLabel
          sx={{
            margin: "0px",
          }}
          checked={excludeFrequentJobSwitching}
          onChange={(e: any): void => {
            setValue(EXCLUDE_FREQUENT_JOB_SWITCHING_NAME, e.target.checked);
          }}
          control={<StyledSwitch />}
          label={<BodySmall color={colors.grayscale.gray700}>Exclude jumpy candidates</BodySmall>}
        />
      );
    }, [excludeFrequentJobSwitching, setValue]);

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {ExcludeJumpySwitch}
        <Tooltip
          title={"Jumpy candidates stay at a company for an average of less than 1.5 years."}
          placement="right"
          arrow={true}
          variant={TooltipVariant.Dark}
          boxSizing="border-box"
        >
          {/* Span is necessary to prevent a MUI v5 render issue */}
          <span>
            <StyledHelpIconSVG src={HelpIconSVG} />
          </span>
        </Tooltip>
      </Stack>
    );
  }
);

const YearsOfExperienceTitleContent = React.memo(
  ({ expanded }: TitleContentProps): React.ReactElement => {
    const { control, setValue } = useFormContext<SearchV3FormSchemaType>();
    const totalYearsOfExperience = useWatch({ control, name: TOTAL_YEARS_OF_EXPERIENCE_NAME });
    const specificYearsOfExperience = useWatch({ control, name: SPECIFIC_YEARS_OF_EXPERIENCE_NAME });
    const yearsAtCurrentCompanyRange = useWatch({ control, name: YEARS_AT_CURRENT_COMPANY_NAME });
    const bachelorsGraduationYearMin = useWatch({ control, name: BACHELORS_GRADUATION_YEAR_NAME_MIN });
    const bachelorsGraduationYearMax = useWatch({ control, name: BACHELORS_GRADUATION_YEAR_NAME_MAX });
    const specificYOEByT2Values = useWatch({ control, name: SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME });

    // need to load titles (shouldnt cost anything because RTK cache) so we can convert title_ids to titles
    const { data: titles } = useListTitlesQuery();

    const onTotalYOEChipDelete = React.useCallback(() => {
      setValue(TOTAL_YEARS_OF_EXPERIENCE_NAME, {});
    }, [setValue]);

    const onSpecificYOEChipDelete = React.useCallback(() => {
      setValue(SPECIFIC_YEARS_OF_EXPERIENCE_NAME, {});
    }, [setValue]);

    const onCurrentCompanyChipDelete = React.useCallback(() => {
      setValue(YEARS_AT_CURRENT_COMPANY_NAME, {});
    }, [setValue]);

    const onBachelorsGraduationYearChipDelete = React.useCallback(() => {
      setValue(BACHELORS_GRADUATION_YEAR_NAME_MIN, undefined);
      setValue(BACHELORS_GRADUATION_YEAR_NAME_MAX, undefined);
    }, [setValue]);

    const totalYearsOfExperienceLabel = React.useMemo(() => {
      return buildYearsOfExperienceLabel(totalYearsOfExperience, "Total");
    }, [totalYearsOfExperience]);

    const specificYearsOfExperienceLabel = React.useMemo(() => {
      return buildYearsOfExperienceLabel(specificYearsOfExperience, "In role");
    }, [specificYearsOfExperience]);

    const yearsAtCurrentCompanyRangeLabel = React.useMemo(() => {
      return buildYearsOfExperienceLabel(yearsAtCurrentCompanyRange, "Current company");
    }, [yearsAtCurrentCompanyRange]);

    const specificYOEByT2LabelBuilder = React.useCallback(
      (specificYOEByT2: z.infer<typeof YearsOfExperienceByTitleWithMetadataSchema>): string | undefined => {
        const titleId = specificYOEByT2?.titleId;
        // convert this titleId to a readable title from the titles options. pick the t2 part of the title
        const titleName = titles?.find((t: Title) => t.id === titleId)?.name?.split(" - ")[1];
        if (!titleName) {
          return undefined;
        }
        const yoeRange = specificYOEByT2?.yoeRange;

        return buildYearsOfExperienceLabel(yoeRange, titleName);
      },
      [titles]
    );

    const bachelorsGraduationYearLabel = React.useMemo(() => {
      if (bachelorsGraduationYearMin === undefined && bachelorsGraduationYearMax === undefined) {
        return undefined;
      }

      const min = bachelorsGraduationYearMin ? Math.round(bachelorsGraduationYearMin) : "Up to";
      const max = bachelorsGraduationYearMax ? Math.round(bachelorsGraduationYearMax) : "+";

      const addADash = bachelorsGraduationYearMin !== undefined && bachelorsGraduationYearMax !== undefined;

      return `Graduated (${min} ${addADash ? "-" : ""} ${max})`;
    }, [bachelorsGraduationYearMax, bachelorsGraduationYearMin]);

    const totalYearsOfExperienceChip = React.useMemo(() => {
      if (totalYearsOfExperienceLabel === undefined) {
        return <></>;
      }

      return (
        <Chip
          label={<TextWithMaxWidth label={totalYearsOfExperienceLabel} width={CHIP_MAX_WIDTH} />}
          deleteIcon={<HighlightOffSharpIcon />}
          onDelete={onTotalYOEChipDelete}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      );
    }, [onTotalYOEChipDelete, totalYearsOfExperienceLabel]);

    const specificYearsOfExperienceChip = React.useMemo(() => {
      if (specificYearsOfExperienceLabel === undefined) {
        return <></>;
      }

      return (
        <Chip
          label={<TextWithMaxWidth label={specificYearsOfExperienceLabel} width={CHIP_MAX_WIDTH} />}
          deleteIcon={<HighlightOffSharpIcon />}
          onDelete={onSpecificYOEChipDelete}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      );
    }, [onSpecificYOEChipDelete, specificYearsOfExperienceLabel]);

    const specificYOEByT2Chip = React.useMemo(() => {
      if (specificYOEByT2Values === undefined) {
        return <></>;
      }

      return (
        <>
          {specificYOEByT2Values
            .filter(specificYOEByT2Value => !!specificYOEByT2Value.titleId)
            .map((specificYOEByT2Value, index) => {
              const label = specificYOEByT2LabelBuilder(specificYOEByT2Value);
              if (label === undefined) {
                return <></>;
              }
              return (
                // TODO: add delete functionality.
                // (the bug was that when you delete index=0, it will still show the label of index=0 in the expanded view)
                <Chip
                  key={index}
                  label={<TextWithMaxWidth label={label} width={CHIP_MAX_WIDTH} />}
                  sx={{
                    mr: previewStateMarginRight,
                    mb: previewStateMarginBottom,
                  }}
                />
              );
            })}
        </>
      );
    }, [specificYOEByT2LabelBuilder, specificYOEByT2Values]);

    const yearsAtCurrentCompanyChip = React.useMemo(() => {
      if (yearsAtCurrentCompanyRangeLabel === undefined) {
        return <></>;
      }

      return (
        <Chip
          label={<TextWithMaxWidth label={yearsAtCurrentCompanyRangeLabel} width={CHIP_MAX_WIDTH} />}
          deleteIcon={<HighlightOffSharpIcon />}
          onDelete={onCurrentCompanyChipDelete}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      );
    }, [onCurrentCompanyChipDelete, yearsAtCurrentCompanyRangeLabel]);

    const bachelorsGraduationYearChip = React.useMemo(() => {
      if (bachelorsGraduationYearLabel === undefined) {
        return <></>;
      }

      return (
        <Chip
          label={<TextWithMaxWidth label={bachelorsGraduationYearLabel} width={CHIP_MAX_WIDTH} />}
          deleteIcon={<HighlightOffSharpIcon />}
          onDelete={onBachelorsGraduationYearChipDelete}
          sx={{
            mr: previewStateMarginRight,
            mb: previewStateMarginBottom,
          }}
        />
      );
    }, [bachelorsGraduationYearLabel, onBachelorsGraduationYearChipDelete]);

    if (expanded) {
      return <></>;
    }

    return (
      <Stack marginRight={previewStateMarginRight} marginBottom={previewStateMarginBottom}>
        {totalYearsOfExperienceChip}
        {specificYearsOfExperienceChip}
        {yearsAtCurrentCompanyChip}
        {specificYOEByT2Chip}
        {bachelorsGraduationYearChip}
      </Stack>
    );
  }
);

const buildYearsOfExperienceLabel = (yoe: MinMaxRange, label: string): string | undefined => {
  // function which takes in a MinMaxRange and outputs a string displaying it
  // used for yearsOfExperienceChip in preview state
  if (yoe.min === undefined && yoe.max === undefined) {
    return undefined;
  }

  const min = yoe.min ? Math.round(yoe.min) : 0;
  let max: string | number = yoe.max ? Math.round(yoe.max) : YEARS_OF_EXPERIENCE_MAX + 1;
  if (max > YEARS_OF_EXPERIENCE_MAX) {
    max = "unlimited";
  }

  return `${label} (${min} - ${max})`;
};

const YearsOfExperienceFiltersContent = React.memo(
  ({ expanded }: { expanded: boolean }): React.ReactElement => {
    const {
      control,
      setValue,
      formState: { errors },
    } = useFormContext<SearchV3FormSchemaType>();
    const totalYearsOfExperienceFormValue = useWatch({ control, name: TOTAL_YEARS_OF_EXPERIENCE_NAME });
    const specificYearsOfExperienceFormValue = useWatch({ control, name: SPECIFIC_YEARS_OF_EXPERIENCE_NAME });
    const specificYearsOfExperienceByT2FormValue = useWatch({ control, name: SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME });
    const yearsAtCurrentCompanyFormValue = useWatch({ control, name: YEARS_AT_CURRENT_COMPANY_NAME });
    const bachelorsGraduationYearMin = useWatch({ control, name: BACHELORS_GRADUATION_YEAR_NAME_MIN });
    const bachelorsGraduationYearMax = useWatch({ control, name: BACHELORS_GRADUATION_YEAR_NAME_MAX });

    const search = useGetSearchFromUrl();

    const shouldShowYearsOfExperience = React.useCallback((yearsOfExperienceFormValue: MinMaxRange): boolean => {
      return yearsOfExperienceFormValue.min !== undefined || yearsOfExperienceFormValue.max !== undefined;
    }, []);

    const showTotalYearsOfExperience = shouldShowYearsOfExperience(totalYearsOfExperienceFormValue);

    const showSpecificYearsOfExperience = shouldShowYearsOfExperience(specificYearsOfExperienceFormValue);

    const showYearsAtCurrentCompany = shouldShowYearsOfExperience(yearsAtCurrentCompanyFormValue);

    const addYoeByT2 = React.useCallback(() => {
      setValue(SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME, [
        ...specificYearsOfExperienceByT2FormValue,
        { required: true, titleId: undefined, yoeRange: { min: 0 } },
      ]);
    }, [setValue, specificYearsOfExperienceByT2FormValue]);

    const removeYoeByT2 = React.useCallback(
      (index: number) => {
        const newYoeByT2 = [...specificYearsOfExperienceByT2FormValue];
        newYoeByT2.splice(index, 1);
        setValue(SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME, newYoeByT2);
      },
      [setValue, specificYearsOfExperienceByT2FormValue]
    );

    const getYoeMinMax = React.useCallback(
      (value: YearsOfExperienceRange) => {
        // Specific minimum YOE must be at least equal to total YOE min
        let specificYearsMin = value.min ?? undefined;
        if (totalYearsOfExperienceFormValue.min !== undefined) {
          specificYearsMin = Math.min(value.min ?? 0, totalYearsOfExperienceFormValue.min ?? 0);
        }

        let specificYearsMax = value.max ?? undefined;

        // Specific maximum YOE is at most total maximum YOE
        // Also bounded by YEARS_OF_EXPERIENCE_MAX
        if (totalYearsOfExperienceFormValue.max !== undefined) {
          specificYearsMax = Math.min(
            totalYearsOfExperienceFormValue.max,
            value.max ?? YEARS_OF_EXPERIENCE_MAX,
            YEARS_OF_EXPERIENCE_MAX
          );
        }

        return {
          min: specificYearsMin,
          max: specificYearsMax,
        };
      },
      [totalYearsOfExperienceFormValue.max, totalYearsOfExperienceFormValue.min]
    );

    // using this useFieldArray to update the index here because otherwise it will throw
    // type error saying that the specific string is of invalid type
    const { update } = useFieldArray({ name: SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME, control });
    const handleSpecificYOEByT2SliderChange = React.useCallback(
      (index: number, value: YearsOfExperienceRange) => {
        update(index, {
          required: specificYearsOfExperienceByT2FormValue[index].required,
          titleId: specificYearsOfExperienceByT2FormValue[index].titleId,
          yoeRange: {
            min: value.min ?? 0,
            max: Math.min(value.max ?? YEARS_OF_EXPERIENCE_MAX, YEARS_OF_EXPERIENCE_MAX),
          },
        });
      },
      [specificYearsOfExperienceByT2FormValue, update]
    );

    const handleSpecificYOESliderChange = React.useCallback(
      (value: YearsOfExperienceRange) => {
        setValue(SPECIFIC_YEARS_OF_EXPERIENCE_NAME, getYoeMinMax(value));
      },
      [setValue, getYoeMinMax]
    );

    const handleTotalYOESliderChange = React.useCallback(
      (value: YearsOfExperienceRange) => {
        setValue(TOTAL_YEARS_OF_EXPERIENCE_NAME, {
          min: value.min ?? undefined,
          max: value.max ?? undefined,
        });
      },
      [setValue]
    );

    const handleYearsAtCurrentCompanySliderChange = React.useCallback(
      (value: YearsOfExperienceRange) => {
        setValue(YEARS_AT_CURRENT_COMPANY_NAME, {
          min: value.min ?? undefined,
          max: value.max ?? undefined,
        });
      },
      [setValue]
    );

    React.useEffect(() => {
      // When total years of experience form value changes, handle the required changes to specific YOE as well
      if (
        (totalYearsOfExperienceFormValue.min !== undefined || totalYearsOfExperienceFormValue.max !== undefined) &&
        (specificYearsOfExperienceFormValue.min !== undefined || specificYearsOfExperienceFormValue.max !== undefined)
      ) {
        handleSpecificYOESliderChange({
          min: specificYearsOfExperienceFormValue.min,
          max: specificYearsOfExperienceFormValue.max,
        });
      }
    }, [
      handleSpecificYOESliderChange,
      specificYearsOfExperienceFormValue.max,
      specificYearsOfExperienceFormValue.min,
      totalYearsOfExperienceFormValue.max,
      totalYearsOfExperienceFormValue.min,
    ]);

    const onRemoveTotalYOE = React.useCallback(() => {
      setValue(TOTAL_YEARS_OF_EXPERIENCE_NAME, {});
    }, [setValue]);

    const onRemoveSpecificYOE = React.useCallback(() => {
      setValue(SPECIFIC_YEARS_OF_EXPERIENCE_NAME, {});
    }, [setValue]);

    const onRemoveYearsAtCurrentCompany = React.useCallback(() => {
      setValue(YEARS_AT_CURRENT_COMPANY_NAME, {});
    }, [setValue]);

    const totalYOEHeader = React.useMemo(() => {
      return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Overline color={colors.grayscale.gray600}>{"Total years of experience"}</Overline>
          {showTotalYearsOfExperience && (
            <RemoveButton
              svgDimensions={"15px"}
              noPadding={true}
              color={colors.critical.hover}
              onClick={onRemoveTotalYOE}
            />
          )}
        </Stack>
      );
    }, [onRemoveTotalYOE, showTotalYearsOfExperience]);

    // Once the search loads, wait a full second until we display slider values for formatting purposes
    const [formStateDirty, setFormStateDirty] = React.useState<boolean>(true);
    React.useEffect(() => {
      if (formStateDirty && !!search) {
        setTimeout(() => setFormStateDirty(false), 5000);
      }
    }, [formStateDirty, search]);

    const isToggling = React.useContext<FilterToggle | undefined>(FilterTogglerContext)?.isToggling;

    const shouldShowTooltips = React.useMemo(() => {
      return !formStateDirty && expanded && !isToggling;
    }, [expanded, formStateDirty, isToggling]);

    const totalYOEBody = React.useMemo(() => {
      return (
        <>
          {!showTotalYearsOfExperience ? (
            <Stack direction="row" justifyContent="flex-start">
              <Button
                variant={ButtonVariant.Ghost}
                removePadding={true}
                onClick={(): void => {
                  setValue(TOTAL_YEARS_OF_EXPERIENCE_NAME, { min: 0 });
                }}
              >
                <Overline color={colors.linkLight}>{"+ Add"}</Overline>
              </Button>
            </Stack>
          ) : (
            <YOESlider
              values={totalYearsOfExperienceFormValue}
              onChange={handleTotalYOESliderChange}
              maximumValue={YEARS_OF_EXPERIENCE_MAX}
              minimumRange={YEARS_OF_EXPERIENCE_ALLOWED_RANGE}
              shouldShowTooltip={shouldShowTooltips}
              scrollContainerId={FiltersContainerID}
            />
          )}
        </>
      );
    }, [
      handleTotalYOESliderChange,
      setValue,
      shouldShowTooltips,
      showTotalYearsOfExperience,
      totalYearsOfExperienceFormValue,
    ]);

    const specificYOEHeader = React.useMemo(() => {
      return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Overline color={colors.grayscale.gray600}>{"Years of experience in role"}</Overline>
          </Stack>
          {showSpecificYearsOfExperience && (
            <RemoveButton
              svgDimensions={"15px"}
              noPadding={true}
              color={colors.critical.hover}
              onClick={onRemoveSpecificYOE}
            />
          )}
        </Stack>
      );
    }, [onRemoveSpecificYOE, showSpecificYearsOfExperience]);

    const specificYOEBody = React.useMemo(() => {
      return (
        <>
          {!showSpecificYearsOfExperience ? (
            <Stack direction="row" justifyContent="flex-start">
              <Button
                variant={ButtonVariant.Ghost}
                removePadding={true}
                onClick={(): void => {
                  setValue(SPECIFIC_YEARS_OF_EXPERIENCE_NAME, { min: 0 });
                }}
              >
                <Overline color={colors.linkLight}>{"+ Add"}</Overline>
              </Button>
            </Stack>
          ) : (
            <YOESlider
              values={specificYearsOfExperienceFormValue}
              onChange={handleSpecificYOESliderChange}
              maximumValue={Math.max(
                totalYearsOfExperienceFormValue.max ?? YEARS_OF_EXPERIENCE_MAX,
                YEARS_OF_EXPERIENCE_MAX
              )}
              minimumRange={YEARS_OF_EXPERIENCE_ALLOWED_RANGE}
              shouldShowTooltip={shouldShowTooltips}
              scrollContainerId={FiltersContainerID}
            />
          )}
        </>
      );
    }, [
      handleSpecificYOESliderChange,
      setValue,
      shouldShowTooltips,
      showSpecificYearsOfExperience,
      specificYearsOfExperienceFormValue,
      totalYearsOfExperienceFormValue.max,
    ]);

    const specificYOEByT2Header = React.useMemo(() => {
      return (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Overline color={colors.grayscale.gray600}>{"Title years of experience"}</Overline>
          <Tooltip
            title={"Experience with specific job title like blockchain engineer."}
            placement="right"
            arrow={true}
            variant={TooltipVariant.Dark}
            boxSizing="border-box"
          >
            {/* Span is necessary to prevent a MUI v5 render issue */}
            <span>
              <StyledHelpIconSVG src={HelpIconSVG} />
            </span>
          </Tooltip>
        </Stack>
      );
    }, []);

    const { data: titles } = useListTitlesQuery();

    const refinedTitles = React.useMemo(() => {
      if (!titles) {
        return [];
      }

      const sorted = sortBy(titles, title => title.name)
        .filter(title => !!title.id && !!title.name)
        .map(title => {
          return {
            id: title.id!,
            name: title.name!,
          };
        });

      return sorted;
    }, [titles]);

    const setSpecificYOEByT2FormValue = React.useCallback(
      (value: TitleIDsRequired | null, index: number): void => {
        if (!value) {
          setValue(`${SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME}.${index}.titleId` as any, undefined);
        } else {
          setValue(`${SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME}.${index}.titleId` as any, value.id);
        }
      },
      [setValue]
    );

    const specificYOEByT2Body = React.useMemo(() => {
      return (
        <>
          {specificYearsOfExperienceByT2FormValue.map((specificYoeByT2, index) => {
            return (
              <>
                <Stack key={index} direction="row" spacing={1.5} alignItems="center" justifyContent="space-between">
                  <Autocomplete
                    placeholder={"Select a title..."}
                    staticOptions={refinedTitles}
                    groupBy={(option: TitleIDsRequired): string => option.name.split(" - ")[0]}
                    onSelectedOptionChange={(value: TitleIDsRequired): void =>
                      setSpecificYOEByT2FormValue(value, index)
                    }
                    initialValue={refinedTitles.find((title: TitleIDsRequired) => {
                      return title.id === specificYoeByT2.titleId;
                    })}
                    multiple={false}
                    getOptionLabel={(option: TitleIDsRequired): string => option.name.split(" - ")[1]}
                  />

                  <RemoveButton
                    svgDimensions={"15px"}
                    noPadding={true}
                    color={colors.critical.hover}
                    onClick={(): void => removeYoeByT2(index)}
                  />
                </Stack>
                <YOESlider
                  values={specificYoeByT2.yoeRange}
                  onChange={(yearsOfExperience: YearsOfExperienceRange): void =>
                    handleSpecificYOEByT2SliderChange(index, yearsOfExperience)
                  }
                  maximumValue={Math.max(
                    totalYearsOfExperienceFormValue.max ?? YEARS_OF_EXPERIENCE_MAX,
                    YEARS_OF_EXPERIENCE_MAX
                  )}
                  minimumRange={YEARS_OF_EXPERIENCE_ALLOWED_RANGE}
                  shouldShowTooltip={shouldShowTooltips}
                  scrollContainerId={FiltersContainerID}
                />
              </>
            );
          })}
          <Stack direction="row" justifyContent="flex-start">
            <Button variant={ButtonVariant.Ghost} removePadding={true} onClick={addYoeByT2}>
              <Overline color={colors.linkLight}>{"+ Add"}</Overline>
            </Button>
          </Stack>
        </>
      );
    }, [
      specificYearsOfExperienceByT2FormValue,
      addYoeByT2,
      refinedTitles,
      totalYearsOfExperienceFormValue.max,
      shouldShowTooltips,
      setSpecificYOEByT2FormValue,
      removeYoeByT2,
      handleSpecificYOEByT2SliderChange,
    ]);

    const yearsAtCurrentCompanyHeader = React.useMemo(() => {
      return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Overline color={colors.grayscale.gray600}>{"Years at Current company"}</Overline>
          {showYearsAtCurrentCompany && (
            <RemoveButton
              svgDimensions={"15px"}
              noPadding={true}
              color={colors.critical.hover}
              onClick={onRemoveYearsAtCurrentCompany}
            />
          )}
        </Stack>
      );
    }, [onRemoveYearsAtCurrentCompany, showYearsAtCurrentCompany]);

    const yearsAtCurrentCompanyBody = React.useMemo(() => {
      return (
        <>
          {!showYearsAtCurrentCompany ? (
            <Stack direction="row" justifyContent="flex-start">
              <Button
                variant={ButtonVariant.Ghost}
                removePadding={true}
                onClick={(): void => {
                  setValue(YEARS_AT_CURRENT_COMPANY_NAME, { min: 0 });
                }}
              >
                <Overline color={colors.linkLight}>{"+ Add"}</Overline>
              </Button>
            </Stack>
          ) : (
            <YOESlider
              values={yearsAtCurrentCompanyFormValue}
              onChange={handleYearsAtCurrentCompanySliderChange}
              maximumValue={YEARS_OF_EXPERIENCE_MAX}
              minimumRange={YEARS_OF_EXPERIENCE_ALLOWED_RANGE}
              shouldShowTooltip={shouldShowTooltips}
              scrollContainerId={FiltersContainerID}
            />
          )}
        </>
      );
    }, [
      handleYearsAtCurrentCompanySliderChange,
      setValue,
      shouldShowTooltips,
      showYearsAtCurrentCompany,
      yearsAtCurrentCompanyFormValue,
    ]);

    const setValueOnEnterBachelorsMin = React.useCallback(
      (value: string): void => {
        if (value !== undefined && value && typeof parseInt(value) === "number") {
          setValue(BACHELORS_GRADUATION_YEAR_NAME_MIN, parseInt(value));
        } else {
          setValue(BACHELORS_GRADUATION_YEAR_NAME_MIN, undefined);
        }
      },
      [setValue]
    );

    const setValueOnEnterBachelorsMax = React.useCallback(
      (value: string): void => {
        if (value !== undefined && value && typeof parseInt(value) === "number") {
          setValue(BACHELORS_GRADUATION_YEAR_NAME_MAX, parseInt(value));
        } else {
          setValue(BACHELORS_GRADUATION_YEAR_NAME_MAX, undefined);
        }
      },
      [setValue]
    );

    const bachelorsGraduationYearBody = React.useMemo(() => {
      return (
        <>
          <Overline color={colors.grayscale.gray600}>Graduation Year</Overline>
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              text={bachelorsGraduationYearMin !== undefined ? bachelorsGraduationYearMin?.toString() : undefined}
              placeholderText={"e.g. 2016"}
              enterOnBlur={true}
              numbersOnly={true}
              handleEnter={setValueOnEnterBachelorsMin}
              error={!!errors.bachelorsGraduationYear}
            />
            <BodySmall>to</BodySmall>
            <TextField
              text={bachelorsGraduationYearMax !== undefined ? bachelorsGraduationYearMax?.toString() : undefined}
              placeholderText={"e.g. 2020"}
              enterOnBlur={true}
              numbersOnly={true}
              handleEnter={setValueOnEnterBachelorsMax}
              error={!!errors.bachelorsGraduationYear}
            />
          </Stack>
          {!!errors.bachelorsGraduationYear && (
            <BodySmall centered color={colors.critical.hover}>
              {BACHELORS_GRADUATION_YEAR_ERROR}
            </BodySmall>
          )}
        </>
      );
    }, [
      bachelorsGraduationYearMax,
      bachelorsGraduationYearMin,
      errors.bachelorsGraduationYear,
      setValueOnEnterBachelorsMax,
      setValueOnEnterBachelorsMin,
    ]);

    return (
      <Stack spacing={2} paddingRight={sliderPaddingRight}>
        {totalYOEHeader}
        {totalYOEBody}
        {specificYOEHeader}
        {specificYOEBody}
        <AdvancedAccordion
          title="Advanced"
          expandedContent={
            <Stack spacing={2} paddingRight={sliderPaddingRight}>
              <ExcludeFrequentJobSwitchingSwitch />
              {yearsAtCurrentCompanyHeader}
              {yearsAtCurrentCompanyBody}
              {specificYOEByT2Header}
              {specificYOEByT2Body}
              {bachelorsGraduationYearBody}
            </Stack>
          }
        />
      </Stack>
    );
  }
);

const YoeFilters = React.memo(
  (): React.ReactElement => {
    const [yoeSectionExpanded, setYoeSectionExpanded] = React.useState<boolean>(false);

    const { control } = useFormContext<SearchV3FormSchemaType>();
    const totalYearsOfExperience = useWatch({ control, name: TOTAL_YEARS_OF_EXPERIENCE_NAME });
    const specificYearsOfExperience = useWatch({ control, name: SPECIFIC_YEARS_OF_EXPERIENCE_NAME });
    const yearsAtCurrentCompanyRange = useWatch({ control, name: YEARS_AT_CURRENT_COMPANY_NAME });
    const bachelorsGraduationYearMin = useWatch({ control, name: BACHELORS_GRADUATION_YEAR_NAME_MIN });
    const bachelorsGraduationYearMax = useWatch({ control, name: BACHELORS_GRADUATION_YEAR_NAME_MAX });
    const specificYOEByT2Values = useWatch({ control, name: SPECIFIC_YEARS_OF_EXPERIENCE_BY_T2_NAME });

    const noChip =
      totalYearsOfExperience.min === undefined &&
      !totalYearsOfExperience.max &&
      specificYearsOfExperience.min === undefined &&
      !specificYearsOfExperience.max &&
      yearsAtCurrentCompanyRange.min === undefined &&
      !yearsAtCurrentCompanyRange.max &&
      bachelorsGraduationYearMin === undefined &&
      !bachelorsGraduationYearMax &&
      !specificYOEByT2Values?.length;

    return (
      <>
        <FilterSectionHeader title={"Years of Experience"} icon={PenToolIcon} />
        <FilterAccordion
          title={noChip ? "Years of Experience" : undefined}
          TitleContent={YearsOfExperienceTitleContent}
          expandedContent={<YearsOfExperienceFiltersContent expanded={yoeSectionExpanded} />}
          expandedCallback={setYoeSectionExpanded}
        />
      </>
    );
  }
);

export default YoeFilters;
