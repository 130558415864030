import CheckIcon from "@mui/icons-material/Check";
import { Box, Tooltip, Stack } from "@mui/material";
import clipboardCopy from "clipboard-copy";
import React, { useMemo, useState } from "react";

import { ReactComponent as ClipboardIconSVG } from "assets/icons/clipboard.svg";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonText } from "components/library/typography";

export const CopyToClipboard = ({
  content,
  label,
  style,
  buttonVariant = ButtonVariant.Ghost,
}: {
  content: () => string;
  label: string;
  style: any;
  buttonVariant?: ButtonVariant;
}): React.ReactElement => {
  const [clicked, setClicked] = useState(false);

  const tooltipTitle = useMemo((): React.ReactElement => {
    return (
      <>
        <CheckIcon fontSize="small" /> Copied!
      </>
    );
  }, []);

  return (
    <Tooltip title={clicked ? tooltipTitle : ""} placement="top" onOpen={(): void => setClicked(false)}>
      <Box sx={{ ...style }}>
        <Button
          onClick={(): void => {
            setClicked(true);
            clipboardCopy(content());
          }}
          variant={buttonVariant}
          inlineContent={true}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <ClipboardIconSVG style={{ marginRight: "5px" }} />
            <ButtonText>{label}</ButtonText>
          </Stack>
        </Button>
      </Box>
    </Tooltip>
  );
};
