/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPersonaViaSoftMatchRequest
 */
export interface GetPersonaViaSoftMatchRequest {
    /**
     * 
     * @type {string}
     * @memberof GetPersonaViaSoftMatchRequest
     */
    jobTitle: string;
}

export function GetPersonaViaSoftMatchRequestFromJSON(json: any): GetPersonaViaSoftMatchRequest {
    return GetPersonaViaSoftMatchRequestFromJSONTyped(json, false);
}

export function GetPersonaViaSoftMatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPersonaViaSoftMatchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobTitle': json['job_title'],
    };
}

export function GetPersonaViaSoftMatchRequestToJSON(value?: GetPersonaViaSoftMatchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_title': value.jobTitle,
    };
}


