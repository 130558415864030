import { useMemo } from "react";

import { useListDoverEntitiesQuery } from "services/doverapi/endpoints/doverEntity/doverEntity";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { DoverEntityRoute } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";

export const useCurrentDoverEntity = (): DoverEntityRoute | undefined => {
  const { user } = useAuth0();
  const { data: entityList, isSuccess } = useListDoverEntitiesQuery({ email: user.email });
  const doverEntities = useMemo(() => listAllEntities(entityList), [entityList]);
  if (isSuccess && doverEntities.length === 0) {
    throw Error("Not a dover entity");
  }
  if (doverEntities) {
    return doverEntities[0];
  }
  return undefined;
};
