import React from "react";

import AlertIconSVG from "assets/icons/alert-red.svg";
import InfoIconSVG from "assets/icons/info-icon.svg";
import SuccessIconSVG from "assets/icons/simple-green-check.svg";
import WarningIconSVG from "assets/icons/warning-outlined.svg";
import DoverLogoSVG from "assets/logos/DoverD-small.svg";
import { StyledBanner, StyledReactSVG } from "components/Banner/styles";
import { Spacer } from "components/Spacer";
import { backgrounds, baseColors, colors } from "styles/theme";

interface Props {
  variant?: "filled" | "outlined" | "outlined-filled";
  type: "success" | "warning" | "info" | "dover" | "critical" | "none";
  alignItems?: "center" | "flex-start" | "flex-end" | "baseline";
  useSpacer?: boolean;
  overrideIcon?: string;
  padding?: string;
  children: React.ReactElement;
  svgBottom?: string;
}

const Banner = ({
  variant = "outlined",
  type,
  alignItems,
  useSpacer = true,
  overrideIcon,
  padding,
  children,
  svgBottom,
}: Props): React.ReactElement => {
  let icon, styledColor;
  switch (type) {
    case "success":
      icon = SuccessIconSVG;
      styledColor = variant === "filled" ? "rgba(59, 163, 132, 0.1)" : baseColors.success;
      break;
    // this is a variation of a success banner with Dover branding
    case "dover":
      icon = DoverLogoSVG;
      styledColor = baseColors.brand;
      break;
    case "warning":
      icon = WarningIconSVG;
      styledColor = variant === "filled" ? colors.warning.light : baseColors.warning;
      break;
    case "info":
      icon = InfoIconSVG;
      styledColor = variant === "filled" ? colors.informational.light : baseColors.informational;
      break;
    case "critical":
      icon = AlertIconSVG;
      styledColor = variant === "filled" ? backgrounds.critical : baseColors.critical;
      break;
    default:
      icon = null;
      styledColor = colors.grayscale.gray100;
  }
  if (overrideIcon) {
    icon = overrideIcon;
  }
  return (
    <StyledBanner
      alignItems={alignItems}
      filled={variant === "filled" || variant === "outlined-filled"}
      outlined={variant === "outlined" || variant === "outlined-filled"}
      styledColor={styledColor}
      padding={padding}
    >
      {icon && <StyledReactSVG svgBottom={svgBottom} src={icon} alignItems={alignItems} />}
      {useSpacer && <Spacer width="12px" />}
      {children}
    </StyledBanner>
  );
};

export default Banner;
