import { Stack, Radio, RadioGroup, Divider } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Color from "color";
import { isNil } from "lodash";
import React from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import MailOpenIcon from "assets/icons/mail-open.svg";
import ThumbsUpIcon from "assets/icons/thumbs-up.svg";
import CampaignEditorWrapper from "components/dover/CampaignEditor/CampaignEditor";
import CreateCampaignModal from "components/dover/SearchesTable/components/CreateCampaignModal";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, Heading, Subtitle1 } from "components/library/typography";
import CustomModal from "components/Modal";
import {
  selectFromListCampaignsQueryResult,
  useGetCampaignStatsQuery,
  useListCampaignsQuery,
} from "services/doverapi/endpoints/campaign";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import {
  useAddCampaignToSearchMutation,
  useRemoveCampaignFromSearchMutation,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { ListCampaign, ListCampaignStateEnum, SearchV3 } from "services/openapi";
import { backgrounds, colors } from "styles/theme";
import { getEmailAliasName } from "utils/getEmailAliasName";

const StyledRadio = styled(Radio)`
  &.MuiButtonBase-root {
    padding: 0px;
  }
  &.Mui-checked {
    color: ${colors.primary.base} !important;
  }
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${Color(backgrounds.green)
      .alpha(0.3)
      .toString()};
  }
`;

function convertFloatToPercentWithOneDecimal(float: number): string {
  return (float * 100).toFixed(1) + "%";
}

const CampaignPercentageStat = ({
  icon,
  label,
  stat,
}: {
  icon: React.ReactNode;
  label: string;
  stat?: number | null;
}): React.ReactElement => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {icon}
      <BodySmall color={colors.grayscale.gray600}>{label}</BodySmall>
      {isNil(stat) ? <BodySmall>{"--"}</BodySmall> : convertFloatToPercentWithOneDecimal(stat)}
    </Stack>
  );
};

interface CampaignRowProps {
  campaign: ListCampaign;
  selected: boolean;
  jobId: string;
  toggleCampaignSelected: (campaignId: string, isSelected: boolean) => void;
  setCampaignToEdit: (campaignId: string) => void;
  closePrimaryModal: () => void;
}

const CampaignRow = React.memo(
  ({
    campaign,
    selected,
    jobId,
    toggleCampaignSelected,
    setCampaignToEdit,
    closePrimaryModal,
  }: CampaignRowProps): React.ReactElement => {
    const { data: userDefinedSenderUser } = useGetProUserQuery((campaign.userDefinedSenderUser as unknown) as string);

    const { data: campaignStats } = useGetCampaignStatsQuery(campaign.id ? { campaignId: campaign.id! } : skipToken);

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const onClick = React.useCallback((): void => {
      if (!campaign.id) {
        return;
      }

      toggleCampaignSelected(campaign.id, !selected);
    }, [campaign.id, selected, toggleCampaignSelected]);

    const onViewClick = React.useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (campaign.id !== undefined) {
          setCampaignToEdit(campaign.id);
          closePrimaryModal();
        }

        e.stopPropagation();
      },
      [campaign.id, closePrimaryModal, setCampaignToEdit]
    );

    const campaignActive = campaign.state == ListCampaignStateEnum.Active;

    if (!campaign.id) {
      return <></>;
    }

    return (
      <>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
          padding="4px 16px"
          sx={{ "&:hover": { backgroundColor: colors.grayscale.gray100, cursor: "pointer" } }}
          onClick={onClick}
        >
          <Stack direction="row" alignItems="start" spacing={1}>
            <RadioGroup>
              <StyledRadio checked={selected} />
            </RadioGroup>
            <Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Body>{campaign.name}</Body>
                <Body
                  color={campaignActive ? colors.success.base : colors.critical.base}
                >{`(${campaign.state?.toLowerCase()})`}</Body>
              </Stack>
              <BodySmall color={colors.grayscale.gray500} weight="500">{`Sender: ${getEmailAliasName(
                userDefinedSenderUser,
                campaign.emailSenderOption,
                campaign.emailAlias
              )}`}</BodySmall>
              <Stack direction="row" alignItems="center" spacing={2}>
                <CampaignPercentageStat
                  label="Open:"
                  icon={<ReactSVG style={{ display: "flex" }} src={MailOpenIcon} />}
                  stat={campaignStats?.openRate}
                />

                <CampaignPercentageStat
                  label="Interest:"
                  icon={<ReactSVG style={{ display: "flex" }} src={ThumbsUpIcon} />}
                  stat={campaignStats?.interestRate}
                />
              </Stack>
            </Stack>
          </Stack>
          <Button variant={ButtonVariant.Ghost} removePadding={true} onClick={onViewClick}>
            <Body color={colors.linkLight}>{"View"}</Body>
          </Button>
        </Stack>
        <CustomModal
          open={isModalOpen}
          dialogActionsStyles={{ padding: "8px 24px" }}
          customPaperProps={{ sx: { overflowY: "hidden" } }}
          customDialogStyles={{ zIndex: 10000 }}
          onClose={(): void => {
            setIsModalOpen(false);
          }}
          title={<Heading weight={"600"}>{"Edit Outreach"}</Heading>}
          maxWidth={"md"}
        >
          <CampaignEditorWrapper
            jobId={jobId}
            campaignId={campaign.id}
            onSave={(): void => {
              setIsModalOpen(false);
            }}
          />
        </CustomModal>
      </>
    );
  }
);

interface AddOrRemoveCampaignModalProps {
  isPrimaryModalOpen: boolean;
  setIsPrimaryModalOpen: (isModalOpen: boolean) => void;
  search: SearchV3;
}

export const AddOrRemoveCampaignModal = React.memo(
  ({ isPrimaryModalOpen, setIsPrimaryModalOpen, search }: AddOrRemoveCampaignModalProps): React.ReactElement => {
    const { allCampaigns } = useListCampaignsQuery(
      { jobId: search.job },
      {
        selectFromResult: rtkResults => selectFromListCampaignsQueryResult(rtkResults),
      }
    );

    const [campaignToEdit, setCampaignToEdit] = React.useState<string | undefined>(undefined);
    const [isCreateNewCampaignModalOpen, setIsCreateNewCampaignModalOpen] = React.useState(false);

    const [selectedCampaignIds, setSelectedCampaignIds] = React.useState<string[]>(search.campaignIds ?? []);

    const [addCampaignToSearch, { isLoading: isAddingCampaignToSearch }] = useAddCampaignToSearchMutation();
    const [
      removeCampaignFromSearch,
      { isLoading: isRemovingCampaignFromSearch },
    ] = useRemoveCampaignFromSearchMutation();

    const handleSaveSelectedCampaigns = React.useCallback((): void => {
      if (!search.id) {
        return;
      }

      const addPromises: Promise<any>[] = [];
      for (let i = 0; i < selectedCampaignIds.length; i++) {
        const campaignId = selectedCampaignIds[i];
        if (!search.campaignIds?.includes(campaignId)) {
          addPromises.push(addCampaignToSearch({ searchId: search.id, campaignId }).unwrap());
        }
      }

      const removePromises: Promise<any>[] = [];
      for (let i = 0; i < (search.campaignIds ?? []).length ?? 0; i++) {
        const campaignId = search.campaignIds![i];
        if (!selectedCampaignIds.includes(campaignId)) {
          removePromises.push(removeCampaignFromSearch({ searchId: search.id, campaignId }).unwrap());
        }
      }

      Promise.all([...addPromises, ...removePromises]).then(() => setIsPrimaryModalOpen(false));
    }, [
      addCampaignToSearch,
      removeCampaignFromSearch,
      search.campaignIds,
      search.id,
      selectedCampaignIds,
      setIsPrimaryModalOpen,
    ]);

    const handleToggleSelectedCampaignId = React.useCallback(
      (campaignId: string) => {
        if (!selectedCampaignIds.includes(campaignId)) {
          setSelectedCampaignIds([...selectedCampaignIds, campaignId]);
          return;
        }

        setSelectedCampaignIds(selectedCampaignIds.filter(id => id !== campaignId));
      },
      [selectedCampaignIds]
    );
    const closePrimaryModal = React.useCallback((): void => {
      setIsPrimaryModalOpen(false);
    }, [setIsPrimaryModalOpen]);

    const primaryModalBody = React.useMemo(() => {
      return (
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Subtitle1 weight="600">{"Select a campaign"}</Subtitle1>
            <Button
              variant={ButtonVariant.Ghost}
              removePadding={true}
              onClick={(): void => {
                setIsCreateNewCampaignModalOpen(true);
              }}
            >
              <Body color={colors.linkLight}>{"+ Create new"}</Body>
            </Button>
          </Stack>
          <Stack
            sx={{ border: `1px solid ${colors.grayscale.gray200}`, borderRadius: "4px" }}
            divider={<Divider orientation="horizontal" flexItem sx={{ borderColor: colors.grayscale.gray200 }} />}
          >
            {allCampaigns?.map(campaign => {
              return (
                <CampaignRow
                  campaign={campaign}
                  selected={!!campaign.id && selectedCampaignIds.includes(campaign.id)}
                  setCampaignToEdit={setCampaignToEdit}
                  jobId={search.job}
                  toggleCampaignSelected={handleToggleSelectedCampaignId}
                  closePrimaryModal={closePrimaryModal}
                />
              );
            })}
          </Stack>
        </Stack>
      );
    }, [allCampaigns, closePrimaryModal, handleToggleSelectedCampaignId, search.job, selectedCampaignIds]);

    return (
      <>
        <CustomModal
          open={isPrimaryModalOpen}
          dialogActionsStyles={{ padding: "8px 24px" }}
          customPaperProps={{ sx: { overflowY: "hidden" } }}
          onClose={(): void => {
            setIsPrimaryModalOpen(false);
          }}
          title={<Heading weight={"600"}>{"Add campaign"}</Heading>}
          maxWidth={"sm"}
          omitDividers={true}
          dialogActions={
            <Stack direction="row" spacing={1}>
              <Button
                variant={ButtonVariant.Secondary}
                onClick={(event: React.MouseEvent): void => {
                  setIsPrimaryModalOpen(false);
                  event.stopPropagation();
                }}
              >
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.Primary}
                onClick={(event: React.MouseEvent): void => {
                  handleSaveSelectedCampaigns();
                  event.stopPropagation();
                }}
                loading={isAddingCampaignToSearch || isRemovingCampaignFromSearch}
              >
                Save
              </Button>
            </Stack>
          }
        >
          {primaryModalBody}
        </CustomModal>
        <CustomModal
          open={!!campaignToEdit}
          dialogActionsStyles={{ padding: "8px 24px" }}
          customPaperProps={{ sx: { overflowY: "hidden" } }}
          customDialogStyles={{ zIndex: 1100 }}
          onClose={(): void => {
            setCampaignToEdit(undefined);
          }}
          title={<Heading weight={"600"}>{"Edit Outreach"}</Heading>}
          maxWidth={"md"}
        >
          {campaignToEdit ? (
            <CampaignEditorWrapper
              jobId={search.job}
              campaignId={campaignToEdit}
              onSave={(): void => {
                setCampaignToEdit(undefined);
              }}
            />
          ) : (
            <></>
          )}
        </CustomModal>
        <CreateCampaignModal
          campaignToClone={allCampaigns?.[0]?.id}
          jobId={search.job}
          isModalOpen={isCreateNewCampaignModalOpen}
          handleCloseModal={(): void => {
            setIsCreateNewCampaignModalOpen(false);
          }}
          onNewCampaignCreated={(newCampaignId: string): void => {
            setCampaignToEdit(newCampaignId);
            setIsCreateNewCampaignModalOpen(false);
          }}
        />
      </>
    );
  }
);
