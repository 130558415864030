import CompanyPitch from "views/create-job/ExtensionJobCreationFlow/steps/CompanyPitch";
import CreateJob from "views/create-job/ExtensionJobCreationFlow/steps/CreateJob";
import JobCreationComplete from "views/create-job/ExtensionJobCreationFlow/steps/JobCreationComplete";
import JobOutreach from "views/create-job/ExtensionJobCreationFlow/steps/JobOutreach";
import {
  ExtensionJobCreationConstants,
  ExtensionJobCreationStep,
} from "views/create-job/ExtensionJobCreationFlow/types";

export const EXTENSION_JOB_CREATION_CONFIG: Readonly<
  { [key in ExtensionJobCreationStep]: ExtensionJobCreationConstants }
> = {
  [ExtensionJobCreationStep.BasicsStep]: {
    nextStep: ExtensionJobCreationStep.CompanyPitchStep,
    path: "basics",
    component: CreateJob,
    index: 0,
  },
  [ExtensionJobCreationStep.CompanyPitchStep]: {
    nextStep: ExtensionJobCreationStep.OutreachStep,
    path: "company-pitch",
    component: CompanyPitch,
    index: 1,
  },
  [ExtensionJobCreationStep.OutreachStep]: {
    nextStep: ExtensionJobCreationStep.CompletedStep,
    path: "draft-outreach",
    component: JobOutreach,
    index: 2,
  },
  [ExtensionJobCreationStep.CompletedStep]: {
    nextStep: undefined,
    path: "success",
    component: JobCreationComplete,
    index: 3,
  },
};

export const KEYED_BY_PATH_EXTENSION_JOB_CREATION_CONFIG = Object.keys(EXTENSION_JOB_CREATION_CONFIG).reduce(
  (acc, key) => {
    const step = EXTENSION_JOB_CREATION_CONFIG[key as ExtensionJobCreationStep];
    acc[step.path] = step;
    return acc;
  },
  {} as { [key: string]: ExtensionJobCreationConstants }
);

export const MAX_CONTENT_WIDTH = 580;

export const GOOGLE_CHROME_EXTENSION_URL =
  "https://chrome.google.com/webstore/detail/dover/ekmeakdlmfdpidkmifdcgaekafojmfch/related?hl=en";

export const START_SOURCING_LINKEDIN_URL = "https://www.linkedin.com/in/maxkolysh/";
