/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PassingAndFailingFilters
 */
export interface PassingAndFailingFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof PassingAndFailingFilters
     */
    nthPassingFilters?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PassingAndFailingFilters
     */
    nthFailingFilters?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PassingAndFailingFilters
     */
    mhPassingFilters?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PassingAndFailingFilters
     */
    mhFailingFilters?: Array<string>;
}

export function PassingAndFailingFiltersFromJSON(json: any): PassingAndFailingFilters {
    return PassingAndFailingFiltersFromJSONTyped(json, false);
}

export function PassingAndFailingFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassingAndFailingFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nthPassingFilters': !exists(json, 'nth_passing_filters') ? undefined : json['nth_passing_filters'],
        'nthFailingFilters': !exists(json, 'nth_failing_filters') ? undefined : json['nth_failing_filters'],
        'mhPassingFilters': !exists(json, 'mh_passing_filters') ? undefined : json['mh_passing_filters'],
        'mhFailingFilters': !exists(json, 'mh_failing_filters') ? undefined : json['mh_failing_filters'],
    };
}

export function PassingAndFailingFiltersToJSON(value?: PassingAndFailingFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nth_passing_filters': value.nthPassingFilters,
        'nth_failing_filters': value.nthFailingFilters,
        'mh_passing_filters': value.mhPassingFilters,
        'mh_failing_filters': value.mhFailingFilters,
    };
}


