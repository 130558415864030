import { Box, Stack } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall } from "components/library/typography";
import Confirm from "components/Modal/ConfirmModal";
import { Spacer } from "components/Spacer";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { usePartialUpdateCampaignMutation } from "services/doverapi/endpoints/campaign";
import { CampaignCampaignStateEnum, ListCampaign, ProUser } from "services/openapi";
import { colors } from "styles/theme";
import { CampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/types";
import { getCampaignStatus } from "views/job/JobSetup/steps/CampaignVariants/utils";
import { StyledSwitch } from "views/job/JobSetup/steps/Overview/styles";

const CampaignStateButton = ({
  campaign,
  campaignSender,
}: {
  campaign: ListCampaign;
  campaignSender: ProUser | undefined;
}): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  const [partialUpdateCampaign, { isLoading }] = usePartialUpdateCampaignMutation();

  const campaignStatus = getCampaignStatus(campaign.state!, campaign.setupState!);

  const [followUpEmailChecked, setFollowUpEmailChecked] = React.useState<boolean>(false);

  const handleToggleFollowUpEmailChecked = React.useCallback(() => {
    setFollowUpEmailChecked(!followUpEmailChecked);
  }, [followUpEmailChecked]);

  React.useEffect(() => {
    if (!isLoading) {
      setFollowUpEmailChecked(false);
    }
  }, [isLoading]);

  const handleUpdateCampaignState = React.useCallback(
    (newState: CampaignCampaignStateEnum, allowFollowUpsPostDeactivation?: boolean) => {
      const tryUpdateCampaignState = async (): Promise<void> => {
        if (!jobId) {
          return;
        }

        await partialUpdateCampaign({
          id: campaign.id!,
          jobId: jobId,
          updatedCampaign: {
            name: campaign.name!,
            campaignState: newState,
            allowFollowUpsPostDeactivation: allowFollowUpsPostDeactivation ?? followUpEmailChecked,
          },
        }).unwrap();
      };
      tryUpdateCampaignState();
    },
    [campaign.id, campaign.name, followUpEmailChecked, jobId, partialUpdateCampaign]
  );

  const DeactivateCampaignModalContent = React.useMemo(
    (): React.ReactElement => (
      <>
        <Spacer height={16} />
        <BodySmall>
          No new candidates will be contacted with outreach from this campaign. Existing queued candidates will still be
          sent outreach from this campaign. You can indicate below if you would like existing candidates to be sent
          follow-ups.
        </BodySmall>
        <Spacer height={32} />
        <Box>
          <Stack direction="row" spacing={1}>
            <StyledSwitch
              checked={followUpEmailChecked}
              onChange={handleToggleFollowUpEmailChecked}
              $checked={followUpEmailChecked}
            />
            <Stack spacing={0}>
              <BodySmall>{"Send Followup Emails to Existing Candidates"}</BodySmall>
            </Stack>
          </Stack>
        </Box>
      </>
    ),
    [followUpEmailChecked, handleToggleFollowUpEmailChecked]
  );

  const ActivateCampaignModalContent = React.useMemo(
    (): React.ReactElement => (
      <>
        <Spacer height={16} />
        <BodySmall>Once you activate, new candidates will start receiving this outreach.</BodySmall>
        <Spacer height={16} />
        <ul>
          <li>
            <BodySmall>Remember to check for any typos.</BodySmall>
          </li>
          <Spacer height={8} />
          <li>
            <BodySmall>
              {campaignSender
                ? `Dover will send emails from ${campaignSender?.fullName}. Please make sure that the emails are addressed
            from this person.`
                : "Please make sure that the emails are addressed from the correct sender."}
            </BodySmall>
          </li>
        </ul>
        <Spacer height={16} />
      </>
    ),
    [campaignSender]
  );

  const DeactivationModal = React.useMemo(() => {
    return (
      <Confirm
        title={<Body>Deactivate Campaign?</Body>}
        submitText={`Deactivate`}
        content={DeactivateCampaignModalContent}
        useProcessing
        processing={isLoading}
        submitVariant="critical"
        callbackOverride={(): void => handleUpdateCampaignState(CampaignCampaignStateEnum.Inactive)}
      >
        {(onShowModal: any): React.ReactNode => (
          <Button variant={ButtonVariant.Secondary} disabled={isLoading} onClick={onShowModal()}>
            <Body color={colors.critical.base} weight="500">
              Deactivate
            </Body>
          </Button>
        )}
      </Confirm>
    );
  }, [DeactivateCampaignModalContent, handleUpdateCampaignState, isLoading]);

  const ActivationModal = React.useMemo(() => {
    return (
      <Confirm
        title={<Body>Activate campaign?</Body>}
        submitText={`Activate`}
        content={ActivateCampaignModalContent}
        useProcessing
        processing={isLoading}
        submitVariant="success"
        callbackOverride={(): void => handleUpdateCampaignState(CampaignCampaignStateEnum.Active, false)}
      >
        {(onShowModal: any): React.ReactNode => (
          <Button variant={ButtonVariant.Secondary} disabled={isLoading} onClick={onShowModal()}>
            <Body weight="500">Activate</Body>
          </Button>
        )}
      </Confirm>
    );
  }, [ActivateCampaignModalContent, handleUpdateCampaignState, isLoading]);

  if (campaignStatus === CampaignStatus.Active) {
    return <>{DeactivationModal}</>;
  } else if (campaignStatus === CampaignStatus.Inactive) {
    return <>{ActivationModal}</>;
  }
  return <></>;
};

export default CampaignStateButton;
