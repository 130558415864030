import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { candidateEndpoints } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import {
  ListPipelineCandidatesArgs,
  pipelineEndpoints,
} from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { STARRED_CANDIDATE_LIST } from "services/doverapi/endpointTagsConstants";
import {
  StarredCandidate,
  ApiApiListStarredCandidatesRequest,
  ApiApiToggleStarCandidateOperationRequest,
} from "services/openapi";

// name and string used for optimistic updates
export interface ToggleStarCandidateArgs extends ApiApiToggleStarCandidateOperationRequest {
  fullName: string | undefined;
  jobId: string | undefined;
  candidateListArgs?: ListPipelineCandidatesArgs;
}

export const starredCandidateEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listStarredCandidates: build.query<Array<StarredCandidate>, ApiApiListStarredCandidatesRequest>({
      queryFn: async args => {
        try {
          const { apiApi: client } = await getOpenApiClients({});

          const result = await client.listStarredCandidates(args);
          return { data: result.results };
        } catch (error) {
          console.error(error);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [{ type: STARRED_CANDIDATE_LIST }];
      },
    }),
    toggleStarredCandidate: build.mutation<void, ToggleStarCandidateArgs>({
      queryFn: async ({ data }) => {
        try {
          const { apiApi: client } = await getOpenApiClients({});

          const result = await client.toggleStarCandidate({ data });
          return { data: result };
        } catch (error) {
          console.error(error);

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: [{ type: STARRED_CANDIDATE_LIST }],
      onQueryStarted: async (
        { fullName, jobId, data: { candidateId }, candidateListArgs },
        { dispatch, queryFulfilled }
      ) => {
        const bioPatch = dispatch(
          candidateEndpoints.util.updateQueryData("getCandidateBio", candidateId, draft => {
            Object.assign(draft, { isStarred: !draft.isStarred });
          })
        );

        const starredListPatch = dispatch(
          starredCandidateEndpoints.util.updateQueryData("listStarredCandidates", {}, draft => {
            const candidateIndex = draft.findIndex(candidate => candidate.candidateId === candidateId);
            if (candidateIndex === -1) {
              // This shouldn't happen, just a consequence of openapi types
              if (!fullName || !jobId) return;

              draft.push({
                id: undefined,
                candidateId: candidateId,
                jobId,
                fullName: fullName || "",
              });
            } else {
              draft.splice(candidateIndex, 1);
            }
          })
        );

        const candidatesListPatch = candidateListArgs
          ? dispatch(
              pipelineEndpoints.util.updateQueryData("listPipelineCandidates", candidateListArgs, draft => {
                const candidateIndex = draft.results.findIndex(candidate => candidate.id === candidateId);
                if (candidateIndex !== -1) {
                  // @ts-ignore: 2540
                  draft.results[candidateIndex].isStarred = !draft.results[candidateIndex].isStarred;
                }
              })
            )
          : undefined;

        try {
          await queryFulfilled;
        } catch {
          // Undo our optimistic updates if the mutation ended up failing server-side.
          bioPatch.undo();
          starredListPatch.undo();
          candidatesListPatch?.undo();
        }
      },
    }),
  }),
});

export const { useListStarredCandidatesQuery, useToggleStarredCandidateMutation } = starredCandidateEndpoints;
