import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, MenuItem } from "@mui/material";
import React, { ReactElement, useRef } from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";
import { B3_doNotUse } from "styles/typography";
import { ConfirmationMenu, DropdownToggle, StyledMenu } from "views/CandidateDetail/components/event-feed/styles";

interface DeleteConfirmationMenuProps {
  open: boolean;
  onClose: () => void | undefined;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  anchorEl: Element | null;
  onConfirm: () => Promise<void>;
}

interface NoteActionsMenuProps {
  showEditButton: boolean;
  showDeleteButton: boolean;
  showPinButton: boolean;
  setEditModeOn: () => void;
  deleteNote: () => Promise<void>;
  pinNote: (state: boolean) => Promise<void>;
  notePinned?: boolean;
}

const DeleteConfirmationMenu = ({
  open,
  onClose,
  onClick,
  anchorEl,
  onConfirm,
}: DeleteConfirmationMenuProps): ReactElement => {
  return (
    <ConfirmationMenu
      open={open}
      onClose={onClose}
      onClick={onClick}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <B3_doNotUse bold>ARE YOU SURE?</B3_doNotUse>
      <Spacer height={5} />
      <Box display="flex">
        <Button variant={ButtonVariant.Ghost} onClick={onClose}>
          Cancel
        </Button>
        <Spacer width={8} />
        <Button onClick={onConfirm} variant={ButtonVariant.Critical}>
          Delete
        </Button>
      </Box>
    </ConfirmationMenu>
  );
};

export const NoteActionsMenu = ({
  showEditButton,
  showDeleteButton,
  showPinButton,
  setEditModeOn,
  deleteNote,
  pinNote,
  notePinned,
}: NoteActionsMenuProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteMenuOpen, setDeleteMenuOpen] = React.useState<boolean>(false);
  const menuOpen = Boolean(anchorEl);
  const deleteMenuToggle = useRef<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleDeleteMenuClose = (): void => {
    setDeleteMenuOpen(false);
  };

  return (
    <>
      <DropdownToggle
        onClick={(e: React.MouseEvent<HTMLElement>): void => {
          handleClick(e);
        }}
        ref={deleteMenuToggle}
      >
        <MoreHorizIcon style={{ color: "#000000" }} />
      </DropdownToggle>
      <StyledMenu
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {showEditButton && (
          <MenuItem sx={{ borderBottom: `solid 1px ${colors.grayscale.gray200}` }} onClick={setEditModeOn}>
            Edit
          </MenuItem>
        )}
        {showDeleteButton && (
          <MenuItem
            onClick={(): void => {
              setDeleteMenuOpen(true);
            }}
          >
            Delete
          </MenuItem>
        )}
        {showPinButton && (
          <MenuItem
            sx={{ borderBottom: `solid 1px ${colors.grayscale.gray200}` }}
            onClick={(): Promise<void> => pinNote(!notePinned)}
          >
            {notePinned && "Un-Pin"}
            {!notePinned && "Pin"}
          </MenuItem>
        )}
      </StyledMenu>
      <DeleteConfirmationMenu
        open={deleteMenuOpen}
        onClose={handleDeleteMenuClose}
        onClick={handleDeleteMenuClose}
        anchorEl={deleteMenuToggle.current}
        onConfirm={deleteNote}
      />
    </>
  );
};
