/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailHeader
 */
export interface EmailHeader {
    /**
     * 
     * @type {string}
     * @memberof EmailHeader
     */
    emailFrom?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailHeader
     */
    emailTo: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailHeader
     */
    emailCc: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailHeader
     */
    emailBcc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EmailHeader
     */
    emailSubject?: string;
}

export function EmailHeaderFromJSON(json: any): EmailHeader {
    return EmailHeaderFromJSONTyped(json, false);
}

export function EmailHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailHeader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailFrom': !exists(json, 'email_from') ? undefined : json['email_from'],
        'emailTo': json['email_to'],
        'emailCc': json['email_cc'],
        'emailBcc': json['email_bcc'],
        'emailSubject': !exists(json, 'email_subject') ? undefined : json['email_subject'],
    };
}

export function EmailHeaderToJSON(value?: EmailHeader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_from': value.emailFrom,
        'email_to': value.emailTo,
        'email_cc': value.emailCc,
        'email_bcc': value.emailBcc,
        'email_subject': value.emailSubject,
    };
}


