import { TextField } from "mui-rff";
import React from "react";

import {
  ApplicationPortalJob,
  ApplicationQuestion,
  ApplicationQuestionApplicationPortalJob,
  ApplicationQuestionInputTypeEnum,
  ApplicationQuestionQuestionTypeEnum,
  DoverJobDescription,
  JobLocationLocationTypeEnum,
  LocationOptionLocationTypeEnum,
} from "services/openapi";
import { FormLabel } from "views/inboundExternal/styles";

export const getInboundFormFields = (
  requireLinkedinProfileUrl: boolean,
  jobId: string,
  linkedinProfileUrlApplicationQuestion?: ApplicationQuestion | ApplicationQuestionApplicationPortalJob,
  phoneNumberApplicationQuestion?: ApplicationQuestion | ApplicationQuestionApplicationPortalJob
): (ApplicationQuestion & { field: React.ReactElement; isNameField?: boolean })[] => [
  {
    field: (
      <>
        <FormLabel>First Name *</FormLabel>
        <TextField size="small" variant="outlined" name="firstName" margin="none" required={true} />
      </>
    ),
    jobId,
    required: true,
    question: "First Name",
    inputType: ApplicationQuestionInputTypeEnum.ShortAnswer,
    isNameField: true,
  },
  {
    field: (
      <>
        <FormLabel>Last Name *</FormLabel>
        <TextField size="small" variant="outlined" name="lastName" margin="none" required={true} />
      </>
    ),
    jobId,
    required: true,
    question: "Last Name",
    inputType: ApplicationQuestionInputTypeEnum.ShortAnswer,
    isNameField: true,
  },
  {
    field: (
      <>
        <FormLabel>LinkedIn Profile URL{(requireLinkedinProfileUrl ?? true) && " *"}</FormLabel>
        <TextField
          size="small"
          variant="outlined"
          name="linkedinUrl"
          margin="none"
          required={requireLinkedinProfileUrl ?? true}
        />
      </>
    ),
    jobId,
    required: requireLinkedinProfileUrl ?? true,
    question: "LinkedIn Profile URL",
    inputType: ApplicationQuestionInputTypeEnum.ShortAnswer,
    questionType: ApplicationQuestionQuestionTypeEnum.LinkedinUrl,
    id: linkedinProfileUrlApplicationQuestion?.id,
  },
  {
    field: (
      <>
        <FormLabel>Email *</FormLabel>
        <TextField size="small" variant="outlined" type="email" name="email" margin="none" required={true} />
      </>
    ),
    jobId,
    required: true,
    question: "Email",
    inputType: ApplicationQuestionInputTypeEnum.ShortAnswer,
  },
  {
    field: (
      <>
        <FormLabel>Phone Number {phoneNumberApplicationQuestion?.required && "*"}</FormLabel>
        <TextField size="small" variant="outlined" name="phoneNumber" margin="none" required={false} />
      </>
    ),
    jobId,
    required: !!phoneNumberApplicationQuestion?.required,
    question: "Phone Number",
    inputType: ApplicationQuestionInputTypeEnum.ShortAnswer,
    questionType: ApplicationQuestionQuestionTypeEnum.PhoneNumber,
    id: phoneNumberApplicationQuestion?.id,
  },
];

const unixTimeZero = Date.parse("01 Jan 1970 00:00:00 GMT");

export const getGoogleJobPostingStructuredData = (
  jobInfo: ApplicationPortalJob,
  jobDescription?: DoverJobDescription
): string => {
  // block behind FF for now
  if (!jobInfo || !jobInfo.shouldPublishToGoogleJobs) {
    return "";
  }

  const uniqueRemoteCountries = new Set<string>();
  jobInfo.locations?.forEach(l => {
    if (
      l.locationOption?.locationType === LocationOptionLocationTypeEnum.City ||
      l.locationOption?.locationType === LocationOptionLocationTypeEnum.Country
    ) {
      if (l.locationOption.country) {
        uniqueRemoteCountries.add(l.locationOption?.country);
      }
    }
  });

  const physicalLocations = jobInfo.locations?.filter(
    l =>
      l.locationType === JobLocationLocationTypeEnum.InOffice || l.locationType === JobLocationLocationTypeEnum.Hybrid
  );

  const isExpired = !jobDescription?.isPublished || !jobInfo.active || jobInfo.isPrivate;

  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    title: jobInfo.title,
    description: jobInfo.userProvidedDescription,
    identifier: {
      "@type": "PropertyValue",
      name: jobInfo.clientName,
      value: jobInfo.id,
    },
    directApply: true,
    datePosted: jobInfo.created?.toISOString().split("T")[0],
    validThrough: isExpired ? new Date(unixTimeZero) : new Date(jobInfo.created!.getTime() + 30 * 24 * 60 * 60 * 1000),
    employmentType: "FULL_TIME",
    hiringOrganization: {
      "@type": "Organization",
      name: jobInfo.clientName,
      sameAs: jobInfo.clientDomain,
      logo: jobInfo.clientLogo,
    },
    applicantLocationRequirements: Array.from(uniqueRemoteCountries.values()).map(country => {
      return { "@type": "Country", name: country };
    }),
    // add a physical location if there is one
    ...(physicalLocations && {
      jobLocation: physicalLocations.map(physicalLocation => {
        return {
          "@type": "Place",
          address: {
            "@type": "PostalAddress",
            addressLocality: physicalLocation.locationOption?.city,
            addressRegion: physicalLocation.locationOption?.state,
            addressCountry: physicalLocation.locationOption?.country,
          },
        };
      }),
    }),
    // only show salary if openToSharingComp is true
    ...(jobInfo.compensation?.openToSharingComp && {
      baseSalary: {
        "@type": "MonetaryAmount",
        currency: jobInfo.compensation?.currencyCode,
        value: {
          "@type": "QuantitativeValue",
          minValue: jobInfo.compensation?.lowerBound,
          maxValue: jobInfo.compensation?.upperBound,
          unitText: "YEAR",
        },
      },
    }),
    // conditionally include TELECOMMUTE if there is a remote location
    ...(jobInfo.locations?.some(l => l.locationType === JobLocationLocationTypeEnum.Remote) && {
      jobLocationType: "TELECOMMUTE",
    }),
  };

  return JSON.stringify(structuredData);
};
