import { Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, ButtonText, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";

interface InboundApplicationProcessingModalProps {
  open: boolean;
  jobId: string | undefined;
  candidateEmail: string | null | undefined;
}

const InboundApplicationProcessingModal = ({
  open,
  jobId,
  candidateEmail,
}: InboundApplicationProcessingModalProps): React.ReactElement => {
  const navigate = useNavigate();
  const modalText =
    `The inbound application for candidate ${candidateEmail} is still processing.` +
    " This page will redirect to the candidate details once processing has been completed." +
    " Please try again later.";

  const handleReviewApplications = (jobId: string | undefined): void => {
    if (jobId) {
      navigate(APP_ROUTE_PATHS.job.applicationReviewV2(jobId));
    }
  };

  return (
    <CustomModal
      open={open}
      title={<Subtitle2>Inbound Application Still Processing...</Subtitle2>}
      onClose={(): void => {
        handleReviewApplications(jobId);
      }}
      maxWidth="sm"
      hideCloseButton={true}
      dialogActions={
        <>
          <Stack direction="row" spacing={1}>
            <Button
              onClick={(): void => {
                handleReviewApplications(jobId);
              }}
              variant={ButtonVariant.Primary}
            >
              <ButtonText>Review Applications</ButtonText>
            </Button>
          </Stack>
        </>
      }
    >
      <Body>{modalText}</Body>
    </CustomModal>
  );
};

export default InboundApplicationProcessingModal;
