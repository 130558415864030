/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CallRecording
 */
export interface CallRecording {
    /**
     * 
     * @type {number}
     * @memberof CallRecording
     */
    status: number;
    /**
     * 
     * @type {string}
     * @memberof CallRecording
     */
    video: string;
}

export function CallRecordingFromJSON(json: any): CallRecording {
    return CallRecordingFromJSONTyped(json, false);
}

export function CallRecordingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallRecording {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'video': json['video'],
    };
}

export function CallRecordingToJSON(value?: CallRecording | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'video': value.video,
    };
}


