import { Checkbox as MuiCheckbox, CheckboxProps } from "@mui/material";
import React from "react";

import { colors } from "styles/theme";

export const Checkbox = ({ sx, ...props }: CheckboxProps): React.ReactElement => {
  return (
    <MuiCheckbox
      {...props}
      sx={{
        padding: 0,
        marginRight: "4px",
        color: colors.grayscale.gray300,
        "&.Mui-checked": {
          color: colors.primary.base,
        },
        ...sx,
      }}
    />
  );
};
