/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject28
 */
export interface InlineObject28 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject28
     */
    campaignId: string;
}

export function InlineObject28FromJSON(json: any): InlineObject28 {
    return InlineObject28FromJSONTyped(json, false);
}

export function InlineObject28FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject28 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'campaignId': json['campaign_id'],
    };
}

export function InlineObject28ToJSON(value?: InlineObject28 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'campaign_id': value.campaignId,
    };
}


