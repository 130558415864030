import React from "react";

import {
  CandidateBioSchedulingOwnershipEnum,
  NextActionSchedulingStateEnum,
  SaapReviewApplication,
} from "services/openapi";
import { ATSInitialReviewOrCallCompleted } from "views/CandidateDetail/components/next-action/states/ATSInitialReviewOrCallCompleted";
import { ConciergeCallCompleted } from "views/CandidateDetail/components/next-action/states/ConciergeCallCompleted";
import { ConciergeCompletedLastStage } from "views/CandidateDetail/components/next-action/states/ConciergeCompletedLastStage";
import { ConciergeCompletedOffer } from "views/CandidateDetail/components/next-action/states/ConciergeCompletedOffer";
import { ConciergeInitialReview } from "views/CandidateDetail/components/next-action/states/ConciergeInitialReview";
import { ConciergePendingNotes } from "views/CandidateDetail/components/next-action/states/ConciergePendingNotes";
import { ConciergePostDoverInterviewer } from "views/CandidateDetail/components/next-action/states/ConciergePostDoverInterviewer";
import { ConciergeScheduled } from "views/CandidateDetail/components/next-action/states/ConciergeScheduled";
import { ConciergeScheduling } from "views/CandidateDetail/components/next-action/states/ConciergeScheduling";
import { HandlingOffer } from "views/CandidateDetail/components/next-action/states/HandlingOffer";
import { InterviewScheduled } from "views/CandidateDetail/components/next-action/states/InterviewScheduled";
import { UserIsSchedulingInterviewNew } from "views/CandidateDetail/components/next-action/states/UserIsSchedulingInterview";
import { NextActionStateComponentProps } from "views/CandidateDetail/components/next-action/utils/constants";

export function NextActionCard({
  application, // For use in app review where you can bookmark people
  candidateBio,
  showBookmarkButton,
}: NextActionStateComponentProps & {
  application?: SaapReviewApplication;
  showBookmarkButton?: boolean;
}): React.ReactElement {
  const schedulingOwnership = candidateBio?.schedulingOwnership;
  const schedulingState = candidateBio?.nextAction?.schedulingState;
  const hasDoverInterviewer = candidateBio?.nextAction?.currentInterview?.hasDoverInterviewer;
  const hasNotesSubmitted = hasDoverInterviewer && candidateBio?.nextAction?.currentInterview?.hasCompletedNotes;
  const isLastInterview = candidateBio?.nextAction?.isLastInterview;

  if (candidateBio.status) {
    return <></>;
  }

  switch (schedulingOwnership) {
    case CandidateBioSchedulingOwnershipEnum.DoverHandlesScheduling:
      switch (schedulingState) {
        case NextActionSchedulingStateEnum.OfferExtended:
          // Offer
          return <ConciergeCompletedOffer candidateBio={candidateBio} />;
        case NextActionSchedulingStateEnum.NewLead:
        case NextActionSchedulingStateEnum.InitialReview:
          return (
            <ConciergeInitialReview
              application={application}
              candidateBio={candidateBio}
              showBookmarkButton={showBookmarkButton}
            />
          );
        case NextActionSchedulingStateEnum.CallCompleted:
          // Last Stage
          if (isLastInterview) {
            return <ConciergeCompletedLastStage candidateBio={candidateBio} />;
          }

          if (hasDoverInterviewer) {
            if (hasNotesSubmitted) {
              // Post Dover Interviewer
              return <ConciergePostDoverInterviewer candidateBio={candidateBio} />;
            }
            // Post DI, pending notes submission
            return <ConciergePendingNotes candidateBio={candidateBio} />;
          } else {
            // Call Completed
            return <ConciergeCallCompleted candidateBio={candidateBio} />;
          }
        case NextActionSchedulingStateEnum.Scheduling:
          return <ConciergeScheduling candidateBio={candidateBio} />;
        case NextActionSchedulingStateEnum.Scheduled:
          return <ConciergeScheduled candidateBio={candidateBio} />;
      }
      break;

    case CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling:
      switch (schedulingState) {
        case NextActionSchedulingStateEnum.OfferExtended:
          return <HandlingOffer candidateBio={candidateBio} />;
        case NextActionSchedulingStateEnum.InitialReview:
        case NextActionSchedulingStateEnum.CallCompleted:
          return <ATSInitialReviewOrCallCompleted candidateBio={candidateBio} />;
        case NextActionSchedulingStateEnum.Scheduling:
          return <UserIsSchedulingInterviewNew candidateBio={candidateBio} />;
        case NextActionSchedulingStateEnum.Scheduled:
          return <InterviewScheduled candidateBio={candidateBio} />;
      }
      break;
  }
  return <></>;
}
