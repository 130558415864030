/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateFeedbackTemplateRequest
 */
export interface UpdateFeedbackTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedbackTemplateRequest
     */
    name: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateFeedbackTemplateRequest
     */
    questions: Array<object>;
}

export function UpdateFeedbackTemplateRequestFromJSON(json: any): UpdateFeedbackTemplateRequest {
    return UpdateFeedbackTemplateRequestFromJSONTyped(json, false);
}

export function UpdateFeedbackTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFeedbackTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'questions': json['questions'],
    };
}

export function UpdateFeedbackTemplateRequestToJSON(value?: UpdateFeedbackTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'questions': value.questions,
    };
}


