import { Autocomplete, Box, FilterOptionsState, Skeleton, Stack, TextField } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useState } from "react";

import { PersonaBanner } from "components/dover/CreateJob/PersonaBanner";
import { StyledAutocompleteWrapper } from "components/dover/TitlesAutocomplete";
import { Body, BodyExtraSmall, BodySmall, Subtitle2 } from "components/library/typography";
import {
  useGetPersonaViaSoftMatchQuery,
  useListJobPersonasQuery,
} from "services/doverapi/endpoints/create-job/endpoints";
import { JobSetupPersona } from "services/openapi";
import { colors } from "styles/theme";

export const getPersonasAutocompleteFilterOptions = ({
  options,
  params,
}: {
  options: JobSetupPersona[];
  params: FilterOptionsState<JobSetupPersona>;
}): JobSetupPersona[] => {
  return options.filter(
    item =>
      item.id === 168 ||
      item.customerFacingName?.toLowerCase().includes(params.inputValue.toLowerCase()) ||
      item.titlePatterns?.some((title: string) => title.toLowerCase().includes(params.inputValue.toLowerCase()))
  );
};

interface PersonasAutocompleteProps {
  title: string;
  errorText?: string;
  defaultValue?: number;
  hideLabel?: boolean;
  hideHelpText?: boolean;
  hideBanner?: boolean;
  onChange?: (value: number | undefined) => void;
}

export const PersonasAutocomplete = React.memo(
  ({
    title,
    errorText,
    defaultValue,
    hideLabel,
    hideHelpText,
    hideBanner,
    onChange,
  }: PersonasAutocompleteProps): React.ReactElement => {
    const { data: allPersonas, isLoading } = useListJobPersonasQuery();
    const { isFetching: isFetchingSoftMatch } = useGetPersonaViaSoftMatchQuery(title || skipToken);

    const customPersonaName = React.useMemo(() => {
      return title ? `${title} (custom)` : "Custom";
    }, [title]);

    const customPersona = React.useMemo(() => {
      return {
        name: customPersonaName,
        customerFacingName: customPersonaName,
        id: 168,
        titlePatterns: [],
        jobCategoryDisplay: "Custom",
        definition: "Custom",
      };
    }, [customPersonaName]);

    const personaOptions = React.useMemo(() => {
      if (allPersonas) {
        return [...allPersonas, customPersona];
      }
      return [];
    }, [customPersona, allPersonas]);

    const [selectedPersona, setSelectedPersona] = useState<JobSetupPersona | null | undefined>(null);

    useEffect(() => {
      if (defaultValue && personaOptions) {
        const persona = personaOptions.find(persona => persona.id === defaultValue);
        if (persona) {
          setSelectedPersona(persona);
        }
      }
    }, [defaultValue, personaOptions]);

    const getOptionLabel = useCallback(
      (option: JobSetupPersona): string => (option.id === 168 ? customPersonaName : option.customerFacingName ?? ""),
      [customPersonaName]
    );

    return (
      <Stack width={"100%"} spacing={0.5}>
        {!hideLabel && <Subtitle2>{"Role Type"}</Subtitle2>}
        {(selectedPersona?.id && selectedPersona?.id !== 168) || !isFetchingSoftMatch ? (
          <Stack spacing={1}>
            {!hideBanner && <PersonaBanner title={title} personaId={selectedPersona?.id} />}
            <StyledAutocompleteWrapper>
              <Autocomplete
                // disableClearable
                size="small"
                loading={isLoading}
                loadingText="Loading..."
                onChange={(_, value): void => {
                  setSelectedPersona(value ?? undefined);
                  if (onChange) {
                    onChange(value?.id);
                  }
                }}
                value={selectedPersona}
                groupBy={(option): string => option.jobCategoryDisplay ?? ""}
                options={personaOptions ?? []}
                isOptionEqualToValue={(option, value): boolean => option?.id === value?.id}
                getOptionLabel={getOptionLabel}
                filterOptions={(options, params): JobSetupPersona[] =>
                  getPersonasAutocompleteFilterOptions({ options, params })
                }
                renderInput={(params): React.ReactElement => <TextField {...params} />}
                renderOption={(props, option): React.ReactElement => {
                  return (
                    // @ts-ignore - the mui props don't agree with the li typing
                    <li
                      {...props}
                      style={{
                        listStyle: "none",
                        width: "100%",
                      }}
                    >
                      <Stack spacing={0.5}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Box display="flex">
                            <Body>{option.id === 168 ? customPersonaName : option.customerFacingName}</Body>
                          </Box>
                        </Box>
                        <Box display="flex">
                          <BodySmall color={colors.grayscale.gray500}>{option.definition}</BodySmall>
                        </Box>
                      </Stack>
                    </li>
                  );
                }}
              />
            </StyledAutocompleteWrapper>
            {!hideHelpText && (
              <BodyExtraSmall color={colors.grayscale.gray600}>
                This is used to help filter candidates by job title. You can either select a predefined role type or
                create a custom one.
              </BodyExtraSmall>
            )}
            {errorText && <BodySmall color={colors.critical.base}>{errorText}</BodySmall>}
          </Stack>
        ) : (
          <>
            {!hideBanner && <Skeleton variant="rectangular" height="60px" />}
            <Skeleton variant="rectangular" height="50px" />
            {!hideHelpText && <Skeleton variant="rectangular" height="30px" />}
          </>
        )}
      </Stack>
    );
  }
);
