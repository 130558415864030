/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SnoozeJob
 */
export interface SnoozeJob {
    /**
     * 
     * @type {boolean}
     * @memberof SnoozeJob
     */
    snooze: boolean;
    /**
     * 
     * @type {string}
     * @memberof SnoozeJob
     */
    snoozedReason?: SnoozeJobSnoozedReasonEnum;
    /**
     * 
     * @type {Date}
     * @memberof SnoozeJob
     */
    snoozedUntil?: Date;
}

/**
* @export
* @enum {string}
*/
export enum SnoozeJobSnoozedReasonEnum {
    OutOfOffice = 'Out of Office',
    HasCandidatesInProcess = 'Has Candidates in Process',
    DontNeedToHireForThisJob = 'Dont Need to Hire for this Job',
    NeedToRecalibrateSearch = 'Need to recalibrate search',
    Other = 'Other'
}

export function SnoozeJobFromJSON(json: any): SnoozeJob {
    return SnoozeJobFromJSONTyped(json, false);
}

export function SnoozeJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): SnoozeJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'snooze': json['snooze'],
        'snoozedReason': !exists(json, 'snoozed_reason') ? undefined : json['snoozed_reason'],
        'snoozedUntil': !exists(json, 'snoozed_until') ? undefined : (new Date(json['snoozed_until'])),
    };
}

export function SnoozeJobToJSON(value?: SnoozeJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'snooze': value.snooze,
        'snoozed_reason': value.snoozedReason,
        'snoozed_until': value.snoozedUntil === undefined ? undefined : (value.snoozedUntil.toISOString()),
    };
}


