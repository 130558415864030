import classNames from "classnames";
import React, { ReactElement } from "react";

import { StyledTile, StyledGraphic } from "components/inputs/TileSelect/styles";

type TileProps = {
  label: string;
  value: string;
  onClick: Function;
  graphic?: string;
  active?: boolean;
};

const Tile = ({ label, value, onClick, graphic, active }: TileProps): ReactElement => {
  return (
    <StyledTile className={classNames({ active: active })} onClick={(): void => onClick(value)}>
      {label}
      {graphic && <StyledGraphic src={graphic} />}
    </StyledTile>
  );
};

export default Tile;
