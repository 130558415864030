/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchTransformComparison,
    SearchTransformComparisonFromJSON,
    SearchTransformComparisonFromJSONTyped,
    SearchTransformComparisonToJSON,
    SlimSearch,
    SlimSearchFromJSON,
    SlimSearchFromJSONTyped,
    SlimSearchToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminSearchTransformRelationship
 */
export interface AdminSearchTransformRelationship {
    /**
     * 
     * @type {SlimSearch}
     * @memberof AdminSearchTransformRelationship
     */
    childSearch?: SlimSearch;
    /**
     * 
     * @type {SlimSearch}
     * @memberof AdminSearchTransformRelationship
     */
    parentSearch?: SlimSearch;
    /**
     * 
     * @type {string}
     * @memberof AdminSearchTransformRelationship
     */
    readonly searchTransformName?: AdminSearchTransformRelationshipSearchTransformNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSearchTransformRelationship
     */
    readonly overridden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminSearchTransformRelationship
     */
    readonly linked?: boolean;
    /**
     * 
     * @type {Array<SearchTransformComparison>}
     * @memberof AdminSearchTransformRelationship
     */
    readonly overriddenParameters?: Array<SearchTransformComparison>;
}

/**
* @export
* @enum {string}
*/
export enum AdminSearchTransformRelationshipSearchTransformNameEnum {
    Clone = 'CLONE',
    Inbound = 'INBOUND',
    InboundV3 = 'INBOUND_V3',
    Referrals = 'REFERRALS',
    TargetedCompanies = 'TARGETED_COMPANIES',
    ProfileAttributes = 'PROFILE_ATTRIBUTES',
    Reengagement = 'REENGAGEMENT'
}

export function AdminSearchTransformRelationshipFromJSON(json: any): AdminSearchTransformRelationship {
    return AdminSearchTransformRelationshipFromJSONTyped(json, false);
}

export function AdminSearchTransformRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSearchTransformRelationship {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'childSearch': !exists(json, 'child_search') ? undefined : SlimSearchFromJSON(json['child_search']),
        'parentSearch': !exists(json, 'parent_search') ? undefined : SlimSearchFromJSON(json['parent_search']),
        'searchTransformName': !exists(json, 'search_transform_name') ? undefined : json['search_transform_name'],
        'overridden': !exists(json, 'overridden') ? undefined : json['overridden'],
        'linked': !exists(json, 'linked') ? undefined : json['linked'],
        'overriddenParameters': !exists(json, 'overridden_parameters') ? undefined : ((json['overridden_parameters'] as Array<any>).map(SearchTransformComparisonFromJSON)),
    };
}

export function AdminSearchTransformRelationshipToJSON(value?: AdminSearchTransformRelationship | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'child_search': SlimSearchToJSON(value.childSearch),
        'parent_search': SlimSearchToJSON(value.parentSearch),
    };
}


