import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import { checkboxClasses } from "@mui/material/Checkbox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment-timezone";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Subtitle1 } from "components/library/typography";
import { MultipartSchedulingClientInterviewer, MultipartSchedulingBaseInterviewSubstage } from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import { interviewSchedulerActions } from "views/interview/InterviewScheduler/reducers";
import { selectTimezone } from "views/interview/InterviewScheduler/selectors";
import { CalendarResource } from "views/interview/InterviewScheduler/types";

interface InterviewerOption {
  interviewerIds: string[];
  label: string;
}

export const InterviewPlan = ({
  interviewRounds,
  allClientInterviewers,
  interviewNameOptions,
  removeLocalInterviewSubstage,
  disabled,
}: {
  interviewRounds: Array<{ entityId: string; substage: MultipartSchedulingBaseInterviewSubstage }>;
  allClientInterviewers: MultipartSchedulingClientInterviewer[];
  interviewNameOptions: string[];
  removeLocalInterviewSubstage: Function;
  disabled?: boolean;
}): React.ReactElement => {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box width="3%">
          <Subtitle1>Order</Subtitle1>
        </Box>
        <Box width="10%">
          <Subtitle1>Date</Subtitle1>
        </Box>
        <Box width="10%">
          <Subtitle1>Start Time</Subtitle1>
        </Box>
        <Box width="23%">
          <Subtitle1>Interview Round</Subtitle1>
        </Box>
        <Box width="10%">
          <Subtitle1>Duration</Subtitle1>
        </Box>
        <Box width="25%">
          <Subtitle1>Interviewer</Subtitle1>
        </Box>
        <Box width="5%">
          <Subtitle1>Delete</Subtitle1>
        </Box>
        <Box width="5%">
          <Subtitle1>Cancel</Subtitle1>
        </Box>
      </Box>
      {interviewRounds.map((round: any) => (
        <InterviewRound
          key={round.entityId}
          entityId={round.entityId}
          interviewRound={round.substage}
          allInterviewers={allClientInterviewers}
          interviewNameOptions={interviewNameOptions}
          removeLocalInterviewSubstage={removeLocalInterviewSubstage}
          disabled={disabled}
        />
      ))}
    </>
  );
};

const InterviewRound = ({
  entityId,
  interviewRound,
  allInterviewers,
  interviewNameOptions,
  removeLocalInterviewSubstage,
  disabled,
}: {
  entityId: string;
  interviewRound: MultipartSchedulingBaseInterviewSubstage;
  allInterviewers: Array<MultipartSchedulingClientInterviewer>;
  interviewNameOptions: string[];
  removeLocalInterviewSubstage: Function;
  disabled?: boolean;
}): React.ReactElement => {
  const dispatch = useDispatch();

  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const confirmedStart = interviewRound.confirmedStartTime ? moment(interviewRound.confirmedStartTime).unix() : null;
  const [start, setStart] = useState<number | null>(confirmedStart ?? null);
  const timezone = useSelector(selectTimezone);
  const [date, setDate] = useState<Moment | null>(
    interviewRound.confirmedStartTime ? moment(interviewRound.confirmedStartTime) : null
  );
  const [interviewRoundName, setInterviewRoundName] = useState<string>(interviewRound.name!);
  const [duration, setDuration] = useState<number>(interviewRound.durationInMinutes!);

  const [willAutoCancel, setWillAutoCancel] = useState<boolean>(!!confirmedStart && (confirmedStart != start || !date));

  const preferredInterviewers = interviewRound.possibleInterviewers;
  const interviewMap: { [id: string]: CalendarResource } = useMemo(
    () => allInterviewers.reduce((acc, curr) => ({ ...acc, [curr.id!]: curr }), {}),
    [allInterviewers]
  );

  const initialInterviewerOptions: Array<{ interviewerIds: Array<string>; label: string }> = useMemo(() => {
    const interviewers = interviewRound.actualInterviewers.length
      ? interviewRound.actualInterviewers
      : preferredInterviewers;

    const preferredInterviewerIds = new Set(preferredInterviewers.map(i => i.id));
    const interviewerOptions = [];
    for (const interviewer of interviewers) {
      if (!(interviewer.id! in interviewMap)) {
        showErrorToast(`Could not retrieve info for ${interviewer.email}. They might need to reauth.`);
      }
      interviewerOptions.push({
        interviewerIds: [interviewer.id!],
        label: preferredInterviewerIds.has(interviewer.id)
          ? buildInterviewSelectString(interviewer)
          : buildNonDefaultInterviewSelectString(interviewer),
      });
    }

    return interviewerOptions;
  }, [interviewRound, interviewMap, preferredInterviewers]);

  const [interviewerIds, setInterviewerIds] = useState<string[]>(
    initialInterviewerOptions.map(({ interviewerIds }) => interviewerIds[0]!)
  );

  const interviewerOptions = useMemo(() => {
    // build the options from already selected => preferred => the rest
    const options = [];
    for (const interviewer of preferredInterviewers) {
      if (interviewerIds.indexOf(interviewer.id!) === -1) {
        options.push({
          interviewerIds: [interviewer.id!],
          label: buildInterviewSelectString(interviewer),
        });
      }
    }

    const preferredInterviewerIds = preferredInterviewers.map(i => i.id!);
    for (const interviewer of allInterviewers) {
      if (interviewerIds.indexOf(interviewer.id!) === -1 && preferredInterviewerIds.indexOf(interviewer.id!) === -1) {
        options.push({
          interviewerIds: [interviewer.id!],
          label: buildNonDefaultInterviewSelectString(interviewer),
        });
      }
    }
    return options;
  }, [allInterviewers, interviewerIds, preferredInterviewers]);

  const interviewDurationOptions = [15, 30, 45, 60, 75, 90, 120];

  useEffect(() => {
    interviewerIds.forEach(id => dispatch(interviewSchedulerActions.addResource(interviewMap[id])));
  }, [dispatch, interviewMap, interviewerIds]);

  // Update date display upon timezone change because the day could change
  useEffect(() => {
    if (start) {
      setDate(moment.unix(start).tz(timezone));
    }
  }, [start, timezone]);

  // If the date changes in the date picker then we should upate `start` to be
  // on that day
  useEffect(() => {
    if (!date) {
      setStart(null);
      return;
    }

    setStart(start => {
      if (!start) {
        return null;
      }
      const currStart = moment.unix(start);

      // We 0 out the seconds and milliseconds so that this value can match the
      // values generated by the select options
      const nextStart = moment(date)
        .hour(currStart.hour())
        .minute(currStart.minute())
        .second(0)
        .millisecond(0);

      return nextStart.unix();
    });
  }, [date]);

  useEffect(() => {
    if (!interviewRoundName || !duration || interviewerIds.length === 0 || isDeleted) {
      dispatch(
        interviewSchedulerActions.removeInterviewRound({
          id: entityId,
          isCandidateMultipartInterviewSubstage: false,
        })
      );
    } else {
      dispatch(
        interviewSchedulerActions.addInterviewRound({
          interviewRound,
          id: entityId,
          start: start ? start * 1000 : null,
          interviewerIds: interviewerIds,
          newName: interviewRoundName,
          newDuration: duration,
        })
      );
    }
  }, [dispatch, entityId, interviewRound, interviewerIds, start, interviewRoundName, duration, isDeleted]);

  useEffect(() => {
    setWillAutoCancel(!!confirmedStart && (!date || confirmedStart != start));
  }, [confirmedStart, date, start]);

  const handleDeleteInterview = (): void => {
    setIsDeleted(true);
    dispatch(
      interviewSchedulerActions.removeInterviewRound({
        id: entityId,
        isCandidateMultipartInterviewSubstage: !!interviewRound.candidateMultipartInterviewSubstageId,
      })
    );
    removeLocalInterviewSubstage(entityId);
    dispatch(interviewSchedulerActions.decrementExpectedNumberOfStages());
  };

  const handleCancelChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      dispatch(interviewSchedulerActions.cancelInterviewRound({ id: entityId }));
    } else {
      dispatch(interviewSchedulerActions.keepInterviewRound({ id: entityId }));
    }
  };

  if (isDeleted) {
    return <></>;
  } else {
    return (
      <Box width="100%" marginY="32px" display="flex" alignItems="center" justifyContent="space-between">
        <Box width="3%">{interviewRound.order === undefined ? "Any" : interviewRound.order + 1}</Box>
        <Box width="10%">
          {/* @ts-ignore I don't know why this is having a type issue, but it does work */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              componentsProps={{
                // If a substage is submitted without a date/start, we will
                // create the `CandidateMultipartInterviewSubstage` entry
                // without scheduling anything, such that it can be scheduled
                // later.
                actionBar: {
                  actions: ["clear", "today"],
                },
              }}
              inputFormat="MM/DD/YY"
              value={date}
              onChange={(newValue): void => {
                setDate(newValue);
              }}
              renderInput={(params): React.ReactElement => <TextField {...params} label="Date" />}
              disabled={disabled}
              disablePast={true}
            />
          </LocalizationProvider>
        </Box>
        <Box width="10%">
          <FormControl fullWidth disabled={!date || disabled}>
            <InputLabel>Start Time</InputLabel>
            <Select
              label="Start Time"
              value={start ?? ""}
              onChange={(e): void => {
                setStart(e.target.value ? (e.target.value as number) : null);
              }}
            >
              {genStartTimeMenuItems(date)}
            </Select>
          </FormControl>
        </Box>
        <Box width="23%">
          <Autocomplete
            freeSolo={true}
            defaultValue={interviewRound.name}
            options={interviewNameOptions}
            renderInput={(params): React.ReactNode => <TextField {...params} label="Interview name" />}
            onInputChange={(_, value): void => {
              setInterviewRoundName(value!);
            }}
            disabled={disabled || !!interviewRound.multipartInterviewSubstageId || interviewRound.name === "Debrief"}
          />
        </Box>
        <Box width="10%">
          <Autocomplete
            defaultValue={interviewRound.durationInMinutes}
            options={interviewDurationOptions}
            getOptionLabel={(option: any): string => `${option}`}
            renderInput={(params): React.ReactNode => <TextField {...params} label="Duration (minutes)" />}
            onChange={(_, value): void => {
              setDuration(value!);
            }}
            disabled={disabled}
          />
        </Box>
        <Box width="25%">
          <Autocomplete
            multiple
            filterSelectedOptions
            disableCloseOnSelect
            defaultValue={initialInterviewerOptions as any}
            options={interviewerOptions as any}
            renderInput={(params): React.ReactNode => (
              <TextField
                {...params}
                label="Interviewer(s)"
                error={interviewRound.requireAllInterviewers && interviewerIds.length < preferredInterviewers.length}
                helperText={
                  interviewRound.requireAllInterviewers && interviewerIds.length < preferredInterviewers.length
                    ? "All interviewers are preferred"
                    : undefined
                }
              />
            )}
            onChange={(_, value): void => {
              const interviewerOptions = (value as unknown) as InterviewerOption[];
              setInterviewerIds(interviewerOptions.map(i => i.interviewerIds).flat());
            }}
            disabled={disabled}
          />
        </Box>
        <Box width="5%">
          <IconButton size="large" onClick={handleDeleteInterview} disabled={disabled}>
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box width="5%">
          <Checkbox
            onChange={handleCancelChange}
            disabled={disabled || !confirmedStart || willAutoCancel}
            indeterminate={willAutoCancel}
            sx={{
              [`&.${checkboxClasses.indeterminate}, &.${checkboxClasses.checked}`]: {
                color: colors.critical.base,
              },
            }}
          />
        </Box>
      </Box>
    );
  }
};

// This will create increment number of menu items starting at inital time
export const genStartTimeMenuItems = (date: Moment | null, timezone?: string): ReactElement[] => {
  const menuItems = [];

  const fallbackDate = timezone ? moment().tz(timezone, true) : moment();
  const dateToUse = date && date.isValid() ? date : fallbackDate;

  if (dateToUse) {
    const increment = 15; // in minutes
    const iterations = 95;
    const initialTime = dateToUse.startOf("day");

    for (let i = 0; i < iterations; i++) {
      const time = initialTime.add(increment, "m");
      menuItems.push(<MenuItem value={time.unix()}>{time.format("h:mma")}</MenuItem>);
    }
  }

  return menuItems;
};

const buildInterviewSelectString = (interviewer: MultipartSchedulingClientInterviewer): string =>
  `${interviewer.fullName} <${interviewer.email}>`;

const buildNonDefaultInterviewSelectString = (interviewer: MultipartSchedulingClientInterviewer): string =>
  `⚠️ ${interviewer.fullName} <${interviewer.email}>`;
