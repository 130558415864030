import { Grid } from "@mui/material";
import React from "react";
import { ReactSVG } from "react-svg";

import NewJobIcon from "assets/icons/new-job.svg";
import { Spacer } from "components/Spacer";
import { TextWithNewLinesAndLinks } from "components/TextHelpers";

export default ({ failureMessage }: { failureMessage: string }): React.ReactElement => (
  <Grid container>
    <Grid container item xs={12} direction="row" alignItems="center">
      <ReactSVG src={NewJobIcon} />
    </Grid>
    <Grid container item xs={12} direction="row" alignItems="center">
      <Spacer height="16px" />
    </Grid>
    <Grid container item xs={12} direction="row" alignItems="center">
      <TextWithNewLinesAndLinks text={failureMessage} />
    </Grid>
  </Grid>
);
