import Slider from "@mui/material/Slider";
import React, { useState } from "react";

import SliderStyles, { StyledFormLabel } from "components/inputs/RangeSlider/styles";
import { RangeSliderProps } from "components/inputs/RangeSlider/types";
import ValueLabel, { ValueLabelProps } from "components/library/YOESlider/ValueLabel";

const RangeSlider = ({
  max = 100,
  min = 1,
  step = 1,
  initialValue = [20, 40],
  marks,
  onChange,
  minDistance = 3,
  label,
}: RangeSliderProps): React.ReactElement => {
  const [value, setValue] = useState<number[]>([initialValue[0], initialValue[1]]);

  return (
    <>
      {label && <StyledFormLabel>{label}</StyledFormLabel>}
      <SliderStyles>
        <Slider
          marks={marks}
          step={step}
          valueLabelDisplay="on"
          components={{
            // eslint-disable-next-line react/no-unstable-nested-components
            ValueLabel: (props: ValueLabelProps): React.ReactElement => (
              <ValueLabel isOpen={true} value={props.value} largeText={true}>
                {props.children}
              </ValueLabel>
            ),
          }}
          max={max}
          min={min}
          value={value}
          onChange={(event: Event, value: number | number[]): void => {
            if (!Array.isArray(value)) {
              return;
            }

            const shouldUpdate = !minDistance || (minDistance && value[1] - value[0] >= minDistance);
            if (shouldUpdate) {
              setValue([value[0], value[1]]);
              if (onChange) {
                onChange(event, value);
              }
            }
          }}
        />
      </SliderStyles>
    </>
  );
};

export default RangeSlider;
