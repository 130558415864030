import { Tooltip, MenuItem } from "@mui/material";
import { Select } from "mui-rff";
import React, { useMemo } from "react";
import { useField } from "react-final-form";
import styled from "styled-components";

import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { ClientOnboardingAtsTypeEnum } from "services/openapi";
import { colors } from "styles/theme";
import { shouldHideAtsFields } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/helpers";
import {
  useGetAtsStages,
  useGetDisabledAtsStages,
  useGetInterviewSchedulingScenario,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/hooks";
import { InterviewSchedulingScenario } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/types";
import {
  getAtsFieldDisplayText,
  getAtsStageTooltip,
} from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/utils";

interface StyledSelectProps {
  disabled: boolean;
}

const StyledSelect = styled(Select)<StyledSelectProps>`
  min-width: 212px;
  background-color: ${(props): string => (props.disabled ? `${colors.grayscale.gray100}` : "white")};
`;

const StyledPlaceholder = styled.div`
  // This color is hardcoded to match the placeholder color of the interviewer field.
  color: #adadad;
`;

const Placeholder = ({ children }: any): React.ReactElement => {
  return (
    <div
      style={{
        color: colors.grayscale.gray600,
      }}
    >
      {children}
    </div>
  );
};

interface Props {
  name: string;
}
export const AtsField = (props: Props): React.ReactElement => {
  const { name } = props;
  const field = useField(name);

  const atsStages = useGetAtsStages();
  const disableMenuItems = useGetDisabledAtsStages();
  const interviewSchedulingScenario = useGetInterviewSchedulingScenario();
  const { data: client } = useGetClientOnboardingQuery();
  const disableSelect = shouldHideAtsFields((interviewSchedulingScenario as unknown) as InterviewSchedulingScenario);

  const displayText = getAtsFieldDisplayText(field.input.value, atsStages);

  const atsStageOptions = useMemo(() => {
    if (!atsStages) {
      return [];
    }
    const preparedAtsStages = atsStages
      .filter(stage => stage.atsStageId)
      .map((stage, index) => {
        const disableStage = disableMenuItems?.some(disableStage => disableStage.atsStage === stage.atsStage);
        return (
          <MenuItem
            sx={{
              "&.MuiButtonBase-root": {
                display: "flex",
                justifyContent: "start",
                padding: "8px",
              },
            }}
            key={index}
            value={stage.atsStageId!}
            disabled={disableStage}
          >
            {stage.atsStage}
          </MenuItem>
        );
      });
    if (client?.atsType !== ClientOnboardingAtsTypeEnum.Ashby) {
      preparedAtsStages.concat([<MenuItem key={-1}>None</MenuItem>]);
    }
    return preparedAtsStages;
  }, [atsStages, client?.atsType, disableMenuItems]);

  const mainComponent = (
    <span>
      <StyledSelect
        name={name}
        variant="outlined"
        autoWidth
        disabled={disableSelect}
        displayEmpty
        renderValue={
          disableSelect
            ? (): React.ReactElement => <Placeholder>Map to ATS Stage</Placeholder>
            : displayText
            ? (): string => displayText
            : (): React.ReactNode => <StyledPlaceholder>Map to ATS Stage</StyledPlaceholder>
        }
      >
        {atsStageOptions && atsStageOptions.length > 0 ? (
          atsStageOptions
        ) : (
          <MenuItem key={0} disabled>
            Error loading ATS Stages
          </MenuItem>
        )}
      </StyledSelect>
    </span>
  );
  return disableSelect ? (
    <Tooltip title={getAtsStageTooltip(interviewSchedulingScenario)}>{mainComponent}</Tooltip>
  ) : (
    mainComponent
  );
};
