import React, { createContext, useEffect, useState } from "react";

import { RecalibrateModal } from "views/candidates/CandidateTable/components/RecalibrateModal";

export interface CandidatesRemovedFromQueueState {
  numCandidatesRemoved: number;
  incrementNumCandidatesRemoved: () => void;
}

export const CandidatesRemovedFromQueueContext = createContext<CandidatesRemovedFromQueueState | undefined>(undefined);

export const CandidatesRemovedFromQueueProvider: React.FC = ({ children }) => {
  const [numCandidatesRemoved, setNumCandidatesRemoved] = useState<number>(0);
  const [recalibrateModalOpen, setRecalibrateModalOpen] = useState<boolean>(false);

  const incrementNumCandidatesRemoved = (): void => {
    setNumCandidatesRemoved(numCandidatesRemoved + 1);
  };

  const handleSetNumCandidatesRemoved = (num: number): void => {
    setNumCandidatesRemoved(num);
  };

  useEffect(() => {
    if (numCandidatesRemoved >= 5) {
      setRecalibrateModalOpen(true);
    }
  }, [numCandidatesRemoved]);

  return (
    <>
      <CandidatesRemovedFromQueueContext.Provider
        value={{
          numCandidatesRemoved,
          incrementNumCandidatesRemoved,
        }}
      >
        {children}
      </CandidatesRemovedFromQueueContext.Provider>
      <RecalibrateModal
        isOpen={recalibrateModalOpen}
        close={(): void => {
          handleSetNumCandidatesRemoved(0);
          setRecalibrateModalOpen(false);
        }}
      />
    </>
  );
};
