import { Divider, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { Body } from "components/library/typography";
import { PipelineCandidate } from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import CandidateCard from "views/candidates/CandidatesReview/components/CandidateCard";
import { ACTION_FILTER_HEIGHT, ReviewTypeSectionLabels } from "views/candidates/CandidatesReview/constants";
import { ReviewFilterEnum } from "views/candidates/types";

const ReviewTypeSection = ({
  reviewType,
  candidates,
}: {
  reviewType: ReviewFilterEnum;
  candidates?: PipelineCandidate[];
}): React.ReactElement => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(screenSizesNumbers.tabletL));

  if (!candidates) {
    return <></>;
  }

  return (
    <Stack mb={2} width={smallScreen ? "100%" : undefined} padding="0 ">
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        width="100%"
        sx={{
          overflow: "hidden",
          position: "sticky",
          top: ACTION_FILTER_HEIGHT,
          background: colors.grayscale.gray025,
          padding: "12px 0px",
          zIndex: 10,
        }}
      >
        <Body weight="600" style={{ whiteSpace: "nowrap" }}>
          {ReviewTypeSectionLabels[reviewType]}
        </Body>

        <Divider sx={{ flexBasis: "100%" }} />
      </Stack>

      <Stack spacing={2} justifyContent="flex-start">
        {candidates.map(candidate => (
          <CandidateCard key={candidate.id} candidate={candidate} reviewType={reviewType} />
        ))}
      </Stack>
    </Stack>
  );
};

export default ReviewTypeSection;
