/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlatformSchedulingEmailAlias
 */
export interface PlatformSchedulingEmailAlias {
    /**
     * 
     * @type {string}
     * @memberof PlatformSchedulingEmailAlias
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformSchedulingEmailAlias
     */
    readonly gmailCredentialAuthState?: PlatformSchedulingEmailAliasGmailCredentialAuthStateEnum;
    /**
     * 
     * @type {number}
     * @memberof PlatformSchedulingEmailAlias
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof PlatformSchedulingEmailAlias
     */
    readonly email?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum PlatformSchedulingEmailAliasGmailCredentialAuthStateEnum {
    IsShellAccount = 'IS_SHELL_ACCOUNT',
    Valid = 'VALID',
    AuthInvalid = 'AUTH_INVALID',
    NeverAuthed = 'NEVER_AUTHED',
    InvalidAuthCredType = 'INVALID_AUTH_CRED_TYPE'
}

export function PlatformSchedulingEmailAliasFromJSON(json: any): PlatformSchedulingEmailAlias {
    return PlatformSchedulingEmailAliasFromJSONTyped(json, false);
}

export function PlatformSchedulingEmailAliasFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformSchedulingEmailAlias {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'gmailCredentialAuthState': !exists(json, 'gmail_credential_auth_state') ? undefined : json['gmail_credential_auth_state'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function PlatformSchedulingEmailAliasToJSON(value?: PlatformSchedulingEmailAlias | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
    };
}


