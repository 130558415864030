/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    candidateId?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    originalEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    newEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    companyEmail: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject
     */
    conversationId: string;
}

export function InlineObjectFromJSON(json: any): InlineObject {
    return InlineObjectFromJSONTyped(json, false);
}

export function InlineObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': !exists(json, 'candidate_id') ? undefined : json['candidate_id'],
        'originalEmail': json['original_email'],
        'newEmail': json['new_email'],
        'companyEmail': json['company_email'],
        'conversationId': json['conversation_id'],
    };
}

export function InlineObjectToJSON(value?: InlineObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'original_email': value.originalEmail,
        'new_email': value.newEmail,
        'company_email': value.companyEmail,
        'conversation_id': value.conversationId,
    };
}


