import { ReviewReferralsState } from "views/Referrals/ReviewReferrals/types";

export const reviewReferralsKey = "reviewReferralsKey";

export const referrerIdQueryParam = "referrerId";

export const inviteEmployeesInstructionsUrl =
  "https://help.dover.com/en/articles/6229699-inviting-your-team-to-upload-their-connections";
export const uploadConnectionsFAQs = "https://help.dover.com/en/articles/6229749-dover-referrals-faqs";
export const uploadConnectionsPagePath = "/referrals/upload-connections";
export const needsReviewPagePath = "/referrals/review/needs-review";
export const helpDocsUrl = "https://help.dover.com/en/articles/6229677-dover-referrals";
export const threeKeysToRecruitingFromYourNetworkUrl =
  "https://blog.dover.io/3-keys-to-recruiting-from-your-network-without-feeling-awkward-about-it/";
export const exampleReferralTemplate =
  "https://help.dover.com/en/articles/6229716-templates-for-reaching-out-to-your-connections";

export const DEFAULT_NUM_REFERRALS_PER_PAGE = 25;

export const initialState: ReviewReferralsState = {
  jobsInitialized: false,
  jobs: {},
  selectedJobId: undefined,
  loadingJobReferrals: false,
  loadingJobReferralsError: null,
  jobReferrals: {},
  totalNumJobReferralsMap: {},
  referralStatsMap: {},
  referrersInitialized: false,
  loadingReferrersError: null,
  referrers: {},
  selectedReferrerId: undefined,
  currentPageIndex: 0,
  numItemsPerPage: DEFAULT_NUM_REFERRALS_PER_PAGE,
  client: undefined,
};
