import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";

import { Subtitle2 } from "components/library/typography";
import { colors } from "styles/theme";
import {
  FilterToggle,
  FilterTogglerContext,
  FormLoadStateContext,
} from "views/sourcing/Search/context/FilterToggleContext";
import { SearchV3FormSchemaType, TitleContentProps } from "views/sourcing/Search/types";

interface FilterAccordionProps {
  title?: string | React.ReactNode;
  errorTextKey?: keyof SearchV3FormSchemaType;
  TitleContent?: React.ComponentType<TitleContentProps>;
  expandedContent: React.ReactNode;
  expandedCallback?: (expanded: boolean) => void;
  expandedOverride?: boolean;
}

const FilterAccordion = React.memo(
  ({
    title,
    errorTextKey,
    TitleContent,
    expandedContent,
    expandedCallback,
    expandedOverride = false,
  }: FilterAccordionProps): React.ReactElement => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const onFilterToggled = React.useContext<FilterToggle | undefined>(FilterTogglerContext)?.onFilterToggled;
    const {
      formState: { errors },
    } = useFormContext<SearchV3FormSchemaType>();

    const initialFormValuesLoaded = React.useContext(FormLoadStateContext)?.loaded;

    const onSetExpanded = React.useCallback(() => {
      setExpanded(!expanded);
      expandedCallback?.(!expanded);
      onFilterToggled?.();
    }, [expanded, expandedCallback, onFilterToggled]);

    React.useEffect(() => {
      if (expandedOverride) {
        setExpanded(expandedOverride);
      }
    }, [expandedOverride]);

    return (
      <Accordion
        expanded={expanded}
        onChange={onSetExpanded}
        disableGutters={true}
        square={true}
        sx={{
          boxShadow: "none",
          "&.Mui-expanded:before": {
            opacity: 1,
          },
        }}
      >
        <AccordionSummary
          sx={{
            padding: "12px 16px",
            alignItems: "start",
            "& .MuiAccordionSummary-content": {
              margin: "0px",
            },
          }}
          expandIcon={expanded ? <KeyboardArrowUpIcon /> : <ChevronRightIcon />}
        >
          <Stack paddingTop={"3px"} spacing={1}>
            {typeof title === "string" ? (
              <Subtitle2
                color={
                  errorTextKey && errors[errorTextKey] && initialFormValuesLoaded ? colors.critical.base : undefined
                }
              >
                {title}
              </Subtitle2>
            ) : (
              title
            )}
            {!!TitleContent && <TitleContent expanded={expanded} />}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: "0px !important" }}>{expandedContent}</AccordionDetails>
      </Accordion>
    );
  }
);

export default FilterAccordion;
