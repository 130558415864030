import Stack from "@mui/material/Stack";
import { find } from "lodash";
import React, { useEffect } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { PageTab, PageTabContainer } from "components/library/TabBar/styles";
import { TabBarProps } from "components/library/TabBar/types";

export const TabBar: React.FC<TabBarProps> = ({
  children,
  tabConfigs,
  selectedTabQueryParamKey = "tab",
  defaultSelectedTabIndex = 0,
  $fontSize,
}) => {
  const [selectedTab, setSelectedTab] = useQueryParam(
    selectedTabQueryParamKey,
    withDefault(StringParam, `${defaultSelectedTabIndex}`)
  );

  useEffect(() => {
    // Switch to the default tab if any one of the following is true:
    // 1) No tab was specified in the url
    // 2) The value in the url was specified, but invalid
    // 3) The value in the url was specified and valid, but that tab is disabled
    const selectedTabConfig = find(tabConfigs, tabConfig => tabConfig.value === selectedTab);
    const defaultTabConfig = tabConfigs[defaultSelectedTabIndex];

    if (defaultTabConfig && (!selectedTabConfig || selectedTabConfig.disabled)) {
      setSelectedTab(defaultTabConfig.value);
    }
  }, [selectedTab, setSelectedTab, tabConfigs, defaultSelectedTabIndex]);

  return (
    <PageTabContainer direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        {tabConfigs.map((tabConfig, idx) => (
          <PageTab
            key={idx}
            $isActive={tabConfig.value === selectedTab}
            $disabled={tabConfig.disabled}
            $fontSize={$fontSize}
            onClick={(): void => {
              if (!tabConfig.disabled) {
                setSelectedTab(tabConfig.value);
              }
            }}
          >
            {tabConfig.label}
          </PageTab>
        ))}
      </Stack>
      {children}
    </PageTabContainer>
  );
};
