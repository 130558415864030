/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlimProUserField,
    SlimProUserFieldFromJSON,
    SlimProUserFieldFromJSONTyped,
    SlimProUserFieldToJSON,
    SubmittedFeedbackField,
    SubmittedFeedbackFieldFromJSON,
    SubmittedFeedbackFieldFromJSONTyped,
    SubmittedFeedbackFieldToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewPanelField
 */
export interface InterviewPanelField {
    /**
     * 
     * @type {string}
     * @memberof InterviewPanelField
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewPanelField
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof InterviewPanelField
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InterviewPanelField
     */
    endTime?: Date;
    /**
     * 
     * @type {Array<SlimProUserField>}
     * @memberof InterviewPanelField
     */
    interviewers?: Array<SlimProUserField>;
    /**
     * 
     * @type {Array<SubmittedFeedbackField>}
     * @memberof InterviewPanelField
     */
    submittedFeedbackForms?: Array<SubmittedFeedbackField>;
}

export function InterviewPanelFieldFromJSON(json: any): InterviewPanelField {
    return InterviewPanelFieldFromJSONTyped(json, false);
}

export function InterviewPanelFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewPanelField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'startTime': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'endTime': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'interviewers': !exists(json, 'interviewers') ? undefined : ((json['interviewers'] as Array<any>).map(SlimProUserFieldFromJSON)),
        'submittedFeedbackForms': !exists(json, 'submitted_feedback_forms') ? undefined : ((json['submitted_feedback_forms'] as Array<any>).map(SubmittedFeedbackFieldFromJSON)),
    };
}

export function InterviewPanelFieldToJSON(value?: InterviewPanelField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'start_time': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'end_time': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'interviewers': value.interviewers === undefined ? undefined : ((value.interviewers as Array<any>).map(SlimProUserFieldToJSON)),
        'submitted_feedback_forms': value.submittedFeedbackForms === undefined ? undefined : ((value.submittedFeedbackForms as Array<any>).map(SubmittedFeedbackFieldToJSON)),
    };
}


