import { Icon } from "@doverhq/dover-ui";
import React, { FC } from "react";

import { ReactComponent as FileIcon } from "assets/icons/resume-icon-grey.svg";
import { Tooltip } from "components/library/Tooltip";

interface ResumeDownloadProps {
  resumePath?: string;
}

export const ResumeDownload: FC<ResumeDownloadProps> = ({ resumePath }) => {
  if (!resumePath) return null;

  const handleDownload = (): void => {
    // Open in new tab which will trigger download
    window.open(resumePath, "_blank");
  };

  return (
    <Tooltip title="Download Resume">
      <span onClick={handleDownload} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
        <Icon Icon={FileIcon} size={16} />
      </span>
    </Tooltip>
  );
};
