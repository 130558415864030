import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";

import { useGetCompanySetupStepsQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetJobFeaturesQuery } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import { CompanySetupStep, CompanySetupStepStateEnum, JobFeatureFeatureNameEnum } from "services/openapi";

export function useGetIncompleteCompanySetupStepsForFeature(
  featureName: JobFeatureFeatureNameEnum,
  jobId: string | undefined
): Array<CompanySetupStep> | undefined {
  const { data: jobFeatures } = useGetJobFeaturesQuery(jobId ? { jobId } : skipToken);
  const { data: companySetupSteps } = useGetCompanySetupStepsQuery();

  return useMemo(() => {
    if (!jobFeatures || !companySetupSteps) {
      return undefined;
    }

    const feature = jobFeatures.features.find(jobFeature => jobFeature.featureName === featureName);
    if (!feature) {
      return undefined;
    }
    const dependentCompanySetupSteps = feature.dependentCompanySetupSteps;
    return (companySetupSteps?.setupSteps ?? []).filter(
      companySetupStep =>
        dependentCompanySetupSteps.includes(companySetupStep.stepName) &&
        [CompanySetupStepStateEnum.StartedPendingClientAction, CompanySetupStepStateEnum.Pending].includes(
          companySetupStep.state
        )
    );
  }, [jobFeatures, featureName, companySetupSteps]);
}
