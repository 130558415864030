import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material";
import React from "react";

import { Overline } from "components/library/typography";
import { colors } from "styles/theme";

interface AdvancedAccordionProps {
  title: string;
  expandedContent: React.ReactNode;
}

const AdvancedAccordion = React.memo(
  ({ title, expandedContent }: AdvancedAccordionProps): React.ReactElement => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    return (
      <Accordion
        expanded={expanded}
        onChange={(): void => setExpanded(!expanded)}
        sx={{
          boxShadow: "none",
          "::before": {
            display: "none",
          },
        }}
        disableGutters={true}
        square={true}
      >
        <AccordionSummary
          sx={{
            alignItems: "start",
            justifyContent: "start",
            padding: "0px !important",
            minHeight: "24px",
            "& .MuiAccordionSummary-content": {
              margin: "0px",
              flexGrow: 0,
            },
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
            },
          }}
          expandIcon={expanded ? <KeyboardArrowUpIcon /> : <ChevronRightIcon />}
        >
          <Stack minHeight="20px" direction="row" alignItems="center" spacing={1}>
            <Overline color={colors.linkLight}>{title}</Overline>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0px" }}>{expandedContent}</AccordionDetails>
      </Accordion>
    );
  }
);

export default AdvancedAccordion;
