/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveFromOutboxResponse
 */
export interface RemoveFromOutboxResponse {
    /**
     * 
     * @type {number}
     * @memberof RemoveFromOutboxResponse
     */
    numSuccess: number;
    /**
     * 
     * @type {number}
     * @memberof RemoveFromOutboxResponse
     */
    numError: number;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof RemoveFromOutboxResponse
     */
    candidateData: Array<{ [key: string]: string; }>;
}

export function RemoveFromOutboxResponseFromJSON(json: any): RemoveFromOutboxResponse {
    return RemoveFromOutboxResponseFromJSONTyped(json, false);
}

export function RemoveFromOutboxResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveFromOutboxResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numSuccess': json['num_success'],
        'numError': json['num_error'],
        'candidateData': json['candidate_data'],
    };
}

export function RemoveFromOutboxResponseToJSON(value?: RemoveFromOutboxResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'num_success': value.numSuccess,
        'num_error': value.numError,
        'candidate_data': value.candidateData,
    };
}


