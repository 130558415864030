/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckCanCreateManualContextForJobResponse
 */
export interface CheckCanCreateManualContextForJobResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckCanCreateManualContextForJobResponse
     */
    valid: boolean;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobResponse
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobResponse
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobResponse
     */
    candidateId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckCanCreateManualContextForJobResponse
     */
    invalidGmailAliasEmails: Array<string> | null;
}

export function CheckCanCreateManualContextForJobResponseFromJSON(json: any): CheckCanCreateManualContextForJobResponse {
    return CheckCanCreateManualContextForJobResponseFromJSONTyped(json, false);
}

export function CheckCanCreateManualContextForJobResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckCanCreateManualContextForJobResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': json['valid'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'url': json['url'],
        'candidateId': !exists(json, 'candidate_id') ? undefined : json['candidate_id'],
        'invalidGmailAliasEmails': json['invalid_gmail_alias_emails'],
    };
}

export function CheckCanCreateManualContextForJobResponseToJSON(value?: CheckCanCreateManualContextForJobResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': value.valid,
        'error': value.error,
        'url': value.url,
        'candidate_id': value.candidateId,
        'invalid_gmail_alias_emails': value.invalidGmailAliasEmails,
    };
}


