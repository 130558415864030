import { Tooltip } from "@mui/material";
import clipboardCopy from "clipboard-copy";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import KeyIcon from "assets/icons/key.svg";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";

interface Props {
  value: string;
}
export const Key = ({ value }: Props): React.ReactElement => {
  const [clicked, setClicked] = useState(false);
  return (
    <Tooltip
      title={clicked ? "Copied!" : "Copy to clipboard"}
      placement="top"
      onOpen={(): void => setClicked(false)}
      onClick={(): void => {
        clipboardCopy(value);
        setClicked(true);
      }}
    >
      <HoverBackground>
        <KeyWrapper>
          <DetailIconWrapper>
            <ReactSVG src={KeyIcon} />
          </DetailIconWrapper>
          <Spacer width={16} />
          <BodySmall>{value}</BodySmall>
          <br />
        </KeyWrapper>
      </HoverBackground>
    </Tooltip>
  );
};

const HoverBackground = styled.div`
  &:hover {
    background-color: ${colors.grayscale.gray300};
    cursor: pointer;
  }
`;

const KeyWrapper = styled.div`
  align-items: baseline;
  display: flex;
  padding-left: 4px;
`;

const DetailIconWrapper = styled.div`
  width: 20px;
`;
