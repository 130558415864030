import { Stack } from "@mui/material";
import React, { useEffect } from "react";

import { useStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { doesStageRequireInterviewer } from "components/dover/top-level-modal-manager/hooks/useValidate";
import { useNextInterviewer } from "components/NextInterviewer/hooks";
import { NextInterviewer } from "components/NextInterviewer/NextInterviewer";
import { useInvalidInterviewerTooltipText } from "components/NextInterviewer/ValidateNextInterviewer";
import { OneClickActionButtons } from "components/OneClickActionButtons";
import { ScheduleRejectButtons } from "components/ScheduleRejectButtons";
import { useDisableCandidateActionsTooltipText } from "hooks/useDisableCandidateActionsTooltipText";
import { CandidateBio, CandidateBioSchedulingOwnershipEnum, NextActionSchedulingStateEnum } from "services/openapi";
import { IllHandleButton } from "views/CandidateDetail/components/next-action/IllHandleButton";

export enum CandidateActionButtonsVariant {
  Default = "DEFAULT",
  Light = "LIGHT",
}

export const CandidateActionButtons = ({
  candidateBio,
  inPipeline = false,
  condense,
  submitHook, // Allows you to add custom behaviour to run before submit logic is run
  preventDefaultEvent, // Bandage for submitting from IRR form
}: {
  candidateBio: CandidateBio;
  applicationId?: string;
  interviewRubricResponseId?: string;
  inPipeline?: boolean;
  variant?: CandidateActionButtonsVariant;
  showValidationTooltip?: boolean;
  disabled?: boolean;
  useParams?: boolean;
  horizontalLayout?: boolean;
  condense?: boolean;
  submitHook?: () => void;
  hideToast?: boolean;
  skipTagInvalidation?: boolean;
  preventDefaultEvent?: boolean;
}): React.ReactElement => {
  // State and query params
  const [selectedStage] = React.useState(candidateBio.nextAction?.nextHiringPipelineStage);
  const jobId = candidateBio.job;

  const [selectedScheduler, setSelectedScheduler] = React.useState<CandidateBioSchedulingOwnershipEnum | undefined>(
    undefined
  );

  useEffect(() => {
    // Once candidateBio is not undefined, set the selected scheduler
    if (candidateBio !== undefined) {
      setSelectedScheduler(candidateBio.schedulingOwnership);
    }
  }, [candidateBio]);

  // Queries and hooks
  const { stage, isTakeHome } = useStage({ candidateId: candidateBio?.id });
  const stageRequiresInterviewer = doesStageRequireInterviewer({
    schedulingOwnership: candidateBio.schedulingOwnership,
    desiredHiringPipelineStage: stage,
    isTakeHome,
  });

  const { nextInterviewerValid } = useNextInterviewer(candidateBio, selectedScheduler);

  // Internal variables
  const callCompleted = candidateBio?.nextAction?.schedulingState === NextActionSchedulingStateEnum.CallCompleted;
  const hasDoverInterviewer = candidateBio?.nextAction?.currentInterview?.hasDoverInterviewer;
  const hasNotesSubmitted = hasDoverInterviewer && candidateBio?.nextAction?.currentInterview?.hasCompletedNotes;

  const customerSelectedAsScheduler =
    candidateBio.schedulingOwnership === CandidateBioSchedulingOwnershipEnum.CustomerHandlesScheduling;

  const invalidInterviewerTooltip = useInvalidInterviewerTooltipText({
    candidateId: candidateBio.id,
  });
  const invalidCandidateActionPermsTooltip = useDisableCandidateActionsTooltipText(jobId);
  const invalidCandidateActionPerms = !!invalidCandidateActionPermsTooltip;

  const approveDisabled = invalidCandidateActionPerms || !nextInterviewerValid;
  const approveDisabledTooltip = invalidCandidateActionPermsTooltip || invalidInterviewerTooltip;
  const rejectDisabled = invalidCandidateActionPerms;

  // Only show new buttons for ATS only mode
  if (customerSelectedAsScheduler) {
    return (
      <ScheduleRejectButtons
        candidateId={candidateBio.id}
        disabled={invalidCandidateActionPerms}
        disabledTooltip={invalidCandidateActionPermsTooltip}
      />
    );
  } else {
    if (callCompleted && hasDoverInterviewer && !hasNotesSubmitted) {
      return <></>;
    }

    return (
      <Stack display="flex" direction="row" alignItems="center" justifyContent="center" spacing={1}>
        {stageRequiresInterviewer && (
          <Stack minWidth="140px" maxWidth="140px">
            <NextInterviewer candidateId={candidateBio.id} desiredHiringPipelineStage={selectedStage} />
          </Stack>
        )}
        <OneClickActionButtons
          hideLabels={condense || inPipeline}
          bio={candidateBio}
          disableApprove={approveDisabled}
          disabledApproveTooltip={approveDisabledTooltip}
          disableReject={rejectDisabled}
          disableRejectTooltip={invalidCandidateActionPermsTooltip}
          submitHook={submitHook}
          preventDefaultEvent={preventDefaultEvent}
        />
        {!inPipeline && <IllHandleButton bio={candidateBio} />}
      </Stack>
    );
  }
};
