import { Progress } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useMemo, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";

import JobFeatureToggleHandler from "components/dover/feature-toggle-handler/JobFeatureToggleHandler";
import { Button, ButtonVariant } from "components/library/Button";
import { StageDrawerActions } from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { usePartialUpdateHiringStageEmailTemplateMutation } from "services/doverapi/endpoints/emailTemplates";
import {
  usePartialUpdateInterviewStageMutation,
  useSwapInterviewStageTypeMutation,
} from "services/doverapi/endpoints/hiringPipelineStage";
import { useGetJobSetupQuery, useUpdateJobSetupMutation } from "services/doverapi/endpoints/job";
import { useGetIsFeatureEnabled } from "services/doverapi/endpoints/jobFeatureSettings/customHooks";
import {
  BaseInterviewSubstage,
  HiringPipelineStageStateEnum,
  HiringStageEmailTemplate,
  HiringStageEmailTemplateStateEnum,
  JobFeatureFeatureNameEnum,
} from "services/openapi";
import { InterviewPlanStageType } from "services/openapi/models/InterviewPlanStageType";
import { customConvertToHTML } from "utils/draftJS";
import { isInterviewStage, isStage } from "utils/isStage";
import { toastOptions } from "utils/showToast";
import { EmailTemplateEdit } from "views/job/JobSetup/components/types";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import { useUpdateHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useUpdateHps";
import { DrawerFormField } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/DrawerFormField";
import { useEditableHiringStageEmailTemplates } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/hooks";
import { fieldsToFormLabel } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/constants";
import { getStageFormFields } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/helpers";
import { useGetInterviewSchedulingScenario } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/hooks";
import { setIncludesTakeHomeStage } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/reducer";

export const EditStageForm = ({
  stageData,
  onClose,
  clientAtsSetupComplete,
}: {
  stageData: StageDataWrapper;
  onClose: (skipCheckingUnsavedChanges?: boolean) => void;
  clientAtsSetupComplete: boolean;
}): React.ReactElement => {
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<string | null>(null);

  const jobId = useJobIdFromUrl();

  const form = useForm();
  const formState = useFormState();

  const interviewSchedulingScenario = useGetInterviewSchedulingScenario();

  const stageIsInterview = isInterviewStage(stageData.stage);

  const dispatch = useDispatch();

  // RTK Mutations
  const [partialUpdateHiringStageEmailTemplate] = usePartialUpdateHiringStageEmailTemplateMutation();
  const [updateJobSetup] = useUpdateJobSetupMutation();
  const [partialUpdateInterviewStage] = usePartialUpdateInterviewStageMutation();
  const updateHps = useUpdateHps();
  const [swapInterviewType] = useSwapInterviewStageTypeMutation();

  const { data: jobSetup } = useGetJobSetupQuery(jobId ?? skipToken);

  const pipelineStageEdits = formState.values?.pipelineStageEdits[`stage-${stageData.stage.id}`];
  const interviewType = pipelineStageEdits?.interviewType;
  const isMultiPart = pipelineStageEdits?.interviewType === InterviewPlanStageType.MULTIPART;
  const isTakeHome = pipelineStageEdits?.interviewType === InterviewPlanStageType.TAKE_HOME;

  // Hooks
  const [templates, emailTemplateEditsById] = useEditableHiringStageEmailTemplates(
    jobId,
    stageData,
    selectedEmailTemplateId,
    setSelectedEmailTemplateId,
    isTakeHome,
    isMultiPart,
    interviewType
  );

  const e2eSchedulingEnabled = !!useGetIsFeatureEnabled({
    jobId,
    featureName: JobFeatureFeatureNameEnum.E2EScheduling,
  });

  const updateEmailTemplate = useCallback(
    (emailTemplate: EmailTemplateEdit, currentEmailTemplate: HiringStageEmailTemplate) => {
      const updateEmailTemplateAsync = async (
        emailTemplate: EmailTemplateEdit,
        currentEmailTemplate: HiringStageEmailTemplate
      ): Promise<void> => {
        if (!emailTemplate.id) {
          return;
        }

        await partialUpdateHiringStageEmailTemplate({
          hiringStageEmailTemplateId: emailTemplate.id,
          updatedHiringStageEmailTemplate: {
            ...currentEmailTemplate,
            subjectTemplate: customConvertToHTML(emailTemplate.subjectTemplate?.getCurrentContent()),
            bodyTemplate: customConvertToHTML(emailTemplate.bodyTemplate?.getCurrentContent()),
            state: HiringStageEmailTemplateStateEnum.Active,
            ccEmails: emailTemplate.contacts.ccRecipients?.map(recipient => recipient.email),
          },
          showToasts: false,
        });
      };

      updateEmailTemplateAsync(emailTemplate, currentEmailTemplate);
    },
    [partialUpdateHiringStageEmailTemplate]
  );

  const [submitting, setSubmitting] = useState(false);

  const fieldsToDisplay = useMemo(() => {
    return getStageFormFields(
      stageData,
      isTakeHome,
      isMultiPart,
      clientAtsSetupComplete,
      interviewSchedulingScenario,
      e2eSchedulingEnabled
    );
  }, [clientAtsSetupComplete, interviewSchedulingScenario, isMultiPart, isTakeHome, stageData, e2eSchedulingEnabled]);

  const onSubmit = async (): Promise<void> => {
    setSubmitting(true);

    form.change(`pipelineStages.stage-${stageData?.stage.id}.isTakeHome`, isTakeHome);
    if (isTakeHome) {
      dispatch(setIncludesTakeHomeStage(true));
    }

    fieldsToDisplay.map(field => {
      // @ts-ignore
      const formLabel = fieldsToFormLabel[field.field];
      if (formLabel) {
        form.change(
          // @ts-ignore
          `pipelineStages.stage-${stageData?.stage.id}.${fieldsToFormLabel[field.field]}`,
          pipelineStageEdits[formLabel]
        );
      }
    });
    form.change(
      `pipelineStages.stage-${stageData?.stage.id}.hiringPipelineStageState`,
      HiringPipelineStageStateEnum.Active
    );

    form.change(`pipelineStages.stage-${stageData?.stage.id}.emailTemplateEdited`, true);

    form.change(`schedulingCalendarGcalId`, formState.values.schedulingCalendarGcalIdEdited);

    const toastId = toast("Submitting interview plan changes...", {
      ...toastOptions,
      type: toast.TYPE.INFO,
      autoClose: false,
    });

    try {
      if (emailTemplateEditsById) {
        for (const emailTemplateEdit of Object.values(emailTemplateEditsById)) {
          const emailTemplate = templates?.find(template => template.id === emailTemplateEdit.id);

          if (!emailTemplate) {
            continue;
          }

          updateEmailTemplate(emailTemplateEdit, emailTemplate);
        }
      }

      // The React Final Form is dirty logic seems to get reset when we go from one step to the next
      // So just manually checking if the interview type has changed. If it has we should call the swap api.
      const { isMultipart: isMultiPartOriginal, isTakeHome: isTakeHomeOriginal } = isStage(stageData.stage);
      const isInterviewTypeDirty = isMultiPartOriginal !== isMultiPart || isTakeHomeOriginal !== isTakeHome;
      if (isInterviewTypeDirty) {
        if (!jobId) {
          return;
        }

        await swapInterviewType({ id: stageData.stage.id, jobId, data: { stageType: interviewType } }).unwrap();
      }

      await updateHps({
        id: stageData?.id ?? "",
        data: {
          name: stageIsInterview ? pipelineStageEdits.name : undefined,
          atsStageId: pipelineStageEdits.atsStageId.length ? pipelineStageEdits.atsStageId : null,
          schedulingEmailTemplateId: pipelineStageEdits.schedulingEmailTemplateId,
          rejectionEmailTemplateId: pipelineStageEdits.rejectionEmailTemplateId,
        },
      })?.unwrap();

      if (stageIsInterview) {
        if (isMultiPart) {
          // gross hacky way to filter out empty substages
          // serializer blows up if we send substages with no name or interviewers
          const substages = pipelineStageEdits.substages.filter((substage: BaseInterviewSubstage) => {
            // @ts-ignore
            return substage.name && substage.possibleInterviewers?.length;
          });

          await partialUpdateInterviewStage({
            id: stageData.id!,
            jobId: jobId ?? "",
            data: {
              interviewMeetingType: pipelineStageEdits.interviewMeetingType,
              reuseConferenceLink: pipelineStageEdits.reuseConferenceLink,
              interviewLocation: pipelineStageEdits.interviewLocation,
              interviewDetails: pipelineStageEdits.interviewDetails,
              debriefDuration: pipelineStageEdits.debriefDuration,
              substages: substages,
            },
          }).unwrap();
        } else {
          await partialUpdateInterviewStage({
            id: stageData.id!,
            jobId: jobId ?? "",
            data: {
              substages: [
                {
                  addInterviewersToHiringTeam: pipelineStageEdits.addInterviewersToHiringTeam,
                  duration: pipelineStageEdits.duration,
                  useDoverInterviewer: pipelineStageEdits.useDoverInterviewer,
                  atsSubstageId: pipelineStageEdits.atsSubstageId,
                  atsFeedbackTemplateId: pipelineStageEdits.atsFeedbackTemplateId,
                  isTakeHome: isTakeHome,
                  possibleInterviewers: pipelineStageEdits.interviewers,
                  additionalInfo: isTakeHome
                    ? {
                        takeHomeDuration: pipelineStageEdits.additionalInfo.takeHomeDuration,
                      }
                    : undefined,
                  defaultFeedbackTemplate: pipelineStageEdits.defaultFeedbackTemplate,
                },
              ],
            },
          }).unwrap();
        }

        // this is only applicable to interviewing stages, can save an API call if not
        await updateJobSetup({
          id: jobSetup?.id!,
          schedulingCalendarGcalId: formState.values.schedulingCalendarGcalIdEdited,
        }).unwrap();
      }

      toast.update(toastId, {
        autoClose: 2000,
        render: "Interview plan changes submitted",
        type: toast.TYPE.SUCCESS,
      });
    } catch (err) {
      toast.update(toastId, {
        autoClose: 2000,
        render: "Error submitting changes",
        type: toast.TYPE.ERROR,
      });
    }
    onClose(true);

    setSubmitting(false);
  };

  const fieldPrefix = `pipelineStageEdits.stage-${stageData?.stage.id}`;

  return (
    <StyledFormWrapper>
      {/* Content should fill drawer height subtracting the header, footer, and verrtical drawer padding;
      setting min-height is necessary to fix action bar to the bottom of the screen */}
      <Box minHeight="calc(100vh - 154px)">
        <JobFeatureToggleHandler>
          {submitting ? (
            <Stack justifyContent="center" alignItems="center" flexGrow={1}>
              <Progress />
            </Stack>
          ) : (
            <Box my={2}>
              {fieldsToDisplay.map(field => {
                return (
                  <DrawerFormField
                    field={field.field}
                    fieldPrefix={fieldPrefix}
                    stageData={stageData}
                    required={field.required}
                    isTakeHome={isTakeHome}
                  />
                );
              })}
            </Box>
          )}
        </JobFeatureToggleHandler>
      </Box>
      <StageDrawerActions justifyContent="flex-end" direction="row" spacing={1}>
        <Button onClick={(): void => onClose()} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          variant={ButtonVariant.Primary}
          disabled={(formState.errors && !!Object.keys(formState.errors).length) || submitting}
        >
          Save
        </Button>
      </StageDrawerActions>
    </StyledFormWrapper>
  );
};

/*
GOOD: This serves to override all of the MUI inputs in this form to have consistent borders
BETTER: a global style sheet that sets our preferred MUI input styles for the entire app
BEST: a new Dover UI library that replaces MUI
*/
const StyledFormWrapper = styled.div`
  .MuiInputBase-root {
    border: none;
  }
`;
