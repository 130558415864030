/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleJobFunnelStats,
    SimpleJobFunnelStatsFromJSON,
    SimpleJobFunnelStatsFromJSONTyped,
    SimpleJobFunnelStatsToJSON,
    SimplePersonaFunnelStats,
    SimplePersonaFunnelStatsFromJSON,
    SimplePersonaFunnelStatsFromJSONTyped,
    SimplePersonaFunnelStatsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CombinedFunnelStats
 */
export interface CombinedFunnelStats {
    /**
     * 
     * @type {SimpleJobFunnelStats}
     * @memberof CombinedFunnelStats
     */
    jobStats: SimpleJobFunnelStats;
    /**
     * 
     * @type {SimplePersonaFunnelStats}
     * @memberof CombinedFunnelStats
     */
    personaStats: SimplePersonaFunnelStats;
}

export function CombinedFunnelStatsFromJSON(json: any): CombinedFunnelStats {
    return CombinedFunnelStatsFromJSONTyped(json, false);
}

export function CombinedFunnelStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CombinedFunnelStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobStats': SimpleJobFunnelStatsFromJSON(json['job_stats']),
        'personaStats': SimplePersonaFunnelStatsFromJSON(json['persona_stats']),
    };
}

export function CombinedFunnelStatsToJSON(value?: CombinedFunnelStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_stats': SimpleJobFunnelStatsToJSON(value.jobStats),
        'persona_stats': SimplePersonaFunnelStatsToJSON(value.personaStats),
    };
}


