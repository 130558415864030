import { Box, Divider, Stack } from "@mui/material";
import { format, isValid } from "date-fns";
import React, { ChangeEvent } from "react";

import Instruction from "components/instructions/Instruction";
import { InstructionList } from "components/instructions/styles";
import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { ClientOnboardingSetupAtsStateEnum } from "services/openapi";
import { colors } from "styles/theme";
import {
  StyledDoneIcon,
  StyledEditIcon,
  StyledTextField,
  SuccessText,
} from "views/CompanySetup/components/AtsSettings/styles";
import { getHiddenKey } from "views/CompanySetup/components/AtsSettings/utils";

interface AshbyComponentProps {
  ashbyApiKeyInput: string;
  ashbyEditMode: boolean;
  ashbyCredsValid: boolean | null | undefined;
  ashbyCredsLastValidAt: Date | undefined;
  setAshbyApiKeyInput: (keyInput: string) => void;
  setAshbyEditMode: (editMode: boolean) => void;
  setupAtsState?: ClientOnboardingSetupAtsStateEnum;
}

export const AshbyComponent = ({
  ashbyApiKeyInput,
  ashbyEditMode,
  ashbyCredsValid,
  ashbyCredsLastValidAt,
  setAshbyApiKeyInput,
  setAshbyEditMode,
  setupAtsState,
}: AshbyComponentProps): React.ReactElement => {
  const handleApiKeyChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setAshbyApiKeyInput(event.target.value);
  };

  const toggleEditAshby = (): void => {
    setAshbyEditMode(!ashbyEditMode);
  };

  const formattedLastValidAt = isValid(ashbyCredsLastValidAt) ? format(ashbyCredsLastValidAt!, "PP p") : undefined;

  const showSuccessScreen =
    (setupAtsState === ClientOnboardingSetupAtsStateEnum.StartedPendingDoverAction ||
      setupAtsState === ClientOnboardingSetupAtsStateEnum.Complete) &&
    ashbyCredsValid &&
    formattedLastValidAt &&
    !ashbyEditMode;

  if (showSuccessScreen) {
    return (
      <Stack spacing={1}>
        {/* TODO: Use backend value */}
        <SuccessText>
          <StyledDoneIcon />
          Successfully connected on {formattedLastValidAt}
        </SuccessText>
        <Box border={1} borderRadius={5} borderColor={colors.grayscale.gray400} padding={1}>
          <Box width="100%" p={2} overflow="hidden">
            <Box display="inline" component="div" marginRight={7.75}>
              API Key:
            </Box>
            {/* TODO: Use backend value */}
            <Box display="inline" component="div">
              {getHiddenKey(ashbyApiKeyInput)}
            </Box>
          </Box>
          <Divider />
          <Box width="100%" p={1}>
            <Box display="inline" component="div" width="120px">
              <Button variant={ButtonVariant.Ghost} onClick={toggleEditAshby}>
                <StyledEditIcon /> Edit
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack spacing={1}>
      <Body>Follow the steps below to integrate with Ashby:</Body>
      <InstructionList>
        <Instruction
          instructionsLink={"https://help.dover.com/en/articles/6964126-where-to-find-your-ashby-api-key"}
          cardHeader={<Body>Create API key and paste it below</Body>}
          cardInstructionBody={
            <>
              <Spacer height="16px" />
              <StyledTextField
                name="ashbyApiKey"
                variant="outlined"
                placeholder="Enter API key"
                fullWidth
                onChange={handleApiKeyChange}
                defaultValue={ashbyApiKeyInput ?? ""}
                style={{ maxWidth: "339px" }}
              />
            </>
          }
        />
      </InstructionList>
      <Spacer height="16px" />
      <Body>Once you click Save and Validate, Dover will begin syncing which can take up to 1 business day.</Body>
    </Stack>
  );
};
