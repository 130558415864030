import { Box, Stack } from "@mui/material";
import styled from "styled-components";

import { colors } from "styles/theme";

export const Cell = styled(Box)`
  padding: 16px;
`;

export const InterestedBadge = styled(Stack)`
  background-color: ${colors.primary.light};
  border-radius: 4px;
  padding: 10px;
  margin-left: 12px;
  align-items: center;
`;
