import { Box } from "@mui/material";
import React from "react";
import { useForm } from "react-final-form";

import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import { useCanStage } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useCanStage";
import { EditAdditionalInstructions } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditAdditionalInstructions";
import { EditATSFeedbackForm } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditATSFeedbackForm";
import { EditATSStageMapping } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditATSStageMapping";
import { EditClientEmailTemplates } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditClientEmailTemplates";
import { EditDebriefDuration } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditDebriefDuration";
import { EditFeedbackTemplate } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditFeedbackTemplate";
import { EditInterviewType } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditInterviewType";
import { EditMeetingLocation } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditMeetingLocation";
import { EditMeetingType } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditMeetingType";
import { EditSchedulingCalendar } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditSchedulingCalendar";
import { EditStageDuration } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditStageDuration";
import { EditStageInterviewers } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditStageInterviewers";
import { EditStageName } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditStageName";
import { EditSubStages } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/Drawer/fields/EditSubStages";
import { DrawerField } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/InterviewPlan/constants";

interface MapDrawerComponentProps {
  field: DrawerField;
  fieldPrefix: string;

  stageData: StageDataWrapper;
  required: boolean;
  isTakeHome: boolean;
}

export const DrawerFormField = ({
  field,
  fieldPrefix,
  stageData,
  required,
  isTakeHome,
}: MapDrawerComponentProps): React.ReactElement => {
  const { canHotswap } = useCanStage(stageData.stage);
  const form = useForm();
  const interviewMeetingTypeValue = form.getFieldState(fieldPrefix + ".interviewMeetingType")?.value;

  let component = <></>;
  switch (field) {
    case DrawerField.INTERVIEW_TYPE:
      component = <EditInterviewType fieldPrefix={fieldPrefix} required={required} canSwap={canHotswap} />;
      break;
    case DrawerField.NAME:
      component = (
        <EditStageName fieldPrefix={fieldPrefix} required={required} stageData={stageData} isTakeHome={isTakeHome} />
      );
      break;
    case DrawerField.CLIENT_EMAIL_TEMPLATES:
      component = <EditClientEmailTemplates fieldPrefix={fieldPrefix} stageData={stageData} />;
      break;
    case DrawerField.FEEDBACK_TEMPLATE:
      component = <EditFeedbackTemplate fieldPrefix={fieldPrefix} />;
      break;
    case DrawerField.DURATION:
      component = (
        <EditStageDuration
          stageData={stageData}
          fieldPrefix={fieldPrefix}
          required={required}
          isTakeHome={isTakeHome}
        />
      );
      break;
    case DrawerField.INTERVIEWERS:
      component = (
        <EditStageInterviewers
          fieldPrefix={fieldPrefix}
          stageData={stageData}
          required={required}
          isTakeHome={isTakeHome}
        />
      );
      break;
    case DrawerField.ATS_STAGE_MAPPING:
      component = <EditATSStageMapping fieldPrefix={fieldPrefix} required={required} stageData={stageData} />;
      break;
    case DrawerField.ATS_FEEDBACK_FORM:
      component = <EditATSFeedbackForm fieldPrefix={fieldPrefix} />;
      break;
    case DrawerField.SUBSTAGES:
      component = <EditSubStages fieldPrefix={fieldPrefix} required={required} />;
      break;
    case DrawerField.MEETING_TYPE:
      component = <EditMeetingType fieldPrefix={fieldPrefix} required={required} />;
      break;
    case DrawerField.LOCATION:
      if (interviewMeetingTypeValue !== "GOOGLE_MEET") {
        component = <EditMeetingLocation fieldPrefix={fieldPrefix} required={required} />;
      }
      break;
    case DrawerField.ADDITIONAL_INSTRUCTIONS:
      component = <EditAdditionalInstructions fieldPrefix={fieldPrefix} stageData={stageData} required={required} />;
      break;
    case DrawerField.DEBRIEF_DURATION:
      component = <EditDebriefDuration fieldPrefix={fieldPrefix} required={required} />;
      break;
    case DrawerField.SCHEDULING_CALENDAR:
      component = <EditSchedulingCalendar />;
      break;
    default:
      break;
  }
  if (
    field !== DrawerField.MEETING_TYPE &&
    field !== DrawerField.LOCATION &&
    field !== DrawerField.INTERVIEWERS &&
    field !== DrawerField.DURATION
  ) {
    return <Box marginBottom={"12px"}>{component}</Box>;
  }
  return component;
};
