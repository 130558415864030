/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { useSetAtom } from "jotai";
import React, { useCallback } from "react";
import { DropzoneOptions, DropzoneRootProps, useDropzone } from "react-dropzone";
import { FieldInputProps } from "react-final-form";
import styled from "styled-components";

import { imageUploadErrorAtom } from "components/ImageUpload/atoms";
import { formatFileTypes } from "components/ImageUpload/utils";
import { colors } from "styles/theme";

const getBorderColor = (props: DropzoneRootProps): string => {
  if (props.isDragAccept) {
    return colors.grayscale.gray300;
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return colors.grayscale.gray300;
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-width: 2px;
  border-radius: 6px;
  border-color: ${(props): string => getBorderColor(props)};
  border-style: dashed;
  background-color: white;
  color: ${colors.grayscale.gray500};
  outline: none;
  transition: border 0.24s ease-in-out;
  :hover {
    cursor: pointer;
  }
`;

interface DropzoneProps {
  input: FieldInputProps<string, HTMLInputElement>;
  options?: DropzoneOptions;
  placeholder?: string | React.ReactNode;
  placeholderWhenDragActive?: string | React.ReactNode;
  acceptFileTypes?: Array<string> | string;
  errorKey?: string;
}

export const Dropzone: React.FC<DropzoneProps> = ({
  input,
  options,
  placeholder,
  placeholderWhenDragActive,
  acceptFileTypes,
  errorKey,
}) => {
  const setImageUploadError = useSetAtom(imageUploadErrorAtom);

  const onDrop = useCallback(acceptedFiles => {
    input.onChange(acceptedFiles);
  }, []);

  const onDropRejected = useCallback(fileRejections => {
    const errorMessage = fileRejections[0]?.errors[0]?.message || "Error selecting file. Please try again.";
    if (errorKey) {
      setImageUploadError({
        errorKey,
        errorMessage: formatFileTypes(errorMessage),
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...options,
    onDrop,
    onDropRejected,
    accept: acceptFileTypes,
  });

  const placeholderComponent = placeholder || <p>Drag and drop your files here, or click to select files</p>;
  const placeholderComponentWhenDragActive = placeholderWhenDragActive || <p>Drop your files here ...</p>;

  return (
    // @ts-ignore
    <Container {...getRootProps()}>
      {/* @ts-ignore */}
      <input {...getInputProps()} />
      {isDragActive ? placeholderComponentWhenDragActive : placeholderComponent}
    </Container>
  );
};
