/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShouldBeContactedByProUser
 */
export interface ShouldBeContactedByProUser {
    /**
     * 
     * @type {number}
     * @memberof ShouldBeContactedByProUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShouldBeContactedByProUser
     */
    readonly firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShouldBeContactedByProUser
     */
    readonly lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShouldBeContactedByProUser
     */
    readonly email?: string;
}

export function ShouldBeContactedByProUserFromJSON(json: any): ShouldBeContactedByProUser {
    return ShouldBeContactedByProUserFromJSONTyped(json, false);
}

export function ShouldBeContactedByProUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShouldBeContactedByProUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function ShouldBeContactedByProUserToJSON(value?: ShouldBeContactedByProUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}


