import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useMemo } from "react";

import { ReactComponent as CircleX } from "assets/icons/x-red-circle.svg";
import { convertDoverUserToBasicEmailOption } from "components/dover/CustomizeNextSteps/components/utils";
import { useBulkEmailSenderOptions } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/candidate-action-email-editor/utils";
import { EmailEditor } from "components/library/TipTap/EmailEditor";
import { BasicEmailOption, EmailEditorProps } from "components/library/TipTap/types";
import { Body } from "components/library/typography";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { DoverUser } from "services/openapi";

type BulkCandidateActionEmailEditorProps = Omit<
  EmailEditorProps,
  "variables" | "fromOptions" | "to" | "toOptions" | "onToChanged" | "ccOptions" | "schedulingLinkConfig"
> & {
  loading?: boolean;
};

interface CandidateActionEmailEditorHandle {
  setEditorContent: (content: string) => void;
}

/**
 * Similar to the normal candidate action email editor, but with some different validations that are specific to the bulk email modal
 */
const BulkCandidateActionEmailEditorRenderFunction: React.ForwardRefRenderFunction<
  CandidateActionEmailEditorHandle,
  BulkCandidateActionEmailEditorProps
> = ({ from, loading, ...rest }, forwardedRef): React.ReactElement => {
  const emailSenderOptions = useBulkEmailSenderOptions();

  const { proUsers } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsers,
        };
      },
    }
  );

  const ccOptions = useMemo<BasicEmailOption[] | undefined>(() => {
    if (!proUsers) {
      return undefined;
    }

    // Ensure that each option at least has an email
    const ccOptions = proUsers
      .filter(user => !!user.email)
      .map(convertDoverUserToBasicEmailOption)
      // Filter out users that were missing data and thus failed to convert
      .filter(user => !!user);

    return ccOptions as BasicEmailOption[];
  }, [proUsers]);

  // Loading state
  if (loading || emailSenderOptions === undefined) {
    return (
      <Box display="flex" height="100px" width="100%" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  // Error state if email option is not valid
  if (!emailSenderOptions.find(option => option.id === from?.id)) {
    return (
      <Stack alignItems="center" spacing={1}>
        <CircleX height="36px" width="36px" />
        <Body>Error: Invalid email sender. If problem persists please reach out to support.</Body>
      </Stack>
    );
  }

  return (
    <EmailEditor
      hideSaveTemplateButton
      fromOptions={emailSenderOptions}
      from={from}
      ref={forwardedRef}
      ccOptions={ccOptions}
      {...rest}
    />
  );
};

export const BulkCandidateActionEmailEditor = React.forwardRef(BulkCandidateActionEmailEditorRenderFunction);
export type BulkCandidateActionEmailEditorRefType = React.ElementRef<typeof BulkCandidateActionEmailEditor>;
