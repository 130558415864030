import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { Body } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useRegenerateSampleCandidateMutation } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import { useGetJobSetupStepsQuery } from "services/doverapi/endpoints/job/endpoints";
import { JobSetupStepStepNameEnum, JobSetupStepStateEnum, HiringPipelineStageType } from "services/openapi";
import NoCandidatesEmptyState from "views/candidates/components/NoCandidatesEmptyState";
import { useAreStagesActive } from "views/candidates/hooks";
import { useStageIdsByType } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

export const NoCandidatesFound = (): React.ReactElement => {
  const jobId = useJobIdFromUrl();

  const isBasePlan = useIsBasePlanCustomer();
  const { data: jobSetupSteps } = useGetJobSetupStepsQuery(jobId ? jobId : skipToken);

  const notFinishedHTWJobCreation = React.useMemo(() => {
    const outreachSetupStep = (jobSetupSteps?.setupSteps ?? []).find(
      setupStep => setupStep.stepName === JobSetupStepStepNameEnum.OutreachState
    );
    return outreachSetupStep?.state === JobSetupStepStateEnum.Pending;
  }, [jobSetupSteps]);
  const queuedStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.QUEUED });
  const isQueuedSelected = useAreStagesActive(queuedStageIds);

  const showSampleCandidate = isBasePlan && isQueuedSelected && !notFinishedHTWJobCreation;

  const [
    regenerateSampleCandidate,
    { isLoading: regeneratingSampleCandidate },
  ] = useRegenerateSampleCandidateMutation();

  return (
    <Box textAlign="center">
      {showSampleCandidate ? (
        <Button
          variant={ButtonVariant.Secondary}
          onClick={(): void => {
            if (jobId) {
              regenerateSampleCandidate({ jobId: jobId });
            }
          }}
          disabled={regeneratingSampleCandidate}
        >
          {regeneratingSampleCandidate ? (
            <DoverLoadingSpinner spinnerSize="15px" minHeight="25px" height="25px" width="215px" />
          ) : (
            <Box height="25px" width="215px">
              <Body>Reset Sample Candidate</Body>
            </Box>
          )}
        </Button>
      ) : (
        <Stack spacing={2} alignItems="center">
          <NoCandidatesEmptyState jobId={jobId} variant="pipeline-view" />
        </Stack>
      )}
    </Box>
  );
};
