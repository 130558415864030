import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu, MenuItem, Stack } from "@mui/material";
import React, { useState } from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { HiringPipelineStageType } from "services/openapi";
import { contactedFollowUpStageName, ContactedSubStageKeys } from "views/candidates/constants";
import { useParams } from "views/candidates/hooks";
import { useStageIdsByType } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

export const ContactedFilterDropdown = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const [{ substages }, setParams] = useParams();

  const contactedStageIds = useStageIdsByType({ stageType: HiringPipelineStageType.CONTACTED });

  const selectedSubstage = !substages?.length || !substages[0] ? undefined : substages[0];
  const isAllSelected = !selectedSubstage;
  // if there are substages, we need to convert our string to int to get the correct key
  const substageKey = selectedSubstage
    ? contactedFollowUpStageName[parseInt(selectedSubstage) as ContactedSubStageKeys]
    : undefined;

  const buttonText = substageKey || "All";

  const handleToggleMenu = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button removePadding onClick={handleToggleMenu} variant={ButtonVariant.Secondary}>
        <Stack justifyContent="space-between" minWidth="220px" flexDirection="row" padding="0.25em">
          {buttonText}
          <KeyboardArrowDownIcon />
        </Stack>
      </Button>
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleCloseMenu}>
        <MenuItem
          onClick={(): void => {
            setParams({ hpsId: contactedStageIds, substages: [] });
            handleCloseMenu();
          }}
          selected={isAllSelected}
        >
          All
        </MenuItem>
        {Object.keys(contactedFollowUpStageName).map((substage: string) => {
          // object keys returns the value as a string, so need to cast to ContactedSubStageKeys
          const substageNum = parseInt(substage) as ContactedSubStageKeys;
          const isSelected = substages?.length === 1 && substages[0] === substage;

          return (
            <MenuItem
              onClick={(): void => {
                setParams({ hpsId: contactedStageIds, substages: [substage] });
                handleCloseMenu();
              }}
              selected={isSelected}
            >
              {contactedFollowUpStageName[substageNum]}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
