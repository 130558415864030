/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AtsInterviewFeedbackTemplate
 */
export interface AtsInterviewFeedbackTemplate {
    /**
     * 
     * @type {string}
     * @memberof AtsInterviewFeedbackTemplate
     */
    readonly externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof AtsInterviewFeedbackTemplate
     */
    readonly name?: string;
}

export function AtsInterviewFeedbackTemplateFromJSON(json: any): AtsInterviewFeedbackTemplate {
    return AtsInterviewFeedbackTemplateFromJSONTyped(json, false);
}

export function AtsInterviewFeedbackTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): AtsInterviewFeedbackTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function AtsInterviewFeedbackTemplateToJSON(value?: AtsInterviewFeedbackTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


