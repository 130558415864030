/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateNewJobResponse
 */
export interface CreateNewJobResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateNewJobResponse
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateNewJobResponse
     */
    failureReason: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewJobResponse
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewJobResponse
     */
    clientId?: string;
}

export function CreateNewJobResponseFromJSON(json: any): CreateNewJobResponse {
    return CreateNewJobResponseFromJSONTyped(json, false);
}

export function CreateNewJobResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNewJobResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'failureReason': json['failure_reason'],
        'jobId': !exists(json, 'job_id') ? undefined : json['job_id'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
    };
}

export function CreateNewJobResponseToJSON(value?: CreateNewJobResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'failure_reason': value.failureReason,
        'job_id': value.jobId,
        'client_id': value.clientId,
    };
}


