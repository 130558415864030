/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterviewEvent
 */
export interface InterviewEvent {
    /**
     * 
     * @type {string}
     * @memberof InterviewEvent
     */
    interviewer: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewEvent
     */
    meetingType: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewEvent
     */
    stageName: string;
    /**
     * 
     * @type {Date}
     * @memberof InterviewEvent
     */
    bookedTime: Date;
    /**
     * 
     * @type {string}
     * @memberof InterviewEvent
     */
    eventType: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewEvent
     */
    eventId: string;
}

export function InterviewEventFromJSON(json: any): InterviewEvent {
    return InterviewEventFromJSONTyped(json, false);
}

export function InterviewEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interviewer': json['interviewer'],
        'meetingType': json['meeting_type'],
        'stageName': json['stage_name'],
        'bookedTime': (new Date(json['booked_time'])),
        'eventType': json['event_type'],
        'eventId': json['event_id'],
    };
}

export function InterviewEventToJSON(value?: InterviewEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interviewer': value.interviewer,
        'meeting_type': value.meetingType,
        'stage_name': value.stageName,
        'booked_time': (value.bookedTime.toISOString()),
        'event_type': value.eventType,
        'event_id': value.eventId,
    };
}


