import { Box, IconButton, Stack } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useState, useEffect } from "react";
import {
  DragDropContext as DragDropContextRaw,
  Droppable as DroppableRaw,
  Draggable as DraggableRaw,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
} from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";

import { ReactComponent as DraggableSVG } from "assets/icons/draggable.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash-gray.svg";
import { removeQuestionAtom, createNewQuestionAtom } from "components/dover/FeedbackTemplates/atoms";
import { userDefinedQuestionsAtom, setQuestionsAtom } from "components/dover/FeedbackTemplates/atoms";
import { useHasPermissionToEditTemplate } from "components/dover/FeedbackTemplates/hooks";
import { QuestionEditor } from "components/dover/FeedbackTemplates/QuestionEditor";
import { FeedbackTemplateQuestion } from "components/dover/FeedbackTemplates/types";
import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { FeedbackTemplateQuestionComponent } from "services/openapi";

const DragDropContext = DragDropContextRaw as any;
const Droppable = DroppableRaw as any;
const Draggable = DraggableRaw as any;

const reorder = (
  list: Array<FeedbackTemplateQuestion>,
  startIndex: number,
  endIndex: number
): FeedbackTemplateQuestion[] => {
  const result = Array.from(list);
  // remove item dragged
  const [removed] = result.splice(startIndex, 1);
  // re-insert into list
  result.splice(endIndex, 0, removed);
  // re-assign orderIndices based on the new order
  const remappedOrderIndex = result.map((question: FeedbackTemplateQuestion, i: number) => ({
    ...question,
    orderIndex: i,
  }));
  return remappedOrderIndex;
};

const QuestionDisplay = ({ question }: { question: FeedbackTemplateQuestion }): React.ReactElement => {
  return (
    <Stack>
      <BodySmall weight="600">{question.label}</BodySmall>
      {question.helperText && <BodySmall>{question.helperText}</BodySmall>}
    </Stack>
  );
};

const QuestionRow = ({
  question,
  setEditQuestion,
  isEditing,
}: {
  question: FeedbackTemplateQuestion;
  setEditQuestion: (question?: FeedbackTemplateQuestion) => void;
  isEditing: boolean;
}): React.ReactElement => {
  const removeQuestion = useSetAtom(removeQuestionAtom);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" spacing={2}>
      <Stack direction="row" alignItems="flex-start" spacing={2}>
        <div>
          <DraggableSVG width="16px" />
        </div>
        <QuestionDisplay question={question} />
      </Stack>
      <Stack direction="row">
        <IconButton
          onClick={(): void => {
            if (isEditing) {
              setEditQuestion(undefined);
            } else {
              setEditQuestion(question);
            }
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={(): void => {
            removeQuestion(question.name);
          }}
        >
          <TrashIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export const QuestionList = (): React.ReactElement => {
  const hasPermsToEdit = useHasPermissionToEditTemplate();

  // Jotai Questions Value and Setters
  const questions = useAtomValue(userDefinedQuestionsAtom);
  const setQuestions = useSetAtom(setQuestionsAtom);
  const createNewQuestion = useSetAtom(createNewQuestionAtom);

  // Tracks which question is being edited; enforces that only one question can be edited at a time
  const [questionToEdit, setQuestionToEdit] = useState<FeedbackTemplateQuestion | undefined>();

  // Track unsaved changes
  const [pendingChanges, setPendingChanges] = useState<{ [key: string]: FeedbackTemplateQuestion }>({});

  function onDragEnd(result: any): void {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const reorderedQuestions = reorder(questions, result.source.index, result.destination.index);
    setQuestions(reorderedQuestions);
  }

  // Handle changes to questions
  const handleQuestionChange = (question: FeedbackTemplateQuestion): void => {
    // Ensure we maintain the options array when switching to SELECT type
    if (question.component === FeedbackTemplateQuestionComponent.SELECT && !question.options?.length) {
      question.options = [{ label: "", value: "" }];
    }

    setPendingChanges(prev => ({
      ...prev,
      [question.name]: question,
    }));
  };

  // Apply pending changes when parent Save is clicked
  useEffect(() => {
    if (Object.keys(pendingChanges).length > 0) {
      const updatedQuestions = questions.map(q => (pendingChanges[q.name] ? pendingChanges[q.name] : q));
      setQuestions(updatedQuestions);
      setPendingChanges({});
    }
  }, [pendingChanges, questions, setQuestions]);

  const handleAddQuestion = (): void => {
    const newQuestion = {
      label: "",
      name: uuidv4(),
      helperText: "",
      component: FeedbackTemplateQuestionComponent.TEXT_FIELD,
      options: [],
    };
    createNewQuestion(newQuestion);
    setQuestionToEdit(newQuestion);
  };

  // If user doesn't have permission to edit, display read-only list of questions
  if (!hasPermsToEdit) {
    return (
      <Stack spacing={2}>
        {questions.map(question => (
          <QuestionDisplay key={question.name} question={question} />
        ))}
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="interview-questions">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot): React.ReactElement => (
            <Stack ref={provided.innerRef} {...provided.droppableProps} spacing={2} width="100%">
              {questions.map((question, i) => (
                <Draggable key={question.name} draggableId={question.name} index={i}>
                  {(provided: DraggableProvided): React.ReactElement => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <QuestionRow
                        question={question}
                        setEditQuestion={setQuestionToEdit}
                        isEditing={questionToEdit?.name === question.name}
                      />
                      {(provided as any).placeholder}
                      {questionToEdit?.name === question.name && (
                        <QuestionEditor onChange={handleQuestionChange} existingQuestion={question} />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {snapshot.isDraggingOver && <Box width="100%" height="32px" />}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <Button variant={ButtonVariant.SecondarySuccess} onClick={handleAddQuestion} width="100%">
          + Add question
        </Button>
      </div>
    </Stack>
  );
};
