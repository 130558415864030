import { convertFromHTML } from "draft-convert";

export function getHtmlBody(html: string): any {
  return convertFromHTML({
    htmlToEntity: (nodeName: any, node: any, createEntity: any): any => {
      if (nodeName === "a") {
        return createEntity("LINK", "MUTABLE", { url: node.href });
      }
    },
  })(html);
}
