import { Box } from "@mui/material";
import React from "react";
import { Field, useFormState } from "react-final-form";

import {
  RequiredAsterisk,
  StageDrawerFormError,
  StageDrawerFormLabel,
} from "components/SetupHiringPipeline/components/InterviewPlan/styles";
import { OnboardingHiringPipelineStageStateEnum } from "services/openapi";
import { B2_doNotUse } from "styles/typography";
import { StageDataWrapper } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/EditStageDrawerWrapper";
import { AtsField } from "views/job/JobSetup/steps/Scheduling/SetupHiringPipeline/components/fields/AtsField";

export const EditATSStageMapping = ({
  fieldPrefix,
  required,
  stageData,
}: {
  fieldPrefix: string;
  required: boolean;
  stageData: StageDataWrapper;
}): React.ReactElement => {
  const formState = useFormState();
  const pipelineStages = formState.values.pipelineStages;

  const checkIfATSStageMappingUnique = (atsStageId: string): boolean => {
    if (!pipelineStages || !atsStageId) {
      return true;
    }
    const pipelineStageKeys = Object.keys(pipelineStages);
    for (let index = 0; index < pipelineStageKeys.length; index++) {
      const stage = pipelineStages[pipelineStageKeys[index]];
      if (
        stage.hiringPipelineStageState === OnboardingHiringPipelineStageStateEnum.Active &&
        stageData.id !== stage.id &&
        atsStageId === stage.atsStageId
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <Box>
      <B2_doNotUse bold style={{ marginBottom: "16px" }}>
        ATS
      </B2_doNotUse>
      <StageDrawerFormLabel bold>
        ATS stage mapping {required && <RequiredAsterisk>*</RequiredAsterisk>}
      </StageDrawerFormLabel>
      <Field
        name={fieldPrefix + ".atsStageId"}
        validate={(value): boolean => {
          return required && value && !value.length;
        }}
      >
        {(props): React.ReactElement => {
          const atsStageMappingAlreadyExists = !checkIfATSStageMappingUnique(props.input.value);
          return (
            <>
              <AtsField name={fieldPrefix + ".atsStageId"} />
              {atsStageMappingAlreadyExists && (
                <StageDrawerFormError>This stage is already mapped to another interview</StageDrawerFormError>
              )}
            </>
          );
        }}
      </Field>
    </Box>
  );
};
