/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCandidateInterview
 */
export interface UpdateCandidateInterview {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCandidateInterview
     */
    enableAiNotetaker?: boolean;
}

export function UpdateCandidateInterviewFromJSON(json: any): UpdateCandidateInterview {
    return UpdateCandidateInterviewFromJSONTyped(json, false);
}

export function UpdateCandidateInterviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCandidateInterview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enableAiNotetaker': !exists(json, 'enable_ai_notetaker') ? undefined : json['enable_ai_notetaker'],
    };
}

export function UpdateCandidateInterviewToJSON(value?: UpdateCandidateInterview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enable_ai_notetaker': value.enableAiNotetaker,
    };
}


