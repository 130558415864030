import { Box } from "@mui/material";
import React from "react";

import {
  InstructionList as StyledInstructionList,
  ListItem,
  StyledImage,
} from "components/instructions/components/styles";
import { InstructionListItem } from "components/instructions/types";
import { Spacer } from "components/Spacer";
import { B1_doNotUse } from "styles/typography";

interface Props {
  listItems: readonly InstructionListItem[];
}

const InstructionList = ({ listItems }: Props): React.ReactElement => {
  return (
    <StyledInstructionList>
      {listItems.map(
        (instruction: InstructionListItem): React.ReactElement => {
          return (
            <ListItem>
              {typeof instruction.text === "string" ? <B1_doNotUse>{instruction.text}</B1_doNotUse> : instruction.text}
              {instruction.imageUrl && (
                <>
                  <Spacer height="16px" />
                  <Box width="100%" maxWidth="875px">
                    <StyledImage src={instruction.imageUrl} />
                  </Box>
                </>
              )}
            </ListItem>
          );
        }
      )}
    </StyledInstructionList>
  );
};

export default InstructionList;
