import { ReactElement } from "react";

export interface OverviewCardCount {
  label?: string;
  count?: number | string;
  labelIcon?: ReactElement;
  link: string;
}

export enum OverviewCardType {
  APPLICANTS = "Applicants",
  SOURCING = "Sourcing",
  CANDIDATES = "Candidates",
}
