import { appConfig } from "../config/appConfig";

const GetApiUri = () => {
  if (process.env.NODE_ENV === "development" || process.env.REACT_APP_ENV === "staging") {
    // this will be "http://localhost:3000" in localdev, and something like
    // "https://3000-2eb8d647-5b6f-4475-b239-45a69bb51a7f.od.fullstackrandomizedtesting.com:7800"
    // in Ondemand. Note the lack of trailing "/"
    // In development mode, we use webpack-dev-server's proxy feature (configured in package.json)
    // to forward API requests on the frontend's domain to the backend running at http://localhost:8000.
    // This way we can assume the API url is just whatever origin we're running the frontend from.
    return window.location.origin;
  }

  return appConfig.apiUrl;
};

export const GetSuperApiUri = () => {
  return appConfig.superApiUrl;
};

export default GetApiUri;
