import { useStage } from "components/dover/top-level-modal-manager/hooks/useStage";
import { doesStageRequireInterviewer } from "components/dover/top-level-modal-manager/hooks/useValidate";
import {
  CandidateBio,
  ClientInterviewerSetupStateEnum,
  CandidateBioSchedulingOwnershipEnum,
  ClientInterviewer,
  SubstageType,
} from "services/openapi";

interface NextInterviewerResult {
  nextInterviewer?: ClientInterviewer;
  nextInterviewerValid: boolean;
}

export const useNextInterviewer = (
  candidateBio?: CandidateBio,
  schedulingOwnershipOverride?: CandidateBioSchedulingOwnershipEnum
): NextInterviewerResult => {
  // override is used to set scheduling ownership before it's saved to candidate bio
  const schedulingOwnership = schedulingOwnershipOverride || candidateBio?.schedulingOwnership;
  const requiresValidation = schedulingOwnership === CandidateBioSchedulingOwnershipEnum.DoverHandlesScheduling;

  const { stage, isTakeHome } = useStage({ candidateId: candidateBio?.id });
  const stageRequiresInterviewer = doesStageRequireInterviewer({
    schedulingOwnership,
    desiredHiringPipelineStage: stage,
    isTakeHome,
  });

  if (!stageRequiresInterviewer) {
    return {
      nextInterviewer: undefined,
      nextInterviewerValid: true,
    };
  }

  /**
   * Next interviewer is valid if:
   * 1. Next stage is not a singlepart interview
   * 2. Dover interviewer is selected and the next interviewer id is undefined (this
   * is expected to be unset because Dover interviewers get assigned when scheduled)
   * 3. Dover interviewer is *NOT* selected and the selected interviewer has finished setup
   */

  if (stage?.contentTypeName !== SubstageType.INTERVIEW_STAGE) {
    return {
      nextInterviewer: undefined,
      nextInterviewerValid: true,
    };
  }

  const nextInterviewer = candidateBio?.nextAction?.nextInterview?.interviewer;
  const useDoverInterviewer = candidateBio?.nextAction?.nextInterview?.hasDoverInterviewer && !nextInterviewer;
  if (useDoverInterviewer) {
    return {
      nextInterviewer: undefined,
      nextInterviewerValid: true,
    };
  }

  if (!nextInterviewer) {
    return {
      nextInterviewer: undefined,
      nextInterviewerValid: !requiresValidation,
    };
  }

  const nextInterviewerPreferencesComplete =
    nextInterviewer?.setupState === ClientInterviewerSetupStateEnum.Ready ||
    // handles case of Outlook users
    (nextInterviewer?.setupState === ClientInterviewerSetupStateEnum.NeedsAuth &&
      !!nextInterviewer?.hasSchedulingLinkOverride);

  return {
    nextInterviewer,
    nextInterviewerValid: !requiresValidation || nextInterviewerPreferencesComplete,
  };
};
