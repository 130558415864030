/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject18
 */
export interface InlineObject18 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject18
     */
    greenhouseKey?: string;
}

export function InlineObject18FromJSON(json: any): InlineObject18 {
    return InlineObject18FromJSONTyped(json, false);
}

export function InlineObject18FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject18 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'greenhouseKey': !exists(json, 'greenhouse_key') ? undefined : json['greenhouse_key'],
    };
}

export function InlineObject18ToJSON(value?: InlineObject18 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'greenhouse_key': value.greenhouseKey,
    };
}


