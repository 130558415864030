/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CandidateActionEmailArgs,
    CandidateActionEmailArgsFromJSON,
    CandidateActionEmailArgsFromJSONTyped,
    CandidateActionEmailArgsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RescheduleInterviewRequest
 */
export interface RescheduleInterviewRequest {
    /**
     * 
     * @type {number}
     * @memberof RescheduleInterviewRequest
     */
    candidateActionVersion: number;
    /**
     * The stage we'd like to reschedule for
     * @type {string}
     * @memberof RescheduleInterviewRequest
     */
    desiredHiringPipelineStage: string;
    /**
     * 
     * @type {boolean}
     * @memberof RescheduleInterviewRequest
     */
    auto?: boolean | null;
    /**
     * 
     * @type {CandidateActionEmailArgs}
     * @memberof RescheduleInterviewRequest
     */
    emailArgs?: CandidateActionEmailArgs | null;
}

export function RescheduleInterviewRequestFromJSON(json: any): RescheduleInterviewRequest {
    return RescheduleInterviewRequestFromJSONTyped(json, false);
}

export function RescheduleInterviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RescheduleInterviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateActionVersion': json['candidate_action_version'],
        'desiredHiringPipelineStage': json['desired_hiring_pipeline_stage'],
        'auto': !exists(json, 'auto') ? undefined : json['auto'],
        'emailArgs': !exists(json, 'email_args') ? undefined : CandidateActionEmailArgsFromJSON(json['email_args']),
    };
}

export function RescheduleInterviewRequestToJSON(value?: RescheduleInterviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_action_version': value.candidateActionVersion,
        'desired_hiring_pipeline_stage': value.desiredHiringPipelineStage,
        'auto': value.auto,
        'email_args': CandidateActionEmailArgsToJSON(value.emailArgs),
    };
}


