import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";

import { FeedbackTemplateDrawerConfig, FeedbackTemplateQuestion } from "components/dover/FeedbackTemplates/types";

/*
 * --------------------------------------------------------
 * Feedback Template Drawer Atoms and Setters
 * --------------------------------------------------------
 * */

export const feedbackTemplateDrawerConfigAtom = atomWithReset<FeedbackTemplateDrawerConfig>({
  onCreateCallback: undefined,
  existingTemplateId: undefined,
  assignedJobStageCount: undefined,
  context: undefined,
});

export const feedbackTemplateFormHasErrorAtom = atomWithReset(false);

/*
 * --------------------------------------------------------
 * Feedback Template Form Atoms and Setters
 * --------------------------------------------------------
 * */

export const selectedPersonaAtom = atomWithReset<number | undefined>(undefined);
export const selectedIRTAtom = atomWithReset<string | undefined>(undefined);
export const feedbackTemplateNameAtom = atomWithReset<string | undefined>(undefined);

export const setPersonaAtom = atom(null, (get, set, personaId: number | undefined) => {
  // Reset the selected IRT Name and Feedback Template Name when changing personas
  set(selectedPersonaAtom, personaId);
  set(selectedIRTAtom, undefined);
  set(feedbackTemplateNameAtom, undefined);
});

/*
 * --------------------------------------------------------
 * User Defined Questions Atoms and Setters
 * --------------------------------------------------------
 */

export const userDefinedQuestionsAtom = atomWithReset<FeedbackTemplateQuestion[]>([]);

export const setQuestionsAtom = atom(null, (get, set, newQuestions: FeedbackTemplateQuestion[]) => {
  set(userDefinedQuestionsAtom, newQuestions);
});

export const createNewQuestionAtom = atom(null, (get, set, newQuestion: FeedbackTemplateQuestion) => {
  const questions = get(userDefinedQuestionsAtom);
  set(userDefinedQuestionsAtom, [...questions, newQuestion]);
});

export const editQuestionAtom = atom(null, (get, set, updatedQuestion: FeedbackTemplateQuestion) => {
  const questions = get(userDefinedQuestionsAtom);
  const updatedQuestions = questions.map(question => {
    if (question.name === updatedQuestion.name) {
      return updatedQuestion;
    }
    return question;
  });
  set(userDefinedQuestionsAtom, updatedQuestions);
});

export const removeQuestionAtom = atom(null, (get, set, questionName: string) => {
  const questions = get(userDefinedQuestionsAtom);
  const updatedQuestions = questions.filter(question => question.name !== questionName);
  set(userDefinedQuestionsAtom, updatedQuestions);
});
