/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JobAtsStageMap,
    JobAtsStageMapFromJSON,
    JobAtsStageMapFromJSONTyped,
    JobAtsStageMapToJSON,
} from './';

/**
 * 
 * @export
 * @interface SyncAndListJobAtsStages
 */
export interface SyncAndListJobAtsStages {
    /**
     * 
     * @type {boolean}
     * @memberof SyncAndListJobAtsStages
     */
    readonly syncSuccessful?: boolean | null;
    /**
     * 
     * @type {Array<JobAtsStageMap>}
     * @memberof SyncAndListJobAtsStages
     */
    readonly atsStages?: Array<JobAtsStageMap>;
}

export function SyncAndListJobAtsStagesFromJSON(json: any): SyncAndListJobAtsStages {
    return SyncAndListJobAtsStagesFromJSONTyped(json, false);
}

export function SyncAndListJobAtsStagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncAndListJobAtsStages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'syncSuccessful': !exists(json, 'sync_successful') ? undefined : json['sync_successful'],
        'atsStages': !exists(json, 'ats_stages') ? undefined : ((json['ats_stages'] as Array<any>).map(JobAtsStageMapFromJSON)),
    };
}

export function SyncAndListJobAtsStagesToJSON(value?: SyncAndListJobAtsStages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


