import { Button, Box, Paper } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import Loading from "components/HotLoading";
import PageHelmet from "components/PageHelmet";
import { Spacer } from "components/Spacer";
import { useListHiringStageEmailTemplatesQuery } from "services/doverapi/endpoints/emailTemplates";
import { JobPicker } from "views/admin/job/HiringStageEmailTemplates/components/JobPicker";
import { TemplatePicker } from "views/admin/job/HiringStageEmailTemplates/components/TemplatePicker";
import { EmailTemplateForm } from "views/admin/job/HiringStageEmailTemplates/EmailTemplateForm";

const JobEmailTemplates = (): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId, emailTemplateId } = useParams<{ jobId: string; emailTemplateId: string }>();

  const { data: hiringStageEmailtemplates } = useListHiringStageEmailTemplatesQuery(jobId ?? skipToken);

  return (
    <>
      <PageHelmet title="Job Email Templates" />

      {hiringStageEmailtemplates ? (
        <Box paddingLeft={4} paddingTop={3}>
          <Box>
            <Box display="flex">
              <Box>
                <h3>Current Job</h3>
                <JobPicker />
                <Spacer height={24} />
                <Button
                  variant="outlined"
                  onClick={(): void => navigate(APP_ROUTE_PATHS.admin.jobEmailTemplates(jobId!))}
                >
                  Create new template
                </Button>
                <Spacer height={12} />
                <TemplatePicker />
              </Box>
              <Spacer width={20} />
              <Box minWidth={700}>
                <Box fontSize={24}>{!emailTemplateId && "Creating new template"}</Box>
                <Paper>
                  <Box padding="25px 20px">
                    <EmailTemplateForm />
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default JobEmailTemplates;
