import { CompanySetupStepStateEnum, CompanySetupStepStepNameEnum } from "services/openapi";

export enum OnboardingSection {
  ATS_SETTINGS = "ATS Settings",
  BRANDING = "Branding",
  CAREERS_PAGE = "Careers Page Company Info Settings",
  EXCLUSIONS = "Exclusions",
  BASIC_INFO = "Basic Info",
}

interface CompanySetupStepInfo {
  state: CompanySetupStepStateEnum;
  isRelevantToJobs: boolean;
}

export type CompanySetupStepNameToInfo = { [key in CompanySetupStepStepNameEnum]: CompanySetupStepInfo };

export interface CompanySetupStepConstants {
  label: string;
  path: string;
  stepName: CompanySetupStepStepNameEnum;
}

export interface CompanySetupStepConfig extends CompanySetupStepConstants {
  setupState: CompanySetupStepStateEnum;
}

export interface EnrichedCompanySetupStep extends CompanySetupStepConfig {
  route: string;
}
