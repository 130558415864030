import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { CandidateBioSchedulingOwnershipEnum } from "services/openapi";

export const useSchedulingOwnership = (candidateId: string): CandidateBioSchedulingOwnershipEnum | undefined => {
  const { data } = useGetCandidateBioQuery(candidateId, {
    selectFromResult: ({ data }) => ({ data: data?.schedulingOwnership }),
  });

  return data;
};
