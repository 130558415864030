import { Button } from "@doverhq/dover-ui";
import { Stack, Box } from "@mui/material";
import { useSetAtom, useAtomValue } from "jotai";
import React, { ReactElement, useMemo } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import { ReactComponent as AISparklesIcon } from "assets/icons/ai-sparkles.svg";
import ReadonlyCompanyAndJobPitchCard from "components/ReadonlyCompanyAndJobPitch";
import StickyDiv from "components/StickyDiv";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { IRRCandidateInterviewInfo } from "services/openapi";
import {
  AINotetakerDrawerOpenAtom,
  TRANSCRIPT_DRAWER_WIDTH,
  TranscriptDrawer,
} from "views/interview/CandidateInterview/TranscriptDrawer";
import AIActionsBanner from "views/interview/common/components/AIActionsBanner";
import { ROUGH_NOTES_DRAWER_NAME } from "views/interview/common/components/AINoteFormatter";
import { SUGGESTED_QUESTIONS_DRAWER_NAME } from "views/interview/common/components/AISuggestedQuestions";
import FormBody from "views/interview/common/components/InterviewRubric/FormBody";
import { InterviewRubricContainer } from "views/interview/common/components/InterviewRubric/styles";
import { Section, Field } from "views/interview/common/types";

const InterviewRubricBody = ({
  sections,
  globalDisabled,
  interviewContext,
  jobId,
  clientId,
  useDoverInterviewer,
  existingRoughNotesAvailable,
}: Props): ReactElement => {
  const [, setAINoteDrawerOpen] = useQueryParam(ROUGH_NOTES_DRAWER_NAME, BooleanParam);
  const [, setAIQuestionsDrawerOpen] = useQueryParam(SUGGESTED_QUESTIONS_DRAWER_NAME, BooleanParam);

  const useAISuggestedQuestions = useFeatureFlag(FeatureFlag.AISuggestedQuestions);
  const useAINotetaker = useFeatureFlag(FeatureFlag.AINotetaker);

  const setAINotetakerDrawerOpen = useSetAtom(AINotetakerDrawerOpenAtom);

  const sectionRefs = useMemo<any>(
    () =>
      sections.reduce((x: any, s: Section): any => {
        x[s.name] = {
          ref: React.createRef(),
          questionRefs: s.fields.reduce((y: any, q: Field): any => {
            y[q.name] = React.createRef();
            return y;
          }, {}),
        };
        return x;
      }, {}),
    [sections]
  );

  // TODO (davin): ideally we maintain more similar UIs between DI and non-DI user views
  // e.g. display *both* the top interview context and the side interview context for DI users
  // then we wouldn't need to have this conditional rendering
  if (useDoverInterviewer) {
    return (
      <Stack direction="row" spacing={2}>
        <Stack spacing={2}>
          <ReadonlyCompanyAndJobPitchCard jobId={jobId} clientId={clientId} />
          <FormBody sectionRefs={sectionRefs} sections={sections} globalDisabled={globalDisabled} />
        </Stack>
        {/*  for dover interviewers, have interview context as a sticky side nav */}
        <Box width="168px">
          <StickyDiv top={0}>{interviewContext}</StickyDiv>
        </Box>
      </Stack>
    );
  }

  // otherwise show a simple interview context at the top of the form
  return (
    <Box width="100%" display="flex" justifyContent="center" paddingBottom="50px">
      <Stack spacing={1} width={useAINotetaker ? "100%" : "75%"} padding="16px">
        {/* Candidate Context */}
        {interviewContext}
        {useAINotetaker && (
          <Box display={{ xs: "block", sm: "none" }}>
            <Button
              variant="outlined"
              onPress={(): void => {
                setAINotetakerDrawerOpen(true);
              }}
              icon={{ Icon: AISparklesIcon }}
              w="full"
              style={{ boxShadow: "2px 0px 4px rgba(0, 0, 0, 0.05)" }}
            >
              Transcript
            </Button>
          </Box>
        )}

        {/* AI Actions */}
        {useAISuggestedQuestions && !useAINotetaker && (
          <AIActionsBanner
            onGenerateQuestions={(e: React.MouseEvent): void => {
              e.preventDefault();
              e.stopPropagation();
              setAIQuestionsDrawerOpen(true);
            }}
            onAutofillNotes={(e: React.MouseEvent): void => {
              e.preventDefault();
              e.stopPropagation();
              setAINoteDrawerOpen(true);
            }}
            existingRoughNotesAvailable={!!existingRoughNotesAvailable}
          />
        )}

        {/* Main Interview Rubric */}
        <FormBody sectionRefs={sectionRefs} sections={sections} globalDisabled={globalDisabled} />
      </Stack>
    </Box>
  );
};

interface Props {
  sections: Section[];
  title: string;
  globalDisabled: boolean;
  interviewContext: React.ReactNode;
  companyAndJobPitch?: string;
  jobId?: string;
  clientId?: string;
  candidateId?: string;
  useDoverInterviewer: boolean;
  existingRoughNotesAvailable?: boolean;
  hasTranscript: boolean;
  candidateInterviewInfo: IRRCandidateInterviewInfo;
}

const InterviewRubric = (props: Props): ReactElement => {
  const useAINotetaker = useFeatureFlag(FeatureFlag.AINotetaker);
  const isAINotetakerDrawerOpen = useAtomValue(AINotetakerDrawerOpenAtom);

  if (useAINotetaker) {
    return (
      <Stack direction="row" justifyContent="center" width="100%">
        <Box mr={isAINotetakerDrawerOpen ? TRANSCRIPT_DRAWER_WIDTH : 0} width="100%" maxWidth="920px">
          <InterviewRubricContainer maxWidth={false}>
            <InterviewRubricBody {...props} />
          </InterviewRubricContainer>
        </Box>
        <TranscriptDrawer candidateInterviewInfo={props.candidateInterviewInfo} />
      </Stack>
    );
  }

  return (
    <InterviewRubricContainer maxWidth={false}>
      <InterviewRubricBody {...props} />
    </InterviewRubricContainer>
  );
};

export default InterviewRubric;
