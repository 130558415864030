import { Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, Subtitle1 } from "components/library/typography";
import CustomModal from "components/Modal";
import { ModalKeyType } from "views/job/types";

export const TurnOffCompletionModal = ({
  shouldShowModal,
  handleCloseModal,
  modalKey,
  headerText,
  bodyText,
  shouldShowCreateJobLink,
}: {
  shouldShowModal: string;
  handleCloseModal: () => void;
  modalKey: ModalKeyType;
  headerText: string;
  bodyText: string;
  shouldShowCreateJobLink: boolean;
}): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <CustomModal
      open={shouldShowModal === modalKey}
      hideCloseButton={true}
      customTitleStyles={{ padding: "0px" }}
      omitDividers={true}
      onClose={handleCloseModal}
      maxWidth={"sm"}
      dialogActions={
        <Stack width="100%" direction="row" alignItems="center" justifyContent="end" spacing={2}>
          {shouldShowCreateJobLink && (
            <Button
              variant={ButtonVariant.Secondary}
              onClick={(): void => {
                navigate(APP_ROUTE_PATHS.job.createJob());
              }}
            >
              {"Hiring for a different role?"}
            </Button>
          )}
          <Button variant={ButtonVariant.Primary} onClick={handleCloseModal}>
            {"Done"}
          </Button>
        </Stack>
      }
    >
      <Stack alignItems="flex-start" flexDirection="column" spacing={2} padding={1}>
        <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
          <Subtitle1>{headerText}</Subtitle1>
        </Stack>
        <Body>{bodyText}</Body>
      </Stack>
    </CustomModal>
  );
};
