import { Stack } from "@mui/material";
import React from "react";

import ClientEmailTemplateSelector, {
  ClientEmailTemplateSelectorProps,
} from "components/dover/EmailTemplates/ClientEmailTemplateSelector";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";

export const ClientEmailTemplateSelect: React.FC<ClientEmailTemplateSelectorProps> = ({
  disabled,
  disabledTooltipText,
  hideSmartReply,
  clientEmailTemplateId,
  setClientEmailTemplateId,
}) => {
  return (
    <Stack spacing={1} width="100%">
      <BodySmall weight="500">Template</BodySmall>
      <Tooltip title={disabledTooltipText}>
        <div>
          <ClientEmailTemplateSelector
            disabled={disabled}
            hideSmartReply={hideSmartReply}
            hideLabel
            clientEmailTemplateId={clientEmailTemplateId}
            setClientEmailTemplateId={setClientEmailTemplateId}
          />
        </div>
      </Tooltip>
    </Stack>
  );
};
