import { Button } from "@doverhq/dover-ui";
import { Box, Stack } from "@mui/material";
import { useSetAtom } from "jotai";
import React from "react";
import { useBoolean } from "react-use";
import styled from "styled-components";

import { ReactComponent as CopySVG } from "assets/icons/copy.svg";
import { ReactComponent as TrashSVG } from "assets/icons/trash-gray.svg";
import { ReactComponent as DoverIcon } from "assets/logos/DoverD.svg";
import { feedbackTemplateDrawerConfigAtom } from "components/dover/FeedbackTemplates/atoms";
import { FeedbackTemplateDrawerAtom } from "components/dover/FeedbackTemplates/FeedbackTemplateDrawer";
import { FeedbackTemplateDrawerContextType } from "components/dover/FeedbackTemplates/types";
import { InfoTip } from "components/InfoTip";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { PersonaCoin } from "components/PersonaCoin";
import { useModal } from "GlobalOverlays/atoms";
import { useDeleteFeedbackTemplateMutation } from "services/doverapi/endpoints/feedbackTemplates";
import { FeedbackTemplateAssignedStage } from "services/openapi";
import { FeedbackTemplateTableParams } from "views/CompanySetup/components/FeedbackTemplates";

const StyledPersonaCoin = styled(PersonaCoin)`
  line-height: 0px;
`;

export const NameCell = (params: FeedbackTemplateTableParams): React.ReactElement => {
  return (
    <Box sx={{ textWrap: "wrap" }}>
      <Stack direction="row" spacing={1} alignItems="center">
        {params.row.isDefault && <DoverIcon width="20px" height="20px" />}
        <BodySmall>{params.row.name}</BodySmall>
        {params.row.isDefault && (
          <InfoTip text="Dover's default form includes basic fields to track pros, cons, and set an overall rating." />
        )}
      </Stack>
    </Box>
  );
};

export const CreatedByCell = (params: FeedbackTemplateTableParams): React.ReactElement => {
  const templateAuthor = params.row.createdBy;
  if (!templateAuthor) {
    return <Box />;
  }
  return (
    <Box style={{ lineHeight: "normal" }}>
      <StyledPersonaCoin pictureUrl={templateAuthor.pictureUrl || undefined} tooltip={templateAuthor.fullName} />
    </Box>
  );
};

export const AssignedJobsCell = (params: FeedbackTemplateTableParams): React.ReactElement => {
  const assignedJobCount = params.row.assignedStageCount;
  const assignedJobList = (
    <div>
      {params.row.assignedStages.map(
        (stage: FeedbackTemplateAssignedStage): React.ReactElement => (
          <div>{stage.jobStageName}</div>
        )
      )}
    </div>
  );

  return (
    <Tooltip title={assignedJobList}>
      <Box sx={{ cursor: "default" }}>
        <BodySmall>{assignedJobCount}</BodySmall>
      </Box>
    </Tooltip>
  );
};

export const ActionsCell = (params: FeedbackTemplateTableParams): React.ReactElement => {
  const feedbackTemplate = params.row;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useBoolean(false);
  const [deleteFeedbackTemplate] = useDeleteFeedbackTemplateMutation();

  // Jotai setters
  const setFeedbackTemplateDrawerConfig = useSetAtom(feedbackTemplateDrawerConfigAtom);

  const { open: openFeedbackTemplateDrawer } = useModal(FeedbackTemplateDrawerAtom);

  const onDuplicate = (): void => {
    setFeedbackTemplateDrawerConfig({
      existingTemplateId: feedbackTemplate.id,
      context: FeedbackTemplateDrawerContextType.Duplicate,
    });
    openFeedbackTemplateDrawer({});
  };

  const onDelete = async (): Promise<void> => {
    await deleteFeedbackTemplate(feedbackTemplate.id).unwrap();
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      {/* Confirm Deletion Modal */}
      <CustomModal
        maxWidth="xs"
        open={showDeleteConfirmation}
        title="Delete template"
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onPress={setShowDeleteConfirmation}>
              Cancel
            </Button>
            <Button variant="criticalFilled" onPress={onDelete}>
              Delete
            </Button>
          </Stack>
        }
      >
        <Body>
          <span>Are you sure you want to delete</span>
          <span>
            <b> {feedbackTemplate.name}</b>
          </span>
          <span>? This action cannot be undone.</span>
        </Body>
      </CustomModal>
      {/* Action cell buttons */}
      <Stack direction="row" spacing={2}>
        <Tooltip title="Duplicate form">
          <div>
            <Button onPress={onDuplicate} icon={{ Icon: CopySVG, color: "transparent" }} />
          </div>
        </Tooltip>
        <Tooltip title={feedbackTemplate.isDefault ? "Deleting default form is not permitted." : "Delete template"}>
          <div>
            <Button
              onPress={setShowDeleteConfirmation}
              icon={{ Icon: TrashSVG, color: "transparent" }}
              isDisabled={feedbackTemplate.isDefault}
            />
          </div>
        </Tooltip>
      </Stack>
    </>
  );
};
