import { createSlice } from "@reduxjs/toolkit";

import { CandidatesReducerState } from "domains/candidate/types";

export const candidatesReducerKey = "candidatesReducerKey";

export const initialState: CandidatesReducerState = {
  currentCandidateId: "",
  candidates: {},
  unsavedCandidateNotes: {},
};

const candidatesSlice = createSlice({
  name: "CandidatesStore",
  initialState: initialState,
  reducers: {
    setCurrentCandidate: (state, action): void => {
      state.currentCandidateId = action.payload;
    },
    setOrUpdateUnsavedCandidateNote: (
      state,
      action: { type: string; payload: { candidateId: string; editorState: string } }
    ): void => {
      state.unsavedCandidateNotes[action.payload.candidateId] = action.payload.editorState;
    },
    removeUnsavedCandidateNote: (state, action: { type: string; payload: { candidateId: string } }): void => {
      delete state.unsavedCandidateNotes[action.payload.candidateId];
    },
  },
});

export const { setOrUpdateUnsavedCandidateNote, removeUnsavedCandidateNote } = candidatesSlice.actions;
export const candidatesReducer = candidatesSlice.reducer; // state tree
export const candidatesActions = candidatesSlice.actions; // keys from reducers
