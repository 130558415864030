import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState, useEffect, useCallback, useMemo } from "react";

import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useListHiringPipelineStagesV2Query } from "services/doverapi/endpoints/hiringPipelineStage";
import {
  CandidateBioJobStage,
  HiringPipelineStageMilestone,
  HiringPipelineStageType,
  InterviewPlanInterviewSubstage,
  NextActionSchedulingStateEnum,
} from "services/openapi";
import { getSinglePartSubstage, isInterviewStage } from "utils/isStage";

export interface SchedulableStage extends CandidateBioJobStage {
  isOneOffInterview?: boolean;
}

// This hook is used to manage the local state of stage for single part interviews
export const useStage = ({
  candidateId,
  forceUseCurrentPipelineStage,
}: {
  candidateId?: string;
  forceUseCurrentPipelineStage?: boolean;
}): {
  stage: SchedulableStage | undefined;
  setStage: (stageId: string) => void;
  stages: SchedulableStage[] | undefined;
  substage: InterviewPlanInterviewSubstage | undefined;
  isTakeHome: boolean;
  isUpdatingStage: boolean;
} => {
  const [stage, setStage] = useState<CandidateBioJobStage | undefined>();
  const [currentPipelineStage, setCurrentPipelineStage] = useState<CandidateBioJobStage | undefined>();

  const stageId = stage?.id;

  const { data: bio, isFetching } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const { substage } = useListHiringPipelineStagesV2Query(bio?.job && stageId ? { jobId: bio.job } : skipToken, {
    selectFromResult: ({ data }): { substage: InterviewPlanInterviewSubstage | undefined } => {
      const foundStage = data?.results.find(s => s.id === stageId);
      if (!foundStage) {
        return { substage: undefined };
      }

      return {
        substage: getSinglePartSubstage(foundStage),
      };
    },
  });

  // Get the schedulable stages
  const schedulableStages = useMemo(
    // TODO: Extract notion of "schedulable" to a util.
    (): SchedulableStage[] | undefined => {
      const stages = bio?.jobStages?.filter(s => isInterviewStage(s));
      if (!stages) {
        return undefined;
      }

      return [
        ...stages,
        {
          id: "",
          jobId: "",
          name: "General Interview",
          stageType: HiringPipelineStageType.INTERVIEW,
          milestone: null,
          isOneOffInterview: true,
        },
      ];
    },
    [bio?.jobStages]
  );

  // Set initial stage once candidate bio loads
  useEffect(() => {
    if (bio && !isFetching) {
      // When call is completed, next stage
      // Otherwise use the current stage
      const schedulingState = bio.nextAction?.schedulingState;
      const initialStage =
        (schedulingState === NextActionSchedulingStateEnum.CallCompleted ||
          schedulingState === NextActionSchedulingStateEnum.InitialReview) &&
        !forceUseCurrentPipelineStage
          ? bio.nextAction?.nextHiringPipelineStage
          : bio.nextAction?.hiringPipelineStage;

      // Good god...
      // if stage is defined but in a pre-interview stage, we need to clamp it to the initial call stage
      // We don't want to set all undefined to 500 because for later stages, sometimes we want to keep it undefined
      if (
        initialStage &&
        [
          HiringPipelineStageType.QUEUED,
          HiringPipelineStageType.CONTACTED,
          HiringPipelineStageType.RESPONDED,
          HiringPipelineStageType.APPLICATION_REVIEW,
        ].includes(initialStage.stageType)
      ) {
        const clampedInitialStage = schedulableStages?.find(
          s => s.milestone === HiringPipelineStageMilestone.INITIAL_CALL
        );
        setStage(clampedInitialStage);
      } else {
        setStage(initialStage);
      }

      setCurrentPipelineStage(bio.nextAction?.hiringPipelineStage);
    }
  }, [bio, isFetching, forceUseCurrentPipelineStage, schedulableStages]);

  // The stage selector used by the super component works based on stage IDs
  // so we need to convert back to stage
  const setStageWithId = useCallback(
    (stageId: string): void => {
      const newStage = schedulableStages?.find(s => s.id === stageId);
      setStage(newStage);
    },
    [setStage, schedulableStages]
  );

  return {
    stage,
    setStage: setStageWithId,
    stages: schedulableStages,
    substage,
    // The reason we need the above 2 is because that route forces everything into an InterviewPanel schema which works for both single part and multipart interviewers
    isTakeHome: substage?.isTakeHome || false,
    isUpdatingStage: !!stageId && currentPipelineStage?.id !== stageId,
  };
};
