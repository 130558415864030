import { Dictionary, EntityAdapter, EntityState } from "@reduxjs/toolkit";

// NOTE: You don't need to use this function, the built in selectAll method works just fine. This will be deprecated.
export const listAllEntities = <T>(entityState: EntityState<T> | undefined): T[] => {
  const entities: T[] = entityState
    ? Object.values(entityState.entities).filter((entity): entity is T => !!entity)
    : [];
  return entities;
};

// NOTE: You don't need to use this function, the built in selectById method works just fine. This will be deprecated.
export const getEntityById = <T>(
  entityState: EntityState<T> | undefined,
  entityId: string | number | null | undefined
): T | undefined => {
  return entityId && entityState ? entityState.entities[entityId] : undefined;
};

/**
 * Filters an entity state object
 *
 * @param adapter The entity adapter
 * @param entityState The  entity state
 * @param fn Filtering function
 * @returns A filtered down entity state object
 */
export const filterEntity = <T>(
  adapter: EntityAdapter<T>,
  entityState: EntityState<T>,
  fn: (entity: T) => boolean
): EntityState<T> => {
  const entities = adapter.getSelectors().selectAll(entityState);

  const filteredEntities = entities.filter(fn);
  const filteredIds = filteredEntities.map(adapter.selectId);
  const filteredDict = constructEntityDict(adapter, filteredEntities);

  return { ids: filteredIds, entities: filteredDict };
};

/**
 * Sort an entity state object
 *
 * @param adapter The entity adapter
 * @param entityState The entity state
 * @param fn Sorting function
 * @returns A sorted entity state object
 */
export const sortEntity = <T>(
  adapter: EntityAdapter<T>,
  entityState: EntityState<T>,
  fn: (a: T, b: T) => number
): EntityState<T> => {
  const entities = adapter.getSelectors().selectAll(entityState);

  const sortedEntites = entities.sort(fn);
  const sortedIds = sortedEntites.map(adapter.selectId);
  const sortedDict = constructEntityDict(adapter, sortedEntites);

  return { ids: sortedIds, entities: sortedDict };
};

const constructEntityDict = <T>(adapter: EntityAdapter<T>, entities: T[]): Dictionary<T> =>
  entities.reduce((acc, curr): Dictionary<T> => ({ ...acc, [adapter.selectId(curr)]: curr }), {});
