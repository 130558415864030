/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCandidateEmailActivityResponse
 */
export interface GetCandidateEmailActivityResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetCandidateEmailActivityResponse
     */
    hasRecentEmailActivity: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetCandidateEmailActivityResponse
     */
    clientToCandidateEmailRequests?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetCandidateEmailActivityResponse
     */
    emailEvents?: Array<object>;
}

export function GetCandidateEmailActivityResponseFromJSON(json: any): GetCandidateEmailActivityResponse {
    return GetCandidateEmailActivityResponseFromJSONTyped(json, false);
}

export function GetCandidateEmailActivityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCandidateEmailActivityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hasRecentEmailActivity': json['has_recent_email_activity'],
        'clientToCandidateEmailRequests': !exists(json, 'client_to_candidate_email_requests') ? undefined : json['client_to_candidate_email_requests'],
        'emailEvents': !exists(json, 'email_events') ? undefined : json['email_events'],
    };
}

export function GetCandidateEmailActivityResponseToJSON(value?: GetCandidateEmailActivityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'has_recent_email_activity': value.hasRecentEmailActivity,
        'client_to_candidate_email_requests': value.clientToCandidateEmailRequests,
        'email_events': value.emailEvents,
    };
}


