/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineObject12
 */
export interface InlineObject12 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    candidateId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    replyType: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    conversationId: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    messageBody?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    userEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    userAlias?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject12
     */
    notInterestedAutomation?: number;
}

export function InlineObject12FromJSON(json: any): InlineObject12 {
    return InlineObject12FromJSONTyped(json, false);
}

export function InlineObject12FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineObject12 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateId': json['candidate_id'],
        'replyType': json['reply_type'],
        'conversationId': json['conversation_id'],
        'messageBody': !exists(json, 'message_body') ? undefined : json['message_body'],
        'userEmail': !exists(json, 'user_email') ? undefined : json['user_email'],
        'userAlias': !exists(json, 'user_alias') ? undefined : json['user_alias'],
        'notInterestedAutomation': !exists(json, 'not_interested_automation') ? undefined : json['not_interested_automation'],
    };
}

export function InlineObject12ToJSON(value?: InlineObject12 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_id': value.candidateId,
        'reply_type': value.replyType,
        'conversation_id': value.conversationId,
        'message_body': value.messageBody,
        'user_email': value.userEmail,
        'user_alias': value.userAlias,
        'not_interested_automation': value.notInterestedAutomation,
    };
}


