import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useEffect, useMemo, useState } from "react";

import { OnboardingCurrentStepToUrlParamMap } from "App/routing/constants";
import { CompletionStep as InboundCriteriaCompletionStep } from "components/dover/InboundCriteriaSetupFlow/steps/CompletionStep";
import { InboundCriteriaSetupForm } from "components/dover/InboundCriteriaSetupFlow/steps/InboundCriteriaSetup";
import JobDescriptionStepForm from "components/dover/InboundCriteriaSetupFlow/steps/JobDescriptionStep";
import StepFlow from "components/StepFlow/StepFlow";
import { StepFlowStep, StepProps } from "components/StepFlow/types";
import { useGetJobDescriptionQuery } from "services/doverapi/endpoints/job-description/endpoints";
import { CurrentUserOnboardingStepResponseCurrentStepEnum } from "services/openapi";
import { showErrorToast } from "utils/showToast";

interface InboundCriteriaSetupStepProps extends StepProps {
  jobId: string;
  onAfterCreateJob?: (jobId: string) => Promise<void>;
  onAfterJobDescription?: () => Promise<void>;
  onAfterCriteria?: () => Promise<void>;
  onAfterCompletion?: () => Promise<void>;
}

/* -----------------------------------------------------------------------------
 * JobDescriptionStep
 * -------------------------------------------------------------------------- */

interface JobDescriptionStepProps
  extends Omit<InboundCriteriaSetupStepProps, "jobId">,
    Partial<Pick<InboundCriteriaSetupStepProps, "jobId">> {}

const JobDescriptionStep = ({
  jobId: jobIdProp,
  onAfterCreateJob,
  onAfterJobDescription,
  goNext,
}: JobDescriptionStepProps): ReactElement => {
  const [jobId, setJobId] = useState(jobIdProp);

  const onNext = async (): Promise<void> => {
    if (onAfterJobDescription) await onAfterJobDescription();
    goNext();
  };

  const onJobCreationSuccess = (jobId: string): void => {
    setJobId(jobId);
    onAfterCreateJob?.(jobId);
  };

  return (
    <JobDescriptionStepForm hideJdPreview jobId={jobId} onNext={onNext} onJobCreationSuccess={onJobCreationSuccess} />
  );
};

/* -----------------------------------------------------------------------------
 * InboundCriteriaStep
 * -------------------------------------------------------------------------- */

const InboundCriteriaStep = ({ jobId, onAfterCriteria, goNext }: InboundCriteriaSetupStepProps): ReactElement => {
  const onNext = async (): Promise<void> => {
    if (onAfterCriteria) await onAfterCriteria();
    goNext();
  };

  return <InboundCriteriaSetupForm jobId={jobId} onNext={onNext} />;
};

/* -----------------------------------------------------------------------------
 * CompletionStep
 * -------------------------------------------------------------------------- */

const CompletionStep = ({ jobId, onAfterCompletion, goNext }: InboundCriteriaSetupStepProps): ReactElement => {
  const onNext = async (): Promise<void> => {
    if (onAfterCompletion) await onAfterCompletion();
    goNext();
  };

  return <InboundCriteriaCompletionStep jobId={jobId} onNext={onNext} />;
};

/* -----------------------------------------------------------------------------
 * InboundCriteriaSetupFlow
 * -------------------------------------------------------------------------- */

export interface InboundCriteriaSetupFlowProps {
  prependSteps?: StepFlowStep[];
  appendSteps?: StepFlowStep[];
  flowTitle: string;
  jobId?: string;
  onAfterCreateJob?: (jobId: string) => Promise<void>;
  onAfterJobDescription?: () => Promise<void>;
  onAfterCriteria?: () => Promise<void>;
  onAfterCompletion?: () => Promise<void>;
}

export const InboundCriteriaSetupFlow = ({
  prependSteps,
  appendSteps,
  flowTitle,
  jobId,
  onAfterCreateJob,
  onAfterJobDescription,
  onAfterCriteria,
  onAfterCompletion,
}: InboundCriteriaSetupFlowProps): ReactElement => {
  const [hasExistingJd, setHasExistingJd] = useState<boolean | undefined>();
  const { data: existingJobDescription, isLoading: isGetJobDescriptionLoading } = useGetJobDescriptionQuery(
    jobId ? { jobId } : skipToken
  );

  const JobDescriptionStepWrapper = useMemo(() => {
    return ({ goNext }: StepProps): ReactElement => {
      return (
        <JobDescriptionStep
          jobId={jobId}
          onAfterCreateJob={onAfterCreateJob}
          onAfterJobDescription={onAfterJobDescription}
          goNext={goNext}
        />
      );
    };
  }, [jobId, onAfterCreateJob, onAfterJobDescription]);

  const InboundCriteriaStepWrapper = useMemo(() => {
    return ({ goNext }: StepProps): ReactElement => {
      if (!jobId) {
        showErrorToast("Job not found, please refresh and try again.");
        return <></>;
      }

      return <InboundCriteriaStep jobId={jobId} onAfterCriteria={onAfterCriteria} goNext={goNext} />;
    };
  }, [jobId, onAfterCriteria]);

  const CompletionStepWrapper = useMemo(() => {
    return ({ goNext }: StepProps): ReactElement => {
      if (!jobId) {
        showErrorToast("Job not found, please refresh and try again.");
        return <></>;
      }

      return <CompletionStep jobId={jobId} onAfterCompletion={onAfterCompletion} goNext={goNext} />;
    };
  }, [jobId, onAfterCompletion]);

  const steps = [
    ...(prependSteps?.length ? prependSteps : []),
    ...(hasExistingJd
      ? []
      : [
          {
            component: JobDescriptionStepWrapper,
            progressLabel: "Job Details",
            stepRoute: OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.JdQuestion],
          },
        ]),
    {
      component: InboundCriteriaStepWrapper,
      progressLabel: "Criteria",
      stepRoute: OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.InboundCriteria],
    },
    {
      component: CompletionStepWrapper,
      progressLabel: "Complete",
      stepRoute: OnboardingCurrentStepToUrlParamMap[CurrentUserOnboardingStepResponseCurrentStepEnum.Completion],
    },
    ...(appendSteps?.length ? appendSteps : []),
  ];

  useEffect(() => {
    if (typeof hasExistingJd === "undefined" && !isGetJobDescriptionLoading) {
      setHasExistingJd(!!existingJobDescription?.userProvidedDescription);
    }
  }, [hasExistingJd, isGetJobDescriptionLoading, existingJobDescription]);

  if (typeof hasExistingJd === "undefined") return <></>;

  return <StepFlow title={flowTitle} steps={steps} />;
};
