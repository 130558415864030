/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchV3Params,
    SearchV3ParamsFromJSON,
    SearchV3ParamsFromJSONTyped,
    SearchV3ParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSearchParamsFromJDResponse
 */
export interface GetSearchParamsFromJDResponse {
    /**
     * 
     * @type {SearchV3Params}
     * @memberof GetSearchParamsFromJDResponse
     */
    searchParams: SearchV3Params;
    /**
     * 
     * @type {string}
     * @memberof GetSearchParamsFromJDResponse
     */
    personaId?: string | null;
}

export function GetSearchParamsFromJDResponseFromJSON(json: any): GetSearchParamsFromJDResponse {
    return GetSearchParamsFromJDResponseFromJSONTyped(json, false);
}

export function GetSearchParamsFromJDResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSearchParamsFromJDResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchParams': SearchV3ParamsFromJSON(json['search_params']),
        'personaId': !exists(json, 'persona_id') ? undefined : json['persona_id'],
    };
}

export function GetSearchParamsFromJDResponseToJSON(value?: GetSearchParamsFromJDResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_params': SearchV3ParamsToJSON(value.searchParams),
        'persona_id': value.personaId,
    };
}


