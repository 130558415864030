import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";

interface JobSourcesProps {
  jobId: string;
  isFirstOpening: boolean;
}

interface JobReportProps {
  jobPositionId: string;
  jobTitle: string;
  jobId: string;
  isFirstOpening: boolean;
}

export const JobSourcesButton = ({ jobId, isFirstOpening }: JobSourcesProps): React.ReactElement => {
  if (!isFirstOpening || !jobId) {
    return <></>;
  }

  return (
    <Button
      removePadding
      variant={ButtonVariant.Secondary}
      onClick={(): void => {
        window.open(`/job/${jobId}/setup/channels`, "_blank", "noopener noreferrer");
      }}
    >
      <Box sx={{ padding: "8px 12px" }}>
        <BodySmall>Channels</BodySmall>
      </Box>
    </Button>
  );
};

export const JobReportButton = ({
  jobPositionId,
  jobTitle,
  jobId,
  isFirstOpening,
}: JobReportProps): React.ReactElement => {
  if (!isFirstOpening) {
    return <></>;
  }

  if (!jobId) {
    return (
      <Link to={"/create-job"} state={{ jobPositionId: jobPositionId, jobTitle: jobTitle }}>
        <Button removePadding variant={ButtonVariant.Primary} onClick={(): void => {}}>
          <Box sx={{ padding: "8px 12px" }}>
            <BodySmall color={colors.white}>Create Job</BodySmall>
          </Box>
        </Button>
      </Link>
    );
  }
  return (
    <>
      <Spacer width="8px" />
      <Button
        removePadding
        variant={ButtonVariant.Secondary}
        onClick={(): void => {
          window.open(`/reports/${jobId}`, "_blank", "noopener noreferrer");
        }}
      >
        <Box sx={{ padding: "8px 12px" }}>
          <BodySmall>Report</BodySmall>
        </Box>
      </Button>
    </>
  );
};
