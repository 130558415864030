import { UseQueryResult } from "services/doverapi/types";
import { HiringPipelineStage, HiringPipelineStageListResponse, HiringPipelineStageType } from "services/openapi";
import { useAllHps } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/hooks/useHps";

/**
 * This hook gets all the stages to be used on the interview plan page.
 * It filters out all stages that are not interview or offer stages.
 * It also returns all of the other rtkq return values associated with the api call.
 */
export const useInterviewPlanStages = (): Omit<UseQueryResult<HiringPipelineStageListResponse>, "data"> & {
  stages: Array<HiringPipelineStage> | undefined;
} => {
  const { stages, ...rest } = useAllHps();

  // Only the interview and offer stages are able to be modified on the interview plan page
  const filteredStages = stages?.filter(
    stage => stage.stageType === HiringPipelineStageType.INTERVIEW || stage.stageType === HiringPipelineStageType.OFFER
  );

  return { stages: filteredStages, ...rest };
};

export const useInterestedStages = (): Omit<UseQueryResult<HiringPipelineStageListResponse>, "data"> & {
  stages: Array<HiringPipelineStage> | undefined;
} => {
  // this powers the fixed applied and responded stages in the interview plan
  const { stages, ...rest } = useAllHps();

  const filteredStages = stages
    ?.filter(
      stage =>
        stage.stageType === HiringPipelineStageType.APPLICATION_REVIEW ||
        stage.stageType === HiringPipelineStageType.RESPONDED
    )
    // this makes applied come before responded
    .sort((a, b) => (a.name < b.name ? -1 : 1));

  return { stages: filteredStages, ...rest };
};
