import { default as MuiTextField } from "@mui/material/TextField";
import PropTypes from "prop-types";
import React from "react";

import Label from "components/inputs/TextField/Label";
import { TextFieldStyles } from "components/inputs/TextField/styles";

const TextField = ({
  value,
  onChange,
  disabled,
  error,
  placeholder,
  isRequired,
  required,
  label,
  helperText,
  inputProps,
  ...rest
}) => {
  return (
    <TextFieldStyles hideLabel={value != null && !!value}>
      <Label label={label} required={required || isRequired} />
      <MuiTextField
        value={value}
        onChange={onChange}
        fullWidth
        disabled={disabled}
        error={error}
        variant="outlined"
        helperText={helperText}
        placeholder={placeholder}
        InputLabelProps={{ shrink: false }}
        inputProps={inputProps}
        {...rest}
      />
    </TextFieldStyles>
  );
};

TextField.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  inputProps: PropTypes.object,
};

TextField.defaultProps = {};

export default TextField;
