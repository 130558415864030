import { Skeleton, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Chip } from "components/library/Chip";
import { Body, Overline } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { SaapReviewAdditionalFiltersCriteriaMatchEnum } from "services/openapi";
import { colors } from "styles/theme";
import { StageColumn, StageTitle } from "views/candidates/CandidateTable/board/styles";
import { useCountApplicantsByBucketLabel, useCountByStageName } from "views/candidates/hooks";

const ApplicantsColumn = (): React.ReactElement => {
  const navigate = useNavigate();

  const jobId = useJobIdFromUrl();

  const numApplicants = useCountByStageName("Applied");

  const { count: numBestMatches, isFetching: isFetchingNumBestMatches } = useCountApplicantsByBucketLabel({
    bucketLabel: SaapReviewAdditionalFiltersCriteriaMatchEnum.BestMatch,
    jobId,
  });

  const hasBestMatches = numBestMatches !== undefined && numBestMatches > 0;

  // Safe to assert numBestMatches is not undefined here because of the check above
  const text = hasBestMatches ? `Best match${numBestMatches! > 1 ? "es" : ""} to review` : "Applicants to review";

  return (
    <StageColumn minimized={false}>
      <StageTitle rotate={false}>
        <Overline color={colors.grayscale.gray500}>{`Applied (${numApplicants})`}</Overline>
      </StageTitle>
      {numApplicants === 0 ? (
        <Body>No applicants to review</Body>
      ) : (
        <Card>
          <Stack spacing={1} alignItems="center">
            {isFetchingNumBestMatches ? (
              <Skeleton width="100%" />
            ) : (
              <>
                {hasBestMatches && <Chip variant="Primary" label={numBestMatches!.toString()} />}
                <Body>{text}</Body>
                <Button
                  variant={ButtonVariant.Primary}
                  onClick={(): void => {
                    if (jobId) {
                      navigate(APP_ROUTE_PATHS.job.applicationReviewV2(jobId));
                    }
                  }}
                  width="100%"
                >
                  View
                </Button>
              </>
            )}
          </Stack>
        </Card>
      )}
    </StageColumn>
  );
};

export default ApplicantsColumn;
