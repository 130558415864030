/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactEmail
 */
export interface ContactEmail {
    /**
     * 
     * @type {string}
     * @memberof ContactEmail
     */
    readonly id?: string;
    /**
     * The raw email address. This is the email as provided by the user, candidate, etc., and is used for display purposes.
     * @type {string}
     * @memberof ContactEmail
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ContactEmail
     */
    readonly contactId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactEmail
     */
    readonly isPrimary?: boolean;
}

export function ContactEmailFromJSON(json: any): ContactEmail {
    return ContactEmailFromJSONTyped(json, false);
}

export function ContactEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactEmail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'contactId': !exists(json, 'contact_id') ? undefined : json['contact_id'],
        'isPrimary': !exists(json, 'is_primary') ? undefined : json['is_primary'],
    };
}

export function ContactEmailToJSON(value?: ContactEmail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}


