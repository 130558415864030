/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PositionInfo,
    PositionInfoFromJSON,
    PositionInfoFromJSONTyped,
    PositionInfoToJSON,
    SearchV3Explanation,
    SearchV3ExplanationFromJSON,
    SearchV3ExplanationFromJSONTyped,
    SearchV3ExplanationToJSON,
    SocialLink,
    SocialLinkFromJSON,
    SocialLinkFromJSONTyped,
    SocialLinkToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProfileResponse
 */
export interface ProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    canonicalId: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    fullName: string;
    /**
     * 
     * @type {Array<SocialLink>}
     * @memberof ProfileResponse
     */
    socialLinks: Array<SocialLink>;
    /**
     * 
     * @type {Array<PositionInfo>}
     * @memberof ProfileResponse
     */
    recentPositionsInfo: Array<PositionInfo>;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    headlineEducation: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    totalYoe: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    specificYoe: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    location: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    saapCalibrationType: ProfileResponseSaapCalibrationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileResponse
     */
    hasEmail?: boolean | null;
    /**
     * 
     * @type {SearchV3Explanation}
     * @memberof ProfileResponse
     */
    niceToHaveExplanation: SearchV3Explanation;
    /**
     * 
     * @type {SearchV3Explanation}
     * @memberof ProfileResponse
     */
    mustHaveExplanation: SearchV3Explanation;
}

/**
* @export
* @enum {string}
*/
export enum ProfileResponseSaapCalibrationTypeEnum {
    GoodFit = 'GOOD_FIT',
    BadFit = 'BAD_FIT',
    TopCandidate = 'TOP_CANDIDATE'
}

export function ProfileResponseFromJSON(json: any): ProfileResponse {
    return ProfileResponseFromJSONTyped(json, false);
}

export function ProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canonicalId': json['canonical_id'],
        'fullName': json['full_name'],
        'socialLinks': ((json['social_links'] as Array<any>).map(SocialLinkFromJSON)),
        'recentPositionsInfo': ((json['recent_positions_info'] as Array<any>).map(PositionInfoFromJSON)),
        'headlineEducation': json['headline_education'],
        'totalYoe': json['total_yoe'],
        'specificYoe': json['specific_yoe'],
        'location': json['location'],
        'saapCalibrationType': json['saap_calibration_type'],
        'hasEmail': !exists(json, 'has_email') ? undefined : json['has_email'],
        'niceToHaveExplanation': SearchV3ExplanationFromJSON(json['nice_to_have_explanation']),
        'mustHaveExplanation': SearchV3ExplanationFromJSON(json['must_have_explanation']),
    };
}

export function ProfileResponseToJSON(value?: ProfileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canonical_id': value.canonicalId,
        'full_name': value.fullName,
        'social_links': ((value.socialLinks as Array<any>).map(SocialLinkToJSON)),
        'recent_positions_info': ((value.recentPositionsInfo as Array<any>).map(PositionInfoToJSON)),
        'headline_education': value.headlineEducation,
        'total_yoe': value.totalYoe,
        'specific_yoe': value.specificYoe,
        'location': value.location,
        'saap_calibration_type': value.saapCalibrationType,
        'has_email': value.hasEmail,
        'nice_to_have_explanation': SearchV3ExplanationToJSON(value.niceToHaveExplanation),
        'must_have_explanation': SearchV3ExplanationToJSON(value.mustHaveExplanation),
    };
}


