import { Progress } from "@doverhq/dover-ui";
import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { init as commandbarInit } from "commandbar";
import { HotkeyMenuAtom } from "HotkeyMenu";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

import { CLIENT_ID_QUERY_PARAM, LoginErrorCodes } from "App/appConstants";
import { reloadPage } from "App/components/ClientImpersonator/utils";
import { Role, useContainsRole } from "components/RBAC";
import { useModal } from "GlobalOverlays/atoms";
import { useHotkey } from "hooks/useHotkey";
import authConfig from "services/auth_config";
import { useSetClientAliasMutation } from "services/doverapi/endpoints/client/endpoints";
import { useGetAuthedUserInfoQuery } from "services/doverapi/endpoints/proUser";
import { useAuth0 } from "services/react-auth0-spa";
import { setNewRelicCustomAttributes } from "utils/newrelic";
import "App/styles/App.scss";
import { showErrorToast } from "utils/showToast";
import Unauthorized from "views/Unauthorized";
import VerifyEmail from "views/VerifyEmail";

// init stateful modules
commandbarInit("ba7abe4a");

const App = (): React.ReactElement => {
  const { loading: auth0Loading, user, failedToLogin, loginFailureErrorCode } = useAuth0();
  const [clientIdParam] = useQueryParam(CLIENT_ID_QUERY_PARAM, StringParam);

  // Setup the keyboard shortcuts menu
  const { toggle } = useModal(HotkeyMenuAtom);
  useHotkey("/", toggle, "Toggle this menu open and close");

  const isInternalUser = useContainsRole([Role.ADMIN, Role.INTERVIEWER, Role.COORDINATOR]);

  const currentClientId = user && user[authConfig.clientInfoUrl];
  setNewRelicCustomAttributes(user, isInternalUser, currentClientId);

  const [setClientAlias, { isLoading: setClientAliasLoading }] = useSetClientAliasMutation();

  const { isFetching: isFetchingUserInfo } = useGetAuthedUserInfoQuery(
    user && user.email_verified === false ? { checkEmailVerification: true } : skipToken
  );

  // If the client id in the url is different than the one attached to auth0 we want to set the client alias on the backend and reload the page
  useEffect(() => {
    // If we don't have a client id in the url, we can't do anything
    if (!clientIdParam) {
      return;
    }

    // We need to wait for auth0 to finish loading before we can compare client ids
    if (auth0Loading) {
      return;
    }

    // If the param is already correct set, early return
    if (currentClientId === clientIdParam) {
      return;
    }

    const trySetClientAlias = async (): Promise<void> => {
      const isSuccess = (await setClientAlias(clientIdParam).unwrap()).success;
      if (isSuccess) {
        if (!!clientIdParam && currentClientId !== clientIdParam) {
          reloadPage();
        }
      } else {
        showErrorToast("Failed to set clientId. Falling back to last known client impersonation.");
      }
    };

    trySetClientAlias();
  }, [auth0Loading, clientIdParam, currentClientId, setClientAlias]);

  if (failedToLogin) {
    if (loginFailureErrorCode == LoginErrorCodes.UnverifiedEmail) {
      return <VerifyEmail />;
    }
    return <Unauthorized />;
  }

  if (auth0Loading || setClientAliasLoading || isFetchingUserInfo) {
    return (
      <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center">
        <Progress size="large" />
      </Box>
    );
  }

  return <Outlet />;
};

export default App;
