import { Box } from "@mui/material";
import React from "react";

import { Body } from "components/library/typography";
import { ClientOnboardingAtsTypeEnum, ClientOnboardingSetupAtsStateEnum } from "services/openapi";
import { ExternalLink } from "styles/typography";
import { SuccessText } from "views/CompanySetup/components/AtsSettings/styles";

export const NoAtsComponent = ({
  setupAtsState,
  atsType,
}: {
  setupAtsState: ClientOnboardingSetupAtsStateEnum | undefined;
  atsType: ClientOnboardingAtsTypeEnum | undefined;
}): React.ReactElement => {
  const switchedFromOther = atsType === ClientOnboardingAtsTypeEnum.Other;

  if (setupAtsState === ClientOnboardingSetupAtsStateEnum.Complete && !switchedFromOther) {
    return (
      <Box width="500px">
        <SuccessText>Step complete! You can use Dover to track and manage candidates.</SuccessText>
        <br />
        <Body>
          Learn more about the Dover ATS{" "}
          <ExternalLink href="https://help.dover.com/en/articles/6480741-dover-candidate-tracking" target="_blank">
            here
          </ExternalLink>
          .
        </Body>
      </Box>
    );
  }
  return (
    <Box width="500px">
      <Body>
        Learn more about the Dover ATS{" "}
        <ExternalLink href="https://help.dover.com/en/articles/6480741-dover-candidate-tracking" target="_blank">
          here
        </ExternalLink>
        .
      </Body>
    </Box>
  );
};
