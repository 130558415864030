/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoverRole
 */
export interface DoverRole {
    /**
     * 
     * @type {string}
     * @memberof DoverRole
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverRole
     */
    readonly name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DoverRole
     */
    isDefault?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DoverRole
     */
    readonly cleanName?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverRole
     */
    readonly description?: string;
}

export function DoverRoleFromJSON(json: any): DoverRole {
    return DoverRoleFromJSONTyped(json, false);
}

export function DoverRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoverRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
        'cleanName': !exists(json, 'clean_name') ? undefined : json['clean_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function DoverRoleToJSON(value?: DoverRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_default': value.isDefault,
    };
}


