import { Skeleton, Stack } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { toast, UpdateOptions } from "react-toastify";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as CircleX } from "assets/icons/x-red-circle.svg";
import { useTopLevelModalManagerOptions } from "components/dover/top-level-modal-manager/hooks";
import { CandidateActionModalType } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/types";
import { MoveJobModalProps } from "components/dover/top-level-modal-manager/types";
import JobSelector from "components/JobSelector/JobSelector";
import { Button, ButtonVariant } from "components/library/Button";
import { Body, BodySmall, ButtonText } from "components/library/typography";
import CustomModal from "components/Modal";
import StageSelector from "components/StageSelector/StageSelector";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { useMoveJobMutation } from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useListJobsQuery, selectAllJobs, removeJobById } from "services/doverapi/endpoints/job";
import { DashboardJob, HiringPipelineStageType, JobStage } from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";

interface MoveJobModalLocalState {
  selectedJobId?: string;
  setSelectedJobId: React.Dispatch<React.SetStateAction<string | undefined>>;

  stage?: JobStage;
  setStage: React.Dispatch<React.SetStateAction<JobStage | undefined>>;
}

/**
 * This is the entry point / top level for the move job modal
 * It has the actual modal component wrapping everything else and manages local state
 */
const MoveJobModalWrapper = ({ isOpen, closeModal, candidateId }: MoveJobModalProps): React.ReactElement => {
  const [selectedJobId, setSelectedJobId] = React.useState<string | undefined>(undefined);
  const [stage, setStage] = React.useState<JobStage | undefined>(undefined);

  const { candidateActionVersion } = useGetCandidateBioQuery(candidateId, {
    selectFromResult: result => ({
      candidateActionVersion: result.data?.actionVersion ?? 0,
    }),
  });
  const [moveJob] = useMoveJobMutation();
  const { showCandidateActionModal } = useTopLevelModalManagerOptions();

  const onSubmitMoveJob = useCallback(async () => {
    // This should never happen because the button is disabled if any of these is unselected
    if (!selectedJobId || !stage) return;

    const moveJobPromise = moveJob({
      id: candidateId,
      data: {
        newJobId: selectedJobId,
        newStageId: stage.id,
        candidateActionVersion,
      },
    }).unwrap();

    try {
      await toast.promise(
        moveJobPromise,
        {
          pending: "Moving job...",
          success: "Job moved!",
          error: {
            render({ data }: { data: { userFacingMessage: string | UpdateOptions | undefined } }) {
              return data?.userFacingMessage ?? "Error moving job";
            },
          },
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }, [candidateActionVersion, candidateId, moveJob, selectedJobId, stage]);

  const onSubmit = useCallback(async () => {
    await onSubmitMoveJob();
    closeModal();
  }, [onSubmitMoveJob, closeModal]);

  const onSubmitAndDraftSchedulingEmail = useCallback(async () => {
    await onSubmitMoveJob();
    // No need to close the modal as the top level modal manager will handle that
    showCandidateActionModal({ candidateId, modalType: CandidateActionModalType.Schedule });
  }, [candidateId, onSubmitMoveJob, showCandidateActionModal]);

  const submitDisabled = useMemo(() => !selectedJobId || !stage, [selectedJobId, stage]);
  const submitDisabledTooltip = useMemo(() => (submitDisabled ? "Select a job and a stage" : undefined), [
    submitDisabled,
  ]);
  const submitAndDraftDisabled = useMemo(() => submitDisabled || !stage?.isSchedulable, [submitDisabled, stage]);
  const submitAndDraftDisabledTooltip = useMemo(
    () =>
      submitAndDraftDisabled
        ? submitDisabledTooltip
          ? submitDisabledTooltip
          : "Selected stage is not a schedulable stage"
        : undefined,
    [submitDisabledTooltip, submitAndDraftDisabled]
  );

  const Actions = (
    <Stack direction="row" spacing={1}>
      <Button
        variant={ButtonVariant.Primary}
        onClick={onSubmitAndDraftSchedulingEmail}
        disabled={submitAndDraftDisabled}
        tooltip={submitAndDraftDisabledTooltip}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <CalendarIcon className="svg-color" color={colors.white} />
          <ButtonText color={colors.white}>Submit & Draft Scheduling Email</ButtonText>
        </Stack>
      </Button>
      <Button
        variant={ButtonVariant.Primary}
        onClick={onSubmit}
        disabled={submitDisabled}
        tooltip={submitDisabledTooltip}
      >
        <ButtonText color={colors.white}>Submit</ButtonText>
      </Button>
    </Stack>
  );

  return (
    <CustomModal
      title="Move job"
      open={isOpen}
      onClose={closeModal}
      maxWidth="sm"
      showTitleSpacer={false}
      dialogActions={Actions}
      customContentStyles={{ backgroundColor: colors.grayscale.gray100 }}
    >
      <MoveJobModalData
        candidateId={candidateId}
        selectedJobId={selectedJobId}
        setSelectedJobId={setSelectedJobId}
        stage={stage}
        setStage={setStage}
      />
    </CustomModal>
  );
};

/**
 * This component is the data loading layer of the component
 * Manages displaying loading states, error states, and the actual content
 */
const MoveJobModalData = ({
  candidateId,
  selectedJobId,
  ...rest
}: Pick<MoveJobModalProps, "candidateId"> & MoveJobModalLocalState): React.ReactElement => {
  const { data: bio, isLoading: isBioLoading } = useGetCandidateBioQuery(candidateId);
  const { jobs, isJobsListLoading } = useListJobsQuery(
    { ordering: "name", active: "true" },
    {
      // Remove the current job from list of options and convert to array
      selectFromResult: results => ({
        jobs: results.data && bio?.job && selectAllJobs(removeJobById(results.data, bio.job)),
        isJobsListLoading: results.isLoading,
      }),
    }
  );

  // Loading state
  if (isBioLoading || isJobsListLoading) {
    return (
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width="250px" height="20px" />

          <Skeleton variant="rectangular" width="450px" height="30px" />
        </Stack>
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width="250px" height="20px" />

          <Skeleton variant="rectangular" width="450px" height="30px" />
        </Stack>
      </Stack>
    );
  }

  // Error state
  if (!bio || !jobs || !bio.job || !bio.jobTitle || !bio.jobStages) {
    return (
      <Stack alignItems="center" spacing={1}>
        <CircleX height="36px" width="36px" />
        <Body>Error. If problem persists please reach out to support.</Body>
      </Stack>
    );
  }

  return <MoveJobModalContent jobTitle={bio.jobTitle} jobs={jobs} selectedJobId={selectedJobId} {...rest} />;
};

/**
 * This component is the actual content of the modal
 * It is a "dumb" component that accepts everything it needs as props
 */
const MoveJobModalContent = ({
  jobTitle,
  jobs,
  selectedJobId,
  setSelectedJobId,
  stage,
  setStage,
}: {
  jobTitle: string;
  jobs: DashboardJob[];
} & MoveJobModalLocalState): React.ReactElement => {
  const selectedJob = jobs.find(j => j.id === selectedJobId);

  return (
    <Stack spacing={2}>
      <BodySmall>
        Current job:
        <BodySmall inline weight="700">
          {` ${jobTitle}`}
        </BodySmall>
      </BodySmall>
      <Stack spacing={1}>
        <BodySmall>Move to new job</BodySmall>
        {jobs && <JobSelector jobId={selectedJobId} jobs={jobs} setJobId={setSelectedJobId} />}
      </Stack>
      <Stack spacing={1}>
        <BodySmall>Select stage</BodySmall>
        <StageSelector
          pipelineStageId={stage?.id}
          setStage={(pipelineStageId: string): void => {
            if (!selectedJob) {
              return;
            }
            setStage(selectedJob.hiringPipelineStages?.find(hps => hps.id === pipelineStageId));
          }}
          disabled={!selectedJob}
          stages={
            selectedJob?.hiringPipelineStages?.map(
              (hps): JobStage => ({
                ...hps,
                // Hardcoded override for stage 400 (Client Review) to "Responded"
                // TODO: Just rename all these HPSs to Responded
                name: hps.stageType === HiringPipelineStageType.RESPONDED ? "Responded" : hps.name,
              })
            ) ?? []
          }
        />
      </Stack>
    </Stack>
  );
};

export default MoveJobModalWrapper;
