/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompleteCheckoutRequest
 */
export interface CompleteCheckoutRequest {
    /**
     * 
     * @type {string}
     * @memberof CompleteCheckoutRequest
     */
    billingSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteCheckoutRequest
     */
    billingSessionType?: CompleteCheckoutRequestBillingSessionTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CompleteCheckoutRequestBillingSessionTypeEnum {
    JobBoards = 'JOB_BOARDS',
    DefaultPaymentMethod = 'DEFAULT_PAYMENT_METHOD'
}

export function CompleteCheckoutRequestFromJSON(json: any): CompleteCheckoutRequest {
    return CompleteCheckoutRequestFromJSONTyped(json, false);
}

export function CompleteCheckoutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompleteCheckoutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingSessionId': json['billing_session_id'],
        'billingSessionType': !exists(json, 'billing_session_type') ? undefined : json['billing_session_type'],
    };
}

export function CompleteCheckoutRequestToJSON(value?: CompleteCheckoutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billing_session_id': value.billingSessionId,
        'billing_session_type': value.billingSessionType,
    };
}


