import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as CheckGreenFilled } from "assets/icons/check-green-filled.svg";
import { ReactComponent as ApplicantSortingPreviewImg } from "assets/images/applicant-sorting-preview.svg";
import Loading, { SizeableSpinner } from "components/HotLoading";
import { Button, ButtonVariant } from "components/library/Button";
import { Card } from "components/library/Card";
import { Body, BodySmall, Heading, PageTitle } from "components/library/typography";
import useWindowDimensions from "hooks/windowDimensions";
import { useListSaapReviewApplicationsQuery } from "services/doverapi/endpoints/applicationReview";
import { useListSearchesV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import { ListSaapReviewApplicationRequestOrderingEnum, SearchV3SearchTypeEnum } from "services/openapi";
import { colors, screenSizesNumbers } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import { STEP_FLOW_PAGE_CONTAINER_HEIGHT } from "views/create-job/constants";

/* -----------------------------------------------------------------------------
 * CompletionStep
 * -------------------------------------------------------------------------- */

const IMPORT_EXISTING_APPLICANTS_MAILTO_LINK =
  "mailto:customersupport@dover.com?subject=Import%20candidates%20to%20Dover&body=Hi%2C%20Dover%20Support%20Team!%0A%0AI%E2%80%99d%20like%20to%20import%20my%20existing%20candidates%20from%20%7B%7Btell%20us%20out%20the%20sources%20you%E2%80%99re%20importing%20from%20%E2%80%94%20eg.%20Lever%2C%20Greenhouse%2C%20Ashby%2C%20Airtable%7D%7D.%20Please%20let%20me%20know%20what%20information%20you%20need%20from%20me%20to%20complete%20the%20process.%0A%0AThank%20you!";

interface CompletionStepProps {
  jobId: string;
  onNext: () => Promise<void>;
}

export const CompletionStep = ({ jobId, onNext }: CompletionStepProps): React.ReactElement => {
  const muiTheme = useTheme();
  const isLargeScreen = useMediaQuery(muiTheme.breakpoints.up(screenSizesNumbers.mobileL));
  const [shouldPoll, setShouldPoll] = useState(true);
  const { data: searches, isLoading: isLoadingSearches } = useListSearchesV3Query(
    jobId
      ? {
          job: jobId,
          searchTypeList: SearchV3SearchTypeEnum.Inbound,
        }
      : skipToken
  );
  const { data: applications, error: listApplicationsError } = useListSaapReviewApplicationsQuery(
    searches?.[0]?.id
      ? {
          searchId: searches[0].id,
          ordering: ListSaapReviewApplicationRequestOrderingEnum.Newest,
          limit: 8,
        }
      : skipToken,
    {
      pollingInterval: shouldPoll ? 10 * 1000 : 0, // 10 seconds
    }
  );
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const { width: windowWidth } = useWindowDimensions();
  // NOTE: The applicants are created asynchronously and we want to ensure at least 3 are available to sort
  const showNextButton = (applications?.totalCount ?? 0 >= 3) || listApplicationsError || hasTimedOut;

  const onSubmit = async (): Promise<void> => {
    if (!jobId) {
      showErrorToast("No job was found, please refresh and try again.");
      return;
    }

    await onNext();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasTimedOut(true);
    }, 2 * 60 * 1000); // 2 mins

    return (): void => {
      clearTimeout(timeout);
    };
  }, [setHasTimedOut]);

  useEffect(() => {
    if (showNextButton) {
      setShouldPoll(false);
    }
  }, [showNextButton, setShouldPoll]);

  if (isLoadingSearches) {
    return <Loading />;
  }

  return (
    <Stack mx={1.5} justifyContent="center" height={STEP_FLOW_PAGE_CONTAINER_HEIGHT}>
      <Card>
        <Stack spacing={2} {...(isLargeScreen ? { textAlign: "center", alignItems: "center" } : {})}>
          <div>
            <Stack spacing={1} alignItems="center" textAlign="center">
              <CheckGreenFilled width="28px" height="28px" />
              {isLargeScreen ? <PageTitle>You&apos;re all set up!</PageTitle> : <Heading>Done</Heading>}
            </Stack>
            <BodySmall>Try out our AI features on Dover&apos;s sample applicants.</BodySmall>
          </div>
          {isLargeScreen ? (
            <ApplicantSortingPreviewImg width="465px" height="210px" />
          ) : (
            <Card padding="16px 0 16px 16px">
              <Stack maxWidth={windowWidth ? `${windowWidth - 96}px` : "75vw"} overflow="hidden">
                <ApplicantSortingPreviewImg width="465px" height="210px" />
              </Stack>
            </Card>
          )}
          {showNextButton ? (
            <Stack width="100%" alignItems="center">
              <Button variant={ButtonVariant.Primary} onClick={onSubmit}>
                View sample applicants <ArrowRight display="inline" color={colors.white} className="svg-color" />
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
              <SizeableSpinner height="24px" width="24px" fontSize="8px" />
              <Body color={colors.secondary.base}>Loading sample applicants...</Body>
            </Stack>
          )}
          <Box
            sx={{ backgroundColor: colors.grayscale.gray100 }}
            p={1.5}
            width="calc(100% + 32px)"
            marginBottom="-16px !important"
          >
            <BodySmall>
              Already have candidates? Please <a href={IMPORT_EXISTING_APPLICANTS_MAILTO_LINK}>contact us</a> to import
              them.
            </BodySmall>
          </Box>
        </Stack>
      </Card>
    </Stack>
  );
};
