/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MinMaxRange,
    MinMaxRangeFromJSON,
    MinMaxRangeFromJSONTyped,
    MinMaxRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SpecificYoeByTitle
 */
export interface SpecificYoeByTitle {
    /**
     * 
     * @type {number}
     * @memberof SpecificYoeByTitle
     */
    titleId: number;
    /**
     * 
     * @type {MinMaxRange}
     * @memberof SpecificYoeByTitle
     */
    yoeRange: MinMaxRange;
}

export function SpecificYoeByTitleFromJSON(json: any): SpecificYoeByTitle {
    return SpecificYoeByTitleFromJSONTyped(json, false);
}

export function SpecificYoeByTitleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecificYoeByTitle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'titleId': json['title_id'],
        'yoeRange': MinMaxRangeFromJSON(json['yoe_range']),
    };
}

export function SpecificYoeByTitleToJSON(value?: SpecificYoeByTitle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title_id': value.titleId,
        'yoe_range': MinMaxRangeToJSON(value.yoeRange),
    };
}


