import { Container, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useGetTemplatedRoutePath } from "App/routing/hooks";
import useIdentifyUser from "hooks/useIdentifyUser";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";

const StyledErrorContainer = styled(Grid)`
  height: 500px;
`;

const ErrorFallback = (): React.ReactElement => {
  const { data: clientData } = useGetUsersClientQuery();
  const jobId = useJobIdFromUrl();

  const templatedPath = useGetTemplatedRoutePath();

  // use react router hook to get current path

  const additionalUserTraits = useMemo(() => {
    return {
      // Client-level data, scoped to the authed user.
      clientId: clientData?.id,
      clientName: clientData?.name,
      doverPlan: clientData?.doverPlan,
      // Job-level metadata. Scoped to the job id present in the page's url, if applicable.
      jobId,
      errorPath: templatedPath,
    };
  }, [clientData?.id, clientData?.name, clientData?.doverPlan, jobId, templatedPath]);

  useIdentifyUser(additionalUserTraits);

  return (
    <Container maxWidth={"md"}>
      <StyledErrorContainer container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <h2>Uh oh! Something went wrong.</h2>
        </Grid>
        <Grid item>
          <Link to="/home">Go to homepage</Link>
        </Grid>
      </StyledErrorContainer>
    </Container>
  );
};

export default ErrorFallback;
