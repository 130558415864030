import { Box, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";

import { ReactComponent as EditPencilSVG } from "assets/icons/pencil-edit.svg";
import { ReactComponent as TrashIconSVG } from "assets/icons/trash.svg";
import { maxFileSizeUpload } from "components/constants";
import { FormValues } from "components/dover/UploadUserFile/types";
import ImageUpload from "components/ImageUpload";
import { UPLOAD_COMPANY_LOGO } from "components/ImageUpload/constants";
import { StyledAlert, StyledEditInput, StyledLogo } from "components/ImageUpload/styles";
import { Button, ButtonVariant } from "components/library/Button";
import { colors } from "styles/theme";
import { generateRandomizedImageUrl } from "utils/image";

interface CompanyLogoProps {
  logoInput?: Blob | string | null;
  setLogoInput: (value: Blob | undefined | null) => void;
  isPreviewThumbnail?: boolean;
}

const INITIAL_FORM_VALUES: { files: File[] } = {
  files: [],
};

const CompanyLogo = ({ logoInput, setLogoInput, isPreviewThumbnail }: CompanyLogoProps): React.ReactElement => {
  const [fileTooLarge, setFileTooLarge] = useState(false);
  const editButtonFileInput = React.useRef(null);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    if (values.files[0]) {
      if (values.files[0].size > maxFileSizeUpload) {
        setFileTooLarge(true);
        return;
      }
      setLogoInput(values.files[0]);
      setFileTooLarge(false);
    }
  };

  const validate = (values: FormValues): any => {
    if (!values.files) {
      return { files: "You must select a file to upload" };
    }
    if (values.files && values.files.length > 0) {
      const file = values.files[0];
      if (!["image/png", "image/jpg", "image/jpeg"].includes(file.type)) {
        return { files: "Upload a PNG or JPG." };
      }
    }
  };
  const handleSubmitEditButton = (event: any): void => {
    if (event.target.files[0].size > maxFileSizeUpload) {
      setFileTooLarge(true);
      return;
    }
    setLogoInput(event.target.files[0]);
    setFileTooLarge(false);
  };
  const handleEditClick = (): void => {
    // @ts-ignore
    editButtonFileInput.current.click();
  };

  return (
    <>
      {logoInput ? (
        <Stack spacing={1} alignItems="space-between" height="100%" width="100%">
          <StyledLogo
            src={
              typeof logoInput === "string"
                ? generateRandomizedImageUrl(logoInput)
                : URL.createObjectURL(logoInput as File)
            }
            alt="logo url"
            isPreviewThumbnail={isPreviewThumbnail}
          />
          {fileTooLarge && (
            <Box color={colors.critical.base}>File size is too large. The uploaded file must be less than 5 MB.</Box>
          )}
          <Stack direction="row" spacing={0.5}>
            <Button variant={ButtonVariant.Secondary} onClick={handleEditClick} removePadding tooltip="Edit">
              <Stack p={0.5}>
                <EditPencilSVG width="14px" height="14px" />
              </Stack>
            </Button>
            <StyledEditInput
              type="file"
              ref={editButtonFileInput}
              onChange={handleSubmitEditButton}
              accept="image/png,image/jpg,image/jpeg,image/svg+xml"
            />
            <Box height="100%">
              <Button
                onClick={(): void => {
                  setLogoInput(null);
                }}
                variant={ButtonVariant.Secondary}
                removePadding
                tooltip="Remove"
              >
                <Stack p={0.5} height="100%">
                  <TrashIconSVG color={colors.grayscale.gray700} className="svg-color" width="14px" height="14" />
                </Stack>
              </Button>
            </Box>
          </Stack>
        </Stack>
      ) : (
        <Grid item xl={6}>
          <Form
            onSubmit={handleSubmit}
            initialValues={INITIAL_FORM_VALUES}
            validate={validate}
            render={({ submitErrors, handleSubmit, submitting }): React.ReactElement => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item>
                      {submitErrors && (
                        <StyledAlert severity="error">
                          <div>{submitErrors?.submitError || "Error uploading file. Please try again."}</div>
                        </StyledAlert>
                      )}
                      <ImageUpload
                        submitting={submitting}
                        acceptedFileTypes={["image/png", "image/jpg", "image/jpeg"]}
                        errorKey={UPLOAD_COMPANY_LOGO}
                      />
                    </Grid>
                    {fileTooLarge && (
                      <Box p={2} color={colors.critical.base}>
                        File size is too large. The uploaded file must be less than 5 MB.
                      </Box>
                    )}
                  </Grid>
                </form>
              );
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default CompanyLogo;
