import styled from "styled-components";

import { theme } from "styles/theme";

// Success styles
export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.black};
  text-align: center;
`;

export const SuccessHeader = styled.div`
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 28px;
  max-width: 653px;
  margin-top: 16px;
`;

export const SpacerLine = styled.div`
  width: 30px;
  height: 1px;
  background-color: ${theme.colors.black};
  margin: 24px 0;
`;

export const SuccessSubheader = styled.div`
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`;

export const SuccessText = styled.div`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
`;
