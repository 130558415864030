import { Stack } from "@mui/material";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

import HelpIconSVG from "assets/icons/help-question.svg";
import { Tooltip, TooltipVariant } from "components/library/Tooltip";
import { Overline, Subtitle2 } from "components/library/typography";
import { colors } from "styles/theme";
import { TITLE_MAX_WIDTH } from "views/sourcing/Search/constants";
import { StyledHelpIconSVG } from "views/sourcing/Search/styles";
import { SearchV3FormSchemaType } from "views/sourcing/Search/types";

const StyledOverline = styled(Overline)`
  white-space: nowrap;
`;

interface TitleWithRequiredLabelProps {
  requiredFieldName?: keyof SearchV3FormSchemaType;
  mainTitle: string;
  errorTextKey?: keyof SearchV3FormSchemaType;
  expanded?: boolean;
  tooltipText?: string;
}

const TitleWithRequiredLabel = React.memo(
  ({
    requiredFieldName,
    mainTitle,
    errorTextKey,
    expanded,
    tooltipText,
  }: TitleWithRequiredLabelProps): React.ReactElement => {
    const {
      control,
      formState: { errors },
    } = useFormContext<SearchV3FormSchemaType>();
    const formValue = useWatch({ control, name: `${requiredFieldName}` as any });

    const isPrimaryValuePopulated = React.useMemo(() => {
      if (formValue === undefined || formValue === null) {
        return false;
      }

      let hasNonNullNonRequired = false;

      Object.keys(formValue).forEach((key: string) => {
        // The one field we don't care about, used elsewhere in this component
        if (key === "required") {
          return;
        }

        const value = formValue[key];
        if (value === undefined) {
          return;
        }

        if (Array.isArray(value) && value.length === 0) {
          hasNonNullNonRequired = false;
          return;
        }

        hasNonNullNonRequired = true;
      });

      return hasNonNullNonRequired;
    }, [formValue]);

    const shouldShowRequiredLabel = React.useMemo(() => {
      return !!formValue && formValue.required !== undefined && isPrimaryValuePopulated && !expanded;
    }, [formValue, isPrimaryValuePopulated, expanded]);

    return (
      <Stack direction="row" spacing={8} alignItems="center" width={TITLE_MAX_WIDTH} justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Subtitle2 color={errorTextKey && errors[errorTextKey] ? colors.warning.base : undefined}>
            {mainTitle}
          </Subtitle2>
          {tooltipText && (
            <Tooltip
              title={tooltipText}
              placement="right"
              arrow={true}
              variant={TooltipVariant.Dark}
              boxSizing="border-box"
            >
              {/* Span is necessary to prevent a MUI v5 render issue */}
              <span>
                <StyledHelpIconSVG src={HelpIconSVG} />
              </span>
            </Tooltip>
          )}
        </Stack>
        {shouldShowRequiredLabel && (
          <StyledOverline color={colors.grayscale.gray500}>
            {formValue.required ? "Must have" : "Nice to have"}
          </StyledOverline>
        )}
      </Stack>
    );
  }
);

export default TitleWithRequiredLabel;
