/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIConversation
 */
export interface AIConversation {
    /**
     * 
     * @type {string}
     * @memberof AIConversation
     */
    type: AIConversationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AIConversation
     */
    content: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AIConversation
     */
    additionalKwargs?: { [key: string]: string; };
}

/**
* @export
* @enum {string}
*/
export enum AIConversationTypeEnum {
    Human = 'human',
    Ai = 'ai',
    Thought = 'thought'
}

export function AIConversationFromJSON(json: any): AIConversation {
    return AIConversationFromJSONTyped(json, false);
}

export function AIConversationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIConversation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'content': json['content'],
        'additionalKwargs': !exists(json, 'additional_kwargs') ? undefined : json['additional_kwargs'],
    };
}

export function AIConversationToJSON(value?: AIConversation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'content': value.content,
        'additional_kwargs': value.additionalKwargs,
    };
}


