/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubstageType {
    INTERVIEW_STAGE = 'INTERVIEW_STAGE',
    MULTIPART_INTERVIEW_STAGE = 'MULTIPART_INTERVIEW_STAGE',
    UNKNOWN = 'UNKNOWN'
}

export function SubstageTypeFromJSON(json: any): SubstageType {
    return SubstageTypeFromJSONTyped(json, false);
}

export function SubstageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubstageType {
    return json as SubstageType;
}

export function SubstageTypeToJSON(value?: SubstageType | null): any {
    return value as any;
}

