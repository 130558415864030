/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyFeatures,
    CompanyFeaturesFromJSON,
    CompanyFeaturesFromJSONTyped,
    CompanyFeaturesToJSON,
    JobPitchFeatures,
    JobPitchFeaturesFromJSON,
    JobPitchFeaturesFromJSONTyped,
    JobPitchFeaturesToJSON,
    SearchFeatures,
    SearchFeaturesFromJSON,
    SearchFeaturesFromJSONTyped,
    SearchFeaturesToJSON,
} from './';

/**
 * 
 * @export
 * @interface ParseJDFeaturesResponse
 */
export interface ParseJDFeaturesResponse {
    /**
     * 
     * @type {string}
     * @memberof ParseJDFeaturesResponse
     */
    personaId: string | null;
    /**
     * 
     * @type {number}
     * @memberof ParseJDFeaturesResponse
     */
    searchTemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof ParseJDFeaturesResponse
     */
    jobTitle: string | null;
    /**
     * 
     * @type {SearchFeatures}
     * @memberof ParseJDFeaturesResponse
     */
    searchFeatures: SearchFeatures | null;
    /**
     * 
     * @type {CompanyFeatures}
     * @memberof ParseJDFeaturesResponse
     */
    companyFeatures: CompanyFeatures | null;
    /**
     * 
     * @type {JobPitchFeatures}
     * @memberof ParseJDFeaturesResponse
     */
    jobPitchFeatures: JobPitchFeatures | null;
    /**
     * 
     * @type {object}
     * @memberof ParseJDFeaturesResponse
     */
    rawJdParsingResult: object;
    /**
     * 
     * @type {string}
     * @memberof ParseJDFeaturesResponse
     */
    jdHighlights: string | null;
}

export function ParseJDFeaturesResponseFromJSON(json: any): ParseJDFeaturesResponse {
    return ParseJDFeaturesResponseFromJSONTyped(json, false);
}

export function ParseJDFeaturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParseJDFeaturesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personaId': json['persona_id'],
        'searchTemplateId': json['search_template_id'],
        'jobTitle': json['job_title'],
        'searchFeatures': SearchFeaturesFromJSON(json['search_features']),
        'companyFeatures': CompanyFeaturesFromJSON(json['company_features']),
        'jobPitchFeatures': JobPitchFeaturesFromJSON(json['job_pitch_features']),
        'rawJdParsingResult': json['raw_jd_parsing_result'],
        'jdHighlights': json['jd_highlights'],
    };
}

export function ParseJDFeaturesResponseToJSON(value?: ParseJDFeaturesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'persona_id': value.personaId,
        'search_template_id': value.searchTemplateId,
        'job_title': value.jobTitle,
        'search_features': SearchFeaturesToJSON(value.searchFeatures),
        'company_features': CompanyFeaturesToJSON(value.companyFeatures),
        'job_pitch_features': JobPitchFeaturesToJSON(value.jobPitchFeatures),
        'raw_jd_parsing_result': value.rawJdParsingResult,
        'jd_highlights': value.jdHighlights,
    };
}


