/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkUpsertJobFeatureSetting
 */
export interface BulkUpsertJobFeatureSetting {
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertJobFeatureSetting
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertJobFeatureSetting
     */
    featureName: BulkUpsertJobFeatureSettingFeatureNameEnum;
    /**
     * 
     * @type {string}
     * @memberof BulkUpsertJobFeatureSetting
     */
    state: BulkUpsertJobFeatureSettingStateEnum;
    /**
     * 
     * @type {object}
     * @memberof BulkUpsertJobFeatureSetting
     */
    debugInfo?: object | null;
}

/**
* @export
* @enum {string}
*/
export enum BulkUpsertJobFeatureSettingFeatureNameEnum {
    ManagedOutbound = 'MANAGED_OUTBOUND',
    DoverInterviewer = 'DOVER_INTERVIEWER',
    E2EScheduling = 'E2E_SCHEDULING',
    ScheduleInitialCustomerCallOnly = 'SCHEDULE_INITIAL_CUSTOMER_CALL_ONLY'
}/**
* @export
* @enum {string}
*/
export enum BulkUpsertJobFeatureSettingStateEnum {
    Enabled = 'ENABLED',
    Disabled = 'DISABLED'
}

export function BulkUpsertJobFeatureSettingFromJSON(json: any): BulkUpsertJobFeatureSetting {
    return BulkUpsertJobFeatureSettingFromJSONTyped(json, false);
}

export function BulkUpsertJobFeatureSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpsertJobFeatureSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'featureName': json['feature_name'],
        'state': json['state'],
        'debugInfo': !exists(json, 'debug_info') ? undefined : json['debug_info'],
    };
}

export function BulkUpsertJobFeatureSettingToJSON(value?: BulkUpsertJobFeatureSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feature_name': value.featureName,
        'state': value.state,
        'debug_info': value.debugInfo,
    };
}


