/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EducationLevelsResponse
 */
export interface EducationLevelsResponse {
    /**
     * 
     * @type {string}
     * @memberof EducationLevelsResponse
     */
    educationLevel: string;
}

export function EducationLevelsResponseFromJSON(json: any): EducationLevelsResponse {
    return EducationLevelsResponseFromJSONTyped(json, false);
}

export function EducationLevelsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EducationLevelsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'educationLevel': json['education_level'],
    };
}

export function EducationLevelsResponseToJSON(value?: EducationLevelsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'education_level': value.educationLevel,
    };
}


