import { Box } from "@mui/material";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { Button, ButtonVariant } from "components/library/Button";
import { BodySmall } from "components/library/typography";
import { useSubmitDecision } from "hooks/useSubmitDecision";
import { ArchiveReasonTypeEnum, CandidateBio, SubmitDecisionRequestDecisionEnum } from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";
import { useGetRejectionReasonByTypeAndDesc } from "views/CandidateDetail/components/next-action/utils/helpers";

export function MarkAsWithdrawnButton({ bio }: { bio?: CandidateBio }): React.ReactElement {
  const submitDecision = useSubmitDecision();

  const candidateId = bio?.id;
  const jobId = bio?.job;
  const candidateActionVersion = bio?.actionVersion;
  const withdrawOtherArchiveReason = useGetRejectionReasonByTypeAndDesc(ArchiveReasonTypeEnum.Withdrew, "Other");
  const withdrawArchiveId = withdrawOtherArchiveReason?.id;
  const disabled = !candidateId || !jobId || !candidateActionVersion || !withdrawArchiveId;

  const clickWithdraw = useCallback(async () => {
    if (!candidateId || !jobId || !candidateActionVersion || !withdrawArchiveId) {
      console.warn(
        "No candidateId, jobId, candidateActionVersion, or withdraw archive uuid when trying to withdraw candidate"
      );
      return;
    }

    const args = {
      jobId,
      args: {
        id: candidateId,
        data: {
          candidateActionVersion: candidateActionVersion,
          decision: SubmitDecisionRequestDecisionEnum.Reject,
          archiveReason: withdrawArchiveId,
          // these next two args are important - customer manually archived the candidate without providing email content
          emailArgs: null,
          auto: false,
        },
      },
    };

    const submitDecisionPromise = submitDecision(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          pending: "Marking as withdrawn...",
          success: "Withdrawn!",
          error: "Error withdrawing",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }, [candidateActionVersion, candidateId, jobId, submitDecision, withdrawArchiveId]);

  return (
    <Button variant={ButtonVariant.Ghost} onClick={clickWithdraw} disabled={disabled} width="100%">
      <Box alignItems="flex-start" display="flex">
        <BodySmall color={colors.grayscale.gray600}>Mark as withdrawn</BodySmall>
      </Box>
    </Button>
  );
}
