/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoverUser
 */
export interface DoverUser {
    /**
     * 
     * @type {number}
     * @memberof DoverUser
     */
    readonly pk?: number;
    /**
     * 
     * @type {number}
     * @memberof DoverUser
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    readonly client?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    readonly emailSenderAliasId?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    readonly gmailCredentialAuthState?: DoverUserGmailCredentialAuthStateEnum;
    /**
     * 
     * @type {object}
     * @memberof DoverUser
     */
    roleTitleData?: object | null;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    readonly freeTrialJobId?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    readonly pictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DoverUser
     */
    phoneNumber?: string | null;
    /**
     * This field is used to determine the corresponding initial setup flow for the user based on their entry point to the app.
     * @type {string}
     * @memberof DoverUser
     */
    initialSetupFlow?: DoverUserInitialSetupFlowEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DoverUser
     */
    blocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoverUser
     */
    setupGuideDismissed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DoverUser
     */
    readonly emailVerified?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum DoverUserGmailCredentialAuthStateEnum {
    IsShellAccount = 'IS_SHELL_ACCOUNT',
    Valid = 'VALID',
    AuthInvalid = 'AUTH_INVALID',
    NeverAuthed = 'NEVER_AUTHED',
    InvalidAuthCredType = 'INVALID_AUTH_CRED_TYPE'
}/**
* @export
* @enum {string}
*/
export enum DoverUserInitialSetupFlowEnum {
    ApplicantSorting = 'APPLICANT_SORTING',
    AtsJobBoards = 'ATS_JOB_BOARDS',
    AtsAddCandidate = 'ATS_ADD_CANDIDATE',
    SourcingAutopilot = 'SOURCING_AUTOPILOT',
    RecruitingCopilot = 'RECRUITING_COPILOT',
    AtsSetup = 'ATS_SETUP',
    AtsWithCall = 'ATS_WITH_CALL',
    EmbeddedRecruiter = 'EMBEDDED_RECRUITER',
    Bypass = 'BYPASS'
}

export function DoverUserFromJSON(json: any): DoverUser {
    return DoverUserFromJSONTyped(json, false);
}

export function DoverUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoverUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pk': !exists(json, 'pk') ? undefined : json['pk'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'emailSenderAliasId': !exists(json, 'email_sender_alias_id') ? undefined : json['email_sender_alias_id'],
        'gmailCredentialAuthState': !exists(json, 'gmail_credential_auth_state') ? undefined : json['gmail_credential_auth_state'],
        'roleTitleData': !exists(json, 'role_title_data') ? undefined : json['role_title_data'],
        'freeTrialJobId': !exists(json, 'free_trial_job_id') ? undefined : json['free_trial_job_id'],
        'pictureUrl': !exists(json, 'picture_url') ? undefined : json['picture_url'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'initialSetupFlow': !exists(json, 'initial_setup_flow') ? undefined : json['initial_setup_flow'],
        'blocked': !exists(json, 'blocked') ? undefined : json['blocked'],
        'setupGuideDismissed': !exists(json, 'setup_guide_dismissed') ? undefined : json['setup_guide_dismissed'],
        'emailVerified': !exists(json, 'email_verified') ? undefined : json['email_verified'],
    };
}

export function DoverUserToJSON(value?: DoverUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'role_title_data': value.roleTitleData,
        'phone_number': value.phoneNumber,
        'initial_setup_flow': value.initialSetupFlow,
        'blocked': value.blocked,
        'setup_guide_dismissed': value.setupGuideDismissed,
    };
}


