/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPersonaForJobRequest
 */
export interface GetPersonaForJobRequest {
    /**
     * 
     * @type {string}
     * @memberof GetPersonaForJobRequest
     */
    jobId: string;
}

export function GetPersonaForJobRequestFromJSON(json: any): GetPersonaForJobRequest {
    return GetPersonaForJobRequestFromJSONTyped(json, false);
}

export function GetPersonaForJobRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPersonaForJobRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobId': json['job_id'],
    };
}

export function GetPersonaForJobRequestToJSON(value?: GetPersonaForJobRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_id': value.jobId,
    };
}


