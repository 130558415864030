import React from "react";

import { Banner, BannerVariant } from "components/library/Banner";
import { BodySmall } from "components/library/typography";
import { appConfig } from "config/appConfig";
import { InternalLink } from "styles/typography";

const InvalidJobBanner = (): React.ReactElement => {
  return (
    <Banner variant={BannerVariant.Critical}>
      <BodySmall>
        This job is not currently set up on Dover so the candidate cannot be added. You can set this job up on Dover at{" "}
        <InternalLink target="_blank" to="/jobs">
          {`${appConfig.appUrl}/jobs`}
        </InternalLink>{" "}
        if you’d like help with this job!
      </BodySmall>
    </Banner>
  );
};

export default InvalidJobBanner;
