import React from "react";

import { Banner, BannerVariant } from "components/library/Banner";
import { Role, useHasRole } from "components/RBAC";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { DoverUser } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { B2_doNotUse } from "styles/typography";

interface ClientPickerWarningBannerProps {
  warningText: string;
}

export const ClientPickerWarningBanner: React.FC<ClientPickerWarningBannerProps> = ({ warningText }) => {
  const { user } = useAuth0();
  const isAdmin = useHasRole(Role.ADMIN);
  const { proUsers } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsers,
        };
      },
    }
  );
  const shouldShowBanner = React.useMemo(() => isAdmin && !proUsers.find(pu => pu.email === user.email), [
    isAdmin,
    proUsers,
    user.email,
  ]);

  if (!shouldShowBanner) {
    return <></>;
  }

  return (
    <Banner variant={BannerVariant.Warning}>
      <B2_doNotUse>{warningText}</B2_doNotUse>
    </Banner>
  );
};
