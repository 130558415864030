import { Box } from "@mui/material";
import React from "react";

import LinkedInDataArchiveReady from "assets/images/linkedin_data_archive_ready.png";
import UploadLinkedinConnectionInstruction from "assets/images/upload-linkedin-connections-instruction.png";
import { ExternalLink } from "components/dover/LinkedInConnectionsUpload/styles";
import { InstructionListItem } from "components/instructions/types";

export const downloadLinkedInConnectionsInstructionItems: Readonly<InstructionListItem[]> = [
  {
    text: (
      <Box>
        {`Go to this link: `}
        <ExternalLink href="https://www.linkedin.com/psettings/member-data" target="_blank" rel="noopener noreferrer">
          https://www.linkedin.com/psettings/member-data
        </ExternalLink>
      </Box>
    ),
  },
  {
    text: <Box>{`Make sure to only select the ‘Connections’ checkbox and click 'Request Archive'.`}</Box>,
    imageUrl: UploadLinkedinConnectionInstruction,
  },
  {
    text: (
      <Box>{`It takes about 10 minutes to generate the file — you'll receive an email from LinkedIn when it's ready.`}</Box>
    ),
    imageUrl: LinkedInDataArchiveReady,
  },
  { text: <Box>{`Come back to this page and upload the file.`}</Box> },
];

export const INITIAL_FORM_VALUES: { files: File[] } = {
  files: [],
};
