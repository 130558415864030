import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { Tooltip } from "components/library/Tooltip";
import { Body } from "components/library/typography";
import CustomModal from "components/Modal";
import { useGetSearchV3DepthResultQuery, useGetSearchV3Query } from "services/doverapi/endpoints/search-v3/endpoints";
import { DepthRequestFiltersEnum, DepthRequestForbiddenReasonsEnum, SearchV3Params } from "services/openapi";
import { colors } from "styles/theme";
import { useSearchId } from "views/sourcing/Search/hooks";
import { searchV3FormSchema, SearchV3FormSchemaType } from "views/sourcing/Search/types";
import { getSearchV3FromFormState } from "views/sourcing/Search/utils";

const FORBIDDEN_TO_DESC: Record<DepthRequestForbiddenReasonsEnum, string> = {
  [DepthRequestForbiddenReasonsEnum.ProfileUnavailable]:
    "Kick out people who match the search but we are no longer able to refresh via LinkedIn.",
  [DepthRequestForbiddenReasonsEnum.NoEmailForProfile]:
    "Kick out people for who we have tried to find emails, but failed to do so.",
  [DepthRequestForbiddenReasonsEnum.Client]:
    "Kick out people who are forbidden for client-scoped forbidden reasons (ie already reached out to this person for another job for this client)",
  [DepthRequestForbiddenReasonsEnum.Job]:
    "Kick out people who are forbidden for job-scoped forbidden reasons (ie already reached out to this person)",
};

const getMustHaveFiltersToDisplay = (v3Params: SearchV3Params): DepthRequestFiltersEnum[] => {
  const mustHaveFilters: DepthRequestFiltersEnum[] = [];
  if (v3Params.targetPersonas?.[0].value?.length) {
    mustHaveFilters.push(DepthRequestFiltersEnum.Persona);
  }
  if (v3Params.targetLocations?.[0]?.value?.length || v3Params.excludedLocations?.[0]?.value?.length) {
    mustHaveFilters.push(DepthRequestFiltersEnum.Location);
  }
  if (
    v3Params.yearsAtCurrentCompanyRange?.[0]?.value ||
    v3Params.totalYearsOfExperience?.[0]?.value ||
    v3Params.specificYearsOfExperience?.[0]?.value ||
    v3Params.specificYearsOfExperienceByT2?.[0]?.value ||
    v3Params.bachelorsGraduationYearRange?.[0]?.value
  ) {
    mustHaveFilters.push(DepthRequestFiltersEnum.YearsOfExperience);
  }

  if (
    !!v3Params.keywords?.find(bucket => bucket.required && bucket.value.length) ||
    v3Params.deniedKeywords?.[0]?.value?.length
  ) {
    mustHaveFilters.push(DepthRequestFiltersEnum.Keywords);
  }
  if (
    v3Params.targetSeniorities?.[0]?.value?.seniorities?.length ||
    v3Params.excludedSeniorities?.[0]?.value?.seniorities?.length
  ) {
    mustHaveFilters.push(DepthRequestFiltersEnum.Seniority);
  }

  if (
    !!v3Params.companySize.find(bucket => bucket.required && bucket.value?.sizes?.length) ||
    v3Params.excludedCompanySize?.[0]?.value?.sizes?.length
  ) {
    mustHaveFilters.push(DepthRequestFiltersEnum.CompanySizes);
  }
  if (v3Params.companyRankRange?.[0]?.value) {
    mustHaveFilters.push(DepthRequestFiltersEnum.CompanyPrestige);
  }

  if (
    !!v3Params.individualTargetCompanyIds?.find(bucket => bucket.required && bucket.value?.length) ||
    v3Params.individualExcludedCompanyIds?.[0]?.value?.length ||
    !!v3Params.targetCompanyLists?.find(bucket => bucket.required && bucket.value?.length) ||
    v3Params.excludedCompanyLists?.[0]?.value?.length
  ) {
    mustHaveFilters.push(DepthRequestFiltersEnum.TargetCompanies);
  }

  if (
    !v3Params.industries?.find(bucket => bucket.required && bucket.value?.length) ||
    v3Params.excludedIndustries?.[0]?.value?.length
  ) {
    mustHaveFilters.push(DepthRequestFiltersEnum.Industries);
  }

  if (
    !!v3Params.targetSchoolLists?.find(bucket => bucket.required && bucket.value?.length) ||
    !!v3Params.individualTargetSchoolIds?.find(bucket => bucket.required && bucket.value?.length) ||
    v3Params.individualExcludedSchoolIds?.[0]?.value?.length ||
    v3Params.excludedSchoolLists?.[0]?.value?.length
  ) {
    mustHaveFilters.push(DepthRequestFiltersEnum.TargetSchools);
  }
  if (v3Params.schoolRankRange?.[0]?.value?.min || (v3Params.schoolRankRange?.[0]?.value?.max ?? 1) < 1) {
    mustHaveFilters.push(DepthRequestFiltersEnum.SchoolPrestige);
  }

  if (v3Params.educationLevel?.find(bucket => bucket.required && bucket.value)) {
    mustHaveFilters.push(DepthRequestFiltersEnum.EducationLevel);
  }
  if (v3Params.fieldsOfStudy?.find(bucket => bucket.required && bucket.value?.length)) {
    mustHaveFilters.push(DepthRequestFiltersEnum.FieldOfStudy);
  }

  if (v3Params.diversity?.[0]?.value) {
    mustHaveFilters.push(DepthRequestFiltersEnum.Diversity);
  }
  mustHaveFilters.push(DepthRequestFiltersEnum.ExcludedEmployees);
  return mustHaveFilters;
};

export const WaterFallModal = ({ open, onClosed }: { open: boolean; onClosed: () => void }): React.ReactElement => {
  const searchId = useSearchId();
  const [filterFilters, setFilterFilters] = React.useState<DepthRequestFiltersEnum[]>([]);
  const [forbiddenFilters, setForbiddenFilters] = React.useState<DepthRequestForbiddenReasonsEnum[]>([]);
  const { control } = useFormContext<SearchV3FormSchemaType>();
  const values = useWatch({ control });
  const { data: search } = useGetSearchV3Query(searchId && open ? { id: searchId } : skipToken);
  const parseResult = searchV3FormSchema.safeParse(values);

  const newSearch = parseResult.success && search ? getSearchV3FromFormState(parseResult.data, search) : undefined;

  const {
    data: waterfallDepthCount,
    isFetching: isWaterFallDepthLoading,
    isUninitialized,
  } = useGetSearchV3DepthResultQuery(
    // technically dont need the debouncedSearchParams check after readyToRequestDepths but typescript too dumb
    open && search && newSearch && newSearch.v3Params
      ? {
          data: {
            params: newSearch.v3Params,
            searchId: search.id,
            filters: filterFilters,
            forbiddenReasons: forbiddenFilters,
          },
        }
      : skipToken
  );

  return (
    <CustomModal open={open} onClose={onClosed} title="Depth Debugger" maxWidth="md">
      <Stack direction="row" justifyContent="space-between" px={4}>
        <Stack direction="row" spacing={4}>
          {!newSearch ? (
            <Body>Could not load search</Body>
          ) : (
            <Stack>
              <Body weight="500">Filters (must haves only)</Body>
              <Checkboxes
                options={getMustHaveFiltersToDisplay(newSearch?.v3Params)}
                values={filterFilters}
                onSelect={setFilterFilters}
              />
            </Stack>
          )}
          <Stack>
            <Body weight="500">Forbidden</Body>
            <Checkboxes
              options={Object.values(DepthRequestForbiddenReasonsEnum)}
              onSelect={setForbiddenFilters}
              values={forbiddenFilters}
              prefix="Exclude"
            />
          </Stack>
        </Stack>
        <Stack
          height="100%"
          alignItems="center"
          justifyContent="center"
          border={`1px solid ${colors.grayscale.gray500}`}
          borderRadius="6px"
          padding={1}
        >
          {isWaterFallDepthLoading || isUninitialized || waterfallDepthCount?.count === undefined ? (
            <Body>Loading...</Body>
          ) : (
            <>
              <Body weight="500">Depth count</Body>
              <Body>{new Intl.NumberFormat().format(waterfallDepthCount?.count)}</Body>
            </>
          )}
        </Stack>
      </Stack>
    </CustomModal>
  );
};

interface CheckboxesProps<T> {
  options: T[];
  values: T[];
  onSelect: React.Dispatch<React.SetStateAction<T[]>>;
  prefix?: string;
}

const Checkboxes = <T extends DepthRequestFiltersEnum | DepthRequestForbiddenReasonsEnum>({
  onSelect,
  options,
  values,
  prefix = "",
}: CheckboxesProps<T>): React.ReactElement => {
  const getTitleCasedFilter = (filter: string): string =>
    filter
      .split("_")
      .join(" ")
      .toLowerCase()
      .replace(/\b\w/g, l => l.toUpperCase());

  const filterWithPrefix = (filter: string): string => {
    const titleClasedFilter = getTitleCasedFilter(filter);
    if (prefix) {
      return `${prefix} '${titleClasedFilter}'`;
    }
    return titleClasedFilter;
  };

  return (
    <>
      {options.map((filter, index) => {
        return (
          <Stack key={index} direction="row" alignItems="center" spacing={1}>
            <input
              type="checkbox"
              checked={values.includes(filter)}
              onChange={(e: any): void => {
                if (e.target.checked) {
                  onSelect(prev => [...prev, filter]);
                } else {
                  onSelect(prev => prev.filter(f => f !== filter));
                }
              }}
            />
            {/* @ts-ignore */}
            <Tooltip title={FORBIDDEN_TO_DESC[filter] ?? ""} placement="top">
              <Body>{filterWithPrefix(filter)}</Body>
            </Tooltip>
          </Stack>
        );
      })}
    </>
  );
};
