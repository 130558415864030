import { Box, Skeleton, Stack } from "@mui/material";
import { isEqual } from "lodash";
import React, { useCallback } from "react";
import styled from "styled-components";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link.svg";
import { BodySmall, Overline } from "components/library/typography";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { NeedsActionFilter, NeedsActionFilters } from "views/candidates/CandidatesReview/constants";
import { useGetNextUpCounts } from "views/candidates/CandidatesReview/hooks";
import { useParams } from "views/candidates/hooks";
import { QuickFilterEnum } from "views/candidates/types";

const ActionFilters = (): React.ReactElement => {
  const jobId = useJobIdFromUrl();
  const { isFetching, counts } = useGetNextUpCounts();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={1}
      width="100%"
      sx={{
        position: "sticky",
        top: 0,
        background: colors.grayscale.gray025,
        padding: "6px 0",
        zIndex: 15,
      }}
    >
      <Stack direction="row" spacing={1} overflow="auto">
        {NeedsActionFilters.map((filter, i) => {
          return <NeedsActionFilterButton filter={filter} key={i} />;
        })}
      </Stack>
      {jobId && (
        <Box>
          <StyledFilterButton>
            <InternalLink to={APP_ROUTE_PATHS.job.applicationReviewV2(jobId)} rel="noopener noreferrer" target="_blank">
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <ExternalLinkIcon className="svg-color" color={colors.grayscale.gray500} width="14px" />
                <FilterButtonText>Applicants</FilterButtonText>
                {isFetching && <Skeleton width="16px" height="100%" />}
                {!isFetching && counts && <CountChip>{counts.get(QuickFilterEnum.Applicants)}</CountChip>}
              </Stack>
            </InternalLink>
          </StyledFilterButton>
        </Box>
      )}
    </Stack>
  );
};

const NeedsActionFilterButton = ({ filter }: { filter: NeedsActionFilter }): React.ReactElement => {
  const [{ quickFilter }, setParams] = useParams();
  const { isFetching, counts } = useGetNextUpCounts();

  const handleSelectFilter = useCallback(
    (filter: NeedsActionFilter): void => {
      setParams({ quickFilter: filter.quickFilter, page: 0 });
    },
    [setParams]
  );

  const isActive = isEqual(quickFilter, filter.quickFilter);

  return (
    <StyledFilterButton
      isActive={isActive}
      onClick={(): void => handleSelectFilter(filter)}
      direction="row"
      spacing={1}
    >
      <FilterButtonText isActive={isActive}>{filter.label}</FilterButtonText>
      {isFetching && <Skeleton width="16px" height="100%" />}
      {!isFetching && counts && <CountChip isActive={isActive}>{counts.get(filter.quickFilter)}</CountChip>}
    </StyledFilterButton>
  );
};

interface StyledFilterButtonProps {
  isActive?: boolean;
}

const StyledFilterButton = styled(Stack)<StyledFilterButtonProps>`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px 8px;
  background: ${({ isActive }): string => (isActive ? colors.primary.light : "none")};
  border: 1px solid white;
  border-color: ${({ isActive }): string => (isActive ? colors.primary.base : colors.grayscale.gray200)};

  border-radius: 6px;

  &:hover {
    background: ${colors.primary.light};
    border-color: ${colors.primary.base};
  }
`;

const FilterButtonText = styled(BodySmall)<StyledFilterButtonProps>`
  color: ${({ isActive }): string => (isActive ? colors.black : colors.grayscale.gray500)};
  font-weight: ${({ isActive }): string => (isActive ? "600" : "400")};
  line-height: 20px;
  white-space: nowrap;

  &:hover {
    color: colors.black;
  }
`;

const CountChip = styled(Overline)<StyledFilterButtonProps>`
  font-weight: 600;
  padding: 0px 6px;
  border-radius: 4px;
  background: ${({ isActive }): string => (isActive ? colors.primary.base : colors.grayscale.gray200)};
  color: ${({ isActive }): string => (isActive ? colors.white : colors.grayscale.gray500)};
`;

export default ActionFilters;
