import { Divider, Stack } from "@mui/material";
import React from "react";

import { SaapReviewApplication } from "services/openapi";
import { ApplicantName } from "views/candidates/DoverTalentNetwork/components/MainPanel/ApplicantInfo/ApplicantName";
import { ContactInfo } from "views/candidates/DoverTalentNetwork/components/MainPanel/ApplicantInfo/ContactInfo";

interface ApplicantInfoProps {
  application: SaapReviewApplication;
}

export const ApplicantInfo: React.FC<ApplicantInfoProps> = ({ application }) => {
  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        <ApplicantName fullName={application.fullName} />
      </Stack>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        divider={<Divider orientation="vertical" sx={{ height: "10px", mr: "4px" }} />}
      >
        <ContactInfo application={application} />
      </Stack>
    </Stack>
  );
};
