import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as LockIcon } from "@doverhq/dover-ui/icons/lock.svg";
import { Stack, Tooltip } from "@mui/material";
import React, { FC } from "react";

import { BodySmall } from "components/library/typography";
import { HiringPipelineStage } from "services/openapi";
import { colors } from "styles/theme";
import { StageName } from "views/job/JobSetup/steps/Scheduling/InterviewPlan/components/StageParts";

interface OfferStageRowProps {
  stage: HiringPipelineStage;
}

export const OfferStageRow: FC<OfferStageRowProps> = ({ stage }) => {
  return (
    <Tooltip title="The final stage in all interview plans is required to be an Offer stage. This stage is not editable.">
      <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Icon Icon={LockIcon} color="gray-500" />
          <StageName name={stage.name} />
        </Stack>
        <BodySmall color={colors.grayscale.gray400}>Not editable</BodySmall>
      </Stack>
    </Tooltip>
  );
};
