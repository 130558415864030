import { Box, Stack } from "@mui/material";
import React from "react";

import { Button, ButtonVariant } from "components/library/Button";
import { Body, ButtonText, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { useSubmitRescheduleInterviewMutation } from "services/doverapi/endpoints/candidate";
import { ShortenedCandidate } from "services/openapi";
import {
  rescheduleInterviewEmailBodyHtml,
  rescheduleInterviewEmailSubjectHtml,
} from "views/admin/RecentInterviews/constants";

interface ConfirmCancelInterviewModalProps {
  open: boolean;
  onClose: () => void;
  candidate: ShortenedCandidate | undefined;
  modalText: string;
}

const ConfirmCancelInterviewModal = ({
  open,
  onClose,
  candidate,
  modalText,
}: ConfirmCancelInterviewModalProps): React.ReactElement => {
  const [submitRescheduleRequest] = useSubmitRescheduleInterviewMutation();

  const handleCancelInterview = async (candidate: ShortenedCandidate | undefined): Promise<void> => {
    if (!candidate || !candidate.id || !candidate.currentPipelineStageId) {
      return;
    }
    await submitRescheduleRequest({
      hiringPipelineStageId: candidate.currentPipelineStageId,
      candidateId: candidate.id,
      emailArgs: {
        subjectHtml: rescheduleInterviewEmailSubjectHtml,
        bodyHtml: rescheduleInterviewEmailBodyHtml,
        ccEmails: [],
        bccEmails: [],
        delay: 0,
      },
    });
    onClose();
  };

  if (!candidate?.interviewId) {
    return (
      <CustomModal open={open} title={<Subtitle2>Cancel Interview?</Subtitle2>} onClose={onClose} maxWidth="sm">
        <Box textAlign="center">
          <Body>Cannot find interview to cancel</Body>
        </Box>
      </CustomModal>
    );
  }

  return (
    <CustomModal
      open={open}
      title={<Subtitle2>Cancel Interview?</Subtitle2>}
      onClose={onClose}
      maxWidth="sm"
      dialogActions={
        <>
          <Stack direction="row" spacing={1}>
            <Button
              onClick={(): void => {
                handleCancelInterview(candidate);
              }}
              variant={ButtonVariant.Secondary}
            >
              <ButtonText>Yes, cancel interview and notify candidate</ButtonText>
            </Button>
            <Button onClick={onClose} variant={ButtonVariant.Primary}>
              <ButtonText>No, keep interview as is</ButtonText>
            </Button>
          </Stack>
        </>
      }
    >
      <Body>{modalText}</Body>
    </CustomModal>
  );
};

export default ConfirmCancelInterviewModal;
