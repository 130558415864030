/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanySizeRange,
    CompanySizeRangeFromJSON,
    CompanySizeRangeFromJSONTyped,
    CompanySizeRangeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompanySizeValue
 */
export interface CompanySizeValue {
    /**
     * 
     * @type {Array<CompanySizeRange>}
     * @memberof CompanySizeValue
     */
    sizes: Array<CompanySizeRange>;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySizeValue
     */
    mostRecentOnly?: boolean;
}

export function CompanySizeValueFromJSON(json: any): CompanySizeValue {
    return CompanySizeValueFromJSONTyped(json, false);
}

export function CompanySizeValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySizeValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sizes': ((json['sizes'] as Array<any>).map(CompanySizeRangeFromJSON)),
        'mostRecentOnly': !exists(json, 'most_recent_only') ? undefined : json['most_recent_only'],
    };
}

export function CompanySizeValueToJSON(value?: CompanySizeValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sizes': ((value.sizes as Array<any>).map(CompanySizeRangeToJSON)),
        'most_recent_only': value.mostRecentOnly,
    };
}


