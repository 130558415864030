import FormControlLabel from "@mui/material/FormControlLabel";
import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  .t-cell {
    outline: none !important;
    background-color: ${(props): string => props.theme.backgrounds.white};
    font-weight: ${(props): number => props.theme.body.b1.fontWeight};
    font-family: ${(props): string => props.theme.body.b1.fontFamily};
    font-size: ${(props): string => props.theme.body.b1.fontSize};
  }

  .h-cell {
    outline: none !important;
    background-color: ${(props): string => props.theme.colors.grayscale.gray100};
    .MuiDataGrid-columnHeaderTitle {
      font-weight: ${(props): number => props.theme.headers.h3.fontWeight};
      font-family: ${(props): string => props.theme.headers.h3.fontFamily};
    }
  }

  .MuiDataGrid-columnSeparator {
    visibility: hidden !important;
  }
`;

export const SwitchLabelControl = styled(FormControlLabel)`
  .MuiSwitch-root {
    width: 48px;
    height: 26px;
    padding: 0;
  }

  .MuiSwitch-switchBase {
    padding: 0;
    margin: 4px;
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${(props): string => props.theme.backgrounds.white};
  }

  .MuiSwitch-track {
    border-radius: 13px;
    background-color: ${(props): string => props.theme.colors.grayscale.gray200};
    opacity: 1;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${(props): string => props.theme.colors.primary.base};
    opacity: 1;
  }

  .MuiSwitch-thumb {
    color: ${(props): string => props.theme.backgrounds.white};
    box-sizing: border-box;
    width: 18px;
    height: 18px;
  }
`;
