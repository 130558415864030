import { skipToken } from "@reduxjs/toolkit/query";
import { sortBy, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";

import { SelectOption } from "components/AtsSettings/types";
import { reformatOptions } from "components/AtsSettings/utils";
import { useGetAshbyDataQuery } from "services/doverapi/endpoints/ashbyData";
import { useGetClientOnboardingQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetGreenhouseDataQuery } from "services/doverapi/endpoints/greenhouseData";
import { useGetLeverDataQuery } from "services/doverapi/endpoints/leverData";
import { AtsData, AtsJob, AtsUser, ClientOnboarding, ClientOnboardingAtsTypeEnum } from "services/openapi";

function useClientAtsData(): {
  client: ClientOnboarding | undefined;
  leverData: AtsData | undefined;
  greenhouseData: AtsData | undefined;
  ashbyData: AtsData | undefined;
} {
  const { data: client } = useGetClientOnboardingQuery();
  const { data: leverData } = useGetLeverDataQuery(
    client?.atsType === ClientOnboardingAtsTypeEnum.Lever ? undefined : skipToken
  );
  const { data: greenhouseData } = useGetGreenhouseDataQuery(
    client?.atsType === ClientOnboardingAtsTypeEnum.Greenhouse ? {} : skipToken
  );

  const { data: ashbyData } = useGetAshbyDataQuery(
    client?.atsType === ClientOnboardingAtsTypeEnum.Ashby ? {} : skipToken
  );

  return { client, leverData, greenhouseData, ashbyData };
}

export function useAtsJobs(): SelectOption[] {
  const { client, leverData, greenhouseData, ashbyData } = useClientAtsData();

  return useMemo(() => {
    let rawAtsJobs: AtsJob[] | undefined;
    const displayIds = client?.atsType === ClientOnboardingAtsTypeEnum.Greenhouse;
    switch (client?.atsType) {
      case ClientOnboardingAtsTypeEnum.Greenhouse:
        rawAtsJobs = greenhouseData?.jobs;
        break;
      case ClientOnboardingAtsTypeEnum.Lever:
        rawAtsJobs = leverData?.jobs;
        break;
      case ClientOnboardingAtsTypeEnum.Ashby:
        rawAtsJobs = ashbyData?.jobs;
        break;
    }

    if (rawAtsJobs) {
      return sortBy(reformatOptions(rawAtsJobs, displayIds), "label");
    }
    return [{ label: "", value: "" }];
  }, [client?.atsType, leverData?.jobs, greenhouseData?.jobs, ashbyData?.jobs]);
}

export function useAtsUsers(): SelectOption[] {
  const { client, leverData, greenhouseData } = useClientAtsData();

  return useMemo(() => {
    let rawAtsUsers: AtsUser[] | undefined;
    switch (client?.atsType) {
      case ClientOnboardingAtsTypeEnum.Greenhouse:
        rawAtsUsers = greenhouseData?.users;
        break;
      case ClientOnboardingAtsTypeEnum.Lever:
        rawAtsUsers = leverData?.users;
        break;
    }

    if (rawAtsUsers) {
      return sortBy(reformatOptions(rawAtsUsers), "label");
    }
    return [{ label: "", value: "" }];
  }, [client?.atsType, leverData?.users, greenhouseData?.users]);
}

function useAtsDataSiteAdmins(): SelectOption[] {
  const { client, leverData, greenhouseData, ashbyData } = useClientAtsData();

  return useMemo(() => {
    if (client?.atsType === ClientOnboardingAtsTypeEnum.Greenhouse && greenhouseData) {
      return reformatOptions(greenhouseData.siteAdmins);
    } else if (client?.atsType === ClientOnboardingAtsTypeEnum.Lever && leverData) {
      return reformatOptions(leverData.siteAdmins);
    } else if (client?.atsType === ClientOnboardingAtsTypeEnum.Ashby && ashbyData) {
      return reformatOptions(ashbyData.siteAdmins);
    }
    return [{ label: "", value: "" }];
  }, [client?.atsType, leverData, greenhouseData, ashbyData]);
}

export function useGetBehalfUsers(): (atsJobId: string) => SelectOption[] {
  const { client, leverData } = useClientAtsData();
  const atsDataSiteAdmins = useAtsDataSiteAdmins();

  return useCallback(
    (atsJobId: string): SelectOption[] => {
      if (client?.atsType === ClientOnboardingAtsTypeEnum.Lever) {
        const job = leverData?.jobs.find(j => j.id === atsJobId) || { owners: [] };
        return sortBy(uniqBy([...reformatOptions(job?.owners), ...atsDataSiteAdmins], "value"), ["label", "value"]);
      } else if (client?.atsType === ClientOnboardingAtsTypeEnum.Greenhouse) {
        return sortBy(uniqBy(atsDataSiteAdmins, "value"), ["label", "value"]);
      } else if (client?.atsType === ClientOnboardingAtsTypeEnum.Ashby) {
        return sortBy(uniqBy(atsDataSiteAdmins, "value"), ["label", "value"]);
      }
      return [];
    },
    [atsDataSiteAdmins, client?.atsType, leverData?.jobs]
  );
}
