/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddCandidateInitialOutreachResponse
 */
export interface AddCandidateInitialOutreachResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AddCandidateInitialOutreachResponse
     */
    readonly success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddCandidateInitialOutreachResponse
     */
    readonly message?: string;
    /**
     * 
     * @type {string}
     * @memberof AddCandidateInitialOutreachResponse
     */
    readonly candidate?: string;
}

export function AddCandidateInitialOutreachResponseFromJSON(json: any): AddCandidateInitialOutreachResponse {
    return AddCandidateInitialOutreachResponseFromJSONTyped(json, false);
}

export function AddCandidateInitialOutreachResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddCandidateInitialOutreachResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'candidate': !exists(json, 'candidate') ? undefined : json['candidate'],
    };
}

export function AddCandidateInitialOutreachResponseToJSON(value?: AddCandidateInitialOutreachResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


