/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewEvent,
    InterviewEventFromJSON,
    InterviewEventFromJSONTyped,
    InterviewEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface FrontCalendarEvent
 */
export interface FrontCalendarEvent {
    /**
     * 
     * @type {Date}
     * @memberof FrontCalendarEvent
     */
    start?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FrontCalendarEvent
     */
    summary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontCalendarEvent
     */
    readonly statusText?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontCalendarEvent
     */
    description?: string | null;
    /**
     * 
     * @type {InterviewEvent}
     * @memberof FrontCalendarEvent
     */
    interviewEvent?: InterviewEvent;
}

export function FrontCalendarEventFromJSON(json: any): FrontCalendarEvent {
    return FrontCalendarEventFromJSONTyped(json, false);
}

export function FrontCalendarEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontCalendarEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': !exists(json, 'start') ? undefined : (json['start'] === null ? null : new Date(json['start'])),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'statusText': !exists(json, 'status_text') ? undefined : json['status_text'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'interviewEvent': !exists(json, 'interview_event') ? undefined : InterviewEventFromJSON(json['interview_event']),
    };
}

export function FrontCalendarEventToJSON(value?: FrontCalendarEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start === undefined ? undefined : (value.start === null ? null : value.start.toISOString()),
        'summary': value.summary,
        'description': value.description,
        'interview_event': InterviewEventToJSON(value.interviewEvent),
    };
}


