/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ContactedSubstageEnum {
    BASE = 0,
    OUTREACH_1_FOLLOW_UP_1 = 100,
    OUTREACH_1_FOLLOW_UP_2 = 200,
    OUTREACH_1_FOLLOW_UP_3 = 300,
    OUTREACH_1_FOLLOW_UP_4 = 400,
    SENT_2 = 500,
    OUTREACH_2_FOLLOW_UP_1 = 600,
    OUTREACH_2_FOLLOW_UP_2 = 700,
    OUTREACH_2_FOLLOW_UP_3 = 800,
    OUTREACH_2_FOLLOW_UP_4 = 900
}

export function ContactedSubstageEnumFromJSON(json: any): ContactedSubstageEnum {
    return ContactedSubstageEnumFromJSONTyped(json, false);
}

export function ContactedSubstageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactedSubstageEnum {
    return json as ContactedSubstageEnum;
}

export function ContactedSubstageEnumToJSON(value?: ContactedSubstageEnum | null): any {
    return value as any;
}

