import { Button } from "@doverhq/dover-ui";
import { Menu, Stack } from "@mui/material";
import { PressEvent } from "@react-types/shared/src/";
import { useDetectAdBlock } from "adblock-detect-react";
import React, { FC, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useToggle } from "react-use";
import { BooleanParam, useQueryParam } from "use-query-params";

import { HELP_CENTER_URL } from "App/appConstants";
import { INVITE_TEAM_QUERY_PARAM } from "App/components/Nav/constants";
import InviteNewUsersModal from "App/components/Nav/InviteNewUsersModal";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { CompanySetupSectionType } from "App/routing/types";
import { ReactComponent as HelpIcon } from "assets/icons/help-question.svg";
import { ReactComponent as SiridIcon } from "assets/icons/sirid.svg";
import { ReactComponent as UserPlusIcon } from "assets/icons/user-plus.svg";
import { useGetCalendlyUrl } from "components/dover/hooks/useCalendlyUrl";
import { BodySmall } from "components/library/typography";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { ClientDoverPlanEnum } from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";

export const SideNavHelp: FC = () => {
  const adBlockDetected = useDetectAdBlock();
  const { data: client } = useGetUsersClientQuery();
  const navigate = useNavigate();

  const doverPlan = useGetDoverPlan();
  const isUsingJobSlots = doverPlan === ClientDoverPlanEnum.JobSlots;

  const supportEmail = isUsingJobSlots && client?.csm ? client.csm.email : "support@dover.com";

  const [helpMenuAnchor, setHelpMenuAnchor] = React.useState<Element | null>(null);
  const [chatOpen, toggleChatOpen] = useToggle(false);
  const calendlyUrl = useGetCalendlyUrl("app", "left_nav", "book_a_call");

  // 5px margin bottom is a gross hack to have the header border align with the
  // bottom of the top nav
  const [inviteNewUsersModalOpen, setInviteNewUsersModalOpen] = useQueryParam(INVITE_TEAM_QUERY_PARAM, BooleanParam);
  const openInviteNewUsersModal = (): void => {
    setInviteNewUsersModalOpen(true);
  };

  const closeInviteNewUsersModal = (): void => {
    setInviteNewUsersModalOpen(false);
  };

  const handleInviteTeammatesClick = (): void => {
    navigate(APP_ROUTE_PATHS.companySetup(CompanySetupSectionType.MEMBERS));
    openInviteNewUsersModal();
  };

  const toggleChat = useCallback(
    (nextVal?: any): void => {
      if (adBlockDetected) {
        showErrorToast("Please disable your ad blocker to use chat support.");
        return;
      }
      toggleChatOpen(nextVal);
    },
    [adBlockDetected, toggleChatOpen]
  );

  const openHelpMenu = (e: PressEvent): void => {
    setHelpMenuAnchor(e.target);
  };

  const closeHelpMenu = (): void => {
    setHelpMenuAnchor(null);
  };

  const clickHelpCenter = (): void => {
    window.open(HELP_CENTER_URL, "_blank", "noopener noreferrer");
  };

  // If should be open, unhide and go to home. Add onHide event listener.
  // If should be closed, hide.
  // This doesn't seem to work on localhost, but it does work in production
  useEffect(() => {
    const Intercom = (window as any).Intercom;
    if (Intercom) {
      if (!chatOpen) {
        Intercom("hide");
      } else {
        Intercom("update", {
          hide_default_launcher: false,
        });
        Intercom("onHide", () => {
          Intercom("update", {
            hide_default_launcher: true,
          });

          // Adjust state if they hide using the launcher
          if (chatOpen) {
            toggleChat(false);
          }
        });
        Intercom("showSpace", "home");
      }
    }
  }, [chatOpen, toggleChat]);

  return (
    <>
      <InviteNewUsersModal open={!!inviteNewUsersModalOpen} onClose={closeInviteNewUsersModal} />

      <Menu
        open={Boolean(helpMenuAnchor)}
        onClose={closeHelpMenu}
        onClick={closeHelpMenu}
        anchorEl={helpMenuAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <a
          href={`mailto:${supportEmail}?subject=Support%20Request`}
          target="_blank"
          style={{ textDecoration: "none" }}
          rel="noreferrer"
        >
          <Button variant="ghost" w="full">
            {isUsingJobSlots ? "Contact your Recruiting Partner" : "Email Support"}
          </Button>
        </a>
        <Button variant="ghost" onPress={clickHelpCenter} w="full">
          Help Center
        </Button>
      </Menu>

      <Stack
        justifyContent="space-evenly"
        spacing={0.25}
        width="100%"
        sx={{
          button: {
            width: "100% !important",
            textAlign: "start",
          },
        }}
      >
        <Button
          variant="ghost"
          p={0.5}
          icon={{ Icon: SiridIcon, color: "transparent" }}
          onPress={(): void => {
            window.open(calendlyUrl, "_blank", "noopener noreferrer");
          }}
        >
          <BodySmall color={colors.grayscale.gray600}>Talk to a recruiting expert</BodySmall>
        </Button>
        <Button variant="ghost" p={0.5} icon={{ Icon: HelpIcon, color: "transparent" }} onPress={openHelpMenu}>
          <BodySmall color={colors.grayscale.gray600}>Get help</BodySmall>
        </Button>
        <Button variant="ghost" p={0.5} icon={{ Icon: UserPlusIcon }} onPress={handleInviteTeammatesClick}>
          <BodySmall color={colors.grayscale.gray600}>Invite your team</BodySmall>
        </Button>
      </Stack>
    </>
  );
};
