/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActivityFeedEmailSenderAliasSerializer,
    ActivityFeedEmailSenderAliasSerializerFromJSON,
    ActivityFeedEmailSenderAliasSerializerFromJSONTyped,
    ActivityFeedEmailSenderAliasSerializerToJSON,
} from './';

/**
 * 
 * @export
 * @interface ActivityFeedEmailParticipantSerializer
 */
export interface ActivityFeedEmailParticipantSerializer {
    /**
     * 
     * @type {ActivityFeedEmailSenderAliasSerializer}
     * @memberof ActivityFeedEmailParticipantSerializer
     */
    emailSenderAlias?: ActivityFeedEmailSenderAliasSerializer;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedEmailParticipantSerializer
     */
    threadId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedEmailParticipantSerializer
     */
    emailEventId?: string;
}

export function ActivityFeedEmailParticipantSerializerFromJSON(json: any): ActivityFeedEmailParticipantSerializer {
    return ActivityFeedEmailParticipantSerializerFromJSONTyped(json, false);
}

export function ActivityFeedEmailParticipantSerializerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityFeedEmailParticipantSerializer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailSenderAlias': !exists(json, 'email_sender_alias') ? undefined : ActivityFeedEmailSenderAliasSerializerFromJSON(json['email_sender_alias']),
        'threadId': !exists(json, 'thread_id') ? undefined : json['thread_id'],
        'emailEventId': !exists(json, 'email_event_id') ? undefined : json['email_event_id'],
    };
}

export function ActivityFeedEmailParticipantSerializerToJSON(value?: ActivityFeedEmailParticipantSerializer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_sender_alias': ActivityFeedEmailSenderAliasSerializerToJSON(value.emailSenderAlias),
        'thread_id': value.threadId,
        'email_event_id': value.emailEventId,
    };
}


