import { Box, CircularProgress, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import { FileDropzone, nameLengthValidator, useOnDropRejected } from "components/FileDropzone";
import { ClickableFileViewer } from "components/FileViewer";
import { ButtonVariant } from "components/library/Button";
import { MenuButton } from "components/MenuButton";
import {
  useDeleteCandidateFileMutation,
  useGetCandidateBioQuery,
  useListCandidateFilesQuery,
  useUploadCandidateFileMutation,
} from "services/doverapi/endpoints/candidate/candidate-detail-endpoints";
import { useInboundApplicationApiQuery } from "services/doverapi/endpoints/inboundApplication";
import { CandidateFilesUploadedContextEnum, CreateCandidateFileUploadedContextEnum } from "services/openapi";
import { colors } from "styles/theme";
import { FilterButton } from "views/CandidateDetail/components/EventFeed";
import { ApplicationAnswersTab } from "views/candidates/ApplicationReview/components/MainPanel/ApplicationAnswersTab";

enum TabOptionsEnum {
  ApplicationQuestion = "applicationQuestions",
  Resume = "resume",
}

export const ResumeTab = ({ candidateId }: { candidateId: string }): ReactElement => {
  const [selectedTab, setSelectedTab] = React.useState<TabOptionsEnum>(TabOptionsEnum.Resume);

  const [uploadFile] = useUploadCandidateFileMutation();
  const [deleteFile] = useDeleteCandidateFileMutation();

  const { data: files, isFetching } = useListCandidateFilesQuery({ candidateId });
  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);
  const { data: application, isFetching: isFetchingApplication } = useInboundApplicationApiQuery(
    candidateBio?.inboundApplicationId ?? skipToken
  );

  const resume = files?.find(file => file.uploadedContext === CandidateFilesUploadedContextEnum.Resume);

  const onDrop = useCallback(
    (acceptedFiles: File[]): void => {
      // This function still runs even if all files were rejeted
      if (acceptedFiles.length === 0) {
        return;
      }

      if (resume?.id) {
        deleteFile({ id: resume.id });
      }

      uploadFile({
        candidate: candidateId,
        uploadedContext: CreateCandidateFileUploadedContextEnum.Resume,
        fileContents: acceptedFiles[0],
        name: acceptedFiles[0]?.name,
      });
    },
    [candidateId, deleteFile, resume?.id, uploadFile]
  );

  const dropzoneOptions = useMemo(
    () => ({
      onDrop,
      maxFiles: 1,
      noClick: true,
      noDrag: true,
      noKeyboard: true,
      validator: nameLengthValidator,
    }),
    [onDrop]
  );

  const onDropRejected = useOnDropRejected(dropzoneOptions);

  const { getRootProps, getInputProps, open } = useDropzone({
    ...dropzoneOptions,
    onDropRejected,
  });

  if (isFetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" marginTop="3em !important">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Stack direction="row" spacing={2} mb={2}>
        <FilterButton onClick={(): void => setSelectedTab(TabOptionsEnum.Resume)} isActive={selectedTab === "resume"}>
          Resume
        </FilterButton>
        <FilterButton
          onClick={(): void => setSelectedTab(TabOptionsEnum.ApplicationQuestion)}
          isActive={selectedTab === TabOptionsEnum.ApplicationQuestion}
        >
          Application Questions
        </FilterButton>
      </Stack>
      {selectedTab === "resume" && (
        <>
          {resume ? (
            <Box position="relative" height="900px">
              <Box sx={{ position: "absolute", top: "20px", right: "20px", zIndex: "500" }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <MenuButton
                    variant={ButtonVariant.Secondary}
                    label="..."
                    options={[
                      {
                        onClick: (): void => {
                          open();
                        },
                        label: "Replace",
                      },
                      {
                        onClick: (): void => {
                          if (!resume?.fileContents) {
                            return;
                          }
                          window.open(resume?.fileContents);
                        },
                        label: "Download",
                      },
                      {
                        onClick: (): void => {
                          if (!resume?.id) {
                            return;
                          }

                          deleteFile({ id: resume.id });
                        },
                        label: "Delete",
                        labelColor: colors.critical.base,
                      },
                    ]}
                  />
                </div>
              </Box>
              <ClickableFileViewer
                url={resume?.fileContents}
                iframeHeight="900px"
                iframeContainerHeight="90vh"
                noFileText="No resume provided"
              />
            </Box>
          ) : (
            <FileDropzone options={dropzoneOptions} />
          )}
        </>
      )}
      {selectedTab === "applicationQuestions" && application && !isFetchingApplication && (
        <ApplicationAnswersTab
          application={{
            cleanFirstName: candidateBio?.contact?.preferredName,
            additionalInfo: application?.additionalInfo,
            applicationQuestions: application.applicationQuestions,
          }}
        />
      )}
    </>
  );
};
