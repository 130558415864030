import { Icon } from "@doverhq/dover-ui";
import Stack from "@mui/material/Stack";
import React from "react";

import { ReactComponent as RestrictedIcon } from "assets/icons/restricted.svg";
import { Body, Heading } from "components/library/typography";

const RestrictedAccess = (): React.ReactElement => {
  return (
    <Stack direction="column" alignItems="center" justifyContent="center" spacing={1}>
      <Icon Icon={RestrictedIcon} color="white" size={25} />
      <Heading weight="600">Restricted access</Heading>
      <Body>
        Please contact your team admin or{" "}
        <a href="mailto:support@dover.com" target="_top">
          Dover Support
        </a>
      </Body>
    </Stack>
  );
};

export default RestrictedAccess;
