import { z } from "zod";

export const EditCareersPageSchema = z.object({
  image: z.union([z.instanceof(Blob), z.string(), z.null()]).optional(),
  companyInfo: z.string().optional(),
  logo: z.union([z.instanceof(Blob), z.string(), z.null()]).optional(),
  // TODO: implement better url validation -- zod accepts strings that are not valid urls and our backend rejects them
  companyUrl: z
    .string()
    .url("Invalid url. Please use this format: https://yourcompany.com")
    .optional(),
  companySlug: z
    .string()
    .max(50, "slugs must be less than 50 characters")
    .refine(value => /^$|^[a-z0-9-]+$/.test(value ?? ""), {
      message: "slugs can only contain lower-case letters, numbers, and hyphens",
    })
    .optional(),
});

export type EditCareersPageSchemaFormType = z.infer<typeof EditCareersPageSchema>;

export const GENERATE_CAREERS_PAGE_CACHE_KEY = "GENERATE_CAREERS_PAGE_CACHE_KEY";

export const ImportCareersPageSchema = z.object({
  companyUrl: z.string().url("Invalid url. Please use this format: https://yourcompany.com"),
});

export type ImportCareersPageSchemaFormType = z.infer<typeof ImportCareersPageSchema>;
