import { SnoozeJobSnoozedReasonEnum } from "services/openapi";

type SnoozeOptions = { [key in SnoozeJobSnoozedReasonEnum]: Readonly<string> };
export const snoozeOptions: Readonly<SnoozeOptions> = {
  [SnoozeJobSnoozedReasonEnum.OutOfOffice]: "I'm going to be out of office",
  [SnoozeJobSnoozedReasonEnum.HasCandidatesInProcess]: "I already have candidates in the process",
  [SnoozeJobSnoozedReasonEnum.DontNeedToHireForThisJob]: "I'm not sure if we need to hire this person any more",
  [SnoozeJobSnoozedReasonEnum.NeedToRecalibrateSearch]: "Candidates are not a good fit / I need to recalibrate",
  [SnoozeJobSnoozedReasonEnum.Other]: "I'm pausing this search for a different reason",
};
