/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BenchmarkChannelPersonaStats
 */
export interface BenchmarkChannelPersonaStats {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkChannelPersonaStats
     */
    readonly customerFacingName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkChannelPersonaStats
     */
    readonly inboundPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkChannelPersonaStats
     */
    readonly outboundPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkChannelPersonaStats
     */
    readonly referralsPercentage?: number;
}

export function BenchmarkChannelPersonaStatsFromJSON(json: any): BenchmarkChannelPersonaStats {
    return BenchmarkChannelPersonaStatsFromJSONTyped(json, false);
}

export function BenchmarkChannelPersonaStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BenchmarkChannelPersonaStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerFacingName': !exists(json, 'customer_facing_name') ? undefined : json['customer_facing_name'],
        'inboundPercentage': !exists(json, 'inbound_percentage') ? undefined : json['inbound_percentage'],
        'outboundPercentage': !exists(json, 'outbound_percentage') ? undefined : json['outbound_percentage'],
        'referralsPercentage': !exists(json, 'referrals_percentage') ? undefined : json['referrals_percentage'],
    };
}

export function BenchmarkChannelPersonaStatsToJSON(value?: BenchmarkChannelPersonaStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


