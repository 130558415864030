/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonaTier2Titles
 */
export interface PersonaTier2Titles {
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonaTier2Titles
     */
    tier2Titles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonaTier2Titles
     */
    tier2TitleIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PersonaTier2Titles
     */
    mostRecentOnly: boolean;
}

export function PersonaTier2TitlesFromJSON(json: any): PersonaTier2Titles {
    return PersonaTier2TitlesFromJSONTyped(json, false);
}

export function PersonaTier2TitlesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonaTier2Titles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tier2Titles': json['tier2_titles'],
        'tier2TitleIds': json['tier2_title_ids'],
        'mostRecentOnly': json['most_recent_only'],
    };
}

export function PersonaTier2TitlesToJSON(value?: PersonaTier2Titles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tier2_titles': value.tier2Titles,
        'tier2_title_ids': value.tier2TitleIds,
        'most_recent_only': value.mostRecentOnly,
    };
}


