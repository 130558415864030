/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonaQuestionnaire
 */
export interface PersonaQuestionnaire {
    /**
     * 
     * @type {string}
     * @memberof PersonaQuestionnaire
     */
    readonly id?: string;
    /**
     * 
     * @type {object}
     * @memberof PersonaQuestionnaire
     */
    readonly formConfig?: object;
    /**
     * 
     * @type {string}
     * @memberof PersonaQuestionnaire
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonaQuestionnaire
     */
    readonly personaName?: string;
}

export function PersonaQuestionnaireFromJSON(json: any): PersonaQuestionnaire {
    return PersonaQuestionnaireFromJSONTyped(json, false);
}

export function PersonaQuestionnaireFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonaQuestionnaire {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'formConfig': !exists(json, 'form_config') ? undefined : json['form_config'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'personaName': !exists(json, 'persona_name') ? undefined : json['persona_name'],
    };
}

export function PersonaQuestionnaireToJSON(value?: PersonaQuestionnaire | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


