import { Grid, MenuItem, Select } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { useListJobsQuery } from "services/doverapi/endpoints/job";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";

export const JobPicker = (): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId } = useParams<{ jobId: string }>();

  const { data: jobsState } = useListJobsQuery({ active: "true" });
  const jobs = useMemo(() => listAllEntities(jobsState), [jobsState]);

  const [value, setValue] = useState(jobId);

  return (
    <Grid container>
      <Select
        sx={{
          "&.MuiInputBase-root": {
            padding: "8px",
          },
        }}
        value={value}
        onChange={(e): void => {
          setValue(e.target.value as string);
          navigate(APP_ROUTE_PATHS.admin.jobEmailTemplates(e.target.value));
        }}
      >
        {jobs.map(job => (
          <MenuItem
            sx={{
              "&.MuiButtonBase-root": {
                display: "flex",
                justifyContent: "start",
                padding: "8px",
              },
            }}
            value={job.id}
          >
            {job.title}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
