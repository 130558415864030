/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterviewSubstageEnum,
    InterviewSubstageEnumFromJSON,
    InterviewSubstageEnumFromJSONTyped,
    InterviewSubstageEnumToJSON,
    Job,
    JobFromJSON,
    JobFromJSONTyped,
    JobToJSON,
    ShortenedContact,
    ShortenedContactFromJSON,
    ShortenedContactFromJSONTyped,
    ShortenedContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShortenedCandidate
 */
export interface ShortenedCandidate {
    /**
     * 
     * @type {string}
     * @memberof ShortenedCandidate
     */
    readonly id?: string;
    /**
     * 
     * @type {Job}
     * @memberof ShortenedCandidate
     */
    job?: Job;
    /**
     * 
     * @type {ShortenedContact}
     * @memberof ShortenedCandidate
     */
    contact?: ShortenedContact;
    /**
     * 
     * @type {InterviewSubstageEnum}
     * @memberof ShortenedCandidate
     */
    currentPipelineSubstage: InterviewSubstageEnum;
    /**
     * 
     * @type {string}
     * @memberof ShortenedCandidate
     */
    readonly interviewId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShortenedCandidate
     */
    readonly currentPipelineStageId?: string;
}

export function ShortenedCandidateFromJSON(json: any): ShortenedCandidate {
    return ShortenedCandidateFromJSONTyped(json, false);
}

export function ShortenedCandidateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShortenedCandidate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'job': !exists(json, 'job') ? undefined : JobFromJSON(json['job']),
        'contact': !exists(json, 'contact') ? undefined : ShortenedContactFromJSON(json['contact']),
        'currentPipelineSubstage': InterviewSubstageEnumFromJSON(json['current_pipeline_substage']),
        'interviewId': !exists(json, 'interview_id') ? undefined : json['interview_id'],
        'currentPipelineStageId': !exists(json, 'current_pipeline_stage_id') ? undefined : json['current_pipeline_stage_id'],
    };
}

export function ShortenedCandidateToJSON(value?: ShortenedCandidate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': JobToJSON(value.job),
        'contact': ShortenedContactToJSON(value.contact),
        'current_pipeline_substage': InterviewSubstageEnumToJSON(value.currentPipelineSubstage),
    };
}


