/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IRRInterviewer,
    IRRInterviewerFromJSON,
    IRRInterviewerFromJSONTyped,
    IRRInterviewerToJSON,
} from './';

/**
 * 
 * @export
 * @interface IRRInterview
 */
export interface IRRInterview {
    /**
     * 
     * @type {string}
     * @memberof IRRInterview
     */
    readonly id?: string;
    /**
     * 
     * @type {IRRInterviewer}
     * @memberof IRRInterview
     */
    interviewer: IRRInterviewer;
    /**
     * 
     * @type {Date}
     * @memberof IRRInterview
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof IRRInterview
     */
    end: Date;
}

export function IRRInterviewFromJSON(json: any): IRRInterview {
    return IRRInterviewFromJSONTyped(json, false);
}

export function IRRInterviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): IRRInterview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'interviewer': IRRInterviewerFromJSON(json['interviewer']),
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
    };
}

export function IRRInterviewToJSON(value?: IRRInterview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interviewer': IRRInterviewerToJSON(value.interviewer),
        'start': (value.start.toISOString()),
        'end': (value.end.toISOString()),
    };
}


