import { Modal, ModalHeader } from "reactstrap";
import styled from "styled-components";

export const InstructionList = styled.ol`
  padding-inline-start: 0;
  list-style: none;
  counter-reset: custom-counter;
  width: 100%;
`;

export const CardListItemWrapper = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  padding: 24px 16px;
  margin-bottom: 16px;
`;

export const CardListItem = styled.li`
  position: relative;
  margin-left: 43px;
  counter-increment: custom-counter;

  &::before {
    position: absolute;
    left: -43px;
    content: counter(custom-counter);
    font-size: 13px;
    color: white;
    width: 25px;
    height: 25px;
    background-color: #355966;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const ModalLink = styled.div`
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  color: #0087c0;
  cursor: pointer;
`;

export const StyledModal = styled(Modal)`
  .modal-content {
    padding: 32px;
    padding-top: 0;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  .close:focus {
    outline: none;
  }
`;
