import { colors } from "styles/theme";

export type JOB_POSITION_OPENING_STATUS_ENUM =
  | "NOT_STARTED"
  | "OFF_TRACK"
  | "ON_TRACK"
  | "OPPORTUNISTIC"
  | "LATE"
  | "COMPLETED";

interface StatusOption {
  label: string;
  value: JOB_POSITION_OPENING_STATUS_ENUM;
  color: string;
}

export const JOB_POSITION_OPENING_STATUS_OPTIONS: Readonly<StatusOption>[] = [
  {
    label: "Not Started",
    value: "NOT_STARTED",
    color: colors.grayscale.gray600,
  },
  {
    label: "Off Track",
    value: "OFF_TRACK",
    color: colors.critical.base,
  },
  {
    label: "On Track",
    value: "ON_TRACK",
    color: colors.primary.base,
  },
  {
    label: "Opportunistic",
    value: "OPPORTUNISTIC",
    color: colors.warning.dark,
  },
  {
    label: "Late",
    value: "LATE",
    color: colors.critical.dark,
  },
  {
    label: "Completed",
    value: "COMPLETED",
    color: colors.primary.dark,
  },
];

export const DEFAULT_STATUS_OPTION = JOB_POSITION_OPENING_STATUS_OPTIONS[0];
export const NOT_STARTED_OPTION = JOB_POSITION_OPENING_STATUS_OPTIONS[0];

export const getStatusOptionByValue = (value: JOB_POSITION_OPENING_STATUS_ENUM): Readonly<StatusOption> | undefined => {
  return JOB_POSITION_OPENING_STATUS_OPTIONS.find(option => option.value === value);
};

interface PriorityOption {
  label: string;
  value: JOB_POSITION_PRIORITY_ENUM;
  color: string;
}

export const JOB_POSITION_PRIORITY_OPTIONS: Readonly<PriorityOption>[] = [
  {
    label: "High",
    value: "HIGH",
    color: colors.critical.base,
  },
  {
    label: "Medium",
    value: "MEDIUM",
    color: colors.warning.dark,
  },
  {
    label: "Low",
    value: "LOW",
    color: colors.primary.base,
  },
  {
    label: "None",
    value: "NONE",
    color: colors.grayscale.gray400,
  },
];

export const DEFAULT_PRIORITY_OPTION = JOB_POSITION_PRIORITY_OPTIONS[3];

export const getPriorityOptionByValue = (value: JOB_POSITION_PRIORITY_ENUM): Readonly<PriorityOption> | undefined => {
  return JOB_POSITION_PRIORITY_OPTIONS.find(option => option.value === value);
};

export type JOB_POSITION_PRIORITY_ENUM = "HIGH" | "MEDIUM" | "LOW" | "NONE";

interface PriorityOption {
  label: string;
  value: JOB_POSITION_PRIORITY_ENUM;
  color: string;
}
