/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormatAiAnswerRequest
 */
export interface FormatAiAnswerRequest {
    /**
     * 
     * @type {string}
     * @memberof FormatAiAnswerRequest
     */
    answer: string;
    /**
     * 
     * @type {string}
     * @memberof FormatAiAnswerRequest
     */
    questionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof FormatAiAnswerRequest
     */
    helperText?: string;
    /**
     * 
     * @type {string}
     * @memberof FormatAiAnswerRequest
     */
    formattingPromptType: FormatAiAnswerRequestFormattingPromptTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum FormatAiAnswerRequestFormattingPromptTypeEnum {
    BulletPoint = 'bullet_point',
    Summarize = 'summarize'
}

export function FormatAiAnswerRequestFromJSON(json: any): FormatAiAnswerRequest {
    return FormatAiAnswerRequestFromJSONTyped(json, false);
}

export function FormatAiAnswerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormatAiAnswerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'answer': json['answer'],
        'questionLabel': json['question_label'],
        'helperText': !exists(json, 'helper_text') ? undefined : json['helper_text'],
        'formattingPromptType': json['formatting_prompt_type'],
    };
}

export function FormatAiAnswerRequestToJSON(value?: FormatAiAnswerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'answer': value.answer,
        'question_label': value.questionLabel,
        'helper_text': value.helperText,
        'formatting_prompt_type': value.formattingPromptType,
    };
}


