import componentMapper from "@data-driven-forms/mui-component-mapper/component-mapper";

import OldRangeSlider from "components/data-driven-forms/fields/OldRangeSlider";
import OldSelect from "components/data-driven-forms/fields/OldSelect";
import RangeSlider from "components/data-driven-forms/fields/RangeSlider";
import ReadOnlyField from "components/data-driven-forms/fields/ReadOnlyField";
import Select from "components/data-driven-forms/fields/Select";
import Slider from "components/data-driven-forms/fields/Slider";
import TextField from "components/data-driven-forms/fields/TextField";

const defaultCustomMapper = {
  ...componentMapper,
  // Any custom fields here
  "range-slider": OldRangeSlider,
  select: OldSelect,
};

export const COMPANY_BIO_MAPPER = {
  ...defaultCustomMapper,
  "text-field": TextField,
  slider: Slider,
  select: Select,
  "range-slider": RangeSlider,
};

export const READ_ONLY_MAPPER = {
  ...defaultCustomMapper,
  "text-field": ReadOnlyField,
  slider: ReadOnlyField,
  select: ReadOnlyField,
  "range-slider": ReadOnlyField,
};

export const JOB_PITCH_MAPPER = {
  ...defaultCustomMapper,
  "text-field": TextField,
  slider: Slider,
  select: Select,
  "range-slider": RangeSlider,
};

export default defaultCustomMapper;
