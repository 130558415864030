import { Stack } from "@mui/material";
import React, { FC } from "react";

import { Subtitle2 } from "components/library/typography";
import { SampleChip } from "views/candidates/ApplicationReview/components/SampleChip";

interface ApplicantNameProps {
  fullName?: string;
  isSample?: boolean;
}

export const ApplicantName: FC<ApplicantNameProps> = ({ fullName, isSample }) => {
  const showSampleChip = isSample || fullName === "Max Kolysh";

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Subtitle2 id="candidate-name">{fullName}</Subtitle2>
      {showSampleChip && <SampleChip />}
    </Stack>
  );
};
