import { Box, Stack, Switch } from "@mui/material";
import React, { useState } from "react";

import HelpSVG from "assets/icons/help-question.svg";
import PipelineIcon from "assets/icons/pipeline-icon.svg";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { useGetJobFunnelStatsQuery } from "services/doverapi/endpoints/job/endpoints";
import { DashboardJob, JobInterviewStageStats } from "services/openapi";
import { colors } from "styles/theme";
import { DateDropdownSelector } from "views/Reporting/components/DateDropdownSelector";
import { StageRow } from "views/Reporting/components/StageRow";
import { DateOption } from "views/Reporting/constants";
import { ReportBlock, StyledTable } from "views/Reporting/styles";
import { StyledSVG } from "views/Reporting/styles";
import { DateRange } from "views/Reporting/types";

interface InterviewStatsTableProps {
  job: DashboardJob;
  dateRange: DateRange;
  dateRangeOption: DateOption;
  onDateRangeChange: (value: DateOption) => void;
}

export const InterviewStatsTable: React.FC<InterviewStatsTableProps> = ({
  job,
  dateRange,
  dateRangeOption,
  onDateRangeChange,
}) => {
  const [showPercentages, setShowPercentages] = useState<boolean>(false);

  const { data: interviewStats, isFetching } = useGetJobFunnelStatsQuery({
    id: job.id!,
    ...dateRange,
  });

  const PASS_THROUGH_RATE_TOOLTIP_TEXT = "Excludes active candidates in previous interview stages.";

  return (
    <ReportBlock>
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledSVG
              src={PipelineIcon}
              style={{
                marginRight: "8px",
                width: "16px",
                height: "16px",
                color: colors.grayscale.gray600,
              }}
            />
            <BodySmall weight="700">Interview Funnel</BodySmall>
            <Tooltip title={PASS_THROUGH_RATE_TOOLTIP_TEXT} placement="top">
              <span>
                <StyledSVG src={HelpSVG} style={{ marginLeft: "4px", marginRight: "4px" }} />
              </span>
            </Tooltip>
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <DateDropdownSelector value={dateRangeOption} onChange={onDateRangeChange} />
            <Stack direction="row" spacing={1} alignItems="center">
              <Switch
                disabled={isFetching}
                checked={showPercentages}
                onChange={(): void => setShowPercentages(!showPercentages)}
                size="small"
              />
              <BodySmall color={colors.grayscale.gray600}>Pass-through rates</BodySmall>
            </Stack>
          </Stack>
        </Box>

        <StyledTable
          responsive={true}
          bordered
          sx={{
            borderRadius: "8px",
            overflow: "hidden",
            border: `1px solid ${colors.grayscale.gray200}`,
            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
            "& th, & td": {
              borderColor: colors.grayscale.gray200,
              padding: "16px 24px",
              fontSize: "14px",
              fontFamily: "Inter",
              fontWeight: 400,
              color: colors.grayscale.gray600,
            },
            "& thead th": {
              backgroundColor: colors.grayscale.gray050,
              fontWeight: 500,
              color: colors.grayscale.gray600,
              fontSize: "13px",
            },
            "& tbody td": {
              color: colors.grayscale.gray700,
              verticalAlign: "middle",
            },
            "& tbody tr": {
              transition: "background-color 0.2s",
              "&:hover": {
                backgroundColor: colors.grayscale.gray050,
              },
            },
          }}
        >
          <thead>
            <tr>
              <th scope="col" />
              <th scope="col">
                <BodySmall weight="500">Entered Stage</BodySmall>
              </th>
              <th scope="col">
                <BodySmall weight="500">Currently Active</BodySmall>
              </th>
              <th scope="col">
                <BodySmall weight="500">Lost</BodySmall>
              </th>
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <tr>
                <td colSpan={5} height="294px">
                  <DoverLoadingSpinner />
                </td>
              </tr>
            ) : (
              <>
                {interviewStats?.statsByStage.map((stats: JobInterviewStageStats, idx: number) => {
                  return (
                    <StageRow
                      key={idx}
                      allStats={interviewStats.statsByStage}
                      stageStats={stats}
                      job={job}
                      showPercentages={showPercentages}
                    />
                  );
                })}
              </>
            )}
          </tbody>
        </StyledTable>
      </Stack>
    </ReportBlock>
  );
};
