/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneratedJDQualification
 */
export interface GeneratedJDQualification {
    /**
     * 
     * @type {string}
     * @memberof GeneratedJDQualification
     */
    mustHave?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedJDQualification
     */
    niceToHave?: string;
}

export function GeneratedJDQualificationFromJSON(json: any): GeneratedJDQualification {
    return GeneratedJDQualificationFromJSONTyped(json, false);
}

export function GeneratedJDQualificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratedJDQualification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mustHave': !exists(json, 'must_have') ? undefined : json['must_have'],
        'niceToHave': !exists(json, 'nice_to_have') ? undefined : json['nice_to_have'],
    };
}

export function GeneratedJDQualificationToJSON(value?: GeneratedJDQualification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'must_have': value.mustHave,
        'nice_to_have': value.niceToHave,
    };
}


