import { z } from "zod";

import { RemotePolicies } from "views/job/constants";
import { KeywordsWithMetadataSchema, YearsOfExperienceWithMetadataSchema } from "views/sourcing/Search/types";

export const CreateJobDescriptionSchema = z.object({
  jobDescription: z.string().optional(),
  totalYearsOfExperience: YearsOfExperienceWithMetadataSchema,
  remotePolicy: z.nativeEnum(RemotePolicies),
  wfhAllowed: z.boolean(),
  remoteRegions: z.array(z.object({ displayName: z.string(), locationOption: z.string() })),
  onsiteCities: z.array(z.object({ displayName: z.string(), locationOption: z.string() })),
  keywordsBucket: KeywordsWithMetadataSchema,
  freeText: z.string().optional(),
  postOnLinkedIn: z.boolean(),
  linkedInCompanyUrl: z.nullable(z.string().url()),
});

export type CreateJobDescriptionSchemaFormType = z.infer<typeof CreateJobDescriptionSchema>;

export const initialCreateJobDescriptionFormValues = {
  totalYearsOfExperience: { required: false, yearsOfExperience: { min: 0, max: 5 } },
  remotePolicy: RemotePolicies.InOffice,
  wfhAllowed: false,
  remoteRegions: [],
  onsiteCities: [],
  keywordsBucket: { required: false, bucket: [] },
  freeText: "",
  postOnLinkedIn: false,
  linkedInCompanyUrl: null,
};
