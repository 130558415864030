/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckCanCreateManualContextForJobRequest
 */
export interface CheckCanCreateManualContextForJobRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobRequest
     */
    job: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobRequest
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobRequest
     */
    emailId?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCanCreateManualContextForJobRequest
     */
    coreSourceIdentifier: CheckCanCreateManualContextForJobRequestCoreSourceIdentifierEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CheckCanCreateManualContextForJobRequest
     */
    isOutreach?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CheckCanCreateManualContextForJobRequestCoreSourceIdentifierEnum {
    ManualOutbound = 'MANUAL_OUTBOUND',
    SourcingExtensionOutbound = 'SOURCING_EXTENSION_OUTBOUND'
}

export function CheckCanCreateManualContextForJobRequestFromJSON(json: any): CheckCanCreateManualContextForJobRequest {
    return CheckCanCreateManualContextForJobRequestFromJSONTyped(json, false);
}

export function CheckCanCreateManualContextForJobRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckCanCreateManualContextForJobRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'job': json['job'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'emailId': !exists(json, 'email_id') ? undefined : json['email_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'coreSourceIdentifier': json['core_source_identifier'],
        'isOutreach': !exists(json, 'is_outreach') ? undefined : json['is_outreach'],
    };
}

export function CheckCanCreateManualContextForJobRequestToJSON(value?: CheckCanCreateManualContextForJobRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job': value.job,
        'url': value.url,
        'email_id': value.emailId,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'core_source_identifier': value.coreSourceIdentifier,
        'is_outreach': value.isOutreach,
    };
}


