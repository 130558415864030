export const setupHiringPipelineReducerKey = "SetupHiringPipeline";

// These are names of Greenhouse Stages that should be disabled in the UI.
export const GREENHOUSE_DISABLE_STAGES = ["Application Review"];

// Lever stages to disable in the UI because they cannot be mapped to Dover stages
// Note these are currently case sensitive
export const LEVER_DISABLE_STAGES = ["Responded", "Reached out", "New lead", "New applicant", "Contacted"];

export const ASHBY_DISABLE_STAGES = ["new lead", "reached out", "replied", "application review", "archived", "hired"];

export const DEFAULT_DEBRIEF_DURATION = 15 * 60; // 15 minutes
