import { atom, useAtomValue } from "jotai";

import { ListSaapReviewApplicationRequestOrderingEnum } from "services/openapi";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";

// NOTE you should use the hook below if you want to get the value of the atom
export const orderingAtom = atom<ListSaapReviewApplicationRequestOrderingEnum>(
  ListSaapReviewApplicationRequestOrderingEnum.Best
);

export const useOrderingValue = (): ListSaapReviewApplicationRequestOrderingEnum => {
  const { jobHasScoringEnabled } = useJobHasScoringEnabled();
  const ordering = useAtomValue(orderingAtom);

  // if scoring is off, dont allow using sort by best match, instead use newest in that case
  if (!jobHasScoringEnabled && ordering === ListSaapReviewApplicationRequestOrderingEnum.Best) {
    return ListSaapReviewApplicationRequestOrderingEnum.Newest;
  }
  return ordering;
};
