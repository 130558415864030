/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDtnCandidateRequest
 */
export interface CreateDtnCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDtnCandidateRequest
     */
    decision: CreateDtnCandidateRequestDecisionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDtnCandidateRequest
     */
    jobId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDtnCandidateRequest
     */
    parentAppId: string;
    /**
     * The stage we'd like to move the candidate to. Required for manual scheduling.
     * @type {string}
     * @memberof CreateDtnCandidateRequest
     */
    desiredHiringPipelineStage?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum CreateDtnCandidateRequestDecisionEnum {
    Approve = 'APPROVE',
    Reject = 'REJECT',
    Reschedule = 'RESCHEDULE',
    Email = 'EMAIL'
}

export function CreateDtnCandidateRequestFromJSON(json: any): CreateDtnCandidateRequest {
    return CreateDtnCandidateRequestFromJSONTyped(json, false);
}

export function CreateDtnCandidateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDtnCandidateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decision': json['decision'],
        'jobId': json['job_id'],
        'parentAppId': json['parent_app_id'],
        'desiredHiringPipelineStage': !exists(json, 'desired_hiring_pipeline_stage') ? undefined : json['desired_hiring_pipeline_stage'],
    };
}

export function CreateDtnCandidateRequestToJSON(value?: CreateDtnCandidateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decision': value.decision,
        'job_id': value.jobId,
        'parent_app_id': value.parentAppId,
        'desired_hiring_pipeline_stage': value.desiredHiringPipelineStage,
    };
}


