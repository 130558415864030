/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailSenderAlias
 */
export interface EmailSenderAlias {
    /**
     * 
     * @type {string}
     * @memberof EmailSenderAlias
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSenderAlias
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailSenderAlias
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailSenderAlias
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailSenderAlias
     */
    readonly fullName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailSenderAlias
     */
    isDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailSenderAlias
     */
    connectedToFront?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailSenderAlias
     */
    readonly isVirtual?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailSenderAlias
     */
    readonly baseEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailSenderAlias
     */
    readonly credsExist?: boolean;
    /**
     * Set to override default suggestion in Company Setup UI; useful if HM already has setup an alias
     * @type {string}
     * @memberof EmailSenderAlias
     */
    suggestedAlias?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailSenderAlias
     */
    readonly baseEmailSuggestedUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSenderAlias
     */
    readonly gmailCredentialAuthState?: EmailSenderAliasGmailCredentialAuthStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum EmailSenderAliasGmailCredentialAuthStateEnum {
    IsShellAccount = 'IS_SHELL_ACCOUNT',
    Valid = 'VALID',
    AuthInvalid = 'AUTH_INVALID',
    NeverAuthed = 'NEVER_AUTHED',
    InvalidAuthCredType = 'INVALID_AUTH_CRED_TYPE'
}

export function EmailSenderAliasFromJSON(json: any): EmailSenderAlias {
    return EmailSenderAliasFromJSONTyped(json, false);
}

export function EmailSenderAliasFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailSenderAlias {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
        'connectedToFront': !exists(json, 'connected_to_front') ? undefined : json['connected_to_front'],
        'isVirtual': !exists(json, 'is_virtual') ? undefined : json['is_virtual'],
        'baseEmail': !exists(json, 'base_email') ? undefined : json['base_email'],
        'credsExist': !exists(json, 'creds_exist') ? undefined : json['creds_exist'],
        'suggestedAlias': !exists(json, 'suggested_alias') ? undefined : json['suggested_alias'],
        'baseEmailSuggestedUsername': !exists(json, 'base_email_suggested_username') ? undefined : json['base_email_suggested_username'],
        'gmailCredentialAuthState': !exists(json, 'gmail_credential_auth_state') ? undefined : json['gmail_credential_auth_state'],
    };
}

export function EmailSenderAliasToJSON(value?: EmailSenderAlias | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'is_default': value.isDefault,
        'connected_to_front': value.connectedToFront,
        'suggested_alias': value.suggestedAlias,
    };
}


