/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserUploadedFileProUser,
    UserUploadedFileProUserFromJSON,
    UserUploadedFileProUserFromJSONTyped,
    UserUploadedFileProUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserUploadedFile
 */
export interface UserUploadedFile {
    /**
     * 
     * @type {string}
     * @memberof UserUploadedFile
     */
    readonly id?: string;
    /**
     * 
     * @type {UserUploadedFileProUser}
     * @memberof UserUploadedFile
     */
    user?: UserUploadedFileProUser;
    /**
     * 
     * @type {Date}
     * @memberof UserUploadedFile
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserUploadedFile
     */
    readonly modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserUploadedFile
     */
    readonly fileContents?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUploadedFile
     */
    uploadedContext: UserUploadedFileUploadedContextEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUploadedFile
     */
    originalFileName?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserUploadedFile
     */
    lastProcessedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserUploadedFile
     */
    readonly processingState?: UserUploadedFileProcessingStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UserUploadedFile
     */
    readonly linkedinPublicUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUploadedFile
     */
    readonly processingError?: string;
}

/**
* @export
* @enum {string}
*/
export enum UserUploadedFileUploadedContextEnum {
    Referrals = 'REFERRALS',
    ManualSourcingExtensionLiProfile = 'MANUAL_SOURCING_EXTENSION_LI_PROFILE',
    CareersPageImage = 'CAREERS_PAGE_IMAGE',
    CompanyLevelContactExclusion = 'COMPANY_LEVEL_CONTACT_EXCLUSION'
}/**
* @export
* @enum {string}
*/
export enum UserUploadedFileProcessingStateEnum {
    Queued = 'QUEUED',
    Processing = 'PROCESSING',
    Processed = 'PROCESSED',
    Failed = 'FAILED'
}

export function UserUploadedFileFromJSON(json: any): UserUploadedFile {
    return UserUploadedFileFromJSONTyped(json, false);
}

export function UserUploadedFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUploadedFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user': !exists(json, 'user') ? undefined : UserUploadedFileProUserFromJSON(json['user']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'fileContents': !exists(json, 'file_contents') ? undefined : json['file_contents'],
        'uploadedContext': json['uploaded_context'],
        'originalFileName': !exists(json, 'original_file_name') ? undefined : json['original_file_name'],
        'lastProcessedAt': !exists(json, 'last_processed_at') ? undefined : (new Date(json['last_processed_at'])),
        'processingState': !exists(json, 'processing_state') ? undefined : json['processing_state'],
        'linkedinPublicUrl': !exists(json, 'linkedin_public_url') ? undefined : json['linkedin_public_url'],
        'processingError': !exists(json, 'processing_error') ? undefined : json['processing_error'],
    };
}

export function UserUploadedFileToJSON(value?: UserUploadedFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserUploadedFileProUserToJSON(value.user),
        'uploaded_context': value.uploadedContext,
        'original_file_name': value.originalFileName,
        'last_processed_at': value.lastProcessedAt === undefined ? undefined : (value.lastProcessedAt.toISOString()),
    };
}


