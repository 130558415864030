import React from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

import { Spacer } from "components/Spacer";
import { EmailEventField } from "services/openapi";
import { colors } from "styles/theme";

interface EmailHeaderProps {
  label: string;
  value: string;
}
const EmailHeader = ({ label, value }: EmailHeaderProps): React.ReactElement => {
  return (
    <EmailHeaderRow>
      <EmailHeaderLabel>{label}:</EmailHeaderLabel>
      <EmailHeaderValue>{value}</EmailHeaderValue>
    </EmailHeaderRow>
  );
};

interface Props {
  isExpanded: boolean;
  emailEventDetails: EmailEventField;
}
export const EmailEventBody = ({ emailEventDetails, isExpanded }: Props): React.ReactElement => {
  if (!isExpanded) {
    return <EmailSnippet>{emailEventDetails.emailSnippet}</EmailSnippet>;
  }

  const { emailFrom, emailTo, emailCc, emailBcc, emailSubject } = emailEventDetails.emailHeader;

  return (
    <EmailBody
      onClick={(e: React.MouseEvent<HTMLElement>): void => {
        e.stopPropagation(); // Preventing onClick which would collapse email event here so that user can select text and copy
      }}
    >
      <EmailHeaderGroup>
        {emailFrom && <EmailHeader label="From" value={emailFrom} />}
        {emailTo && emailTo.length > 0 && <EmailHeader label="To" value={emailTo.join(", ")} />}
        {emailCc && emailCc.length > 0 && <EmailHeader label="Cc" value={emailCc.join(", ")} />}
        {emailBcc && emailBcc.length > 0 && <EmailHeader label="Bcc" value={emailBcc.join(", ")} />}
        {emailSubject && <EmailHeader label="Subject" value={emailSubject} />}
      </EmailHeaderGroup>
      <Spacer height={16} />
      {ReactHtmlParser(emailEventDetails.emailBody)}
    </EmailBody>
  );
};

const EmailHeaderGroup = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const EmailHeaderRow = styled.div`
  display: flex;
  flex-flow: row;
  padding-bottom: 8px;
`;

const EmailHeaderLabel = styled.div`
  font-size: 14px;
  flex: 1;
  color: ${colors.subTitle};
`;

const EmailHeaderValue = styled.div`
  flex: 7;
  font-size: 14px;
`;

const EmailBody = styled.div`
  padding: 4px;
  font-size: 14px;
  word-break: break-word;
  overflow-wrap: break-word;

  p,
  div,
  a {
    font-size: 14px;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;

const EmailSnippet = styled.div`
  overflow: hidden;
  padding: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
`;
