import { JobSetupStepConstants, StepVisiblityCheckFunc } from "services/doverapi/endpoints/job/types";
import { JobFeatureFeatureNameEnum, JobSetupStepStepNameEnum } from "services/openapi";

export const SERVICE_FEATURE_NAMES = [
  JobFeatureFeatureNameEnum.DoverInterviewer,
  JobFeatureFeatureNameEnum.E2EScheduling,
  JobFeatureFeatureNameEnum.ManagedOutbound,
];

export const JOB_SETUP_STEP_CONFIGS: Readonly<{ [key in JobSetupStepStepNameEnum]?: JobSetupStepConstants }> = {
  [JobSetupStepStepNameEnum.JpqState]: {
    path: "questionnaire",
    label: "Onboarding Calibration Form",
    stepName: JobSetupStepStepNameEnum.JpqState,
  },
  [JobSetupStepStepNameEnum.OverviewState]: {
    path: "overview",
    label: "Overview",
    stepName: JobSetupStepStepNameEnum.OverviewState,
  },
  [JobSetupStepStepNameEnum.JobBasicsState]: {
    path: "basic-info",
    label: "Job Basics",
    stepName: JobSetupStepStepNameEnum.JobBasicsState,
  },
  [JobSetupStepStepNameEnum.JobDescriptionState]: {
    path: "job-description",
    label: "Job Details",
    stepName: JobSetupStepStepNameEnum.JobDescriptionState,
  },
  [JobSetupStepStepNameEnum.InboundSourcesState]: {
    path: "job-boards",
    label: "Job Boards",
    stepName: JobSetupStepStepNameEnum.InboundSourcesState,
  },
  [JobSetupStepStepNameEnum.PitchState]: {
    path: "pitch",
    label: "Pitch",
    stepName: JobSetupStepStepNameEnum.PitchState,
  },
  [JobSetupStepStepNameEnum.OutreachState]: {
    path: "outreach",
    label: "Outreach",
    stepName: JobSetupStepStepNameEnum.OutreachState,
  },
  [JobSetupStepStepNameEnum.ScreenerQuestionsState]: {
    path: "rubric",
    label: "Screener Questions",
    stepName: JobSetupStepStepNameEnum.ScreenerQuestionsState,
  },
  [JobSetupStepStepNameEnum.InterviewPlanState]: {
    path: "scheduling",
    label: "Interview Plan",
    stepName: JobSetupStepStepNameEnum.InterviewPlanState,
  },
  [JobSetupStepStepNameEnum.SearchCriteriaState]: {
    path: "search-criteria",
    label: "Search Criteria",
    stepName: JobSetupStepStepNameEnum.SearchCriteriaState,
  },
  [JobSetupStepStepNameEnum.SourcingAutopilotPendingCallState]: {
    path: "sourcing-pending-call",
    label: "Sourcing",
    stepName: JobSetupStepStepNameEnum.SourcingAutopilotPendingCallState,
  },
};

export const STEP_VISIBILITY_CONFIG: Readonly<{ [key in JobSetupStepStepNameEnum]: StepVisiblityCheckFunc }> = {
  [JobSetupStepStepNameEnum.OverviewState]: () => true,
  [JobSetupStepStepNameEnum.JobBasicsState]: () => true,
  [JobSetupStepStepNameEnum.JobDescriptionState]: ({ isRelevantToJob }) => isRelevantToJob,
  [JobSetupStepStepNameEnum.InboundSourcesState]: ({ isRelevantToJob }) => isRelevantToJob,
  [JobSetupStepStepNameEnum.PitchState]: ({ isRelevantToJob }) => isRelevantToJob,
  [JobSetupStepStepNameEnum.OutreachState]: ({ isRelevantToJob }) => isRelevantToJob,
  [JobSetupStepStepNameEnum.ScreenerQuestionsState]: ({ isRelevantToJob }) => isRelevantToJob,
  [JobSetupStepStepNameEnum.InterviewPlanState]: () => true,
  [JobSetupStepStepNameEnum.SearchCriteriaState]: () => true,
  [JobSetupStepStepNameEnum.JpqState]: () => false,
  [JobSetupStepStepNameEnum.SourcingAutopilotPendingCallState]: () => true,
};
