import { Button as DuiButton } from "@doverhq/dover-ui";
import { Menu, Stack } from "@mui/material";
import { PressEvent } from "@react-types/shared/src";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { ReactElement, useState } from "react";
import styled from "styled-components";

import { ReactComponent as PencilEditIcon } from "assets/icons/pencil-edit.svg";
import { AddOrEditLinkItemV2 } from "components/CandidateHeader/SocialLinks/EditSocialLinkForm";
import { SocialLinkListItemV2 } from "components/CandidateHeader/SocialLinks/SocialLinkListItem";
import { Button, ButtonVariant } from "components/library/Button";
import { Subtitle1 } from "components/library/typography";
import { useCandidateId } from "hooks/useCandidateId";
import { useGetCandidateBioQuery } from "services/doverapi/endpoints/candidate";
import { usePartialUpdateContactMutation } from "services/doverapi/endpoints/contact";
import { ContactSocialLink } from "services/openapi";
import { colors } from "styles/theme";

export const EditSocialLinksV2 = ({ candidateId: candidateIdFromProps }: { candidateId?: string }): ReactElement => {
  const candidateIdFromUrl = useCandidateId();
  const candidateId = candidateIdFromProps || candidateIdFromUrl; // In app review page we don't put the candidate id in the url

  const [updateContact] = usePartialUpdateContactMutation();

  const { data: candidateBio } = useGetCandidateBioQuery(candidateId ?? skipToken);

  // The social link id that is currently being edited
  const [linkIdxBeingEdited, setLinkIdxBeingEdited] = useState<number | null>(null);
  const [addNewLinkOpen, setAddNewLinkOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const socialLinks = candidateBio?.contact.socialLinks ?? [];

  const updateSocialLinks = (updatedSocialLinks: ContactSocialLink[]): void => {
    const contactId = candidateBio?.contact.id;

    if (!contactId) {
      console.error("Tried to update social links without a contact id");
      return;
    }

    updateContact({
      id: contactId,
      data: {
        socialLinks: updatedSocialLinks,
      },
      candidateId,
    });
  };

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: PressEvent): void => {
    setAnchorEl(event.target);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const updateSocialLink = (socialLink: ContactSocialLink): void => {
    if (linkIdxBeingEdited === null) {
      return;
    }

    const newSocialLinks = [...socialLinks];

    newSocialLinks[linkIdxBeingEdited] = socialLink;

    updateSocialLinks(newSocialLinks);
    setLinkIdxBeingEdited(null);
  };

  const deleteSocialLink = (linkIdx: number): void => {
    const newSocialLinks = [...socialLinks];
    newSocialLinks.splice(linkIdx, 1);
    updateSocialLinks(newSocialLinks);
  };

  return (
    <>
      <DuiButton
        variant="ghost"
        onPress={handleClick}
        id="candidate-detail-edit-button"
        p={1}
        icon={{ Icon: PencilEditIcon }}
      />
      <StyledMenu open={menuOpen} onClose={handleClose} anchorEl={anchorEl}>
        <Stack spacing={2}>
          <Subtitle1 style={{ lineHeight: "20px" }}>Edit profile links</Subtitle1>
          <Stack spacing={1}>
            {socialLinks?.map((link, idx) => (
              <SocialLinkListItemV2
                socialLink={link}
                editModeOn={idx === linkIdxBeingEdited}
                setEditMode={(updatedEditMode: boolean): void => {
                  if (updatedEditMode) {
                    setLinkIdxBeingEdited(idx);
                    return;
                  }

                  setLinkIdxBeingEdited(null);
                }}
                onSave={(updatedLink: ContactSocialLink): void => {
                  updateSocialLink(updatedLink);
                }}
                onDelete={(): void => {
                  deleteSocialLink(idx);
                }}
              />
            ))}
          </Stack>
          {linkIdxBeingEdited === null && !addNewLinkOpen && (
            <AddButton
              variant={ButtonVariant.Ghost}
              onClick={(): void => {
                setAddNewLinkOpen(true);
              }}
            >
              + Add
            </AddButton>
          )}
          {addNewLinkOpen && (
            <AddOrEditLinkItemV2
              setEditMode={(updatedEditMode: boolean): void => {
                setAddNewLinkOpen(updatedEditMode);
              }}
              onSave={(newLink: ContactSocialLink): void => {
                updateSocialLinks([...socialLinks, newLink]);
                setAddNewLinkOpen(false);
              }}
            />
          )}
        </Stack>
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    padding: 16px;
    min-width: 360px;
    margin-top: 3px;
  }
  .MuiList-root {
    padding: 0;
  }
`;

const AddButton = styled(Button)`
  font-size: 14px;
  color: ${colors.link};
  width: 70px;
  padding: 5px;
`;
