import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";

import { colors } from "styles/theme";

interface SeeMoreLessProps {
  text: string;
  maxCharLimit?: number;
  showMoreLabel?: string;
  showLessLabel?: string;
}

const SeeMoreLess = ({
  text,
  maxCharLimit = 250,
  showMoreLabel = "... See more",
  showLessLabel = "See less",
}: SeeMoreLessProps): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [textHasOverflow, setTextHasOverflow] = useState(false);
  const [truncatedText, setTruncatedText] = useState(text);

  useEffect(() => {
    if (text.length > maxCharLimit) {
      setTextHasOverflow(true);
    }
  }, [maxCharLimit, text.length]);

  useEffect(() => {
    if (textHasOverflow) {
      if (isExpanded) {
        setTruncatedText(text);
      } else {
        setTruncatedText(text.slice(0, maxCharLimit));
      }
    }
  }, [isExpanded, maxCharLimit, text, textHasOverflow]);

  return (
    <Box sx={{ maxCharLimit: isExpanded ? "auto" : `${maxCharLimit}px`, overflow: "hidden" }}>
      <span>
        {truncatedText}{" "}
        {textHasOverflow && (
          <Box
            display="inherit"
            color={colors.link}
            onClick={(): void => setIsExpanded(!isExpanded)}
            sx={{ cursor: "pointer" }}
          >
            {isExpanded ? showLessLabel : showMoreLabel}
          </Box>
        )}
      </span>
    </Box>
  );
};
export default SeeMoreLess;
