/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOrCreateApiKeyCredentialRequest
 */
export interface GetOrCreateApiKeyCredentialRequest {
    /**
     * 
     * @type {string}
     * @memberof GetOrCreateApiKeyCredentialRequest
     */
    jobSource: string;
}

export function GetOrCreateApiKeyCredentialRequestFromJSON(json: any): GetOrCreateApiKeyCredentialRequest {
    return GetOrCreateApiKeyCredentialRequestFromJSONTyped(json, false);
}

export function GetOrCreateApiKeyCredentialRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrCreateApiKeyCredentialRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobSource': json['job_source'],
    };
}

export function GetOrCreateApiKeyCredentialRequestToJSON(value?: GetOrCreateApiKeyCredentialRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'job_source': value.jobSource,
    };
}


