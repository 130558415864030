/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterSimilarCandidateRequest
 */
export interface RegisterSimilarCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterSimilarCandidateRequest
     */
    canonicalId: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSimilarCandidateRequest
     */
    searchId: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSimilarCandidateRequest
     */
    reviewType: RegisterSimilarCandidateRequestReviewTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum RegisterSimilarCandidateRequestReviewTypeEnum {
    Queued = 'QUEUED',
    Success = 'SUCCESS',
    FailedEmailFinding = 'FAILED_EMAIL_FINDING',
    FailedGenerateOutreach = 'FAILED_GENERATE_OUTREACH',
    FailedRefresh = 'FAILED_REFRESH',
    FailedTrulyForbidden = 'FAILED_TRULY_FORBIDDEN',
    Unreviewed = 'UNREVIEWED',
    NotSimilar = 'NOT_SIMILAR',
    NeedsRefresh = 'NEEDS_REFRESH',
    NotSimilarPostRefresh = 'NOT_SIMILAR_POST_REFRESH'
}

export function RegisterSimilarCandidateRequestFromJSON(json: any): RegisterSimilarCandidateRequest {
    return RegisterSimilarCandidateRequestFromJSONTyped(json, false);
}

export function RegisterSimilarCandidateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterSimilarCandidateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canonicalId': json['canonical_id'],
        'searchId': json['search_id'],
        'reviewType': json['review_type'],
    };
}

export function RegisterSimilarCandidateRequestToJSON(value?: RegisterSimilarCandidateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canonical_id': value.canonicalId,
        'search_id': value.searchId,
        'review_type': value.reviewType,
    };
}


