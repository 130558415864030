import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import sortBy from "lodash/sortBy";
import React, { FC, useState } from "react";
import { FormProvider, useController, useForm, useFormContext, useFormState, useWatch } from "react-hook-form";

import { Autocomplete } from "components/library/Autocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Body, BodySmall, ButtonText, Subtitle2 } from "components/library/typography";
import YOESlider, { YearsOfExperienceRange } from "components/library/YOESlider";
import DoverLoadingOverlay from "components/loading-overlay";
import CustomModal from "components/Modal";
import { modalAtom } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { useJobId } from "hooks/useJobIdFromUrl";
import { useGetJobSetupQuery, useUpdateJobSetupMutation } from "services/doverapi/endpoints/job";
import { useGenerateJobDescriptionMutation } from "services/doverapi/endpoints/job-description/endpoints";
import {
  useGetOrCreateKeywordWithNameMutation,
  useLazyListProfileSearchKeywordsQuery,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { JobLocationLocationTypeEnum, ProfileSearchKeywordSerializer } from "services/openapi";
import { colors } from "styles/theme";
import { showErrorToast } from "utils/showToast";
import {
  CreateJobDescriptionSchema,
  CreateJobDescriptionSchemaFormType,
  initialCreateJobDescriptionFormValues,
} from "views/create-job/CreateJob/constants";
import JobLocationFields, {
  useGetJobLocationsFromFieldValues,
} from "views/create-job/shared-steps/components/JobLocationFields";
import { getJobDetailProps } from "views/job/JobSetup/steps/JobPosting/JobPosting";
import { shouldShowInOfficeCities, shouldShowRemoteRegions } from "views/job/utils";
import { YEARS_OF_EXPERIENCE_MAX, YEARS_OF_EXPERIENCE_ALLOWED_RANGE } from "views/sourcing/Search/constants";

const YEARS_OF_EXPERIENCE_FORM_NAME = "totalYearsOfExperience.yearsOfExperience";

const KEYWORDS_BUCKET_NAME = "keywordsBucket.bucket";

const deduceNoNewOptions = (
  fetchResponse: ProfileSearchKeywordSerializer[],
  selectedCanonicalKeywords: string[]
): boolean => {
  // function to determine if the keyword request does not have any matches
  const noNewOptions = fetchResponse.every((keyword: ProfileSearchKeywordSerializer): boolean =>
    selectedCanonicalKeywords.includes(keyword.canonicalKeyword)
  );
  return noNewOptions;
};

const KeywordAdder = ({
  customKeywordString,
  createKeyword,
}: {
  customKeywordString: string;
  createKeyword: () => Promise<void>;
}): React.ReactElement => {
  // shared component for adding keywords
  return (
    <Box sx={{ cursor: "pointer" }} onClick={createKeyword}>
      <Body>{`+ Add ${customKeywordString} keyword`}</Body>
    </Box>
  );
};

interface KeywordsAutocompleteProps {
  customKeywordString: string;
  selectedCanonicalKeywords: string[];
  isCreateKeywordLoading: boolean;
  initialValue: ProfileSearchKeywordSerializer[];
  fetchKeywords: (request: string) => Promise<ProfileSearchKeywordSerializer[]>;
  onFetchCompleted: (customKeywordString: string, fetchResponse: ProfileSearchKeywordSerializer[]) => void;
  createKeyword: () => Promise<void>;
  onKeywordsSelectionChange: (newKeywords: ProfileSearchKeywordSerializer[]) => Promise<void>;
}

export const KeywordsAutocomplete = ({
  customKeywordString,
  selectedCanonicalKeywords,
  isCreateKeywordLoading,
  fetchKeywords,
  onFetchCompleted,
  createKeyword,
  onKeywordsSelectionChange,
  initialValue,
}: KeywordsAutocompleteProps): React.ReactElement => {
  return (
    <Autocomplete
      fontSize="small"
      placeholder={"Enter a keyword..."}
      noOptionsText={
        !isCreateKeywordLoading && !!customKeywordString.length ? (
          <KeywordAdder customKeywordString={customKeywordString} createKeyword={createKeyword} />
        ) : (
          "Start typing to see keywords"
        )
      }
      fetch={fetchKeywords}
      onFetchCompleted={onFetchCompleted}
      filterSelectedOptions={true}
      getOptionLabel={(option: ProfileSearchKeywordSerializer): string => option.friendlyName}
      getOptionDisabled={(option: ProfileSearchKeywordSerializer): boolean =>
        selectedCanonicalKeywords.includes(option.canonicalKeyword)
      }
      onSelectedOptionsChange={onKeywordsSelectionChange}
      initialValues={initialValue}
      multiple={true}
    />
  );
};

const KeywordsSelector = React.memo(
  (): React.ReactElement => {
    const { control, setValue } = useFormContext<CreateJobDescriptionSchemaFormType>();
    const [customKeywordString, setCustomKeywordString] = useState<string>("");

    const [lazyListKeywords] = useLazyListProfileSearchKeywordsQuery();
    const [createKeywordWithName, { isLoading: isCreateKeywordLoading }] = useGetOrCreateKeywordWithNameMutation();

    const keywords: ProfileSearchKeywordSerializer[] = useWatch({
      control,
      name: KEYWORDS_BUCKET_NAME,
    });

    const selectedCanonicalKeywords = React.useMemo(() => {
      return keywords.map(keyword => keyword.canonicalKeyword);
    }, [keywords]);

    const fetchKeywords = async (request: string): Promise<ProfileSearchKeywordSerializer[]> => {
      const keywordsResponse = await lazyListKeywords({ limit: 50, queryText: request }).unwrap();

      // return keywordsResponse sorted first by if they haven't already been added, then by friendlyName
      return sortBy(keywordsResponse, [
        (k): boolean => selectedCanonicalKeywords.includes(k.canonicalKeyword),
        "friendlyName",
      ]);
    };

    const onFetchCompleted = (customKeywordString: string, fetchResponse: ProfileSearchKeywordSerializer[]): void => {
      const noNewOptions = deduceNoNewOptions(fetchResponse, selectedCanonicalKeywords);
      if (noNewOptions && customKeywordString.length) {
        // we are searching for something, but fetch is returning the same thing as before we started typing
        // -> this keyword doesnt exist
        setCustomKeywordString(customKeywordString);
      } else {
        setCustomKeywordString("");
      }
    };

    const createKeyword = async (): Promise<void> => {
      if (!customKeywordString.length) {
        return;
      }
      // call API to create keyword
      createKeywordWithName(customKeywordString)
        .unwrap()
        .then(newKeyword => {
          // add this keyword to the FormState via a SetValue
          setValue(KEYWORDS_BUCKET_NAME, [...keywords, newKeyword]);
          // clear the customKeywordString
          setCustomKeywordString("");
        });
    };

    const onKeywordsSelectionChange = React.useCallback(
      async (newKeywords: ProfileSearchKeywordSerializer[]): Promise<void> => {
        if (newKeywords.length > keywords.length) {
          const newKeyword = newKeywords[newKeywords.length - 1];
          setValue(KEYWORDS_BUCKET_NAME, [...keywords, newKeyword]);
        } else if (newKeywords.length < keywords.length) {
          // if a keyword is deleted, just update the form state
          setValue(KEYWORDS_BUCKET_NAME, newKeywords);
        }
      },
      [keywords, setValue]
    );

    return (
      <KeywordsAutocomplete
        customKeywordString={customKeywordString}
        selectedCanonicalKeywords={selectedCanonicalKeywords}
        isCreateKeywordLoading={isCreateKeywordLoading}
        initialValue={keywords ?? []}
        fetchKeywords={fetchKeywords}
        onFetchCompleted={onFetchCompleted}
        createKeyword={createKeyword}
        onKeywordsSelectionChange={onKeywordsSelectionChange}
      />
    );
  }
);

const GenerateJobDescriptionForm: FC<GlobalModalProps> = ({ isOpen, close }) => {
  const [jobId] = useJobId();

  // TODO: subscribe to this to check isSuccess in JobDescriptionStep
  const [generateJobDescription, { isLoading: isGeneratingJobDescription }] = useGenerateJobDescriptionMutation({
    fixedCacheKey: "generateJobDescription",
  });
  const [updateJobSetup] = useUpdateJobSetupMutation();

  // RHF setup
  const { control, setValue, handleSubmit, getValues } = useFormContext<CreateJobDescriptionSchemaFormType>();
  const { errors } = useFormState({ control });

  const totalYearsOfExperienceFormValue = useWatch({ control, name: YEARS_OF_EXPERIENCE_FORM_NAME });

  const { field: remotePolicyField } = useController({
    name: "remotePolicy",
    control,
  });

  const { field: remoteRegionsField } = useController({
    name: "remoteRegions",
    control,
  });

  const { field: onsiteCitiesField } = useController({
    name: "onsiteCities",
    control,
  });

  const { field: freeTextField } = useController({
    name: "freeText",
    control,
  });

  // Dynamically show certain form inputs based on the remote policy
  const showRemoteRegions = remotePolicyField.value
    ? shouldShowRemoteRegions({ remotePolicy: remotePolicyField.value })
    : false;
  const showOnsiteCities = remotePolicyField.value ? shouldShowInOfficeCities(remotePolicyField.value) : false;

  const remoteRegionsError = React.useMemo(() => {
    if (!showRemoteRegions || !!remoteRegionsField?.value?.length) {
      return undefined;
    }
    return "Please select at least one region";
  }, [remoteRegionsField?.value?.length, showRemoteRegions]);

  const onsiteCitiesError = React.useMemo(() => {
    if (!showOnsiteCities || !!onsiteCitiesField?.value?.length) {
      return undefined;
    }
    return "Please select at least one city";
  }, [onsiteCitiesField?.value?.length, showOnsiteCities]);

  const disableSubmit = remoteRegionsError || onsiteCitiesError || isGeneratingJobDescription;

  const handleYearsOfExperienceSliderChange = React.useCallback(
    (value: YearsOfExperienceRange) => {
      setValue(YEARS_OF_EXPERIENCE_FORM_NAME, {
        min: value.min ?? undefined,
        max: value.max ?? undefined,
      });
    },
    [setValue]
  );

  const locations = useGetJobLocationsFromFieldValues();

  const onSubmit = React.useCallback(async (): Promise<void> => {
    const createJobAndGenerateJd = async (): Promise<void> => {
      const values = getValues();

      if (jobId) {
        try {
          await generateJobDescription({
            data: {
              jobId,
              yearsOfExperience: values.totalYearsOfExperience.yearsOfExperience,
              locations: locations,
              skills: values.keywordsBucket.bucket.map(keyword => keyword.canonicalKeyword),
              additionalDetails: values.freeText,
            },
          }).unwrap();

          await updateJobSetup({
            id: jobId,
            locations,
          }).unwrap();

          close();
        } catch (e) {
          showErrorToast("Failed to generate job description. Please refresh and try again.");
        }
      }
    };

    await createJobAndGenerateJd();
    close();
  }, [updateJobSetup, generateJobDescription, getValues, jobId, locations, close]);

  const onErrors = (): void => {
    console.log("errors", errors);
  };

  return (
    <CustomModal
      open={isOpen}
      title={<Subtitle2>Generate job description</Subtitle2>}
      onClose={close}
      maxWidth="sm"
      dialogActions={
        <Stack direction="row" spacing={1}>
          <Button onClick={close} variant={ButtonVariant.Secondary}>
            <ButtonText color={colors.grayscale.gray500}>Cancel</ButtonText>
          </Button>
          <Button
            width="fit-content"
            variant={ButtonVariant.Primary}
            onClick={handleSubmit(onSubmit, onErrors)}
            disabled={!!disableSubmit || isGeneratingJobDescription}
          >
            Generate
          </Button>
        </Stack>
      }
    >
      <DoverLoadingOverlay active={isGeneratingJobDescription}>
        <Stack spacing={2} id="create-job-description-scroll-container" position="relative">
          <Box sx={{ backgroundColor: colors.grayscale.gray100, borderRadius: "6px" }} p={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <BodySmall>✏️</BodySmall>
              <BodySmall>Just answer a few questions about the job and we’ll create one for you.</BodySmall>
            </Stack>
          </Box>
          <Subtitle2>Years of experience</Subtitle2>
          <YOESlider
            values={totalYearsOfExperienceFormValue}
            onChange={handleYearsOfExperienceSliderChange}
            maximumValue={YEARS_OF_EXPERIENCE_MAX}
            minimumRange={YEARS_OF_EXPERIENCE_ALLOWED_RANGE}
            shouldShowTooltip={true}
            scrollContainerId={"create-job-description-scroll-container"}
          />
          <JobLocationFields />
          <Stack spacing={0.5}>
            <Subtitle2>Job requirements and skills</Subtitle2>
            <KeywordsSelector />
          </Stack>
          <Stack spacing={1.5}>
            <Subtitle2>Include anything else about the role</Subtitle2>
            <TextField
              minRows={4}
              multiline={true}
              text={freeTextField.value}
              onTextUpdated={(value): void => freeTextField.onChange(value)}
            />
          </Stack>
        </Stack>
      </DoverLoadingOverlay>
    </CustomModal>
  );
};

const GenerateJobDescriptionModal: FC<GlobalModalProps> = ({ isOpen, close }) => {
  const [jobId] = useJobId();
  const { data: job } = useGetJobSetupQuery(jobId ?? skipToken);

  const { initialRemoteRegions, initialOnsiteLocations, initialRemotePolicy } = getJobDetailProps(job);

  const jobHasHybridLocations = job?.locations?.some(
    location => location.locationType === JobLocationLocationTypeEnum.Hybrid
  );

  // RHF setup
  const formMethods = useForm<CreateJobDescriptionSchemaFormType>({
    defaultValues: {
      ...initialCreateJobDescriptionFormValues,
      remoteRegions: initialRemoteRegions?.map(r => {
        return { displayName: r.locationOption?.displayName, locationOption: r.locationOption?.id };
      }),
      onsiteCities: initialOnsiteLocations?.map(r => {
        return { displayName: r.locationOption?.displayName, locationOption: r.locationOption?.id };
      }),
      remotePolicy: initialRemotePolicy,
      wfhAllowed: jobHasHybridLocations ?? false,
    },
    resolver: zodResolver(CreateJobDescriptionSchema),
  });

  return (
    <FormProvider {...formMethods}>
      <GenerateJobDescriptionForm isOpen={isOpen} close={close} />
    </FormProvider>
  );
};

export const generateJDModalAtom = modalAtom(GenerateJobDescriptionModal);
