import { atom } from "jotai";

type ApplicationQuestionsAtom = Record<string, string[]>;

export const applicationQuestionsAtom = atom<ApplicationQuestionsAtom>({});

// set the answers to a questionId
export const setAnswersForQuestionIdAtom = atom(null, (get, set, questionId: string, options: string[]) => {
  const prevSelectedOptions = get(applicationQuestionsAtom);
  set(applicationQuestionsAtom, { ...prevSelectedOptions, [questionId]: options });
});

// add an individual answer to a questionId's answers
export const selectAppQuestionOptionAtom = atom(null, (get, set, questionId: string, option: string) => {
  const prevSelectedOptions = get(applicationQuestionsAtom);
  const newSelectedOptions = { ...prevSelectedOptions };
  const previouslySelectedOptionsForQ = prevSelectedOptions[questionId];

  // if we previously didn't have an entry for this q, add it, and initialize the answers array
  if (!previouslySelectedOptionsForQ) {
    newSelectedOptions[questionId] = [option];

    // otherwise if it was already selected, this means we want to deselect it
  } else if (previouslySelectedOptionsForQ.includes(option)) {
    newSelectedOptions[questionId] = newSelectedOptions[questionId].filter((currOption: string) => {
      return currOption != option;
    });
    if (!newSelectedOptions[questionId].length) {
      delete newSelectedOptions[questionId];
    }
  }

  // otherwise, add it
  else {
    newSelectedOptions[questionId] = [...previouslySelectedOptionsForQ, option];
  }

  set(applicationQuestionsAtom, newSelectedOptions);
});

// one or more answers are selected for a questionId (ie application question filters are applied)
export const hasAppQuestionFiltersAppliedAtom = atom(get => {
  const appQuestions = get(applicationQuestionsAtom);
  for (const questionId in appQuestions) {
    if (appQuestions[questionId].length) {
      return true; // If at least one questionId has a truthy value, return true
    }
  }
  return false;
});
