import Stack from "@mui/material/Stack";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { RHFTextField } from "sections/addcandidate/components/hook-form";
import { InterviewPipelineCandidateInfoSchema } from "sections/addcandidate/types/candidateInfo";

const AgencyInfoFields = (): React.ReactElement | null => {
  const { control } = useFormContext<InterviewPipelineCandidateInfoSchema>();
  const source = useWatch({
    control: control,
    name: "source",
  });

  switch (source.internalName) {
    case "AGENCY":
      return (
        <Stack direction="row" spacing={2}>
          <RHFTextField
            name="agencyName"
            size="small"
            variant="outlined"
            label="Agency Name"
            placeholder="Sandstone Recruiting"
          />
          <RHFTextField
            name="agencyEmail"
            size="small"
            variant="outlined"
            label="Agency Email"
            placeholder="george@sandstonerecruiting.com"
          />
        </Stack>
      );
    case "CANDIDATE_REFERRED":
      return (
        <Stack direction="row" spacing={2}>
          <RHFTextField
            name="agencyName"
            size="small"
            variant="outlined"
            label="Referrer Name"
            placeholder="Joe Schmoe"
          />
          <RHFTextField
            name="agencyEmail"
            size="small"
            variant="outlined"
            label="Referrer Email"
            placeholder="george@example.com"
          />
        </Stack>
      );
    default:
      return null;
  }
};

export default AgencyInfoFields;
