import { Stack } from "@mui/material";
import React from "react";

import { AccountSettingsSectionType } from "App/routing/types";
import Filters from "views/CompanySetup/components/Notifications/components/Filters";
import Table from "views/CompanySetup/components/Notifications/components/Table";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { accountSettingsConfiguration } from "views/CompanySetup/constants";

const NotificationPreferences = (): React.ReactElement => {
  return (
    <SectionWrapper config={accountSettingsConfiguration[AccountSettingsSectionType.NOTIFICATIONS]}>
      <Stack display="flex" spacing={1} direction="row">
        <Filters />
        <Table />
      </Stack>
    </SectionWrapper>
  );
};

export default NotificationPreferences;
