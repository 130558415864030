import { Box, Grid } from "@mui/material";
import React from "react";

import { Spacer } from "components/Spacer";
import { TimeRangePicker } from "views/CompanySetup/components/InterviewPreferences/components/TimeRangePicker";
import { DAYS_OF_WEEK } from "views/CompanySetup/components/InterviewPreferences/constants";
import { DividingLine } from "views/CompanySetup/components/InterviewPreferences/styles";

export const AvailabilityChart = ({ setIsDirty }: { setIsDirty: (value: boolean) => void }): React.ReactElement => {
  return (
    <Box>
      {DAYS_OF_WEEK.map((day, index) => {
        return (
          <div key={index}>
            <Grid container>
              <Grid item>
                <TimeRangePicker dayOfWeek={day.letter} label={day.label} setIsDirty={setIsDirty} />
                <Spacer height="21px" />
              </Grid>
              <DividingLine />
            </Grid>
            {index !== DAYS_OF_WEEK.length - 1 && <Spacer height="21px" />}
          </div>
        );
      })}
    </Box>
  );
};
