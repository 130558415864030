/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateActivityNote
 */
export interface CandidateActivityNote {
    /**
     * 
     * @type {string}
     * @memberof CandidateActivityNote
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CandidateActivityNote
     */
    readonly created?: Date;
    /**
     * 
     * @type {string}
     * @memberof CandidateActivityNote
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateActivityNote
     */
    candidate: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateActivityNote
     */
    author?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CandidateActivityNote
     */
    readonly authorName?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateActivityNote
     */
    readonly authorRole?: string;
    /**
     * 
     * @type {Date}
     * @memberof CandidateActivityNote
     */
    pinnedAt?: Date | null;
}

export function CandidateActivityNoteFromJSON(json: any): CandidateActivityNote {
    return CandidateActivityNoteFromJSONTyped(json, false);
}

export function CandidateActivityNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateActivityNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'content': !exists(json, 'content') ? undefined : json['content'],
        'candidate': json['candidate'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'authorName': !exists(json, 'author_name') ? undefined : json['author_name'],
        'authorRole': !exists(json, 'author_role') ? undefined : json['author_role'],
        'pinnedAt': !exists(json, 'pinned_at') ? undefined : (json['pinned_at'] === null ? null : new Date(json['pinned_at'])),
    };
}

export function CandidateActivityNoteToJSON(value?: CandidateActivityNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'candidate': value.candidate,
        'author': value.author,
        'pinned_at': value.pinnedAt === undefined ? undefined : (value.pinnedAt === null ? null : value.pinnedAt.toISOString()),
    };
}


