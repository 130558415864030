import { Box, Step, StepButton, Stepper } from "@mui/material";
import React, { useState, FC } from "react";
import { useBoolean } from "react-use";

import { useResetAddCandidateToCampaignAtomState } from "components/dover/AddCandidate/atoms";
import CandidateDetailsForm from "components/dover/AddCandidate/CandidateDetailsForm";
import { FooterSpacer } from "components/dover/AddCandidate/Footer";
import PreviewAndSendEmailStep from "components/dover/AddCandidate/PreviewAndSendEmailStep";
import CustomModal from "components/Modal";
import { UnsavedChangesModal } from "components/UnsavedChangesModal";
import { modalAtom, useModal } from "GlobalOverlays/atoms";
import { GlobalModalProps } from "GlobalOverlays/GlobalOverlays";
import { colors } from "styles/theme";

/* -----------------------------------------------------------------------------
 * Form Wrapper
 * -------------------------------------------------------------------------- */
const steps = [
  { label: "Candidate details", component: CandidateDetailsForm },
  { label: "Preview and send email", component: PreviewAndSendEmailStep },
];

const StepButtonStyles = {
  ".MuiStepIcon-root": {
    color: colors.white,
    border: `1px solid ${colors.grayscale.gray300}`,
    borderRadius: "50%",
    ".MuiStepIcon-text": {
      fill: colors.grayscale.gray500,
    },
    ".MuiStepLabel-labelContainer": {
      color: colors.grayscale.gray500,
    },
    "&.Mui-active": {
      color: colors.grayscale.gray500,
      border: "none",
      ".MuiStepIcon-text": {
        fill: colors.white,
      },
      ".MuiStepLabel-labelContainer": {
        color: colors.grayscale.gray700,
      },
    },
    "&.Mui-completed": {
      color: colors.primary.base,
      border: "none",
    },
  },
};

const AddCandidateToCampaignFormWrapper: FC = () => {
  const { close: closeModal } = useModal(addCandidateToCampaignModalAtom);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [completed, setCompleted] = useState<Record<number, boolean>>({});

  const handleComplete = (): void => {
    setCompleted(prevCompleted => ({ ...prevCompleted, [activeStep]: true }));
  };

  const handleNext = (): void => {
    handleComplete();
    // If we're on the last step, close the modal
    if (activeStep === steps.length - 1) {
      closeModal();
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const ActiveStepComponent = steps[activeStep].component;

  return (
    <Box position="relative">
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={step.label} completed={completed[index]}>
            {/* Disable StepButton to prevent navigating without form validation */}
            <StepButton color="inherit" disabled sx={StepButtonStyles}>
              {step.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box
        p={2}
        bgcolor={colors.grayscale.gray100}
        sx={{ marginLeft: "-24px", marginRight: "-24px", marginTop: "16px" }}
      >
        <ActiveStepComponent handleNextStep={handleNext} handlePrevStep={handleBack} />
      </Box>
      <FooterSpacer />
    </Box>
  );
};

/* -----------------------------------------------------------------------------
 * Main Component
 * -------------------------------------------------------------------------- */

interface AddCandidateToCampaignModalProps {
  campaignId: string;
}

export const AddCandidateToCampaignModal: React.FC<GlobalModalProps & AddCandidateToCampaignModalProps> = ({
  isOpen,
  close,
}) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useBoolean(false);

  const resetAtomState = useResetAddCandidateToCampaignAtomState();

  const confirmOnClose = (): void => {
    setShowUnsavedChangesModal(true);
  };

  const resetStateOnClose = (): void => {
    resetAtomState();
    close();
  };

  return (
    <>
      <UnsavedChangesModal
        open={showUnsavedChangesModal}
        onCancel={setShowUnsavedChangesModal}
        onDiscard={resetStateOnClose}
        maxWidth="xs"
      />
      <CustomModal title="Add candidate to campaign" open={isOpen} onClose={confirmOnClose} maxWidth="sm">
        <AddCandidateToCampaignFormWrapper />
      </CustomModal>
    </>
  );
};

export const addCandidateToCampaignModalAtom = modalAtom(AddCandidateToCampaignModal);
