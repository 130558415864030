import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { useListEmailSenderAliasesQuery } from "services/doverapi/endpoints/emailSenderAlias";
import { listAllEntities } from "services/doverapi/entityAdapterUtils";
import { EmailSenderAlias } from "services/openapi";

interface Props {
  initialEmailSenderAliasId: string;
  onChange: (option: EmailSenderAlias) => void;
}
const EmailSenderAliasPicker = ({ initialEmailSenderAliasId, onChange }: Props): React.ReactElement => {
  const {
    data: emailSenderAliasesState,
    isSuccess: emailSenderAliasesStateIsSuccess,
  } = useListEmailSenderAliasesQuery();
  const emailSenderAliases = useMemo(() => listAllEntities(emailSenderAliasesState), [emailSenderAliasesState]);

  const [option, setOption] = useState<EmailSenderAlias | null>(null);

  useEffect(() => {
    if (emailSenderAliases) {
      setOption(emailSenderAliases.find(i => i.id === initialEmailSenderAliasId) || null);
    }
  }, [emailSenderAliases, initialEmailSenderAliasId]);

  return (
    <Autocomplete
      value={option}
      onChange={(event: any, newOption: any): void => {
        setOption(newOption);
        onChange(newOption);
      }}
      renderInput={(params: any): React.ReactElement => (
        <TextField
          {...params}
          label={<span>Email Sender Alias {!emailSenderAliasesStateIsSuccess && <CircularProgress size={14} />}</span>}
          fullWidth
        />
      )}
      options={emailSenderAliases.filter(alias => !!alias.email)}
      getOptionLabel={(emailSenderAlias: EmailSenderAlias): string => emailSenderAlias.email!}
      blurOnSelect
    />
  );
};

export default EmailSenderAliasPicker;
