import { format } from "date-fns";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjsDuration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { useIsBasePlanCustomer } from "services/doverapi/endpoints/client/hooks";
import {
  NextAction,
  SlimNextActionSchedulingStateEnum,
  PipelineCandidate,
  CandidateBio,
  HiringPipelineStageType,
} from "services/openapi";
import { ReviewFilterEnum } from "views/candidates/types";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsDuration);
dayjs.extend(customParseFormat);

export const convertPrettyDate = (date: Date): string => {
  const dateCopy = new Date(date);
  const today = new Date();
  if (today.setHours(0, 0, 0, 0) === dateCopy.setHours(0, 0, 0, 0)) {
    return "today";
  }

  return format(date, "MMM d, yyyy");
};

export const getInterviewerNameFromNextAction = (nextAction: NextAction | undefined): string => {
  const interview = nextAction?.currentInterview;

  if (!interview) {
    return "";
  }

  let interviewerName = "";
  if (interview.hasDoverInterviewer) {
    interviewerName = "Dover Interviewer";
  } else if (interview.interviewer?.firstName) {
    interviewerName = interview.interviewer.firstName;
    if (interview.interviewer?.lastName) {
      interviewerName += ` ${interview?.interviewer?.lastName}`;
    }
  }

  return interviewerName;
};

const interviewDurationOptions = [
  { value: 15 * 60, label: "15 min" },
  { value: 20 * 60, label: "20 min" },
  { value: 25 * 60, label: "25 min" },
  { value: 30 * 60, label: "30 min" },
  { value: 45 * 60, label: "45 min" },
  { value: 60 * 60, label: "60 min" },
  { value: 75 * 60, label: "75 min" },
  { value: 90 * 60, label: "90 min" },
  { value: 120 * 60, label: "120 min" },
];

interface Option {
  value: number | string;
  label: string;
}
export function getDurationOptions(): Option[] {
  return interviewDurationOptions;
}

export const getFormattedSendAt = (sendAt: string): string => {
  if (sendAt === "") {
    return "";
  }
  const date = new Date(sendAt);
  const timezone = date.toLocaleTimeString("en-US", { timeZoneName: "short" }).split(" ")[2];
  return `${dayjs(date).format("ddd, MMM D, h:mma")} ${timezone}`;
};

/**
 *  We don't want to show the email of any who is "Queued" to self serve users.
 */
export const useShowCandidateEmail = (bio?: CandidateBio): boolean => {
  const isBasePlan = useIsBasePlanCustomer();

  if (!bio) return false;

  return !isBasePlan || bio.candidatePipelineStage?.stageType !== HiringPipelineStageType.QUEUED;
};

export const getReviewType = (candidate: PipelineCandidate): ReviewFilterEnum | undefined => {
  const schedulingState = candidate?.nextAction?.schedulingState;

  switch (schedulingState) {
    case SlimNextActionSchedulingStateEnum.InitialReview:
      if (candidate.nextAction?.pendingCustomerResponse) {
        return ReviewFilterEnum.PendingCustomerResponse;
      }
      return ReviewFilterEnum.RespondedAsInterested;

    case SlimNextActionSchedulingStateEnum.CallCompleted:
      return ReviewFilterEnum.CompletedInterview;
  }

  return undefined;
};
