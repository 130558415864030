/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

import { Box, Stack } from "@mui/material";
import Switch from "@mui/material/Switch";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";

import { BodySmall, Subtitle2 } from "components/library/typography";
import { useUpsertNotificationConfigMutation } from "services/doverapi/endpoints/notificationConfig";
import { colors } from "styles/theme";
import { NotificationType } from "views/CompanySetup/components/Notifications/components/constants";
import { SwitchLabelControl } from "views/CompanySetup/components/Notifications/styles";

const weeklyNotifHeaders: Record<NotificationType, string> = {
  [NotificationType.WeeklyNotifs]: "Updates",
  [NotificationType.InterviewsNotifs]: "Interviews",
  [NotificationType.AppReviewNotifs]: "Application Review",
  [NotificationType.SourcingAutopilot]: "Sourcing",
};

const generateCols = (notifType: NotificationType): GridColDef[] => {
  return [
    {
      field: "label",
      headerName: weeklyNotifHeaders[notifType],
      flex: 1,
      sortable: false,
      cellClassName: "t-cell",
      headerClassName: "h-cell",
      renderCell: (params: GridRenderCellParams): React.ReactElement => {
        return (
          <Stack>
            <Subtitle2>{params.row.label}</Subtitle2>
            <Box sx={{ textWrap: "wrap" }}>
              <BodySmall color={colors.grayscale.gray600}>{params.row.description}</BodySmall>
            </Box>
          </Stack>
        );
      },
    },
    {
      field: "emailSetting",
      headerName: "Email",
      flex: 0.3,
      minWidth: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams): React.ReactElement => {
        const [
          upsertConfig, // This is the mutation trigger
          { isLoading: isUpdating }, // This is the destructured mutation result
        ] = useUpsertNotificationConfigMutation();

        return (
          <SwitchLabelControl
            checked={params.row.emailSetting}
            disabled={isUpdating}
            onChange={(): void => {
              upsertConfig({
                ...(params.row.original ?? {}),
                enabled: !params.row.emailSetting,
                job: params.row.job,
                notifType: params.row.type,
                channel: "EMAIL",
              });
            }}
            control={<Switch sx={{ m: 1 }} />}
            label={isUpdating ? "Saving" : params.row.emailSetting ? "On" : "Off"}
            sx={{
              color: params.row.emailSetting ? colors.primary.base : colors.grayscale.gray400,
            }}
          />
        );
      },
      cellClassName: "t-cell",
      headerClassName: "h-cell",
    },
  ];
};

export const preferencesTableCols: Record<NotificationType, GridColDef[]> = {
  [NotificationType.WeeklyNotifs]: generateCols(NotificationType.WeeklyNotifs),
  [NotificationType.InterviewsNotifs]: generateCols(NotificationType.InterviewsNotifs),
  [NotificationType.AppReviewNotifs]: generateCols(NotificationType.AppReviewNotifs),
  [NotificationType.SourcingAutopilot]: generateCols(NotificationType.SourcingAutopilot),
};
