/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocialLink
 */
export interface SocialLink {
    /**
     * 
     * @type {string}
     * @memberof SocialLink
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof SocialLink
     */
    linkType: string;
}

export function SocialLinkFromJSON(json: any): SocialLink {
    return SocialLinkFromJSONTyped(json, false);
}

export function SocialLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'linkType': json['link_type'],
    };
}

export function SocialLinkToJSON(value?: SocialLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'link_type': value.linkType,
    };
}


