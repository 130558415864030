import { format } from "date-fns";
import React from "react";

import { Caption } from "components/library/typography";
import { colors } from "styles/theme";
import { Cell } from "views/candidates/CandidateTable/table/cells/styles";
import { CellProps } from "views/candidates/CandidateTable/table/cells/types";

export const LastModifiedCell = ({ candidate }: CellProps): React.ReactElement => {
  return (
    <Cell>
      <Caption color={colors.grayscale.gray600}>
        {candidate.lastEventTs ? format(candidate.lastEventTs, "MM/dd/yy") : ""}
      </Caption>
    </Cell>
  );
};
