import { Stack, Skeleton } from "@mui/material";
import { range } from "lodash";
import React from "react";

export const LoadingSkeleton = ({ showHeader = false }: { showHeader?: boolean }): React.ReactElement => {
  return (
    <Stack width="100%" alignItems="left" direction="column" spacing={0}>
      {!!showHeader && (
        <>
          <Skeleton height="62px" width="33%" />
          <Skeleton height="48px" width="100%" />
        </>
      )}
      <Stack direction="column" spacing={-1.5} width="100%">
        {range(5).map(() => (
          <Stack direction="row" width="100%" spacing={2} alignItems="center">
            <Skeleton height="72px" width="100%" />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
