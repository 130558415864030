/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CampaignMessage,
    CampaignMessageFromJSON,
    CampaignMessageFromJSONTyped,
    CampaignMessageToJSON,
    SlimEmailSenderAlias,
    SlimEmailSenderAliasFromJSON,
    SlimEmailSenderAliasFromJSONTyped,
    SlimEmailSenderAliasToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListCampaign
 */
export interface ListCampaign {
    /**
     * 
     * @type {string}
     * @memberof ListCampaign
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCampaign
     */
    readonly name?: string;
    /**
     * 
     * @type {string}
     * @memberof ListCampaign
     */
    readonly state?: ListCampaignStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ListCampaign
     */
    readonly setupState?: ListCampaignSetupStateEnum;
    /**
     * 
     * @type {SlimEmailSenderAlias}
     * @memberof ListCampaign
     */
    emailAlias?: SlimEmailSenderAlias;
    /**
     * 
     * @type {Array<CampaignMessage>}
     * @memberof ListCampaign
     */
    readonly threadMessages?: Array<CampaignMessage>;
    /**
     * 
     * @type {string}
     * @memberof ListCampaign
     */
    readonly emailSenderOption?: ListCampaignEmailSenderOptionEnum;
    /**
     * 
     * @type {number}
     * @memberof ListCampaign
     */
    userDefinedSenderUser: number;
}

/**
* @export
* @enum {string}
*/
export enum ListCampaignStateEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    Deleted = 'DELETED'
}/**
* @export
* @enum {string}
*/
export enum ListCampaignSetupStateEnum {
    Pending = 'PENDING',
    StartedPendingClientAction = 'STARTED_PENDING_CLIENT_ACTION',
    StartedPendingDoverAction = 'STARTED_PENDING_DOVER_ACTION',
    Complete = 'COMPLETE'
}/**
* @export
* @enum {string}
*/
export enum ListCampaignEmailSenderOptionEnum {
    HiringManager = 'HIRING_MANAGER',
    VirtualRecruiter = 'VIRTUAL_RECRUITER',
    OtherUser = 'OTHER_USER'
}

export function ListCampaignFromJSON(json: any): ListCampaign {
    return ListCampaignFromJSONTyped(json, false);
}

export function ListCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'setupState': !exists(json, 'setup_state') ? undefined : json['setup_state'],
        'emailAlias': !exists(json, 'email_alias') ? undefined : SlimEmailSenderAliasFromJSON(json['email_alias']),
        'threadMessages': !exists(json, 'thread_messages') ? undefined : ((json['thread_messages'] as Array<any>).map(CampaignMessageFromJSON)),
        'emailSenderOption': !exists(json, 'email_sender_option') ? undefined : json['email_sender_option'],
        'userDefinedSenderUser': json['user_defined_sender_user'],
    };
}

export function ListCampaignToJSON(value?: ListCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_alias': SlimEmailSenderAliasToJSON(value.emailAlias),
        'user_defined_sender_user': value.userDefinedSenderUser,
    };
}


