/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InboundApplicationUrls
 */
export interface InboundApplicationUrls {
    /**
     * 
     * @type {string}
     * @memberof InboundApplicationUrls
     */
    unknown: string;
    /**
     * 
     * @type {string}
     * @memberof InboundApplicationUrls
     */
    careersPage: string;
    /**
     * 
     * @type {string}
     * @memberof InboundApplicationUrls
     */
    candidateReferred: string;
}

export function InboundApplicationUrlsFromJSON(json: any): InboundApplicationUrls {
    return InboundApplicationUrlsFromJSONTyped(json, false);
}

export function InboundApplicationUrlsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InboundApplicationUrls {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unknown': json['unknown'],
        'careersPage': json['careers_page'],
        'candidateReferred': json['candidate_referred'],
    };
}

export function InboundApplicationUrlsToJSON(value?: InboundApplicationUrls | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unknown': value.unknown,
        'careers_page': value.careersPage,
        'candidate_referred': value.candidateReferred,
    };
}


