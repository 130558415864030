import { Box, Stack, styled } from "@mui/material";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { FC } from "react";

import { Caption } from "components/library/typography";
import { colors } from "styles/theme";
import { pageAtom } from "views/candidates/ApplicationReview/atoms/pagination";
import { selectedApplicantListTabAtom } from "views/candidates/ApplicationReview/atoms/selectedApplicantListTab";
import { useApplications } from "views/candidates/ApplicationReview/hooks/useApplications";
import { useJobHasScoringEnabled } from "views/candidates/ApplicationReview/hooks/useJobHasScoringEnabled";

export const ApplicantListTabs: FC = () => {
  const [selectedTab, setSelectedTab] = useAtom(selectedApplicantListTabAtom);
  const resetPage = useResetAtom(pageAtom);

  const { jobHasScoringEnabled } = useJobHasScoringEnabled();

  const { unreviewedCount, bookmarkedCount, matchingCount } = useApplications();

  const isUnreviewedSelected = selectedTab === "Unreviewed";
  const isBookmarkedSelected = selectedTab === "Bookmarked";
  const isMatchingSelected = selectedTab === "Matching";

  const clickUnreviewed = (): void => {
    if (isUnreviewedSelected) {
      return;
    }
    resetPage();
    setSelectedTab("Unreviewed");
  };

  const clickBookmarked = (): void => {
    if (isBookmarkedSelected) {
      return;
    }
    resetPage();
    setSelectedTab("Bookmarked");
  };

  const clickMatching = (): void => {
    if (isMatchingSelected) {
      return;
    }
    resetPage();
    setSelectedTab("Matching");
  };

  return (
    <Stack direction="row" width="100%" borderBottom="1px solid rgba(0, 0, 0, 0.1)">
      <TabButton isActive={isUnreviewedSelected} onClick={clickUnreviewed}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Caption
            color={isUnreviewedSelected ? colors.black : colors.grayscale.gray400}
            weight={isUnreviewedSelected ? "600" : "400"}
          >
            {/* Although this is actually unreviewedCount (ie not bookmarked), call it All - product decision */}
            All
          </Caption>
          {unreviewedCount !== undefined && <Count>{unreviewedCount}</Count>}
        </Stack>
      </TabButton>
      {/* If job doesnt have scoring enabled shouldnt show the matching tab */}
      {jobHasScoringEnabled && (
        <TabButton isActive={isMatchingSelected} onClick={clickMatching}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Caption
              color={isMatchingSelected ? colors.black : colors.grayscale.gray400}
              weight={isMatchingSelected ? "600" : "400"}
            >
              Matching
            </Caption>
            {matchingCount !== undefined && <Count>{matchingCount}</Count>}
          </Stack>
        </TabButton>
      )}
      <TabButton isActive={isBookmarkedSelected} onClick={clickBookmarked}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Caption
            color={isBookmarkedSelected ? colors.black : colors.grayscale.gray400}
            weight={isBookmarkedSelected ? "600" : "400"}
          >
            Bookmarked
          </Caption>
          {bookmarkedCount !== undefined && <Count>{bookmarkedCount}</Count>}
        </Stack>
      </TabButton>
    </Stack>
  );
};

interface TabButtonProps {
  isActive?: boolean;
}

const TabButton = styled(Box)<TabButtonProps>`
  flex: 1;
  justify-content: center;
  display: flex;
  border-bottom: ${(props): string => (props.isActive ? `3px solid ${colors.primary.base}` : "")};
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 6px;
  cursor: pointer;
  font-size: 14px;
`;

const Count = styled(Box)`
  padding: 0 6px;
  background-color: ${colors.grayscale.gray200};
  border-radius: 20px;
  color: ${colors.black};
  font-size: 12px;
  font-weight: 600;
  font-family: Inter;
`;
