/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchoolListParams,
    SchoolListParamsFromJSON,
    SchoolListParamsFromJSONTyped,
    SchoolListParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SchoolList
 */
export interface SchoolList {
    /**
     * 
     * @type {string}
     * @memberof SchoolList
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolList
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SchoolList
     */
    scopedToClient?: string | null;
    /**
     * 
     * @type {SchoolListParams}
     * @memberof SchoolList
     */
    paramsJson: SchoolListParams;
}

export function SchoolListFromJSON(json: any): SchoolList {
    return SchoolListFromJSONTyped(json, false);
}

export function SchoolListFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchoolList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'scopedToClient': !exists(json, 'scoped_to_client') ? undefined : json['scoped_to_client'],
        'paramsJson': SchoolListParamsFromJSON(json['params_json']),
    };
}

export function SchoolListToJSON(value?: SchoolList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'scoped_to_client': value.scopedToClient,
        'params_json': SchoolListParamsToJSON(value.paramsJson),
    };
}


