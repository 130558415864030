import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Menu, MenuItem, Stack } from "@mui/material";
import React, { MouseEventHandler, ReactElement, useMemo } from "react";
import styled from "styled-components";
import theme from "styled-theming";

import { SecondaryButton } from "components/Button";
import { Body, BodyExtraSmall } from "components/library/typography";
import { Spacer } from "components/Spacer";
import { colors } from "styles/theme";

export enum DropdownToggleVariant {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  Ghost = "GHOST",
  Critical = "CRITICAL",
  LightCritical = "LIGHT_CRITICAL",
}

interface ReviewActionsDropdownProps {
  actions: Array<{ icon?: ReactElement; label: string; onClick: MouseEventHandler }>;
  toggleStyle?: Object;
  disabled?: boolean;
  toggleContent?: React.ReactElement;
  toggleVariant?: DropdownToggleVariant;
  // we can feed which label is currently selected, so as to highlight which label is selected by boldening it in the dropdown
  selectedLabel?: string;
  dropdownToggleId?: string;
}

export const ReviewActionsDropdown = ({
  actions,
  toggleStyle,
  toggleContent,
  selectedLabel,
  disabled = false,
  toggleVariant = DropdownToggleVariant.Secondary,
  dropdownToggleId,
}: ReviewActionsDropdownProps): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const stageMenuOpen = Boolean(anchorEl);
  const handleClose = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setAnchorEl(null);
    event?.stopPropagation();
  };

  /* Light variants require a full border, whereas filled variants do not */
  const includeFullBorder = useMemo(() => {
    return [DropdownToggleVariant.Ghost, DropdownToggleVariant.LightCritical, DropdownToggleVariant.Secondary].includes(
      toggleVariant
    );
  }, [toggleVariant]);

  if (actions.length) {
    return (
      <>
        <StageDropdownToggle
          onClick={(e: React.MouseEvent<HTMLElement>): void => {
            setAnchorEl(e.currentTarget);
            e.stopPropagation();
          }}
          style={toggleStyle}
          toggleVariant={toggleVariant}
          includeFullBorder={includeFullBorder}
          disabled={disabled}
          id={dropdownToggleId}
        >
          {toggleContent ? (
            toggleContent
          ) : (
            <Stack direction="row" alignItems="center">
              <MoreVertIcon fontSize="small" />
              <Box mx="5px">
                <Body>More</Body>
              </Box>
            </Stack>
          )}
        </StageDropdownToggle>
        <StyledMenu
          open={stageMenuOpen}
          // @ts-ignore
          onClose={handleClose}
          onClick={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          PaperProps={{
            style: {
              maxHeight: 400,
            },
          }}
        >
          {actions.map(a => (
            <StyledMenuItem
              onClick={(e: React.MouseEvent<HTMLElement>): void => {
                handleClose();
                a.onClick(e);
              }}
            >
              {a.icon ? (
                <>
                  {a.icon}
                  <Spacer width="6px" />
                </>
              ) : null}
              {/* we want to display the selectedLabel in a boldened way */}
              {a.label !== selectedLabel && a.label}
              {!!a.label && a.label === selectedLabel && <BodyExtraSmall weight="700">{a.label}</BodyExtraSmall>}
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </>
    );
  }

  return <></>;
};

/********* Styles *********/

const backgroundColor = theme.variants("mode", "toggleVariant", {
  [DropdownToggleVariant.Primary]: { light: colors.primary.base },
  [DropdownToggleVariant.Secondary]: { light: "white" },
  [DropdownToggleVariant.Critical]: { light: colors.critical.base },
  [DropdownToggleVariant.Ghost]: { light: "transparent" },
  [DropdownToggleVariant.LightCritical]: { light: colors.critical.light },
});

const hoverColor = theme.variants("mode", "toggleVariant", {
  [DropdownToggleVariant.Primary]: { light: colors.primary.dark },
  [DropdownToggleVariant.Secondary]: { light: colors.grayscale.gray100 },
  [DropdownToggleVariant.Critical]: { light: colors.critical.dark },
  [DropdownToggleVariant.Ghost]: { light: colors.grayscale.gray100 },
  [DropdownToggleVariant.LightCritical]: { light: "white" },
});

const borderColor = theme.variants("mode", "toggleVariant", {
  [DropdownToggleVariant.Primary]: { light: colors.white },
  [DropdownToggleVariant.Secondary]: { light: colors.grayscale.gray300 },
  [DropdownToggleVariant.Critical]: { light: colors.critical.dark },
  [DropdownToggleVariant.Ghost]: { light: colors.grayscale.gray200 },
  [DropdownToggleVariant.LightCritical]: { light: colors.grayscale.gray200 },
});

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 12px;
    font-family: "Inter";
    p {
      font-size: 12px;
      font-family: "Inter";
    }
    .noIcon {
      padding-left: 28px;
      line-height: 22px;
    }
  }
`;

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border: solid 1px ${colors.grayscale.gray200};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.grayscale.gray200};
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.grayscale.gray400};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.grayscale.gray500};
  }
`;

interface DropdownToggleProps {
  toggleVariant: DropdownToggleVariant;
  includeFullBorder: boolean;
}

const StageDropdownToggle = styled(SecondaryButton)<DropdownToggleProps>`
  &.MuiButton-root {
    height: 100%;
    border: 1px solid ${borderColor};
    border-width: ${({ includeFullBorder }): string => (includeFullBorder ? "1px" : "0px")};
    border-left: 1px solid ${borderColor};
    background-color: ${backgroundColor};
    box-sizing: border-box;
    max-width: 192px;
    min-width: auto;
    padding: 6px 8px 8px;
    line-height: 15px;
    &:hover {
      background-color: ${hoverColor};
      border: 0px;
      border-left: 1px solid ${borderColor};
      border-left-width: ${({ includeFullBorder }): string => (includeFullBorder ? "0px" : "1px")};
    }

    color: ${colors.grayscale.gray700};
    font-size: 12px;
    .MuiButton-endIcon {
      position: relative;
      top: 1px;
    }
  }
`;
