/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartnerJobResponse
 */
export interface PartnerJobResponse {
    /**
     * 
     * @type {string}
     * @memberof PartnerJobResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJobResponse
     */
    title: string | null;
}

export function PartnerJobResponseFromJSON(json: any): PartnerJobResponse {
    return PartnerJobResponseFromJSONTyped(json, false);
}

export function PartnerJobResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerJobResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
    };
}

export function PartnerJobResponseToJSON(value?: PartnerJobResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
    };
}


