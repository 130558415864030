import { Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { useActionVersion } from "components/dover/top-level-modal-manager/hooks/useActionVersion";
import { useInitialCandidateReply } from "components/dover/top-level-modal-manager/hooks/useInitialCandidateReply";
import {
  CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES,
  CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES,
  CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES,
  CANDIDATE_ACTION_MODAL_TITLE_WEIGHT,
} from "components/dover/top-level-modal-manager/modals/candidate-action-modal/constants";
import { CandidateActionModalProps } from "components/dover/top-level-modal-manager/types";
import { Button, ButtonVariant } from "components/library/Button";
import { TextField } from "components/library/TextField";
import { Body, BodySmall } from "components/library/typography";
import CustomModal from "components/Modal";
import { useCandidateJobId } from "hooks/useCandidateJobId";
import { useSubmitDecision } from "hooks/useSubmitDecision";
import { SubmitDecisionRequestDecisionEnum } from "services/openapi";
import { colors } from "styles/theme";
import { toastOptions } from "utils/showToast";

const NeedsResponseModal = ({ isOpen, closeModal, candidateId }: CandidateActionModalProps): React.ReactElement => {
  const candidateActionVersion = useActionVersion(candidateId);
  const initialCandidateReply = useInitialCandidateReply(candidateId);
  const jobId = useCandidateJobId(candidateId);

  const submitDecision = useSubmitDecision();

  const [response, setResponse] = useState<string | undefined>(undefined);

  const onSubmit = useCallback(async () => {
    if (!response || !jobId) {
      return;
    }

    const args = {
      jobId,
      args: {
        id: candidateId,
        data: {
          candidateActionVersion,
          decision: SubmitDecisionRequestDecisionEnum.Approve,
          responseToCandidateQuestion: response,
          auto: true,
        },
      },
    };

    const submitDecisionPromise = submitDecision(args).unwrap();

    try {
      await toast.promise(
        submitDecisionPromise,
        {
          error: "Error updating candidate",
        },
        { ...toastOptions }
      );

      closeModal();
    } catch (e) {
      console.error(e);
      return;
    }
  }, [candidateActionVersion, candidateId, closeModal, jobId, response, submitDecision]);

  const ActionButtons = useMemo(
    () => (
      <Stack width="100%" spacing={2} direction="row" justifyContent="flex-end" padding="12px">
        <Button variant={ButtonVariant.SecondarySuccess} onClick={onSubmit} disabled={!response}>
          Submit and Schedule
        </Button>
      </Stack>
    ),
    [response, onSubmit]
  );

  return (
    <CustomModal
      open={isOpen}
      onClose={closeModal}
      title={<Body weight={CANDIDATE_ACTION_MODAL_TITLE_WEIGHT}>Needs Response</Body>}
      maxWidth="sm"
      customDialogStyles={{ ...CANDIDATE_ACTION_MODAL_CUSTOM_DIALOG_STYLES, zIndex: 3000 }}
      customContentStyles={{ ...CANDIDATE_ACTION_MODAL_CUSTOM_CONTENT_STYLES, backgroundColor: colors.white }} // There is an issue with mui text fields that only allow transparent background, so overridinng the whole modal to be white here
      dialogActions={ActionButtons}
      dialogActionsStyles={CANDIDATE_ACTION_MODAL_DIALOG_ACTIONS_STYLES}
    >
      <Stack spacing={2}>
        <BodySmall>
          We&apos;ll schedule the next interview, but first please help us respond to this candidate. Our team will
          incorporate your note into the email.
        </BodySmall>
        <Stack spacing={1}>
          <BodySmall weight="500">Candidate&apos;s response:</BodySmall>
          <TextField multiline disabled minRows={4} maxRows={8} defaultValue={initialCandidateReply} />
        </Stack>
        <Stack spacing={1}>
          <BodySmall weight="500">Enter your response:</BodySmall>
          <TextField
            multiline
            minRows={4}
            maxRows={4}
            text={response || ""}
            onTextUpdated={(updatedText: string): void => {
              setResponse(updatedText);
            }}
            error={response !== undefined && response.length === 0}
            errorMessage={"Please give your response"}
          />
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default NeedsResponseModal;
