import { Skeleton, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAtomValue, useAtom } from "jotai";
import React, { useEffect } from "react";

import {
  feedbackTemplateDrawerConfigAtom,
  feedbackTemplateNameAtom,
  userDefinedQuestionsAtom,
} from "components/dover/FeedbackTemplates/atoms";
import { INVALID_PERMS_TO_EDIT_TEMPLATE_MESSAGE } from "components/dover/FeedbackTemplates/constants";
import { DrawerFooter } from "components/dover/FeedbackTemplates/DrawerHeaderFooter";
import { FeedbackTemplateDrawerAtom } from "components/dover/FeedbackTemplates/FeedbackTemplateDrawer";
import { useHasPermissionToEditTemplate, useReset } from "components/dover/FeedbackTemplates/hooks";
import InnerForm from "components/dover/FeedbackTemplates/InnerForm";
import { FeedbackTemplateFormProps, FeedbackTemplateQuestion } from "components/dover/FeedbackTemplates/types";
import { Banner, BannerVariant } from "components/library/Banner";
import { useModal } from "GlobalOverlays/atoms";
import {
  useGetFeedbackTemplateQuery,
  useUpdateFeedbackTemplateMutation,
} from "services/doverapi/endpoints/feedbackTemplates";

/**
 * @description Form for editing an existing FeedbackTemplate
 *
 * Form state is tracked with Jotai.
 *
 */
const EditFeedbackTemplateForm: React.FC<FeedbackTemplateFormProps> = ({ validateOnClose }) => {
  // Jotai state
  const { existingTemplateId, assignedJobStageCount } = useAtomValue(feedbackTemplateDrawerConfigAtom);
  const resetAtomState = useReset();
  const { close: onClose } = useModal(FeedbackTemplateDrawerAtom);

  const [feedbackTemplateName, setFeedbackTemplateName] = useAtom(feedbackTemplateNameAtom);
  const [userDefinedQuestions, setUserDefinedQuestions] = useAtom(userDefinedQuestionsAtom);

  const { data: existingFeedbackTemplate, isFetching: isLoadingExistingTemplate } = useGetFeedbackTemplateQuery(
    existingTemplateId ?? skipToken
  );
  const [updateFeedbackTemplate] = useUpdateFeedbackTemplateMutation();

  const hasPermsToEdit = useHasPermissionToEditTemplate();

  useEffect(() => {
    if (existingFeedbackTemplate) {
      setFeedbackTemplateName(existingFeedbackTemplate.name);
      setUserDefinedQuestions(existingFeedbackTemplate.userDefinedQuestions as FeedbackTemplateQuestion[]);
    }
  }, [existingFeedbackTemplate, setFeedbackTemplateName, setUserDefinedQuestions]);

  const onUpdateFeedbackTemplate = async (): Promise<void> => {
    if (existingTemplateId && feedbackTemplateName && userDefinedQuestions) {
      const validQuestions = userDefinedQuestions.filter(q => q.label.trim() !== "");

      await updateFeedbackTemplate({
        id: existingTemplateId,
        data: { name: feedbackTemplateName, questions: validQuestions },
      }).unwrap();
    }
    resetAtomState();
    onClose();
  };

  if (!existingTemplateId) {
    return null;
  }

  return (
    <Stack p={2} spacing={2}>
      {isLoadingExistingTemplate ? (
        <Skeleton variant="rectangular" height="50px" />
      ) : (
        <>
          {hasPermsToEdit && assignedJobStageCount !== undefined && assignedJobStageCount > 0 && (
            <Banner variant={BannerVariant.Warning}>
              {`This form is currently assigned to ${assignedJobStageCount} job stage(s) and edits will be applied to all of them.`}
            </Banner>
          )}
          {!hasPermsToEdit && <Banner variant={BannerVariant.Info}>{INVALID_PERMS_TO_EDIT_TEMPLATE_MESSAGE}</Banner>}
          <InnerForm existingNameToEdit={existingFeedbackTemplate?.name} />
          <DrawerFooter onClose={validateOnClose} onSave={onUpdateFeedbackTemplate} />
        </>
      )}
    </Stack>
  );
};

export default EditFeedbackTemplateForm;
