import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { getNewSearchV3 } from "components/dover/helpers";
import { ToggleEnablingAutopilotAtom, ToggleEnablingAutopilotFromDrawerAtom } from "components/dover/sourcing/atoms";
import {
  useParseJdFeaturesMutation,
  useGetJobDescriptionQuery,
} from "services/doverapi/endpoints/job-description/endpoints";
import { useUpsertJobFeatureSettingMutation } from "services/doverapi/endpoints/jobFeatureSettings/endpoints";
import {
  useCreateSearchV3Mutation,
  useGetPersonaForJobMutation,
  useListSearchesV3Query,
  useUpdateSearchFromFeaturesMutation,
} from "services/doverapi/endpoints/search-v3/endpoints";
import { PersonaIDsRequired } from "services/doverapi/endpoints/search-v3/types";
import {
  DashboardJob,
  ParseJDFeaturesResponse,
  SearchV3SearchTypeEnum,
  UpsertJobFeatureSettingFeatureNameEnum,
  UpsertJobFeatureSettingStateEnum,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

/**
 * Custom hook for turning on sourcing autopilot for a job (replaces legacy autopilot onboarding flow).
 *
 * @param job - The job we're enabling autopilot for.
 * @param shouldEnableAutopilotJobFeature - If true, turn on the autopilot job feature.
 * @returns A callable function that enables sourcing autopilot.
 */
const useEnableAutopilotV2 = (
  job?: DashboardJob,
  shouldEnableAutopilotJobFeature?: boolean,
  entrypoint?: string
): (() => Promise<void>) => {
  const navigate = useNavigate();

  // We track loading state in a global atom; RTK cache keys won't work here b/c it's a synchronous call chain
  // (note that we toggle a different atom depending on the entrypoint)
  const toggleEnablingAutopilotGeneral = useSetAtom(ToggleEnablingAutopilotAtom);
  const toggleEnablingAutopilotFromDrawer = useSetAtom(ToggleEnablingAutopilotFromDrawerAtom);
  const toggleEnablingAutopilot =
    entrypoint === "createJobDrawer" ? toggleEnablingAutopilotFromDrawer : toggleEnablingAutopilotGeneral;

  const [getPersonaForJob] = useGetPersonaForJobMutation();
  const [updateSearchFromFeatures] = useUpdateSearchFromFeaturesMutation();
  const [parseJdFeatures] = useParseJdFeaturesMutation();
  const [createSearchV3] = useCreateSearchV3Mutation();
  const [upsertJobFeatureSetting] = useUpsertJobFeatureSettingMutation();

  // Get existing outbound searches
  const { data: searches, isLoading: isLoadingSearches } = useListSearchesV3Query(
    job?.id
      ? {
          job: job.id,
          searchTypeList: SearchV3SearchTypeEnum.Outbound,
        }
      : skipToken
  );

  // Get JD
  const { data: jobDescription } = useGetJobDescriptionQuery(
    job?.id
      ? {
          jobId: job.id,
          requiresAuthentication: true,
        }
      : skipToken
  );

  return async (): Promise<void> => {
    // None checks (these should be defined anyway)
    if (!job?.id) {
      return;
    }
    if (!job?.client) {
      return;
    }

    // First toggle loading state to true
    toggleEnablingAutopilot(true);

    // Enable the autopilot job feature for paying customers in certain entrypoints,
    // or fail loudly
    if (shouldEnableAutopilotJobFeature) {
      try {
        await upsertJobFeatureSetting({
          upsertData: {
            data: {
              job: job.id,
              featureName: UpsertJobFeatureSettingFeatureNameEnum.ManagedOutbound,
              state: UpsertJobFeatureSettingStateEnum.Enabled,
            },
          },
        }).unwrap();
      } catch {
        showErrorToast("Failed to enable autopilot");
        toggleEnablingAutopilot(false);
        return;
      }
    }

    // If the job already has an outbound search, go to saap
    if (!isLoadingSearches && searches && searches?.length > 0) {
      const existingOutboundSearchId = searches[0].id!;
      toggleEnablingAutopilot(false);
      navigate(APP_ROUTE_PATHS.sourcing(existingOutboundSearchId));
      return;
    }

    // Find the job's persona to initialize the search with, or fail quietly
    let personaData;
    try {
      personaData = await getPersonaForJob({
        jobId: job.id,
      }).unwrap();
    } catch (error) {
      console.error(`Error finding persona for job: ${error}`);
    }
    const persona = personaData ? (personaData.persona as PersonaIDsRequired) : undefined;

    // Try to create a new base search or fail loudly
    let baseSearch;
    try {
      baseSearch = await createSearchV3(
        getNewSearchV3({
          persona: persona,
          jobId: job.id,
          searchName: "base",
          clientId: job?.client,
        })
      ).unwrap();
    } catch {
      toggleEnablingAutopilot(false);
      return;
    }

    const baseSearchId = baseSearch.id;
    if (!baseSearchId) {
      showErrorToast("Failed to initialize a search");
      toggleEnablingAutopilot(false);
      return;
    }

    // If there's no JD to parse, go to saap
    if (!jobDescription?.userProvidedDescription) {
      navigate(APP_ROUTE_PATHS.sourcing(baseSearchId));
      toggleEnablingAutopilot(false);
      return;
    }

    // Try to parse JD, or fail silently and go to saap
    let parsedJdFeatureResponse: ParseJDFeaturesResponse | undefined;
    try {
      parsedJdFeatureResponse = await parseJdFeatures({
        jdText: jobDescription?.userProvidedDescription,
        jobTitle: job.title,
        searchTemplateId: job.personaId, // this currently represents the search template id, not the persona id
      }).unwrap();
    } catch (error) {
      console.error(error);
    }

    if (!parsedJdFeatureResponse) {
      navigate(APP_ROUTE_PATHS.sourcing(baseSearchId));
      toggleEnablingAutopilot(false);
      return;
    }

    // Take parsed JD features and update the search criteria with them, or fail silently
    if (parsedJdFeatureResponse) {
      try {
        await updateSearchFromFeatures({
          id: baseSearchId,
          data: {
            personaId: parsedJdFeatureResponse?.personaId ?? undefined,
            searchFeatures: parsedJdFeatureResponse?.searchFeatures || null,
          },
        }).unwrap();
      } catch (error) {
        console.error(error);
      }
    }

    // Now we're done, go to saap
    toggleEnablingAutopilot(false);
    navigate(APP_ROUTE_PATHS.sourcing(baseSearchId));
  };
};

export default useEnableAutopilotV2;
